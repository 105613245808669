import { Mesh, TextureLoader, PlaneGeometry, MeshPhongMaterial, CircleGeometry, MeshBasicMaterial, Object3D, DoubleSide, LineBasicMaterial, BackSide, Vector3, BufferGeometry, Line, Float32BufferAttribute, BufferAttribute, Shape, ExtrudeGeometry, FrontSide, MeshStandardMaterial } from "three";
import { eAxisType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { iMinMax, iPoint2D, iSize } from "../../_context/_interfaces/Interfaces";
import { MatrixUtils } from "../../_utils/MatrixUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ShapesUtils } from "../../_utils/ShapesUtils";
import { iOpticsVO } from "../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../data/data_loader/OpticsDataLoader";
import { SceneContext } from "../../scene/SceneContext";
import { eSmPlaneWaveType, iPointSourceProfile, eSmRaysKind, eSmBaseShapeKind } from "../../simulation/SimulationContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { iDetectorData } from "./ExportToJSONInterfaces";
import { Strings } from "../../_context/Strings";
import { iLightSource, iCircularPlaneWaveData, iRectanglePlaneWaveData, iEllipticalPlaneWaveData } from "../behaviors/LightSourceContext";
import { OpticsFactory } from "../optics/OpticsFactory";
import { PartsCatalog } from "./PartsCatalog";
import { SurfaceContext } from "../optics/SurfaceContext";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { piGaussianBeam } from "../../ui/part_info/_light_source_section/piGaussianBeam";
import { Part } from "../Part";
import { ePartType, iFace, iPart } from "../PartInterfaces";

export class PartsFactory {
    // private static MODEL_SCALE = 2;
    private static DETECTOR_THICKNESS_RANGE: iMinMax = { max: 3, min: 0.1 } //in mm

    //__________________________________________________________________________________________
    /**
     * @description generates a plane with an image of the loaded item
     * @param pImageUrl image to load
     * @returns plane with the image
     */
    //__________________________________________________________________________________________


    public static async getImagePlane(pImageUrl: string): Promise<Mesh> {


        return new Promise((res) => {
            let aLoader = new TextureLoader();
            aLoader.load(pImageUrl, (texture) => {
                let aScale = (UnitHandler.scale / 4.5);
                let aW = (texture.image.width * aScale);
                let aH = (texture.image.height * aScale);

                let aPlaneGeometry = new PlaneGeometry(aW, aH);
                //let aPlaneGeometry = new PlaneGeometry(90, 90)
                let aPlaneMaterial = new MeshStandardMaterial({
                    map: texture,
                    transparent: true,
                    roughness: 0.9,
                    metalness: 1,
                });

                const aPlane = new Mesh(aPlaneGeometry, aPlaneMaterial);
                aPlane.position.y = 0
                aPlane.name = Strings.IMAGE_PART;
                aPlane.quaternion.copy(SceneContext.CAMERA.quaternion)
                res(aPlane)

            })
        })
    }
    //__________________________________________________________________________________________
    public static createSpinner(pPlane: Mesh, pPartUrl: string) {
        let aScale = UnitHandler.scale;
        let aSpinnerGeometry = new CircleGeometry(2 * aScale);
        let aSpinnerMaterial = new MeshBasicMaterial({
            color: 0x48BAE9
        });
        const spinnerBox = new Mesh(aSpinnerGeometry, aSpinnerMaterial);
        spinnerBox.position.z = aScale
        spinnerBox.position.x = -(4 * aScale);

        let animateSpinner = () => {
            spinnerBox.position.x += (4 * aScale)
            if (spinnerBox.position.x > (6 * aScale)) {
                spinnerBox.position.x = -(4 * aScale)
            }
            Op3dContext.PARTS_MANAGER?.rotatePlaneOnCamera(pPlane);

            SceneContext.OP3D_SCENE.activateRenderer()
            let loaded = PartsCatalog.instance.isPartReady(pPartUrl)
            if (loaded) {
                clearInterval(spinnerAnim)
                pPlane.remove(spinnerBox)
            }
        }
        let updatePlaneRotation = () => {

            Op3dContext.PARTS_MANAGER?.rotatePlaneOnCamera(pPlane);
            SceneContext.OP3D_SCENE.activateRenderer()
            let loaded = PartsCatalog.instance.isPartReady(pPartUrl)
            if (loaded) {
                clearInterval(rotatePlane)
            }
        }
        let spinnerAnim = setInterval(animateSpinner, 150)
        let rotatePlane = setInterval(updatePlaneRotation, 20)

        return spinnerBox
    }
    //__________________________________________________________________________________________
    public static getPartFromFaces(pParams: {
        faces: Array<iFace>,
        number_id: string,
        id: string,
        iPartName: string;
        detectorData?: iDetectorData,
    }) {

        let aPartObject3D = new Object3D();
        let aShapeObject3D = new Object3D();
        aPartObject3D.add(aShapeObject3D);

        let aSolidObject3D = new Object3D();
        for (let i = 0; i < pParams.faces.length; i++) {
            aSolidObject3D.add(pParams.faces[i].visualization.mesh);
        }
        aShapeObject3D.add(aSolidObject3D);

        let aAxis = SceneContext.OP3D_SCENE.getAxisModel();
        aPartObject3D.add(aAxis);

        let aIPart: iPart = {
            data: {
                detectorData: pParams.detectorData,
                number_id: pParams.number_id,
            },
            name: pParams.iPartName,
            axes: [{
                object3D: aAxis,
                internal_id: Op3dUtils.idGenerator(),
                type: eAxisType.GENERAL
            }],
            shapes: [{
                solids: [{
                    faces: pParams.faces,
                    object3D: aSolidObject3D,
                    name: 'solid_0',
                    internal_id: Op3dUtils.idGenerator()
                }],
                name: 'shape_0',
                object3D: aShapeObject3D,
                internal_id: Op3dUtils.idGenerator()
            }],
            object3D: aPartObject3D,
            internal_id: Op3dUtils.idGenerator()
        };

        return aIPart;
    }
    // //__________________________________________________________________________________________
    // private static _getSize(pSize: number) {
    //     if (pSize * PartsFactory.MODEL_SCALE < LaserBehavior.MAX_LIGHT_SOURCE_MODEL) {
    //         return pSize * PartsFactory.MODEL_SCALE;
    //     }

    //     return LaserBehavior.MAX_LIGHT_SOURCE_MODEL;
    // }
    //__________________________________________________________________________________________
    private static _getGaussianModel(pLightSourceData: iLightSource) {
        let aPrimaryWavelength = pLightSourceData.wavelengthData.find((wavelenght) =>
            (true == wavelenght.isPrimary));
        if (null == aPrimaryWavelength) {
            throw new Error("No primary wavelenght");
        }
        let aWavelength = (aPrimaryWavelength.wl * OP3DMathUtils.NANO_TO_MM);

        let aGaussianBeam = pLightSourceData.gaussianBeam;
        const aRadiusX = piGaussianBeam.getWaist(aGaussianBeam!.q_x, aWavelength);
        const aRadiusY = piGaussianBeam.getWaist(aGaussianBeam!.q_y, aWavelength);

        let aNewMesh = ShapesUtils.getCylinder({
            radius_bottom: 1,
            radius_top: 1,
            height: 5,
            color: pLightSourceData.color,
            alpha: pLightSourceData.alpha,
            radius_x: aRadiusX,
            radius_y: aRadiusY
        });

        return aNewMesh;
    }
    //__________________________________________________________________________________________
    private static _getPlaneWaveModel(pLightSourceData: iLightSource) {

        let aNewMesh: Mesh;
        switch (pLightSourceData.shape) {
            case eSmPlaneWaveType.CIRCULAR: {
                const aRadius = (pLightSourceData.sourceGeometricalData as iCircularPlaneWaveData).radius;

                aNewMesh = ShapesUtils.getCylinder({
                    radius_bottom: 1,
                    radius_top: 1,
                    height: 5,
                    color: pLightSourceData.color,
                    alpha: pLightSourceData.alpha,
                    radius_x: aRadius,
                    radius_y: aRadius,
                });
                break;
            }
            case eSmPlaneWaveType.RECTANGULAR:
                const aParams = (pLightSourceData.sourceGeometricalData as iRectanglePlaneWaveData)
                const aWidth = aParams.width;
                const aHeight = aParams.height;
                aNewMesh = ShapesUtils.getBox({
                    width: aWidth,
                    height: aHeight,
                    depth: 5,
                    color: pLightSourceData.color,
                    alpha: pLightSourceData.alpha
                });
                break;

            case eSmPlaneWaveType.ELLIPTICAL: {
                const aParams = (pLightSourceData.sourceGeometricalData as iEllipticalPlaneWaveData)
                const aRadiusX = aParams.radius_x;
                const aRadiusY = aParams.radius_y;
                aNewMesh = ShapesUtils.getCylinder({
                    radius_bottom: 1,
                    radius_top: 1,
                    height: 5,
                    color: pLightSourceData.color,
                    alpha: pLightSourceData.alpha,
                    radius_x: aRadiusX,
                    radius_y: aRadiusY,
                });
                break;
            }
            default:
                throw new Error("not implemented '_getPlaneWaveModel'");
        }

        return aNewMesh;
    }
    //__________________________________________________________________________________________
    private static _getPointSourceModel(pData: iLightSource) {

        //const aScale = parts.behaviors.LaserBehavior.MODEL_SCALE;
        pData.model_radius = pData.model_radius != null ?
            pData.model_radius : (pData.sourceGeometricalData as iPointSourceProfile).angle_x;

        let aMesh = ShapesUtils.getEllipsoid({
            angle_x: pData.model_radius,
            angle_y: pData.model_radius,
            color: pData.color,
            alpha: pData.alpha
        });
        return aMesh;
    }
    //__________________________________________________________________________________________
    public static getLightSourceModel(pKind: eSmRaysKind, aLSData: iLightSource) {
        let aNewMesh: Mesh;

        if (aLSData.arrayOfSourcesData?.is_array) {
            const aArrayOfSourcesData = aLSData.arrayOfSourcesData.data;
            switch (aLSData.arrayOfSourcesData.data?.kind) {
                case eSmBaseShapeKind.RECTANGLE:
                    aNewMesh = ShapesUtils.getBox({
                        width: aArrayOfSourcesData?.half_width! * 2,
                        height: aArrayOfSourcesData?.half_height! * 2,
                        depth: 5,
                        color: aLSData.color,
                        alpha: aLSData.alpha
                    });
                    break;
                case eSmBaseShapeKind.ELLIPSE:
                    aNewMesh = ShapesUtils.getCylinder({
                        radius_bottom: 1,
                        radius_top: 1,
                        height: 5,
                        color: aLSData.color,
                        alpha: aLSData.alpha,
                        radius_x: aArrayOfSourcesData?.radius_x!,
                        radius_y: aArrayOfSourcesData?.radius_y!,
                    });
                    break;
                default:
                    throw new Error("unhandeled type of array of sources 'getLightSourceModel'");
            }
        } else {
            switch (pKind) {
                case eSmRaysKind.PLANE_WAVE:
                    aNewMesh = this._getPlaneWaveModel(aLSData);
                    break;
                case eSmRaysKind.POINT_SOURCE:
                    aNewMesh = this._getPointSourceModel(aLSData);
                    break;
                case eSmRaysKind.GAUSSIAN_BEAM:
                    aNewMesh = this._getGaussianModel(aLSData);
                    break;
                case eSmRaysKind.RAY_FILE:
                    return undefined;
                default:
                    throw new Error("unhandeled type 'getLightSourceModel'");
            }

        }

        return aNewMesh;
    }
    //__________________________________________________________________________________________
    public static getLaserFaces(pLightSourceData: iLightSource) {

        let aModel = this.getLightSourceModel(pLightSourceData.kind, pLightSourceData);
        if (aModel != null) {
            let aFace: iFace = {
                internal_id: Op3dUtils.idGenerator(),
                visualization: {
                    mesh: aModel,
                    emissive: (aModel.material as any).emissive.clone(),
                }
            }
            return [aFace];
        }


        return [];
    }
    //__________________________________________________________________________________________
    public static getDetectorThickness(pSize: iSize) {
        let aS = pSize.width * pSize.height;
        let aThickness = Math.min(PartsFactory.DETECTOR_THICKNESS_RANGE.max,
            Math.max(this.DETECTOR_THICKNESS_RANGE.min,
                (PartsFactory.DETECTOR_THICKNESS_RANGE.max / 2500) * aS));
        return aThickness;
    }
    //__________________________________________________________________________________________
    public static getDetectorFaces(pDetectorData: iDetectorData) {
        let aThickness = this.getDetectorThickness(pDetectorData.size);

        let aSurfaces = MatrixUtils.getRectBoxOptics({
            height: pDetectorData.size.height,
            thickness: aThickness,//3,
            width: pDetectorData.size.width
        });

        let aFaces = aSurfaces.map((surface) => {
            return OpticsFactory.getFace({
                surface: surface,
                materialID: Strings.TRANSPARENT_MATERIAL,
                coating: {
                    "top": Strings.ABSORBING,
                    "bottom": Strings.ABSORBING,
                    "left": Strings.ABSORBING,
                    "right": Strings.ABSORBING,
                }
            })
        });

        for (let i = 0; i < aFaces.length; i++) {
            switch (aFaces[i].name) {
                case SurfaceContext.FRONT:
                case SurfaceContext.BACK:
                    let aSurfacesMat = new MeshPhongMaterial({ color: pDetectorData.appearance.color.surface });
                    aSurfacesMat.transparent = pDetectorData.appearance.opacity < 1;
                    aSurfacesMat.opacity = pDetectorData.appearance.opacity;
                    aFaces[i].visualization.mesh.material = aSurfacesMat;
                    break;

                default:
                    // frame
                    let aFrameMat = new MeshPhongMaterial({ color: pDetectorData.appearance.color.frame });
                    aFrameMat.side = DoubleSide;
                    aFaces[i].visualization.mesh.material = aFrameMat;

                    break;
            }
        }

        return aFaces;
    }
    //__________________________________________________________________________________________
    public static getCross(pX: number, pY: number, _pThikncess: number) {
        let aMainContainer = new Object3D();

        const aMaterial = new LineBasicMaterial({
            color: 0x808080,
            side: BackSide
        });

        let aFrontContainer = new Object3D();
        const aHorizontalLinePoints = [];
        aHorizontalLinePoints.push(new Vector3(-pX / 2, 0, 0));
        aHorizontalLinePoints.push(new Vector3(pX / 2, 0, 0));
        const aGeoHorizontal = new BufferGeometry().setFromPoints(aHorizontalLinePoints);
        const aHorizontalLine = new Line(aGeoHorizontal, aMaterial);
        aFrontContainer.add(aHorizontalLine);

        const aVerticalLinePoints = [];
        aVerticalLinePoints.push(new Vector3(0, -pY / 2, 0));
        aVerticalLinePoints.push(new Vector3(0, pY / 2, 0));
        const aGeoVertical = new BufferGeometry().setFromPoints(aVerticalLinePoints);
        const aVerticalLine = new Line(aGeoVertical, aMaterial);
        aFrontContainer.add(aVerticalLine);
        aMainContainer.name = Strings.DETECTOR_CROSS;

        //  let aBackContainer = aFrontContainer.clone();
        aMainContainer.add(aFrontContainer);
        // aMainContainer.add(aBackContainer);
        //aBackContainer.position.z = pThikncess + 0.1;
        return aMainContainer;
    }
    //__________________________________________________________________________________________
    public static getBLetter(pWidth: number, pHeight: number, pThikncess: number) {
        let aMinSide = Math.min(pWidth, pHeight);
        let aLetterHeight = aMinSide / 6;
        let aLetterWidth = aMinSide / 7;
        let aHCell = aLetterHeight / 14;
        let aWCell = aLetterWidth / 10;

        let aStart: iPoint2D = {
            x: -(pWidth / 2) + (pWidth / 10),
            y: (pHeight / 2) - (pHeight / 10)
        }

        let aP1: iPoint2D = { x: aStart.x, y: aStart.y };
        let aP2: iPoint2D = { x: aP1.x + aLetterWidth - (aWCell * 2), y: aP1.y };
        let aP3: iPoint2D = { x: aP1.x + aLetterWidth, y: aP2.y - (aHCell * 2) };
        let aP4: iPoint2D = { x: aP2.x - (aWCell * 2), y: aP3.y };
        let aP5: iPoint2D = { x: aP1.x + (aWCell * 2), y: aP3.y };
        let aP6: iPoint2D = { x: aP1.x, y: aP3.y };
        let aP7: iPoint2D = { x: aP3.x, y: aP3.y - (aHCell * 3) };
        let aP8: iPoint2D = { x: aP4.x + aWCell, y: aP7.y };
        let aP9: iPoint2D = { x: aP8.x, y: aP4.y - aHCell };
        let aP10: iPoint2D = { x: aP3.x, y: aP9.y };
        let aP11: iPoint2D = { x: aP7.x - aWCell, y: aP7.y - aHCell };
        let aP12: iPoint2D = { x: aP4.x, y: aP11.y };
        let aP13: iPoint2D = { x: aP1.x, y: aP11.y };
        let aP14: iPoint2D = { x: aP5.x, y: aP13.y };
        let aP15: iPoint2D = { x: aP2.x, y: aP11.y - aHCell };
        let aP16: iPoint2D = { x: aP1.x, y: aP15.y };
        let aP17: iPoint2D = { x: aP7.x - aWCell, y: aP15.y - aHCell };
        let aP18: iPoint2D = { x: aP1.x, y: aP17.y };
        let aP19: iPoint2D = { x: aP7.x, y: aP17.y - aHCell };
        let aP20: iPoint2D = { x: aP8.x, y: aP19.y };
        let aP21: iPoint2D = { x: aP12.x, y: aP17.y };
        let aP22: iPoint2D = { x: aP14.x, y: aP20.y - (aHCell * 3) };
        let aP23: iPoint2D = { x: aP1.x, y: aP22.y };
        let aP24: iPoint2D = { x: aP14.x, y: aP18.y };
        let aP25: iPoint2D = { x: aP19.x, y: aP22.y + aHCell };
        let aP26: iPoint2D = { x: aP20.x, y: aP25.y };
        let aP27: iPoint2D = { x: aP25.x, y: aP23.y };
        let aP28: iPoint2D = { x: aP21.x, y: aP27.y };
        let aP29: iPoint2D = { x: aP2.x, y: aP1.y - aLetterHeight };
        let aP30: iPoint2D = { x: aP1.x, y: aP29.y };


        const vertices = [
            aP1.x, aP1.y, 0, // vertex 0
            aP2.x, aP2.y, 0, // vertex 1
            aP3.x, aP3.y, 0, // vertex 2
            aP4.x, aP4.y, 0, // vertex 3
            aP5.x, aP5.y, 0, // vertex 4
            aP6.x, aP6.y, 0, // vertex 5
            aP7.x, aP7.y, 0, // vertex 6
            aP8.x, aP8.y, 0, // vertex 7
            aP9.x, aP9.y, 0, // vertex 8
            aP10.x, aP10.y, 0, // vertex 9
            aP11.x, aP11.y, 0, // vertex 10
            aP12.x, aP12.y, 0, // vertex 11
            aP13.x, aP13.y, 0, // vertex 12
            aP14.x, aP14.y, 0, // vertex 13
            aP15.x, aP15.y, 0, // vertex 14
            aP16.x, aP16.y, 0, // vertex 15
            aP17.x, aP17.y, 0, // vertex 16
            aP18.x, aP18.y, 0, // vertex 17
            aP19.x, aP19.y, 0, // vertex 18
            aP20.x, aP20.y, 0, // vertex 19
            aP21.x, aP21.y, 0, // vertex 20
            aP22.x, aP22.y, 0, // vertex 21
            aP23.x, aP23.y, 0, // vertex 22
            aP24.x, aP24.y, 0, // vertex 23
            aP25.x, aP25.y, 0, // vertex 24
            aP26.x, aP26.y, 0, // vertex 25
            aP27.x, aP27.y, 0, // vertex 26
            aP28.x, aP28.y, 0, // vertex 27
            aP29.x, aP29.y, 0, // vertex 28
            aP30.x, aP30.y, 0, // vertex 29

        ];

        // Define the indices of the triangle (in this case, just a single triangle)
        const indices = [
            0, 1, 5, // (1,2,6)
            5, 1, 2, // (6,2,3)
            3, 2, 8,//(4,3,9)
            8, 2, 9,//(9,3,10)
            8, 9, 7, // (9,10,8)
            7, 9, 6,  //(8,10,7)
            7, 6, 11, //(8,7,12)
            11, 6, 10,//(12,7,11)
            5, 4, 12,//(6,5,13)
            12, 4, 13,//(13,5,14)
            12, 10, 15,//(13,11,16)
            15, 10, 14,  //(16,11,15)
            15, 14, 17, //(16,15,18)
            17, 14, 16, //(18,15,17)
            20, 16, 19,  //(21,17,20)
            19, 16, 18, //(20,17,19)
            17, 23, 22,//(18,24,23)
            22, 23, 21,//(23,24,22)
            19, 18, 25, //(20,19,26)
            25, 18, 24,  //(26,19,25)
            25, 24, 27,  //(26,25,28)
            27, 24, 26, //(28,25,27)
            22, 26, 29,  //(23,27,30)
            29, 26, 28//(30,27,29)

        ];

        // Create a BufferGeometry object and set its attributes
        const geometry = new BufferGeometry();
        geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        geometry.setIndex(new BufferAttribute(new Uint16Array(indices), 1));

        // Create a Mesh object using the BufferGeometry and a material
        const material = new MeshBasicMaterial({
            opacity: 0.3, transparent: true,
            color: 0x808080, side: BackSide
        });
        const mesh = new Mesh(geometry, material);
        mesh.name = Strings.DETECTOR_B_LETTER;
        mesh.position.z = pThikncess + 0.1;
        return mesh;
    }
    //__________________________________________________________________________________________
    public static getFLetter(pWidth: number, pHeight: number) {

        let aMinSide = Math.min(pWidth, pHeight)
        let aLetterSide = aMinSide / 6;
        let aDelta = aLetterSide / 4;
        let aStart: iPoint2D = {
            x: (pWidth / 2) - (pWidth / 10),
            y: (pHeight / 2) - (pHeight / 10)
        }

        let aP1: iPoint2D = { x: aStart.x, y: aStart.y };
        let aP2: iPoint2D = { x: aP1.x - aLetterSide + aDelta, y: aP1.y };
        let aP3: iPoint2D = { x: aP2.x, y: aP2.y - aDelta };
        let aP3tag: iPoint2D = { x: aP1.x, y: aP3.y };
        let aP4: iPoint2D = { x: aP1.x - aDelta, y: aP3.y };
        let aP5: iPoint2D = { x: aP4.x, y: aP4.y - aDelta };
        let aP5tag: iPoint2D = { x: aP1.x, y: aP5.y };
        let aP6: iPoint2D = { x: aP3.x + (aDelta / 2), y: aP5.y };
        let aP7: iPoint2D = { x: aP6.x, y: aP6.y - aDelta };
        let aP8: iPoint2D = { x: aP5.x, y: aP7.y };
        let aP8tag: iPoint2D = { x: aP1.x, y: aP8.y };
        let aP9: iPoint2D = { x: aP8.x, y: aP1.y - aLetterSide };
        let aP10: iPoint2D = { x: aP1.x, y: aP9.y };

        const vertices = [
            aP1.x, aP1.y, 0, // vertex 0
            aP2.x, aP2.y, 0, // vertex 1
            aP3.x, aP3.y, 0,  // vertex 2
            aP3tag.x, aP3tag.y, 0,  // vertex 3

            aP4.x, aP4.y, 0,  // vertex 4
            aP5.x, aP5.y, 0,  // vertex 5
            aP5tag.x, aP5tag.y, 0, // vertex 6

            aP6.x, aP6.y, 0,  // vertex 7
            aP7.x, aP7.y, 0,  // vertex 8

            aP8.x, aP8.y, 0, // vertex 9
            aP8tag.x, aP8tag.y, 0, // vertex 10

            aP9.x, aP9.y, 0,  // vertex 11
            aP10.x, aP10.y, 0, // vertex 12
        ];

        // Define the indices of the triangle (in this case, just a single triangle)
        const indices = [
            0, 1, 3, // (1,2,3`)
            3, 1, 2, // (3`2,3,)
            3, 4, 6, // (3`4,5`)
            6, 4, 5,  // (5`,4,5)
            6, 7, 10, // (5`,6,8`)
            10, 7, 8, // (8`,6,7)
            10, 9, 12,  // (8`,8,10)
            12, 9, 11 //(10,8,9)

        ];

        // Create a BufferGeometry object and set its attributes
        const geometry = new BufferGeometry();
        geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        geometry.setIndex(new BufferAttribute(new Uint16Array(indices), 1));

        // Create a Mesh object using the BufferGeometry and a material
        const material = new MeshBasicMaterial({
            opacity: 0.3, transparent: true,
            color: 0x808080, side: BackSide
        });
        const mesh = new Mesh(geometry, material);
        mesh.name = Strings.DETECTOR_F_LETTER;
        mesh.position.z -= 0.1;
        return mesh;
    }
    //__________________________________________________________________________________________
    public static _getFLetter(pWidth: number, pHeight: number) {
        const shape = new Shape();

        let aMinSide = Math.min(pWidth, pHeight)
        let aLetterSide = aMinSide / 6;
        let aDelta = aLetterSide / 4;
        let aStart: iPoint2D = {
            x: (pWidth / 2) - (pWidth / 10),
            y: (pHeight / 2) - (pHeight / 10)
        }

        /**
         *  _ _ _ _
         * |  _ _ _|
         * | |_ _              
         * |  _ _|
         * |_|
         * 
         */


        let aP1: iPoint2D = { x: aStart.x, y: aStart.y };
        let aP2: iPoint2D = { x: aP1.x - aLetterSide, y: aP1.y };
        let aP3: iPoint2D = { x: aP2.x, y: aP2.y - aDelta };
        let aP4: iPoint2D = { x: aP1.x - aDelta, y: aP3.y };
        let aP5: iPoint2D = { x: aP4.x, y: aP4.y - aDelta };
        let aP6: iPoint2D = { x: aP3.x + aDelta, y: aP5.y };
        let aP7: iPoint2D = { x: aP6.x, y: aP6.y - aDelta };
        let aP8: iPoint2D = { x: aP5.x, y: aP7.y };
        let aP9: iPoint2D = { x: aP8.x, y: aP1.y - aLetterSide };
        let aP10: iPoint2D = { x: aP1.x, y: aP9.y };


        shape.moveTo(aStart.x, aStart.y);
        shape.lineTo(aP1.x, aP1.y);
        shape.lineTo(aP2.x, aP2.y);
        shape.lineTo(aP3.x, aP3.y);
        shape.lineTo(aP4.x, aP4.y);
        shape.lineTo(aP5.x, aP5.y);
        shape.lineTo(aP6.x, aP6.y);
        shape.lineTo(aP7.x, aP7.y);
        shape.lineTo(aP8.x, aP8.y);
        shape.lineTo(aP9.x, aP9.y);
        shape.lineTo(aP10.x, aP10.y);



        // Extrude the shape into a 3D object
        const extrudeSettings = {
            depth: 1,
            bevelEnabled: false,
        };
        const geometry = new ExtrudeGeometry(shape, extrudeSettings);
        const material = new MeshBasicMaterial({ transparent: true, opacity: 0.3, color: 0x808080, side: FrontSide });
        const mesh = new Mesh(geometry, material);
        mesh.name = Strings.DETECTOR_F_LETTER;
        mesh.scale.setZ(0.1);
        mesh.position.z -= 0.1;

        return mesh;
    }
    //__________________________________________________________________________________________

    public static getParaxialLens(pParaxialLens: iDetectorData, pId: string) {
        let aFaces = this.getDetectorFaces(pParaxialLens);
        let aIPart = this.getPartFromFaces({
            detectorData: pParaxialLens,
            faces: aFaces,
            number_id: null,
            id: null,
            iPartName: "Paraxial lens",
        });

        let aOpticsholderCotnainer = new Object3D();
        aOpticsholderCotnainer.userData = {
            isDetector: true,
            isResizableDetctor: true
        }

        let aDetectorHolder: iPart = {
            name: Strings.OPTICS_HOLDER,
            object3D: aOpticsholderCotnainer,
            internal_id: Op3dUtils.idGenerator(),
            data: {
            }
        }

        let aPart = new Part({
            id: pId,
            options: { type: ePartType.DYNAMIC_PART }
        }).add(aDetectorHolder);
        aPart.addSubPartToPart(aDetectorHolder, aIPart);

        return aPart;
    }
    //__________________________________________________________________________________________
    public static getDetectorElement(pDetectorData: iDetectorData, _pId: string) {
        let aFaces = this.getDetectorFaces(pDetectorData);
        let aIPart = this.getPartFromFaces({
            detectorData: pDetectorData,
            faces: aFaces,
            number_id: null,
            id: null,
            iPartName: "Customized Detector",
        });

        let aOpticsholderCotnainer = new Object3D();
        aOpticsholderCotnainer.userData = {
            isDetector: true,
            isResizableDetctor: true
        }

        let aDetectorHolder: iPart = {
            name: Strings.OPTICS_HOLDER,
            object3D: aOpticsholderCotnainer,
            internal_id: Op3dUtils.idGenerator(),
            data: {
            }
        }

        let aThickness = this.getDetectorThickness(pDetectorData.size)
        let aCross = this.getCross(pDetectorData.size.width,
            pDetectorData.size.height, aThickness);
        aOpticsholderCotnainer.add(aCross);

        let aFLetter = this.getFLetter(pDetectorData.size.width,
            pDetectorData.size.height);
        aOpticsholderCotnainer.add(aFLetter);

        let aBLetter = this.getBLetter(pDetectorData.size.width,
            pDetectorData.size.height, aThickness);
        aOpticsholderCotnainer.add(aBLetter);

        aDetectorHolder.subParts = [aIPart];
        aDetectorHolder.object3D!.add(aIPart.object3D!);
        return [aDetectorHolder];
    }
    //__________________________________________________________________________________________
    public static getDetectorElementOLD(pDetectorData: iDetectorData, pId: string) {
        let aFaces = this.getDetectorFaces(pDetectorData);
        let aIPart = this.getPartFromFaces({
            detectorData: pDetectorData,
            faces: aFaces,
            number_id: null,
            id: null,
            iPartName: "Customized Detector",
        });

        let aOpticsholderCotnainer = new Object3D();
        aOpticsholderCotnainer.userData = {
            isDetector: true,
            isResizableDetctor: true
        }

        let aDetectorHolder: iPart = {
            name: Strings.OPTICS_HOLDER,
            object3D: aOpticsholderCotnainer,
            internal_id: Op3dUtils.idGenerator(),
            data: {
            }
        }

        let aThickness = this.getDetectorThickness(pDetectorData.size)
        let aCross = this.getCross(pDetectorData.size.width,
            pDetectorData.size.height, aThickness);
        aOpticsholderCotnainer.add(aCross);

        let aFLetter = this.getFLetter(pDetectorData.size.width,
            pDetectorData.size.height);
        aOpticsholderCotnainer.add(aFLetter);

        let aBLetter = this.getBLetter(pDetectorData.size.width,
            pDetectorData.size.height, aThickness);
        aOpticsholderCotnainer.add(aBLetter);

        let aPart = new Part({
            id: pId,
            options: { type: ePartType.DYNAMIC_PART }
        }).add(aDetectorHolder);
        aPart.addSubPartToPart(aDetectorHolder, aIPart);

        return aPart;
    }
    //__________________________________________________________________________________________
    public static async getImmidiateOpticalElement() {
        try {
            let aPart = await this._getOpticalElement();
            if (aPart === null || aPart === undefined) {
                throw new Error("Failed create optical element");
            }
            (aPart.subParts[0].subParts[0].shapes[0].solids[0].faces[0].visualization.mesh.material as any).color.setHex(0xFF0000)
            Op3dContext.PARTS_MANAGER.addPart(aPart);
            aPart.update();
            return aPart;

        } catch (error) {
            alert("error adding optical element");
        }
    }
    //__________________________________________________________________________________________
    private static async _getOpticalElement() {
        //  let aNumberId = 'EDM_67-230';..EDM_32-023//"thin_lens_optics_rect";
        let aNumberId = "EDM_32-978";
        let aResOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
            number_id: aNumberId
        });

        if (aResOpticsVO == null) {
            throw new Error("error getting opticsvo");
        }

        return this.getNewOpticalDevice(aResOpticsVO);
    }
    //__________________________________________________________________________________________
    public static getNewOpticalDevice(pOpticsVO: iOpticsVO) {
        let aPartData = OpticsFactory.createOpticalDevice(pOpticsVO);
        if ((null == aPartData) || (null == aPartData.object3D)) {
            return null;
        }

        Op3dContext.SCENE_HISTORY.addToHistory();

        let aOpticsHolderObjec3D = new Object3D();
        aOpticsHolderObjec3D.name = Strings.OPTICS_HOLDER;

        let aOpticsHolderPart: iPart = {
            name: Strings.OPTICS_HOLDER,
            object3D: aOpticsHolderObjec3D,
            internal_id: Op3dUtils.idGenerator()
        };

        let aPart = new Part({
            number_id: pOpticsVO.number_id,
            options: {
                type: ePartType.CATALOG_OPTICS
            }
        });
        aPart.add(aOpticsHolderPart);
        aPart.addSubPartToPart(aOpticsHolderPart, aPartData);
        aPart.setPartLabel(pOpticsVO.name);

        Op3dContext.SCENE_HISTORY.saveScene();
        return aPart;
    }
    //__________________________________________________________________________________________



}
