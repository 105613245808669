﻿import { eErrorType } from "../../_context/Enums";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iLightSourceCategories } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { iSourceVO } from "../../parser/SourcesParser";
import { Info } from "./Info";
import { LightSourceInfoContext } from "./LightSourceInfoContext";

export class LightSourceInfo extends Info<iSourceVO, iLightSourceCategories> {

    private static SKIN_PATH = "./skins/info/source_info.html";
    private static INSTANCE: LightSourceInfo;
    private mSourceVO: iSourceVO;

    private constructor(pElement: HTMLElement) {
        super(pElement, LightSourceInfo.SKIN_PATH);
    }
    //__________________________________________________________________________________________
    public static get instance(): LightSourceInfo {
        if (this.INSTANCE == null) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "true");
            document.getElementById('forms').appendChild(aDiv);

            this.INSTANCE = new LightSourceInfo(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete() {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onHidden(): void {
        super._onHidden();
        this.mSourceVO = null;
    }
    //__________________________________________________________________________________________
    private async _addBasicFields(pFields: Array<iLightSourceCategories>) {
        for (let i = 0; i < pFields.length; i++) {
            const aItem = pFields[i];
            let aValue: string;

            if (aItem.translate) {
                aValue = aItem.translate(this.mSourceVO);
            } else {
                aValue = DataUtils.getDataFromPath(this.mSourceVO, aItem.path);
            }

            if (aValue == null) {
                continue;
            }

            this._addSingleDataItem(aValue, aItem);
        }
    }
    //__________________________________________________________________________________________
    protected async _initFields(pData: iSourceVO) {
        try {

            this.mSourceVO = pData;
            this.mName.innerHTML = this.mSourceVO.name;
            this.mBrand.innerHTML = this.mSourceVO.parameters.info.brand;

            if (Op3dContext.USER_VO.isAdmin) {
                console.log('%c' + "ID number is: " +
                    this.mSourceVO.number_id,
                    'font-size:15px; margin:2px; background: #40E0D0; color: #000000');
            }

            this.mGraphBtn.style.display = "block";
            await this._addBasicFields(LightSourceInfoContext.LIGHT_SOURCE_BASIC_FIELDS_MAP);

            this._initWeblinkField(this.mSourceVO.parameters.info.weblink, this.mWeblinkField);

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({
                error: e, show_message: false,
                type: eErrorType.INTERNAL_ERROR
            });
        }
    }
    //__________________________________________________________________________________________
}