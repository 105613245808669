import { useContext } from "react";
import classes from "./../../UI/optichat-message-input.module.scss";
import OptiChatContext, {
  eChatMessageType,
  iOptiChatMessage,
} from "../../store/OptiChatContext";

export default function OptiChatReviewBtn() {
  const optiChatContext = useContext(OptiChatContext);
  const FIRST_MESSAGE: iOptiChatMessage = {
    message: "Please review our conversation. What did you like or dislike?",
    typed: false,
    type: eChatMessageType.REVIEW_MESSAGE,
  };

  const onReviewHandler = () => {
    optiChatContext.setReviewOpen((prevState) => {
      if (!prevState === true) {
        optiChatContext.setReviewConversation([FIRST_MESSAGE]);
      }
      return !prevState;
    });
  };

  return (
    <div className={classes["review-btn"]} onClick={onReviewHandler}>
      <img />
    </div>
  );
}
