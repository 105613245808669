import { ePolarizationSourceType } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iHash, iMinMax, iPoint2D, iSize } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ColorUtils } from "../../ColorUtils";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisCache } from "../AnalysisCache";
import { AnalysisCanvasUtils } from "../AnalysisCanvasUtils";
import { AnalysisContext, eAnalysisDisplay, iBasicGraphDataParams } from "../AnalysisContext";
import { AnalysisViewFactory, iAnalysisSpotData } from "./AnalysisViewFactory";
import { ExtendedViewNew } from "./ExtendedViewNew";
import { saveAs } from 'file-saver';
import { anCrossSectionGraph } from "./anCrossSectionGraph";
import { SnapshotTools } from "../../../_utils/SnapshotTools";
import { ColorMapRange as ColorMapRange, iColorMapRangeData } from "../components/ColorMapRange";
import { HTMLAnalysisTemplate } from "./HTMLAnalysisTemplate";
import { anExtendedResultFactory } from "../ResultFactory/anExtendedResultFactory";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { iZoomData, eExtendedViewType } from "../ResultFactory/anAnalysisResultyFactory";

export class anSpotGraph extends ExtendedViewNew<iAnalysisSpotData> {
    private static SPOT_CURSOR_ID = "spot-cursor";

    private mCurrZoomData: iZoomData;
    private mEvents: iHash<any> = {};
    private mOnMouseDown: boolean = false;
    private mCrossSectionGraph: anCrossSectionGraph;
    private mCoordHoverSpan: HTMLElement;
    private mCursors: iHash<HTMLElement>;
    private mCurrGraphData: iBasicGraphDataParams;
    private mSpotView: HTMLElement;

    constructor(pData: iAnalysisSpotData) {
        super();

        this.mData = pData;
    }
    //__________________________________________________________________________________________
    private _getGraphData() {
        let aGraphData = {
            analysisItem: DataUtils.getObjectCopy(this.mData.analysisItem),
            cacheData: this.mData.cacheData,
            labels: AnalysisCanvasUtils.getLabels(this.mData.analysisItem),

            spectrumData: {
                label: AnalysisCanvasUtils.getSpectrumLabel(this.mData.analysisItem),
                range: this.mData.cacheData.matrix.working.values_range
            },
            range_points: AnalysisCanvasUtils.getRange(this.mData.cacheData,
                this.mData.analysisItem.display),
        };

        return aGraphData;
    }
    //__________________________________________________________________________________________
    private _findPos(pTarget: HTMLElement) {
        var curleft = 0, curtop = 0;
        if (pTarget.offsetParent) {
            do {
                curleft += pTarget.offsetLeft;
                curtop += pTarget.offsetTop;
            } while (pTarget = pTarget.offsetParent as HTMLElement);
            return { x: curleft, y: curtop };
        }
        return undefined;
    }
    //__________________________________________________________________________________________
    private _pointToPixel(e: MouseEvent) {
        try {
            let aPos = this._findPos(e.target as HTMLElement);

            const aDgima: iPoint2D = {
                x: e.pageX - aPos.x,
                y: e.pageY - aPos.y
            }

            const aTotalSize = this.mCurrZoomData.totalSize;

            const aCurrSize = this.mCurrZoomData.rectSize;
            const aRatioCanvas: iPoint2D = {
                x: aCurrSize.width / aTotalSize.width,
                y: aCurrSize.height / aTotalSize.height
            }

            const aRatioPixels: iPoint2D = {
                x: this.mData.cacheData.common_data.resolution.y / aTotalSize.width,
                y: this.mData.cacheData.common_data.resolution.x / aTotalSize.height
            }

            const aOffset = this.mCurrZoomData.startPoint;

            const aPointOnCanvas: iPoint2D = {
                x: aOffset.x + aDgima.x * aRatioCanvas.x,
                y: aOffset.y + aDgima.y * aRatioCanvas.y
            }

            const aPixel: iPoint2D = {
                x: Math.floor(aPointOnCanvas.x * aRatioPixels.y),
                y: Math.floor(aPointOnCanvas.y * aRatioPixels.x)
            }

            return aPixel;

        } catch (e) {
            // Op3dContext.USER_VO.isEmployeeUser && console.log(e, "_pointToPixel");
        }
    }
    //__________________________________________________________________________________________
    private _drowCrossByPoint(pX: number, pY: number) {

        let aFixedCross = Op3dUtils.getElementIn(this.mSpotView, "cross-section-container-fixed");
        if (aFixedCross != null) {
            ViewUtils.removeFromParent(aFixedCross);
        }

        let aNewCanvasContainer = Op3dUtils.getElementIn(this.mSpotView, "graph-container");
        let aResult = AnalysisCanvasUtils.initCanvas({
            fitToContainer: true,
            container: aNewCanvasContainer,
            isAdditionalCanvas: true,
            isTextual: true,
            containerID: "cross-section-container-fixed"
        });

        const aCtx = aResult.ctx;
        aCtx.canvas.style.position = "absolute";
        aCtx.canvas.style.left = '0';
        // aCtx.canvas.style.top = '0';
        aCtx.canvas.style.pointerEvents = "none";

        //vertical line
        aCtx.beginPath();
        aCtx.strokeStyle = 'white';
        aCtx.lineWidth = 1;
        aCtx.moveTo(pX, 0);
        aCtx.lineTo(pX, this.mCurrZoomData.totalSize.height);
        aCtx.stroke();

        //horizontal line
        aCtx.beginPath();
        aCtx.strokeStyle = 'white';
        aCtx.lineWidth = 1;
        aCtx.moveTo(0, pY);
        aCtx.lineTo(this.mCurrZoomData.totalSize.width, pY);
        aCtx.stroke();
    }
    //__________________________________________________________________________________________
    private _drawFixedCross(e: MouseEvent) {
        let aBB = (e.target as HTMLElement).getBoundingClientRect()
        let aX = e.clientX - aBB.left;
        let aY = e.clientY - aBB.top;

        this._drowCrossByPoint(aX, aY);
    }
    //__________________________________________________________________________________________
    private async _onClickCanvas(e: MouseEvent) {
        if (e.button == 2 || this.mOnMouseDown == false) return;
        const aPixel = this._pointToPixel(e);

        this.mSpotView.style.pointerEvents = "none";
        setTimeout(() => {
            this.mSpotView.style.pointerEvents = "all";
        }, 1000); // Re-enable the button after 1 second (adjust as needed)

        this.mOnMouseDown = false;

        if (aPixel.x < 0 || aPixel.x > this.mData.cacheData.common_data.resolution.x ||
            aPixel.y < 0 || aPixel.y > this.mData.cacheData.common_data.resolution.y) {
            return;
        }

        await this._openXYCrossSection(aPixel);
        this._drawFixedCross(e);
    }
    //__________________________________________________________________________________________
    private _onMouseDown() {
        this.mOnMouseDown = true;
    }
    //__________________________________________________________________________________________
    private _removeFixedCross() {
        let aFixedCross = Op3dUtils.getElementIn(this.mSpotView, "cross-section-container-fixed");
        if (aFixedCross != null) {
            ViewUtils.removeFromParent(aFixedCross);
        }
    }
    //__________________________________________________________________________________________
    public onCloseExtended() {
        this._removeFixedCross();
        this.mCrossSectionGraph = undefined;
    }
    //__________________________________________________________________________________________
    private async _openXYCrossSection(pPixel: iPoint2D) {

        const aAnalysisItem = DataUtils.getObjectCopy(this.mData.analysisItem);
        aAnalysisItem.display = eAnalysisDisplay.XY_CROSS_SECTION;

        if (this.mCrossSectionGraph !== undefined) {
            await this.mCrossSectionGraph.close();
            this.mCrossSectionGraph = undefined;
        }

        this.mCrossSectionGraph = AnalysisViewFactory.createAnalysis({
            analysisItem: aAnalysisItem,
            cacheData: this.mData.cacheData,
            originalCacheData: DataUtils.getObjectCopy(this.mData.cacheData),
            pixel: pPixel,
            polarizationKind: this.mCurrPolarizationType,
            viewType: eExtendedViewType.LINE,
            spotGraph: this,
            fromMinimize: false,
            isLogView: this.mIsLogView
        }) as anCrossSectionGraph;

        this.mCrossSectionGraph.open();
    }
    //__________________________________________________________________________________________
    private _pixelToCanvas(pPixel: iPoint2D) {
        const aTotalSize = this.mCurrZoomData.totalSize;

        const aRatioPixels: iPoint2D = {
            x: this.mData.cacheData.common_data.resolution.y / aTotalSize.width,
            y: this.mData.cacheData.common_data.resolution.x / aTotalSize.height
        }

        const aCurrSize = this.mCurrZoomData.rectSize;
        const aRatioCanvas: iPoint2D = {
            x: aCurrSize.width / aTotalSize.width,
            y: aCurrSize.height / aTotalSize.height
        }

        /**
         * The pixel is increased because we want to draw on the frame of the canvas
         */
        const aCanvas: iPoint2D = {
            x: (pPixel.x + 1) / aRatioPixels.x,
            y: (pPixel.y + 1) / aRatioPixels.y
        }

        const aOffset = this.mCurrZoomData.startPoint;

        const aDgima: iPoint2D = {
            x: ((aCanvas.x - aOffset.x) / aRatioCanvas.x),
            y: ((aCanvas.y - aOffset.y) / aRatioCanvas.y)
        }

        return aDgima;
    }
    //__________________________________________________________________________________________
    public onPixelViewChanged(pPixel: iPoint2D) {
        let aCanvasPoint = this._pixelToCanvas(pPixel);
        this._drowCrossByPoint(aCanvasPoint.x, aCanvasPoint.y);
    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();

        if (this.mCrossSectionGraph !== undefined) {
            this.mCrossSectionGraph.close();
        }

        if (this.mEvents != null && this.mSpotView != null) {
            for (let event in this.mEvents) {
                this.mSpotView.removeEventListener(event, this.mEvents[event]);
            }
        }
    }
    //__________________________________________________________________________________________
    private _getOriginalRange() {
        let aRangePoints = AnalysisCanvasUtils.getRange(this.mData.originalCacheData, this.mData.analysisItem.display);
        let aRangeX = aRangePoints.x.max - aRangePoints.x.min;
        let aRangeY = aRangePoints.y.max - aRangePoints.y.min;
        let aRange: iPoint2D = {
            x: aRangeX,
            y: aRangeY
        };
        return aRange;
    }
    //__________________________________________________________________________________________
    protected _setCurrentGraphData() {
        this.mCurrGraphData = {
            analysisItem: this.mData.analysisItem,
            cacheData: this.mData.cacheData,
            labels: AnalysisCanvasUtils.getLabels(this.mData.analysisItem),
            range_points: AnalysisCanvasUtils.getRange(this.mData.cacheData, this.mData.analysisItem.display),
            spectrumData: {
                label: AnalysisCanvasUtils.getSpectrumLabel(this.mData.analysisItem),
                range: this.mData.cacheData.matrix.original.values_range
            }
        }
    }
    //__________________________________________________________________________________________
    protected _onReset() {
        this.mWavelengthsComponent.reset();

        let aOriginalCacheData = AnalysisCache.getSpecificCombinedData({
            polarization: ePolarizationSourceType.X,
            id: this.mData.analysisItem.id,
            name: this.mData.cacheData.name,
            wavelengths: this.mWavelengthsComponent.getCheckedWL(),
            polarizationKind: ePolarizationSourceType.X
        }, true);

        let aGraphData = this._getGraphData()
        this.mCurrGraphData = aGraphData;

        this.mData.cacheData = aOriginalCacheData;
        this.mCurrZoomData = undefined;
        // this._setCurrentGraphData();
        this._drawGraph();
    }
    //__________________________________________________________________________________________
    private _onZoomOnSelection(): void {
        const aOriginalData = this._getOriginalRange();
        const aCurrrRange = AnalysisCanvasUtils.getRange(this.mData.cacheData, this.mData.analysisItem.display);
        const aMaxX = aCurrrRange.x.max;

        let aRangeX: iMinMax = {
            max: aMaxX - ((this.mCurrZoomData.startPoint.x * aOriginalData.x) / this.mCurrZoomData.totalSize.width),
            min: aMaxX - (((this.mCurrZoomData.rectSize.width + this.mCurrZoomData.startPoint.x) * aOriginalData.x) / this.mCurrZoomData.totalSize.width),
        }

        let aMaxY = aCurrrRange.y.max;
        let aRangeY: iMinMax = {
            max: aMaxY - ((this.mCurrZoomData.startPoint.y * aOriginalData.y) / this.mCurrZoomData.totalSize.height),
            min: aMaxY - (((this.mCurrZoomData.rectSize.height + this.mCurrZoomData.startPoint.y) * aOriginalData.y) / this.mCurrZoomData.totalSize.height),
        }

        this.mCurrGraphData = {
            canvas: this.mData.canvas,
            analysisItem: this.mData.analysisItem,
            cacheData: this.mData.cacheData,
            labels: AnalysisCanvasUtils.getLabels(this.mData.analysisItem),
            spectrumData: {
                label: AnalysisCanvasUtils.getSpectrumLabel(this.mData.analysisItem),
                range: this.mData.cacheData.matrix.original.values_range
            },
            range_points: {
                x: aRangeX,
                y: aRangeY
            }
        }

        this._clear();
        new anExtendedResultFactory().createResult({
            container: this.mSpotView,
            graphData: this.mCurrGraphData,
            zoomData: this.mCurrZoomData,
            onSelectionZoom: (pData: iZoomData) => this._onZoomOnSelectionEvent(pData),
            uiParams: AnalysisContext.EXTENDED_GENERAL_OPTIONS
        });
    }
    //__________________________________________________________________________________________
    private _onZoomOnSelectionEvent(pData: iZoomData): void {

        const aScale: iPoint2D = {
            x: this.mCurrZoomData.totalSize.width / this.mCurrZoomData.rectSize.width,
            y: this.mCurrZoomData.totalSize.height / this.mCurrZoomData.rectSize.height,
        }

        const aNewSp: iPoint2D = {
            x: this.mCurrZoomData.startPoint.x + pData.startPoint.x / aScale.x,
            y: this.mCurrZoomData.startPoint.y + pData.startPoint.y / aScale.y,
        }

        const aNewRectSize: iSize = {
            width: pData.rectSize.width / aScale.x,
            height: pData.rectSize.height / aScale.y,
        }

        const aNewZoomData: iZoomData = {
            startPoint: aNewSp,
            rectSize: aNewRectSize,
            totalSize: this.mCurrZoomData.totalSize,
        }

        this.mCurrZoomData = aNewZoomData;
        this._onZoomOnSelection();
    }
    //__________________________________________________________________________________________
    /**
     * 
     * @param pZoomDirection 
     * if bigger then 1 then zoom in otherwise zoomout
     * @returns 
     */
    //__________________________________________________________________________________________
    protected _onZoom(pZoomDirection: number) {

        const aCurrScale = (1 - 2 * ExtendedViewNew.ZOOM_DELTA);
        const aScale = pZoomDirection > 0 ? aCurrScale : 1 / aCurrScale;
        const aRectSize: iSize = {
            width: this.mCurrZoomData.rectSize.width * aScale,
            height: this.mCurrZoomData.rectSize.height * aScale,
        }

        if (!OP3DMathUtils.isInRange(aRectSize.width / this.mCurrZoomData.totalSize.width,
            { min: 0.02, max: 1 })) {
            return;
        }

        const x = (this.mCurrZoomData.rectSize.width - aRectSize.width) / 2;
        const y = (this.mCurrZoomData.rectSize.height - aRectSize.height) / 2;

        const aNewSP: iPoint2D = {
            x: this.mCurrZoomData.startPoint.x + x,
            y: this.mCurrZoomData.startPoint.y + y,
        }

        const aZoomData: iZoomData = {
            startPoint: aNewSP,
            rectSize: aRectSize,
            totalSize: this.mCurrZoomData.totalSize,
        }

        this.mCurrZoomData = aZoomData;
        this._onZoomOnSelection();
    }
    //__________________________________________________________________________________________
    protected _drawGraph() {
        let aGraphData = this._getGraphData();
        this._clear();

        let aRes = new anExtendedResultFactory().createResult({
            container: this.mSpotView,
            graphData: aGraphData,
            zoomData: this.mCurrZoomData,
            onSelectionZoom: (pData: iZoomData) => this._onZoomOnSelectionEvent(pData),
            uiParams: AnalysisContext.EXTENDED_GENERAL_OPTIONS
        });

        this.mCurrZoomData = {
            startPoint: { x: 0, y: 0 },
            rectSize: aRes.rectSize,
            totalSize: aRes.totalSize,
        };
    }
    //__________________________________________________________________________________________
    private _createSpotStructure(pContainer: HTMLElement) {
        let aTemplate = $(HTMLAnalysisTemplate.SPOT_STRUCTURE);
        pContainer.appendChild(aTemplate[0]);
    }
    //__________________________________________________________________________________________
    private _removeCross() {
        let aCrossSectionContainer = Op3dUtils.getElementIn(this.mSpotView, "cross-section-container");
        if (aCrossSectionContainer != null) {
            ViewUtils.removeFromParent(aCrossSectionContainer);
        }
    }
    //__________________________________________________________________________________________
    private _hideCursors() {
        this._removeCross();
        ViewUtils.setElementVisibilityByDNone(this.mCursors[anSpotGraph.SPOT_CURSOR_ID], false);
    }
    //__________________________________________________________________________________________
    private onMouseOut() {
        ViewUtils.setElementVisibilityByDNone(this.mCoordHoverSpan, false);
        this._hideCursors();
    }
    //__________________________________________________________________________________________
    private _init2DOptions() {
        let a2DDD = this._getPart("2d-dd");
        ViewUtils.setElementVisibilityByDNone(a2DDD, true);

        let aTemplate = this._getPart("display-item", true);
        let aParent = aTemplate.parentElement;
        aTemplate.parentElement.removeChild(aTemplate);

        let aOptions = AnalysisContext.ANALYSIS_EXTENDED_DISPLAY_TYPES;
        for (let i = 0; i < aOptions.length; i++) {
            let aOption = aTemplate.cloneNode(true) as HTMLOptionElement;
            let aTxt = aOptions[i].charAt(0).toUpperCase() + aOptions[i].slice(1).toLowerCase();
            if (aOptions[i] == eAnalysisDisplay.XY_CROSS_SECTION) {
                // special treatment
                aTxt = aOptions[i];
            }
            aOption.innerText = aTxt;
            aOption.value = aOptions[i];
            aParent.appendChild(aOption);
            aOption.addEventListener("click", () => {
                this._on2DDisplayChanged(aOptions[i]);
                aParent.classList.remove('show');
            })
        }
    }
    //__________________________________________________________________________________________
    private _onPixelViewChanged(pPixel: iPoint2D) {
        let aCanvasPoint = this._pixelToCanvas(pPixel);
        this._drowCrossByPoint(aCanvasPoint.x, aCanvasPoint.y);
    }
    //__________________________________________________________________________________________
    private _on2DDisplayChanged(pDisplayType: eAnalysisDisplay) {
        this._setDisplayType(pDisplayType);

        if (pDisplayType == eAnalysisDisplay.XY_CROSS_SECTION) {
            let aPixel: iPoint2D = {
                x: Math.round(this.mData.cacheData.common_data.resolution.y / 2),
                y: Math.round(this.mData.cacheData.common_data.resolution.x / 2),
            }
            this._onPixelViewChanged(aPixel);
            this._openXYCrossSection(aPixel);
            return;
        }

        this.mData.analysisItem.display = pDisplayType;
        this.mData.canvas = null;
        this._onShown();
    }
    //__________________________________________________________________________________________
    private _onWheel(e: WheelEvent) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        // zoom in negative
        let aZoom = e.deltaY < 0 ? 1 : -1;
        this._onZoom(aZoom);
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.clearElementsChildren(this.mSpotView);
        this._createSpotStructure(this.mSpotView);
    }
    //__________________________________________________________________________________________
    protected _onResize() {
        if (this.mIsShown == false) {
            return;
        }

        this._clear();
        this.mCurrZoomData = {
            startPoint: { x: 0, y: 0 },
            rectSize: this.mCurrZoomData.totalSize,
            totalSize: this.mCurrZoomData.totalSize,
        }
        this._drawGraph();
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        super._onCreationComplete();
        this._init2DOptions();

        this.mSpotView = Op3dUtils.getElementIn(this.mContainer, "spot-view", true);
        ViewUtils.setElementVisibilityByDNone(this.mSpotView, true);
        this._createSpotStructure(this.mSpotView);
        // this.mCanvasContainer = Op3dUtils.getElementIn(this.mSpotView, "analysis-canvas-container");
        // $(this.mSpotView).resize(() => this._onResize());


        let aAdjustAxesOptions = this._getPart("adjust-axes-ranges");
        ViewUtils.removeFromParent(aAdjustAxesOptions);

        this.mEvents["mousemove"] = (e: MouseEvent) => this.onMouseOver(e);
        this.mEvents["mouseout"] = () => this.onMouseOut();
        this.mEvents["mousedown"] = () => this._onMouseDown();
        this.mEvents["mouseup"] = (e: MouseEvent) => this._onClickCanvas(e);

        this.mSpotView.addEventListener("mousemove", this.mEvents["mousemove"]);
        this.mSpotView.addEventListener("mouseout", this.mEvents["mouseout"]);
        this.mSpotView.addEventListener("mousedown", this.mEvents["mousedown"]);
        this.mSpotView.addEventListener("mouseup", this.mEvents["mouseup"]);

        this.mCoordHoverSpan = this._getPart("coord-hover-data", true);
        this.mCursors = {};
        this.mCursors[anSpotGraph.SPOT_CURSOR_ID] = this._getPart(anSpotGraph.SPOT_CURSOR_ID);
        this.mSpotView.addEventListener("wheel", (e: WheelEvent) => this._onWheel(e));
        ViewUtils.makeUnselectable(this.mContainer);
        this.mColorMapOpt.addEventListener("click", (e: MouseEvent) => this._onColorMapRange(e));


        this._getPart("download-raw", true).addEventListener("click", () => this._downloadRaw());
        this.mIsReady = true;

    }
    //__________________________________________________________________________________________
    private _onColorMapRange(e: MouseEvent) {
        /**
         * @TODO: 
         * check that range is valid
         */


        $(this._getPart("extended_view_dd")).dropdown('hide');
        let aData: iColorMapRangeData = {
            clickPoint: e,
            originalRange: {
                max: this.mData.originalCacheData.matrix.original.values_range.max,
                min: this.mData.originalCacheData.matrix.original.values_range.min
            },
            currRange: this.mData.cacheData.matrix.working.values_range,
            onChange: (pData: iMinMax) => {
                this.mData.cacheData.matrix.setColorMapRange(pData.min, pData.max);
                // this.mZoomLevel = 1;
                this.mCurrZoomData = undefined;
                this._drawGraph();
            }
        }

        new ColorMapRange().open(aData);

    }
    //__________________________________________________________________________________________
    private _downloadRaw() {

        /**
        *@TODO analysis polarization type impl
        */
        let aAnalysisData = AnalysisCache.getSpecificCombinedData({
            polarization: ePolarizationSourceType.X,
            id: this.mData.analysisItem.id,
            name: this.mData.cacheData.name,
            wavelengths: this.mWavelengthsComponent.getCheckedWL(),
            polarizationKind: ePolarizationSourceType.X
        }, true);

        /**
         * @TODO 
         * send suitable display type
         */
        let aCanvas = AnalysisCanvasUtils.drawImage(aAnalysisData.matrix.working.matrix,
            aAnalysisData.common_data.resolution,
            this.mData.analysisItem.display,
            aAnalysisData.matrix.working.values_range,
            aAnalysisData.common_data.spectralColors);

        SnapshotTools.downloadImageFromCanvas({
            canvas: aCanvas, mimeType: "image/png",
            name: this.mData.cacheData.name
        });

    }
    //__________________________________________________________________________________________
    private onMouseOver(e: MouseEvent) {
        this._hoverSpotImage(e);
    }
    //__________________________________________________________________________________________
    private findPos(obj) {
        var curleft = 0, curtop = 0;
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
            return { x: curleft, y: curtop };
        }
        return undefined;
    }
    //__________________________________________________________________________________________
    private _getColorUnderPixel(e: MouseEvent): string {
        let aPos = this.findPos(e.target);
        var x = e.pageX - aPos.x;
        var y = e.pageY - aPos.y;
        var c = (e.target as HTMLCanvasElement).getContext('2d');
        (e.target as HTMLCanvasElement).setAttribute('will-read-frequently', 'true');
        var p = c.getImageData(x, y, 1, 1).data;
        var hex = ColorUtils.rgbToHex(p[0], p[1], p[2]);
        return hex;
    }
    //__________________________________________________________________________________________
    private _hoverSpotImage(e: MouseEvent) {
        try {
            const aPixel = this._pointToPixel(e);
            // let aVal = this.mData.cacheData.matrix.working.matrix[aPixel.y][aPixel.x];
            let aVal = this.mData.cacheData.matrix.original.matrix[aPixel.y][aPixel.x];
            ViewUtils.setElementVisibilityByDNone(this.mCoordHoverSpan, true);


            let aNewCanvasContainer = Op3dUtils.getElementIn(this.mSpotView, "graph-container");

            let aElementIn = aNewCanvasContainer.contains(e.target as HTMLElement)
            if (isNaN(aVal) || aElementIn === false) {
                this.onMouseOut();
                return;
            }

            let aColor = this._getColorUnderPixel(e);
            this._setSpotCursor(e, aColor);
            this._setCrossSectionCursor(e);
            this.mFooter.updatePixel(aPixel, aVal)

        } catch (error) {
            this.onMouseOut();
        }
    }
    //__________________________________________________________________________________________
    private _setCrossSectionCursor(e: MouseEvent) {
        /**
        * @TODO fix cross of hover
        */

        this._removeCross();

        let aNewCanvasContainer = Op3dUtils.getElementIn(this.mSpotView, "graph-container");
        let aResult = AnalysisCanvasUtils.initCanvas({
            container: aNewCanvasContainer,
            fitToContainer: true,
            isAdditionalCanvas: true,
            isTextual: false,
            containerID: "cross-section-container"
        });

        aResult.ctx.canvas.style.position = "absolute";
        aResult.ctx.canvas.style.left = '0';
        // aResult.ctx.canvas.style.top = '0';
        aResult.ctx.canvas.style.pointerEvents = "none";

        const aBB = (e.target as HTMLElement).getBoundingClientRect()
        let aX = e.clientX - aBB.left;
        let aY = e.clientY - aBB.top;
        let aColor = this.mData.viewType == eExtendedViewType.LINE ? "#AEAEAE" : "white";

        this._drawCross(
            aResult.ctx,
            this.mCurrZoomData.totalSize,
            { x: aX, y: aY },

            aColor);
    }
    //__________________________________________________________________________________________
    private _drawCross(pCtx: CanvasRenderingContext2D,
        pSize: iSize,
        pMousePoint: iPoint2D,

        pColor: string) {

        //vertical line
        pCtx.beginPath();
        pCtx.strokeStyle = pColor;
        pCtx.lineWidth = 0.5;
        pCtx.setLineDash([5, 5]);
        pCtx.moveTo(pMousePoint.x, 0);
        pCtx.lineTo(pMousePoint.x, pSize.height);
        pCtx.stroke();

        //horizontal line
        pCtx.beginPath();
        pCtx.strokeStyle = pColor;
        pCtx.lineWidth = 0.5;
        pCtx.setLineDash([5, 5]);
        pCtx.moveTo(0, pMousePoint.y);
        pCtx.lineTo(pSize.width, pMousePoint.y);
        pCtx.stroke();
    }
    //__________________________________________________________________________________________
    private _setSpotCursor(e: MouseEvent, pColor: string) {
        const aMouseX = e.clientX;
        const aMouseY = e.clientY;
        this.mCursors[anSpotGraph.SPOT_CURSOR_ID].style.top = aMouseY - 15 + "px";
        this.mCursors[anSpotGraph.SPOT_CURSOR_ID].style.left = aMouseX + "px";
        this.mCursors[anSpotGraph.SPOT_CURSOR_ID].style.backgroundColor = pColor;
        ViewUtils.setElementVisibilityByDNone(this.mCursors[anSpotGraph.SPOT_CURSOR_ID], true);
    }
    //__________________________________________________________________________________________
    protected _onDownloadXLSX(): void {
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: this.mData.cacheData.name,
            Author: Op3dContext.USER_VO.userVO.name.first + " " + Op3dContext.USER_VO.userVO.name.last,
            CreatedDate: new Date()
        };

        const aBase = this.getXLSXHeader();

        let aData = AnalysisCache.getAllDataByQuery({
            name: this.mData.cacheData.name,
            polarization: ePolarizationSourceType.X,
            id: this.mData.analysisItem.id,
            wavelengths: [],
            polarizationKind: ePolarizationSourceType.X
        }, false);

        for (let i = 0; i < aData.length; i++) {
            let aSheetName = aData[i].common_data.wavelength.toString()
            aBase[4] = ["Wavelength [nm]", aSheetName];
            this._addSheet(wb, aSheetName, aBase, aData[i].matrix.working.matrix);
        }

        let aWBOutBinary = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        let aBuffer = new ArrayBuffer(aWBOutBinary.length);
        let aView = new Uint8Array(aBuffer);
        for (let i = 0; i < aWBOutBinary.length; i++) {
            aView[i] = aWBOutBinary.charCodeAt(i) & 0xFF;
        }

        saveAs(new Blob([aBuffer], { type: "application/octet-stream" }),
            this.mData.cacheData.name + ".xlsx");

    }
    //__________________________________________________________________________________________
}