import classes from "../../UI/optichat-tabs.module.scss";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { iOptiChatTabData } from "./OptiChatTabs";
import OptiChatContext, {
  eOptiChatTabActionType,
} from "../../store/OptiChatContext";
import { OpticsMenu } from "../../../ui/menus/_search/OpticsMenu";
import { ViewUtils } from "../../../ui/ViewUtils";
import { OptiChatTabUtils } from "../../utils/OptiChatTabUtils";

const OptiChatSingleTab = (props: { data: iOptiChatTabData }) => {
  const { data, setTabs, tabs } = useContext(OptiChatContext);
  const tooltipRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const textInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    ViewUtils.initTooltips();
  }, []);

  const onDeleteTabHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    ViewUtils.disposeTooltip(tooltipRef.current);

    const updatedTabs = tabs.filter((tab) => tab.key !== props.data.key);

    data.current.changes.push({
      type: eOptiChatTabActionType.DELETE_TAB,
      deleted_tab: props.data.title,
    });

    const aIsRemovedActive = props.data.active === true;

    if (aIsRemovedActive) {
      const aNewActive = updatedTabs[updatedTabs.length - 1];
      aNewActive.active = true;

      const aNewChanges = OptiChatTabUtils.switchTab(
        data.current,
        aNewActive.title
      );

      data.current.changes = aNewChanges;
      OpticsMenu.instance.setFilters(aNewActive.requested_params);
      await OpticsMenu.instance._getResults(true, false);
    }

    setTabs([...updatedTabs]);
  };

  const onSaveName = () => {
    window.removeEventListener("mouseup", onClickWindow);
    setIsEdit(false);
    onChangeTextHandler();
  };
  const onClickWindow = useCallback((e) => {
    if (e.target !== textInputRef.current) {
      onSaveName();
    }
  }, []);

  const onDoubleClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isEdit === false) {
      window.addEventListener("mouseup", onClickWindow);
      setIsEdit(true);
    }
  };

  const onTabClickHandler = async () => {
    if (props.data.active === true) {
      return;
    }

    const aUpdatedTabs = [...tabs];
    aUpdatedTabs.forEach((item) => {
      item.active = item.title === props.data.title;
    });

    const aUpdatedChanges = OptiChatTabUtils.switchTab(
      data.current,
      props.data.title
    );

    data.current.changes = aUpdatedChanges;

    setTabs([...aUpdatedTabs]);
    OpticsMenu.instance.setFilters(props.data.requested_params);
    await OpticsMenu.instance._getResults(true, false);
  };

  const onChangeTextHandler = () => {
    const aNewTabName = textInputRef.current.value.trim();
    if (aNewTabName !== props.data.title && aNewTabName.length > 0) {
      const updatedTabs = [...tabs];

      data.current.changes.push({
        type: eOptiChatTabActionType.RENAME_TAB,
        from: props.data.title,
        to: aNewTabName,
      });

      const aIdx = updatedTabs.findIndex((tab) => tab.key === props.data.key);
      updatedTabs[aIdx].title = aNewTabName;

      setTabs([...updatedTabs]);
    }
  };

  const onKeyUpHandler = (e) => {
    if (e.key === "Enter") {
      onSaveName();
    }
  };

  return (
    <div
      data-testid="single-tab"
      ref={tooltipRef}
      data-toggle="tooltip"
      data-placement="top"
      title={props.data.title}
      className={`${classes.tab} ${
        tabs.length === 1 ? classes["only-child"] : ""
      } ${props.data.active && classes.active}`}
      onClick={onTabClickHandler}
    >
      <div
        className={classes["first-child"]}
        onDoubleClick={onDoubleClickHandler}
      >
        <div className={classes.avatar}>
          <img />
        </div>

        {isEdit && (
          <input
            ref={textInputRef}
            type="text"
            defaultValue={props.data.title}
            onKeyUp={onKeyUpHandler}
          />
        )}
        {!isEdit && <div className={classes.text}>{props.data.title}</div>}
        <div className={classes.close} onClick={onDeleteTabHandler}>
          <img />
        </div>
      </div>
    </div>
  );
};

export default OptiChatSingleTab;
