﻿import { eTextTransform } from "../../../_context/Enums";
import { iHash } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ViewUtils } from "../../ViewUtils";

export interface iOneColumnData {
    class_name: string;
    checked: boolean;
    isVisible: boolean;
    textTransform: eTextTransform;
}

export interface iColumnsData {
    data: iHash<iOneColumnData>;
    onChange: Function;
    container: HTMLElement;
}

export interface iOneColumnData {
    name: string;
    class_name: string;
    checked: boolean;
    isVisible: boolean;
    textTransform: eTextTransform;
    index: number;
}

export class MenuListColumnsManager {

    private mContainer: HTMLElement;
    private mOnChange: Function;
    private mColumnData: iHash<iOneColumnData> = {};
    private mSingleColumnsCheckbox: HTMLElement;

    constructor(pData: iColumnsData) {
        this.mContainer = pData.container;
        this.mOnChange = pData.onChange;

        this.init();

        this.updateData(pData.data);
    }
    //__________________________________________________________________________________________
    public distract() {

    }
    //__________________________________________________________________________________________
    private init() {
        this.mSingleColumnsCheckbox = Op3dUtils.getElementIn(this.mContainer,
            'single_column_item');
        ViewUtils.removeFromParent(this.mSingleColumnsCheckbox);
    }
    //__________________________________________________________________________________________
    public updateData(pData: iHash<iOneColumnData>) {
        let aStyle: string = "";
        for (let item in pData) {
            let aSingleColumnsCheckbox = this.mSingleColumnsCheckbox.cloneNode(true) as HTMLElement;
            let aClassName = pData[item].class_name;
            aSingleColumnsCheckbox.classList.add('dropdown_' + aClassName);
            aStyle += '.' + 'hide_' + aClassName + ' .' + 'dropdown_' + aClassName +
                '{display: none !important}';

            let aInput = Op3dUtils.getElementIn(aSingleColumnsCheckbox,
                'column_checkbox_input', true) as HTMLInputElement;

            aInput.addEventListener('change',
                (e: Event) => this.onChangeCheckbox(e, aInput, aClassName));

            aInput.checked = pData[item].checked != null ? pData[item].checked : false;
            let aLabel = Op3dUtils.getElementIn(aSingleColumnsCheckbox,
                'column_checkbox_label', true) as HTMLLabelElement;

            let aDisplayName = aClassName.replace(/-/g, ' ');
            aDisplayName = aDisplayName.replace(/_/g, ' ');
            aLabel.innerHTML = aDisplayName;
            //aLabel.htmlFor = aInput.id;
            aLabel.removeAttribute('htmlFor');


            let aTextTransform = (null != pData[item].textTransform) ?
                pData[item].textTransform : eTextTransform.SENTENCE_STYLE;

            ViewUtils.setTextTransform(aLabel, aTextTransform);
            aSingleColumnsCheckbox.addEventListener('click', () => {
                aInput.checked = !aInput.checked;
                aInput.dispatchEvent(new Event('change'));
            });


            this.mColumnData[aClassName] = pData[item];
            this.mContainer.appendChild(aSingleColumnsCheckbox);
        }

        var style = document.createElement('style');
        style.innerHTML = aStyle;
        document.getElementsByTagName('head')[0].appendChild(style);
    }
    //__________________________________________________________________________________________
    public show(pNames: Array<string>, pToShow: boolean) {
        for (let item in pNames) {
            let aName = pNames[item];
            let aCurrData = this.mColumnData[aName] as iOneColumnData;
            if (aCurrData != null) {
                aCurrData.isVisible = pToShow;
            }
        }

        if (this.mOnChange) {
            this.mOnChange(this.mColumnData);
        }
    }
    //__________________________________________________________________________________________
    private onChangeCheckbox(e: Event, pElement: HTMLInputElement, pName: string) {
        if (e != null) {
            e.stopPropagation();
        }

        this.mColumnData[pName].checked = pElement.checked;
        if (this.mOnChange) {
            this.mOnChange(this.mColumnData);
        }
    }
    //__________________________________________________________________________________________
}
