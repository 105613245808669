import { iAsyncCallback } from "../op3d/_context/_interfaces/Interfaces";

export class JSONLoader {


    public load(pPath: string, pParams: any = null): Promise<iAsyncCallback<any>> {
        return new Promise((resolve) => {
            var aHttpRequest = new XMLHttpRequest();

            aHttpRequest.open(pParams != null ? "POST" : "GET", pPath);

            aHttpRequest.onreadystatechange = function () {
                if (aHttpRequest.readyState === 4) {
                    if (aHttpRequest.status === 200) {
                        const result = aHttpRequest.responseText;

                        resolve({ success: true, data: result })

                    } else {
                        resolve({ success: false, data: "load failed. Path:" + pPath })

                    }
                } else {

                }
            };

            aHttpRequest.send(pParams);
        })
    }
}
