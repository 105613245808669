import { eErrorType } from "../../_context/Enums";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { AuthUtils } from "../../server/AuthUtils";
import { RegisteredServer } from "../../server/RegisteredServer";
import { ViewUtils } from "../ViewUtils";
import { Spinner } from "./Spinner";

export class AcceptTermsForm {

    private mErrorMessage2: HTMLElement;
    private mTermsBox: HTMLElement;
    private mCallback: Function;

    constructor(pCallback: Function) {
        this.mCallback = pCallback;
        this._init();
    }
    //__________________________________________________________________________________________
    private _init() {
        // show box
        this.mTermsBox = Op3dUtils.getElementIn(document.body, "updatedTerms_box");
        this.mTermsBox.classList.remove('d-none');

        let aAcceptTerms = Op3dUtils.getElementIn(document.body, "on-accept", true);
        aAcceptTerms.addEventListener('click', () => this.onAcceptedTerms());
        this.mErrorMessage2 = Op3dUtils.getElementIn(document.body, "login-err-message2", true);
    }
    //__________________________________________________________________________________________
    private distract() {
        this.mErrorMessage2 = null;
        ViewUtils.removeFromParent(this.mTermsBox);
        this.mTermsBox = null;
    }
    //__________________________________________________________________________________________
    private async onAcceptedTerms() {
        let aTermsOfUse = document.getElementById("terms_of_use");
        if ((aTermsOfUse as any).checked) {
            Spinner.instance.show();
            let aRes = await RegisteredServer.acceptTerms();

            if (aRes.success) {
                Spinner.instance.hide();
                this._onFinishRegistation();

            } else {
                MessagesHandler.ON_ERROR_PROGRAM({
                    error: aRes.data,
                    type: eErrorType.GENERAL,
                    redirectTo500: true
                });
                //window.location.href = server.ServerContext.base_link + "/error500.html";
            }

            return;
        }
        ViewUtils.setElementVisibilityByDNone(this.mErrorMessage2, true);
        this.mErrorMessage2.innerText = 'You have to accept the Terms and Conditions and the Privacy Policy.';
    }
    //__________________________________________________________________________________________
    private _onFinishRegistation() {

        let aCookie = Op3dUtils.getCookie(AuthUtils.TRANSFER_AFTER_REGISTRATION);
        if (aCookie != "") {
            Op3dUtils.deleteCookie(AuthUtils.TRANSFER_AFTER_REGISTRATION);
            window.location.replace(aCookie);
        } else {
            this.distract();
            this.mCallback();
        }

    }
    //__________________________________________________________________________________________
}
