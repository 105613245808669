import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iDownloadFullSetupData, iSetupParameters } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { PartsDataLoader } from "../../data/data_loader/PartsDataLoader";
import { iOptixPartJSON } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { NotificationCenter } from "../../ui/home/_notifications/NotificationCenter";
import { Part } from "../../parts/Part";
import { PartsCatalog } from "../../parts/_parts_assets/PartsCatalog";
import { Object3D } from "three";
import { ePartType, iPart } from "../../parts/PartInterfaces";
import { OpticsDataLoader } from "../../data/data_loader/OpticsDataLoader";
import { OpticsFactory } from "../../parts/optics/OpticsFactory";

export class PartsImporter {

    private mErrors: Array<string> = new Array<string>();
    //__________________________________________________________________________________________
    public async importScene(pSetupData: iDownloadFullSetupData,
        pAddToCurrent: boolean = false) {
        let aParts: Array<iOptixPartJSON>;
        try {
            aParts = JSON.parse(pSetupData.content) as Array<iOptixPartJSON>;
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);

            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.OPT_CONTENT_NOT_SUPPORTED
            });
            return;
        }

        await Op3dContext.DATA_MANAGER.prepareDataManager();
        await PartsDataLoader.instance.getAll()
        await this._importParts(aParts);
            /*await */   this._initParts(aParts, pAddToCurrent);

        if (false == pAddToCurrent) {
            this._initSetupParams(pSetupData.parameters);
        }
    }
    //__________________________________________________________________________________________
    public async initOnePart(pCurrPartData: iOptixPartJSON, pUseIDS: boolean, pAddToCurrent: boolean = false) {
        const aNumberID = pCurrPartData.number_id;
        const aPartOptions = pCurrPartData.partOptions;
        const aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pCurrPartData.id);

        let aCurrPart: Part;
        switch (aPartOptions.type) {
            case ePartType.CS:
                aCurrPart = new Part({ id: 'CS', options: { type: ePartType.CS } });
                break;
            case ePartType.GROUP:
                aCurrPart = new Part({
                    id: pCurrPartData.partIDs.name,
                    options: { type: ePartType.GROUP }
                });
                break;
            case ePartType.PARAXIAL_LENS:
                aCurrPart = new Part({
                    id: Strings.PARAXIAL_LENS_NAME,
                    options: {
                        type: ePartType.PARAXIAL_LENS
                    }
                });

                let aParaxialLensData = pCurrPartData.paraxialLensData;
                if (undefined === aParaxialLensData) {
                    Op3dContext.LOADED_PARTS++;
                    return null;
                }

                Op3dContext.PARTS_MANAGER.addParaxialLens(aCurrPart, aParaxialLensData);
                break;
            case ePartType.CAD_PART:
            case ePartType.BLACKBOX:
                if (undefined === aPartVO) {
                    this.mErrors.push("missing data of: " + pCurrPartData.id);
                    Op3dContext.LOADED_PARTS++;
                    return null;
                }

                if (false === aPartVO.isOptPart) {
                    this.mErrors.push("part cant be in opt: " + pCurrPartData.id);
                    Op3dContext.LOADED_PARTS++;
                    return null;
                }

                let aiPart = await PartsDataLoader.instance.getSingleFullData({
                    number_id: pCurrPartData.number_id
                });

                aCurrPart = new Part({
                    id: pCurrPartData.id,
                    number_id: pCurrPartData.number_id,
                    options: { type: aPartOptions.type }
                });
                aCurrPart.add(aiPart);
                break;
            case ePartType.CATALOG_OPTICS:
                aCurrPart = await this._getOpticsPart(aNumberID);
                if (null === aCurrPart) {
                    Op3dContext.LOADED_PARTS++;
                    return null;
                }

                break;
            case ePartType.DYNAMIC_PART:
                aCurrPart = await PartsCatalog.instance.getDynamicPart(aPartVO);
                break;
            case ePartType.GENERAL:
                let aParts = await PartsDataLoader.instance.getSingleFullData({
                    number_id: aNumberID
                });

                aCurrPart = new Part({
                    id: pCurrPartData.id,
                    number_id: aNumberID,
                    options: { type: ePartType.GENERAL }
                }).add(aParts);

                break;
            default:
                Op3dContext.LOADED_PARTS++;
                return null;
        }

        //ERROR!!!!!
        Op3dContext.PARTS_MANAGER.addPart(aCurrPart);
        // pCurrPartData.partOptions.visible == true
        await aCurrPart.initFromJSON(pCurrPartData, pUseIDS, pAddToCurrent);
        aCurrPart.visibleObj.visible = pCurrPartData.partOptions.visible
        aCurrPart.iPart.partVO = aPartVO //!ADDED 23.01
        Op3dContext.LOADED_PARTS++;
        return aCurrPart;
    }
    //__________________________________________________________________________________________
    private async _getOpticsPart(pNumberID: string) {
        let aOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
            number_id: pNumberID
        });
        if (null === aOpticsVO) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.DEPRECATED_OPTIC,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
            this.mErrors.push(MessagesHandler.DEPRECATED_OPTIC);
            return null;
        }

        let aOpticsHolderObject3D = new Object3D();
        aOpticsHolderObject3D.name = Strings.OPTICS_HOLDER;

        let aOpticsHolderPart: iPart = {
            name: Strings.OPTICS_HOLDER,
            object3D: aOpticsHolderObject3D,
            internal_id: Op3dUtils.idGenerator()
        }

        let aOptics = OpticsFactory.createOpticalDevice(aOpticsVO);
        aOpticsHolderPart.object3D.add(aOptics.object3D);
        aOpticsHolderPart.subParts = [aOptics];

        let aPart = new Part({
            number_id: pNumberID,
            options: { type: ePartType.DYNAMIC_PART }
        }).add(aOpticsHolderPart);
        aPart.setPartLabel(aOpticsVO.name);

        return aPart;
    }
    //__________________________________________________________________________________________
    private _initParts(pParts: Array<iOptixPartJSON>, pAddToCurrent: boolean = false) {
        for (let i = 0; i < pParts.length; i++) {
            try {
                this.initOnePart(pParts[i], true, pAddToCurrent);
            } catch (error) {
                continue;
            }
        }
    }
    //__________________________________________________________________________________________
    private async _importParts(aParts: Array<iOptixPartJSON>) {
        let aInvalidParts: number = 0;

        for (let i = aParts.length - 1; i >= 0; i--) {
            const aCurrData = aParts[i];
            const aNumberID = aCurrData.number_id;

            switch (aCurrData.partOptions.type) {
                case ePartType.CAD_PART:
                case ePartType.BLACKBOX:
                    let aPartVO = Op3dContext.DATA_MANAGER.getPartVOByNumberID(aNumberID);
                    if (undefined === aPartVO) {
                        let aPart = await PartsDataLoader.instance.getSingleFullData({
                            number_id: aNumberID
                        });

                        if (null === aPart) {
                            aParts.splice(i, 1);
                            aInvalidParts++;
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        if (aInvalidParts > 0) {
            Op3dContext.TOTAL_PARTS -= aInvalidParts;
            NotificationCenter.instance.pushNotification({
                message: `${aInvalidParts} part/s in this setup are missing`,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
            });
        }
    }
    //__________________________________________________________________________________________
    private _initSetupParams(pSetupParams: iSetupParameters) {
        if (null == pSetupParams) {
            return null;
        }

        Op3dContext.SETUPS_MANAGER.setupParameters = pSetupParams;
    }
    //__________________________________________________________________________________________
}
