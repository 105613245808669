
export enum eLoadingState {
    ERROR,
    LOADING,
    FULL,
    EMPTY
}
//__________________________________________________________________________________________________
export enum eWavelengthUnit {
    NM = 0,
    MU = 1,
}
//__________________________________________________________________________________________________
export enum eErrorMessageType {
    FILE_READING_FAILED = "FILE_READING_FAILED",
    MISSING_COLUMNS = "MISSING_COLUMNS",
    ERROR_IN_ROWS = "ERROR_IN_ROWS",
    WL_DESCENDING_ORDER = "WL_DESCENDING_ORDER"
}
//__________________________________________________________________________________________________
export enum eOrientation {
    LANDSCAPE = "landscape",
    PORTRAIT = "portrait"
}
//__________________________________________________________________________________________________
// export enum eLaserMaskPattern {
//     NO_MASK = 0,
//     PI = 1,
//     FORM3D = 2,
//     TWO_DOTS = 3,
//     UP_ARROW = 4,
//     FULL_MATRIX = 5,
//     LETTER_R = 6,
//     CUSTOM = 7
// };
//__________________________________________________________________________________________________
export enum ePointSourceSpreadType {
    CIRCULAR = "CIRCULAR",
    RECTANGULAR = "RECTANGULAR"
}
//__________________________________________________________________________________________________
export enum eStateToAnalysis {
    FROM_SCENE = 'FROM_SCENE',
    FROM_PORTAL = 'FROM_PORTAL',
    ENABLE_ANALYSIS = 'ENABLE_ANALYSIS',
    DISABLE_ANALYSIS = "DISABLE_ANALYSIS"
}
//__________________________________________________________________________________________________
export enum eModalWindowState {
    RENAME = "RENAME",
    SAVE = "SAVE",
    NEW = '',
    DOWNLOAD = 'DOWNLOAD',
    COPY = 'COPY'
}
//__________________________________________________________________________________________________
export enum eOpenExistingSetup {
    LOCAL,
    CLOUD
}
//__________________________________________________________________________________________________
/**
 *@description Current scene camera type
 */
//__________________________________________________________________________________________________
export enum eCameraType {
    PERSPECTIVE,
    ORTHOGRAPHIC
}
//__________________________________________________________________________________________________
/**
 * @deprecated
 * this enum was in use in the old version 
 */
//__________________________________________________________________________________________________
export enum eBeamType {
    ONE_RAY,
    CIRCULAR,
    MATRIX
}
//__________________________________________________________________________________________________
export enum eTextTransform {
    NONE,
    CAPITALIZE,
    UPPERCASE,
    LOWERCASE,
    SENTENCE_STYLE
};
//__________________________________________________________________________________________________
export enum eRedirectUri {
    SIMULATION = "simulation",
    WEBSITE = "website",
    WAREHOUSE = "warehouse",
    PRICING = "pricing",
}
//__________________________________________________________________________________________________
export enum eErrorType {
    SETUP_INIT = "setup_init",
    SEQ = "seq",
    MODEL_LOADING = "model_loading",
    ASSET_LOADING = "asset_loading",
    WINDOW_ERROR = "window_error",
    PARTS_BEHAVIOR = "parts_behavior",
    SIMULATION = "simulation",
    FORMS = "forms",
    PART_INFO = "part_info",
    IMPORT_EXPORT_SETUP = "import_export_setup",
    INTERNAL_ERROR = "internal_error",

    UI = "ui",
    GENERAL = "general",
    AUTH = "auth",
    ELEMENT_CREATION = "element creation",
    FEATURES_BEHAVIOR = "features_behavior",
}
//__________________________________________________________________________________________________
export enum eCylindricalDirection {
    X = "X",
    Y = "Y"
}
//__________________________________________________________________________________________________
export enum eOpticsMeasurePoint {
    FRONT,
    BACK
}
//__________________________________________________________________________________________________
export enum eSetupType {
    USER,
    EXAMPLE,
    // WIDGET,
    // SNAPSHOT,

    // TUTORIAL,
    // TEST,
    // SCANNER
};
//__________________________________________________________________________________________________
export enum eDetectorImageType {
    DETECTOR,
    OPTICS
}
//__________________________________________________________________________________________________
export enum eBucketToRetrieve {
    GPU,
    S3
}
//__________________________________________________________________________________________________
export enum eGpuType {
    DEFAULT = 'g4',
    FAST = 'g5'
}
//__________________________________________________________________________________________________
export enum eFileType {
    OPT = ".opt",
    SEQ = ".seq",
    JSON = ".json",
    CSV = ".csv",
    PLY = ".ply",
    GLB = ".glb"
}
//__________________________________________________________________________________________________
export enum ePlaneType {
    HORIZONTAL,
    VERTICAL
}
//__________________________________________________________________________________________________
export enum eBrandTypes {
    OPTIC,
    LIGHT_SOURCE,
    OPTOMECHANIC
}
//__________________________________________________________________________________________________
export enum eIngredientType {
    OPTIC,
    SOURCE,
    OPTOMECHANIC
}
//__________________________________________________________________________________________________
export enum eLaserMaskPattern {
    NO_MASK = 0,
    PI = 1,
    FORM3D = 2,
    TWO_DOTS = 3,
    UP_ARROW = 4,
    FULL_MATRIX = 5,
    LETTER_R = 6,
    CUSTOM = 7
};
//__________________________________________________________________________________________________
/**
 * Enum of all possible types of elements in software
 */
//__________________________________________________________________________________________________
// export enum ePartType {
//     MOUNT,
//     DETECTOR,
//     DETECTOR_IMAGING,
//     FLOOR,
//     WALL,
//     RAIL,
//     BREADBOARD,
//     LASER,
//     ADAPTER,
//     WALL_ADAPTER,
//     CEILING,
//     MODULE,
//     THIRD_PARTY,
//     FLOOR_ADAPTER,
//     CEILING_ADAPTER,
//     LENS_TUBE,
//     MICROSCOPE,
//     IRIS,
//     LaserHelper,
//     UPPER_PART,
//     TRANSLATOR,
//     IRIS_DIAPHRAGM,
//     FIBER_ADAPTER,
//     OBJECTIVE,
//     BEAM_DUMPER,
//     BS_CUBE_HOLDER,
//     ISOLATOR,
//     SLM_MODULATOR,
//     AOM_MODULATOR,
//     MOUNT_ADAPTER,
//     ABSTRACT_MOUNT,
//     ABSTRACT_LASER,
//     SPECTROMETER,
//     PLACE_HOLDER,
//     GALVOMETER
// }
//__________________________________________________________________________________________________
// export enum eMaterialCheckType {
//     SHOW_SOURCES, // whether from sources and not show message
//     DONT_SHOW_SOURCES, // whether from sources and show message
//     SIMULATION // whether show simulation message
// }
//__________________________________________________________________________________________________
export enum eMaterialType {
    MAIN = 'Main',
    ORGANIC = 'Organic',
    GLASS = 'Glass',
    OTHER = 'Other',
    CRYSTALS = 'Crystals'
}
//__________________________________________________________________________________________________
export enum eMaterialEquationType {
    CONSTANT, //0
    SELLMEIER, //1
    MODIFIED_SELLMEIER, //2
    CAUCHY, // 3
    DRUDE_LORENTZ, //4
    CUSTOMIZED, // 5
    SCHOTT, //6

    CAUCHY2, //7
    CONRADY1, //8
    CONRADY2, //9
    HELMHOLTZ, //10
    HARTMANN1, //11
    HARTMANN2, //12
    HARTMANN3A, //13
    HARTMANN3B, //14
    HARTMANN4, //15
    HERZBERGER2X2, //16
    HERZBERGER3X2, //17
    HERZBERGER4X2, //18
    HERZBERGER3X3, //19
    HERZBERGER5X2, //20
    HERZBERGERJK, //21

    SCHOTT2, //22
    SCHOTT3, //23
    SCHOTT4, //24
    SCHOTT5, //25
    HO01, //26
    HO02, //27
    SELLMOD1, //28
    SELLMOD1A, //29
    SELLMOD2, //30
    SELLMOD2A, //31
    SELLMOD3, //32
    SELLMOD4, //33

    SELLMOD5, //34
    SELLMOD6, //35
    SELLMOD7, //36
    SELLMOD7A, //37
    SELLMOD8, //38
    SELLMOD9, //39
    KINGSLAKE1, //40
    KINGSLAKE2, //41
    MISK01, //42
    MISK02, //43
    MISK03, //44
    MISK04, //45
    MISK05 //46
};
//__________________________________________________________________________________________________
export enum ePolarizationSourceType {
    NONE,
    X,
    Y,
    Z
}
//__________________________________________________________________________________________________
export enum eChosenFileState {
    UPLOAD_FILE,
    BUILD_RESPONSE
};
//__________________________________________________________________________________________________
export enum eResultType {
    TRANSMISSION,
    REFLECTION
};
//__________________________________________________________________________________________________
export enum eDataPermission {
    PRIVATE,
    PUBLIC,
    WIDGET
}
//__________________________________________________________________________________________________
export enum eSidebarState {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE"
}
//__________________________________________________________________________________________________
export enum eLegType {
    OptixPost,
    ThirdParty
};
//__________________________________________________________________________________________________
export enum eDetectorSurfaceShape {
    CIRCULAR = 0,
    RECTANGULAR = 1
}
//__________________________________________________________________________________________________
export enum eOpticsType {
    CIRCLE,
    RECTANGLE,
    VOLUME,
    CIRCLE_NO_OFF_AXIS,
    RECT_VOLUME,
    BS_CUBE_TYPE,
    OPTICAL_DESIGN
};
//__________________________________________________________________________________________________
export enum ePARAM_TYPE {
    DIMENTIONAL_NUMBER,
    BOOLEAN,
    NON_DIMENTIONAL_NUMBER,
    ANGLE_RAD,
    ANGLE_DEG,
    Array,
    CUSTOM_NUMBER,
    PERCENTAGE_NUMBER,
    LIST
};
//__________________________________________________________________________________________________
export enum eScatteringType {
    SIMPLE,
    ADVANCED,
    USER_DEFINED
}
//__________________________________________________________________________________________________
export enum eScatteringSampling {
    UNIFORM = "UNIFORM",
    COSINE_WEIGHTED = "COSINE_WEIGHTED"
}
//__________________________________________________________________________________________________
export enum eScatteringModel {
    LAMBERTIAN = "LAMBERTIAN",
    GAUSSIAN = "GAUSSIAN",
    COS_NTH = "COS_NTH",
    ABG = "ABG"
}
//__________________________________________________________________________________________________
/**
 * @description being used in user settings
 */
//__________________________________________________________________________________________________
export enum eIntensityColorCount {
    NUMBER_3,
    NUMBER_10,
    CONTINUOUS,
    USER_DEFINED
}
//__________________________________________________________________________________________________
export enum eFACE {
    TOP,
    FRONT,
    RIGHT,
    LEFT,
    BACK,
    BOTTOM
}
//__________________________________________________________________________________________________
export enum eBomCategory {
    Optix3D = 0,
    THIRD_PARTY,
    GENERAL,
    Optics,
    Source,
    DETECTOR
}
//__________________________________________________________________________________________________
export enum eUnitType {
    INCHES,
    MILLIMETERS
}
//__________________________________________________________________________________________________
export enum eSceneMode {
    AUTO,
    PAN,
    SPECTRUM,
    MEASURES,
    RULER,
    TPM, //Two Points Measure.
    VIDEO,
    IMAGING,
    FOCUSING,
    ANALYSIS,
    SCANNING
};
//__________________________________________________________________________________________________
export enum eUserType {
    BASIC,
    ADMIN,
    EMPLOYEE,
    PREMIUM,
};
//__________________________________________________________________________________________________
export enum eSimulationEvents {
    SERVER_EVENT = 'ServerEvent',
    CLIENT_EVENT = 'ClientEvent',
    CLIENT_GENERAL_EVENT = 'ClientGeneralEvent',
};
//__________________________________________________________________________________________________
export enum eCadEvents {
    SERVER_EVENT = 'CadServerEvent',
    CLIENT_EVENT = 'CadClientEvent',
};
//__________________________________________________________________________________________________
export enum eJobMessageType {
    UPLOAD = 'JOB_UPLOAD',
    CREATE = 'JOB_CREATE',
    SUBMIT = 'JOB_SUBMIT',
    PROGRESS = 'JOB_PROGRESS',
    PROCCESED = 'JOB_PROCCESED',
};
//__________________________________________________________________________________________________
export enum eJobStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
};
//__________________________________________________________________________________________________
export enum eMessageAction {
    SEND = 'sendmessage'
};
//__________________________________________________________________________________________________
export enum eSimulationType {
    VISUALIZATION = "VISUALIZATION",
    ANALYSIS = "ANALYSIS"
}
//__________________________________________________________________________________________________
export enum eGeneralWebsocketType {
    CLIENT_LINE_ITEMS_CHANGED = "ClientLineItemsChanged",
    CLIENT_TIMERS_CHANGED = "ClientTimersChanged",
    SETUP_IS_OPEN = "SETUP_IS_OPEN"
}
//__________________________________________________________________________________________________
export enum eSimulationWebsocketEventType {
    SERVER_INIT = 'ServerSimulationInit',
    SERVER_START = 'ServerSimulationStart',
    SERVER_STOP = 'ServerSimulationStop',
    SERVER_PROGRESS = 'ServerSimulationProgress',
    CLIENT_READY = 'ClientSimulationReady',
    CLIENT_DONE = 'ClientSimulationDone',
    CLIENT_ERROR = 'ClientSimulationError',
    CLIENT_PROGRESS = 'ServerSimulationProgress',
    CLIENT_SETUP_VERSION_ERROR = 'CLIENT_SETUP_VERSION_ERROR'
}
//__________________________________________________________________________________________________
export enum eLineItem {
    STANDARD,
    ADVANCED,
    ACADEMIC
}
//__________________________________________________________________________________________________
export enum eAxis {
    x = "x",
    y = "y",
    z = "z"
}
//__________________________________________________________________________________________________
export enum eImageFileType {
    PNG = "png",
    JPEG = "jpeg"
}
//__________________________________________________________________________________________________
export enum ePolarizationType {
    P_POLARIZED = "P_POLARIZED",
    S_POLARIZED = "S_POLARIZED",
    UNPOLARIZED = "UNPOLARIZED",
    POLARIZED = "POLARIZED"
};
//__________________________________________________________________________________________________
export enum eCadTriangulationResolution {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH'
}
//__________________________________________________________________________________________________
export enum eCadType {
    OPTICS = 'OPTICS',
    OPTO_MECHANICS = 'OPTO_MECHANICS'
}
//__________________________________________________________________________________________________
export enum eNewPolarizationType {
    LINEAR = 'LINEAR',
    CIRCULAR_LEFT = 'CIRCULAR_LEFT',
    CIRCULAR_RIGHT = 'CIRCULAR_RIGHT',
    GENERAL_JONES = 'GENERAL_JONES',
    ELLIPTICAL = 'ELLIPTICAL',
    USER_DEFINED_JONES = 'USER_DEFINED_JONES',
    USER_DEFINED_STOKES = 'USER_DEFINED_STOKES',
}
//__________________________________________________________________________________________________
export enum ePolarizationFields {
    POLARIZATION_ANGLE = 'POLARIZATION_ANGLE',
    ECCENTRICITY = 'ECCENTRICITY',
    ELLIPTICITY = 'ELLIPTICITY',
    EXTINCTION_RATIO = 'EXTINCTION_RATIO',
    POLARIZATION_RATIO = 'POLARIZATION_RATIO',
    PRINCIPAL_X = 'PRINCIPAL_X',
    PRINCIPAL_Y = 'PRINCIPAL_Y',
    STOKE_1 = 'STOKE_1',
    STOKE_2 = 'STOKE_2',
    STOKE_3 = 'STOKE_3',
}
//__________________________________________________________________________________________________
export enum eAxisType {
    GENERAL,
    OPTICS,
    LASER,
    CAGE
}
//__________________________________________________________________________________________________
export enum eSeqGeometryTypes {
    CONIC //0
}
//__________________________________________________________________________________________________
export enum eMouseButtons {
    Main,
    Auxiliary,
    Secondary
}
//__________________________________________________________________________________________________
