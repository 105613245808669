import { iOptiChatTabData } from "../components/chat/OptiChatTabs";
import { eOptiChatTabActionType, iOptiChatContextData } from "../store/OptiChatContext";

export namespace OptiChatTabUtils {


    export function switchTab(pCurrentData: iOptiChatContextData,
        pTitle: string) {

        const aUpdatedChanges = pCurrentData.changes.filter(
            (change) => change.type !== eOptiChatTabActionType.SWITCH_TAB
        );

        if (pTitle !== pCurrentData.currTab) {
            aUpdatedChanges.push({
                type: eOptiChatTabActionType.SWITCH_TAB,
                current_tab: pTitle,
            });
        }

        return aUpdatedChanges;
    }
    //________________________________________________________________
    export function updateCurrTab(pCurrentData: iOptiChatContextData, pTab: iOptiChatTabData) {
        if (pTab !== undefined) {
            pCurrentData.currTab = pTab.title;
        }
    }
    //________________________________________________________________
}