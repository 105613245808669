import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ViewUtils } from "../ViewUtils";

export class anWavelengthsComponent {

    private mContainer: HTMLElement;
    private mSingleCbItem: HTMLElement;
    private mSingleRadioItem: HTMLElement;
    private mParentContainer: HTMLElement;
    private mItems: Array<{
        input?: HTMLInputElement,
        container: HTMLElement,
        wl: number
    }> = [];

    private mOnChange: (pWavelengths: Array<number>) => void;
    private mCheckAll: HTMLInputElement;
    private mIsCheckboxOptions: boolean;

    constructor(pContainer: HTMLElement, pOnChange: (pWavelengths: Array<number>) => void) {
        this.mContainer = pContainer;
        this.mOnChange = pOnChange;
        this._iniElements();
    }
    //__________________________________________________________________________________________
    private _iniElements() {
        this.mSingleRadioItem = Op3dUtils.getElementIn(this.mContainer, "single-radio-wl-item", true);
        this.mSingleCbItem = Op3dUtils.getElementIn(this.mContainer, "single-cb-wl-item", true);
        this.mParentContainer = this.mSingleCbItem.parentElement;
        ViewUtils.removeFromParent(this.mSingleCbItem);
        ViewUtils.removeFromParent(this.mSingleRadioItem);

        this.mCheckAll = Op3dUtils.getElementIn(this.mContainer, "all-wls") as HTMLInputElement;
        let aParent = this.mCheckAll.parentElement;
        aParent.addEventListener("click", (e) => this._onAllStateChanged(e));
    }
    //__________________________________________________________________________________________
    private _onAllStateChanged(e: MouseEvent) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }

        this.mCheckAll.checked = !this.mCheckAll.checked;

        for (let i = 0; i < this.mItems.length; i++) {
            this.mItems[i].input.checked = this.mCheckAll.checked;
        }

        this.mOnChange(this.getCheckedWL());
    }
    //__________________________________________________________________________________________
    public reset() {
        if (this.mIsCheckboxOptions) {
            this.mItems.forEach(item => item.input.checked = true);
        } else {
            // do nothing, keep it as it is

            // this.mItems[0].input.checked = true;
            // for (let i = 1; i < this.mItems.length; i++) {
            //     this.mItems[i].input.checked = false;
            // }
        }
    }
    //__________________________________________________________________________________________
    public clear() {
        ViewUtils.removeElementChildren(this.mParentContainer, 1);
        ViewUtils.setElementVisibilityByDNone(this.mParentContainer, false);

        while (this.mItems.length > 0) {
            this.mItems.pop();
        }
    }
    //__________________________________________________________________________________________
    public setData(pOptions: { wavelengths: Array<number>, isCheckAll: boolean, isCheckbox: boolean }) {
        this.clear();

        this.mIsCheckboxOptions = pOptions.isCheckbox;
        ViewUtils.setClassForElement(this.mContainer, "check-all", pOptions.isCheckAll);
        ViewUtils.setElementVisibilityByDNone(this.mParentContainer, true);

        let aGroupAddition = Op3dUtils.idGenerator();
        for (let i = 0; i < pOptions.wavelengths.length; i++) {

            let aTemplate = pOptions.isCheckbox ? this.mSingleCbItem : this.mSingleRadioItem;
            let aClone = aTemplate.cloneNode(true) as HTMLElement;
            this.mParentContainer.appendChild(aClone);
            let aInput: HTMLInputElement;
            let aLabel: HTMLLabelElement;

            if (pOptions.isCheckbox) {

                aInput = Op3dUtils.getElementIn(aClone, "wl-check", true) as HTMLInputElement;
                aClone.addEventListener("click", (e) => this._onClickCheckboxWL(e, aInput));
                aInput.checked = true;

                aLabel = Op3dUtils.getElementIn(aClone, "wl-check-label", true) as HTMLLabelElement;

            } else {

                aInput = Op3dUtils.getElementIn(aClone, "wl-radio", true) as HTMLInputElement;
                aClone.addEventListener("change", (e) => this._onWlRadioChange(e, pOptions.wavelengths[i]));
                if (i == 0) {
                    aInput.checked = true;
                } else {
                    aInput.checked = false;
                }

                aLabel = Op3dUtils.getElementIn(aClone, "wl-radio-label", true) as HTMLLabelElement;
                aInput.name = `${aInput.name} ${aGroupAddition}`;
            }

            aLabel.innerText = pOptions.wavelengths[i].toString();
            aLabel.htmlFor = aInput.id;
            this.mItems.push({ input: aInput, wl: pOptions.wavelengths[i], container: aClone });
        }
    }
    //__________________________________________________________________________________________
    private _onWlRadioChange(e: Event, pWl: number) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.mOnChange([pWl]);
    }
    //__________________________________________________________________________________________
    private _isAllWlChecked(): boolean {
        let aUnchckedWavelengthIdx = this.mItems.findIndex(item => item.input.checked == false);
        return (aUnchckedWavelengthIdx == -1);
    }
    //__________________________________________________________________________________________
    private _onClickCheckboxWL(e: MouseEvent, pCheckbox: HTMLInputElement) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        pCheckbox.checked = !pCheckbox.checked;
        let aWls = this.getCheckedWL();
        this.mOnChange(aWls);

        let aIsAllWavelengthsSaved = this._isAllWlChecked();
        this.mCheckAll.checked = aIsAllWavelengthsSaved;
    }
    //__________________________________________________________________________________________
    public getCheckedWL() {

        let aCheckedItems = this.mItems.filter(item => item.input.checked);
        let aWls = aCheckedItems.map(item => item.wl);
        return aWls;

    }
    //__________________________________________________________________________________________
}
