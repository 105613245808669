import { LightSourcesList } from "../../home/LightSourcesList";

export class LightSourceHeaderList extends LightSourcesList {

    constructor(pContainer: HTMLElement) {
        super(pContainer);
    }
    //__________________________________________________________________________________________

}
