import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iOP3DHTMLInputElement } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ServerContext } from "../../server/ServerContext";
import { ViewUtils } from "../ViewUtils";

export interface iRBElement {
    /**
     * @description Label of radio button.
     */
    label: string;

    /**
     * @description Value of the radio button. If not mention, it will be the same as label.
     */
    value?: any;

    /**
     * @description Determined if the radio button is enabled or not. Default - true;
     */
    enable?: boolean;

    /**
     * @description Determined if this option is for premium users only. If so and the it's a 
     *  basic user, it will be disabled and our overlay will be added.
     */
    isPremiumFeature?: boolean;
}

export interface iRBGroup<T = any> {
    /**
     * @description RB in the group.
     */
    radioButtons: Array<iRBElement>;

    /**
     * @description Funcions occures on change rb select.
     * 
     * @param pValue - value of chosen rb.
     */
    onChange?: (pValue: T) => any;

    /**
     * @description Gap between two radio buttons.
     */
    gap?: number;

    /**
     * @description If mentioned - this will be the default chosen rb, Otherwise, default will 
     *  be the first rb option.
     */
    defaultValue?: any;

    /**
     * @description If mentioned - this will be the title of the rb_group.
     */
    title?: string;

    styleOptions?: iRBStyleOptions;

    isColumn?: boolean;
}

export interface iRBStyleOptions {
    /**
     * The gap between each radio button. Default - 16px.
     */
    gap?: number;

    /**
     * Width of each radio button element. Default - auto.
     */
    width?: number;
}

export class RBGroup<T = any> {

    public static RB_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" 
    xmlns="http://www.w3.org/2000/svg"><rect x="3.5" y="3.5" width="17" height="17" rx="8.5" 
    fill="white" stroke="#23A7DE"/><rect class="rbc" x="7.5" y="7.5" width="9" height="9" rx="4.5" 
    fill="#23A7DE"/></svg>`;

    private mContainer: HTMLElement;

    //______________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pData?: iRBGroup<T>) {
        this.mContainer = pContainer;
        this.update(pData);
    }
    //______________________________________________________________________________________________
    public get value() {
        let aInput = $(this.mContainer).find('input[type=radio]:checked')[0] as
            iOP3DHTMLInputElement<T>;
        if (undefined === aInput) {
            return undefined;
        }

        return aInput.data;
    }
    //______________________________________________________________________________________________
    public setValue(pValue: T, pDispatchEvent: boolean = false) {
        let aChosenInput: iOP3DHTMLInputElement<T>;

        let aInputs = $(this.mContainer).find('input[type=radio]');
        for (let i = 0, l = aInputs.length; i < l; i++) {
            let aInput = aInputs[i] as iOP3DHTMLInputElement<T>;
            aInput.checked = (pValue === aInput.data);
            if (true === aInput.checked) {
                aChosenInput = aInput;
            }
        }

        if ((true === pDispatchEvent) && (undefined !== aChosenInput)) {
            aChosenInput.dispatchEvent(new Event('change'));
        }
    }
    //______________________________________________________________________________________________
    public show(pShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mContainer.parentElement, pShow);
    }
    //______________________________________________________________________________________________
    public update(pData: iRBGroup) {
        this._clear();
        if (undefined === pData) {
            return;
        }

        this.mContainer.removeAttribute('class');
        this.mContainer.classList.add('rb_cot');

        if (undefined !== pData.title) {
            let aTitle = document.createElement('div');
            aTitle.classList.add('title');
            aTitle.innerHTML = pData.title;
            this.mContainer.appendChild(aTitle);
        }

        let aParent = document.createElement('div');
        this.mContainer.appendChild(aParent);
        aParent.classList.add('rb_group');

        let aStyleOptions = ((undefined !== pData.styleOptions) ? pData.styleOptions : {});
        if (null !== aStyleOptions.gap) {
            aParent.style.gap = (aStyleOptions.gap + 'px');
        }

        let aRBID = Op3dUtils.idGenerator();
        let aIsPremiumUser = (false === Op3dContext.USER_PERMISSION.isFreeUser);
        let aAddUpgradePremiumButton = false;

        for (let i = 0; i < pData.radioButtons.length; i++) {
            let aRB = pData.radioButtons[i];

            let aRBElement = document.createElement('div');
            aRBElement.classList.add('rb_element');
            aRBElement.innerHTML += RBGroup.RB_SVG;

            let aInput = document.createElement('input') as iOP3DHTMLInputElement<T>;
            aInput.type = 'radio';
            aInput.name = aRBID;
            aInput.id = Op3dUtils.idGenerator();

            let aValue = (undefined !== aRB.value) ? aRB.value : aRB.label;

            let aDefValue = pData.defaultValue;
            aInput.checked = ((aDefValue === aValue) || ((undefined === aDefValue) && (0 === i)));
            aInput.data = aValue;

            if ((true === aRB.isPremiumFeature) && (false === aIsPremiumUser)) {
                aRBElement.classList.add('pr_dis');
                aRBElement.classList.add('premium-feature');
                aRBElement.classList.add('flex_right-middle');
                aAddUpgradePremiumButton = (false === pData.isColumn);
            } else {
                aInput.addEventListener('change', () => pData.onChange(aValue));
            }

            let aLabel = document.createElement('label');
            aLabel.innerHTML = aRB.label;
            aLabel.htmlFor = aInput.id;

            aRBElement.insertBefore(aInput, aRBElement.children[0]);
            aRBElement.appendChild(aLabel);

            if (undefined !== aStyleOptions.width) {
                aRBElement.style.width = aStyleOptions.width + 'px';
            }

            aParent.appendChild(aRBElement);
        }

        if (true === aAddUpgradePremiumButton) {
            let aPFButton = Op3dUtils.getPremiumButton(true);
            aParent.appendChild(aPFButton);
        } else {
            $(this.mContainer).find('.premium-feature').each((_index, element) => {
                if (document.body.classList.contains(Strings.BASIC_LICENSE)) {
                    (element as HTMLElement).onclick = () => window.open(ServerContext.pricing_base_link, '_blank');
                }
            });
        }

        if (true === pData.isColumn) {
            aParent.classList.add('rb_col');
        }
    }
    //______________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mContainer);

        this.mContainer.removeAttribute('class');
        this.mContainer.classList.add('rb_cot');
    }
    //______________________________________________________________________________________________
}