import { eIngredientType } from "../../../_context/Enums";
import { OpticsContext } from "../../../_context/OpticsContext";
import { ENVS, ServerContext } from "../../../server/ServerContext";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Popup } from "../Popup";

export class OpticsDeleter extends Op3dComponentBase<{}> {

    private mTypesSelect: HTMLSelectElement;
    private mSubtypesSelect: HTMLSelectElement;
    private mDeletePassword: HTMLInputElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/optics/optics_deleter.html'
        });
    }
    //__________________________________________________________________________________________
    private distract() {
        this.mTypesSelect = null;
        this.mSubtypesSelect = null;
        this.mDeletePassword = null;

        this.mContainer.parentElement.removeChild(this.mContainer);
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        this.distract();
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mDeletePassword = this._getPart("delete-password", true) as HTMLInputElement;
        this.mTypesSelect = this._getPart("types-select", true) as HTMLSelectElement;
        this.mTypesSelect.addEventListener("change", () => this._onTypeChanged());
        this.mSubtypesSelect = this._getPart("subtypes-select", true) as HTMLSelectElement;

        this._getPart("delete-optics-btn", true).addEventListener("click",
            () => this._onDelete());

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private async _onDelete() {
        let aPassword = this.mDeletePassword.value.trim();
        let aType = this.mTypesSelect.options[this.mTypesSelect.selectedIndex].value;
        let aSubType = this.mSubtypesSelect.options[this.mSubtypesSelect.selectedIndex].value;

        let aRes = await ServerContext.SERVER.deleteOpticsByQuery({
            ingredientType: eIngredientType.OPTIC,
            type: aType,
            subtype: aSubType,
            password: aPassword
        });

        if (aRes.success) {
            Popup.instance.open({ text: "Deleted " + aRes.data + " elements" });
        } else {
            Popup.instance.open({ text: "Error occurred, see error in console" });
            console.log("Deleter error", aRes.data);
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen(_pData?: {}): void {
        ViewUtils.clearElementsChildren(this.mTypesSelect);
        ViewUtils.clearElementsChildren(this.mSubtypesSelect);

        const aTypes = OpticsContext.OPTICS_DATA.map(item => item.type);
        this._fillSelect(this.mTypesSelect, aTypes);
        this.mTypesSelect.selectedIndex = 0;
        this._onTypeChanged()
    }
    //__________________________________________________________________________________________
    private _fillSelect(pSelect: HTMLSelectElement, pData: Array<string>) {
        for (let i = 0; i < pData.length; i++) {
            let aOption = document.createElement("option") as HTMLOptionElement;
            aOption.value = pData[i];
            aOption.innerText = pData[i];
            pSelect.add(aOption);
        }
    }
    //__________________________________________________________________________________________
    private _onTypeChanged() {
        let aType = this.mTypesSelect.options[this.mTypesSelect.selectedIndex].value;
        const aSubtypesItems = OpticsContext.OPTICS_DATA.find(data => data.type == aType);
        ViewUtils.clearElementsChildren(this.mSubtypesSelect);
        const aSubtypes = aSubtypesItems.subtypes.map(subtype => subtype.name);
        this._fillSelect(this.mSubtypesSelect, aSubtypes);
    }
    //__________________________________________________________________________________________
}
