﻿import { EventManager } from "../../../../../oc/events/EventManager";
import { EventsContext } from "../../../../_context/EventsContext";
import { MessagesHandler } from "../../../../_context/MessagesHandler";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { iCoatingVO, iHash } from "../../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { CoatingDataLoader } from "../../../../data/data_loader/CoatingDataLoader";
import { Op3dComponentBase } from "../../../Op3dComponentBase";
import { ViewUtils } from "../../../ViewUtils";
import { CoatingChart } from "../../../charts/CoatingChart";
import { Popup } from "../../Popup";
import { eCoatingTypes, tCoatingType } from "../esCoating";
import { uoSection } from "../uoSection";
import { UploadCoatingFileForm } from "./UploadCoatingFileForm";
import { eCoatedState, eCoatedType } from "./UploadOpticsContext";


export class uoCoatingInfoNew extends uoSection<{ opticsVO: iOpticsVO, originalName: string }> {


    private static SKIN_PATH = './skins/forms/optics/uo_coating_info_new.html';
    private mCoatingProps!: HTMLElement;
    private mCoatingTypeGroup: Array<HTMLInputElement> = [];
    private mIsCoatedGroup: Array<HTMLInputElement> = [];
    private mPresetCoatings!: HTMLSelectElement;
    private mCoatingChartBtn!: HTMLElement;
    private mCustomUploadBtn!: HTMLElement;
    private mRemoveCustomBtn2!: HTMLElement;
    private mCoatingSpanName!: HTMLElement;

    private mParsedCoatings: iHash<iCoatingVO> = {};
    private mOriginalName!: string;
    private mCoatingState: iHash<string | undefined> = {}

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoCoatingInfoNew.SKIN_PATH,
            title: 'Coating Info',
            collapseQaId: "uo_coating_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: false
        });
    }
    //__________________________________________________________________________________________
    private _onParseCoating(pCoating: iCoatingVO) {

        this._setCoatingBtn(true)


        this.mParsedCoatings[this.mOriginalName] = pCoating;
        CoatingDataLoader.instance.addItemManually(pCoating);
        this.mCoatingSpanName.innerText = pCoating.name;
        this._onChangePreset();
    }

    //__________________________________________________________________________________________
    private _onClickCustomFile() {

        if (Op3dContext.USER_VO.isBasicLicense == true) {
            return;
        }
        UploadCoatingFileForm.instance.open((pCoating: iCoatingVO) => this._onParseCoating(pCoating));
    }
    //__________________________________________________________________________________________
    private async _showCoatingChart() {

        let aCoatingNumberID = this.mCoatingState[this.mOriginalName];

        if (aCoatingNumberID !== undefined) {
            let aCoatingVO = await CoatingDataLoader.instance.getSingleFullData({ number_id: aCoatingNumberID });
            if (aCoatingVO == null) {
                Popup.instance.open({
                    text: MessagesHandler.MISSING_COATING_DATA
                });
                return
            }
            CoatingChart.instance.open(aCoatingVO)
        }
    }
    //______________________________________________________________________________________________
    private _onRemoveCoating() {

        this._setCoatingBtn(false)

        ViewUtils.setElementAttribute(this.mRemoveCustomBtn2, "number_id");
        this.mCoatingSpanName.innerText = "";


    }
    //______________________________________________________________________________________________
    protected async _initElements(): Promise<void> {

        this.mCoatingChartBtn = this._getPart("coating-chart", true);
        this.mCoatingChartBtn.addEventListener("click", () => this._showCoatingChart());

        this.mCoatingSpanName = this._getPart("coating-name", true);
        this.mRemoveCustomBtn2 = this._getPart("remove-coating-btn", true);
        this.mRemoveCustomBtn2.addEventListener("click", () => this._onRemoveCoating());

        this.mCustomUploadBtn = this._getPart("custom-file-btn", true);
        this.mCustomUploadBtn.addEventListener("click", () => this._onClickCustomFile());

        this.mPresetCoatings = this._getPart("preset-coatings", true) as HTMLSelectElement;
        this.mPresetCoatings.addEventListener("change", () => this._onChangePreset());
        this.mCoatingProps = this._getPart("coating-props", true);

        $(this.mContainer).find('input[type="radio"][name="coating-face-state"]').each(
            (_index, element) => {

                let aLabel = Op3dUtils.getElementInByAttr(this.mContainer, "for", element.id) as HTMLLabelElement;
                element.id += Op3dComponentBase.ID_ITERATOR++;
                aLabel.htmlFor = element.id;

                this.mIsCoatedGroup.push(element as HTMLInputElement);

                element.addEventListener('change',
                    () => this._onIsCoatedChanged((element as HTMLInputElement).value == eCoatedState.COATED, true));
            });

        $(this.mContainer).find('input[type="radio"][name="coating-type"]').each(
            (_index, element) => {

                let aLabel = Op3dUtils.getElementInByAttr(this.mContainer, "for", element.id) as HTMLLabelElement;
                element.id += Op3dComponentBase.ID_ITERATOR++;
                aLabel.htmlFor = element.id

                this.mCoatingTypeGroup.push(element as HTMLInputElement);
                element.addEventListener('change', () => {
                    let aVal = (element as HTMLInputElement).value as eCoatedType;
                    this._changeCoatingType(aVal, true);
                });
            });
    }
    //__________________________________________________________________________________________
    private _onIsCoatedChanged(pIsCoated: boolean, pToUpdate: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mCoatingProps, pIsCoated);
        if (pToUpdate) {
            this._onChangePreset();
        }
    }
    //__________________________________________________________________________________________
    private _setIsCoatedState(pType: eCoatedState) {
        for (let i = 0; i < this.mIsCoatedGroup.length; i++) {
            const aElement = this.mIsCoatedGroup[i]
            const aVal = aElement.value as eCoatedState;
            if (aVal == pType) {
                aElement.checked = true;
            }
        }
        this._onIsCoatedChanged(pType == eCoatedState.COATED, false);
    }
    //__________________________________________________________________________________________
    private _onChangePreset() {
        this.mCoatingState[this.mOriginalName] = this._getCoatingNumberID()
        EventManager.dispatchEvent(EventsContext.OPTICS_VO_COATING_CHANGED, this);
    }
    //__________________________________________________________________________________________
    private _changeCoatingType(pType: eCoatedType, pToUpdate: boolean) {

        for (let i = 0; i < this.mCoatingTypeGroup.length; i++) {
            const aElement = this.mCoatingTypeGroup[i]
            const aVal = aElement.value as eCoatedType;
            if (aVal == pType) {
                aElement.checked = true;
            }
        }

        $(this.mContainer).find('[coating-type]').each(
            (_index, element) => {
                let aAttr = element.getAttribute("coating-type");
                ViewUtils.setElementDisabled(element, aAttr != pType);
            });

        if (pToUpdate) {
            this._onChangePreset();
        }
    }
    //__________________________________________________________________________________________
    public fillAttachments(pOpticsVO: iOpticsVO, pCoatingAttachments: iHash<iCoatingVO>, pIsNewInstance: boolean) {
        for (let key in pOpticsVO.parameters.coating) {
            if (this.mParsedCoatings[key] != null) {
                pCoatingAttachments[key] = this.mParsedCoatings[key];

            } else if (pIsNewInstance) {
                if (this._isCoatingPreset(pOpticsVO.parameters.coating[key])) {
                    continue;
                }

                pCoatingAttachments[key] = CoatingDataLoader.instance.getFromCache(pOpticsVO.parameters.coating[key]) as iCoatingVO;
            }
        }
    }
    //__________________________________________________________________________________________
    private _isCoatingPreset(pCoatingNumberID: string) {
        const aPresetKeys = Object.values(eCoatingTypes);
        return aPresetKeys.indexOf(pCoatingNumberID as eCoatingTypes) != -1;
    }
    //__________________________________________________________________________________________
    private _getCoatingNumberID(): string | undefined {
        let aCheckedItem = this.mIsCoatedGroup.find(item => item.checked == true);
        if (aCheckedItem === undefined) {
            throw new Error("No coating selected");
        }

        let aCoatedState = aCheckedItem.value as eCoatedState;
        if (aCoatedState == eCoatedState.COATED) {
            let aCoatingTypeGroup = this.mCoatingTypeGroup.find(item => item.checked == true);
            let aChoice: eCoatedType;

            if (aCoatingTypeGroup === undefined) {
                this._changeCoatingType(eCoatedType.PRESET, false);
                // throw new Error("No coating type selected");
                aChoice = eCoatedType.PRESET;

            } else {
                aChoice = aCoatingTypeGroup.value as eCoatedType;
            }

            switch (aChoice) {
                case eCoatedType.CUSTOM:
                    if (this.mParsedCoatings[this.mOriginalName] != null) {
                        return this.mParsedCoatings[this.mOriginalName].number_id;
                    }

                    let aCoatingNumberID = this.mRemoveCustomBtn2.getAttribute("number_id");
                    return (aCoatingNumberID !== null ? aCoatingNumberID : undefined);

                case eCoatedType.PRESET:
                    let aOption = this.mPresetCoatings.value as tCoatingType;
                    const aPresetNumberID = eCoatingTypes[aOption];
                    return aPresetNumberID;
            }

        } else {
            return undefined;
        }
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO): void {

        if (this.mOriginalName == null) {
            return;
        }

        if (pOpticsVO.parameters.coating == undefined) {
            pOpticsVO.parameters.coating = {};
        }

        for (let face in this.mCoatingState) {
            const aNumberID = this.mCoatingState[face];
            if (aNumberID === undefined) {
                delete pOpticsVO.parameters.coating[face];
            } else {
                pOpticsVO.parameters.coating[face] = aNumberID;
            }

        }
    }
    //__________________________________________________________________________________________
    public clear() {
        this.mCoatingState = {}
        this.mParsedCoatings = {};
        this.mPresetCoatings.selectedIndex = 0;
        this._setCoatingBtn(false)

    }
    //__________________________________________________________________________________________
    private _setCoatingBtn(pIsCustomCoated: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mRemoveCustomBtn2, pIsCustomCoated);
        ViewUtils.setElementVisibilityByDNone(this.mCustomUploadBtn, !pIsCustomCoated);
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: { opticsVO: iOpticsVO, originalName: string }) {

        if (pData === null) {
            this.show(false);
            return;
        }

        this.mOriginalName = pData.originalName;
        this.show(true);

        let aCoatingData = pData.opticsVO.parameters.coating != null ? pData.opticsVO.parameters.coating[pData.originalName] : undefined;
        let aPrevData = this.mCoatingState[this.mOriginalName];
        if (aPrevData !== undefined) {
            aCoatingData = aPrevData;
        }

        ViewUtils.setElementAttribute(this.mRemoveCustomBtn2, "number_id");
        this._setCoatingBtn(false)


        if (aCoatingData === undefined) {
            // uncoated
            this._setIsCoatedState(eCoatedState.UNCOATED);
            this.mPresetCoatings.selectedIndex = 0;
            delete this.mCoatingState[this.mOriginalName];
        } else {

            // coated
            this._setIsCoatedState(eCoatedState.COATED);
            const aPresetKeys = Object.values(eCoatingTypes);
            const aPresetIndex = aPresetKeys.indexOf(aCoatingData as any);
            const aIsPreset = aPresetIndex != -1;
            const aCoatedType = aIsPreset ? eCoatedType.PRESET : eCoatedType.CUSTOM;

            this._changeCoatingType(aCoatedType, false);
            if (aIsPreset) {


                this.mPresetCoatings.value = Object.keys(eCoatingTypes)[aPresetIndex];
                this.mCoatingState[this.mOriginalName] = aPresetKeys[aPresetIndex];

            } else {
                let aCoatingData2 = CoatingDataLoader.instance.getFromCache(aCoatingData) as iCoatingVO;
                let aCoatingName: string = "";
                let aNumberID: string | undefined;
                if (aCoatingData2 === null) {
                    aCoatingName == "Custom coating";
                } else {
                    aNumberID = aCoatingData2.number_id;
                    aCoatingName = aCoatingData2.name;
                }

                let aCustomFileName = this.mParsedCoatings[this.mOriginalName] != null ?
                    this.mParsedCoatings[this.mOriginalName].name : aCoatingName;
                if (Op3dContext.USER_VO.isBasicLicense == true) {
                    aCustomFileName = "Custom coating";
                    this.mCustomUploadBtn.style.pointerEvents = "none";
                }

                this._setCoatingBtn(true);
                this.mCoatingSpanName.innerText = aCustomFileName;
                ViewUtils.setElementAttribute(this.mRemoveCustomBtn2, "number_id", aNumberID);
                this.mCoatingState[this.mOriginalName] = aNumberID;
                this.mPresetCoatings.selectedIndex = 0;
            }
        }
    }
    //__________________________________________________________________________________________
}
