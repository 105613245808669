import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { LaserBehavior } from "../../parts/behaviors/LaserBehavior";
import { ViewUtils } from "../ViewUtils";
import { LightSourcesList } from "../home/LightSourcesList";

export class LightSourcesListAnalysisPortal extends LightSourcesList {

    private mTitle: HTMLElement;
    private mCheckAll: HTMLInputElement;

    constructor(pContainer: HTMLElement) {
        super(pContainer)
    }
    //__________________________________________________________________________________________
    public removeByInternalId(pInternalId: string) {
        let aIdx = this.mItems.findIndex(item => item.internalId == pInternalId);
        if (aIdx != -1) {
            ViewUtils.removeFromParent(this.mItems[aIdx].container);
            this.mItems.splice(aIdx, 1);
        }
    }
    //__________________________________________________________________________________________
    protected _initElements() {
        super._initElements();

        this.mCheckAll = Op3dUtils.getElementIn(this.mContainer, "all-ls", false) as HTMLInputElement;
        let aAllLAbel = Op3dUtils.getElementIn(this.mContainer, "all-ls-label", true) as HTMLLabelElement;
        aAllLAbel.htmlFor = this.mCheckAll.id;

        this.mCheckAll.addEventListener("click", (_e) => this._onAllStateChanged());
        this.mTitle = Op3dUtils.getElementIn(this.mContainer, "ls-title", true);
    }
    //__________________________________________________________________________________________
    private _updateTitle() {
        let aText: string;
        let aCheckedItems = this.mItems.filter(item => item.checkbox.checked == true).length;

        if (this.mCheckAll == null) return
        if (aCheckedItems == this.mItems.length) {
            // if all are selected
            aText = "Light sources (all)"
            this.mCheckAll.checked = true
        } else {
            aText = "Light sources (" + aCheckedItems + ")"
            this.mCheckAll.checked = false
        }


        this.mTitle.innerText = aText;

    }
    //__________________________________________________________________________________________
    private _onAllStateChanged() {
        for (let i = 0; i < this.mItems.length; i++) {
            this.mItems[i].checkbox.checked = this.mCheckAll.checked;
            if (this.mCheckAll.checked) {

                if (!this.mItems[i].checkbox.checked) {
                    this.mItems[i].ls_item.dispatchEvent(new Event('click'));
                }
            } else {
                if (this.mItems[i].checkbox.checked) {
                    this.mItems[i].ls_item.dispatchEvent(new Event('click'));
                }
            }
        }

        this._updateTitle();
    }
    //__________________________________________________________________________________________
    public reset() {
        super.reset(1);
        this._updateTitle();
    }
    //__________________________________________________________________________________________
    public addLightSource(aLaserBehavior: LaserBehavior, pPart: Part) {
        super.addLightSource(aLaserBehavior, pPart);
        this._updateTitle();
    }
    //__________________________________________________________________________________________
    public get internalIds() {
        let aRes = this.mItems.map(item => item.internalId);
        return aRes;
    }
    //__________________________________________________________________________________________
    protected _lighSourceStateChanged(e: Event,
        pLaserBehavior: LaserBehavior,
        pCheckbox: HTMLInputElement) {

        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        super._lighSourceStateChanged(e, pLaserBehavior, pCheckbox);
        this._updateTitle()
    }
    //__________________________________________________________________________________________

}
