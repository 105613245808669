import { Vector3, Mesh, SphereGeometry, MeshBasicMaterial, Intersection, Matrix4, ArrowHelper, CylinderGeometry, BufferGeometry, LineDashedMaterial, Line, Line3 } from "three";
import { EventManager } from "../../../oc/events/EventManager";
import { eAxisType, eDataPermission, ePlaneType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { iClientPoint } from "../../_context/_interfaces/Interfaces";
import { OptixPartDisplayer } from "../../parts/_parts_assets/OptixPartDisplayer";
import { SceneContext } from "../../scene/SceneContext";
import { ChoosePartMode } from "../forms/ChoosePartMode";
import { ColorPicker } from "../forms/ColorPicker";
import { Measurement } from "../forms/Measurement";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { eNotificationToastDuration, eNotificationType } from "../home/_notifications/NotificationsContext";
import { Group } from "../forms/Group";
import { PartsDataLoader } from "../../data/data_loader/PartsDataLoader";
import { ServerContext } from "../../server/ServerContext";
import { iEventData } from "../../parts/base_3d/Button3D";
import { OptomechanicsSurfaceManager } from "../forms/optics/OptomechanicsSurfaceManager";
import { Part } from "../../parts/Part";
import { iRefCS, iPartChange, eChangesType, iPart, iPartMongoDB, iAssemblyMongoDB, iFaceDataNEW, ePartType } from "../../parts/PartInterfaces";
import { OptomechanicsSolidsForm } from "../forms/optics/OptomechanicsSolidsForm";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { RaysDisplayer } from "../../simulation/RaysDisplayer";
import { setPartToBB } from "../../parts/BlackBox/utils/BlackBoxUtils";
import { ePhase, AssemblyMode } from "../forms/AssemblyMode";
import { CSMode, eDirection } from "../forms/CSMode";
import { piMoveRotateSection } from "../part_info/piMoveRotateSection";

export interface iEdgeSelectionData {
    radius: number,
    center: Vector3,
    dist: number,
    start: Vector3,
    end: Vector3,
    direction: Vector3
}

export enum eClickMode {
    /**
     * General selection mode (select parts).
     */
    BASE = "BASE",

    /**
     * Mode to choose working coordinate system of part.
     */
    LCS = "LCS",
    /**
     * Mode to choose referance to the working coordinate system of part.
     */
    LCS_REF = "LCS_REF",
    /**
    * Show edges on scene
    */
    EDGES = "EDGES",
    /**
    * Show vertices on scene
    */
    VERTICES = "VERTICES",
    /**
    * Show faces on scene
    */
    FACES = "FACES",

    CHANGE_FACE_PROPERTIES = "CHANGE_FACE_PROPERTIES",

    CHANGE_SOLIDS_MATERIAL = "CHANGE_SOLIDS_MATERIAL",
    /**
    * Show solids on scene
    */
    SOLIDS = "SOLIDS",
    /**
    * Measurement mode with choosing edges and vertices
    */
    MEASUREMENT = 'MEASUREMENT',
    /**
    * Editor mode creating group of parts
    */
    EDITOR = 'EDITOR',
    COLOR_FACE = 'COLOR_FACE',
    COLOR_PART = 'COLOR_PART',
    COLOR_SOLID = 'COLOR_SOLID',
    CS = 'CS',


    CHANGE_BASE = 'CHANGE_BASE',
    ADD_POST = 'ADD_POST',
    GROUP = 'GROUP',
    BB = 'BB',
    RAYS_POSITION = "RAYS_POSITION"
};



export class PartsEventsHandler {

    private mWorkingPart: Part;

    private mExitFunction: Function;
    private mModeFunction: Function;


    private mPreviousHighlighted: any;
    private mHighlightedParts: any = new Array<any>();

    private mMeasurePointMesh: Mesh;
    private mPointsOnScene: Array<Mesh> = new Array<Mesh>();
    private mChoosedPoints: Array<{ mesh: Mesh, vector: Vector3 }> = [];
    private mEdgesMeshes: Array<Mesh> = new Array<Mesh>();
    private mConnectLine?: Line;

    //__________________________________________________________________________________________
    public constructor() {
        //this.mStatusRow = document.getElementById('status_row');
        this._addEventListeners();
    }
    //__________________________________________________________________________________________
    private _addEventListeners() {
        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this.restoreToGeneralMode(), this);
    }
    //__________________________________________________________________________________________
    public changeMode(pMode: eClickMode, pUseExitFunc: boolean = true) {
        if (pMode == SceneContext.CHOOSE_MODE.mode) {
            return;
        }

        this.mWorkingPart = null;

        if ((true == pUseExitFunc) && (null != this.mExitFunction)) {
            this.mExitFunction();
            this.mExitFunction = null;
        }

        SceneContext.CHOOSE_MODE.mode = pMode;
        Op3dContext.INPUT_DISPATCHER.clickManager.clear();
        EventManager.dispatchEvent(EventsContext.MODE_CHANGED, this, pMode);
    }
    //__________________________________________________________________________________________
    public get mode() {
        return SceneContext.CHOOSE_MODE.mode;
    }
    //__________________________________________________________________________________________
    public restoreToGeneralMode(pUseExitFunction?: boolean) {
        if ((true == pUseExitFunction) && (null != this.mExitFunction)) {
            this.mExitFunction();
            this.mExitFunction = null;
        }
        this.changeMode(eClickMode.BASE);
        Op3dContext.INPUT_DISPATCHER.clickManager.enterGeneralMode();
    }
    //__________________________________________________________________________________________
    public async enterChooseLCSMode(pPart: Part) {
        await Op3dContext.PART_INFO.close();

        let aMsg = '<strong>Choose a local coordinate system (LCS) for this part.</strong><br>';
        aMsg += '<small style="display: block;">'
        aMsg += 'This part already has a default LCS. ';
        aMsg += 'A part might include more than one coordinate system (CS). ';
        aMsg += 'You can set as LCS any one of them by selecting the relevant CS from the ';
        aMsg += 'scene or from the left sidebar. ';
        aMsg += 'The newly selected LCS will represent this part in any rotation or placement ';
        aMsg += ' activity.<br>';
        aMsg += 'Press the ESC key to exit the selection mode.';
        aMsg += '</small>'

        let aNotification = await NotificationCenter.instance.pushNotification({
            message: aMsg,
            toastDuration: eNotificationToastDuration.INFINITE,
            params: {
                isColorBoxVisible: true,
                type: eNotificationType.CONSTRUCTION_COMMENT,
                title: 'Choose LCS',
                color: '#23A7DE'
            }
        });

        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
        this.changeMode(eClickMode.LCS);
        Op3dContext.INPUT_DISPATCHER.clickManager.enterAxisChoosingMode();

        let aSideBar = Op3dContext.DIV_CONTROLLER.sideBar;
        let aNavState = aSideBar.getNavigatorState();
        aSideBar.enterChooseLCSMode(pPart);

        this.mWorkingPart = pPart;

        this._setCSVisibility(false);
        this._setSpecificPartAxesVisibility(true, pPart);


        let aEscapeFunc = () => this.restoreToGeneralMode();
        let aKeyFunc = (e: KeyboardEvent) => {
            if (e.code != "Escape") {
                return;
            }

            aEscapeFunc();
        };


        window.addEventListener('keyup', aKeyFunc);

        this.mExitFunction = () => {
            NotificationCenter.instance.removeNotification(aNotification);
            this._setAllAxesVisibility(false);
            this._setCSVisibility(true);
            window.removeEventListener('keyup', aKeyFunc);
            aSideBar.setNavigatorByAttrName(aNavState);
            aSideBar.enableTabs(true);
            Op3dContext.PARTS_MANAGER.updatePartsList(false);
        };
    }
    //__________________________________________________________________________________________
    public async enterAddLCSMode(pPart: Part) {
        await Op3dContext.PART_INFO.close();

        EventManager.addEventListener(EventsContext.RESTORE_TO_BASE_MODE,
            () => this.restoreToGeneralMode(), this)

        this.changeMode(eClickMode.CS);
        Op3dContext.INPUT_DISPATCHER.clickManager.enterAddCSMode();

        let aSideBar = Op3dContext.DIV_CONTROLLER.sideBar;
        let aNavState = aSideBar.getNavigatorState();
        aSideBar.enterChooseLCSMode(pPart);

        this.mWorkingPart = pPart;
        Op3dContext.PARTS_MANAGER.setSelectedPart(null);

        let aKeyFunc = (e: KeyboardEvent) => {
            if (e.code != "Escape") {
                return;
            }
            this.restoreToGeneralMode();
        };

        window.addEventListener('keyup', aKeyFunc);
        // this._setAllAxesVisibility(true, pPart);

        this.mWorkingPart = pPart;

        this.mExitFunction = () => {

            // this._setAllAxesVisibility(false);
            window.removeEventListener('keyup', aKeyFunc);
            aSideBar.setNavigatorByAttrName(aNavState);
            aSideBar.enableTabs(true);

            CSMode.instance.closeTool()
            // CSMode.instance.close()
            ChoosePartMode.instance.leaveChoosePartMode()
            CSMode.instance.setCurrentCSMode(undefined)
            this._setAllAxesVisiblity(false);

            Op3dContext.PARTS_MANAGER.updatePartsList();
        };

        this._setAllAxesVisiblity(true);
        // this._setCSVisibility(false);
    }
    //__________________________________________________________________________________________
    private _setAllAxesVisiblity(pVisible: boolean) {
        Op3dContext.PARTS_MANAGER.parts.forEach((part) =>
            part.getAxes().forEach((axis) => axis.object3D.setVisibility(pVisible)));
    }
    //__________________________________________________________________________________________
    private _setCSVisibility(pToShow: boolean) {
        let aCS = Op3dContext.PARTS_MANAGER.parts.filter(
            (part) => (ePartType.CS == part.partOptions.type));
        for (let i = 0; i < aCS.length; i++) {
            aCS[i].getAxes().forEach((axis) => axis.object3D.setVisibility(pToShow, true));
        }
    }
    //__________________________________________________________________________________________
    public async enterChooseRefMode(pPart: Part, pPiMoveRotSection?: piMoveRotateSection) {
        this.changeMode(eClickMode.LCS_REF);
        Op3dContext.INPUT_DISPATCHER.clickManager.enterAxisChoosingMode();

        let aMsg = '<strong>Choose a reference coordinate system (CS) for this part.</strong>';
        aMsg += '<br><small style="display: block;">'
        aMsg += 'You can choose it either from the scene or from the left sidebar. ';
        aMsg += "After your selection, the part's local coordinate system (LCS) ";
        aMsg += "will be linked to the reference CS. ";
        aMsg += "This means that the part's location and rotation will be calculated ";
        aMsg += " relative to the reference CS.<br>";
        aMsg += 'Press the ESC key to exit the selection mode.';
        aMsg += '</small>'


        let aNotification = await NotificationCenter.instance.pushNotification({
            message: aMsg,
            toastDuration: eNotificationToastDuration.INFINITE,
            params: {
                isColorBoxVisible: true,
                type: eNotificationType.CONSTRUCTION_COMMENT,
                title: 'Choose reference CS',
                color: '#23A7DE'
            }
        });

        let aSideBar = Op3dContext.DIV_CONTROLLER.sideBar;
        let aNavState = aSideBar.getNavigatorState();
        aSideBar.enterChooseRefMode(pPart);

        this.mModeFunction = (pAxis: iRefCS) => {
            this.restoreToGeneralMode();
            Op3dContext.SCENE_HISTORY.addToHistory();
            pPart.setRefrence(pAxis);
            Op3dContext.SCENE_HISTORY.saveScene();
            OptixPartDisplayer.highlightObject(pPart);
            if (pPiMoveRotSection !== undefined) {
                pPiMoveRotSection.onChangeRefCS();
            }
            Op3dContext.PART_INFO.update();
        };

        let aKeyFunc = (e: KeyboardEvent) => {
            if (e.code != "Escape") {
                return;
            }

            this.restoreToGeneralMode();
        };

        window.addEventListener('keyup', aKeyFunc);
        this._setAllAxesVisibility(true, pPart);

        this.mWorkingPart = pPart;

        this.mExitFunction = () => {
            NotificationCenter.instance.removeNotification(aNotification);
            this._setAllAxesVisibility(false);
            window.removeEventListener('keyup', aKeyFunc);
            aSideBar.setNavigatorByAttrName(aNavState);
            aSideBar.enableTabs(true);
            if (pPiMoveRotSection !== undefined) {
                pPiMoveRotSection.onChangeRefCS(true);
            }
            Op3dContext.PARTS_MANAGER.updatePartsList();
        };
    }
    //__________________________________________________________________________________________
    private _setSpecificPartAxesVisibility(pToShow: boolean, pPart: Part) {
        pPart.getAxes().forEach((axis) => {
            let aToShow = ((true === pToShow) && (eAxisType.GENERAL === axis.type));
            axis.object3D.setVisibility(aToShow, true);
        });
    }
    //__________________________________________________________________________________________
    private _setAllAxesVisibility(pToShow: boolean, pSelectedPart?: Part,
        pForce: boolean = false) {

        let aParts = Op3dContext.PARTS_MANAGER.parts;
        let aLinkedParts = new Array<Part>();
        if ((null != pSelectedPart) && (null != pSelectedPart.linkedParts)) {
            aLinkedParts.push(...pSelectedPart.linkedParts);
        }

        for (let i = 0; i < aParts.length; i++) {
            /*
            * @description if part in group -> dont show axes and disable click events
            */
            if ((ePartType.GROUP === aParts[i].partOptions.type) || (ePartType.GROUP ===
                Group.instance.findMainGroupPart(aParts[i]).partOptions.type)) {
                aParts[i].clickEvents(!pToShow)
                continue
            }

            if ((pSelectedPart != aParts[i]) && (-1 == aLinkedParts.indexOf(aParts[i]))) {
                aParts[i].getAxes().forEach((axis) => {
                    let aToShow = (eAxisType.GENERAL === axis.type);
                    axis.object3D.setVisibility(aToShow, pForce);
                }
                );
            }
        }
    }
    //__________________________________________________________________________________________
    private _onSetLCS(pEventData: iEventData) {
        let aAxis = pEventData.axis;
        if (null == aAxis) {
            return;
        }

        let aPart = this.mWorkingPart;
        this.restoreToGeneralMode();

        Op3dContext.SCENE_HISTORY.addToHistory();
        aPart.setWorkingCS(aAxis);
        Op3dContext.SCENE_HISTORY.saveScene();
        Op3dContext.PARTS_MANAGER.updatePartsList();
        aPart.onSelect();
    }
    //__________________________________________________________________________________________
    private _onSetRef(pRefPart: Part, pEventData: iEventData) {
        if (null == pEventData.axis) {
            return;
        }

        if ((pRefPart != Op3dContext.GCS) && (pRefPart.refCS.refPart == this.mWorkingPart)) {
            return;
        }

        Op3dContext.SCENE_HISTORY.addToHistory();
        this._unHighlightAxis(pEventData);
        this.mModeFunction({
            cs: pEventData.axis,
            refPart: pRefPart
        } as iRefCS);

        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _highlightAxis(pEventData: iEventData) {
        if (null == pEventData.axis) {
            return;
        }
        document.body.classList.add("pointer");
        pEventData.axis.object3D.higlight();
    }

    //__________________________________________________________________________________________
    private _createAxisHelperPoint(pEvent, pEventData: iEventData) {
        // if (null == pEventData.axis) {
        //     return;
        // }
        let aAxisPoints = []

        if (pEventData.solid != null) {
            for (let face of pEventData.solid.faces) {
                let axisVector = new Vector3(face.visualization.mesh.matrixWorld.elements[12], face.visualization.mesh.matrixWorld.elements[13], face.visualization.mesh.matrixWorld.elements[14])
                aAxisPoints.push(axisVector)
            }
        } else {
            let axisVector = new Vector3(pEventData.axis.object3D.matrixWorld.elements[12], pEventData.axis.object3D.matrixWorld.elements[13], pEventData.axis.object3D.matrixWorld.elements[14])
            aAxisPoints.push(axisVector)
        }

        let closestPoint = null;
        let closestDistance = Infinity;

        for (const vector of aAxisPoints) {
            const distance = vector.distanceTo(pEvent.point);

            if (distance < closestDistance) {
                closestDistance = distance;
                closestPoint = vector;
            }
        }


        // pEventData.axis.object3D.higlight();
        this._drawCenterPoint(closestPoint)
        return {
            radius: null,
            center: closestPoint,
            dist: null,
            start: null,
            end: null,
            direction: null
        }
    }
    //__________________________________________________________________________________________
    private _unHighlightAxis(pEventData: iEventData) {
        if (null == pEventData.axis) {
            return;
        }

        pEventData.axis.object3D.unHiglight();
    }
    //__________________________________________________________________________________________
    public onMouseOver(pPart: Part, pEventData: iEventData, pEvent: iClientPoint) {
        switch (SceneContext.CHOOSE_MODE.mode) {
            case eClickMode.BASE:
                document.body.classList.add("pointer");
                break;
            case eClickMode.LCS:
                this._highlightAxis(pEventData);
                break;
            case eClickMode.LCS_REF:
                if ((null != pPart.refCS) && (pPart.refCS.refPart == this.mWorkingPart) ||
                    (pPart == this.mWorkingPart)) {
                    return;
                }

                this._highlightAxis(pEventData);
                break;
            case eClickMode.SOLIDS:
                this.resetHighlighting()
                this._chooseSolids(pEvent, pEventData)
                return;
            case eClickMode.CS:
                this.resetHighlighting()
                switch (CSMode.instance.currentState()) {
                    case ePhase.BASE_POINT:
                        if (pEventData.hasOwnProperty('face')) {
                            return;
                        }

                        if (pEventData.hasOwnProperty('edges')) {
                            this._chooseEdgeNew(pEvent, pEventData, false);
                        }

                        this._highlightAxis(pEventData);

                        break;
                    case ePhase.BASE_DIRECTION:
                        switch (CSMode.instance.mDirectionState) {
                            case eDirection.FACE:
                                if (pEventData.hasOwnProperty('edges')) return
                                if (pEventData.hasOwnProperty('vertices')) return
                                if (pEventData.hasOwnProperty('face')) {
                                    this._chooseFaces(pEvent, pEventData);
                                }
                                break
                            case eDirection.EDGE:
                                if (pEventData.hasOwnProperty('face')) return
                                if (pEventData.hasOwnProperty('vertices')) return
                                this._chooseEdgeNew(pEvent, pEventData, true)
                                break
                            case eDirection.POINT:
                                if (pEventData.hasOwnProperty('face')) return
                                if (pEventData.hasOwnProperty('vertices')) return
                                this._chooseEdgeNew(pEvent, pEventData, false)
                                break
                        }
                        break;
                    case ePhase.COMPLETED:
                        break;
                }
                return
            case eClickMode.EDITOR:
                this.resetHighlighting()
                switch (AssemblyMode.instance.currentPhase()) {
                    case ePhase.BASE_POINT:
                        if (pEventData.hasOwnProperty('face')) return
                        this._chooseEdgeNew(pEvent, pEventData, false)
                        break;
                    case ePhase.BASE_DIRECTION:
                        if (pEventData.hasOwnProperty('edges')) return
                        this._chooseFaces(pEvent, pEventData)
                        break;
                    case ePhase.SECOND_POINT:
                        if (pEventData.hasOwnProperty('face')) return
                        this._chooseEdgeNew(pEvent, pEventData, false)
                        break;
                    case ePhase.SECOND_DIRECTION:
                        if (pEventData.hasOwnProperty('edges')) return
                        this._chooseFaces(pEvent, pEventData)
                        break;
                    case ePhase.COMPLETED:
                        break;
                }
                return;
            case eClickMode.FACES:
                this.resetHighlighting()
                this._chooseFaces(pEvent, pEventData)
                return;
            case eClickMode.CHANGE_FACE_PROPERTIES:
                this.resetHighlighting()
                this._chooseFaces(pEvent, pEventData)
                return;
            case eClickMode.CHANGE_SOLIDS_MATERIAL:
                this.resetHighlighting()
                this._chooseSolids(pEvent, pEventData)
                return;
            case eClickMode.EDGES:
            case eClickMode.CHANGE_BASE:
            case eClickMode.ADD_POST:
            case eClickMode.MEASUREMENT:
                this.resetHighlighting()
                if (pEventData.hasOwnProperty('edges')) {
                    this._chooseEdgeNew(pEvent, pEventData)
                } else {
                    this._createAxisHelperPoint(pEvent, pEventData)
                }

                return;
        }
    }
    //__________________________________________________________________________________________
    public onMouseOut(pPart: Part, pEventData: iEventData, _pEvent: iClientPoint) {
        switch (SceneContext.CHOOSE_MODE.mode) {
            case eClickMode.LCS:
                this._unHighlightAxis(pEventData);
                break;
            case eClickMode.LCS_REF:
                if (pPart != this.mWorkingPart) {
                    this._unHighlightAxis(pEventData);
                }
                break;
            case eClickMode.CS:
                this._unHighlightAxis(pEventData);
                break
            default:
                break;
        }
    }
    //__________________________________________________________________________________________
    public resetHighlighting() {
        if (this.mPreviousHighlighted) {
            for (let part of this.mHighlightedParts) {
                part.mesh.geometry.attributes['color'].copy(part.mesh.geometry.attributes['colorBase'])
                part.mesh.geometry.attributes['color'].needsUpdate = true
            }
        }

        for (let mesh of this.mEdgesMeshes) {
            (mesh.material as any).dispose()
            mesh.geometry.dispose()
            SceneContext.MAIN_SCENE.remove(mesh)
        }

        this._clearAllMeasurePoints()

        SceneContext.OP3D_SCENE.activateRenderer()
        this.mPreviousHighlighted = null
        this.mHighlightedParts = []
    }
    //__________________________________________________________________________________________
    private _clearAllMeasurePoints() {
        for (let point of this.mPointsOnScene) {
            point?.geometry.dispose()
            SceneContext.MAIN_SCENE.remove(point)
        }
        this.mPointsOnScene = []

    }
    //__________________________________________________________________________________________
    private _drawCenterPoint(pPoint: Vector3) {
        const aPointGeometry = new SphereGeometry(1)
        let aZoom = 1 / SceneContext.CAMERA.zoom
        let scalingFactor = 2;
        if (SceneContext.CAMERA.zoom > 2) {
            scalingFactor = SceneContext.CAMERA.zoom
        }
        if (SceneContext.CAMERA.zoom > 6) {
            scalingFactor = 4
        }


        aPointGeometry.scale(aZoom * scalingFactor, aZoom * scalingFactor, aZoom * scalingFactor)
        const aPointMaterial = new MeshBasicMaterial({ color: 0xFFA500, depthWrite: false, depthTest: false })

        this.mMeasurePointMesh = new Mesh(
            aPointGeometry,
            aPointMaterial
        )
        this.mMeasurePointMesh.position.copy(pPoint);
        // (this.mMeasurePointMesh.material as any).depthTest = false;
        this.mPointsOnScene.push(this.mMeasurePointMesh)

        SceneContext.MAIN_SCENE.add(this.mMeasurePointMesh)

    }
    //__________________________________________________________________________________________
    public static filterChanges(pPrevChanges: Array<iPartChange>, pCurrentChanges: Array<iPartChange>) {

        let aNewChanges = pPrevChanges || []
        for (let i = 0; i < pCurrentChanges.length; i++) {
            let aChange = pCurrentChanges[i]
            switch (aChange.type) {
                case eChangesType.OPTICS_AXIS:
                    let aAxisOptics
                    if (pPrevChanges == null) {
                        aAxisOptics = []
                    } else {
                        aAxisOptics = pPrevChanges.filter(change => change.type == eChangesType.OPTICS_AXIS)
                    }
                    if (aAxisOptics.length !== 0) {
                        aAxisOptics[0].data.position = aChange.data.position
                        aAxisOptics[0].data.rotation = aChange.data.rotation
                        aAxisOptics[0].data.radius = aChange.data.radius
                        aAxisOptics[0].data.length = aChange.data.length
                        aAxisOptics[0].data.face = aChange.data.face
                        aAxisOptics[0].data.shape = aChange.data.shape
                    } else {
                        aNewChanges.push(
                            {
                                type: eChangesType.OPTICS_AXIS,
                                data: {
                                    position: aChange.data.position,
                                    rotation: aChange.data.rotation,
                                    radius: aChange.data.radius,
                                    length: aChange.data.length,
                                    face: aChange.data.face,
                                    shape: aChange.data.shape
                                }
                            })
                    }
                    break;
                case eChangesType.CAGE_AXIS:
                    let aAxisCage
                    if (pPrevChanges == null) {
                        aAxisCage = []
                    } else {
                        aAxisCage = pPrevChanges.filter(change => change.type == eChangesType.CAGE_AXIS)
                    }

                    if (aAxisCage.length !== 0) {
                        aAxisCage[0].data.position = aChange.data.position
                        aAxisCage[0].data.rotation = aChange.data.rotation
                    } else {
                        aNewChanges.push(
                            {
                                type: eChangesType.CAGE_AXIS,
                                data: {
                                    position: aChange.data.position,
                                    rotation: aChange.data.rotation,
                                    radius: 1
                                }
                            })
                    }
                    break;
                case eChangesType.LASER_AXIS:
                    let aAxisLaser
                    if (pPrevChanges == null) {
                        aAxisLaser = []
                    } else {
                        aAxisLaser = pPrevChanges.filter(change => change.type == eChangesType.LASER_AXIS)
                    }
                    // let aAxisLaser = pPrevChanges.filter(change => change.type == eChangesType.LASER_AXIS)
                    if (aAxisLaser.length !== 0) {
                        aAxisLaser[0].data.position = aChange.data.position
                        aAxisLaser[0].data.rotation = aChange.data.rotation
                    } else {
                        aNewChanges.push(
                            {
                                type: eChangesType.LASER_AXIS,
                                data: {
                                    position: aChange.data.position,
                                    rotation: aChange.data.rotation,
                                }
                            })
                    }
                    break;
                case eChangesType.BASIC_TRANSLATION:
                    let aBase
                    if (pPrevChanges == null) {
                        aBase = []
                    } else {
                        aBase = pPrevChanges.filter(change => change.type == eChangesType.BASIC_TRANSLATION)
                    }
                    // let aBase = pPrevChanges.filter(change => change.type == eChangesType.BASIC_TRANSLATION)
                    if (aBase.length !== 0) {
                        aBase[0].data.deltaPos = aChange.data.deltaPos
                        aBase[0].data.rot = aChange.data.rot
                    } else {
                        aNewChanges.push(
                            {
                                type: eChangesType.BASIC_TRANSLATION,
                                data: {
                                    deltaPos: aChange.data.deltaPos,
                                    rot: aChange.data.rot,
                                }
                            })

                    }
                    break;
                case eChangesType.COLOR:
                    if (aChange.data.path.length == 0) {
                        aNewChanges = aNewChanges.filter(change => change.type !== eChangesType.COLOR)
                        aNewChanges.push(aChange)
                        break;
                    } else {
                        let aClone
                        if (pPrevChanges == null) {
                            aClone = []
                        } else {
                            aClone = pPrevChanges.find(change => change.type == eChangesType.COLOR && change.data.path.toString() == aChange.data.path.toString())
                        }

                        // let aClone = pPrevChanges.find(change => change.type == eChangesType.COLOR && change.data.path.toString() == aChange.data.path.toString())
                        if (aClone !== undefined) {
                            aNewChanges.splice(aNewChanges.indexOf(aClone), 1, aChange)
                        } else {
                            aNewChanges.push(aChange)
                        }
                        break;
                    }
            }

        }
        return aNewChanges
    }
    //__________________________________________________________________________________________
    public static async addChangesToPart(pPart: Part, pChanges: any, pPartToChange?: iPart) {
        let aPartVOMainPart = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id)
        if (aPartVOMainPart.isAssembly == true) {
            let index = pPart.subParts.indexOf(pPartToChange)
            let aPartsData = PartsDataLoader.instance.getFromCache(aPartVOMainPart.number_id, false) as iPartMongoDB;

            if (aPartsData.permission == eDataPermission.PRIVATE) {
                if (aPartsData.owner !== undefined && aPartsData.owner == Op3dContext.USER_VO.id) {

                    let aPartInAssembly = JSON.parse(aPartsData.assembly_parts)[index] as iAssemblyMongoDB

                    let aCurrentPart = await (await ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
                    let aPreviousChanges = (null != aCurrentPart.changes) ?
                        JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;
                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)

                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aPartInAssembly.number_id
                    });

                    if (aRes.success == true) {
                        PartsDataLoader.instance.deleteFromCache(aPartsData.number_id);
                        let aNumberID = aPartsData.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart = await PartsDataLoader.instance.getSingleFullData({
                            number_id: aNumberID
                        });
                        aPartsData.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aPartsData)
                        let aPartNew = new Part({
                            id: aPartsData.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }



                } else {
                    let aCopiedAssembly = await ServerContext.SERVER.copyAssembly({ number_id: aPartsData.number_id });

                    let aPartInAssembly = JSON.parse(aCopiedAssembly.data.assembly_parts)[index] as iAssemblyMongoDB
                    // PartsEventsHandler.addChangesToPart(aPartInAssembly.number_id, this.mChangedFaces, this.mCurrentPart)

                    let aCurrentPart = await (await ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
                    let aPreviousChanges = (null != aCurrentPart.changes) ?
                        JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;

                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)


                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aPartInAssembly.number_id
                    });

                    if (aRes.success == true) {
                        let aNumberID = aCopiedAssembly.data.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });
                        aCopiedAssembly.data.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aCopiedAssembly.data)

                        let aPartNew = new Part({
                            id: aCopiedAssembly.data.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }
                }
            }

            if (aPartsData.permission == eDataPermission.PUBLIC) {
                if (Op3dContext.USER_VO.isAdmin == true) {
                    let aPartInAssembly = JSON.parse(aPartsData.assembly_parts)[index] as iAssemblyMongoDB

                    let aCurrentPart = await (await ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
                    let aPreviousChanges = (null != aCurrentPart.changes) ?
                        JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;
                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)

                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aPartInAssembly.number_id
                    });

                    if (aRes.success == true) {
                        PartsDataLoader.instance.deleteFromCache(aPartsData.number_id);
                        let aNumberID = aPartsData.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });
                        aPartsData.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aPartsData)
                        let aPartNew = new Part({
                            id: pPart.id + aNumberID,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }

                } else if (Op3dContext.USER_VO.isBasicUser == true) {
                    let aCopiedAssembly = await ServerContext.SERVER.copyAssembly({ number_id: aPartsData.number_id });

                    let aPartInAssembly = JSON.parse(aCopiedAssembly.data.assembly_parts)[index] as iAssemblyMongoDB
                    // PartsEventsHandler.addChangesToPart(aPartInAssembly.number_id, this.mChangedFaces, this.mCurrentPart)

                    let aCurrentPart = await (await ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
                    let aPreviousChanges = (null != aCurrentPart.changes) ?
                        JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;

                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)


                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aPartInAssembly.number_id
                    });

                    if (aRes.success == true) {
                        let aNumberID = aCopiedAssembly.data.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });
                        aCopiedAssembly.data.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aCopiedAssembly.data)
                        let aPartNew = new Part({
                            id: aCopiedAssembly.data.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }
                }
            }
        } else {
            let aPartsData = PartsDataLoader.instance.getFromCache(aPartVOMainPart.number_id, false) as iPartMongoDB;


            if (aPartsData.permission == eDataPermission.PUBLIC) {
                if (Op3dContext.USER_VO.isAdmin == true) {

                    // let aCurrentPart = await (await ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
                    let aPreviousChanges = (null != aPartsData.changes) ?
                        JSON.parse(aPartsData.changes) : null as Array<iPartChange>;
                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)

                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aPartsData.number_id
                    });

                    if (aRes.success == true) {
                        PartsDataLoader.instance.deleteFromCache(aPartsData.number_id);
                        let aNumberID = aPartsData.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });
                        aPartsData.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aPartsData)
                        let aPartNew = new Part({
                            id: aPartsData.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }
                } else if (Op3dContext.USER_VO.isBasicUser == true) {
                    let aCopiedPart = await ServerContext.SERVER.copyPart({ number_id: aPartsData.number_id, inAssembly: false });

                    let aPreviousChanges = (null != aPartsData?.changes) ?
                        JSON.parse(aPartsData.changes) : null as Array<iPartChange>;

                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)


                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aCopiedPart.data.number_id
                    });

                    if (aRes.success == true) {
                        let aNumberID = aCopiedPart.data.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });

                        aCopiedPart.data.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aCopiedPart.data)
                        let aPartNew = new Part({
                            id: aCopiedPart.data.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }
                }
            }

            if (aPartsData.permission == eDataPermission.PRIVATE) {
                if (aPartsData.owner !== undefined && aPartsData.owner == Op3dContext.USER_VO.id) {
                    let aPreviousChanges = (null != aPartsData.changes) ?
                        JSON.parse(aPartsData.changes) : null as Array<iPartChange>;
                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)

                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aPartsData.number_id
                    });

                    if (aRes.success == true) {
                        PartsDataLoader.instance.deleteFromCache(aPartsData.number_id);
                        let aNumberID = aPartsData.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });

                        aPartsData.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aPartsData)
                        let aPartNew = new Part({
                            id: aPartsData.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }
                } else {
                    let aCopiedPart = await ServerContext.SERVER.copyPart({ number_id: aPartsData.number_id, inAssembly: false });

                    let aPreviousChanges = (null != aPartsData.changes) ?
                        JSON.parse(aPartsData.changes) : null as Array<iPartChange>;

                    let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, pChanges)


                    let aRes = await ServerContext.SERVER.addChangesToPart({
                        changes: JSON.stringify(aUpdatedChanges),
                        number_id: aCopiedPart.data.number_id
                    });

                    if (aRes.success == true) {
                        let aNumberID = aCopiedPart.data.number_id;
                        let aJSONData = pPart.exportToJSONObject();
                        let aNewPart =
                            await PartsDataLoader.instance.getSingleFullData({
                                number_id: aNumberID
                            });
                        aCopiedPart.data.info.number_id = aNumberID
                        Op3dContext.DATA_MANAGER.addToPartsData(aCopiedPart.data)
                        let aPartNew = new Part({
                            id: aCopiedPart.data.info.id,
                            number_id: aNumberID
                        }).add(aNewPart);

                        // aPartNew.iPart.partVO = new PartVO(aPartVO)
                        Op3dContext.PARTS_MANAGER.deletePart(pPart);
                        aPartNew.initFromJSON(aJSONData, true);

                    }
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public async onMouseUp(pObject: Part | any, pEventData: iEventData | any,
        pFaceIndex?: Intersection) {
        switch (SceneContext.CHOOSE_MODE.mode) {
            case eClickMode.GROUP:
                pObject.onSelect();
                Group.instance.setPartToGroup(pObject)
                break
            case eClickMode.BB:
                pObject.onSelect();
                setPartToBB(pObject)
                break
            case eClickMode.BASE:
                pObject.onSelect();
                break;
            case eClickMode.LCS:
                this._onSetLCS(pEventData);
                break;
            case eClickMode.LCS_REF:

                if (pObject != this.mWorkingPart) {
                    this._onSetRef(pObject, pEventData);
                }
                break;
            case eClickMode.EDGES:
                this._chooseEdgeNew(pFaceIndex, pEventData, true)
                return;
            case eClickMode.FACES:
                this._chooseFaces(pFaceIndex, pEventData)
                return;
            case eClickMode.RAYS_POSITION:
                let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;

                let aRaysDisplayer: RaysDisplayer = pObject;
                let aRaycasterPoint = pEventData.point;
                let aCalculatedPoint = aRaysDisplayer.getCoordinatesOfRayCenter(aRaycasterPoint);
                aSelectedPart.visibleObj.position.set(0, 0, 0);
                aSelectedPart.visibleObj.lookAt(aCalculatedPoint.direction.x, aCalculatedPoint.direction.y, aCalculatedPoint.direction.z);
                aSelectedPart.visibleObj.position.copy(aCalculatedPoint.position);
                aSelectedPart.refRotation = aSelectedPart.visibleObj.rotation;

                ChoosePartMode.instance.leaveChoosePartMode();
                SimulationRunner.instance.raysDisplayer.removeListeners();

                this._removeConnectedLine();
                return;
            case eClickMode.CHANGE_FACE_PROPERTIES:
                let aFace = this._chooseFaces(pFaceIndex, pEventData);
                let aPart = Op3dContext.PARTS_MANAGER.getPartByFaceID(pEventData.face.internal_id)
                aFace = aPart.getFaces()[0].indexes.find(item => JSON.stringify(item.path) === JSON.stringify(aFace.path))
                if (aPart.partPermission() === eDataPermission.PRIVATE) {
                    OptomechanicsSurfaceManager.instance.open({
                        face: aFace,
                        part: aPart
                    });
                }
                ChoosePartMode.instance.leaveChoosePartMode()
                return
            case eClickMode.CHANGE_SOLIDS_MATERIAL:
                let aFaceSolid = this._chooseFaces(pFaceIndex, pEventData);
                let aPartSiolid = Op3dContext.PARTS_MANAGER.getPartByFaceID(pEventData.face.internal_id);
                let aFacesSolid = aPartSiolid.getSolidsFacesByNumber(aFaceSolid.path[1]);
                if (aPartSiolid.partPermission() === eDataPermission.PRIVATE) {
                    OptomechanicsSolidsForm.instance.open({
                        part: aPartSiolid,
                        faces: aFacesSolid
                    });
                }
                ChoosePartMode.instance.leaveChoosePartMode();
                return
            case eClickMode.SOLIDS:
                this._chooseSolids(pFaceIndex, pEventData)
                return;
            case eClickMode.MEASUREMENT:
                if (pEventData.hasOwnProperty('edges')) {
                    let aEdgeData: iEdgeSelectionData = this._chooseEdgeNew(pFaceIndex, pEventData)
                    Measurement.instance.setMeasure(aEdgeData, pObject, pEventData)
                } else {
                    let aEdgeData = this._createAxisHelperPoint(pFaceIndex, pEventData)
                    if (aEdgeData) {
                        Measurement.instance.setMeasure(aEdgeData, pObject, pEventData)
                    }
                }


                return
            case eClickMode.EDITOR:
                let center, aRotationMatrix, aObjectNormal;

                switch (AssemblyMode.instance.currentPhase()) {

                    case ePhase.BASE_POINT:
                        if (pEventData.hasOwnProperty('face')) return

                        center = this._chooseEdgeNew(pFaceIndex, pEventData, false).center
                        AssemblyMode.instance.setPartMain(pObject)
                        AssemblyMode.instance._setActiveField(center)
                        break;
                    case ePhase.BASE_DIRECTION:
                        if (pEventData.hasOwnProperty('edges')) return

                        this._chooseFaces(pFaceIndex, pEventData)

                        aRotationMatrix = new Matrix4().extractRotation(pFaceIndex.object.matrixWorld.clone())
                        aObjectNormal = pFaceIndex.face.normal.clone().applyMatrix4(aRotationMatrix)

                        AssemblyMode.instance._setActiveField(aObjectNormal)
                        break;
                    case ePhase.SECOND_POINT:

                        if (pEventData.hasOwnProperty('face')) return

                        center = this._chooseEdgeNew(pFaceIndex, pEventData, false).center

                        AssemblyMode.instance.setPartToConnect(pObject)
                        AssemblyMode.instance._setActiveField(center)

                        break;
                    case ePhase.SECOND_DIRECTION:
                        if (pEventData.hasOwnProperty('edges')) return

                        this._chooseFaces(pFaceIndex, pEventData)

                        aRotationMatrix = new Matrix4().extractRotation(pFaceIndex.object.matrixWorld.clone())
                        aObjectNormal = pFaceIndex.face.normal.clone().applyMatrix4(aRotationMatrix)

                        AssemblyMode.instance._setActiveField(aObjectNormal)

                        break;
                    case ePhase.COMPLETED:
                        return
                }
                return;
            case eClickMode.COLOR_FACE:
            case eClickMode.COLOR_PART:
            case eClickMode.COLOR_SOLID:
                this._changeFaceColor(pFaceIndex, pEventData, pObject)
                return
            case eClickMode.CS:
                let aCSData: iEdgeSelectionData;
                switch (CSMode.instance.currentState()) {
                    case ePhase.BASE_POINT:
                        if (pEventData.hasOwnProperty('face')) { return; }
                        if (pEventData.hasOwnProperty('edges')) {
                            aCSData = this._chooseEdgeNew(pFaceIndex, pEventData, false);
                            CSMode.instance.setCSCenterPoint(aCSData,
                                this.mWorkingPart, pEventData);
                        } else {
                            let aAxis = pEventData.axis;
                            if (null != aAxis) {
                                let aPoint =
                                    aAxis.object3D.getWorldPosition(new Vector3());
                                CSMode.instance.setCSCenterPoint(aPoint,
                                    this.mWorkingPart, pEventData);
                            }
                        }
                        break;
                    case ePhase.BASE_DIRECTION:
                        let aRotationMatrix, aObjectNormal
                        switch (CSMode.instance.mDirectionState) {
                            case eDirection.FACE:
                                if (pEventData.hasOwnProperty('edges')) return;
                                if (pEventData.hasOwnProperty('face')) {
                                    this._chooseFaces(pFaceIndex, pEventData);
                                }

                                aRotationMatrix = new Matrix4().extractRotation(pFaceIndex.object.matrixWorld.clone())
                                aObjectNormal = pFaceIndex.face.normal.clone().applyMatrix4(aRotationMatrix)


                                CSMode.instance.setCSDirection(aObjectNormal)
                                break
                            case eDirection.EDGE:
                                if (pEventData.hasOwnProperty('face')) return
                                let aEdgeData = this._chooseEdgeNew(pFaceIndex, pEventData, true)

                                aRotationMatrix = new Matrix4().extractRotation(pFaceIndex.object.matrixWorld.clone())
                                aObjectNormal = aEdgeData.direction.applyMatrix4(aRotationMatrix)


                                CSMode.instance.setCSDirection(aObjectNormal)
                                break
                            case eDirection.POINT:
                                if (pEventData.hasOwnProperty('face')) return

                                let aPoint = this._chooseEdgeNew(pFaceIndex, pEventData, false).center

                                let aTreshold = SceneContext.CAMERA.zoom > 3 ? 0.5 : SceneContext.CAMERA.zoom < 1 ? 1.5 : 2 - (SceneContext.CAMERA.zoom / 2)
                                let aMesh = new Mesh(new SphereGeometry(aTreshold), new MeshBasicMaterial({ color: 0xFFA500 }))

                                SceneContext.MAIN_SCENE.add(aMesh)
                                aMesh.position.copy(aPoint)

                                this.mChoosedPoints.push({
                                    mesh: aMesh,
                                    vector: aPoint
                                })

                                if (this.mChoosedPoints.length == 2) {
                                    let aPoint1 = this.mChoosedPoints[0]
                                    let aPoint2 = this.mChoosedPoints[1]
                                    let dir = new Vector3();
                                    let finalVec = dir.subVectors(aPoint2.vector, aPoint1.vector).normalize();


                                    CSMode.instance.setCSDirection(finalVec)
                                    SceneContext.MAIN_SCENE.remove(aPoint1.mesh)
                                    SceneContext.MAIN_SCENE.remove(aPoint2.mesh)
                                    this.mChoosedPoints = []
                                }
                                break
                        }

                        break;
                    case ePhase.COMPLETED:
                        break;

                }
                return
        }
    }
    //__________________________________________________________________________________________
    private _changeFaceColor(pFaceIndex, pEventData, pPart: Part) {
        this.resetHighlighting()

        let aFacePos = 0
        let aFaceIndexes = {
            start: 0,
            end: 0
        }
        aFacePos = pFaceIndex.face.a
        let aCurrFace: iFaceDataNEW

        pEventData.face.indexes.forEach(el => {
            if (el.indexes.start <= aFacePos && el.indexes.end >= aFacePos) {
                aFaceIndexes.start = el.indexes.start
                aFaceIndexes.end = el.indexes.end
                aCurrFace = el
            }
        })

        let aColorData = {
            face: aCurrFace,
            start: aFaceIndexes.start,
            end: aFaceIndexes.end,
            indexes: aFaceIndexes,
            event: pEventData,
            part: pPart
        }

        ColorPicker.instance.setCurrentFace(aColorData)

        this.mPreviousHighlighted = {
            colorStart: aFaceIndexes.start,
            colorEnd: aFaceIndexes.end,
            object: pEventData.face.visualization.mesh,
            data: aCurrFace
        }

        aCurrFace.colors = []
        for (let i = aFaceIndexes.start; i <= aFaceIndexes.end; i++) {
            pEventData.face.visualization.mesh.geometry.attributes['color'].setXYZ(i, 0, 0, 0.3);

            aCurrFace.colors.push(0, 0, 0.3, 1)
        }

        this.mHighlightedParts.push({
            mesh: pEventData.face.visualization.mesh,
            data: aCurrFace
        })

        pEventData.face.visualization.mesh.geometry.attributes['color'].needsUpdate = true
    }
    //__________________________________________________________________________________________
    private _chooseFaces(pFaceIndex, pEventData) {
        let aFacePos = 0
        let aFaceIndexes = {
            start: 0,
            end: 0
        }
        aFacePos = pFaceIndex.face.a
        let aCurrFace: iFaceDataNEW

        pEventData.face.indexes.forEach(el => {
            if (el.indexes.start <= aFacePos && el.indexes.end >= aFacePos) {
                aFaceIndexes.start = el.indexes.start
                aFaceIndexes.end = el.indexes.end
                aCurrFace = el
            }
        })

        this.mPreviousHighlighted = {
            colorStart: aFaceIndexes.start,
            colorEnd: aFaceIndexes.end,
            object: pEventData.face.visualization.mesh,
            data: aCurrFace
        }

        aCurrFace.colors = []
        for (let i = aFaceIndexes.start; i <= aFaceIndexes.end; i++) {
            pEventData.face.visualization.mesh.geometry.attributes['color'].setXYZ(i, 0, 0, 1);

            aCurrFace.colors.push(0, 0, 1, 1)
        }

        this.mHighlightedParts.push({
            mesh: pEventData.face.visualization.mesh,
            data: aCurrFace
        })
        pEventData.face.visualization.mesh.geometry.attributes['color'].needsUpdate = true

        return { ...aCurrFace }
    }
    //__________________________________________________________________________________________
    private _chooseSolids(pFaceIndex, pEventData) {

        let aFacePos = 0
        let aFaceIndexes = {
            start: 0,
            end: 0
        }
        aFacePos = pFaceIndex.face.a
        let aCurrFace: iFaceDataNEW

        pEventData.face.indexes.forEach(el => {
            if (el.indexes.start <= aFacePos && el.indexes.end >= aFacePos) {
                aFaceIndexes.start = el.indexes.start
                aFaceIndexes.end = el.indexes.end
                aCurrFace = el
            }
        })

        let aSolidNumber = aCurrFace.path[1]
        let aSolidFirst = pEventData.face.indexes.find(el => el.path[1] == aSolidNumber)
        let aSolidLast = pEventData.face.indexes.findLast(el => el.path[1] == aSolidNumber)

        this.mPreviousHighlighted = {
            colorStart: aSolidFirst.indexes.start,
            colorEnd: aSolidLast.indexes.end,
            object: pEventData.face.visualization.mesh,
            data: aCurrFace
        }

        aCurrFace.colors = []
        for (let i = aSolidFirst.indexes.start; i <= aSolidLast.indexes.end; i++) {
            pEventData.face.visualization.mesh.geometry.attributes['color'].setXYZ(i, 0, 0, 1);

            aCurrFace.colors.push(0, 0, 1, 1)
        }

        this.mHighlightedParts.push({
            mesh: pEventData.face.visualization.mesh,
            data: aCurrFace
        })
        pEventData.face.visualization.mesh.geometry.attributes['color'].needsUpdate = true

        return { ...aCurrFace }
    }
    //__________________________________________________________________________________________

    private _chooseEdgeNew(pFaceIndex, pEventData, pHighlightEdge?): iEdgeSelectionData {

        let aMesh = pEventData.edges.edgeMesh

        let aData = {
            center: [],
            radius: 0
        }
        let aEdgesIndexes = {
            start: 0,
            end: 0
        }

        let aEdgePos = pFaceIndex.index
        pEventData.edges.indices.forEach(el => {
            if (el.indexes.start <= aEdgePos && el.indexes.end >= aEdgePos) {
                aEdgesIndexes.start = el.indexes.start
                aEdgesIndexes.end = el.indexes.end
                aData = el.type == 2 ? el.data : null
            }
        })

        this.mPreviousHighlighted = {
            colorStart: aEdgesIndexes.start,
            colorEnd: aEdgesIndexes.end,
            object: pEventData.edges.edgeMesh
        }

        let aMeshArray = []
        for (let i = aEdgesIndexes.start; i <= aEdgesIndexes.end; i++) {
            let aPoint = new Vector3(
                aMesh.geometry.attributes['position'].getX(i),
                aMesh.geometry.attributes['position'].getY(i),
                aMesh.geometry.attributes['position'].getZ(i)
            )
            aMeshArray.push(aPoint)
        }

        let aIntersectionPointLocal = aMesh.worldToLocal(pFaceIndex.point)
        let aClosestPointLocal = this._calculateClosestPointNew(aMeshArray, aIntersectionPointLocal)

        let aStartPoint = aMeshArray.slice(0, aMeshArray.length / 2).at(-1).clone()
        let aEndPoint = aMeshArray.slice(aMeshArray.length / 2)[0].clone()
        let aDist = aStartPoint.distanceTo(aEndPoint)

        let aEdgePoint = aData !== null ? aMesh.localToWorld(new Vector3().fromArray(aData.center)) : aClosestPointLocal != undefined && aMesh.localToWorld(aClosestPointLocal.clone())

        if (pHighlightEdge == true || (pHighlightEdge == undefined && (aEdgePoint === false || aData !== null)) || aData !== null) {
            for (let i = 0; i < aMeshArray.length; i += 2) {

                let aZoom = 1 / SceneContext.CAMERA.zoom
                let scalingFactor = 2;
                if (SceneContext.CAMERA.zoom > 0.7) {
                    scalingFactor = SceneContext.CAMERA.zoom / 1.2
                }
                if (SceneContext.CAMERA.zoom > 2) {
                    scalingFactor = SceneContext.CAMERA.zoom / 2
                }
                if (SceneContext.CAMERA.zoom > 6) {
                    scalingFactor = 2
                }


                let aDirection = new Vector3().subVectors(aMesh.localToWorld(aMeshArray[i + 1].clone()), aMesh.localToWorld(aMeshArray[i].clone()));
                let aArrow = new ArrowHelper(aDirection.clone().normalize(), aMesh.localToWorld(aMeshArray[i].clone()));

                let aEdgeGeometry = new CylinderGeometry(aZoom * scalingFactor, aZoom * scalingFactor, aDirection.length());

                let aEdge = new Mesh(aEdgeGeometry,
                    new MeshBasicMaterial({ color: 0xFFA500, depthTest: false, depthWrite: false }));
                aEdge.rotation.copy(aArrow.rotation.clone());
                aEdge.renderOrder = 999
                aEdge.position.copy(new Vector3().addVectors(aMesh.localToWorld(aMeshArray[i].clone()), aDirection.multiplyScalar(0.5)));
                // aEdge.material.depthTest = false
                this.mEdgesMeshes.push(aEdge)
                SceneContext.MAIN_SCENE.add(aEdge)
            }

        }
        if (pHighlightEdge != true || pHighlightEdge == undefined) {
            this._drawCenterPoint(aEdgePoint)
        }


        let aDirection = new Vector3().subVectors(aEndPoint, aStartPoint).normalize();

        return {
            radius: aData?.radius,
            center: aEdgePoint,
            dist: aDist,
            start: aStartPoint,
            end: aEndPoint,
            direction: aDirection
        }

    }
    //__________________________________________________________________________________________
    private _calculateClosestPointNew(pMeshArray, pIntersectionPointLocal) {
        let aStartPoint = pMeshArray.slice(0, pMeshArray.length / 2).at(-1)
        let aEndPoint = pMeshArray.slice(pMeshArray.length / 2)[0]
        let aMiddle = new Vector3().addVectors(aStartPoint, aEndPoint).multiplyScalar(0.5)
        let aDistFromP1 = aStartPoint.distanceTo(pIntersectionPointLocal)
        let aDistFromP2 = aEndPoint.distanceTo(pIntersectionPointLocal)
        let aDistFromMiddle = aMiddle.distanceTo(pIntersectionPointLocal)

        if (aDistFromP1 < aDistFromP2 && aDistFromP1 < aDistFromMiddle && aDistFromP1 < 1) {
            return aStartPoint
        } else if (aDistFromMiddle < aDistFromP1 && aDistFromMiddle < aDistFromP2 && aDistFromMiddle < 1) {
            return aMiddle
        } else if (aDistFromP2 < 1) {
            return aEndPoint
        } else {
            return undefined
        }
    }
    //__________________________________________________________________________________________
    public _setDashedLineVisibility(pToShow: boolean, e: MouseEvent) {
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart
        if(aSelectedPart === null || aSelectedPart === undefined){
            this.restoreToGeneralMode();
            this._removeConnectedLine();
            return;
        }
        let aMousePos = SceneContext.POSITION_GENERATOR.getMousePointPlaneIntersection(
            e, ePlaneType.HORIZONTAL, aSelectedPart);

        let aLine = new Line3();
        aLine.start = aSelectedPart.position;
        aLine.end = aMousePos;

        this._removeConnectedLine();
        if (false == pToShow) {
            return;
        }

        let aGeometry = new BufferGeometry().setFromPoints([aLine.start, aLine.end]);
        let aMaterial = new LineDashedMaterial({
            color: (0xFFFFFF - Op3dContext.USER_VO.simulationSettings.sceneBGColor),
            linewidth: 10,
            scale: 1,
            dashSize: 10,
            gapSize: 10,
        });

        this.mConnectLine = new Line(aGeometry, aMaterial);
        this.mConnectLine.computeLineDistances();
        SceneContext.MAIN_SCENE.add(this.mConnectLine);
    }
    //__________________________________________________________________________________________
    public _removeConnectedLine() {
        if (null === this.mConnectLine || undefined === this.mConnectLine) {
            return;
        }

        this.mConnectLine.parent.remove(this.mConnectLine);
        this.mConnectLine = null;
    }
    //__________________________________________________________________________________________
}

