import { Op3dComponentBase } from "../ui/Op3dComponentBase";


export interface iConvertFormParams {
    func: (pMapFile: File, pFileList: FileList) => void;
    title: string;
};

export class ConvertForm extends Op3dComponentBase<iConvertFormParams> {

    private static INSTANCE: ConvertForm;

    private mFunc: (pMapFile: File, pFileList: FileList) => void;
    private mTitle: HTMLElement;
    private mMapFileTitle: HTMLElement;
    private mFilesTitle: HTMLElement;
    private mMapFileInput: HTMLInputElement;
    private mFilesInput: HTMLInputElement;

    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/convert_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ConvertForm.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            document.getElementById('forms').appendChild(aDiv);

            ConvertForm.INSTANCE = new ConvertForm(aDiv);
        }

        return ConvertForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pParserPormParams?: iConvertFormParams): void {
        this.mFunc = pParserPormParams.func;

        this.mTitle.innerHTML = pParserPormParams.title;
        this.mFilesTitle.innerText = "Choose files";
        this.mMapFileTitle.innerText = "Choose file";
        this.mMapFileInput.value = null;
        this.mFilesInput.value = null;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mMapFileTitle = this._getPart("map-file-title", true) as HTMLElement;
        this.mFilesTitle = this._getPart("files-title", true) as HTMLElement;
        let aConvertBtn = this._getPart('convert-btn') as HTMLButtonElement;
        this.mMapFileInput = this._getPart('map_file_input') as HTMLInputElement;
        this.mMapFileInput.addEventListener("change", (e) => this._onMapChanged(e));
        this.mFilesInput = this._getPart('file_input') as HTMLInputElement;
        this.mFilesInput.addEventListener("change", (e) => this._onFilesChanged(e));
        aConvertBtn.addEventListener('click', () => this._onFileChange(this.mMapFileInput, this.mFilesInput));


        this.mTitle = this._getPart('title');
    }
    //__________________________________________________________________________________________
    private _onFilesChanged(_e: Event) {

        try {
            let aNames = "";
            for (let i = 0; i < this.mFilesInput.files.length; i++) {
                aNames += this.mFilesInput.files[i].name + ";";
            }

            this.mFilesTitle.innerText = aNames;
        } catch (e) {
            this.mFilesTitle.innerText = "Choose files";
            this.mFilesInput.value = null;
        }
    }
    //__________________________________________________________________________________________
    private _onMapChanged(_e: Event) {

        try {
            this.mMapFileTitle.innerText = this.mMapFileInput.files[0].name
        } catch (e) {
            this.mMapFileTitle.innerText = "Choose file";
            this.mMapFileInput.value = null;
        }
    }
    //__________________________________________________________________________________________
    private _onFileChange(aMapFileInput: HTMLInputElement, pInputFile: HTMLInputElement) {
        let aMapInputFile = aMapFileInput.files[0];
        let aInputFile = pInputFile.files[0];
        if (null == aMapInputFile || null == aInputFile) {
            return;
        }
        this.mFunc(aMapFileInput.files[0], pInputFile.files);
        this.close();
    }
    //__________________________________________________________________________________________
}
