import { iOptiChatInfoData } from "./OptiChatInfo";
import classes from "../../UI/optichat-info.module.scss";

export const INFO_DATA: Array<iOptiChatInfoData> = [
  {
    title: "Capabilities",
    content: (
      <div className={classes.main}>
        <div>
          OptiChat Search Coplilot for 3DOptix - Discover optical elements in
          our catalog and perform calculations effortlessly. More abilities are
          coming soon.
        </div>
        <div>
          <p>Search</p>
          <span>
            Find optical elements in our catalog and get basic assistance in
            selecting the right parameters.
          </span>
        </div>
        <div>
          <p>Calculations</p>
          <span>
            The OptiChat Search Coplilot can automatically perform calculations
            for you, including refractive index from material and wavelength,
            filling in missing values in the Lens Maker equation, or finding the
            necessary lens radii for a specific EFL.
          </span>
        </div>
        <div>
          <p>Chains Actions</p>
          <span>
            The OptiChat Search Coplilot can perform chained actions for you,
            fill gaps in calculations and ask for the information it needs.
          </span>
        </div>
      </div>
    ),
  },
  {
    title: "Tips",
    content: (
      <div className={classes.main}>
        <div>
          <p>Search Results</p>
          <span>
            <ul>
              <li>Each set of search results will open in a new tab</li>
              <li>
                The ongoing chat conversation will always refer to the context
                of the conversation that resulted in the search results in the
                active tab
              </li>
              <li>
                Switching to filtered search mode will display the filter
                settings that correspond with last active tab in the Search
                Coplilot
              </li>
            </ul>
          </span>
        </div>
        <div>
          <p>Be linear</p>
          <span>
            Break complex tasks into smaller, sequential ones for better
            performance
          </span>
        </div>
        <div>
          <p>Utilize keywords</p>
          <span>
            YourSearch Coplilot is sensitive to keywords, so use terms closer to
            the action you are trying to make to improve results
          </span>
        </div>
        <div>
          <p>Specify units</p>
          <span>
            To protect yourself from AI errors and ensure proper unit
            conversion, you can ask what are the required units for your
            parameters
          </span>
        </div>
        <div>
          <p>Verify parameters</p>
          <span>
            After complex tasks, and especially in search, Search Copilot is
            likely to provide the search and calculations parameters used. We
            highly recommend asking for the parameters anyway, if you want to
            verify the parameters. You can always change the search filters
            manually or correct the Search Copilot, if the wrong ones were used.
          </span>
        </div>
        <div>
          <p>Issues and feedback</p>
          <span>
            If you encounter any issues, mistakes, or have exceptionally good
            conversations, don't hesitate to provide feedback for our AI/ML team
            using the green feedback star button
          </span>
        </div>
      </div>
    ),
  },
  {
    title: "Limitations",
    content: (
      <div className={classes.main}>
        <div>
          <p>False Information</p>
          <span>
            Please keep in mind that while OptiChat is highly capable, it does
            have some limitations and might occasionally provide inaccurate
            information and unexpected behavior.
          </span>
        </div>
        <div>
          <p>Chat Memory</p>
          <span>
            Like any other AI chat tool, OptiChat has limited memory capacity,
            in a longer conversation it might lose access to the information
            provided in the beginning of the conversation.
          </span>
        </div>
      </div>
    ),
  },
];
