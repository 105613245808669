import { EventManager } from "../../../oc/events/EventManager";
import { AnalyticsEventsContext } from "../../_context/AnalyticsEventsContext";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { BlackBoxWrapper } from "../../parts/BlackBox/context/BlackBoxCounterProvider";

import { eMouseMode } from "../../scene/Op3dScene";
import { SceneContext } from "../../scene/SceneContext";
import { ViewMenuBtn } from "../../scene/_camera/ViewMenu/ViewMenuBtn";
import { CameraUtils } from "../../scene/_camera/CameraUtils";
import { eViewTypeXYZ } from "../../scene/absSceneCube";
import { ENVS, ServerContext } from "../../server/ServerContext";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { AnalysesSynchronizer } from "../analysis/AnalysesSynchronizer";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { GaussianBeamTable } from "../analysis/GaussianBeamTable";
import { LightSourceHeaderList } from "../analysis/components/LightSourceHeaderList";
import { ChoosePartMode } from "../forms/ChoosePartMode";
import { Measurement } from "../forms/Measurement";

export class Header extends Op3dComponentBase {
  private mShowLinkedPartsButton: HTMLElement;
  // private mLightSourceDD: anLightSourceComponent;
  private mLightSourceDD: LightSourceHeaderList;
  private mLiveModeBtn: HTMLElement;

  constructor() {
    super({
      container: document.getElementById("header-container"),
      skinPath: "./skins/home/header.html",
    });
  }
  //__________________________________________________________________________________________
  protected _initElements(): void {
    this._getPart("analysis-portal-btn").addEventListener("click", () =>
      this._onAnalysisPortal()
    );
    this._getPart("check-btn").addEventListener("click", () =>
      this.onPropogateSimulation()
    );
    let aLaserIcon = this._getPart("toggle_all_rays");
    aLaserIcon.addEventListener("click", () => this.toggleLasers(aLaserIcon));
    this._getPart("quick-view-btn").addEventListener(
      "click",
      this._onShowAllAnalysis
    );
    this._getPart("zoom-in-btn", true).addEventListener("click", () =>
      this._onZoom(1)
    );
    this._getPart("group_tool", true).addEventListener("click", () =>
      ChoosePartMode.instance.enterGroupMode()
    );
    this._getPart("gaussian_analysis_table").addEventListener("click", () =>
      GaussianBeamTable.openGaussianTable()
    );

    try {
      Op3dUtils.renderReactComponentIn(
        this.mContainer,
        "black_box_btn",
        <BlackBoxWrapper />
      );

      Op3dUtils.renderReactComponentIn(
        document.body,
        "camera-view-button",
        <ViewMenuBtn />
      );
    } catch (e) {
      Op3dContext.USER_VO.isEmployeeUser && console.log(e);
    }

    this._getPart("fit-to-zoom", true).addEventListener("click", () => {
      CameraUtils.fitToZoom(eViewTypeXYZ.ISO_VIEW);
    });

    this.mLiveModeBtn = this._getPart("live-mode");
    this.mLiveModeBtn.addEventListener("click", () => this._onClickLiveMode());

    this._getPart("zoom-out-btn", true).addEventListener("click", () =>
      this._onZoom(-1)
    );

    let aMeasureIconActive = this._getPart("measurement_active", true);
    let aMeasureIcon = this._getPart("measurement", true);

    aMeasureIcon.addEventListener("click", () =>
      EventManager.dispatchEvent(EventsContext.ENTER_MEASUREMENT_MODE, this)
    );
    aMeasureIconActive.addEventListener("click", () => {
      EventManager.dispatchEvent(EventsContext.LEAVE_MEASUREMENT_MODE, this);
      Measurement.instance.close();
    });

    EventManager.addEventListener(
      EventsContext.ENTER_MEASUREMENT_MODE,
      () => {
        aMeasureIcon.classList.add("d-none");
        aMeasureIconActive.classList.remove("d-none");
        ChoosePartMode.instance.enterMesurementMode();
        Op3dContext.DIV_CONTROLLER.sideBar.enable(false);
      },
      this
    );
    EventManager.addEventListener(
      EventsContext.LEAVE_MEASUREMENT_MODE,
      () => {
        aMeasureIcon.classList.remove("d-none");
        aMeasureIconActive.classList.add("d-none");
      },
      this
    );

    // EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
    //     () => this._onUpdatePermissions(), this);

    // this._getPart('show_wire_frame').addEventListener('click',
    //     () => Op3dUtils.setWireFrame(Op3dContext.PARTS_MANAGER.selectedPart));

    let aPanBtn = this._getPart("pan_btn");
    aPanBtn.addEventListener("click", () => this._setMouseMode(aPanBtn));

    this.mShowLinkedPartsButton = this._getPart("show_links_lines");

    if (Op3dContext.USER_VO.isAdmin) {
      let mDownloadCSVBtn = this._getPart("downloadCSV");
      mDownloadCSVBtn.classList.remove("d-none");
      mDownloadCSVBtn.addEventListener("click", () =>
        SimulationRunner.instance.downloadCSV()
      );
    }

    this.mShowLinkedPartsButton.addEventListener("click", () =>
      this._toggleLinkLines()
    );

    this.mLightSourceDD = new LightSourceHeaderList(
      document.getElementById("light-source-component2")
    );

    document
      .getElementById("laser_selector_dropdown_btn")
      .addEventListener("click", () => this.mLightSourceDD.update(0));

    // this.setAdminMessage()
    $(this.mContainer)
      .find(".premium-feature-toolbar")
      .each((_index, element) => {
        if (document.body.classList.contains(Strings.BASIC_LICENSE)) {
          (element as HTMLElement).onclick = () =>
            window.open(ServerContext.pricing_base_link, "_blank");
        }
      });
  }

  //__________________________________________________________________________________________

  private _onClickLiveMode() {
    /**
     * @TODO: Liliya
     */
    //LiveHandler.instance.liveOn = ViewUtils.toggleElementActive(this.mLiveModeBtn);
  }
  //__________________________________________________________________________________________
  private toggleLasers(pIcon: HTMLElement) {
    let aIsAllShown = SimulationRunner.instance.isRaysShown();
    if (true == aIsAllShown) {
      pIcon.classList.replace("hidden", "shown");
    } else {
      pIcon.classList.replace("shown", "hidden");
    }
    SimulationRunner.instance.setRaysVisibility(!aIsAllShown);
  }
  //__________________________________________________________________________________________
  private _onShowAllAnalysis() {
    // if ((this as any).hasAttribute('active')) {
    AnalysesSynchronizer.instance.getAllFastAnalysisQV();
    // return
    // }

    // NotificationCenter.instance.pushNotification({
    //     message: MessagesHandler.ANALYSIS_MISSING_LASER,
    //     params: NotificationCenter.NOTIFICATIONS_TYPES.SIMULATION,
    // });
  }
  //__________________________________________________________________________________________
  private _setMouseMode(pPanBtn: HTMLElement) {
    let aIsPan = ViewUtils.toggleElementActive(pPanBtn);
    let aMouseMode = true == aIsPan ? eMouseMode.PAN : eMouseMode.GENERAL;
    SceneContext.OP3D_SCENE.op3dOrbitController.setMouseMode(aMouseMode);
  }
  //__________________________________________________________________________________________
  public hideLines() {
    ViewUtils.setElementActive(this.mShowLinkedPartsButton, false);
    Op3dContext.PARTS_MANAGER.showLinesVisibility(false);
  }
  //__________________________________________________________________________________________
  private _toggleLinkLines() {
    let aIsActive = ViewUtils.toggleElementActive(this.mShowLinkedPartsButton);
    Op3dContext.PARTS_MANAGER.showLinesVisibility(aIsActive);
  }
  //__________________________________________________________________________________________

  private _onAnalysisPortal() {
    AnalysisPortal.instance.open();
    AnalyticsEventsContext.triggerEvent(
      AnalyticsEventsContext.CLICK_ANALYSES_PORTAL,
      "simulation"
    );
  }
  //__________________________________________________________________________________________
  private _onZoom(pZoomDelta: number) {
    EventManager.dispatchEvent<number>(EventsContext.ON_ZOOM, this, pZoomDelta);
  }
  //__________________________________________________________________________________________
  protected async _onCreationComplete() {
    $(this.mContainer)
      .find('[data-toggle="tooltip"]')
      .tooltip()
      .on("click", function () {
        $(this).blur();
      });

    ViewUtils.makeUnselectable(this.mContainer);
    this.mIsReady = true;
  }
  //__________________________________________________________________________________________
  private onPropogateSimulation() {
    SimulationRunner.instance.runVisualizationSimulation();
    AnalyticsEventsContext.triggerEvent(
      AnalyticsEventsContext.CLICK_PROPAGATION_SIMULATION,
      "simulation"
    );
  }
  //__________________________________________________________________________________________
}
