import { iMinMax, iPoint2D } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisCanvasUtils } from "../AnalysisCanvasUtils";
import { AnalysisContext, eAnalysisDisplay } from "../AnalysisContext";
import { AnalysisDataUtils } from "../AnalysisDataUtils";
import { iAnalysisCrossSectionData } from "./AnalysisViewFactory";
import { ExtendedViewNew } from "./ExtendedViewNew";
import { Chart, ChartData } from "chart.js/auto";
import { saveAs } from 'file-saver';
import zoomPlugin from 'chartjs-plugin-zoom';
import { GraphAxisRange, iGraphRangeData } from "../components/GraphAxisRange";
import { UnitHandler } from "../../../units/UnitsHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { DataUtils } from "../../../_utils/DataUtils";
import { AnalysisConstants } from "../AnalysisConstants";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";

export class anCrossSectionGraph extends ExtendedViewNew<iAnalysisCrossSectionData> {
    private static INITIAL_WIDTH: number = 500;
    private mXPixel: HTMLInputElement;
    private mYPixel: HTMLInputElement;
    private mCurrChart: Chart;
    private mGraphContent: HTMLElement;

    constructor(pData: iAnalysisCrossSectionData) {
        super();
        this.mData = pData;
        this._setInitialPlace();
    }
    //____________________________________________________________________________
    private _setInitialPlace() {

        const aSpotWidth = this.mData.spotGraph.container.clientWidth;
        const aLeft = parseInt(this.mData.spotGraph.container.style.left) || 0;
        const aScreenWidth = window.innerWidth;
        const aTop = parseInt(this.mData.spotGraph.container.style.top) || 0;

        if (aSpotWidth + aLeft + anCrossSectionGraph.INITIAL_WIDTH < aScreenWidth) {
            this.mContainer.style.left = aLeft + aSpotWidth + 'px';
        } else {
            let aNewLeft = aLeft - anCrossSectionGraph.INITIAL_WIDTH;
            if (aNewLeft < 0) {
                let aBB = this.mContainer.getBoundingClientRect();
                aNewLeft = (window.innerWidth / 2) - (aBB.width / 2);
            }
            this.mContainer.style.left = aNewLeft + 'px';
        }

        this.mContainer.style.top = aTop + 'px';
    }
    //____________________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();
        if (this.mData !== null && this.mData !== undefined) {
            this.mData.spotGraph.onCloseExtended();
        }
    }
    //____________________________________________________________________________
    private _onPixelChanged() {

        this.mData.pixel = this._getPixelData();
        this.mData.spotGraph.onPixelViewChanged(this._getPixelData());
        this._drawGraph();
    }
    //____________________________________________________________________________
    protected _onCreationComplete(): void {
        super._onCreationComplete();

        let aFooterData = this._getPart("extended-line-data");
        ViewUtils.setElementVisibilityByDNone(aFooterData, true);

        let aCrossSection = this._getPart("cross-section-view");
        ViewUtils.setElementVisibilityByDNone(aCrossSection, true);


        let aAdjustAxesOptions = this._getPart("adjust-axes-ranges");
        aAdjustAxesOptions.addEventListener("click", () => this._onAdjustAxesRange());

        ViewUtils.removeFromParent(this.mColorMapOpt);

        this.mXPixel = this._getPart("x-pixel") as HTMLInputElement;
        this.mYPixel = this._getPart("y-pixel") as HTMLInputElement;
        this.mGraphContent = this._getPart("graph-content", true);
        this.mXPixel.addEventListener("change", () => this._onPixelChanged());
        this.mYPixel.addEventListener("change", () => this._onPixelChanged());
        ViewUtils.makeUnselectable(this.mContainer);

        ViewUtils.removeFromParent(this._getPart("download-raw", true));
        this.mIsReady = true;
    }
    //____________________________________________________________________________
    private _initPixel() {
        this.mXPixel.value = this.mData.pixel.x.toString();
        this.mYPixel.value = this.mData.pixel.y.toString();
    }
    //____________________________________________________________________________
    protected _onShown(): void {
        super._onShown();

        this._initPixel();
    }
    //____________________________________________________________________________
    private _onAdjustAxesRange() {
        $(this._getPart("extended_view_dd")).dropdown('hide');

        let aMinXLabel = parseFloat(this.mCurrChart.data.labels[0].toString());
        let aMaxXLabel = parseFloat(this.mCurrChart.data.labels[this.mCurrChart.data.labels.length - 1].toString());

        let aMaxVal = Math.max(...this.mCurrChart.data.datasets[0].data as any,
            ...this.mCurrChart.data.datasets[1].data as any);

        let aMinVal = Math.min(...this.mCurrChart.data.datasets[0].data as any,
            ...this.mCurrChart.data.datasets[1].data as any);

        let aData: iGraphRangeData = {
            x: {
                isInfinite: false,
                range: {
                    min: aMinXLabel,
                    max: aMaxXLabel,
                }
            },
            y: {
                range: {
                    min: aMinVal,
                    max: aMaxVal
                },
                isInfinite: true
            },
            onChange: (pRange: iPoint2D<iMinMax>) => this._onChangeRange(pRange)
        }
        new GraphAxisRange().open(aData);
    }
    //____________________________________________________________________________
    protected _onClickQV() { }
    //____________________________________________________________________________
    private _onChangeRange(pRange: iPoint2D<iMinMax>) {

        this.mCurrChart.config.options.scales.y.min = pRange.y.min;
        this.mCurrChart.config.options.scales.y.max = pRange.y.max;

        let aLabelsLength = this.mCurrChart.data.labels.length;
        let aMinLabel = parseFloat(this.mCurrChart.data.labels[0].toString());
        let aMaxLabel = parseFloat(this.mCurrChart.data.labels[aLabelsLength - 1].toString());
        let aRange = (aMaxLabel - aMinLabel);
        if (aRange <= 0) {
            this.mCurrChart.update();
            return;
        }

        let aStartPixelX = ((pRange.x.min - aMinLabel) / aRange) * aLabelsLength;
        if (aStartPixelX < 0) {
            aStartPixelX = 0;
        }

        let aEndPixelX = ((pRange.x.max - aMinLabel) / aRange) * aLabelsLength;
        if (aEndPixelX > aLabelsLength) {
            aEndPixelX = aLabelsLength;
        }

        if (aStartPixelX >= aEndPixelX) {
            this.mCurrChart.update();
            return;
        }

        this.mCurrChart.config.options.scales.x.min = Math.floor(aStartPixelX);
        this.mCurrChart.config.options.scales.x.max = Math.floor(aEndPixelX);
        this.mCurrChart.update();
    }
    //____________________________________________________________________________
    private _getPixelData() {
        let aPixel: iPoint2D = {
            x: parseInt(this.mXPixel.value) - 1,
            y: parseInt(this.mYPixel.value) - 1,
        }

        return aPixel;
    }
    //____________________________________________________________________________
    private _getPoints(pOptions: { pixel?: iPoint2D }) {

        if (pOptions.pixel == null) {
            pOptions.pixel = this._getPixelData();
        }

        let aXPoints = AnalysisDataUtils.getCrossSectionValues(
            this.mData.cacheData,
            eAnalysisDisplay.X_CROSS_SECTION, pOptions.pixel.y);

        let aPointsXFiltered = aXPoints.map(point => point.y);


        let aYPoints = AnalysisDataUtils.getCrossSectionValues(
            this.mData.cacheData,
            eAnalysisDisplay.Y_CROSS_SECTION, pOptions.pixel.x);

        let aPointsYFiltered = aYPoints.map(point => point.y);

        let aPoints = {
            x: aPointsXFiltered,
            y: aPointsYFiltered,
        }

        return { pixel: pOptions.pixel, points: aPoints };
    }
    //______________________________________________________________________________________________
    protected _onDownloadXLSX() {
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: this.mData.cacheData.name,
            Author: Op3dContext.USER_VO.userVO.name.first + " " + Op3dContext.USER_VO.userVO.name.last,
            CreatedDate: new Date()
        };

        const aBase = this.getXLSXHeader();

        let aContentX = Array<Array<any>>();
        let aSizeX = DataUtils.getSize(this.mData.cacheData.common_data.x_range);
        let aEachPixelX = aSizeX / (this.mData.cacheData.common_data.resolution.y - 1);
        const aData = this._getPoints({});

        const aUnit = UnitHandler.shortSign;
        aContentX[0] = [`X Coordinate [${aUnit}]`, "Value [W/cm^2]"];
        for (let i = 0; i < aData.points.x.length; i++) {
            let aXVal = this.mData.cacheData.common_data.x_range.min + (i * aEachPixelX);
            aContentX[1 + i] = [aXVal, aData.points.x[i]];
        }

        this._addSheet(wb, "x", aBase, aContentX);
        let aSizeY = DataUtils.getSize(this.mData.cacheData.common_data.y_range);
        let aEachPixelY = aSizeY / (this.mData.cacheData.common_data.resolution.x - 1);


        let aContentY = Array<Array<any>>();
        aContentY[0] = [`Y Coordinate [${aUnit}]`, "Value [W/cm^2]"];
        for (let i = 0; i < aData.points.y.length; i++) {
            let aYVal = this.mData.cacheData.common_data.y_range.min + (i * aEachPixelY);
            aContentY[1 + i] = [aYVal, aData.points.y[i]];
        }

        this._addSheet(wb, "y", aBase, aContentY);
        let aWBOutBinary = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        let aBuffer = new ArrayBuffer(aWBOutBinary.length);
        let aView = new Uint8Array(aBuffer);
        for (let i = 0; i < aWBOutBinary.length; i++) {
            aView[i] = aWBOutBinary.charCodeAt(i) & 0xFF;
        }

        saveAs(new Blob([aBuffer], { type: "application/octet-stream" }),
            this.mData.cacheData.name + ".xlsx");
    }
    //____________________________________________________________________________
    protected _drawGraph() {



        let aPointsData = this._getPoints({ pixel: this.mData.pixel });

        let aGraphCanvas = document.createElement('canvas');
        ViewUtils.clearElementsChildren(this.mGraphContent)
        this.mGraphContent.appendChild(aGraphCanvas);
        aGraphCanvas.id = Op3dUtils.idGenerator();
        let aBB = this.mGraphContent.getBoundingClientRect();
        aGraphCanvas.width = aBB.width;
        aGraphCanvas.height = aBB.height;
        let a2D = aGraphCanvas.getContext('2d');
        this.mGraphContent.classList.add("d-flex", "justify-content-center")


        const aRangePoints = AnalysisCanvasUtils.getRange(this.mData.cacheData, this.mData.analysisItem.display)
        let aLabels = [];

        let i = aRangePoints.x.min;
        let aRange = aRangePoints.x.max - aRangePoints.x.min;
        let aDelta = aRange / aPointsData.points.x.length
        do {
            aLabels.push(OP3DMathUtils.toFixed(i, Op3dContext.SETUPS_MANAGER.settings.numericAccuracy));
            i += aDelta;
        } while (i < aRangePoints.x.max)

        const zoomOptions = {
            pan: {
                enabled: true,
                mode: 'xy',
                modifierKey: 'ctrl'
            },

            zoom: {
                drag: {
                    enabled: true,
                    backgroundColor: 'rgba(168,202,234,0.5)',
                    borderColor: 'rgba(168,202,234,1)',
                    borderWidth: 1
                },
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
            }


        };
        const data: ChartData = {
            labels: aLabels,
            datasets: [{
                label: 'x-cross section',
                data: aPointsData.points.x,
                pointRadius: 0,
                borderWidth: 2,
                backgroundColor: AnalysisContext.SPOT_CROSS_SECTION_OPTIONS.X_PATH_COLOR,
                borderColor: AnalysisContext.SPOT_CROSS_SECTION_OPTIONS.X_PATH_COLOR,
            },
            {
                label: 'y-cross section',
                data: aPointsData.points.y,
                pointRadius: 0,
                borderWidth: 2,
                backgroundColor: AnalysisContext.SPOT_CROSS_SECTION_OPTIONS.Y_PATH_COLOR,
                borderColor: AnalysisContext.SPOT_CROSS_SECTION_OPTIONS.Y_PATH_COLOR,
            }
            ]
        };


        const config = {
            type: 'line',
            data: data,
            options: {
                scales: this._getInitialScales(),
                plugins: {
                    tooltip: {
                        intersect: false,
                        displayColors: false
                    },
                    legend: {
                        display: true
                    },
                    zoom: zoomOptions,
                }
            }
        };

        this.mCurrChart = new Chart(a2D!, config as any);
        Chart.register(zoomPlugin);

    }
    //____________________________________________________________________________
    private _getInitialScales() {
        // let aData = this.mData.analysisItem.type == eAnalysisType.ADVANCED ?
        // AnalysisContext.ADVANCED_ANALYSIS_DATA :
        //  AnalysisContext.FAST_ANALYSIS_DATA;
        // aData.find(item => item.name == this.mData.analysisItem.name);
        const aDataItem = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.type === this.mData.analysisItem.type && item.name === this.mData.analysisItem.name);
        if (aDataItem === undefined) {
            throw new Error("missing analysis data");
        }

        let aAxisLabels: iPoint2D<string> = {
            x: `${AnalysisContext.XY_CROSS_SECTION_LABELS.x} [${UnitHandler.shortSign}]`,
            y: `${aDataItem.label2} ${aDataItem.unitLabel}`
        }

        return {
            x: {
                title: {
                    display: true,
                    text: aAxisLabels.x
                }
            },
            y: {
                title: {
                    display: true,
                    text: aAxisLabels.y
                }
            }
        };
    }
    //____________________________________________________________________________
    protected _onZoom(pZoomLevel: number): void {
        if (pZoomLevel < 0) {
            // zoom in
            this.mCurrChart.zoom(1.1)

        } else {
            this.mCurrChart.zoom(0.9)
        }



        // this.mCurrChart.update();
    }
    //____________________________________________________________________________
    protected _onReset(): void {

        let aScales = this._getInitialScales();
        // delete this.mCurrChart.config.options.scales.y.min;
        // delete this.mCurrChart.config.options.scales.y.max;
        // delete this.mCurrChart.config.options.scales.x.min;
        this.mCurrChart.config.options.scales = aScales;
        this.mCurrChart.resetZoom();
    }
    //____________________________________________________________________________

}