﻿import { EventManager } from "../../../../oc/events/EventManager";
import { eMaterialEquationType } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iMaterialMetaDataVO, iMinMax, iMaterialVO, iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { MaterialUtils } from "../../../_utils/MaterialUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { MaterialDataLoader } from "../../../data/data_loader/MaterialDataLoader";
import { ViewUtils } from "../../ViewUtils";
import { MaterialInformationForm } from "../../infos/MaterialInformationForm";
import { uoSection } from "./uoSection";

export interface iUOMaterialProps {
    nDisabledState: boolean;
    /**
     * @description whether to enable input elements
     */
    enableInputElements: boolean;
    /**
     * @description whether the form has a listeners to change event of the input of all material fields
     */
    hasParametersListeners: boolean;
    /**
     * @description whether the form has a listener to change event of the select
     */
    isHaveMaterialEquationTypeListener: boolean;
    /**
     * @description whether the form has a listener to change event of the select
     */
    isHaveNListener: boolean;
    /**
     * @description the state of disable of the custom option in select element
     */
    customOptionDisableState: boolean;
    /**
     * @description whether the select has a custom option
     */
    hasCustomOption: boolean;
}

export interface iuoMaterialData {
    updateFocusesFunction: Function;
    materials: Array<iMaterialMetaDataVO>;
    materialProps: iUOMaterialProps;
}

export interface iMaterialParam<T = any> {
    name: string;
    path: string;
    unit?: string;
    range?: iMinMax<number>;
    rangeFunc?: (pVal: number, pData?: T) => number;
    value?: number;
}

export interface iuoMaterial {
    materialID: string;
    wavelength: number;
}

export class uoMaterial extends uoSection<iuoMaterial, string> {

    private static SKIN_PATH = './skins/forms/optics/uo_material.html';


    public static CAUCHY_SCHOTT_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            path: 'parameters.coeffs.a'
        },
        {
            name: 'B',
            unit: 'μm<sup>-2</sup>',
            path: 'parameters.coeffs.b'
        },
        {
            name: 'C',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c'
        },
        {
            name: 'D',
            unit: 'μm<sup>4</sup>',
            path: 'parameters.coeffs.d'
        },
        {
            name: 'E',
            unit: 'μm<sup>6</sup>',
            path: 'parameters.coeffs.e'
        },
        {
            name: 'F',
            unit: 'μm<sup>8</sup>',
            path: 'parameters.coeffs.f'
        },
        {
            name: 'G',
            unit: 'μm<sup>10</sup>',
            path: 'parameters.coeffs.g'
        },
        {
            name: 'H',
            unit: 'μm<sup>12</sup>',
            path: 'parameters.coeffs.h'
        }];

    public static CONRADY1_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            path: 'parameters.coeffs.a'
        },
        {
            name: 'B',
            unit: 'μm<sup></sup>',
            path: 'parameters.coeffs.b'
        },
        {
            name: 'C',
            unit: 'μm<sup>3.5</sup>',
            path: 'parameters.coeffs.c'
        }];

    public static SELLMEIER_PARAMS: Array<iMaterialParam> = [
        {
            name: 'B1',
            path: 'parameters.coeffs.b1'
        },
        {
            name: 'C1',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c1'
        },
        {
            name: 'B2',
            path: 'parameters.coeffs.b2'
        },
        {
            name: 'C2',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c2'
        },
        {
            name: 'B3',
            path: 'parameters.coeffs.b3'
        },
        {
            name: 'C3',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c3'
        }
    ];

    public static MODIFIED_SELLMEIER_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            path: 'parameters.coeffs.a'
        },
        ...uoMaterial.SELLMEIER_PARAMS
    ];

    private mMaterialsDropdown: HTMLSelectElement;
    private mMaterialTypeDropDown: HTMLSelectElement;
    private mOneParamElement: HTMLElement;
    private mParamsParent: HTMLElement;
    private mMaterialVO: iMaterialVO;
    private mLambdaDB: iOP3DHTMLInputElement;
    private mN_Input: HTMLInputElement;
    private mRefractiveIndexElement: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pTitle: string) {
        super(pContainer, {
            skinPath: uoMaterial.SKIN_PATH,
            title: pTitle,
            isNewSkin: false,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    public discard() {
        ViewUtils.removeFromParent(this.mContainer);
        this.mN_Input = null;
        this.mLambdaDB = null;
        this.mMaterialVO = null;
        this.mMaterialsDropdown = null;
        this.mMaterialTypeDropDown = null;
        this.mOneParamElement = null;
        this.mParamsParent = null;
        this.mRefractiveIndexElement = null;
        this._clear();
    }
    //__________________________________________________________________________________________
    public getData(): string {
        return this.mMaterialsDropdown.value;
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: iuoMaterial) {
        this.mMaterialsDropdown.value = pData.materialID;
        this.mLambdaDB.value = (null != pData.wavelength) ? pData.wavelength.toString() : '550';
        this.mLambdaDB.prevValue = this.mLambdaDB.value;
        this._onChangeMaterial(false);
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param');
        this.mParamsParent = this.mOneParamElement.parentElement;
        this._clear();

        this.mMaterialsDropdown = Op3dUtils.getElementIn(this.mContainer,
            'materials-select', true) as HTMLSelectElement;
        this.mMaterialTypeDropDown = Op3dUtils.getElementIn(this.mContainer,
            'materials-type-dropdown') as HTMLSelectElement;
        this.mLambdaDB = Op3dUtils.getElementIn(this.mContainer,
            'lambda_input') as iOP3DHTMLInputElement;
        this.mN_Input = Op3dUtils.getElementIn(this.mContainer,
            'refractive_index') as HTMLInputElement;

        let aMaterials = await Op3dContext.DATA_MANAGER.getMaterials();
        for (let i = 0; i < aMaterials.length; i++) {
            let aOption = document.createElement('option');
            aOption.text = aMaterials[i].name;
            aOption.value = aMaterials[i].number_id;

            this.mMaterialsDropdown.appendChild(aOption);
        }

        this.mLambdaDB.addEventListener('change', () => this._onChangeLambda());
    }
    //__________________________________________________________________________________________
    private _onChangeLambda() {
        let aVal = parseFloat(this.mLambdaDB.value);
        if (true == isNaN(aVal)) {
            this.mLambdaDB.value = this.mLambdaDB.prevValue;
            return;
        }

        if (aVal < 0) {
            aVal = 0;
        }

        this.mLambdaDB.value = aVal.toString();
        this.mLambdaDB.prevValue = this.mLambdaDB.value;

        EventManager.dispatchEvent(EventsContext.MATERIAL_WL_CHANGED, this, aVal);
        this._onChangeMaterial(false)
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mMaterialsDropdown.addEventListener('change', () => this._onChangeMaterial(true));
        Op3dUtils.getElementIn(this.mContainer, 'material_info').addEventListener('click',
            () => MaterialInformationForm.instance.open(this.mMaterialVO));
    }
    //__________________________________________________________________________________________
    private async _onChangeMaterial(pToUpdate: boolean) {
        let aNumberID = this.mMaterialsDropdown.value;
        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({
            number_id: aNumberID
        });
        this.mMaterialVO = aMaterialVO;
        this._fillData(aMaterialVO);

        if (true == pToUpdate) {
            EventManager.dispatchEvent(EventsContext.OPTICS_MAT_CHANGE, this)
            EventManager.dispatchEvent(EventsContext.OPTOMECH_MAT_CHANGE, this)
        }
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mParamsParent);
    }
    //__________________________________________________________________________________________
    protected _fillData(pMaterialVO: iMaterialVO) {
        this._clear();

        let aEnableRefractiveIndex = false;
        let aType = pMaterialVO.parameters.type;
        this.mMaterialTypeDropDown.value = aType.toString();

        switch (aType) {
            case eMaterialEquationType.CAUCHY:
            case eMaterialEquationType.SCHOTT:
                this._createParams(uoMaterial.CAUCHY_SCHOTT_PARAMS, pMaterialVO);
                break;
            case eMaterialEquationType.SELLMEIER:
                this._createParams(uoMaterial.SELLMEIER_PARAMS, pMaterialVO);
                break;
            case eMaterialEquationType.MODIFIED_SELLMEIER:
                this._createParams(uoMaterial.MODIFIED_SELLMEIER_PARAMS, pMaterialVO);
                break;
            case eMaterialEquationType.CONRADY1:
                this._createParams(uoMaterial.CONRADY1_PARAMS, pMaterialVO);
                aEnableRefractiveIndex = true;
                break;
            case eMaterialEquationType.CONSTANT:
                this._createParams([], pMaterialVO);
                aEnableRefractiveIndex = true;
                break;
        }

        ViewUtils.setElementDisabled(this.mRefractiveIndexElement, aEnableRefractiveIndex);
        let aLambda = parseFloat(this.mLambdaDB.value);

        let aN = MaterialUtils.getN(pMaterialVO, aLambda);
        this.mN_Input.title = aN.toString();
        this.mN_Input.value = aN.toString();
    }
    //__________________________________________________________________________________________
    private _createParams(pData: Array<iMaterialParam>, pMaterialVO: iMaterialVO) {
        for (let i = 0; i < pData.length; i++) {
            let aData = pData[i];

            let aName = aData.name;
            let aUnit = aData.unit;
            let aPath = aData.path.split('.');


            let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
            this.mParamsParent.appendChild(aOneParam);

            Op3dUtils.getElementIn(aOneParam, 'param_name').innerHTML = aName;

            let aUnitElement = Op3dUtils.getElementIn(aOneParam, 'unit_span');
            if (null != aUnit) {
                aUnitElement.innerHTML = aUnit;
            } else {
                ViewUtils.removeFromParent(aUnitElement.parentElement);
            }

            let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as HTMLInputElement;
            this._fillInput(pMaterialVO, aPath, aInput);
        }
    }
    //__________________________________________________________________________________________
    public setMaterialsSelectDisabled(pVal: boolean) {
        ViewUtils.setElementDisabled(this.mMaterialsDropdown, pVal);
    }
    //__________________________________________________________________________________________
}
