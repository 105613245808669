import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { iBoxParams } from "../_utils/MatrixUtils";
import { OpticUtils } from "../_utils/OpticUtils";
import { iOpticsVO, iOpticsVOGeometry, iBallLens, iGeneralConicLens, iMultiPlet, iCircularOffAxisParabolic, iCircularParabolic, iThinCircularLens, iDovePrism, iCylindrical, iAxiconPrism, iASphericLens, iPellinBrocaPrism, iBSCube, iRhombicPrism, iPentaPrism, iEquilateralPrism, iCircularWedgePrism, iRectWedgePrism, iRightAnglePrism, iRectBaseRoofPrismMirror, iGeneralRectConicLens, iRectangularCylindrical } from "../data/VO/OpticsVOInterfaces";
import { MathContext } from "./MathContext";
import { eOpticShape, eBaseShape, eOpticsTypeNames, OpticsContext } from "./OpticsContext";

export type tBrands = 'Thorlabs' | 'Knight Optical' | 'Edmund Optics' | 'OptoSigma' |
    'Standa' | 'Union Optic' | 'Holmarc Opto-Mechatronics' | 'Union Optic' | 'Ross Optical' | "Iridian Spectral Technologies" |
    'Simtrum' | 'EKSMA Optics' | 'Qioptiq Photonics' | 'Alkor Technologies' | 'Altechna' | "CeNing Optics" |
    'Laser Components' | 'MKS/Newport' | 'CVI Laser Optics' | '3DOptix' | 'WTS Photonics' | '';

export class OpticsDBConvertor {


    //__________________________________________________________________________________________
    public static getConvertedOptics(pOpticsVO: iOpticsVO) {

        let aGeometry = pOpticsVO.parameters.geometry;
        let aBrand = pOpticsVO.parameters.info != null ?
            pOpticsVO.parameters.info.brand as tBrands : null;

        // the optics was made from the program like EFL calculator 
        if (aBrand == null) {
            return aGeometry;
        }

        switch (pOpticsVO.parameters.shape) {
            case eOpticShape.DOVE_PRISM:
                return OpticsDBConvertor._getDovePrismGeometry(aBrand, aGeometry);
            case eOpticShape.AXICON_PRISM:
                return OpticsDBConvertor._getAxiconGeometry(aBrand, aGeometry);
            case eOpticShape.CYLINDRICAL:
                if (eBaseShape.RECTANGULAR == pOpticsVO.parameters.baseShape) {
                    return OpticsDBConvertor._getRectCylindricalGeometry(aBrand, aGeometry);
                } else {
                    return OpticsDBConvertor._getCircleCylindricalGeometry(aBrand, aGeometry);
                }
            case eOpticShape.ASPHERIC:
                return OpticsDBConvertor._getAspheicGeometry(aBrand, aGeometry);
            case eOpticShape.PELLIN_BROCA_PRISM:
                return OpticsDBConvertor._getPellinBorcaGeometry(aBrand, aGeometry);
            case eOpticShape.RHOMBIC_PRISM:
                return OpticsDBConvertor._getRhombicPrismGeometry(aBrand, aGeometry);

            case eOpticShape.BS_CUBE:
                return OpticsDBConvertor._getBsCubeGeometry(aBrand, aGeometry);

            case eOpticShape.PENTA_PRISM:
                return OpticsDBConvertor._getPentaPrismGeometry(aBrand, aGeometry);
            case eOpticShape.EQUAL_PRISM:
                return OpticsDBConvertor._getEqualPrismGeometry(aBrand, aGeometry);

            case eOpticShape.WEDGE:
                return this._getWedgeGeometry(pOpticsVO.parameters.baseShape,
                    aBrand, pOpticsVO.parameters.geometry, pOpticsVO.parameters.subType);

            case eOpticShape.RIGHT_ANGLE_PRISM:
                if (eOpticsTypeNames.PRISM == pOpticsVO.parameters.type) {
                    return OpticsDBConvertor._getRightAnglePrismGeometry(aBrand, aGeometry);
                } else if (eOpticsTypeNames.MIRROR == pOpticsVO.parameters.type) {
                    return OpticsDBConvertor._getMRightAnglePrismGeometry(aBrand, aGeometry);
                }
                break;
            case eOpticShape.ROOF_PRISM_MIRROR:
                if (eBaseShape.RECTANGULAR == pOpticsVO.parameters.baseShape) {
                    return OpticsDBConvertor._getRectBaseRoofPrism(aBrand, aGeometry);
                }
                break;
            case eOpticShape.SPHERICAL:
                return this._getSphericalGeometry(aBrand, aGeometry);
            case eOpticShape.CONIC:
                return this._getConicGeometry(aBrand, aGeometry);

            case eOpticShape.THIN_LENS:
                return this._getThinLensGeometry(pOpticsVO.parameters.baseShape, aBrand,
                    aGeometry, pOpticsVO.parameters.type);

            case eOpticShape.GRATING:
                if (eBaseShape.CIRCULAR == pOpticsVO.parameters.baseShape) {
                    return this._getGratingCircularThinLensGeometry(aBrand, aGeometry);
                } else if (eBaseShape.RECTANGULAR == pOpticsVO.parameters.baseShape) {
                    return this._getGratingRectThinLensGeometry(aBrand, aGeometry);
                }
                break
            case eOpticShape.PARABOLIC:
                if (eBaseShape.CIRCULAR == pOpticsVO.parameters.baseShape) {
                    return OpticsDBConvertor._getCircularParabolic(aBrand, aGeometry);
                }
                break
            case eOpticShape.OFF_AXIS_PARABOLIC:
                if (eBaseShape.CIRCULAR == pOpticsVO.parameters.baseShape) {
                    return OpticsDBConvertor._getCircularOffAxisParabolic(aBrand, aGeometry);
                }
                break
            case eOpticShape.MULTIPLET:
                return OpticsDBConvertor._getMultiplet(aBrand, aGeometry);
            case eOpticShape.BALL_LENS:
                return OpticsDBConvertor._getBallLens(aBrand, aGeometry);
            case eOpticShape.HALF_BALL_LENS:
                return OpticsDBConvertor._getHalfBallLens(aBrand, aGeometry);

            default:
                break;
        }

        return aGeometry;
    }
    //__________________________________________________________________________________________
    private static _getHalfBallLens(_pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aGeometry = pGeometry as iBallLens;

        const aDiameter = aGeometry.diameter
        const aRadiusCurvature = (aGeometry.diameter / 2) + MathContext.EPSILON_10;
        const aSphereHeight = this._sphereHeight(aRadiusCurvature, aDiameter);
        let c = (1 / aRadiusCurvature);

        let aDeformation = (null != pGeometry.deformation) ? pGeometry.deformation : null;
        let aGenericConic: iGeneralConicLens = {
            back: {
                cx: 0,
                cy: 0,
                kx: 0,
                ky: 0,
                diameter: aDiameter,
                deformations: (null != aDeformation) ? aDeformation.back : undefined
            },
            front: {
                cx: c,
                cy: c,
                kx: 0,
                ky: 0,
                diameter: aDiameter,
                deformations: (null != aDeformation) ? aDeformation.front : undefined
            },
            thickness_center: aSphereHeight
        };

        return aGenericConic;
    }
    //______________________________________________________________________________________________
    private static _getBallLens(_pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aGeometry = pGeometry as iBallLens;

        const aDiameter = aGeometry.diameter
        const aRadiusCurvature = (aGeometry.diameter / 2) + MathContext.EPSILON_10;
        const aSphereHeight = this._sphereHeight(aRadiusCurvature, aDiameter) * 2;
        let c = (1 / aRadiusCurvature);

        let aDeformation = (null != pGeometry.deformation) ? pGeometry.deformation : null;
        let aGenericConic: iGeneralConicLens = {
            back: {
                cx: -c,
                cy: -c,
                kx: 0,
                ky: 0,
                diameter: aDiameter,
                deformations: (null != aDeformation) ? aDeformation.back : undefined
            },
            front: {
                cx: c,
                cy: c,
                kx: 0,
                ky: 0,
                diameter: aDiameter,
                deformations: (null != aDeformation) ? aDeformation.front : undefined
            },
            thickness_center: aSphereHeight
        };

        return aGenericConic;
    }
    //__________________________________________________________________________________________
    private static _sphereHeight(pRadiusCurvatre: number, pDiameter: number) {

        let rsgm = 0.5 * pDiameter;
        rsgm = pRadiusCurvatre * pRadiusCurvatre - rsgm * rsgm
        if (rsgm < 0) {
            return 0;
        }

        return pRadiusCurvatre - Math.pow(rsgm, 0.5);
    }
    //__________________________________________________________________________________________
    private static _getMultiplet(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aGeometry: iMultiPlet;

        let aThicknesses: Array<number>;
        let aRadiuses: Array<number>;

        switch (pBrand) {
            // user defined case 
            case (""):
                aThicknesses = new Array<number>();
                aThicknesses.push(pGeometry.thickness_center_1);
                aThicknesses.push(pGeometry.thickness_center_2);

                if (undefined != pGeometry.thickness_center_3) {
                    aThicknesses.push(pGeometry.thickness_center_3);
                }

                aRadiuses = new Array<number>();
                aRadiuses.push(pGeometry.r1);
                aRadiuses.push(pGeometry.r2);
                aRadiuses.push(pGeometry.r3);

                if (undefined != pGeometry.r4) {
                    aRadiuses.push(pGeometry.r4);
                }

                aGeometry = {
                    diameter: pGeometry.diameter,
                    multiplet_r: aRadiuses,
                    multiplet_thickness_center: aThicknesses
                };
                break;
            case "Thorlabs":
                aThicknesses = new Array<number>();
                aThicknesses.push(pGeometry.thickness_center_1);
                aThicknesses.push(pGeometry.thickness_center_2);
                if (undefined != pGeometry.thickness_center_3) {
                    aThicknesses.push(pGeometry.thickness_center_3);
                }
                aRadiuses = new Array<number>();
                aRadiuses.push(pGeometry.r1);
                aRadiuses.push(pGeometry.r2);
                aRadiuses.push(pGeometry.r3);
                if (undefined != pGeometry.r4) {
                    aRadiuses.push(pGeometry.r4);
                }

                aGeometry = {
                    diameter: pGeometry.diameter,
                    multiplet_r: aRadiuses,
                    multiplet_thickness_center: aThicknesses
                };

                break;
            case "Edmund Optics":
                aThicknesses = new Array<number>();
                aThicknesses.push(pGeometry.thickness_center);
                aThicknesses.push(pGeometry.thickness_center_a);
                if (undefined != pGeometry.thickness_center_b) {
                    aThicknesses.push(pGeometry.thickness_center_b);
                }
                aRadiuses = new Array<number>();
                aRadiuses.push(pGeometry.r1);
                aRadiuses.push(pGeometry.r2);
                aRadiuses.push(pGeometry.r3);
                if (undefined != pGeometry.r4) {
                    aRadiuses.push(pGeometry.r4);
                }

                aGeometry = {
                    diameter: pGeometry.diameter,
                    multiplet_r: aRadiuses,
                    multiplet_thickness_center: aThicknesses
                };
                break;
            case "MKS/Newport":
                aThicknesses = new Array<number>();
                aThicknesses.push(pGeometry.thickness_center);
                aThicknesses.push(pGeometry.thickness_center_1);
                if (undefined != pGeometry.thickness_center_2) {
                    aThicknesses.push(pGeometry.thickness_center_2);
                }
                aRadiuses = new Array<number>();
                aRadiuses.push(pGeometry.r);
                aRadiuses.push(pGeometry.r1);
                aRadiuses.push(pGeometry.r2);
                if (undefined != pGeometry.r3) {
                    aRadiuses.push(pGeometry.r3);
                }

                aGeometry = {
                    diameter: pGeometry.diameter,
                    multiplet_r: aRadiuses,
                    multiplet_thickness_center: aThicknesses
                };
                break;
            case "Union Optic":
                aThicknesses = new Array<number>();
                aThicknesses.push(pGeometry.thickness_center);
                aThicknesses.push(pGeometry.thickness_center_1);
                if (undefined != pGeometry.thickness_center_2) {
                    aThicknesses.push(pGeometry.thickness_center_2);
                }
                aRadiuses = new Array<number>();
                aRadiuses.push(pGeometry.r1);
                aRadiuses.push(pGeometry.r2);
                aRadiuses.push(pGeometry.r3);
                if (undefined != pGeometry.r4) {
                    aRadiuses.push(pGeometry.r4);
                }

                aGeometry = {
                    diameter: pGeometry.diameter,
                    multiplet_r: aRadiuses,
                    multiplet_thickness_center: aThicknesses
                };
                break;
            default:
                aGeometry = pGeometry as iMultiPlet;
        }

        return aGeometry;
    }
    //__________________________________________________________________________________________
    private static _getWedgeGeometry(pBaseShape: eBaseShape, pBrand: tBrands,
        pGeometry: iOpticsVOGeometry, pSubtype: string) {

        if (eBaseShape.CIRCULAR == pBaseShape) {
            return OpticsDBConvertor._getCirleWedgePrismGeometry(pBrand, pGeometry, pSubtype);
        } else if (eBaseShape.RECTANGULAR == pBaseShape) {
            return OpticsDBConvertor._getRectWedgePrismGeometry(pBrand, pGeometry, pSubtype);
        }
    }
    //__________________________________________________________________________________________
    private static _getThinLensGeometry(pBaseShape: eBaseShape, pBrand: tBrands,
        pGeometry: iOpticsVOGeometry, pType: string) {
        if (eBaseShape.CIRCULAR == pBaseShape) {
            return this._getCircularThinLensGeometry(pBrand, pGeometry);
        } else if (eBaseShape.RECTANGULAR == pBaseShape) {
            return this._getRectThinLensGeometry(pBrand, pGeometry, pType);
        }
    }
    //__________________________________________________________________________________________
    private static _getRectThinLensGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry, pType: string) {
        let aBoxGeometry: iBoxParams;

        switch (pType) {
            // thin BS
            case eOpticsTypeNames.BEAM_SPLITTER:
                switch (pBrand) {
                    case "Qioptiq Photonics":
                        aBoxGeometry = {
                            width: pGeometry.b,
                            height: pGeometry.l,
                            thickness: pGeometry.d
                        };
                        break;
                    case "Knight Optical":
                        aBoxGeometry = {
                            width: pGeometry.width,
                            height: pGeometry.length,
                            thickness: pGeometry.thickness
                        };
                        break;
                    default:
                        aBoxGeometry = pGeometry as iBoxParams;
                        break;
                }
                break;


            case eOpticsTypeNames.FILTER:

                switch (pBrand) {
                    case "OptoSigma":
                        aBoxGeometry = {
                            width: pGeometry.a,
                            height: pGeometry.b,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Iridian Spectral Technologies":
                    case "Knight Optical":
                        aBoxGeometry = {
                            width: pGeometry.width,
                            height: pGeometry.length,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Laser Components":
                        aBoxGeometry = {
                            width: pGeometry.width,
                            height: pGeometry.length,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Qioptiq Photonics":
                        aBoxGeometry = {
                            width: pGeometry.l,
                            height: pGeometry.h,
                            thickness: pGeometry.d
                        };
                        break;
                    default:
                        aBoxGeometry = pGeometry as iBoxParams;
                        break;
                }
                break;

            case eOpticsTypeNames.WINDOW:
                switch (pBrand) {
                    case "Edmund Optics":
                        aBoxGeometry = {
                            width: pGeometry.width,
                            height: pGeometry.length,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Laser Components":
                    case "Knight Optical":
                    case "EKSMA Optics":
                        aBoxGeometry = {
                            width: pGeometry.width,
                            height: pGeometry.length,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Holmarc Opto-Mechatronics":
                    case "OptoSigma":
                        aBoxGeometry = {
                            width: pGeometry.a,
                            height: pGeometry.b,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Qioptiq Photonics":
                        aBoxGeometry = {
                            width: pGeometry.b,
                            height: pGeometry.l,
                            thickness: pGeometry.d
                        };
                        break;
                    default: {
                        aBoxGeometry = pGeometry as iBoxParams;
                        break;
                    }
                }
                break;

            default: {
                switch (pBrand) {
                    case "OptoSigma":
                        aBoxGeometry = {
                            width: pGeometry.a,
                            height: pGeometry.b,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "EKSMA Optics":
                    case "Knight Optical":
                    case "Edmund Optics":
                    case "Laser Components":
                        aBoxGeometry = {
                            width: pGeometry.width,
                            height: pGeometry.length,
                            thickness: pGeometry.thickness
                        };
                        break;
                    case "Qioptiq Photonics":
                        aBoxGeometry = {
                            width: pGeometry.b,
                            height: pGeometry.l,
                            thickness: pGeometry.d
                        };
                        break;
                    default:
                        aBoxGeometry = pGeometry as iBoxParams;
                        break;
                }
            }
                break;
        }

        return aBoxGeometry;
    }
    //__________________________________________________________________________________________
    private static _getGratingRectThinLensGeometry(pBrand: tBrands,
        pGeometry: iOpticsVOGeometry) {
        let aGeometry: iBoxParams
        switch (pBrand) {
            case "Thorlabs":
                aGeometry = {
                    width: pGeometry.width,
                    thickness: pGeometry.d,
                    height: pGeometry.height,
                }
                break;
            case "Edmund Optics":
                aGeometry = {
                    height: pGeometry.length,
                    width: pGeometry.width,
                    thickness: pGeometry.thickness
                }
                break;
            default:
                aGeometry = {
                    height: pGeometry.height,
                    width: pGeometry.width,
                    thickness: pGeometry.thickness
                }
                break;
        }

        return aGeometry;
    }
    //__________________________________________________________________________________________
    public static getOffAxisAngle(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        switch (pBrand) {
            case "3DOptix":
            case "Edmund Optics":
            case "Thorlabs":
                return (pGeometry.off_axis_angle * OP3DMathUtils.DEG_TO_RAD);
            case "Standa":
                return pGeometry.alpha;
            default:
                return pGeometry.off_axis_angle * OP3DMathUtils.DEG_TO_RAD;
        }
    }
    //__________________________________________________________________________________________
    private static _getCircularOffAxisParabolic(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aGeometry: iCircularOffAxisParabolic;
        let aOffAxisAngle = OpticsDBConvertor.getOffAxisAngle(pBrand, pGeometry);

        switch (pBrand) {
            case "3DOptix":
            case "Edmund Optics":
                aGeometry = {
                    diameter: pGeometry.diameter,
                    thickness_edge: pGeometry.a,
                    off_axis_angle: aOffAxisAngle,
                    radius_of_curvature: pGeometry.radius_of_curvature,
                };
                break;
            case "Thorlabs":
                aGeometry = {
                    diameter: pGeometry.diameter,
                    thickness_edge: pGeometry.thickness,
                    off_axis_angle: aOffAxisAngle,
                    radius_of_curvature: (2 * pGeometry.pfl),
                };
                break;
            case "Standa":
                let R = (2 * pGeometry.pfl);
                let x0 = (Math.tan(aOffAxisAngle / 2) * R);
                let zA = (1 / (2 * R)) * Math.pow((x0 + (pGeometry.diameter / 2)), 2);
                let zC = (1 / (2 * R)) * Math.pow((x0 - (pGeometry.diameter / 2)), 2);

                aGeometry = {
                    diameter: pGeometry.diameter,
                    thickness_edge: (6.35 + zA - zC),
                    off_axis_angle: aOffAxisAngle,
                    radius_of_curvature: R,
                };
                break;
            default:
                aGeometry = (pGeometry as iCircularOffAxisParabolic);
                break;
        }

        const aDiameter = aGeometry.diameter
        const aThicknessEdge = aGeometry.thickness_edge;

        const R = aGeometry.radius_of_curvature;
        const c = -(1 / R);
        const x0 = (Math.tan(aOffAxisAngle / 2) * R);
        const z0 = (1 / (2 * R)) * (Math.pow(x0, 2));

        let zA = (1 / (2 * R)) * Math.pow((x0 + (aDiameter / 2)), 2);


        let aThicknessCenter = (aThicknessEdge - (zA - z0));
        let aDeformation = (undefined != pGeometry.deformation) ? pGeometry.deformation : undefined;

        let aGenericConic: iGeneralConicLens = {
            back: {
                cx: 0,
                cy: 0,
                kx: 0,
                ky: 0,
                diameter: aGeometry.diameter
            },
            front: {
                cx: c,
                cy: c,
                kx: -1,
                ky: -1,
                diameter: aGeometry.diameter,
                x0: x0,
                y0: 0,
                z0: z0,
                deformations: (null != aDeformation) ? aDeformation.front : null,
            },
            thickness_center: aThicknessCenter
        };

        return aGenericConic;
    }
    //__________________________________________________________________________________________
    private static _getCircularParabolic(pBrand: tBrands,
        pGeometry: iOpticsVOGeometry) {
        let aThicknessEdge: number;
        switch (pBrand) {
            case "3DOptix":
                aThicknessEdge = pGeometry.h;
                break;
            case "OptoSigma":
                aThicknessEdge = pGeometry.thickness_h;
                break;
            default:
                aThicknessEdge = pGeometry.thickness_edge;
                break;
        }

        let aGeometry: iCircularParabolic = {
            diameter: pGeometry.diameter,
            thickness_edge: aThicknessEdge,
            radius_of_curvature: pGeometry.radius_of_curvature
        };

        const aR1 = -aGeometry.radius_of_curvature;
        const aDiameter = aGeometry.diameter
        const t = aR1 * (1 - Math.sqrt(1 - Math.pow((aDiameter / (2 * Math.abs(aR1))), 2)));
        let aThicknessCenter = (aThicknessEdge + t);

        let aDeformation = (null != pGeometry.deformation) ? pGeometry.deformation : null;

        let aGenericConic: iGeneralConicLens = {
            back: {
                cx: 0,
                cy: 0,
                kx: 0,
                ky: 0,
                diameter: aGeometry.diameter
            },
            front: {
                cx: (-1 / aGeometry.radius_of_curvature),
                cy: (-1 / aGeometry.radius_of_curvature),
                kx: -1,
                ky: -1,
                diameter: aGeometry.diameter,
                deformations: (null != aDeformation) ? aDeformation.front : null
            },
            thickness_center: aThicknessCenter
        };

        return aGenericConic;
    }
    //__________________________________________________________________________________________
    private static _getGratingCircularThinLensGeometry(pBrand: tBrands,
        pGeometry: iOpticsVOGeometry) {

        let aThickness: number;
        switch (pBrand) {
            case "Thorlabs":
                aThickness = pGeometry.d;
                break;
            default:
                aThickness = pGeometry.thickness;
        }

        let aGeometry: iThinCircularLens = {
            diameter: pGeometry.diameter,
            thickness: aThickness
        };
        return aGeometry;
    }
    //__________________________________________________________________________________________
    private static _getCircularThinLensGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        if (null != pGeometry.thickness) {
            return pGeometry;
        }

        let aThickness: number;
        switch (pBrand) {
            case "Qioptiq Photonics":
                aThickness = pGeometry.d;
                break;
            default:
                aThickness = (null != pGeometry.thickness_edge) ? pGeometry.thickness_edge :
                    pGeometry.thickness_center;
        }

        if (aThickness == null) {
            throw new Error("Missing Data");
        }

        let aGeometry: iThinCircularLens = {
            diameter: pGeometry.diameter,
            thickness: aThickness
        };

        return aGeometry;
    }
    //__________________________________________________________________________________________
    private static _getSphericalGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aTC: number;

        switch (pBrand) {
            case "Standa": {
                aTC = pGeometry.thickness; // thickness center
                break;
            }
            default:
                aTC = pGeometry.thickness_center;
                break;
        }

        let aR1 = pGeometry.r1;
        let aR2 = pGeometry.r2;
        let aC1 = ((undefined !== aR1) && (0 !== aR1)) ? (1 / aR1) : 0;
        let aC2 = ((undefined !== aR2) && (0 !== aR2)) ? (1 / aR2) : 0;
        let r = pGeometry.diameter / 2;


        if (null == aTC) {
            if (null == pGeometry.thickness_edge) {
                throw new Error("Missing Data");
            }


            let aT1 = ((undefined !== aR1) && (0 !== aR1)) ?
                aR1 * (1 - Math.sqrt((1 - Math.pow((r / aR1), 2)))) : 0;
            let aT2 = ((undefined !== aR2) && (0 !== aR2)) ?
                (-aR2 * (1 - Math.sqrt((1 - Math.pow((r / aR2), 2))))) : 0;

            aTC = pGeometry.thickness_edge + aT1 + aT2;
        }

        let aDeformations = pGeometry.deformation;


        let aGeometry: iGeneralConicLens = {
            front: {
                cx: aC1,
                cy: aC1,
                diameter: pGeometry.diameter,
                kx: 0,
                ky: 0,
                deformations: (null != aDeformations) ? aDeformations.front : null
            },
            back: {
                cx: aC2,
                cy: aC2,
                diameter: pGeometry.diameter,
                kx: 0,
                ky: 0,
                deformations: (null != aDeformations) ? aDeformations.back : null
            },
            thickness_center: aTC,
        }

        return aGeometry;
    }
    //__________________________________________________________________________________________
    private static _getConicGeometry(_pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aR1 = pGeometry.r1;
        let aR2 = pGeometry.r2;
        let aC1 = ((null != aR1) && (0 != aR1)) ? (1 / aR1) : 0;
        let aC2 = ((null != aR2) && (0 != aR2)) ? (1 / aR2) : 0;
        let aTC = pGeometry.thickness_center;

        let aDeformations = pGeometry.deformation;
        let aGeometry: iGeneralConicLens = {
            front: {
                cx: aC1,
                cy: aC1,
                diameter: pGeometry.diameter,
                kx: 0,
                ky: 0,
                deformations: (null != aDeformations) ? aDeformations.front : null
            },
            back: {
                cx: aC2,
                cy: aC2,
                diameter: pGeometry.diameter,
                kx: 0,
                ky: 0,
                deformations: (null != aDeformations) ? aDeformations.back : null
            },
            thickness_center: aTC,
        }

        return aGeometry;
    }
    //__________________________________________________________________________________________
    private static _getDovePrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aDovePrism: iDovePrism;
        switch (pBrand) {
            case "Thorlabs":
                aDovePrism = {
                    a: (pGeometry.a * Math.SQRT1_2),
                    b: (pGeometry.l),
                    c: (pGeometry.a),
                    d: (pGeometry.a)
                };
                break;
            case "Knight Optical":
                aDovePrism = {
                    a: (pGeometry.b * Math.SQRT1_2),
                    b: (pGeometry.c),
                    c: (pGeometry.a),
                    d: (pGeometry.b)
                };
                break;
            case "Standa":
            case "Union Optic":
                aDovePrism = {
                    a: (pGeometry.b),
                    b: (pGeometry.a),
                    c: (pGeometry.h),
                    d: (pGeometry.b * Math.SQRT2)
                };
                break;
            case "OptoSigma":
                aDovePrism = {
                    a: (pGeometry.a),
                    b: (pGeometry.d),
                    c: (pGeometry.b),
                    d: (pGeometry.a)
                };
                break;
            case "Holmarc Opto-Mechatronics":
                aDovePrism = {
                    a: (pGeometry.a),
                    b: (pGeometry.l),
                    c: (pGeometry.b),
                    d: (pGeometry.a * Math.SQRT2)
                };
                break;
            case "Ross Optical":
            case "Simtrum":
                aDovePrism = {
                    a: (pGeometry.b * Math.SQRT1_2),
                    b: (pGeometry.c),
                    c: (pGeometry.a),
                    d: (pGeometry.b)
                };
                break;
            case "Edmund Optics":
            default:
                aDovePrism = (pGeometry as iDovePrism);
                break;
        }

        return aDovePrism;
    }
    //__________________________________________________________________________________________
    private static _getCircleCylindricalGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aCylindrical: iCylindrical;
        switch (pBrand) {

            case "WTS Photonics": {
                aCylindrical = {
                    diameter: pGeometry.diameter,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                    height: pGeometry.y,
                    width: pGeometry.x,
                    thickness_center: pGeometry.thickness_center
                };
                break;
            }
            case 'Simtrum': {
                aCylindrical = {
                    height: pGeometry.length,
                    width: pGeometry.width,
                    diameter: pGeometry.diameter,
                    thickness_center: pGeometry.thickness_center,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                };
                break;
            }
            case "OptoSigma": {
                aCylindrical = {
                    height: pGeometry.a,
                    width: pGeometry.b,
                    diameter: pGeometry.diameter,
                    thickness_center: pGeometry.thickness_center,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                };
                break;
            }

            case "EKSMA Optics": {
                aCylindrical = {
                    height: pGeometry.a,
                    width: pGeometry.b,
                    diameter: pGeometry.diameter,
                    thickness_center: pGeometry.thickness_center,
                    r1: pGeometry.r2,
                    r2: pGeometry.r1,
                };
                break;
            }

            case "CVI Laser Optics": {
                let aThicknessCenter = pGeometry.thickness_center;
                if (aThicknessCenter == null && pGeometry.thickness_edge != null) {

                    let aR1 = pGeometry.r1;
                    let aR2 = pGeometry.r2;
                    /**
                     * check what should i use for rectangular shape 
                     */

                    let aT1: number, aT2: number;
                    if (pGeometry.diameter != null) {
                        ///let r = pGeometry.diameter / 2;
                        let aSag = OP3DMathUtils.getSag(pGeometry.diameter, aR1, aR2);
                        aT1 = aSag.sag1;
                        aT2 = aSag.sag2;
                        // aT1 = aR1 * (1 - Math.sqrt((1 - Math.pow((r / aR1), 2))));
                        // if (aR2 != null) {
                        //     aT2 = (null != aR2) ? (aR2 * (1 - Math.sqrt((1 - Math.pow((r / aR2), 2))))) : 0;
                        // } else {
                        //     aT2 = 0;
                        // }

                    } else {

                        let aMin = Math.min(pGeometry.width, pGeometry.height);
                        let aSag = OP3DMathUtils.getSag(aMin, aR1, aR2);
                        aT1 = aSag.sag1;
                        aT2 = aSag.sag2;
                        // aT1 = aR1 * (1 - Math.sqrt((1 - Math.pow((aMax / aR1), 2))));
                        // if (aR2 != null) {
                        //     aT2 = (null != aR2) ? (aR2 * (1 - Math.sqrt((1 - Math.pow((aMax / aR2), 2))))) : 0;
                        // } else {
                        //     aT2 = 0;
                        // }
                    }

                    aThicknessCenter = pGeometry.thickness_edge + aT1 + aT2;
                }


                aCylindrical = {
                    height: pGeometry.height,
                    width: pGeometry.width,
                    diameter: pGeometry.diameter,
                    thickness_center: aThicknessCenter,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                };
                break;
            }
            case "CeNing Optics":
            case "MKS/Newport": {
                aCylindrical = {
                    height: pGeometry.height,
                    width: pGeometry.width,
                    diameter: pGeometry.diameter,
                    thickness_center: pGeometry.thickness_center,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                };


                break;
            }
            default:
                aCylindrical = pGeometry as iCylindrical;
                break;
        }

        let aR1X = ((undefined !== pGeometry.r1_x) && (null !== pGeometry.r1_x)) ?
            pGeometry.r1_x : pGeometry.r1;
        let aR1Y = ((undefined !== pGeometry.r1_y) && (null !== pGeometry.r1_y)) ?
            pGeometry.r1_y : Infinity;
        let aR2X = ((undefined !== pGeometry.r2_x) && (null !== pGeometry.r2_x)) ?
            pGeometry.r2_x : pGeometry.r2;
        let aR2Y = ((undefined !== pGeometry.r2_y) && (null !== pGeometry.r2_y)) ?
            pGeometry.r2_y : Infinity;

        let aDeformation = (null != pGeometry.deformation) ? pGeometry.deformation : null;

        let aRet: iGeneralConicLens = {
            back: {
                cx: ((undefined !== aR2X) ? (1 / aR2X) : 0),
                cy: ((undefined !== aR2Y) ? (1 / aR2Y) : 0),
                kx: 0,
                ky: 0,
                diameter: aCylindrical.diameter,
                deformations: (null != aDeformation) ? aDeformation.back : null
            },
            front: {
                cx: ((undefined !== aR1X) ? (1 / aR1X) : 0),
                cy: ((undefined !== aR1Y) ? (1 / aR1Y) : 0),
                kx: 0,
                ky: 0,
                diameter: aCylindrical.diameter,
                deformations: (null != aDeformation) ? aDeformation.front : null
            },
            thickness_center: pGeometry.thickness_center
        };

        return aRet;
    }
    //__________________________________________________________________________________________
    private static _getRectCylindricalGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aCylindrical: iRectangularCylindrical;
        switch (pBrand) {

            case "WTS Photonics": {
                aCylindrical = {
                    height: pGeometry.y,
                    width: pGeometry.x,
                    thickness_center: pGeometry.thickness_center,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            case 'Simtrum': {
                aCylindrical = {
                    height: pGeometry.length,
                    width: pGeometry.width,
                    thickness_center: pGeometry.thickness_center,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            case "OptoSigma": {
                aCylindrical = {
                    height: pGeometry.a,
                    width: pGeometry.b,
                    thickness_center: pGeometry.thickness_center,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            case "EKSMA Optics": {
                aCylindrical = {
                    height: pGeometry.a,
                    width: pGeometry.b,
                    thickness_center: pGeometry.thickness_center,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            case "CVI Laser Optics": {
                let aThicknessCenter = pGeometry.thickness_center;
                if (aThicknessCenter == null && pGeometry.thickness_edge != null) {
                    let aR1 = pGeometry.r1;
                    let aR2 = pGeometry.r2;

                    let aT1: number, aT2: number;
                    if (pGeometry.diameter != null) {
                        let aSag = OP3DMathUtils.getSag(pGeometry.diameter, aR1, aR2);
                        aT1 = aSag.sag1;
                        aT2 = aSag.sag2;
                    } else {
                        let aMin = Math.min(pGeometry.width, pGeometry.height);
                        let aSag = OP3DMathUtils.getSag(aMin, aR1, aR2);
                        aT1 = aSag.sag1;
                        aT2 = aSag.sag2;
                    }

                    aThicknessCenter = pGeometry.thickness_edge + aT1 + aT2;
                }

                aCylindrical = {
                    height: pGeometry.height,
                    width: pGeometry.width,
                    thickness_center: aThicknessCenter,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            case "CeNing Optics":
            case "MKS/Newport": {
                aCylindrical = {
                    height: pGeometry.height,
                    width: pGeometry.width,
                    thickness_center: pGeometry.thickness_center,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            case "Thorlabs":
            case "Edmund Optics": {
                aCylindrical = {
                    height: pGeometry.height,
                    width: pGeometry.length,
                    thickness_center: pGeometry.thickness_center,
                    r1_x: pGeometry.r1_x,
                    r1_y: pGeometry.r1_y,
                    r2_x: pGeometry.r2_x,
                    r2_y: pGeometry.r2_y
                };
                break;
            }
            default:
                aCylindrical = pGeometry as iRectangularCylindrical;
                break;
        }

        let aR1X = aCylindrical.r1_x;
        let aR1Y = aCylindrical.r1_y;
        let aR2X = aCylindrical.r2_x;
        let aR2Y = aCylindrical.r2_y;

        let aDeformation = (null != pGeometry.deformation) ? pGeometry.deformation : null;

        let aRet: iGeneralRectConicLens = {
            back: {
                cx: ((undefined !== aR2X) ? (1 / aR2X) : 0),
                cy: ((undefined !== aR2Y) ? (1 / aR2Y) : 0),
                kx: 0,
                ky: 0,
                height: aCylindrical.height,
                width: aCylindrical.width,
                deformations: (null != aDeformation) ? aDeformation.back : null
            },
            front: {
                cx: ((undefined !== aR1X) ? (1 / aR1X) : 0),
                cy: ((undefined !== aR1Y) ? (1 / aR1Y) : 0),
                kx: 0,
                ky: 0,
                height: aCylindrical.height,
                width: aCylindrical.width,
                deformations: (null != aDeformation) ? aDeformation.front : null
            },
            thickness_center: pGeometry.thickness_center
        };

        return aRet;
    }
    //__________________________________________________________________________________________
    private static _getAxiconGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aAxicon: iAxiconPrism;
        switch (pBrand) {
            case "Thorlabs": {
                let aHeight = OpticUtils.getAxiconHeightByAlpha(pGeometry.alpha,
                    pGeometry.diameter);
                aAxicon = {
                    convexity: pGeometry.convexity,
                    diameter: pGeometry.diameter,
                    height: aHeight,
                    thickness_edge: pGeometry.thickness_edge
                };
                break;
            }
            case "Altechna":
            case "Standa":
            case "EKSMA Optics":
            case "Edmund Optics": {
                let aAlpha = (Math.PI - pGeometry.apex_angle) / 2;
                let aHeight = OpticUtils.getAxiconHeightByAlpha(aAlpha,
                    pGeometry.diameter);
                aAxicon = {
                    convexity: pGeometry.convexity,
                    diameter: pGeometry.diameter,
                    height: aHeight,
                    thickness_edge: pGeometry.thickness_edge
                };
                break;
            }
            default:
                aAxicon = pGeometry as iAxiconPrism;
                let aHeight = OpticUtils.getAxiconHeightByAlpha(pGeometry.alpha,
                    pGeometry.diameter);
                aAxicon.height = aHeight
                break;
        }

        return aAxicon;
    }
    //__________________________________________________________________________________________
    private static _getAspheicGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aAsphericLens: iASphericLens;

        switch (pBrand) {
            case "Edmund Optics":
                aAsphericLens = {
                    coeffs: {
                        2: pGeometry.d,
                        4: pGeometry.e,
                        6: pGeometry.f,
                        8: pGeometry.g,
                        10: pGeometry.h,
                        12: pGeometry.j,
                        14: pGeometry.l
                    },
                    diameter: pGeometry.diameter,
                    k: pGeometry.k,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                    thickness_center: pGeometry.thickness_center
                };
                break;
            case "Thorlabs":
                aAsphericLens = {
                    coeffs: {
                        2: pGeometry.a2,
                        4: pGeometry.a4,
                        6: pGeometry.a6,
                        8: pGeometry.a8,
                        10: pGeometry.a10,
                        12: pGeometry.a12,
                        14: pGeometry.a14
                    },
                    diameter: pGeometry.diameter,
                    k: pGeometry.k,
                    r1: pGeometry.r1,
                    r2: pGeometry.r2,
                    thickness_center: pGeometry.thickness_center
                };
                break;
            default:
                aAsphericLens = pGeometry as iASphericLens;
                break;
        }


        return aAsphericLens;
    }
    //__________________________________________________________________________________________
    private static _getPellinBorcaGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aPellinBorca: iPellinBrocaPrism;
        switch (pBrand) {
            case "Thorlabs":
            case "3DOptix":
                aPellinBorca = {
                    a: pGeometry.a,
                    b: pGeometry.b,
                    h: pGeometry.h,
                    alpha: pGeometry.phi,
                    beta: (Math.PI / 2)
                };
                break;
            case "Knight Optical":
                aPellinBorca = {
                    a: pGeometry.b,
                    b: pGeometry.a,
                    h: pGeometry.thickness,
                    alpha: pGeometry.angle_b,
                    beta: pGeometry.angle_d
                };
                break;
            case "OptoSigma":
                aPellinBorca = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    h: pGeometry.c,
                    alpha: pGeometry.theta2,
                    beta: pGeometry.theta1
                };
                break;
            case 'Union Optic':
            case 'Simtrum':
                aPellinBorca = {
                    a: pGeometry.a,
                    b: pGeometry.b,
                    h: pGeometry.h,
                    alpha: pGeometry.theta,
                    beta: (Math.PI / 2)
                };
                break;
            case "Holmarc Opto-Mechatronics":
                aPellinBorca = {
                    a: pGeometry.a,
                    b: pGeometry.b,
                    h: pGeometry.h,
                    alpha: pGeometry.beta,
                    beta: pGeometry.alpha
                };
                break;
            case "EKSMA Optics":
            case "Standa":
            default:
                aPellinBorca = pGeometry as iPellinBrocaPrism;
                break;

        }

        return aPellinBorca;
    }
    //__________________________________________________________________________________________
    private static _getBsCubeGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aBSCube: iBSCube;
        switch (pBrand) {

            case "Standa":
            case "Altechna":
            case "MKS/Newport":
            case "Union Optic":
            case "3DOptix": {
                aBSCube = { cube_side: pGeometry.width };
                break;
            }
            case "Qioptiq Photonics": {
                aBSCube = { cube_side: pGeometry.l1 };
                break;
            }
            case "Simtrum":
            case "OptoSigma":
            case "EKSMA Optics": {
                aBSCube = { cube_side: pGeometry.a };
                break;
            }
            case "Holmarc Opto-Mechatronics":
            case "Thorlabs":
            case "Edmund Optics":
            default:
                aBSCube = { cube_side: pGeometry.cube_side };
        }

        return aBSCube;
    }
    //__________________________________________________________________________________________
    private static _getRhombicPrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aRhombicPrismGeometry: iRhombicPrism;
        switch (pBrand) {
            case "Knight Optical":
                aRhombicPrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.b,
                    c: pGeometry.d,
                    d: pGeometry.c
                };
                break;
            case "Union Optic":
                aRhombicPrismGeometry = {
                    a: pGeometry.a,
                    b: (pGeometry.a * Math.SQRT2),
                    c: pGeometry.b,
                    d: pGeometry.c
                };
                break
            case "Simtrum":
                aRhombicPrismGeometry = {
                    a: pGeometry.a,
                    b: (pGeometry.a * Math.SQRT2),
                    c: pGeometry.a,
                    d: pGeometry.c
                };
                break;
            case "Holmarc Opto-Mechatronics":
            case "Edmund Optics":
            default:
                aRhombicPrismGeometry = pGeometry as iRhombicPrism;
                break;
        }

        return aRhombicPrismGeometry;
    }
    //__________________________________________________________________________________________
    private static _getPentaPrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aPentaPrismGeometry: iPentaPrism;
        switch (pBrand) {
            case "Edmund Optics":
                aPentaPrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    c: pGeometry.a,
                    d: pGeometry.a,
                    e: pGeometry.b,
                    alpha: pGeometry.alpha
                };
                break;
            case "Standa":
                aPentaPrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.b,
                    c: pGeometry.c,
                    d: pGeometry.a,
                    e: pGeometry.a,
                    alpha: (3 * (Math.PI / 8))
                };
                break
            case "Knight Optical":
                break;
            case "Union Optic":
                aPentaPrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    c: pGeometry.h,
                    d: pGeometry.a,
                    e: pGeometry.a,
                    alpha: (3 * (Math.PI / 8))
                };
                break;
            case "Qioptiq Photonics":
                aPentaPrismGeometry = {
                    a: pGeometry.l,
                    b: pGeometry.l,
                    c: pGeometry.h,
                    d: pGeometry.l,
                    e: pGeometry.l,
                    alpha: (3 * (Math.PI / 8))
                };
                break;
            case "Holmarc Opto-Mechatronics":
                aPentaPrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.c,
                    c: pGeometry.b,
                    d: pGeometry.a,
                    e: pGeometry.a,
                    alpha: (3 * (Math.PI / 8))
                };
                break;
            default:
                aPentaPrismGeometry = pGeometry as iPentaPrism;
                break;
        }

        return aPentaPrismGeometry;
    }
    //__________________________________________________________________________________________
    private static _getEqualPrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aEquilateralPrismGeometry: iEquilateralPrism;
        switch (pBrand) {
            case "Union Optic":
                aEquilateralPrismGeometry = {
                    a: pGeometry.a,
                    t: pGeometry.thickness
                };
                break;
            case "OptoSigma":
            case "Simtrum":
                aEquilateralPrismGeometry = {
                    a: pGeometry.a,
                    t: pGeometry.b
                };
                break;
            case "Edmund Optics":
                aEquilateralPrismGeometry = {
                    a: pGeometry.length_of_face,
                    t: pGeometry.length
                };
                break;
            case "Knight Optical":
                aEquilateralPrismGeometry = {
                    a: pGeometry.height,
                    t: pGeometry.length
                };
                break;
            case "Standa":
            case "Qioptiq Photonics":
                aEquilateralPrismGeometry = {
                    a: pGeometry.l,
                    t: pGeometry.h
                };
                break;
            case "Holmarc Opto-Mechatronics":
            case "Alkor Technologies":
                aEquilateralPrismGeometry = {
                    a: pGeometry.a,
                    t: pGeometry.c
                };
                break;
            case "Ross Optical":
                aEquilateralPrismGeometry = {
                    a: pGeometry.a,
                    t: pGeometry.b
                };
                break;
            case "Altechna":
                aEquilateralPrismGeometry = {
                    a: pGeometry.a,
                    t: pGeometry.l
                };
                break;
            case "Thorlabs":
            case "Laser Components":
                aEquilateralPrismGeometry = {
                    a: pGeometry.a,
                    t: pGeometry.h
                };
                break;
            default:
                aEquilateralPrismGeometry = pGeometry as iEquilateralPrism;
                break;
        }

        return aEquilateralPrismGeometry;
    }
    //__________________________________________________________________________________________
    private static _getCirleWedgePrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry,
        pSubType: string) {

        let aWedgeGeometry: iCircularWedgePrism;

        switch (pSubType) {
            case OpticsContext._Wedge_Prism:
                switch (pBrand) {
                    case "EKSMA Optics": {
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        let aThickness = pGeometry.thickness;
                        aThickness += aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickness,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    }
                    case "Simtrum":
                    case "Ross Optical":
                    case "Holmarc Opto-Mechatronics":
                    case "Thorlabs": {
                        let aThickess = pGeometry.thickness_edge;
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        aThickess += aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickess,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    }

                    case "Union Optic":
                    case "Edmund Optics": {
                        let aThickess = pGeometry.thickness;
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        aThickess += aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickess,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    }
                    case "Standa": {
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        const aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        let aThickess = pGeometry.thickness;
                        aThickess -= aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickess,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    }
                    default:
                        aWedgeGeometry = pGeometry as iCircularWedgePrism;
                        break;
                }
                break;
            case OpticsContext._Wedge_Window:
                switch (pBrand) {

                    case "Laser Components": {
                        let aThickess = pGeometry.edge_thickness_max;
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        aThickess += aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickess,
                            wedge_angle: pGeometry.wedge_angle
                        };

                        break;
                    }
                    case "CVI Laser Optics":
                    case "OptoSigma": {
                        let aThickess = pGeometry.thickness;
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        aThickess -= aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickess,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    }
                    case "Alkor Technologies": {
                        // const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        // let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: pGeometry.thickness_center /*+ aDeltaT*/,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    }
                    case "MKS/Newport":
                    case "Edmund Optics":
                    case "Thorlabs":
                        let aThickess = pGeometry.thickness;
                        const aBackSurfaceD = pGeometry.diameter / Math.cos(pGeometry.wedge_angle);
                        let aDeltaT = (aBackSurfaceD * Math.sin(pGeometry.wedge_angle)) / 2;
                        aThickess += aDeltaT;
                        aWedgeGeometry = {
                            diameter: pGeometry.diameter,
                            thickness_center: aThickess,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;
                    default:
                        aWedgeGeometry = pGeometry as iCircularWedgePrism;
                        break;
                }

        }

        return aWedgeGeometry;
    }
    //__________________________________________________________________________________________
    private static _getRectWedgePrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry, pSubtype: string) {
        let aRectWedge: iRectWedgePrism;

        switch (pSubtype) {
            case OpticsContext._Wedge_Prism:
                switch (pBrand) {
                    case "Simtrum":
                    case "Ross Optical":
                    case "Holmarc Opto-Mechatronics":
                    case "Edmund Optics":
                    case "Thorlabs":
                    case "EKSMA Optics":
                    case "Standa":
                        throw new Error("unsupported for rectangular shape");

                    case "Union Optic": {
                        aRectWedge = {
                            width: pGeometry.width,
                            height: pGeometry.height,
                            t: pGeometry.thickness,
                            wedge_angle: pGeometry.wedge_angle
                        };
                        break;

                    }
                    default:
                        aRectWedge = pGeometry as iRectWedgePrism;
                        break;
                }
                break;
            case OpticsContext._Wedge_Window: {
                switch (pBrand) {
                    case "CVI Laser Optics":
                    case "OptoSigma":
                    case "MKS/Newport":
                    case "Edmund Optics":
                    case "Laser Components":
                    case "Thorlabs":
                        throw new Error("unsupported for rectangular shape");

                    default:
                        aRectWedge = pGeometry as iRectWedgePrism;
                        break;
                }
            }
                break;
        }

        return aRectWedge;
    }
    //__________________________________________________________________________________________
    private static _getRightAnglePrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aRightAnglePrismGeometry: iRightAnglePrism;
        switch (pBrand) {
            case "Thorlabs":
                aRightAnglePrismGeometry = {
                    a: pGeometry.l,
                    b: pGeometry.l,
                    c: pGeometry.l
                };
                break;
            case "OptoSigma":
                aRightAnglePrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    c: pGeometry.b
                };
                break;
            case "Standa":
            case "EKSMA Optics":
                aRightAnglePrismGeometry = {
                    a: pGeometry.b,
                    b: pGeometry.b,
                    c: pGeometry.a
                };
                break;
            case "3DOptix":
            case "MKS/Newport":
                aRightAnglePrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    c: pGeometry.c
                };
                break;
            case "Edmund Optics":
                aRightAnglePrismGeometry = {
                    a: pGeometry.length_of_legs,
                    b: pGeometry.length_of_legs,
                    c: pGeometry.length_of_legs
                };
                break;
            case "Laser Components":
                aRightAnglePrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    c: pGeometry.a
                };
                break;
            case "CVI Laser Optics":
                aRightAnglePrismGeometry = {
                    a: pGeometry.dimensions,
                    b: pGeometry.dimensions,
                    c: pGeometry.dimensions
                };
                break;
            case "Alkor Technologies":
                aRightAnglePrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.b,
                    c: pGeometry.a
                };
                break;
            case "Ross Optical":
            case "Qioptiq Photonics":
                aRightAnglePrismGeometry = {
                    a: pGeometry.l,
                    b: pGeometry.l,
                    c: pGeometry.h
                };
                break;
            default:
                aRightAnglePrismGeometry = pGeometry as iRightAnglePrism;
                break;
        }

        return aRightAnglePrismGeometry;
    }
    //__________________________________________________________________________________________
    private static _getMRightAnglePrismGeometry(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aRightAnglePrismGeometry: iRightAnglePrism;
        switch (pBrand) {
            case "Thorlabs":
                aRightAnglePrismGeometry = {
                    a: pGeometry.l,
                    b: pGeometry.l,
                    c: pGeometry.l
                };
                break;
            case "Edmund Optics":
                aRightAnglePrismGeometry = {
                    a: pGeometry.length_of_legs,
                    b: pGeometry.length_of_legs,
                    c: pGeometry.length_of_legs
                };
                break;
            case "3DOptix":
                aRightAnglePrismGeometry = {
                    a: pGeometry.a,
                    b: pGeometry.a,
                    c: pGeometry.c
                };
                break;
            case "Union Optic":
            default:
                aRightAnglePrismGeometry = pGeometry as iRightAnglePrism;
                break;
        }

        return aRightAnglePrismGeometry;
    }
    //__________________________________________________________________________________________
    private static _getRectBaseRoofPrism(pBrand: tBrands, pGeometry: iOpticsVOGeometry) {
        let aRoofPrismGeometry: iRectBaseRoofPrismMirror;
        switch (pBrand) {
            case "Union Optic":
                aRoofPrismGeometry = {
                    width: pGeometry.dimension_1,
                    height: pGeometry.dimension_2,
                    thickness: (pGeometry.height - (pGeometry.dimension_1 / 2))
                };
                break;
            case "Thorlabs":
            default:
                aRoofPrismGeometry = pGeometry as iRectBaseRoofPrismMirror;
                break;
        }

        return aRoofPrismGeometry;
    }
    //__________________________________________________________________________________________
}
