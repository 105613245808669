import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iCadOpticalData } from "../../../_context/_interfaces/Interfaces";
import { iOpticsVOGeometry } from "../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../parts/Part";
import { iFaceDataNEW } from "../../../parts/PartInterfaces";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Spinner } from "../../home/Spinner";
import { Popup } from "../Popup";
import { Optics3DPresenter } from "../tools/Optics3DPresenter";
import { esOptomechColorInfo } from "./esOptomechColorInfo";
import { uoMaterial, iuoMaterial } from "./uoMaterial";

export interface iOptomechanicsSolidsFormSections {
    colorInfo?: esOptomechColorInfo,
    material: uoMaterial;
};

export interface iOpticsCreateParams {
    name: string;
    geometry: iOpticsVOGeometry;
    materialID: string;
};

export interface iOptomechanicsSolidsFormParams {
    part: Part;
    faces: iFaceDataNEW[];
    callback?: (pOptOpticsData: iCadOpticalData) => void;
};

export class OptomechanicsSolidsForm extends Op3dComponentBase<iOptomechanicsSolidsFormParams> {

    private static INSTANCE: OptomechanicsSolidsForm | null = null;

    private mSections: iOptomechanicsSolidsFormSections;
    private mOptics3DPresenter: Optics3DPresenter;
    private mPart: Part;
    private mSaveBtn: HTMLElement;

    private mNameInput: HTMLInputElement;
    private mFaces: iFaceDataNEW[];
    private mDefaultBtn: HTMLElement;
    //__________________________________________________________________________________________
    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/optics/optomechanics_optics_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == OptomechanicsSolidsForm.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            document.getElementById('forms').appendChild(aContainer);

            OptomechanicsSolidsForm.INSTANCE = new OptomechanicsSolidsForm(aContainer);
        }

        return OptomechanicsSolidsForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm(): Promise<void> {
        await Op3dContext.wait(() => {
            for (let section in this.mSections) {
                if (true != this.mSections[section].isReady) {
                    return false;
                }
            }
            return true;
        });

        while (!this.mIsReady || !this.mOptics3DPresenter.isReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pOptomechanicsFormParams: iOptomechanicsSolidsFormParams) {
        Spinner.instance.show();
        this._clear();

        this.mPart = pOptomechanicsFormParams.part;
        this.mFaces = pOptomechanicsFormParams.faces;
        let aCadOpticalData: iCadOpticalData = pOptomechanicsFormParams.part.opticalData;
        this.mNameInput.value = pOptomechanicsFormParams.part.getLabel().label;
        document.getElementById('material_sections').classList.remove('d-none')
        this.mSections.material.enable(true)

        let aMaterialVO: iuoMaterial;
        let aSolidMaterialID = this._getMaterialExistenceOnSolid();

        if (aSolidMaterialID !== null) {
            aMaterialVO = { materialID: aSolidMaterialID, wavelength: 550 }
        } else {
            aMaterialVO = { materialID: aCadOpticalData.materialID, wavelength: 550 }
        }

        this.mSections.material.setData(aMaterialVO);

        if (this.mPart != null && !this.mPart.userIsPartOwner() && Op3dContext.USER_PERMISSION.isFreeUser === true) {
            this.mSections.material.setMaterialsSelectDisabled(true);
        } else {
            this.mSections.material.setMaterialsSelectDisabled(false);
        }

        this.mSections.material.expand()

        this.mSections.colorInfo.setData(this.mFaces);
        this.mSections.colorInfo.collapse();

        await this.mOptics3DPresenter.onRefreshOptomechanicsOptic(this.mPart);
        for (let i = 0; i < this.mFaces.length; i++) {
            this.mOptics3DPresenter.highlightOptomechanicsFace(this.mFaces[i].name, true)
        }

        this.mOptics3DPresenter.toUpdate = true;
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, true);

        this.mSections.colorInfo.enable(Op3dContext.USER_VO.isBasicLicense !== true);

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll()
        this.mOptics3DPresenter.toUpdate = false;
        this._clear();
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mNameInput.value = ''
        this.mPart = null;
        this.mFaces = null;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        super._initElements();
        this.mSaveBtn = this._getPart('save_btn', true);
        this.mNameInput = this._getPart('optomechanics-optics-name') as HTMLInputElement;
        this.mNameInput.disabled = true;
        this.mDefaultBtn = this._getPart('default-btn');
        this.mDefaultBtn.parentElement.classList.remove('d-none');
        this._initSections();
        this._init3DPresenter();
    }
    //__________________________________________________________________________________________
    private _init3DPresenter() {
        this.mOptics3DPresenter = new Optics3DPresenter(
            this._getPart('optic-preview-element'), {
            isFixedDiv: false,
            alwaysShowImage: false
        });
    }
    //__________________________________________________________________________________________
    private async _initSections() {
        this.mSections = {
            colorInfo: new esOptomechColorInfo(this._getPart("color_section")),
            material: new uoMaterial(this._getPart("material_sections"), "Material"),
        };
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mSaveBtn.addEventListener('click', () => this._onSave());
        this.mDefaultBtn.addEventListener('click', () => this._onSave(true))
        EventManager.addEventListener(EventsContext.OPTOMECH_MAT_CHANGE,
            () => this._updateSaveBtnState(), this);        
        EventManager.addEventListener(EventsContext.OPTOMECH_COLOR_CHANGE,
            () => this._updateSaveBtnState(), this);
    }
    //__________________________________________________________________________________________
    private _updateSaveBtnState() { 
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, false);
    }
    //__________________________________________________________________________________________
    private async _onSave(pReturnDefault?: boolean) {
        let aMaterialID = '';

        if (pReturnDefault === true) {
            this._removeFacesMaterial();
        } else {
            aMaterialID = this.mSections.material.getData();
            this._applyFacesMaterial(aMaterialID);
        }

        Op3dContext.DIV_CONTROLLER.updatePartInfo();

        let aAnswer = await this.mPart.saveOptomechanicsOpticalInfoInMongo();
        if (!aAnswer) {
            Popup.instance.open({
                text: MessagesHandler.ERROR_ON_SAVE_FOREIGN_PART
            })
        }else{
            this.mSections.colorInfo.saveColorChanges(this.mPart);
        }

        this.close();

    }
    //__________________________________________________________________________________________
    protected _applyFacesMaterial(pMaterialID: string) {
        this.mFaces.forEach(face => {
            if (face.data == null) {
                face.data = {}
            }
            face.data["materialID"] = pMaterialID;
        });
        for (let i = 0; i < this.mFaces.length; i++) {
            let aFaceOpticalDataIndex = this.mPart.opticalData.faces.findIndex(face => JSON.stringify(face.path) === JSON.stringify(this.mFaces[i].path));
            if (aFaceOpticalDataIndex !== -1) {
                this.mPart.opticalData.faces[aFaceOpticalDataIndex].data["materialID"] = pMaterialID;
            } else {
                this.mPart.opticalData.faces.push(this.mFaces[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    protected _removeFacesMaterial() {
        this.mPart.opticalData.faces.forEach(face => {
            if (face.data != null && face.data.materialID !== undefined) {
                delete face.data.materialID;
            }
        });
        this.mFaces.forEach((face) => {
            if (face.data != null && face.data.materialID !== undefined) {
                delete face.data.materialID;
            }
        })
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _getMaterialExistenceOnSolid() {
        let aFace = this.mFaces.find(face => face.data != null && face.data.materialID !== undefined)
        if (aFace !== undefined) {
            return aFace.data.materialID;
        }
        return null;
    }
    //__________________________________________________________________________________________
}