import { eDataPermission, eMaterialEquationType, eMaterialType } from "../_context/Enums";
import { iMaterialVO } from "../_context/_interfaces/Interfaces";
import { MaterialDataLoader } from "../data/data_loader/MaterialDataLoader";
import { Parser, eManipulationType } from "./Parser";
import { ParserContext } from "./ParserContext";


export class MaterialParser extends Parser<iMaterialVO> {

    //__________________________________________________________________________________________
    constructor(pFileList: FileList, pToReplace: boolean) {
        super();

        this.load({
            fileList: pFileList,
            dataLoader: MaterialDataLoader.instance,
            replaceExist: pToReplace,
            build: {
                checkValidationFunc: (pData) => this._checkMaterialVOValidation(pData),
                form: {
                    'id number': {
                        path: 'number_id',
                        isRequired: true
                    },
                    'permission': {
                        path: 'permission',
                        default: eDataPermission.PUBLIC,
                        isRequired: true
                    },
                    'name': {
                        path: 'name',
                        isRequired: true
                    },
                    'original name': {
                        path: 'original_name'
                    },
                    'Catalogue Number': {
                        path: 'catalog_number'
                    },
                    'data source': {
                        path: 'data_source'
                    },
                    'company': {
                        path: 'parameters.company'
                    },
                    'startlam': {
                        path: 'parameters.startLambda',
                    },
                    'endlam': {
                        path: 'parameters.endLambda',
                    },
                    'equationtype': {
                        path: 'parameters.type',
                        isRequired: true,
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData) => this._getMaterialEnum(pData)
                    },
                    'b1': {
                        path: 'parameters.coeffs.b1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c1': {
                        path: 'parameters.coeffs.c1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b2': {
                        path: 'parameters.coeffs.b2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c2': {
                        path: 'parameters.coeffs.c2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b3': {
                        path: 'parameters.coeffs.b3',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c3': {
                        path: 'parameters.coeffs.c3',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b4': {
                        path: 'parameters.coeffs.b4',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c4': {
                        path: 'parameters.coeffs.c4',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b5': {
                        path: 'parameters.coeffs.b5',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c5': {
                        path: 'parameters.coeffs.c5',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b6': {
                        path: 'parameters.coeffs.b6',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c6': {
                        path: 'parameters.coeffs.c6',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b7': {
                        path: 'parameters.coeffs.b7',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c7': {
                        path: 'parameters.coeffs.c7',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b8': {
                        path: 'parameters.coeffs.b8',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c8': {
                        path: 'parameters.coeffs.c8',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'd0': {
                        path: 'parameters.coeffs.d0',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'd1': {
                        path: 'parameters.coeffs.d1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'd2': {
                        path: 'parameters.coeffs.d2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'e0': {
                        path: 'parameters.coeffs.e0',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'e1': {
                        path: 'parameters.coeffs.e1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'e2': {
                        path: 'parameters.coeffs.e2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'lamtk': {
                        path: 'parameters.lamtk',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'alpha-30': {
                        path: 'parameters.alpha_minus_30',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'alpha+20': {
                        path: 'parameters.alpha_plus_20',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'density[g/cm^3]': {
                        path: 'parameters.density',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'deltapgf': {
                        path: 'parameters.deltaPgF',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'abbenumber': {
                        path: 'parameters.abbeNumber',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'weblinks': {
                        path: 'parameters.weblinks',
                        manipulationType: eManipulationType.SEMI_COLON_PARSE
                    },
                    'materialtype': {
                        path: 'parameters.materialType',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData) => this._getMaterialTypeEnum(pData)
                    },
                    'a': {
                        path: 'parameters.coeffs.a',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b': {
                        path: 'parameters.coeffs.b',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c': {
                        path: 'parameters.coeffs.c',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'd': {
                        path: 'parameters.coeffs.d',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'e': {
                        path: 'parameters.coeffs.e',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'f': {
                        path: 'parameters.coeffs.f',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'g': {
                        path: 'parameters.coeffs.g',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'h': {
                        path: 'parameters.coeffs.h',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'i': {
                        path: 'parameters.coeffs.i',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'j': {
                        path: 'parameters.coeffs.j',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'pages': {
                        path: 'parameters.pages',
                        manipulationFunction: (pData: string) => this._updatePagesContent(pData)
                    },
                    'n': {
                        path: 'parameters.coeffs.n'
                    }
                }
            }
        });
    }
    //__________________________________________________________________________________________
    private async _updatePagesContent(pData: string) {
        let aPages = ParserContext.addSpecialCharachters(pData);
        return aPages;
    }
    //__________________________________________________________________________________________
    private async _checkMaterialVOValidation(pData: iMaterialVO) {
        let aArr = new Array<{ name: string; value: number }>();
        switch (pData.parameters.type) {
            case eMaterialEquationType.SELLMEIER:
                aArr = [
                    { name: 'b1', value: pData.parameters.coeffs.b1 },
                    { name: 'c1', value: pData.parameters.coeffs.c1 },
                    { name: 'b2', value: pData.parameters.coeffs.b2 },
                    { name: 'c2', value: pData.parameters.coeffs.c2 },
                    { name: 'b3', value: pData.parameters.coeffs.b3 },
                    { name: 'c3', value: pData.parameters.coeffs.c3 },
                    { name: 'b4', value: pData.parameters.coeffs.b4 },
                    { name: 'c4', value: pData.parameters.coeffs.c4 },
                    { name: 'b5', value: pData.parameters.coeffs.b5 },
                    { name: 'c5', value: pData.parameters.coeffs.c5 },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.MODIFIED_SELLMEIER:
                aArr = [
                    { name: 'b1', value: pData.parameters.coeffs.b1 },
                    { name: 'c1', value: pData.parameters.coeffs.c1 },
                    { name: 'b2', value: pData.parameters.coeffs.b2 },
                    { name: 'c2', value: pData.parameters.coeffs.c2 },
                    { name: 'b3', value: pData.parameters.coeffs.b3 },
                    { name: 'c3', value: pData.parameters.coeffs.c3 },
                    { name: 'b4', value: pData.parameters.coeffs.b4 },
                    { name: 'c4', value: pData.parameters.coeffs.c4 },
                    { name: 'b5', value: pData.parameters.coeffs.b5 },
                    { name: 'c5', value: pData.parameters.coeffs.c5 },
                    { name: 'b6', value: pData.parameters.coeffs.b6 },
                    { name: 'c6', value: pData.parameters.coeffs.c6 },
                    { name: 'b7', value: pData.parameters.coeffs.b7 },
                    { name: 'c7', value: pData.parameters.coeffs.c7 },
                    { name: 'b8', value: pData.parameters.coeffs.b8 },
                    { name: 'c8', value: pData.parameters.coeffs.c8 },
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.CAUCHY2:
            case eMaterialEquationType.CONRADY1:
            case eMaterialEquationType.CONRADY2:
            case eMaterialEquationType.HARTMANN1:
            case eMaterialEquationType.HARTMANN2:
            case eMaterialEquationType.HARTMANN3A:
            case eMaterialEquationType.MISK01:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.SCHOTT:
            case eMaterialEquationType.SCHOTT2:
            case eMaterialEquationType.SCHOTT4:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },

                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'e', value: pData.parameters.coeffs.e },
                    { name: 'f', value: pData.parameters.coeffs.f },
                    { name: 'g', value: pData.parameters.coeffs.g },
                    { name: 'h', value: pData.parameters.coeffs.h },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.HARTMANN3B:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.HERZBERGER2X2:
            case eMaterialEquationType.HO01:
            case eMaterialEquationType.HO02:
            case eMaterialEquationType.KINGSLAKE1:
            case eMaterialEquationType.MISK02:
            case eMaterialEquationType.MISK03:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.HARTMANN4:
            case eMaterialEquationType.SELLMOD1:
            case eMaterialEquationType.SELLMOD1A:
            case eMaterialEquationType.SELLMOD2:
            case eMaterialEquationType.SELLMOD2A:
            case eMaterialEquationType.SELLMOD3:
            case eMaterialEquationType.SELLMOD6:
            case eMaterialEquationType.MISK05:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'e', value: pData.parameters.coeffs.e },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.CAUCHY:
            case eMaterialEquationType.HERZBERGER4X2:
            case eMaterialEquationType.HERZBERGER3X3:
            case eMaterialEquationType.SELLMOD5:
            case eMaterialEquationType.SELLMOD7:
            case eMaterialEquationType.SELLMOD7A:
            case eMaterialEquationType.SELLMOD9:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'e', value: pData.parameters.coeffs.e },
                    { name: 'f', value: pData.parameters.coeffs.f },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.HERZBERGER5X2:
            case eMaterialEquationType.SELLMOD4:
            case eMaterialEquationType.SELLMOD8:
            case eMaterialEquationType.KINGSLAKE2:
            case eMaterialEquationType.MISK04:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'e', value: pData.parameters.coeffs.e },
                    { name: 'f', value: pData.parameters.coeffs.f },
                    { name: 'g', value: pData.parameters.coeffs.g },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.SCHOTT5:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'e', value: pData.parameters.coeffs.e },
                    { name: 'f', value: pData.parameters.coeffs.f },
                    { name: 'g', value: pData.parameters.coeffs.g },
                    { name: 'h', value: pData.parameters.coeffs.h },
                    { name: 'j', value: pData.parameters.coeffs.j },
                    { name: 'k', value: pData.parameters.coeffs.k },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.SCHOTT3:
                aArr = [
                    { name: 'a', value: pData.parameters.coeffs.a },
                    { name: 'b', value: pData.parameters.coeffs.b },
                    { name: 'c', value: pData.parameters.coeffs.c },
                    { name: 'd', value: pData.parameters.coeffs.d },
                    { name: 'e', value: pData.parameters.coeffs.e },
                    { name: 'f', value: pData.parameters.coeffs.f },
                    { name: 'g', value: pData.parameters.coeffs.g },
                    { name: 'h', value: pData.parameters.coeffs.h },
                    { name: 'j', value: pData.parameters.coeffs.i },
                    { name: 'Start Lambda', value: pData.parameters.startLambda },
                    { name: 'End Lambda', value: pData.parameters.endLambda }
                ];
                break;
            case eMaterialEquationType.CONSTANT:
                aArr = [{ name: 'n', value: pData.parameters.coeffs.n }];
                break;
            default:
                return 'Wrong equation type.';
        }

        return this._checkMultipleNumericItemsValidation(aArr);
    }
    //__________________________________________________________________________________________
    private _checkMultipleNumericItemsValidation(pParams: Array<{ name: string; value: number }>) {
        let aInvalidParams = new Array<string>();
        for (let i = 0; i < pParams.length; i++) {
            if ((null == pParams[i].value) || (true == isNaN(pParams[i].value))) {
                aInvalidParams.push(pParams[i].name)
            }
        }

        if (0 == aInvalidParams.length) {
            return null;
        }

        let aMsg = 'The following parameters are invalid: ' + aInvalidParams[0];

        for (let i = 1; i < aInvalidParams.length; i++) {
            aMsg += ', ' + aInvalidParams[i];
        }

        return aMsg;
    }
    //__________________________________________________________________________________________
    private async _getMaterialEnum(pData: string) {
        if (null == pData) {
            return null;
        }

        switch (pData.toLowerCase().trim()) {
            case 'constant':
                return eMaterialEquationType.CONSTANT;
            case 'sellmeier':
                return eMaterialEquationType.SELLMEIER;
            case 'modified sellmeier':
                return eMaterialEquationType.MODIFIED_SELLMEIER;
            case 'cauchy':
                return eMaterialEquationType.CAUCHY;
            case 'schott':
                return eMaterialEquationType.SCHOTT;

            case 'cauchy2':
                return eMaterialEquationType.CAUCHY2;
            case 'conrady1':
                return eMaterialEquationType.CONRADY1;
            case 'conrady2':
                return eMaterialEquationType.CONRADY2;
            case 'helmholtz':
                return eMaterialEquationType.HELMHOLTZ;
            case 'hartmann1':
                return eMaterialEquationType.HARTMANN1;

            case 'hartmann2':
                return eMaterialEquationType.HARTMANN2;
            case 'hartmann3a':
                return eMaterialEquationType.HARTMANN3A;
            case 'hartmann3b':
                return eMaterialEquationType.HARTMANN3B;
            case 'hartmann4':
                return eMaterialEquationType.HARTMANN4;
            case 'herzberger2x2':
                return eMaterialEquationType.HERZBERGER2X2;

            case 'herzberger3x2':
                return eMaterialEquationType.HERZBERGER3X2;
            case 'herzberger4x2':
                return eMaterialEquationType.HERZBERGER4X2;
            case 'herzberger3x3':
                return eMaterialEquationType.HERZBERGER3X3;
            case 'herzberger5x2':
                return eMaterialEquationType.HERZBERGER5X2;
            case 'herzbergerjk':
                return eMaterialEquationType.HERZBERGERJK;


            case 'schott2':
                return eMaterialEquationType.SCHOTT2;
            case 'schott3':
                return eMaterialEquationType.SCHOTT3;
            case 'schott4':
                return eMaterialEquationType.SCHOTT4;
            case 'schott5':
                return eMaterialEquationType.SCHOTT5;
            case 'ho01':
                return eMaterialEquationType.HO01;

            case 'ho02':
                return eMaterialEquationType.HO02;
            case 'sellmod1':
                return eMaterialEquationType.SELLMOD1;
            case 'sellmod1a':
                return eMaterialEquationType.SELLMOD1A;
            case 'sellmod2':
                return eMaterialEquationType.SELLMOD2;
            case 'sellmod2a':
                return eMaterialEquationType.SELLMOD2A;

            case 'sellmod3':
                return eMaterialEquationType.SELLMOD3;
            case 'sellmod4':
                return eMaterialEquationType.SELLMOD4;
            case 'sellmod5':
                return eMaterialEquationType.SELLMOD5;
            case 'sellmod6':
                return eMaterialEquationType.SELLMOD6;
            case 'sellmod7':
                return eMaterialEquationType.SELLMOD7;

            case 'sellmod7a':
                return eMaterialEquationType.SELLMOD7A;
            case 'sellmod8':
                return eMaterialEquationType.SELLMOD8;
            case 'sellmod9':
                return eMaterialEquationType.SELLMOD9;
            case 'kingslake1':
                return eMaterialEquationType.KINGSLAKE1;
            case 'kingslake2':
                return eMaterialEquationType.KINGSLAKE2;

            case 'misk01':
                return eMaterialEquationType.MISK01;
            case 'misk02':
                return eMaterialEquationType.MISK02;
            case 'misk03':
                return eMaterialEquationType.MISK03;
            case 'misk04':
                return eMaterialEquationType.MISK04;
            case 'misk05':
                return eMaterialEquationType.MISK05;

            default:
                return null;
        }
    }
    //__________________________________________________________________________________________
    private async _getMaterialTypeEnum(pData: string) {
        pData = pData.toUpperCase();

        switch (pData) {
            case 'MAIN':
                return eMaterialType.MAIN;
            case 'ORGANIC':
                return eMaterialType.ORGANIC;
            case 'GLASS':
                return eMaterialType.GLASS;
            case 'OTHER':
                return eMaterialType.OTHER;
            case 'CRYSTALS':
                return eMaterialType.CRYSTALS;
            default:
                return null;
        }
    }
    //__________________________________________________________________________________________

}
