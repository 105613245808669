export class HTMLAnalysisTemplate {

    public static SPOT_STRUCTURE = `<div class="spot-view w-100 h-100" >
    <div class="ext-analysis__content__top">
        <div class="ext-analysis__content__top__left" id="y-label-container"></div>
        <div class="ext-analysis__content__top__middle">



        <table id="table-container">
                <tr>
                    <td class="y-ticks" id="y-ticks"></td>
                    <td class="display-content">
                      
                        <div id="graph-container" class="display-content" style="position:relative;">  <div id="analysis-canvas-container"></div></div>
                    </td>
                </tr>
                <tr>
                    <td class="empty"></td>
                    <td class="x-ticks" id="x-ticks"></td>
                </tr>
            </table>

      


            
        </div>
        <div class="ext-analysis__content__top__right" id="spectrum-bar-container"></div>
    </div>
    <div class="ext-analysis__content__bottom" id="x-label-container"></div>

</div>`;
}
