import { Op3dContext } from "../../_context/Op3dContext";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { AnalysisCache, iAnalysisCacheData } from "./AnalysisCache";
import { AnalysisCanvasUtils } from "./AnalysisCanvasUtils";
import { AnalysisConstants } from "./AnalysisConstants";
import { iAnalysisItem, eAnalysisType, AnalysisContext } from "./AnalysisContext";
import { AnalysisDataUtils } from "./AnalysisDataUtils";
import { AnalysisPortal } from "./AnalysisPortal";
import { anSmallResultFactory } from "./ResultFactory/anSmallResultFactory";

export class CompareAnalysis extends Op3dComponentBase<{ fromMinimize: boolean, analysis_ids: Array<string> }> {

    private static INSTANCE: CompareAnalysis;

    private mResultItemTemplate: HTMLElement;
    private mResultItemsParent: HTMLElement;
    private mId = Op3dUtils.idGenerator();
    private mResults = Array<{ container: HTMLElement, analysis_id: string }>();

    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/analysis/compare_analyses.html',
            draggableParams: {
                snap: true,
                class: "draggable-form",
                containment: 'window',
                handle: ".modal-header"
            },
            minimizeData: {
                title: "Analysis Portal",
                showCallback: () => this._onMaximize(),
                htmlItem: null,
                iconClasses: "icon-compare-on",

            }
        });
    }
    //__________________________________________________________________________________________
    private _onMaximize() {
        this.mData.fromMinimize = true;
        this.show();
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.classList.add('overflow-hidden');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.style.resize = "both";
            aDiv.style.minWidth = 610 + 'px';
            aDiv.style.maxWidth = '95vw';
            aDiv.style.minHeight = 620 + 'px';
            aDiv.style.maxHeight = '80vh'
            aDiv.style.height = 620 + 'px';
            aDiv.id = "analysis-screens";
            document.getElementById('forms').appendChild(aDiv);

            this.INSTANCE = new CompareAnalysis(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {

        this.mResultItemTemplate = this._getPart("result-item", true);
        this.mResultItemsParent = this.mResultItemTemplate.parentElement;
        ViewUtils.removeFromParent(this.mResultItemTemplate);

        ViewUtils.makeUnselectable(this.mContainer);

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData?: { fromMinimize: boolean, analysis_ids: Array<string> }): void {
        this.mData = pData;

    }
    //__________________________________________________________________________________________
    private _onUpdatedAnalysis(pNewAnalysisData: Array<string>) {
        if (this.mData !== undefined) {
            let aNewElements = DataUtils.filterArray(this.mData.analysis_ids, pNewAnalysisData);
            let aData = AnalysisDataUtils.getAnalysisData(aNewElements);
            for (let i = 0; i < aData.length; i++) {
                this._addResult(aData[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    protected async _onShown() {
        if (this.mData != null && this.mData.fromMinimize) {
            return;
        }

        AnalysisCache.addEventListener(this.mId, (pNewIds: Array<string>) => this._onUpdatedAnalysis(pNewIds),
            this.mData.analysis_ids, true);

        this._clearItems();

        let aData = AnalysisDataUtils.getAnalysisData(this.mData.analysis_ids);
        for (let i = 0; i < aData.length; i++) {
            this._addResult(aData[i]);
        }
    }
    //__________________________________________________________________________________________
    private _addResult(pData: { analysisItem: iAnalysisItem, cacheData: iAnalysisCacheData }) {
        let aItem = this.mResults.find(item => item.analysis_id == pData.analysisItem.id);
        let aClone: HTMLElement;
        let aResultItem: HTMLElement;

        if (aItem != null) {
            aClone = aItem.container;
            aResultItem = Op3dUtils.getElementIn(aClone, "single-analysis-item");

        } else {
            aClone = this.mResultItemTemplate.cloneNode(true) as HTMLElement;
            this.mResultItemsParent.appendChild(aClone);
            this.mResults.push({
                container: aClone,
                analysis_id: pData.analysisItem.id
            });

            aResultItem = Op3dUtils.getElementIn(aClone, "single-analysis-item");

            this._addButton(aResultItem,
                pData.analysisItem);
        }

        let aIcon = "";
        let aAnalysisType = pData.cacheData.common_data.analysisType;
        if (eAnalysisType.ADVANCED == aAnalysisType) {
            let aSimulationKind = pData.analysisItem.simulation_kind;

            let aIconSvg = AnalysisContext.getSimulationDDICON(aAnalysisType, aSimulationKind);
            let aIconCot = document.createElement('div');
            aIconCot.classList.add('svg_icon_class');
            aIconCot.innerHTML = aIconSvg;
            aIcon = aIconCot.outerHTML;
        }

        let aLabel = Op3dUtils.getElementIn(aClone, "label");
        let aColor = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.name === pData.analysisItem.name && pData.analysisItem.type === item.type).color;

        aLabel.className = "analysis-label " + aColor;
        aLabel.innerHTML = aIcon + pData.analysisItem.name;

        let aDetectorName = Op3dUtils.getElementIn(aClone, "detector-name");
        aDetectorName.innerHTML = Op3dContext.PARTS_MANAGER.getPartByFaceID(pData.cacheData.common_data.faceId).getIndexedLabel(true);

        let aSurfaceName = Op3dUtils.getElementIn(aClone, "surface-name");
        aSurfaceName.innerText = Op3dContext.PARTS_MANAGER.getFaceNameById(pData.cacheData.common_data.faceId);

        new anSmallResultFactory().createResult({
            container: aResultItem,
            graphData: {
                labels: AnalysisCanvasUtils.getLabels(pData.analysisItem),
                spectrumData: {
                    label: AnalysisCanvasUtils.getSpectrumLabel(pData.analysisItem),
                    range: pData.cacheData.matrix.original.values_range
                },
                range_points: AnalysisCanvasUtils.getRange(pData.cacheData, pData.analysisItem.display),
                cacheData: pData.cacheData,
                analysisItem: pData.analysisItem
            },
            uiParams: AnalysisContext.GRID_GENERAL_OPTIONS,
        });
    }
    //__________________________________________________________________________________________
    private _clearItems() {
        this.mResults = new Array();
        ViewUtils.removeElementChildren(this.mResultItemsParent);
    }
    //__________________________________________________________________________________________
    private _addButton(pItem: HTMLElement, pAnalysisData: iAnalysisItem) {
        pItem.addEventListener("click",
            (_e: MouseEvent) => AnalysisPortal.instance.onClickScreen(pAnalysisData));
    }
    //__________________________________________________________________________________________
}
