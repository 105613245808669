﻿import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Part } from "../../../parts/Part";
import { iPart } from "../../../parts/PartInterfaces";
import { ViewUtils } from "../../ViewUtils";
import { OptomechanicsOpticsForm } from "./OptomechanicsOpticsForm";
import { OptomechanicsSolidsForm } from "./OptomechanicsSolidsForm";
import { uoSection } from "./uoSection";

export interface iuoSolidsParams {
    ipart: iPart;
    mouseenter: (pPathNum: number) => void;
    mouseleave: () => void;
    part: Part;
}

export class uoSolids extends uoSection<iuoSolidsParams> {

    private static SKIN_PATH = './skins/forms/optics/uo_solids_info.html';

    private mButton: HTMLElement;
    private mElementsParent: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoSolids.SKIN_PATH,
            title: 'Optical solids',
            isNewSkin: false,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mButton = Op3dUtils.getElementIn(this.mContainer, 'one_button_solids');
        this.mElementsParent = this.mButton.parentElement;
        this.mElementsParent.removeChild(this.mButton);
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mElementsParent);
    }
    //__________________________________________________________________________________________
    protected async _setOptomechanicsData(puoSolidsParams: iuoSolidsParams) {

        this._clear();
        let aPart = puoSolidsParams.part;
        let aiPart = puoSolidsParams.ipart;
        let aFaces = aiPart.subParts[0].shapes[0].solids[0].faces[0].indexes;
        let aSolids = 0;
        for (let i = 0; i < aFaces.length; i++) {
            if(aFaces[i].path[1] !== aSolids){
                aSolids++;
            }
        }
        for (let i = 0; i <= aSolids; i++) {
            let aBtn = this.mButton.cloneNode(true) as HTMLElement;
            Op3dUtils.getElementIn(aBtn, 'btn_name_solids').innerHTML = `Solid ${i+1}`;
            let aFacesSolid = aPart.getSolidsFacesByNumber(i);

            aBtn.addEventListener('click', () => {
                OptomechanicsOpticsForm.instance.close();
                OptomechanicsSolidsForm.instance.open({
                    faces: aFacesSolid,
                    part: puoSolidsParams.part
                });
            });

            aBtn.addEventListener('mouseenter', () =>
                puoSolidsParams.mouseenter(i)
            );
            aBtn.addEventListener('mouseleave', () => { }
            );

            this.mElementsParent.appendChild(aBtn);
        
        }
    }
    //__________________________________________________________________________________________
}
