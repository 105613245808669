import { MOUSE, MathUtils, Vector3 } from "three";
import { EventsContext } from "../_context/EventsContext";
import { Op3dSceneWidget } from "../_widget/Op3dSceneWidget";
import { eMouseMode, Op3dScene } from "./Op3dScene";
import { SceneContext } from "./SceneContext";
import { Op3dContext } from "../_context/Op3dContext";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { EventBase } from "../../oc/events/EventBase";
import { EventManager } from "../../oc/events/EventManager";
import { OrbitControls } from './OrbitControls.js';

export class Op3dOrbitController {
    public static INITIAL_CONTROLLER = {
        LEFT: null,
        RIGHT: MOUSE.LEFT,
        MIDDLE: MOUSE.RIGHT
    };
    public static INITIAL_DIST: number = 1050;
    public static ORTHO_POS_AXIS: number = 350;

    private mOrbitControls: OrbitControls;
    private mLookAt: Vector3 = new Vector3();
    private mInstaceID: string = Op3dUtils.idGenerator();

    private mMouseMode: eMouseMode = eMouseMode.GENERAL;
    constructor(pOp3dScene: Op3dScene | Op3dSceneWidget) {
        this._initController(pOp3dScene);

        EventManager.addEventListener(EventsContext.ON_ZOOM,
            (pData: EventBase<number>) => this._onZoom(pData.data), this);
    }
    //__________________________________________________________________________________________
    private _initController(pOp3dScene: Op3dScene | Op3dSceneWidget) {

        this.mOrbitControls = new OrbitControls(SceneContext.CAMERA,
            SceneContext.RENDERER.domElement);


        this.mOrbitControls.maxPolarAngle = Math.PI;

        this.mOrbitControls.enablePan = true;
        this.mOrbitControls.enableZoom = true;
        (this.mOrbitControls as any).enableKeys = false;
        this.mOrbitControls.enableDamping = false;
        this.mOrbitControls.enableRotate = true;
        this.mOrbitControls.rotateSpeed = 0.50;
        this.mOrbitControls.panSpeed = 0.75;
        this.mOrbitControls.target = new Vector3();


        this.mOrbitControls.minDistance = 1;
        this.mOrbitControls.maxDistance = 100000;
        this.mOrbitControls.minZoom = 1e-5;
        this.mOrbitControls.maxZoom = 1e5;

        this.mOrbitControls.mouseButtons = Op3dOrbitController.INITIAL_CONTROLLER;

        SceneContext.CAMERA.lookAt(new Vector3());
        SceneContext.CAMERA.updateMatrix();
        SceneContext.CAMERA.updateMatrixWorld(true);
        pOp3dScene.addRenderCallback(this.mInstaceID,
            () => this.update());
        //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


    }

    //__________________________________________________________________________________________
    public updateZoomParams() {
        this.mOrbitControls.maxDistance = ((Op3dOrbitController.INITIAL_DIST * 100) /
            Op3dContext.USER_VO.simulationSettings.maxZoomOut);
        this.mOrbitControls.minDistance = ((Op3dOrbitController.INITIAL_DIST * 1000) /
            Op3dContext.USER_VO.simulationSettings.maxZoomIn);
    }
    //__________________________________________________________________________________________
    public update() {
        if (false == this.mOrbitControls.enabled) {
            return;
        }

        this.mOrbitControls.update();

        if (null == SceneContext.OP3D_SCENE) {
            return;
        }


        this.mLookAt = this.mOrbitControls.target

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public setLookAt(pVec: Vector3) {
        this.mLookAt = pVec;
        this.mOrbitControls.target = pVec;
        //this.update();
    }
    //__________________________________________________________________________________________
    public setLookAtNEW(pVec: Vector3) {
        // this.mLookAt = pVec;
        this.mOrbitControls.target = pVec;
        //this.update();
    }
    //__________________________________________________________________________________________
    public get mouseMode() {
        return this.mMouseMode;
    }
    //__________________________________________________________________________________________
    public setMouseMode(pMouseMode: eMouseMode) {
        this.mMouseMode = pMouseMode;

        let aOrbit: MOUSE;
        let aPan: MOUSE;

        switch (this.mMouseMode) {
            case eMouseMode.GENERAL:
                aOrbit = MOUSE.RIGHT;
                aPan = MOUSE.LEFT;
                break;
            case eMouseMode.PAN:
                aPan = MOUSE.RIGHT;
                aOrbit = MOUSE.LEFT;
                break;
        }

        this.mOrbitControls.mouseButtons = {
            LEFT: null,
            RIGHT: aPan,
            MIDDLE: aOrbit
        };
        this.update();
    }
    //__________________________________________________________________________________________
    private _onZoom(pVal: number) {

        if (pVal > 0) {
            this.mOrbitControls.dOut(SceneContext.CONTROLLER_ZOOM_STEPS);

        } else {
            this.mOrbitControls.dIn(SceneContext.CONTROLLER_ZOOM_STEPS);
        }

        this.update();
    }
    //__________________________________________________________________________________________
    public get orbitControls() {
        return this.mOrbitControls
    }
    //__________________________________________________________________________________________
    // private _onZoomOut() {
    //     this.mOrbitControls.dollyOut(SceneContext.CONTROLLER_ZOOM_STEPS);
    //     this.update();
    // }
    //__________________________________________________________________________________________
    public get maxDistance() {
        return this.mOrbitControls.maxDistance;
    }
    //__________________________________________________________________________________________
    public get vectorToLookAt(): Vector3 {
        let aCamPos = SceneContext.CAMERA.position.clone();
        let aVectorToLookAt = aCamPos.sub(this.mLookAt);
        return aVectorToLookAt;
    }
    //__________________________________________________________________________________________

    //__________________________________________________________________________________________
    public get minDistance() {
        return this.mOrbitControls.minDistance;
    }
    //__________________________________________________________________________________________
    public get lookAt() {
        return this.mLookAt;
    }
    //__________________________________________________________________________________________
    public set target(pVec: Vector3) {
        this.mOrbitControls.target = pVec;
    }
    //__________________________________________________________________________________________
    public setTarget(pVec: Vector3) {
        this.mOrbitControls.target.copy(pVec)
    }
    //__________________________________________________________________________________________
    public enable(pEnable: boolean) {
        this.mOrbitControls.enabled = pEnable;
    }
    //__________________________________________________________________________________________

}
