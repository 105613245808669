import { iHash, iMinMax, iNumericKeyHash, iPoint2D } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { iAnalysisData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { iAnalysisCacheData } from "./AnalysisCache";

export type tAnalysisType = tFastAnalysisType | tAdvancedAnalysisType;

export type tFastAnalysisType = "Spot (Incoherent Irradiance)";

export type tAdvancedAnalysisType =
    "Spot (Incoherent Irradiance)" |
    "Spot (Coherent Irradiance)" |
    "Coherent Phase" |
    "Spot (Coherent Irradiance) Huygens" |
    "Coherent Phase Huygens" |
    "Spot (Coherent Irradiance) Fresnel" |
    "Coherent Phase Fresnel" |
    "Spot (Coherent Irradiance) Polarized" |
    "Coherent Phase Polarized" |
    "Spot (Incoherent Irradiance) Polarized" |
    "Spot (Coherent Irradiance) Fresnel Far" |
    "Coherent Phase Fresnel Far" |
    "Fresnel PSF" |
    "Huygens PSF";

export enum eAnalysisType {
    FAST,
    ADVANCED
}


export interface iBasicGraphDataParams {
    range_points: {
        x: iMinMax,
        y: iMinMax
    };
    labels: { x: string, y: string };
    spectrumData: {
        label: string,
        range: iMinMax
    },
    cacheData: iAnalysisCacheData;
    analysisItem: iAnalysisItem;
    canvas?: HTMLCanvasElement;
    points?: iPoint2D<Array<iPoint2D>>;
}

export interface iAnalysisItemSurfaceParams {
    name: string;
    faceId: string;
    /**
     * internal id of part
     */
    internalId: string;
    onChange: (pInternalId: string, pData: iAnalysisData) => void;
    analysisData: iAnalysisData;
    part: Part;
}

export interface iAnalysisReloadData {
    size: iPoint2D, faceId: string, analysis: iAnalysisItem
}

export interface iAnalysisOptionsData {
    faceId: string,
    line: HTMLElement,
    onRemove: (pId: string) => void,
    analysisData: iAnalysisItem<tAnalysisType>,
    onChange: (e?: Event) => void;
    onPixelOverLimit: (e?: Event) => void;
    titleSizeCotnainer: HTMLElement;
}

export interface iAnalysisResultItem {
    needsUpdate: boolean;
    callback: (e) => void;
    compare: boolean;
    container: HTMLElement,
    isLocked: boolean,
    cacheData: iAnalysisCacheData,
    analysisItem: iAnalysisItem;
    id: string;
    isReady: boolean
}

export interface iAnalysisDropDownData {
    label: HTMLElement;
    name: tAnalysisType;
    type: eAnalysisType,
    analysis_id: string;
    isActiveCheckbox: HTMLInputElement;
    select: HTMLElement;
    display: HTMLSelectElement;
    resolutionX: HTMLInputElement;
    resolutionY: HTMLInputElement;
    raysCount: iHash<HTMLInputElement>;
    simulation_kind: eSimulationKind;
}

export enum eSimulationKind {
    RAY_TRACING = "RAY_TRACING",
    DIFFRACTION = "DIFFRACTION",
}
export enum eDiffractionKind {
    FRESNEL = "FRESNEL",
    HUYGENS = "HUYGENS",
}
export enum eFresnelKind {
    NEAR = "NEAR",
    FAR = "FAR",
}
export enum ePolarizationKind {
    NONE = "NONE",
    X = "X",
    Y = "Y",
    Z = "Z",
}

export interface iGraphUIParams {
    label_font_size: number;
    ticks_font_size: number;
    to_fixed: number;
    spectrum_bar_string_length: number;
}

export enum eAnalysisDisplay {
    GRAY_SCALE = "Gray Scale",
    FALSE_COLOR = "False Color",
    SPECTRAL_VIEW = "Spectral View",
    X_CROSS_SECTION = "X Cross-section",
    Y_CROSS_SECTION = "Y Cross-section",
    XY_CROSS_SECTION = "XY Cross-section"
}


export interface iAnalysisItem<T = tAnalysisType> {
    /**
     * analysis id
     */
    id: string;
    name: T,
    display: eAnalysisDisplay;
    isActiveAnalysisSurface: boolean;
    numRays: iHash<number>;
    resolution: { x: number, y: number };
    type: eAnalysisType;
    isActive: boolean;
    analysis_version_old: string;
    analysis_version: string;
    simulation_kind: eSimulationKind;
}

export interface iAnalysisDDItem {
    name: tAnalysisType;
    oldNames?: tAnalysisType[];
    type: eAnalysisType,
    isDiffration: boolean,
    isSystemAnalysis?: boolean,
    /**
     * wheter this analysis can be displayed for only one wavelength
     */
    tesselation?: { sectors: number, slices: number };
    /**
     * for phase where by defautl we should use onyl one wavelength
     */
    useOneWavelength?: boolean;
    shortcut: string;
    /**
     * wheter this analysis is being used to show power 
     */
    isPowerReadingAnalysis?: boolean;
    /**
     * wheter this analysis supported now or not
     */
    isFresnelAnalysis?: boolean;
    disabled: boolean;
    unitLabel: string;
    color: string;
    label2?: string;
    isPolarized?: boolean

}
export class AnalysisContext {

    public static SVG_ICONS: iNumericKeyHash<iHash<string>>;

    public static ANALYSIS_EXTENDED_DISPLAY_TYPES: Array<eAnalysisDisplay> =
        [eAnalysisDisplay.FALSE_COLOR, eAnalysisDisplay.GRAY_SCALE,
        eAnalysisDisplay.SPECTRAL_VIEW,
        eAnalysisDisplay.XY_CROSS_SECTION];

    public static ANALYSIS_EXTENDED_DISPLAY_TYPES_LINE: Array<eAnalysisDisplay> =
        [eAnalysisDisplay.X_CROSS_SECTION, eAnalysisDisplay.Y_CROSS_SECTION];

    public static ANALYSIS_2D_DISPLAY_TYPES: Array<eAnalysisDisplay> =
        [eAnalysisDisplay.FALSE_COLOR, eAnalysisDisplay.GRAY_SCALE,
        eAnalysisDisplay.SPECTRAL_VIEW
        ];
    //__________________________________________________________________________________________
    public static getSimulationDDICON(pType: eAnalysisType, pKind: eSimulationKind) {
        if (null == AnalysisContext.SVG_ICONS) {
            AnalysisContext.SVG_ICONS = {};

            AnalysisContext.SVG_ICONS[eAnalysisType.FAST] = {};
            AnalysisContext.SVG_ICONS[eAnalysisType.FAST][eSimulationKind.RAY_TRACING] = '<svg width="16" height="14" viewBox="0 0 16 14" fill="inherit" xmlns="http://www.w3.org/2000/svg"> <rect width="16" height="14" rx="4"/> </svg>';

            AnalysisContext.SVG_ICONS[eAnalysisType.ADVANCED] = {};
            AnalysisContext.SVG_ICONS[eAnalysisType.ADVANCED][eSimulationKind.RAY_TRACING] = '<svg width="14" height="14" viewBox="0 0 14 14" fill="inherit" xmlns="http://www.w3.org/2000/svg"> <circle cx="7" cy="7" r="7" /> <path d="M6.80979 2.58541C6.86966 2.40115 7.13034 2.40115 7.19021 2.58541L8.07767 5.31672C8.10444 5.39912 8.18123 5.45492 8.26788 5.45492H11.1397C11.3335 5.45492 11.414 5.70284 11.2573 5.81672L8.93391 7.50476C8.86382 7.55569 8.83448 7.64596 8.86126 7.72837L9.74871 10.4597C9.80859 10.6439 9.59769 10.7972 9.44095 10.6833L7.11756 8.99524C7.04746 8.94431 6.95254 8.94431 6.88244 8.99524L4.55905 10.6833C4.40231 10.7972 4.19141 10.6439 4.25129 10.4597L5.13874 7.72837C5.16552 7.64596 5.13618 7.55569 5.06609 7.50476L2.7427 5.81672C2.58595 5.70284 2.66651 5.45492 2.86025 5.45492H5.73212C5.81877 5.45492 5.89556 5.39912 5.92233 5.31672L6.80979 2.58541Z" fill="white" class="an_icon_inside"/> </svg>'
            AnalysisContext.SVG_ICONS[eAnalysisType.ADVANCED][eSimulationKind.DIFFRACTION] = '<svg width="14" height="14" viewBox="0 0 14 14" fill="inherit" xmlns="http://www.w3.org/2000/svg"> <circle cx="7" cy="7" r="7"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 6 8)" class="an_icon_inside" fill="white"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 3.5 4)" class="an_icon_inside" fill="white"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 3.5 12)" class="an_icon_inside" fill="white"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 8.5 4)" class="an_icon_inside" fill="white"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 8.5 12)" class="an_icon_inside" fill="white"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 8)" class="an_icon_inside" fill="white"/> <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 1 8)" class="an_icon_inside" fill="white"/> </svg>'
        }

        return AnalysisContext.SVG_ICONS[pType][pKind];
    }
    //__________________________________________________________________________________________
    public static getAdvancedIcon() {
        let aIcon = document.createElement("i");
        aIcon.classList.add(AnalysisContext.ANALYSID_ADVANCED_ICON_CLASS);
        return aIcon;
    }
    //__________________________________________________________________________________________
    private static ANALYSID_ADVANCED_ICON_CLASS = 'icon-circle-star';

    public static XY_CROSS_SECTION_LABELS: iPoint2D<string> = {
        x: "Coordinate value",
        y: ""
    }

    public static X_CROSS_SECTION_LABELS: iPoint2D<string> = {
        x: "X Coordinate value",
        y: ""
    }

    public static Y_CROSS_SECTION_LABELS: iPoint2D<string> = {
        x: "Y Coordinate value",
        y: ""
    }

    public static GRAY_SCALE_LABELS: iPoint2D<string> = {
        x: "X Coordinate value",
        y: "Y Coordinate value"
    }

    public static DEFAULT_RESOLUTION: iPoint2D = {
        x: 100, y: 100
    };

    //__________________________________________________________________________________________
    private static DEFAULT_ANALYSIS_PROPS: iAnalysisItem<tFastAnalysisType> = {
        type: eAnalysisType.FAST,
        isActiveAnalysisSurface: true,
        display: eAnalysisDisplay.FALSE_COLOR,
        numRays: {},
        id: null,
        isActive: true,
        resolution: AnalysisContext.DEFAULT_RESOLUTION,
        name: "Spot (Incoherent Irradiance)",
        analysis_version_old: "",
        analysis_version: "",
        simulation_kind: eSimulationKind.RAY_TRACING
    }
    //__________________________________________________________________________________________
    public static getDefaultAnalysis() {
        let aNewAnalysis = DataUtils.getObjectCopy(AnalysisContext.DEFAULT_ANALYSIS_PROPS);
        aNewAnalysis.id = Op3dUtils.idGenerator();
        return aNewAnalysis;
    }
    //__________________________________________________________________________________________
    public static LIVE_SCREEN = "live-screen";
    public static CANVAS_CONTAINER = "canvas-context-container";
    public static Y_LABEL_CONTAINER = "y-label-container";
    public static X_LABEL_CONTAINER = "x-label-container";
    public static MEASURES_CONTAINER = "measures-container";
    public static GRAPH_CONTAINER = "graph-container";
    public static SPECTRUM_BAR_CONTAINER = "spectrum-bar-container";
    public static STATIC_DIV = "static-div";
    public static FREEZE_CLASS = "frozen-analysis";
    public static DETECTOR_ID = 0;

    public static GENERAL_OPTIONS = {
        labels: '#666',
        general: 'black',
        font_family: 'monospace',
    }

    public static GRID_GENERAL_OPTIONS: iGraphUIParams = {
        ticks_font_size: 10,
        label_font_size: 10,
        to_fixed: 3,
        spectrum_bar_string_length: 4

    }

    public static EXTENDED_GENERAL_OPTIONS: iGraphUIParams = {
        ticks_font_size: 13,
        label_font_size: 14,
        to_fixed: 4,
        spectrum_bar_string_length: 6
    }

    public static SPOT_CROSS_SECTION_OPTIONS = {
        X_PATH_COLOR: "#0CB755",
        Y_PATH_COLOR: "#FF5A5F"
    }

    public static SPOT_OPTIONS = {
        SPOT_X_TICKS_COUNT: 3,
        SPOT_Y_TICKS_COUNT: 3,
        BAR_TICKS_COUNT: 10,
        SPECTRUM_BAR_START: { x: 0.01, y: 0.3 },
        SPECTRUM_BAR_SIZE: { width: 0.3, height: 0.5 },
        SPECTRUM_BAR_SIZE_LIMIT: { width: 50, height: Number.MAX_SAFE_INTEGER },
        GRAPH_SIZE: { width: 0.82, height: 0.88 },
        GRAPH_START: { x: 0.12, y: 0.05 }
    }
}
