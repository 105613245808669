import { eUnitType } from "../../_context/Enums";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { iSceneOptions } from "../../scene/GridManager";
import { SceneContext } from "../../scene/SceneContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { newNumberInputElement } from "../part_info/_components/NewNumberInputElement";
import { iNumberInputData } from "../part_info/_components/NumberInputElement";

export class BreadboardForm extends Op3dComponentBase {

    private static INSTANCE: BreadboardForm;

    private mSizeX: newNumberInputElement;
    private mSizeY: newNumberInputElement;

    private mBreadboardCheckbox: HTMLInputElement;
    private mGridboardCheckbox: HTMLInputElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/breadboard_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == BreadboardForm.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            document.getElementById('forms').appendChild(aDiv);

            BreadboardForm.INSTANCE = new BreadboardForm(aDiv);
        }

        return BreadboardForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _onOpen() {
        let aSceneOptions = await SceneContext.GRID_MANAGER.getSceneOptions();

        this.mBreadboardCheckbox.checked = aSceneOptions.isBreadboardVisible;
        this.mGridboardCheckbox.checked = aSceneOptions.isGridVisible;

        let aScale = (eUnitType.INCHES == UnitHandler.PRESENTED_UNIT) ? 1 : 25;
        this.mSizeX.value = (aSceneOptions.divisions.x * aScale);
        this.mSizeY.value = (aSceneOptions.divisions.y * aScale);
    }
    //__________________________________________________________________________________________
    private _onSave() {
        let aScale = (eUnitType.INCHES == UnitHandler.PRESENTED_UNIT) ? 1 : 25;

        let aDivisionsX = parseFloat((this.mSizeX.value / aScale).toFixed(0));
        let aDivisionsY = parseFloat((this.mSizeY.value / aScale).toFixed(0));

        let aSceneOptions: iSceneOptions = {
            divisions: { x: aDivisionsX, y: aDivisionsY },
            isBreadboardVisible: this.mBreadboardCheckbox.checked,
            isGridVisible: this.mGridboardCheckbox.checked
        }
        SceneContext.GRID_MANAGER.setSceneOptions(aSceneOptions, true);
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mBreadboardCheckbox = this._getPart('show_breadboard') as HTMLInputElement;
        this.mGridboardCheckbox = this._getPart('show_grid') as HTMLInputElement;

        let aSizeXCot = this._getPart('size_x');
        let aSizeXOptions: iNumberInputData = {
            isGlobalToFixed: true,
            field_name: 'X: ',
            hasArrows: true,
            unitName: UnitHandler.shortSign,
            toFixed: 0
        };

        let aStep = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 25 : 1;
        this.mSizeX = new newNumberInputElement(aSizeXCot, aSizeXOptions);
        this.mSizeX.setData({
            callback: (pValue) => {
                if ((eUnitType.INCHES == UnitHandler.PRESENTED_UNIT)) {
                    return;
                }

                if (0 == (pValue % 25)) {
                    return;
                }

                let aNext = OP3DMathUtils.nextDividedByN(pValue, aStep);
                let aPrev = OP3DMathUtils.prevDividedByN(pValue, aStep);
                let aVal = ((pValue - aPrev) < ((aNext - pValue))) ? aPrev : aNext;
                this.mSizeX.value = aVal;
            },
            step: aStep,
            isInt: true,
            range: {
                min: (aStep * 20),
                max: (aStep * 2000)
            }
        });

        let aSizeYCot = this._getPart('size_y');
        let aSizeYOptions: iNumberInputData = {
            isGlobalToFixed: true,
            field_name: 'Y: ',
            hasArrows: true,
            unitName: UnitHandler.shortSign,
            toFixed: 0
        };

        this.mSizeY = new newNumberInputElement(aSizeYCot, aSizeYOptions);
        this.mSizeY.setData({
            callback: (pValue) => {
                if ((eUnitType.INCHES == UnitHandler.PRESENTED_UNIT)) {
                    return;
                }

                if (0 == (pValue % 25)) {
                    return;
                }

                let aPrev = OP3DMathUtils.prevDividedByN(pValue, aStep);
                let aNext = OP3DMathUtils.nextDividedByN(pValue, aStep);
                let aVal = ((pValue - aPrev) < ((aNext - pValue))) ? aPrev : aNext;
                this.mSizeY.value = aVal;
            },
            step: aStep,
            isInt: true,
            range: {
                min: (aStep * 20),
                max: (aStep * 2000)
            }
        });

        this._getPart('save-btn').addEventListener('click', () => this._onSave());
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
}
