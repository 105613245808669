import { eStateToAnalysis } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { SettingsContext } from "../../../settings/SettingsContext";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";
import { iNumberInputElementParams } from "../PartInfoSection";

export interface iNumberInputData extends iNIEBasicCreationData {
    /**
     *@default is TRUE
     */
    hasArrows?: boolean;
};

export interface iNIEBasicCreationData {
    /**
     * addition to title
     */
    unitName?: string;
    worldToUnit?: number;
    /**
     * how much digets after dot to keep
     */
    toFixed?: number | false;
    isGlobalToFixed: boolean;

    /**
    * default is false
    */
    isScientific?: boolean;

    sign?: string;


    field_name?: string;

    startValue?: number;

    label?: string;

    triggers?: iNIETriggers;

}

export interface iNIETriggers {
    saveHistory?: boolean;
    saveScene?: boolean;
    triggerAnalysis?: boolean;
}
/**
    * 
   @deprecated
   @description use NIE instead
    */
export class NumberInputElement {

    private mCreationData: iNumberInputData;
    private mParams: iNumberInputElementParams = {};
    private mInput: iOP3DHTMLInputElement;
    private mContainer: HTMLElement;
    private mActualValue: number;

    /**
    * 
    *@deprecated
    *@description use NIE instead
    */
    constructor(pContainer: HTMLElement, pData: iNumberInputData) {
        this._init(pContainer, pData);
    }
    //__________________________________________________________________________________________
    public reset() {
        this.mInput.value = (null != this.mParams.startValue) ?
            this.mParams.startValue.toString() : '0';

        if (this.mCreationData.isScientific == true) {
            this.mInput.value = OP3DMathUtils.getScientificValue(parseFloat(this.mInput.value));
        }
        this.mInput.prevValue = this.mInput.value;
    }
    //__________________________________________________________________________________________
    public updateCreationData(pData: iNIEBasicCreationData) {
        for (let key in pData) {
            this.mCreationData[key] = pData[key];
        }

        if (null != pData.unitName) {
            let aTitle = Op3dUtils.getElementIn(this.mContainer, 'title_addition');
            aTitle.innerHTML = this.mCreationData.unitName;
        }

        if (null != pData.field_name) {
            let aTextDiv = Op3dUtils.getElementIn(this.mContainer, 'text_div');
            if (null != aTextDiv) {
                aTextDiv.setAttribute('field_name', this.mCreationData.field_name);
            }
        }
    }
    //__________________________________________________________________________________________
    public setData(pNewParams: iNumberInputElementParams) {
        if (null == pNewParams) {
            return;
        }

        if (null != pNewParams.callback) {
            this.mParams.callback = pNewParams.callback;
        }

        if (null != pNewParams.range) {
            this.mParams.range = pNewParams.range;
        }

        if (null != pNewParams.step) {
            this.mParams.step = pNewParams.step;
        }

        if (null != pNewParams.startValue) {
            this.mParams.startValue = pNewParams.startValue;
        }

        if (null != pNewParams.factor) {
            this.mParams.factor = pNewParams.factor;
        }

        if (null != pNewParams.mod) {
            this.mParams.mod = pNewParams.mod;
        }

        if (true == pNewParams.isInt) {
            this.mCreationData.toFixed = 0;
            this.mParams.isInt = true;
        }
    }
    //__________________________________________________________________________________________
    public updateValue() {
        let aValue = isNaN(this.value) ? 0 : this.value;
        aValue = OP3DMathUtils.clampValue(aValue, this.mParams.range.min, this.mParams.range.max);
        if (null != this.mParams.mod) {
            aValue = (aValue % this.mParams.mod);
        }
    }
    //__________________________________________________________________________________________
    private _onSetValue(pCheckTriggers: boolean = false) {
        if (true == isNaN(parseFloat(this.mInput.value))) {
            this.mInput.value = this.mInput.prevValue;
            if (true == isNaN(parseFloat(this.mInput.value))) {
                /**
                 * it measns that the previous value is also nan
                 */
                this.mInput.value = this.mParams.range.min.toString();
            }
            if (this.mCreationData.isScientific == true) {
                this.mInput.value = OP3DMathUtils.getScientificValue(parseFloat(this.mInput.value));
            }
            return;
        }

        let aValue = isNaN(this.value) ? 0 : this.value;
        aValue = OP3DMathUtils.clampValue(aValue, this.mParams.range.min, this.mParams.range.max);
        if (null != this.mParams.mod) {
            aValue = (aValue % this.mParams.mod);
        }

        let aToFixed: number = this.getToFixed();

        if (aToFixed !== undefined) {
            this.mInput.value = OP3DMathUtils.toFixed(aValue, aToFixed);
        } else {
            this.mInput.value = aValue.toString();
        }

        if (this.mInput.prevValue == this.mInput.value) {
            return;
        }

        this.value = aValue;

        if (null != this.mParams.callback) {
            if ((true == this.mCreationData.triggers.saveHistory) && (true == pCheckTriggers)) {
                Op3dContext.SCENE_HISTORY.addToHistory();
            }

            this.mParams.callback(aValue, pCheckTriggers);

            if (true == pCheckTriggers) {
                if (true == this.mCreationData.triggers.saveScene) {
                    Op3dContext.SCENE_HISTORY.saveScene();
                }

                if (true == this.mCreationData.triggers.triggerAnalysis) {
                    AnalysisPortal.instance.enableRunAnalysis(
                        eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                }
            }
        }

    }
    //__________________________________________________________________________________________
    private getToFixed() {
        let aToFixed: number;
        if (this.mCreationData.isGlobalToFixed === true) {
            try {
                aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
            } catch (e) {
                aToFixed = SettingsContext.DEFAULT_USER_VO.parameters.simulation.numericAccuracy;
            }
        } else if (false != this.mCreationData.toFixed) {
            aToFixed = this.mCreationData.toFixed;
        }

        return aToFixed;
    }
    //__________________________________________________________________________________________
    public set value(pVal: number) {
        if (true == isNaN(pVal)) {
            this.mInput.value = this.mInput.prevValue;
            return;
        }

        if (null != this.mParams.mod) {
            pVal = (pVal % this.mParams.mod);
        }

        if (true == this.mParams.isInt) {
            pVal = parseInt(pVal.toString());
        }

        if (this.mCreationData.isScientific == true) {
            this.mInput.value = OP3DMathUtils.getScientificValue(pVal);
        } else {
            let aToFixed: number = this.getToFixed();
            if (aToFixed !== undefined) {
                this.mInput.value = OP3DMathUtils.toFixed(pVal, aToFixed);
            } else {
                this.mInput.value = pVal.toString();
            }

            this.mInput.prevValue = this.mInput.value;
        }
        this.mActualValue = pVal;
    }
    //__________________________________________________________________________________________
    public get value(): number {
        let aValue = this.mInput.value;
        let aRet = parseFloat(aValue);
        return aRet;
    }
    //__________________________________________________________________________________________
    public set convertedValue(pValue: number) {
        this.value = (pValue / this.mParams.factor);
    }
    //__________________________________________________________________________________________
    public get convertedValue() {
        let aVal = this.mActualValue;

        return (aVal * this.mParams.factor);
    }
    //__________________________________________________________________________________________
    public set startValue(pValue: number) {
        let aToFixed: number = this.getToFixed();
        if (aToFixed !== undefined) {
            this.mInput.startValue = OP3DMathUtils.toFixed(pValue, aToFixed);
        } else {
            this.mInput.startValue = pValue.toString();
        }
    }
    //__________________________________________________________________________________________
    public get startValue() {
        return parseFloat(this.mInput.startValue);
    }
    //__________________________________________________________________________________________
    public get actualValue() {
        return this.mActualValue;
    }
    //__________________________________________________________________________________________
    private _onKeyUp(e: Event) {
        if ((e as any).key === 'ArrowUp' || (e as any).key === 'ArrowDown') {
            if (true == this.mCreationData.triggers.saveScene) {
                Op3dContext.SCENE_HISTORY.saveScene();
            }

            if (true == this.mCreationData.triggers.triggerAnalysis) {
                AnalysisPortal.instance.enableRunAnalysis(
                    eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
            }
        }

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    }
    //__________________________________________________________________________________________
    private async _onArrowDown(e: Event) {
        if ((e as any).key === 'ArrowUp') {
            this._onValueChange(1, true);
        }
        if ((e as any).key === 'ArrowDown') {
            this._onValueChange(-1, true);
        }
    }
    //__________________________________________________________________________________________
    private _init(pContainer: HTMLElement, pData: iNumberInputData) {
        this.mContainer = pContainer;
        this._initCreationData(pData);

        this.mParams.range = { min: -Number.MAX_VALUE, max: Number.MAX_VALUE };
        this.mParams.step = 1;
        this.mParams.factor = 1;

        this.mInput = pContainer.getElementsByTagName('input')[0] as iOP3DHTMLInputElement;
        this.mInput.addEventListener("change", () => this._onSetValue(true));
        this.mInput.addEventListener("keyup", (e) => this._onKeyUp(e));
        this.mInput.addEventListener("keydown", (e) => this._onArrowDown(e));

        this.mInput.value = '0';

        if (null != this.mCreationData.unitName) {
            let aTitle = pContainer.getElementsByClassName("input_pi_append")[0];
            if (null != aTitle) {
                aTitle.innerHTML = this.mCreationData.unitName;
            }
        }

        if (null != this.mCreationData.sign) {
            let aTextDiv = this.mContainer.getElementsByClassName('text_div')[0];
            let aAttrVal = aTextDiv.getAttribute('field_name');
            aAttrVal += this.mCreationData.sign;
        }

        let aArrowsParent = this.mContainer.getElementsByClassName('field_arrows')[0];
        if (null != aArrowsParent) {
            let aArrowUp = aArrowsParent.children[0] as HTMLElement;
            aArrowUp.addEventListener('mousedown', () => {
                if (true == this.mCreationData.triggers.saveHistory) {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                }

                let aInterval = setInterval(() => this._onValueChange(1, false), 200);

                let aMouseUpFunc = () => {
                    clearInterval(aInterval);
                    if (true == this.mCreationData.triggers.saveScene) {
                        Op3dContext.SCENE_HISTORY.saveScene();
                    }

                    if (true == this.mCreationData.triggers.triggerAnalysis) {
                        AnalysisPortal.instance.enableRunAnalysis(
                            eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                    }
                    window.removeEventListener('mouseup', aMouseUpFunc);
                }
                window.addEventListener('mouseup', aMouseUpFunc);
                this._onValueChange(1, false);
            });
            let aArrowDown = aArrowsParent.children[1] as HTMLElement;
            aArrowDown.addEventListener('mousedown', () => {
                if (true == this.mCreationData.triggers.saveHistory) {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                }

                let aInterval = setInterval(() => this._onValueChange(-1, false), 200);

                let aMouseUpFunc = () => {
                    clearInterval(aInterval);
                    if (true == this.mCreationData.triggers.saveScene) {
                        Op3dContext.SCENE_HISTORY.saveScene();
                    }

                    if (true == this.mCreationData.triggers.triggerAnalysis) {
                        AnalysisPortal.instance.enableRunAnalysis(
                            eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                    }
                    window.removeEventListener('mouseup', aMouseUpFunc);
                }
                window.addEventListener('mouseup', aMouseUpFunc);
                this._onValueChange(-1, false);
            });
        }
    }
    //__________________________________________________________________________________________
    private _onValueChange(pSign: number, pKeyboard: boolean) {
        let aNewVal: number;

        let aToFixed: number = this.getToFixed();
        if (aToFixed !== undefined) {
            let aValue = parseFloat(OP3DMathUtils.toFixed(parseFloat(this.mInput.value),
                aToFixed));

            let aStep = parseFloat(OP3DMathUtils.toFixed(this.mParams.step,
                this.mCreationData.toFixed));

            aNewVal = (1 == pSign) ? OP3DMathUtils.nextDividedByN(aValue, aStep) :
                OP3DMathUtils.prevDividedByN(aValue, aStep);
            if (null != this.mParams.mod) {
                aNewVal = (aNewVal % this.mParams.mod);
            }
        } else {
            let aValue = parseFloat(this.mInput.value);
            let aLOG10 = Math.log10(aValue);
            let aExp = (Math.sign(aLOG10) * Math.ceil(Math.abs(aLOG10)));
            if ((aExp < 0) && (pSign < 0)) {
                aExp -= 1;
            }
            let aAdd = Math.min(parseFloat(Math.pow(10, aExp).toFixed(Math.abs(aExp))), 1);
            aNewVal = parseFloat((aValue + pSign * aAdd).toFixed(Math.abs(aExp)));
        }

        this.mInput.value = this.mCreationData.isScientific == true ?
            OP3DMathUtils.getScientificValue(aNewVal) :
            aNewVal.toString();
        this._onSetValue();
        !pKeyboard && this.mInput.blur();
    }
    //__________________________________________________________________________________________

    private _initCreationData(pData: iNumberInputData) {
        this.mCreationData = {
            isGlobalToFixed: pData.isGlobalToFixed,
            isScientific: (null != pData.isScientific) ? pData.isScientific : false,
            hasArrows: (null != pData.hasArrows) ? pData.hasArrows : true,
            sign: pData.sign,
            unitName: pData.unitName,
            toFixed: (null != pData.toFixed) ? pData.toFixed : 2,
            worldToUnit: (null != pData.worldToUnit) ? pData.worldToUnit : 1,
            triggers: (null != pData.triggers) ? pData.triggers : {}
        };
    }
    //__________________________________________________________________________________________
    public set show(pToShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, pToShow);
    }
    //__________________________________________________________________________________________
    public set enable(pVal: boolean) {
        ViewUtils.setElementDisabled(this.mContainer, !pVal);
    }
    //__________________________________________________________________________________________
    public get range() {
        return this.mParams.range;
    }
    //__________________________________________________________________________________________
}
