import { eStateToAnalysis } from "../../_context/Enums";
import { SimulationContext } from "../../simulation/SimulationContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { AnalysisPortal } from "../analysis/AnalysisPortal";

export class TesselationProps extends Op3dComponentBase {
    private static SKIN_PATH: string = './skins/home/tesselation_props.html';

    constructor() {
        super({
            container: document.getElementById("forms").appendChild(document.createElement("div")),
            skinPath: TesselationProps.SKIN_PATH,
            draggableParams: {
                snap: false,
                containment: 'window',
                handle: '.tesselation-props'
            }
        });
    }
    //________________________________________________________________
    public async close(pData?: any): Promise<void> {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, false);
    }
    //________________________________________________________________
    public async open(pData?: any): Promise<void> {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, true);
    }
    //________________________________________________________________
    protected _onCreationComplete(): void {

        let aSectors = this._getPart("tes-sectors") as HTMLInputElement;
        aSectors.addEventListener("change", () => {
            SimulationContext.SIMULATION_CONSTANTS.SURFACES_SECTORS = parseInt(aSectors.value);
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
        });
        aSectors.value = SimulationContext.SIMULATION_CONSTANTS.SURFACES_SECTORS.toString();

        let aSlices = this._getPart("tes-slices") as HTMLInputElement;
        aSlices.addEventListener("change", () => {
            SimulationContext.SIMULATION_CONSTANTS.SURFACES_SLICES = parseInt(aSlices.value);
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
        });
        aSlices.value = SimulationContext.SIMULATION_CONSTANTS.SURFACES_SLICES.toString();

        this.mIsReady = true;
    }
    //________________________________________________________________

}