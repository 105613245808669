
export class SurfaceContext {

    //General:
    public static FRAME: string = 'frame';
    public static FRONT: string = 'front';
    public static BACK: string = 'back';
    public static BASE: string = 'base';
    public static RIGHT: string = 'right';
    public static LEFT: string = 'left';
    public static TOP: string = 'top';
    public static BOTTOM: string = 'bottom';
    public static REAR_RIGHT: string = 'rear_right';
    public static REAR_LEFT: string = 'rear_left';
    public static FRONT_LEFT: string = 'front_left';
    public static FRONT_RIGHT: string = 'front_right';
    public static HYPOTENUSE: string = 'hypotenuse';
    public static HYPOTENUSE_1: string = 'hypotenuse_1';
    public static HYPOTENUSE_2: string = 'hypotenuse_2';
    public static PERPENDICULAR_1: string = 'perpendicular_1';
    public static PERPENDICULAR_2: string = 'perpendicular_2';
    public static SIDE: string = 'side';
    public static BACK_LEFT_LEG: string = 'back_left_leg';
    public static BACK_LEFT: string = 'back_left';
    public static BACK_RIGHT: string = 'back_right';
    public static FRONT_LEFT_LEG: string = 'front_left_leg';
    public static TOP_LEFT_LEG: string = 'top_left_leg';
    public static BOTTOM_LEFT_LEG: string = 'bottom_left_leg';


    public static PARAXIAL_SURFACE: string = 'paraxial_surface';


    //RoofPrism:
    public static TOP_FRONT_LEFT: string = 'top_front_left';
    public static TOP_FRONT_RIGHT: string = 'top_front_right';
    public static BOTTOM_FRONT_LEFT: string = 'bottom_front_left';
    public static BOTTOM_FRONT_RIGHT: string = 'bottom_front_right';
    public static TOP_BACK: string = 'top_back';
    public static BOTTOM_BACK: string = 'bottom_back';


    //Off Axis Parabolic:
    public static PARABOLIC: string = 'parabolic';


}
