﻿import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Part } from "../../../parts/Part";
import { eChangesType, iColorChange, iFaceDataNEW, iPartChange } from "../../../parts/PartInterfaces";
import { ServerContext } from "../../../server/ServerContext";
import { ColorUtils } from "../../ColorUtils";
import { ViewUtils } from "../../ViewUtils";
import { PartsEventsHandler } from "../../_globals/PartsEventsHandler";
import { NIE } from "../../part_info/_components/NIE";
import { OptomechanicsSolidsForm } from "./OptomechanicsSolidsForm";
import { uoSection } from "./uoSection";

export class esOptomechColorInfo extends uoSection<Array<iFaceDataNEW>> {

    private static SKIN_PATH = './skins/forms/optics/es_optomech_color_info.html'

    private mFaces: Array<iFaceDataNEW>;
    private mColorInput: iOP3DHTMLInputElement;
    private mChanges: Array<iPartChange<iColorChange>> = [];
    private mColorChanged: any;
    private mOpacityInput: NIE;
    private mOpacityChanged: boolean;
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: esOptomechColorInfo.SKIN_PATH,
            title: 'Color Info',
            isNewSkin: false,
            isPremiumSection: true
        });
    }
    //__________________________________________________________________________________________
    protected async _setData(pFace: Array<iFaceDataNEW>) {
        this._clear();
        this.mFaces = pFace;
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        if(Op3dContext.APP_FEATURES_CONFIG.cad_surface_painting.enabled === false){
            ViewUtils.setElementVisibilityByDFlexDNone( this.mContainer, false)
        }
        this.mColorInput = Op3dUtils.getElementIn(this.mContainer,
            'face_color') as iOP3DHTMLInputElement;
        this.mOpacityInput = new NIE(
            this._getPart("optomech_color_opacity_inp"),
            {
                isGlobalToFixed: true,
                label: {
                    text: "Opacity",
                    bold: false,
                    justify: "start"
                },
                decimalPrecision: 0,
                unit: '%',
                initialValue: 100,
                presentedScale: 1,
                range: {min: 0, max: 100},
                class: ['wl_dist_data_inputs'],
                onChange: () => {
                    this.mOpacityChanged = true
                    EventManager.dispatchEvent(EventsContext.OPTOMECH_COLOR_CHANGE, OptomechanicsSolidsForm.instance)
                }
            });
        this.mColorInput.addEventListener('change', () => {
            this.mColorChanged = true
            EventManager.dispatchEvent(EventsContext.OPTOMECH_COLOR_CHANGE, OptomechanicsSolidsForm.instance)
        });
        let aLinkToPricing = this._getPart('try-professional-label', true) as HTMLAnchorElement;
        aLinkToPricing.href = ServerContext.pricing_base_link;
    }
    //__________________________________________________________________________________________
    private _makeChanges() {
        this.mChanges = [];
        const aNewColor = this.mColorInput.value;
        const aHexColor = ColorUtils.fromHTMLColorToNumberColor(aNewColor);
        const {r, g, b} = ColorUtils.hexToRGB(aHexColor);
        let aOpacity = 1; // [r, g, b, opacity = 1]
        if(this.mOpacityChanged === true) {
            aOpacity = this.mOpacityInput.actualValue / 100;
        }
        for(let i = 0; i < this.mFaces.length; i++) {
            this.mChanges.push({
                type: eChangesType.COLOR,
                data: {
                    path: this.mFaces[i].path,
                    color: [r / 255, g / 255, b / 255, aOpacity],
                    start:  this.mFaces[i].indexes.start,
                    end: this.mFaces[i].indexes.end,
                }
            })
        }
    }
    //__________________________________________________________________________________________
    private _clear(){
        this.mChanges = [];
        this.mColorChanged = false;
        this.mOpacityChanged = false;
        this.mFaces = null;
        this.mColorInput.value = 'black'
        this.mOpacityInput.value = 100;
    }
    //__________________________________________________________________________________________
    public saveColorChanges(pPart: Part, pApplyForAllFaces?: boolean): void {
        if(this.mColorChanged === true || this.mOpacityChanged === true){
            if(pApplyForAllFaces === true){
                this.mFaces = pPart.getFaces()[0].indexes;
            }
            this._makeChanges();
            PartsEventsHandler.addChangesToPart(pPart, this.mChanges);
            Op3dContext.PARTS_MANAGER.updatePartsList(false);
        }
    }
    //__________________________________________________________________________________________
    public applyForFaces(pFaces: Array<iFaceDataNEW>){
        this.mFaces = pFaces;
    }
    //__________________________________________________________________________________________
   }
