import { useContext } from "react";
import { createPortal } from "react-dom";
import OptiChatSingleTab from "./OptiChatSingleTab";
import classes from "../../UI/optichat-tabs.module.scss";
import OptiChatContext from "../../store/OptiChatContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export interface iOptiChatTabData {
  key: string;
  title: string;
  active: boolean;
  requested_params: Array<any>;
}

const OptiChatTabs = () => {
  const { tabs, setTabs } = useContext(OptiChatContext);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const items = reorder(tabs, result.source.index, result.destination.index);

    setTabs(items);
  };

  return createPortal(
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            key="tabs-container-key"
            className={classes["optichat-tabs-container"]}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tabs.map((item, idx) => (
              <Draggable key={item.key} draggableId={item.key} index={idx}>
                {(provided) => (
                  <div
                    className={`${item.active ? classes.active : ""}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <OptiChatSingleTab key={idx} data={item} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>,
    document.getElementById("optichat-tabs")
  );
};

export default OptiChatTabs;
