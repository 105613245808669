import { useState } from "react";
import classes from "../../UI/optichat-info.module.scss";
import OptiChatNavbar from "./OptiChatNavbar";
import { INFO_DATA } from "./OptiChatInfoData";

export default function OptiChatInfoBody() {
  const [content, setContent] = useState(INFO_DATA[0].content);
  const onOptionChanged = (option: string) => {
    let aIdx = INFO_DATA.findIndex((item) => item.title === option);
    setContent(INFO_DATA[aIdx].content);
  };
  return (
    <div className={classes.content}>
      <OptiChatNavbar onChangeOption={onOptionChanged} />
      <div className={classes.divider}></div>
      <div className={classes.body}>{content}</div>
    </div>
  );
}
