import { iHash } from "../../_context/_interfaces/Interfaces";

export enum eNodeType {
    ROOT = "ROOT",
    REGULAR_RAY = "REGULAR_RAY",
    SURFACE = "SURFACE",
    NEXT_SURFACE = "NEXT_SURFACE"
}
export enum eTreeIcons {
    INFO = "./images/tree/info_icon.svg",
    LASER = "./images/tree/laser_icon.svg",
    // QV = "./images/tree/qv.svg",
    QV = "icon-chart-eye",
    CIRCLE = "./images/tree/circle_icon.svg",
    ABSORBING = "./images/tree/empty_circle_icon.svg",
    INFINITE_RAY = "./images/tree/align-end-icon.svg",
    REGULAR_RAY = "./images/tree/regular_ray_icon.svg",
    START = "./images/tree/start_icon.svg",
    SHOW = "./images/tree/eye.svg",
    HIDE = "./images/tree/eye-slash.svg",
    SCREEN = "./images/tree/screen_icon.svg",
    GROUP = "./images/tree/group_icon.svg",
    BLACK_BOX = "./images/tree/black_box.svg",
    GEAR = "./images/tree/gear.svg",
    APERTURE = "./images/icons_new/outliner/aperture.svg",
    MAGNET = "./images/tree/snap_to_chief_ray.svg",


    MAGNIFY = "./images/tree/magnify.svg",
    OPTICS = "./images/tree/dome.svg",
    AXIS = "./images/tree/xyz.svg",
    SOLID = "./images/tree/part.svg",
    ANALYSIS = "./images/tree/part_full.svg",
    PART = "./images/tree/cube.svg",

    MOVE = "./images/tree/move_part.svg",

    DUPLICATE = "./images/tree/content_copy.svg",

    PARTS = "./images/tree/parts.svg",
    CATALOG = "./images/tree/list.svg",
    ALL = "./images/tree/eye-slash.svg"
}

export interface iSingleTreeItemData {
    original_surface_id?: string;
    node_type: eNodeType,
    end_surface_id?: string,
    start_surface_id?: string
}

export interface iSingleTreeItem<T = iSingleTreeItemData> {
    data?: T,
    icon: eTreeIcons,
    text: string,
    id?: string,
    state?: { opened: boolean },
    a_attr?: iHash<string>;
    li_attr?: iHash<string>;
    children?: Array<iSingleTreeItem<T>>,
}