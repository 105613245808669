import { Chart } from "chart.js/auto";
import { eErrorType } from "../_context/Enums";
import { MessagesHandler } from "../_context/MessagesHandler";
import { iHash, iNumericKeyHash, iMinMax } from "../_context/_interfaces/Interfaces";
import { ColorUtils } from "../ui/ColorUtils";
import { iGeneralGraphFormParams } from "../ui/charts/WavelenghtEditForm";

export class DataUtils {
    //__________________________________________________________________________________________
    public static filterArray<T = number | string>(pArray1: Array<T>, pArray2: Array<T>) {
        // create a new array to store the filtered elements
        const filteredArr = [];

        // loop through the second array
        for (let i = 0; i < pArray2.length; i++) {
            // check if the element exists in the first array
            if (pArray1.includes(pArray2[i])) {
                // if it does, add it to the filtered array
                filteredArr.push(pArray2[i]);
            }
        }

        // return the filtered array
        return filteredArr;
    }
    //__________________________________________________________________________________________
    public static fillElementsFromObject(pElements: Array<HTMLElement>, pData: any) {
        pElements.forEach((element) => {
            let aPath = element.getAttribute('path');
            let aData = DataUtils.getDataFromPath(pData, aPath);
            if (element instanceof HTMLInputElement) {
                switch (element.getAttribute('type')) {
                    case 'checkbox':
                        DataUtils._fillCheckbox(element, aData);
                        break;
                    case 'radio':
                        DataUtils._fillRadio(element, aData);
                        break;
                    case 'text':
                    case 'tel':
                    case 'number':
                        DataUtils._fillTextInput(element, aData);
                        break;
                    case 'color':
                        DataUtils._fillColor(element, aData);
                        break;
                }
            } else if (element instanceof HTMLSelectElement) {
                DataUtils._fillSelect(element, aData);
            } else {
                DataUtils._fillText(element, aData);
            }
        });
    }
    //__________________________________________________________________________________________
    /**
     * @description Creates a new object, according to pDefault structure. Copies all of the 
     * non-null properties from pFrom, that exist in pDefault. Properties in pDefault that not 
     * existing in pFrom - getting their value from pDefault.
     * @param pFrom Object to copy from.
     * @param pDefault Object to determine the structure and the default values.
     * @returns New object according to the description.
     */
    //__________________________________________________________________________________________
    public static fillObjectAccordingTo(pFrom: any, pDefault?: any) {
        let aTo = DataUtils.getObjectCopy(pDefault);
        //DataUtils.fillObjectFromObject(aTo, pFrom, pDefault);
        DataUtils.fillExistingKeysFromObject(aTo, pFrom);

        return aTo;
    }
    //__________________________________________________________________________________________
    public static base64ToArrayBuffer(base64: string) {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    //__________________________________________________________________________________________
    public static findInObject<T>(pObj: iHash<T>, pFunc: (val: T) => boolean) {
        return Object.values(pObj).find(pFunc);
    }
    //__________________________________________________________________________________________
    public static findKeyInObject<T>(pObj: iHash<T>, pFunc: (val: T) => boolean) {
        let aKey: string;
        let aIndex = Object.values(pObj).findIndex(pFunc);
        if (0 <= aIndex) {
            aKey = Object.keys(pObj)[aIndex];
        }

        return aKey;
    }
    //__________________________________________________________________________________________
    public static hasNullProperty(pObject: any) {
        if (null == pObject) {
            return true;
        }

        for (let property in pObject) {
            if (((pObject[property] instanceof Object) &&
                (true == DataUtils.hasNullProperty(pObject[property])) ||
                (null == pObject[property]))) {
                return true;
            }
        }

        return false;
    }
    //__________________________________________________________________________________________
    public static fillObjectFromObject(pTo: any, pFrom: any, pDefault?: any) {
        for (let parameter in pDefault) {

            if (pDefault[parameter] instanceof Object) {
                if (null == pTo[parameter]) {
                    if (pDefault[parameter] instanceof Array) {
                        pTo[parameter] = [];
                    } else {
                        pTo[parameter] = {};
                    }
                }

                let aFrom = (null != pFrom) ? pFrom[parameter] : null;
                DataUtils.fillObjectFromObject(pTo[parameter], aFrom, pDefault[parameter]);

            } else {
                pTo[parameter] = ((null != pFrom) && (null != pFrom[parameter])) ?
                    pFrom[parameter] : pDefault[parameter];
            }
        }
    }
    //__________________________________________________________________________________________
    public static fillExistingKeysFromObject(pTo: any, pFrom: any, _pDefault?: any) {
        for (let parameter in pFrom) {
            if (pFrom[parameter] instanceof Array) {
                pTo[parameter] = new Array().concat(...pFrom[parameter])
            } else if (pFrom[parameter] instanceof Object) {
                if (null == pTo[parameter]) {
                    pTo[parameter] = {};
                }
                this.fillExistingKeysFromObject(pTo[parameter], pFrom[parameter])
            } else {
                // Liliya addition
                if (null !== pFrom[parameter]) {
                    pTo[parameter] = pFrom[parameter];
                }
                //----------------
                //pTo[parameter] = pFrom[parameter];
            }
        }
    }
    //__________________________________________________________________________________________
    private static _filterEmptyObjects(obj: Object) {
        const result = {};
        for (let key in obj) {
            if (obj[key] !== null && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
                continue; // Skip empty objects
            }
            result[key] = obj[key];
        }
        return result;
    }
    //__________________________________________________________________________________________
    /**
     * @description compare two objects , whether they are equalor not
     * ignores empty objects and not consider them as entry
     */
    public static isObjectsEqual(pObj1: any, pObj2: any) {
        // Check if both parameters are objects
        if (typeof pObj1 === 'object' && typeof pObj2 === 'object') {
            // Filter out empty objects
            const aFilteredObj1 = this._filterEmptyObjects(pObj1);
            const aFilteredObj2 = this._filterEmptyObjects(pObj2);

            // Get the keys of both filtered objects
            const aKeys1 = Object.keys(aFilteredObj1);
            const aKeys2 = Object.keys(aFilteredObj2);

            // Check if the number of keys is the same
            if (aKeys1.length !== aKeys2.length) {
                return false;
            }

            // Iterate over each key
            for (let key of aKeys1) {
                // Recursive call to deepCompare for nested objects
                if (!this.isObjectsEqual(aFilteredObj1[key], aFilteredObj2[key])) {
                    return false;
                }
            }

            // If all properties are deeply equal, return true
            return true;
        }

        // If both parameters are not objects, perform a simple comparison
        return pObj1 === pObj2;
    }
    //__________________________________________________________________________________________
    public static fillObjectAccordingToList(pTo: any, pFrom: any, pPathList: Array<string>) {
        if (null == pFrom) {
            return;
        }

        for (let i = 0; i < pPathList.length; i++) {
            let aPath = pPathList[i].split('.');
            let aValue = DataUtils._getOnePropertyValueFromObject(pFrom, aPath);
            if ((null != aValue) && ('' != aValue) && (false == (aValue instanceof Object))) {
                DataUtils._fillData(pTo, aPath, aValue);
            }
        }
    }
    //__________________________________________________________________________________________
    private static _getOnePropertyValueFromObject(pObj: any, pPath: Array<string>) {
        let aObj = Object.assign({}, pObj);
        for (let i = 0; i < pPath.length; i++) {
            if (null == aObj) {
                return null;
            }

            aObj = aObj[pPath[i]];
        }

        return aObj;
    }
    //__________________________________________________________________________________________

    public static getDataFromPath(pData: any, pPath: string): any {
        let aPath = pPath.split('.');
        let aData = pData;

        for (let i = 0; ((i < aPath.length) && (null != aData)); i++) {
            let aKey = aPath[i];
            aData = aData[aKey];
        }

        return aData;
    }
    //__________________________________________________________________________________________
    public static removeKeys<T>(pHash: iHash<T>, pExcludeKeys: Array<string>): iHash<T> {
        let aCleanHash: iHash<T> = {};
        for (let key in pHash) {
            if (pExcludeKeys.indexOf(key) === -1) {
                aCleanHash[key] = pHash[key];
            }
        }

        return aCleanHash;
    }
    //__________________________________________________________________________________________
    public static hashToArrOfOptions<T>(pHash: iNumericKeyHash<T>, pExcludeKeys: Array<string> = []) {
        let aArray = new Array<any>();

        for (let key in pHash) {
            if (pExcludeKeys.indexOf(key) === -1) {
                let aObj = {};
                aObj[key] = pHash[key];
                aArray.push(aObj)
            }

        }

        return aArray;
    }
    //__________________________________________________________________________________________
    public static arrToHash(pArr: Array<any>) {
        let aHash = {};
        for (let i = 0; i < pArr.length; i++) {
            const aKey = Object.keys(pArr[i])[0]
            const aVal = Object.values(pArr[i])[0];
            aHash[aKey] = aVal;
        }
        return aHash;
    }
    //__________________________________________________________________________________________
    public static hashToArr<T>(pHash: iNumericKeyHash<T>) {
        let aArray = new Array<T>();

        for (let i in pHash) {
            aArray[parseInt(i)] = pHash[i];
        }

        return aArray;
    }
    //__________________________________________________________________________________________
    private static _fillCheckbox(pCheckbox: HTMLInputElement, pIsChecked: boolean) {
        pCheckbox.checked = (null != pIsChecked) ? pIsChecked :
            (null != pCheckbox.getAttribute('d_checked'));
    }
    //__________________________________________________________________________________________
    private static _fillRadio(pRadio: HTMLInputElement, pValue: string) {
        if (null == pValue) {
            pRadio.checked = (null != pRadio.getAttribute('d_checked'));
            return;
        }

        pRadio.checked = (pValue == pRadio.value);
    }
    //__________________________________________________________________________________________
    protected static _fillText(pElement: HTMLElement, pValue: string) {
        pElement.innerText = pValue;
    }
    //__________________________________________________________________________________________
    protected static _fillTextInput(pInput: HTMLInputElement, pValue: string) {
        pInput.value = (null != pValue) ? pValue : pInput.getAttribute('def_val');
        pInput.dispatchEvent(new Event('change'));
    }
    //__________________________________________________________________________________________
    private static _fillSelect(pSelectElement: HTMLSelectElement, pValue: string) {
        let aValue = pValue;
        if (null == aValue) {
            aValue = $(pSelectElement).find('option[def_opt]').first().attr('value');
        }

        pSelectElement.value = aValue;
        pSelectElement.dispatchEvent(new Event('change'));
    }
    //__________________________________________________________________________________________
    public static sumMatrix(pMatrix: Array<Array<number>>): number {
        let aSum = 0;
        for (let i = 0; i < pMatrix.length; i++) {
            for (let j = 0; j < pMatrix[i].length; j++) {
                aSum += pMatrix[i][j];
            }
        }

        return aSum;
    }
    //__________________________________________________________________________________________
    private static _fillColor(pInput: HTMLInputElement, pValue: number) {
        let aValue = (null != pValue) ? pValue : parseInt(pInput.getAttribute('def_val'));
        pInput.value = ColorUtils.numToHEXColor(aValue);
    }
    //__________________________________________________________________________________________
    public static getItemIndex(pObject: Object, pAttribute: string, pValue: any) {
        if (null == pValue) {
            return -1;
        }

        let aIndex = pObject[pAttribute].findIndex((attr) => {
            return (DataUtils.isEqual(attr, pValue));
        });

        if (-1 == aIndex) {
            aIndex = pObject[pAttribute].length;
            pObject[pAttribute].push(pValue);
        }

        return aIndex;
    }
    //__________________________________________________________________________________________
    /**
     * @returns Object, ordering according to path attribiute in pParent.
     */
    public static fillObject(pParent: HTMLElement): any {
        try {
            let aObj: iHash<any> = {};

            $(pParent).find('[path]').each((_index, element) => {
                let aPath = element.getAttribute('path').split('.');
                let aValue;
                if (element instanceof HTMLInputElement) {
                    switch (element.getAttribute('type')) {
                        case 'checkbox':
                            aValue = DataUtils._getCheckboxValue(element);
                            break;
                        case 'radio':
                            aValue = DataUtils._getRadioValue(element);
                            break;
                        case 'text':
                            aValue = DataUtils._getTextInputValue(element);
                            break;
                        case 'tel':
                        case 'number':
                            aValue = DataUtils._getNumericInputValue(element);
                            break;
                        case 'color':
                            aValue = DataUtils._getColorValue(element);
                            break;
                        default:
                            throw new Error('Wrong input type');

                    }
                } else if (element instanceof HTMLSelectElement) {
                    let aValueType = element.getAttribute('value_type');

                    aValue = ('numeric' == aValueType) ?
                        parseFloat(element.value) : element.value;
                }

                DataUtils._fillData(aObj, aPath, aValue);

            });

            return aObj;

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({
                error: e,
                type: eErrorType.GENERAL,
                show_message: true,
            });
        }
    }
    //__________________________________________________________________________________________
    public static getSize(pMinMax: iMinMax) {
        let aRange = pMinMax.max - pMinMax.min;
        return aRange;
    }
    //__________________________________________________________________________________________
    private static _getRadioValue(pInput: HTMLInputElement) {
        if (false == pInput.checked) {
            return null;
        }

        let aValueType = pInput.getAttribute('value_type');
        return ('numeric' == aValueType) ? parseFloat(pInput.value) : pInput.value;
    }
    //__________________________________________________________________________________________
    private static _getColorValue(pInput: HTMLInputElement) {
        return parseInt(pInput.value.slice(1), 16);
    }
    //__________________________________________________________________________________________
    protected static _getTextInputValue(pInput: HTMLInputElement) {
        if (null != pInput.getAttribute('numeric')) {
            return this._getNumericInputValue(pInput);
        }

        return pInput.value;
    }
    //__________________________________________________________________________________________
    protected static _getNumericInputValue(pInput: HTMLInputElement) {
        return parseFloat(pInput.value);
    }
    //__________________________________________________________________________________________
    private static _getCheckboxValue(pCheckbox: HTMLInputElement) {
        return pCheckbox.checked;
    }
    //__________________________________________________________________________________________
    private static _fillData(pObj: iHash<any>, pPath: Array<string>, pValue: any) {
        if ((null == pPath) || (0 == pPath.length) || (null == pValue)) {
            return;
        }

        let aKey = pPath.shift();
        if (0 == pPath.length) {
            pObj[aKey] = pValue;
            return;
        }

        if (pObj[aKey] == null) {
            pObj[aKey] = {};
        }

        this._fillData(pObj[aKey], pPath, pValue);
    }
    //__________________________________________________________________________________________
    /**
     * this function finds the previous closest non empty index to gives
     * @param pCurrIndex the index that we want to find closest value to
     * @param pArr 
     */
    //__________________________________________________________________________________________
    public static getClosestNonEmptyVal(pCurrIndex: number, pArr: Array<number>) {
        let aValue: number;

        for (let i = pCurrIndex; i < pArr.length; i++) {
            if (pArr[i] != null) {
                aValue = pArr[i];
            }
        }

        if (aValue == null) {
            aValue = pArr[pCurrIndex];
        }
        return aValue;
    }
    //__________________________________________________________________________________________
    public static getClosestIndex(pVal: number, pArr: Array<number>) {
        let curr = pArr[0];
        let diff = Math.abs(pVal - curr);
        let index = 0;
        for (let val = 0; val < pArr.length; val++) {
            let newdiff = Math.abs(pVal - pArr[val]);
            if (newdiff < diff) {
                diff = newdiff;
                curr = pArr[val];
                index = val;
            };
        };
        return index;
    }
    //__________________________________________________________________________________________
    public static getClosestVal(pVal: number, pArr: Array<number>) {
        const closest = pArr.reduce((prev, curr) => {
            return (Math.abs(curr - pVal) < Math.abs(prev - pVal) ? curr : prev);
        });
        return closest;
    }
    //__________________________________________________________________________________________
    public static isEqual(pObj1: any, pObj2: any) {
        return (JSON.stringify(pObj1) == JSON.stringify(pObj2));
    }
    //__________________________________________________________________________________________
    public static isArrayEqual(pArr1: Array<any>, pArr2: Array<any>) {
        if (pArr1 == pArr2) {
            return true;
        }

        if (pArr1.length != pArr2.length) {
            return false;
        }

        for (let i = 0; i < pArr1.length; i++) {
            if (pArr1[i] != pArr2[i]) {
                return false;
            }
        }

        return true;
    }
    //__________________________________________________________________________________________
    public static getObjectCopy<T>(pObject: T): T | null {
        if (null == pObject) {
            return null;
        }
        let aRes: T;
        try {
            aRes = structuredClone(pObject);
        } catch (e) {
            aRes = DataUtils.getObjectCopyOld(pObject);
        }
        return aRes;
    }
    //__________________________________________________________________________________________
    public static getObjectCopyOld<T>(pObject: T): T | null {
        if (null == pObject) {
            return null;
        }
        const aRes = JSON.parse(JSON.stringify(pObject)) as T;
        return aRes;
    }
    //__________________________________________________________________________________________
    public static getChartJSInstance(pContainer: HTMLElement, pData: iGeneralGraphFormParams) {
        pContainer.innerHTML = '';
        pContainer.style.width = (pData.width + 32) + 'px';
        pContainer.style.height = (pData.height + 32) + 'px';

        let aGraphCanvas = document.createElement('canvas') as HTMLCanvasElement;
        pContainer.appendChild(aGraphCanvas);
        aGraphCanvas.width = pData.width;
        aGraphCanvas.height = pData.height;

        aGraphCanvas.style.width = (pData.width + 'px');
        aGraphCanvas.style.height = (pData.height + 'px');
        var ctx = aGraphCanvas.getContext("2d");

        let aChart = new Chart(ctx, pData.config);
        return aChart;
    }
    //__________________________________________________________________________________________

}
