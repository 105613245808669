import { useState } from 'react';
import { BlackBoxBtn } from '../components/BlackBoxBtn';

export function BlackBoxWrapper() {
    const [counter, setCounter] = useState(1);

    return (
        <BlackBoxBtn counter={ counter } setCounter={ setCounter } />
    );
}
