
import { Op3dContext } from "../../_context/Op3dContext";
import { iMinMax } from "../../_context/_interfaces/Interfaces";
import { Part } from "../Part";
import { iPostBehaviorJSONObject } from "../_parts_assets/ExportToJSONInterfaces";
import { Behavior } from "./Behavior";
import { MovementBehavior } from "./MovementBehavior";
import { iPostData } from "../../data/VO/PartVO";
import { GridManager } from "../../scene/GridManager";
import { SceneContext } from "../../scene/SceneContext";
import { Vector3 } from "three";

export class PostBehavior extends Behavior<iPostData> {

    public static POST_SETPS = {
        thorlabs:
            [[0.5, 0.75, 1, 1.5, 2, 3, 4, 6],
            [12.5, 19, 25, 38, 50, 75, 100, 150, 300]
            ],

    }

    public static POST_HOLDER_CONTAINER: string = 'post_holder_container';
    public static POST_CONTAINER: string = 'post_container';
    public static BASIC_MAX_ROD_SIZE: number = 127;


    public static POST_HOLDER_NAME: string = 'PH30E_M';
    public static POST_NAME: string = 'post';

    public static BASE_HEIGHT: number = 34.7;

    //__________________________________________________________________________________________
    public static getActualPostLength(pPart: Part) {
        let aPostPart = pPart.getSubpartByName(PostBehavior.POST_NAME);
        try {
            return aPostPart.facesMesh.scale.y;
        } catch (e) {
            return -1;
        }
    }
    //__________________________________________________________________________________________
    public static getCurrentHeight(pPart: Part) {
        let aPartPosY = pPart.iPart.subParts[0].object3D.position.y;
        let aBasePart = pPart.getSubpartByName(PostBehavior.POST_HOLDER_NAME);
        if (aBasePart == undefined) return 0
        let aBasePartPosY = aBasePart.object3D.position.y;

        let aCurrHeight = (aPartPosY - aBasePartPosY);
        return aCurrHeight;
    }
    //__________________________________________________________________________________________
    public static exportToJSON(pPart: Part, pJSONData: iPostBehaviorJSONObject): void {
        pJSONData.postBehavior = {
            height: PostBehavior.getCurrentHeight(pPart),
            isPostVisible: pPart.getSubpartByName(PostBehavior.POST_NAME)?.object3D.visible
        };
    }
    //__________________________________________________________________________________________
    public static async initFromJSON(pPart: Part, pJSONData: iPostBehaviorJSONObject) {

        PostBehavior.updatePostHeight(pPart, pJSONData.postBehavior.height);
        PostBehavior.displayPost(pPart, pJSONData.postBehavior.isPostVisible);
    }
    //__________________________________________________________________________________________
    public static isPostShown(pPart: Part) {
        let aPostPart = pPart.getSubpartByName(PostBehavior.POST_NAME);
        let aBasePart = pPart.getSubpartByName(PostBehavior.POST_HOLDER_NAME);
        return aBasePart.object3D.visible == true && aPostPart.object3D.visible == true;

    }
    //__________________________________________________________________________________________
    public static displayPost(pPart: Part, pToShow: boolean): void {
        let aPostPart = pPart.getSubpartByName(PostBehavior.POST_NAME);
        let aBasePart = pPart.getSubpartByName(PostBehavior.POST_HOLDER_NAME);
        aBasePart.object3D.visible = pToShow;
        aPostPart.object3D.visible = pToShow;

        Op3dContext.SCENE_HISTORY.saveScene();
        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public static getRange(pPart: Part) {

        let aPostPart = pPart.getSubpartByName(PostBehavior.POST_NAME);
        let aPartPosY = pPart.iPart.subParts[0].object3D.position;

        let aPostOpticsDelta = aPartPosY.y - aPostPart.object3D.position.y

        let aCellSize = GridManager.CELL_SIZE;
        let aBasicMaxRodSide = (PostBehavior.BASIC_MAX_ROD_SIZE + aPostOpticsDelta);
        let aMax = aBasicMaxRodSide + (aCellSize - (aBasicMaxRodSide % aCellSize));
        let aRange: iMinMax = {
            min: (PostBehavior.BASE_HEIGHT + aPostOpticsDelta),
            max: aMax
        };
        return aRange;
    }
    //__________________________________________________________________________________________
    public static updatePostHeight(pPart: Part, pHeight: number): void {
        let aPrevCSPos = new Array<Vector3>();
        if (null != pPart.linkedParts) {
            for (let i = 0; i < pPart.linkedParts.length; i++) {
                let aLinkedPartCS = pPart.linkedParts[i].refCS.cs.object3D;
                aPrevCSPos.push(aLinkedPartCS.getWorldPosition(new Vector3()));
            }
        }

        let aPostPart = pPart.getSubpartByName(PostBehavior.POST_NAME);
        let aCurrHeight = PostBehavior.getCurrentHeight(pPart);
        let aDelta = (pHeight - aCurrHeight);
        if (0 == aDelta) {
            return;
        }


        pPart.iPart.subParts.forEach(part => {
            if (part.name == PostBehavior.POST_NAME || part.name == PostBehavior.POST_HOLDER_NAME) return
            part.object3D.position.y += aDelta;
        })
        pPart.iPart.axes.forEach(axes => {
            axes.object3D.position.y += aDelta;
        })

        aPostPart.object3D.position.y += aDelta;
        aPostPart.facesMesh.scale.y += aDelta;

        //
        //scaling of edges mesh and vertex mesh
        //
        aPostPart.shapes[0].edgesMesh.scale.y += aDelta

        if (null != pPart.linkedParts) {
            for (let i = 0; i < pPart.linkedParts.length; i++) {
                let aLinkedPart = pPart.linkedParts[i];
                let aLinkedPartCS = aLinkedPart.refCS.cs.object3D;
                let aCurrPos = aLinkedPartCS.getWorldPosition(new Vector3());
                let aDeltaPos = aCurrPos.sub(aPrevCSPos[i]);

                MovementBehavior.addToPos(aLinkedPart, aDeltaPos);
            }
        }

        Op3dContext.SCENE_HISTORY.saveScene();
        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public static init(pPart: Part) {
        let aPostPart = pPart.getSubpartByName(PostBehavior.POST_NAME);
        let aPostHolderPart = pPart.getSubpartByName(PostBehavior.POST_HOLDER_NAME);

        if ((null == aPostPart) || (null == aPostHolderPart)) {
            return;
        }

        let aPostPos = aPostPart.object3D.position;
        let aPostScale = (Op3dContext.OPTICAL_AXIS_HEIGHT + aPostPos.y);
        aPostPart.facesMesh.scale.y = aPostScale;

        //
        //scaling of edges and vertex mesh
        //
        aPostPart.shapes[0].edgesMesh.scale.y = aPostScale

        aPostHolderPart.object3D.position.y = -Op3dContext.OPTICAL_AXIS_HEIGHT;

        let aPostHolderAxis = aPostHolderPart.axes[0];
        pPart.setWorkingCS(aPostHolderAxis, false);
        aPostHolderAxis.object3D.scale.set(2, 2, 2);
    }
    //__________________________________________________________________________________________
}
