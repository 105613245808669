﻿import { iNumericKeyHash, t3DArray } from "../../../_context/_interfaces/Interfaces";
import { iSurfaceDeformation, iPolynomialDeformation, eSurfaceDeformation } from "../../../data/VO/OpticsVOInterfaces";
import { UnitHandler } from "../../../units/UnitsHandler";
import { newNumberInputElement } from "../../part_info/_components/NewNumberInputElement";
import { sdSurfaceDeformation } from "./sdSurfaceDeformation";

export class sdPolynomial extends sdSurfaceDeformation<iSurfaceDeformation<iPolynomialDeformation>>{

    private mTermsParent: HTMLElement;
    private mCoeffsElemets = new Array<newNumberInputElement>();
    private mNumOfTerms: newNumberInputElement;
    private mType: eSurfaceDeformation;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pOnChange: () => void) {
        super(pContainer, pOnChange);
        this._initElements();
    }
    //__________________________________________________________________________________________
    public setData(pSurfaceDeformation: iSurfaceDeformation<iPolynomialDeformation>) {
        this.mType = pSurfaceDeformation.type;
        let aTerms = pSurfaceDeformation.params.terms;
        let aLastCoeffIndex = 0;
        let aTermsHash: iNumericKeyHash<number> = {};
        if (null != aTerms) {
            for (let i = 0; i < aTerms.length; i++) {
                let aIndex = aTerms[i][0];
                aTermsHash[aIndex] = aTerms[i][2];
                if (aIndex > aLastCoeffIndex) {
                    aLastCoeffIndex = aIndex;
                }
            }
        }

        let aNumberOfElements = this._getNumberOfTerms(aLastCoeffIndex);
        this.mNumOfTerms.value = aNumberOfElements;
        this._createTerms(aNumberOfElements);

        for (let i = 0; i < aTerms.length; i++) {
            let aCoeffIndex = aTerms[i][0];
            let aTermIndex = this._getIndexByTerm(aCoeffIndex);
            this.mCoeffsElemets[aTermIndex].value = (undefined !== aTermsHash[aCoeffIndex]) ?
                aTermsHash[aCoeffIndex] : 0;
        }
    }
    //__________________________________________________________________________________________
    public getData(): iSurfaceDeformation {
        if (0 == this.mCoeffsElemets.length) {
            return null;
        }

        let aTerms = new Array<t3DArray<number>>();

        for (let i = 0; i < this.mCoeffsElemets.length; i++) {
            let aCoeff = this.mCoeffsElemets[i].value;
            if (aCoeff != 0) {
                let aIndex = this._getTermByIndex(i);
                aTerms.push([aIndex, aIndex, aCoeff]);
            }
        }

        let aDeformation: iSurfaceDeformation<iPolynomialDeformation> = {
            type: this.mType,
            params: {
                terms: aTerms
            }
        };

        return aDeformation;
    }
    //__________________________________________________________________________________________
    protected _initElements() {
        this._initNumOfTerms();

        this.mTermsParent = document.createElement('div');
        this.mTermsParent.classList.add('row');

        let aTermsCot = document.createElement('div');
        aTermsCot.classList.add('col-12');
        aTermsCot.appendChild(this.mTermsParent);
        this.mContainer.appendChild(aTermsCot);
    }
    //__________________________________________________________________________________________
    private _initNumOfTerms() {


        let aDIV = document.createElement('div');
        aDIV.classList.add('col-4');
        this.mContainer.appendChild(aDIV);

        this.mNumOfTerms = new newNumberInputElement(aDIV, {
            label: 'Number of terms',
            isGlobalToFixed: false
        });

        this.mNumOfTerms.setData({
            callback: (pValue) => this._createTerms(pValue),
            range: {
                max: 99,
                min: 0
            },
            step: 1
        });
    }
    //__________________________________________________________________________________________
    private _onChange() {
        this.mOnChange();
        // EventManager.dispatchEvent(EventsContext.OPTICS_DEFORMATION_CHANGE, this);
    }
    //__________________________________________________________________________________________
    private _createTerms(pNumberOfTerms: number) {
        let aCurrTerms = this.mTermsParent.children.length;
        if (aCurrTerms == pNumberOfTerms) {
            return;
        }
        if (aCurrTerms > pNumberOfTerms) {
            while (this.mCoeffsElemets.length > pNumberOfTerms) {
                let aNIE = this.mCoeffsElemets.pop();
                this.mTermsParent.removeChild(this.mTermsParent.children[this.mCoeffsElemets.length]);
                aNIE.distract();
            }
        } else {
            for (let i = aCurrTerms; i < pNumberOfTerms; i++) {
                let aDiv = document.createElement('div');
                aDiv.classList.add('col-4');
                aDiv.style.paddingTop = '8px';
                this.mTermsParent.appendChild(aDiv);

                let aIndex = this._getTermByIndex(i);
                let aUnit: string;
                if (aIndex > 0) {
                    aUnit = `${UnitHandler.shortSign}<sup>-${aIndex}</sup>`
                }
                let aNIE = new newNumberInputElement(aDiv, {
                    isGlobalToFixed: false,
                    unitName: aUnit,
                    field_name: `${aIndex}:`,
                    unitElementWidth: 38,
                    toFixed: false
                });

                aNIE.setData({
                    callback: () => this._onChange(),
                    step: 0.01,
                    range: { min: -Infinity, max: Infinity }
                });
                this.mCoeffsElemets.push(aNIE);
            }
        }
    }
    //__________________________________________________________________________________________
    private _getTermByIndex(pIndex: number) {
        switch (this.mType) {
            case eSurfaceDeformation.ASPHERE:
                return pIndex;
            case eSurfaceDeformation.EVEN_ASPHERE:
                return (2 * pIndex);
            case eSurfaceDeformation.ODD_ASPHERE:
                return ((2 * pIndex) + 1);
            default:
                throw new Error("Not polynomial");
        }
    }
    //__________________________________________________________________________________________
    private _getNumberOfTerms(pMaxTerm: number) {
        if (0 == pMaxTerm) {
            return 0;
        }

        switch (this.mType) {
            case eSurfaceDeformation.ASPHERE:
                return (pMaxTerm + 1);
            case eSurfaceDeformation.EVEN_ASPHERE:
                return (1 + (pMaxTerm / 2));
            case eSurfaceDeformation.ODD_ASPHERE:
                return (1 + ((pMaxTerm - 1) / 2));
            default:
                throw new Error("Not polynomial");
        }
    }
    //__________________________________________________________________________________________
    private _getIndexByTerm(pTerm: number) {
        if (0 == pTerm) {
            return 0;
        }

        switch (this.mType) {
            case eSurfaceDeformation.ASPHERE:
                return pTerm;
            case eSurfaceDeformation.EVEN_ASPHERE:
                return (pTerm / 2);
            case eSurfaceDeformation.ODD_ASPHERE:
                return ((pTerm - 1) / 2);
            default:
                throw new Error("Not polynomial");
        }
    }
    //__________________________________________________________________________________________
}
