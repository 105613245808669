
import { Matrix4, Vector3 } from "three";
import { eDataPermission } from "../../_context/Enums";
import { iPartVOData } from "./PartVO";
import { iPartDetailsVO } from "../../ui/forms/ChangePartDetailsForm";



export enum eObjectType {
    SET_UP,
    PART,
    CABLE
}

export interface iServerPartVO {
    number_id: string;
    info: iPartVOData;
    owner: string;
    permission: eDataPermission;
}

export interface iGeneralVOData {
    /**
   * @description section of part
   */
    main_section: string;
    /**
    * @description section of part
    */
    section: string;

    /**
    * @description categories of part
    */
    category: string;

    /**
    * @description determine if some part has post
    */
    hasPost?: boolean;

    /**
    * @description categories of initialHeight
    */
    initialHeight?: number;

    /**
    * @description subCategories of part
    */
    subCategory: string;

    /**
    * @description ID 
    */
    id: string;

    /**
    * @description name 
    */
    name: string;

    /**
    * @description whether the part is side bar part by default
    * @default true
    */
    sideBarPart: boolean;

    /**
     * @description 
     * whether the [art model is benig made dynamically or not,
     * defalt is FALSE
     */
    isDynamicPart?: boolean;


    parameters?: {
        catalog_number?: string;
    }
    /**
    * @description whether this part is available for admin only
    * @default false
    */
    adminPart?: boolean;

    /**
    * @description url of the model of the part
    */
    url: string;
    original_url?: string;

    /**
    * @description whether the part is custom partd
    * @default false
    */
    isCustom?: boolean;

    /**
     * @description link to pdf of the part
     */
    pdfLink?: string;
    number_id?: string;

    permission?: eDataPermission;
    owner?: string;

    hasCage?: boolean;
    isOptix?: boolean;
    translationMatrix?: Matrix4;
    originalName?: string;
    isAssembly?: boolean;
    isEdited?: boolean;
    move?: Vector3;

    label?: string;
    isBlackBox?: boolean;

}

/**
 648c9bde1333de525fd4bb99
 
 */

export abstract class GeneralVO {

    ///public cellSize = 6;

    private mIsOptPart: boolean = true;
    private mImageUrl: string;
    private mObjectType: eObjectType = eObjectType.PART;
    protected mSize: Vector3;

    protected mGeneralVOData: iGeneralVOData;

    constructor(pType: eObjectType, pData: iGeneralVOData) {
        this.mObjectType = pType;

        if (null == pData) {
            throw new Error("No Data for PartVO");
        }

        this.mGeneralVOData = {
            id: pData.id,
            name: pData.name,
            originalName: pData.originalName,
            number_id: pData.number_id,
            main_section: pData.main_section,
            section: pData.section,
            category: pData.category,
            subCategory: (null != pData.subCategory) ? pData.subCategory : '',
            sideBarPart: (null != pData.sideBarPart) ? pData.sideBarPart : true,
            isDynamicPart: (null != pData.isDynamicPart) ? pData.isDynamicPart : false,
            url: pData.url,
            original_url: pData.url,
            adminPart: (null != pData.adminPart) ? pData.adminPart : false,

            hasPost: (null != pData.hasPost) ? pData.hasPost : false,
            isBlackBox: (null != pData.isBlackBox) ? pData.isBlackBox : false,
            isCustom: (null != pData.isCustom) ? pData.isCustom : false,
            pdfLink: pData.pdfLink,
            isOptix: (true == pData.isOptix),
            isAssembly: (true == pData.isAssembly),
            isEdited: (true == pData.isEdited),
            hasCage: (true == pData.hasCage),
            move: ((null != pData.move) ?
                new Vector3(pData.move.x, pData.move.y, pData.move.z) : null),
            label: (null != pData.label) ? pData.label :
                ((eDataPermission.PRIVATE == pData.permission) ? pData.name : pData.id),
            initialHeight: pData.initialHeight,
            owner: pData.owner,
            permission: pData.permission,
            translationMatrix: pData.translationMatrix
        }
    }
    //__________________________________________________________________________________________
    public get translationMatrix() {
        return this.mGeneralVOData.translationMatrix;
    }
    //__________________________________________________________________________________________
    public get initialHeight() {
        return this.mGeneralVOData.initialHeight;
    }
    //__________________________________________________________________________________________
    public get label() {
        return this.mGeneralVOData.label;
    }
    //__________________________________________________________________________________________
    public get move() {
        return this.mGeneralVOData.move;
    }
    //__________________________________________________________________________________________
    public get isOptix() {
        return this.mGeneralVOData.isOptix;
    }
    //__________________________________________________________________________________________
    public get isAssembly() {
        return this.mGeneralVOData.isAssembly;
    }
    //__________________________________________________________________________________________
    public get isBlackBox() {
        return this.mGeneralVOData.isBlackBox;
    }
    //__________________________________________________________________________________________
    public get number_id() {
        return this.mGeneralVOData.number_id;
    }
    //__________________________________________________________________________________________
    public get permission() {
        return this.mGeneralVOData.permission;
    }
    //__________________________________________________________________________________________
    public get owner() {
        return this.mGeneralVOData.owner;
    }
    //__________________________________________________________________________________________
    public get isEdited() {
        return this.mGeneralVOData.isEdited;
    }
    //__________________________________________________________________________________________
    public get hasCage() {
        return this.mGeneralVOData.hasCage;
    }
    //__________________________________________________________________________________________
    public get isBreadboard() {
        return ("Optical Table & Breadboards" == this.mGeneralVOData.section);
    }
    //__________________________________________________________________________________________
    public isMatchSearch(pString: string) {
        let aSearchString = pString.toUpperCase().trim();
        let aName = this.mGeneralVOData.name.toUpperCase().trim();
        let aID = this.mGeneralVOData.id.toUpperCase().trim();

        let aIsMatch = ((aName.indexOf(aSearchString) > -1) ||
            (aID.toUpperCase().indexOf(aSearchString) > -1));

        return aIsMatch;
    }
    //__________________________________________________________________________________________
    public get isOptPart(): boolean {
        return this.mIsOptPart;
    }
    //__________________________________________________________________________________________
    public get isSideBarPart() {
        return this.mGeneralVOData.sideBarPart;
    }
    //__________________________________________________________________________________________
    public get isDynamicModel() {
        return this.mGeneralVOData.isDynamicPart;
    }
    //__________________________________________________________________________________________
    //__________________________________________________________________________________________
    public get isAdminPart(): boolean {
        return this.mGeneralVOData.adminPart;
    }
    //__________________________________________________________________________________________
    public get isCustom(): boolean {
        return this.mGeneralVOData.isCustom;
    }
    //__________________________________________________________________________________________
    public get name(): string {
        return this.mGeneralVOData.name;
    }
    //__________________________________________________________________________________________
    public setPartData(pData: iPartDetailsVO) {
        this.mGeneralVOData.name = pData.name;
        this.mGeneralVOData.section = pData.section;
        this.mGeneralVOData.category = pData.category;
        this.mGeneralVOData.subCategory = pData.subCategory;
    }
    //__________________________________________________________________________________________
    public get originalName(): string {
        return this.mGeneralVOData.originalName;
    }
    //__________________________________________________________________________________________
    public get originalUrl(): string {
        return this.mGeneralVOData.original_url;
    }
    //__________________________________________________________________________________________
    public get id(): string {
        return this.mGeneralVOData.id;
    }
    //__________________________________________________________________________________________
    public get imageUrl(): string {
        return this.mImageUrl;
    }
    //__________________________________________________________________________________________
    public get subCategory() {
        return this.mGeneralVOData.subCategory;
    }
    //__________________________________________________________________________________________
    public get section() {
        return this.mGeneralVOData.section;
    }
    //__________________________________________________________________________________________
    public get mainSection() {
        return this.mGeneralVOData.main_section;
    }
    //__________________________________________________________________________________________
    public get category() {
        return this.mGeneralVOData.category;
    }
    //__________________________________________________________________________________________
    public get objectType(): eObjectType {
        return this.mObjectType;
    }
    //__________________________________________________________________________________________
    // public get partType(): ePartType {
    //     return this.mPartType;
    // }
    // //__________________________________________________________________________________________
    // public set partType(pType) {
    //     this.mPartType = pType;
    // }
    //__________________________________________________________________________________________
    public get baseUrl(): string {
        return this.mGeneralVOData.url;
    }
    //__________________________________________________________________________________________
    public get url(): string {
        return this.mGeneralVOData.url;
    }

    //__________________________________________________________________________________________
    public set size(pSize: Vector3) {
        this.mSize = pSize;
    }
    //__________________________________________________________________________________________
    public get pdfLink() {
        return this.mGeneralVOData.pdfLink;
    }
    //__________________________________________________________________________________________
    public get size() {
        return this.mSize.clone();
    }
    //__________________________________________________________________________________________
    public toString(): string {
        return this.id.substring(0, 5) + " " + this.name;
    }
    //__________________________________________________________________________________________
    public abstract toStringForSideBar();
    //__________________________________________________________________________________________
}
