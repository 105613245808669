import { Op3dComponentBase } from "../Op3dComponentBase";
import { Spinner } from "../home/Spinner";
import { Popup } from "./Popup";

export interface iNameForm {
    title: string;
    callback: (pVal: string) => any;
    validationFunc?: (pVal: string) => string;
    initialValue?: string;
}

export class NameForm extends Op3dComponentBase<iNameForm> {

    private static INSTANCE: NameForm;

    private mCallback: (pVal: string) => any;
    private mValidationFunc: (pVal: string) => string;
    private mNameInput: HTMLInputElement;

    //______________________________________________________________________________________________
    constructor() {
        super({
            container: NameForm._getCot(),
            skinPath: './skins/forms/name_form.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //______________________________________________________________________________________________
    public static get instance() {
        if (undefined === NameForm.INSTANCE) {
            NameForm.INSTANCE = new NameForm();
        }

        return NameForm.INSTANCE;
    }
    //______________________________________________________________________________________________
    private async _onSave() {
        Spinner.instance.show();
        let aValidationErrorMsg: string;
        if (undefined !== this.mValidationFunc) {
            aValidationErrorMsg = this.mValidationFunc(this.mNameInput.value);
        }

        if (undefined !== aValidationErrorMsg) {
            Popup.instance.open({
                text: aValidationErrorMsg,
                addWarningSign: true
            });
        } else {
            this.mCallback(this.mNameInput.value);
            this.close();
        }

        Spinner.instance.hide();
    }
    //______________________________________________________________________________________________
    protected _onOpen(pData: iNameForm): void {
        this._getPart('title').innerHTML = pData.title;
        this.mCallback = pData.callback;
        this.mValidationFunc = pData.validationFunc;
        let aInitialValue = pData.initialValue;
        if (undefined !== aInitialValue) {
            this.mNameInput.value = aInitialValue;
        }
    }
    //______________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    protected _initElements(): void {
        this.mNameInput = this._getPart('name_input') as HTMLInputElement;
    }
    //______________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._getPart('save_btn').addEventListener('click', () => this._onSave());
    }
    //______________________________________________________________________________________________
    private static _getCot() {
        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('fade');
        aDiv.classList.add('new_modal');
        aDiv.classList.add('h-auto');
        aDiv.classList.add('name_form_main');
        aDiv.style.top = ((window.innerHeight / 2) - 100) + 'px'
        aDiv.style.left = ((window.innerWidth / 2) - 400) + 'px'
        document.getElementById('forms').appendChild(aDiv);

        return aDiv;
    }
    //______________________________________________________________________________________________
}