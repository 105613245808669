import { iClientPoint } from "../../_context/_interfaces/Interfaces";
import { Part } from "../Part";
import { CageBehavior } from "./CageBehavior";
import { IrisBehavior } from "./IrisBehavior";
import { LaserBehavior } from "./LaserBehavior";
import { PostBehavior } from "./PostBehavior";
import { SelectBehavior } from "./SelectBehavior";


    export enum eBehavior {
        LASER_BEHAVIOR,
        SELECT_BEHAVIOR,
        CAGE_BEHAVIOR,
        POST_BEHAVIOR,
        IRIS_BEHAVIOR,
        RAIL_BEHAVIOR
    }
    //______________________________________________________________________________________________
    export type tBehaviorsTypes = 'laserBehavior' | 'cageBehavior' | 'postBehavior' |
        'selectBehavior';
    //______________________________________________________________________________________________
    export interface iLaserBehaviorContext {
        'laserBehavior': LaserBehavior;
        'cageBehavior': CageBehavior;
        'postBehavior': PostBehavior;
        'irisBehavior': IrisBehavior;
        'selectBehavior': SelectBehavior;
    }
    //______________________________________________________________________________________________
    export interface iLaserBehavior {

    }
    //______________________________________________________________________________________________
    // export interface iCageBahavior {
    //     updatePosOnCage(pPart: Part, pPos: number): void;
    //     updateCageSize(pPart: Part, pSizeMM: number): Promise<void>
    // }
    //______________________________________________________________________________________________
    export interface iRailHolderBehavior {
        // checkConnectionWith(pRail: parts.PartOld, pPart: parts.PartOld): void;
    }
    //______________________________________________________________________________________________
    export interface iRailBehavior {
        // moveOnRail(pMount: Part, pPositionOnRail: number): void;
    }
    //______________________________________________________________________________________________
    export interface iPostBehavior {
        updatePostHeight(pPart: Part, pScale: number): void;
        displayPost(pPart: Part, pToShow: boolean): void;
    }
    //______________________________________________________________________________________________
    export interface iIrisBehavior {
        changeInnerRadius(pPart: Part, pRadius: number): void;
    }
    //______________________________________________________________________________________________
    export interface iMovementBehavior {
        /**
         * @description Move pItem to pPoint.
         * @param pItem - item to move.
         * @param pPoint - point the item should move to.
         */
        moveOptixPart(pItem: Part, pPoint: iClientPoint, pIsFromSideBar?: boolean): void;
        /**
         * @description this function saves the delta of the click on 
         * the element to his anchor point
         * @param pEvent event of the click on canvas
         */
        // setDelta(pEvent: iClientPoint, pPart: Part): void;
        /**
         * @description Move pPart to pNewPos. If pIsHeight is false - y position not changing.
         * @param pPart - part to move
         * @param pNewPos - position to move part into.
         * @param pIsHeight - determine if changing y position also.
         */
        //moveToPos(pPart: Part, pNewPos: Vector3, pIsHeight: boolean): void;

        /**
         * @description - Rotate an object along an axis in world space.
         * @param pPart - Part to rotate.
         * @param pAxis - axis of rotation (assumed to be normalized).
         * @param pRad angle of rotation (in radians).
         * @param pPoint - point of rotation (in world coordinates).
         */
        //rotateAroundPoint(pPart: Part, pAxis: Vector3, pRad: number, pPoint: Vector3): void;
    }
    //______________________________________________________________________________________________
    export interface iSelectBehavior {
        /**
         * @description Select on the OptixPart.
         * @param pItem - item to select.
         */
        onSelect(pItem: Part): void;
    }
    //______________________________________________________________________________________________
    //export interface iBehaviorsTypes {
    //  'move': iMovementBehavior;
    //  'laser': iLaserBehavior;
    // }
    //______________________________________________________________________________________________
    //export type tBehaviorsTypes<K extends keyof iBehaviorsTypes> = iBehaviorsTypes[K];
    //______________________________________________________________________________________________
    // export interface iOpticsHolderBehavior {
    //     /**
    //      * 
    //      * @param pIndex index to place device in
    //      * @param pOpticsVO the data of device
    //      */
    //     addNewOpticalDevice(pIndex: number, pOpticsVO: .OpticsVO): void;
    //     /**
    //      * @param pIndex index of desired device to return
    //      */
    //     getDeviceByIndex(pIndex: number): OpticalDevice;
    //     /**
    //      * @description return all optical devices
    //      */
    //     getOpticalDevices(): Array<OpticalDevice>;
    // }
    //______________________________________________________________________________________________