import { EventManager } from "../../../oc/events/EventManager";
import { eUnitType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash, iNumericKeyHash } from "../../_context/_interfaces/Interfaces";
import { ComplexNumber } from "../../_utils/ComplexNumber";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { Part } from "../../parts/Part";
import { UnitHandler } from "../../units/UnitsHandler";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { CustomSelect, iCustomSelectOption } from "../components/CustomSelect";
import { FloatingCalcSettings, eCalcUnitType, iCalcSettings } from "../forms/FloatingCalcSettings";
import { OP3DTable } from "../forms/tools/OP3DTable";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";


export interface iGaussianBeamAnalysis {
    label: string;
    n: number;
    distance: number;
    q_x: ComplexNumber;
    q_y: ComplexNumber;
};

export interface iTableTitles {
    name: string;
    flexGrow: number;
    unitType?: eCalcUnitType;
}

export class GaussianBeamTable extends Op3dComponentBase {

    public static GAUSSIAN_DATA: iHash<iNumericKeyHash<Array<iGaussianBeamAnalysis>>>;
    private static TITLES: Array<iTableTitles> = [
        {
            name: 'Section',
            flexGrow: 1
        },
        {
            name: 'Refractive index',
            flexGrow: 1
        },
        {
            name: 'Thickness',
            flexGrow: 1,
            unitType: eCalcUnitType.LENGHT
        },
        {
            name: 'Waist position (x, y)',
            flexGrow: 2,
            unitType: eCalcUnitType.LENGHT
        },
        {
            name: 'Waist  (x, y)',
            flexGrow: 2,
            unitType: eCalcUnitType.LENGHT
        },
        {
            name: 'Z Rayleigh  (x, y)',
            flexGrow: 2,
            unitType: eCalcUnitType.LENGHT
        },
        {
            name: 'Divergence angle  (x, y)',
            flexGrow: 2,
            unitType: eCalcUnitType.ANGLE
        }
    ];

    private static INSTANCE?: GaussianBeamTable;

    private mTable: OP3DTable;
    private mWavelengthsSelect: CustomSelect<number>;
    private mLasersSelect: CustomSelect<Part>;

    private mCalcSettings: FloatingCalcSettings;
    private mSettings: iCalcSettings = {
        decimalPrecision: 5,
        lenghtUnits: {
            scale: (1 / UnitHandler.presentedScale),
            sign: UnitHandler.shortSign,
            type: eCalcUnitType.LENGHT
        },
        angleUnits: {
            scale: OP3DMathUtils.RAD_TO_DEG,
            sign: 'deg',
            type: eCalcUnitType.ANGLE
        }
    };

    //______________________________________________________________________________________________
    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/analysis/gaussian_beam_table.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //______________________________________________________________________________________________
    public static get instance() {
        if (undefined === GaussianBeamTable.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.style.zIndex = "1062";
            aDiv.classList.add('new_modal');
            aDiv.classList.add('gaussian_beam_table');
            aDiv.setAttribute("data-backdrop", "false");
            document.getElementById('forms').appendChild(aDiv);

            GaussianBeamTable.INSTANCE = new GaussianBeamTable(aDiv);
        }

        return GaussianBeamTable.INSTANCE;
    }
    //______________________________________________________________________________________________
    public static openGaussianTable() {
        if ((null == GaussianBeamTable.GAUSSIAN_DATA) ||
            (0 == Object.entries(GaussianBeamTable.GAUSSIAN_DATA).length)) {

            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.GAUSSIAN_BEAM_TABLE_ERROR
            });
            return;
        }

        GaussianBeamTable.instance.open();
    }
    //______________________________________________________________________________________________
    protected _onOpen() {
        this._fill();
    }
    //______________________________________________________________________________________________
    private _fill() {
        if (null == GaussianBeamTable.GAUSSIAN_DATA) {
            return;
        }

        this._setLasersDropdown();
        this._setWavelengthDropdown();
        this._drawTable();
    }
    //______________________________________________________________________________________________
    private _setLasersDropdown() {
        let aLasers = Op3dContext.PARTS_MANAGER.parts.flatMap((part) => {
            if (null == GaussianBeamTable.GAUSSIAN_DATA[part.internalID]) {
                return [] as Array<iCustomSelectOption<Part>>;
            }

            let aOption: iCustomSelectOption<Part> = {
                value: part.internalID,
                text: part.getIndexedLabel(true),
                data: part,
                enable: true
            };

            return aOption;
        });

        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        let aSelectedOption = ((null != aSelectedPart) &&
            (null != GaussianBeamTable.GAUSSIAN_DATA[(aSelectedPart.internalID)])) ?
            aSelectedPart.internalID : aLasers[0] ? aLasers[0].value : null;
        if (aSelectedOption == null) {
            return
        }

        this.mLasersSelect.setOptions(aLasers, aSelectedOption, false);
    }
    //______________________________________________________________________________________________
    private _setWavelengthDropdown() {
        let aPart = this.mLasersSelect.selectedOptionData;
        let aLightSource = aPart.getBehavior('laserBehavior').laserData.lightSource;
        let aWavelengths = aLightSource.wavelengthData.map((w) => {
            let aWavelength = w.wl;
            let aOption: iCustomSelectOption<number> = {
                value: aWavelength.toString(),
                text: aWavelength + ' nm',
                data: aWavelength,
                enable: true
            };

            return aOption;
        });

        this.mWavelengthsSelect.setOptions(aWavelengths, aWavelengths[0].value, false);
    }
    //______________________________________________________________________________________________
    private _drawTable() {
        let aPartID = this.mLasersSelect.value;
        if (null == GaussianBeamTable.GAUSSIAN_DATA[aPartID]) {
            return;
        }

        let aWavelength = this.mWavelengthsSelect.selectedOptionData;

        if (null == GaussianBeamTable.GAUSSIAN_DATA[aPartID][aWavelength]) {
            return;
        }

        this._setTable(GaussianBeamTable.GAUSSIAN_DATA[aPartID][aWavelength], aWavelength);
    }
    //______________________________________________________________________________________________
    private _setTable(pData: Array<iGaussianBeamAnalysis>, pWavelength: number) {
        let aDecimalPrecision = this.mSettings.decimalPrecision;
        let aLenghtUnits = this.mSettings.lenghtUnits.scale;
        let aAngleUnit = this.mSettings.angleUnits.scale;

        let aData = pData.map((row) => {
            let aZrX = OP3DMathUtils.toFixed((row.q_x.im * aLenghtUnits), aDecimalPrecision);
            let aZrY = OP3DMathUtils.toFixed((row.q_y.im * aLenghtUnits), aDecimalPrecision);
            let n = row.n;
            let pi = Math.PI;

            let aWavelengthMM = (pWavelength * OP3DMathUtils.NANO_TO_MM);

            let aWaistX = Math.sqrt((row.q_x.im * aWavelengthMM) / (pi * n));
            let aWaistY = Math.sqrt((row.q_y.im * aWavelengthMM) / (pi * n));

            let aWx = OP3DMathUtils.toFixed((aWaistX * aLenghtUnits), aDecimalPrecision);
            let aWy = OP3DMathUtils.toFixed((aWaistY * aLenghtUnits), aDecimalPrecision);

            let aAngle_xRad = (aWavelengthMM / (pi * n * aWaistX));
            let aAngle_yRad = (aWavelengthMM / (pi * n * aWaistY));

            let aAngle_x = OP3DMathUtils.toFixed((aAngle_xRad * aAngleUnit), aDecimalPrecision);
            let aAngle_y = OP3DMathUtils.toFixed((aAngle_yRad * aAngleUnit), aDecimalPrecision);

            let aWaistPosX = OP3DMathUtils.toFixed((row.q_x.re * aLenghtUnits), aDecimalPrecision);
            let aWaistPosY = OP3DMathUtils.toFixed((row.q_y.re * aLenghtUnits), aDecimalPrecision);

            let aRow = new Array<string>();
            aRow.push(row.label);
            aRow.push(OP3DMathUtils.toFixed((n), aDecimalPrecision));
            aRow.push(OP3DMathUtils.toFixed((row.distance * aLenghtUnits), aDecimalPrecision));
            aRow.push('(' + aWaistPosX + ', ' + aWaistPosY + ')');
            aRow.push('(' + aWx + ', ' + aWy + ')');
            aRow.push('(' + aZrX + ', ' + aZrY + ')');
            aRow.push('(' + aAngle_x + ', ' + aAngle_y + ')');

            return aRow;
        });

        let aLenghtUnitSign = ' [' + this.mSettings.lenghtUnits.sign + ']';
        let aAngleUnitSign = ' [' + this.mSettings.angleUnits.sign + ']';
        let aTitles = GaussianBeamTable.TITLES.map((title) => {
            let aUnit = '';
            switch (title.unitType) {
                case eCalcUnitType.ANGLE:
                    aUnit = aAngleUnitSign;
                    break;
                case eCalcUnitType.LENGHT:
                    aUnit = aLenghtUnitSign;
                    break;
                default:
                    break;
            }

            let aTitleData = {
                name: (title.name + aUnit),
                flexGrow: title.flexGrow
            };

            return aTitleData;
        });

        this.mTable.set({
            titles: aTitles,
            data: aData
        });
    }
    //______________________________________________________________________________________________
    protected _initElements(): void {
        this.mTable = new OP3DTable(this._getPart('table'));

        this._initWavelengthsSelect();
        this._initLasersSelect();
        this._initSettings();
    }
    //______________________________________________________________________________________________
    protected _addEventListeners(): void {
        EventManager.addEventListener(EventsContext.ON_FINISH_GAUSSIAN_SIMULATION,
            () => {
                if (false == this.mIsReady) {
                    return;
                }

                this._fill();
            }, this);
    }
    //______________________________________________________________________________________________
    private _initWavelengthsSelect() {
        this.mWavelengthsSelect = new CustomSelect<number>(this._getPart('wv_select'), {
            class: ['forms-custom-select', 'standard', 'stat', 'uppercase_label',
                'custom_select_large', 'lbh_auto'],
            labelOptions: {
                label: 'Laser wavelength',
                bold: false
            },
            onChange: () => this._drawTable()
        });
    }
    //______________________________________________________________________________________________
    private _initLasersSelect() {
        this.mLasersSelect = new CustomSelect<Part>(this._getPart('laser_select'), {
            class: ['forms-custom-select', 'standard', 'stat', 'uppercase_label',
                'custom_select_large', 'lbh_auto'],
            labelOptions: {
                label: 'Laser name',
                bold: false
            },
            width: 350,
            onChange: () => {
                this._setWavelengthDropdown();
                this._drawTable();
                Op3dContext.PARTS_MANAGER.setSelectedPart(this.mLasersSelect.selectedOptionData);
            }
        });
    }
    //__________________________________________________________________________________________
    private _initSettings() {
        this.mCalcSettings = new FloatingCalcSettings({
            callback: (pSettings) => this._onUpdateSettings(pSettings),
            lenghtUnitsSettings: [{
                sign: UnitHandler.SHORT_IN_SIGN,
                scale: (UnitHandler.MM_TO_IN),
                type: eCalcUnitType.LENGHT,
                isDefault: (UnitHandler.PRESENTED_UNIT == eUnitType.INCHES)
            },
            {
                sign: UnitHandler.SHORT_MM_SIGN,
                scale: 1,
                type: eCalcUnitType.LENGHT,
                isDefault: (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS)
            }],
            angleUnitsSettings: [{
                sign: 'deg',
                scale: OP3DMathUtils.RAD_TO_DEG,
                type: eCalcUnitType.ANGLE,
                isDefault: true
            },
            {
                sign: 'rad',
                scale: 1,
                type: eCalcUnitType.ANGLE
            },
            {
                sign: 'mrad',
                scale: OP3DMathUtils.KILO,
                type: eCalcUnitType.ANGLE
            }]
        });

        let aMoreOptionsBtn = this._getPart('more_options_button');
        aMoreOptionsBtn.addEventListener('click', (_e) => {
            let aBB = aMoreOptionsBtn.getBoundingClientRect();
            this.mCalcSettings.open({ clientX: aBB.right, clientY: aBB.top }, this.mSettings);
        });
    }
    //______________________________________________________________________________________________
    private _onUpdateSettings(pCalcSettings: iCalcSettings) {
        this.mSettings = pCalcSettings;
        this._drawTable();
    }
    //______________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    public static instanceExists() {
        return (this.INSTANCE !== undefined);
    }
    //______________________________________________________________________________________________
    public onClose() {
        super.close();
    }
    //______________________________________________________________________________________________
}