import { MutableRefObject, createContext } from "react";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { iOptiChatTabData } from "../components/chat/OptiChatTabs";

export interface iOptiChatContextData {
  key: string;
  changes: Array<iOptiChatChange>;
  isWaitingServerResponse: boolean;
  currTab: string;
}
export interface iOptiChatChange {
  type: eOptiChatTabActionType;

  /**
   * @description for SWITCH_TAB enum
   * @current_tab the tab name that the user sees right now
   */
  current_tab?: string;

  /**
   * @description for CHANGE_FILTER enum
   * @tab tab name
   */
  tab?: string;
  /**
   * @description for CHANGE_FILTER enum
   * @filters current elastic filters
   */
  filters?: iHash<any>;

  /**
   * @description for RENAME_TAB enum
   * @from previous name
   */
  from?: string;
  /**
   * @description for RENAME_TAB enum
   * @to current name
   */
  to?: string;

  /**
   * @description for DELETE_TAB enum
   * @deleted_tab tab name
   */
  deleted_tab?: string;

  /**
   * @description for TAB_CREATION enum
   * @tab_name tab name
   */
  tab_name?: string;
}
export interface iOptiChatAddMessage {
  message: string;
  session: string;

  changes?: Array<iOptiChatChange>;
}
export enum eOptiChatTabActionType {
  SWITCH_TAB = 0,
  CHANGE_FILTER = 1,
  RENAME_TAB = 2,
  DELETE_TAB = 3,
  ADDING_TO_CANVAS = 4,
  TAB_CREATION = 5,
}
// const USER_AVATAR_COLORS = ["#75B4C2", "#8F9DD1", "#D2875E"];

export interface iOptiChatContext {
  addUserMessage: (message: string) => void;
  resetSession: () => void;

  data: MutableRefObject<iOptiChatContextData>;

  session: string;
  setSession: (val: any) => void;

  conversation: Array<iOptiChatMessage>;
  setConversation: (val: any) => void;

  isReviewOpen: boolean;
  setReviewOpen: (val: any) => void;

  reviewConversation: Array<iOptiChatMessage>;
  setReviewConversation: (val: any) => void;

  tabs: Array<iOptiChatTabData>;
  setTabs: (tabs: any) => void;
}

export enum eChatMessageType {
  USER_MESSAGE = "user-message",
  CHATBOT_MESSAGE = "bot-response",
  REVIEW_MESSAGE = "review-response",
  ERROR_RESPONSE = "error-response",
}
export interface iChatBotElasticResponse {
  requested_params: [];
  title: string;
}

export interface iOptiChatMessage {
  message: string;
  typed: boolean;
  type: eChatMessageType;
  userMessage?: string;
}

const defaultContext: iOptiChatContext = {
  data: undefined,

  session: "",
  setSession: (_session: any) => {},

  conversation: [],
  setConversation: (_messages: any) => {},

  reviewConversation: [],
  setReviewConversation: (_messages: any) => {},

  isReviewOpen: false,
  setReviewOpen: (_val: any) => {},
  addUserMessage: (_message: string) => {},
  resetSession: () => {},
  tabs: [],
  setTabs: (_tabs: any) => {},
};

export interface iOptiChatMenuOptionExample {
  example: string;
  title: string;
}
export enum eExampleConversationType {
  SEARCH = "Search",
  CALCULATION = "Calculations",
  FIND = "Find the right one",
  GENERAL = "General",
}
export interface iExampleData {
  type: eExampleConversationType;
  examples: Array<string>;
}

const OptiChatContext = createContext(defaultContext);

export default OptiChatContext;
