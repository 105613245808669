﻿import { EventManager } from "../../../../oc/events/EventManager";
import { eBrandTypes } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { OpticsContext } from "../../../_context/OpticsContext";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { ServerContext, ENVS } from "../../../server/ServerContext";
import { ViewUtils } from "../../ViewUtils";
import { uoSection } from "./uoSection";


export class uoBasicInfo extends uoSection<iOpticsVO> {

    private static SKIN_PATH = './skins/forms/optics/uo_basic_info.html'
    private mNameInput: HTMLInputElement;


    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoBasicInfo.SKIN_PATH,
            title: 'Basic Info',
            isNewSkin: false,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    public get name() {
        return this.mNameInput.value;
    }
    //__________________________________________________________________________________________
    protected async _setData(pOpticsVO: iOpticsVO) {
        try {
            let aElements = $(this.mContainer).find('[path]');
            for (let i = 0; i < aElements.length; i++) {
                let aElement = aElements[i] as HTMLInputElement | HTMLSelectElement;
                let aPath = aElement.getAttribute('path').split('.');
                this._fillInput(pOpticsVO, aPath, aElement);

                let aSearchString = '[p_path="' + aElement.getAttribute('path') + '"]';
                let aParent = $(this.mContainer).find(aSearchString)[0];
                if (null != aParent) {
                    let aIsVisible = (('' != aElement.value) && (null != aElement.value))
                    ViewUtils.setElementVisibilityByDNone(aParent, aIsVisible);
                }

                aElement.addEventListener('change', () => this._onChange());
            }

        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
        }

        this.expand();
        this._setPermission(pOpticsVO);
    }

    //__________________________________________________________________________________________
    private _setPermission(pOpticsVO: iOpticsVO) {
        let aIsCustomized = OpticsContext.isCustomized(pOpticsVO);
        // ((eDataPermission.PRIVATE == pPermission) &&
        //     (pShape != eOpticShape.PARAXIAL_LENS));

        let aPrefixDiv = this.mNameInput.previousElementSibling;
        aPrefixDiv.innerHTML = (true == aIsCustomized) ? '(customized) ' : '';
        if (false == aIsCustomized) {
            ViewUtils.setClassState(this.mNameInput, "customized", false);
            return;
        }

        ViewUtils.setClassState(this.mNameInput, "customized", true);
        // this.mNameInput.style.marginLeft = '-100px';
        // this.mNameInput.style.paddingLeft = '100px';
    }
    //__________________________________________________________________________________________
    private _onChange() {
        EventManager.dispatchEvent(EventsContext.OPTICS_BASIC_CHANGE, this);
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mNameInput = $(this.mContainer).find('input[path="name"]')[0] as HTMLInputElement;

        this._initSubtypeSelect();
        await this._initBrands();
        this._initPermissionRadioButtons();
        this._initWeblinkButton();
    }
    //__________________________________________________________________________________________
    private _initWeblinkButton() {
        Op3dUtils.getElementIn(this.mContainer, 'weblink_button').addEventListener('click',
            () => {
                let aLink = (Op3dUtils.getElementIn(this.mContainer,
                    'weblink') as HTMLInputElement).value;
                Op3dUtils.openPopupWindow(aLink);
            });
    }
    //__________________________________________________________________________________________
    private _initPermissionRadioButtons() {
        $(this.mContainer).find('input[type="radio"][name="uo_permission"]').each(
            (_index, radioButton) => {
                radioButton.addEventListener('internal_change', () => {
                    let aWrap = radioButton.parentElement.parentElement;
                    ViewUtils.setElementActive(aWrap, (radioButton as HTMLInputElement).checked);
                });
            });
    }
    //__________________________________________________________________________________________
    private async _initBrands() {
        let aBrands = await Op3dContext.DATA_MANAGER.getBrands(eBrandTypes.OPTIC);
        let aBrandsSelect = Op3dUtils.getElementIn(this.mContainer, 'brands_select') as HTMLSelectElement;

        for (let i = 0; i < aBrands.length; i++) {
            let aOption = document.createElement('option');
            let aBrandName = aBrands[i];
            aOption.value = aBrandName;
            aOption.text = aBrandName;

            aBrandsSelect.appendChild(aOption);
        }
    }
    //__________________________________________________________________________________________
    private _initSubtypeSelect() {
        let aSubtypeSelect = Op3dUtils.getElementIn(this.mContainer, 'subtypes_select') as HTMLSelectElement;

        let aTypes = OpticsContext.OPTICS_DATA;
        for (let i = 0; i < aTypes.length; i++) {
            let aGroup = document.createElement('optgroup');
            aGroup.label = aTypes[i].type;

            let aSubtypes = aTypes[i].subtypes;
            for (let j = 0; j < aSubtypes.length; j++) {
                let aOption = document.createElement('option');
                let aSubtypeName = aSubtypes[j].name;
                aOption.value = aSubtypeName;
                aOption.text = aSubtypeName;

                aGroup.appendChild(aOption);
            }

            aSubtypeSelect.appendChild(aGroup);
        }

        let aTypesSelect = Op3dUtils.getElementIn(this.mContainer,
            'types_select') as HTMLSelectElement;
        aTypesSelect.addEventListener('internal_change', () => {
            let aSelectedType = aTypesSelect.value

            let aSelectedOption = aSubtypeSelect.selectedOptions[0];
            let aGroup = aSelectedOption.parentElement as HTMLOptGroupElement;
            if (aGroup.label != aSelectedType) {
                aSubtypeSelect.selectedIndex = -1;
            }

            $(aSubtypeSelect).find('optgroup').each(
                (_index, group) => {
                    let aIsShown = (aSelectedType == (group as HTMLOptGroupElement).label);
                    ViewUtils.setElementVisibilityByDNone(group as HTMLOptGroupElement, aIsShown);
                });
        });
    }
    //__________________________________________________________________________________________
}
