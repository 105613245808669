import { iMinMax } from "../../../_context/_interfaces/Interfaces";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Popup } from "../../forms/Popup";

export interface iColorMapRangeData {
    originalRange: iMinMax;
    currRange: iMinMax;
    // min: number;
    // max: number;
    onChange: (pData: iMinMax) => void
    clickPoint: MouseEvent
}

export class ColorMapRange extends Op3dComponentBase<iColorMapRangeData>{

    // private mOnCloseScene = (e: MouseEvent) => this._onCloseScene(e);
    private mRanges: iMinMax<HTMLInputElement>;

    constructor() {
        super({
            container: ColorMapRange._getContainer(),
            skinPath: './skins/forms/analysis/color_map_axis_range.html',
            draggableParams: {
                snap: true,
                class: "draggable-form",
                containment: 'window',
                handle: ".color_map_range__header"
            },
        });
    }
    //___________________________________________________________________________________________________________________
    // private _onCloseScene(e: MouseEvent) {
    //     if (this.mContainer.contains(e.target as HTMLElement) === true) {
    //         return;
    //     }

    //     this.close();
    // }
    //___________________________________________________________________________________________________________________
    protected _onCreationComplete(): void {

        this.mRanges = {
            min: this._getPart("range-min") as HTMLInputElement,
            max: this._getPart("range-max") as HTMLInputElement,
        }

        // this.mRanges.min.addEventListener("change", () => this._onChangeRange())
        // this.mRanges.max.addEventListener("change", () => this._onChangeRange())
        let aOkBtn = this._getPart("save-btn");
        aOkBtn.addEventListener("click", () => this.onSaveRange())

        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    private onSaveRange() {
        let aOnChangeRange = this._onChangeRange();
        if (aOnChangeRange === true) {
            this.close();
        }


    }
    //______________________________________________________________________________________________
    private _onChangeRange() {
        let aRange: iMinMax = {
            min: parseFloat(this.mRanges.min.value),
            max: parseFloat(this.mRanges.max.value),
        }

        if (aRange.min >= aRange.max) {
            Popup.instance.open({
                text: "Invalid range"
            })
            return false;
        }

        if (aRange.min < this.mData.originalRange.min || aRange.max > this.mData.originalRange.max) {
            Popup.instance.open({
                text: `Invalid range, new range must be between ${this.mData.originalRange.min} and ${this.mData.originalRange.max}`
            })
            return false;
        }
        this.mData.onChange(aRange);
        return true;
    }
    //___________________________________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();
        // document.removeEventListener("click", this.mOnCloseScene, true);
        ViewUtils.removeFromParent(this.mContainer);
    }
    //___________________________________________________________________________________________________________________
    protected _onOpen(pData: iColorMapRangeData): void {

        this.mData = pData;
        /**
         * change when decmal accuracy will be on dev
         */
        this.mRanges.min.value = this.mData.currRange.min.toString();
        this.mRanges.max.value = this.mData.currRange.max.toString();

        let aDelta = 30;
        this.mContainer.style.left = (this.mData.clickPoint.clientX + aDelta) + 'px';
        this.mContainer.style.top = (this.mData.clickPoint.clientY + aDelta) + 'px';

        // document.addEventListener("click", this.mOnCloseScene, true);
    }
    //___________________________________________________________________________________________________________________
    private static _getContainer() {
        let aFormsContainer = document.getElementById('forms');
        if (aFormsContainer === null) {
            throw new Error("forms container not found");
        }

        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('new-modal');
        aDiv.style.width = "auto";
        aDiv.classList.add('overflow-hidden');
        aDiv.setAttribute("data-backdrop", "false");
        aFormsContainer.appendChild(aDiv);
        return aDiv;
    }
    //___________________________________________________________________________________________________________________

}