import { iMaterialMetaDataVO, iMaterialVO } from "../../_context/_interfaces/Interfaces";
import { ServerContext } from "../../server/ServerContext";
import { eCacheDataType } from "../CacheStorage";
import { DataLoader } from "./DataLoader";

export class MaterialDataLoader extends
    DataLoader<iMaterialMetaDataVO, { number_id: string }, iMaterialVO, iMaterialVO> {

    private static INSTANCE: MaterialDataLoader;

    //__________________________________________________________________________________________
    private constructor() {
        super({
            cacheData: {
                type: eCacheDataType.MATERIAL,
                key: 'number_id'
            },
            fullDataServerCall: (pData: { number_id: string }) =>
                ServerContext.SERVER.getFullMaterial(pData),
            addItemServerCall: (pData) =>
                ServerContext.SERVER.addMaterial(pData),
            //metaDataServerCall: (pData: iServerMetaDataQuery) =>
            //server.ServerContext.SERVER.getMaterialsMetaData(pData),
            // detailedDataServerCall: (pData: iServerMetaDataQuery) =>
            //     engine.Op3dContext.serverManager.getDetailedMaterialsData(pData),
            // addItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.addMaterialSync(pData),
            // editItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.addMaterialSync(pData),
            // removeItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.removeMaterialSync(pData),
            isExistServerCall: (pData) =>
                ServerContext.SERVER.isMaterialExists({ numberID: pData.number_id })
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________

}
