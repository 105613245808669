
import { EventsContext } from "../../../_context/EventsContext";
import { MathContext } from "../../../_context/MathContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { op3dTooltip } from "../../home/op3dTooltip";
import { iNIETriggers, NumberInputElement } from "../_components/NumberInputElement";
import { eAngleUnit } from "../piMoveRotateSection";
import { piAngularInformation } from "./piAngularInformation";
import { piAppearanceSection } from "./piApperaranceSection";
import { piPolarizationSection } from "./piPolarizationSection";
import { piWavelengthSection } from "./piWavelengthSection";
import { iMinMax } from "../../../_context/_interfaces/Interfaces";
import { eSmDivergenceType, eSmPlaneWaveType, eSmRaysDensity, eSmRaysKind, iPointSourceProfile, SimulationContext } from "../../../simulation/SimulationContext";
import { ePolarizationType, eUnitType, eStateToAnalysis } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { Part } from "../../../parts/Part";
import { iLaserBehaviorJSON, iLaserBehaviorJSONObject } from "../../../parts/_parts_assets/ExportToJSONInterfaces";
import { eCountType, iAppearanceData, iBasicLightSource, iPointSourceData, iWavelengthData, tRaysKind } from "../../../parts/behaviors/LightSourceContext";
import { RadioComponent } from "../../components/RadioComponent";
import { PartInfoSection } from "../PartInfoSection";
import { piSpatialInformationSection } from "./piSpatialInformationSection";
import { Popup } from "../../forms/Popup";
import { EventManager } from "../../../../oc/events/EventManager";
import { ParserContext } from "../../../parser/ParserContext";
import { UnitHandler } from "../../../units/UnitsHandler";
import { ViewUtils } from "../../ViewUtils";
import { SourcesDataLoader } from "../../../data/data_loader/SourcesDataLoader";
import { LightSourceInfo } from "../../infos/LightSourceInfo";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";
import { SourcesMenu } from "../../menus/_search/SourcesMenu";
import { DataUtils } from "../../../_utils/DataUtils";
import { piGaussianBeam } from "./piGaussianBeam";
import { CustomSelect, iCustomSelectOption } from "../../components/CustomSelect";

export interface iShapeNIEParams extends iNIEParams<number> {
}

export interface iNIEParams<T> {
    container: HTMLElement,
    worldToUnit: number,

    /**
     * whether this input should have an qa_id for automation
     */
    qa_id?: string,
    range: iMinMax,

    //titleAddition?: string,

    unitName?: string;


    defaultValue: number,

    sign?: string,
    label?: string;
    factor?: number;
    /**
     * @default true
     */
    hasArrows?: boolean,
    isScientific?: boolean,
    step: number,
    toFixed?: number,
    callback: (pParam: T) => void,
    triggers?: iNIETriggers,
    unitElementWidth?: number;
    isInt?: boolean;
}

export interface iLightSourceSection extends iAppearanceData {
    part: Part;
    laserBehavior: iLaserBehaviorJSON;
    isSingleSource: boolean;
    /**
     * decimal
     */
    isUserDefinedLaser: boolean;
    id: string;
}

export class piLightSourceSection extends PartInfoSection<iLightSourceSection> {
    private static ELEMENTS_DEFAULTS = {
        HALF_CONE_X: 10,
        HALF_CONE_Y: 10,
        DIST_AT_Z: 50,
        HALF_WIDTH_AT_Z: 10,
        HALF_HEIGHT_AT_Z: 10,
        POWER: 1
    }

    private static DEFAULT_LIGHT_SOURCE = "User-defined light source";
    private mName: HTMLAnchorElement;
    private mSearchBtn: HTMLElement;
    private mSourceType: CustomSelect<eSmRaysKind>;
    private mDivergenceType: HTMLSelectElement;
    private mElements: {
        half_cone_x?: NumberInputElement;
        half_cone_y?: NumberInputElement;
        half_width_dist_z?: NumberInputElement;
        half_width_dist_wx?: NumberInputElement;
        half_width_dist_wy?: NumberInputElement;
        count_3d?: NumberInputElement;
        count_analysis?: NumberInputElement;
        power?: NumberInputElement;
    };

    private mAngularInformation: piAngularInformation;
    private mAppearanceSection: piAppearanceSection;
    private mPolarizationSection: piPolarizationSection;
    private mWavelengthSection: piWavelengthSection;
    private mSpatialInfo: piSpatialInformationSection;
    private mPointSourceAngleUnit: HTMLSelectElement
    private mCountsComponent: RadioComponent;
    private mGaussianBeam: piGaussianBeam;


    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_light_source.html'
        });
    }
    //__________________________________________________________________________________________
    private _onChangeRotationUnit() {
        this._changeFieldName();
        let aSign = (eAngleUnit.DEG == parseInt(this.mPointSourceAngleUnit.value)) ? '°' : '';
        this.mElements.half_cone_x.updateCreationData({ field_name: 'Θ' + aSign, isGlobalToFixed: true });
        this.mElements.half_cone_y.updateCreationData({ field_name: 'φ' + aSign, isGlobalToFixed: true });

        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mPointSourceAngleUnit.value));
        let aFactor = (true == aIsDeg) ? MathContext.DEG_TO_RAD : 0.001;

        let aHalfConeX = (this.mElements.half_cone_x.convertedValue / aFactor);
        let aHalfConeY = (this.mElements.half_cone_y.convertedValue / aFactor);

        let aMul = (true == aIsDeg) ? 1 / (1000 * Math.PI / 180) : 1000 * Math.PI / 180;
        let aRange = DataUtils.getObjectCopy(this.mElements.half_cone_x.range);
        aRange.min *= aMul;
        aRange.max *= aMul;

        this.mElements.half_cone_x.setData({
            factor: aFactor,
            range: aRange
        });

        aRange = DataUtils.getObjectCopy(this.mElements.half_cone_y.range);
        aRange.min *= aMul;
        aRange.max *= aMul;

        this.mElements.half_cone_y.setData({
            factor: aFactor,
            range: aRange
        });

        this.mElements.half_cone_x.value = aHalfConeX;
        this.mElements.half_cone_y.value = aHalfConeY;
    }
    //__________________________________________________________________________________________
    private _initAngularUnit() {
        let aDefaultAngleUnit = eAngleUnit.DEG;
        this.mPointSourceAngleUnit = this._getPart('divergence-unit') as HTMLSelectElement;
        this.mPointSourceAngleUnit.value = aDefaultAngleUnit.toString();
        this.mPointSourceAngleUnit.addEventListener('change', () => this._onChangeRotationUnit());

        this._changeFieldName();
    }
    //__________________________________________________________________________________________
    private _changeFieldName() {
        let aValue = parseInt(this.mPointSourceAngleUnit.value);
        let aSign = (eAngleUnit.DEG == aValue) ? '°' : ':';

        Op3dUtils.getElementIn(this.mContainer,
            'half-cone-x').setAttribute('field_name', 'Θx' + aSign);

        Op3dUtils.getElementIn(this.mContainer,
            'half-cone-y').setAttribute('field_name', 'Θy' + aSign);
    }
    //__________________________________________________________________________________________
    private _onChangeCountType() {
        let aOpt = this.mCountsComponent.chosenOption as eCountType;
        let aCountRays = 0;
        let aCancelCallback: Function;
        switch (aOpt) {
            case eCountType.PER_WAVELENGTH:
                aCountRays = this.mElements.count_analysis.value * this.mWavelengthSection.wavelengthsCount;
                aCancelCallback = () => this.mCountsComponent.chooseOption(eCountType.TOTAL, false);
                break;
            case eCountType.TOTAL:
                aCountRays = this.mElements.count_analysis.value;
                aCancelCallback = () => {
                    this.mElements.count_analysis.value = Op3dContext.SETUPS_MANAGER.settings.maxEmittedRays;
                }
                break;
        }

        if (aCountRays > Op3dContext.SETUPS_MANAGER.settings.maxEmittedRays) {
            Popup.instance.open({
                text: MessagesHandler.MAX_EMITTED_RAYS_REACHED_CONFIRMATION,
                yesBtn: {
                    title: "Confirm",
                    callback: () => { }
                },
                noBtn: {
                    title: "Cancel",
                    callback: aCancelCallback
                }
            })
        }
        this._updateShape(null);
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {

        this.mCountsComponent = new RadioComponent(this._getPart("counts-component"), {
            value_attr: "counts_btn",
            onChange: (_pVal: string) => this._onChangeCountType(),
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: true
            }
        });

        let aNumOfRaysIcon = this._getPart('num_of_rays_tooltip');
        let aText = '<div>The number in the <strong>“3D layout”</strong> field represents the number of rays for the 3D visualization (on the main 3D screen). It is also the default value for the <strong>“Fast”</strong> analysis in the analysis portal.</div><br>';
        aText += '<div>The allowed input range is 1 to 200 rays and serves as an approximation to the real quantity of the drawn rays or the fast analysis rays (since rays are distributed randomly or periodically).</div><br>';
        aText += '<div>The number in the <strong>"Analysis"</strong> field represents the upper limit of rays for the <strong>“Advanced”</strong> analyses in the analysis portal.</div>';

        op3dTooltip.addTooltipToElement(aNumOfRaysIcon, aText);

        this._initAngularUnit();

        this.mSpatialInfo = new piSpatialInformationSection(
            this._getPart("spatial-info"), {
            onChange: (pShape: eSmRaysKind) => this._updateShape(pShape)
        });

        this.mGaussianBeam = new piGaussianBeam(this._getPart("gaussian-beam-info"),
            () => this._updateShape(eSmRaysKind.GAUSSIAN_BEAM));

        this.mWavelengthSection = new piWavelengthSection(
            this._getPart("wavelength-section"),
            { onChange: (pData: iWavelengthData) => this._updateWavelengths(pData) }
        )

        // this.mArraySourcesSettingsSection = new piArraySourcesSettings(
        //     this._getPart("array-sources-settings"),
        //     { onChange: () => this._onArrayPropsChanged() });

        this.mPolarizationSection = new piPolarizationSection(
            this._getPart("polarization-section"),
            {
                onChange: (pPolarization: ePolarizationType) => this._onPolarizationChanged(pPolarization),
                onCalculationChange: () => this._onPolarizationChanged(null)
            });

        this.mAppearanceSection = new piAppearanceSection(
            this._getPart("appearance-container"),
            { onChange: (pData: iAppearanceData) => this._onAppearanceChanged(pData) });

        this.mAngularInformation = new piAngularInformation(
            this._getPart("coordinates-container"),
            { onChange: (pShape: eSmRaysKind) => this._updateShape(pShape) });

        this.mName = this._getPart("light-source-name", true) as HTMLAnchorElement;
        this.mName.addEventListener("click", () => this._onInfo());

        this.mDivergenceType = this._getPart("divergence-type", true) as HTMLSelectElement;
        this.mDivergenceType.addEventListener("change", () => this._onChangeDivergenceType());

        this.mSourceType = new CustomSelect<eSmRaysKind>(this._getPart("source-type"), {
            labelOptions: {
                label: 'Source type',
                bold: false,
                justify: "start"
            },
            placeHolder: 'Choose source type',
            onChange: () => this._onChangeSourceType(),
            class: ["forms-custom-select", "flex_100", "w-auto"],
            staticPostion: true
        });

        let aOptions: Array<iCustomSelectOption<eSmRaysKind>> = [
            {
                value: eSmRaysKind.POINT_SOURCE,
                text: 'Point source',
                data: eSmRaysKind.POINT_SOURCE,
                enable: true
            },
            {
                value: eSmRaysKind.PLANE_WAVE,
                text: 'Plane wave',
                data: eSmRaysKind.PLANE_WAVE,
                enable: true
            },
            {
                value: eSmRaysKind.GAUSSIAN_BEAM,
                text: 'Gaussian',
                data: eSmRaysKind.GAUSSIAN_BEAM,
                isPremiumOnly: true,
                enable: true
            }
        ];

        this.mSourceType.setOptions(aOptions, eSmRaysKind.POINT_SOURCE, false);

        this.mSearchBtn = this._getPart("search-laser-btn", true);
        this.mSearchBtn.addEventListener("click", () => this._onSearchLightSource());

        //const aFixRot = 2;
        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mPointSourceAngleUnit.value));
        let aFactorDeg = aIsDeg ? 1 : MathContext.RAD_TO_DEG / 1000;
        const aRangeDeg: iMinMax = { min: 0.001 * aFactorDeg, max: 180 * aFactorDeg };

        this.mElements = {};
        const aUnitSign = UnitHandler.shortSign;

        this.mElements.half_cone_x = this._initHalfAngle(
            this._getPart("half-cone-theta-x"),
            aRangeDeg,
            6,
            piLightSourceSection.ELEMENTS_DEFAULTS.HALF_CONE_X * aFactorDeg);

        this.mElements.half_cone_y = this._initHalfAngle(
            this._getPart("half-cone-theta-y"),
            aRangeDeg,
            6,
            piLightSourceSection.ELEMENTS_DEFAULTS.HALF_CONE_Y * aFactorDeg);

        const aUnitConversion = (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS) ?
            1 : 0.04;


        this.mElements.half_width_dist_z = piLightSourceSection.getNumberInputElement({
            container: this._getPart("half-width-dist-z"),
            worldToUnit: aUnitConversion,
            range: { max: Number.MAX_SAFE_INTEGER, min: 0.0001 },
            callback: () => this._updateHalfWidthAtZProps(),
            defaultValue: piLightSourceSection.ELEMENTS_DEFAULTS.DIST_AT_Z * aUnitConversion,
            step: 1 * aUnitConversion,
            unitName: aUnitSign,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });
        this.mElements.half_width_dist_wx = piLightSourceSection.getNumberInputElement({
            container: this._getPart("half-width-dist-wx"),
            worldToUnit: aUnitConversion,
            range: { max: Number.MAX_SAFE_INTEGER, min: 0.0001 },
            callback: () => this._updateHalfWidthAtZProps(),
            defaultValue: piLightSourceSection.ELEMENTS_DEFAULTS.HALF_WIDTH_AT_Z * aUnitConversion,
            step: 1 * aUnitConversion,
            unitName: aUnitSign,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });
        this.mElements.half_width_dist_wy = piLightSourceSection.getNumberInputElement({
            container: this._getPart("half-width-dist-wy"),
            worldToUnit: aUnitConversion,
            range: { max: Number.MAX_SAFE_INTEGER, min: 0.0001 },
            unitName: aUnitSign,
            callback: () => this._updateHalfWidthAtZProps(),
            defaultValue: piLightSourceSection.ELEMENTS_DEFAULTS.HALF_HEIGHT_AT_Z * aUnitConversion,
            step: 1 * aUnitConversion,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });
        this.mElements.count_3d = piLightSourceSection.getNumberInputElement({
            container: this._getPart('3d-rays-count'),
            worldToUnit: 1,
            range: { max: SimulationContext.SIMULATION_CONSTANTS.RAYS_COUNT_SINGLE_LASER_3D, min: 1 },
            callback: () => this._updateShape(null, false),
            defaultValue: SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.regular,
            step: 1,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: true
            }
        });
        this.mElements.count_analysis = piLightSourceSection.getNumberInputElement({
            container: this._getPart('analysis-rays-count'),
            worldToUnit: 1,
            isScientific: true,
            hasArrows: true,
            range: {
                max: SimulationContext.SIMULATION_CONSTANTS.ANALYSIS_RAYS_COUNT,
                min: SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.analysis
            },
            callback: () => {
                this._updateShape(null, false);
                EventManager.dispatchEvent(EventsContext.CHANGE_ANALYSIS_RAYS_COUNT, this, {
                    rays_count: this.mElements.count_analysis.value,
                    internal_id: Op3dContext.PARTS_MANAGER.selectedPart.internalID
                });
            },
            defaultValue: SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.analysis,
            step: 1,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });
        this.mElements.power = piLightSourceSection.getNumberInputElement({
            container: this._getPart("source-power"),
            worldToUnit: 1,
            hasArrows: true,
            range: { min: 0, max: 1000000 },
            defaultValue: piLightSourceSection.ELEMENTS_DEFAULTS.POWER,
            step: 0.1,
            toFixed: 3,
            callback: () => this._updateShape(null),
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });
    }
    //__________________________________________________________________________________________
    private _updateHalfWidthAtZProps() {
        let aZDist = (+this.mElements.half_width_dist_z.value);
        let aHalfWidthX = (+this.mElements.half_width_dist_wx.value);
        let aHalfWidthY = (+this.mElements.half_width_dist_wy.value);

        let aDegX = (Math.atan(aHalfWidthX / aZDist) * MathContext.RAD_TO_DEG);
        let aDegY = (Math.atan(aHalfWidthY / aZDist) * MathContext.RAD_TO_DEG);

        this.mElements.half_cone_x.value = aDegX;
        this.mElements.half_cone_y.value = aDegY;
        this._updateShape(eSmRaysKind.POINT_SOURCE);
    }
    //__________________________________________________________________________________________
    public static getNumberInputElement(pData: iShapeNIEParams) {

        let aLongestNum = Math.max(pData.range.min.toString().length,
            pData.step.toString().length);

        let aFix = pData.toFixed != null ? pData.toFixed : Math.min(6, aLongestNum + 1);

        let aElement = new NumberInputElement(pData.container, {
            isGlobalToFixed: true,
            hasArrows: pData.hasArrows,
            isScientific: pData.isScientific,
            toFixed: aFix,
            worldToUnit: pData.worldToUnit,
            sign: pData.sign,
            unitName: pData.unitName,
            triggers: pData.triggers
        });

        aElement.setData({
            factor: pData.factor,
            range: pData.range,
            step: pData.step,
            callback: pData.callback
        });

        aElement.value = pData.defaultValue;
        return aElement;
    }
    //__________________________________________________________________________________________
    private _onPolarizationChanged(_pPolarization?: ePolarizationType) {
        this._updateShape(null);
    }
    //__________________________________________________________________________________________
    private _onAppearanceChanged(_pData: iAppearanceData) {
        this._updateShape(null);
    }
    //__________________________________________________________________________________________
    private _updateWavelengths(pData: iWavelengthData) {
        let aBehavior = Op3dContext.PARTS_MANAGER.selectedPart.getBehavior("laserBehavior");
        aBehavior.updateWavelengths(pData);
    }
    //__________________________________________________________________________________________
    private _initHalfAngle(pContainer: HTMLElement, pRangeDeg: iMinMax,
        pToFixed: number, pDefaulValue: number) {

        let aItem = new NumberInputElement(pContainer, {
            hasArrows: true,
            isGlobalToFixed: true,
            toFixed: pToFixed,
            worldToUnit: 1,
            sign: ParserContext.SPECIAL_CHARACTERS.degree,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });

        aItem.setData({
            factor: MathContext.DEG_TO_RAD,
            range: pRangeDeg,
            step: 0.5,
            callback: () => this._updateShape(eSmRaysKind.POINT_SOURCE)
        });
        aItem.value = pDefaulValue;
        return aItem;
    }
    //__________________________________________________________________________________________
    private _onChangeDivergenceType() {
        this._setDivergenceSectionsVisibility();
        this._updateDivergence(this.mDivergenceType.value as eSmDivergenceType);
    }
    //__________________________________________________________________________________________
    private _onChangeSourceType() {
        Op3dContext.SCENE_HISTORY.addToHistory();
        this._setGeometrySectionsVisibility();
        this._updateShape(this.mSourceType.selectedOptionData);
        this.mAppearanceSection.onChangeSourceType(this.mSourceType.selectedOptionData);

        let aPartID = Op3dContext.PARTS_MANAGER.selectedPart.internalID;
        AnalysisPortal.instance.updateSpecificLaser(aPartID, this.mSourceType.selectedOptionData);
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _setDivergenceSectionsVisibility() {
        const aValue = this.mDivergenceType.value;
        $(this.mContainer).find('[divergence]').each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                aValue != "" && element.getAttribute('divergence').indexOf(aValue) != -1);
        });
    }
    //__________________________________________________________________________________________
    private _setGeometrySectionsVisibility() {
        const aValue = this.mSourceType.value;
        $(this.mContainer).find('[shape]').each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                aValue != "" && element.getAttribute('shape').indexOf(aValue) != -1);
        });
    }
    //__________________________________________________________________________________________
    private async _onInfo() {
        let aNumberID = this.mName.getAttribute("number_id");

        const aSourceVO = await SourcesDataLoader.instance.getSingleFullData({
            number_id: aNumberID
        })
        if (aSourceVO != null) {
            LightSourceInfo.instance.open(aSourceVO);

        } else {
            Popup.instance.open({ text: MessagesHandler.ERROR_LOADING_LIGHT_SOURCE });
        }
    }
    //__________________________________________________________________________________________
    private _onSearchLightSource() {
        SourcesMenu.instance.open({
            part_id: Op3dContext.PARTS_MANAGER.selectedPart.id,
            part: Op3dContext.PARTS_MANAGER.selectedPart,
        });
    }
    //__________________________________________________________________________________________
    private _updateDivergence(pDivergenceType: eSmDivergenceType) {
        let aDivergence = pDivergenceType != null ? pDivergenceType :
            this.mDivergenceType.value as eSmDivergenceType;

        switch (aDivergence) {
            case eSmDivergenceType.HALF_WIDTH_AT_Z:
                this._updateHalfWidthAtZProps();
                break;
            case eSmDivergenceType.HALF_CONE_ANGLE:
            case eSmDivergenceType.HALF_WIDTH_RECT:
                this.mElements.half_cone_x.value = piLightSourceSection.ELEMENTS_DEFAULTS.HALF_CONE_X;
                this.mElements.half_cone_y.value = piLightSourceSection.ELEMENTS_DEFAULTS.HALF_CONE_Y;
                break;

            // case eSmDivergenceType.HALF_WIDTH_AT_Z:
            //     this.mElements.half_width_dist_z.value = piLightSourceSection.ELEMENTS_DEFAULTS.DIST_AT_Z;
            //     this.mElements.half_width_dist_wx.value = piLightSourceSection.ELEMENTS_DEFAULTS.HALF_WIDTH_AT_Z;
            //     this.mElements.half_width_dist_wy.value = piLightSourceSection.ELEMENTS_DEFAULTS.HALF_HEIGHT_AT_Z;
            //     this._updateHalfWidthAtZProps();
            //     break;
        }

        this._updateShape(eSmRaysKind.POINT_SOURCE);
    }
    //__________________________________________________________________________________________
    private _updateShape(pRaysKind: eSmRaysKind, pTriggerAnalysis: boolean = true) {
        let aLightSourceSpatialData = this.mSpatialInfo.getData();
        let aPlaneWaveShape = aLightSourceSpatialData.shape as eSmPlaneWaveType;
        let aSourceType = pRaysKind != null ? pRaysKind : this.mSourceType.selectedOptionData;
        let aSourceGeometricalData = this.getGeo(aSourceType);

        const aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        let aBehavior = aPart.getBehavior("laserBehavior");
        let aLaserJSON: iLaserBehaviorJSONObject = {};
        aBehavior.exportToJSON(aPart, aLaserJSON);
        let aAppearanceData = this.mAppearanceSection.getData();
        let aWavelengthData = this.mWavelengthSection.getData();

        let aLightSource: iBasicLightSource = {
            count_type: this.mCountsComponent.chosenOption as unknown as eCountType,
            model_radius: aAppearanceData.model_radius,
            alpha: aAppearanceData.alpha,
            color: aAppearanceData.color,
            rays_color: aAppearanceData.rays_color,
            distribution_data: aWavelengthData.distribution_data,
            wavelengthData: aWavelengthData.wavelengthData,
            power: this.mElements.power!.value,
            kind: aSourceType,
            shape: aPlaneWaveShape,
            sourceGeometricalData: aSourceGeometricalData,
            directionalData: this.mAngularInformation._getData(),
            polarization: this.mPolarizationSection._getData(),
            gaussianBeam: this.mGaussianBeam.getData()
        };

        aBehavior.updateLightSourceGeo(aPart, aLightSource);

        if (true == pTriggerAnalysis) {
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
                eStateToAnalysis.FROM_SCENE);
        }
    }
    //__________________________________________________________________________________________
    private getGeo(pSourceType: eSmRaysKind) {
        let aLightSourceSpatialData = this.mSpatialInfo.getData();
        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mPointSourceAngleUnit.value));
        let aFactor = aIsDeg ? 1 : MathContext.RAD_TO_DEG / 1000;

        let aSourceGeometricalData: tRaysKind;
        switch (pSourceType) {
            case eSmRaysKind.POINT_SOURCE:
                aSourceGeometricalData = {
                    divergence_type: this.mDivergenceType.value,
                    angle_x: this.mElements.half_cone_x.value * aFactor,
                    angle_y: this.mElements.half_cone_y.value * aFactor,
                    dist_z: this.mElements.half_width_dist_z.value,
                    half_width_dist_wx: this.mElements.half_width_dist_wx.value,
                    half_width_dist_wy: this.mElements.half_width_dist_wy.value,
                    density_pattern: aLightSourceSpatialData.density_pattern,
                    //common
                    count: this.mElements.count_3d.value,
                    count_analysis: this.mElements.count_analysis.value,

                } as iPointSourceData;
                break;
            case eSmRaysKind.PLANE_WAVE:
                aSourceGeometricalData = {
                    width: aLightSourceSpatialData.width,
                    height: aLightSourceSpatialData.height,
                    radius: aLightSourceSpatialData.radius,
                    density_pattern: aLightSourceSpatialData.density_pattern as eSmRaysDensity,
                    radius_x: aLightSourceSpatialData.radius_x,
                    radius_y: aLightSourceSpatialData.radius_y,
                    //common
                    count: this.mElements.count_3d.value,
                    count_analysis: this.mElements.count_analysis.value,
                }
                break;
            case eSmRaysKind.GAUSSIAN_BEAM:
                aSourceGeometricalData = {
                    width: aLightSourceSpatialData.width,
                    height: aLightSourceSpatialData.height,
                    radius: aLightSourceSpatialData.radius,
                    density_pattern: aLightSourceSpatialData.density_pattern as eSmRaysDensity,
                    radius_x: aLightSourceSpatialData.radius_x,
                    radius_y: aLightSourceSpatialData.radius_y,
                    //common
                    count: 1,
                    count_analysis: this.mElements.count_analysis.value,
                }
                break;
        }

        return aSourceGeometricalData;
    }
    //__________________________________________________________________________________________
    private _setSourceTypeOptions(pIsUserDefined: boolean) {
        let aOptions: Array<iCustomSelectOption<eSmRaysKind>> = [
            {
                value: eSmRaysKind.POINT_SOURCE,
                text: 'Point source',
                data: eSmRaysKind.POINT_SOURCE,
                enable: true
            },
            {
                value: eSmRaysKind.PLANE_WAVE,
                text: 'Plane wave',
                data: eSmRaysKind.PLANE_WAVE,
                enable: true
            }
        ];

        if (true === pIsUserDefined) {
            aOptions.push({
                value: eSmRaysKind.GAUSSIAN_BEAM,
                text: 'Gaussian',
                data: eSmRaysKind.GAUSSIAN_BEAM,
                isPremiumOnly: true,
                enable: true
            })
        }

        this.mSourceType.setOptions(aOptions, eSmRaysKind.POINT_SOURCE, false);
    }
    //__________________________________________________________________________________________
    protected async _fillSection(pLightSourceSection: iLightSourceSection): Promise<void> {

        const aLightSourceData = pLightSourceSection.laserBehavior.laserData.lightSource;
        this.mSpatialInfo.fillSection({ lightSourceData: aLightSourceData });
        ///this.mArraySourcesSettingsSection.fillSection(aArrayOfSourcesData);
        //---------------------------------------------------------------------------------
        if (eSmRaysKind.GAUSSIAN_BEAM != aLightSourceData.kind) {
            this.mSpatialInfo.fillSection({ lightSourceData: aLightSourceData });
            this.mCountsComponent.chooseOption(aLightSourceData.count_type.toString(), false);
        }

        this.mCountsComponent.chooseOption(aLightSourceData.count_type.toString(), false);
        // geo data
        this._setGeometrySectionsVisibility();

        let aIsUserDefined = pLightSourceSection.laserBehavior.laserData.lightSource.isUserDefined;
        this._setSourceTypeOptions(aIsUserDefined);
        this._fillShape(aLightSourceData.kind, aLightSourceData.sourceGeometricalData, true);
        //---------------------------------------------------------------------------------
        let aSourceType = this.mSourceType.selectedOptionData;
        let aShowSpatialInfo = ((eSmRaysKind.PLANE_WAVE === aSourceType) ||
            (eSmRaysKind.POINT_SOURCE == aSourceType));
        if (true === aShowSpatialInfo) {
            this.mSpatialInfo.data = { lightSourceData: aLightSourceData };
        }
        this.mSpatialInfo.setVisibility(aShowSpatialInfo);
        //---------------------------------------------------------------------------------
        //directional data
        this.mAngularInformation.fillSection(aLightSourceData.directionalData);
        if (eSmRaysKind.GAUSSIAN_BEAM != aLightSourceData.kind) {
            //---------------------------------------------------------------------------------

            //polarization data
            this.mPolarizationSection.fillSection(aLightSourceData.polarization);
            //---------------------------------------------------------------------------------
        }

        // appearance data
        if (aLightSourceData.isUserDefined) {
            this.mAppearanceSection.data = {
                alpha: pLightSourceSection.alpha,
                model_radius: pLightSourceSection.model_radius,
                color: pLightSourceSection.color,
                rays_color: pLightSourceSection.rays_color
            };
        } else {
            this.mAppearanceSection.data = {
                rays_color: pLightSourceSection.rays_color
            };
        }


        this.mAppearanceSection.hideFieldsForNonUserDefined(aLightSourceData.isUserDefined)
        this.mAppearanceSection.setVisibility(true);   //aLightSourceData.isUserDefined
        //---------------------------------------------------------------------------------

        // powerdata
        this.mElements.power.value = aLightSourceData.power;

        let aWavelengthData = aLightSourceData.wavelengthData;
        let aDistributionData = aLightSourceData.distribution_data;
        let aHasWavelengthData = (null != aWavelengthData);
        if (true == aHasWavelengthData) {
            this.mWavelengthSection.data = {
                distribution_data: aDistributionData,
                wavelengthData: aWavelengthData
            }
        }

        this.mWavelengthSection.setVisibility(aHasWavelengthData);

        if (undefined !== aLightSourceData.gaussianBeam) {
            this.mGaussianBeam.fillSection({
                beam: aLightSourceData.gaussianBeam,
                part: pLightSourceSection.part
            });
        }

        //source data
        if (null != aLightSourceData.light_source_number_id) {
            let aLightSource = await SourcesDataLoader.instance.getSingleFullData({
                number_id: aLightSourceData.light_source_number_id
            });

            this.mName.innerHTML = aLightSource.name;
            this.mName.setAttribute("number_id", aLightSourceData.light_source_number_id);
            ViewUtils.setElementsEventsState(true, this.mName);

            //wavelength data
            //---------------------------------------------------------------------------------

        } else {

            ViewUtils.setElementsEventsState(false, this.mName, false);
            this.mName.innerText = piLightSourceSection.DEFAULT_LIGHT_SOURCE;
            this.mName.removeAttribute("number_id");
        }

        let aIsSingleSource = false;
        let aIsUserDefinedLaser = pLightSourceSection.isUserDefinedLaser;
        let aToShowLaser = !(aIsSingleSource || aIsUserDefinedLaser);

        ViewUtils.setElementVisibilityByDNone(this.mSearchBtn, aToShowLaser);
    }
    //__________________________________________________________________________________________
    private _fillCommonData(pGeoData: tRaysKind) {
        this.mElements.count_3d.value = pGeoData['count'];
        this.mElements.count_analysis.value = pGeoData['count_analysis'] as number;
    }
    //__________________________________________________________________________________________
    private _fillShape(pRaysKind: eSmRaysKind, pGeoData: tRaysKind, _pToUpdateSubtype: boolean) {
        if (null != pRaysKind) {
            this.mSourceType.setValue(pRaysKind, false);
        }

        this._setGeometrySectionsVisibility();

        switch (pRaysKind) {
            case eSmRaysKind.POINT_SOURCE:
                let aIsDeg = (eAngleUnit.DEG == parseInt(this.mPointSourceAngleUnit.value));
                let aFactor = aIsDeg ? 1 : MathContext.DEG_TO_RAD * 1000;
                this._fillCommonData(pGeoData);
                this.mElements.half_cone_x.value = pGeoData['angle_x'] * aFactor;
                this.mElements.half_cone_y.value = pGeoData['angle_y'] * aFactor;

                this.mElements.half_width_dist_z.value = (pGeoData as iPointSourceProfile).dist_z;
                this.mElements.half_width_dist_wx.value = (pGeoData as iPointSourceProfile).half_width_dist_wx;
                this.mElements.half_width_dist_wy.value = (pGeoData as iPointSourceProfile).half_width_dist_wy;

                ViewUtils.selectElementByValue(this.mDivergenceType, (pGeoData as iPointSourceProfile).divergence_type);
                break;
            case eSmRaysKind.PLANE_WAVE:
                this._fillCommonData(pGeoData);
                break;
            //     case eSmRaysKind.LASER_MASK:
            //         ui.ViewUtils.setElementVisibilityByDNone(this.mDeleteBtn,
            //             pGeoData['mask_data'] != null);
            //         this.mLaserMaskName.innerText = pGeoData['mask_name'] != null ?
            //             pGeoData['mask_name'] : "No Mask";
            //         this.mElements.size.value = pGeoData['size'];
            //         break;
            //     case eSmRaysKind.CIRCULAR_PROFILE:
            //         this.mElements.radius.value = pGeoData['radius'];
            //         this.mElements.count.value = pGeoData['count'];
            //         break;
            //     case eSmRaysKind.MATRIX_ILLUMINATION:
            //         this.mElements.count.value = pGeoData['count'];
            //         this.mElements.width.value = pGeoData["width"];
            //         this.mElements.height.value = pGeoData["height"];
            //         break;
            //     default:
            //         throw new Error("Not supported!");
        }

        this._setDivergenceSectionsVisibility();
    }
    //__________________________________________________________________________________________
}