
import { UnZip } from "../../../loaders/UnZip";
import { iAsyncCallback } from "../../_context/_interfaces/Interfaces";

export class OptReader {
    mFile: File;

    public readFile(pFile: File) {
        this.mFile = pFile;
        /// return FileUtils.loadFile(pFile);
        return this._readFileAsZip(this.mFile);
    }
    //__________________________________________________________________________________________
    private _readFileAsZip(pFile: File) {
        return new Promise<iAsyncCallback<string>>((resolve) => {
            new UnZip(pFile,
                (pContent: string) => resolve({ success: true, data: pContent }),
                () => { },
                () => {
                    resolve({ success: false, data: null });
                });
        });
    }
    //__________________________________________________________________________________________
}
