// import { eTreeIcons } from "../../../ui/home/SideBarListContext"
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { OptixPartDisplayer } from "../../_parts_assets/OptixPartDisplayer";
import { sliceName } from "../utils/BlackBoxUtils";
import { eTreeIcons } from "../../../ui/home/SideBarListContext";

export default function BlackBoxPartItem({ part, handePartsListChange }) {
  const deletePart = () => {
    handePartsListChange(part);
    OptixPartDisplayer.unHighlightObject(part);
  };
  return (
    <li
      data-tooltip-content={part.getLabel().label || part.iPart.name}
      data-tooltip-id="optic_name"
      className="d-flex justify-content-between"
    >
      <Tooltip id="optic_name" place="left" />
      <img src={eTreeIcons.OPTICS} />
      <div data-number_id={part.internalID}>{sliceName(part)}</div>
      <div onClick={deletePart}>
        <i className="icon-close"></i>
      </div>
    </li>
  );
}
