import { absPartsManager } from "../parts/absPartsManager";
import { Part } from "../parts/Part";

export class PartsManagerWidget extends absPartsManager {

    constructor() {
        super();
    }
    //__________________________________________________________________________________________
    public set mouseDownPart(pPart: Part) {
        this.mMouseDownPart = pPart;
    }
    //__________________________________________________________________________________________
}
