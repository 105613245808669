import { Float32BufferAttribute, Matrix4, Mesh, Object3D } from "three";
import { Op3dContext } from "../../_context/Op3dContext";
import { iRGB } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { UnitHandler } from "../../units/UnitsHandler";
import { ColorUtils } from "../../ui/ColorUtils";
import { iFacesMeshInfo } from "../PartInterfaces";

export class AxisObject3D extends Object3D {

    public static AXIS_NAME: string = 'op3d_axis';
    private mIsPart: boolean;
    public alwaysShow: boolean;

    //______________________________________________________________________________________________
    constructor(pObject: Object3D, pIsPart: boolean = false) {
        super();
        this.alwaysShow = pIsPart;
        this.mIsPart = pIsPart;
        this._create(pObject as Mesh);
        this.hide();
    }
    //______________________________________________________________________________________________
    public _clone(_object?: Object3D, _recursive?: boolean): Object3D {
        return new AxisObject3D(Op3dUtils.cloneObject3D(Op3dContext.AXIS_MODEL), this.mIsPart);
    }
    //______________________________________________________________________________________________
    private _create(pObject: Mesh) {
        if (pObject == null) return
        pObject.traverse((obj) => {
            if (obj instanceof Mesh) { obj.userData.isAxisMesh = true };
        });

        this._scale(pObject, (0.5 * UnitHandler.scale));
        this.add(pObject);
        this.name = AxisObject3D.AXIS_NAME;

        this.unHiglight();
    }
    //______________________________________________________________________________________________
    private _scale(pObject: Object3D, pScale: number) {
        let aScaleMat = new Matrix4().makeScale(pScale, pScale, pScale)
        pObject.applyMatrix4(aScaleMat);
    }
    //______________________________________________________________________________________________
    private _paintAxisModel(pFacesMeshInfo: Array<iFacesMeshInfo>, aColor: iRGB) {
        let aSolidColors = [
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [0, 0, 1], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 0.5 },

            { color: [aColor.r, aColor.g, aColor.b], opacity: 0.5 },
            { color: [1, 0, 0], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 0.5 },

            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },

            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 },
            { color: [0, 1, 0], opacity: 1 },
            { color: [aColor.r, aColor.g, aColor.b], opacity: 1 }
        ];

        let aColors = new Array<number>();

        for (let i = 0; i < pFacesMeshInfo.length; i++) {
            let aStart = pFacesMeshInfo[i].indexes.start;
            let aEnd = pFacesMeshInfo[i].indexes.end;
            let aPath = pFacesMeshInfo[i].path;
            for (let c = aStart; c <= aEnd; c++) {
                aColors[(4 * c)] = aSolidColors[aPath[1]].color[0];
                aColors[(4 * c) + 1] = aSolidColors[aPath[1]].color[1];
                aColors[(4 * c) + 2] = aSolidColors[aPath[1]].color[2];
                aColors[(4 * c) + 3] = aSolidColors[aPath[1]].opacity;
            }
        }

        (this.children[0] as Mesh).geometry.setAttribute('color',
            new Float32BufferAttribute(aColors, 4));
    }
    //______________________________________________________________________________________________
    public higlight() {
        let aColor = ColorUtils.hexToNormalizedRGB(0xFFA80F);
        this._paintAxisModel(this.children[0].userData.facesMesh, aColor);
    }
    //______________________________________________________________________________________________
    public unHiglight() {
        let aOppositeColor = (0xFFFFFF - Op3dContext.USER_VO.simulationSettings.sceneBGColor);
        let aColor = ColorUtils.hexToNormalizedRGB(aOppositeColor);
        this._paintAxisModel(this.children[0].userData.facesMesh, aColor);

        (this.children[0] as Mesh).material = Op3dContext.GLOBAL_MATERIAL
    }
    //______________________________________________________________________________________________
    public show() {
        this.visible = true;
    }
    //______________________________________________________________________________________________
    public hide(pForce: boolean = false) {
        if ((false == pForce) && (true == this.alwaysShow)) {
            return;
        }

        this.visible = false;
    }
    //______________________________________________________________________________________________
    public setVisibility(pToShow: boolean, pForce: boolean = false) {
        if (true == pToShow) {
            this.show();
        } else {
            this.hide(pForce);
        }
    }
    //______________________________________________________________________________________________
};

export class Axis {
    public static AXIS_LCS_NAME: string = 'op3d_axis';

    constructor() {
    }
    //______________________________________________________________________________________________

    public getAxisModel(pIsPart: boolean) {
        let aModel = new AxisObject3D(Op3dUtils.cloneObject3D(Op3dContext.AXIS_MODEL), pIsPart);
        return aModel;
    }
    //______________________________________________________________________________________________
}