import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../Part";
import { iBehaviorsJSON, iCageBehaviorJSONObject } from "../_parts_assets/ExportToJSONInterfaces";
import { Behavior } from "./Behavior";
import { MovementBehavior } from "./MovementBehavior";
import { SceneContext } from "../../scene/SceneContext";
import { Vector3, Euler, Box3 } from "three";
import { iPart } from "../PartInterfaces";
import { eAxisType } from "../../_context/Enums";

export class CageBehavior extends Behavior {

    public static CAGE_STEPS: Array<{ length: number, pn: string }> = [
        { length: 0.25, pn: "ER025" },
        { length: 0.5, pn: "ER05" },
        { length: 1, pn: "ER1" },
        { length: 1.5, pn: "ER1.5" },
        { length: 2, pn: "ER2" },
        { length: 3, pn: "ER3" },
        { length: 4, pn: "ER4" },
        { length: 6, pn: "ER6" },
        { length: 8, pn: "ER8" },
        { length: 10, pn: "ER10" },
        { length: 12, pn: "ER12" },
        { length: 18, pn: "ER18" },
        { length: 24, pn: "ER24" },

    ];


    public static CAGE_CONTAINER_NAME: string = 'rod';

    public static CAGE_SIZE_STEP: number = 25.4; // mm 
    public static MAX_CAGE_SIZE: number = 635; // mm
    public static MAX_CAGE_DELTA: number = 1;

    //__________________________________________________________________________________________
    public static getAllRods(pPart: Part) {
        return pPart.getSubpartsByName(CageBehavior.CAGE_CONTAINER_NAME);
    }
    //__________________________________________________________________________________________
    public static hideAllRods(pRods: Array<iPart>) {
        pRods.forEach(rod => rod.object3D.visible = false)
    }
    //__________________________________________________________________________________________
    public static showAllRods(pRods: Array<iPart>) {
        pRods.forEach(rod => rod.object3D.visible = true)
    }
    //__________________________________________________________________________________________
    public static init(pPart: Part) {
        let aCageParts = CageBehavior.getAllRods(pPart)

        if (null == aCageParts || pPart.id == 'rod') return

        CageBehavior.hideAllRods(aCageParts)
    }
    //__________________________________________________________________________________________
    public static isConnectedToCage(pPart: Part) {
        let aIsConnectedToCage = ((null != pPart.refCS) && (true == pPart.refCS.isCage));
        return aIsConnectedToCage;

    }
    //__________________________________________________________________________________________
    public static distToAnchor(pPart: Part) {
        let aDistToAnchor = MovementBehavior.distToRef(pPart).z;
        return (aDistToAnchor * Math.sign(aDistToAnchor));
    }
    //__________________________________________________________________________________________
    public static updatePosOnCage(pPart: Part, pPos: number) {
        if (pPos > CageBehavior.getCageLength(pPart.refCS.refPart)) {
            return;
        }

        let aDistToAnchor = MovementBehavior.distToRef(pPart).z;
        pPos *= Math.sign(aDistToAnchor);
        MovementBehavior.transformPart(pPart, new Vector3(0, 0, pPos), new Euler());

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public static getCageLength(pPart: Part) {
        let aRods = CageBehavior.getAllRods(pPart)

        let aCageLength = aRods[0]?.facesMesh.scale.z > 1 ?
            aRods[0].facesMesh.scale.z : 0;

        return aCageLength;
    }
    //__________________________________________________________________________________________
    public static async updateCageSize(pPart: Part, pSizeMM: number) {
        let aCageRods = CageBehavior.getAllRods(pPart)
        if (pSizeMM == 0) {
            CageBehavior.hideAllRods(aCageRods)
        } else {
            CageBehavior.showAllRods(aCageRods)
        }
        aCageRods.forEach(rod => rod.facesMesh.scale.setZ(pSizeMM))
        aCageRods.forEach(rod => rod.shapes[0].edgesMesh.scale.setZ(pSizeMM))
    }
    //__________________________________________________________________________________________
    public static checkConnectionWith(pAnchor: Part, pTestingPart: Part) {
        if (CageBehavior.getCageLength(pTestingPart) > 0) {
            return;
        }

        let aPartCageAxis = pTestingPart.getAxes().find((axis => (eAxisType.CAGE === axis.type)));
        if (undefined === aPartCageAxis) {
            return;
        }

        let aAnchorCageAxis = pAnchor.getAxes().find((axis => (eAxisType.CAGE === axis.type)));
        if (undefined === aAnchorCageAxis) {
            return;
        }

        let aAnchorBox = new Box3().setFromObject(pTestingPart.visibleObj);
        let aPartBox = new Box3().setFromObject(pAnchor.visibleObj);

        let aHasIntersection = aAnchorBox.intersectsBox(aPartBox);

        if (true === aHasIntersection) {
            if (!this.isTheSameCageSize(pAnchor, pTestingPart)) {
                return;
            }

            let aDistFromAnchor = MovementBehavior.distBetweenAxes(aPartCageAxis,
                aAnchorCageAxis).z;
            let aRodsLength = CageBehavior.getCageLength(pAnchor);
            if (Math.abs(aDistFromAnchor) < aRodsLength) {
                CageBehavior._connect(pAnchor, pTestingPart);
            } else {
                this._disconnect(pAnchor, pTestingPart);
            }
        } else {
            this._disconnect(pAnchor, pTestingPart);
        }
    }
    //__________________________________________________________________________________________
    private static isTheSameCageSize(pAncor: Part, pTestingPart: Part) {
        let aAncorSize = this.getCageToAxesSize(pAncor)
        let aPartSize = this.getCageToAxesSize(pTestingPart)

        if (Math.abs((aAncorSize - aPartSize)) > CageBehavior.MAX_CAGE_DELTA) {
            return false
        }

        return true
    }
    //__________________________________________________________________________________________
    private static getCageToAxesSize(pPart: Part) {
        let aRods = CageBehavior.getAllRods(pPart)

        let aDist = aRods[0].object3D.position.clone()
        aDist.z = 0
        aDist.y = 0
        return aDist.length()
    }
    //__________________________________________________________________________________________
    public static exportToJSON(pPart: Part, pJSONData: iBehaviorsJSON): void {
        pJSONData.cageBehavior = {
            length: CageBehavior.getCageLength(pPart)
        }
    }
    //__________________________________________________________________________________________
    public static async initFromJSON(pPart: Part, pJSONData: iCageBehaviorJSONObject) {
        if (Object.keys(pJSONData).length === 0) {
            CageBehavior.updateCageSize(pPart, 0);
            return
        }
        CageBehavior.updateCageSize(pPart, pJSONData.cageBehavior?.length || 0);
    }
    //__________________________________________________________________________________________  
    public static _disconnect(pAnchor: Part, pPartToDisconnect: Part) {
        let aAnchorCage = pAnchor.getAxes().find(axis => (eAxisType.CAGE === axis.type));
        let aPartCage = pPartToDisconnect.getAxes().find(axis => (eAxisType.CAGE === axis.type));

        if ((null == pPartToDisconnect.refCS) ||
            (pPartToDisconnect.refCS.cs.object3D != aAnchorCage.object3D)) {
            return;
        }

        pPartToDisconnect.clearRef();
        pPartToDisconnect.setWorkingCS(aPartCage);
        Op3dContext.PARTS_MANAGER.updatePartsList();
        Op3dContext.PART_INFO.update();
    }
    //__________________________________________________________________________________________
    public static _connect(pAnchor: Part, pPartToConnect: Part) {
        /**Set cage axes as LCS of part to connect */

        let aPartCage = pPartToConnect.getAxes().find(axis => (eAxisType.CAGE === axis.type));
        let aAnchorCage = pAnchor.getAxes().find(axis => (eAxisType.CAGE === axis.type));
        pPartToConnect.setWorkingCS(aPartCage, false);
        /**Set anchor cage as ref of the part on the cage */
        pPartToConnect.setRefrence({
            cs: aAnchorCage,
            refPart: pAnchor,
            isCage: true
        });

        let aZ = MovementBehavior.distToRef(pPartToConnect).z;

        let aPos = new Vector3(0, 0, aZ);
        let aRot = new Euler();
        MovementBehavior.transformPart(pPartToConnect, aPos, aRot);
        Op3dContext.PART_INFO.update();
    }
    //__________________________________________________________________________________________
}
