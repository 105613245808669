import { eUnitType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { iDetectorData, iDetectorAppearanceData, iDetectorSizeData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { SceneContext } from "../../scene/SceneContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";
import { piDetectorSection } from "./piDetectorSection";

export class piDetectorSizeSection extends PartInfoSection<iDetectorData>{
    public static MAX_DETECTOR_RESOLUTION = 10000 * 10000
    public static MIN_DETECTOR_RESOLUTION = 1;
    private mElements: iHash<NumberInputElement>;
    private mOnChange: (pUpdateVisualization: boolean) => void;
    private mTransparency: HTMLInputElement;

    constructor(pContainer: HTMLElement, pOnChange: (pUpdateVisualization: boolean) => void) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_detector_size_section.html'
        });

        this.mOnChange = pOnChange;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        let aMin = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 1e-4 : 0.0002;
        let aMax = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 200 : 8;
        let aStep = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 0.5 : 0.01;
        let aDefValue = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 10 : 0.4;

        this.mTransparency = this._getPart("detector-transparency") as HTMLInputElement;
        this.mTransparency.addEventListener("change", () => this._onDetectorTransparencyChanged());

        this.mElements = {
            half_width: piDetectorSection.getNumberInputElement({
                container: this._getPart("detector-half-width", true),
                range: { min: aMin, max: aMax },
                step: aStep,
                unitName: UnitHandler.shortSign,
                defaultValue: aDefValue,
                worldToUnit: 1,
                callback: () => this._onSizeChanged(true)
            }),
            half_height: piDetectorSection.getNumberInputElement({
                container: this._getPart("detector-half-height", true),
                range: { min: aMin, max: aMax },
                step: aStep,
                unitName: UnitHandler.shortSign,
                defaultValue: aDefValue,
                worldToUnit: 1,
                callback: () => this._onSizeChanged(true)
            })
        }
    }
    //__________________________________________________________________________________________
    private _onDetectorTransparencyChanged() {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if (aPart == null) { return; }

        Op3dContext.SCENE_HISTORY.addToHistory();
        this._onSizeChanged(true);
        SceneContext.OP3D_SCENE.activateRenderer();
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    public fillSection(pData: iDetectorData): void {
        this._fillSection(pData);
    }
    //__________________________________________________________________________________________
    private _onSizeChanged(pUpdateVisualization: boolean) {
        this.mOnChange(pUpdateVisualization);
    }
    //__________________________________________________________________________________________
    public getApperanceData(): iDetectorAppearanceData {
        let aData: iDetectorAppearanceData = {
            opacity: parseFloat(this.mTransparency.value) / 100,
            color: {
                frame: 0x000000,
                surface: 0xffffff
            },
        };

        return aData;
    }
    //__________________________________________________________________________________________
    public getSizeData(): iDetectorSizeData {
        let aScale = UnitHandler.presentedScale;
        let aData: iDetectorSizeData = {
            height: ((this.mElements["half_height"].value * 2) / aScale),
            width: ((this.mElements["half_width"].value * 2) / aScale),
        };

        return aData;
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iDetectorData): void {
        let aScale = UnitHandler.presentedScale;

        this.mElements["half_width"].value = pData.size.width * aScale / 2;
        this.mElements["half_height"].value = pData.size.height * aScale / 2;
        this.mTransparency.value = (pData.appearance.opacity * 100).toString();
    }
    //__________________________________________________________________________________________
}
