import { iOP3DHTMLInputElement, iClientPoint, iMinMax } from "../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { ViewUtils } from "../ViewUtils";
import { CustomSelect, iCustomSelectOption } from "../components/CustomSelect";

export interface iCalcSettingsParams {
    settings: iCalcSettings;
}

export enum eCalcUnitType {
    LENGHT,
    ANGLE
}

export interface iCalcUnitSettings {
    sign: string;
    scale?: number;
    type?: eCalcUnitType;

    isDefault?: boolean;
}

export interface iCalcSettings {
    decimalPrecision: number;
    lenghtUnits?: iCalcUnitSettings;
    angleUnits?: iCalcUnitSettings;
}

export interface iCalcSettingsCreationParams {
    lenghtUnitsSettings?: Array<iCalcUnitSettings>;
    angleUnitsSettings?: Array<iCalcUnitSettings>;
    callback: (pCalcSettings: iCalcSettings) => any;
}

export class FloatingCalcSettings {

    private mContainer: HTMLElement;

    private mCallback: (pCalcSettings: iCalcSettings) => any;

    private mDecimalPrecisionInput: iOP3DHTMLInputElement;
    private mLenghtUnitSelect: CustomSelect;
    private mAngleUnitSelect: CustomSelect;

    //__________________________________________________________________________________________
    constructor(pParams: iCalcSettingsCreationParams) {
        this._create(pParams);
        this.mCallback = pParams.callback;
    }
    //__________________________________________________________________________________________
    public open(e: iClientPoint, pCalcSettings: iCalcSettings) {
        this.mContainer.style.left = e.clientX + 'px';
        this.mContainer.style.top = e.clientY + 'px';

        //this.mCalcSettings = pCalcSettings;
        this._fill(pCalcSettings);

        this._addCloseListener();
        document.body.appendChild(this.mContainer);
    }
    //__________________________________________________________________________________________
    private _addCloseListener() {
        let aCloseFunc = () => {
            this.close();
            window.removeEventListener('mousedown', aCloseFunc);
        }
        window.addEventListener('mousedown', aCloseFunc);
    }
    //__________________________________________________________________________________________
    public close() {
        ViewUtils.removeFromParent(this.mContainer);
    }
    //__________________________________________________________________________________________
    private _fill(pSettings: iCalcSettings) {
        this.mDecimalPrecisionInput.value = pSettings.decimalPrecision.toString();
        this.mDecimalPrecisionInput.prevValue = this.mDecimalPrecisionInput.value;
        this.mDecimalPrecisionInput.actualValue = pSettings.decimalPrecision;

        this.mLenghtUnitSelect.setValue(pSettings.lenghtUnits.sign, false);
    }
    //__________________________________________________________________________________________
    private _create(pParams: iCalcSettingsCreationParams) {
        this.mContainer = document.createElement('div');
        this.mContainer.classList.add('calc_settings_form');
        this.mContainer.addEventListener('mousedown', (e) => e.stopPropagation());

        let aDecimalPrecisionDiv = document.createElement('div');
        aDecimalPrecisionDiv.classList.add('clac_settings_elem');
        this.mContainer.appendChild(aDecimalPrecisionDiv);

        let aDecimalPrecisionTitle = document.createElement('div');
        aDecimalPrecisionTitle.classList.add('elem_settings_title')
        aDecimalPrecisionTitle.innerHTML = 'Decimal precision'
        aDecimalPrecisionDiv.appendChild(aDecimalPrecisionTitle);

        let aInputDiv = document.createElement('div');
        aInputDiv.classList.add('input_cot');
        aInputDiv.classList.add('w_in');
        aInputDiv.classList.add('stat');
        aInputDiv.style.width = '30px';
        aDecimalPrecisionDiv.appendChild(aInputDiv);

        this.mDecimalPrecisionInput = document.createElement('input') as iOP3DHTMLInputElement;
        aInputDiv.appendChild(this.mDecimalPrecisionInput);
        this._addInputListeners(this.mDecimalPrecisionInput, { min: 0, max: 10 }, true);

        if (null != pParams.lenghtUnitsSettings) {
            this.mLenghtUnitSelect = this._addUnitDropdown(pParams.lenghtUnitsSettings,
                'Lenght unit');
        }

        if (null != pParams.angleUnitsSettings) {
            this.mAngleUnitSelect = this._addUnitDropdown(pParams.angleUnitsSettings, 'Angle unit');
        }
    }
    //__________________________________________________________________________________________
    private _addUnitDropdown(pData: Array<iCalcUnitSettings>, pName: string) {
        let aUnitDiv = document.createElement('div');
        aUnitDiv.classList.add('clac_settings_elem');
        this.mContainer.appendChild(aUnitDiv);

        let aUnitTitle = document.createElement('div');
        aUnitTitle.classList.add('elem_settings_title');
        aUnitTitle.innerHTML = pName;
        aUnitDiv.appendChild(aUnitTitle);

        let aSelectDiv = document.createElement('div');
        aUnitDiv.appendChild(aSelectDiv);

        let aDefaultOption = null;

        let aOptions = new Array<iCustomSelectOption<iCalcUnitSettings>>();
        let aUnitsSettings = pData;
        for (let i = 0; i < aUnitsSettings.length; i++) {
            let aOption: iCustomSelectOption<iCalcUnitSettings> = {
                value: aUnitsSettings[i].sign,
                text: aUnitsSettings[i].sign,
                data: aUnitsSettings[i],
                enable: true
            }

            if (true == aUnitsSettings[i].isDefault) {
                aDefaultOption = aUnitsSettings[i].sign;
            }

            aOptions.push(aOption);
        }

        let aUnitSelect = new CustomSelect<iCalcUnitSettings>(aSelectDiv, {
            onChange: () => this._onChange(),
            staticPostion: true,
            width: 70
        });
        aUnitSelect.setOptions(aOptions, aDefaultOption, false);

        return aUnitSelect;
    }
    //__________________________________________________________________________________________
    private _addInputListeners(pInput: iOP3DHTMLInputElement, pRange: iMinMax,
        pIsInt: boolean) {
        pInput.addEventListener('change', () => {
            let aVal = (true == pIsInt) ? parseInt(pInput.value) : parseFloat(pInput.value);
            if (true == isNaN(aVal)) {
                pInput.value = pInput.prevValue;
                return;
            }

            aVal = OP3DMathUtils.clampValue(aVal, pRange.min, pRange.max);

            pInput.value = aVal.toString();
            if (pInput.value == pInput.prevValue) {
                return;
            }

            pInput.prevValue = pInput.value;
            pInput.actualValue = aVal;

            this._onChange();
        });
    }
    //__________________________________________________________________________________________
    private _onChange() {
        let aCalcSettings: iCalcSettings = {
            decimalPrecision: this.mDecimalPrecisionInput.actualValue
        };

        if (null != this.mLenghtUnitSelect) {
            aCalcSettings.lenghtUnits = this.mLenghtUnitSelect.selectedOptionData;
        }

        if (null != this.mAngleUnitSelect) {
            aCalcSettings.angleUnits = this.mAngleUnitSelect.selectedOptionData;
        }

        this.mCallback(aCalcSettings);
    }
    //__________________________________________________________________________________________
}