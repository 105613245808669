import { Op3dContext } from "../_context/Op3dContext";
import { iHash } from "../_context/_interfaces/Interfaces";
import { FileUtils } from "../_utils/FileUtils";
import { Popup } from "../ui/forms/Popup";
import { ProgressBar } from "../ui/tools/ProggressBar";

export class CoatingConvert {
    private mFileList: FileList;
    private mLogger = new Array<string>();


    constructor(pMapFile: File, pFileList: FileList) {
        this.mFileList = pFileList;
        this._loadMap(pMapFile);
    }
    //__________________________________________________________________________________________
    private async _loadMap(pMapFile: File) {
        let aMapContent = await FileUtils.loadFileText(pMapFile);
        if (aMapContent.success == false) {
            Popup.instance.open({ text: "map file loading failed" });
            return;
        }



        await this._onLoadMap(aMapContent.data)

        // let aReader = new FileReader();
        // aReader.onload = () => this._onLoadMap((aReader.result as string));
        // aReader.onerror = () => this._onError(pMapFile.name, 'Error loading map file');
        // aReader.readAsBinaryString(pMapFile);
    }
    //__________________________________________________________________________________________
    private async _onLoadMap(pData: string) {

        var workbook = XLSX.read(pData, {
            type: 'binary', sheetStubs: true
        });

        let aSheetName = workbook.SheetNames[0];
        let aRows = XLSX.utils.sheet_to_json<iHash<string>>(workbook.Sheets[aSheetName], {
            blankrows: true,
        });

        await this._convert(aRows);

        if (this.mLogger.length == 0) {
            Popup.instance.open({ text: "Action completed with no errors" });

        } else {

            Popup.instance.open({
                text: "Errors: " + this.mLogger.length + "/" + this.mFileList.length + " total files\n" +
                    "for more details go to console"
            });

            for (let i = 0; i < this.mLogger.length; i++) {
                Op3dContext.USER_VO.isEmployeeUser && console.log(this.mLogger[i] + "\n");
            }
        }
    }
    //__________________________________________________________________________________________
    private _addMsg(pName: string, pIsSuccess: boolean, pMsg: string) {
        if (pIsSuccess) {

        } else {
            this.mLogger.push("Failed: [" + pName + "] " + pMsg);
        }

    }
    //__________________________________________________________________________________________
    private getLegalName(pFileMap: Array<iHash<string>>, pFileName: string) {
        let aName = pFileName.replace('.csv', '');
        let aIndex = pFileMap.findIndex(e => e.name === aName);
        if (aIndex !== -1) {
            let aNumberId = pFileMap[aIndex]['id number'];
            return aName + '[' + aNumberId + '].csv';
        }
        return null;
    }
    //__________________________________________________________________________________________
    private convertData(pData: string, _pFileName: string) {
        try {
            let aFirstRow = pData.split("\n")[0];

            let aHasTitles = aFirstRow.toLowerCase().indexOf("wl") != -1 ||
                aFirstRow.toLowerCase().indexOf(("wl").toLowerCase()) != -1 ||
                aFirstRow.toLowerCase().indexOf(("WaveLength (nm)").toLowerCase()) != -1 ||
                aFirstRow.toLowerCase().indexOf(("Wavelength(nm)").toLowerCase()) != -1 ||
                aFirstRow.toLowerCase().indexOf(("WaveLength").toLowerCase()) != -1 ||
                aFirstRow.toLowerCase().indexOf(("Wavelength [nm]").toLowerCase()) != -1 ||
                aFirstRow.toLowerCase().indexOf(('WaveLength[nm]').toLowerCase()) != -1 ||
                aFirstRow.toLowerCase().indexOf(('WL[nm]').toLowerCase()) != -1;

            if (aHasTitles) {
                const aConversionParams = [
                    {
                        to: 'wl',
                        from: [
                            'wl',
                            'Wavelength(nm)',
                            'WaveLength (nm)',
                            "WaveLength",
                            "Wavelength [nm]",
                            'WL[nm]',
                            'WaveLength[nm]',]
                    },
                    {
                        to: 't_p',
                        from: ['t_p', 'T P-Pol', 'T', 'Tp', 'T[P]']
                    },
                    {
                        to: 'r_p',
                        from: ['r_p', 'R T-Pol', 'R', 'Reflection', 'Rp', 'R[P]']
                    },
                    {
                        to: 't_s',
                        from: ['t_s', 'T S-Pol', 'T', 'Ts', 'T[S]']
                    },
                    {
                        to: 'r_s',
                        from: ['r_s', 'R', 'R S-Pol', 'Reflection', 'Rs', 'R[S]']
                    }
                ]

                //let aChangedTitles = 0;
                let aCsvHeaderEndIndex = pData.indexOf('\n');
                if (aCsvHeaderEndIndex !== -1) {
                    let aHeader = pData.substring(0, aCsvHeaderEndIndex);
                    let aContent = pData.substring(aCsvHeaderEndIndex);
                    let aArr = aHeader.split(',');
                    if (aArr.length >= aConversionParams.length) {
                        aConversionParams.forEach((pConversionParam, pIndex) => {
                            pConversionParam.from.forEach(pFromKey => {
                                if (aArr[pIndex].toLowerCase().trim() === pFromKey.toLowerCase().trim()) {
                                    aArr[pIndex] = pConversionParam.to;
                                    //aChangedTitles++;
                                }
                            })
                        });
                        aHeader = aArr.join(',');
                        pData = aHeader + aContent;
                    }
                    // if (aChangedTitles < 5) {
                    //     this._addMsg(pFileName, false, "error parsing titles");
                    //     return null;
                    // }
                }


            } else {
                const aFirstRow = "wl,t_p,r_p,t_s,r_s\n";
                pData = aFirstRow + pData;
            }
            return pData;

        } catch (e) {

            return null;
        }
    }
    //__________________________________________________________________________________________
    private async _downloadFile(pFile: File, pFileName: string) {

        let aFile = await FileUtils.loadFileText(pFile);
        if (aFile.success) {
            let aRes = this.convertData(aFile.data, pFileName);
            await Op3dContext.sleep(50);
            if (aRes != null) {
                FileUtils.downloadFile({
                    content: aRes,
                    fullfileName: pFileName,
                    mimeType: 'text/csv'
                });
            } else {
                this._addMsg(pFileName, false, "error convert file");

            }

        } else {
            this._addMsg(pFileName, false, "error load file");
        }

        // let aReader = new FileReader();
        // aReader.onload = () => {
        //     let aData = this.convertData(aReader.result as string, pFileName);
        //     if (aData != null) {
        //         FileUtils.downloadFile({
        //             content: aData,
        //             fullfileName: pFileName,
        //             mimeType: 'text/csv'
        //         });
        //     }
        // },
        //     aReader.onerror = () => this._onError('Error reading file', pFileName);
        // aReader.readAsBinaryString(pFile);
    }
    //__________________________________________________________________________________________
    private async _convert(pFileMap: Array<iHash<string>>) {

        ProgressBar.instance.open({
            title: 'Uploading...',
            min: 0,
            max: (this.mFileList.length - 1),
            closeOnFinish: true,
            //subtitle: '<strong>File name: <strong>' + this.mParserParams.file.name
        });


        for (let i = 0; i < this.mFileList.length; i++) {
            let aOriginalFileName = this.mFileList[i].name;
            if (aOriginalFileName.indexOf("[") != -1 && aOriginalFileName.indexOf("]") != -1) {
                this._addMsg(aOriginalFileName, false, " already have an ID");
                ProgressBar.instance.increment();
                continue;
            }

            let aFileName = this.getLegalName(pFileMap, aOriginalFileName);
            if (aFileName == null) {
                this._addMsg(aOriginalFileName, false, "this file name not found in map");
                ProgressBar.instance.increment();
                continue;
            }

            await this._downloadFile(this.mFileList[i], aFileName);
            ProgressBar.instance.increment();
            await Op3dContext.sleep(50);

        }

        ProgressBar.instance.close();
    }
    //_____________________________________________________________________
}
