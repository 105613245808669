import { iPoint2D, iMinMax, iSize } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { UnitHandler } from "../../../units/UnitsHandler";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisCanvasUtils } from "../AnalysisCanvasUtils";
import { iGraphUIParams, eAnalysisDisplay, AnalysisContext, iBasicGraphDataParams } from "../AnalysisContext";
import { anAnalysisResultyFactory, iDrawData, iZoomData } from "./anAnalysisResultyFactory";
import { anSmallResultFactory, iCreateThumbnailAnalysisOptions } from "./anSmallResultFactory";

export interface iCreateExtendedAnalysisOptions extends iCreateThumbnailAnalysisOptions {


    onSelectionZoom: (pZoomData: iZoomData) => void,
    zoomData?: iZoomData

}
export class anExtendedResultFactory extends anAnalysisResultyFactory {

    private mMouseDown: iPoint2D;
    private mRectContext: CanvasRenderingContext2D;
    private mMouseUpCallback: (e: MouseEvent) => void;

    constructor() {
        super()
    }

    //__________________________________________________________________________________________
    public createResult(pOptions: iCreateExtendedAnalysisOptions): iDrawData {

        switch (pOptions.graphData.analysisItem.display) {
            case eAnalysisDisplay.FALSE_COLOR:
            case eAnalysisDisplay.GRAY_SCALE:
            case eAnalysisDisplay.SPECTRAL_VIEW:
                return this._createSpot(pOptions);
        }
    }
    //__________________________________________________________________________________________
    private _addSpotControls(pOptions: {
        context: CanvasRenderingContext2D,
        uiParams: iGraphUIParams,
        totalSize: iSize,
        onSelectionZoom: (pZoomData: iZoomData) => void,
    }) {


        let aAttribure = pOptions.context.canvas.getAttribute("initialized");
        if (aAttribure == null) {
            pOptions.context.canvas.setAttribute("initialized", "initialized");

            pOptions.context.canvas.addEventListener("mousedown",
                (e: MouseEvent) => this._onContextMouseDown(e, pOptions));

            pOptions.context.canvas.addEventListener("mousemove",
                (e: MouseEvent) => this._onContextMouseMove(e));
        }
    }
    //__________________________________________________________________________________________
    private _onDeleteRect() {
        this.mMouseDown = null;
        try {
            ViewUtils.removeFromParent(this.mRectContext.canvas);
        } catch (e) {
            /**
             * do nothing
             * use case where we move one extended view and then leave it when we are in top of another one
             */
        }
    }
    //__________________________________________________________________________________________
    private _onContextMouseMove(e: MouseEvent) {
        if (this.mMouseDown == null) {
            return;
        }

        AnalysisCanvasUtils.drawRectangleOnContext(e, this.mMouseDown, this.mRectContext);
    }
    //__________________________________________________________________________________________
    private _removeMouseUpEvent(pContext: CanvasRenderingContext2D) {
        pContext.canvas.removeEventListener('mouseup', this.mMouseUpCallback)
    }
    //__________________________________________________________________________________________
    private _onContextMouseDown(e: MouseEvent, pOptions: {
        context: CanvasRenderingContext2D,
        uiParams: iGraphUIParams,
        totalSize: iSize,
        onSelectionZoom?: (pZoomData: iZoomData) => void,
    }) {

        if (this.mMouseUpCallback != null) {
            this._removeMouseUpEvent(pOptions.context);
        }

        this.mMouseUpCallback = (e: MouseEvent) => this._onContextMouseUp(e,
            pOptions.context,
            pOptions.onSelectionZoom,
            pOptions.totalSize);

        pOptions.context.canvas.addEventListener("mouseup", this.mMouseUpCallback);

        let aOffset = $(e.target).offset();
        let aX = e.clientX - aOffset.left;
        let aY = e.clientY - aOffset.top;
        this.mMouseDown = { x: aX, y: aY };
        let aResult = AnalysisCanvasUtils.initCanvas({
            container: pOptions.context.canvas.parentElement,
            fitToContainer: true,
            isAdditionalCanvas: true,
            isTextual: false
        });
        aResult.ctx.canvas.style.position = "absolute";
        aResult.ctx.canvas.style.left = '0';
        aResult.ctx.canvas.style.top = '0';
        aResult.ctx.canvas.style.pointerEvents = "none";
        this.mRectContext = aResult.ctx;

        this._addPoint(this.mMouseDown)
    }
    //__________________________________________________________________________________________
    private _addPoint(pPoint: iPoint2D) {
        this.mRectContext.beginPath();
        this.mRectContext.arc(pPoint.x, pPoint.y, 0.5, 0, 2 * Math.PI, true);
        this.mRectContext.stroke();
    }
    //__________________________________________________________________________________________
    private _onContextMouseUp(e: MouseEvent,
        pContext: CanvasRenderingContext2D,
        pCallBack: (pData: iZoomData) => void, pTotalSize: iSize) {

        this._removeMouseUpEvent(pContext);
        let aData = AnalysisCanvasUtils.drawRectangleOnContext(e, this.mMouseDown, this.mRectContext);
        this._onDeleteRect();

        if (aData == null || aData.width < 1 || aData.height < 1) {
            return;
        }

        e.stopImmediatePropagation()
        e.preventDefault();
        e.stopPropagation();

        let aStartPoint: iPoint2D = {
            x: Math.max(0, aData.startPoint.x),
            y: Math.max(0, aData.startPoint.y)
        }


        let aDataSelection: iZoomData = {
            rectSize: { width: aData.width, height: aData.height },
            startPoint: aStartPoint,
            totalSize: pTotalSize,
        }

        pCallBack(aDataSelection);
    }
    //__________________________________________________________________________________________
    private _createSpot(pOptions: iCreateExtendedAnalysisOptions): iDrawData {

        this._createGradientBar(pOptions.container,
            pOptions.graphData,
            pOptions.uiParams);

        const aDrawResult = this._drawSpot(pOptions);

        AnalysisCanvasUtils.addAxisLabels(pOptions);

        this._addSpotControls({
            context: aDrawResult.context,
            uiParams: pOptions.uiParams,
            totalSize: aDrawResult.totalSize,
            onSelectionZoom: pOptions.onSelectionZoom
        });
        return aDrawResult;
    }
    //__________________________________________________________________________________________
    /**
     * @param pOptions draw the graph canvas image on the main canvas
     */
    //__________________________________________________________________________________________
    private _addRegularImage(pOptions: {
        ctx: CanvasRenderingContext2D,
        graphContainerSize: iSize,
        actualSurfaceSize?: iSize,
        start: iPoint2D,
        canvas: HTMLCanvasElement,
        sourceSize: iSize,
        fitToContainer?: boolean
        keepProportion?: boolean
    }) {

        /**
         * how much from the image we will copy
         */
        let aSourceSize: iSize = {
            width: pOptions.sourceSize.width,
            height: pOptions.sourceSize.height
        }

        let aMaxSize = Math.max(aSourceSize.width, aSourceSize.height);
        let aMinGraphSize = Math.min(pOptions.graphContainerSize.width, pOptions.graphContainerSize.height);
        let aRatio = aMinGraphSize / aMaxSize;
        let aNewSourceSize: iSize = pOptions.sourceSize;
        let aNewStart: iPoint2D = pOptions.start;

        /**
         * the image should fit to the given container
         */

        const aStartClipImage: iPoint2D = { x: 0, y: 0 };

        // keep proportion
        if (pOptions.keepProportion == true) {

            let aRatio2 = aMinGraphSize / Math.max(pOptions.actualSurfaceSize.width, pOptions.actualSurfaceSize.height);

            aNewSourceSize = {
                width: pOptions.actualSurfaceSize.width * aRatio2,
                height: pOptions.actualSurfaceSize.height * aRatio2,
            }

            aNewStart = {
                x: pOptions.start.x + (pOptions.graphContainerSize.width / 2) - aNewSourceSize.width / 2,
                y: pOptions.start.y + (pOptions.graphContainerSize.height / 2) - aNewSourceSize.height / 2
            }

            pOptions.ctx.drawImage(
                pOptions.canvas,
                aStartClipImage.x, aStartClipImage.y,
                aSourceSize.width, aSourceSize.height,
                aNewStart.x, aNewStart.y,
                aNewSourceSize.width, aNewSourceSize.height);
        }

        if (pOptions.fitToContainer == false) {

            aNewSourceSize = {
                width: aSourceSize.width * aRatio,
                height: aSourceSize.height * aRatio
            }

            aNewStart = {
                x: pOptions.start.x + (pOptions.graphContainerSize.width / 2) - aNewSourceSize.width / 2,
                y: pOptions.start.y + (pOptions.graphContainerSize.height / 2) - aNewSourceSize.height / 2
            }
        }

        pOptions.ctx.drawImage(
            pOptions.canvas,
            aStartClipImage.x, aStartClipImage.y,
            aSourceSize.width, aSourceSize.height,
            aNewStart.x, aNewStart.y,
            aNewSourceSize.width, aNewSourceSize.height,
        );

        return { start: aNewStart, size: aNewSourceSize };
    }
    //__________________________________________________________________________________________
    private _addZoomedImage(pOptions: {
        ctx: CanvasRenderingContext2D,
        graphContainerSize: iSize,
        start: iPoint2D,
        canvas: HTMLCanvasElement,
        resolution: iPoint2D,
        sourceSize: iSize,
        zoomData?: iZoomData,
        fitToContainer: boolean
    }) {

        let aStartClipImage = {
            x: pOptions.zoomData != null ? (pOptions.zoomData.startPoint.x * pOptions.sourceSize.width) / pOptions.zoomData.totalSize.width : 0,
            y: pOptions.zoomData != null ? (pOptions.zoomData.startPoint.y * pOptions.sourceSize.height) / pOptions.zoomData.totalSize.height : 0
        };

        /**
         * how much from the image we will copy
         */
        let aSourceSize: iSize = {
            width: pOptions.zoomData != null ?
                (pOptions.zoomData.rectSize.width * pOptions.sourceSize.width) / pOptions.zoomData.totalSize.width :
                pOptions.sourceSize.width - (aStartClipImage.x * 2),

            height: pOptions.zoomData != null ?
                (pOptions.zoomData.rectSize.height * pOptions.sourceSize.height) / pOptions.zoomData.totalSize.height :
                pOptions.sourceSize.height - (aStartClipImage.y * 2),
        }

        let aNewStart: iPoint2D = pOptions.start;

        /**
         * the image should fit to the given container
         */
        if (pOptions.fitToContainer == false) {

            aNewStart = {
                x: 0,
                y: 0
            }
        }

        pOptions.ctx.drawImage(
            pOptions.canvas,
            aStartClipImage.x, aStartClipImage.y,
            aSourceSize.width, aSourceSize.height,
            aNewStart.x, aNewStart.y,
            pOptions.graphContainerSize.width,
            pOptions.graphContainerSize.height,
        );

        return { start: aNewStart, size: pOptions.graphContainerSize };
    }
    //__________________________________________________________________________________________
    private _drawSpot(pOptions: iCreateExtendedAnalysisOptions): iDrawData {

        const aOptions = AnalysisContext.SPOT_OPTIONS;
        const aGraphContainer = Op3dUtils.getElementIn(pOptions.container,
            AnalysisContext.GRAPH_CONTAINER);

        const aActualSurfaceSize: iSize = {
            width: DataUtils.getSize(pOptions.graphData.range_points.x),
            height: DataUtils.getSize(pOptions.graphData.range_points.y)
        };

        let aGraphResult: { ctx: CanvasRenderingContext2D, size: iSize }
        let aStartPoint: iPoint2D = { x: 0, y: 0 };
        let aTableContainer = Op3dUtils.getElementIn(pOptions.container, "table-container");
        aTableContainer.style.width = "100%";
        aTableContainer.style.height = "100%";

        let aTableSize = aTableContainer.getBoundingClientRect();
        aTableContainer.style.width = Math.floor(aTableSize.width) + 'px';
        aTableContainer.style.height = Math.floor(aTableSize.height) + 'px';
        aTableSize = aTableContainer.getBoundingClientRect();

        const aXLabelsHeight = 35;
        const aYLabelsWidth = 45;
        const aActualDivSize: iSize = {
            width: aTableSize.width - aYLabelsWidth,
            height: aTableSize.height - aXLabelsHeight
        }

        let aDivRatio = aActualDivSize.width / aActualDivSize.height;
        let aActualRatio = aActualSurfaceSize.width / aActualSurfaceSize.height;

        let aH = aActualDivSize.height * Math.min(1, (aDivRatio / aActualRatio));
        let aNewDivSize: iSize = {
            height: aH,
            width: aActualRatio * aH
        }

        aGraphContainer.style.width = aNewDivSize.width + 'px';
        aGraphContainer.style.height = aNewDivSize.height + 'px';

        aGraphResult = AnalysisCanvasUtils.initCanvas({
            fitToContainer: false,
            container: aGraphContainer,
            containerID: AnalysisContext.CANVAS_CONTAINER,
            isAdditionalCanvas: false,
            isTextual: false
        });

        // background------------------------------------------------------------
        aGraphResult.ctx.fillStyle = "#ffffff";
        aGraphResult.ctx.fillRect(aStartPoint.x, aStartPoint.y,
            aGraphResult.size.width, aGraphResult.size.height);
        aGraphResult.ctx.lineWidth = 1;
        //------------------------------------------------------------------------
        const aResolution = pOptions.graphData.cacheData.common_data.resolution;
        let aCanvas = pOptions.graphData.canvas;

        if ((aCanvas instanceof CanvasRenderingContext2D) == false) {
            aCanvas = AnalysisCanvasUtils.drawImage(pOptions.graphData.cacheData.matrix.working.matrix,
                aResolution, pOptions.graphData.analysisItem.display,
                pOptions.graphData.cacheData.matrix.working.values_range,
                pOptions.graphData.cacheData.common_data.spectralColors);
            pOptions.graphData.canvas = aCanvas;
        }

        let aRes: { size: iSize, start: iPoint2D<number> };
        if (pOptions.zoomData === undefined) {
            // add regular image

            aRes = this._addRegularImage({
                ctx: aGraphResult.ctx,
                graphContainerSize: aGraphResult.size,
                start: aStartPoint,
                canvas: aCanvas,
                sourceSize: {
                    width: aResolution.x,
                    height: aResolution.y
                },
                keepProportion: true,
                actualSurfaceSize: aActualSurfaceSize
            });
        } else {

            // add zoomed image
            aRes = this._addZoomedImage({
                ctx: aGraphResult.ctx,
                graphContainerSize: aGraphResult.size,
                start: aStartPoint,
                canvas: aCanvas,
                resolution: aResolution,
                sourceSize: {
                    width: aResolution.x,
                    height: aResolution.y
                },
                zoomData: pOptions.zoomData,
                fitToContainer: false
            });
        }

        // general
        aGraphResult.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        aGraphResult.ctx.font = pOptions.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;

        //add x ticks -------------------------------------------------------------
        let aXticksContainer = Op3dUtils.getElementIn(pOptions.container, "x-ticks");
        let aBBX = aXticksContainer.getBoundingClientRect();

        aXticksContainer.style.width = aNewDivSize.width + 'px';
        aXticksContainer.style.height = aBBX.height + 'px';

        let aTicksCtx = AnalysisCanvasUtils.initCanvas({
            container: aXticksContainer,
            fitToContainer: false,
            containerID: "x-ticks",
            isAdditionalCanvas: false,
            isTextual: false
        });

        if (aTicksCtx == null) {
            aTicksCtx = {
                ctx: aGraphResult.ctx,
                size: { height: aRes.size.height, width: aRes.size.width }
            };
        }
        this._addXTicks({
            ctx: aTicksCtx.ctx,
            size: { height: aTicksCtx.size.height, width: aTicksCtx.size.width },
            start: { x: 0, y: 0 },
            x_ticks: pOptions.graphData.range_points.x,
            count: aOptions.SPOT_X_TICKS_COUNT,
            uiParams: pOptions.uiParams,
        });
        //------------------------------------------------------------------------

        //add y ticks -------------------------------------------------------------
        let aYticksContainer = Op3dUtils.getElementIn(pOptions.container, "y-ticks");
        let aBBY = aYticksContainer.getBoundingClientRect();
        const aYTicksCtx = AnalysisCanvasUtils.initCanvas({
            container: aYticksContainer,
            containerID: "y-ticks",
            isAdditionalCanvas: false,
            fitToContainer: false,
            isTextual: false
        });

        aYticksContainer.style.width = aBBY.width + 'px';
        aYticksContainer.style.height = aNewDivSize.height + 'px';
        aYticksContainer.style.display = 'block';


        this._addYTicks({
            ctx: aYTicksCtx.ctx,
            size: { height: aYTicksCtx.size.height, width: aYTicksCtx.size.width },
            start: { x: aYTicksCtx.size.width, y: 0 },
            y_ticks: pOptions.graphData.range_points.y,
            count: aOptions.SPOT_Y_TICKS_COUNT,
            uiParams: pOptions.uiParams,
        });

        let aDrawRes: iDrawData = {
            context: aGraphResult.ctx,
            rectSize: aRes.size,
            totalSize: aRes.size,
            startPoint: aRes.start
        };
        return aDrawRes;
    }
    //__________________________________________________________________________________________
    private _addYTicks(pParams: {
        ctx: CanvasRenderingContext2D,
        start: iPoint2D,
        size: iSize,
        y_ticks: iMinMax,
        count: number,
        uiParams: iGraphUIParams,
    }) {

        pParams.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        pParams.ctx.font = pParams.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;

        let aCurrValue = pParams.y_ticks.max;
        let aDelta = (pParams.y_ticks.max - pParams.y_ticks.min) / (pParams.count - 1);
        let aDeltaPixels = pParams.size.height / (pParams.count - 1);
        let aCurrPos = pParams.start.y;



        for (let i = 0; i < pParams.count; i++) {
            let aPresentedValue = OP3DMathUtils.roundNum((aCurrValue * UnitHandler.presentedScale), 6);
            let aTxtLength = aPresentedValue.toFixed(0).length;
            let aToFixed = anSmallResultFactory.Y_TICKS_TOTAL_LENGTH - aTxtLength > 0 ?
                anSmallResultFactory.Y_TICKS_TOTAL_LENGTH - aTxtLength :
                0;


            let aTextWidth = pParams.ctx.measureText(parseFloat(aPresentedValue.toFixed(aToFixed)).toString()).width;
            let aPosY = aCurrPos;
            if (i == 0) {
                //first
                aPosY = pParams.start.y + (pParams.uiParams.ticks_font_size);
            } else if (i == pParams.count - 1) {
                //last
                aPosY = pParams.start.y + pParams.size.height;
            }

            pParams.ctx.fillText(
                parseFloat(aPresentedValue.toFixed(aToFixed)).toString(),
                pParams.start.x - aTextWidth - 2,
                aPosY);

            aCurrPos += aDeltaPixels;
            aCurrValue -= aDelta;
        }
    }
    //__________________________________________________________________________________________
    private _addXTicks(pParams: {
        start: iPoint2D,
        ctx: CanvasRenderingContext2D,
        x_ticks: iMinMax,
        size: iSize,
        count: number,
        uiParams: iGraphUIParams,
    }) {

        pParams.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        pParams.ctx.font = pParams.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;

        //add x ticks -------------------------------------------------------------
        let aCurrValue = pParams.x_ticks.min;
        let aDelta = (pParams.x_ticks.max - pParams.x_ticks.min) / (pParams.count - 1);
        let aDeltaPixels = pParams.size.width / (pParams.count - 1);
        let aCurrPos = pParams.start.x;

        for (let i = 0; i < pParams.count; i++) {
            const aPresentedValue = OP3DMathUtils.roundNum((aCurrValue * UnitHandler.presentedScale), 6);
            const aTxtLength = aPresentedValue.toFixed(0).length;
            const aToFixed = Math.max(0, anSmallResultFactory.X_TICKS_TOTAL_LENGTH - aTxtLength)

            let aTextWidth = pParams.ctx.measureText(parseFloat(aPresentedValue.toFixed(aToFixed)).toString()).width;

            let aPosX = aCurrPos - (aTextWidth / 2);
            if (i == 0) {
                //first
                aPosX = pParams.start.x;
            } else if (i == pParams.count - 1) {

                //last
                aPosX = aCurrPos - aTextWidth;
            }

            pParams.ctx.fillText(parseFloat(aPresentedValue.toFixed(aToFixed)).toString(),
                aPosX,
                pParams.uiParams.ticks_font_size);

            aCurrPos += aDeltaPixels;
            aCurrValue += aDelta;
        }
    }
    //__________________________________________________________________________________________
    private _getSpectrumBar(pType: eAnalysisDisplay, pCtx: CanvasRenderingContext2D,
        pSize: iSize,
        pStartPoint: iPoint2D, pSpectrumRange: iMinMax): CanvasGradient {

        switch (pType) {

            case eAnalysisDisplay.FALSE_COLOR: {
                let aGradient = pCtx.createLinearGradient(pStartPoint.x, pStartPoint.y,
                    pStartPoint.x + pSize.width, pStartPoint.y + pSize.height);

                if (pSpectrumRange.max == pSpectrumRange.min) {
                    aGradient.addColorStop(0, '#0000ff');
                    aGradient.addColorStop(1, '#0000ff');

                } else {
                    aGradient.addColorStop(0, '#ff0000');
                    aGradient.addColorStop(1 / 4, 'orange');
                    aGradient.addColorStop(2 / 4, 'yellow');
                    aGradient.addColorStop(3 / 4, '#00ff00');
                    aGradient.addColorStop(1, '#0000ff');
                }

                return aGradient;
            }
            case eAnalysisDisplay.GRAY_SCALE: {
                let aGradient = pCtx.createLinearGradient(pStartPoint.x, pStartPoint.y,
                    pStartPoint.x + pSize.width, pStartPoint.y + pSize.height);

                if (pSpectrumRange.max == pSpectrumRange.min) {
                    aGradient.addColorStop(0, 'black');
                    aGradient.addColorStop(1, 'black');

                } else {
                    aGradient.addColorStop(0, 'white');
                    aGradient.addColorStop(1, 'black');
                }

                return aGradient;
            }
            default:
                return null;
        }
    }
    //__________________________________________________________________________________________
    public static addAxisLabels(
        pContainer: HTMLElement,
        pGraphData: iBasicGraphDataParams,
        pUiParams: iGraphUIParams) {
        // x axis label ----------------------------------------------------------
        const aXLabelContainer = Op3dUtils.getElementIn(pContainer,
            AnalysisContext.X_LABEL_CONTAINER);
        AnalysisCanvasUtils.addXLabelContainer(aXLabelContainer, pGraphData.labels.x, pUiParams);
        //------------------------------------------------------------------------

        // y axis label ----------------------------------------------------------
        const aYLabelContainer = Op3dUtils.getElementIn(pContainer,
            AnalysisContext.Y_LABEL_CONTAINER);
        AnalysisCanvasUtils.addYLabelContainer(aYLabelContainer, pGraphData.labels.y, pUiParams);
        //------------------------------------------------------------------------
    }
    //__________________________________________________________________________________________
    private _createGradientBar(pMainContainer: HTMLElement,
        pGraphData: iBasicGraphDataParams,
        pUIParams: iGraphUIParams) {

        const aGradientContainer = Op3dUtils.getElementIn(pMainContainer,
            AnalysisContext.SPECTRUM_BAR_CONTAINER);

        if (pGraphData.analysisItem.display === eAnalysisDisplay.SPECTRAL_VIEW) {
            ViewUtils.removeFromParent(aGradientContainer);
            return;
        }

        let aResult = AnalysisCanvasUtils.initCanvas({
            container: aGradientContainer,
            isAdditionalCanvas: false,
            containerID: AnalysisContext.CANVAS_CONTAINER,
            fitToContainer: true,
            isTextual: true
        });

        // drawing the spectrum bar 
        let aBarSize: iSize = {
            width: aResult.size.width * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE.width,
            height: aResult.size.height * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE.height
        };

        if (aBarSize.width > AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.width) {
            aBarSize.width = AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.width;
        }

        if (aBarSize.height > AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.height) {
            aBarSize.height = AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.height;
        }

        const aStart: iPoint2D = {
            x: aResult.size.width * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_START.x,
            y: aResult.size.height * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_START.y
        };

        const aGradient = this._getSpectrumBar(pGraphData.analysisItem.display,
            aResult.ctx,
            aBarSize, aStart, pGraphData.spectrumData.range);

        aResult.ctx.fillStyle = aGradient;
        aResult.ctx.fillRect(aStart.x, aStart.y, aBarSize.width, aBarSize.height);
        aResult.ctx.strokeRect(aStart.x, aStart.y, aBarSize.width, aBarSize.height);
        aResult.ctx.strokeStyle = "black";
        aResult.ctx.lineWidth = 1;

        // title 
        aResult.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        aResult.ctx.font = pUIParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;
        let aLines = pGraphData.spectrumData.label.split(' ');
        const aTextStart: iPoint2D = {
            x: aStart.x,
            y: aStart.y - (pUIParams.ticks_font_size * (aLines.length + 0.5)),
        }

        for (let i = 0; i < aLines.length; i++) {
            aResult.ctx.fillText(aLines[i], aTextStart.x, aTextStart.y + (i) * pUIParams.ticks_font_size);
        }

        // preferences
        const aDeltaVal = (pGraphData.spectrumData.range.max - pGraphData.spectrumData.range.min) /
            (AnalysisContext.SPOT_OPTIONS.BAR_TICKS_COUNT - 1);

        const aDeltaPixels = aBarSize.height / (AnalysisContext.SPOT_OPTIONS.BAR_TICKS_COUNT - 1);
        let aCurrText = pGraphData.spectrumData.range.min;
        let aTextX = aStart.x + aBarSize.width + (pUIParams.ticks_font_size / 2);
        let aTextY = aStart.y + aBarSize.height + (pUIParams.ticks_font_size / 2);

        // tickes of spectrum bar
        for (let i = 0; i < AnalysisContext.SPOT_OPTIONS.BAR_TICKS_COUNT; i++) {
            let aTxtLength = aCurrText.toFixed(0).length;
            let aToFixed = pUIParams.spectrum_bar_string_length - aTxtLength > 0 ?
                pUIParams.spectrum_bar_string_length - aTxtLength :
                pUIParams.spectrum_bar_string_length;

            aResult.ctx.fillText(aCurrText.toFixed(aToFixed), aTextX, aTextY);
            aCurrText += aDeltaVal;
            aTextY -= aDeltaPixels;
        }
    }
    //__________________________________________________________________________________________
}
