import { useContext } from "react";
import classes from "../../UI/optichat-menu.module.scss";
import sendBtn from "../../../../../public/images/icons_new/chatbot/send_btn_active.svg";
import OptiChatContext, {
  iOptiChatMenuOptionExample,
} from "../../store/OptiChatContext";

export default function OptiChatMenuOption(props: {
  data: iOptiChatMenuOptionExample;
}) {
  const optiChatContext = useContext(OptiChatContext);

  const onClickOptionHandler = () => {
    optiChatContext.addUserMessage(props.data.example);
  };
  return (
    <div className={classes.option} onClick={onClickOptionHandler}>
      <div className={classes.content}>
        <div className={classes.title}>{props.data.title}</div>
        <div className={classes.example}>{props.data.example}</div>
      </div>
      <div>
        <img className={classes["send-btn"]} src={sendBtn} />
      </div>
    </div>
  );
}
