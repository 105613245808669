import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ViewUtils } from "../ViewUtils";

export interface iCheckboxParams {
    label?: string;
    onChange?: (pVal: boolean) => any;
}

export class Checkbox {

    public static CHECKBOX_SVG: string = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<rect width="24" height="24" fill="white"></rect>\n                    <path class="check_box_v" d="M7.75 11.5604L10.9741 14.4914L16.25 9.50867" stroke="#2E3333" stroke-width="2" stroke-linecap="round"></path>\n                    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" stroke="#959E9E"></rect>\n                </svg>';
    private mInput: HTMLInputElement;
    private mCheckBoxLabel: HTMLLabelElement;

    //______________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pCheckboxParams: iCheckboxParams) {
        this._create(pContainer, pCheckboxParams);
    }
    //______________________________________________________________________________________________
    public set checked(pVal: boolean) {
        this.mInput.checked = pVal;
    }
    //______________________________________________________________________________________________
    public get checked() {
        return this.mInput.checked;
    }
    //______________________________________________________________________________________________
    public set label(pTitle: string) {
        this.mCheckBoxLabel.innerHTML = pTitle;
    }
    //______________________________________________________________________________________________
    public setVisibility(pVal: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mInput.parentElement, pVal);
    }
    //______________________________________________________________________________________________
    private _create(pContainer: HTMLElement, pCheckboxParams: iCheckboxParams) {
        pContainer.removeAttribute('class');
        pContainer.classList.add('checkbox_cot');

        this.mInput = document.createElement('input');
        pContainer.appendChild(this.mInput);
        this.mInput.id = Op3dUtils.idGenerator();
        this.mInput.type = 'checkbox';

        let aCheckboxDiv = document.createElement('div');
        aCheckboxDiv.classList.add('checkbox_d');
        aCheckboxDiv.innerHTML = Checkbox.CHECKBOX_SVG;
        pContainer.appendChild(aCheckboxDiv);

        let aCheckboxLabel = document.createElement('label');
        this.mCheckBoxLabel = aCheckboxLabel
        aCheckboxLabel.classList.add('checkbox_label');
        aCheckboxLabel.htmlFor = this.mInput.id;
        if (null != pCheckboxParams.label) {
            aCheckboxLabel.innerHTML = pCheckboxParams.label;
        }
        pContainer.appendChild(aCheckboxLabel);

        if (null != pCheckboxParams.onChange) {
            this.mInput.addEventListener('change', () => pCheckboxParams.onChange(this.mInput.checked));
        }
    }
    //__________________________________________________________________________________________

}
