
export class Colors {
    public static HIGHLIGHT_COLOR = 0x23A7DE;
    public static PINK = 0xFFCCFF;
    public static LIGHT_GREEN = 0xCCFFCC;
    public static RED = 0xff0000;
    public static YELLOW = 0xffff00;
    public static MAGENTA = 0xff00ff;
    public static ROYAL_BLUE = 0x2255bb;
    public static GREEN = 0x22bb55;
    public static WHITE = 0xFFFFFF;
    public static SILVER = 0xC0C0C0;
    public static BLACK = 0x000000;
    public static DARK_GREEN = 0x008000
    public static PARTS_COLOR_LIGHT = 0x383838;
    public static PARTS_COLOR_EXTRA_LIGHT = 0x6C6C6C;
    public static PARTS_COLOR_MEDIUM = 0x1C1C1C;
    public static PARTS_COLOR_DARK = 0x00000;
    public static ANCHOR_COLOR = 0x2083ac;
    public static ANCHOR_MOUNT_COLOR = 0x0c648a;
    public static IMAGING_DETECTOR_COLOR = 0x1f7a1f;//0xffff80;
    public static IMPORT_PART_FRONT_OPTICS_COLOR = 0XFF3333;

    // MEASURES context.Colors
    public static HIGHLIGHT_IN_MEASURES = 0xb02c2f;
    public static UNHIGHLIGHT_IN_MEASURES = 0x6c0104;

    //GRAPHS COLORS
    public static PROGRAM_BLUE = 0x23A7DE;
    public static GRAPH_COLOR1 = 0x1DA79D;
}
