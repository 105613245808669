import { MessagesHandler } from "../../_context/MessagesHandler";
import { iAsyncCallback, tSearchType, iServerMetaDataQuery } from "../../_context/_interfaces/Interfaces";
import { eOMFilterName } from "../../ui/menus/_search/_filterHandlers/OpticsFilterHandler";
import { CacheStorage, eCacheDataType } from "../CacheStorage";

export interface iCacheData {
    type: eCacheDataType;
    key: string;
    saveConvertedData?: boolean;
}


export interface iDataLoaderParams<T, Q, V, C> {
    cacheData?: iCacheData;
    fullDataServerCall: (pData: Q) => Promise<iAsyncCallback<V>>;
    getAll?: () => Promise<iAsyncCallback<Array<V>>>;
    dataConvertionFunc?: (pData: V) => Promise<C>;
    addItemServerCall?: (pData: V) => Promise<iAsyncCallback<string>>;
    isExistServerCall?: (pData: T) => Promise<iAsyncCallback<boolean>>;
    getNumberIDByName?: (pData: string) => Promise<iAsyncCallback<string>>;
    getManyItems?: (pData: tSearchType) => Promise<iAsyncCallback<{
        data?: Array<T>,
        total?: number,
        meta?: any,
        results?: Array<T>,
        aggregation?: { [key in eOMFilterName]: { buckets: Array<{ key: number, doc_count: number }> } },
        documents?: Array<string>
    }>>;
    metaDataServerCall?: (pData: iServerMetaDataQuery) => Promise<iAsyncCallback<T>>;
    removeItemServerCall?: (pData: T) => Promise<iAsyncCallback<null>>;
}

/**
* Synchonized data loader.
* 
* @interface pDataLoaderParams - supply server call and cacheDataType.
* @template T - type of the meta data. This data will be saved in the data loader. used for menu lists
* 
* @template Q - single item Full Data Query. If you would like to save cache and 
*               the function getFullData(pData: Q) is not inherit, Q must have a member named cacheData.key, 
*               otherwise an exeption will be thrown.
* @template V - type of the full data VO.
* 
* @template C - type of the object that saved in cache storage. default is V
* 
* @param - pDataLoaderParams.cacheData - provide if you would like to save cache.
* @param - pDataLoaderParams.cacheData.key - unique key to save data on cache.
* 
*/
export abstract class DataLoader<T = any, Q = any, V = any, C = any> {

    protected mDataLoaderParams: iDataLoaderParams<T, Q, V, C>;
    protected mNeedsUpdate: boolean = true;
    protected mDataTotal: number;
    protected mCurrentData: Array<T>;
    private static ELASTIC_RESULTS: number = 10000;
    mTotalAggregation: any;

    //__________________________________________________________________________________________
    constructor(pDataLoaderParams: iDataLoaderParams<T, Q, V, C>) {
        this._checkInputValidation(pDataLoaderParams);
        this.mDataLoaderParams = pDataLoaderParams;

        if (null != this.mDataLoaderParams.cacheData) {
            let aCacheType = this.mDataLoaderParams.cacheData.type;
            CacheStorage.instance.registerNewStorage(aCacheType);
        }
    }
    //__________________________________________________________________________________________
    public addItemManually(pData: C) {
        this._addToCache(pData);
    }
    //__________________________________________________________________________________________
    public async getFullMetaData() {
        let aQuery: iServerMetaDataQuery = {
            limit: Number.MAX_SAFE_INTEGER,
            skip: 0
        };

        let aServerData = await this.mDataLoaderParams.metaDataServerCall(aQuery);
        if (null == aServerData) {
            return null;
        }

        return aServerData.data;
    }
    //__________________________________________________________________________________________
    public async getManyItems(pQuery: tSearchType) {
        if (this.mNeedsUpdate) {
            let aResult = await this.mDataLoaderParams.getManyItems(pQuery);
            if (aResult.success) {
                this.mDataTotal = aResult.data.total;
                this.mTotalAggregation = aResult.data.aggregation;
                this.mCurrentData = aResult.data.data;
            } else {
                this.mDataTotal = 0;

                this.mCurrentData = [];
            }
            this.mNeedsUpdate = false;
            return this.mCurrentData;
        } else {
            return this.mCurrentData
        }
    }
    //__________________________________________________________________________________________
    public deleteFromCache(pKey: string) {
        let aData = CacheStorage.instance.removeData(pKey, this.mDataLoaderParams.cacheData!.type);
        this._onRemoveData(aData);
    }
    //__________________________________________________________________________________________
    protected _onRemoveData(_pKey: V | C) { }
    //__________________________________________________________________________________________
    public getFromCache(pKey: string, pConverted: boolean = true) {
        let aData = CacheStorage.instance.getFromCache<C>(pKey,
            this.mDataLoaderParams.cacheData?.type as eCacheDataType);
        if (null == aData) {
            return null;
        }

        if ((false == this.mDataLoaderParams.cacheData?.saveConvertedData) &&
            (true == pConverted)) {
            let aConvertedData = this.mDataLoaderParams.dataConvertionFunc!(aData as any) as any;
            return aConvertedData;
        }

        return aData;
    }
    //__________________________________________________________________________________________
    public static getPagingText(pDataTotal: number, pLimit: number, pCurrentPage: number) {
        let aTotal = pDataTotal;

        let aStr: string;
        if (0 == aTotal) {
            aStr = 'No Results found';
        } else {
            let aFrom = pCurrentPage * pLimit + 1;
            let aMaxNext = (pCurrentPage + 1) * pLimit;
            let aTo = Math.min(aMaxNext, aTotal);

            aStr = `Results ${aFrom} - ${aTo} of ${aTotal}`;
        }

        return aStr;
    }
    //__________________________________________________________________________________________
    public async getManyFullData(pData: Array<Q>): Promise<Array<C>> {
        let aRet = new Array<C>();
        for (let i = 0; i < pData.length; i++) {
            let aItem = await this.getSingleFullData(pData[i]);
            aRet.push(aItem);
        }

        return aRet;
    }
    //__________________________________________________________________________________________
    public async getAll() {
        let aData = await this.mDataLoaderParams.getAll!();
        for (let part of aData.data) {
            this._addToCache((part as any));
        }
    }
    //__________________________________________________________________________________________
    public async getSingleFullData(pData: Q, pConverted: boolean, pCallback?: Function): Promise<V>;
    //__________________________________________________________________________________________
    public async getSingleFullData(pData: Q): Promise<C>;
    //__________________________________________________________________________________________
    public async getSingleFullData(pData: Q, pConverted: boolean = true, pCallback?: Function) {
        try {
            let aToSaveOnCache = (null != this.mDataLoaderParams.cacheData);
            if ((true == aToSaveOnCache) &&
                (null == pData[this.mDataLoaderParams.cacheData.key])) {
                let aErrMsg = 'key does not supply for data in function getFullData(pData: Q)';
                aErrMsg += ' in ' + this.constructor.name;
                throw new Error(aErrMsg);
            }

            if (true == aToSaveOnCache) {
                let aName = pData[this.mDataLoaderParams.cacheData.key];
                let aCacheData = this.getFromCache(aName, pConverted);
                if (null != aCacheData) {
                    return aCacheData;
                }
            }


            let aServerData = await this.mDataLoaderParams.fullDataServerCall(pData);

            let aData = (null != aServerData && (aServerData as any).data != "") ?
                aServerData.data : null;
            let aConvertedData: V | C = aData;

            if ((null != aData) && (true == aToSaveOnCache)) {
                // convert data to C
                aConvertedData = await this.saveToCache(aData, pConverted);
            }

            if (pCallback != null) pCallback(aConvertedData)

            return (aConvertedData as C);

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM(e as any);
        }
    }
    //__________________________________________________________________________________________
    public async saveToCache(pData: V, pConverted: boolean = true) {
        let aConvertedData: C;
        if (false == this.mDataLoaderParams.cacheData.saveConvertedData) {
            this._addToCache((pData as any));
            aConvertedData = await this._convertData(pData);
        } else {
            aConvertedData = await this._convertData(pData);
            this._addToCache(aConvertedData);
        }

        if (false == pConverted) {
            return pData;
        }

        return aConvertedData;
    }
    //__________________________________________________________________________________________
    private _addToCache(pCacheData: C) {
        CacheStorage.instance.addData(pCacheData[this.mDataLoaderParams.cacheData.key],
            pCacheData, this.mDataLoaderParams.cacheData.type);
    }
    //__________________________________________________________________________________________
    public async getNumberIDByName(pName: string) {
        if (null == this.mDataLoaderParams.getNumberIDByName) {
            return null;
        }

        let aData = await this.mDataLoaderParams.getNumberIDByName(pName);
        return (((null != aData) && (true == aData.success)) ? aData.data : null);
    }
    //__________________________________________________________________________________________
    private _removeFromCache(pData: T) {
        CacheStorage.instance.removeData(pData[this.mDataLoaderParams.cacheData.key],
            this.mDataLoaderParams.cacheData.type);
    }
    //__________________________________________________________________________________________
    private async _convertData(pData: V): Promise<C> {
        if (null != this.mDataLoaderParams.dataConvertionFunc) {
            let aConvertedData = await this.mDataLoaderParams.dataConvertionFunc(pData);
            return aConvertedData;
        }

        return (pData as any as C);
    }
    //__________________________________________________________________________________________
    public set needsUpdate(pVal: boolean) {
        this.mNeedsUpdate = pVal;
    }
    //__________________________________________________________________________________________
    public get dataTotal() {
        return this.mDataTotal;
    }
    //__________________________________________________________________________________________
    public get dataAggregation() {
        return this.mTotalAggregation;
    }
    //__________________________________________________________________________________________
    private _checkInputValidation(pDataLoaderParams: iDataLoaderParams<T, Q, V, C>) {
        try {
            if ((null != pDataLoaderParams.cacheData) &&
                (null == pDataLoaderParams.cacheData.key)) {
                let aErrorMsg = 'To save cache in data loader - you must supply a valid key.'
                aErrorMsg += ' Class: ' + this.constructor.name;
                throw new Error(aErrorMsg);
            }
        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM(e as any);
        }
    }
    //__________________________________________________________________________________________
    public async remove(pData: T): Promise<iAsyncCallback<null>> {
        if (null == this.mDataLoaderParams.removeItemServerCall) {
            return null;
        }

        this.mNeedsUpdate = true;
        let aRet = await this.mDataLoaderParams.removeItemServerCall(pData);

        let aToSaveOnCache = (null != this.mDataLoaderParams.cacheData);
        if (true == aToSaveOnCache) {
            this._removeFromCache(pData);
        }

        return aRet;
    }
    //__________________________________________________________________________________________
    public async add(pData: V, pUpdate: boolean = true) {
        if (null == this.mDataLoaderParams.addItemServerCall) {
            return null;
        }

        let aRet = await this.mDataLoaderParams.addItemServerCall(pData);
        if ((null == aRet) || (false == aRet.success)) {
            return null;
        }

        let aToSaveOnCache = (null != this.mDataLoaderParams.cacheData);
        if (true == aToSaveOnCache) {
            this.saveToCache(pData);
        }

        if (true == pUpdate) {
            this.mNeedsUpdate = true;
        }

        return aRet;
    }
    //__________________________________________________________________________________________
    public async isExist(pData: T): Promise<boolean> {
        if (null == this.mDataLoaderParams.isExistServerCall) {
            return false;
        }

        let aRet = await this.mDataLoaderParams.isExistServerCall(pData);
        if ((null == aRet) || (true != aRet.data)) {
            return false;
        }

        return true;
    }
    //__________________________________________________________________________________________
}
