﻿import { OpticsContext } from "../../../../_context/OpticsContext";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../../parts/Part";
import { iFace } from "../../../../parts/PartInterfaces";
import { OpticsFactory } from "../../../../parts/optics/OpticsFactory";
import { ViewUtils } from "../../../ViewUtils";
import { uoSection } from "../uoSection";
import { iuoSurfaceCallbacks } from "./UploadOpticsContext";

export class uoSurfacesNew extends uoSection<{ opticsVO: iOpticsVO, part: Part }> {

    private static SKIN_PATH = './skins/forms/optics/uo_surfaces_info.html';

    private mButton: HTMLElement;
    private mElementsParent: HTMLElement;
    private mCallbacks: iuoSurfaceCallbacks;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pCallbacks: iuoSurfaceCallbacks) {
        super(pContainer, {
            skinPath: uoSurfacesNew.SKIN_PATH,
            title: 'Optical surfaces',
            collapseQaId: "uo_surfaces_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: false
        });

        this.mCallbacks = pCallbacks;
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mButton = Op3dUtils.getElementIn(this.mContainer, 'one_button');
        this.mElementsParent = this.mButton.parentElement;
        this.mElementsParent.removeChild(this.mButton);
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mElementsParent);
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: { opticsVO: iOpticsVO, part: Part | undefined }) {
        switch (pData.opticsVO.parameters.subType) {
            case OpticsContext._Paraxial_Lens:
            case OpticsContext._User_Aperture:
                this.show(false);
                return;
            default:
                this.show(true);
                break;
        }

        this._clear();
        let aFaces: Array<iFace>;

        if (pData.part !== undefined) {
            const aOptics = pData.part.returnOpticsFromPart()
            const aPart = aOptics || pData.part
            aFaces = aPart.getFaces();
        } else {
            let aIPart = OpticsFactory.createOpticalDevice(pData.opticsVO);
            aFaces = aIPart.shapes[0].solids[0].faces;
        }

        for (let i = 0; i < aFaces.length; i++) {
            let aFace = aFaces[i];

            if ((null === aFace.data) || undefined === aFace.data) {
                continue;
            }

            let aBtn = this.mButton.cloneNode(true) as HTMLElement;
            let aBtnName = Op3dUtils.getElementIn(aBtn, 'btn_name');
            if (aBtnName !== null && aFace.name !== undefined) {
                aBtnName.innerHTML = aFace.name;
            }

            ViewUtils.setElementAttribute(aBtn, "face_name", aFace.name);
            aBtn.addEventListener('click', () => this._onSurfaceClicked(aBtn, aFaces[i]));
            aBtn.addEventListener('mouseenter', () => this.mCallbacks.mouseenter(aFace.originalName));
            aBtn.addEventListener('mouseleave', () => this.mCallbacks.mouseleave(aFace.originalName));
            this.mElementsParent.appendChild(aBtn);
        }
    }
    //__________________________________________________________________________________________
    public chooseSurface(pFace: iFace) {
        $(this.mContainer).find('.face-item.chosen').each((_index, element) => {
            ViewUtils.setClassForElement(element as HTMLElement, "chosen", false);
        });

        let aBtn = $(`[face_name=${pFace.originalName}`)[0] as HTMLElement;
        if (aBtn !== null) {
            this._onSurfaceClicked(aBtn, pFace);
        }
    }
    //__________________________________________________________________________________________
    private _onSurfaceClicked(pBtn: HTMLElement, pIFace: iFace) {

        $(this.mContainer).find('.face-item.chosen').each((_index, element) => {
            if (pBtn != element) {
                ViewUtils.setClassForElement(element as HTMLElement, "chosen", false);
            }
        });

        let aIsChosen = ViewUtils.toggleClass(pBtn, "chosen");
        if (aIsChosen) {
            this.mCallbacks.onclick(pIFace);
        } else {
            this.mCallbacks.onclick(null);
        }
    }
    //__________________________________________________________________________________________
}
