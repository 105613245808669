import { eDataPermission, eUnitType } from "../../../../_context/Enums";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { iMLSearchData, iHash } from "../../../../_context/_interfaces/Interfaces";
import { Part } from "../../../../parts/Part";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { MenuListAbstract } from "../MenuListAbstract";
import { eFilterType, iSingleFilterData, iDropdownData, iRadioBtnsData, iMultipleCheckboxData, iRangeData, iOneRadioBtnData } from "../MenusContext";

import { DropdownFilter } from "../filters/DropdownFilter";
import { Filter } from "../filters/Filter";
import { MultipleCheckboxFilter } from "../filters/MultipleCheckboxFilter";
import { RadioBtnsFilter } from "../filters/RadioBtnFilter";
import { RangeFilter } from "../filters/RangeFilter";


export interface iMLFilterJSON {
    data: iMLSearchData;
    unit: eUnitType;
}

export abstract class FiltersHandler {

    private static ID_ITERATOR: number = 0;

    protected isMongoQuery: boolean = false;

    private mFilterContainer: HTMLElement;
    private mFilterTemplate: HTMLElement;
    protected mFilters: iHash<Object> = {};
    protected mFilterComponents: iHash<Filter> = {};
    protected mMenu: MenuListAbstract<any>;
    protected mFiltersAdded: boolean = false;
    protected mFilterUnitScale: eUnitType;

    //__________________________________________________________________________________________
    constructor(pMenu: MenuListAbstract<any>, pFilterTemplate: HTMLElement,
        pFilterContainer: HTMLElement) {

        this.mFilterContainer = pFilterContainer;
        this.mFilterTemplate = pFilterTemplate;
        this.mMenu = pMenu;
        this.mFilterUnitScale = UnitHandler.PRESENTED_UNIT;

        this._initFilters2();
    }
    //__________________________________________________________________________________________
    public filterComponent(pPath: string) {
        return this.mFilterComponents[pPath]
    }
    //__________________________________________________________________________________________
    public getScale() {
        switch (this.mFilterUnitScale) {
            case eUnitType.INCHES:
                return UnitHandler.MM_TO_IN;
            default:
                return 1;
        }
    }
    //__________________________________________________________________________________________
    public updateUnit(pUnit: eUnitType) {
        if (pUnit == this.mFilterUnitScale) {
            return;
        }

        this.mFilterUnitScale = pUnit;
        this._updateFiltersUnit(pUnit);
    }
    //__________________________________________________________________________________________
    protected _updateFiltersUnit(_pUnit: eUnitType) { }
    //__________________________________________________________________________________________
    public get filterUnit() {
        return this.mFilterUnitScale;
    }
    //__________________________________________________________________________________________
    public set filterUnit(pUnit: eUnitType) {
        this.mFilterUnitScale = pUnit;
    }
    //__________________________________________________________________________________________
    public exportToJson() {
        let aFilterData = {};

        for (let filter in this.mFilterComponents) {
            if (this.mFilterComponents[filter] != null) {
                aFilterData[filter] = this.mFilterComponents[filter].exportToJson();
            }
        }

        let aData: iMLFilterJSON = {
            data: aFilterData,
            unit: UnitHandler.PRESENTED_UNIT
        };

        return aData;
    }
    //__________________________________________________________________________________________
    public initFromJson(pData: iMLFilterJSON) {
        for (let filter in pData.data) {
            if (this.mFilterComponents[filter] != null) {
                this.mFilterComponents[filter].initFromJson(pData.data[filter], pData.unit);
            }
        }
    }
    //__________________________________________________________________________________________
    private async _prepareFilters() {

        let aIsReady: boolean = false;
        do {
            await Op3dContext.sleep(50);
            aIsReady = this.mFiltersAdded;
            for (let item in this.mFilterComponents) {
                aIsReady = aIsReady && this.mFilterComponents[item].isReady;
            }

        } while (!aIsReady)
    }
    //__________________________________________________________________________________________
    private _initFilters2() {
        this._createFilter(this._addPermissionFilter());

        if (Op3dContext.APP_FEATURES_CONFIG.optomech_menu.enabled) {
            this._createFilter(this._addMainFilters());
        }

        this._initFilters()

    }
    //__________________________________________________________________________________________ 
    private _addPermissionFilter() {
        let aRadioBtnsData: Array<iOneRadioBtnData> = [
            {
                name: 'All',
                serverName: [eDataPermission.PUBLIC, eDataPermission.PRIVATE]
            },
            {
                name: 'Public',
                serverName: [eDataPermission.PUBLIC]
            },
            {
                name: 'Private',
                serverName: [eDataPermission.PRIVATE]
            },

        ];

        let aPermissionFilter: iSingleFilterData<iRadioBtnsData> = {
            filterType: eFilterType.RADIO_BTNS,
            data: { data: aRadioBtnsData, defaultNameIndex: 0 },
            isVisible: true,

            name: 'permission',
            params: {
                showOnStart: true,
                title: "Ownership",
                hideResetBtn: true,
                onChange: (pData: Array<eDataPermission>, pToChange: boolean) =>
                    this._onPermissionChange(pData, pToChange)
            }
        };

        return aPermissionFilter;
    }
    //__________________________________________________________________________________________  
    protected _onPermissionChange(_pData: Array<eDataPermission>, _pToChange: boolean) { }
    //__________________________________________________________________________________________   
    private _addMainFilters() {
        const aCategory = ['Optics', 'Opto-Mechanics']


        let aData: iRadioBtnsData = {
            defaultNameIndex: 0,
            data: new Array<iOneRadioBtnData>()
        };

        let aNames = aCategory
        for (let i = 0; i < aNames.length; i++) {
            const aName = aNames[i];
            aData.data.push({ name: aName });
        }

        return {
            filterType: eFilterType.RADIO_BTNS,
            data: aData,
            isVisible: true,
            name: 'Category',
            params: {
                showOnStart: true,
                title: "Category",
                hideResetBtn: true,
                onChange: (pType: any, pToChange: boolean) =>
                    this._onCategoryChange(pType, pToChange, undefined),
            }
        }
    }
    //__________________________________________________________________________________________
    public setCategory(pValue) {
        if (Op3dContext.APP_FEATURES_CONFIG.optomech_menu.enabled) {
            this.mFilterComponents['Category'].setValue(pValue)
        }

    }
    //__________________________________________________________________________________________
    protected _onCategoryChange(pType: any, pToChange: boolean, pOpenData: any) {
        if (Op3dContext.APP_FEATURES_CONFIG.optomech_menu.enabled) {
            if (pToChange === true && pType !== pOpenData)
                Op3dContext.DIV_CONTROLLER.onOpenSearchMenu(pType, pOpenData)
        }



    }
    //__________________________________________________________________________________________
    protected abstract _initFilters(): void;
    //__________________________________________________________________________________________
    public async reload(_pPart?: Part) {
        await this._prepareFilters()
        this.reset();
        this._adjustFilters();
    }
    //__________________________________________________________________________________________
    protected _adjustFilters() { }
    //__________________________________________________________________________________________
    public reset() {
        for (let item in this.mFilterComponents) {
            if (item === 'Category') continue
            this.mFilterComponents[item].reset(false, true);
        }

        // for (let item in this.mFilters) {
        //     delete this.mFilters[item];
        // }
    }
    //__________________________________________________________________________________________
    protected _onIngredientChange(pIngredient: any, pData: any,
        pOmFilterType: eFilterType, pToUpdate: boolean) {

        if (pData != null) {
            switch (pOmFilterType) {
                case eFilterType.RANGE:

                    if (pIngredient.min && pIngredient.max) {
                        this.mFilters[pIngredient.min] = this.isMongoQuery ? { '$gte': pData[0] } : { 'from': pData[0] };
                        this.mFilters[pIngredient.max] = this.isMongoQuery ? { '$lte': pData[1] } : { 'to': pData[1] };
                    } else {
                        this.mFilters[pIngredient] = this.isMongoQuery ? { '$gte': pData[0], '$lte': pData[1] } : { 'from': pData[0], 'to': pData[1] };
                    }

                    break;
                case eFilterType.DROPDOWN:
                case eFilterType.MULTIPLE_CHECKBOX:
                    this.mFilters[pIngredient] = this.isMongoQuery ? { $in: pData } : pData;
                    break;
                case eFilterType.RADIO_BTNS:
                    this.mFilters[pIngredient] = pData;
                    break;
            }
        } else {
            if (pIngredient.min && pIngredient.max) {
                delete this.mFilters[pIngredient.min];
                delete this.mFilters[pIngredient.max];
            } else {
                delete this.mFilters[pIngredient];
            }
        }

        if (pToUpdate) {
            this._updateMenu();
        }
    }
    //__________________________________________________________________________________________
    private _updateMenu() {
        if (null != this.mMenu) {
            this.mMenu.update();
        }
    }
    //__________________________________________________________________________________________
    private _addFilterContainer() {
        let aFilterContainer = this.mFilterTemplate.cloneNode(true) as HTMLElement;
        aFilterContainer.id += FiltersHandler.ID_ITERATOR++;
        this.mFilterContainer.appendChild(aFilterContainer);
        return aFilterContainer;
    }
    //__________________________________________________________________________________________
    protected _createFilter(pFilterData: iSingleFilterData) {
        let aFilterContainer = this._addFilterContainer();
        let aNewFilter: Filter;

        switch (pFilterData.filterType) {
            case eFilterType.DROPDOWN:
                aNewFilter = new DropdownFilter({
                    container: aFilterContainer,
                    data: pFilterData.data as iDropdownData,
                    params: pFilterData.params
                });
                break;
            case eFilterType.RADIO_BTNS:
                aNewFilter = new RadioBtnsFilter({
                    container: aFilterContainer,
                    data: pFilterData.data as iRadioBtnsData,
                    params: pFilterData.params
                });
                break;
            case eFilterType.MULTIPLE_CHECKBOX:
                aNewFilter = new MultipleCheckboxFilter({
                    container: aFilterContainer,
                    data: pFilterData.data as iMultipleCheckboxData,
                    params: pFilterData.params
                });
                break;
            case eFilterType.RANGE:
                aNewFilter = new RangeFilter({
                    container: aFilterContainer,
                    data: pFilterData.data as iRangeData,
                    params: pFilterData.params
                });
                break;
            default:
                throw new Error("unhandeled filter type");
        }

        aNewFilter.setVisibility(pFilterData.isVisible);
        this.mFilterComponents[pFilterData.name] = aNewFilter;
    }
    //__________________________________________________________________________________________
    public getFilters(): iHash<any> {
        let aFilters = Object.assign({}, this.mFilters);
        return aFilters;
    }
    //__________________________________________________________________________________________
    public get filtersReady() {
        return this.mFiltersAdded;
    }
    //__________________________________________________________________________________________
    public setFilters(_pData: any) {

    }
    //__________________________________________________________________________________________
}
