import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";

export enum eSpinnerType {
    REGULAR,
    SIMULATION,
    EDITOR,
    CUSTOM
}

export class Spinner extends Op3dComponentBase {
    private static FIVE_MINUTES_SPINNER = 300000;
    private static INSTANCE: Spinner;
    private mCurrentSpinnerType: eSpinnerType = eSpinnerType.REGULAR;
    private mSpinnerText: HTMLElement;
    private mOnStop: () => void;
    private mStopBtn: HTMLElement;
    private mTimer: any;

    private mWaitingArray = new Array<Function>();
    //__________________________________________________________________________________________
    private constructor() {
        super({
            container: document.getElementById('spinner-container'),
            skinPath: './skins/home/spinner.html'
        });

    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            this.INSTANCE = new Spinner();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    private async _setSpinnerType(pType: eSpinnerType, pCustomData?: { stop_callback: Function, sample: string, data: string | number }) {
        await this._prepareForm();

        if (this.mCurrentSpinnerType == pType && pType !== eSpinnerType.CUSTOM) {
            return;
        }

        switch (pType) {
            case eSpinnerType.REGULAR:
                ViewUtils.setElementVisibilityByDNone(this.mStopBtn, false);
                this.mOnStop = null;
                this.mTimer = null;
                this.mSpinnerText.innerText = "Loading...";
                break;
            // case eSpinnerType.SIMULATION:
            //     this.mStopBtn.classList.remove('d-none')
            //     this.mTimer = setTimeout(() => this._onCheckSpinner(), Spinner.FIVE_MINUTES_SPINNER);
            //     this.mOnStop = () => {
            //         SimulationRunner.instance.mAbortController.abort()
            //         this.hide()
            //     };
            //     this.mSpinnerText.innerText = "Simulating";
            //     break;
            case eSpinnerType.EDITOR:
                ViewUtils.setElementVisibilityByDNone(this.mStopBtn, false);
                this.mTimer = setTimeout(() => this._onCheckSpinner(), Spinner.FIVE_MINUTES_SPINNER);
                this.mOnStop = () => this.hide();
                this.mSpinnerText.classList.add('text-center');
                this.mSpinnerText.innerText = "Uploading...\n Do not close the window";
                break;
            case eSpinnerType.CUSTOM:
                // this.mOnStop = () => this.hide();
                this.mOnStop = () => {
                    this.hide();
                    if (pCustomData.stop_callback != null) {
                        pCustomData.stop_callback();
                    }
                }
                ViewUtils.setClassForElement(this.mStopBtn, 'd-none', pCustomData.stop_callback == null)
                this.mTimer = null;
                this.mSpinnerText.classList.add('text-center');
                this.mSpinnerText.innerText = `${pCustomData.sample}`;
                if (pCustomData.data) {
                    this.mSpinnerText.innerText = `${pCustomData.sample} ${pCustomData.data}%`;
                }
                break;
        }

        this.mCurrentSpinnerType = pType;
    }
    //__________________________________________________________________________________________
    private _onCheckSpinner() {
        if (this.mTimer != null) {
            this.mTimer = null;
            this.checkIfPauseSimulation();
        }
    }
    //__________________________________________________________________________________________
    private checkIfPauseSimulation(_pTitle?: string, _pBodyMsg?: string) {
        //TODO long simulation waiting
        // engine.Op3dContext.SimulationRunner.pause = true;
        // data.context.OpContext.divManager.hideSpinner();

        // if (pTitle == null) {
        //     pTitle = "Simulation is running for 5 minutes.<br>";
        // }

        // let aMsg = pTitle;
        // aMsg += data.context.MessagesHandler.LONG_WAITING_TIME_MSG

        // if (pBodyMsg != null) {
        //     aMsg += pBodyMsg;
        // }

        // data.context.OpContext.divManager.showPopupNew({
        //     visible: true, text: aMsg,
        //     yesBtn: {
        //         callback: () => {
        //             data.context.OpContext.divManager.showSpinner({
        //                 text: "SIMULATING", message: views.Spinner.LASER_MSG,
        //                 stopCallback: this.mStopCallBack, colorOpacity: Spinner.COLOR_OPACITY_3
        //             });
        //             engine.Op3dContext.SimulationRunner.pause = false;
        //         },
        //         title: "Continue"
        //     },
        //     noBtn: {
        //         callback:
        //             () => { this.mStopCallBack ? this.mStopCallBack() : null },
        //         title: "Stop"
        //     }
        // });
    }
    //__________________________________________________________________________________________
    public async show(pSpinnerType: eSpinnerType = eSpinnerType.REGULAR) {
        if (!this.mIsReady) {
            this.mWaitingArray.push(() => this.show(pSpinnerType));
            return;
        }
        this._setSpinnerType(pSpinnerType);
        ViewUtils.setElementVisibilityByDNone(this.mContainer, true);
    }
    //__________________________________________________________________________________________
    public spinnerCallBack = async (pSample: string | 'remove', pStopCallback: Function, pData: string | number = '') => {
        if (pSample !== 'remove') {
            Spinner.instance.showWithCustomData(pSample, pStopCallback, pData)
        } else {
            Spinner.instance.hide()
        }

    }
    //__________________________________________________________________________________________

    public async showWithCustomData(pSample: string, pStopCallback: Function, pData: string | number = '') {
        if (!this.mIsReady) {
            this.mWaitingArray.push(() => this.showWithCustomData(pSample, pStopCallback, pData));
            return;
        }
        this._setSpinnerType(eSpinnerType.CUSTOM, { sample: pSample, stop_callback: pStopCallback, data: pData });
        ViewUtils.setElementVisibilityByDNone(this.mContainer, true);
    }
    //__________________________________________________________________________________________

    public async hide() {
        if (!this.mIsReady) {
            this.mWaitingArray.push(() => this.hide());
            return;
        }
        this.mTimer = null;
        ViewUtils.setElementVisibilityByDNone(this.mContainer, false);
    }
    //__________________________________________________________________________________________
    private _onStop() {
        if (this.mOnStop != null) {
            this.mOnStop();
        }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mStopBtn = this._getPart("stop-loading-btn", true);
        this.mStopBtn.addEventListener("click", () => this._onStop());
        this.mSpinnerText = this._getPart("spinner_text", true);
        ViewUtils.makeUnselectable(this.mContainer);

        this.mIsReady = true;

        while (this.mWaitingArray.length > 0) {
            let aCallback = this.mWaitingArray.shift();
            aCallback();
        }
    }
    //__________________________________________________________________________________________
}