﻿import { MessagesHandler } from "../../_context/MessagesHandler";
import { iMaterialVO, iMaterialMetaDataVO, iRgba, iMinMax } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { RadioButtonsComponent, iRadioButtonsComponentData } from "../components/RadioButtonsComponent";
import { ViewUtils } from "../ViewUtils";
import { Spinner } from "../home/Spinner";
import { Op3dContext } from "../../_context/Op3dContext";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { MaterialUtils } from "../../_utils/MaterialUtils";
import { MaterialDataLoader } from "../../data/data_loader/MaterialDataLoader";
import { Colors } from "../../_context/Colors"; import { ColorUtils } from "../ColorUtils";
import { Chart } from "chart.js/auto";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { SnapshotTools } from "../../_utils/SnapshotTools";
import { tMimeType } from "../../_utils/FileUtils";
import { saveAs } from 'file-saver';

export interface iSingleSVGLineData {
    color: iRgba;
    points: Array<iSVGPoint>;
    lineRange?: iMinMax; // relevant only for specturm charts - the only line data that has an additional range
}
export interface iSVGPoint<T = any> {
    x: number;
    y: number;
    data?: T;
}

export interface iRPRS_SearchedData {
    n_input: number;
    n_element: number;
    rp: Array<iSVGPoint>;
    rs: Array<iSVGPoint>;
};

export enum eGraphRPRSDirection {
    IN,
    OUT
};

export class MaterialInformationForm extends Op3dComponentBase<iMaterialVO> {

    private static SKIN_PATH = "./skins/info/material_information.html";
    private static START_VALUE: number = 400;
    private static END_VALUE: number = 1000;
    private static DEFAULT_MATERIAL_NUMBER_ID: string = "n-bk7_schott";

    private mMaterialsDD: HTMLSelectElement;
    private mWavelengthInput: HTMLInputElement;
    private mStartInput: HTMLInputElement;
    private mEndInput: HTMLInputElement;
    private mNoImageDivMaterial: HTMLElement;
    private mNoImageDivMaterialRpRs: HTMLElement;
    private mChartDiv: HTMLElement;
    private mChartDivRpRs: HTMLElement;
    private mDirection: eGraphRPRSDirection;
    private mMaterialVO: iMaterialVO;

    private static INSTANCE: MaterialInformationForm;
    mPoitnsRpRs: { Rp: number[]; Rs: number[]; Deg: number[]; };
    mRefractiveData: iSVGPoint<any>[];

    private constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: MaterialInformationForm.SKIN_PATH
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (MaterialInformationForm.INSTANCE == null) {
            let aFormsContainer = document.getElementById('forms');
            if (aFormsContainer == null) {
                throw new Error('container with id "forms" not found');
            }

            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('ui-draggable');
            aDiv.classList.add('width-auto');
            aDiv.classList.add('height-auto');
            aDiv.setAttribute("role", "dialog");
            aDiv.setAttribute("data-backdrop", "false");
            aFormsContainer.appendChild(aDiv);
            MaterialInformationForm.INSTANCE = new MaterialInformationForm(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        $(this.mContainer).draggable({
            handle: ".modal-header",
            containment: 'window'
        });

        ViewUtils.makeUnselectable(this.mContainer);
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onShown() {
        this._setFields(this.mMaterialVO);
    }
    //__________________________________________________________________________________________
    protected _addEventListeners() {
        this.mMaterialsDD.addEventListener("change", () => this._onMaterialChanged());

        let aDisplayMaterialBtn = this._getPart("display-btn-material", true);
        aDisplayMaterialBtn.addEventListener("click", () => this._onDisplayMaterial());

        let aDisplayRpRsBtn = this._getPart("display-btn-rp-rs", true);
        aDisplayRpRsBtn.addEventListener("click", () => this._onDisplayRpRs());

        let aData: iRadioButtonsComponentData<eGraphRPRSDirection> = {
            elements: [
                {
                    data: eGraphRPRSDirection.IN,
                    checked: true,
                    name: "In",
                    callback: () => this._onDirectionChanged(eGraphRPRSDirection.IN)
                },
                {
                    data: eGraphRPRSDirection.OUT,
                    name: "Out",
                    callback: () => this._onDirectionChanged(eGraphRPRSDirection.OUT)
                }
            ]
        }

        let aContainer = this._getPart("in-out-container", true);
        new RadioButtonsComponent(aContainer, aData);
    }
    //__________________________________________________________________________________________
    protected _onOpen(pMaterialVO?: iMaterialVO): void {
        this.mMaterialVO = pMaterialVO;
        Spinner.instance.show();

        if (true == this.mContainer.classList.contains('show')) {
            this._onShown();
        }
    }
    //__________________________________________________________________________________________
    private _onDirectionChanged(pDirection: eGraphRPRSDirection) {
        this.mDirection = pDirection;
        this._onDisplayRpRs();
    }
    //__________________________________________________________________________________________
    protected async _initElements() {

        this.mMaterialsDD = this._getPart("materials-dropdown-info", true) as HTMLSelectElement;
        this.mStartInput = this._getPart("start-input", true) as HTMLInputElement;
        this.mEndInput = this._getPart("end-input", true) as HTMLInputElement;
        this.mNoImageDivMaterial = this._getPart("no-material-image", true);
        this.mNoImageDivMaterialRpRs = this._getPart("no-material-image-rp-rs", true);
        this.mWavelengthInput = this._getPart("wavelength-input", true) as HTMLInputElement;

        //this.mInBtn = this.getPart("in-btn", true);
        //this.mOutBtn = this.getPart("out-btn", true);
        let pMaterials = await Op3dContext.DATA_MANAGER.getMaterials();
        this._initMaterialsDD(pMaterials);
        this._initDropdowns();
    }
    //__________________________________________________________________________________________
    private _initDropdowns() {
        this._getPart("download-png_left", true).addEventListener("click", () => this._onDownloadImage("image/png", true));
        this._getPart("download-png", true).addEventListener("click", () => this._onDownloadImage("image/png"));
        this._getPart("download-jpg_left", true).addEventListener("click", () => this._onDownloadImage("image/jpg", true));
        this._getPart("download-jpg", true).addEventListener("click", () => this._onDownloadImage("image/jpg"));
        this._getPart("download-xlsx_left", true).addEventListener("click", () => this._onDownloadXLSXIntensity());
        this._getPart("download-xlsx", true).addEventListener("click", () => this._onDownloadXLSXRefractive());
    }
    //__________________________________________________________________________________________
    protected _onDownloadXLSXIntensity(): void {
        let aPrefix = 'Normalized Intensity'
        let aDataType = 'Normalized Infinity vs Angle Of Incidence';

        let aMaterialName: string = aPrefix + ' ' + this.mMaterialVO.name;
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: aMaterialName,
            Author: Op3dContext.USER_VO.userVO.name.first + " " + Op3dContext.USER_VO.userVO.name.last,
            CreatedDate: new Date()
        };
        let aDirection = this.mDirection === eGraphRPRSDirection.IN ? "in" : "out";
        let aDate = Op3dUtils.getDate();
        let aBase = Array<Array<any>>();
        aBase[0] = ["Date", aDate];
        aBase[1] = ["Material", this.mMaterialVO.name];
        aBase[2] = ["Wavelength", this.mWavelengthInput.value];
        aBase[3] = ["Data type", aDataType];
        aBase[4] = ["in/out", aDirection];
        aBase[5] = ["Angle Of Incidence(deg)", 'R_p', 'R_s'];

        let aContent = Array<Array<any>>();
        for (let i = 0; i < this.mPoitnsRpRs.Rp.length; i++) {
            aContent[i] = [this.mPoitnsRpRs.Deg[i], this.mPoitnsRpRs.Rp[i], this.mPoitnsRpRs.Rs[i]];
        }
        this._addSheet(wb, aMaterialName, aBase, aContent);
        let aWBOutBinary = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        let aBuffer = new ArrayBuffer(aWBOutBinary.length);
        let aView = new Uint8Array(aBuffer);
        for (let i = 0; i < aWBOutBinary.length; i++) {
            aView[i] = aWBOutBinary.charCodeAt(i) & 0xFF;
        }

        saveAs(new Blob([aBuffer], { type: "application/octet-stream" }),
            aMaterialName + ".xlsx");

    }
    //__________________________________________________________________________________________
    protected _onDownloadXLSXRefractive(): void {
        let aPrefix = 'Refractive Index'
        let aDataType = 'Refractive Index vs Wavelength';

        let aMaterialName: string = aPrefix + ' ' + this.mMaterialVO.name;
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: aMaterialName,
            Author: Op3dContext.USER_VO.userVO.name.first + " " + Op3dContext.USER_VO.userVO.name.last,
            CreatedDate: new Date()
        };
        let aDate = Op3dUtils.getDate();
        let aBase = Array<Array<any>>();
        aBase[0] = ["Date", aDate];
        aBase[1] = ["Material", this.mMaterialVO.name];
        aBase[2] = ["Data type", aDataType];
        aBase[3] = ["Wavelength(nm)", 'Refractive Index'];

        let aContent = Array<Array<any>>();
        for (let i = 0; i < this.mRefractiveData.length; i++) {
            aContent[i] = [this.mRefractiveData[i].x, this.mRefractiveData[i].y];
        }
        this._addSheet(wb, aMaterialName, aBase, aContent);
        let aWBOutBinary = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        let aBuffer = new ArrayBuffer(aWBOutBinary.length);
        let aView = new Uint8Array(aBuffer);
        for (let i = 0; i < aWBOutBinary.length; i++) {
            aView[i] = aWBOutBinary.charCodeAt(i) & 0xFF;
        }
        saveAs(new Blob([aBuffer], { type: "application/octet-stream" }),
            aMaterialName + ".xlsx");

    }
    //__________________________________________________________________________________________
    protected _addSheet(pWB: XLSX.WorkBook, pKey: string, pBase: any[][], pContent: any[][]) {
        pWB.SheetNames.push(pKey);
        pWB.Sheets[pKey] = XLSX.utils.aoa_to_sheet([...pBase, ...pContent]);
    }
    //__________________________________________________________________________________________
    private async _onDownloadImage(pMimeType: tMimeType, pLeftPic: boolean = false) {
        let aDoc = this.mChartDiv.childNodes[1] as HTMLCanvasElement;
        let aPrefix = 'Normalized Intensity'
        if (pLeftPic === true) {
            aDoc = this.mChartDivRpRs.childNodes[1] as HTMLCanvasElement;
            aPrefix = 'Refractive Index'
        }
        let aMaterialName: string = aPrefix + ' ' + this.mMaterialVO.name;
        aDoc.style.backgroundColor = 'white';
        aDoc.parentElement.style.backgroundColor = 'white';
        SnapshotTools.downloadImageFromCanvas({
            canvas: aDoc,
            mimeType: pMimeType,
            name: aMaterialName,
        });

    }
    //__________________________________________________________________________________________
    private _onDisplayRpRs() {
        if (this.mMaterialVO == null) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.MATERIAL_INFO_INVALID_MATERIAL,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
            return;
        }

        let aWavelength = parseFloat(this.mWavelengthInput.value);

        if (isNaN(aWavelength)) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.MATERIAL_INFO_WRONG_FIELDS,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
            return;
        }

        this._initRpRsSVG();
        this._calculateRpRsData();
    }
    //__________________________________________________________________________________________
    private _calculateRpRsData() {
        if (this.mMaterialVO == null) {
            return;
        }

        let aWavelength = parseFloat(this.mWavelengthInput.value);

        let aNInput = 1;
        let aNElement = MaterialUtils.getN(this.mMaterialVO, aWavelength);

        if (this.mDirection === eGraphRPRSDirection.OUT) {
            aNInput = aNElement;
            aNElement = 1;
        }

        for (let i = 0; i <= 90; i++) {
            i = parseFloat(i.toFixed(1));

            let aRad = i * OP3DMathUtils.DEG_TO_RAD;
            let aVal1 = OP3DMathUtils.calculateRP_GraphMaterial(aRad, aNInput, aNElement);
            let aVal2 = OP3DMathUtils.calculateRS_GraphMaterial(aRad, aNInput, aNElement);
            if (isNaN(aVal1)) {
                aVal1 = 1;
            }
            if (isNaN(aVal2)) {
                aVal2 = 1;
            }
        }

        let aDelta = 0.1;
        let aValuesDataRp = new Array<{ x: number, y: number }>();
        let aValuesDataRs = new Array<{ x: number, y: number }>();
        for (let i = 0; i <= 90; i += aDelta) {
            i = parseFloat(i.toFixed(1));

            let aRad = i * OP3DMathUtils.DEG_TO_RAD;
            let aVal1 = OP3DMathUtils.calculateRP_GraphMaterial(aRad, aNInput, aNElement);
            let aVal2 = OP3DMathUtils.calculateRS_GraphMaterial(aRad, aNInput, aNElement);
            if (isNaN(aVal1)) {
                aVal1 = 1;
            }
            if (isNaN(aVal2)) {
                aVal2 = 1;
            }
            aValuesDataRp.push({ x: i, y: aVal1 });
            aValuesDataRs.push({ x: i, y: aVal2 });
        }

        let aSearchedData = {
            n_input: aNInput,
            n_element: aNElement,
            rp: aValuesDataRp,
            rs: aValuesDataRs
        };

        this._onShowRpRsGraphSVG(aSearchedData);
    }
    //__________________________________________________________________________________________
    private _onDisplayMaterial() {

        let aStart = parseInt(this.mStartInput.value);
        let aEnd = parseInt(this.mEndInput.value);

        if (isNaN(aStart) || isNaN(aEnd)) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.MATERIAL_INFO_WRONG_FIELDS,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
            return;
        }

        if (this.mMaterialVO == null) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.MATERIAL_INFO_INVALID_MATERIAL,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
            return;
        }

        this._initMaterialSVG();
        this._calculateMaterialData(aStart, aEnd);
    }
    //__________________________________________________________________________________________
    private _calculateMaterialData(pStart: number, pEnd: number) {
        if (this.mMaterialVO != null) {
            let aData = new Array<number>();

            for (let i = pStart; i < pEnd; i++) {
                let aVal = MaterialUtils.getN(this.mMaterialVO, i);
                if (isNaN(aVal) && aData[i - 1]) {
                    aVal = aData[i - 1];
                }
                aData[i] = aVal;
            }

            this.onShowMaterialGraphSVG(aData, pStart, pEnd);
        }
    }
    //__________________________________________________________________________________________
    private _initMaterialsDD(pMaterials: Array<iMaterialMetaDataVO>) {
        ViewUtils.clearElementsChildren(this.mMaterialsDD);

        for (let i = 0; i < pMaterials.length; i++) {
            let aOption = document.createElement('option') as HTMLOptionElement;
            aOption.innerHTML = pMaterials[i].name;
            aOption.value = pMaterials[i].name;
            aOption.setAttribute("number_id", pMaterials[i].number_id);
            this.mMaterialsDD.appendChild(aOption);
        }

        this.mMaterialsDD.classList.add("selectpicker");
        $(this.mMaterialsDD).selectpicker();
        this.mMaterialsDD.selectedIndex = 0;
    }
    //__________________________________________________________________________________________
    private async _setDefaultMaterial(pNumberID: string = MaterialInformationForm.DEFAULT_MATERIAL_NUMBER_ID) {
        Spinner.instance.show();
        const aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pNumberID });
        this._onGetMaterial(aMaterialVO);
        let aMaterialName = aMaterialVO.name;
        let aSelectedIndex = ViewUtils.findIndexByValue(this.mMaterialsDD, aMaterialName);
        this.mMaterialsDD.selectedIndex = aSelectedIndex;
        $(this.mMaterialsDD).selectpicker('refresh');
    }
    //__________________________________________________________________________________________
    private async _onMaterialChanged() {

        let aNumberID = this.mMaterialsDD.selectedOptions[0].getAttribute("number_id");
        if (aNumberID != null) {
            Spinner.instance.show();
            let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: aNumberID });
            this._onGetMaterial(aMaterialVO);
        }
    }
    //__________________________________________________________________________________________
    private _onGetMaterial(pMaterialVO: iMaterialVO) {
        this.mMaterialVO = pMaterialVO;
        if (pMaterialVO == null) {
            this.mMaterialsDD.selectedIndex = 0;
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.ERROR_LOADING_SINGLE_MATERIAL,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
        } else {
            let aStart = (null != pMaterialVO.parameters.startLambda) ?
                pMaterialVO.parameters.startLambda : MaterialInformationForm.START_VALUE;
            let aEnd = (null != pMaterialVO.parameters.endLambda) ?
                pMaterialVO.parameters.endLambda : MaterialInformationForm.END_VALUE;
            this.mStartInput.value = aStart.toString();
            this.mEndInput.value = aEnd.toString();

            this._onDisplayMaterial();
            this._onDisplayRpRs();
        }

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _onShowRpRsGraphSVG(pSearchedData: iRPRS_SearchedData) {

        if (this.mChartDivRpRs) {
            this.mChartDivRpRs.style.display = "block";
        }

        let aColor2 = ColorUtils.hexToRGB(Colors.GRAPH_COLOR1);
        let aPointsRp = pSearchedData.rp.map(item => item.y)
        let aPointsRs = pSearchedData.rs.map(item => item.y)
        let aLabels = pSearchedData.rs.map(item => item.x)
        this.mPoitnsRpRs = {
            Rp: aPointsRp,
            Rs: aPointsRs,
            Deg: aLabels
        }
        let data = {
            labels: aLabels,
            datasets: [
                {
                    label: 'Rp',
                    data: aPointsRp,
                    fill: false,
                    backgroundColor: 'rgba(35, 167, 222, 1)',
                    borderColor: 'rgba(35, 167, 222, 1)',
                    borderWidth: 1,
                    pointRadius: 0
                },
                {
                    label: 'Rs',
                    data: aPointsRs,
                    fill: false,
                    backgroundColor: `rgba(${aColor2.r},${aColor2.g},${aColor2.b})`,
                    borderColor: `rgba(${aColor2.r},${aColor2.g},${aColor2.b})`,
                    borderWidth: 1,
                    pointRadius: 0
                }]
        };

        let aGraphCanvas = document.createElement('canvas');
        ViewUtils.clearElementsChildren(this.mChartDivRpRs)
        this.mChartDivRpRs.appendChild(aGraphCanvas);
        aGraphCanvas.id = Op3dUtils.idGenerator();
        let aBB = this.mChartDivRpRs.getBoundingClientRect();
        aGraphCanvas.width = aBB.width;
        aGraphCanvas.height = aBB.height;
        const aPlugin = {
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#99ffff';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            }
        };
        var ctx = aGraphCanvas.getContext("2d");
        new Chart(ctx, {
            type: 'line',
            data: data,
            plugins: [aPlugin],
            options: {
                plugins: {
                    // @ts-ignore
                    customCanvasBackgroundColor: {
                        color: 'white',
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        displayColors: true
                    },
                    legend: {
                        display: true
                    }
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: "Angle of Incidence (deg)"
                        },
                        type: 'linear', // Adjust the scale type based on your data
                        ticks: {
                            callback: function (value) {
                                return value; // Return the formatted label
                            }
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: "Normalized Intensity"
                        }
                    }
                },
            },
        });


        this.mNoImageDivMaterialRpRs.style.display = "none";

    }
    //__________________________________________________________________________________________
    private onShowMaterialGraphSVG(pData: Array<number>, pStart: number, pEnd: number) {
        if (this.mChartDiv) {
            this.mChartDiv.style.display = "block";
        }

        let aPoints = this._convertArrayToPointObjects(pData, pStart, pEnd);
        this.mRefractiveData = aPoints;
        let aLabels = aPoints.map(item => item.x)
        let aData = aPoints.map(item => item.y)
        let aLineColor = ColorUtils.hexToRGB(Colors.PROGRAM_BLUE);
        let aLineData = new Array<Array<iSingleSVGLineData>>();
        aLineData.push([{
            color: { r: aLineColor.r, g: aLineColor.g, b: aLineColor.b, a: 1 },
            points: aPoints
        }]);

        let aMinVal = OP3DMathUtils.min(pData);
        let aMaxVal = OP3DMathUtils.max(pData);

        if (aMinVal == aMaxVal) {
            aMinVal = 0;
            aMaxVal *= 2;
        }

        let data = {
            labels: aLabels,
            datasets: [
                {
                    label: 'n',
                    data: aData,
                    fill: false,
                    backgroundColor: 'rgba(35, 167, 222, 1)',
                    color: 'rgba(35, 167, 222, 1)',
                    borderColor: 'rgba(35, 167, 222, 1)',
                    borderWidth: 1,
                    pointRadius: 0
                }]
        };

        let aGraphCanvas = document.createElement('canvas');
        ViewUtils.clearElementsChildren(this.mChartDiv)
        this.mChartDiv.appendChild(aGraphCanvas);
        aGraphCanvas.id = Op3dUtils.idGenerator();
        let aBB = this.mChartDiv.getBoundingClientRect();
        aGraphCanvas.width = aBB.width;
        aGraphCanvas.height = aBB.height;
        const aPlugin = {
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#99ffff';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        };
        var ctx = aGraphCanvas.getContext("2d");
        new Chart(ctx, {
            type: 'line',
            data: data,
            plugins: [aPlugin],
            options: {
                plugins: {
                    // @ts-ignore
                    customCanvasBackgroundColor: {
                        color: 'white'
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        displayColors: true
                    },
                    legend: {
                        display: true
                    }
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: "Wavelength (nm)"
                        },
                        type: 'linear', // Adjust the scale type based on your data
                        ticks: {
                            callback: function (value) {
                                return value; // Return the formatted label
                            }
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: "Refractive Index"
                        }
                    }
                },
            },
        });

        this.mNoImageDivMaterial.style.display = "none";
    }
    //__________________________________________________________________________________________
    private _convertArrayToPointObjects(pData: Array<number>, pStart: number, pEnd: number): Array<iSVGPoint> {
        let aArrayOfPoints = new Array<iSVGPoint>();
        for (let i = pStart; i < pEnd; i++) {
            aArrayOfPoints.push({ x: i, y: pData[i] });
        }

        return aArrayOfPoints;
    }
    //__________________________________________________________________________________________
    /**
     * @description - initialized the charts at open of form
     * @param pMaterialVO  - material to set
     */
    //__________________________________________________________________________________________
    private _setFields(pMaterialVO?: iMaterialVO) {
        if (pMaterialVO == null) {
            this._setDefaultMaterial();
        } else {
            this._setMaterialBy(pMaterialVO.name);
            this._onGetMaterial(pMaterialVO);
        }

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    /**
     * @param pMateriaName  -the material name to set
     * @description - this function loads material by name received 
     * in parameter and update the charts accordingly
     */
    //__________________________________________________________________________________________
    private _setMaterialBy(pName: string) {
        if (pName == null) {
            this.mMaterialsDD.selectedIndex = 0;
            return;
        }

        let aIndex = ViewUtils.getDropDownItemIndex(this.mMaterialsDD, pName);
        if (aIndex != -1) {
            this.mMaterialsDD.selectedIndex = aIndex;
            let aParentElement = this.mMaterialsDD.parentElement;
            if (aParentElement != null) {
                let aMaterialsBtn = aParentElement.getElementsByClassName("filter-option-inner-inner")[0];
                if (aMaterialsBtn) {
                    aMaterialsBtn.innerHTML = pName;
                }
            }
        } else {
            this.mMaterialsDD.selectedIndex = 0;
        }

        $(this.mMaterialsDD).selectpicker('refresh');
    }
    //__________________________________________________________________________________________
    /**
     * @description - initializes the rp-rs chart svg class instance 
     */
    //__________________________________________________________________________________________
    private _initRpRsSVG() {
        if (this.mChartDivRpRs == null) {
            this.mChartDivRpRs = this._getPart("rp-rs-chart-svg", true);
            this.mChartDivRpRs.style.display = "block";
        }
    }
    //__________________________________________________________________________________________
    protected _onHidden() {
        // this.mMaterialChartSVG.close();
        // this.mRpRsChartSVG.close();
    }
    //__________________________________________________________________________________________
    /**
     * @description - initializes the material chart svg class instance 
     */
    //__________________________________________________________________________________________
    private _initMaterialSVG() {
        if (this.mChartDiv == null) {
            this.mChartDiv = this._getPart("material-chart-svg", true);
            this.mChartDiv.style.display = "block";
        }
    }
    //______________________________________________________________________________________________
}
