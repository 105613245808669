﻿import { ePARAM_TYPE } from "../../../../_context/Enums";
import { EventsContext } from "../../../../_context/EventsContext";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO, iOpticsVOPhysicalData } from "../../../../data/VO/OpticsVOInterfaces";
import { ViewUtils } from "../../../ViewUtils";
import { iParamInfo, iParamInfoChange } from "../uoGeometricalInfo";
import { uoSection } from "../uoSection";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { EventManager } from "../../../../../oc/events/EventManager";
import { OP3DMathUtils } from "../../../../_utils/OP3DMathUtils";

export class uoPhysicalInfoNew extends uoSection<iOpticsVO, iOpticsVOPhysicalData> {

    private static SKIN_PATH = './skins/forms/optics/uo_geometrial_info.html'

    private static PARAMS_INFO: iHash<iParamInfo> = {
        blaze_wavelength: {
            name: 'Blaze wavelength',
            type: ePARAM_TYPE.CUSTOM_NUMBER,
            unit: 'nm'
        },
        order: {
            name: 'Order',
            type: ePARAM_TYPE.NON_DIMENTIONAL_NUMBER
        },
        blaze_angle: {
            name: 'Blaze angle',
            type: ePARAM_TYPE.ANGLE_DEG,
        },
        grating_side: {
            name: 'Grating side',
            type: ePARAM_TYPE.Array,
            show: () => false
        },
        grooves: {
            name: 'Grooves/mm',
            type: ePARAM_TYPE.CUSTOM_NUMBER,
            unit: 'mm<sup>-1</sup>'
        },
        cut_on: {
            name: 'Cut-on wavelength',
            type: ePARAM_TYPE.CUSTOM_NUMBER,
            unit: 'nm'
        },
        cut_off: {
            name: 'Cut-off wavelength',
            type: ePARAM_TYPE.CUSTOM_NUMBER,
            unit: 'nm'
        },
        cwl: {
            name: 'Center wavelength',
            type: ePARAM_TYPE.CUSTOM_NUMBER,
            unit: 'nm'
        },
        bw: {
            name: 'Bandwidth',
            type: ePARAM_TYPE.CUSTOM_NUMBER,
            unit: 'nm'
        },
        bs_transmission: {
            name: 'Transmission',
            type: ePARAM_TYPE.PERCENTAGE_NUMBER,
            unit: '%',
            onChange: (pData: iParamInfoChange<HTMLInputElement>) => {
                let aValue = parseFloat(pData.element.value);
                aValue = OP3DMathUtils.clampValue(aValue, 0, 100);
                pData.element.value = aValue.toString();
                let aT = $(pData.parent).find('input[param_key="bs_reflection"]')[0] as HTMLInputElement;
                aT.value = (100 - aValue).toString();
            },
            isEnabled: false
        },
        bs_reflection: {
            name: 'Reflection',
            type: ePARAM_TYPE.PERCENTAGE_NUMBER,
            unit: '%',
            onChange: (pData: iParamInfoChange<HTMLInputElement>) => {
                let aValue = parseFloat(pData.element.value);
                aValue = OP3DMathUtils.clampValue(aValue, 0, 100);
                pData.element.value = aValue.toString();
                let aT = $(pData.parent).find('input[param_key="bs_transmission"]')[0] as HTMLInputElement;
                aT.value = (100 - aValue).toString();
            },
            isEnabled: false
        }
    }

    private mOneParamElement: HTMLElement;
    private mParamsParent: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoPhysicalInfoNew.SKIN_PATH,
            title: 'Optics physical properties',
            collapseQaId: "uo_physical_info_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: true
        });
    }
    //__________________________________________________________________________________________
    protected async _setData(pOpticsVO: iOpticsVO) {
        this._clear();
        this._fillData(pOpticsVO.parameters.physical_data as iOpticsVOPhysicalData);
    }
    //__________________________________________________________________________________________
    private _fillData(pOpticsVOPhysicalData: iOpticsVOPhysicalData) {
        for (let key in pOpticsVOPhysicalData) {
            let aParamInfo = uoPhysicalInfoNew.PARAMS_INFO[key];
            if (null == aParamInfo) {
                continue;
                //throw new Error("Key is not defined!");
            }

            if ((null != aParamInfo.show) &&
                (false == aParamInfo.show(pOpticsVOPhysicalData[key]))) {
                continue;
            }

            let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
            this.mParamsParent.appendChild(aOneParam);
            let aParamName = Op3dUtils.getElementIn(aOneParam, 'param_name');
            if (aParamName === null) {
                throw new Error("Param name not found!");
            }

            aParamName.innerHTML = aParamInfo.name;


            let aValue = pOpticsVOPhysicalData[key];
            let aUnitParam = Op3dUtils.getElementIn(aOneParam, 'unit_span') as HTMLElement;
            switch (aParamInfo.type) {
                case ePARAM_TYPE.DIMENTIONAL_NUMBER:
                    aUnitParam.innerHTML = UnitHandler.shortSign;
                    aValue *= UnitHandler.presentedScale;
                    break;
                case ePARAM_TYPE.ANGLE_RAD:
                    aUnitParam.innerHTML = 'Rad';
                    break;
                case ePARAM_TYPE.PERCENTAGE_NUMBER:
                    aUnitParam.innerHTML = aParamInfo.unit + '';
                    aValue *= 100;
                    break;
                case ePARAM_TYPE.CUSTOM_NUMBER:
                    aUnitParam.innerHTML = aParamInfo.unit + '';
                    break;
                default:
                    ViewUtils.removeFromParent(aUnitParam);
                    break;
            }


            let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as HTMLInputElement;
            aInput.value = aValue.toString();
            aInput.title = aValue.toString();

            aInput.setAttribute('param_key', key);
            aInput.addEventListener('change', () => {
                if (null != aParamInfo.onChange) {
                    aParamInfo.onChange({ parent: this.mParamsParent, element: aInput });
                }

                this._onChange();
            });

            aInput.disabled = (false == aParamInfo.isEnabled);
        }

        let aHasPresentedData = (this.mParamsParent.children.length > 0);
        ViewUtils.setElementVisibilityByDNone(this.mContainer, aHasPresentedData);
    }
    //__________________________________________________________________________________________
    private _onChange() {
        EventManager.dispatchEvent(EventsContext.OPTICS_PHYSICAL_DATA_SECTION_CHANGED, this);
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO): void {
        let aPhysicalData = this.getData();
        // for (let key in pOpticsVO.parameters.physical_data) {
        //     pOpticsVO.parameters.physical_data[key] = aPhysicalData[key];
        // }

        for (let key in aPhysicalData) {
            pOpticsVO.parameters.physical_data[key] = aPhysicalData[key];
        }
        // pOpticsVO.parameters.physical_data = aPhysicalData;
    }
    //__________________________________________________________________________________________
    public getData() {
        let aData: iOpticsVOPhysicalData = {

        };

        $(this.mParamsParent).find('input[param_key]').each(
            (_index, element) => {
                let aKey = element.getAttribute('param_key');
                if (aKey != null) {
                    let aElement = uoPhysicalInfoNew.PARAMS_INFO[aKey];
                    let aVal = parseFloat((element as HTMLInputElement).value);

                    switch (aElement.type) {
                        case ePARAM_TYPE.PERCENTAGE_NUMBER:
                            aVal /= 100;
                            break;
                        default:
                            break;
                    }

                    aData[aKey] = aVal;
                }
            }
        );

        return aData;
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, false);
        ViewUtils.removeElementChildren(this.mParamsParent);
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param');
        this.mParamsParent = this.mOneParamElement.parentElement as HTMLElement;
        this._clear();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
    }
    //__________________________________________________________________________________________
}
