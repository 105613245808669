import classes from "../../UI/optichat-info.module.scss";

export default function OptiChatNavbarOption({
  idx,
  title,
  active,
  onChangeOption,
}) {
  return (
    <>
      <div
        onClick={() => onChangeOption(title, idx)}
        className={`${classes.option} ${active && classes.active}`}
      >
        <div className={classes.text}>{title}</div>
        <div className={classes.underline}></div>
      </div>
    </>
  );
}
