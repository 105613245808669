import { EventManager } from "../../oc/events/EventManager";
import { eGeneralWebsocketType } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { iEventData } from "./ServerContext";

export class WebsocketEventsHandler {


    //______________________________________________________________________________________________
    public static handleWebsocketEvent(pEvent: iEventData<eGeneralWebsocketType>) {
        switch (pEvent.type) {
            case eGeneralWebsocketType.CLIENT_LINE_ITEMS_CHANGED:
                Op3dContext.USER_VO.updateLicenseData(pEvent.payload.license, pEvent.payload.limit, pEvent.payload.cad_parts_limit);
                Op3dContext.USER_PERMISSION.update(pEvent.payload.permissions);
                break;
            case eGeneralWebsocketType.CLIENT_TIMERS_CHANGED:
                Op3dContext.USER_VO.updateLicenseData(pEvent.payload.license);
                EventManager.dispatchEvent(EventsContext.TIMERS_UPDATE, this);
                break;
            case eGeneralWebsocketType.SETUP_IS_OPEN:
                if (undefined === Op3dContext.SETUPS_MANAGER) {
                    return;
                }

                Op3dContext.SETUPS_MANAGER.closeOpenSetup(false, pEvent.payload);
                break;
        }
    }
    //______________________________________________________________________________________________
}
