import { EventBase } from "../../../../oc/events/EventBase";
import { EventManager } from "../../../../oc/events/EventManager";
import { ePolarizationSourceType } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iSize } from "../../../_context/_interfaces/Interfaces";
import { tMimeType } from "../../../_utils/FileUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { SnapshotTools } from "../../../_utils/SnapshotTools";
import { UnitHandler } from "../../../units/UnitsHandler";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { RBGroup } from "../../components/RBGroup";
import { MinimizedNavbar } from "../../home/MinimizeNavbar";
import { AnalysesSynchronizer, iLockAnalsysData } from "../AnalysesSynchronizer";
import { AnalysisCache, iAnalysisCacheData, iAnalysisQuery2 } from "../AnalysisCache";
import { AnalysisContext, eAnalysisDisplay, eAnalysisType, iAnalysisItem } from "../AnalysisContext";
import { anWavelengthsComponent } from "../anWavelengthComponent";
import { AnalysisFooter } from "./AnalysisFooter";
import { iAnalysisData } from "./AnalysisViewFactory";
import html2canvas from "html2canvas";
import { QuickViewHandler } from "../QuickViewHandler";
import { AnalysisConstants } from "../AnalysisConstants";
import { eLogarithmicView, eExtendedViewType } from "../ResultFactory/anAnalysisResultyFactory";

export abstract class ExtendedViewNew<T extends iAnalysisData> extends Op3dComponentBase<T>{

    protected static ZOOM_DELTA = 0.1;

    private static ANALYSIS_IDX = 0;
    private mId = Op3dUtils.idGenerator();
    private mTitle: HTMLElement;
    private mLockBtn: HTMLElement;
    private mViewTypeDD: HTMLElement;
    protected mWavelengthsComponent: anWavelengthsComponent;
    protected mFooter: AnalysisFooter;
    protected mCurrPolarizationType: ePolarizationSourceType = ePolarizationSourceType.X;
    private mPolarizationRB: RBGroup;
    private mFormTitle: HTMLElement;
    protected mIsShown = false;
    protected mIsLogView: boolean = false;
    protected mColorMapOpt: HTMLElement;

    constructor() {
        super({
            container: ExtendedViewNew._getContainer(),
            skinPath: './skins/forms/analysis/analysis_extended_view_new.html',
            draggableParams: {
                class: "draggable-form",
                containment: 'window',
                handle: ".modal-header"
            },
            minimizeData: {
                isAnalysisItem: true,
                title: "Analysis Extended View",
                showCallback: () => this._onMaximize(),
                htmlItem: null,
                iconClasses: "icon-chart-magnify",
            }
        })
    }
    //__________________________________________________________________________________________
    public static getAllWavelengthsOfItem(pAnalysisitem: iAnalysisItem) {
        let aAllWls = AnalysisCache.getWavelengthsForAnalysis({
            id: pAnalysisitem.id,
            name: pAnalysisitem.name,
            polarization: ePolarizationSourceType.X,
            polarizationKind: ePolarizationSourceType.X
        });

        return aAllWls;

    }
    //__________________________________________________________________________________________
    private _onMaximize() {
        this.mData.fromMinimize = true;
        this.show();
    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();

        AnalysisCache.removeEventListener(this.mId);
        ViewUtils.removeFromParent(this.mContainer);

        this.mIsShown = false;
    }
    //__________________________________________________________________________________________
    private _onLogView(pLogType: eLogarithmicView) {
        this.mIsLogView = false;
        switch (pLogType) {
            case eLogarithmicView.LOG_10:
                ViewUtils.setElementDisabled(this.mColorMapOpt, true)
                this.mData.cacheData.matrix.convertLog10();
                this.mViewTypeDD.setAttribute("view_type", "log-10");
                this.mIsLogView = true;
                this.mFooter.setDataTypeVisibility(true)
                break;
            case eLogarithmicView.NORMALIZED:
                ViewUtils.setElementDisabled(this.mColorMapOpt, true)
                this.mData.cacheData.matrix.normalizeMatrix();
                this.mViewTypeDD.setAttribute("view_type", "normalized");
                this.mIsLogView = false;
                this.mFooter.setDataTypeVisibility(false);
                break;
            case eLogarithmicView.LINEAR:
                ViewUtils.setElementDisabled(this.mColorMapOpt, false)
                this.mViewTypeDD.setAttribute("view_type", "linear");
                this.mFooter.setDataTypeVisibility(false)
                this.mData.cacheData.matrix.convertToLinear();
        }
        this._drawGraph();
    }
    //__________________________________________________________________________________________
    protected _setCurrentGraphData() { }
    //__________________________________________________________________________________________
    private _onChangePolarizationType(pPolarizationType: ePolarizationSourceType): void {
        this.mCurrPolarizationType = pPolarizationType;
        let aId = this.mData.analysisItem.id;
        let aAnalysis = structuredClone(AnalysisCache.getResultsByIdAndPolarizationType(aId, pPolarizationType)[0]);
        if (aAnalysis !== undefined) {
            this.mData.cacheData = aAnalysis;

            let aQuery: iAnalysisQuery2 = {
                polarization: pPolarizationType,
                polarizationKind: aAnalysis.common_data.polarization_kind,
                name: this.mData.analysisItem.name,
                id: aId,
                wavelengths: this.mWavelengthsComponent.getCheckedWL()
            }

            this.onUpdateAnalysisAndWavelength(aQuery, false);
            this.mFooter.update(this.mData, false);
        }
    }
    //__________________________________________________________________________________________
    protected _getCacheData(pWavelengths: Array<number>) {

        let aCacheData = AnalysisCache.getSpecificCombinedData(this._getQuery(pWavelengths), true);
        return aCacheData;
    }
    //__________________________________________________________________________________________
    protected _getPixelsText() {
        const aResolution = this.mData.cacheData.common_data.resolution;
        let aTxt = aResolution.x + "W x " + aResolution.y + "H";
        return aTxt;
    }
    //__________________________________________________________________________________________
    protected _getSizeInMM(): iSize {
        let aCommonData = this.mData.cacheData.common_data;
        let aSize: iSize = {
            height: ((aCommonData.y_range.max - aCommonData.y_range.min)),
            width: ((aCommonData.x_range.max - aCommonData.x_range.min))
        }

        return aSize;
    }
    //__________________________________________________________________________________________
    protected _getSizeText() {
        let aScale = UnitHandler.presentedScale;
        let aSizeInMM = this._getSizeInMM();
        let aWidth = aSizeInMM.width * aScale;
        let aHeight = aSizeInMM.height * aScale;
        const aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        let aTxt = OP3DMathUtils.toFixed(aWidth, aToFixed) +
            "W x " + OP3DMathUtils.toFixed(aHeight, aToFixed) + "H";
        return aTxt;
    }
    //__________________________________________________________________________________________
    protected _addSheet(pWB: XLSX.WorkBook, pKey: string, pBase: any[][], pContent: any[][]) {
        pWB.SheetNames.push(pKey);
        pWB.Sheets[pKey] = XLSX.utils.aoa_to_sheet([...pBase, ...pContent]);
    }
    //__________________________________________________________________________________________
    protected abstract _onDownloadXLSX(): void;
    //__________________________________________________________________________________________
    protected static getHitsText(pTotalHits: Number) {
        let aNum = OP3DMathUtils.formatLargeNumber(pTotalHits);
        return aNum;
    }
    //__________________________________________________________________________________________
    private _onLockChanged(pLockData: iLockAnalsysData) {
        if (this.mData && this.mData.analysisItem.id == pLockData.analysisID) {
            ViewUtils.setClassForElement(this.mLockBtn, "open", !pLockData.isLocked);
        }
    }
    //__________________________________________________________________________________________
    private _lockItem(e: Event) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        let aIsOpen = ViewUtils.toggleClass(this.mLockBtn, "open");
        AnalysesSynchronizer.instance.setLockedItems({
            analysisID: this.mData.analysisItem.id,
            isLocked: !aIsOpen
        }, true);
    }
    //__________________________________________________________________________________________
    protected _onClickQV() {
        QuickViewHandler.instance.addAnalysis({
            analysisId: this.mData.analysisItem.id,
            display: this.mData.analysisItem.display,
            name: this.mData.analysisItem.name,
            faceID: this.mData.cacheData.common_data.faceId
        });

        this.close();
    }
    //__________________________________________________________________________________________
    public static returnClearPolarizationAnalysisId(pId: string) {

        if (pId.startsWith('N_') || pId.startsWith('Y_') || pId.startsWith('Z_')) {
            return pId.slice(2)
        }
        return pId;
    }
    //__________________________________________________________________________________________
    public static returnPolarizationAnalysisIdByType(pPolarizationKind: ePolarizationSourceType, pId: string) {

        let aId = ExtendedViewNew.returnClearPolarizationAnalysisId(pId);
        switch (pPolarizationKind) {
            case ePolarizationSourceType.NONE:
                return 'N_' + aId;
            case ePolarizationSourceType.X:
                return aId;
            case ePolarizationSourceType.Y:
                return 'Y_' + aId;
            case ePolarizationSourceType.Z:
                return 'Z_' + aId;
        }
    }
    //__________________________________________________________________________________________
    private _initLogView() {
        let aLog10Opt = this._getPart("log-10-view", true);
        aLog10Opt.addEventListener("click", () => this._onLogView(eLogarithmicView.LOG_10));

        let aLinearOpt = this._getPart("linear-view", true)
        aLinearOpt.addEventListener("click", () => this._onLogView(eLogarithmicView.LINEAR));
        this.mViewTypeDD = aLog10Opt.parentElement!;

        let aNormalizedMatrix = this._getPart('normalized_matrix');
        switch (this.mData.analysisItem.name) {
            case "Fresnel PSF":
            case "Huygens PSF":
                aNormalizedMatrix.addEventListener("click", () =>
                    this._onLogView(eLogarithmicView.NORMALIZED));
                break;
            default:
                aNormalizedMatrix.parentElement.removeChild(aNormalizedMatrix);
                break;


        }
    }
    //__________________________________________________________________________________________
    protected _onResize() { }
    //______________________________________________________________________________________________
    protected getXLSXHeader() {
        const aDate = Op3dUtils.getDate();
        let aBase = Array<Array<any>>();
        aBase[0] = ["Date", aDate];
        aBase[1] = ["Name", Op3dContext.SETUPS_MANAGER.fileName];
        aBase[2] = ["Size", this._getSizeText() + " Pixels " + this._getPixelsText()];
        aBase[3] = ["Total hits", ExtendedViewNew.getHitsText(this.mData.cacheData.common_data.total_hits)];
        aBase[4] = ["Data type", this.mData.analysisItem.name];
        return aBase;
    }
    //______________________________________________________________________________________________
    private _initResize() {
        $(this.mContainer).resize(() => {
            $(this.mContainer).draggable({
                snap: true,
                handle: '.modal-header',
                containment: [-this.mContainer.clientWidth + this.mContainer.clientWidth * 0.1, 0, window.innerWidth
                    - this.mContainer.clientWidth * 0.1, window.innerHeight - this.mContainer.clientHeight * 0.2]
            });

            this._onResize()
        });

        $(window).resize(() => {
            $(this.mContainer).draggable({
                handle: '.modal-header',
                containment: [-this.mContainer.clientWidth + this.mContainer.clientWidth * 0.1, 0, window.innerWidth
                    - this.mContainer.clientWidth * 0.1, window.innerHeight - this.mContainer.clientHeight * 0.2]
            });
        });

        $(this.mContainer).draggable({
            handle: '.modal-header',
            containment: [-this.mContainer.clientWidth + this.mContainer.clientWidth * 0.2, 0, window.innerWidth
                - this.mContainer.clientWidth / 2, window.innerHeight - this.mContainer.clientHeight / 2]
        });
    }
    //__________________________________________________________________________________________
    private _initQv() {
        let aQVBtn = this._getPart("qv-btn", true);
        if (this.mData.viewType == eExtendedViewType.LINE ||
            this.mData.analysisItem.type == eAnalysisType.ADVANCED) {
            ViewUtils.removeFromParent(aQVBtn)
        } else {
            aQVBtn.addEventListener("click", () => this._onClickQV());
        }
    }
    //__________________________________________________________________________________________
    private _initHeaderControllers() {
        let aReset = this._getPart("reset-btn", true);
        aReset.addEventListener("click", () => this._onReset());

        let aZoomIn = this._getPart("zoom-in", true);
        aZoomIn.addEventListener("click", () => this._onZoom(-1));

        let aZoomOut = this._getPart("zoom-out", true);
        aZoomOut.addEventListener("click", () => this._onZoom(1));
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this._initLogView();
        this._initPolarizationRB();
        this._initResize();
        this._initQv();
        this._initHeaderControllers();

        this.mTitle = this._getPart("title", true);
        this.mLockBtn = Op3dUtils.getElementIn(this.mContainer, "lock-btn", true)!;
        this.mLockBtn.addEventListener("click", (e) => this._lockItem(e));

        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip({ trigger: "hover" })

        $(this.mContainer).find('[data-toggle="popover"]').popover({
            trigger: 'hover',
            html: true,
            content: `<p>Select area to zoom</p><p>Left click at the selected pixel will generate an XY cross-section graph</p>`
        });

        EventManager.addEventListener(EventsContext.ON_CLOSE,
            () => this.close(), this);

        $(this.mContainer).on('click', '.dropdown-menu', function (e) {
            e.stopPropagation();
        });

        this._getPart('size_unit').innerHTML = `[${UnitHandler.shortSign}]`;

        this.mFooter = new AnalysisFooter(this._getPart("analysis-footer", true));
        this.mWavelengthsComponent = new anWavelengthsComponent(
            this._getPart("wavelengths-parent", true),
            (pWavelengths: Array<number>) => this._onWavelengthsChanged(pWavelengths));


        // add event listeners
        AnalysisCache.addEventListener(this.mId,
            () => this.onUpdateAnalysisAndWavelength(this._getQuery(), true),
            [this.mData.analysisItem.id], false);

        this._getPart("download-png", true).addEventListener("click", () => this._onDownloadImage("image/png"));
        this._getPart("download-jpg", true).addEventListener("click", () => this._onDownloadImage("image/jpg"));
        this._getPart("download-xlsx", true).addEventListener("click", () => this._onDownloadXLSX());

        this.mColorMapOpt = this._getPart("color-map-range", true);

        EventManager.addEventListener<iLockAnalsysData>(EventsContext.LOCKED_ANALYSIS,
            (pData: EventBase<iLockAnalsysData>) => this._onLockChanged(pData.data), this);
    }
    //__________________________________________________________________________________________
    private _initPolarizationRB() {
        this.mPolarizationRB = new RBGroup<ePolarizationSourceType>(this._getPart('rb_parent'), {
            onChange: (pPolarizationType) => this._onChangePolarizationType(pPolarizationType),
            radioButtons: [{
                label: 'None',
                value: ePolarizationSourceType.NONE
            }, {
                label: 'X',
                value: ePolarizationSourceType.X
            }, {
                label: 'Y',
                value: ePolarizationSourceType.Y
            }, {
                label: 'Z',
                value: ePolarizationSourceType.Z
            }],
            defaultValue: ePolarizationSourceType.X,
            styleOptions: {
                gap: 0,
                width: 93
            }
        });
    }
    //__________________________________________________________________________________________
    private async _onDownloadImage(pMimeType: tMimeType) {
        let aDoc = this._getPart('snapshot_try') as HTMLElement;

        await Op3dContext.sleep(100);

        $(this.mContainer).find('.not-snapshot').each((_index, element) => {
            (element as HTMLElement).style.visibility = "hidden";
        });
        html2canvas(aDoc).then(canvas => {

            let aAnalysisName: string = this.mData.cacheData.name;
            $(this.mContainer).find('.not-snapshot').each((_index, element) => {
                (element as HTMLElement).style.visibility = "visible";
            });

            SnapshotTools.downloadImageFromCanvas({
                canvas: canvas,
                mimeType: pMimeType,
                name: aAnalysisName
            });
        });
    }
    //__________________________________________________________________________________________
    protected _getQuery(pWavelengths?: Array<number>) {
        let aWavelengths = pWavelengths !== undefined ? pWavelengths : this.mWavelengthsComponent.getCheckedWL();

        //added id transform to polarized type. If kind === x => return origianl id
        let aPolarizationId = ExtendedViewNew.returnPolarizationAnalysisIdByType(this.mCurrPolarizationType, this.mData.analysisItem.id)

        let aQuery: iAnalysisQuery2 = {
            polarization: ePolarizationSourceType.X,
            polarizationKind: this.mCurrPolarizationType,
            name: this.mData.analysisItem.name,
            id: aPolarizationId,
            wavelengths: aWavelengths
        }

        return aQuery;
    }
    //__________________________________________________________________________________________
    protected onUpdateAnalysisAndWavelength(pQuery: iAnalysisQuery2, pUpdateWlList: boolean) {
        if (AnalysesSynchronizer.instance.isLocked(pQuery.id)) {
            return;
        }

        if (this.mIsVisible == false) {
            return;
        }

        let aUseSingleWl = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
            item.name === pQuery.name).useOneWavelength === true;


        // let aId = ExtendedViewNew.returnPolarizationAnalysisIdByType(pQuery.polarizationKind, pQuery.id)
        // pQuery.id = aId;
        let aCacheData = AnalysisCache.getSpecificCombinedData(pQuery, aUseSingleWl);
        if (aCacheData === undefined) {

            for (let i = 0; i < 4; i++) {
                pQuery.id = ExtendedViewNew.returnPolarizationAnalysisIdByType(i, pQuery.id)
                pQuery.polarizationKind = i;
                aCacheData = AnalysisCache.getSpecificCombinedData(pQuery, aUseSingleWl);
                if (aCacheData !== undefined) {
                    this._updateRadioButtons(i)
                    break;
                };
            }
        }

        this.mData.cacheData = aCacheData;
        if (pUpdateWlList === true) {
            this._updateWavelengthList();
        }
        this._drawGraph();
    }
    //__________________________________________________________________________________________
    protected _onReset() { }
    //__________________________________________________________________________________________
    protected _onZoom(_pZoomLevel: number) { }
    //__________________________________________________________________________________________
    private static _getContainer() {
        let aFormsContainer = document.getElementById('forms');
        if (aFormsContainer === null) {
            throw new Error("forms container not found");
        }

        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('fade');
        aDiv.classList.add('overflow-hidden');
        aDiv.setAttribute("data-backdrop", "false");
        aDiv.style.resize = "both";
        aDiv.style.minWidth = 500 + 'px';
        aDiv.style.maxWidth = '95vw';
        aDiv.style.minHeight = 665 + 'px';
        aDiv.style.maxHeight = '95vh'
        aDiv.style.height = 620 + 'px';
        aDiv.style.width = 500 + 'px';
        aDiv.id = "analysis-screens" + ExtendedViewNew.ANALYSIS_IDX++;
        aFormsContainer.appendChild(aDiv);
        return aDiv;
    }
    //__________________________________________________________________________________________
    protected _onMinimizeForm() {
        if (this.mParams.minimizeData !== undefined) {
            const aName = this.mData?.analysisItem.name;
            this.mParams.minimizeData.title = aName;
            this.mParams.minimizeData.analysisType = this.mData.analysisItem.type;
            let aColor = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.name === aName && this.mData?.analysisItem.type === item.type).color;
            this.mParams.minimizeData.labelClasses = aColor
            //AnalysisContext.getLabelColorClassByName(aName,  this.mData?.analysisItem.type),
            MinimizedNavbar.instance.addItem(this.mParams.minimizeData,
                this.mFormTitle.innerText + " " + aName);
            this.hide();
        }
    }
    //__________________________________________________________________________________________
    protected _onWavelengthsChanged(pWavelengths: Array<number>) {
        let aCacheData: iAnalysisCacheData;
        if (pWavelengths.length == 0) {

            aCacheData = AnalysisCache.getEmptyMatrix({
                polarization: ePolarizationSourceType.X,
                id: this.mData.analysisItem.id,
                name: this.mData.cacheData.name,
                wavelengths: pWavelengths,
                polarizationKind: ePolarizationSourceType.X
            });

        } else {
            aCacheData = this._getCacheData(pWavelengths);
        }

        this.mData.cacheData = aCacheData;
        this._drawGraph();
    }
    //__________________________________________________________________________________________
    protected abstract _drawGraph(): void;
    //__________________________________________________________________________________________
    private _updateRadioButtons(pPolarization: ePolarizationSourceType) {
        this.mPolarizationRB.update({
            onChange: (pPolarizationType) => this._onChangePolarizationType(pPolarizationType),
            radioButtons: [{
                label: 'None',
                value: ePolarizationSourceType.NONE
            }, {
                label: 'X',
                value: ePolarizationSourceType.X
            }, {
                label: 'Y',
                value: ePolarizationSourceType.Y
            }, {
                label: 'Z',
                value: ePolarizationSourceType.Z
            }],
            defaultValue: pPolarization,
        })
    }
    //__________________________________________________________________________________________
    private _initFormTitle() {
        let aPart = Op3dContext.PARTS_MANAGER.getPartByFaceID(this.mData.cacheData.common_data.faceId);
        let aName = aPart.getIndexedLabel(false);
        let aNameHtml = aPart.getIndexedLabel(true);
        let aSurfaceName = Op3dContext.PARTS_MANAGER.getFaceNameById(this.mData.cacheData.common_data.faceId);

        this.mFormTitle = this._getPart("analysis-title");
        this.mFormTitle.innerHTML = aNameHtml + " - " + aSurfaceName;
        this.mFormTitle.title = aName + " - " + aSurfaceName;

        if (this.mData.polarizationKind !== undefined) {
            this.mCurrPolarizationType = this.mData.polarizationKind;
            this.mData.cacheData.common_data.polarization_kind = this.mCurrPolarizationType
            this._updateRadioButtons(this.mCurrPolarizationType);
        }
    }
    //__________________________________________________________________________________________
    protected _setDisplayType(pDsisplayType: eAnalysisDisplay) {
        this.mContainer.setAttribute("display_type", pDsisplayType);
    }
    //__________________________________________________________________________________________
    protected _onShown(): void {

        this._initFormTitle();
        this._setDisplayType(this.mData.analysisItem.display);
        this._updateWavelengthList();

        AnalysisCache.addEventListener(this.mId,
            () => this.onUpdateAnalysisAndWavelength(this._getQuery(), true),
            [this.mData.analysisItem.id], false);

        this._setCurrentGraphData();
        this._drawGraph();

        let aIsLogView = false;//this.mOptions.isLogView
        this.mFooter.update(this.mData, aIsLogView);

        let aAnalysisName = this.mData.cacheData.name;
        let asPolarized = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.name === aAnalysisName).isPolarized;
        if (asPolarized === true) {
            this.onUpdateAnalysisAndWavelength(this._getQuery(), false);
            this.mPolarizationRB.show(true);
        } else {
            this.mPolarizationRB.show(false);
        }

        this.mIsShown = true;
    }
    //__________________________________________________________________________________________
    protected _onOpen(): void {
        this._setTitle();

        if (this.mParams.minimizeData.isMinimized) {
            MinimizedNavbar.instance.removeItem(this.mParams.minimizeData);
        }
        this._updateWavelengthList()
        // this._clear();
        if (this.mIsVisible) {
            this._onShown();
        }
    }
    //__________________________________________________________________________________________
    private _updateWavelengthList() {
        let aTotalWavelengths = AnalysisCache.getWavelengthsForAnalysis({
            name: this.mData.cacheData.name,
            polarization: this.mData.cacheData.polarization,
            id: this.mData.analysisItem.id,
            polarizationKind: this.mCurrPolarizationType
        });

        let aIsCheckbox = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
            item.name === this.mData.cacheData.name &&
            item.type === this.mData.cacheData.common_data.analysisType).useOneWavelength !== true;

        this.mWavelengthsComponent.setData({
            wavelengths: aTotalWavelengths,
            isCheckAll: aIsCheckbox,
            isCheckbox: aIsCheckbox
        });
    }
    //__________________________________________________________________________________________
    private _setTitle() {

        ViewUtils.clearElementsChildren(this.mTitle);

        let aIcon = "";
        let aAnalysisType = this.mData.cacheData.common_data.analysisType;
        if (eAnalysisType.ADVANCED == aAnalysisType) {
            let aSimulationKind = this.mData.analysisItem.simulation_kind;

            let aIconSvg = AnalysisContext.getSimulationDDICON(aAnalysisType, aSimulationKind);
            let aIconCot = document.createElement('div');
            aIconCot.classList.add('svg_icon_class');
            aIconCot.innerHTML = aIconSvg;
            aIcon = aIconCot.outerHTML;
        }

        const aName = this.mData.cacheData.name;
        const aColor = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.name === aName && this.mData.analysisItem.type === item.type).color;
        this.mTitle.className = "analysis-label " + aColor;
        // AnalysisContext.getLabelColorClassByName(aName, this.mData.analysisItem.type);
        this.mTitle.innerHTML = aIcon + aName;
    }
    //__________________________________________________________________________________________
}