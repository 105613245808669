import { Strings } from "../../_context/Strings";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { SceneContext } from "../../scene/SceneContext";
import { Part } from "../Part";
import { iFace } from "../PartInterfaces";
import { iDetectorData } from "../_parts_assets/ExportToJSONInterfaces";
import { PartsFactory } from "../_parts_assets/PartsFactory";
import { Behavior } from "./Behavior";

export class CustomDetectorBehavior extends Behavior {

    //__________________________________________________________________________________________
    public static updateDetectorData(pPart: Part, pData: iDetectorData, pUpdateVis: boolean) {
        let aOpticsHolder = pPart.getSubpartByName(Strings.OPTICS_HOLDER);
        if (null == aOpticsHolder) {
            return;
        }

        let aDetectorData = aOpticsHolder.subParts[0].data;
        for (let key in pData) {
            const aNewData = pData[key];
            if (aNewData != null) {
                aDetectorData.detectorData[key] = aNewData;
            }
        }

        if (true == pUpdateVis) {
            CustomDetectorBehavior.drawDetector(pPart, aDetectorData.detectorData);
        }
    }
    //__________________________________________________________________________________________
    public static drawDetector(pPart: Part, pDetectorData: iDetectorData) {
        let aFacesHash: iHash<iFace> = {};
        let aFaces = PartsFactory.getDetectorFaces(pDetectorData);
        for (let i = 0; i < aFaces.length; i++) {
            aFacesHash[aFaces[i].name] = aFaces[i];
        }

        pPart.getFaces().forEach((face) => {
            let aFace = aFacesHash[face.name];
            if (null == aFace) {
                return;
            }

            let aFaceMeshParent = face.visualization.mesh.parent;
            Op3dUtils.removeObject3DFromParent(face.visualization.mesh);
            face.visualization.mesh = aFace.visualization.mesh;
            aFaceMeshParent.add(aFace.visualization.mesh);
            if (null != aFace.axes) {
                for (let j = 0; j < aFace.axes.length; j++) {
                    face.visualization.mesh.add(aFace.axes[j].object3D)
                    //aFace.visualization.mesh.add(face.axes[j].object3D);
                }
                face.axes = aFace.axes;
            }

            face.data = aFace.data;
        });

        let aOldB = pPart.visibleObj.getObjectByName(Strings.DETECTOR_B_LETTER);
        let aOldF = pPart.visibleObj.getObjectByName(Strings.DETECTOR_F_LETTER);
        let aOldCross = pPart.visibleObj.getObjectByName(Strings.DETECTOR_CROSS);
        let aCrossParent = aOldCross.parent;
        let aThickness = PartsFactory.getDetectorThickness(pDetectorData.size)
        let aNewCross = PartsFactory.getCross(pDetectorData.size.width, pDetectorData.size.height, aThickness);
        let aNewFLetter = PartsFactory.getFLetter(pDetectorData.size.width, pDetectorData.size.height);
        let aNewBLetter = PartsFactory.getBLetter(pDetectorData.size.width, pDetectorData.size.height,
            aThickness);
        aCrossParent.remove(aOldCross);
        aCrossParent.remove(aOldF);
        aCrossParent.remove(aOldB);

        aCrossParent.add(aNewCross);
        aCrossParent.add(aNewFLetter);
        aCrossParent.add(aNewBLetter);

        pPart.updatePart();
        SceneContext.OP3D_SCENE.activateRenderer();
        // Op3dContext.PARTS_MANAGER.updatePartsList();
    }
    //__________________________________________________________________________________________
}
