import { eIngredientType } from "../../_context/Enums";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { iGeneralIngrediantVOQuery, iHash, iMenuSearchQuery } from "../../_context/_interfaces/Interfaces";
import { iSourceVO, eLightSourceType } from "../../parser/SourcesParser";
import { ServerContext } from "../../server/ServerContext";
import { LightSourceUtils } from "../../simulation/LightSourceUtils";
import { NotificationCenter } from "../../ui/home/_notifications/NotificationCenter";
import { eCacheDataType } from "../CacheStorage";
import { DataLoader } from "./DataLoader";

export class SourcesDataLoader extends DataLoader<iSourceVO,
    iGeneralIngrediantVOQuery, iSourceVO, iSourceVO> {

    /////private static SUBLENS_NAME: string = "_sub_lens_";
    private static INSTANCE: SourcesDataLoader;
    private mWlWeightHash: iHash<iHash<number>> = {};

    private constructor() {
        super({
            fullDataServerCall: (pSourceVOQuery: iGeneralIngrediantVOQuery) =>
                ServerContext.SERVER.getOneIngredient({
                    number_id: pSourceVOQuery.number_id,
                    type: eIngredientType.SOURCE
                }),
            // dataConvertionFunc: (pSource: parser.iSourceVO) => this._onReceivedLightSource(pSource),
            cacheData: {
                key: 'number_id',
                type: eCacheDataType.SOURCE
            },
            addItemServerCall: (pData) =>
                ServerContext.SERVER.updateSource(pData),
            getManyItems: (pQuery) =>
                ServerContext.SERVER.getManySourcesElements(pQuery as iMenuSearchQuery),
            isExistServerCall: (pSourceVO) =>
                ServerContext.SERVER.isIngredientExists({
                    numberID: pSourceVO.number_id,
                    ingredient: eIngredientType.SOURCE
                })

        });
    }
    //__________________________________________________________________________________________
    public async getWlWeight(pLightSource: iSourceVO, pWl: number) {
        if (this.mWlWeightHash[pLightSource.number_id] == null) {
            this.mWlWeightHash[pLightSource.number_id] = {};
        }

        let aWeight = this.mWlWeightHash[pLightSource.number_id][pWl];
        if (aWeight == null) {

            aWeight = await this._getWeight(pLightSource, pWl);
            this.mWlWeightHash[pLightSource.number_id][pWl] = aWeight;
        }

        return aWeight;
    }
    //__________________________________________________________________________________________
    private async _getWeight(pLightSource: iSourceVO, pWl: number) {
        let aAmplitude: number;

        switch (pLightSource.parameters.type) {
            case eLightSourceType.BLACKBODY:
                aAmplitude = LightSourceUtils.getBlackbodyWeight(
                    pWl,
                    pLightSource.parameters.data.temperature,
                    pLightSource.parameters.data.center_wavelength);
                break;
            case eLightSourceType.GAUSSIAN:
                aAmplitude = LightSourceUtils.getGaussianAmplitude(
                    pWl,
                    pLightSource.parameters.data.center_wavelength,
                    pLightSource.parameters.data.bandwidth);
                break;
            default: {
                let aRes = await ServerContext.SERVER.getWeightByWl({
                    number_id: pLightSource.number_id,
                    wl: pWl
                });
                if (aRes.success) {
                    aAmplitude = aRes.data;
                } else {
                    NotificationCenter.instance.pushNotification({
                        message: MessagesHandler.ERROR_CALCULATING_WAVELENGTH_WEIGHT,
                        params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
                    });
                }
            }
        }

        return aAmplitude;
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
}
