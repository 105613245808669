import { useRef, useState } from "react";
import styled from "styled-components";
import { ViewUtils } from "../../ui/ViewUtils";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import { iPoint2D } from "../../_context/_interfaces/Interfaces";

export const StyledDraggableObject = styled.div`
  box-shadow: 0px 3px 23px 0px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  height: inherit;
  width: inherit;
`;

export default function DraggableForm({ right, bottom, children, handle }) {
  const [focused, setFocused] = useState(true);
  // const [position, setPosition] = useState({ x: 0, y: 0 });

  const draggableRef = useRef();
  const onMouseDown = () => {
    $(document.body)
      .find(".focused-elem")
      .each((_idx, elem) => {
        ViewUtils.setClassForElement(
          elem as HTMLElement,
          "focused-elem",
          false
        );
      });

    setFocused(true);
  };

  // const resizeHandler = (e) => {
  //   console.log("resize", e);
  //   console.log("draggableRef", draggableRef);

  //   let aPosition = defaultPosition();
  //   setPosition(aPosition);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", resizeHandler);

  //   return () => {
  //     window.removeEventListener("resize", resizeHandler);
  //   };
  // }, []);

  const DEFAULT_SIZE = { width: 560, height: 480 };
  const MIN_HEIGHT = 40 + 80 + 148; // 40 header + 80 input + 148 padding content of menu options

  const defaultPosition = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let aDefaultPos: iPoint2D = {
      x: viewportWidth - DEFAULT_SIZE.width - right,
      y: viewportHeight - DEFAULT_SIZE.height - bottom,
    };
    return aDefaultPos;
  };
  return (
    <Draggable
      // position={defaultPosition()}
      ref={draggableRef}
      bounds="parent"
      handle={handle}
      onStart={onMouseDown}
      defaultPosition={defaultPosition()}
    >
      <Resizable
        className={`drag-focus ${focused ? "focused-elem" : ""}`}
        bounds="parent"
        defaultSize={DEFAULT_SIZE}
        enable={{
          top: false,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        minHeight={MIN_HEIGHT}
        minWidth={DEFAULT_SIZE.width}
        maxWidth={DEFAULT_SIZE.width}
      >
        <StyledDraggableObject>{children}</StyledDraggableObject>
      </Resizable>
    </Draggable>
  );
}
