import { eLoadingState, eLegType, eAxisType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iAsyncCallback, iHash } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { eSmRaysKind, eSmPlaneWaveType } from "../../simulation/SimulationContext";
import { OptixReader } from "../../_utils/OptixReader";
import { ServerContext } from "../../server/ServerContext";
import { iDetectorData } from "./ExportToJSONInterfaces";
import { GeneralVO } from "../../data/VO/GeneralVO";
import { PartsDataLoader, tDynamicCreationIds } from "../../data/data_loader/PartsDataLoader";
import { eCountType, eWavelengthDistributionType } from "../behaviors/LightSourceContext";
import { LaserBehavior } from "../behaviors/LaserBehavior";
import { PartsFactory } from "./PartsFactory";
import { SceneContext } from "../../scene/SceneContext";
import { EventManager } from "../../../oc/events/EventManager";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Object3D, Vector3, BufferGeometry, Float32BufferAttribute, Mesh, Material } from "three";
import { Part } from "../Part";
import { iPartMongoDB, iOptixData, iPart, iAxis, ePartType } from "../PartInterfaces";
import { Op3dScene } from "../../scene/Op3dScene";
import { eLabelType } from "../../scene/CSS2DLabel";
import { createBlackBoxDevice } from "../BlackBox/utils/BlackBoxUtils";

export type tPartsHashType = Object3D | eLoadingState | Array<iPartMongoDB> | iOptixData

/**
 * @description This class responsible for storing parts in software
 */
export class PartsCatalog {

    public static D1012_DATA: iDetectorData = {
        appearance: {
            color: {
                frame: 0x00000,
                surface: 0xffffff
            },
            opacity: 0.1
        },
        size: {
            height: 25.4 * 2,
            width: 25.4 * 2,
            // pixels: AnalysisContext.DEFAULT_RESOLUTION,
        }
    };
    public static PART_SCALE = 1;//52.07;//(16.4 * 3.175);
    private static INSTANCE: PartsCatalog;
    private static LEG_3DOPTIX: string = "20556";
    private static LEG_THIRD_PARTY: string = "post_holder";
    private static POST_CYLINDER: string = "cylinder";
    // private static AXIS_MODEL_NAME = "cs";

    private mParts: iHash<tPartsHashType> = {}; //dictionary
    /**
     * @description parts in use on scene
     */
    //private mPartsInUse: Array<string> = new Array<string>();

    private constructor() {
    }
    //__________________________________________________________________________________________
    public static get instance(): PartsCatalog {

        if (this.INSTANCE == null) {
            this.INSTANCE = new PartsCatalog();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public async loadAxisModel() {
        let aURL = 'https://assets-3doptix-staging.s3.amazonaws.com/part_models/3doptix-cs.optix';

        let aFile = await this._getOptixFile(aURL);
        let aArrayBuffer = await new Blob([aFile]).arrayBuffer();
        let aPart = new OptixReader().returnFacesMesh(aArrayBuffer);

        let aCSGeo = new BufferGeometry()
        aCSGeo.setAttribute('position', new Float32BufferAttribute(aPart[0], 3));
        aCSGeo.setAttribute('color', new Float32BufferAttribute(aPart[1], 3));
        aCSGeo.computeVertexNormals();

        let faceMesh = new Mesh(aCSGeo, Op3dContext.GLOBAL_MATERIAL);
        faceMesh.userData.facesMesh = DataUtils.getObjectCopy(aPart[2]);
        Op3dContext.AXIS_MODEL = faceMesh;
    }
    //__________________________________________________________________________________________
    public async getLegByType(pLegType: eLegType): Promise<Object3D> {
        switch (pLegType) {
            case eLegType.OptixPost:
                let aPost = await this.get3DOptixLeg();
                return aPost;
            case eLegType.ThirdParty:
                let aThirdParty = await this.getThirdPartyLeg();
                return aThirdParty
            default:
                throw new Error("Invalid leg type");
        }
    }
    //__________________________________________________________________________________________
    public async getThirdPartyLeg() {
        let aPath = ServerContext.part_prefix + PartsCatalog.LEG_THIRD_PARTY;
        let aLeg = await this.getMatchingPart({ url: aPath });
        if (aLeg instanceof Object3D) {
            let aRet = this._cloneObject3D(aLeg);
            return aRet.getObjectByName('PH30E_M').children[0];
        }

        return null;
    }
    //__________________________________________________________________________________________
    public async getPostCylinder() {
        let aPath = ServerContext.part_prefix + PartsCatalog.POST_CYLINDER;
        let aPost = await this.getMatchingPart({ url: aPath });
        if (aPost instanceof Object3D) {
            let aRet = this._cloneObject3D(aPost);
            return aRet;
        }
    }
    //__________________________________________________________________________________________
    public async get3DOptixLeg() {
        let aPath = ServerContext.part_prefix + PartsCatalog.LEG_3DOPTIX;
        let aLeg = await this.getMatchingPart({ url: aPath });
        if (aLeg instanceof Object3D) {
            let aRet = this._cloneObject3D(aLeg);
            return aRet;
        }

        return null;
    }
    //__________________________________________________________________________________________
    private _cloneObject3D(pObject3D: Object3D) {
        let aClonedObject = pObject3D.clone();
        this._deepCloneMaterial(aClonedObject);

        return aClonedObject;
    }
    //__________________________________________________________________________________________
    public addPart(pUrl: string, pObject: tPartsHashType): void {
        this.mParts[pUrl] = pObject;
    }
    //__________________________________________________________________________________________
    public addLoading(pUrl: string) {
        this.mParts[pUrl] = eLoadingState.LOADING;
    }
    //__________________________________________________________________________________________
    public addErrorLoading(pUrl: string) {
        this.mParts[pUrl] = eLoadingState.ERROR;
    }
    //__________________________________________________________________________________________
    public isPartError(pUrl: string) {
        return (this.mParts[pUrl] === eLoadingState.ERROR);
    }
    //__________________________________________________________________________________________
    public async getPartsByUrls(pLoadFiles: Array<iLoadFile>) {
        await new LoadingHandler().load(pLoadFiles);
    }
    //__________________________________________________________________________________________
    public async getDynamicPart(pPartVO: GeneralVO) {
        if (pPartVO.isBlackBox == true) {
            try {
                let aNumber_ID = pPartVO.number_id

                let aPartMongoDB =
                    await PartsDataLoader.instance.getSingleFullData({
                        number_id: aNumber_ID
                    }, false);

                let iPart = createBlackBoxDevice(aPartMongoDB.black_box_data.shape_data.size, aPartMongoDB.name, aPartMongoDB.black_box_data.shape_data.color)

                let aPart = new Part({
                    id: pPartVO.id,
                    number_id: aNumber_ID,
                    options: { type: ePartType.BLACKBOX }
                }).add(iPart);
                this._setDynamicLabel(aPart, pPartVO.name);

                return aPart
            } catch (e) {
                Op3dContext.USER_VO.isEmployeeUser && console.log(e)
            }

        } else {
            switch (pPartVO.id as tDynamicCreationIds) {
                case "R1111": {
                    let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
                    let aIPart: iPart = {
                        object3D: new Object3D(),
                        internal_id: Op3dUtils.idGenerator(),
                        axes: [{
                            object3D: aPartAxis,
                            type: eAxisType.LASER
                        }]
                    }
                    aIPart.object3D.add(aPartAxis);
                    let aPart = new Part({
                        id: pPartVO.id,
                        options: { type: ePartType.DYNAMIC_PART, static: true }
                    }).add([aIPart]);
                    this._setDynamicLabel(aPart, pPartVO.name)

                    return aPart;
                }
                case "L1111":
                case "L1112":

                    let aLaserFaces = PartsFactory.getLaserFaces({
                        count_type: eCountType.TOTAL,
                        alpha: 1,
                        model_radius: 0,
                        color: '#000',
                        rays_color: '#91ff00',
                        kind: eSmRaysKind.PLANE_WAVE,
                        shape: eSmPlaneWaveType.CIRCULAR,
                        sourceGeometricalData: LaserBehavior.DEFAULT_CIRCULAR_PROFILE_GEO,
                        wavelengthData: null,
                        distribution_data: {
                            type: eWavelengthDistributionType.USER_DEFINED
                        },
                        light_source_number_id: null,
                        polarization: null,
                        power: null,
                        directionalData: null,
                    });

                    let aIPart = PartsFactory.getPartFromFaces({
                        faces: aLaserFaces,
                        number_id: null,
                        id: null,
                        iPartName: Strings.USER_DEFINED_LIGHT_SOURCE
                    });

                    let aLaserAxis: iAxis = {
                        internal_id: Op3dUtils.idGenerator(),
                        type: eAxisType.LASER,
                        object3D: SceneContext.OP3D_SCENE.getAxisModel(false),
                    };

                    aIPart.axes.push(aLaserAxis);
                    aIPart.object3D.add(aLaserAxis.object3D);

                    let aPart = new Part({
                        id: pPartVO.id,
                        options: { type: ePartType.DYNAMIC_PART }
                    }).add([aIPart]);
                    aPart.setPartLabel(pPartVO.name)
                    this._setDynamicLabel(aPart, pPartVO.name)

                    return aPart;

                case "D1012":
                    let aData = DataUtils.getObjectCopy(PartsCatalog.D1012_DATA);
                    let aDetector = PartsFactory.getDetectorElementOLD(aData, pPartVO.id);
                    this._setDynamicLabel(aDetector, pPartVO.name)
                    return aDetector;

            }
        }

    }
    //__________________________________________________________________________________________
    private _setDynamicLabel(pPart: Part, pName: string) {
        pPart.setPartLabel(pName)
        if (Op3dScene.SHOW_ALL_LABELS === true) {
            pPart.setLabelVisiblity(eLabelType.LABEL, true)
        }
    }
    //__________________________________________________________________________________________
    public async _getOptixFile(pURL: string) {
        return new Promise<File>((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", pURL, true);
            xhr.responseType = "arraybuffer";

            //xhr.setRequestHeader("Content-type", "application/json");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    resolve(xhr.response);
                } else if (xhr.status == ServerContext.NOT_FOUND) {
                    resolve(null);
                }
            }
            xhr.send();
        });
    }
    //__________________________________________________________________________________________
    /**
     * @param pPartUrl url of part to check
     * @returns whether the part is already loaded or not
     */
    //__________________________________________________________________________________________
    public isPartReady(pPartUrl: string): boolean {
        // return (this.mParts[pPartUrl] instanceof Object3D);
        return (this.mParts[pPartUrl] instanceof Object);
    }
    //__________________________________________________________________________________________
    public isPartLoading(pPartUrl: string): boolean {
        //Op3dContext.PARTS_MANAGER?.rotatePlaneOnCamera()

        return (this.mParts[pPartUrl] === eLoadingState.LOADING);
    }
    //__________________________________________________________________________________________
    public async getMatchingPart(pLoadFile: iLoadFile) {

        let aUrl = pLoadFile.url;

        if (null == this.mParts[aUrl]) {
            await new LoadingHandler().load([pLoadFile]);
        }

        while (PartsCatalog.instance.isPartLoading(aUrl)) {
            await Op3dContext.sleep(50)
        }

        return this.mParts[aUrl];
    }
    //__________________________________________________________________________________________
    private _deepCloneMaterial(pPart: Object3D) {
        if (pPart instanceof Mesh && pPart.material != null) {
            this._cloneMat(pPart);
        }

        if (pPart.children.length > 0) {
            for (let i = 0; i < pPart.children.length; i++) {
                this._deepCloneMaterial(pPart.children[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    private _cloneMat(pPart: Mesh) {
        if (pPart.material instanceof Array) {
            let aLength = (pPart.material as any).length;
            let aCloneMat = new Array<Material>();
            for (let i = 0; i < aLength; i++) {
                aCloneMat[i] = pPart.material[i].clone();
            }

            // support for parts that have a array of materials
            (pPart as any).material = aCloneMat;
        } else {
            pPart.material = pPart.material.clone();
        }
    }
    //__________________________________________________________________________________________
}

export interface iLoadFile {
    url: string;
    isOptix?: boolean;
    move?: Vector3;
    isAssembly?: boolean;
    number_id?: string;
}

export class LoadingHandler {



    private mPartsToLoad: number;
    // private mPartZipLoader: PartZipLoader = new PartZipLoader();

    constructor() { }
    //__________________________________________________________________________________________
    public async load(pUrlsToLoad: Array<iLoadFile>) {

        let aUrls = pUrlsToLoad.map(item => item.url);
        //let aUniqueUrls = oc.Utils.getUniqueItems(pUrlsToLoad);
        let aUniqueUrls: Array<string> = aUrls.filter((item, i, ar) => ar.indexOf(item) === i);
        this.mPartsToLoad = aUniqueUrls.length;



        for (let i = 0; i < aUniqueUrls.length; i++) {
            // let aCurrUrl = aUniqueUrls[i].url;
            let aCurrUrl = aUniqueUrls[i];
            let aIsReady = PartsCatalog.instance.isPartReady(aCurrUrl);
            if (aIsReady) {
                this.mPartsToLoad--;
                continue;
            }

            if (PartsCatalog.instance.isPartError(aCurrUrl)) {
                this.mPartsToLoad--;
                continue;
            }

            if (PartsCatalog.instance.isPartLoading(aCurrUrl)) {
                continue;
            }


            let aItem = pUrlsToLoad.find(item => item.url == aUniqueUrls[i]);

            this._loadPart(aItem);
            // this._loadPart(aUniqueUrls[i]);

        }

        await Op3dContext.wait(() => (0 == this.mPartsToLoad));

        // while (this.mPartsToLoad > 0) {
        //     await Op3dContext.sleep(50);
        // }
    }
    //__________________________________________________________________________________________
    private _loadPart(pLoadFile: iLoadFile) {
        if (null == pLoadFile) {
            this.mPartsToLoad--;
            return;
        }

        let aUrl = pLoadFile.url;
        if ((null == aUrl) || ("" == aUrl)) {
            this.mPartsToLoad--;
            return;
        }

        PartsCatalog.instance.addLoading(aUrl);


        if (pLoadFile.isAssembly) {
            this._loadAssembly(pLoadFile)

            // this._loadJSONPart(pLoadFile)
        } else if (true == pLoadFile.isOptix) {
            // this._loadJSON(pLoadFile)
            this._loadOptix(pLoadFile);
        }
        // else {
        //     this.mPartZipLoader.loadModel(aUrl,
        //         (pResult: iAsyncCallback<Object3D | string>) =>
        //             this._onObjectZipLoaded(pResult, aUrl));
        // }

    }
    //__________________________________________________________________________________________
    public getFile(pURL) {
        return new Promise<File>((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", pURL, true);
            xhr.responseType = "arraybuffer";

            //xhr.setRequestHeader("Content-type", "application/json");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    resolve(xhr.response);
                } else if (xhr.status == ServerContext.NOT_FOUND) {
                    resolve(null);
                }
            }
            xhr.send();
        });
    }
    //__________________________________________________________________________________________
    public async _loadAssembly(pLoadFile: iLoadFile) {
        let aRes = await ServerContext.SERVER.getPartById({ number_id: pLoadFile.number_id })
        let aParts = JSON.parse(aRes.data.assembly_parts)

        let aPartsInAssembly = []
        for (let i = 0; i < aParts.length; i++) {
            let aPart = await ServerContext.SERVER.getPartById({ number_id: aParts[i].number_id })
            let aUrl = ServerContext.part_prefix + aPart.data.info.url;

            let aFile = await this._getArrayBufferFile((aUrl + '.optix'));
            let aArrayBuffer = await new Blob([aFile]).arrayBuffer();
            let aBrepReader = new OptixReader()
            let aResult = await aBrepReader.read(aArrayBuffer);
            aPartsInAssembly.push({ data: aPart.data, matrix: aParts[i].matrix, meshData: aResult })
        }

        PartsCatalog.instance.addPart(pLoadFile.url, aPartsInAssembly);
        EventManager.dispatchEvent(EventsContext.ITEM_LOADED, this, pLoadFile.url);
        this.mPartsToLoad--;
    }
    //__________________________________________________________________________________________
    private async _loadOptix(pLoadFile: iLoadFile) {
        let aUrl = pLoadFile.url;

        let aFile = await this._getArrayBufferFile((aUrl + '.optix'));
        let aArrayBuffer = await new Blob([aFile]).arrayBuffer();
        let aBrepReader = new OptixReader()
        let aPart = await aBrepReader.read(aArrayBuffer);

        PartsCatalog.instance.addPart(aUrl, aPart);
        // parts.PartsCatalog.instance.addPart(aUrl, aArrayBuffer);
        EventManager.dispatchEvent(EventsContext.ITEM_LOADED, this, aUrl);
        this.mPartsToLoad--;
    }
    //__________________________________________________________________________________________
    private async _getArrayBufferFile(pURL: string) {
        return new Promise<File>((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", pURL + `?V=${Op3dUtils.idGenerator()}`, true);
            xhr.responseType = "arraybuffer";

            //xhr.setRequestHeader("Content-type", "application/json");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    resolve(xhr.response);
                } else if (xhr.status == ServerContext.NOT_FOUND) {
                    resolve(null);
                }
            }
            xhr.send();
        });
    }
    //__________________________________________________________________________________________
    public async _getJSONFile(pURL: string) {
        return new Promise<any>((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", pURL, true);
            xhr.responseType = "json";

            //xhr.setRequestHeader("Content-type", "application/json");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    resolve(xhr.response);
                } else if (xhr.status == 404) {
                    resolve(null);
                }
            }
            xhr.send();
        });
    }
    //__________________________________________________________________________________________
    private _onErrorCallback(pErr: Object, pUrl: string) {
        if (!PartsCatalog.instance.isPartError(pUrl)) {
            PartsCatalog.instance.addErrorLoading(pUrl);
            this._onObjectZipLoaded({
                success: false,
                data: null
            }, pUrl);
            Op3dContext.USER_VO.isEmployeeUser && console.log("error loading part", pErr);
            MessagesHandler.ERROR_LOADING_MODEL(pErr);
        }
    }
    //__________________________________________________________________________________________
    private _setScale(pObject: Object3D) {
        let aBaseScale = PartsCatalog.PART_SCALE;
        pObject.scale.set(aBaseScale, aBaseScale, aBaseScale);
    }
    //__________________________________________________________________________________________
    private _onObjectZipLoaded(pResult: iAsyncCallback<Object3D | string>, pUrl: string) {
        if (pResult.success == false) {
            this._onErrorCallback(pResult.data, pUrl);

        } else {

            const aObject3D = pResult.data as Object3D;
            let aContainer = new Object3D();
            aContainer.add(aObject3D);
            this._setScale(aObject3D);
            PartsCatalog.instance.addPart(pUrl, aContainer);
            EventManager.dispatchEvent(EventsContext.ITEM_LOADED, this, pUrl);
        }

        this.mPartsToLoad--;
    }
    //__________________________________________________________________________________________

}