import { EventBase } from "../../../../oc/events/EventBase";
import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { NotificationNote } from "./NotificationNote";
import { iNotificationTypeData, eNotificationType, iNotificationData, iNoteHTMLData } from "./NotificationsContext";

export class NotificationCenter extends Op3dComponentBase<{}> {

    public static NOTIFICATIONS_TYPES: {
        SIMULATION: iNotificationTypeData,
        CONSTRUCTION_COMMENT: iNotificationTypeData,
        ADD_LCS: iNotificationTypeData,
        GENERAL: iNotificationTypeData,
        SUCCESS_MESSAGE: iNotificationTypeData,
        ERROR: iNotificationTypeData,
        ALARM: iNotificationTypeData,
    } = {
            SIMULATION: {
                isColorBoxVisible: true,
                type: eNotificationType.SIMULATION,
                color: "#4895ef",// blue
                title: "Simulation"
            },
            CONSTRUCTION_COMMENT: {
                isColorBoxVisible: true,
                type: eNotificationType.CONSTRUCTION_COMMENT,
                color: "#9d4edd",// purple
                title: "Construction comment"
            },
            ADD_LCS: {
                isColorBoxVisible: true,
                type: eNotificationType.CONSTRUCTION_COMMENT,
                color: "#23a7de",
                title: 'Add LCS'
            },
            GENERAL: {
                isColorBoxVisible: true,
                type: eNotificationType.GENERAL,
                color: "#FFD166", // yellow
                title: "General",
            },
            ERROR: {
                isColorBoxVisible: true,
                type: eNotificationType.ERROR,
                color: "#EF476F", // red
                title: "Error"
            },
            SUCCESS_MESSAGE: {
                isColorBoxVisible: true,
                type: eNotificationType.SUCCESS_MESSAGE,
                color: "#06d6a0", // green
                title: "Notice"
            },
            ALARM: {
                isColorBoxVisible: false,
                type: eNotificationType.ALARM,
                color: "#EF476F", // red
                title: `<svg width="20" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.08749 31.5C3.81249 31.5 3.56249 31.431 3.33749 31.293C3.11249 31.155 2.93749 30.974 2.81249 30.75C2.68749 30.525 2.61849 30.281 2.60549 30.018C2.59249 29.755 2.66149 29.499 2.81249 29.25L16.6875 5.25C16.8375 5 17.0315 4.8125 17.2695 4.6875C17.5075 4.5625 17.751 4.5 18 4.5C18.25 4.5 18.494 4.5625 18.732 4.6875C18.97 4.8125 19.1635 5 19.3125 5.25L33.1875 29.25C33.3375 29.5 33.4065 29.7565 33.3945 30.0195C33.3825 30.2825 33.3135 30.526 33.1875 30.75C33.0625 30.975 32.8875 31.1565 32.6625 31.2945C32.4375 31.4325 32.1875 31.501 31.9125 31.5H4.08749ZM18 27C18.425 27 18.7815 26.856 19.0695 26.568C19.3575 26.28 19.501 25.924 19.5 25.5C19.5 25.075 19.356 24.7185 19.068 24.4305C18.78 24.1425 18.424 23.999 18 24C17.575 24 17.2185 24.144 16.9305 24.432C16.6425 24.72 16.499 25.076 16.5 25.5C16.5 25.925 16.644 26.2815 16.932 26.5695C17.22 26.8575 17.576 27.001 18 27ZM18 22.5C18.425 22.5 18.7815 22.356 19.0695 22.068C19.3575 21.78 19.501 21.424 19.5 21V16.5C19.5 16.075 19.356 15.7185 19.068 15.4305C18.78 15.1425 18.424 14.999 18 15C17.575 15 17.2185 15.144 16.9305 15.432C16.6425 15.72 16.499 16.076 16.5 16.5V21C16.5 21.425 16.644 21.7815 16.932 22.0695C17.22 22.3575 17.576 22.501 18 22.5Z" fill="#D62A2A"/>
                </svg>
                 Notice`
            },
        }

    private mIsOpen: boolean = false;
    private static SKIN_PATH = "./skins/tools/notification_center.html";
    private mNotes: Array<NotificationNote> = new Array<NotificationNote>();
    private mNoteTemplate: HTMLElement;
    private mNotesParent: HTMLElement;

    private mToastsContainer: HTMLElement;
    private static INSTANCE: NotificationCenter;

    public static get instance() {
        if (this.INSTANCE == null) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('d-none');
            document.getElementById('forms').appendChild(aDiv);
            this.INSTANCE = new NotificationCenter(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    private constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: NotificationCenter.SKIN_PATH
        });
    }
    //__________________________________________________________________________________________
    protected _onNew(): void {
        this._clearNotifications(true)
    }
    //__________________________________________________________________________________________
    private _clearNotifications(pToClose: boolean) {
        this._unhighlightBell();
        while (this.mNotes.length > 0) {
            this.mNotes.pop().distract();
        }
        if (pToClose) {
            this.close();
        }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete() {

        let aRestoreBtn = this._getPart("restore-btn", true);
        aRestoreBtn.addEventListener("click", () => this._clearNotifications(false));
        this.mNoteTemplate = this._getPart("notification-center-element", true);
        this.mNotesParent = this.mNoteTemplate.parentElement;
        ViewUtils.removeElementChildren(this.mNotesParent);
        this.mToastsContainer = document.getElementById("toasts-container");
        EventManager.addEventListener(EventsContext.NOTIFICATION_REMOVED,
            (pData: EventBase) => this.removeNotification(pData.data), this);

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    public async pushNotification(pData: iNotificationData) {
        if (null == pData.params) {
            pData.params = NotificationCenter.NOTIFICATIONS_TYPES.GENERAL;
        }

        if (("" == pData.message) || (null == pData.message)) {
            return null;
        }

        await this._prepareForm();
        let aClone = this.mNoteTemplate.cloneNode(true) as HTMLElement;

        let aNoteData: iNoteHTMLData = {
            firstParent: this.mToastsContainer,
            secondParent: this.mNotesParent,
        };

        let aNewNote = new NotificationNote(aClone, pData, aNoteData);
        this.mNotes.push(aNewNote);
        this._highlightBell();

        return aNewNote;
    }
    //__________________________________________________________________________________________
    private _unhighlightBell() {
        EventManager.dispatchEvent(EventsContext.UNHIGHLIGHT_NOTICICATION_BELL, this);
    }
    //__________________________________________________________________________________________
    private async _highlightBell() {
        EventManager.dispatchEvent(EventsContext.HIGHLIGHT_NOTICICATION_BELL, this);
    }
    //__________________________________________________________________________________________
    public removeNotification(pItemRemoved: NotificationNote) {
        for (let i = 0; i < this.mNotes.length; i++) {
            if (this.mNotes[i] == pItemRemoved) {
                this.mNotes[i].distract();
                this.mNotes.splice(i, 1);
                break;
            }
        }
    }
    //__________________________________________________________________________________________
    public async toggle() {
        await this._prepareForm();

        this.mIsOpen = !this.mIsOpen;
        if (this.mIsOpen) {
            this._open();
        } else {
            this.close();
        }
    }
    //__________________________________________________________________________________________
    public async close() {
        this.mIsOpen = false;
        await this._prepareForm();
        ViewUtils.setElementVisibilityByDNone(this.mContainer, false);
    }
    //__________________________________________________________________________________________
    private async _open() {
        await this._prepareForm();
        ViewUtils.setElementVisibilityByDNone(this.mContainer, true);
        this._unhighlightBell();
        this.mIsOpen = true;
        for (let i = 0; i < this.mNotes.length; i++) {
            this.mNotes[i].update();
        }
    }
    //__________________________________________________________________________________________
}
