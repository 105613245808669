import { eDataPermission } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { OpticsContext } from "../../_context/OpticsContext";
import { Strings } from "../../_context/Strings";
import { iOP3DHTMLInputElement } from "../../_context/_interfaces/Interfaces";
import { OpticsDataLoader } from "../../data/data_loader/OpticsDataLoader";
import { Part } from "../../parts/Part";
import { iPart, ePartType } from "../../parts/PartInterfaces";
import { ViewUtils } from "../ViewUtils";
import { PartInfoSection } from "./PartInfoSection";

export interface iPartBasicInfoSection {
    part: Part;
}

export class piPartBasicInfoSection extends PartInfoSection<Part> {

    private mTitle: HTMLElement;

    private mPartInfoSection: HTMLElement;
    private mPartName: HTMLElement;
    private mPartShortName: HTMLElement;
    private mShowPartLabel: HTMLInputElement;

    private mOpticsInfoSection: HTMLElement;
    private mOpticsName: HTMLAnchorElement;

    private mLabelName: iOP3DHTMLInputElement;
    private mLabelChangeFunc: (pVal: string) => void;

    private mPowerReadingDiv: HTMLElement;
    private mShowPowerReading: HTMLInputElement;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_basic.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mTitle = this._getPart('title');

        this.mPartInfoSection = this._getPart('part_basic_info');
        this.mOpticsInfoSection = this._getPart('optics_info');

        this.mPartName = this._getPart('part_name');
        this.mPartShortName = this._getPart('short_name');

        this.mOpticsName = this._getPart('optics_name') as HTMLAnchorElement;
        this.mOpticsName.addEventListener('click', (e) => this._onClickOptics(e));

        this.mShowPartLabel = this._getPart('show_sprite_label') as HTMLInputElement;
        this.mShowPartLabel.addEventListener('change', () =>
            {
                Op3dContext.SCENE_HISTORY.saveScene();
                Op3dContext.PARTS_MANAGER.selectedPart.labelVisiblity = this.mShowPartLabel.checked;
            });

        this.mPowerReadingDiv = this._getPart('show_power_reading_div');
        this.mShowPowerReading = this._getPart('show_power_reading') as HTMLInputElement;
        this.mShowPowerReading.addEventListener('change', () =>
            Op3dContext.PARTS_MANAGER.selectedPart.powerReadingVisiblity =
            this.mShowPowerReading.checked);
        let aHasPowerReadingTooltipElement = this.mShowPowerReading.parentElement.parentElement;
        aHasPowerReadingTooltipElement.addEventListener('mouseenter', (e) => {
            let aMouseLeaveEvent = () => {
                Op3dContext.TOOLTIP.hide();
                aHasPowerReadingTooltipElement.removeEventListener('mouseleave', aMouseLeaveEvent);
            };
            aHasPowerReadingTooltipElement.addEventListener('mouseleave', aMouseLeaveEvent);

            let aMsg = `Perform incoherent irradiance (advanced) analysis, in order to present 
            power reading.`;
            Op3dContext.TOOLTIP.show(e, aMsg);
        });

        this.mLabelName = this._getPart('label_name') as iOP3DHTMLInputElement;
        this.mLabelName.addEventListener('change', () => this._onLabelChanged());
    }
    //__________________________________________________________________________________________
    private _onLabelChanged() {
        if ('' == this.mLabelName.value) {
            this.mLabelName.value = this.mLabelName.prevValue;
            return;
        }

        this.mLabelChangeFunc(this.mLabelName.value);
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mOpticsName.innerHTML = '';
        ViewUtils.setElementVisibilityByDNone(this.mOpticsInfoSection, false);
        ViewUtils.setElementVisibilityByDNone(this.mPartInfoSection, false);
        ViewUtils.setElementVisibilityByDNone(this.mPartName, false);

        this.mPartShortName.innerHTML = '';
        this.mLabelName.style.marginLeft = null;
        this.mLabelName.style.paddingLeft = null;

        this.mOpticsName.removeAttribute('href');
        this.mOpticsName.classList.add(Strings.POINTER_EVENTS_NONE);
    }
    //__________________________________________________________________________________________
    protected async _fillSection(pPart: Part): Promise<void> {
        this._clear();

        this._handlePartInfoSection(pPart);
        let aOptics = await this._handleOpticsSection(pPart);
        if (ePartType.GROUP === pPart.partOptions.type) {
            ViewUtils.setElementVisibilityByDNone(this.mShowPartLabel, false);
            return
        }
        this._handleLabel(pPart, aOptics);
        this._handlePowerReading(pPart);
    }
    //__________________________________________________________________________________________
    private _handlePowerReading(pPart: Part) {
        let aIsOptics = (pPart.getOpticsFaces().length > 0 && pPart.getOpticsFaces()[0]?.name !== "paraxial_surface");
        ViewUtils.setElementVisibilityByDNone(this.mPowerReadingDiv, aIsOptics);

        let aHasPowerReading = pPart.hasPowerReading;
        ViewUtils.setElementDisabled(this.mShowPowerReading.parentElement,
            (false === aHasPowerReading));

        let aIsVisible = ((true === pPart.powerReadingVisiblity) && (true === aHasPowerReading));
        this.mShowPowerReading.checked = aIsVisible;
    }
    //__________________________________________________________________________________________
    private _handleLabel(pPart: Part, pOptics: iPart) {
        let aShortLabel = ((null != pOptics) && (true == pOptics.data.isCustomizedOptics) && pOptics.data.isAperture == false) ?
            '(customized)' : pPart.getShortLabel();
        if (null != aShortLabel) {
            ViewUtils.setElementVisibilityByDNone(this.mPartShortName, true);
            this.mPartShortName.innerHTML = aShortLabel + ': ';

            let aBB = this.mPartShortName.getBoundingClientRect().width;

            this.mLabelName.style.marginLeft = -(aBB + 4) + 'px';
            this.mLabelName.style.paddingLeft = (aBB + 4) + 'px';
        }

        this.mLabelName.value = pPart.getLabel().label;
        this.mShowPartLabel.checked = pPart.labelVisiblity;
        this.mLabelName.prevValue = this.mLabelName.value;

        this.mLabelChangeFunc = (pVal) => {
            pPart.setPartLabel(pVal, true);
            Op3dContext.PARTS_MANAGER.updatePartsList(false);
        };
    }
    //__________________________________________________________________________________________
    private async _handleOpticsSection(pPart: Part) {
        let aOpticsHolder = pPart.getSubpartByName(Strings.OPTICS_HOLDER);
        if (null == aOpticsHolder) {
            return null;
        }

        let aOptics = (null != aOpticsHolder.subParts) ? aOpticsHolder.subParts[0] : null;
        let aNumberID = ((null != aOptics) && (null != aOptics.data)) ?
            aOptics.data.number_id : null;

        if (null != aNumberID) {
            let aOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
                number_id: aNumberID
            });

            if (null != aOpticsVO) {
                let aPrefix = '';
                let aIsCustomized = OpticsContext.isCustomized(aOpticsVO);
                // if (eDataPermission.PRIVATE == aOpticsVO.permission) {
                if (aIsCustomized) {
                    aPrefix += '<strong style="user-select: none;">(customized) </strong>';
                }

                this.mOpticsName.innerHTML = aPrefix + aOpticsVO.name;

                if ((eDataPermission.PUBLIC == aOpticsVO.permission) &&
                    (null != aOpticsVO.parameters.info.weblinks) &&
                    (null != aOpticsVO.parameters.info.weblinks[0])) {
                    this.mOpticsName.href = aOpticsVO.parameters.info.weblinks[0];
                    this.mOpticsName.classList.remove(Strings.POINTER_EVENTS_NONE);
                }

                ViewUtils.setElementVisibilityByDNone(this.mOpticsInfoSection, true);
            }
        }

        return aOptics;
    }
    //__________________________________________________________________________________________
    private _onClickOptics(e: Event) {
        e.preventDefault();

        let params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,';
        params += 'width=600,height=300,left=100,top=100';

        window.open(this.mOpticsName.href, this.mOpticsName.href, params);
    }
    //__________________________________________________________________________________________
    private _handlePartInfoSection(pPart: Part) {
        let aIsVisible = false;
        this.mTitle.innerHTML = 'Part Information';

        let aLaserBehavior = pPart.getBehavior('laserBehavior');
        let aIsUserDefinedLightSource = ((null != aLaserBehavior) &&
            (null != aLaserBehavior.laserData) &&
            (true == aLaserBehavior.laserData.isUserDefinedLaser));
        let aIsDynamicPart = (ePartType.DYNAMIC_PART === pPart.partOptions.type);

        if (ePartType.CS === pPart.partOptions.type) {
            this._showCSDetails(pPart);
            aIsVisible = true;
        } else if ((null != pPart.id) && (false == aIsUserDefinedLightSource) &&
            (false == aIsDynamicPart) &&
            (ePartType.PARAXIAL_LENS != pPart.partOptions.type)) {
            this._showPartDetails(pPart);
            aIsVisible = true;
        }

        ViewUtils.setElementVisibilityByDNone(this.mPartInfoSection, aIsVisible);
    }
    //__________________________________________________________________________________________
    private _showPartDetails(pPart: Part) {
        let aID = pPart.id;
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aID);
        let aName: string;
        if (null != aPartVO) {
            aName = (eDataPermission.PUBLIC == aPartVO.permission) ?
                (aID + ' ' + aPartVO.name) : aPartVO.name;
        } else {
            aName = aID;
        }

        this.mPartName.innerHTML = aName;
        ViewUtils.setElementVisibilityByDNone(this.mPartName, true);
    }
    //__________________________________________________________________________________________
    private _showCSDetails(pPart: Part) {
        let aTitle = 'CS Information';
        let aName = 'CS';
        let aCSName = pPart.getAxes()[0].name;
        if (null != aCSName) {
            aName += ': ' + aCSName;
        }

        this.mTitle.innerHTML = aTitle;
        this.mPartName.innerHTML = aName;
        ViewUtils.setElementVisibilityByDNone(this.mPartName, true);
    }
    //__________________________________________________________________________________________
}
