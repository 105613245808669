import { EventBase } from "../../../../oc/events/EventBase";
import { EventManager } from "../../../../oc/events/EventManager";
import { ePolarizationSourceType, eStateToAnalysis } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { Strings } from "../../../_context/Strings";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iAnalysisData } from "../../../parts/_parts_assets/ExportToJSONInterfaces";
import { eSmRaysKind } from "../../../simulation/SimulationContext";
import { ViewUtils } from "../../ViewUtils";
import { iLockAnalsysData, AnalysesSynchronizer } from "../AnalysesSynchronizer";
import { AnalysisCache, iAnalysisQuery2 } from "../AnalysisCache";
import { AnalysisConstants } from "../AnalysisConstants";
import { tAnalysisType, AnalysisContext, eAnalysisType, iAnalysisItem, eSimulationKind, iAnalysisItemSurfaceParams, iAnalysisResultItem } from "../AnalysisContext";
import { AnalysisPortal } from "../AnalysisPortal";
import { AnalysisThumbnail } from "../AnalysisThumbnail";
import { ExtendedViewNew } from "../_extendedView/ExtendedViewNew";

export class anSurfaceComponent {

    public static RAYS_INPUT_SIZE: number = 100;
    private static MAX_COUNT_LABELS = 3;
    private mParams: iAnalysisItemSurfaceParams;
    private mContainer: HTMLElement;
    private mMainCheckbox: HTMLInputElement;
    private mItems = new Array<AnalysisThumbnail>();
    private mIsDisabled: boolean;
    private mSingleListItem: HTMLElement;
    private mListContainer: HTMLElement;
    private mLabelsContainer: HTMLElement;
    private mFastLabel: HTMLElement;
    private mAdvancedLabel: HTMLElement;
    private mLightSourceTitle: HTMLElement;
    private mLsTitlesContainer: HTMLElement;
    private mAnalysisResult: HTMLElement;
    private mMsgContainer: HTMLElement;
    private mAnalysisResultContainer: HTMLElement;
    private mTitlesSizeContainer: HTMLElement;
    private mTitle: HTMLElement;
    private mNumOfHandledAnalysis: number = 0;
    private mPixelsTooltip: HTMLElement;

    constructor(pContainer: HTMLElement, pParams: iAnalysisItemSurfaceParams) {
        this.mContainer = pContainer;
        this.mParams = pParams;

        this._init();

        EventManager.addEventListener<iLockAnalsysData>(EventsContext.LOCKED_ANALYSIS,
            (pData: EventBase<iLockAnalsysData>) => this._onLockChanged(pData.data), this);

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onPermissionUpdate(), this);

        EventManager.addEventListener(EventsContext.CHANGE_ANALYSIS_RAYS_COUNT,
            (pData: EventBase<{
                rays_count: number,
                internal_id: string
            }>) => this._onRaysCountChanged(pData.data), this);

        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip({ html: true })
    }
    //______________________________________________________________________________________________
    public changedAnalysisParameters(pId: string, pVal: boolean): any {
        let aItem = this.mItems.find(item => item.id === pId);
        if (aItem !== undefined) {
            aItem.changedAnalysisParameters = pVal;
        }
    }
    //__________________________________________________________________________________________
    public get items() {
        return this.mItems;
    }
    //______________________________________________________________________________________________
    public updateSpecificLaser(pLaserID: string, pType: eSmRaysKind) {
        for (let i = 0; i < this.mItems.length; i++) {
            this.mItems[i].updateSpecificLaser(pLaserID, pType);
        }
    }
    //______________________________________________________________________________________________
    public async pixelsOverLimit() {
        if (Op3dContext.USER_VO.isBasicLicense === true) {
            this.mPixelsTooltip.classList.remove("colored-scale");
            await Op3dContext.sleep(100);
            this.mPixelsTooltip.classList.add("colored-scale");
        }
    }
    //__________________________________________________________________________________________
    private _onProgress(pData: { analysis: { name: tAnalysisType, id: string }, progress: string }) {
        const aAnalysisData = pData.analysis;
        const aProgress: string = pData.progress;
        if (this.mItems && this.mItems.length > 0) {
            this.mItems.forEach(item => {
                if (item.result && aAnalysisData.id == item.result.id) {
                    const pContainer = item.result.container;
                    let aSpinner = Op3dUtils.getElementIn(pContainer, "analysis-spinner");
                    const pLoadingTextElement = aSpinner.querySelector('div>p');
                    pLoadingTextElement.textContent = aProgress;
                }
            })
        }
    }
    //__________________________________________________________________________________________
    private _onRaysCountChanged(pData: { rays_count: number, internal_id: string }) {
        this.mItems.forEach(item => item.checkRaysCount(pData))
    }
    //__________________________________________________________________________________________
    public updateSurfaceTitle() {
        let aNameHtml = this.mParams.part.getIndexedLabel(true);
        let aNameStr = this.mParams.part.getIndexedLabel(false);
        this.mParams.name = aNameStr;
        this.mTitle.innerHTML = aNameHtml;
        this.mTitle.title = this.mParams.name;
        this.mTitle.setAttribute("data-original-title", this.mParams.name);
        $(this.mTitle).tooltip({
            html: true
        });
    }
    //__________________________________________________________________________________________
    public updateAllNeedsUpdate() {
        let aAllNeedUpdate = this.mItems.filter(item => item.result != null && item.result.needsUpdate);
        aAllNeedUpdate.forEach(item => this._onResize(item.result.container, item));
    }
    //__________________________________________________________________________________________
    public show() {
        //document.querySelectorAll('[id^="createdOnID"]');
        $(this.mContainer).find('[id^="collapse-item"]').collapse('show');
    }
    //__________________________________________________________________________________________
    public expandByFaceId(pId: string) {
        if (this.mParams.faceId === pId) {
            this.show();
        }
    }
    //__________________________________________________________________________________________
    public closeOptionsDD(pThumbnail: AnalysisThumbnail): void {
        this.mItems.forEach(item => {
            if (item != pThumbnail) {
                item.closeOptionsDD();
            }
        })
    }
    //__________________________________________________________________________________________
    private _onLockChanged(pData: iLockAnalsysData) {
        let aItem = this.mItems.find(item => item.id == pData.analysisID);
        if (aItem != null) {
            aItem.setLock(pData.isLocked)
        }
    }
    //__________________________________________________________________________________________
    public isActive() {
        let aIsActive = (((true == this.mMainCheckbox.checked) && (this.mItems.length > 0)) &&
            (this.mItems.some(elem => elem.isActiveCheckbox)));

        return aIsActive;
    }
    //__________________________________________________________________________________________
    public isShown() {
        return this.mContainer.children[1].classList.contains('show');
    }
    //__________________________________________________________________________________________
    public get params() {
        return this.mParams;
    }
    //__________________________________________________________________________________________
    public totalItemsCount() {
        return this.mItems.length;
    }
    //__________________________________________________________________________________________
    public hasResults() {
        let aHasResults = this.mItems.findIndex(item => item.result != null) != -1;
        return aHasResults;
    }
    //__________________________________________________________________________________________
    public terminateAnalyses() {
        this.mItems.forEach(item => item.terminateAnalysis());
    }
    //__________________________________________________________________________________________
    public setNotUpdated(pIsToShow: boolean) {
        this.mItems.forEach(item => item.setNotUpdated(pIsToShow));
    }
    //__________________________________________________________________________________________
    private _onAddNewAnalysis() {

        let aDefaultAnalysis = AnalysisContext.getDefaultAnalysis();

        this._addItem(aDefaultAnalysis, true);
        this.updateRaysComponent()
    }
    //__________________________________________________________________________________________
    private _onPermissionUpdate() {
        let aHasAdvanceAnalysis = Op3dContext.USER_PERMISSION.hasAdvancedAnalysis;
        if (false == aHasAdvanceAnalysis) {
            for (let i = 0; i < this.mItems.length; i++) {
                let aItem = this.mItems[i];
                if (eAnalysisType.ADVANCED == aItem.getData().type) {
                    aItem.remove();
                }
            }
        } else {
            let aAnalysisData = this.mParams.analysisData;
            let aAdvancedAnalysis = aAnalysisData.advanced;
            for (let i = 0; i < aAdvancedAnalysis.length; i++) {
                if (null == this.mItems.find((val) => (val.id == aAdvancedAnalysis[i].id))) {
                    this._addItem(aAdvancedAnalysis[i], false);
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public readyResults(): number {
        let aIsReady = this.mItems.filter(item => item.result != null && item.result.isReady);
        return aIsReady.length;
    }
    //__________________________________________________________________________________________
    public distract() {

        EventManager.removeEventListener(EventsContext.LOCKED_ANALYSIS, this);
        EventManager.removeEventListener(EventsContext.PERMISSION_UPDATE, this);
        EventManager.removeEventListener(EventsContext.CHANGE_ANALYSIS_RAYS_COUNT, this);

        this.mItems.forEach(item => item.distract())
        this.mParams = null;
        this.mMainCheckbox = null;
        this.mItems = null;
        this.mIsDisabled = null;
        this.mSingleListItem = null;
        this.mListContainer = null;
        this.mLabelsContainer = null;
        this.mFastLabel = null;
        this.mAdvancedLabel = null;
        this.mLightSourceTitle = null;
        this.mLsTitlesContainer = null;
        this.mAnalysisResult = null;
        this.mAnalysisResultContainer = null;
        this.mTitlesSizeContainer = null;
        ViewUtils.removeFromParent(this.mContainer);
    }
    //__________________________________________________________________________________________
    private _init() {
        const aMessage = "Please note that the maximal possible resolution in fast analysis is 100 x 100.<br>Try premium subscription for a higher resolution."

        let aCollapseHeaderMessage = Op3dUtils.getElementIn(this.mContainer, "diff-warnings-header", true);
        let aCollapseTarget = Op3dUtils.getElementIn(this.mContainer, "diff-warnings", true);
        aCollapseHeaderMessage.setAttribute("data-target", "#" + aCollapseTarget.id);

        this.mPixelsTooltip = Op3dUtils.getElementIn(this.mContainer, "num_of_pxls_tooltip");
        if (Op3dContext.USER_VO.isBasicLicense === true) {

            this.mPixelsTooltip.title = aMessage;
            $(this.mPixelsTooltip).tooltip({ html: true });
        } else {
            ViewUtils.removeFromParent(this.mPixelsTooltip);
        }

        let aAddBtn = Op3dUtils.getElementIn(this.mContainer, "add-analysis", true);
        aAddBtn.addEventListener("click", () => this._onAddNewAnalysis());

        this.mTitlesSizeContainer = Op3dUtils.getElementIn(this.mContainer, "rays-titles-size-container", true);
        this.mLightSourceTitle = Op3dUtils.getElementIn(this.mContainer, "ls-title", true);
        this.mLsTitlesContainer = this.mLightSourceTitle.parentElement;
        ViewUtils.removeFromParent(this.mLightSourceTitle);

        this.mMsgContainer = Op3dUtils.getElementIn(this.mContainer, 'msg_container');

        this.mAnalysisResult = Op3dUtils.getElementIn(this.mContainer, "analysis-results-item", true);
        this.mAnalysisResultContainer = this.mAnalysisResult.parentElement;
        ViewUtils.removeFromParent(this.mAnalysisResult);

        this.mAdvancedLabel = Op3dUtils.getElementIn(this.mContainer, 'advanced-label', true);
        ViewUtils.removeFromParent(this.mAdvancedLabel);
        this.mFastLabel = Op3dUtils.getElementIn(this.mContainer, 'fast-label', true);
        ViewUtils.removeFromParent(this.mFastLabel);
        this.mLabelsContainer = Op3dUtils.getElementIn(this.mContainer, "labels-container", true);
        this.mSingleListItem = Op3dUtils.getElementIn(this.mContainer, "single-list-item", true);
        this.mListContainer = this.mSingleListItem.parentElement;
        this.mSingleListItem.parentElement.removeChild(this.mSingleListItem);
        let aCollapseItem = Op3dUtils.getElementIn(this.mContainer, "collapse-item", true);
        let aCollapseHeader = Op3dUtils.getElementIn(this.mContainer, "collapse-header", true);
        aCollapseHeader.setAttribute("data-target", "#" + aCollapseItem.id);

        $(aCollapseItem).on('shown.bs.collapse', (_e) => {
            if (this.mIsDisabled) {
                aCollapseItem.style.pointerEvents = 'none'
            } else {
                aCollapseItem.style.pointerEvents = 'auto'
            }
            this._onCollapseShow();
        });

        this.mMainCheckbox = Op3dUtils.getElementIn(this.mContainer, "active-analysis-cb", true) as HTMLInputElement;
        let aLabel = Op3dUtils.getElementIn(this.mContainer, "active-analysis-cb-label", true) as HTMLLabelElement;
        this.mTitle = Op3dUtils.getElementIn(this.mContainer, "active-analysis-cb-label-name", true) as HTMLLabelElement;
        aLabel.htmlFor = this.mMainCheckbox.id;
        this.mTitle.innerHTML = this.mParams.name;
        this.mTitle.title = this.mParams.name;
        this.mTitle.setAttribute("data-original-title", this.mParams.name);
        $(this.mTitle).tooltip({ html: true });
        this.mMainCheckbox.addEventListener("change", (e) => this._onDisableSection(e));

        let aAnalyses = new Array<iAnalysisItem<tAnalysisType>>();
        if (true == Op3dContext.USER_PERMISSION.hasAdvancedAnalysis) {
            aAnalyses.push(...this.mParams.analysisData.advanced);
        }
        aAnalyses.push(...this.mParams.analysisData.fast);

        this._initLaserTitle();
        for (let i = 0; i < aAnalyses.length; i++) {
            this._addItem(aAnalyses[i], false);
        }
    }
    //__________________________________________________________________________________________
    public _onCollapseShow() {
        for (let i = 0; i < this.mItems.length; i++) {
            if (this.mItems[i].result == null || this.mItems[i].result.isReady == false) {
                continue;
            }

            if (this.mItems[i].result.needsUpdate) {
                this.mItems[i].addResult(
                    this.mItems[i].result.container,
                    this.mItems[i].result);
            }
        }

        if (this.mParams.part != Op3dContext.PARTS_MANAGER.selectedPart) {
            this.mParams.part.onSelect();
        }
    }
    //__________________________________________________________________________________________
    public getSelected4Compare(): Array<string> {
        let aRes = this.mItems.filter(item => item.result != null && item.result.compare);
        let aData = aRes.map(item => item.id);
        return aData;
    }
    //__________________________________________________________________________________________
    private _setLabel(pContainer: HTMLElement, pItem: AnalysisThumbnail) {
        if (pItem.label != null) {
            ViewUtils.removeFromParent(pItem.label);
        }

        let aLabel = pItem.analysisType == eAnalysisType.ADVANCED ? this.mAdvancedLabel : this.mFastLabel;
        let aIconDIV = Op3dUtils.getElementIn(aLabel, 'icon-class');
        if (null != aIconDIV) {
            let aIconSVG = AnalysisContext.getSimulationDDICON(pItem.analysisType,
                pItem.simulationKind);
            aIconDIV.innerHTML = aIconSVG;
        }

        let aLabelClone = aLabel.cloneNode(true) as HTMLElement;
        pContainer.appendChild(aLabelClone);

        const aClassName = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.type === pItem.analysisType && item.name === pItem.name).color;
        aLabelClone.classList.add(aClassName);
        pItem.label = aLabelClone;
        let aNameItem = Op3dUtils.getElementIn(aLabelClone, "analysis-label-name");
        aNameItem.innerText = pItem.name;
    }
    //__________________________________________________________________________________________
    public get getAllInvalidAnalysis() {
        let aInvalidAnalysis = new Array<AnalysisThumbnail>();
        for (let i = 0; i < this.mItems.length; i++) {
            const aItem = this.mItems[i];
            if (this.mIsDisabled) {
                continue;
            }

            let aIsActive = aItem.isActiveCheckbox == true;
            let aIsUnlocked = aItem.result == null || aItem.result.isLocked == false;

            if (aIsActive && aIsUnlocked && aItem.isInvalid) {
                aInvalidAnalysis.push(aItem);
            }
        }

        return aInvalidAnalysis;
    }
    //__________________________________________________________________________________________
    public analysisToRun(pChangedScene: boolean) {
        let aAnalysistoRun = new Array<AnalysisThumbnail>();
        for (let i = 0; i < this.mItems.length; i++) {
            const aItem = this.mItems[i];
            if (this.mIsDisabled) {
                continue;
            }

            let aIsActive = aItem.isActiveCheckbox == true;
            let aIsUnlocked = aItem.result == null || aItem.result.isLocked == false;

            if ((true === aIsActive) && (true === aIsUnlocked) &&
                ((true == aItem.changedAnalysisParameters) || pChangedScene === true)) {
                aAnalysistoRun.push(aItem);
            }
        }

        return aAnalysistoRun;
    }
    //__________________________________________________________________________________________
    private _updateSingleResult(pContainer: HTMLElement, pItem: AnalysisThumbnail) {
        this._setLabel(pContainer, pItem);

        let aSpinner = Op3dUtils.getElementIn(pContainer, "analysis-spinner");
        const pLoadingTextElement = aSpinner.querySelector('div>p');
        pLoadingTextElement.textContent = 'Uploading...'
        ViewUtils.setElementVisibilityByDNone(aSpinner, true);
        let aId = Op3dUtils.idGenerator();
        aSpinner.setAttribute("ph_id", aId); //place holder id 
        let aAnalysisContent = Op3dUtils.getElementIn(pContainer, "analysis-content");
        ViewUtils.setElementVisibilityByDNone(aAnalysisContent, false);

        EventManager.addEventListener<{
            analysis: { name: tAnalysisType, id: string },
            progress: string
        }>(EventsContext.ANALYSIS_PROGRESS,
            (pData: EventBase) => this._onProgress(pData.data), this);

        const aSingleData = this._getSingleData(pItem);

        AnalysisCache.addEventListener(aId,
            () => this._onAnalysisFinished({
                spinner: aSpinner,
                analysisItem: aSingleData,
                resultItem: pItem.result
            }), [pItem.id], false,);
    }
    //__________________________________________________________________________________________
    private _addButton(pAnalysissingleResult: HTMLElement, pResultItem: AnalysisThumbnail) {

        pAnalysissingleResult.addEventListener("click",
            (_e: MouseEvent) => this._onClickScreen(pResultItem));
    }
    //__________________________________________________________________________________________
    private _onClickScreen(pResultItem: AnalysisThumbnail) {
        if (pResultItem.result.isReady == false) {
            return;
        }
        const aAnalysisItem = pResultItem.result.analysisItem;
        AnalysisPortal.instance.onClickScreen(aAnalysisItem)
    }
    //__________________________________________________________________________________________
    private _onAnalysisFinished(pParams: {
        spinner: HTMLElement,
        analysisItem: iAnalysisItem<tAnalysisType>,
        resultItem: iAnalysisResultItem
    }) {

        let aAllWls = ExtendedViewNew.getAllWavelengthsOfItem(pParams.analysisItem);
        let aUseWavelengths = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
            item.name === pParams.analysisItem.name &&
            item.type === pParams.analysisItem.type).useOneWavelength === true;

        let aWavelengths = [];
        if (aUseWavelengths) {
            aWavelengths = [aAllWls[0]];
        }

        let aQuery: iAnalysisQuery2 = {
            id: pParams.analysisItem.id,
            polarization: ePolarizationSourceType.X,
            polarizationKind: ePolarizationSourceType.X,
            name: pParams.analysisItem.name,
            wavelengths: aWavelengths
        }

        let aCacheData = AnalysisCache.getSpecificCombinedData(aQuery, aUseWavelengths);
        /**
         * If analysis has finished successfully and we got it from cache, 
         * via resultItem we give a permission to draw the result of analysis 
         * (isReady field), and also we are transfering data from AnalysisCache to the viewer.
         */
        if (aCacheData !== undefined && aCacheData !== null) {
            let aAnalysisSingleResult = pParams.spinner.parentElement;
            pParams.resultItem.isReady = true;
            pParams.resultItem.cacheData = aCacheData;
            pParams.resultItem.analysisItem = pParams.analysisItem;
            this._hideSpinner(pParams.spinner);

            // data init
            let aItem = this.mItems.find(item => item.id == pParams.resultItem.id);
            aItem.addResult(aAnalysisSingleResult, pParams.resultItem);
        }

    }
    //__________________________________________________________________________________________
    private _hideSpinner(pSpinner: HTMLElement) {
        this.mNumOfHandledAnalysis++
        AnalysisCache.removeEventListener(pSpinner.getAttribute("ph_id"));
        ViewUtils.setElementVisibilityByDNone(pSpinner, false);
        if (this.mNumOfHandledAnalysis == this.mItems.length) {
            EventManager.removeEventListener(EventsContext.ANALYSIS_PROGRESS, this);
            this.mNumOfHandledAnalysis = 0;
        }
    }
    //__________________________________________________________________________________________
    public updateAnalysisResults(pIds: Array<string>): void {
        if (this.mIsDisabled) {
            return;
        }

        let aItems = this.mItems.filter(item => pIds.indexOf(item.id) !== -1);

        for (let i = 0; i < aItems.length; i++) {
            const aCurrItem = aItems[i];
            let aResult = aCurrItem.result;
            let aResultContainer: HTMLElement;

            if ((aCurrItem.result != null && aCurrItem.result.isLocked) ||
                aCurrItem.isActiveCheckbox == false) {
                continue;
            }

            if (aResult == null) {

                aResultContainer = this.mAnalysisResult.cloneNode(true) as HTMLElement;
                aResultContainer.setAttribute("index", i.toString());
                let aFunc = (_e) => this._onResize(aResultContainer, aCurrItem);

                AnalysesSynchronizer.instance.setLockedItems(
                    { analysisID: aCurrItem.id, isLocked: false },
                    false, true);

                aCurrItem.result = {
                    isReady: false,
                    needsUpdate: true,
                    compare: false,
                    container: aResultContainer,
                    id: aCurrItem.id,
                    isLocked: false,
                    analysisItem: null,
                    cacheData: null,
                    callback: aFunc
                };

                this.mAnalysisResultContainer.appendChild(aResultContainer);
                aCurrItem.initSingleResult();
                $(aResultContainer).resize(aFunc);
                this._addButton(aResultContainer, aCurrItem);

            } else {

                aResultContainer = aResult.container;
                aCurrItem.result.isReady = false;
                aCurrItem.result.needsUpdate = true;
            }

            this._updateSingleResult(aResultContainer, aCurrItem);
        }
    }
    //__________________________________________________________________________________________
    private _onResize(pContainer: HTMLElement, pItem: AnalysisThumbnail) {
        if (pItem.result.analysisItem == null) {
            return;
        }

        pItem.addResult(pContainer, pItem.result)
    }
    //__________________________________________________________________________________________
    private _onDisableSection(e: Event) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        this.mIsDisabled = ViewUtils.toggleClass(this.mContainer, Strings.DISABLED_OPACITY);
        !this.mIsDisabled === true && AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL);
        this._onChange()
    }
    //__________________________________________________________________________________________
    private _getSingleData(pItem: AnalysisThumbnail) {
        return pItem.getData();
    }
    //__________________________________________________________________________________________
    private _getData() {
        let aFast = new Array<iAnalysisItem<tAnalysisType>>();
        let aAdvanced = new Array<iAnalysisItem<tAnalysisType>>();

        this.mItems.forEach(item => {
            let aData = item.getData();
            aData.isActiveAnalysisSurface = this.mMainCheckbox.checked;
            if (aData.type == eAnalysisType.ADVANCED) {
                aAdvanced.push(aData);
            } else {
                aFast.push(aData);
            }
        })
        return { fast: aFast, advanced: aAdvanced };
    }
    //__________________________________________________________________________________________
    private _onChange(e?: Event) {
        if (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
        }

        let aData = this._getData();
        let aAnalysisData: iAnalysisData = {
            fast: aData.fast,
            advanced: aData.advanced,
            faceId: this.mParams.faceId
        };
        this.mParams.onChange(this.mParams.internalId, aAnalysisData);
        this.updateLabels();
    }
    //__________________________________________________________________________________________
    private _getChosenItems() {
        let aItems = new Array<{
            type: eAnalysisType,
            shortcut: string,
            name: tAnalysisType,
            simulationKind: eSimulationKind
        }>();

        for (let i = 0; i < this.mItems.length; i++) {
            let aSelect = this.mItems[i].select;
            aItems.push({
                shortcut: aSelect.getAttribute("shortcut"),
                name: this.mItems[i].name,
                type: this.mItems[i].analysisType,
                simulationKind: this.mItems[i].simulationKind
            });
        }
        return aItems;
    }
    //__________________________________________________________________________________________
    public updateLabels() {
        ViewUtils.removeElementChildren(this.mLabelsContainer);
        let aChosenItems = this._getChosenItems();
        let aShowMsg = (aChosenItems.filter((item) =>
            eSimulationKind.DIFFRACTION == item.simulationKind).length > 0);
        ViewUtils.setElementVisibilityByDNone(this.mMsgContainer, aShowMsg);


        let aLabelsNum = Math.min(aChosenItems.length, anSurfaceComponent.MAX_COUNT_LABELS)

        for (let i = 0; i < aLabelsNum; i++) {
            const aData = aChosenItems[i];
            let aLabel: HTMLElement;
            if (aData.type == eAnalysisType.ADVANCED) {
                aLabel = this.mAdvancedLabel.cloneNode(true) as HTMLElement;
            } else {
                aLabel = this.mFastLabel.cloneNode(true) as HTMLElement;
            }
            let aSimulationKind = aData.simulationKind;
            let aIconDIV = Op3dUtils.getElementIn(aLabel, 'icon-class');
            if (null != aIconDIV) {
                let aIconSVG = AnalysisContext.getSimulationDDICON(aData.type, aSimulationKind);
                aIconDIV.innerHTML = aIconSVG;
            }

            let aName = Op3dUtils.getElementIn(aLabel, "analysis-label-name", true);
            let aClass = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.type === aData.type && item.name === aData.name).color;
            // AnalysisContext.getLabelColorClassByName(aData.name, aData.type);
            aLabel.classList.add(aClass);
            aName.innerText = aData.shortcut;
            aName.title = aData.name;
            $(aName).tooltip({ html: true });
            aName.setAttribute("data-original-title", aData.name);
            this.mLabelsContainer.appendChild(aLabel);
        }

        if (aChosenItems.length > anSurfaceComponent.MAX_COUNT_LABELS) {
            let aDelta = aChosenItems.length - anSurfaceComponent.MAX_COUNT_LABELS;
            let aLabel = this.mFastLabel.cloneNode(true) as HTMLElement;
            aLabel.innerText = "+" + aDelta;
            aLabel.classList.add("label-00");
            this.mLabelsContainer.appendChild(aLabel);
        }
    }
    //__________________________________________________________________________________________
    private _onRemove(pId: string) {
        let aIdx = this.mItems.findIndex(item => item.id == pId);
        this.mItems.splice(aIdx, 1);
        this.updateLabels()
    }
    //__________________________________________________________________________________________
    private _addItem(pAnalysisData: iAnalysisItem<tAnalysisType>,
        pNotify: boolean) {

        let aLine = this.mSingleListItem.cloneNode(true) as HTMLElement;
        this.mListContainer.appendChild(aLine);
        let aItem = new AnalysisThumbnail({
            onChange: () => this._onChange(),
            onPixelOverLimit: () => this.pixelsOverLimit(),
            titleSizeCotnainer: this.mTitlesSizeContainer,
            faceId: this.mParams.faceId,
            line: aLine,
            analysisData: pAnalysisData,
            onRemove: (pId: string) => this._onRemove(pId),
        });

        this.mItems.push(aItem);
        this.updateLabels();

        if (pNotify) {
            this._onChange();
        }
    }
    //__________________________________________________________________________________________
    public updateRaysComponent() {
        this.mItems.forEach(item => {
            let aToUpdateLasers = item.updateRaysComponent();
            if (aToUpdateLasers) {
                ViewUtils.removeElementChildren(this.mLsTitlesContainer);
                this._initLaserTitle();
            }
        });
    }
    //__________________________________________________________________________________________
    public updateAnalyses(pIds: Array<string>) {
        let aExistingAnalyses = this.mItems.filter(item => pIds.indexOf(item.id) != -1);

        for (let i = 0; i < aExistingAnalyses.length; i++) {
            if ((aExistingAnalyses[i].result != null && aExistingAnalyses[i].result.isLocked) ||
                aExistingAnalyses[i].isActiveCheckbox == false) {
                continue;
            }
            if (aExistingAnalyses[i].result == null) {
                continue;
            }

            this._updateSingleResult(aExistingAnalyses[i].result.container, aExistingAnalyses[i]);
        }
    }
    //__________________________________________________________________________________________
    /**
     * udate all fast analysis in portal
     */
    //__________________________________________________________________________________________
    // public updateFast() {
    //     for (let i = 0; i < this.mItems.length; i++) {
    //         if (this.mItems[i].type == eAnalysisType.FAST) {
    //             if ((this.mItems[i].result != null && this.mItems[i].result.isLocked) ||
    //                 this.mItems[i].isActiveCheckbox == false) {
    //                 continue;
    //             }
    //             if (this.mItems[i].result == null) {
    //                 continue;
    //             }

    //             this._updateSingleResult(this.mItems[i].result.container, this.mItems[i]);
    //         }
    //     }
    // }
    //__________________________________________________________________________________________
    private _initLaserTitle() {
        let aLasers = Op3dContext.PARTS_MANAGER.getLasersParts();
        for (let id in aLasers) {
            let aLsTitle = this.mLightSourceTitle.cloneNode(true) as HTMLElement;
            this.mLsTitlesContainer.appendChild(aLsTitle);
            aLsTitle.innerText = aLasers[id].getShortLabel();
        }
    }
    //__________________________________________________________________________________________
    public get faceId() {
        return this.mParams.faceId;
    }
    //__________________________________________________________________________________________
    public get internalID() {
        return this.mParams.internalId;
    }
    //__________________________________________________________________________________________
}
