import { iAsyncCallback, iNameURL } from "../_context/_interfaces/Interfaces";

export type tMimeType = 'application/x-binary' | 'text/csv' | 'application/json' | 'text/plain' |
    'application/octet-stream' | 'image/png' | 'image/jpg' | 'text/plain;base64';
export class FileUtils {

    //______________________________________________________________________________________________
    public static downloadFile(pData: {
        content: any,
        mimeType: tMimeType
        /**
         * @description this name should contain the file type
         */
        fullfileName: string
    }) {

        let aBlob = new Blob([pData.content],
            { type: pData.mimeType });

        let link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute("href", URL.createObjectURL(aBlob));
        link.setAttribute("download", pData.fullfileName);
        link.click();




        // let dataStr = `data: ${pData.mimeType};charset=utf-8,` + pData.content;
        // let dlAnchorElem = document.createElement('a');
        // dlAnchorElem.setAttribute("href", dataStr);
        // dlAnchorElem.setAttribute("download", pData.fullfileName);
        // dlAnchorElem.click();


    }
    //______________________________________________________________________________________________
    public static loadFileDataurl(pFile: File) {
        return new Promise<iAsyncCallback<string>>((resolve) => {
            let aReader = new FileReader();
            aReader.onload = () => {
                resolve({ success: true, data: aReader.result as string });
            };
            aReader.onerror = (error) => {
                resolve({ success: false, data: error as any });
            };
            aReader.readAsDataURL(pFile);
        });
    }
    //______________________________________________________________________________________________
    public static loadFileXlsx(pFile: File) {
        return new Promise<iAsyncCallback<{ name: string, data: Array<string> }>>(resolve => {
            const reader = new FileReader();

            reader.onload = function (e) {
                const data = new Uint8Array(e.target.result as any);
                const workbook = XLSX.read(data, { type: 'array', });

                // Access the data in the XLSX file
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: true }) as Array<string>;
                resolve({ success: true, data: { data: jsonData, name: pFile.name } });
            };

            reader.readAsArrayBuffer(pFile);
        })
    }
    //______________________________________________________________________________________________
    public static loadFileText(pFile: File) {
        return new Promise<iAsyncCallback<string>>((resolve) => {
            let aReader = new FileReader();
            aReader.onload = () => {
                resolve({ success: true, data: aReader.result as string });
            };
            aReader.onerror = (error) => {
                resolve({ success: false, data: error as any });
            };
            aReader.readAsText(pFile);
        });
    }
    //______________________________________________________________________________________________
    public static downloadZipFile(pFile: iNameURL) {
        let aBlobPromise = fetch(pFile.url).then((r) => {
            if (200 == r.status) {
                return r.blob();
            }

            return Promise.reject(new Error(r.statusText));
        });

        const zip = new JSZip();
        const folder = zip.folder("files");
        folder.file(pFile.name, aBlobPromise);
    }
    //______________________________________________________________________________________________
}
