﻿import { Op3dContext } from "../../../_context/Op3dContext";
import { iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Part } from "../../../parts/Part";
import { iFace, iFaceDataNEW } from "../../../parts/PartInterfaces";
import { ServerContext } from "../../../server/ServerContext";
import { newNumberInputElement } from "../../part_info/_components/NewNumberInputElement";
import { uoSection } from "./uoSection";

export class esSimulationSettings extends uoSection<iFace | iFaceDataNEW> {

    private static SKIN_PATH = './skins/forms/optics/es_sim_info.html';
    private mReflecitionCheckbox: iOP3DHTMLInputElement;
    private mMaxBounces: newNumberInputElement;

    private mFace: iFace | iFaceDataNEW;
    private mPart: Part = null;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: esSimulationSettings.SKIN_PATH,
            title: 'Simulation settings',
            isNewSkin: false,
            isPremiumSection: true
        });
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mReflecitionCheckbox = Op3dUtils.getElementIn(this.mContainer,
            'no_reflection_checkbox', true) as iOP3DHTMLInputElement<iFace | iFaceDataNEW>;
        let aLabel = Op3dUtils.getElementIn(this.mContainer, "no_reflection_checkbox_label", true) as HTMLLabelElement;
        aLabel.htmlFor = this.mReflecitionCheckbox.id;

        let aMaxBouncesContainer = Op3dUtils.getElementIn(this.mContainer, 'max_bounces');
        this.mMaxBounces = new newNumberInputElement(aMaxBouncesContainer, {
            label: 'Max bounces',
            hasArrows: true,
            isGlobalToFixed: false,
            qa_id: 'bounces_input'
        });
        this.mMaxBounces.setData({
            // callback: (pValue) => this.mFace.data.reflectionData.max_num_bounces = pValue,
            range: {
                min: 0,
                max: 50
            },
            step: 1,
            isInt: true
        });

        this.mReflecitionCheckbox.addEventListener('change',
            () => this._onChangeNoReflectionCheckbox());

        let aLinkToPricing = this._getPart('try-professional-label', true) as HTMLAnchorElement;
        aLinkToPricing.href = ServerContext.pricing_base_link;
    }
    //__________________________________________________________________________________________
    private _onChangeNoReflectionCheckbox() {
        Op3dContext.SCENE_HISTORY.addToHistory();

        let aIsReflectionEnabled = (false == this.mReflecitionCheckbox.checked);
        /**
         * changes the info in the face object, remove it from there and do func saveScatter();
         */
        // this.mCurrFace.data.reflectionData.enabled = aIsReflectionEnabled;
        // this.mCurrFace.data.reflectionData.max_num_bounces = this.mBouncesValue
        this.mMaxBounces.enable = aIsReflectionEnabled;
        if (this.mPart != null) {
            if (this.mPart.opticalData.faces == null) {
                this.mPart.opticalData['faces'] = [];
            }
            let aItems = this.mPart.opticalData.faces.filter(item => item.name == this.mFace.name)
            if (aItems.length < 1) {
                this.mPart.opticalData.faces.push(this.mFace)
            } else {
                aItems[0] = this.mFace;
            }
        }
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mFace = null;
        this.mPart = null;
    }
    //__________________________________________________________________________________________
    protected async _setData(pFace: iFace | iFaceDataNEW, pOptomechanicsLens?: Part) {
        this._clear();

        let aRefData;
        this.mPart = pOptomechanicsLens != null ? pOptomechanicsLens : null;
        if (null != pFace.data && null != pFace.data.reflectionData) {
            aRefData = pFace.data.reflectionData;
        } else if (this.mPart != null) {
            if (pFace["data"] == null) {
                pFace["data"] = {}
            };
            pFace.data["reflectionData"] = {
                enabled: false,
                max_num_bounces: 0
            }
            aRefData = pFace.data.reflectionData
        }

        let aIsReflectionEnabled = aRefData.enabled;
        this.mMaxBounces.value = aRefData.max_num_bounces;
        this.mFace = pFace;
        this.mReflecitionCheckbox.checked = (false == aIsReflectionEnabled);
        this.mMaxBounces.enable = aIsReflectionEnabled;
    }
    //__________________________________________________________________________________________
    public saveSimulationSettings(){
        let aIsReflectionEnabled = (false == this.mReflecitionCheckbox.checked);
        this.mFace.data.reflectionData.enabled = aIsReflectionEnabled;
        this.mFace.data.reflectionData.max_num_bounces = this.mMaxBounces.value;
    }
    //__________________________________________________________________________________________
}
