import { Vector3, Matrix4 } from "three";
import { MathContext } from "../../_context/MathContext";
import { iHash, iSingleCellData, iPolarizationSectionData } from "../../_context/_interfaces/Interfaces";
import { MatrixUtils } from "../../_utils/MatrixUtils";
import { eSmRaysDensity, eSmDivergenceType, eSmRaysKind, eSmBaseShapeKind, eSmPlaneWaveType, iSmSystemFile, iSmShapeData, iSmLightSourceData, eSmLightSourceKind, iSmGeometryData, eSmGeometryType, iSingleArraySourcesItem, iClientGaussianSource } from "../../simulation/SimulationContext";
import { Part } from "../Part";
import { ComplexNumber } from "../../_utils/ComplexNumber";
import { piGaussianBeam } from "../../ui/part_info/_light_source_section/piGaussianBeam";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { iArrayOfElementsOptions } from "../PartInterfaces";

export enum eWavelengthDistributionType {
    GAUSSIAN = "GAUSSIAN",
    TH = "TH",
    BLACKBODY = "BLACKBODY",
    USER_DEFINED = "USER_DEFINED"
}

export interface iCommonSourceData {
    count: number;
    count_analysis: number;

    /**
     * @deprecated
     */
    density?: eSmRaysDensity;
    density_pattern: eSmRaysDensity;
}

export interface iPointSourceData extends iCommonSourceData {
    angle_x: number;
    angle_y: number;
    divergence_type: eSmDivergenceType;
    dist_z: number;
    half_width_dist_wx: number;
    half_width_dist_wy: number;

};

export interface iCircularPlaneWaveData extends iCommonSourceData {
    radius: number;
};

export interface iMaskData {
    size: number;
    mask_name?: string;
    mat_size: number;
    mask_data: iHash<iSingleCellData>
}

export interface iRectanglePlaneWaveData extends iCommonSourceData {
    width: number;
    height: number;
};

export interface iLaserData<T = tRaysKind> {
    direction: Vector3;
    exitPoint: Vector3;
    lightSource: iLightSource<T>;
    isSingleSource: boolean;
    isUserDefinedLaser: boolean;
};

export interface iEllipticalPlaneWaveData extends iCommonSourceData {
    radius_x: number;
    radius_y: number;
}

export interface iRayFileData extends iCommonSourceData {
    ray_file_name: string;
}

export type tRaysKind = iRectanglePlaneWaveData |
    iCircularPlaneWaveData | iPointSourceData |
    iEllipticalPlaneWaveData | iRayFileData;

export interface iWavelengthSingleItem {
    wl: number;
    weight: number;
    isPrimary: boolean;
}

export interface iWavelengthFile {
    wavelength: number;
    weight: number;
}

export interface iLightSource<T = tRaysKind> extends iBasicLightSource<T> {
    light_source_number_id: string;
}

export interface iSourceDirectionalData {
    direction_theta: number;
    direction_phi: number;
    direction_x: number;
    direction_y: number;
    direction_z: number;
    azimuth_z: number;
}

export interface iAppearanceData {
    model_radius?: number;
    alpha?: number;
    color?: string;
    rays_color?: string;
}

export interface iWavelengthData {
    wavelengthData: Array<iWavelengthSingleItem>;
    distribution_data: iWavelenghtDistributionData;
}

export type tWavelenghtDistributionData = iWavelenghtDistributionTH &
    iWavelenghtDistributionBlackbody & iWavelenghtDistributionGaussian &
    iWavelenghtDistributionRange;

export interface iWavelenghtDistributionData {
    type: eWavelengthDistributionType;
    data?: tWavelenghtDistributionData;
    preset_id?: string;
}

export interface iWavelenghtDistributionTH {
}

export interface iWavelenghtDistributionBlackbody {
    blackbody_temperature?: number;
    blackbody_max: number;
}

export interface iWavelenghtDistributionGaussian {
    gauss_center?: number;
    gauss_width: number;
}

export interface iWavelenghtDistributionRange {
    min: number;
    max: number;
    delta: number;
}

export interface iBasicLightSourceParams<T = tRaysKind> {
    kind: eSmRaysKind;
    sourceGeometricalData: T;
}

export enum eCountType {
    TOTAL = "TOTAL",
    PER_WAVELENGTH = "PER_WAVELENGTH"
}

export enum ePropogationType {
    RAY_TRACING = "Ray tracing propogation",
    BEAM_PROPOGATION = "Beam propagation",
    IMPROVED_RAY_TRACING = "Improved ray tracing propogation"
};





export interface iGaussianBeam {
    q_x: ComplexNumber;
    q_y: ComplexNumber;
};

export interface iBasicLightSource<T = tRaysKind> extends iBasicLightSourceParams<T>, iAppearanceData, iWavelengthData {
    isUserDefined?: boolean;
    power: number;
    shape: eSmPlaneWaveType;
    count_type: eCountType;
    directionalData: iSourceDirectionalData;
    polarization: iPolarizationSectionData;
    arrayOfSourcesData?: iArrayOfElementsOptions;
    gaussianBeam?: iGaussianBeam;
};


export interface iSourceShapeData {
    lightSource: iLightSource,
    systemFile: iSmSystemFile,
    wavelengths: Array<number>,
    numRays: Number,
    matrix: Matrix4,
    normalizedWeights: Array<number>,
    part: Part,
    jones_vector: Array<Array<number>>;
}

export interface iBasicSourceShapeData {
    lightSource: iLightSource;
    systemFile: iSmSystemFile;
    wavelengths: Array<number>;
    matrix: THREE.Matrix4;
    normalizedWeights: Array<number>;
    part: Part;
}

export class LightSourceContext {
    private static CONST_DIST_Z: number = 5;
    public static mDefaultJones = [[0.70711, 0], [0.70711, 0]];
    public static addPointSource(pParams: iSourceShapeData) {

        pParams.systemFile.light_sources.push({
            id: pParams.part.internalID,
            ray_generation: [{
                shape: pParams.lightSource.shape,
                number_id: pParams.lightSource.light_source_number_id,
                kind: pParams.lightSource.kind,
                wavelengths: pParams.wavelengths,
                wlWeights: pParams.normalizedWeights,
                isCustom: pParams.lightSource.isUserDefined,
            }]
        });

        const aGeo = pParams.lightSource.sourceGeometricalData as iPointSourceData;
        let aShapeIndex: number;
        let aFarFieldDist: number;
        let aSourceShape: iSmShapeData;
        switch (aGeo.divergence_type) {
            case eSmDivergenceType.HALF_WIDTH_AT_Z: {
                // this shape is fixed as rectangular
                aSourceShape = {
                    kind: eSmBaseShapeKind.RECTANGLE,
                    width: aGeo.half_width_dist_wx * 2,
                    height: aGeo.half_width_dist_wy * 2
                }
                aFarFieldDist = aGeo.dist_z;
                aShapeIndex = Part.getAttrIndex(pParams.systemFile, 'shapes', aSourceShape);
                break;
            }
            case eSmDivergenceType.HALF_CONE_ANGLE: {
                let aNewHalfAngleX = Math.tan(aGeo.angle_x * MathContext.DEG_TO_RAD) * LightSourceContext.CONST_DIST_Z;
                let aNewHalfAngleY = Math.tan(aGeo.angle_y * MathContext.DEG_TO_RAD) * LightSourceContext.CONST_DIST_Z;

                aSourceShape = {
                    kind: eSmBaseShapeKind.ELLIPSE,
                    radius_x: aNewHalfAngleX,
                    radius_y: aNewHalfAngleY
                }
                aFarFieldDist = LightSourceContext.CONST_DIST_Z;
                aShapeIndex = Part.getAttrIndex(pParams.systemFile, 'shapes', aSourceShape);
                break;
            }
            case eSmDivergenceType.HALF_WIDTH_RECT: {
                let aNewHalfSideX = Math.tan(aGeo.angle_x * MathContext.DEG_TO_RAD) * LightSourceContext.CONST_DIST_Z;
                let aNewHalfSideY = Math.tan(aGeo.angle_y * MathContext.DEG_TO_RAD) * LightSourceContext.CONST_DIST_Z;

                aSourceShape = {
                    kind: eSmBaseShapeKind.RECTANGLE,
                    width: aNewHalfSideX * 2,
                    height: aNewHalfSideY * 2
                }
                aFarFieldDist = LightSourceContext.CONST_DIST_Z;
                aShapeIndex = Part.getAttrIndex(pParams.systemFile, 'shapes', aSourceShape);
                break;
            }
        }

        let aIsArray = pParams.lightSource.arrayOfSourcesData != null ?
            pParams.lightSource.arrayOfSourcesData.is_array : false;

        let aSource: iSmLightSourceData = {
            is_array: aIsArray,
            count_type: pParams.lightSource.count_type,
            shape: aShapeIndex,
            power: pParams.lightSource.power,
            wavelength_weights: pParams.normalizedWeights,
            id: pParams.part.internalID,
            kind: eSmLightSourceKind.POINT,
            num_rays: pParams.numRays,
            world_matrix: pParams.matrix.toArray(),
            wavelengths: pParams.wavelengths,
            density_pattern: pParams.lightSource.sourceGeometricalData.density_pattern,
            far_field_distance: aFarFieldDist,
            jones_vector: pParams.jones_vector
        }

        if (aIsArray) {
            this._addArrayOfSourcesData(aSource, pParams.systemFile, pParams.lightSource.arrayOfSourcesData);
        }

        pParams.systemFile.sources.push(aSource);
    }
    //_______________________________________________________________________
    public static addRayFile(pParams: {
        systemFile: iSmSystemFile,
        numRays: Number,
        part: Part,
        lightSource: iLightSource,
        matrix: Matrix4,
    }): void {

        pParams.systemFile.light_sources.push({
            id: pParams.part.internalID,
            ray_generation: [{
                kind: pParams.lightSource.kind,
                ray_file_name: (pParams.lightSource.sourceGeometricalData as iRayFileData).ray_file_name,
                wavelengths: [],
                wlWeights: [],
                isCustom: false,
                number_id: null,
                shape: null,
            }]
        });

        let aSource: iSmLightSourceData = {
            is_array: false,
            count_type: eCountType.PER_WAVELENGTH,
            power: 0,
            wavelength_weights: [],
            id: pParams.part.internalID,
            kind: eSmLightSourceKind.RAY_FILE,
            num_rays: pParams.numRays,
            world_matrix: pParams.matrix.toArray(),
            wavelengths: [],
            jones_vector: LightSourceContext.mDefaultJones
        };
        pParams.systemFile.sources.push(aSource);
    }
    //__________________________________________________________________________________________
    public static addGaussianSource(pParams: iSourceShapeData): void {

        //For client visualisation:
        let aClientSource: iClientGaussianSource = {
            wavelength_weights: pParams.normalizedWeights,
            world_matrix: pParams.matrix,
            wavelengths: pParams.wavelengths,
            beam: pParams.lightSource.gaussianBeam
        };
        pParams.systemFile.gaussianSources.push(aClientSource);

        pParams.systemFile.light_sources.push({
            id: pParams.part.internalID,
            ray_generation: [{
                shape: pParams.lightSource.shape,
                number_id: pParams.lightSource.light_source_number_id,
                kind: pParams.lightSource.kind,
                wavelengths: pParams.wavelengths,
                wlWeights: pParams.normalizedWeights,
                isCustom: pParams.lightSource.isUserDefined
            }]
        });

        let aW = (pParams.wavelengths[0] * OP3DMathUtils.NANO_TO_MM);


        let aSource: iSmLightSourceData = {
            count_type: pParams.lightSource.count_type,
            wavelength_weights: pParams.normalizedWeights,
            power: pParams.lightSource.power,
            id: pParams.part.internalID,
            kind: eSmLightSourceKind.GAUSSIAN_BEAM,
            num_rays: pParams.numRays,
            world_matrix: pParams.matrix.toArray(),
            wavelengths: pParams.wavelengths,
            density_pattern: pParams.lightSource.sourceGeometricalData.density_pattern,
            is_array: false,
            waist_x: piGaussianBeam.getWaist(pParams.lightSource.gaussianBeam.q_x, aW),
            waist_y: piGaussianBeam.getWaist(pParams.lightSource.gaussianBeam.q_y, aW),
            jones_vector: pParams.jones_vector
        };
        pParams.systemFile.sources.push(aSource);
    }
    //_______________________________________________________________________
    public static addPlaneWaveSource(pParams: iSourceShapeData): void {

        pParams.systemFile.light_sources.push({
            id: pParams.part.internalID,
            ray_generation: [{
                shape: pParams.lightSource.shape,
                number_id: pParams.lightSource.light_source_number_id,
                kind: pParams.lightSource.kind,
                wavelengths: pParams.wavelengths,
                wlWeights: pParams.normalizedWeights,
                isCustom: pParams.lightSource.isUserDefined,
            }]
        });

        let aShapeIndex: number;
        switch (pParams.lightSource.shape) {
            case eSmPlaneWaveType.CIRCULAR: {
                let aGeo = pParams.lightSource.sourceGeometricalData as iCircularPlaneWaveData;
                const aSourceShape = { kind: eSmBaseShapeKind.CIRCLE, radius: aGeo.radius };
                aShapeIndex = Part.getAttrIndex(pParams.systemFile, 'shapes', aSourceShape);
                break;
            }
            case eSmPlaneWaveType.RECTANGULAR: {
                let aGeo = pParams.lightSource.sourceGeometricalData as iRectanglePlaneWaveData;
                const aSourceShape = {
                    kind: eSmBaseShapeKind.RECTANGLE,
                    width: aGeo.width,
                    height: aGeo.height
                }
                aShapeIndex = Part.getAttrIndex(pParams.systemFile, 'shapes', aSourceShape);
                break;
            }
            case eSmPlaneWaveType.ARBITRARY:
                throw new Error("not implemented");

            case eSmPlaneWaveType.ELLIPTICAL: {
                let aGeo = pParams.lightSource.sourceGeometricalData as iEllipticalPlaneWaveData;
                const aSourceShape = {
                    kind: eSmBaseShapeKind.ELLIPSE,
                    width: aGeo.radius_x,
                    height: aGeo.radius_y
                }
                aShapeIndex = Part.getAttrIndex(pParams.systemFile, 'shapes', aSourceShape);
                break;
            }
        }

        let aIsArray = pParams.lightSource.arrayOfSourcesData != null ?
            pParams.lightSource.arrayOfSourcesData.is_array : false;

        let aSource: iSmLightSourceData = {
            count_type: pParams.lightSource.count_type,
            wavelength_weights: pParams.normalizedWeights,
            power: pParams.lightSource.power,
            id: pParams.part.internalID,
            kind: eSmLightSourceKind.SHAPE,
            num_rays: pParams.numRays,
            shape: aShapeIndex,
            world_matrix: pParams.matrix.toArray(),
            wavelengths: pParams.wavelengths,
            density_pattern: pParams.lightSource.sourceGeometricalData.density_pattern,
            is_array: aIsArray,
            jones_vector: pParams.jones_vector
        }

        if (aIsArray) {
            this._addArrayOfSourcesData(aSource, pParams.systemFile, pParams.lightSource.arrayOfSourcesData);
        }

        pParams.systemFile.sources.push(aSource);
    }
    //_______________________________________________________________________
    private static _addArrayOfSourcesData(
        pSource: iSmLightSourceData,
        pSysFile: iSmSystemFile,
        pArraySourcesData: iArrayOfElementsOptions) {

        if (pArraySourcesData.is_array == false) {
            return;
        }

        let aSourceShape: iSmShapeData;
        switch (pArraySourcesData.data.kind) {
            case eSmBaseShapeKind.ELLIPSE: {
                aSourceShape = {
                    kind: eSmBaseShapeKind.ELLIPSE,
                    radius_x: pArraySourcesData.data.radius_x,
                    radius_y: pArraySourcesData.data.radius_y
                }
                break;
            }
            case eSmBaseShapeKind.RECTANGLE: {
                aSourceShape = {
                    kind: eSmBaseShapeKind.RECTANGLE,
                    width: pArraySourcesData.data.half_width * 2,
                    height: pArraySourcesData.data.half_height * 2
                }
                break;
            }
        }

        let aShapeIndex = Part.getAttrIndex(pSysFile, 'shapes', aSourceShape);

        let aGeo: iSmGeometryData = {
            shape: aShapeIndex,
            kind: eSmGeometryType.PLANAR
        }


        if (pArraySourcesData.data.sourceShapeData != null) {
            const aSurfaceShapeData = pArraySourcesData.data.sourceShapeData[0];
            aGeo.kind = eSmGeometryType.BICONIC;
            aGeo.k_x = 0;
            aGeo.k_y = 0;
            // aGeo.polynomial = MatrixUtils.deformationToSM(aSurfaceShapeData,aGeo);
            aGeo.radius_x = 0;
            aGeo.radius_y = 0;
            let aPolynomialData = MatrixUtils.deformationToSM(aSurfaceShapeData);
            if (aPolynomialData != null) {
                aGeo.polynomial = aPolynomialData;
            }
        }

        let aGeoIdx = Part.getAttrIndex(pSysFile, 'geometries', aGeo);
        let aItem: iSingleArraySourcesItem = {
            geometry: aGeoIdx,
            step_x: pArraySourcesData.data.sampling_x,
            step_y: pArraySourcesData.data.sampling_y,
        }

        pSource.array = aItem;
    }
    //_______________________________________________________________________

}