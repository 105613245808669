import { ePolarizationSourceType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { iMinMax, iPoint2D, iSize } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { OptixReader } from "../../_utils/OptixReader";
import { iAnalysisData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { iAnalysisResponseData } from "../../simulation/SimulationRunner";
import { Popup } from "../forms/Popup";
import { iAnalysisCacheData, AnalysisCache, iAnalysisCommonData } from "./AnalysisCache";
import { AnalysisConstants } from "./AnalysisConstants";
import { iAnalysisItem, tAnalysisType, eAnalysisDisplay } from "./AnalysisContext";
import { AnalysisMatrix } from "./AnalysisMatrix";
import { ExtendedViewNew } from "./_extendedView/ExtendedViewNew";



export class AnalysisDataUtils {
    private static SQRT_METER_TO_SQRT_CM = (1 / 10000) * 2;
    private static SQRT_METER_TO_SQRT_CM_COHERENT = (1 / 10000);
    private static SQRT_METER_TO_SQRT_CM_POLARIZED = (1 / 10000) * 1;
    //__________________________________________________________________________________________
    private static _flipMatrixVerticaly(pMat: Array<Array<number>>, pRes: iPoint2D) {
        let aFlippedMat = new Array<Array<number>>();

        for (let i = pRes.y - 1; i >= 0; i--) {
            let aRow = new Array<number>();
            for (let j = 0; j < pRes.x; j++) {
                aRow.push(pMat[i][j]);
            }

            aFlippedMat.push(aRow);
        }

        return aFlippedMat;
    }
    //______________________________________________________________________________________________
    private static _flipMatrixHorizontaly(pMat: Array<Array<number>>) {
        let aFlippedMat = new Array<Array<number>>();

        for (let i = 0; i < pMat.length; i++) {
            aFlippedMat[i] = new Array<number>();
            for (let j = pMat[i].length - 1; j >= 0; j--) {
                aFlippedMat[i].push(pMat[i][j]);
            }
        }

        return aFlippedMat;
    }
    //______________________________________________________________________________________________
    private static _readMatrix(pView: DataView, pPointer: number,
        pResolution: iPoint2D, pAnalysysName: tAnalysisType) {

        let aValuesRange: iMinMax = {
            min: -1,
            max: -1
        };

        let aMatrix = new Array<Array<number>>();
        for (let x = 0; x < pResolution.y; x++) {

            aMatrix[x] = new Array<number>();
            for (let y = 0; y < pResolution.x; y++) {
                let aVal = (pView.getFloat32(pPointer, true));
                switch (pAnalysysName) {
                    case "Spot (Coherent Irradiance)":
                    case "Spot (Incoherent Irradiance)":
                        aVal *= AnalysisDataUtils.SQRT_METER_TO_SQRT_CM;
                        break;
                    case "Spot (Coherent Irradiance) Huygens":
                    case "Spot (Coherent Irradiance) Fresnel":
                    case "Spot (Coherent Irradiance) Fresnel Far":
                    case "Fresnel PSF":
                    case "Huygens PSF":
                        aVal *= AnalysisDataUtils.SQRT_METER_TO_SQRT_CM_COHERENT;
                        break;
                    case "Spot (Coherent Irradiance) Polarized":
                    case "Spot (Incoherent Irradiance) Polarized":
                        // case "Coherent Phase Polarized":
                        aVal *= AnalysisDataUtils.SQRT_METER_TO_SQRT_CM_POLARIZED;
                        break;
                }

                pPointer += OptixReader.SIZEOF_INT;


                aValuesRange.min = aValuesRange.min == -1 ? aVal : Math.min(aValuesRange.min, aVal);
                aValuesRange.max = aValuesRange.max == -1 ? aVal : Math.max(aValuesRange.max, aVal);

                // if (aValuesRange.min == -1) {
                //     aValuesRange.min = aVal;
                // } else {
                //     aValuesRange.min = Math.min(aValuesRange.min, aVal);
                // }

                // if (aValuesRange.max == -1) {
                //     aValuesRange.max = aVal;
                // } else {
                //     aValuesRange.max = Math.max(aValuesRange.max, aVal);
                // }

                aMatrix[x][y] = aVal;
            }
        }

        let aFlipX = this._flipMatrixHorizontaly(aMatrix);
        let aFlipY = this._flipMatrixVerticaly(aFlipX, pResolution);

        return {
            values_range: aValuesRange,
            pointer: pPointer,
            matrix: aFlipY
        };
    }
    //______________________________________________________________________________________________
    public static getAnalysisData(pAnalysisIds: Array<string>) {

        let aResults = new Array<{ analysisItem: iAnalysisItem, cacheData: iAnalysisCacheData }>();
        const aParts = Op3dContext.PARTS_MANAGER.parts;
        let aAnalysisOptions: Array<iAnalysisData>;
        for (let i = 0; i < aParts.length; i++) {
            aAnalysisOptions = aParts[i].analysisOptions;

            if (aAnalysisOptions != null && aAnalysisOptions.length > 0) {

                for (let _idx in aAnalysisOptions) {
                    let aAllAnalysis = new Array<iAnalysisItem>();
                    aAnalysisOptions.forEach(element => {
                        aAllAnalysis.push(...element.fast);
                        aAllAnalysis.push(...element.advanced);
                    });

                    let aAnalysis = aAllAnalysis.filter(analysis => pAnalysisIds.indexOf(analysis.id) != -1);
                    aAnalysis.forEach(item => aResults.push({
                        analysisItem: item,
                        cacheData: AnalysisCache.getSpecificCombinedData({
                            name: item.name,
                            /**
                             * @TODO:
                             * analysis - send the suitable polarization value
                             */
                            polarization: ePolarizationSourceType.X,
                            polarizationKind: ePolarizationSourceType.X,
                            id: item.id,
                            wavelengths: []
                        }, false)
                    }));
                }
            }
        }

        return aResults;
    }
    //______________________________________________________________________________________________
    public static handleAnalysis(pAnalysis: iAnalysisResponseData, pWavelengths: Array<number>) {
        return new Array<iAnalysisCacheData>(...this._readSingleFile(pAnalysis, pWavelengths));
    }
    //______________________________________________________________________________________________
    private static _readSingleFile(pData: iAnalysisResponseData, pWavelengths: Array<number>) {
        // console.log("analysis: for start _readSingleFile-------------------------------",
        //     (new Date().getTime() - simulation.SimulationRunner.start_analysis) / 1000);

        let aPointer = 0;
        let aView = new DataView(pData.content);

        //int32_t       magic number (0x3D4F7074)
        (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        // console.log("aMagicNumber", aMagicNumber);

        // int32_t       data kind (1 - for SPOT analysis data)
        (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        // console.log("aDataKind", aDataKind);

        // int32_t       data kind (1 - for SPOT analysis data)
        (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        // console.log("aDataTypeKind", aDataTypeKind);

        // int32_t       polarization kind (0 - for NONE data)
        let aPolarizationTypeKind = (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        // console.log(aPolarizationTypeKind, "polarization kind");

        //int32_t       version (currently, 1)
        (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        //console.log("aVersion", aVersion);

        //int64_t       num_hits 
        let aNumHits = (aView.getBigInt64(aPointer, true));
        aPointer += OptixReader.SIZEOF_LONG;
        //console.log("aResolutionY", aResolutionY);

        //int32_t       num_wavelengths
        let aNumWavelengths = (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        // c/onsole.log("aNumWavelengths", aNumWavelengths);

        //int32_t       resolution_x
        let aResolutionX = (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        //console.log("aResolutionX", aResolutionX);

        //int32_t       resolution_y
        let aResolutionY = (aView.getInt32(aPointer, true));
        aPointer += OptixReader.SIZEOF_INT;
        //console.log("aResolutionY", aResolutionY);

        //console.log("finish data");
        // ------------------------------------------


        let aAnalysis = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
            ((item.name === pData.analysis_name) && (item.type === pData.analysisType)));

        let aData: Array<iAnalysisCacheData> = [];
        for (let i = 0; i < aNumWavelengths; i++) {

            let aResolution: iPoint2D = { x: aResolutionX, y: aResolutionY };
            let aRes = this._readMatrix(aView, aPointer, aResolution, pData.analysis_name);
            // let aS_mat: Array<Array<number>>;
            // let aP_mat: Array<Array<number>>;
            aPointer = aRes.pointer;

            let aAnalysisID = pData.analysis_id;
            let aCommonData: iAnalysisCommonData = {
                analysis_id: aAnalysisID,
                faceId: pData.faceId,
                x_range: { min: -pData.faceSize.x / 2, max: pData.faceSize.x / 2 },
                y_range: { min: -pData.faceSize.y / 2, max: pData.faceSize.y / 2 },
                total_hits: Number(aNumHits),
                resolution: aResolution,
                analysisType: pData.analysisType,
                wavelength: pWavelengths[i],
                polarization_kind: aPolarizationTypeKind,
            }

            let aPower: number;

            if (true === aAnalysis.isPowerReadingAnalysis) {
                aPower = AnalysisDataUtils._getTotalPower(aRes.matrix, aCommonData);
            } else {
                aPower = 0;
            }
            let aResult: iAnalysisCacheData = {
                common_data: aCommonData,
                matrix: new AnalysisMatrix({
                    id: Op3dUtils.idGenerator(),
                    matrix: aRes.matrix,
                    values_range: aRes.values_range,
                    totalPower: aPower
                }),
                polarization: aPolarizationTypeKind,   // it's always X by default
                name: pData.analysis_name
            };

            aData.push(aResult);
        }
        if (true === aAnalysis.isPowerReadingAnalysis) {
            let aClearId = ExtendedViewNew.returnClearPolarizationAnalysisId(pData.analysis_id);
            let aPart = Op3dContext.PARTS_MANAGER.getPartByAnalysisById(aClearId);
            if (aPolarizationTypeKind === ePolarizationSourceType.NONE) {
                let aTotalPower = 0;
                for (let i = 0; i < aData.length; i++) {
                    aTotalPower += aData[i].matrix.working.totalPower;
                }

                aPart.updateTotalPowerLabel(pData.faceId, aTotalPower);
            }
        }

        return aData;
    }
    //______________________________________________________________________________________________
    private static _getTotalPower(pMatrix: Array<Array<number>>, pCommonData: iAnalysisCommonData) {
        let aTotalIntensity = DataUtils.sumMatrix(pMatrix);
        let aCount = (pCommonData.resolution.x * pCommonData.resolution.y);
        let aAvgIntensity = aTotalIntensity / aCount;
        let aSizeMM: iSize = {
            height: ((pCommonData.y_range.max - pCommonData.y_range.min)),
            width: ((pCommonData.x_range.max - pCommonData.x_range.min))
        };

        let aSInMM = (aSizeMM.width * aSizeMM.height);
        let aSInCm2 = (aSInMM / 100);
        let aTotalPower = (aSInCm2 * aAvgIntensity);

        return aTotalPower;
    }
    //______________________________________________________________________________________________
    public static getCrossSectionValues(pCacheData: iAnalysisCacheData,
        pDisplay: eAnalysisDisplay, pPixel: number) {
        /**
         * @TODO analysis fix the calculation
         */
        let aValues = new Array<iPoint2D>();

        let aMsg = null;
        if (pDisplay == eAnalysisDisplay.X_CROSS_SECTION) {
            let aMax = pCacheData.common_data.resolution.y;
            if (pPixel > 0 && pPixel < aMax) {
                aValues = this._getXPoints(pCacheData, pPixel);
            } else {
                aMsg = `Pixel can be up to ${aMax}`
            }
        } else if (pDisplay == eAnalysisDisplay.Y_CROSS_SECTION) {
            let aMax = pCacheData.common_data.resolution.x;
            if (pPixel > 0 && pPixel < aMax) {
                aValues = this._getYPoints(pCacheData, pPixel);
            } else {
                aMsg = `Pixel can be up to ${aMax}`
            }
        }

        if (aMsg != null) {
            Popup.instance.open({
                text: aMsg
            })
            return [];
        }

        return aValues;
    }
    //______________________________________________________________________________________________
    private static _getYPoints(pCacheData: iAnalysisCacheData, pPixel: number) {
        let aValues = new Array<iPoint2D>();
        let aP0 = { x: pPixel, y: 0 };
        let aP1 = { x: pPixel, y: pCacheData.common_data.resolution.y };
        let aSlope = (aP1.y - aP0.y) / (aP1.x - aP0.x);
        if (aSlope == Infinity) {
            aSlope = 0;
        }
        let aDeltaX: number;
        let aDeltaY = 1;
        let aCurrPoint: iPoint2D = { x: aP0.x, y: aP0.y };
        do {
            let aVal = pCacheData.matrix.working.matrix[aCurrPoint.y][aCurrPoint.x];
            //let aVal = pCacheData.matrix[aCurrPoint.x][aCurrPoint.y];
            aValues.push({
                x: aCurrPoint.y,
                y: aVal,
            });

            aDeltaX = Math.round(aDeltaY * aSlope);
            aCurrPoint.x += aDeltaX;
            aCurrPoint.y += aDeltaY;

        } while (aCurrPoint.y < aP1.y)

        return aValues;
    }
    //______________________________________________________________________________________________
    private static _getXPoints(pCacheData: iAnalysisCacheData, pPixel: number) {
        let aValues = new Array<iPoint2D>();
        let aP0 = { x: 0, y: pPixel };

        let aP1 = { x: pCacheData.common_data.resolution.x, y: pPixel };
        let aSlope = (aP1.y - aP0.y) / (aP1.x - aP0.x);
        let aDeltaX = 1;
        let aDeltaY: number;
        let aCurrPoint: iPoint2D = { x: aP0.x, y: aP0.y };
        do {

            //let aVal = pCacheData.matrix[aCurrPoint.x][aCurrPoint.y];
            let aVal = pCacheData.matrix.working.matrix[aCurrPoint.y][aCurrPoint.x];
            aValues.push({
                x: aCurrPoint.x,
                y: aVal
            });

            aDeltaY = Math.round(aDeltaX * aSlope);
            aCurrPoint.x += aDeltaX;
            aCurrPoint.y += aDeltaY;
        } while (aCurrPoint.x < aP1.x)

        return aValues;
    }
    //______________________________________________________________________________________________
}
