import { eLegType, eDataPermission, eCadType } from "../../_context/Enums";
import { eBaseShape } from "../../_context/OpticsContext";
import { iVectorNumeric, iCadOpticalData, iMinMax } from "../../_context/_interfaces/Interfaces";
import { ServerContext } from "../../server/ServerContext";
import { iGeneralVOData, GeneralVO, eObjectType } from "./GeneralVO";
import { iPartVOLaserData, LaserData } from "./LaserData";
import { iOpticsVOGeometry } from "./OpticsVOInterfaces";


export interface iPartParserData {
    mount_data?: iPartMountsParserData;
}
export interface iPartMountsParserData {
    number_id: string;
    type: string;
    sub_type: string;
    brand: string;
    typebrand: string;
    catalog_number: string;
    main_name?: string;
    metric_imperial?: string;
    material?: string;
    finish_coated?: string;
    optical_shape?: string;
    construction?: string;
    maximum_depth?: number;
    size_of_compatible_optics_min?: number;
    size_of_compatible_optics_max?: number;
    tall_rectungular_optics?: number;
    optics_diameter_size_min?: number;
    optics_diameter_size_max?: number;
    optic_thickness_min?: number;
    optic_thickness_max?: number;
    thickness_of_compatible_optics_min?: number;
    thickness_of_compatible_optics_max?: number;
    clear_aparture_diameter?: number;
    optical_height?: number;
    platform_size?: number;
    compatible_lens_tubes?: string;
    type_of_movement?: string;
    type_of_drive?: string;
    key_feature?: string;
    lock_nuts?: string;
    actuator_orientation?: string;
    resolution_tilt?: number;
    platform_thread?: string;
    thread_type?: string;
    adjustment_range_tilt?: string;
    adjustment_range_rotation?: string;
    resolution_rotation?: string;
    adjuster_thread?: string;
    adjuster_screw_thread?: number;
    fine_adjustment_range_rotation?: string;
    fine_tilt_angular_range?: number;
    angular_range_piezo?: string;
    angular_range?: string;
    adjustment_per_revolution?: number;
    adjustment_per_revolution_piezo?: number;
    pointing_stability?: number;
    screw_diameter_and_pitch?: string;
    adjustment_screw_pitch?: string;
    tapped_holes?: string;
    post_mounting_screw?: string;
    though_hole_dia?: number;
    mounting_holes?: string;
    mounting_thread?: string;
    sensitivity_arc?: number;
    vacuum_compatibility_torr?: string;
    weight?: number;
    weblink?: string;
    step_file?: string;
    optic_size_accomodated_min?: string;
    optic_size_accomodated_max?: string;
    optic_diameter_min?: string;
    optic_diameter_max?: string;
    weblink_status?: string;
    maximum_optic_thickness?: string;
    minimum_optic_thickness?: number;
    optic_size_max?: number;
    thickness?: number;
    a?: number;
    b?: number;
    number_of_optics?: number;
    compatible_lens_tubes_od?: string;
    inner_diameter?: string;
    od?: string;
    outer_diameter?: number;
    clear_aperture?: string;
    max_aperture?: number;
    min_aperture?: number;
    iris_diaphragm?: string;
    number_of_leaves?: number;
    number_of_blades?: number;
    travel_x?: string;
    travel_y?: string;
    travel_z?: string;
    number_of_axes?: number;
    drive_type?: string;
    drive_mechanism_per_revolution?: number;
    thread?: string;
    sensitivity_um?: number;
    vernier?: number;
    air_tank_capacity?: string;
    air_pressure?: string;
    free_air_delivery?: string;
    vacuum_compatibility_tor?: string;
}

export interface iPartVOData extends iGeneralVOData {

    hasPost?: boolean;

    /**
    * @description set of parameters for laserbox
    */
    laserData?: Array<iPartVOLaserData>;

    // /**
    // * @description physical size of part in cells
    // */
    // physicalSize: iVectorNumeric;

    /**
    * @description initial height of part
    */
    initialHeight?: number;


    /**
    * @description offset of part box
    * @default THREE.Vector3(0,0,0)
    */
    offset?: Array<number>;


    // /**
    // * @description possible connection faces TO part
    // * @default []
    // */
    // connectionFacesTo?: Array<number>;

    /**
   * @description  possible connection faces ON part
   * @default []
   */
    connectionFacesOn?: Array<number>;

    /**
   * @description link to PDF drawing of the part
   * @default ""
   */
    pdfLink?: string;

    /**
    * @description whether the part optomechanics can be hidden
    * @default false
    */
    isEnableHideOpthomechanics?: boolean;

    /**
    * @description defines the extra parts for BOM for the current part
    * @default {}
    */
    extraPartsBom?: Array<string>;

    // /**
    // * @description whether to use deafult image of part or use default jpeg image
    // * @default false
    // */
    // useDefaultImage?: boolean;

    opticsData?: iOpticsData;
    postData?: iPostData;
    railData?: iRailData;
    isConnectToRail?: boolean;
    rotationDegrees?: iVectorNumeric;
    isaddThickness?: boolean;
    opticalPartType?: eCadType;
    opticalData?: iCadOpticalData;

    parser_data?: iPartParserData;
    parameters?: any
};

export interface iOpticsData {
    geometry?: iOpticsVOGeometry;
    opticsDirection?: number;
    opticsDirectionX?: number;
    opticsDirectionY?: number;
    baseShape?: eBaseShape;
};

export enum eOpticsDirection {

}

export interface iFaceIndexData {
    shapeIndex: number;
    solidIndex: number;
    faceIndex: number;
}

// export interface iDetectorData extends iOpticsData {
//     defaultDetectorCSV: string;
//     alignParentContainer: boolean;
//     isCanChangeSize: boolean;
//     shape: eOpticShape;
//     isTransfer: boolean;
//     thickness: number;
//     surfaceColor: string;
//     isDoubleSided: boolean;
//     opacity: number;
// }

export interface iPostData {
    thirdPartyData: {
        nominalHeight: number;
        minHeight: number;
    };
    optixData: {
        nominalHeight: number;
        minHeight: number;
    };
    topHeight: number;
    maxHeight: number;
    modelHeight: number;
    isCanChangePost: boolean;
    legType: eLegType;
};

export interface iRailData {
    railBoundries: iMinMax;
}

export interface iPartVOParameters {
    catalog_number?: string;
}
export class PartVO extends GeneralVO {


    private mLaserBoxData: LaserData;

    private mOpticalPartType: eCadType;
    private mOpticalData: iCadOpticalData;
    private mParameters: iPartVOParameters;


    constructor(pData: iPartVOData) {
        super(eObjectType.PART, pData);

        if (null != pData) {
            this.mParameters = pData.parameters != null ? pData.parameters : {};

            if (null != pData.opticalData) {
                this.mOpticalPartType = pData.opticalData.type;
                this.mOpticalData = pData.opticalData;
            }

            this._initPostData(pData.postData);
            this._initLaserBoxData(pData.laserData);
        }
    }
    //_________________________________________________
    public get parameters() {
        return this.mParameters;
    }
    //_________________________________________________
    // public get rotationDegrees() {
    //     return this.mRotationDegrees;
    // }
    //_________________________________________________
    private _initPostData(pPostData: iPostData) {
        if (null == pPostData) {
            return;
        }
    }
    //_________________________________________________
    public get opticalPartType() {
        return this.mOpticalPartType;
    }
    //_________________________________________________
    public set opticalPartType(pVal: eCadType) {
        this.mOpticalPartType = pVal;
    }
    //_________________________________________________
    public get opticalData() {
        return this.mOpticalData;
    }
    //_________________________________________________
    public set opticalData(pData: iCadOpticalData) {
        this.mOpticalData = pData;
        this.mOpticalPartType = pData.type
    }

    //________________________________________________
    private _initLaserBoxData(pData: Array<iPartVOLaserData>) {
        if ((undefined === pData) || (undefined === pData[0])) {
            return;
        }

        this.mLaserBoxData = new LaserData(pData[0]);
    }
    //_______________________________________________
    public toStringForSideBar() {

        if (eDataPermission.PRIVATE == this.mGeneralVOData.permission) {
            return this.mGeneralVOData.name;
        } else {
            return this.mGeneralVOData.id + "</br>" + " " + this.mGeneralVOData.name;
        }
        //         return this.id + "</br>" + " " + this.name;
        // }
    }
    //_______________________________________________
    public set initialHeight(pVal: number) {
        this.mGeneralVOData.initialHeight = pVal;
    }
    //_______________________________________________
    public get initialHeight() {
        return this.mGeneralVOData.initialHeight;
    }
    //_______________________________________________
    public get hasPost() {
        return this.mGeneralVOData.hasPost;
    }
    //_______________________________________________
    public get blackBox() {
        return this.mGeneralVOData.isBlackBox;
    }
    //_______________________________________________
    public get laserData() {
        return this.mLaserBoxData;
    }
    //_______________________________________________
    // public set ownerId(pVal: string) {
    //     this.mOwnerId = pVal;
    // }
    // //_______________________________________________
    // public get ownerId(): string {
    //     return this.mOwnerId;
    // }
    //_______________________________________________
    public get imageUrl(): string {
        if (this.mGeneralVOData.isBlackBox == true) {
            return (`${ServerContext.image_prefix}${this.mGeneralVOData.url}.png`);
        }
        switch (this.mGeneralVOData.permission) {
            case eDataPermission.PRIVATE:
                let aUrl = `${ServerContext.user_prefix_cad}${this.mGeneralVOData.owner}/images/${this.mGeneralVOData.id}.png`;
                return aUrl;
            case eDataPermission.PUBLIC:
                return (`${ServerContext.image_prefix}${this.mGeneralVOData.url}.png`);
        }
    }
    //_______________________________________________
    public get url(): string {
        let aPrefix = '';

        if (eDataPermission.PUBLIC == this.mGeneralVOData.permission) {
            aPrefix = ServerContext.part_prefix;
        }

        return aPrefix + this.mGeneralVOData.url;
    }
    //_______________________________________________
    public get shortUrl(): string {
        if (this.mGeneralVOData.url.includes('http')) {
            return this.mGeneralVOData.url;
        } else {
            let aPrefix = ServerContext.part_prefix;
            return aPrefix + this.mGeneralVOData.url;
        }
    }
    //_______________________________________________
}