import { eOrientation } from "../../../../_context/Enums";
import { iSize } from "../../../../_context/_interfaces/Interfaces";
import { FileUtils } from "../../../../_utils/FileUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { ApertureDataLoader } from "../../../../data/data_loader/ApertureDataLoader";
import { ViewUtils } from "../../../ViewUtils";
import { UploadApertureFileForm } from "./UploadApertureFileForm";

export interface iUploadApertureImageOptions {
    container: HTMLElement;
    title: string;
    defaultValue: number;
    defaultBGColor: string;
    upload_btn_qa_id: string;
    onChange: () => void
}

export interface iImageValidateRes {
    resolution: iSize,
    isGrayScale: boolean;
    bitDepth: number;
}

export interface iUploadApertureImageRes {
    resolution: iSize,
    file: File,
    name: string;
    url: string;
}

export class UploadApertureImage {

    private mOptions: iUploadApertureImageOptions;
    private mImagePreview!: HTMLImageElement;
    private mLoadedImageResolution: iSize | undefined;
    private mFile: File | undefined;
    private mImagePreviewPlaceHolder!: HTMLImageElement;
    private mURL: string | undefined;
    private mSecondImage: UploadApertureImage;
    private mUploadBtn: HTMLElement;
    private mRemoveImageButton: HTMLElement;
    private mImageName: HTMLElement;

    constructor(pOptions: iUploadApertureImageOptions) {
        this.mOptions = pOptions;
        this._init();
    }
    //_________________________________________________________________________________
    public set secondImage(pSecond: UploadApertureImage) {
        this.mSecondImage = pSecond;
    }
    //_________________________________________________________________________________
    private _init() {
        let aLabel = Op3dUtils.getElementIn(this.mOptions.container, "label", true);
        if (aLabel !== null) {
            aLabel.innerText = this.mOptions.title;
        }


        this.mImagePreviewPlaceHolder = Op3dUtils.getElementIn(this.mOptions.container, "empty-image-place-holder", true) as HTMLImageElement;
        this.mImagePreviewPlaceHolder.style.backgroundColor = this.mOptions.defaultBGColor;

        this.mImagePreview = Op3dUtils.getElementIn(this.mOptions.container, "image-preview", true) as HTMLImageElement;
        // this.mImageName = Op3dUtils.getElementIn(this.mOptions.container, "image-name", true)!;

        this.mUploadBtn = Op3dUtils.getElementIn(this.mOptions.container, "image-upload-btn", true)!;
        this.mUploadBtn.setAttribute("qa_id", this.mOptions.upload_btn_qa_id);
        this.mUploadBtn.addEventListener("click", () => this._onUploadImage());



        this.mRemoveImageButton = Op3dUtils.getElementIn(this.mOptions.container, "remove-image-btn", true);
        this.mRemoveImageButton.addEventListener("click", () => this._onRemoveImage());

        this.mImageName = Op3dUtils.getElementIn(this.mOptions.container, "image-name", true);
    }
    //_________________________________________________________________________________
    private _onRemoveImage() {
        this.reset();
        this._changeButtons("");
        this.mURL = "";

        this.mOptions.onChange();

    }
    //_________________________________________________________________________________
    public async set(pData: { url: string, name: string }) {
        this._setImagePreview(false);



        if (pData == null || pData.url == "") {
            this._changeButtons("")

            return;
        }
        let aImage = await ViewUtils.getImage(pData.url);
        let aResolution: iSize = { width: aImage.width, height: aImage.height };
        this._setImagePreview(true);

        this.mLoadedImageResolution = aResolution;
        this.mImagePreview.src = pData.url;
        let aOrientation = aResolution.width > aResolution.height ? eOrientation.LANDSCAPE : eOrientation.PORTRAIT;
        this.mImagePreview.setAttribute("orientation", aOrientation);
        /**
         * @TODO:
         */
        // this.mImageName.innerText = pData.name;

        this._changeButtons(pData.name)
        this.mURL = pData.url;
    }
    //_________________________________________________________________________________
    private _setImagePreview(pIsPreviewEnabled: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mImagePreviewPlaceHolder, !pIsPreviewEnabled);
        ViewUtils.setElementVisibilityByDNone(this.mImagePreview, pIsPreviewEnabled);
    }
    //_________________________________________________________________________________
    private _onUploadImage() {
        UploadApertureFileForm.instance.open({
            func: (pFile: File, pSize: iSize) => this._onFileChosen(pFile, pSize),
            title: `${this.mOptions.title} upload`,
            acceptedSizeMb: 10,
            size_to_compare: this.mSecondImage.loadedImageResolution
        });
    }
    //_________________________________________________________________________________
    public imagePreview() {
        return this.mImagePreview.src;
    }
    //_________________________________________________________________________________
    private _changeButtons(pFileName: string) {
        let aHasImage = pFileName !== undefined && pFileName !== null && pFileName !== ""
        ViewUtils.setElementVisibilityByDNone(this.mUploadBtn, aHasImage === false);
        ViewUtils.setElementVisibilityByDNone(this.mRemoveImageButton, aHasImage === true);
        this.mImageName.innerText = pFileName;

    }
    //_________________________________________________________________________________
    private async _onFileChosen(pFile: File, pResolution: iSize) {
        this._setImagePreview(false);
        let aRes = await FileUtils.loadFileDataurl(pFile);
        if (aRes.success == false) {
            return;
        }
        this.mURL = "";
        this.mLoadedImageResolution = pResolution;
        this._setImagePreview(true);
        this.mImagePreview.src = aRes.data;
        let aOrientation = pResolution.width > pResolution.height ? eOrientation.LANDSCAPE : eOrientation.PORTRAIT;

        this.mFile = pFile;
        this.mImagePreview.setAttribute("orientation", aOrientation);
        /**
         * @TODO:
         */
        // this.mImageName.innerText = pFile.name;

        this._changeButtons(pFile.name)
        await ApertureDataLoader.instance.createTextureByFile(pFile);
        this.mOptions.onChange();
    }
    //_________________________________________________________________________________
    public getData(): iUploadApertureImageRes {
        return {
            resolution: this.mLoadedImageResolution!,
            file: this.mFile!,
            name: this.mFile != null ? this.mFile.name : this.mImageName.innerText,
            url: this.mURL!
        }
    }
    //_________________________________________________________________________________
    public get loadedImageResolution() {
        return this.mLoadedImageResolution;
    }
    //_________________________________________________________________________________
    public reset() {
        this.mLoadedImageResolution = undefined;
        this.mFile = undefined;
        this._setImagePreview(false);
        this.mImagePreview.src = "";
        /**
       * @TODO:
       */
        // this.mImageName.innerText = "";
        this.mURL = undefined;
    }
    //_________________________________________________________________________________
}