import { eAnalysisType, iAnalysisDDItem } from "./AnalysisContext"




export class AnalysisConstants {

    public static isPowerReadingAnalysis() {

    }

    public static ANALYSES_OPTIONS: Array<iAnalysisDDItem> = [{
        name: "Spot (Incoherent Irradiance)",
        type: eAnalysisType.FAST,
        disabled: false,
        shortcut: "Spot II",
        unitLabel: "[W/cm^2]",
        isDiffration: false,
        label2: "Incoherent Irradiance",
        color: "label-01"
    },
    {
        name: "Spot (Incoherent Irradiance)",
        shortcut: "Spot II",
        type: eAnalysisType.ADVANCED,
        disabled: false,
        label2: "Incoherent Irradiance",
        color: "label-01",
        isDiffration: false,
        unitLabel: "[W/cm^2]"
    },
    {
        name: "Spot (Coherent Irradiance)",
        type: eAnalysisType.ADVANCED,
        disabled: false,
        shortcut: "Spot CI",
        label2: "Coherent Irradiance",
        color: "label-08",
        isDiffration: false,
        unitLabel: "[W/cm^2]",
    },
    {
        name: "Coherent Phase",
        type: eAnalysisType.ADVANCED,
        disabled: false,
        shortcut: "CP",
        useOneWavelength: true,
        tesselation: {
            sectors: 1200,
            slices: 1000
        },
        unitLabel: "",
        isDiffration: false,
        label2: "Coherent Phase",
        color: "label-09",
    },
    {
        name: "Spot (Incoherent Irradiance) Polarized",
        type: eAnalysisType.ADVANCED,
        isPowerReadingAnalysis: true,
        shortcut: "Spot II Pol",
        unitLabel: "[W/cm^2]",
        disabled: false,
        isPolarized: true,
        isDiffration: false,
        label2: "Incoherent Irradiance Polarized",
        color: "label-10"
    },
    {
        name: "Spot (Coherent Irradiance) Polarized",
        type: eAnalysisType.ADVANCED,
        shortcut: "Spot CI Pol",
        isPolarized: true,
        unitLabel: "[W/cm^2]",
        disabled: false,
        isDiffration: false,
        label2: "Coherent Irradiance Polarized",
        color: "label-11"
    },
    {
        name: "Coherent Phase Polarized",
        type: eAnalysisType.ADVANCED,
        shortcut: "CP Pol",
        disabled: false,
        unitLabel: "[W/cm^2]",
        useOneWavelength: true,
        tesselation: {
            sectors: 1200,
            slices: 1000
        },
        isDiffration: false,
        label2: "Coherent Phase Polarized",
        isPolarized: true,
        color: "label-12"
    },
    {
        name: "Spot (Coherent Irradiance) Huygens",
        oldNames: ["Spot (Coherent Irradiance)"],
        shortcut: "Spot CI H",
        disabled: false,
        isDiffration: true,
        label2: "Coherent Irradiance Huygens",
        color: "label-08",
        unitLabel: "[W/cm^2]",
        type: eAnalysisType.ADVANCED,
    },
    {
        name: "Coherent Phase Huygens",
        oldNames: ["Coherent Phase"],
        shortcut: "CP H",
        disabled: false,
        unitLabel: "",
        isDiffration: true,
        type: eAnalysisType.ADVANCED,
        label2: "Coherent Phase Huygens",
        color: "label-09",
    },
    {
        name: "Spot (Coherent Irradiance) Fresnel",
        shortcut: "Spot CI F",
        disabled: false,
        isFresnelAnalysis: true,
        isDiffration: true,
        label2: "Coherent Irradiance Fresnel",
        color: "label-10",
        unitLabel: "[W/cm^2]",
        type: eAnalysisType.ADVANCED,
    },
    {
        name: "Coherent Phase Fresnel",
        shortcut: "CP F",
        disabled: false,
        unitLabel: "",
        isDiffration: true,
        useOneWavelength: true,
        isFresnelAnalysis: true,
        type: eAnalysisType.ADVANCED,
        label2: "Coherent Phase Fresnel",
        color: "label-11",
    },
    {
        name: "Coherent Phase Fresnel Far",
        isFresnelAnalysis: true,
        shortcut: "CP FF",
        disabled: false,
        unitLabel: "",
        isDiffration: true,
        useOneWavelength: true,
        type: eAnalysisType.ADVANCED,
        label2: "Coherent Phase Fresnel Far",
        color: "label-12",
    },
    {
        name: "Spot (Coherent Irradiance) Fresnel Far",
        shortcut: "Spot CI FF",
        disabled: false,
        isDiffration: true,
        isFresnelAnalysis: true,
        label2: "Coherent Irradiance Fresnel Far",
        color: "label-13",
        unitLabel: "[W/cm^2]",
        type: eAnalysisType.ADVANCED,
    },

    {
        name: "Huygens PSF",
        shortcut: "Huygens PSF",
        disabled: false,
        isDiffration: false,
        isSystemAnalysis: true,
        label2: "Huygens PSF",
        color: "label-08",
        unitLabel: "[W/cm^2]",
        type: eAnalysisType.ADVANCED,
    },
    {
        name: "Fresnel PSF",
        shortcut: "Fresnel PSF",
        disabled: false,
        isDiffration: false,
        isSystemAnalysis: true,
        isFresnelAnalysis: true,
        label2: "Fresnel PSF",
        color: "label-10",
        unitLabel: "[W/cm^2]",
        type: eAnalysisType.ADVANCED,
    }
    ]
}