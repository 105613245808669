import { Vector3 } from "three";
import { eDataPermission, eSeqGeometryTypes } from "../../_context/Enums";
import { eBaseShape, eOpticShape } from "../../_context/OpticsContext";
import { tGratingSide } from "../../_context/Types";
import { iHash, iNumericKeyHash, t3DArray } from "../../_context/_interfaces/Interfaces";
import { iConicParams, iGeneralCircularConicParams, iGeneralRectangularConicParams } from "../../_utils/MatrixUtils";
import { iPartMountsParserData } from "./PartVO";

export interface iToleranceMeasure {
    value: number;
    toleranceDelta: {
        min: number;
        max: number;
    };
};

export interface iBSCube {
    cube_side: number;
}

export interface iDovePrism {
    a: number;
    b: number;
    c: number;
    d: number;
};

export interface iZernike {

}

export interface iEquilateralPrism {
    a: number;
    t: number;
};

export interface iRectWedgePrism {
    width: number;
    height: number;
    t: number;
    wedge_angle: number;
};

export interface iDShaped {
    diameter: number;
    thickness: number;
    thickness_small: number;
}

export interface iGeneralRectConicLens {
    front: iGeneralRectangularConicParams;
    back: iGeneralRectangularConicParams;

    thickness_center: number;

    deformation?: iHash<Array<iSurfaceDeformation>>;
}

export interface iGeneralConicLens {
    front: iGeneralCircularConicParams;
    back: iGeneralCircularConicParams;

    thickness_center: number;

    deformation?: iHash<Array<iSurfaceDeformation>>;
}

export interface iCircularWedgePrism {
    diameter: number;
    thickness_center: number;
    wedge_angle: number;
};

export interface iPentaPrism {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
    alpha: number;
};

export interface iPellinBrocaPrism {
    a: number;
    b: number;
    h: number;
    alpha: number;
    beta: number;
};

export interface iRhombicPrism {
    a: number;
    b: number;
    c: number;
    d: number;
};

export interface iRightAnglePrism {
    a: number;
    b: number;
    c: number;
};

export interface iRectBaseRoofPrismMirror {
    width: number;
    height: number;
    thickness: number;
};

export interface iCircleBaseRoofPrismMirror {
    diameter: number;
    thickness: number;
    a: number;
};

export interface iRectangularCylindrical {
    thickness_center: number;
    width: number;
    height: number;

    r1_x?: number;
    r1_y?: number;
    r2_x?: number;
    r2_y?: number;
}

export interface iCircularCylindrical {
    r1: number;
    r2: number;
    diameter: number;
    thickness_center: number;
}

export interface iCylindrical extends iRectangularCylindrical, iCircularCylindrical {

}

export interface iAxiconPrism {
    diameter: number;
    height: number;
    convexity: string;
    thickness_edge: number;
    // thickness_edge: number;
}

export interface iOddAsphere extends iConicParams {
    coefficients: iNumericKeyHash<number>;
    thickness_center: number;
}

export interface iMultiPlet {
    diameter: number;
    multiplet_r: Array<number>;
    multiplet_thickness_center: Array<number>;
}

export interface iParaxialLens {
    paraxialEFL: number;
    diameter: number;
}


export interface iConicLens {
    r1: number;
    r2: number;
    k: number;
    diameter: number;
    thickness_center: number;
};

export interface iCircularParabolic {
    radius_of_curvature: number;
    diameter: number;
    thickness_edge: number;
}

export interface iCircularOffAxisParabolic extends iCircularParabolic {
    off_axis_angle: number;
}



export interface iBallLens {
    diameter: number;
}

export interface iASphericLens {
    k: number;
    r1: number;
    r2: number;
    coeffs: iNumericKeyHash<number>
    diameter: number;
    thickness_center: number;
};


export interface iSphericalLens {
    r1: number;
    r2: number;

    diameter: number;
    thickness_center: number;

    deformation?: iHash<Array<iSurfaceDeformation>>;
};


export interface iThinCircularLens {
    diameter: number;
    thickness: number;
};



export interface iDispertionPrism {
    a: number;
    l: number;
    alpha: number;
};

export interface iGeneralSurfaceParams {
    surface_id?: string;
    coating_id?: string;
    name: string;
    isInactive?: boolean;
    isMirror?: boolean;
}

export interface iOpticsVO {
    name: string;
    number_id: string;
    permission: eDataPermission;
    parameters: iOpticsVOParameters;
    owner?: string;
    creator?: string;
}
export interface iOptomechanicVO {
    name: string;
    number_id: string;
    permission: eDataPermission;
    info: iOptomechanicInfoVO
    owner?: string;
    creator?: string;
}
export interface iOptomechanicInfoVO {
    id: string,
    name: string,
    url: string,
    section: string,
    category: string,
    subCategory: string,
    main_section: string,
    sideBarPart: boolean,
    parameters?: iPartMountsParserData
}

export enum eSurfaceDeformation {
    ZERNIKE_STANDARD_PHAZE = "ZERNIKE_STANDARD_PHAZE",
    ZERNIKE_STANDARD_SAG = "ZERNIKE_STANDARD_SAG",
    ZERNIKE_FRINGE_PHAZE = "ZERNIKE_FRINGE_PHAZE",
    ZERNIKE_FRINGE_SAG = "ZERNIKE_FRINGE_SAG",
    ZERNIKE_ANNULAR_PHAZE = "ZERNIKE_ANNULAR_PHAZE",
    JONES_MATRIX = "JONES_MATRIX",
    ASPHERE = "ASPHERE",
    EVEN_ASPHERE = "EVEN_ASPHERE",
    ODD_ASPHERE = "ODD_ASPHERE"
}

export interface iSurfaceDeformation<T = any> {
    type: eSurfaceDeformation;
    params: T;
}

export interface iZernikeDeformation {
    coeffs: iNumericKeyHash<number>;
    normalization_radius: number;
}

export interface iPolynomialDeformation {
    terms: Array<t3DArray<number>>;
}
export enum ePolarizerWaveplateType {
    QWP = 'QWP',
    HWP = 'HWP',
}
export interface iPolarizerData {
    polarizerDirectionAngleDeg?: number;
    extinctionRatio?: number;
    polarizationRatio?: number;
    ordinaryRefractiveIndex?: number;
    extraRefractiveIndex?: number;
    extraAxisDirection?: number;
    getDataFromMaterialSettings?: boolean;
    waveplateSubtype?: ePolarizerWaveplateType | string;
    designWavelength?: number;
}
export interface iPolarizaerSimulationData {
    direction_angle?: number;
    extinction_ratio?: number;

}
export interface iWaveplateSimulationData {
    ordinary_refractive_index?: number;
    extraordinary_refractive_index?: number;
    extraordinary_direction_angle?: number;
}

export interface iOpticsVOParameters {
    type?: string;
    subType?: string;
    materialID: string;
    baseShape: eBaseShape;
    shape: eOpticShape;
    coating: iHash<string>;
    wavelength?: number;
    polarizer_data?: iPolarizerData;
    physical_data?: iOpticsVOPhysicalData;
    info?: iOpticsVOInfo;
    geometry: iOpticsVOGeometry;
};

export interface iApertureDataOpticsVO {
    phase_mask?: {
        url: string,
        name: string
    };
    transmittance_mask?: {
        url: string,
        name: string
    };
}
export interface iOpticsVOPhysicalData extends iApertureDataOpticsVO {
    /**
     * Grating
     */
    blaze_wavelength?: number,
    order?: number,
    blaze_angle?: number,
    orientation_vector?: Vector3,
    grating_side?: tGratingSide,
    dispersion?: number,
    grooves?: number,

    /**
     * filter
     */
    cut_on?: number;
    cut_off?: number;
    cwl?: number;
    bw?: number;


    /**
     * bs cube
     */
    bs_transmission?: number;
    bs_reflection?: number
}

export interface iOpticsVOGeometry {
    r?: number;
    r1?: number;
    r2?: number;
    r3?: number;
    r4?: number;

    r1_x?: number;
    r1_y?: number;
    r2_x?: number;
    r2_y?: number;

    radius_of_curvature?: number;

    a?: number;
    b?: number;
    c?: number;
    d?: number;
    e?: number;
    f?: number;
    g?: number;
    h?: number;
    j?: number;
    l?: number;
    t?: number;
    x?: number;
    y?: number;


    a2?: number;
    a3?: number;
    a4?: number;
    a5?: number;
    a6?: number;
    a7?: number;
    a8?: number;
    a9?: number;
    a10?: number;
    a11?: number;
    a12?: number;
    a13?: number;
    a14?: number;
    a15?: number;
    a16?: number;



    length_of_face?: number;
    length?: number;
    length_of_legs?: number;
    length_of_hypotenuse?: number;
    dimensions?: number;
    dimension_1?: number;
    dimension_2?: number;

    /**
     * bs cube
     */
    cube_side?: number;
    l1?: number;
    l2?: number;

    /**
     * coefficient of conic formula
     */
    k?: number;

    /**
     * Axicon
     */
    convexity?: string;

    thickness_center?: number;
    thickness_center_1?: number;
    thickness_center_2?: number;
    thickness_center_3?: number;
    thickness_center_a?: number;
    thickness_center_b?: number;

    thickness_edge?: number;
    thickness?: number;
    edge_thickness_max?: number;
    thickness_h?: number;

    off_axis_angle?: number;
    alpha?: number;
    apex_angle?: number;
    beta?: number;
    phi?: number;
    angle_a?: number;
    angle_b?: number;
    angle_c?: number;
    angle_d?: number;
    theta?: number;
    theta1?: number;
    theta2?: number;
    wedge_angle?: number;
    deviation_angle?: number;
    efl?: number;
    pfl?: number;
    diameter?: number;
    width?: number;
    height?: number;

    innerRadius?: number;
    outerRadius?: number;

    coeffs?: iNumericKeyHash<number>;

    n_plate_r?: Array<number>;
    n_plate_thickness_center?: Array<number>;

    paraxialEFL?: number;
    y_offset?: number;

    deformation?: iHash<Array<iSurfaceDeformation>>;

    seq_geometry?: iSeqGeometry;
};

export interface iSeqGeometry {
    type: eSeqGeometryTypes;
    geometry: iGeneralConicLens;
}

export interface iOpticsVOInfo {
    brand: string;
    weblinks?: Array<string>;
    catalog_number?: string;
    vendor_catalog_number?: string;
    weblink_status?: string;
};