
import { EventManager } from "../../../../oc/events/EventManager";
import { eJobMessageType, eDataPermission, eCadType } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { Strings } from "../../../_context/Strings";
import { iCadOpticalData, iCadUploadData, iSize } from "../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iPartVOData } from "../../../data/VO/PartVO";
import { AxisObject3D } from "../../../parts/_parts_assets/Axis";
import { SceneContext } from "../../../scene/SceneContext";
import { ENVS, ServerContext } from "../../../server/ServerContext";
import { Menu } from "../../home/Menu";
import { Spinner } from "../../home/Spinner";
import { NotificationCenter } from "../../home/_notifications/NotificationCenter";
import { ePartType, iPartMongoDB } from "../../../parts/PartInterfaces";
import { ColorUtils } from "../../ColorUtils";
import { Op3dScene } from "../../../scene/Op3dScene";
import { Box3, Object3D, OrthographicCamera, Scene, Vector3, WebGLRenderer } from "three";
import { Part } from "../../../parts/Part";
import { PartsDataLoader } from "../../../data/data_loader/PartsDataLoader";


export enum eCadCallbackReason {
    SPINNER
}
export enum eCADCategory {
    MOUNTS = 'Mounts',
    OPTICAL_TABLE_AND_BREADBOARD = 'Optical table and breadboard',
    CAGE_SYSTEM = 'Cage system',
    LIGHT = 'Light',
    PRIVATE = ' Private'
}

export class PartSnapshotGenerator {
    private static SNAPSHOT_SIZE: iSize = { height: 600, width: 600 };
    private static DIAGONAL = 550;


    private mRenderer: WebGLRenderer;
    private mCamera: OrthographicCamera;
    private mScene: Scene;
    private mPartContainer: Object3D;
    private mSceneReady = false;

    constructor() {
        this._init();

    }
    //______________________________________________________________________________________
    private async _init() {
        this.mScene = new Scene();
        this.mPartContainer = new Object3D();
        let aLights = await SceneContext.OP3D_SCENE.lights
        this.mScene.add(aLights);
        this.mScene.add(this.mPartContainer)
        this.mRenderer = new WebGLRenderer({
            antialias: false,
            preserveDrawingBuffer: true,
            stencil: false,
            alpha: true
        });

        this.mCamera = SceneContext.CAMERA.clone();
        this.mRenderer.setClearColor(0xffffff, 1.0);
        // this.mRenderer.setSize(PartSnapshotGenerator.SNAPSHOT_SIZE.width,
        //     PartSnapshotGenerator.SNAPSHOT_SIZE.height);
        this.mSceneReady = true;
    }
    //______________________________________________________________________________________
    private async _prepareForm() {
        while (!this.mSceneReady) {
            await Op3dContext.sleep(50);
        }
    }
    //______________________________________________________________________________________

    private _clearScene() {
        while (this.mPartContainer.children.length > 0) {
            this.mPartContainer.children.pop();
        }
    }
    //______________________________________________________________________________________
    public async takeSnapshot(pPart: Part) {
        await this._prepareForm();
        /**
         * @TODO
         */


        //prepare canvas
        const aCanvas = document.createElement("canvas");
        let aCtx = aCanvas.getContext("2d");
        const aWidth = PartSnapshotGenerator.SNAPSHOT_SIZE.width;
        const aHeight = PartSnapshotGenerator.SNAPSHOT_SIZE.height;
        aCanvas.width = aWidth;
        aCanvas.height = aHeight;

        //prepare scene
        this._clearScene();
        let aClone = pPart.visibleObj.clone();
        aClone.position.set(0, 0, 0)

        let aAxes = new Array<Object3D>();
        aClone.traverse((obj) => {
            if (obj.name == AxisObject3D.AXIS_NAME) {
                aAxes.push(obj);
            }
        });

        for (let i = 0; i < aAxes.length; i++) {
            aAxes[i].parent.remove(aAxes[i]);
        }

        let aBox = new Box3().setFromObject(aClone);
        let aNewPos = aBox.getCenter(new Vector3()).multiplyScalar(-1);
        //aNewPos.y = -aBox.min.y;
        aClone.position.copy(aNewPos);

        this.mPartContainer.add(aClone);
        //default camera position
        this.mCamera.position.set(-1500, 675, -1500);
        this.mCamera.lookAt(new Vector3());
        this._placeCamera();

        let aFrustumScale = 3;
        this.mCamera.left = -aWidth / aFrustumScale
        this.mCamera.right = aWidth / aFrustumScale
        this.mCamera.top = aHeight / aFrustumScale
        this.mCamera.bottom = -aHeight / aFrustumScale
        this.mCamera.updateProjectionMatrix();
        this.mRenderer.setSize(aWidth, aHeight);


        this.mRenderer.autoClear = false;
        this.mRenderer.clear();
        this.mRenderer.clearDepth();
        this.mRenderer.render(this.mScene, this.mCamera);

        let aDomElement = this.mRenderer.domElement;
        aCtx.drawImage(aDomElement, 0, 0, aCanvas.width, aCanvas.height);
        // SimulationRunner.instance.setRaysVisibility(true);


        let image = aCtx.getImageData(0, 0, aCanvas.width, aCanvas.height);
        let imageData = image.data,
            length = imageData.length;
        for (let i = 3; i < length; i += 4) {
            if (imageData[i - 3] === 255 && imageData[i - 2] === 255 && imageData[i - 1] === 255)
                imageData[i] = 0;
        }

        aCtx.putImageData(image, 0, 0);
        // SnapshotTools.downloadImageFromCanvas({
        //     canvas: aCtx.canvas,
        //     mimeType: "image/png",
        //     name: "snapshot_part"
        // });


        return aCanvas.toDataURL('image/webp', 0.1);
    }
    //______________________________________________________________________________________
    private _placeCamera() {
        this.mPartContainer.updateMatrixWorld(true);
        const aBoundingBox = new Box3();
        aBoundingBox.setFromObject(this.mPartContainer);
        const size = aBoundingBox.getSize(new Vector3());

        let aBoxMeasureSide = OP3DMathUtils.calculateBoxDiagonal(size.x, size.z)
        let aBoxWindow = aBoxMeasureSide + 300;
        this.mCamera.zoom = ((PartSnapshotGenerator.DIAGONAL / 0.5) / aBoxWindow);
        this.mCamera.updateProjectionMatrix();
    }
    //______________________________________________________________________________________
}
export class ImportCad {


    private static ADMIN_PARTS_OWNER = ServerContext.info_account_id;
    private static INSTANCE: ImportCad;
    private static SPINNER_INTERVAL: any = false;
    private static PROGRESS_TO_SHOW: number = 0;
    private mSnapshotGenerator = new PartSnapshotGenerator();

    //__________________________________________________________________________________________
    private constructor() { }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ImportCad.INSTANCE) {
            ImportCad.INSTANCE = new ImportCad()
        }

        return ImportCad.INSTANCE;
    }
    //__________________________________________________________________________________________
    private _callbackExecutionProgress(peJobMessageType: eJobMessageType | "remove", pReason: eCadCallbackReason, _pData?: string | number) {
        const aShowSpinner = Spinner.instance.spinnerCallBack;
        if (ImportCad.SPINNER_INTERVAL) {
            if (ImportCad.PROGRESS_TO_SHOW < 20) {
                ImportCad.PROGRESS_TO_SHOW += 1;
                if (ImportCad.PROGRESS_TO_SHOW < 10) {
                    ImportCad.PROGRESS_TO_SHOW += 1;
                    (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_UPLOADING, null, ImportCad.PROGRESS_TO_SHOW);
                } else {
                    (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_PROCESSING, null, ImportCad.PROGRESS_TO_SHOW);
                }
            }
        } else {
            ImportCad.SPINNER_INTERVAL = setInterval(() => {
                if (ImportCad.PROGRESS_TO_SHOW < 20) {
                    ImportCad.PROGRESS_TO_SHOW += 1;
                    if (ImportCad.PROGRESS_TO_SHOW < 10) {
                        ImportCad.PROGRESS_TO_SHOW += 1;
                        (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_UPLOADING, null, ImportCad.PROGRESS_TO_SHOW);
                    } else {
                        (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_PROCESSING, null, ImportCad.PROGRESS_TO_SHOW);
                    }
                }

            }, 2500);
        }

        switch (peJobMessageType) {
            case eJobMessageType.UPLOAD:
                (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_UPLOADING, null, ImportCad.PROGRESS_TO_SHOW);
                break;
            case eJobMessageType.SUBMIT:
                ImportCad.PROGRESS_TO_SHOW = 10;
                (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_PROCESSING, null, ImportCad.PROGRESS_TO_SHOW);
                break;
            case eJobMessageType.PROGRESS:
                clearInterval(ImportCad.SPINNER_INTERVAL);
                (pReason == eCadCallbackReason.SPINNER) && aShowSpinner(Strings.SPINNER_TEXT_PROCESSING, null, ImportCad.PROGRESS_TO_SHOW);
                ImportCad.PROGRESS_TO_SHOW < 81 ? ImportCad.PROGRESS_TO_SHOW += 9 : ImportCad.PROGRESS_TO_SHOW = 90;
                break;
            case "remove":
                (pReason == eCadCallbackReason.SPINNER) && aShowSpinner('remove', null);
                break;
        }
    }
    //__________________________________________________________________________________________
    private async _createPartInMongo(pData: { locations: any, optix_folder_size: number },
        pOriginalName: string, pName: string, pId: string, pOpticalData?: iCadOpticalData) {

        let aPartVO: iPartVOData = {
            number_id: pId,
            id: pOriginalName + '_' + pId,
            name: pName,
            url: pData.locations[0],
            sideBarPart: true,
            originalName: pOriginalName,
            main_section: "Assembly",
            section: 'Private',
            category: 'Private',
            subCategory: 'Private',
            laserData: [],

        }
        // if (pOpticalData != null) {
        aPartVO.opticalData = pOpticalData;
        // }
        let aPartMongo: iPartMongoDB = {
            permission: eDataPermission.PRIVATE,
            number_id: pId,
            name: pOriginalName + '_' + pId,
            info: aPartVO,
            owner: Op3dContext.USER_VO.id
        }

        Op3dContext.USER_VO.cadData.current_usage = pData.optix_folder_size;

        await PartsDataLoader.instance.add(aPartMongo);
        return pId;
    }
    //__________________________________________________________________________________________
    private async _createSecificPartInMongo(pPartData: any, pCADCategory: eCADCategory) {

        let aNewPartId = Op3dUtils.idGenerator().toLowerCase();

        let aPartVO: iPartVOData = {
            number_id: aNewPartId,
            id: pPartData.object.catalog_number.replace('/', '_').replace('.', '_'),
            name: pPartData.object.catalog_number.replace('/', '_').replace('.', '_'),
            url: pPartData.object.step_file.replace('/', '_').replace('.', '_'),
            sideBarPart: true,
            originalName: pPartData.object.catalog_number.replace('/', '_').replace('.', '_'),
            main_section: "Parts",
            section: pCADCategory,
            category: pPartData.object.type,
            subCategory: pPartData.object.sub_type,
            laserData: [],
            parameters: pPartData.object

        }

        let aPartMongo: iPartMongoDB = {
            permission: eDataPermission.PUBLIC,
            number_id: aNewPartId,
            name: pPartData.object.catalog_number.replace('/', '_').replace('.', '_'),
            info: aPartVO,
            owner: ImportCad.ADMIN_PARTS_OWNER
        }

        await PartsDataLoader.instance.add(aPartMongo);

        return aNewPartId
    }

    //__________________________________________________________________________________________
    public async convertStepToOptix(pFile: File, pCadData: iCadUploadData) {
        if (null == pFile) {
            return;
        }
        let aCadColor;
        if (pCadData.useCustomColor === true && pCadData.materialColor !== undefined && pCadData.cad_type === eCadType.OPTO_MECHANICS) {
            let aHexColor = ColorUtils.fromHTMLColorToNumberColor(pCadData.materialColor)
            aCadColor = ColorUtils.hexToRGB(aHexColor);
        }
        const aPartType = pCadData.cad_type;
        let aOpticalData: iCadOpticalData = {
            type: aPartType,
            materialID: pCadData.materialID,
            wavelength: 550,
            faces: [],
            use3DOptixColor: pCadData.use3DOptixColor
        };

        let aDotIndex = pFile.name.lastIndexOf('.');
        let aName = pFile.name.substring(0, aDotIndex);
        let aNameToShow = pCadData.name
        let aNewPartId = Op3dUtils.idGenerator().toLowerCase();
        const aFileName = `${aName}_${aNewPartId}`;
        let aType = pFile.name.substring(aDotIndex + 1);

        const newFile = new File([pFile], `${aFileName}.${aType}`, { type: pFile.type });

        let aOptixFile = await ServerContext.SERVER.convertStepToOptix({
            stepFile: newFile,
            optix_file_name: aFileName,
            name: newFile.name,
            partType: aPartType,
            adminUpload: false,
            quality: ((undefined !== pCadData.triangulation_resolution) ?
                pCadData.triangulation_resolution : { angle: '0.5', deflection: "0.01" })
        }, this._callbackExecutionProgress);


        if (aOptixFile.data !== null) {
            ImportCad.PROGRESS_TO_SHOW = 92;
            Spinner.instance.spinnerCallBack(Strings.SPINNER_TEXT_DRAWING, null,
                ImportCad.PROGRESS_TO_SHOW);

            await this._createPartInMongo(aOptixFile.data as any, aName, aNameToShow, aNewPartId, aOpticalData);
            Op3dContext.SCENE_HISTORY.addToHistory();

            //load from mongoDB
            let aPartMongoDB =
                await PartsDataLoader.instance.getSingleFullData({
                    number_id: aNewPartId
                }, false);

            let aNewPart = await PartsDataLoader.instance.getSingleFullData({
                number_id: aNewPartId
            });

            Op3dContext.DATA_MANAGER.addToPartsData(aPartMongoDB);

            if (undefined === aNewPart) {
                let aQuery = {
                    id: aPartMongoDB.info.id,
                    number_id: aPartMongoDB.number_id,
                }
                await ServerContext.SERVER.deleteCADPart(aQuery);
                NotificationCenter.instance.pushNotification({
                    message: MessagesHandler.ERROR_LOADING_PART,
                    params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
                });
                ImportCad.clearCadSpinnerData();
                return
            }

            let aPart = new Part({
                id: aPartMongoDB.name,
                number_id: aNewPartId,
                options: { type: ePartType.CAD_PART }
            }).add(aNewPart);

            if (pCadData.materialColor !== undefined && pCadData.cad_type === eCadType.OPTO_MECHANICS) {
                let aFacesData = aPart.getFaces()[0];
                let aTemplateOfChanges = [{
                    type: 0,
                    data: {
                        path: [0, 0, 0],
                        color: [aCadColor.r / 255, aCadColor.g / 255, aCadColor.b / 255],
                        start: 0,
                        end: aFacesData.indexes[aFacesData.indexes.length - 1].indexes.end
                    }
                }]

                let aChanges = JSON.stringify(aTemplateOfChanges);

                let aRes = await ServerContext.SERVER.addChangesToPart({
                    changes: aChanges,
                    number_id: aNewPartId
                });

                if (aRes.success === true) {
                    for (let q = 0; q < aPart.subParts[0].facesMesh.geometry.attributes['color'].count; q++) {
                        aPart.subParts[0].facesMesh.geometry.attributes['color'].setXYZ(q, aCadColor.r / 255, aCadColor.g / 255, aCadColor.b / 255);
                        aPart.subParts[0].facesMesh.geometry.attributes['colorBase'].setXYZ(q, aCadColor.r / 255, aCadColor.g / 255, aCadColor.b / 255);
                    }
                    aPart.subParts[0].facesMesh.geometry.attributes['color'].needsUpdate = true;
                } else {
                    Op3dContext.USER_VO.isEmployeeUser && console.log('Can not save the color');
                }
            }

            let aPic = await this.mSnapshotGenerator.takeSnapshot(aPart);

            let aBlob = await fetch(aPic)
                .then(res => res.blob())

            await ServerContext.SERVER.uploadImageOfPart(aPartMongoDB.info.id, aBlob, "png");
            aPart.setPartLabel(aPartMongoDB.info.name);

            if (Op3dScene.SHOW_ALL_LABELS === true) {
                aPart.labelVisiblity = true;
            }

            Op3dContext.SCENE_HISTORY.saveScene();
            EventManager.dispatchEvent(EventsContext.UPDATE_SIDE_BAR_PRIVATE, this);

            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.PART_SUCCESS_UPLOAD,
                params: NotificationCenter.NOTIFICATIONS_TYPES.SUCCESS_MESSAGE,
            });

            Menu.instance.setCADProgressBar()
            Op3dContext.PARTS_MANAGER.updatePartsList();

            this.showCADNotification()
        }
        ImportCad.clearCadSpinnerData();
    }
    //__________________________________________________________________________________________
    public async convertStepToOptixAdmin(pFile: File, pPartData: any, eCADCategory: eCADCategory) {
        if (null == pFile) {
            return;
        }

        let aName = pPartData.object.catalog_number

        const newFile = new File([pFile], pPartData.object.step_file.replace('.', '_'), { type: pFile.type });


        try {
            let aOptixFile = await ServerContext.SERVER.convertStepToOptix({
                stepFile: newFile,
                optix_file_name: aName,
                name: newFile.name.replace('.', '_'),
                adminUpload: true
            }, this._callbackExecutionProgress);

            if (aOptixFile.data !== null) {
                ImportCad.PROGRESS_TO_SHOW = 92;
                Spinner.instance.spinnerCallBack(Strings.SPINNER_TEXT_DRAWING, null, ImportCad.PROGRESS_TO_SHOW);

                let aNumberID = await this._createSecificPartInMongo(pPartData, eCADCategory);


                let aPartMongoDB = await PartsDataLoader.instance.getSingleFullData({
                    number_id: aNumberID
                }, false);

                let aNewPart = await PartsDataLoader.instance.getSingleFullData({
                    number_id: aNumberID
                });



                if (undefined === aNewPart) {
                    let aQuery = {
                        id: aPartMongoDB.info.id,
                        number_id: aPartMongoDB.number_id,
                    }
                    await ServerContext.SERVER.deleteAdminPart(aQuery);
                    ImportCad.clearCadSpinnerData();
                    throw new Error('Error creating part')

                }

                Op3dContext.DATA_MANAGER.addToPartsData(aPartMongoDB);

                let aPart = new Part({
                    id: aPartMongoDB.name,
                    number_id: aNumberID,
                    options: { type: ePartType.CAD_PART }
                }).add(aNewPart);

                let aPic = await this.mSnapshotGenerator.takeSnapshot(aPart);

                let aBlob = await fetch(aPic)
                    .then(res => res.blob())

                await ServerContext.SERVER.uploadPartImage(aPartMongoDB.info.id, aBlob, 'png');
                Op3dContext.PARTS_MANAGER.deletePart(aPart)

            }
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log('error');

        } finally {
            ImportCad.clearCadSpinnerData();
        }





    }
    //__________________________________________________________________________________________
    private showCADNotification() {
        try {
            if ((window as any).userGuidingUserStorage != null && (window as any).userGuidingUserStorage.attributes.isBasic == true) {
                (window as any).userGuiding.previewGuide(85715)
            }
        } catch (e) {
            console.error('UserGuiding show notification failed:', e);
        }

    }
    //__________________________________________________________________________________________
    public async uploadOptixPart(pFile: File) {
        if (null == pFile) {
            return;
        }

        Spinner.instance.show();

        let aDotIndex = pFile.name.lastIndexOf('.');
        let aName = pFile.name.substring(0, aDotIndex); // prompt('Enter name', '');
        let aNewPartId = Op3dUtils.idGenerator().toLowerCase();
        const aFileName = `${aName}_${aNewPartId}`;
        let aType = pFile.name.substring(aDotIndex + 1);

        const newFile = new File([pFile], `${aFileName}.${aType}`, { type: pFile.type });
        if (newFile !== null) {
            let res = await ServerContext.SERVER.uploadOptixPart(aFileName, newFile);
            await this._createPartInMongo(res.data as any, aName, aName, aNewPartId);

            //load from mongoDB
            let aPartMongoDB = await PartsDataLoader.instance.getSingleFullData({
                number_id: aNewPartId
            }, false);
            let aNewPart = await PartsDataLoader.instance.getSingleFullData({
                number_id: aNewPartId
            });


            Op3dContext.DATA_MANAGER.addToPartsData(aPartMongoDB);

            let aPart = new Part({
                id: aPartMongoDB.name,
                number_id: aNewPartId,
                options: { type: ePartType.CAD_PART }
            }).add(aNewPart);

            let aPic = await this.mSnapshotGenerator.takeSnapshot(aPart);


            let aBlob = await fetch(aPic)
                .then(res => res.blob())

            await ServerContext.SERVER.uploadImageOfPart(aPartMongoDB.info.id, aBlob, "png");

            Op3dContext.SCENE_HISTORY.saveScene();
            EventManager.dispatchEvent(EventsContext.UPDATE_SIDE_BAR_PRIVATE, this);

            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.PART_SUCCESS_UPLOAD,
                params: NotificationCenter.NOTIFICATIONS_TYPES.SUCCESS_MESSAGE,
            });
            Op3dContext.PARTS_MANAGER.updatePartsList();
        }

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    public static clearCadSpinnerData() {
        Spinner.instance.spinnerCallBack('remove', null);
        clearInterval(ImportCad.SPINNER_INTERVAL);
        ImportCad.SPINNER_INTERVAL = false;
        ImportCad.PROGRESS_TO_SHOW = 0;
    }
}