﻿import { Color, Vector3 } from "three";
import { iRGBA } from "../_context/_interfaces/Interfaces";
import { iRGB, iRgba, iColor } from "./_globals/uiInterfaces";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";

export class ColorUtils {

    public static YELLOW: number = 0xffff00;
    public static RED: number = 0xff0000;
    public static BLUE: number = 0x0000FF;
    public static GREEN: number = 0x00ff00;
    public static BLACK: number = 0x000000;
    public static WHITE: number = 0xffffff;

    private static MAX_NUM: number = 255;
    private static MIN_SPECTRUM: number = 380;
    private static MAX_SPECTRUM: number = 750;

    //__________________________________________________________________________________________
    public static convertLetterToHex(letter: string) {
        // Convert letter to ASCII code
        var asciiCode = letter.charCodeAt(0);

        // Calculate the RGB values based on the ASCII code
        var red = (asciiCode * 17) % 256;
        var green = (asciiCode * 7) % 256;
        var blue = (asciiCode * 23) % 256;

        // Convert RGB values to hexadecimal
        var hexRed = red.toString(16).padStart(2, '0');
        var hexGreen = green.toString(16).padStart(2, '0');
        var hexBlue = blue.toString(16).padStart(2, '0');

        // Return the RGB color as a hexadecimal string
        return "#" + hexRed + hexGreen + hexBlue;
    }
    //__________________________________________________________________________________________
    public static fromHTMLColorToNumberColor(pColor: string): number {
        pColor = pColor.replace("#", "0x");
        return (parseInt(pColor))
    }
    //__________________________________________________________________________________________
    public static threeColorToHex(pColor: Color | iRGB): number {
        return parseInt("0x" + ((1 << 24) + (pColor.r << 16) + (pColor.g << 8) + pColor.b).toString(16).slice(1));
    }
    //__________________________________________________________________________________________
    private static _componentToHex(c: number) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
    //__________________________________________________________________________________________
    public static rgbToHex(pR: number, pG: number, pB: number) {

        return "#" + this._componentToHex(pR) + this._componentToHex(pG) + this._componentToHex(pB);

    }
    //__________________________________________________________________________________________
    public static rgbToNumber(pR: number, pG: number, pB: number): number {
        //return parseInt("0x" + ((1 << 24) + (pR << 16) + (pG << 8) + pB).toString(16).slice(1));
        return Math.round(((pR << 16) + (pG << 8) + pB));
    }
    //__________________________________________________________________________________________
    public static RGBAtoString(pRgba: iRgba) {
        return 'rgba(' + pRgba.r + ',' + pRgba.g + ',' + pRgba.b + ',' + pRgba.a + ')';
    }
    //__________________________________________________________________________________________
    public static RGBAtoColorNumber(pRgba: iRgba) {
        let aNumBase10Color = ((pRgba.r << 16) + (pRgba.g << 8) + (pRgba.b));

        return aNumBase10Color;
    }
    //__________________________________________________________________________________________
    public static getOppositeColor(hexColor: string) {
        // Remove the leading '#' if present
        if (hexColor.startsWith('#')) {
            hexColor = hexColor.substring(1);
        }

        // Convert the hexadecimal color to RGB
        var red = parseInt(hexColor.substr(0, 2), 16);
        var green = parseInt(hexColor.substr(2, 2), 16);
        var blue = parseInt(hexColor.substr(4, 2), 16);

        // Calculate the relative luminance using the formula
        var relativeLuminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;

        // Return black if the relative luminance is above 0.5, otherwise return white
        return (relativeLuminance > 0.5) ? '#000000' : '#FFFFFF';
    }
    //__________________________________________________________________________________________
    public static getOppositeHexColor(pColor: number) {
        return (0xffffff - pColor);
    }
    //__________________________________________________________________________________________
    public static stringColorHexToNumber(pColorAsString: string): number {
        let aColor = pColorAsString.replace("#", '');
        return parseInt(aColor, 16);
    }
    //__________________________________________________________________________________________
    public static decimalColorToRGBA(pColor: number, pAlpha: number) {
        let aColorHex = ('000000' + pColor.toString(16).substring(0, 6)).slice(-6);
        let aRGBA: iRGBA = {
            r: parseInt(aColorHex.substring(0, 2)),
            g: parseInt(aColorHex.substring(2, 4)),
            b: parseInt(aColorHex.substring(4, 6)),
            a: pAlpha
        };

        return aRGBA;
    }
    //__________________________________________________________________________________________
    public static hexToRGB(pColor: number, pIsNormalized: boolean = false): iRGB {
        let aVar = pColor.toString(16);
        let aLength = aVar.length;
        if (aLength < 6) {
            for (let i = 0; i < (6 - aLength); i++) {
                aVar = '0' + aVar;
            }
        } else if (aLength > 6) {
            aVar = aVar.substring(0, 6);
        }

        let aR = parseInt(aVar.substring(0, 2), 16);
        let aG = parseInt(aVar.substring(2, 4), 16);
        let aB = parseInt(aVar.substring(4, 6), 16);

        if (true == pIsNormalized) {
            aR /= 255;
            aG /= 255;
            aB /= 255;
        }

        return { r: aR, g: aG, b: aB };
    }
    //__________________________________________________________________________________________
    public static hexToRGBA(pColor: number, pIsNormalized: boolean = false): iRgba {
        let aVar = pColor.toString(16);
        let aLength = aVar.length;
        if (aLength < 6) {
            for (let i = 0; i < (6 - aLength); i++) {
                aVar = '0' + aVar;
            }
        }

        if (aLength < 8) {
            aVar += 'FF';
        } else if (aLength > 8) {
            aVar = aVar.substring(0, 8);
        }

        let aR = parseInt(aVar.substring(0, 2), 16);
        let aG = parseInt(aVar.substring(2, 2), 16);
        let aB = parseInt(aVar.substring(4, 2), 16);
        let aAlpha = parseInt(aVar.substring(6, 2), 16);

        if (true == pIsNormalized) {
            aR /= ColorUtils.MAX_NUM;
            aG /= ColorUtils.MAX_NUM;
            aB /= ColorUtils.MAX_NUM;
        }

        return { r: aR, g: aG, b: aB, a: aAlpha };
    }
    //__________________________________________________________________________________________
    public static hexToNormalizedRGB(pColor: number) {
        let aRGB = ColorUtils.hexToRGB(pColor);

        aRGB.r /= ColorUtils.MAX_NUM;
        aRGB.g /= ColorUtils.MAX_NUM;
        aRGB.b /= ColorUtils.MAX_NUM;

        return aRGB;
    }
    //__________________________________________________________________________________________
    public static iColorToRgba(pColor: iColor) {
        if (pColor == null || pColor.color == null || pColor.alpha == null) {
            return null;
        }

        let aRgbaColor: iRgba = { r: pColor.color.r, g: pColor.color.g, b: pColor.color.b, a: pColor.alpha };
        return aRgbaColor;
    }


    //__________________________________________________________________________________________
    public static numToHEXColor(pNum: number) {
        let aStr = pNum.toString(16);
        let aZerosToAdd = (6 - aStr.length);
        for (let i = 0; i < aZerosToAdd; i++) {
            aStr = '0' + aStr;
        }

        return ('#' + aStr);
    }
    //__________________________________________________________________________________________
    public static directionToRGB(pDirection: Vector3, pIsNormalized: boolean = false) {
        let aVec = pDirection.clone().multiplyScalar(255);
        aVec.x *= Math.sign(aVec.x);
        aVec.y *= Math.sign(aVec.y);
        aVec.z *= Math.sign(aVec.z);

        if (true == pIsNormalized) {
            aVec.multiplyScalar(1 / 255);
        }

        let aRGB: iRGB = {
            r: aVec.x,
            g: aVec.y,
            b: aVec.z
        };

        return aRGB;
    }
    //__________________________________________________________________________________________
    public static intensityToHEX(pAlpha: number) {
        let aRGB = this.intensityToRGB(pAlpha);
        return this.rgbToNumber(aRGB.r, aRGB.g, aRGB.b);
    }
    //__________________________________________________________________________________________

    //__________________________________________________________________________________________
    public static intensityToRGB(pAlpha: number, pIsNormalized: boolean = false) {
        pAlpha = OP3DMathUtils.clampValue(pAlpha, 0, 1);

        let aMinSpectralRange = 440;
        let aMaxSpectralRange = 645;

        let aWavelength = (aMinSpectralRange +
            ((aMaxSpectralRange - aMinSpectralRange) * pAlpha));
        let aRGB = this.wavelengthToRGB(aWavelength);;
        if (true == pIsNormalized) {
            aRGB.r /= 255;
            aRGB.g /= 255;
            aRGB.b /= 255;
        }

        return this.wavelengthToRGB(aWavelength);
    }
    //__________________________________________________________________________________________
    /**
     * @description convert a wavelength to rgb color
     * @param pLambda the lambda to convert to rgb
     * @param pGamma 
     * @link https://gist.github.com/friendly/67a7df339aa999e2bcfcfec88311abfc
     * @returns rgb color of wavelength
     */
    //__________________________________________________________________________________________
    public static alphaToRGB(pAlpha: number) {
        let aMinSpectralRange = 380;
        let aMaxSpectralRange = 750;

        let aSpectralRange = (aMaxSpectralRange - aMinSpectralRange);
        let aWavelength = (aMinSpectralRange + (aSpectralRange * pAlpha));

        return this.wavelengthToRGB(aWavelength);
    }
    //__________________________________________________________________________________________
    public static wavelengthToRGB(pLambda: number, pGamma: number = 1,
        pIsNormalized: boolean = false) {

        pLambda = OP3DMathUtils.clampValue(pLambda, ColorUtils.MIN_SPECTRUM, ColorUtils.MAX_SPECTRUM);
        // if ((pLambda < ColorUtils.MIN_SPECTRUM) || (pLambda > ColorUtils.MAX_SPECTRUM)) {
        //     return ({ r: 0, g: 0, b: 0 } as iRGB);
        // }
        // let aMin = 380;
        // let aMax = 750;
        let aR, aG, aB, aC, aD;
        if (pLambda <= 440) {
            aD = (440 - ColorUtils.MIN_SPECTRUM);
            aC = 0.3 + 0.7 * ((pLambda - ColorUtils.MIN_SPECTRUM) / aD);
            aR = Math.pow((((440 - pLambda) / aD) * aC), pGamma);
            aG = 0;
            aB = Math.pow(aC, pGamma);
        } else if ((pLambda > 440) && (pLambda <= 490)) {
            aD = (490 - 440);
            aR = 0;
            aG = Math.pow((((pLambda - 440) / aD)), pGamma);
            aB = 1;
        } else if ((pLambda > 490) && (pLambda <= 510)) {
            aD = (510 - 490);
            aR = 0;
            aG = 1
            aB = Math.pow(((510 - pLambda) / aD), pGamma);
        } else if ((pLambda > 510) && (pLambda <= 580)) {
            aD = (580 - 510);
            aR = Math.pow(((pLambda - 510) / aD), pGamma);
            aG = 1
            aB = 0;
        } else if ((pLambda > 580) && (pLambda <= 645)) {
            aD = (645 - 580);
            aR = 1
            aG = Math.pow(((645 - pLambda) / aD), pGamma);
            aB = 0;
        } else if ((pLambda > 645) && (pLambda <= 750)) {
            aD = (750 - 645);
            aC = (0.3 + (0.7 * ((ColorUtils.MAX_SPECTRUM - pLambda) / aD)));
            aR = Math.pow(aC, pGamma);
            aG = 0;
            aB = 0;
        }

        let aFactor = (true == pIsNormalized) ? 1 : 255;

        let aRGB: iRGB = {
            r: (aFactor * aR),
            g: (aFactor * aG),
            b: (aFactor * aB)
        };
        return aRGB;
    }
    //__________________________________________________________________________________________
}
