import { Op3dContext } from "../../_context/Op3dContext";
import { Group } from "../../ui/forms/Group";
import { Part } from "../Part";
import { OptixPartDisplayer } from "../_parts_assets/OptixPartDisplayer";
import { Behavior } from "./Behavior";
import { iSelectBehavior } from "./BehaviorContext";

export class SelectBehavior extends Behavior implements iSelectBehavior {

    //__________________________________________________________________________________________
    public onDeselect(pPart: Part) {
        pPart.unHighlightObject();
    }
    //__________________________________________________________________________________________
    public onSelect(pPart: Part): void {
        $(':focus').blur();

        pPart = Group.instance.findMainGroupPart(pPart)
        Op3dContext.PARTS_MANAGER.setSelectedPart(pPart);
        pPart.highlightObject();
    }
    //__________________________________________________________________________________________
    public onDblSelect(pPart: Part): void {
        $(':focus').blur();

        let aPart = Group.instance.findMainGroupPart(pPart)
        aPart.unHighlightObject()
        Op3dContext.PARTS_MANAGER.setSelectedPart(pPart);
        OptixPartDisplayer.highlightObject(pPart)
    }
    //__________________________________________________________________________________________
}
