import { EventManager } from "../../../oc/events/EventManager";
import { eStateToAnalysis } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { LaserBehavior } from "../../parts/behaviors/LaserBehavior";
import { ENVS, ServerContext } from "../../server/ServerContext";
import { SimulationContext, eSmRaysKind } from "../../simulation/SimulationContext";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { ViewUtils } from "../ViewUtils";
import { Popup } from "../forms/Popup";
import { eTreeIcons } from "../home/SideBarListContext";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { piDetectorSizeSection } from "../part_info/piDetectorSizeSection";
import { AnalysesSynchronizer } from "./AnalysesSynchronizer";
import { AnalysisCache } from "./AnalysisCache";
import { AnalysisCanvasUtils } from "./AnalysisCanvasUtils";
import { AnalysisConstants } from "./AnalysisConstants";
import { iAnalysisItem, tAnalysisType, eAnalysisType, eSimulationKind, eAnalysisDisplay, AnalysisContext, iAnalysisDDItem, iAnalysisDropDownData, iAnalysisResultItem, iAnalysisOptionsData } from "./AnalysisContext";
import { AnalysisPortal } from "./AnalysisPortal";
import { anSmallResultFactory } from "./ResultFactory/anSmallResultFactory";
import { QuickViewHandler } from "./QuickViewHandler";
import { anSurfaceComponent } from "./components/anSurfaceComponent";

export class AnalysisThumbnail {

    private mParams: iAnalysisDropDownData;
    private mResult: iAnalysisResultItem;
    private mCreationOptions: iAnalysisOptionsData;
    private mLockBtn: Element;
    private mHasChangedParameters = true;
    public mPrevXPxls: number;
    public mPrevYPxls: number;

    constructor(pOptions: iAnalysisOptionsData) {
        this.mCreationOptions = pOptions;
        this._init();
    }
    //__________________________________________________________________________________________
    public distract() {
        EventManager.removeEventListener(EventsContext.PERMISSION_UPDATE, this);
        this.mParams = null;
        this.mResult = null;
        this.mCreationOptions = null;
        this.mLockBtn = null;
        this.mHasChangedParameters = null;
    }
    //______________________________________________________________________________________________
    public updateSpecificLaser(pLaserID: string, pKind: eSmRaysKind) {
        this.mParams.raysCount[pLaserID].disabled = this.isDisabledRays(pKind);
    }
    //______________________________________________________________________________________________
    public set enable(pVal: boolean) {
        ViewUtils.setElementDisabled(this.mCreationOptions.line, (false == pVal));
    }
    //__________________________________________________________________________________________
    public closeOptionsDD() {
        ViewUtils.setClassForElement(this.mParams.select, Strings.SHOW, false);
    }
    //__________________________________________________________________________________________
    private _init() {
        let aCheckbox = Op3dUtils.getElementIn(this.mCreationOptions.line,
            "single-active-analysis", true) as HTMLInputElement;

        let aLabel = Op3dUtils.getElementIn(this.mCreationOptions.line,
            "single-active-analysis-label", true) as HTMLLabelElement;

        aLabel.htmlFor = aCheckbox.id;
        aCheckbox.addEventListener("change", (e) => this._onChangeCheckbox(e));

        aCheckbox.checked = this.mCreationOptions.analysisData.isActive;

        let aDD = Op3dUtils.getElementIn(this.mCreationOptions.line,
            "analysis-dd") as HTMLSelectElement;

        let aChoiceElement = Op3dUtils.getElementIn(this.mCreationOptions.line, "dd-selected-choice");
        aChoiceElement!.addEventListener("mouseup", (e) => this._onToggleTypeDD(e, aChoiceElement!));
        this._initDD(aDD, aChoiceElement!);

        let aRemoveBtn = Op3dUtils.getElementIn(this.mCreationOptions.line, "delete-single-item", true);
        aRemoveBtn!.addEventListener("click", () => this.remove());

        let aDisplayDD = Op3dUtils.getElementIn(this.mCreationOptions.line,
            "display-dd", true) as HTMLSelectElement;
        this._initDisplayDD(aDisplayDD);

        ViewUtils.selectElementByValue(aDisplayDD,
            this.mCreationOptions.analysisData.display, false);

        aDisplayDD.addEventListener("change", () => this._onDisplayChanged());

        let aResolutionX = Op3dUtils.getElementIn(this.mCreationOptions.line,
            "pixels-x", true) as HTMLInputElement;

        aResolutionX.value = this.mCreationOptions.analysisData.resolution.x + "";
        aResolutionX.addEventListener("change", () => this._onSizeChange(aResolutionX));

        let aResolutionY = Op3dUtils.getElementIn(this.mCreationOptions.line,
            "pixels-y", true) as HTMLInputElement;
        aResolutionY.value = this.mCreationOptions.analysisData.resolution.y + "";
        aResolutionY.addEventListener("change",
            () => this._onSizeChange(aResolutionY));

        this.mParams = {
            analysis_id: this.mCreationOptions.analysisData.id,
            isActiveCheckbox: aCheckbox,
            label: null,
            name: this.mCreationOptions.analysisData.name,
            type: this.mCreationOptions.analysisData.type,
            resolutionX: aResolutionX,
            resolutionY: aResolutionY,
            select: aDD,
            display: aDisplayDD,
            raysCount: this._initRaysComponent(),
            simulation_kind: this.mCreationOptions.analysisData.simulation_kind
        }

        this._setInitialRaysValues();
        this._chooseOption();
        this._onAnalysisTypeChanged(false);
        this._notifyIfRaysAreLessThanXY();
        this.mPrevXPxls = parseInt(this.mParams.resolutionX.value)
        this.mPrevYPxls = parseInt(this.mParams.resolutionY.value)
        parseInt(this.mParams.resolutionY.value)
        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onUpdatePermissions(), this);

        /**
         * This function will show/hide the drop-down item (Polarization for example) depending 
         * on the current status of the feature in AWS config list.
         */

        const aPolarizationEnabled = Op3dContext.APP_FEATURES_CONFIG.polarization.enabled;
        this.showDDItem('Spot (Coherent Irradiance) Polarized', aPolarizationEnabled);
        this.showDDItem('Coherent Phase Polarized', aPolarizationEnabled);
        this.showDDItem('Spot (Incoherent Irradiance) Polarized', aPolarizationEnabled);
    }
    //__________________________________________________________________________________________
    /**
     * @param pItemName string
     * @param pToShow boolean
     * 
     * This method is used to show or hide the item in the drop down list.
     */
    //______________________________________________________________________________________________
    public showDDItem(pItemName: string, pToShow: boolean) {
        let aDD = Op3dUtils.getElementIn(this.mCreationOptions.line, "analysis-dd");
        let aHasAdvanceAnalysis = Op3dContext.USER_PERMISSION.hasAdvancedAnalysis;
        let aAdvancedContainer = Op3dUtils.getElementIn(aDD, "advanced-items-container");
        ViewUtils.setElementDisabled(aAdvancedContainer, (false === aHasAdvanceAnalysis));
        for (let i = 0; i < aAdvancedContainer.children.length; i++) {
            if ('single-advanced-dd-item' == aAdvancedContainer.children[i].id) {
                let aChild = aAdvancedContainer.children[i] as HTMLElement;
                ViewUtils.setElementDisabled(aChild, (false === aHasAdvanceAnalysis));
                if (aChild.attributes.getNamedItem('analysis-name').value === pItemName) {
                    ViewUtils.setElementVisibilityByDNone(aChild, (true === pToShow));
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public get analysisVersionOld() {
        return this.mCreationOptions.analysisData.analysis_version_old
    }
    //__________________________________________________________________________________________
    public get raysCount() {
        return this.mParams.raysCount;
    }
    //__________________________________________________________________________________________
    public get analysisVersionCurrent() {
        return this.mCreationOptions.analysisData.analysis_version
    }
    //__________________________________________________________________________________________
    public changeCurrentAnalysisVersion() {
        this.mCreationOptions.analysisData.analysis_version = Op3dUtils.idGenerator();
    }
    //______________________________________________________________________________________________
    public changeOldAnalysisVersion(pVersion: string) {
        this.mCreationOptions.analysisData.analysis_version_old = pVersion;
    }
    //__________________________________________________________________________________________
    public get isInvalid() {
        let aIsInvalid = false;
        for (let key in this.mParams.raysCount) {
            if (this.mParams.raysCount[key].classList.contains(Strings.RED_BORDER)) {
                aIsInvalid = true;
                break;
            }
        }

        return aIsInvalid;
    }
    //__________________________________________________________________________________________
    public checkRaysCount(pData: { rays_count: number; internal_id: string; }): void {
        if (parseFloat(this.mParams.raysCount[pData.internal_id].value) > pData.rays_count) {
            this._setInputInvalid(this.mParams.raysCount[pData.internal_id], true)
            Popup.instance.open({
                text: MessagesHandler.WRONG_ANALYSIS_RAYS_COUNT
            });
        } else {
            this._setInputInvalid(this.mParams.raysCount[pData.internal_id], false);
        }
        this._notifyIfRaysAreLessThanXY();
    }
    //__________________________________________________________________________________________
    private _setInitialRaysValues() {

        for (let key in this.mParams.raysCount) {
            if (this.mCreationOptions.analysisData.numRays[key] != null) {
                let aVal = this.mCreationOptions.analysisData.numRays[key];
                let aDispatchEvent = false;
                if (isNaN(aVal)) {
                    let aLaserData = Op3dContext.PARTS_MANAGER.getPartByInternalId(key).getBehavior("laserBehavior").laserData.lightSource.sourceGeometricalData;
                    aVal = (this.mCreationOptions.analysisData.type == eAnalysisType.ADVANCED) ? aLaserData.count_analysis : aLaserData.count;
                    this.mCreationOptions.analysisData.numRays[key] = aVal;
                    aDispatchEvent = true;
                }
                this.mParams.raysCount[key].value = OP3DMathUtils.getScientificValue(aVal);
                if (aDispatchEvent) {
                    this.mParams.raysCount[key].dispatchEvent(new Event("change"));
                }
            }
        }
    }
    //__________________________________________________________________________________________
    private _onAnalysisTypeChanged(pNotify: boolean) {
        this.mHasChangedParameters = true;
        AnalysisPortal.instance.updateLabels();
        if (pNotify) {
            this.mCreationOptions.onChange();
            this.changeCurrentAnalysisVersion();
        }
    }
    //__________________________________________________________________________________________
    private _chooseOption() {
        const aKind = this.mCreationOptions.analysisData.simulation_kind != null ?
            this.mCreationOptions.analysisData.simulation_kind : eSimulationKind.RAY_TRACING;


        let aOption = $(this.mParams.select).find('.dropdown-item').filter(
            (_idx, item) =>
                item.getAttribute("analysis-name") == this.mCreationOptions.analysisData.name &&
                item.getAttribute("simulation-kind") == aKind &&
                item.getAttribute("analysis-type") == this.mCreationOptions.analysisData.type.toString())[0];

        if (aOption === undefined) {
            throw new Error("Analysis doesnt exists in analysis options");
        }
        aOption.dispatchEvent(new CustomEvent("click", { detail: { notify: false } }));
    }
    //__________________________________________________________________________________________
    public set changedAnalysisParameters(pVal: boolean) {
        this.mHasChangedParameters = pVal;
    }
    //__________________________________________________________________________________________
    public get changedAnalysisParameters() {
        return this.mHasChangedParameters;
    }
    //__________________________________________________________________________________________
    private _initRaysComponent() {

        let aLasers = Op3dContext.PARTS_MANAGER.getLasers();
        let aRaysCountInputs: iHash<HTMLInputElement> = {};
        let aRaysItem = Op3dUtils.getElementIn(this.mCreationOptions.line, "single-rays-item");
        let aNoRays = Op3dUtils.getElementIn(this.mCreationOptions.line, "no_rays");

        let aRayElement = document.createElement('input')
        aRayElement.type = 'text'
        aRayElement.className = 'form-control ml-2 analysis-form-control';
        aRayElement.id = 'single-rays-item';

        /*
        *Prev: error when we dont have any aRaysItems after deleting
        *Changes: now we are not depending on child and creating it
        */
        let aContainer = Op3dUtils.getElementIn(this.mCreationOptions.line, "rays-count-parent");
        ViewUtils.removeFromParent(aRaysItem);

        if (Object.keys(aLasers).length === 0) {
            ViewUtils.setElementVisibilityByDNone(aNoRays, true)
        } else {
            ViewUtils.setElementVisibilityByDNone(aNoRays, false)
        }

        let aSize = 0;
        for (let id in aLasers) {

            const aLaser = aLasers[id];
            let aLSRaysInput = aRayElement.cloneNode(true) as HTMLInputElement
            aSize++;
            aContainer.appendChild(aLSRaysInput);
            aLSRaysInput.addEventListener("change",
                () => this._onChangeRaysCount(aLSRaysInput, aLaser));

            aLSRaysInput.setAttribute("laser", id);
            aRaysCountInputs[id] = aLSRaysInput;

            const aLightSource = aLaser.laserData.lightSource;

            let aCount = (this.mCreationOptions.analysisData.type == eAnalysisType.FAST) ?
                aLightSource.sourceGeometricalData.count :
                aLightSource.sourceGeometricalData.count_analysis;

            aLSRaysInput.value = OP3DMathUtils.getScientificValue(aCount);


            //if (eSmRaysKind.GAUSSIAN_BEAM == aLightSource.kind) {
            aLSRaysInput.disabled = this.isDisabledRays(aLightSource.kind,
                this.mCreationOptions.analysisData.name,
                this.mCreationOptions.analysisData.type);
            //}
        }

        let aTotalSize = Math.max(100, aSize * anSurfaceComponent.RAYS_INPUT_SIZE);
        aContainer.style.width = aTotalSize + 'px';
        this.mCreationOptions.titleSizeCotnainer.style.width = aTotalSize + "px";
        return aRaysCountInputs;
    }
    //__________________________________________________________________________________________
    private isDisabledRays(pKind: eSmRaysKind,
        pName: tAnalysisType = this.mParams.name,
        pType: eAnalysisType = this.mParams.type) {
        const aIsGaussLS = eSmRaysKind.GAUSSIAN_BEAM == pKind;
        // const aAnalysisData = this.mParams;
        const aIsDiffraction = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
            item.name === pName &&
            item.type === pType).isDiffration;

        return (aIsGaussLS && aIsDiffraction);
    }
    //__________________________________________________________________________________________
    private _setInputInvalid(pInput: HTMLInputElement, pIsInvalid: boolean) {
        ViewUtils.setClassState(pInput, Strings.RED_BORDER, pIsInvalid);
    }
    //__________________________________________________________________________________________
    private _onChangeRaysCount(pInput: HTMLInputElement, pLaserBehavior: LaserBehavior) {

        let aInputVal = parseFloat(pInput.value);

        /*
        *   preventing float numbers in rays field
        */
        if (isNaN(aInputVal)) {
            pInput.value = 1 + ''
        } else {
            pInput.value = Number.isInteger(aInputVal) ? pInput.value : Math.round(aInputVal) + ''
        }



        let aNotChanged = false;

        switch (this.mParams.type) {
            case eAnalysisType.ADVANCED:
                const aMaxAdvanced = pLaserBehavior.laserData.lightSource.sourceGeometricalData.count_analysis as number;
                if (aInputVal > aMaxAdvanced) {
                    aNotChanged = true;
                    this._setInputInvalid(pInput, true);
                    Popup.instance.open({
                        text: MessagesHandler.WRONG_ANALYSIS_RAYS_COUNT
                    });
                    /*
                     *   check value less then 0 => set to 1
                    */
                } else if (aInputVal <= 0) {
                    pInput.value = 1 + ''
                } else {
                    this._setInputInvalid(pInput, false);
                }
                break;
            case eAnalysisType.FAST:
                if (aInputVal > SimulationContext.SIMULATION_CONSTANTS.RAYS_COUNT_SINGLE_LASER_3D) {
                    pInput.value = SimulationContext.SIMULATION_CONSTANTS.RAYS_COUNT_SINGLE_LASER_3D.toString();
                    aNotChanged = true;
                }
                /*
                 *   check value less then 0 => set to 1
                */
                if (aInputVal <= 0) {
                    pInput.value = 1 + ''
                }
                break;
        }

        pInput.value = OP3DMathUtils.getScientificValue(parseFloat(pInput.value));

        if (!aNotChanged) {
            this._updateRunBtn();
            //   !aNotChanged && analysis.AnalysisPortal.instance.enableRunAnalysis2(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL);
        }
        this.mCreationOptions.onChange();
        this.mHasChangedParameters = true;
        this.changeCurrentAnalysisVersion();
        this._notifyIfRaysAreLessThanXY();
    }
    //__________________________________________________________________________________________
    private _notifyIfRaysAreLessThanXY() {
        const aResolutionX = parseInt(this.mParams.resolutionX.value);
        const aResolutionY = parseInt(this.mParams.resolutionY.value);
        for (const [_key, raysInp] of Object.entries(this.mParams.raysCount)) {
            if (Number(raysInp.value) < aResolutionX * aResolutionY) {
                raysInp.classList.add('rays-warning-border');
            } else if (Number(raysInp.value) >= aResolutionX * aResolutionY) {
                raysInp.classList.remove('rays-warning-border');
            }
        }
        this._checkRaysWarnings();
    }
    //__________________________________________________________________________________________
    private _checkRaysWarnings() {
        const aWarnings = document.querySelectorAll('.rays-warning-border');
        if (aWarnings.length > 0) document.querySelectorAll('.bi-exclamation-triangle').forEach(inp => inp.classList.remove('d-none'))
        else document.querySelectorAll('.bi-exclamation-triangle').forEach(inp => inp.classList.add('d-none'));
    }
    //__________________________________________________________________________________________
    private _onSizeChange(pSizeElement: HTMLInputElement) {
        let aPreviousValue = pSizeElement.value;
        let aOverLimit = false;
        let aResX: number;

        const aResolutionX = parseInt(this.mParams.resolutionX.value);
        const aResolutionY = parseInt(this.mParams.resolutionY.value);
        if (aResolutionX * aResolutionY > piDetectorSizeSection.MAX_DETECTOR_RESOLUTION) {
            aOverLimit = true;
            this.mParams.resolutionX.value = `${this.mPrevXPxls}`;
            this.mParams.resolutionY.value = `${this.mPrevYPxls}`;
            let aMsg = "Maximal possible resolution is 10000 x 10000";
            Popup.instance.open({
                text: aMsg
            })
            return;
        }
        this.mPrevXPxls = aResolutionX;
        this.mPrevYPxls = aResolutionY;
        let aValue = parseInt(pSizeElement.value);

        switch (this.mParams.type) {
            case eAnalysisType.FAST:
                if (aValue > 100) {
                    aResX = 100;
                    aOverLimit = true;
                    this._pixelsOverLimit();
                } else if (aValue < piDetectorSizeSection.MIN_DETECTOR_RESOLUTION) {
                    aResX = piDetectorSizeSection.MIN_DETECTOR_RESOLUTION;
                    aOverLimit = true;
                    this._pixelsOverLimit();
                } else {
                    aResX = aValue;
                }
                break;

            case eAnalysisType.ADVANCED:
                if (this.mParams.simulation_kind == eSimulationKind.DIFFRACTION && ServerContext.env != ENVS.DEV) {
                    if (aValue > 800) {
                        aResX = 800;
                        aOverLimit = true;
                        this._pixelsOverLimit();
                        let aMsg = "Maximal possible resolution in diffraction analysis is 800 x 800";
                        Popup.instance.open({
                            text: aMsg
                        })
                    } else if (aValue < piDetectorSizeSection.MIN_DETECTOR_RESOLUTION) {
                        aResX = piDetectorSizeSection.MIN_DETECTOR_RESOLUTION;
                        aOverLimit = true;
                        this._pixelsOverLimit();
                        let aMsg = "Maximal possible resolution is 10000 x 10000";
                        Popup.instance.open({
                            text: aMsg
                        })
                    } else {
                        aResX = aValue;
                    }
                    break;
                } else {
                    if (aValue > piDetectorSizeSection.MAX_DETECTOR_RESOLUTION) {
                        aResX = piDetectorSizeSection.MAX_DETECTOR_RESOLUTION;
                        aOverLimit = true
                        $(pSizeElement).popover({
                            content: "Limited to " + piDetectorSizeSection.MAX_DETECTOR_RESOLUTION + " till January,2023",
                            placement: "right"
                        }).popover('enable').popover('show').on(setTimeout(function () { $(pSizeElement).popover('hide').popover('disable') }, 2000))

                    } else if (aValue < piDetectorSizeSection.MIN_DETECTOR_RESOLUTION) {
                        aResX = piDetectorSizeSection.MIN_DETECTOR_RESOLUTION;
                        aOverLimit = true
                    } else {
                        aResX = aValue;
                    }
                    break;
                }
        }

        pSizeElement.value = aResX.toString()
        if (aPreviousValue !== pSizeElement.value) {
            if (!aOverLimit) {
                return;
            }
        }
        this._notifyIfRaysAreLessThanXY();
        this.mHasChangedParameters = true;
        this._updateRunBtn();
        this.changeCurrentAnalysisVersion();
        this.mCreationOptions.onChange()
    }
    //__________________________________________________________________________________________
    private _pixelsOverLimit() {
        this.mCreationOptions.onPixelOverLimit();
    }
    //__________________________________________________________________________________________
    private _updateRunBtn() {
        if (this.isLocked == false) {
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL);
        }
    }
    //__________________________________________________________________________________________
    private _onClickAnalysis(e: MouseEvent) {
        if (e.button == 2) {
            // right button context menu
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();

            // add to quickView

            if (this.mParams.type == eAnalysisType.FAST) {
                let aJSTreeContextMenuItem: JSTreeContextMenuItems = {
                    'qv': {
                        label: 'Add to quick view',
                        action: () => {
                            QuickViewHandler.instance.addAnalysis({
                                analysisId: this.mCreationOptions.analysisData.id,
                                display: this.mParams.display.selectedOptions[0].value as eAnalysisDisplay,
                                name: this.mParams.name,
                                faceID: this.mCreationOptions.faceId
                            });
                        },
                        icon: eTreeIcons.QV
                    }
                };

                Op3dContext.SIDEBAR_LIST.openContextMenu(null, e, aJSTreeContextMenuItem);
            } else {
                NotificationCenter.instance.pushNotification({
                    message: MessagesHandler.ADVANCED_QUICK_VIEW_MSG,
                    params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL
                });
            }
        }
    }
    //__________________________________________________________________________________________
    private _compareResult(e: Event, pCompareBtn: HTMLElement) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        $(this.mResult.container).find('[data-toggle="tooltip"]').tooltip('hide')
        let aIsCompare = ViewUtils.toggleElementActive(pCompareBtn);
        this.mResult.compare = aIsCompare;
    }
    //__________________________________________________________________________________________
    public get result() {
        // this._notifyIfRaysAreLessThanXY();
        return this.mResult;
    }
    //__________________________________________________________________________________________
    public set result(pResult: iAnalysisResultItem) {
        this.mResult = pResult;
    }
    //__________________________________________________________________________________________
    public get name() {
        return this.mParams.name;
    }
    //__________________________________________________________________________________________
    public get isLocked() {
        if (this.mResult != null) {
            return this.mResult.isLocked;
        }
        return false;
    }
    //__________________________________________________________________________________________
    private _lockResult(e: Event) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        $(this.mResult.container).find('[data-toggle="tooltip"]').tooltip('hide')

        let aIsLocked = ViewUtils.toggleElementActive(this.mLockBtn);
        this.mResult.isLocked = aIsLocked;

        if (this.mHasChangedParameters) {
            this._updateRunBtn();
        }

        AnalysesSynchronizer.instance.setLockedItems({
            analysisID: this.mResult.analysisItem.id,
            isLocked: aIsLocked
        }, true);
    }
    //__________________________________________________________________________________________
    public initSingleResult() {

        this.mResult.container.addEventListener("mouseup",
            (e: MouseEvent) => this._onClickAnalysis(e));

        let aCompareBtn = Op3dUtils.getElementIn(this.mResult.container, "compare-btn");
        aCompareBtn.addEventListener("click",
            (e) => this._compareResult(e, aCompareBtn));

        let aLockBtn = Op3dUtils.getElementIn(this.mResult.container, "lock-btn");
        aLockBtn.addEventListener("click", (e) => this._lockResult(e));
        this.mLockBtn = aLockBtn;

        $(this.mResult.container).find('[data-toggle="tooltip"]').tooltip()

    }
    //__________________________________________________________________________________________
    public addResult(
        pAnalysisSingleResult: HTMLElement,
        pResultItem: iAnalysisResultItem) {

        let aScreenItem = Op3dUtils.getElementIn(pAnalysisSingleResult, "analysis-content");
        ViewUtils.setElementVisibilityByDNone(aScreenItem, true);

        // data init
        let aRes = new anSmallResultFactory().createResult({
            container: aScreenItem,
            graphData: {
                labels: AnalysisCanvasUtils.getLabels(pResultItem.analysisItem),
                spectrumData: {
                    label: AnalysisCanvasUtils.getSpectrumLabel(pResultItem.analysisItem),
                    range: pResultItem.cacheData.matrix.original.values_range
                },
                range_points: AnalysisCanvasUtils.getRange(pResultItem.cacheData, pResultItem.analysisItem.display),
                cacheData: pResultItem.cacheData,
                analysisItem: pResultItem.analysisItem,
            },
            uiParams: AnalysisContext.GRID_GENERAL_OPTIONS,
        });

        pResultItem.needsUpdate = (aRes.totalSize.width == 0);
        this.enable = true;
    }
    //__________________________________________________________________________________________
    private _onDisplayChanged() {
        this.mCreationOptions.onChange()

        if (this.mResult != null &&
            this.mResult.container != null) {
            let aDisplayType = this.mParams.display.selectedOptions[0].value as eAnalysisDisplay;
            this.mResult.analysisItem.display = aDisplayType;
            this.addResult(this.mResult.container, this.mResult);
        }
    }
    //__________________________________________________________________________________________
    private _initDisplayDD(pSelect: HTMLSelectElement) {
        let aKeys = AnalysisContext.ANALYSIS_2D_DISPLAY_TYPES;
        for (let i = 0; i < aKeys.length; i++) {
            let aOption = document.createElement("option");
            let aTxt = aKeys[i].charAt(0).toUpperCase() + aKeys[i].slice(1).toLowerCase()

            aOption.innerText = aTxt;
            aOption.value = aKeys[i];
            pSelect.appendChild(aOption);
        }
    }
    //__________________________________________________________________________________________
    public remove(pNotify: boolean = true) {

        ViewUtils.removeFromParent(this.mCreationOptions.line);
        this.mCreationOptions.onRemove(this.mCreationOptions.analysisData.id);
        SimulationRunner.instance.removeFresnelFarFromHash(this.mCreationOptions.analysisData.id)
        this._discardResult();
        this._onAnalysisTypeChanged(pNotify);
        this._notifyIfRaysAreLessThanXY();
        AnalysesSynchronizer.instance.onDeleteAnalysis(this.mCreationOptions.analysisData.id);
        ServerContext.SERVER.removeOneAnalysis({
            setup_id: Op3dContext.SETUPS_MANAGER.currSetupID,
            analysis_id: this.mParams.analysis_id
        })
    }
    //__________________________________________________________________________________________
    private _initDD(pDD: HTMLElement, pChoiceElement: HTMLElement) {

        const aFastData = AnalysisConstants.ANALYSES_OPTIONS.filter(item => item.type === eAnalysisType.FAST);

        let aFastContainer = Op3dUtils.getElementIn(pDD, "fast-items-container", true);
        let aFastOption = Op3dUtils.getElementIn(pDD, "single-fast-dd-item", true);

        aFastOption.parentElement.removeChild(aFastOption);
        this._initOptions(aFastData, aFastOption, aFastContainer, pChoiceElement, pDD,
            eAnalysisType.FAST, eSimulationKind.RAY_TRACING);

        let aHasAdvanceAnalysis = Op3dContext.USER_PERMISSION.hasAdvancedAnalysis;

        let aAdvancedHeader = Op3dUtils.getElementIn(pDD, "advanced_header");
        let aUpgradeElem = Op3dUtils.getElementIn(aAdvancedHeader, "upgrade_element");
        ViewUtils.setElementVisibilityByDNone(aUpgradeElem, (false == aHasAdvanceAnalysis));

        let aLinkToPricing = Op3dUtils.getElementIn(aAdvancedHeader,
            "try-professional-thumbnail") as HTMLAnchorElement;
        aLinkToPricing.href = ServerContext.pricing_base_link;

        let aAdvancedContainer = Op3dUtils.getElementIn(pDD, "advanced-items-container");
        ViewUtils.setElementDisabled(aAdvancedContainer, (false == aHasAdvanceAnalysis));

        const aAdvancedData = AnalysisConstants.ANALYSES_OPTIONS.filter(item =>
            item.type === eAnalysisType.ADVANCED && (true !== item.isDiffration) &&
            (true !== item.isSystemAnalysis));


        let aAdvancedOption = Op3dUtils.getElementIn(pDD, "single-advanced-dd-item");

        let aDiffracionHeader = Op3dUtils.getElementIn(pDD, 'diffracion_header');
        aDiffracionHeader.parentElement.removeChild(aDiffracionHeader);


        aAdvancedOption.parentElement.removeChild(aAdvancedOption);
        this._initOptions(aAdvancedData, aAdvancedOption, aAdvancedContainer, pChoiceElement,
            pDD, eAnalysisType.ADVANCED, eSimulationKind.RAY_TRACING, aHasAdvanceAnalysis);

        let aDropdownDivider = document.createElement('dropdown-divider');
        aDropdownDivider.classList.add('dropdown-divider');
        aAdvancedContainer.appendChild(aDropdownDivider);

        aAdvancedContainer.appendChild(aDiffracionHeader);


        const aDiffracionData = AnalysisConstants.ANALYSES_OPTIONS.filter(item =>
            (true === item.isDiffration));
        //AnalysisContext.DIFFRACTION_ANALYSIS_DATA;
        this._initOptions(aDiffracionData, aAdvancedOption, aAdvancedContainer, pChoiceElement, pDD, eAnalysisType.ADVANCED, eSimulationKind.DIFFRACTION, aHasAdvanceAnalysis);

        const aSystemData = AnalysisConstants.ANALYSES_OPTIONS.filter(item =>
            (true === item.isSystemAnalysis));
        let aSystemHeader = Op3dUtils.getElementIn(pDD, 'system_header');
        aSystemHeader.parentElement.removeChild(aSystemHeader);
        aAdvancedContainer.appendChild(aSystemHeader);
        this._initOptions(aSystemData, aAdvancedOption, aAdvancedContainer, pChoiceElement, pDD, eAnalysisType.ADVANCED, eSimulationKind.DIFFRACTION, aHasAdvanceAnalysis);
    }
    //__________________________________________________________________________________________
    private _onUpdatePermissions() {
        let aHasAdvanceAnalysis = Op3dContext.USER_PERMISSION.hasAdvancedAnalysis;

        let aDD = Op3dUtils.getElementIn(this.mCreationOptions.line, "analysis-dd");

        let aAdvancedHeader = Op3dUtils.getElementIn(aDD, "advanced_header");
        let aUpgradeElem = Op3dUtils.getElementIn(aAdvancedHeader, "upgrade_element");
        ViewUtils.setElementVisibilityByDNone(aUpgradeElem, (false == aHasAdvanceAnalysis));

        let aAdvancedContainer = Op3dUtils.getElementIn(aDD, "advanced-items-container");
        ViewUtils.setElementDisabled(aAdvancedContainer, (false == aHasAdvanceAnalysis));

        for (let i = 0; i < aAdvancedContainer.children.length; i++) {
            if ('single-advanced-dd-item' == aAdvancedContainer.children[i].id) {
                let aChild = aAdvancedContainer.children[i];
                ViewUtils.setElementDisabled(aChild, (false == aHasAdvanceAnalysis));
            }
        }
    }
    //__________________________________________________________________________________________
    private _initOptions(pData: iAnalysisDDItem[], pSingleItem: HTMLElement,
        pContainer: HTMLElement, pChoiceElement: HTMLElement, pDD: HTMLElement,
        pType: eAnalysisType, pSimulationKind: eSimulationKind, pIsEnabled: boolean = true) {

        for (let i = 0; i < pData.length; i++) {
            // let aConditionFar = pData[i].name.includes('Fresnel Far');
            // let aConditionEnv = ServerContext.env === ENVS.PROD;

            if (pData[i].disabled) {
                continue;
            }

            if (pData[i].isFresnelAnalysis === true &&
                Op3dContext.APP_FEATURES_CONFIG.fresnel_analysis.enabled === false) {
                continue;
            }

            // if (aConditionFar && aConditionEnv) {
            //     continue;
            // }

            let aOption = pSingleItem.cloneNode(true) as HTMLElement;
            let aName = Op3dUtils.getElementIn(aOption, "analysis-name", true);
            aName.innerText = pData[i].name;

            pContainer.appendChild(aOption);
            let aIconCot = Op3dUtils.getElementIn(aOption, "icon_div", true);

            let aColorClass = pData[i].color;
            aIconCot.classList.add(aColorClass);

            let aIcon = AnalysisContext.getSimulationDDICON(pType, pSimulationKind);
            aIconCot.innerHTML = aIcon;

            aOption.setAttribute("shortcut", pData[i].shortcut);
            aOption.setAttribute("analysis-name", pData[i].name);
            aOption.setAttribute("analysis-type", pType.toString());
            aOption.setAttribute("simulation-kind", pSimulationKind);
            ViewUtils.setElementDisabled(aOption, (false == pIsEnabled));

            aOption.addEventListener("click",
                (e) => this._onDDChanged(e as PointerEvent,
                    pChoiceElement, aOption, pDD));
        }
    }
    //__________________________________________________________________________________________
    private _onDDChanged(pEvent: CustomEvent | PointerEvent,
        pChoiceElement: HTMLElement, pOption: HTMLElement, pDD: HTMLElement) {

        pEvent.preventDefault();
        pEvent.stopPropagation();
        pEvent.stopImmediatePropagation();

        const aAnalysisName = pOption.getAttribute("analysis-name") as tAnalysisType;

        let aChoice = pOption.cloneNode(true) as HTMLElement;
        aChoice.classList.add("p-0")
        pChoiceElement.innerText = "";
        pChoiceElement.appendChild(aChoice);
        pChoiceElement.title = aAnalysisName;
        $(pChoiceElement).tooltip();
        pChoiceElement.setAttribute("data-original-title", aAnalysisName);

        let aType: eAnalysisType = parseInt(pOption.getAttribute("analysis-type"));
        this.mParams.type = aType;
        this.mParams.name = aAnalysisName;

        let aSimulationKind = pOption.getAttribute('simulation-kind') as eSimulationKind;
        this.mParams.simulation_kind = aSimulationKind;

        if (aType == eAnalysisType.FAST) {
            let aResX = +this.mParams.resolutionX.value > 100 ?
                100 : parseInt(this.mParams.resolutionX.value);
            let aResY = +this.mParams.resolutionY.value > 100 ?
                100 : parseInt(this.mParams.resolutionY.value);
            this.mParams.resolutionX.value = aResX.toString();
            this.mParams.resolutionY.value = aResY.toString();
        }

        pDD.setAttribute("shortcut", pOption.getAttribute("shortcut"));
        let aNotify = pEvent.detail.notify != null ? pEvent.detail.notify : true;

        if (aNotify) {
            for (let key in this.mParams.raysCount) {
                if (aType == eAnalysisType.FAST) {
                    let aVal = parseFloat(this.mParams.raysCount[key].value);
                    if (aVal > SimulationContext.SIMULATION_CONSTANTS.RAYS_COUNT_SINGLE_LASER_3D) {
                        let aLasers = Op3dContext.PARTS_MANAGER.getLasers()

                        let aCount = this.mParams.type == eAnalysisType.FAST ?
                            aLasers[key].laserData.lightSource.sourceGeometricalData.count :
                            aLasers[key].laserData.lightSource.sourceGeometricalData.count_analysis;

                        this.mParams.raysCount[key].value = aCount.toString();

                    }

                } else {
                    let aLaser = Op3dContext.PARTS_MANAGER.getPartByInternalId(key);
                    let aCount = aLaser.getBehavior("laserBehavior").laserData.lightSource.sourceGeometricalData.count_analysis;
                    this.mParams.raysCount[key].value = OP3DMathUtils.getScientificValue(Number(aCount));
                }
            }
        }
        this._onAnalysisTypeChanged(aNotify);
        this._updateRunBtn();
        this._notifyIfRaysAreLessThanXY();
        this._updateLasers();
    }
    //__________________________________________________________________________________________
    private _updateLasers() {
        let aLasers = Op3dContext.PARTS_MANAGER.getLasers();
        for (let laserID in this.mParams.raysCount) {
            let aType = aLasers[laserID].laserData.lightSource.kind;
            this.updateSpecificLaser(laserID, aType);
        }
    }
    //__________________________________________________________________________________________
    private _onToggleTypeDD(e: Event, pBtn: HTMLElement) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        let aIsShow = ViewUtils.toggleClass(this.mParams.select, Strings.SHOW);
        if (aIsShow) {
            AnalysisPortal.instance.closeOptionsDD(this);
            let aBB = pBtn.getBoundingClientRect();

            this.mParams.select.style.left = aBB.left + 'px';
            this.mParams.select.style.top = aBB.top + 50 + 'px';
            let aDDBB = this.mParams.select.getBoundingClientRect();

            let aDelta = aDDBB.top + aDDBB.height - window.innerHeight;
            if (aDelta > 0) {
                this.mParams.select.style.top = (aBB.top + 50) - (aDelta + 10) + 'px'
            }
            // this.mHasChangedParameters = true;
        } else {

        }
    }
    //__________________________________________________________________________________________
    private _onChangeCheckbox(e) {
        Op3dContext.SCENE_HISTORY.addToHistory();

        let aIsActive = e.target.checked
        let aEventPath = e.composedPath()
        if (aIsActive) {
            this._updateRunBtn();
            aEventPath[2].classList.remove('disable');
            this.mHasChangedParameters = true;
        } else {
            aEventPath[2].classList.add('disable')
        }

        this.mCreationOptions.onChange(e);
    }
    //__________________________________________________________________________________________
    public updateRaysComponent() {
        let aAnalysisID = this.mCreationOptions.analysisData.id;
        let aAnalysis = Op3dContext.PARTS_MANAGER.getAnalysisById(aAnalysisID);
        if (aAnalysis == null) {
            // analysis was deleted
            return;
        }

        let aNewKeys = Object.keys(aAnalysis.numRays);
        let aCurrKeys = Object.keys(this.mParams.raysCount);
        let aDeleted = aNewKeys.filter(item => !aCurrKeys.includes(item));
        let aAdded = aCurrKeys.filter(item => !aNewKeys.includes(item));

        let aUpdateLaser = false;
        if (aDeleted.length != 0 || aAdded.length != 0) {

            let aCountParent = Op3dUtils.getElementIn(this.mCreationOptions.line,
                "rays-count-parent");
            ViewUtils.removeElementChildren(aCountParent, 1);
            this.mParams.raysCount = this._initRaysComponent();
            aUpdateLaser = true;
        }

        return aUpdateLaser;
    }
    //__________________________________________________________________________________________
    public set label(pLabel: HTMLElement) {
        this.mParams.label = pLabel;
    }
    //__________________________________________________________________________________________
    public get label() {
        return this.mParams.label;
    }
    //__________________________________________________________________________________________
    public get select() {
        return this.mParams.select;
    }
    //__________________________________________________________________________________________
    private _discardResult() {
        if (this.mResult == null) {
            return;
        }

        $(this.mResult.container).removeResize(this.mResult.callback);
        ViewUtils.removeFromParent(this.mResult.container);
    }
    //__________________________________________________________________________________________
    public get simulationKind() {
        return this.mParams.simulation_kind;
    }
    //__________________________________________________________________________________________
    public get analysisType() {
        return this.mParams.type;
    }
    //__________________________________________________________________________________________
    public setNotUpdated(pIsToShow: boolean): void {
        if (this.mParams.type == eAnalysisType.ADVANCED &&
            this.mResult != null &&
            this.mResult.container != null) {
            let aNotUpdated = Op3dUtils.getElementIn(this.mResult.container, "not_updated");
            ViewUtils.setElementVisibilityByDNone(aNotUpdated, pIsToShow)
        }
    }
    //__________________________________________________________________________________________
    public terminateAnalysis(): void {
        if (this.mResult == null) {
            return
        }

        if (this.mResult.isReady) {
            return;
        }

        this.mHasChangedParameters = true;
        this.changeCurrentAnalysisVersion();
        let aSpinner = Op3dUtils.getElementIn(this.mResult.container,
            "analysis-spinner");
        AnalysisCache.removeEventListener(aSpinner.getAttribute("ph_id"));

        this.enable = true;
        this._discardResult();
        this.mResult = null;
    }
    //__________________________________________________________________________________________
    public getData() {
        let aRaysCount: iHash<number> = {};
        for (let item in this.mParams.raysCount) {
            let aKey = this.mParams.raysCount[item].getAttribute("laser");
            aRaysCount[aKey] = parseFloat(this.mParams.raysCount[item].value);
        }

        const aX = parseInt(this.mParams.resolutionX.value);
        const aY = parseInt(this.mParams.resolutionY.value);

        let aAnalysisItem: iAnalysisItem = {
            isActiveAnalysisSurface: null,
            display: this.mParams.display.selectedOptions[0].value as eAnalysisDisplay,
            isActive: this.mParams.isActiveCheckbox.checked,
            numRays: aRaysCount,
            resolution: { x: aX, y: aY },
            type: this.mParams.type,
            id: this.mCreationOptions.analysisData.id,
            name: this.mParams.name as tAnalysisType,
            analysis_version_old: this.analysisVersionOld,
            analysis_version: this.analysisVersionCurrent,
            simulation_kind: this.mParams.simulation_kind
        }

        return aAnalysisItem;
    }
    //__________________________________________________________________________________________
    public get id(): string {
        return this.mCreationOptions.analysisData.id;
    }
    //__________________________________________________________________________________________
    public get isActiveCheckbox(): boolean {
        return this.mParams.isActiveCheckbox.checked;
    }
    //__________________________________________________________________________________________
    public setLock(pIsLocked: boolean) {
        this.mResult.isLocked = pIsLocked;
        ViewUtils.setElementActive(this.mLockBtn, pIsLocked);
    }
    //__________________________________________________________________________________________
}