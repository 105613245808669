import { eStateToAnalysis } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { SettingsContext } from "../../../settings/SettingsContext";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";
import { iNumberInputElementParams } from "../PartInfoSection";

export interface iNumberInputData extends iNIEBasicCreationData {
    /**
     *@default is TRUE
     */
    hasArrows?: boolean;
    triggers?: any
    label?: any
};

export interface iNIEBasicCreationData {
    /**
     * addition to title
     */
    unitName?: string;
    worldToUnit?: number;
    /**
     * how much digets after dot to keep
     */
    toFixed?: number | false;
    isGlobalToFixed: boolean;
    /**
     * optical qa_id for automation
     */
    qa_id?: string;


    sign?: string;


    field_name?: string;

    startValue?: number;

    unitElementWidth?: number;
    isScientific?: boolean
}
/**
    * 
   @deprecated
   @description use NIE instead
    */
export class newNumberInputElement {

    private mCreationData: iNumberInputData;
    private mParams: iNumberInputElementParams = {};
    private mInput: iOP3DHTMLInputElement;
    private mContainer: HTMLElement;
    private mActualValue: number;

    /**
     * 
    @deprecated
    @description use NIE instead
     */
    constructor(pContainer: HTMLElement, pData: iNumberInputData) {
        this._init(pContainer, pData);
    }
    //__________________________________________________________________________________________
    private _getToFixed() {
        let aToFixed: number;
        if (this.mCreationData.isGlobalToFixed === true) {
            try {
                aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
            } catch (e) {
                // settings are not loaded yet
                aToFixed = SettingsContext.DEFAULT_USER_VO.parameters.simulation.numericAccuracy;
            }

        } else if (false != this.mCreationData.toFixed) {
            aToFixed = this.mCreationData.toFixed;
        }

        return aToFixed;
    }
    //__________________________________________________________________________________________
    public distract() {
        this.mCreationData = null;
        this.mParams = null;
        this.mInput = null;
        this.mContainer = null;
        this.mActualValue = null;

        ViewUtils.removeFromParent(this.mContainer);
    }
    //__________________________________________________________________________________________
    public reset() {
        this.mInput.value = (null != this.mParams.startValue) ?
            this.mParams.startValue.toString() : '0';
        this.mInput.prevValue = this.mInput.value;
    }
    //__________________________________________________________________________________________
    public updateCreationData(pData: iNIEBasicCreationData) {
        for (let key in pData) {
            this.mCreationData[key] = pData[key];
        }

        if (null != pData.unitName) {
            let aTitle = this.mContainer.getElementsByClassName("input_pi_append")[0];
            aTitle.innerHTML = this.mCreationData.unitName;
        }

        if (null != pData.field_name) {
            let aTextDiv = Op3dUtils.getElementIn(this.mContainer, 'text_div');
            if (null != aTextDiv) {
                aTextDiv.setAttribute('field_name', this.mCreationData.field_name);
            }
        }
    }
    //__________________________________________________________________________________________
    public setData(pNewParams: iNumberInputElementParams) {
        if (null == pNewParams) {
            return;
        }

        if (null != pNewParams.callback) {
            this.mParams.callback = pNewParams.callback;
        }

        if (null != pNewParams.range) {
            this.mParams.range = pNewParams.range;
        }

        if (null != pNewParams.step) {
            this.mParams.step = pNewParams.step;
        }

        if (null != pNewParams.startValue) {
            this.mParams.startValue = pNewParams.startValue;
        }

        if (null != pNewParams.factor) {
            this.mParams.factor = pNewParams.factor;
        }

        if (null != pNewParams.mod) {
            this.mParams.mod = pNewParams.mod;
        }

        if (true == pNewParams.isInt) {
            this.mCreationData.toFixed = 0;
        }

        return this;
    }
    //__________________________________________________________________________________________
    private _onSetValue(pCheckTriggers: boolean = false) {
        if (true == isNaN(parseFloat(this.mInput.value))) {
            this.mInput.value = this.mInput.prevValue;
            if (this.mCreationData.isScientific == true) {
                this.mInput.value =
                    OP3DMathUtils.getScientificValue(parseFloat(this.mInput.value));
            }
            return;
        }

        let aValue = isNaN(this.value) ? 0 : this.value;
        aValue = OP3DMathUtils.clampValue(aValue, this.mParams.range.min, this.mParams.range.max);
        if (null != this.mParams.mod) {
            aValue = (aValue % this.mParams.mod);
        }

        let aToFixed = this._getToFixed();
        if (aToFixed !== undefined) {
            this.mInput.value = OP3DMathUtils.toFixed(aValue, aToFixed);
        }

        if (this.mInput.prevValue == this.mInput.value) {
            return;
        }

        this.value = aValue;

        if (null != this.mParams.callback) {
            if ((true == this.mCreationData.triggers.saveHistory) && (true == pCheckTriggers)) {
                Op3dContext.SCENE_HISTORY.addToHistory();
            }

            this.mParams.callback(aValue);

            if (true == pCheckTriggers) {
                if (true == this.mCreationData.triggers.saveScene) {
                    Op3dContext.SCENE_HISTORY.saveScene();
                }

                if (true == this.mCreationData.triggers.triggerAnalysis) {
                    AnalysisPortal.instance.enableRunAnalysis(
                        eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                }
            }
        }

        this.mInput.blur();
    }
    //__________________________________________________________________________________________
    public set value(pVal: number) {
        if (true == isNaN(pVal)) {
            this.mInput.value = this.mInput.prevValue;
            return;
        }

        if (null != this.mParams.mod) {
            pVal = (pVal % this.mParams.mod);
        }

        if (true == this.mParams.isInt) {
            pVal = parseInt(pVal.toString());
        }

        let aToFixed = this._getToFixed();
        if (aToFixed !== undefined) {
            this.mInput.value = OP3DMathUtils.toFixed(pVal, aToFixed);
        } else {
            this.mInput.value = pVal.toString();
        }

        this.mInput.prevValue = this.mInput.value;
        this.mActualValue = pVal;
    }
    //__________________________________________________________________________________________
    public get value(): number {
        let aValue = this.mInput.value;
        let aRet = parseFloat(aValue);
        return aRet;
    }
    //__________________________________________________________________________________________
    public set convertedValue(pValue: number) {
        this.value = (pValue / this.mParams.factor);
    }
    //__________________________________________________________________________________________
    public get convertedValue() {
        let aVal = this.mActualValue;

        return (aVal * this.mParams.factor);
    }
    //__________________________________________________________________________________________
    public set startValue(pValue: number) {
        let aToFixed = this._getToFixed();
        if (aToFixed !== undefined) {
            this.mInput.value = OP3DMathUtils.toFixed(pValue, aToFixed);
        } else {
            this.mInput.value = pValue.toString();
        }
    }
    //__________________________________________________________________________________________
    public get startValue() {
        return parseFloat(this.mInput.startValue);
    }
    //__________________________________________________________________________________________
    public get actualValue() {
        return this.mActualValue;
    }
    //__________________________________________________________________________________________
    public get range() {
        return this.mParams.range;
    }
    //__________________________________________________________________________________________
    private _onKeyUp(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    }
    //__________________________________________________________________________________________
    private _create(pData: iNumberInputData) {
        let aDiv = document.createElement('div');
        aDiv.classList.add('input_field_div');
        aDiv.classList.add('nie_flex_box');

        if (null != pData.label) {
            let aLabelDiv = document.createElement('div');
            aLabelDiv.innerHTML = pData.label;
            this.mContainer.appendChild(aLabelDiv);
        }

        if (null != pData.field_name) {
            let aFieldNameDiv = document.createElement('div');
            aFieldNameDiv.classList.add('field_name_div');
            aFieldNameDiv.classList.add('new');

            let aTextDiv = document.createElement('div');
            aTextDiv.setAttribute('field_name', pData.field_name);
            aTextDiv.classList.add('text_div');
            aFieldNameDiv.appendChild(aTextDiv);
            aDiv.appendChild(aFieldNameDiv);
        }

        this.mInput = document.createElement('input') as iOP3DHTMLInputElement;
        if (pData.qa_id != null) {
            this.mInput.setAttribute("qa_id", pData.qa_id);
        }

        aDiv.appendChild(this.mInput);

        if (false != pData.hasArrows) {
            let aArrwosDiv = document.createElement('div');
            aArrwosDiv.classList.add('field_arrows');

            let aUpArrowDiv = document.createElement('div');
            aUpArrowDiv.classList.add('field_arrows_arrow');
            aUpArrowDiv.innerHTML = '<i class="bi bi-caret-up-fill"></i>';
            aArrwosDiv.appendChild(aUpArrowDiv);

            let aDownArrowDiv = document.createElement('div');
            aDownArrowDiv.classList.add('field_arrows_arrow');
            aDownArrowDiv.innerHTML = '<i class="bi bi-caret-down-fill"></i>';
            aArrwosDiv.appendChild(aDownArrowDiv);

            aDiv.appendChild(aArrwosDiv);
        }

        if ((null != pData.unitName) && ('' != pData.unitName)) {
            let aUnitAppend = document.createElement('div');
            aUnitAppend.classList.add('input_pi_append');
            aUnitAppend.innerHTML = pData.unitName;

            aDiv.appendChild(aUnitAppend);
        }

        this.mContainer.appendChild(aDiv);
    }
    //__________________________________________________________________________________________
    private _init(pContainer: HTMLElement, pData: iNumberInputData) {
        this.mContainer = pContainer;
        this.mContainer.classList.add("w-100");

        this._create(pData);
        this._initCreationData(pData);

        this.mParams.range = { min: -Number.MAX_VALUE, max: Number.MAX_VALUE };
        this.mParams.step = 1;
        this.mParams.factor = 1;

        this.mInput.addEventListener("change", () => this._onSetValue(true));
        this.mInput.addEventListener("keyup", (e) => this._onKeyUp(e));
        this.mInput.value = '0';
        //this.mInput.classList.add('width-auto')

        if (null != this.mCreationData.unitName) {
            let aTitle = pContainer.getElementsByClassName("input_pi_append")[0] as HTMLElement;
            if (null != aTitle) {
                aTitle.innerHTML = this.mCreationData.unitName;
            }

            if (null != this.mCreationData.unitElementWidth) {
                aTitle.style.flex = '1 12 ' + this.mCreationData.unitElementWidth + 'px';
                aTitle.style.maxWidth = 'none';
            }
        }

        if (null != this.mCreationData.sign) {
            let aTextDiv = this.mContainer.getElementsByClassName('text_div')[0];
            let aAttrVal = aTextDiv.getAttribute('field_name');
            aAttrVal += this.mCreationData.sign;
        }

        let aArrowsParent = this.mContainer.getElementsByClassName('field_arrows')[0];
        if (null != aArrowsParent) {
            let aArrowUp = aArrowsParent.children[0] as HTMLElement;
            aArrowUp.addEventListener('mousedown', () => {
                if (true == this.mCreationData.triggers.saveHistory) {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                }

                let aInterval = setInterval(() => this._onArrowClicked(1), 200);

                let aMouseUpFunc = () => {
                    clearInterval(aInterval);
                    if (true == this.mCreationData.triggers.saveScene) {
                        Op3dContext.SCENE_HISTORY.saveScene();
                    }

                    if (true == this.mCreationData.triggers.triggerAnalysis) {
                        AnalysisPortal.instance.enableRunAnalysis(
                            eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                    }
                    window.removeEventListener('mouseup', aMouseUpFunc);
                }
                window.addEventListener('mouseup', aMouseUpFunc);
                this._onArrowClicked(1);
            });
            let aArrowDown = aArrowsParent.children[1] as HTMLElement;
            aArrowDown.addEventListener('mousedown', () => {
                if (true == this.mCreationData.triggers.saveHistory) {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                }

                let aInterval = setInterval(() => this._onArrowClicked(-1), 200);

                let aMouseUpFunc = () => {
                    clearInterval(aInterval);
                    if (true == this.mCreationData.triggers.saveScene) {
                        Op3dContext.SCENE_HISTORY.saveScene();
                    }

                    if (true == this.mCreationData.triggers.triggerAnalysis) {
                        AnalysisPortal.instance.enableRunAnalysis(
                            eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                    }
                    window.removeEventListener('mouseup', aMouseUpFunc);
                }
                window.addEventListener('mouseup', aMouseUpFunc);
                this._onArrowClicked(-1);
            });
        }
    }
    //__________________________________________________________________________________________
    private _onArrowClicked(pSign: number) {
        let aNewVal: number;
        if (false != this.mCreationData.toFixed) {
            let aValue = parseFloat(OP3DMathUtils.toFixed(parseFloat(this.mInput.value),
                this.mCreationData.toFixed));
            let aStep = parseFloat(OP3DMathUtils.toFixed(this.mParams.step,
                this.mCreationData.toFixed));

            aNewVal = (1 == pSign) ? OP3DMathUtils.nextDividedByN(aValue, aStep) :
                OP3DMathUtils.prevDividedByN(aValue, aStep);

            if (null != this.mParams.mod) {
                aNewVal = (aNewVal % this.mParams.mod);
            }

        } else {
            let aValue = parseFloat(this.mInput.value);
            if (0 == aValue) {
                aNewVal = aValue += pSign;
            } else {
                let aLOG10 = Math.log10(Math.abs(aValue));
                let aExp = (Math.sign(aLOG10) * Math.ceil(Math.abs(aLOG10)));
                if ((aExp < 0) && (((pSign < 0) && (Math.sign(aValue) > 0)) ||
                    ((pSign > 0) && (Math.sign(aValue) < 0)))) {
                    aExp -= 1;
                }

                let aAdd = Math.min(parseFloat(Math.pow(10, aExp).toFixed(Math.abs(aExp))), 1);
                aNewVal = parseFloat((aValue + pSign * aAdd).toFixed(Math.abs(aExp)));
            }
        }

        this.mInput.value = this.mCreationData.isScientific == true ?
            OP3DMathUtils.getScientificValue(aNewVal) :
            aNewVal.toString();

        this._onSetValue();
    }
    //__________________________________________________________________________________________
    private _initCreationData(pData: iNumberInputData) {
        this.mCreationData = {
            isGlobalToFixed: pData.isGlobalToFixed,
            hasArrows: (null != pData.hasArrows) ? pData.hasArrows : true,
            sign: pData.sign,
            unitName: pData.unitName,
            toFixed: (null != pData.toFixed) ? pData.toFixed : 2,
            worldToUnit: (null != pData.worldToUnit) ? pData.worldToUnit : 1,
            triggers: (null != pData.triggers) ? pData.triggers : {},
            unitElementWidth: (null != pData.unitElementWidth) ? pData.unitElementWidth : null
        };
    }
    //__________________________________________________________________________________________
    public set show(pToShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, pToShow);
    }
    //__________________________________________________________________________________________
    public set enable(pVal: boolean) {
        ViewUtils.setElementDisabled(this.mContainer, !pVal);
    }
    //__________________________________________________________________________________________
    public get container() {
        return this.mContainer;
    }
    //__________________________________________________________________________________________
}
