import { EventManager } from "../../../../../oc/events/EventManager";
import { EventsContext } from "../../../../_context/EventsContext";
import { MessagesHandler } from "../../../../_context/MessagesHandler";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { OpticsContext } from "../../../../_context/OpticsContext";
import { iOP3DHTMLInputElement } from "../../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVOGeometry, iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { iMaskAttachments } from "../../../../server/ServerContext";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { Popup } from "../../Popup";
import { UploadApertureImage } from "../_apertures/UploadApertureImage";
import { uoSection } from "../uoSection";

export interface iApertureReturnData {
    geo: iOpticsVOGeometry;
    phase_mask: { url: string, file: File, name: string };
    transmittance_mask: { url: string, file: File, name: string };
}

export class uoUserApertureInfoNew extends uoSection<iOpticsVO, iApertureReturnData> {

    private static SKIN_PATH = './skins/forms/optics/uo_aperture_info.html'
    private static MAX_HALF_SIZE_MM = 1000;
    private static MIN_HALF_SIZE = 0.0001;
    private mTransparencyImage!: UploadApertureImage;
    private mPhaseImage!: UploadApertureImage;
    private mMajorDimensionHalfSize!: iOP3DHTMLInputElement;
    // private mOpticsVO!: iOpticsVO;

    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoUserApertureInfoNew.SKIN_PATH,
            title: 'Aperture Images',
            collapseQaId: "user_aperture_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: true
        });
    }
    //_________________________________________________________________________________
    protected async _onCreationComplete(): Promise<void> {
        super._onCreationComplete();

        let aMajorDimensionUnit = this._getPart("major-dimension-unit");
        aMajorDimensionUnit.innerText = UnitHandler.shortSign;

        this.mMajorDimensionHalfSize = this._getPart("major-dimension", true) as iOP3DHTMLInputElement;
        this.mMajorDimensionHalfSize.addEventListener("change", () => this._onDataChanged());

        let aFirst = this._getPart("single-upload-image");
        let aParent = aFirst.parentElement;
        let aSecond = aFirst.cloneNode(true) as HTMLElement;
        if (aParent != null) {
            aParent.appendChild(aSecond);
        }

        this.mTransparencyImage = new UploadApertureImage({
            upload_btn_qa_id: "transarency_upload_img",
            container: aFirst,
            title: "Transparency image",
            defaultValue: 1,
            onChange: () => this._onDataChanged(),
            defaultBGColor: "#ffffff",

        });

        this.mPhaseImage = new UploadApertureImage({
            upload_btn_qa_id: "phase_upload_img",
            container: aSecond,
            title: "Phase image",
            defaultValue: 0,
            onChange: () => this._onDataChanged(),
            defaultBGColor: "#000000"
        });

        this.mTransparencyImage.secondImage = this.mPhaseImage;
        this.mPhaseImage.secondImage = this.mTransparencyImage;
    }

    //_________________________________________________________________________________
    private _onDataChanged() {

        this.mMajorDimensionHalfSize.value = OP3DMathUtils.toFixed(
            parseFloat(this.mMajorDimensionHalfSize.value),
            Op3dContext.SETUPS_MANAGER.settings.numericAccuracy);




        let aMajorInput = parseFloat(this.mMajorDimensionHalfSize.value);
        if (isNaN(aMajorInput) || aMajorInput < 0) {
            Popup.instance.open({
                text: `${MessagesHandler.INVALID_INPUT}, major dimension is invalid`,
            })
            this.mMajorDimensionHalfSize.value = this.mMajorDimensionHalfSize.prevValue;
        }

        const aScale = UnitHandler.getUnitToWorld(UnitHandler.PRESENTED_UNIT);
        aMajorInput *= aScale;
        if (aMajorInput > uoUserApertureInfoNew.MAX_HALF_SIZE_MM) {
            Popup.instance.open({
                text: `${MessagesHandler.INVALID_INPUT}, max size is ${uoUserApertureInfoNew.MAX_HALF_SIZE_MM}mm`,
            })
            this.mMajorDimensionHalfSize.value = this.mMajorDimensionHalfSize.prevValue;

        } else if (aMajorInput < uoUserApertureInfoNew.MIN_HALF_SIZE) {
            Popup.instance.open({
                text: `${MessagesHandler.INVALID_INPUT}, min size is ${uoUserApertureInfoNew.MIN_HALF_SIZE}mm`,
            })
            this.mMajorDimensionHalfSize.value = this.mMajorDimensionHalfSize.prevValue;
        }

        this.mMajorDimensionHalfSize.prevValue = this.mMajorDimensionHalfSize.value;
        this.mMajorDimensionHalfSize.actualValue = parseFloat(this.mMajorDimensionHalfSize.value);

        EventManager.dispatchEvent(EventsContext.OPTICS_APERTURE_CHANGED, this);
    }
    //_________________________________________________________________________________
    private async _getFileFromURL(pData: { name: string, url: string } | undefined) {
        if (pData !== undefined && pData.url !== undefined && pData.url !== "") {
            const response = await fetch(`${pData.url}?V=${Op3dUtils.idGenerator()}`);
            const aBlob = await response.blob();
            return new File([aBlob], pData.name);
        }
    }
    //_________________________________________________________________________________
    public async fillAttachments(pOpticsVO: iOpticsVO, pMasks: iMaskAttachments, pIsNewInstance: boolean) {
        const aTransmittanceData = this.mTransparencyImage.getData();
        const aPhaseData = this.mPhaseImage.getData();

        if (aPhaseData.file != null) {
            pMasks.phase = aPhaseData.file;
        } else if (pOpticsVO.parameters.physical_data !== undefined && pIsNewInstance) {
            let aFile = await this._getFileFromURL(pOpticsVO.parameters.physical_data.phase_mask);
            pMasks.phase = aFile;
        }

        if (aTransmittanceData.file != null) {
            pMasks.transmittance = aTransmittanceData.file;
        } else if (pOpticsVO.parameters.physical_data !== undefined && pIsNewInstance) {
            let aFile = await this._getFileFromURL(pOpticsVO.parameters.physical_data.transmittance_mask);
            pMasks.transmittance = aFile;
        }

    }
    //_________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO): void {
        if (pOpticsVO.parameters.subType != OpticsContext._User_Aperture) {
            return;
        }

        let aTransmittanceData = this.mTransparencyImage.getData();
        let aPhaseData = this.mPhaseImage.getData();

        let aGeo: iOpticsVOGeometry = {
            width: 0,
            height: 0,
            thickness: pOpticsVO.parameters.geometry.thickness
        }

        const aResolution = aTransmittanceData.resolution != null ? aTransmittanceData.resolution : aPhaseData.resolution;
        try {

            let aMajorInput = this.mMajorDimensionHalfSize.actualValue;
            const aScale = UnitHandler.getUnitToWorld(UnitHandler.PRESENTED_UNIT);

            aMajorInput *= (2 * aScale);
            aGeo.height = Math.min(aResolution.height / aResolution.width, 1) * aMajorInput;
            aGeo.width = Math.min(aResolution.width / aResolution.height, 1) * aMajorInput;

        } catch (e) {
            //do nothing
        }

        if (pOpticsVO.parameters.physical_data === undefined) {
            pOpticsVO.parameters.physical_data = {}
        }
        pOpticsVO.parameters.geometry = aGeo;

        if (aTransmittanceData.file != null) {
            pOpticsVO.parameters.physical_data.transmittance_mask = {
                name: aTransmittanceData.file.name,
                url: ""
            }

        } else if (aTransmittanceData.url != null) {
            pOpticsVO.parameters.physical_data.transmittance_mask = {
                name: aTransmittanceData.name,
                url: aTransmittanceData.url
            }
        }

        if (aPhaseData.file != null) {
            pOpticsVO.parameters.physical_data.phase_mask = {
                name: aPhaseData.file.name,
                url: ""
            }

        } else if (aPhaseData.url != null) {
            pOpticsVO.parameters.physical_data.phase_mask = {
                name: aPhaseData.name,
                url: aPhaseData.url
            }
        }
    }
    //_________________________________________________________________________________
    public clear() {
        this.mPhaseImage.reset();
        this.mTransparencyImage.reset();
    }
    //_________________________________________________________________________________
    public show(pShow: boolean): void {
        super.show(pShow);
        this.clear();
    }
    //_________________________________________________________________________________
    protected async _setData(pOpticsVO: iOpticsVO) {
        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._User_Aperture:
                this.show(true);
                this.expand();
                break;
            default:
                this.show(false);
                return;
        }

        this.mPhaseImage.reset();
        await this.mPhaseImage.set(pOpticsVO.parameters.physical_data.phase_mask);

        this.mTransparencyImage.reset();
        await this.mTransparencyImage.set(pOpticsVO.parameters.physical_data.transmittance_mask);
        const aScale = UnitHandler.getUnitToWorld(UnitHandler.PRESENTED_UNIT)

        let aMaxSize = (Math.max(pOpticsVO.parameters.geometry.width, pOpticsVO.parameters.geometry.height));
        this.mMajorDimensionHalfSize.value = OP3DMathUtils.toFixed((aMaxSize / aScale / 2),
            Op3dContext.SETUPS_MANAGER.settings.numericAccuracy);

        this.mMajorDimensionHalfSize.actualValue = (aMaxSize / aScale / 2);
        this.mMajorDimensionHalfSize.prevValue = this.mMajorDimensionHalfSize.value;
    }
    //_________________________________________________________________________________
}