
//__________________________________________________________________________________________

import { Vector3 } from "three";
import { MessagesHandler } from "../_context/MessagesHandler";
import { iHash } from "../_context/_interfaces/Interfaces";
import { FileUtils } from "../_utils/FileUtils";
import { DataLoader } from "../data/data_loader/DataLoader";
import { Popup } from "../ui/forms/Popup";
import { UnitHandler } from "../units/UnitsHandler";
import { ParserForm, iParserRows } from "./ParserForm";
import { ProgressBar } from "../ui/tools/ProggressBar";

export interface iParserParams<T> {
    /**
     * @description File to parse. Could be in the following formats:
     *      'xlsx', 'csv', 'xls', 'xml', 'ods', 'txt'
     */
    fileList: FileList;

    /**
     * @description If mentioned - this will be the value of the missing cells.
     */
    defval?: any;

    /**
     * @description If true - text will be trimmed.
     */
    trim?: boolean; //Default - true

    /**
     * @description Data loader.
     */
    dataLoader?: DataLoader<any>;

    /**
     * @description Whether to replace existing data on the server. Default - false.
     */
    replaceExist?: boolean;


    /**
     * @description If uploading via parser failed, this function will returns an idetifier for 
     * this item in the file.
     * 
     * Default - returns Object.name
     * 
     */


    /**
     * 
     * @description If provided - an auto build for the file is perform.
     * Each key value will place according to the provided path.
     * @example for building the following object:
     * 
     *  FIRST_NAME  LAST_NAME   ID
     * 
     * person: {
     *      name:{
     *          first:  --FIRST_NAME--,
     *          last:   --LAST_NAME--
     *      },
     *      id: --ID--
     * }
     * 
     * buildForm should be as follows:
     * 
     * {
     *  FIRST_NAME: {path:  name.first},
     *  LAST_NAME:  {path:  name.last},
     *  ID:         {path:  id}
     * }
     * 
     * 
     * @param checkValidationFunc - Function to check the object after auto build is finished.
     * If not provided - the object will not check.
     * 
     * @param errorIdentificationFunc If uploading via parser failed, this function will returns 
     * an idetifier for this item in the file.
     * 
     * Default - returns Object.name
     * 
     */
    build?: {
        form: iHash<iParserBuildForm>;
        checkValidationFunc?: (pData: T, pRow?: iHash<string>) => Promise<string>;
        errorIdentificationFunc?: (pRow?: iHash<string>) => string;
    };
}

export interface iParserBuildForm {
    /**
    * @description path in the object of current key.
     * */
    path: string;

    /**
     * @description isRequired - if a required field does not exist on the parsed row - it 
     * log an error and this specific row will not upload to the server. Default - false.
     */
    isRequired?: boolean;

    /**
     * @description default value for the key if the cell is the cell is empty.
     * If isRequired is true and default is provided, it will fill even if this key not provided
     * at all in the file.
     */
    default?: any;

    /**
     * @description If provided - a manipulation will perform on the value.
     * For custom manipulation - choose eManipulationType.CUSTOM and provide 
     * manipulationFunction.
     */
    manipulationType?: eManipulationType;

    /**
     * Call only if manipulationType is eManipulationType.CUSTOM. If eManipulationType.CUSTOM 
     * and the funtion is not provided - no manipulation will perform on the value.
     */
    manipulationFunction?: (pData: string, pRow?: iHash<string>) => Promise<any>
}


export enum eManipulationType {
    LOWER_CASE,
    UPPER_CASE,
    PARSE_INT,
    PARSE_FLOAT,
    DEG_TO_RAD,
    VECTOR_3,
    SEMI_COLON_PARSE,
    CUSTOM,
    TRIM
}

export interface iLoggerParams {
    msg: string;
    isError?: boolean;
}

export interface iUploadDetails {
    success: number;
    error: number;
    total: number;
    update?: number;
}

export interface iParseRowRet<T> {
    object?: T;
    error?: {
        objectID: string
        msg: string;
    };
}

export class Parser<T> {

    protected mParserParams: iParserParams<T>;
    private mLogger: Array<iLoggerParams>;

    //__________________________________________________________________________________________
    public load(pParserParams: iParserParams<T>) {
        try {
            if (false == this._checkInput(pParserParams)) {
                return;
            }

            this.mParserParams = pParserParams;
            this.mParserParams.replaceExist = (null != pParserParams.replaceExist) ?
                pParserParams.replaceExist : false;

            this.mLogger = new Array<iLoggerParams>();

            if ((null != this.mParserParams.build) &&
                (null == this.mParserParams.build.checkValidationFunc)) {
                this.mParserParams.build.checkValidationFunc = (_pData: T) => { return null; };
            }

            if (null != this.mParserParams.build) {

                if (null == this.mParserParams.build.checkValidationFunc) {
                    this.mParserParams.build.checkValidationFunc = (_pData: T) => null;
                }

                if (null == this.mParserParams.build.errorIdentificationFunc) {
                    this.mParserParams.build.errorIdentificationFunc =
                        (pRow) => 'Name: ' + pRow.name;
                }
            }

            this._loadFiles(pParserParams);

        } catch (error) {
            MessagesHandler.ON_ERROR_PROGRAM(error as any);
        }
    }
    //__________________________________________________________________________________________
    protected async parseRow(pRow: iHash<string>): Promise<iParseRowRet<T>> {
        if ((null == this.mParserParams.build) || (null == this.mParserParams.build.form)) {
            throw new Error('If build.form not provided, you must inherit parseRow function!');
        }

        let aRequiredList = {};

        let aObj: any = {};
        for (let key in pRow) {
            let aBuildKey = key.toLowerCase().trim();
            let aCurrForm = this.mParserParams.build.form[aBuildKey];
            if (null == aCurrForm) {
                continue;
            }

            let aPath = aCurrForm.path;
            if (null == aPath) {
                continue;
            }

            let aValue = pRow[key];

            if (null != aCurrForm.manipulationType) {
                aValue = await this._manipulateValue(aValue, aCurrForm.manipulationType,
                    aCurrForm.manipulationFunction, pRow);
            }

            let aIsRequired = aCurrForm.isRequired;
            if (true == aIsRequired) {
                aRequiredList[aBuildKey] = true;
            }

            if (null == aValue) {
                aValue = aCurrForm.default;
            }

            if (null == aValue) {
                continue;
            }

            this._fillObject(aObj, aPath.split('.'), aValue);
        }

        for (let key in this.mParserParams.build.form) {
            let aCurrForm = this.mParserParams.build.form[key];
            if (null == aCurrForm) {
                continue;
            }

            if ((null != aCurrForm) && (true == aCurrForm.isRequired)) {
                if ((true != aRequiredList[key])) {
                    if (null != aCurrForm.default) {
                        let aPath = aCurrForm.path;
                        this._fillObject(aObj, aPath.split('.'), aCurrForm.default);
                    } else {
                        let aErr = 'Required key - ' + key + ' not mentioned.';
                        return {
                            error: {
                                msg: aErr,
                                objectID: this.mParserParams.build.errorIdentificationFunc(pRow)
                            }
                        };
                    }
                }
            }
        }

        let aInvalidMsg: string = await this.mParserParams.build.checkValidationFunc(aObj, pRow);
        if (null != aInvalidMsg) {
            return {
                error: {
                    msg: aInvalidMsg,
                    objectID: this.mParserParams.build.errorIdentificationFunc(pRow)
                }
            };
        }

        return {
            object: aObj
        };
    }
    //__________________________________________________________________________________________
    private _getVector3(pValue: any) {
        let aValue: string = pValue.replace("[", '');
        aValue = aValue.replace("]", '');

        let aValues = aValue.split(";");
        let aX = parseFloat(aValues[0]);
        let aY = parseFloat(aValues[1]);
        let aZ = parseFloat(aValues[2]);

        if (isNaN(aX) || isNaN(aY) || isNaN(aZ)) {
            throw new Error("Not a valid vector");
        }

        let aVec = new Vector3(aX, aY, aZ);
        return aVec;
    }
    //__________________________________________________________________________________________
    private async _manipulateValue(pValue: any, pManipulationType: eManipulationType,
        pManipulationFunction: (pValue: any, pRow?: iHash<string>) => any,
        pRow: iHash<string>) {

        if (null == pValue) {
            return null;
        }

        let aValue = pValue.toString();
        switch (pManipulationType) {
            case eManipulationType.LOWER_CASE:
                aValue = aValue.toLowerCase();
                break;
            case eManipulationType.UPPER_CASE:
                aValue = aValue.toUpperCase();
                break;
            case eManipulationType.PARSE_INT:
            case eManipulationType.PARSE_FLOAT:
                aValue = this._getNumber(aValue, pManipulationType);
                break;
            case eManipulationType.VECTOR_3:
                aValue = this._getVector3(aValue);
                break;
            case eManipulationType.DEG_TO_RAD:
                aValue = parseFloat(aValue);
                aValue = (true == isNaN(aValue)) ? null :
                    (aValue * UnitHandler.DEG_TO_RAD);
                break;
            case eManipulationType.SEMI_COLON_PARSE:
                aValue = aValue.split(';');
                for (let i = aValue.length - 1; i >= 0; i--) {
                    let aVal = aValue[i];
                    if (null == aVal) {
                        aValue.splice(i, 1);
                    } else {
                        aValue[i] = aVal.trim();
                        if ('' == aValue[i]) {
                            aValue.splice(i, 1);
                        }
                    }
                }
                break;
            case eManipulationType.TRIM:
                aValue = aValue.trim();
                break;
            case eManipulationType.CUSTOM:
                if (null == pManipulationFunction) {
                    return pValue;
                }

                aValue = await pManipulationFunction(aValue, pRow);
        }

        return aValue;
    }
    //__________________________________________________________________________________________
    protected _getNumber(pValue: string, pManipulationType: eManipulationType) {
        let aValue: number;
        switch (pManipulationType) {
            case eManipulationType.PARSE_INT:
                aValue = parseInt(pValue);
                break;
            case eManipulationType.PARSE_FLOAT:
                aValue = parseFloat(pValue);
                break;
            default:
                throw new Error("Not a number.");
        }

        if (true == isNaN(aValue)) {
            aValue = null;
        }

        return aValue;
    }
    //__________________________________________________________________________________________
    private _fillObject(pObject: any, pPath: Array<string>, pValue: any) {
        if (null == pPath) {
            return;
        }

        let aPath0 = pPath.shift();
        if (0 == pPath.length) {
            pObject[aPath0] = pValue;
            return;
        }

        if (null == pObject[aPath0]) {
            pObject[aPath0] = {};
        }

        this._fillObject(pObject[aPath0], pPath, pValue);
    }
    //__________________________________________________________________________________________
    protected _isValidParsedFile(pParsedFile: Array<iParserRows>) {
        if (null == pParsedFile) {
            this._onError('Error parsing the file');
            return false;
        }

        for (let i = 0; i < pParsedFile.length; i++) {
            if (null == pParsedFile[i].rows) {
                this._onError('Error parsing the file');
                return false;
            }
        }

        return true;
    }
    //__________________________________________________________________________________________
    protected async _onUpload(pDetails: iUploadDetails, pFileSectionName: string, pRow: number,
        pUpload: boolean, pID?: string, pErr?: string, pUpdate?: boolean) {
        let aMsg = pFileSectionName + '; Row ' + pRow + ' ';


        if (null == pID) {
            aMsg += 'is invalid.';
            pDetails.error++;
        } else if (true == pUpload) {
            if (pUpdate === true) {
                pDetails.update++;
                aMsg += ', ' + pID + ' updated successfully';
            } else {
                pDetails.success++;
                aMsg += ', ' + pID + ' uploaded successfully';
            }

        } else {
            aMsg += ', ' + pID + ' upload failed. ' + pErr;
            pDetails.error++;
        }

        this.mLogger.push({ msg: aMsg, isError: (false == pUpload) });
        await ProgressBar.instance.increment();
    }
    //__________________________________________________________________________________________
    protected _getTotalRows(pParserRows: Array<iParserRows>) {
        return pParserRows.reduce((prev, current) => prev + current.rows.length, 0);
    }
    //__________________________________________________________________________________________
    protected async _parseData(pParserRows: Array<iParserRows>) {
        try {
            if (false == this._isValidParsedFile(pParserRows)) {
                return;
            }

            // Spinner.instance.hide();
            //GeneralParserForm.instance.setVisibility(false);

            let aTotalRows = this._getTotalRows(pParserRows);

            let aUploadDetails: iUploadDetails = {
                success: 0,
                error: 0,
                total: aTotalRows
            };

            ProgressBar.instance.open({
                title: 'Uploading...',
                min: 0,
                max: (aTotalRows - 1),
                closeOnFinish: true
            });

            for (let i = 0; i < pParserRows.length; i++) {
                let aBasicDetails = '<strong>File name: </strong>';
                aBasicDetails += pParserRows[i].fileName + '<br>';
                aBasicDetails += '<strong>Section name: </strong>';
                aBasicDetails += pParserRows[i].sectionName + '<br>';

                ProgressBar.instance.updateDetails(aBasicDetails);

                let aRows = pParserRows[i].rows;
                let aStartRow = pParserRows[i].start;

                for (let row = 0; row < aRows.length; row++) {
                    let aCurrInfo = '<strong> Current section: </strong>';
                    aCurrInfo += row + '/' + aRows.length + '<br>';
                    aCurrInfo += '<strong>Total: </strong>'
                    aCurrInfo += (aUploadDetails.success + aUploadDetails.error);
                    aCurrInfo += '/ ' + aUploadDetails.total + '<br>';
                    ProgressBar.instance.updateDetails(aBasicDetails + aCurrInfo);

                    let aRow = aRows[row];
                    let aParsedData = await this.parseRow(aRow);
                    let aRowNum = (aStartRow + row + 2);

                    if (null == aParsedData) {
                        this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false);
                        continue;
                    }

                    if (null == aParsedData.object) {
                        this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false,
                            aParsedData.error.objectID, aParsedData.error.msg);
                        continue;
                    }

                    let aName = aParsedData.object['name'];
                    if (false == this.mParserParams.replaceExist) {
                        let aIsExist = await this.mParserParams.dataLoader.isExist(aParsedData);
                        if (true == aIsExist) {
                            this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false, aName,
                                'Item already exist');
                            continue;
                        }
                    }

                    aParsedData.object['toReplace'] = this.mParserParams.replaceExist;

                    let aRes = await this.mParserParams.dataLoader.add(aParsedData.object,
                        false);
                    let aIsAdded = (null != aRes);
                    this._onUpload(aUploadDetails, aBasicDetails, aRowNum, aIsAdded,
                        aName, 'Server Error');
                }
            }

            ProgressBar.instance.close();
            this._printLog(aUploadDetails);

        } catch (error) {
            ProgressBar.instance.close();
            MessagesHandler.ON_ERROR_PROGRAM(error as any);
        }
    }
    //__________________________________________________________________________________________
    protected _printLog(pUploadDetails: iUploadDetails) {
        let aLog = '';
        console.log('----------------------------------------------------------------');
        for (let i = 0; i < this.mLogger.length; i++) {
            let aMsg = this.mLogger[i].msg.slice();
            aMsg = aMsg.replaceAll('<strong>', '');
            aMsg = aMsg.replaceAll('</strong>', '');
            aMsg = aMsg.replaceAll('<br>', '');

            if (true == this.mLogger[i].isError) {


                aMsg += '\n';
                aLog += aMsg;
                ///  console.log('%c' + aMsg, aCss);
                console.log(aMsg);
            } else {
                console.log(aMsg);
            }
        }

        if ('' != aLog) {
            FileUtils.downloadFile({
                content: aLog,
                mimeType: 'text/plain',
                fullfileName: 'error_log.txt'
            });
        }

        let aUpdated = pUploadDetails.update
        let aSummery = "Total Rows: " + pUploadDetails.total + '\n';
        aSummery += "Total Succeeded: " + pUploadDetails.success + (aUpdated > 0 ? ' (Updated:' + aUpdated + ")" : '') + '\n';
        aSummery += "Total Failed: " + pUploadDetails.error + '\n';
        console.log('----------------------------------------------------------------');
        console.log(aSummery);
        console.log('----------------------------------------------------------------');

        let aMsg = aSummery + '\n\n';
        aMsg += 'For more details, go to the browser console.';
        if ('' != aLog) {
            aMsg += '\n See error_log.txt file for errors.';
        }

        Popup.instance.open({ text: aMsg });
    }
    //__________________________________________________________________________________________
    protected _log(pLoggerParams: iLoggerParams) {
        this.mLogger.push(pLoggerParams);
    }
    //__________________________________________________________________________________________
    private _checkInput(pParserParams: iParserParams<T>) {
        try {
            if (null == pParserParams.fileList) {
                throw new Error('No file');
            }

            let aAcceptableFileTypes = ['xlsx', 'csv', 'xls', 'xml', 'ods', 'txt'];
            for (let i = 0; i < pParserParams.fileList.length; i++) {
                let aFileName = pParserParams.fileList[i].name;
                let aFileType = aFileName.split('.').pop();
                if (aAcceptableFileTypes.indexOf(aFileType) == -1) {
                    this._onError('Invalid File');
                    return false;
                }
            }

            if (null != pParserParams.build) {
                if (null == pParserParams.build.form) {
                    throw ('form does not provided');
                }

                for (let key in pParserParams.build.form) {
                    if (key != key.toLowerCase().trim()) {
                        throw ('All keys in form must in lower case and without spaces.');
                    }
                }
            }

            return true;

        } catch (error) {
            MessagesHandler.ON_ERROR_PROGRAM(error as any);
        }
    }
    //__________________________________________________________________________________________
    private _loadFiles(pParserParams: iParserParams<T>): void {
        let aNumOfFiles = pParserParams.fileList.length;
        let aFiles: iHash<{ fileName: string; data: string }> = {};
        for (let i = 0; i < aNumOfFiles; i++) {
            let aReader = new FileReader();
            let aFile = pParserParams.fileList[i];
            let aName = aFile.name;

            aReader.onload = () => {
                aFiles[i] = {
                    fileName: aName,
                    data: aReader.result as string
                };

                if (aNumOfFiles == Object.values(aFiles).length) {
                    this._onReadingFiles(aFiles);
                }
            }

            aReader.onerror = () => this._onError('Error reading file');
            aReader.readAsBinaryString(aFile);
        }

        //this._onReadingFiles((aReader.result as string));
    }
    //__________________________________________________________________________________________
    private _onReadingFiles(pFilesData: iHash<{ fileName: string; data: string }>) {
        // var workbook = XLSX.read(pFilesData, {
        //     type: 'binary', sheetStubs: true
        // });

        let aWorkbooks = new Array<{
            workbook: XLSX.WorkBook;
            fileName: string;
        }>();

        for (let key in pFilesData) {
            aWorkbooks.push({
                fileName: pFilesData[key].fileName,
                workbook: XLSX.read(pFilesData[key].data, {
                    type: 'binary', sheetStubs: true,

                })
            });
        }

        ParserForm.instance.open({
            workbooks: aWorkbooks,
            callback: (pData) => this._parseData(pData),
            defval: this.mParserParams.defval,
            trim: this.mParserParams.trim
        });
        /*
                    let aSections: iHash = {};
                    for (let i = 0; i < workbook.SheetNames.length; i++) {
                        let aSheetName = workbook.SheetNames[i];
                        let aRows = XLSX.utils.sheet_to_json<iHash<string>>(workbook.Sheets[aSheetName],
                            {
                                blankrows: true,
                                defval: this.mParserParams.defval
                            });
        
                        if (null == aRows) {
                            this._onError('Error reading file');
                            return;
                        }
        
        
        
                        if (false != this.mParserParams.trim) {
                            aRows = this._trim(aRows);
                        }
        
                        aSections[workbook.SheetNames[i]] = aRows;
                    }
        
                    this._parseData(aSections);
                    */
    }
    //__________________________________________________________________________________________

    private _onError(_pMsg: string) {
        //data.context.OpContext.divManager.showPopupNew({ visible: true, text: pMsg });
    }
    //__________________________________________________________________________________________
}
