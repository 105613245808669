import { iAsyncCallback, iCoatingVO } from "../_context/_interfaces/Interfaces";
import { FileUtils } from "../_utils/FileUtils";
import { eErrorMessageType } from "../_context/Enums";
import { iCoatingRow } from "./AdminCoatingParser";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { Op3dContext } from "../_context/Op3dContext";

export class CoatingParser {

    private mTitlesHash: {
        wl: number,
        t_p: number,
        t_s: number,
        r_p: number,
        r_s: number,
    };

    public async parse(pFile: File) {
        return await this._loadFile(pFile);
    }
    //____________________________________________________________________
    private async _loadFile(pFile: File): Promise<iAsyncCallback<eErrorMessageType | iCoatingVO>> {


        let aResult = await FileUtils.loadFileXlsx(pFile);
        if (aResult.success == true) {

            try {

                let aRows = aResult.data as any;
                return await this._parseData(pFile.name, aRows);

            } catch (e) {
                return ({ success: false, data: eErrorMessageType.FILE_READING_FAILED })
            }

        } else {
            return ({ success: false, data: eErrorMessageType.FILE_READING_FAILED })

        }
    }
    //____________________________________________________________________
    // private _getFloat(pData: any) {
    //     if (isNaN(parseFloat(pData)) == false) {
    //         return parseFloat(pData);
    //     }
    //     return null;
    // }
    //____________________________________________________________________
    private async _parseRow(pRow: Array<number>)
        : Promise<iAsyncCallback<eErrorMessageType | iCoatingRow>> {
        // const aParserParams = {
        //     'wl': {
        //         path: 'wl',
        //         getValue: this._getFloat,
        //         isRequired: true,
        //     },

        //     't_p': {
        //         path: 'T_P',
        //         getValue: this._getFloat,
        //     },

        //     'r_p': {
        //         path: 'R_P',
        //         getValue: this._getFloat,
        //     },

        //     't_s': {
        //         path: 'T_S',
        //         getValue: this._getFloat,
        //     },

        //     'r_s': {
        //         path: 'R_S',
        //         getValue: this._getFloat,
        //     }
        // }

        var aCoatingRow = <iCoatingRow>{};
        //for (let i=0;i<pRow.length;i++) {
        aCoatingRow.T_P = pRow[this.mTitlesHash.t_p];
        aCoatingRow.R_P = pRow[this.mTitlesHash.r_p];
        aCoatingRow.T_S = pRow[this.mTitlesHash.t_s];
        aCoatingRow.R_S = pRow[this.mTitlesHash.r_s];
        aCoatingRow.wl = pRow[this.mTitlesHash.wl];
        // let aKey = key.toLocaleLowerCase().trim();
        // if (aParserParams[aKey]) {
        //     let aParam = aParserParams[aKey];
        //     let aValue = aParam.getValue(pRow[key]);
        //     aCoatingRow[aParam.path] = aValue;
        // }
        //}


        // for (let key in aParserParams) {
        //     let aParam = aParserParams[key];
        //     if (aParam.isRequired === true) {

        //         if (Object.keys(aCoatingRow).length > 0 && aCoatingRow[aParam.path] === undefined) {
        //             return ({ success: false, data: eErrorMessageType.MISSING_COLUMNS });
        //         }
        //     }
        // }
        return ({ success: true, data: aCoatingRow });
    }
    //____________________________________________________________________
    private async _parseData(pFileName: string, pParsedFile: { data: Array<Array<number>> })
        : Promise<iAsyncCallback<iCoatingVO | eErrorMessageType>> {

        let aStart = 1;
        let aEnd = pParsedFile.data.length;
        let row = 0;


        let aTitles = pParsedFile.data[0] as Array<any>;
        this.mTitlesHash = {
            t_p: aTitles.indexOf("t_p"),
            t_s: aTitles.indexOf("t_s"),
            r_p: aTitles.indexOf("r_p"),
            r_s: aTitles.indexOf("r_s"),
            wl: aTitles.indexOf("wl"),
        }

        let aBadIndex = Object.values(this.mTitlesHash).findIndex(item => item == -1);
        if (aBadIndex !== -1) {
            return ({ success: false, data: eErrorMessageType.MISSING_COLUMNS });
        }

        try {

            var aCoating: iCoatingVO = {
                wl: [],
                T_P: [],
                R_P: [],
                T_S: [],
                R_S: [],
                number_id: Op3dUtils.idGenerator(true),
                name: pFileName,
                owner: null,
                perElement: false
            };

            let aPrev;
            for (row = aStart; row < aEnd; row++) {
                let aRow = pParsedFile.data[row];
                if (Object.keys(aRow).length == 0) {
                    continue;
                }

                let aCurr = await this._parseRow(aRow);
                if (aCurr.success == false) {
                    return ({ success: false, data: eErrorMessageType.ERROR_IN_ROWS });
                }

                let aRowItem = aCurr.data as iCoatingRow;
                if (aPrev) {
                    // check if current row is the same as previous, 
                    // we don't save two identical rows
                    if ((row !== (aEnd - 1)) &&
                        (aPrev.T_P === aRowItem.T_P) &&
                        (aPrev.R_P === aRowItem.R_P) &&
                        (aPrev.T_S === aRowItem.T_S) &&
                        (aPrev.R_S === aRowItem.R_S)) {
                        continue;
                    }
                }

                aCoating.wl.push(aRowItem.wl);
                aCoating.T_P.push(aRowItem.T_P);
                aCoating.R_P.push(aRowItem.R_P);
                aCoating.T_S.push(aRowItem.T_S);
                aCoating.R_S.push(aRowItem.R_S);
                aPrev = aCurr;
            }

            let aFirst = aCoating.wl[0];
            let aLast = aCoating.wl[aCoating.wl.length - 1];
            if (aFirst > aLast) {
                return ({ success: false, data: eErrorMessageType.WL_DESCENDING_ORDER });
            }

            return ({ success: true, data: aCoating });

        } catch (error) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(error);
        }
        return ({ success: false, data: eErrorMessageType.ERROR_IN_ROWS });
    }
    //____________________________________________________________________
}
