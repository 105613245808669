export class ParserContext {

    public static addSpecialCharachters(pName: string) {
        let aNewName = pName;
        aNewName = aNewName.trim();
        aNewName = aNewName.replace('  ', ' ');

        for (let code in ParserContext.PARSER_HASH) {
            if (aNewName.indexOf(code) != -1) {
                let aValToReplace: string = ParserContext.PARSER_HASH[code].toString()
                aNewName = aNewName.replace(code.toString(), aValToReplace)
            }
        }

        return aNewName;
    }
    public static deleteSpecialCharachters(pName: string) {
        let aNewName = pName;
        aNewName = aNewName.trim();
        aNewName = aNewName.replace('  ', ' ');

        for (let code in ParserContext.PARSER_HASH) {
            if (aNewName.indexOf(code) != -1) {
                aNewName = aNewName.replace(code.toString(), '')
            }
        }
        if (aNewName.includes('in') === true) {
            return parseFloat(aNewName) * 25.4;

        } else {
            return parseFloat(aNewName);
        }

    }

    public static SPECIAL_CHARACTERS = {
        micron: "μm",
        linesToMM: "lines/mm",
        feet: "'",
        k_sign: "K",
        mrad: "mRad",
        degree: "°",
        percent: "%",
        inch: "″",
        nm: "nm",
        mm: "mm",
        phi: "Φ",
        phi_small: "φ",
        theta_small: 'ϑ',
        theta: "Θ",
        psi_big: "𝝍",
        plus_minus: "±",
        dia: "ø",
        lambda: "λ",
        mu: "µ",
        less_then: "<",
        less_then_equal: "≤",
        greater_then: ">",
        greater_then_equal: "≥",
        alpha: "α",
        beta: "β"
    }

    public static PARSER_HASH = {
        "#ft#": this.SPECIAL_CHARACTERS.feet,
        "#lam#": this.SPECIAL_CHARACTERS.lambda,
        "#dia#": this.SPECIAL_CHARACTERS.dia,
        "#pm#": this.SPECIAL_CHARACTERS.plus_minus,
        "#deg#": this.SPECIAL_CHARACTERS.degree,
        "#phi#": this.SPECIAL_CHARACTERS.phi,
        "#theta#": this.SPECIAL_CHARACTERS.theta,
        "#psi#": this.SPECIAL_CHARACTERS.psi_big,
        "#in#": this.SPECIAL_CHARACTERS.inch,
        "#mu#": this.SPECIAL_CHARACTERS.mu,
        "#lt#": this.SPECIAL_CHARACTERS.less_then,
        "#lte#": this.SPECIAL_CHARACTERS.less_then_equal,
        "#gt#": this.SPECIAL_CHARACTERS.greater_then,
        "#gte#": this.SPECIAL_CHARACTERS.greater_then_equal
    };

    public static PARSER_COLORS = {
        blue: '#23A7De',
        red: '#fe5c5c',
        green: '#00cc66',
        yellow: '#ffa500'
    };

}
