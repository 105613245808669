import {
    Matrix4, Vector3, Euler, BufferGeometry,
    Float32BufferAttribute, Mesh, LineSegments, Object3D
} from "three";
import { eAxisType, eDataPermission, eLoadingState } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iGeneralIngrediantVOQuery, iHash, iRGBA, t3DArray } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { PartsCatalog } from "../../parts/_parts_assets/PartsCatalog";
import { PartsFactory } from "../../parts/_parts_assets/PartsFactory";
import { eMeshType } from "../../parts/_parts_assets/PartsManager";
import { SceneContext } from "../../scene/SceneContext";
import { ServerContext } from "../../server/ServerContext";
import { eSmRaysKind, eSmPlaneWaveType } from "../../simulation/SimulationContext";
import { Menu } from "../../ui/home/Menu";
import { eCacheDataType } from "../CacheStorage";
import { DataLoader } from "./DataLoader";
import { PartVO } from "../VO/PartVO";
import { OptixReader } from "../../_utils/OptixReader";
import { LaserBehavior } from "../../parts/behaviors/LaserBehavior";
import { eCountType, eWavelengthDistributionType } from "../../parts/behaviors/LightSourceContext";
import { eChangesType, eGroupType, iAssemblyMongoDB, iAxis, iBasicTranslation, iCageAxis, iColorChange, iEdge, iFaceDataNEW, iLaserAxis, iOpticsAxis, iOptixData, iPart, iPartChange, iPartMongoDB } from "../../parts/PartInterfaces";
import { createBlackBoxDevice } from "../../parts/BlackBox/utils/BlackBoxUtils";

export type tDynamicCreationIds = "R1111" | "D1012" | "L1111" | "L1112"

export class PartsDataLoader extends DataLoader<iPartMongoDB,
    iGeneralIngrediantVOQuery,
    iPartMongoDB, iPart[]> {

    private static INSTANCE: PartsDataLoader;

    private mParsedPartsHash: iHash<iOptixData | eLoadingState> = {};

    //__________________________________________________________________________________________
    private constructor() {
        super({

            fullDataServerCall: (pQuery: iGeneralIngrediantVOQuery) =>
                ServerContext.SERVER.getPartById({
                    number_id: pQuery.number_id
                }),

            cacheData: {
                key: 'number_id',
                type: eCacheDataType.PARTS,
                saveConvertedData: false
            },
            addItemServerCall: (pData) =>
                ServerContext.SERVER.addPart(pData),
            dataConvertionFunc: (pData) =>
                this._onGettingPartMongoDB(pData),
            getAll: () =>
                ServerContext.SERVER.getParts(),
        });

    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public distract() {
        for (let key in this.mParsedPartsHash) {
            delete this.mParsedPartsHash[key];
        }
    }
    //__________________________________________________________________________________________
    protected _onRemoveData(pPartMongoDB: iPartMongoDB) {
        if (pPartMongoDB == null) return
        delete this.mParsedPartsHash[pPartMongoDB.url];
    }
    //__________________________________________________________________________________________
    private async _onGettingPartMongoDB(pPartMongoDB: iPartMongoDB) {
        if (null == pPartMongoDB) {
            return null;
        }

        let aParts = new Array<iPart>();
        if (true == pPartMongoDB.info.isDynamicPart) {
            return this._getDynamicPart(pPartMongoDB.info.id);
        }

        if (true == pPartMongoDB.info.isBlackBox) {
            Op3dContext.DATA_MANAGER.addToPartsData(pPartMongoDB);

            let aSize = pPartMongoDB.black_box_data.shape_data.size;
            let aName = pPartMongoDB.name;
            let aColor = pPartMongoDB.black_box_data.shape_data.color;

            return [createBlackBoxDevice(aSize, aName, aColor)]
        }

        let aIsAssembly = (null != pPartMongoDB.assembly_parts);
        if (true == aIsAssembly) {
            let aAssemblyParts = JSON.parse(pPartMongoDB.assembly_parts) as Array<iAssemblyMongoDB>;
            for (let i = 0; i < aAssemblyParts.length; i++) {
                let aPartMongoDB = await this.getSingleFullData({
                    number_id: aAssemblyParts[i].number_id
                })

                aPartMongoDB.forEach((part) => {
                    part.object3D.applyMatrix4(aAssemblyParts[i].matrix);
                })

                aParts.push(...aPartMongoDB);

            }
        } else {
            let aOpticalData = pPartMongoDB.info.opticalData;
            let aPredefinedColor: iRGBA;
            if ((undefined !== aOpticalData) && (true === aOpticalData.use3DOptixColor)) {
                let aGray = (250 / 255);
                aPredefinedColor = { r: aGray, g: aGray, b: aGray, a: 0.7 };
            }
            let aParsedFile = await this.getOneOptix(pPartMongoDB.info.url, aPredefinedColor);

            if (aParsedFile === undefined) {
                return
            }

            let aOpticsAxis: iOpticsAxis;
            let aMatrix4: Matrix4;
            let aLaserAxis: iLaserAxis;
            let aCageAxis: iCageAxis;
            if (undefined !== pPartMongoDB.changes) {
                let aChanges = JSON.parse(pPartMongoDB.changes) as Array<iPartChange>;
                for (let i = 0; i < aChanges.length; i++) {
                    switch (aChanges[i].type) {
                        case eChangesType.BASIC_TRANSLATION:
                            aMatrix4 = this._onTranslation(aParsedFile, aChanges[i].data);
                            break;
                        case eChangesType.COLOR:
                            this._onChangeFacesColor(aParsedFile, aChanges[i].data);
                            break;
                        case eChangesType.OPTICS_AXIS:
                            aOpticsAxis = aChanges[i].data;
                            break;
                        case eChangesType.LASER_AXIS:
                            aLaserAxis = aChanges[i].data;
                            break;
                        case eChangesType.CAGE_AXIS:
                            aCageAxis = aChanges[i].data;
                            break;
                    }
                }
            };

            let aGeneralVO = pPartMongoDB.info;
            aGeneralVO.permission = pPartMongoDB.permission;
            aGeneralVO.number_id = pPartMongoDB.number_id;
            aGeneralVO.owner = pPartMongoDB.owner;
            aGeneralVO.permission = pPartMongoDB.permission;
            aGeneralVO.isOptix = true;
            aGeneralVO.translationMatrix = aMatrix4;

            if ((undefined !== aOpticalData) && (undefined !== aOpticalData.type)) {
                aGeneralVO.opticalPartType = pPartMongoDB.info.opticalData.type;
                aGeneralVO.opticalData = pPartMongoDB.info.opticalData;
            }

            Op3dContext.DATA_MANAGER.addToPartsData(pPartMongoDB);
            let aPart = this._createPartFromOptix(aParsedFile, pPartMongoDB.info.id,
                pPartMongoDB.info.url);
            aParsedFile = undefined
            pPartMongoDB.info.number_id = pPartMongoDB.number_id;
            aPart.number_id = pPartMongoDB.number_id;

            /**
             * This iterator works only if the part is optomechanics lens. 
             * Changes the existing (default) faces information to the changed information that user saved.
             */

            if (aGeneralVO.permission === eDataPermission.PRIVATE && aGeneralVO?.opticalData?.faces !== undefined) {
                for (let i = 0; i < aGeneralVO.opticalData.faces.length; i++) {
                    let aPartFace = aPart.shapes[0].solids[0].faces[0].indexes.find(item => JSON.stringify(item.path) == JSON.stringify(aGeneralVO.opticalData.faces[i].path));
                    if (aPartFace != null) {
                        aPartFace.name = aGeneralVO.opticalData.faces[i].name;
                        if (aGeneralVO.opticalData.faces[i].data != null) {
                            aPartFace['data'] = aGeneralVO.opticalData.faces[i].data;
                        }
                    }
                }
            }


            aPart.partVO = new PartVO(aGeneralVO);

            if (null != aOpticsAxis) {
                let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
                aPart.object3D.add(aPartAxis)

                aPartAxis.rotation.copy(aOpticsAxis.rotation);
                aPartAxis.position.copy(aOpticsAxis.position);

                if (null == aPart.axes) {
                    aPart.axes = [];
                }
                aPart.axes.push({
                    object3D: aPartAxis,
                    internal_id: Op3dUtils.idGenerator(),
                    type: eAxisType.OPTICS,
                    radius: aOpticsAxis.radius,
                    length: aOpticsAxis.length,
                    face: aOpticsAxis.face,
                    shape: aOpticsAxis.shape
                })

            }

            if (null != aLaserAxis) {
                let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
                aPart.object3D.add(aPartAxis)

                aPartAxis.rotation.copy(aLaserAxis.rotation);
                aPartAxis.position.copy(aLaserAxis.position);

                if (null == aPart.axes) {
                    aPart.axes = [];
                }
                aPart.axes.push({
                    object3D: aPartAxis,
                    internal_id: Op3dUtils.idGenerator(),
                    type: eAxisType.LASER
                });
            }

            if (null != aCageAxis) {
                let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
                aPart.object3D.add(aPartAxis)

                aPartAxis.rotation.copy(aCageAxis.rotation);
                aPartAxis.position.copy(aCageAxis.position);

                if (null == aPart.axes) {
                    aPart.axes = [];
                }
                aPart.axes.push({
                    object3D: aPartAxis,
                    internal_id: Op3dUtils.idGenerator(),
                    type: eAxisType.CAGE
                });

            }

            aParts.push(aPart);
        }


        return aParts;
    }
    //__________________________________________________________________________________________
    private _onChangeFacesColor(pParsedPart: iOptixData, pChange: iColorChange) {
        let aColor = pChange.color;
        let l = aColor.length;
        for (let i = pChange.start, end = pChange.end; i <= end; i++) {
            for (let j = 0; j < l; j++) {
                pParsedPart.facesColor[((4 * i) + j)] = aColor[j];
            }
        }
    }
    //__________________________________________________________________________________________
    private _onTranslation(pParsedPart: iOptixData, pChange: iBasicTranslation) {

        let aFaces = pParsedPart.faces;
        let aTranslatedFaces = new Array<number>();
        let aDeltaPos = new Vector3().copy(pChange.deltaPos);

        let aRot = new Euler();
        if (null != pChange.rot) {
            aRot.copy(pChange.rot);
            aRot.y = aRot.y * -1
        }

        let aMatrix4 = new Matrix4().makeRotationFromEuler(aRot);

        for (let i = 0; i < aFaces.length; i += 3) {
            let aVec = new Vector3(aFaces[i], aFaces[i + 1], aFaces[i + 2]);
            aVec.sub(aDeltaPos);
            aVec.applyMatrix4(aMatrix4);
            aTranslatedFaces.push(aVec.x, aVec.y, aVec.z);
        }
        pParsedPart.faces = aTranslatedFaces;

        let aEdges = pParsedPart.edges;
        let aTranslatedEdges = new Array<Vector3>();
        for (let i = 0; i < aEdges.length; i++) {
            let aVec = new Vector3().copy(aEdges[i]);
            aVec.sub(aDeltaPos);
            aVec.applyMatrix4(aMatrix4);
            aTranslatedEdges.push(aVec);
        }
        pParsedPart.edges = aTranslatedEdges;

        for (let i = 0; i < pParsedPart.edgesData.length; i++) {
            let aData = pParsedPart.edgesData[i].data;
            if (null != aData) {
                if (null != aData.startPoint) {
                    let aPoint = new Vector3().fromArray(aData.startPoint);
                    aPoint.sub(aDeltaPos);
                    aPoint.applyMatrix4(aMatrix4);
                    let aNewPoint = aPoint.toArray() as t3DArray<number>;
                    pParsedPart.edgesData[i].data.startPoint = aNewPoint;
                }
                if (null != aData.endPoint) {
                    let aPoint = new Vector3().fromArray(aData.endPoint);
                    aPoint.sub(aDeltaPos);
                    aPoint.applyMatrix4(aMatrix4);
                    let aNewPoint = aPoint.toArray() as t3DArray<number>;
                    pParsedPart.edgesData[i].data.endPoint = aNewPoint;
                }
                if (null != aData.center) {
                    let aPoint = new Vector3().fromArray(aData.center);
                    aPoint.sub(aDeltaPos);
                    aPoint.applyMatrix4(aMatrix4);
                    let aNewPoint = aPoint.toArray() as t3DArray<number>;
                    pParsedPart.edgesData[i].data.center = aNewPoint;
                }
            }
        }

        let aPos = aDeltaPos.multiplyScalar(-1).applyMatrix4(aMatrix4);
        aMatrix4.setPosition(aPos);
        return aMatrix4;
    }
    //__________________________________________________________________________________________
    private async getOneOptix(pURL: string, pPreDefinedNormalizedColor?: iRGBA) {
        let aURL = ServerContext.part_prefix + pURL + '.optix';
        if (pURL.includes('http')) {
            aURL = pURL
        }

        if (undefined === this.mParsedPartsHash[pURL]) {
            this.mParsedPartsHash[pURL] = eLoadingState.LOADING;
            let aRes = await fetch(aURL);
            let aOptixFile = await aRes.arrayBuffer();

            if (null == aOptixFile) {
                throw new Error("No file");
            }
            try {
                let aParsedFile = new OptixReader().read(aOptixFile, pPreDefinedNormalizedColor);
                this.mParsedPartsHash[pURL] = aParsedFile;

            } catch (e) {
                throw e
            }

        } else if (eLoadingState.LOADING === this.mParsedPartsHash[pURL]) {
            await Op3dContext.wait(() => (eLoadingState.LOADING !== this.mParsedPartsHash[pURL]));
        }

        return DataUtils.getObjectCopy(this.mParsedPartsHash[pURL] as iOptixData);
    }
    //__________________________________________________________________________________________
    private _createPartFromOptix(pPartData: iOptixData, pID?: string, _pUrl?: string) {
        let aPartOptixData = pPartData as iOptixData;

        let aFaceMesh = this._createFaceMesh(aPartOptixData.faces, aPartOptixData.facesColor);
        let aEdgeMesh = this._createEdgeMesh(aPartOptixData.edges, aPartOptixData.edgesColor);
        let aPart = this._combineToObject3D(pID, aFaceMesh, aPartOptixData.facesData,
            aEdgeMesh, aPartOptixData.edgesData);

        return aPart;
    }
    //__________________________________________________________________________________________
    private _createFaceMesh(pPosBuffer: Array<number>, pColorBuffer: Array<number>) {
        let aFaceGeo = new BufferGeometry()
        aFaceGeo.setAttribute('position', new Float32BufferAttribute(pPosBuffer, 3));
        aFaceGeo.setAttribute('color', new Float32BufferAttribute(pColorBuffer, 4));
        aFaceGeo.setAttribute('colorBase', new Float32BufferAttribute(pColorBuffer, 4));
        aFaceGeo.computeVertexNormals();


        let aFaceMesh = new Mesh(aFaceGeo, Op3dContext.GLOBAL_MATERIAL);
        (aFaceMesh as any).type = eMeshType.FACE

        return aFaceMesh
    }
    //__________________________________________________________________________________________
    private _createEdgeMesh(pPosBuffer: Array<Vector3>, pColorBuffer: Array<number>) {
        let aEdgesGeo = new BufferGeometry()
        aEdgesGeo.setFromPoints(pPosBuffer)
        aEdgesGeo.setAttribute('color', new Float32BufferAttribute(pColorBuffer, 4));
        aEdgesGeo.setAttribute('colorBase', new Float32BufferAttribute(pColorBuffer, 4));
        let aEdgesMesh = new LineSegments(aEdgesGeo, Op3dContext.GLOBAL_LINE_MATERIAL);
        (aEdgesMesh as any).type = eMeshType.EDGE

        aEdgesMesh.computeLineDistances();
        return aEdgesMesh
    }
    //__________________________________________________________________________________________
    private _combineToObject3D(pID: string, pFacesMesh: Mesh,
        pFacesData: Array<iFaceDataNEW>, pEdgesMesh: LineSegments,
        pEdgesData: Array<iEdge>) {
        pEdgesMesh.visible = Menu.instance.isEdgesShown()

        let aPartObject3D = new Object3D();
        let aShapeObject3D = new Object3D();
        aPartObject3D.add(aShapeObject3D);
        let aSolidObject3D = new Object3D();
        aShapeObject3D.add(aSolidObject3D);

        aSolidObject3D.add(pFacesMesh);

        aSolidObject3D.add(pEdgesMesh);

        let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
        aPartObject3D.add(aPartAxis);


        let aPart: iPart = {
            name: pID,
            axes: [{
                internal_id: Op3dUtils.idGenerator(),
                object3D: aPartAxis,
                type: eAxisType.GENERAL
            }],
            data: {
                id: '',
            },
            facesMesh: pFacesMesh,
            internal_id: Op3dUtils.idGenerator(),
            object3D: aPartObject3D,
            shapes: [{
                internal_id: Op3dUtils.idGenerator(),
                object3D: aShapeObject3D,
                edgesMesh: pEdgesMesh,
                solids: [{
                    internal_id: Op3dUtils.idGenerator(),
                    object3D: aSolidObject3D,
                    faces: [{
                        internal_id: Op3dUtils.idGenerator(),
                        visualization: {
                            mesh: pFacesMesh
                        },
                        indexes: pFacesData
                    }]
                }],
                edges: pEdgesData,
            }]
        };
        return aPart;
    }
    //__________________________________________________________________________________________
    private _getDynamicPart(pID: string) {
        switch (pID as tDynamicCreationIds) {
            case "R1111": {
                let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
                let aIPart: iPart = {
                    object3D: new Object3D(),
                    internal_id: Op3dUtils.idGenerator(),
                    axes: [{
                        object3D: aPartAxis,
                        type: eAxisType.LASER
                    }]
                }
                aIPart.object3D.add(aPartAxis);
                return [aIPart];
            }
            case "L1111":
            case "L1112":
                let aLaserFaces = PartsFactory.getLaserFaces({
                    arrayOfSourcesData: { is_array: false, group_type: eGroupType.REGULAR },
                    count_type: eCountType.TOTAL,
                    alpha: 1,
                    model_radius: 0,
                    color: '#000',
                    rays_color: '#91ff00',
                    kind: eSmRaysKind.PLANE_WAVE,
                    shape: eSmPlaneWaveType.CIRCULAR,
                    sourceGeometricalData:
                        LaserBehavior.DEFAULT_CIRCULAR_PROFILE_GEO,
                    wavelengthData: null,
                    distribution_data: {
                        type: eWavelengthDistributionType.USER_DEFINED
                    },
                    light_source_number_id: null,
                    polarization: null,
                    power: null,
                    directionalData: null,
                });

                let aIPart = PartsFactory.getPartFromFaces({
                    faces: aLaserFaces,
                    number_id: null,
                    id: null,
                    iPartName: Strings.USER_DEFINED_LIGHT_SOURCE
                });

                let aAxis: iAxis = {
                    internal_id: Op3dUtils.idGenerator(),
                    type: eAxisType.LASER,
                    object3D: SceneContext.OP3D_SCENE.getAxisModel(false),
                }

                aIPart.axes.push(aAxis);
                aIPart.object3D.add(aAxis.object3D);

                return [aIPart];

            case "D1012":
                let aData = DataUtils.getObjectCopy(PartsCatalog.D1012_DATA);
                let aDetector = PartsFactory.getDetectorElement(aData, pID);
                return aDetector;
        }
    }
    //__________________________________________________________________________________________
}