import { EventManager } from "../../../../oc/events/EventManager";
import { eCadType } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iCadOpticalData } from "../../../_context/_interfaces/Interfaces";
import { iOpticsVOGeometry } from "../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../parts/Part";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Spinner } from "../../home/Spinner";
import { Popup } from "../Popup";
import { Optics3DPresenter } from "../tools/Optics3DPresenter";
import { esOptomechColorInfo } from "./esOptomechColorInfo";
import { uoMaterial, iuoMaterial } from "./uoMaterial";
import { uoSolids } from "./uoSolids";
import { uoSurfaces } from "./uoSurfaces";

export interface iOptomechanicsOpticsFormSections {
    material: uoMaterial;
    colorInfo: esOptomechColorInfo;
    surfacesInfo: uoSurfaces;
    solidsInfo?: uoSolids;
};

export interface iOpticsCreateParams {
    name: string;
    geometry: iOpticsVOGeometry;
    materialID: string;
};

export interface iOptomechanicsOpticsFormParams {
    part: Part;
    callback?: (pOptOpticsData: iCadOpticalData) => void;
};

export class OptomechanicsOpticsForm extends Op3dComponentBase<iOptomechanicsOpticsFormParams> {

    private static INSTANCE: OptomechanicsOpticsForm;

    private mSections: iOptomechanicsOpticsFormSections;
    private mOptics3DPresenter: Optics3DPresenter;
    private mPart: Part;
    private mSaveBtn: HTMLElement;

    private mNameInput: HTMLInputElement;
    //__________________________________________________________________________________________
    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/optics/optomechanics_optics_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == OptomechanicsOpticsForm.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            document.getElementById('forms').appendChild(aContainer);

            OptomechanicsOpticsForm.INSTANCE = new OptomechanicsOpticsForm(aContainer);
        }

        return OptomechanicsOpticsForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm(): Promise<void> {
        await Op3dContext.wait(() => {
            for (let section in this.mSections) {
                if (true != this.mSections[section].isReady) {
                    return false;
                }
            }
            return true;
        });

        while (!this.mIsReady || !this.mOptics3DPresenter.isReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pOpticsFormParams: iOptomechanicsOpticsFormParams) {
        Spinner.instance.show();
        this._clear();

        this.mPart = pOpticsFormParams.part;

        let aCadOpticalData: iCadOpticalData = pOpticsFormParams.part.opticalData;
        this.mNameInput.value = pOpticsFormParams.part.getLabel().label;

        if (this.mPart.opticalPartType == eCadType.OPTICS) {

            document.getElementById('material_sections').classList.remove('d-none');
            this.mSections.material.enable(true);
            const aMatrialVO: iuoMaterial = { materialID: aCadOpticalData.materialID, wavelength: 550 };
            this.mSections.material.setData(aMatrialVO);

            if (this.mPart != null && !this.mPart.userIsPartOwner() && Op3dContext.USER_PERMISSION.isFreeUser === true) {
                this.mSections.material.setMaterialsSelectDisabled(true);
            } else {
                this.mSections.material.setMaterialsSelectDisabled(false);
            }
            this.mSections.material.expand();
        } else {
            this.mSections.material.enable(false);
            this.mSections.material.collapse();
            document.getElementById('material_sections').classList.add('d-none');
        }

        const aFaces = this.mPart.getFaces()[0].indexes;
        this.mSections.colorInfo.setData(aFaces);
        this.mSections.colorInfo.enable(Op3dContext.USER_VO.isBasicLicense === false);
        this.mSections.colorInfo.collapse();

        await this.mOptics3DPresenter.onRefreshOptomechanicsOptic(this.mPart);

        this.mOptics3DPresenter.toUpdate = true;
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, true);

        await this.mSections.surfacesInfo.setOptomechanicsData({
            mouseenter: (pFaceName) => this.mOptics3DPresenter.highlightOptomechanicsFace(pFaceName),
            mouseleave: () => this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll(),
            optics: this.mPart.iPart,
            part: this.mPart
        });
        let aIndexes = aFaces
        let aSolidsQuantity = aIndexes[aIndexes.length - 1].path[1]
        await this.mSections.solidsInfo.setOptomechanicsData({
            mouseenter: (pPathNum) => this.mOptics3DPresenter.highlightOptomechanicsSolid(pPathNum),
            mouseleave: () => this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll(),
            ipart: this.mPart.iPart,
            part: this.mPart
        });
        if(pOpticsFormParams.part.opticalPartType === eCadType.OPTICS && aSolidsQuantity > 0){
            this.mSections.solidsInfo.show(true);
        }else{
            this.mSections.solidsInfo.show(false);
        }

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll()
        this.mOptics3DPresenter.toUpdate = false;
        this._clear();
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mNameInput.value = ''
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        super._initElements();
        this.mSaveBtn = this._getPart('save_btn', true);
        this.mNameInput = this._getPart('optomechanics-optics-name') as HTMLInputElement;
        this.mNameInput.disabled = true;
        this._initSections();
        this._init3DPresenter();
    }
    //__________________________________________________________________________________________
    private _init3DPresenter() {
        this.mOptics3DPresenter = new Optics3DPresenter(
            this._getPart('optic-preview-element'), {
            isFixedDiv: false,
            alwaysShowImage: false
        });
    }
    //__________________________________________________________________________________________
    private async _initSections() {
        this.mSections = {
            material: new uoMaterial(this._getPart("material_sections"), "Material"),
            colorInfo: new esOptomechColorInfo(this._getPart("color_section")),
            surfacesInfo: new uoSurfaces(this._getPart('surfaces_section')),
            solidsInfo: new uoSolids(this._getPart('solids_section'))
        };
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mSaveBtn.addEventListener('click', () => this._onSave());
        EventManager.addEventListener(EventsContext.OPTOMECH_MAT_CHANGE,
            () => this._updateSaveBtnState(), this);
        EventManager.addEventListener(EventsContext.OPTOMECH_COLOR_CHANGE,
            () => this._updateSaveBtnState(), this);
    }
    //__________________________________________________________________________________________
    private _updateSaveBtnState() {
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, false);
    }
    //__________________________________________________________________________________________
    private async _onSave() {
        let aMaterialID = '';
        if (this.mPart.opticalPartType == eCadType.OPTICS) {
            aMaterialID = this.mSections.material.getData();
        }
        this.mPart.opticalData["materialID"] = aMaterialID;
        this._removeFacesMaterial();
        let aAnswer = await this.mPart.saveOptomechanicsOpticalInfoInMongo();
        if (!aAnswer) {
            Popup.instance.open({
                text: MessagesHandler.ERROR_ON_SAVE_FOREIGN_PART
            })
        } else {
            this.mSections.colorInfo.saveColorChanges(this.mPart, true)
            Op3dContext.DIV_CONTROLLER.updatePartInfo();
            this.close();
        }
    }
    //__________________________________________________________________________________________
    protected _removeFacesMaterial() {
        if (this.mPart.opticalData.faces !== undefined) {
            this.mPart.opticalData.faces.forEach(face => {
                if (face.data != null && face.data.materialID != null) {
                    delete face.data.materialID;
                }
            });
        }
        this.mPart.getFaces()[0].indexes.forEach((face) => {
            if (face.data !== null && face.data !== undefined && face.data.materialID !== undefined) {
                delete face.data.materialID;
            }
        })
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
}