import { MeshBasicMaterial, TextureLoader, MeshPhongMaterial, DoubleSide, CanvasTexture, Texture } from "three";
import { FileUtils } from "../../_utils/FileUtils";
import { eCacheDataType, CacheStorage } from "../CacheStorage";
import { iApertureDataOpticsVO } from "../VO/OpticsVOInterfaces";


export class ApertureDataLoader {


    private static INSTANCE: ApertureDataLoader;
    private constructor() {

        CacheStorage.instance.registerNewStorage(eCacheDataType.APERTURE);
        CacheStorage.instance.registerNewStorage(eCacheDataType.APERTURE_MATERIAL);
    }
    //_______________________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            this.INSTANCE = new ApertureDataLoader();
        }

        return this.INSTANCE;
    }
    //_______________________________________________________________________________________________________
    public getMaskByURL(pURL: string): MeshBasicMaterial {
        if (pURL == null || pURL == "") return;
        return CacheStorage.instance.getFromCache(pURL, eCacheDataType.APERTURE_MATERIAL);
    }
    //_______________________________________________________________________________________________________
    public async loadApertures(pData: iApertureDataOpticsVO) {
        const aTextureData: { url: string, opacity: number, isTransparent: boolean } =
            pData.transmittance_mask !== undefined &&
                pData.transmittance_mask !== null &&
                pData.transmittance_mask.url != "" ? {
                url: pData.transmittance_mask.url,
                opacity: 1,
                isTransparent: false
            } : {
                url: pData.phase_mask.url,
                isTransparent: true,
                opacity: 0.2
            };

        // Load the image texture
        return new Promise(resolve => {

            if (aTextureData.url === "") {
                resolve(false);
            }
            var textureLoader = new TextureLoader();
            // textureLoader.load("https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=0.630xw:1.00xh;0.186xw,0&resize=1200:*", function (texture) {
            textureLoader.load(aTextureData.url, function (texture: Texture) {
                //  texture.wrapS = RepeatWrapping;
                //  texture.wrapT = RepeatWrapping;


                let aMat = new MeshPhongMaterial({ map: texture, side: DoubleSide });
                aMat.transparent = aTextureData.isTransparent;
                aMat.opacity = aTextureData.opacity;
                CacheStorage.instance.addData(aTextureData.url, aMat, eCacheDataType.APERTURE_MATERIAL);
                resolve(true);
            });
        })


    }
    //_______________________________________________________________________________________________________
    public createTextureByFile(pFile: File) {

        return new Promise(async (resolve) => {
            let aDataURL = await FileUtils.loadFileDataurl(pFile);
            let aImage = new Image();

            aImage.onload = () => {

                const aCanvas = document.createElement('canvas');
                aCanvas.width = aImage.width;
                aCanvas.height = aImage.height;

                const aContext = aCanvas.getContext('2d');

                aContext.drawImage(aImage, 0, 0);

                const aTexture = new CanvasTexture(aCanvas);
                let aMat = new MeshPhongMaterial({ map: aTexture, side: DoubleSide });
                CacheStorage.instance.addData(pFile.name, aMat, eCacheDataType.APERTURE_MATERIAL);
                resolve(true);
            };
            aImage.src = aDataURL.data;
        })


    }
    //_______________________________________________________________________________________________________
}