import { useRef, useState } from "react";
import OptiChatContext, {
  eChatMessageType,
  eExampleConversationType,
  iExampleData,
  iOptiChatContext,
  iOptiChatMenuOptionExample,
  iOptiChatMessage,
} from "./OptiChatContext";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { OpticsMenu } from "../../ui/menus/_search/OpticsMenu";

const TOTAL_COUNT_EXAMPLES = 3;
export const REVIEW_MESSAGES: Array<string> = [
  "Your review matters! Share your experience with us. Thank you!",
  "Thank you for your review! We appreciate it!",
  "We appreciate your review! Thank you for sharing your feedback with us.",
];

export const generateExample = () => {
  const aExamples: Array<iOptiChatMenuOptionExample> = [];
  const aNumbers = generateNums(
    EXAMPLE_CONVO_DATA.length,
    TOTAL_COUNT_EXAMPLES
  );

  for (let idx of aNumbers) {
    const examples = EXAMPLE_CONVO_DATA[idx].examples;
    let aIdx = OP3DMathUtils.getRandomNumber(examples.length);
    aExamples.push({
      example: examples[aIdx],
      title: EXAMPLE_CONVO_DATA[idx].type,
    });
  }

  return aExamples;
};

const generateNums = (pLimit: number, pCount: number) => {
  const numbers = [];

  while (numbers.length < pCount) {
    const randomNumber = Math.floor(Math.random() * pLimit); // Generates a random number between 0 and 4
    if (!numbers.includes(randomNumber)) {
      numbers.push(randomNumber);
    }
  }

  return numbers;
};

const EXAMPLE_CONVO_DATA: Array<iExampleData> = [
  {
    type: eExampleConversationType.SEARCH,
    examples: [
      "Search for convex-convex lenses with a first radius of curvature of 10-15 mm",
      "Search for gratings with grooves range of 100-200 lines/mm",
      'Search for flat windows with "laser grade" keywords, made by Edmund Optics',
    ],
  },
  {
    type: eExampleConversationType.CALCULATION,
    examples: [
      "What is the refractive index of N-SF11 at 600 nm?",
      "What is the EFL of a lens made from fused silica, with a central thickness of 2 mm, first and second radii of curvature 10 mm, at 450 nm?",
      "Give me the 20 materials with a refractive index closest to 1.7 at 800 nm.",
    ],
  },
  {
    type: eExampleConversationType.FIND,
    examples: [
      "Calculate and find the plano-convex lens, made from UVFS, that will create an EFL of 20 mm at 730 nm.",
      "Calculate and find the convex-concave lenses that are made from a material that has a refractive index of 1.5147 at 645 nm.",
      "Calculate and find the ball lenses from the material S-LAH79 that have EFL of 5 mm at 605 nm.",
    ],
  },
  {
    type: eExampleConversationType.GENERAL,
    examples: [
      "Help me to build a Galilean beam expander.",
      "What actions can you perform?",
      "Help me understand the basics of optical system alignment and calibration.",
    ],
  },
];

export const waitingDots = (container: HTMLElement, pDots: string) => {
  if (pDots.length === 0) {
    pDots = ".";
  } else if (pDots.length === 1) {
    pDots = "..";
  } else if (pDots.length === 2) {
    pDots = "...";
  } else if (pDots.length === 3) {
    pDots = "";
  }

  container.innerText = pDots;
  return pDots;
};

function OptiChatProvider({ children }) {
  const data = useRef({
    currTab: "",
    key: Op3dUtils.idGenerator(),
    isWaitingServerResponse: false,
    changes: [],
  });

  const [conversation, setConversation] = useState([]);
  const [reviewConversation, setReviewConversation] = useState([]);
  const [session, setSession] = useState(Op3dUtils.idGenerator());
  const [isReviewOpen, setReviewOpen] = useState(false);
  const [tabs, setTabs] = useState([]);

  const resetSession = () => {
    if (
      conversation.length > 0 ||
      optiChatContext.data.current.changes.length > 0
    ) {
      optiChatContext.data.current = {
        currTab: "",
        changes: [],
        isWaitingServerResponse: false,
        key: Op3dUtils.idGenerator(),
      };

      const newSession = Op3dUtils.idGenerator();
      optiChatContext.setSession(newSession);
      optiChatContext.setConversation([]);
      optiChatContext.setReviewOpen(false);
      optiChatContext.setTabs([]);
      OpticsMenu.instance.resetAllFilters();
      OpticsMenu.instance._getResults(true);
    }
  };
  const addUserMessage = (pMessage: string) => {
    let aUserMessage: iOptiChatMessage = {
      message: pMessage,
      typed: true,
      type: eChatMessageType.USER_MESSAGE,
    };
    let aBotMessage: iOptiChatMessage = {
      typed: false,
      message: "",
      userMessage: pMessage,
      type: eChatMessageType.CHATBOT_MESSAGE,
    };
    optiChatContext.setConversation([
      ...optiChatContext.conversation,
      aUserMessage,
      aBotMessage,
    ]);
  };

  const optiChatContext: iOptiChatContext = {
    session: session,
    setSession: setSession,

    data: data,
    addUserMessage: addUserMessage,
    resetSession: resetSession,

    conversation: conversation,
    setConversation: setConversation,

    reviewConversation: reviewConversation,
    setReviewConversation: setReviewConversation,

    isReviewOpen: isReviewOpen,
    setReviewOpen: setReviewOpen,

    tabs: tabs,
    setTabs: setTabs,
  };

  return (
    <OptiChatContext.Provider value={optiChatContext}>
      {children}
    </OptiChatContext.Provider>
  );
}

export default OptiChatProvider;
