import {
    eDataPermission,
    eTextTransform,
    eUnitType,
} from "../../../_context/Enums";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { eBaseShape } from "../../../_context/OpticsContext";
import { Strings } from "../../../_context/Strings";
import {
    iSortItem,
    iHash,
    iElasticQuery,
    iAsyncCallback,
} from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { DataLoader } from "../../../data/data_loader/DataLoader";
import { ServerContext } from "../../../server/ServerContext";
import { UnitHandler } from "../../../units/UnitsHandler";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Popup } from "../../forms/Popup";
import { Spinner } from "../../home/Spinner";
import {
    MenuListColumnsManager,
    iOneColumnData,
    iColumnsData,
} from "./MenuListColumnsManager";

import {
    iMenuParams,
    eMenuListColumnsDataType,
    iMenuListColumn,
} from "./MenusContext";
import { FiltersHandler } from "./_filterHandlers/FiltersHandler";
import { eOMFilterName } from "./_filterHandlers/OpticsFilterHandler";
import { AnalyticsEventsContext } from "../../../_context/AnalyticsEventsContext";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { eOMPFilterName } from "./_filterHandlers/OptomechanicFilterHandler";
import { OptiChatBtn } from "../../../optiChat/components/OptiChatBtn";
import private_img from '../../../../../public/images/icons_new/product_catalog/private_part.svg'
import assembly_img from '../../../../../public/images/icons_new/product_catalog/assembly_part.svg'
export interface iResizableHTMLElement extends HTMLDivElement {
    clientX: number;
    currWidth: number;
}

export interface iWarningMessageOptions {
    message: string;
    yesBtn?: { text: string; callback: Function };
    noBtn?: { text: string; callback?: Function };
}

export abstract class MenuListAbstract<
    T extends { number_id: string; raw?: any },
    S = any
> extends Op3dComponentBase<S> {
    protected static SKIN_PATH = "./skins/menus/menu_list_abstract.html";
    private static FILTER_TYPE_NUMBER = "number";

    public static NA: string = ""; //"N/A";
    protected mMenuParams: iMenuParams;
    protected mSingleListItem: HTMLElement;
    protected mResultsList: HTMLElement;
    protected mTitlesParent: HTMLElement;
    protected mSingleTitleItem: HTMLElement;
    protected mCurrentPage: number = 0;
    protected mSortObj: iSortItem;
    protected mFiltersHandler: FiltersHandler;
    protected mFilterTemplate: HTMLElement;
    protected mFiltersParent: HTMLElement;
    protected mSearchInput: HTMLInputElement;
    private mTotalResultsElement: HTMLElement;
    private mNamesElement: HTMLElement;
    protected mResizeControls: HTMLElement;
    protected mSignelLineItemQaId: string;
    protected mResultsListPromoted: HTMLElement;
    protected mStarredItems: Array<string> = [];
    protected mFavoriteFilter!: HTMLInputElement;
    protected mItemsToCanvasList: Array<{ element: HTMLInputElement; item: T }> =
        [];
    private aAddToCanvasBtn: HTMLElement;
    private mClearOnExit: boolean;
    protected mPromotedIds: Array<string> = [];
    private mModalWarning: HTMLElement;
    private mWarningMessageOptions: iWarningMessageOptions;
    private mModalCompare: HTMLElement;
    protected mTitle: HTMLElement;

    protected constructor(
        pParams: { skinPath: string; container: HTMLElement },
        pMenuParms: iMenuParams
    ) {
        super(pParams);
        this.mStarredItems =
            Op3dContext.USER_VO.userVO.parameters.catalogFavorites || [];

        this.mMenuParams = pMenuParms;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm(): Promise<void> {
        while (!this.mIsReady || !this.mFiltersHandler.filtersReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    public get data() {
        return this.mData;
    }
    //__________________________________________________________________________________________
    protected fillOneExtraField(_pData: T, _pCloneItem: HTMLElement) { }
    //__________________________________________________________________________________________
    protected async fillPromotedList(
        pOriginalQuery: iElasticQuery,
        pBrandsToAdd: string,
        pInstanceMenu: any
    ) {
        let aRequestFunction, aRandomBrandQuery, aPromotedQuery;
        switch (pInstanceMenu) {
            case "OPTICS":
                aRequestFunction = ServerContext.SERVER.getManyOpticalElements.bind(
                    ServerContext.SERVER
                );
                aRandomBrandQuery = {
                    query: "",
                    page: { current: 1, size: 1 },
                    filters: {
                        all: [
                            { ["permission"]: 1 },
                            { ["parameters.info.brand"]: pBrandsToAdd },
                        ],
                    },
                };
                aPromotedQuery = this.addPromotionBrandsToQuery(
                    pBrandsToAdd,
                    pOriginalQuery,
                    "OPTICS"
                );

                break;
            case "PARTS":
                aRequestFunction = ServerContext.SERVER.getManyPartsElements.bind(
                    ServerContext.SERVER
                );
                aRandomBrandQuery = {
                    query: "",
                    page: { current: 1, size: 1 },
                    filters: {
                        all: [
                            { ["info.section"]: "Mounts" },
                            { ["permission"]: 1 },
                            { ["info.parameters.brand"]: pBrandsToAdd },
                        ],
                    },
                };
                aPromotedQuery = this.addPromotionBrandsToQuery(
                    pBrandsToAdd,
                    pOriginalQuery,
                    "PARTS"
                );

                break;
        }

        let aResPromotion = (await aRequestFunction(
            aPromotedQuery
        )) as iAsyncCallback<{
            data: Array<T>;
            total: number;
            meta?: any;
            results?: any;
        }>;
        if (aResPromotion.data.results.length === 0 && !this.mClearOnExit) {
            aResPromotion = (await aRequestFunction(
                aRandomBrandQuery
            )) as iAsyncCallback<{
                data: Array<T>;
                total: number;
                meta?: any;
                results?: any;
            }>;
        }
        let aPromotedResults = aResPromotion.data.results as Array<T>;

        if (aPromotedResults[0] != null) {
            this.removeRawKeys(aPromotedResults[0]);
            this.mPromotedIds.push(aPromotedResults[0].number_id);
            let aCloneItem = this.mSingleListItem.cloneNode(true) as HTMLElement;

            let aContent = Op3dUtils.getElementIn(
                aCloneItem,
                "single-content",
                true
            ) as HTMLElement;
            let aContentExtra = Op3dUtils.getElementIn(
                aCloneItem,
                "single-extra"
            ) as HTMLElement;

            ViewUtils.setElementVisibilityByDNone(aContentExtra, false);

            aContent.setAttribute("promoted", "true");

            this._fillOneItem(aPromotedResults[0], aContent);

            this.mResultsListPromoted.appendChild(aCloneItem);

            $(this.mResultsListPromoted)
                .find('[data-toggle="tooltip"]')
                .tooltip({
                    delay: { show: 500, hide: 0 },
                })
                .on("click", function () {
                    $(this).blur();
                });

            AnalyticsEventsContext.triggerOpticsPromotedSearchAnalyticsEvent({
                type: aPromotedResults[0][eOMFilterName.type] as string,
                subType:
                    aPromotedResults[0][eOMFilterName.subType] != null
                        ? aPromotedResults[0][eOMFilterName.subType]
                        : null,
                brand:
                    aPromotedResults[0][eOMFilterName.brand] != null
                        ? aPromotedResults[0][eOMFilterName.brand]
                        : null,
                searchString: this.mSearchInput.value,
                baseShape: eBaseShape[aPromotedResults[0][eOMFilterName.baseShape]],
            });
        }
    }
    //__________________________________________________________________________________________
    private _fillList(pData: Array<T>) {
        this._updatePaging();

        for (let i = 0; i < pData.length; i++) {
            this.removeRawKeys(pData[i]);
            let aCloneItem = this.mSingleListItem.cloneNode(true) as HTMLElement;

            let aContent = Op3dUtils.getElementIn(
                aCloneItem,
                "single-content",
                true
            ) as HTMLElement;
            let aContentExtra = Op3dUtils.getElementIn(
                aCloneItem,
                "single-extra"
            ) as HTMLElement;

            ViewUtils.setElementVisibilityByDNone(aContentExtra, false);

            this._fillOneItem(pData[i], aContent);

            this.mResultsList.appendChild(aCloneItem);
        }
        $(this.mResultsList)
            .find('[data-toggle="tooltip"]')
            .tooltip({
                delay: { show: 500, hide: 0 },
            })
            .on("click", function () {
                $(this).blur();
            });
    }
    //__________________________________________________________________________________________
    private fillInfo(
        pOneItemData: any,
        pIsAdmin: boolean,
        pSearchResultItem: HTMLElement,
        pPrefix: string = ""
    ): void {
        for (let attribute in pOneItemData) {
            let aAttribute = attribute;
            let aAttrData;
            aAttrData = pOneItemData[aAttribute];
            let aAttrDiv: HTMLElement;
            if (aAttrData instanceof Array) {
                aAttrData = aAttrData.join(",");
            }
            if (aAttrData instanceof Object) {
                let aPrefix = pPrefix + attribute + ".";
                this.fillInfo(aAttrData, pIsAdmin, pSearchResultItem, aPrefix);
            } else {
                aAttrDiv = Op3dUtils.getElementIn(
                    pSearchResultItem,
                    "item_" + pPrefix + attribute
                ) as HTMLElement;
                if (
                    aAttrDiv != null &&
                    pSearchResultItem.getAttribute("promoted") === "true"
                ) {
                    aAttrDiv.setAttribute("promoted", "true");
                }
                this.fillOneAttributeData(aAttrDiv, aAttrData, pOneItemData.permission === 0, pOneItemData.assembly_parts != null);
            }
        }
    }
    //__________________________________________________________________________________________
    private collapseExtra(e: Event, pElement: HTMLElement, pData: T) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        let aParent = pElement!.parentElement!.parentElement!
            .parentElement as HTMLElement;
        let aContentExtra = Op3dUtils.getElementIn(
            aParent,
            "single-extra"
        ) as HTMLElement;
        aContentExtra!.style.width = `${this.mNamesElement.clientWidth}px`;
        let aItemsContainer = Op3dUtils.getElementIn(
            aContentExtra,
            "items-container"
        ) as HTMLElement;
        let aItemName = Op3dUtils.getElementIn(
            aContentExtra,
            "item-name"
        ) as HTMLElement;
        ViewUtils.toggleClass(aContentExtra, Strings.D_NONE);
        if (aItemsContainer.innerHTML == "") {
            this.fillOneExtraField(pData, aContentExtra);
            pElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.59 16.1299L12 11.5399L7.41 16.1299L6 14.7099L12 8.70988L18 14.7099L16.59 16.1299Z" fill="#2E3333"/>
            </svg>`;
        } else {
            let aCollapsedLinkField = aContentExtra.getElementsByTagName("a")[0];

            aCollapsedLinkField && aContentExtra.removeChild(aCollapsedLinkField);
            aItemsContainer.innerHTML = "";
            aItemName.innerHTML = "";
            pElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7.41 9.70996L12 14.3L16.59 9.70996L18 11.13L12 17.13L6 11.13L7.41 9.70996Z" fill="#2E3333"/>
            </svg>`;
        }
    }
    //__________________________________________________________________________________________
    private addToFavorites(e: Event, pElement: HTMLElement, pData: T) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();

        let aFilled = pElement.style.fill == "rgb(149, 158, 158)";
        let aIndexOfItem = this.mStarredItems.indexOf(pData.number_id);

        if (aFilled == true) {
            pElement.style.fill = "none";
            this.mStarredItems.splice(aIndexOfItem, 1);
        } else {
            pElement.style.fill = "rgb(149, 158, 158)";
            this.mStarredItems.push(pData.number_id);
        }

        Op3dContext.USER_VO.userVO.parameters.catalogFavorites = this.mStarredItems;
    }
    //__________________________________________________________________________________________
    private createElementFromHTML(pHTMLString: string) {
        let aDiv = document.createElement("div");
        aDiv.innerHTML = pHTMLString.trim();

        return aDiv.firstChild;
    }
    //__________________________________________________________________________________________
    private addFirstStaticColumn() {
        let aChild = document.createElement("div") as iResizableHTMLElement;
        aChild.classList.add("pl-0", "flex-shrink-0", "custom-oms");

        let aStarredIcon = this
            .createElementFromHTML(`<svg class='starred' id='starred' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M11.5386 2.40363C11.7097 1.99375 12.2903 1.99375 12.4614 2.40363L14.4851 7.25209C14.7012 7.76994 15.1883 8.12384 15.7476 8.16938L20.9841 8.59572C21.4268 8.63176 21.6062 9.18403 21.2693 9.4734L17.2835 12.8963C16.8577 13.2618 16.6717 13.8345 16.8012 14.3805L18.0139 19.4924C18.1164 19.9246 17.6466 20.2659 17.2673 20.0348L12.7803 17.3018C12.301 17.0099 11.699 17.0099 11.2197 17.3018L6.7327 20.0348C6.35337 20.2659 5.88358 19.9246 5.9861 19.4924L7.19879 14.3805C7.32831 13.8345 7.14225 13.2618 6.71654 12.8963L2.73073 9.4734C2.39377 9.18403 2.57321 8.63176 3.0159 8.59572L8.25239 8.16938C8.81169 8.12384 9.2988 7.76994 9.51494 7.25209L11.5386 2.40363Z" stroke="#959E9E"/>
        </svg>`);

        let aChechbox = document.createElement("input");
        aChechbox.className = "item-selected";
        aChechbox.id = "item-selected";
        aChechbox.setAttribute("type", "checkbox");
        let aDropdownIcon = this
            .createElementFromHTML(`<svg  id='dropdown' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7.41 9.70996L12 14.3L16.59 9.70996L18 11.13L12 17.13L6 11.13L7.41 9.70996Z" fill="#2E3333"/>
        </svg>`);

        let aTitle = document.createElement("div");
        aTitle.style.width = "100px";
        aTitle.style.height = "32.2px";
        aTitle.style.borderRight = "1px solid grey";
        aChild.appendChild(aStarredIcon as HTMLElement);
        aChild.appendChild(aChechbox);
        aChild.appendChild(aDropdownIcon as HTMLElement);

        aTitle.setAttribute("static_col", "true");
        aChild.style.width = "100px";
        aChild.classList.add("favorites-block");

        let aContent = Op3dUtils.getElementIn(
            this.mSingleListItem,
            "single-content"
        );
        aContent && aContent.prepend(aChild);

        this.mTitlesParent.prepend(aTitle);
    }
    //__________________________________________________________________________________________
    private _initColumns() {
        let aColumnsDataHash: iHash<iOneColumnData> = {};
        let aStyle = "";

        this.addFirstStaticColumn();

        for (let i = 0; i < this.mMenuParams.columns.length; i++) {
            const aColumn = this.mMenuParams.columns[i];

            //add title
            let aTitleItem = this.mSingleTitleItem.cloneNode(
                true
            ) as iResizableHTMLElement;
            aTitleItem.id = "titleItem" + Op3dUtils.idGenerator();
            let aContent = Op3dUtils.getElementIn(aTitleItem, "content", true);
            let aNameSpan = document.createElement("span");
            aNameSpan.innerHTML = aColumn.name;
            let aTitleTransform =
                null != aColumn.title_transform
                    ? aColumn.title_transform
                    : eTextTransform.SENTENCE_STYLE;

            aContent.appendChild(aNameSpan);
            ViewUtils.setTextTransform(aNameSpan, aTitleTransform);

            let aChild = document.createElement("div") as iResizableHTMLElement;
            aChild.classList.add("pl-0", "flex-shrink-0", "custom-oms");

            if (aColumn.capitalize !== false) {
                aChild.style.textTransform = " capitalize !important;";
            }

            if (null == aColumn.minWidth) {
                aColumn.minWidth = 100;
            }

            if (null == aColumn.width) {
                aColumn.width = 2 * aColumn.minWidth;
            }

            if (null == aColumn.maxWidth) {
                aColumn.maxWidth = 2 * aColumn.width;
            }

            aTitleItem.style.minWidth = aColumn.minWidth + "px";
            aChild.style.minWidth = aColumn.minWidth + "px";

            aTitleItem.style.width = aColumn.width + "px";
            aChild.style.width = aColumn.width + "px";

            aTitleItem.currWidth = aColumn.width;
            aChild.currWidth = aColumn.width;

            aChild.id =
                aColumn.serverPath != null
                    ? "item_" + aColumn.serverPath
                    : "item_" + aColumn.name;

            for (let attr in aColumn) {
                aChild.setAttribute(attr, aColumn[attr]);
                aTitleItem.setAttribute(attr, aColumn[attr]);
            }

            if (true == aColumn.static_col) {
                aChild.setAttribute("static_col", "true");
                aTitleItem.setAttribute("static_col", "true");
            }

            if (null != aColumn.translateFunction) {
                aChild.setAttribute("translate_index", i.toString());
            }

            if (eMenuListColumnsDataType.NUMBER == aColumn.dataType) {
                let aFixed = null != aColumn.fixed ? aColumn.fixed : 2;
                aChild.setAttribute("fixed", aFixed.toString());
                aTitleItem.setAttribute("fixed", aFixed.toString());
                aChild.setAttribute("filter_type", "number");
                aTitleItem.setAttribute("filter_type", "number");
            }

            let aUnits = aChild.getAttribute("units");
            if (aUnits != null && aUnits != "") {
                if (aUnits == "env") {
                    let aSpanMM = document.createElement("span");
                    aSpanMM.innerHTML = "&nbsp(mm)";
                    aSpanMM.classList.add("om-span-mm");
                    let aSpanIN = document.createElement("span");
                    aSpanIN.innerHTML = "&nbsp(in)";
                    aSpanIN.classList.add("om-span-in");
                    aContent.appendChild(aSpanMM);
                    aContent.appendChild(aSpanIN);
                } else {
                    let aUnitsSpan = document.createElement("span");
                    aUnitsSpan.innerHTML = "&nbsp(" + aUnits + ")";
                    aUnitsSpan.classList.add("custom_text-lowercase");
                    aContent.appendChild(aUnitsSpan);
                }
            }

            let aTableCssName = aColumn.name
                .replaceAll("/", "_")
                .replaceAll(" ", "_")
                .toLowerCase()
                .trim();
            if (true != aColumn.static_col) {
                if (null == aColumnsDataHash[aTableCssName]) {
                    let aIsShownOnStart = true == aColumn.showOnStart;
                    let aIsVisible = false != aColumn.isVisible;
                    aColumnsDataHash[aTableCssName] = {
                        name: aColumn.name,
                        class_name: aTableCssName,
                        checked: aIsShownOnStart,
                        isVisible: aIsVisible,
                        textTransform: aColumn.title_transform,
                        index: i,
                    };
                } else {
                    aColumnsDataHash[aTableCssName].index = i;
                }

                aChild.classList.add("col_" + aTableCssName);
                aTitleItem.classList.add("col_" + aTableCssName);
                aStyle +=
                    "." +
                    aTableCssName +
                    " ." +
                    "col_" +
                    aTableCssName +
                    "{display: block !important}";
                aStyle +=
                    "." +
                    "hide_" +
                    aTableCssName +
                    " ." +
                    "col_" +
                    aTableCssName +
                    "{display: none !important}";
            }

            this._initSortElement(aTitleItem, aColumn.sortName as string);

            let aResizeName = aColumn.name;
            aResizeName = aResizeName.replaceAll(" ", "_");

            let aTitleDiv = aTitleItem.children[0] as iResizableHTMLElement;
            this.initResizableElement(aTitleDiv, aChild, aColumn);

            this.mTitlesParent.appendChild(aTitleItem);
            let aContentItem = Op3dUtils.getElementIn(
                this.mSingleListItem,
                "single-content"
            );
            aContentItem && aContentItem.appendChild(aChild);

            aTitleItem.addEventListener("dragstart", (e) => this._handleDragStart(e));
            aTitleItem.addEventListener("dragover", this._handleDragOver);
            aTitleItem.addEventListener("dragend", this._handleDragEnd);
            aTitleItem.addEventListener("drop", this._handleDrop);
        }

        this._addStyle(aStyle);

        aColumnsDataHash = Op3dUtils.sortObject<iOneColumnData>(
            aColumnsDataHash,
            function (a, b) {
                return a.index - b.index;
            }
        );
        this.onChangeColumns(aColumnsDataHash);

        let aColumnContainer = this._getPart("column_container");
        aColumnContainer.addEventListener("click", (e: Event) => {
            e.stopPropagation();
        });

        let aColumnsData: iColumnsData = {
            container: aColumnContainer,
            data: aColumnsDataHash,
            onChange: (pNames: iHash<iOneColumnData>) => this.onChangeColumns(pNames),
        };

        new MenuListColumnsManager(aColumnsData);
    }
    //____________________________________________________
    private _changeAllColumnsPositions() {
        const aElementsIdxPath = new Map();
        this.mNamesElement.childNodes.forEach((elem, aIdx) => {
            const aServerpath = $(elem).attr("serverpath");
            if (aIdx != -1) aElementsIdxPath.set(aIdx, aServerpath);
        });
        this.mResultsList.childNodes.forEach((lineItem) => {
            if (lineItem.hasChildNodes()) {
                let aFirstElementChild = (lineItem as HTMLElement).firstElementChild;
                aFirstElementChild &&
                    aFirstElementChild.childNodes.forEach((currColumn, currIdx) => {
                        const aElemAttr = aElementsIdxPath.get(currIdx);
                        if (!(aElemAttr == $(currColumn).attr("serverpath"))) {
                            let aNodeShouldBe;
                            aFirstElementChild &&
                                aFirstElementChild.childNodes.forEach((el, _elIdx) => {
                                    if ($(el).attr("serverpath") == aElemAttr) {
                                        aNodeShouldBe = el;
                                    }
                                });
                            if (aNodeShouldBe != null) {
                                aFirstElementChild &&
                                    aFirstElementChild.insertBefore(aNodeShouldBe, currColumn);
                            }
                        }
                    });
            }
        });
        this.mResultsListPromoted.childNodes.forEach((lineItem) => {
            if (lineItem.hasChildNodes()) {
                let aFirstElementChild = (lineItem as HTMLElement).firstElementChild;
                aFirstElementChild &&
                    aFirstElementChild.childNodes.forEach((currColumn, currIdx) => {
                        const aElemAttr = aElementsIdxPath.get(currIdx);
                        if (!(aElemAttr == $(currColumn).attr("serverpath"))) {
                            let aNodeShouldBe;
                            aFirstElementChild &&
                                aFirstElementChild.childNodes.forEach((el, _elIdx) => {
                                    if ($(el).attr("serverpath") == aElemAttr) {
                                        aNodeShouldBe = el;
                                    }
                                });
                            if (aNodeShouldBe != null) {
                                aFirstElementChild &&
                                    aFirstElementChild.insertBefore(aNodeShouldBe, currColumn);
                            }
                        }
                    });
            }
        });

        this.mNamesElement.style.width;
    }
    //____________________________________________________
    private _handleDragStart = (e: DragEvent) => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData(
            Number as any,
            Array.prototype.indexOf.call(
                (e.srcElement as any).parentNode.childNodes,
                e.target
            ) as any
        );
        (e.target as HTMLElement).style.opacity = "0.6";
    };
    //____________________________________________________
    private _handleDrop = (e) => {
        e.stopPropagation();
        try {
            const target = this._findClosestParentAbove(e.target, "list-title-item");
            let aTargetParent = target.parentElement;
            if (aTargetParent !== null) {
                let aOriginPos = +e.dataTransfer.getData(Number); // IDX of origin   // positions start from 2 (0 pos == 2 pos)
                let aTargetPos = +Array.prototype.indexOf.call(
                    aTargetParent.childNodes,
                    target
                ); // IDX of target
                if (aOriginPos - aTargetPos == -1) {
                    aOriginPos = +Array.prototype.indexOf.call(
                        aTargetParent.childNodes,
                        target
                    );
                    aTargetPos = +e.dataTransfer.getData(Number);
                    aTargetParent.insertBefore(
                        target,
                        aTargetParent.children[e.dataTransfer.getData(Number) - 2]
                    );
                } else {
                    aTargetParent.insertBefore(
                        aTargetParent.children[e.dataTransfer.getData(Number) - 2],
                        target
                    );
                }
            }

            this._changeAllColumnsPositions();

            // this._changeLastColumnsPosition()
            return false;
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
        }
    };
    //____________________________________________________
    private _handleDragOver = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
    };
    //____________________________________________________
    private _handleDragEnd = (e: Event) => {
        this._changeAllColumnsPositions();
        e.stopPropagation();
        (e.target as HTMLElement).style.opacity = "1";
    };
    //____________________________________________________
    private _findClosestParentAbove(
        pElement: HTMLElement,
        pClass: string
    ): HTMLElement {
        if (pElement.classList.contains(pClass)) {
            return pElement;
        }
        return this._findClosestParentAbove(
            pElement.parentElement as HTMLElement,
            pClass
        );
    }
    //____________________________________________________
    private initResizableElement(
        pTitleDiv: iResizableHTMLElement,
        pChild: iResizableHTMLElement,
        pColumn: iMenuListColumn
    ) {
        pTitleDiv.classList.add(Strings.JUSTIFY_CONTENT_BETWEEN);

        let aResizeControls = pTitleDiv.getElementsByClassName(
            "resizable-col"
        )[0] as iResizableHTMLElement;
        aResizeControls.currWidth = pColumn.width as number;

        let aOnMouseMove = (e: MouseEvent) => {
            let aDeltaWidth = e.clientX - aResizeControls.clientX;

            let aNewWidth = aResizeControls.currWidth + aDeltaWidth;
            if (aNewWidth < pColumn.minWidth! || aNewWidth > pColumn.maxWidth!) {
                return;
            }

            let aName = pChild.getAttribute("name");
            aResizeControls.clientX = e.clientX;
            aResizeControls.currWidth = aNewWidth;

            $(this.mSingleListItem)
                .find('.custom-oms[name="' + aName + '"]')
                .each(
                    (_index, elem) =>
                        ((elem as HTMLElement).style.width = aNewWidth + "px")
                );

            $(this.mContainer)
                .find('.results_table .custom-oms[name="' + aName + '"]')
                .each(
                    (_index, elem) =>
                        ((elem as HTMLElement).style.width = aNewWidth + "px")
                );
        };

        let aOnMouseUp = (e: MouseEvent) => {
            e.stopPropagation();

            this.mContainer.removeEventListener("mousemove", aOnMouseMove);
            window.removeEventListener("mouseup", aOnMouseUp);

            this.mNamesElement.classList.remove(Strings.POINTER_EVENTS_NONE);
            this.mNamesElement.classList.remove(Strings.USER_SELECT_NONE);
            this.mResultsList.classList.remove(Strings.POINTER_EVENTS_NONE);
            this.mResultsList.classList.remove(Strings.USER_SELECT_NONE);
        };

        aResizeControls.addEventListener("mousedown", (e: MouseEvent) => {
            this.mNamesElement.classList.add(Strings.POINTER_EVENTS_NONE);
            this.mNamesElement.classList.add(Strings.USER_SELECT_NONE);
            this.mResultsList.classList.add(Strings.POINTER_EVENTS_NONE);
            this.mResultsList.classList.add(Strings.USER_SELECT_NONE);

            aResizeControls.clientX = e.clientX;
            window.addEventListener("mouseup", aOnMouseUp);
            this.mContainer.addEventListener("mousemove", aOnMouseMove);
        });
    }
    //____________________________________________________
    protected onChangeColumns(pNames: iHash<iOneColumnData>) {
        for (let item in pNames) {
            let aOne = pNames[item];
            let aName = aOne.class_name.toLowerCase();
            if (aOne.checked && aOne.isVisible) {
                this.mContainer.classList.add(aName);
            } else {
                this.mContainer.classList.remove(aName);
            }

            if (aOne.isVisible) {
                this.mContainer.classList.remove("hide_" + aName);
            } else {
                this.mContainer.classList.add("hide_" + aName);
                this.mContainer.classList.remove(aName);
            }
        }
        // this.onScroll();
    }
    //__________________________________________________________________________________________
    private _addStyle(pStyle: string) {
        let aCssStyle = document.createElement("style");
        aCssStyle.innerHTML = pStyle;
        document.getElementsByTagName("head")[0].appendChild(aCssStyle);
    }
    //__________________________________________________________________________________________
    private _onChangeSort(pSortName: string, pParent: HTMLElement) {
        let aMenuTitles = document.getElementById("names_element");
        let aTitles = aMenuTitles.getElementsByClassName("sort-controls");
        for (let title of aTitles) {
            if (title === pParent) continue;
            title.classList.remove("up");
            title.classList.remove("down");
        }
        let aSortOrder: number;
        if (pParent.classList.contains("down")) {
            aSortOrder = 1;
            pParent.classList.remove("down");
            pParent.classList.add("up");
        } else if (pParent.classList.contains("up")) {
            aSortOrder = -1;
            pParent.classList.add("down");
            pParent.classList.remove("up");
        } else {
            aSortOrder = 1;
            pParent.classList.remove("down");
            pParent.classList.add("up");
        }

        this.mSortObj = {
            field: pSortName,
            order: aSortOrder,
        };

        this._getResults(true, this.mClearOnExit);
    }
    //__________________________________________________________________________________________
    private _updatePaging() {
        let aTotal = this.mMenuParams.dataLoader.dataTotal;
        let aLimit = +this._getPart<HTMLSelectElement>("results").value;
        let aText = DataLoader.getPagingText(aTotal, aLimit, this.mCurrentPage);

        let aNextBtn = this._getPart("next-page");
        let aPrevBtn = this._getPart("previous-page");
        let aFirstPage = this.mCurrentPage === 0;

        let totalPages = Math.ceil(aTotal / aLimit);
        let aLastPage = this.mCurrentPage === totalPages - 1;

        ViewUtils.setElementDisabled(aPrevBtn, aFirstPage);
        ViewUtils.setElementDisabled(aNextBtn, aLastPage);

        this.mTotalResultsElement.innerHTML = aText;
    }
    //__________________________________________________________________________________________
    private _initSortElement(pTitleDiv: HTMLElement, pSortName: string) {
        if (null == pSortName) {
            return;
        }

        let aSortControls = Op3dUtils.getElementIn(
            pTitleDiv,
            "sort_controls",
            true
        ) as HTMLElement;
        pTitleDiv.classList.add("sortable");
        pTitleDiv.addEventListener("click", () =>
            this._onChangeSort(pSortName, aSortControls)
        );
    }
    //__________________________________________________________________________________________
    private handleNumberFilter(pElement: HTMLElement, pVal: string): string {
        let aNum = parseFloat(pVal);
        if (isNaN(aNum)) {
            return pVal;
        }

        if (aNum == 0 && pElement.getAttribute("allow_zero") == "false") {
            return "";
        }

        let aFixed = parseInt(pElement.getAttribute("fixed"));
        let aFractionDigit = aFixed != null && !isNaN(aFixed) ? aFixed : 2;

        let aUnits = pElement.getAttribute("units");

        let aRet = aNum.toFixed(aFractionDigit);
        /**
         * @TODO
         */
        if (aUnits != null && aUnits != "") {
            let aUnitString = "";
            if (aUnits == "env") {
                /**
                 * @TODO
                 */
                aUnitString = " (" + UnitHandler.shortSign + ")";
            } else {
                aUnitString = " (" + aUnits + ")";
            }
            pElement.setAttribute("curr_unit", aUnitString);
        }

        return aRet;
    }
    //__________________________________________________________________________________________
    private fillOneAttributeData(aAttrDiv: HTMLElement, pAttrData: any, pPrivatePart: boolean, pAssemblyPart: boolean) {
        if (aAttrDiv == null) {
            return;
        }

        let aVal =
            pAttrData === null || pAttrData === "" ? MenuListAbstract.NA : pAttrData;
        let aIsNumberFilter =
            aAttrDiv.getAttribute("filter_type") ==
            MenuListAbstract.FILTER_TYPE_NUMBER;
        if (aIsNumberFilter) {
            aVal = this.handleNumberFilter(aAttrDiv, aVal);
        }

        let aTranslateIndex = aAttrDiv.getAttribute("translate_index");
        if (null != aTranslateIndex) {
            let aColumn = this.mMenuParams.columns[parseInt(aTranslateIndex)];
            aVal = aColumn.translateFunction!(pAttrData);
        }

        if (aAttrDiv.getAttribute("promoted") === "true" && aAttrDiv.id === "item_parameters.info.brand") {
            aAttrDiv.style.backgroundImage = `url("./images/brand_icons/${aVal}.png")`;
            aAttrDiv.style.backgroundSize = `70%`;
        } else if (pPrivatePart && aAttrDiv.id === "item_name") {
            this.addIconToField(aVal, aAttrDiv, private_img)
        } else if (pAssemblyPart && aAttrDiv.id === "item_info.parameters.catalog_number") {
            this.addIconToField(aVal, aAttrDiv, assembly_img)
        } else {
            aAttrDiv.innerHTML = aVal;
        }

        aAttrDiv.setAttribute("data-toggle", "tooltip");
        aAttrDiv.setAttribute("data-placement", "bottom");
        aAttrDiv.setAttribute("title", aVal);
    }
    //__________________________________________________________________________________________
    private addIconToField(pVal, pAttrDiv, aImg) {
        const aPrivateImg = document.createElement('img')
        aPrivateImg.style.width = '24px'
        aPrivateImg.style.height = '24px'
        aPrivateImg.style.padding = '6px 5.5px'
        const aPrivateNameBlock = document.createElement('div')
        aPrivateNameBlock.style.display = 'flex'
        aPrivateNameBlock.style.alignItems = 'center'
        aPrivateNameBlock.style.marginLeft = '-5px'
        const aPrivateName = document.createElement('div')
        aPrivateName.innerHTML = pVal
        aPrivateImg.src = aImg
        aPrivateNameBlock.append(aPrivateImg)
        aPrivateNameBlock.append(aPrivateName)
        pAttrDiv.append(aPrivateNameBlock);
    }
    //__________________________________________________________________________________________
    protected checkAddToCanvasLimit(): null | {
        limit: number;
        warningMessage: string;
    } {
        return null;
    }
    //__________________________________________________________________________________________
    protected addToCanvasList(
        e: Event,
        pElement: HTMLInputElement,
        pData: iOpticsVO | T
    ) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        let aIndexOfItem = this.mItemsToCanvasList.indexOf({
            element: pElement,
            item: pData as T,
        });

        let aRes = this.checkAddToCanvasLimit();
        let aLimit = aRes?.limit;
        let aWarningMessage = aRes?.warningMessage;

        if ((pElement as HTMLInputElement).checked == true) {
            if (this.mItemsToCanvasList.length == aLimit) {
                (pElement as HTMLInputElement).checked = false;
                this._showWarning({ message: aWarningMessage });
            } else {
                this.mItemsToCanvasList.push({
                    element: pElement as HTMLInputElement,
                    item: pData as T,
                });
            }
        } else {
            this.mItemsToCanvasList.splice(aIndexOfItem, 1);
        }

        if (
            this.mItemsToCanvasList.length > 0 &&
            this.mItemsToCanvasList.length < 2
        ) {
            this._getPart("compare_selected").classList.add("disabled");
            this.aAddToCanvasBtn.classList.remove("disabled");
        } else if (this.mItemsToCanvasList.length >= 2) {
            this.aAddToCanvasBtn.classList.remove("disabled");
            this._getPart("compare_selected").classList.remove("disabled");
        } else {
            this.aAddToCanvasBtn.classList.add("disabled");
        }

        this.changeSelectedCount();
    }
    //__________________________________________________________________________________________
    protected _fillOneItem(pData: T, pCloneItem: HTMLElement) {
        this.fillInfo(pData, false, pCloneItem);

        pCloneItem.setAttribute("qa_id", this.mSignelLineItemQaId);

        let aStar = Op3dUtils.getElementIn(
            pCloneItem,
            "starred",
            true
        ) as HTMLElement;
        if (aStar !== null) {
            if (this.mStarredItems.indexOf((pData as any).number_id) != -1) {
                aStar.style.fill = "#959e9e";
            }

            aStar.addEventListener("click", (e) =>
                this.addToFavorites(e, aStar, pData)
            );
        }

        let aItemSelected = Op3dUtils.getElementIn(
            pCloneItem,
            "item-selected",
            true
        ) as HTMLInputElement;
        if (aItemSelected !== null) {
            if (
                this.mItemsToCanvasList.findIndex(
                    (item) => (item.item as any).id === (pData as any).id
                ) != -1
            ) {
                aItemSelected.checked = true;
            }
            aItemSelected.addEventListener("change", (e) =>
                this.addToCanvasList(e, aItemSelected, pData)
            );
        }

        pCloneItem.addEventListener("click", (e) =>
            this._onClickItem(e, pCloneItem, aItemSelected, pData)
        );

        let aDropdown = Op3dUtils.getElementIn(
            pCloneItem,
            "dropdown",
            true
        ) as HTMLElement;
        aDropdown.addEventListener("click", (e) =>
            this.collapseExtra(e, aDropdown, pData as any)
        );
    }
    //__________________________________________________________________________________________
    private _onClickItem(
        e: MouseEvent,
        pCloneItem: HTMLElement,
        pItemCheckbox: HTMLInputElement,
        pData: T
    ) {
        const aFavouritedBlock =
            pCloneItem.getElementsByClassName("favorites-block")[0];

        if (aFavouritedBlock !== null) {
            let aElementIn = aFavouritedBlock.contains(e.target as HTMLElement);
            if (aElementIn === true) {
                return;
            }
        }

        pItemCheckbox.checked = !pItemCheckbox.checked;
        this.addToCanvasList(e, pItemCheckbox, pData);
    }
    //__________________________________________________________________________________________
    protected abstract _onSelectItem(pOpticItem: T | Array<T>): Promise<void>;

    //__________________________________________________________________________________________
    private removeRawKeys(pOpticsData: T | any) {
        for (const key in pOpticsData) {
            if (typeof pOpticsData[key] === "object" && pOpticsData[key] !== null) {
                if (pOpticsData[key].hasOwnProperty("raw")) {
                    pOpticsData[key] = pOpticsData[key].raw;
                }
                this.removeRawKeys(pOpticsData[key]);
            }
        }
        return pOpticsData;
    }
    //__________________________________________________________________________________________
    private async _onSelectItemEvent(pItemsData: Array<any>) {
        if (false == this.mIsVisible) {
            return;
        }

        await this._onSelectItem(pItemsData);

        this.mItemsToCanvasList = [];
    }
    //__________________________________________________________________________________________
    private async _loadNext() {
        let aTotal = this.mMenuParams.dataLoader.dataTotal;
        let aLimit = +this._getPart<HTMLSelectElement>("results").value;
        if ((1 + this.mCurrentPage) * aLimit < aTotal) {
            this.mCurrentPage++;
            this.mMenuParams.dataLoader.needsUpdate = true;
            await this._getResults(true, this.mClearOnExit);
        }
    }
    //__________________________________________________________________________________________
    private async _loadPrevious() {
        if (this.mCurrentPage !== 0) {
            this.mCurrentPage--;
            this.mMenuParams.dataLoader.needsUpdate = true;
            await this._getResults(true, this.mClearOnExit);
        }
    }
    //__________________________________________________________________________________________
    protected deleteSelected() { }
    //__________________________________________________________________________________________
    protected changeSelectedCount() {
        let aElements = document.getElementsByClassName("selected_number");
        for (let i = 0; i < aElements.length; i++) {
            if (this.mItemsToCanvasList.length == 0) {
                aElements[i].innerHTML = "";
                aElements[i].parentElement.parentElement.classList.add("disabled");
                this.aAddToCanvasBtn.classList.add("disabled");
            } else {
                aElements[i].parentElement.parentElement.classList.remove("disabled");
                aElements[i].innerHTML = `(${this.mItemsToCanvasList.length})`;
                if (this.mItemsToCanvasList.length === 1) {
                    this._getPart("compare_selected").classList.add("disabled");
                }
            }
        }
    }
    //__________________________________________________________________________________________
    private unselectItems() {
        this.mItemsToCanvasList = [];
        this._getPart("compare_selected").classList.add("disabled");

        this.changeSelectedCount();
        this._getResults(true, this.mClearOnExit);
    }
    //__________________________________________________________________________________________
    protected fillCompareTable(_pParts: Array<any>) { }
    //__________________________________________________________________________________________
    protected _showWarning(pOptions: {
        message: string;
        yesBtn?: { text: string; callback: Function };
        noBtn?: { text: string; callback?: Function };
    }) {
        this.mWarningMessageOptions = pOptions;
        this.mModalWarning.classList.remove("hidden");
        let aIsInitialized = this.mModalWarning.getAttribute("initialized");
        let aWarningMEssageHTML = Op3dUtils.getElementIn(
            this.mModalWarning,
            "warning-message"
        );
        if (aWarningMEssageHTML !== null) {
            aWarningMEssageHTML.innerHTML = pOptions.message;
        }
        let aYesButton = Op3dUtils.getElementIn(this.mModalWarning, "yes-warning");
        if (aYesButton !== null) {
            aYesButton.innerHTML =
                pOptions.yesBtn !== undefined ? pOptions.yesBtn.text : "OK";
        }

        let aNoBtn = Op3dUtils.getElementIn(this.mModalWarning, "cancel-warning");
        if (aNoBtn !== null) {
            if (pOptions.noBtn !== undefined) {
                aNoBtn.innerHTML = pOptions.noBtn.text;
                ViewUtils.setElementVisibilityByDNone(aNoBtn, true);
            } else {
                ViewUtils.setElementVisibilityByDNone(aNoBtn, false);
            }
        }

        if (aIsInitialized === null) {
            this.mModalWarning.setAttribute("initialized", "initialized");
            aYesButton?.addEventListener("click", () => this._onYesWarningBtn());
            aNoBtn?.addEventListener("click", () => this._onNoWarningBtn());
        }
    }
    //__________________________________________________________________________________________
    private _onNoWarningBtn() {
        this.mModalWarning.classList.add("hidden");
        if (
            this.mWarningMessageOptions.noBtn !== undefined &&
            this.mWarningMessageOptions.noBtn.callback !== undefined
        ) {
            this.mWarningMessageOptions.noBtn.callback();
        }
    }
    //__________________________________________________________________________________________
    private _onYesWarningBtn() {
        this.mModalWarning.classList.add("hidden");
        if (
            this.mWarningMessageOptions.yesBtn !== undefined &&
            this.mWarningMessageOptions.yesBtn.callback !== undefined
        ) {
            this.mWarningMessageOptions.yesBtn.callback();
        }
    }
    //__________________________________________________________________________________________
    private compareSelected() {
        this.mModalCompare.classList.remove("hidden");
        this.fillCompareTable(this.mItemsToCanvasList.map((item) => item.item));
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mTotalResultsElement = this._getPart("pagination");

        this.mModalWarning = this._getPart("modal-warning", true);
        this.mFilterTemplate = this._getPart("generic_filter_container", true);
        this.mFiltersParent = this.mFilterTemplate.parentElement as HTMLElement;
        ViewUtils.removeFromParent(this.mFilterTemplate);

        this.mNamesElement = this._getPart("names_element");

        this.mSingleListItem = this._getPart("single-list-item", true);
        this.mResultsList = this.mSingleListItem.parentElement as HTMLElement;
        this.mResultsListPromoted = this._getPart("items-container_promoted");

        this.mFavoriteFilter = this._getPart("starred_filter") as HTMLInputElement;
        this.mFavoriteFilter.addEventListener("click", () => {
            if (this.mFavoriteFilter.style.fill === "rgb(149, 158, 158)") {
                this.mFavoriteFilter.style.fill = "white";
            } else {
                this.mFavoriteFilter.style.fill = "rgb(149, 158, 158)";
            }
            this._getResults(true, this.mClearOnExit);
        });

        this._getPart("filter_basic").innerHTML = this.mMenuParams.category;
        this.mTitle = this._getPart("menu_title");
        this.mTitle.innerText = this.mMenuParams.title;

        if (
            Op3dContext.APP_FEATURES_CONFIG.optics_chatbot.enabled === true &&
            this.mMenuParams.isHelpMeSearchBtnExits === true
        ) {
            try {
                Op3dUtils.renderReactComponentIn(
                    this.mContainer,
                    "help-me-search-btn",
                    <OptiChatBtn />
                );
            } catch (e) { }
        } else {
            let aHelpMeSearchBtn = this._getPart("help-me-search-btn", true);
            ViewUtils.removeFromParent(aHelpMeSearchBtn);
        }

        this._getPart("results").addEventListener("change", () =>
            this._getResults(true, this.mClearOnExit)
        );

        this._getPart("delete_selected").addEventListener("click", () =>
            this.deleteSelected()
        );
        this._getPart("unselect_all").addEventListener("click", () =>
            this.unselectItems()
        );
        this._getPart("compare_selected").addEventListener("click", () =>
            this.compareSelected()
        );

        this.aAddToCanvasBtn = this._getPart("add_to_canvas");
        this.aAddToCanvasBtn.addEventListener("click", () =>
            this._onSelectItemEvent(this.mItemsToCanvasList.map((item) => item.item))
        );

        let aNextBtn = this._getPart("next-page");
        aNextBtn.addEventListener("click", () => this._loadNext());
        let aPrevBtn = this._getPart("previous-page");
        aPrevBtn.addEventListener("click", () => this._loadPrevious());

        let aClearAll = this._getPart("clear_all");
        EventManager.addEventListener(
            EventsContext.CLEAR_FILTERS,
            () => this._onResetFilters(),
            this
        );
        aClearAll.addEventListener("click", () => this._onResetFilters());

        this.mModalCompare = this._getPart("modal-compare", true);
        this._getPart("close-btn-compare", true).addEventListener("click", () => {
            this.mModalCompare.classList.add("hidden");
            Op3dUtils.getElementIn(this.mModalCompare, "compare-table").innerHTML =
                "";
        });

        let aSearchBtn = this._getPart("search-btn", true);
        aSearchBtn.addEventListener("click", () =>
            this._getResults(true, this.mClearOnExit)
        );

        this.mSearchInput = this._getPart("search-input", true) as HTMLInputElement;
        this.mSearchInput.addEventListener("keyup", (e: KeyboardEvent) =>
            this._onKeyUp(e)
        );

        this.mSingleTitleItem = this._getPart("single-title-item", true);
        this.mTitlesParent = this.mSingleTitleItem.parentElement as HTMLElement;

        ViewUtils.removeFromParent(this.mSingleTitleItem);
        ViewUtils.removeFromParent(this.mSingleListItem);

        this._initColumns();

        this._initUnits();

        this.mContainer.setAttribute("ml_unit", UnitHandler.shortSign);
    }
    //__________________________________________________________________________________________
    private _initUnits() {
        let aEnvUnit = UnitHandler.shortSign;
        let aInchBtn = this._getPart("inch_btn") as HTMLInputElement;
        aInchBtn.name = this.className + "Units";
        let aMMBtn = this._getPart("mm_btn") as HTMLInputElement;
        aMMBtn.name = this.className + "Units";
        aInchBtn.addEventListener("click", () => {
            this.mFiltersHandler.updateUnit(eUnitType.INCHES);
            this._getResults(true, this.mClearOnExit);
            let aShortSign = UnitHandler.getShortSign(eUnitType.INCHES);
            this.mContainer.setAttribute("ml_unit", aShortSign);
        });
        aMMBtn.addEventListener("click", () => {
            this.mFiltersHandler.updateUnit(eUnitType.MILLIMETERS);
            this._getResults(true, this.mClearOnExit);
            let aShortSign = UnitHandler.getShortSign(eUnitType.MILLIMETERS);
            this.mContainer.setAttribute("ml_unit", aShortSign);
        });

        this.mContainer.setAttribute("ml_unit", aEnvUnit);
    }
    //__________________________________________________________________________________________
    private _onResetFilters() {
        this.mCurrentPage = 0;
        this.resetAppliedFilters();
        this.mFavoriteFilter.style.fill = "white";
        this.mFiltersHandler.reset();
        this._getResults(true, this.mClearOnExit);
    }
    //__________________________________________________________________________________________
    private _onKeyUp(e: KeyboardEvent) {
        if (e.key != Strings.KEY_ENTER) {
            return;
        }

        e.preventDefault();
        this._getResults(true, this.mClearOnExit);
    }

    //__________________________________________________________________________________________
    protected resetAppliedFilters() {
        this._getPart("categories_list_container").innerHTML = "";
    }
    //__________________________________________________________________________________________
    private resetOneFilter(pPath: string) {
        this.mFiltersHandler.filterComponent(pPath).reset(true, true);
    }
    //__________________________________________________________________________________________
    protected updateAppliedFilters(pName: string, pFilter: any, pPath: string) {

        let aConvertedFromArray = Array.isArray(pFilter)
            ? pFilter.join(", ")
            : pFilter;
        let aSlicedFilter =
            aConvertedFromArray.length > 20
                ? aConvertedFromArray.slice(0, 20) + "..."
                : aConvertedFromArray;
        let aItem = this._getPart("category-item");
        let aItemClone = aItem.cloneNode(true) as HTMLElement;
        ViewUtils.setElementVisibilityByDNone(aItemClone, true);
        let aCategory = Op3dUtils.getElementIn(aItemClone, "category");
        aCategory!.innerHTML = pName + ": ";
        let aClearFilter = Op3dUtils.getElementIn(aItemClone, "clear-filter");
        aClearFilter.addEventListener("click", () => {
            if (pPath == "favorites") {
                this.mFavoriteFilter.style.fill = "white";
                this._getResults(true, this.mClearOnExit);
            } else {
                this.resetOneFilter(pPath);
            }
        });
        aCategory!.setAttribute("path", pPath);
        Op3dUtils.getElementIn(aItemClone, "filter")!.innerHTML = aSlicedFilter;
        this._getPart("categories_list_container").appendChild(aItemClone);
    }
    //__________________________________________________________________________________________
    public update(): Promise<void> {
        this.mCurrentPage = 0;
        this._getResults(true, this.mClearOnExit);
        return null as any;
    }
    //__________________________________________________________________________________________
    private addPromotionBrandsToQuery(
        pQueryString: string,
        pQueryElastic: iElasticQuery,
        pSearchIndex: string
    ) {
        let aQueryCopy: iElasticQuery = JSON.parse(JSON.stringify(pQueryElastic));

        aQueryCopy.filters.all.push({
            any: {
                [pSearchIndex === "PARTS" ? eOMPFilterName.brand : eOMFilterName.brand]:
                    pQueryString,
            },
        });
        aQueryCopy.page!.size = 1;
        aQueryCopy.page!.current = 1;
        return aQueryCopy;
    }
    //__________________________________________________________________________________________
    protected privateFilterApplied(pPermission: boolean) {
        pPermission
            ? this._getPart("footer_buttons").classList.add("private")
            : this._getPart("footer_buttons").classList.remove("private");
    }
    //__________________________________________________________________________________________
    protected abstract _getQuery();
    //__________________________________________________________________________________________
    protected addPromotedResults(
        _pQueryElastic: iElasticQuery,
        _pBrands: Array<string>
    ) { }
    //__________________________________________________________________________________________
    protected excludePromotedResults(pResults: Array<T>) {
        return pResults;
    }
    //__________________________________________________________________________________________
    public chosenLimit() {
        let aLimit = +this._getPart<HTMLSelectElement>("results").value;
        return aLimit;
    }
    //__________________________________________________________________________________________
    private resetAllCounts() {
        const aAllTitleItems = $(this.mFiltersParent).find(`[nameforcount]`);
        for (let i = 0; i < aAllTitleItems.length; i++) {
            let aNumberRegex = /\(\d+\)/g;
            aAllTitleItems[i].innerHTML = aAllTitleItems[i].innerHTML.replace(
                aNumberRegex,
                ""
            );
        }
    }

    //__________________________________________________________________________________________
    private setPermissionCount(
        pAggs,
        pAggsKey: string,
        pFilters: iHash<Object>,
        pFilterContainer: HTMLElement
    ) {
        let aPermissionFilter = pFilters["permission"] as Array<number>;

        if (aPermissionFilter.length > 1) {
            let aTotalCount = 0;
            for (let title of pAggs[pAggsKey].buckets) {
                aTotalCount += title.doc_count;
            }
            $(pFilterContainer).find(
                `[nameforcount="${"All"}"]`
            )[0].innerHTML += ` (${aTotalCount})`;
        } else if (aPermissionFilter[0] === eDataPermission.PRIVATE) {
            let aCount = pAggs[pAggsKey].buckets[0]?.doc_count;
            if (aCount === undefined) {
                aCount = 0;
            }
            $(pFilterContainer).find(
                `[nameforcount="${"Private"}"]`
            )[0].innerHTML += ` (${aCount})`;
        } else {
            let aCount = pAggs[pAggsKey].buckets[0]?.doc_count;
            if (aCount === undefined) {
                aCount = 0;
            }
            $(pFilterContainer).find(
                `[nameforcount="${"Public"}"]`
            )[0].innerHTML += ` (${pAggs[pAggsKey].buckets[0].doc_count})`;
        }
    }
    //__________________________________________________________________________________________
    protected setTypeCount(
        pAggs,
        pAggsKey: string,
        pFilters: iHash<Object>,
        pFilterContainer: HTMLElement
    ) {
        let aCount = 0;
        if (
            pFilters["parameters.type"] === undefined &&
            pFilters["info.parameters.type"] === undefined
        ) {
            for (let title of pAggs[pAggsKey].buckets) {
                aCount += title.doc_count;
            }
            let aData = $(pFilterContainer).find(`[nameforcount="All"]`)[0];

            if (aData === undefined) {
                return;
            }
            aData.innerHTML += ` (${aCount})`;
        } else {
            for (let title of pAggs[pAggsKey].buckets) {
                aCount += title.key;
                let aData = $(pFilterContainer).find(
                    `[nameforcount="${title.key}"]`
                )[0];

                if (aData === undefined) {
                    continue;
                }
                aData.innerHTML += ` (${title.doc_count})`;
            }
        }
    }

    //__________________________________________________________________________________________
    protected setCount(pAggs, pAggsKey: string, pFilterContainer: HTMLElement) {
        for (let title of pAggs[pAggsKey].buckets) {
            let aData = $(pFilterContainer).find(`[nameforcount="${title.key}"]`)[0];

            if (aData === undefined) {
                continue;
            }
            aData.innerHTML += ` (${title.doc_count})`;
        }
    }
    //__________________________________________________________________________________________
    public resetAllFilters() {
        this.mFiltersHandler.reset();
        this.mSearchInput.value = "";
    }
    //__________________________________________________________________________________________
    public getFilters() {
        return this.mFiltersHandler.getFilters();
    }
    //__________________________________________________________________________________________
    protected setCounts(pAggs: any) {
        for (let aggs of Object.keys(pAggs)) {
            let aFilterComponent = this.mFiltersHandler.filterComponent(aggs);
            let aFilterContainer = aFilterComponent.container;
            let aFilters = this.mFiltersHandler.getFilters();
            if (aggs === "permission") {
                this.setPermissionCount(pAggs, aggs, aFilters, aFilterContainer);
            }
        }
    }
    //__________________________________________________________________________________________
    private setFilterCounts() {
        this.resetAllCounts();
        let aFiltersToChange = this.mMenuParams.dataLoader.dataAggregation;
        this.setCounts(aFiltersToChange);
    }
    //__________________________________________________________________________________________
    protected async _getResults(
        pClearResults: boolean,
        pFromPart: boolean = false,
        pQuery?: iElasticQuery
    ) {
        this.mClearOnExit = pFromPart;
        Spinner.instance.show();
        try {
            if (pClearResults) {
                this._clearResults();
            }

            let aQuery = pQuery !== undefined ? pQuery : this._getQuery();
            let aRes = (await this.mMenuParams.dataLoader.getManyItems(
                aQuery
            )) as Array<T>;

            this.addPromotedResults(aQuery, ["Edmund Optics", "3DOptix"]);
            !(this.className === "SourcesMenu") && this.setFilterCounts();
            this._fillList(aRes);
        } catch (error) {
            Popup.instance.open({
                text: MessagesHandler.ERROR_SEARCH_MENU,
            });
            if (pClearResults == false) {
                this._getResults(true, this.mClearOnExit);
            }
        } finally {
            Spinner.instance.hide();
            this._changeAllColumnsPositions();
        }
    }

    //__________________________________________________________________________________________
    protected _onHidden(): void {
        this.mClearOnExit && this.mFiltersHandler.reload();
        this.mData = null;
        ServerContext.SERVER.editUserData(Op3dContext.USER_VO.userVO);
        this.mCurrentPage = 0;
        this.mClearOnExit = false;
        this.mItemsToCanvasList = [];
        this.mSearchInput.value = "";
        this.changeSelectedCount();
    }
    //__________________________________________________________________________________________
    public get searchString() {
        return this.mSearchInput.value;
    }
    //__________________________________________________________________________________________
    protected _clearResults() {
        this.mMenuParams.dataLoader.needsUpdate = true;
        ViewUtils.clearElementsChildren(this.mResultsList);
        ViewUtils.clearElementsChildren(this.mResultsListPromoted);
    }
    //__________________________________________________________________________________________
    public setFilters(_pData: any, _pQueryString?: string) { }
    //__________________________________________________________________________________________
}
