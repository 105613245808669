﻿import JSZip from "jszip";
import * as zip from "@zip.js/zip.js";

export class UnZip {
  private mCallback: Function;
  private mErrorCallback: Function;
  private mProgressCallback: Function;

  constructor(pURL: string | File, pCallback: Function, pProgressCallback: Function, pErrorCallback: Function) {
    this.mCallback = pCallback;
    this.mErrorCallback = pErrorCallback;
    this.mProgressCallback = pProgressCallback;
    this.getZipFile(pURL);
    // zip.workerScriptsPath = "./js/";
  }

  //--------------------------------------------
  private getZipFile(pURL: string | File): void {
    if (pURL instanceof File) {
      this.onDownloadZip(pURL);
      return;
    }

    //Op3dUtils.UPDATE_TIME('Go to Server', true);
    let aURl = pURL;
    let self = this;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", aURl, true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        self.onDownloadZip(xhr.response);
      } else if (xhr.status == 404) {
        self.mErrorCallback();
        console.log("status 404");
        return;
      }
    };
    xhr.onprogress = function (pProgress) {
      self.mProgressCallback(pProgress);
    };
    xhr.onerror = function () {
      console.log("Error callback");
      self.mErrorCallback();
      return;
    };

    xhr.responseType = "arraybuffer";
    if (xhr == null) {
      console.log("error xhr");
    }
    xhr.send();
  }
  //__________________________________
  private async onDownloadZip(pFile) {

    var blob = new Blob([pFile], { type: "octet/stream" });

    const reader = new zip.ZipReader(new zip.BlobReader(blob));
    const entries = await reader.getEntries();
    if (entries.length) {

      const currEntry = entries[0].compressedSize === 0 ? entries.find(entry => entry.compressedSize > 0) : entries[0];
      // get first entry content as text by using a TextWriter
      const text = await currEntry.getData(
        // writer
        new zip.TextWriter(),
        // options
      );
      // text contains the entry data as a String

      this.mCallback(text);

    }
  }
}
export const unZipMany = async (pBuffer: ArrayBuffer) => {
  let zip = new JSZip();
  let zipFile = await zip.loadAsync(pBuffer, {});

  return (<any>zipFile)
}
export const unZip = async (pBuffer: ArrayBuffer) => {
  let zip = new JSZip();
  let zipFile = await zip.loadAsync(pBuffer, {});

  let unzipped = await (zipFile as any)
    .file(Object.keys((zipFile as any).files)[0])
    .async("arraybuffer");

  return unzipped;
}