import { CylinderGeometry, Mesh, SphereGeometry, BoxGeometry, MeshPhongMaterial } from "three";
import { ColorUtils } from "../ui/ColorUtils";
import { OP3DMathUtils } from "./OP3DMathUtils";
import { eSmBaseShapeKind, iSmShapeData } from "../simulation/SimulationContext";
import { iPoint2D } from "../_context/_interfaces/Interfaces";
import { MathContext } from "../_context/MathContext";

export interface iMaterialOptions {
    alpha?: number;
    color?: string;
}

export interface iCylinderOptions extends iMaterialOptions {
    radius_top: number, radius_bottom: number, height: number; radius_x: number, radius_y: number
}

export interface iBoxOptions extends iMaterialOptions {
    width: number, height: number, depth: number
}

export interface iSphereOptions extends iMaterialOptions {
    radius: number
}

export interface iEllipsoidOptions extends iMaterialOptions {
    angle_x: number
    angle_y: number
}

export class ShapesUtils {

    //______________________________________________________________________________________________
    public static getShapeSize(pShapeData: iSmShapeData) {
        let aWidth: number = pShapeData.width;
        let aHeight: number = pShapeData.height;

        if (pShapeData.kind === eSmBaseShapeKind.CIRCLE) {

            aWidth = pShapeData.radius * 2;
            aHeight = pShapeData.radius * 2;

        } else if (pShapeData.kind === eSmBaseShapeKind.POLYGON) {

            let aX = pShapeData.points.filter((_item, idx) => idx % 2 != 0);
            aX = aX.sort();
            let aY = pShapeData.points.filter((_item, idx) => idx % 2 == 0);
            aY = aY.sort();
            let aW = aX[aX.length - 1] - aX[0];
            let aH = aY[aY.length - 1] - aY[0];
            aWidth = aW;
            aHeight = aH;
        }

        let aSize: iPoint2D = {
            x: aWidth,
            y: aHeight
        }

        return aSize;
    }
    //______________________________________________________________________________________________
    public static getCylinder(pOptions: iCylinderOptions) {


        let aGeo = new CylinderGeometry(pOptions.radius_top,
            pOptions.radius_bottom, pOptions.height, 50);
        aGeo.scale(pOptions.radius_x, 1, pOptions.radius_y);

        let aMat = this._getMatetial({ color: pOptions.color, alpha: pOptions.alpha });
        let aCylinder = new Mesh(aGeo, aMat);
        aCylinder.rotateX(Math.PI / 2);
        return aCylinder;
    }
    //___________________________________________________________________________
    public static getSphere(pOptions: iSphereOptions) {
        const aGeo = new SphereGeometry(pOptions.radius, 32, 32);
        const aMat = this._getMatetial({ color: pOptions.color, alpha: pOptions.alpha });
        return new Mesh(aGeo, aMat);
    }
    //___________________________________________________________________________
    public static getBox(pOptions: iBoxOptions) {
        const aGeo = new BoxGeometry(pOptions.width, pOptions.height, pOptions.depth);
        const aMat = this._getMatetial({ color: pOptions.color, alpha: pOptions.alpha });
        return new Mesh(aGeo, aMat);
    }
    //___________________________________________________________________________
    public static getEllipsoid(pOptions: iEllipsoidOptions) {
        const aSphereGeometry = new SphereGeometry(1, 32, 32);
        const aMiddle = (pOptions.angle_x + pOptions.angle_y) / 2;
        aSphereGeometry.scale(pOptions.angle_x, pOptions.angle_y, aMiddle);
        const aMat = this._getMatetial({ color: pOptions.color, alpha: pOptions.alpha });
        return new Mesh(aSphereGeometry, aMat);
    }
    //___________________________________________________________________________
    private static _getMatetial(pOptions: iMaterialOptions) {
        const aColor = pOptions.color != null ? pOptions.color : "#000";
        const aAlpha = pOptions.alpha != null ? OP3DMathUtils.clampValue(pOptions.alpha, 0, 1) : 1;
        const aNumberColor = ColorUtils.fromHTMLColorToNumberColor(aColor);
        return new MeshPhongMaterial({
            color: aNumberColor,
            transparent: aAlpha != 1,
            opacity: aAlpha
        });
    }
    //___________________________________________________________________________

}