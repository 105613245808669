import { LineSegments, Intersection, Mesh, Object3D } from "three";
import { Op3dContext } from "../../_context/Op3dContext";
import { tButton3DEvents } from "../../_context/Types";
import { iClientPoint } from "../../_context/_interfaces/Interfaces";
import { ClickManager } from "./ClickManager";
import { Part } from "../Part";
import { iEdge, iPart, iAxis, iFace, iSolid, iShape, iFaceDataNEW } from "../PartInterfaces";

export interface iEdgeData {
    edgeMesh: LineSegments;
    indices: Array<iEdge>;
    part: iPart
}
// export interface iVertexData {
//     vertexMesh: Mesh;
//     indices: Array<iVertex>
// }

export interface iEventData {
    axis?: iAxis;
    face?: iFace;
    solid?: iSolid;
    shape?: iShape;
    part?: iPart;
    edges?: iEdgeData;
    // vertices?: iVertexData;
    indexData?: iFaceDataNEW
};

export interface iEventParams {
    type: tButton3DEvents;
    func: (pMouseEvent: iClientPoint, pFaceIndex?: Intersection) => void;
};


export interface iOP3DButton {
    type: tButton3DEvents;
    func: (pEventData: iEventData, pMouseEvent: iClientPoint, pFaceIndex?: Intersection) => void;
}

export class Button3D {

    //__________________________________________________________________________________________
    private static _addAxesListeners(pCM: ClickManager,
        pOP3DButton: Array<iOP3DButton>, pEventData: iEventData) {

        pEventData.axis.object3D.traverse((obj) => {
            if (obj instanceof Mesh) {
                for (let i = 0; i < pOP3DButton.length; i++) {
                    let aEventParams: iEventParams = {
                        type: pOP3DButton[i].type,
                        func: (pMouseEvent: iClientPoint, pFaceIndex?: Intersection) =>
                            pOP3DButton[i].func(pEventData, pMouseEvent, pFaceIndex)
                    };
                    pCM.addEventListener(obj, aEventParams);
                }
            }
        });
    }
    //__________________________________________________________________________________________
    public static removeEventListeners(pPart: iPart) {
        if (null == pPart) {
            return;
        }

        Op3dContext.INPUT_DISPATCHER.clickManager.removeObjectEventListener(pPart.object3D);
    }
    //__________________________________________________________________________________________
    public static addEventListeners(pPart: iPart, pOP3DButton: Array<iOP3DButton>) {
        if (null == pPart) {
            return;
        }

        let aCM = Op3dContext.INPUT_DISPATCHER.clickManager;

        if (null != pPart.axes) {
            for (let axis = 0; axis < pPart.axes.length; axis++) {
                let aAxisEventData: iEventData = {
                    part: pPart,
                    axis: pPart.axes[axis]
                };

                this._addAxesListeners(aCM, pOP3DButton, aAxisEventData)
            }
        }

        let aShapes = pPart.shapes;
        if (null != aShapes) {
            for (let i = 0; i < aShapes.length; i++) {
                if (null != aShapes[i].axes) {
                    for (let axis = 0; aShapes[i].axes.length; axis++) {
                        let aAxisEventData: iEventData = {
                            shape: aShapes[i],
                            part: pPart,
                            axis: aShapes[i].axes[axis]
                        };

                        this._addAxesListeners(aCM, pOP3DButton, aAxisEventData)
                    }
                }

                let aEdges = aShapes[i].edges;
                if (null != aEdges) {
                    // if (eClickMode.BASE ==
                    //     Op3dContext.PARTS_EVENTS_HANDLER.mode) {

                    let aMesh = aShapes[i].edgesMesh;
                    for (let l = 0; l < pOP3DButton.length; l++) {
                        let aEventData: iEventData = {
                            edges: {
                                edgeMesh: aMesh,
                                indices: aEdges,
                                part: pPart
                            }
                        };

                        let aEventParams: iEventParams = {
                            type: pOP3DButton[l].type,
                            func: (pMouseEvent: iClientPoint, pFaceIndex?: Intersection) =>
                                pOP3DButton[l].func(aEventData, pMouseEvent, pFaceIndex)
                        };

                        aCM.addEventListener(aMesh, aEventParams);
                    }
                    // }
                }

                let aSolids = aShapes[i].solids;
                if (null != aSolids) {
                    for (let j = 0; j < aSolids.length; j++) {
                        if (null != aSolids[j].axes) {
                            for (let axis = 0; axis < aSolids[j].axes.length; axis++) {
                                let aAxisEventData: iEventData = {
                                    shape: aShapes[i],
                                    solid: aSolids[j],
                                    part: pPart,
                                    axis: aSolids[j].axes[axis]
                                };

                                this._addAxesListeners(aCM, pOP3DButton, aAxisEventData)
                            }
                        }

                        let aFaces = aSolids[j].faces;
                        if (null != aFaces) {
                            for (let k = 0; k < aFaces.length; k++) {
                                if (null != aFaces[k].axes) {
                                    for (let axis = 0; axis < aFaces[k].axes.length; axis++) {
                                        let aAxisEventData: iEventData = {
                                            shape: aShapes[i],
                                            solid: aSolids[j],
                                            face: aFaces[k],
                                            part: pPart,
                                            axis: aFaces[k].axes[axis]
                                        };

                                        this._addAxesListeners(aCM, pOP3DButton, aAxisEventData)
                                    }
                                }

                                // if (eClickMode.BASE ==
                                //     Op3dContext.PARTS_EVENTS_HANDLER.mode) {

                                let aMesh = aFaces[k].visualization.mesh;
                                for (let l = 0; l < pOP3DButton.length; l++) {
                                    let aEventData: iEventData = {
                                        face: aFaces[k],
                                        solid: aSolids[j],
                                        shape: aShapes[i],
                                        part: pPart
                                    };

                                    let aEventParams: iEventParams = {
                                        type: pOP3DButton[l].type,
                                        func: (pMouseEvent: iClientPoint, pFaceIndex?: Intersection) =>
                                            pOP3DButton[l].func(aEventData, pMouseEvent, pFaceIndex)
                                    };

                                    aCM.addEventListener(aMesh, aEventParams);
                                }
                                // }
                            }
                        }
                    }
                }
            }
        }

        if (null != pPart.subParts) {
            for (let subpartIndex = 0; subpartIndex < pPart.subParts.length; subpartIndex++) {
                this.addEventListeners(pPart.subParts[subpartIndex], pOP3DButton);
            }
        }
    }
    //__________________________________________________________________________________________
    public static addRaysButton(pRays: Object3D, pOP3DButton: Array<iOP3DButton>) {
        let aCM = Op3dContext.INPUT_DISPATCHER.clickManager;
        pRays.traverse(obj => {
            if (obj instanceof LineSegments) {

                for (let i = 0; i < pOP3DButton.length; i++) {
                    let aEventParams: iEventParams = {
                        type: pOP3DButton[i].type,
                        func: (pMouseEvent: iClientPoint, pEventData: any) => pOP3DButton[i].func(pEventData, pMouseEvent)
                    };
                    aCM.addEventListener(obj, aEventParams);
                }
            }
        })
    }
    //___________________________________________________________________________________________
    public static removeRaysButton(pRays: Object3D) {
        pRays.traverse(obj => {
            if (obj instanceof LineSegments) {
                this.removeButtonByObject3D(obj);
            }
        })
    }
    //___________________________________________________________________________________________
    public static addEventListener(pPart: iPart, pEventParams: iEventParams) {
        let aCM = Op3dContext.INPUT_DISPATCHER.clickManager;

        let aShapes = pPart.shapes;
        if (null != aShapes) {
            for (let i = 0; i < aShapes.length; i++) {

                let aEdges = aShapes[i].edges;
                if (null != aEdges) {
                    let aMesh = aShapes[i].edgesMesh;
                    aCM.addEventListener(aMesh, pEventParams);
                }


                // let aVertices = aShapes[i].vertices;
                // if (null != aEdges) {
                //     let aMesh = aShapes[i].vertexMesh;
                //     aCM.addEventListener(aMesh, pEventParams);

                // }


                let aSolids = aShapes[i].solids;
                if (null != aSolids) {
                    for (let j = 0; j < aSolids.length; j++) {
                        let aFaces = aSolids[j].faces;
                        if (null != aFaces) {
                            for (let k = 0; k < aFaces.length; k++) {
                                let aMesh = aFaces[k].visualization.mesh;
                                aCM.addEventListener(aMesh, pEventParams);
                            }
                        }
                    }
                }
            }
        }

        if (null != pPart.subParts) {
            for (let subpart = 0; subpart < pPart.subParts.length; subpart++) {
                this.addEventListener(pPart.subParts[subpart], pEventParams);
            }
        }
    }
    //__________________________________________________________________________________________
    public static removeEventListers(pPart: Part) {
        this.removeButtonByObject3D(pPart.visibleObj);
    }
    //__________________________________________________________________________________________
    public static removeButtonByObject3D(pObject3D: Object3D) {
        if (null == pObject3D) {
            return;
        }

        pObject3D.traverse((pItem: Object3D) => {

            // if (pItem instanceof Mesh) {
            Op3dContext.INPUT_DISPATCHER.clickManager.removeClick(pItem);
            //} 
            //else {
            //  console.log(pItem);
            //}
        });
    }
}