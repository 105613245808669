import { Strings } from "../../../_context/Strings";
import { iPoint2D, iMinMax, iSize } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { UnitHandler } from "../../../units/UnitsHandler";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisCanvasUtils } from "../AnalysisCanvasUtils";
import { iGraphUIParams, eAnalysisDisplay, AnalysisContext, iBasicGraphDataParams } from "../AnalysisContext";
import { anAnalysisResultyFactory, iDrawData } from "./anAnalysisResultyFactory";


export interface iCreateThumbnailAnalysisOptions {
    container: HTMLElement,
    graphData: iBasicGraphDataParams,
    uiParams: iGraphUIParams,
}

export class anSmallResultFactory extends anAnalysisResultyFactory {

    constructor() { super(); }

    //__________________________________________________________________________________________
    public createResult(pOptions: iCreateThumbnailAnalysisOptions): iDrawData {

        let aCanvasContainer = Op3dUtils.getElementIn(pOptions.container, "analysis-canvas-container");
        if (aCanvasContainer === null) {
            throw new Error("missing canvas container");

        } else {
            ViewUtils.clearElementsChildren(aCanvasContainer);
        }

        switch (pOptions.graphData.analysisItem.display) {
            case eAnalysisDisplay.FALSE_COLOR:
            case eAnalysisDisplay.GRAY_SCALE:
            case eAnalysisDisplay.SPECTRAL_VIEW:
                this._createSpotStructure(aCanvasContainer);
                return this._createSpot(pOptions);
        }
    }
    //__________________________________________________________________________________________
    private _drawSpot(pOptions: iCreateThumbnailAnalysisOptions): iDrawData {

        const aGraphContainer = Op3dUtils.getElementIn(pOptions.container,
            AnalysisContext.GRAPH_CONTAINER);

        const aGraphResult = AnalysisCanvasUtils.initCanvas({
            fitToContainer: true,
            container: aGraphContainer,
            containerID: AnalysisContext.CANVAS_CONTAINER,
            isAdditionalCanvas: false,
            isTextual: false
        });

        const aOptions = AnalysisContext.SPOT_OPTIONS;
        const aStartPoint: iPoint2D = {
            x: aOptions.GRAPH_START.x * aGraphResult.size.width,
            y: aOptions.GRAPH_START.y * aGraphResult.size.height,
        }
        const aGraphContainerSize: iSize = {
            width: aOptions.GRAPH_SIZE.width * aGraphResult.size.width,
            height: aOptions.GRAPH_SIZE.height * aGraphResult.size.height,
        }

        // background------------------------------------------------------------
        aGraphResult.ctx.fillStyle = "#ffffff";
        aGraphResult.ctx.fillRect(aStartPoint.x, aStartPoint.y, aGraphContainerSize.width, aGraphContainerSize.height);
        aGraphResult.ctx.lineWidth = 1;
        //------------------------------------------------------------------------
        const aResolution = pOptions.graphData.cacheData.common_data.resolution;
        let aCanvas = pOptions.graphData.canvas;

        if ((aCanvas instanceof CanvasRenderingContext2D) == false) {
            aCanvas = AnalysisCanvasUtils.drawImage(pOptions.graphData.cacheData.matrix.working.matrix,
                aResolution, pOptions.graphData.analysisItem.display,
                pOptions.graphData.cacheData.matrix.working.values_range,
                pOptions.graphData.cacheData.common_data.spectralColors);
            pOptions.graphData.canvas = aCanvas;
        }

        let aWidth = DataUtils.getSize(pOptions.graphData.cacheData.common_data.x_range);
        let aHeight = DataUtils.getSize(pOptions.graphData.cacheData.common_data.y_range);

        let aRes: { size: iSize, start: iPoint2D<number> } = this._addRegularImage({
            ctx: aGraphResult.ctx,
            graphContainerSize: aGraphContainerSize,
            start: aStartPoint,
            canvas: aCanvas,
            sourceSize: {
                width: aResolution.x,
                height: aResolution.y
            },
            keepProportion: true,
            actualSurfaceSize: { width: aWidth, height: aHeight }
        });

        // general
        aGraphResult.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        aGraphResult.ctx.font = pOptions.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;

        //add x ticks -------------------------------------------------------------
        this._addXTicks({
            ctx: aGraphResult.ctx,
            size: { height: aRes.size.height, width: aRes.size.width },
            start: aRes.start,
            x_ticks: pOptions.graphData.range_points.x,
            count: aOptions.SPOT_X_TICKS_COUNT,
            uiParams: pOptions.uiParams,
            type: pOptions.graphData.analysisItem.display
        });
        //------------------------------------------------------------------------

        //add y ticks -------------------------------------------------------------
        this._addYTicks({
            ctx: aGraphResult.ctx,
            size: { height: aRes.size.height, width: aRes.size.width },
            start: aRes.start,
            y_ticks: pOptions.graphData.range_points.y,
            count: aOptions.SPOT_Y_TICKS_COUNT,
            uiParams: pOptions.uiParams,
            type: pOptions.graphData.analysisItem.display
        });

        let aDrawRes: iDrawData = {
            context: aGraphResult.ctx,
            rectSize: aRes.size,
            totalSize: aRes.size,
            startPoint: aRes.start
        };
        return aDrawRes;
    }
    //__________________________________________________________________________________________
    private _createSpot(pOptions: iCreateThumbnailAnalysisOptions): iDrawData {

        const aHasSpectrum = pOptions.graphData.analysisItem.display !== eAnalysisDisplay.SPECTRAL_VIEW;

        if (aHasSpectrum) {
            this._createGradientBar(pOptions);
        }

        AnalysisCanvasUtils.addAxisLabels(pOptions);
        const aDrawResult = this._drawSpot(pOptions);
        return aDrawResult;
    }
    //__________________________________________________________________________________________
    private _createSpotStructure(pContainer: HTMLElement) {

        let aTopDiv = document.createElement("div");

        let aLeftDiv = document.createElement("div");
        aLeftDiv.classList.add(Strings.ANSLYSIS_CLASS, Strings.W_79, Strings.H_100, Strings.DISPLAY_FLEX_CLASS);

        let aYLabelParent = document.createElement("div");
        aYLabelParent.classList.add(Strings.ANSLYSIS_CLASS, Strings.W_10,
            Strings.H_100);

        let aYLabel = document.createElement("div");
        aYLabel.id = AnalysisContext.Y_LABEL_CONTAINER;
        aYLabel.classList.add(Strings.ANSLYSIS_CLASS, AnalysisContext.Y_LABEL_CONTAINER);
        aYLabelParent.appendChild(aYLabel);

        let aGraphContainer = document.createElement("div");
        aGraphContainer.id = AnalysisContext.GRAPH_CONTAINER;
        aGraphContainer.style.position = "relative";

        aGraphContainer.classList.add(Strings.ANSLYSIS_CLASS, Strings.W_90, Strings.H_100);

        aLeftDiv.appendChild(aYLabelParent);
        aLeftDiv.appendChild(aGraphContainer);

        let aSpectrumContainer = document.createElement("div");
        aSpectrumContainer.id = AnalysisContext.SPECTRUM_BAR_CONTAINER;
        aTopDiv.appendChild(aLeftDiv);

        aSpectrumContainer.classList.add(Strings.ANSLYSIS_CLASS, Strings.W_21, Strings.H_100);
        aTopDiv.appendChild(aSpectrumContainer);

        let aBottomDiv = document.createElement("div");
        aBottomDiv.id = AnalysisContext.X_LABEL_CONTAINER;
        pContainer.appendChild(aTopDiv);
        pContainer.appendChild(aBottomDiv);

        aTopDiv.classList.add(Strings.ANSLYSIS_CLASS, Strings.H_92, Strings.DISPLAY_FLEX_CLASS);
        aBottomDiv.classList.add(Strings.ANSLYSIS_CLASS, Strings.H_8, AnalysisContext.X_LABEL_CONTAINER);
    }
    //__________________________________________________________________________________________
    /**
     * @param pOptions draw the graph canvas image on the main canvas
     */
    //__________________________________________________________________________________________
    private _addRegularImage(pOptions: {
        ctx: CanvasRenderingContext2D,
        graphContainerSize: iSize,
        actualSurfaceSize?: iSize,
        start: iPoint2D,
        canvas: HTMLCanvasElement,
        sourceSize: iSize,
        fitToContainer?: boolean
        keepProportion?: boolean
    }) {

        /**
         * how much from the image we will copy
         */
        let aSourceSize: iSize = {
            width: pOptions.sourceSize.width,
            height: pOptions.sourceSize.height
        }

        let aMaxSize = Math.max(aSourceSize.width, aSourceSize.height);
        let aMinGraphSize = Math.min(pOptions.graphContainerSize.width, pOptions.graphContainerSize.height);
        let aRatio = aMinGraphSize / aMaxSize;
        let aNewSourceSize: iSize = pOptions.sourceSize;
        let aNewStart: iPoint2D = pOptions.start;

        /**
         * the image should fit to the given container
         */

        const aStartClipImage: iPoint2D = { x: 0, y: 0 };

        // keep proportion
        if (pOptions.keepProportion == true) {

            let aRatio2 = aMinGraphSize / Math.max(pOptions.actualSurfaceSize.width, pOptions.actualSurfaceSize.height);

            aNewSourceSize = {
                width: pOptions.actualSurfaceSize.width * aRatio2,
                height: pOptions.actualSurfaceSize.height * aRatio2,
            }

            aNewStart = {
                x: pOptions.start.x + (pOptions.graphContainerSize.width / 2) - aNewSourceSize.width / 2,
                y: pOptions.start.y + (pOptions.graphContainerSize.height / 2) - aNewSourceSize.height / 2
            }

            pOptions.ctx.drawImage(
                pOptions.canvas,
                aStartClipImage.x, aStartClipImage.y,
                aSourceSize.width, aSourceSize.height,
                aNewStart.x, aNewStart.y,
                aNewSourceSize.width, aNewSourceSize.height);
        }

        if (pOptions.fitToContainer == false) {

            aNewSourceSize = {
                width: aSourceSize.width * aRatio,
                height: aSourceSize.height * aRatio
            }

            aNewStart = {
                x: pOptions.start.x + (pOptions.graphContainerSize.width / 2) - aNewSourceSize.width / 2,
                y: pOptions.start.y + (pOptions.graphContainerSize.height / 2) - aNewSourceSize.height / 2
            }


        }



        pOptions.ctx.drawImage(
            pOptions.canvas,
            aStartClipImage.x, aStartClipImage.y,
            aSourceSize.width, aSourceSize.height,
            aNewStart.x, aNewStart.y,
            aNewSourceSize.width, aNewSourceSize.height,
        );

        return { start: aNewStart, size: aNewSourceSize };
    }
    //__________________________________________________________________________________________
    private _addYTicks(pParams: {
        ctx: CanvasRenderingContext2D,
        start: iPoint2D,
        size: iSize,
        y_ticks: iMinMax,
        count: number,
        uiParams: iGraphUIParams,
        type: eAnalysisDisplay
    }) {

        pParams.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        pParams.ctx.font = pParams.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;

        let aCurrValue = pParams.y_ticks.max;
        let aDelta = (pParams.y_ticks.max - pParams.y_ticks.min) / (pParams.count - 1);
        let aDeltaPixels = pParams.size.height / (pParams.count - 1);
        let aCurrPos = pParams.start.y;

        let aPresentedScale = 1;
        switch (pParams.type) {
            case eAnalysisDisplay.FALSE_COLOR:
            case eAnalysisDisplay.GRAY_SCALE:
            case eAnalysisDisplay.SPECTRAL_VIEW:
                aPresentedScale = UnitHandler.presentedScale;
                break;
            default:
                break;
        }

        for (let i = 0; i < pParams.count; i++) {
            let aPresentedValue = OP3DMathUtils.roundNum((aCurrValue * aPresentedScale), 6);
            let aTxtLength = aPresentedValue.toFixed(0).length;
            let aToFixed = anSmallResultFactory.Y_TICKS_TOTAL_LENGTH - aTxtLength > 0 ?
                anSmallResultFactory.Y_TICKS_TOTAL_LENGTH - aTxtLength :
                0;


            let aTextWidth = pParams.ctx.measureText(parseFloat(aPresentedValue.toFixed(aToFixed)).toString()).width;
            let aPosY = aCurrPos;
            if (i == 0) {
                //first
                aPosY = pParams.start.y + (pParams.uiParams.ticks_font_size / 2);
            } else if (i == pParams.count - 1) {
                //last
                aPosY = pParams.start.y + pParams.size.height;
            }

            pParams.ctx.fillText(
                parseFloat(aPresentedValue.toFixed(aToFixed)).toString(),
                pParams.start.x - aTextWidth - 2,
                aPosY);

            aCurrPos += aDeltaPixels;
            aCurrValue -= aDelta;
        }
    }
    //__________________________________________________________________________________________
    private _addXTicks(pParams: {
        start: iPoint2D,
        ctx: CanvasRenderingContext2D,
        x_ticks: iMinMax, size: iSize,
        count: number,
        uiParams: iGraphUIParams,
        type: eAnalysisDisplay
    }) {

        pParams.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        pParams.ctx.font = pParams.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;

        //add x ticks -------------------------------------------------------------
        let aCurrValue = pParams.x_ticks.min;
        let aDelta = (pParams.x_ticks.max - pParams.x_ticks.min) / (pParams.count - 1);
        let aDeltaPixels = pParams.size.width / (pParams.count - 1);
        let aCurrPos = pParams.start.x;

        for (let i = 0; i < pParams.count; i++) {
            let aPresentedValue = OP3DMathUtils.roundNum((aCurrValue * UnitHandler.presentedScale), 6);
            let aTxtLength = aPresentedValue.toFixed(0).length;
            let aToFixed = anSmallResultFactory.X_TICKS_TOTAL_LENGTH - aTxtLength > 0 ?
                anSmallResultFactory.X_TICKS_TOTAL_LENGTH - aTxtLength :
                0;


            let aTextWidth = pParams.ctx.measureText(parseFloat(aPresentedValue.toFixed(aToFixed)).toString()).width;

            let aPosX = aCurrPos - (aTextWidth / 2);
            if (i == 0) {
                //first
                aPosX = pParams.start.x;
            } else if (i == pParams.count - 1) {

                //last
                aPosX = pParams.start.x + pParams.size.width - aTextWidth;
            }

            pParams.ctx.fillText(parseFloat(aPresentedValue.toFixed(aToFixed)).toString(),
                aPosX,
                pParams.start.y + pParams.size.height + pParams.uiParams.ticks_font_size);

            aCurrPos += aDeltaPixels;
            aCurrValue += aDelta;
        }
    }
    //__________________________________________________________________________________________
    private _getSpectrumBar(pType: eAnalysisDisplay, pCtx: CanvasRenderingContext2D,
        pSize: iSize,
        pStartPoint: iPoint2D, pSpectrumRange: iMinMax): CanvasGradient {

        switch (pType) {

            case eAnalysisDisplay.FALSE_COLOR: {
                let aGradient = pCtx.createLinearGradient(pStartPoint.x, pStartPoint.y,
                    pStartPoint.x + pSize.width, pStartPoint.y + pSize.height);

                if (pSpectrumRange.max == pSpectrumRange.min) {
                    aGradient.addColorStop(0, '#0000ff');
                    aGradient.addColorStop(1, '#0000ff');

                } else {
                    aGradient.addColorStop(0, '#ff0000');
                    aGradient.addColorStop(1 / 4, 'orange');
                    aGradient.addColorStop(2 / 4, 'yellow');
                    aGradient.addColorStop(3 / 4, '#00ff00');
                    aGradient.addColorStop(1, '#0000ff');
                }

                return aGradient;
            }
            case eAnalysisDisplay.GRAY_SCALE: {
                let aGradient = pCtx.createLinearGradient(pStartPoint.x, pStartPoint.y,
                    pStartPoint.x + pSize.width, pStartPoint.y + pSize.height);

                if (pSpectrumRange.max == pSpectrumRange.min) {
                    aGradient.addColorStop(0, 'black');
                    aGradient.addColorStop(1, 'black');

                } else {
                    aGradient.addColorStop(0, 'white');
                    aGradient.addColorStop(1, 'black');
                }

                return aGradient;
            }
            default:
                return null;
        }
    }
    //__________________________________________________________________________________________
    public static addAxisLabels(pContainer: HTMLElement,
        pGraphData: iBasicGraphDataParams,
        pUIParams: iGraphUIParams) {
        // x axis label ----------------------------------------------------------
        const aXLabelContainer = Op3dUtils.getElementIn(pContainer,
            AnalysisContext.X_LABEL_CONTAINER);
        AnalysisCanvasUtils.addXLabelContainer(aXLabelContainer, pGraphData.labels.x, pUIParams);
        //------------------------------------------------------------------------

        // y axis label ----------------------------------------------------------
        const aYLabelContainer = Op3dUtils.getElementIn(pContainer,
            AnalysisContext.Y_LABEL_CONTAINER);
        AnalysisCanvasUtils.addYLabelContainer(aYLabelContainer, pGraphData.labels.y, pUIParams);
        //------------------------------------------------------------------------
    }
    //__________________________________________________________________________________________
    private _createGradientBar(pOptions: iCreateThumbnailAnalysisOptions) {

        const aGradientContainer = Op3dUtils.getElementIn(pOptions.container,
            AnalysisContext.SPECTRUM_BAR_CONTAINER);

        let aResult = AnalysisCanvasUtils.initCanvas({
            container: aGradientContainer,
            isAdditionalCanvas: false,
            containerID: AnalysisContext.CANVAS_CONTAINER,
            fitToContainer: true,
            isTextual: true
        });

        // drawing the spectrum bar 
        let aBarSize: iSize = {
            width: aResult.size.width * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE.width,
            height: aResult.size.height * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE.height
        };

        if (aBarSize.width > AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.width) {
            aBarSize.width = AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.width;
        }

        if (aBarSize.height > AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.height) {
            aBarSize.height = AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_SIZE_LIMIT.height;
        }

        const aStart: iPoint2D = {
            x: aResult.size.width * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_START.x,
            y: aResult.size.height * AnalysisContext.SPOT_OPTIONS.SPECTRUM_BAR_START.y
        };

        const aGradient = this._getSpectrumBar(pOptions.graphData.analysisItem.display,
            aResult.ctx, aBarSize, aStart, pOptions.graphData.spectrumData.range);
        aResult.ctx.fillStyle = aGradient;
        aResult.ctx.fillRect(aStart.x, aStart.y, aBarSize.width, aBarSize.height);
        aResult.ctx.strokeRect(aStart.x, aStart.y, aBarSize.width, aBarSize.height);
        aResult.ctx.strokeStyle = "black";
        aResult.ctx.lineWidth = 1;

        // title 
        aResult.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.general;
        aResult.ctx.font = pOptions.uiParams.ticks_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;
        let aLines = pOptions.graphData.spectrumData.label.split(' ');
        const aTextStart: iPoint2D = {
            x: aStart.x,
            y: aStart.y - (pOptions.uiParams.ticks_font_size * (aLines.length + 0.5)),
        }

        for (let i = 0; i < aLines.length; i++) {
            aResult.ctx.fillText(aLines[i], aTextStart.x, aTextStart.y + (i) * pOptions.uiParams.ticks_font_size);
        }

        // preferences
        const aDeltaVal = (pOptions.graphData.spectrumData.range.max - pOptions.graphData.spectrumData.range.min) /
            (AnalysisContext.SPOT_OPTIONS.BAR_TICKS_COUNT - 1);

        const aDeltaPixels = aBarSize.height / (AnalysisContext.SPOT_OPTIONS.BAR_TICKS_COUNT - 1);
        let aCurrText = pOptions.graphData.spectrumData.range.min;
        let aTextX = aStart.x + aBarSize.width + (pOptions.uiParams.ticks_font_size / 2);
        let aTextY = aStart.y + aBarSize.height + (pOptions.uiParams.ticks_font_size / 2);

        // tickes of spectrum bar
        for (let i = 0; i < AnalysisContext.SPOT_OPTIONS.BAR_TICKS_COUNT; i++) {
            let aTxtLength = aCurrText.toFixed(0).length;
            let aToFixed = pOptions.uiParams.spectrum_bar_string_length - aTxtLength > 0 ?
                pOptions.uiParams.spectrum_bar_string_length - aTxtLength :
                pOptions.uiParams.spectrum_bar_string_length;

            aResult.ctx.fillText(aCurrText.toFixed(aToFixed), aTextX, aTextY);
            aCurrText += aDeltaVal;
            aTextY -= aDeltaPixels;
        }
    }
    //__________________________________________________________________________________________
}
