import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { NotificationCenter } from '../../../ui/home/_notifications/NotificationCenter';
import { eNotificationToastDuration, eNotificationType } from '../../../ui/home/_notifications/NotificationsContext';
import BlackBoxList from './BlackBoxList';
import { Op3dContext } from '../../../_context/Op3dContext';
import { Part } from '../../Part';
import { MessagesHandler } from '../../../_context/MessagesHandler';
import { _createBlackBoxInMongo, _saveBlackBoxSetupToCopy, groupParts, isForBlackBox, updatePartsList } from '../utils/BlackBoxUtils';
import { Box3 } from 'three';
import { BlackBoxInputField } from './BlackBoxInputField';
import { BlackBoxInputColorField } from './BlackBoxInputColorField';
import { BlackBoxHeader } from './BlackBoxHeader';
import { defaultValues } from './BlackBoxConstants';
import { OptixPartDisplayer } from '../../_parts_assets/OptixPartDisplayer';
import Draggable from 'react-draggable';
import { BlackBoxInputDiameterField } from './BlackBoxInputDiameterField';

export interface eBlackBoxSetting {
    front_margin: string,
    back_margin: string,
    barrel_outer_diameter: string,
    barrel_color: string
}


export function BlackBox({ onClose, counter, setCounter }) {
    const [parts, setParts] = useState(new Set<Part>());
    const [name, setName] = useState('BlackBox');
    const [minimalDiameter, setMinimalDiameter] = useState<string>(defaultValues.DIAMETER);
    const aBlackBoxSettings = useRef({ front_margin: defaultValues.FRONT_OFFSET, back_margin: defaultValues.BACK_OFFSET, barrel_outer_diameter: defaultValues.DIAMETER, barrel_color: defaultValues.COLOR });
    const boundingBox = useRef<Box3>(new Box3())


    const updateInputs = (pValue: string, pCase: string) => {
        aBlackBoxSettings.current[pCase] = pValue
    }

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleGroupPart = async () => {
        const { success } = await groupParts(parts, boundingBox.current, name, aBlackBoxSettings.current)
        if (success === true) {
            setCounter(prevState => prevState + 1)
            onClose()
        }
    }

    const handePartsListChange = (pPart: Part) => {
        updatePartsList(pPart, parts, setParts, boundingBox.current, setMinimalDiameter)
    }

    const handleCloseBlackBox = () => {
        for (let part of parts) {
            OptixPartDisplayer.unHighlightObject(part)
        }
        onClose()
    }

    useEffect(() => {
        Op3dContext.PARTS_MANAGER.unHiglightAllParts()
        setName(name + counter)
    }, [])

    useEffect(() => {
        const handleAddElement = (event) => {
            const newElement = event.detail as Part;
            if (!isForBlackBox(newElement)) {
                NotificationCenter.instance.pushNotification({
                    message: MessagesHandler.BLACK_BOX_ADD_NOTIFICATION,
                    toastDuration: eNotificationToastDuration.MEDIUM,
                    params: {
                        type: eNotificationType.CONSTRUCTION_COMMENT,
                        title: 'BLACK BOX TOOL - Notification',
                        color: '#23A7DE',
                        isColorBoxVisible: true
                    }
                });
                return
            }
            handePartsListChange(newElement)
        };
        window.addEventListener('addPart', handleAddElement);

        return () => {
            window.removeEventListener('addPart', handleAddElement);
        };
    }, [parts]);




    return ReactDOM.createPortal(
        <div style={ { width: '100vw', height: '100vh', position: 'absolute', top: '0' } }>
            <Draggable
                bounds='parent'
                defaultPosition={ { x: 272, y: 105 } }
                handle=".modal-header">
                <div className="modal-dialog m-0 black_box">
                    <div className="modal-content ">
                        <BlackBoxHeader onClose={ handleCloseBlackBox } />
                        <div className="modal-body ">
                            <div className="d-flex title">
                                <img src='./images/icons_new/toolbar/BB.svg' alt="Black box tool" />
                                <input type='text' onChange={ handleChange } value={ name } />
                            </div>

                            { parts.size === 0
                                ?
                                <div className="empty_state text-center" >Click on objects to add</div>
                                :
                                <div className="parts_list_block">
                                    <BlackBoxList parts={ parts } handePartsListChange={ handePartsListChange } />

                                </div>
                            }
                        </div>

                        <hr className="m-0" />
                        { parts.size !== 0 &&
                            <div className="black_box-margin">
                                <BlackBoxInputField title='Front margin' changeInput={ updateInputs } defaultValue={ defaultValues.FRONT_OFFSET } />
                                <BlackBoxInputField title='Back margin' changeInput={ updateInputs } defaultValue={ defaultValues.BACK_OFFSET } />
                                <BlackBoxInputDiameterField title='Barrel outer diameter' changeInput={ updateInputs } diameterData={ { defaultDiameter: minimalDiameter } } />
                                <BlackBoxInputColorField title='Barrel color' changeInput={ updateInputs } defaultValue={ defaultValues.COLOR } />
                            </div>
                        }

                        <div className="modal-footer">

                            <button type="button" className="btn btn-primary" onClick={ handleCloseBlackBox } id="cancel_btn" >Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={ handleGroupPart } disabled={ parts.size === 0 && true } id="save_btn" >OK</button>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
        , document.getElementById('forms')
    )
}
