import { eCadType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../../parts/Part";
import { iPartData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { ViewUtils } from "../ViewUtils";
import { OptomechanicsOpticsForm } from "../forms/optics/OptomechanicsOpticsForm";
import { PartInfoSection } from "./PartInfoSection";
import { iOpticsSection } from "./piOpticsSettingsSection";

export interface iOptomechanicsOpticsSection {
    part: Part,
    partData: iPartData
};

export class piOptomechanicsOpticsSettingsSection extends PartInfoSection<iOptomechanicsOpticsSection> {

    private mOpticsName: HTMLElement;
    private mOpticalSettings: HTMLElement;
    private mPart: Part;
    private mSectionTitle: HTMLElement;
    private mTypeTitle: HTMLElement;
    private mHideFaces: HTMLInputElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_optomechanics_optics_settings_section.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mOpticsName = this._getPart("optics_name");
        this.mSectionTitle = this._getPart("cad_section_title");
        this.mTypeTitle = this._getPart("cad_type_title");

        this.mOpticalSettings = this._getPart('optomechanics_optical_settings');
        this.mOpticalSettings.addEventListener("click", () => this._openOpticsForm());

        this.mHideFaces = this._getPart('cad_hide_inactive') as HTMLInputElement;

        this.mHideFaces.addEventListener('change', () => {
            this._hideInactiveSurfaces(this.mHideFaces.checked);
        })

        let aTooltips = $(this.mContainer).find('[data-toggle="tooltip"]');
        for (let i = 0; i < aTooltips.length; i++) {
            let aText = aTooltips[i].title;
            aTooltips[i].removeAttribute('title');
            aTooltips[i].addEventListener('mouseenter', () => {
                let aBB = aTooltips[i].getBoundingClientRect();

                Op3dContext.TOOLTIP.show({
                    clientX: aBB.left,
                    clientY: aBB.bottom
                }, aText)
            });
            aTooltips[i].addEventListener('mouseleave', () => Op3dContext.TOOLTIP.hide());
        }
    }
    //__________________________________________________________________________________________
    protected _hideInactiveSurfaces(pHide: boolean) {
        let aHide = { opacity: Op3dContext.UNMUTABLE_MESH_MATERIAL_OPACITY, transparent: true };
        let aUsedProperty = { opacity: 1, transparent: false };
        if (pHide === true) aUsedProperty = aHide;
        let aFaces = this.mPart.getFaces();
        this.mPart.unHighlightObject();
        for (let i = 0; i < aFaces.length; i++) {
            let aCopy = aFaces[i].visualization.mesh;
            let aCopyMat = (aCopy.material as any).clone();
            aCopyMat.opacity = aUsedProperty.opacity;
            aCopyMat.transparent = aUsedProperty.transparent;
            aCopy.material = aCopyMat;
        }
        this.mPart.unHighlightObject();
    }
    //__________________________________________________________________________________________
    protected _isSurfaceHided() {

        return (this.mPart.getFaces()[0].visualization.mesh.material as any).opacity === Op3dContext.UNMUTABLE_MESH_MATERIAL_OPACITY
    }
    //__________________________________________________________________________________________
    protected async _fillSection(pOpticsSection: iOpticsSection) {
        this._clear();
        this.mPart = pOpticsSection.part;
        this.mOpticsName.innerHTML = this.mPart.getLabel().label;
        this.mSectionTitle.innerHTML = 'CAD Optics Settings';
        this.mTypeTitle.innerHTML = 'Optical Element';
        if (this.mPart.opticalPartType == eCadType.OPTO_MECHANICS) {
            this.mSectionTitle.innerHTML = 'CAD Optomechanical Settings';
            this.mTypeTitle.innerHTML = 'Optomechanical Element';
        }
        let aSurfaceHided = this._isSurfaceHided();
        this.mHideFaces.checked = aSurfaceHided;
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mOpticsName.innerHTML = 'No optical element';
        ViewUtils.setElementActive(this.mOpticsName, false);
        this.mOpticsName.removeAttribute('data-original-title');
    }
    //__________________________________________________________________________________________
    private async _openOpticsForm() {
        OptomechanicsOpticsForm.instance.open({
            part: Op3dContext.PARTS_MANAGER.selectedPart
        });
    }
    //__________________________________________________________________________________________
}