import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { Op3dContext } from "./Op3dContext";

export type tCategoryType = "simulation" | "widget";
export class AnalyticsEventsContext {


  public static CLICK_ANALYSES_PORTAL = "click_analyses_portal";
  public static CLICK_PROPAGATION_SIMULATION = "click_propagation_simulation";
  public static CLICK_PROPAGATION_SIMULATION_WIDGET = "click_propagation_simulation_widget"
  public static RUN_ANALYSIS = "run_analysis";
  private static GET_OPTICS_RESULT = "get_optics_result";
  private static GET_OPTICS_RESULT_PROMOTED = "get_optics_result_promoted";
  private static GET_PARTS_RESULT = "get_parts_result";
  public static CONNECT_SYSTEM = "connect_system";
  public static FINISH_SESSION = "finish_session";
  public static REGISTER = "user_register";

  private static APP_SIMULATION = "simulation";
  // private static APP_WIDGET = "widget";
  public static LOGIN = "login";
  public static CONNECT = "connect";
  public static DISCONNECT = "disconnect";

  private static START_TIME: number;
  //_______________________________________________________________________________________
  public static triggerEventOf(pEventName: string, pCategory: tCategoryType, pMail: string) {
    (window as any).gtag('event', pEventName, {
      'event_category': pCategory,
      'event_label': pMail,
      'value': pMail
    });
  }
  //_______________________________________________________________________________________
  public static triggerEvent(pEventName: string, pCategory: tCategoryType) {
    (window as any).gtag('event', pEventName, {
      'event_category': pCategory,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email
    });
  }
  //_______________________________________________________________________________________
  // public static triggerWidgetAnalyticsEvent() {
  //   gtag('event', AnalyticsEventsContext.CLICK_PROPAGATION_SIMULATION_WIDGET, {
  //     'event_category': this.APP_WIDGET,
  //     'event_label': Op3dContext.USER_VO.email,
  //     'value': Op3dContext.USER_VO.email

  //   });
  // }
  //_______________________________________________________________________________________
  public static onChoseOptics(pOptic: iOpticsVO) {
    (window as any).gtag('event', this.GET_OPTICS_RESULT, {
      'event_category': this.APP_SIMULATION,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email,
      'brand': pOptic["parameters.info.brand"],
      'type': pOptic["parameters.type"],
      'sub_type': pOptic["parameters.subType"],
      'shape': pOptic["parameters.baseShape"],
    });
  }
  //_______________________________________________________________________________________
  public static triggerOpticsPromotedSearchAnalyticsEvent(pParams: {
    brand: object, type: string, subType: string,
    baseShape: string, searchString: string
  }) {
    (window as any).gtag('event', this.GET_OPTICS_RESULT_PROMOTED, {
      'event_category': this.APP_SIMULATION,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email,
      'brand': pParams.brand,
      'type': pParams.type,
      'sub_type': pParams.subType,
      'shape': pParams.baseShape,
      'search_string': pParams.searchString
    });
  }
  //_______________________________________________________________________________________
  public static triggerOpticsSearchAnalyticsEvent(pParams: {
    brand: object, type: string, subType: string,
    baseShape: object, searchString: string
  }) {
    (window as any).gtag('event', this.GET_OPTICS_RESULT, {
      'event_category': this.APP_SIMULATION,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email,
      'brand': pParams.brand,
      'type': pParams.type,
      'sub_type': pParams.subType,
      'shape': pParams.baseShape,
      'search_string': pParams.searchString
    });
  }
  //_______________________________________________________________________________________
  public static triggerPartsSearchAnalyticsEvent(mainSection: string, section: string,
    category: string, subCategory: string, searchedWord: string) {
    (window as any).gtag('event', this.GET_PARTS_RESULT, {
      'event_category': this.APP_SIMULATION,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email,
      'main_section': mainSection,
      'section': section,
      'category': category,
      'sub_category': subCategory,
      'searched_word': searchedWord
    });
  }
  //_______________________________________________________________________________________
  public static triggerConnectSystem(pEvent: "login" | "connect") {
    AnalyticsEventsContext.START_TIME = new Date().getTime();
    (window as any).gtag('event', pEvent, {
      'event_category': this.APP_SIMULATION,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email
    });
  }
  //_______________________________________________________________________________________
  public static triggerFinishSession(pUnloadEvent?: boolean) {
    let aParams = {
      'event_category': this.APP_SIMULATION,
      'event_label': Op3dContext.USER_VO.email,
      'value': Op3dContext.USER_VO.email,
      'timestamp': AnalyticsEventsContext.timePassed
    }

    if (pUnloadEvent == true) {
      aParams['transport_type'] = 'beacon'
    }

    (window as any).gtag('event', this.DISCONNECT, aParams);
  }
  //_______________________________________________________________________________________
  private static get timePassed() {
    let millis = new Date().getTime() - this.START_TIME;
    var minutes = Math.floor(millis / 60000);
    var seconds = Math.round((millis % 60000) / 1000);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
}
  //_______________________________________________________________________________________