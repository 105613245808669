import JSZip from "jszip";
import { eSimulationType, eBucketToRetrieve } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import {
    iPoint2D, iHash, iRGB, iRunSimulationParams, iSimulationPayload, iVizualisationResultData,
    iRunAnalysesObject, iSingleAnalysisObject, iNameURL, iSimulationMaps, iOneSimulationResultData, iSimulationResultPayload, iAnalysisServerResult, iClientSimulationPayload, iRunCheck
} from "../_context/_interfaces/Interfaces";
import { FileUtils } from "../_utils/FileUtils";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { Popup } from "../ui/forms/Popup";
import { NotificationCenter } from "../ui/home/_notifications/NotificationCenter";
import { eNotificationToastDuration } from "../ui/home/_notifications/NotificationsContext";
import {
    eLaserColorType, iSimulationReportItem, eSimulationReportError, eSmRaysKind, SimulationContext, eSmBaseShapeKind
} from "./SimulationContext";
import { EventManager } from "../../oc/events/EventManager";
import { ServerContext, ENVS } from "../server/ServerContext";
import { ColorUtils } from "../ui/ColorUtils";
import { ViewUtils } from "../ui/ViewUtils";
import { AnalysesSynchronizer } from "../ui/analysis/AnalysesSynchronizer";
import { AnalysisCache } from "../ui/analysis/AnalysisCache";
import { eAnalysisType, tAnalysisType } from "../ui/analysis/AnalysisContext";
import { AnalysisDataUtils } from "../ui/analysis/AnalysisDataUtils";
import { AnalysisPortal } from "../ui/analysis/AnalysisPortal";
import { QuickViewHandler } from "../ui/analysis/QuickViewHandler";
import { Spinner } from "../ui/home/Spinner";
import { RaysDisplayer, iRayDataFile } from "./RaysDisplayer";
import { unZip } from "../../loaders/UnZip";
import { saveAs } from 'file-saver';
import { Vector3 } from "three";
import { iFace } from "../parts/PartInterfaces";
import { GaussianBeamTable } from "../ui/analysis/GaussianBeamTable";
import { GaussianRayTracer } from "./GaussianRayTracer";
import { Part } from "../parts/Part";

export interface iAnalysisResponseData {
    content: ArrayBuffer;
    faceId: string;
    analysisType: eAnalysisType;
    faceSize: iPoint2D;
    analysis_id: string;
    analysis_name: tAnalysisType
}

export interface iSimulationRaysOptions {
    simulationType: eSimulationType,
    /**
     *@description  only if the simulation typeis analysis
     */
    // analysisType?: eAnalysisType
}

export interface iRayFileReader {
    //idx?: number;
    Ox?: number;
    Oy?: number;
    Oz?: number;
    Dx?: number;
    Dy?: number;
    Dz?: number;
    wavelength_idx?: number;
    //As?: number;
    Ap?: number;
    Hx?: number;
    Hy?: number;
    Hz?: number;
    hit_surface_idx?: number;
    //parent_idx?: number;
    source_idx?: number;
    //family_idx?: number;
    //diffraction_order?: number;
    //phase?: number;
};

export interface iSurfaceMapItem {
    index: number;
    faceId: string;
    size?: iPoint2D;
}

export class SimulationRunner {
    private static FORCE_RUN_GPU: boolean = false;
    private static DEBUG_SIMULATION: boolean = false;

    private static INSTANCE: SimulationRunner;
    private mRaysDisplayer: RaysDisplayer = new RaysDisplayer();
    private mCurrentRayData: Array<iRayDataFile>;
    // private mSurfacesMap: Array<{ index: number, faceId: string, size?: iPoint2D }> = [];

    private mWlToRGB: iHash<iRGB> = {};

    private mFresnelHash: iHash<number> = {};
    public mAbortController: AbortController;
    private mLightBtnState: HTMLElement;

    private constructor() {

        AnalysesSynchronizer.instance.init();

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this._onNew(), this);

        this.mLightBtnState = document.getElementById("toggle_all_rays");
    }
    //______________________________________________________________________________________________
    public addGaussianRays(pVertices: Array<number>, pColors: Array<number>) {
        this.mRaysDisplayer.addGaussianMesh(pVertices, pColors);
    }
    //______________________________________________________________________________________________
    public isRaysDrawn() {
        let aRays = this.mRaysDisplayer.raysOnScene;

        return (aRays !== null && aRays.length > 0);
    }
    //______________________________________________________________________________________________
    public isRaysShown() {
        return this.mRaysDisplayer.raysOnScene != null && this.mRaysDisplayer.isRaysShown()
    }
    //______________________________________________________________________________________________
    public isRaysShownOfLaser(pPart: Part) {
        return this.mRaysDisplayer.isRaysShown(pPart)
    }
    //______________________________________________________________________________________________
    public toggleRaysOfLaser(pPart: Part) {
        return this.mRaysDisplayer.toggleRaysOfLaser(pPart)
    }
    //______________________________________________________________________________________________
    public setRaysVisibility(pToShow: boolean) {
        this.mRaysDisplayer.setRaysVisibility(pToShow)
    }
    //______________________________________________________________________________________________
    private _setLightBtnState() {
        if (this.mRaysDisplayer.raysOnScene != null) {
            ViewUtils.setElementDisabled(this.mLightBtnState, false)
        }
        if (this.mRaysDisplayer.isRaysShown()) {
            this.mLightBtnState.classList.replace('shown', 'hidden')
        } else {
            this.mLightBtnState.classList.replace('hidden', 'shown')
        }
        for (let i = 0; i < Op3dContext.PARTS_MANAGER.parts.length; i++) {
            let aPart = Op3dContext.PARTS_MANAGER.parts[i];
            let aLaserBehavior = aPart.getBehavior('laserBehavior');
            if (aLaserBehavior !== undefined && aLaserBehavior.showRays === false) {
                SimulationRunner.instance.toggleRaysOfLaser(aPart);
            }
        }
    }
    //______________________________________________________________________________________________
    public static get instance(): SimulationRunner {
        if (this.INSTANCE == null) {
            this.INSTANCE = new SimulationRunner();
        }
        return this.INSTANCE;
    }
    //______________________________________________________________________________________________
    public get colorType() {
        return this.mRaysDisplayer.laserColorType;
    }
    //______________________________________________________________________________________________
    public set setColorType(pLaserColorType: eLaserColorType) {
        this.mRaysDisplayer.laserColorType = pLaserColorType;
        this.mRaysDisplayer.removeRays();
        this.mRaysDisplayer.draw();
    }
    //______________________________________________________________________________________________
    private _onNew() {
        this.mWlToRGB = {};
        this.mCurrentRayData = [];

        this.mRaysDisplayer.clearScene()
        ViewUtils.setElementDisabled(this.mLightBtnState, true)
    }
    //______________________________________________________________________________________________
    private _isCanRunSimulation() {
        /**
         * @condition 1
         * there are at least one laser on scene
         */


        let aHasLaser = false;
        const aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            const element = aParts[i];
            if (element.getBehavior("laserBehavior") != null) {
                aHasLaser = true;
                break;
            }
        }

        if (aHasLaser == false) {
            return MessagesHandler.NO_LASER_FOUND_FOR_SIMULATION;
        }

        return null;
    }
    //______________________________________________________________________________________________
    public onStopVisualization() {
        /**
         * @Costi 
         * check this out 
         */
        SimulationRunner.instance.mAbortController.abort()
    }
    //______________________________________________________________________________________________
    public async runVisualizationSimulation(pWithoutListener?: boolean) {

        const aShowSpinner = Spinner.instance.spinnerCallBack;
        QuickViewHandler.isAnalysisFromQuickView = false;

        let aErrorMessage = this._isCanRunSimulation();
        if (aErrorMessage != null) {
            Popup.instance.open({ text: aErrorMessage });
            return;
        }
        try {
            aShowSpinner('Uploading...', () => this.onStopVisualization());
            Op3dContext.PARTS_MANAGER.setSelectedPart(null);
            await this.startVisualizationSimulation(pWithoutListener, aShowSpinner);
            Spinner.instance.hide();


        } catch (e) {
            Spinner.instance.hide();
            //DEBUG
            console.error(e);
        }
    }
    //______________________________________________________________________________________________
    public get rayData(): Array<iRayDataFile> {
        return this.mCurrentRayData;
    }
    //______________________________________________________________________________________________
    public draw() {
        this.mRaysDisplayer.show(this.mCurrentRayData);
    }
    //______________________________________________________________________________________________
    public async loadRayTracingVisualization() {
        const aSetupID = Op3dContext.SETUPS_MANAGER.currSetupID;
        let aIsSimulationExist = await this._isSimulationExist(aSetupID);
        if (false === aIsSimulationExist) {
            return;
        }

        this._onVisualisationReady(aSetupID, false);
    }
    //______________________________________________________________________________________________
    public async _isSimulationExist(pSetupID: string) {
        const aChangesInSetup = Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup;
        const aSimulationExists = await ServerContext.SERVER.isRaysFileExists({ _id: pSetupID });

        return ((true === aSimulationExists.data) && (false === aChangesInSetup) &&
            (false === SimulationRunner.FORCE_RUN_GPU) &&
            (false === SimulationRunner.DEBUG_SIMULATION));
    }
    //______________________________________________________________________________________________
    private _getUserSetupPrefix(pSetupID: string) {
        const aOwnerId = Op3dContext.USER_VO.id;
        let aUserPrefix = ServerContext.user_prefix_setups;
        let aPrefix = `${aUserPrefix}${aOwnerId}/${pSetupID}`;

        return aPrefix;
    }
    //______________________________________________________________________________________________
    public async _onVisualisationReady(pSetupID: string, pToSave: boolean = true) {
        const aPrefix = this._getUserSetupPrefix(pSetupID);
        const aMapsURL = `${aPrefix}/maps.json?V=${Op3dUtils.idGenerator()}`;
        const aRayTableURL = `${aPrefix}/output_rays.csv?V=${Op3dUtils.idGenerator()}`;

        let aSimulationResultData: iVizualisationResultData = {
            maps: await fetch(aMapsURL).then(res => res.text()),
            ray_table: await fetch(aRayTableURL).then(res => res.text())
        };

        this.mCurrentRayData = this._parseRayData(aSimulationResultData);
        this.draw();
        this._setLightBtnState();

        if (true !== Op3dContext.isWidget) {
            Op3dContext.SETUPS_MANAGER.setupParameters.simulation_version_to_compare++
        }

        if (true === pToSave) {
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = false;
            Op3dContext.SCENE_HISTORY.saveScene(false, true);
        }
    }
    //______________________________________________________________________________________________
    private async _runVisualisationSimulation(pSetupID: string, pParams: iRunSimulationParams) {
        this.mAbortController = new AbortController();

        let aSimulationPayload: iSimulationPayload = {
            gpu_type: Op3dContext.GPU_MACHINE,
            setup_id: pSetupID,
            snellius_version: Op3dContext.DATA_MANAGER.snelliusVersion,
            debug: SimulationRunner.DEBUG_SIMULATION,
            from_api: false,
            is_sync: true
        };

        let aOriginalResult = await ServerContext.SERVER.runSimulation(aSimulationPayload,
            this.mAbortController.signal, pParams.progressCallback);

        if (this.mAbortController.signal.aborted) {
            return;
        }

        let aResult = aOriginalResult.data as iClientSimulationPayload;
        if (false === aOriginalResult.success) {
            if (true === (aResult as any).isVersionError) {
                MessagesHandler.SETUP_VERSION_ERROR();
                return false;
            }

            aResult = JSON.parse(aResult as any) as iClientSimulationPayload;;
            this._onVisualisationError(aResult.result);
            this._downloadDebugData(aResult.result);

            return false;
        }

        let aPayload = aResult.result.data;
        this._showReport(aPayload.report);
        this._downloadDebugData(aResult.result);

        return true;
    }
    //______________________________________________________________________________________________
    private _onVisualisationError(pSimulationResult: iOneSimulationResultData) {
        let aErrorMessage = MessagesHandler.SIMULATION_FAILED;
        if ((null !== pSimulationResult) && (undefined !== pSimulationResult) &&
            (undefined !== pSimulationResult.error) &&
            (undefined !== pSimulationResult.error.message)) {
            if (MessagesHandler[pSimulationResult.error.message]) {
                aErrorMessage = MessagesHandler[pSimulationResult.error.message];
            }
        }

        Popup.instance.open({ text: aErrorMessage });
    }
    //______________________________________________________________________________________________
    private async _runSimulation(pParams: iRunSimulationParams) {
        if (!Op3dContext.USER_VO.userCanRunSimulation_GPULimit) {
            Popup.instance.open({ text: 'GPU usage limit exceeded' });
            return;
        }

        const aSetupID = Op3dContext.SETUPS_MANAGER.currSetupID;
        let aIsSimulationExist = await this._isSimulationExist(aSetupID);

        if (Op3dUtils.checkBlackBoxCollision() !== true) {
            EventManager.dispatchEvent(EventsContext.ON_START_SIMULATION, this);
        }


        if (false === aIsSimulationExist) {
            let aIsSucceed = await this._runVisualisationSimulation(aSetupID, pParams);
            if (false === aIsSucceed) {
                return;
            }
        }

        await this._onVisualisationReady(aSetupID);
        if (undefined === pParams.withoutListener) {
            EventManager.dispatchEvent(EventsContext.ON_FINISH_SIMULATION, this);
        }
    }
    //______________________________________________________________________________________________
    private _showReport(pReport: Array<iSimulationReportItem>) {
        if (pReport == null || pReport.length == 0) {
            return;
        }

        for (let i = 0; i < pReport.length; i++) {
            let aType: string;
            switch (pReport[i].type) {
                case eSimulationReportError.COATING_ERROR:
                    aType = "coatings";
                    break;
                case eSimulationReportError.MATERIAL_ERROR:
                    aType = "materials";
                    break;
            }
            NotificationCenter.instance.pushNotification({
                message: `This scene includes optical elements with ${aType} that are not supported in some of the emitted wavelengths. A ray that hits such an optical element, will terminate.`,
                params: NotificationCenter.NOTIFICATIONS_TYPES.SIMULATION,
                toastDuration: eNotificationToastDuration.MEDIUM
            });
        }
    }
    //______________________________________________________________________________________________
    public downloadCSV() {

        if (!this.mCurrentRayData || this.mCurrentRayData.length === 0) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.ANALYSIS_MISSING_LASER,
                params: NotificationCenter.NOTIFICATIONS_TYPES.SIMULATION,
            });
            return
        }
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.jsonToCsv(this.mCurrentRayData)));
        element.setAttribute('download', 'data');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    //______________________________________________________________________________________________
    private jsonToCsv(items) {
        const header = 'id,Ox,Oy,Oz,Dx,Dy,Dz,Wavelength,As,Ap,Phase,Hx,Hy,Hz,ParentId'
        let CSV = header
        for (let obj of items) {
            let part1 = `${obj.id},${obj.exit.x},${obj.exit.y},${obj.exit.z},`
            let part2 = `${obj.direction.x},${obj.direction.y},${obj.direction.z},${obj.wavelength},${obj.As},${obj.Ap},${obj.phase},${obj.endPoint.x},${obj.endPoint.y},${obj.endPoint.z},${obj.parentID}`
            CSV += '\r\n' + part1 + part2
        }

        return CSV;
    }
    //______________________________________________________________________________________________
    public removeFresnelFarFromHash(pId: string) {
        this.mFresnelHash[pId] = null;
    }
    //______________________________________________________________________________________________
    public async runAnalysesByIds(pOptions: iRunAnalysesObject) {
        try {
            AnalysisCache.clearByIds(pOptions.analysisToRun);
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.ANALYSIS_START,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL
            });

            let aAnalysesToRun = pOptions.analysisToRun;
            for (let i = 0, l = aAnalysesToRun.length; i < l; i++) {
                let aAnalysis = Op3dContext.PARTS_MANAGER.getAnalysisById(aAnalysesToRun[i]);

                this._runSingleAnalysis({
                    gpu_type: Op3dContext.GPU_MACHINE,
                    setup_id: Op3dContext.SETUPS_MANAGER.currSetupID,
                    snellius_version: Op3dContext.DATA_MANAGER.snelliusVersion,
                    debug: SimulationRunner.DEBUG_SIMULATION,
                    from_api: false,
                    is_sync: true,
                    analyses_to_run: [aAnalysesToRun[i]]
                }, {
                    type: aAnalysis.type,
                    onFinish: pOptions.onFinish,
                    runningAnalysis: { id: aAnalysis.id, name: aAnalysis.name },
                    bucket: pOptions.analysisBuckets[aAnalysesToRun[i]],
                    signal: pOptions.signal
                });
            }
        } catch (e) {
            pOptions.onFinish(false, e, MessagesHandler.ANALYSIS_FINISHED_ERROR);
        }
    }
    //______________________________________________________________________________________________
    private _printAnalysisRequest(pRequest: iSimulationPayload) {

        if (ServerContext.env === ENVS.PROD) {
            return;
        }

        console.log("---------------------------------------------")
        console.log("requst", pRequest)
        console.log("analysis daya", pRequest.analyses_to_run);
        console.log("setup_id", pRequest.setup_id);
        console.log("---------------------------------------------")
    }
    //______________________________________________________________________________________________
    private async _getAnalysisFromS3(pSetupID: string, pParams: iSingleAnalysisObject) {
        let aRetriveFromS3: iNameURL;
        const aAnalysisExists = await ServerContext.SERVER.isExistsAnalysisResult({
            setup_id: pSetupID,
            analysis_id: pParams.runningAnalysis.id
        });

        if ((true === aAnalysisExists.data) && (eBucketToRetrieve.S3 === pParams.bucket) &&
            (false === SimulationRunner.DEBUG_SIMULATION) &&
            (false === SimulationRunner.FORCE_RUN_GPU)) {

            const aOwnerId = Op3dContext.SETUPS_MANAGER.ownerId;
            let aUserPrefix = ServerContext.user_prefix_setups;
            let aAnalysisID = pParams.runningAnalysis.id;
            const aFileName = `${aAnalysisID}_analysis_0.bin.zip?V=${Op3dUtils.idGenerator()}`;
            const aTemplate = `${aUserPrefix}${aOwnerId}/${pSetupID}/analyses/${aFileName}}`;

            aRetriveFromS3 = { name: aFileName, url: aTemplate };
        }

        return aRetriveFromS3;
    }
    //______________________________________________________________________________________________
    private _onAnalysisError(pContent: any, pParams: iSingleAnalysisObject) {
        let aMessage = MessagesHandler.ANALYSIS_FINISHED_ERROR;
        try {
            aMessage = pContent.data.error.message;
            if (aMessage == "USAGE_LIMIT_EXCEEDED") {
                aMessage = "GPU usage limit exceeded";
            }
        } catch (e) {
            aMessage = MessagesHandler.ANALYSIS_FINISHED_ERROR;
        }
        pParams.onFinish(false, pContent, aMessage, pParams.runningAnalysis.id);
    }
    //__________________________________________________________________________________________
    public async getSimulationFiles(pPayloadObj: iSimulationResultPayload) {
        //What is widget analysis
        if (pPayloadObj.analysis && pPayloadObj.analysis.length) {
            for (let i = 0; i < pPayloadObj.analysis.length; i++) {
                let aContent = await ServerContext.SERVER.httpGetFile(pPayloadObj.analysis[i].url);
                pPayloadObj.analysis[i].content = aContent;
            }
        }

        return pPayloadObj;
    }
    //______________________________________________________________________________________________
    private async _runSingleAnalysis(pRequest: iSimulationPayload, pParams: iSingleAnalysisObject) {
        AnalysisPortal.instance.isTerminated = false;
        try {
            this._printAnalysisRequest(pRequest);
            const aSetupID = Op3dContext.SETUPS_MANAGER.currSetupID;
            const aRetriveFromS3 = await this._getAnalysisFromS3(aSetupID, pParams);
            let aResult = await ServerContext.SERVER.runSimulation(pRequest, pParams.signal,
                (pProgress: string) => this._onProgressAnalysis(pParams.runningAnalysis, pProgress),
                aRetriveFromS3);

            if (AnalysisPortal.instance.isTerminated === true as any) {
                return;
            }

            if (false === aResult.success) {
                pParams.onFinish(false, null, MessagesHandler.ANALYSIS_FINISHED_ERROR);
                return;
            }

            let aContent = aResult.data.result;
            let aPayload = aContent.data;
            if ((undefined === aPayload) || (undefined === aPayload.analysis)) {
                this._onAnalysisError(aContent, pParams);
                return;
            }

            let aAnalysisID = pRequest.analyses_to_run[0];

            const aPrefix = this._getUserSetupPrefix(aSetupID);
            const aMapsURL = `${aPrefix}/analyses/${aAnalysisID}_maps.json?V=${Op3dUtils.idGenerator()}`;
            let aMaps = JSON.parse(await fetch(aMapsURL).then(m => m.text())) as iSimulationMaps;
            await this.getSimulationFiles(aPayload);
            this._downloadDebugData(aContent);

            for (let i = 0, l = aPayload.analysis.length; i < l; i++) {
                let aAnalysisPayload = aPayload.analysis[i];

                let aAnalysisResult = await this._getAnalysisResult(aAnalysisPayload, aAnalysisID,
                    aMaps);

                if ((null !== aAnalysisResult) && (undefined !== aAnalysisResult) &&
                    (0 === aAnalysisResult.length)) {

                    NotificationCenter.instance.pushNotification({
                        message: MessagesHandler.NO_RESULTS_ANALYSIS_FOUND,
                        params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
                    });

                    pParams.onFinish(false, null, MessagesHandler.ANALYSIS_FINISHED_ERROR);
                    return;
                }

                AnalysisCache.addAnalysisData(aAnalysisResult, pParams.runningAnalysis.id);

            }

            pParams.onFinish(true, null);

        } catch (e) {

            let aMessage = (e as any).message != null ? (e as any).message :
                MessagesHandler.ANALYSIS_FINISHED_ERROR;
            pParams.onFinish(false, e, aMessage);
        }
    }
    //______________________________________________________________________________________________
    private _onProgressAnalysis(pRunningAnalysis: { name: tAnalysisType, id: string },
        pProgress: string) {
        EventManager.dispatchEvent(EventsContext.ANALYSIS_PROGRESS,
            this, { analysis: pRunningAnalysis, progress: pProgress });
    }
    //______________________________________________________________________________________________
    private async _getAnalysisResult(pAnalysisResponse: iAnalysisServerResult, pAnalysisID: string,
        pMaps: iSimulationMaps) {

        const aContent = await unZip(pAnalysisResponse.content)
        const aAnalysisFace = Op3dContext.PARTS_MANAGER.getFaceAnalysisByAnalysisID(pAnalysisID);
        const aAnalysis = aAnalysisFace.analysis;
        let aFace = aAnalysisFace.face;

        const aData: iAnalysisResponseData = {
            analysis_name: aAnalysis.name,
            analysis_id: aAnalysis.id,
            faceSize: this._getSurfaceSize(aFace),
            content: aContent,
            faceId: aFace.internal_id,
            analysisType: aAnalysis.type
        };

        let aRes = AnalysisDataUtils.handleAnalysis(aData, pMaps.wavelengths);
        return aRes;
    }
    //______________________________________________________________________________________________
    private _getSurfaceSize(pFace: iFace) {
        let aFaceData = pFace.data;
        if ((undefined === aFaceData) || (undefined === aFaceData.shapeData)) {
            return undefined;
        }

        let aW: number;
        let aH: number;

        let aShapeData = aFaceData.shapeData;
        switch (aShapeData.kind) {
            case eSmBaseShapeKind.CIRCLE:
                aW = (2 * aFaceData.shapeData.radius);
                aH = (2 * aFaceData.shapeData.radius);
                break;
            case eSmBaseShapeKind.RECTANGLE:
                aW = aFaceData.shapeData.width;
                aH = aFaceData.shapeData.height;
                break;
            case eSmBaseShapeKind.POLYGON:
                let aX = aShapeData.points.filter((_item, idx) => idx % 2 != 0).sort();
                let aY = aShapeData.points.filter((_item, idx) => idx % 2 == 0).sort();
                aW = (aX[aX.length - 1] - aX[0]);
                aH = (aY[aY.length - 1] - aY[0]);
                break;
            default:
                throw new Error("Wrong shape");
        }

        let aSize: iPoint2D = {
            x: aW,
            y: aH
        };

        return aSize;
    }
    //______________________________________________________________________________________________
    private async _runGaussianBeamVisualization() {
        GaussianBeamTable.GAUSSIAN_DATA = {};
        await new GaussianRayTracer().runSimulation();
    }
    //______________________________________________________________________________________________
    private _runCheck() {
        let aGaussianKindS = 0;
        let aNonGaussianKindS = 0;

        let aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0, l = aParts.length; i < l; i++) {
            let aLaserBehavior = aParts[i].getBehavior('laserBehavior');
            if (undefined !== aLaserBehavior) {
                if (eSmRaysKind.GAUSSIAN_BEAM === aLaserBehavior.laserData.lightSource.kind) {
                    aGaussianKindS++;
                } else {
                    aNonGaussianKindS++;
                }
            }
        }

        let aMsg: string;

        if ((0 === aNonGaussianKindS) && (0 === aGaussianKindS)) {
            aMsg = MessagesHandler.NO_SOURCES_FOUND;
        } else if (aNonGaussianKindS > SimulationContext.SIMULATION_CONSTANTS.LIGHT_SOURCE_COUNT) {
            aMsg = MessagesHandler.TO_MANY_LIGHT_SOURCE_FOR_VISUALIZATION;
        }

        let aRunCheck: iRunCheck = {
            is_can_run_visualization: ((aNonGaussianKindS > 0) && (undefined === aMsg)),
            error_message: aMsg
        };

        return aRunCheck;
    }
    //______________________________________________________________________________________________
    private async _runRayTracingVisualization(pWithoutListener?: boolean,
        pProgressCallback?: Function) {
        this.mCurrentRayData = null;

        let aRunCheck = this._runCheck();
        if (false === aRunCheck.is_can_run_visualization) {
            if (undefined !== aRunCheck.error_message) {
                NotificationCenter.instance.pushNotification({
                    message: aRunCheck.error_message,
                    params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
                });
            }

            return;
        }

        await this._runSimulation({
            withoutListener: pWithoutListener,
            progressCallback: pProgressCallback
        });
    }
    //______________________________________________________________________________________________
    public async startVisualizationSimulation(pWithoutListener?: boolean,
        pProgressCallback?: Function) {
        this.mRaysDisplayer.clearScene();


        if (Op3dContext.PARTS_MANAGER.gaussianBeamExistsOnScene() === true) {
            await this._runGaussianBeamVisualization();
        }
        await this._runRayTracingVisualization(pWithoutListener, pProgressCallback);
    }
    //______________________________________________________________________________________________
    private async _downloadDebugData(pResult: iOneSimulationResultData) {
        if (false === SimulationRunner.DEBUG_SIMULATION) {
            return;
        }

        let aData = pResult.data;
        if (undefined !== (pResult as any).debug_files) {

            const zip = new JSZip();
            const folder = zip.folder("files");


            (pResult as any).debug_files.forEach((file) => {
                let aBlobPromise = fetch(file.url).then((r) => {
                    if (200 == r.status) {
                        return r.blob();
                    }

                    return Promise.reject(new Error(r.statusText));
                });

                folder.file(file.name, aBlobPromise);
            });

            zip.generateAsync({ type: "blob" }).then(function (blob) { saveAs(blob, 'simulation') });

            return;
        }

        if (undefined !== aData) {
            FileUtils.downloadFile({
                content: aData.ray_table,
                fullfileName: 'data_file.csv',
                mimeType: 'text/csv'
            });
        }

        let aDebug = aData.debug;

        if ((undefined === aDebug) || (undefined === aDebug.files) || (0 === aDebug.files.length)) {
            return;
        }

        const zip = new JSZip();
        const folder = zip.folder("files");


        aDebug.files.forEach((file) => {
            let aBlobPromise = fetch(file.url).then((r) => {
                if (200 == r.status) {
                    return r.blob();
                }

                return Promise.reject(new Error(r.statusText));
            });

            folder.file(file.name, aBlobPromise);
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) { saveAs(blob, 'simulation') });
    }
    //______________________________________________________________________________________________
    private _getTitlesMap(pTitles: Array<string>) {
        let aTitlesMap: iRayFileReader = {};
        for (let i = 0; i < pTitles.length; i++) {
            aTitlesMap[pTitles[i]] = i;
        }

        return aTitlesMap;
    }
    //______________________________________________________________________________________________
    private _parseRayData(pSimulationResultData: iVizualisationResultData) {
        const aRayData = pSimulationResultData.ray_table;
        if ((null == aRayData) || (aRayData.length < 2)) {
            return;
        }

        const aMaps = JSON.parse(pSimulationResultData.maps) as iSimulationMaps;

        const aRays = new Array<iRayDataFile>();
        const aRows = aRayData.split('\n');
        const aTitlesMap = this._getTitlesMap(aRows[0].split(','));
        let aMaxIntensity = Number.MIN_VALUE;


        for (let i = 1; i < (aRows.length - 1); i++) {
            const aRay = aRows[i].split(',');
            let aWavelengthIdx = parseInt(aRay[aTitlesMap.wavelength_idx]);
            let aWavelength = aMaps.wavelengths[aWavelengthIdx];
            if (undefined == aWavelength) {
                continue;
            }

            if (this.mWlToRGB[aWavelength] == null) {
                this.mWlToRGB[aWavelength] = ColorUtils.wavelengthToRGB(aWavelength);
            }

            let aAmplitude = parseFloat(aRay[aTitlesMap.Ap]);
            let aIntensity = Math.pow(aAmplitude, 2);

            let aExitPoint = new Vector3(parseFloat(aRay[aTitlesMap.Ox]),
                parseFloat(aRay[aTitlesMap.Oy]), parseFloat(aRay[aTitlesMap.Oz]));

            let aDirection = new Vector3(parseFloat(aRay[aTitlesMap.Dx]),
                parseFloat(aRay[aTitlesMap.Dy]), parseFloat(aRay[aTitlesMap.Dz]));

            let aEndPoint = new Vector3(parseFloat(aRay[aTitlesMap.Hx]),
                parseFloat(aRay[aTitlesMap.Hy]), parseFloat(aRay[aTitlesMap.Hz]));

            let aSurfaceIndex = parseInt(aRay[aTitlesMap.hit_surface_idx]);

            let aSurfaceMap = aMaps.surfaces;
            let aSurfaceId: string;
            if (aSurfaceIndex > -1) {
                aSurfaceId = ((undefined === aSurfaceMap[aSurfaceIndex]) ? Op3dUtils.idGenerator() :
                    aSurfaceMap[aSurfaceIndex]);
            }

            const aDistanceOfLaserRay = Op3dContext.SETUPS_MANAGER.settings.distanceOfLaserRay;
            aRay[aTitlesMap.hit_surface_idx] = aSurfaceId;
            const aFinalPoint = (undefined !== aSurfaceId) ? aEndPoint :
                aExitPoint.clone().add(aDirection.clone().multiplyScalar(aDistanceOfLaserRay));

            aRays.push({
                rgba: null,
                color: null,
                direction: aDirection,
                exit: aExitPoint,
                log_intensity: aIntensity,
                relative_intensity: aIntensity,
                general_intensity: aIntensity,
                endPoint: aEndPoint,
                surfaceID: aSurfaceId,
                wavelength: aWavelength,
                sourceID: aMaps.sources[aRay[aTitlesMap.source_idx]],
                Ap: Number(aRay[aTitlesMap.Ap]),
                finalPoint: aFinalPoint,
            });
            aMaxIntensity = Math.max(aMaxIntensity, aIntensity)
        }

        const aHash = this._getMaxHash(aRays);
        aRays.forEach(ray => {
            let aColor = this.mWlToRGB[ray.wavelength];
            ray.general_intensity = (ray.relative_intensity / aMaxIntensity);

            ray.relative_intensity = (ray.relative_intensity /
                aHash.max_intensity[ray.sourceID]);

            ray.log_intensity = Math.log10((ray.log_intensity /
                aHash.min_log[ray.sourceID]));
            ray.rgba = {
                r: aColor.r,
                g: aColor.g,
                b: aColor.b,
                a: ray.relative_intensity
            }
            ray.color = ColorUtils.RGBAtoColorNumber(ray.rgba);
        });

        const aMaxIntensitiesLog = this._getMaxHashLog(aRays);
        aRays.forEach(ray => {
            ray.log_intensity = (ray.log_intensity /
                aMaxIntensitiesLog[ray.sourceID]);
        });

        return aRays;
    }
    //______________________________________________________________________________________________
    private _getMaxHashLog(pRays: Array<iRayDataFile>) {
        let aMaxHash: iHash<number> = {};

        for (let i = 0; i < pRays.length; i++) {
            if (aMaxHash[pRays[i].sourceID] == null) {
                aMaxHash[pRays[i].sourceID] = pRays[i].log_intensity;
            } else {
                aMaxHash[pRays[i].sourceID] = Math.max(aMaxHash[pRays[i].sourceID], pRays[i].log_intensity);
            }
        }

        return aMaxHash;
    }
    //______________________________________________________________________________________________
    private _getMaxHash(pRays: Array<iRayDataFile>) {
        let aMaxHashIntensity: iHash<number> = {};
        let aMinHashLog: iHash<number> = {};

        for (let i = 0; i < pRays.length; i++) {
            if (aMaxHashIntensity[pRays[i].sourceID] == null) {
                aMaxHashIntensity[pRays[i].sourceID] = pRays[i].relative_intensity;
            } else {
                aMaxHashIntensity[pRays[i].sourceID] = Math.max(aMaxHashIntensity[pRays[i].sourceID], pRays[i].relative_intensity);
            }

            if (aMinHashLog[pRays[i].sourceID] == null) {
                aMinHashLog[pRays[i].sourceID] = pRays[i].log_intensity;
            } else {
                aMinHashLog[pRays[i].sourceID] = Math.min(aMinHashLog[pRays[i].sourceID], pRays[i].log_intensity);
            }
        }

        return { max_intensity: aMaxHashIntensity, min_log: aMinHashLog };
    }
    //______________________________________________________________________________________________
    public get raysDisplayer() {
        return this.mRaysDisplayer;
    }
    //______________________________________________________________________________________________
    public get mainLaserLinesContainer() {
        return this.mRaysDisplayer.mainLaserLinesContainer;
    }
    //______________________________________________________________________________________________
}


