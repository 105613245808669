
/**
 * @author Liliya Styagluk
 */

import { EventManager } from "../../oc/events/EventManager";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { Strings } from "../_context/Strings";
import { iOp3dComponentBaseData } from "../_context/_interfaces/Interfaces";
import { ViewUtils } from "./ViewUtils";
import { MinimizedNavbar } from "./home/MinimizeNavbar";
// import { Spinner } from "./home/Spinner";
import { ContainerLoader } from "./tools/ContainerLoader";

export abstract class Op3dComponentBase<T = any> {

    public static ID_ITERATOR: number = 1;
    /**
     * @description parent container
     */
    protected mContainer: HTMLElement;
    /**
     * this element can be changed when the container 
     * is not the modal that jquery opens
     */
    protected mModal: HTMLElement;
    protected mData: T | undefined;
    protected mParams: iOp3dComponentBaseData;
    protected mIsReady: boolean = false;
    protected mIsVisible: boolean = false;
    private mESCFunc = (e: KeyboardEvent) => this._onEcsape(e);


    constructor(pParams: iOp3dComponentBaseData) {
        this.mParams = pParams;
        this.mContainer = pParams.container;
        this.mModal = this.mContainer;

        new ContainerLoader(pParams.skinPath, pParams.container,
            () => this._creationComplete());
    }
    //__________________________________________________________________________________________
    private async _creationComplete() {
        this._initCloseBtn();

        $(this.mModal).on('hidden.bs.modal', () => {
            this.mIsVisible = false;
            this._onHidden()
        });
        $(this.mModal).on('shown.bs.modal', () => {
            this.mIsVisible = true;
            this._onShown();
        });

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this._onNew(), this);

        this._initDraggable();
        await this._initElements();
        this._addEventListeners();

        $(this.mContainer).find('.premium-feature').each((_index, element) => {
            if (document.body.classList.contains(Strings.BASIC_LICENSE)) {
                (element as HTMLElement).onclick = () => window.open('https://3doptix.com/pricing/', '_blank');
            }
        });

        this._onCreationComplete();
    }
    //__________________________________________________________________________________________
    private _onEcsape(e: KeyboardEvent) {
        if (e.code == 'Escape') {
            e.preventDefault();
            e.stopPropagation();

            this.close();
            window.removeEventListener('keyup', this.mESCFunc);
        }
    }
    //__________________________________________________________________________________________
    public get className(): string {
        return (this.constructor as any).name as any;
    }
    //__________________________________________________________________________________________
    protected _onNew() { }
    //__________________________________________________________________________________________
    private _initDraggable() {
        let aDraggableParams = this.mParams.draggableParams;
        if (null == aDraggableParams) {
            return;
        }

        if (null != aDraggableParams.class) {
            this.mContainer.classList.add(aDraggableParams.class);
        }

        $(this.mContainer).draggable({
            snap: aDraggableParams.snap,
            handle: aDraggableParams.handle,
            containment: aDraggableParams.containment,

            start: (null != aDraggableParams.onDragStart) ? aDraggableParams.onDragStart : null,
            drag: (null != aDraggableParams.onDragging) ? aDraggableParams.onDragging : null,
            stop: (null != aDraggableParams.onDragEnd) ? aDraggableParams.onDragEnd : null,
        });
    }
    //__________________________________________________________________________________________
    /**
     * @important each class that inherits from this class must set to TRUE the mIsReady parameter;
     */
    //__________________________________________________________________________________________
    protected abstract _onCreationComplete(): void;
    //__________________________________________________________________________________________
    protected _addEventListeners(): void { };
    //__________________________________________________________________________________________
    protected _onHidden(): void {

    }
    //__________________________________________________________________________________________
    protected _initElements(): void { };
    //__________________________________________________________________________________________
    protected _getPart<T = HTMLElement>(pId: string, pToChangeId: boolean = false): T {
        let aElement = $(this.mContainer).find('#' + pId).get(0);

        if (true == pToChangeId && aElement != null) {
            aElement.id += Op3dComponentBase.ID_ITERATOR;
            Op3dComponentBase.ID_ITERATOR++;
        }

        return aElement as T;
    }
    //__________________________________________________________________________________________
    private _initCloseBtn() {

        let aCloseBtn = this._getPart("close-btn", true);
        let aCancelBtn = this._getPart("cancel-btn", true);

        if (aCloseBtn != null) {
            aCloseBtn.addEventListener("click", () => this.close());
        }

        let aMinimizeBtn = this._getPart("minimize-btn");
        if (aMinimizeBtn && this.mParams.minimizeData) {
            aMinimizeBtn.addEventListener("click", () => this._onMinimizeForm());
        }

        if (aCancelBtn != null) {
            aCancelBtn.addEventListener("click", () => this.close());
        }
    }
    //__________________________________________________________________________________________
    protected _onMinimizeForm() {
        if (this.mParams.minimizeData != undefined) {
            MinimizedNavbar.instance.addItem(this.mParams.minimizeData);
            this.hide();
        }
    }
    //__________________________________________________________________________________________
    /**
     * @description This function will be override only when we want a 
     * different behavior for opening the element
     */
    //__________________________________________________________________________________________
    protected _openFunc() {
        this.show();
    }
    //__________________________________________________________________________________________
    protected _checkData(_pData?: T) {
        return null;
    }
    //__________________________________________________________________________________________
    protected onFocusOpen() {
        let aFocused = document.body.getElementsByClassName("focused")[0];
        ViewUtils.setClassForElement(aFocused as HTMLElement, "focused", false);

        ViewUtils.setClassForElement(this.mContainer, "focused", true);
    }
    //__________________________________________________________________________________________
    public async open(pData?: T) { // try not to override this func only if it is realy important
        // Spinner.instance.show();
        await this._prepareForm();
        // Spinner.instance.hide();



        let aDataCheckMsg = this._checkData(pData);
        if (null != aDataCheckMsg) {
            //TODO add message
            // data.context.OpContext.divManager.pushNotification({
            //     message: aDataCheckMsg,
            //     params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            // });
        }

        if (true == this.mParams.closeOnESC) {
            window.addEventListener('keyup', this.mESCFunc);
        }

        await this._onOpen(pData);
        this._openFunc();
    }
    //__________________________________________________________________________________________
    public async update(pData?: T) { // try not to override this func only if it is realy important
        await this._prepareForm();
        this._onUpdate(pData);
    }
    //__________________________________________________________________________________________
    public show() {
        $(this.mModal).modal('show');
    }
    //__________________________________________________________________________________________
    public hide() {
        $(this.mModal).modal('hide');
    }
    //__________________________________________________________________________________________
    protected onFocusClose() {
        ViewUtils.setClassForElement(this.mContainer, "focused", false);
    }
    //__________________________________________________________________________________________
    public async close(pData?: any) {
        await this._prepareForm();
        this.hide();


        /**
         * @TODO - Need to remove this condition. Currently works for editor only.
         */
        if (null != Op3dContext.DIV_CONTROLLER) {
            Op3dContext.DIV_CONTROLLER.removeFromOpenFormsArr(this);
        }


        this.mIsVisible = false;

        this._onClose(pData);
        this.mData = null;

    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any) { }
    //__________________________________________________________________________________________
    public get container(): HTMLElement {
        return this.mContainer;
    }
    //__________________________________________________________________________________________
    public get isReady(): boolean {
        return this.mIsReady;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm() {
        while (!this.mIsReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    public get visible(): boolean {
        return this.mIsVisible;
    }
    //__________________________________________________________________________________________
    protected resetForm() { }
    //__________________________________________________________________________________________
    protected _onShown(): void {
        // impl on need
    }
    //__________________________________________________________________________________________
    protected _onOpen(_pData?: T) { }
    //__________________________________________________________________________________________
    protected _onUpdate(_pData?: T) {
        // impl on need
    }
    //__________________________________________________________________________________________
}