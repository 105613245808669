import { iUploadSetupData } from "../_context/_interfaces/Interfaces";

export abstract class absSceneHistory {
    constructor() { }
    //__________________________________________________________________________________________
    public clearHistory() { }
    //__________________________________________________________________________________________
    public addToHistory(_pPrevState?: iUploadSetupData) { }
    //__________________________________________________________________________________________
    public async saveScene(_pForceSave?: boolean, _pNoChanges?: boolean) { }
    //__________________________________________________________________________________________
    public autoSave() { }
    //__________________________________________________________________________________________
    public async undoHistory() { }
    //__________________________________________________________________________________________
    public setLastContent(_pLastContent: string) { }
    //__________________________________________________________________________________________
    public set ignoreSaving(_pIgnore: boolean) { }
    //__________________________________________________________________________________________
    public async setIsLoading(_pState: boolean) { }
    //__________________________________________________________________________________________
    public deleteLastHistory() { }
    //__________________________________________________________________________________________
    public async redoHistory() { }
    //__________________________________________________________________________________________
}
