import { eSurfaceDeformation, iSurfaceDeformation } from "../../../data/VO/OpticsVOInterfaces";
import { eSmBaseShapeKind } from "../../../simulation/SimulationContext";
import { ViewUtils } from "../../ViewUtils";
import { esSurfaceShape } from "./esSurfaceShape";

export interface iSurfaceShapeLightSource {
    normalization_radius: number,
    deformations: iSurfaceDeformation[],
    shape: eSmBaseShapeKind,
    width: number;
    height: number
};

export class esSurfaceShapeLightSource extends esSurfaceShape<iSurfaceShapeLightSource> {

    declare protected mSurfaceShape: iSurfaceShapeLightSource;
    private mOnChange: (pData: iSurfaceDeformation[]) => void;

    constructor(pContainer: HTMLElement, pOnChange: (pData: iSurfaceDeformation[]) => void) {
        super(pContainer);
        this.mOnChange = pOnChange;
    }
    //__________________________________________________________________________________________
    protected _onUpdateSurfaceShape() {
        let aData = this.getData()
        this.mOnChange(aData);
        // do nothing for now
    }
    //__________________________________________________________________________________________
    protected _getNormalizationRadius() {
        return this.mSurfaceShape.normalization_radius;
    }
    //__________________________________________________________________________________________
    public async setData(pData: iSurfaceShapeLightSource): Promise<void> {
        await this._setData(pData);
    }
    //__________________________________________________________________________________________
    protected _getDeformations() {
        if (this.mSurfaceShape.deformations != null && this.mSurfaceShape.deformations.length > 0) {
            return this.mSurfaceShape.deformations;
        }
        return null;
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete(): Promise<void> {
        await super._onCreationComplete();

        let aIdx = ViewUtils.findIndexByValue(this.mDeformationTypeSelect, eSurfaceDeformation.JONES_MATRIX);
        this.mDeformationTypeSelect.options.remove(aIdx);
        let aCollapseTarget = this.collapseTarget;
        ViewUtils.removeElementChildren(this.mContainer);
        this.mContainer.appendChild(aCollapseTarget.children[0]);
    }
    //__________________________________________________________________________________________
}
