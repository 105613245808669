import { EventManager } from "../../oc/events/EventManager";
import { eStateToAnalysis } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { iUploadSetupData } from "../_context/_interfaces/Interfaces";
import { DataUtils } from "../_utils/DataUtils";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { ePartType } from "../parts/PartInterfaces";
import { iOptixPartJSON } from "../parts/_parts_assets/ExportToJSONInterfaces";
import { PartsImporter } from "../setups/opt/PartsImporter";
import { ViewUtils } from "../ui/ViewUtils";
import { eClickMode } from "../ui/_globals/PartsEventsHandler";
import { AnalysisPortal } from "../ui/analysis/AnalysisPortal";
import { Measurement } from "../ui/forms/Measurement";
import { Op3dScene } from "./Op3dScene";
import { SceneContext } from "./SceneContext";
import { absSceneHistory } from "./absSceneHistory";


export class SceneHistory extends absSceneHistory {

    private static TIMER_TO_AUTO_SAVE: number = 120000; // 2 minutes
    public static TO_AUTO_SAVE: boolean = true;
    private mTimeout: any;
    private mAutoSaveCallback = () => this.autoSave();

    private mUndo: HTMLElement;
    private mRedo: HTMLElement;
    private mSavingActive: HTMLElement;
    private mSavingUnactive: HTMLElement;
    private mSceneHistory: Array<iUploadSetupData>;
    private mSceneFuture: Array<iUploadSetupData>;
    private mIgnoreSaving: boolean = false;
    private mLastContent: string;
    private mInitSave: boolean = false;
    private mIsReady: boolean;

    constructor() {
        super();

        this.mTimeout = setTimeout(this.mAutoSaveCallback, SceneHistory.TIMER_TO_AUTO_SAVE);
        this._onInit()
        this.mSceneHistory = []
        this.mSceneFuture = []
    }
    //__________________________________________________________________________________________
    public async clearHistory() {
        this.mInitSave = false;
        await this._prepare();

        this.mSceneHistory = [];
        this.mSceneFuture = [];
        ViewUtils.setElementDisabled(this.mUndo, true);
        ViewUtils.setElementDisabled(this.mRedo, true);
    }
    //__________________________________________________________________________________________
    public async setIsLoading(pState: boolean) {
        await this._prepare();

        if (pState) {
            this.mSavingActive?.classList.remove('d-none')
            this.mSavingUnactive?.classList.add('d-none')
        } else {
            this.mSavingActive.classList.add('d-none')
            this.mSavingUnactive.classList.remove('d-none')
        }
    }
    //__________________________________________________________________________________________
    public setLastContent(pLastContent: string) {
        this.mLastContent = pLastContent;
    }
    //__________________________________________________________________________________________
    private async _prepare() {
        if (true != this.mIsReady) {
            await Op3dContext.wait(() => {
                this.mUndo = document.getElementById('undo-btn');
                this.mRedo = document.getElementById('redo-btn');
                this.mSavingActive = document.getElementById('loading-active');
                this.mSavingUnactive = document.getElementById('loading-unactive');

                this.mIsReady = ((null != this.mUndo) && (null != this.mRedo) &&
                    (null != this.mSavingActive) && (null != this.mSavingUnactive));

                return this.mIsReady;
            });
        }
    }
    //__________________________________________________________________________________________
    private async _onInit() {
        await this._prepare();

        ViewUtils.setElementDisabled(this.mUndo, true);
        ViewUtils.setElementDisabled(this.mRedo, true);

        this.mUndo.addEventListener('click', () => this.undoHistory())
        this.mRedo.addEventListener('click', () => this.redoHistory())
    }
    //__________________________________________________________________________________________
    public deleteLastHistory() {
        this.mSceneHistory.pop();

        if (0 === this.mSceneHistory.length) {
            ViewUtils.setElementDisabled(this.mUndo, true);
        }
    }
    //__________________________________________________________________________________________
    public addToHistory(pPrevState?: iUploadSetupData) {
        if (true == this.mIgnoreSaving) {
            return;
        };

        let aState = (null == pPrevState) ? Op3dContext.SETUPS_MANAGER.getSetupData() :
            pPrevState;
        if (JSON.stringify((this.mSceneHistory as any).at(-1)) === JSON.stringify(aState) && SceneContext.CHOOSE_MODE.mode !== eClickMode.MEASUREMENT) {
            return;
        }

        this.mSceneHistory.push(aState);
        ViewUtils.setElementDisabled(this.mUndo, false);
        this.mSceneFuture.splice(0);
        ViewUtils.setElementDisabled(this.mRedo, true);
    }
    //__________________________________________________________________________________________
    public async saveScene(pForceSave?: boolean, pNoChanges?: boolean) {
        if (true == this.mIgnoreSaving) {
            return;
        }

        const aSetupData = JSON.stringify(Op3dContext.SETUPS_MANAGER.getSetupData())
        if (aSetupData === this.mLastContent && !pForceSave) {
            return;
        }
        if (this.mInitSave && !pNoChanges) {
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = true;
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis = true;
            Op3dContext.SETUPS_MANAGER.setupParameters.setup_version_to_compare = Op3dUtils.idGenerator();
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
                eStateToAnalysis.FROM_SCENE);
        }

        this.mInitSave = true;
        this.mLastContent = aSetupData;
        await Op3dContext.SETUPS_MANAGER.saveOpt({ showMessage: false });

        if (this.mTimeout) {
            clearTimeout(this.mTimeout);
            this.mTimeout = setTimeout(this.mAutoSaveCallback, SceneHistory.TIMER_TO_AUTO_SAVE);
        }
        await Op3dContext.sleep(10);
    }
    //__________________________________________________________________________________________
    public autoSave() {
        if (this.mTimeout) {
            clearTimeout(this.mTimeout);
        }

        this.mTimeout = setTimeout(this.mAutoSaveCallback, SceneHistory.TIMER_TO_AUTO_SAVE);
        if (!SceneHistory.TO_AUTO_SAVE) {
            return;
        }

        if (true == this.mIgnoreSaving) {
            return;
        }

        EventManager.dispatchEvent(EventsContext.AUTO_SAVE, this);
    }
    //__________________________________________________________________________________________
    public set ignoreSaving(pIgnore: boolean) {
        this.mIgnoreSaving = pIgnore;
    }
    //__________________________________________________________________________________________
    private async _changeSystemState(pNewState: iUploadSetupData,
        pCurrState: iUploadSetupData) {

        this.mIgnoreSaving = true;

        await SceneContext.GRID_MANAGER.setSceneOptions(pNewState.parameters.sceneOptions);
        let aCurrentStateParts = JSON.parse(pCurrState.content) as iOptixPartJSON[];
        let aNewStateParts = JSON.parse(pNewState.content) as iOptixPartJSON[];

        let aLastChangeIDs = new Set(aNewStateParts.map(el => el.internalID));
        let aCurrentStateIDs = new Set(aCurrentStateParts.map(el => el.internalID));

        let aUniqueIDToDelete = aCurrentStateParts.filter(
            e => !aLastChangeIDs.has(e.internalID));
        let aUniqueIDToCreate = aNewStateParts.filter(
            e => !aCurrentStateIDs.has(e.internalID));

        //here to create item


        /** 
         * this condition was removed because for array of elements the undo-redo wasnt working
        */
        //if (aNewStateParts.length > aCurrentStateParts.length) {
        for (let i = 0; i < aUniqueIDToCreate.length; i++) {
            await new PartsImporter().initOnePart(aUniqueIDToCreate[i],
                true);
        }
        //} else if (aNewStateParts.length < aCurrentStateParts.length) {
        aUniqueIDToDelete.forEach(part => {
            let aInternalID = part.internalID;
            let aPartToDelete = Op3dContext.PARTS_MANAGER.getPartByInternalId(aInternalID);
            Op3dContext.PARTS_MANAGER.deletePart(aPartToDelete);
        })
        // }

        for (let part of aNewStateParts) {
            let aPrevPart = aCurrentStateParts.find((pPart) =>
                (pPart.internalID == part.internalID));

            if (false == DataUtils.isEqual(aPrevPart, part)) {
                let aPart = Op3dContext.PARTS_MANAGER.getPartByInternalId(part.internalID);
                if (null != aPart) {
                    await aPart.initFromJSON(part, true);


                    if (part.partOptions.visible == false) {
                        aPart.visibleObj.visible = part.partOptions.visible;
                        aPart.clickEvents(false)

                        if (ePartType.GROUP !== aPart.partOptions.type) {
                            aPart.labelVisiblity = false;
                        }
                    } else {
                        aPart.visibleObj.visible = part.partOptions.visible;
                        aPart.clickEvents(true)

                        if (ePartType.GROUP !== aPart.partOptions.type) {
                            // is part is not group we would like to return him his previous label state

                            aPart.labelVisiblity = (Op3dScene.SHOW_ALL_LABELS === true);
                        }
                    }

                    // } else {
                    //  await new PartsImporter().initOnePart(part,
                    //    true);
                }
            }
        }


        Op3dContext.SETUPS_MANAGER.updateSetupDetails(pNewState.parameters.details,
            pNewState.permission)
        SceneContext.OP3D_SCENE.activateRenderer();
        Op3dContext.PARTS_MANAGER.updatePartsList(false);
        this.mIgnoreSaving = false;
        this.saveScene();
    }
    //__________________________________________________________________________________________
    public async undoHistory() {
        if (SceneContext.CHOOSE_MODE.mode === eClickMode.MEASUREMENT) {
            Measurement.instance.undo()
        }
        if (0 === this.mSceneHistory.length) {
            return;
        } else if (1 === this.mSceneHistory.length) {
            ViewUtils.setElementDisabled(this.mUndo, true);
        }

        let aCurrSystemState = Op3dContext.SETUPS_MANAGER.getSetupData();

        this.mSceneFuture.push(aCurrSystemState);
        ViewUtils.setElementDisabled(this.mRedo, false);
        this._changeSystemState(this.mSceneHistory.pop(), aCurrSystemState);
        EventManager.dispatchEvent(EventsContext.UNDO, this);
    }
    //__________________________________________________________________________________________
    public async redoHistory() {
        if (SceneContext.CHOOSE_MODE.mode === eClickMode.MEASUREMENT) {
            Measurement.instance.redo()
        }
        if (0 === this.mSceneFuture.length) {
            return;
        } else if (1 === this.mSceneFuture.length) {
            ViewUtils.setElementDisabled(this.mRedo, true);
        }
        ViewUtils.setElementDisabled(this.mUndo, false);

        let aCurrSystemState = Op3dContext.SETUPS_MANAGER.getSetupData();
        this.mSceneHistory.push(aCurrSystemState);
        this._changeSystemState(this.mSceneFuture.pop(), aCurrSystemState);
        EventManager.dispatchEvent(EventsContext.REDO, this);
    }
    //__________________________________________________________________________________________
}