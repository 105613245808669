import { EventBase } from "../../../../../oc/events/EventBase";
import { EventManager } from "../../../../../oc/events/EventManager";
import { EventsContext } from "../../../../_context/EventsContext";
import { MessagesHandler } from "../../../../_context/MessagesHandler";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { OpticsContext } from "../../../../_context/OpticsContext";
import { Strings } from "../../../../_context/Strings";
import { DataUtils } from "../../../../_utils/DataUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../../../data/data_loader/OpticsDataLoader";
import { Part } from "../../../../parts/Part";
import { iFace } from "../../../../parts/PartInterfaces";
import { OpticsShapeUtils } from "../../../../parts/optics/OpticShapeUtils";
import { ServerContext, iOpticAttachments } from "../../../../server/ServerContext";
import { Op3dComponentBase } from "../../../Op3dComponentBase";
import { ViewUtils } from "../../../ViewUtils";
import { Spinner } from "../../../home/Spinner";
import { NotificationCenter } from "../../../home/_notifications/NotificationCenter";
import { Popup } from "../../Popup";
import { Optics3DPresenter, eDisplayedItem } from "../../tools/Optics3DPresenter";
import { eFormType, iUploadOpticsParamsNew } from "./UploadOpticsContext";
import { uoBasicInfoNew } from "./uoBasicInfoNew";
import { uoCoatingInfoNew } from "./uoCoatingInfoNew";
import { uoDeformationSettings } from "./uoDeformationSettings";
import { uoFaceBasicInfoNew as uoFaceBasicInfoNew } from "./uoFaceBasicInfoNew";
import { uoFaceSimulationSettings } from "./uoFaceSimulationSettings";
import { uoGeometricalInfoNew } from "./uoGeometrialInfoNew";
import { uoMaterialNew } from "./uoMaterialNew";
import { uoPhysicalInfoNew } from "./uoPhysicalInfoNew";
import { uoPolarizingElementInfo } from "./uoPolarizingElementInfo";
import { uoScatteringInfoNew } from "./uoScatteringInfoNew";
import { uoSurfacesNew } from "./uoSurfacesNew";
import { uoUserApertureInfoNew } from "./uoUserApertureInfoNew";

export class UploadOpticsFormNew extends Op3dComponentBase<iUploadOpticsParamsNew> {

    protected static INSTANCE: UploadOpticsFormNew;

    protected mSections: {
        material: Array<uoMaterialNew>;
        basicInfo: uoBasicInfoNew;
        polarizerInfo: uoPolarizingElementInfo;
        geometricalInfo: uoGeometricalInfoNew;
        physicalInfo: uoPhysicalInfoNew;
        coatingInfo: uoCoatingInfoNew;
        surfacesInfo: uoSurfacesNew;
        userApertureInfo: uoUserApertureInfoNew;
        scatteringInfo?: uoScatteringInfoNew;
        faceBasicInfo?: uoFaceBasicInfoNew;
        simulationSettings?: uoFaceSimulationSettings;
        deformationSettings: uoDeformationSettings;
    };

    private mMaterialsContainer!: HTMLElement;
    private mOptics3DPresenter!: Optics3DPresenter;
    private mSaveBtn!: HTMLElement;
    protected mSaveAsBtn!: HTMLElement;
    private mClickedFace!: iFace;
    protected mSaveButtonsContainer!: HTMLElement;

    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/optics/upload_optics_form_new.html'
        });
    }
    //____________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aFormsContainer = document.getElementById('forms');
            if (aFormsContainer == null) {
                throw new Error("forma container not found");
            }

            let aContainer = document.createElement('div');
            aContainer.setAttribute("data-backdrop", "false")
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.setAttribute("qa_id", "qa_create_optics_form")
            aFormsContainer.appendChild(aContainer);
            UploadOpticsFormNew.INSTANCE = new UploadOpticsFormNew(aContainer);
        }

        return this.INSTANCE;
    }
    //____________________________________________________________________________
    protected async _prepareForm(): Promise<void> {

        while (!this.mIsReady || !this.mOptics3DPresenter.isReady) {
            await Op3dContext.sleep(50);
        }

        await this._waitForSections();
    }
    //____________________________________________________________________________
    private _onPhysicalDataChanged(pSender: uoPhysicalInfoNew) {
        if (this.mSections.physicalInfo !== pSender) {
            return;
        }

        this._updateSaveBtn(false, false);
    }
    //____________________________________________________________________________
    protected _onCreationComplete() {
        this._initSections();
        this._init3DPresenter();

        this.mSaveButtonsContainer = this._getPart("save-buttons", true);
        this.mSaveBtn = this._getPart("save_btn");
        this.mSaveBtn.addEventListener("click", () => this._onSave());

        this.mSaveAsBtn = this._getPart("save_as_btn");
        this.mSaveAsBtn.addEventListener("click", () => this._onSaveAs());

        EventManager.addEventListener(EventsContext.OPTICS_VO_BASIC_DATA_CHANGED,
            (pData: EventBase<iOpticsVO>) => this._onBasicDataChanged(pData.sender, pData.data), this);

        EventManager.addEventListener(EventsContext.OPTICS_PHYSICAL_DATA_SECTION_CHANGED,
            (pData: EventBase<iOpticsVO>) => this._onPhysicalDataChanged(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_VO_GEO_DATA_CHANGED,
            (pData: EventBase) => this._updateOpticAfterGeoChange(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_VO_COATING_CHANGED,
            (pData: EventBase) => this._updateOpticAfterCoatingChange(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_APERTURE_CHANGED,
            (pData: EventBase) => this._apertureDataChanged(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_DEFORMATION_SECTION_CHANGED,
            (pData: EventBase) => this._deformationChange(pData.sender), this);

        EventManager.addEventListener(EventsContext.SCATTERING_SECTION_DATA_CHANGE,
            (pData: EventBase) => this._onScatteringDataChanged(pData.sender), this);

        EventManager.addEventListener(EventsContext.SIMULATION_SECTION_DATA_CHANGE,
            (pData: EventBase) => this._onSimualtionSettigsChanged(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_MATERIAL_SECTION_CHANGED,
            (pData: EventBase) => this._onMaterialChanged(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_POLARIZER_DATA_SECTION_CHANGED,
            (pData: EventBase) => this._onPolarizerSettingChanged(pData.sender), this);

        EventManager.addEventListener(EventsContext.MATERIAL_WL_CHANGED_NEW,
            (pData: EventBase) => this._onMaterialWlChanged(pData.sender), this);


        EventManager.addEventListener(EventsContext.BASIC_FACE_SECTION_CHANGED,
            (pData: EventBase) => this._onFaceBasicInfoChanged(pData.sender), this);

        $('[data-toggle="tooltip"]').tooltip().on('click', () => {
            $(this).blur()
        });

        this.mIsReady = true;
    }
    //____________________________________________________________________________
    private _onMaterialWlChanged(pSender: uoMaterialNew) {
        if (this.mSections.material.indexOf(pSender) == -1) {
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        this.mSections.geometricalInfo.setData(aOpticsVO);
        // this._updateSaveBtn(true, false);
    }
    //____________________________________________________________________________
    private _onMaterialChanged(pSender: uoMaterialNew) {
        if (this.mSections.material.indexOf(pSender) == -1) {
            return;
        }
        this.mSections.polarizerInfo.onChangeMaterialWL();
        this._updateSaveBtn(true, false);
    } 
    //____________________________________________________________________________
    private _onPolarizerSettingChanged(pSender: uoPolarizingElementInfo) {
        if (this.mSections.polarizerInfo  == null || this.mSections.polarizerInfo != pSender) {
            return;
        }

        this._updateSaveBtn(true, false);
    }
    //____________________________________________________________________________
    private _onFaceBasicInfoChanged(pSender: uoFaceBasicInfoNew) {
        if (this.mSections.faceBasicInfo == null || this.mSections.faceBasicInfo != pSender) {
            return;
        }

        this._updateSaveBtn(true, false);
    }
    //____________________________________________________________________________
    private _onSimualtionSettigsChanged(pSender: uoFaceSimulationSettings) {
        if (this.mSections.simulationSettings == null || this.mSections.simulationSettings != pSender) {
            return;
        }

        this._updateSaveBtn(true, false);
    }
    //____________________________________________________________________________
    private _onScatteringDataChanged(pSender: uoScatteringInfoNew) {
        if (this.mSections.scatteringInfo == null || this.mSections.scatteringInfo != pSender) {
            return;
        }

        this._updateSaveBtn(true, false);
    }
    //____________________________________________________________________________
    private _deformationChange(pSender: uoDeformationSettings): void {
        if (this.mSections.deformationSettings == null || pSender != this.mSections.deformationSettings) {
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        this.mOptics3DPresenter.onRefreshOptic(aOpticsVO);
        if (this.mClickedFace.originalName !== undefined) {
            this.mOptics3DPresenter.highlightMesh(this.mClickedFace.originalName);
        }
        this._updateSaveBtn(false, false);
    }
    //____________________________________________________________________________
    private _apertureDataChanged(pSender: uoUserApertureInfoNew) {
        if (this.mSections.userApertureInfo == null || pSender != this.mSections.userApertureInfo) {
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        this.mOptics3DPresenter.onRefreshOptic(aOpticsVO);
        this._updateSaveBtn(false, false);
    }
    //____________________________________________________________________________
    protected _isSaveAllowed(pOpticsVO: iOpticsVO, pAttachments: iOpticAttachments, _pIsNewInstance: boolean): string | null {

        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._User_Aperture:
                let aHasMaskAttachment = pAttachments.masks.phase !== undefined || pAttachments.masks.transmittance !== undefined;
                let aHasFileUrl = (pOpticsVO.parameters.physical_data?.transmittance_mask !== undefined &&
                    pOpticsVO.parameters.physical_data.transmittance_mask.url !== "") ||
                    (pOpticsVO.parameters.physical_data?.phase_mask !== undefined &&
                        pOpticsVO.parameters.physical_data.phase_mask.url !== "")

                // if (aHasMaskAttachment === false && pIsNewInstance === true) {
                if (aHasMaskAttachment === false && aHasFileUrl === false) {
                    return "Missing aperture images";
                }
        }

        return null;
    }
    //____________________________________________________________________________
    private _getParsedOpticsVOupload(pOpticsVO: iOpticsVO) {
        if (pOpticsVO.parameters.subType === OpticsContext._Multiplets) {
            let aMat = pOpticsVO.parameters.materialID;
            let aMats = aMat.split(";");
            pOpticsVO.parameters.materialID = aMats as any;
        }
    }
    //____________________________________________________________________________
    protected async _save(pOpticsVO: iOpticsVO, pIsNewInstance: boolean): Promise<Part | undefined> {
        Spinner.instance.show();

        let aAttachments = await this._getAttachments(pOpticsVO, pIsNewInstance);
        let aSaveError = this._isSaveAllowed(pOpticsVO, aAttachments, pIsNewInstance);
        if (aSaveError !== null) {
            Spinner.instance.hide();
            Popup.instance.open({
                text: aSaveError
            });
            return;
        }

        this._getParsedOpticsVOupload(pOpticsVO);
        let aRes = await ServerContext.SERVER.uploadOpticalElement({
            data: pOpticsVO,
            attachments: aAttachments
        });

        if (aRes.success) {
            const aOpticsVONew = await OpticsDataLoader.instance.getSingleFullData({
                number_id: pOpticsVO.number_id
            });

            let aNewOptics = Op3dContext.PARTS_MANAGER.chooseOptics({
                openMenuPoint: this.mData?.openMenuPoint,
                part: this.mData?.part,
                opticsVO: aOpticsVONew,
                copy_data: (undefined !== this.mData?.part)
            });

            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.SUCCESS_UPLOAD_OPTICS,
                params: NotificationCenter.NOTIFICATIONS_TYPES.SUCCESS_MESSAGE
            });

            Op3dContext.DIV_CONTROLLER.updatePartInfo();
            this.close();
            Spinner.instance.hide();
            return aNewOptics;

        } else {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.FAILED_UPLOAD_OPTICS,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
        }
        Spinner.instance.hide();
    }
    //____________________________________________________________________________
    protected async _onSaveAs(): Promise<Part | undefined> {
        if (Op3dContext.USER_VO.isBasicLicense) {
            return;
        }

        Spinner.instance.show();

        try {

            let aOpticsVO = this._getOpticsVO();

            let aOriginalName = this.mData?.opticsVO.name;
            let aCurrentName = aOpticsVO.name;

            if (aOriginalName == aCurrentName) {
                Popup.instance.open({
                    text: MessagesHandler.SAVE_AS_OPTICAL_ELEMENT_SAME_NAME
                });
                Spinner.instance.hide();
                return;
            }

            aOpticsVO.number_id = Op3dUtils.idGenerator(true);// change the id to a different one in order to save a new instnce of this element 
            Spinner.instance.hide();
            return await this._save(aOpticsVO, true);


        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
            Spinner.instance.hide();
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.OPTICAL_ELEMENT_CREATION_ERROR,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
        }
    }

    //____________________________________________________________________________
    protected async _onSave(): Promise<Part | undefined> {
        if (Op3dContext.USER_VO.isBasicLicense) {
            return;
        }

        try {

            Spinner.instance.hide();
            let aOpticsVO = this._getOpticsVO();
            let aOwnerChanged = this.mData?.opticsVO.owner !== undefined && this.mData.opticsVO.owner !== Op3dContext.USER_VO.id;
            let aIsuploadOptics = this.mData?.formType === eFormType.UPLOAD;
            let aIsNewInstance = aOwnerChanged || aIsuploadOptics;

            if (aOwnerChanged === true) {
                aOpticsVO.number_id = Op3dUtils.idGenerator(true);
            }
            let aNewOptics = await this._save(aOpticsVO, aIsNewInstance); // false isNewInstance before

            return aNewOptics;

        } catch (e) {
            Spinner.instance.hide();

            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.OPTICAL_ELEMENT_CREATION_ERROR,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
        }
    }
    //____________________________________________________________________________
    protected async _getAttachments(pOpticsVO: iOpticsVO, pIsNewInstance: boolean): Promise<iOpticAttachments> {
        let aAttachments: iOpticAttachments = {
            coating: {},
            masks: {
                phase: undefined,
                transmittance: undefined
            }
        }

        await this.mSections.userApertureInfo.fillAttachments(pOpticsVO, aAttachments.masks, pIsNewInstance);
        this.mSections.coatingInfo.fillAttachments(pOpticsVO, aAttachments.coating, pIsNewInstance);
        return aAttachments;
    }
    //____________________________________________________________________________
    protected _getOpticsVO() {
        let aOpticsVO = DataUtils.getObjectCopy(this.mData?.opticsVO);
        if (aOpticsVO === null || aOpticsVO === undefined) {
            throw new Error("failed to copy opticsVO");
        }

        this.mSections.basicInfo.fillObject(aOpticsVO);
        this.mSections.geometricalInfo.fillObject(aOpticsVO);
        this.mSections.physicalInfo.fillObject(aOpticsVO);
        this.mSections.material.forEach((material, idx) => material.fillObject(aOpticsVO, idx));
        this.mSections.coatingInfo.fillObject(aOpticsVO);
        this.mSections.deformationSettings.fillObject(aOpticsVO);
        this.mSections.userApertureInfo.fillObject(aOpticsVO);
        this.mSections.polarizerInfo?.fillObject(aOpticsVO);
        return aOpticsVO;
    }
    //____________________________________________________________________________
    protected _updateSaveBtn(_faceEvent: boolean, _pMajorChange: boolean) {
        ViewUtils.setElementDisabled(this.mSaveButtonsContainer, false);
    }
    //____________________________________________________________________________
    private _updateOpticAfterCoatingChange(pSender: uoCoatingInfoNew) {
        if (this.mSections.coatingInfo == null || pSender != this.mSections.coatingInfo) {
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        this.mOptics3DPresenter.onRefreshOptic(aOpticsVO, { fitToZoom: false });
        this._updateSaveBtn(false, false);
    }
    //____________________________________________________________________________
    private async _updateOpticAfterGeoChange(pSender: uoGeometricalInfoNew) {
        if (this.mSections.geometricalInfo == null || pSender != this.mSections.geometricalInfo) {
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        const aNewSubtype = OpticsShapeUtils.getSubtype(aOpticsVO);
        if (aOpticsVO.parameters.subType != aNewSubtype) {
            // subtype was changed
            aOpticsVO.parameters.subType = aNewSubtype;
            await this.mSections.basicInfo.setData({ opticsVO: aOpticsVO, formType: this.mData?.formType });
        }

        this.mSections.polarizerInfo?.recalculateDataWithChangedThickness();

        this.mOptics3DPresenter.onRefreshOptic(aOpticsVO, { fitToZoom: false });
        this.mSections.geometricalInfo.setData(aOpticsVO);
        this._updateSaveBtn(false, false);
    }
    //____________________________________________________________________________
    private async _onBasicDataChanged(pSender: uoBasicInfoNew, pOpticsVO: iOpticsVO | undefined) {
        if (this.mSections.basicInfo === null || pSender !== this.mSections.basicInfo) {
            return;
        }

        if (pOpticsVO !== undefined) {
            let aOldNumberID = this.mData.opticsVO.number_id;
            this.mData.opticsVO = pOpticsVO;
            this.mData.opticsVO.number_id = aOldNumberID;
            this.mData.surfacesPart = undefined;
            await this._fillForm();
        }

        this._updateSaveBtn(false, true);
    }
    //____________________________________________________________________________
    private _init3DPresenter() {
        this.mOptics3DPresenter = new Optics3DPresenter(
            this._getPart('optic-preview-element', true), {
            isFixedDiv: true,
            alwaysShowImage: false
        });
    }
    //____________________________________________________________________________
    private async _waitForSections() {
        await Op3dContext.wait(() => {
            for (let section in this.mSections) {
                if (this.mSections[section] instanceof Array) {
                    for (let i = 0; i < this.mSections[section].length; i++) {
                        if (true != this.mSections[section][i].isReady) {
                            return false;
                        }
                    }
                }
                else if (this.mSections[section] != null && true != this.mSections[section].isReady) {
                    return false;
                }
            }

            return true;
        });
    }
    //____________________________________________________________________________
    protected _initSections() {
        this.mSections = {
            basicInfo: new uoBasicInfoNew(this._getPart('basic_info_section', true)),
            material: new Array<uoMaterialNew>(),
            polarizerInfo: new uoPolarizingElementInfo(this._getPart('polarizer_info_section', true)),
            geometricalInfo: new uoGeometricalInfoNew(this._getPart('geometrial_info_section', true)),
            physicalInfo: new uoPhysicalInfoNew(this._getPart('physical_info_section', true)),
            coatingInfo: new uoCoatingInfoNew(this._getPart("coating-section", true)),
            userApertureInfo: new uoUserApertureInfoNew(this._getPart("aperture_info_section", true)),
            surfacesInfo: new uoSurfacesNew(this._getPart('surfaces_section', true), {
                onclick: (pFace: iFace) => this._onClickSurface(pFace),
                mouseenter: (pOriginalName: string) => this._onHighlight(pOriginalName),
                mouseleave: (pOriginalName: string) => this._onUnhighlight(pOriginalName),
            }),
            deformationSettings: new uoDeformationSettings(this._getPart("face-deformation-settings", true)),
        };
        this.mSections.polarizerInfo.getMaterialInstances(this.mSections.material);
        this.mSections.polarizerInfo.getLensGeoPropertiesInstance(this.mSections.geometricalInfo);
        this.mMaterialsContainer = this._getPart('material_sections', true);
    }
    //____________________________________________________________________________
    private _onUnhighlight(_pOriginalName: string) {
        if (this.mClickedFace == null) {
            this.mOptics3DPresenter.unHighlightMesh();
        }
    }
    //____________________________________________________________________________
    private _onHighlight(pOriginalName: string) {
        if (this.mClickedFace == null) {
            this.mOptics3DPresenter.highlightMesh(pOriginalName);
        }
    }
    //____________________________________________________________________________
    protected async _onClickSurface(pFace: iFace) {
        if (this.mClickedFace !== null && this.mClickedFace !== pFace) {
            // wa want to unhighlight the previous face
            this.mOptics3DPresenter.unHighlightMesh();
        }


        this.mClickedFace = pFace;

        if (pFace != null && pFace.originalName != null) {
            this.mOptics3DPresenter.highlightMesh(pFace.originalName);
            await this.mSections.coatingInfo.setData({ opticsVO: this.mData.opticsVO, originalName: pFace.originalName })
            await this.mSections.deformationSettings.setData({ opticsVO: this.mData.opticsVO, originalName: pFace.originalName })
        } else {
            this.mOptics3DPresenter.unHighlightMesh();
            await this.mSections.coatingInfo.setData(null);
            await this.mSections.deformationSettings.setData(null);
        }
    }
    //____________________________________________________________________________
    private async _fillForm() {
        this._clear();
        await this._fillSections();
        this.mOptics3DPresenter.showImageBtn = this.mSections.basicInfo.hasImageBtn;
        this.mOptics3DPresenter.onRefreshOptic(this.mData.opticsVO, { fitToZoom: true });
    }
    //____________________________________________________________________________
    protected async _onOpen(pData?: iUploadOpticsParamsNew) {
        this.mData = pData;
        this.mData.surfacesPart = this.mData.part;
        this.mData.originalOpticsVO = this.mData.opticsVO;
        ViewUtils.setElementVisibilityByDNone(this.mSaveAsBtn, false);
        this.mOptics3DPresenter.toUpdate = true;
        await this._fillForm();
    }
    //____________________________________________________________________________
    protected async _fillSections() {
        await this.mSections.basicInfo.setData({
            opticsVO: this.mData.opticsVO,
            formType: this.mData.formType
        });        
        await this.mSections.polarizerInfo.setData(this.mData.opticsVO);
        await this.mSections.geometricalInfo.setData(this.mData.opticsVO);
        await this._fillMaterials();
        await this.mSections.physicalInfo.setData(this.mData.opticsVO);
        await this.mSections.surfacesInfo.setData({
            opticsVO: this.mData.opticsVO,
            part: this.mData.surfacesPart
        });
        await this.mSections.deformationSettings.setData(null);
        await this.mSections.coatingInfo.setData(null);
        await this.mSections.userApertureInfo.setData(this.mData.opticsVO)
    }
    //____________________________________________________________________________
    private async _fillMaterials() {
        let aMaterials = this.mData.opticsVO.parameters.materialID.split(";");
        for (let i = 0; i < aMaterials.length; i++) {
            if ('' == aMaterials[i]) {
                continue;
            }

            let aUOMaterial = await uoMaterialNew.getOne({
                material: aMaterials[i],
                container: this.mMaterialsContainer,
                idx: i,
                wl: this.mData.opticsVO.parameters.wavelength,
                total_count: aMaterials.length
            });

            this.mSections.material.push(aUOMaterial);
            aUOMaterial.show(Strings.TRANSPARENT_MATERIAL != aMaterials[i]);
        }
    }
    //____________________________________________________________________________
    protected _onClose(): void {
        this.mSections.deformationSettings.clear()
        this.mSections.coatingInfo.clear()
        this.mSections.userApertureInfo.clear()
        this.mOptics3DPresenter.toUpdate = false;
        this.mOptics3DPresenter.onChangeItemType(eDisplayedItem.optic3D, false)
        this.mData = undefined;
    }
    //____________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mMaterialsContainer, 0);
        this.mSections.material.forEach(item => item.discard());
        this.mSections.material.splice(0);
    }
    //____________________________________________________________________________
}