
import { eAxisType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { Part } from "../../parts/Part";
import { eGroupType, ePartType } from "../../parts/PartInterfaces";
import { iOptixPartJSON, iDetectorData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { CageBehavior } from "../../parts/behaviors/CageBehavior";
import { LaserBehavior } from "../../parts/behaviors/LaserBehavior";
import { eSmRaysKind } from "../../simulation/SimulationContext";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { ChoosePartMode } from "../forms/ChoosePartMode";
import { Group } from "../forms/Group";
import { PartInfoSection } from "./PartInfoSection";
import { iArrayOfElementsSectionData, piArrayofElementsSettings } from "./_light_source_section/piArrayofElementsSettings";
import { iLightSourceSection, piLightSourceSection } from "./_light_source_section/piLightSourceSection";
import { iCageSection, piCageSection } from "./piCageSection";
import { piDetectorSection } from "./piDetectorSection";
import { iIrisSection, piIrisSection } from "./piIrisSection";
import { iCSFormData, piMoveRotateSection } from "./piMoveRotateSection";
import { iOpticsSection, piOpticsSettingsSection } from "./piOpticsSettingsSection";
import { iOptomechanicsOpticsSection, piOptomechanicsOpticsSettingsSection } from "./piOptomechanicsOpticsSettingsSection";
import { piPartBasicInfoSection } from "./piPartBasicInfoSection";
import { piPostSection } from "./piPostSection";

export interface iPartInfoSectionData<T = any> {
    /**
     * @description PartInfosection instance.
     */
    section: PartInfoSection<T>;

    /**
     * @description Determine if to show or hide the section, according to given condition.
     */
    showCondition: (pPartJSON?: iOptixPartJSON, pPart?: Part) => boolean;

    /**
     * @description Function to get appropriate data for the section.
     */
    getData: (pPartJSON?: iOptixPartJSON, pPart?: Part) => T;

    parent?: HTMLElement;
}

export interface iPISection {
    parent: HTMLElement;
    section: iPartInfoSectionData;
}

export class PartInfo extends Op3dComponentBase {

    private mSections: Array<iPartInfoSectionData>;
    private mActiveSection: HTMLElement;
    private mPinnedSection: PartInfoSection;
    private mOneTimeOpenSection: PartInfoSection;
    private mLightOffButton: HTMLElement;
    private mEditGroup: HTMLElement;
    private mUngroup: HTMLElement;

    //__________________________________________________________________________________________
    constructor() {
        super({
            container: document.getElementById('part_info_2'),
            skinPath: './skins/part_info/part_info_2.html'
        });
    }
    //__________________________________________________________________________________________
    public setPinSection(pSection: PartInfoSection) {
        if (null != this.mPinnedSection) {
            this.mPinnedSection.unPin();
        }

        this.mPinnedSection = pSection;
    }
    //__________________________________________________________________________________________
    public setOneTimeOpenSect(pSectionName: string) {
        let aSection = this.mSections.find((section) =>
            (section.section.constructor.name == pSectionName));

        if ((null == aSection) || (this.mPinnedSection == aSection.section)) {
            return;
        }

        this.mOneTimeOpenSection = aSection.section;
        if (null != this.mPinnedSection) {
            this.mPinnedSection.unPin();
            this.mPinnedSection.close();
            this.mPinnedSection = null;
        }
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        for (let i = 0; i < this.mSections.length; i++) {
            let aSection = this.mSections[i].section;
            aSection.close();
        }

        if (null == this.mPinnedSection) {
            this.mActiveSection = null;
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen(): void {
        this._onUpdate();
    }
    //__________________________________________________________________________________________
    public show(): void {
        ViewUtils.setClassShowState(this.mContainer, true);
    }
    //__________________________________________________________________________________________
    public hide(): void {
        ViewUtils.setClassShowState(this.mContainer, false);
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        let aSections = $(this.mContainer).find('.pi_section_parent');
        for (let i = 0; i < aSections.length; i++) {
            let aPIIcon = aSections[i].getElementsByClassName('pi_icon')[0];
            aPIIcon.addEventListener('click', () => this._onClickIcon(aSections[i]));
        }

        (<any>$(this.mContainer).find('[data-toggle="tooltip"]')).tooltip({ html: true }).on('click', function () {
            $(this).blur()
        });
        this._initSections();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._getPart('delete_icon').addEventListener('click',
            () => Op3dContext.PARTS_MANAGER.deleteSelected());
        this._getPart('duplicate_icon').addEventListener('click',
            () => Op3dContext.PARTS_MANAGER.duplicateSeleced());

        this.mLightOffButton = this._getPart('light_off');
        this.mLightOffButton.addEventListener('click', () => this.toggleLight());

        this.mEditGroup = this._getPart('edit_group');
        this.mEditGroup.addEventListener('click', () => {
            ChoosePartMode.instance.enterGroupMode()
            Group.instance.editGroup(Op3dContext.PARTS_MANAGER.selectedPart);
        });

        this.mUngroup = this._getPart('ungroup');
        this.mUngroup.addEventListener('click', () => {
            Op3dContext.PARTS_MANAGER.selectedPart.unHighlightObject()
            Group.instance.ungroup(Op3dContext.PARTS_MANAGER.selectedPart)
        });
    }
    //__________________________________________________________________________________________
    private toggleLight() {
        const aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        const aIsShown = SimulationRunner.instance.toggleRaysOfLaser(aPart);
        const aLaserBehavior = aPart.getBehavior('laserBehavior') as LaserBehavior;
        aLaserBehavior.showRays = !aLaserBehavior.showRays;

        if (true == aIsShown) {
            this.mLightOffButton.classList.replace('shown', 'hidden')
        } else {
            this.mLightOffButton.classList.replace('hidden', 'shown')
        }

    }
    //__________________________________________________________________________________________
    protected _onNew(): void {
        this.close()
    }
    //__________________________________________________________________________________________
    public manuallySetActiveSection(pSection: HTMLElement) {
        this.mActiveSection = pSection;
    }
    //__________________________________________________________________________________________
    private _onClickIcon(pSection: HTMLElement) {
        if (null != this.mActiveSection) {
            this.mActiveSection.dispatchEvent(new CustomEvent('hide_pi'));
        }

        if (this.mActiveSection != pSection) {
            this.mActiveSection = pSection;
            this.mActiveSection.dispatchEvent(new CustomEvent('show_pi'));
        } else {
            this.mActiveSection = null;
        }

        this.setPinSection(null);
    }
    //______________________________________________________________________________________________
    private _initSections() {
        this.mSections = new Array<iPartInfoSectionData>();


        this.mSections.push(
            this._getPartInformationSection(),
            this._getMovePartSection(),
            this._getLaserSection(),
            this._getArrayOfElementsSection(),
            this._getDetectorSection(),
            this._getCageSection(),
            this._getOpticSettingsSection(),
            this._getPostSection(),
            this._getIrisSection(),
            this._getOptomechanicsSettingsSection()
        );
    }
    //______________________________________________________________________________________________
    private _getOpticSettingsSection() {
        let aOpticsSettingsSection: iPartInfoSectionData<iOpticsSection> = {
            section: new piOpticsSettingsSection(this._getPart('advence_optics_section')),
            showCondition: (_pPartJson, pPart) => {
                let aOpticalAxis = pPart.getAxes().find(axis => (eAxisType.OPTICS === axis.type));
                let aOpticsHolder = (undefined !== aOpticalAxis) ? aOpticalAxis :
                    pPart.getSubpartByName(Strings.OPTICS_HOLDER);

                return ((null != aOpticsHolder) &&
                    (true != aOpticsHolder.object3D.userData.isDetector));
            },
            getData: (pPartJson, pPart) => {
                return {
                    part: pPart,
                    partData: pPartJson.partsData
                };
            }
        };

        return aOpticsSettingsSection;
    }
    //______________________________________________________________________________________________
    private _getOptomechanicsSettingsSection() {

        let aOptomechanicOpticsSettingsButton: iPartInfoSectionData<iOptomechanicsOpticsSection> = {
            section: new piOptomechanicsOpticsSettingsSection(this._getPart('optomechanics_optics_section')),
            showCondition: (_pPartJson, pPart) => {
                return ((null != pPart.opticalPartType));
            },
            getData: (_pPartJson, pPart) => {
                return {
                    part: pPart,
                    partData: pPart.opticalData
                };
            }
        };
        return aOptomechanicOpticsSettingsButton;
    }
    //______________________________________________________________________________________________
    private _getIrisSection() {
        let aIrisSection: iPartInfoSectionData<iIrisSection> = {
            section: new piIrisSection(this._getPart('iris_section')),
            showCondition: (pPartJSON) => (null != pPartJSON.behaviors.irisBehavior),
            getData: (pPartJSON, pPart) => {
                let aOuterRadius = pPart.getBehavior('irisBehavior').getPartOuterRadius(pPart);

                let aIrisSection: iIrisSection = {
                    irisBehaviorJSON: pPartJSON.behaviors.irisBehavior,
                    range: { min: 0, max: aOuterRadius }
                };

                return aIrisSection;
            }
        }

        return aIrisSection;
    }
    //______________________________________________________________________________________________
    private _getPostSection() {
        let aPostSection: iPartInfoSectionData<Part> = {
            section: new piPostSection(this._getPart('post-section')),
            showCondition: (_pPartJSON, pPart) => {
                let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id);
                if ((null == aPartVO) || (true != aPartVO.hasPost)) {
                    return false;
                }

                if (CageBehavior.isConnectedToCage(pPart)) return false

                return true;

            },
            getData: (_pPartJson, pPart) => pPart
        };
        return aPostSection;
    }
    //______________________________________________________________________________________________
    private _getCageSection() {
        let aCageSection: iPartInfoSectionData<iCageSection> = {
            section: new piCageSection(this._getPart('cage-section')),
            // showCondition: (pPartJSON, pPart) => (null != pPart.getBehavior('cageBehavior')),
            showCondition: (_pPartJSON, pPart) => {
                let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id);
                if ((null == aPartVO) || (true != aPartVO.hasCage)) {
                    return false;
                }
                return true;
            },
            getData: (_pPartJson, pPart) => { return { part: pPart }; }
        };

        return aCageSection;
    }
    //______________________________________________________________________________________________
    private _getDetectorSection() {

        let aDetectorSection: iPartInfoSectionData<{
            detectorData: iDetectorData,
            part: Part
        }> = {
            section: new piDetectorSection(this._getPart('detector-element-section')),
            showCondition: (_pPartJson, pPart) => {
                let aOpticsHolder = pPart.getSubpartByName(Strings.OPTICS_HOLDER);
                let aRet = ((null != aOpticsHolder) &&
                    (true == aOpticsHolder.object3D.userData.isDetector));

                return aRet;
            },
            getData: (_pPartJson, pPart) => {
                let aOpticsHolder = pPart.getSubpartByName(Strings.OPTICS_HOLDER);
                let aDetectorData = aOpticsHolder.subParts != null ?
                    aOpticsHolder.subParts[0].data.detectorData : null;
                return { detectorData: aDetectorData, part: pPart };
            }
        };

        return aDetectorSection;
    }
    //______________________________________________________________________________________________
    private _getArrayOfElementsSection() {
        let aArrayOfElementsSection: iPartInfoSectionData<iArrayOfElementsSectionData> = {
            section: new piArrayofElementsSettings(this._getPart("array-of-elements-section")),
            showCondition: (_pPartJson, pPart: Part) => {
                //let aCond1 = pPart.getBehavior('laserBehavior') !== undefined;

                const aFeatureFlag = Op3dContext.APP_FEATURES_CONFIG.array_of_elements.enabled;
                const aNotOpticInMount = (true === aFeatureFlag) &&
                    (pPart.refCS.cs.type !== eAxisType.OPTICS);
                const aIsArrayOfElements = (true === aFeatureFlag) &&
                    ((ePartType.GROUP === pPart.partOptions.type) &&
                        (pPart.groupData.group_type === eGroupType.ARRAY_OF_ELEMENTS));

                const aMainPart = pPart.linkedParts != null ? pPart.linkedParts[0] : pPart;
                const aMainGroup = Group.instance.findMainGroupPart(aMainPart);

                const aIsMemberOfRegularGroup = ((true === aFeatureFlag) && (aMainPart !== aMainGroup) &&
                    (ePartType.GROUP === aMainGroup.partOptions.type) &&
                    (aMainGroup.groupData.group_type === eGroupType.REGULAR));

                const aIsGroupItself = ((true === aFeatureFlag) &&
                    (pPart === aMainGroup) && (ePartType.GROUP === aMainGroup.partOptions.type) &&
                    (aMainGroup.groupData.group_type == eGroupType.REGULAR));
                if (pPart.getBehavior('laserBehavior') !== undefined && pPart.id !== 'L1111') {
                    return false;
                }
                return (
                    //cond1
                    pPart.getBehavior('laserBehavior') !== undefined ||

                    //cond2
                    (aNotOpticInMount && !aIsMemberOfRegularGroup) && !aIsGroupItself ||

                    //cond3
                    aIsArrayOfElements


                );
            },
            getData: (_pPartJson, pPart) => {

                let aOptions: iArrayOfElementsSectionData;

                const aLaserBehavior = pPart.getBehavior('laserBehavior');
                if (aLaserBehavior !== undefined) {
                    aOptions = {
                        hasCountCheck: false,
                        partInternalID: pPart.internalID,
                        countAddition: true,
                        defaults: piArrayofElementsSettings.DEFAULTS.source,
                        data: aLaserBehavior.laserData.lightSource.arrayOfSourcesData,
                        isGroupSettings: false
                    }

                } else {
                    let aMainPart = pPart.linkedParts != null ? pPart.linkedParts[0] : pPart;

                    aOptions = {
                        hasCountCheck: true,
                        isCad: aMainPart.id !== undefined && aMainPart.id !== null,
                        partInternalID: pPart.internalID,
                        countAddition: false,
                        data: pPart.groupData,
                        isGroupSettings: true,
                        defaults: piArrayofElementsSettings.getOpticDefaults(aMainPart)
                    }
                }

                return aOptions;
            }
        }

        return aArrayOfElementsSection;
    }
    //______________________________________________________________________________________________
    private _getMovePartSection() {

        let aMovePartSection: iPartInfoSectionData<iCSFormData> = {
            section: new piMoveRotateSection(this._getPart('move_part_section')),
            showCondition: (_pPartJson, pPart) => {
                if (true == pPart.partOptions.static) {
                    return false;
                }

                let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id)

                if (aPartVO != null) {
                    if ((null != aPartVO?.hasCage && false != aPartVO?.hasCage) &&
                        (true == CageBehavior.isConnectedToCage(pPart))) {
                        return false;
                    }
                }

                return true;
            },
            getData: (_pPartJson, pPart) => {
                return {
                    part: pPart,
                    refCS: ((null != pPart.refCS) ? pPart.refCS : null)
                };
            }
        };

        return aMovePartSection;
    }
    //______________________________________________________________________________________________
    private _getPartInformationSection() {
        let aPartInformation: iPartInfoSectionData<Part> = {
            section: new piPartBasicInfoSection(this._getPart('part-information-section')),
            showCondition: (pPart) => (ePartType.GROUP !== pPart.partOptions.type),
            getData: (_pPartJson, pPart) => pPart
        };

        return aPartInformation;
    }
    //______________________________________________________________________________________________
    private _getLaserSection() {
        let aLaserSection: iPartInfoSectionData<iLightSourceSection> = {
            section: new piLightSourceSection(this._getPart('light-source-section')),
            showCondition: (pPartJson) => (null != pPartJson.behaviors.laserBehavior),
            getData: (pPartJson, pPart) => {
                let aLaserBehaviorJSON = pPartJson.behaviors.laserBehavior;
                let aLaserBehavior = pPart.getBehavior('laserBehavior');

                let aLightSourceSection: iLightSourceSection = {
                    part: pPart,
                    laserBehavior: aLaserBehaviorJSON,
                    id: pPart.id,
                    alpha: aLaserBehavior.laserData.lightSource.alpha,
                    color: aLaserBehavior.laserData.lightSource.color,
                    rays_color: aLaserBehavior.laserData.lightSource.rays_color,
                    model_radius: aLaserBehavior.laserData.lightSource.model_radius,
                    isSingleSource: aLaserBehavior.laserData.isSingleSource,
                    isUserDefinedLaser: aLaserBehavior.laserData.isUserDefinedLaser
                };

                return aLightSourceSection;
            }
        };

        return aLaserSection;
    }
    //__________________________________________________________________________________________
    protected async _onUpdate() {
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if (null == aSelectedPart) {
            return;
        }

        let aPartJSON = aSelectedPart.exportToJSONObject();
        for (let i = 0; i < this.mSections.length; i++) {
            const aSectionData = this.mSections[i];
            let aToShow = (true == aSectionData.showCondition(aPartJSON, aSelectedPart));
            if (true == aToShow) {
                let aData = aSectionData.getData(aPartJSON, aSelectedPart);
                aSectionData.section.data = aData;
            }

            await aSectionData.section.setParentVisibility(aToShow);
        }

        if (null != this.mOneTimeOpenSection) {
            this.mOneTimeOpenSection.show();
            this.mOneTimeOpenSection = null;
        } else if ((null != this.mPinnedSection) && (true == this.mPinnedSection.isVisible())) {
            this.mPinnedSection.show();
        }

        let aIsLaser = (null != aPartJSON.behaviors.laserBehavior);
        let aIsShowlight = aIsLaser && aPartJSON.behaviors.laserBehavior.laserData.lightSource.kind !== eSmRaysKind.GAUSSIAN_BEAM;
        let aIsGroup = (ePartType.GROUP === aSelectedPart.partOptions.type);

        ViewUtils.setElementVisibilityByDNone(this.mLightOffButton, aIsShowlight);

        if (aIsLaser) {
            const aLightBtnState = SimulationRunner.instance.isRaysShownOfLaser(Op3dContext.PARTS_MANAGER.selectedPart);
            if (aLightBtnState) {
                this.mLightOffButton.classList.replace('shown', 'hidden')
            } else {
                this.mLightOffButton.classList.replace('hidden', 'shown')
            }
        }

        ViewUtils.setElementVisibilityByDNone(this.mEditGroup, aIsGroup);
        ViewUtils.setElementVisibilityByDNone(this.mUngroup, aIsGroup);

        this.isToHideDeleteRemoveBtns(aIsGroup)
    }
    //__________________________________________________________________________________________
    private isToHideDeleteRemoveBtns(pIsToHide: boolean) {
        if (pIsToHide == true) {
            this._getPart('delete_icon').classList.add('d-none')
            this._getPart('duplicate_icon').classList.add('d-none')
        } else {
            this._getPart('delete_icon').classList.remove('d-none')
            this._getPart('duplicate_icon').classList.remove('d-none')
        }
    }
    //__________________________________________________________________________________________
    public updateSections(pSections: string[]) {

        try {
            for (let i = 0; i < pSections.length; i++) {
                let aSection = this.mSections.find((section) => section.constructor.name == pSections[i]);
                if (aSection) {
                    const aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
                    const aPartJSON = aSelectedPart.exportToJSONObject();
                    const aData = aSection.getData(aPartJSON, aSelectedPart);
                    aSection.section.data = aData;
                    aSection.section.update();
                }
            }
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
        }
    }
    //__________________________________________________________________________________________
    public updateSectionData<T = any>(pSectionName: string, pData: T) {
        try {
            let aSection = this.mSections.find((section) =>
                (section.constructor.name == pSectionName));
            if (aSection) {
                aSection.section.data = pData;
                aSection.section.update();
            }
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
        }
    }
    //__________________________________________________________________________________________


}
