import { eUnitType, eSimulationType, ePolarizationType, eAxisType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MathContext } from "../../_context/MathContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { eSmRaysDensity, eSmRaysKind, iSmSystemFile, eSmPlaneWaveType, SimulationContext } from "../../simulation/SimulationContext";
import { NotificationCenter } from "../../ui/home/_notifications/NotificationCenter";
import { Part } from "../Part";
import { iLaserBehaviorJSONObject, iLaserDataJSON } from "../_parts_assets/ExportToJSONInterfaces";
import { PartsFactory } from "../_parts_assets/PartsFactory";
import { Behavior } from "./Behavior";
import { iLaserBehavior } from "./BehaviorContext";
import {
    iCircularPlaneWaveData, iLaserData, iSourceDirectionalData,
    iLightSource, iPointSourceData, LightSourceContext,
    iBasicLightSource, eCountType, eWavelengthDistributionType,
    iEllipticalPlaneWaveData, iRectanglePlaneWaveData,
    iBasicLightSourceParams, iWavelengthData, iGaussianBeam, tRaysKind
} from "./LightSourceContext";
import { EventManager } from "../../../oc/events/EventManager";
import { LaserData } from "../../data/VO/LaserData";
import { iSimulationRaysOptions } from "../../simulation/SimulationRunner";
import { iAnalysisItem } from "../../ui/analysis/AnalysisContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { Matrix4, Vector3, Color, MeshPhongMaterial } from "three";
import { SourcesDataLoader } from "../../data/data_loader/SourcesDataLoader";
import { iSourceVO } from "../../parser/SourcesParser";
import { ComplexNumber } from "../../_utils/ComplexNumber";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import Complex from "complex.js";
import { iArrayOfElementsOptions } from "../PartInterfaces";


export class LaserBehavior extends Behavior<Array<iLaserData>> implements iLaserBehavior {
    private static _DEFAULT_CIRCULAR_PROFILE_GEO: iCircularPlaneWaveData;
    // used for send to snellius for point source profile
    public static MAX_LIGHT_SOURCE_MODEL: number = 40;
    public static MATRIX_DEF_SIZE: number = 10;
    public static LASER_MASK_NAMES_HASH = ["No Mask", "PI Mask", "3D Form",
        "Two Dots Mask", "Up Arrow Mask", "Full Matrix", "LETTER R", "Custom Mask"];

    private mIsActive: boolean = true;
    private mLasersData: iLaserData;
    private mIsReady = false;
    private mAnalysisOptions: iHash<number> = {};
    private mShowRays: boolean = true;


    constructor(pPart: Part) {
        super();

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOByNumberID(pPart.numberID);
        if (undefined === aPartVO) {
            aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id);
        }

        let aLaserData = aPartVO.laserData;
        this._init(pPart, aLaserData);

        EventManager.addEventListener(EventsContext.ON_NEW, () => this._onNew(), this);
    }
    //______________________________________________________________________________________________
    public static get DEFAULT_CIRCULAR_PROFILE_GEO() {
        if (undefined === LaserBehavior._DEFAULT_CIRCULAR_PROFILE_GEO) {
            this._DEFAULT_CIRCULAR_PROFILE_GEO = {
                density_pattern: eSmRaysDensity.CONCENTRIC_CIRCLES,
                count: SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.regular,
                count_analysis: SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.analysis,
                radius: (eUnitType.MILLIMETERS === UnitHandler.presentedScale) ? 1 : 1.016
            };
        }

        return this._DEFAULT_CIRCULAR_PROFILE_GEO;
    }
    //______________________________________________________________________________________________
    public get showRays(): boolean {
        return this.mShowRays;
    }
    //______________________________________________________________________________________________
    public set showRays(value: boolean) {
        this.mShowRays = value;
    }
    //______________________________________________________________________________________________
    public static getWorldDirection(pWorldMatrix: Matrix4,) {
        let aRotationMat = new Matrix4().extractRotation(pWorldMatrix.clone());
        let aDirectionVector = new Vector3(0, 0, 1).applyMatrix4(aRotationMat);
        return aDirectionVector;
    }
    //______________________________________________________________________________________________
    public updateAnalysisOptions(pData: {
        toRemove: boolean,
        analysis: iAnalysisItem, raysCount: number
    }) {
        if (pData.toRemove) {
            delete this.mAnalysisOptions[pData.analysis.id];
        } else {
            if (undefined !== this.mAnalysisOptions[pData.analysis.id]) {
                this.mAnalysisOptions[pData.analysis.id] = pData.raysCount;
            }
        }
    }
    //______________________________________________________________________________________________
    private _onNew() {
    }
    //______________________________________________________________________________________________
    public get laserData() {
        return this.mLasersData;
    }
    //______________________________________________________________________________________________
    public getPrimaryWavelength() {
        if ((undefined === this.mLasersData) || (undefined === this.mLasersData) ||

            (undefined === this.mLasersData.lightSource) ||
            (undefined === this.mLasersData.lightSource.wavelengthData)) {
            return null;
        }

        let aWavelengthData = this.mLasersData.lightSource.wavelengthData;
        for (let i = 0; i < aWavelengthData.length; i++) {
            if (true === aWavelengthData[i].isPrimary) {
                return aWavelengthData[i].wl;
            }
        }
        return null;
    }
    //______________________________________________________________________________________________
    public static getCombinedMatrix(pDirectionalData: iSourceDirectionalData) {
        const aPhiRad = pDirectionalData.direction_phi * MathContext.DEG_TO_RAD;
        const aThetaRad = (-pDirectionalData.direction_theta) * MathContext.DEG_TO_RAD;
        let aAxis = new Vector3(Math.cos(aPhiRad), Math.sin(aPhiRad), 0);
        aAxis = aAxis.cross(new Vector3(0, 0, 1)).normalize();
        let aMat = new Matrix4();
        aMat.makeRotationAxis(aAxis, aThetaRad);

        const aZDag = pDirectionalData.azimuth_z * MathContext.DEG_TO_RAD;
        let aMatZ = new Matrix4();
        let aAxisZ = new Vector3(0, 0, 1);
        aMatZ.makeRotationAxis(aAxisZ, aZDag);

        let aNewMat = new Matrix4().multiplyMatrices(aMat, aMatZ);
        return aNewMat;
    }
    //______________________________________________________________________________________________
    private _isValidLightSource(pLightSource: iLightSource, pPart: Part) {
        if (pLightSource.kind === null) {
            return ("Invalid light source kind " + pPart.getIndexedLabel(false));
        }

        switch (pLightSource.kind) {
            case eSmRaysKind.PLANE_WAVE: {
                if (pLightSource.shape === undefined || pLightSource.shape === ("" as any)) {
                    return ("Invalid shape for " + pPart.getIndexedLabel(false));
                }
                break;
            }
            case eSmRaysKind.POINT_SOURCE: {
                if (pLightSource.sourceGeometricalData === undefined ||
                    (pLightSource.sourceGeometricalData as iPointSourceData).divergence_type === ("" as any)) {
                    return ("Invalid divergence type for " + pPart.getIndexedLabel(false));
                }
                break;
            }
        }

        return null;
    }
    //______________________________________________________________________________________________
    public updateJsonSimulationData(pSystemFile: iSmSystemFile,
        pWorldMatrix: Matrix4,
        pPart: Part,
        pRaysData: iSimulationRaysOptions) {

        if (false === this.mIsActive) {
            return;
        }

        const aLightSource = this.mLasersData.lightSource;
        let aInvalidMsg = this._isValidLightSource(aLightSource, pPart);
        if (null !== aInvalidMsg) {
            NotificationCenter.instance.pushNotification({
                message: aInvalidMsg,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
            });
            return;
        }

        if (aLightSource.isUserDefined) {

            // do nothing for now

        } else if (undefined === aLightSource.light_source_number_id) {
            return;
        }

        let aWavelengths = new Array<number>();
        let aNormalizedWeights = new Array<number>();
        let aTotalWeight = 0;
        aLightSource.wavelengthData.map(item => aTotalWeight += item.weight);

        for (let i in aLightSource.wavelengthData) {
            aWavelengths.push(aLightSource.wavelengthData[i].wl);
            let aCurrWeight = aLightSource.wavelengthData[i].weight
            aNormalizedWeights.push(aCurrWeight / aTotalWeight);
        }

        let aWorldMatrix = pWorldMatrix.clone();
        let aLaserPoint = pPart.getAxes().find((axis) => (axis.type === eAxisType.LASER));
        if (undefined !== aLaserPoint) {
            aWorldMatrix.extractRotation(aLaserPoint.object3D.matrixWorld)
            aWorldMatrix.copyPosition(aLaserPoint.object3D.matrixWorld);
        }

        let aCombinedMatrix = LaserBehavior.getCombinedMatrix(aLightSource.directionalData);
        let aNewMatrix4 = new Matrix4().multiplyMatrices(aWorldMatrix, aCombinedMatrix);

        let aNumRays: Number;
        if (pRaysData.simulationType === eSimulationType.VISUALIZATION) {
            aNumRays = aLightSource.sourceGeometricalData.count;

        } else {
            aNumRays = aLightSource.sourceGeometricalData.count_analysis;
        }
        let aJonesMatrix = LightSourceContext.mDefaultJones;
        if (pPart.getBehavior('laserBehavior').laserData.lightSource.polarization.polarizationState !== ePolarizationType.UNPOLARIZED &&
            pPart.getBehavior('laserBehavior').laserData.lightSource.polarization.polarizationData !== undefined) {
            let aPolarization = pPart.getBehavior('laserBehavior').laserData.lightSource.polarization.polarizationData
            aJonesMatrix = this.calculatePolarizationDataForSimulation(aPolarization.jones_vector.principal_x,
                aPolarization.jones_vector.principal_y,
                aPolarization.jones_vector.orthogonal_x,
                aPolarization.jones_vector.orthogonal_y,
                aPolarization.general.polarization_ratio)
        }
        switch (aLightSource.kind) {
            case eSmRaysKind.PLANE_WAVE: {
                LightSourceContext.addPlaneWaveSource({
                    lightSource: aLightSource,
                    normalizedWeights: aNormalizedWeights,
                    systemFile: pSystemFile,
                    part: pPart,
                    wavelengths: aWavelengths,
                    numRays: aNumRays,
                    matrix: aNewMatrix4,
                    jones_vector: aJonesMatrix
                });
                break;
            }

            case eSmRaysKind.POINT_SOURCE: {
                LightSourceContext.addPointSource({
                    lightSource: aLightSource,
                    systemFile: pSystemFile,
                    wavelengths: aWavelengths,
                    normalizedWeights: aNormalizedWeights,
                    numRays: aNumRays,
                    matrix: aNewMatrix4,
                    part: pPart,
                    jones_vector: aJonesMatrix
                });
                break;
            }

            case eSmRaysKind.RAY_FILE: {
                LightSourceContext.addRayFile({
                    lightSource: aLightSource,
                    matrix: aNewMatrix4,
                    numRays: aNumRays,
                    part: pPart,
                    systemFile: pSystemFile
                })
                break;
            }

            case eSmRaysKind.GAUSSIAN_BEAM: {
                LightSourceContext.addGaussianSource({
                    lightSource: aLightSource,
                    normalizedWeights: aNormalizedWeights,
                    systemFile: pSystemFile,
                    numRays: aNumRays,
                    part: pPart,
                    wavelengths: aWavelengths,
                    matrix: aNewMatrix4,
                    jones_vector: aJonesMatrix
                });
                break;
            }


            default: throw new Error("unhandeled type");
        }

    }
    //______________________________________________________________________________________________
    public exportToJSON(_pPart: Part, pJSONData: iLaserBehaviorJSONObject) {
        let aDirection = this.mLasersData.direction.clone();
        let aExitPoint = this.mLasersData.exitPoint.clone();

        let aLaserData: iLaserDataJSON = {
            direction: [aDirection.x, aDirection.y, aDirection.z],
            exitPoint: [aExitPoint.x, aExitPoint.y, aExitPoint.z],
            lightSource: this.mLasersData.lightSource
        };


        pJSONData.laserBehavior = {
            analysisOptions: this.mAnalysisOptions,
            laserData: aLaserData,
            status: this.mIsActive
        };
    }
    //______________________________________________________________________________________________
    private async _preparePart() {
        while (!this.mIsReady) {
            await Op3dContext.sleep(50);
        }
    }
    //______________________________________________________________________________________________
    public async initFromJSON(pPart: Part,
        pJSONData: iLaserBehaviorJSONObject,
        _pUseIDS: boolean) {

        if ((undefined === pJSONData) || (undefined === pJSONData.laserBehavior) ||
            (undefined === pJSONData.laserBehavior.laserData)) {
            return;
        }

        await this._preparePart();

        const aLaserData = pJSONData.laserBehavior.laserData;
        // when we duplicate the part we loose the array type and  get laserdata as 
        // object that doent have the property length
        for (let key in aLaserData.lightSource) {
            this.mLasersData.lightSource[key] = aLaserData.lightSource[key];
        }

        pPart.getBehavior('laserBehavior').mIsActive =
            (undefined !== pJSONData.laserBehavior.status) ? pJSONData.laserBehavior.status : true;

        this._initUserDefinedLightSource(pPart);
    }
    //______________________________________________________________________________________________
    private _initUserDefinedLightSource(pPart: Part) {
        if (this.mLasersData.isUserDefinedLaser) {

            if (undefined !== this.mLasersData.lightSource.gaussianBeam) {
                this.mLasersData.lightSource.gaussianBeam = {
                    q_x: new ComplexNumber(this.mLasersData.lightSource.gaussianBeam.q_x),
                    q_y: new ComplexNumber(this.mLasersData.lightSource.gaussianBeam.q_y),
                }
            }

            this._setModel(pPart);

            if (null !== this.mLasersData.lightSource.color) {
                this.mLasersData.lightSource.alpha =
                    (undefined !== this.mLasersData.lightSource.alpha) ?
                        this.mLasersData.lightSource.alpha : 1;
                this.changeMaterialColor(this.mLasersData.lightSource.alpha,
                    this.mLasersData.lightSource.color, pPart)
            }
        }
    }
    //______________________________________________________________________________________________
    public changeMaterialColor(pAlpha: number, pColor: string, pPart: Part) {
        let aFaces = pPart.getFaces();
        let aNewMat = new MeshPhongMaterial({
            opacity: pAlpha,
            transparent: (1 !== pAlpha),
            color: new Color(pColor)
        });


        for (let i = 0; i < aFaces.length; i++) {
            (aFaces[i].visualization.mesh.material as any) = aNewMat.clone();

            // (aFaces[i].visualization.mesh.material as any).color = new Color(pColor);
            // (aFaces[i].visualization.mesh.material as any).transparent = (1 !== pAlpha);
            // (aFaces[i].visualization.mesh.material as any).opacity = pAlpha;
        }
    }
    //______________________________________________________________________________________________
    private _initUserDefinedLaser() {
        let aBasicLightSource: iBasicLightSource<iCircularPlaneWaveData> = {
            count_type: eCountType.TOTAL,
            power: 1,
            alpha: 1,
            color: null,
            model_radius: 0,
            rays_color: '#91ff00',
            kind: eSmRaysKind.PLANE_WAVE,
            shape: eSmPlaneWaveType.CIRCULAR,
            isUserDefined: true,
            wavelengthData: [{ weight: 1, wl: 550, isPrimary: true }],
            distribution_data: {
                type: eWavelengthDistributionType.USER_DEFINED
            },
            sourceGeometricalData: LaserBehavior.DEFAULT_CIRCULAR_PROFILE_GEO,
            directionalData: {
                azimuth_z: 0, direction_phi: 0, direction_theta: 0,
                direction_x: 0, direction_y: 0, direction_z: 1
            },
            polarization: { polarizationState: ePolarizationType.UNPOLARIZED },
            arrayOfSourcesData: {
                is_array: false,
                group_type: undefined
            },
            gaussianBeam: this._getDefaultGaussianBeam()
        };

        return aBasicLightSource;
    }
    //______________________________________________________________________________________________
    private _getDefaultGaussianBeam() {
        let aWavelength = (550 * OP3DMathUtils.NANO_TO_MM);
        let waist = 1;

        let aZr = ((waist * waist * Math.PI) / (aWavelength));

        let q_x = new ComplexNumber({ im: aZr, re: 0 });
        let q_y = q_x.clone();

        let aGaussianBeam: iGaussianBeam = {
            q_x: q_x,
            q_y: q_y
        };

        return aGaussianBeam;
    }
    //______________________________________________________________________________________________
    private _initLaserFromJSONData(pCurrLaserData: LaserData) {
        let aX = pCurrLaserData.direction.x;
        let aY = pCurrLaserData.direction.y;
        let aZ = pCurrLaserData.direction.z;
        let aTheta = Math.atan(aY / aX) * MathContext.RAD_TO_DEG;
        let aVal = Math.sqrt((aX * aX) + (aY * aY));
        let aPhi = Math.atan(aVal / aZ) * MathContext.RAD_TO_DEG;

        let aBasicLightSource: iBasicLightSource<iCircularPlaneWaveData> = {
            count_type: eCountType.TOTAL,
            power: 1,
            alpha: 1,
            color: null,
            model_radius: 0,
            rays_color: '#91ff00',
            kind: eSmRaysKind.PLANE_WAVE,
            shape: eSmPlaneWaveType.CIRCULAR,
            wavelengthData: [],
            distribution_data: {
                type: eWavelengthDistributionType.USER_DEFINED
            },
            sourceGeometricalData: LaserBehavior.DEFAULT_CIRCULAR_PROFILE_GEO,
            directionalData: {
                azimuth_z: 0,
                direction_phi: isNaN(aPhi) ? 0 : aPhi,
                direction_theta: isNaN(aTheta) ? 0 : aTheta,
                direction_x: pCurrLaserData.direction.x,
                direction_y: pCurrLaserData.direction.y,
                direction_z: pCurrLaserData.direction.z
            },
            polarization: { polarizationState: ePolarizationType.UNPOLARIZED },
            arrayOfSourcesData: {
                is_array: false,
                group_type: undefined
            }
        };

        return aBasicLightSource;
    }
    //______________________________________________________________________________________________
    private _initLaserFrom3DData() {
        let aBasicLightSource: iBasicLightSource = {
            arrayOfSourcesData: {
                is_array: false,
                group_type: undefined
            },
            count_type: eCountType.TOTAL,
            wavelengthData: [],
            distribution_data: {
                type: eWavelengthDistributionType.USER_DEFINED
            },
            color: null,
            alpha: 1,
            model_radius: 0,
            rays_color: '#91ff00',
            power: 1,
            kind: eSmRaysKind.PLANE_WAVE,
            shape: eSmPlaneWaveType.CIRCULAR,
            sourceGeometricalData: LaserBehavior.DEFAULT_CIRCULAR_PROFILE_GEO,
            directionalData: {
                azimuth_z: 0,
                direction_phi: 0,
                direction_theta: 0,
                direction_x: 0,
                direction_y: 0,
                direction_z: 1
            },
            polarization: {
                polarizationState: ePolarizationType.UNPOLARIZED
            }
        };

        return aBasicLightSource;
    }
    //______________________________________________________________________________________________
    private async _init(pPart: Part, pData: LaserData) {
        let aLaserPart = pPart.getAxes().find((axis) => (eAxisType.LASER === axis.type));

        let aBasicLightSource: iBasicLightSource<tRaysKind>;
        if (undefined !== pData) {
            if (true === pData.isUserDefinedLaser) {
                aBasicLightSource = this._initUserDefinedLaser();
            } else {
                aBasicLightSource = this._initLaserFromJSONData(pData);
            }
        } else {
            aBasicLightSource = this._initLaserFrom3DData();
        }

        this.mLasersData = {
            direction: (undefined !== pData) ? pData.direction.clone() :
                new Vector3(aBasicLightSource.directionalData.direction_x,
                    aBasicLightSource.directionalData.direction_y,
                    aBasicLightSource.directionalData.direction_z),
            exitPoint: aLaserPart.object3D.position,
            lightSource: {
                arrayOfSourcesData: aBasicLightSource.arrayOfSourcesData,
                count_type: aBasicLightSource.count_type,
                distribution_data: {
                    type: eWavelengthDistributionType.USER_DEFINED
                },
                model_radius: aBasicLightSource.model_radius,
                rays_color: aBasicLightSource.rays_color,
                alpha: aBasicLightSource.alpha,
                color: aBasicLightSource.color,
                isUserDefined: aBasicLightSource.isUserDefined,
                shape: aBasicLightSource.shape,
                power: aBasicLightSource.power,
                polarization: aBasicLightSource.polarization,
                directionalData: aBasicLightSource.directionalData,
                kind: aBasicLightSource.kind,
                light_source_number_id: pPart.numberID,
                wavelengthData: aBasicLightSource.wavelengthData,
                sourceGeometricalData: aBasicLightSource.sourceGeometricalData,
                gaussianBeam: aBasicLightSource.gaussianBeam
            },
            isUserDefinedLaser: pData.isUserDefinedLaser,
            isSingleSource: pData.isSingleSource,
        };

        await this._initLightSource(pData);

        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    private async _initLightSource(pCurrLaserData: LaserData) {
        if (undefined === pCurrLaserData.lightSourceID) {
            return;
        }

        const aLightSource = await SourcesDataLoader.instance.getSingleFullData({
            number_id: pCurrLaserData.lightSourceID
        });
        this.setLightSource(aLightSource, 1);
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //______________________________________________________________________________________________
    public set active(pActive: boolean) {
        this.mIsActive = pActive;
    }
    //______________________________________________________________________________________________
    public get active(): boolean {
        return this.mIsActive;
    }
    //______________________________________________________________________________________________
    public setLightSource(pSourceVO: iSourceVO, pWeight: number) {
        this.mLasersData.lightSource.light_source_number_id = pSourceVO.number_id;
        this.mLasersData.lightSource.wavelengthData = [{
            isPrimary: true,
            weight: pWeight,
            wl: pSourceVO.parameters.data.center_wavelength
        }];
    }
    //______________________________________________________________________________________________
    public updateWavelengths(pWavelengthsData: iWavelengthData) {
        this.mLasersData.lightSource.wavelengthData = pWavelengthsData.wavelengthData;
        this.mLasersData.lightSource.distribution_data = pWavelengthsData.distribution_data;;
    }
    //______________________________________________________________________________________________
    private _setModel(pPart: Part) {
        let aLightsource = this.mLasersData.lightSource;
        let aMesh = PartsFactory.getLightSourceModel(aLightsource.kind, aLightsource);
        if (aMesh !== undefined) {
            pPart.getFaces()[0].visualization.mesh.geometry = aMesh.geometry;
            pPart.getFaces()[0].visualization.mesh.material = aMesh.material;
            pPart.getFaces()[0].visualization.mesh.rotation.copy(aMesh.rotation);
        }
    }
    //______________________________________________________________________________________________
    private _isNeedToChangeModel(pOld: iLightSource, pNew: iBasicLightSourceParams) {
        if ((true !== this.mLasersData.isUserDefinedLaser)) {
            return false;
        }

        if ((undefined !== (pNew as any).shape) && (pOld.shape != (pNew as any).shape)) {
            return true;
        }

        if (eSmRaysKind.GAUSSIAN_BEAM === pNew.kind) {
            if (eSmRaysKind.GAUSSIAN_BEAM === pOld.kind) {
                let aNew = pNew as iBasicLightSource;
                if ((false === pOld.gaussianBeam.q_x.isEqual(aNew.gaussianBeam.q_x)) ||
                    (false === pOld.gaussianBeam.q_y.isEqual(aNew.gaussianBeam.q_y))) {
                    return true;
                }
            } else {
                return true;
            }
        }


        if (pOld.kind === eSmRaysKind.POINT_SOURCE) {
            if (pOld.model_radius !== (pNew as any).model_radius) {
                return true;
            }
        }

        if (pOld.shape === eSmPlaneWaveType.CIRCULAR) {
            if ((pOld.sourceGeometricalData as iCircularPlaneWaveData).radius !=
                (pNew.sourceGeometricalData as iCircularPlaneWaveData).radius) {
                return true;
            }
        }

        if (pOld.shape === eSmPlaneWaveType.ELLIPTICAL) {
            if (((pOld.sourceGeometricalData as iEllipticalPlaneWaveData).radius_x !=
                (pNew.sourceGeometricalData as iEllipticalPlaneWaveData).radius_x) ||
                ((pOld.sourceGeometricalData as iEllipticalPlaneWaveData).radius_y !=
                    (pNew.sourceGeometricalData as iEllipticalPlaneWaveData).radius_y)) {
                return true;
            }
        }

        if (pOld.shape === eSmPlaneWaveType.RECTANGULAR) {
            if (((pOld.sourceGeometricalData as iRectanglePlaneWaveData).width !=
                (pNew.sourceGeometricalData as iRectanglePlaneWaveData).width) ||
                ((pOld.sourceGeometricalData as iRectanglePlaneWaveData).height !=
                    (pNew.sourceGeometricalData as iRectanglePlaneWaveData).height)) {
                return true;
            }
        }

        return false;
    }
    //______________________________________________________________________________________________
    public updateArrayOfSources(pPart: Part, pData: iArrayOfElementsOptions) {
        this.mLasersData.lightSource.arrayOfSourcesData = pData;

        this._setModel(pPart);

        if (pData.is_array === true) {
            pPart.setPartLabel("Array of sources");
        } else {
            pPart.setPartLabel("User-defined light source");
        }
    }
    //______________________________________________________________________________________________
    public updateLightSourceGeo(pPart: Part, pLightSourceGeo: iBasicLightSourceParams): void {

        let aNeedToUpdate = this._isNeedToChangeModel(this.mLasersData.lightSource, pLightSourceGeo);
        for (let key in pLightSourceGeo) {
            this.mLasersData.lightSource[key] = pLightSourceGeo[key];
        }

        /**
         * we will set the shape according to th first laser data that we have
         */
        if (aNeedToUpdate) {
            this._setModel(pPart);
        }

        Op3dContext.DIV_CONTROLLER.updatePartInfo();
    }
    //_______________________________________________________________________
    private calculatePolarizationDataForSimulation(pPrincipalJx: string, pPrincipalJy: string, pOrthogonalJx: string, pOrthogonalJy: string, pPolarizationRatio: number) {
        let aPrincipalJx = Complex(pPrincipalJx);
        let aPrincipalJy = Complex(pPrincipalJy);
        let aOrthogonalJx = Complex(pOrthogonalJx);
        let aOrthogonalJy = Complex(pOrthogonalJy);
        let aSqrtRatio = Math.sqrt(pPolarizationRatio)

        let aXDiv = aOrthogonalJx.div(aSqrtRatio)
        let aJxSimulation = aPrincipalJx.add(aXDiv);

        let aYDiv = aOrthogonalJy.div(aSqrtRatio)
        let aJySimulation = aPrincipalJy.add(aYDiv);

        return [[aJxSimulation.re, aJxSimulation.im], [aJySimulation.re, aJySimulation.im]];
    }
    //______________________________________________________________________________________________
}
