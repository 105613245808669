import { eUnitType, eDataPermission } from "../../../../_context/Enums";
import { eOpticsTypeNames } from "../../../../_context/OpticsContext";
import { OptomechanicContext, THREAD, eDriveType, eMountingData, eOptomechanicTypeNames, eOrientationTypes } from "../../../../_context/OptomechanicContext";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { iOptomechanicVO } from "../../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../../parts/Part";
import { MenuListAbstract } from "../MenuListAbstract";
import { iOneRadioBtnData, iRadioBtnsData, eFilterType, iOneCheckBoxData, iMultipleCheckboxData } from "../MenusContext";
import { iBootstrapSliderOptions } from "../filters/RangeFilter";
import { FiltersHandler } from "./FiltersHandler";

export enum eOMPFilterName {
    number_id = 'number_id',
    owner = 'owner',
    permission = 'permission',
    type = 'info.parameters.type',
    sub_type = 'info.parameters.sub_type',
    brand = 'info.parameters.brand',
    typebrand = 'info.parameters.typebrand',
    catalog_number = 'info.parameters.catalog_number',
    main_name = 'info.parameters.main_name',
    metric_imperial = 'info.parameters.metric_imperial',
    material = 'info.parameters.material',
    finish_coated = 'info.parameters.finish_coated',
    optical_shape = 'info.parameters.optical_shape',
    construction = 'info.parameters.construction',
    maximum_depth = 'info.parameters.maximum_depth',
    size_of_compatible_optics = 'info.parameters.size_of_compatible_optics',
    size_of_compatible_optics_min = 'info.parameters.size_of_compatible_optics_min',
    size_of_compatible_optics_max = 'info.parameters.size_of_compatible_optics_max',
    tall_rectangular_optics = 'info.parameters.tall_rectangular_optics',
    optics_diameter_size = 'info.parameters.optics_diameter_size',
    optics_diameter_size_min = 'info.parameters.optics_diameter_size_min',
    optics_diameter_size_max = 'info.parameters.optics_diameter_size_max',
    optic_thickness_min = 'info.parameters.optic_thickness_min',
    optic_thickness_max = 'info.parameters.optic_thickness_max',
    thickness_of_compatible_optics = 'info.parameters.thickness_of_compatible_optics',
    thickness_of_compatible_optics_min = 'info.parameters.thickness_of_compatible_optics_min',
    thickness_of_compatible_optics_max = 'info.parameters.thickness_of_compatible_optics_max',
    clear_aparture_diameter = 'info.parameters.clear_aparture_diameter',
    optical_height = 'info.parameters.optical_height',
    platform_size = 'info.parameters.platform_size',
    platform_width = 'info.parameters.platform_width',
    platform_height = 'info.parameters.platform_height',
    compatible_lens_tubes = 'info.parameters.compatible_lens_tubes',
    type_of_movement = 'info.parameters.type_of_movement',
    type_of_drive = 'info.parameters.type_of_drive',
    key_feature = 'info.parameters.key_feature',
    lock_nuts = 'info.parameters.lock_nuts',
    actuator_orientation = 'info.parameters.actuator_orientation',
    resolution_tilt = 'info.parameters.resolution_tilt',
    platform_thread = 'info.parameters.platform_thread',
    thread_type = 'info.parameters.thread_type',
    adjustment_range_tilt = 'info.parameters.adjustment_range_tilt',
    adjustment_range_rotation = 'info.parameters.adjustment_range_rotation',
    resolution_rotation = 'info.parameters.resolution_rotation',
    adjuster_thread = 'info.parameters.adjuster_thread',
    adjuster_screw_thread = 'info.parameters.adjuster_screw_thread',
    fine_adjustment_range_rotation = 'info.parameters.fine_adjustment_range_rotation',
    fine_tilt_angular_range = 'info.parameters.fine_tilt_angular_range',
    angular_range_piezo = 'info.parameters.angular_range_piezo',
    angular_range = 'info.parameters.angular_range',
    adjustment_per_revolution = 'info.parameters.adjustment_per_revolution',
    adjustment_per_revolution_piezo = 'info.parameters.adjustment_per_revolution_piezo',
    pointing_stability = 'info.parameters.pointing_stability',
    screw_diameter_and_pitch = 'info.parameters.screw_diameter_and_pitch',
    adjustment_screw_pitch = 'info.parameters.adjustment_screw_pitch',
    tapped_holes = 'info.parameters.tapped_holes',
    post_mounting_screw = 'info.parameters.post_mounting_screw',
    though_hole_dia = 'info.parameters.though_hole_dia',
    mounting_holes = 'info.parameters.mounting_holes',
    mounting_thread = 'info.parameters.mounting_thread',
    sensitivity_arc = 'info.parameters.sensitivity_arc',
    vacuum_compatibility_torr = 'info.parameters.vacuum_compatibility_torr',
    weight = 'info.parameters.weight',
    weblink = 'info.parameters.weblink',
    step_file = 'info.parameters.step_file',
    optic_size_accomodated = 'info.parameters.optic_size_accomodated',
    optic_size_accomodated_min = 'info.parameters.optic_size_accomodated_min',
    optic_size_accomodated_max = 'info.parameters.optic_size_accomodated_max',
    optic_diameter = 'info.parameters.optic_diameter',
    optic_diameter_min = 'info.parameters.optic_diameter_min',
    optic_diameter_max = 'info.parameters.optic_diameter_max',
    weblink_status = 'info.parameters.weblink_status',
    maximum_optic_thickness = 'info.parameters.maximum_optic_thickness',
    minimum_optic_thickness = 'info.parameters.minimum_optic_thickness',
    optic_size_max = 'info.parameters.optic_size_max',
    thickness = 'info.parameters.thickness',
    a = 'info.parameters.a',
    b = 'info.parameters.b',
    number_of_optics = 'info.parameters.number_of_optics',
    compatible_lens_tubes_od = 'info.parameters.compatible_lens_tubes_od',
    inner_diameter = 'info.parameters.inner_diameter',
    od = 'info.parameters.od',
    outer_diameter = 'info.parameters.outer_diameter',
    clear_aperture = 'info.parameters.clear_aperture',
    max_aperture = 'info.parameters.max_aperture',
    min_aperture = 'info.parameters.min_aperture',
    iris_diaphragm = 'info.parameters.iris_diaphragm',
    iris_diaphragm_min = 'info.parameters.iris_diaphragm_min',
    iris_diaphragm_max = 'info.parameters.iris_diaphragm_max',
    number_of_leaves = 'info.parameters.number_of_leaves',
    number_of_blades = 'info.parameters.number_of_blades',
    travel_x = 'info.parameters.travel_x',
    travel_y = 'info.parameters.travel_y',
    travel_z = 'info.parameters.travel_z',
    number_of_axes = 'info.parameters.number_of_axes',
    drive_type = 'info.parameters.drive_type',
    drive_mechanism_per_revolution = 'info.parameters.drive_mechanism_per_revolution',
    thread = 'info.parameters.thread',
    sensitivity_um = 'info.parameters.sensitivity_um',
    vernier = 'info.parameters.vernier',
    air_tank_capacity = 'info.parameters.air_tank_capacity',
    air_pressure = 'info.parameters.air_pressure',
    free_air_delivery = 'info.parameters.free_air_delivery',
    vacuum_compatibility_tor = 'info.parameters.vacuum_compatibility_tor',
}


export class OptomechanicFiltersHandler extends FiltersHandler {

    private mSpecificFilters: iHash<Object> = {};
    public static SHAPES_NAMES: Array<string> = ['Circular', 'Rectangular', 'Volume'];
    private mCurrentPart: Part;


    //__________________________________________________________________________________________
    constructor(pMenu: MenuListAbstract<iOptomechanicVO>, pFilterTemplate: HTMLElement,
        pFilterContainer: HTMLElement) {
        super(pMenu, pFilterTemplate, pFilterContainer);
    }
    //__________________________________________________________________________________________
    protected _onCategoryChange(pType: any, pToChange: boolean) {
        super._onCategoryChange(pType, pToChange, 'Opto-Mechanics');
    }
    //__________________________________________________________________________________________
    protected _updateFiltersUnit(pUnit: eUnitType) {
        for (let filter in this.mFilterComponents) {
            this.mFilterComponents[filter].updateUnit(pUnit);
        }
    }
    //__________________________________________________________________________________________
    protected async _initFilters(): Promise<void> {
        this._createFilter(this._addTypeFilter());
        this._createFilter(this._addSubtypeFilter());

        this._createFilter(this._addBrandsFilter());
        this._createFilter(this._addMeterialFilter());

        this._createFilter(this._addTypeOfOpticsFilter());
        this._createFilter(this._addTypeOfMovementFilter());
        this._createFilter(this._addKeyFeatures());

        this._addOtherFilters()


        this._addMoreFilters();
        this.mFiltersAdded = true;
    }

    //__________________________________________________________________________________________
    private _addTypeFilter() {

        let aData: iRadioBtnsData = {
            defaultNameIndex: 0,
            data: new Array<iOneRadioBtnData>()
        };

        let aNames = Object.values(eOptomechanicTypeNames);
        for (let i = 0; i < aNames.length; i++) {
            const aName = aNames[i];
            aData.data.push({ name: aName });
        }

        return {
            filterType: eFilterType.RADIO_BTNS,
            data: aData,
            isVisible: true,
            name: eOMPFilterName.type,
            params: {
                showOnStart: true,
                title: "Types",
                hideResetBtn: false,
                onChange: (pType: eOptomechanicTypeNames, pToChange: boolean) =>
                    this._onTypeChange(pType, pToChange),
            }
        }

    }

    //__________________________________________________________________________________________
    private updateFilter(pData: Array<string>, pKey: eOMPFilterName) {
        if (pData == null) {
            return;
        }

        let aCheckboxData = new Array<iOneCheckBoxData>();
        for (let i = 0; i < pData.length; i++) {
            aCheckboxData.push({
                name: pData[i],
                isEnabled: true
            });
        }

        let aMultipleCheckBoxParams: iMultipleCheckboxData = {
            checkboxesData: aCheckboxData,
            selectAllCheckbox: ((pData.length > 1)),
        }

        this.mFilterComponents[pKey].updateFilterData(aMultipleCheckBoxParams,
            false);
    }
    //__________________________________________________________________________________________
    private _resetFilters() {
        this._onSubtypeChange(null, false);
        this._onTypeOfMovementChange(null, false);
        this._onKeyFeatureChange(null, false);
        let aMultipleCheckBoxParams: iMultipleCheckboxData = {
            checkboxesData: [],
            selectAllCheckbox: false,
        }

        this.mFilterComponents[eOMPFilterName.sub_type].updateFilterData(aMultipleCheckBoxParams,
            false);
        this.mFilterComponents[eOMPFilterName.key_feature].updateFilterData(aMultipleCheckBoxParams,
            false);
        this.mFilterComponents[eOMPFilterName.type_of_movement].updateFilterData(aMultipleCheckBoxParams,
            false);
    }
    //__________________________________________________________________________________________
    private _onTypeChange(pType: eOptomechanicTypeNames, pToChange: boolean = true) {
        let aType = (pType != eOptomechanicTypeNames.ALL) ? pType : null;
        let aIsTypeChosen = (aType != null);
        if (aIsTypeChosen) {
            let aItem = OptomechanicContext.OPTOMECHANICS_DATA.find((item) => (item.type == pType))

            const aSubtypes = aItem.subtypes.sort();
            const aTypeOfMovement = aItem.type_of_movement.sort();
            const aKeyFeature = aItem.key_feature.sort();
            this.updateFilter(aSubtypes, eOMPFilterName.sub_type);
            this.updateFilter(aTypeOfMovement, eOMPFilterName.type_of_movement);
            this.updateFilter(aKeyFeature, eOMPFilterName.key_feature);
        } else {
            this._resetFilters();
        }

        this.mFilterComponents[eOMPFilterName.sub_type].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.type_of_movement].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.key_feature].setVisibility(false);

        OptomechanicContext.getAccessoriesSliderFilters().forEach(filter => this.mFilterComponents[filter.path].setVisibility(false))
        OptomechanicContext.getMountsSliderFilters().forEach(filter => this.mFilterComponents[filter.path].setVisibility(false))

        this.mFilterComponents[eOMPFilterName.actuator_orientation].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.thread_type].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.adjuster_thread].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.tapped_holes].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.fine_tilt_angular_range].setVisibility(false);

        this.mFilterComponents[eOMPFilterName.platform_thread].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.thread].setVisibility(false);
        this.mFilterComponents[eOMPFilterName.drive_type].setVisibility(false);

        switch (pType) {
            case eOptomechanicTypeNames.OPTICS_MOUNT:

                OptomechanicContext.getMountsSliderFilters().forEach(filter => this.mFilterComponents[filter.path].setVisibility(true))
                OptomechanicContext.getMountsMainSliderFilters().forEach(filter => this.mFilterComponents[filter.path].setVisibility(true))
                this.mFilterComponents[eOMPFilterName.sub_type].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.type_of_movement].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.key_feature].setVisibility(true);

                this.mFilterComponents[eOMPFilterName.actuator_orientation].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.platform_thread].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.thread_type].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.adjuster_thread].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.tapped_holes].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.fine_tilt_angular_range].setVisibility(true);

                break;
            case eOptomechanicTypeNames.MOUNT_ACCESSORIES:
                OptomechanicContext.getAccessoriesSliderFilters().forEach(filter => this.mFilterComponents[filter.path].setVisibility(true))
                OptomechanicContext.getMountsMainSliderFilters().forEach(filter => this.mFilterComponents[filter.path].setVisibility(true))
                this.mFilterComponents[eOMPFilterName.sub_type].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.type_of_movement].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.key_feature].setVisibility(true);

                this.mFilterComponents[eOMPFilterName.thread].setVisibility(true);
                this.mFilterComponents[eOMPFilterName.drive_type].setVisibility(true);

                break;
        }

        this._onIngredientChange(eOMPFilterName.type,
            aType, eFilterType.RADIO_BTNS, pToChange);
    }
    //__________________________________________________________________________________________
    private _addOtherFilters() {
        let aCoated = OptomechanicContext.getCoatings()
        let aLensTubes = OptomechanicContext.getCompatibleLensTypes()

        let aOrientation = Object.values(eOrientationTypes)
        let aDriveType = Object.values(eDriveType)
        let aThread = THREAD
        let aThreadType = [eMountingData._1,
        eMountingData._2,
        eMountingData._7]
        let aAdjusterType = [
            '3/16in',
            'M2.5 x 0.20',
            eMountingData._6,
            '9/16in',
            eMountingData._5]
        let aTappedHoles = [eMountingData._2,
        eMountingData._3,
        eMountingData._4,
            '6-32',
            '1/4in-20',
        eMountingData._5]
        let aPlatformThread = ['8-32', 'M4 THD', '1/4-20 (M6) CLR']
        let aPostMounting = Object.values(eMountingData)

        this._createFilter(this._addCheckBoxFilter({
            items: aCoated,
            title: "Finish coated",
            type: eOMPFilterName.finish_coated,
            isVisible: true


        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aLensTubes,
            title: "Compatible Lens Tubes (SM)",
            type: eOMPFilterName.compatible_lens_tubes,
            isVisible: true
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aOrientation,
            title: "Actuator Orientation",
            type: eOMPFilterName.actuator_orientation,
            isVisible: false

        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aThreadType,
            title: "Thread Type",
            type: eOMPFilterName.thread_type,
            isVisible: false
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aAdjusterType,
            title: "Adjuster Thread (mm)",
            type: eOMPFilterName.adjuster_thread,
            isVisible: false
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aTappedHoles,
            title: "Tapped holes",
            type: eOMPFilterName.tapped_holes,
            isVisible: false
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aPostMounting,
            title: "Post Mounting (screw)s",
            type: eOMPFilterName.post_mounting_screw,
            isVisible: true
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aDriveType,
            title: "Drive Type",
            type: eOMPFilterName.drive_type,
            isVisible: true
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aThread,
            title: "Thread",
            type: eOMPFilterName.thread,
            isVisible: true
        }))
        this._createFilter(this._addCheckBoxFilter({
            items: aPlatformThread,
            title: "Platform Thread",
            type: eOMPFilterName.platform_thread,
            isVisible: true
        }))
    }
    //__________________________________________________________________________________________
    private _addKeyFeatures() {
        const aKeyFeatures = OptomechanicContext.getKeyFeatures()

        let aFilter = this._addCheckBoxFilter({
            items: aKeyFeatures,
            title: "Key Features",
            type: eOMPFilterName.key_feature,
            isVisible: true
        });

        return aFilter;
    }
    //__________________________________________________________________________________________
    private _addTypeOfOpticsFilter() {
        const aOpticsType = OptomechanicContext.getOpticShapes()

        let aFilter = this._addCheckBoxFilter({
            items: aOpticsType,
            title: "Type of Optics",
            type: eOMPFilterName.optical_shape,
            isVisible: true
        });

        return aFilter;
    }
    //__________________________________________________________________________________________
    private _addTypeOfMovementFilter() {
        const aTypesOfMovement = OptomechanicContext.getMovementTypes()

        let aFilter = this._addCheckBoxFilter({
            items: aTypesOfMovement,
            title: "Type of Movements",
            type: eOMPFilterName.type_of_movement,
            isVisible: false
        });

        return aFilter;
    }
    //__________________________________________________________________________________________
    private _addBrandsFilter() {
        const aBrands = OptomechanicContext.getAllBrands()

        let aFilter = this._addCheckBoxFilter({
            items: aBrands,
            title: "Brands",
            type: eOMPFilterName.brand,
            isVisible: true
        });

        return aFilter;
    }
    //__________________________________________________________________________________________
    protected _adjustFilters() {
        this.mSpecificFilters = {};
        if (null == this.mCurrentPart) {
            return;
        }

        // let aOpticsHolderPart = this.mCurrentPart.getAxes().find(axis => axis.isForOptics);
        // if (null == aOpticsHolderPart) {
        //     return;
        // }

        // let aCircular = aOpticsHolderPart?.radius
        // let aRect = aOpticsHolderPart?.length
        // let aShape = null

        // if (aOpticsHolderPart.shape != null) {
        //     aShape = aOpticsHolderPart.shape
        // } else {
        //     if (aCircular != null) {
        //         aShape = eBaseShape.CIRCULAR
        //     } else if (aRect != null) {
        //         aShape = eBaseShape.RECTANGULAR
        //     } else {
        //         aShape = eBaseShape.VOLUME
        //     }
        // }

    }

    //__________________________________________________________________________________________
    protected _onPermissionChange(pData: Array<eDataPermission>, pToChange: boolean) {

        this._onIngredientChange(eOMPFilterName.permission, pData, eFilterType.RADIO_BTNS, pToChange);
    }
    //__________________________________________________________________________________________
    private _addMeterialFilter() {
        const aMaterials = OptomechanicContext.getMaterails()


        let aFilter = this._addCheckBoxFilter({
            items: aMaterials,
            title: "Material",
            type: eOMPFilterName.material,
            isVisible: true
        });

        return aFilter;
    }
    //__________________________________________________________________________________________
    private _addCheckBoxFilter(pData: {
        title: string,
        type: eOMPFilterName, items: Array<string>,
        isVisible: boolean
    }) {
        let aOnChange = (pItem: string, pToChange: boolean) =>
            this._onCheckBoxDataChanged(pItem, pData.type, pToChange);

        let aCheckboxesData = new Array<iOneCheckBoxData>();
        for (let i = 0; i < pData.items.length; i++) {
            aCheckboxesData.push({ name: pData.items[i] });
        }
        return {
            filterType: eFilterType.MULTIPLE_CHECKBOX,
            params: {
                onChange: aOnChange,
                title: pData.title,
                hideResetBtn: false
            },
            name: pData.type,
            data: {
                checkboxesData: aCheckboxesData,
                selectAllCheckbox: true
            },
            isVisible: pData.isVisible
        }
    }
    //__________________________________________________________________________________________
    private _addSubtypeFilter() {
        let aSubtypes = OptomechanicContext.getSubtypes()

        let aFilter = this._addCheckBoxFilter({
            items: aSubtypes,
            title: "Sub-Type",
            type: eOMPFilterName.sub_type,
            isVisible: false
        });

        return aFilter;
    }

    //__________________________________________________________________________________________
    private _onCheckBoxDataChanged(pItem: string, pType: eOMPFilterName, pToChange: boolean = true) {
        let aBrand = (pItem != eOpticsTypeNames.ALL) ? pItem : null;
        this._onIngredientChange(pType,
            aBrand,
            eFilterType.MULTIPLE_CHECKBOX,
            pToChange);
    }
    //__________________________________________________________________________________________
    protected _onSubtypeChange(pData: Array<string>, pToUpdate: boolean) {
        this._onIngredientChange(eOMPFilterName.sub_type, pData, eFilterType.MULTIPLE_CHECKBOX,
            pToUpdate);
    }

    //__________________________________________________________________________________________
    // protected _onCompatibleLensTubesChange(pData: Array<string>, pToUpdate: boolean) {
    //     this._onIngredientChange(eOMPFilterName.compatible_lens_tubes, pData, eFilterType.MULTIPLE_CHECKBOX,
    //         pToUpdate);
    // }
    //__________________________________________________________________________________________
    protected _onTypeOfMovementChange(pData: Array<string>, pToUpdate: boolean) {
        this._onIngredientChange(eOMPFilterName.type_of_movement, pData, eFilterType.MULTIPLE_CHECKBOX,
            pToUpdate);
    }
    //__________________________________________________________________________________________
    protected _onKeyFeatureChange(pData: Array<string>, pToUpdate: boolean) {
        this._onIngredientChange(eOMPFilterName.key_feature, pData, eFilterType.MULTIPLE_CHECKBOX,
            pToUpdate);
    }
    //__________________________________________________________________________________________
    public async reload(pPart?: Part) {
        this.mCurrentPart = pPart;

        await super.reload();
    }
    //__________________________________________________________________________________________
    private _addMoreFilters() {

        let aAccessoriesSliderFilters = OptomechanicContext.getAccessoriesSliderFilters()
        let aMountsSliderFilters = OptomechanicContext.getMountsSliderFilters()
        let aMainSliderFilters = OptomechanicContext.getMountsMainSliderFilters()

        for (let aFilter of aAccessoriesSliderFilters) {
            let aSliderData: iBootstrapSliderOptions = {
                min: aFilter.min,
                max: aFilter.max,
                value: [aFilter.min, aFilter.max],
                enabled: true,
                step: (aFilter as any).step ? (aFilter as any).step : 1

            };
            this._createFilter({
                data: {
                    data: aSliderData,
                    filterHandler: this,
                    unit: aFilter?.unit ? aFilter?.unit : 'mm',
                },
                filterType: eFilterType.RANGE,
                isVisible: false,
                name: aFilter.path,
                params: {
                    hideResetBtn: false,
                    title: aFilter.name,
                    onChange: (pData: any, pToChange: boolean) =>
                        this._onIngredientChange(aFilter.path, pData,
                            eFilterType.RANGE, pToChange)
                }
            })
        }
        for (let aFilter of aMountsSliderFilters) {
            let aSliderData: iBootstrapSliderOptions = {
                min: aFilter.min,
                max: aFilter.max,
                value: [aFilter.min, aFilter.max],
                enabled: true,
                step: (aFilter as any).step ? (aFilter as any).step : 1
            };
            this._createFilter({
                data: {
                    data: aSliderData,
                    filterHandler: this,
                    unit: aFilter?.unit ? aFilter?.unit : 'mm',
                    isCanChangeUnit: aFilter?.unit === undefined ? true : false
                },
                filterType: eFilterType.RANGE,
                isVisible: false,
                name: aFilter.path,
                params: {
                    hideResetBtn: false,
                    title: aFilter.name,
                    onChange: (pData: any, pToChange: boolean) =>
                        this._onIngredientChange(aFilter.path, pData,
                            eFilterType.RANGE, pToChange)
                }
            })
        }
        for (let aFilter of aMainSliderFilters) {
            let aSliderData: iBootstrapSliderOptions = {
                min: aFilter.min,
                max: aFilter.max,
                value: [aFilter.min, aFilter.max],
                enabled: true,
                step: (aFilter as any).step ? (aFilter as any).step : 1
            };
            this._createFilter({
                data: {
                    data: aSliderData,
                    filterHandler: this,
                    unit: aFilter?.unit ? aFilter?.unit : 'mm',
                    isCanChangeUnit: aFilter?.unit === undefined ? true : false
                },
                filterType: eFilterType.RANGE,
                isVisible: true,
                name: aFilter.path,
                params: {
                    hideResetBtn: false,
                    title: aFilter.name,
                    onChange: (pData: any, pToChange: boolean) =>
                        this._onIngredientChange(aFilter.path, pData,
                            eFilterType.RANGE, pToChange)
                }
            })
        }
    }
    //__________________________________________________________________________________________
    public getFilters() {
        let aFilters = Object.assign({}, this.mFilters, this.mSpecificFilters);
        return aFilters;
    }
    //__________________________________________________________________________________________
}
