import { iHash, iMaterialMetaDataVO, iMinMax, iScatteringVO } from "../../../../_context/_interfaces/Interfaces";
import { iOpticsVO, iOpticsVOGeometry, iSurfaceDeformation } from "../../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../../parts/Part";
import { iFace } from "../../../../parts/PartInterfaces";
import { eSmBaseShapeKind, iSimulationReflectionItem } from "../../../../simulation/SimulationContext";

export interface iUploadOpticsParamsNew {
    originalOpticsVO?: iOpticsVO,
    opticsVO: iOpticsVO,
    face?: iFace,
    part?: Part;
    surfacesPart?: Part;
    formType: eFormType;
    openMenuPoint?: { clientX: number, clientY: number };
    onFinishSave?: (pNewPart: Part) => Promise<void>;
    groupEdit?: boolean;
}

export interface iApertureReturnData {
    geo: iOpticsVOGeometry;
    phase_mask: { url: string, file: File, name: string };
    transmittance_mask: { url: string, file: File, name: string };
}

export interface iSurfaceShape {
    // optic
    opticsVO: iOpticsVO;
    face: iFace;

    // light source
    normalization_radius: number,
    deformations: iSurfaceDeformation[],
    shape: eSmBaseShapeKind,
    width: number;
    height: number


};

export interface iFaceBasicData {
    face_name: string
}

export interface iSurfaceSectionsData {
    simulationData: iHash<iSimulationReflectionItem>;
    basicData: iHash<iFaceBasicData>;
    scattering: iHash<iScatteringVO>;
    deformation: iHash<iSurfaceDeformation>
}
/**
 * being used for CSS purposes also
 */
export enum eFormType {
    UPLOAD = "UPLOAD",
    EDIT = "EDIT"
}

export interface iuoSurfaceCallbacks {
    onclick: (pFace: iFace) => void
    mouseleave: (pFaceName: string | undefined) => void;
    mouseenter: (pFaceName: string | undefined) => void;
}
export interface iUOMaterialProps {
    nDisabledState: boolean;
    /**
     * @description whether to enable input elements
     */
    enableInputElements: boolean;
    /**
     * @description whether the form has a listeners to change event of the input of all material fields
     */
    hasParametersListeners: boolean;
    /**
     * @description whether the form has a listener to change event of the select
     */
    isHaveMaterialEquationTypeListener: boolean;
    /**
     * @description whether the form has a listener to change event of the select
     */
    isHaveNListener: boolean;
    /**
     * @description the state of disable of the custom option in select element
     */
    customOptionDisableState: boolean;
    /**
     * @description whether the select has a custom option
     */
    hasCustomOption: boolean;
}

export enum eCoatedType {
    PRESET = "PRESET",
    CUSTOM = "CUSTOM",
}

export enum eCoatedState {
    UNCOATED = "UNCOATED",
    COATED = "COATED",
    DEFAULT = "DEFAULT",
}
export interface iuoMaterialData {
    updateFocusesFunction: Function;
    materials: Array<iMaterialMetaDataVO>;
    materialProps: iUOMaterialProps;
}

export interface iMaterialParam<T = any> {
    name: string;
    path: string;
    unit?: string;
    range?: iMinMax<number>;
    rangeFunc?: (pVal: number, pData?: T) => number;
    value?: number;
}

export interface iuoMaterial {
    materialID: string;
    wavelenght: number;
}


export class UploadOpticsContext {
}
