import { eAxisType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { LabelHandler } from "../../_context/LabelHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import {
    iClientPoint, iUploadSetupData, iHash, iSideBarData,
    iChooseOpticsEvent, iSetupDetails, iChooseOptomechanicEvent
} from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { absPartsManager, eObjectType, iBOMData } from "../absPartsManager";
import { PartsCatalog, iLoadFile } from "./PartsCatalog";
import { eBaseShape, } from "../../_context/OpticsContext";
import { PartsImporter } from "../../setups/opt/PartsImporter";
import { EventManager } from "../../../oc/events/EventManager";
import { MovementBehavior } from "../behaviors/MovementBehavior";
import { SurfaceContext } from "../optics/SurfaceContext";
import { AnalysisContext, iAnalysisReloadData } from "../../ui/analysis/AnalysisContext";
import { GridManager } from "../../scene/GridManager";
import { CageBehavior } from "../behaviors/CageBehavior";
import { SceneContext } from "../../scene/SceneContext";
import { eClickMode } from "../../ui/_globals/PartsEventsHandler";
import { Group } from "../../ui/forms/Group";
import { OptixPartUtils } from "../../_utils/OptixPartUtils";
import { PartsDataLoader } from "../../data/data_loader/PartsDataLoader";
import { PartsFactory } from "./PartsFactory";
import { EventBase } from "../../../oc/events/EventBase";
import { iSideBarActivePartData } from "../../ui/home/SideBar";
import { Raycaster, Vector3, Mesh, LineSegments, Vector2, Intersection, Euler, Points, Box3 } from "three";
import { eLabelType } from "../../scene/CSS2DLabel";
import { Part } from "../Part";
import { iPart, iPartOptions, iAxis, ePartType } from "../PartInterfaces";
import { OpticsDataLoader } from "../../data/data_loader/OpticsDataLoader";
import { ShapesUtils } from "../../_utils/ShapesUtils";
import { BOMGenerator } from "../../bom/BOMGenerator";
import { Op3dScene } from "../../scene/Op3dScene";

export interface iBOMItem {
    id: string,
    name: string,
    catalogNumber: string;
    count: number;
}

export interface iBOMExportData extends iBOMData {
    name: string;
    setupDetails: iSetupDetails;
}

export enum eMeshType {
    FACE = 'FACE',
    VERTEX = 'VERTEX',
    EDGE = 'EDGE'
}

export class PartsManager extends absPartsManager {

    /**
     * @description current added frm side bar part- potential to be added into the parts-container 
     */
    private mSideBarPart: Part | undefined;
    private mIsFromSideBar: boolean = false;
    private mIsClickSpace: boolean = false;
    private mRayCaster = new Raycaster();
    private mPartMoved: iClientPoint | undefined;
    private mPrevState: iUploadSetupData | undefined;
    private mOnMouseMove: iHash<Function> = {};
    mIsThrottled: boolean;

    constructor() {
        super();
    }
    //__________________________________________________________________________________________
    public getRawBOMData(): iBOMData {
        let aBomData: iBOMData = {
            optics: {},
            optomechanics: {}
        }

        try {
            for (let i = 0; i < this.mParts.length; i++) {
                BOMGenerator.addToBOM(this.mParts[i], aBomData)
            }

        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e, "Failed to generate bom")
        }


        return aBomData;
    }
    //__________________________________________________________________________________________
    public setOnMouseMoveCallback(pKey: string, pFunc: Function) {
        this.mOnMouseMove[pKey] = pFunc;
    }
    //__________________________________________________________________________________________
    public updatePartsByNumberID(pNumberID: string, pInternalId: string) {
        for (let i = this.mParts.length - 1; i >= 0; i--) {
            if (this.mParts[i].opticsNumberID === pNumberID && this.mParts[i].internalID !== pInternalId) {
                let aOpticsVO = OpticsDataLoader.instance.getFromCache(pNumberID)
                this.chooseOptics({
                    opticsVO: aOpticsVO,
                    part: this.mParts[i]
                })
            }
        }
    }
    //______________________________________________________________________________________________
    public static get analysisList() {
        let aAnalysesData: Array<iAnalysisReloadData> = [];

        const aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            const aPart = aParts[i];

            if (aPart.analysisOptions != null && aPart.analysisOptions.length > 0) {

                for (let j = 0; j < aPart.analysisOptions.length; j++) {
                    aPart.analysisOptions.map(surface => {

                        let aFace = aPart.getFaceByID(surface.faceId);
                        let aSize = ShapesUtils.getShapeSize(aFace.data.shapeData);

                        surface.fast.map(fast => aAnalysesData.push({
                            faceId: surface.faceId,
                            analysis: fast,
                            size: aSize
                        }));

                        surface.advanced.map(adv => aAnalysesData.push({
                            faceId: surface.faceId,
                            analysis: adv,
                            size: aSize
                        }));
                    });
                }
            }
        }

        return aAnalysesData;
    }

    //__________________________________________________________________________________________
    public getAllWavelengthsInSystem() {
        let aDistinctWavelengths = Array<number>();
        for (let i = 0; i < this.mParts.length; i++) {
            const aLaserBehavior = this.mParts[i].getBehavior("laserBehavior");
            if (aLaserBehavior !== undefined) {
                aLaserBehavior.laserData.lightSource.wavelengthData.map(item => (aDistinctWavelengths.indexOf(item.wl) == -1) &&
                    aDistinctWavelengths.push(item.wl));
            }
        }

        return aDistinctWavelengths;
    }
    //__________________________________________________________________________________________
    public async deleteAllOpticsByNumberID(pNumberID: string) {
        for (let i = this.mParts.length - 1; i >= 0; i--) {
            if (this.mParts[i].opticsNumberID === pNumberID) {
                await this.deletePart(this.mParts[i])
            }
        }
    }
    //__________________________________________________________________________________________
    public unAddPart() {
        if (SceneContext.CHOOSE_MODE.mode === eClickMode.MEASUREMENT) {
            return
        }
        Op3dContext.SCENE_HISTORY.deleteLastHistory()

        if (null == this.mSideBarPart) {
            return;
        }

        this.deletePart(this.mSideBarPart)
        this.mSideBarPart = undefined;
        this.mIsFromSideBar = false;
        this.updateAnalysisOptions(true);
    }
    //__________________________________________________________________________________________
    public async duplicatePart(pOptions: { part: Part, isSingleDuplicate: boolean, addToHistory: boolean }) {

        if (pOptions.addToHistory) {
            Op3dContext.SCENE_HISTORY.addToHistory();
        }
        let aJSONData = pOptions.part.exportToJSONObject();
        let aCloneJSON = DataUtils.getObjectCopy(aJSONData);

        let aNewPart = await new PartsImporter().initOnePart(aCloneJSON, false);
        let aNewPos = this._getNewPartPosition(aNewPart);
        /*
        *   moved clearREF before setOnPosition because of group duplicating
        */
        aNewPart.clearRef();
        aNewPart.setOnPosition(aNewPos);

        aNewPart.removeAllAnalysisOptions();

        this._handleDetector(pOptions.part, aNewPart);
        await this._handleMountWithOptics(pOptions.part, aNewPart);
        await this._handleCageParts(pOptions.part, aNewPart);
        this._handleDuplicateLabel(aNewPart);

        if (pOptions.isSingleDuplicate === true) {
            Op3dContext.PART_INFO.close();

            aNewPart.onSelect();
            this.updatePartsList();
            this.updateLinkedParts();
            EventManager.dispatchEvent(EventsContext.PART_ADDED, this);
            Op3dContext.SCENE_HISTORY.saveScene();
        }


        Op3dContext.PARTS_MANAGER.updateAnalysisOptions(false);

        return aNewPart;
    }
    //__________________________________________________________________________________________
    private async _handleMountWithOptics(pPart: Part, pNewPart: Part) {
        if (null == pPart.linkedParts) {
            return;
        }

        let aOptics = pPart.linkedParts.find((part) => (eAxisType.OPTICS === part.refCS.cs.type));
        if (null == aOptics) {
            return;
        }

        let aJSONData = aOptics.exportToJSONObject();
        let aJSON = DataUtils.getObjectCopy(aJSONData);
        let aNewOptics = await new PartsImporter().initOnePart(aJSON, false);

        let aNewOpticsAxis = pNewPart.getAxes().find(axis => (eAxisType.OPTICS === axis.type));

        let aOpticsFaceName = aOptics.workingLCS.object3D.parent.name;
        let aLCS = aNewOptics.getAxes().find((axis) => //{ object3D: { parent: { name: any; }; }; }
            axis.object3D.parent.name == aOpticsFaceName);
        aNewOptics.setWorkingCS(aLCS, true);
        aNewOptics.setRefrence({
            cs: aNewOpticsAxis,
            refPart: pNewPart
        });

        let aOpticsPos = MovementBehavior.distToRef(aOptics);
        MovementBehavior.transformPart(aNewOptics, aOpticsPos,
            aOptics.refRotation);
        aNewOpticsAxis.linkedOpticsId = aOptics.refCS.cs.linkedOpticsId;
    }
    //__________________________________________________________________________________________
    private _handleDetector(pPart: Part, pNewPart: Part) {
        let aOpticsHolder = pPart.getSubpartByName(Strings.OPTICS_HOLDER);
        if ((null == aOpticsHolder) || (null == aOpticsHolder.object3D)
            || (true != aOpticsHolder.object3D.userData.isDetector)) {
            return;
        }

        let aFront = pNewPart.getFaces().find(face => face.name == SurfaceContext.FRONT);
        pNewPart.addAnalysisOption({
            advanced: [],
            fast: [AnalysisContext.getDefaultAnalysis()],
            faceId: aFront.internal_id
        });
    }
    //__________________________________________________________________________________________
    private _handleDuplicateLabel(pNewPart: Part) {
        let aLabel = pNewPart.getLabel();
        if (null == aLabel) {
            return;
        }
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pNewPart.id);
        aLabel.index = LabelHandler.getCurrentIndex(pNewPart, aPartVO);
        pNewPart.setPartLabel('Copy of ' + aLabel.label);
    }
    //__________________________________________________________________________________________
    private _getNewPartPosition(pNewPart: Part, pIndex = 0) {
        let aNewPos = pNewPart.visibleObj.position.clone();
        let aPartSizeDelta = Op3dUtils.getNetoItemSize(pNewPart.visibleObj);
        aPartSizeDelta.x = 0;
        aPartSizeDelta.y = 0;

        //when adding to scene multiple parts we check indexes of parts and add them on scene depending on cell size one by one
        aPartSizeDelta.z += (GridManager.CELL_SIZE / 2) * (pIndex + 1);

        aPartSizeDelta.applyEuler(pNewPart.visibleObj.rotation);
        aNewPos.add(aPartSizeDelta);
        return aNewPos;
    }
    //__________________________________________________________________________________________
    private async _handleCageParts(pPartToDuplicate: Part, pNewPart: Part) {
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPartToDuplicate.id)
        if (null == aPartVO || null == aPartVO.hasCage || aPartVO.hasCage == false) {
            return;
        }

        if (true == CageBehavior.isConnectedToCage(pPartToDuplicate)) {
            Op3dContext.PARTS_MANAGER.checkConnectionWithParts(pNewPart);
            return;
        }

        if (null == pPartToDuplicate.linkedParts) {
            return;
        }

        let aCageContainerName = CageBehavior.CAGE_CONTAINER_NAME;
        let aCageAxes = pNewPart.getSubpartByName(aCageContainerName).axes[0];

        for (let i = 0; i < pPartToDuplicate.linkedParts.length; i++) {
            let aPart = pPartToDuplicate.linkedParts[i];
            if (true == aPart.refCS.isCage) {
                let aJSONData = aPart.exportToJSONObject();
                let aJSON = DataUtils.getObjectCopy(aJSONData);
                let aNewPart = await new PartsImporter().initOnePart(aJSON, false);

                let aCageContainerName = CageBehavior.CAGE_CONTAINER_NAME;
                let aCagePart = aNewPart.getSubpartByName(aCageContainerName);
                aNewPart.setWorkingCS(aCagePart.axes[0], false);

                let aPosOnCage = MovementBehavior.distToRef(aPart).z;
                let aPos = new Vector3(0, 0, aPosOnCage);
                let aRot = aPart.refRotation.clone();

                aNewPart.setRefrence({
                    cs: aCageAxes,
                    refPart: pNewPart,
                    isCage: true
                });

                MovementBehavior.transformPart(aNewPart, aPos, aRot);
            }
        }
    }
    //__________________________________________________________________________________________
    public async duplicateSeleced() {
        this.duplicatePart({ part: this.mSelectedPart, isSingleDuplicate: true, addToHistory: true });
    }
    //__________________________________________________________________________________________
    public isToShowEdges(pIsToShow: boolean) {
        this.getSceneObjectsByType(eObjectType.OPTOMECHANICS_EDGE).forEach(part => part.visible = pIsToShow)
    }
    //__________________________________________________________________________________________
    public deleteSelected(pEvent?: Event) {
        if (null == this.mSelectedPart) {
            return;
        }

        if (SceneContext.CHOOSE_MODE.mode !== eClickMode.BASE) {
            return;
        }
        if ((null != this.mSelectedPart) &&
            (ePartType.GROUP === this.mSelectedPart.partOptions.type)) {
            return;
        }

        if ((null != pEvent) && !(pEvent.target instanceof HTMLBodyElement)) {
            return;
        }

        Op3dContext.SCENE_HISTORY.addToHistory();

        this.deletePart(this.mSelectedPart);
        this.setSelectedPart(null);

        //* SAVING
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private async deleteLinkedOptics(pPart: Part) {
        let aAxis = pPart.getAxes().find(axis => (eAxisType.OPTICS === axis.type));
        if (null == aAxis) {
            return;
        }


        let aCurrentOptics: Part;
        if (pPart?.linkedParts != null) {
            for (let linkedPart of pPart.linkedParts) {
                if (linkedPart.refCS.cs.linkedOpticsId == aAxis.linkedOpticsId) {
                    aCurrentOptics = linkedPart;
                }
            }

            await Op3dContext.PARTS_MANAGER.deletePart(aCurrentOptics);
        }
    }
    //__________________________________________________________________________________________
    public setAllLabelsVisibility(pType: eLabelType, pToShow: boolean) {
        for (let i = 0, l = this.mParts.length; i < l; i++) {
            this.mParts[i].setLabelVisiblity(pType, pToShow);
        }
    }
    //__________________________________________________________________________________________
    public resetPowerReadings() {
        for (let i = 0, l = this.mParts.length; i < l; i++) {
            this.mParts[i].resetPowers();
        }
    }
    //__________________________________________________________________________________________
    public async deletePart(pPart: Part, pToClearScene: boolean = false) {
        if (pPart == null) {
            return;
        }

        if (ePartType.GROUP === pPart.refCS.refPart.partOptions.type) {
            if (pPart.refCS.refPart.linkedParts.length == 2) {
                !pToClearScene && pPart.refCS.refPart.unHighlightObject()
                Group.instance.ungroup(pPart.refCS.refPart)

            }
        }
        let aPartIndex = this.mParts.findIndex((part) => { return pPart == part });
        if (aPartIndex != -1) {
            this.mParts.splice(aPartIndex, 1);
        }

        pPart.visibleObj?.parent?.remove(pPart.visibleObj);
        if (this.mSelectedPart == pPart) {
            this._onDeselectPart();
            Op3dContext.PART_INFO.close();
        }


        await this.deleteLinkedOptics(pPart);

        if (eAxisType.OPTICS === pPart.refCS.cs.type) {
            delete pPart.refCS.cs.linkedOpticsId;
        }

        pPart.visibleObj?.traverse((obj) => {
            if (obj instanceof Mesh || obj instanceof LineSegments || obj instanceof Points) {
                (obj as any).geometry.dispose()
            }
        })

        pPart.removeAllSubParts()
        pPart.delete();

        this.updatePartsList();
        this.updateLinkedParts();
        this.updateAnalysisOptions(true);

        // SceneContext.OP3D_SCENE.activateRenderer();

        // await Op3dContext.sleep(50)
        EventManager.dispatchEvent(EventsContext.PART_DELETED, this);

    }
    //__________________________________________________________________________________________
    public onMouseMove(pEvent: iClientPoint) {
        if (!this.mIsThrottled) {

            if ((null != this.mSelectedPart) && (this.mMouseDownPart == this.mSelectedPart) &&
                (false == this.mIsClickSpace)) {
                if (eClickMode.BASE == Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                    document.body.classList.add("grabbing");
                    this.mSelectedPart.moveOptixPart(pEvent);
                    for (let key in this.mOnMouseMove) {
                        this.mOnMouseMove[key]();
                    }
                }
            } else if ((null != this.mSelectedPart) &&
                (ePartType.GROUP === this.mSelectedPart.partOptions.type) &&
                (this.mMouseDownPart != null) && (false === this.mIsClickSpace)) {
                if (eClickMode.BASE == Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                    document.body.classList.add("grabbing");
                    this.mMouseDownPart.moveOptixPart(pEvent);
                    for (let key in this.mOnMouseMove) {
                        this.mOnMouseMove[key]();
                    }
                }
            } else if ((null != this.mSideBarPart) &&
                (true == OptixPartUtils.isInBoundingRect(pEvent, Op3dContext.CONTAINER))) {

                let aIsHitGrid = this._tryHitGrid(pEvent);
                if (true == aIsHitGrid) {

                    this.mIsFromSideBar = true;
                    SceneContext.OP3D_SCENE.enableController(false);
                    document.body.classList.add("grabbing");
                    this.mSideBarPart.moveOptixPart(pEvent);
                }
            }
            this.mIsThrottled = true;
            setTimeout(() => {
                this.mIsThrottled = false;
            }, 10);
        }
    }
    //__________________________________________________________________________________________
    public _tryHitGrid(pEvent: iClientPoint) {
        let aMouse = new Vector2();
        let aBounding = Op3dContext.CONTAINER.getBoundingClientRect();

        let aDomElement = SceneContext.RENDERER.domElement;
        aMouse.x = ((pEvent.clientX - aBounding.left) / aDomElement.clientWidth) * 2 - 1;
        aMouse.y = - ((pEvent.clientY - aBounding.top) / aDomElement.clientHeight) * 2 + 1;

        this.mRayCaster.setFromCamera(aMouse, SceneContext.CAMERA);
        let aIntersects = this.mRayCaster.intersectObject(
            SceneContext.POSITION_GENERATOR.horizontal,
            true);

        return (aIntersects.length > 0);
    }
    //__________________________________________________________________________________________
    public async onMouseUp(e: iClientPoint, pTarget: Event): Promise<void> {
        document.body.classList.remove("grabbing");

        try {
            Op3dContext.SIDEBAR_LIST.deselectNode();
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log("Op3dContext.SIDEBAR_LIST not loaded yet");
        }
        if (this.mMouseDownPart != null && (this.mMouseDownPart === this.mSelectedPart) &&
            (ePartType.GROUP ===
                Group.instance.findMainGroupPart(this.mMouseDownPart).partOptions.type)) {
            return;
        }

        this.mMouseDownPart = null;

        if (SceneContext.CHOOSE_MODE.mode !== eClickMode.BASE)
            this.unAddPart()

        if (this.mSideBarPart != null) {
            if (this.mIsFromSideBar) {
                Op3dContext.PARTS_MANAGER.checkConnectionWithParts(this.mSideBarPart);

                let aTarget = pTarget.target as HTMLElement;
                if ((null == aTarget) || (null == aTarget.parentElement) ||
                    (false == (aTarget.parentElement.classList.contains('view-1') || aTarget.className.includes('sprite')))) {
                    this.unAddPart()
                } else {

                    let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mSideBarPart.id);

                    let aInternalId = this.mSideBarPart.internalID;
                    this.mSideBarPart = null;
                    this.mIsFromSideBar = false;
                    let aSideBarPart = this.mParts.find((part) =>
                        part.internalID === aInternalId);

                    aSideBarPart.iPart.partVO = aPartVO

                    if (aPartVO.isDynamicModel == false && aPartVO.isBlackBox == false) {
                        let aPart2 = await PartsDataLoader.instance.getSingleFullData({
                            number_id: aPartVO.number_id
                        });

                        aSideBarPart.removeAllSubParts();
                        aSideBarPart.add(aPart2);
                        aSideBarPart.setPartLabel(aPartVO.name);




                    }

                    aSideBarPart.snapToGrid();
                    SceneContext.OP3D_SCENE.activateRenderer();
                    Op3dContext.SCENE_HISTORY.saveScene();
                    this.updateAnalysisOptions(false);

                    EventManager.dispatchEvent(EventsContext.PART_ADDED, this);

                    this.showDetectorGuide(aSideBarPart)

                }
            } else {
                this.unAddPart();
            }

            this.updatePartsList(false);
            this.updateLinkedParts();
        }

        if ((null != this.mSelectedPart) && (true == this.mIsFromSideBar)) {
            Op3dContext.PARTS_MANAGER.checkConnectionWithParts(this.mSelectedPart);
            this.mSelectedPart.onSelect();
            this._onDeselectPart();
            this.mIsFromSideBar = false;
        }

        if (this.mSelectedPart != null) {

            let aLaserBehavior = this.mSelectedPart.getBehavior("laserBehavior");
            let aUpdateSpatialInfo = aLaserBehavior != null ?
                aLaserBehavior.laserData.isUserDefinedLaser : false;

            if (aUpdateSpatialInfo) {

                let aLaserPoint = this.mSelectedPart.getAxes().find(axis =>
                    (eAxisType.LASER === axis.type));
                let aLaserPointPos = aLaserPoint.object3D.getWorldPosition(new Vector3());

                EventManager.dispatchEvent<Vector3>(EventsContext.UPDATE_SPATIAL_INFO,
                    this.mSelectedPart.internalID, aLaserPointPos);
            }
            Op3dContext.PARTS_MANAGER.checkConnectionWithParts(this.mSelectedPart);

            if ((pTarget.target as any).localName == 'canvas') {

                if (JSON.stringify(this.mPartMoved) !== JSON.stringify(e)) {
                    Op3dContext.SCENE_HISTORY.addToHistory(this.mPrevState)
                    Op3dContext.SCENE_HISTORY.saveScene();
                }
            }
        }

        this.mIsClickSpace = false;
    }
    //__________________________________________________________________________________________
    private showDetectorGuide(pPart: Part) {
        try {
            if (pPart.iPart.name.includes('D1012')
                &&
                (window as any).userGuidingUserStorage != null
                &&
                (window as any).userGuidingUserStorage.attributes.isBasic == true
                &&
                (window as any).userGuiding.getAppState().guidePreview.active == false
            ) {
                (window as any).userGuiding.previewGuide(85709)
            }
        } catch (e) {
            console.error('UserGuiding detector failed:', e);
        }

    }
    //__________________________________________________________________________________________
    public set mouseDownPart(pPart: Part) {
        this.mMouseDownPart = pPart;
    }
    //__________________________________________________________________________________________
    public onMouseDown(pEvent: iClientPoint,
        pObject: Intersection): void {
        switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
            case eClickMode.GROUP:
            case eClickMode.BASE:
                if (pObject == null) {
                    this._onDeselectPart();
                    this.mIsClickSpace = true;
                } else {
                    this.mPartMoved = pEvent
                    this.mPrevState = Op3dContext.SETUPS_MANAGER.getSetupData()

                    this.mIsClickSpace = false;
                }
                break;
            default:
                break;
        }
    }
    //__________________________________________________________________________________________
    public onDblMouseDown(): void {
        document.body.classList.remove("grabbing");


        if (this.mSelectedPart == null || this.mMouseDownPart == null) {
            return
        } else {
            this.mSelectedPart.unHighlightObject()
        }
        if (ePartType.GROUP === this.mSelectedPart.partOptions.type) {
            this.mMouseDownPart.onDblSelect()
        }

        this.mSelectedPart.onDblSelect();
        this.mIsFromSideBar = false;



        this.mIsClickSpace = false;

    }
    //__________________________________________________________________________________________
    private async _createPlane(pData: iSideBarData) {
        let aPartVO = pData.item;
        let aImageURL = Op3dContext.IMAGES_HASH[aPartVO.id];
        let aPlane: Mesh;

        let aImg = new Image()
        aImg.src = aImageURL;
        const aCanvas = document.createElement('canvas');

        // Draw the texture on the canvas
        const aContext = aCanvas.getContext('2d');
        aContext.drawImage(aImg, 0, 0, aCanvas.width, aCanvas.height);

        // Get pixel data
        const aImageData = aContext.getImageData(0, 0, aCanvas.width, aCanvas.height);
        const aPixelData = aImageData.data;
        let aCounterOfWhitePixels = 0
        for (let i = 0; i < aPixelData.length; i += 4) {
            const aRed = aPixelData[i];
            const aGreen = aPixelData[i + 1];
            const aBlue = aPixelData[i + 2];
            const aAlpha = aPixelData[i + 3];
            if (aRed === 255 && aGreen === 255 && aBlue === 255 && aAlpha === 255) {
                aCounterOfWhitePixels++
            }
            if (aCounterOfWhitePixels > 100) {
                aImageURL = '../images/place_holder.png'
                break
            }
        }

        aPlane = await PartsFactory.getImagePlane(aImageURL)

        let aImagePlane: iPart = {
            name: Strings.IMAGE_PART,
            object3D: aPlane,
            internal_id: Op3dUtils.idGenerator()
        }

        let aPartOptions: iPartOptions = { type: ePartType.CAD_PART };
        if (aPartVO.isBreadboard) {
            aPartOptions.initialHeight = 0;
            aPartOptions.isOptix = aPartVO.isOptix;
        } else if (null != aPartVO.initialHeight) {
            aPartOptions.initialHeight = aPartVO.initialHeight;
        }

        let aPart = new Part({
            id: aPartVO.id,
            number_id: aPartVO.number_id,
            options: aPartOptions
        }).add(aImagePlane);

        if (aPart instanceof Part) {
            // SceneContext.OP3D_SCENE.activateRenderer()
            //TODO: remove this line
            this.mSideBarPart = aPart;
            this.mSideBarPart.moveOptixPart(pData.event);
            this.onMouseMove(pData.event);
            if (Op3dScene.SHOW_ALL_LABELS === true) {
                aPart.labelVisiblity = true
            }
        } else {
            // alert("part is not loaded yet");
        }
    }
    //__________________________________________________________________________________________
    public async deletePartsByID(pID: string) {
        let aPartsToDelete = this.mParts.filter((part) => (pID == part.id));

        for (let i = 0; i < aPartsToDelete.length; i++) {
            await this.deletePart(aPartsToDelete[i]);
        }
    }

    //__________________________________________________________________________________________
    public async onSideBarMouseDown(pData: iSideBarData) {
        Op3dContext.INPUT_DISPATCHER.mouseDownTime = new Date();
        this._onDeselectPart();
        Op3dContext.SCENE_HISTORY.addToHistory()


        if (pData.item.isDynamicModel || pData.item.isBlackBox == true) {

            this.mSideBarPart = await PartsCatalog.instance.getDynamicPart(pData.item);
            this.mSideBarPart.setPartLabel(pData.item.name)

            /**
             * @TODO temporary solution we should decide how to know that a detector was added
             */
            if (pData.item.id == "D1012") {
                let aFrontFace: string = null;
                try {
                    aFrontFace = this.mSideBarPart.getFaces().find(face => face.name == SurfaceContext.FRONT).internal_id;
                } catch (e) {
                    Op3dContext.USER_VO.isEmployeeUser && console.log(e);
                    aFrontFace = null;
                }
                this.mSideBarPart.addAnalysisOption({
                    advanced: [],
                    fast: [AnalysisContext.getDefaultAnalysis()],
                    faceId: aFrontFace
                });
            }

            this.mSideBarPart.moveOptixPart(pData.event);
            this.updateAnalysisOptions(false);
            // }

        } else {
            this._createPlane({ ...pData });
        }
    }
    //__________________________________________________________________________________________
    protected _onNew() {
        Op3dContext.SCENE_HISTORY.clearHistory();
        this._clearScene();
    }
    //__________________________________________________________________________________________
    /**
    * @description initialization of all relevant components for the parts scene
    */
    //__________________________________________________________________________________________
    private async _onSideBarPartsReady(pParts: Array<iSideBarActivePartData>) {
        let aUrlsToLoad = new Array<iLoadFile>();
        let aLength = pParts.length;
        let aPlaceHolder = Op3dContext.DATA_MANAGER.getPartVOById(Strings.PLACE_HOLDER_ID);

        for (let i = 0; i < aLength; i++) {
            if (!pParts[i].isCustom) {
                aUrlsToLoad.push({ url: pParts[i].url });
            } else {
                let aIsExist = (null == aUrlsToLoad.find((loadFile) =>
                    (loadFile.url == aPlaceHolder.url)));
                if (true == aIsExist) {
                    aUrlsToLoad.push({
                        url: aPlaceHolder.url,
                        isOptix: aPlaceHolder.isOptix,
                        move: aPlaceHolder.move,
                        isAssembly: aPlaceHolder.isAssembly
                    });
                }
            }
        }

        await PartsCatalog.instance.getPartsByUrls(aUrlsToLoad);
    }
    //__________________________________________________________________________________________
    protected _addEventListeners() {
        EventManager.addEventListener(EventsContext.ON_CLEAR_ALL_PARTS,
            () => this._clearScene(), this);

        EventManager.addEventListener(EventsContext.SIDE_BAR_ITEMS_READY,
            (pData: EventBase<Array<iSideBarActivePartData>>) =>
                this._onSideBarPartsReady(pData.data), this);

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this._onNew(), this);

    }
    //__________________________________________________________________________________________
    public async deleteOpticsFromPart(pPart: Part) {
        let aLinkedParts = pPart.linkedParts;
        if (null == aLinkedParts) {
            return;
        }

        let aOpticsPart = aLinkedParts.find((part) => (eAxisType.OPTICS === part.refCS.cs.type));
        if (null == aOpticsPart) {
            return;
        }

        delete aOpticsPart.refCS.cs.linkedOpticsId;
        await this.deletePart(aOpticsPart);
    }
    //__________________________________________________________________________________________
    private async _setOpticsOnMount(pMount: Part, pOptics: Part, pOpticsAxis: iAxis,
        pOpticsNumberID: string) {
        pOptics.visibleObj.visible = false;

        if (null == pOpticsAxis.face) {
            return;
        }

        let aOpticsLCSFace = pOptics.getOpticsFaces().find((face) =>
            ((null != face.name) && pOpticsAxis.face.toUpperCase() == face.name.toUpperCase()));
        if (null == aOpticsLCSFace) {
            return;
        }
        await this.deleteOpticsFromPart(pMount);

        pOptics.setWorkingCS(aOpticsLCSFace.axes[0], false);
        pOptics.setRefrence({
            cs: pOpticsAxis,
            refPart: pMount
        });

        let aPositionOffset = new Vector3();
        if (eBaseShape.VOLUME == pOpticsAxis.shape) {
            let aOpticsSize = Op3dUtils.getNetoItemSize(pOptics.visibleObj);
            let aOffsetY = (aOpticsSize.y / 2);
            aPositionOffset.set(0, aOffsetY, 0);
        }

        MovementBehavior.transformPart(pOptics, aPositionOffset,
            new Euler());

        pOptics.visibleObj.visible = true
        pOpticsAxis.linkedOpticsId = pOpticsNumberID;
    }
    //__________________________________________________________________________________________
    private _copyPartProperties(pOldPart: Part, pNewPart: Part) {
        this._copySurfacesProperties(pOldPart, pNewPart);

        let aLCSPath = pOldPart.getWCSPath();
        let aNewOpticsLCS = pNewPart.getCSByPath(aLCSPath);
        if (undefined !== aNewOpticsLCS) {
            pNewPart.setWorkingCS(aNewOpticsLCS);
        }
        MovementBehavior.copyPose(pOldPart, pNewPart)

        let aLinkedParts = pOldPart.linkedParts;
        if (undefined !== aLinkedParts) {
            for (let i = 0, l = aLinkedParts.length; i < l; i++) {
                let aRefCS = aLinkedParts[i].refCS;
                let aCSPath = pOldPart.getCSPath(aRefCS.cs.internal_id);
                let aNewCS = pNewPart.getCSByPath(aCSPath);
                if (undefined !== aNewCS) {
                    aLinkedParts[i].setRefrence({
                        cs: aNewCS,
                        refPart: pNewPart
                    });
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public chooseOptics(pData: iChooseOpticsEvent): Part {
        const aNewOptics = PartsFactory.getNewOpticalDevice(pData.opticsVO);

        let aPos = new Vector3();
        if (pData.openMenuPoint !== undefined && pData.openMenuPoint !== null) {
            aNewOptics.moveOptixPart(pData.openMenuPoint);
            aPos = this._getNewPartPosition(aNewOptics, pData.index);
            aNewOptics.setOnPosition(aPos);
        }

        const aPart = pData.part;
        if (undefined !== aPart) {
            const aOpticsAxis = aPart.getOpticsAxes();
            if (undefined !== aOpticsAxis) {
                this._setOpticsOnMount(aPart, aNewOptics, aOpticsAxis, pData.opticsVO.number_id);
            } else {
                let aLabel = pData.opticsVO.name;
                if (true === pData.copy_data) {
                    this._copyPartProperties(aPart, aNewOptics);
                    let aPartLabel = aPart.getLabel();
                    if (undefined !== aPartLabel) {
                        aLabel = aPartLabel.label;
                    }
                } else {
                    MovementBehavior.copyPose(aPart, aNewOptics)
                }

                aNewOptics.setPartLabel(aLabel);
                this.deletePart(aPart);
            }
        } else {
            aNewOptics.setPartLabel(pData.opticsVO.name);
        }

        aNewOptics.subParts[0].subParts[0].data.searchData = pData.searchData;

        this.updatePartsList();
        this.updateLinkedParts();
        SceneContext.OP3D_SCENE.activateRenderer();

        if (true === Op3dScene.SHOW_ALL_LABELS) {
            aNewOptics.labelVisiblity = true;
        }

        return aNewOptics;
    }
    //__________________________________________________________________________________________
    private _copySurfacesProperties(pOriginal: Part, pNew: Part) {

        const aOriginalFaces = pOriginal.getFaces();
        const aNewFaces = pNew.getFaces();

        for (let i = 0; i < aOriginalFaces.length; i++) {
            const aCurrFace = aOriginalFaces[i];
            const aNewFace = aNewFaces.find(item => item.originalName === aCurrFace.originalName);
            if (aNewFace !== undefined) {
                /**
                 * just the grating for now, 
                 * add more on demand
                 */
                aNewFace.data.gratingData = aCurrFace.data.gratingData;
            }
        }
    }
    //__________________________________________________________________________________________
    public chooseOptomechanics(pData: iChooseOptomechanicEvent): Vector3 {

        let aPreviousPartPosition = pData.previousPartPosition
        let aPart = pData.part;
        let aSize, aPosToMove
        if (pData.openMenuPoint !== undefined && pData.openMenuPoint !== null) {
            aPart.moveOptixPart(pData.openMenuPoint);

            const box = new Box3().setFromObject(aPart.iPart.object3D)
            aSize = box.getSize(new Vector3())

            let aNewPos = aPart.visibleObj.position.clone();
            aPosToMove = aPreviousPartPosition + aSize.z / 2
            aNewPos.z += aPosToMove

            aPart.setOnPosition(aNewPos);
        }


        SceneContext.OP3D_SCENE.activateRenderer();
        return aSize
    }
    //__________________________________________________________________________________________
    protected _clearScene() {
        while (this.mParts.length > 1) {
            this.deletePart(this.mParts.pop(), true);
        }
    }
    //__________________________________________________________________________________________
    public get sideBarPart() {
        return this.mSideBarPart;
    }

    //__________________________________________________________________________________________
    public set sideBarPart(pPart: Part) {
        this.mSideBarPart = pPart;
    }
    //__________________________________________________________________________________________
}
