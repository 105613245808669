import { EventManager } from "../../../../oc/events/EventManager";
import { eMaterialEquationType, eDataPermission, eUnitType } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { eBaseShape, eOpticShape } from "../../../_context/OpticsContext";
import { Strings } from "../../../_context/Strings";
import { iOP3DHTMLInputElement, iHash } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { MaterialUtils } from "../../../_utils/MaterialUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { MaterialDataLoader } from "../../../data/data_loader/MaterialDataLoader";
import { UnitHandler } from "../../../units/UnitsHandler";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { CustomSelect, iCustomSelectOption } from "../../components/CustomSelect";
import { FloatingCalcSettings, eCalcUnitType, iCalcSettings, iCalcUnitSettings } from "../FloatingCalcSettings";
import { CalculatorGraph, iCalcCustomSelectData } from "../tools/CalculatorGraph";
import { FloatingScene } from "../tools/FloatingScene";
import { iGBCObject } from "./GaussianBeamCalculator";

export interface iEFLCalculatorElements {
    H1: iEFLCalculatorObject;
    H2: iEFLCalculatorObject;
    R1: iEFLCalculatorObject;
    R2: iEFLCalculatorObject;
    EFL: iEFLCalculatorObject;
    BFL: iEFLCalculatorObject;
    FFL: iEFLCalculatorObject;
    CT: iEFLCalculatorObject;
    CA: iEFLCalculatorObject;
    n: iEFLCalculatorObject;
    material_wavelenght: iEFLCalculatorObject;
};

export enum eLensCalculator {
    EFL = 'EFL',
    FFL = 'FFL',
    BFL = 'BFL',
    R1 = 'R1',
    R2 = 'R2',
    CT = 'CT',
    n = 'n'
}

export interface iEFLCalculatorObject {
    input: iOP3DHTMLInputElement;
    hide?: Array<eEFLOpticsType>;
    target?: eEFLCalcMode;
    units?: iCalcUnitSettings;

    hideValue?: number;
    defaultValue?: number;

    changeEvent?: (pVal: number) => any;

    readonly?: Array<eEFLCalcMode>;
};

export enum eEFLOpticsType {
    CONVEX_CONVEX = 'CONVEX_CONVEX',
    CONCAVE_CONCAVE = 'CONCAVE_CONCAVE',
    POS_MENISCUS = 'POS_MENISCUS',
    POS_MENISCUS_OPP = 'POS_MENISCUS_OPP',
    NEG_MENISCUS = 'NEG_MENISCUS',
    NEG_MENISCUS_OPP = 'NEG_MENISCUS_OPP',
    PLANO_CONVEX = 'PLANO_CONVEX',
    PLANO_CONCAVE = 'PLANO_CONCAVE',
    PLANO_CONVEX_OPP = 'PLANO_CONVEX_OPP',
    PLANO_CONCAVE_OPP = 'PLANO_CONCAVE_OPP'
};

export enum eEFLCalcMode {
    FOCUSES = 'FOCUSES',
    R1 = 'R1',
    R2 = 'R2',
    n = 'n'
};

export class LensCalculator extends Op3dComponentBase {

    private static INSTANCE: LensCalculator;
    private mElements: iEFLCalculatorElements;

    private mEFLCalcMode: eEFLCalcMode;
    private mEFLOpticsType: eEFLOpticsType;

    private mMaterialSelect: CustomSelect;

    private mRBEvent: EventListener;
    private mGraph: CalculatorGraph;
    private mCalcSettings: FloatingCalcSettings;

    private mSettings: iCalcSettings = {
        decimalPrecision: 10,
        lenghtUnits: {
            scale: (1 / UnitHandler.presentedScale),
            sign: UnitHandler.shortSign,
            type: eCalcUnitType.LENGHT
        }
    };
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/calculators/lens_calculator.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == LensCalculator.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.classList.add('efl_calculator');
            aContainer.classList.add('new_modal');
            aContainer.classList.add('p-0');
            aContainer.style.left = ((window.innerWidth - 552) / 2) + 'px';
            aContainer.style.top = '55px';
            aContainer.setAttribute('data-backdrop', 'static');
            document.getElementById('forms').appendChild(aContainer);
            LensCalculator.INSTANCE = new LensCalculator(aContainer);
        }

        return LensCalculator.INSTANCE;
    }
    //__________________________________________________________________________________________
    public static calculateFFL(R1: number, R2: number, CT: number, n: number) {
        let EFL = LensCalculator.calculateEFL(R1, R2, CT, n);
        let FFL = -EFL * (1 + (((n - 1) * CT) / (n * R2)));

        return FFL;
    }
    //__________________________________________________________________________________________
    public static calculateBFL(R1: number, R2: number, CT: number, n: number) {
        let EFL = LensCalculator.calculateEFL(R1, R2, CT, n);
        let BFL = EFL * (1 - (((n - 1) * CT) / (n * R1)));

        return BFL;
    }
    //__________________________________________________________________________________________
    public static calculateEFL(R1: number, R2: number, CT: number, n: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let EFL = (1 / ((n - 1) * (c1 - c2 + (((n - 1) * CT * c1 * c2) / n))));
        return EFL;
    }
    //__________________________________________________________________________________________
    public static calcRefIndexByEFL(R1: number, R2: number, CT: number, EFL: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let a = ((c1 - c2) + (CT * c1 * c2));
        let b = -((c1 - c2) + (2 * CT * c1 * c2) + (1 / EFL));
        let c = (CT * c1 * c2);

        let n12 = OP3DMathUtils.solveQuadraticEquation(a, b, c);
        let n = (n12.x1 > 0) ? n12.x1 : n12.x2;

        return n;
    }
    //__________________________________________________________________________________________
    public static calcCTByEFL(R1: number, R2: number, n: number, EFL: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let CT = (1 / (c1 * c2)) * (n / ((n - 1) * (n - 1)));
        CT *= ((1 / EFL) - ((n - 1) * (c1 - c2)))

        return CT;
    }
    //__________________________________________________________________________________________
    public static calcCTByBFL(R1: number, R2: number, n: number, BFL: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let CT = (((1 / BFL) - ((n - 1) * (c1 - c2))) / ((n - 1) * c1 + (1 / BFL)));
        CT *= (n / ((n - 1) * c2));

        return CT;
    }
    //__________________________________________________________________________________________
    public static calcCTByFFL(R1: number, R2: number, n: number, FFL: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let CT = (((1 / FFL) + ((n - 1) * (c1 - c2))) / ((n - 1) * c1 + (1 / FFL)));
        CT *= (n / ((n - 1) * c1));

        return CT;
    }
    //__________________________________________________________________________________________
    public static calcRefIndexByBFL(R1: number, R2: number, CT: number, BFL: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let a = ((c1 - c2) + (CT * c1 * c2));
        let b = -((c1 - c2) + (2 * CT * c1 * c2) + ((1 - CT * c1) / BFL));
        let c = (CT * c1) * (c2 - (1 / BFL));

        let n12 = OP3DMathUtils.solveQuadraticEquation(a, b, c);
        let n = (n12.x1 > 0) ? n12.x1 : n12.x2;

        return n;
    }
    //__________________________________________________________________________________________
    public static calcRefIndexByFFL(R1: number, R2: number, CT: number, FFL: number) {
        let c1 = (1 / R1);
        let c2 = (1 / R2);

        let a = ((c1 - c2) + (CT * c1 * c2));
        let b = -((c1 - c2) + (2 * CT * c1 * c2) - ((1 + CT * c2) / FFL));
        let c = -(CT * c2) * (c1 + (1 / FFL));

        let n12 = OP3DMathUtils.solveQuadraticEquation(a, b, c);
        let n = (n12.x1 > 0) ? n12.x1 : n12.x2;

        return n;
    }
    //__________________________________________________________________________________________
    public static calculateR1ByEFL(R2: number, CT: number, EFL: number, n: number) {
        let c2 = (1 / R2);

        let R1 = (((n - 1) * (1 + (((n - 1) * CT * c2) / n))) / ((n - 1) * c2 + (1 / EFL)));
        return R1;
    }
    //__________________________________________________________________________________________
    public static calculateR1ByBFL(R2: number, CT: number, BFL: number, n: number) {
        let c2 = (1 / R2);

        let R1 = (n - 1) * (BFL * (n + (n - 1) * CT * c2) + CT);
        R1 /= (n * (BFL * (n - 1) * c2 + 1));
        return R1;
    }
    //__________________________________________________________________________________________
    public static calculateR1ByFFL(R2: number, CT: number, FFL: number, n: number) {
        let EFL = -FFL / (1 + (((n - 1) * CT) / (n * R2)));
        return LensCalculator.calculateR1ByEFL(R2, CT, EFL, n);
    }
    //__________________________________________________________________________________________
    public static calculateR2ByEFL(R1: number, CT: number, EFL: number, n: number) {
        let c1 = (1 / R1);

        let R2 = (((n - 1) * (1 - (((n - 1) * CT * c1) / n))) / ((n - 1) * c1 - (1 / EFL)));
        return R2;
    }
    //__________________________________________________________________________________________
    public static calculateR2ByBFL(R1: number, CT: number, BFL: number, n: number) {
        let EFL = BFL / (1 - (((n - 1) * CT) / (n * R1)));
        return LensCalculator.calculateR2ByEFL(R1, CT, EFL, n);
    }
    //__________________________________________________________________________________________
    public static calculateR2ByFFL(R1: number, CT: number, FFL: number, n: number) {
        let c1 = (1 / R1);

        let R2 = ((n - 1) * (FFL * (n - (n - 1) * CT * c1) - CT)) / (FFL * (n - 1) * c1 - 1);
        return R2;
    }
    //__________________________________________________________________________________________
    public updateMaterial(pMaterialID: string) {
        if (this.mMaterialSelect.value == pMaterialID) {
            return;
        }

        this.mMaterialSelect.value = pMaterialID;
    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        FloatingScene.instance.close();
        this.mGraph.close();
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        $(this.mContainer).find('.selectpicker').selectpicker({
            liveSearch: true,
            container: '.select_cot'
        });

        this._changeState();

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mElements = {
            H1: {
                input: this._getPart('h1_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                defaultValue: 0
            },
            H2: {
                input: this._getPart('h2_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                defaultValue: 0
            },
            R1: {
                input: this._getPart('r1_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                target: eEFLCalcMode.R1,
                hide: [eEFLOpticsType.PLANO_CONCAVE_OPP, eEFLOpticsType.PLANO_CONVEX_OPP],
                defaultValue: 50
            },
            R2: {
                input: this._getPart('r2_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                target: eEFLCalcMode.R2,
                hide: [eEFLOpticsType.PLANO_CONCAVE, eEFLOpticsType.PLANO_CONVEX],
                defaultValue: -100
            },
            EFL: {
                input: this._getPart('efl_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                target: eEFLCalcMode.FOCUSES
            },
            BFL: {
                input: this._getPart('bfl_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                target: eEFLCalcMode.FOCUSES,
                readonly: [eEFLCalcMode.R1, eEFLCalcMode.R2, eEFLCalcMode.n]
            },
            FFL: {
                input: this._getPart('ffl_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                target: eEFLCalcMode.FOCUSES,
                readonly: [eEFLCalcMode.R1, eEFLCalcMode.R2, eEFLCalcMode.n]
            },
            CT: {
                input: this._getPart('ct_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                defaultValue: 10
            },
            CA: {
                input: this._getPart('ca_input').children[1] as iOP3DHTMLInputElement,
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits),
                defaultValue: 25
            },
            n: {
                input: this._getPart('n_input').children[1] as iOP3DHTMLInputElement,
                target: eEFLCalcMode.n,
                defaultValue: 1.5187257731709503
            },
            material_wavelenght: {
                input: this._getPart('material_wavelenght').children[1] as iOP3DHTMLInputElement,
                defaultValue: 550,
                units: {
                    sign: 'nm'
                },
                changeEvent: () => this._onChangeMaterial()
            }
        }

        let aFixed = this.mSettings.decimalPrecision;
        for (let elem in this.mElements) {
            let aElement = this.mElements[elem] as iGBCObject;
            if (null != aElement.defaultValue) {
                let aUnits = aElement.units;
                let aScale = ((null != aUnits) && (null != aUnits.scale)) ? aUnits.scale : 1;


                let aVal = (aElement.defaultValue / aScale);

                let aSign = (null != aUnits) ? aUnits.sign : '';
                aElement.input.value = (OP3DMathUtils.toFixed(aVal, aFixed) + ' ' + aSign);
                aElement.input.prevValue = aElement.input.value;

                aElement.input.actualValue = aElement.defaultValue;
            }
        }

        this._initMaterialSelect();
        this._onChangePermission();

        this._initGraph();
        this._initSettings();
    }
    //__________________________________________________________________________________________
    private _initSettings() {
        this.mCalcSettings = new FloatingCalcSettings({
            callback: (pSettings) => this._onUpdateSettings(pSettings),
            lenghtUnitsSettings: [{
                sign: UnitHandler.SHORT_IN_SIGN,
                scale: (UnitHandler.IN_TO_MM),
                type: eCalcUnitType.LENGHT,
                isDefault: (UnitHandler.PRESENTED_UNIT == eUnitType.INCHES)
            },
            {
                sign: UnitHandler.SHORT_MM_SIGN,
                scale: 1,
                type: eCalcUnitType.LENGHT,
                isDefault: (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS)
            }]
        });
    }
    //__________________________________________________________________________________________
    private _onUpdateSettings(pCalcSettings: iCalcSettings) {
        this.mSettings = pCalcSettings;
        let aScale = pCalcSettings.lenghtUnits.scale;

        for (let elem in this.mElements) {
            let aElement = this.mElements[elem] as iGBCObject;

            if ((null != aElement.units) && (eCalcUnitType.LENGHT == aElement.units.type)) {
                aElement.units = DataUtils.getObjectCopy(this.mSettings.lenghtUnits);
                aElement.input.value = OP3DMathUtils.toFixed((aElement.input.actualValue / aScale),
                    pCalcSettings.decimalPrecision) + ' ' + aElement.units.sign;
                aElement.input.prevValue = aElement.input.value;
            }
        }

        this.mGraph.updateUnit(pCalcSettings.lenghtUnits);
    }
    //__________________________________________________________________________________________
    private _initGraph() {
        let aOptionsX = new Array<iCustomSelectOption<iCalcCustomSelectData>>();
        aOptionsX.push({
            value: 'n', text: 'n',
            data: { defaultRange: { min: 1.2, max: Infinity } },
            enable: true
        });

        aOptionsX.push({
            value: 'R1', text: 'R1',
            data: { unit: this.mElements.R1.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: 'R2', text: 'R2',
            data: { unit: this.mElements.R2.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: 'CT', text: 'CT',
            data: { unit: this.mElements.CT.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: 'EFL', text: 'EFL', isPremiumOnly: true,
            data: { unit: this.mElements.EFL.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: 'BFL', text: 'BFL', isPremiumOnly: true,
            data: { unit: this.mElements.BFL.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: 'FFL', text: 'FFL', isPremiumOnly: true,
            data: { unit: this.mElements.FFL.units } as iCalcCustomSelectData,
            enable: true
        });

        let aOptionsY = new Array<iCustomSelectOption<iCalcCustomSelectData>>();
        aOptionsY.push({
            value: 'EFL', text: 'EFL',
            data: { unit: this.mElements.EFL.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsY.push({
            value: 'BFL', text: 'BFL',
            data: { unit: this.mElements.BFL.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsY.push({
            value: 'FFL', text: 'FFL',
            data: { unit: this.mElements.FFL.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsY.push({
            value: 'R1', text: 'R1', isPremiumOnly: true,
            data: { unit: this.mElements.R1.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsY.push({
            value: 'R2', text: 'R2', isPremiumOnly: true,
            data: { unit: this.mElements.R2.units } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsY.push({
            value: 'n', text: 'n', isPremiumOnly: true,
            data: { defaultRange: { min: 1.2, max: Infinity } } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsY.push({
            value: 'CT', text: 'CT', isPremiumOnly: true,
            data: { unit: this.mElements.CT.units } as iCalcCustomSelectData,
            enable: true
        });

        let aGraphHash = this._getGraphHash();
        this.mGraph = CalculatorGraph.getNewInstance({
            calcHash: aGraphHash,
            optionsX: aOptionsX,
            optionsY: aOptionsY
        });
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        let aCalcBtn = this._getPart('calc_settings_btn');
        aCalcBtn.addEventListener('click', (_e) => {
            let aBB = aCalcBtn.getBoundingClientRect();

            this.mCalcSettings.open({ clientX: aBB.right, clientY: aBB.top }, this.mSettings);
        });
        this._getPart('close').addEventListener('click', () => this.close());
        this._getPart('show_lens_3d').addEventListener('click', () => this._onShowIN3D());
        this._getPart('show_graphs').addEventListener('click', () => this._onShowGraph());
        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onChangePermission(), this);

        for (let item in this.mElements) {
            let aElement = this.mElements[item] as iEFLCalculatorObject;
            if (null != aElement.units) {
                aElement.input.addEventListener('focus', () => aElement.input.value =
                    parseFloat(aElement.input.value).toString());
                aElement.input.addEventListener('blur', () => {
                    let aVal = parseFloat(aElement.input.value);
                    aElement.input.value = aVal.toString();
                    if ((null != aElement.units) &&
                        (Infinity != parseFloat(aElement.input.value))) {
                        aElement.input.value += ' ' + aElement.units.sign;
                    }

                    aElement.input.prevValue = aElement.input.value;
                });
            }

            aElement.input.addEventListener('change', () => {
                if (true == isNaN(parseFloat(aElement.input.value))) {
                    aElement.input.value = aElement.input.prevValue;
                    return;
                }

                let aVal = parseFloat(aElement.input.value);

                aElement.input.value = OP3DMathUtils.toFixed(aVal, this.mSettings.decimalPrecision);
                aElement.input.prevValue = aElement.input.value;
                aElement.input.actualValue = aVal;

                if (null != aElement.changeEvent) {
                    let aVal = parseFloat(aElement.input.value);
                    aElement.changeEvent(aVal);
                }


                this._calculate();
                aElement.input.dispatchEvent(new Event('blur'));
            });
        }

        $(this.mContainer).find('input[name="optics_type"]').each(
            (_index, input) => {
                let aOpticsType = input.getAttribute('opticsType') as eEFLOpticsType;
                input.addEventListener('change', () => {

                    let aOpp = Op3dUtils.getElementIn(input.nextElementSibling as HTMLElement,
                        'opposite_arrows');
                    if (null != aOpp) {
                        let aDis = aOpp.getAttribute('dis');
                        if (null != aDis) {
                            let aDisableStates = aDis.split(',');
                            let aIndex = aDisableStates.indexOf(this.mEFLCalcMode);
                            if (1 == aIndex) {
                                aOpticsType = input.getAttribute('opp') as eEFLOpticsType;
                            }
                        }
                    }

                    this.mEFLOpticsType = aOpticsType;
                    this._onChangeLensType();
                    this._changeState();
                });

                //For optics with opposite state
                let aOpp = Op3dUtils.getElementIn(input.nextElementSibling as HTMLElement,
                    'opposite_arrows');
                if (null != aOpp) {
                    aOpp.addEventListener('click', (e) => {
                        if (false == (input as HTMLInputElement).checked) {
                            return;
                        }

                        e.stopPropagation();

                        let aOppOpticsType = input.getAttribute('opp') as eEFLOpticsType;
                        let aNewOpticsType = (this.mEFLOpticsType == aOppOpticsType) ?
                            aOpticsType : aOppOpticsType;

                        this.mEFLOpticsType = aNewOpticsType;
                        this._onChangeLensType();
                        this._changeState();
                    });
                }

                if (true == (input as HTMLInputElement).checked) {
                    this.mEFLOpticsType = input.getAttribute('opticsType') as eEFLOpticsType;
                    this._changeState();
                }
            });

        this.mRBEvent = (e) => {
            let aInput = (e.target as HTMLInputElement);
            this.mEFLCalcMode = aInput.getAttribute('calc_type') as eEFLCalcMode;
            if (eEFLCalcMode.n == this.mEFLCalcMode) {
                this.mMaterialSelect.enabled = false;
            }

            let aIsRefractiveIndexMode = (eEFLCalcMode.n == this.mEFLCalcMode);
            this.mMaterialSelect.readonly = aIsRefractiveIndexMode;
            this.mMaterialSelect.setVisibility(!aIsRefractiveIndexMode);

            let aWavelenght = this.mElements.material_wavelenght.input.parentElement;
            ViewUtils.setElementVisibilityByDNone(aWavelenght, !aIsRefractiveIndexMode);

            let aEtSign = this._getPart('et_sign');
            ViewUtils.setElementVisibilityByDNone(aEtSign, !aIsRefractiveIndexMode);
            this._changeState();
        };

        $(this.mContainer).find('input[name="calc_type"]').each(
            (_index, input) => {
                input.addEventListener('change', this.mRBEvent);

                if (true == (input as HTMLInputElement).checked) {
                    this.mEFLCalcMode = input.getAttribute('calc_type') as eEFLCalcMode;
                }
            });
    }
    //__________________________________________________________________________________________
    private _changeState(pToCalculate: boolean = true) {
        for (let item in this.mElements) {
            let aElement = this.mElements[item] as iEFLCalculatorObject;
            let aParent = aElement.input.parentElement;

            if (null != aElement.target) {
                let aIsTarget = (this.mEFLCalcMode == aElement.target);
                ViewUtils.setClassState(aParent, 'calc_target', aIsTarget);
                aElement.input.readOnly = aIsTarget;

                let aTabIndex = (true == aIsTarget) ? '-1' : null;
                ViewUtils.setElementAttribute(aElement.input, 'tabindex', aTabIndex);
            }

            if (null != aElement.readonly) {
                let aReadOnly = (aElement.readonly.indexOf(this.mEFLCalcMode) > -1);
                ViewUtils.setClassState(aParent, 'readonly', aReadOnly);
                aElement.input.readOnly = aReadOnly;
            }
            /*
                            if (null != aElement.hide) {
                                let aToShow = (-1 == aElement.hide.indexOf(this.mEFLOpticsType));
                                ViewUtils.setElementVisibilityByDNone(aParent, aToShow);
            
                                if (false == aToShow) {
                                    if (null != aElement.hideValue) {
                                        aElement.input.value = aElement.hideValue.toString();
                                    }
                                }
            
                                aElement.input.dispatchEvent(new Event('blur'));
                            }
                            */
        }

        $(this.mContainer).find('[picType]').each((_index, elem) => {
            let aPicType = elem.getAttribute('picType') as eEFLOpticsType;
            ViewUtils.setClassShowState(elem as HTMLElement, this.mEFLOpticsType == aPicType);
        });

        let aR1RadioBtn = Op3dUtils.getElementInByAttr(this.mContainer, 'calc_type',
            eEFLCalcMode.R1).parentElement;
        let aShowR1 = (this.mElements.R1.hide.indexOf(this.mEFLOpticsType) == -1);
        ViewUtils.setElementVisibilityByDNone(aR1RadioBtn, aShowR1);

        let aR2RadioBtn = Op3dUtils.getElementInByAttr(this.mContainer, 'calc_type',
            eEFLCalcMode.R2).parentElement;
        let aShowR2 = (this.mElements.R2.hide.indexOf(this.mEFLOpticsType) == -1);
        ViewUtils.setElementVisibilityByDNone(aR2RadioBtn, aShowR2);

        $(this.mContainer).find('[dis]').each((_index, elem) => {
            ViewUtils.setElementDisabled(elem,
                (elem.getAttribute('dis').split(',').indexOf(this.mEFLCalcMode) > -1));
        });

        if (true == pToCalculate) {
            this._calculate();
        }
    }
    //__________________________________________________________________________________________
    private _calculate() {
        switch (this.mEFLCalcMode) {
            case eEFLCalcMode.FOCUSES:
                this._calculateEFL();
                break;
            case eEFLCalcMode.n:
                this._calculateRefractiveIndex();
                break;
            case eEFLCalcMode.R1:
                this._calculateR1();
                break;
            case eEFLCalcMode.R2:
                this._calculateR2();
                break;
        }

        this._calcBFL_FFL_PrinciplePlanes();
        this._updateType();
        this.updateDrawing();
    }
    //__________________________________________________________________________________________
    private _calculateEFL() {
        let R1 = this.mElements.R1.input.actualValue;
        let R2 = this.mElements.R2.input.actualValue;
        let CT = this.mElements.CT.input.actualValue;
        let n = this.mElements.n.input.actualValue;

        let EFL = LensCalculator.calculateEFL(R1, R2, CT, n);
        this.mElements.EFL.input.actualValue = EFL;
        this.mElements.EFL.input.value = OP3DMathUtils.toFixed(EFL, this.mSettings.decimalPrecision);
        this.mElements.EFL.input.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _calculateR1() {
        let R2 = this.mElements.R2.input.actualValue;
        let CT = this.mElements.CT.input.actualValue;
        let EFL = this.mElements.EFL.input.actualValue;
        let n = this.mElements.n.input.actualValue;

        let R1 = LensCalculator.calculateR1ByEFL(R2, CT, EFL, n);
        this.mElements.R1.input.actualValue = R1;
        this.mElements.R1.input.value = OP3DMathUtils.toFixed(R1, this.mSettings.decimalPrecision);
        this.mElements.R1.input.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _calculateR2() {
        let R1 = this.mElements.R1.input.actualValue;
        let CT = this.mElements.CT.input.actualValue;
        let EFL = this.mElements.EFL.input.actualValue;
        let n = this.mElements.n.input.actualValue;

        let R2 = LensCalculator.calculateR2ByEFL(R1, CT, EFL, n);
        this.mElements.R2.input.actualValue = R2;
        this.mElements.R2.input.value = OP3DMathUtils.toFixed(R2, this.mSettings.decimalPrecision);
        this.mElements.R2.input.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _calculateRefractiveIndex() {
        let R1 = this.mElements.R1.input.actualValue;
        let R2 = this.mElements.R2.input.actualValue;
        let CT = this.mElements.CT.input.actualValue;
        let EFL = this.mElements.EFL.input.actualValue;

        let n = LensCalculator.calcRefIndexByEFL(R1, R2, CT, EFL);
        this.mElements.n.input.actualValue = n;
        this.mElements.n.input.value = OP3DMathUtils.toFixed(n, this.mSettings.decimalPrecision);
        this.mElements.n.input.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _calcBFL_FFL_PrinciplePlanes() {
        let R1 = this.mElements.R1.input.actualValue;
        let R2 = this.mElements.R2.input.actualValue;
        let CT = this.mElements.CT.input.actualValue;
        let n = this.mElements.n.input.actualValue;
        let EFL = this.mElements.EFL.input.actualValue;


        let BFL = EFL * (1 - (((n - 1) * CT) / (n * R1)));
        let FFL = -EFL * (1 + (((n - 1) * CT) / (n * R2)));

        let H2 = (BFL - EFL);
        let H1 = (EFL + FFL);

        this.mElements.BFL.input.value = OP3DMathUtils.toFixed(BFL, this.mSettings.decimalPrecision);
        this.mElements.BFL.input.actualValue = BFL;
        this.mElements.H2.input.value = OP3DMathUtils.toFixed(H2, this.mSettings.decimalPrecision);
        this.mElements.H2.input.actualValue = H2;

        this.mElements.FFL.input.value = OP3DMathUtils.toFixed(FFL, this.mSettings.decimalPrecision);
        this.mElements.FFL.input.actualValue = FFL;
        this.mElements.H1.input.value = OP3DMathUtils.toFixed(H1, this.mSettings.decimalPrecision);
        this.mElements.H1.input.actualValue = H1;

        this.mElements.BFL.input.dispatchEvent(new Event('blur'));
        this.mElements.H2.input.dispatchEvent(new Event('blur'));
        this.mElements.FFL.input.dispatchEvent(new Event('blur'));
        this.mElements.H1.input.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _onChangePermission() {
        let aCalcTypeParent = this._getPart('calc_type_parent');
        // let aOverlay = aCalcTypeParent.getElementsByClassName('pf_overlay')[0] as HTMLElement;
        // if (null == aOverlay) {
        //     aOverlay = document.createElement('div');
        //     aOverlay.classList.add('pf_overlay');
        // }

        let aPFButton = aCalcTypeParent.getElementsByClassName('pf_button')[0] as HTMLElement;
        if (null == aPFButton) {
            aPFButton = Op3dUtils.getPremiumButton(true);
        }

        let aR1RadioBtn = Op3dUtils.getElementInByAttr(this.mContainer, 'calc_type',
            eEFLCalcMode.R1);
        let aR2RadioBtn = Op3dUtils.getElementInByAttr(this.mContainer, 'calc_type',
            eEFLCalcMode.R2);
        let aRefIndexRadioBtn = Op3dUtils.getElementInByAttr(this.mContainer, 'calc_type',
            eEFLCalcMode.n);

        aR1RadioBtn.removeEventListener('change', this.mRBEvent);
        aR2RadioBtn.removeEventListener('change', this.mRBEvent);
        aRefIndexRadioBtn.removeEventListener('change', this.mRBEvent);

        let aIsFreeUser = (true == Op3dContext.USER_PERMISSION.isFreeUser);
        ViewUtils.setClassState(aR1RadioBtn.parentElement, 'pf', aIsFreeUser);
        ViewUtils.setClassState(aR2RadioBtn.parentElement, 'pf', aIsFreeUser);
        ViewUtils.setClassState(aRefIndexRadioBtn.parentElement, 'pf', aIsFreeUser);

        if (true == aIsFreeUser) {
            aCalcTypeParent.appendChild(aPFButton);
        } else {
            aR1RadioBtn.addEventListener('change', this.mRBEvent);
            aR2RadioBtn.addEventListener('change', this.mRBEvent);
            aRefIndexRadioBtn.addEventListener('change', this.mRBEvent);
            ViewUtils.removeFromParent(aPFButton);
        }
    }
    //__________________________________________________________________________________________
    private async _initMaterialSelect() {
        let aMWInput = this.mElements.material_wavelenght.input;
        let aMWInputParent = aMWInput.parentElement;
        this.mMaterialSelect = new CustomSelect(this._getPart('material_select'),
            {
                labelOptions: {
                    label: 'From material',
                    checkbox: true,
                    onCheckboxChange: (pVal) => {
                        let aFromMat = (true == pVal);
                        aMWInput.readOnly = !aFromMat;
                        ViewUtils.setClassState(aMWInputParent, 'grey_state', !aFromMat);

                        let aRefIndexInput = this.mElements.n.input;
                        let aRefIndexParent = aRefIndexInput.parentElement;

                        aRefIndexInput.readOnly = aFromMat;
                        ViewUtils.setClassState(aRefIndexParent, 'readonly', aFromMat);

                        this._changeState();
                    },
                    defaultCheckboxState: true
                },
                placeHolder: 'Choose material',
                search: true,
                onChange: () => this._onChangeMaterial()
            });

        let aMaterials = await Op3dContext.DATA_MANAGER.getMaterials();
        let aOptions = aMaterials.map((val) => {
            let aOption: iCustomSelectOption = {
                value: val.number_id,
                text: val.name,
                enable: true
            };

            return aOption;
        });

        this.mMaterialSelect.setOptions(aOptions, 'n-bk7_schott');
    }
    //__________________________________________________________________________________________
    private async _onChangeMaterial() {
        let aNumberID = this.mMaterialSelect.value;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({
            number_id: aNumberID
        });

        let aWavelenghtInput = this.mElements.material_wavelenght.input;
        let aIsConstant = (eMaterialEquationType.CONSTANT == aMaterialVO.parameters.type);
        ViewUtils.setClassState(aWavelenghtInput.parentElement, 'grey_state',
            aIsConstant);

        let aCurrW = this.mElements.material_wavelenght.input.actualValue;
        let aNewW = aCurrW;
        if (false == aIsConstant) {
            let aStartLambda = aMaterialVO.parameters.startLambda;
            let aEndLambda = aMaterialVO.parameters.endLambda;

            aNewW = OP3DMathUtils.clampValue(aCurrW, aStartLambda, aEndLambda);
            this.mElements.material_wavelenght.input.actualValue = aNewW;
            this.mElements.material_wavelenght.input.value = aNewW.toString();
        }


        let n = MaterialUtils.getN(aMaterialVO, aNewW);
        this.mElements.n.input.actualValue = n;
        this.mElements.n.input.value = n.toString();

        this._changeState();
    }
    //__________________________________________________________________________________________
    private updateDrawing() {
        if ((null == this.mMaterialSelect) || (null == this.mElements.EFL.input.actualValue) ||
            (null == this.mElements.BFL.input.actualValue) ||
            (null == this.mElements.FFL.input.actualValue)) {
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        FloatingScene.instance.update(aOpticsVO);

        let aMaterialName = this.mMaterialSelect.text;
        this.mGraph.update({
            data: {
                CA: this.mElements.CA.input.actualValue,
                CT: this.mElements.CT.input.actualValue,
                R1: this.mElements.R1.input.actualValue,
                R2: this.mElements.R2.input.actualValue,
                EFL: this.mElements.EFL.input.actualValue,
                BFL: this.mElements.BFL.input.actualValue,
                FFL: this.mElements.FFL.input.actualValue,
                n: this.mElements.n.input.actualValue,
                wavelenght: this.mElements.material_wavelenght.input.actualValue,
            },
            materialL: 'Air',
            materialName: (null != aMaterialName) ? aMaterialName : 'Customized',
            materialR: 'Air'
        });
    }
    //__________________________________________________________________________________________
    private _getGraphHash() {
        let aGraphEqHash: iHash<iHash<(pData: iHash<number>) => number>> = {};
        aGraphEqHash['n'] = {};
        aGraphEqHash['EFL'] = {};
        aGraphEqHash['FFL'] = {};
        aGraphEqHash['BFL'] = {};
        aGraphEqHash['R1'] = {};
        aGraphEqHash['R2'] = {};

        aGraphEqHash[eLensCalculator.EFL] = {};
        aGraphEqHash[eLensCalculator.EFL][eLensCalculator.n] =
            (pData) => LensCalculator.calculateEFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.EFL][eLensCalculator.R1] =
            (pData) => LensCalculator.calculateEFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.EFL][eLensCalculator.R2] =
            (pData) => LensCalculator.calculateEFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.EFL][eLensCalculator.CT] =
            (pData) => LensCalculator.calculateEFL(pData.R1, pData.R2, pData.CT, pData.n);

        aGraphEqHash[eLensCalculator.BFL] = {};
        aGraphEqHash[eLensCalculator.BFL][eLensCalculator.n] =
            (pData) => LensCalculator.calculateBFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.BFL][eLensCalculator.R1] =
            (pData) => LensCalculator.calculateBFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.BFL][eLensCalculator.R2] =
            (pData) => LensCalculator.calculateBFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.BFL][eLensCalculator.CT] =
            (pData) => LensCalculator.calculateBFL(pData.R1, pData.R2, pData.CT, pData.n);

        aGraphEqHash[eLensCalculator.FFL] = {};
        aGraphEqHash[eLensCalculator.FFL][eLensCalculator.n] =
            (pData) => LensCalculator.calculateFFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.FFL][eLensCalculator.R1] =
            (pData) => LensCalculator.calculateFFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.FFL][eLensCalculator.R2] =
            (pData) => LensCalculator.calculateFFL(pData.R1, pData.R2, pData.CT, pData.n);
        aGraphEqHash[eLensCalculator.FFL][eLensCalculator.CT] =
            (pData) => LensCalculator.calculateFFL(pData.R1, pData.R2, pData.CT, pData.n);

        aGraphEqHash[eLensCalculator.n] = {};
        aGraphEqHash[eLensCalculator.n][eLensCalculator.EFL] = (pData) =>
            LensCalculator.calcRefIndexByEFL(pData.R1, pData.R2, pData.CT, pData.EFL);
        aGraphEqHash[eLensCalculator.n][eLensCalculator.BFL] = (pData) =>
            LensCalculator.calcRefIndexByBFL(pData.R1, pData.R2, pData.CT, pData.BFL);
        aGraphEqHash[eLensCalculator.n][eLensCalculator.FFL] = (pData) =>
            LensCalculator.calcRefIndexByFFL(pData.R1, pData.R2, pData.CT, pData.FFL);

        aGraphEqHash[eLensCalculator.R1] = {};
        aGraphEqHash[eLensCalculator.R1][eLensCalculator.EFL] = (pData) =>
            LensCalculator.calculateR1ByEFL(pData.R2, pData.CT, pData.EFL, pData.n);
        aGraphEqHash[eLensCalculator.R1][eLensCalculator.BFL] = (pData) =>
            LensCalculator.calculateR1ByBFL(pData.R2, pData.CT, pData.BFL, pData.n);
        aGraphEqHash[eLensCalculator.R1][eLensCalculator.FFL] = (pData) =>
            LensCalculator.calculateR1ByFFL(pData.R2, pData.CT, pData.FFL, pData.n);

        aGraphEqHash[eLensCalculator.R2] = {};
        aGraphEqHash[eLensCalculator.R2][eLensCalculator.EFL] = (pData) =>
            LensCalculator.calculateR2ByEFL(pData.R1, pData.CT, pData.EFL, pData.n);
        aGraphEqHash[eLensCalculator.R2][eLensCalculator.BFL] = (pData) =>
            LensCalculator.calculateR2ByBFL(pData.R1, pData.CT, pData.BFL, pData.n);
        aGraphEqHash[eLensCalculator.R2][eLensCalculator.FFL] = (pData) =>
            LensCalculator.calculateR2ByFFL(pData.R1, pData.CT, pData.FFL, pData.n);

        aGraphEqHash[eLensCalculator.CT] = {};
        aGraphEqHash[eLensCalculator.CT][eLensCalculator.EFL] = (pData) =>
            LensCalculator.calcCTByEFL(pData.R1, pData.R2, pData.n, pData.EFL);
        aGraphEqHash[eLensCalculator.CT][eLensCalculator.BFL] = (pData) =>
            LensCalculator.calcCTByBFL(pData.R1, pData.R2, pData.n, pData.BFL);
        aGraphEqHash[eLensCalculator.CT][eLensCalculator.FFL] = (pData) =>
            LensCalculator.calcCTByFFL(pData.R1, pData.R2, pData.n, pData.FFL);

        return aGraphEqHash;
    }
    //__________________________________________________________________________________________
    private _onShowIN3D() {
        let aOpticsVO = this._getOpticsVO();
        FloatingScene.instance.open(aOpticsVO);
    }
    //__________________________________________________________________________________________
    private _onShowGraph() {
        let aMaterialName = this.mMaterialSelect.text;
        this.mGraph.open({
            data: {
                CA: this.mElements.CA.input.actualValue,
                CT: this.mElements.CT.input.actualValue,
                R1: this.mElements.R1.input.actualValue,
                R2: this.mElements.R2.input.actualValue,
                EFL: this.mElements.EFL.input.actualValue,
                BFL: this.mElements.BFL.input.actualValue,
                FFL: this.mElements.FFL.input.actualValue,
                n: this.mElements.n.input.actualValue,
                wavelenght: this.mElements.material_wavelenght.input.actualValue,
            },
            materialL: 'Air',
            materialName: (null != aMaterialName) ? aMaterialName : 'Customized',
            materialR: 'Air'
        });
    }
    //__________________________________________________________________________________________
    private _getOpticsVO() {
        let R1 = this.mElements.R1.input.actualValue;
        let R2 = this.mElements.R2.input.actualValue;
        let CT = this.mElements.CT.input.actualValue;
        let CA = this.mElements.CA.input.actualValue;

        let aOpticsVO: iOpticsVO = {
            name: 'Presented optics',
            number_id: '',
            permission: eDataPermission.PRIVATE,
            parameters: {
                baseShape: eBaseShape.CIRCULAR,
                coating: {},
                geometry: {
                    r1: R1,
                    r2: R2,
                    thickness_center: CT,
                    diameter: CA
                },
                materialID: Strings.TRANSPARENT_MATERIAL,
                shape: eOpticShape.SPHERICAL,
                subType: '',
                info: {
                    brand: '3DOptix',
                    weblinks: []
                }
            }
        }

        return aOpticsVO;
    }
    //__________________________________________________________________________________________
    private _onChangeLensType() {
        let R1 = this.mElements.R1.input.actualValue;
        let R2 = this.mElements.R2.input.actualValue;

        if (R2 == Infinity) {
            R2 = R1;
        }
        if (R1 == Infinity) {
            R1 = R2;
        }

        switch (this.mEFLOpticsType) {
            case eEFLOpticsType.CONVEX_CONVEX:
                R1 = Math.abs(R1);
                R2 = -Math.abs(R2);
                break;
            case eEFLOpticsType.CONCAVE_CONCAVE:
                R1 = -Math.abs(R1);
                R2 = Math.abs(R2);
                break;
            case eEFLOpticsType.POS_MENISCUS:
                R1 = Math.abs(R1);
                R2 = Math.abs(R2);

                if (R1 > R2) {
                    let r2 = R1;
                    R1 = R2;
                    R2 = r2;
                } else if (R1 == R2) {
                    R2 *= 2;
                }

                break;
            case eEFLOpticsType.POS_MENISCUS_OPP:
                R1 = -Math.abs(R1);
                R2 = -Math.abs(R2);

                if (R1 > R2) {
                    let r2 = R1;
                    R1 = R2;
                    R2 = r2;
                } else if (R1 == R2) {
                    R1 *= 2;
                }

                break;
            case eEFLOpticsType.NEG_MENISCUS:
                R1 = Math.abs(R1);
                R2 = Math.abs(R2);

                if (R1 < R2) {
                    let r2 = R1;
                    R1 = R2;
                    R2 = r2;
                }
                break;
            case eEFLOpticsType.NEG_MENISCUS_OPP:
                R1 = -Math.abs(R1);
                R2 = -Math.abs(R2);

                if (R1 < R2) {
                    let r2 = R1;
                    R1 = R2;
                    R2 = r2;
                }
                break;
            case eEFLOpticsType.PLANO_CONVEX:
                R1 = Math.abs(R1);
                R2 = Infinity;
                break;
            case eEFLOpticsType.PLANO_CONCAVE:
                R1 = -Math.abs(R1);
                R2 = Infinity;
                break;
            case eEFLOpticsType.PLANO_CONVEX_OPP:
                R2 = Math.abs(R2);
                R1 = Infinity;
                break;
            case eEFLOpticsType.PLANO_CONCAVE_OPP:
                R1 = Infinity;
                R2 = -Math.abs(R2);
                break;
        }

        this.mElements.R1.input.actualValue = R1;
        this.mElements.R2.input.actualValue = R2;
        this.mElements.R1.input.value = R1.toString();
        this.mElements.R2.input.value = R2.toString();
        this.mElements.R1.input.dispatchEvent(new Event('blur'));
        this.mElements.R2.input.dispatchEvent(new Event('blur'));

        this._calculateEFL();
        this._calcBFL_FFL_PrinciplePlanes();
    }
    //__________________________________________________________________________________________
    private _updateType() {
        let R1 = this.mElements.R1.input.actualValue;
        let R2 = this.mElements.R2.input.actualValue;

        let aSignR1 = Math.sign(R1);
        let aSignR2 = Math.sign(R2);

        let aType: eEFLOpticsType;

        if (Infinity == R1) {
            if (aSignR2 < 0) {
                aType = eEFLOpticsType.PLANO_CONCAVE_OPP;
            } else {
                aType = eEFLOpticsType.PLANO_CONVEX_OPP;
            }
        } else if (Infinity == R2) {
            if (aSignR1 < 0) {
                aType = eEFLOpticsType.PLANO_CONCAVE;
            } else {
                aType = eEFLOpticsType.PLANO_CONVEX;
            }
        } else if (aSignR1 == aSignR2) {
            if (aSignR1 < 0) {
                if (R1 < R2) {
                    aType = eEFLOpticsType.POS_MENISCUS_OPP;
                } else {
                    aType = eEFLOpticsType.NEG_MENISCUS_OPP;
                }
            } else {
                if (R1 < R2) {
                    aType = eEFLOpticsType.POS_MENISCUS;
                } else {
                    aType = eEFLOpticsType.NEG_MENISCUS;
                }
            }
        } else {
            if (aSignR1 < 0) {
                aType = eEFLOpticsType.CONCAVE_CONCAVE;
            } else {
                aType = eEFLOpticsType.CONVEX_CONVEX;
            }
        }

        $(this.mContainer).find('input[name="optics_type"]').each(
            (_index, input) => {
                (input as HTMLInputElement).checked = (input.getAttribute('opticstype') == aType) ||
                    (input.getAttribute('opp') == aType)
            });

        if (aType != this.mEFLOpticsType) {
            this.mEFLOpticsType = aType;
            this._changeState(false);
        }
    }
    //__________________________________________________________________________________________
}
