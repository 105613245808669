import { Op3dComponentBase } from "../Op3dComponentBase";

export interface iUploadExcelForm {
    title: string;
    callback: (pVal: File) => any;

    templateFunc: () => any;
}

export class UploadExcelForm extends Op3dComponentBase<iUploadExcelForm> {

    private static INSTANCE: UploadExcelForm;

    private mCallback: (pVal: File) => any;
    private mFileInput: HTMLInputElement;
    private mTemplateFunc: () => any;
    private mInputLabel: HTMLElement;

    //______________________________________________________________________________________________
    constructor() {
        super({
            container: UploadExcelForm._getCot(),
            skinPath: './skins/forms/upload_excel_form.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //______________________________________________________________________________________________
    public static get instance() {
        if (undefined === UploadExcelForm.INSTANCE) {
            UploadExcelForm.INSTANCE = new UploadExcelForm();
        }

        return UploadExcelForm.INSTANCE;
    }
    //______________________________________________________________________________________________
    private async _onSave() {
        this.mCallback(this.mFileInput.files[0]);
        this.close();
    }
    //______________________________________________________________________________________________
    protected _onOpen(pData: iUploadExcelForm): void {
        this.mInputLabel.innerHTML = 'Choose file';
        this._getPart('title').innerHTML = pData.title;
        this.mCallback = pData.callback;
        this.mTemplateFunc = pData.templateFunc;
    }
    //______________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    protected _initElements(): void {
        this.mFileInput = this._getPart('input') as HTMLInputElement;
        this.mInputLabel = this._getPart('file_name');
    }
    //______________________________________________________________________________________________
    private _downloadTemplate() {
        this.mTemplateFunc();
    }
    //______________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._getPart('save_btn').addEventListener('click', () => this._onSave());
        this._getPart('template_btn').addEventListener('click', () => this._downloadTemplate());
        this.mFileInput.addEventListener('change', () => this._onUploadFile());
    }
    //______________________________________________________________________________________________
    private _onUploadFile() {
        let aFile = this.mFileInput.files[0];
        let aName = aFile.name;
        this.mInputLabel.innerHTML = aName;
    }
    //______________________________________________________________________________________________
    private static _getCot() {
        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('fade');
        aDiv.classList.add('new_modal');
        aDiv.classList.add('h-auto');
        aDiv.classList.add('name_form_main');
        aDiv.style.top = ((window.innerHeight / 2) - 100) + 'px'
        aDiv.style.left = ((window.innerWidth / 2) - 400) + 'px'
        document.getElementById('forms').appendChild(aDiv);

        return aDiv;
    }
    //______________________________________________________________________________________________
}