﻿import { Op3dContext } from "../../../_context/Op3dContext";
import { iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iFace, iFaceDataNEW } from "../../../parts/PartInterfaces";
import { ViewUtils } from "../../ViewUtils";
import { uoSection } from "./uoSection";

export class esBasicInfo extends uoSection<iFace | iFaceDataNEW> {

    private static SKIN_PATH = './skins/forms/optics/es_basic_info.html'

    private mFace: iFace | iFaceDataNEW;
    private mName: iOP3DHTMLInputElement;
    private mChangeNameFunc: (pName: string) => void;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pChangeFaceNameFunc: (pName: string) => void) {
        super(pContainer, {
            skinPath: esBasicInfo.SKIN_PATH,
            title: 'Basic Info',
            isNewSkin: false,
            isPremiumSection: false
        });

        this.mChangeNameFunc = pChangeFaceNameFunc;
    }
    //__________________________________________________________________________________________
    // protected async _setData(pFace: iFace | iFaceDataNEW) {
    //     this.mFace = pFace;
    //     this.mName.prevValue = pFace.name;
    //     this.mName.value = pFace.name;
    //     this.mChangeNameFunc(this.mName.value);

    //     this.expand();
    // }
    //__________________________________________________________________________________________
    protected async _setData(pFace: iFace | iFaceDataNEW, pUseSampleForViewOnly?: { useSample: boolean, sample: string }) {
        if (pUseSampleForViewOnly != null && pUseSampleForViewOnly.useSample === true) {
            this.mFace = pFace;
            this.mName.prevValue = pUseSampleForViewOnly.sample;
            this.mName.value = pUseSampleForViewOnly.sample;
            ViewUtils.setElementDisabled(this.mContainer, true)
        } else {
            this.mFace = pFace;
            this.mName.prevValue = pFace.name;
            this.mName.value = pFace.name;
            this.mChangeNameFunc(this.mName.value);
        }

        this.expand();
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mName = Op3dUtils.getElementIn(this.mContainer,
            'face_name') as iOP3DHTMLInputElement;

        this.mName.addEventListener('change', () => this._onChangeName());
    }
    //__________________________________________________________________________________________
    private _onChangeName() {
        if ('' == this.mName.value) {
            this.mName.value = this.mName.prevValue;
        }
        this.mName.prevValue = this.mName.value;
        

        this.mChangeNameFunc(this.mName.value);
        Op3dContext.PARTS_MANAGER.updatePartsList(false);
    }
    //__________________________________________________________________________________________
    public saveName(){
        this.mFace.name = this.mName.value; // changes the info in the face object, remove it from there and do func saveName();
    }
    //__________________________________________________________________________________________

}
