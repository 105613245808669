import { EventManager } from "../../../oc/events/EventManager";
import { eLineItem, eStateToAnalysis, eUserType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { Strings } from "../../_context/Strings";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { iFullUserData, iReadonlyUserData, iUserLicense, iUserVO, iWLPreset } from "../../_context/_interfaces/SettingInterfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { iWavelengthData } from "../../parts/behaviors/LightSourceContext";
import { ServerContext } from "../../server/ServerContext";
import { SettingsContext } from "../../settings/SettingsContext";
import { ViewUtils } from "../../ui/ViewUtils";
import { AnalysisPortal } from "../../ui/analysis/AnalysisPortal";

export interface iUserDataExtended {
    cad_parts_limit: number,
    curr_cad_folder_size: number,
    limit: number,
    userData: iFullUserData
}

export interface iCadData {
    limit: number;
    current_usage: number;
}

export class UserVO {

    private mUserVO: iUserVO;
    private mReadonlyData: iReadonlyUserData;
    private mCompleteRegister: boolean = true;
    private mGPULimit: number;
    private mCadData: iCadData;

    constructor(pData: iUserDataExtended) {
        this.mGPULimit = pData.limit;
        this.mCadData = {
            current_usage: pData.curr_cad_folder_size,
            limit: pData.cad_parts_limit
        };

        this._initReadonlyData(pData.userData);
        this.set(pData.userData);
    }
    //______________________________________________________________________________________________
    public getUserInitials() {
        if (this.mUserVO.name != undefined) {
            let aFirst = this.mUserVO.name.first[0];
            let aLast = this.mUserVO.name.last[0];
            return `${aFirst}${aLast}`.toUpperCase();
        }
    }
    //______________________________________________________________________________________________
    public get cadData() {
        return this.mCadData;
    }
    //______________________________________________________________________________________________
    public get needsToCompeteRegister() {
        return this.mCompleteRegister;
    }
    //______________________________________________________________________________________________
    public get billing() {
        return this.mUserVO.billing;
    }
    //______________________________________________________________________________________________
    public get email(): string {
        return this.mReadonlyData.email;
    }
    //______________________________________________________________________________________________
    public isOwner(pOwner: string) {
        return this.mReadonlyData._id === pOwner;
    }
    //______________________________________________________________________________________________
    public getWLPresetByName(pPresetName: string) {
        let aPreset: iWLPreset<iWavelengthData>;
        let aPresets = this.mUserVO.parameters.presets;
        if (undefined !== aPresets) {
            let aWLPresets = aPresets.wavelengths;
            if (undefined !== aWLPresets) {
                for (let id in aWLPresets) {
                    if (pPresetName === aWLPresets[id].name) {
                        aPreset = aWLPresets[id];
                        break;
                    }
                }
            }
        }

        return aPreset;
    }
    //______________________________________________________________________________________________
    public getWLPresetByID(pId: string) {
        let aPreset: iWLPreset<iWavelengthData>;
        let aPresets = this.mUserVO.parameters.presets;
        if (undefined !== aPresets) {
            let aWLPresets = aPresets.wavelengths;
            if (undefined !== aWLPresets) {
                aPreset = aWLPresets[pId];
            }
        }

        return aPreset;
    }
    //______________________________________________________________________________________________
    public async addWLPreset(pPreset: iWLPreset<iWavelengthData>) {
        let aErrMsg: string;
        if (undefined === this.mUserVO.parameters.presets) {
            this.mUserVO.parameters.presets = {};
        }

        if (undefined === this.mUserVO.parameters.presets.wavelengths) {
            this.mUserVO.parameters.presets.wavelengths = {};
        }

        this.mUserVO.parameters.presets.wavelengths[pPreset.id] = pPreset;
        let aRes = await ServerContext.SERVER.editUserData(this.mUserVO);
        if (false === aRes.success) {
            aErrMsg = "Error saving preset";
        } else {
            EventManager.dispatchEvent(EventsContext.UPDATE_USER_SETTINGS, this);
        }

        return aErrMsg;
    }
    //______________________________________________________________________________________________
    public async deleteWLPreset(pPreset: iWLPreset<iWavelengthData>) {
        let aErrMsg: string;
        if ((undefined === this.mUserVO.parameters.presets) ||
            (undefined === this.mUserVO.parameters.presets.wavelengths)) {
            return;
        }

        delete this.mUserVO.parameters.presets.wavelengths[pPreset.id];
        let aRes = await ServerContext.SERVER.editUserData(this.mUserVO);
        if (false === aRes.success) {
            aErrMsg = "Error deleting preset";
        }

        return aErrMsg;
    }
    //______________________________________________________________________________________________
    public updateLicenseData(pLicense: iUserLicense, pLimit?: number, pCadPartsLimit?: number) {
        this.mUserVO.billing.license = pLicense;
        if (pLimit != null) {
            this.mGPULimit = pLimit;
        }
        if (pCadPartsLimit != null) {
            this.mCadData.limit = pCadPartsLimit;
        }
        const aTryPremiumBtn = document.getElementById('try-professional');
        ViewUtils.setElementVisibilityByDNone(aTryPremiumBtn, !(pLicense.line_items.length > 0));
    }
    //______________________________________________________________________________________________
    public getLicenseType() {
        if (this.mUserVO.billing.license.line_items.length == 0) {
            return "Basic";
        }

        switch (this.mUserVO.billing.license.line_items[0]) {
            case eLineItem.STANDARD:
                return "Standard";

            case eLineItem.ADVANCED:
                return "Advanced";

            case eLineItem.ACADEMIC:
                return "Academic Research";
        }
    }
    //______________________________________________________________________________________________
    public get userCanRunSimulation_GPULimit() {
        if (this.isBasicLicense) {
            return true;
        } else if ((this.mGPULimit * 3600000 - this.billing.license.transaction_time) > 0) {   // hours to milliseconds 
            return true;
        } else {
            return false;
        }
    }
    //______________________________________________________________________________________________
    public disableTransactionTime() {
        this.billing.license.transaction_time = this.mGPULimit * 3600000 + 100;
    }
    //______________________________________________________________________________________________
    private _initReadonlyData(pData?: iFullUserData) {
        if (null == pData) {
            this.mCompleteRegister = false;
            return;
        }

        //todo: check in widget, what about userVO for widget
        this.mReadonlyData = {
            _id: pData._id,
            email: pData.email,
            role: pData.role,
        };
        if (this.mReadonlyData.email == null ||
            this.mReadonlyData.role == null) {

            throw new Error("Missing user data");
        }
    }
    //______________________________________________________________________________________________
    public get userVO(): iUserVO {
        return this.mUserVO;
    }
    //______________________________________________________________________________________________

    //______________________________________________________________________________________________
    public set(pData: iUserVO) {
        if (null == pData) {
            return;
        }

        if ((null != this.mUserVO) && (null != pData.parameters.simulation) &&
            ((pData.parameters.simulation.amplitudeTreshold !=
                this.mUserVO.parameters.simulation.amplitudeTreshold))) {
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
                eStateToAnalysis.FROM_SCENE);
        }

        this.mUserVO = DataUtils.fillObjectAccordingTo(pData,
            SettingsContext.DEFAULT_USER_VO);

        this.mCompleteRegister = this._isMissingFields();

        ViewUtils.setClassForElement(document.body,
            Strings.BASIC_LICENSE,
            this.mUserVO.billing.license.line_items.length == 0);
    }
    //______________________________________________________________________________________________
    private _isMissingFields() {
        let aToFill = ((this.mUserVO.personal.company == null || this.mUserVO.personal.company == "") ||
            (this.mUserVO.personal.country == null || this.mUserVO.personal.country == "") ||
            (this.mUserVO.name.first == null || this.mUserVO.name.first == "") ||
            (this.mUserVO.name.last == null || this.mUserVO.name.last == ""))

        return aToFill;
    }
    //______________________________________________________________________________________________
    public get GPULimit() {
        return this.mGPULimit;
    }
    //______________________________________________________________________________________________
    public get simulationSettings() {
        return this.mUserVO.parameters.simulation;
    }
    //______________________________________________________________________________________________
    public get isAdmin() {
        return this.mReadonlyData.role == eUserType.ADMIN;
    }
    //______________________________________________________________________________________________
    // public get isPremium() {
    //     return this.mReadonlyData.role == eUserType.PREMIUM;
    // }
    //______________________________________________________________________________________________
    public get isBasicLicense() {
        if (this.mUserVO.billing.license.line_items.length == 0) {
            return true;
        }

        return false;
    }
    //______________________________________________________________________________________________
    public get isBasicUser() {
        return this.mReadonlyData.role == eUserType.BASIC;
    }
    //______________________________________________________________________________________________
    public get isEmployeeUser() {
        return this.mReadonlyData.role === eUserType.EMPLOYEE;
    }
    //______________________________________________________________________________________________
    public get id() {
        return this.mReadonlyData._id;
    }
    //______________________________________________________________________________________________
    public get presets() {
        return this.mUserVO.parameters.presets;
    }
    //______________________________________________________________________________________________
    public get wl_presets() {
        let aPresets: iHash<iWLPreset<iWavelengthData>>;
        if (undefined !== this.mUserVO.parameters.presets) {
            aPresets = this.mUserVO.parameters.presets.wavelengths;
        }

        return aPresets;
    }
    //______________________________________________________________________________________________
    // public get environmentSettings() {
    //     //TODO: return the current user settings
    //     return this.mUserVO.parameters.environment;
    // }
    //______________________________________________________________________________________________
}
