import { eDataPermission } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { eBaseShape, eOpticShape } from "../../../_context/OpticsContext";
import { iSurfaceDeformation, iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { eSmBaseShapeKind } from "../../../simulation/SimulationContext";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { iSurfaceShapeLightSource, esSurfaceShapeLightSource } from "../optics/esSurfaceShapeLightSource";
import { Optics3DPresenter, i3DPresenterParams } from "../tools/Optics3DPresenter";

/**
 * Array of elements
 */
export class aoeSurfaceShape extends Op3dComponentBase<iSurfaceShapeLightSource> {

    private static SKIN_PATH: string = "./skins/forms/aoe_surface_shape.html"
    private mEditShapeComponent: esSurfaceShapeLightSource;
    private mOnChange: () => void;
    private m3DPresenter: Optics3DPresenter;
    private mOpticPreviewUnavailabe: HTMLElement;

    constructor(pContainer: HTMLElement, pOnChange: () => void) {
        super({
            container: pContainer,
            skinPath: aoeSurfaceShape.SKIN_PATH
        });

        this.mOnChange = pOnChange
    }
    //__________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mOpticPreviewUnavailabe = this._getPart("optic-preview-unavailable", true);
        let aContainerSurfaceShape = this._getPart("aos-surface-shape-container", true);
        this.mEditShapeComponent = new esSurfaceShapeLightSource(aContainerSurfaceShape,
            (pDeformation: iSurfaceDeformation[]) => this._onUpdateSurface(pDeformation));

        let aSaveBtn = this._getPart("save-btn", true);
        aSaveBtn.addEventListener("click", () => this._onSave());

        let aOptions: i3DPresenterParams = {
            isFixedDiv: true,
            alwaysShowImage: false,
            showImage: false
        };

        this.m3DPresenter = new Optics3DPresenter(this._getPart("optic-preview-element", true), aOptions);
        this.mIsReady = true;
    }
    //__________________________________________________________________________
    private _onUpdateSurface(pDeformation: iSurfaceDeformation[]) {

        if (pDeformation != null && pDeformation.length > 0 && this.mData!.shape == eSmBaseShapeKind.RECTANGLE) {
            ViewUtils.setElementVisibilityByDNone(this.mOpticPreviewUnavailabe, true);
            ViewUtils.setElementVisibilityByDNone(this.m3DPresenter.container, false);
            return;
        }

        ViewUtils.setElementVisibilityByDNone(this.mOpticPreviewUnavailabe, false);
        ViewUtils.setElementVisibilityByDNone(this.m3DPresenter.container, true);

        let aDefo = null;
        if (pDeformation != null && pDeformation.length > 0) {
            aDefo = {};
            aDefo["front"] = pDeformation;
        }

        let aNewOpticsVO: iOpticsVO = {
            name: "Array of sources",
            number_id: "",
            permission: eDataPermission.PRIVATE,
            parameters: {
                info: {
                    brand: "",
                    weblinks: []
                },
                baseShape: this.mData!.shape == eSmBaseShapeKind.RECTANGLE ? eBaseShape.RECTANGULAR : eBaseShape.CIRCULAR,
                shape: this.mData!.shape == eSmBaseShapeKind.RECTANGLE ? eOpticShape.THIN_LENS : eOpticShape.CONIC,
                geometry: {
                    thickness_center: 0.002,
                    thickness: 0.002,
                    width: this.mData!.width,
                    height: this.mData!.height,
                    diameter: this.mData!.normalization_radius * 2,
                    r1: 0,
                    r2: 0,
                    deformation: aDefo,
                },
                materialID: "",
                coating: {}
            }
        }


        this.m3DPresenter.onRefreshOptic(aNewOpticsVO, {
            fitToZoom: true,
            highlightedFaceName: "front"
        });

        this.m3DPresenter.toUpdate = true;
    }
    //__________________________________________________________________________
    private _onSave() {
        this.mOnChange();
        this.close();
    }
    //__________________________________________________________________________
    public getData() {
        return this.mEditShapeComponent.getData();
    }
    //__________________________________________________________________________
    protected async _prepareForm() {
        while (this.mIsReady == false || this.mEditShapeComponent.isReady == false) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________
    protected _onClose(_pData?: any): void {
        this.m3DPresenter.toUpdate = false
    }
    //__________________________________________________________________________
    protected _onOpen(pData: iSurfaceShapeLightSource): void {
        this.mEditShapeComponent.setData(pData);
        this.mData = pData;
        this.m3DPresenter.toUpdate = true;
        this._onUpdateSurface(this.mEditShapeComponent.getData());
    }
    //__________________________________________________________________________

}