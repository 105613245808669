import { Op3dContext } from "../../_context/Op3dContext";
import { iClientPoint } from "../../_context/_interfaces/Interfaces";
import { ViewUtils } from "../ViewUtils";

export enum eTextAlignment {
    LEFT,
    CENTER,
    RIGHT
};

export interface iTooltipOptions {
    /**
     * @description If true - max tooltip width will be 400px. Otherwise - 200px.
     */
    wide?: boolean;

    textAlign?: eTextAlignment;
}

export class op3dTooltip {

    private mContainer: HTMLElement;
    private mTooltipText: HTMLElement;

    //__________________________________________________________________________________________
    constructor() {
        this._init();
    }
    //__________________________________________________________________________________________
    private _init() {
        this.mContainer = document.createElement('div');
        this.mTooltipText = document.createElement('span');
        this.mContainer.classList.add('black-tooltip');
        this.mContainer.appendChild(this.mTooltipText);
        this.mContainer.style.pointerEvents = "none";
    }
    //__________________________________________________________________________________________
    public static addTooltipToElement(pElement: HTMLElement, pText: string) {
        pElement.addEventListener('mouseover', () => {
            let aBB = pElement.getBoundingClientRect();
            Op3dContext.TOOLTIP.show({
                clientX: (aBB.left + aBB.width),
                clientY: (aBB.top + aBB.height)
            }, pText, {
                textAlign: eTextAlignment.LEFT,
                wide: true
            });
        });

        pElement.addEventListener('mouseleave', () => Op3dContext.TOOLTIP.hide());
    }
    //__________________________________________________________________________________________
    private _setOptions(pTooltipOptions: iTooltipOptions) {
        if (true == pTooltipOptions.wide) {
            this.mContainer.classList.add('wide');
        }

        if (null != pTooltipOptions.textAlign) {
            ViewUtils.setTextAlign(this.mTooltipText, pTooltipOptions.textAlign);
        }
    }
    //__________________________________________________________________________________________
    public show(e: iClientPoint, pText: string, pTooltipOptions?: iTooltipOptions) {
        this.mContainer.style.left = e.clientX + 'px';
        this.mContainer.style.top = e.clientY + 'px';

        if (null != pTooltipOptions) {
            this._setOptions(pTooltipOptions);
        }

        this.mTooltipText.innerHTML = pText;
        document.body.appendChild(this.mContainer);

        let aBB = this.mContainer.getBoundingClientRect();
        let aDeltaWidthRight = (aBB.right - window.innerWidth);
        if (aDeltaWidthRight > 0) {
            this.mContainer.style.left = (e.clientX - aDeltaWidthRight) + 'px';
        }

        ViewUtils.setClassShowState(this.mTooltipText, true);
    }
    //__________________________________________________________________________________________
    public hide() {
        let aTooltipParent = this.mContainer.parentElement;
        if (null != aTooltipParent) {
            aTooltipParent.removeChild(this.mContainer);
        }
        this.mContainer.classList.remove('wide');

        ViewUtils.setClassShowState(this.mTooltipText, false);
    }
    //__________________________________________________________________________________________
}