import Complex from "complex.js";
import { eNewPolarizationType, ePolarizationType, ePolarizationFields } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iPolarizationSectionData, iPolarizationData, iJonesVector } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { iCircularPlaneWaveData, iRectanglePlaneWaveData, iEllipticalPlaneWaveData } from "../../../parts/behaviors/LightSourceContext";
import { eSmPlaneWaveType } from "../../../simulation/SimulationContext";
import { ViewUtils } from "../../ViewUtils";
import { PartInfoSection } from "../PartInfoSection";
import { newNumberInputElement } from "../_components/NewNumberInputElement";
import { piSpatialInformationSection } from "./piSpatialInformationSection";
import { Popup } from "../../forms/Popup";

interface iPolarizationInputElements {
    polarizationTypeSelector?: HTMLSelectElement;
    general_input: {
        orientationAngle?: newNumberInputElement;
        eccentricity?: newNumberInputElement;
        ellipticity?: newNumberInputElement;
        extinctionRatio?: newNumberInputElement;
        polarizationRatio?: newNumberInputElement;

    },
    jones_vector_input: {
        principalX?: HTMLInputElement;
        principalY?: HTMLInputElement;
        orthogonalX?: HTMLInputElement;
        orthogonalY?: HTMLInputElement;
    },
    stokes_input: {
        s0?: newNumberInputElement;
        s1?: newNumberInputElement;
        s2?: newNumberInputElement;
        s3?: newNumberInputElement;
        phaseShift?: newNumberInputElement;
        auxiliaryAngle?: newNumberInputElement;
    }
}

export class piPolarizationSection extends PartInfoSection<iPolarizationSectionData> {
    private defaultLinearValues: iPolarizationData = {
        type: eNewPolarizationType.LINEAR,
        general: {
            polarization_angle: 45,
            eccentricity: 0,
            ellipticity: 0,
            extinction_ratio: 20,
            polarization_ratio: 100,
        },
        jones_vector: {
            principal_x: '0.70711',
            principal_y: '0.70711',
            orthogonal_x: '-0.70711',
            orthogonal_y: '0.70711',
        },
        stokes: {
            stoke_0: 0,
            stoke_1: 0,
            stoke_2: 0,
            stoke_3: 0,
            phase_shift: 30,
            auxiliary_angle: 0

        }
    }
    private defaultCircularLeftValues: iPolarizationData = {
        type: eNewPolarizationType.CIRCULAR_LEFT,
        general: {
            polarization_angle: 0,
            eccentricity: 0,
            ellipticity: 0,
            extinction_ratio: 20,
            polarization_ratio: 100,
        },
        jones_vector: {
            principal_x: '0.70711',
            principal_y: '-0.70711i',
            orthogonal_x: '0.70711',
            orthogonal_y: '0.70711i',
        },
        stokes: {
            stoke_0: 0,
            stoke_1: 0,
            stoke_2: 0,
            stoke_3: 0,
            phase_shift: 0,
            auxiliary_angle: 0
        }
    }
    private defaultCircularRightValues: iPolarizationData = {
        type: eNewPolarizationType.CIRCULAR_RIGHT,
        general: {
            polarization_angle: 0,
            eccentricity: 0,
            ellipticity: 0,
            extinction_ratio: 20,
            polarization_ratio: 100,
        },
        jones_vector: {
            principal_x: '0.70711',
            principal_y: '0.70711i',
            orthogonal_x: '0.70711',
            orthogonal_y: '-0.70711i',
        },
        stokes: {
            stoke_0: 0,
            stoke_1: 0,
            stoke_2: 0,
            stoke_3: 0,
            phase_shift: 0,
            auxiliary_angle: 0
        }
    }
    private defaultEllipticalValues: iPolarizationData = {
        type: eNewPolarizationType.ELLIPTICAL,
        general: {
            polarization_angle: 45,
            eccentricity: 0.9,
            ellipticity: 45,
            extinction_ratio: 20,
            polarization_ratio: 100,
        },
        jones_vector: {
            principal_x: '-0.70711i',
            principal_y: '0.70711i',
            orthogonal_x: '-0.70711i',
            orthogonal_y: '-0.70711i',
        },
        stokes: {
            stoke_0: 0,
            stoke_1: 0,
            stoke_2: 0,
            stoke_3: 0,
            phase_shift: 0,
            auxiliary_angle: 0
        }
    }
    private defaultnUserDefinedJonesValues: iPolarizationData = {
        type: eNewPolarizationType.USER_DEFINED_JONES,
        general: {
            polarization_angle: 0,
            eccentricity: 0,
            ellipticity: 45,
            extinction_ratio: 20,
            polarization_ratio: 100,
        },
        jones_vector: {
            principal_x: '0.70711',
            principal_y: '0.70711i',
            orthogonal_x: '0.70711',
            orthogonal_y: '-0.70711i',
        },
        stokes: {
            stoke_0: 0,
            stoke_1: 0,
            stoke_2: 0,
            stoke_3: 0,
            phase_shift: 0,
            auxiliary_angle: 0
        }
    }
    private defaultnUserDefinedStokesValues: iPolarizationData = {
        type: eNewPolarizationType.USER_DEFINED_STOKES,
        general: {
            polarization_angle: 0,
            eccentricity: 0,
            ellipticity: 45,
            extinction_ratio: 20,
            polarization_ratio: 100,
        },
        jones_vector: {
            principal_x: '0.70711',
            principal_y: '0.70711i',
            orthogonal_x: '0.70711',
            orthogonal_y: '-0.70711i',
        },
        stokes: {
            stoke_0: 0,
            stoke_1: 0,
            stoke_2: 0,
            stoke_3: 0,
            phase_shift: 0,
            auxiliary_angle: 0
        }
    }
    private mParams: {
        onChange: (pPolarization: ePolarizationType) => void;
        onCalculationChange: () => void;
    };
    private mCurrentState: ePolarizationType;

    private mInputElements: iPolarizationInputElements = {
        polarizationTypeSelector: null,
        general_input: {},
        jones_vector_input: {},
        stokes_input: {}
    };
    private mDefaultValuesForTypes: Array<iPolarizationData> = [
        this.defaultLinearValues, this.defaultCircularLeftValues,
        this.defaultCircularRightValues, this.defaultEllipticalValues
    ]
    private mElementsDiv: HTMLElement;
    private mCurrentPolarizationType: eNewPolarizationType = eNewPolarizationType.LINEAR;
    private mCurrPolarizationData: iPolarizationData;

    constructor(pContainer: HTMLElement, pParams: { onChange: (pPolarization: ePolarizationType) => void, onCalculationChange: () => void }) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_polarization_section.html'
        });
        this.mParams = pParams;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        $(this.mContainer).find('[name="polarization-source"]').each((_index, element) => {
            element.addEventListener("click",
                () => this.onPolarizationChanged((element as HTMLInputElement).value as ePolarizationType));
            if ((element as HTMLInputElement).checked) {
                this.mCurrentState = (element as HTMLInputElement).value as ePolarizationType;
            }
        });
        this.mElementsDiv = this._getPart('input_elements_container');
        this.mInputElements.polarizationTypeSelector = this._getPart('polarization_type') as HTMLSelectElement;
        this.mInputElements.polarizationTypeSelector.addEventListener('change', () => {
            this.mCurrentPolarizationType = this.mInputElements.polarizationTypeSelector.value as eNewPolarizationType;
            this._fillPolarizationData();
        })
        this.mInputElements.general_input.orientationAngle = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("orientation_angle", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Orientation angle",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 45,
            callback: () => {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.POLARIZATION_ANGLE);
            },
            range: {
                min: -180,
                max: 180
            },
            unitName: 'Degrees',
            unitElementWidth: 30

        });
        this.mInputElements.general_input.eccentricity = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("eccentricity", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Eccentricity",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 0.9,
            callback: () => {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.ECCENTRICITY)
            },
            range: {
                min: 0,
                max: 1
            },
        });
        this.mInputElements.general_input.ellipticity = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("ellipticity", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Ellipticity angle",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 45,
            callback: () => {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.ELLIPTICITY)
            },
            range: {
                // min: -(Math.PI / 4),
                // max: (Math.PI / 4)
                min: -45,
                max: 45
            },
            unitName: 'Degrees',
            unitElementWidth: 30
        });
        this.mInputElements.general_input.extinctionRatio = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("extinction_ratio", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Extinction ratio",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 20,
            callback: () => {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.EXTINCTION_RATIO)
            },
            range: {
                min: 0,
                max: 100
            },
            unitName: 'dB',
            unitElementWidth: 30
        });
        this.mInputElements.general_input.polarizationRatio = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("polarization_ratio", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Polarization ratio",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 100,
            callback: () => {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.POLARIZATION_RATIO)
            },
            range: {
                min: 0,
                max: 10000000
            },
            unitName: 'Unitless',
            unitElementWidth: 30
        });
        this.mInputElements.jones_vector_input.principalX = this._getPart("principal_x_input", true) as HTMLInputElement;
        this._getPart("principal_x", true).appendChild(this.mInputElements.jones_vector_input.principalX);
        this.mInputElements.jones_vector_input.principalX.addEventListener('change', () => {
            const aValid = this.complexNumStringValidator(this.mInputElements.jones_vector_input.principalX.value);
            if (aValid) {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.PRINCIPAL_X);
            } else {
                this.mInputElements.jones_vector_input.principalX.value = this.mCurrPolarizationData.jones_vector.principal_x;
            }
        })
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.principalX, true)

        this.mInputElements.jones_vector_input.principalY = this._getPart("principal_y_input", true) as HTMLInputElement;
        this._getPart("principal_y", true).appendChild(this.mInputElements.jones_vector_input.principalY);
        this.mInputElements.jones_vector_input.principalY.addEventListener('change', () => {
            const aValid = this.complexNumStringValidator(this.mInputElements.jones_vector_input.principalX.value);
            if (aValid) {
                this.calculationManager(this.mCurrPolarizationData.type, ePolarizationFields.PRINCIPAL_Y)
            } else {
                this.mInputElements.jones_vector_input.principalX.value = this.mCurrPolarizationData.jones_vector.principal_y;
            }
        })
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.principalY, true)


        this.mInputElements.jones_vector_input.orthogonalX = this._getPart("orthogonal_x_input", true) as HTMLInputElement;
        this._getPart("orthogonal_x", true).appendChild(this.mInputElements.jones_vector_input.orthogonalX);
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.orthogonalX, true)

        this.mInputElements.jones_vector_input.orthogonalY = this._getPart("orthogonal_y_input", true) as HTMLInputElement;
        this._getPart("orthogonal_y", true).appendChild(this.mInputElements.jones_vector_input.orthogonalY);
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.orthogonalY, true)

        this.mInputElements.stokes_input.s0 = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("s_0", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "S0",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 1,
            callback: () => {
            },
            range: {
                min: -100,
                max: 100
            },
        });
        this.mInputElements.stokes_input.s1 = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("s_1", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "S1",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 0.5,
            callback: () => {
                this.calculationStokeHandler(this.mCurrPolarizationData.stokes.stoke_1, ePolarizationFields.STOKE_1, this.mInputElements.stokes_input.s1)
            },
            range: {
                min: -100,
                max: 100
            },
        });
        this.mInputElements.stokes_input.s2 = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("s_2", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "S2",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 0.37,
            callback: () => {
                this.calculationStokeHandler(this.mCurrPolarizationData.stokes.stoke_2, ePolarizationFields.STOKE_2, this.mInputElements.stokes_input.s2)
            },
            range: {
                min: -100,
                max: 100
            },
        });
        this.mInputElements.stokes_input.s3 = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("s_3", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "S3",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 0.45,
            callback: () => {
                this.calculationStokeHandler(this.mCurrPolarizationData.stokes.stoke_3, ePolarizationFields.STOKE_3, this.mInputElements.stokes_input.s3)
            },
            range: {
                min: -100,
                max: 100
            },
        });
        this.mInputElements.stokes_input.phaseShift = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("phase_shift", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Phase shift",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 30,
            callback: () => { },
            range: {
                min: 0,
                max: 100
            },
        });
        this.mInputElements.stokes_input.auxiliaryAngle = piSpatialInformationSection.getNewNumberInputElement({
            container: this._getPart("auxiliary_angle", true),
            worldToUnit: 1,
            hasArrows: false,
            label: "Auxiliary angle",
            toFixed: 5,
            step: 0.00001,
            defaultValue: 30,
            callback: () => { },
            range: {
                min: 0,
                max: 100
            },
        });
        this._fillPolarizationData();
    }
    //__________________________________________________________________________________________
    private _setPolarization(pPolarization: iPolarizationSectionData) {
        $(this.mContainer).find('[name="polarization-source"]').each((_index, element) => {
            (element as HTMLInputElement).checked = pPolarization.polarizationState == (element as HTMLInputElement).value;
        });
        this.mCurrentState = pPolarization.polarizationState;
        if (pPolarization.polarizationState == ePolarizationType.POLARIZED) {

            let pData = pPolarization.polarizationData != null ? pPolarization.polarizationData : null;
            this._fillPolarizationData(pData);
        }
        ViewUtils.setElementVisibilityByDNone(this.mElementsDiv, (pPolarization.polarizationState == ePolarizationType.POLARIZED));
    }
    //__________________________________________________________________________________________
    private onPolarizationChanged(pPolarization: ePolarizationType) {
        this.mCurrentState = pPolarization;
        this.mParams.onChange(pPolarization);
        ViewUtils.setElementVisibilityByDNone(this.mElementsDiv, (pPolarization == ePolarizationType.POLARIZED));
    }
    //__________________________________________________________________________________________
    protected _fillSection(pPolarization: iPolarizationSectionData): void {
        this._setPolarization(pPolarization);
    }
    //__________________________________________________________________________________________
    protected _fillPolarizationData(pData?: iPolarizationData): void {
        this.mCurrentPolarizationType = pData != null ? pData.type : this.mInputElements.polarizationTypeSelector.value as eNewPolarizationType;
        for (let i = 0; i < this.mInputElements.polarizationTypeSelector.options.length; i++) {
            if (this.mCurrentPolarizationType == this.mInputElements.polarizationTypeSelector.options[i].value) {
                this.mInputElements.polarizationTypeSelector.selectedIndex = this.mInputElements.polarizationTypeSelector.options[i].index;
            }
        }
        if (this.mCurrentPolarizationType == eNewPolarizationType.USER_DEFINED_JONES) {
            this.mCurrPolarizationData = pData != null ? pData : DataUtils.getObjectCopy(this.defaultnUserDefinedJonesValues);
            this._showFieldsInUserDefinedMode(this.mCurrentPolarizationType);
        } else if (this.mCurrentPolarizationType == eNewPolarizationType.USER_DEFINED_STOKES) {
            this.mCurrPolarizationData = pData != null ? pData : DataUtils.getObjectCopy(this.defaultnUserDefinedStokesValues);
            this._showFieldsInUserDefinedMode(this.mCurrentPolarizationType);
        } else {
            let aItem = this.mDefaultValuesForTypes.find(item => {
                return item.type == this.mCurrentPolarizationType;
            })
            this.mCurrPolarizationData = pData != null ? pData : DataUtils.getObjectCopy(aItem);
            this._showFieldsInDefaultMode(this.mCurrentPolarizationType);
        }
        /** Try is needed to not to throw exception on load.  */
        try {
            this.mCurrPolarizationData.stokes.stoke_0 = this.stoke0();
            this.mCurrPolarizationData.stokes.phase_shift = this.calculatePhaseShift(this.mCurrPolarizationData.jones_vector.principal_x, this.mCurrPolarizationData.jones_vector.principal_y)
            this.mCurrPolarizationData.stokes.auxiliary_angle = this.calculateAuxuliaryAngle(this.mCurrPolarizationData.jones_vector.principal_x, this.mCurrPolarizationData.jones_vector.principal_y)

            if (this.mCurrentPolarizationType === eNewPolarizationType.USER_DEFINED_JONES ||
                this.mCurrentPolarizationType === eNewPolarizationType.LINEAR) {
                let aEllipticity = this.mCurrPolarizationData.general.ellipticity
                let aPolarizAngleRad = this.degreeToRadian(this.mCurrPolarizationData.general.polarization_angle, 5);
                ({
                    s1: this.mCurrPolarizationData.stokes.stoke_1,
                    s2: this.mCurrPolarizationData.stokes.stoke_2,
                    s3: this.mCurrPolarizationData.stokes.stoke_3
                } = this.calculateStokesFromEllipticity(aEllipticity, aPolarizAngleRad))
            } else if (this.mCurrentPolarizationType != eNewPolarizationType.USER_DEFINED_STOKES) {
                ({ s1: this.mCurrPolarizationData.stokes.stoke_1, s2: this.mCurrPolarizationData.stokes.stoke_2, s3: this.mCurrPolarizationData.stokes.stoke_3 }
                    =
                    this.calculateStokesFromJones(
                        this.mCurrPolarizationData.jones_vector.principal_x,
                        this.mCurrPolarizationData.jones_vector.principal_y,
                        this.stoke0(),
                        this.mCurrPolarizationData.stokes.phase_shift
                    ))
            }
        } catch (e) { };

        this.updateInputs();
    }
    //__________________________________________________________________________________________
    private updateInputs() {
        /** General section */
        this.mInputElements.general_input.orientationAngle.value = this.mCurrPolarizationData.general.polarization_angle;
        this.mInputElements.general_input.polarizationRatio.value = this.mCurrPolarizationData.general.polarization_ratio;
        this.mInputElements.general_input.eccentricity.value = this.mCurrPolarizationData.general.eccentricity;
        this.mInputElements.general_input.extinctionRatio.value = this.mCurrPolarizationData.general.extinction_ratio;
        this.mInputElements.general_input.ellipticity.value = this.mCurrPolarizationData.general.ellipticity;
        /** Jones section */
        this.mInputElements.jones_vector_input.principalX.value = this.mCurrPolarizationData.jones_vector.principal_x;
        this.mInputElements.jones_vector_input.principalY.value = this.mCurrPolarizationData.jones_vector.principal_y;
        this.mInputElements.jones_vector_input.orthogonalX.value = this.mCurrPolarizationData.jones_vector.orthogonal_x;
        this.mInputElements.jones_vector_input.orthogonalY.value = this.mCurrPolarizationData.jones_vector.orthogonal_y;
        /** Stoke section */
        this.mInputElements.stokes_input.s0.value = this.mCurrPolarizationData.stokes.stoke_0;
        this.mInputElements.stokes_input.s1.value = this.mCurrPolarizationData.stokes.stoke_1;
        this.mInputElements.stokes_input.s2.value = this.mCurrPolarizationData.stokes.stoke_2;
        this.mInputElements.stokes_input.s3.value = this.mCurrPolarizationData.stokes.stoke_3;
        this.mInputElements.stokes_input.phaseShift.value = this.mCurrPolarizationData.stokes.phase_shift;
        this.mInputElements.stokes_input.auxiliaryAngle.value = this.mCurrPolarizationData.stokes.auxiliary_angle;
    }
    //__________________________________________________________________________________________
    protected _showFieldsInDefaultMode(pType: eNewPolarizationType): void {
        for (const key in this.mInputElements.general_input) {
            ViewUtils.setElementVisibilityByDNone(this.mInputElements.general_input[`${key}`].container, true);
        }
        for (const key in this.mInputElements.stokes_input) {
            this.mInputElements.stokes_input[`${key}`].enable = false;
        }
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.principalX, true);
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.principalY, true);
        if (pType == eNewPolarizationType.CIRCULAR_LEFT ||
            pType == eNewPolarizationType.CIRCULAR_RIGHT || pType == eNewPolarizationType.LINEAR) {
            if (this.mCurrentPolarizationType != eNewPolarizationType.LINEAR) {
                ViewUtils.setElementVisibilityByDNone(this.mInputElements.general_input.orientationAngle.container, false);
            }
            ViewUtils.setElementVisibilityByDNone(this.mInputElements.general_input.eccentricity.container, false);
            ViewUtils.setElementVisibilityByDNone(this.mInputElements.general_input.ellipticity.container, false);
        }
    }
    //__________________________________________________________________________________________
    protected _showFieldsInUserDefinedMode(pType: eNewPolarizationType): void {
        for (const key in this.mInputElements.general_input) {
            if (key !== 'polarizationRatio' && key !== 'extinctionRatio') {
                ViewUtils.setElementVisibilityByDNone(this.mInputElements.general_input[`${key}`].container, false);
            }
        }
        let aDisabelPrincipalXY = false;
        switch (pType) {
            case eNewPolarizationType.USER_DEFINED_JONES:
                for (const key in this.mInputElements.stokes_input) {
                    this.mInputElements.stokes_input[`${key}`].enable = false;
                }
                break;
            case eNewPolarizationType.USER_DEFINED_STOKES:
                for (const key in this.mInputElements.jones_vector_input) {
                    this.mInputElements.jones_vector_input[`${key}`].enable = false;
                }
                aDisabelPrincipalXY = true;
                this.mInputElements.stokes_input.s1.enable = true;
                this.mInputElements.stokes_input.s2.enable = true;
                this.mInputElements.stokes_input.s3.enable = true;
        }
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.principalX, aDisabelPrincipalXY);
        ViewUtils.setElementDisabled(this.mInputElements.jones_vector_input.principalY, aDisabelPrincipalXY);
    }
    // __________________________________________________________________________________________
    public _getData(): iPolarizationSectionData {
        if (this.mCurrentState == ePolarizationType.UNPOLARIZED) {
            return {
                polarizationState: this.mCurrentState
            };
        } else {
            return {
                polarizationState: this.mCurrentState,
                polarizationType: this.mCurrentPolarizationType,
                polarizationData: this.mCurrPolarizationData
            };
        }
    }
    //__________________________________________________________________________________________
    public fillSection(pPolarization: iPolarizationSectionData) {
        if (typeof pPolarization == 'string') {
            pPolarization = { polarizationState: ePolarizationType.UNPOLARIZED };
        }
        if (pPolarization.polarizationState == ePolarizationType.UNPOLARIZED) {
            this.mCurrPolarizationData = DataUtils.getObjectCopy(this.defaultLinearValues);
            this.mCurrentPolarizationType = eNewPolarizationType.LINEAR;
            this.mInputElements.polarizationTypeSelector.selectedIndex = 0;
        }
        this._fillSection(pPolarization);
    }
    //__________________________________________________________________________________________
    public static calculateExtinctionRatioByPolarization(pPolarizationRatio: number) {
        let aResult = 10 * Math.log10(pPolarizationRatio);

        return aResult;
    }
    //__________________________________________________________________________________________
    public static calculatePolarizationRatioByExtinction(pExtinctionRatio: number) {
        const aLogValue = pExtinctionRatio / 10;
        const aResult = 10 ** aLogValue;

        return aResult;
    }
    //__________________________________________________________________________________________
    private jonesVectorLinear(pPolarizationAngle: number): iJonesVector {
        let aPrincipalJx = `${this.roundUpToNDigit(Math.cos(pPolarizationAngle))}`;
        let aPrincipalJy = `${this.roundUpToNDigit(Math.sin(pPolarizationAngle))}`;
        let aOrthogonalJx = `${this.roundUpToNDigit(-Math.sin(pPolarizationAngle))}`;
        let aOrthogonalJy = `${this.roundUpToNDigit(Math.cos(pPolarizationAngle))}`;

        return { principalJx: aPrincipalJx, principalJy: aPrincipalJy, orthogonalJx: aOrthogonalJx, orthogonalJy: aOrthogonalJy };
    }
    //__________________________________________________________________________________________
    private jonesVectorCircularLeftRight(pCircularRight: boolean): iJonesVector { // true - right, false - left
        let aSignPrincipalJy = '-';
        let aSignOrthogonalJy = '';

        if (pCircularRight) {
            aSignPrincipalJy = '';
            aSignOrthogonalJy = '-';
        }

        let aPrincipalJx = Complex(`${this.roundUpToNDigit(1 / Math.sqrt(2))}`);
        let aPrincipalJy = Complex(`${aSignPrincipalJy}${this.roundUpToNDigit(1 / Math.sqrt(2))}i`);
        let aOrthogonalJx = Complex(`${this.roundUpToNDigit(1 / Math.sqrt(2))}`);
        let aOrthogonalJy = Complex(`${aSignOrthogonalJy}${this.roundUpToNDigit(1 / Math.sqrt(2))}i`);

        return { principalJx: aPrincipalJx.toString(), principalJy: aPrincipalJy.toString(), orthogonalJx: aOrthogonalJx.toString(), orthogonalJy: aOrthogonalJy.toString() };
    }
    //__________________________________________________________________________________________

    /**   Using ellipticity angle  */
    private semiMajorAxisFromEllipticityAngle(pEllipticity: number) {
        let aResult = this.roundUpToNDigit(1 / (Math.sqrt(1 + (Math.tan(pEllipticity) ** 2))));

        return aResult;
    }
    //__________________________________________________________________________________________
    private semiMinorAxisFromEllipticityAngle(pEllipticity: number) {
        let aResult = this.roundUpToNDigit(this.semiMajorAxisFromEllipticityAngle(pEllipticity) * Math.tan(pEllipticity));

        return aResult;
    }

    //__________________________________________________________________________________________
    private ellipticityAngleFromStoke(pStoke1: number, pStoke2: number, pStoke3: number) {
        let aResult = 0.5 * Math.atan2(pStoke3, Math.sqrt(pStoke1 ** 2 + pStoke2 ** 2));
        return aResult;
    }
    //__________________________________________________________________________________________
    private polarizationAngleFromStoke(pStoke1: number, pStoke2: number) {
        let aResult = 0.5 * Math.atan2(pStoke2, pStoke1);
        return aResult;
    }
    //__________________________________________________________________________________________
    private polarizationAngleFromEllipticalAxis(pMajorAxisA: number, pMinorAxisB: number, pEllipticity: number) {
        if (Math.abs(this.radianToDegree(pEllipticity)) - 45 >= 0.00001) {
            return 0
        }

        let aAngle0 = Math.atan2(pMinorAxisB, pMajorAxisA);
        let aAngle1: number;

        if (aAngle0 >= 0) {
            aAngle1 = aAngle0;
        }
        aAngle1 = 2 * Math.PI + aAngle0;

        let aResult = this.roundUpToNDigit(aAngle1 / 2);

        return aResult
    }
    //__________________________________________________________________________________________
    private calculateEllipticityFromEccentricity(pEccentricity: number) {
        let aResult = Math.atan(Math.sqrt(1 - pEccentricity ** 2));

        return aResult;
    }
    //__________________________________________________________________________________________
    private calculateEccentricityFromEllipticity(pEllipticity: number) {
        let aResult = Math.sqrt(1 - Math.tan(pEllipticity) ** 2);

        return aResult;
    }
    //__________________________________________________________________________________________
    private laserIntensity() {
        const aLaserBehavior = Op3dContext.PARTS_MANAGER.selectedPart.getBehavior('laserBehavior');
        const aLightSourceData = aLaserBehavior.laserData.lightSource;
        const aLaserShape = aLightSourceData.shape as eSmPlaneWaveType;
        let aLaserPower = aLightSourceData.power;
        switch (aLaserShape) {
            case eSmPlaneWaveType.CIRCULAR: {
                let aLaserData = aLightSourceData.sourceGeometricalData as iCircularPlaneWaveData;
                return aLaserPower / (Math.PI * aLaserData.radius ** 2);
            }
            case eSmPlaneWaveType.RECTANGULAR: {
                let aLaserData = aLightSourceData.sourceGeometricalData as iRectanglePlaneWaveData;
                return aLaserPower / (aLaserData.width / aLaserData.height);
            }
            case eSmPlaneWaveType.ELLIPTICAL: {
                let aLaserData = aLightSourceData.sourceGeometricalData as iEllipticalPlaneWaveData;
                return (Math.PI * aLaserData.radius_x * aLaserData.radius_y);
            }
        }
    }
    //__________________________________________________________________________________________
    /**   Calculate Stokes params  */
    private stoke0() {
        return this.roundUpToNDigit(this.laserIntensity());
    }
    //__________________________________________________________________________________________
    private stoke1FromEllipticity(pEllipticity: number, pPolarizationAngle: number) {
        let aResult = this.roundUpToNDigit(this.stoke0() * Math.cos(2 * pEllipticity) * Math.cos(2 * pPolarizationAngle));

        return aResult;
    }
    //__________________________________________________________________________________________
    private stoke2FromEllipticity(pEllipticity: number, pPolarizationAngle: number) {
        let aResult = this.roundUpToNDigit(this.stoke0() * Math.cos(2 * pEllipticity) * Math.sin(2 * pPolarizationAngle));

        return aResult;
    }
    //__________________________________________________________________________________________
    private stoke3FromEllipticity(pEllipticity: number) {
        let aResult = this.roundUpToNDigit(this.stoke0() * Math.sin(2 * pEllipticity));

        return aResult;
    }
    //__________________________________________________________________________________________
    private stoke1FromJones(pX: string, pY: string, pS0: number) {
        let { re: xR, im: xI } = Complex(pX);
        let { re: yR, im: yI } = Complex(pY);
        let aAbsXSq = (Math.sqrt(xR ** 2 + xI ** 2)) ** 2;
        let aAbsYSq = (Math.sqrt(yR ** 2 + yI ** 2)) ** 2;

        return this.roundUpToNDigit(pS0 * (aAbsXSq - aAbsYSq));
    }
    //__________________________________________________________________________________________
    private stoke2FromJones(pX: string, pY: string, pS0: number, pPhaseShift: number) {
        let { re: xR, im: xI } = Complex(pX);
        let { re: yR, im: yI } = Complex(pY);
        let aAbsXSq = Math.sqrt(xR ** 2 + xI ** 2);
        let aAbsYSq = Math.sqrt(yR ** 2 + yI ** 2);

        return this.roundUpToNDigit(2 * pS0 * aAbsXSq * aAbsYSq * Math.cos(this.degreeToRadian(pPhaseShift)));
    }
    //__________________________________________________________________________________________
    private stoke3FromJones(pX: string, pY: string, pS0: number, pPhaseShift: number) {
        let { re: xR, im: xI } = Complex(pX);
        let { re: yR, im: yI } = Complex(pY);
        let aAbsXSq = Math.sqrt(xR ** 2 + xI ** 2);
        let aAbsYSq = Math.sqrt(yR ** 2 + yI ** 2);

        return this.roundUpToNDigit(2 * pS0 * aAbsXSq * aAbsYSq * Math.sin(this.degreeToRadian(pPhaseShift)));
    }
    //__________________________________________________________________________________________
    private calculateCurrPolarizationData(pType: eNewPolarizationType, pChangedField: ePolarizationFields) {
        let aS0 = this.stoke0();
        let aS1: number, aS2: number, aS3: number, aPhaseShift: number;
        let aEllipticity = this.degreeToRadian(this.mCurrPolarizationData.general.ellipticity);
        let aEccentricity = this.mCurrPolarizationData.general.eccentricity;
        let aPolarizAngle = this.mInputElements.general_input.orientationAngle.value;
        let aExtinction = this.mCurrPolarizationData.general.extinction_ratio;
        let aPolarizationRatio = this.mCurrPolarizationData.general.polarization_ratio;

        if (pChangedField == ePolarizationFields.POLARIZATION_RATIO) {
            aPolarizationRatio = this.mInputElements.general_input.polarizationRatio.value;
            aExtinction = piPolarizationSection.calculateExtinctionRatioByPolarization(this.mInputElements.general_input.polarizationRatio.value);
        }

        if (pChangedField == ePolarizationFields.EXTINCTION_RATIO) {
            aExtinction = this.mInputElements.general_input.extinctionRatio.value;
            aPolarizationRatio = piPolarizationSection.calculatePolarizationRatioByExtinction(this.mInputElements.general_input.extinctionRatio.value);
        }

        let aPolarizationAngleInRad = this.degreeToRadian(aPolarizAngle, 5);
        let aRecalculatedJones: iJonesVector;

        switch (pType) {
            case eNewPolarizationType.LINEAR:
                ({ aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 } = this.calculateLinear(aPolarizationAngleInRad, aEllipticity))
                break;
            case eNewPolarizationType.CIRCULAR_RIGHT:
                ({ aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 } = this.calculateCircular(true))
                break;
            case eNewPolarizationType.CIRCULAR_LEFT:
                ({ aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 } = this.calculateCircular(false))
                break;
            case eNewPolarizationType.ELLIPTICAL:
                if (pChangedField == ePolarizationFields.ECCENTRICITY) {
                    aEccentricity = this.mInputElements.general_input.eccentricity.value;
                    aEllipticity = this.calculateEllipticityFromEccentricity(aEccentricity);
                } else if (pChangedField == ePolarizationFields.ELLIPTICITY) {
                    aEllipticity = this.degreeToRadian(this.mInputElements.general_input.ellipticity.value);
                    aEccentricity = this.calculateEccentricityFromEllipticity(aEllipticity);
                }
                ({ aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 } = this.calculateElliptical(aPolarizationAngleInRad, aEllipticity))
                break;
            case eNewPolarizationType.USER_DEFINED_JONES:
                ({ aRecalculatedJones, aPhaseShift, aS1, aS2, aS3, aEllipticity, aEccentricity, aPolarizationAngleInRad } = this.calculateUserDefinedJones())
                break;
            case eNewPolarizationType.USER_DEFINED_STOKES:
                ({ aRecalculatedJones, aPhaseShift, aS1, aS2, aS3, aEllipticity, aEccentricity, aPolarizationAngleInRad } = this.calculateUserDefinedStokes())
                break;
        }

        let aAuxiliaryAngle = this.calculateAuxuliaryAngle(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy);

        let aNewCurrPolarizationData: iPolarizationData = {
            type: this.mCurrentPolarizationType,
            general: {
                // polarization_angle: this.radianToDegree(aPolarizationAngleInRad),
                polarization_angle: aPolarizAngle,
                eccentricity: aEccentricity,
                ellipticity: this.radianToDegree(aEllipticity),
                extinction_ratio: aExtinction,
                polarization_ratio: aPolarizationRatio
            },
            jones_vector: {
                principal_x: aRecalculatedJones.principalJx,
                principal_y: aRecalculatedJones.principalJy,
                orthogonal_x: aRecalculatedJones.orthogonalJx,
                orthogonal_y: aRecalculatedJones.orthogonalJy,
            },
            stokes: {
                stoke_0: aS0,
                stoke_1: aS1,
                stoke_2: aS2,
                stoke_3: aS3,
                phase_shift: aPhaseShift,
                auxiliary_angle: aAuxiliaryAngle
            }
        }
        this.mCurrPolarizationData = aNewCurrPolarizationData;
    }
    //__________________________________________________________________________________________
    private jonesMatrixEllipticalForRotatedEllipse(pJq: number, pJp: string, pPolarizationAngle: number): iJonesVector {
        let aCosOfPolarizAngle = Math.cos(pPolarizationAngle);
        let aSinOfPolarizAngle = Math.sin(pPolarizationAngle);

        let aPrincipalJx = Complex(pJq * aCosOfPolarizAngle).sub(Complex(pJp).mul(aSinOfPolarizAngle));
        aPrincipalJx.re = this.roundUpToNDigit(aPrincipalJx.re)
        aPrincipalJx.im = this.roundUpToNDigit(aPrincipalJx.im)
        let aPrincipalJy = Complex(pJq * aSinOfPolarizAngle).add(Complex(pJp).mul(aCosOfPolarizAngle));
        aPrincipalJy.re = this.roundUpToNDigit(aPrincipalJy.re)
        aPrincipalJy.im = this.roundUpToNDigit(aPrincipalJy.im)
        // let aOrthogonalJx = this.jonesOrthogonalEllipse(aPrincipalJx);
        // let aOrthogonalJy = this.jonesOrthogonalEllipse(aPrincipalJy);
        let aOrthogonalJx = aPrincipalJy.neg();
        let aOrthogonalJy = aPrincipalJx;

        return { principalJx: aPrincipalJx.toString(), principalJy: aPrincipalJy.toString(), orthogonalJx: aOrthogonalJx.toString(), orthogonalJy: aOrthogonalJy.toString() };
    }
    //__________________________________________________________________________________________
    private calculateStokesFromEllipticity(pEllipticity: number, pPolarizationAngleInRad: number) {
        let aS1 = this.stoke1FromEllipticity(pEllipticity, pPolarizationAngleInRad);
        let aS2 = this.stoke2FromEllipticity(pEllipticity, pPolarizationAngleInRad);
        let aS3 = this.stoke3FromEllipticity(pEllipticity);
        return { s1: aS1, s2: aS2, s3: aS3 };
    }
    //__________________________________________________________________________________________
    private calculateStokesFromJones(pPrincipalJx: string, pPrincipalJy: string, pS0: number, pPhaseShift: number) {
        let aS1 = this.stoke1FromJones(pPrincipalJx, pPrincipalJy, pS0);
        let aS2 = this.stoke2FromJones(pPrincipalJx, pPrincipalJy, pS0, pPhaseShift);
        let aS3 = this.stoke3FromJones(pPrincipalJx, pPrincipalJy, pS0, pPhaseShift);
        return { s1: aS1, s2: aS2, s3: aS3 };
    }
    //__________________________________________________________________________________________
    private calculateCurrDataForEllipticalType(pPolarizationAngle: number, pEllipticity: number): iJonesVector {
        let aSemiMajorAxis = this.semiMajorAxisFromEllipticityAngle(pEllipticity);
        let aSemiMinorAxis = this.semiMinorAxisFromEllipticityAngle(pEllipticity);
        let aJq = aSemiMajorAxis;
        let aJpReal = this.roundUpToNDigit(aSemiMinorAxis * (Math.cos(Math.PI / 2)))
        let aJpImmaginary = this.roundUpToNDigit(aSemiMinorAxis * (Math.sin(Math.PI / 2)))
        let aJp = Complex(aJpReal, aJpImmaginary).toString();

        return this.jonesMatrixEllipticalForRotatedEllipse(aJq, aJp, pPolarizationAngle);
    }
    //__________________________________________________________________________________________
    private calculationManager(pType: eNewPolarizationType, pChangedField: ePolarizationFields) {
        Op3dContext.SCENE_HISTORY.addToHistory();
        this.calculateCurrPolarizationData(pType, pChangedField);
        this.updateInputs();
        this.mParams.onCalculationChange();
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private calculatePhaseShift(pPrincipalJx: string, pPrincipalJy: string) {
        let aAngleX = this.calculateAngle(pPrincipalJx);
        let aAngleY = this.calculateAngle(pPrincipalJy);
        let aPhaseShift = aAngleY - aAngleX;

        let aResult = this.radianToDegree(aPhaseShift);
        return aResult;
    }
    //__________________________________________________________________________________________
    private calculateAuxuliaryAngle(pPrincipalJx: string, pPrincipalJy: string) {
        let { re: xR, im: xI } = Complex(pPrincipalJx);
        let { re: yR, im: yI } = Complex(pPrincipalJy);
        let aJxAbs = Math.sqrt(xR ** 2 + xI ** 2);
        let aJyAbs = Math.sqrt(yR ** 2 + yI ** 2);
        let aAuxiliaryAngle = Math.atan(aJyAbs / aJxAbs);
        let aResult = this.radianToDegree(aAuxiliaryAngle);
        return aResult;
    }
    //__________________________________________________________________________________________
    private calculateLinear(pPolarizationAngleInRad: number, pEllipticity: number) {
        let aRecalculatedJones = this.jonesVectorLinear(pPolarizationAngleInRad);
        let aPhaseShift = this.calculatePhaseShift(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy);
        let { s1: aS1, s2: aS2, s3: aS3 } = this.calculateStokesFromEllipticity(pEllipticity, pPolarizationAngleInRad);
        return { aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 };
    }
    //__________________________________________________________________________________________
    private calculateCircular(pIsRightPolarization: boolean) {
        let aS0 = this.stoke0()
        let aRecalculatedJones = this.jonesVectorCircularLeftRight(pIsRightPolarization); // circular left = false
        let aPhaseShift = this.calculatePhaseShift(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy);
        let { s1: aS1, s2: aS2, s3: aS3 } = this.calculateStokesFromJones(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy, aS0, aPhaseShift);
        return { aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 };
    }
    //__________________________________________________________________________________________
    private calculateElliptical(pPolarizationAngleInRad: number, pEllipticity: number) {
        let aRecalculatedJones = this.calculateCurrDataForEllipticalType(pPolarizationAngleInRad, pEllipticity);
        let aPhaseShift = this.calculatePhaseShift(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy);
        let { s1: aS1, s2: aS2, s3: aS3 } = this.calculateStokesFromJones(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy, this.stoke0(), aPhaseShift);
        return { aRecalculatedJones, aPhaseShift, aS1, aS2, aS3 };
    }
    //__________________________________________________________________________________________
    private calculateUserDefinedJones() {
        let aS0 = this.stoke0();
        let aPrincipalJx = this.mInputElements.jones_vector_input.principalX.value.replace(/\s/g, '');;
        let aPrincipalJy = this.mInputElements.jones_vector_input.principalY.value.replace(/\s/g, '');;
        let aPhaseShift = this.calculatePhaseShift(aPrincipalJx, aPrincipalJy);

        let { s1: aS1, s2: aS2, s3: aS3 } = this.calculateStokesFromJones(aPrincipalJx, aPrincipalJy, aS0, aPhaseShift);

        let aEllipticity = this.ellipticityAngleFromStoke(aS1, aS2, aS3);
        let aEccentricity = this.calculateEccentricityFromEllipticity(aEllipticity);

        let aSemiMajorAxis = this.semiMajorAxisFromEllipticityAngle(aEllipticity);
        let aSemiMinorAxis = this.semiMinorAxisFromEllipticityAngle(aEllipticity);

        let aPolarizationAngleInRad = this.polarizationAngleFromEllipticalAxis(aSemiMajorAxis, aSemiMinorAxis, aEllipticity);

        let aJq = aSemiMajorAxis;
        let aJp = `${(this.roundUpToNDigit(aSemiMinorAxis * (Math.cos(Math.PI / 2))))} + ${this.roundUpToNDigit(aSemiMinorAxis * (Math.sin(Math.PI / 2)))}i`;

        let { re: real, im: imaginary } = Complex(aJp);
        let aOrtXR = this.roundUpToNDigit((aJq * Math.cos(aPolarizationAngleInRad)) - (real * Math.sin(aPolarizationAngleInRad)));
        let aOrtXI = this.roundUpToNDigit(imaginary * Math.sin(aPolarizationAngleInRad));
        let aOrtXISign = Math.sign(aOrtXI) == -1 ? '+' : '-';
        let aOrtYR = this.roundUpToNDigit((-aJq * Math.sin(aPolarizationAngleInRad)) - (real * Math.cos(aPolarizationAngleInRad)));
        let aOrtYI = this.roundUpToNDigit(imaginary * Math.cos(aPolarizationAngleInRad))
        let aOrtYISign = Math.sign(aOrtYI) == -1 ? '+' : '-';
        let aOrthogonalJx = Complex(`${aOrtXR} ${aOrtXISign} ${aOrtXI}i`).toString();
        let aOrthogonalJy = Complex(`${aOrtYR} ${aOrtYISign} ${aOrtYI}i`).toString();

        let aRecalculatedJones = { principalJx: aPrincipalJx, principalJy: aPrincipalJy, orthogonalJx: aOrthogonalJx, orthogonalJy: aOrthogonalJy };
        return { aRecalculatedJones, aPhaseShift, aS1, aS2, aS3, aEllipticity, aEccentricity, aPolarizationAngleInRad };
    }
    //__________________________________________________________________________________________
    private calculateUserDefinedStokes() {
        let aS1 = this.mInputElements.stokes_input.s1.value;
        let aS2 = this.mInputElements.stokes_input.s2.value;
        let aS3 = this.mInputElements.stokes_input.s3.value;

        let aEllipticity = this.ellipticityAngleFromStoke(aS1, aS2, aS3);
        let aEccentricity = this.calculateEccentricityFromEllipticity(aEllipticity);

        let aPolarizationAngleInRad = this.polarizationAngleFromStoke(aS1, aS2);

        let aRecalculatedJones = this.calculateCurrDataForEllipticalType(aPolarizationAngleInRad, aEllipticity);
        let aPhaseShift = this.calculatePhaseShift(aRecalculatedJones.principalJx, aRecalculatedJones.principalJy);
        return { aRecalculatedJones, aPhaseShift, aS1, aS2, aS3, aEllipticity, aEccentricity, aPolarizationAngleInRad };
    }
    //__________________________________________________________________________________________
    private degreeToRadian(pVal: number, pRound?: number) {
        if (pRound != null) {
            return parseFloat((pVal * (Math.PI / 180)).toFixed(pRound));
        } else {
            return pVal * (Math.PI / 180);
        }
    }
    //__________________________________________________________________________________________
    private radianToDegree(pVal: number) {
        return pVal * (180 / Math.PI);
    }
    //__________________________________________________________________________________________
    private roundUpToNDigit(pValue: number, pN: number = 5): number {
        return parseFloat(pValue.toFixed(pN))
    }
    //__________________________________________________________________________________________
    private complexNumStringValidator(pStr: string): boolean {
        const aRegex = /[^0-9+\- .i]/g;
        const aMatches = pStr.match(aRegex);
        if (aMatches != null && aMatches.length > 0) {
            return false;
        }

        return true;
    }
    //__________________________________________________________________________________________
    private validationOfJones(pJx: string, pJy: string) {
        let aJxSquare = this.conjugateOfComplexNumber(pJx);
        let aJySquare = this.conjugateOfComplexNumber(pJy);
        let aSumOfJxJy = this.sumOfComplexNumbers(aJxSquare, aJySquare);
        let aRootOfSum = this.squareRootOfComplexNumber(aSumOfJxJy);
        if (parseFloat(aRootOfSum).toFixed(4) == '1.0000') {
            return true;
        }
        return false;
    }
    //__________________________________________________________________________________________

    private conjugateOfComplexNumber(pComplexNum: string): string {
        let aConOfNum = Complex(pComplexNum).conjugate();
        let aResult = aConOfNum.toString();
        return aResult;
    }
    //__________________________________________________________________________________________
    private squareRootOfComplexNumber(pComplexNum: string): string {
        let aSquaredNum = Complex(pComplexNum).sqrt();

        return aSquaredNum.toString();
    }
    //__________________________________________________________________________________________
    private sumOfComplexNumbers(pComplexNum1: string, pComplexNum2: string) {
        let aSum = Complex(pComplexNum1).add(pComplexNum2);

        return aSum.toString();
    }
    //__________________________________________________________________________________________
    private calculationStokeHandler(pCurrPolarizStoke: number, pPolarizField: ePolarizationFields, pStokeInputElement: newNumberInputElement) {
        let aStokeIsValid = this.stokeIsValid();
        let aCurrS3 = pCurrPolarizStoke;
        if (aStokeIsValid) {
            this.calculationManager(this.mCurrPolarizationData.type, pPolarizField);
        } else {
            pStokeInputElement.value = aCurrS3;
            Popup.instance.open({ text: 'Stoke0**2 is greater than Stoke1**2 + Stoke2**2 + Stoke3**2' })
        }
    }
    //__________________________________________________________________________________________
    private stokeIsValid(): boolean {
        let aS0 = this.stoke0();
        let aS1 = this.mInputElements.stokes_input.s1.value;
        let aS2 = this.mInputElements.stokes_input.s2.value;
        let aS3 = this.mInputElements.stokes_input.s3.value;

        let aResult = aS0 ** 2 >= (aS1 ** 2 + aS2 ** 2 + aS3 ** 2);

        return aResult;
    }
    //__________________________________________________________________________________________
    private calculateAngle(pComplexNum: string) {
        return Complex(pComplexNum).arg()
    }
}