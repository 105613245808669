import { Matrix4 } from "three";
import { iSmSystemFile } from "../../simulation/SimulationContext";
import { iSimulationRaysOptions } from "../../simulation/SimulationRunner";
import { Part } from "../Part";
import { iBehaviorsJSON } from "../_parts_assets/ExportToJSONInterfaces";




export abstract class Behavior<T = any> {

    /**
     * @description additional optional data of the behavior
     */
    protected mData: T;
    protected mUpdateListeners = new Array<Function>()


    //__________________________________________________________________________________________
    constructor(pData?: T) {
        this.mData = pData;
    }
    //__________________________________________________________________________________________
    public updateJsonSimulationData(_pData: iSmSystemFile, _pWorldMatrix: Matrix4,
        _pPart: Part, _pRaysData: iSimulationRaysOptions) { }
    //__________________________________________________________________________________________
    public onSelect(_pPart: Part) { }
    //__________________________________________________________________________________________
    public onDblSelect(_pPart: Part) { }
    //__________________________________________________________________________________________
    public onDeselect(_pPart: Part) { }
    //__________________________________________________________________________________________
    public async initFromJSON(_pPart: Part, _pJSONData: iBehaviorsJSON, _pUseIDS: boolean) { }
    //__________________________________________________________________________________________
    public exportToJSON(_pPart: Part, _pJSONData: iBehaviorsJSON) { }
    //__________________________________________________________________________________________
    public onPartRemoved(_pPart: Part) { }
    //__________________________________________________________________________________________
    public update(_pPart: Part) { }
    //__________________________________________________________________________________________    
}