import { Color, DoubleSide, Material, MeshPhongMaterial } from "three";

export class ThreeMaterialUtils {
    public static createInActiveMat(pFrameColor: number = 0xB0B0B0): Material {
        let aMat = new MeshPhongMaterial();
        aMat.color.setHex(pFrameColor);
        aMat.emissive.setHex(pFrameColor);
        aMat.side = DoubleSide;
        return aMat;
    }
    //__________________________________________________________________________________________
    public static createUncoatedMat() {
        let aMat = new MeshPhongMaterial();
        aMat.color.setHex(0xfafafafa);
        aMat.side = DoubleSide;
        aMat.transparent = true;
        aMat.opacity = 0.7;
        return aMat;
    }
    //__________________________________________________________________________________________
    public static createParaxialLensMat() {
        let aMat = new MeshPhongMaterial();
        aMat.color.setHex(0x00FF22);
        aMat.side = DoubleSide;
        aMat.transparent = true;
        aMat.opacity = 0.7;
        return aMat;
    }
    //__________________________________________________________________________________________
    public static createCoatedMat(pColor: number, pOpacity: number) {
        let aMaterial = new MeshPhongMaterial({
            color: pColor,
            opacity: pOpacity,
            transparent: true
        });

        aMaterial.side = DoubleSide;
        return aMaterial;
    }
    //__________________________________________________________________________________________
    public static createMat(): MeshPhongMaterial {
        let aMat = new MeshPhongMaterial();
        aMat.side = DoubleSide;

        return aMat;
    }
    //__________________________________________________________________________________________
    public static createMirrorMat(): MeshPhongMaterial {
        let aMat = new MeshPhongMaterial();
        aMat.color = new Color(0.3, 0.3, 0.3);
        aMat.side = DoubleSide;
        return aMat;
    }
    //__________________________________________________________________________________________
    public static createTransparentMat(pIsTransparent: boolean, pOpacity: number): MeshPhongMaterial {
        let aMaterial = new MeshPhongMaterial({ color: 0xffffff });
        aMaterial.transparent = pIsTransparent;
        aMaterial.opacity = pOpacity;
        aMaterial.side = DoubleSide;
        return aMaterial;
    }
    //__________________________________________________________________________________________
}
