import { eUnitType, eBrandTypes, eDataPermission, eAxisType } from "../../../../_context/Enums";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { eBaseShape, eOpticsTypeNames, OpticsContext } from "../../../../_context/OpticsContext";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../../parts/Part";
import { MenuListAbstract } from "../MenuListAbstract";
import {
    iOneRadioBtnData, iSingleFilterData, iRadioBtnsData, eFilterType, iRangeData,
    iDropdownData, iOMOneDropdownItem, iOneCheckBoxData,
    iMultipleCheckboxData
} from "../MenusContext";
import { iBootstrapSliderOptions, SliderValue } from "../filters/RangeFilter";
import { FiltersHandler } from "./FiltersHandler";

export enum eOMFilterName {
    permission = 'permission',
    type = 'parameters.type',
    subType = 'parameters.subType',
    brand = 'parameters.info.brand',
    materialID = 'parameters.materialID',
    diameter = 'parameters.geometry.diameter',
    baseShape = 'parameters.baseShape',
    width = 'parameters.geometry.width',
    height = 'parameters.geometry.height',
    owner = 'owner',
    numberId = 'number_id',

    cut_off = 'parameters.physical_data.cut_off',
    cut_on = 'parameters.physical_data.cut_on',

    grooves = 'parameters.physical_data.grooves',

    a = 'parameters.geometry.a',
    b = 'parameters.geometry.b',
    c = 'parameters.geometry.c',
    d = 'parameters.geometry.d',
    e = 'parameters.geometry.e',
    f = 'parameters.geometry.f',
    g = 'parameters.geometry.g',
    h = 'parameters.geometry.h',
    j = 'parameters.geometry.j',
    l = 'parameters.geometry.l',
    t = 'parameters.geometry.t',
    x = 'parameters.geometry.x',
    y = 'parameters.geometry.y',

    length_of_face = 'parameters.geometry.length_of_face',
    length = 'parameters.geometry.length',
    length_of_legs = 'parameters.geometry.length_of_legs',
    length_of_hypotenuse = 'parameters.geometry.length_of_hypotenuse',
    dimensions = 'parameters.geometry.dimensions',
    dimension_1 = 'parameters.geometry.dimension_1',
    dimension_2 = 'parameters.geometry.dimension_2',
    cube_side = 'parameters.geometry.cube_side',
    l1 = 'parameters.geometry.l1',
    l2 = 'parameters.geometry.l2',
    k = 'parameters.geometry.k',
    convexity = 'parameters.geometry.convexity',
    thickness_center = 'parameters.geometry.thickness_center',
    thickness_edge = 'parameters.geometry.thickness_edge',
    thickness = 'parameters.geometry.thickness',
    edge_thickness_max = 'parameters.geometry.edge_thickness_max',
    thickness_h = 'parameters.geometry.thickness_h',
    alpha = 'parameters.geometry.alpha',
    apex_angle = 'parameters.geometry.apex_angle',
    beta = 'parameters.geometry.beta',
    phi = 'parameters.geometry.phi',
    angle_a = 'parameters.geometry.angle_a',
    angle_b = 'parameters.geometry.angle_b',
    angle_c = 'parameters.geometry.angle_c',
    angle_d = 'parameters.geometry.angle_d',
    theta = 'parameters.geometry.theta',
    theta1 = 'parameters.geometry.theta1',
    theta2 = 'parameters.geometry.theta2',
    wedge_angle = 'parameters.geometry.wedge_angle',
    deviation_angle = 'parameters.geometry.deviation_angle',
    efl = 'parameters.geometry.efl',
    bfl = 'parameters.geometry.bfl',
    r1 = 'parameters.geometry.r1',
    r2 = 'parameters.geometry.r2',
    innerRadius = 'parameters.geometry.innerRadius',
    outerRadius = 'parameters.geometry.outerRadius',
}

export class OpticsFiltersHandler extends FiltersHandler {
    private static LIMIT_PRECENT: number = 0.05;
    public static SHAPES_NAMES: Array<string> = ['Circular', 'Rectangular', 'Volume'];
    private mSpecificFilters: iHash<Object> = {};

    private mSpecialFilters: Array<eOMFilterName> = new Array<eOMFilterName>();
    private mCurrentPart: Part;
    //__________________________________________________________________________________________
    constructor(pMenu: MenuListAbstract<iOpticsVO>, pFilterTemplate: HTMLElement,
        pFilterContainer: HTMLElement) {
        super(pMenu, pFilterTemplate, pFilterContainer);
    }
    //__________________________________________________________________________________________
    protected _updateFiltersUnit(pUnit: eUnitType) {
        for (let filter in this.mFilterComponents) {
            this.mFilterComponents[filter].updateUnit(pUnit);
        }
    }
    //__________________________________________________________________________________________
    protected async _initFilters(): Promise<void> {
        // this._createFilter(this._addPermissionFilter());

        this._createFilter(this._addTypeFilter());
        this._createFilter(this._addSubtypeFilter());

        this._createFilter(await this._addBrandsFilter());
        this._createFilter(await this._addMeterialFilter());

        this._createFilter(this._addShapeFilter());
        this._createFilter(this._addDiameterFilter());

        this._addMoreFilters();
        this.mFiltersAdded = true;
    }
    //__________________________________________________________________________________________
    private async _addBrandsFilter() {
        const aBrands = await Op3dContext.DATA_MANAGER.getBrands(eBrandTypes.OPTIC);


        let aFilter = this._addCheckBoxFilter({
            items: aBrands,
            title: "Brands",
            type: eOMFilterName.brand,
        });

        return aFilter;
    }
    //__________________________________________________________________________________________
    protected _adjustFilters() {
        this.mSpecificFilters = {};
        this.mFilterComponents[eOMFilterName.diameter].setVisibility(true);
        this.mFilterComponents[eOMFilterName.baseShape].setVisibility(true);

        if (null == this.mCurrentPart) {
            return;
        }


        // let aOpticsHolderPart = this.mCurrentPart.getSubpartByName(OPTICS_HOLDER);
        let aOpticsHolderPart = this.mCurrentPart.getAxes().find(axis =>
            (eAxisType.OPTICS === axis.type));
        if (null == aOpticsHolderPart) {
            return;
        }

        // let aOpticsHolder = aOpticsHolderPart.object3D;
        let aCircular = aOpticsHolderPart?.radius
        let aRect = aOpticsHolderPart?.length
        let aShape


        if (aOpticsHolderPart.shape != null) {
            aShape = aOpticsHolderPart.shape
        } else {
            if (aCircular != null) {
                aShape = eBaseShape.CIRCULAR
            } else if (aRect != null) {
                aShape = eBaseShape.RECTANGULAR
            } else {
                aShape = eBaseShape.VOLUME
            }
        }

        // let aOpticsData = aOpticsHolder.userData.opticsData as iOpticsData;
        // if (null != aCircular) {
        if (eBaseShape.CIRCULAR == aShape) {
            let aDiameter = aCircular * 2;
            let aMinD = (aDiameter * (1 - OpticsFiltersHandler.LIMIT_PRECENT));
            let aMaxD = (aDiameter * (1 + OpticsFiltersHandler.LIMIT_PRECENT));

            this.mSpecificFilters[eOMFilterName.diameter] = {
                'from': aMinD,
                'to': aMaxD
            };
            this.mSpecificFilters[eOMFilterName.baseShape] = [eBaseShape.CIRCULAR];

            this.mFilterComponents[eOMFilterName.diameter].setVisibility(false);
            this.mFilterComponents[eOMFilterName.baseShape].setVisibility(false);
        } else if (eBaseShape.RECTANGULAR == aShape) {

            let aMinL = (aRect * (1 - OpticsFiltersHandler.LIMIT_PRECENT));
            let aMaxL = (aRect * (1 + OpticsFiltersHandler.LIMIT_PRECENT));

            this.mSpecificFilters[eOMFilterName.height] = {
                'from': aMinL,
                'to': aMaxL
            };
            // this.mSpecificFilters[eOMFilterName.width] = {
            //     '$gte': aMinD,
            //     '$lte': aMaxD
            // };


            this.mSpecificFilters[eOMFilterName.baseShape] = [eBaseShape.RECTANGULAR]

            this.mFilterComponents[eOMFilterName.diameter].setVisibility(false);
            this.mFilterComponents[eOMFilterName.baseShape].setVisibility(false);
        } else if (eBaseShape.VOLUME == aShape) {
            this.mSpecificFilters[eOMFilterName.baseShape] = [eBaseShape.RECTANGULAR, eBaseShape.VOLUME]

            this.mFilterComponents[eOMFilterName.diameter].setVisibility(false);
            this.mFilterComponents[eOMFilterName.baseShape].setVisibility(false);
        }
        // }
    }
    //__________________________________________________________________________________________

    protected _onPermissionChange(pData: Array<eDataPermission>, pToChange: boolean) {
        let aTypeFilter = this.mFilterComponents[eOMFilterName.type] as any;
        let aShowApperture = (null != pData) ?
            (pData.indexOf(eDataPermission.PRIVATE) > -1) : false;
        aTypeFilter.showRB(eOpticsTypeNames.APERTURE, aShowApperture);

        this._onIngredientChange(eOMFilterName.permission, pData, eFilterType.RADIO_BTNS, pToChange);
    }
    //__________________________________________________________________________________________
    private _addShapeFilter(): iSingleFilterData<iRadioBtnsData> {
        let aData: iRadioBtnsData = {
            defaultNameIndex: 0,
            data: new Array<iOneRadioBtnData>()
        };

        let aNames = Object.values(OpticsFiltersHandler.SHAPES_NAMES);
        aData.data.push({
            name: 'None'
        });
        for (let i = 0; i < aNames.length; i++) {
            const aName = aNames[i];
            aData.data.push({
                name: aName
            });
        }

        return {

            filterType: eFilterType.RADIO_BTNS,
            data: aData,
            isVisible: true,
            name: eOMFilterName.baseShape,
            params: {
                showCloseBtn: false,
                title: "Shape",
                hideResetBtn: true,
                onChange: (pData: string, pToChange: boolean) =>
                    this.onShapeChanged(pData, pToChange)
            }
        }
    }
    //__________________________________________________________________________________________
    protected resetMountFilters() {
        if (this.mFilterComponents[eOMFilterName.diameter] != null) {
            this.mFilterComponents[eOMFilterName.diameter].sendDataOnEdges = false;
            this.mFilterComponents[eOMFilterName.diameter].setVisibility(false);
            this.mFilterComponents[eOMFilterName.diameter].reset();
        }

        if (this.mFilterComponents[eOMFilterName.width] != null) {
            this.mFilterComponents[eOMFilterName.width].sendDataOnEdges = false;
            this.mFilterComponents[eOMFilterName.width].setVisibility(false);
            this.mFilterComponents[eOMFilterName.width].reset();
        }

        if (this.mFilterComponents[eOMFilterName.height] != null) {
            this.mFilterComponents[eOMFilterName.height].sendDataOnEdges = false;
            this.mFilterComponents[eOMFilterName.height].setVisibility(false);
            this.mFilterComponents[eOMFilterName.height].reset();
        }
    }
    //__________________________________________________________________________________________
    protected onShapeChanged(pData: string, pToChange: boolean = true) {
        this.resetMountFilters();

        let aBaseShape = null;
        switch (pData) {
            case OpticsFiltersHandler.SHAPES_NAMES[eBaseShape.CIRCULAR]:
                this._updateDiameterSlider();
                aBaseShape = [eBaseShape.CIRCULAR];
                break;
            case OpticsFiltersHandler.SHAPES_NAMES[eBaseShape.RECTANGULAR]:
                aBaseShape = [eBaseShape.RECTANGULAR];
                break;
            case OpticsFiltersHandler.SHAPES_NAMES[eBaseShape.VOLUME]:
                aBaseShape = [eBaseShape.VOLUME];

                break;
            default:
                this._updateDiameterSlider();
                break;
        }

        this._onIngredientChange(eOMFilterName.baseShape, aBaseShape,
            eFilterType.MULTIPLE_CHECKBOX, pToChange);
    }
    //__________________________________________________________________________________________
    private _updateDiameterSlider() {
        let aMin = 0;
        let aMax = (this.mFilterUnitScale == eUnitType.MILLIMETERS) ? 508 : 20;

        let aValue = [aMin, aMax];
        let aBootstrapSliderOptions: iBootstrapSliderOptions = {
            min: aMin,
            max: aMax,
            value: aValue,
            enabled: true,
            precision: 2
        };

        this.mFilterComponents[eOMFilterName.diameter].updateFilterData({
            data: aBootstrapSliderOptions,
            showDropdown: true,
            sendDataOnEdges: false
        } as iRangeData, false);
        this.mFilterComponents[eOMFilterName.diameter].enable();

        this.mFilterComponents[eOMFilterName.diameter].setValue(aValue, false);
        this.mFilterComponents[eOMFilterName.diameter].setVisibility(true);
    }
    //__________________________________________________________________________________________
    private _addDiameterFilter(): iSingleFilterData<iRangeData> {
        let aMin = 0;
        let aMax = (this.mFilterUnitScale == eUnitType.MILLIMETERS) ? 508 : 20;

        let aBootstrapSliderOptions: iBootstrapSliderOptions = {
            value: [aMin, aMax],
            step: 0.01,
            precision: 2,
        };

        let aIsEnabled = ((aMin / 0.95) != (aMax / 1.05));
        return {
            filterType: eFilterType.RANGE,
            isVisible: true,
            name: eOMFilterName.diameter,
            params: {
                title: "Diameter",
                hideResetBtn: false,
                onChange: (pData: SliderValue, pToChange: boolean) =>
                    this._onDiameterChanged(pData, pToChange)
            },
            data: {
                data: aBootstrapSliderOptions,
                showDropdown: aIsEnabled,
                filterHandler: this
            }
        }
    }
    //__________________________________________________________________________________________
    private _onDiameterChanged(pData: SliderValue, pToChange: boolean) {
        this._onIngredientChange(eOMFilterName.diameter, pData,
            eFilterType.RANGE, pToChange);
    }
    //__________________________________________________________________________________________
    private async _addMeterialFilter(): Promise<iSingleFilterData<iDropdownData>> {
        const aMaterials = await Op3dContext.DATA_MANAGER.getMaterials();

        let aOnChange = (pData: any, pToChange: boolean) =>
            this._onIngredientChange('parameters.materialID', pData,
                eFilterType.DROPDOWN, pToChange);

        let aData = new Array<iOMOneDropdownItem>();
        for (let i = 0; i < aMaterials.length; i++) {
            const element = aMaterials[i];
            aData.push({
                serverName: element.number_id,
                name: element.name,
            })
        }
        return {
            filterType: eFilterType.DROPDOWN,
            params: {
                onChange: aOnChange,
                title: "Material",
                hideResetBtn: true
            },
            name: eOMFilterName.materialID,
            data: {
                data: aData
            },
            isVisible: true
        }
    }
    //__________________________________________________________________________________________
    private _addCheckBoxFilter(pData: {
        title: string,
        type: eOMFilterName, items: Array<string>
    }) {
        let aOnChange = (pItem: string, pToChange: boolean) =>
            this._onCheckBoxDataChanged(pItem, pData.type, pToChange);

        let aCheckboxesData = new Array<iOneCheckBoxData>();
        for (let i = 0; i < pData.items.length; i++) {
            aCheckboxesData.push({ name: pData.items[i] });
        }
        return {
            filterType: eFilterType.MULTIPLE_CHECKBOX,
            params: {
                onChange: aOnChange,
                title: pData.title,
                hideResetBtn: true
            },
            name: pData.type,
            data: {
                checkboxesData: aCheckboxesData,
                selectAllCheckbox: true
            },
            isVisible: true
        }
    }
    //__________________________________________________________________________________________
    private _addSubtypeFilter(): iSingleFilterData<iMultipleCheckboxData> {

        /**
        * @TODO - transfer it to a suitable class
        */

        let aOnChange = (pData: Array<string>, pToChange: boolean) =>
            this._onSubtypeChange(pData, pToChange);

        return {
            filterType: eFilterType.MULTIPLE_CHECKBOX,
            params: {
                onChange: aOnChange,
                isCanBeNone: false,
                title: "Subtypes",
                hideResetBtn: true
            },
            name: eOMFilterName.subType,
            data: null,
            isVisible: false
        }
    }
    //__________________________________________________________________________________________
    private _addTypeFilter(): iSingleFilterData<iRadioBtnsData> {
        /**
         * @TODO - transfer it to a suitable class
         */

        let aData: iRadioBtnsData = {
            defaultNameIndex: 0,
            data: new Array<iOneRadioBtnData>()
        };

        let aNames = Object.values(eOpticsTypeNames);
        for (let i = 0; i < aNames.length; i++) {
            const aName = aNames[i];
            if (aName.toLowerCase().indexOf("coming soon") == -1) {
                aData.data.push({
                    name: aName,

                });
            }
        }

        return {

            filterType: eFilterType.RADIO_BTNS,
            data: aData,
            isVisible: true,
            name: eOMFilterName.type,
            params: {
                showOnStart: true,
                title: "Types",
                hideResetBtn: true,
                onChange: (pType: eOpticsTypeNames, pToChange: boolean) =>
                    this._onTypeChange(pType, pToChange),
            }
        }
    }
    //__________________________________________________________________________________________
    private _onCheckBoxDataChanged(pItem: string, pType: eOMFilterName, pToChange: boolean = true) {
        let aBrand = (pItem != eOpticsTypeNames.ALL) ? pItem : null;
        this._onIngredientChange(pType,
            aBrand,
            eFilterType.MULTIPLE_CHECKBOX,
            pToChange);
    }
    //__________________________________________________________________________________________
    private _onTypeChange(pType: eOpticsTypeNames, pToChange: boolean = true) {
        let aType = (pType != eOpticsTypeNames.ALL) ? pType : null;
        let aIsSubtypeChosen = (aType != null);
        if (aIsSubtypeChosen) {
            let aSubtypesObjs = OpticsContext.OPTICS_DATA.find((item) =>
                (item.type == pType)).subtypes.filter((subtype) =>
                    (false != subtype.showOnOpticsMenu));

            const aSubtypes = aSubtypesObjs.map((obj) => obj.name).sort();
            this.updateSubtypes(aSubtypes);
        } else {
            this._resetSubtypes();
        }

        this.mFilterComponents[eOMFilterName.subType].setVisibility(aIsSubtypeChosen);
        this._onIngredientChange(eOMFilterName.type,
            aType, eFilterType.RADIO_BTNS, pToChange);
    }
    //__________________________________________________________________________________________
    private _resetSubtypes() {
        this._onSubtypeChange(null, false);
        let aMultipleCheckBoxParams: iMultipleCheckboxData = {
            checkboxesData: [],
            selectAllCheckbox: false,
        }

        this.mFilterComponents[eOMFilterName.subType].updateFilterData(aMultipleCheckBoxParams,
            false);
    }
    //__________________________________________________________________________________________
    protected _onSubtypeChange(pData: Array<string>, pToUpdate: boolean) {
        this._hideSpecialFilters();
        this._onIngredientChange(eOMFilterName.subType, pData, eFilterType.MULTIPLE_CHECKBOX,
            pToUpdate);

        if (null != pData) {
            let aSubtypesFilters = this._getFiltersBySubtypes(pData);
            for (let i = 0; i < aSubtypesFilters.length; i++) {
                this.mSpecialFilters.push(aSubtypesFilters[i]);
                this.mFilterComponents[aSubtypesFilters[i]].setVisibility(true);
            }
        }

        // this._updateShapeFilter();
    }
    //__________________________________________________________________________________________
    private _hideSpecialFilters() {
        //            this.mMenu.showColumns(this.mSpecialFilters, false);

        for (let i = 0; i < this.mSpecialFilters.length; i++) {
            let aFilterNum = this.mSpecialFilters[i];
            this.mFilterComponents[aFilterNum].setVisibility(false);
            this.mFilterComponents[aFilterNum].reset();
        }

        this.mSpecialFilters.splice(0);
    }
    //__________________________________________________________________________________________
    private _getFiltersBySubtypes(pSubTypes: Array<string>) {
        let aRet = new Array<eOMFilterName>();
        for (let i = 0; i < pSubTypes.length; i++) {
            aRet.push(...this._getSubtypeFilters(pSubTypes[i]));
        }

        let aFilters = [...new Set(aRet)];

        return aFilters;
    }
    //__________________________________________________________________________________________
    private _getSubtypeFilters(pSubType: string) {
        switch (pSubType) {
            case OpticsContext._Curved_Mirror_Concave:
            case OpticsContext._Curved_Mirror_Convex:
            case OpticsContext._Spherical_Lens_Plano_Convex:
            case OpticsContext._Cylindrical_Lens_Plano_Convex:
            case OpticsContext._Cylindrical_Mirror_Plano_Convex:
            case OpticsContext._Cylindrical_Mirror_Plano_Concave:
            case OpticsContext._Cylindrical_Lens_Plano_Concave:
            case OpticsContext._Spherical_Lens_Plano_Concave:
                return [eOMFilterName.r1, eOMFilterName.bfl, eOMFilterName.efl];
            case OpticsContext._Ball_Lens:
            case OpticsContext._Half_Ball_Lens:
                return [eOMFilterName.bfl, eOMFilterName.efl];
            case OpticsContext._Parabolic_Mirror_Concave:
                return [eOMFilterName.efl];
            case OpticsContext._Off_Axis_Parabolic_Lens:
            case OpticsContext._Off_Axis_Parabolic_Mirror:
                return [eOMFilterName.efl];
            case OpticsContext._Spherical_Lens_Concave_Convex:
            case OpticsContext._Spherical_Lens_Convex_Concave:
            case OpticsContext._Spherical_Lens_Concave_Concave:
            case OpticsContext._Spherical_Lens_Convex_Convex:
                return [eOMFilterName.r1, eOMFilterName.r2,
                eOMFilterName.bfl, eOMFilterName.efl];
            case OpticsContext._Blazed_Ruled_Reflective_Grating:
            case OpticsContext._Reflective_Grating:
            case OpticsContext._Echelle_Grating:
            case OpticsContext._Transmission_Grating:
                return [eOMFilterName.grooves];
            case OpticsContext._Longpass_Filter:
            case OpticsContext._Shortpass_Filter:
            case OpticsContext._Bandpass_Filter:
            case OpticsContext._Dichroic_Filter:
            case OpticsContext._Notch_Filter:
            case OpticsContext._Calibration_Filter:
            case OpticsContext._Spectral_Shape_Filter:
            case OpticsContext._Laser_Line_Filter:
            case OpticsContext._Color_Glass:
            case OpticsContext._Neutral_Density_Filter:
            case OpticsContext._Hot_Cold_Mirror:
                return [eOMFilterName.cut_off, eOMFilterName.cut_on];
            default:
                return [];
        }
    }

    //__________________________________________________________________________________________
    public async reload(pPart?: Part) {
        this.mCurrentPart = pPart;

        await super.reload();
    }

    //__________________________________________________________________________________________
    private updateSubtypes(pSubtypes: Array<string>) {
        if (pSubtypes == null) {
            return;
        }

        let aCheckboxData = new Array<iOneCheckBoxData>();
        for (let i = 0; i < pSubtypes.length; i++) {
            aCheckboxData.push({
                name: pSubtypes[i],
                isEnabled: true
            });
        }

        let aMultipleCheckBoxParams: iMultipleCheckboxData = {
            checkboxesData: aCheckboxData,
            selectAllCheckbox: ((pSubtypes.length > 1)),
        }

        this.mFilterComponents[eOMFilterName.subType].updateFilterData(aMultipleCheckBoxParams,
            false);
    }
    //__________________________________________________________________________________________
    protected _onCategoryChange(pType: any, pToChange: boolean) {
        super._onCategoryChange(pType, pToChange, 'Optics');
    }
    //__________________________________________________________________________________________
    private _addMoreFilters() {
        let aEFLMin = (eUnitType.MILLIMETERS == this.mFilterUnitScale) ? -7112 : -280;
        let aEFLMax = (eUnitType.MILLIMETERS == this.mFilterUnitScale) ? 22352 : 880;

        let aEFLSliderOptions: iBootstrapSliderOptions = {
            min: aEFLMin,
            max: aEFLMax,
            value: [aEFLMin, aEFLMax],
            enabled: true,
        };

        this._createFilter({
            data: {
                data: aEFLSliderOptions,
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.efl,
            params: {
                title: 'EFL',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.efl, pData,
                        eFilterType.RANGE, pToChange)
            }
        });

        let aBFLMin = (eUnitType.MILLIMETERS == this.mFilterUnitScale) ? -7112 : -280;
        let aBFLMax = (eUnitType.MILLIMETERS == this.mFilterUnitScale) ? 22352 : 880;
        let aBFLSliderOptions: iBootstrapSliderOptions = {
            min: aBFLMin,
            max: aBFLMax,
            value: [aBFLMin, aBFLMax],
            enabled: true
        };

        this._createFilter({
            data: {
                data: aBFLSliderOptions,
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.bfl,
            params: {
                title: 'BFL',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.bfl, pData,
                        eFilterType.RANGE, pToChange)
            }
        });

        let aRadiusMin = (eUnitType.MILLIMETERS == this.mFilterUnitScale) ? -3048 : -120;
        let aRadiusMax = (eUnitType.MILLIMETERS == this.mFilterUnitScale) ? 2032 : 80;

        let aRadiusesSliderOptions: iBootstrapSliderOptions = {
            min: aRadiusMin,
            max: aRadiusMax,
            value: [aRadiusMin, aRadiusMax],
            enabled: true,
            precision: 2
        };

        this._createFilter({
            data: {
                data: aRadiusesSliderOptions,
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.r1,
            params: {

                title: 'R1',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.r1, pData,
                        eFilterType.RANGE, pToChange)
            }
        });

        this._createFilter({
            data: {
                data: aRadiusesSliderOptions,
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.r2,
            params: {
                title: 'R2',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.r2, pData,
                        eFilterType.RANGE, pToChange)
            }
        });

        this._createFilter({
            data: {
                data: {
                    min: 0,
                    max: 5000,
                    value: [0, 5000],
                    enabled: true
                },
                isCanChangeUnit: false,
                unit: 'nm',
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.cut_off,
            params: {
                title: 'Cut off frequency',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.cut_off, pData,
                        eFilterType.RANGE, pToChange)
            }
        });

        this._createFilter({
            data: {
                data: {
                    min: 0,
                    max: 5000,
                    value: [0, 5000],
                    enabled: true
                },
                isCanChangeUnit: false,
                unit: 'nm',
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.cut_on,
            params: {
                title: 'Cut on frequency',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.cut_on, pData,
                        eFilterType.RANGE, pToChange)
            }
        });

        this._createFilter({
            data: {
                data: {
                    min: 100,
                    max: 5000,
                    value: [100, 5000],
                    enabled: true
                },
                isCanChangeUnit: false,
                unit: 'L/mm',
                filterHandler: this
            },
            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eOMFilterName.grooves,
            params: {
                title: 'Grooves/mm',
                hideResetBtn: false,
                onChange: (pData: any, pToChange: boolean) =>
                    this._onIngredientChange(eOMFilterName.grooves, pData,
                        eFilterType.RANGE, pToChange)
            }
        });
    }
    //__________________________________________________________________________________________
    public getFilters() {
        let aFilters = Object.assign({}, this.mFilters, this.mSpecificFilters);
        return aFilters;
    }
    //__________________________________________________________________________________________
    public setFilters(pData: Array<any>) {
        if (pData == null || Object.keys(pData).length === 0) {
            return;
        }

        for (let key in this.mFilterComponents) {
            this.mFilterComponents[key].setData(key, pData);
        }
    }
    //__________________________________________________________________________________________
}
