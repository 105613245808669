import { EventBase } from "../../../oc/events/EventBase";
import { EventManager } from "../../../oc/events/EventManager";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { eBaseShape, eOpticsTypeNames, OpticsContext } from "../../_context/OpticsContext";
import { OpticsDefaults } from "../../_context/OpticsDefaults";
import { iClientPoint } from "../../_context/_interfaces/Interfaces";
import { iOpticsVO } from "../../data/VO/OpticsVOInterfaces";
import { OptixPartDisplayer } from "../../parts/_parts_assets/OptixPartDisplayer";
import { PartsCatalog } from "../../parts/_parts_assets/PartsCatalog";
import { SceneContext } from "../../scene/SceneContext";
import { eClickMode } from "../_globals/PartsEventsHandler";
import { AnalysesSynchronizer } from "../analysis/AnalysesSynchronizer";
import { AnalysisContext } from "../analysis/AnalysisContext";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { ChoosePartMode } from "../forms/ChoosePartMode";
import { FloatingInput } from "../forms/FloatingInput";
import { ImportCad } from "../forms/tools/ImportCad";
import { piMoveRotateSection } from "../part_info/piMoveRotateSection";
import { Menu } from "./Menu";
import { iSideBarListData, eListItemType, iSidebarLabelStructure } from "./SideBar";
import { op3dTooltip } from "./op3dTooltip";
import { Strings } from "../../_context/Strings";
import { OpticsDataLoader } from "../../data/data_loader/OpticsDataLoader";
import { MovementBehavior } from "../../parts/behaviors/MovementBehavior";
import { ServerContext } from "../../server/ServerContext";
import { OpticsMenu } from "../menus/_search/OpticsMenu";
import { Group } from "../forms/Group";
import { UploadFileFormCadNew } from "../../parser/UploadFileFormCadNew";
import { OpticsFactory } from "../../parts/optics/OpticsFactory";
import { SurfaceContext } from "../../parts/optics/SurfaceContext";
import { eAxisType, eCadType, eDataPermission } from "../../_context/Enums";
import { OptomechanicsSurfaceManager } from "../forms/optics/OptomechanicsSurfaceManager";
import { UploadOpticsFormNew } from "../forms/optics/new/UploadOpticsFormNew";
import { EditOpticsForm } from "../forms/optics/new/EditOpticsForm";
import { eFormType } from "../forms/optics/new/UploadOpticsContext";
import { Part } from "../../parts/Part";
import { eGroupType, ePartType, iAxis, iFace, iPart } from "../../parts/PartInterfaces";
import { UnitHandler } from "../../units/UnitsHandler";
import { DataUtils } from "../../_utils/DataUtils";
import { eTreeIcons, iSingleTreeItem } from "./SideBarListContext";
import { SimulationRunner } from "../../simulation/SimulationRunner";

export class SidebarList {

    private mListParent: HTMLElement;
    private mSelectedNode!: JSTreeNode<iSideBarListData>;
    private mTooltip!: op3dTooltip;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        this.mListParent = pContainer;
        this._init();
        this._addEventListeners();
    }
    //__________________________________________________________________________________________
    public openContextMenu(pPart: Part, e: MouseEvent,
        pManualEvents?: JSTreeContextMenuItems) {
        let aNode = (null != pPart) ? this._getNodeByPart(pPart) : this._getGenaralNode();

        let aEvent = e as iOP3DCMEvent;
        aEvent.manualEvents = pManualEvents;

        (<any>$(this.mListParent)).jstree(true).show_contextmenu(aNode, e.clientX, e.clientY, aEvent);
    }
    //__________________________________________________________________________________________
    private _getGenaralNode() {
        return $(this.mListParent).find('[id="general_node"]')[0];
    }
    //__________________________________________________________________________________________
    public updatePartsList(pParts: Array<Part>) {
        let aPartsList = new Array<iSingleTreeItem<iSideBarListData>>();
        for (let i = 0; i < pParts.length; i++) {
            if (((pParts[i].refCS != null) &&
                (ePartType.GROUP === pParts[i].refCS.refPart.partOptions.type)) ||
                (true === pParts[i].isDeprecated)) {
                continue;
            }

            aPartsList.push(...this._getPartTreeSubpartsData(pParts[i], pParts[i].iPart));
        }

        aPartsList.push({
            icon: null,
            text: null,
            data: {
                item: null,
                part: null,
                type: eListItemType.GENERAL,
                labelInfo: null
            },
            li_attr: {
                'class': 'd-none'
            },
            id: 'general_node'
        });

        $(this.mListParent).jstree(true).settings.core.data = aPartsList;
        $(this.mListParent).jstree(true).refresh(false, true);


    }
    //__________________________________________________________________________________________
    public openAll() {
        (<any>$(this.mListParent)).jstree(true).open_all();
    }
    //__________________________________________________________________________________________
    public deselectNode(pFromSidebar: boolean = false) {
        if (null == this.mSelectedNode) {
            return;
        }

        //$(this.mListParent).jstree(true).deselect_node(this.mSelectedNode.id, true);

        if ((null == this.mSelectedNode.data) ||
            (null == this.mSelectedNode.data.deselectNodeFunc)) {
            this.mSelectedNode = null;
            return;
        }

        this.mSelectedNode.data.deselectNodeFunc(pFromSidebar);
        this.mSelectedNode = null;
    }
    //__________________________________________________________________________________________
    private _init() {
        this.mTooltip = new op3dTooltip();
        $.jstree.defaults.core.worker = false

        let aJSTreeStaticDefaults: JSTreeStaticDefaults = {
            core: {
                "themes": {
                    stripes: false,
                    dots: true,
                },
                error: null,
                multiple: false,
            },
            check_callback: true,
            plugins: ['contextmenu', 'sort'],
            contextmenu: {
                items: ($node) => this._showListContextMenu($node),
                select_node: false,
                show_at_node: false
            }
        }

        let aTooltipFunc = () => $(this.mListParent).find('[data-toggle="tooltip"]').each(
            (_index, pElement) => {
                let aText = (pElement as HTMLElement).getAttribute('tooltip_text');
                (pElement as HTMLElement).addEventListener('mouseenter', (_e: MouseEvent) => {
                    let aBB = pElement.getBoundingClientRect();
                    this.mTooltip.show({ clientX: aBB.right, clientY: aBB.top }, aText);
                });
                pElement.addEventListener('mouseleave', () => this.mTooltip.hide());
            }
        );

        $(this.mListParent).jstree(aJSTreeStaticDefaults);


        $(this.mListParent).on('refresh.jstree', aTooltipFunc);
        $(this.mListParent).on('after_open.jstree', aTooltipFunc);
        // $(this.mListParent).on('after_open.jstree', aTooltipFunc);
        // $(this.mListParent).on('after_open.jstree', aTooltipFunc);
        $(this.mListParent).on('select_node.jstree',
            (_e, data) => this._onSelectNode(data.node));

        this.mListParent.addEventListener('mouseup', (e: Event) => e.stopPropagation());

        EventManager.addEventListener(EventsContext.PART_DESELECTED,
            (pEventBase: EventBase<Part>) =>
                this._onDeselect(pEventBase.data), this);

        this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
    }
    //__________________________________________________________________________________________
    private _addEventListeners() {
        EventManager.addEventListener(EventsContext.UPDATE_PARTS_LIST,
            (pData: {
                data: {
                    parts: Array<Part>;
                }
            }) => this.updatePartsList(pData.data.parts), this);

        EventManager.addEventListener<Part>(EventsContext.PART_SELECTED,
            (pData: EventBase<Part>) =>
                this._onPartSelected(pData.data), this);

        EventManager.addEventListener(EventsContext.UNDO,
            () => this.updatePartsList(Op3dContext.PARTS_MANAGER.parts), this);

        EventManager.addEventListener(EventsContext.REDO,
            () => this.updatePartsList(Op3dContext.PARTS_MANAGER.parts), this);
    }
    //__________________________________________________________________________________________
    private _onPartSelected(pPart: Part) {
        this._selectPartOnList(pPart);
    }
    //__________________________________________________________________________________________
    private _selectPartOnList(pPart: Part) {
        let aPartNode = this._getNodeByPart(pPart);
        if (null == aPartNode) {
            return;
        }

        aPartNode.scrollIntoView({ behavior: "smooth", block: "start" });

        $(this.mListParent).jstree(true).open_node(aPartNode);
        $(this.mListParent).jstree(true).select_node(aPartNode, true);
    }
    //__________________________________________________________________________________________
    private _getPartTreeSubpartsData(pPart: Part, pPartData: iPart) {
        let aPartsList = new Array<iSingleTreeItem<iSideBarListData>>();
        let aPartName = pPartData.name;

        let aIndexedLabel = pPart.getIndexedLabel(true);
        let aPartLabel: string;
        if (null == aIndexedLabel) {
            aIndexedLabel = aPartName;
            aPartLabel = aPartName;
        } else {
            aPartLabel = pPart.getLabel().label;
        }

        let aSubParts = new Array<iSingleTreeItem<iSideBarListData>>();
        if (ePartType.GROUP === pPart.partOptions.type) {
            if (null != pPart.linkedParts) {
                for (let j = 0; j < pPart.linkedParts.length; j++) {
                    aSubParts.push(...this._getPartTreeSubpartsData(pPart.linkedParts[j],
                        pPart.linkedParts[j].iPart));
                }
            }
        }

        if (null != pPartData.subParts) {
            for (let j = 0; j < pPartData.subParts.length; j++) {
                let aSubPartData = pPartData.subParts[j];

                let aAxes = new Array<iSingleTreeItem<iSideBarListData>>();
                if (null != aSubPartData.axes) {
                    for (let axisIndex = 0; axisIndex < aSubPartData.axes.length; axisIndex++) {
                        aAxes.push(this._getCoordinateSystem(pPart,
                            aSubPartData.axes[axisIndex]));
                    }
                }

                if (null != aSubPartData.subParts) {
                    let aCurrSubParts = this._getPartTreeSubpartsData(pPart, aSubPartData);
                    if ((null != aCurrSubParts) && (aCurrSubParts.length > 0)) {
                        aSubParts.push(...aCurrSubParts);
                    }
                } else {

                    let aPrefix = '';
                    if ((null != aSubPartData.data) &&
                        // (true == aSubPartData.data.isCustomizedOptics) &&  (ePartType.PARAXIAL_LENS != pPart.partOptions.type)) {
                        (true == aSubPartData.data.isCustomizedOptics)) {
                        aPrefix = '<strong>(customized) </strong>';
                    }

                    let aShapes = this._getPartTreeSolidsData(pPart, aSubPartData);
                    if (null != aShapes) {

                        let aIcon: any;

                        const aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id);
                        if ((null != aSubPartData.data) &&
                            (null != aSubPartData.data.number_id)) {
                            aIcon = aSubPartData.data.isAperture ? eTreeIcons.APERTURE : eTreeIcons.OPTICS
                        } else if (aPartVO != null && aPartVO.isBlackBox == true) {
                            aIcon = eTreeIcons.BLACK_BOX
                        } else {
                            aIcon = eTreeIcons.PART
                        }

                        if (aShapes.length > 1) {
                            if (aAxes.length > 0) {
                                aShapes.unshift(...aAxes);
                            }

                            let aIsPart = (null != pPart.id);

                            let aSelectNodeFunc = (false == aIsPart) ?
                                () => this._selectPart(pPart) : () => {
                                    switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                                        case eClickMode.BASE:
                                            Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                                            let aDisplayer = OptixPartDisplayer;
                                            if (ePartType.GROUP === pPart.partOptions.type) {
                                                aDisplayer.highlightObject(pPart);
                                            } else {
                                                aDisplayer.highlightPart(aSubPartData);
                                            }

                                            break;
                                        default:
                                            break;
                                    };
                                };

                            let aDeselectNodeFunc = (false == aIsPart) ? null :
                                () => {
                                    let aDisplayer = OptixPartDisplayer;
                                    if (ePartType.GROUP === pPart.partOptions.type) {
                                        aDisplayer.unHighlightObject(pPart);
                                    } else {
                                        aDisplayer.unhighlightPart(aSubPartData);
                                    }

                                }


                            let aText = (true == aIsPart) ? aIndexedLabel :
                                (aPrefix + aSubPartData.name);

                            let aLabelInfo: iSidebarLabelStructure;

                            if (true == aIsPart) {
                                aLabelInfo = {
                                    currValue: aPartLabel,
                                    actionTitle: 'Change label',
                                    changeFunc: (pValue) => pPart.setPartLabel(pValue, true)
                                };
                            } else {
                                aLabelInfo = {
                                    currValue: aSubPartData.name,
                                    actionTitle: 'Rename',
                                    changeFunc: (pValue) => aSubPartData.name = pValue
                                };
                            }

                            aSubParts.push({
                                icon: aIcon,
                                text: aText,
                                data: {
                                    labelInfo: aLabelInfo,
                                    item: aSubPartData,
                                    part: pPart,
                                    type: eListItemType.SHAPE,
                                    selectNodeFunc: aSelectNodeFunc,
                                    deselectNodeFunc: aDeselectNodeFunc
                                },
                                a_attr: {
                                    'data-toggle': 'tooltip',
                                    'tooltip_text': aSubPartData.name
                                },
                                li_attr: {
                                    'p_id': (true == aIsPart) ? pPart.visibleObj.uuid :
                                        aSubPartData.object3D.uuid
                                },
                                id: aSubPartData.object3D.uuid,
                                children: aShapes
                            });

                        } else {
                            if (aAxes.length > 0) {
                                let aChildren = aShapes[0].children;
                                if (null != aChildren) {
                                    aChildren.unshift(...aAxes);
                                } else {
                                    aChildren = aAxes;
                                }
                            }

                            let aSelectNodeFunc = () => {
                                switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                                    case eClickMode.BASE:
                                        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                                        let aDisplayer = OptixPartDisplayer;
                                        aDisplayer.highlightPart(aSubPartData);
                                        break;
                                    default:
                                        break;
                                };
                            };

                            aSubParts.push({
                                icon: aIcon,
                                text: (aPrefix + aSubPartData.name),
                                data: {
                                    labelInfo: {
                                        currValue: aSubPartData.name,
                                        actionTitle: 'Rename',
                                        changeFunc: (pValue) => aSubPartData.name = pValue
                                    },
                                    item: aSubPartData,
                                    part: pPart,
                                    type: eListItemType.SHAPE,
                                    selectNodeFunc: aSelectNodeFunc,
                                    deselectNodeFunc: () => {
                                        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                                        OptixPartDisplayer.unhighlightPart(aSubPartData);
                                    }
                                },
                                a_attr: {
                                    'data-toggle': 'tooltip',
                                    'tooltip_text': aSubPartData.name
                                },
                                li_attr: {
                                    'p_id': pPart.visibleObj.uuid
                                },
                                id: aSubPartData.object3D.uuid,
                                children: aShapes[0].children
                            });
                        }
                    }
                }
            }
        }

        let aAxes = new Array<iSingleTreeItem<iSideBarListData>>();
        if (null != pPartData.axes) {
            for (let axisIndex = 0; axisIndex < pPartData.axes.length; axisIndex++) {
                aAxes.push(this._getCoordinateSystem(pPart, pPartData.axes[axisIndex]));
            }
        }


        if (aSubParts.length > 1) {
            if (aAxes.length > 0) {
                aSubParts.unshift(...aAxes);
            }

            aPartsList.push({
                icon: (ePartType.GROUP === pPart.partOptions.type) ?
                    ((pPart.visibleObj.visible === false) ? eTreeIcons.HIDE : eTreeIcons.GROUP) :
                    eTreeIcons.PARTS,
                text: aIndexedLabel,
                data: {
                    labelInfo: {
                        currValue: aPartLabel,
                        actionTitle: 'Change label',
                        changeFunc: (pValue) => pPart.setPartLabel(pValue, true)
                    },
                    item: pPartData,
                    part: pPart,
                    type: eListItemType.PART,
                    selectNodeFunc: () => this._selectPart(pPart),
                },
                a_attr: {
                    'data-toggle': 'tooltip',
                    'tooltip_text': aIndexedLabel
                },
                li_attr: {
                    'p_id': pPart.visibleObj.uuid
                },
                id: pPart.visibleObj.uuid,
                children: aSubParts
            });
        } else if (1 == aSubParts.length) {
            if (aAxes.length > 0) {
                if (null == aSubParts[0].children) {
                    aSubParts[0].children = aAxes;
                } else {
                    aSubParts[0].children.unshift(...aAxes);
                }
            }

            if ((ePartType.PARAXIAL_LENS == pPart.partOptions.type) ||
                (eTreeIcons.OPTICS != aSubParts[0].icon) ||
                ((eTreeIcons.OPTICS == aSubParts[0].icon) && (null == pPart.id))) {

                aSubParts[0].data.selectNodeFunc = () => this._selectPart(pPart);
                aSubParts[0].data.deselectNodeFunc = null;
                aSubParts[0].data.type = eListItemType.PART;

                let aTextPrefix = '';
                if ((null != aSubParts[0].data.item.data) &&
                    (true == aSubParts[0].data.item.data.isCustomizedOptics) &&
                    (ePartType.PARAXIAL_LENS != pPart.partOptions.type)) {
                    aTextPrefix = '<strong>(customized) </strong>';
                }

                aSubParts[0].text = (aTextPrefix + aIndexedLabel);
                aSubParts[0].data.labelInfo = {
                    currValue: aPartLabel,
                    actionTitle: 'Change label',
                    changeFunc: (pValue) => pPart.setPartLabel(pValue, true)
                };
                aSubParts[0].a_attr['tooltip_text'] = aIndexedLabel;
                aSubParts[0].id = pPart.visibleObj.uuid;
            }

            aPartsList.push(aSubParts[0]);
        } else if ((ePartType.CS === pPart.partOptions.type) ||
            (true === pPart.partOptions.static)) {
            let aAxisPart = this._getCSPart(pPart, aPartName, aAxes[0]);
            aPartsList.push(aAxisPart);
        } else {

            let aChildren = this._getPartTreeSolidsData(pPart, pPartData);
            if (aAxes.length > 0) {
                if (null != aChildren) {
                    aChildren.unshift(...aAxes);
                }

                aChildren = aAxes;
            }

            let aSelectNodeFunc = () => {
                switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                    case eClickMode.BASE:
                        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                        let aDisplayer = OptixPartDisplayer;
                        aDisplayer.highlightPart(pPartData);
                        break;
                    default:
                        break;
                };
            };

            let aPartListItem: iSingleTreeItem<iSideBarListData> = {
                icon: eTreeIcons.PART,
                text: aIndexedLabel,
                data: {
                    labelInfo: {
                        currValue: aPartLabel,
                        actionTitle: 'Change label',
                        changeFunc: (pValue) => pPart.setPartLabel(pValue, true)
                    },
                    item: pPartData,
                    part: pPart,
                    type: eListItemType.PART,
                    selectNodeFunc: aSelectNodeFunc,
                    deselectNodeFunc: () => {
                        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                        OptixPartDisplayer.unhighlightPart(pPartData);
                    }
                },
                a_attr: {
                    'data-toggle': 'tooltip',
                    'tooltip_text': aIndexedLabel
                },
                li_attr: {
                    'p_id': pPartData.object3D.uuid
                },
                id: pPartData.object3D.uuid,
                children: aChildren
            };

            // if (pPart.id == 'GCS') {
            //     aPartListItem.data.item = pPart.subParts[0];
            //     aPartListItem.children = null;
            // }


            aPartsList.push(aPartListItem);

        }

        return aPartsList;
    }
    //__________________________________________________________________________________________
    private _getPartTreeSolidsData(pPart: Part, pPartData: iPart) {
        if ((null == pPartData.shapes) || (0 == pPartData.shapes.length)) {
            return null;
        }

        let aShape = pPartData.shapes[0];
        const aAnalysisOptions = pPart.analysisOptions != null ? pPart.analysisOptions : [];

        let aSolids = new Array<iSingleTreeItem<iSideBarListData>>();
        for (let i = 0; i < aShape.solids.length; i++) {
            let aFaces = new Array<iSingleTreeItem<iSideBarListData>>();
            let aSolidFaces = aShape.solids[i].faces;
            if ((null != aSolidFaces) && (aSolidFaces.length > 1)) {
                for (let j = 0; j < aSolidFaces.length; j++) {
                    if (false == aSolidFaces[j].isForSimulation) {
                        continue;
                    }

                    let aMesh = aSolidFaces[j].visualization.mesh;
                    let aHasAnalysis = (aAnalysisOptions.findIndex(
                        option => option.faceId == aSolidFaces[j].internal_id) > -1);

                    let aIcon = (true == aHasAnalysis) ? eTreeIcons.ANALYSIS :
                        eTreeIcons.SOLID;
                    let aText = (true == aHasAnalysis) ? 'Active: ' : '';
                    aText += aSolidFaces[j].name;

                    let aFace: iSingleTreeItem<iSideBarListData> = {
                        icon: aIcon,//eTreeIcons.SOLID,
                        text: aText,
                        data: {
                            labelInfo: {
                                currValue: aSolidFaces[j].name,
                                actionTitle: 'Rename surface',
                                changeFunc: (pValue) => aSolidFaces[j].name = pValue
                            },
                            part: pPart,
                            item: aSolidFaces[j],
                            type: eListItemType.FACE,
                            selectNodeFunc: () => {
                                switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                                    case eClickMode.BASE:
                                        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                                        // OptixPartDisplayer.highlightFace(aSolidFaces[j]);
                                        break;
                                    default:
                                        break;
                                }
                            },
                            // deselectNodeFunc: () => {
                            //     OptixPartDisplayer.unhighlightFace(aSolidFaces[j])
                            // }
                        },
                        li_attr: {
                            'p_id': aMesh.uuid
                        },
                        a_attr: {
                            'data-toggle': 'tooltip',
                            'tooltip_text': aSolidFaces[j].name

                        },
                        id: aMesh.uuid
                    };

                    let aAxes = new Array<iSingleTreeItem<iSideBarListData>>();
                    if (null != aSolidFaces[j].axes) {
                        for (let k = 0; k < aSolidFaces[j].axes.length; k++) {
                            aAxes.push(this._getCoordinateSystem(pPart, aSolidFaces[j].axes[k]))
                        }
                    }

                    if (aAxes.length > 0) {
                        aFace.children = aAxes;
                    }

                    aFaces.push(aFace);
                }
                aSolids.push({
                    icon: eTreeIcons.SOLID,
                    text: aShape.solids[i].name,
                    data: {
                        labelInfo: {
                            currValue: aShape.solids[i].name,
                            actionTitle: 'Rename',
                            changeFunc: (pValue) => aShape.solids[i].name = pValue
                        },
                        part: pPart,
                        item: aShape.solids[i],
                        type: eListItemType.SOLID,
                        selectNodeFunc: () => {
                            switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                                case eClickMode.BASE:
                                    Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                                    // OptixPartDisplayer.highlightSolid(aShape.solids[i]);
                                    break;
                                default:
                                    break;
                            }
                        },
                        // deselectNodeFunc: () => {
                        //     OptixPartDisplayer.unhighlightSolid(aShape.solids[i]);
                        // }
                    },
                    a_attr: {
                        'data-toggle': 'tooltip',
                        'tooltip_text': aShape.solids[i].name
                    },
                    id: aShape.solids[i].object3D.uuid,
                    children: aFaces
                });

            } else {
                aSolids.push({
                    icon: eTreeIcons.SOLID,
                    text: aShape.solids[i].name,
                    data: {
                        labelInfo: {
                            currValue: aShape.solids[i].name,
                            actionTitle: 'Rename',
                            changeFunc: (pValue) => aShape.solids[i].name = pValue
                        },
                        part: pPart,
                        item: aShape.solids[i],
                        type: eListItemType.SOLID,
                        selectNodeFunc: () => {
                            switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                                case eClickMode.BASE:
                                    Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                                    // OptixPartDisplayer.highlightSolid(aShape.solids[i]);
                                    break;
                                default:
                                    break;
                            }
                        },
                        // deselectNodeFunc: () => {
                        //     OptixPartDisplayer.unhighlightSolid(aShape.solids[i]);
                        // }
                    },
                    a_attr: {
                        'data-toggle': 'tooltip',
                        'tooltip_text': aShape.solids[i].name
                    },
                    id: aShape.solids[i].object3D.uuid
                });
            }
        }

        return aSolids;
    }
    //__________________________________________________________________________________________
    private _getCSPart(pPart: Part, _pPartName: string,
        pAxis: iSingleTreeItem<iSideBarListData<any>>) {

        let aTooltip = '';

        if (true == pPart.partOptions.static) {
            aTooltip = 'Global coordinate system';
            delete pAxis.data.labelInfo;
            pAxis.text = 'GCS';
        } else {
            let aText = 'CS';
            aTooltip = 'Coordinate system';

            if (null != pAxis.data.item.name) {
                aText += ' :';
                aText += pAxis.data.item.name;

                aTooltip += ' :';
                aTooltip += pAxis.data.item.name;

            } else {
                aTooltip = 'Coordinate system';
            }
            pAxis.text = aText;
        }

        pAxis.a_attr = {
            'data-toggle': 'tooltip',
            'tooltip_text': aTooltip
        };
        pAxis.id = pPart.visibleObj.uuid;
        pAxis.li_attr = {
            'p_id': pPart.visibleObj.uuid
        };

        return pAxis;
    }
    //__________________________________________________________________________________________
    private _onSelectNode(pNode: JSTreeNode<iSideBarListData>) {
        //added Op3dContext.PARTS_EVENTS_HANDLER.mode !== eClickMode.GROUP to select/deselect parts from sidebar
        if ((null != this.mSelectedNode) && (pNode.id == this.mSelectedNode.id && Op3dContext.PARTS_EVENTS_HANDLER.mode !== eClickMode.GROUP)) {
            return;
        }

        //$(this.mListParent).jstree(true).select_node(pNode.id, true);
        this.deselectNode(true);
        if ((null == pNode.data) || (null == pNode.data.selectNodeFunc)) {
            return;
        }

        pNode.data.selectNodeFunc();
        this.mSelectedNode = pNode;
    }
    //__________________________________________________________________________________________
    private _onDeselect(pPart: Part) {
        let aNode = this._getNodeByPart(pPart);
        if (null == aNode) {
            return;
        }

        $(this.mListParent).jstree(true).deselect_node(aNode.id);
    }
    //__________________________________________________________________________________________
    private _getNodeByPart(pPart: Part) {
        let aUUID = pPart.visibleObj.uuid;
        // $(this.mListParent).jstree(true).open_all()
        let aPartNode = $(this.mListParent).find('[p_id="' + aUUID + '"]')[0];

        if (aPartNode == undefined) {
            $(this.mListParent).jstree(true).open_all()
            aPartNode = $(this.mListParent).find('[p_id="' + aUUID + '"]')[0];
            $(this.mListParent).jstree(true).close_all()
        }

        return aPartNode;
    }
    //__________________________________________________________________________________________
    private _showListContextMenu(pCMEvent: JSTREEContextMenuEvent): JSTreeContextMenuItems {
        if (Op3dContext.PARTS_EVENTS_HANDLER.mode != eClickMode.BASE) {
            return null;
        }

        let aPointerEvent = pCMEvent.event;
        if (null != aPointerEvent.manualEvents) {
            return aPointerEvent.manualEvents;
        }

        let aNode = pCMEvent.node;

        if ((null == aNode.data) || (null == aNode.data.type)) {
            return null;
        }

        let aJSTreeContextMenuItems: JSTreeContextMenuItems;

        switch (aNode.data.type) {
            case eListItemType.GENERAL:
                aJSTreeContextMenuItems = this._getGeneralOptions(aPointerEvent);
                break;
            case eListItemType.COORDINATE_SYSTEM:
                aJSTreeContextMenuItems = this._getCSOptions(aNode, aPointerEvent);
                break;
            case eListItemType.PART:
                aJSTreeContextMenuItems = this._getPartOptions(aNode, aPointerEvent);
                break;
            case eListItemType.SHAPE:
                aJSTreeContextMenuItems = this._getSubpartOptions(aNode);
                break;
            case eListItemType.FACE:
                aJSTreeContextMenuItems = this._getFaceOptions(aNode, aPointerEvent);
                break;
            default:
                if ((null != aNode.data.changeNameFunc)) {
                    if (null == aJSTreeContextMenuItems) {
                        aJSTreeContextMenuItems = {};
                    }

                    aJSTreeContextMenuItems['change_name'] = {
                        label: 'Rename',
                        action: () => {
                            let aInitialValue = aNode.data.label;

                            FloatingInput.instance.open({
                                callback: (pValue) => {
                                    if ((null == pValue) || ('' == pValue)) {
                                        return;
                                    }

                                    aNode.data.changeNameFunc(pValue);
                                    this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                                },
                                saveState: true,
                                initialValue: aInitialValue,
                                position: { x: aPointerEvent.clientX, y: aPointerEvent.clientY },
                            })

                        },
                        icon: "bi bi-input-cursor-text"
                    }
                }

                break;
        }

        return aJSTreeContextMenuItems;
    }
    //__________________________________________________________________________________________
    private _getGeneralOptions(e: MouseEvent) {

        let aAddObjectOptions = {
            isDisabled: false, label: "Add user-defined object",
        };
        if (Op3dContext.USER_VO.isBasicLicense === true) {
            aAddObjectOptions.label += `<label onclick="window.open('${ServerContext.pricing_base_link}', '_blank');" class="ml-2 pf_button pf_small"> </label>`;
            aAddObjectOptions.isDisabled = true;
        }

        let aJSTreeContextMenuItems: JSTreeContextMenuItems = {

            'open_optics_menu': {
                label: 'Open Product Catalog',
                action: () =>
                    OpticsMenu.instance.open({
                        openMenuPoint: { clientX: e.clientX, clientY: e.clientY }
                    }),

                icon: eTreeIcons.OPTICS
            },

            'add_ls': {
                label: 'Add light source',
                action: async () => {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                    const aPartVO = Op3dContext.DATA_MANAGER.getPartVOById("L1111");
                    let aLaser = await PartsCatalog.instance.getDynamicPart(aPartVO);
                    if (aLaser !== undefined) {
                        aLaser.moveOptixPart(e);
                        Op3dContext.SCENE_HISTORY.saveScene();
                        Op3dContext.PARTS_MANAGER.updateAnalysisOptions(false);
                        EventManager.dispatchEvent(EventsContext.PART_ADDED, this);
                        Op3dContext.PARTS_MANAGER.updatePartsList(false);
                    }

                },
                icon: eTreeIcons.LASER
            },
            'add_detector': {
                label: 'Add detector',
                action: async () => {
                    await this._onAddDetectorElement({ clientX: e.clientX, clientY: e.clientY })
                    EventManager.dispatchEvent(EventsContext.PART_ADDED, this);
                    Op3dContext.PARTS_MANAGER.updatePartsList(false);
                },
                icon: eTreeIcons.SCREEN
            },
            'add_cs': {
                label: 'Add coordinate system',
                action: () => {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                    let aPart = new Part({ id: 'CS', options: { type: ePartType.CS } });
                    aPart.moveOptixPart(e);
                    Op3dContext.PARTS_MANAGER.updatePartsList(false);
                    Op3dContext.SCENE_HISTORY.saveScene();
                },
                icon: eTreeIcons.AXIS
            },
            'add_paraxial_lens': {
                label: 'Add paraxial lens',
                action: () => {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                    let aPart = new Part({
                        id: Strings.PARAXIAL_LENS_NAME,
                        options: {
                            type: ePartType.PARAXIAL_LENS
                        }
                    });

                    let aParaxialLensVO = OpticsFactory.getDefaultParaxialLensVO();
                    Op3dContext.PARTS_MANAGER.addParaxialLens(aPart, aParaxialLensVO);
                    aPart.moveOptixPart(e);
                    Op3dContext.PARTS_MANAGER.updatePartsList(false);
                    Op3dContext.SCENE_HISTORY.saveScene();
                },
                icon: eTreeIcons.CIRCLE
            },
            'add_optomechanical_lens': {
                label: 'Import CAD',
                action: () => {
                    Op3dContext.SCENE_HISTORY.addToHistory();

                    let aLimit = Op3dContext.USER_VO.isBasicLicense ?
                        Menu.LIMIT_CAD_FILE.FREE : Menu.LIMIT_CAD_FILE.PREMIUM;
                    UploadFileFormCadNew.instance.open({
                        func: (pFiles, pCadData) => ImportCad.instance.convertStepToOptix(pFiles.fileList[0], pCadData),
                        hasToReplace: false,
                        title: `Upload a STEP file (up to ${aLimit}MB)`,
                        acceptedFormats: '.stp, .step',
                        acceptedSizeMb: aLimit,
                        allowedNameRegex: /^[0-9a-zA-Z!.\-_*'()]+$/
                    })

                    Op3dContext.PARTS_MANAGER.updatePartsList(false);
                    Op3dContext.SCENE_HISTORY.saveScene();
                },
                icon: eTreeIcons.PART
            },
            'add_object': {
                label: aAddObjectOptions.label,
                _disabled: aAddObjectOptions.isDisabled,
                action: () => {
                    if (Op3dContext.USER_VO.isBasicLicense === true) {
                        return;
                    }

                    UploadOpticsFormNew.instance.open({
                        formType: eFormType.UPLOAD,
                        openMenuPoint: { clientX: e.clientX, clientY: e.clientY },
                        opticsVO: OpticsDefaults.getDefaultOpticsVO(eOpticsTypeNames.LENS,
                            OpticsContext._Spherical_Lens_Plano_Convex,
                            eBaseShape.CIRCULAR,
                            UnitHandler.PRESENTED_UNIT)
                    });
                },
                icon: eTreeIcons.PART

            }
        };

        return aJSTreeContextMenuItems;
    }

    //__________________________________________________________________________________________
    private async _onAddDetectorElement(pPoint: iClientPoint) {
        Op3dContext.SCENE_HISTORY.addToHistory();

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById("D1012");
        let aDetector = await PartsCatalog.instance.getDynamicPart(aPartVO);
        let aFrontFace: string = null;
        try {
            aFrontFace = aDetector.getFaces().find(face => face.name == SurfaceContext.FRONT).internal_id;
        } catch (e) {
            aFrontFace = null;
        }

        let aDefaultAnalysis = AnalysisContext.getDefaultAnalysis();
        aDetector.addAnalysisOption({
            advanced: [],
            fast: [aDefaultAnalysis],
            faceId: aFrontFace
        });

        aDetector.moveOptixPart(pPoint);
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _removeAnalysisOption(pPart: Part, pFaceId: string) {
        Op3dContext.SCENE_HISTORY.addToHistory();

        pPart.removeAnalysisOption(pFaceId);
        AnalysisPortal.instance.removeSurfaceById(pFaceId);
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _addAnalysisOption(pPart: Part, pFaceId: string) {
        /**
         * @TODO analysis
         * defined whether the surface is curcular or not
         */


        Op3dContext.SCENE_HISTORY.addToHistory();
        let aDefaultAnalysis = AnalysisContext.getDefaultAnalysis();

        pPart.addAnalysisOption({
            advanced: [],
            fast: [aDefaultAnalysis],
            faceId: pFaceId
        });

        Op3dContext.SCENE_HISTORY.saveScene();
        AnalysisPortal.instance.update();
    }
    //__________________________________________________________________________________________
    private _openSurfaceSettings(pNumberID: string, pPart: Part, pFace: iFace) {

        let aOpticsVO = OpticsDataLoader.instance.getFromCache(pNumberID);

        EditOpticsForm.instance.open({
            formType: eFormType.EDIT,
            face: pFace,
            opticsVO: aOpticsVO,
            part: pPart
        })
    }
    //__________________________________________________________________________________________
    private _addSurfaceSettingsOption(pOptics: iPart, pFace: iFace,
        pItems: JSTreeContextMenuItems, pPart: Part) {

        let aCond1 = pOptics.data !== undefined && pOptics.data.number_id !== undefined;
        let aCond2 = pFace.data !== null && pFace.data !== undefined;
        let aCond3 = pOptics.data.isAperture == false;

        if (aCond1 === true && aCond2 === true && aCond3 === true) {
            pItems['surface_settings'] = {
                label: 'Surface settings',
                action: () => this._openSurfaceSettings(pOptics.data.number_id,
                    pPart, pFace),

                icon: eTreeIcons.GEAR
            }
        }
    }
    //__________________________________________________________________________________________
    private _getFaceOptions(pNode: JSTreeNode<iSideBarListData>, e: MouseEvent) {
        const aPart = pNode.data?.part;
        if (aPart === undefined) {
            throw new Error('aPart is undefined');
        }

        const aFaceId = pNode.data?.item.internal_id;
        const aFace = aPart.getFaceByID(aFaceId);
        if (aFace === null) {
            throw new Error('aFace is null');
        }

        let aJSTreeContextMenuItems: JSTreeContextMenuItems = {};
        let aFaceAnalysisExists = aPart.analysisOptions != null ?
            aPart.analysisOptions.find(item => item.faceId == aFaceId) != null :
            false;

        let aOptics = aPart.getPartOptics();
        if (null != aOptics) {

            this._addSurfaceSettingsOption(aOptics, aFace, aJSTreeContextMenuItems, aPart);



            if (null != pNode.data.labelInfo) {
                aJSTreeContextMenuItems['change_name'] = {
                    label: pNode.data.labelInfo.actionTitle,
                    action: () => {
                        FloatingInput.instance.open({
                            callback: (pValue) => {
                                if ((null == pValue) || ('' == pValue)) {
                                    return;
                                }
                                pNode.data.labelInfo.changeFunc(pValue);
                                this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                            },
                            saveState: true,
                            initialValue: pNode.data.labelInfo.currValue,
                            position: { x: e.clientX, y: e.clientY },
                        })
                    },
                    icon: "bi bi-input-cursor-text"
                }
            }
        }

        if (ePartType.BLACKBOX !== aPart.partOptions.type) {
            if (aFaceAnalysisExists == true) {
                aJSTreeContextMenuItems['remove_detecting_surface'] = {
                    label: 'Remove surface from detecting surfaces',
                    action: () => this._removeAnalysisOption(pNode.data.part, aFaceId),
                    icon: 'bi bi-square'
                }

            } else {
                aJSTreeContextMenuItems['add_detecting_surface'] = {
                    label: 'Add surface as a detecting surface',
                    action: () => this._addAnalysisOption(pNode.data.part, aFaceId),
                    icon: 'bi bi-square'
                }
            }
        }

        return aJSTreeContextMenuItems;
    }
    //__________________________________________________________________________________________
    private _getSubpartOptions(pNode: JSTreeNode<iSideBarListData>) {
        if ((null == pNode.data) || (null == pNode.data.item) ||
            (null == pNode.data.item.data) || (null == pNode.data.item.data.number_id)) {
            return null;
        }

        let aJSTreeContextMenuItems: JSTreeContextMenuItems = {};
        let aNumberID = pNode.data.item.data.number_id;
        let aOpticsVO: iOpticsVO = OpticsDataLoader.instance.getFromCache(aNumberID);
        if (null != aOpticsVO) {
            let aPart = pNode.data.part;
            // let aPartOptics = aPart.getPartOptics();

            aJSTreeContextMenuItems['optics_form'] = {
                label: 'Optical settings',
                action: () => EditOpticsForm.instance.open({
                    formType: eFormType.EDIT,
                    opticsVO: DataUtils.getObjectCopy(aOpticsVO),
                    part: aPart
                }),
                icon: eTreeIcons.GEAR
            };
        }



        return aJSTreeContextMenuItems;
    }
    //__________________________________________________________________________________________
    private _getPartOptions(pNode: JSTreeNode<iSideBarListData>, e: MouseEvent) {
        let aPart = Group.instance.findMainGroupPart(pNode.data.part)

        // if (Op3dContext.PARTS_MANAGER.selectedPart != null && aPart !== Op3dContext.PARTS_MANAGER.selectedPart) {
        //     aPart = Op3dContext.PARTS_MANAGER.selectedPart
        // }
        if (Op3dContext.PARTS_MANAGER.selectedPart != null && Op3dContext.PARTS_MANAGER.selectedPart === pNode.data.part) {
            aPart = Op3dContext.PARTS_MANAGER.selectedPart
        }
        // if(aPart.visibleObj.Op3dContext.PARTS_MANAGER.selectedPart)

        if ((false === aPart.visibleObj.visible) && (ePartType.GROUP !== aPart.partOptions.type)) {
            return;
        }

        if ('GCS' === aPart.id) {
            return this._getGCSOption(pNode);
        }
        let aJSTreeContextMenuItems: JSTreeContextMenuItems = {};

        if (ePartType.GROUP === aPart.partOptions.type) {
            if (aPart.visibleObj.visible == true) {
                aJSTreeContextMenuItems.ungroup = {
                    label: 'Ungroup',
                    action: () => {
                        aPart.unHighlightObject()
                        Group.instance.ungroup(aPart)

                    },
                    icon: 'icon-delete'
                };
            }


            if (aPart.visibleObj.visible == false) {
                aJSTreeContextMenuItems.show = {
                    label: 'Show',
                    action: () => {
                        Op3dContext.SCENE_HISTORY.addToHistory()
                        let aParts: Array<Part> = Group.instance.returnAllSubparts(pNode.data.part)
                        let aSubGroups: Array<Part> = Group.instance.returnAllSubpartGroups(pNode.data.part)
                        aParts.forEach(part => {
                            part.visibleObj.visible = true
                            part.labelVisiblity = false;
                            part.clickEvents(true)
                        })
                        aSubGroups.forEach(part => {
                            part.visibleObj.visible = true
                            part.clickEvents(true)
                        })

                        SceneContext.OP3D_SCENE.activateRenderer();
                        this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                        Op3dContext.SCENE_HISTORY.addToHistory()
                        Op3dContext.SCENE_HISTORY.saveScene()

                    },
                    icon: eTreeIcons.SHOW
                };
            } else {
                aJSTreeContextMenuItems.show = {
                    label: 'Hide',
                    action: () => {
                        Op3dContext.SCENE_HISTORY.addToHistory()
                        let aParts: Array<Part> = Group.instance.returnAllSubparts(Group.instance.findMainGroupPart(pNode.data.part))
                        let aSubGroups: Array<Part> = Group.instance.returnAllSubpartGroups(Group.instance.findMainGroupPart(pNode.data.part))
                        aParts.forEach(part => {
                            part.visibleObj.visible = false;
                            part.labelVisiblity = false;
                            part.clickEvents(false)
                        })
                        aSubGroups.forEach(part => {
                            part.visibleObj.visible = false
                            part.clickEvents(false)
                        })

                        SceneContext.OP3D_SCENE.activateRenderer();
                        this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                        Op3dContext.SCENE_HISTORY.addToHistory()
                        Op3dContext.SCENE_HISTORY.saveScene()

                    },
                    icon: eTreeIcons.HIDE
                };
            }

            if (aPart.visibleObj.visible == true && aPart.groupData.group_type == eGroupType.REGULAR) {
                aJSTreeContextMenuItems.edit_group = {
                    label: 'Edit group',
                    action: () => {
                        ChoosePartMode.instance.enterGroupMode()
                        Group.instance.editGroup(aPart)

                    },
                    icon: eTreeIcons.GROUP
                };
            }

            if (aPart.groupData.group_type === eGroupType.ARRAY_OF_ELEMENTS) {
                const aOriginalLinkedPart = aPart.linkedParts[0];

                let aPartOptics = aOriginalLinkedPart.getPartOptics();
                let aNumberID = aPartOptics !== null ?
                    aOriginalLinkedPart.getPartOptics().data.number_id : null;

                if (aNumberID !== null && aNumberID !== undefined) {
                    const aOpticsVO: iOpticsVO = OpticsDataLoader.instance.getFromCache(aNumberID);
                    aJSTreeContextMenuItems['optics_form'] = {
                        label: 'Optical settings',
                        action: () => {

                            Op3dContext.SCENE_HISTORY.addToHistory();

                            EditOpticsForm.instance.open({
                                formType: eFormType.EDIT,
                                opticsVO: DataUtils.getObjectCopy(aOpticsVO),
                                part: aOriginalLinkedPart,
                                groupEdit: true,
                                onFinishSave: async (pNewPart: Part) => {
                                    const aArrayData = aPart.groupData;
                                    let aArrayDataCopy = DataUtils.getObjectCopy(aArrayData);

                                    pNewPart.clearRef();
                                    await aPart.removeArrayOfElementsGroup();

                                    if (aArrayDataCopy !== undefined && aArrayDataCopy.is_array === true) {
                                        await pNewPart.setArrayOptions(aArrayDataCopy);
                                    }

                                    Op3dContext.SCENE_HISTORY.saveScene();

                                }
                            });
                        },
                        icon: eTreeIcons.GEAR
                    };
                }
            }
        }

        if (ePartType.GROUP === aPart.partOptions.type) {
            aJSTreeContextMenuItems.choose_lcs = {
                label: 'Change LCS',
                action: () => {
                    Op3dContext.PARTS_EVENTS_HANDLER.enterChooseLCSMode(aPart);
                },
                icon: eTreeIcons.AXIS
            };
        }

        let aCond1 = (ePartType.GROUP === aPart.partOptions.type);
        let aCond2 = (true === aPart.visibleObj.visible);

        let aHasMoveRotate = aCond1 || aCond2;
        if (aHasMoveRotate) {
            aJSTreeContextMenuItems.move_part = {
                label: 'Move / Rotate',
                action: () => {
                    Op3dContext.PART_INFO.setOneTimeOpenSect(piMoveRotateSection.name);
                    if (aPart != Op3dContext.PARTS_MANAGER.selectedPart) {
                        aPart.onSelect();
                    } else {
                        Op3dContext.PART_INFO.update();
                    }
                },
                icon: eTreeIcons.MOVE
            };
        }

        const aMainGroup = Group.instance.findMainGroupPart(aPart);

        if ((true === Op3dContext.APP_FEATURES_CONFIG.snap_lens_to_chief_ray.enabled) &&
            (undefined === aPart.getBehavior('laserBehavior')) &&
            (ePartType.GROUP !== aMainGroup.partOptions.type)) {
            aJSTreeContextMenuItems['snap_to_laser_center'] = {
                label: 'Snap to chief ray',
                action: () => {
                    SimulationRunner.instance.raysDisplayer.addListeners()
                    Op3dContext.PARTS_MANAGER.setSelectedPart(aPart);
                    ChoosePartMode.instance.enterPositionMode()
                },
                icon: eTreeIcons.MAGNET
            };
        }
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aPart.id);
        if ((ePartType.GROUP !== aPart.partOptions.type) &&
            (ePartType.BLACKBOX !== aPart.partOptions.type)) {
            let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aPart.id);
            if ((null != aPartVO) && (true == aPartVO.isOptix) &&
                (false == aPartVO.isDynamicModel)) {
                aJSTreeContextMenuItems.add_cs = {
                    label: 'Add coordinate system',
                    action: () => {
                        Op3dContext.PARTS_EVENTS_HANDLER.enterAddLCSMode(aPart);
                        ChoosePartMode.instance.enterChooseCSMode()
                    },
                    icon: eTreeIcons.AXIS
                };
            }
        }

        /**
         * commented due to upload to production
         */
        // if ((true == Op3dContext.USER_VO.isAdmin) && (null != aPartVO)) {
        //     aJSTreeContextMenuItems.change_details = {
        //         label: 'Change part details',
        //         action: () => {
        //             op3d.ui.forms.ChangePartDetailsForm.instance.open({ part: aPart });
        //         },
        //         icon:eTreeIcons.CATALOG
        //     };
        // }

        let aOpticsHolderNEW = aPart.getAxes().find(axis => (eAxisType.OPTICS === axis.type))
        let aOpticsHolderOLD = aPart.getSubpartByName(Strings.OPTICS_HOLDER);
        let aOpticsHolder = aOpticsHolderOLD == undefined ? aOpticsHolderNEW : aOpticsHolderOLD

        let aSearchOpticsLabel = 'Search optics';
        let aPartForOpticsMenu = aPart;
        if ((null != aOpticsHolder) && (ePartType.DYNAMIC_PART !== aPart.partOptions.type) &&
            (true != aOpticsHolder.object3D.userData.isDetector)) {

            // let aPartOptics = aPart.getPartOptics();
            let aNumberID: string;
            if (null != aOpticsHolderNEW) {
                aNumberID = aOpticsHolderNEW.linkedOpticsId;
            } else {
                let aPartOptics = aPart.getPartOptics();
                if (null != aPartOptics) {
                    aNumberID = aPartOptics.data.number_id;
                }
            }

            let aProps = {
                has_replace_optics: true,
                has_optical_settings: true,
            };

            if (null != aNumberID) {
                const aOpticsVO: iOpticsVO = OpticsDataLoader.instance.getFromCache(aNumberID);

                if (null != aOpticsVO) {


                    if (aOpticsVO.parameters.type == eOpticsTypeNames.APERTURE) {
                        if (Op3dContext.USER_VO.isBasicLicense) {
                            aProps.has_optical_settings = false;
                        }
                        aProps.has_replace_optics = false;
                    }

                    if (aProps.has_optical_settings) {
                        aJSTreeContextMenuItems['optics_form'] = {
                            label: 'Optical settings',
                            action: () => EditOpticsForm.instance.open({
                                formType: eFormType.EDIT,
                                opticsVO: DataUtils.getObjectCopy(aOpticsVO),
                                part: aPart
                            }),
                            icon: eTreeIcons.GEAR
                        };
                    }

                    aSearchOpticsLabel = 'Replace optics';
                    if (eAxisType.OPTICS === aPart.refCS.cs.type) {
                        aPartForOpticsMenu = aPart.refCS.refPart;
                    }

                }
            }

            if (aProps.has_replace_optics) {
                aJSTreeContextMenuItems['search_optics'] = {
                    label: aSearchOpticsLabel,
                    action: () => this._onOpenSearchOpticsMenu(aPartForOpticsMenu),
                    icon: eTreeIcons.MAGNIFY
                };
            }
        }

        if (ePartType.PARAXIAL_LENS === aPart.partOptions.type) {
            let aOpticsVO = aPart.paraxialLensData;
            if (null != aOpticsVO) {
                aJSTreeContextMenuItems['optics_form'] = {
                    label: 'Optical settings',
                    action: () => EditOpticsForm.instance.open({
                        formType: eFormType.EDIT,
                        opticsVO: aOpticsVO,
                        part: aPart,
                    }),
                    icon: eTreeIcons.GEAR
                };
            }
        }

        if ((null != pNode.data.labelInfo)) {
            if (null == aJSTreeContextMenuItems) {
                aJSTreeContextMenuItems = {};
            }
            aJSTreeContextMenuItems['change_name'] = {
                label: pNode.data.labelInfo.actionTitle,
                action: () => {
                    FloatingInput.instance.open({
                        callback: (pValue) => {
                            if ((null == pValue) || ('' == pValue)) {
                                return;
                            }

                            aPart.setPartLabel(pValue, true)
                            // pNode.data.labelInfo.changeFunc(pValue);
                            this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                        },
                        saveState: true,
                        initialValue: aPart.getLabel().label,
                        // pNode.data.labelInfo.currValue,
                        position: { x: e.clientX, y: e.clientY },
                    })
                },
                icon: "bi bi-input-cursor-text"
            };
        }
        if (ePartType.GROUP !== aPart.partOptions.type) {
            aJSTreeContextMenuItems.duplicate = {
                label: 'Duplicate',
                action: () =>
                    Op3dContext.PARTS_MANAGER.duplicatePart({ part: aPart, isSingleDuplicate: true, addToHistory: true }),
                icon: eTreeIcons.DUPLICATE
            };
        }

        if (aPart.opticalData != null && aPartVO.permission == eDataPermission.PRIVATE) {
            // let aIndexes = aPart.getFaces()[0].indexes
            // let aSolidsQuantity = aIndexes[aIndexes.length - 1].path[1]
            aJSTreeContextMenuItems.cad_settings = {
                label: 'CAD settings',
                action: () => {

                },
                submenu: {
                    "choose_solid": {
                        label: 'Choose solids (optical elements only)',
                        _disabled: !(aPart.opticalData.type === eCadType.OPTICS),
                        action: () => {
                            aPart.unHighlightObject();
                            ChoosePartMode.instance.enterOneTargetSolidMode();
                        },
                        icon: eTreeIcons.PART
                    },
                    'choose_face': {
                        label: 'Choose face to change its properties',
                        action: () => {
                            Op3dContext.SCENE_HISTORY.addToHistory();
                            aPart.unHighlightObject();
                            ChoosePartMode.instance.enterOneTargetFacesMode()
                        },
                        icon: eTreeIcons.SOLID
                    },
                    "all_faces": {
                        label: 'Apply surfaces properties for all faces',
                        action: () => {
                            Op3dContext.SCENE_HISTORY.addToHistory();
                            OptomechanicsSurfaceManager.instance.open({
                                face: null,
                                part: aPart,
                            });
                        },
                        icon: eTreeIcons.PARTS
                    }
                },
                icon: eTreeIcons.PART
            }
        }
        if (ePartType.GROUP !== aPart.partOptions.type) {
            aJSTreeContextMenuItems.duplicate = {
                label: 'Duplicate',
                action: () =>
                    Op3dContext.PARTS_MANAGER.duplicatePart({
                        part: aPart,
                        isSingleDuplicate: true,
                        addToHistory: true
                    }),
                icon: eTreeIcons.DUPLICATE
            };
        }


        if ((null != aPart.analysisOptions) && (0 < aPart.analysisOptions.length) &&
            (null != aPart.getSubpartByName(Strings.OPTICS_HOLDER))) {
            aJSTreeContextMenuItems['add_to_QV'] = {
                label: 'Add to quick view',
                action: () =>
                    AnalysesSynchronizer.instance.addCurrentDetectorToQV(aPart),
                icon: eTreeIcons.QV
            };
        }

        if (ePartType.GROUP !== aPart.partOptions.type) {
            aJSTreeContextMenuItems.remove = {
                label: 'Remove',
                action: () => {
                    Op3dContext.SCENE_HISTORY.addToHistory()
                    Op3dContext.PARTS_MANAGER.deletePart(aPart)
                    Op3dContext.SCENE_HISTORY.saveScene()
                },
                icon: 'icon-delete'
            };
        }





        // if (null != aPart.id) {
        //     aJSTreeContextMenuItems.change_orientaion = {
        //         label: 'Change part basic orientaion',
        //         action: () => this._onSetAsDefaultOrientation(aPart),
        //         icon:eTreeIcons.MOVE
        //     }
        // }

        return aJSTreeContextMenuItems;
    }
    //__________________________________________________________________________________________
    private _onOpenSearchOpticsMenu(pPart: Part) {
        let aPartOptics = pPart.getPartOptics();
        let aSearchData = ((null != aPartOptics) && (null != aPartOptics.data)) ?
            aPartOptics.data.searchData : null;


        OpticsMenu.instance.open({
            part: pPart,
            searchData: aSearchData
        });
    }
    //__________________________________________________________________________________________
    private _getGCSOption(pNode: JSTreeNode<iSideBarListData<iAxis>>):
        JSTreeContextMenuItems {

        let aShowHideLabel = (true == pNode.data.item.object3D.visible) ? 'Hide' : 'Show';
        let aShowHideIcon = (true == pNode.data.item.object3D.visible) ?
            eTreeIcons.HIDE : eTreeIcons.SHOW;

        let aJSTreeContextMenuItems: JSTreeContextMenuItems = {
            'show': {
                label: aShowHideLabel,
                action: () => {
                    pNode.data.item.object3D.visible = !pNode.data.item.object3D.visible;
                    SceneContext.OP3D_SCENE.activateRenderer();
                },
                icon: aShowHideIcon
            }
        };

        return aJSTreeContextMenuItems;
    }
    //__________________________________________________________________________________________
    private _getCSOptions(pNode: JSTreeNode<iSideBarListData<iAxis>>,
        e: MouseEvent):
        JSTreeContextMenuItems {

        let aPart = pNode.data.part;

        let aIsVisible = pNode.data.item.object3D.visible;

        let aShowHideLabel = (true == aIsVisible) ? 'Hide' : 'Show';
        let aShowHideIcon = (true == aIsVisible) ? eTreeIcons.HIDE :
            eTreeIcons.SHOW;

        let aJSTreeContextMenuItems: JSTreeContextMenuItems = {
        };

        let aIsGCS = (true == aPart.partOptions.static);

        aJSTreeContextMenuItems.move_part = {
            label: 'Move / Rotate',
            action: () => {
                Op3dContext.PART_INFO.setOneTimeOpenSect(piMoveRotateSection.name);
                if (aPart != Op3dContext.PARTS_MANAGER.selectedPart) {
                    aPart.onSelect();
                } else {
                    Op3dContext.PART_INFO.update();
                }
            },
            icon: eTreeIcons.MOVE
        };

        if ((null != pNode.data.labelInfo)) {
            if (null == aJSTreeContextMenuItems) {
                aJSTreeContextMenuItems = {};
            }

            aJSTreeContextMenuItems['change_name'] = {
                label: pNode.data.labelInfo.actionTitle,
                action: () => {
                    FloatingInput.instance.open({
                        callback: (pValue) => {
                            pNode.data.labelInfo.changeFunc(pValue);
                            this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                        },
                        saveState: true,
                        initialValue: pNode.data.labelInfo.currValue,
                        position: { x: e.clientX, y: e.clientY },
                        allowEmpty: true
                    });
                },
                icon: "bi bi-input-cursor-text"
            }
        }
        if (pNode.data.item.isCustom && pNode.data.item.internal_id != aPart.workingLCS.internal_id) {
            aJSTreeContextMenuItems.remove = {
                label: 'Remove',
                action: () => {
                    Op3dContext.SCENE_HISTORY.addToHistory()
                    aPart.removeAxis(pNode.data.item.internal_id)
                    Op3dContext.SCENE_HISTORY.saveScene()
                    this.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
                },
                icon: 'icon-delete'
            };
        }


        if ((false == aIsGCS) && (ePartType.CS !== aPart.partOptions.type)) {
            aJSTreeContextMenuItems['dup_ref'] = {
                label: 'Duplicate & Linked',
                action: () => {
                    let aPos = MovementBehavior.distToRef(aPart);
                    let aRot = aPart.refRotation.clone();

                    let aNewPart = new Part({
                        id: 'CS',
                        options: { type: ePartType.CS, initialHeight: aPos.y }
                    });
                    aNewPart.setRefrence(aPart.refCS);
                    MovementBehavior.transformPart(aNewPart, aPos, aRot);

                    let aAxis = aNewPart.getAxes()[0];
                    aPart.setRefrence({
                        cs: aAxis,
                        refPart: aNewPart
                    });

                    Op3dContext.PARTS_MANAGER.updatePartsList(false);
                    Op3dContext.PARTS_MANAGER.updateLinkedParts();
                    if (Op3dContext.PARTS_MANAGER.selectedPart == aPart) {
                        Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                        aPart.onSelect();
                    }
                },
                icon: eTreeIcons.AXIS
            };


            aJSTreeContextMenuItems['set_coordinate_system'] = {
                label: 'Set as LCS',
                action: () => this._onSetAsCoordinateSystem(pNode),
                icon: eTreeIcons.AXIS
            };
        }

        if (ePartType.CS === aPart.partOptions.type) {
            aJSTreeContextMenuItems.show = {
                label: aShowHideLabel,
                action: () => {
                    let aIsVisible = !pNode.data.item.object3D.visible;
                    pNode.data.item.object3D.alwaysShow = aIsVisible;
                    pNode.data.item.object3D.visible = aIsVisible;

                    let aUUID = aPart.visibleObj.uuid;

                    let aElem = $(this.mListParent).find('[id=' + aUUID + ']')[0];
                    aElem.style.opacity = (true == aIsVisible) ? '1' : '0.5';
                    SceneContext.OP3D_SCENE.activateRenderer();
                },
                icon: aShowHideIcon
            };

            aJSTreeContextMenuItems.duplicate = {
                label: 'Duplicate',
                action: () => Op3dContext.PARTS_MANAGER.duplicatePart({
                    part: aPart,
                    isSingleDuplicate: true,
                    addToHistory: true
                }),
                icon: eTreeIcons.DUPLICATE
            };

            aJSTreeContextMenuItems.remove = {
                label: 'Remove',
                action: () => Op3dContext.PARTS_MANAGER.deletePart(aPart),
                icon: 'icon-delete'
            };
        } else {
            aJSTreeContextMenuItems.show = {
                label: aShowHideLabel,
                action: () => {
                    let aIsVisible = !pNode.data.item.object3D.visible;
                    pNode.data.item.object3D.alwaysShow = aIsVisible;
                    pNode.data.item.object3D.visible = aIsVisible;
                    SceneContext.OP3D_SCENE.activateRenderer();
                },
                icon: aShowHideIcon
            };
        };

        return aJSTreeContextMenuItems;
    }
    //__________________________________________________________________________________________
    private _onSetAsCoordinateSystem(pNode: JSTreeNode<iSideBarListData<iAxis>>) {
        let aPart = pNode.data.part;
        let aCoordinateSystem = pNode.data.item;

        let aUUID = pNode.data.part.workingLCS.object3D.uuid;
        //let aCurrCoordinateSystemNode = $(this.mListParent).find('[id="' + aUUID + '"]')[0];
        //aCurrCoordinateSystemNode.setAttribute('working_cs', 'false');


        let aCurrNode = $(this.mListParent).jstree(true).get_node(aUUID) as
            JSTreeNode<iSideBarListData<iAxis>>;

        if (aCurrNode) {
            let aCSTooltip = 'Coordinate System';
            if (null != aCurrNode.data.item.name) {
                aCSTooltip += ': ';
                aCSTooltip += aCurrNode.data.item.name;
            }

            let aCS = (null != aCurrNode.data.item.name) ? 'CS: ' + aCurrNode.data.item.name : 'CS';
            aCurrNode.text = aCS;
            aCurrNode.a_attr.tooltip_text = aCSTooltip;
            aCurrNode.a_attr.working_cs = 'false';
        }

        let aLCSTooltip = 'Local Coordinate System';
        if (null != pNode.data.item.name) {
            aLCSTooltip += ': ';
            aLCSTooltip += pNode.data.item.name;
        }

        let aLCS = (null != pNode.data.item.name) ? 'LCS: ' + pNode.data.item.name : 'LCS';
        pNode.text = aLCS;
        pNode.a_attr.tooltip_text = aLCSTooltip;
        pNode.a_attr.working_cs = 'true';

        $(this.mListParent).jstree(true).redraw_node(aCurrNode.id);
        $(this.mListParent).jstree(true).redraw_node(pNode.id);
        $(this.mListParent).find('[data-toggle="tooltip"]').each(
            (_index, pElement) => {
                let aText = (pElement as HTMLElement).getAttribute('tooltip_text');
                (pElement as HTMLElement).addEventListener('mouseenter', (_e: MouseEvent) => {
                    let aBB = pElement.getBoundingClientRect();
                    this.mTooltip.show({ clientX: aBB.right, clientY: aBB.top }, aText);
                }
                );
                pElement.addEventListener('mouseleave', () => this.mTooltip.hide());
            });
        aPart.setWorkingCS(aCoordinateSystem);

        if (aPart == Op3dContext.PARTS_MANAGER.selectedPart) {
            OptixPartDisplayer.unHighlightObject(aPart);
            OptixPartDisplayer.highlightObject(aPart);
        }

        //Op3dContext.PARTS_MANAGER.updatePartsList();
    }
    //__________________________________________________________________________________________
    private _onClickCSNode(pPart: Part, pItem: iAxis) {
        switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
            case eClickMode.BASE:
                if (ePartType.CS === pPart.partOptions.type) {
                    this._selectPart(pPart);
                } else {
                    Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                    pItem.object3D.show();
                    pItem.object3D.higlight();
                }
                break;
            case eClickMode.LCS:
            case eClickMode.LCS_REF:
                this.mSelectedNode = null;
                $(this.mListParent).jstree(true).deselect_all(true);
                Op3dContext.PARTS_EVENTS_HANDLER.onMouseUp(pPart, { axis: pItem });
                break;
        }
    }
    //__________________________________________________________________________________________
    private _getCoordinateSystem(pPart: Part, pItem: iAxis) {
        let aIsWorkingLCS = (pPart.workingLCS.object3D == pItem.object3D);

        let aName = (true == aIsWorkingLCS) ? 'LCS' : 'CS';
        let aTitle = (true == aIsWorkingLCS) ? 'Local coordinate system' : 'Coordinate system';
        if ((null != pItem.name) && ('' != pItem.name)) {
            aName += ': ';
            aName += pItem.name;

            aTitle += ': ';
            aTitle += pItem.name;
        }

        let aCoordinateSystem: iSingleTreeItem<iSideBarListData> = {
            icon: eTreeIcons.AXIS,
            text: aName,
            a_attr: {
                'data-toggle': 'tooltip',
                'tooltip_text': aTitle,
                'working_cs': ((true == aIsWorkingLCS) ? 'true' : 'false')
            },
            data: {
                labelInfo: {
                    currValue: pItem.name,
                    actionTitle: 'Rename CS',
                    changeFunc: (pValue: string) => pItem.name = pValue
                },
                selectNodeFunc: () => this._onClickCSNode(pPart, pItem),
                deselectNodeFunc: () => {
                    if (ePartType.CS === pPart.partOptions.type) {
                        return;
                    }

                    pItem.object3D.unHiglight();
                    pItem.object3D.hide();
                },
                item: pItem,
                part: pPart,
                type: eListItemType.COORDINATE_SYSTEM,
            },
            id: pItem.object3D.uuid
        }

        return aCoordinateSystem;
    }
    //__________________________________________________________________________________________
    private _selectPart(pPart: Part) {
        switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
            case eClickMode.BASE:
                if ((Op3dContext.PARTS_MANAGER.selectedPart != pPart) &&
                    (ePartType.GROUP === pPart.partOptions.type)) {
                    $(':focus').blur();
                    Op3dContext.PARTS_MANAGER.setSelectedPart(pPart);
                    pPart.highlightObject(true);
                } else if (ePartType.GROUP === pPart.refCS.refPart.partOptions.type) {
                    $(':focus').blur();
                    Op3dContext.PARTS_MANAGER.setSelectedPart(pPart);
                    pPart.highlightObject(true);
                } else {
                    pPart.onSelect();
                }
                break;
            //case added when in group tool choose parts to group from sidebar
            case eClickMode.GROUP:
                Group.instance.setPartToGroup(pPart)
                break
            default:
                break;
        }
    }
    //__________________________________________________________________________________________
}
