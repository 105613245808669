﻿import { EventManager } from "../../../../../oc/events/EventManager";
import { EventsContext } from "../../../../_context/EventsContext";
import { iHash, iOP3DHTMLInputElement } from "../../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../../_utils/DataUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../../parts/Part";
import { iFace } from "../../../../parts/PartInterfaces";
import { OpticsFactory } from "../../../../parts/optics/OpticsFactory";
import { iSimulationReflectionItem } from "../../../../simulation/SimulationContext";
import { newNumberInputElement } from "../../../part_info/_components/NewNumberInputElement";
import { uoSection } from "../uoSection";


export class uoFaceSimulationSettings extends uoSection<{ opticsVO: iOpticsVO, originalName: string, part: Part }> {

    private static SKIN_PATH = './skins/forms/optics/es_sim_info.html';
    private mReflecitionCheckbox: iOP3DHTMLInputElement;
    private mMaxBounces: newNumberInputElement;
    private mSimulationData: iHash<iSimulationReflectionItem> = {};
    private mCurrOriginalName: string;

    //__________________________________________________________________________________________
    public fillSurfaceData(pData: iHash<iSimulationReflectionItem>) {
        for (let original_name in this.mSimulationData) {
            pData[original_name] = this.mSimulationData[original_name];
        }
    }
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoFaceSimulationSettings.SKIN_PATH,
            title: 'Simulation settings',
            collapseQaId: "uo_faces_simulation_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mReflecitionCheckbox = Op3dUtils.getElementIn(this.mContainer,
            'no_reflection_checkbox', true) as iOP3DHTMLInputElement/*<iFace | iFaceDataNEW>*/;

        let aLabel = Op3dUtils.getElementIn(this.mContainer, "no_reflection_checkbox_label", true) as HTMLLabelElement;
        aLabel.htmlFor = this.mReflecitionCheckbox.id;

        let aMaxBouncesContainer = Op3dUtils.getElementIn(this.mContainer, 'max_bounces');
        this.mMaxBounces = new newNumberInputElement(aMaxBouncesContainer, {
            label: 'Max bounces',
            hasArrows: true,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: true
            },
            qa_id: 'bounces_input',
            isGlobalToFixed: false
        });

        this.mMaxBounces.setData({
            callback: (pValue) => this._onMaxBouncesChanged(pValue),
            range: {
                min: 0,
                max: 50
            },
            step: 1,
            isInt: true
        });

        this.mReflecitionCheckbox.addEventListener('change', () => this._onChangeNoReflectionCheckbox());

        // let aLinkToPricing = this._getPart('try-professional-scattering', true) as HTMLAnchorElement;
        // aLinkToPricing.href = ServerContext.pricing_base_link;
    }
    //__________________________________________________________________________________________
    private _onMaxBouncesChanged(pVal: number) {
        this.mSimulationData[this.mCurrOriginalName].max_num_bounces = pVal;
        this._onChange();
    }
    //__________________________________________________________________________________________
    private _onChangeNoReflectionCheckbox() {

        let aIsReflectionEnabled = (false == this.mReflecitionCheckbox.checked);
        this.mSimulationData[this.mCurrOriginalName].enabled = aIsReflectionEnabled;
        this.mMaxBounces.enable = aIsReflectionEnabled;
        this._onChange();
    }
    //__________________________________________________________________________________________
    private _onChange() {
        EventManager.dispatchEvent(EventsContext.SIMULATION_SECTION_DATA_CHANGE, this);
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: { opticsVO: iOpticsVO, originalName: string, part: Part }) {

        if (pData == null) {
            this.show(false);
            return;
        }

        this.show(true);

        this.mCurrOriginalName = pData.originalName;
        let aPrevData = this.mSimulationData[this.mCurrOriginalName];
        if (aPrevData == null) {
            let aFaces: iFace[];
            if (pData.part === undefined) {
                let aIPart = OpticsFactory.createOpticalDevice(pData.opticsVO);
                aFaces = aIPart.shapes[0].solids[0].faces;
            } else {
                const aOptics = pData.part.returnOpticsFromPart()
                const aPart = aOptics || pData.part
                aFaces = aPart.getFaces();
                // aFaces = pData.part.getFaces();
            }

            const aCurrFace = aFaces.find(face => face.originalName == pData.originalName);

            if (aCurrFace.data.reflectionData == null) {
                aCurrFace.data.reflectionData = {
                    enabled: false,
                    max_num_bounces: 0
                };
            }
            aPrevData = DataUtils.getObjectCopy(aCurrFace.data.reflectionData);
            this.mSimulationData[this.mCurrOriginalName] = aPrevData;
        }

        const aIsReflectionEnabled = aPrevData.enabled;
        this.mMaxBounces.value = aPrevData.max_num_bounces;
        this.mReflecitionCheckbox.checked = (false == aIsReflectionEnabled);
        this.mMaxBounces.enable = aIsReflectionEnabled;
    }
    //__________________________________________________________________________________________
    public clear() {
        this.collapse();
        this.mSimulationData = {};
        this.mCurrOriginalName = null;
    }
    //__________________________________________________________________________________________
}
