import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { eBaseShape } from "../../../_context/OpticsContext";
import { iMenuSearchQuery } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { SourcesDataLoader } from "../../../data/data_loader/SourcesDataLoader";
import { iSourceVO } from "../../../parser/SourcesParser";
import { MenuListAbstract } from "./MenuListAbstract";
import { iOpenSourcesMenu } from "./MenusContext";
import { eSMFilterName, SourcesFiltersHandler } from "./_filterHandlers/SourcesFiltersHandler";

export class SourcesMenu extends MenuListAbstract<iSourceVO, iOpenSourcesMenu> {

    private static INSTANCE: SourcesMenu;
    //__________________________________________________________________________________________
    protected fillOneExtraField(pData: iSourceVO, pCloneItem: HTMLElement) {
        for (let attribute in pData) {
            let aAttribute = attribute;
            let aAttrData = pData[aAttribute];
            if (aAttrData === null || aAttrData === undefined)
                continue

            if (aAttrData instanceof Object && aAttribute !== 'validLasers')
                this.fillOneExtraField(aAttrData, pCloneItem);

            switch (aAttribute) {

                case 'createdAt':
                case 'number_id':
                case 'fetchedAt':
                case 'permission':
                case 'search_name':
                case 'updatedAt':
                case '__v':
                case 'data':
                case 'parameters':
                case 'validLasers':
                case 'info':
                case 'weblink':
                    continue
                case 'name':
                    pCloneItem.firstElementChild!.innerHTML = aAttrData
                    break
                default:
                    let aValue = attribute == 'parameters.baseShape' ? eBaseShape[aAttrData] : aAttrData
                    let aBlock = document.createElement('div')
                    aBlock.className = 'info-block'
                    aBlock.innerHTML = `<div>${attribute.substring(attribute.lastIndexOf(".") + 1)}</div><div>${aValue}</div>`

                    let aContainer = Op3dUtils.getElementIn(pCloneItem, "items-container") as HTMLElement;
                    aContainer.appendChild(aBlock)
                    break
            }


        }
        // }

    }
    //__________________________________________________________________________________________
    protected _getQuery() {
        let aServerMetaDataQuery: iMenuSearchQuery = {
            limit: +(<HTMLSelectElement>this._getPart("results")).value,
            skip: (this.mCurrentPage * +(<HTMLSelectElement>this._getPart("results")).value),
            filters: this.mFiltersHandler.getFilters(),
            free_string: Op3dUtils.getNormalizedName(this.mSearchInput.value),
            sort: this.mSortObj
        };


        return aServerMetaDataQuery;
    }
    //__________________________________________________________________________________________
    public checkAddToCanvasLimit() {
        let aLimit, aWarningMessage;
        aLimit = 1;
        aWarningMessage = MessagesHandler.CHOOSE_SOURCE_LIMIT

        return { limit: aLimit, warningMessage: aWarningMessage }
    }
    //__________________________________________________________________________________________
    private constructor(pParams: { skinPath: string, container: HTMLElement }) {
        super({
            skinPath: pParams.skinPath,
            container: pParams.container,
        }, {
            isHelpMeSearchBtnExits: false,
            title: "Search light source element",
            category: 'SOURCES',
            limit: 50,
            dataLoader: SourcesDataLoader.instance,
            columns: [{
                name: 'Name',
                serverPath: 'name',
                sortName: 'name',
                showOnStart: true,
                capitalize: false,
                static_col: true,
                minWidth: 600
            },
            {
                name: 'Type',
                serverPath: eSMFilterName.TYPES,
                sortName: eSMFilterName.TYPES,
                showOnStart: true,
                // translateFunction: (pType) => this._getTranslatedType(pType),
                minWidth: 200,
                static_col: true,
            },

            {
                name: 'Brand',
                serverPath: eSMFilterName.BRANDS,
                sortName: eSMFilterName.BRANDS,
                showOnStart: true,
                minWidth: 150,
                static_col: true,
            },
            {
                name: 'Central wavelength',
                serverPath: eSMFilterName.WAVELENGTH,
                sortName: eSMFilterName.WAVELENGTH,
                showOnStart: true,
                minWidth: 300,
                static_col: true,
                fixed: 3
            },
            {
                name: 'Temperature',
                serverPath: eSMFilterName.TEMPERATURE,
                sortName: eSMFilterName.TEMPERATURE,
                showOnStart: true,
                minWidth: 300,
                static_col: true,
            }
            ]
        });


        this.mSignelLineItemQaId = "qa_light_source_item";
    }
    //__________________________________________________________________________________________
    protected async _onSelectItem(pItem: iSourceVO, _pIsFromRecentelyUsed?: boolean) {
        if (this.mData?.onSelectItem) {
            this.mData.onSelectItem(pItem[0]);

        } else {
            let aLaserBehavior = this.mData?.part?.getBehavior("laserBehavior");
            let aRes = await SourcesDataLoader.instance.getWlWeight(pItem[0], pItem[0].parameters.data.center_wavelength);

            aLaserBehavior?.setLightSource(pItem[0], aRes);
            Op3dContext.DIV_CONTROLLER.updatePartInfo();
        }

        this.close();
    }
    //__________________________________________________________________________________________
    public static get instance(): SourcesMenu {
        if (this.INSTANCE == null) {

            let aMenusContainer = document.getElementById('menus');
            if (aMenusContainer === null) {
                throw new Error("Missing menus container");
            }

            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.classList.add('w-100-modal');
            aMenusContainer.appendChild(aDiv);

            this.INSTANCE = new SourcesMenu({
                container: aDiv,
                skinPath: MenuListAbstract.SKIN_PATH
            });
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {

        super._onCreationComplete();
        this.mFiltersHandler = new SourcesFiltersHandler(this,
            this.mFilterTemplate,
            this.mFiltersParent);

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pData: iOpenSourcesMenu) {
        this.mData = pData;

        await this.mFiltersHandler.reload();

        this._getResults(true, true);
    }
    //__________________________________________________________________________________________
}
