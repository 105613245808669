﻿import { t2DArray } from "../../../_context/_interfaces/Interfaces";
import { iSurfaceDeformation, eSurfaceDeformation } from "../../../data/VO/OpticsVOInterfaces";
import { iJonesMatrix } from "../../../parts/PartInterfaces";
import { newNumberInputElement } from "../../part_info/_components/NewNumberInputElement";
import { sdSurfaceDeformation } from "./sdSurfaceDeformation";

export class sdJonesMatrix extends sdSurfaceDeformation<iSurfaceDeformation>{

    private mMatrixElements: {
        A: t2DArray<newNumberInputElement>;
        B: t2DArray<newNumberInputElement>;
        C: t2DArray<newNumberInputElement>;
        D: t2DArray<newNumberInputElement>;
    };

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pOnChange: () => void) {
        super(pContainer, pOnChange);
        this._initElements();
    }
    //__________________________________________________________________________________________
    public setData(pSurfaceDeformation: iSurfaceDeformation<iJonesMatrix>) {
        if ((null == pSurfaceDeformation) || (null == pSurfaceDeformation.params)) {
            for (let item in this.mMatrixElements) {
                this.mMatrixElements[item][0].value = 0;
                this.mMatrixElements[item][1].value = 0;
            }
        } else {
            for (let item in this.mMatrixElements) {
                this.mMatrixElements[item][0].value = pSurfaceDeformation.params[item][0];
                this.mMatrixElements[item][1].value = pSurfaceDeformation.params[item][1];
            }
        }
    }
    //__________________________________________________________________________________________
    public getData(): iSurfaceDeformation {
        let aDeformation: iSurfaceDeformation<iJonesMatrix> = {
            type: eSurfaceDeformation.JONES_MATRIX,
            params: {
                A: [this.mMatrixElements.A[0].value, this.mMatrixElements.A[1].value],
                B: [this.mMatrixElements.B[0].value, this.mMatrixElements.B[1].value],
                C: [this.mMatrixElements.C[0].value, this.mMatrixElements.C[1].value],
                D: [this.mMatrixElements.D[0].value, this.mMatrixElements.D[1].value],
            }
        };

        return aDeformation;
    }
    //__________________________________________________________________________________________
    protected _initElements() {
        this._initMatrixElements();
    }
    //__________________________________________________________________________________________
    private _initMatrixElements() {
        this.mMatrixElements = { A: null, B: null, C: null, D: null };
        for (let item in this.mMatrixElements) {
            let aReDIV = document.createElement('div');
            aReDIV.classList.add('col-3');
            aReDIV.style.paddingTop = '8px';
            this.mContainer.appendChild(aReDIV);

            this.mMatrixElements[item] = [];

            this.mMatrixElements[item][0] = new newNumberInputElement(aReDIV, {
                field_name: 'Re(' + item + ')'
            }).setData(({
                callback: () => this._onChange()
            }));

            let aImDIV = document.createElement('div');
            aImDIV.classList.add('col-3');
            aImDIV.style.paddingTop = '8px';
            this.mContainer.appendChild(aImDIV);

            this.mMatrixElements[item][1] = new newNumberInputElement(aImDIV, {
                field_name: 'Im(' + item + ')'
            }).setData(({
                callback: () => this._onChange()
            }));
        }
    }
    //__________________________________________________________________________________________
    private _onChange() {
        this._onChange();
        //EventManager.dispatchEvent(EventsContext.OPTICS_DEFORMATION_CHANGE, this);
    }
    //__________________________________________________________________________________________
}
