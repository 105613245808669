import { iRing } from "../../_utils/MatrixUtils";
import { SceneContext } from "../../scene/SceneContext";
import { Part } from "../Part";
import { Behavior } from "./Behavior";
import { iPostData } from "../../data/VO/PartVO";
import { iIrisBehaviorJSONObject } from "../_parts_assets/ExportToJSONInterfaces";
import { iIrisBehavior } from "./BehaviorContext";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { Strings } from "../../_context/Strings";
import { OpticsFactory } from "../optics/OpticsFactory";

export interface iIrisData {
    outerRadius: number;
    color: number;
};

export class IrisBehavior extends Behavior<iPostData> implements iIrisBehavior {

    private mCurrInnerRadius: number;

    //__________________________________________________________________________________________
    constructor(pPart: Part) {
        super();

        this._init(pPart);
    }
    //__________________________________________________________________________________________
    public changeInnerRadius(pPart: Part, pInnerRadius: number): void {
        let aIrisParent = pPart.getSubpartByName(Strings.IRIS_CONTAINER);
        let aIrisData = aIrisParent.object3D.userData.irisData as iIrisData;

        let aOuterRadius = aIrisData.outerRadius;
        let aInnerRadius = OP3DMathUtils.clampValue(pInnerRadius, 0, aOuterRadius);
        if (aInnerRadius == this.mCurrInnerRadius) {
            return;
        }

        this.mCurrInnerRadius = aInnerRadius;

        let aIrisParams: iRing = {
            r1: aInnerRadius,
            r2: aOuterRadius
        };


        let aNewIris = OpticsFactory.getIris(aIrisParams);
        pPart.removeSubPartsFromPart(aIrisParent);
        pPart.addSubPartToPart(aIrisParent, aNewIris);

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public getPartOuterRadius(pPart: Part) {
        let aIrisParent = pPart.getSubpartByName(Strings.IRIS_CONTAINER);
        let aOuterRadius = (aIrisParent.object3D.userData.irisData as iIrisData).outerRadius;

        return aOuterRadius;
    }
    //__________________________________________________________________________________________
    public exportToJSON(_pPart: Part, pJSONData: iIrisBehaviorJSONObject): void {
        pJSONData.irisBehavior = {
            innerRadius: this.mCurrInnerRadius
        };
    }
    //__________________________________________________________________________________________
    public async initFromJSON(pPart: Part, pJSONData: iIrisBehaviorJSONObject) {
        this.changeInnerRadius(pPart, pJSONData.irisBehavior.innerRadius);
    }
    //__________________________________________________________________________________________
    private _init(pPart: Part) {
        this.changeInnerRadius(pPart, 0.1);
    }
    //__________________________________________________________________________________________
}
