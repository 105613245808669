﻿export class Spectrum {

    // todo move this to json and load from server
    public static SPECTRUM = [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        {
            "R": 0.00184,
            "G": -0.000454,
            "B": 0.0122
        },
        {
            "R": 0.0024,
            "G": -0.000573,
            "B": 0.016
        },
        {
            "R": 0.00295,
            "G": -0.000692,
            "B": 0.0198
        },
        {
            "R": 0.00351,
            "G": -0.000812,
            "B": 0.0235
        },
        {
            "R": 0.00406,
            "G": -0.000931,
            "B": 0.0273
        },
        {
            "R": 0.00462,
            "G": -0.00105,
            "B": 0.0311
        },
        {
            "R": 0.00562,
            "G": -0.00127,
            "B": 0.0374
        },
        {
            "R": 0.00662,
            "G": -0.0015,
            "B": 0.0436
        },
        {
            "R": 0.00763,
            "G": -0.00172,
            "B": 0.0499
        },
        {
            "R": 0.00863,
            "G": -0.00195,
            "B": 0.0561
        },
        {
            "R": 0.00963,
            "G": -0.00217,
            "B": 0.0624
        },
        {
            "R": 0.0115,
            "G": -0.00262,
            "B": 0.0763
        },
        {
            "R": 0.0134,
            "G": -0.00307,
            "B": 0.0902
        },
        {
            "R": 0.0153,
            "G": -0.00353,
            "B": 0.104
        },
        {
            "R": 0.0171,
            "G": -0.00398,
            "B": 0.118
        },
        {
            "R": 0.019,
            "G": -0.00443,
            "B": 0.132
        },
        {
            "R": 0.0214,
            "G": -0.00498,
            "B": 0.151
        },
        {
            "R": 0.0237,
            "G": -0.00554,
            "B": 0.17
        },
        {
            "R": 0.0261,
            "G": -0.00609,
            "B": 0.19
        },
        {
            "R": 0.0284,
            "G": -0.00665,
            "B": 0.209
        },
        {
            "R": 0.0308,
            "G": -0.0072,
            "B": 0.228
        },
        {
            "R": 0.0331,
            "G": -0.00828,
            "B": 0.254
        },
        {
            "R": 0.0355,
            "G": -0.00936,
            "B": 0.28
        },
        {
            "R": 0.0378,
            "G": -0.0104,
            "B": 0.307
        },
        {
            "R": 0.0402,
            "G": -0.0115,
            "B": 0.333
        },
        {
            "R": 0.0425,
            "G": -0.0126,
            "B": 0.359
        },
        {
            "R": 0.0443,
            "G": -0.0134,
            "B": 0.392
        },
        {
            "R": 0.0462,
            "G": -0.0142,
            "B": 0.425
        },
        {
            "R": 0.048,
            "G": -0.0151,
            "B": 0.458
        },
        {
            "R": 0.0499,
            "G": -0.0159,
            "B": 0.491
        },
        {
            "R": 0.0517,
            "G": -0.0167,
            "B": 0.524
        },
        {
            "R": 0.0519,
            "G": -0.0176,
            "B": 0.556
        },
        {
            "R": 0.0521,
            "G": -0.0185,
            "B": 0.589
        },
        {
            "R": 0.0524,
            "G": -0.0194,
            "B": 0.621
        },
        {
            "R": 0.0526,
            "G": -0.0203,
            "B": 0.654
        },
        {
            "R": 0.0528,
            "G": -0.0212,
            "B": 0.686
        },
        {
            "R": 0.0511,
            "G": -0.0209,
            "B": 0.708
        },
        {
            "R": 0.0494,
            "G": -0.0207,
            "B": 0.73
        },
        {
            "R": 0.0477,
            "G": -0.0204,
            "B": 0.752
        },
        {
            "R": 0.046,
            "G": -0.0202,
            "B": 0.774
        },
        {
            "R": 0.0443,
            "G": -0.0199,
            "B": 0.796
        },
        {
            "R": 0.0419,
            "G": -0.0191,
            "B": 0.816
        },
        {
            "R": 0.0395,
            "G": -0.0184,
            "B": 0.836
        },
        {
            "R": 0.037,
            "G": -0.0176,
            "B": 0.855
        },
        {
            "R": 0.0346,
            "G": -0.0169,
            "B": 0.875
        },
        {
            "R": 0.0322,
            "G": -0.0161,
            "B": 0.895
        },
        {
            "R": 0.0287,
            "G": -0.0144,
            "B": 0.909
        },
        {
            "R": 0.0252,
            "G": -0.0126,
            "B": 0.923
        },
        {
            "R": 0.0218,
            "G": -0.0109,
            "B": 0.936
        },
        {
            "R": 0.0183,
            "G": -0.0091,
            "B": 0.95
        },
        {
            "R": 0.0148,
            "G": -0.00735,
            "B": 0.964
        },
        {
            "R": 0.0114,
            "G": -0.00561,
            "B": 0.971
        },
        {
            "R": 0.00794,
            "G": -0.00386,
            "B": 0.978
        },
        {
            "R": 0.00452,
            "G": -0.00212,
            "B": 0.984
        },
        {
            "R": 0.00109,
            "G": -0.000374,
            "B": 0.991
        },
        {
            "R": -0.00234,
            "G": 0.00137,
            "B": 0.998
        },
        {
            "R": -0.00769,
            "G": 0.00502,
            "B": 0.982
        },
        {
            "R": -0.013,
            "G": 0.00866,
            "B": 0.966
        },
        {
            "R": -0.0184,
            "G": 0.0123,
            "B": 0.951
        },
        {
            "R": -0.0237,
            "G": 0.016,
            "B": 0.935
        },
        {
            "R": -0.0291,
            "G": 0.0196,
            "B": 0.919
        },
        {
            "R": -0.0354,
            "G": 0.0244,
            "B": 0.9
        },
        {
            "R": -0.0417,
            "G": 0.0292,
            "B": 0.881
        },
        {
            "R": -0.0481,
            "G": 0.0339,
            "B": 0.863
        },
        {
            "R": -0.0544,
            "G": 0.0387,
            "B": 0.844
        },
        {
            "R": -0.0607,
            "G": 0.0435,
            "B": 0.825
        },
        {
            "R": -0.0678,
            "G": 0.049,
            "B": 0.817
        },
        {
            "R": -0.0749,
            "G": 0.0545,
            "B": 0.809
        },
        {
            "R": -0.082,
            "G": 0.06,
            "B": 0.802
        },
        {
            "R": -0.0891,
            "G": 0.0655,
            "B": 0.794
        },
        {
            "R": -0.0962,
            "G": 0.071,
            "B": 0.786
        },
        {
            "R": -0.105,
            "G": 0.0788,
            "B": 0.762
        },
        {
            "R": -0.113,
            "G": 0.0866,
            "B": 0.738
        },
        {
            "R": -0.121,
            "G": 0.0944,
            "B": 0.715
        },
        {
            "R": -0.13,
            "G": 0.102,
            "B": 0.691
        },
        {
            "R": -0.138,
            "G": 0.11,
            "B": 0.667
        },
        {
            "R": -0.145,
            "G": 0.118,
            "B": 0.656
        },
        {
            "R": -0.153,
            "G": 0.126,
            "B": 0.645
        },
        {
            "R": -0.16,
            "G": 0.135,
            "B": 0.633
        },
        {
            "R": -0.168,
            "G": 0.143,
            "B": 0.622
        },
        {
            "R": -0.175,
            "G": 0.151,
            "B": 0.611
        },
        {
            "R": -0.183,
            "G": 0.16,
            "B": 0.586
        },
        {
            "R": -0.19,
            "G": 0.17,
            "B": 0.562
        },
        {
            "R": -0.198,
            "G": 0.179,
            "B": 0.537
        },
        {
            "R": -0.205,
            "G": 0.189,
            "B": 0.513
        },
        {
            "R": -0.213,
            "G": 0.198,
            "B": 0.488
        },
        {
            "R": -0.218,
            "G": 0.206,
            "B": 0.463
        },
        {
            "R": -0.223,
            "G": 0.215,
            "B": 0.438
        },
        {
            "R": -0.228,
            "G": 0.223,
            "B": 0.412
        },
        {
            "R": -0.233,
            "G": 0.232,
            "B": 0.387
        },
        {
            "R": -0.238,
            "G": 0.24,
            "B": 0.362
        },
        {
            "R": -0.242,
            "G": 0.248,
            "B": 0.343
        },
        {
            "R": -0.246,
            "G": 0.256,
            "B": 0.324
        },
        {
            "R": -0.249,
            "G": 0.264,
            "B": 0.304
        },
        {
            "R": -0.253,
            "G": 0.272,
            "B": 0.285
        },
        {
            "R": -0.257,
            "G": 0.28,
            "B": 0.266
        },
        {
            "R": -0.261,
            "G": 0.291,
            "B": 0.252
        },
        {
            "R": -0.265,
            "G": 0.302,
            "B": 0.238
        },
        {
            "R": -0.269,
            "G": 0.312,
            "B": 0.224
        },
        {
            "R": -0.273,
            "G": 0.323,
            "B": 0.21
        },
        {
            "R": -0.277,
            "G": 0.334,
            "B": 0.196
        },
        {
            "R": -0.28,
            "G": 0.348,
            "B": 0.186
        },
        {
            "R": -0.283,
            "G": 0.362,
            "B": 0.176
        },
        {
            "R": -0.285,
            "G": 0.377,
            "B": 0.167
        },
        {
            "R": -0.288,
            "G": 0.391,
            "B": 0.157
        },
        {
            "R": -0.291,
            "G": 0.405,
            "B": 0.147
        },
        {
            "R": -0.292,
            "G": 0.422,
            "B": 0.139
        },
        {
            "R": -0.293,
            "G": 0.439,
            "B": 0.131
        },
        {
            "R": -0.293,
            "G": 0.457,
            "B": 0.123
        },
        {
            "R": -0.294,
            "G": 0.474,
            "B": 0.115
        },
        {
            "R": -0.295,
            "G": 0.491,
            "B": 0.107
        },
        {
            "R": -0.295,
            "G": 0.512,
            "B": 0.101
        },
        {
            "R": -0.296,
            "G": 0.533,
            "B": 0.0949
        },
        {
            "R": -0.296,
            "G": 0.555,
            "B": 0.0888
        },
        {
            "R": -0.297,
            "G": 0.576,
            "B": 0.0828
        },
        {
            "R": -0.297,
            "G": 0.597,
            "B": 0.0767
        },
        {
            "R": -0.291,
            "G": 0.618,
            "B": 0.0714
        },
        {
            "R": -0.285,
            "G": 0.639,
            "B": 0.0661
        },
        {
            "R": -0.28,
            "G": 0.66,
            "B": 0.0608
        },
        {
            "R": -0.274,
            "G": 0.681,
            "B": 0.0555
        },
        {
            "R": -0.268,
            "G": 0.702,
            "B": 0.0502
        },
        {
            "R": -0.258,
            "G": 0.723,
            "B": 0.0459
        },
        {
            "R": -0.248,
            "G": 0.745,
            "B": 0.0416
        },
        {
            "R": -0.237,
            "G": 0.766,
            "B": 0.0374
        },
        {
            "R": -0.227,
            "G": 0.788,
            "B": 0.0331
        },
        {
            "R": -0.217,
            "G": 0.809,
            "B": 0.0288
        },
        {
            "R": -0.203,
            "G": 0.829,
            "B": 0.0257
        },
        {
            "R": -0.189,
            "G": 0.85,
            "B": 0.0226
        },
        {
            "R": -0.176,
            "G": 0.87,
            "B": 0.0195
        },
        {
            "R": -0.162,
            "G": 0.891,
            "B": 0.0164
        },
        {
            "R": -0.148,
            "G": 0.911,
            "B": 0.0133
        },
        {
            "R": -0.125,
            "G": 0.926,
            "B": 0.0111
        },
        {
            "R": -0.103,
            "G": 0.941,
            "B": 0.00883
        },
        {
            "R": -0.0803,
            "G": 0.955,
            "B": 0.00659
        },
        {
            "R": -0.0578,
            "G": 0.97,
            "B": 0.00436
        },
        {
            "R": -0.0352,
            "G": 0.985,
            "B": 0.00212
        },
        {
            "R": -0.00696,
            "G": 0.994,
            "B": 0.000864
        },
        {
            "R": 0.0213,
            "G": 1,
            "B": -0.000392
        },
        {
            "R": 0.0495,
            "G": 1.01,
            "B": -0.00165
        },
        {
            "R": 0.0778,
            "G": 1.02,
            "B": -0.0029
        },
        {
            "R": 0.106,
            "G": 1.03,
            "B": -0.00416
        },
        {
            "R": 0.137,
            "G": 1.03,
            "B": -0.00499
        },
        {
            "R": 0.168,
            "G": 1.04,
            "B": -0.00582
        },
        {
            "R": 0.198,
            "G": 1.04,
            "B": -0.00664
        },
        {
            "R": 0.229,
            "G": 1.05,
            "B": -0.00747
        },
        {
            "R": 0.26,
            "G": 1.05,
            "B": -0.0083
        },
        {
            "R": 0.292,
            "G": 1.05,
            "B": -0.00908
        },
        {
            "R": 0.324,
            "G": 1.05,
            "B": -0.00986
        },
        {
            "R": 0.356,
            "G": 1.05,
            "B": -0.0106
        },
        {
            "R": 0.388,
            "G": 1.05,
            "B": -0.0114
        },
        {
            "R": 0.42,
            "G": 1.05,
            "B": -0.0122
        },
        {
            "R": 0.455,
            "G": 1.05,
            "B": -0.0126
        },
        {
            "R": 0.489,
            "G": 1.05,
            "B": -0.0129
        },
        {
            "R": 0.524,
            "G": 1.05,
            "B": -0.0133
        },
        {
            "R": 0.558,
            "G": 1.05,
            "B": -0.0136
        },
        {
            "R": 0.593,
            "G": 1.05,
            "B": -0.014
        },
        {
            "R": 0.632,
            "G": 1.05,
            "B": -0.0141
        },
        {
            "R": 0.672,
            "G": 1.05,
            "B": -0.0143
        },
        {
            "R": 0.711,
            "G": 1.04,
            "B": -0.0144
        },
        {
            "R": 0.751,
            "G": 1.04,
            "B": -0.0146
        },
        {
            "R": 0.79,
            "G": 1.04,
            "B": -0.0147
        },
        {
            "R": 0.834,
            "G": 1.03,
            "B": -0.0147
        },
        {
            "R": 0.878,
            "G": 1.02,
            "B": -0.0148
        },
        {
            "R": 0.922,
            "G": 1.01,
            "B": -0.0148
        },
        {
            "R": 0.966,
            "G": 1.01,
            "B": -0.0149
        },
        {
            "R": 1.01,
            "G": 0.998,
            "B": -0.0149
        },
        {
            "R": 1.05,
            "G": 0.986,
            "B": -0.0148
        },
        {
            "R": 1.1,
            "G": 0.974,
            "B": -0.0148
        },
        {
            "R": 1.14,
            "G": 0.962,
            "B": -0.0147
        },
        {
            "R": 1.19,
            "G": 0.95,
            "B": -0.0147
        },
        {
            "R": 1.23,
            "G": 0.938,
            "B": -0.0146
        },
        {
            "R": 1.28,
            "G": 0.926,
            "B": -0.0144
        },
        {
            "R": 1.33,
            "G": 0.915,
            "B": -0.0143
        },
        {
            "R": 1.37,
            "G": 0.903,
            "B": -0.0141
        },
        {
            "R": 1.42,
            "G": 0.892,
            "B": -0.014
        },
        {
            "R": 1.47,
            "G": 0.88,
            "B": -0.0138
        },
        {
            "R": 1.53,
            "G": 0.87,
            "B": -0.0136
        },
        {
            "R": 1.58,
            "G": 0.859,
            "B": -0.0134
        },
        {
            "R": 1.64,
            "G": 0.849,
            "B": -0.0131
        },
        {
            "R": 1.69,
            "G": 0.838,
            "B": -0.0129
        },
        {
            "R": 1.75,
            "G": 0.828,
            "B": -0.0127
        },
        {
            "R": 1.8,
            "G": 0.812,
            "B": -0.0124
        },
        {
            "R": 1.86,
            "G": 0.796,
            "B": -0.0122
        },
        {
            "R": 1.91,
            "G": 0.779,
            "B": -0.0119
        },
        {
            "R": 1.97,
            "G": 0.763,
            "B": -0.0117
        },
        {
            "R": 2.02,
            "G": 0.747,
            "B": -0.0114
        },
        {
            "R": 2.07,
            "G": 0.727,
            "B": -0.0111
        },
        {
            "R": 2.12,
            "G": 0.708,
            "B": -0.0108
        },
        {
            "R": 2.17,
            "G": 0.688,
            "B": -0.0105
        },
        {
            "R": 2.22,
            "G": 0.669,
            "B": -0.0102
        },
        {
            "R": 2.27,
            "G": 0.649,
            "B": -0.00993
        },
        {
            "R": 2.31,
            "G": 0.632,
            "B": -0.00963
        },
        {
            "R": 2.36,
            "G": 0.615,
            "B": -0.00932
        },
        {
            "R": 2.4,
            "G": 0.597,
            "B": -0.00902
        },
        {
            "R": 2.45,
            "G": 0.58,
            "B": -0.00871
        },
        {
            "R": 2.49,
            "G": 0.563,
            "B": -0.00841
        },
        {
            "R": 2.53,
            "G": 0.546,
            "B": -0.00813
        },
        {
            "R": 2.56,
            "G": 0.529,
            "B": -0.00785
        },
        {
            "R": 2.6,
            "G": 0.511,
            "B": -0.00758
        },
        {
            "R": 2.63,
            "G": 0.494,
            "B": -0.0073
        },
        {
            "R": 2.67,
            "G": 0.477,
            "B": -0.00702
        },
        {
            "R": 2.7,
            "G": 0.459,
            "B": -0.00676
        },
        {
            "R": 2.73,
            "G": 0.44,
            "B": -0.00651
        },
        {
            "R": 2.75,
            "G": 0.422,
            "B": -0.00625
        },
        {
            "R": 2.78,
            "G": 0.403,
            "B": -0.006
        },
        {
            "R": 2.81,
            "G": 0.385,
            "B": -0.00574
        },
        {
            "R": 2.82,
            "G": 0.368,
            "B": -0.00545
        },
        {
            "R": 2.83,
            "G": 0.351,
            "B": -0.00515
        },
        {
            "R": 2.85,
            "G": 0.335,
            "B": -0.00486
        },
        {
            "R": 2.86,
            "G": 0.318,
            "B": -0.00456
        },
        {
            "R": 2.87,
            "G": 0.301,
            "B": -0.00427
        },
        {
            "R": 2.87,
            "G": 0.287,
            "B": -0.004
        },
        {
            "R": 2.86,
            "G": 0.272,
            "B": -0.00373
        },
        {
            "R": 2.86,
            "G": 0.258,
            "B": -0.00345
        },
        {
            "R": 2.85,
            "G": 0.243,
            "B": -0.00318
        },
        {
            "R": 2.85,
            "G": 0.229,
            "B": -0.00291
        },
        {
            "R": 2.83,
            "G": 0.216,
            "B": -0.00278
        },
        {
            "R": 2.81,
            "G": 0.204,
            "B": -0.00265
        },
        {
            "R": 2.8,
            "G": 0.191,
            "B": -0.00253
        },
        {
            "R": 2.78,
            "G": 0.179,
            "B": -0.0024
        },
        {
            "R": 2.76,
            "G": 0.166,
            "B": -0.00227
        },
        {
            "R": 2.73,
            "G": 0.156,
            "B": -0.00222
        },
        {
            "R": 2.7,
            "G": 0.145,
            "B": -0.00216
        },
        {
            "R": 2.66,
            "G": 0.135,
            "B": -0.00211
        },
        {
            "R": 2.63,
            "G": 0.124,
            "B": -0.00205
        },
        {
            "R": 2.6,
            "G": 0.114,
            "B": -0.002
        },
        {
            "R": 2.55,
            "G": 0.106,
            "B": -0.0019
        },
        {
            "R": 2.51,
            "G": 0.0983,
            "B": -0.0018
        },
        {
            "R": 2.46,
            "G": 0.0904,
            "B": -0.00171
        },
        {
            "R": 2.42,
            "G": 0.0826,
            "B": -0.00161
        },
        {
            "R": 2.37,
            "G": 0.0747,
            "B": -0.00151
        },
        {
            "R": 2.32,
            "G": 0.0691,
            "B": -0.0014
        },
        {
            "R": 2.27,
            "G": 0.0634,
            "B": -0.00128
        },
        {
            "R": 2.21,
            "G": 0.0578,
            "B": -0.00117
        },
        {
            "R": 2.16,
            "G": 0.0521,
            "B": -0.00105
        },
        {
            "R": 2.11,
            "G": 0.0465,
            "B": -0.000938
        },
        {
            "R": 2.05,
            "G": 0.0425,
            "B": -0.000861
        },
        {
            "R": 1.99,
            "G": 0.0384,
            "B": -0.000784
        },
        {
            "R": 1.93,
            "G": 0.0344,
            "B": -0.000707
        },
        {
            "R": 1.87,
            "G": 0.0303,
            "B": -0.00063
        },
        {
            "R": 1.81,
            "G": 0.0263,
            "B": -0.000553
        },
        {
            "R": 1.75,
            "G": 0.0236,
            "B": -0.000506
        },
        {
            "R": 1.69,
            "G": 0.0209,
            "B": -0.000459
        },
        {
            "R": 1.64,
            "G": 0.0181,
            "B": -0.000411
        },
        {
            "R": 1.58,
            "G": 0.0154,
            "B": -0.000364
        },
        {
            "R": 1.52,
            "G": 0.0127,
            "B": -0.000317
        },
        {
            "R": 1.47,
            "G": 0.0111,
            "B": -0.000282
        },
        {
            "R": 1.41,
            "G": 0.00942,
            "B": -0.000247
        },
        {
            "R": 1.36,
            "G": 0.00778,
            "B": -0.000213
        },
        {
            "R": 1.3,
            "G": 0.00614,
            "B": -0.000178
        },
        {
            "R": 1.25,
            "G": 0.0045,
            "B": -0.000143
        },
        {
            "R": 1.2,
            "G": 0.00362,
            "B": -0.000115
        },
        {
            "R": 1.15,
            "G": 0.00274,
            "B": -0.0000874
        },
        {
            "R": 1.11,
            "G": 0.00186,
            "B": -0.0000596
        },
        {
            "R": 1.06,
            "G": 0.000977,
            "B": -0.0000319
        },
        {
            "R": 1.01,
            "G": 0.0000966,
            "B": -0.00000408
        },
        {
            "R": 0.965,
            "G": -0.000315,
            "B": 0.0000189
        },
        {
            "R": 0.92,
            "G": -0.000726,
            "B": 0.000042
        },
        {
            "R": 0.876,
            "G": -0.00114,
            "B": 0.000065
        },
        {
            "R": 0.831,
            "G": -0.00155,
            "B": 0.000088
        },
        {
            "R": 0.786,
            "G": -0.00196,
            "B": 0.000111
        },
        {
            "R": 0.748,
            "G": -0.00209,
            "B": 0.000127
        },
        {
            "R": 0.71,
            "G": -0.00223,
            "B": 0.000143
        },
        {
            "R": 0.673,
            "G": -0.00236,
            "B": 0.00016
        },
        {
            "R": 0.635,
            "G": -0.0025,
            "B": 0.000176
        },
        {
            "R": 0.597,
            "G": -0.00263,
            "B": 0.000192
        },
        {
            "R": 0.566,
            "G": -0.00263,
            "B": 0.000199
        },
        {
            "R": 0.535,
            "G": -0.00263,
            "B": 0.000206
        },
        {
            "R": 0.505,
            "G": -0.00263,
            "B": 0.000213
        },
        {
            "R": 0.474,
            "G": -0.00263,
            "B": 0.00022
        },
        {
            "R": 0.443,
            "G": -0.00263,
            "B": 0.000227
        },
        {
            "R": 0.419,
            "G": -0.00256,
            "B": 0.000225
        },
        {
            "R": 0.395,
            "G": -0.0025,
            "B": 0.000222
        },
        {
            "R": 0.372,
            "G": -0.00243,
            "B": 0.00022
        },
        {
            "R": 0.348,
            "G": -0.00237,
            "B": 0.000217
        },
        {
            "R": 0.324,
            "G": -0.0023,
            "B": 0.000215
        },
        {
            "R": 0.306,
            "G": -0.00221,
            "B": 0.000205
        },
        {
            "R": 0.288,
            "G": -0.00213,
            "B": 0.000195
        },
        {
            "R": 0.271,
            "G": -0.00204,
            "B": 0.000184
        },
        {
            "R": 0.253,
            "G": -0.00196,
            "B": 0.000174
        },
        {
            "R": 0.235,
            "G": -0.00187,
            "B": 0.000164
        },
        {
            "R": 0.222,
            "G": -0.00178,
            "B": 0.000151
        },
        {
            "R": 0.209,
            "G": -0.0017,
            "B": 0.000137
        },
        {
            "R": 0.195,
            "G": -0.00161,
            "B": 0.000124
        },
        {
            "R": 0.182,
            "G": -0.00153,
            "B": 0.000111
        },
        {
            "R": 0.169,
            "G": -0.00144,
            "B": 0.0000972
        },
        {
            "R": 0.159,
            "G": -0.00137,
            "B": 0.000088
        },
        {
            "R": 0.15,
            "G": -0.0013,
            "B": 0.0000787
        },
        {
            "R": 0.14,
            "G": -0.00122,
            "B": 0.0000695
        },
        {
            "R": 0.131,
            "G": -0.00115,
            "B": 0.0000602
        },
        {
            "R": 0.121,
            "G": -0.00108,
            "B": 0.000051
        },
        {
            "R": 0.114,
            "G": -0.00102,
            "B": 0.0000479
        },
        {
            "R": 0.107,
            "G": -0.000964,
            "B": 0.0000447
        },
        {
            "R": 0.0999,
            "G": -0.000906,
            "B": 0.0000416
        },
        {
            "R": 0.0928,
            "G": -0.000848,
            "B": 0.0000384
        },
        {
            "R": 0.0858,
            "G": -0.00079,
            "B": 0.0000353
        },
        {
            "R": 0.0807,
            "G": -0.000746,
            "B": 0.0000345
        },
        {
            "R": 0.0756,
            "G": -0.000701,
            "B": 0.0000337
        },
        {
            "R": 0.0705,
            "G": -0.000657,
            "B": 0.0000328
        },
        {
            "R": 0.0654,
            "G": -0.000612,
            "B": 0.000032
        },
        {
            "R": 0.0603,
            "G": -0.000568,
            "B": 0.0000312
        },
        {
            "R": 0.0565,
            "G": -0.000533,
            "B": 0.0000299
        },
        {
            "R": 0.0528,
            "G": -0.000498,
            "B": 0.0000285
        },
        {
            "R": 0.049,
            "G": -0.000463,
            "B": 0.0000272
        },
        {
            "R": 0.0453,
            "G": -0.000428,
            "B": 0.0000258
        },
        {
            "R": 0.0415,
            "G": -0.000393,
            "B": 0.0000245
        },
        {
            "R": 0.0388,
            "G": -0.000367,
            "B": 0.0000229
        },
        {
            "R": 0.0361,
            "G": -0.000341,
            "B": 0.0000213
        },
        {
            "R": 0.0335,
            "G": -0.000314,
            "B": 0.0000197
        },
        {
            "R": 0.0308,
            "G": -0.000288,
            "B": 0.0000181
        },
        {
            "R": 0.0281,
            "G": -0.000262,
            "B": 0.0000165
        },
        {
            "R": 0.0263,
            "G": -0.000245,
            "B": 0.0000154
        },
        {
            "R": 0.0245,
            "G": -0.000227,
            "B": 0.0000143
        },
        {
            "R": 0.0227,
            "G": -0.00021,
            "B": 0.0000133
        },
        {
            "R": 0.0209,
            "G": -0.000192,
            "B": 0.0000122
        },
        {
            "R": 0.0191,
            "G": -0.000175,
            "B": 0.0000111
        },
        {
            "R": 0.0179,
            "G": -0.000164,
            "B": 0.0000106
        },
        {
            "R": 0.0168,
            "G": -0.000153,
            "B": 0.0000101
        },
        {
            "R": 0.0156,
            "G": -0.000143,
            "B": 0.00000966
        },
        {
            "R": 0.0145,
            "G": -0.000132,
            "B": 0.00000918
        },
        {
            "R": 0.0133,
            "G": -0.000121,
            "B": 0.0000087
        },
        {
            "R": 0.0125,
            "G": -0.000114,
            "B": 0.00000845
        },
        {
            "R": 0.0117,
            "G": -0.000107,
            "B": 0.0000082
        },
        {
            "R": 0.011,
            "G": -0.0000999,
            "B": 0.00000794
        },
        {
            "R": 0.0102,
            "G": -0.0000928,
            "B": 0.00000769
        },
        {
            "R": 0.00941,
            "G": -0.0000858,
            "B": 0.00000744
        },
        {
            "R": 0.00883,
            "G": -0.0000802,
            "B": 0.00000717
        },
        {
            "R": 0.00825,
            "G": -0.0000746,
            "B": 0.00000691
        },
        {
            "R": 0.00768,
            "G": -0.0000689,
            "B": 0.00000664
        },
        {
            "R": 0.0071,
            "G": -0.0000633,
            "B": 0.00000638
        },
        {
            "R": 0.00652,
            "G": -0.0000577,
            "B": 0.00000611
        },
        {
            "R": 0.00612,
            "G": -0.000054,
            "B": 0.00000589
        },
        {
            "R": 0.00573,
            "G": -0.0000502,
            "B": 0.00000568
        },
        {
            "R": 0.00533,
            "G": -0.0000465,
            "B": 0.00000546
        },
        {
            "R": 0.00494,
            "G": -0.0000427,
            "B": 0.00000525
        },
        {
            "R": 0.00454,
            "G": -0.000039,
            "B": 0.00000503
        },
        {
            "R": 0.00427,
            "G": -0.0000365,
            "B": 0.00000485
        },
        {
            "R": 0.00399,
            "G": -0.000034,
            "B": 0.00000467
        },
        {
            "R": 0.00372,
            "G": -0.0000315,
            "B": 0.00000449
        },
        {
            "R": 0.00344,
            "G": -0.000029,
            "B": 0.00000431
        },
        {
            "R": 0.00317,
            "G": -0.0000265,
            "B": 0.00000413
        },
        {
            "R": 0.00273,
            "G": 0.000051,
            "B": 0.00000332
        },
        {
            "R": 0.00229,
            "G": 0.000129,
            "B": 0.0000025
        },
        {
            "R": 0.00185,
            "G": 0.000206,
            "B": 0.00000169
        },
        {
            "R": 0.00141,
            "G": 0.000284,
            "B": 8.75e-7
        },
        {
            "R": 0.00097,
            "G": 0.000361,
            "B": 6.15e-8
        },
        {
            "R": 0.00091,
            "G": 0.000339,
            "B": 5.78e-8
        },
        {
            "R": 0.000849,
            "G": 0.000316,
            "B": 5.42e-8
        },
        {
            "R": 0.000789,
            "G": 0.000294,
            "B": 5.05e-8
        },
        {
            "R": 0.000728,
            "G": 0.000271,
            "B": 4.69e-8
        },
        {
            "R": 0.000668,
            "G": 0.000249,
            "B": 4.32e-8
        },
        {
            "R": 0.000627,
            "G": 0.000234,
            "B": 4.06e-8
        },
        {
            "R": 0.000585,
            "G": 0.000218,
            "B": 3.81e-8
        },
        {
            "R": 0.000544,
            "G": 0.000203,
            "B": 3.55e-8
        },
        {
            "R": 0.000502,
            "G": 0.000187,
            "B": 3.3e-8
        },
        {
            "R": 0.000461,
            "G": 0.000172,
            "B": 3.04e-8
        },
        {
            "R": 0.000433,
            "G": 0.000162,
            "B": 2.86e-8
        },
        {
            "R": 0.000405,
            "G": 0.000151,
            "B": 2.69e-8
        },
        {
            "R": 0.000377,
            "G": 0.000141,
            "B": 2.51e-8
        },
        {
            "R": 0.000349,
            "G": 0.00013,
            "B": 2.34e-8
        },
        {
            "R": 0.000321,
            "G": 0.00012,
            "B": 2.16e-8
        },
        {
            "R": 0.000302,
            "G": 0.000113,
            "B": 2.04e-8
        },
        {
            "R": 0.000283,
            "G": 0.000106,
            "B": 1.92e-8
        },
        {
            "R": 0.000264,
            "G": 0.0000988,
            "B": 1.79e-8
        },
        {
            "R": 0.000245,
            "G": 0.0000917,
            "B": 1.67e-8
        },
        {
            "R": 0.000226,
            "G": 0.0000846,
            "B": 1.55e-8
        },
        {
            "R": 0.000213,
            "G": 0.0000797,
            "B": 1.46e-8
        },
        {
            "R": 0.0002,
            "G": 0.0000748,
            "B": 1.38e-8
        },
        {
            "R": 0.000186,
            "G": 0.0000698,
            "B": 1.29e-8
        },
        {
            "R": 0.000173,
            "G": 0.0000649,
            "B": 1.21e-8
        },
        {
            "R": 0.00016,
            "G": 0.00006,
            "B": 1.12e-8
        },
        {
            "R": 0.000151,
            "G": 0.0000565,
            "B": 1.06e-8
        },
        {
            "R": 0.000141,
            "G": 0.000053,
            "B": 9.96e-9
        },
        {
            "R": 0.000132,
            "G": 0.0000494,
            "B": 9.33e-9
        },
        {
            "R": 0.000122,
            "G": 0.0000459,
            "B": 8.71e-9
        },
        {
            "R": 0.000113,
            "G": 0.0000424,
            "B": 8.09e-9
        },
        {
            "R": 0.000106,
            "G": 0.0000399,
            "B": 7.64e-9
        },
        {
            "R": 0.0000996,
            "G": 0.0000374,
            "B": 7.19e-9
        },
        {
            "R": 0.0000929,
            "G": 0.000035,
            "B": 6.73e-9
        },
        {
            "R": 0.0000862,
            "G": 0.0000325,
            "B": 6.28e-9
        },
        {
            "R": 0.0000795,
            "G": 0.00003,
            "B": 5.83e-9
        },
        {
            "R": 0.0000748,
            "G": 0.0000282,
            "B": 5.51e-9
        },
        {
            "R": 0.0000701,
            "G": 0.0000265,
            "B": 5.18e-9
        },
        {
            "R": 0.0000655,
            "G": 0.0000247,
            "B": 4.86e-9
        },
        {
            "R": 0.0000608,
            "G": 0.000023,
            "B": 4.53e-9
        },
        {
            "R": 0.0000561,
            "G": 0.0000212,
            "B": 4.21e-9
        },
        {
            "R": 0.0000528,
            "G": 0.00002,
            "B": 3.98e-9
        },
        {
            "R": 0.0000495,
            "G": 0.0000187,
            "B": 3.74e-9
        },
        {
            "R": 0.0000461,
            "G": 0.0000175,
            "B": 3.51e-9
        },
        {
            "R": 0.0000428,
            "G": 0.0000162,
            "B": 3.27e-9
        },
        {
            "R": 0.0000395,
            "G": 0.000015,
            "B": 3.04e-9
        },
        {
            "R": 0.0000372,
            "G": 0.0000141,
            "B": 2.87e-9
        },
        {
            "R": 0.0000349,
            "G": 0.0000132,
            "B": 2.7e-9
        },
        {
            "R": 0.0000325,
            "G": 0.0000124,
            "B": 2.53e-9
        },
        {
            "R": 0.0000302,
            "G": 0.0000115,
            "B": 2.36e-9
        },
        {
            "R": 0.0000279,
            "G": 0.0000106,
            "B": 2.19e-9
        },
        {
            "R": 0.0000262,
            "G": 0.00000997,
            "B": 2.07e-9
        },
        {
            "R": 0.0000246,
            "G": 0.00000935,
            "B": 1.95e-9
        },
        {
            "R": 0.0000229,
            "G": 0.00000872,
            "B": 1.82e-9
        },
        {
            "R": 0.0000213,
            "G": 0.0000081,
            "B": 1.7e-9
        },
        {
            "R": 0.0000196,
            "G": 0.00000747,
            "B": 1.58e-9
        },
        {
            "R": 0.0000184,
            "G": 0.00000703,
            "B": 1.49e-9
        },
        {
            "R": 0.0000173,
            "G": 0.00000659,
            "B": 1.4e-9
        },
        {
            "R": 0.0000161,
            "G": 0.00000614,
            "B": 1.31e-9
        },
        {
            "R": 0.000015,
            "G": 0.0000057,
            "B": 1.22e-9
        },
        {
            "R": 0.0000138,
            "G": 0.00000526,
            "B": 1.13e-9
        },
        {
            "R": 0.000013,
            "G": 0.00000495,
            "B": 1.07e-9
        },
        {
            "R": 0.0000121,
            "G": 0.00000464,
            "B": 1e-9
        },
        {
            "R": 0.0000113,
            "G": 0.00000432,
            "B": 9.42e-10
        },
        {
            "R": 0.0000105,
            "G": 0.00000401,
            "B": 8.79e-10
        },
        {
            "R": 0.00000967,
            "G": 0.0000037,
            "B": 8.16e-10
        },
        {
            "R": 0.00000909,
            "G": 0.00000348,
            "B": 7.7e-10
        },
        {
            "R": 0.00000852,
            "G": 0.00000326,
            "B": 7.24e-10
        },
        {
            "R": 0.00000794,
            "G": 0.00000305,
            "B": 6.78e-10
        },
        {
            "R": 0.00000737,
            "G": 0.00000283,
            "B": 6.32e-10
        },
        {
            "R": 0.00000679,
            "G": 0.00000261,
            "B": 5.86e-10
        },
        {
            "R": 0.00000639,
            "G": 0.00000246,
            "B": 5.53e-10
        },
        {
            "R": 0.00000598,
            "G": 0.0000023,
            "B": 5.2e-10
        },
        {
            "R": 0.00000558,
            "G": 0.00000215,
            "B": 4.87e-10
        },
        {
            "R": 0.00000517,
            "G": 0.00000199,
            "B": 4.54e-10
        },
        {
            "R": 0.00000477,
            "G": 0.00000184,
            "B": 4.21e-10
        },
        {
            "R": 0.00000449,
            "G": 0.00000173,
            "B": 3.97e-10
        },
        {
            "R": 0.00000421,
            "G": 0.00000162,
            "B": 3.74e-10
        },
        {
            "R": 0.00000392,
            "G": 0.00000152,
            "B": 3.5e-10
        },
        {
            "R": 0.00000364,
            "G": 0.00000141,
            "B": 3.27e-10
        },
        {
            "R": 0.00000336,
            "G": 0.0000013,
            "B": 3.03e-10
        },
        {
            "R": 0.00000316,
            "G": 0.00000122,
            "B": 2.86e-10
        },
        {
            "R": 0.00000296,
            "G": 0.00000114,
            "B": 2.69e-10
        },
        {
            "R": 0.00000275,
            "G": 0.00000107,
            "B": 2.52e-10
        },
        {
            "R": 0.00000255,
            "G": 9.89e-7,
            "B": 2.35e-10
        },
        {
            "R": 0.00000235,
            "G": 9.11e-7,
            "B": 2.18e-10
        },
        {
            "R": 0.00000221,
            "G": 8.56e-7,
            "B": 2.05e-10
        },
        {
            "R": 0.00000207,
            "G": 8.01e-7,
            "B": 1.93e-10
        },
        {
            "R": 0.00000192,
            "G": 7.46e-7,
            "B": 1.8e-10
        },
        {
            "R": 0.00000178,
            "G": 6.91e-7,
            "B": 1.68e-10
        },
        {
            "R": 0.00000164,
            "G": 6.36e-7,
            "B": 1.55e-10
        }
    ];
}