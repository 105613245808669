import { Vector3 } from "three";

export interface iPartVOLaserData {
    /**
    * @description whether to use default rotation function 
    */
    useDefaultRotationFunc: boolean;

    /**
     * @description default brand of the laserbox
     */
    brand: string;

    /**
     * @description whether the laserbox can have laser mask
     * be loaded into the laserbox
     * @default false
     */
    isCanHaveLaserMask: boolean;

    /**
     * @description the name of the default laser source that should
     * be loaded into the laserbox
     */
    defaultLightSource: string;

    /**
     * @description whether the laser box have only single source compatible
     * @default false
     */
    isSingleSource: boolean;

    isUserDefinedLaser: boolean;


    /**
     * @description defines one exit points of the laser
     */
    laserPoint: Vector3;

    /**
     * @description defines one default direction of the laser
     */
    defaultDirectionVector: Vector3;
}

export interface iLine3D {
    point: Vector3;
    direction: Vector3;
};

export class LaserData {

    private mPartVOLaserData: iPartVOLaserData;

    constructor(pPartVOLaserData: iPartVOLaserData) {

        let aExitPoint = (null != pPartVOLaserData.laserPoint) ? pPartVOLaserData.laserPoint :
            { x: 0, y: 0, z: 0 };
        let aDirection = (null != pPartVOLaserData.defaultDirectionVector) ?
            pPartVOLaserData.defaultDirectionVector : { x: 0, y: 0, z: 1 };

        let aIsCanHaveLaserMask = (null != pPartVOLaserData.isCanHaveLaserMask) ?
            pPartVOLaserData.isCanHaveLaserMask : false;
        let aIsSingleSource = (null != pPartVOLaserData.isSingleSource) ?
            pPartVOLaserData.isSingleSource : false;
        let aUseDefaultRotationFunc = (null != pPartVOLaserData.useDefaultRotationFunc) ?
            pPartVOLaserData.useDefaultRotationFunc : false;

        this.mPartVOLaserData = {
            isUserDefinedLaser: pPartVOLaserData.isUserDefinedLaser,
            brand: pPartVOLaserData.brand,
            defaultLightSource: pPartVOLaserData.defaultLightSource,
            defaultDirectionVector: new Vector3(aDirection.x, aDirection.y, aDirection.z),
            isCanHaveLaserMask: aIsCanHaveLaserMask,
            isSingleSource: aIsSingleSource,
            laserPoint: new Vector3(aExitPoint.x, aExitPoint.y, aExitPoint.z),
            useDefaultRotationFunc: aUseDefaultRotationFunc
        };
    }
    //__________________________________________________________________________________________
    public get useDefaultRotationFunc(): boolean {
        return (true == this.mPartVOLaserData.useDefaultRotationFunc);
    }
    //__________________________________________________________________________________________
    public get isUserDefinedLaser(): boolean {
        return this.mPartVOLaserData.isUserDefinedLaser;
    }
    //__________________________________________________________________________________________
    public get brand(): string {
        return this.mPartVOLaserData.brand;
    }
    //__________________________________________________________________________________________
    public get laserPoint() {
        return this.mPartVOLaserData.laserPoint.clone();
    }
    //__________________________________________________________________________________________
    public get isSingleSource() {
        return (true == this.mPartVOLaserData.isSingleSource);
    }
    //__________________________________________________________________________________________
    public get lightSourceID() {
        return (this.mPartVOLaserData.defaultLightSource);
    }
    //__________________________________________________________________________________________
    public get isCanHaveLaserMask() {
        return (true == this.mPartVOLaserData.isCanHaveLaserMask);
    }
    //__________________________________________________________________________________________
    public get direction() {
        return this.mPartVOLaserData.defaultDirectionVector.clone();
    }
    //__________________________________________________________________________________________
}
