import { Object3D, Box3, Vector3, Line3, BufferGeometry, LineDashedMaterial, Line, Intersection, Mesh } from "three";
import { EventManager } from "../../oc/events/EventManager";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { iClientPoint, iSideBarData, iHash, iChooseOpticsEvent, iChooseOptomechanicEvent, iAnalysisFaceItem } from "../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { SceneContext } from "../scene/SceneContext";
import { eClickMode } from "../ui/_globals/PartsEventsHandler";
import { iAnalysisItem, tAnalysisType, eAnalysisType } from "../ui/analysis/AnalysisContext";
import { UnitHandler } from "../units/UnitsHandler";
import { OptixPartDisplayer } from "./_parts_assets/OptixPartDisplayer";
import { eMeshType, iBOMItem } from "./_parts_assets/PartsManager";
import { CageBehavior } from "./behaviors/CageBehavior";
import { LaserBehavior } from "./behaviors/LaserBehavior";
import { OpticsFactory } from "./optics/OpticsFactory";
import { Utils } from "../../oc/events/Utils";
import { eLabelType } from "../scene/CSS2DLabel";
import { Part } from "./Part";
import { iFace } from "./PartInterfaces";
import { SimulationContext, eSmRaysKind } from "../simulation/SimulationContext";

export interface iBOMData {
    optics: iHash<iBOMItem>;
    optomechanics: iHash<iBOMItem>;
}
export enum eObjectType {
    OPTICS,
    OPTOMECHANICS_FACE,
    OPTOMECHANICS_EDGE,
    USER_DEFINED
}

export class absPartsManager {
    protected static DEBUG_CENTER: boolean = false;
    protected mPartsContainer: Object3D;
    protected mStaticPartsContainer: Object3D;
    protected mSelectedPart: Part;
    protected mParts = new Array<Part>();
    protected mLinkedLinesParent: Object3D;
    private mSphere: Mesh;
    private mBox: Object3D;
    protected mMouseDownPart: Part;

    constructor() {
        this._init();
        this._addEventListeners();
    }
    //______________________________________________________________________________________________
    public getAllWavelengthsInSystem(): Array<number> {
        return []
    }
    //______________________________________________________________________________________________
    public getRawBOMData(): iBOMData {
        return null;
    }
    //______________________________________________________________________________________________
    public async deleteAllOpticsByNumberID(_pNumberID: string) { }
    //______________________________________________________________________________________________
    public updatePartsByNumberID(_pNumberID: string, _pInternalId: string) { }
    //______________________________________________________________________________________________
    public chooseOptics(_pData: iChooseOpticsEvent): Part {
        return null
    }
    //______________________________________________________________________________________________
    public chooseOptomechanics(_pData: iChooseOptomechanicEvent): Vector3 {
        return null
    }
    //______________________________________________________________________________________________
    public deleteOpticsFromPart(_pPart: Part) {
        throw new Error("No implementation");
    }
    //______________________________________________________________________________________________
    public isToShowVertices(pIsToShow: boolean) {
        this.getAllVertices().forEach(part => part.visible = pIsToShow)
    }
    //______________________________________________________________________________________________

    public getSceneObjectsByType(pType: eObjectType, pExceptDetector?: boolean) {
        const aParts = Op3dContext.PARTS_MANAGER.parts;
        let aObjects = []
        switch (pType) {
            case eObjectType.OPTOMECHANICS_EDGE:
                for (let i = 0; i < this.mParts.length; i++) {

                    (this.mParts[i] as any).mObject3D.traverse((obj) => {
                        if (obj.type == eMeshType.EDGE) {
                            aObjects.push(obj)
                        };
                    })
                }
                break;
            case eObjectType.OPTOMECHANICS_FACE:
                for (let i = 0; i < this.mParts.length; i++) {

                    (this.mParts[i] as any).mObject3D.traverse((obj) => {
                        if (obj.type == eMeshType.FACE) {
                            aObjects.push(obj)
                        };
                    })
                }
                break;
            case eObjectType.OPTICS:

                for (let i = 0; i < aParts.length; i++) {
                    const aPart = aParts[i];

                    if (aPart.id == null) {
                        aObjects.push(aPart.visibleObj)
                    }
                }

                break;
            case eObjectType.USER_DEFINED:
                for (let i = 0; i < aParts.length; i++) {
                    const aPart = aParts[i];

                    if (aPart.id === 'L1111' || (aPart.id === 'D1012' && pExceptDetector !== true) || (aPart.id === 'CS' && pExceptDetector !== true)) {
                        aObjects.push(aPart.visibleObj)
                    }
                }

                break
        }

        return aObjects;
    }

    //______________________________________________________________________________________________
    public isToShowEdges(_pIsToShow: boolean) {

    }
    //______________________________________________________________________________________________
    public get sideBarPart() {
        return null;
    }
    //______________________________________________________________________________________________
    public async deletePartsByID(_pID: string) { }
    //______________________________________________________________________________________________
    protected _addEventListeners() {
    }
    //______________________________________________________________________________________________
    public getAllVertices(): Array<Object3D> {
        let aVerticesParts: Array<Object3D> = []
        for (let i = 0; i < this.mParts.length; i++) {

            (this.mParts[i] as any).mObject3D.traverse((obj) => {
                if (obj.type == eMeshType.VERTEX) {
                    aVerticesParts.push(obj)
                };
            })
        }

        return aVerticesParts
    }
    //______________________________________________________________________________________________
    public onMouseMove(_pEvent: iClientPoint) { }
    //______________________________________________________________________________________________
    public async onMouseUp(_e: iClientPoint, _pTarget: Event): Promise<void> { }
    //______________________________________________________________________________________________
    public onMouseDown(_pEvent: iClientPoint, _pObject: Intersection): void { }
    //______________________________________________________________________________________________
    public onDblMouseDown(_pEvent: iClientPoint, _pTarget: Event): void { }
    //______________________________________________________________________________________________
    protected _onDeselectPart() {
        this.setSelectedPart(null);
    }
    //______________________________________________________________________________________________
    protected _clearScene() {
    }
    //______________________________________________________________________________________________
    public async onSideBarMouseDown(_pData: iSideBarData) { }
    //______________________________________________________________________________________________
    public updatePartsList(pForgetState: boolean = true) {
        if (Op3dContext.setupIsLoaded) {
            EventManager.dispatchEvent(EventsContext.UPDATE_PARTS_LIST, this,
                {
                    parts: this.mParts,
                    forget: pForgetState
                });
        } else {
            return
        }
    }
    //______________________________________________________________________________________________
    public getLasersParts() {
        let aLasers: iHash<Part> = {};
        const aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            const aPart = aParts[i];

            let aLaserBehavior = aPart.getBehavior("laserBehavior");
            if (aLaserBehavior != null) {
                aLasers[aPart.internalID] = aPart;
            }
        }
        return aLasers;
    }
    //______________________________________________________________________________________________
    public getLasers() {
        let aLasers: iHash<LaserBehavior> = {};
        const aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            const aPart = aParts[i];

            let aLaserBehavior = aPart.getBehavior("laserBehavior");
            if (aLaserBehavior != null) {
                aLasers[aPart.internalID] = aLaserBehavior;
            }
        }
        return aLasers;
    }
    //______________________________________________________________________________________________
    public setOnMouseMoveCallback(_pKey: string, _pFunc: Function) { }
    //______________________________________________________________________________________________
    public checkConnectionWithParts(pPart: Part) {
        for (let i = 0; i < this.mParts.length; i++) {
            const aPart = this.mParts[i];
            if (aPart != pPart) {
                let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id)
                if ((null == aPartVO) || (true != aPartVO.hasCage)) {
                    return false;
                }
                CageBehavior.checkConnectionWith(aPart, pPart);

            }
        }
    }
    //______________________________________________________________________________________________

    //______________________________________________________________________________________________
    public getPartByFaceID(pSurfaceID: string) {
        for (let i = 0; i < this.mParts.length; i++) {
            const aPart = this.mParts[i];
            let aFace = aPart.getFaces().find(item => item.internal_id == pSurfaceID);
            if (aFace != null) {
                return aPart;
            }
        }

        return null;
    }

    //______________________________________________________________________________________________
    protected _onNew() {
    }
    //______________________________________________________________________________________________
    public getLabelIndex(pPart: Part, pLabel: string) {
        let aLabelsIndexes = this.mParts.filter((part) => {
            if (pPart == part) {
                return false;
            }

            let aLabel = part.getLabel();
            if (null == aLabel) {
                return false;
            }

            return (pLabel == aLabel.label);

        }).map((part) => part.getLabel().index);


        let aIndex = 0;
        while (aLabelsIndexes.indexOf(aIndex) > -1) {
            aIndex++;
        }

        return aIndex;
    }
    //______________________________________________________________________________________________
    public setSelectedPart(pPart: Part) {
        if (this.mSelectedPart == pPart) {
            return;
        }

        if (pPart != null && pPart.visibleObj.visible == false) {
            return;
        }

        if (null != this.mSelectedPart) {
            if (SceneContext.CHOOSE_MODE.mode != eClickMode.GROUP) {
                this.mSelectedPart.onDeselect();
                EventManager.dispatchEvent(EventsContext.PART_DESELECTED, this,
                    this.mSelectedPart);
            }
        }

        this.mSelectedPart = pPart;

        if (null != pPart && SceneContext.CHOOSE_MODE.mode != eClickMode.GROUP) {
            EventManager.dispatchEvent(EventsContext.PART_SELECTED, this, pPart);
        }
    }
    //______________________________________________________________________________________________
    public addPart(pPart: Part) {

        if ((null == pPart) || (this.mParts.findIndex(part => part == pPart) > -1)) {
            return;
        }

        if (false == pPart.partOptions.static) {
            this.mPartsContainer.add(pPart.visibleObj);

        } else {
            this.mStaticPartsContainer.add(pPart.visibleObj);
        }
        SceneContext.OP3D_SCENE.activateRenderer()

        this.mParts.push(pPart);
        // this.updatePartsList(false);
        this.updateLinkedParts();
    }
    //______________________________________________________________________________________________
    public updateLinkedParts() {
        if (null == this.mLinkedLinesParent) {
            return;
        }

        this._clearLinkedPartsLines();
        this._createLinkedPartsLines();
    }
    //______________________________________________________________________________________________
    public setLinkedPartsLinesVisibility(pVisible: boolean) {
        if (null == this.mLinkedLinesParent) {
            return;
        }

        this.mLinkedLinesParent.visible = pVisible;
        if (true == pVisible) {
            this.updateLinkedParts();
        }
    }
    //______________________________________________________________________________________________
    public setAllLabelsVisibility(_pType: eLabelType, _pToShow: boolean) {
        throw new Error("No implementation");
    }
    //______________________________________________________________________________________________
    public resetPowerReadings() {
        throw new Error("No implementation");
    }
    //______________________________________________________________________________________________
    public getPartByAnalysisById(pID: string) {
        let aPart: Part;
        for (let i = 0; i < this.mParts.length; i++) {
            const aOptions = this.mParts[i].analysisOptions;
            if (null != aOptions) {
                for (let j = 0; j < aOptions.length; j++) {
                    let aAnalysis = aOptions[j].advanced.find(analysis => analysis.id == pID);
                    if (null == aAnalysis) {
                        aAnalysis = aOptions[j].fast.find(analysis => analysis.id == pID);
                    }

                    if (null != aAnalysis) {
                        aPart = this.mParts[i];
                        break;
                    }
                }
            }
        }

        return aPart;
    }
    //______________________________________________________________________________________________
    public getAnalysisFace(pAnalysisId: string) {
        for (let i = 0; i < this.mParts.length; i++) {
            const aOptions = this.mParts[i].analysisOptions;
            if (aOptions != null) {
                for (let j = 0; j < aOptions.length; j++) {
                    let aAnalysis: iAnalysisItem<tAnalysisType>;
                    const aAnalysisOption = aOptions[j];
                    aAnalysis = aAnalysisOption.advanced.find(analysis =>
                        (analysis.id === pAnalysisId));
                    if (undefined === aAnalysis) {
                        aAnalysis = aAnalysisOption.fast.find(analysis =>
                            (analysis.id === pAnalysisId));
                    }

                    if (undefined !== aAnalysis) {
                        let aFaces = this.mParts[i].getFaces();
                        return aFaces.find(face => aOptions[j].faceId === face.internal_id);
                    }
                }
            }
        }

        return undefined;
    }
    //______________________________________________________________________________________________
    public getFaceAnalysisByAnalysisID(pAnalysisId: string) {
        let aAnalysisFaceItem: iAnalysisFaceItem;
        for (let i = 0; i < this.mParts.length; i++) {
            const aOptions = this.mParts[i].analysisOptions;
            if (aOptions != null) {
                for (let j = 0; j < aOptions.length; j++) {
                    let aAnalysis: iAnalysisItem<tAnalysisType>;
                    const aAnalysisOption = aOptions[j];
                    aAnalysis = aAnalysisOption.advanced.find(analysis =>
                        (analysis.id === pAnalysisId));
                    if (undefined === aAnalysis) {
                        aAnalysis = aAnalysisOption.fast.find(analysis =>
                            (analysis.id === pAnalysisId));
                    }

                    if (undefined !== aAnalysis) {
                        let aFaces = this.mParts[i].getFaces();
                        let aFace = aFaces.find(face => aOptions[j].faceId === face.internal_id);

                        aAnalysisFaceItem = {
                            analysis: aAnalysis,
                            face: aFace
                        };

                        return aAnalysisFaceItem;
                    }
                }
            }
        }

        return aAnalysisFaceItem;
    }
    //______________________________________________________________________________________________
    public getAnalysisById(pAnalysisId: string) {
        let aAnalysis: iAnalysisItem<tAnalysisType>;
        for (let i = 0; i < this.mParts.length; i++) {
            const aOptions = this.mParts[i].analysisOptions;
            if (aOptions != null) {
                for (let j = 0; j < aOptions.length; j++) {
                    aAnalysis = aOptions[j].advanced.find(analysis => analysis.id == pAnalysisId);
                    if (aAnalysis == null) {
                        aAnalysis = aOptions[j].fast.find(analysis => analysis.id == pAnalysisId);
                    }

                    if (aAnalysis != null) {
                        return aAnalysis;
                    }
                }
            }
        }

        return aAnalysis;
    }
    //______________________________________________________________________________________________
    public async deletePart(_pPart: Part) { }
    //______________________________________________________________________________________________
    public deleteSelected(_pEvent?: Event) { }
    //______________________________________________________________________________________________
    public getAllOpticFaces() {
        let aFaces = new Array<iFace>();
        for (let i = 0; i < this.mParts.length; i++) {
            aFaces.push(...this.mParts[i].getOpticsFaces());
        }
        return aFaces;
    }
    //______________________________________________________________________________________________
    public async updateAllParts() {
        // await Op3dContext.wait(() => ((Op3dContext.TOTAL_PARTS == this.mParts.length - 1 && Op3dContext.TOTAL_PARTS == Op3dContext.LOADED_PARTS)));
        await Op3dContext.wait(() => (Op3dContext.TOTAL_PARTS == Op3dContext.LOADED_PARTS));
        for (let i = 0; i < this.mParts.length; i++) {
            const aPart = this.mParts[i];
            aPart.update();
            this.checkConnectionWithParts(aPart);
        }
    }
    //______________________________________________________________________________________________
    public get partsContainer() {
        return this.mPartsContainer;
    }
    //______________________________________________________________________________________________
    public get selectedPart(): Part {
        return this.mSelectedPart;
    }
    //______________________________________________________________________________________________
    public get parts() {
        return this.mParts;
    }
    //______________________________________________________________________________________________
    public getStop() {
        for (let i = 0; i < this.mParts.length; i++) {
            if (this.mParts[i].iPart.shapes[0].name == 'STOP') {
                return this.mParts[i];
            }
        }

        return null;
    }
    //______________________________________________________________________________________________
    public rotatePlaneOnCamera(pPlane: Object3D) {
        if (pPlane != null) {
            pPlane.quaternion.copy(SceneContext.CAMERA.quaternion)
        }
    }
    //______________________________________________________________________________________________
    public set mouseDownPart(_pPart: Part) {
    }
    //______________________________________________________________________________________________
    private _updateSingleAnalysis(pAnalysis: iAnalysisItem, pLaserIds: Array<string>, pToDelete: boolean) {
        let aRays = pAnalysis.numRays;
        let aInternalIds = Object.keys(aRays);
        if (pToDelete) {
            let aDeleted = aInternalIds.filter(item => !pLaserIds.includes(item));
            for (let i = 0; i < aDeleted.length; i++) {
                delete pAnalysis.numRays[aDeleted[i]];
            }
        } else {
            let aAdded = pLaserIds.filter(item => !aInternalIds.includes(item));
            let aCount = pAnalysis.type == eAnalysisType.FAST ?
                SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.regular :
                SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.analysis;

            for (let i = 0; i < aAdded.length; i++) {
                pAnalysis.numRays[aAdded[i]] = aCount;
            }
        }
    }
    //______________________________________________________________________________________________
    public updateAnalysisOptions(pToDelete: boolean) {
        let aLaserParts = this.mParts.filter(part => part.getBehavior("laserBehavior") != null);
        let aLaserIds = aLaserParts.map(laser => laser.internalID);

        for (let i = 0; i < this.mParts.length; i++) {
            let aAnalysisOptions = this.mParts[i].analysisOptions;
            if (aAnalysisOptions != null) {

                aAnalysisOptions.forEach(item => {
                    let aAnalyses = [...item.advanced, ...item.fast];
                    aAnalyses.forEach(analysis => {
                        this._updateSingleAnalysis(analysis, aLaserIds, pToDelete);
                    });
                });
            }
        }
    }
    //______________________________________________________________________________________________
    private _init() {
        this.mPartsContainer = new Object3D();
        this.mStaticPartsContainer = new Object3D();
        this.mStaticPartsContainer.name = "static_parts-container";
        this.mPartsContainer.name = "parts-container";
        SceneContext.MAIN_SCENE.add(this.mPartsContainer);
        SceneContext.MAIN_SCENE.add(this.mStaticPartsContainer);
    }
    //______________________________________________________________________________________________
    public getFaceNameById(pFaceInternalId: string) {
        for (let i = 0; i < this.mParts.length; i++) {
            let aFaces = this.mParts[i].getFaces();
            let aItem = aFaces.find(item => item.internal_id == pFaceInternalId);
            if (aItem != null) {
                return aItem.name;
            }
        }

        return null;
    }
    //______________________________________________________________________________________________
    public getPartByInternalId(pInternalID: string) {
        for (let part in this.mParts) {
            if (pInternalID == this.mParts[part].internalID) {
                return this.mParts[part];
            }
        }

        return null;
    }
    //______________________________________________________________________________________________
    public async duplicateSeleced() { }
    //______________________________________________________________________________________________
    public async duplicatePart(_pOptions: { part: Part, isSingleDuplicate: boolean, addToHistory: boolean }) {
        return null;
    }
    //______________________________________________________________________________________________
    public unAddPart() { }
    //______________________________________________________________________________________________
    public addParaxialLens(pPart: Part, pOpticsVO: iOpticsVO) {
        let aParaxialLens = OpticsFactory.createOpticalDevice(pOpticsVO);

        pPart.addSubPartToPart(pPart.iPart, aParaxialLens);
        pPart.paraxialLensData = pOpticsVO;
        pPart.setWireframeVisibility(true);

        pPart.paraxialLensData = pOpticsVO;
    }
    //______________________________________________________________________________________________
    private _clearLinkedPartsLines() {
        if (null == this.mLinkedLinesParent) {
            return;
        }

        Op3dContext.GCS.getAxes()[0].object3D.hide();
        while (this.mLinkedLinesParent.children.length > 1) {
            this.mLinkedLinesParent.remove(this.mLinkedLinesParent.children[0]);
        }

        this.mLinkedLinesParent.parent.remove(this.mLinkedLinesParent);
        this.mLinkedLinesParent = null;
    }
    //______________________________________________________________________________________________
    public showLinesVisibility(pToShow: boolean) {
        this._clearLinkedPartsLines();
        if (false == pToShow) {
            return;
        }

        this._createLinkedPartsLines();
    }
    //______________________________________________________________________________________________
    public unHiglightAllParts() {
        for (let i = 0; i < this.mParts.length; i++) {
            OptixPartDisplayer.unHighlightObject(this.mParts[i]);
        }
    }
    //______________________________________________________________________________________________
    public getCenter() {
        if (this.mSphere != null) {
            this.mSphere.parent.remove(this.mSphere)
        }


        if (this.mBox != null && this.mBox.parent != null) {
            this.mBox.parent.remove(this.mBox);
        }

        let aBox3 = new Box3().setFromObject(this.mPartsContainer)
        let aPosition = aBox3.getCenter(new Vector3());

        if (absPartsManager.DEBUG_CENTER == true) {
            Op3dUtils.addBoxHelperToScene(this.mPartsContainer);

            this.mSphere = Utils.getSphere();
            this.mPartsContainer.add(this.mSphere);
            this.mSphere.position.copy(aPosition);
        }

        return aPosition;
    }
    //______________________________________________________________________________________________
    public initGCS() {
        Op3dContext.GCS = new Part({ id: 'GCS', options: { initialHeight: 0, static: true } });
    }
    //______________________________________________________________________________________________
    private _getConnectingLine(pPart: Part) {
        let aLine3 = new Line3();
        aLine3.start = pPart.workingLCS.object3D.getWorldPosition(new Vector3());
        aLine3.end = pPart.refCS.cs.object3D.getWorldPosition(new Vector3());

        let aScale = UnitHandler.scale;
        let aGeometry = new BufferGeometry().setFromPoints([aLine3.start, aLine3.end]);
        let aMaterial = new LineDashedMaterial({
            color: (0xFFFFFF - Op3dContext.USER_VO.simulationSettings.sceneBGColor),
            linewidth: 10,
            scale: 1,
            dashSize: (10 * aScale),
            gapSize: (10 * aScale)
        });

        let aLine = new Line(aGeometry, aMaterial);
        return aLine;
    }
    //______________________________________________________________________________________________
    private _createLinkedPartsLines() {
        this.mLinkedLinesParent = new Object3D();
        for (let i = 0; i < this.mParts.length; i++) {
            let aPart = this.mParts[i];

            if (null != aPart.refCS) {
                let aLine = this._getConnectingLine(aPart);
                aLine.computeLineDistances();
                this.mLinkedLinesParent.add(aLine);
            }
        }

        Op3dContext.GCS.getAxes()[0].object3D.show();

        SceneContext.MAIN_SCENE.add(this.mLinkedLinesParent);
    }
    //______________________________________________________________________________________________
    public gaussianBeamExistsOnScene() {
        for (let i = 0; i < this.mParts.length; i++) {
            const aLasBehavior = this.mParts[i].getBehavior('laserBehavior');
            if (aLasBehavior != null) {
                if (aLasBehavior.laserData.lightSource.kind === eSmRaysKind.GAUSSIAN_BEAM) {
                    return true;
                }
            } else {
                continue
            }
        }
        return false;
    }
    //______________________________________________________________________________________________
}