import { eIngredientType } from "../../_context/Enums";

import { iElasticQuery, iGeneralIngrediantVOQuery } from "../../_context/_interfaces/Interfaces";
import { ServerContext } from "../../server/ServerContext";
import { CacheStorage, eCacheDataType } from "../CacheStorage";
import { iOptomechanicVO } from "../VO/OpticsVOInterfaces";

import { DataLoader } from "./DataLoader";

export class OptomechanicDataLoader extends DataLoader<iOptomechanicVO,
    iGeneralIngrediantVOQuery,
    iOptomechanicVO, iOptomechanicVO> {
    private static INSTANCE: OptomechanicDataLoader;

    private constructor() {
        super({

            fullDataServerCall: (pOpticsVOQuery: iGeneralIngrediantVOQuery) =>
                ServerContext.SERVER.getOneIngredient({
                    number_id: pOpticsVOQuery.number_id,
                    type: eIngredientType.OPTOMECHANIC
                }),
            cacheData: {
                key: 'number_id',
                type: eCacheDataType.OPTOMECHANIC
            },
            addItemServerCall: (pData) =>
                ServerContext.SERVER.updateOpticAdmin(pData as any),
            getManyItems: (pQuery) =>
                ServerContext.SERVER.getManyPartsElements(pQuery as iElasticQuery),
            isExistServerCall: (pOpticsVO) =>
                ServerContext.SERVER.isIngredientExists({
                    numberID: pOpticsVO.number_id,
                    ingredient: eIngredientType.OPTIC
                }),
            dataConvertionFunc: (pData: iOptomechanicVO) =>
                this._onGettingOptomechanicVO(pData)


        });
    }
    //__________________________________________________________________________________________
    public removeFromCache(pKey: string) {
        CacheStorage.instance.removeData(pKey, this.mDataLoaderParams.cacheData.type);
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public async getManyItems(pQuery: iElasticQuery) {
        if (this.mNeedsUpdate === true) {
            let aResult = await this.mDataLoaderParams.getManyItems(pQuery);
            if (aResult.success) {

                this.mDataTotal = aResult.data.meta.page.total_results;
                this.mTotalAggregation = aResult.data.aggregation
                this.mCurrentData = aResult.data.results.map(item => {
                    return item
                });

                // }
            } else {
                this.mDataTotal = 0;

                this.mCurrentData = [];
            }
            this.mNeedsUpdate = false;
            return this.mCurrentData;
        } else {
            return this.mCurrentData
        }
    }
    //__________________________________________________________________________________________
    private async _onGettingOptomechanicVO(pOptomechanicVO: iOptomechanicVO) {
        if (null == pOptomechanicVO) {
            return null;
        }

        return pOptomechanicVO;
    }
    //__________________________________________________________________________________________
}
