import { iMinMax, iPoint2D } from "../../../_context/_interfaces/Interfaces";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";

export interface iGraphRangeData {
    x: {
        range: iMinMax,
        isInfinite: boolean
    },
    y: {
        range: iMinMax,
        isInfinite: boolean
    },
    onChange: (pData: iPoint2D<iMinMax>) => void
}

export class GraphAxisRange extends Op3dComponentBase<iGraphRangeData>{

    private mXRange: iMinMax<HTMLInputElement>;
    private mYRange: iMinMax<HTMLInputElement>;
    // private mOnCloseScene = (e: MouseEvent) => this._onCloseScene(e);

    constructor() {
        super({
            container: GraphAxisRange._getContainer(),
            skinPath: './skins/forms/analysis/graph_axis_range_form.html',
            draggableParams: {
                snap: true,
                class: "draggable-form",
                containment: 'window',
                handle: ".graph-axis-range__header"
            },
        });
    }
    //________________________________________________________________
    // private _onCloseScene(e: MouseEvent) {
    //     if (this.mContainer.contains(e.target as HTMLElement) === true) {
    //         return;
    //     }

    //     this.close();
    // }
    //________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();
        // document.removeEventListener("click", this.mOnCloseScene, true);
        ViewUtils.removeFromParent(this.mContainer);
    }
    //________________________________________________________________
    protected _onOpen(pData: iGraphRangeData): void {

        this.mData = pData;

        // document.addEventListener("click", this.mOnCloseScene, true);
        this.mXRange.min.value = pData.x.range.min.toString();
        this.mXRange.max.value = pData.x.range.max.toString();
        this.mYRange.min.value = pData.y.range.min.toString();
        this.mYRange.max.value = pData.y.range.max.toString();

        // this.mXRange.min.addEventListener("change", () => this._onChangeRange())
        // this.mXRange.max.addEventListener("change", () => this._onChangeRange())

        // this.mYRange.min.addEventListener("change", () => this._onChangeRange())
        // this.mYRange.max.addEventListener("change", () => this._onChangeRange());

        let aSaveBtn = this._getPart("save-btn");
        aSaveBtn.addEventListener("click", () => this.onSave());
    }
    //________________________________________________________________
    private onSave() {
        this._onChangeRange();
    }
    //________________________________________________________________
    protected _onShown(): void {
        let aBB = this.mContainer.getBoundingClientRect();
        let aX = (window.innerWidth / 2) - (aBB.width / 2);
        let aY = (window.innerHeight / 2) - (aBB.height / 2);

        this.mContainer.style.left = aX + 'px';
        this.mContainer.style.top = aY + 'px';
    }
    //________________________________________________________________
    private _onChangeRange() {
        let aRange: iPoint2D<iMinMax> = {
            x: {
                min: parseFloat(this.mXRange.min.value),
                max: parseFloat(this.mXRange.max.value),
            },
            y: {
                min: parseFloat(this.mYRange.min.value),
                max: parseFloat(this.mYRange.max.value),
            }
        }

        if (this.mData.x.isInfinite === false) {
            if (aRange.x.min < this.mData.x.range.min) {
                this.mXRange.min.value = this.mData.x.range.min.toString();
                aRange.x.min = this.mData.x.range.min;
            }
            if (aRange.x.max > this.mData.x.range.max) {
                this.mXRange.max.value = this.mData.x.range.max.toString();
                aRange.x.max = this.mData.x.range.max;
            }
        }

        if (this.mData.y.isInfinite === false) {
            if (aRange.y.min < this.mData.y.range.min) {
                this.mYRange.min.value = this.mData.y.range.min.toString();
                aRange.y.min = this.mData.y.range.min;
            }
            if (aRange.y.max > this.mData.y.range.max) {
                this.mYRange.max.value = this.mData.y.range.max.toString();
                aRange.y.max = this.mData.y.range.max;
            }
        }

        this.mData.onChange(aRange);
    }
    //________________________________________________________________
    protected _onCreationComplete(): void {

        this.mXRange = {
            min: this._getPart("x-min") as HTMLInputElement,
            max: this._getPart("x-max") as HTMLInputElement,
        }
        this.mYRange = {
            min: this._getPart("y-min") as HTMLInputElement,
            max: this._getPart("y-max") as HTMLInputElement,
        }
        this.mIsReady = true;
    }
    //________________________________________________________________
    private static _getContainer() {
        let aFormsContainer = document.getElementById('forms');
        if (aFormsContainer === null) {
            throw new Error("forms container not found");
        }

        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('new-modal');
        aDiv.style.width = "auto";
        aDiv.classList.add('overflow-hidden');
        aDiv.setAttribute("data-backdrop", "false");
        aFormsContainer.appendChild(aDiv);
        return aDiv;
    }
    //________________________________________________________________
}