import { eUnitType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../../parts/Part";
import { CageBehavior } from "../../parts/behaviors/CageBehavior";
import { UnitHandler } from "../../units/UnitsHandler";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";

export interface iCageSection {
    part: Part;
}

export class piCageSection extends PartInfoSection<iCageSection> {

    private mCageMove: NumberInputElement;
    private mCageSize: NumberInputElement;
    // private mHidePost: HTMLInputElement;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_cage.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._initCageSize();
    }
    //__________________________________________________________________________________________
    private _initCageSize() {
        this.mCageSize = new NumberInputElement(
            this._getPart('cage-size'), {
            isGlobalToFixed: true,
            toFixed: Op3dContext.USER_VO.userVO.parameters.simulation.digitsAfterDot,
            unitName: UnitHandler.shortSign,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });

        this.mCageSize.setData({
            callback: (pValue: number) =>
                this._onUpdateCageSize(pValue),
            range: {
                min: 0,
                max: (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 635 : 25
            },
            step: (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 12.5 : 0.5
        });

        this.mCageMove = new NumberInputElement(
            this._getPart('cage-move'), {
            isGlobalToFixed: true,
            toFixed: Op3dContext.USER_VO.userVO.parameters.simulation.digitsAfterDot,
            unitName: UnitHandler.shortSign,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: true
            }
        });

        this.mCageMove.setData({
            callback: (pValue: number) =>
                this._onUpdatePosOnCage(pValue),
            step: (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 1 : 0.04
        });
    }
    //__________________________________________________________________________________________
    private _onUpdateCageSize(pValue: number) {
        Op3dContext.SCENE_HISTORY.addToHistory();

        let aValue = (pValue / UnitHandler.presentedScale);
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;

        CageBehavior.updateCageSize(aSelectedPart, aValue)

    }
    //__________________________________________________________________________________________
    private _onUpdatePosOnCage(pPos: number) {
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        let aPos = (pPos / UnitHandler.presentedScale);

        CageBehavior.updatePosOnCage(aSelectedPart, aPos)
    }
    //__________________________________________________________________________________________
    protected async _fillSection(pData: iCageSection) {

        let aPart = pData.part;

        let aUnitScale = UnitHandler.presentedScale;
        if (true == CageBehavior.isConnectedToCage(aPart)) {
            this.mCageSize.value = 0;
            let aAnchor = aPart.refCS.refPart;

            let aLength = (CageBehavior.getCageLength(aAnchor) * aUnitScale);

            this.mCageSize.enable = false;
            this.mCageMove.enable = true;
            this.mCageMove.setData({
                range: {
                    min: 0,
                    max: (aLength - aUnitScale)
                }
            });

            this.mCageMove.value = (CageBehavior.distToAnchor(aPart) * aUnitScale);
        } else {
            this.mCageMove.value = 0;
            this.mCageMove.enable = false;

            let aLength = (CageBehavior.getCageLength(aPart) * aUnitScale);

            this.mCageSize.enable = true;
            this.mCageSize.value = aLength;
        }
    }

}
