﻿import { tInfoData } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ParserContext } from "../../parser/ParserContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { Spinner } from "../home/Spinner";

export abstract class Info<T, S extends tInfoData> extends Op3dComponentBase<T>{
    private static readonly MAX_VALUE_STRING_LENGTH: number = 15;

    protected mDataField: HTMLElement;
    private mMoreFields: Array<HTMLElement>;
    protected mBasicDataField: HTMLElement;
    protected mMoreBasicDataParent: HTMLElement;
    protected mBasicFields: Array<HTMLElement>;
    protected mMoreBasicFields: Array<HTMLElement>;
    protected mBasicFieldsParent: HTMLElement;
    protected mName: HTMLElement;
    protected mBrand: HTMLElement;
    protected mGraphBtn: HTMLElement;
    protected mWeblinkField: HTMLAnchorElement;

    constructor(pElement: HTMLElement, pSkin: string) {
        super({ container: pElement, skinPath: pSkin });

        this.mMoreFields = new Array<HTMLElement>();
        this.mBasicFields = new Array<HTMLElement>();
        this.mMoreBasicFields = new Array<HTMLElement>();
    }
    //_________________________________________________________
    protected _initWeblinkField(pWeblink: string, pWebLinkField: HTMLAnchorElement) {
        if (pWebLinkField == null) {
            return;
        }
        if (pWeblink != null && pWeblink != "undefined" && pWeblink != "") {
            pWebLinkField.href = pWeblink;
            pWebLinkField.innerHTML = pWeblink;
            pWebLinkField.style.display = "block";
        } else {
            pWebLinkField.style.display = "none";
        }
    }
    //_________________________________________________________
    protected abstract _onCreationComplete();
    //_________________________________________________________
    protected _addSingleDataItem(pValue: string, pItem: S) {

        let aDataField: HTMLElement = this.mBasicDataField.cloneNode(true) as HTMLElement;
        let aDataLabel = aDataField.getElementsByClassName("text-label")[0];
        let aDataText = aDataField.getElementsByClassName("text-data")[0] as HTMLElement;
        aDataLabel.innerHTML = pItem.title;

        aDataText.innerHTML = this.getFixedValue(pValue, pItem.sign);


        if (pItem.class_list != null) {
            for (let i = 0; i < pItem.class_list.length; i++) {
                aDataText.classList.add(pItem.class_list[i]);
            }
        }

        if (pItem.html_id != null) {
            aDataText.id = pItem.html_id;
        }

        if (this.mBasicFieldsParent.children.length <= 6) {
            this.mBasicFieldsParent.appendChild(aDataField);
            this.mBasicFields.push(aDataField);

        } else {

            aDataField.classList.remove("col-6");
            aDataField.classList.add("col-3");
            this.mMoreBasicDataParent.appendChild(aDataField);
            this.mMoreBasicFields.push(aDataField);
        }
    }
    //_________________________________________________________
    protected _initElements() {
        super._initElements();
        this.mWeblinkField = this._getPart("weblink", true) as HTMLAnchorElement;

        this.mDataField = this._getPart("data-item", true);
        this.mBasicDataField = this._getPart("basic-field", true);
        this.mBasicFieldsParent = this._getPart("basic-data-parent", true);
        this.mMoreBasicDataParent = Op3dUtils.getElementIn(this.mContainer,
            "more-basic-data-parent", true);
        this.mName = this._getPart("name", true);
        this.mBrand = this._getPart("brand", true);
        this.mGraphBtn = this._getPart("graph-btn", true);

        ViewUtils.removeFromParent(this.mDataField);
        ViewUtils.removeFromParent(this.mBasicDataField);
        this._initGraphBtn();
    }
    //__________________________________________________________________________________________
    private _initGraphBtn() {
        this.mGraphBtn.addEventListener("click", () => this._onGraphClicked());
    }
    //__________________________________________________________________________________________
    protected abstract _initFields(pData: T);
    //__________________________________________________________________________________________
    protected getFixedValue(pValue: string, pSign: string): string {
        if (pValue != null) {
            pSign = pSign != null ? pSign : "";
            let aFixedValue = pValue.toString() + ' [' + pSign + ']';
            let aVal = parseFloat(pValue.toString());

            if (!isNaN(aVal) && pSign == ParserContext.SPECIAL_CHARACTERS.mm) {
                aFixedValue = this._getInchValue(parseFloat(pValue)) + "/" +
                    this._getMMValue(parseFloat(pValue));

                if (aFixedValue.length > Info.MAX_VALUE_STRING_LENGTH) {
                    aFixedValue = this._getInchValue(parseFloat(pValue)) + "/<br>" +
                        this._getMMValue(parseFloat(pValue));
                }

            } else if (!isNaN(aVal) && pSign == ParserContext.SPECIAL_CHARACTERS.degree) {
                if (aFixedValue.length > Info.MAX_VALUE_STRING_LENGTH) {
                    aFixedValue = aVal.toFixed(Info.MAX_VALUE_STRING_LENGTH - 1) + pSign;
                }
            }

            return aFixedValue;
        }
    }
    //__________________________________________________________________________________________
    private _getInchValue(pMMValue: number) {
        let aValue = pMMValue * UnitHandler.MM_TO_IN;

        let aValAsString = aValue.toFixed(5);
        aValAsString = parseFloat(aValAsString).toString();
        aValAsString += ParserContext.SPECIAL_CHARACTERS.inch;

        return aValAsString;
    }
    //__________________________________________________________________________________________
    private _getMMValue(pMMValue: number) {
        let aValAsString = pMMValue.toFixed(5);
        aValAsString = parseFloat(aValAsString).toString();
        aValAsString += ParserContext.SPECIAL_CHARACTERS.mm;

        return aValAsString;
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pData?: T) {
        this._resetFields();
        Spinner.instance.show();
        await this._initFields(pData);
        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    protected _onGraphClicked() { }
    //__________________________________________________________________________________________
    protected _resetFields() {
        for (let i = this.mMoreFields.length - 1; i >= 0; i--) {
            ViewUtils.removeFromParent(this.mMoreFields[i]);
        }

        this.mMoreFields = new Array<HTMLElement>();

        for (let i = this.mBasicFields.length - 1; i >= 0; i--) {
            ViewUtils.removeFromParent(this.mBasicFields[i]);
        }

        for (let i = this.mMoreBasicFields.length - 1; i >= 0; i--) {
            ViewUtils.removeFromParent(this.mMoreBasicFields[i]);
        }

        this.mBasicFields = new Array<HTMLElement>();
        this.mMoreBasicFields = new Array<HTMLElement>();
    }
    //_________________________________________________________
}