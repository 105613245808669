// import { Server } from "./Server";
import {
    eCadType, eDataPermission, eGeneralWebsocketType, eIngredientType,
    eJobMessageType, eJobStatus, eSimulationEvents, eCadEvents,
    eSimulationWebsocketEventType
} from "../_context/Enums";
import { Op3dContext } from "../_context/Op3dContext";
import {
    iCadResolution, iAsyncCallback, iGetManySetupsQuery, iUploadSetupData,
    iServerMetaDataQuery, iMaterialVO, iCadOpticalData, iCoatingVO, iDownloadFullSetupData,
    iGetOneSetup, iMenuSearchQuery, iIngredientQuery, iElasticQuery, iAppFeaturesConfig,
    iAutorizationCodeResult, iServerErrorVO,
    iMaterialMetaDataVO,
    iClientSimulationPayload,
    iNameURL,
    iSimulationPayload,
    iSimulationResultPayload,
} from "../_context/_interfaces/Interfaces";
import { iFullUserData, iUserVO } from "../_context/_interfaces/SettingInterfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { ServerProxy } from "./ServerProxy";
import {
    ENVS,
    ServerContext, eSimulationResultsStorage, iEventData, iLoginResultData,
    iOpticAttachments, iUserTokens
} from "./ServerContext";
import { WebsocketEventsHandler } from "./WebsocketEventsHandler";
import { Popup } from "../ui/forms/Popup";
import { iServerPartVO } from "../data/VO/GeneralVO";
import { iServerPartDetails } from "../ui/forms/ChangePartDetailsForm";
import { iSourceVO } from "../parser/SourcesParser";
import { eCadCallbackReason } from "../ui/forms/tools/ImportCad";
import { Strings } from "../_context/Strings";
import { SimulationRunner } from "../simulation/SimulationRunner";
import { Socket, io } from "socket.io-client";
import { OpticsContext } from "../_context/OpticsContext";
import { iPartMongoDB } from "../parts/PartInterfaces";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { iServerUserVO } from "../_main/Registration";
import { iOptiChatAddMessage, iChatBotElasticResponse } from "../optiChat/store/OptiChatContext";


export class RegisteredServer {


    private mWebSocket!: Socket;
    private mWebSocketEvent: any;
    protected mResultStorate = eSimulationResultsStorage.HTTP;



    constructor(pStorage: eSimulationResultsStorage) {
        // super(pStorage);
        this.mResultStorate = pStorage;
        //this.mWebSocket = undefined;
    }
    //__________________________________________________________________________________________
    public static getTokensByAutorizationCode(pData: { redirect_uri: string, authorization_code: string }) {
        let aURL = ServerContext.backend_url + "/auth/auth_code";
        return new Promise<iAsyncCallback<iAutorizationCodeResult>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: iAutorizationCodeResult }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public static checkEnv(pData: { username: string, password: string }) {
        let aURL = ServerContext.backend_url + "/auth/env";
        return new Promise<iAsyncCallback<boolean>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: boolean }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public static loginAsGuest() {
        let aURL = ServerContext.backend_url + "/auth/login_guest";
        return new Promise<iAsyncCallback<iLoginResultData & iUserTokens>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, {},
                (pData: iLoginResultData & iUserTokens) => resolve({ data: pData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public static acceptTerms(): Promise<iAsyncCallback<boolean>> {
        let aURL = ServerContext.backend_url + "/api/user/accept_terms";

        return new Promise<iAsyncCallback<boolean>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, {},
                (pData: { data: boolean }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public static addError(_pData: iServerErrorVO) {
        // let aURL = ServerContext.backend_url + "/error/add";

        // return new Promise<iAsyncCallback<Object>>((resolve) => {
        //     ServerProxy.ajaxPOST_JSON(aURL, pData,
        //         (pData: any) => resolve({ success: true, data: pData }),
        //         (pErr: any) => resolve({ success: false, data: pErr }));
        // });
    }
    //__________________________________________________________________________________________
    public static getDataByToken(pData: { token: string }) {
        let aURL = ServerContext.backend_url + "/auth/get_data";

        return new Promise<iAsyncCallback<iLoginResultData>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: any) => resolve({ data: pData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public static forgotPassword(pData: { email: string }) {
        let aURL = ServerContext.backend_url + "/api/password/forgot";
        return new Promise<iAsyncCallback<iLoginResultData>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: iLoginResultData) => resolve({ data: pData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public static getUpdatedAccessToken(pBody: { refresh_token: string }): Promise<iAsyncCallback<iUserTokens>> {
        let aURL = ServerContext.backend_url + "/auth/get_tokens";
        return new Promise<iAsyncCallback<iUserTokens>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pBody,
                (pData: { data: iUserTokens }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });

    }
    //__________________________________________________________________________________________
    public static register(pData: iServerUserVO) {
        let aURL = ServerContext.backend_url + "/auth/register";
        return new Promise<iAsyncCallback<iLoginResultData>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: iLoginResultData) => resolve({ data: pData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public static updateUser(pData: { id_token: string }) {
        let aURL = ServerContext.backend_url + "/auth/update_user";
        return new Promise<iAsyncCallback<any>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: null) => resolve({ data: pData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public webSocket() {
        return this.mWebSocket
    }
    //__________________________________________________________________________________________
    public getManySourcesElements(_pQuery: iMenuSearchQuery): Promise<iAsyncCallback<{ data: iSourceVO[]; total: number; }>> {
        let aURL = ServerContext.backend_url + "/api/ingredient/get_meny_sources";
        return new Promise<iAsyncCallback<any>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, _pQuery,
                (pData) => resolve({ success: true, data: pData }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    private _errorNotification(pError?: string) {
        let aMsg = 'This file is not supported.';
        const ERORRS: any = {
            'USER_STORAGE_QUOTA_EXCEEDED_ERROR': 'Storage quota exceeded.',
            'FILE_PROCESSING_ERROR': 'Failed to import the file.',
            'FILE_ASSEMBLY_ERROR': 'This .step file contains an assembly. We are currently not supporting the import of assemblies. Please resave your file as a part and try again.'
        };
        if (pError && pError in ERORRS) {
            aMsg = ERORRS[pError];
        }

        if (pError == 'FILE_ASSEMBLY_ERROR') {
            Popup.instance.open({
                text: aMsg,
                yesBtn: { callback: () => { }, title: "Got it!" }
            })
        } else {
            Popup.instance.open({
                text: aMsg,
            })
        }

    }
    //__________________________________________________________________________________________
    public convertStepToOptix(pData: { optix_file_name: string, name: string, stepFile: File, partType?: eCadType, quality?: iCadResolution, adminUpload?: boolean }, pStepCallback: Function): Promise<iAsyncCallback<File>> {
        return new Promise<iAsyncCallback<any>>((resolve) => {
            const aToken = ServerContext.tokens.access_token;
            pStepCallback(eJobMessageType.UPLOAD, eCadCallbackReason.SPINNER);

            let eventHandler: ((pCustomEvent: CustomEvent<any>) => void) | undefined = undefined;
            eventHandler = (pCustomEvent: CustomEvent) => {
                let aReceivedMessage = pCustomEvent.detail;
                if (aReceivedMessage.status === eJobStatus.ERROR) {
                    pStepCallback('remove', eCadCallbackReason.SPINNER);
                    this._errorNotification(aReceivedMessage.error)
                    if (eventHandler !== undefined) {
                        this.mWebSocketEvent.removeEventListener(eCadEvents.CLIENT_EVENT, eventHandler);
                    }
                    resolve({ data: null, success: false });
                    return;
                }
                switch (aReceivedMessage.type) {
                    case eJobMessageType.CREATE:
                        Op3dContext.USER_VO.isEmployeeUser && console.log('%cCREATE!', 'background-color: cyan');
                        ServerProxy.httpPutFile(pData.stepFile, aReceivedMessage.data.job.url,
                            (pErr) => {
                                Op3dContext.USER_VO.isEmployeeUser && console.log(`%#1`, 'background-color: cyan');
                                if (pErr) {

                                    pStepCallback('remove', eCadCallbackReason.SPINNER);
                                    Op3dContext.USER_VO.isEmployeeUser && console.log(pErr);
                                    if (eventHandler !== undefined) {
                                        this.mWebSocketEvent.removeEventListener(eCadEvents.CLIENT_EVENT, eventHandler);
                                    }
                                    resolve({ data: null, success: false });
                                    return;
                                } else {
                                    let aIsOpticalPart = pData.partType == eCadType.OPTICS ? true : false;
                                    let aJobSubmitMessage = {
                                        type: eJobMessageType.SUBMIT,
                                        token: aToken,
                                        data: {
                                            job: {
                                                fileName: pData.name,
                                                id: aReceivedMessage.data.job.id,
                                                settings: pData.quality,
                                                isOpticalPart: aIsOpticalPart,
                                                adminUpload: pData.adminUpload
                                            }
                                        }
                                    }
                                    ServerProxy.webSocketEmit(this.mWebSocket, eCadEvents.SERVER_EVENT, aJobSubmitMessage);
                                }
                            });
                        break;
                    case eJobMessageType.SUBMIT:
                        Op3dContext.USER_VO.isEmployeeUser && console.log(`%SUBMIT`, 'background-color: cyan');
                        // console.log('job submited, file loaded');
                        pStepCallback('remove', eCadCallbackReason.SPINNER);
                        pStepCallback(eJobMessageType.SUBMIT, eCadCallbackReason.SPINNER);
                        break;
                    case eJobMessageType.PROGRESS:
                        Op3dContext.USER_VO.isEmployeeUser && console.log(`%PROGRESS`, 'background-color: cyan');
                        pStepCallback('remove', eCadCallbackReason.SPINNER);
                        let aProgress = JSON.parse(aReceivedMessage.data);
                        pStepCallback(eJobMessageType.PROGRESS, eCadCallbackReason.SPINNER, aProgress);
                        break;
                    case eJobMessageType.PROCCESED:
                        Op3dContext.USER_VO.isEmployeeUser && console.log(`%PROCCESED`, 'background-color: cyan');
                        pStepCallback('remove', eCadCallbackReason.SPINNER);
                        try {
                            if (eventHandler !== undefined) {
                                this.mWebSocketEvent.removeEventListener(eCadEvents.CLIENT_EVENT, eventHandler);
                            }
                            let aUrls = JSON.parse(aReceivedMessage.data);
                            if (aUrls.locations.length != 1) {
                                resolve({ data: null, success: false });
                                return;
                            } else {
                                resolve({ data: aUrls, success: true });
                            }
                        } catch (e) {
                            this._errorNotification(aReceivedMessage.error)
                            if (eventHandler !== undefined) {
                                this.mWebSocketEvent.removeEventListener(eCadEvents.CLIENT_EVENT, eventHandler);
                            }
                            resolve({ data: null, success: false });
                            return;
                        }
                        break;
                    default:
                        break;
                }
            };
            this.mWebSocketEvent.addEventListener(eCadEvents.CLIENT_EVENT, eventHandler);
            let aJobCreateMessage = {
                type: eJobMessageType.CREATE,
                token: aToken,
                data: {
                    job: {
                        fileName: pData.stepFile.name,
                    }
                }
            }
            ServerProxy.webSocketEmit(this.mWebSocket, eCadEvents.SERVER_EVENT, aJobCreateMessage);
            Op3dContext.USER_VO.isEmployeeUser && console.log(`%websocket error`, 'background-color: cyan');
            Op3dContext.USER_VO.isEmployeeUser && console.log(`%Websocket closed!`, 'background-color: cyan');
        });
    }
    //__________________________________________________________________________________________
    public addReview(pData: { content: string, session: string }) {
        const aURL = `${ServerContext.backend_url}/chat/api/v1/review`;
        return this._sendRequest<{ status: string, data: string }>({
            method: "post",
            url: aURL,
            data: pData
        })
    }
    //__________________________________________________________________________________________
    public addMessage(pData: iOptiChatAddMessage) {
        const aURL = `${ServerContext.backend_url}/chat/api/v1/message`;
        return this._sendRequest<{
            message: string,
            status: string,
            data: {
                elastic: Array<iChatBotElasticResponse>,
                message_to_user: { content: string, role: string }
            }
        }>({
            method: "post",
            url: aURL,
            data: pData
        })
    }
    //__________________________________________________________________________________________
    public getManyMaterials() {
        const aURL = `${ServerContext.backend_url}/api/material/materials/public`;
        return this._sendRequest<Array<iMaterialMetaDataVO>>({
            method: "get",
            url: aURL
        });
    }
    //__________________________________________________________________________________________
    public async httpGetFile(pFileUrl: string): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            ServerProxy.httpGetFile(pFileUrl, (pErr, pFile) => {
                if (pErr != null) {
                    Op3dContext.USER_VO.isEmployeeUser && console.log(pErr);
                    reject(pErr);

                } else if (pFile != null) {
                    resolve(pFile.arrayBuffer());
                }
            });
        });
    }
    //__________________________________________________________________________________________
    private async _getSimulationFiles(pPayloadObj: iSimulationResultPayload) {
        if (Op3dContext.isWidget && Op3dContext.isWidgetAnalysis) {
            pPayloadObj.analysis[0].url = pPayloadObj.analysis[0].url.replace(pPayloadObj.analysis[0].name, 'analysis_0.bin.zip');
        }
        if (pPayloadObj.analysis && pPayloadObj.analysis.length) {
            for (let i = 0; i < pPayloadObj.analysis.length; i++) {
                let aContent = await this.httpGetFile(pPayloadObj.analysis[i].url);
                pPayloadObj.analysis[i].content = aContent;
            }
        }

        return pPayloadObj;
    }
    //__________________________________________________________________________________________
    _connectToWebSocket(pToken: string): Promise<Socket> {
        return new Promise((resolve, reject) => {

            let aWebSocket = io(ServerContext.backend_url, { transports: ["websocket"], query: { "token": pToken } });

            aWebSocket.on("connect", () => {
                Op3dContext.USER_VO.isEmployeeUser && console.log('socket connected')

                resolve(aWebSocket);
            });
            aWebSocket.on("connect_error", () => {
                reject(new Error(`Connection failed to ${ServerContext.backend_url}`));
            });
        });
    }
    //__________________________________________________________________________________________
    public async connectToWebSocket() {
        if (!this.mWebSocket) {
            try {
                //const aToken = server.ServerContext.accessToken
                const aToken = ServerContext.tokens.access_token;
                this.mWebSocket = await this._connectToWebSocket(aToken);
                ServerProxy.webSocketAddEventListener(this.mWebSocket, eSimulationEvents.CLIENT_GENERAL_EVENT,
                    (pEvent: iEventData<eGeneralWebsocketType>) => WebsocketEventsHandler.handleWebsocketEvent(pEvent));

                this.mWebSocketEvent = new EventTarget();
                ServerProxy.webSocketAddEventListener(this.mWebSocket, eSimulationEvents.CLIENT_EVENT,
                    (pEvent: iEventData<eSimulationWebsocketEventType>) => {
                        this.mWebSocketEvent.dispatchEvent(new CustomEvent(eSimulationEvents.CLIENT_EVENT, { detail: pEvent }));
                    });
                ServerProxy.webSocketAddEventListener(this.mWebSocket, eCadEvents.CLIENT_EVENT,
                    (pEvent: iEventData<eSimulationWebsocketEventType>) => {
                        this.mWebSocketEvent.dispatchEvent(new CustomEvent(eCadEvents.CLIENT_EVENT, { detail: pEvent }));
                    });
            } catch (e) {
                Op3dContext.USER_VO.isEmployeeUser && console.log(`${e}`);
            }
        }
    }
    //__________________________________________________________________________________________
    public runSimulation(pData: iSimulationPayload, signal?: any, pOnProgressCallback?: Function,
        pRetrieveFromS3?: iNameURL): Promise<iAsyncCallback<iClientSimulationPayload>> {

        if (signal?.aborted) {
            return Promise.reject(new DOMException("Terminated", "AbortError"));
        }

        if (false === Op3dContext.USER_VO.userCanRunSimulation_GPULimit) {
            let aRes: iClientSimulationPayload = {
                result: {
                    error: {
                        code: 4,
                        message: "USAGE_LIMIT_EXCEEDED"
                    },
                    data: {}
                },
                maps_url: ''
            };

            Popup.instance.open({ text: 'GPU usage limit exceeded' });
            return Promise.resolve({ success: false, data: aRes });
        }

        return new Promise<iAsyncCallback<any>>((resolve, reject) => {
            let timeout: NodeJS.Timeout;
            let eventHandler: ((pCustomEvent: CustomEvent<any>) => void) | undefined = undefined;
            let aCtx = {
                currentID: Op3dUtils.idGenerator(),
                terminated: false
            }
            signal?.addEventListener("abort", () => {
                aCtx.terminated = true;
                clearTimeout(timeout);
                if (eventHandler !== undefined) {
                    this.mWebSocketEvent.removeEventListener(eSimulationEvents.CLIENT_EVENT, eventHandler);
                }
                let stopEvent = {
                    type: eSimulationWebsocketEventType.SERVER_STOP,
                    id: aCtx.currentID,
                    payload: {},
                };
                Op3dContext.USER_VO.isEmployeeUser && console.log(`Abort simulation ${aCtx.currentID}`);
                ServerProxy.webSocketEmit(this.mWebSocket, eSimulationEvents.SERVER_EVENT, stopEvent);
                reject(new DOMException("Terminated", "AbortError"));
            });
            eventHandler = (pCustomEvent: CustomEvent) => {
                let event = pCustomEvent.detail;
                if (event.id !== aCtx.currentID) {
                    return;
                }
                if (aCtx.terminated === true) {
                    return;
                }
                if (pRetrieveFromS3) {
                    event.type = eSimulationWebsocketEventType.CLIENT_DONE;
                }
                switch (event.type) {
                    case eSimulationWebsocketEventType.CLIENT_READY:
                        pOnProgressCallback ? pOnProgressCallback(Strings.SPINNER_TEXT_PROCESSING,
                            () => SimulationRunner.instance.onStopVisualization()) : null;
                        aCtx.currentID = event.payload.id;
                        let startEvent = {
                            type: eSimulationWebsocketEventType.SERVER_START,
                            id: aCtx.currentID,
                            payload: pData
                        };
                        ServerProxy.webSocketEmit(this.mWebSocket, eSimulationEvents.SERVER_EVENT, startEvent);
                        break;
                    case eSimulationWebsocketEventType.CLIENT_DONE:
                        this.mWebSocketEvent.removeEventListener(eSimulationEvents.CLIENT_EVENT, eventHandler);
                        try {
                            if (pRetrieveFromS3 && pRetrieveFromS3.name && pRetrieveFromS3.url) {
                                const aPayload: iSimulationResultPayload = {
                                    analysis: [
                                        {
                                            "name": pRetrieveFromS3.name,
                                            "url": pRetrieveFromS3.url,
                                        }
                                    ],
                                    report: [],
                                    messages: "",
                                    time: {
                                        job: 0,
                                        snellius: 0
                                    }
                                };

                                const aPayloadToSim = JSON.stringify(aPayload) as any
                                this._getSimulationFiles(aPayloadToSim).then((payload) => {
                                    resolve({ success: true, data: payload });
                                }).catch((e) => {
                                    Op3dContext.USER_VO.isEmployeeUser && console.log(e);
                                    resolve({ success: false, data: pRetrieveFromS3 });
                                });
                            } else {
                                resolve({ success: true, data: JSON.parse(event.payload) });
                            }
                            break;
                        } catch (e) {
                            Op3dContext.USER_VO.isEmployeeUser && console.log(e)
                        }
                        break
                    case eSimulationWebsocketEventType.CLIENT_ERROR:
                        Op3dContext.USER_VO.isEmployeeUser && console.log('Simulation error!');
                        try {
                            if (event.payload.error.message == 'USAGE_LIMIT_EXCEEDED') {
                                Op3dContext.USER_VO.disableTransactionTime();
                            }
                        } catch (e) { }
                        this.mWebSocketEvent.removeEventListener(eSimulationEvents.CLIENT_EVENT, eventHandler);
                        resolve({ success: false, data: event.payload });
                        break;
                    case eSimulationWebsocketEventType.CLIENT_SETUP_VERSION_ERROR:
                        resolve({ success: false, data: { isVersionError: true } });
                        break;
                    case eSimulationWebsocketEventType.SERVER_PROGRESS:
                        if (pOnProgressCallback) {
                            if (event.payload.progress == 'job_end') {
                                pOnProgressCallback('Drawing...', () => SimulationRunner.instance.onStopVisualization());
                            } else {
                                pOnProgressCallback(event.payload.progress, () => SimulationRunner.instance.onStopVisualization());
                            }
                        }
                        break;
                    case eSimulationWebsocketEventType.CLIENT_PROGRESS:
                        Op3dContext.USER_VO.isEmployeeUser && console.log('CLIENT_PROGRESS event');
                        break;
                    default:
                        Op3dContext.USER_VO.isEmployeeUser && console.log('Unknown event');
                        this.mWebSocketEvent.removeEventListener(eSimulationEvents.CLIENT_EVENT, eventHandler);
                        resolve({ success: false, data: event.type });
                        break;
                }
            }
            this.mWebSocketEvent.addEventListener(eSimulationEvents.CLIENT_EVENT, eventHandler);
            timeout = setTimeout(() => {
                let initEvent = {
                    id: aCtx.currentID,
                    type: eSimulationWebsocketEventType.SERVER_INIT,
                    payload: {},
                };
                ServerProxy.webSocketEmit(this.mWebSocket, eSimulationEvents.SERVER_EVENT, initEvent);
            }, 2000);
        });
    }
    //__________________________________________________________________________________________
    public getManySetups<T>(pData: iGetManySetupsQuery) {
        let aURL = ServerContext.backend_url + "/api/setup/get_many";
        return new Promise<iAsyncCallback<T>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: T) => resolve({ data: pData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public removeOneSetup(pData: { _id: string }) {
        let aURL = ServerContext.backend_url + "/api/setup/remove";
        return new Promise<iAsyncCallback<null>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                () => resolve({ data: null, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public uploadImage(pFile: File, pData: { file_name: string, url: string }) {
        const aURL = ServerContext.backend_url + "/api/ingredient/file";
        let aFormData = new FormData();
        aFormData.append("data", JSON.stringify(pData));
        aFormData.append("file", pFile, pData.file_name);

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_MULTIPART(aURL, aFormData,
                (pData: { data: string, status: string }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }

    //__________________________________________________________________________________________
    private _uploadOptic(pData: iOpticsVO) {
        const aURL = ServerContext.backend_url + "/api/ingredient/optic";
        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: string, status: string }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    private _addAperture(pData: iOpticsVO): Promise<iAsyncCallback<string>> {

        return new Promise<iAsyncCallback<string>>((resolve) => {

            let aURL = ServerContext.backend_url + "/api/ingredient/aperture";
            ServerProxy.ajaxPOST_JSON(
                aURL,
                pData,
                (pData: { data: string, status: string }) => { resolve({ data: pData.data, success: true }) },
                (pData: { data: string, status: string }) => { resolve({ data: pData.data, success: false }) }
            );
        });
    }
    //__________________________________________________________________________________________
    private async _uploadApertureElements(pOptions: { data: iOpticsVO, attachments: iOpticAttachments }) {

        const aId = Op3dUtils.idGenerator();
        const aMasks = pOptions.attachments.masks;
        const aBaseURL = `${ServerContext.user_prefix_aperture_images}${Op3dContext.USER_VO.id}`;

        if (pOptions.data.parameters.physical_data == null) {
            pOptions.data.parameters.physical_data = {}
        }

        if (aMasks.transmittance != null) {

            let aName = `${aId}_amp_${aMasks.transmittance.name}`;
            let aRes = await this.uploadImage(aMasks.transmittance, {
                url: aBaseURL,
                file_name: aName
            });

            if (aRes.success == false) {
                return aRes;
            }

            pOptions.data.parameters.physical_data.transmittance_mask = {
                name: aMasks.transmittance.name,
                url: `${aBaseURL}/${aName}`
            }
        }

        if (aMasks.phase != null) {

            let aName = `${aId}_phs_${aMasks.phase.name}`;

            let aRes = await this.uploadImage(aMasks.phase, {
                url: aBaseURL,
                file_name: aName
            });

            if (aRes.success == false) {
                return aRes;
            }

            pOptions.data.parameters.physical_data.phase_mask = {
                name: aMasks.phase.name,
                url: `${aBaseURL}/${aName}`
            }
        }

        return this._addAperture(pOptions.data);
    }
    //__________________________________________________________________________________________
    private async _uploadOpticElements(pData: { data: iOpticsVO, attachments: iOpticAttachments }) {
        for (let key in pData.attachments.coating) {
            let aRes = await this.addCoating({
                toReplace: false, data: pData.attachments.coating[key],
                permission: eDataPermission.PRIVATE
            });
            if (aRes.success == false) {
                return aRes;
            }
        }

        return this._uploadOptic(pData.data);
    }
    //__________________________________________________________________________________________
    public async uploadOpticalElement(pData: { data: iOpticsVO, attachments: iOpticAttachments }) {

        switch (pData.data.parameters.subType) {
            case OpticsContext._User_Aperture:
                return await this._uploadApertureElements(pData);

            default:
                return await this._uploadOpticElements(pData);
        }
    }
    //__________________________________________________________________________________________
    public getLightSourceBrands() {
        const aURL = ServerContext.backend_url + "/api/ingredient/light_source/brands";
        return this._sendRequest<Array<string>>({ method: "get", url: aURL });
    }
    //__________________________________________________________________________________________
    public getOpticBrands() {
        const aURL = ServerContext.backend_url + "/api/ingredient/optic/brands";
        return this._sendRequest<Array<string>>({ method: "get", url: aURL });
    }

    //__________________________________________________________________________________________
    public getOneSetup(pData: iGetOneSetup): Promise<iAsyncCallback<iDownloadFullSetupData>> {
        const aOpen = pData.to_open != null ? pData.to_open : true;
        let aURL = `${ServerContext.backend_url}/api/setup/${pData._id}`;
        return this._sendRequest<iDownloadFullSetupData>({
            method: "get", url: aURL, params: {
                open: aOpen,
                session: pData.session
            }
        });
    }
    //__________________________________________________________________________________________
    public getOneIngredient<T>(pQuery: iIngredientQuery): Promise<iAsyncCallback<T>> {
        let aNumberID = pQuery.number_id;
        if (aNumberID.indexOf("/")) {
            aNumberID = aNumberID.replace("/", "%2F");
        }
        const aType = pQuery.type === eIngredientType.OPTIC ? "optic" : "light_source";
        const aURL = `${ServerContext.backend_url}/api/ingredient/${aType}/${aNumberID}`;
        return this._sendRequest<T>({ method: "get", url: aURL });
    }
    //__________________________________________________________________________________________
    public updateSetup(pData: iUploadSetupData) {
        const aURL = ServerContext.backend_url + "/api/setup/update";

        if (pData._id == null && ServerContext.env !== ENVS.PROD) {
            console.log('%csetup with no ID!', 'background-color: yellow');
        }

        return this._sendRequest<string>({ method: "post", url: aURL, data: pData });
    }
    //__________________________________________________________________________________________
    public closeSetup(pData: { _id: string }) {
        // !pBeforeUnload && await Op3dContext.sleep(100)

        if (pData._id == null) {
            return;
        }

        return new Promise<iAsyncCallback<string>>((resolve) => {
            let aURL = ServerContext.backend_url + "/api/setup/close";
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { id: string }) => resolve({ data: pData.id, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public isMaterialExists(pData: { numberID: string; }) {
        let aURL = ServerContext.backend_url + "/api/material/is_exist";
        return new Promise<iAsyncCallback<boolean>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: boolean }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public updatePartParameters(pData: { partData: any; }) {
        let aURL = ServerContext.backend_url + "/api/part/update_parameters";
        return new Promise<iAsyncCallback<boolean>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: boolean }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public isPartExists(pData: { tableID: string; }) {
        let aURL = ServerContext.backend_url + "/api/part/is_part_exists";
        return this._sendRequest<any>({ method: "get", url: aURL, params: pData });
    }
    //__________________________________________________________________________________________
    private _sendRequest<T>(pOptions: { method: "post" | "delete" | "get", url: string, data?: any, params?: Record<string, any> }) {
        return new Promise<iAsyncCallback<T>>(resolve => {

            let config: AxiosRequestConfig = {
                method: pOptions.method,
                maxBodyLength: Infinity,
                url: pOptions.url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ServerContext.tokens.access_token}`
                },
                data: pOptions.data || undefined,
                params: pOptions.params || undefined,
            };

            if (pOptions.data !== undefined) {
                config.data = pOptions.data;
            }

            axios.request(config)
                .then((response: AxiosResponse) => {
                    resolve({ success: true, data: response.data.data })
                })
                .catch((error) => {
                    resolve({ success: false, data: error })
                });
        })
    }
    //__________________________________________________________________________________________
    public deleteIngredient(pData: { numberID: string; ingredient: eIngredientType }) {
        const aType = pData.ingredient === eIngredientType.OPTIC ? "optic" : "light_source";
        const aURL = `${ServerContext.backend_url}/api/ingredient/${aType}/${pData.numberID}`;
        return this._sendRequest<boolean>({ method: "delete", url: aURL });
    }
    //__________________________________________________________________________________________
    public isIngredientExists(pData: { numberID: string; ingredient: eIngredientType }) {
        const aType = pData.ingredient === eIngredientType.OPTIC ? "optic" : "light_source";
        const aURL = `${ServerContext.backend_url}/api/ingredient/${aType}/exists/${pData.numberID}`;
        return this._sendRequest<boolean>({ method: "get", url: aURL });
    }
    //__________________________________________________________________________________________
    public getCoatingNumberIDByName(pData: { name: string; }): Promise<iAsyncCallback<string>> {
        let aURL = ServerContext.backend_url + "/api/coating/get_number_id_by_name";

        let aData = {
            name: pData.name,
            permission: eDataPermission.PUBLIC
        };

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, aData,
                (pData: { data: string }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public isCoatingExists(pData: { number_id: string }): Promise<iAsyncCallback<boolean>> {
        let aURL = ServerContext.backend_url + "/api/coating/is_exists";
        return new Promise<iAsyncCallback<boolean>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: boolean }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public getMaterialsMetaData(pData: iServerMetaDataQuery): Promise<iAsyncCallback<Array<iMaterialVO>>> {
        let aURL = ServerContext.backend_url + "/api/material/get_many";

        return new Promise<iAsyncCallback<Array<iMaterialVO>>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: Array<iMaterialVO> }) => resolve({
                    success: true,
                    data: pData.data
                }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public getWeightByWl(pData: { number_id: string, wl: number }) {
        let aURL = ServerContext.backend_url + "/api/light_source/get_wl_weight";

        return new Promise<iAsyncCallback<number>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: number }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public uploadLightSourceFile(pLightSourceFile: File): Promise<iAsyncCallback<any>> {
        let aURL = ServerContext.backend_url + "/api/light_source/get_s3_upload_url";
        return new Promise<iAsyncCallback<any>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, { filename: pLightSourceFile.name },
                (pData: { url: string }) => {
                    ServerProxy.httpPutFile(pLightSourceFile, pData.url,
                        (pErr) => {
                            if (pErr) {
                                Op3dContext.USER_VO.isEmployeeUser && console.log(pErr);
                                resolve({ data: null, success: false });
                            }
                            resolve({ success: true, data: null });
                        });
                },
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public getParts() {
        let aURL = ServerContext.backend_url + "/api/part/get_all";

        return new Promise<iAsyncCallback<Array<iPartMongoDB>>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, {},
                (pData: { data: Array<iPartMongoDB> }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public getPartById(pData: { number_id: string }) {
        let aURL = ServerContext.backend_url + "/api/part/get_part";

        return new Promise<iAsyncCallback<iPartMongoDB>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: iPartMongoDB }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public getPrivatePartById(pBody: { id: string }) {
        let aURL = ServerContext.backend_url + "/api/part/get_part_by_id";

        return new Promise<iAsyncCallback<any>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pBody,
                (pData: { data: iPartMongoDB }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public isRaysFileExists(pBody: { _id: string }): Promise<iAsyncCallback<boolean>> {
        let aURL = ServerContext.backend_url + "/api/setup/has_rays_file";

        return this._sendRequest({ method: "post", url: aURL, data: pBody });


        // return new Promise<iAsyncCallback<boolean>>((resolve) => {
        //     ServerProxy.ajaxPOST_JSON(aURL, pBody,
        //         (pData: { data: boolean }) => resolve({
        //             success: true,
        //             data: pData.data
        //         }),
        //         (pErr: any) => resolve({ success: false, data: pErr }));
        // });
    }
    //__________________________________________________________________________________________
    public isExistsAnalysisResult(pParams: { setup_id: string, analysis_id: string }): Promise<iAsyncCallback<boolean>> {
        let aURL = `${ServerContext.backend_url}/api/setup/exists_analysis_results`;
        return this._sendRequest<boolean>({
            method: "get", url: aURL,
            params: pParams
        });
    }
    //__________________________________________________________________________________________
    public getPartsInfo() {
        let aURL = ServerContext.backend_url + "/api/part/get_all_info";

        return new Promise<iAsyncCallback<Array<iServerPartVO>>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, {},
                (pData: { data: Array<iServerPartVO> }) => resolve({
                    success: true, data: pData.data
                }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public addPart(pData: iPartMongoDB) {
        let aURL = ServerContext.backend_url + "/api/part/add";

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { _id: string }) => resolve({ data: pData._id, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public changePartDetails(pData: iServerPartDetails): Promise<iAsyncCallback<string>> {
        let aURL = ServerContext.backend_url + "/api/part/updatePartInfo";

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pRetData: { _id: string }) => resolve({ data: pRetData._id, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public deletePart(pData: { number_id: string }) {
        let aURL = ServerContext.backend_url + "/api/part/delete";

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { number_id: string }) => resolve({ data: pData.number_id, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public copyPart(pData: { number_id: string, inAssembly: boolean }) {
        let aURL = ServerContext.backend_url + "/api/part/copy";

        return new Promise<iAsyncCallback<iPartMongoDB>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: iPartMongoDB }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public copyAssembly(pData: { number_id: string }) {
        let aURL = ServerContext.backend_url + "/api/part/copy_assembly";

        return new Promise<iAsyncCallback<iPartMongoDB>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: iPartMongoDB }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public addChangesToPart(pData: { changes: string, number_id: string }) {
        let aURL = ServerContext.backend_url + "/api/part/add_changes";

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { changes: string, number_id: string }) => resolve({ data: pData.changes, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public getFullMaterial(pData: { number_id: string }): Promise<iAsyncCallback<iMaterialVO>> {
        let aURL = `${ServerContext.backend_url}/api/material/${pData.number_id}`;
        return this._sendRequest({ method: "get", url: aURL });
    }
    //__________________________________________________________________________________________
    public getApplicationFeatureConfig(): Promise<iAsyncCallback<iAppFeaturesConfig>> {
        let aURL = ServerContext.backend_url + "/config/api/v1/config";
        return this._sendRequest({ method: "get", url: aURL });
    }
    //__________________________________________________________________________________________
    public getFullCoating(pCoatingData: { number_id: string }): Promise<iAsyncCallback<iCoatingVO>> {
        let aURL = ServerContext.backend_url + "/api/coating/get_one";
        return new Promise((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pCoatingData,
                (pData: iCoatingVO) => resolve({ success: true, data: pData }),
                (err: any) => resolve({ success: false, data: err }));
        });
    }
    //__________________________________________________________________________________________
    public addOpticalDataToPart(pData: { opticalData: iCadOpticalData, number_id: string }) {
        let aURL = ServerContext.backend_url + "/api/part/add_optical_data";
        const aOptData = JSON.stringify(pData.opticalData)
        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                () => resolve({ data: aOptData, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public addPartToAssembly(pData: { number_id: string, part: any }) {
        let aURL = ServerContext.backend_url + "/api/part/add_to_assembly";

        return new Promise<iAsyncCallback<iPartMongoDB>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: iPartMongoDB }) => resolve({ data: pData.data, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }
    //__________________________________________________________________________________________
    public deleteOpticsByQuery(pData: {
        type: string, subtype: string,
        ingredientType: eIngredientType, password: string
    }): Promise<iAsyncCallback<number>> {

        let aURL = ServerContext.backend_url + "/api/ingredient/admin/delete_by_query";

        return new Promise<iAsyncCallback<number>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: number) => resolve({ success: true, data: pData }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public getGeoFolderSize(pData: iOpticsVO) {
        let aURL = ServerContext.backend_url + "/import-part/get_geo_folder_size";

        return new Promise<iAsyncCallback<number>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: number) => resolve({ success: true, data: pData }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public deleteCADPart(pBody: { id: string, number_id: string, prefix?: string }) {
        let aURL = ServerContext.backend_url + "/import-part/delete_part_from_user";

        return new Promise<iAsyncCallback<number>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pBody,
                (pData: { data: number }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public deleteAdminPart(pBody: { id: string, number_id: string }) {
        let aURL = ServerContext.backend_url + "/import-part/delete_admin_part";

        return this._sendRequest({ method: "delete", url: aURL, params: pBody });
    }
    //__________________________________________________________________________________________
    public uploadImageOfPart<T>(pId: string, pImageFileContent: Blob, pImageFileExt: string) {
        let aURL = ServerContext.backend_url + "/import-part/add_image_to_user";

        return new Promise<iAsyncCallback<T>>((resolve) => {
            let aFormData = new FormData();
            aFormData.append("files", pImageFileContent, pId + '.' + pImageFileExt);

            ServerProxy.ajaxPOST_MULTIPART(
                aURL,
                aFormData,
                () => { resolve({ data: null, success: true }) },
                () => { resolve({ data: null, success: false }) }
            );
        });
    }
    //__________________________________________________________________________________________
    public uploadOptixPart<T>(pName: string, pFileContent: Blob) {
        let aURL = ServerContext.backend_url + "/import-part/add_optix_part_to_user";

        return new Promise<iAsyncCallback<T>>((resolve) => {
            let aFormData = new FormData();
            //aFormData.append("files", pModelFileContent, pId + '.json');
            aFormData.append("files", pFileContent, pName + '.optix');

            ServerProxy.ajaxPOST_MULTIPART(
                aURL,
                aFormData,
                (aData) => { resolve({ data: aData, success: true }) },
                () => { resolve({ data: null, success: false }) }
            );
        });
    }
    //__________________________________________________________________________________________
    public uploadPartImage<T>(pId: string, pImageFileContent: Blob, pImageFileExt: string) {
        let aURL = ServerContext.backend_url + "/import-part/add_image";

        return new Promise<iAsyncCallback<T>>((resolve) => {
            let aFormData = new FormData();
            //aFormData.append("files", pModelFileContent, pId + '.json');
            aFormData.append("files", pImageFileContent, pId + '.' + pImageFileExt);

            ServerProxy.ajaxPOST_MULTIPART(
                aURL,
                aFormData,
                () => { resolve({ data: null, success: true }) },
                () => { resolve({ data: null, success: false }) }
            );
        });
    }
    //__________________________________________________________________________________________
    public uploadPart<T>(pId: string, pDataFileContent: string, pModelFileContent: Blob,
        pImageFileContent: Blob, pImageFileExt: string) {
        let aURL = ServerContext.backend_url + "/import-part/add_part";

        return new Promise<iAsyncCallback<T>>((resolve) => {
            let aFormData = new FormData();
            aFormData.append("files", new Blob([pDataFileContent]), pId + '.json');
            aFormData.append("files", pModelFileContent, pId + '.zip');
            //aFormData.append("files", pModelFileContent, pId + '.json');
            aFormData.append("files", pImageFileContent, pId + '.' + pImageFileExt);

            ServerProxy.ajaxPOST_MULTIPART(
                aURL,
                aFormData,
                () => { resolve({ data: null, success: true }) },
                () => { resolve({ data: null, success: false }) }
            );
        });
    }
    //__________________________________________________________________________________________
    public updateSource(pData: iSourceVO) {
        let aURL = ServerContext.backend_url + "/api/ingredient/admin/source";

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: string) => resolve({ success: true, data: pData }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public async getManyOpticalElements(pQuery: iElasticQuery) {
        let aURL = ServerContext.backend_url + "/elastic-search/api/v1/search";

        return this._sendRequest({ method: "post", url: aURL, data: pQuery });
    }
    //__________________________________________________________________________________________
    public async getManyPartsElements(pQuery: iElasticQuery) {
        let aURL = ServerContext.backend_url + "/elastic-search/api/v1/search_parts";

        return this._sendRequest({ method: "post", url: aURL, data: pQuery });
    }
    //__________________________________________________________________________________________
    public async getElasticOpticsSuggestions(pQuery: iElasticQuery) {
        const aURL = ServerContext.backend_url + "/elastic-search/api/v1/suggestions";
        return this._sendRequest({ method: "post", url: aURL, data: pQuery });
    }
    //__________________________________________________________________________________________
    public async getElasticPartsSuggestions(pQuery: iElasticQuery) {
        let aURL = ServerContext.backend_url + "/elastic-search/api/v1/suggestions_parts";
        // return new Promise<iAsyncCallback<{ meta: any, results: { documents: Array<string> } }>>((resolve) => {
        //     ServerProxy.ajaxPOST_JSON(aURL, pQuery,
        //         (pData: any) => resolve({ success: true, data: pData.data }),
        //         (pErr: any) => resolve({ success: false, data: pErr }));
        // });
        return this._sendRequest({ method: "post", url: aURL, data: pQuery });
    }

    //__________________________________________________________________________________________
    public updateOpticAdmin(pData: iOpticsVO) {
        let aURL = ServerContext.backend_url + "/api/ingredient/admin/optic";

        (pData as any).toReplace = true;

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: string) => resolve({ success: true, data: pData }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public addMaterial(pData: iMaterialVO): Promise<iAsyncCallback<string>> {
        let aURL = `${ServerContext.backend_url}/api/material/add`;

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { status: string, data: string }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public addCoating(pData: { toReplace: boolean, data: iCoatingVO, permission: eDataPermission }): Promise<iAsyncCallback<string>> {
        let aURL = ServerContext.backend_url + "/api/coating/add";

        return new Promise<iAsyncCallback<string>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: string }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public searchFilter(pPath: string, pData: any) {
        let aURL = ServerContext.backend_url + "/api/search/";
        aURL += pPath;

        return new Promise<iAsyncCallback<any>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: any }) => resolve({ success: true, data: pData }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public getFilterFinderData(pData: { names: Array<string> }): Promise<iAsyncCallback<any>> {
        let aURL = ServerContext.backend_url + "/api/ingredient/get_filter_finder_data";
        return new Promise<iAsyncCallback<any>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: any }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________
    public removeOneAnalysis(pData: { setup_id: string, analysis_id: string }): Promise<iAsyncCallback<null>> {
        let aURL = ServerContext.backend_url + "/api/setup/remove_analysis";
        return new Promise<iAsyncCallback<null>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                () => resolve({ data: null, success: true }),
                (pErr: any) => resolve({ data: pErr, success: false }));
        });
    }

    //__________________________________________________________________________________________
    public editUserData(pData: iUserVO): Promise<iAsyncCallback<iFullUserData>> {
        let aURL = ServerContext.backend_url +
            "/api/user/edit_settings";

        return new Promise<iAsyncCallback<iUserVO>>((resolve) => {
            ServerProxy.ajaxPOST_JSON(aURL, pData,
                (pData: { data: iFullUserData }) => resolve({ success: true, data: pData.data }),
                (pErr: any) => resolve({ success: false, data: pErr }));
        });
    }
    //__________________________________________________________________________________________

}
