﻿import { Strings } from "../../../_context/Strings";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Part } from "../../../parts/Part";
import { iPart } from "../../../parts/PartInterfaces";
import { ViewUtils } from "../../ViewUtils";
import { EditFaceForm } from "./EditFaceForm";
import { OptomechanicsOpticsForm } from "./OptomechanicsOpticsForm";
import { OptomechanicsSurfaceManager } from "./OptomechanicsSurfaceManager";
import { uoSection } from "./uoSection";

export interface iuoSurfacesParams {
    optics: iPart;
    mouseenter: (pName: string) => void;
    mouseleave: () => void;
    part: Part;
}

export class uoSurfaces extends uoSection<iuoSurfacesParams> {

    private static SKIN_PATH = './skins/forms/optics/uo_surfaces_info.html';

    private mButton: HTMLElement;
    private mElementsParent: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoSurfaces.SKIN_PATH,
            title: 'Optical surfaces',
            isNewSkin: false,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mButton = Op3dUtils.getElementIn(this.mContainer, 'one_button');
        this.mElementsParent = this.mButton.parentElement;
        this.mElementsParent.removeChild(this.mButton);
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mElementsParent);
    }
    //__________________________________________________________________________________________
    protected async _setData(puoSurfacesParams: iuoSurfacesParams) {
        this._clear();

        let aOptics = puoSurfacesParams.optics;
        let aFaces = aOptics.shapes[0].solids[0].faces;
        for (let i = 0; i < aFaces.length; i++) {
            let aFace = aFaces[i];

            if ((null == aFace.data) || (Strings.ABSORBING == aFace.data.coatingID)) {
                continue;
            }

            let aBtn = this.mButton.cloneNode(true) as HTMLElement;
            Op3dUtils.getElementIn(aBtn, 'btn_name').innerHTML = aFace.name;
            aBtn.addEventListener('click', () => {
                // OpticsForm.instance.close();
                OptomechanicsOpticsForm.instance.close();
                EditFaceForm.instance.open({
                    face: aFace,
                    opticsPart: aOptics,
                    part: puoSurfacesParams.part
                });
            });

            aBtn.addEventListener('mouseenter', () =>
                puoSurfacesParams.mouseenter(aFace.originalName));
            aBtn.addEventListener('mouseleave', () => puoSurfacesParams.mouseleave());
            this.mElementsParent.appendChild(aBtn);
        }
    }
    //__________________________________________________________________________________________
    protected async _setOptomechanicsData(puoSurfacesParams: iuoSurfacesParams) {

        this._clear();

        let aiPart = puoSurfacesParams.optics;
        let aFaces = aiPart.subParts[0].shapes[0].solids[0].faces[0].indexes;
        for (let i = 0; i < aFaces.length; i++) {
            let aFace = aFaces[i];

            let aBtn = this.mButton.cloneNode(true) as HTMLElement;
            Op3dUtils.getElementIn(aBtn, 'btn_name').innerHTML = aFace.name;
            aBtn.addEventListener('click', () => {
                OptomechanicsOpticsForm.instance.close();
                OptomechanicsSurfaceManager.instance.open({
                    face: aFace,
                    opticsPart: aiPart,
                    part: puoSurfacesParams.part
                });
            });

            aBtn.addEventListener('mouseenter', () =>
                puoSurfacesParams.mouseenter(aFace.name)
            );
            aBtn.addEventListener('mouseleave', () => { }
            );

            this.mElementsParent.appendChild(aBtn);
        }
    }
    //__________________________________________________________________________________________
}
