import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { MathContext } from "../_context/MathContext";
import { iColor } from "../ui/_globals/uiInterfaces";
import { Spectrum } from "./Spectrum";
import { eLightSourceType, iSourceVO, iSourceVOSpectrumFile } from "../parser/SourcesParser";
import { eWavelengthDistributionType, iWavelenghtDistributionBlackbody, iWavelenghtDistributionData, iWavelenghtDistributionGaussian, iWavelenghtDistributionRange, iWavelengthSingleItem, tWavelenghtDistributionData } from "../parts/behaviors/LightSourceContext";
import { Color } from "three";

export class LightSourceUtils {

    private static _DEFAULT_WL_DATA: tWavelenghtDistributionData;

    private static LEGAL_TO: number = 10000;

    private static H = 6.62607015 * 1E-34;  //Planck constant
    private static C = 2.998 * 1E+8;    //Speed of light
    private static KB = 1.38065 * 1E-23; //Boltzmann constant
    private static HC = LightSourceUtils.H * LightSourceUtils.C;
    private static PI_HC_8 = (8 * Math.PI * LightSourceUtils.HC);

    private static B = 2.897771955 * 0.001;//
    //LightSourceUtils.H * LightSourceUtils.C /
    // LightSourceUtils.KB * 1 /
    // (5 + LightSourceUtils.W * (-5E-5));

    private static M_TO_NM = Math.pow(10, 9);
    private static LEGAL_FROM_BLACKBODY: number = 150;
    private static LEGAL_TO_BLACKBODY: number = 12000;
    private static MIN_VISIBLE_LAMBDA = 400;
    private static MAX_VISIBLE_LAMBDA = 700;

    public static getColor(pSourceVO: iSourceVO) {
        let aSpectrum: Array<number> = this.getSpectrum(pSourceVO);
        let aWavelengths = [pSourceVO.parameters.data.center_wavelength];
        let aSpectrumSmall = [aSpectrum[pSourceVO.parameters.data.center_wavelength]];

        return this.getColor2(aWavelengths, aSpectrumSmall);
    }
    //__________________________________________________________________________________________
    public static get DEFAULT_WL_DATA() {
        if (undefined === LightSourceUtils._DEFAULT_WL_DATA) {
            LightSourceUtils._DEFAULT_WL_DATA = {
                gauss_center: 550,
                gauss_width: 50,
                blackbody_temperature: LightSourceUtils.getBlackbodyTemperature(550),
                blackbody_max: 550,
                min: 400,
                max: 700,
                delta: 50
            }
        }

        return LightSourceUtils._DEFAULT_WL_DATA;
    }
    //__________________________________________________________________________________________
    public static getWavelenghtData(pWavelenghtDistributionData: iWavelenghtDistributionData) {
        let aData = pWavelenghtDistributionData.data as iWavelenghtDistributionRange;
        if (false === LightSourceUtils._isValidRange(aData)) {
            return undefined;
        }
        let aWeightFunc = this._getWeightFunc(pWavelenghtDistributionData);
        if (undefined === aWeightFunc) {
            return undefined;
        }

        let aWavelengths = Array<iWavelengthSingleItem>();

        let aToFix = OP3DMathUtils.countDecimalPoints(aData.delta);
        let aPrimaryWeight = -Infinity;
        let aPrimaryIndex = 0;
        for (let w = aData.min; w <= aData.max; w += aData.delta) {
            let wl = OP3DMathUtils.roundNum(w, aToFix);

            let aWeight = aWeightFunc(wl);
            if (aWeight > aPrimaryWeight) {
                aPrimaryWeight = aWeight;
                aPrimaryIndex = aWavelengths.length;
            }

            aWavelengths.push({
                wl: wl,
                weight: aWeight,
                isPrimary: false
            });
        }

        aWavelengths[aPrimaryIndex].isPrimary = true;

        return aWavelengths;
    }
    //__________________________________________________________________________________________
    private static _isValidRange(pData: iWavelenghtDistributionRange) {
        let aIsValid = ((undefined !== pData.min) && (undefined !== pData.max) &&
            (undefined !== pData.delta) && (0 > pData.min) && (0 < pData.max) ||
            (0 < pData.delta) && ((pData.min < pData.max)));

        return aIsValid;
    }
    //__________________________________________________________________________________________
    private static _getWeightFunc(pDistributionData: iWavelenghtDistributionData) {
        let aType = pDistributionData.type;
        let aWeightFunc: (pW: number) => number;

        switch (aType) {
            case eWavelengthDistributionType.BLACKBODY:
                let aBBData = pDistributionData.data as iWavelenghtDistributionBlackbody;
                let aTemperature = aBBData.blackbody_temperature;
                let aBlackbodyMax = aBBData.blackbody_max;
                aWeightFunc = (w) => this.getBlackbodyWeight(w, aTemperature, aBlackbodyMax);
                break;
            case eWavelengthDistributionType.GAUSSIAN:
                let aGaussianData = pDistributionData.data as iWavelenghtDistributionGaussian;
                let aGaussianCenterW = aGaussianData.gauss_center;
                let aGaussianWidth = aGaussianData.gauss_width;
                aWeightFunc = (w) => this.getGaussianAmplitude(w, aGaussianCenterW, aGaussianWidth);
                break;
            case eWavelengthDistributionType.TH:
                aWeightFunc = () => 1;
                break;
            default:
                return undefined;
        }

        return aWeightFunc

    }
    //__________________________________________________________________________________________
    public static getBlackbodyTemperature(pWavelength: number) {
        // T = b/λmax;

        let aTemperature = this.B / (pWavelength / LightSourceUtils.M_TO_NM);
        return aTemperature;
    }
    //__________________________________________________________________________________________
    public static getBlackbodyPeakWL(pTemperature: number) {

        let aLambda = LightSourceUtils.B / pTemperature;
        return (aLambda * LightSourceUtils.M_TO_NM);
    }
    //__________________________________________________________________________________________
    public static getSource(pSource: iSourceVO) {
        const aIsFunction = this.isFunction(pSource.parameters.type);
        let aSource: iSourceVOSpectrumFile;

        if (aIsFunction) {
            aSource = this._getSourceByfunction(pSource);
        } else {
            // 
            // let aKeys = Object.keys(aSource.spectrum);
            // let aHasFactor = aKeys.findIndex((key) => key.toString().indexOf('.') != -1);
            aSource = pSource.parameters.data.spectrum_file;
            // for (let index in aSource.spectrum) {

            // }
        }

        return aSource;
    }
    //__________________________________________________________________________________________
    public static getSpectrumByFunction(pSource: iSourceVO) {
        let aSpectrum = this._getSourceByfunction(pSource);
        return aSpectrum;
    }
    //__________________________________________________________________________________________
    public static getSpectrum(pSource: iSourceVO) {
        const aIsFunction = this.isFunction(pSource.parameters.type);
        let aSource: iSourceVOSpectrumFile;
        let aSpectrum: Array<number>;

        if (aIsFunction) {
            aSource = this._getSourceByfunction(pSource);
            aSpectrum = aSource.spectrum as Array<number>;
        } else {
            // 
            alert("not supported");
            aSpectrum = [];
            // let aKeys = Object.keys(aSource.spectrum);
            // let aHasFactor = aKeys.findIndex((key) => key.toString().indexOf('.') != -1);
            // aSource = pSourceVO.parameters.data.spectrum_file;
            // for (let index in aSource.spectrum) {
            // }
        }

        return aSpectrum;
    }
    //__________________________________________________________________________________________
    private static _getB(pWavelengths: Array<number>, pSource: Array<number>,
        pLegalFrom: number, pCoefficient: number, pIsFalseColor: boolean) {

        let aResult: number;
        let aDenominator: number = 0;
        const aSpectrum: Array<any> = Spectrum.SPECTRUM;
        for (let i = 0; i < aSpectrum.length; i++) {
            if (null != aSpectrum[i]) {
                aDenominator += aSpectrum[i].B;
            }
        }

        let aNumerator: number = 0;
        for (let i = 0; i < pSource.length; i++) {
            let aCurrIntensity = 0;
            let aWavelength = pWavelengths[i];

            if (null != pSource[i]) {
                aCurrIntensity = pSource[i];
                if (true == pIsFalseColor) {
                    aWavelength = this._getFalseLambda(aWavelength, pLegalFrom, pCoefficient);
                }
            }

            if (null == aSpectrum[aWavelength]) {
                if (Math.floor(aWavelength) != aWavelength) {
                    let aFloor = Math.floor(aWavelength);
                    let aCeil = Math.ceil(aWavelength);

                    let aS0 = (null != aSpectrum[aFloor]) ? aSpectrum[aFloor].B : 0;
                    let aS1 = (null != aSpectrum[aCeil]) ? aSpectrum[aCeil].B : 0;

                    let aAvg = (aS0 + ((aS1 - aS0) * (aWavelength - aFloor)));
                    aNumerator += aAvg * aCurrIntensity;
                }
            } else {
                aNumerator += aSpectrum[aWavelength].B * aCurrIntensity;
            }
        }

        aResult = 255 * (aNumerator / aDenominator);

        return OP3DMathUtils.clampValue(aResult, 0, 255);
    }
    //__________________________________________________________________________________________
    public static getUVColor(pWavelengths: Array<number>, pSource: Array<number>): iColor {
        let aFrom = pWavelengths[0];
        let aTo = pWavelengths[(pWavelengths.length - 1)];

        let aDelta = (aTo - aFrom);
        let aMaxR = 0;
        let aMaxG = 0;
        let aMaxB = 0;

        let aMaxIndexR = 0;
        let aMaxIndexG = 0;
        let aMaxIndexB = 0;

        for (let i = 0; i < pSource.length; i++) {
            let aWavelength = pWavelengths[i];

            let aYR = 125 * (Math.exp(-0.5 / aDelta * (aWavelength)));
            let aYG = 70 * (Math.exp(-3 / aDelta * (aWavelength)));
            let aYB = 165 * (1 - Math.exp(-1 / aDelta * (aWavelength))) + 120;

            let aR = pSource[i] * aYR;
            let aG = pSource[i] * aYG;
            let aB = pSource[i] * aYB;
            if (aR > aMaxR) {
                aMaxR = aR;
                aMaxIndexR = aWavelength;
            }
            if (aG > aMaxG) {
                aMaxG = aG;
                aMaxIndexG = aWavelength;
            }
            if (aB > aMaxB) {
                aMaxB = aB;
                aMaxIndexB = aWavelength;
            }
        }

        let aR = 125 * (Math.exp(-0.5 / aDelta * (aMaxIndexR)));
        let aG = 70 * (Math.exp(-3 / aDelta * (aMaxIndexG)));
        let aB = 165 * (1 - Math.exp(-1 / aDelta * (aMaxIndexB))) + 120;

        let aFactorR = 0.25 * (Math.exp(-10 / aDelta * (aMaxIndexR))) + 0.75;
        let aFactorG = 0.25 * (Math.exp(-10 / aDelta * (aMaxIndexG))) + 0.75;
        let aFactorB = 0.25 * (Math.exp(-10 / aDelta * (aMaxIndexB))) + 0.75;
        let aColor = new Color(aR * aFactorR, aG * aFactorG, aB * aFactorB);

        //console.log("r is  " + aR + "    g is  " + aG + "  b is  " + aB);

        return {
            color: aColor,
            alpha: 1
        }

    }
    //__________________________________________________________________________________________
    public static getColor2(pWavelengths: Array<number>, pSource: Array<number>): iColor {
        if (pSource.length == 0) {
            return {
                color: new Color(0, 0, 0),
                alpha: 1
            }
        }

        let aWavelengths = pWavelengths.slice();
        let aSource = pSource.slice();

        let aFrom = aWavelengths[0];
        let aTo = aWavelengths[(aWavelengths.length - 1)];

        if (aFrom < this.MIN_VISIBLE_LAMBDA && aTo < this.MIN_VISIBLE_LAMBDA) { //All light in ultra violet
            let aUVSource = aSource.slice();
            let aColor = this.getUVColor(aWavelengths.slice(), aUVSource);

            return aColor;
        } else if ((aFrom >= this.MIN_VISIBLE_LAMBDA && aTo <= this.MAX_VISIBLE_LAMBDA)) { //all of light is in visible spectrum
            let aVisibleSource = aSource.slice();
            let aVisibleW = aWavelengths.slice();
            let aColor = this._calculateColor(aVisibleW, aVisibleSource, false);
            return aColor;
        } else if (aFrom >= this.MAX_VISIBLE_LAMBDA) {  // all of light is in infra red spectrum
            let aInfraRedSource = aSource.slice();
            let aInfraRedWavelength = aWavelengths.slice();
            let aInfraRedColor = this.getInfraRedColor(aInfraRedWavelength, aInfraRedSource);

            return aInfraRedColor;
        } else { //light is part in visible spectrum and part in invisible spectrum.
            let aFirstVisibleIndex = aWavelengths.findIndex((wavelength) =>
                (wavelength >= this.MIN_VISIBLE_LAMBDA));

            let aFirstInvisibleWLIndex = aWavelengths.findIndex((wavelength) =>
                (wavelength > this.MAX_VISIBLE_LAMBDA));

            let aVisibleWL = aWavelengths.slice(aFirstVisibleIndex,
                (aFirstInvisibleWLIndex - 1));
            let aVisibleSource = aSource.slice(aFirstVisibleIndex,
                (aFirstInvisibleWLIndex - 1));
            let aColor = this._calculateColor(aVisibleWL, aVisibleSource, false);

            return aColor
        }
    }
    //__________________________________________________________________________________________
    private static getInfraRedColor(pWavelengths: Array<number>, pSource: Array<number>): iColor {
        let aDelta = (10000 - 750);
        let aMaxR = 0;
        let aMaxG = 0;
        let aMaxB = 0;

        let aMaxIndexR = 0;
        let aMaxIndexG = 0;
        let aMaxIndexB = 0;

        for (let i = 0; i < aDelta; i++) {
            let aWavelength = pWavelengths[i];

            let aYR = (205 * (Math.exp(-1 / aDelta * (i))) + 50);
            let aYG = ((50 * Math.exp(-Math.pow((aWavelength - 800), 2) / Math.pow(200, 2))) +
                (70 * (1 - Math.exp(-3 / aDelta * (aWavelength)))) + 10);
            let aYB = ((50 * Math.exp(-Math.pow((aWavelength - 1350), 2) / Math.pow(800, 2))) +
                (10 * Math.exp(-Math.pow((aWavelength - 5850), 2) / Math.pow(2000, 2))));

            if (pSource != null) {

                let aR = pSource[i] * aYR;
                let aG = pSource[i] * aYG;
                let aB = pSource[i] * aYB;
                if (aR > aMaxR) {
                    aMaxR = aR;
                    aMaxIndexR = pWavelengths[i];
                }
                if (aG > aMaxG) {
                    aMaxG = aG;
                    aMaxIndexG = pWavelengths[i];
                }
                if (aB > aMaxB) {
                    aMaxB = aB;
                    aMaxIndexB = pWavelengths[i];
                }
            }
        }

        let aR = (205 * (Math.exp(-1 / aDelta * (aMaxIndexR))) + 50);
        let aG = (70 * (1 - Math.exp(-3 / aDelta * (aMaxIndexG))) + 10);
        let aB = ((50 * Math.exp(-Math.pow((aMaxIndexB - 1350), 2) / Math.pow(800, 2))) +
            (10 * Math.exp(-Math.pow((aMaxIndexB - 5850), 2) / Math.pow(2000, 2))));


        let aFactorR = 0.3 * (Math.exp(-10 / aDelta * (aMaxIndexR))) + 0.7;
        let aFactorG = 0.3 * (Math.exp(-10 / aDelta * (aMaxIndexG))) + 0.7;
        let aFactorB = 0.3 * (Math.exp(-10 / aDelta * (aMaxIndexB))) + 0.7;
        let aColor = new Color(aR * aFactorR, aG * aFactorG, aB * aFactorB);

        return {
            color: aColor,
            alpha: 1
        }
    }
    //__________________________________________________________________________________________
    private static _calculateColor(pWavelengths: Array<number>, pSource: Array<number>,
        pIsFalseColor: boolean = true): iColor {

        let aFrom = pWavelengths[0];
        let aTo = pWavelengths[(pWavelengths.length - 1)];
        let aVisibleSpectrumWidth = (this.MAX_VISIBLE_LAMBDA - this.MIN_VISIBLE_LAMBDA);
        let aSourceWidth = (aTo - aFrom);
        let aCoefficient = (aVisibleSpectrumWidth / aSourceWidth);

        if ((aFrom >= this.MIN_VISIBLE_LAMBDA) && (aTo <= this.MAX_VISIBLE_LAMBDA)) {
            pIsFalseColor = false;
        }

        let aRed = this._getR(pWavelengths, pSource, aFrom, aCoefficient, pIsFalseColor);
        let aGreen = this._getG(pWavelengths, pSource, aFrom, aCoefficient, pIsFalseColor);
        let aBlue = this._getB(pWavelengths, pSource, aFrom, aCoefficient, pIsFalseColor);
        let aVal = Math.max(aRed, aGreen, aBlue);
        let aScale = 1;

        if (aVal < 255) {
            aScale = 255 / aVal;
            aRed *= aScale;
            aGreen *= aScale;
            aBlue *= aScale;
        }

        let aColor = new Color(aRed, aGreen, aBlue);
        let aAlphaMin = 0.2;

        let aRet: iColor = {
            color: aColor,
            alpha: aAlphaMin + ((1 - aAlphaMin) / aScale)
        };

        return aRet;
    }
    //__________________________________________________________________________________________
    private static _getG(pWavelengths: Array<number>, pSource: Array<number>,
        pLegalFrom: number, pCoefficient: number, pIsFalseColor: boolean) {

        let aResult: number;
        let aDenominator: number = 0;
        const aSpectrum: Array<any> = Spectrum.SPECTRUM;
        for (let i = 0; i < aSpectrum.length; i++) {
            if (null != aSpectrum[i]) {
                aDenominator += aSpectrum[i].G;
            }
        }

        let aNumerator: number = 0;
        for (let i = 0; i < pSource.length; i++) {
            let aCurrIntensity = 0;
            let aWavelength = pWavelengths[i];

            if (null != pSource[i]) {
                aCurrIntensity = pSource[i];
                if (true == pIsFalseColor) {
                    aWavelength = this._getFalseLambda(aWavelength, pLegalFrom, pCoefficient);
                }
            }

            if (null == aSpectrum[aWavelength]) {
                if (Math.floor(aWavelength) != aWavelength) {
                    let aFloor = Math.floor(aWavelength);
                    let aCeil = Math.ceil(aWavelength);

                    let aS0 = (null != aSpectrum[aFloor]) ? aSpectrum[aFloor].G : 0;
                    let aS1 = (null != aSpectrum[aCeil]) ? aSpectrum[aCeil].G : 0;

                    let aAvg = (aS0 + ((aS1 - aS0) * (aWavelength - aFloor)));
                    aNumerator += aAvg * aCurrIntensity;
                }
            } else {
                aNumerator += aSpectrum[aWavelength].G * aCurrIntensity;
            }
        }

        aResult = 255 * (aNumerator / aDenominator);

        return OP3DMathUtils.clampValue(aResult, 0, 255);
    }
    //__________________________________________________________________________________________
    private static _getFalseLambda(pLamnda: number, pLegalFrom: number, pCoefficient: number): number {
        let aFalseLambda = pCoefficient * (pLamnda - pLegalFrom) + 380;
        return Math.floor(aFalseLambda);
    }
    //__________________________________________________________________________________________
    private static _getR(pWavelengths: Array<number>, pSource: Array<number>,
        pLegalFrom: number, pCoefficient: number, pIsFalseColor: boolean) {

        let aResult: number;
        let aDenominator: number = 0;
        const aSpectrum: Array<any> = Spectrum.SPECTRUM;
        for (let i = 0; i < aSpectrum.length; i++) {
            if (null != aSpectrum[i]) {
                aDenominator += aSpectrum[i].R;
            }
        }

        let aNumerator: number = 0;
        for (let i = 0; i < pSource.length; i++) {
            let aCurrIntensity = 0;
            let aWavelength = pWavelengths[i];

            if (null != pSource[i]) {
                aCurrIntensity = pSource[i];
                if (true == pIsFalseColor) {
                    aWavelength = this._getFalseLambda(aWavelength, pLegalFrom, pCoefficient);
                }
            }

            if (null == aSpectrum[aWavelength]) {
                if (Math.floor(aWavelength) != aWavelength) {
                    let aFloor = Math.floor(aWavelength);
                    let aCeil = Math.ceil(aWavelength);

                    let aS0 = (null != aSpectrum[aFloor]) ? aSpectrum[aFloor].R : 0;
                    let aS1 = (null != aSpectrum[aCeil]) ? aSpectrum[aCeil].R : 0;

                    let aAvg = (aS0 + ((aS1 - aS0) * (aWavelength - aFloor)));
                    aNumerator += aAvg * aCurrIntensity;
                }
            } else {
                aNumerator += aSpectrum[aWavelength].R * aCurrIntensity;
            }
        }

        aResult = 255 * (aNumerator / aDenominator);

        return OP3DMathUtils.clampValue(aResult, 0, 255);
    }
    //__________________________________________________________________________________________
    private static _getSourceByfunction(pSourceVO: iSourceVO)
        : iSourceVOSpectrumFile {

        switch (pSourceVO.parameters.type) {
            case eLightSourceType.BLACKBODY:
                return this._getBlackbodySource(pSourceVO.parameters.data.temperature,
                    pSourceVO);
            case eLightSourceType.GAUSSIAN:
                return this._getGaussianSource(pSourceVO.parameters.data.center_wavelength,
                    pSourceVO.parameters.data.bandwidth, pSourceVO);
        }
    }
    //__________________________________________________________________________________________
    private static _getGaussianSource(pWl: number, pWidth: number,
        pSourceVO: iSourceVO): iSourceVOSpectrumFile {


        // let aSource: iNumericKeyHash<number> = {};
        let aSource = new Array<number>();
        for (let i = 0; i < this.LEGAL_TO; i++) {
            let aVal = Math.exp(-Math.pow((i - pWl) / pWidth, 2));
            if (aVal == 0) {
                aVal = MathContext.EPSILON_20;
            }
            //aSource.push(aVal);
            aSource[i] = aVal;
        }

        let aResult: iSourceVOSpectrumFile = {
            legalFrom: 0,
            legalTo: this.LEGAL_TO,
            name: pSourceVO.name,
            spectrum: aSource
        }

        return aResult;
    }
    //__________________________________________________________________________________________
    private static _getBlackbodySource(pTemperature: number,
        pSourceVO: iSourceVO): iSourceVOSpectrumFile {

        //let aNewSourceArr = new Array<number>();
        let aNewSourceArr = new Array<number>();
        let aMaxVal = 0;
        let aKBT = this.KB * pTemperature;

        for (let lambda_nm = this.LEGAL_FROM_BLACKBODY; lambda_nm < this.LEGAL_TO_BLACKBODY; lambda_nm++) {
            let aLambdaM = lambda_nm * 1E-9;
            let b = (-1 + Math.exp(LightSourceUtils.HC / (aKBT * aLambdaM)));
            let aLambdaExp5 = (Math.pow(aLambdaM, 5))
            let aVal = LightSourceUtils.PI_HC_8 / b / aLambdaExp5;
            if (aMaxVal < aVal) {
                aMaxVal = aVal;
            }
            aNewSourceArr[lambda_nm] = (aVal);
        }

        let aNewSmallArr = new Array<number>();

        for (let i = 0; i < this.LEGAL_TO_BLACKBODY; i++) {
            let aRes = (aNewSourceArr[i] / aMaxVal);
            if (isNaN(aRes)) {
                aNewSmallArr[i] = 0;
            } else {
                aNewSmallArr[i] = aRes;
            }
        }

        let aSource: iSourceVOSpectrumFile = {
            legalFrom: this.LEGAL_FROM_BLACKBODY,
            legalTo: this.LEGAL_TO_BLACKBODY,
            spectrum: aNewSmallArr,
            name: pSourceVO.name
        };

        return aSource;
    }
    //__________________________________________________________________________________________
    public static getGaussianAmplitude(pCurrLambda: number, pCentralWavelength: number,
        pWidth: number) {

        let aRes: number;
        if (0 === pWidth) {
            aRes = (pCurrLambda === pCentralWavelength) ? 1 : 0;
        } else {
            aRes = Math.exp(-Math.pow(((pCurrLambda - pCentralWavelength) / pWidth), 2));
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    public static getBlackbodyWeight(pCurrWavelength: number, pTemperature: number,
        pMaxWavelength: number) {

        let aKBT = LightSourceUtils.KB * pTemperature;
        let aLambdaM = pCurrWavelength / LightSourceUtils.M_TO_NM;
        let aLambdaExp5 = (Math.pow(aLambdaM, 5));
        let bCurr = (-1 + Math.exp(LightSourceUtils.HC / (aKBT * aLambdaM)));

        let aMaxLambdaM = pMaxWavelength / LightSourceUtils.M_TO_NM;
        let aMaxLambdaExp5 = (Math.pow(aMaxLambdaM, 5));
        let bMax = (-1 + Math.exp(LightSourceUtils.HC / (aKBT * aMaxLambdaM)));

        let aAmplitudeCurrLambda = LightSourceUtils.PI_HC_8 / bCurr / aLambdaExp5;
        let aMaxLambdaAmplitude = LightSourceUtils.PI_HC_8 / bMax / aMaxLambdaExp5;
        let aNormalized = aAmplitudeCurrLambda / aMaxLambdaAmplitude;

        return aNormalized;
    }
    //__________________________________________________________________________________________
    public static isFunction(pType: eLightSourceType) {
        switch (pType) {
            case eLightSourceType.GAUSSIAN:
            case eLightSourceType.BLACKBODY:
                return true;
            case eLightSourceType.LASER:
            case eLightSourceType.HG:
            case eLightSourceType.LED:
            case eLightSourceType.WHITE_SOURCE:
            case eLightSourceType.CUSTOMIZED:
                return false;
            default:
                return false;
        }
    }
}
