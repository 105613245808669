import { eIngredientType } from "../../_context/Enums";
import { OpticsContext } from "../../_context/OpticsContext";
import { iElasticQuery, iGeneralIngrediantVOQuery } from "../../_context/_interfaces/Interfaces";
import { ServerContext } from "../../server/ServerContext";
import { CacheStorage, eCacheDataType } from "../CacheStorage";
import { iOpticsVO } from "../VO/OpticsVOInterfaces";
import { ApertureDataLoader } from "./ApertureDataLoader";
import { CoatingDataLoader } from "./CoatingDataLoader";
import { DataLoader } from "./DataLoader";

export class OpticsDataLoader extends DataLoader<iOpticsVO,
    iGeneralIngrediantVOQuery,
    iOpticsVO, iOpticsVO> {

    /////private static SUBLENS_NAME: string = "_sub_lens_";
    private static INSTANCE: OpticsDataLoader;

    private constructor() {
        super({

            fullDataServerCall: (pOpticsVOQuery: iGeneralIngrediantVOQuery) =>
                ServerContext.SERVER.getOneIngredient({
                    number_id: pOpticsVOQuery.number_id,
                    type: eIngredientType.OPTIC
                }),
            cacheData: {
                key: 'number_id',
                type: eCacheDataType.OPTIC_VO
            },
            addItemServerCall: (pData) =>
                ServerContext.SERVER.updateOpticAdmin(pData),
            getManyItems: (pQuery) =>
                ServerContext.SERVER.getManyOpticalElements(pQuery as iElasticQuery),
            isExistServerCall: (pOpticsVO) =>
                ServerContext.SERVER.isIngredientExists({
                    numberID: pOpticsVO.number_id,
                    ingredient: eIngredientType.OPTIC
                }),
            dataConvertionFunc: (pData: iOpticsVO) =>
                this._onGettingOpticsVO(pData)


        });
    }
    //__________________________________________________________________________________________
    public removeFromCache(pKey: string) {
        CacheStorage.instance.removeData(pKey, this.mDataLoaderParams.cacheData.type);
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public async getManyItems(pQuery: iElasticQuery) {
        if (this.mNeedsUpdate) {
            let aResult = await this.mDataLoaderParams.getManyItems(pQuery);
            if (aResult.success) {

                const aTotalResults = aResult.data.aggregation.permission.buckets.reduce((accumulator, curValue) => accumulator + curValue.doc_count, 0)
                this.mDataTotal = aTotalResults;
                this.mTotalAggregation = aResult.data.aggregation;
                this.mCurrentData = aResult.data.results.map(item => {
                    return item
                });

                // }
            } else {
                this.mDataTotal = 0;
                this.mTotalAggregation = [];

                this.mCurrentData = [];
            }
            this.mNeedsUpdate = false;
            return this.mCurrentData;
        } else {
            return this.mCurrentData
        }
    }
    //__________________________________________________________________________________________
    private async _onGettingOpticsVO(pOpticsVO: iOpticsVO) {
        if (null == pOpticsVO) {
            return null;
        }

        if ((null != pOpticsVO.parameters) && (null != pOpticsVO.parameters.coating)) {
            for (let surfaceName in pOpticsVO.parameters.coating) {
                let aCoatingNumberID = pOpticsVO.parameters.coating[surfaceName];
                await CoatingDataLoader.instance.getSingleFullData({
                    number_id: aCoatingNumberID
                });
            }
        }

        if (pOpticsVO.parameters.subType == OpticsContext._User_Aperture) {
            await ApertureDataLoader.instance.loadApertures(pOpticsVO.parameters.physical_data);
        }

        if (Array.isArray(pOpticsVO.parameters.materialID)) {
            pOpticsVO.parameters.materialID = pOpticsVO.parameters.materialID.join(";")
        }

        this._editGeo(pOpticsVO);

        return pOpticsVO;
    }
    //__________________________________________________________________________________________
    private _editGeo(pOpticsVO: iOpticsVO) {
        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._Cylindrical_Lens_Plano_Concave:
            case OpticsContext._Cylindrical_Lens_Plano_Convex:
            case OpticsContext._Cylindrical_Mirror_Plano_Concave:
            case OpticsContext._Cylindrical_Mirror_Plano_Convex:

                let aR1X = (undefined !== pOpticsVO.parameters.geometry.r1_x) ?
                    pOpticsVO.parameters.geometry.r1_x : Infinity;
                let aR1Y = (undefined !== pOpticsVO.parameters.geometry.r1_y) ?
                    pOpticsVO.parameters.geometry.r1_y : pOpticsVO.parameters.geometry.r1;
                let aR2X = (undefined !== pOpticsVO.parameters.geometry.r2_x) ?
                    pOpticsVO.parameters.geometry.r2_x : Infinity;
                let aR2Y = (undefined !== pOpticsVO.parameters.geometry.r2_y) ?
                    pOpticsVO.parameters.geometry.r2_y : Infinity;

                pOpticsVO.parameters.geometry.r1_x = aR1X;
                pOpticsVO.parameters.geometry.r1_y = aR1Y;
                pOpticsVO.parameters.geometry.r2_x = aR2X;
                pOpticsVO.parameters.geometry.r2_y = aR2Y;

                delete pOpticsVO.parameters.geometry.r1;
                delete pOpticsVO.parameters.geometry.r2;

                break;
            default:
                break;
        }
    }
    //__________________________________________________________________________________________
}
