import classes from "../../UI/optichat-info.module.scss";
import OptiChatHeader from "../OptiChatHeader";
import OptiChatInfoBody from "./OptiChatInfoBody";
import { StyledOptiChat } from "../chat/OptiChat";
import DraggableForm from "../DraggableForm";

export interface iOptiChatInfoData {
  title: string;
  content: any;
}
export default function OptiChatInfo({ onClose }) {
  return (
    <DraggableForm handle=".handle" right="100" bottom="170">
      <StyledOptiChat className={classes.optichat_info}>
        <OptiChatHeader
          hasReset={false}
          className="handle"
          title={"OPTICHAT™ - INFO"}
          onClose={onClose}
          hasInfoBtn={false}
        />
        <OptiChatInfoBody />
      </StyledOptiChat>
    </DraggableForm>
  );
}
