import { useEffect, useState } from "react";
import { BlackBox } from "./BlackBox";
import { Op3dContext } from "../../../_context/Op3dContext";
import { SceneContext } from "../../../scene/SceneContext";
import { eClickMode } from "../../../ui/_globals/PartsEventsHandler";
import { ChoosePartMode } from "../../../ui/forms/ChoosePartMode";
import { ViewUtils } from "../../../ui/ViewUtils";

export function BlackBoxBtn(props) {
  if (Op3dContext.USER_PERMISSION.isFreeUser === true) return null;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    if (!isModalOpen) {
      ChoosePartMode.closeOpenTools();
      Op3dContext.PART_INFO.close();
      SceneContext.CHOOSE_MODE.mode = eClickMode.BB;
    } else {
      ChoosePartMode.instance.leaveChoosePartMode();
    }

    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  useEffect(() => {
    ViewUtils.initTooltips();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    ChoosePartMode.instance.leaveChoosePartMode();
  };

  return (
    <div>
      <button
        onClick={toggleModal}
        className="btn icon-btn"
        data-toggle="tooltip"
        data-placement="top"
        title="Blackbox"
      >
        {isModalOpen ? (
          <img
            src="./images/icons_new/toolbar/black_box_active.svg"
            alt="BlackBox tool"
          />
        ) : (
          <img
            src="./images/icons_new/toolbar/black_box.svg"
            alt="BlackBox tool"
          />
        )}
      </button>

      {isModalOpen && (
        <BlackBox
          onClose={closeModal}
          counter={props.counter}
          setCounter={props.setCounter}
        />
      )}
    </div>
  );
}
