import { eErrorMessageType } from "../../../../_context/Enums";
import { iCoatingVO } from "../../../../_context/_interfaces/Interfaces";
import { CoatingParser } from "../../../../parser/CoatingParser";
import { Op3dComponentBase } from "../../../Op3dComponentBase";
import { ViewUtils } from "../../../ViewUtils";
import { Popup } from "../../Popup";

export class UploadCoatingFileForm extends Op3dComponentBase<(pData: iCoatingVO) => void> {

    // private static INSTANCE: UploadCoatingFileForm;
    private static ERROR_MESSAGES = {
        FILE_NAME_ERR: "Supported characters are: (0-9, a-z, A-Z), as well as the following special characters: ! . \ - _ * ' ( )",
        FILE_READING_FAILED: "Error reading file",
        MISSING_COLUMNS: "Missing columns",
        ERROR_IN_ROWS: "Some of the rows are invalid",
        WL_DESCENDING_ORDER: "Wavelengths should be in ascending order",
        ERROR_READING_ROW: "Error occurres while reading rows",
        ERROR_DOWNLOAD_TEMPLATE: "Error occurred while download template",
        INVALID_FILE_TYPE: "Invalid file type, file must be .csv or .xlsx",
    }
    private mTemplateBtn: HTMLElement;
    private mFileInput: HTMLInputElement;
    private mFileInputLabel: HTMLLabelElement;
    private mWarningMessage: HTMLElement;
    private mWarningDiv: HTMLElement;
    private mParsedCoating: iCoatingVO;
    private mImportBtn: HTMLElement;
    private mFunc: (pData: iCoatingVO) => void;

    private constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/upload_coating_form.html'
        });
    }
    //_____________________________________________________________________________________
    public static get instance() {

        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('fade');
        document.getElementById('forms').appendChild(aDiv);
        return new UploadCoatingFileForm(aDiv);

    }
    //_____________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        ViewUtils.removeFromParent(this.mContainer);
        this.mData = null;
    }
    //_____________________________________________________________________________________
    private _onDownloadTemplate() {
        // ViewUtils.setClassForElement(this.mTemplateBtn, "clicked", true);
        // ViewUtils.setElementDisabled(this.mUploadContainer, false);

        const data: Array<Array<any>> = [
            ['wl', 't_p', 'r_p', 't_s', 'r_s'],
            [400, 0.1, 0.2, 0.9, 0.8]
        ];

        try {
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
            XLSX.writeFile(workbook, 'output.xlsx');

        } catch (e) {
            this._showError(UploadCoatingFileForm.ERROR_MESSAGES.ERROR_DOWNLOAD_TEMPLATE)
        }
    }
    //_____________________________________________________________________________________
    protected _onCreationComplete(): void {

        let aInfoBtn = this._getPart("upload-coating-info", true);
        aInfoBtn.addEventListener("click", () => this._onInfoClick());

        this.mTemplateBtn = this._getPart("download-template", true);
        this.mTemplateBtn.addEventListener("click", () => this._onDownloadTemplate());

        this.mFileInput = this._getPart("custom-coating-input", true) as HTMLInputElement;
        this.mFileInput.addEventListener("change", () => this._onFileChange());
        this.mFileInputLabel = this._getPart("custom-coating-input-label", true) as HTMLLabelElement;
        // this.mUploadContainer = this._getPart("upload-container", true);
        this.mWarningMessage = this._getPart("text-warning", true);
        this.mWarningDiv = this._getPart("warning-container", true);
        this.mImportBtn = this._getPart('import-btn', true);
        this.mImportBtn.addEventListener('click', () => this._onImport());

        this.mIsReady = true;
    }
    //_____________________________________________________________________________________
    private _onInfoClick() {
        let aText = "1. Download the coating file template" + "<br>" +
            "2. Customize it according to your needs and save" + "<br>" +
            "3. Upload the customized file to 3doptix" + "<br>";

        Popup.instance.open({
            text: aText,
            title: "Custom coating file upload info"
        })
    }
    //_____________________________________________________________________________________
    private _onImport() {
        this.mFunc(this.mParsedCoating);
        this.close();
    }
    //_____________________________________________________________________________________
    private _showError(pTxt: string) {
        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, true);
        this.mWarningMessage.innerHTML = pTxt;
    }
    //_____________________________________________________________________________________
    private _clearFile() {
        this.mFileInputLabel.innerText = "Choose file";
        this.mFileInput.value = null;
        this.mFileInput.files = null;
    }
    //_____________________________________________________________________________________
    private async _onFileChange() {

        this.mParsedCoating = null;
        ViewUtils.setElementDisabled(this.mImportBtn, true);
        let aFile = this.mFileInput.files[0];
        let aName = aFile.name;
        let aExtension = aName.slice(aName.lastIndexOf('.') + 1);
        if (aExtension !== "csv" && aExtension !== 'xlsx') {
            this._showError(UploadCoatingFileForm.ERROR_MESSAGES.INVALID_FILE_TYPE);
            return;
        }

        var pattern = /^[0-9a-zA-Z!.\-_*'()]+$/;
        if (pattern.test(aName) == false) {
            this._clearFile();
            this._showError(UploadCoatingFileForm.ERROR_MESSAGES.FILE_NAME_ERR);
            return;
        }

        let aRes = await new CoatingParser().parse(aFile);
        if (aRes.success == false) {
            this._clearFile();
            this._showError(UploadCoatingFileForm.ERROR_MESSAGES[aRes.data as eErrorMessageType]);
            return;
        }

        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, false);
        this.mFileInputLabel.innerText = aFile.name;
        this.mParsedCoating = aRes.data as iCoatingVO;
        ViewUtils.setElementDisabled(this.mImportBtn, false);
    }
    //_____________________________________________________________________________________
    private _clearForm() {
        this._clearFile();
        // ViewUtils.setClassForElement(this.mTemplateBtn, "clicked", false);
        ViewUtils.setElementDisabled(this.mImportBtn, true);
        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, false);
        this.mParsedCoating = null;
    }
    //_____________________________________________________________________________________
    protected _onOpen(pFunc?: (pData: iCoatingVO) => void): void {
        this._clearForm();
        this.mFunc = pFunc;
    }
    //_____________________________________________________________________________________
}
