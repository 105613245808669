import { EventManager } from "../../../oc/events/EventManager";
import { EventTypes } from "../../../oc/events/EventTypes";
import { AnalyticsEventsContext } from "../../_context/AnalyticsEventsContext";
import { eFileType, eErrorType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iNumericKeyHash, iHash, iClientPoint } from "../../_context/_interfaces/Interfaces";
import { Part } from "../../parts/Part";
import { ClickManager } from "../../parts/base_3d/ClickManager";
import { eMouseMode } from "../../scene/Op3dScene";
import { SceneContext } from "../../scene/SceneContext";
import { ServerContext, ENVS } from "../../server/ServerContext";
import { SetupsManager } from "../../setups/SetupManager";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { Popup } from "../forms/Popup";
import { Menu } from "../home/Menu";
import { eClickMode } from "./PartsEventsHandler";
import { eMouseEvent } from "./uiEnums";

export interface iInputDispatcherData {
    function: Function,
    preventDefault?: boolean;
    owner?: HTMLElement
}

enum eKeyEvent {
    UP,
    DOWN,
    PRESS
}

export interface iShortcutKey {
    code?: string;
    ctrlKey?: boolean;
    altKey?: boolean;
    shiftKey?: boolean;
}


export class InputDispatcher {
    private mShowNetworkMessage: boolean = false;
    private mMouseDownTime: Date = new Date();

    private mShortCuts: iNumericKeyHash<iHash<iInputDispatcherData>> = {};

    private mClickManager: ClickManager;
    private mLeftMenuOptions: HTMLElement;
    mIsThrottled: boolean;

    constructor() {

        this.mClickManager = new ClickManager();
        this._addBasicListeners();
        this._addShortcuts();
    }
    //__________________________________________________________________________________________
    public get clickManager() {
        return this.mClickManager;
    }
    //__________________________________________________________________________________________
    private _onMouseDown(pEvent: MouseEvent) {
        //added in order to remove from focus the menus that were open,
        //if we will have some issues with elements that are getting out
        //of focus then this line should be cheked
        $(':focus').blur();

        this.mMouseDownTime = new Date();

        SceneContext.OP3D_SCENE.activateRenderer();
        switch (SceneContext.OP3D_SCENE.op3dOrbitController.mouseMode) {
            case eMouseMode.PAN:
                if (2 == pEvent.button) {
                    document.body.classList.add("pan");
                }
                return;
            case eMouseMode.GENERAL:
            default:
                if ((1 == pEvent.button) ||
                    ((true == pEvent.ctrlKey) && (2 == pEvent.button))) {
                    document.body.classList.add("pan");
                } else {
                    document.body.classList.remove("pan");
                }

                break;
        }


        if (0 == pEvent.button) {
            this._onDownEvent({
                clientX: pEvent.clientX,
                clientY: pEvent.clientY
            });
        }
    }
    //__________________________________________________________________________________________
    private _onDblMouseDown(pEvent: MouseEvent) {
        //added in order to remove from focus the menus that were open,
        //if we will have some issues with elements that are getting out
        //of focus then this line should be cheked
        $(':focus').blur();

        this.mMouseDownTime = new Date();

        SceneContext.OP3D_SCENE.activateRenderer();
        switch (SceneContext.OP3D_SCENE.op3dOrbitController.mouseMode) {
            case eMouseMode.PAN:
                if (2 == pEvent.button) {
                    document.body.classList.add("pan");
                }
                return;
            case eMouseMode.GENERAL:
            default:
                if ((1 == pEvent.button) ||
                    ((true == pEvent.ctrlKey) && (2 == pEvent.button))) {
                    document.body.classList.add("pan");
                } else {
                    document.body.classList.remove("pan");
                }

                break;
        }


        if (0 == pEvent.button) {
            this._onDblMouseDownEvent({
                clientX: pEvent.clientX,
                clientY: pEvent.clientY
            });
        }
    }
    //__________________________________________________________________________________________
    private _addShortcuts() {
        this.mShortCuts[eKeyEvent.DOWN] = {};
        this.mShortCuts[eKeyEvent.UP] = {};
        this.mShortCuts[eKeyEvent.PRESS] = {};



        this._addShortCutKey(
            { code: 'delete' },
            { function: (e: Event) => Op3dContext.PARTS_MANAGER.deleteSelected(e) },
            eKeyEvent.UP);

        this._addShortCutKey(
            { code: 'o', ctrlKey: true },
            {
                function: () => EventManager.dispatchEvent(
                    EventsContext.OPEN_WAKEUP_SCREEN, this)
            },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 's', ctrlKey: true },
            { function: () => EventManager.dispatchEvent(EventsContext.SAVE_OPT_CLOUD, this) },
            eKeyEvent.DOWN);

        // this._addShortCutKey({ code: 's', ctrlKey: true, shiftKey: true },
        //     { function: () => EventManager.dispatchEvent(EventsContext.ON_SAVE_AS, this) },
        //     eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'i', ctrlKey: true },
            { function: () => EventManager.dispatchEvent(EventsContext.ON_IMPORT, this) },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'z', ctrlKey: true },
            { function: () => Op3dContext.SCENE_HISTORY.undoHistory() },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'y', ctrlKey: true },
            { function: () => Op3dContext.SCENE_HISTORY.redoHistory() },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'l', ctrlKey: true },
            { function: () => SimulationRunner.instance.runVisualizationSimulation() },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'g', ctrlKey: true },
            {
                function: () => EventManager.dispatchEvent(
                    EventsContext.SHOW_GRID_SHORTCUT, this)
            },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'b', ctrlKey: true },
            {
                function: () => EventManager.dispatchEvent(
                    EventsContext.SHOW_BREADBOARD_SHORCUT, this)
            },
            eKeyEvent.DOWN);

        this._addShortCutKey(
            { code: 'e', ctrlKey: true },
            {
                function: () => EventManager.dispatchEvent(EventsContext.ON_EXPORT,
                    this, eFileType.OPT)
            },
            eKeyEvent.DOWN);

        // this._addShortCutKey(
        //     { code: 'Escape' },
        //     {
        //         function: () => {
        //             if (SceneContext.CHOOSE_MODE.mode !== eClickMode.BASE) {
        //                 ChoosePartMode.instance.leaveChoosePartMode()
        //             }
        //         }
        //     },
        //     eKeyEvent.UP);
    }
    //__________________________________________________________________________________________
    private _getKey(pShortcutKey: iShortcutKey) {
        let aKey = '';
        aKey += pShortcutKey.ctrlKey == true ? 'ctrl' : '';
        aKey += pShortcutKey.altKey == true ? 'alt' : '';
        aKey += (pShortcutKey.shiftKey == true) ? 'shift' : '';
        aKey += this._getParsedCode(pShortcutKey as KeyboardEvent);

        return aKey.toLowerCase();
    }
    //__________________________________________________________________________________________
    private _getParsedCode(pEvent: KeyboardEvent): string {
        if ((pEvent == null) || (pEvent.code == null)) {
            return '';
        }

        if (pEvent.code.toLowerCase().indexOf("control") > -1) {
            return pEvent.ctrlKey == true ? '' : 'ctrl';
        }

        if (pEvent.code.toLowerCase().indexOf("shift") > -1) {
            return pEvent.shiftKey == true ? '' : 'shift';
        }

        if (pEvent.code.toLowerCase().indexOf("alt") > -1) {
            return pEvent.altKey == true ? '' : 'alt';
        }

        if (pEvent.code.toLowerCase().indexOf("delete") > -1) {
            return "delete";
        }

        let aKeyIndex = pEvent.code.toLowerCase().indexOf("key");
        if (aKeyIndex > -1) {
            let aKeyCode = pEvent.code.toLowerCase().replace("key", "");
            return aKeyCode;
        }
        return pEvent.code.toLowerCase();
    }
    //__________________________________________________________________________________________
    private _addShortCutKey(pShortcutKey: iShortcutKey,
        pInputDispatcherData: iInputDispatcherData,
        pKeyEvent: eKeyEvent) {

        let aKey = this._getKey(pShortcutKey);
        this.mShortCuts[pKeyEvent][aKey] = pInputDispatcherData;
    }
    //__________________________________________________________________________________________
    private _onDownEvent(pData: iClientPoint) {
        let aObject = this.mClickManager.onMouseDown(pData);
        Op3dContext.PARTS_MANAGER.onMouseDown(pData, aObject as any);
    }
    //__________________________________________________________________________________________
    private _onDblMouseDownEvent(pData: iClientPoint) {
        let aObject = this.mClickManager.onDblMouseDown(pData);
        Op3dContext.PARTS_MANAGER.onDblMouseDown(pData, aObject as any);
    }
    //__________________________________________________________________________________________
    private _onMouseUp(e: MouseEvent) {
        document.body.classList.remove("pan");
        let aMouseUpTime = new Date().getTime();
        let aMouseDownTime = this.mMouseDownTime.getTime()
        let aClickDelta = (aMouseUpTime - aMouseDownTime);

        if ((2 == e.button) && (aClickDelta <= 200)) {
            let aPart = this.mClickManager.getObjectUnder(e);
            this._onContextMenu(e, aPart);
            return;
        }

        let aEvent = { clientX: e.clientX, clientY: e.clientY };
        Op3dContext.PARTS_MANAGER.onMouseUp(aEvent, e);
        if (aClickDelta <= 200) {
            this.mClickManager.onMouseUp(aEvent);
        }

        try {
            this.mLeftMenuOptions.classList.remove("clicked")
        } catch (e) {
            this.mLeftMenuOptions = document.getElementById("left-options-menu");
        }

        SceneContext.OP3D_SCENE.enableController(true);
    }
    //__________________________________________________________________________________________

    private _onContextMenu(pEvent: MouseEvent, pPartUnder: Part) {
        Op3dContext.DIV_CONTROLLER.openContextMenu(pEvent, pPartUnder);
    }
    //__________________________________________________________________________________________
    private _addBasicListeners() {
        window.addEventListener('resize',
            () => EventManager.dispatchEvent(EventsContext.RESIZE_ALL_SCREEN,
                this));

        /*
        * this function prevents context menu on Linux and some other systems
        * 11/06 added checking input field to open context menu but only on inputs
        */
        window.oncontextmenu = (e) => {
            if (e.target.localName != 'input') {
                return false
            }
        };

        Op3dContext.CONTAINER.addEventListener("contextmenu", (e) => {
            e.stopPropagation();
            e.stopImmediatePropagation();
            e.preventDefault();
        });

        Op3dContext.CONTAINER.addEventListener("dragover",
            (e) => this._onDragOver(e));

        Op3dContext.CONTAINER.addEventListener("drop",
            (e: DragEvent) => this._onDrop(e));

        window.addEventListener('online',
            () => setTimeout(() => this._onNetworkChanged(false), 3000));

        window.addEventListener('offline',
            () => setTimeout(() => this._onNetworkChanged(true), 3000));

        Op3dContext.CONTAINER.addEventListener('mousedown',
            (e: MouseEvent) => this._onMouseDown(e), false);
        Op3dContext.CONTAINER.addEventListener('dblclick',
            (e: MouseEvent) => this._onDblMouseDown(e), false);

        Op3dContext.CONTAINER.addEventListener('wheel',
            (event: WheelEvent) => this._onMouseWheel(event), {
            passive: true
        });

        window.onfocus = (e) => this.onFocus(e);
        window.onblur = (e) => this.onBlur(e);

        Op3dContext.CONTAINER.addEventListener('mousemove', (e: MouseEvent) => this._onMouseMove(e));
        document.addEventListener('mouseup', (e: MouseEvent) => this._onMouseUp(e));

        if (ServerContext.env === ENVS.PROD) {

            window.onerror = function (errorMsg, _url, _lineNumber) {

                if (errorMsg != null &&
                    (errorMsg instanceof String)) {
                    MessagesHandler.ON_ERROR_PROGRAM({
                        error: errorMsg,
                        type: eErrorType.WINDOW_ERROR,
                        show_message: false
                    });
                }
            };
        }

        document.addEventListener(EventTypes.KEY_DOWN,
            (e) => this._onKeyAction(e as KeyboardEvent, eKeyEvent.DOWN));
        document.addEventListener(EventTypes.KEY_UP,
            (e) => this._onKeyAction(e as KeyboardEvent, eKeyEvent.UP));
        document.addEventListener(EventTypes.KEY_PRESS,
            (e) => this._onKeyAction(e as KeyboardEvent, eKeyEvent.PRESS));
    }
    //__________________________________________________________________________________________
    private onBlur(_e: any) {
        AnalyticsEventsContext.triggerFinishSession();
    }
    private onFocus(_e: any) {
        AnalyticsEventsContext.triggerConnectSystem("connect");
    }
    private _onMouseWheel(_event: WheelEvent) {
        // const aCamera = SceneContext.CAMERA;
        // const aCameraPos = aCamera.position.clone();
        // let aMouse = this.mClickManager.getZoomPoint(event).multiplyScalar(-1);
        // aMouse.y = aCameraPos.y;
        // let aZoomFactor = event.deltaY < 0 ? InputDispatcher.ZOOM_IN_FACTOR : -InputDispatcher.ZOOM_IN_FACTOR;
        // let aNewCameraPos = aMouse.multiplyScalar(1 + aZoomFactor);
        // aNewCameraPos.y = 0;
        // aCamera.position.sub(aNewCameraPos);
        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    private _onKeyAction(e: KeyboardEvent, pKeyEvent: eKeyEvent) {
        let aKey = this._getKey(e);
        if (this.mShortCuts[pKeyEvent] != null && this.mShortCuts[pKeyEvent][aKey] != null) {
            if (this.mShortCuts[pKeyEvent][aKey].preventDefault != false) {
                e.preventDefault();
            }

            if (this.mShortCuts[pKeyEvent][aKey].function != null) {
                this.mShortCuts[pKeyEvent][aKey].function(e);
            }
        }
    }
    //__________________________________________________________________________________________
    private _onDragOver(e: any) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        e.dataTransfer.dropEffect =
            (eClickMode.BASE == Op3dContext.PARTS_EVENTS_HANDLER.mode) ? 'copy' : 'none';

    }
    //__________________________________________________________________________________________
    private async _onDrop(e: DragEvent) {
        try {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            if (eClickMode.BASE != Op3dContext.PARTS_EVENTS_HANDLER.mode) {
                return;
            }

            let aFile = e.dataTransfer.files[0];
            if (aFile == null) {
                return;
            }

            if (!Menu.instance.isValidFile(aFile)) {
                MessagesHandler.UNREDOGNIZED_FILE_TYPE();
                return;
            }

            Popup.instance.open({
                text: MessagesHandler.OPEN_NEW_SETUP_CONFIRMATION_MSG,
                noBtn: {
                    callback: () => { }, title: "Cancel"
                },
                yesBtn: {
                    callback: () => this._onConfirmOpenFile(aFile),
                    title: "Confirm"
                }
            });

        } catch (e) {
            MessagesHandler.ERROR_READING_FILE_MSG();
        }
    }
    //__________________________________________________________________________________________
    private _onConfirmOpenFile(aFile: File) {
        Op3dContext.SETUPS_MANAGER.closeSetup();

        // server.ServerContext.SERVER.closeSetup({
        //     _id: Op3dContext.SETUPS_MANAGER.currSetupID
        // }, true);
        SetupsManager.openFile(aFile, false, false)
    }
    //__________________________________________________________________________________________
    private _onMouseMove(e: MouseEvent) {
        if (!this.mIsThrottled) {
            SceneContext.OP3D_SCENE.activateRenderer();
            // helps to lower the intesections count
            this.mClickManager.onHover(e);
            let aIsMouseDown = (e.buttons == eMouseEvent.PRIMARY_BUTTOM);
            if (true == aIsMouseDown) {
                Op3dContext.PARTS_MANAGER.onMouseMove(e);
                return;
            } else if (SceneContext.CHOOSE_MODE.mode === eClickMode.RAYS_POSITION) {
                Op3dContext.PARTS_EVENTS_HANDLER._setDashedLineVisibility(true, e);
            }
            this.mIsThrottled = true;
            setTimeout(() => {
                this.mIsThrottled = false;
            }, 100);
        }
    }
    //__________________________________________________________________________________________

    private _onNetworkChanged(pIsOfflineMsg: boolean) {
        if (pIsOfflineMsg && pIsOfflineMsg == !navigator.onLine) {
            MessagesHandler.NETWORK_OFF();
            this.mShowNetworkMessage = true;
            return;
        }

        if (navigator.onLine == !pIsOfflineMsg && this.mShowNetworkMessage) {
            MessagesHandler.NETWORK_ON();
        }
    }
    //__________________________________________________________________________________________
    public get mouseDownTime(): Date {
        return this.mMouseDownTime;
    }
    //__________________________________________________________________________________________
    public set mouseDownTime(pVal: Date) {
        this.mMouseDownTime = pVal;
    }
    //__________________________________________________________________________________________
}