import { read, utils } from "xlsx";
import { iAsyncCallback, iCoatingVO, iHash } from "../_context/_interfaces/Interfaces";
import { FileUtils } from "../_utils/FileUtils";
import { ServerContext } from "../server/ServerContext";
import { Popup } from "../ui/forms/Popup";
import { Spinner } from "../ui/home/Spinner";
import { ProgressBar } from "../ui/tools/ProggressBar";
import { eDataPermission } from "../_context/Enums";

export interface iCoatingRow {
    R_P: number;
    R_S: number;
    T_P: number;
    T_S: number;
    wl: number;
    number_id?: string;
}

export class AdminCoatingParser {

    private mLogger = new Array<iAsyncCallback<string>>();
    private mToReplace: boolean;
    private mFiles: Array<File>;

    constructor(pFileList: FileList, pToReplace: boolean) {
        this.mToReplace = pToReplace;
        this.mFiles = new Array<File>();


        for (let file of pFileList) {
            this.mFiles.push(file);
        }


        this._load();
    }
    //____________________________________________________________________
    private async _load() {

        Spinner.instance.hide();

        await ProgressBar.instance.open({
            title: 'Uploading...',
            min: 0,
            max: this.mFiles.length,
            closeOnFinish: true,
        });

        for (let i = 0; i < this.mFiles.length; i++) {
            await ProgressBar.instance.updateDetails(this.mFiles[i].name);

            let aRes = await this._loadFile(this.mFiles[i]);
            if (aRes.success == false) {
                this.mLogger.push({ success: false, data: aRes.data as string });
            } else {

                if (aRes.data == '-1') {
                    this.mLogger.push({ success: false, data: this.mFiles[i].name + " (didn't replaced)" });
                } else {
                    this.mLogger.push({ success: true, data: this.mFiles[i].name });
                }
            }

            await ProgressBar.instance.update(i + 1);
        }

        let aSuccess = 0;
        let aFailed = 0;
        for (let i = 0; i < this.mLogger.length; i++) {
            const element = this.mLogger[i];
            if (element.success) {
                aSuccess++;
                console.log("success " + element.data);
            } else {
                aFailed++;
                console.log("failed " + element.data);
            }
        }

        let aSummery = "Total Rows: " + this.mFiles.length + '\n';
        aSummery += "Total Succeeded: " + aSuccess + '\n';
        aSummery += "Total Failed: " + aFailed + '\n';

        console.log('----------------------------------------------------------------');
        console.log(aSummery);
        console.log('----------------------------------------------------------------');

        let aMsg = aSummery + '\n\n';
        aMsg += 'For more details, go to the browser console.';

        Popup.instance.open({ text: aMsg });
        ProgressBar.instance.close();
    };
    //____________________________________________________________________
    private _getLegalName(name: string): string {
        let aName = name.replace('.csv', '');
        return aName.split('[')[0];
    };
    //____________________________________________________________________
    private _getNumberId(name: string) {
        let aArr1 = name.split('[');
        if (aArr1.length === 2) {
            let aArr2 = aArr1[1].split(']')
            return aArr2[0];
        }
        return undefined;
    };
    //____________________________________________________________________
    private async _loadFile(pFile: File): Promise<iAsyncCallback<string>> {
        let aRes = await FileUtils.loadFileText(pFile);
        if (aRes.success == false) {
            return ({ data: "error loading file " + pFile.name, success: false })
        }

        try {
            let aData = aRes.data;
            var workbook = read(aData, {
                type: 'binary', sheetStubs: true
            });

            let aSheetName = workbook.SheetNames[0];
            let aRows = utils.sheet_to_json<iHash<string>>(workbook.Sheets[aSheetName],
                {
                    blankrows: true,
                });

            let aCoatingResult = await this._parseData(pFile.name, aRows);
            if (aCoatingResult.success) {

                let aServerRes = await ServerContext.SERVER.addCoating({
                    data: aCoatingResult.data as iCoatingVO,
                    toReplace: this.mToReplace,
                    permission: eDataPermission.PUBLIC
                });
                return ({ success: aServerRes.success, data: (aServerRes.data as any).message });
            }

            return ({ data: aCoatingResult.data as string, success: false })

        } catch (e) {
            return ({ data: "error reading file " + pFile.name, success: false })
        }
    }
    //____________________________________________________________________
    private _getFloat(pData: string): number | undefined {
        if (isNaN(parseFloat(pData)) == false) {
            return parseFloat(pData);
        }
    }
    //____________________________________________________________________
    private async _parseRow(pRow: iHash<string>): Promise<iAsyncCallback<string | iCoatingRow>> {
        const aParserParams: iHash<{
            path: string,
            getValue: (pData: string) => number | undefined,
            isRequired?: boolean
        }> = {
            'wl': {
                path: 'wl',
                getValue: (pData: string) => this._getFloat(pData),
                isRequired: true,
            },

            't_p': {
                path: 'T_P',
                getValue: (pData: string) => this._getFloat(pData),
            },

            'r_p': {
                path: 'R_P',
                getValue: (pData: string) => this._getFloat(pData),
            },

            't_s': {
                path: 'T_S',
                getValue: (pData: string) => this._getFloat(pData),
            },

            'r_s': {
                path: 'R_S',
                getValue: (pData: string) => this._getFloat(pData),
            }
        }

        var aCoatingRow = <iCoatingRow>{};
        for (let key in pRow) {
            let aKey = key.toLocaleLowerCase().trim();
            if (aParserParams[aKey]) {
                let aParam = aParserParams[aKey];
                let aValue = aParam.getValue(pRow[key]);
                if (aValue !== undefined) {
                    aCoatingRow[aParam.path] = aValue;
                }
            }
        }

        for (let key in aParserParams) {
            let aParam = aParserParams[key];
            if (aParam.isRequired === true) {

                if (Object.keys(aCoatingRow).length > 0 && aCoatingRow[aParam.path] === undefined) {
                    return ({ success: false, data: "required field in missing" });
                }
            }
        }
        return ({ success: true, data: aCoatingRow });
    }
    //____________________________________________________________________
    private async _parseData(pFileName: string, pParsedFile: Array<iHash<string>>): Promise<iAsyncCallback<iCoatingVO | string>> {
        let aStart = 0;
        let aEnd = pParsedFile.length;

        let aCoating = <iCoatingVO>{};
        aCoating.wl = [];
        aCoating.T_P = [];
        aCoating.R_P = [];
        aCoating.T_S = [];
        aCoating.R_S = [];
        aCoating.number_id = this._getNumberId(pFileName);
        aCoating.name = this._getLegalName(pFileName);

        // let factor = 1;
        let aPrev: iCoatingRow | undefined;
        for (let row = aStart; row < aEnd; row++) {
            let aRow = pParsedFile[row];
            if (Object.keys(aRow).length == 0) {
                continue;
            }

            let aCurr = await this._parseRow(aRow);
            if (aCurr.success == false) {
                return ({ data: "error in rows in file [" + pFileName + " ]", success: false });
            }

            let aRowItem = aCurr.data as iCoatingRow;
            if (aPrev != undefined) {
                // check if current row is the same as previos, 
                // we don't save two identical rows
                if ((row !== (aEnd - 1)) &&
                    (aPrev.T_P === aRowItem.T_P) &&
                    (aPrev.R_P === aRowItem.R_P) &&
                    (aPrev.T_S === aRowItem.T_S) &&
                    (aPrev.R_S === aRowItem.R_S)) {
                    continue;
                }
            }

            aCoating.wl.push(aRowItem.wl);
            aCoating.T_P.push(aRowItem.T_P);
            aCoating.R_P.push(aRowItem.R_P);
            aCoating.T_S.push(aRowItem.T_S);
            aCoating.R_S.push(aRowItem.R_S);
            aPrev = aCurr.data as iCoatingRow;
        }

        let aFirst = aCoating.wl[0];
        let aLast = aCoating.wl[aCoating.wl.length - 1];
        if (aFirst > aLast) {
            return ({ success: false, data: "wl are in descending order" });
        }

        return ({ success: true, data: aCoating });
    }
    //____________________________________________________________________
}
