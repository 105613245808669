
//______________________________________________________________________________________________
export enum eComponentType {
    COLLAPSE_TEMPLATE = 'collapse_template',
    ROW = 'row_template',
    EMPTY_ROW = 'empty_row_template',
    INPUT_GROUP_APPEND = 'input_group_append_template',
    INPUT = 'input_template',
    CHECKBOX = 'checkbox_template',
    SELECT = 'select_template',
}
//______________________________________________________________________________________________
export enum eMouseEvent {
    NO_BUTTON,
    PRIMARY_BUTTOM,
    SECONDARY_BUTTON
}
    //______________________________________________________________________________________________