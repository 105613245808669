import { Box3, Vector3, Group as THREEGroup } from "three";
import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../../parts/Part";
import { OptixPartDisplayer } from "../../parts/_parts_assets/OptixPartDisplayer";
import { SceneContext } from "../../scene/SceneContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { eClickMode } from "../_globals/PartsEventsHandler";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { eNotificationToastDuration, eNotificationType } from "../home/_notifications/NotificationsContext";
import { ChoosePartMode } from "./ChoosePartMode";
import { NotificationNote } from "../home/_notifications/NotificationNote";
import { eGroupType, ePartType } from "../../parts/PartInterfaces";

export class Group extends Op3dComponentBase {

    public static INSTANCE: Group;
    private mGroupList: HTMLElement;
    private mGroupTitle: HTMLInputElement;
    /**
    * @description list of black box parts on scene
    */
    private mPartsList: Set<Part> = new Set();
    private mBoundingBox: Box3
    private mCurrentGroup: Part
    private mRemovedParts: Array<Part> = [];
    private mCounter: number = 1
    public isOpen: boolean
    private mLinkedPartsIDs: string[];
    private mNotification: NotificationNote;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/group.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == Group.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.style.width = "240px";
            aDiv.style.height = "auto";
            aDiv.style.top = "105px";
            aDiv.style.left = "272px";
            aDiv.style.margin = "0";
            aDiv.style.boxShadow = '0px 3px 10px rgba(0, 0, 0, 0.501961)'
            aDiv.style.borderRadius = '4px'
            document.getElementById('forms').appendChild(aDiv);

            Group.INSTANCE = new Group(aDiv);
        }
        return Group.INSTANCE;
    }
    //__________________________________________________________________________________________
    private async showInfo(pIsToShow: boolean) {
        if (pIsToShow == true) {
            if (this.mNotification != null) {
                this.mNotification.update()
            } else {
                let aMsg = '<small style="display: block;">'
                aMsg += 'Click on parts of the setup to add them to the group.';
                aMsg += '</small>'

                this.mNotification = await NotificationCenter.instance.pushNotification({
                    message: aMsg,
                    toastDuration: eNotificationToastDuration.INFINITE,
                    params: {
                        isColorBoxVisible: true,
                        type: eNotificationType.CONSTRUCTION_COMMENT,
                        title: 'GROUP TOOL - INFO',
                        color: '#23A7DE',
                    }
                });
            }

            this._getPart('info_btn').classList.add('active')

        } else {
            if (this.mNotification == null) {
                return
            }
            NotificationCenter.instance.removeNotification(this.mNotification);
            this.mNotification = null

            this._getPart('info_btn').classList.remove('active')
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen() {

        Op3dContext.PARTS_MANAGER.unHiglightAllParts()
        let aEditMode = this.mCurrentGroup != null
        if (aEditMode === true) this.mCurrentGroup.highlightObject(true)
        this.mGroupTitle.value = (aEditMode === true && this.mCurrentGroup.getLabel().label) || `Group ${this.mCounter} `
        this.isOpen = true

        let aToShowEmptyList = this.mPartsList.size == 0
        this.setEmptyList(aToShowEmptyList)
    }
    //__________________________________________________________________________________________
    private setEmptyList(pToShow: boolean) {
        if (pToShow == true) {
            this._getPart('empty').classList.remove('d-none')
        } else {
            this._getPart('empty').classList.add('d-none')
        }
    }
    //__________________________________________________________________________________________
    /**
    * @description edit already existing group
    */
    public editGroup(pGroup: Part) {
        pGroup.linkedParts.forEach(element => {
            this.mPartsList.add(element)
            this.createListElement(element)
        });
        this.mCurrentGroup = pGroup
        this.mLinkedPartsIDs = this.mCurrentGroup.linkedParts.map(part => part.internalID)
        this.createBoundingBoxFromParts(this.returnAllSubparts(pGroup))

        this.setGroupBtnState()
    }
    //__________________________________________________________________________________________
    /**
    * @description add/remove part to/from part group list
    */
    public setPartToGroup(pPart: Part) {
        if (pPart === this.mCurrentGroup) return
        if (this.mRemovedParts.indexOf(pPart) !== -1) {
            this.mRemovedParts[this.mRemovedParts.indexOf(pPart)].clearRef()
        }

        let aPart = (ePartType.GROUP === pPart.refCS.refPart.partOptions.type) ?
            this.findMainGroupPart(pPart, this.mCurrentGroup) : pPart

        if (aPart === this.mCurrentGroup) {
            aPart = pPart
        }
        this.mPartsList.forEach(part => OptixPartDisplayer.highlightGroup(part))
        OptixPartDisplayer.highlightGroup(pPart)

        if (this.mPartsList.has(aPart) || aPart === this.mCurrentGroup || this.mPartsList.has(pPart)) {
            this.removePartFromList(aPart)
            return
        }

        if (this.mCurrentGroup != null) {
            let aMainPart = this.findMainGroupPart(aPart)
            let aCurrent = this.findMainGroupPart(this.mCurrentGroup)
            if (aMainPart === aCurrent) return
        }

        this.mRemovedParts.splice(this.mRemovedParts.indexOf(pPart), 1)

        let aAllLinedParts = []

        this.mPartsList.add(aPart)

        this.mPartsList.forEach(groupPart => {
            aAllLinedParts.push(...this.returnAllSubparts(groupPart))

        })

        this.createBoundingBoxFromParts(aAllLinedParts)
        this.createListElement(aPart)

        this.setGroupBtnState()

        if (this.mPartsList.size > 0) {
            this.setEmptyList(false)
        }
    }
    //__________________________________________________________________________________________
    private setGroupBtnState() {
        let aBtn = this._getPart('save_btn')
        if (this.mPartsList.size > 1) {
            aBtn.removeAttribute('disabled')
        } else {
            aBtn.setAttribute('disabled', 'true')
        }
    }
    //__________________________________________________________________________________________

    private createBoundingBoxFromParts(pParts: Array<Part>) {
        let aGroup = new THREEGroup()
        pParts.forEach(part => aGroup.add(part.visibleObj.clone()))
        this.mBoundingBox = new Box3()
        this.mBoundingBox.setFromObject(aGroup)
    }
    //__________________________________________________________________________________________
    /**
    * @description remove part from html block list
    */
    private removePartFromList(pPart: Part) {
        pPart.clearRef()

        let aCurrentButtonList = this.mGroupList.querySelectorAll('div')
        let aCurrentBtn
        for (let i = 0; i < aCurrentButtonList.length; i++) {
            if (aCurrentButtonList[i].dataset.number_id == pPart.internalID) {
                aCurrentBtn = aCurrentButtonList[i].parentElement as HTMLLIElement
            }
        }
        aCurrentBtn.parentNode.removeChild(aCurrentBtn)

        this.mPartsList.delete(pPart)
        this.mRemovedParts.push(pPart)
        pPart.unHighlightObject()

        let aAllLinedParts = []

        this.mPartsList.forEach(groupPart => {
            aAllLinedParts.push(...this.returnAllSubparts(groupPart))

            OptixPartDisplayer.highlightGroup(groupPart)
        })

        this.createBoundingBoxFromParts(aAllLinedParts)

        this.setGroupBtnState()

        if (this.mPartsList.size == 0) {
            this.setEmptyList(true)
        }

    }
    //__________________________________________________________________________________________
    /**
    * @description add html block with part's data
    */
    private createListElement(pPart: Part) {
        let aIconType, aItemName;
        let aItemSlicedName = (pPart.getLabel().label || pPart.iPart.name).length > 13 ?
            (pPart.getLabel().label || pPart.iPart.name).slice(0, 13) + '...' :
            (pPart.getLabel().label || pPart.iPart.name);
        if (ePartType.GROUP === pPart.partOptions.type) {
            aIconType = './images/tree/group_icon.svg'
            aItemName = `<strong>${aItemSlicedName}</strong>`
        } else if (Op3dContext.DATA_MANAGER.getPartVOById(pPart.id)?.isAssembly == true) {
            aIconType = './images/tree/parts.svg'
            aItemName = `<strong>${aItemSlicedName}</strong>`
        } else {
            aIconType = './images/tree/cube.svg'
            aItemName = aItemSlicedName
        }

        let aPartHTML = document.createElement('div')
        aPartHTML.dataset.number_id = pPart.internalID

        let aPartHTMLBlock = document.createElement('li')
        aPartHTMLBlock.setAttribute('data-tooltip', (pPart.getLabel().label || pPart.iPart.name))

        aPartHTMLBlock.classList.add('d-flex', 'justify-content-between')
        let aRemoveBtn = document.createElement('div')
        aRemoveBtn.addEventListener('click', () => this.removePartFromList(pPart))
        let aRemoveIcon = document.createElement('i')
        aRemoveIcon.className = 'icon-close'
        aRemoveBtn.appendChild(aRemoveIcon)
        let aIcon = document.createElement('img')
        aIcon.src = aIconType
        aPartHTMLBlock.appendChild(aIcon)
        aPartHTMLBlock.appendChild(aPartHTML)
        aPartHTMLBlock.appendChild(aRemoveBtn)

        aPartHTML.innerHTML = aItemName
        this.mGroupList.appendChild(aPartHTMLBlock)
    }
    //__________________________________________________________________________________________
    /**
    * @description set all parts in group to higher level or to higher group
    */
    public async ungroup(pGroup: Part) {
        Op3dContext.SCENE_HISTORY.addToHistory()

        pGroup.linkedParts.forEach(part => part.clearRef());

        if (pGroup.refCS.refPart.linkedParts.length == 2) {
            this.ungroup(pGroup.refCS.refPart)

        } else {

            pGroup.visibleObj.visible = true
            pGroup.linkedParts.forEach(part => {
                part.clearRef()
                part.clickEvents(true)
                part.visibleObj.visible = true
            })
            await Op3dContext.PARTS_MANAGER.deletePart(pGroup)
        }

        Op3dContext.SCENE_HISTORY.saveScene()
    }
    //__________________________________________________________________________________________
    /**
    * @description create part and set all parts reference to this part
    */
    private groupParts() {
        let aParts = this.mPartsList
        Op3dContext.SCENE_HISTORY.addToHistory()

        for (let part of this.mRemovedParts) {
            if (aParts.has(part)) continue
            part.clearRef()
        }

        if (aParts.size <= 1) return

        let aGroupName = this.mGroupTitle.value
        let aGroupRefPart: Part

        if (this.mCurrentGroup != null) {
            aGroupRefPart = this.mCurrentGroup
            aGroupRefPart.setPartLabel(aGroupName)
        } else {
            aGroupRefPart = new Part({
                id: aGroupName,
                options: {
                    type: ePartType.GROUP
                }
            });
            aGroupRefPart.groupData = { group_type: eGroupType.REGULAR, is_array: false }
        }

        if (aGroupRefPart.linkedParts != null) {
            for (let part of [...aGroupRefPart.linkedParts]) {
                aGroupRefPart.removeFromLinkedParts(part)
            }
        }

        let aCenter = this.mBoundingBox.getCenter(new Vector3)

        aGroupRefPart.visibleObj.position.copy(aCenter.clone())

        for (let part of aParts) {
            if (ePartType.GROUP !== part.partOptions.type) {
                part.clearRef()
                if (part.linkedParts != null) {
                    let aLinkedParts = [...part.linkedParts]
                    for (let i = 0; i < aLinkedParts.length; i++) {
                        part.removeFromLinkedParts(aLinkedParts[i])
                        aLinkedParts[i].clearRef()
                    }

                }
            }


            part.setRefrence({ cs: aGroupRefPart.iPart.axes[0], refPart: aGroupRefPart })
        }

        Op3dContext.PARTS_MANAGER.updatePartsList(true);
        ChoosePartMode.instance.leaveChoosePartMode()

        this.mRemovedParts.forEach(part => part.unHighlightObject())
        this.mPartsList.clear()
        this.mRemovedParts = []
        this.mLinkedPartsIDs = []
        this.mGroupList.innerHTML = ''
        SceneContext.CHOOSE_MODE.mode = eClickMode.BASE
        this.mCurrentGroup = null
        this.close()
        this.isOpen = false
        Op3dContext.SCENE_HISTORY.saveScene()
        this.mCounter++
        Op3dContext.PARTS_MANAGER.setSelectedPart(aGroupRefPart)
    }
    //__________________________________________________________________________________________
    /**
    * @description recursion getting all subparts from group
    */
    public returnAllSubparts(pPart) {
        if (ePartType.GROUP !== pPart?.partOptions.type) return [pPart]

        let newArr: Array<Part> = [];
        for (let i = 0; i < pPart.linkedParts.length; i++) {
            if (ePartType.GROUP === pPart.linkedParts[i].partOptions.type) {
                newArr = newArr.concat(this.returnAllSubparts(pPart.linkedParts[i]));
            } else {
                newArr.push(pPart.linkedParts[i]);
            }
        }
        return newArr;
    }
    //__________________________________________________________________________________________
    /**
    * @description return only group subparts
    */
    public returnAllSubpartGroups(pPart) {
        if (ePartType.GROUP !== pPart?.partOptions.type) return [pPart]

        let aGroupParts = [pPart]
        for (let i = 0; i < pPart.linkedParts.length; i++) {
            if (ePartType.GROUP === pPart.linkedParts[i].partOptions.type) {
                aGroupParts = aGroupParts.concat(pPart.linkedParts[i])
                this.returnAllSubparts(pPart.linkedParts[i])
            }
        }
        return aGroupParts;
    }
    //__________________________________________________________________________________________
    /**
    * @description find main ref group part on higher level 
    */
    public findMainGroupPart(pPart: Part, pCurrentGroup?: Part) {
        if (pPart === null) return null
        let aPart: Part = pPart
        if ((pPart.refCS != null) && (ePartType.GROUP === pPart.refCS.refPart.partOptions.type) &&
            (pPart.refCS.refPart != pCurrentGroup)) {
            aPart = this.findMainGroupPart(pPart.refCS.refPart, pCurrentGroup)
        }
        return aPart
    }

    //__________________________________________________________________________________________
    public findSelectedPart(pPart: Part) {
        if (pPart === null) return null;
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if ((aSelectedPart == null) || (ePartType.GROUP !== aSelectedPart.partOptions.type)) {
            return pPart;
        }
        return aSelectedPart;
    }

    //__________________________________________________________________________________________
    public closeForWakeupScreen() {
        this.mCurrentGroup = null
        this.mRemovedParts = []
        this.mLinkedPartsIDs = []
        this.close()
        this.isOpen = false
        this.mGroupList.innerHTML = ''
        this.mPartsList.clear()
    }
    //__________________________________________________________________________________________
    public closeTool() {
        ChoosePartMode.instance.leaveChoosePartMode()
        this.mPartsList.forEach(part => part.unHighlightObject())
        this.mPartsList.clear()
        this.mGroupList.innerHTML = ''

        if (this.mCurrentGroup != null) {
            for (let id of this.mLinkedPartsIDs) {
                let aPart = Op3dContext.PARTS_MANAGER.parts.find(part => part.internalID == id)
                if (this.mCurrentGroup.linkedParts != null && this.mCurrentGroup.linkedParts.indexOf(aPart) != -1) {
                    continue
                }
                aPart.setRefrence({ cs: this.mCurrentGroup.iPart.axes[0], refPart: this.mCurrentGroup })
            }
        }

        this.showInfo(false)
        this.mCurrentGroup = null
        this.mRemovedParts = []
        this.mLinkedPartsIDs = []
        this.close()
        this.isOpen = false
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mGroupList = this._getPart('parts_list')
        this.mGroupTitle = this._getPart('group_title') as HTMLInputElement
        this._getPart('save_btn').addEventListener('click', () => this.groupParts())
        this._getPart('close_btn').addEventListener('click', () => this.closeTool())
        this._getPart('cancel_btn').addEventListener('click', () => this.closeTool())

        this._getPart('info_btn').addEventListener('click', () => {
            this.showInfo(this.mNotification == null)
        })
    }
    //__________________________________________________________________________________________

    protected _onCreationComplete(): void {
        this.mIsReady = true;
        (<any>$(this.mContainer)).draggable()
    }
    //__________________________________________________________________________________________
}
