import { Vector3 } from "three/src/math/Vector3.js";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { iOpticsVOGeometry, iOpticsVOPhysicalData, iOpticsVO, iOpticsVOParameters, ePolarizerWaveplateType } from "../data/VO/OpticsVOInterfaces";
import { eDataPermission, eUnitType } from "./Enums";
import { eOpticsTypeNames, eBaseShape, OpticsContext, eOpticShape } from "./OpticsContext";
import { Strings } from "./Strings";
import { eCoatingTypes } from "../ui/forms/optics/esCoating";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { Op3dContext } from "./Op3dContext";

export class OpticsDefaults {
    private static INCH_TO_MM_SCALE = 25.4;
    static DEFAULTS = {
        materials: {
            uvfs: "uvfs_mal-_1965",
            n_sf11: "n-sf11_schott",
            fused_silica: "fused_sil_mal-1965",
            n_bk7: "n-bk7_schott",
        },
        general: {
            wavelength: 550
        },
        geo: {
            one_inch: 1 * OpticsDefaults.INCH_TO_MM_SCALE,
            width: [1 * OpticsDefaults.INCH_TO_MM_SCALE, 25],
            height: [1 * OpticsDefaults.INCH_TO_MM_SCALE, 25],
            diameter: [1 * OpticsDefaults.INCH_TO_MM_SCALE, 25],
            thickness: [0.24 * OpticsDefaults.INCH_TO_MM_SCALE, 6],
            thickness_center_mirror: [0.24 * OpticsDefaults.INCH_TO_MM_SCALE, 6],
            thickness_center_lens: [0.12 * OpticsDefaults.INCH_TO_MM_SCALE, 3],
            thickness_edge: [0.24 * OpticsDefaults.INCH_TO_MM_SCALE, 6],
            thickness_filter: [0.24 * OpticsDefaults.INCH_TO_MM_SCALE, 6],
            aperture_thickness: 0.01,
        }
    };


    //______________________________________________________________________________________
    public static getDefaultOpticsVO(pType: eOpticsTypeNames, pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {


        switch (pType) {

            case eOpticsTypeNames.GRATING:
                return this._getDefaultGrating(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.APERTURE:
                return this._getDefaultAperture(pSubtype, pUnit);
            case eOpticsTypeNames.LENS:
                return this._getDefaultLens(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.BEAM_SPLITTER:
                return this._getDefaultBeamSplitter(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.FILTER:
                return this._getDefaultFilter(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.WINDOW:
                return this._getDefaultWindow(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.MIRROR:
                return this._getDefaultMirror(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.PRISM:
                return this._getDefaultPrism(pSubtype, pBaseShape, pUnit);
            case eOpticsTypeNames.POLARIZING_ELEMENT:
                return this._getDefaultPolarizer(pSubtype, pBaseShape, pUnit);



            default:
                throw Error("unahndeled");
        }
    }
    //______________________________________________________________________________________
    private static _getDefaultPrism(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType): iOpticsVO {
        let aGeo: iOpticsVOGeometry = {};


        let aOpticsVO: iOpticsVO = {
            name: "User defined object",
            creator: Op3dContext.USER_VO.id,
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                physical_data: {},
                info: { brand: "" },
                type: eOpticsTypeNames.PRISM,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: "",
                coating: {},
                geometry: aGeo,
                shape: null
            }
        }
        switch (pSubtype) {
            case OpticsContext._Knife_Edge_Prism:
                /**
                 * @TODO:
                 */
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.RIGHT_ANGLE_PRISM;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.n_bk7;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.c = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                break;

            case OpticsContext._Right_Angle_Prism:
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.RIGHT_ANGLE_PRISM;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.c = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                break;

            case OpticsContext._Equilateral_Prism:
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.EQUAL_PRISM;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.n_sf11;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.t = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                break;

            case OpticsContext._Dove_Prism:
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.DOVE_PRISM;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.n_bk7;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 103.18 : 103.18 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE
                aGeo.c = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.d = pUnit === eUnitType.MILLIMETERS ? 35.36 : 35.36 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Wedge_Prism:

                aOpticsVO.parameters.shape = eOpticShape.WEDGE;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.n_bk7;

                pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.RECTANGULAR;

                if (pBaseShape === eBaseShape.RECTANGULAR) {

                    aGeo.t = pUnit === eUnitType.MILLIMETERS ? 10 : 10 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                    aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
                    aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

                } else if (pBaseShape === eBaseShape.CIRCULAR) {
                    aGeo.thickness_center = pUnit === eUnitType.MILLIMETERS ? 3.421940776489603 : 3.421940776489603 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                    aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
                }
                aGeo.wedge_angle = 0.03374245042880637;
                break;

            case OpticsContext._Pellin_Broca_Prism:
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.PELLIN_BROCA_PRISM;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.uvfs;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 38 : 38 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 66 : 66 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.h = pUnit === eUnitType.MILLIMETERS ? 22 : 22 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.alpha = 1.3689140922167127;
                aGeo.beta = 1.5707963267948966;
                break;

            case OpticsContext._Penta_Prism:
                aOpticsVO.parameters.shape = eOpticShape.PENTA_PRISM;
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.n_bk7;
                aGeo.a = 20
                aGeo.b = 20
                aGeo.c = 20
                aGeo.d = 20
                aGeo.e = 20
                aGeo.alpha = 67.5 * OP3DMathUtils.DEG_TO_RAD;
                break;

            case OpticsContext._Axicon_Prism:
                /**
                 * @TODO: 
                 * not supported for now 
                 * 
                 */
                return null;

            case OpticsContext._Rhombic_Prism:
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.RHOMBIC_PRISM;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.n_bk7;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                aGeo.c = pUnit === eUnitType.MILLIMETERS ? 17.7 : 17.7 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.d = pUnit === eUnitType.MILLIMETERS ? 35.4 : 35.4 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

        }

        return aOpticsVO;
    }
    //______________________________________________________________________________________
    private static _getDefaultMirror(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {

        pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.CIRCULAR;

        let aGeo: iOpticsVOGeometry = {};
        if (pBaseShape === eBaseShape.RECTANGULAR) {
            aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
            aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

        } else if (pBaseShape === eBaseShape.CIRCULAR) {
            aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
        }

        let aOpticsVO: iOpticsVO = {
            creator: Op3dContext.USER_VO.id,
            name: "User defined object",
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                physical_data: {},
                info: { brand: "" },
                type: eOpticsTypeNames.MIRROR,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: "",
                coating: {},
                geometry: aGeo,
                shape: null
            }
        }

        switch (pSubtype) {

            case OpticsContext._Dielectric_Mirror:
            case OpticsContext._Metallic_Mirror:
                aOpticsVO.parameters.shape = eOpticShape.THIN_LENS;
                aGeo.thickness = OpticsDefaults.DEFAULTS.geo.thickness[pUnit];
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }
                break;

            case OpticsContext._Dichroic_Mirror:
                aOpticsVO.parameters.shape = eOpticShape.THIN_LENS;
                aGeo.thickness = OpticsDefaults.DEFAULTS.geo.thickness[pUnit];
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                break;

            case OpticsContext._Curved_Mirror_Concave:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_mirror[pUnit];
                aGeo.r1 = pUnit === eUnitType.INCHES ? -4 * OpticsDefaults.INCH_TO_MM_SCALE : -100;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }
                break;

            case OpticsContext._Curved_Mirror_Convex:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_mirror[pUnit];
                aGeo.r1 = pUnit === eUnitType.INCHES ? 4 * OpticsDefaults.INCH_TO_MM_SCALE : 100;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }
                break;

            case OpticsContext._Cylindrical_Mirror_Plano_Convex:
                aOpticsVO.parameters.shape = eOpticShape.CYLINDRICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.r1 = pUnit === eUnitType.INCHES ? 4 * OpticsDefaults.INCH_TO_MM_SCALE : 100;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_mirror[pUnit];
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }
                break;

            case OpticsContext._Cylindrical_Mirror_Plano_Concave:
                aOpticsVO.parameters.shape = eOpticShape.CYLINDRICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }

                aGeo.r1 = pUnit === eUnitType.INCHES ? -4 * OpticsDefaults.INCH_TO_MM_SCALE : -100;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_mirror[pUnit];
                break;

            case OpticsContext._Parabolic_Mirror_Concave:
                aOpticsVO.parameters.shape = eOpticShape.PARABOLIC;
                aGeo.radius_of_curvature = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }
                aGeo.thickness_edge = OpticsDefaults.DEFAULTS.geo.thickness_edge[pUnit];
                break;

            case OpticsContext._Off_Axis_Parabolic_Mirror:

                /**
                 * @TODO:
                 */

                aOpticsVO.parameters.shape = eOpticShape.OFF_AXIS_PARABOLIC;
                aGeo.off_axis_angle = 45;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR
                }

                aGeo.thickness_edge = 30;
                aGeo.radius_of_curvature = 200;
                aGeo.a = 12.7;
                break;

            case OpticsContext._D_Shaped_Mirror:
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness = 4;
                aOpticsVO.parameters.shape = eOpticShape.D_SHAPED;
                break;

            case OpticsContext._Knife_Edge_Prism_Mirror:
                /**
                 * @TODO:
                 */
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.shape = eOpticShape.RIGHT_ANGLE_PRISM;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : 1 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 25 : 1 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.c = pUnit === eUnitType.MILLIMETERS ? 25 : 1 * OpticsDefaults.INCH_TO_MM_SCALE;
                aOpticsVO.parameters.coating = {
                    "front": eCoatingTypes.IDEAL_REFLECTOR,
                    "right": eCoatingTypes.IDEAL_REFLECTOR
                }
                break;

            case OpticsContext._Roof_Prism_Mirror:
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.shape = eOpticShape.ROOF_PRISM_MIRROR;
                aOpticsVO.parameters.coating = {
                    "front_right": eCoatingTypes.IDEAL_REFLECTOR,
                    "front_left": eCoatingTypes.IDEAL_REFLECTOR
                }
                aGeo.thickness = OpticsDefaults.DEFAULTS.geo.thickness[pUnit];
                break;

            case OpticsContext._Right_Angle_Prism_Mirror:
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.shape = eOpticShape.RIGHT_ANGLE_PRISM;
                aGeo.a = pUnit === eUnitType.MILLIMETERS ? 25 : 1 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.b = pUnit === eUnitType.MILLIMETERS ? 25 : 1 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.c = pUnit === eUnitType.MILLIMETERS ? 25 : 1 * OpticsDefaults.INCH_TO_MM_SCALE;
                aOpticsVO.parameters.coating = {
                    "hypotenuse": eCoatingTypes.IDEAL_REFLECTOR
                }
                break;
        }

        return aOpticsVO;
    } 
    //______________________________________________________________________________________
    private static _getDefaultPolarizer(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {

        pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.CIRCULAR;

        let aGeo: iOpticsVOGeometry = {};
        if (pBaseShape === eBaseShape.RECTANGULAR) {
            aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
            aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

        } else if (pBaseShape === eBaseShape.CIRCULAR) {
            aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
        }

        let aOpticsVO: iOpticsVO = {
            creator: Op3dContext.USER_VO.id,
            name: "User defined object",
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                physical_data: {},
                polarizer_data: {
                },
                info: { brand: "" },
                type: eOpticsTypeNames.POLARIZING_ELEMENT,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: "",
                coating: {},
                geometry: aGeo,
                shape: null
            }
        }

        switch (pSubtype) {

            case OpticsContext._Ideal_Polarizer:
                aOpticsVO.parameters.shape = eOpticShape.THIN_LENS;
                aGeo.thickness = 3;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.polarizer_data.extinctionRatio = 20;
                aOpticsVO.parameters.polarizer_data.polarizationRatio = 100;
                aOpticsVO.parameters.polarizer_data.polarizerDirectionAngleDeg = 0;
                break;

            case OpticsContext._Ideal_Waveplate:
                aOpticsVO.parameters.shape = eOpticShape.THIN_LENS;
                aGeo.thickness = 3;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.polarizer_data.ordinaryRefractiveIndex = 1.5;
                aOpticsVO.parameters.polarizer_data.extraRefractiveIndex = 1.53;
                aOpticsVO.parameters.polarizer_data.designWavelength = 550;
                aOpticsVO.parameters.polarizer_data.extraAxisDirection = 0;
                aOpticsVO.parameters.polarizer_data.getDataFromMaterialSettings = false;
                aOpticsVO.parameters.polarizer_data.waveplateSubtype = ePolarizerWaveplateType.QWP;

                break;
        }

        return aOpticsVO;
    }
    //______________________________________________________________________________________
    private static _getDefaultWindow(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {
        let aGeo: iOpticsVOGeometry = {};
        pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.RECTANGULAR;

        let aOpticsVO: iOpticsVO = {
            creator: Op3dContext.USER_VO.id,
            name: "User defined object",
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                physical_data: {},
                info: { brand: "" },
                type: eOpticsTypeNames.WINDOW,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: OpticsDefaults.DEFAULTS.materials.fused_silica,
                coating: {},
                geometry: aGeo,
                shape: eOpticShape.THIN_LENS
            }
        }

        switch (pSubtype) {
            case OpticsContext._Flat_Window:
                aGeo.thickness = pUnit === eUnitType.MILLIMETERS ? 3 : 3 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break

            case OpticsContext._Wedge_Window:
                aOpticsVO.parameters.shape = eOpticShape.WEDGE;

                if (pBaseShape === eBaseShape.RECTANGULAR) {

                    aGeo.t = pUnit === eUnitType.MILLIMETERS ? 10 : 10 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                    aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
                    aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

                } else if (pBaseShape === eBaseShape.CIRCULAR) {
                    aGeo.thickness_center = pUnit === eUnitType.MILLIMETERS ? 10 : 10 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                    aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
                }

                aGeo.wedge_angle = 0.610865;

                break;
            case OpticsContext._Curved_Window_Plano_Convex:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aGeo.thickness_center = pUnit === eUnitType.MILLIMETERS ? 6 : 0.24 * OpticsDefaults.INCH_TO_MM_SCALE;
                pBaseShape = eBaseShape.CIRCULAR;
                aOpticsVO.parameters.baseShape = pBaseShape;
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 50 : 2 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = 0;
                break;
            case OpticsContext._Curved_Window_Plano_Concave:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aGeo.thickness_center = pUnit === eUnitType.MILLIMETERS ? 6 : 0.24 * OpticsDefaults.INCH_TO_MM_SCALE;
                pBaseShape = eBaseShape.CIRCULAR;
                aOpticsVO.parameters.baseShape = pBaseShape;
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? -50 : 2 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = 0;
                break;
        }

        if (pBaseShape === eBaseShape.RECTANGULAR) {
            aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
            aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

        } else if (pBaseShape === eBaseShape.CIRCULAR) {
            aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
        }


        return aOpticsVO;
    }
    //______________________________________________________________________________________
    private static _getDefaultGrating(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {
        let aGeo: iOpticsVOGeometry = {};
        let aPhysicalData: iOpticsVOPhysicalData = {};

        pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.RECTANGULAR;

        if (pBaseShape === eBaseShape.RECTANGULAR) {
            aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
            aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

        } else if (pBaseShape === eBaseShape.CIRCULAR) {
            aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
        }

        let aOpticsVO: iOpticsVO = {
            name: "User defined object",
            creator: Op3dContext.USER_VO.id,
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                physical_data: aPhysicalData,
                info: { brand: "" },
                type: eOpticsTypeNames.GRATING,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: OpticsDefaults.DEFAULTS.materials.fused_silica,
                coating: {},
                geometry: aGeo,
                shape: eOpticShape.GRATING,
            }
        }

        switch (pSubtype) {
            case OpticsContext._Blazed_Ruled_Reflective_Grating:

                aGeo.thickness = 3;
                aPhysicalData.order = 1;
                aPhysicalData.orientation_vector = new Vector3(1, 0, 0);
                aPhysicalData.blaze_wavelength = 550;
                aPhysicalData.blaze_angle = 5;
                aPhysicalData.grooves = 50;
                aPhysicalData.dispersion = 0.82;
                aPhysicalData.grating_side = "Front";
                break;

            case OpticsContext._Echelle_Grating:
                aGeo.thickness = 3;
                aPhysicalData.order = 1;
                aPhysicalData.orientation_vector = new Vector3(1, 0, 0);
                aPhysicalData.blaze_wavelength = 550;
                aPhysicalData.blaze_angle = 5;
                aPhysicalData.grooves = 50;
                aPhysicalData.dispersion = 14.37;
                aPhysicalData.grating_side = "Front";
                break;

            case OpticsContext._Transmission_Grating:
                aGeo.thickness = 3;
                aPhysicalData.blaze_wavelength = 550;
                aPhysicalData.grooves = 50;
                aPhysicalData.blaze_angle = 5;
                aPhysicalData.orientation_vector = new Vector3(1, 0, 0);
                aPhysicalData.order = 1;
                aPhysicalData.grating_side = "Back";
                break;

            case OpticsContext._Reflective_Grating:
                aGeo.thickness = 3;
                aPhysicalData.grooves = 50;
                aPhysicalData.dispersion = 0.6;
                aPhysicalData.orientation_vector = new Vector3(1, 0, 0);
                aPhysicalData.order = 1;
                aPhysicalData.grating_side = "Front";
                break;
        }

        return aOpticsVO;
    }
    //______________________________________________________________________________________
    private static _getDefaultFilter(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {

        pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.CIRCULAR;
        let aGeo: iOpticsVOGeometry = {};
        let aOpticsVO: iOpticsVO = {
            name: "User defined object",
            creator: Op3dContext.USER_VO.id,
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                info: { brand: "" },
                type: eOpticsTypeNames.FILTER,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: OpticsDefaults.DEFAULTS.materials.fused_silica,
                coating: {},
                geometry: aGeo,
                shape: eOpticShape.THIN_LENS
            }
        }

        if (pBaseShape === eBaseShape.RECTANGULAR) {
            aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
            aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

        } else if (pBaseShape === eBaseShape.CIRCULAR) {
            aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
        }

        switch (pSubtype) {
            case OpticsContext._Longpass_Filter = "Longpass Filter":
            case OpticsContext._Shortpass_Filter = "Shortpass Filter":
            case OpticsContext._Bandpass_Filter = "Bandpass Filter":
            case OpticsContext._Dichroic_Filter = "Dichroic Filter":
            case OpticsContext._Notch_Filter = "Notch Filter":
            case OpticsContext._Calibration_Filter = "Calibration Filter":
            case OpticsContext._Spectral_Shape_Filter = "Spectral Shape Filter":
            case OpticsContext._Laser_Line_Filter = "Laser-line Filter":
            case OpticsContext._Color_Glass = "Color Glass":
            case OpticsContext._Neutral_Density_Filter = "Neutral Density Filter":
            case OpticsContext._Hot_Cold_Mirror = "Hot/Cold Mirror":
                aGeo.thickness = OpticsDefaults.DEFAULTS.geo.thickness_filter[pUnit];
                break;
        }

        return aOpticsVO;
    }
    //______________________________________________________________________________________
    private static _getDefaultBeamSplitter(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {
        let aGeo: iOpticsVOGeometry = {};

        let aOpticsVO: iOpticsVO = {
            name: "User defined object",
            creator: Op3dContext.USER_VO.id,
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                info: {
                    brand: ""
                },
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                type: eOpticsTypeNames.BEAM_SPLITTER,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: OpticsDefaults.DEFAULTS.materials.fused_silica,
                coating: {},
                geometry: aGeo,
                shape: null
            }
        }


        switch (pSubtype) {
            case OpticsContext._Cube_BS:

                aOpticsVO.parameters.shape = eOpticShape.BS_CUBE;
                aOpticsVO.parameters.baseShape = eBaseShape.VOLUME;
                aOpticsVO.parameters.physical_data = {
                    bs_reflection: 0.5,
                    bs_transmission: 0.5
                }
                aGeo.cube_side = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch
                break;

            case OpticsContext._Thin_BS:
                aOpticsVO.parameters.shape = eOpticShape.THIN_LENS;
                aGeo.thickness = pUnit === eUnitType.MILLIMETERS ? 3 : 0.12 * OpticsDefaults.INCH_TO_MM_SCALE;

                pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.RECTANGULAR;
                aOpticsVO.parameters.baseShape = pBaseShape;

                if (pBaseShape === eBaseShape.RECTANGULAR) {
                    aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
                    aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

                } else if (pBaseShape === eBaseShape.CIRCULAR) {
                    aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
                }
                break;

        }

        return aOpticsVO;
    }
    //______________________________________________________________________________________
    private static _getDefaultLens(pSubtype: string, pBaseShape: eBaseShape | undefined, pUnit: eUnitType) {

        let aGeo: iOpticsVOGeometry = {};
        pBaseShape = pBaseShape !== undefined ? pBaseShape : eBaseShape.CIRCULAR;

        let aOpticsVO: iOpticsVO = {
            name: "User defined object",
            creator: Op3dContext.USER_VO.id,
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: {
                info: {
                    brand: ""
                },
                wavelength: OpticsDefaults.DEFAULTS.general.wavelength,
                type: eOpticsTypeNames.LENS,
                baseShape: pBaseShape,
                subType: pSubtype,
                materialID: "",
                coating: {},
                geometry: aGeo,
                shape: eOpticShape.SPHERICAL
            }
        }

        if (pBaseShape === eBaseShape.RECTANGULAR) {
            aGeo.width = OpticsDefaults.DEFAULTS.geo.width[pUnit];
            aGeo.height = OpticsDefaults.DEFAULTS.geo.height[pUnit];

        } else if (pBaseShape === eBaseShape.CIRCULAR) {
            aGeo.diameter = OpticsDefaults.DEFAULTS.geo.diameter[pUnit];
        }

        switch (pSubtype) {


            case OpticsContext._Spherical_Lens_Plano_Convex:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = 0;
                break;

            case OpticsContext._Spherical_Lens_Plano_Concave:
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? -100 : -4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = 0;
                break;

            case OpticsContext._Spherical_Lens_Convex_Convex:
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = pUnit === eUnitType.MILLIMETERS ? -100 : -4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Spherical_Lens_Concave_Concave:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? -100 : -4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Spherical_Lens_Concave_Convex:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = pUnit === eUnitType.MILLIMETERS ? 25 : OpticsDefaults.DEFAULTS.geo.one_inch;
                break;

            case OpticsContext._Spherical_Lens_Convex_Concave:
                aOpticsVO.parameters.shape = eOpticShape.SPHERICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 75 : 3 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Cylindrical_Lens_Plano_Convex:
                aOpticsVO.parameters.shape = eOpticShape.CYLINDRICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 100 : 4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;



            case OpticsContext._Cylindrical_Lens_Plano_Concave:
                aOpticsVO.parameters.shape = eOpticShape.CYLINDRICAL;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? -100 : -4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Ball_Lens:
                aOpticsVO.parameters.shape = eOpticShape.BALL_LENS;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.diameter = pUnit === eUnitType.MILLIMETERS ? 5 : 0.2 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Half_Ball_Lens:
                aOpticsVO.parameters.shape = eOpticShape.HALF_BALL_LENS;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.diameter = pUnit === eUnitType.MILLIMETERS ? 5 : 0.2 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

            case OpticsContext._Aspheric_Lens:
                aOpticsVO.parameters.shape = eOpticShape.ASPHERIC;
                aOpticsVO.parameters.materialID = OpticsDefaults.DEFAULTS.materials.fused_silica;
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? 38.1 : 38.1 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = 0;
                aGeo.thickness_center = OpticsDefaults.DEFAULTS.geo.thickness_center_lens[pUnit];
                aGeo.k = -1;
                break;

            case OpticsContext._Multiplets:

                aOpticsVO.parameters.shape = eOpticShape.MULTIPLET;
                aOpticsVO.parameters.materialID = "n-baf10_schott;n-sf6ht_schott";
                aGeo.r1 = pUnit === eUnitType.MILLIMETERS ? -52 : -52 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r2 = pUnit === eUnitType.MILLIMETERS ? 49.9 : 49.9 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.r3 = pUnit === eUnitType.MILLIMETERS ? 600 : 600 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.thickness_center_1 = pUnit === eUnitType.MILLIMETERS ? 2 : 2 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                aGeo.thickness_center_2 = pUnit === eUnitType.MILLIMETERS ? 4 : 4 / 25.4 * OpticsDefaults.INCH_TO_MM_SCALE;
                break;

        }

        return aOpticsVO;

    }
    //______________________________________________________________________________________
    private static _getDefaultAperture(pSubtype: string, pUnit: eUnitType) {
        let aParameters: iOpticsVOParameters;
        switch (pSubtype) {
            case OpticsContext._User_Aperture:
                aParameters = {
                    baseShape: eBaseShape.RECTANGULAR,
                    coating: {},
                    materialID: Strings.TRANSPARENT_MATERIAL,
                    shape: eOpticShape.APERTURE,
                    subType: pSubtype,
                    type: eOpticsTypeNames.APERTURE,
                    info: {
                        brand: "",
                    },
                    geometry: {
                        thickness: OpticsDefaults.DEFAULTS.geo.aperture_thickness,
                        width: OpticsDefaults.DEFAULTS.geo.width[pUnit],
                        height: OpticsDefaults.DEFAULTS.geo.height[pUnit]
                    },
                    physical_data: {
                        phase_mask: {
                            name: "",
                            url: ""
                        },
                        transmittance_mask: {
                            name: "",
                            url: ""
                        },
                    }
                }
                break;
        }

        let aOpticsVO: iOpticsVO = {
            name: "User defined aperture",
            creator: Op3dContext.USER_VO.id,
            number_id: Op3dUtils.idGenerator(true),
            permission: eDataPermission.PRIVATE,
            parameters: aParameters
        }

        return aOpticsVO
    }
    //______________________________________________________________________________________
}
