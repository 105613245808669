import { Op3dContext } from "../../../_context/Op3dContext";
import { iPoint2D, iSize } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { UnitHandler } from "../../../units/UnitsHandler";
import { ViewUtils } from "../../ViewUtils";
import { eAnalysisType, eSimulationKind } from "../AnalysisContext";
import { iAnalysisData } from "./AnalysisViewFactory";

export class AnalysisFooter {

    private mGeneralDataItems: {
        pixels: HTMLElement,
        size: HTMLElement,
        total_hits: HTMLElement,
        total_power: HTMLElement,
        date_time: HTMLElement,
        data_type: HTMLElement,
        hover_pixels: HTMLElement,
        hover_value: HTMLElement,
    }

    private mContainer: HTMLElement;

    constructor(pContainer: HTMLElement) {
        this.mContainer = pContainer;
        this.mGeneralDataItems = {
            pixels: Op3dUtils.getElementIn(this.mContainer, "pixels-span", true)!,
            size: Op3dUtils.getElementIn(this.mContainer, "size-span", true)!,
            total_hits: Op3dUtils.getElementIn(this.mContainer, "total-hits-span", true)!,
            total_power: Op3dUtils.getElementIn(this.mContainer, "total-power-span", true)!,
            date_time: Op3dUtils.getElementIn(this.mContainer, "date-time-span", true)!,
            data_type: Op3dUtils.getElementIn(this.mContainer, "data-type-span", true)!,
            hover_pixels: Op3dUtils.getElementIn(this.mContainer, "hover-pixel", true)!,
            hover_value: Op3dUtils.getElementIn(this.mContainer, "hover-value", true)!,
        }
    }
    //__________________________________________________________________________________________
    public update(pData: iAnalysisData, pIsLogView: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mGeneralDataItems.data_type.parentElement!, pIsLogView);
        this.mGeneralDataItems["date_time"].innerText = Op3dUtils.getDate();

        let aShowHits = pData.analysisItem.simulation_kind !== eSimulationKind.DIFFRACTION;
        ViewUtils.setElementVisibilityByDNone(this.mGeneralDataItems["total_hits"].parentElement, aShowHits);

        this.mGeneralDataItems["total_hits"].innerText = this._getHitsText(pData.cacheData.common_data.total_hits);
        this.mGeneralDataItems["total_power"].innerText = this._getTotalPowerText(pData);
        this.mGeneralDataItems["size"].innerText = this._getSizeText(pData);
        this.mGeneralDataItems["pixels"].innerText = this._getPixelsText(pData);
    }
    //__________________________________________________________________________________________
    private _getHitsText(pTotalHits: Number) {
        let aNum = OP3DMathUtils.formatLargeNumber(pTotalHits);
        return aNum;
    }
    //__________________________________________________________________________________________
    public setDataTypeVisibility(pIsVisible: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mGeneralDataItems.data_type.parentElement!, pIsVisible);
    }
    //__________________________________________________________________________________________
    public updatePixel(pPixel: iPoint2D, pVal: number) {
        let aPixelsString = "Pixel x: " + pPixel.x + " pixel y: " + pPixel.y;
        let aValue = "Value: " + OP3DMathUtils.toFixed(pVal,
            Op3dContext.SETUPS_MANAGER.settings.numericAccuracy);

        this.mGeneralDataItems["hover_pixels"].innerText = aPixelsString;
        this.mGeneralDataItems["hover_value"].innerText = aValue;
    }
    //__________________________________________________________________________________________
    private _getSizeInMM(pData: iAnalysisData): iSize {
        let aCommonData = pData.cacheData.common_data;
        let aSize: iSize = {
            height: ((aCommonData.y_range.max - aCommonData.y_range.min)),
            width: ((aCommonData.x_range.max - aCommonData.x_range.min))
        }

        return aSize;
    }
    //__________________________________________________________________________________________
    private _getSizeText(pData: iAnalysisData) {
        let aScale = UnitHandler.presentedScale;
        let aSizeInMM = this._getSizeInMM(pData);
        let aWidth = aSizeInMM.width * aScale;
        let aHeight = aSizeInMM.height * aScale;
        const aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        let aTxt = OP3DMathUtils.toFixed(aWidth, aToFixed) +
            "W x " + OP3DMathUtils.toFixed(aHeight, aToFixed) + "H";
        return aTxt;
    }
    //__________________________________________________________________________________________
    private _getPixelsText(pData: iAnalysisData) {
        const aResolution = pData.cacheData.common_data.resolution;
        let aTxt = aResolution.x + "W x " + aResolution.y + "H";
        return aTxt;
    }
    //__________________________________________________________________________________________
    private _getTotalPowerText(pData: iAnalysisData) {
        let aTotalPowerElement = this.mGeneralDataItems["total_power"];

        ViewUtils.setElementVisibilityByDNone(aTotalPowerElement.parentElement!, true);

        if ((eAnalysisType.FAST == pData.analysisItem.type) ||
            (eAnalysisType.ADVANCED == pData.analysisItem.type &&
                (pData.analysisItem.name === "Coherent Phase" || pData.analysisItem.name === "Coherent Phase Polarized"))) {
            /**
             * total power in disabeld for this type of analysis
             */
            ViewUtils.setElementVisibilityByDNone(aTotalPowerElement.parentElement!, false);
            return "";
        }

        /**
         * 1) find S in mm2
         * 2) S' = convert to cm2
         * 3) aSumMatrix* S'
         */

        let aMatrix = pData.cacheData.matrix.working.matrix;
        let aTotalIntensity = DataUtils.sumMatrix(aMatrix);
        let aCount = pData.cacheData.common_data.resolution.x *
            pData.cacheData.common_data.resolution.y;
        let aAvgIntensity = aTotalIntensity / aCount;
        let aSizeInMM = this._getSizeInMM(pData);
        let aSInMM = aSizeInMM.width * aSizeInMM.height;
        let aSInCm2 = aSInMM / 100;
        let aTotalPower = aSInCm2 * aAvgIntensity;

        const aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        return OP3DMathUtils.toFixed(aTotalPower, aToFixed);
    }
    //__________________________________________________________________________________________
}