import { Object3D, Line, Line3, BufferGeometry, LineDashedMaterial, Vector3, Euler, Quaternion } from "three";
import { EventBase } from "../../../oc/events/EventBase";
import { EventManager } from "../../../oc/events/EventManager";
import { eStateToAnalysis, eUnitType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MathContext } from "../../_context/MathContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { OptixPartDisplayer } from "../../parts/_parts_assets/OptixPartDisplayer";
import { MovementBehavior } from "../../parts/behaviors/MovementBehavior";
import { SceneContext } from "../../scene/SceneContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { ViewUtils } from "../ViewUtils";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { Group } from "../forms/Group";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";
import { ePartType, iRefCS } from "../../parts/PartInterfaces";
import { WarningPopup } from "../forms/WarningPopup";

export interface iCSFormData {
    part: Part;
    refCS?: iRefCS;
}

export enum eAngleUnit {
    DEG,
    RAD
}

export class piMoveRotateSection extends PartInfoSection<iCSFormData> {

    private mPosX: NumberInputElement;
    private mPosY: NumberInputElement;
    private mPosZ: NumberInputElement;

    private mRotX: NumberInputElement;
    private mRotY: NumberInputElement;
    private mRotZ: NumberInputElement;

    private mSetRefButton: HTMLElement;
    private mSetRefButtonContainer: HTMLElement;

    private mAngleUnit: HTMLSelectElement
    private mPositionUnit: HTMLSelectElement;

    private mSelectedCS: Object3D;
    private mRefCS: iRefCS;

    private mPart: Part;

    private mConnectLine: Line;
    private mLockTransform: HTMLInputElement;
    private mRefCsTitle: HTMLElement;
    private mRefCsContainer: HTMLElement;
    private mCancelRefCsBtn: HTMLElement;
    private mPickBtnActive: boolean = false;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_move_rotate.html'
        });
    }
    //__________________________________________________________________________________________
    private _removeConnectedLine() {
        if (null == this.mConnectLine) {
            return;
        }

        this.mConnectLine.parent.remove(this.mConnectLine);
        this.mConnectLine = null;
    }
    //__________________________________________________________________________________________
    private _setDashedLineVisibility(pToShow: boolean, pLine?: Line3) {
        this._removeConnectedLine();
        if (false == pToShow) {
            return;
        }

        let aGeometry = new BufferGeometry().setFromPoints([pLine.start, pLine.end]);
        let aMaterial = new LineDashedMaterial({
            color: (0xFFFFFF - Op3dContext.USER_VO.simulationSettings.sceneBGColor),
            linewidth: 10,
            scale: 1,
            dashSize: 10,
            gapSize: 10
        });

        this.mConnectLine = new Line(aGeometry, aMaterial);
        this.mConnectLine.computeLineDistances();
        SceneContext.MAIN_SCENE.add(this.mConnectLine);
    }
    //__________________________________________________________________________________________

    private _clearDashedLine() {
        this._setDashedLineVisibility(false);
    }
    //__________________________________________________________________________________________
    private _updateConnectingLine() {
        let aLine = new Line3();
        aLine.start = this.mSelectedCS.getWorldPosition(new Vector3);
        aLine.end = this.mRefCS.cs.object3D.getWorldPosition(new Vector3);

        this._setDashedLineVisibility(true, aLine);
    }
    //__________________________________________________________________________________________
    private _addEventListeners() {
        EventManager.addEventListener(EventsContext.PART_MOVED,
            (pEvent: EventBase) => {
                if (pEvent.sender != this.mPart) {
                    return;
                }

                this._updatePos();
            }, this);
        EventManager.addEventListener(EventsContext.UNDO, () => {
            this._updatePos();
            this._updateRot();
        }, this);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iCSFormData): void {
        Op3dContext.PARTS_MANAGER.setLinkedPartsLinesVisibility(false);
        let aGroupPart = Group.instance.findMainGroupPart(pData.part);
        let aIsGroup = (ePartType.GROUP === aGroupPart.partOptions.type);
        ViewUtils.setElementVisibilityByDNone(this.mSetRefButton, !aIsGroup);
        ViewUtils.setElementVisibilityByDNone(this.mRefCsContainer, !aIsGroup);
        ViewUtils.setElementDisabled(this.mSetRefButton, false);

        this._addEventListeners();

        this.mPart = pData.part;
        this.mLockTransform.checked = this.mPart.locked;
        ViewUtils.setElementDisabled(this._getPart('reset'), this.mLockTransform.checked);

        this.mSelectedCS = this.mPart.workingLCS.object3D;

        this.mRefCS = pData.refCS;
        this.mRefCS.cs.object3D.setVisibility(true);
        if (this.mPart.refCS.refPart.id === "GCS") {
            ViewUtils.setElementVisibilityByDNone(this.mRefCsContainer, false);
            ViewUtils.setElementVisibilityByDNone(this.mSetRefButtonContainer, true)

        } else if (!aIsGroup) {
            this.onChangeRefCS();
        }
        this._updatePos();
        this._updateRot();
        this.updateStepParams()
    }
    //__________________________________________________________________________________________
    protected _onHide(): void {
        this.mPickBtnActive = false
        this.onChangeRefCS();
        Op3dContext.PARTS_EVENTS_HANDLER.restoreToGeneralMode(true);
        
        this._clearDashedLine();
        OptixPartDisplayer.unHighlightObject(this.mPart);
        Op3dContext.PARTS_MANAGER.unHiglightAllParts();
        let aIsCSPart = (ePartType.CS === this.mPart.partOptions.type);

        this.mSelectedCS.visible = aIsCSPart;
        this.mRefCS.cs.object3D.setVisibility(false);
        this.mPart = null;

        Op3dContext.PARTS_MANAGER.setLinkedPartsLinesVisibility(true);

        EventManager.removeEventListener(EventsContext.PART_MOVED, this);
        EventManager.removeEventListener(EventsContext.UNDO, this);

        //!TODO check if have some changes
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._initPositionPart();
        this._initRotationPart();

        this.updateStepParams()

        this.mSetRefButton = this._getPart('ref_button');
        this.mSetRefButtonContainer = this._getPart('reference_cs_btn_container')
        this.mLockTransform = this._getPart('lock-part-transform') as HTMLInputElement;

        this.mRefCsTitle = this._getPart('ref_cs_part_title');
        this.mRefCsContainer = this._getPart('ref_cs_container')

        this.mCancelRefCsBtn = this._getPart('remove_cs_btn')

        this.mCancelRefCsBtn.addEventListener('click', () => { this._onClickRemoveCsBtn() })
        this.mLockTransform.addEventListener("change", () => this._onChangeLock());
        this.mSetRefButton.addEventListener('click', () => {
            this.mPickBtnActive = !this.mPickBtnActive;
            if(this.mPickBtnActive === false){
                this.onChangeRefCS();
                Op3dContext.PARTS_EVENTS_HANDLER.restoreToGeneralMode(true);
                return;
            }
            this._onChooseRef()
        });
        this._getPart('reset').addEventListener('click', () => this._reset());
    }
    //______________________________________________________________________________________________
    private _onChangeLock() {
        this.mPart.locked = this.mLockTransform.checked;
        ViewUtils.setElementDisabled(this._getPart('reset'), this.mLockTransform.checked);

    }
    //__________________________________________________________________________________________
    private _reset() {
        Op3dContext.SCENE_HISTORY.addToHistory();
        MovementBehavior.resetTransforms(this.mPart);


        this._updatePos();
        this._updateRot();
    }
    //__________________________________________________________________________________________
    private _changeState(pInputChanged: boolean) {
        if (this.mLockTransform.checked === true) {
            new WarningPopup("Move & rotate is locked").open();
            this._updatePos();
            this._updateRot();
            return;
        }
        if (pInputChanged === true) {
            this.updateStepParams()
        }

        let aRotX = (this.mRotX.convertedValue);
        let aRotY = (this.mRotY.convertedValue);
        let aRotZ = (this.mRotZ.convertedValue);
        let aNewRot = new Euler(aRotX, aRotY, aRotZ);

        let aTranslateX = this.mPosX.convertedValue;
        let aTranslateY = this.mPosY.convertedValue;
        let aTranslateZ = this.mPosZ.convertedValue;
        let aNewPos = new Vector3(aTranslateX, aTranslateY, aTranslateZ);

        MovementBehavior.transformPart(this.mPart, aNewPos, aNewRot);
        this._updateConnectingLine();

        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
    }
    //__________________________________________________________________________________________
    private _updatePos() {
        let aSelectedCSPos = MovementBehavior.distToRef(this.mPart);

        this.mPosX.convertedValue = aSelectedCSPos.x;
        this.mPosY.convertedValue = aSelectedCSPos.y;
        this.mPosZ.convertedValue = aSelectedCSPos.z;

        this._updateConnectingLine();
    }
    //__________________________________________________________________________________________
    private _updateRot() {
        let aRotation = new Euler();

        let aSelctedCSRot = this.mSelectedCS.getWorldQuaternion(new Quaternion());
        let aRef = this.mRefCS.cs.object3D;

        let aRefCSRot = aRef.getWorldQuaternion(new Quaternion());
        aSelctedCSRot.multiply(aRefCSRot.invert());
        aRotation.setFromQuaternion(aSelctedCSRot);

        aRotation.copy(this.mPart.refRotation);
        this.mRotX.convertedValue = aRotation.x;
        this.mRotY.convertedValue = aRotation.y;
        this.mRotZ.convertedValue = aRotation.z;
        this.mRotX.setData({ startValue: aRotation.x });
        this.mRotY.setData({ startValue: aRotation.y });
        this.mRotZ.setData({ startValue: aRotation.z });
        this.mRotX.startValue = aRotation.x;
        this.mRotY.startValue = aRotation.y;
        this.mRotZ.startValue = aRotation.z;
        this._onChangeRotationUnit();
    }
    //__________________________________________________________________________________________
    private async _onChooseRef() {
        this.mSetRefButton.classList.add('active_ref_button')
        this.mRefCS.cs.object3D.setVisibility(false);

        let aPart = this.mPart;
        Op3dContext.PARTS_EVENTS_HANDLER.enterChooseRefMode(aPart, this);
    }
    //__________________________________________________________________________________________
    private _initPositionPart() {
        this.mPosX = new NumberInputElement(this._getPart('pos_x'), {
            field_name: 'X:',
            isGlobalToFixed: true,
            toFixed: 6,
            triggers: {
                saveHistory: true,
                triggerAnalysis: true,
                saveScene: true
            }
        });
        this.mPosY = new NumberInputElement(this._getPart('pos_y'), {
            field_name: 'Y:',
            isGlobalToFixed: true,
            toFixed: 6,
            triggers: {
                saveHistory: true,
                triggerAnalysis: true,
                saveScene: true
            }
        });
        this.mPosZ = new NumberInputElement(this._getPart('pos_z'), {
            field_name: 'Z:',
            isGlobalToFixed: true,
            toFixed: 6,
            triggers: {
                saveHistory: true,
                triggerAnalysis: true,
                saveScene: true
            }
        });

        let aStep = (eUnitType.INCHES == UnitHandler.PRESENTED_UNIT) ? 0.05 : 1;
        let aFactor = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 1 :
            UnitHandler.IN_TO_MM;

        this.mPosX.setData({
            callback: (_pValue, pInputChange: boolean) => this._changeState(pInputChange),
            step: aStep,
            factor: aFactor
        });
        this.mPosY.setData({
            callback: (_pValue, pInputChange: boolean) => this._changeState(pInputChange),
            step: aStep,
            factor: aFactor
        });
        this.mPosZ.setData({
            callback: (_pValue, pInputChange: boolean) => this._changeState(pInputChange),
            step: aStep,
            factor: aFactor
        });

        this._initPosUnit();
    }
    //__________________________________________________________________________________________
    private getIncrementStep(pNumber: number) {
        const absNumber = Math.abs(pNumber);
        const decimalPlaces = absNumber.toString().split('.')[1];
        if (decimalPlaces) {
            const increment = 1 / Math.pow(10, decimalPlaces.length);
            return increment;
        } else {
            return 1;
        }
    }
    //__________________________________________________________________________________________
    public updateStepParams() {
        let aPosXStep = this.getIncrementStep(this.mPosX.value)
        this.mPosX.setData({
            step: aPosXStep
        });
        let aPosYStep = this.getIncrementStep(this.mPosY.value)
        this.mPosY.setData({
            step: aPosYStep
        });
        let aPosZStep = this.getIncrementStep(this.mPosZ.value)
        this.mPosZ.setData({
            step: aPosZStep
        });
        let aRotXStep = this.getIncrementStep(this.mRotX.value)
        this.mRotX.setData({
            step: aRotXStep
        });
        let mRotYStep = this.getIncrementStep(this.mRotY.value)
        this.mRotY.setData({
            step: mRotYStep
        });
        let mRotZStep = this.getIncrementStep(this.mRotZ.value)
        this.mRotZ.setData({
            step: mRotZStep
        });
    }
    //__________________________________________________________________________________________
    private _initPosUnit() {
        let aEnvUnit = UnitHandler.PRESENTED_UNIT;

        this.mPositionUnit = this._getPart('pos_unit') as HTMLSelectElement;
        this.mPositionUnit.value = aEnvUnit.toString();
        this.mPositionUnit.addEventListener('change', () => this._onPoistionUnitChanged());
    }
    //__________________________________________________________________________________________
    private _onPoistionUnitChanged() {
        let aPIUnit = parseInt(this.mPositionUnit.value);

        let aFactor = (eUnitType.MILLIMETERS == aPIUnit) ? 1 :
            UnitHandler.IN_TO_MM;
        let aStep = (eUnitType.MILLIMETERS == aPIUnit) ? 1 : 0.05;

        this.mPosX.setData({ factor: aFactor, step: aStep });
        this.mPosY.setData({ factor: aFactor, step: aStep });
        this.mPosZ.setData({ factor: aFactor, step: aStep });

        this._updatePos();
    }
    //__________________________________________________________________________________________
    private _initRotationPart() {
        this.mRotX = new NumberInputElement(this._getPart('rot_x'), {
            toFixed: 5,
            isGlobalToFixed: true,
            triggers: {
                saveHistory: true,
                triggerAnalysis: true,
                saveScene: true
            }
        });
        this.mRotY = new NumberInputElement(this._getPart('rot_y'), {
            toFixed: 5,
            isGlobalToFixed: true,
            triggers: {
                saveHistory: true,
                triggerAnalysis: true,
                saveScene: true
            }
        });
        this.mRotZ = new NumberInputElement(this._getPart('rot_z'), {
            toFixed: 5,
            isGlobalToFixed: true,
            triggers: {
                saveHistory: true,
                triggerAnalysis: true,
                saveScene: true
            }
        });

        this.mRotX.setData({
            callback: (_pValue, pInputChange: boolean) => this._changeState(pInputChange),
            mod: 360,
            step: 0.5,
            factor: MathContext.DEG_TO_RAD
        });
        this.mRotY.setData({
            callback: (_pValue, pInputChange: boolean) => this._changeState(pInputChange),
            mod: 360,
            step: 0.5,
            factor: MathContext.DEG_TO_RAD
        });
        this.mRotZ.setData({
            callback: (_pValue, pInputChange: boolean) => this._changeState(pInputChange),
            mod: 360,
            step: 0.5,
            factor: MathContext.DEG_TO_RAD
        });

        this._initRotUnit();
    }
    //__________________________________________________________________________________________
    private _initRotUnit() {
        let aDefaultAngleUnit = eAngleUnit.DEG;
        this.mAngleUnit = this._getPart('rot_unit') as HTMLSelectElement;
        this.mAngleUnit.value = aDefaultAngleUnit.toString();


        this.mAngleUnit.addEventListener('change', () => this._onChangeRotationUnit());

        this._changeFieldName();
    }
    //__________________________________________________________________________________________
    private _onChangeRotationUnit() {
        this._changeFieldName();
        // let aSign = (eAngleUnit.DEG == parseInt(this.mAngleUnit.value)) ? '°' : '';
        let aSign = '';
        this.mRotX.updateCreationData({ field_name: 'X' + aSign, isGlobalToFixed: true, });
        this.mRotY.updateCreationData({ field_name: 'Y' + aSign, isGlobalToFixed: true, });
        this.mRotZ.updateCreationData({ field_name: 'Z' + aSign, isGlobalToFixed: true, });

        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mAngleUnit.value));
        let aFactor = (true == aIsDeg) ? MathContext.DEG_TO_RAD : 0.001;
        let aMod = (true == aIsDeg) ? 360 : (2000 * Math.PI);

        let aX = (this.mRotX.convertedValue / aFactor);
        let aY = (this.mRotY.convertedValue / aFactor);
        let aZ = (this.mRotZ.convertedValue / aFactor);

        this.mRotX.setData({ factor: aFactor, mod: aMod });
        this.mRotY.setData({ factor: aFactor, mod: aMod });
        this.mRotZ.setData({ factor: aFactor, mod: aMod });

        this.mRotX.value = aX;
        this.mRotY.value = aY;
        this.mRotZ.value = aZ;
    }
    //__________________________________________________________________________________________
    private _changeFieldName() {
        Op3dUtils.getElementIn(this.mContainer, 'rot_text_x').setAttribute('field_name', 'X:');
        Op3dUtils.getElementIn(this.mContainer, 'rot_text_y').setAttribute('field_name', 'Y:');
        Op3dUtils.getElementIn(this.mContainer, 'rot_text_z').setAttribute('field_name', 'Z:');
    }
    //__________________________________________________________________________________________
    private _onClickRemoveCsBtn() {
        ViewUtils.setElementVisibilityByDNone(this.mSetRefButtonContainer, true);
        ViewUtils.setElementVisibilityByDNone(this.mRefCsContainer, false);
        this.mPart.setRefrence({
            refPart: Op3dContext.GCS,
            cs: Op3dContext.GCS.getAxes()[0]
        });
        this.mSelectedCS = this.mPart.workingLCS.object3D;
        this.mRefCS = this.mPart.refCS;
        this._updateConnectingLine();
        Op3dContext.SIDEBAR_LIST.updatePartsList(Op3dContext.PARTS_MANAGER.parts);
    }
    //__________________________________________________________________________________________
    public onChangeRefCS(pExit: boolean = false) {
        if(pExit === true){
            this.mPickBtnActive = false;
        }
        this.mSetRefButton.classList.remove('active_ref_button');
        ViewUtils.setElementVisibilityByDNone(this.mSetRefButtonContainer, false);
        ViewUtils.setElementVisibilityByDNone(this.mRefCsContainer, true);
        let aTitle = this.mPart.refCS.refPart?.getLabel()?.label;
        if (aTitle === undefined) {
            this.mSetRefButton.classList.remove('active_ref_button');
            ViewUtils.setElementVisibilityByDNone(this.mSetRefButtonContainer, true);
            ViewUtils.setElementVisibilityByDNone(this.mRefCsContainer, false);
            ViewUtils.setElementDisabled(this.mSetRefButton, false);
        } else {
            this.mRefCsTitle.innerText = aTitle;
        }
    }
    //__________________________________________________________________________________________
}
