
export class Strings {

    public static USER_DEFINED_LIGHT_SOURCE = "USER_DEFINED_LIGHT_SOURCE";
    public static LP1_USER_EMAIL = "LP1_USER_EMAIL";
    public static KEY_ENTER = "Enter";
    public static KEY_ESCAPE = "Escape";
    public static BOLD_CLASS: string = "bold";
    public static CLOSE_MASK_STATE: string = "close-mask";
    public static MARKED: string = "marked";
    public static WAKEUP_SCREEN: string = "wakeup-screen";
    public static BLACK_BORDER: string = "black-border";
    public static DISABLED_OPACITY: string = "disabled-opacity";

    public static POINTER_EVENTS_NONE: string = "pointer_events_none";
    public static BTN_SPINNER_ICON = "fa fa-circle-o-notch fa-spin";
    public static USER_SELECT_NONE: string = "user_select_none";
    public static BASIC_LICENSE = "basic-license";

    public static SPINNER_TEXT_PROCESSING: string = "Processing..."
    public static SPINNER_TEXT_UPLOADING: string = "Uploading..."
    public static SPINNER_TEXT_DRAWING: string = "Drawing......"

    public static ALL: string = "All";
    public static SHOW: string = "show";
    public static H_5: string = "h-5";
    public static H_8: string = "h-8";
    public static H_10: string = "h-10";
    public static H_15: string = "h-15";
    public static H_80: string = "h-80";
    public static H_100: string = "h-100";
    public static H_90: string = "h-90";
    public static H_92: string = "h-92";
    public static W_10: string = "w-10";
    public static W_90: string = "w-90";
    public static W_80: string = "w-80";
    public static W_79: string = "w-79";
    public static W_20: string = "w-20";
    public static W_21: string = "w-21";
    public static ANSLYSIS_CLASS: string = "analysis";
    public static DISABLED_STATE: string = "disabled";
    public static ORTHOGRAPHIC_MODE: string = "ortho-box-mode";
    public static ACTIVE_STATE_CLASS: string = "active";
    public static SELECTED_TAB: string = "selected-tab";
    public static TOP_NAV_BUTTON_SELECTED: string = "selected-navItem";
    public static OPTICS_HOLDER = "mirror-holder";
    public static IRIS_CONTAINER = "iris-container";
    public static LASER_POINT = "laser_point";
    public static POST_CONNECTION_POINT = "post_connection_point";
    public static CAGE_BEHAVIOR = "cage_container";
    public static MODULE_ID: string = "module";
    public static INFINITE = "Infinite";
    public static PLACE_HOLDER_ID: string = "place_holder";
    public static PART = "part";
    public static D_NONE = "d-none";
    public static D_NONE_EMPTY: string = "none_if_empty";
    public static DISPLAY_FLEX_CLASS: string = "d-flex";
    public static JUSTIFY_CONTENT_CENTER = "justify-content-center";
    public static JUSTIFY_CONTENT_BETWEEN = "justify-content-between";

    public static USER_SETTINGS = "3doptix_settings";
    public static _MOUNTS = "Mount";
    public static INVALID: string = "invalid";
    public static REQUIRED: string = "required";
    public static RED_BORDER: string = "red_border";



    public static _Optical_Design = "Optical Design";
    public static _Basic_Elements = "Basic Elements";

    public static DETECTOR_IMAGE_TITLE: string = "Detector Image";

    public static PARAXIAL_LENS_NAME = "Paraxial lens";
    public static ABSORBING = "absorbing";
    public static FRONT = "front";
    public static BACK = "back";
    public static SURFACE_NAME = "surfaceName";
    public static TRANSPARENT_MATERIAL = "transparent_material";
    public static ERROR = "error";
    public static SUCCESS = "success";
    public static LOCAL_STORAGE_ENV = "3doptix-env";
    public static SEQ_OPTIC: string = "seq_custom_optic"
    static IMAGE_PART: string = "imagePart"
    public static DETECTOR_CROSS: string = "detector_cross";
    public static DETECTOR_F_LETTER: string = "detector_front_letter";
    public static DETECTOR_B_LETTER: string = "detector_back_letter";


    public static isValidOptics = function (coating, material) {
        var startLambda = material.parameters.startLambda;
        var endLambda = material.parameters.endLambda;

        var coatingWL = coating.wl;
        var startCoating = coatingWL[0];
        var endCoating = coatingWL[(coatingWL.length - 1)];
        if ((endLambda < startCoating) || (startLambda > endCoating)) {
            return false;
        }

        return true;
    }


}

