/**
 * @description This class is responsible for loading the scene lights
 * return in need a clone of the lights 
 */

import { Links } from "../_context/Links";
import { Op3dContext } from "../_context/Op3dContext";
import { SceneContext } from "./SceneContext";
import { Object3D, DirectionalLight, ObjectLoader } from "three";

export class SceneLights {

    private mLights!: Object3D;

    constructor() {
        new ObjectLoader().load(Links.LIGHTS_PATH,
            (pLights: Object3D) => {
                // let light = new HemisphereLight()
                // pLights.add(light)
                this._onObjectLoaded(pLights)
            });
    }
    //___________________________________________________________________
    private _onObjectLoaded(pLights: Object3D) {
        this.mLights = pLights;
        this.mLights.name = "lights";

        this.updateLights();
        SceneContext.MAIN_SCENE.add(this.mLights)
    }
    //___________________________________________________________________
    public async getLights(): Promise<Object3D> {
        while (this.mLights == null) {
            await Op3dContext.sleep(50);
        }

        return this.mLights.clone();
    }
    //___________________________________________________________________
    /**
     * @description updates the light intensity according to user settings
     */
    //___________________________________________________________________
    public updateLights() {
        for (let i = 0; i < this.mLights.children.length; i++) {
            let aLight = this.mLights.children[i] as DirectionalLight;
            aLight.intensity = Op3dContext.USER_VO.simulationSettings.lightLevel;
        }
    }
    //___________________________________________________________________
}
