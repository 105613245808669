import { eMouseButtons } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { iOP3DHTMLOptionElement } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ServerContext } from "../../server/ServerContext";
import { ViewUtils } from "../ViewUtils";
import { ContextMenu, iContextMenuItem } from "../forms/ContextMenu";
import { Checkbox } from "./Checkbox";

export interface iCustomSelectOptionElement<T = string> extends HTMLElement {
    value: T;
}

export interface iCustomSelectLabel {
    bold?: boolean;
    label: string;
    checkbox?: boolean;
    onCheckboxChange?: (pVal: boolean) => any;
    defaultCheckboxState?: boolean;
    /**
     * Default - start
     */
    justify?: 'start' | 'cener' | 'space-between' | 'end';
}

export interface iCustomSelectOption<T = any> {
    value: string;
    /**
     * @description Showed text in the dropdown. Default - value.
     */
    text?: string;

    /**
     * @description Determined if this option is from premuim only. If so - the premium button 
     *  will be added.
     */
    isPremiumOnly?: boolean;
    qa_id?: string;

    data?: T;

    enable: boolean;

    context_menu_items?: Array<iContextMenuItem>;
}

export interface iCustomSelectParams<T = any> {
    labelOptions?: iCustomSelectLabel;
    placeHolder?: string;
    qa_id?: string;
    staticPostion?: boolean;
    isVertical?: boolean;
    class?: string[];

    options?: Array<iCustomSelectOption<T>>;
    defaultOptionValue?: string;
    search?: boolean;
    onChange?: (pValue: string) => any;

    maxDisplayedOptions?: number;
    width?: number;
}

export class CustomSelect<T = any> {

    private static SELECT_CHEVRON: string = '<svg class="select_chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 8.70996L12 13.3L16.59 8.70996L18 10.13L12 16.13L6 10.13L7.41 8.70996Z" fill="#2E3333"/></svg>';


    private mSelectElement: HTMLSelectElement;
    private mCheckbox: HTMLInputElement;
    private mOptionsContainer: HTMLElement;

    private mOnChangeSelect: (pDispatchEvent?: boolean) => void;

    private mContainer: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pParams?: iCustomSelectParams<T>) {
        if (pContainer instanceof HTMLSelectElement) {
            this._initFromSelectElement(pContainer, pParams);
        } else {
            this._init(pContainer, pParams);
        }
    }
    //__________________________________________________________________________________________
    private _init(pContainer: HTMLElement, pParams?: iCustomSelectParams<T>) {
        this.mContainer = pContainer;
        this._create(pParams);
    }
    //__________________________________________________________________________________________
    private _initFromSelectElement(pSelect: HTMLSelectElement, pParams?: iCustomSelectParams<T>) {
        let aOptions = new Array<iCustomSelectOption>();
        for (let i = 0; i < pSelect.options.length; i++) {
            let aSelectOption = pSelect.options[i];
            let aText = aSelectOption.text;
            let aValue = ('' != aSelectOption.value) ? aSelectOption.value : aSelectOption.text;
            aOptions.push({
                value: aValue,
                text: aText,
                enable: true
            });
        }

        let aDiv = document.createElement('div');
        let aSelectParent = pSelect.parentElement;
        if (null != aSelectParent) {
            aSelectParent.insertBefore(aDiv, aSelectParent);
            ViewUtils.removeFromParent(pSelect);
        }

        if (null == pParams) {
            pParams = {};
        }

        pParams.options = aOptions;

        this._init(aDiv, pParams);
    }
    //__________________________________________________________________________________________
    public isExist(pValue: string) {
        let aIsExist = false;
        for (let i = 0, l = this.mSelectElement.options.length; i < l; i++) {
            if (pValue === this.mSelectElement.options[i].value) {
                aIsExist = true;
                break;
            }
        }

        return aIsExist;
    }
    //__________________________________________________________________________________________
    public setValue(pValue: string, pDispatchEvent: boolean = true) {
        this.mSelectElement.value = pValue;
        this.mOnChangeSelect(pDispatchEvent);
    }
    //__________________________________________________________________________________________
    public setOptions(pOptions: Array<iCustomSelectOption>, pDefault?: string,
        pDispatchEvent: boolean = true) {

        ViewUtils.removeElementChildren(this.mSelectElement);
        for (let i = 0; i < pOptions.length; i++) {
            let aOption = pOptions[i];

            let aValue = aOption.value;
            let aText = (null != aOption.text) ? aOption.text : aValue;

            let aSelectOption = document.createElement('option') as
                iOP3DHTMLOptionElement<T>;
            aSelectOption.text = aText;
            aSelectOption.value = aValue;
            aSelectOption.enable = aOption.enable;
            aSelectOption.isPremiumOnly = aOption.isPremiumOnly;
            aSelectOption.qa_id = aOption.qa_id;
            aSelectOption.data = aOption.data;
            aSelectOption.context_menu_items = aOption.context_menu_items;

            this.mSelectElement.add(aSelectOption);
        }

        this._FilterByString('');

        if (null != pDefault) {
            this.mSelectElement.value = pDefault;
            this.mOnChangeSelect(pDispatchEvent);
        }

        return this;
    }
    //__________________________________________________________________________________________
    public setIndex(pIndex: number, pDispatchEvent: boolean = true) {
        this.mSelectElement.selectedIndex = pIndex;
        this.mOnChangeSelect(pDispatchEvent);
    }
    //__________________________________________________________________________________________
    public get enabled() {
        if (undefined === this.mCheckbox) {
            let aSelectButton = $(this.mContainer).find('button')[0] as HTMLElement;
            return aSelectButton.classList.contains('disabled');
        }

        return (true === this.mCheckbox.checked);
    }
    //__________________________________________________________________________________________
    public set enabled(pVal: boolean) {
        if (undefined === this.mCheckbox) {
            let aSelectButton = $(this.mContainer).find('button')[0] as HTMLElement;
            ViewUtils.setClassState(aSelectButton, 'disabled', (false === pVal));
        } else {
            this.mCheckbox.checked = pVal;
            this.mCheckbox.dispatchEvent(new Event('change'));
        }
    }
    //__________________________________________________________________________________________
    public set readonly(pVal: boolean) {
        ViewUtils.setClassState(this.mContainer, 'readonly', pVal);
    }
    //__________________________________________________________________________________________
    public setVisibility(pVal: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, pVal);
    }
    //__________________________________________________________________________________________
    public set value(pValue: string) {
        this.mSelectElement.value = pValue;
        this.mSelectElement.dispatchEvent(new Event('change'));
    }
    //__________________________________________________________________________________________
    public get value() {
        return this.mSelectElement.value;
    }
    //__________________________________________________________________________________________
    public get selectedOptionData() {
        let aOption = this.mSelectElement.selectedOptions[0] as iOP3DHTMLOptionElement<T>;
        return (null != aOption) ? aOption.data : null;
    }
    //__________________________________________________________________________________________
    public get text() {
        let aSelectOption = this.mSelectElement.selectedOptions[0];
        return ((true == this.enabled) && (null != aSelectOption)) ? aSelectOption.text : null;
    }
    //__________________________________________________________________________________________
    public get options() {
        let aOptions = new Array<iOP3DHTMLOptionElement<T>>();
        for (let i = 0; i < this.mSelectElement.options.length; i++) {
            aOptions.push(this.mSelectElement.options[i] as iOP3DHTMLOptionElement<T>);
        }
        return aOptions;
    }
    //__________________________________________________________________________________________
    private _create(pParams: iCustomSelectParams) {
        this.mContainer.removeAttribute('class');
        this.mContainer.classList.add('select_cot');
        if (pParams.class != null) {
            this.mContainer.classList.add(...pParams.class);
        }

        let aLabelOptions = pParams.labelOptions;
        if (null != aLabelOptions) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('select_cot_label');

            let aJustify = (null != aLabelOptions.justify) ? aLabelOptions.justify : 'start';
            aDiv.style.justifyContent = aJustify;

            let aID = Op3dUtils.idGenerator();
            if (true == aLabelOptions.checkbox) {
                this.mCheckbox = document.createElement('input');
                this.mCheckbox.type = 'checkbox';
                this.mContainer.appendChild(this.mCheckbox);
                this.mCheckbox.id = aID;

                if (null != aLabelOptions.onCheckboxChange) {
                    this.mCheckbox.addEventListener('change', () =>
                        aLabelOptions.onCheckboxChange(this.mCheckbox.checked));
                }

                if (null != aLabelOptions.defaultCheckboxState) {
                    this.mCheckbox.checked = aLabelOptions.defaultCheckboxState;
                    this.mCheckbox.dispatchEvent(new Event('change'));
                }

                aDiv.innerHTML += Checkbox.CHECKBOX_SVG;
            } else {
                this.mContainer.classList.add('standard');
            }

            let aLabel = document.createElement('label');
            aLabel.innerHTML = aLabelOptions.label;
            if (aLabelOptions.bold == false) {
                aLabel.style.fontWeight = '400';
            }
            aDiv.appendChild(aLabel);
            aLabel.htmlFor = aID;

            this.mContainer.appendChild(aDiv);
        }

        if (true == pParams.staticPostion) {
            this.mContainer.classList.add('stat');
        }

        if (null != pParams.width) {
            this.mContainer.style.width = pParams.width + 'px';
        }

        this.mSelectElement = document.createElement('select');
        let aSelectButton = document.createElement('button');
        if (pParams.qa_id != null) {
            aSelectButton.setAttribute("qa_id", pParams.qa_id);
        }
        this.mContainer.appendChild(this.mSelectElement);
        this.mContainer.appendChild(aSelectButton);

        let aButtonSpan = document.createElement('span');
        aSelectButton.appendChild(aButtonSpan);
        if (null != pParams.placeHolder) {
            aButtonSpan.innerHTML = pParams.placeHolder;
        }

        aSelectButton.innerHTML += CustomSelect.SELECT_CHEVRON;
        aButtonSpan = aSelectButton.children[0] as HTMLSpanElement;

        let aDropdown = document.createElement('c_dropdown');
        let aDropdownCot = document.createElement('div');
        aDropdownCot.classList.add('c_dropdown_cot');
        aDropdown.addEventListener('mousedown', (e) => e.stopPropagation());

        this.mOptionsContainer = document.createElement('div');
        this.mOptionsContainer.classList.add('select_cot_options_container');
        let aMaxDisplayedOptions = (null != pParams.maxDisplayedOptions) ?
            pParams.maxDisplayedOptions : 6;
        let aMaxH = (aMaxDisplayedOptions * 26);
        this.mOptionsContainer.style.maxHeight = 'min(calc(100vh - 200px), ' + aMaxH + 'px)';

        aDropdownCot.appendChild(this.mOptionsContainer);

        aDropdown.appendChild(aDropdownCot);

        if (true == pParams.isVertical) {
            this.mContainer.classList.add('vertical');
        }
        let aFilterFunc = (pVal: string, pFocus: boolean = true) => {
            this._FilterByString(pVal);
            this._setPosition(aDropdown, aSelectButton, aSearchInput, aDropdownCot);

            if (true == pFocus) {
                aSearchInput.focus();
            }
        };

        let aSearchInput: HTMLInputElement;
        if (true == pParams.search) {
            aSearchInput = document.createElement('input');
            aSearchInput.classList.add('c_search');
            this.mOptionsContainer.addEventListener('mouseup', (e) => e.stopPropagation());

            aSearchInput.addEventListener('input', () => aFilterFunc(aSearchInput.value));
            aSearchInput.addEventListener('mouseup', (e) => e.stopPropagation());
        }

        if (null != pParams.options) {
            for (let i = 0; i < pParams.options.length; i++) {
                let aOption = pParams.options[i];

                let aValue = aOption.value;
                let aText = (null != aOption.text) ? aOption.text : aValue;

                let aSelectOption = document.createElement('option') as iOP3DHTMLOptionElement;
                aSelectOption.text = aText;
                aSelectOption.value = aValue;
                aSelectOption.isPremiumOnly = aOption.isPremiumOnly;
                aSelectOption.qa_id = aOption.qa_id;
                aSelectOption.data = aOption.data;
                this.mSelectElement.add(aSelectOption);
            }
        }

        aDropdown.addEventListener('wheel', e => e.stopPropagation());

        let aCloseFunc = () => {
            ViewUtils.removeFromParent(aDropdown);
            window.removeEventListener('mouseup', aCloseFunc);
            aSelectButton.classList.remove('open');
        }

        aSelectButton.addEventListener('mouseup', (e) => {
            e.stopPropagation();
            let aIsOpen = ViewUtils.toggleClass(aSelectButton, "open");

            if (aIsOpen) {
                window.addEventListener('mouseup', aCloseFunc);
                if (null != aSearchInput) {
                    aSearchInput.value = '';
                }
                aFilterFunc('', false);
            } else {
                aCloseFunc();
            }





            //aSelectButton.classList.add('open');
        });

        this.mOnChangeSelect = (pDispatchEvent: boolean = true) => {
            let aSelectedIndex = this.mSelectElement.selectedIndex;
            if (-1 == aSelectedIndex) {
                aButtonSpan.innerHTML = (null != pParams.placeHolder) ?
                    pParams.placeHolder : '';
                return;
            }

            aButtonSpan.innerHTML = this.mSelectElement.options[aSelectedIndex].text;

            if ((true == pDispatchEvent) && (null != pParams.onChange)) {
                pParams.onChange(this.mSelectElement.value);
            }
            aCloseFunc();
        }

        this.mSelectElement.addEventListener('change', () => this.mOnChangeSelect());

        if (null != pParams.defaultOptionValue) {
            this.mSelectElement.value = pParams.defaultOptionValue;
            aSelectButton.children[0].innerHTML = this.mSelectElement.selectedOptions[0].text;
        }

        return this;
    }
    //__________________________________________________________________________________________
    private _FilterByString(pSearchVal: string) {
        let aSearchVal = pSearchVal.toUpperCase();
        let aFilteredOptions = new Array<iOP3DHTMLOptionElement<iCustomSelectOption>>();
        let aOptions = this.mSelectElement.options;
        for (let i = 0; i < aOptions.length; i++) {
            let aCurrOption = aOptions[i] as iOP3DHTMLOptionElement<iCustomSelectOption>;

            if (aCurrOption.text.toUpperCase().indexOf(aSearchVal) > -1) {
                aFilteredOptions.push(aCurrOption);
            }
        }

        aFilteredOptions.sort((a, _b) => (true == a.isPremiumOnly) ? 1 : -1);

        this._filterOptions(aFilteredOptions);
    }
    //__________________________________________________________________________________________
    private _filterOptions(pOptions: Array<iOP3DHTMLOptionElement<any>>) {
        ViewUtils.removeElementChildren(this.mOptionsContainer);
        let aIsPremiumButtonAdded = false;
        for (let i = 0; i < pOptions.length; i++) {
            const aOption = pOptions[i];

            let aOp = document.createElement('c_drop_option') as iCustomSelectOptionElement;
            let aValue = aOption.value;
            let aText = (null != aOption.text) ? aOption.text : aValue;

            aOp.setAttribute('text', aText);
            aOp.innerHTML = aText;
            aOp.value = aValue;
            ViewUtils.setElementDisabled(aOp, (false == aOption.enable));

            if (aOption.qa_id != null) {
                aOp.setAttribute("qa_id", aOption.qa_id)
            }

            let aIsFreeUser = (true == Op3dContext.USER_PERMISSION.isFreeUser);
            if ((true === aIsFreeUser) && (true === aOption.isPremiumOnly)) {
                if (false == aIsPremiumButtonAdded) {
                    this._addPremiumButton();
                    aIsPremiumButtonAdded = true;
                }
                aOp.classList.add('po');
            } else {
                aOp.addEventListener('mouseup', (e) => {
                    switch (e.button) {
                        case eMouseButtons.Main:
                            if (aOption.value === this.mSelectElement.value) {
                                window.dispatchEvent(new Event('mouseup'));
                                return;
                            }

                            this.mSelectElement.value = aOption.value;
                            this.mSelectElement.dispatchEvent(new Event('change'));
                            break;
                        case eMouseButtons.Secondary:
                            e.stopPropagation();

                            if (undefined !== aOption.context_menu_items) {
                                ContextMenu.instance.open({
                                    position: { x: e.clientX, y: e.clientY },
                                    items: aOption.context_menu_items
                                });
                            } else {
                                ContextMenu.instance.close();
                            }
                            break;

                    }
                });
            }

            this.mOptionsContainer.appendChild(aOp);
        }
    }
    //__________________________________________________________________________________________
    private _addPremiumButton() {
        let aPFButton = document.createElement('div');
        aPFButton.classList.add('pf_button');
        aPFButton.classList.add('pf_small');
        aPFButton.addEventListener('mouseup', () => {
            let aAnchor = document.createElement('a');
            aAnchor.target = '_blank';
            aAnchor.href = ServerContext.pricing_base_link;
            aAnchor.click();
        });

        this.mOptionsContainer.appendChild(aPFButton);
    }
    //__________________________________________________________________________________________
    private _setPosition(pDropdown: HTMLElement, pSelectButton: HTMLElement,
        pSearchInput: HTMLInputElement, pDropdownCot: HTMLElement) {

        let aBB = pSelectButton.getBoundingClientRect();
        document.body.appendChild(pDropdown);

        if (true == this.mContainer.classList.contains('vertical')) {
            pDropdown.style.width = aBB.height + 'px';
            pDropdown.style.left = aBB.left + aBB.width + 'px';
            pDropdown.style.top = aBB.top + 'px';
        } else {
            pDropdown.style.left = aBB.left + 'px';
            pDropdown.style.width = aBB.width + 'px';

            if ((window.innerHeight - aBB.bottom) < 200) {
                if (null != pSearchInput) {
                    pDropdownCot.appendChild(pSearchInput);
                }

                let aDropdownBB = pDropdown.children[0].getBoundingClientRect();
                pDropdown.style.top = (aBB.top - 2 - aDropdownBB.height) + 'px';

            } else {
                pDropdown.style.top = (aBB.bottom + 2) + 'px';
                if (null != pSearchInput) {
                    pDropdownCot.insertBefore(pSearchInput, pDropdownCot.firstChild);
                }
            }
        }
    }
    //__________________________________________________________________________________________
}