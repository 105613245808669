export class Links {

    public static CATEGORIES_JSON = './json/partCategories.json';
    public static CONFIGURATION = './json/config.json';
    // public static PARTS_JSON = './json/parts.json';
    public static OPTICS_IMAGES_PATHS_JSON = './json/opticsImages.json';

    public static LIGHTS_PATH = './assets/scene/lights.json';

    // public static UPDATE_PREFIX(pPrefix: string) {
    //     let aPrefix = (null != pPrefix && "" != pPrefix) ? pPrefix + '/' : '';

    //     Links.CATEGORIES_JSON = './' + aPrefix + 'json/partCategories.json?V=' + Op3dContext.VERSION_NUM;
    //     Links.PARTS_JSON = './' + aPrefix + 'json/parts.json';
    //     Links.OPTICS_IMAGES_PATHS_JSON = './' + aPrefix + 'json/opticsImages.json';
    //     Links.LIGHTS_PATH = './' + aPrefix + 'assets/scene/lights.json';
    // }
}
