import { Op3dContext } from "../../_context/Op3dContext";
import { iMaterialVO, iMaterialMetaDataVO } from "../../_context/_interfaces/Interfaces";
import { iPopupBtn } from "../_globals/uiInterfaces";
import { MaterialDataLoader } from "../../data/data_loader/MaterialDataLoader";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { MaterialInformationForm } from "../infos/MaterialInformationForm";
import { ViewUtils } from "../ViewUtils";

export interface iPopupParamsMat {
    text: string;
    yesBtn?: iPopupBtn;
    noBtn?: iPopupBtn;
}

export class PopupChooseMaterial extends Op3dComponentBase<iPopupParamsMat> {

    private mYesBtn: HTMLElement;
    private mNoBtn: HTMLElement;
    private mPopupFooter: HTMLElement;
    private mMaterialsDropdown: HTMLSelectElement;
    private mMaterialCharWindow: HTMLElement;
    private mMaterialVO: iMaterialVO;
    private mMaterials: iMaterialMetaDataVO[];

    //__________________________________________________________________________________________
    public constructor() {
        let aContainer = document.createElement('div');
        aContainer.classList.add('modal');
        aContainer.classList.add('fade');
        aContainer.style.zIndex = '2000';
        aContainer.setAttribute('data-backdrop', 'static');
        document.getElementById('forms').appendChild(aContainer);
        super({
            container: aContainer,
            skinPath: './skins/forms/popup_choose_material.html'
        });
        this.mContainer = aContainer

    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mYesBtn = this._getPart("yes-popup-btn");
        this.mNoBtn = this._getPart("no-popup-btn");
        this.mPopupFooter = this._getPart("popup-footer");

        this.mYesBtn.addEventListener("click", () => this._onBtnClicked(this.mData.yesBtn, this.mMaterialsDropdown.value));
        this.mNoBtn.addEventListener("click", () => this._onBtnClicked(this.mData.noBtn));


        this.mMaterialsDropdown = this._getPart('materials-select-cm') as HTMLSelectElement;
        this.mMaterialsDropdown.value = 'Select'
        this.mMaterialCharWindow = this._getPart('material_info_cm')


        this.mMaterialsDropdown.addEventListener('change', async () => {
            let aNumberID = this.mMaterialsDropdown.value;
            let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({
                number_id: aNumberID
            });
            this.mMaterialVO = aMaterialVO;

        });
        this.mMaterialCharWindow.addEventListener('click',
            () => {
                MaterialInformationForm.instance.container.style.zIndex = '2500'
                return MaterialInformationForm.instance.open(this.mMaterialVO)
            }
        );

        this.mMaterials = await Op3dContext.DATA_MANAGER.getMaterials();
        for (let i = 0; i < this.mMaterials.length; i++) {
            let aOption = document.createElement('option');
            aOption.text = this.mMaterials[i].name;
            aOption.value = this.mMaterials[i].number_id;
            this.mMaterialsDropdown.appendChild(aOption);
        }
    }
    //__________________________________________________________________________________________
    private _onBtnClicked(pData: iPopupBtn, pValue?: string) {
        if (pData && pData.callback) {
            if (pValue != null) {
                pData.callback(pValue);
            } else {
                pData.callback();
            }
        }
    }
    //__________________________________________________________________________________________
    protected _onHidden(): void {
        super._onHidden();
        this.mData = null;
    }
    //__________________________________________________________________________________________
    private _setBtnName(pBtn: HTMLElement, pData: iPopupBtn, pDefaultName: string) {
        if (pData != null) {
            pBtn.innerHTML = pData.title != null ? pData.title : pDefaultName;
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData: iPopupParamsMat): void {
        if (null == pData) {
            return;
        }
        this.mData = pData;

        ViewUtils.setElementVisibilityByDNone(this.mPopupFooter,
            this.mData.noBtn != null || this.mData.yesBtn != null);

        ViewUtils.setElementVisibilityByDNone(this.mNoBtn, this.mData.noBtn != null);
        this._setBtnName(this.mNoBtn, this.mData.noBtn, "Cancel");
        ViewUtils.setElementVisibilityByDNone(this.mYesBtn, this.mData.yesBtn != null);
        this._setBtnName(this.mYesBtn, this.mData.yesBtn, "Confirm");

    }
    //__________________________________________________________________________________________
}

