import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../../parts/Part";
import { iPartChange, iColorChange, eChangesType } from "../../parts/PartInterfaces";
import { iEventData } from "../../parts/base_3d/Button3D";
import { SceneContext } from "../../scene/SceneContext";

import { Op3dComponentBase } from "../Op3dComponentBase";
import { eClickMode, PartsEventsHandler } from "../_globals/PartsEventsHandler";

export class ColorPicker extends Op3dComponentBase {

    private static INSTANCE: ColorPicker;
    public static IS_CHOOSE_OBJECT: boolean;
    private mFace: HTMLInputElement;
    private mColor: HTMLInputElement;
    private mFaceIndexStart: any;
    private mFaceIndexEnd: any;
    private mEvent: iEventData;
    private mCurrentFace: any;
    private mChangedFaces: Array<iPartChange<iColorChange>>;
    private mCurrentPart: Part;
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/color_picker.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ColorPicker.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.style.width = "300px";
            document.getElementById('forms').appendChild(aDiv);

            ColorPicker.INSTANCE = new ColorPicker(aDiv);
        }

        return ColorPicker.INSTANCE;
    }
    //__________________________________________________________________________________________
    private _changeFaceColor() {
        const r = parseInt(this.mColor.value.substr(1, 2), 16)
        const g = parseInt(this.mColor.value.substr(3, 2), 16)
        const b = parseInt(this.mColor.value.substr(5, 2), 16)

        for (let i = this.mFaceIndexStart; i <= this.mFaceIndexEnd; i++) {
            this.mEvent.face.visualization.mesh.geometry.attributes['color'].setXYZ(i, r / 255, g / 255, b / 255);
        }
        (this.mEvent.face.visualization.mesh.geometry.attributes['colorBase'] as any).copy(this.mEvent.face.visualization.mesh.geometry.attributes['color'])
        this.mCurrentFace.colors = [r / 255, g / 255, b / 255]
        this.mCurrentFace.colorsBase = [r / 255, g / 255, b / 255]

        if (this.mChangedFaces == undefined) {
            this.mChangedFaces = []
        }

        this.mChangedFaces.push({
            type: eChangesType.COLOR,
            data: {
                path: this.mCurrentFace.path,
                color: this.mCurrentFace.colors,
                start: this.mFaceIndexStart,
                end: this.mFaceIndexEnd,
            }

        })

        this.mEvent.face.visualization.mesh.geometry.attributes['color'].needsUpdate = true
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pIsColorObject: boolean) {
        if (ColorPicker.INSTANCE) {
            if (pIsColorObject == true) {
                Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(SceneContext.CHOOSE_MODE.mode);
                return
            }
            Op3dContext.PARTS_MANAGER.isToShowEdges(true)
            Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(SceneContext.CHOOSE_MODE.mode);

        }
    }
    //__________________________________________________________________________________________
    private _leaveColorMode() {
        Op3dContext.PARTS_MANAGER.onMouseDown(undefined, undefined)

        Op3dContext.PARTS_MANAGER.isToShowVertices(false)
        Op3dContext.PARTS_MANAGER.isToShowEdges(false)

        SceneContext.CHOOSE_MODE.mode = eClickMode.BASE
        Op3dContext.PARTS_EVENTS_HANDLER.resetHighlighting()

        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(eClickMode.BASE);
    }
    //__________________________________________________________________________________________
    protected _onClose() {
        this.mFace.value = ''
        this.mFaceIndexStart = null
        this.mFaceIndexEnd = null
        this.mEvent = null

        this.mCurrentFace = null;
        this.mChangedFaces = [];
        this.mCurrentPart = null;
        ColorPicker.IS_CHOOSE_OBJECT = false
        this._leaveColorMode()
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mFace = this._getPart('current_face') as HTMLInputElement;
        this.mColor = this._getPart('current_color') as HTMLInputElement;
        this._getPart('update_btn').addEventListener('click', () => this.onUpdatePart())
        // this._getPart('save_btn').addEventListener('click', () => this._onSave())

        this._getPart('apply_btn').addEventListener('click', () => this.onApply())
    }

    //__________________________________________________________________________________________
    public setCurrentFace(pCurrentData) {
        this.mCurrentFace = pCurrentData.face
        if (SceneContext.CHOOSE_MODE.mode === eClickMode.COLOR_PART) {
            this.mFaceIndexStart = 0
            this.mFaceIndexEnd = pCurrentData.event.face.visualization.mesh.geometry.attributes['color'].array.length
            this.mCurrentFace.path = []
        } else if (SceneContext.CHOOSE_MODE.mode === eClickMode.COLOR_SOLID) {
            let aSolidNumber = pCurrentData.face.path[1]
            let aSolidFirst = pCurrentData.event.face.indexes.find(el => el.path[1] == aSolidNumber)
            let aSolidLast = pCurrentData.event.face.indexes.findLast(el => el.path[1] == aSolidNumber)
            this.mFaceIndexStart = aSolidFirst.indexes.start
            this.mFaceIndexEnd = aSolidLast.indexes.end

        } else {
            this.mFaceIndexStart = pCurrentData.start
            this.mFaceIndexEnd = pCurrentData.end
        }

        this.mEvent = pCurrentData.event
        this.mFace.value = pCurrentData.face.name

        this.mCurrentPart = pCurrentData.part
    }
    //__________________________________________________________________________________________
    private onApply() {
        this._changeFaceColor()
    }

    //__________________________________________________________________________________________
    private async onUpdatePart() {

        // let aCurrentPart = await server.ServerContext.SERVER.getPartById({ number_id: this.mCurrentPart.iPart.partVO.number_id })
        // let aCurrentPart = PartsDataLoader.instance.getFromCache(this.mCurrentPart.iPart.partVO.number_id, false) as
        //     iPartMongoDB;
        // let aPartChanges = JSON.parse(aCurrentPart.changes)
        PartsEventsHandler.addChangesToPart(this.mCurrentPart, this.mChangedFaces, this.mEvent.part)
        // let aPartVOMainPart = Op3dContext.DATA_MANAGER.getPartVOById(this.mCurrentPart.id)
        // if (aPartVOMainPart.isAssembly == true) {
        //     let index = this.mCurrentPart.subParts.indexOf(this.mEvent.part)
        //     let aPartsData = PartsDataLoader.instance.getFromCache(aPartVOMainPart.number_id, false) as iPartMongoDB;

        //     if (aPartsData.permission == eDataPermission.PRIVATE) {
        //         if (aPartsData.owner !== undefined && aPartsData.owner == Op3dContext.USER_VO.id) {

        //             let aPartInAssembly = JSON.parse(aPartsData.assembly_parts)[index] as iAssemblyMongoDB

        //             let aCurrentPart = await (await server.ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
        //             let aPreviousChanges = (null != aCurrentPart.changes) ?
        //                 JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;
        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)

        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aPartInAssembly.number_id
        //             });



        //         } else {
        //             let aCopiedAssembly = await server.ServerContext.SERVER.copyAssembly({ number_id: aPartsData.number_id });

        //             let aPartInAssembly = JSON.parse(aCopiedAssembly.data.assembly_parts)[index] as iAssemblyMongoDB
        //             // PartsEventsHandler.addChangesToPart(aPartInAssembly.number_id, this.mChangedFaces, this.mCurrentPart)

        //             let aCurrentPart = await (await server.ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
        //             let aPreviousChanges = (null != aCurrentPart.changes) ?
        //                 JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;

        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)


        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aPartInAssembly.number_id
        //             });

        //             if (aRes.success == true) {
        //                 let aNumberID = aCopiedAssembly.data.number_id;
        //                 let aJSONData = this.mCurrentPart.exportToJSONObject();
        //                 let aNewPart =
        //                     await PartsDataLoader.instance.getSingleFullData({
        //                         number_id: aNumberID
        //                     });

        //                 let aPartNew = new Part(this.mCurrentPart.id + aNumberID).add(aNewPart);

        //                 // aPartNew.iPart.partVO = new PartVO(aPartVO)
        //                 Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart);
        //                 aPartNew.initFromJSON(aJSONData, true);
        //                 Op3dContext.DATA_MANAGER.addToPartsData(aCopiedAssembly.data.info)
        //             }
        //         }
        //     }

        //     if (aPartsData.permission == eDataPermission.PUBLIC) {
        //         if (Op3dContext.USER_VO.isAdmin == true) {
        //             let aPartInAssembly = JSON.parse(aPartsData.assembly_parts)[index] as iAssemblyMongoDB

        //             let aCurrentPart = await (await server.ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
        //             let aPreviousChanges = (null != aCurrentPart.changes) ?
        //                 JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;
        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)

        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aPartInAssembly.number_id
        //             });
        //         } else if (Op3dContext.USER_VO.isBasic == true) {
        //             let aCopiedAssembly = await server.ServerContext.SERVER.copyAssembly({ number_id: aPartsData.number_id });

        //             let aPartInAssembly = JSON.parse(aCopiedAssembly.data.assembly_parts)[index] as iAssemblyMongoDB
        //             // PartsEventsHandler.addChangesToPart(aPartInAssembly.number_id, this.mChangedFaces, this.mCurrentPart)

        //             let aCurrentPart = await (await server.ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
        //             let aPreviousChanges = (null != aCurrentPart.changes) ?
        //                 JSON.parse(aCurrentPart.changes) : null as Array<iPartChange>;

        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)


        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aPartInAssembly.number_id
        //             });

        //             if (aRes.success == true) {
        //                 let aNumberID = aCopiedAssembly.data.number_id;
        //                 let aJSONData = this.mCurrentPart.exportToJSONObject();
        //                 let aNewPart =
        //                     await PartsDataLoader.instance.getSingleFullData({
        //                         number_id: aNumberID
        //                     });

        //                 let aPartNew = new Part(this.mCurrentPart.id + aNumberID).add(aNewPart);

        //                 // aPartNew.iPart.partVO = new PartVO(aPartVO)
        //                 Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart);
        //                 aPartNew.initFromJSON(aJSONData, true);
        //                 Op3dContext.DATA_MANAGER.addToPartsData(aCopiedAssembly.data.info)
        //             }
        //         }
        //     }

        //     // let aSubParts = JSON.parse(aPartsData.assembly_parts)


        //     // // let aPartInAssembly = JSON.parse(aPartsData.assembly_parts)[index] as iAssemblyMongoDB
        //     // let aPartInAssembly = JSON.parse(aPartsData.assembly_parts)[index] as iAssemblyMongoDB
        //     // // let aPartData = PartsDataLoader.instance.getFromCache(aPartInAssembly.number_id, false) as iPartMongoDB;
        //     // // let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mEvent.part.name)
        //     // // PartsEventsHandler.addChangesToPart(this.mEvent.part.partVO.number_id, this.mChangedFaces)
        //     // PartsEventsHandler.addChangesToPart(aPartInAssembly.number_id, this.mChangedFaces, this.mCurrentPart)
        //     // PartsEventsHandler.addChangesToPart(aPartVO.number_id, this.mChangedFaces)
        // } else {
        //     let aPartsData = PartsDataLoader.instance.getFromCache(aPartVOMainPart.number_id, false) as iPartMongoDB;

        //     // let aPartClone = await server.ServerContext.SERVER.copyPart({ number_id: Op3dContext.DATA_MANAGER.getPartVOById(this.mAssemblyPart.iPart.subParts[i].name).number_id })
        //     let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mCurrentPart.id)

        //     if (aPartsData.permission == eDataPermission.PUBLIC) {
        //         if (Op3dContext.USER_VO.isAdmin == true) {

        //             // let aCurrentPart = await (await server.ServerContext.SERVER.getPartById({ number_id: aPartInAssembly.number_id })).data
        //             let aPreviousChanges = (null != aPartsData.changes) ?
        //                 JSON.parse(aPartsData.changes) : null as Array<iPartChange>;
        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)

        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aPartsData.number_id
        //             });
        //         } else if (Op3dContext.USER_VO.isBasic == true) {
        //             let aCopiedAssembly = await server.ServerContext.SERVER.copyPart({ number_id: aPartsData.number_id });

        //             let aPreviousChanges = (null != aPartsData.changes) ?
        //                 JSON.parse(aPartsData.changes) : null as Array<iPartChange>;

        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)


        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aCopiedAssembly.data.number_id
        //             });

        //             if (aRes.success == true) {
        //                 let aNumberID = aCopiedAssembly.data.number_id;
        //                 let aJSONData = this.mCurrentPart.exportToJSONObject();
        //                 let aNewPart =
        //                     await PartsDataLoader.instance.getSingleFullData({
        //                         number_id: aNumberID
        //                     });

        //                 let aPartNew = new Part(this.mCurrentPart.id + aNumberID).add(aNewPart);

        //                 // aPartNew.iPart.partVO = new PartVO(aPartVO)
        //                 Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart);
        //                 aPartNew.initFromJSON(aJSONData, true);
        //                 Op3dContext.DATA_MANAGER.addToPartsData(aCopiedAssembly.data.info)
        //             }
        //         }
        //     }

        //     if (aPartsData.permission == eDataPermission.PRIVATE) {
        //         if (aPartsData.owner !== undefined && aPartsData.owner == Op3dContext.USER_VO.id) {
        //             let aPreviousChanges = (null != aPartsData.changes) ?
        //                 JSON.parse(aPartsData.changes) : null as Array<iPartChange>;
        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)

        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aPartsData.number_id
        //             });
        //         } else {
        //             let aCopiedAssembly = await server.ServerContext.SERVER.copyPart({ number_id: aPartsData.number_id });

        //             let aPreviousChanges = (null != aPartsData.changes) ?
        //                 JSON.parse(aPartsData.changes) : null as Array<iPartChange>;

        //             let aUpdatedChanges = PartsEventsHandler.filterChanges(aPreviousChanges, this.mChangedFaces)


        //             let aRes = await server.ServerContext.SERVER.addChangesToPart({
        //                 changes: JSON.stringify(aUpdatedChanges),
        //                 number_id: aCopiedAssembly.data.number_id
        //             });

        //             if (aRes.success == true) {
        //                 let aNumberID = aCopiedAssembly.data.number_id;
        //                 let aJSONData = this.mCurrentPart.exportToJSONObject();
        //                 let aNewPart =
        //                     await PartsDataLoader.instance.getSingleFullData({
        //                         number_id: aNumberID
        //                     });

        //                 let aPartNew = new Part(this.mCurrentPart.id + aNumberID).add(aNewPart);

        //                 // aPartNew.iPart.partVO = new PartVO(aPartVO)
        //                 Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart);
        //                 aPartNew.initFromJSON(aJSONData, true);
        //                 Op3dContext.DATA_MANAGER.addToPartsData(aCopiedAssembly.data.info)
        //             }
        //         }
        //     }
        //     // PartsEventsHandler.addChangesToPart(this.mCurrentPart.iPart.partVO.number_id, this.mChangedFaces, this.mCurrentPart)
        //     // PartsEventsHandler.addChangesToPart(aPartVO.number_id, this.mChangedFaces, this.mCurrentPart)
        // }



        // //!isAssembly
        // if (aPartAssembly.length !== 0) {

        // }

        // if (aCurrentPart.permission == eDataPermission.PUBLIC) {
        //     if (Op3dContext.USER_VO.isAdmin == true) {
        //         PartsEventsHandler.addChangesToPart(this.mCurrentPart.iPart.partVO.number_id, this.mChangedFaces)
        //     } else if (Op3dContext.USER_VO.isBasic == true) {
        //         let aName = prompt('Enter part name', '')
        //         let aDiscr = prompt('Enter part def', '')

        //         if (aName.length == 0 || aDiscr.length == 0) {
        //             return
        //         }
        //         let aId = Op3dUtils.idGenerator()


        //         let aPartVO: iPartVOData = {
        //             number_id: aId,
        //             id: aName + aId,
        //             name: aDiscr,
        //             label: aName + aId,
        //             url: this.mCurrentPart.iPart.partVO.baseUrl,
        //             sideBarPart: true,
        //             isOptix: true,
        //             isAssembly: false,
        //             main_section: "Parts",
        //             section: "Custom",
        //             category: "Custom",
        //             subCategory: "Custom",
        //             laserData: [],
        //             initialHeight: 4,
        //             isEdited: true
        //         }

        //         let aPartMongo: iPartMongoDB = {
        //             permission: eDataPermission.PRIVATE,
        //             number_id: aId,
        //             name: aName + aId,
        //             changes: aCurrentPart.changes,
        //             assembly_parts: undefined,
        //             info: aPartVO,
        //             owner: Op3dContext.USER_VO.id
        //         }

        //         server.ServerContext.SERVER.addPart(aPartMongo)
        //         // let aMatrix = this.mCurrentPart.visibleObj.matrix.clone()
        //         // let aPart = new Part(aName + aId).add(this.mCurrentPart.iPart.subParts[0])
        //         // aPart.iPart.partVO = new PartVO(aPartVO)
        //         // aPart.visibleObj.applyMatrix4(aMatrix)

        //         // Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart)

        //         this.mCurrentPart.iPart.partVO = new PartVO(aPartVO);

        //         let aNumberID = this.mCurrentPart.iPart.partVO.number_id;
        //         let aJSONData = this.mCurrentPart.exportToJSONObject();
        //         let aNewPart =
        //             await PartsDataLoader.instance.getSingleFullData({
        //                 number_id: aNumberID
        //             });
        //         let aPartNew = new Part(aName + aId).add(aNewPart);
        //         Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart);
        //         aPartNew.initFromJSON(aJSONData, true);

        //         PartsEventsHandler.addChangesToPart(aId, this.mChangedFaces)
        //     }
        // }

        // if (aCurrentPart.permission == eDataPermission.PRIVATE) {
        //     if (aCurrentPart.owner !== undefined && aCurrentPart.owner == Op3dContext.USER_VO.id) {
        //         PartsEventsHandler.addChangesToPart(this.mCurrentPart.iPart.partVO.number_id, this.mChangedFaces)
        //     } else {
        // let aName = prompt('Enter part name', '')
        // let aDiscr = prompt('Enter part def', '')

        // if (aName.length == 0 || aDiscr.length == 0) {
        //     return
        // }
        // let aId = Op3dUtils.idGenerator()


        // let aPartVO:iPartVOData = {
        //     number_id: aId,
        //     id: aName + aId,
        //     name: aDiscr,
        //     label: aName + aId,
        //     url: this.mCurrentPart.iPart.partVO.baseUrl,
        //     sideBarPart: true,
        //     isOptix: true,
        //     isAssembly: false,
        //     main_section: "Parts",
        //     section: "Custom",
        //     category: "Custom",
        //     subCategory: "Custom",
        //     laserData: [],
        //     initialHeight: 4,
        //     isEdited: true
        // }

        // let aPartMongo: iPartMongoDB = {
        //     permission: eDataPermission.PRIVATE,
        //     number_id: aId,
        //     name: aName + aId,
        //     changes: aCurrentPart.changes,
        //     assembly_parts: undefined,
        //     info: aPartVO,
        //     owner: Op3dContext.USER_VO.id
        // }

        // let aAddFunc = await server.ServerContext.SERVER.addPart(aPartMongo)
        // if (aAddFunc.success == true) {
        //     let aNumberID = aId;
        //     let aJSONData = this.mCurrentPart.exportToJSONObject();
        //     let aNewPart =
        //         await PartsDataLoader.instance.getSingleFullData({
        //             number_id: aNumberID
        //         });
        //     let aPartNew = new Part(aName + aId).add(aNewPart);
        //     Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart);
        //     aPartNew.initFromJSON(aJSONData, true);


        //     PartsEventsHandler.addChangesToPart(aId, this.mChangedFaces)
        // }
        // let aMatrix = this.mCurrentPart.visibleObj.matrix.clone()
        // let aPart = new Part(aName + aId).add(this.mCurrentPart.iPart.subParts[0])
        // aPart.iPart.partVO = new PartVO(aPartVO)
        // aPart.visibleObj.applyMatrix4(aMatrix)

        // Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart)

        // this.mCurrentPart.iPart.partVO = new PartVO(aPartVO);


        //     }

        // }
        // //!isEdited
        // if (aPartChanges.length !== 0) {



        // } else {
        // let aName = prompt('Enter part name', '')
        // let aDiscr = prompt('Enter part def', '')

        // if (aName.length == 0 || aDiscr.length == 0) {
        //     return
        // }
        // let aId = Op3dUtils.idGenerator()


        // let aPartVO: iPartVOData = {
        //     number_id: Op3dUtils.idGenerator(),
        //     id: aName + aId,
        //     name: aDiscr,
        //     label: aName + aId,
        //     url: this.mCurrentPart.iPart.partVO.baseUrl,
        //     sideBarPart: true,
        //     isOptix: true,
        //     isAssembly: false,
        //     main_section: "Assembly",
        //     section: "Custom",
        //     category: "Custom",
        //     subCategory: "Custom",
        //     laserData: [],
        //     initialHeight: 4,
        //     isEdited: true
        // }

        // let aPartMongo: iPartMongoDB = {
        //     permission: eDataPermission.PRIVATE,
        //     number_id: Op3dUtils.idGenerator(),
        //     name: aName + aId,
        //     changes: JSON.stringify(this.mChangedFaces),
        //     assembly_parts: undefined,
        //     info: aPartVO,
        //     owner: Op3dContext.USER_VO.id
        // }

        // server.ServerContext.SERVER.addPart(aPartMongo)
        // let aMatrix = this.mCurrentPart.visibleObj.matrix.clone()
        // let aPart = new Part(aName + aId).add(this.mCurrentPart.iPart.subParts[0])
        // aPart.iPart.partVO = new PartVO(aPartVO)
        // aPart.visibleObj.applyMatrix4(aMatrix)

        // Op3dContext.PARTS_MANAGER.deletePart(this.mCurrentPart)

        // }

        this._onClose()
        this.close()
        // }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
        (<any>$(this.mContainer)).draggable()
    }
    //__________________________________________________________________________________________
}