import moment from "moment";
import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ViewUtils } from "../../ViewUtils";
import { iNotificationData, iNoteHTMLData, eNotificationToastDuration, eNotificationType } from "./NotificationsContext";

// var moment = moment;

export class NotificationNote {

    private mContainer: HTMLElement;
    private mData: iNotificationData;
    private mTime: HTMLElement;
    private mCreatedAt: number;
    private mNoteHtmlData: iNoteHTMLData;
    private mEventListener: () => void;
    private mIsToast: boolean = true; // when the notiication is not loacted in notification center

    constructor(pElement: HTMLElement, pData: iNotificationData, pHTMLData: iNoteHTMLData) {
        this.mContainer = pElement;
        this.mNoteHtmlData = pHTMLData;
        this.mData = pData;
        this.mCreatedAt = Date.now();
        this._initElements();
    }
    //_______________________________________________________________
    private async _initElements() {
        let aNotificationType = Op3dUtils.getElementIn(this.mContainer, "notification-type", true);
        aNotificationType.innerHTML = this.mData.params.title;

        let aColorBox = Op3dUtils.getElementIn(this.mContainer, "color-box", true);
        ViewUtils.setElementVisibilityByDNone(aColorBox, this.mData.params.isColorBoxVisible);
        aColorBox.style.background = this.mData.params.color;

        let aNotificationMessage = Op3dUtils.getElementIn(this.mContainer, "notification-message", true);
        aNotificationMessage.innerHTML = this.mData.message;

        this.mTime = Op3dUtils.getElementIn(this.mContainer, "notification-time", true);
        this.update();

        let aDeleteBtn = Op3dUtils.getElementIn(this.mContainer, "delete-notitication-btn", true);
        aDeleteBtn.addEventListener("click", () => this._onDelete());

        this.mNoteHtmlData.firstParent.appendChild(this.mContainer);
        this.mContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });

        await Op3dContext.sleep(50);

        let aToastDuration = this.mData.toastDuration != null ? this.mData.toastDuration : eNotificationToastDuration.MEDIUM;
        if (this.mData.toastDuration != eNotificationToastDuration.INFINITE) {
            this.mContainer.style.transitionDelay = aToastDuration + "s";
            this.mContainer.style.transitionDuration = "0.35s";
            this.mEventListener = () => this._onTansitionEnd();
            this.mContainer.addEventListener('transitionend', this.mEventListener);
            ViewUtils.setClassState(this.mContainer, 'hide', true);
        }

        this._initDontShowAgain();
    }
    //_______________________________________________________________
    public get type(): eNotificationType {
        return this.mData.params.type;
    }
    //_______________________________________________________________
    private _initDontShowAgain() {
        let aDontShowAgain = Op3dUtils.getElementIn(this.mContainer, 'dont_show_again');
        if (null == this.mData.dontShowAgainCallback) {
            ViewUtils.removeFromParent(aDontShowAgain);
            return;
        }

        let aInput = Op3dUtils.getElementIn(aDontShowAgain, 'dont_show_again_input', true) as HTMLInputElement;
        let aLabel = aInput.nextElementSibling as HTMLLabelElement;
        aLabel.htmlFor = aInput.id;

        aInput.addEventListener('change', () => this.mData.dontShowAgainCallback(aInput.checked));
    }
    //_______________________________________________________________
    private _onTansitionEnd() {
        // in case of the notification was removes but the event didnt finished
        if (this.mContainer) {
            this.mContainer.removeEventListener("transitionend", this.mEventListener);
        }
        ViewUtils.setClassState(this.mContainer, 'hide', false);
        this._makeAsNotificationNote();
    }
    //_______________________________________________________________
    private _makeAsNotificationNote() {
        if (this.mNoteHtmlData && this.mNoteHtmlData.secondParent) {
            this.mIsToast = false;
            this.mNoteHtmlData.secondParent.insertBefore(this.mContainer, this.mNoteHtmlData.secondParent.firstChild);
        }
    }
    //_______________________________________________________________
    public update() {
        let aTime = moment(this.mCreatedAt).fromNow();
        this.mTime.innerHTML = aTime;
    }
    //_______________________________________________________________
    public distract() {
        ViewUtils.removeFromParent(this.mContainer);
        this.mContainer.removeEventListener("transitionend", this.mEventListener);
        this.mContainer = null;
        this.mData = null;
        this.mTime = null;
        this.mCreatedAt = null;
        this.mNoteHtmlData = null;
        this.mEventListener = null;
        this.mIsToast = null;
    }
    //_______________________________________________________________
    private _onDelete() {
        if (this.mIsToast) {
            this._makeAsNotificationNote();
        } else {
            EventManager.dispatchEvent(EventsContext.NOTIFICATION_REMOVED, this, this);
        }
    }
    //_______________________________________________________________
}
