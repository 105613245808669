import { OrthographicCamera, WebGLRenderer, Scene } from "three";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { iChooseMode } from "../_context/_interfaces/Interfaces";
import { Op3dSceneWidget } from "../_widget/Op3dSceneWidget";
import { eClickMode } from "../ui/_globals/PartsEventsHandler";
import { GridManager } from "./GridManager";
import { Op3dScene } from "./Op3dScene";
import { PositionGenerator } from "./PositionGenerator";
import { eViewTypeXYZ } from "./absSceneCube";
import { eViewMenuState } from "./_camera/ViewMenu/ViewMenu";

export class SceneContext {

    public static CONTROLLER_ZOOM_STEPS: number = 1.25;
    public static CAMERA: OrthographicCamera;
    public static CAMERA2: OrthographicCamera;
    public static CAMERA3: OrthographicCamera;
    public static CAMERA4: OrthographicCamera;
    public static SNAPSHOT_CAMERA: OrthographicCamera;
    public static RENDERER: WebGLRenderer;
    public static SPRITE_RENDERER: CSS2DRenderer;
    public static MAIN_SCENE: Scene;
    public static MEASURES_SCENE: Scene;
    public static MOUNT_TEXT_SCENE: Scene;
    public static TUTORIAL_SCENE: Scene;
    public static OP3D_SCENE: Op3dScene | Op3dSceneWidget;
    public static GRID_MANAGER: GridManager;
    public static POSITION_GENERATOR: PositionGenerator;

    public static RENDERER2: WebGLRenderer;
    public static RENDERER3: WebGLRenderer;
    public static RENDERER4: WebGLRenderer;
    public static SNAPSHOT_RENDERER: WebGLRenderer;


    public static CURRENT_VIEW: { type: eViewTypeXYZ, mode: eViewMenuState } = { type: eViewTypeXYZ.ISO_VIEW, mode: eViewMenuState.XYZ }
    public static ISO_VIEW = document.getElementsByClassName('view-1')[0]
    public static XY_VIEW = document.getElementsByClassName('view-2')[0]
    public static YZ_VIEW = document.getElementsByClassName('view-3')[0]
    public static ZX_VIEW = document.getElementsByClassName('view-4')[0]

    public static CHOOSE_MODE: iChooseMode = { mode: eClickMode.BASE }


}
