import { WebGLRenderer } from "three";
import { absSceneCube } from "./absSceneCube";
import { EventsContext } from "../_context/EventsContext";

import { iCubeParams } from "../_context/_interfaces/Interfaces";
import { EventManager } from "../../oc/events/EventManager";

import { EventBase } from "../../oc/events/EventBase";


export class SceneCube extends absSceneCube {

    constructor(pCubeParams: iCubeParams) {
        super(pCubeParams);
    }
    //__________________________________________________________________________________________

    protected _addEventListeners() {
        window.addEventListener('resize', () => this._onWindowResize(), false);

        this.mRenderer.domElement.addEventListener("mousedown",
            (e: MouseEvent) => this.onClick(e));

        EventManager.addEventListener(EventsContext.SHOW_CUBE,
            (pData: EventBase<boolean>) => this._showCube(pData.data), this);
    }
    //__________________________________________________________________________________________
    protected _initRenderer() {
        let aParam = {
            antialias: true,
            preserveDrawingBuffer: true,
            alpha: true
        };
        this.mRenderer = new WebGLRenderer(aParam);
        this.mRenderer.useLegacyLights = false
        this.mRenderer.domElement.id = "cube-dom-element";
        this.mRenderer.setSize(SceneCube.SIZE, SceneCube.SIZE);
        document.getElementsByClassName('view-1')[0].appendChild(this.mRenderer.domElement);
        this._onWindowResize();
    }
    //__________________________________________________________________________________________
}