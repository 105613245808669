﻿import { eMaterialEquationType } from "../../../../_context/Enums";
import { EventsContext } from "../../../../_context/EventsContext";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { iMaterialVO, iOP3DHTMLInputElement } from "../../../../_context/_interfaces/Interfaces";
import { MaterialUtils } from "../../../../_utils/MaterialUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { ViewUtils } from "../../../ViewUtils";
import { iuoMaterial, iMaterialParam, uoMaterial } from "../uoMaterial";
import { uoSection } from "../uoSection";
import { EventManager } from "../../../../../oc/events/EventManager";
import { MaterialDataLoader } from "../../../../data/data_loader/MaterialDataLoader";
import { MaterialInformationForm } from "../../../infos/MaterialInformationForm";

export class uoMaterialNew extends uoSection<iuoMaterial, string> {

    private static SKIN_PATH = './skins/forms/optics/uo_material.html';

    public static CAUCHY_SCHOTT_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            path: 'parameters.coeffs.a'
        },
        {
            name: 'B',
            unit: 'μm<sup>-2</sup>',
            path: 'parameters.coeffs.b'
        },
        {
            name: 'C',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c'
        },
        {
            name: 'D',
            unit: 'μm<sup>4</sup>',
            path: 'parameters.coeffs.d'
        },
        {
            name: 'E',
            unit: 'μm<sup>6</sup>',
            path: 'parameters.coeffs.e'
        },
        {
            name: 'F',
            unit: 'μm<sup>8</sup>',
            path: 'parameters.coeffs.f'
        },
        {
            name: 'G',
            unit: 'μm<sup>10</sup>',
            path: 'parameters.coeffs.g'
        },
        {
            name: 'H',
            unit: 'μm<sup>12</sup>',
            path: 'parameters.coeffs.h'
        }];

    public static SELLMEIER_PARAMS: Array<iMaterialParam> = [
        {
            name: 'B1',
            path: 'parameters.coeffs.b1'
        },
        {
            name: 'C1',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c1'
        },
        {
            name: 'B2',
            path: 'parameters.coeffs.b2'
        },
        {
            name: 'C2',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c2'
        },
        {
            name: 'B3',
            path: 'parameters.coeffs.b3'
        },
        {
            name: 'C3',
            unit: 'μm<sup>2</sup>',
            path: 'parameters.coeffs.c3'
        }
    ];

    public static MODIFIED_SELLMEIER_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            path: 'parameters.coeffs.a'
        },
        ...uoMaterial.SELLMEIER_PARAMS
    ];

    private mMaterialsDropdown: HTMLSelectElement;
    private mMaterialTypeDropDown: HTMLSelectElement;
    private mOneParamElement: HTMLElement;
    private mParamsParent: HTMLElement;
    private mMaterialVO: iMaterialVO;
    private mLambdaDB: iOP3DHTMLInputElement;
    private mN_Input: HTMLInputElement;
    private mRefractiveIndexElement: HTMLElement;

    //__________________________________________________________________________________________
    public static async getOne(pOptions: {
        material: string,
        container: HTMLElement, idx: number,
        wl: number, total_count: number
    }) {

        let aMatContainer = document.createElement('div');
        aMatContainer.classList.add('col-12');
        aMatContainer.classList.add('px-0');
        aMatContainer.classList.add('upload_optics_section');
        aMatContainer.style.borderTop = 'none';
        aMatContainer.setAttribute("qa_id", "qa_material_section_" + (pOptions.idx + 1));

        pOptions.container.appendChild(aMatContainer);
        let aTitle = 'Material ';
        if (pOptions.total_count > 1) {
            aTitle += ((pOptions.idx + 1) + ' ');
        }
        aTitle += 'info';

        let aUOMaterial = new uoMaterialNew(aMatContainer, aTitle);
        await aUOMaterial.setData({
            materialID: pOptions.material,
            wavelength: pOptions.wl
        });

        return aUOMaterial;
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO, pIdx?: number) {
        if (pIdx == 0) {
            pOpticsVO.parameters.materialID = this.mMaterialsDropdown.value;
        } else {
            pOpticsVO.parameters.materialID += ";" + this.mMaterialsDropdown.value;
        }

        let aWL = parseFloat(this.mLambdaDB.value);
        pOpticsVO.parameters.wavelength = aWL || 550;
    }
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pTitle: string) {
        super(pContainer, {
            skinPath: uoMaterialNew.SKIN_PATH,
            title: pTitle,
            collapseQaId: "uo_material_info_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: true
        });
    }
    //__________________________________________________________________________________________
    public discard() {
        ViewUtils.removeFromParent(this.mContainer);
        this._clear();
    }
    //__________________________________________________________________________________________
    public getData(): string {
        return this.mMaterialsDropdown.value;
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: iuoMaterial) {
        this.mMaterialsDropdown.value = pData.materialID;
        this.mLambdaDB.value = (null != pData.wavelength) ? pData.wavelength.toString() : '550';
        this.mLambdaDB.prevValue = this.mLambdaDB.value;
        this._onChangeMaterial(false);
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param') as HTMLElement;
        this.mParamsParent = this.mOneParamElement.parentElement as HTMLElement;
        this._clear();

        this.mMaterialsDropdown = Op3dUtils.getElementIn(this.mContainer,
            'materials-select', true) as HTMLSelectElement;
        this.mMaterialTypeDropDown = Op3dUtils.getElementIn(this.mContainer,
            'materials-type-dropdown') as HTMLSelectElement;
        this.mLambdaDB = Op3dUtils.getElementIn(this.mContainer,
            'lambda_input') as iOP3DHTMLInputElement;
        this.mN_Input = Op3dUtils.getElementIn(this.mContainer,
            'refractive_index') as HTMLInputElement;

        let aMaterials = await Op3dContext.DATA_MANAGER.getMaterials();
        for (let i = 0; i < aMaterials.length; i++) {
            let aOption = document.createElement('option');
            aOption.text = aMaterials[i].name;
            aOption.value = aMaterials[i].number_id;

            this.mMaterialsDropdown.appendChild(aOption);
        }

        this.mLambdaDB.addEventListener('change', () => this._onChangeLambda());
    }
    //__________________________________________________________________________________________
    private _onChangeLambda() {
        let aVal = parseFloat(this.mLambdaDB.value);
        if (true == isNaN(aVal)) {
            this.mLambdaDB.value = this.mLambdaDB.prevValue;
            return;
        }

        if (aVal < 0) {
            aVal = 0;
        }

        this.mLambdaDB.value = aVal.toString();
        this.mLambdaDB.prevValue = this.mLambdaDB.value;

        EventManager.dispatchEvent(EventsContext.MATERIAL_WL_CHANGED_NEW, this, aVal);
        this._onChangeMaterial(true)
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mMaterialsDropdown.addEventListener('change', () => this._onChangeMaterial(true));
        let aMatInfo = Op3dUtils.getElementIn(this.mContainer, 'material_info') as HTMLElement;
        aMatInfo.addEventListener('click',
            () => MaterialInformationForm.instance.open(this.mMaterialVO));
    }
    //__________________________________________________________________________________________
    private async _onChangeMaterial(pToUpdate: boolean) {
        let aNumberID = this.mMaterialsDropdown.value;
        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({
            number_id: aNumberID
        });
        this.mMaterialVO = aMaterialVO;
        this._fillData(aMaterialVO);

        if (true == pToUpdate) {
            EventManager.dispatchEvent(EventsContext.OPTICS_MATERIAL_SECTION_CHANGED, this)
        }
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mParamsParent);
    }
    //__________________________________________________________________________________________
    protected _fillData(pMaterialVO: iMaterialVO) {
        this._clear();

        let aEnableRefractiveIndex = false;
        let aType = pMaterialVO.parameters.type;
        this.mMaterialTypeDropDown.value = aType.toString();

        switch (aType) {
            case eMaterialEquationType.CAUCHY:
            case eMaterialEquationType.SCHOTT:
                this._createParams(uoMaterial.CAUCHY_SCHOTT_PARAMS, pMaterialVO);
                break;
            case eMaterialEquationType.SELLMEIER:
                this._createParams(uoMaterial.SELLMEIER_PARAMS, pMaterialVO);
                break;
            case eMaterialEquationType.MODIFIED_SELLMEIER:
                this._createParams(uoMaterial.MODIFIED_SELLMEIER_PARAMS, pMaterialVO);
                break;
            case eMaterialEquationType.CONSTANT:
                this._createParams([], pMaterialVO);
                aEnableRefractiveIndex = true;
                break;
        }

        ViewUtils.setElementDisabled(this.mRefractiveIndexElement, aEnableRefractiveIndex);
        let aLambda = parseFloat(this.mLambdaDB.value);

        let aN = MaterialUtils.getN(pMaterialVO, aLambda);
        this.mN_Input.title = aN.toString();
        this.mN_Input.value = aN.toString();
    }
    //__________________________________________________________________________________________
    private _createParams(pData: Array<iMaterialParam>, pMaterialVO: iMaterialVO) {
        for (let i = 0; i < pData.length; i++) {
            let aData = pData[i];

            let aName = aData.name;
            let aUnit = aData.unit;
            let aPath = aData.path.split('.');


            let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
            this.mParamsParent.appendChild(aOneParam);
            let aParamName = Op3dUtils.getElementIn(aOneParam, 'param_name') as HTMLElement;
            aParamName.innerHTML = aName;

            let aUnitElement = Op3dUtils.getElementIn(aOneParam, 'unit_span') as HTMLElement;
            if (null != aUnit) {
                aUnitElement.innerHTML = aUnit;
            } else {
                ViewUtils.removeFromParent(aUnitElement.parentElement as HTMLElement);
            }

            let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as HTMLInputElement;
            this._fillInput(pMaterialVO, aPath, aInput);
        }
    }
    //__________________________________________________________________________________________
    public setMaterialsSelectDisabled(pVal: boolean) {
        ViewUtils.setElementDisabled(this.mMaterialsDropdown, pVal);
    }
    //__________________________________________________________________________________________
    public get refractiveIndex(){
        return this.mN_Input.value;
    }
    //__________________________________________________________________________________________
    public get wavelength(){
        return this.mLambdaDB.value;
    }
    //__________________________________________________________________________________________
}
