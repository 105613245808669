﻿import { EventManager } from "../../../../../oc/events/EventManager";
import { EventsContext } from "../../../../_context/EventsContext";
import { iHash, iOP3DHTMLInputElement } from "../../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { uoSection } from "../uoSection";
import { iFaceBasicData } from "./UploadOpticsContext";


export class uoFaceBasicInfoNew extends uoSection<{ name: string, originalName: string }> {

    private static SKIN_PATH = './skins/forms/optics/es_basic_info.html'

    private mCurrFaceOriginalName: string = null;
    private mName: iOP3DHTMLInputElement;
    private mNamesHash: iHash<string> = {};

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoFaceBasicInfoNew.SKIN_PATH,
            title: 'Basic Info',
            collapseQaId: "uo_face_basic_info_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    public clear() {
        this.mNamesHash = {};
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: { name: string, originalName: string }) {
        if (pData == null) {
            this.show(false);
            return;
        }

        this.show(true);

        let aName = this.mNamesHash[pData.originalName] != null ? this.mNamesHash[pData.originalName] : pData.name;
        this.mCurrFaceOriginalName = pData.originalName;
        this.mName.prevValue = aName;
        this.mName.value = aName;

        this.expand();
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mName = Op3dUtils.getElementIn(this.mContainer, 'face_name') as iOP3DHTMLInputElement;

        this.mName.addEventListener('change', () => this._onChangeName());
    }
    //__________________________________________________________________________________________
    private _onChange() {
        EventManager.dispatchEvent(EventsContext.BASIC_FACE_SECTION_CHANGED, this);
    }
    //__________________________________________________________________________________________
    private _onChangeName() {
        this.mNamesHash[this.mCurrFaceOriginalName] = this.mName.value;
        this._onChange();
    }
    //__________________________________________________________________________________________
    public fillSurfaceData(pData: iHash<iFaceBasicData>) {
        for (let face in this.mNamesHash) {
            pData[face] = { face_name: this.mNamesHash[face] };
        }
    }
    //__________________________________________________________________________________________
}
