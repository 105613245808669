import { Part } from "../Part";
import { Behavior } from "./Behavior";
import { eBehavior } from "./BehaviorContext";
import { CageBehavior } from "./CageBehavior";
import { IrisBehavior } from "./IrisBehavior";
import { LaserBehavior } from "./LaserBehavior";
import { SelectBehavior } from "./SelectBehavior";


export abstract class BehaviorsFactory {


    //__________________________________________________________________________________________
    constructor() {
    }
    //__________________________________________________________________________________________
    public static behaviorsFactory(pPart: Part, pBehavior: eBehavior): Behavior {
        switch (pBehavior) {
            case eBehavior.LASER_BEHAVIOR:
                return new LaserBehavior(pPart);

            case eBehavior.SELECT_BEHAVIOR:
                return new SelectBehavior(pPart)

            case eBehavior.CAGE_BEHAVIOR:
                return new CageBehavior();

            case eBehavior.IRIS_BEHAVIOR:
                return new IrisBehavior(pPart);

            default:
                throw new Error("Wrong behavior type");
        }
    }
    //__________________________________________________________________________________________

}