import { eStateToAnalysis } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { LaserBehavior } from "../../parts/behaviors/LaserBehavior";
import { Part } from "../../parts/Part";
import { ViewUtils } from "../ViewUtils";
import { AnalysisPortal } from "../analysis/AnalysisPortal";

export class LightSourcesList {

    protected mContainer: HTMLElement;
    protected mSingleLightsItem: HTMLElement;
    protected mLightSourcesContainer: HTMLElement;
    protected mItems: Array<{
        internalId: string,
        ls_item: HTMLElement,
        checkbox: HTMLInputElement,
        container: HTMLElement
    }> = [];

    constructor(pContainer: HTMLElement) {
        this.mContainer = pContainer;

        this._initElements();
    }
    //__________________________________________________________________________________________
    protected _initElements() {
        this.mSingleLightsItem = Op3dUtils.getElementIn(this.mContainer, "single-ls-item", true);
        this.mLightSourcesContainer = this.mSingleLightsItem.parentElement;
        this.mSingleLightsItem.parentElement.removeChild(this.mSingleLightsItem);
    }
    //__________________________________________________________________________________________
    public reset(pStart: number) {
        ViewUtils.removeElementChildren(this.mLightSourcesContainer, pStart);
        ViewUtils.setElementVisibilityByDNone(this.mLightSourcesContainer, false);
        this.mItems.splice(0);
    }
    //__________________________________________________________________________________________
    public update(pStart: number) {
        this.reset(pStart);

        for (let i = 0; i < Op3dContext.PARTS_MANAGER.parts.length; i++) {
            let aBehavior = Op3dContext.PARTS_MANAGER.parts[i].getBehavior("laserBehavior");
            if (aBehavior != null) {
                this.addLightSource(aBehavior, Op3dContext.PARTS_MANAGER.parts[i]);
            }
        }

        if ((0 == this._numOfActiveLasers()) && (null != this.mItems[0])) {
            this.mItems[0].container.dispatchEvent(new Event('click'));
        }
    }
    //__________________________________________________________________________________________
    protected _lighSourceStateChanged(e: Event, pLaserBehavior: LaserBehavior,
        pCheckbox: HTMLInputElement) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();

        // if ((true == pCheckbox.checked) && (1 == this._numOfActiveLasers())) {
        //     return;
        // }

        Op3dContext.SCENE_HISTORY.addToHistory();
        Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = true;
        pCheckbox.checked = !pCheckbox.checked;
        pLaserBehavior.active = pCheckbox.checked;
        Op3dContext.SCENE_HISTORY.saveScene();


        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
    }
    //__________________________________________________________________________________________
    private _numOfActiveLasers() {
        return this.mItems.filter((item) => (true == item.checkbox.checked)).length;
    }
    //__________________________________________________________________________________________
    private _limitNameLength(pName: string): string {
        if (pName.length > 40) {
            return pName.slice(0, 40) + '...'
        }

        return pName
    }
    //__________________________________________________________________________________________
    public addLightSource(aLaserBehavior: LaserBehavior, pPart: Part) {
        if (true == this.isExists(pPart.internalID)) {
            return;
        }

        ViewUtils.setElementVisibilityByDNone(this.mLightSourcesContainer, true);
        let aClone = this.mSingleLightsItem.cloneNode(true) as HTMLElement;
        this.mLightSourcesContainer.appendChild(aClone);

        let aCheckbox = Op3dUtils.getElementIn(aClone, "ls-check", true) as HTMLInputElement;
        aCheckbox.checked = aLaserBehavior.active;

        aClone.addEventListener("click",
            (e: Event) => this._lighSourceStateChanged(e, aLaserBehavior, aCheckbox));

        let aLabelHtml = pPart.getIndexedLabel(true);
        let aLabelElem = Op3dUtils.getElementIn(aClone, "ls-check-label", true) as HTMLLabelElement;
        aLabelElem.innerHTML = this._limitNameLength(aLabelHtml);
        aLabelElem.htmlFor = aCheckbox.id;
        this.mItems.push({
            checkbox: aCheckbox,
            ls_item: aClone,
            internalId: pPart.internalID,
            container: aClone
        });

        (<any>$(this.mContainer).find('[data-toggle="popover"]')).popover({
            content: aLabelHtml,
            html: true,
            trigger: 'hover'
        }).on('click', function () {
            $(this).blur()
        });

    }
    //__________________________________________________________________________________________
    public isExists(pInternalID: string) {
        return (this.mItems.findIndex((item) => (pInternalID == item.internalId)) > -1);
    }
    //__________________________________________________________________________________________
}
