import { EventManager } from "../../../../oc/events/EventManager";
import { eWavelengthUnit, eStateToAnalysis } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iMinMax, iHash } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ParserContext } from "../../../parser/ParserContext";
import { eWavelengthDistributionType, iWavelenghtDistributionData, iWavelengthData, iWavelengthSingleItem } from "../../../parts/behaviors/LightSourceContext";
import { LightSourceUtils } from "../../../simulation/LightSourceUtils";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";
import { WavelengthEditForm, iWLDistType } from "../../charts/WavelenghtEditForm";
import { CustomSelect, iCustomSelectOption } from "../../components/CustomSelect";
import { RBElement } from "../../components/RBElement";
import { PartInfoSection } from "../PartInfoSection";
import { NIE } from "../_components/NIE";

export interface iWavelengthElement {
    wavelength: NIE;
    weight: NIE;
    primaryRB: RBElement;
    item: HTMLElement;
};

export class piWavelengthSection extends PartInfoSection<iWavelengthData> {

    public static WL_RANGE: iMinMax = { min: 140, max: 20000 };
    private mParams: { onChange: (pData: iWavelengthData) => void; };
    private mSingleItem: HTMLElement;
    private mItemsContainer: HTMLElement;
    private mWavelengthElements: Array<iWavelengthElement> = [];

    private mDistributionTypeSelect: CustomSelect<iWLDistType>;

    private mElements: iHash<NIE> = {};
    private mWavelenghtChart: WavelengthEditForm;

    private mWavelengthData: iWavelengthData;

    constructor(pContainer: HTMLElement, pParams: { onChange: (pData: iWavelengthData) => void }) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_wavelength_section.html'
        });
        this.mParams = pParams;
    }
    //__________________________________________________________________________________________
    private _setDistributionTypeOptions(pSelectedValue: string) {
        let aOptions: iCustomSelectOption<iWLDistType>[] = [
            {
                value: "USER_DEFINED",
                data: {
                    type: eWavelengthDistributionType.USER_DEFINED
                },
                text: "User-defined",
                qa_id: "qa_user_defined",
                enable: true
            },
            {
                value: "TH",
                data: {
                    type: eWavelengthDistributionType.TH
                },
                text: "TH",
                isPremiumOnly: true,
                qa_id: "qa_th",
                enable: true
            },
            {
                value: "GAUSSIAN",
                data: {
                    type: eWavelengthDistributionType.GAUSSIAN
                },
                text: "Gaussian",
                isPremiumOnly: true,
                qa_id: "qa_gaussian",
                enable: true
            },
            {
                value: "BLACKBODY",
                data: {
                    type: eWavelengthDistributionType.BLACKBODY
                },
                text: "Blackbody",
                isPremiumOnly: true,
                qa_id: "qa_blackbody",
                enable: true
            }];

        let aPresets = Op3dContext.USER_VO.presets;
        if (undefined !== aPresets) {
            let aWLPresets = aPresets.wavelengths;
            if (undefined !== aWLPresets) {
                for (let id in aWLPresets) {
                    let aOption: iCustomSelectOption<iWLDistType> = {
                        value: id,
                        data: {
                            type: aWLPresets[id].data.distribution_data.type,
                            preset_id: id
                        },
                        text: aWLPresets[id].name,
                        enable: true
                    };

                    aOptions.push(aOption);
                }
            }
        }

        this.mDistributionTypeSelect.setOptions(aOptions, pSelectedValue, false);
    }
    //__________________________________________________________________________________________
    private _initDistributionType() {
        this.mDistributionTypeSelect = new CustomSelect<iWLDistType>(
            this._getPart("distribution-type-container"), {
            labelOptions: {
                bold: false,
                justify: "start",
                label: 'Spectrum type',
            },
            placeHolder: 'Intensity colors count',
            class: ["forms-custom-select", "wl_select"],
            staticPostion: true,
            qa_id: "qa_distribution_type",
        });
        this.mDistributionTypeSelect.enabled = false;
        this._setDistributionTypeOptions(eWavelengthDistributionType.USER_DEFINED);
    }
    //__________________________________________________________________________________________
    private _initInputs() {
        let aUserSettings = Op3dContext.USER_VO.userVO.parameters.simulation;
        let aISnm = (aUserSettings.wavelengthUnit === eWavelengthUnit.NM);
        let aUnitLabel = (true == aISnm) ? "nm" : ParserContext.SPECIAL_CHARACTERS.micron;
        let aScale = (true === aISnm) ? 1 : (1 / 1000);

        const aCentralWavelength = 550;
        this.mElements.central_wavelength = new NIE(
            this._getPart("gauss-central-wl"),
            {
                isGlobalToFixed: true,
                label: {
                    text: "Central wavelength",
                    bold: false,
                    justify: "start"
                },
                unit: aUnitLabel,
                decimalPrecision: 0,
                initialValue: aCentralWavelength,
                presentedScale: aScale,
                class: ['wl_dist_data_inputs'],
            });
        this.mElements.central_wavelength.readonly = true;

        this.mElements.gauss_width = new NIE(this._getPart("gauss-width"), {
            isGlobalToFixed: true,
            label: {
                text: "Width",
                bold: false,
                justify: "start"
            },
            unit: aUnitLabel,
            decimalPrecision: 2,
            initialValue: 50,
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });
        this.mElements.gauss_width.readonly = true;

        const aTemperature = LightSourceUtils.getBlackbodyTemperature(aCentralWavelength);
        this.mElements.temperature = new NIE(this._getPart("blackbody-temperature"), {
            isGlobalToFixed: true,
            label: {
                text: "Temperature",
                bold: false,
                justify: "start"
            },
            unit: "K",
            decimalPrecision: 4,
            initialValue: aTemperature,
            class: ['wl_dist_data_inputs']
        });
        this.mElements.temperature.readonly = true;

        this.mElements.blackbody_center = new NIE(this._getPart("blackbody-central-wl"), {
            isGlobalToFixed: true,
            label: {
                text: "Temperature",
                bold: false,
                justify: "start"
            },
            unit: aUnitLabel,
            decimalPrecision: 2,
            initialValue: aCentralWavelength,
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });
        this.mElements.blackbody_center.readonly = true;

        this.mElements.range_min = new NIE(this._getPart("range_min"), {
            isGlobalToFixed: true,
            label: {
                text: "λmin",
                bold: false,
                justify: "start"
            },
            unit: aUnitLabel,
            decimalPrecision: 2,
            initialValue: 400,
            range: {
                min: 140,
                max: 20000
            },
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });
        this.mElements.range_min.readonly = true;

        this.mElements.range_max = new NIE(this._getPart("range_max"), {
            isGlobalToFixed: true,
            label: {
                text: "λmax",
                bold: false,
                justify: "start"
            },
            unit: aUnitLabel,
            decimalPrecision: 2,
            initialValue: 700,
            range: {
                min: 140,
                max: 20000
            },
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });
        this.mElements.range_max.readonly = true;

        const aDelta = 50;
        this.mElements.range_delta = new NIE(this._getPart("range_delta"), {
            isGlobalToFixed: true,
            label: {
                text: "Δ",
                bold: false,
                justify: "start"
            },
            decimalPrecision: 2,
            unit: aUnitLabel,
            initialValue: aDelta,
            range: {
                min: 0.1,
                max: 1000
            },
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });
        this.mElements.range_delta.readonly = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._initDistributionType();
        this._initInputs();
        this.mWavelenghtChart = new WavelengthEditForm();

        this._getPart("edit_btn").addEventListener("click", () => this._onEdit());
        this._getPart('view_cart').addEventListener('click', () => this._onShowChart());

        this.mSingleItem = this._getPart("single-wl-item");
        this.mItemsContainer = this.mSingleItem.parentElement;
        this.mItemsContainer.removeChild(this.mSingleItem);

        EventManager.addEventListener(EventsContext.UPDATE_USER_SETTINGS, () =>
            this._onUpdateUserSettings(), this);
    }
    //__________________________________________________________________________________________
    private _onUpdateUserSettings() {
        let aUserSettings = Op3dContext.USER_VO.userVO.parameters.simulation;
        let aISnm = (aUserSettings.wavelengthUnit === eWavelengthUnit.NM);
        let aUnitLabel = (true == aISnm) ? "nm" : ParserContext.SPECIAL_CHARACTERS.micron;
        let aScale = (true === aISnm) ? 1 : (1 / 1000);
        let aDecimalPrecision = (true == aISnm) ? 2 : 5;

        this.mElements.central_wavelength.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.gauss_width.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.blackbody_center.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.range_min.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.range_max.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.range_delta.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });

        let aSelectedOption = this.mDistributionTypeSelect.value;
        this._setDistributionTypeOptions(aSelectedOption);
        this._fillSection(this.mData);
    }
    //__________________________________________________________________________________________
    private _onEdit() {
        this.mWavelenghtChart.open({
            wavelenghtData: this.getData(),
            callback: (pData) => {
                Op3dContext.SCENE_HISTORY.addToHistory();
                this._fillSection(pData);
                this._updateData();
                Op3dContext.SCENE_HISTORY.saveScene();
                AnalysisPortal.instance.enableRunAnalysis(
                    eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
            },
            isEditble: true
        });
    }
    //__________________________________________________________________________________________
    private _onShowChart() {
        this.mWavelenghtChart.open({
            wavelenghtData: this.getData(),
            isEditble: false
        });
    }
    //__________________________________________________________________________________________
    private _setDistributionSectionsVisibility() {
        let aValue = this.mDistributionTypeSelect.selectedOptionData.type;
        $(this.mContainer).find('[distribution]').each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                element.getAttribute('distribution').indexOf(aValue) != -1);
        });

        this.mContainer.setAttribute("distribution", aValue);
    }
    //__________________________________________________________________________________________
    private _addSingleItem(pData: iWavelengthSingleItem) {
        let aWavelengthUnit = Op3dContext.USER_VO.userVO.parameters.simulation.wavelengthUnit;
        let aISnm = (eWavelengthUnit.NM === aWavelengthUnit);
        let aScale = (true === aISnm) ? 1 : (1 / 1000);
        let aUnitSign = (true === aISnm) ? 'nm' : ParserContext.SPECIAL_CHARACTERS.micron;

        let aItem = this.mSingleItem.cloneNode(true) as HTMLElement;

        let aWavelengthNIE = new NIE(Op3dUtils.getElementIn(aItem, 'wavelenght_input'), {
            class: ['wl_input'],
            presentedScale: aScale,
            unit: aUnitSign,
            isGlobalToFixed: true,
            range: piWavelengthSection.WL_RANGE
        });
        aWavelengthNIE.readonly = true;
        this.mItemsContainer.appendChild(aItem);

        let aPrimaryRB = new RBElement(Op3dUtils.getElementIn(aItem, "wl-primary"), {
            label: undefined,
            name: 'pi_wavelenght_rb',
            initialState: pData.isPrimary
        });
        aPrimaryRB.enable = false;

        let aWeightNIE = new NIE(Op3dUtils.getElementIn(aItem, 'weight_input'), {
            class: ['wl_input'],
            isGlobalToFixed: true
        });
        aWeightNIE.readonly = true;

        let aWeightMin = (false === pData.isPrimary) ? 0 : 0.001;
        aWavelengthNIE.actualValue = pData.wl;
        aWeightNIE.value = pData.weight;
        aPrimaryRB.checked = pData.isPrimary;
        aWeightNIE.updateRange({ min: aWeightMin, max: 1 });

        let aElement: iWavelengthElement = {
            primaryRB: aPrimaryRB,
            wavelength: aWavelengthNIE,
            weight: aWeightNIE,
            item: aItem
        };
        this.mWavelengthElements.push(aElement);
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mItemsContainer);
        this.mWavelengthElements = [];
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iWavelengthData): void {
        this._clear();
        this.mWavelengthData = pData;
        if (pData == null) return
        if (0 === pData.wavelengthData.length) {
            this.mWavelengthData = {
                distribution_data: {
                    type: eWavelengthDistributionType.USER_DEFINED
                },
                wavelengthData: [{ isPrimary: true, weight: 1, wl: 550 }]
            };
        }

        for (let i = 0; i < pData.wavelengthData.length; i++) {
            this._addSingleItem(pData.wavelengthData[i]);
        }

        this._setDistribution(pData.distribution_data);
    }
    //__________________________________________________________________________________________
    private _setDistribution(pData: iWavelenghtDistributionData) {
        let aDistributionValue: string = eWavelengthDistributionType.USER_DEFINED;
        if (false === Op3dContext.USER_VO.isBasicLicense) {
            if (undefined !== pData) {
                aDistributionValue = pData.type;
            }

            if ((undefined !== pData.preset_id) &&
                (true === this.mDistributionTypeSelect.isExist(pData.preset_id))) {
                aDistributionValue = pData.preset_id;
            }
        }

        this.mDistributionTypeSelect.setValue(aDistributionValue, false);

        let aData = ((undefined !== pData) && (undefined !== pData.data)) ? pData.data :
            LightSourceUtils.DEFAULT_WL_DATA;
        this.mElements.central_wavelength.actualValue = aData.gauss_center;
        this.mElements.gauss_width.actualValue = aData.gauss_width;
        this.mElements.temperature.actualValue = aData.blackbody_temperature;
        this.mElements.blackbody_center.actualValue = aData.blackbody_max;
        this.mElements.range_min.actualValue = aData.min;
        this.mElements.range_max.actualValue = aData.max;
        this.mElements.range_delta.actualValue = aData.delta;

        this._setDistributionSectionsVisibility();
    }
    //__________________________________________________________________________________________
    private _updateData() {
        let aData = this.getData();
        this.mParams.onChange(aData);
        Op3dContext.SCENE_HISTORY.saveScene();

        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
    }
    //__________________________________________________________________________________________
    public get wavelengthsCount() {
        return this.mWavelengthElements.length;
    }
    //__________________________________________________________________________________________
    public getData() {
        return this.mWavelengthData;
    }
    //__________________________________________________________________________________________
    public fillSection(pData: iWavelengthData) {
        this._fillSection(pData);
    }
    //__________________________________________________________________________________________
}