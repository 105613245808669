import { EventManager } from "../../../../oc/events/EventManager";
import { eDataPermission } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { OpticsContext } from "../../../_context/OpticsContext";
import { iChooseOpticsEvent } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../../data/data_loader/OpticsDataLoader";
import { Part } from "../../../parts/Part";
import { iPart, iFace } from "../../../parts/PartInterfaces";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { Optics3DPresenter, i3DPresenterParams } from "../tools/Optics3DPresenter";
import { OpticsForm } from "./OpticsForm";
import { esBasicInfo } from "./esBasicInfo";
import { esCoating } from "./esCoating";
import { esScattering } from "./esScattering";
import { esSimulationSettings } from "./esSimulationSettings";
import { esSurfaceShape, iSurfaceShape } from "./esSurfaceShape";

export interface iEditFaceForm {
    opticsPart: iPart;
    face: iFace;
    part: Part;
}

export interface iSurfaceSections {
    basicInfo: esBasicInfo;
    coatingInfo: esCoating;
    scatteringInfo: esScattering;
    simulationSettings: esSimulationSettings;
    surfaceShape: esSurfaceShape<iSurfaceShape>;
}

export class EditFaceForm extends Op3dComponentBase<iEditFaceForm> {

    private static INSTANCE: EditFaceForm;

    private mSections: iSurfaceSections;
    private mOptics3DPresenter: Optics3DPresenter;
    private mFaceNameTitle: HTMLElement;
    private mCurrOptics: iPart;
    private mCurrOpticsVO: iOpticsVO;
    private mPart: Part;
    private mFace: iFace;

    //__________________________________________________________________________________________
    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/optics/edit_face_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == EditFaceForm.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            document.getElementById('forms').appendChild(aContainer);

            EditFaceForm.INSTANCE = new EditFaceForm(aContainer);
        }

        return EditFaceForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm(): Promise<void> {
        while (!this.mIsReady || !this.mOptics3DPresenter.isReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pEditFaceForm: iEditFaceForm) {
        let aOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
            number_id: pEditFaceForm.opticsPart.data.number_id
        });

        this._setParams(pEditFaceForm, aOpticsVO);
    }
    //__________________________________________________________________________________________
    private _setParams(pEditFaceForm: iEditFaceForm, pOpticsVO: iOpticsVO) {
        this.mOptics3DPresenter.onRefreshOptic(pOpticsVO, {
            fitToZoom: true,
            highlightedFaceName: pEditFaceForm.face.originalName
        });

        this.mOptics3DPresenter.toUpdate = true;

        this.mSections.basicInfo.setData(pEditFaceForm.face);
        this.mSections.simulationSettings.setData(pEditFaceForm.face);
        this.mSections.coatingInfo.setData(pEditFaceForm.face);
        this.mSections.scatteringInfo.setData(pEditFaceForm.face);
        this.mSections.surfaceShape.setData({
            opticsVO: pOpticsVO,
            face: pEditFaceForm.face
        });
        this.mSections.surfaceShape.show(true == OpticsContext.isCircularConic(pOpticsVO));

        this.mCurrOptics = pEditFaceForm.opticsPart;
        this.mCurrOpticsVO = pOpticsVO;

        this.mPart = pEditFaceForm.part;
        this.mFace = pEditFaceForm.face;
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        this.mOptics3DPresenter.toUpdate = false;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        super._initElements();

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._initPermissions(), this);

        this.mFaceNameTitle = this._getPart('face_name');

        this._getPart('optics_form_link').addEventListener('click',
            () => this._onClickOpticsForm());

        this._initSections();
        this._init3DPresenter();


        EventManager.addEventListener(EventsContext.OPTICS_DEFORMATION_CHANGE,
            () => this._updateOpticsVO(), this);
    }
    //__________________________________________________________________________________________
    private _updateOpticsVO() {
        let aCurrDeformations = this.mCurrOpticsVO.parameters.geometry.deformation;
        let aDeformations = this.mSections.surfaceShape.getData();
        if ((null == aDeformations) || (0 == aDeformations.length)) {
            if ((null != aCurrDeformations) &&
                (null != aCurrDeformations[this.mFace.originalName])) {
                this._createNewOpticsVO();
            }
        } else {
            this._createNewOpticsVO();
        }
    }
    //__________________________________________________________________________________________
    private async _createNewOpticsVO() {
        let aNewOpticsVO = this.mCurrOpticsVO;
        if (eDataPermission.PUBLIC == this.mCurrOpticsVO.permission) {
            aNewOpticsVO = DataUtils.getObjectCopy(this.mCurrOpticsVO);
            aNewOpticsVO.permission = eDataPermission.PRIVATE;
            aNewOpticsVO.number_id = Op3dUtils.idGenerator();
        }

        if (null == aNewOpticsVO.parameters.geometry.deformation) {
            aNewOpticsVO.parameters.geometry.deformation = {};
        }

        aNewOpticsVO.parameters.geometry.deformation[this.mFace.originalName] =
            this.mSections.surfaceShape.getData();

        OpticsDataLoader.instance.addItemManually(aNewOpticsVO);

        let aOpticsData: iChooseOpticsEvent = {
            part: this.mPart,
            opticsVO: aNewOpticsVO,
            copy_data: true
        };

        this.mPart = Op3dContext.PARTS_MANAGER.chooseOptics(aOpticsData)
        // EventManager.dispatchEvent<iChooseOpticsEvent>(
        //     EventsContext.SELECT_OPTICS, this, aOpticsData);

        this.mCurrOpticsVO = aNewOpticsVO;
        this.mOptics3DPresenter.onRefreshOptic(aNewOpticsVO, {
            fitToZoom: false,
            highlightedFaceName: this.mFace.originalName
        });

        this.mOptics3DPresenter.toUpdate = true;

        await OpticsDataLoader.instance.add(aNewOpticsVO);
    }
    //__________________________________________________________________________________________
    private _onClickOpticsForm() {
        OpticsForm.instance.open({
            presenter_3d: { show_image: true },
            opticsPart: this.mCurrOptics,
            opticsVO: this.mCurrOpticsVO,
            part: this.mPart
        });
        this.close();
    }
    //__________________________________________________________________________________________
    private _init3DPresenter() {
        let a3DPresenterCot = this._getPart('optic-preview-element');
        let aOptions: i3DPresenterParams = {
            isFixedDiv: false,
            alwaysShowImage: false,
            showImage: false
        };

        this.mOptics3DPresenter = new Optics3DPresenter(a3DPresenterCot, aOptions);
    }
    //__________________________________________________________________________________________
    private async _initSections() {
        this.mSections = {
            basicInfo: new esBasicInfo(this._getPart('basic_info_section'),
                (pName) => this.mFaceNameTitle.innerHTML = pName),
            coatingInfo: new esCoating(this._getPart('coating_section')),
            scatteringInfo: new esScattering(this._getPart('scattering_section')),
            simulationSettings: new esSimulationSettings(this._getPart('simulation_settings')),
            surfaceShape: new esSurfaceShape(this._getPart('surface_shape'))
        };

        await Op3dContext.wait(() => {
            for (let section in this.mSections) {
                if (true != this.mSections[section].isReady) {
                    return false;
                }
            }
            return true;
        });
        this._initPermissions();
    }
    //__________________________________________________________________________________________
    private _initPermissions() {
        this.mSections.scatteringInfo.enable(Op3dContext.USER_PERMISSION.isScatteringEnabled);
        this.mSections.simulationSettings.enable(Op3dContext.USER_VO.isBasicLicense !== true);

        if (false == Op3dContext.USER_PERMISSION.isScatteringEnabled) {
            this.mSections.scatteringInfo.collapse();
        }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
}