﻿import { Object3D, WebGLRenderer, PerspectiveCamera, Scene, LineSegments, Box3, Vector3, MOUSE } from "three";
import { OrbitControls } from "../../../scene/OrbitControls.js";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { SceneContext } from "../../../scene/SceneContext";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { SurfaceContext } from "../../../parts/optics/SurfaceContext";
import { OpticsFactory } from "../../../parts/optics/OpticsFactory";
import { Spinner } from "../../home/Spinner";

export class FloatingScene extends Op3dComponentBase<iOpticsVO> {

    private static INSTANCE: FloatingScene;

    private mObjectsContainer: Object3D;
    private mRenderer: WebGLRenderer;
    private mOrbitController: OrbitControls;

    private mCamera: PerspectiveCamera;
    private mScene: Scene;

    private mGrid: LineSegments;
    private mGridBtn: HTMLElement;

    private mStopRenderTime: DOMHighResTimeStamp;

    private mFrameBtn: HTMLElement;
    private mIsFrameVisible: boolean = true;
    private mMouseMoveFunc: EventListener;

    //__________________________________________________________________________________________
    private constructor(pParentElement: HTMLElement) {
        super({
            container: pParentElement,
            skinPath: "./skins/tools/floating_scene.html",
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header',
                onDragStart: () => this._onDragStart(),
                onDragEnd: () => this._onDragEnd()
            }
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == FloatingScene.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.classList.add('new_modal');
            aDiv.classList.add('floating_scene');
            aDiv.style.display = 'none';
            aDiv.style.top = '55px';
            aDiv.style.display = Math.min(0, (((window.innerWidth - 552) / 2) - 472)) + 'px';
            document.getElementById('forms').appendChild(aDiv);
            FloatingScene.INSTANCE = new FloatingScene(aDiv);
        }

        return FloatingScene.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData?: iOpticsVO): void {
        this._onUpdate(pData, true);
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mMouseMoveFunc = () => this._activateRender();

        this.mContainer.addEventListener('mousemove', this.mMouseMoveFunc);
        this.mContainer.addEventListener('wheel', this.mMouseMoveFunc);

        this.mGridBtn = this._getPart('grid');
        this.mGridBtn.addEventListener('click', () => this._toggleGrid());

        this.mFrameBtn = this._getPart('frame_btn');
        this.mFrameBtn.addEventListener('click', () => this._toggleFrame());

        this._getPart('zoom_in').addEventListener('click', () => {
            this.mOrbitController.dIn(SceneContext.CONTROLLER_ZOOM_STEPS);
            this._activateRender();
        });
        this._getPart('zoom_out').addEventListener('click', () => {
            this.mOrbitController.dOut(SceneContext.CONTROLLER_ZOOM_STEPS);
            this._activateRender();
        });
    }
    //__________________________________________________________________________________________
    private _onDragStart() {
        this.mContainer.removeEventListener('mousemove', this.mMouseMoveFunc);
        this.mContainer.removeEventListener('wheel', this.mMouseMoveFunc);
    }
    //__________________________________________________________________________________________
    private _onDragEnd() {
        this.mContainer.addEventListener('mousemove', this.mMouseMoveFunc);
        this.mContainer.addEventListener('wheel', this.mMouseMoveFunc);
    }
    //__________________________________________________________________________________________
    private _toggleGrid() {
        this.mGrid.visible = !this.mGrid.visible;
        ViewUtils.setClassShowState(this.mGridBtn, this.mGrid.visible);
        this._activateRender();
    }
    //__________________________________________________________________________________________
    private _toggleFrame() {
        this.mIsFrameVisible = !this.mIsFrameVisible;
        ViewUtils.setClassShowState(this.mFrameBtn, this.mIsFrameVisible);
        this._setFrame();
    }
    //__________________________________________________________________________________________
    private _setFrame() {
        let aFrame = this.mObjectsContainer.getObjectByName(SurfaceContext.FRAME);
        if (null == aFrame) {
            return;
        }

        aFrame.visible = this.mIsFrameVisible;
        this._activateRender();
    }
    //__________________________________________________________________________________________
    private _activateRender() {
        this.mStopRenderTime = (performance.now() + 150);
        requestAnimationFrame((pTime) => this._render(pTime));
    }
    //__________________________________________________________________________________________
    private _render(pTimeStamp: DOMHighResTimeStamp) {
        if (pTimeStamp < this.mStopRenderTime) {
            requestAnimationFrame((pTime) => this._render(pTime));
        }

        this.mOrbitController.update();
        this.mRenderer.render(this.mScene, this.mCamera);
    }
    //__________________________________________________________________________________________
    protected _onUpdate(pData?: iOpticsVO, pZoomIntoFit: boolean = false): void {
        this._clear();
        this._setOptics(pData);
        this._activateRender();

        if (true == pZoomIntoFit) {
            this._zoomToFit();
        }
    }
    //__________________________________________________________________________________________
    private _setOptics(pOpticsVO?: iOpticsVO) {
        let aFaces = OpticsFactory.createOpticsNew(pOpticsVO);

        let aContainer = new Object3D();

        for (let i = 0; i < aFaces.length; i++) {
            const aFace = aFaces[i];
            aContainer.add(aFace.visualization.mesh);
        }

        let boundingBox = new Box3().setFromObject(aContainer);
        aContainer.position.set(0, Math.abs(boundingBox.min.y), 0);

        this.mObjectsContainer.add(aContainer);
        this._setFrame();
    }
    //__________________________________________________________________________________________
    private _zoomToFit() {
        let boundingBox = new Box3().setFromObject(this.mObjectsContainer.children[0]);
        let aMeshY = Math.abs(boundingBox.getCenter(new Vector3()).y);

        let aZoomDelta = aMeshY * 5
        this.mCamera.position.set(-aZoomDelta, aZoomDelta, -aZoomDelta);
        this.mOrbitController.target.set(0, aMeshY, 0)
        this.mOrbitController.update()
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this._create();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _clear() {
        while (this.mObjectsContainer.children.length > 0) {
            this.mObjectsContainer.remove(this.mObjectsContainer.children[0]);
        }

        ViewUtils.setClassShowState(this.mGridBtn, this.mGrid.visible);
    }
    //__________________________________________________________________________________________
    private async _create() {
        Spinner.instance.show();

        let aSceneContainer = this._getPart('scene_container');

        let w = 438;
        let h = 355;

        this.mRenderer = new WebGLRenderer({ antialias: true });
        let aSceneBGColor = Op3dContext.USER_VO.simulationSettings.sceneBGColor;
        (this.mRenderer as any).setClearColor(aSceneBGColor, 1.0);
        this.mRenderer.setSize(w, h);
        aSceneContainer.appendChild(this.mRenderer.domElement);

        let aAspectRatio: number = w / h;
        this.mCamera = new PerspectiveCamera(45, aAspectRatio, 0.1, 10000 * 10000);

        //this.mCamera = new OrthographicCamera(-hw, hw, hh, -hh, 0.500, 10000);
        //this.mCamera.position.copy(new Vector3(-1, 1, -1).multiplyScalar(1500)); //ISO View

        this.mOrbitController = new OrbitControls(this.mCamera, this.mRenderer.domElement);
        this.mOrbitController.mouseButtons = {
            // ORBIT: MOUSE.RIGHT,
            // ZOOM: null,
            // PAN: MOUSE.MIDDLE

            LEFT: null,
            RIGHT: MOUSE.LEFT,
            MIDDLE: MOUSE.RIGHT
        };
        this.mOrbitController.maxDistance = 2500;

        this.mObjectsContainer = new Object3D();
        let aLights = await SceneContext.OP3D_SCENE.lights;

        this.mGrid = SceneContext.GRID_MANAGER.getGridMesh();

        this.mScene = new Scene();
        this.mScene.add(this.mCamera);
        this.mScene.add(this.mObjectsContainer);
        this.mScene.add(aLights);
        this.mScene.add(this.mGrid);

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
}