
//______________________________________________________________________________________________
export enum eFilterFinderFilterType {
    BAND_PASS_FILTER,
    LONG_PASS_FILTER,
    SHORT_PASS_FILTER,
    NOTCH_FILTER,
    CUSTOMIZED_FILTER
}
//______________________________________________________________________________________________
export interface iFilterQueryVO {
    roi_min?: number;
    roi_max?: number;
    num_matches: number;
    filter_min?: number;
    filter_max?: number;
    height?: number;
    transition_wavelength?: number;
    x?: Array<number>;
    y?: Array<number>;
}
//______________________________________________________________________________________________
export interface iFilterFinderResult {
    systemName: string;
    companyName: string;
    originalName: string;
    link: string;
}
    //______________________________________________________________________________________________
