export enum eNotificationType {
    SIMULATION,
    CONSTRUCTION_COMMENT,
    GENERAL,
    ERROR,
    SUCCESS_MESSAGE,
    ALARM,
    ADD_LCS
}

export interface iNoteHTMLData {
    firstParent: HTMLElement;
    secondParent: HTMLElement;
}

export enum eNotificationToastDuration {
    SHORT = 1,
    MEDIUM = 2,
    LONG = 5,
    INFINITE = Number.MAX_VALUE
}

export interface iNotificationTypeData {
    type: eNotificationType,
    color: string;
    title: string;
    isColorBoxVisible: boolean;
}

export interface iNotificationData {
    /**
     * @description Use one of NotificationCenter.NOTIFICATIONS_TYPES
     * */
    params?: iNotificationTypeData;
    message: string;
    toastDuration?: eNotificationToastDuration;// duration of visibility of toast in ms
    dontShowAgainCallback?: (pShow: boolean) => void;
}

export class NotificationsContext {

}
