import { Strings } from "../../../../_context/Strings";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { ViewUtils } from "../../../ViewUtils";
import { iRadioBtnComp, iOneRadioBtnData, iOpticsFilterComp, iRadioBtnsData } from "../MenusContext";
import { OpticsFiltersHandler, eOMFilterName } from "../_filterHandlers/OpticsFilterHandler";
import { Filter } from "./Filter";
import private_img from '../../../../../../public/images/icons_new/product_catalog/private_part.svg'

export class RadioBtnsFilter extends Filter {

    private static SKIN_PATH = "./skins/menus/filters/radio_btns_component.html";

    private mRadioBtns: Array<iRadioBtnComp> = new Array<iRadioBtnComp>();
    private mSingleRadioBtnItem: HTMLElement;
    private mRadioBtnsParent: HTMLElement;
    private mCurrRadioBtnData: iOneRadioBtnData;
    private mSetAsDefault: Function;

    constructor(pData: iOpticsFilterComp<iRadioBtnsData>) {
        super(RadioBtnsFilter.SKIN_PATH, pData);
    }
    //__________________________________________________________________________________________
    protected init() {
        super.init();

        this.mRadioBtnsParent = this._getPart('radio_btns_parent', true);
        this.mSingleRadioBtnItem = this._getPart('single_radio_btn_item');
        ViewUtils.removeFromParent(this.mSingleRadioBtnItem);
    }
    //__________________________________________________________________________________________
    public showRB(pName: string, pToShow: boolean) {
        let aRB = this.mRadioBtns.find((rb) => rb.name == pName);
        if (null != aRB) {
            ViewUtils.setElementVisibilityByDNone(aRB.wrapper, pToShow);
        }
    }
    //__________________________________________________________________________________________
    public exportToJson() {
        let aRadioBtnsData: iOneRadioBtnData;
        if (null != this.mCurrRadioBtnData) {
            aRadioBtnsData = {
                name: this.mCurrRadioBtnData.name,
                serverName: this.mCurrRadioBtnData.serverName
            }
        }

        return aRadioBtnsData;
    }
    //__________________________________________________________________________________________
    public initFromJson(pData: iOneRadioBtnData) {
        if (pData == null) {
            return;
        }

        for (let i = 0; i < this.mRadioBtns.length; i++) {
            if ((pData.name == this.mRadioBtns[i].name) &&
                (pData.serverName == this.mRadioBtns[i].serverName)) {
                this._onRadioBtnClicked(this.mRadioBtns[i], false);

                break;
            }
        }
    }
    //__________________________________________________________________________________________
    public reset(pToChange: boolean = false) {
        if (null != this.mSetAsDefault) {
            this.mSetAsDefault();
        } else {
            this.mCurrRadioBtnData = null;
        }

        this._onChange(pToChange);
    }
    //__________________________________________________________________________________________
    private _clear() {
        if (this.mRadioBtnsParent != null) {
            while (this.mRadioBtnsParent.children.length > 0) {
                this.mRadioBtnsParent.removeChild(this.mRadioBtnsParent.children[0]);
            }
        }

        this.mRadioBtns.splice(0);
        this.mCurrRadioBtnData = null;
    }
    //__________________________________________________________________________________________
    protected _onChange(pToChange: boolean) {
        if (null == this.mOnChange) {
            return;
        }

        let aName: string;
        if (null != this.mCurrRadioBtnData) {
            aName = (null != this.mCurrRadioBtnData.serverName) ?
                this.mCurrRadioBtnData.serverName : this.mCurrRadioBtnData.name;
        }

        this.mOnChange(aName, pToChange);
    }
    //__________________________________________________________________________________________
    private updateRadioBtns(pRadioBtnCompData: iRadioBtnsData) {
        if (!pRadioBtnCompData) {
            return;
        }

        this._clear();

        let aIndex = 0;

        let aDefault = (pRadioBtnCompData.defaultNameIndex != null &&
            pRadioBtnCompData.defaultNameIndex < pRadioBtnCompData.data.length) ?
            pRadioBtnCompData.defaultNameIndex : 0;

        for (let i = 0; i < pRadioBtnCompData.data.length; i++) {
            let aSingleRadioBtnItem = this.mSingleRadioBtnItem.cloneNode(true) as HTMLElement;

            let aInput = Op3dUtils.getElementIn(aSingleRadioBtnItem,
                'radio_btn_input', true) as HTMLInputElement;
            let aIsChecked = (aIndex == i);
            aInput.checked = aIsChecked;
            ViewUtils.setElementActive(aSingleRadioBtnItem, aIsChecked);

            let aData = pRadioBtnCompData.data[i]
            let aRadioBtnComp: iRadioBtnComp = {
                radioBtn: aInput,
                wrapper: aSingleRadioBtnItem,
                name: aData.name,
                //specialBtn: aData.specialBtn,
                serverName: aData.serverName
            }

            //aInput.addEventListener('click', () => this._onRadioBtnClicked(aRadioBtnComp));
            aSingleRadioBtnItem.addEventListener('mouseup', () =>
                this._onClickRBElement(aRadioBtnComp));
            if (aDefault == i) {
                this.mSetAsDefault = () => this._onRadioBtnClicked({
                    radioBtn: aInput,
                    name: aData.name, serverName: pRadioBtnCompData.data[i].serverName,
                    wrapper: aSingleRadioBtnItem
                }, false);
            }

            let aLabel = Op3dUtils.getElementIn(aSingleRadioBtnItem,
                'radio_btn_label', true) as HTMLLabelElement;
            aLabel.htmlFor = aInput.id;

            let aLabelTextSpan = document.createElement('span');
            aLabelTextSpan.style.position = 'relative';
            aLabelTextSpan.style.top = '3px';
            aLabelTextSpan.innerHTML = aData.name;
            aLabelTextSpan.setAttribute('nameForCount', aData.name)
            if (aData.name === 'Private') {
                const aPrivateImg = document.createElement('img')
                aPrivateImg.src = private_img
                aLabel.appendChild(aPrivateImg)
                aLabel.style.display = 'flex'
                aLabel.style.alignItems = 'center'
                aPrivateImg.style.margin = '5px'
                aInput.parentElement.style.display = 'flex'
            }
            aLabel.appendChild(aLabelTextSpan);

            this.mRadioBtnsParent.appendChild(aSingleRadioBtnItem);
            this.mRadioBtns.push(aRadioBtnComp);
        }
    }
    //__________________________________________________________________________________________
    private _onClickRBElement(pRadioBtnComp: iRadioBtnComp) {
        this._onRadioBtnClicked(pRadioBtnComp);
    }
    //__________________________________________________________________________________________
    private _onRadioBtnClicked(pRadioBtnComp: iRadioBtnComp, pToChange: boolean = true) {
        if ((null != this.mCurrRadioBtnData) && ((null != pRadioBtnComp.serverName) &&          //If chosen radio button is already clicked.
            (pRadioBtnComp.serverName == this.mCurrRadioBtnData.serverName))) {
            return;
        }

        //Deselect all radio buttons.
        for (let i = 0; i < this.mRadioBtns.length; i++) {
            this.mRadioBtns[i].radioBtn.checked = false;
            ViewUtils.setElementActive(this.mRadioBtns[i].wrapper, false);
        }

        pRadioBtnComp.radioBtn.checked = true;
        ViewUtils.setElementActive(pRadioBtnComp.wrapper, true);
        this.mCurrRadioBtnData = {
            name: pRadioBtnComp.name,
            serverName: pRadioBtnComp.serverName
        };

        this._onChange(pToChange);
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        super._onCreationComplete();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    // private onClickSpecialBtn() {
    //     if (this.mCurrRadioBtnData != null &&
    //         this.mCurrRadioBtnData.specialBtn != null &&
    //         this.mCurrRadioBtnData.specialBtn.callback != null) {
    //         this.mCurrRadioBtnData.specialBtn.callback();
    //     }
    // }
    //__________________________________________________________________________________________
    public setAsDefault() {

    }
    //__________________________________________________________________________________________
    public updateFilterData(pRadioBtnsData: iRadioBtnsData, pToChange: boolean = true) {
        this.updateRadioBtns(pRadioBtnsData);
        if (true == pToChange) {
            this._onChange(pToChange);
        }
    }
    //__________________________________________________________________________________________
    public setValue(pVal: string, pToChange: boolean = false) {
        for (let i = 0; i < this.mRadioBtns.length; i++) {
            let aRadioBtn = this.mRadioBtns[i]
            if (aRadioBtn.name == pVal) {
                this._onRadioBtnClicked(aRadioBtn, pToChange);
            }
        }
    }
    //__________________________________________________________________________________________
    public disable() {
        if (this.mRadioBtnsParent != null) {
            this.mRadioBtnsParent.classList.add(Strings.DISABLED_STATE)
        }
    }
    //__________________________________________________________________________________________
    public enable() {
        if (this.mRadioBtnsParent != null) {
            this.mRadioBtnsParent.classList.remove(Strings.DISABLED_STATE)
        }
    }
    //__________________________________________________________________________________________
    public setData(pName: string, pData: Array<any>) {

        for (let i = 0; i < pData.length; i++) {
            let aKey = Object.keys(pData[i])[0];
            if (aKey === pName) {

                switch (pName) {
                    case eOMFilterName.baseShape: {
                        let aVal = Object.values(pData[i])[0] as number;
                        let aBaseShapeName = OpticsFiltersHandler.SHAPES_NAMES[aVal];
                        for (let i = 0; i < this.mRadioBtns.length; i++) {
                            if (aBaseShapeName === this.mRadioBtns[i].name ||
                                aBaseShapeName == this.mRadioBtns[i].serverName) {
                                this._onRadioBtnClicked(this.mRadioBtns[i], false);
                                this.updateCollapse({ toShow: true, scrollInfoView: true });
                                break;
                            }
                        }
                    }
                        break;
                    case eOMFilterName.permission:
                        /**
                        * @TODO:
                        * handle filter
                        */
                        break;
                    default:
                        let aVal = Object.values(pData[i])[0];
                        for (let i = 0; i < this.mRadioBtns.length; i++) {
                            if (aVal === this.mRadioBtns[i].name ||
                                aVal == this.mRadioBtns[i].serverName) {
                                this._onRadioBtnClicked(this.mRadioBtns[i], false);
                                this.updateCollapse({ toShow: true, scrollInfoView: true });
                                break;
                            }
                        }
                        break;
                }
                return;
            }
        }
    }

    //__________________________________________________________________________________________
    // public getData() {
    //     return this.mCurrRadioBtnData;
    // }
    //__________________________________________________________________________________________
}
