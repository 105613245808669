﻿import { Object3D } from "three";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iHash } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ViewUtils } from "../../ViewUtils";
import { Spinner } from "../../home/Spinner";
import { ContainerLoader } from "../../tools/ContainerLoader";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";

export interface iuoSectionParams {
    skinPath: string;
    title: string;
    collapseQaId?: string;
    isNewSkin: boolean;
    isPremiumSection: boolean
    isAllSectionHidden?: boolean;
}

export class uoSection<T = any, S = T> {

    private mCollapseTarget: HTMLElement;
    protected mContainer: HTMLElement;
    private mIsReady: boolean = false;

    //__________________________________________________________________________________________
    public fillObject(_pOpticsVO: iOpticsVO) {

    }
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pParams: iuoSectionParams) {
        //super("./skins/upload_optics/uo_section.html", pContainer);
        this.mContainer = pContainer;
        if (pParams.isPremiumSection) {
            this.mContainer.classList.add("premium-feature-section");
        }

        //Load uoSection Skin:
        let auoSectionSkin = "./skins/forms/optics/uo_section.html";
        if (pParams.isNewSkin) {
            auoSectionSkin = "./skins/forms/optics/uo_section_new.html";
        }

        new ContainerLoader(auoSectionSkin, pContainer,
            () => this._onSectionSkinReady(pParams));
    }
    //__________________________________________________________________________________________
    public getData(): S {
        return null;
    }
    //__________________________________________________________________________________________
    public async setData(pData: T, pPart?: any) {
        Spinner.instance.show();
        await Op3dContext.wait(() => { return (true == this.mIsReady); });
        Spinner.instance.hide();
        if (pPart != null) {
            await this._setData(pData, pPart);
        } else {
            await this._setData(pData);
        }

    }
    //__________________________________________________________________________________________
    public async setOptomechanicsData(pData: T) {
        Spinner.instance.show();
        await Op3dContext.wait(() => { return (true == this.mIsReady); });
        Spinner.instance.hide();

        this._setOptomechanicsData(pData);
    }
    //__________________________________________________________________________________________
    protected async _setData(_pData: T, _pPart?: any) {

    }
    //__________________________________________________________________________________________
    protected _setOptomechanicsData(_pData: T) {

    }
    //__________________________________________________________________________________________
    public show(pShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, pShow);
    }
    //__________________________________________________________________________________________
    public get container() {
        return this.mContainer;
    }
    //__________________________________________________________________________________________
    public get isReady() {
        return this.mIsReady;
    }
    //__________________________________________________________________________________________
    private _onSectionSkinReady(pParams: iuoSectionParams) {
        this._getPart('title').innerHTML = pParams.title;

        let aCollapseQAIdElement = Op3dUtils.getElementIn(this.mContainer, "collapse-target-anchor", true);
        if (aCollapseQAIdElement !== null && pParams.collapseQaId !== undefined) {
            ViewUtils.setElementAttribute(aCollapseQAIdElement, "qa_id", pParams.collapseQaId);
        }
        this.mCollapseTarget = Op3dUtils.getElementIn(this.mContainer, 'collapse_target');
        if (pParams.isAllSectionHidden === true) {
            this.mCollapseTarget.classList.add("section-content");
        }
        this.mCollapseTarget.removeAttribute('id');
        new ContainerLoader(pParams.skinPath, this.mCollapseTarget,
            () => this._onCreationComplete());
    }
    //__________________________________________________________________________________________
    public async enable(pToEnable: boolean) {
        await Op3dContext.wait(() => { return (true == this.mIsReady); });

        let aCollapse = this.getPartByAttribute('data-toggle', 'collapse');
        if (true == pToEnable) {
            let aID = Op3dUtils.idGenerator();
            aCollapse.setAttribute('href', '#' + aID);
            this.mCollapseTarget.id = aID;
        } else {
            this.mCollapseTarget.removeAttribute('id');
            aCollapse.removeAttribute('href');
        }
    }
    //__________________________________________________________________________________________
    public get collapseTarget() {
        return this.mCollapseTarget;
    }
    //__________________________________________________________________________________________
    private _initCollapse() {
        try {
            let aCollapse = this.getPartByAttribute('data-toggle', 'collapse');
            if (null == aCollapse) {
                throw (new Error('There is no attribute data-toggle="collapse" in the skin,'));
            }

            let aID = Op3dUtils.idGenerator();
            aCollapse.setAttribute('href', '#' + aID);

            if (null == this.mCollapseTarget) {
                throw (new Error('Collapse target is not found in this container.'));
            }

            this.mCollapseTarget.id = aID;
        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM(e as any);
        }
    }
    //__________________________________________________________________________________________
    public async update(pObject3D: Object3D, pData?: T) {
        await Op3dContext.wait(() => { return (true == this.mIsReady); });

        this._onUpdate(pObject3D, pData);
    }
    //__________________________________________________________________________________________
    public _onUpdate(_pObject3D: Object3D, _pData?: T) { }
    //__________________________________________________________________________________________
    public updateOptics(_pOptics: Object3D) { }
    //__________________________________________________________________________________________
    public expand() {
        $(this.mCollapseTarget).collapse('show');
    }
    //__________________________________________________________________________________________
    public collapse() {
        $(this.mCollapseTarget).collapse('hide');
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {
        this._initCollapse();
        await this._initElements();
        this._addEventListeners();

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected async _initElements() { }
    //__________________________________________________________________________________________
    protected _addEventListeners() { }
    //__________________________________________________________________________________________
    protected _getPart(pId: string, pToChangeId?: boolean) {
        let aElement = $(this.mContainer).find('#' + pId).get(0);

        if (true == pToChangeId) {
            aElement.id += Op3dUtils.idGenerator();
        }

        return aElement;
    }
    //__________________________________________________________________________________________
    /**
     * Returns first element has attribute matched pVal.
     * @param pAttribute - attribute name.
     * @param pVal - value of the desired attribute.
     */
    protected getPartByAttribute(pAttribute: string, pVal: string = "") {
        let aElement = $(this.mContainer).find('[' + pAttribute + '=' + pVal + ']').get(0);

        return aElement;
    }
    //__________________________________________________________________________________________
    protected async _waitForReady() {
        await Op3dContext.wait(() => { return (true == this.mIsReady); });
    }
    //__________________________________________________________________________________________
    protected _fillInput(pObject: iHash<any>, pPath: Array<string>,
        pElement: HTMLInputElement | HTMLSelectElement, _pDispatchEvent: boolean = true) {

        let aKey = pPath.shift();
        if (0 == pPath.length) {
            let aValue = null != pObject[aKey] ? pObject[aKey] : "";

            if ((pElement instanceof HTMLInputElement) && (pElement.type == 'radio')) {
                pElement.checked = (aValue == pElement.value);
            } else {
                pElement.value = aValue;
                pElement.title = aValue;
            }

            pElement.dispatchEvent(new CustomEvent('internal_change'));

            return;
        }

        this._fillInput(pObject[aKey], pPath, pElement);
    }
    //__________________________________________________________________________________________
}
