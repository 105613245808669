import { EventManager } from "../../../../oc/events/EventManager";
import { eCadType, eStateToAnalysis } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { Part } from "../../../parts/Part";
import { iPart, iFaceDataNEW } from "../../../parts/PartInterfaces";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";
import { Popup } from "../Popup";
import { Optics3DPresenter, i3DPresenterParams } from "../tools/Optics3DPresenter";
import { OptomechanicsOpticsForm } from "./OptomechanicsOpticsForm";
import { esBasicInfo } from "./esBasicInfo";
import { esCoating } from "./esCoating";
import { esOptomechColorInfo } from "./esOptomechColorInfo";
import { esScattering } from "./esScattering";
import { esSimulationSettings } from "./esSimulationSettings";

export interface iOptomechanicsEditFaceForm {
    opticsPart?: iPart;
    face: iFaceDataNEW;
    part: Part;
}

export interface iOptomechanicsSurfaceSections {
    basicInfo: esBasicInfo;
    colorInfo: esOptomechColorInfo;
    scatteringInfo: esScattering;
    simulationSettings: esSimulationSettings;
    coatingInfo: esCoating;
}

export class OptomechanicsSurfaceManager extends Op3dComponentBase<iOptomechanicsEditFaceForm> {

    private static INSTANCE: OptomechanicsSurfaceManager;

    private mSections: iOptomechanicsSurfaceSections;
    private mOptics3DPresenter: Optics3DPresenter;
    private mFaceNameTitle: HTMLElement;
    private mPart: Part;
    private mFace: iFaceDataNEW;
    private mFaces: iFaceDataNEW[];
    private mChangeAllFaces: boolean = false;
    private mCancelBtn: HTMLElement;
    private mSaveBtn: HTMLElement;
    //__________________________________________________________________________________________
    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/optics/edit_optomechanics_face_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == OptomechanicsSurfaceManager.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            document.getElementById('forms').appendChild(aContainer);

            OptomechanicsSurfaceManager.INSTANCE = new OptomechanicsSurfaceManager(aContainer);
        }

        return OptomechanicsSurfaceManager.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm(): Promise<void> {
        while (!this.mIsReady || !this.mOptics3DPresenter.isReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pEditFaceForm: iOptomechanicsEditFaceForm) {
        this._clear();
        this.mPart = pEditFaceForm.part;
        if (pEditFaceForm.face == null) {
            this.mFace = pEditFaceForm.part.getFaces()[0].indexes[0];
            pEditFaceForm.face = this.mFace;
            this.mChangeAllFaces = true;
            this.mFaceNameTitle.innerHTML = 'All faces';
            this.mFaces = structuredClone(pEditFaceForm.part.getFaces()[0].indexes);
        } else {
            this.mFace = pEditFaceForm.face;
        }
        if (this.mPart.userIsPartOwner() === false || Op3dContext.USER_VO.isBasicLicense === true) {
            ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, true);
        }
        this.mSections
        this._setParams(pEditFaceForm);
    }
    //__________________________________________________________________________________________
    private _setParams(pEditFaceForm: iOptomechanicsEditFaceForm) {
        this.mOptics3DPresenter.onRefreshOptomechanicsOptic(pEditFaceForm.part, {
            fitToZoom: true,
            highlightedFaceName: pEditFaceForm.face.name,
            isOptomechanicsPart: true,
        });
        this.mOptics3DPresenter.toUpdate = true;
        let aSample = null;
        if (this.mChangeAllFaces === true) {
            this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll();
            aSample = { useSample: true, sample: 'All faces' };
        }
        this.mSections.basicInfo.setData(pEditFaceForm.face, aSample); 

        this.mSections.colorInfo.setData([pEditFaceForm.face]);
        this.mSections.colorInfo.collapse();

        this.mSections.simulationSettings.setData(pEditFaceForm.face, pEditFaceForm.part);

        this.mSections.scatteringInfo.setData(pEditFaceForm.face, pEditFaceForm.part);

        this.mSections.coatingInfo.setData(pEditFaceForm.face, pEditFaceForm.part);

        document.getElementById('simulation_settings').classList.remove('d-none')

        if (this.mPart.opticalPartType === eCadType.OPTO_MECHANICS) {
            document.getElementById('simulation_settings').classList.add('d-none')
        }
    }
    //__________________________________________________________________________________________
    protected async _onClose(pTransition: boolean = false) {
        this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll();
        this.mOptics3DPresenter.toUpdate = false;
        if (!pTransition) {
            OptomechanicsOpticsForm.instance.close();
        }
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        super._initElements();

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._initPermissions(), this);

        this.mFaceNameTitle = this._getPart('cad_optical_form_face_name');

        this._getPart('optics_form_link').addEventListener('click',
            () => this._onClickOpticsForm());

        this.mCancelBtn = this._getPart('cancel_btn_opt_surface');
        this.mSaveBtn = this._getPart('save_btn_opt_surface');
        
        this.mCancelBtn.addEventListener('click', () => {
            this.mOptics3DPresenter.toUpdate = false;
            OptomechanicsOpticsForm.instance.close();
            this.close(true)
        });
        this.mSaveBtn.addEventListener('click', () => this._save());
        this._initSections();
        this._init3DPresenter();
    }
    //__________________________________________________________________________________________
    private async _onClickOpticsForm() {
        OptomechanicsOpticsForm.instance.open({
            part: this.mPart,
        });
        this.close(true);
    }
    //__________________________________________________________________________________________
    private _init3DPresenter() {
        let a3DPresenterCot = this._getPart('optic-preview-element');
        let aOptions: i3DPresenterParams = {
            isFixedDiv: false,
            alwaysShowImage: false,
            showImage: false,
        };

        this.mOptics3DPresenter = new Optics3DPresenter(a3DPresenterCot, aOptions);
    }
    //__________________________________________________________________________________________
    private async _initSections() {
        this.mSections = {
            basicInfo: new esBasicInfo(this._getPart('basic_info_section'),
                (pName) => this.mFaceNameTitle.innerHTML = pName),
            coatingInfo: new esCoating(this._getPart('coating_section')),
            scatteringInfo: new esScattering(this._getPart('scattering_section')),
            simulationSettings: new esSimulationSettings(this._getPart('simulation_settings')),
            colorInfo: new esOptomechColorInfo(this._getPart('color_section'))
        };

        await Op3dContext.wait(() => {
            for (let section in this.mSections) {
                if (true != this.mSections[section].isReady) {
                    return false;
                }
            }
            return true;
        });
        this._initPermissions();
    }
    //__________________________________________________________________________________________
    private _initPermissions() {
        this.mSections.scatteringInfo.enable(Op3dContext.USER_PERMISSION.isScatteringEnabled);
        this.mSections.coatingInfo.enable(Op3dContext.USER_PERMISSION.isFreeUser !== true);
        this.mSections.colorInfo.enable(Op3dContext.USER_PERMISSION.isFreeUser !== true);
        this.mSections.simulationSettings.enable(Op3dContext.USER_PERMISSION.isFreeUser !== true);

        if (false === Op3dContext.USER_PERMISSION.isScatteringEnabled) {
            this.mSections.scatteringInfo.collapse();
            this.mSections.coatingInfo.collapse();
            this.mSections.simulationSettings.collapse();
        }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _applySurfaceDataToAllFaces() {
        this.mFaces.map(face => {
            let aDataCopy = structuredClone(this.mFace.data)
            face["data"] = aDataCopy;
        });

        this.mPart.opticalData["faces"] = this.mFaces;
        this.mPart.getFaces()[0].indexes = this.mFaces;
    }
    //__________________________________________________________________________________________
    protected async _saveNewData() {
        let aDataToSave = null;

        if (this.mChangeAllFaces === false) {
            aDataToSave = this.mFace;
        } else {
            this._applySurfaceDataToAllFaces();
            this.mSections.colorInfo.applyForFaces(this.mFaces);
        }

        let aAnswer = await this.mPart.saveOptomechanicsOpticalInfoInMongo(aDataToSave);

        return aAnswer
    }
    //__________________________________________________________________________________________
    protected _clear() {
        this.mPart = null;
        this.mFaces = null;
        this.mFace = null;
        this.mChangeAllFaces = false;
    }
    //__________________________________________________________________________________________
    private async _save(){
        this.mSections.basicInfo.saveName()
        await this.mSections.coatingInfo.saveCoating();
        this.mSections.scatteringInfo.saveScattering();
        this.mSections.simulationSettings.saveSimulationSettings();

        let aAnswer = await this._saveNewData()
        this.mOptics3DPresenter.unHighlightOptomechanicsFaceAll();
        ViewUtils.setElementDisabled(this.mFaceNameTitle, false);
        ViewUtils.setElementDisabled(this.mSections.basicInfo.container, false);
        if (!aAnswer) {
            Popup.instance.open({
                text: MessagesHandler.ERROR_ON_SAVE_FOREIGN_PART
            })
        } else {
            this.mSections.colorInfo.saveColorChanges(this.mPart);
        }

        Op3dContext.SCENE_HISTORY.saveScene();
        Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = true;

        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
        
        this.mOptics3DPresenter.toUpdate = false;
        OptomechanicsOpticsForm.instance.close();
        this.close(true);
    }
    //__________________________________________________________________________________________
}   