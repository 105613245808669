import { Vector3, Box3, Object3D, Group as THREEGroup } from "three";
import { eAxisType, eCadType, eDataPermission, eErrorType } from "../../../_context/Enums";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iUploadSetupData } from "../../../_context/_interfaces/Interfaces";
import { Main } from "../../../_main/Main";
import { iSurface, MatrixUtils } from "../../../_utils/MatrixUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { SnapshotTools } from "../../../_utils/SnapshotTools";
import { iPartVOData } from "../../../data/VO/PartVO";
import { PartsDataLoader } from "../../../data/data_loader/PartsDataLoader";
import { SceneContext } from "../../../scene/SceneContext";
import { ServerContext } from "../../../server/ServerContext";
import { eClickMode } from "../../../ui/_globals/PartsEventsHandler";
import { Spinner } from "../../../ui/home/Spinner";
import { Part } from "../../Part";
import { PartsFactory } from "../../_parts_assets/PartsFactory";
import { SurfaceContext } from "../../optics/SurfaceContext";
import { Strings } from "../../../_context/Strings";
import { OpticsFactory } from "../../optics/OpticsFactory";
import { Group } from "../../../ui/forms/Group";
import { ePartType, iPart, iPartMongoDB } from "../../PartInterfaces";
import { OptixPartDisplayer } from "../../_parts_assets/OptixPartDisplayer";
import { eBlackBoxSetting } from "../components/BlackBox";
import { iOptixPartJSON } from "../../_parts_assets/ExportToJSONInterfaces";
import { SetupsManager } from "../../../setups/SetupManager";


export interface iBlackBoxVOGeometry {
    center: Vector3;
    size: Vector3;
    color: string
}
export interface iBlackBoxData {
    shape_data: iBlackBoxVOGeometry;
    content: string;
}

export interface iBlackboxSetup {
    parts: Array<iOptixPartJSON>;
    setup_version: string;
}

export const updateDiameterOffset = (pBoundingBox: Box3, setDiameter) => {
    const aSize = pBoundingBox.getSize(new Vector3())
    const aRadius = aSize.x > aSize.y ? aSize.x + 0.2 : aSize.y + 0.2
    setDiameter(aRadius.toFixed(1) + ' mm')
}

export const updatePartsList = (pPart: Part, pParts: Set<Part>, setParts: Function, pBoundingBox: Box3, setDiameter) => {
    let aParts = new Set(pParts)
    if (!pParts.has(pPart)) {
        setParts((prevElements) => new Set(prevElements.add(pPart)));
        aParts = new Set(aParts.add(pPart))
        OptixPartDisplayer.highlightObject(pPart)
    } else {
        setParts((prevElements) => new Set([...prevElements].filter(part => part.internalID !== pPart.internalID)));
        aParts = new Set([...aParts].filter(part => part.internalID !== pPart.internalID))
        OptixPartDisplayer.unHighlightObject(pPart)
    }
    calculateBoundingBoxFunction(aParts, pBoundingBox)
    updateDiameterOffset(pBoundingBox, setDiameter)
}

export const getBlackBox = (pSize: Vector3) => {
    const aSurfaces = new Array<iSurface>();

    const aWidth = pSize.x;
    const aHeight = pSize.y
    const aDepth = pSize.z

    const aRadius = Math.max(aWidth, aHeight)

    const aFrontSurface = MatrixUtils.getOneCircularThinSurface({
        r: (aRadius / 2),
        position: [0, 0, -aDepth / 2],
        rotationAngle: 0,
        rotationAxis: MatrixUtils.ROTATION_AXIS,
        surfaceName: SurfaceContext.FRONT,
    });
    const aBackSurface = MatrixUtils.getOneCircularThinSurface({
        r: (aRadius / 2),
        position: [0, 0, aDepth / 2],
        rotationAngle: Math.PI,
        rotationAxis: MatrixUtils.ROTATION_AXIS,
        surfaceName: SurfaceContext.BACK,
    });

    aSurfaces.push(aFrontSurface.surface);
    aSurfaces.push(aBackSurface.surface);

    const aFrontVertices = aFrontSurface.edgeVertices.map(vertex => {
        let aClone = vertex.clone();
        aClone = aClone.applyMatrix4(aFrontSurface.surface.matrix.clone());
        return aClone;
    });

    const aBackVertices = aBackSurface.edgeVertices.map(vertex => {
        let aClone = vertex.clone();
        aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
        return aClone;
    });



    MatrixUtils._addFrame(aFrontVertices, aBackVertices,
        aFrontSurface.surface, aBackSurface.surface, aSurfaces, SurfaceContext.FRAME);


    return aSurfaces
}

export const createBlackBoxDevice = (pSize: Vector3, pName: string, pColor: string) => {
    const aSurfaces = getBlackBox(pSize)

    const aFaces = aSurfaces.map((surface) => {
        return OpticsFactory.getFace({
            surface: surface,
            materialID: Strings.TRANSPARENT_MATERIAL,
            is_inside_blackbox: true
        });
    });

    const aPartObject3D = new Object3D();
    const aShapeObject3D = new Object3D();
    aPartObject3D.add(aShapeObject3D);

    const aSolidObject3D = new Object3D();
    for (let i = 0; i < aFaces.length; i++) {
        (aFaces[i].visualization.mesh.material as any).color.set(pColor)
        aSolidObject3D.add(aFaces[i].visualization.mesh);
    }

    const aFLetter = PartsFactory.getFLetter(pSize.x * 2,
        pSize.y * 2);
    aFLetter.position.set(-pSize.x / 2, -pSize.y / 2, (-pSize.z / 2) - 0.1)
    aSolidObject3D.add(aFLetter);

    const aBLetter = PartsFactory.getBLetter(pSize.x * 2,
        pSize.y * 2, 10);
    aBLetter.position.set(pSize.x / 2, -pSize.y / 2, (pSize.z / 2) + 0.1)
    aSolidObject3D.add(aBLetter);


    aShapeObject3D.add(aSolidObject3D);

    const aPart: iPart = {
        data: {

        },
        name: pName,
        shapes: [{
            solids: [{
                faces: aFaces,
                object3D: aSolidObject3D,
                name: 'solid_0',
                internal_id: Op3dUtils.idGenerator()
            }],
            name: 'shape_0',
            object3D: aShapeObject3D,
            internal_id: Op3dUtils.idGenerator()
        }],
        object3D: aPartObject3D,
        internal_id: Op3dUtils.idGenerator()
    };

    return aPart;
}



export const setPartToBB = (pPart: Part) => {
    const event = new CustomEvent('addPart', { detail: pPart });
    window.dispatchEvent(event);
}


// export const changeBoxOffset = (pSize: Vector3, pCenter: Vector3, pMargins: { Front_margin, Back_margin }, pDiameter) => {
export const changeBoxOffset = (pSize: Vector3, pCenter: Vector3, pBlackBoxSettings: eBlackBoxSetting) => {
    let aFrontOffset = parseFloat(pBlackBoxSettings.front_margin)
    let aBackOffset = parseFloat(pBlackBoxSettings.back_margin)
    let mDiameter = parseFloat(pBlackBoxSettings.barrel_outer_diameter)
    aFrontOffset = aFrontOffset == 0 ? 0.001 : aFrontOffset
    aBackOffset = aBackOffset == 0 ? 0.001 : aBackOffset
    pCenter.z -= ((aFrontOffset / 2))
    pCenter.z += ((aBackOffset / 2))

    pSize.x = mDiameter
    pSize.y = mDiameter
    pSize.z += aFrontOffset
    pSize.z += aBackOffset
}

export const _createBlackBoxInMongo = async (pName: string, pId: string, pBlackBoxData: iBlackBoxData) => {
    const aPartVO: iPartVOData = {
        id: pName + '_' + pId,
        number_id: pId,
        name: pName,
        url: 'BlackBox',
        sideBarPart: true,
        originalName: pName,
        main_section: "Assembly",
        section: 'Private',
        category: 'Private',
        subCategory: 'Private',
        isBlackBox: true,
        original_url: ''
    }

    const aPartMongo: iPartMongoDB = {
        permission: eDataPermission.PRIVATE,
        number_id: pId,
        name: pName + '_' + pId,
        info: aPartVO,
        owner: Op3dContext.USER_VO.id,
        black_box_data: pBlackBoxData
    }

    await PartsDataLoader.instance.add(aPartMongo);
}
export const _saveBlackBoxSetupToCopy = async (pBlackBoxPart: Part, pBlackBoxContent: Set<Part>) => {
    try {
        const aFilteredParts = []
        for (let part of Op3dContext.PARTS_MANAGER.parts) {
            const aIsPartInBlackBox = pBlackBoxContent.has(part)
            if (aIsPartInBlackBox != true) {
                if (ePartType.BLACKBOX === part.partOptions.type) {
                    part.visibleObj.visible = true
                }
                aFilteredParts.push(part)
            }
        }

        const aSetupData = Op3dContext.SETUPS_MANAGER.getSetupData(aFilteredParts);

        Op3dContext.PARTS_MANAGER.deletePart(pBlackBoxPart)

        const aCopyCurrentSetup = JSON.stringify(aSetupData);
        const aCurrentSetup = JSON.parse(aCopyCurrentSetup) as iUploadSetupData;
        aCurrentSetup._id = null;
        const aCurName = aCurrentSetup.name
        aCurrentSetup.name = aCurName + '_BB';
        aCurrentSetup.parameters.details.setupName = aCurName + '_BB'

        const aImage = SnapshotTools.getSceneSnapshot2(true);
        aCurrentSetup.image = aImage;

        const aRes = await ServerContext.SERVER.updateSetup(aCurrentSetup);
        if (aRes.success) {
            const aNewSetupID = aRes.data;
            localStorage.setItem(Main.OP3D_SETUP_ID, aNewSetupID);
            window.open(window.location.href, '_blank');
        }

    } catch (e) {
        MessagesHandler.ON_ERROR_PROGRAM({
            error: e,
            type: eErrorType.GENERAL
        });
    }
}

export const groupParts = async (pParts: Set<Part>, pBoundingBox: Box3, pName: string, pBlackBoxSettings: eBlackBoxSetting) => {
    if (pParts.size == 0) return { success: false }
    Spinner.instance.show();
    const aSize = pBoundingBox.getSize(new Vector3())
    const aCenter = pBoundingBox.getCenter(new Vector3)


    changeBoxOffset(aSize, aCenter, pBlackBoxSettings)
    const aNewPartId = Op3dUtils.idGenerator().toLowerCase();

    SceneContext.CHOOSE_MODE.mode = eClickMode.BASE

    const iPart = createBlackBoxDevice(aSize, pName.trim(), pBlackBoxSettings.barrel_color)

    const aBlackboxParts = new Array<Part>(...pParts).map(part => part.exportToJSONObject());
    const aBlackboxContent: iBlackboxSetup = {
        parts: aBlackboxParts,
        setup_version: SetupsManager.SETUP_VERSION
    };

    const aBBData: iBlackBoxData = {
        shape_data: {
            center: aCenter,
            size: aSize,
            color: pBlackBoxSettings.barrel_color
        },
        content: btoa(JSON.stringify(aBlackboxContent))
    }

    await _createBlackBoxInMongo(pName.trim(), aNewPartId, aBBData)
    const aPartMongoDB =
        await PartsDataLoader.instance.getSingleFullData({
            number_id: aNewPartId
        }, false);


    Op3dContext.DATA_MANAGER.addToPartsData(aPartMongoDB);
    const aPart = new Part({
        id: aPartMongoDB.name,
        number_id: aNewPartId,
        options: { type: ePartType.BLACKBOX }
    }).add(iPart);
    aPart.visibleObj.position.copy(aCenter)

    aPart.visibleObj.visible = false

    _saveBlackBoxSetupToCopy(aPart, pParts)

    Spinner.instance.hide();
    return { success: true }
}

export const isForBlackBox = (pPart: Part): boolean => {
    const aPart = Group.instance.findMainGroupPart(pPart)
    if (aPart.opticalPartType == eCadType.OPTICS) return true
    if ((eAxisType.OPTICS === aPart.refCS.cs.type) ||
        (ePartType.GROUP === aPart.partOptions.type)) {
        return false;
    }

    const aFaces = aPart.getFaces().filter((face) =>
        ((null != face.data) && (null != face.data.simGeoData) && (face.data.materialID != Strings.TRANSPARENT_MATERIAL)));
    if (aFaces.length > 0) return true

    return false
}

export const calculateBoundingBoxFunction = (pParts: Set<Part>, pBoundingBox: Box3) => {
    const aGroup = new THREEGroup()
    pParts.forEach(part => aGroup.add(Op3dUtils.getNetoItem(part.visibleObj)))
    pBoundingBox.setFromObject(aGroup)
}

export const sliceName = (pPart: Part) => {
    const aPartName = pPart.iPart.name
    const aPartLabel = pPart.getLabel().label
    let aDisplayedName = aPartLabel != null ? aPartLabel : aPartName

    if (aDisplayedName.length > 13) {
        aDisplayedName = aDisplayedName.slice(0, 13) + '...'
    }

    return aDisplayedName
}


