import { eUnitType } from "../_context/Enums";
import { ParserContext } from "../parser/ParserContext";

export class UnitHandler {

    public static PRESENTED_UNIT: eUnitType;

    public static MM_TO_IN: number = 1 / 25.4;
    public static IN_TO_MM: number = 25.4;
    private static UNIT: eUnitType;
    public static CM_TO_MM = 10;
    public static INCH_IMPERIC_STEP = 0.75;

    public static SHORT_MM_SIGN: string = 'mm';
    public static SHORT_IN_SIGN: string = 'in';

    public static DEG_TO_RAD: number = (Math.PI / 180);

    //__________________________________________________________________________________________
    public static getUnitToWorld(pUnit: eUnitType) {
        if (pUnit == eUnitType.MILLIMETERS) {
            return 1;
        }

        return this.IN_TO_MM;
    }
    //__________________________________________________________________________________________
    public static signAsStringByUnitForInputElement(pUnit: eUnitType) {
        switch (pUnit) {
            case eUnitType.INCHES:
                return UnitHandler.SHORT_IN_SIGN;
            case eUnitType.MILLIMETERS:
                return UnitHandler.SHORT_MM_SIGN;
        }
    }
    //__________________________________________________________________________________________
    public static get sign() {
        switch (UnitHandler.PRESENTED_UNIT) {
            case eUnitType.INCHES:
                return ParserContext.SPECIAL_CHARACTERS.inch;
            case eUnitType.MILLIMETERS:
                return ParserContext.SPECIAL_CHARACTERS.mm;
        }
    }
    //__________________________________________________________________________________________
    public static get shortSign() {
        switch (UnitHandler.PRESENTED_UNIT) {
            case eUnitType.INCHES:
                return UnitHandler.SHORT_IN_SIGN;
            case eUnitType.MILLIMETERS:
                return UnitHandler.SHORT_MM_SIGN;
        }
    }
    //__________________________________________________________________________________________
    public static get envName() {
        switch (UnitHandler.PRESENTED_UNIT) {
            case eUnitType.INCHES:
                return 'Imperial';
            case eUnitType.MILLIMETERS:
                return 'Metric';
        }
    }
    //__________________________________________________________________________________________
    public static getShortSign(pUnit: eUnitType) {
        switch (pUnit) {
            case eUnitType.INCHES:
                return UnitHandler.SHORT_IN_SIGN;
            case eUnitType.MILLIMETERS:
                return UnitHandler.SHORT_MM_SIGN;
        }
    }
    //__________________________________________________________________________________________
    public static set currUnit(pUnit: eUnitType) {
        this.UNIT = pUnit;
    }
    //__________________________________________________________________________________________
    public static get currUnit() {
        return this.UNIT;
    }
    //__________________________________________________________________________________________
    public static get presentedScale() {
        if (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS) {
            return 1;
        }

        return this.MM_TO_IN;
    }
    //__________________________________________________________________________________________
    public static get scale() {
        if (this.UNIT == eUnitType.MILLIMETERS) {
            return 1;
        }

        return this.MM_TO_IN;
    }
    //__________________________________________________________________________________________
}
