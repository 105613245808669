import { eDataPermission, eSetupType, eErrorType } from "../_context/Enums";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { iSetupParameters, iGetOneSetup, iOpenSetupsMenu, iUploadSetupData, iSetupDetails, iBasicSetupData, iID } from "../_context/_interfaces/Interfaces";
import { Part } from "../parts/Part";
import { iOptixPartJSON } from "../parts/_parts_assets/ExportToJSONInterfaces";
import { iSceneOptions } from "../scene/GridManager";
import { Op3dScene } from "../scene/Op3dScene";
import { SceneContext } from "../scene/SceneContext";
import { ServerContext } from "../server/ServerContext";
import { SettingsContext } from "../settings/SettingsContext";
import { iSaveOptParams } from "../setups/SetupManager";
import { OptImporter } from "../setups/opt/OptImporter";
import { SimulationRunner } from "../simulation/SimulationRunner";
import { Popup } from "../ui/forms/Popup";
import { Spinner } from "../ui/home/Spinner";
import { UnitHandler } from "../units/UnitsHandler";

export class SetupsManagerWidget {

    public static FILE_COUNTER = 0;

    private mSetupPermission: eDataPermission = eDataPermission.PUBLIC;
    private mSetupParameters: iSetupParameters;
    public mCurrSetupID: string;
    private mBeforeUnloadFunc: EventListener;
    private mOwnerId: string;

    constructor() {
        this._init();
        this._initParameters();
    }
    //__________________________________________________________________________________________
    private _initParameters() {
        this.mSetupParameters = {
            settings: SettingsContext.getUserSimulationSettings(),
            to_publish: false,
            details: {
                setupName: "Untitled Setup"
            },
            env: ServerContext.env,
            setupType: eSetupType.USER,
            unit: UnitHandler.PRESENTED_UNIT,
            version: Op3dContext.SETUP_NUM,
            env_version: Op3dContext.VERSION_NUM,
            changes_in_setup: false,
            setup_version_to_compare: '',
            simulation_version_to_compare: 0,
            changes_in_setup_for_analysis: false,
            show_labels: Op3dScene.SHOW_ALL_LABELS,
            previews_permission: this.mSetupPermission,
            initialPartsQuantity: 0
        };
    }
    //__________________________________________________________________________________________
    public get settings() {
        return this.mSetupParameters.settings;
    }
    //__________________________________________________________________________________________
    public async getOneSetup(pData: iGetOneSetup) {
        let aRes = await ServerContext.SERVER.getOneSetup(pData);
        return aRes;
    }
    //______________________________________________________________________________________________
    public set session(_pID: string) {
    }
    //__________________________________________________________________________________________
    public resetCurrSetupID() {
        this.mCurrSetupID = null
    }
    //__________________________________________________________________________________________
    public async closeSetup() { }
    //__________________________________________________________________________________________
    public set setupID(_pID: string) { }
    //__________________________________________________________________________________________
    private async _init() {

    }
    //__________________________________________________________________________________________
    public get beforeUnloadFunc() {
        return this.mBeforeUnloadFunc;
    }
    //__________________________________________________________________________________________
    public async onOpenSetup(pData: iOpenSetupsMenu) {
        Op3dContext.TOTAL_PARTS = JSON.parse(pData.content.content).length;
        Op3dContext.LOADED_PARTS = 0;
        Spinner.instance.show();
        await Op3dContext.wait(() => (true == Op3dContext.DATA_MANAGER.isReady));
        Spinner.instance.hide();
        try {
            Spinner.instance.show();

            if (false == pData.addToExisting) {
            }

            await new OptImporter().readContent(pData.content);
            this.mCurrSetupID = pData.content._id;
            this.mOwnerId = pData.content.owner;

            SceneContext.OP3D_SCENE.setCameraDefault(Op3dContext.PARTS_MANAGER.getCenter());
            Spinner.instance.hide();
        }
        catch (error) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(error);

            Spinner.instance.hide();
            Popup.instance.open({ text: MessagesHandler.ERROR_WHILE_LOADING_SETUP });
        }
        finally {
            Spinner.instance.hide();
        }
    }
    //__________________________________________________________________________________________
    public async initAfterLoad() {
        await Op3dContext.PARTS_MANAGER.updateAllParts();

        if (this.mSetupParameters.details.runSimOnload) {
            await SimulationRunner.instance.runVisualizationSimulation();
        }

        const urlParams = new URLSearchParams(window.location.search);
        const aSetupID = window.atob(urlParams.get("id"));
        let aIsSimulationExist = await SimulationRunner.instance._isSimulationExist(aSetupID);
        if (aIsSimulationExist == true) {
            SimulationRunner.instance._onVisualisationReady(aSetupID, false);
        }
    }
    //__________________________________________________________________________________________
    public set currSetupID(pID: string) {
        this.mCurrSetupID = pID;
    }
    //__________________________________________________________________________________________
    public get ownerId() {
        return this.mOwnerId;
    }
    //__________________________________________________________________________________________
    public get currSetupID() {
        return this.mCurrSetupID;
    }
    //__________________________________________________________________________________________
    private _export(pParts: Array<Part>) {
        try {
            let aExportArrayParts = new Array<iOptixPartJSON>();

            for (let i = 0; i < pParts.length; i++) {
                const aCurrPart = pParts[i].exportToJSONObject();
                if (aCurrPart != null) {
                    aExportArrayParts.push(aCurrPart);
                }
            }
            return aExportArrayParts;

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({
                error: e,
                type: eErrorType.IMPORT_EXPORT_SETUP,
                show_message: true,
            });
        }
    }
    //__________________________________________________________________________________________
    public getSetupData(): iUploadSetupData {
        let aParts = this._export(Op3dContext.PARTS_MANAGER.parts);
        const aPermission = this.mSetupParameters.setupType === eSetupType.EXAMPLE ?
            eDataPermission.PUBLIC : this.mSetupPermission;
        this.mSetupParameters.show_labels = Op3dScene.SHOW_ALL_LABELS;
        const aSetupData: iUploadSetupData = {
            content: JSON.stringify(aParts),
            _id: this.mCurrSetupID,
            name: this.mSetupParameters.details.setupName,
            permission: aPermission,
            parameters: this.mSetupParameters,
            bom: {
                optics: [] as any,
                optomechanics: [] as any
            },
        };
        return aSetupData;
    }
    //__________________________________________________________________________________________
    public get labelState() {
        return
    }
    //__________________________________________________________________________________________
    public set labelState(_pVal) {
        return
    }
    //__________________________________________________________________________________________
    public async saveOpt(_pSaveOptParams: iSaveOptParams) { }
    //__________________________________________________________________________________________
    public closeOpenSetup(_pForce: boolean, _pData?: iID) { }
    //__________________________________________________________________________________________
    private _checkName(pData: iSetupDetails) {
        if (this.mSetupParameters.details.setupName != pData.setupName) {
            Op3dContext.DIV_CONTROLLER.setFileName({
                to_update: false,
                name: pData.setupName
            });
        }
    }
    //__________________________________________________________________________________________
    public onStartNewDesign(pSetupData: iBasicSetupData, pSetupPermisison: eDataPermission) {
        if (this.mCurrSetupID != null) {
            ServerContext.SERVER.closeSetup({ _id: this.mCurrSetupID })
            this.mCurrSetupID = null;
        }

        this._checkName(pSetupData.parameters.details);
        this.mSetupParameters.details = pSetupData.parameters.details;
        this.mSetupPermission = pSetupPermisison;
    }
    //__________________________________________________________________________________________
    public downloadOPT() { }
    //__________________________________________________________________________________________
    public updateSetupDetails(_pDetails: iSetupDetails) { }
    //__________________________________________________________________________________________
    public onUpdateSceneOptions(_pSceneOptions: iSceneOptions) { }
    //__________________________________________________________________________________________
    public set setupParameters(pParameters: iSetupParameters) {
        this.mSetupParameters = pParameters;
    }
    //__________________________________________________________________________________________
    public get setupParameters() {
        return this.mSetupParameters;
    }
    //__________________________________________________________________________________________
    public get fileName(): string {
        return this.mSetupParameters.details.setupName;
    }
    //__________________________________________________________________________________________
}