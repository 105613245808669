
import { eDataPermission, eIngredientType, eUnitType } from "../../../_context/Enums";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iElasticQuery } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOptomechanicVO } from "../../../data/VO/OpticsVOInterfaces";
import { OptomechanicDataLoader } from "../../../data/data_loader/OptomechanicDataLoader";
import { ENVS, ServerContext } from "../../../server/ServerContext";
import { Popup } from "../../forms/Popup";
import { Spinner } from "../../home/Spinner";
import { MenuListAbstract } from "./MenuListAbstract";
import { iOpticsMenuOpen, iMenuListColumn, iOptomechanicMenuOpen } from "./MenusContext";
import autoComplete from "@tarekraafat/autocomplete.js";
import { OptomechanicFiltersHandler, eOMPFilterName } from "./_filterHandlers/OptomechanicFilterHandler";
import { PartsDataLoader } from "../../../data/data_loader/PartsDataLoader";
import { Part } from "../../../parts/Part";

export class OptomechanicMenu extends MenuListAbstract<iOptomechanicVO, iOptomechanicMenuOpen> {

    private static INSTANCE: OptomechanicMenu;
    private pFromPart: boolean
    //__________________________________________________________________________________________
    private constructor(pParams: { skinPath: string, container: HTMLElement }) {
        super({
            skinPath: pParams.skinPath,
            container: pParams.container,
        }, {
            isHelpMeSearchBtnExits: false,
            title: "PRODUCT CATALOG",
            category: 'Opto-Mechanics',
            limit: 50,
            dataLoader: OptomechanicDataLoader.instance,
            columns: [
                {
                    name: 'Catalog name',
                    serverPath: eOMPFilterName.catalog_number,
                    sortName: eOMPFilterName.catalog_number,
                    showOnStart: true,
                    width: 200,
                },
                {
                    name: 'Main name',
                    serverPath: eOMPFilterName.main_name,
                    sortName: eOMPFilterName.main_name,
                    showOnStart: true,
                    width: 300,
                },
                {
                    name: 'Type',
                    serverPath: eOMPFilterName.type,
                    sortName: eOMPFilterName.type,
                    showOnStart: true,
                    width: 150,
                },
                {
                    name: 'SubType',
                    serverPath: eOMPFilterName.sub_type,
                    sortName: eOMPFilterName.sub_type,
                    showOnStart: true,
                    width: 200,
                },
                {
                    name: 'Brand',
                    serverPath: eOMPFilterName.brand,
                    sortName: eOMPFilterName.brand,
                    showOnStart: true,
                    width: 150,
                },
                {
                    name: 'Typebrand',
                    serverPath: eOMPFilterName.typebrand,
                    sortName: eOMPFilterName.typebrand,
                    showOnStart: true,
                    width: 150,
                },

                {
                    name: 'Metric imperial',
                    serverPath: eOMPFilterName.metric_imperial,
                    sortName: eOMPFilterName.metric_imperial,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Material',
                    serverPath: eOMPFilterName.material,
                    sortName: eOMPFilterName.material,
                    showOnStart: true,
                    width: 200,
                },
                {
                    name: 'Finish coated',
                    serverPath: eOMPFilterName.finish_coated,
                    sortName: eOMPFilterName.finish_coated,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Type of Optics',
                    serverPath: eOMPFilterName.optical_shape,
                    sortName: eOMPFilterName.optical_shape,
                    showOnStart: true,
                    width: 200,
                },
                {
                    name: 'Construction',
                    serverPath: eOMPFilterName.construction,
                    sortName: eOMPFilterName.construction,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Maximum depth',
                    serverPath: eOMPFilterName.maximum_depth,
                    sortName: eOMPFilterName.maximum_depth,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Size of compatible optics min',
                    serverPath: eOMPFilterName.size_of_compatible_optics_min,
                    sortName: eOMPFilterName.size_of_compatible_optics_min,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Size of compatible optics max',
                    serverPath: eOMPFilterName.size_of_compatible_optics_max,
                    sortName: eOMPFilterName.size_of_compatible_optics_max,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Tall rectangular optics',
                    serverPath: eOMPFilterName.tall_rectangular_optics,
                    sortName: eOMPFilterName.tall_rectangular_optics,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Optics diameter size min',
                    serverPath: eOMPFilterName.optics_diameter_size_min,
                    sortName: eOMPFilterName.optics_diameter_size_min,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Optics diameter size max',
                    serverPath: eOMPFilterName.optics_diameter_size_max,
                    sortName: eOMPFilterName.optics_diameter_size_max,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Optic thickness min',
                    serverPath: eOMPFilterName.optic_thickness_min,
                    sortName: eOMPFilterName.optic_thickness_min,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Optic thickness max',
                    serverPath: eOMPFilterName.optic_thickness_max,
                    sortName: eOMPFilterName.optic_thickness_max,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Thickness of compatible optics min',
                    serverPath: eOMPFilterName.thickness_of_compatible_optics_min,
                    sortName: eOMPFilterName.thickness_of_compatible_optics_min,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Thickness of compatible optics max',
                    serverPath: eOMPFilterName.thickness_of_compatible_optics_max,
                    sortName: eOMPFilterName.thickness_of_compatible_optics_max,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Clear aperture diameter',
                    serverPath: eOMPFilterName.clear_aparture_diameter,
                    sortName: eOMPFilterName.clear_aparture_diameter,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Optical height',
                    serverPath: eOMPFilterName.optical_height,
                    sortName: eOMPFilterName.optical_height,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Platform width',
                    serverPath: eOMPFilterName.platform_width,
                    sortName: eOMPFilterName.platform_width,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Platform height',
                    serverPath: eOMPFilterName.platform_height,
                    sortName: eOMPFilterName.platform_height,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Compatible lens tubes',
                    serverPath: eOMPFilterName.compatible_lens_tubes,
                    sortName: eOMPFilterName.compatible_lens_tubes,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Type of Movements',
                    serverPath: eOMPFilterName.type_of_movement,
                    sortName: eOMPFilterName.type_of_movement,
                    showOnStart: false,
                    width: 200,
                },
                {
                    name: 'Type of Drive',
                    serverPath: eOMPFilterName.type_of_drive,
                    sortName: eOMPFilterName.type_of_drive,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Key feature',
                    serverPath: eOMPFilterName.key_feature,
                    sortName: eOMPFilterName.key_feature,
                    showOnStart: true,
                    width: 200,
                },
                {
                    name: 'Actuator orientation',
                    serverPath: eOMPFilterName.actuator_orientation,
                    sortName: eOMPFilterName.actuator_orientation,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Resolution tilt',
                    serverPath: eOMPFilterName.resolution_tilt,
                    sortName: eOMPFilterName.resolution_tilt,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Platform thread',
                    serverPath: eOMPFilterName.platform_thread,
                    sortName: eOMPFilterName.platform_thread,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Thread type',
                    serverPath: eOMPFilterName.thread_type,
                    sortName: eOMPFilterName.thread_type,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Adjustment range tilt',
                    serverPath: eOMPFilterName.adjustment_range_tilt,
                    sortName: eOMPFilterName.adjustment_range_tilt,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Adjustment range rotation',
                    serverPath: eOMPFilterName.adjustment_range_rotation,
                    sortName: eOMPFilterName.adjustment_range_rotation,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Resolution rotation',
                    serverPath: eOMPFilterName.resolution_rotation,
                    sortName: eOMPFilterName.resolution_rotation,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Adjuster thread',
                    serverPath: eOMPFilterName.adjuster_thread,
                    sortName: eOMPFilterName.adjuster_thread,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Ajuster screw thread',
                    serverPath: eOMPFilterName.adjuster_screw_thread,
                    sortName: eOMPFilterName.adjuster_screw_thread,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Fine adjustment range rotation',
                    serverPath: eOMPFilterName.fine_adjustment_range_rotation,
                    sortName: eOMPFilterName.fine_adjustment_range_rotation,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Fine tilt angular range',
                    serverPath: eOMPFilterName.fine_tilt_angular_range,
                    sortName: eOMPFilterName.fine_tilt_angular_range,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Angular range piezo',
                    serverPath: eOMPFilterName.angular_range_piezo,
                    sortName: eOMPFilterName.angular_range_piezo,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Angular range',
                    serverPath: eOMPFilterName.angular_range,
                    sortName: eOMPFilterName.angular_range,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Adjustment per revolution',
                    serverPath: eOMPFilterName.adjustment_per_revolution,
                    sortName: eOMPFilterName.adjustment_per_revolution,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Adjustment per revolution piezo',
                    serverPath: eOMPFilterName.adjustment_per_revolution_piezo,
                    sortName: eOMPFilterName.adjustment_per_revolution_piezo,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Pointing stability',
                    serverPath: eOMPFilterName.pointing_stability,
                    sortName: eOMPFilterName.pointing_stability,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Screw diameter and pitch',
                    serverPath: eOMPFilterName.screw_diameter_and_pitch,
                    sortName: eOMPFilterName.screw_diameter_and_pitch,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Adjustment screw pitch',
                    serverPath: eOMPFilterName.adjustment_screw_pitch,
                    sortName: eOMPFilterName.adjustment_screw_pitch,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Tapped holes',
                    serverPath: eOMPFilterName.tapped_holes,
                    sortName: eOMPFilterName.tapped_holes,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Post mounting screw',
                    serverPath: eOMPFilterName.post_mounting_screw,
                    sortName: eOMPFilterName.post_mounting_screw,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Though hole dia',
                    serverPath: eOMPFilterName.though_hole_dia,
                    sortName: eOMPFilterName.though_hole_dia,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Mounting holes',
                    serverPath: eOMPFilterName.mounting_holes,
                    sortName: eOMPFilterName.mounting_holes,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Mounting thread',
                    serverPath: eOMPFilterName.mounting_thread,
                    sortName: eOMPFilterName.mounting_thread,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Sensitivity arc',
                    serverPath: eOMPFilterName.sensitivity_arc,
                    sortName: eOMPFilterName.sensitivity_arc,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Vacuum compatibility torr',
                    serverPath: eOMPFilterName.vacuum_compatibility_torr,
                    sortName: eOMPFilterName.vacuum_compatibility_torr,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Weight',
                    serverPath: eOMPFilterName.weight,
                    sortName: eOMPFilterName.weight,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Lock nuts',
                    serverPath: eOMPFilterName.lock_nuts,
                    sortName: eOMPFilterName.lock_nuts,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Maximum optic thickness',
                    serverPath: eOMPFilterName.maximum_optic_thickness,
                    sortName: eOMPFilterName.maximum_optic_thickness,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Minimum optic thickness',
                    serverPath: eOMPFilterName.minimum_optic_thickness,
                    sortName: eOMPFilterName.minimum_optic_thickness,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Optic diameter min',
                    serverPath: eOMPFilterName.optic_diameter_min,
                    sortName: eOMPFilterName.optic_diameter_min,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Optic diameter max',
                    serverPath: eOMPFilterName.optic_diameter_max,
                    sortName: eOMPFilterName.optic_diameter_max,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Optic size accomodated max',
                    serverPath: eOMPFilterName.optic_size_accomodated_max,
                    sortName: eOMPFilterName.optic_size_accomodated_max,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Optic size accomodated min',
                    serverPath: eOMPFilterName.optic_size_accomodated_min,
                    sortName: eOMPFilterName.optic_size_accomodated_min,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },

                {
                    name: 'Optic size max',
                    serverPath: eOMPFilterName.optic_size_max,
                    sortName: eOMPFilterName.optic_size_max,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Thickness of Compatible Optics min',
                    serverPath: eOMPFilterName.thickness_of_compatible_optics_min,
                    sortName: eOMPFilterName.thickness_of_compatible_optics_min,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Thickness of Compatible Optics max',
                    serverPath: eOMPFilterName.thickness_of_compatible_optics_max,
                    sortName: eOMPFilterName.thickness_of_compatible_optics_max,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'Thickness',
                    serverPath: eOMPFilterName.thickness,
                    sortName: eOMPFilterName.thickness,
                    showOnStart: false,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    width: 150,
                },
                {
                    name: 'A',
                    serverPath: eOMPFilterName.a,
                    sortName: eOMPFilterName.a,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'B',
                    serverPath: eOMPFilterName.b,
                    sortName: eOMPFilterName.b,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Number of optics',
                    serverPath: eOMPFilterName.number_of_optics,
                    sortName: eOMPFilterName.number_of_optics,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Compatible lens tubes od',
                    serverPath: eOMPFilterName.compatible_lens_tubes_od,
                    sortName: eOMPFilterName.compatible_lens_tubes_od,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Inner diameter',
                    serverPath: eOMPFilterName.inner_diameter,
                    sortName: eOMPFilterName.inner_diameter,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Od',
                    serverPath: eOMPFilterName.od,
                    sortName: eOMPFilterName.od,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Outer diameter',
                    serverPath: eOMPFilterName.outer_diameter,
                    sortName: eOMPFilterName.outer_diameter,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Clear aperture',
                    serverPath: eOMPFilterName.clear_aperture,
                    sortName: eOMPFilterName.clear_aperture,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Min aperture',
                    serverPath: eOMPFilterName.min_aperture,
                    sortName: eOMPFilterName.min_aperture,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Max aperture',
                    serverPath: eOMPFilterName.max_aperture,
                    sortName: eOMPFilterName.max_aperture,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Iris diaphragm min',
                    serverPath: eOMPFilterName.iris_diaphragm_max,
                    sortName: eOMPFilterName.iris_diaphragm_max,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Iris diaphragm max',
                    serverPath: eOMPFilterName.iris_diaphragm_min,
                    sortName: eOMPFilterName.iris_diaphragm_min,
                    translateFunction: (pValue) => this._getScaledNumber(pValue),
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Number of leaves',
                    serverPath: eOMPFilterName.number_of_leaves,
                    sortName: eOMPFilterName.number_of_leaves,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Number of blades',
                    serverPath: eOMPFilterName.number_of_blades,
                    sortName: eOMPFilterName.number_of_blades,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Travel x',
                    serverPath: eOMPFilterName.travel_x,
                    sortName: eOMPFilterName.travel_x,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Travel y',
                    serverPath: eOMPFilterName.travel_y,
                    sortName: eOMPFilterName.travel_y,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Travel z',
                    serverPath: eOMPFilterName.travel_z,
                    sortName: eOMPFilterName.travel_z,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Number of axes',
                    serverPath: eOMPFilterName.number_of_axes,
                    sortName: eOMPFilterName.number_of_axes,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Drive type',
                    serverPath: eOMPFilterName.drive_type,
                    sortName: eOMPFilterName.drive_type,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Drive mechanism per revolution',
                    serverPath: eOMPFilterName.drive_mechanism_per_revolution,
                    sortName: eOMPFilterName.drive_mechanism_per_revolution,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Thread',
                    serverPath: eOMPFilterName.thread,
                    sortName: eOMPFilterName.thread,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Sensitivity um',
                    serverPath: eOMPFilterName.sensitivity_um,
                    sortName: eOMPFilterName.sensitivity_um,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Vernier',
                    serverPath: eOMPFilterName.vernier,
                    sortName: eOMPFilterName.vernier,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Air tank capacity',
                    serverPath: eOMPFilterName.air_tank_capacity,
                    sortName: eOMPFilterName.air_tank_capacity,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Air pressure',
                    serverPath: eOMPFilterName.air_pressure,
                    sortName: eOMPFilterName.air_pressure,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Free air delivery',
                    serverPath: eOMPFilterName.free_air_delivery,
                    sortName: eOMPFilterName.free_air_delivery,
                    showOnStart: false,
                    width: 150,
                },
                {
                    name: 'Vacuum compatibility tor',
                    serverPath: eOMPFilterName.vacuum_compatibility_tor,
                    sortName: eOMPFilterName.vacuum_compatibility_tor,
                    showOnStart: false,
                    width: 150,
                }],
        });

        this.mSignelLineItemQaId = "qa_optics_item";
    }
    //__________________________________________________________________________________________
    public checkAddToCanvasLimit() {
        let aLimit, aWarningMessage
        if (this.pFromPart == true) {
            aLimit = 1
            aWarningMessage = MessagesHandler.CHOOSE_ONE_OPTIC
        } else {
            aLimit = 10
            aWarningMessage = MessagesHandler.CHOOSE_OPTICS_LIMIT
        }
        return { limit: aLimit, warningMessage: aWarningMessage };
    }
    //__________________________________________________________________________________________
    protected addPromotedResults(pQueryElastic: iElasticQuery, pBrands: Array<string>) {
        this.mResultsListPromoted.innerHTML = ''
        for (let brand of pBrands) {
            this.fillPromotedList(pQueryElastic, brand, 'PARTS')
        }
    }
    //__________________________________________________________________________________________
    private _getScaledNumber(pValue: string) {
        if (null == pValue) {
            return '';
        }

        let aScale = (eUnitType.INCHES == this.mFiltersHandler.filterUnit) ? (1 / 25.4) : 1;
        return (parseFloat(pValue.toString()) * aScale).toFixed(2);
    }
    //__________________________________________________________________________________________
    protected excludePromotedResults(pResults: Array<iOptomechanicVO>) {
        const filteredArray = pResults.filter((obj) => !this.mPromotedIds.includes((obj.number_id as any).raw));
        this.mPromotedIds = []
        return filteredArray;
    }
    //__________________________________________________________________________________________
    private addOrQuery(pGlobalOrArray: Array<Object>, pFilter, pPath) {

        if (pFilter != null) {
            let aFieldExists = this.mMenuParams.columns.find(item => item.serverPath === pPath)
            this.updateAppliedFilters(aFieldExists.name, pFilter, pPath)
            pGlobalOrArray.push(pFilter.map((type: string) => ({ [pPath]: type })))
        }
    }
    //__________________________________________________________________________________________
    private addRangeQuery(pGlobalAndArray, pFilter, pPath) {

        if (pFilter != null) {
            this.updateAppliedFilters('Fine Tilt Range', `${pFilter['from']} -  ${pFilter['to']}`, pPath)
            pGlobalAndArray.push({ [pPath]: pFilter })

        }
    }
    //__________________________________________________________________________________________
    protected _getQuery() {
        this.resetAppliedFilters()

        let aFilters = this.mFiltersHandler.getFilters()

        let aMainOrQueries = [
            eOMPFilterName.optical_shape,
            eOMPFilterName.sub_type,
            eOMPFilterName.brand,
            eOMPFilterName.material,
            eOMPFilterName.type_of_movement,
            eOMPFilterName.key_feature,
            eOMPFilterName.compatible_lens_tubes,
            eOMPFilterName.platform_thread,
            eOMPFilterName.actuator_orientation,
            eOMPFilterName.thread_type,
            eOMPFilterName.adjuster_thread,
            eOMPFilterName.tapped_holes,
            eOMPFilterName.drive_type,
            eOMPFilterName.thread,
        ]
        let aMainRangeQueries = [
            eOMPFilterName.fine_tilt_angular_range,
            eOMPFilterName.adjuster_screw_thread,
            eOMPFilterName.tall_rectangular_optics,
            eOMPFilterName.clear_aparture_diameter,
            eOMPFilterName.though_hole_dia,
            eOMPFilterName.maximum_depth,
            eOMPFilterName.optical_height,
            eOMPFilterName.adjustment_per_revolution,
            eOMPFilterName.resolution_tilt,
            eOMPFilterName.pointing_stability,
            eOMPFilterName.sensitivity_arc,
            eOMPFilterName.vacuum_compatibility_torr,
            eOMPFilterName.drive_mechanism_per_revolution,
            eOMPFilterName.inner_diameter,
            eOMPFilterName.outer_diameter,
            eOMPFilterName.a,
            eOMPFilterName.b,
            eOMPFilterName.number_of_optics,
            eOMPFilterName.od,
            eOMPFilterName.travel_x,
            eOMPFilterName.travel_y,
            eOMPFilterName.weight
        ]

        //OTHER FILTERS
        let aType = (<any>aFilters?.[eOMPFilterName.type])
        let aPermission = (<any>aFilters?.permission)
        let aSizeOfOptics = aFilters[eOMPFilterName.size_of_compatible_optics]
        let aThicknessOptics = aFilters[eOMPFilterName.thickness_of_compatible_optics]
        let aOpticDiameterSize = aFilters[eOMPFilterName.optics_diameter_size]
        let aPlatformSize = aFilters[eOMPFilterName.platform_size]
        let aAngularRange = aFilters[eOMPFilterName.angular_range]
        let aOpticDiameter = aFilters[eOMPFilterName.optic_diameter]
        let aThickness = aFilters[eOMPFilterName.thickness]
        let aAperture = aFilters[eOMPFilterName.min_aperture]
        let aIris = aFilters[eOMPFilterName.iris_diaphragm]
        let aFinishCoated = (<any>aFilters?.[eOMPFilterName.finish_coated])
        let aPostMountingScrew = (<any>aFilters?.[eOMPFilterName.post_mounting_screw])

        let aFreeString = Op3dUtils.getNormalizedName(this.mSearchInput.value)?.trim()

        this.privateFilterApplied(aPermission == 0)

        let aLimit = +(<HTMLSelectElement>this._getPart("results")).value
        let aOrFilters = []
        let aAndFilters = []
        const aAggsMustFilters = []
        const aAggsShouldFilters = []
        const aAggsFilter = []
        aAggsFilter.push(...aMainOrQueries, eOMPFilterName.type, eOMPFilterName.permission, eOMPFilterName.post_mounting_screw, eOMPFilterName.finish_coated)

        aAndFilters.push({ 'info.section': 'Mounts' })
        aAggsMustFilters.push({ match: { 'info.section': 'Mounts' } })

        for (let filter of aMainOrQueries) {
            let aCurrentFilter = (<any>aFilters?.[filter])
            this.addOrQuery(aOrFilters, aCurrentFilter, filter)
            aCurrentFilter && aAggsShouldFilters.push({ terms: { [`${filter}.enum`]: aCurrentFilter.map(filter => filter) } })
        }
        for (let filter of aMainRangeQueries) {
            let aCurrentFilter = (<any>aFilters?.[filter])
            this.addRangeQuery(aAndFilters, aCurrentFilter, filter)
            aCurrentFilter && aAggsMustFilters.push({
                range: {
                    [filter]: {
                        "gte": aCurrentFilter['from'],
                        "lte": aCurrentFilter['to']
                    }
                }
            })
        }

        if (aPermission != null) {
            if (aPermission.length != 2) {
                this.updateAppliedFilters('Ownership', aPermission[0] == 1 ? 'Public' : 'Private', "permission")
            }

            if (aPermission == 0) {

                aAndFilters.push({ [eOMPFilterName.owner]: Op3dContext.USER_VO.id })
                aAggsMustFilters.push({ match: { [eOMPFilterName.owner]: Op3dContext.USER_VO.id } })
            } else if (aPermission == 1) {

                aAndFilters.push({ ['permission']: 1 })
                aAggsMustFilters.push({ match: { [eOMPFilterName.permission]: aPermission[0] } })
            } else {

                aOrFilters.push([{ ['permission']: 1 }, { [eOMPFilterName.owner]: Op3dContext.USER_VO.id }])
                aAggsShouldFilters.push({ bool: { should: [{ match: { [eOMPFilterName.permission]: 1 } }, { match: { [eOMPFilterName.owner]: Op3dContext.USER_VO.id } }] } })

            }
        } else {
            aOrFilters.push([{ ['permission']: 1 }, { [eOMPFilterName.owner]: Op3dContext.USER_VO.id }])
            aAggsShouldFilters.push({ bool: { should: [{ match: { [eOMPFilterName.permission]: 1 } }, { match: { [eOMPFilterName.owner]: Op3dContext.USER_VO.id } }] } })
        }

        if (this.mFavoriteFilter.style.fill === 'rgb(149, 158, 158)') {
            this.updateAppliedFilters('Filter', 'Favorites', "favorites")
            if (this.mStarredItems != null && this.mStarredItems.length != 0) {
                aOrFilters.push(this.mStarredItems.map(favorite => ({ [eOMPFilterName.number_id]: favorite })))
            } else {
                aAndFilters.push({ [eOMPFilterName.number_id]: 'EMPTY' })
            }
        }
        if (aFinishCoated != null) {
            this.updateAppliedFilters('Finish coated', aFinishCoated, eOMPFilterName.finish_coated)
            aOrFilters.push(
                aFinishCoated.map((movement: string) => ({ [eOMPFilterName.finish_coated]: movement })).concat(aFinishCoated.map((movement: string) => ({ [eOMPFilterName.construction]: movement })))
            )

            aAggsShouldFilters.push({ bool: { should: [{ terms: { [`${eOMPFilterName.finish_coated}.enum`]: aFinishCoated } }, { terms: { [`${eOMPFilterName.construction}.enum`]: aFinishCoated } }] } })
        }
        if (aPostMountingScrew != null) {
            this.updateAppliedFilters('Post mounting screw', aPostMountingScrew, eOMPFilterName.post_mounting_screw)

            aOrFilters.push(
                aPostMountingScrew.map((movement: string) => ({ [eOMPFilterName.post_mounting_screw]: movement }))
                    .concat(aPostMountingScrew.map((movement: string) => ({ [eOMPFilterName.mounting_thread]: movement })))
                    .concat(aPostMountingScrew.map((movement: string) => ({ [eOMPFilterName.mounting_holes]: movement })))
            )
            aAggsShouldFilters.push({ bool: { should: [{ terms: { [`${eOMPFilterName.post_mounting_screw}.enum`]: aPostMountingScrew } }, { terms: { [`${eOMPFilterName.mounting_thread}.enum`]: aPostMountingScrew } }, { terms: { [`${eOMPFilterName.mounting_holes}.enum`]: aPostMountingScrew } }] } })
        }
        if (aSizeOfOptics != null) {
            this.updateAppliedFilters('Size Of Compatible Optics', `${aSizeOfOptics['from']} -  ${aSizeOfOptics['to']}`, eOMPFilterName.size_of_compatible_optics)
            aAndFilters.push({ [eOMPFilterName.size_of_compatible_optics_min]: aSizeOfOptics })
            aAndFilters.push({ [eOMPFilterName.size_of_compatible_optics_max]: aSizeOfOptics })

            aAggsMustFilters.push({ match: { [eOMPFilterName.size_of_compatible_optics_min]: aSizeOfOptics } }, { match: { [eOMPFilterName.size_of_compatible_optics_max]: aSizeOfOptics } })

        }
        if (aThicknessOptics != null) {
            this.updateAppliedFilters('Thickness of Optics', `${aThicknessOptics['from']} -  ${aThicknessOptics['to']}`, eOMPFilterName.thickness_of_compatible_optics)
            aAndFilters.push({
                any: [
                    {
                        any: [{ [eOMPFilterName.optic_thickness_min]: aThicknessOptics }, { [eOMPFilterName.optic_thickness_max]: aThicknessOptics }]
                    },
                    {
                        any: [{ [eOMPFilterName.thickness_of_compatible_optics_min]: aThicknessOptics }, { [eOMPFilterName.thickness_of_compatible_optics_max]: aThicknessOptics }]
                    }
                ]
            })

        }
        if (aOpticDiameterSize != null) {
            this.updateAppliedFilters('Optic Diameter Size', `${aOpticDiameterSize['from']} -  ${aOpticDiameterSize['to']}`, eOMPFilterName.optic_diameter)
            aAndFilters.push({ [eOMPFilterName.optics_diameter_size_min]: aOpticDiameterSize }, { [eOMPFilterName.optics_diameter_size_max]: aOpticDiameterSize })
            aAggsMustFilters.push({ match: { [eOMPFilterName.optics_diameter_size_min]: aOpticDiameterSize } }, { match: { [eOMPFilterName.optics_diameter_size_max]: aOpticDiameterSize } })
        }
        if (aPlatformSize != null) {
            this.updateAppliedFilters('Platform Size', `${aPlatformSize['from']} -  ${aPlatformSize['to']}`, eOMPFilterName.platform_size)
            aAndFilters.push({ [eOMPFilterName.platform_width]: aPlatformSize }, { [eOMPFilterName.platform_height]: aPlatformSize })
            aAggsMustFilters.push({ match: { [eOMPFilterName.platform_width]: aPlatformSize } }, { match: { [eOMPFilterName.platform_height]: aPlatformSize } })
        }
        if (aAngularRange != null) {
            this.updateAppliedFilters('Angular Range', `${aAngularRange['from']} -  ${aAngularRange['to']}`, eOMPFilterName.angular_range)
            aAndFilters.push({ any: [{ [eOMPFilterName.angular_range]: aAngularRange }, { [eOMPFilterName.fine_tilt_angular_range]: aAngularRange }] })
            aAggsMustFilters.push({ match: { [eOMPFilterName.angular_range]: aAngularRange } }, { match: { [eOMPFilterName.fine_tilt_angular_range]: aAngularRange } })
        }
        if (aAperture != null) {
            this.updateAppliedFilters('Aperture', `${aAperture['from']} -  ${aAperture['to']}`, eOMPFilterName.min_aperture)
            aAndFilters.push({ [eOMPFilterName.min_aperture]: aAperture }, { [eOMPFilterName.max_aperture]: aAperture })
            aAggsMustFilters.push({ match: { [eOMPFilterName.min_aperture]: aAperture } }, { match: { [eOMPFilterName.max_aperture]: aAperture } })
        }

        if (aOpticDiameter != null) {
            this.updateAppliedFilters('Optic diameter', `${aOpticDiameter['from']} -  ${aOpticDiameter['to']}`, eOMPFilterName.optic_diameter)
            aAndFilters.push({
                any: [
                    {
                        any: [{ [eOMPFilterName.optic_diameter_min]: aOpticDiameter }, { [eOMPFilterName.optic_diameter_max]: aOpticDiameter }]
                    },
                    {
                        any: [{ [eOMPFilterName.optic_size_accomodated_min]: aOpticDiameter }, { [eOMPFilterName.optic_size_accomodated_max]: aOpticDiameter }]
                    },
                    {
                        [eOMPFilterName.optic_size_max]: aThicknessOptics
                    }
                ]
            })
        }
        if (aType != null) {
            this.updateAppliedFilters('Type', aType, eOMPFilterName.type)
            aAndFilters.push({ [eOMPFilterName.type]: aType })
            aAggsMustFilters.push({ term: { [`${eOMPFilterName.type}.enum`]: aType } })
        }
        if (aThickness != null) {
            this.updateAppliedFilters('Thickness', `${aThickness['from']} -  ${aThickness['to']}`, eOMPFilterName.thickness)
            aOrFilters.push([{ [eOMPFilterName.thickness]: aThickness },
            { all: [{ [eOMPFilterName.minimum_optic_thickness]: aThickness }, { [eOMPFilterName.maximum_optic_thickness]: aThickness }] }])
        }

        if (aIris != null) {
            this.updateAppliedFilters('Iris Diaphragm', `${aIris['from']} -  ${aIris['to']}`, eOMPFilterName.iris_diaphragm)
            aAndFilters.push({ [eOMPFilterName.iris_diaphragm_min]: aIris }, { [eOMPFilterName.iris_diaphragm_max]: aIris })
            aAggsMustFilters.push({ match: { [eOMPFilterName.iris_diaphragm_min]: aIris } }, { match: { [eOMPFilterName.iris_diaphragm_max]: aIris } })
        }

        let aAggsQuery = {
            index: '',
            body: {
                query: {
                    bool: {
                        must: aAggsMustFilters.concat(aAggsShouldFilters),
                        // should: { match: { 'parameters.subType': 'Wedge Prism' } }

                    },
                },
            },
        }

        aOrFilters.forEach(filter => aAndFilters.push({ any: filter }))
        let aNewResNew: iElasticQuery = {
            query: aFreeString,
            filters: { all: aAndFilters },
            page: {
                current: this.mCurrentPage + 1,
                size: aLimit
            },
            sort: { name: 'asc' },
            originalSearchQuery: aAggsQuery,
            aggregation: aAggsFilter
        }

        if (this.mSortObj != null) {
            let aSortField = this.mSortObj.field
            aNewResNew.sort = {
                [aSortField]: this.mSortObj.order == 1 ? 'asc' : 'desc'
            }
        }
        return aNewResNew
    }
    //__________________________________________________________________________________________
    /** 
    * @desription iterating through choosing items and send event of creating optics part
    **/
    protected async _onSelectItem(pItem: Array<iOptomechanicVO>) {
        Spinner.instance.show();
        let aCountNotSupported = 0
        let aPreviusPartZPos = 0

        for (let i = 0; i < pItem.length; i++) {


            try {
                let aNumberID = pItem[i].number_id;
                let aPartMongoDB = await PartsDataLoader.instance.getSingleFullData({
                    number_id: aNumberID
                }, false);

                let aNewPart = await PartsDataLoader.instance.getSingleFullData({
                    number_id: aNumberID
                });
                Op3dContext.DATA_MANAGER.addToPartsData(aPartMongoDB);

                let aPart = new Part({ id: aPartMongoDB.name, number_id: aNumberID }).add(aNewPart);

                let aPreviusPartPosition = Op3dContext.PARTS_MANAGER.chooseOptomechanics({
                    openMenuPoint: this.mData?.openMenuPoint,
                    part: aPart,
                    index: i,
                    previousPartPosition: aPreviusPartZPos
                });
                aPreviusPartZPos += Math.abs(aPreviusPartPosition.z)

            } catch (e) {
                aCountNotSupported++
            }
        }


        Op3dContext.PARTS_MANAGER.updatePartsList();

        this.close();
        Spinner.instance.hide();
        Op3dContext.SCENE_HISTORY.saveScene();


    }
    //__________________________________________________________________________________________
    protected deleteSelected() {
        let aItemsToDelete = this.mItemsToCanvasList.filter(item => item.item.permission === eDataPermission.PRIVATE);
        if (aItemsToDelete.length > 0) {
            this._showWarning({
                message: `Are you sure you want to delete ${aItemsToDelete.length} items? This action cannot be undone.`,
                yesBtn: {
                    text: "OK",
                    callback: () => this._onDeleteSelected()
                },
                noBtn: {
                    text: "Cancel",
                }
            })
        } else {
            this._showWarning({
                message: `No private items to delete`
            });
        }
    }
    //__________________________________________________________________________________________
    private async _onDeleteSelected() {
        Spinner.instance.show();
        let aPrivateItems = this.mItemsToCanvasList.filter(item => item.item.permission === eDataPermission.PRIVATE);
        let aTotal = aPrivateItems.length;
        let aRemoved = 0;
        let aErrors = 0;
        for (let i = 0; i < aPrivateItems.length; i++) {
            let aRes = await ServerContext.SERVER.deleteIngredient({
                ingredient: eIngredientType.OPTIC,
                numberID: aPrivateItems[i].item.number_id
            });

            if (aRes.success === true) {
                try {
                    await Op3dContext.PARTS_MANAGER.deleteAllOpticsByNumberID(aPrivateItems[i].item.number_id);
                } catch (e) {
                    Op3dContext.USER_VO.isEmployeeUser && console.log("failed to delete parts from scene");
                }
                aRemoved++;
            } else {
                aErrors++;
            }
        }

        Spinner.instance.hide();
        let aCallback = () => {
            this.mItemsToCanvasList = [];
            this.changeSelectedCount()
            this._getResults(true, this.pFromPart);

        }
        Popup.instance.open({
            text: `${aRemoved}/${aTotal} removed successfully`,
            yesBtn: {
                callback: aCallback
            },
            onClose: aCallback
        });

        if (aRemoved > 0) {
            Op3dContext.SCENE_HISTORY.clearHistory();
        }
    }
    //__________________________________________________________________________________________
    protected _onClose(pData?: any): void {
        super._onClose(pData);
        this._clearResults();
    }
    //__________________________________________________________________________________________
    public static get instance(): OptomechanicMenu {
        if (this.INSTANCE == null) {

            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            // aDiv.classList.add('fade');
            aDiv.classList.add('w-100-modal');

            document.getElementById('menus').appendChild(aDiv);

            this.INSTANCE = new OptomechanicMenu({
                container: aDiv,
                skinPath: MenuListAbstract.SKIN_PATH
            });
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    private async returnAutocompleteResults(freeString: string) {

        let aRes = await ServerContext.SERVER.getElasticPartsSuggestions({ query: freeString } as iElasticQuery)

        let aNewArray: Array<string> = [];
        (aRes.data as any).results.documents.forEach((item: any) => {
            aNewArray.push(item.suggestion)
        })

        return aNewArray
    }
    //__________________________________________________________________________________________
    private initAutocomplete() {
        const autoCompleteJS = new autoComplete({
            selector: "#search-input",
            data: {
                src: async (freeString: string) => {
                    try {
                        let items = await this.returnAutocompleteResults(freeString)
                        return items
                    } catch (error) {
                        return error
                    }

                },
            },
            placeHolder: "Search",
            resultItem: {
                class: "result_item",
                element: (item: HTMLElement, data: string) => {
                    (<any>item).style = "display: flex; justify-content: space-between;";
                    item.innerHTML = `
                    <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                      ${data.match}
                    </span>`
                },
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
            resultsList: {
                class: "result_list",
                noResults: true,
                maxResults: 10,
                tabSelect: true
            },
            debounce: 150,
            searchEngine: 'strict',
            submit: true
        });

        autoCompleteJS.input.addEventListener("selection", (event: CustomEvent) => {
            const feedback = event.detail;
            autoCompleteJS.input.blur();

            const selectionAll = feedback.selection.value;
            autoCompleteJS.input.value = selectionAll;

            this._getResults(true, this.pFromPart)
        });
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {
        this.initAutocomplete()

        super._onCreationComplete();

        await Op3dContext.DATA_MANAGER.getMaterials();
        this.mFiltersHandler = new OptomechanicFiltersHandler(this,
            this.mFilterTemplate,
            this.mFiltersParent);
        await this.mFiltersHandler.reload();

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected fillCompareTable(pParts: Array<any>) {
        let aTable = this._getPart('compare-table') as HTMLTableElement

        let aCallWidth: string = '90%'
        switch (pParts.length) {
            case 1:
                aCallWidth = '90%'
                break;
            case 2:
                aCallWidth = '45%'
                break;
            case 3:
                aCallWidth = '30%'
                break;
            default:
                aCallWidth = '30%'
                let aWarningMsg = this._getPart('compare-warning')
                aWarningMsg.style.display = 'flex'
                setTimeout(() => {
                    aWarningMsg.style.display = 'none'
                }, 2500)
        }
        let row = aTable.insertRow();
        row.insertCell();


        for (let i = 0; i < (pParts.length >= 3 ? 3 : pParts.length); i++) {
            let aName = row.insertCell(i + 1);
            aName.innerHTML = pParts[i].name;
            aName.style.width = aCallWidth;
        }

        this.mMenuParams.columns.forEach((key: iMenuListColumn) => {
            if (key.serverPath !== 'name') {
                let row = aTable.insertRow();
                let aName = row.insertCell();
                aName.innerHTML = key.name
                let aCounter = 0
                for (let i = 0; i < (pParts.length >= 3 ? 3 : pParts.length); i++) {

                    let aCurrentPart = pParts[i] as any
                    let aCell = row.insertCell();
                    let aValue = aCurrentPart[key.serverPath]

                    let aConvertedValue = aCurrentPart[key.serverPath]
                    if (null != key.translateFunction) {
                        aConvertedValue = key?.translateFunction(aCurrentPart[key.serverPath])
                    }

                    if (aValue != null) {
                        aCell.innerHTML = aConvertedValue
                    } else {
                        aCell.innerHTML = ''
                        aCounter++

                    }

                }
                if (aCounter === (pParts.length >= 3 ? 3 : pParts.length)) {
                    aTable.deleteRow(row.rowIndex)
                }
            }

        })

    }
    //__________________________________________________________________________________________
    protected fillOneExtraField(pData: iOptomechanicVO, pCloneItem: HTMLElement) {
        let aLink

        for (let attribute in pData) {
            let aAttribute = attribute;

            if (aAttribute === eOMPFilterName.main_name) {
                pCloneItem.firstElementChild!.innerHTML = pData[aAttribute]
                continue
            }

            if ((aAttribute === 'parameters.info.weblinks' || aAttribute === 'info.parameters.weblink') && pData[aAttribute] !== null) {
                aLink = Array.isArray(pData[aAttribute]) ? pData[aAttribute][0] : pData[aAttribute]
                let aLinkBlock = document.createElement('a')
                aLinkBlock.href = aLink
                aLinkBlock.target = "_blank"
                aLinkBlock.innerHTML = aLink
                pCloneItem.appendChild(aLinkBlock)
                continue;

            }
            let aFieldExists = this.mMenuParams.columns.find(item => item.serverPath === aAttribute)
            if (aFieldExists) {

                let aBlock = document.createElement('div')
                aBlock.className = 'info-block'
                let aConvertedValue = pData[aAttribute]
                if (null != aFieldExists.translateFunction) {
                    aConvertedValue = aFieldExists?.translateFunction(pData[aAttribute])
                }

                aBlock.innerHTML = `<div>${aFieldExists.name}</div><div>${aConvertedValue}</div>`

                let aContainer = Op3dUtils.getElementIn(pCloneItem, "items-container") as HTMLElement;
                aContainer.appendChild(aBlock)
                continue
            }

        }
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pData?: iOpticsMenuOpen) {
        this.mData = pData;
        this.mFiltersHandler.setCategory('Opto-Mechanics')


        if (pData.part != null) {
            await this.mFiltersHandler.reload(pData.part);
            if (null != pData.searchData) {
                this.mFiltersHandler.initFromJson(pData.searchData.filtersData);
                this.mSearchInput.value = pData.searchData.searchString;
                this.mSortObj = pData.searchData.sort;
            } else {
                this.mSearchInput.value = '';
                this.mSortObj = null;
            }
            this._getResults(true, true);
            return
        }


        this._getResults(true, false);
    }
    //__________________________________________________________________________________________
    protected setCounts(pAggs: any) {
        super.setCounts(pAggs)
        for (let aggs of Object.keys(pAggs)) {
            let aFilterComponent = this.mFiltersHandler.filterComponent(aggs)
            let aFilterContainer = aFilterComponent.container
            let aFilters = this.mFiltersHandler.getFilters()
            if (aggs === 'info.parameters.type') {
                this.setTypeCount(pAggs, aggs, aFilters, aFilterContainer)

            } else {
                this.setCount(pAggs, aggs, aFilterContainer)
            }
        }
    }
    //__________________________________________________________________________________________
    protected async _getResults(pClearResults: boolean, pFromPart?: boolean) {
        this.pFromPart = pFromPart
        super._getResults(pClearResults, pFromPart);
    }
    //__________________________________________________________________________________________
}
