import { useState } from "react"
import { iBlackBoxInputProps } from "./BlackBoxInputField"

export function BlackBoxInputColorField({ title, changeInput, defaultValue }: iBlackBoxInputProps) {
    const [value, setValue] = useState(defaultValue)

    const handleInput = (event) => {
        setValue(event.target.value)
        changeInput(event.target.value, title.toLowerCase().replaceAll(' ', '_'))
    }

    return <div>
        <span>{ title }</span>
        <input className='w-100' type='color' value={ value } onChange={ handleInput } />
    </div>
}