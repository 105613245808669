import { unZipMany } from "../../loaders/UnZip";
import { MessagesHandler } from "../_context/MessagesHandler";
import { iPartMountsParserData } from "../data/VO/PartVO";
import { ServerContext } from "../server/ServerContext";
import { ImportCad, eCADCategory } from "../ui/forms/tools/ImportCad";
import { ProgressBar } from "../ui/tools/ProggressBar";
import { UnitHandler } from "../units/UnitsHandler";
import { Parser, eManipulationType, iUploadDetails } from "./Parser";
import { ParserContext } from "./ParserContext";
import { iParserRows } from "./ParserForm";


export class MountsParser extends Parser<iPartMountsParserData> {
    mStepFiles: Array<File> = [];
    mArchive: any;

    //__________________________________________________________________________________________
    constructor(pFileList: FileList, pSecondaryFileList: FileList, pToReplace: boolean) {
        super();

        this.init(pFileList, pSecondaryFileList, pToReplace)
    }
    //__________________________________________________________________________________________
    private async init(pFileList: FileList, pSecondaryFileList: FileList, pToReplace: boolean) {

        this.load({
            fileList: pFileList,
            dataLoader: null,
            replaceExist: pToReplace,
            build: {
                checkValidationFunc: (_pData, _pRow) => {

                    return null
                },
                errorIdentificationFunc: (pRow) => {
                    let aID = pRow['Catalog number'];
                    let aBrand = pRow['Brand'];
                    let aMainName = pRow['Main Name'];

                    let aRet = 'Number ID: ' + aID;
                    aRet += 'Brand Catalog Number: ' + aBrand;
                    aRet += 'Main Name: ' + aMainName;

                    return aRet;
                },
                form: {
                    'id number': {
                        path: 'number_id',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'type': {
                        path: 'type',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'sub-type': {
                        path: 'sub_type',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'typebrand': {
                        path: 'typebrand',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'brand': {
                        path: 'brand',
                        isRequired: true,
                        manipulationType: eManipulationType.TRIM,
                    },
                    'catalog number': {
                        path: 'catalog_number',
                        isRequired: true,
                        manipulationType: eManipulationType.TRIM,
                    },
                    'main name': {
                        path: 'main_name',
                        manipulationType: eManipulationType.CUSTOM,
                        isRequired: true,
                        manipulationFunction: async (pData) => ParserContext.addSpecialCharachters(pData)
                    },
                    'metric imperial': {
                        path: 'metric_imperial',
                        manipulationType: eManipulationType.TRIM,
                    },
                    'material': {
                        path: 'material',
                        manipulationType: eManipulationType.TRIM
                    },
                    'finish coated': {
                        path: 'finish_coated',
                        manipulationType: eManipulationType.TRIM,
                    },
                    'optical shape': {
                        path: 'optical_shape',
                        manipulationType: eManipulationType.TRIM,
                    },
                    'construction': {
                        path: 'construction',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    'maximum depth (mm)': {
                        path: 'maximum_depth',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    'size of compatible optics min (mm)': {
                        path: 'size_of_compatible_optics_min',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    'size of compatible optics max (mm)': {
                        path: 'size_of_compatible_optics_max',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "tall rectangular optics (mm)": {
                        path: 'tall_rectangular_optics',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optics diameter size min (mm)": {
                        path: 'optics_diameter_size_min',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optics diameter size max (mm)": {
                        path: 'optics_diameter_size_max',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optic thickness (min mm)": {
                        path: 'optic_thickness_min',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optic thickness (max mm)": {
                        path: 'optic_thickness_max',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "thickness of compatible optics min (mm)": {
                        path: 'thickness_of_compatible_optics_min',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "thickness of compatible optics max (mm)": {
                        path: 'thickness_of_compatible_optics_max',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "clear aparture diameter": {
                        path: 'clear_aparture_diameter',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optical height (mm)": {
                        path: 'optical_height',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "platform width (mm)": {
                        path: 'platform_width',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "platform height (mm)": {
                        path: 'platform_height',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "compatible lens tubes (sm)": {
                        path: 'compatible_lens_tubes',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    "type of movement": {
                        path: 'type_of_movement',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "type of drive (actuator)": {
                        path: 'type_of_drive',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "key feature": {
                        path: 'key_feature',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    "lock nuts": {
                        path: 'lock_nuts',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "actuator orientation": {
                        path: 'actuator_orientation',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "platform thread": {
                        path: 'platform_thread',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "thread type": {
                        path: 'thread_type',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "adjustment range /tilt (deg)": {
                        path: 'adjustment_range_tilt',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "adjustment range /rotation (deg)": {
                        path: 'adjustment_range_rotation',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "resolution /tilt (deg)": {
                        path: 'resolution_tilt',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "resolution /rotation (deg)": {
                        path: 'resolution_rotation',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "adjuster thread (mm)": {
                        path: 'adjuster_thread',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "adjuster screw thread (tpi)": {
                        path: 'adjuster_screw_thread',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "fine-adjustment range /rotation": {
                        path: 'fine_adjustment_range_rotation',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "fine tilt angular range (deg)": {
                        path: 'fine_tilt_angular_range',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "angular range piezo (mrad)": {
                        path: 'angular_range_piezo',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "angular range (deg)": {
                        path: 'angular_range',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "adjustment per revolution(mrad)": {
                        path: 'adjustment_per_revolution',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "adjustment per revolution piezo (mrad)": {
                        path: 'adjustment_per_revolution_piezo',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "pointing stability (μrad)": {
                        path: 'pointing_stability',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "screw diameter and pitch": {
                        path: 'screw_diameter_and_pitch',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "adjustment screw pitch (mm)": {
                        path: 'adjustment_screw_pitch',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "tapped holes": {
                        path: 'tapped_holes',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    "post mounting (screw)": {
                        path: 'post_mounting_screw',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    "though hole dia": {
                        path: 'though_hole_dia',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "mounting thread": {
                        path: 'mounting_thread',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    "mounting holes": {
                        path: 'mounting_holes',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.splitValue(pData)
                    },
                    "sensitivity (arc sec)": {
                        path: 'sensitivity_arc',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "vacuum compatibility (torr)": {
                        path: 'vacuum_compatibility_torr',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.convertBigNumber(pData)
                    },
                    "weight (kg)": {
                        path: 'weight',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "pitch/yaw adjust (resolution) (mrad/rev)": {
                        path: 'pitch_yaw_adjust',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "x/y adjust (resolution) (µm/rev)": {
                        path: 'x_y_adjust',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "z adjust (resolution) (µm/rev)": {
                        path: 'z_adjust',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "included retaining ring": {
                        path: 'included_retaining_ring',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "weblink": {
                        path: 'weblink',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "step file (.step)": {
                        path: 'step_file',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "weblink status": {
                        path: 'weblink_status',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "optic diameter min (mm)": {
                        path: 'optic_diameter_min',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optic diameter max (mm)": {
                        path: 'optic_diameter_max',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optic size accomodated min (mm)": {
                        path: 'optic_size_accomodated_min',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optic size accomodated max (mm)": {
                        path: 'optic_size_accomodated_max',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "optic size max (mm)": {
                        path: 'optic_size_max',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "minimum optic thickness (mm)": {
                        path: 'minimum_optic_thickness',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "maximum optic thickness (mm)": {
                        path: 'maximum_optic_thickness',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "thickness (mm)": {
                        path: 'thickness',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "a (mm)": {
                        path: 'a',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "b (mm)": {
                        path: 'b',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "number of optics": {
                        path: 'number_of_optics',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "compatible lens tubes (od mm)": {
                        path: 'compatible_lens_tubes_od',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "od": {
                        path: 'od',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "inner diameter (mm)": {
                        path: 'inner_diameter',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "outer diameter (mm)": {
                        path: 'outer_diameter',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "clear aperture (mm)": {
                        path: 'clear_aperture',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.getMillimeterValue(pData)
                    },
                    "min aperture (mm)": {
                        path: 'min_aperture',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "max aperture (mm)": {
                        path: 'max_aperture',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "iris diaphragm min (mm)": {
                        path: 'iris_diaphragm_min',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "iris diaphragm max (mm)": {
                        path: 'iris_diaphragm_max',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "number of leaves": {
                        path: 'number_of_leaves',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "number of blades": {
                        path: 'number_of_blades',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "travel (x,mm)": {
                        path: 'travel_x',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "travel (y,mm)": {
                        path: 'travel_y',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => ParserContext.deleteSpecialCharachters(pData)
                    },
                    "travel (z,mm)": {
                        path: 'travel_z',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "number of axes": {
                        path: 'number_of_axes',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "drive type": {
                        path: 'drive_type',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "drive mechanism (µm per revolution)": {
                        path: 'drive_mechanism_per_revolution',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "thread": {
                        path: 'thread',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "sensitivity (μm)": {
                        path: 'sensitivity_um',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "vernier (mm)": {
                        path: 'vernier',
                        manipulationType: eManipulationType.PARSE_FLOAT,
                    },
                    "vacuum compatibility (tor)": {
                        path: 'vacuum_compatibility_tor',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: async (pData) => this.convertBigNumber(pData)
                    },
                    "free air delivery": {
                        path: 'free_air_delivery',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "air pressure (min/max)": {
                        path: 'air_pressure',
                        manipulationType: eManipulationType.TRIM,
                    },
                    "air tank capacity (l)": {
                        path: 'air_tank_capacity',
                        manipulationType: eManipulationType.TRIM,
                    },
                }
            }
        });

        this.mArchive = await unZipMany(pSecondaryFileList[0] as any)

    }
    //__________________________________________________________________________________________
    private convertBigNumber(pValue: string) {
        if (pValue.includes('^') === true) {
            let aParsedToArray = pValue.split('^')
            return Math.pow(parseFloat(aParsedToArray[0]), parseFloat(aParsedToArray[1]))
        } else {
            return parseFloat(pValue)
        }
    }
    //__________________________________________________________________________________________
    private splitValue(pValue: string) {
        return pValue.split(';')
    }
    //__________________________________________________________________________________________
    private async useFilesInArchive(zipData) {
        try {
            const fileNames = Object.keys(zipData.files);

            for (const fileName of fileNames) {
                const file = zipData.file(fileName);
                if (file) {
                    const content = await file.async("uint8array");

                    const blob = new Blob([content.buffer], { type: file.comment || "" });

                    const extractedFile = new File([blob], fileName, {
                        type: blob.type,
                    });
                    this.mStepFiles.push(extractedFile)
                }
            }
        } catch (error) {
            console.error("Error accessing files in the archive:", error);
        }

    }
    //__________________________________________________________________________________________
    protected _getNumber(pValue: string, pManipulationType: eManipulationType) {
        let aFactor = 1;

        if (pValue.indexOf('in') > -1) {
            pValue = pValue.substring(0, (pValue.length - 2));
            aFactor = UnitHandler.IN_TO_MM;
        }

        let aValue: number;
        switch (pManipulationType) {
            case eManipulationType.PARSE_INT:
                aValue = parseInt(pValue);
                break;
            case eManipulationType.PARSE_FLOAT:
                aValue = parseFloat(pValue);
                break;
            default:
                throw new Error("Not a number.");
        }

        if (true == isNaN(aValue)) {
            return null;
        }

        return (aValue * aFactor);
    }

    //__________________________________________________________________________________________
    private async checkZipFilesReady() {
        await this.useFilesInArchive(this.mArchive)
    }
    //__________________________________________________________________________________________
    protected async _parseData(pParserRows: Array<iParserRows>) {
        try {


            if (false == this._isValidParsedFile(pParserRows)) {
                return;
            }
            let aRes = 0

            let aTotalRows = this._getTotalRows(pParserRows);
            let aUploadDetails: iUploadDetails = {
                success: 0,
                error: 0,
                update: 0,
                total: aTotalRows
            };

            ProgressBar.instance.open({
                title: 'Uploading...',
                min: 0,
                max: (aTotalRows),
                closeOnFinish: true
            });
            await this.checkZipFilesReady()
            let aObj = []
            for (let i = 0; i < pParserRows.length; i++) {
                let aBasicDetails = '<strong>File name: </strong>';
                aBasicDetails += pParserRows[i].fileName + '<br>';
                aBasicDetails += '<strong>Section name: </strong>';
                aBasicDetails += pParserRows[i].sectionName + '<br>';

                ProgressBar.instance.updateDetails(aBasicDetails);

                let aRows = pParserRows[i].rows;
                let aStartRow = pParserRows[i].start;

                for (let row = 0; row < aRows.length; row++) {
                    let aCurrInfo = '<strong> Current section: </strong>';
                    aCurrInfo += row + '/' + aRows.length + '<br>';
                    aCurrInfo += '<strong>Total: </strong>'
                    aCurrInfo += (aUploadDetails.success + aUploadDetails.error);
                    aCurrInfo += '/ ' + aUploadDetails.total + '<br>';
                    ProgressBar.instance.updateDetails(aBasicDetails + aCurrInfo);

                    let aRow = aRows[row];
                    let aParsedData = await this.parseRow(aRow);



                    let aRowNum = (aStartRow + row + 2);
                    let aCurrentStepFile = this.mStepFiles.find(item => item.name.substring(0, item.name.lastIndexOf('.')) === aParsedData.object.step_file)
                    if (!aCurrentStepFile) {
                        this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false, aParsedData.object.catalog_number, `Step file ${aParsedData.object.step_file} does not exists in .step archive`);
                        continue
                    }

                    if (null == aParsedData) {
                        this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false);
                        continue;
                    }

                    if (null == aParsedData.object) {
                        this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false,
                            aParsedData.error.objectID, aParsedData.error.msg);
                        continue;
                    }

                    let aName = aParsedData.object.catalog_number;

                    //check and fill new fileds min/max
                    let aSizeOfCompatibleOpticsMin = aParsedData.object.size_of_compatible_optics_min
                    let aSizeOfCompatibleOpticsMax = aParsedData.object.size_of_compatible_optics_max
                    let aOpticsDiameterSizeMin = aParsedData.object.optics_diameter_size_min
                    let aOpticsDiameterSizeMax = aParsedData.object.optics_diameter_size_max
                    let aOpticsDiametereMin = aParsedData.object.optic_diameter_min
                    let aOpticsDiameterMax = aParsedData.object.optic_diameter_max
                    let aOpticSizeAccomodatedMin = aParsedData.object.optic_size_accomodated_min
                    let aOpticSizeAccomodatedMax = aParsedData.object.optic_size_accomodated_max
                    let aThicknessOfCompOpticsMin = aParsedData.object.thickness_of_compatible_optics_min
                    let aThicknessOfCompOpticsMax = aParsedData.object.thickness_of_compatible_optics_max


                    if (aSizeOfCompatibleOpticsMax != null && aSizeOfCompatibleOpticsMin == null) {
                        aParsedData.object.size_of_compatible_optics_min = aSizeOfCompatibleOpticsMax
                    }
                    if (aOpticsDiameterSizeMax != null && aOpticsDiameterSizeMin == null) {
                        aParsedData.object.optics_diameter_size_min = aOpticsDiameterSizeMax
                    }
                    if (aOpticsDiameterMax != null && aOpticsDiametereMin == null) {
                        aParsedData.object.optic_diameter_min = aOpticsDiameterMax
                    }
                    if (aOpticSizeAccomodatedMax != null && aOpticSizeAccomodatedMin == null) {
                        aParsedData.object.optic_size_accomodated_min = aOpticSizeAccomodatedMax
                    }
                    if (aThicknessOfCompOpticsMax != null && aThicknessOfCompOpticsMin == null) {
                        aParsedData.object.thickness_of_compatible_optics_min = aThicknessOfCompOpticsMax
                    }

                    let aIsExist = await ServerContext.SERVER.isPartExists({ tableID: aParsedData.object.number_id })

                    if (true === aIsExist.data) {
                        if (true === this.mParserParams.replaceExist) {
                            await ServerContext.SERVER.updatePartParameters({ partData: aParsedData.object })
                            this._onUpload(aUploadDetails, aBasicDetails, aRowNum, true, aName,
                                'Part succesfully updated in MongoDB', true);
                            continue;
                        } else {
                            this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false, aName,
                                'Item already exist');
                            continue;

                        }
                    }
                    aObj.push(aParsedData)

                    aRes++

                    try {
                        await ImportCad.instance.convertStepToOptixAdmin(aCurrentStepFile, aParsedData, eCADCategory.MOUNTS)

                    } catch (e) {
                        this._onUpload(aUploadDetails, aBasicDetails, aRowNum, false, aName,
                            'Failed to create part');
                    }

                    let aIsAdded = (null != aRes);
                    this._onUpload(aUploadDetails, aBasicDetails, aRowNum, aIsAdded,
                        aName, 'Server Error');
                }

            }

            this._printLog(aUploadDetails);

        } catch (error) {
            ProgressBar.instance.close();
            MessagesHandler.ON_ERROR_PROGRAM(error as any);
        }
    }

    //__________________________________________________________________________________________ 
    protected getMillimeterValue(pString: string) {
        if (pString.includes('in')) {
            return parseFloat(pString) * 25.4;
        }
        return parseFloat(pString)
    }
    //__________________________________________________________________________________________ 

}
