import { Vector3, OrthographicCamera, WebGLRenderer, Scene } from "three";
import { EventBase } from "../../oc/events/EventBase";
import { EventManager } from "../../oc/events/EventManager";
import { EventsContext } from "../_context/EventsContext";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { iHash, iCubeClickParams } from "../_context/_interfaces/Interfaces";
import { SceneContext } from "./SceneContext";
import { Op3dOrbitController } from "./Op3dOrbitController";
import { SceneLights } from "./SceneLights";
import { SceneCube } from "./SceneCube";
import { Axis } from "../parts/_parts_assets/Axis";
import { ColorUtils } from "../ui/ColorUtils";
import { PositionGenerator } from "./PositionGenerator";
import { CSS2DRenderer } from 'three/addons/renderers/CSS2DRenderer.js';
import { eViewTypeXYZ } from "./absSceneCube";
import { CameraUtils } from "./_camera/CameraUtils";

export enum eMouseMode {
    GENERAL,
    PAN,
    ROTATE
}

export class Op3dScene {

    private static MAX_MEMORY_LIMIT = 1500 * 1048576; // 1500mb
    public static SHOW_ALL_LABELS: boolean = false;
    public static SHOW_POWER_METER: boolean = false;
    private static RESET_CAMERA_TARGET = new Vector3(0, 0, 0);
    private static RESET_CAMERA_POSITION = new Vector3(-596, 701, -1041);
    public static START_CAMERA_VAL: number = 1500;//673.5753140545634;
    private mOrbitController: Op3dOrbitController;
    private mSceneLights: SceneLights;
    private mCameraToLocation: Vector3;
    private mCube: SceneCube;
    private mOnRenderHash: iHash<Function> = {};
    private mMemoryCheck = () => this.checkMemory();
    private mHasAlarmed: boolean = false;
    private mRendererFrame = (pTimeStamp: DOMHighResTimeStamp) => setTimeout(() => this._update(pTimeStamp), 1000 / 40);
    private mAxis: Axis;
    private mStopAt: DOMHighResTimeStamp;

    constructor(pContainer: HTMLElement, pIsCreateCube: boolean) {
        Op3dContext.CONTAINER = pContainer;

        this._initScene();


        this.mSceneLights = new SceneLights();

        this.mAxis = new Axis();

        this._initCamera();
        this._initRenderers();

        this.mOrbitController = new Op3dOrbitController(this);
        //this.resetCamera();


        if (pIsCreateCube) {
            this.mCube = new SceneCube({
                rendererElement: Op3dContext.CONTAINER,
                camera: SceneContext.CAMERA
            });
        }

        let aNow = window.performance.now();
        this.mStopAt = (aNow + 150);
        this._update(aNow);
        // this._setFPS();

        this._addEventListeners();
        setInterval(this.mMemoryCheck, 500);



        this.activateRenderer();

        try {
            if ((window as any).userGuidingUserStorage != null && (window as any).userGuidingUserStorage.attributes.isBasic == true) {
                if ((window as any).userGuiding.getHistory().find(guide => guide == 85517) == null) {
                    (window as any).userGuiding.previewGuide(85517);
                } else {
                    (window as any).userGuiding.previewGuide(this.shuffleNotificationWindows([85698, 85697, 85696, 85705]))
                }
            }
        } catch (e) {
            console.error('UserGuiding preview failed:', e);
        }


    }
    //__________________________________________________________________________________________
    private shuffleNotificationWindows(pGuidesArray: Array<number>) {
        return pGuidesArray[Math.floor(Math.random() * pGuidesArray.length)];
    }
    //__________________________________________________________________________________________
    public setPanMode(pIsPan: boolean) {
        if (true == pIsPan) {
            this.mOrbitController.setMouseMode(eMouseMode.PAN);
        } else {
            this.mOrbitController.setMouseMode(eMouseMode.GENERAL);
        }
    }
    //__________________________________________________________________________________________
    // private _setFPS() {
    //     let aInput = document.getElementById('fps_input') as HTMLInputElement;
    //     if (server.ServerContext.env == server.ENVS.DEV) {
    //         ViewUtils.setElementVisibilityByDNone(aInput.parentElement, true);

    //         setInterval(() => {
    //             aInput.value = this.mFPS.toString() + ' FPS';
    //             this.mFPS = 0;
    //         }, 1000);
    //     } else {
    //         ViewUtils.removeFromParent(aInput);
    //     }
    // }
    //__________________________________________________________________________________________
    public activateRenderer() {
        this.mStopAt = (performance.now() + 150);
    }
    //__________________________________________________________________________________________
    public setMouseMode(pMouseMode: eMouseMode) {
        this.mOrbitController.setMouseMode(pMouseMode);
    }
    //__________________________________________________________________________________________
    public get op3dOrbitController() {
        return this.mOrbitController;
    }
    //__________________________________________________________________________________________
    public get lights() {
        return this.mSceneLights.getLights();
    }
    //__________________________________________________________________________________________
    public get cube() {
        return this.mCube;
    }
    //__________________________________________________________________________________________
    public orthoViewAxisChanged(pNormal: Vector3, pCallback: Function) {
        this.mCube.orthoViewAxisChanged(pNormal, pCallback);
    }
    //__________________________________________________________________________________________
    public set cameraToLocation(pVec: Vector3) {
        this.mCameraToLocation = pVec;

    }

    //__________________________________________________________________________________________
    private checkMemory() {
        let aReached = false;
        if (!this.mHasAlarmed && window.performance != null &&
            (window.performance as any).memory != null &&
            (window.performance as any).memory.usedJSHeapSize > Op3dScene.MAX_MEMORY_LIMIT) {
            aReached = true;
        }

        //TODO
        // let aIsSimulationActive = engine.Op3dContext.SimulationRunner != null &&
        //     engine.Op3dContext.SimulationRunner.isSimulationActive;
        // if (!aIsSimulationActive && !aReached && this.mHasAlarmed && window.performance != null &&
        //     (window.performance as any).memory != null && (window.performance as any).memory.usedJSHeapSize < MAX_MEMORY_LIMIT) {
        //     this.mHasAlarmed = false;
        // }

        if (!this.mHasAlarmed && aReached) {
            this.mHasAlarmed = true;
            //TODO
            // if (aIsSimulationActive) {
            //     engine.Op3dContext.SimulationRunner.pause = true;
            //     data.context.OpContext.divManager.showSpinnerMemory();
            // } else {
            MessagesHandler.MEMORY_LIMIT_REACHED();
            //}
        }
    }
    //__________________________________________________________________________________________
    public removeRenderCallback(pKey: string) {
        delete this.mOnRenderHash[pKey];
    }
    //__________________________________________________________________________________________
    public addRenderCallback(pKey: string, pCallback: Function) {
        this.mOnRenderHash[pKey] = pCallback;
    }
    //__________________________________________________________________________________________
    public rotateCamera() {

        if (this.mCameraToLocation != null) {

            let aCameraToPosition = this.mCameraToLocation.clone();


            this.mOrbitController.update();
            this.mCameraToLocation = null;


            SceneContext.CAMERA.position.copy(aCameraToPosition);

            const event = new CustomEvent('on_camera_move');
            window.dispatchEvent(event);

        }

    }
    //__________________________________________________________________________________________
    private _update(pTimeStamp: DOMHighResTimeStamp): void {
        requestAnimationFrame(this.mRendererFrame);

        if (pTimeStamp < this.mStopAt) {
            this._doUpdate()
        }

    }
    //__________________________________________________________________________________________

    private _doUpdate() {

        for (let key in this.mOnRenderHash) {
            this.mOnRenderHash[key]();
        }

        this.rotateCamera()

        if (SceneContext.CURRENT_VIEW.type !== eViewTypeXYZ.FOUR_WINDOW_VIEW) {
            this._renderSceneUpdate(SceneContext.RENDERER, SceneContext.CAMERA)
            return
        } else {
            this._renderSceneUpdate(SceneContext.RENDERER, SceneContext.CAMERA)
            this._renderSceneUpdate(SceneContext.RENDERER2, SceneContext.CAMERA2)
            this._renderSceneUpdate(SceneContext.RENDERER3, SceneContext.CAMERA3)
            this._renderSceneUpdate(SceneContext.RENDERER4, SceneContext.CAMERA4)
        }

    }
    //__________________________________________________________________________________________
    private _renderSceneUpdate(pRenderer: WebGLRenderer, pCamera: OrthographicCamera) {

        pRenderer.render(SceneContext.MAIN_SCENE, pCamera);
        SceneContext.SPRITE_RENDERER.render(SceneContext.MAIN_SCENE, SceneContext.CAMERA);
    }
    //__________________________________________________________________________________________
    private _addEventListeners() {
        EventManager.addEventListener(EventsContext.RESIZE_ALL_SCREEN,
            () => this._onWindowResize(), this);

        EventManager.addEventListener(EventsContext.WINDOW_RESIZE,
            () => this._onWindowResize(), this);

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this._onNew(), this);

        EventManager.addEventListener(EventsContext.ROTATION_FROM_CUBE,
            (pData: EventBase<iCubeClickParams>) => this._rotationFromCube(pData.data), this);

    }
    //__________________________________________________________________________________________

    public getCameraPosition(pData: iCubeClickParams, pCamera: OrthographicCamera) {
        let center = Op3dContext.PARTS_MANAGER.getCenter();
        let aVectorToLookAt = this.vectorToLookAtFunc(center, pCamera);
        let aZoom = aVectorToLookAt.length();
        let aPosition: Vector3 = pData.normal;

        aPosition = aPosition.multiplyScalar(aZoom);
        aVectorToLookAt.normalize();
        aVectorToLookAt.multiplyScalar(0.01);
        aPosition.add(aVectorToLookAt);
        // aPosition.add(this.mOrbitController.lookAt);
        aPosition.add(center);

        return aPosition;
    }
    //__________________________________________________________________________________________
    public vectorToLookAtFunc(pObjCenter: Vector3, pCamera: OrthographicCamera): Vector3 {
        let aCamPos = pCamera.position.clone();
        let aVectorToLookAt = aCamPos.sub(pObjCenter);
        return aVectorToLookAt;
    }
    //__________________________________________________________________________________________

    private _rotationFromCube(pData: iCubeClickParams) {

        let center = Op3dContext.PARTS_MANAGER.getCenter()

        this.mOrbitController.setLookAt(center)

        let aData = pData;
        if (aData.cube != this.mCube) {
            return;
        }
        let aVectorToLookAt = this.mOrbitController.vectorToLookAt;
        let aZoom = aVectorToLookAt.length();

        let aPosition = aData.normal;


        aPosition = aPosition.multiplyScalar(aZoom);
        aVectorToLookAt.normalize();
        aVectorToLookAt.multiplyScalar(0.01);
        aPosition.add(aVectorToLookAt);
        aPosition.add(this.mOrbitController.lookAt);
        if (Math.abs(aPosition.y) < 1) {
            aPosition.y = 20;
        }
        this.mCameraToLocation = aPosition;

    }
    //__________________________________________________________________________________________
    private _renderResize(pRenderer: WebGLRenderer, pCamera: OrthographicCamera, width: number, height: number) {
        let aFrustumScale = 3;


        if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) {
            aFrustumScale = 1.5
        }
        pCamera.left = -width / aFrustumScale
        pCamera.right = width / aFrustumScale
        pCamera.top = height / aFrustumScale
        pCamera.bottom = -height / aFrustumScale
        pCamera.updateProjectionMatrix();
        pRenderer.setSize(width, height);

        if (!(pRenderer instanceof CSS2DRenderer)) {
            this.setBasicWatermark(pRenderer);
        }

    }
    //__________________________________________________________________________________________
    private _onWindowResize() {
        let width = Op3dContext.CONTAINER.clientWidth
        let height = Op3dContext.CONTAINER.clientHeight

        if (SceneContext.CURRENT_VIEW.type !== eViewTypeXYZ.FOUR_WINDOW_VIEW) {
            this._renderResize(SceneContext.RENDERER, SceneContext.CAMERA, width, height)

            this._renderResize(SceneContext.SPRITE_RENDERER as any, SceneContext.CAMERA, width, height)
        } else {
            this._renderResize(SceneContext.SPRITE_RENDERER as any, SceneContext.CAMERA, width / 2, height / 2)
            this._renderResize(SceneContext.RENDERER, SceneContext.CAMERA, width / 2, height / 2)
            this._renderResize(SceneContext.RENDERER2, SceneContext.CAMERA2, width / 2, height / 2)
            this._renderResize(SceneContext.RENDERER3, SceneContext.CAMERA3, width / 2, height / 2)
            this._renderResize(SceneContext.RENDERER4, SceneContext.CAMERA4, width / 2, height / 2)

        }
    }
    //__________________________________________________________________________________________
    public updateRendererSize() {
        this._onWindowResize()
    }
    //__________________________________________________________________________________________
    private _initCamera() {
        let aFrustumScale = 3;

        let aWidth = Op3dContext.CONTAINER.clientWidth / aFrustumScale; //new
        let aHeight = Op3dContext.CONTAINER.clientHeight / aFrustumScale; //new

        // let aWidth = Op3dContext.CONTAINER.clientWidth;
        // let aHeight = Op3dContext.CONTAINER.clientHeight;
        // let aAspectRatio: number = aWidth / aHeight;

        // const frustumSize = 500;
        SceneContext.CAMERA = new OrthographicCamera
            (-aWidth, aWidth, aHeight, -aHeight, 0.500, 10000);

        SceneContext.CAMERA2 = new OrthographicCamera
            (-aWidth, aWidth, aHeight, -aHeight, 0.1, 10000);

        SceneContext.CAMERA2.position.set(0, 0, 1000);
        SceneContext.CAMERA2.lookAt(new Vector3(0, 0, 0))
        SceneContext.CAMERA2.name = 'XY-View'

        SceneContext.CAMERA3 = new OrthographicCamera
            (-aWidth, aWidth, aHeight, -aHeight, 0.1, 10000);
        SceneContext.CAMERA3.position.set(1000, 0, 0)
        SceneContext.CAMERA3.lookAt(new Vector3(0, 0, 0))
        SceneContext.CAMERA3.name = 'YZ-View'

        SceneContext.CAMERA4 = new OrthographicCamera
            (-aWidth, aWidth, aHeight, -aHeight, 0.1, 10000);

        SceneContext.CAMERA4.position.set(0, 1000, 0)
        SceneContext.CAMERA4.lookAt(new Vector3(0, 0, 0))
        SceneContext.CAMERA4.name = 'XZ-View'

        this.setCameraDefault();
        SceneContext.MAIN_SCENE.add(SceneContext.CAMERA);
    }
    //__________________________________________________________________________________________
    public setCameraDefault(pVec: Vector3 = new Vector3()) {
        SceneContext.CAMERA.position.set(-1500, Op3dScene.START_CAMERA_VAL, -1500);

        if (SceneContext.OP3D_SCENE) {
            SceneContext.OP3D_SCENE.lookAt = pVec;
        }
    }
    //__________________________________________________________________________________________
    private _onNew() {
        Op3dScene.SHOW_ALL_LABELS = false;
        this.resetCamera();
    }
    //__________________________________________________________________________________________
    public resetCamera() {
        this.setCamera(Op3dScene.RESET_CAMERA_POSITION, Op3dScene.RESET_CAMERA_TARGET);
    }
    //__________________________________________________________________________________________
    public setCamera(pNewCameraPosition: Vector3, pNewCameraLookAt: Vector3) {
        SceneContext.CAMERA.position.copy(pNewCameraPosition);

        this.mOrbitController.setLookAt(pNewCameraLookAt.clone());
        SceneContext.CAMERA.lookAt(pNewCameraLookAt.clone());
        SceneContext.CAMERA.updateMatrix();
        SceneContext.CAMERA.updateMatrixWorld(true);
    }

    //__________________________________________________________________________________________
    public setBasicWatermark(pRenderer: WebGLRenderer) {
        if (Op3dContext.USER_VO.isBasicLicense != true) {
            pRenderer.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
            return;
        }

        let aHEXBgColor = ColorUtils.numToHEXColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor)
        let aColor = +("0x" + aHEXBgColor.slice(1).replace(
            aHEXBgColor.length < 5 && /./g, '$&$&'));

        let r = aColor >> 16;
        let g = aColor >> 8 & 255;
        let b = aColor & 255;

        let hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        let aTextColor: string;
        if (hsp > 127.5) {
            aTextColor = 'rgba(0, 0, 0, 0.15)'
        } else {
            aTextColor = 'rgba(255, 255, 255, 0.15)'
        }

        pRenderer.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 0);
        let aCanvas = document.createElement('canvas')
        aCanvas.width = pRenderer.domElement.width
        aCanvas.height = pRenderer.domElement.height
        let ctx = aCanvas.getContext('2d')!;
        let aText = 'BASIC';

        ctx.beginPath();
        ctx.fillStyle = ColorUtils.numToHEXColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor)
        ctx.fillRect(0, 0, aCanvas.width, aCanvas.height);

        ctx.font = "bold 300px Open Sans";

        let aCalculatedDistanceHorizont = (aCanvas.width - ctx.measureText(aText).width) / 2
        let aCalculatedDistanceVertic = (aCanvas.height) / 2 + 100
        ctx.fillStyle = aTextColor
        ctx.fillText(aText, aCalculatedDistanceHorizont, aCalculatedDistanceVertic)


        pRenderer.domElement.style.backgroundImage = 'url(' + aCanvas.toDataURL() + ')'

        return 'url(' + aCanvas.toDataURL() + ')';
    }
    //__________________________________________________________________________________________
    private _rendererOptions(pRenderer: WebGLRenderer, pWidth: number, pHeigth: number,
        pDOMElement: Element) {
        pRenderer.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
        pRenderer.setSize(pWidth, pHeigth);

        this.setBasicWatermark(pRenderer)

        if (Op3dContext.USER_VO.isBasicLicense != true) {
            this.setBasicWatermark(pRenderer)
            pRenderer.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
        }

        pDOMElement.prepend(pRenderer.domElement);
    }
    //__________________________________________________________________________________________
    private _onContextLost() {
        // window.localStorage.setItem(Op3dScene.LOCAL_STORAGE_ITEM, "true");
        Op3dContext.USER_VO.isEmployeeUser && console.log("_onContextLost");
    }
    //__________________________________________________________________________________________
    private _initRenderersOptions() {
        let width = Op3dContext.CONTAINER.clientWidth;
        let height = Op3dContext.CONTAINER.clientHeight;

        this._rendererOptions(SceneContext.RENDERER, width, height, SceneContext.ISO_VIEW);
        this._rendererOptions(SceneContext.RENDERER2, width / 2, height / 2, SceneContext.XY_VIEW);
        this._rendererOptions(SceneContext.RENDERER3, width / 2, height / 2, SceneContext.YZ_VIEW);
        this._rendererOptions(SceneContext.RENDERER4, width / 2, height / 2, SceneContext.ZX_VIEW);
    }
    //__________________________________________________________________________________________
    private _onContextRestored() {
        Op3dContext.USER_VO.isEmployeeUser && console.log("_onContextRestored");
        this._initRenderersOptions();
    }
    //__________________________________________________________________________________________
    protected _initCSS2Renderer() {
        SceneContext.SPRITE_RENDERER = new CSS2DRenderer() as any;
        SceneContext.SPRITE_RENDERER.setSize(Op3dContext.CONTAINER.clientWidth, Op3dContext.CONTAINER.clientHeight);
        SceneContext.SPRITE_RENDERER.domElement.style.position = 'absolute';
        SceneContext.SPRITE_RENDERER.domElement.style.top = '0px';
        SceneContext.ISO_VIEW.parentElement.insertBefore(SceneContext.SPRITE_RENDERER.domElement, SceneContext.ISO_VIEW);
    }
    //__________________________________________________________________________________________
    protected _initRenderers() {
        let aParam = {
            antialias: true,
            // logarithmicDepthBuffer: true,
            preserveDrawingBuffer: true,
            alpha: true
        };
        //Init 1 main View rendering
        SceneContext.RENDERER = new WebGLRenderer(aParam);
        //Init 1 renderer for label css2dobjects rendering
        this._initCSS2Renderer();

        SceneContext.RENDERER.domElement.addEventListener('webglcontextlost',
            () => this._onContextLost(), false);
        SceneContext.RENDERER.domElement.addEventListener('webglcontextrestored',
            () => this._onContextRestored(), false);

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onUpdatePermissions(), this);

        //Init 3 more views
        SceneContext.RENDERER2 = new WebGLRenderer(aParam);
        SceneContext.RENDERER3 = new WebGLRenderer(aParam);
        SceneContext.RENDERER4 = new WebGLRenderer(aParam);


        SceneContext.ISO_VIEW = document.getElementsByClassName('view-1')[0];
        SceneContext.XY_VIEW = document.getElementsByClassName('view-2')[0];
        SceneContext.YZ_VIEW = document.getElementsByClassName('view-3')[0];
        SceneContext.ZX_VIEW = document.getElementsByClassName('view-4')[0];
        this.addEventListenersToCanvases()

        this._initRenderersOptions();
    }
    //__________________________________________________________________________________________
    private addEventListenersToCanvases() {
        SceneContext.ISO_VIEW.addEventListener('dblclick', () => {
            if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) CameraUtils.switchSceneView(eViewTypeXYZ.ISO_VIEW)
        })
        SceneContext.XY_VIEW.addEventListener('dblclick', () => {
            if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) CameraUtils.switchSceneView(eViewTypeXYZ.XY_VIEW)
        })
        SceneContext.YZ_VIEW.addEventListener('dblclick', () => {
            if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) CameraUtils.switchSceneView(eViewTypeXYZ.YZ_VIEW)
        })
        SceneContext.ZX_VIEW.addEventListener('dblclick', () => {
            if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) CameraUtils.switchSceneView(eViewTypeXYZ.XZ_VIEW)
        })
        SceneContext.XY_VIEW.addEventListener('wheel', (e) => {
            this._zoomInOut(SceneContext.CAMERA2, e)
        })
        SceneContext.YZ_VIEW.addEventListener('wheel', (e) => {
            this._zoomInOut(SceneContext.CAMERA3, e)
        })
        SceneContext.ZX_VIEW.addEventListener('wheel', (e) => {
            this._zoomInOut(SceneContext.CAMERA4, e)
        })
    }
    //__________________________________________________________________________________________
    private _zoomInOut(pCamera: OrthographicCamera, pMouseEvent: Event) {
        if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) {
            let aZoom = pCamera.zoom
            if ((pMouseEvent as any).deltaY < 0) aZoom += 0.05
            if ((pMouseEvent as any).deltaY > 0) aZoom -= 0.05
            if (aZoom < 0) return
            pCamera.zoom = aZoom
            pCamera.updateProjectionMatrix()
        }
    }
    //__________________________________________________________________________________________
    private _onUpdatePermissions() {
        if (Op3dContext.USER_VO.isBasicLicense == true) {
            SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER)
            SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER2)
            SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER3)
            SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER4)
        } else {
            SceneContext.RENDERER.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
            SceneContext.RENDERER2.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
            SceneContext.RENDERER3.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
            SceneContext.RENDERER4.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
        }
    }
    //__________________________________________________________________________________________
    private _initScene() {

        SceneContext.MAIN_SCENE = new Scene();
        SceneContext.POSITION_GENERATOR = new PositionGenerator();

    }
    //__________________________________________________________________________________________

    public updateLights() {
        this.mSceneLights.updateLights();
    }
    //__________________________________________________________________________________________
    public updateZoomParams() {
        this.mOrbitController.updateZoomParams();
    }
    //__________________________________________________________________________________________
    public enableController(pEnable: boolean) {
        this.mOrbitController.enable(pEnable);
    }
    //__________________________________________________________________________________________
    public set lookAt(pLookAt: Vector3) {
        this.mOrbitController.setLookAt(pLookAt);
    }
    //__________________________________________________________________________________________
    public get lookAt() {
        return this.mOrbitController.lookAt;
    }
    //__________________________________________________________________________________________

    public get target() {
        return this.mOrbitController.orbitControls.target
    }
    //__________________________________________________________________________________________
    public getAngle(pAngle: string) {
        if (pAngle === 'VERTICAL') {
            return this.mOrbitController.orbitControls.getPolarAngle()
        } else if (pAngle === 'HORIZONTAL') {
            return this.mOrbitController.orbitControls.getAzimuthalAngle()
        }


    }
    //__________________________________________________________________________________________
    // public async loadAxis() {
    //     await this.mAxis.loadAxis();
    // }
    //__________________________________________________________________________________________
    public getAxisModel(pIsPart: boolean = false) {
        return this.mAxis.getAxisModel(pIsPart);
    }
    //__________________________________________________________________________________________
    // public async getAxisPart() {
    //     return await this.mAxis.getAxisPart()
    // }
    //__________________________________________________________________________________________
}
