
import { Op3dContext } from "../_context/Op3dContext";
import { Part } from "../parts/Part";
import { absDivController } from "../ui/absDivController";
import { PartInfo } from "../ui/part_info/PartInfo";
import { HeaderWidget } from "./HeaderWidget";


export class DivControllerWidget extends absDivController {

    constructor() {
        super();
    }
    //__________________________________________________________________________________________
    protected _init() {
        this.mHeader = new HeaderWidget();
    }
    //__________________________________________________________________________________________
    public updatePartInfo() {
        Op3dContext.PART_INFO = new PartInfo();
        Op3dContext.PART_INFO.update();
    }
    //__________________________________________________________________________________________
    protected _onPartSelected(pPart: Part) {
        if ((Op3dContext.PARTS_MANAGER.selectedPart != pPart) ||
            (true == pPart.partOptions.static)) {
            return;
        }

        Op3dContext.PART_INFO.open();
    }
    //__________________________________________________________________________________________
}
