import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";

export class WarningPopup extends Op3dComponentBase {

    private mText: string;

    private static _getContainer() {
        let aFormsContainer = document.getElementById('forms');
        if (aFormsContainer === null) {
            throw new Error("forms container not found");
        }

        let aId = "warning-popup";
        let aDiv = document.getElementById(aId);
        if (aDiv == null) {
            aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('new-modal');
            aDiv.style.width = "auto";
            aDiv.id = aId;
            aDiv.classList.add('overflow-hidden');
            aDiv.setAttribute("data-backdrop", "false");
            aFormsContainer.appendChild(aDiv);
        }

        return aDiv;
    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();
        ViewUtils.removeFromParent(this.mContainer);
    }
    //__________________________________________________________________________________________
    constructor(pText: string) {
        super({
            container: WarningPopup._getContainer(),
            skinPath: './skins/forms/warning_popup.html'
        });

        this.mText = pText;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        let aContent = this._getPart("popup-content", true);
        aContent.innerHTML = this.mText;
    }
    //__________________________________________________________________________________________
    protected _onShown(): void {
        let aInnerWidth = window.innerWidth;
        let aInnerHeight = window.innerHeight;

        let aSize = this.mContainer.getBoundingClientRect();

        this.mContainer.style.left = (aInnerWidth / 2) - (aSize.width / 2) + 'px';
        this.mContainer.style.top = (aInnerHeight / 2) - (aSize.height / 2) + 'px';

        setTimeout(() => this.close(), 1000);
    }
    //__________________________________________________________________________________________
}
