import { eAxisType, eUnitType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { OpticsContext } from "../../_context/OpticsContext";
import { Strings } from "../../_context/Strings";
import { iDownloadFullSetupData } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { PartsDataLoader } from "../../data/data_loader/PartsDataLoader";
import { SetupsDataLoader } from "../../data/data_loader/SetupsDataLoader";
import { eGroupType, ePartType } from "../../parts/PartInterfaces";
import { iJSONPartOptionsOLD, iOptixPartJSON } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { eCountType } from "../../parts/behaviors/LightSourceContext";
import { ServerContext } from "../../server/ServerContext";
import { SettingsContext } from "../../settings/SettingsContext";
import { iGratingData, eSmRaysDensity, SimulationContext } from "../../simulation/SimulationContext";
import { AnalysisConstants } from "../../ui/analysis/AnalysisConstants";
import { eSimulationKind } from "../../ui/analysis/AnalysisContext";
import { PartsImporter } from "./PartsImporter";

/**
* This class is being initialized on demand, when a opt file needs to be loaded from local computer.
* this class responsible of loading the opt file, unzip it and send to initialization to 'SetupManager' function
* the version doesnt include the values that is set, is less then
* */
export class OptImporter {

    /**
     * whether to do scaling to part matrix and props or not
     */
    private static UNITS_VERSION: string = "6.8.4";
    private static WEIGHT_VERSION: string = "7.0.8";
    private static CAD_VERSION: string = "7.0.4";

    /**
     * changes in light source definition (density)
     */
    public static LIGHT_SOURCE_VERSION = "7.0.2";

    /**
     * fix tht transmission grating problem
     */
    public static GRATING_VERSION = "7.0.3";

    public static CS_FIXED_VERSION = "7.0.9";
    public static SIMULATION_SETTINGS = "7.0.5";
    public static RAYS_COUNT_TYPE = "7.0.10";
    public static RAYS_NAN_COUNT = "7.0.8";
    /**
     * in some of the versions we made a change where we have a constant name for user defined light source,
     * without backwards compatibility the system file thinks that it is an optix part
     */
    public static OLD_USER_DEFINED_LS_NAMING = "7.0.11";
    public static NEW_ANALYSIS_HYUGENS_NAMES = "7.0.12";
    public static BACKEND_OPT = "7.0.17";
    public static GROUP_STRUCTURE = "7.0.16";
    public static WL_LIMIT_CHECK = "7.0.15";

    //__________________________________________________________________________________________
    private async _getConvertedContent(pData: iDownloadFullSetupData) {
        const aNewContent = await this._convertOldOpt(pData);
        pData.content = aNewContent;
        return pData;
    }
    //__________________________________________________________________________________________
    public async readContent(pData: iDownloadFullSetupData, pAddToCurrent: boolean = false) {

        pData = await this._getConvertedContent(pData);

        // if (OptImporter.CAD_VERSION > pData.parameters.version) {
        //     await this.readContentOLD(pData);
        //     return;
        // }

        if (!Op3dContext.isWidget) Op3dContext.SCENE_HISTORY.ignoreSaving = true;

        if (pData.parameters.details.labels === undefined) {
            pData.parameters.details.labels = ['No labels']
        }

        await new PartsImporter().importScene(pData, pAddToCurrent);
        await Op3dContext.SETUPS_MANAGER.initAfterLoad();
        Op3dContext.PARTS_MANAGER.updatePartsList();


        if (!Op3dContext.isWidget) Op3dContext.SCENE_HISTORY.ignoreSaving = false;

        //If it's an old widget, save it in the new format.
        if ((true === Op3dContext.isWidget) &&
            (true === this._getConvertion(pData.parameters.version, Op3dContext.SETUP_NUM))) {
            const aSetupData = Op3dContext.SETUPS_MANAGER.getSetupData();
            aSetupData._id = pData._id;
            aSetupData.parameters.version = Op3dContext.SETUP_NUM;
            const aRes = await SetupsDataLoader.instance.add(aSetupData);
            if (true !== aRes.success) {
                throw new Error("Error loading setup");
            }
        }
    }
    //__________________________________________________________________________________________
    private async _backwardOPT(pBackendOPT: boolean, pItem: iOptixPartJSON) {
        if (false === pBackendOPT) {
            return;
        }

        if (null === pItem.id) {
            delete pItem.id;
        }

        let aPartOptions = pItem.partOptions as iJSONPartOptionsOLD;
        if (undefined === aPartOptions) {
            aPartOptions = {
                type: ePartType.GENERAL
            }
            pItem.partOptions = aPartOptions;
        }

        let aID = pItem.id;
        if ((undefined !== aID) && ('Paraxial lens' !== aID)) {
            if (true === aPartOptions.isCS) {
                pItem.partOptions.type = ePartType.CS;
            } else if (true === aPartOptions.isGroup) {
                pItem.partOptions.type = ePartType.GROUP;
            } else {
                let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aID);
                if (undefined !== aPartVO) {
                    pItem.number_id = aPartVO.number_id;
                    pItem.partOptions.type = (true === aPartVO.isDynamicModel) ?
                        ePartType.DYNAMIC_PART : ePartType.CAD_PART;
                } else {
                    if (true === aPartOptions.isBlackBox) {
                        pItem.partOptions.type = ePartType.BLACKBOX;
                    } else {
                        pItem.partOptions.type = ePartType.CAD_PART;
                    }

                    let aRes = await ServerContext.SERVER.getPrivatePartById({ id: aID });
                    if ((undefined !== aRes) && (null !== aRes) &&
                        (true === aRes.success) && (undefined !== aRes.data) &&
                        (null !== aRes.data)) {
                        await PartsDataLoader.instance.saveToCache(aRes.data, false);
                        aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aID);
                        pItem.number_id = aPartVO.number_id;
                    }
                }
            }
        } else {
            let aPartsData = pItem.partsData;
            if (undefined !== aPartsData) {
                let aOpticsData = aPartsData[Strings.OPTICS_HOLDER];
                if ((undefined !== aOpticsData) &&
                    (undefined !== aOpticsData.number_id)) {
                    pItem.number_id = aOpticsData.number_id;
                    pItem.partOptions.type = ePartType.CATALOG_OPTICS;
                } else {
                    let aParaxialLensData = aPartsData[Strings.PARAXIAL_LENS_NAME];
                    if ((undefined !== aParaxialLensData) &&
                        (undefined !== aParaxialLensData.paraxialLensData)) {
                        pItem.paraxialLensData = aParaxialLensData.paraxialLensData;
                        pItem.partOptions.type = ePartType.PARAXIAL_LENS;
                    }
                }
            }
        }


    }
    //__________________________________________________________________________________________
    private _backwardLightSource(aWavelengthsLimitCheck: boolean,
        aConvertWeight: boolean,
        aConvertLightSource: boolean,
        aRaysCountType: boolean,
        aNanRaysCountAllowes: boolean,
        aConvertUnit: boolean,
        aBackendOPT: boolean,
        aCurrItem: iOptixPartJSON) {
        if (undefined !== aCurrItem.behaviors.laserBehavior) {
            /**
             * Handle laser
             */

            if (true === aBackendOPT) {
                aCurrItem.behaviors.laserBehavior.laserData =
                    aCurrItem.behaviors.laserBehavior.laserData[0];
            }

            let aOptions = aCurrItem.partOptions as iJSONPartOptionsOLD;

            let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aCurrItem.id);
            let aIsDynamicPart = ((aPartVO?.isDynamicModel) || ((null != aOptions) &&
                ((true == aOptions.isDynamicModel) ||
                    (ePartType.DYNAMIC_PART === aOptions.type))));


            if (true == aIsDynamicPart) {
                if (null == aCurrItem.partOptions) {
                    aCurrItem.partOptions = {};
                }

                aCurrItem.partOptions.type = ePartType.DYNAMIC_PART;

                if ((undefined !== aCurrItem.partIDs) &&
                    (undefined !== aCurrItem.partIDs.subParts) &&
                    (undefined !== aCurrItem.partIDs.subParts[0]) &&
                    (undefined !== aCurrItem.partIDs.subParts[0].axes) &&
                    (aCurrItem.partIDs.subParts[0].axes.length < 2)) {

                    while (aCurrItem.partIDs.subParts[0].axes.length < 2) {
                        let aMatrix = aCurrItem.partIDs.subParts[0].matrixWorld;

                        aCurrItem.partIDs.subParts[0].axes.push({
                            internal_id: Op3dUtils.idGenerator(),
                            matrixWorld: DataUtils.getObjectCopy(aMatrix),
                            type: eAxisType.GENERAL
                        });
                    }
                }
            }

            let aLaserData = aCurrItem.behaviors.laserBehavior.laserData;
            const aCurrLaserData = aLaserData.lightSource;

            if (true === aConvertWeight) {
                for (let k = 0; k < aCurrLaserData.wavelengthData.length; k++) {
                    if (aCurrLaserData.wavelengthData[k].isPrimary && aCurrLaserData.wavelengthData[k].weight == 0) {
                        aCurrLaserData.wavelengthData[k].weight = 1;
                    }
                }
            }

            if (aWavelengthsLimitCheck && aCurrLaserData.wavelengthData.length > SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT) {
                aCurrLaserData.wavelengthData = aCurrLaserData.wavelengthData.slice(0, SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT);
            }

            if (aConvertLightSource) {
                if (aCurrLaserData.sourceGeometricalData.density == eSmRaysDensity.PERIODIC ||
                    aCurrLaserData.sourceGeometricalData.density == eSmRaysDensity.REGULAR ||
                    aCurrLaserData.sourceGeometricalData.density == null) {
                    aCurrLaserData.sourceGeometricalData.density_pattern = eSmRaysDensity.CONCENTRIC_CIRCLES;
                }
            }

            if (aRaysCountType && aCurrLaserData.count_type == null) {
                aCurrLaserData.count_type = eCountType.PER_WAVELENGTH;
            }


            if (aNanRaysCountAllowes) {
                if (isNaN(aCurrLaserData.sourceGeometricalData.count)) {
                    aCurrLaserData.sourceGeometricalData.count = SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.regular;
                }
                if (isNaN(aCurrLaserData.sourceGeometricalData.count_analysis)) {
                    aCurrLaserData.sourceGeometricalData.count_analysis = SimulationContext.SIMULATION_CONSTANTS.DEFAULT_RAYS_COUNT.analysis;
                }
            }

            if (aConvertUnit) {
                switch (aCurrLaserData.kind) {
                    case "PLANE_WAVE": {
                        switch (aCurrLaserData.shape) {
                            case "CIRCULAR":
                                (aCurrLaserData.sourceGeometricalData as any).radius *= 25.4;
                                break;
                            case "RECTANGULAR":
                                (aCurrLaserData.sourceGeometricalData as any).width *= 25.4;
                                (aCurrLaserData.sourceGeometricalData as any).height *= 25.4;
                                break;
                        }
                        break;
                    }
                }
            }
        }
    }
    //__________________________________________________________________________________________
    private _backwardUnit(pConvertUnit: boolean, pCurrItem: iOptixPartJSON) {
        if (true == pConvertUnit) {
            let aMatrix = pCurrItem.worldMatrix;
            let aX = aMatrix.elements[12] * 25.4;
            let aY = aMatrix.elements[13] * 25.4;
            let aZ = aMatrix.elements[14] * 25.4;

            aMatrix.elements[12] = aX;
            aMatrix.elements[13] = aY;
            aMatrix.elements[14] = aZ;


            if (null != pCurrItem.behaviors.postBehavior) {
                pCurrItem.behaviors.postBehavior.height *= 25.4;
            }

            if (null != pCurrItem.behaviors.cageBehavior) {
                pCurrItem.behaviors.cageBehavior.length *= 25.4;
            }
        }
    }
    //__________________________________________________________________________________________
    private _backwardLSNaming(pChangedLSStructureNaming: boolean, pData: iOptixPartJSON) {
        if (pChangedLSStructureNaming === true) {
            switch (pData.id) {
                case "L1111":
                    if (pData.partIDs.subParts[0].name === "L1111") {
                        pData.partIDs.subParts[0].name = Strings.USER_DEFINED_LIGHT_SOURCE;
                    }
                    break;
            }
        }
    }
    //__________________________________________________________________________________________
    private _getConvertion(pCurrVersion: string, pVersion: string) {
        let aToConvert = false;
        try {
            let aWeightConversion = Op3dContext.compareVersions(pCurrVersion, pVersion);
            aToConvert = aWeightConversion == 1;

        } catch (e) {
            // do nothing
        }

        return aToConvert;
    }
    //__________________________________________________________________________________________
    private _backwardCad(pToConvert: boolean, pItem: iOptixPartJSON,
        pConvertUnit: boolean) {

        let aOptions = pItem.partOptions as iJSONPartOptionsOLD;
        if ((true == pToConvert) && (null != pItem.id)) {
            let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pItem.id);
            let aIsDynamicPart = ((aPartVO?.isDynamicModel) ||
                ((null != aOptions) && (true == aOptions.isDynamicModel)));

            if (false == aIsDynamicPart) {
                switch (pItem.id) {
                    case 'B2448F':
                    case 'B3036F':
                    case 'B7590A':
                        pItem.id = pItem.id;
                        let aDecreaseBreadboardHeight = (true == pConvertUnit) ? 4 : 100;
                        pItem.worldMatrix[13] -= aDecreaseBreadboardHeight;
                        break;
                    case 'L1011':
                    case 'L1012':
                    case 'L1014':
                    case 'L1020':
                        pItem.id = pItem.id
                        break;
                    default:
                        pItem.id += '_Assembly'
                        break
                }
                // if (aCurrItem.behaviors.laserBehavior == null) {
                //     aCurrItem.id += '_Assembly';
                // }

            }
        }
    }
    //__________________________________________________________________________________________
    private _backwardAnalysisOptions(pData: iOptixPartJSON) {
        let aAnalysisOptions = pData.analysisOptions;
        if (aAnalysisOptions != null) {
            for (let face of aAnalysisOptions) {
                let aAllAnalysis = [...face.advanced, ...face.fast];
                for (let analysis of aAllAnalysis) {

                    let aIsDiffraction = analysis.simulation_kind === eSimulationKind.DIFFRACTION;

                    let aItem = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.name === analysis.name &&
                        item.type === analysis.type &&
                        item.isDiffration === aIsDiffraction);

                    if (aItem === undefined) {
                        aItem = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
                            item.oldNames !== undefined &&
                            item.oldNames.indexOf(analysis.name) !== -1 &&
                            item.type === analysis.type &&
                            item.isDiffration === aIsDiffraction);

                        if (aItem !== undefined) {
                            analysis.name = aItem.name;
                        }
                    }
                }
            }
        }
    }

    //__________________________________________________________________________________________
    private _backwardGroup(pKey: boolean, pData: iOptixPartJSON) {
        if (pKey === true) {
            if (pData.groupData === undefined) {
                if (ePartType.GROUP === pData.partOptions.type) {
                    pData.groupData = {
                        group_type: eGroupType.REGULAR,
                        is_array: false
                    }
                } else {
                    pData.groupData = {
                        group_type: undefined,
                        is_array: false
                    }
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public static backwardSupportReflectiveGratingData(pGratingData: iGratingData, pSubtype: string) {
        if (OpticsContext.isReflectiveGrating(pSubtype)) {
            pGratingData.min_transmission_order = 0;
            pGratingData.max_transmission_order = -1;
        }
        return pGratingData;
    }
    //__________________________________________________________________________________________
    private async _convertOldOpt(pSetupData: iDownloadFullSetupData) {

        const aVersion = pSetupData.parameters.version;

        let aConvertUnit = ((pSetupData.parameters.unit == eUnitType.INCHES) &&
            this._getConvertion(aVersion, OptImporter.UNITS_VERSION));
        let aConvertWeight = this._getConvertion(aVersion, OptImporter.WEIGHT_VERSION);
        let aConvertLightSource = this._getConvertion(aVersion, OptImporter.LIGHT_SOURCE_VERSION);
        let aConvertCad = this._getConvertion(aVersion, OptImporter.CAD_VERSION);
        let aSimulationSettings = this._getConvertion(aVersion, OptImporter.SIMULATION_SETTINGS);
        let aRaysCountType = this._getConvertion(aVersion, OptImporter.RAYS_COUNT_TYPE);
        let aNanRaysCountAllowes = this._getConvertion(aVersion, OptImporter.RAYS_NAN_COUNT);
        let aFixedCadRotation = this._getConvertion(aVersion, OptImporter.CS_FIXED_VERSION);
        let aChangedLSStructureNaming = this._getConvertion(aVersion, OptImporter.OLD_USER_DEFINED_LS_NAMING);
        let aRenameAnalysis = this._getConvertion(aVersion, OptImporter.NEW_ANALYSIS_HYUGENS_NAMES);
        let aGroupStructureOutdated = this._getConvertion(aVersion, OptImporter.GROUP_STRUCTURE);
        let aWavelengthsLimitCheck = this._getConvertion(aVersion, OptImporter.WL_LIMIT_CHECK);

        let aBackendOPT = this._getConvertion(aVersion, OptImporter.BACKEND_OPT);

        if (aSimulationSettings == true || pSetupData.parameters.settings == null) {
            pSetupData.parameters.settings = SettingsContext.getUserSimulationSettings();
        }

        pSetupData.parameters.settings =
            DataUtils.fillObjectAccordingTo(pSetupData.parameters.settings,
                SettingsContext.getUserSimulationSettings());

        if (aConvertWeight == false &&
            aConvertUnit == false &&
            aConvertCad == false &&
            aNanRaysCountAllowes == false &&
            aRaysCountType == false &&
            aConvertLightSource == false &&
            aFixedCadRotation == false &&
            aRenameAnalysis == false &&
            aGroupStructureOutdated == false &&
            aChangedLSStructureNaming == false &&
            aBackendOPT === false) {
            return pSetupData.content;
        }

        const aParts = JSON.parse(pSetupData.content) as Array<iOptixPartJSON>;
        for (let i = 0; i < aParts.length; i++) {
            const aCurrItem = aParts[i];
            if (true == aFixedCadRotation) {
                delete aCurrItem.rotationRef;
            }

            this._backwardGroup(aGroupStructureOutdated, aCurrItem);
            this._backwardAnalysisOptions(aCurrItem);
            this._backwardCad(aConvertCad, aCurrItem, aConvertUnit);
            this._backwardLSNaming(aChangedLSStructureNaming, aCurrItem)
            this._backwardUnit(aConvertUnit, aCurrItem);
            this._backwardLightSource(aWavelengthsLimitCheck, aConvertWeight, aConvertLightSource,
                aRaysCountType, aNanRaysCountAllowes, aConvertUnit, aBackendOPT, aCurrItem);
            await this._backwardOPT(aBackendOPT, aCurrItem);
        }

        let aConverted = JSON.stringify(aParts);
        return aConverted;
    }
    //__________________________________________________________________________________________
}