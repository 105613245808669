import { WebGLRenderer } from "three";
import { EventsContext } from "../_context/EventsContext";
import { iCubeParams } from "../_context/_interfaces/Interfaces";
import { absSceneCube } from "../scene/absSceneCube";
import { EventBase } from "../../oc/events/EventBase";
import { EventManager } from "../../oc/events/EventManager";

export class SceneCubeWidget extends absSceneCube {
    constructor(pCubeParams: iCubeParams) {
        super(pCubeParams);
    }

    //__________________________________________________________________________________________
    protected _addEventListeners() {
        window.addEventListener('resize', () => this._onWindowResize(), false);

        this.mRenderer.domElement.addEventListener("mousedown",
            (e: MouseEvent) => this.onClick(e));

        EventManager.addEventListener(EventsContext.SHOW_CUBE,
            (pData: EventBase<boolean>) => this._showCube(pData.data), this);
    }

    //__________________________________________________________________________________________
    protected _initRenderer() {
        let aParam = { antialias: true, alpha: true };
        this.mRenderer = new WebGLRenderer(aParam);
        this.mRenderer.domElement.id = "cube-dom-element";
        this.mRenderer.setSize(absSceneCube.SIZE, absSceneCube.SIZE);
        document.getElementById('canvas_container').appendChild(this.mRenderer.domElement);
        this._onWindowResize();
    }
    //__________________________________________________________________________________________
}
