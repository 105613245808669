import { AnalyticsEventsContext } from "../_context/AnalyticsEventsContext";
import { eErrorType } from "../_context/Enums";
import { MessagesHandler } from "../_context/MessagesHandler";
import { iUserPersonalInfo } from "../_context/_interfaces/SettingInterfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { AuthUtils } from "../server/AuthUtils";
import { RegisteredServer } from "../server/RegisteredServer";
import { ViewUtils } from "../ui/ViewUtils";
import { Spinner } from "../ui/home/Spinner";

export interface iServerUserVO {
    name: { first: string, last: string }; //= {}; // first, last
    email: string;
    personal: iUserPersonalInfo;
}

export class Registration {

    public static COUNTRIES_LIST = ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
        , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia & Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
        , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
        , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
        , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
        , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
        , "Indonesia", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
        , "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
        , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
        , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
        , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre & Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
        , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain", "Sri Lanka", "St Kitts & Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
        , "Suriname", "Swaziland", "Sweden", "Switzerland", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad & Tobago", "Tunisia"
        , "Turkey", "Turkmenistan", "Turks & Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
        , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];

    private mFirstName: HTMLInputElement;
    private mLastName: HTMLInputElement;
    private mEmail: HTMLInputElement;
    private mCountrySelect: HTMLSelectElement;
    private mPhone: HTMLInputElement;
    private mCompany: HTMLInputElement;
    private mTermsCheckbox: HTMLInputElement;
    private mPolicyCheckbox: HTMLInputElement;
    private mRegistrationMessage: HTMLElement;

    constructor() {
        Spinner.instance.show();
        this._init();
    }
    //__________________________________________________________________________________________
    private async _init() {

        this.mFirstName = document.getElementById("first-name-reg") as HTMLInputElement;
        this.mLastName = document.getElementById("last-name-reg") as HTMLInputElement;
        this.mEmail = document.getElementById("email-reg") as HTMLInputElement;
        this.mCountrySelect = document.getElementById("country-reg-menu") as HTMLSelectElement;
        this.mPhone = document.getElementById("phone-reg") as HTMLInputElement;
        this.mCompany = document.getElementById("company-reg") as HTMLInputElement;
        this.mTermsCheckbox = document.getElementById("terms-input") as HTMLInputElement;
        this.mPolicyCheckbox = document.getElementById("policy-input") as HTMLInputElement;
        this.mRegistrationMessage = document.getElementById("login-err-message");

        this._initCountrySelect();
        await this._initForm();

        document.getElementById("register-btn").addEventListener("click",
            () => this._onRegister());

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private async _initForm() {
        let aTokens = AuthUtils.getTokensFromCookies();
        let aUserResult = await RegisteredServer.getDataByToken({ token: aTokens.access_token });
        if (aUserResult.success) {
            const aUserData = aUserResult.data.userData;

            this.mEmail.value = aUserData.email;
            this._setReadonlyInput(this.mEmail);

            if (aUserData.name != null && aUserData.name.first != null && aUserData.name.first != "") {
                this.mFirstName.value = aUserData.name.first;
                this._setReadonlyInput(this.mFirstName);
            }

            if (aUserData.name != null && aUserData.name.last != null && aUserData.name.last != "") {
                this.mLastName.value = aUserData.name.last;
                this._setReadonlyInput(this.mLastName);
            }

            if (aUserData.personal != null && aUserData.personal.phone != null && aUserData.personal.phone != "") {
                this.mPhone.value = aUserData.personal.phone;
                this._setReadonlyInput(this.mPhone);
            }

            if (aUserData.personal != null && aUserData.personal.company != null && aUserData.personal.company != "") {
                this.mCompany.value = aUserData.personal.company;
                this._setReadonlyInput(this.mCompany);
            }

            if (aUserData.personal != null && aUserData.personal.country != null && aUserData.personal.country != "") {
                this.mCountrySelect.value = aUserData.personal.country;
                this._setReadonlyInput(this.mCountrySelect);
            }
        } else {
            MessagesHandler.ON_ERROR_PROGRAM({
                error: aUserResult.data,
                type: eErrorType.AUTH,
                redirectTo500: true,
                show_message: false
            });
        }
    }
    //__________________________________________________________________________________________
    private _setReadonlyInput(pInput: HTMLElement) {
        pInput.setAttribute("disabled", "disabled");
        pInput.classList.add("opacity-1")
    }
    //__________________________________________________________________________________________
    private _initCountrySelect() {
        let aCountriesList = Registration.COUNTRIES_LIST;
        aCountriesList.unshift("Country");

        for (let i = 0; i < aCountriesList.length; i++) {
            let aOption = document.createElement("option");
            aOption.innerText = aCountriesList[i];
            aOption.value = aCountriesList[i];
            this.mCountrySelect.appendChild(aOption);
        }
    }
    //__________________________________________________________________________________________
    private async _onRegister() {


        ViewUtils.setElementVisibilityByDNone(this.mRegistrationMessage, false);
        let aUserData = this._getUserData();

        if (aUserData != null) {
            Spinner.instance.show();
            let aRes = await RegisteredServer.register(aUserData);
            if (aRes.success) {


                AnalyticsEventsContext.triggerEventOf(AnalyticsEventsContext.REGISTER,
                    "simulation", aUserData.email);
                let aCookie = Op3dUtils.getCookie(AuthUtils.TRANSFER_AFTER_REGISTRATION);
                if (aCookie != "") {
                    Op3dUtils.deleteCookie(AuthUtils.TRANSFER_AFTER_REGISTRATION);
                    window.location.replace(aCookie);

                } else {
                    window.location.replace("index.html");
                }

            } else {
                Spinner.instance.hide();
                ViewUtils.setElementVisibilityByDNone(this.mRegistrationMessage, true);
                if ((aRes.data as any).responseJSON.statusCode === 403) {
                    this.mRegistrationMessage.innerText = MessagesHandler.EMAIL_ALREADY_EXISTS;
                    return;
                }
                this.mRegistrationMessage.innerText = MessagesHandler.REGISTRATION_FAILED;
            }
        }
    }
    //__________________________________________________________________________________________
    private _getUserData() {

        let aUserData: iServerUserVO = null;
        let aError = null;

        if (!this.mTermsCheckbox.checked ||
            !this.mPolicyCheckbox.checked) {
            aError = MessagesHandler.TERMS_ERROR_MESSSAGE;
        }

        let aFirstName = this.mFirstName.value.trim();
        let aLastName = this.mLastName.value.trim();
        let aEmail = this.mEmail.value.trim();

        if (aFirstName == "" ||
            aLastName == "" ||
            aEmail == ""
        ) {
            aError = MessagesHandler.ONE_OF_THE_FIELDS_IS_EMPTY
        }

        let aCompany = this.mCompany.value.trim();
        if (aCompany == "") {
            aError = MessagesHandler.COMPANY_NOT_VALID;
        }

        let aCompanyRegex = new RegExp(/^[a-zA-Z0-9!@#$%^&* ()_+\-=\[\]{};':"\\|,.<>\/?]*$/);
        if (!aCompanyRegex.test(aCompany)) {
            aError = MessagesHandler.COMPANY_NOT_VALID_LETTERS;
        }

        let aCountry = this.mCountrySelect.options[this.mCountrySelect.selectedIndex].value.trim()
        if (aCountry.toLowerCase() == "country") {
            aError = MessagesHandler.COUNTRY_NOT_VALID;
        }

        let aPhone = this.mPhone.value.trim();
        let aPhoneRegex = new RegExp(/^[0-9-+]+$/);
        if (aPhone != "" && !aPhoneRegex.test(aPhone)) {
            aError = MessagesHandler.PHONE_NOT_VALID;
        }

        if (aError != null) {
            ViewUtils.setElementVisibilityByDNone(this.mRegistrationMessage, true);
            this.mRegistrationMessage.innerText = aError;

        } else {
            aUserData = {
                email: aEmail,
                name: {
                    first: aFirstName,
                    last: aLastName
                },
                personal: {
                    company: aCompany,
                    country: aCountry,
                    phone: aPhone
                }
            }
        }

        return aUserData;
    }
    //__________________________________________________________________________________________
}
