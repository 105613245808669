
import { Op3dContext } from "./op3d/_context/Op3dContext";
import { Main } from "./op3d/_main/Main";
import { Op3dUtils } from "./op3d/_utils/Op3dUtils";
import { AuthUtils } from "./op3d/server/AuthUtils";
import { RegisteredServer } from "./op3d/server/RegisteredServer";

import { ENVS, ServerContext } from "./op3d/server/ServerContext";


/**
 * @description link to server of simulation API's
 */
var backend_url: string;
/**
 * @description this link load to the home page
 */
var base_link: string;
var redirect_uri: string;
var image_prefix: string;
var user_prefix_aperture_images: string;
var part_prefix: string;
var user_prefix_setups: string;
var user_prefix_cad: string;
var optic_images: string;
var parts_data: string;
var cognito_domain: string;
var cookie_domain: string;
var cognito_client_id: string;
var warehouse_base_link: string;
var website_base_link: string;
var pricing_base_link: string;
var billing_url: string;
var info_account_id: string;
// var cognito_user_pool_name: string;

function _setEnv(env: ENVS) {
    let aBaseUrl: string;
    switch (env) {
        case ENVS.DEV: {
            //https://d1ik9cm03215cv.cloudfront.net/users/setups/

            aBaseUrl = "https://dlf8924rngn1f.cloudfront.net";
            backend_url = "https://backend-dev.3doptix.com";
            base_link = 'https://dev.3doptix.com';
            website_base_link = "https://3doptix.com";
            user_prefix_setups = 'https://assets-3doptix-staging.s3.amazonaws.com/users/setups/dev/';
            user_prefix_cad = 'https://assets-3doptix-staging.s3.amazonaws.com/users/geometries/dev/';
            user_prefix_aperture_images = 'https://assets-3doptix-staging.s3.amazonaws.com/users/aperture_images/dev/';

            warehouse_base_link = "https://app.3doptix.com";
            cognito_client_id = '227ka56unod2vd5ne0psn32piu';
            cognito_domain = `https://3doptis-dev-new-user-pool.auth.us-east-1.amazoncognito.com`;
            cookie_domain = '3doptix.com';
            billing_url = "https://3doptix.com/account/";
            info_account_id = '5e00dbfb80b08a2e141155c5';
            // backend_url = 'http://localhost:3000';
            break;
        }
        case ENVS.QA: {
            //'https://assets-3doptix-staging.s3.amazonaws.com
            //https://dlf8924rngn1f.cloudfront.net

            aBaseUrl = "https://dlf8924rngn1f.cloudfront.net";
            backend_url = "https://backend-staging.3doptix.com";
            //backend_url = "http://localhost:3000";
            base_link = 'https://staging.3doptix.com';
            website_base_link = "https://3doptix.com";
            user_prefix_setups = 'https://assets-3doptix-staging.s3.amazonaws.com/users/setups/staging/';
            user_prefix_cad = 'https://assets-3doptix-staging.s3.amazonaws.com/users/geometries/staging/';
            user_prefix_aperture_images = 'https://assets-3doptix-staging.s3.amazonaws.com/users/aperture_images/staging/';

            warehouse_base_link = "https://app.3doptix.com";
            cognito_client_id = '5jl61rae5a10prv21t9fbtf58c';
            cognito_domain = 'https://staging-login.3doptix.com';
            cookie_domain = '3doptix.com';
            billing_url = "https://3doptix.com/account/";
            info_account_id = '5e00dbfb80b08a2e141155c5';
            break;
        }
        case ENVS.RELEASE: {
            aBaseUrl = "https://d2gvilz5kio5j3.cloudfront.net";
            backend_url = "https://backend.4doptix.com";
            base_link = "https://simulation.4doptix.com";
            website_base_link = "https://www.4doptix.com";
            user_prefix_setups = 'https://assets-3doptix-release.s3.amazonaws.com/users/setups/';
            user_prefix_cad = 'https://assets-3doptix-release.s3.amazonaws.com/users/geometries/';
            user_prefix_aperture_images = 'https://assets-3doptix-release.s3.amazonaws.com/users/aperture_images/';

            warehouse_base_link = "https://app.4doptix.com";
            cognito_domain = 'https://login.4doptix.com';
            cognito_client_id = '17ou2561a2ptvcgbnlv61h5aom';
            cookie_domain = '4doptix.com';
            billing_url = "https://www.4doptix.com/account/orders/";
            info_account_id = '63904c55c530610d0508a493';
            //backend_url = 'http://localhost:3000';
            break;
        }
        case ENVS.PROD: {
            aBaseUrl = "https://d1mjmqtmltwn6h.cloudfront.net";
            backend_url = "https://backend.3doptix.com";
            base_link = "https://simulation.3doptix.com";
            website_base_link = "https://3doptix.com";
            user_prefix_setups = 'https://assets-3doptix-prod.s3.amazonaws.com/users/setups/';
            user_prefix_cad = 'https://assets-3doptix-prod.s3.amazonaws.com/users/geometries/';
            user_prefix_aperture_images = 'https://assets-3doptix-prod.s3.amazonaws.com/users/aperture_images/';

            warehouse_base_link = "https://app.3doptix.com";
            cognito_domain = 'https://prod-login.3doptix.com';
            cognito_client_id = '7s7iustcd6l4m00pcm4p5utpp6';
            cookie_domain = '3doptix.com';
            billing_url = "https://3doptix.com/account/";
            info_account_id = '63904c55c530610d0508a493';
            // backend_url = 'http://localhost:3000';

            break;
        }
        case ENVS.CHINA: {
            aBaseUrl = "https://d1mjmqtmltwn6h.cloudfront.net";
            backend_url = "https://backend-prod.3doptix.com";
            base_link = "https://simulation.3doptix.com";
            website_base_link = "https://www.3doptix.com";
            user_prefix_setups = 'https://assets-3doptix-prod.s3.amazonaws.com/users/setups/';
            user_prefix_cad = 'https://assets-3doptix-prod.s3.amazonaws.com/users/geometries/';
            user_prefix_aperture_images = 'https://assets-3doptix-prod.s3.amazonaws.com/users/aperture_images/';

            warehouse_base_link = "https://app.3doptix.com";
            cognito_domain = 'https://prod-login.3doptix.com';
            cognito_client_id = '7s7iustcd6l4m00pcm4p5utpp6';
            cookie_domain = '3doptix.com';
            billing_url = "https://www.3doptix.com/account/";
            info_account_id = '63904c55c530610d0508a493';
            //backend_url = 'http://localhost:3000';

            break;
        }
    }

    parts_data = `${aBaseUrl}/parts_data/`;
    image_prefix = `${aBaseUrl}/part_images/`;
    part_prefix = `${aBaseUrl}/part_models/`;
    optic_images = `${aBaseUrl}/optic_images/`;
    redirect_uri = `${base_link}/on_login.html?link=simulation`;
    pricing_base_link = `${website_base_link}/pricing/`;
}
//__________________________________________________________________________________________________
async function _checkEnvData(pUsername: string, pPassword: string) {
    let aRes = await RegisteredServer.checkEnv({
        username: pUsername,
        password: pPassword
    });

    if (aRes.success === true && aRes.data === true) {
        Op3dUtils.setCookie(AuthUtils.COOKIE_ENV_PASS, new Date().getTime().toString(), 7, 0);
        openEnv();
    } else {
        alert("not authorized");
    }
}
//__________________________________________________________________________________________________
function _checkEnv() {

    let aPathName = window.location.href;
    if (aPathName.toLowerCase().indexOf("widget.html") > -1 ||
        aPathName.toLowerCase().indexOf("on_login.html") > -1) {
        return true;
    }

    if (__SERVE_CMD__) {
        return true;
    }


    switch (ServerContext.env) {
        case ENVS.DEV:
        case ENVS.QA:

            let aCookie = Op3dUtils.getCookie(AuthUtils.COOKIE_ENV_PASS);
            if (aCookie != "") {
                return true;
            }

            let aEnvContainer = document.getElementById("env-form-container");
            if (aEnvContainer !== null) {
                aEnvContainer.classList.remove("d-none");
                let aUserNameInput = document.getElementById("env-username") as HTMLInputElement;
                let aPasswordInput = document.getElementById("env-password") as HTMLInputElement;
                let aEnter = document.getElementById("env-enter");
                aEnter?.addEventListener("click",
                    () => _checkEnvData(aUserNameInput.value.trim(),
                        aPasswordInput.value.trim()));
            }

            return false;
        default:
            return true;
    }
}
//__________________________________________________________________________________________________
function openEnv() {
    let aForm = document.getElementById("env-form");
    if (aForm != null && aForm.parentElement != null) {
        aForm.parentElement.removeChild(aForm);
    }

    Op3dContext.MANAGER = new Main()
}

declare const __SERVER_CONTEXT__: ENVS;
declare const __SERVE_CMD__: boolean;

window.onload = function () {
    try {
        const curr_env = __SERVER_CONTEXT__;
        _setEnv(curr_env);
        if (__SERVE_CMD__) {
            base_link = 'http://localhost:5555';
            redirect_uri = 'http://localhost:5555/on_login.html?link=simulation';
        }

        Op3dContext.SETUP_NUM = "7.0.18";
        Op3dContext.VERSION_NUM = "2.1.4";
        ServerContext.backend_url = backend_url;
        ServerContext.image_prefix = image_prefix;
        ServerContext.user_prefix_cad = user_prefix_cad;
        ServerContext.user_prefix_aperture_images = user_prefix_aperture_images;
        ServerContext.user_prefix_setups = user_prefix_setups;
        ServerContext.part_prefix = part_prefix;
        ServerContext.parts_data_prefix = parts_data;
        ServerContext.env = curr_env;
        ServerContext.base_link = base_link;
        ServerContext.cognito_domain = cognito_domain;
        ServerContext.cookie_domain = cookie_domain;
        ServerContext.info_account_id = info_account_id;
        ServerContext.redirect_uri = redirect_uri;
        ServerContext.optic_images = optic_images;
        ServerContext.cognito_client_id = cognito_client_id;
        ServerContext.warehouse_base_link = warehouse_base_link;
        ServerContext.website_base_link = website_base_link;
        ServerContext.pricing_base_link = pricing_base_link;
        ServerContext.billing_url = billing_url;
        let aIsEnvOpen = _checkEnv();
        if (aIsEnvOpen) {
            openEnv();
        }

    } catch (error) {
    }
};
//__________________________________________________________________________________________________
