﻿import { Chart, ChartData, ChartDataset } from "chart.js/auto";

import { eResultType, eChosenFileState, ePolarizationType, eErrorType } from "../../../_context/Enums";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iHash, iAsyncCallback } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ServerContext } from "../../../server/ServerContext";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Spinner } from "../../home/Spinner";
import { NotificationCenter } from "../../home/_notifications/NotificationCenter";
import { Popup } from "../Popup";
import { iFilterFinderResult, eFilterFinderFilterType, iFilterQueryVO } from "./FilterFinderContext";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);
export interface iResultData {
    distance: number,
    name: string,
    pass_err: number,
    stop_err: number,
    x: Array<number>,
    y: Array<number>
}
export class FilterFinderForm extends Op3dComponentBase {

    public static COLORS_ARRAY: Array<string> = [
        '#23A7DE',
        '#1DA79D',
        '#8CC442',
        '#FFC31B',
        '#F85C50',
        '#BC0022',
        '#7C3668',
        '#0043A4',
        //0x1EC9E8, // LIGHT CYAN BLUE
        "#0260E8", // BLUE
        //0x7EB3FF, // LIGHT BLUE
        //0X5BFF62, // LIGHT GREEN
        "#00DC7D", // GREEN
        //0x008736, // DARK GREEN
        //0xF5E027, // ORANGE
        //0xFFC11E, // DARK ORANGE 
        //0xF85C50, // RED
        //0xE20338, // DARK RED
    ];


    private static SKIN_PATH = "./skins/tools/filter_search_form.html";
    private static TO_FIXED_ERRORS = 3;
    private static INSTANCE: FilterFinderForm;

    private mUploadFileBtn: HTMLElement;

    private mRegionOfInterestDiv: HTMLElement;
    private mRegionInterestFromInput: HTMLInputElement;
    private mRegionInterestToInput: HTMLInputElement;
    private mNumberOfMatches: HTMLInputElement;


    private mBuildResponseFileBtn: HTMLElement;
    private mFilterTypeDD: HTMLSelectElement;

    // //---------- bandpass & notch filters
    private mFilterBandpassDiv: HTMLElement;
    private mFromBandPassInput: HTMLInputElement;
    private mToBandPassInput: HTMLInputElement;
    private mHeightbandPassInput: HTMLInputElement;
    // //-----------------------------

    // //---------- longpass & shortpass
    private mLongpassDiv: HTMLElement;
    private mLongpassTransmissionWlInput: HTMLInputElement;
    // //--------------------------------

    // //------------ customized
    private mCustomized: HTMLElement;
    private mUploadFileDiv: HTMLElement;
    private mCustomizedBuildResponse: HTMLElement;
    private mNumberOfChanges: HTMLInputElement;

    private mTransmissionBtn: HTMLElement;
    private mReflectionBtn: HTMLElement;
    private mCurentResultType: eResultType = null;

    private mPPolarized: HTMLElement;
    private mSPolarized: HTMLElement;
    private mUnpolarized: HTMLElement;
    private mFiltersCoatingResult: iHash<iFilterFinderResult>;
    private mFileInput: HTMLInputElement;
    private mFileTitle: HTMLLabelElement;

    private mFileState: eChosenFileState;
    private mFilterType: eFilterFinderFilterType;
    private mErrorItem: HTMLElement;
    private mErrorsContainer: HTMLElement;
    private mShowInformtationCheckbox: HTMLInputElement;
    private mCurrentServerDataResult: any = null;
    private mFileData: any = null; // check type
    private mGraphContainer: HTMLElement;
    private mChart: Chart;
    // ///private mSource: any;
    // private mHelpDiv: HTMLElement;

    private constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: FilterFinderForm.SKIN_PATH
        });
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onShown(): void {
        this._setDefautlParams();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners() {
        this.mUploadFileBtn.addEventListener('click',
            () => this.onFileToggleClicked(this.mUploadFileBtn,
                this.mBuildResponseFileBtn, eChosenFileState.UPLOAD_FILE));

        this.mBuildResponseFileBtn.addEventListener('click',
            () => this.onFileToggleClicked(this.mBuildResponseFileBtn,
                this.mUploadFileBtn, eChosenFileState.BUILD_RESPONSE));

        this.mFileInput.addEventListener('change',
            (e: Event) => this.onFileUploaded(e));

        let aFindBtn = this._getPart('find-btn', true);
        aFindBtn.addEventListener('click', () => this._onFindClicked());

        this.mFilterTypeDD.addEventListener("change", () => this.onFilterTypeChanged());
        this.mHeightbandPassInput.addEventListener("change",
            () => this._onHeightValueChanged());
        this.mTransmissionBtn.addEventListener("click",
            () => this._onResultTypeChanged(eResultType.TRANSMISSION));
        this.mReflectionBtn.addEventListener("click",
            () => this._onResultTypeChanged(eResultType.REFLECTION));
        this.mPPolarized.addEventListener("click",
            () => this.onPolarizationChanged(ePolarizationType.P_POLARIZED));
        this.mSPolarized.addEventListener("click",
            () => this.onPolarizationChanged(ePolarizationType.S_POLARIZED));
        this.mUnpolarized.addEventListener("click",
            () => this.onPolarizationChanged(ePolarizationType.UNPOLARIZED));
        this.mShowInformtationCheckbox.addEventListener("change",
            () => this._onShowInformationChanged());
    }

    //___________________________________________________________
    private onPolarizationChanged(pPolarizationType: ePolarizationType) {
        ViewUtils.setElementActive(this.mPPolarized, false);
        ViewUtils.setElementActive(this.mSPolarized, false);
        ViewUtils.setElementActive(this.mUnpolarized, false);

        switch (pPolarizationType) {
            case ePolarizationType.P_POLARIZED:
                ViewUtils.setElementActive(this.mPPolarized, true);
                break;
            case ePolarizationType.S_POLARIZED:
                ViewUtils.setElementActive(this.mSPolarized, true);
                break;
            case ePolarizationType.UNPOLARIZED:
                ViewUtils.setElementActive(this.mUnpolarized, true);
                break;
        }
    }
    //__________________________________________________________
    private _onHeightValueChanged() {
        let aVal = parseFloat(this.mHeightbandPassInput.value);
        if (isNaN(aVal) || (aVal < 0)) {
            this.mHeightbandPassInput.value = '0';
        } else if (aVal > 1) {
            this.mHeightbandPassInput.value = '1';
        }
    }
    //__________________________________________________________
    private _onResultTypeChanged(pResultType: eResultType) {
        ViewUtils.setElementActive(this.mTransmissionBtn, false);
        ViewUtils.setElementActive(this.mReflectionBtn, false);

        switch (pResultType) {
            case eResultType.TRANSMISSION:
                ViewUtils.setElementActive(this.mTransmissionBtn, true);
                break;
            case eResultType.REFLECTION:
                ViewUtils.setElementActive(this.mReflectionBtn, true);
                break;
        }

        this.mCurentResultType = pResultType;
    }
    //__________________________________________________________
    protected _initElements() {
        //this.mHelpDiv = this._getPart("help-div", true);
        // ViewUtils.removeFromParent(this.mHelpDiv);
        this.mFilterTypeDD = this._getPart("filter-type-dropdown", true) as HTMLSelectElement;
        this.mRegionInterestFromInput = this._getPart("region-interest-from-input") as HTMLInputElement;
        this.mRegionInterestToInput = this._getPart("region-interest-to-input") as HTMLInputElement;
        this.mNumberOfMatches = this._getPart("number-of-matches") as HTMLInputElement;
        this.mUploadFileBtn = this._getPart("upload-file");
        this.mBuildResponseFileBtn = this._getPart("build-response");
        this.mFileInput = this._getPart("file-input") as HTMLInputElement;
        this.mFileTitle = this._getPart("file-title") as HTMLLabelElement;
        this.mFilterBandpassDiv = this._getPart("filter-bandpass-type", true)
        this.mRegionOfInterestDiv = this._getPart("region-of-interest", true)
        this.mFromBandPassInput = this._getPart("band-pass-filter-from-input", true) as HTMLInputElement;
        this.mToBandPassInput = this._getPart("band-pass-filter-to-input", true) as HTMLInputElement;
        this.mHeightbandPassInput = this._getPart("bandpass-height-input", true) as HTMLInputElement;
        this.mLongpassDiv = this._getPart("longpass-div", true);
        this.mLongpassTransmissionWlInput = this._getPart("longpass-at-input", true) as HTMLInputElement;
        this.mCustomized = this._getPart("customized-div", true) as HTMLInputElement;
        this.mUploadFileDiv = this._getPart("upload-file-div", true);
        this.mCustomizedBuildResponse = this._getPart("customized-build-response", true);
        this.mNumberOfChanges = this._getPart("number-of-changes", true) as HTMLInputElement;

        this.mTransmissionBtn = this._getPart("transmission-btn", true);
        this.mReflectionBtn = this._getPart("reflection-btn", true);
        this.mPPolarized = this._getPart("p-polarized-btn", true);
        this.mSPolarized = this._getPart("s-polarized-btn", true);
        this.mUnpolarized = this._getPart("unpolarized-btn", true);
        this.mErrorItem = this._getPart("error-text-item-container", true);
        this.mErrorsContainer = this.mErrorItem.parentElement;

        this.mGraphContainer = this._getPart('filter-graph');

        ViewUtils.removeFromParent(this.mErrorItem);
        this.mShowInformtationCheckbox = this._getPart("show-filter-info") as HTMLInputElement;
    }

    //_________________________________________________________
    private onFilterTypeChanged() {
        let aSelectedType = this.mFilterTypeDD.selectedOptions[0].innerHTML.toUpperCase();
        switch (aSelectedType) {
            case "BAND-PASS FILTERS":
                this.mFilterType = eFilterFinderFilterType.BAND_PASS_FILTER;
                break;

            case "LONGPASS FILTERS":
                this.mFilterType = eFilterFinderFilterType.LONG_PASS_FILTER;
                break;

            case "SHORTPASS FILTERS":
                this.mFilterType = eFilterFinderFilterType.SHORT_PASS_FILTER;
                break;

            case "NOTCH FILTERS":
                this.mFilterType = eFilterFinderFilterType.NOTCH_FILTER;
                break;

            case "CUSTOMIZED FILTERS":
                this.mFilterType = eFilterFinderFilterType.CUSTOMIZED_FILTER;
                break;

            default:
                this.mFilterType = null;
        }

        this.updateFilterTypeState();
    }
    //_________________________________________________________
    private initLongShortPassFilter() {
        ViewUtils.setElementVisibilityByDNone(this.mLongpassDiv, true)
        ViewUtils.setElementVisibilityByDNone(this.mRegionOfInterestDiv, true)
        this.mLongpassTransmissionWlInput.value = "";
    }
    //_________________________________________________________
    private initCustomizedFiter() {
        ViewUtils.setElementVisibilityByDNone(this.mCustomized, true);
        this.onFileToggleClicked(this.mUploadFileBtn,
            this.mBuildResponseFileBtn,
            eChosenFileState.UPLOAD_FILE);
    }
    //_________________________________________________________
    private initBandPassFilter() {
        ViewUtils.setElementVisibilityByDNone(this.mFilterBandpassDiv, true);
        ViewUtils.setElementVisibilityByDNone(this.mRegionOfInterestDiv, true);
        this.mFromBandPassInput.value = "";
        this.mToBandPassInput.value = "";
        this.mHeightbandPassInput.value = "";
    }
    //_________________________________________________________
    private updateFilterTypeState() {
        ViewUtils.setElementVisibilityByDNone(this.mFilterBandpassDiv, false);
        ViewUtils.setElementVisibilityByDNone(this.mLongpassDiv, false);
        ViewUtils.setElementVisibilityByDNone(this.mCustomized, false);
        ViewUtils.setElementVisibilityByDNone(this.mUploadFileDiv, false);
        ViewUtils.setElementVisibilityByDNone(this.mCustomizedBuildResponse, false);
        ViewUtils.setElementVisibilityByDNone(this.mRegionOfInterestDiv, false);

        switch (this.mFilterType) {
            case eFilterFinderFilterType.NOTCH_FILTER:
            case eFilterFinderFilterType.BAND_PASS_FILTER:
                this.initBandPassFilter();
                break;

            case eFilterFinderFilterType.CUSTOMIZED_FILTER:
                this.initCustomizedFiter();
                break;

            case eFilterFinderFilterType.SHORT_PASS_FILTER:
            case eFilterFinderFilterType.LONG_PASS_FILTER:
                this.initLongShortPassFilter();
                break;
            default:


        }
    }
    //_________________________________________________________
    private getPath() {
        //let aPolarizationType = "";
        //switch (this.mCurrentPolarizationType) {
        //    case ePolarizationType.P_POLARIZED:
        //        aPolarizationType = "p_polarized";
        //        break;
        //    case ePolarizationType.S_POLARIZED:
        //        aPolarizationType = "s_polarized";
        //        //aPolarizationType = "p_polarized";
        //        break;
        //    case ePolarizationType.UNPOLARIZED:
        //        aPolarizationType = "unpolarized";
        //        break;
        //}
        //let aPath = aResultType + "/" + aPolarizationType; 


        let aFilterType = "";
        switch (this.mFilterType) {
            case eFilterFinderFilterType.NOTCH_FILTER:
                aFilterType = "notch";
                break;
            case eFilterFinderFilterType.BAND_PASS_FILTER:
                aFilterType = "bandpass";
                break;
            case eFilterFinderFilterType.SHORT_PASS_FILTER:
                aFilterType = "shortpass";
                break;
            case eFilterFinderFilterType.LONG_PASS_FILTER:
                aFilterType = "longpass";
                break;
            case eFilterFinderFilterType.CUSTOMIZED_FILTER:
                aFilterType = "custom";
                break;
            default:
        }

        let aResultType = "";

        switch (this.mCurentResultType) {
            case eResultType.TRANSMISSION:
                aResultType = "transmission";
                break;
            case eResultType.REFLECTION:
                aResultType = "reflection";
                break;
        }

        let aPath = aFilterType + "/" + aResultType;
        return aPath;
    }
    //__________________________________________________________
    private _onFindClicked() {
        const aRes = this._isInputValid();
        if (aRes.success) {
            this.onValidInput()
        } else {
            this.onError(aRes.data)
        }
    }
    //_________________________________________________________
    private _isInputValid(): iAsyncCallback<string> {
        if (this.mFilterType == null) {
            return { success: false, data: "Filter type is invalid" };
        }

        if (isNaN(parseInt(this.mNumberOfMatches.value))) {
            return { success: false, data: "Number of matches is invalid" };
        }

        if (parseInt(this.mNumberOfMatches.value) > 10) {
            return { success: false, data: "Number of matches must be ≤ 10" };
        }


        let aROIMin = parseInt(this.mRegionInterestFromInput.value);
        let aROIMax = parseInt(this.mRegionInterestToInput.value);

        switch (this.mFilterType) {
            case eFilterFinderFilterType.BAND_PASS_FILTER:
            case eFilterFinderFilterType.NOTCH_FILTER:

                let aFromBandpass = parseInt(this.mFromBandPassInput.value);
                let aToBandpass = parseInt(this.mToBandPassInput.value);

                if (isNaN(aFromBandpass)) {
                    return {
                        success: false,
                        data: "Filter Region range is invalid"
                    };
                }
                if (isNaN(aToBandpass)) {
                    return {
                        success: false,
                        data: "Filter Region is invalid"
                    };
                }

                if (aFromBandpass <= aROIMin) {
                    return {
                        success: false,
                        data: 'The lower bound of the "Filter Region" needs to be bigger than the Region of interest'
                    };
                }

                if (aToBandpass >= aROIMax) {
                    return {
                        success: false,
                        data: 'The upper bound of the "Filter Region" needs to be smaller than the Region of interest'
                    };
                }

                if (!this.isRegionInterestValid()) {
                    return {
                        success: false,
                        data: "Region of interest range is invalid"
                    };
                }

                let aHeightValue = parseFloat(this.mHeightbandPassInput.value)
                if (isNaN(aHeightValue)) {
                    return {
                        success: false,
                        data: "Height value is invalid"
                    };
                }

                if (aHeightValue < 0 || aHeightValue > 1) {
                    return {
                        success: false,
                        data: "Height value must be between (0-1)"
                    };
                }

                return { success: true, data: null };

            case eFilterFinderFilterType.SHORT_PASS_FILTER:
            case eFilterFinderFilterType.LONG_PASS_FILTER:
                if (!this.isRegionInterestValid()) {
                    return {
                        success: false,
                        data: "Region of interest range is invalid"
                    };
                }

                let aLongpassWLInput = parseInt(this.mLongpassTransmissionWlInput.value);
                if (isNaN(aLongpassWLInput)) {
                    return {
                        success: false,
                        data: "Transition wavelength value is invalid"
                    };
                }

                if (aLongpassWLInput <= aROIMin || aLongpassWLInput >= aROIMax) {
                    return {
                        success: false,
                        data: "Transition wavelength needs to be bigger than " +
                            aROIMin + " and less than " + aROIMax
                    };
                }

                return { success: true, data: null };
            case eFilterFinderFilterType.CUSTOMIZED_FILTER:
                /// return this.isCustomizeValid();
                Popup.instance.open({ text: MessagesHandler.COMING_SOON });
                break;
        }
    }
    //__________________________________________________________
    // private isCustomizeValid() {
    //     if (this.mFileData != null) {
    //         return { success: true, data: null };
    //     }

    //     if (!this.mFileInput.files[0]) {
    //         return { success: false, data: MessagesHandler.NO_FILE_CHOSEN };
    //     }

    //     let aFileName = this.mFileInput.files[0].name;
    //     if (aFileName.indexOf('.csv') < 0) {
    //         return { success: false, data: MessagesHandler.WRONG_FILE_FORMAT };
    //     }

    //     this.loadFile();
    // }
    //__________________________________________________________
    private isRegionInterestValid() {
        let aFrom = parseInt(this.mRegionInterestFromInput.value)
        let aTo = parseInt(this.mRegionInterestToInput.value)
        if (isNaN(aFrom) ||
            isNaN(aTo)) {
            return false;
        }

        return true;
    }
    //_________________________________________________________
    private async onValidInput() {
        let aPath = this.getPath();

        let aQuery: iFilterQueryVO = {
            num_matches: parseInt(this.mNumberOfMatches.value)
        }

        switch (this.mFilterType) {
            case eFilterFinderFilterType.BAND_PASS_FILTER:
            case eFilterFinderFilterType.NOTCH_FILTER:
                aQuery.filter_min = parseInt(this.mFromBandPassInput.value);
                aQuery.filter_max = parseInt(this.mToBandPassInput.value);
                aQuery.height = parseFloat(this.mHeightbandPassInput.value);

                aQuery.roi_min = parseInt(this.mRegionInterestFromInput.value);
                aQuery.roi_max = parseInt(this.mRegionInterestToInput.value);
                break;
            case eFilterFinderFilterType.LONG_PASS_FILTER:
            case eFilterFinderFilterType.SHORT_PASS_FILTER:
                aQuery.transition_wavelength = parseInt(this.mLongpassTransmissionWlInput.value);

                aQuery.roi_min = parseInt(this.mRegionInterestFromInput.value);
                aQuery.roi_max = parseInt(this.mRegionInterestToInput.value);
                break;
            case eFilterFinderFilterType.CUSTOMIZED_FILTER:
                aQuery.x = this.mFileData.x;
                aQuery.y = this.mFileData.y;
                break;
        }

        for (let item in aQuery) {
            if (aQuery[item] == null) {
                delete aQuery[item];
            }
        }

        Spinner.instance.show();
        this.mCurrentServerDataResult = null;

        const aResult = await ServerContext.SERVER.searchFilter(aPath, aQuery);
        if (aResult.success) {
            this.onResultSuccess(aResult.data, aQuery);
        } else {
            this._onResultError(aResult);
        }
    }
    //_________________________________________________________
    private async onResultSuccess(pServerData: any, pQuery: iFilterQueryVO) {

        if (pServerData == null || pServerData == "") {
            return;
        }
        this.mCurrentServerDataResult = pServerData;

        await this._addErrorsText(pServerData);
        Spinner.instance.hide();

        let aDataSet = this.getDashedLineData(pServerData, pQuery);
        this._drawGraphs(pServerData, aDataSet)
    }

    //_________________________________________________________
    private _onShowInformationChanged() {
        this.mChart.config.options.plugins.tooltip.enabled = !this.mChart.config.options.plugins.tooltip.enabled;
        this.mChart.update();
        if (this.mCurrentServerDataResult != null) {
            this._addErrorsText(this.mCurrentServerDataResult);
        }
    }
    //_________________________________________________________
    private onFilterCoatingsReceived(pServerData: any,
        pFilterCoatings: iHash<iFilterFinderResult>) {
        try {
            this.mFiltersCoatingResult = pFilterCoatings;

            this._clearErrors();
            let aPassTitle = "PBE = "; // pass band error
            let aStopTitle = "SBE = "; //"stop band error = ";

            for (let i = 0; i < pServerData.length; i++) {
                let aPassError = parseFloat(pServerData[i].pass_err);
                let aStopError = parseFloat(pServerData[i].stop_err);
                let aPercentage = 100 * (1 - aPassError - aStopError);
                aPassError *= 100;
                aStopError *= 100;

                let aAdditionalInfo = "";
                if (this.mShowInformtationCheckbox.checked) {
                    aAdditionalInfo = "(" + aPassTitle +
                        aPassError.toFixed(FilterFinderForm.TO_FIXED_ERRORS) + "%, ";
                    aAdditionalInfo += aStopTitle +
                        aStopError.toFixed(FilterFinderForm.TO_FIXED_ERRORS) + "%) - ";
                }

                let aText = aPercentage.toFixed(FilterFinderForm.TO_FIXED_ERRORS - 1) +
                    "% - " + aAdditionalInfo;
                let aSystemName = pServerData[i].name;
                let aItem = this.mFiltersCoatingResult[aSystemName]
                //let aCoatingNameData = aItem.originalName;
                //if (aCoatingNameData != null) {
                let aAnchorElement = null;
                if (aItem != null) {
                    aAnchorElement = document.createElement('a') as HTMLAnchorElement;
                    aAnchorElement.onclick = () => this.onLinkClicked(aItem);
                    aAnchorElement.href = "#";
                    ViewUtils.setElementBold(aAnchorElement, true);
                    ViewUtils.setClassForElement(aAnchorElement, "py-2", true);
                    aAnchorElement.innerText = aItem.companyName + "- " + aItem.originalName;
                    //aAnchorElement.addEventListener("click", () => this.onLinkClicked(aItem));
                    //aText += aAnchorElement.outerHTML;
                    //let aAnchorelement2 = '<a href="' + aItem.link + '"  target="_blank"><strong>' + aItem.company + "- " + aItem.originalName + '</strong></a>'
                    //aText += aAnchorelement;

                } else {
                    aText += aSystemName;
                }

                let aCloneSpan = this.mErrorItem.cloneNode(true) as HTMLElement;
                aCloneSpan.innerHTML = aText;

                let aClassName = "item-" + ((i % FilterFinderForm.COLORS_ARRAY.length) + 1);
                aCloneSpan.classList.add(aClassName);
                this.mErrorsContainer.appendChild(aCloneSpan);



                if (aAnchorElement != null) {
                    aCloneSpan.parentElement.appendChild(aAnchorElement);
                }
            }
        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({ error: e, type: eErrorType.INTERNAL_ERROR, show_message: false });
        }
    }
    //_________________________________________________________
    // private placeVirtualMount(pCoatingNameVO: iFilterFinderResult) {
    //     //TODO
    //     Popup.instance.open({ text: MessagesHandler.COMING_SOON });
    //     //let aAbstractMountVO = engine.Op3dContext.dataManager.getPartVOById("G0001");
    //     //new engine.LoadingHandler().load([aAbstractMountVO.url], () => this.onPartsLoaded(aAbstractMountVO, pCoatingNameVO));
    // }
    //_________________________________________________________
    // private onPartsLoaded(pAbstractMountVO: PartVO,
    //     pCoatingNameVO: iFilterFinderResult) {

    //     let aAbstractMount = engine.Op3dContext.partsCatalog.getPart(pAbstractMountVO) as engine.parts.AbstractMount;
    //     if (aAbstractMount == null) {
    //         return;
    //     }

    //     let aLookAt = engine.Op3dContext.viewManager.lookAt.clone();
    //     aLookAt.y = 24;
    //     engine.Op3dContext.partsContainer.add(aAbstractMount);
    //     aAbstractMount.movePart(aLookAt, true, true);
    //     aAbstractMount.update();

    //     aAbstractMount.onSelect();https://www.geeksforgeeks.org/javascript-set-constructor-property/?ref=lbp

    //     views.SynchronizedOpticsMenu.instance.openMenuBySpecificSearch({
    //         freeString: pCoatingNameVO.originalName,
    //         filters: [],
    //         callback: () => this.checkIfFilterChosen(aAbstractMount),
    //         notice: 'We have found one or more filters with this coating.<br>Select a filter from the result section'
    //     });

    //     this.setVisibility(false);
    // }
    //_________________________________________________________
    // private checkIfFilterChosen(pMount: engine.parts.AbstractMount) {
    //     if (pMount != engine.Op3dContext.partsManager.selectedPart) {
    //         pMount.onSelect();
    //     }
    //     if (pMount.opticalElements == null || pMount.opticalElements[0] == null) {
    //         engine.Op3dContext.partsManager.onDeletePressed();
    //     }
    // }
    //_________________________________________________________
    private onLinkClicked(_pLink: iFilterFinderResult) {
        // let aVendorCallback = () => {
        //     let aLinkElem = document.createElement('a');
        //     aLinkElem.href = pLink.link;
        //     aLinkElem.target = "_blank";
        //     aLinkElem.click();
        // };

        Popup.instance.open({ text: MessagesHandler.COMING_SOON });
        // @TODO
        // Op3dContext.DIV_CONROLLER.showButtonsModal(true, ["Go to vendor's website", "Place in a virtual mount"],
        //     [aVendorCallback, () => this.placeVirtualMount(pLink)]);
    }
    //_________________________________________________________
    private async _addErrorsText(pServerData: Array<any>) {
        try {
            if (pServerData instanceof Array) {
                let aCoatingNames = pServerData.map((item) => item.name);

                let aResult = await Op3dContext.DATA_MANAGER.getFiltersData(aCoatingNames);
                this.onFilterCoatingsReceived(pServerData, aResult);
            }
        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({ error: e, show_message: false, type: eErrorType.INTERNAL_ERROR });
        }
    }
    //_________________________________________________________
    private _onResultError(pError: any) {

        let aMsg = MessagesHandler.SOMETHING_WENT_WRONG;
        if (pError != null && pError.responseJSON != null && pError.responseJSON.description != null) {
            aMsg = pError.responseJSON.description;
        }

        Popup.instance.open({ text: aMsg });
        Spinner.instance.hide();
    }
    //_________________________________________________________
    private _initCustomizedBuildResponse() {
        ViewUtils.setElementVisibilityByDNone(this.mCustomizedBuildResponse, true);
        this.mNumberOfChanges.value = "";
    }
    //_________________________________________________________
    private _initCustomizedUploadFile() {
        ViewUtils.setElementVisibilityByDNone(this.mUploadFileDiv, true);
        this.mFileTitle.innerHTML = "Choose File";
        this.mFileInput.value = null;
    }
    //_________________________________________________________
    private _onUploadFileStateChanged() {
        ViewUtils.setElementVisibilityByDNone(this.mUploadFileDiv, false);
        ViewUtils.setElementVisibilityByDNone(this.mCustomizedBuildResponse, false);

        switch (this.mFileState) {
            case eChosenFileState.BUILD_RESPONSE:
                this._initCustomizedBuildResponse();
                break;
            case eChosenFileState.UPLOAD_FILE:
                this._initCustomizedUploadFile();
                break;
        }
    }
    //_________________________________________________________
    private onFileUploaded(_e: Event) {
        this.mFileData = null;
        if (this.mFileInput.files && this.mFileInput.files[0]) {
            let aName = this.mFileInput.files[0].name;
            this.mFileTitle.innerHTML = aName;
        }
    }
    //_________________________________________________________
    private onError(pMsg: string) {
        NotificationCenter.instance.pushNotification({
            message: pMsg,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
        });
    }
    //_________________________________________________________
    private onFileToggleClicked(pChosenElem: HTMLElement, pNotChosenEle: HTMLElement, pFileState: eChosenFileState) {
        ViewUtils.setElementActive(pChosenElem, true);
        ViewUtils.setElementActive(pNotChosenEle, false);
        this.mFileState = pFileState;

        this._onUploadFileStateChanged();
    }
    //_________________________________________________________
    public static get instance() {
        if (FilterFinderForm.INSTANCE == null) {

            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('w-100-modal');
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "true");
            aDiv.setAttribute("role", "dialog");
            aDiv.setAttribute("aria-hidden", "true");
            document.getElementById('forms').appendChild(aDiv);
            FilterFinderForm.INSTANCE = new FilterFinderForm(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onHidden(): void {
        this.mCurrentServerDataResult = null;
        this.mFileData = null;
    }
    //__________________________________________________________________________________________
    protected _onOpen(_pData?: any): void {
    }
    //__________________________________________________________________________________________
    private _setDefautlParams() {
        this._onClearForm();
        this.mShowInformtationCheckbox.checked = true;
        this._onResultTypeChanged(eResultType.TRANSMISSION);
        this.onPolarizationChanged(ePolarizationType.P_POLARIZED);
        ViewUtils.selectOptionByIndex(this.mFilterTypeDD, 1, true);
        this.mRegionInterestFromInput.value = '400';
        this.mRegionInterestToInput.value = '700';
        this.mNumberOfMatches.value = '3';
        this.mFromBandPassInput.value = "450";
        this.mToBandPassInput.value = "500";
        this.mHeightbandPassInput.value = '1';

        this._onFindClicked();
    }
    //__________________________________________________________________________________________
    private _clearErrors() {
        for (let i = this.mErrorsContainer.children.length - 1; i >= 0; i--) {
            this.mErrorsContainer.removeChild(this.mErrorsContainer.children[i]);
        }
    }
    //__________________________________________________________________________________________
    private _onClearForm() {
        this._clearErrors();
    }
    //_____________________________________________________________________________________________
    private _getLongestDataIdx(pResult: Array<iResultData>) {
        let aMaxLength = pResult[0].x.length;
        let aFoundIdx = 0;
        for (let i = 0; i < pResult.length; i++) {
            if (pResult[i].x.length > aMaxLength) {
                aMaxLength = pResult[i].x.length;
                aFoundIdx = i;
            }
        }
        return aFoundIdx;
    }

    //_____________________________________________________________________________________________
    private getDashedLineData(pResult: Array<iResultData>, pQuery: iFilterQueryVO) {

        let aLongestIdx = this._getLongestDataIdx(pResult);
        let aLongestLength = pResult[aLongestIdx].x.length
        let aDelta = (pQuery.roi_max - pQuery.roi_min) / aLongestLength;
        let aDashedResult = [];

        for (let i = pQuery.roi_min; i < pQuery.roi_max; i += aDelta) {

            switch (this.mFilterType) {
                case eFilterFinderFilterType.BAND_PASS_FILTER:
                    if (i < pQuery.filter_min) {
                        aDashedResult.push(0);
                    } else if (i > pQuery.filter_max) {
                        aDashedResult.push(0);
                    } else {
                        aDashedResult.push(pQuery.height);
                    }
                    break;
                case eFilterFinderFilterType.NOTCH_FILTER:
                    if (i < pQuery.filter_min) {
                        aDashedResult.push(1);
                    } else if (i > pQuery.filter_max) {
                        aDashedResult.push(1);
                    } else {
                        aDashedResult.push(pQuery.height);
                    }
                    break;
                case eFilterFinderFilterType.LONG_PASS_FILTER:
                    if (i < pQuery.transition_wavelength) {
                        aDashedResult.push(0);
                    } else
                        aDashedResult.push(1);

                    break;
                case eFilterFinderFilterType.SHORT_PASS_FILTER:
                    if (i < pQuery.transition_wavelength) {
                        aDashedResult.push(1);
                    } else
                        aDashedResult.push(0);
                    break;
            }
        }
        let aDashedDataSet: ChartDataset = {
            label: 'Input',
            data: aDashedResult,
            borderColor: 'black',
            borderWidth: 2,
            pointRadius: 0,
            borderDash: [5, 5], // Dashed pattern: 5 pixels on, 5 pixels off
        };

        return aDashedDataSet;
    }
    //_____________________________________________________________________________________________
    private _drawGraphs(
        pData: any,
        pDashedData: ChartDataset) {

        let aInitLength = pData[0].x.length;
        let aInitX = pData[0].x;

        let aFoundIdx = 0;
        for (let i = 0; i < pData.length; i++) {
            if (pData[i].x.length > aInitLength) {
                aInitLength = pData[i].x.length;
                aInitX = pData[i].x;
                aFoundIdx = i;
            }
        }

        for (let i = 0; i < pData.length; i++) {
            if (aFoundIdx === i) {
                continue;
            }
            let aNewY = [];
            const aCurrArr = pData[i];
            for (let j = 0; j < aInitX.length; j++) {
                let aXIdx = aCurrArr.x.indexOf(aInitX[j]);
                if (aXIdx === -1) {
                    let aPrevWLIdx = Op3dUtils.getClosestPrevIndex(aInitX[j], aCurrArr.x);
                    let aNextWLIdx = Op3dUtils.getClosestNextIndexInArray(aInitX[j], aCurrArr.x);
                    if (aPrevWLIdx == -1 && aNextWLIdx == -1) {
                        if (aInitLength - j < 100) {
                            aNewY.push(aCurrArr.y[aCurrArr.y.length - 1]);
                        } else {
                            if(aNewY[j - 1] !== undefined){
                                aNewY.push(aNewY[j-1]);
                            }else{
                                aNewY.push(aCurrArr.y[0]);
                            }
                        }
                        continue
                    }
                    
                    let aY = OP3DMathUtils.getLinearInterpolationValue(aInitX[j], aCurrArr.x[aPrevWLIdx], aCurrArr.x[aNextWLIdx], aCurrArr.y[aPrevWLIdx], aCurrArr.y[aNextWLIdx]);
                    if (isNaN(aY)) {
                        aY = 0;
                    }

                    aNewY.push(aY)

                } else {
                    aNewY.push(aCurrArr.y[aXIdx])

                }
            }
            aCurrArr.y = aNewY;
        }

        const data: ChartData = { labels: aInitX, datasets: [] }


        for (let i = 0; i < pData.length; i++) {
            let aDataToPush = {
                label: pData[i].name,
                data: pData[i].y,
                pointRadius: 0,
                pointHoverRadius: 5,
                pointHitRadius: 10,
                borderWidth: 1,
                backgroundColor: FilterFinderForm.COLORS_ARRAY[i],
                borderColor: FilterFinderForm.COLORS_ARRAY[i],
            }

            data.datasets.push(aDataToPush)
        }

        data.datasets.push(pDashedData);

        let aXAxisLabelText = "Wavelength (nm)";
        let aYAxisLabelText = this.mCurentResultType == eResultType.TRANSMISSION ? "Transmission P-Polarized" : "Reflection P-Polarized";

        const config = {
            type: 'line',
            data: data,
            options: {
                plugins: {
                    // zoom: zoomOptions,
                    tooltip: {
                        enabled: this.mShowInformtationCheckbox.checked,
                        intersect: false,
                        displayColors: true,
                        mode: 'index',
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex !== data.datasets.length - 1; // Exclude Dataset 2 from tooltip
                        },
                    },
                    legend: {
                        display: true
                    }
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: aXAxisLabelText
                        },
                        type: 'linear', // Adjust the scale type based on your data
                        ticks: {
                            callback: function (value) {
                                return value; // Return the formatted label
                            }
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: aYAxisLabelText
                        }
                    }
                },
            }
        };

        let aGraphCanvas = document.createElement('canvas');
        ViewUtils.clearElementsChildren(this.mGraphContainer)
        this.mGraphContainer.appendChild(aGraphCanvas);
        aGraphCanvas.id = Op3dUtils.idGenerator();
        let aBB = this.mGraphContainer.getBoundingClientRect();
        aGraphCanvas.width = aBB.width;
        aGraphCanvas.height = aBB.height;

        let a2D = aGraphCanvas.getContext('2d');
        this.mChart = new Chart(a2D, config as any);
    }
    //__________________________________________________________________________________________
}
