import { iHash } from "../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { Op3dComponentBase } from "../ui/Op3dComponentBase";
import { ViewUtils } from "../ui/ViewUtils";


export interface iParserRows {
    fileName: string;
    sectionName: string;
    rows: Array<iHash<string>>;
    start: number;
}

export interface iParserFormParams {
    workbooks: Array<{
        workbook: XLSX.WorkBook;
        fileName: string;
    }>;
    callback: (pData: Array<iParserRows>) => void;
    defval: string;
    trim?: boolean;
};

export class ParserForm extends Op3dComponentBase<iParserFormParams> {

    private static INSTANCE: ParserForm;

    private mOneElement: HTMLElement;
    private mElementsParent: HTMLElement;

    private mCallback: (pData: Array<iParserRows>) => void;
    private mRows: iHash<Array<iHash<string>>>;
    private mRowsProperties: iHash<{
        toParse: boolean;
        start: number;
        end: number;
        fileName: string;
        sectionName: string;
    }>;


    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/parser_form.html'
        });
    }
    //__________________________________________________________________________________________
    public async close() {
        this.hide();
        this._onClose();
    }
    //__________________________________________________________________________________________
    protected _onClose() {
        this._clear();
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ParserForm.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            document.getElementById('forms').appendChild(aDiv);

            ParserForm.INSTANCE = new ParserForm(aDiv);
        }

        return ParserForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mElementsParent);
        this.mRows = null;
        this.mRowsProperties = null;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pParserFormParams?: iParserFormParams): void {
        this._clear();
        this.mCallback = pParserFormParams.callback;
        this.mRows = {};
        this.mRowsProperties = {};

        for (let i = 0; i < pParserFormParams.workbooks.length; i++) {
            let aWorkbook = pParserFormParams.workbooks[i];
            let aFileName = aWorkbook.fileName;

            let aSheetNames = aWorkbook.workbook.SheetNames;
            for (let j = 0; j < aSheetNames.length; j++) {
                let aSheetName = aWorkbook.workbook.SheetNames[j];
                let aSheet = aWorkbook.workbook.Sheets[aSheetName];
                let aRows = XLSX.utils.sheet_to_json<iHash<string>>(aSheet, {
                    blankrows: true,
                    defval: pParserFormParams.defval
                });

                let aID = aFileName + '_' + aSheetName;
                this.mRows[aID] = aRows;

                if (false != pParserFormParams.trim) {
                    this._trim(aRows);
                }

                let aOneElement = this.mOneElement.cloneNode(true) as HTMLElement;
                this.mElementsParent.appendChild(aOneElement);
                aOneElement.id = aID;


                Op3dUtils.getElementIn(aOneElement, 'file_name').innerHTML = aFileName;
                Op3dUtils.getElementIn(aOneElement, 'section_name').innerHTML = aSheetName;

                let aCheckbox = Op3dUtils.getElementIn(aOneElement, 'section_checkbox',
                    true) as HTMLInputElement;
                let aLabel = Op3dUtils.getElementIn(aOneElement, 's_label') as HTMLLabelElement;
                aLabel.htmlFor = aCheckbox.id;

                let aInputsParent = Op3dUtils.getElementIn(aOneElement, 'inputs_parent');
                aCheckbox.addEventListener('change',
                    () => {
                        ViewUtils.setElementDisabled(aInputsParent, !aCheckbox.checked);
                        this.mRowsProperties[aID].toParse = aCheckbox.checked;
                    });

                let aMax = (aRows.length + 1).toString();
                let aStartRow = Op3dUtils.getElementIn(aOneElement,
                    'start_row') as HTMLInputElement;
                aStartRow.addEventListener('change',
                    () => this.mRowsProperties[aID].start = (parseInt(aStartRow.value) - 1));

                aStartRow.max = aMax;
                let aEndRow = Op3dUtils.getElementIn(aOneElement,
                    'end_row') as HTMLInputElement;
                aEndRow.addEventListener('change',
                    () => this.mRowsProperties[aID].end = parseInt(aEndRow.value));

                aEndRow.max = aMax;
                aEndRow.value = aMax;

                this.mRowsProperties[aID] = {
                    start: 0,
                    end: aRows.length,
                    toParse: true,
                    fileName: aFileName,
                    sectionName: aSheetName
                };
            }
        }
    }
    //__________________________________________________________________________________________
    private _trim(pObject: Array<iHash<any>>) {
        if (null == pObject) {
            return null;
        }

        let aTrimmedObject = pObject.map((row) => {
            let aNewRow: iHash = {};

            for (let cell in row) {
                let aNewCell = cell.toString().trim();

                if ((null != row[cell]) && (true == isNaN(row[cell]))) {
                    aNewRow[aNewCell] = row[cell].toString().trim();
                } else {
                    aNewRow[aNewCell] = row[cell];
                }
            }

            return aNewRow;
        });

        return aTrimmedObject;
    }
    //__________________________________________________________________________________________
    private _onParse() {
        let aRows = new Array<iParserRows>();
        for (let row in this.mRows) {
            let aRowProperties = this.mRowsProperties[row];
            if (true == aRowProperties.toParse) {
                let aStart = aRowProperties.start;
                let aEnd = aRowProperties.end;

                aRows.push({
                    fileName: aRowProperties.fileName,
                    sectionName: aRowProperties.sectionName,
                    rows: this.mRows[row].slice(aStart, aEnd),
                    start: aStart
                });
            }
        }

        this.mCallback(aRows);
        this.close();
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mOneElement = this._getPart('one_section_element');
        this.mElementsParent = this.mOneElement.parentElement;
        ViewUtils.removeFromParent(this.mOneElement);
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._getPart('parse_btn').addEventListener('click', () => this._onParse());
    }
    //__________________________________________________________________________________________
}
