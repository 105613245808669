import { EventManager } from "../../../oc/events/EventManager";
import { eModalWindowState, eUnitType, eDataPermission, eErrorType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iBasicSetupData, iHash, iUploadSetupData } from "../../_context/_interfaces/Interfaces";
import { Main } from "../../_main/Main";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { SnapshotTools } from "../../_utils/SnapshotTools";
import { iSceneOptions } from "../../scene/GridManager";
import { SceneContext } from "../../scene/SceneContext";
import { ServerContext } from "../../server/ServerContext";
import { SettingsContext } from "../../settings/SettingsContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { Spinner } from "./Spinner";



export enum eFillType {
    TEXT_INPUT,
    TEXT,
    CHECKBOX,
    INPUT_NUMBER,
    NUMERIC_SELECT,
    SELECT,
    MULTIPLE_SELECT,
};

export interface iFillElement<T = any> {
    /**
     * Type of the data.
     */
    type: eFillType;

    /**
     * path of key in data.
     */
    path?: string;

    /**
     * Function return the relevalnt type to determine the value.
     */
    function?: (pData: T) => any;

    /**
     * Wheather to dispatch change event. Default - false.
     */
    dispatchChangeEvent?: boolean;

    /**
     * Default value of the data.
     */
    defaultValue?: any;
    placeHolder?: string;
    /**
     * default is false
     */
    isRequired?: boolean;
    errorFieldName?: string;
};

export interface iDesignFormData {
    setup?: iBasicSetupData;
    state: eModalWindowState;
    isNew: boolean;
    onFinish?: Function;
};

export enum eSceneType {
    GENERAL,
    LAB
}

export class NewDesignForm extends Op3dComponentBase<iDesignFormData> {

    private static ELEMENTS: iHash<iFillElement<iBasicSetupData>> = {
        name: {
            path: 'parameters.details.setupName',
            type: eFillType.TEXT_INPUT,
            placeHolder: "Setup Name",
            isRequired: true,
            errorFieldName: "Setup name",
            // defaultValue: 'New design 001'
        },
        unit: {
            path: 'parameters.unit',
            type: eFillType.NUMERIC_SELECT,
            defaultValue: () => {
                let aLastInput = window.localStorage.getItem('last_unit');
                return (null != aLastInput) ? aLastInput : eUnitType.MILLIMETERS;
            }
        },
        scene_type: {
            path: 'parameters.sceneType',
            type: eFillType.NUMERIC_SELECT,
            defaultValue: eSceneType.GENERAL
        },
        is_associated: {
            path: 'parameters.details.isAssociated',
            type: eFillType.CHECKBOX,
            defaultValue: false,
            dispatchChangeEvent: true
        },
        general_comments: {
            path: 'parameters.details.generalComments',
            placeHolder: "Short description about the setup",
            type: eFillType.TEXT_INPUT,
            isRequired: true,
            errorFieldName: "Description",
        },
        link: {
            path: 'parameters.details.link',
            type: eFillType.TEXT_INPUT
        },
        title: {
            path: 'parameters.details.title',
            type: eFillType.TEXT_INPUT
        },
        journal: {
            path: 'parameters.details.journal',
            type: eFillType.TEXT_INPUT
        },
        authors: {
            path: 'parameters.details.authors',
            type: eFillType.TEXT_INPUT
        },
        abstract: {
            path: 'parameters.details.abstract',
            type: eFillType.TEXT_INPUT
        },
        labels: {
            path: 'parameters.details.labels',
            type: eFillType.MULTIPLE_SELECT,
            //defaultValue: ['No labels'],
            isRequired: true,
            errorFieldName: "Labels",
        },
        // additional_info: {
        //     function: (pData) => (null != pData.parameters.details.generalComments),
        //     type: eFillType.CHECKBOX,
        //     dispatchChangeEvent: true,
        //     defaultValue: true
        // }
    };

    private static SKIN_PATH: string = './skins/home/new_design_form.html';
    private static INSTANCE: NewDesignForm;

    private mSaveBtn: HTMLElement;
    private mTitle: HTMLElement;
    private mUnitSelect: HTMLSelectElement;
    private mSceneType: HTMLSelectElement;
    private mPermissionSelect: HTMLSelectElement;

    private mState: eModalWindowState;
    private mIsNew: boolean;
    private mOnFinish: Function;

    private mIsOnSaving: boolean = false;
    private mEmptyInputErr: Array<{ element: HTMLElement, errorField: string }> = [];

    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: NewDesignForm.SKIN_PATH
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == NewDesignForm.INSTANCE) {
            let aContainer = document.createElement("div");
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.setAttribute('data-backdrop', 'static');
            aContainer.id = "new-design-container";
            aContainer.tabIndex = 1;
            document.getElementById("forms").appendChild(aContainer);

            NewDesignForm.INSTANCE = new NewDesignForm(aContainer);
        }

        return NewDesignForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData: iDesignFormData) {
        this._onUpdateForm(pData.setup);
        this._setPermission(pData);
        this._setFormTitleSaveNames(pData.state);
        this.mState = pData.state;
        this.mIsNew = pData.isNew;
        this.mOnFinish = pData.onFinish;

        let aIsNewState = (eModalWindowState.NEW == this.mState);
        ViewUtils.setElementDisabled(this.mUnitSelect, (false == aIsNewState));
        ViewUtils.setElementDisabled(this.mSceneType, (false == aIsNewState));

        EventManager.dispatchEvent(EventsContext.ON_CLOSE, this)
    }
    //__________________________________________________________________________________________
    private _setPermission(pData: iDesignFormData) {
        let aPermission: eDataPermission;
        if (false == Op3dContext.USER_PERMISSION.hasPrivateSetupsPermission) {
            aPermission = eDataPermission.PUBLIC;
        } else {
            aPermission = (null != pData.setup) ? pData.setup.permission :
                eDataPermission.PRIVATE;
        }

        this.mPermissionSelect.value = aPermission.toString();
    }
    //__________________________________________________________________________________________
    private initPopups() {
        $('#design-name').popover({
            content: function () {
                return $('.custom-popover-content-name').html();
            },
            html: true,
            placement: 'right',
            trigger: 'focus',
            offset: '0,50',
            fallbackPlacement: ['bottom'],

        })


        $('#general_comments').popover({
            content: function () {
                return $('.custom-popover-content-discription').html();
            },
            html: true,
            placement: 'right',
            trigger: 'focus',
            offset: '0,50',
            fallbackPlacement: ['bottom'],

        })
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mTitle = this._getPart('new-design-title');
        this.mUnitSelect = this._getPart('units-type') as HTMLSelectElement;
        this.mSceneType = this._getPart('scene-type') as HTMLSelectElement;
        this.mSaveBtn = this._getPart("save_btn");

        let aLinkToPricing = this._getPart("try-professional-thumbnail") as HTMLAnchorElement;
        aLinkToPricing.href = ServerContext.pricing_base_link;


        this.initPopups()
        this._initPermissionSelect();
        this._loadLabels();
    }
    //__________________________________________________________________________________________
    private _initPermissionSelect() {
        this.mPermissionSelect = this._getPart('publication') as HTMLSelectElement;
        this.mPermissionSelect.addEventListener('change',
            () => this._setFormTitleSaveNames(this.mState));
        this._onUpdatePermissions();

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onUpdatePermissions(), this);
    }
    //__________________________________________________________________________________________
    private _onUpdatePermissions() {
        let aPrivateSetupsPermission = Op3dContext.USER_PERMISSION.hasPrivateSetupsPermission;
        let aFindStr = 'option[value="' + eDataPermission.PRIVATE + '"]';
        let aPrivateOption = $(this.mPermissionSelect).find(aFindStr)[0];
        (aPrivateOption as HTMLOptionElement).disabled = (false == aPrivateSetupsPermission);

        if (false == aPrivateSetupsPermission) {
            this.mPermissionSelect.value = (eDataPermission.PUBLIC).toString();
        }
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mSaveBtn.addEventListener("mouseup", async () => {
            this.mIsOnSaving ? null : this._onSave();
        });
        this.mContainer.addEventListener('keyup', async (e) => {
            this.mIsOnSaving ? null : this._onKeyup(e);
        });

        let aUnitsSelect = this._getPart("units-type") as HTMLSelectElement;
        aUnitsSelect.addEventListener('change', () => window.localStorage.setItem('last_unit',
            aUnitsSelect.value));

        // let aAdditionalInfoSec = this._getPart("additional-info-section");
        // let aAdditionalInfoCheckbox = this._getPart('additional-info') as HTMLInputElement;
        // this._getPart('additional-info').addEventListener('change', () => {
        //     let aIsAdditionalInfo = aAdditionalInfoCheckbox.checked;
        //     ViewUtils.setElementVisibilityByDNone(aAdditionalInfoSec, aIsAdditionalInfo);
        // });



        let aAssociatedSection = this._getPart("associated-publications-section");
        let aIsAssociatedCB = this._getPart('is_associated_cb') as HTMLInputElement;
        aIsAssociatedCB.addEventListener('change', () =>
            ViewUtils.setElementVisibilityByDNone(aAssociatedSection, aIsAssociatedCB.checked));
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _onKeyup(e: KeyboardEvent) {
        if (e.key != Strings.KEY_ENTER) {
            return;
        }

        this._onSave();
    }
    //__________________________________________________________________________________________
    private _loadLabels() {
        var elm = $('#labels');
        (<any>$(elm)).select2({
            placeholder: "Select one / multiple labels"
        }).change(function () {
            var selectedIDs = $.map((<any>$(elm)).select2('data'), function (val, _i) {
                return val.id;
            }).join(",");
            $('#selectedIDs').text(selectedIDs);
        });
    }
    //__________________________________________________________________________________________
    private _setFormTitleSaveNames(pState: eModalWindowState) {
        let aSetupPermission =
            (eDataPermission.PRIVATE == parseInt(this.mPermissionSelect.value)) ?
                'private' : 'public';
        if (aSetupPermission === 'private') {
            this.mSaveBtn.classList.remove('btn-success')
            this.mSaveBtn.classList.add('btn-primary')
        } else {
            this.mSaveBtn.classList.remove('btn-primary')
            this.mSaveBtn.classList.add('btn-success')
        }
        switch (pState) {
            case eModalWindowState.RENAME:
            case eModalWindowState.SAVE:
                this.mSaveBtn.innerHTML = 'Update';
                this.mTitle.innerHTML = 'Update setup details';
                break;
            case eModalWindowState.DOWNLOAD:
                this.mSaveBtn.innerHTML = 'Download';
                this.mTitle.innerHTML = 'Download setup';
                break;
            case eModalWindowState.COPY:
                this.mSaveBtn.innerHTML = 'Make a copy';
                this.mTitle.innerHTML = 'Copy document';
                break;
            case eModalWindowState.NEW:
                this.mSaveBtn.innerHTML = 'Create ' + aSetupPermission + ' setup';
                this.mTitle.innerHTML = 'New setup';
                if (aSetupPermission === 'private') {
                    this.mSaveBtn.classList.remove('btn-success')
                    this.mSaveBtn.classList.add('btn-primary')
                } else {
                    this.mSaveBtn.classList.remove('btn-primary')
                    this.mSaveBtn.classList.add('btn-success')
                }
                break;
        }
    }
    //__________________________________________________________________________________________
    private _onUpdateForm(pData: iBasicSetupData) {
        for (let name in NewDesignForm.ELEMENTS) {
            let aFillElement = NewDesignForm.ELEMENTS[name];
            let aElement = Op3dUtils.getElementInByAttr(this.mContainer, 'name', name);
            let aValue = this._getValue(pData, aFillElement);
            switch (aFillElement.type) {
                case eFillType.CHECKBOX:
                    (aElement as HTMLInputElement).checked = aValue;
                    break;
                case eFillType.NUMERIC_SELECT:
                    (aElement as HTMLInputElement).value = aValue;
                    break;
                case eFillType.INPUT_NUMBER:
                    (aElement as HTMLInputElement).value = aValue;
                    break;
                case eFillType.TEXT_INPUT:
                    (aElement as HTMLInputElement).value = aValue;
                    if (aFillElement.placeHolder != null) {
                        (aElement as HTMLInputElement).placeholder = aFillElement.placeHolder;
                    }
                    break;
                case eFillType.TEXT:
                    aElement.innerHTML = aValue;
                    break;
                case eFillType.MULTIPLE_SELECT:
                    $(aElement).val(aValue).trigger('change');
                    break;
                default:
                    throw new Error("Wrong type");
            }

            if (true == aFillElement.dispatchChangeEvent) {
                aElement.dispatchEvent(new Event('change'));
            }
        }
    }
    //__________________________________________________________________________________________
    private _getValue(pData: iBasicSetupData, pFillElement: iFillElement) {
        if (null == pData) {
            return this._getDefaultValue(pFillElement);
        }

        if (null != pFillElement.path) {
            let aPath = pFillElement.path.split('.');
            let aVal = pData;
            for (let i = 0; i < aPath.length; i++) {
                aVal = aVal[aPath[i]];
                if (null == aVal) {
                    return this._getDefaultValue(pFillElement);
                }
            }
            return ((null != aVal) ? aVal : this._getDefaultValue(pFillElement));
        } else if (null != pFillElement.function) {
            return pFillElement.function(pData);
        }

        throw new Error("Wrong FillElement! No function or path");
    }
    //__________________________________________________________________________________________
    private _getDefaultValue(pFillElement: iFillElement) {
        if (pFillElement.defaultValue instanceof Function) {
            return pFillElement.defaultValue();
        }

        if (null == pFillElement.defaultValue) {
            switch (pFillElement.type) {
                case eFillType.CHECKBOX:
                    return false;
                case eFillType.TEXT:
                case eFillType.TEXT_INPUT:
                    return '';
                case eFillType.NUMERIC_SELECT:
                case eFillType.INPUT_NUMBER:
                    return 0;
                case eFillType.MULTIPLE_SELECT:
                    return [];
                default:
                    return null;
            }
        }

        return pFillElement.defaultValue;
    }
    //__________________________________________________________________________________________
    private async _saveSetupToCopy(pData: iBasicSetupData) {
        try {
            let aSetupData = Op3dContext.SETUPS_MANAGER.getSetupData();
            let aCopyCurrentSetup = JSON.stringify(aSetupData);

            let aCurrentSetup = JSON.parse(aCopyCurrentSetup) as iUploadSetupData;
            aCurrentSetup._id = null;
            aCurrentSetup.name = pData.parameters.details.setupName;
            aCurrentSetup.parameters.details = pData.parameters.details;
            aCurrentSetup.parameters.previews_permission = pData.permission;
            aCurrentSetup.permission = pData.permission;

            let aImage = SnapshotTools.getSceneSnapshot2(true);
            aCurrentSetup.image = aImage;

            let aRes = await ServerContext.SERVER.updateSetup(aCurrentSetup);
            if (aRes.success) {
                let aNewSetupID = aRes.data;
                localStorage.setItem(Main.OP3D_SETUP_ID, aNewSetupID);
                window.open(window.location.href, '_blank');
            }

            this.close();
        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({
                error: e,
                type: eErrorType.GENERAL
            });
        }
    }
    //__________________________________________________________________________________________
    private _downloadSetup() {
        Op3dContext.SETUPS_MANAGER.downloadOPT();
    }
    //__________________________________________________________________________________________
    private _fillElement(pData: any, pElement: HTMLElement, pFillElement: iFillElement,
        pPath: Array<string>) {
        if (null == pData) {
            return;
        }
        let aKey = pPath.shift();
        if (0 == pPath.length) {
            switch (pFillElement.type) {
                case eFillType.CHECKBOX:
                    pData[aKey] = (pElement as HTMLInputElement).checked;
                    break;
                case eFillType.TEXT_INPUT:
                    let aString = (pElement as HTMLInputElement).value;
                    aString = aString.replaceAll("\n", '');
                    pData[aKey] = aString;
                    if (pFillElement.isRequired && pData[aKey] == "") {
                        this.mEmptyInputErr.push({ element: pElement, errorField: pFillElement.errorFieldName });
                    }
                    break;
                case eFillType.NUMERIC_SELECT:
                case eFillType.INPUT_NUMBER:
                    pData[aKey] = parseFloat((pElement as HTMLInputElement).value.toString());
                    break;
                case eFillType.MULTIPLE_SELECT:
                    let aOptions = (pElement as HTMLSelectElement).selectedOptions;
                    let aArr = new Array<string>();
                    for (let i = 0; i < aOptions.length; i++) {
                        aArr.push(aOptions[i].value);
                    }

                    if (0 == aArr.length) {
                        aArr = this._getDefaultValue(pFillElement);
                    }

                    pData[aKey] = aArr;

                    if (pFillElement.isRequired && aArr.length == 0) {
                        this.mEmptyInputErr.push({ element: pElement, errorField: pFillElement.errorFieldName });
                    }

                    break;
                default:
                    break;
            }

            return;
        }

        if (null == pData[aKey]) {
            pData[aKey] = {};
        }

        this._fillElement(pData[aKey], pElement, pFillElement, pPath);
    }
    //__________________________________________________________________________________________
    private _doBeforeSave() {
        //Protect from illigally change the permission.
        if (eDataPermission.PRIVATE == parseInt(this.mPermissionSelect.value) &&
            (false == Op3dContext.USER_PERMISSION.hasPrivateSetupsPermission)) {
            this.mPermissionSelect.value = (eDataPermission.PUBLIC).toString();
        }
    }
    //__________________________________________________________________________________________
    private _getSetupPermission() {
        if (false == Op3dContext.USER_PERMISSION.hasPrivateSetupsPermission) {
            return eDataPermission.PUBLIC;
        }

        return parseInt(this.mPermissionSelect.value);
    }
    //__________________________________________________________________________________________
    private async _onSave() {
        this.mIsOnSaving = true;
        Spinner.instance.show();
        this._clearInputIsEmptyErrors()
        this._doBeforeSave();

        let aData: iBasicSetupData = {} as any;
        for (let name in NewDesignForm.ELEMENTS) {
            let aFillElement = NewDesignForm.ELEMENTS[name];
            let aElement = Op3dUtils.getElementInByAttr(this.mContainer, 'name', name);
            if (null != aFillElement.path) {
                let aPath = aFillElement.path.split('.');
                this._fillElement(aData, aElement, aFillElement, aPath);
            }
        }
        if (this.mEmptyInputErr.length) {
            Spinner.instance.hide();
            this._inputIsEmptyErrors()
            this.mIsOnSaving = false;
            return;
        } else {
            this._clearInputIsEmptyErrors()
        }

        aData.permission = this._getSetupPermission();
        aData.parameters.settings = SettingsContext.getUserSimulationSettings();

        switch (this.mState) {
            case eModalWindowState.COPY:
                //Open in new window.
                this._saveSetupToCopy(aData);
                break;
            case eModalWindowState.NEW:


                let aIsLabScene = (eSceneType.LAB == parseInt(this.mSceneType.value));
                let aSceneOptions: iSceneOptions = {
                    divisions: { x: 100, y: 100 },
                    isBreadboardVisible: (true == aIsLabScene),
                    isGridVisible: (false == aIsLabScene),
                };

                aData.parameters.sceneOptions = aSceneOptions;

                if (true == this.mIsNew) {
                    Op3dContext.MANAGER.openNewSetup(aData);
                } else {
                    if (aData.parameters.unit == UnitHandler.PRESENTED_UNIT) {
                        //Clear scene & start new

                        EventManager.dispatchEvent(EventsContext.ON_NEW, this)
                        EventManager.dispatchEvent(EventsContext.ON_CLEAR_ALL_PARTS, this);
                        SceneContext.GRID_MANAGER.setSceneOptions(aSceneOptions);

                        Op3dContext.SETUPS_MANAGER.onStartNewDesign(aData, aData.permission);

                        setTimeout(() => Op3dContext.SCENE_HISTORY.saveScene(true), 150);

                    } else {
                        Op3dContext.MANAGER.reloadAndOpenNewSetup(aData);
                    }
                }
                break;
            case eModalWindowState.RENAME:
            case eModalWindowState.SAVE:
                Op3dContext.SCENE_HISTORY.addToHistory();

                Op3dContext.SETUPS_MANAGER.updateSetupDetails(aData.parameters.details,
                    aData.permission);
                await Op3dContext.SETUPS_MANAGER.saveOpt({ showMessage: true });
                break;
            case eModalWindowState.DOWNLOAD:
                Op3dContext.SETUPS_MANAGER.updateSetupDetails(aData.parameters.details,
                    aData.permission);
                this._downloadSetup();
                break;
        }

        if (null != this.mOnFinish) {
            this.mOnFinish();
        }
        Op3dContext.setupIsLoaded = true;
        await this.close();
        try {
            (window as any).userGuiding.launchResourceCenter(2754)
        } catch (e) {
            console.error('UserGuiding launch resource center failed:', e);
        }

        Spinner.instance.hide();
        await Op3dContext.sleep(1000).then(() => {
            this.mIsOnSaving = false;
        });

    }
    //__________________________________________________________________________________________
    private _inputIsEmptyErrors() {
        for (let i = 0; i < this.mEmptyInputErr.length; i++) {
            this.mEmptyInputErr[i].element.setAttribute('required', 'required');
            this.mEmptyInputErr[i].element.setAttribute('invalid', 'invalid');
            // if(i == this.mEmptyInputErr.length - 1){
            //     Popup.instance.open({
            //         text: `${MessagesHandler.NEW_DESIGN_FORM_MISSING_REQUIRED_FIELD}: ${this.mEmptyInputErr[this.mEmptyInputErr.length - 1].errorField}`
            //     });
            // }
        }
    }

    private _clearInputIsEmptyErrors() {
        for (let i = 0; i < this.mEmptyInputErr.length; i++) {
            this.mEmptyInputErr[i].element.removeAttribute('required');
            this.mEmptyInputErr[i].element.removeAttribute('invalid');
        }
        this.mEmptyInputErr = [];
    }
}
