import { eDataPermission } from "../_context/Enums";
import { iNumericKeyHash } from "../_context/_interfaces/Interfaces";
import { FileUtils } from "../_utils/FileUtils";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { SourcesDataLoader } from "../data/data_loader/SourcesDataLoader";
import { LightSourceUtils } from "../simulation/LightSourceUtils";
import { Parser, eManipulationType } from "./Parser";


export interface iSourceVO {
    name: string;
    number_id: string;
    permission: eDataPermission;
    parameters: iSourceVOParams;
}

export interface iSourceVOParams {
    type: eLightSourceType;
    data: iSourceVOData;
    info: iSourceVOInfo;
}

export interface iSourceVOData {
    center_wavelength?: number;
    bandwidth?: number;
    temperature?: number;
    wavelengths?: number[]; // only for custom sources
    wavelengthWights?: number[];// only for custom sources
    spectrum_file?: iSourceVOSpectrumFile;
}

export interface iSourceVOSpectrumFile {
    name: string;
    legalFrom: number;
    legalTo: number;
    spectrum: iNumericKeyHash<number>;
}

export enum eLightSourceType {
    LASER = "Laser",
    HG = "HG",
    LED = "LED",
    WHITE_SOURCE = "White Source",
    BLACKBODY = "Blackbody",
    GAUSSIAN = "Gaussian",
    CUSTOMIZED = "Customized"
}

export interface iSourceVOInfo {
    brand: string;
    validLasers: Array<string>;
    weblink: string;
}

export class SourcesParser extends Parser<iSourceVO> {
    private mFiles: Array<File>;

    constructor(pFileList: FileList, pSpectrumFiles: FileList, pToReplace: boolean) {
        super();

        this._saveFiles(pSpectrumFiles);
        this._init(pFileList, pToReplace);
    }
    //__________________________________________________________________________________________
    private _saveFiles(pSpectrumFiles: FileList,) {
        this.mFiles = new Array<File>();
        for (let i = 0; i < pSpectrumFiles.length; i++) {
            this.mFiles.push(pSpectrumFiles.item(i));
        }
    }
    //__________________________________________________________________________________________
    private async _init(pFileList: FileList, pToReplace: boolean) {
        // let aSpectrumFiles = await this._loadSpectrumFiles(pSpectrumFiles);
        this.load({
            fileList: pFileList,
            dataLoader: SourcesDataLoader.instance,
            replaceExist: pToReplace,
            build: {
                checkValidationFunc: (pData: iSourceVO) =>
                    this._checkSourceVOValidation(pData),
                form: {
                    'brand': {
                        path: 'parameters.info.brand',
                        isRequired: true
                    },
                    'weblink': {
                        path: 'parameters.info.weblink'
                    },
                    'id number': {
                        path: 'number_id',
                        isRequired: true
                    },
                    'name': {
                        path: 'name',
                        isRequired: true
                    },
                    'permission': {
                        path: 'permission',
                        default: eDataPermission.PUBLIC,
                        isRequired: true
                    },
                    'type': {
                        path: 'parameters.type',
                        isRequired: true,
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData: string) => this._getLightSourceType(pData)
                    },
                    'wavelength': {
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pWL: string, pRow) =>
                            this._getCentralWavelength(pWL, pRow),
                        path: 'parameters.data.center_wavelength',
                    },
                    'width': {
                        manipulationType: eManipulationType.PARSE_FLOAT,
                        path: 'parameters.data.bandwidth',
                    },
                    'temperature': {
                        manipulationType: eManipulationType.PARSE_FLOAT,
                        path: 'parameters.data.temperature',
                    },
                    'csv file name': {
                        path: 'parameters.data.spectrum_file',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData: string) =>
                            this._getSourceFile(pData)
                    },
                    'csv source': {
                        path: 'parameters.data.spectrum_file',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData: string) =>
                            this._getSourceFile(pData)
                    },
                    'valid lasers': {
                        path: 'parameters.info.validLasers',
                        manipulationType: eManipulationType.SEMI_COLON_PARSE
                    }
                }
            }
        });
    }
    //__________________________________________________________________________________________
    private async _getCentralWavelength(pWl: string, pRow: any) {
        let aWavelength: number;
        let aType = await this._getLightSourceType(pRow['type']);

        switch (aType) {
            case eLightSourceType.BLACKBODY:
                let aTemperature = parseFloat(pRow['temperature']);
                aWavelength = LightSourceUtils.getBlackbodyPeakWL(aTemperature);
                break;
            case eLightSourceType.GAUSSIAN:
                aWavelength = parseFloat(pWl);
                break;

        }

        return aWavelength;
    }
    //__________________________________________________________________________________________
    private async _getLightSourceType(pData: string) {
        if (null == pData) {
            return null;
        }

        switch (pData.toUpperCase()) {

            case eLightSourceType.BLACKBODY.toUpperCase():
                return eLightSourceType.BLACKBODY;

            case eLightSourceType.CUSTOMIZED.toUpperCase():
                return eLightSourceType.CUSTOMIZED;

            case eLightSourceType.GAUSSIAN.toUpperCase():
                return eLightSourceType.GAUSSIAN;

            case eLightSourceType.HG.toUpperCase():
                return eLightSourceType.HG;

            case eLightSourceType.LASER.toUpperCase():
                return eLightSourceType.LASER;

            case eLightSourceType.LED.toUpperCase():
                return eLightSourceType.LED;

            case eLightSourceType.WHITE_SOURCE.toUpperCase():
                return eLightSourceType.WHITE_SOURCE;

            default:
                return null;
        }
    }
    //__________________________________________________________________________________________
    private async _parseSingleFile(pFileName: string) {
        let aFile = null;

        for (let i = 0; i < this.mFiles.length; i++) {
            let aName = this.mFiles[i].name.replace(".csv", '');
            if (aName.toLowerCase().trim() == pFileName.toLowerCase().trim()) {
                aFile = this.mFiles[i];
                break;
            }
        }

        if (aFile == null) {
            this._log({ msg: "file not found [" + pFileName + " ]", isError: true });
            return null;
        }

        let aRes = await FileUtils.loadFileText(aFile);
        if (aRes.success == false || aRes.data == null) {
            this._log({ msg: "error reading file [" + pFileName + " ]", isError: true });
            return null;
        }

        try {
            let aSpectrumData: iNumericKeyHash<number> = {};
            let aRows = aRes.data.split('\n');
            let aPrevValue = 0;

            for (let i = 1; i < (aRows.length - 1); i++) {
                let aCols = aRows[i].split(',');
                let aCurrIndex = parseFloat(aCols[0]);
                let aCurrValue = parseFloat(aCols[1]);

                if (aCurrValue < 0 || aCurrValue > 1) {
                    throw new Error("illegal spectrum, not all values are between [0-1]");
                }

                if (aCurrValue != aPrevValue) {
                    aSpectrumData[aCurrIndex] = aCurrValue;
                }

                aPrevValue = aCurrValue;
            }

            return aSpectrumData;

        } catch (e) {

            this._log({ msg: "error reading file [" + pFileName + "]", isError: true })
            return null;
        }
    }
    //__________________________________________________________________________________________
    private async _getSourceFile(pCSVName: string) {
        if (null == pCSVName) {
            this._log({ msg: "undefined name [" + pCSVName + "] file", isError: true })
            return null;
        }

        const aSpectrumFile = await this._parseSingleFile(pCSVName);
        if (aSpectrumFile != null) {

            let aKeys = Object.keys(aSpectrumFile);
            let aSpectrumFiles: iSourceVOSpectrumFile = {
                name: pCSVName,
                legalFrom: parseFloat(aKeys[1]),
                legalTo: parseFloat(aKeys[aKeys.length - 1]),
                spectrum: aSpectrumFile
            };

            return aSpectrumFiles;
        }

        return null;
    }
    //__________________________________________________________________________________________
    private async _checkSourceVOValidation(pData: iSourceVO) {
        let aParametersData = pData.parameters.data;
        if (null == aParametersData) {
            return 'No data';
        }

        switch (pData.parameters.type) {
            case eLightSourceType.BLACKBODY:
                if (null == aParametersData.center_wavelength) {
                    return 'No center wavelength for blackbody';
                }
                if (null == aParametersData.temperature) {
                    return 'No temperature for blackbody';
                }
                break;
            case eLightSourceType.GAUSSIAN:
                if (null == aParametersData.bandwidth) {
                    return 'No bandwidth for gaussian';
                }
                if (null == aParametersData.center_wavelength) {
                    return 'No center wavelength for gaussian';
                }
                break;
            case eLightSourceType.LASER:
            case eLightSourceType.LED:
            case eLightSourceType.HG:
            case eLightSourceType.WHITE_SOURCE:

                if ((null == aParametersData.spectrum_file) ||
                    (null == aParametersData.spectrum_file.name) ||
                    (null == aParametersData.spectrum_file.spectrum)) {

                    return 'Invalid spectrum file';
                }

                let aMaxWL = OP3DMathUtils.maxIndex(aParametersData.spectrum_file.spectrum);
                if (null == aMaxWL) {
                    return 'No center wavelength for spectrum';
                }

                aParametersData.center_wavelength = parseFloat(aMaxWL);
                break;
        }

        return null;
    }
    //__________________________________________________________________________________________
}
