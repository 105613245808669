import { Vector3, Material, Mesh, BufferGeometry, Float32BufferAttribute } from "three";
import { MathContext } from "../../_context/MathContext";
import { OpticsContext, eOpticShape } from "../../_context/OpticsContext";
import { iHash, iNumericKeyHash } from "../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { iOddAsphereCreationParams, iGeneralConicParams, iChebyshevCreationParams, iZernikeStandartPhase, iZernikeFringeSag, iConicZernikeParams } from "../../_utils/MatrixUtils";
import { iZernikeDeformation, iSurfaceDeformation, eSurfaceDeformation, iPolynomialDeformation, iOpticsVO } from "../../data/VO/OpticsVOInterfaces";
import { ThreeMaterialUtils } from "./ThreeMaterialUtils";
import { iOpticsReturnData } from "../../_context/_interfaces/ioInterfaces";




export interface iCircularSurfaceParams {
    diameter: number;
    innerRadius: number;
    name: string;
    regularNormals: boolean;
};

export interface iRectSurfaceParams {
    width: number;
    height: number;
    innerRadius: number;
    name: string;
    regularNormals: boolean;
};

export type tZFunction = (pX: number, pY: number) => number;


export class OpticsShapeUtils {

    //__________________________________________________________________________________________
    public static calculateZRoofPrism(pY: number, _pThickness: number) {
        let aZ = Math.abs(pY);// + pThickness;
        return -aZ;
    }
    //__________________________________________________________________________________________
    private static factorial(pVal: number) {
        let aNum = 1;
        for (let i = 1; i < pVal; i++) {
            aNum *= i;
        }

        return aNum;
    }
    //__________________________________________________________________________________________
    private static _QConPolynom(m: number, u: number) {

        let aRes = 0;
        for (let s = 0; s < m; s++) {
            let aMinus1Coef = Math.pow((-1), s);

            let aTop = this.factorial((2 * m) + 4 - s);
            let aSF = this.factorial(s);
            let aM4sF = this.factorial(m + 4 - s);
            let aMsF = this.factorial(m - s);

            let aBottom = aSF * aM4sF * aMsF;
            let pow = 2 * (m - s);
            let u2 = Math.pow(u, pow);


            aRes += aMinus1Coef * (aTop / aBottom) * u2;
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    public static calculateZOddPolynomial(pParams: {
        x: number,
        y: number,
        R: number,
        K: number,
        coefs: iHash<number>,
        radiusWorld: number
    }) {

        let aRadiusMM = pParams.radiusWorld;


        let aC = 1 / pParams.R;
        let aR = Math.sqrt((pParams.x * pParams.x) + (pParams.y * pParams.y));
        let aR2 = Math.pow(aR, 2);
        let aRCurrMM = aR;

        let aTop = aC * aR2;
        let aBottom = 1 + (Math.sqrt(1 - ((1 + pParams.K) * aC * aC * aR2)));
        let aFirstPart = aTop / aBottom;

        let aAddition = 0;
        let aNormalized = aRCurrMM / aRadiusMM;
        for (let i = 1; i <= 30; i++) {
            let aCoeficient = isNaN(pParams.coefs[i - 1]) ? 0 : pParams.coefs[i - 1];
            let aCurrR = Math.pow(aNormalized, i);
            aAddition += (aCoeficient * aCurrR);
        }

        let z = aFirstPart + aAddition;
        return z;
    }
    //__________________________________________________________________________________________
    private static xyPolinomialSigmaFunc(pCoefs: iHash<number>, pX: number, pY: number,
        pNRadiusWorld: number) { // 0 --> 64

        let aIndex = 1;
        let aPowX = aIndex;
        let aPowY = 0;

        let aResult = 0;
        for (let j = 0; j < 64; j++) {

            if (aPowX < 0) {
                aIndex++;
                aPowX = aIndex;
                aPowY = 0;
            }

            let aCMM = isNaN(pCoefs[j]) ? 0 : pCoefs[j];
            //let aSumPow = aPowX + aPowY;

            // let aCMul = Math.pow(pRatio, (aSumPow - 1));
            // if ((aSumPow - 1) != 0) {
            //     aC *= aCMul;
            // }

            // let aInchMul = Math.pow(UnitHandler.INCHES_TO_WORLD, (aSumPow - 1));
            // if ((aSumPow - 1) != 0) {
            //     aC *= aInchMul;
            // }

            aResult += aCMM * Math.pow(pX / pNRadiusWorld, aPowX) * Math.pow(pY / pNRadiusWorld, aPowY);
            //console.log("C" + (j + 2) + ":  X" + aPowX + "Y" + aPowY + "\n");
            aPowX--;
            aPowY++;
        }

        return aResult;
    }
    //__________________________________________________________________________________________
    public static calculateZXYPolynomial(pParams: {
        x: number, y: number,
        R: number, K: number,
        coefs: iHash<number>,
        nRadius: number
    }) {

        //conic
        let aC = 1 / pParams.R;
        let aC2 = Math.pow(aC, 2);
        let aR = Math.sqrt((pParams.x * pParams.x) + (pParams.y * pParams.y));
        let aR2 = Math.pow(aR, 2);
        let aTop = aR2 * aC;
        let aBottom = 1 + (Math.sqrt(1 - ((1 + pParams.K) * aC2 * aR2)));
        let aConic = aTop / aBottom;
        let aSigma = this.xyPolinomialSigmaFunc(pParams.coefs, pParams.x, pParams.y, pParams.nRadius);
        let aZ = aConic + aSigma;

        return aZ;
    }
    //__________________________________________________________________________________________
    public static calculateZQCon(pParams: {
        x: number,
        y: number,
        R: number,
        K: number,
        radius: number,
        coeffs: iNumericKeyHash<number>,

    }) {

        let aR = Math.sqrt((pParams.x * pParams.x) + (pParams.y * pParams.y));
        let aU = aR / pParams.radius;

        let aResSigma = 0;
        for (let m = 0; m < 13; m++) {
            let aQConPolynom = this._QConPolynom(m, aU);
            let aAm = isNaN(pParams.coeffs[m]) ? 0 : pParams.coeffs[m];
            aResSigma += (aAm * aQConPolynom);
        }

        let aU4 = Math.pow(aU, 4);

        let c = pParams.R != 0 ? 1 / pParams.R : 0;
        let k = pParams.K;

        let aZConic = this.calcCircleConicExtended(pParams.x, pParams.y, c, c, k, k);
        let aQConPolynom = aU4 * aResSigma;
        let z = aZConic + aQConPolynom;
        return z;
    }
    //__________________________________________________________________________________________
    public static calculateZAspheric(pParams: {
        x: number,
        y: number,
        K: number,
        R: number,
        coeffs: iNumericKeyHash<number>,

    }) {
        let c = pParams.R != 0 ? 1 / pParams.R : 0;
        let k = pParams.K;

        let aZConic = this.calcCircleConicExtended(pParams.x, pParams.y, c, c, k, k);


        let aR = Math.sqrt((pParams.x * pParams.x) + (pParams.y * pParams.y));
        //let aR2 = Math.pow(aR, 2);

        let aCoeficients = 0;
        for (let index in pParams.coeffs) {
            let aPow = parseInt(index);// / 2;
            let aVal = pParams.coeffs[index] * Math.pow(aR, aPow);
            //let aVal = pParams.coeffs[index] * Math.pow(aR2, aPow);
            aCoeficients += aVal;
        }

        let z = aZConic + aCoeficients;

        return z;
    }
    //__________________________________________________________________________________________
    public static calculateToroid(pParams: {
        val: number, R: number,
        A: number, B: number, C: number, D: number, K: number
    }) {

        let aT2 = pParams.val * pParams.val;
        let aT4 = aT2 * aT2;
        let aT6 = aT2 * aT4;
        let aT8 = aT4 * aT4;
        let aT10 = aT8 * aT2;
        let aC = 1 / pParams.R;
        let aC2 = aC * aC;
        let aTop = aC * aT2;
        let aSqrt = Math.sqrt(1 - ((1 + pParams.K) * (aC2 * aT2)));
        let aBottom = 1 + aSqrt;

        let aPolynom = (pParams.A * aT4) + (pParams.B * aT6) +
            (pParams.C * aT8) + (pParams.D * aT10);
        let aRes = (aTop / aBottom) + aPolynom;
        return aRes;
    }
    //__________________________________________________________________________________________
    public static calcZCircleCylindrical(pParams: {
        x: number, y: number, rx: number, ry: number
    }) {

        let cx = (0 != pParams.rx) ? (1 / pParams.rx) : 0;
        let cy = (0 != pParams.ry) ? (1 / pParams.ry) : 0;
        let z = this.calcCircleConicExtended(pParams.x, pParams.y, cx, cy, 0, 0);

        return z;
    }
    //__________________________________________________________________________________________
    public static calculateZAxicon(pX: number, pY: number,
        pDiameter: number, pHeight: number, pMul: number): number {

        let aR = pDiameter / 2;
        let aX2 = pX * pX;
        let aY2 = pY * pY
        let aSqrtX2Y2 = Math.sqrt(aX2 + aY2);

        let z =/* pHeight -*/ ((pHeight / aR) * aSqrtX2Y2) * pMul;
        return z;
    }
    //__________________________________________________________________________________________
    public static calcOddAsphereZ(x: number, y: number,
        pOddAsphereParams: iOddAsphereCreationParams) {

        let r = Math.hypot(x, y);
        let c = (1 / pOddAsphereParams.R);
        let k = pOddAsphereParams.K;

        let aConicSQRT = Math.sqrt(1 - (1 + k) * Math.pow(c, 2) * Math.pow(r, 2));
        let aConicPart = ((c * Math.pow(r, 2)) / (1 + aConicSQRT));

        let aCoeefsPart = 0;
        for (let coeff in pOddAsphereParams.coefficients) {
            let aCoeff = parseInt(coeff);
            aCoeefsPart += pOddAsphereParams.coefficients[coeff] * Math.pow(r, aCoeff);
        }

        let aZ = aConicPart + aCoeefsPart;
        return aZ;
    }
    //__________________________________________________________________________________________
    public static getGeneralConicPart(x: number, y: number, pConicParams: iGeneralConicParams) {
        let kx = pConicParams.kx;
        let ky = pConicParams.ky;
        let cx = pConicParams.cx;
        let cy = pConicParams.cy;

        let aConicSQRTX = (1 + kx) * Math.pow(cx, 2) * Math.pow(x, 2);
        let aConicSQRTY = (1 + ky) * Math.pow(cy, 2) * Math.pow(y, 2);

        let aConicSQRT = Math.sqrt(1 - (aConicSQRTX + aConicSQRTY));
        let aConicPart = ((cx * Math.pow(x, 2) + (cy * Math.pow(y, 2))) / (1 + aConicSQRT));

        return aConicPart;
    }
    //__________________________________________________________________________________________
    public static calcChebyshevZ(x: number, y: number, pChebyshevParams: iChebyshevCreationParams) {
        let aConicPart = this.getGeneralConicPart(x, y, pChebyshevParams);
        let aCoeefsPart = 0;
        for (let coeffX in pChebyshevParams.coeffs) {
            let aCoeffs = pChebyshevParams.coeffs[coeffX];
            let aTx = this._getChebyshevPolinom(x, parseInt(coeffX));
            for (let coeffY in aCoeffs) {
                let aTy = this._getChebyshevPolinom(y, parseInt(coeffY));
                let aCij = aCoeffs[coeffY];
                aCoeefsPart += (aCij * aTx * aTy);
            }
        }

        let aZ = (aConicPart + aCoeefsPart);
        return aZ;
    }
    //__________________________________________________________________________________________
    public static calciZernikeStandartPhaseZ(x: number, y: number,
        pZernikeFringSag: iZernikeStandartPhase) {
        let M = pZernikeFringSag.M;

        let aZernikePart = this.calcZernikeZ(x, y, pZernikeFringSag);
        let z = (2 * Math.PI * M * aZernikePart);
        return z;
    }
    //__________________________________________________________________________________________
    public static calcZernikeFringeSagZ(x: number, y: number,
        pZernikeFringSag: iZernikeFringeSag) {
        let r = Math.hypot(x, y);
        let aFringeSagPart = 0;
        for (let i = 0; i < pZernikeFringSag.fringeSagCoeffs.length; i++) {
            let aCoeff = pZernikeFringSag.fringeSagCoeffs[i];
            aFringeSagPart += (aCoeff * Math.pow(r, (2 * i)));
        }

        let aZernikePart = this.calcZernikeZ(x, y, pZernikeFringSag);
        let z = (aFringeSagPart + aZernikePart);
        return z;
    }
    //__________________________________________________________________________________________
    public static calcZernikeZ(x: number, y: number, pConicZernikeParams: iConicZernikeParams) {
        let aConicPart = this.getGeneralConicPart(x, y, pConicZernikeParams);
        let aZernikePart = this.getZernikePart(x, y, pConicZernikeParams);

        let z = (aConicPart + aZernikePart);
        return z;
    }
    //__________________________________________________________________________________________
    private static getZernikePart(x: number, y: number, pZernikeParams: iZernikeDeformation) {
        let Ro = (Math.hypot(x, y) / pZernikeParams.normalization_radius);
        let aPhi = (0 == x) ? (Math.PI / 2) : Math.atan2(y, x);
        let aCoeffs = pZernikeParams.coeffs;

        let aRes = 0;
        for (let j in aCoeffs) {
            if (0 == aCoeffs[parseInt(j)]) {
                continue;
            }

            let nm = this.getZernikeNMByIndex(parseInt(j));
            let aTo = ((nm.n - Math.abs(nm.m)) / 2);

            let aZernikeSumPart = 0;
            for (let k = 0; k <= aTo; k++) {
                let aNumerator = (0 == (k % 2)) ? 1 : -1;
                aNumerator *= OP3DMathUtils.factorial(nm.n - k);

                let aDenominator = OP3DMathUtils.factorial(k);
                aDenominator *= OP3DMathUtils.factorial(((nm.n + nm.m) / 2) - k);
                aDenominator *= OP3DMathUtils.factorial(((nm.n - nm.m) / 2) - k);

                let aFrac = (aNumerator / aDenominator);
                aZernikeSumPart += (aFrac * Math.pow(Ro, (nm.n - (2 * k))));
            }


            let aAngle = (Math.abs(nm.m) * aPhi);
            let aZernikeAnglePart = (nm.m >= 0) ? Math.cos(aAngle) : Math.sin(aAngle);
            let aN = Math.sqrt(((2 * nm.n) + 2) / (1 + OP3DMathUtils.KroneckerDelta(nm.m, 0)));

            aRes += (aCoeffs[j] * aN * aZernikeSumPart * aZernikeAnglePart);
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    public static getZernikeNMByIndex(pIndex: number) {
        let n = Math.ceil((-3 + Math.sqrt(9 + (8 * pIndex))) / 2);
        let m = ((2 * pIndex) - n * (n + 2));

        return {
            n: n,
            m: m
        };
    }
    //__________________________________________________________________________________________
    private static _getChebyshevPolinom(x: number, pOrder: number) {
        if (0 == pOrder) {
            return 1;
        }

        if (1 == pOrder) {
            return x;
        }

        return this._getChebyshevPolinomRec(x, x, 1, pOrder, 2);
    }
    //__________________________________________________________________________________________
    private static _getChebyshevPolinomRec(x: number, Txn: number, TxnMinus1: number,
        pOrder: number, pCurrentOrder: number) {

        let aTXNPlus1 = (2 * x * Txn - TxnMinus1);
        if (pCurrentOrder == pOrder) {
            return aTXNPlus1;
        }

        return this._getChebyshevPolinomRec(x, aTXNPlus1, Txn, pOrder, ++pCurrentOrder);
    }
    //__________________________________________________________________________________________
    public static calcZCircleConic(pX: number, pY: number,
        pR: number, pK: number): number {

        // let aC = 1 / pR;
        // let aC2 = Math.pow(aC, 2);
        // let aR = Math.sqrt((pX * pX) + (pY * pY));
        // let aR2 = Math.pow(aR, 2);

        // let aTop = aC * aR2;
        // let aBottom = 1 + Math.sqrt(1 - (aC2 * aR2));
        // let z = (aTop / aBottom);
        let c = pR != 0 ? 1 / pR : 0;

        let z = this.calcCircleConicExtended(pX, pY, c, c, pK, pK);
        return z;
    }
    //__________________________________________________________________________________________
    public static calcZCircleConic2(pX: number, pY: number,
        pR: number, _pK: number, _pOffsetY: number): number {





        let aC = 1 / pR;
        let aC2 = Math.pow(aC, 2);

        let r = Math.hypot(pX, pY);
        let r_pow_2 = Math.pow(r, 2);


        let aNumerator = (aC * r_pow_2);
        let aDenominator = (1 + Math.sqrt(1 - (aC2 * r_pow_2)));
        let z = (aNumerator / aDenominator);

        //let z = this.calcCircleConicExtended(pX, pY, pR, pR, pK, pK);
        return z;
    }
    //__________________________________________________________________________________________
    public static addDeformations(x: number, y: number,
        pDeformations: Array<iSurfaceDeformation>) {
        if (null == pDeformations) {
            return 0;
        }

        let aZ = 0;
        for (let i = 0; i < pDeformations.length; i++) {
            let aDeformation = pDeformations[i];
            switch (aDeformation.type) {
                case eSurfaceDeformation.ZERNIKE_STANDARD_SAG:
                    break;
                case eSurfaceDeformation.ZERNIKE_FRINGE_SAG:
                    aZ += this._getZernikeFringeSag(x, y, aDeformation.params);
                    break;
                case eSurfaceDeformation.ZERNIKE_STANDARD_PHAZE:
                case eSurfaceDeformation.ZERNIKE_FRINGE_PHAZE:
                case eSurfaceDeformation.ZERNIKE_ANNULAR_PHAZE:
                case eSurfaceDeformation.JONES_MATRIX:
                    break;
                case eSurfaceDeformation.ASPHERE:
                case eSurfaceDeformation.EVEN_ASPHERE:
                case eSurfaceDeformation.ODD_ASPHERE:
                    aZ += this._getPolynomialAddition(x, y, aDeformation.params);
                    break;
                default:
                    throw new Error("Not implemented deformation");
            }
        }

        return aZ;
    }
    //__________________________________________________________________________________________
    private static _getPolynomialAddition(x: number, y: number,
        polynomial: iPolynomialDeformation) {

        if ((null == polynomial) || (null == polynomial.terms)) {
            return;
        }

        let aZ = 0;
        let aTerms = polynomial.terms;
        for (let i = 0; i < aTerms.length; i++) {
            let aIndexX = aTerms[i][0];
            let aIndexY = aTerms[i][1];
            let aCoeff = aTerms[i][2];
            aZ += aCoeff * (Math.pow(x, aIndexX) + Math.pow(y, aIndexY));
        }

        return aZ;
    }
    //__________________________________________________________________________________________
    private static _getZernikeFringeSag(x: number, y: number, pZernike: iZernikeDeformation) {
        return this.getZernikePart(x, y, pZernike);
    }
    //__________________________________________________________________________________________
    public static calcZCircleSpherical(pX: number, pY: number,
        pR: number): number {

        // let aC = 1 / pR;
        // let aC2 = Math.pow(aC, 2);
        // let aR = Math.sqrt((pX * pX) + (pY * pY));
        // let aR2 = Math.pow(aR, 2);

        // let aTop = aC * aR2;
        // let aBottom = 1 + Math.sqrt(1 - (aC2 * aR2));
        // let z = (aTop / aBottom);


        let c = pR != 0 ? 1 / pR : 0;

        let z = this.calcCircleConicExtended(pX, pY, c, c, 0, 0);
        return z;
    }
    //__________________________________________________________________________________________
    public static calcCircleConicExtended(pX: number, pY: number, pCx: number, pCy: number,
        pKx: number, pKy: number, pDeformation?: Array<iSurfaceDeformation>) {

        let aCx = pCx;
        let aCx2 = aCx * aCx;
        let aCy = pCy;
        let aCy2 = aCy * aCy;

        let aX2 = pX * pX;
        let aY2 = pY * pY;
        let aTop = (aCx * aX2) + (aCy * aY2);
        let aBottom = 1 + Math.sqrt(1 - ((1 + pKx) * aCx2 * aX2) - ((1 + pKy) * aCy2 * aY2));


        let z = aTop / aBottom;
        if (null != pDeformation) {
            z += this.addDeformations(pX, pY, pDeformation)
        }

        return z;
    }
    //__________________________________________________________________________________________
    public static getConicNormal(x: number, y: number, cx: number, cy: number,
        kx: number, ky: number) {

        let cx2 = (cx * cx);
        let cy2 = (cy * cy);
        let x2 = (x * x);
        let y2 = (y * y);


        let C = Math.sqrt(1 - ((1 + kx) * cx2 * x2 + (1 + ky) * cy2 * y2));

        let N = (cx * x2 + cy * y2);
        let D = (1 + C);
        let D2 = (D * D);

        let Nx = (2 * cx * x);
        let Ny = (2 * cy * y);
        let Dx = -(((1 + kx) * cx2 * x) / C);
        let Dy = -(((1 + ky) * cy2 * y) / C);


        let nx = ((Nx * D - N * Dx) / D2);
        let ny = ((Ny * D - N * Dy) / D2);
        let nz = -1;

        let n = new Vector3(nx, ny, nz).normalize();

        return n;
    }

    //__________________________________________________________________________________________
    // private static _createGeo(): Geometry {
    //     let aGeo = new Geometry();
    //     aGeo.colorsNeedUpdate = true;
    //     aGeo.elementsNeedUpdate = true;
    //     aGeo.normalsNeedUpdate = true;
    //     return aGeo;
    // }
    //__________________________________________________________________________________________
    private static _findNearestIndex(pCurrentPoint: Vector3,
        pArrToSort: Array<Vector3>) {
        let aNearestDistSquared = Number.POSITIVE_INFINITY;
        let aNearestIndex: number;
        for (let i = 0; i < pArrToSort.length; i++) {
            let aPoint = pArrToSort[i];
            let aDist = (pCurrentPoint.x - aPoint.x) * (pCurrentPoint.x - aPoint.x)
                + (pCurrentPoint.y - aPoint.y) * (pCurrentPoint.y - aPoint.y);
            if (aDist < aNearestDistSquared) {
                aNearestDistSquared = aDist;
                aNearestIndex = i;
            }
        }

        return aNearestIndex;
    }
    //__________________________________________________________________________________________
    private static _orderListByPath(pArr: Array<Vector3>, pFirstIndex: number = 0) {
        let orderedList = new Array<Vector3>();
        let aFirstPoint = pArr.splice(pFirstIndex, 1)[0];
        orderedList.push(aFirstPoint); //Arbitrary starting point

        while (pArr.length > 0) {
            //Find the index of the closest point (using another method)
            let nearestIndex = this._findNearestIndex(orderedList[orderedList.length - 1],
                pArr);
            //Remove from the unorderedList and add to the ordered one
            orderedList.push(...pArr.splice(nearestIndex, 1));
        }

        return orderedList;
    }
    //__________________________________________________________________________________________
    private static _getNearestIndex(pArray: Array<Vector3>, pPoint: Vector3) {
        try {

            let aPoint = pPoint.clone();
            aPoint.z = 0;
            let aFirstPoint = pArray[0].clone();
            aFirstPoint.z = 0;
            let aMinDist = aFirstPoint.distanceTo(aPoint.clone());
            let aMinDistIndex = 0

            for (let i = 1; i < pArray.length; i++) {
                let aPointCurr = pArray[i].clone();
                aPointCurr.z = 0;
                let aCurrDist = aPointCurr.distanceTo(aPoint.clone());
                if (aMinDist > aCurrDist) {
                    aMinDist = aCurrDist;
                    aMinDistIndex = i;
                }
            }

            return aMinDistIndex;

        } catch (error) {
            return 0;
        }
    }
    //__________________________________________________________________________________________
    // public static createRectCylinderFromArrays(
    //     pVertices1: Array<Vector3>,
    //     pVertices2: Array<Vector3>) {


    //     let aIndexOfFirstPointSecondArray = this._getNearestIndex(pVertices2, pVertices1[0]);

    //     let aOrderedList1 = pVertices1;//this._orderListByPath(pVertices1);
    //     let aOrderedList2 = pVertices2;//this._orderListByPath(pVertices2, aIndexOfFirstPointSecondArray);

    //     let aLongArr = aOrderedList1.length > aOrderedList2.length ?
    //         aOrderedList1 : aOrderedList2;

    //     let aShortArr = aOrderedList2.length < aOrderedList1.length ?
    //         aOrderedList2 : aOrderedList1;

    //     let i_long_index = 0;
    //     let j_short_index = 0;

    //     let aDirectionLong = Math.sign(aLongArr[1].y - aLongArr[0].y);
    //     let aDirectionShort = Math.sign(aShortArr[1].y - aShortArr[0].y);

    //     if (aDirectionLong < 0) {
    //         aLongArr = aLongArr.reverse();
    //     }

    //     if (aDirectionShort < 0) {
    //         aShortArr = aShortArr.reverse();
    //     }

    //     let aDeltaShort = aShortArr.length / aLongArr.length;
    //     let aDeltaLong = 1

    //     Math.sign(aOrderedList1[1].y - aOrderedList1[0].y)
    //     let aVertices = new Array<number>();
    //     while (i_long_index < aLongArr.length - 1 || j_short_index < aShortArr.length - 1) {
    //         let aCurrentI = i_long_index;
    //         let aCurrentJ = Math.round(j_short_index);

    //         if (i_long_index > aLongArr.length - 1) {
    //             aCurrentI = 0;
    //         }

    //         if (j_short_index > aShortArr.length - 1) {
    //             aCurrentJ = 0;
    //         }

    //         aVertices.push(aShortArr[aCurrentJ].x, aShortArr[aCurrentJ].y, aShortArr[aCurrentJ].z);
    //         aVertices.push(aLongArr[aCurrentI + 1].x, aLongArr[aCurrentI + 1].y, aLongArr[aCurrentI + 1].z);
    //         aVertices.push(aLongArr[aCurrentI].x, aLongArr[aCurrentI].y, aLongArr[aCurrentI].z);

    //         aVertices.push(aShortArr[aCurrentJ + 1].x, aShortArr[aCurrentJ + 1].y, aShortArr[aCurrentJ + 1].z);
    //         aVertices.push(aLongArr[aCurrentI + 1].x, aLongArr[aCurrentI + 1].y, aLongArr[aCurrentI + 1].z);
    //         aVertices.push(aShortArr[aCurrentJ].x, aShortArr[aCurrentJ].y, aShortArr[aCurrentJ].z);

    //         i_long_index += aDeltaLong;
    //         j_short_index += aDeltaShort;
    //     }

    //     let J_plus_1 = new Vector3(aVertices[0], aVertices[1], aVertices[2]);
    //     let I_plus_1 = new Vector3(aVertices[6], aVertices[7], aVertices[8]);
    //     let I = new Vector3(aVertices[aVertices.length - 6],
    //         aVertices[aVertices.length - 5], aVertices[aVertices.length - 4]);
    //     let J = new Vector3(aVertices[aVertices.length - 3],
    //         aVertices[aVertices.length - 2], aVertices[aVertices.length - 1]);

    //     aVertices.push(J.x, J.y, J.z);
    //     aVertices.push(I_plus_1.x, I_plus_1.y, I_plus_1.z);
    //     aVertices.push(I.x, I.y, I.z);

    //     aVertices.push(J_plus_1.x, J_plus_1.y, J_plus_1.z);
    //     aVertices.push(I_plus_1.x, I_plus_1.y, I_plus_1.z);
    //     aVertices.push(J.x, J.y, J.z);

    //     return aVertices;
    // }
    //__________________________________________________________________________________________
    // private static isClockwise(pVec1: Vector3, pVec2: Vector3) {
    //     let aCross = pVec1.cross(pVec2);
    //     // let dot = pVec1.x * pVec2.x + pVec1.y * pVec2.y + pVec1.z * pVec2.z;
    //     // let lenSq1 = pVec1.x * pVec1.x + pVec1.y * pVec1.y + pVec1.z * pVec1.z
    //     // let lenSq2 = pVec2.x * pVec2.x + pVec2.y * pVec2.y + pVec2.z * pVec2.z;
    //     // let angle = Math.acos(dot / Math.sqrt(lenSq1 * lenSq2));
    //     return aDot;
    // }
    //__________________________________________________________________________________________
    public static createRectangularCylinderFromArrays(pVertices1: Array<Vector3>,
        pVertices2: Array<Vector3>) {

        let aIndexOfFirstPointSecondArray = this._getNearestIndex(pVertices2, pVertices1[0]);

        let aOrderedList1 = this._orderListByPath(pVertices1);
        let aOrderedList2 = this._orderListByPath(pVertices2, aIndexOfFirstPointSecondArray);

        let aLongArr = aOrderedList1.length > aOrderedList2.length ?
            aOrderedList1 : aOrderedList2;

        let aShortArr = aOrderedList2.length < aOrderedList1.length ?
            aOrderedList2 : aOrderedList1;

        let i_long_index = 0;
        let j_short_index = 0;

        let aCross1 = new Vector3().crossVectors(aLongArr[0], aLongArr[1]);
        let aCross2 = new Vector3().crossVectors(aShortArr[0], aShortArr[1]);
        let aDot = aCross1.dot(aCross2);
        // let aDirectionLong = Math.sign(aLongArr[1].y - aLongArr[0].y);
        // let aDirectionShort = Math.sign(aShortArr[1].y - aShortArr[0].y);

        if (aDot < 0) {
            aShortArr = aShortArr.reverse();
        }

        aIndexOfFirstPointSecondArray = this._getNearestIndex(aShortArr, aLongArr[0]);
        aLongArr = this._orderListByPath(aLongArr);
        aShortArr = this._orderListByPath(aShortArr, aIndexOfFirstPointSecondArray);//, aIndexOfFirstPointSecondArray);

        //aCross2 = aShortArr[0].clone().cross(aShortArr[1]);
        //aDot = aCross1.dot(aCross2);

        // if (aDirectionShort < 0) {
        //     aShortArr = aShortArr.reverse();
        // }

        let aDeltaShort = aShortArr.length / aLongArr.length;
        let aDeltaLong = 1;

        let aVertices = new Array<number>();
        while (i_long_index < aLongArr.length - 1 || j_short_index < aShortArr.length - 1) {
            let aCurrentI = i_long_index;
            let aCurrentJ = Math.round(j_short_index);

            if (i_long_index > aLongArr.length - 1) {
                aCurrentI = 0;
            }

            if (j_short_index > aShortArr.length - 1) {
                aCurrentJ = 0;
            }

            aVertices.push(aShortArr[aCurrentJ].x, aShortArr[aCurrentJ].y, aShortArr[aCurrentJ].z);
            aVertices.push(aLongArr[aCurrentI + 1].x, aLongArr[aCurrentI + 1].y, aLongArr[aCurrentI + 1].z);
            aVertices.push(aLongArr[aCurrentI].x, aLongArr[aCurrentI].y, aLongArr[aCurrentI].z);

            aVertices.push(aShortArr[aCurrentJ + 1].x, aShortArr[aCurrentJ + 1].y, aShortArr[aCurrentJ + 1].z);
            aVertices.push(aLongArr[aCurrentI + 1].x, aLongArr[aCurrentI + 1].y, aLongArr[aCurrentI + 1].z);
            aVertices.push(aShortArr[aCurrentJ].x, aShortArr[aCurrentJ].y, aShortArr[aCurrentJ].z);

            i_long_index += aDeltaLong;
            j_short_index += aDeltaShort;
        }

        let J_plus_1 = new Vector3(aVertices[0], aVertices[1], aVertices[2]);
        let I_plus_1 = new Vector3(aVertices[6], aVertices[7], aVertices[8]);
        let I = new Vector3(aVertices[aVertices.length - 6],
            aVertices[aVertices.length - 5], aVertices[aVertices.length - 4]);
        let J = new Vector3(aVertices[aVertices.length - 3],
            aVertices[aVertices.length - 2], aVertices[aVertices.length - 1]);

        aVertices.push(J.x, J.y, J.z);
        aVertices.push(I_plus_1.x, I_plus_1.y, I_plus_1.z);
        aVertices.push(I.x, I.y, I.z);

        aVertices.push(J_plus_1.x, J_plus_1.y, J_plus_1.z);
        aVertices.push(I_plus_1.x, I_plus_1.y, I_plus_1.z);
        aVertices.push(J.x, J.y, J.z);

        return aVertices;
    }
    //__________________________________________________________________________________________
    public static createCylinderFromArrays(
        pVertices1: Array<Vector3>,
        pVertices2: Array<Vector3>) {


        let aIndexOfFirstPointSecondArray = this._getNearestIndex(pVertices2, pVertices1[0]);
        let aOrderedList1 = this._orderListByPath(pVertices1);
        let aOrderedList2 = this._orderListByPath(pVertices2, aIndexOfFirstPointSecondArray);

        let aLongArr = aOrderedList1.length > aOrderedList2.length ?
            aOrderedList1 : aOrderedList2;

        let aShortArr = aOrderedList2.length < aOrderedList1.length ?
            aOrderedList2 : aOrderedList1;

        let aCross1 = new Vector3().crossVectors(aLongArr[0], aLongArr[1]);
        let aCross2 = new Vector3().crossVectors(aShortArr[0], aShortArr[1]);
        let aDot = aCross1.dot(aCross2);

        if (aDot < 0) {
            aShortArr = aShortArr.reverse();
        }

        aIndexOfFirstPointSecondArray = this._getNearestIndex(aShortArr, aLongArr[0]);
        aLongArr = this._orderListByPath(aLongArr);
        aShortArr = this._orderListByPath(aShortArr, aIndexOfFirstPointSecondArray);

        let i_long_index = 0;
        let j_short_index = 0;

        let aVertices = new Array<number>();
        while (i_long_index < aLongArr.length - 1 || j_short_index < aShortArr.length - 1) {
            let aCurrentI = i_long_index;
            let aCurrentJ = j_short_index;

            if (i_long_index == j_short_index || j_short_index == aShortArr.length - 1) {
                aVertices.push(aShortArr[aCurrentJ].x, aShortArr[aCurrentJ].y, aShortArr[aCurrentJ].z);
                aVertices.push(aLongArr[aCurrentI + 1].x, aLongArr[aCurrentI + 1].y, aLongArr[aCurrentI + 1].z);
                aVertices.push(aLongArr[aCurrentI].x, aLongArr[aCurrentI].y, aLongArr[aCurrentI].z);
                i_long_index++;

            } else if (i_long_index > j_short_index) {

                // before was j+1,i+1,j
                aVertices.push(aShortArr[aCurrentJ + 1].x, aShortArr[aCurrentJ + 1].y, aShortArr[aCurrentJ + 1].z);
                aVertices.push(aLongArr[aCurrentI].x, aLongArr[aCurrentI].y, aLongArr[aCurrentI].z);
                aVertices.push(aShortArr[aCurrentJ].x, aShortArr[aCurrentJ].y, aShortArr[aCurrentJ].z);
                j_short_index++;
            }

        }

        let J_plus_1 = new Vector3(aShortArr[0].x, aShortArr[0].y, aShortArr[0].z);
        let J = new Vector3(aShortArr[aShortArr.length - 1].x,
            aShortArr[aShortArr.length - 1].y, aShortArr[aShortArr.length - 1].z);

        let I_plus_1 = new Vector3(aLongArr[0].x, aLongArr[0].y, aLongArr[0].z);

        let I = new Vector3(aLongArr[aLongArr.length - 1].x,
            aLongArr[aLongArr.length - 1].y, aLongArr[aLongArr.length - 1].z);


        aVertices.push(J.x, J.y, J.z);
        aVertices.push(I_plus_1.x, I_plus_1.y, I_plus_1.z);
        aVertices.push(I.x, I.y, I.z);

        aVertices.push(J_plus_1.x, J_plus_1.y, J_plus_1.z);
        aVertices.push(I_plus_1.x, I_plus_1.y, I_plus_1.z);
        aVertices.push(J.x, J.y, J.z);

        return aVertices;
    }
    //__________________________________________________________________________________________
    public static createThinRectangularSurface2(pData) {
        let aPoints = new Array<Vector3>();
        let aTopY = -pData.height / 2;
        let aBottomY = pData.height / 2;
        let aLeftX = -pData.width / 2;
        let aRightX = pData.width / 2;

        aPoints.push(new Vector3(aLeftX, aTopY, 0));
        aPoints.push(new Vector3(aRightX, aTopY, 0));
        aPoints.push(new Vector3(aRightX, aBottomY, 0));
        aPoints.push(new Vector3(aLeftX, aBottomY, 0));

        let aVertices = new Array<number>();
        if (pData.regularNormals) {
            aVertices.push(aPoints[0].x, aPoints[0].y, aPoints[0].z);
            aVertices.push(aPoints[1].x, aPoints[1].y, aPoints[1].z);
            aVertices.push(aPoints[3].x, aPoints[3].y, aPoints[3].z);

            aVertices.push(aPoints[3].x, aPoints[3].y, aPoints[3].z);
            aVertices.push(aPoints[1].x, aPoints[1].y, aPoints[1].z);
            aVertices.push(aPoints[2].x, aPoints[2].y, aPoints[2].z);
        } else {
            aVertices.push(aPoints[0].x, aPoints[0].y, aPoints[0].z);
            aVertices.push(aPoints[3].x, aPoints[3].y, aPoints[3].z);
            aVertices.push(aPoints[1].x, aPoints[1].y, aPoints[1].z);

            aVertices.push(aPoints[1].x, aPoints[1].y, aPoints[1].z);
            aVertices.push(aPoints[3].x, aPoints[3].y, aPoints[3].z);
            aVertices.push(aPoints[2].x, aPoints[2].y, aPoints[2].z);
        }

        let aMaterial = ThreeMaterialUtils.createMat();
        let aMesh = OpticsShapeUtils.createMeshNew(aMaterial, aVertices);

        let aOpticsReturnData: iOpticsReturnData = {
            shape: aMesh
        }

        return aOpticsReturnData;
    }
    //__________________________________________________________________________________________
    public static createMeshNew(pMaterial: Material, pVertices: Array<number>) {
        let aGeo = new BufferGeometry();
        aGeo.setAttribute('position', new Float32BufferAttribute(pVertices, 3));

        let aClone = new Array<number>()
        for (let i = 0; i < pVertices.length; i++) {
            if ((i + 1) % 3 != 0) {
                aClone.push(pVertices[i]);
            }

        }

        let aSum = 0;
        aClone.forEach(item => aSum += Math.abs(item));
        aClone = aClone.map(item => item /= aSum);

        for (let i = 0; i < aClone.length; i++) {



            if (aClone[i] < 0) {
                aClone[i] = 0;
            } else if (aClone[i] > 0) {
                aClone[i] = 1;
            }

        }

        aGeo.setAttribute("uv", new Float32BufferAttribute(aClone, 2))
        aGeo.computeVertexNormals();
        let aMesh = new Mesh(aGeo, pMaterial);

        return aMesh;
    }
    //__________________________________________________________________________________________
    public static createRectangularSurface(pData: iRectSurfaceParams, pZFunction: tZFunction) {
        let aEdgeVertices = new Array();
        let aGeo = new BufferGeometry();
        let aGridSize = 90;
        let aWidth = pData.width / 2;
        let aHeight = pData.height / 2;


        let aVertices = new Array<number>();

        let aStrips = new Array<Array<Vector3>>();


        for (let i = 0; i < aGridSize; i++) {
            aStrips[i] = new Array<Vector3>();

            let aX = (i / aGridSize) * aWidth;
            for (let j = 0; j < aGridSize; j++) {
                let aY = (j / aGridSize) * aHeight;
                let aZ = pZFunction(aX, aY);
                let aPoint = new Vector3(aX, aY, aZ);
                aStrips[i].push(aPoint);
                if (0 == i) {
                    aEdgeVertices.push(aPoint);
                }
            }
        }

        for (let strip = 0; strip < aStrips.length - 1; strip++) {
            const aCurrStrip = aStrips[strip];
            const aNextStrip = aStrips[strip + 1];
            if (0 == aNextStrip.length) {
                break;
            }

            let aLength = Math.max(aCurrStrip.length, aNextStrip.length);
            for (let i = 0; i < aLength - 1; i++) {

                let aArr1Index = aCurrStrip[i] != null ? i : 0;
                let aPoint1 = aCurrStrip[aArr1Index];
                let aPoint2 = aCurrStrip[aArr1Index + 1] != null ?
                    aCurrStrip[aArr1Index + 1] :
                    aCurrStrip[0];

                let aArr2Index = aNextStrip[i] != null ? i : 0;
                let aPoint3 = aNextStrip[aArr2Index];
                let aPoint4 = aNextStrip[aArr2Index + 1] != null ?
                    aNextStrip[aArr2Index + 1] :
                    aNextStrip[0];

                if (pData.regularNormals) {
                    aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
                    aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                    aVertices.push(aPoint1.x, aPoint1.y, aPoint1.z);

                    aVertices.push(aPoint4.x, aPoint4.y, aPoint4.z);
                    aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                    aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);

                } else {
                    aVertices.push(aPoint1.x, aPoint1.y, aPoint1.z);
                    aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                    aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);

                    aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
                    aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                    aVertices.push(aPoint4.x, aPoint4.y, aPoint4.z);
                }
            }
        }

        aEdgeVertices.pop();
        aGeo.setAttribute('position', new Float32BufferAttribute(aVertices, 3));
        let aMat = ThreeMaterialUtils.createMat();
        let aMesh = new Mesh(aGeo, aMat);

        aGeo.computeVertexNormals();
        aMesh.name = pData.name;

        let aOpticsReturnData: iOpticsReturnData = {
            shape: aMesh,
            edgeVertices: aEdgeVertices
        };

        return aOpticsReturnData;
    }
    //__________________________________________________________________________________________
    public static getRotatedCircleVertices(R: number, angle: number, zDelta: number) {
        let aVertices = new Array<number>();
        let aPoints = new Array<number>();

        let aCosAngle = Math.cos(angle);
        let aSinAngle = Math.sin(angle);

        for (let alpha = 0; alpha <= 360; alpha++) {
            let aRad = alpha * MathContext.DEG_TO_RAD;

            let x = R * Math.cos(aRad);
            let y = R * Math.sin(aRad) * aCosAngle;
            let z = R * Math.sin(aRad) * aSinAngle;
            aPoints.push(x, y, z);
        }

        for (let i = 0; i < (aPoints.length - 3); i += 3) {
            aVertices.push(0, 0, zDelta);
            aVertices.push(aPoints[i + 3], aPoints[i + 4], zDelta + aPoints[i + 5]);
            aVertices.push(aPoints[i], aPoints[i + 1], zDelta + aPoints[i + 2]);
        }

        return aVertices;
    }
    //__________________________________________________________________________________________
    public static getSubtype(pOpticsVO: iOpticsVO): string {
        const aShape = pOpticsVO.parameters.shape;
        switch (aShape) {
            case eOpticShape.SPHERICAL: {
                const aR1 = pOpticsVO.parameters.geometry.r1;
                const aR2 = pOpticsVO.parameters.geometry.r2;
                const aType = pOpticsVO.parameters.type;
                return OpticsContext.getSphericalSubtype(aR1, aR2, aType)
            }
            case eOpticShape.CYLINDRICAL: {
                const aR1X = pOpticsVO.parameters.geometry.r1_x;
                const aR1Y = pOpticsVO.parameters.geometry.r1_y;
                const aType = pOpticsVO.parameters.type;

                return OpticsContext.getCylindricalSubtype(aR1X, aR1Y, aType);
            }
            default:
                return pOpticsVO.parameters.subType;
        }
    }
    //__________________________________________________________________________________________
    public static getHalfCircleVertices(pParams: { center_height: number, alpha_deg: number, radius: number, thickness: number }) {
        let aVertices = new Array<number>();
        let aPointsXY = new Array<number>();
        let aEdgeVertices = new Array<Vector3>();

        let aSteps = parseInt(pParams.alpha_deg.toString()) / 2;
        let aDeltaStep = pParams.alpha_deg / aSteps;
        let x: number, y: number;

        let aStart = (180 - pParams.alpha_deg) / 2;
        for (let alpha = aStart; alpha <= pParams.alpha_deg + aStart; alpha += aDeltaStep) {
            x = pParams.radius * Math.cos(-alpha * MathContext.DEG_TO_RAD);
            y = pParams.radius * Math.sin(-alpha * MathContext.DEG_TO_RAD);

            aPointsXY.push(x, y);
            aEdgeVertices.push(new Vector3(x, y, 0));

        }
        //last point
        aEdgeVertices.push(new Vector3(x, y, 0));


        //aEdgeVertices.pop();

        for (let i = 0; i < (aPointsXY.length - 2); i += 2) {
            //aVertices.push(0, 0, 0);
            aVertices.push(0, pParams.center_height, 0);
            aVertices.push(aPointsXY[i + 2], aPointsXY[i + 3], 0);
            aVertices.push(aPointsXY[i], aPointsXY[i + 1], 0);
        }

        return { edgeVertices: aEdgeVertices, vertices: aVertices };
    }
    //__________________________________________________________________________________________
    public static getCircleVertices(R: number) {
        let aVertices = new Array<number>();
        let aPointsXY = new Array<number>();
        let aEdgeVertices = new Array<Vector3>();

        for (let alpha = 0; alpha <= 360; alpha++) {
            let aRad = alpha * MathContext.DEG_TO_RAD;

            let x = R * Math.cos(aRad);
            let y = R * Math.sin(aRad);
            aPointsXY.push(x, y);
            aEdgeVertices.push(new Vector3(x, y, 0));
        }

        // we would like to have only 360 edge vertices
        aEdgeVertices.pop();

        for (let i = 0; i < (aPointsXY.length - 2); i += 2) {
            aVertices.push(0, 0, 0);
            aVertices.push(aPointsXY[i + 2], aPointsXY[i + 3], 0);
            aVertices.push(aPointsXY[i], aPointsXY[i + 1], 0);
        }

        return { edgeVertices: aEdgeVertices, vertices: aVertices };
    }
    //__________________________________________________________________________________________
    public static getBufferRectangular(pZFunction: tZFunction,
        pParams: {
            width: number,
            height: number,
            width_segments: number
            height_segments: number
        }) {

        let aVertices = new Array<number>();
        let aEdgeVertices = new Array<Vector3>();
        let aPoints = new Array<Array<Vector3>>();

        let aStartWidth = -pParams.width / 2;
        let aDeltaWidth = pParams.width / (pParams.width_segments);

        let aStartHeight = -pParams.height / 2;
        let aDeltaHeight = pParams.height / (pParams.height_segments);

        for (let i = 0; i <= pParams.width_segments; i++) {
            aPoints[i] = new Array<Vector3>();
            let x = aStartWidth + (i * aDeltaWidth);

            for (let j = 0; j <= pParams.height_segments; j++) {

                let y = aStartHeight + (j * aDeltaHeight);
                let z = pZFunction(x, y);
                let aPoint = new Vector3(x, y, z);
                aPoints[i].push(aPoint);
                if (i == 0 ||
                    j == 0 ||
                    i == pParams.width_segments ||
                    j == pParams.height_segments) {

                    aEdgeVertices.push(aPoint);
                }
            }
        }

        for (let i = 0; i < aPoints.length - 1; i++) {
            const aCurrLine = aPoints[i];
            const aNextLine = aPoints[i + 1];

            for (let i = 0; i < aCurrLine.length - 1; i++) {

                let aPoint1 = aCurrLine[i];
                let aPoint2 = aCurrLine[i + 1];
                let aPoint3 = aNextLine[i];
                let aPoint4 = aNextLine[i + 1];

                aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
                aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                aVertices.push(aPoint1.x, aPoint1.y, aPoint1.z);

                aVertices.push(aPoint4.x, aPoint4.y, aPoint4.z);
                aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);


            }
        }

        return {
            vertices: aVertices,
            edgeVertices: aEdgeVertices
        };
    }
    //__________________________________________________________________________________________
    public static getBufferCircular(pZFunction: tZFunction, pParams: {
        radius: number,
        circles: number,
        delta?: number,
        N?: (x: number, y: number) => Vector3;
    }) {

        let aVertices = new Array<number>();
        let aNormalVectors = new Array<Array<Vector3>>();
        let aNormals = new Array<number>();

        let aEdgeVertices = new Array<Vector3>();
        let aCirclesPoints = new Array<Array<Vector3>>();
        let r_delta = pParams.radius / pParams.circles;
        let aDelta = (null == pParams.delta) ? 1 : pParams.delta;

        for (let i = 0; i <= pParams.circles; i++) {
            aCirclesPoints[i] = new Array<Vector3>();
            aNormalVectors[i] = new Array<Vector3>();
            let aStartAlpha = i % 2 ? 0 : 0.5;
            let aCurrRadius = pParams.radius - (i * r_delta);

            for (let alpha = aStartAlpha; alpha <= 360; alpha += aDelta) {
                let aRad = alpha * MathContext.DEG_TO_RAD;

                let x = aCurrRadius * Math.cos(aRad);
                let y = aCurrRadius * Math.sin(aRad);
                let z = pZFunction(x, y);
                let aPoint = new Vector3(x, y, z);
                if (pParams.N != null) {
                    aNormalVectors[i].push(pParams.N(x, y));
                }
                aCirclesPoints[i].push(aPoint);
                if (i == 0) {
                    aEdgeVertices.push(aPoint);
                }
            }
        }


        for (let circle = 0; circle < aCirclesPoints.length - 1; circle++) {
            const aCurrCircle = aCirclesPoints[circle];
            const aNextCircle = aCirclesPoints[circle + 1];
            if (aNextCircle.length == 0) {
                break;
            }

            let aLength = Math.max(aCurrCircle.length, aNextCircle.length);
            for (let i = 0; i < aLength - 1; i++) {

                let aArr1Index = aCurrCircle[i] != null ? i : 0;
                let aPoint1 = aCurrCircle[aArr1Index];
                let aPoint2 = aCurrCircle[aArr1Index + 1] != null ?
                    aCurrCircle[aArr1Index + 1] : aCurrCircle[0];

                let aArr2Index = aNextCircle[i] != null ? i : 0;
                let aPoint3 = aNextCircle[aArr2Index];
                let aPoint4 = aNextCircle[aArr2Index + 1] != null ?
                    aNextCircle[aArr2Index + 1] : aNextCircle[0];

                if (aNormalVectors[circle].length > 0) {
                    let aNormal1 = aNormalVectors[circle][aArr1Index]
                    let aNormal2 = aNormalVectors[circle][aArr1Index + 1] != null ?
                        aNormalVectors[circle][aArr1Index + 1] : aNormalVectors[circle][0];

                    let aNormal3 = aNormalVectors[circle + 1][aArr2Index]
                    let aNormal4 = aNormalVectors[circle][aArr2Index + 1] != null ?
                        aNormalVectors[circle][aArr2Index + 1] : aNormalVectors[circle][0];

                    aNormals.push(...aNormal3.toArray());
                    aNormals.push(...aNormal2.toArray());
                    aNormals.push(...aNormal1.toArray());

                    aNormals.push(...aNormal4.toArray());
                    aNormals.push(...aNormal2.toArray());
                    aNormals.push(...aNormal3.toArray());
                }

                aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
                aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                aVertices.push(aPoint1.x, aPoint1.y, aPoint1.z);

                aVertices.push(aPoint4.x, aPoint4.y, aPoint4.z);
                aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
            }
        }

        return {
            vertices: aVertices,
            edgeVertices: aEdgeVertices,
            normals: aNormals
        };
    }
    //__________________________________________________________________________________________
    public static getBufferCircular2(pZFunction: tZFunction, pParams: {
        radius: number,
        circles: number,
        x0?: number,
        y0?: number,
        z0?: number
    }) {


        let x0 = (null != pParams.x0) ? pParams.x0 : 0;
        let y0 = (null != pParams.y0) ? pParams.y0 : 0;
        let z0 = (null != pParams.z0) ? pParams.z0 : 0;

        let aVertices = new Array<number>();
        let aEdgeVertices = new Array<Vector3>();
        let aCirclesPoints = new Array<Array<Vector3>>();
        let r_delta = pParams.radius / pParams.circles;
        let aDelta = 1;

        for (let i = 0; i <= pParams.circles; i++) {
            aCirclesPoints[i] = new Array<Vector3>();
            let aStartAlpha = i % 2 ? 0 : 0.5;
            let aCurrRadius = pParams.radius - (i * r_delta);

            for (let alpha = aStartAlpha; alpha <= 360; alpha += aDelta) {
                let aRad = alpha * MathContext.DEG_TO_RAD;

                let x = aCurrRadius * Math.cos(aRad);
                let y = aCurrRadius * Math.sin(aRad);

                let z = pZFunction((x - x0), (y - y0));
                let aPoint = new Vector3(x, y, (z - z0));
                aCirclesPoints[i].push(aPoint);
            }
        }

        aEdgeVertices.push(...aCirclesPoints[0]);

        for (let circle = 0; circle < aCirclesPoints.length - 1; circle++) {
            const aCurrCircle = aCirclesPoints[circle];
            const aNextCircle = aCirclesPoints[circle + 1];
            if (aNextCircle.length == 0) {
                break;
            }

            let aLength = Math.max(aCurrCircle.length, aNextCircle.length);
            for (let i = 0; i < aLength - 1; i++) {

                let aArr1Index = aCurrCircle[i] != null ? i : 0;
                let aPoint1 = aCurrCircle[aArr1Index];
                let aPoint2 = aCurrCircle[aArr1Index + 1] != null ?
                    aCurrCircle[aArr1Index + 1] :
                    aCurrCircle[0];

                let aArr2Index = aNextCircle[i] != null ? i : 0;
                let aPoint3 = aNextCircle[aArr2Index];
                let aPoint4 = aNextCircle[aArr2Index + 1] != null ?
                    aNextCircle[aArr2Index + 1] :
                    aNextCircle[0];

                aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
                aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                aVertices.push(aPoint1.x, aPoint1.y, aPoint1.z);

                aVertices.push(aPoint4.x, aPoint4.y, aPoint4.z);
                aVertices.push(aPoint2.x, aPoint2.y, aPoint2.z);
                aVertices.push(aPoint3.x, aPoint3.y, aPoint3.z);
            }
        }

        return {
            vertices: aVertices,
            edgeVertices: aEdgeVertices
        };
    }
    //__________________________________________________________________________________________
    public static createCircularSurface(pData: iCircularSurfaceParams,
        pZFunction: tZFunction) {


        let aResult = this.getBufferCircular(pZFunction, {
            circles: 90,
            radius: pData.diameter / 2,
        });

        let aGeo = new BufferGeometry();
        aGeo.setAttribute('position', new Float32BufferAttribute(aResult.vertices, 3));

        let aMat = ThreeMaterialUtils.createMat();
        let aMesh = new Mesh(aGeo, aMat);
        aGeo.computeVertexNormals();
        aMesh.name = pData.name;

        let aOpticsReturnData: iOpticsReturnData = {
            shape: aMesh,
            edgeVertices: aResult.edgeVertices
        };

        return aOpticsReturnData;
    }
    //__________________________________________________________________________________________
}
