import { useState } from "react"

export interface iBlackBoxInputProps {
    title: string,
    defaultValue: string,
    changeInput: any,
}

export function BlackBoxInputField({ title, changeInput, defaultValue }: iBlackBoxInputProps) {
    const [prevValue, setPrevValue] = useState('')
    const [value, setValue] = useState(defaultValue)


    const onChangeInputField = (event) => {
        const aCurrentValue = event.target.value

        if (!isNaN(aCurrentValue)) {
            const aCheckedValue = aCurrentValue < 0 ? 0 : aCurrentValue
            setValue(aCheckedValue.replaceAll(' ', ''))
        }
    }


    const resetOnFocus = () => {
        setPrevValue(value)
        setValue('')
    }
    const addMmOnBlur = (event) => {
        const aValue = event.target.value

        if (aValue !== '') {
            setValue(aValue + ' mm')
            changeInput(aValue + ' mm', title.toLowerCase().replaceAll(' ', '_'))
        } else {
            setValue(prevValue)
            changeInput(prevValue, title.toLowerCase().replaceAll(' ', '_'))
        }
    }


    return <div>
        <span>{ title }</span>
        <input className='w-100' type='text' value={ value } onBlur={ addMmOnBlur } placeholder={ prevValue } onFocus={ resetOnFocus } onChange={ onChangeInputField } />
    </div>
}