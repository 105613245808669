import { Chart, ChartConfiguration } from "chart.js/auto";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { eWavelengthDistributionType, iWavelenghtDistributionData, iWavelengthData, iWavelengthFile, iWavelengthSingleItem } from "../../parts/behaviors/LightSourceContext";
import { iHash, iPoint2D } from "../../_context/_interfaces/Interfaces";
import { NIE } from "../part_info/_components/NIE";
import { Op3dContext } from "../../_context/Op3dContext";
import { eWavelengthUnit } from "../../_context/Enums";
import { ParserContext } from "../../parser/ParserContext";
import { LightSourceUtils } from "../../simulation/LightSourceUtils";
import { ViewUtils } from "../ViewUtils";
import { iWavelengthElement, piWavelengthSection } from "../part_info/_light_source_section/piWavelengthSection";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { DataUtils } from "../../_utils/DataUtils";
import { CustomSelect, iCustomSelectOption } from "../components/CustomSelect";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { RBElement } from "../components/RBElement";
import { NameForm } from "../forms/NameForm";
import { iWLPreset } from "../../_context/_interfaces/SettingInterfaces";
import { Popup } from "../forms/Popup";
import { UploadExcelForm } from "../forms/UploadExcelForm";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { SimulationContext } from "../../simulation/SimulationContext";
import { EventManager } from "../../../oc/events/EventManager";
import { EventsContext } from "../../_context/EventsContext";
import { eNotificationToastDuration } from "../home/_notifications/NotificationsContext";

export interface iGeneralGraphFormParams {
    config: ChartConfiguration;
    width: number;
    height: number;

    title: string;
}


export interface iWavelenghtEditForm {
    callback?: (pWavelenghtData: iWavelengthData) => any;
    wavelenghtData: iWavelengthData;
    isEditble: boolean;
}

export interface iWLDistType {
    type: eWavelengthDistributionType;
    preset_id?: string;
}

export class WavelengthEditForm extends Op3dComponentBase<iWavelenghtEditForm> {

    private static EXCEL_TEMPLATE: Array<iWavelengthFile> = [
        { wavelength: 400, weight: 0.7 },
        { wavelength: 450, weight: 0.8 },
        { wavelength: 500, weight: 0.9 },
        { wavelength: 550, weight: 0.7 },
        { wavelength: 600, weight: 0.9 },
        { wavelength: 650, weight: 0.8 },
        { wavelength: 700, weight: 0.7 }
    ];

    private mChart: Chart;
    private mElements: {
        central_wavelength: NIE;
        gauss_width: NIE;
        blackbody_center: NIE;
        temperature: NIE;
        range_min: NIE;
        range_max: NIE;
        range_delta: NIE;
    };
    private mWavelengthElements: Array<iWavelengthElement> = [];
    private mDistributionTypeSelect: CustomSelect<iWLDistType>;

    private mSingleItem: HTMLElement;
    private mItemsContainer: HTMLElement;
    private mGraphCot: HTMLElement;
    private mSaveAsPresetButton: HTMLElement;
    private mUploadExcelButton: HTMLElement;
    private mModifyBtn: HTMLElement;
    private mAddWLButton: HTMLElement;
    private mSortBtn: HTMLElement;
    private mBody: HTMLElement;
    private mGraphHeight: number = 500;

    private mCallback: (pWavelenghtData: iWavelengthData) => any;

    //______________________________________________________________________________________________
    constructor() {
        super({
            container: WavelengthEditForm._getCot(),
            skinPath: "./skins/tools/wavelenght_form.html",
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //______________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mGraphCot = this._getPart('graph_cot');
        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    protected _initElements(): void {
        this._initDistributionTypeSelect();
        this._initInputs();

        this.mSaveAsPresetButton = this._getPart('preset_btn');
        this.mUploadExcelButton = this._getPart('upload_excel');
        this.mModifyBtn = this._getPart('modify_btn');
        this.mBody = this._getPart('body');
        this.mAddWLButton = this._getPart('add-wl');
        this.mSortBtn = this._getPart('sort_btn');

        this.mSingleItem = this._getPart("single-wl-item");
        this.mItemsContainer = this.mSingleItem.parentElement;
        this.mItemsContainer.removeChild(this.mSingleItem);
    }
    //______________________________________________________________________________________________
    private _initDistributionTypeSelect() {
        this.mDistributionTypeSelect = new CustomSelect<iWLDistType>(
            this._getPart("distribution-type-container"), {
            labelOptions: {
                bold: false,
                justify: "start",
                label: 'Spectrum type',
            },
            placeHolder: 'Intensity colors count',
            onChange: () => this._onChange(),
            class: ["forms-custom-select", "wl_select"],
            staticPostion: true,
            qa_id: "qa_distribution_type",
        });

        this._setDistributionSelectOptions(eWavelengthDistributionType.USER_DEFINED);
    }
    //______________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._getPart('apply_btn').addEventListener('click', () => this._onApply());
        this.mSortBtn.addEventListener('click', () => this._sort());
        this.mAddWLButton.addEventListener("click", () => this._addSingleItem(null, true));
        this.mUploadExcelButton.addEventListener('click', () => this._onUploadExcelBtn());
        this._getPart('download_excel').addEventListener('click', () => this._onDownloadExcel());
        this.mModifyBtn.addEventListener('click', () => this._onModifyButtonClicked());
        this.mSaveAsPresetButton.addEventListener('click', () => this._onSetAsPreset());
        $(this.mContainer).resize(() => {
            if (false === this.mIsVisible) {
                return;
            }

            this._onResize();
            this._draw();
        });

        $(this.mSortBtn).tooltip();

        EventManager.addEventListener(EventsContext.UPDATE_USER_SETTINGS, () =>
            this._onUpdateUserSettings(), this);
    }
    //______________________________________________________________________________________________
    private _onModifyButtonClicked() {
        this.mDistributionTypeSelect.setValue(eWavelengthDistributionType.USER_DEFINED, true);
    }
    //______________________________________________________________________________________________
    private _onUpdateUserSettings() {
        let aUserSettings = Op3dContext.USER_VO.userVO.parameters.simulation;
        let aISnm = (aUserSettings.wavelengthUnit === eWavelengthUnit.NM);
        let aUnitLabel = (true == aISnm) ? "nm" : ParserContext.SPECIAL_CHARACTERS.micron;
        let aScale = (true === aISnm) ? 1 : (1 / 1000);
        let aDecimalPrecision = (true == aISnm) ? 2 : 5;

        this.mElements.central_wavelength.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.gauss_width.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.blackbody_center.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.range_min.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.range_max.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });
        this.mElements.range_delta.setParams({
            presentedScale: aScale,
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision
        });

        this._fillData(this._getData());
        this.update();
    }
    //______________________________________________________________________________________________
    private _getInitialPresetName() {
        let aInitialName = 'My new preset';
        let aWLPresets = Op3dContext.USER_VO.wl_presets
        if (undefined !== aWLPresets) {
            let aL = Object.entries(aWLPresets).length;
            if (aL > 0) {
                aInitialName += ('_' + (aL + 1));
            }
        }

        return aInitialName;
    }
    //______________________________________________________________________________________________
    private _onSetAsPreset(pCurrPreset?: iWLPreset<iWavelengthData>) {
        let aInitialName = (undefined !== pCurrPreset) ? pCurrPreset.name :
            this._getInitialPresetName();

        NameForm.instance.open({
            callback: async (pVal) => await this._onSavePreset(pVal, pCurrPreset),
            title: 'Save as preset (Distribution type)',
            initialValue: aInitialName,
            validationFunc: (pVal) => {
                let aMsg: string;
                if ('' === pVal) {
                    aMsg = 'Name is required in order to save preset';
                }

                return aMsg;
            }
        });
    }
    //______________________________________________________________________________________________
    private _onUploadExcelBtn() {
        UploadExcelForm.instance.open({
            templateFunc: () => {
                const aWorkbook = XLSX.utils.book_new();
                const aWorksheet = XLSX.utils.json_to_sheet(WavelengthEditForm.EXCEL_TEMPLATE);
                XLSX.utils.book_append_sheet(aWorkbook, aWorksheet, 'Sheet1');
                XLSX.write(aWorkbook, { type: 'buffer', bookType: 'xlsx' });
                XLSX.writeFile(aWorkbook, 'wavelenght_template.xlsx');
            },
            callback: (pFile) => this._onUploadWavelenghtFile(pFile),
            title: 'Custom .xlsx wavelengths file import'
        });
    }
    //______________________________________________________________________________________________
    private _onDownloadExcel() {
        let aData = this._getData();
        let aFile = aData.wavelengthData.map(wl => {
            let aWLFile: iWavelengthFile = {
                wavelength: wl.wl,
                weight: wl.weight
            };

            return aWLFile;
        });

        const aWorkbook = XLSX.utils.book_new();
        const aWorksheet = XLSX.utils.json_to_sheet(aFile);
        XLSX.utils.book_append_sheet(aWorkbook, aWorksheet, 'Sheet1');
        XLSX.write(aWorkbook, { type: 'buffer', bookType: 'xlsx' });
        XLSX.writeFile(aWorkbook, 'wavelenghts.xlsx');
    }
    //______________________________________________________________________________________________
    private _onUploadWavelenghtFile(pFile: File) {
        let aCurrData = this._getData();
        let aReader = new FileReader();
        aReader.onload = () => {
            let aWorkbook = XLSX.read(aReader.result, { type: 'binary', sheetStubs: true });
            let aSheet = aWorkbook.Sheets[aWorkbook.SheetNames[0]];
            let aWavelengthFile = XLSX.utils.sheet_to_json<iWavelengthFile>(aSheet);
            if (0 === aWavelengthFile.length) {
                this._onErrorReadingFile("Empty file");
                return;
            }

            let aWavelengthData = new Array<iWavelengthSingleItem>();

            let aPrimaryIndex = -1;
            let aMaxWeight = -Infinity;
            let aWLRange = piWavelengthSection.WL_RANGE;
            for (let i = 0, l = aWavelengthFile.length; i < l; i++) {
                let wl = aWavelengthFile[i].wavelength;
                let aWeight = aWavelengthFile[i].weight;

                if ((undefined === wl) || (undefined === aWeight)) {
                    this._onErrorReadingFile("Wrong file structure. Please download our template");
                    return;
                }

                if (false === OP3DMathUtils.isInRange(wl, aWLRange)) {
                    this._onErrorReadingFile("Wavelength could be only between 140nm to 20,000nm");
                    return;
                }

                if (false === OP3DMathUtils.isInRange(aWeight, { min: 0, max: 1 })) {
                    this._onErrorReadingFile("Weight could be only between 0 to 1");
                    return;
                }

                if (aMaxWeight < aWeight) {
                    aMaxWeight = aWeight;
                    aPrimaryIndex = i;
                }

                aWavelengthData.push({
                    wl: aWavelengthFile[i].wavelength,
                    weight: aWavelengthFile[i].weight,
                    isPrimary: false
                });
            }

            if (-1 === aPrimaryIndex) {
                let aMsg = 'At least one of the wavelengths must have weight bigger then 0';
                this._onErrorReadingFile(aMsg);
                return;
            }
            aWavelengthData[aPrimaryIndex].isPrimary = true;
            aCurrData.wavelengthData = aWavelengthData;
            this._fillData(aCurrData);
            this._update();
        }
        aReader.readAsBinaryString(pFile);
        aReader.onerror = () => this._onErrorReadingFile('Error reading file');
    }
    //______________________________________________________________________________________________
    private _onErrorReadingFile(pMsg: string) {
        Popup.instance.open({
            text: pMsg,
            addWarningSign: true
        });
    }
    //______________________________________________________________________________________________
    private async _onSavePreset(pName: string, pCurrPreset?: iWLPreset<iWavelengthData>) {
        let aPreset = Op3dContext.USER_VO.getWLPresetByName(pName);
        if (undefined !== aPreset) {
            Popup.instance.open({
                text: `<div>${pName}. Preset already exists.<br>Do you want to replace it?</div>`,
                yesBtn: {
                    title: 'Yes',
                    callback: async () => {
                        if (undefined !== pCurrPreset) {
                            pCurrPreset.name = pName;
                            await Op3dContext.USER_VO.deleteWLPreset(aPreset);
                            await this._savePresetOnServer(pCurrPreset);
                        } else {
                            await this._savePreset(pName, aPreset.id);
                        }
                    }
                },
                noBtn: {
                    title: 'No'
                },
                addWarningSign: true,
                title: 'Confirm save preset'
            });

            return;
        }

        if (undefined !== pCurrPreset) {
            pCurrPreset.name = pName;
            await this._savePresetOnServer(pCurrPreset);
        } else {
            let aPresetID = Op3dUtils.idGenerator();
            await this._savePreset(pName, aPresetID);
        }
    }
    //______________________________________________________________________________________________
    private async _savePreset(pName: string, pID: string) {
        let aData = this._getData();
        aData.distribution_data.preset_id = pID;

        let aPreset: iWLPreset = {
            data: aData,
            name: pName,
            id: pID
        };

        await this._savePresetOnServer(aPreset);
    }
    //______________________________________________________________________________________________
    private async _savePresetOnServer(pPreset: iWLPreset) {
        let aSavingErrorMsg = await Op3dContext.USER_VO.addWLPreset(pPreset);
        if (undefined === aSavingErrorMsg) {
            this._setDistributionSelectOptions(pPreset.id, true);
        } else {
            Popup.instance.open({ text: aSavingErrorMsg });
        }
    }
    //______________________________________________________________________________________________
    private _sort() {
        this._fillData(this._getData());
        this._update();
    }
    //______________________________________________________________________________________________
    private _onApply() {
        let aNumOfWavelenghts = this.mWavelengthElements.length;
        if (aNumOfWavelenghts > SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT) {
            Popup.instance.open({
                text: MessagesHandler.TOO_MANY_WAVELENGTHS_PER_SETUP
            });

            return;
        }

        if (undefined === this.mWavelengthElements.find(wl => wl.weight.actualValue > 0)) {
            Popup.instance.open({
                text: MessagesHandler.WEIGHT_0_MSG
            });

            return;
        }

        let aData = this._getData();
        this.mCallback(aData);
        this.close();
    }
    //______________________________________________________________________________________________
    private _setDistributionSelectOptions(pValue: string, pDispatchEvent: boolean = false) {
        let aOptions: iCustomSelectOption<iWLDistType>[] = [
            {
                value: eWavelengthDistributionType.USER_DEFINED,
                data: {
                    type: eWavelengthDistributionType.USER_DEFINED
                },
                text: "User-defined",
                qa_id: "qa_user_defined",
                enable: true
            },
            {
                value: eWavelengthDistributionType.TH,
                data: {
                    type: eWavelengthDistributionType.TH
                },
                text: "TH",
                isPremiumOnly: true,
                qa_id: "qa_th",
                enable: true
            },
            {
                value: eWavelengthDistributionType.GAUSSIAN,
                data: {
                    type: eWavelengthDistributionType.GAUSSIAN
                },
                text: "Gaussian",
                isPremiumOnly: true,
                qa_id: "qa_gaussian",
                enable: true
            },
            {
                value: eWavelengthDistributionType.BLACKBODY,
                data: {
                    type: eWavelengthDistributionType.BLACKBODY
                },
                text: "Blackbody",
                isPremiumOnly: true,
                qa_id: "qa_blackbody",
                enable: true
            }];

        let aPresets = Op3dContext.USER_VO.presets;
        if (undefined !== aPresets) {
            let aWLPresets = aPresets.wavelengths;
            if (undefined !== aWLPresets) {
                for (let id in aWLPresets) {
                    let aPreset = aWLPresets[id];
                    let aOption: iCustomSelectOption<iWLDistType> = {
                        value: id,
                        data: {
                            type: aPreset.data.distribution_data.type,
                            preset_id: id
                        },
                        text: aPreset.name,
                        isPremiumOnly: true,
                        enable: true,
                        context_menu_items: [
                            {
                                title: 'Rename preset',
                                callback: () => this._onSetAsPreset(aPreset)
                            },
                            {
                                title: 'Delete preset',
                                callback: () => this._onDeletePreset(aPreset)
                            }
                        ]
                    };

                    aOptions.push(aOption);
                }
            }
        }

        this.mDistributionTypeSelect.setOptions(aOptions, pValue, (true === pDispatchEvent));
    }
    //______________________________________________________________________________________________
    private async _onDeletePreset(pPreset: iWLPreset<iWavelengthData>) {
        let aCurrValue = this.mDistributionTypeSelect.value;
        if (pPreset.id === aCurrValue) {
            aCurrValue = eWavelengthDistributionType.USER_DEFINED;
        }

        await Op3dContext.USER_VO.deleteWLPreset(pPreset);
        this._setDistributionSelectOptions(aCurrValue, (pPreset.id === aCurrValue));
    }
    //______________________________________________________________________________________________
    private async _onChange() {
        let aWLDistributionData = this.mDistributionTypeSelect.selectedOptionData;
        let aType = aWLDistributionData.type;
        let aPresetID = aWLDistributionData.preset_id;
        let aWLPreset: iWLPreset<iWavelengthData>;
        if (undefined !== aPresetID) {
            aWLPreset = Op3dContext.USER_VO.getWLPresetByID(aPresetID);
        }

        let aData = (undefined !== aWLPreset) ? aWLPreset.data : this._getData();
        if (eWavelengthDistributionType.USER_DEFINED !== aType) {
            aData.wavelengthData = LightSourceUtils.getWavelenghtData(aData.distribution_data);
        }

        this._fillData(aData);
        this._update();
    }
    //______________________________________________________________________________________________
    private _getWavelenghs() {
        let aWavelengths = this.mWavelengthElements.map(elem => {
            let aWavelengthSingleItem: iWavelengthSingleItem = {
                isPrimary: elem.primaryRB.checked,
                weight: elem.weight.actualValue,
                wl: elem.wavelength.actualValue
            };

            return aWavelengthSingleItem;
        });

        aWavelengths.sort((a, b) => (a.wl - b.wl));
        return aWavelengths;
    }
    //______________________________________________________________________________________________
    private _getDistributionData() {
        let aWavelenghtDistributionData: iWavelenghtDistributionData = {
            type: (this.mDistributionTypeSelect.value as eWavelengthDistributionType),
            data: {
                gauss_center: this.mElements.central_wavelength.actualValue,
                gauss_width: this.mElements.gauss_width.actualValue,
                blackbody_max: this.mElements.blackbody_center.actualValue,
                blackbody_temperature: this.mElements.temperature.actualValue,
                min: this.mElements.range_min.actualValue,
                max: this.mElements.range_max.actualValue,
                delta: this.mElements.range_delta.actualValue
            }
        };

        return aWavelenghtDistributionData;
    }
    //______________________________________________________________________________________________
    private _getData() {
        let aWavelengthData: iWavelengthData = {
            wavelengthData: this._getWavelenghs(),
            distribution_data: this._getDistributionData()
        };

        return aWavelengthData;
    }
    //______________________________________________________________________________________________
    private _addSingleItem(pData: iWavelengthSingleItem, pIsNew: boolean) {
        let aDistributionData = this.mDistributionTypeSelect.selectedOptionData;
        let aDistributionType = aDistributionData.type;
        let aIsUserDefined = ((eWavelengthDistributionType.USER_DEFINED === aDistributionType) &&
            (undefined === aDistributionData.preset_id));

        let aUserSettings = Op3dContext.USER_VO.userVO.parameters.simulation;
        let aWavelengthUnit = aUserSettings.wavelengthUnit;
        let aISnm = (eWavelengthUnit.NM === aWavelengthUnit);
        let aScale = (true === aISnm) ? 1 : (1 / 1000);
        let aUnitSign = (true === aISnm) ? 'nm' : ParserContext.SPECIAL_CHARACTERS.micron;
        let aDecimalPrecision = (true == aISnm) ? 2 : 5;

        let aItem = this.mSingleItem.cloneNode(true) as HTMLElement;


        let aWavelengthNIE = new NIE(Op3dUtils.getElementIn(aItem, 'wavelenght_input'), {
            class: ['wl_input'],
            presentedScale: aScale,
            unit: aUnitSign,
            isGlobalToFixed: true,
            decimalPrecision: aDecimalPrecision,
            onChange: () => this._draw(),
            range: piWavelengthSection.WL_RANGE
        });
        aWavelengthNIE.readonly = (false === aIsUserDefined);


        let aRemoveBtn = Op3dUtils.getElementIn(aItem, "remove-wl");
        ViewUtils.setElementVisibilityByDNone(aRemoveBtn, (true === aIsUserDefined));
        if (true === aIsUserDefined) {
            let aCurrIndex = this.mWavelengthElements.length;
            aRemoveBtn.addEventListener("click", () => this._onRemoveWl(aCurrIndex, aItem, true));
        }

        let aIsPrimary = (null !== pData) ? (true === pData.isPrimary) : false;
        ViewUtils.setElementDisabled(aRemoveBtn, aIsPrimary);

        this.mItemsContainer.appendChild(aItem);


        let aData = (null !== pData) ? pData : this._getNextWL();

        let aPrimaryRB = new RBElement(Op3dUtils.getElementIn(aItem, "wl-primary"), {
            label: undefined,
            name: 'wavelenght_form_rb',
            initialState: aData.isPrimary,
            onChange: () => {
                for (let i = 0; i < this.mWavelengthElements.length; i++) {
                    let aIsPrimary = (aPrimaryRB === this.mWavelengthElements[i].primaryRB);
                    if (true === aIsUserDefined) {
                        let aCurrItem = this.mWavelengthElements[i].item;
                        let aCurrRemoveBtn = Op3dUtils.getElementIn(aCurrItem, "remove-wl");
                        ViewUtils.setElementDisabled(aCurrRemoveBtn, (true === aIsPrimary));
                    }
                    let aWeightMin = (true == aIsPrimary) ? 0.001 : 0;
                    this.mWavelengthElements[i].weight.updateRange({ min: aWeightMin, max: 1 });
                }
            }
        });

        let aWeightNIE = new NIE(Op3dUtils.getElementIn(aItem, 'weight_input'), {
            class: ['wl_input'],
            decimalPrecision: aUserSettings.digitsAfterDot,
            onChange: (pValue) => {
                aPrimaryRB.enable = (0 !== pValue);
                this._draw();
            },
            toExp: true
        });

        aWeightNIE.readonly = (false === aIsUserDefined);
        let aWeightMin = (false === aData.isPrimary) ? 0 : 0.001;
        aWavelengthNIE.actualValue = aData.wl;
        aWeightNIE.actualValue = aData.weight;
        aPrimaryRB.checked = aData.isPrimary;
        aWeightNIE.updateRange({ min: aWeightMin, max: 1 });

        let aElement: iWavelengthElement = {
            primaryRB: aPrimaryRB,
            wavelength: aWavelengthNIE,
            weight: aWeightNIE,
            item: aItem
        };
        this.mWavelengthElements.push(aElement);

        if (true === pIsNew) {
            this._draw();
        }
    }
    //______________________________________________________________________________________________
    private _getNextWL() {
        let aWl = 550;
        let aWeight = 1;
        let aLastIndex: number;

        if (1 == this.mWavelengthElements.length) {
            aWl = this.mWavelengthElements[0].wavelength.actualValue;
            aWeight = this.mWavelengthElements[0].weight.actualValue;

        } else if (this.mWavelengthElements.length > 1) {

            aLastIndex = (this.mWavelengthElements.length - 1);
            let aWLast = this.mWavelengthElements[aLastIndex].wavelength.actualValue;
            let aWBeforeLast = this.mWavelengthElements[(aLastIndex - 1)].wavelength.actualValue;
            aWl = Math.max(piWavelengthSection.WL_RANGE.min,
                Math.min(piWavelengthSection.WL_RANGE.max, ((2 * aWLast) - aWBeforeLast)));
            aWeight = this.mWavelengthElements[aLastIndex].weight.actualValue;
        }

        let aWavelengthSingleItem: iWavelengthSingleItem = {
            isPrimary: (0 == this.mWavelengthElements.length),
            weight: aWeight,
            wl: aWl
        };

        return aWavelengthSingleItem;
    }
    //______________________________________________________________________________________________
    private _onRemoveWl(pElementIndex: number, pItem: HTMLElement, pUpdate: boolean) {
        let aElement = this.mWavelengthElements[pElementIndex];
        if (true === aElement.primaryRB.checked) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.PRIMARY_WL_CANNOT_BE_DELETED,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL
            });
            return;
        }

        let aIndex = this.mWavelengthElements.indexOf(aElement);
        pItem.remove();
        this.mWavelengthElements.splice(aIndex, 1);
        if (true === pUpdate) {
            this._onChange();
        }
    }
    //______________________________________________________________________________________________
    private _initInputs() {
        let aUserSettings = Op3dContext.USER_VO.userVO.parameters.simulation;
        let aISnm = (aUserSettings.wavelengthUnit === eWavelengthUnit.NM);
        let aUnitLabel = (true == aISnm) ? "nm" : ParserContext.SPECIAL_CHARACTERS.micron;
        let aScale = (true === aISnm) ? 1 : (1 / 1000);
        let aDecimalPrecision = (true === aISnm) ? 2 : 5;

        (this.mElements as any) = {}

        const aCentralWavelength = 550;
        this.mElements.central_wavelength = new NIE(this._getPart("gauss-central-wl"), {
            isGlobalToFixed: true,
            label: {
                text: "Central wavelength",
                bold: false,
                justify: "start"
            },
            onChange: () => this._onChange(),
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision,
            initialValue: aCentralWavelength,
            presentedScale: aScale,
            class: ['wl_dist_data_inputs'],
        });

        this.mElements.gauss_width = new NIE(this._getPart("gauss-width"), {
            isGlobalToFixed: true,
            label: {
                text: "Width",
                bold: false,
                justify: "start"
            },
            range: {
                min: 0,
                max: Infinity
            },
            onChange: () => this._onChange(),
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision,
            toExp: true,
            initialValue: 50,
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });

        const aTemperature = LightSourceUtils.getBlackbodyTemperature(aCentralWavelength);
        let aMaxT = LightSourceUtils.getBlackbodyTemperature(piWavelengthSection.WL_RANGE.min);
        let aMinT = LightSourceUtils.getBlackbodyTemperature(piWavelengthSection.WL_RANGE.max);
        this.mElements.temperature = new NIE(this._getPart("blackbody-temperature"), {
            isGlobalToFixed: true,
            label: {
                text: "Temperature",
                bold: false,
                justify: "start"
            },
            onChange: (pVal) => {
                this.mElements.blackbody_center.actualValue =
                    LightSourceUtils.getBlackbodyPeakWL(pVal);
                this._onChange();
            },
            range: {
                min: aMinT,
                max: aMaxT
            },
            unit: "K",
            decimalPrecision: 4,
            initialValue: aTemperature,
            class: ['wl_dist_data_inputs']
        });

        let aMinBBC = LightSourceUtils.getBlackbodyPeakWL(aMaxT);
        let aMaxBBC = LightSourceUtils.getBlackbodyPeakWL(aMinT);
        this.mElements.blackbody_center = new NIE(this._getPart("blackbody-central-wl"), {
            isGlobalToFixed: true,
            label: {
                text: "Temperature",
                bold: false,
                justify: "start"
            },
            onChange: (pVal) => {
                this.mElements.temperature.value = LightSourceUtils.getBlackbodyTemperature(pVal);
                this._onChange();
            },
            range: {
                min: aMinBBC,
                max: aMaxBBC
            },
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision,
            initialValue: aCentralWavelength,
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });

        this.mElements.range_min = new NIE(this._getPart("range_min"), {
            isGlobalToFixed: true,
            label: {
                text: "λmin",
                bold: false,
                justify: "start"
            },
            onChange: () => this._onChange(),
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision,
            initialValue: 400,
            range: piWavelengthSection.WL_RANGE,
            validation: {
                func: (pVal) => {
                    let aMsg: string;
                    let aMax = this.mElements.range_max.actualValue;
                    let aMin = pVal;
                    let aDelta = this.mElements.range_delta.actualValue;

                    let aMaxCount = SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT;
                    let aNumOfW = (Math.floor(((aMax - aMin) / aDelta)) + 1);
                    if (aMin >= aMax) {
                        aMsg = "Max must be bigger then min";
                    } else if (aNumOfW > aMaxCount) {
                        let aMinPossible = (aMax - ((aMaxCount - 1) * aDelta));
                        aMsg = `You cannot use more then ${aMaxCount} wavelengths. 
                        λmin could not be lower then  ${aMinPossible}`;
                    }

                    if (undefined !== aMsg) {
                        NotificationCenter.instance.pushNotification({
                            message: aMsg,
                            toastDuration: eNotificationToastDuration.LONG
                        });
                    }

                    return aMsg;
                },
                returnToPrevValue: true
            },
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });

        this.mElements.range_max = new NIE(this._getPart("range_max"), {
            isGlobalToFixed: true,
            label: {
                text: "λmax",
                bold: false,
                justify: "start"
            },
            onChange: () => this._onChange(),
            unit: aUnitLabel,
            decimalPrecision: aDecimalPrecision,
            initialValue: 700,
            range: piWavelengthSection.WL_RANGE,
            validation: {
                func: (pVal) => {
                    let aMsg: string;
                    let aMax = pVal;
                    let aMin = this.mElements.range_min.actualValue;
                    let aDelta = this.mElements.range_delta.actualValue;

                    let aMaxCount = SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT;
                    let aNumOfW = (Math.floor(((aMax - aMin) / aDelta)) + 1);
                    if (aMin >= aMax) {
                        aMsg = "Max must be bigger then min";
                    } else if (aNumOfW > aMaxCount) {
                        let aMaxPossible = (aMin + ((aMaxCount - 1) * aDelta));
                        aMsg = `You cannot use more then ${aMaxCount} wavelengths. 
                        λmax could not be bigger then  ${aMaxPossible}`;
                    }

                    if (undefined !== aMsg) {
                        NotificationCenter.instance.pushNotification({
                            message: aMsg,
                            toastDuration: eNotificationToastDuration.LONG
                        });
                    }

                    return aMsg;
                },
                returnToPrevValue: true
            },
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });

        const aDelta = 50;
        this.mElements.range_delta = new NIE(this._getPart("range_delta"), {
            isGlobalToFixed: true,
            label: {
                text: "Δ",
                bold: false,
                justify: "start"
            },
            onChange: () => this._onChange(),
            decimalPrecision: aDecimalPrecision,
            unit: aUnitLabel,
            initialValue: aDelta,
            validation: {
                func: (pVal) => {
                    let aMsg: string;
                    let aMax = this.mElements.range_max.actualValue;
                    let aMin = this.mElements.range_min.actualValue;
                    let aDelta = pVal;

                    let aMaxCount = SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT;
                    let aNumOfW = (Math.floor(((aMax - aMin) / aDelta)) + 1);
                    if (aNumOfW > aMaxCount) {
                        let aMinPossible = (((aMax - aMin) / (aMaxCount - 1)));
                        aMsg = `You cannot use more then ${aMaxCount} wavelengths. 
                        Δ could not be lower then  ${aMinPossible}`;
                    }

                    if (undefined !== aMsg) {
                        NotificationCenter.instance.pushNotification({
                            message: aMsg,
                            toastDuration: eNotificationToastDuration.LONG
                        });
                    }

                    return aMsg;
                },
                returnToPrevValue: true
            },
            presentedScale: aScale,
            class: ['wl_dist_data_inputs']
        });
    }
    //______________________________________________________________________________________________
    private _setDistributionSectionsVisibility() {
        let aWLDistributionData = this.mDistributionTypeSelect.selectedOptionData;
        let aType = aWLDistributionData.type;
        $(this.mContainer).find('[distribution]').each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                element.getAttribute('distribution').indexOf(aType) != -1);
        });

        let aIsPreset = (undefined !== aWLDistributionData.preset_id);
        for (let name in this.mElements) {
            this.mElements[name].readonly = aIsPreset;
        }

        let aIsUserDefined = ((eWavelengthDistributionType.USER_DEFINED === aType) &&
            (false === aIsPreset));

        ViewUtils.setElementVisibilityByDNone(this.mSaveAsPresetButton, (false === aIsPreset));
        ViewUtils.setElementVisibilityByDNone(this.mModifyBtn, (false === aIsUserDefined));
        ViewUtils.setElementVisibilityByDNone(this.mUploadExcelButton, aIsUserDefined);
        ViewUtils.setElementVisibilityByDNone(this.mAddWLButton, aIsUserDefined);
        ViewUtils.setElementVisibilityByDNone(this.mSortBtn, aIsUserDefined);

        this.mContainer.setAttribute("distribution", aType);

        this._onResize();
    }
    //______________________________________________________________________________________________
    private _onResize() {
        let aBodyBB = this.mBody.getBoundingClientRect();
        let aItemsContainerBB = this.mItemsContainer.getBoundingClientRect();
        let aMaxH = (aBodyBB.height - (aItemsContainerBB.top - aBodyBB.top) - 46);
        this.mItemsContainer.style.maxHeight = aMaxH + 'px';

        this.mGraphHeight = Math.min((aBodyBB.height - 96), 500);
    }
    //______________________________________________________________________________________________
    protected _onOpen(pWavelenghtEditForm: iWavelenghtEditForm): void {
        this._clear();
        let aEditable = (true === pWavelenghtEditForm.isEditble) ? 'true' : 'false';
        this.mContainer.setAttribute('editable', aEditable);

        this.mCallback = pWavelenghtEditForm.callback;
        this._fillData(pWavelenghtEditForm.wavelenghtData);
    }
    //______________________________________________________________________________________________
    protected _onShown(): void {
        this._update();
    }
    //______________________________________________________________________________________________
    private _update() {
        this._setDistributionSectionsVisibility();
        this._draw();
    }
    //______________________________________________________________________________________________
    private _fillData(pData: iWavelengthData) {
        this._clear();

        this._setDistribution(pData.distribution_data);
        if (0 === pData.wavelengthData.length) {
            this._addSingleItem(null, true);
        } else {
            for (let i = 0; i < pData.wavelengthData.length; i++) {
                this._addSingleItem(pData.wavelengthData[i], false);
            }
        }
    }
    //______________________________________________________________________________________________
    private _setDistribution(pData: iWavelenghtDistributionData) {
        let aDistributionValue: string = eWavelengthDistributionType.USER_DEFINED;
        if (false === Op3dContext.USER_VO.isBasicLicense) {
            if (undefined !== pData) {
                aDistributionValue = pData.type;
            }

            if ((undefined !== pData.preset_id) &&
                (true === this.mDistributionTypeSelect.isExist(pData.preset_id))) {
                aDistributionValue = pData.preset_id;
            }
        }

        this.mDistributionTypeSelect.setValue(aDistributionValue, false);

        let aData = ((undefined !== pData) && (undefined !== pData.data)) ? pData.data :
            LightSourceUtils.DEFAULT_WL_DATA;
        this.mElements.central_wavelength.actualValue = aData.gauss_center;
        this.mElements.gauss_width.actualValue = aData.gauss_width;
        this.mElements.temperature.actualValue = aData.blackbody_temperature;
        this.mElements.blackbody_center.actualValue = aData.blackbody_max;
        this.mElements.range_min.actualValue = aData.min;
        this.mElements.range_max.actualValue = aData.max;
        this.mElements.range_delta.actualValue = aData.delta;
    }
    //______________________________________________________________________________________________
    private _clear() {
        if (null != this.mChart) {
            this.mChart.destroy();
        }

        this.mGraphCot.innerHTML = '';

        ViewUtils.removeElementChildren(this.mItemsContainer);
        this.mWavelengthElements = [];
    }
    //______________________________________________________________________________________________
    private _getWavelengthsDrawData() {
        let aWavelengthsDrawHash: iHash<{ wl: number; weight: number; radius: number; }> = {};

        let aWavelengthUnit = Op3dContext.USER_VO.userVO.parameters.simulation.wavelengthUnit;
        let aISnm = (eWavelengthUnit.NM === aWavelengthUnit);
        let aScale = (true === aISnm) ? 1 : (1 / 1000);
        let aMargin = 50;
        let aType = this.mDistributionTypeSelect.selectedOptionData.type;
        let aMaxFix = (true === aISnm) ? 2 : 5;
        let aToFix = Math.min(aMaxFix, Math.max(
            OP3DMathUtils.countDecimalPoints(aScale * this.mElements.range_delta.actualValue),
            OP3DMathUtils.countDecimalPoints(aScale * this.mElements.range_min.actualValue),
            OP3DMathUtils.countDecimalPoints(aScale * this.mElements.range_max.actualValue)));

        let aMin = (this.mElements.range_min.actualValue - aMargin);
        let aMax = (this.mElements.range_max.actualValue + aMargin);

        switch (aType) {
            case eWavelengthDistributionType.USER_DEFINED:
                let aLastIndex = (this.mWavelengthElements.length - 1);
                aMin = Math.floor(this.mWavelengthElements[0].wavelength.actualValue - aMargin);
                aMax = Math.floor((this.mWavelengthElements[aLastIndex].wavelength.actualValue + aMargin));
                for (let i = aMin; i < aMax; i++) {
                    let w = OP3DMathUtils.roundNum((i * aScale), aToFix);
                    aWavelengthsDrawHash[w] = ({
                        wl: w,
                        radius: 0,
                        weight: 0
                    })
                }

                break;
            case eWavelengthDistributionType.TH:
                let aTHMin = this.mElements.range_min.actualValue;
                let aTHMax = this.mElements.range_max.actualValue;
                for (let i = aMin; i <= aMax; i++) {
                    let w = OP3DMathUtils.roundNum((i * aScale), aToFix);
                    aWavelengthsDrawHash[w] = ({
                        wl: w,
                        radius: 0,
                        weight: (((i >= aTHMin) && (i <= aTHMax)) ? 1 : 0)
                    });
                }

                break;
            case eWavelengthDistributionType.BLACKBODY:
                let aBBTop = this.mElements.blackbody_center.actualValue;
                let aTemperature = this.mElements.temperature.actualValue;
                for (let i = aMin; i <= aMax; i++) {
                    let w = OP3DMathUtils.roundNum(i, aToFix);
                    aWavelengthsDrawHash[w] = ({
                        wl: w,
                        radius: 0,
                        weight: LightSourceUtils.getBlackbodyWeight(i, aTemperature, aBBTop)
                    });
                }

                break;
            case eWavelengthDistributionType.GAUSSIAN:
                let aGWidth = this.mElements.gauss_width.actualValue;
                let aGCenterW = this.mElements.central_wavelength.actualValue;
                for (let i = aMin; i <= aMax; i++) {
                    let w = OP3DMathUtils.roundNum((i * aScale), aToFix);
                    aWavelengthsDrawHash[w] = ({
                        wl: w,
                        radius: 0,
                        weight: LightSourceUtils.getGaussianAmplitude(i, aGCenterW, aGWidth)
                    });
                }
                break;
            default:
                throw new Error("Wrong type");
        }

        let aWavelengthsArr = new Array<iWavelengthElement>();
        let aNumOfWavelenghts = this.mWavelengthElements.length;
        if (aNumOfWavelenghts > 300) {
            aWavelengthsArr.push(this.mWavelengthElements[0]);
            let aRes = Math.floor(aNumOfWavelenghts / 300);
            for (let i = 1, l = (aNumOfWavelenghts - 1); i < l; i += aRes) {
                aWavelengthsArr.push(this.mWavelengthElements[i]);
            }
            aWavelengthsArr.push(this.mWavelengthElements[(this.mWavelengthElements.length - 1)]);
        } else {
            aWavelengthsArr = this.mWavelengthElements.slice();
        }

        for (let i = 0, l = aWavelengthsArr.length; i < l; i++) {
            let aWavelength = aWavelengthsArr[i].wavelength.actualValue;
            let aWeight = aWavelengthsArr[i].weight.actualValue;

            let w = OP3DMathUtils.roundNum((aWavelength * aScale), aToFix);
            aWavelengthsDrawHash[w] = ({
                wl: w,
                radius: 4,
                weight: aWeight
            });
        }

        let aWavelengthsDrawData = Object.values(aWavelengthsDrawHash);
        aWavelengthsDrawData.sort((a, b) => (a.wl - b.wl));
        return aWavelengthsDrawData;
    }
    //______________________________________________________________________________________________
    private _draw() {
        if (0 === this.mWavelengthElements.length) {
            return;
        }

        let aData = this._getWavelengthsDrawData();
        let aPoints = aData.map((data) => {
            let aPoint2D: iPoint2D = {
                x: data.wl,
                y: data.weight
            };

            return aPoint2D;
        });

        let aRadiuses = aData.map(data => data.radius);

        let aDrawData: iGeneralGraphFormParams = {
            width: 500,
            height: this.mGraphHeight,
            title: 'Wavelenght distribution chart',
            config: {
                type: 'line',
                data: {
                    labels: aPoints.map((w) => w.x.toString()),
                    datasets: [{
                        data: aPoints,
                        label: 'Intensity',
                        pointRadius: aRadiuses,
                        borderWidth: 1
                    }]
                },
                options: {
                    animation: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            max: 1.01
                        }
                    },
                    plugins: {
                        tooltip: {
                            intersect: false,
                            callbacks: {
                                title: function (context) {
                                    let w = (context[0].raw as iPoint2D).x;
                                    let aRet = 'Wavelenght: ' + w + 'nm';
                                    return aRet;
                                }
                            },
                            displayColors: false
                        },
                        legend: {
                            display: false
                        }
                    }
                }
            }
        };

        this.mChart = DataUtils.getChartJSInstance(this.mGraphCot, aDrawData);
    }
    //______________________________________________________________________________________________
    private static _getCot() {
        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('new_modal');
        aDiv.classList.add('w-auto');
        aDiv.classList.add('wavelenght_cot');
        aDiv.setAttribute('data-backdrop', 'static')
        aDiv.style.height = '700px';
        aDiv.style.minHeight = '580px';
        aDiv.style.maxHeight = 'calc(100vh - 64px)';
        aDiv.style.resize = 'vertical';
        aDiv.style.left = '285px';
        aDiv.style.top = '105px';
        document.getElementById('forms').appendChild(aDiv);

        return aDiv;
    }
    //______________________________________________________________________________________________
}