import { AxiosError } from "axios";
import { EventManager } from "../../../oc/events/EventManager";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { iSetupMetadata, iUploadSetupData, iDownloadFullSetupData, iGetManySetupsQuery, iGetOneSetup } from "../../_context/_interfaces/Interfaces";
import { ServerContext } from "../../server/ServerContext";
import { DataLoader } from "./DataLoader";


export class SetupsDataLoader extends DataLoader<iSetupMetadata, iGetOneSetup,
    iUploadSetupData, iDownloadFullSetupData> {

    private static INSTANCE: SetupsDataLoader;
    mBackDrop: any;

    private constructor() {
        super({
            fullDataServerCall: (pQuery) =>
                ServerContext.SERVER.getOneSetup(pQuery as { session: string, to_open?: boolean, _id: string }) as any,

            addItemServerCall: (pData: iUploadSetupData) =>
                ServerContext.SERVER.updateSetup(pData),

            getManyItems: (pQuery) => //: iGetManySetupsQuery
                ServerContext.SERVER.getManySetups(pQuery as iGetManySetupsQuery),

            removeItemServerCall: (pSetupMetada: iSetupMetadata) =>
                ServerContext.SERVER.removeOneSetup({
                    _id: pSetupMetada._id
                }),
            //dataConvertionFunc: (pData: iOpticsVO) => this._onGettingOpticsVO(pData)
            //todo
            // metaDataServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.getManyIngredientsNew(pData),
            // duplicateItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.duplicateSync({
            //         number_id: pData.number_id
            //     }),
            // detailedDataServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.getManyIngredientsNew(pData),
            // addItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.updateoptics(pData),
            // editItemServerCall: (pOpticsVO) =>
            //     engine.Op3dContext.serverManager.updateoptics(pOpticsVO),

            // isExistServerCall: (pOpticsVO) =>
            //     engine.Op3dContext.serverManager.isOpticsExists(pOpticsVO),

        });
    }
    //__________________________________________________________________________________________
    private showBackDrop(pAddButton: boolean, pMsg: string) {

        //this is only for some time. NVM
        let aBackDrop = document.createElement('div')

        let aModal = document.createElement('div')
        let aModalContent = document.createElement('div')
        aModalContent.className = 'modal-content'

        let aModalBody = document.createElement('div')
        let aModalText = document.createElement('p')
        aModalText.innerHTML = pMsg;
        aModalBody.className = 'modal-body'

        aBackDrop.appendChild(aModal)
        aModal.appendChild(aModalContent)
        aModalContent.appendChild(aModalBody)
        aModalBody.appendChild(aModalText)

        if (true === pAddButton) {
            let aModalFooter = document.createElement('div')
            let aModalBtn = document.createElement('button')
            aModalBtn.type = 'button'
            aModalBtn.className = 'btn btn-primary'
            aModalBtn.innerHTML = 'Return to wakeupscreen'
            aModalFooter.appendChild(aModalBtn)

            aModalBtn.addEventListener('click', () => {
                Op3dContext.SETUPS_MANAGER.currSetupID = null;
                //setups.SetupsManager.SESSION = null;

                EventManager.dispatchEvent(EventsContext.OPEN_WAKEUP_SCREEN, this)
                document.body.removeChild(this.mBackDrop)
                this.mBackDrop = null
            })
            aModalFooter.className = 'modal-footer'
            aModalContent.appendChild(aModalFooter)
        }

        // aReturnBtn.innerHTML = '<i class="bi bi-arrow-clockwise"></i>'


        aBackDrop.style.position = 'absolute'
        aBackDrop.style.display = 'flex'
        aBackDrop.style.justifyContent = 'center'
        aBackDrop.style.alignItems = 'center'
        aBackDrop.style.top = '0px'
        aBackDrop.style.width = '100vw'
        aBackDrop.style.height = '100vh'
        aBackDrop.style.backgroundColor = 'rgba(0,0,0,0.5)'

        aBackDrop.style.zIndex = '999999999999999999999999'
        return aBackDrop
    }
    //__________________________________________________________________________________________
    public async add(pData: iUploadSetupData, pUpdate: boolean = true) {
        if (null == this.mDataLoaderParams.addItemServerCall) {
            return null;
        }

        let aRet = await this.mDataLoaderParams.addItemServerCall(pData);
        if (null == aRet) {
            return null;
        }

        if (false == aRet.success) {
            if ((aRet.data as any).status == ServerContext.FORBIDDEN) {
                this.mBackDrop = this.showBackDrop(true, 'The session is ended. Please return to wakeupscreen')
                document.body.appendChild(this.mBackDrop)
            } else {
                let aError = aRet.data as any as AxiosError;
                if ((undefined !== aError) && (undefined !== aError.response) &&
                    (ServerContext.PreconditionFailed === aError.response.status)) {
                    switch (aError.response.status) {
                        case ServerContext.PreconditionFailed:
                            this.mBackDrop = this.showBackDrop(false, 'New 3DOptix version is available. Please refresh.');
                            document.body.appendChild(this.mBackDrop);
                            break;
                        case ServerContext.FORBIDDEN:
                            Op3dContext.SETUPS_MANAGER.closeOpenSetup(true);
                            break;

                    }
                }
            }
        }

        let aToSaveOnCache = (null != this.mDataLoaderParams.cacheData);
        if (true == aToSaveOnCache) {
            this.saveToCache(pData);
        }

        if (true == pUpdate) {
            this.mNeedsUpdate = true;
        }

        return aRet;
    }
    //__________________________________________________________________________________________
    // public async getManyItems(pQuery: tSearchType) {
    //     if (this.mNeedsUpdate) {
    //         let aResult = await this.mDataLoaderParams.getManyItems(pQuery);
    //         if (aResult.success) {
    //             this.mDataTotal = aResult.data.total;
    //             console.log(aResult.data);
    //             this.mCurrentData = aResult.data.data;
    //         } else {
    //             this.mDataTotal = 0;
    //             console.log(aResult);
    //             this.mCurrentData = [];
    //         }
    //         return this.mCurrentData;
    //     } else {
    //         return this.mCurrentData
    //     }
    // }
    //__________________________________________________________________________________________

    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
}
