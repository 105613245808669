﻿export class EventBase<T = any> {
    protected mInstanceID: number;
    protected static mInstanceCounter: number = 0;
    private mKey: string;
    private mCallBack: Function;
    private mAttachedData: any;
    private mData: T;
    private mSender: any;
    private mOwner: any;

    constructor(pKey: string,
        pCallBack: Function,
        pOwner: any,
        pAttachedData?: T,
        _pFunction?: Function) {

        EventBase.mInstanceCounter++;
        this.mInstanceID = EventBase.mInstanceCounter;
        this.mAttachedData = pAttachedData;
        this.mOwner = pOwner;
        this.mKey = pKey;
        this.mCallBack = pCallBack;
    }
    //__________________________________________________________________________________________        
    public get callBack(): Function {
        return this.mCallBack;
    }
    //__________________________________________________________________________________________
    public set callBack(value: Function) {
        this.mCallBack = value;
    }
    __________________________________________________________________________________________
    public get data(): T {
        return this.mData;
    }
    //__________________________________________________________________________________________
    public set data(value: T) {
        this.mData = value;
    }
    //__________________________________________________________________________________________
    public get owner(): any {
        return this.mOwner;
    }
    //__________________________________________________________________________________________
    public set owner(value: any) {
        this.mOwner = value;
    }
    //__________________________________________________________________________________________
    public get sender(): any {
        return this.mSender;
    }
    //__________________________________________________________________________________________
    public set sender(value: any) {
        this.mSender = value;
    }
    //__________________________________________________________________________________________
    public get attachedData(): T {
        return this.mAttachedData;
    }
    //__________________________________________________________________________________________
    public set attachedData(value: T) {
        this.mAttachedData = value;
    }
    //__________________________________________________________________________________________
    public get key(): string {
        return this.mKey;
    }
    //__________________________________________________________________________________________
    public set key(value: string) {
        this.mKey = value;
    }
    //__________________________________________________________________________________________
}
