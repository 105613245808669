import { MathContext } from "../_context/MathContext";
import { iNumericKeyHash, iPoint2D, iLine, iMinMax } from "../_context/_interfaces/Interfaces";
import { Spline } from "./Spline";
import { OpticsShapeUtils } from "../parts/optics/OpticShapeUtils";
import { Vector3, Matrix4 } from "three";

export class OP3DMathUtils {

    private static ABBREVIATIONS = ['', 'K', 'M', 'B', 'T'];
    public static TERA: number = 1e9;
    public static MEGA: number = 1e6;
    public static KILO: number = 1e3;
    public static MILI: number = 1e-3;
    public static MICRO: number = 1e-6;
    public static NANO: number = 1e-9;
    public static NANO_TO_MM: number = 1e-6;
    public static RAD_TO_DEG: number = 180 / Math.PI;
    public static DEG_TO_RAD: number = Math.PI / 180;

    //__________________________________________________________________________________________
    public static getRandomNumber(pNumber: number) {
        return Math.floor(Math.random() * (pNumber));
    }

    //__________________________________________________________________________________________
    public static roundNum2(pNum: number, pDigits: number = 0) {
        let aRound = OP3DMathUtils.pow10(pDigits);
        let aNum = (Math.round(pNum * aRound) / aRound);

        return aNum;
    }
    //__________________________________________________________________________________________
    public static formatLargeNumber(pNumber: Number) {
        if (typeof pNumber !== 'number' || isNaN(pNumber)) {
            return 'Invalid number';
        }

        let index = 0;
        let num = pNumber;

        while (num >= 1000 && index < this.ABBREVIATIONS.length - 1) {
            num /= 1000;
            index++;
        }

        //const formattedNum = num.toLocaleString('en-US', { maximumFractionDigits: 2 });
        const formattedNum = pNumber.toLocaleString('en-US');
        return formattedNum + this.ABBREVIATIONS[index];
    }
    //__________________________________________________________________________________________
    public static KroneckerDelta(m: number, n: number) {
        return ((m == n) ? 1 : 0);
    }
    //__________________________________________________________________________________________
    public static calculateBoxDiagonal(pSide1: number, pSide2: number) {
        return Math.sqrt(Math.pow(pSide1, 2) + Math.pow(pSide2, 2))
    }
    //__________________________________________________________________________________________
    public static toFixed(pNum: number, pDigits: number | false, pToExp: boolean = false) {
        if (false == pDigits) {
            return pNum.toString();
        }

        let aFixedNum = parseFloat(pNum.toFixed(pDigits));
        if ((true === pToExp) && (0 === aFixedNum) && (pNum > 0)) {
            return pNum.toExponential(pDigits);
        }

        return aFixedNum.toString();
    }
    //__________________________________________________________________________________________
    public static getLinearInterpolationValue(x: number, x1: number, x2: number, y1: number,
        y2: number) {
        if (x2 == x1) {
            return y1;
        }

        return (y1 + ((y2 - y1) * ((x - x1) / (x2 - x1))));
    }
    //__________________________________________________________________________________________
    public static solveQuadraticEquation(a: number, b: number, c: number) {
        let x1 = null;
        let x2 = null;

        let aDiscriminant = ((b * b) - (4 * a * c));
        if (aDiscriminant >= 0) {
            x1 = ((-b + Math.sqrt(aDiscriminant)) / (2 * a));
            x2 = ((-b - Math.sqrt(aDiscriminant)) / (2 * a));
        }

        return {
            x1: x1,
            x2: x2
        };
    }
    //__________________________________________________________________________________________
    public static roundNum(pNum: number, pDigits: number = 0) {
        return parseFloat(pNum.toFixed(pDigits));
    }
    //__________________________________________________________________________________________
    public static ceilFloat(pNum: number, pDigits: number = 0) {
        let aNum = OP3DMathUtils.roundNum(pNum, (pDigits + 1));
        let aFactor = Math.pow(10, pDigits);

        aNum = Math.ceil(aNum * aFactor);
        aNum /= aFactor;

        return aNum;
    }
    //__________________________________________________________________________________________
    /**
     * @param pStart returns all numbers in desired range by given delta
     */
    static range(pStart: number, pEnd: number, pDelta: number) {
        let aValues = new Array<number>();
        for (let i = pStart; i < pEnd; i += pDelta) {
            aValues.push(i);
        }

        return aValues;
    }
    //__________________________________________________________________________________________
    /**
     * 
     * @param pDia diameter of optical element
     * @param pR1 
     * @param pR2 
     * @returns the sag of each side
     * 
     *    <-s1-> <-s2->
     *    |   |  |    |
     *    |   |__|__ |
     *    |  /|  |  \ |
     *    | / |  |   \| 
     *    ||  |  |   || 
     *    | \ |  |   /|
     *    |  \|__|__/ | 
     */
    //__________________________________________________________________________________________
    public static getSag(pDia: number, pR1: number, pR2: number) {
        let aR1 = Math.abs(pR1);
        let aR2 = Math.abs(pR2);

        let b1 = Math.asin(pDia / (2 * aR1));
        let b2 = Math.asin(pDia / (2 * aR2));

        let aDist1 = ((pDia / 2) / Math.tan(b1));
        let aDist2 = ((pDia / 2) / Math.tan(b2));

        let aDelta1 = aR1 - aDist1;
        let aDelta2 = pR2 != null ? aR2 - aDist2 : 0;

        return {
            sag1: aDelta1,
            sag2: aDelta2
        };
    }
    //__________________________________________________________________________________________
    public static getScientificValue(pValue: number) {
        let aRes = pValue.toString().length > 4 ? pValue.toExponential(1) : pValue.toString();
        return aRes;
    }
    //__________________________________________________________________________________________
    static distance2(pX1: number, pY1: number, pX2: number, pY2: number): number {
        let aDx = pX1 - pX2;
        let aDy = pY1 - pY2;
        return (Math.sqrt((aDx * aDx) + (aDy * aDy)));
    }
    //__________________________________________________________________________________________
    public static min(pArray: Array<number>): number {
        let aMinVal = Number.MAX_VALUE;
        for (let item in pArray) {
            if (pArray[item] < aMinVal) {
                aMinVal = pArray[item];
            }
        }

        return aMinVal;
    }
    //__________________________________________________________________________________________
    public static maxIndex(pArr: Array<number> | iNumericKeyHash<number>) {
        let aMaxIndex = null;
        let aMaxVal = Number.MIN_VALUE;

        for (var item in pArr) {
            if (pArr[item] != null && pArr[item] > aMaxVal) {
                aMaxVal = pArr[item];
                aMaxIndex = item;
            }
        }
        return aMaxIndex;
    }
    //__________________________________________________________________________________________
    public static max(pArr: Array<number> | iNumericKeyHash<number>) {
        let aMax = Number.MIN_VALUE;

        for (var item in pArr) {
            if (pArr[item] != null && pArr[item] > aMax) {
                aMax = pArr[item];
            }
        }
        return aMax;
    }
    //__________________________________________________________________________________________
    /**
     * @description this function calculates and returns the vref according to given parameters
     * @param pV_in vector in 
     * @param pNormal normal of surface
     * @important make sure that the vectors are normalized
     */
    //__________________________________________________________________________________________
    public static calculateVref(pV_in: Vector3, pNormal: Vector3) {
        let N_dot_V = pNormal.dot(pV_in);
        let Vref = new Vector3(
            pV_in.x - 2 * N_dot_V * pNormal.x,
            pV_in.y - 2 * N_dot_V * pNormal.y,
            pV_in.z - 2 * N_dot_V * pNormal.z);

        return Vref.normalize();
    }
    //__________________________________________________________________________________________
    public static calculateEFL(
        pR1: number,
        pR2: number,
        pN: number,
        pThickness: number,
        pIsPlano: boolean,
        pRadius: number) {

        const aMMToWorld = 1;
        const aPhi1 = (pN - 1) / pR1;
        let aPhi2 = (1 - pN) / pR2;

        let aZ1 = OpticsShapeUtils.calcZCircleSpherical(0, 0,
            pR1 * aMMToWorld) * aMMToWorld;

        aZ1 -= OpticsShapeUtils.calcZCircleSpherical(0,
            pRadius * aMMToWorld,
            pR1 * aMMToWorld) * aMMToWorld;

        let aZ2 = OpticsShapeUtils.calcZCircleSpherical(0, 0,
            pR2 * aMMToWorld) * aMMToWorld;

        aZ2 -= OpticsShapeUtils.calcZCircleSpherical(0,
            pRadius * aMMToWorld, pR2 * aMMToWorld) * aMMToWorld;

        let aD = pThickness + aZ1 + aZ2;
        if (pIsPlano) {
            aPhi2 = 0;
            aD = pThickness + aZ1;
        }

        let aPhi = aPhi1 + aPhi2 - aPhi1 * aPhi2 * (aD / pN);
        let aEFL = 1 / aPhi;
        return aEFL;
    }
    //__________________________________________________________________________________________
    public static calculateFFL(pR1: number, pR2: number,
        pN: number, pThickness: number,
        pIsPlano: boolean,
        pRadius: number,
    ) {


        let aPhi1 = (pN - 1) / pR1;
        let aPhi2 = (1 - pN) / pR2;
        let aMMToWorld = 1;
        let aZ1 = OpticsShapeUtils.calcZCircleSpherical(0, 0,
            pR1 * aMMToWorld) * aMMToWorld;
        aZ1 -= OpticsShapeUtils.calcZCircleSpherical(0,
            pRadius * aMMToWorld,
            pR1 * aMMToWorld) * aMMToWorld;

        let aZ2 = OpticsShapeUtils.calcZCircleSpherical(0, 0,
            pR2 * aMMToWorld) * aMMToWorld;
        aZ2 -= OpticsShapeUtils.calcZCircleSpherical(0, pRadius * aMMToWorld,
            pR2 * aMMToWorld) * aMMToWorld;

        let aD = pThickness + aZ1 + aZ2;
        if (pIsPlano) {
            aPhi2 = 0;
            aD = pThickness + aZ1;
        }
        let aPhi = aPhi1 + aPhi2 - aPhi1 * aPhi2 * (aD / pN);
        let aEFL = 1 / aPhi;


        let aP = (aPhi2 / aPhi) * (1 / pN) * aD;
        let aFFL = -aEFL + aP;
        return aFFL;
    }
    //__________________________________________________________________________________________
    public static calculateRS_GraphMaterial(pTheta, n_input, n_element) {
        let a = (n_input / n_element) * Math.sin(pTheta);
        let a2 = a * a;

        let b = n_input * Math.cos(pTheta) - (n_element * Math.sqrt(1 - a2))
        let c = n_input * Math.cos(pTheta) + (n_element * Math.sqrt(1 - a2))

        let aRes = Math.pow(Math.abs(b / c), 2);
        return aRes;
    }
    //__________________________________________________________________________________________
    public static calculateRP_GraphMaterial(pTheta, n_input, n_element) {
        //Haim - what if a2 < 1 ? 
        let a = (n_input / n_element) * Math.sin(pTheta);
        let a2 = a * a;

        let b = (n_input * Math.sqrt(1 - a2)) - n_element * Math.cos(pTheta)
        let c = (n_input * Math.sqrt(1 - a2)) + n_element * Math.cos(pTheta);

        let aRes = Math.pow(Math.abs(b / c), 2);
        return aRes;
    }
    //__________________________________________________________________________________________
    public static calculateBfl(
        pR1: number,
        pR2: number,
        pN: number,
        pThicknessCenter: number,
        pRadius: number) {

        let aMMToWorld = 1;
        let aPhi1 = (pN - 1) / pR1;
        let aPhi2 = (1 - pN) / pR2;

        let aZ1 = OpticsShapeUtils.calcZCircleSpherical(0, 0,
            pR1 * aMMToWorld);

        aZ1 -= OpticsShapeUtils.calcZCircleSpherical(0,
            pRadius * aMMToWorld,
            pR1 * aMMToWorld);

        let aZ2 = OpticsShapeUtils.calcZCircleSpherical(0, 0,
            pR2 * aMMToWorld);

        aZ2 -= OpticsShapeUtils.calcZCircleSpherical(0,
            pRadius * aMMToWorld,
            pR2 * aMMToWorld)



        let aD = pThicknessCenter + (aZ1) + (aZ2);
        let aPhi = aPhi1 + aPhi2 - aPhi1 * aPhi2 * (aD / pN);
        let aEFL = 1 / aPhi;
        let aP = -(aPhi1 * aEFL / pN) * pThicknessCenter;


        //let aBFL = (1 - (((pN - 1) * aD) / (pN * pR1))) * aEFL;
        let aBFL = aP + aEFL;
        return aBFL;
    }
    //__________________________________________________________________________________________
    private static mr_Eq1(a: number, b: number) {

        let epseq1 = 0.5e-06

        let d = Math.abs(a - b);
        if (0 == d) {
            return true;
        }
        let a1 = Math.abs(a);
        let b1 = Math.abs(b);
        if (a1 < epseq1 || b1 < epseq1) {
            return (d < epseq1);
        }
        let big = (a1 > b1) ? a1 : b1;

        return (d / big < epseq1);
    }
    //__________________________________________________________________________________________
    private static mr_Eq3(a0: Vector3, a1: Vector3) {
        return (this.mr_Eq1(a0.x, a1.x) && this.mr_Eq1(a0.y, a1.y) && this.mr_Eq1(a0.z, a1.z));
    }
    //__________________________________________________________________________________________
    public static mr_vectprod3(pVec1: Vector3, pVec2: Vector3): Vector3 {

        let aNewVec = new Vector3();
        aNewVec.x = pVec1.y * pVec2.z - pVec2.y * pVec1.z;
        aNewVec.y = -(pVec1.x * pVec2.z - pVec2.x * pVec1.z);
        aNewVec.z = pVec1.x * pVec2.y - pVec2.x * pVec1.y;

        return aNewVec;
    }
    //__________________________________________________________________________________________
    public static angleBetweenVectors(pVec1: Vector3, pVec2: Vector3) {
        let zero = new Vector3();
        if (this.mr_Eq3(pVec1, zero) || this.mr_Eq3(pVec2, zero)) {
            return 2 * Math.PI;
        }
        let c = pVec1.dot(pVec2) / pVec1.length() / pVec2.length();
        if (Math.abs(c) > 1.0) {
            if (c < 0) {
                c = -1;
            } else {
                c = 1;
            }
        }

        return Math.acos(c);
    }
    //__________________________________________________________________________________________
    public static isInRange(pNum: number, pRange: iMinMax, pIncludeBoundaries: boolean = true, pEpsilon: number = MathContext.EPSILON_10) {
        const aMin = pRange.min - pEpsilon;
        const aMax = pRange.max + pEpsilon;

        if (true === pIncludeBoundaries) {
            return ((pNum >= aMin) && ((pNum <= aMax)));
        }

        return ((pNum > aMin) && ((pNum < aMax)));
    }
    //__________________________________________________________________________________________
    public static isInErrorRange(pDesiredResult: number, pActualResult: number,
        pEpsilon: number = MathContext.EPSILON_7) {
        if (pDesiredResult == pActualResult) {
            return true;
        }

        if (pDesiredResult == null || pActualResult == null) {
            return false;
        }

        return (((pActualResult + pEpsilon) >= pDesiredResult) &&
            ((pActualResult - pEpsilon) <= pDesiredResult));
    }
    //__________________________________________________________________________________________
    public static pow10(pExponent: number) {
        let aUnit = 1;
        if (pExponent >= 0) {
            for (let i = 0; i < pExponent; i++) {
                aUnit *= 10;
            }

            return aUnit;
        } else {
            for (let i = 0; i < Math.abs(pExponent); i++) {
                aUnit /= 10;
            }

            return aUnit;
        }
    }
    //__________________________________________________________________________________________
    public static cubicInterpolates(pX: Array<number>, pY: Array<number>, xInterpolates: Array<number>) {

        let aSpline = new Spline(pX, pY);
        let aNewPoints = new Array<iPoint2D>();
        for (let i = 0; i < xInterpolates.length; i++) {
            let aCurrY = aSpline.at(xInterpolates[i])
            aNewPoints.push({ x: xInterpolates[i], y: aCurrY });
        }

        return aNewPoints;
    }
    //__________________________________________________________________________________________
    public static calculateDistance3D(pPoint1: Vector3, pPoint2: Vector3) {
        //refactor
        //return pPoint1.distanceTo(pPoint2);
        let aDistance = pPoint1.distanceTo(pPoint2)

        // let x2 = Math.pow(pPoint2.x - pPoint1.x, 2);
        // let y2 = Math.pow(pPoint2.y - pPoint1.y, 2);
        // let z2 = Math.pow(pPoint2.z - pPoint1.z, 2);
        // let aDist = Math.sqrt(x2 + y2 + z2);
        return aDistance;
    }
    //__________________________________________________________________________________________
    public static returnCloserValue(pValue: number, pVal1: number, pVal2: number) {
        let aDist1 = Math.abs(pVal1 - pValue);
        let aDist2 = Math.abs(pVal2 - pValue);

        return (aDist1 > aDist2 ? pVal1 : pVal2);
    }
    //__________________________________________________________________________________________
    public static clampValue(pVal: number, pMin?: number, pMax?: number): number {

        return Math.max(pMin, Math.min(pVal, pMax))
        // if ((false == isNaN(pMin)) && (pVal < pMin)) {
        //     return pMin;
        // }
        // if ((false == isNaN(pMax)) && (pVal > pMax)) {
        //     return pMax;
        // }
    }
    //__________________________________________________________________________________________
    public static getUnscaledMatrix(m: Matrix4) {
        let r = new Matrix4().extractRotation(m);
        r.elements[12] = m.elements[12];
        r.elements[13] = m.elements[13];
        r.elements[14] = m.elements[14];

        return r;
    }
    //__________________________________________________________________________________________
    public static getFloatRound(pNum: number, pN: number) {
        let aDeltaFromNext = (pN - (parseFloat((pNum % pN).toFixed(10)) % pN));
        let aDeltaFromPrev = (pN - aDeltaFromNext);

        return ((aDeltaFromNext > aDeltaFromPrev) ? (pNum + aDeltaFromNext) :
            (pNum - aDeltaFromPrev));
    }
    //__________________________________________________________________________________________
    public static prevDividedByN(pNum: number, pN: number) {
        let aDeltaFromNext = (pN - (parseFloat((pNum % pN).toFixed(10)) % pN));
        let aNext = parseFloat((pNum + aDeltaFromNext).toFixed(10));
        let aNew = parseFloat((aNext - pN).toFixed(10));


        let aPrev = ((pNum == aNew) ? parseFloat((aNext - (2 * pN)).toFixed(10)) : aNew);

        return aPrev;
    }
    //__________________________________________________________________________________________
    public static nextDividedByN(pNum: number, pN: number) {
        let aDeltaFromNext = (pN - (parseFloat((pNum % pN).toFixed(10)) % pN));
        let aNext = (pNum + aDeltaFromNext)

        return aNext;
    }
    //__________________________________________________________________________________________
    public static factorial(pNum: number) {
        if (pNum < 0) {
            return -1;
        }

        if ((0 == pNum) || (1 == pNum)) {
            return 1;
        }

        let aRes = pNum;
        while (pNum > 1) {
            pNum--;
            aRes *= pNum;
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    public static findTwoLinesIntersection(pLine1: iLine, pLine2: iLine) {
        if (true == this.isLinesParallels(pLine1, pLine2)) {
            if (true == OP3DMathUtils.isPointOnLine(pLine1, pLine2.origin)) {
                return (pLine1.origin);
            } else {
                return null;
            }
        }

        let o1 = pLine1.origin;
        let o2 = pLine2.origin;
        let d1 = pLine1.direction;
        let d2 = pLine2.direction;
        let I = new Vector3(1, 1, 1);

        let axis: string;
        if ((0 != d1.x) && (0 != d2.x)) {
            axis = 'x';
        } else if ((0 != d1.y) && (0 != d2.y)) {
            axis = 'y';
        } else if ((0 != d1.z) && (0 != d2.z)) {
            axis = 'z';
        } else {
            return null;
        }

        let o2_o1 = new Vector3().subVectors(o2, o1);

        let t = (o1[axis] - o2[axis]) * d1.dot(I);
        t += (o2_o1.dot(I) * d1[axis]);
        t /= (d2[axis] * d1.dot(I) - d1[axis] * d2.dot(I));

        let s = ((o2_o1.dot(I) + t * d2.dot(I)) / (d1.dot(I)));

        let p1 = o1.clone().add(d1.clone().multiplyScalar(s));
        // let p2 = o2.clone().add(d2.clone().multiplyScalar(t));

        return p1;
    }
    //__________________________________________________________________________________________
    public static isPointOnLine(pLine: iLine, pPoint: Vector3) {
        let p = pPoint;
        let o = pLine.origin;
        let d = pLine.direction;

        let s: number;
        if (0 != d.x) {
            s = ((p.x = o.x) / d.x);
        } else if (0 != d.y) {
            s = ((p.y = o.y) / d.y);
        } else if (0 != d.z) {
            s = ((p.z = o.z) / d.z);
        }
        let axis: string;
        if (0 != d.x) {
            axis = 'x';
        } else if (0 != d.y) {
            axis = 'y';
        } else if (0 != d.z) {
            axis = 'z';
        } else {
            return false;
        }
        s = ((p[axis] = o[axis]) / d[axis]);

        let pl = o.clone().add(d.clone().multiplyScalar(s));
        return pl.equals(p);
    }
    //__________________________________________________________________________________________
    public static isLinesParallels(pLine1: iLine, pLine2: iLine) {
        let aAngle = pLine1.direction.angleTo(pLine2.direction);
        return ((0 == aAngle) || (Math.PI == aAngle));
    }
    //__________________________________________________________________________________________
    public static getVecToVecRotMatrix(pFrom: Vector3, pTo: Vector3) {
        let aRotV = new Vector3().crossVectors(pTo, pFrom);
        if (0 == aRotV.length()) {
            aRotV = pTo.clone();
        }

        aRotV.normalize();
        let aAngle = pTo.angleTo(pFrom);
        let c = Math.cos(aAngle);
        let s = Math.sin(aAngle);
        let t = (1 - c);

        let x = aRotV.x;
        let y = aRotV.y;
        let z = aRotV.z;

        let cx = t * x;
        let cy = t * y;
        let cz = t * z;

        let sx = s * x;
        let sy = s * y;
        let sz = s * z;


        let rotMat = new Matrix4().set(
            ((cx * x) + c), ((cx * y) - sz), ((cx * z) + sy), 0,
            ((cx * y) + s), ((cy * y) + c), ((cy * z) - sx), 0,
            ((cx * z) - sy), ((cy * z) + sx), ((cz * z) + c), 0,
            0, 0, 0, 1
        );

        return rotMat;
    }
    //__________________________________________________________________________________________
    public static isCloseToLinear(p1: iPoint2D, p2: iPoint2D, p3: iPoint2D, pEpsilon: number) {
        let s1 = ((p2.y - p1.y) / (p2.x - p1.x));
        let s2 = ((p3.y - p1.y) / (p3.x - p1.x));

        return ((Math.sign(s1) == Math.sign(s2)) && (Math.abs(s2 - s1) <= pEpsilon));
    }
    //__________________________________________________________________________________________
    public static getVecToVecRotMatrix2(pFrom: Vector3, pTo: Vector3) {
        let aRotV = new Vector3().crossVectors(pTo, pFrom);
        if (0 == aRotV.length()) {
            aRotV = pTo.clone();
        }

        aRotV.normalize();
        let aAngle = pTo.angleTo(pFrom);
        let rotMat = new Matrix4().makeRotationAxis(aRotV, -aAngle)
        return rotMat;
    }
    //__________________________________________________________________________________________
    public static countDecimalPoints(pNum: number) {
        let aDecimalPoints = 0;
        let aFloatingPoints = pNum.toString().split('.')[1];
        if (undefined !== aFloatingPoints) {
            aDecimalPoints = aFloatingPoints.length;
        }

        return aDecimalPoints;
    }
    //__________________________________________________________________________________________
}
