import { useContext, useEffect, useRef, useState } from "react";
import classes from "./../../UI/optichat-message-input.module.scss";
import sendBtnInactive from "../../../../../public/images/icons_new/chatbot/send_btn_inactive.svg";
import sendBtnActive from "../../../../../public/images/icons_new/chatbot/send_btn_active.svg";
import OptiChatContext from "../../store/OptiChatContext";
import OptiChatReviewBtn from "../review/OptiChatReviewBtn";

export default function OptiChatMessageInput(props: {
  isDisabled: boolean;
  hasReviewBtn: boolean;
  onSendMessage;
}) {
  const { data } = useContext(OptiChatContext);
  const textareaRef = useRef<HTMLTextAreaElement>();
  const [isSendEnabled, setIsSendEnabled] = useState(false);

  const updateSendBtn = () => {
    let aValue = textareaRef.current.value.trim();
    setIsSendEnabled(
      aValue.length > 0 && data.current.isWaitingServerResponse === false
    );
  };

  useEffect(() => {
    updateSendBtn();
  }, []);

  const onKeyUpHandler = () => {
    updateSendBtn();
  };

  const onKeyDownHandler = (e) => {
    // updateSendBtn();

    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (e.ctrlKey === false) {
        onSendHandler();
      } else {
        textareaRef.current.value = textareaRef.current.value + "\n";
      }
    }
  };

  const onSendHandler = () => {
    if (isSendEnabled === false) {
      return;
    }

    let aMessage = textareaRef.current.value.trim();
    setIsSendEnabled(false);
    textareaRef.current.value = "";

    updateSendBtn();
    props.onSendMessage(aMessage);
  };

  return (
    <div className={classes["chat-message-input"]}>
      <div className={classes.message}>
        <div className={props.isDisabled ? classes.disabled : ""}>
          <div className={classes.textarea}>
            <textarea
              data-testid="message-input"
              placeholder="Write a message"
              ref={textareaRef}
              onKeyDown={onKeyDownHandler}
              onKeyUp={onKeyUpHandler}
            ></textarea>
          </div>
          <div className={classes["send-btn"]}>
            {isSendEnabled ? (
              <img src={sendBtnActive} onClick={onSendHandler} />
            ) : (
              <img src={sendBtnInactive} className={classes.disabled} />
            )}
          </div>
        </div>
      </div>
      {props.hasReviewBtn && <OptiChatReviewBtn />}
    </div>
  );
}
