import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { Part } from "../parts/Part";
import { ViewUtils } from "../ui/ViewUtils";
import { Box3, Vector3 } from "three";
import { iHTMLBorderData, iHash, iRGBA } from "../_context/_interfaces/Interfaces";
import { Strings } from "../_context/Strings";


export interface iCSS2DLabelParams {
    label?: string;
    options?: iCSS2DContainerParams;
    type: eLabelType;
}

export enum eLabelType {
    LABEL = 'label',
    POWER = 'power'
}


export interface iCSS2DContainerParams {
    backgroundColor?: iRGBA;
    fontSize?: number;
    fontColor?: iRGBA;
    border?: iHTMLBorderData;
}

export class CSS2DLabel {
    private mLabelObject;
    private mLabelContainer: HTMLDivElement;

    private mLabelsHash: iHash<HTMLElement> = {};

    constructor(pPart: Part) {
        this._init(pPart);
    }
    //__________________________________________________________________________________________
    public update(pPart: Part, pCSS2DLabelParams: iCSS2DLabelParams) {
        let aDiv = this.mLabelsHash[pCSS2DLabelParams.type];
        if (null == aDiv) {
            aDiv = document.createElement('div');
            aDiv.classList.add('sprite-label');
            this.mLabelContainer.appendChild(aDiv);
            ViewUtils.setElementVisibilityByDNone(aDiv, false);
            this.mLabelsHash[pCSS2DLabelParams.type] = aDiv;
        }

        if (null != pCSS2DLabelParams.options) {
            if (null != pCSS2DLabelParams.options.backgroundColor) {
                this._setBackGroundColor(aDiv, pCSS2DLabelParams.options.backgroundColor);
            }
            if (null != pCSS2DLabelParams.options.fontColor) {
                this._setFontColor(aDiv, pCSS2DLabelParams.options.fontColor);
            }
            if (null != pCSS2DLabelParams.options.fontSize) {
                this._setFontSize(aDiv, pCSS2DLabelParams.options.fontSize);
            }
            if (null != pCSS2DLabelParams.options.border) {
                this._setBorder(aDiv, pCSS2DLabelParams.options.border);
            }
        }

        if (null != pCSS2DLabelParams.label) {
            aDiv.innerHTML = pCSS2DLabelParams.label;
        }

        this.updatePosition(pPart);

        return this;
    }
    //__________________________________________________________________________________________
    private _init(pPart: Part) {
        this.mLabelContainer = document.createElement('div');
        this.mLabelContainer.classList.add('sprite-label-cot');
        this.mLabelContainer.id = Op3dUtils.idGenerator();

        this.mLabelObject = new CSS2DObject(this.mLabelContainer);
        this.mLabelObject.name = 'part_label';

        this.updatePosition(pPart);

        pPart.visibleObj.add(this.mLabelObject);
    }
    //__________________________________________________________________________________________
    public get labelObject() {
        return this.mLabelObject;
    }
    //__________________________________________________________________________________________
    /* Notice, for the parts starting height(y) position will be 100, but for the label it will be 0*/
    public updatePosition(pPart: Part) {
        let aVisibleObject = pPart.visibleObj;

        let aItem = Op3dUtils.getNetoItem(aVisibleObject);
        let aWorldPos = aVisibleObject.getWorldPosition(new Vector3());
        let aBox = new Box3().setFromObject(aItem);
        let aY = (aBox.max.y - aWorldPos.y + 5);
        if (isNaN(aY) === true) {
            aY = 0;
        }
        this.mLabelObject.position.set(0, aY, 0);
    }
    //__________________________________________________________________________________________
    /* Use before deleting the part with label, after deleting the parent part you won't be able to call removeLabel()*/
    private _removeFromDom() {
        ViewUtils.removeFromParent(this.mLabelContainer);
    }
    //__________________________________________________________________________________________
    public removeLabelFromType(pType: eLabelType) {
        let aDiv = this.mLabelsHash[pType];
        if (null == aDiv) {
            return;
        }

        ViewUtils.removeFromParent(aDiv);
        delete this.mLabelsHash[pType];
    }
    //__________________________________________________________________________________________
    public removeFromParent(pPart: Part) {
        this._removeFromDom();
        let aLabel = pPart.visibleObj?.getObjectByName('part_label');
        if (null != aLabel?.parent) {
            aLabel.parent.remove(aLabel);
        }
    }
    //__________________________________________________________________________________________
    public setLabelVisibility(pType: eLabelType, pIsVisible: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mLabelsHash[pType], pIsVisible);
    }
    //__________________________________________________________________________________________
    public isVisible(pType: eLabelType) {
        return ((undefined !== this.mLabelsHash[pType]) &&
            ((false === this.mLabelsHash[pType].classList.contains(Strings.D_NONE))));
    }
    //__________________________________________________________________________________________
    public isExist(pType: eLabelType) {
        return (undefined !== this.mLabelsHash[pType]);
    }
    //__________________________________________________________________________________________
    public _setFontSize(pDiv: HTMLElement, pFontSize: number) {
        pDiv.style.fontSize = `${pFontSize}px !important`;
    }
    //__________________________________________________________________________________________
    public _setFontColor(pDiv: HTMLElement, pRGBA?: iRGBA) {
        pDiv.style.color = `rgba( ${pRGBA.r}, ${pRGBA.g}, ${pRGBA.b}, ${pRGBA.a} )`;
    }
    //__________________________________________________________________________________________
    public _setBackGroundColor(pDiv: HTMLElement, pRGBA?: iRGBA) {
        pDiv.style.backgroundColor = `rgba( ${pRGBA.r}, ${pRGBA.g}, ${pRGBA.b}, ${pRGBA.a} )`;
    }
    //__________________________________________________________________________________________
    public _setBorder(pDiv: HTMLElement, pBorder: iHTMLBorderData) {
        pDiv.style.border = `${pBorder.thickness}px ${pBorder.style} 
        rgba( ${pBorder.color.r}, ${pBorder.color.g}, ${pBorder.color.b}, ${pBorder.color.a} )`;
    }
    //__________________________________________________________________________________________
    public _setBorderRadius(pDiv: HTMLElement, pRadius: number) {
        pDiv.style.borderRadius = `${pRadius}px !important`;
    }
    //__________________________________________________________________________________________
    public mainParentExists(){
        const aResult = this.mLabelsHash.label.parentElement.parentElement != null;
        return aResult;
    }

    //__________________________________________________________________________________________
}
