import { EventManager } from "../../../../../oc/events/EventManager";
import { ePARAM_TYPE } from "../../../../_context/Enums";
import { EventsContext } from "../../../../_context/EventsContext";
import { MathContext } from "../../../../_context/MathContext";
import { OpticsContext } from "../../../../_context/OpticsContext";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import {  ePolarizerWaveplateType, iOpticsVO,  iPolarizerData } from "../../../../data/VO/OpticsVOInterfaces";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { ViewUtils } from "../../../ViewUtils";
import { piPolarizationSection } from "../../../part_info/_light_source_section/piPolarizationSection";
import { iParamInfo } from "../uoGeometricalInfo";
import { uoSection } from "../uoSection";
import { uoGeometricalInfoNew } from "./uoGeometrialInfoNew";

export enum ePolarizerMaterialIndexes{
    USER_DEFINED,
    MATERIAL_DATA
}
export enum eParamsChanged{
    ORDINARY = 'ordinaryRefractiveIndex',
    EXTRA = 'extraRefractiveIndex',
    WAVELENGTH = 'designWavelength',
    THICKNESS = 'thickness',
    EXTINCTION_RATIO = 'extinctionRatio',
    POLARIZATION_RATIO = 'polarizationRatio',
}
export class uoPolarizingElementInfo extends uoSection<iOpticsVO, iPolarizerData> {

    private static SKIN_PATH = './skins/forms/optics/uo_polarizer_info.html'
    public static CHANGED_PARAM = null;
    private static PARAMS_INFO: iHash<iParamInfo> = {
        polarizerDirectionAngleDeg: {
            name: 'Polarizer direction angle',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            unit: 'deg',
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 0
                }else{
                    pElem.element.value = pElem.element.value > 180 ? 180 : pElem.element.value ;
                    pElem.element.value = pElem.element.value < 0 ? 0 : pElem.element.value ;
                }
            },
        },
        extinctionRatio: {
            name: 'Extinction ratio',
            type: ePARAM_TYPE.NON_DIMENTIONAL_NUMBER,
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 100
                }else{
                    pElem.element.value = pElem.element.value > 100 ? 100 : pElem.element.value ;
                    pElem.element.value = pElem.element.value < 0 ? 0 : pElem.element.value ;
                }
                this.CHANGED_PARAM = eParamsChanged.EXTINCTION_RATIO
            },
            titleFunc: () => {
                let aTitle = 'dB';

                return aTitle;
            },

        },
        polarizationRatio: {
            name: 'Polarization ratio',
            type: ePARAM_TYPE.NON_DIMENTIONAL_NUMBER,
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 100
                }else{
                    pElem.element.value = pElem.element.value > 10000000000 ? 10000000000 : pElem.element.value ;
                    pElem.element.value = pElem.element.value < 0 ? 0 : pElem.element.value ;
                }
                this.CHANGED_PARAM = eParamsChanged.POLARIZATION_RATIO

            },
            titleFunc: () => {
                let aTitle = 'Unitless';

                return aTitle;
            },

        },
        ordinaryRefractiveIndex: {
            name: 'Ordinary refractive index',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 1.5
                }
                this.CHANGED_PARAM = eParamsChanged.ORDINARY
                
            }
        },
        extraRefractiveIndex: {
            name: 'Extraordinary refractive index',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 1.53
                }
                this.CHANGED_PARAM = eParamsChanged.EXTRA}
        },
        extraAxisDirection: {
            name: 'Extraordinary axis direction angle',
            type: ePARAM_TYPE.ANGLE_DEG,
            unit: 'deg',
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 0
                }else{
                    pElem.element.value = pElem.element.value > 180 ? 180 : pElem.element.value ;
                    pElem.element.value = pElem.element.value < 0 ? 0 : pElem.element.value ;
                }
            }
        },
        designWavelength: {
            name: 'Design wavelength',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            unit: 'nm',
            onChange: (pElem) => {
                if(isNaN(Number(pElem.element.value))){
                    pElem.element.value = 550
                }
                this.CHANGED_PARAM = eParamsChanged.WAVELENGTH
            }
        },
    }
    private mOneParamElement: HTMLElement;
    private mParamsParent: HTMLElement;
    private mPolarizerType: any;
    private mWaveplateSelectorsGeneralContainer: HTMLElement;
    private mWaveplateTypeSelector: HTMLSelectElement;
    private mMaterialSelector: HTMLSelectElement;
    private mMaterialRefractiveIndex: any;
    private mLensGeoPropertiesInstance: uoGeometricalInfoNew;
    private mDefaultRefractiveIndexDifference: number = 0.03;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoPolarizingElementInfo.SKIN_PATH,
            title: 'Polarizing element settings',
            collapseQaId: "uo_polarizer_info_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: true
        });
    }
    //__________________________________________________________________________________________
    protected async _setData(pOpticsVO: iOpticsVO) {
        this._clear();
        this._fillData(pOpticsVO.parameters.polarizer_data as iPolarizerData);
        const aData = pOpticsVO.parameters.polarizer_data
        if(aData?.waveplateSubtype !== undefined){
            this.mMaterialSelector.options.selectedIndex = aData.getDataFromMaterialSettings === true ? ePolarizerMaterialIndexes.MATERIAL_DATA : ePolarizerMaterialIndexes.USER_DEFINED
            this.mWaveplateTypeSelector.options.selectedIndex = aData.waveplateSubtype === ePolarizerWaveplateType.QWP ? 0 : 1;
        }
    }
    //__________________________________________________________________________________________
    private _fillData(pOpticsVOPolarizerData: iPolarizerData) {
        
        if(pOpticsVOPolarizerData !== undefined){
            if(pOpticsVOPolarizerData.extraAxisDirection !== undefined){
                this.mPolarizerType = OpticsContext._Ideal_Waveplate;
                
                ViewUtils.setElementVisibilityByDNone(this.mWaveplateSelectorsGeneralContainer, true)
            }else{
                this.mPolarizerType = OpticsContext._Ideal_Polarizer; 
                ViewUtils.setElementVisibilityByDNone(this.mWaveplateSelectorsGeneralContainer, false)
            }
        }

        for (let key in pOpticsVOPolarizerData) {
            let aParamInfo = uoPolarizingElementInfo.PARAMS_INFO[key];
            if (null == aParamInfo) {
                continue;
                //throw new Error("Key is not defined!");
            }

            if ((null != aParamInfo.show) &&
                (false == aParamInfo.show(pOpticsVOPolarizerData[key]))) {
                continue;
            }

            let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
            this.mParamsParent.appendChild(aOneParam);
            let aParamName = Op3dUtils.getElementIn(aOneParam, 'param_name');
            if (aParamName === null) {
                throw new Error("Param name not found!");
            }

            aParamName.innerHTML = aParamInfo.name;

            let aValue = pOpticsVOPolarizerData[key];
            let aUnitParam = Op3dUtils.getElementIn(aOneParam, 'unit_span') as HTMLElement;
            switch (key) {
                case 'extinctionRatio':
                    aUnitParam.innerHTML = 'dB';
                    break;
                case 'polarizationRatio':
                    aUnitParam.innerHTML = 'Unitless'
                    break;               
                case 'designWavelength':
                    aUnitParam.innerHTML = 'nm'
                    break;
                case 'extraAxisDirection':
                case 'polarizerDirectionAngleDeg':
                    aUnitParam.innerHTML = 'Deg';
                    break;
                default:
                    ViewUtils.removeFromParent(aUnitParam);
                    break;
            }

            let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as HTMLInputElement;
            aInput.value = aValue.toString();
            aInput.title = aValue.toString();

            aInput.setAttribute('param_key', key);
            aInput.addEventListener('change', () => {
                if (null != aParamInfo.onChange) {
                    aParamInfo.onChange({ parent: this.mParamsParent, element: aInput });
                }

                this._onChange();
            });

            aInput.disabled = (false == aParamInfo.isEnabled);
        }

        let aHasPresentedData = (this.mParamsParent.children.length > 0);
        ViewUtils.setElementVisibilityByDNone(this.mContainer, aHasPresentedData);
    }
    //__________________________________________________________________________________________
    private _onChange() {
        let aThickness;
        if(uoPolarizingElementInfo.CHANGED_PARAM === eParamsChanged.ORDINARY || uoPolarizingElementInfo.CHANGED_PARAM === eParamsChanged.WAVELENGTH){
            aThickness = this.calculateCurrentData() // if changed all except extra refractive index
            this.updateLensThickness(aThickness);
        }else if(uoPolarizingElementInfo.CHANGED_PARAM ===  eParamsChanged.EXTRA){
            aThickness = this.calculateCurrentDataWithChangedExtraRefractiveIndex() // if changed extra-refractive index
            this.updateLensThickness(aThickness);
        }else if(uoPolarizingElementInfo.CHANGED_PARAM === eParamsChanged.THICKNESS){
            const aExtrRefrIndex = this.calculateDataWithChangedThickness(this.mLensGeoPropertiesInstance.getData().thickness);
            this.updateNumberInputValue(aExtrRefrIndex, eParamsChanged.EXTRA)
        }else if(uoPolarizingElementInfo.CHANGED_PARAM === eParamsChanged.EXTINCTION_RATIO){
            const aPolarizRatio = piPolarizationSection.calculatePolarizationRatioByExtinction(this.getData().extinctionRatio)
            this.updateNumberInputValue(aPolarizRatio, eParamsChanged.POLARIZATION_RATIO)
        }else if(uoPolarizingElementInfo.CHANGED_PARAM === eParamsChanged.POLARIZATION_RATIO){
            const aExtinxtionRatio = piPolarizationSection.calculateExtinctionRatioByPolarization(this.getData().polarizationRatio)
            this.updateNumberInputValue(aExtinxtionRatio, eParamsChanged.EXTINCTION_RATIO)
        }
        
        EventManager.dispatchEvent(EventsContext.OPTICS_POLARIZER_DATA_SECTION_CHANGED, this);
        uoPolarizingElementInfo.CHANGED_PARAM = null;
     
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO): void {
        let aPhysicalData = this.getData();

        for (let key in aPhysicalData) {
            pOpticsVO.parameters.polarizer_data[key] = aPhysicalData[key];
        }
        pOpticsVO.parameters.polarizer_data = aPhysicalData;
    }
    //__________________________________________________________________________________________
    public getData() {
        let aData: iPolarizerData = {

        };

        let aScale = UnitHandler.presentedScale;
        $(this.mParamsParent).find('input[param_key]').each(
            (_index, element) => {
                let aKey = element.getAttribute('param_key');

                let aParamInfo = uoPolarizingElementInfo.PARAMS_INFO[aKey];
                let aValue = parseFloat((element as HTMLInputElement).value);
                switch (aParamInfo.type) {
                    case ePARAM_TYPE.DIMENTIONAL_NUMBER:
                        aValue /= aScale;
                        break;
                    case ePARAM_TYPE.ANGLE_RAD:
                        aValue *= MathContext.DEG_TO_RAD;
                        break;
                    default:
                        break;
                }

                aData[aKey] = aValue;
            }
        );
        if(this.mPolarizerType === OpticsContext._Ideal_Waveplate){
            aData.waveplateSubtype = this.mWaveplateTypeSelector.value
            aData.getDataFromMaterialSettings = this.mMaterialSelector.options.selectedIndex === ePolarizerMaterialIndexes.MATERIAL_DATA ? true : false
        }
        return aData;
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, false);
        ViewUtils.removeElementChildren(this.mParamsParent);
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param');
        this.mWaveplateSelectorsGeneralContainer = Op3dUtils.getElementIn(this.mContainer, 'polarizer_waveplate_selectors');
        this.mWaveplateTypeSelector = Op3dUtils.getElementIn(this.mContainer, 'waveplate_param_input') as HTMLSelectElement;
        this.mMaterialSelector = Op3dUtils.getElementIn(this.mContainer, 'material_param_input') as HTMLSelectElement;
        this.mParamsParent = this.mOneParamElement.parentElement as HTMLElement;
        this._clear();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mMaterialSelector.addEventListener('change', () => {
            this.changeMaterialOrdinaryRefractiveIndex();
            uoPolarizingElementInfo.CHANGED_PARAM = eParamsChanged.ORDINARY
            this._onChange();

        })
        this.mWaveplateTypeSelector.addEventListener('change', () => {
            uoPolarizingElementInfo.CHANGED_PARAM = eParamsChanged.ORDINARY
            this._onChange();
            
        })
    }
    //__________________________________________________________________________________________
    private changeMaterialOrdinaryRefractiveIndex(){
        if(this.mMaterialSelector.options.selectedIndex === ePolarizerMaterialIndexes.MATERIAL_DATA){
            $(this.mParamsParent).find('input[param_key]').each(
                (_index, element) => {
                    let aKey = element.getAttribute('param_key');
                    if(aKey === eParamsChanged.ORDINARY){
                        (element as undefined as HTMLInputElement).value = this.mMaterialRefractiveIndex[0].refractiveIndex;
                        ViewUtils.setElementDisabledAttribute(element, true);
                    }
                    else if(aKey === eParamsChanged.WAVELENGTH){
                        (element as undefined as HTMLInputElement).value = this.mMaterialRefractiveIndex[0].wavelength;
                        ViewUtils.setElementDisabledAttribute(element, true);
                    }
                }
            );
        }else{
            $(this.mParamsParent).find('input[param_key]').each(
                (_index, element) => {
                    let aKey = element.getAttribute('param_key');
                    if(aKey === eParamsChanged.ORDINARY){
                        (element as undefined as HTMLInputElement).value = '1.5';
                        ViewUtils.setElementDisabledAttribute(element, false);
                    }
                    else if(aKey === eParamsChanged.WAVELENGTH){
                        (element as undefined as HTMLInputElement).value = '550'
                        ViewUtils.setElementDisabledAttribute(element, true);
                    }
                }
            );
        }
    }
    //___________________________________________________________________________________________
    private calcExtraordinaryRefractiveIndex(pRefractiveIndex): number{
        let aResult = pRefractiveIndex + this.mDefaultRefractiveIndexDifference;
        return aResult;
    }
    //___________________________________________________________________________________________
    private calcRefractiveIndexDifference(pRefractiveIndex, pExtraRefractiveIndex,): number{
        let aResult = pExtraRefractiveIndex - pRefractiveIndex;
        this.mDefaultRefractiveIndexDifference = aResult;
        return aResult;
    }
    //___________________________________________________________________________________________
    private calcZeroShiftPeriod(pDesignWavelength, pRefractiveIndexDifference): number{
        let aResult = (pDesignWavelength / pRefractiveIndexDifference);
        return aResult;
    }
    //___________________________________________________________________________________________
    private calcZeroShiftThickness(pZeroShiftPeriod): number{
        let aResult = Math.trunc( (3 * 1e+6) / pZeroShiftPeriod) * pZeroShiftPeriod;
        return aResult;
    } 
    //___________________________________________________________________________________________
    private calcElementThickness(pZeroShiftThickness, pDesignWavelength: number, pRefractiveIndexDifference: number, pWaveplateType: ePolarizerWaveplateType): number{
        if(pWaveplateType === ePolarizerWaveplateType.HWP){
            let aResult = (pZeroShiftThickness + (pDesignWavelength / (2 * pRefractiveIndexDifference))) / 1e+6; // [nm] to [mm]
            return aResult;
        }else{
            let aResult = (pZeroShiftThickness + (pDesignWavelength / (4 * pRefractiveIndexDifference))) / 1e+6;
            return aResult;
        }
    }
    //___________________________________________________________________________________________
    private calculateCurrentData(){
        const aCurrData = this.getData()

        const aExtraRefractiveIndex = this.calcExtraordinaryRefractiveIndex(aCurrData.ordinaryRefractiveIndex);
        this.updateNumberInputValue(aExtraRefractiveIndex, eParamsChanged.EXTRA);

        const aExtraRefractiveIndexDifference = this.calcRefractiveIndexDifference(aCurrData.ordinaryRefractiveIndex, aExtraRefractiveIndex);
        const aZeroShiftPeriod = this.calcZeroShiftPeriod(aCurrData.designWavelength, aExtraRefractiveIndexDifference);
        const aZeroShiftThickness = this.calcZeroShiftThickness(aZeroShiftPeriod);
        const aElementThinckness = this.calcElementThickness(aZeroShiftThickness, aCurrData.designWavelength, aExtraRefractiveIndexDifference, this.getCurrentWaveplateType()); 
       
        return aElementThinckness
    }
    //___________________________________________________________________________________________
    private calculateCurrentDataWithChangedExtraRefractiveIndex(){
        const aCurrData = this.getData()

        const aExtraRefractiveIndexDifference = this.calcRefractiveIndexDifference(aCurrData.ordinaryRefractiveIndex, aCurrData.extraRefractiveIndex);
        const aZeroShiftPeriod = this.calcZeroShiftPeriod(aCurrData.designWavelength, aExtraRefractiveIndexDifference);
        const aZeroShiftThickness = this.calcZeroShiftThickness(aZeroShiftPeriod);
        const aElementThinckness = this.calcElementThickness(aZeroShiftThickness, aCurrData.designWavelength, aExtraRefractiveIndexDifference, this.getCurrentWaveplateType()); 
        
        return aElementThinckness
    }
    //___________________________________________________________________________________________
    private calculateDataWithChangedThickness(pElementThickness: number) {
    
        let aCurrData = this.getData()
    
        const aIndex = aCurrData.waveplateSubtype === ePolarizerWaveplateType.QWP ? 4 : 2;
        const aExtraRefractiveIndexDifference = aCurrData.designWavelength / (aIndex * (pElementThickness * 1e+6));
        const aExtraRefractiveIndex = aCurrData.ordinaryRefractiveIndex + aExtraRefractiveIndexDifference
        
        this.updateNumberInputValue(aExtraRefractiveIndex, eParamsChanged.EXTRA);
        return aExtraRefractiveIndex
    }
    //___________________________________________________________________________________________
    public getLensGeoPropertiesInstance(pInstance: any){
        this.mLensGeoPropertiesInstance = pInstance
    }
    //___________________________________________________________________________________________
    public getMaterialInstances(pMaterialSectionInstance: any){
        this.mMaterialRefractiveIndex = pMaterialSectionInstance
    }
    //___________________________________________________________________________________________
    private updateNumberInputValue(pValue: number, pInputId: string){
        $(this.mParamsParent).find('input[param_key]').each(
            (_index, element) => {
                let aKey = element.getAttribute('param_key');
                if(aKey === pInputId){
                    (element as undefined as HTMLInputElement).value = pValue.toString();
                }
            }
        );
    }
    //___________________________________________________________________________________________
    public recalculateDataWithChangedThickness(){
        if(uoPolarizingElementInfo.CHANGED_PARAM !== null){
            return
        }
        uoPolarizingElementInfo.CHANGED_PARAM = eParamsChanged.THICKNESS;
        this._onChange()
    }
    //___________________________________________________________________________________________
    public onChangeMaterialWL(){
        uoPolarizingElementInfo.CHANGED_PARAM = eParamsChanged.ORDINARY;
        this.changeMaterialOrdinaryRefractiveIndex();
        this._onChange();
    }
    //___________________________________________________________________________________________
    private getCurrentWaveplateType(){
        const aWaveplateType = this.mWaveplateTypeSelector.options.selectedIndex === 0 ? ePolarizerWaveplateType.QWP : ePolarizerWaveplateType.HWP;
        return aWaveplateType;
    }
    //___________________________________________________________________________________________
    private updateLensThickness(pThickness: number){
        this.mLensGeoPropertiesInstance.updateLensThicknessExternally(pThickness);
    }
}