import { UserPermissions } from "../UserPermissions";
import { AnalyticsEventsContext } from "../_context/AnalyticsEventsContext";
import { eDataPermission, eErrorType, eRedirectUri, eUnitType } from "../_context/Enums";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { Strings } from "../_context/Strings";
import { iAppFeaturesConfig, iAsyncCallback, iBasicSetupData, iDownloadFullSetupData } from "../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { DivControllerWidget } from "../_widget/DivControllerWidget";
import { Op3dSceneWidget } from "../_widget/Op3dSceneWidget";
import { PartsManagerWidget } from "../_widget/PartsManagerWidget";
import { SetupsManagerWidget } from "../_widget/SetupManagerWidget";
import { DataManager } from "../data/DataManager";
import { UserVO } from "../data/VO/UserVO";
import { PartsManager } from "../parts/_parts_assets/PartsManager";
import { GridManager } from "../scene/GridManager";
import { Op3dScene } from "../scene/Op3dScene";
import { SceneContext } from "../scene/SceneContext";
import { SceneHistory } from "../scene/SceneHistory";
import { SceneHistoryWidget } from "../scene/SceneHistoryWidget";
import { AuthUtils } from "../server/AuthUtils";
import { RegisteredServer } from "../server/RegisteredServer";
import { ServerContext, eSimulationResultsStorage, iLoginResultData } from "../server/ServerContext";
import { SetupsManager } from "../setups/SetupManager";
import { DivController } from "../ui/DivController";
import { ViewUtils } from "../ui/ViewUtils";
import { InputDispatcher } from "../ui/_globals/InputDispatcher";
import { PartsEventsHandler } from "../ui/_globals/PartsEventsHandler";
import { UserSettingsFormNew } from "../ui/forms/UserSettingsFormNew";
import { AcceptTermsForm } from "../ui/home/AcceptTermsForm";
import { Login } from "../ui/home/Login";
import { Menu } from "../ui/home/Menu";
import { Spinner } from "../ui/home/Spinner";
import { WakeupScreen } from "../ui/home/WakeupScreen";
import { NotificationCenter } from "../ui/home/_notifications/NotificationCenter";
import { op3dTooltip } from "../ui/home/op3dTooltip";
import { UnitHandler } from "../units/UnitsHandler";
import { Registration } from "./Registration";
import '../../../public/scss/index.scss'
import '../../../public/css/custom.css'
import { SimulationRunner } from "../simulation/SimulationRunner";
import { AnalysisPortal } from "../ui/analysis/AnalysisPortal";
import { GaussianBeamTable } from "../ui/analysis/GaussianBeamTable";
import { AnalysisCache } from "../ui/analysis/AnalysisCache";
import { Zip } from "../../loaders/Zip";
import { SettingsContext } from "../settings/SettingsContext";
import { ImageQualityForm } from "../ui/tools/ImageQualityForm";
import { PartsCatalog } from "../parts/_parts_assets/PartsCatalog";
import { PartsDataLoader } from "../data/data_loader/PartsDataLoader";
import { CacheStorage } from "../data/CacheStorage";
import { PDFImageGenerator } from "../_utils/PDFGenerator";
import { PostBehavior } from "../parts/behaviors/PostBehavior";
import { Measurement } from "../ui/forms/Measurement";
import { Matrix4, Vector3 } from "three";
import { OpticsMenu } from "../ui/menus/_search/OpticsMenu";

export class Main {
    public static CANVAS_CONTAINER_NAME = "canvas_container";

    public static OP3D_SETUP_ID = 'op3d_setup_id';
    public static OP3D_NEW_SETUP = 'new_setup';
    public static OP3D_FULL_SETUP = 'existing_setup';

    constructor() {

        let aPathName = window.location.href;

        if (!aPathName.includes('simulation.3doptix')) {
            this.setDebugMode()
        }

        if (aPathName.toLowerCase().indexOf("widget.html") > -1) {
            this._onLoginWidget();

        } else if (aPathName.toLowerCase().indexOf("registration.html") != -1) {
            new Registration();

        } else if (aPathName.toLowerCase().indexOf("on_login.html") != -1) {
            this._onLoginPage();

        } else if (aPathName.toLowerCase().indexOf("login_app.html") != -1) {
            this._secondaryLogin();

        } else {

            this._login();
        }
    }
    //__________________________________________________________________________________________
    private setDebugMode() {
        (<any>window).op3dContext = Op3dContext;
        (<any>window).sceneContext = SceneContext;
        (<any>window).serverContext = ServerContext;
        (<any>window).simulationRunner = SimulationRunner;
        (<any>window).op3dUtils = Op3dUtils;
        (<any>window).analysisPortal = AnalysisPortal;
        (<any>window).matrix4 = Matrix4;
        (<any>window).Vector3 = Vector3;
        (<any>window).gaussianBeamTable = GaussianBeamTable;
        (<any>window).cacheStorage = CacheStorage;
        (<any>window).analysisCache = AnalysisCache;
        (<any>window).unitsHandler = UnitHandler;
        (<any>window).ImageQualityForm = ImageQualityForm;
        (<any>window).zip = Zip;
        (<any>window).spinner = Spinner;
        (<any>window).PartsDataLoader = PartsDataLoader;
        (<any>window).PDFGenerator = PDFImageGenerator;
        (<any>window).PostBehavior = PostBehavior;
        (<any>window).SetupsManager = SetupsManager;
        (<any>window).Measurement = Measurement;
        (<any>window).OpticsMenu = OpticsMenu;
        (<any>window).main = Main;
    }
    //__________________________________________________________________________________________
    private async _onLoginPage() {
        const urlParams = new URLSearchParams(window.location.search);
        let aCode = urlParams.get("code");
        let aLink = urlParams.get("link") as eRedirectUri;
        if (aCode != null) {
            try {

                let aRedirectUri = "";
                let aBaseLink = null;
                let aRedirectLink = Op3dUtils.getCookie("redirect");
                switch (aLink) {
                    case eRedirectUri.SIMULATION:
                        aRedirectUri = `${ServerContext.base_link}/on_login.html?link=simulation`;
                        aBaseLink = ServerContext.base_link + ServerContext.index_addition;
                        break;
                    case eRedirectUri.WAREHOUSE:
                        aRedirectUri = `${ServerContext.base_link}/on_login.html?link=warehouse`;

                        if (aRedirectLink != null && aRedirectLink != "") {
                            Op3dUtils.deleteCookie("redirect");
                            aBaseLink = aRedirectLink;
                        } else {
                            aBaseLink = ServerContext.warehouse_base_link;
                        }

                        break;
                    case eRedirectUri.PRICING:
                        aRedirectUri = `${ServerContext.base_link}/on_login.html?link=pricing`;

                        if (aRedirectLink != null && aRedirectLink != "") {
                            Op3dUtils.deleteCookie("redirect");
                            aBaseLink = aRedirectLink;
                        } else {
                            aBaseLink = ServerContext.pricing_base_link;
                        }

                        break;
                    case eRedirectUri.WEBSITE:
                        aRedirectUri = `${ServerContext.base_link}/on_login.html?link=website`;

                        if (aRedirectLink != null && aRedirectLink != "") {
                            Op3dUtils.deleteCookie("redirect");
                            aBaseLink = aRedirectLink;
                        } else {
                            aBaseLink = ServerContext.website_base_link;
                        }

                        break;
                }

                let aRes = await RegisteredServer.getTokensByAutorizationCode({
                    redirect_uri: aRedirectUri,
                    authorization_code: aCode
                });

                if (aRes.success) {
                    let aResUpdateUser = await RegisteredServer.updateUser({ id_token: aRes.data.id_token });
                    if (aResUpdateUser.success) {

                        ServerContext.setTokens(aRes.data, true);
                        AuthUtils.saveCookies(ServerContext.tokens);

                        let aAcceptTerms = aResUpdateUser.data.userData.acceptTerms;
                        if (aAcceptTerms == false) {
                            // transfer to reg form
                            Op3dUtils.setCookie(AuthUtils.TRANSFER_AFTER_REGISTRATION, aBaseLink, 7, 0);
                            window.location.replace(ServerContext.base_link + ServerContext.index_addition)
                            return;

                        }
                        window.location.replace(aBaseLink);

                    } else {
                        MessagesHandler.ON_ERROR_PROGRAM({
                            email: "internal",
                            error: aResUpdateUser.data,
                            type: eErrorType.AUTH,
                            redirectTo500: true,
                            show_message: false,
                        });
                    }

                } else {

                    MessagesHandler.ON_ERROR_PROGRAM({
                        email: "internal",
                        error: aRes.data,
                        type: eErrorType.AUTH,
                        show_message: false,
                        redirectTo500: true
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
    //__________________________________________________________________________________________
    private _secondaryLogin() {
        new Login({
            onReady: () => {
                let aText = document.getElementById("browser-menu-login");
                ViewUtils.removeFromParent(aText);

                let aSiteWrapper = document.getElementById("site-wrapper");
                ViewUtils.removeFromParent(aSiteWrapper);

                let aLoginFooter = document.getElementById("login-footer");
                ViewUtils.setElementVisibilityByDNone(aLoginFooter, false);

                let aLoginBox = document.getElementById("login-box");
                if (aLoginBox != null) {
                    aLoginBox.classList.add("mt-0");
                }
            },
            isOnRegisterBlank: true,
            isExternalLogin: true
        });
    }
    //__________________________________________________________________________________________
    private async _login() {

        let aRefreshToken = AuthUtils.getRefreshtoken();
        let aHasRefreshToken = ("" != aRefreshToken);

        if (aHasRefreshToken) {

            Spinner.instance.show();
            let aRes = await RegisteredServer.getUpdatedAccessToken({ refresh_token: aRefreshToken });
            if (aRes.success) {
                /**
                 *1) get user data and login ...
                 */

                ServerContext.setTokens(aRes.data, true);
                AuthUtils.saveCookies(ServerContext.tokens);
                let aUserResult = await RegisteredServer.getDataByToken({ token: ServerContext.tokens.access_token });

                if (aUserResult.data.userData.role === 1 || aUserResult.data.userData.role === 2) aUserResult.data.cad_parts_limit = 1000

                if (aUserResult.success) {
                    await this._onLogin(aUserResult.data);


                    this.initUserGuiding(aUserResult)



                } else {

                    MessagesHandler.ON_ERROR_PROGRAM({
                        email: "internal",
                        error: aUserResult.data,
                        type: eErrorType.AUTH,
                        show_message: false,
                        redirectTo500: true
                    });
                }


                Spinner.instance.hide();
                ViewUtils.removeFromParent(document.getElementById("login-container"));
                return;

            } else {
                AuthUtils.deleteCookies();
            }
        }

        this._redirectToLogin();
    }
    //__________________________________________________________________________________________
    private initUserGuiding(pRes: iAsyncCallback<iLoginResultData>) {
        try {
            (window as any).userGuiding.identify(pRes.data.userData._id, {
                email: pRes.data.userData.email,
                name: pRes.data.userData.name,
                isBasic: pRes.data.userData.billing.license.line_items.length == 0

            })
        } catch (e) {
            console.warn('UserGuiding initialization failed:', e);
        }
    }
    //__________________________________________________________________________________________
    private _redirectToLogin() {

        const aUri = ServerContext.redirect_uri;
        const aClientId = ServerContext.cognito_client_id;

        let aUrl = `${ServerContext.cognito_domain}/login?client_id=${aClientId}&response_type=code&scope=email+openid+phone&redirect_uri=${aUri}`;
        window.location.replace(aUrl);
    }
    //__________________________________________________________________________________________
    public async _onLoginWidget() {
        try {

            let aLoginDataResult = await RegisteredServer.loginAsGuest();
            if (aLoginDataResult.success == false) {
                MessagesHandler.ON_ERROR_PROGRAM({
                    email: 'guest@3doptix.com',
                    error: aLoginDataResult.data,
                    type: eErrorType.AUTH,
                    redirectTo500: true,
                    show_message: false,
                });
                return;
            }

            Op3dContext.USER_PERMISSION = new UserPermissions(aLoginDataResult.data.permissions);
            Op3dContext.SCENE_HISTORY = new SceneHistoryWidget();
            await PartsCatalog.instance.loadAxisModel();
            ServerContext.SERVER = new RegisteredServer(eSimulationResultsStorage.HTTP);
            Op3dContext.USER_VO = new UserVO({
                cad_parts_limit: aLoginDataResult.data.cad_parts_limit,
                userData: aLoginDataResult.data.userData,
                limit: aLoginDataResult.data.limit,
                curr_cad_folder_size: aLoginDataResult.data.curr_cad_folder_size
            });
            ServerContext.setTokens(aLoginDataResult.data, false);
            Op3dContext.DATA_MANAGER = new DataManager();
            Op3dContext.SETUPS_MANAGER = new SetupsManagerWidget();
            Op3dContext.TOOLTIP = new op3dTooltip();
            ViewUtils.removeFromParent(document.getElementById("login-container"));

            await ServerContext.SERVER.connectToWebSocket();
            Op3dUtils.deleteCookie(Strings.USER_SETTINGS);

            const urlParams = new URLSearchParams(window.location.search);
            let aId = urlParams.get("id");

            if (aId != null) {

                let aRes = await Op3dContext.SETUPS_MANAGER.getOneSetup({ _id: window.atob(aId), to_open: false });
                //await op3d.server.ServerContext.SERVER.getOneSetup({ _id: window.atob(aId), to_open: false });

                if (aRes.success == false) {
                    window.location.href = ServerContext.base_link + "/error404.html";
                    return;

                } else if (aRes.data.parameters.isShareable === false) {
                    window.location.href = ServerContext.base_link + "/access_denied.html";
                    return;
                }

                let aResContentUrl = `${ServerContext.user_prefix_setups}${aRes.data.owner}/${window.atob(aId)}/setup.opt?V=${Op3dUtils.idGenerator()}`;
                try {
                    let aContent = await fetch(aResContentUrl).then(data => data.text());
                    aRes.data.content = aContent;
                } catch (e) {
                    aRes.success = false;
                }

                if (aRes.success) {
                    Op3dContext.isWidget = true;
                    let aUnitLength = aRes.data.parameters.unit == eUnitType.MILLIMETERS ? 25 : 1;
                    GridManager.CELL_SIZE = aUnitLength;

                    this._onStartWidget(aRes.data, aRes.data.permission, aRes.data.parameters.unit);
                    await Op3dContext.SETUPS_MANAGER.onOpenSetup({
                        addToExisting: false,
                        content: aRes.data
                    });

                    let aTryProfessional = document.getElementById("try-professional");
                    ViewUtils.setElementVisibilityByDNone(aTryProfessional, false);
                }

                return;
            }

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({
                email: 'guest@3doptix.com',
                error: e,
                type: eErrorType.GENERAL,
                show_message: true
            });
        }
    }
    //__________________________________________________________________________________________
    private _onStartWidget(pData: iBasicSetupData, pSetupPermission: eDataPermission, pUnit: eUnitType) {
        document.body.classList.remove(Strings.WAKEUP_SCREEN);

        this._setUnit(pUnit);

        let aContainer = document.getElementById(Main.CANVAS_CONTAINER_NAME);
        if (aContainer == null) {
            throw new Error("missing 3D canvas container");
        }
        ViewUtils.setElementVisibilityByDNone(aContainer, true);
        //todo if this is a widget server should be widgetserver
        // server.ServerContext.SERVER = new server.RegisteredServer();
        // Op3dContext.DATA_MANAGER = new data.DataManager();
        SceneContext.OP3D_SCENE = new Op3dSceneWidget(aContainer, true);
        let aSceneOptions = pData.parameters.sceneOptions;
        SceneContext.GRID_MANAGER = new GridManager(aSceneOptions);
        Op3dContext.DIV_CONTROLLER = new DivControllerWidget();
        Op3dContext.PARTS_MANAGER = new PartsManagerWidget();
        Op3dContext.INPUT_DISPATCHER = new InputDispatcher();
        Op3dContext.PARTS_EVENTS_HANDLER = new PartsEventsHandler();
        // Op3dContext.SETUPS_MANAGER = new op3d.setups.SetupsManagerWidget();

        // we don't need this line anymore 
        //await scene.SceneContext.OP3D_SCENE.loadAxis();

        Op3dContext.PARTS_MANAGER.initGCS();

        Op3dContext.SETUPS_MANAGER.onStartNewDesign(pData, pSetupPermission);
        SceneHistory.TO_AUTO_SAVE = false

    }
    //__________________________________________________________________________________________
    private async _loadProgram() {

        Spinner.instance.show();

        Op3dContext.SCENE_HISTORY = new SceneHistory();
        ServerContext.SERVER = new RegisteredServer(eSimulationResultsStorage.S3);
        Op3dContext.DATA_MANAGER = new DataManager();
        Op3dContext.TOOLTIP = new op3dTooltip();

        Menu.instance.create();
        await PartsCatalog.instance.loadAxisModel();
        await ServerContext.SERVER.connectToWebSocket();
        try {
            let aResponse = (await ServerContext.SERVER.getApplicationFeatureConfig())
            let aConfig: iAppFeaturesConfig = {
                optics_chatbot: { enabled: false },
                fresnel_analysis: { enabled: false },
                polarization: { enabled: false },
                triangulation_prefs: {
                    email: [],
                    enabled: false
                },
                array_of_elements: { enabled: false },
                optomech_menu: { enabled: false },
                snap_lens_to_chief_ray: { enabled: false },
                cad_surface_painting: { enabled: false }
            }
            if (aResponse.success === true && aResponse.data !== undefined) {
                const aResp = JSON.parse(aResponse.data as undefined as string);
                if (typeof aResp === 'object' && aResp !== null) {
                    aConfig = aResp
                }
            }
            Op3dContext.APP_FEATURES_CONFIG = aConfig;

        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e)
        }
        let aUserSettingsRedirect = Op3dUtils.getCookie(Strings.USER_SETTINGS);
        Op3dUtils.deleteCookie(Strings.USER_SETTINGS);

        let aNewSetup = localStorage.getItem(Main.OP3D_NEW_SETUP);
        if (null != aNewSetup) {
            await this._loadNewSetup(aNewSetup);
            Op3dContext.SCENE_HISTORY.saveScene(true);
            return;
        }

        let aExistingSetup = localStorage.getItem(Main.OP3D_FULL_SETUP);
        if (null != aExistingSetup) {
            this.openExistingSetup(JSON.parse(aExistingSetup));
            return;
        }

        let aOpenSetupID = localStorage.getItem(Main.OP3D_SETUP_ID);
        localStorage.removeItem(Main.OP3D_SETUP_ID);

        /**
         * this kind of setup comes from the warehouse [copy & edit]
         */
        if (aOpenSetupID !== null) {
            await this._onOpenSetupWarehouseCopyAndEdit(aOpenSetupID);
            Op3dContext.USER_PERMISSION.isFreeUser == true ?
                document.body.classList.add('try_premium') :
                document.body.classList.remove('try_premium')
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        let aId = urlParams.get("id");

        if (aId != null) {
            await this._onOpenSetupFromUrl(aId);
            return;
        }


        Spinner.instance.show();
        WakeupScreen.instance.open({ isNew: true });
        if ((null != aUserSettingsRedirect) && ("" != aUserSettingsRedirect)) {
            await UserSettingsFormNew.instance.open({ userSettingsParams: Op3dContext.USER_VO.userVO, currSettings: SettingsContext.getUserSimulationSettings() });
        }
        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private async _onLogin(pLoginResponse: iLoginResultData) {

        try {
            Op3dContext.USER_VO = new UserVO({
                userData: pLoginResponse.userData,
                cad_parts_limit: pLoginResponse.cad_parts_limit,
                curr_cad_folder_size: pLoginResponse.curr_cad_folder_size,
                limit: pLoginResponse.limit
            });
            AnalyticsEventsContext.triggerConnectSystem("login");
            Op3dContext.USER_PERMISSION = new UserPermissions(pLoginResponse.permissions);

            if (Op3dContext.USER_VO.needsToCompeteRegister) {
                window.location.replace(ServerContext.base_link + "/registration.html");
                return;
            }

            let aAcceptTerms = pLoginResponse.userData.acceptTerms;
            if (aAcceptTerms !== true) {
                new AcceptTermsForm(() => this._loadProgram());
                return;
            }

            Spinner.instance.show();
            await this._loadProgram();
            Spinner.instance.hide();

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM({
                email: pLoginResponse.userData.email,
                error: e,
                show_message: false,
                type: eErrorType.GENERAL
            });
        }
    }
    //__________________________________________________________________________________________
    private async _onOpenSetupFromUrl(pId: string) {
        // let aRes = await op3d.Op3dContext.SETUPS_MANAGER.getOneSetup({ _id: pId, to_open: true, session: op3d.setups.SetupsManager.SESSION });
        let aRes = await ServerContext.SERVER.getOneSetup({ _id: pId, to_open: true });
        let aResContentUrl = `${ServerContext.user_prefix_setups}${Op3dContext.USER_VO.id}/${pId}/setup.opt?V=${Op3dUtils.idGenerator()}`;
        try {
            let aContent = await fetch(aResContentUrl).then(data => data.text());
            aRes.data.content = aContent;
        } catch (e) {
            aRes.success = false;
        }

        if (aRes.success) {
            if (Op3dContext.USER_PERMISSION.isFreeUser !== true) {
                aRes.data.permission = eDataPermission.PRIVATE;
            }
            this._onStartProgram(aRes.data);
            //_____________________________________________
            await Op3dContext.SETUPS_MANAGER.onOpenSetup({
                addToExisting: false,
                content: aRes.data
            });
            //_____________________________________________

            let aTryProfessional = document.getElementById("try-professional");
            ViewUtils.setElementVisibilityByDNone(aTryProfessional, false);

        } else {
            NotificationCenter.instance.pushNotification({
                message: "Error loading setup",
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
            });
        }
    }
    //__________________________________________________________________________________________
    public openNewSetup(pData: iBasicSetupData) {
        Op3dContext.SCENE_HISTORY.ignoreSaving = false;
        this._onStartProgram(pData);
        Op3dContext.SETUPS_MANAGER.session = Op3dUtils.idGenerator()
        Op3dContext.SCENE_HISTORY.saveScene(true);
    }
    //__________________________________________________________________________________________
    public async reloadAndOpenNewSetup(pSetupData: iBasicSetupData) {
        await Op3dContext.SETUPS_MANAGER.closeSetup();
        window.localStorage.setItem(Main.OP3D_NEW_SETUP, JSON.stringify(pSetupData));

        this.reload();
    }
    //__________________________________________________________________________________________
    public async reload() {

        let aFunc: EventListener | undefined;
        if (Op3dContext.SETUPS_MANAGER != undefined) {
            await Op3dContext.SETUPS_MANAGER.closeSetup();
            aFunc = Op3dContext.SETUPS_MANAGER.beforeUnloadFunc;
        }

        AnalyticsEventsContext.triggerFinishSession();
        if (aFunc != undefined) {
            window.removeEventListener('beforeunload', aFunc);
        }
        window.addEventListener('beforeunload', function (event) {
            event.stopImmediatePropagation();

        });
        window.location.reload();
    }
    //__________________________________________________________________________________________
    public async reloadAndOpenExistingSetup(pSetupData: iDownloadFullSetupData) {
        await Op3dContext.SETUPS_MANAGER.closeSetup();
        window.localStorage.setItem(Main.OP3D_FULL_SETUP, JSON.stringify(pSetupData));

        this.reload();
    }
    //__________________________________________________________________________________________
    public async openExistingSetup(pData: iDownloadFullSetupData, pLoad: boolean = true) {
        localStorage.removeItem(Main.OP3D_FULL_SETUP);

        if (true == pLoad) {
            this._onStartProgram(pData);
        }

        await Op3dContext.SETUPS_MANAGER.onOpenSetup({
            addToExisting: false,
            content: pData
        });
        Spinner.instance.show();

        await Op3dContext.SCENE_HISTORY.saveScene(true);
        Spinner.instance.hide();

    }
    //__________________________________________________________________________________________
    private async _loadNewSetup(pSetupParams: string) {
        localStorage.removeItem(Main.OP3D_NEW_SETUP);

        let aNewSetup = JSON.parse(pSetupParams) as iDownloadFullSetupData;
        this._onStartProgram(aNewSetup);
    }
    //__________________________________________________________________________________________
    private async _onOpenSetupWarehouseCopyAndEdit(pCopySetup: string) {

        // it means that the setup belongs to the active user 
        let aResContentUrl = `${ServerContext.user_prefix_setups}${Op3dContext.USER_VO.id}/${pCopySetup}/setup.opt?V=${Op3dUtils.idGenerator()}`
        //   let aRes = await op3d.Op3dContext.SETUPS_MANAGER.getOneSetup({ _id: pCopySetup, session: op3d.setups.SetupsManager.SESSION, to_open: true });
        let aRes = await ServerContext.SERVER.getOneSetup({ _id: pCopySetup, to_open: true });
        Op3dContext.SCENE_HISTORY.ignoreSaving = false;
        aRes.data.content = await fetch(aResContentUrl).then(data => data.text())
        await this.openExistingSetup(aRes.data);
    }
    //__________________________________________________________________________________________
    private _setUnit(pUnit: eUnitType) {
        GridManager.CELL_SIZE = (eUnitType.MILLIMETERS == pUnit) ? 25 : 25.4;
        UnitHandler.currUnit = eUnitType.MILLIMETERS;
        Op3dContext.OPTICAL_AXIS_HEIGHT = (4 * GridManager.CELL_SIZE);
        UnitHandler.PRESENTED_UNIT = pUnit;
    }
    //__________________________________________________________________________________________
    private _onStartProgram(pData: iBasicSetupData) {
        Spinner.instance.show();

        document.body.classList.remove(Strings.WAKEUP_SCREEN);

        //document.getElementById('status_row').classList.remove('d-none');
        //this._displayVersion();
        // this._displayTesselation();
        this._setUnit(pData.parameters.unit);

        let aContainer = document.getElementById(Main.CANVAS_CONTAINER_NAME);
        if (aContainer == null) {
            throw new Error("missing 3D canvas container");
        }
        ViewUtils.setElementVisibilityByDNone(aContainer, true);


        SceneContext.OP3D_SCENE = new Op3dScene(aContainer, true);

        let aSceneOptions = pData.parameters.sceneOptions;
        SceneContext.GRID_MANAGER = new GridManager(aSceneOptions);
        Op3dContext.DIV_CONTROLLER = new DivController();

        Op3dContext.PARTS_MANAGER = new PartsManager();
        Op3dContext.INPUT_DISPATCHER = new InputDispatcher();
        Op3dContext.PARTS_EVENTS_HANDLER = new PartsEventsHandler();
        Op3dContext.SETUPS_MANAGER = new SetupsManager();
        Op3dContext.SETUPS_MANAGER.snapshotGenerator.onNew();
        // we don't need this line anymore 
        //await scene.SceneContext.OP3D_SCENE.loadAxis();

        Op3dContext.PARTS_MANAGER.initGCS();
        Op3dContext.SETUPS_MANAGER.onStartNewDesign(pData, pData.permission);
        Menu.instance.setCurrentEnvironment();

        // Op3dContext.SCENE_HISTORY = new scene.SceneHistory();
        $(document.body).find('[data-toggle="tooltip"]').tooltip().on('click', () => {
            $(this).blur()
        });

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
}
