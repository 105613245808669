import { iHash, iNumericKeyHash, t3DArray } from "../_context/_interfaces/Interfaces";
import { tVector3Array } from "../_utils/MatrixUtils";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { iFrameData, iJSONMatrix4, iJonesMatrixData, iPhaseData } from "../parts/PartInterfaces";
import { eCountType, iMaskData, iRectanglePlaneWaveData, iCircularPlaneWaveData, iPointSourceData, iGaussianBeam } from "../parts/behaviors/LightSourceContext";
import { eAnalysisType, tAnalysisType, eSimulationKind, ePolarizationKind, eDiffractionKind, eFresnelKind } from "../ui/analysis/AnalysisContext";

//______________________________________________________________________________________________
export enum eSmLightSourceKind {
    GAUSSIAN_BEAM = "GAUSSIAN_BEAM",
    SHAPE = "PLANE_WAVE",
    RAY_FILE = "RAY_FILE",
    POINT = "POINT",
}
//______________________________________________________________________________________________
export interface iAdvancedAnalysisOptions {
    simulation_kind?: eSimulationKind;
    diffraction_kind?: eDiffractionKind;

    fresnel_kind?: eFresnelKind;
    fresnel_padding_factor?: number;
}
//______________________________________________________________________________________________
export interface iSmAnalysisItem extends iAdvancedAnalysisOptions {
    kind: eSmAnalysisKind,

    /**
     * @description attribute to RAY_TABLE analysis;
     * if it is greater than 0, no more than "max_num_rays" rays wiil be saved.
     * Rays to save are chosen in the way to preserve the whole path of a ray 
     * emitted by a source through a scene; as a result, the actual number of 
     * saved rays may be slightly greater than "max_num_rays" value
     * @default 0 - if num rays set to 0 all rays traced during 
     * the simulation will be saved to output ray file
     */
    max_num_rays?: number

    /**
     * for analysis
     */
    resolution_x?: number,
    resolution_y?: number;
    surface?: number;
    spot_data_kind?: eSmAnalysisType,

    /**
     * not for snellius
     */
    type?: eAnalysisType;
    name?: tAnalysisType;
    num_rays?: iHash<number>,
    id?: string;
    polarization_kind?: ePolarizationKind;

}
//______________________________________________________________________________________________
export interface iRunSimulationData extends iAdvancedAnalysisOptions {
    name: string,
    id: string,
    settings: iSimulationSettingsJSON,
    system_file: {
        data: iSmSystemFile
        save: boolean,
        name?: string;
    },

    analyses: Array<iSmAnalysisItem>,
    snellius_version?: string,


}
//______________________________________________________________________________________________
export interface iSimulationReportItem {
    type: eSimulationReportError;
}
//______________________________________________________________________________________________
export interface iSimulationResponse {
    analysis: Array<any>,
    messages: string;
    error?: {
        code: number,
        message: string
    }
    /**
     * a url to the result file
     */
    ray_table: string;
    report: Array<iSimulationReportItem>;

}
//______________________________________________________________________________________________
export interface iSingleArraySourcesItem {
    /**
     * geometry of array of sources can be of kinds PLANAR, POLYNOMIAL or BICONIC
     */
    geometry: number;
    step_x: number;
    step_y: number;
}

//______________________________________________________________________________________________
export interface iSmLightSourceData {
    kind: eSmLightSourceKind;
    wavelengths: Array<number>;

    shape?: number;
    far_field_distance?: number
    /**
     * number of emitted rays (ignored for the FILE source)
     */
    num_rays: Number;
    count_type: eCountType;

    /**
     * Added attribute "density_pattern" to source in setup file; possible values: "REGULAR", "RANDOM"
     */
    density_pattern?: eSmRaysDensity;
    /**
     * internal id of the laser part
     */
    id: string;
    world_matrix: Array<number>;
    jones_vector: Array<Array<number>>
    wavelength_weights: Array<number>;
    power: number;
    is_array: boolean;
    array?: iSingleArraySourcesItem;

    waist_x?: number;
    waist_y?: number;
}
//______________________________________________________________________________________________
export interface iSmArrayOfSourcesShapeData {
    step_x: number;
    step_y: number;
}
//______________________________________________________________________________________________
export interface iSmClientLightSourceData {
    id: string;
    ray_generation: Array<iSmRaysData>;
}
//______________________________________________________________________________________________
export interface iSmSurfaceGeometricalData {
    name: string;
    world_matrix: Array<number>;
    /**
     * @description index in the geometries array
     * */
    geometry: number;
};
//______________________________________________________________________________________________
export interface iSmScatteringParams {
    distribution: iSMScatteringDisributionParams
    num_scattered_rays: number;
    threshold: number;
}
//______________________________________________________________________________________________
export interface iSMScatteringDisributionParams {
    kind: string;
    sigma_x: number;
    sigma_y: number;
    azimuth: number;
    N: number;
    A: number;
    B: number;
    g: number;
}
//______________________________________________________________________________________________
export type tPhaseProfileTermKind = "ZERNIKE" | "RADIAL_DISTANCE"

//______________________________________________________________________________________________
export interface iSinglePhaseProfile {
    term_kind: tPhaseProfileTermKind;
    normalization_radius?: number;
    terms: Array<Array<number>>;
}
//______________________________________________________________________________________________
export interface iSmScatteringData {
    transmittance: number;
    reflectance: number;
    transmission_scatterer: iSmScatteringParams;
    reflection_scatterer: iSmScatteringParams;
}
//______________________________________________________________________________________________
export interface iSmParaxialLens {
    is_ideal_lens?: boolean;
    focal_length?: number;
}
//______________________________________________________________________________________________
export interface iSMOpticsParams {
    id: string;

    /**
     * @description index in the materials array
     * */
    material: number;
    /**
     * @description index in the coatings array
     * -1 if not coated
     * */
    coating: number;
}
//______________________________________________________________________________________________
export interface iSmSurfaceData extends iSMOpticsParams, iSmSurfaceGeometricalData,
    iFrameData, iSmParaxialLens, iPhaseData, iJonesMatrixData {

    /**
     * reflection parameters
     */
    reflection_enabled: boolean;
    max_num_bounces: number;

    /**
     * grating
     */
    is_grating: boolean;
    grating?: iSmGratingData;

    /**
    * mask 
    */
    is_mask: boolean;
    transmittance_mask?: number;
    phase_mask?: number;

    /**
     * scattering
     */
    scattering_enabled: boolean;
    scattering?: iSmScatteringData;

    /**
     * blackbox
     */
    is_inside_blackbox?: boolean
};
//______________________________________________________________________________________________
export interface iSMCSParams {
    matrix: Array<number>;
    label: string;
    id: string;
}
//______________________________________________________________________________________________
export interface iSMPartsData {
    label: string;
    partID: string;
    lcs: iSMCSParams;
    rcs: iSMCSParams;
    surfacesIDs: Array<number>;
    lightSourceIndex?: number;
    linkedPartIds?: Array<string>;
}
//______________________________________________________________________________________________
export interface iPhaseProfile {
    S: iSMPolynomial;
    P: iSMPolynomial;
}
//______________________________________________________________________________________________
export interface iGratingRangeData {
    /**
            *  minimum diffraction order for which transmission ray will be computed
            */
    min_transmission_order: number;//-1000,
    /**
     * maximum diffraction order for which transmission ray will be computed
     */
    max_transmission_order: number//1000,
    /**
     * minimum diffraction order for which reflection ray will be computed
     */
    min_reflection_order: number;// -3,
    /**
     * maximum diffraction order for which reflection ray will be computed
     */
    max_reflection_order: number;// 17
}
//______________________________________________________________________________________________
export interface iGratingSectionData extends iGratingRangeData {
    opticsVO: iOpticsVO;
}
//______________________________________________________________________________________________
export interface iSmGratingData extends iGratingRangeData {



    groove: number,
    // /**
    //  *  vector perpendicular to grooves and pointing to the half space defined by 
    //  *  the positive direction of Y-axis in the grating local coordinate system
    //  */
    // groove_orientation: tVector3Array,

}
//______________________________________________________________________________________________
export interface iGratingData extends iGratingRangeData {


    /**
     * distance between grating grooves in nanometers
     */
    groove_pitch: number,
    /**
     *  vector perpendicular to grooves and pointing to the half space defined by 
     *  the positive direction of Y-axis in the grating local coordinate system
     */
    groove_orientation: tVector3Array,

}
//______________________________________________________________________________________________
export enum eSmTermKind {
    RADIAL_DISTANCE = "RADIAL_DISTANCE",
    ZERNIKE_COEFFS = "ZERNIKE"
}
//______________________________________________________________________________________________
export enum eSmGeometryType {
    PLANAR = "PLANAR",
    BICONIC = "BICONIC",
    ASPHERIC = "ASPHERIC",
    QCON = "QCON",
    //SPHERICAL = "SPHERICAL",
    CYLINDER = "CYLINDER",
    FRAME = "FRAME",
    AXICON = "AXICON",
    MESH = "MESH"
};
//______________________________________________________________________________________________
export interface iSMPolynomial {
    term_kind: eSmTermKind;
    normalization_radius?: number;
    terms?: Array<Array<number>> | Array<number> | iNumericKeyHash<number>;
    /**
     * @Eliav , check if you need to update something
     */
    dont_use_normalization_term?: boolean;
    // [
    //     [2, 2, 1.44e-7],
    //     [4, 4, 0.23e-8],
    //     [8, 8, 0.33e-7]
    // ]
}
//______________________________________________________________________________________________
export interface iSimulationGeometryData {
    kind: eSmGeometryType;
    url?: string;

    k_x?: number;
    k_y?: number;
    radius_x?: number;
    radius_y?: number;
    height?: number;
    center_offset?: t3DArray;

    polynomial?: iSMPolynomial;

    /**
     * for cylinder shape
     */
    radius?: number;


    /**
     * For paraxial lens
     */
    is_ideal_lens?: boolean;
    focal_length?: number;
    excluded_faces?: number[][];
    included_faces?: number[][];
    mesh?: number;
};
//______________________________________________________________________________________________
export interface iSmGeometryData extends iSimulationGeometryData {
    /**
     * @description index in the shapes array
     */
    shape?: number;
};
//______________________________________________________________________________________________
export enum eSmBaseShapeKind {
    CIRCLE = "CIRCLE",
    POLYGON = "POLYGON",
    RECTANGLE = "RECTANGLE",
    ELLIPSE = "ELLIPSE",
    POINT = "POINT"
};
//______________________________________________________________________________________________
export enum eSimulationReportError {
    COATING_ERROR = "COATING_ERROR",
    MATERIAL_ERROR = "MATERIAL_ERROR"
}
//______________________________________________________________________________________________

//______________________________________________________________________________________________
export enum eLaserColorType {
    WAVELENGTH,
    INTENSITY,
    DIRECTION,
    USER_DEFINED,
    LOG
}
//______________________________________________________________________________________________
export interface iSmShapeData {
    kind: eSmBaseShapeKind;
    half_angle?: number;
    radius?: number;
    points?: Array<number>;
    width?: number;
    height?: number;
    radius_x?: number;
    radius_y?: number;
};
//______________________________________________________________________________________________
export enum eSmMaterialType {
    CONSTANT = "CONSTANT",
};
//______________________________________________________________________________________________
export interface iSmMaskData {
    kind: eSmMaskKind,
    url: string;
}
//______________________________________________________________________________________________
export enum eSmMaskKind {
    IMAGE = "IMAGE"
}
//______________________________________________________________________________________________
export interface iSmMaterialData {
    number_id: string;
};
//______________________________________________________________________________________________
export enum eSmCoatingKind {
    ABSORBING = "ABSORBING"
};
//______________________________________________________________________________________________
export interface iSmCoatingData {
    kind: eSmCoatingKind | string;
};
//______________________________________________________________________________________________
export interface iGratingGroove {
    pitch: number;
    thickness: number;
    orientation: Array<number>;
}
//______________________________________________________________________________________________
export type tSystemFileEntry = "sources" | "light_sources" | "surfaces" | "geometries" | "shapes" |
    "grating_grooves" | "materials" | "coatings" | "masks"
//______________________________________________________________________________________________
export interface iSmSystemFile {
    /**
     * for sellius use
     */
    sources: Array<iSmLightSourceData>;
    /**
     * will be deleted after usage in agent 
     */
    light_sources: Array<iSmClientLightSourceData>;
    surfaces: Array<iSmSurfaceData>;
    geometries: Array<iSmGeometryData>;
    shapes: Array<iSmShapeData>;
    grating_grooves: Array<iSmGratingData>;
    materials: Array<iSmMaterialData>;
    coatings: Array<string>;
    parts: Array<iSMPartsData>;
    black_boxes?: Array<{ number_id: string, matrix: any }>
    masks: Array<iSmMaskData>;
    gaussianSources?: Array<iClientGaussianSource>;
};
//______________________________________________________________________________________________
export interface iClientGaussianSource {
    beam: iGaussianBeam;
    world_matrix: iJSONMatrix4;
    wavelengths: Array<number>;
    wavelength_weights: Array<number>;
}
//______________________________________________________________________________________________
export enum eSmAnalysisType {
    INCOHERENT_IRRADIANCE = "INCOHERENT_IRRADIANCE",
    COHERENT_IRRADIANCE = "COHERENT_IRRADIANCE",
    COHERENT_PHASE = "COHERENT_PHASE",
    INCOHERENT_IRRADIANCE_S = "INCOHERENT_IRRADIANCE_S",
    INCOHERENT_IRRADIANCE_P = "INCOHERENT_IRRADIANCE_P",
    COHERENT_IRRADIANCE_S = "COHERENT_IRRADIANCE_S",
    COHERENT_IRRADIANCE_P = "COHERENT_IRRADIANCE_P",
    COHERENT_PHASE_S = "COHERENT_PHASE_S",
    COHERENT_PHASE_P = "COHERENT_PHASE_P"
}
//______________________________________________________________________________________________
export enum eSmAnalysisKind {
    RAY_TABLE = "RAY_TABLE",
    SPOT = "SPOT"
    // IMAGE = "IMAGE",
};
//______________________________________________________________________________________________
export interface iSystemFileParams {
    data: iSmSystemFile;
    save: boolean,
    name?: string;
}
//______________________________________________________________________________________________
export interface iSimulationSettingsJSON {
    ray_intensity_threshold: number;

    /**
     * when true, true normals will be not used; 
     */
    dont_use_true_normals: boolean;

    /**
     * control of surfaces tesselation
     * @param num_tesselation_sectors 60
     * @param num_tesselation_slices 30
     */
    num_tesselation_sectors?: number;
    num_tesselation_slices?: number;

};
//______________________________________________________________________________________________
export interface iSimulationReflectionItem {
    enabled: boolean,
    max_num_bounces: number
}
//______________________________________________________________________________________________
// export interface iSmSingleData {
//     kind: eSmAnalysisType;
//     /**
//      * the name of the file without the extention
//      */
//     file_stem: string;

// };
//______________________________________________________________________________________________
export enum eSmPlaneWaveType {
    RECTANGULAR = "RECTANGULAR",
    ELLIPTICAL = "ELLIPTICAL",
    CIRCULAR = "CIRCULAR",
    ARBITRARY = "ARBITRARY",
}
//______________________________________________________________________________________________
export enum eSmDivergenceType {
    HALF_CONE_ANGLE = "HALF_CONE_ANGLE",
    HALF_WIDTH_RECT = "HALF_WIDTH_RECT",
    HALF_WIDTH_AT_Z = "HALF_WIDTH_AT_Z",
};
//______________________________________________________________________________________________
export enum eSmRaysDensity {
    /**
     * @deprecated
     */
    PERIODIC = "PERIODIC",
    /**
     * @deprecated
     */
    REGULAR = "REGULAR",

    XY_GRID = "XY_GRID",
    CONCENTRIC_CIRCLES = "CONCENTRIC_CIRCLES",
    RANDOM = "RANDOM",

}
//______________________________________________________________________________________________
export enum eSmRaysKind {
    POINT_SOURCE = "POINT_SOURCE",
    PLANE_WAVE = "PLANE_WAVE",
    GAUSSIAN_BEAM = "GAUSSIAN_BEAM",
    RAY_FILE = "RAY_FILE",
    /**
     * @deprecated
     */
    CIRCULAR_GRID = "CIRCULAR_GRID",
    /**
    * @deprecated
    */
    MATRIX_ILLUMINATION = "RECTANGULAR_GRID",
    /**
    * @deprecated
    */
    LASER_MASK = "LASER_MASK",
    /**
    * @deprecated
    */
    ONE_RAY = "ONE_RAY"
};
//______________________________________________________________________________________________
export interface iSmRaysData {
    kind: eSmRaysKind;
    shape: eSmPlaneWaveType;
    wavelengths: Array<number>;
    isCustom: boolean;
    ray_file_name?: string;
    wlWeights: Array<number>;
    number_id: string;
};
//______________________________________________________________________________________________
export interface iMaskProfile extends iSmRaysData, iMaskData {
};
//______________________________________________________________________________________________
export interface iMatrixIlluminationProfile extends iSmRaysData, iRectanglePlaneWaveData {
};
//______________________________________________________________________________________________
export interface iCircularProfile extends iSmRaysData, iCircularPlaneWaveData {
};
//______________________________________________________________________________________________
export interface iPointSourceProfile extends iSmRaysData, iPointSourceData {
};
//______________________________________________________________________________________________
// export interface iCircularRandomProfile extends iSmRaysData {
//     num_rays: number;
// };
//______________________________________________________________________________________________
export type iRaydKind = iPointSourceProfile | iCircularProfile | iMatrixIlluminationProfile | iMaskProfile;
//______________________________________________________________________________________________
export class SimulationContext {
    public static SURFACES_SECTORS: number = 120;//60;
    public static SURFACES_SLICES: number = 100;//30;
    public static DEFAULT_MAX_BOUNCES: number = 5;
    public static SIMULATION_CONSTANTS = {
        LIGHT_SOURCE_COUNT: 15,
        RAYS_COUNT_SINGLE_LASER_3D: 200,
        RAYS_COUNT_TOTAL_3D: 1000,
        WAVELENGTHS_COUNT: 1000,
        SCATTERING_RAYS: 1,
        MAX_EMITTED_RAYS: 1e10,
        MAX_DISPLAY_RAYS: 20000,
        ANALYSIS_RAYS_COUNT: Number.MAX_SAFE_INTEGER,
        SURFACES_SECTORS: SimulationContext.SURFACES_SECTORS,
        SURFACES_SLICES: SimulationContext.SURFACES_SLICES,
        DEFAULT_RAYS_COUNT: {
            regular: 30,
            analysis: 1e6
        }
    }
}

