import { Op3dContext } from "../../_context/Op3dContext";
import { iPoint2D } from "../../_context/_interfaces/Interfaces";
import { Op3dComponentBase } from "../Op3dComponentBase";

export interface iFloatingInput {
    position: iPoint2D;
    initialValue: string;
    callback: (pValue: string) => any;
    allowEmpty?: boolean;
    saveState: boolean;
}

export class FloatingInput extends Op3dComponentBase<iFloatingInput> {

    private static INSTANCE: FloatingInput;

    private mInput: HTMLInputElement;
    private mCallback: (pValue: string) => any;
    private mAllowEmpty: boolean;
    private mSaveState: boolean;

    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/floating_input.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == FloatingInput.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.classList.add('floating_input');
            aContainer.setAttribute('data-toggle', 'modal');
            aContainer.setAttribute('data-backdrop', 'true');

            document.getElementById('forms').appendChild(aContainer);
            FloatingInput.INSTANCE = new FloatingInput(aContainer);
        }

        return FloatingInput.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData?: iFloatingInput): void {
        this.mCallback = pData.callback;
        this.mAllowEmpty = (null != pData.allowEmpty) ? pData.allowEmpty : false;
        this._addTmpEventListeners();
        this.mSaveState = (null != pData.saveState) ? pData.saveState : false;
        this.mInput.value = (null != pData.initialValue) ? pData.initialValue : '';

        this.mContainer.style.left = pData.position.x + 'px';
        this.mContainer.style.top = pData.position.y + 'px';
    }
    //__________________________________________________________________________________________
    private _addTmpEventListeners() {
        let aSaveBtn = this._getPart('save_btn');

        let aCloseFunc = () => {
            window.removeEventListener('mouseup', aCloseFunc);
            window.addEventListener('keyup', aESCFunc);
            aSaveBtn.removeEventListener('click', aOnSaveFunc);

            this.close();
        };

        let aESCFunc = (e: KeyboardEvent) => {
            if (e.code == 'Escape') {
                e.preventDefault();
                e.stopPropagation();

                aCloseFunc();
            }
        };

        let aOnSaveFunc = () => {
            if (("" != this.mInput.value) || (true == this.mAllowEmpty)) {
                if (true == this.mSaveState) {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                }
                this.mCallback(this.mInput.value);
                if (true == this.mSaveState) {
                    Op3dContext.SCENE_HISTORY.saveScene();
                }
            }
            aCloseFunc();
        }

        window.addEventListener('mouseup', aCloseFunc);
        window.addEventListener('keyup', aESCFunc);
        aSaveBtn.addEventListener('click', aOnSaveFunc);
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        this.mCallback = null;
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mContainer.addEventListener('mouseup', (e: Event) => e.stopPropagation());
    }
    //__________________________________________________________________________________________

    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mInput = this._getPart('input') as HTMLInputElement;
    }
    //__________________________________________________________________________________________


}
