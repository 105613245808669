import { eSetupType, eModalWindowState, eDataPermission, eUnitType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iHash, iSetupMenuSearchQuery, iSetupMetadata } from "../../_context/_interfaces/Interfaces";
import { Main } from "../../_main/Main";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ServerContext, ENVS } from "../../server/ServerContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { Popup } from "../forms/Popup";
import { NewDesignForm } from "./NewDesignForm";
import { NotificationCenter } from "./_notifications/NotificationCenter";
import { DataLoader } from "../../data/data_loader/DataLoader";
import { SetupsDataLoader } from "../../data/data_loader/SetupsDataLoader";
import { ViewUtils } from "../ViewUtils";
import { Spinner } from "./Spinner";
import { EventManager } from "../../../oc/events/EventManager";
import { SceneContext } from "../../scene/SceneContext";
import { SceneHistory } from "../../scene/SceneHistory";
import { SetupsManager } from "../../setups/SetupManager";
import { ChoosePartMode } from "../forms/ChoosePartMode";
import { UnitHandler } from "../../units/UnitsHandler";
import { Zip } from "../../../loaders/Zip";


export interface iWakeupScreenParams {
    isNew: boolean;
};

export class WakeupScreen extends Op3dComponentBase<iWakeupScreenParams> {

    private static INSTANCE: WakeupScreen;

    private static SKIN_PATH: string = './skins/home/wakeup_screen.html';

    private static LIMIT = 15;
    private static ACTIVE_MENU_CLASS = "active-menu";
    private static RIGHT_GRID_SECTION = "right-grid-section";
    private static GRID_CLASS = "grid-view-2";

    private mSingleItem: HTMLElement;
    private mResultsContainer: HTMLElement;
    private mCurrPage: number = 0;
    private mFreeStringInput: HTMLInputElement;
    private mItemsSelectedCount: HTMLElement;
    private mItemsToDelete = Array<string>();
    private mSidebarMenu: HTMLElement;
    private mSetupType = eSetupType.USER;
    private mCurrSort: iHash<number> = { updatedAt: -1 };
    private mDeleteBtn: HTMLElement;
    private mListViewBtn: HTMLElement;
    private mGridViewBtn: HTMLElement;
    private mRightContentSection: HTMLElement;
    private mSortItems: iHash<HTMLElement> = {};
    private mCheckAll: HTMLInputElement;
    private mFilterLabels: Array<string> = []
    //private mCurrentSession: string
    private mRecentlyOpenedBtn: HTMLElement;
    private mMySetupsBtn: any;
    private mIsNew: boolean;
    private mTotalResultsElement: HTMLElement;
    private mSetupActionsBlock: HTMLElement;
    private mDownloadOpt: HTMLElement;

    constructor() {
        super({
            container: document.getElementById("wakeup-screen"),
            skinPath: WakeupScreen.SKIN_PATH
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (WakeupScreen.INSTANCE == null) {
            WakeupScreen.INSTANCE = new WakeupScreen();
        }

        try {
            (window as any).userGuiding.hideResourceCenter()
        } catch (e) {
            console.warn('UserGuiding hide recource center failed:', e);
        }

        return WakeupScreen.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pWakeupScreenParams: iWakeupScreenParams) {
        this.mIsNew = pWakeupScreenParams.isNew;
        $(function () {
            $("#modal").dialog();
        });

        try {
            if ((window as any).userGuidingUserStorage != null && (window as any).userGuidingUserStorage.attributes.isBasic == true) {
                (window as any).userGuiding.previewGuide(85423)

            }
        } catch (e) {
            console.warn('UserGuiding initialization failed:', e);
        }

        // ViewUtils.setElementVisibilityByDNone(this.mTryProfessioanlBtn, true);
    }
    //__________________________________________________________________________________________
    public show() {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, true)
    }
    //__________________________________________________________________________________________
    public hide() {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, false)
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {
        // this.mCurrentSession = Op3dUtils.idGenerator()

        document.body.classList.add(Strings.WAKEUP_SCREEN);
        this.mTotalResultsElement = this._getPart("total_result_element");
        this.mRightContentSection = this._getPart("right-content-section");
        this.mItemsSelectedCount = this._getPart("items-selected-count");
        this.mSingleItem = this._getPart("single-item");
        this.mResultsContainer = this.mSingleItem.parentElement;
        this.mSingleItem.parentElement.removeChild(this.mSingleItem);
        this.mFreeStringInput = this._getPart("free-string") as HTMLInputElement;
        this.mFreeStringInput.addEventListener('input', this._debounce(() =>
            this._getResults(true, this._getGeneralQuery()), 500));

        this.mSidebarMenu = this._getPart("sidebar-menu") as HTMLElement;
        this.mResultsContainer.addEventListener('scroll', (event: Event) => this._onScroll(event));

        //this.mTryProfessioanlBtn = document.getElementById("try-professional");
        // this.mTryProfessioanlBtn.addEventListener("click",
        //     () => this._onTryProffesional());

        this.mCheckAll = this._getPart("check-all") as HTMLInputElement;
        this.mCheckAll.addEventListener("change", (e: Event) => this._onCheckAll(e));

        this.mSetupActionsBlock = this._getPart("setup_actions_block");
        this.mDownloadOpt = this._getPart("download_opt");
        this.mDownloadOpt.addEventListener("click", () => this._onDownloadOpt());
        this.mDeleteBtn = this._getPart("delete-btn");
        this.mDeleteBtn.addEventListener("click", () => this._onDeleteGeneral());
        this._getPart("new-design-btn").addEventListener("click", () =>
            this._onOpenDesignForm());

        this._getPart("back-to-scene").addEventListener("click", () => this._backToScene());

        this._getPart("existing-from-local").addEventListener("click", () =>
            SetupsManager.chooseOptFromLocal(this.mIsNew, false, () => this.close()));

        this.mRecentlyOpenedBtn = this._getPart("recently-opened-li")
        this.mRecentlyOpenedBtn.addEventListener("click", () => this._onRecentlyOpened(this.mRecentlyOpenedBtn));

        let aCheckboxList = this.mContainer.getElementsByClassName('dropdown-item');
        for (let i = 0; i < aCheckboxList.length; i++) {
            aCheckboxList[i].addEventListener('click', (e) => this._filterLabels(e, aCheckboxList[i]))
        }



        // let a3DOptixExamples = this._getPart("3doptix-examples-li");
        // a3DOptixExamples.addEventListener("click", (e) => this._onOpen3DOptixExamples(a3DOptixExamples));


        this.mMySetupsBtn = this._getPart("my-setups-li");
        this.mMySetupsBtn.addEventListener("click", () => this._onOpenMySetups(this.mMySetupsBtn));

        let aWarehouse = this._getPart("my-setups-li");
        aWarehouse.addEventListener("click", () => this._onOpenWarehouse(aWarehouse));

        this.mListViewBtn = this._getPart("list-view", true);
        this.mListViewBtn.addEventListener("click", () => this._changeView(true));
        this.mGridViewBtn = this._getPart("grid-view", true);
        this.mGridViewBtn.addEventListener("click", () => this._changeView(false));

        let aLinkToPricing = document.getElementById('try-professional') as HTMLAnchorElement;
        aLinkToPricing.href = ServerContext.pricing_base_link;

        this._initSort();
        await this._getResults(true, this._getGeneralQuery());



        this._onUpdatePermissions();
        // if (Op3dContext.USER_VO.isBasicLicense == true) {
        //     setTimeout(() => this.createModalTryPremium(), 2000);
        // }

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onUpdatePermissions(), this);

        this.mIsReady = true;




    }
    //__________________________________________________________________________________________
    private _onUpdatePermissions() {
        ViewUtils.setClassForElement(document.body, "try_premium",
            Op3dContext.USER_VO.isBasicLicense == true);


    }
    //__________________________________________________________________________________________
    private _filterLabels(_e: Event, pCheckboxElem: Element) {


        let aCurrLabel = pCheckboxElem.children[0] as any
        if (aCurrLabel.checked) {
            this.mFilterLabels.push(aCurrLabel.attributes.label.value)
        } else {
            this.mFilterLabels = this.mFilterLabels.filter(el => el !== aCurrLabel.attributes.label.value)
        }


        if (this.mFilterLabels.length !== 0) {
            let x = document.getElementsByClassName('all-select-label')[0]
            x.setAttribute('count', this.mFilterLabels.length + '')
        } else {
            let x = document.getElementsByClassName('all-select-label')[0]
            x.removeAttribute('count')
        }

        this._getResults(true, this._getGeneralQuery())
    }

    //__________________________________________________________________________________________
    private _backToScene() {
        let aContainer = document.getElementById(Main.CANVAS_CONTAINER_NAME);
        if (aContainer == null) {
            throw new Error("missing 3D canvas container");
        }

        this.showWakeupScreen(false, aContainer);
        this.hide();
    }
    //__________________________________________________________________________________________
    private _changeView(pIsList: boolean) {
        ViewUtils.setElementActive(this.mListViewBtn, pIsList);
        ViewUtils.setElementActive(this.mGridViewBtn, !pIsList);

        if (pIsList) {
            this.mRightContentSection.classList.remove(WakeupScreen.RIGHT_GRID_SECTION);
            this.mResultsContainer.classList.remove(WakeupScreen.GRID_CLASS);
        } else {
            this.mRightContentSection.classList.add(WakeupScreen.RIGHT_GRID_SECTION);
            this.mResultsContainer.classList.add(WakeupScreen.GRID_CLASS);
        }
    }
    //__________________________________________________________________________________________
    // private _onTryProffesional() {
    //     alert("Coming Soon");
    // }
    //__________________________________________________________________________________________
    private _onOpenWarehouse(_pItem: HTMLElement) {
        //this._changeActiveClassState(pItem, "3doptix-setups");
    }
    //__________________________________________________________________________________________
    public _onOpenMySetups(pItem: HTMLElement) {
        this._changeActiveClassState(pItem, "3doptix-setups");
        SetupsDataLoader.instance.needsUpdate = true;
        this.mFreeStringInput.value = "";
        this.mSetupType = eSetupType.USER;
        this.mCurrPage = 0;
        this._setDefaultSort();
        this._getResults(true, this._getGeneralQuery());
    }
    //__________________________________________________________________________________________
    private _setDefaultSort() {
        this.mCurrSort = { updatedAt: -1 };
        for (let item in this.mSortItems) {
            ViewUtils.setElementActive(this.mSortItems[item], item == "updatedAt");
        }
    }
    //__________________________________________________________________________________________
    private _changeActiveClassState(pItem: HTMLElement, pClass: string) {
        this.mSidebarMenu.classList.value = "sidebar-section " + pClass;
        $(this.mContainer).find("." + WakeupScreen.ACTIVE_MENU_CLASS).removeClass(WakeupScreen.ACTIVE_MENU_CLASS);
        pItem.classList.add(WakeupScreen.ACTIVE_MENU_CLASS);
    }
    //__________________________________________________________________________________________
    // private _onOpen3DOptixExamples(pItem: HTMLElement) {
    //     this._changeActiveClassState(pItem, "example-setups");

    //     SetupsDataLoader.instance.needsUpdate = true;
    //     this.mFreeStringInput.value = "";
    //     this.mCurrPage = 0;
    //     this.mSetupType = eSetupType.EXAMPLE;
    //     this._setDefaultSort();
    //     this._getResults(true, this._getGeneralQuery());
    // }
    //__________________________________________________________________________________________
    private async _onRecentlyOpened(pItem: HTMLElement) {

        this._changeActiveClassState(pItem, "recently-opened-setups");

        SetupsDataLoader.instance.needsUpdate = true;
        this.mFreeStringInput.value = "";
        this.mCurrPage = 0;
        this.mSetupType = eSetupType.USER;
        let aQuery = this._getGeneralQuery();
        aQuery.sort = { updatedAt: -1 };

        this._getResults(true, aQuery);
    }
    //__________________________________________________________________________________________
    public async _onOpenDesignForm() {
        Spinner.instance.show();

        let aOnFinish: Function = null;
        if (false == this.mIsNew) {
            aOnFinish = () => this.close();
        }

        NewDesignForm.instance.open({
            setup: null,
            state: eModalWindowState.NEW,
            isNew: this.mIsNew,
            onFinish: aOnFinish
        });

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    public async showWakeupScreen(pState: boolean, pContainer: HTMLElement) {

        if (pState) {
            try {
                (window as any).userGuiding.hideResourceCenter()
            } catch (e) {
                console.warn('UserGuiding hide recource center failed:', e);
            }

            SceneHistory.TO_AUTO_SAVE = false
            const event = new CustomEvent('closeCameraViewMenu');
            window.dispatchEvent(event);
            NotificationCenter.instance.close();

            ChoosePartMode.closeOpenTools()

            EventManager.dispatchEvent(EventsContext.ON_CLOSE, this)

            ViewUtils.setElementVisibilityByDNone(this._getPart("back-to-scene"),
                Op3dContext.SETUPS_MANAGER.currSetupID != null);

            this._setElementsByDNone(
                [pContainer,
                    document.getElementById('status_row'),
                    document.getElementById('tesselation-props'),
                    document.getElementById('side-bar-container'),
                    document.getElementById('version-display'),
                    document.getElementById('fps_input')],
                false
            );

            this._hideHeaderIcons(true)
            document.body.classList.add(Strings.WAKEUP_SCREEN);
            Op3dContext.PARTS_MANAGER.setSelectedPart(null)

            //this.mCurrPage = 0;
            if (this.mSidebarMenu.classList.value.includes('3doptix-setups')) {
                this._onOpenMySetups(this.mMySetupsBtn);

            } else if (this.mSidebarMenu.classList.value.includes('recently-opened-setups')) {
                this._onRecentlyOpened(this.mRecentlyOpenedBtn);

            } else {
                this._onOpenMySetups(this.mMySetupsBtn);
            }
            ///this.mItemsToDelete = new Array<string>();
            this._updateActionsBlock()
            // this._updateDeleteBtn()

        } else {
            try {
                (window as any).userGuiding.launchResourceCenter(2754)
            } catch (e) {
                console.warn('UserGuiding launch recource center failed:', e);
            }

            document.body.classList.remove(Strings.WAKEUP_SCREEN);
            SceneHistory.TO_AUTO_SAVE = true;
            this._setElementsByDNone(
                [pContainer,
                    document.getElementById('status_row'),
                    document.getElementById('side-bar-container'),
                    document.getElementById('tesselation-props'),
                    document.getElementById('version-display'),
                    document.getElementById('fps_input')],
                true
            );
            if (SceneContext.OP3D_SCENE != null) {
                SceneContext.OP3D_SCENE.updateRendererSize();
            }
            this._hideHeaderIcons(false);
        }
    }
    //__________________________________________________________________________________________
    private _hideHeaderIcons(pState: boolean) {
        let aIconHeader = document.querySelector('.icons-header') as HTMLElement;
        if (null == aIconHeader) {
            return;
        }

        if (pState) {
            aIconHeader.style.height = '0px';
            aIconHeader.style.overflow = 'hidden';
        } else {
            aIconHeader.style.height = '55px';
            aIconHeader.style.overflow = 'unset';
        }
    }
    //__________________________________________________________________________________________
    private _setElementsByDNone(pElements: Array<HTMLElement>, pState: boolean) {
        for (let el of pElements) {
            pState
                ?
                ViewUtils.setElementVisibilityByDNone(el, true)
                :
                ViewUtils.setElementVisibilityByDNone(el, false)
        }
    }
    //__________________________________________________________________________________________
    private _initSort() {

        this.mSortItems = {};
        $(this.mContainer).find("[sortable]").each((_index, element) => {
            let aAttr = element.getAttribute("sortable");
            this.mSortItems[aAttr] = element as HTMLElement;
            element.parentElement.addEventListener("click", () => this._sortBy(aAttr));
        })
    }
    //__________________________________________________________________________________________
    private _sortBy(pPath: string) {
        let aClasses = this.mSortItems[pPath].className;
        let aSortType: number;
        if (aClasses.indexOf("up") != -1) {
            aSortType = -1;
            this.mSortItems[pPath].classList.value = "sort-item down";
        } else {
            this.mSortItems[pPath].classList.value = "sort-item up";
            aSortType = 1;
        }

        for (let item in this.mSortItems) {
            ViewUtils.setElementActive(this.mSortItems[item], item == pPath);
        }

        this.mCurrSort = {};
        this.mCurrSort[pPath] = aSortType;
        this.mCurrPage = 0;

        this._getResults(true, this._getGeneralQuery());
    }
    //__________________________________________________________________________________________
    private _onDeleteGeneral() {
        if (this.mItemsToDelete.length > 0) {
            Popup.instance.open({
                text: MessagesHandler.REMOVE_SETUPS_MSG_CONFIRMATION,
                noBtn: { title: "Cancel", callback: () => { } },
                yesBtn: { title: "Yes", callback: () => this._removeItems() }
            });
        }
    }
    //__________________________________________________________________________________________
    private _onDownloadOpt() {
        if (this.mItemsToDelete.length > 0) {
            this._downloadOptFiles();
        }
    }
    //__________________________________________________________________________________________
    private async _removeItems() {

        Spinner.instance.show();

        let aDeleted = 0;
        let aErrors = 0;
        for (let i = 0; i < this.mItemsToDelete.length; i++) {

            let aRes = await SetupsDataLoader.instance.remove({ _id: this.mItemsToDelete[i] } as any);
            if (aRes.success) {
                aDeleted++
            } else {
                aErrors++;
            }
        }

        if (aErrors > 0) {
            Popup.instance.open({
                text: "Action finished with " + aErrors + " Errors"
            });
        }

        this.mCurrPage = 0;
        this.mCheckAll.checked = false;
        this.mItemsToDelete = new Array<string>();
        this._updateActionsBlock();
        Spinner.instance.hide();
        this._getResults(true, this._getGeneralQuery());


    }
    //__________________________________________________________________________________________
    private async _downloadOptFiles() {

        Spinner.instance.show();

        let aDownloaded = 0;
        let aErrors = 0;
        for (let i = 0; i < this.mItemsToDelete.length; i++) {

            try {
                let aRes = await ServerContext.SERVER.getOneSetup({ _id: this.mItemsToDelete[i] });
                if (aRes.success) {

                    let aResContentUrl = `${ServerContext.user_prefix_setups}${aRes.data.owner}/${this.mItemsToDelete[i]}/setup.opt`;
                    let aContent = await fetch(aResContentUrl).then(data => data.text());
                    aRes.data.content = aContent;
                    Zip.createAndDownloadOpt({
                        data: JSON.stringify(aRes.data),
                        fileName: aRes.data.name,
                        file_format: ".opt",
                    });
                    aDownloaded++
                } else {
                    aErrors++;
                }
            } catch (e) {
                aErrors++;
            }
        }

        if (aErrors > 0) {
            Popup.instance.open({
                text: "Action finished with " + aErrors + " Errors"
            });
        }

        this._updateActionsBlock();
        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _onCheckAll(e: Event) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

        const aIschecked = this.mCheckAll.checked;
        this.mItemsToDelete = new Array<string>();

        $(this.mContainer).find(".setup-checkbox").each((_index, element) => {
            if (!(element as HTMLInputElement).disabled) {
                (element as HTMLInputElement).checked = aIschecked;
                element.dispatchEvent(new Event('change'));
            }
        });
    }
    //__________________________________________________________________________________________
    private _onScroll(event: Event) {
        let aElement = event.target as any;

        //let aScrollHeight = aElement.scrollHeight;
        //let aClientHeight = aElement.clientHeight;

        let aPixelRatio = Op3dUtils.isMac() ? window.devicePixelRatio - 1 : window.devicePixelRatio;
        if (aPixelRatio > 1) {
            // aPixelRatio = 1;
            aPixelRatio = 0.99;
        }

        let aIsInBottomOfThePage = aElement.offsetHeight + aElement.scrollTop >= aElement.scrollHeight * aPixelRatio;
        if (aIsInBottomOfThePage) {
            if (((1 + this.mCurrPage) * WakeupScreen.LIMIT) < SetupsDataLoader.instance.dataTotal) {
                SetupsDataLoader.instance.needsUpdate = true;
                this.mCurrPage++;
                this._getResults(false, this._getGeneralQuery());
            }
        }

        // if (aElement.scrollHeight - aElement.scrollTop === aElement.clientHeight) {

        //     if (((1 + this.mCurrPage) * WakeupScreen.LIMIT) < SetupsDataLoader.instance.dataTotal) {
        //         SetupsDataLoader.instance.needsUpdate = true;
        //         this.mCurrPage++;
        //         this._getResults(false, this._getGeneralQuery());
        //     }
        // }
    }
    //__________________________________________________________________________________________
    private _clearResults() {
        this.mCurrPage = 0;
        this.mItemsToDelete = new Array<string>();
        this.mCheckAll.checked = false;
        SetupsDataLoader.instance.needsUpdate = true;
        ViewUtils.removeElementChildren(this.mResultsContainer);
    }
    //__________________________________________________________________________________________
    private _debounce(func, wait) {
        let timeout;

        // This is the function that is returned and will be executed many times
        // We spread (...args) to capture any number of parameters we want to pass
        return function executedFunction(...args) {

            // The callback function to be executed after 
            // the debounce time has elapsed
            const later = () => {
                // null timeout to indicate the debounce ended
                timeout = null;

                // Execute the callback
                func(...args);
            };
            // This will reset the waiting every function execution.
            // This is the step that prevents the function from
            // being executed because it will never reach the 
            // inside of the previous setTimeout  
            clearTimeout(timeout);

            // Restart the debounce waiting period.
            // setTimeout returns a truthy value (it differs in web vs Node)
            timeout = setTimeout(later, wait);
        };
    };
    //__________________________________________________________________________________________

    private _getGeneralQuery(): iSetupMenuSearchQuery {

        let aServerMetaDataQuery: iSetupMenuSearchQuery;
        let aStr = Op3dUtils.getNormalizedName(this.mFreeStringInput.value);

        switch (this.mSetupType) {
            case eSetupType.EXAMPLE:
                aServerMetaDataQuery = {
                    limit: WakeupScreen.LIMIT,
                    filters: {
                        "parameters.setupType": eSetupType.EXAMPLE,
                        permission: { $in: [eDataPermission.PUBLIC] }
                    },
                    sort: this.mCurrSort,
                    skip: (this.mCurrPage * WakeupScreen.LIMIT),
                };
                break;
            case eSetupType.USER:
                aServerMetaDataQuery = {
                    limit: WakeupScreen.LIMIT,
                    filters: {
                        "parameters.setupType": eSetupType.USER,
                        "permission": { $in: [eDataPermission.PUBLIC, eDataPermission.PRIVATE] }
                    },
                    sort: this.mCurrSort,
                    skip: (this.mCurrPage * WakeupScreen.LIMIT),
                };

                if (aStr != "") {
                    aServerMetaDataQuery.filters.name = { $regex: aStr, $options: "i" };
                }

                if (this.mFilterLabels.length > 0) {
                    aServerMetaDataQuery.filters["parameters.details.labels"] = { $in: this.mFilterLabels };
                }
                break;
        }

        return aServerMetaDataQuery;
    }
    //__________________________________________________________________________________________
    private async _getResults(pClear: boolean, pQuery: iSetupMenuSearchQuery) {
        if (pClear) {
            this._clearResults();
        }

        Spinner.instance.show();
        //let aResults = await SetupsDataLoader.instance.getManyItems(pQuery);
        let aResults = await SetupsDataLoader.instance.getManyItems(pQuery);
        this._fillList(aResults);

        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip().on('click', function () {
            $(this).blur()
        });

        //$(this.mContainer).find('[data-toggle="tooltip"]').tooltip();
        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _onCheckItem(pEvent: Event, pId: string, pCheckbox: HTMLInputElement) {
        pEvent.preventDefault();
        pEvent.stopImmediatePropagation();
        pEvent.stopPropagation();

        if (pCheckbox.checked) {
            this.mItemsToDelete.push(pId);
            pCheckbox.style.zIndex = '2';

        } else {
            pCheckbox.style.zIndex = '0';
            let aIndex = this.mItemsToDelete.indexOf(pId);
            if (aIndex != -1) {
                this.mItemsToDelete.splice(aIndex, 1);
            }
            this.mCheckAll.checked = false
        }

        this._updateActionsBlock();
        // this._updateDeleteBtn();
    }
    //__________________________________________________________________________________________
    private _updateActionsBlock() {
        ViewUtils.setClassForElement(this.mSetupActionsBlock.parentElement, "actions_block", this.mItemsToDelete.length > 0)
        this.mItemsSelectedCount.innerHTML = `<strong>${this.mItemsToDelete.length}</strong>`;
    }
    // private _updateDeleteBtn() {
    //     ViewUtils.setClassForElement(this.mDeleteBtn.parentElement, "delete-btn", this.mItemsToDelete.length > 0)
    //     this.mItemsSelectedCount.innerHTML = `<strong>${this.mItemsToDelete.length}</strong>`;
    // }
    //__________________________________________________________________________________________
    private _updatePaging() {
        let aText = DataLoader.getPagingText(
            SetupsDataLoader.instance.dataTotal,
            WakeupScreen.LIMIT,
            this.mCurrPage);
        this.mTotalResultsElement.innerHTML = aText;
    }
    //__________________________________________________________________________________________
    private _fillList(pResults: iSetupMetadata[]) {
        this._updatePaging();

        if (pResults.length === 0) {
            SceneHistory.TO_AUTO_SAVE = false;
            this._getPart("back-to-scene").classList.add('d-none')
            this.mCheckAll.checked = false;
        }

        for (let i = 0; i < pResults.length; i++) {
            const aData = pResults[i];
            if (!aData.filtered && aData.filtered !== undefined) {
                continue;
            }

            let aClone = this.mSingleItem.cloneNode(true) as HTMLElement;

            let aUserPrefix = `${ServerContext.user_prefix_setups}${Op3dContext.USER_VO.id}`;
            let aImg = Op3dUtils.getElementIn(aClone, "setup-image", true) as HTMLImageElement;
            aImg.alt = aData.name;
            aImg.src = `${aUserPrefix}/${aData._id}/image.png?V=${Op3dUtils.idGenerator()}`

            aImg.onload = () => {
                ViewUtils.removeFromParent(Op3dUtils.getElementIn(aClone, "setup-image-ph", true));
                aImg.classList.remove('d-none');
            }

            let aName = Op3dUtils.getElementIn(aClone, "setup-name");
            aName.innerText = aData.name;
            aName.setAttribute('title', aData.name);

            let aLabel = Op3dUtils.getElementIn(aClone, "setup-label");
            let aSetupPermission = Op3dUtils.getElementIn(aClone, "setup-permission");
            aData.permission == 0 ? aSetupPermission.innerText = " Private" : aSetupPermission.innerText = " Public"
            aName.innerText = aData.name;


            if (aData.parameters.details.labels != null &&
                aData.parameters.details.labels.length > 0 &&
                aData.parameters.details?.labels.length < 3) {

                aLabel.innerHTML = aData.parameters.details.labels.map(el => `<span class=${el.toLowerCase().replace(" ", "_")}>${el}</span>`).join(' ')

            } else if (aData.parameters.details.labels != null && aData.parameters.details.labels.length > 2) {
                let aLabels = []
                for (let i = 0; i < 2; i++) {
                    let aLabel = aData.parameters.details.labels[i]
                    aLabels.push(`<span class='${aLabel.toLowerCase().replace(" ", "_")}'>${aLabel}</span>`)

                }
                aLabel.innerHTML = aLabels.join(' ') + '...'
            }

            let aOpened = Op3dUtils.getElementIn(aClone, "setup-closed");
            let aClosed = Op3dUtils.getElementIn(aClone, "setup-opened");

            if (ServerContext.env == ENVS.DEV) {
                aClosed.addEventListener('click', () => {
                    $(this.mContainer).find('[data-toggle="tooltip"]').tooltip('hide')
                    ServerContext.SERVER.closeSetup({ _id: aData._id })
                    this._getResults(true, this._getGeneralQuery());
                })
            }

            ViewUtils.setElementVisibilityByDNone(aClosed, aData.parameters.is_opened)
            ViewUtils.setElementVisibilityByDNone(aOpened, !aData.parameters.is_opened)

            let aDateGrid = Op3dUtils.getElementIn(aClone, "setup-date-grid");
            let aDateList = Op3dUtils.getElementIn(aClone, "setup-date-list");

            let aDate = new Date(aData.updatedAt);
            let aDateStr = "";
            aDateStr += aDate.getDate() + " ";
            let aMonth = aDate.toLocaleString('default', { month: 'short' });
            aDateStr += aMonth + " ";
            aDateStr += aDate.getFullYear();

            let aMinutes = aDate.getMinutes();
            let aHours = aDate.getHours();
            let aTimeStr = (aHours < 10 ? '0' + aHours : aHours) + ":" +
                (aMinutes < 10 ? '0' + aMinutes : aMinutes);

            aDateGrid.innerHTML = aDateStr + " | " + aTimeStr;
            aDateList.innerHTML = aDateStr + "<br>" + aTimeStr;

            let aCheckbox = Op3dUtils.getElementIn(aClone, "setup-checkbox", true) as HTMLInputElement;
            aCheckbox.addEventListener("change",
                (e: Event) => this._onCheckItem(e, aData._id, aCheckbox));
            let aUnit = Op3dUtils.getElementIn(aClone, "setup-unit");
            aUnit.innerText = (aData.parameters.unit == eUnitType.INCHES) ? "Imperial" : "Metric";

            aClone.addEventListener("click", (e: Event) => this._onSelectItem(e, aData));
            this.mResultsContainer.append(aClone);
        }
    }
    //__________________________________________________________________________________________
    public async _onClose(): Promise<void> {
        document.body.classList.remove(Strings.WAKEUP_SCREEN);
        this.showWakeupScreen(false, Op3dContext.CONTAINER);
        this.hide();
    }
    //__________________________________________________________________________________________
    private async _getSetup(pID: string) {
        let aRes = await ServerContext.SERVER.getOneSetup({ _id: pID, to_open: true });

        Op3dContext.SCENE_HISTORY.ignoreSaving = false;
        let aResContentUrl = `${ServerContext.user_prefix_setups}`;
        aResContentUrl += `${Op3dContext.USER_VO.id}/${pID}/setup.opt?V=${Op3dUtils.idGenerator()}`;
        let aContent = await fetch(aResContentUrl).then(data => data.text());

        if (false == aRes.success || aContent == null) {
            Popup.instance.open({ text: MessagesHandler.ERROR_WHILE_LOADING_SETUP });
            return null;
        }

        aRes.data.content = aContent;
        return aRes.data;
    }
    //__________________________________________________________________________________________
    private async _onSelectItem(pEvent: Event, pData: iSetupMetadata) {
        if (pEvent.target instanceof HTMLInputElement) {
            return;
        }
        Spinner.instance.show();

        if ((true == this.mIsNew) ||
            (UnitHandler.PRESENTED_UNIT == pData.parameters.unit)) {
            let aSetup = await this._getSetup(pData._id);
            if (null == aSetup) {
                return;
            }

            if (false == this.mIsNew) {
                await Op3dContext.SETUPS_MANAGER.closeSetup();
            }

            Spinner.instance.hide();
            Op3dContext.MANAGER.openExistingSetup(aSetup, this.mIsNew);
            this.close();

        } else {
            let aNewSetupID = pData._id;
            localStorage.setItem(Main.OP3D_SETUP_ID, aNewSetupID);
            Op3dContext.MANAGER.reload();
        }
    }
    //__________________________________________________________________________________________
    // private async _openSetup(pData: iDownloadFullSetupData) {
    //     if (true == this.mIsNew) {
    //         Op3dContext.MANAGER.openExistingSetup(pData);
    //     } else {
    //         if (UnitHandler.currUnit == pData.parameters.unit) {
    //             await Op3dContext.SETUPS_MANAGER.onOpenSetup({
    //                 addToExisting: false,
    //                 content: pData
    //             });
    //         } else {
    //             Op3dContext.MANAGER.reloadAndOpenExistingSetup(pData);
    //         }
    //     }
    // }
    //__________________________________________________________________________________________
    // private _onDeleteOne(e: Event, pData: iSetupMetadata) {
    //     if (e) {
    //         e.preventDefault();
    //         e.stopImmediatePropagation();
    //         e.stopPropagation();
    //     }
    //     Popup.instance.open({
    //         text: MessagesHandler.REMOVE_SETUP_MSG_CONFIRMATION,
    //         noBtn: { title: "Cancel", callback: () => { } },
    //         yesBtn: { title: "Yes", callback: () => this._removeItem(pData) }
    //     });
    // }
    //__________________________________________________________________________________________
    protected async _removeItem(pData: iSetupMetadata) {
        let aRes = await SetupsDataLoader.instance.remove(pData);
        if (aRes.success) {
            this._getResults(true, this._getGeneralQuery());
        } else {
            Popup.instance.open({ text: MessagesHandler.ERROR_REMOVING_SETUP });
        }
    }
    //__________________________________________________________________________________________
    public distract() {
        /**
         * @TODO
         */

        SetupsDataLoader.instance.needsUpdate = true;
        ViewUtils.removeFromParent(this.mContainer);
        //ViewUtils.removeFromParent(this.mTryProfessioanlBtn);

        //this.mTryProfessioanlBtn = null;
        this.mSingleItem = null;
        this.mResultsContainer = null;
        this.mCurrPage = null;
        this.mFreeStringInput = null;
        this.mItemsSelectedCount = null;
        this.mItemsToDelete = null;
        this.mSidebarMenu = null;
        this.mSetupType = null;
        this.mCurrSort = null;
        this.mDeleteBtn = null;
        this.mListViewBtn = null;
        this.mGridViewBtn = null;
        this.mRightContentSection = null;
        this.mSortItems = null;
    }
    //__________________________________________________________________________________________
}
