import { BufferGeometry, DoubleSide, Float32BufferAttribute, LineBasicMaterial, LineSegments, Mesh, MeshBasicMaterial, PlaneGeometry, Raycaster, Vector2, Vector3 } from "three";
import { ePlaneType } from "../_context/Enums";
import { iClientPoint } from "../_context/_interfaces/Interfaces";
import { Op3dContext } from "../_context/Op3dContext";
import { SceneContext } from "./SceneContext";
import { Part } from "../parts/Part";

export class PositionGenerator {
    private static PLANE_SIZE: number = 100000000;
    private mHorizontalPlane: Mesh;
    private mRayCaster: Raycaster = new Raycaster();
    public static PLANE_HEIGHT: number = 0;

    constructor() {
        this._addPlane();
    }
    //___________________________________________________________________
    public distract() {
        //todo
    }
    //___________________________________________________________________
    public updateH(pY: number) {
        this.mHorizontalPlane.position.y = pY;
    }
    //___________________________________________________________________
    private _addPlane() {
        //HORIZONTAL PLANE
        let aGeo = new PlaneGeometry(PositionGenerator.PLANE_SIZE, PositionGenerator.PLANE_SIZE);
        let aMat = new MeshBasicMaterial({ side: DoubleSide, color: 0x0000ff, transparent: false, opacity: 1 });
        aMat.visible = false;
        this.mHorizontalPlane = new Mesh(aGeo, aMat);
        this.mHorizontalPlane.position.set(0, PositionGenerator.PLANE_HEIGHT, 0);
        this.mHorizontalPlane.rotateX(-Math.PI / 2);
        this.mHorizontalPlane.visible = true;
        SceneContext.MAIN_SCENE.add(this.mHorizontalPlane);
    }
    //______________________________________________________
    public getMousePointPlaneIntersection(pPoint: iClientPoint,
        _pPlaneType: ePlaneType,
        _pPart: Part) {

        //this._setHorizontalPlanePosition(pPart);
        let aMouse = new Vector2();
        let aBounding = Op3dContext.CONTAINER.getBoundingClientRect();
        //this.mHorizontalPlane.rotation.copy(SceneContext.CAMERA.rotation);

        aMouse.x = ((pPoint.clientX - aBounding.left) / SceneContext.RENDERER.domElement.clientWidth) * 2 - 1;
        aMouse.y = - ((pPoint.clientY - aBounding.top) / SceneContext.RENDERER.domElement.clientHeight) * 2 + 1;

        this.mRayCaster.setFromCamera(aMouse, SceneContext.CAMERA);

        this.mHorizontalPlane.updateMatrixWorld(true);
        let aIntersects = this.mRayCaster.intersectObject(this.mHorizontalPlane);

        if (aIntersects.length > 0) {
            let aPos = aIntersects[0].point;
            return aPos;
        }
    }
    //___________________________________________________________________


    public get horizontal() {
        return this.mHorizontalPlane;
    }
    //___________________________________________________________________

    public getPos(e: MouseEvent) {
        let aMouse = new Vector2();
        let aBounding = Op3dContext.CONTAINER.getBoundingClientRect();
        //this.mHorizontalPlane.rotation.copy(SceneContext.CAMERA.rotation);

        aMouse.x = ((e.clientX - aBounding.left) / SceneContext.RENDERER.domElement.clientWidth) * 2 - 1;
        aMouse.y = - ((e.clientY - aBounding.top) / SceneContext.RENDERER.domElement.clientHeight) * 2 + 1;

        let aVec = new Vector3(aMouse.x, aMouse.y, 0.5);
        aVec.unproject(SceneContext.CAMERA);
        aVec.sub(SceneContext.CAMERA.position).normalize();
        let distance = - SceneContext.CAMERA.position.z / aVec.z;
        let pos = new Vector3().copy(SceneContext.CAMERA.position).add(aVec.multiplyScalar(distance));


        let aGeo = new BufferGeometry();
        let aPos = new Float32BufferAttribute([...SceneContext.CAMERA.position.toArray(), ...pos.toArray()], 3);
        let aColor = new Float32BufferAttribute([1, 0, 0, 1, 0, 1, 0, 1], 4);
        aGeo.setAttribute('position', aPos);
        aGeo.setAttribute('color', aColor);

        let aLine = new LineSegments(aGeo, new LineBasicMaterial({ vertexColors: true, transparent: true }));
        SceneContext.MAIN_SCENE.add(aLine);

    }
    //___________________________________________________________________
}