import { MathContext } from "../../../_context/MathContext";
import { iHash } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ParserContext } from "../../../parser/ParserContext";
import { iSourceDirectionalData } from "../../../parts/behaviors/LightSourceContext";
import { eSmRaysKind } from "../../../simulation/SimulationContext";
import { ViewUtils } from "../../ViewUtils";
import { RadioComponent } from "../../components/RadioComponent";
import { PartInfoSection } from "../PartInfoSection";
import { NumberInputElement } from "../_components/NumberInputElement";
import { eAngleUnit } from "../piMoveRotateSection";
import { piLightSourceSection } from "./piLightSourceSection";

export enum eCoordinate {
    X,
    Y,
    Z,
    PHI,
    THETA
}
export class piAngularInformation extends PartInfoSection<{}>{

    private mCoordinatesComponent: RadioComponent;
    private mElements: iHash<NumberInputElement>;
    private mParams: { onChange: (pShape: eSmRaysKind) => void };
    private mAngleUnit: HTMLSelectElement

    constructor(pContainer: HTMLElement, pParams: { onChange: (pShape: eSmRaysKind) => void }) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_directional_coordinates.html'
        });
        this.mParams = pParams;
    }
    //__________________________________________________________________________________________
    private _changeFieldName() {
        let aValue = parseInt(this.mAngleUnit.value);
        let aSign = (eAngleUnit.DEG == aValue) ? '°' : ':';

        Op3dUtils.getElementIn(this.mContainer,
            'theta-input').setAttribute('field_name', 'Θ' + aSign);
        Op3dUtils.getElementIn(this.mContainer,
            'phi-input').setAttribute('field_name', 'φ' + aSign);
        Op3dUtils.getElementIn(this.mContainer,
            'azimuth-input').setAttribute('field_name', 'Θz' + aSign);
    }
    //__________________________________________________________________________________________
    private _onChangeRotationUnit() {
        this._changeFieldName();
        let aSign = (eAngleUnit.DEG == parseInt(this.mAngleUnit.value)) ? '°' : '';
        this.mElements["direction_theta"].updateCreationData({
            field_name: 'Θ' + aSign,
            isGlobalToFixed: true
        });
        this.mElements["direction_phi"].updateCreationData({
            field_name: 'φ' + aSign,
            isGlobalToFixed: true
        });
        this.mElements["azimuth_z"].updateCreationData({
            field_name: 'Θz' + aSign,
            isGlobalToFixed: true
        });

        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mAngleUnit.value));
        let aFactor = (true == aIsDeg) ? MathContext.DEG_TO_RAD : 0.001;

        let aTheta = (this.mElements["direction_theta"].convertedValue / aFactor);
        let aPhi = (this.mElements["direction_phi"].convertedValue / aFactor);
        let aAzimuth = (this.mElements["azimuth_z"].convertedValue / aFactor);

        let aMul = (true == aIsDeg) ? 1 / (1000 * Math.PI / 180) : 1000 * Math.PI / 180;
        let aRange = DataUtils.getObjectCopy(this.mElements["direction_theta"].range);
        aRange.min *= aMul;
        aRange.max *= aMul;

        this.mElements["direction_theta"].setData({
            // factor: aFactor,
            range: aRange
        });

        aRange = DataUtils.getObjectCopy(this.mElements["direction_phi"].range);
        aRange.min *= aMul;
        aRange.max *= aMul;

        this.mElements["direction_phi"].setData({
            //  factor: aFactor,
            range: aRange
        });

        aRange = DataUtils.getObjectCopy(this.mElements["azimuth_z"].range);
        aRange.min *= aMul;
        aRange.max *= aMul;

        this.mElements["azimuth_z"].setData({
            factor: aFactor,
            range: aRange
        });

        this.mElements["direction_theta"].value = aTheta;
        this.mElements["direction_phi"].value = aPhi;
        this.mElements["azimuth_z"].value = aAzimuth;
    }
    //__________________________________________________________________________________________
    private _initAngularUnit() {
        let aDefaultAngleUnit = eAngleUnit.DEG;
        this.mAngleUnit = this._getPart('angular-info-unit') as HTMLSelectElement;
        this.mAngleUnit.value = aDefaultAngleUnit.toString();
        this.mAngleUnit.addEventListener('change', () => this._onChangeRotationUnit());

        this._changeFieldName();
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mCoordinatesComponent = new RadioComponent(this._getPart("coordinates-component"), {
            value_attr: "coordinates-btn",
            onChange: (pVal: string) => this._onChangeCoordinatesView(pVal),
            triggers: {
                saveHistory: false,
                saveScene: false,
                triggerAnalysis: false
            }
        });

        this._initAngularUnit();
        this.mElements = {
            azimuth_z: piLightSourceSection.getNumberInputElement({
                hasArrows: true,
                sign: ParserContext.SPECIAL_CHARACTERS.degree,
                container: this._getPart('azimuth-z'),
                label: "Azimuth",
                step: 1,
                range: { min: -90, max: 90 },
                toFixed: 8,
                defaultValue: 0,
                worldToUnit: 1,
                factor: MathContext.DEG_TO_RAD,
                callback: () => this._onChangeCoordinate(null),
                triggers: {
                    saveHistory: true,
                    saveScene: true,
                    triggerAnalysis: true
                }
            }),
            direction_x: piLightSourceSection.getNumberInputElement({
                container: this._getPart("cartesian-coordinaes-x"),
                defaultValue: 0,
                range: { min: -1, max: 1 },
                toFixed: 8,
                callback: () => this._onChangeCoordinate(eCoordinate.X),
                step: 0.1,
                worldToUnit: 1,
                hasArrows: true,
                triggers: {
                    saveHistory: true,
                    saveScene: true,
                    triggerAnalysis: true
                }
            }),
            direction_y: piLightSourceSection.getNumberInputElement({
                container: this._getPart("cartesian-coordinaes-y"),
                defaultValue: 0,
                range: { min: -1, max: 1 },
                toFixed: 8,
                callback: () => this._onChangeCoordinate(eCoordinate.Y),
                step: 0.1,
                worldToUnit: 1,
                hasArrows: true,
                triggers: {
                    saveHistory: true,
                    saveScene: true,
                    triggerAnalysis: true
                }
            }),
            direction_z: piLightSourceSection.getNumberInputElement({
                container: this._getPart("cartesian-coordinaes-z"),
                defaultValue: 1,
                range: { min: -1, max: 1 },
                callback: () => this._onChangeCoordinate(eCoordinate.Z),
                step: 0.1,
                toFixed: 8,
                worldToUnit: 1,
                hasArrows: true,
                triggers: {
                    saveHistory: true,
                    saveScene: true,
                    triggerAnalysis: true
                }
            }),
            direction_theta: piLightSourceSection.getNumberInputElement({
                container: this._getPart("spherical-coordinaes-theta"),
                defaultValue: 0,
                range: { min: 0, max: 180 },
                callback: () => this._onChangeCoordinate(eCoordinate.THETA),
                step: 0.5,
                worldToUnit: 1,
                toFixed: 6,
                hasArrows: true,
                factor: MathContext.DEG_TO_RAD,
                triggers: {
                    saveHistory: true,
                    saveScene: true,
                    triggerAnalysis: true
                }
            }),
            direction_phi: piLightSourceSection.getNumberInputElement({
                container: this._getPart("spherical-coordinaes-phi"),
                defaultValue: 0,
                range: { min: -180, max: 180 },
                callback: () => this._onChangeCoordinate(eCoordinate.PHI),
                step: 0.5,
                toFixed: 6,
                worldToUnit: 1,
                hasArrows: true,
                factor: MathContext.DEG_TO_RAD,
                triggers: {
                    saveHistory: true,
                    saveScene: true,
                    triggerAnalysis: true
                }
            }),
        };
    }
    //__________________________________________________________________________________________
    private _onChangeCoordinate(pCoordinate: eCoordinate) {
        switch (pCoordinate) {
            case eCoordinate.PHI:
            case eCoordinate.THETA: {
                const aPhi = this.mElements["direction_phi"].convertedValue;//* MathContext.DEG_TO_RAD;
                const aTheta = this.mElements["direction_theta"].convertedValue;// * MathContext.DEG_TO_RAD;

                let aX = Math.cos(aPhi) * Math.sin(aTheta);
                let aY = Math.sin(aPhi) * Math.sin(aTheta);
                let aZ = Math.cos(aTheta);

                this.mElements["direction_x"].value = aX;
                this.mElements["direction_y"].value = aY;
                this.mElements["direction_z"].value = aZ;
                break;
            }
            case eCoordinate.X:
            case eCoordinate.Y:
            case eCoordinate.Z: {
                let aX = this.mElements["direction_x"].value;
                let aY = this.mElements["direction_y"].value;
                let aZ = this.mElements["direction_z"].value;
                let aTheta = Math.atan(aY / aX);
                let aVal = Math.sqrt((aX * aX) + (aY * aY));
                let aPhi = Math.atan(aVal / aZ);
                this.mElements["direction_phi"].convertedValue = aPhi;// * MathContext.RAD_TO_DEG;
                this.mElements["direction_theta"].convertedValue = aTheta;// * MathContext.RAD_TO_DEG;
                break;
            }

        }

        this.mParams.onChange(null);
    }
    //__________________________________________________________________________________________
    public _getData() {
        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mAngleUnit.value));
        let aFactor = aIsDeg ? 1 : MathContext.RAD_TO_DEG / 1000;
        let aDirectionalData: iSourceDirectionalData = {
            direction_phi: this.mElements.direction_phi.value * aFactor,
            direction_theta: this.mElements.direction_theta.value * aFactor,
            direction_x: this.mElements.direction_x.value,
            direction_y: this.mElements.direction_y.value,
            direction_z: this.mElements.direction_z.value,
            azimuth_z: this.mElements.azimuth_z.value * aFactor,
        };
        return aDirectionalData;
    }
    //__________________________________________________________________________________________
    public fillSection(pDirectionalData: iSourceDirectionalData) {
        this._fillSection(pDirectionalData);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pDirectionalData: iSourceDirectionalData): void {
        let aIsDeg = (eAngleUnit.DEG == parseInt(this.mAngleUnit.value));
        let aFactor = aIsDeg ? 1 : MathContext.DEG_TO_RAD * 1000;
        this.mElements.azimuth_z.value = pDirectionalData['azimuth_z'] * aFactor;
        this.mElements.direction_x.value = pDirectionalData['direction_x'];
        this.mElements.direction_y.value = pDirectionalData['direction_y'];
        this.mElements.direction_z.value = pDirectionalData['direction_z'];
        this.mElements.direction_theta.value = pDirectionalData['direction_theta'] * aFactor;
        this.mElements.direction_phi.value = pDirectionalData['direction_phi'] * aFactor;
        this._onChangeCoordinatesView(this.mCoordinatesComponent.chosenOption);
    }
    //__________________________________________________________________________________________
    private _onChangeCoordinatesView(pVal: string) {
        $(this.mContainer).find("[coordinates]").each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                pVal != "" && element.getAttribute('coordinates').indexOf(pVal) != -1);
        });
    }
    //__________________________________________________________________________________________
}
