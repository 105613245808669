﻿import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { iNumericKeyHash } from "../../../_context/_interfaces/Interfaces";
import { eSurfaceDeformation, iSurfaceDeformation, iZernikeDeformation } from "../../../data/VO/OpticsVOInterfaces";
import { UnitHandler } from "../../../units/UnitsHandler";
import { newNumberInputElement } from "../../part_info/_components/NewNumberInputElement";
import { sdSurfaceDeformation } from "./sdSurfaceDeformation";


export type tZernikeDeformation = eSurfaceDeformation.ZERNIKE_ANNULAR_PHAZE |
    eSurfaceDeformation.ZERNIKE_FRINGE_PHAZE | eSurfaceDeformation.ZERNIKE_FRINGE_SAG |
    eSurfaceDeformation.ZERNIKE_STANDARD_PHAZE | eSurfaceDeformation.ZERNIKE_STANDARD_SAG;



export class sdZernike extends sdSurfaceDeformation<iSurfaceDeformation<iZernikeDeformation>>{

    private mTermsParent: HTMLElement;
    private mCoeffsElemets = new Array<newNumberInputElement>();
    private mNormalizationRadius: newNumberInputElement;
    private mNumOfTerms: newNumberInputElement;
    private mType: eSurfaceDeformation;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pOnChange: () => void) {
        super(pContainer, pOnChange);
        this._initElements();
    }
    //__________________________________________________________________________________________
    public setData(pSurfaceDeformation: iSurfaceDeformation<iZernikeDeformation>) {
        this.mType = pSurfaceDeformation.type;
        let aZernikeDeformation = pSurfaceDeformation.params;
        this.mNormalizationRadius.value = aZernikeDeformation.normalization_radius;
        let aLastCoeffIndex = -1;
        if (null != aZernikeDeformation.coeffs) {
            for (let coeffIndex in aZernikeDeformation.coeffs) {
                let aIndex = parseInt(coeffIndex);
                if (aIndex > aLastCoeffIndex) {
                    aLastCoeffIndex = aIndex;
                }
            }
        }

        let aNumberOfElements = (aLastCoeffIndex + 1);
        this.mNumOfTerms.value = aNumberOfElements;
        this._createTerms(aNumberOfElements);

        for (let coeffIndex in aZernikeDeformation.coeffs) {
            let aIndex = parseInt(coeffIndex);
            this.mCoeffsElemets[aIndex].value = aZernikeDeformation.coeffs[coeffIndex];
        }
    }
    //__________________________________________________________________________________________
    public getData(): iSurfaceDeformation<iZernikeDeformation> {
        let aCoeffs: iNumericKeyHash<number> = {};
        for (let i = 0; i < this.mCoeffsElemets.length; i++) {
            let aCoeff = this.mCoeffsElemets[i].value;
            if (aCoeff != 0) {
                aCoeffs[i] = aCoeff;
            }
        }

        let aDeformation: iSurfaceDeformation<iZernikeDeformation> = {
            type: this.mType,
            params: {
                coeffs: aCoeffs,
                normalization_radius: this.mNormalizationRadius.value
            }
        };

        return aDeformation;
    }
    //__________________________________________________________________________________________
    protected _initElements() {
        this._initNormalizationRadius();
        this._initNumOfTerms();
        //this._initDecenters();

        this.mTermsParent = document.createElement('div');
        this.mTermsParent.classList.add('row');

        let aTermsCot = document.createElement('div');
        aTermsCot.classList.add('col-12');
        aTermsCot.appendChild(this.mTermsParent);
        this.mContainer.appendChild(aTermsCot);
    }
    //__________________________________________________________________________________________

    private _initNormalizationRadius() {
        let aDIV = document.createElement('div');
        aDIV.classList.add('col-3');
        this.mContainer.appendChild(aDIV);

        this.mNormalizationRadius = new newNumberInputElement(aDIV, {
            unitName: UnitHandler.shortSign,
            isGlobalToFixed: true,
            label: 'Normalization radius'
        });

        this.mNormalizationRadius.setData({
            callback: () => this._onChange(),
            range: {
                max: Infinity,
                min: 0
            },
            step: 1
        });
    }
    //__________________________________________________________________________________________
    private _initNumOfTerms() {
        let aDIV = document.createElement('div');
        aDIV.classList.add('col-3');
        this.mContainer.appendChild(aDIV);

        this.mNumOfTerms = new newNumberInputElement(aDIV, {
            label: 'Number of terms',
            isGlobalToFixed: false,
        });

        this.mNumOfTerms.setData({
            callback: (pValue) => this._createTerms(pValue),
            range: {
                max: Infinity,
                min: 0
            },
            step: 1
        });
    }
    //__________________________________________________________________________________________
    private _onChange() {
        this.mOnChange();
        EventManager.dispatchEvent(EventsContext.OPTICS_DEFORMATION_CHANGE, this);
    }
    //__________________________________________________________________________________________
    private _createTerms(pNumberOfTerms: number) {
        let aCurrTerms = this.mTermsParent.children.length;
        if (aCurrTerms == pNumberOfTerms) {
            return;
        }
        if (aCurrTerms > pNumberOfTerms) {
            while (this.mCoeffsElemets.length > pNumberOfTerms) {
                let aNIE = this.mCoeffsElemets.pop();
                this.mTermsParent.removeChild(this.mTermsParent.children[this.mCoeffsElemets.length]);
                aNIE.distract();
            }
        } else {
            for (let i = aCurrTerms; i < pNumberOfTerms; i++) {
                let aDiv = document.createElement('div');
                aDiv.classList.add('col-3');
                aDiv.style.paddingTop = '8px';
                this.mTermsParent.appendChild(aDiv);

                let aNIE = new newNumberInputElement(aDiv, {
                    unitName: UnitHandler.shortSign,
                    field_name: i + ': ',
                    isGlobalToFixed: false,
                    toFixed: false
                });

                aNIE.setData({ callback: () => this._onChange(), step: 0.1 });
                this.mCoeffsElemets.push(aNIE);
            }
        }
    }
    //__________________________________________________________________________________________
}
