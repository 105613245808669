
import { eIntensityColorCount, eWavelengthUnit } from "../_context/Enums";

import { Op3dContext } from "../_context/Op3dContext";
import { iUserSimulationSettings } from "../_context/_interfaces/SettingInterfaces";
import { SimulationContext } from "../simulation/SimulationContext";

export class SettingsContext {


    public static getUserSimulationSettings() {
        const aUserSettings = Op3dContext.USER_VO.simulationSettings;
        let aSimulaionSettings: iUserSimulationSettings = {
            amplitudeTreshold: aUserSettings.amplitudeTreshold,
            distanceOfLaserRay: aUserSettings.distanceOfLaserRay,
            intensityColorCount: aUserSettings.intensityColorCount,
            maxDisplayRays: aUserSettings.maxDisplayRays,
            visualizationTreshold: aUserSettings.visualizationTreshold,
            maxEmittedRays: aUserSettings.maxEmittedRays,
            numericAccuracy: aUserSettings.numericAccuracy
        }

        return aSimulaionSettings;
    }

    //__________________________________________________________________________________________
    public static DEFAULT_USER_VO = {
        billing: {
            license: {
                line_items: [],
                simulation_time: 0,
                transaction_time: 0
            },
            total_timers: {
                simulation_time: 0,
                transaction_time: 0
            }
        },
        name: {
            first: '',
            last: ''
        },
        personal: {
            //   displayName: '',
            company: '',
            country: '',
            phone: '',
        },
        parameters: {
            simulation: {
                intensityColorCount: {
                    choice: eIntensityColorCount.CONTINUOUS
                },
                maxEmittedRays: SimulationContext.SIMULATION_CONSTANTS.MAX_EMITTED_RAYS,
                wavelengthUnit: eWavelengthUnit.NM,
                amplitudeTreshold: 0.005,
                maxDisplayRays: SimulationContext.SIMULATION_CONSTANTS.MAX_DISPLAY_RAYS,
                visualizationTreshold: 0.0001,
                numericAccuracy: 4,
                digitsAfterDot: 3,
                distanceOfLaserRay: 625, // mm
                lightLevel: 0.5,
                maxZoomIn: 900,
                maxZoomOut: 45,
                sceneBGColor: 0xc2c2c2,
                steps: 0.01,
            },
        }
    };
    //__________________________________________________________________________________________
    public static MILLISECONDS_TO_SECONDS: number = 1 / 1000;
    //public static MILLISECONDS_TO_MINUTES: number = 1 / 60000;
    public static MILLISECONDS_TO_HOURS: number = 1 / 3600000;
    // public static GPU_LIMIT: Array<{
    //     line_item: eLineItem,
    //     limit: number // in hours
    // }> = [{
    //     line_item: eLineItem.ACADEMIC, limit: 1000
    // }, {
    //     line_item: eLineItem.ADVANCED, limit: 2000
    // }, {
    //     line_item: eLineItem.STANDARD, limit: 1000
    // }]
    //__________________________________________________________________________________________


}
