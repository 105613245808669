import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";

export interface iLoginOptions {
    isExternalLogin: boolean,
    onReady?: Function,
    isOnRegisterBlank: boolean

}
export class Login extends Op3dComponentBase {

    private static SKIN_PATH: string = './skins/home/login.html';

    private mLoginOptions: iLoginOptions;


    constructor(pOptions: iLoginOptions) {
        super({
            container: document.getElementById("login-container"),
            skinPath: Login.SKIN_PATH
        });

        this.mLoginOptions = pOptions;
    }
    //__________________________________________________________________________________________
    public distract() {

        ViewUtils.removeFromParent(this.mContainer);
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {

        // this.mContainer.addEventListener('keydown', (event) => event.keyCode === 13 && this._onLoginBtn())

        let aRegisterAnchor = this._getPart("register-btn") as HTMLAnchorElement;
        aRegisterAnchor.addEventListener("click", () => this.onRegister(aRegisterAnchor));

        // this._getPart("login-forgot-password", true).addEventListener("click",
        //     () => this._onForgotPassword());

        // this._getPart("on-login").addEventListener("click", () => this._onLoginBtn());
        ///this._getPart("on-google").addEventListener("click", () => this._onGoogle());

        if (this.mLoginOptions.onReady != null) {
            this.mLoginOptions.onReady();
        }

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private onRegister(pLink: HTMLAnchorElement) {
        if (this.mLoginOptions.isOnRegisterBlank) {
            pLink.target = "_blank";
        }

        pLink.href = "lp1.html";
        pLink.click();
    }

    /**
     * 
     * @returns this function is not in use anymore
     */
    //private async _onLoginBtn() {


    //}
    //__________________________________________________________________________________________
    // private async _onForgotPassword() {
    //     if (this.mLoginOptions.isExternalLogin) {
    //         ViewUtils.setClassForElement(document.getElementById("forgot-password-container"), "external", true);
    //     }
    //     new ForgotPassword().open();
    // }
    //__________________________________________________________________________________________
}
