import { Vector3, ArrowHelper, Mesh, SphereGeometry, MeshBasicMaterial, Matrix4 } from "three";
import { EventManager } from "../../../oc/events/EventManager";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { eBaseShape } from "../../_context/OpticsContext";
import { CADUtils } from "../../_utils/CADUtills";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { AxisObject3D } from "../../parts/_parts_assets/Axis";
import { OptixPartDisplayer } from "../../parts/_parts_assets/OptixPartDisplayer";
import { SceneContext } from "../../scene/SceneContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { iEdgeSelectionData, PartsEventsHandler } from "../_globals/PartsEventsHandler";
import { Checkbox } from "../components/Checkbox";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { eNotificationToastDuration } from "../home/_notifications/NotificationsContext";
import { ePhase } from "./AssemblyMode";
import { ChoosePartMode } from "./ChoosePartMode";
import { NotificationNote } from "../home/_notifications/NotificationNote";
import { iEventData } from "../../parts/base_3d/Button3D";
import { eChangesType, iAxis } from "../../parts/PartInterfaces";
import { eAxisType } from "../../_context/Enums";

export enum mCurrentState {
    BASE_POINT,
    BASE_DIRECTION,
    COMPLETED
}
export enum eDirection {
    FACE,
    EDGE,
    POINT
}
export enum eAxisDirection {
    AXIS_X,
    AXIS_Y,
    AXIS_Z
}

export interface iDirectionVectors {
    axisX: Vector3,
    axisY: Vector3,
    axisZ: Vector3,

}

export interface iAxisDirection {
    state: eAxisDirection;
    checkbox: HTMLInputElement;
    flip_direction: HTMLElement;
    input: HTMLInputElement;
    completed_field: HTMLElement;
    arrow_helper: ArrowHelper,
    vector: Vector3
}

export class CSMode extends Op3dComponentBase {

    public static INSTANCE: CSMode;
    private mPart: Part;
    private mEventData: any;

    private mCenterPoint: Vector3;

    private mCurrentState: ePhase;
    private mRadius: number;
    private mLength: number;
    private aBasePoint: HTMLInputElement;
    public mDirectionState: eDirection;
    private mAxisDirection: eAxisDirection;

    private mDirFaceBtn: HTMLElement;
    private mDirEdgeBtn: HTMLElement;
    private mDirPointsBtn: HTMLElement;
    private mDirections: Array<iAxisDirection>
    private mBasePointMesh: Mesh;
    private mNotification: NotificationNote;
    private mAxisType: any;
    private mOpticsShape: any;
    private mOpticsCircularFaces: HTMLSelectElement;
    private mOpticsVolumeFaces: HTMLSelectElement;
    private mAdminSaveBtn: HTMLElement;

    private mCheckbox: Checkbox;
    private mCSName: HTMLInputElement;
    isOpen: boolean;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/cs_mode.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {

        if (null == CSMode.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.style.width = "336px";
            aDiv.style.height = "auto";
            aDiv.style.top = "105px";
            aDiv.style.overflow = "hidden";
            aDiv.style.left = "272px";
            aDiv.style.boxShadow = '0px 2.15873px 7.19577px rgba(0, 0, 0, 0.501961)'
            aDiv.style.borderRadius = '4px'
            document.getElementById('forms').appendChild(aDiv);

            CSMode.INSTANCE = new CSMode(aDiv);
        }

        return CSMode.INSTANCE;
    }

    //__________________________________________________________________________________________
    protected _onOpen() {
        this.mCheckbox.checked = false;
        this.mCSName.value = '';

        this.isOpen = true

        this.mCurrentState = ePhase.BASE_POINT
    }
    //__________________________________________________________________________________________
    private async showInfo(pIsToShow: boolean) {
        if (pIsToShow == true) {
            if (this.mNotification != null) {
                this.mNotification.update()
            } else {
                let aMsg = '<strong>Add a local coordinate system (LCS) for this part.</strong><br>';
                aMsg += '<small style="display: block;">'
                aMsg += 'This part already has a default LCS. ';
                aMsg += 'A part might include more than one coordinate system (CS).<br> ';
                aMsg += '1. Pick the point of origin on the part<br> ';
                aMsg += '2. Define two directions by picking axis <br> ';
                aMsg += 'Reset button will reset all your selections. <br> ';
                aMsg += 'Press the ESC key to exit the mode.';
                aMsg += '</small>'

                this.mNotification = await NotificationCenter.instance.pushNotification({
                    message: aMsg,
                    toastDuration: eNotificationToastDuration.INFINITE,
                    params: NotificationCenter.NOTIFICATIONS_TYPES.ADD_LCS,
                    // params: {
                    //     isColorBoxVisible:true,
                    //     type: eNotificationType.CONSTRUCTION_COMMENT,
                    //     title: 'Add LCS',
                    //     color: '#23A7DE'
                    // }
                });
            }

            this._getPart('info_btn').classList.add('active')

        } else {
            if (this.mNotification == null) {
                return
            }
            NotificationCenter.instance.removeNotification(this.mNotification);
            this.mNotification = null

            this._getPart('info_btn').classList.remove('active')
        }



    }
    //__________________________________________________________________________________________

    public setCurrentCSMode(_pMode: eChangesType) {
        this.mCurrentState = ePhase.BASE_POINT
    }
    //__________________________________________________________________________________________
    public currentState() {
        return this.mCurrentState
    }
    //__________________________________________________________________________________________
    public setCSCenterPoint(pPoint: iEdgeSelectionData | Vector3, pPart: Part,
        pEventData: iEventData) {
        if (pEventData.hasOwnProperty('edges')) {
            let aPoint = pPoint as iEdgeSelectionData;
            this.mCenterPoint = aPoint.center;
            this.mRadius = aPoint.radius;
            this.mLength = aPoint.dist;
        } else if (pEventData.hasOwnProperty('vertices')) {
            this.mCenterPoint = pPoint as Vector3;
        } else if (null != pEventData.axis) {
            this.mCenterPoint = pPoint as Vector3;
        } else {
            return;
        }

        this.mPart = pPart;
        this.mEventData = pEventData;

        this.aBasePoint.value = `X ${this.mCenterPoint.x.toFixed(2)}, Y ${this.mCenterPoint.y.toFixed(2)}, Z ${this.mCenterPoint.z.toFixed(2)}`
        let aTreshold = SceneContext.CAMERA.zoom > 3 ? 0.5 : SceneContext.CAMERA.zoom < 1 ? 1.5 : 2 - (SceneContext.CAMERA.zoom / 2)
        this.mBasePointMesh = new Mesh(new SphereGeometry(aTreshold),
            new MeshBasicMaterial({ color: 0xFFA500 }));
        this.mBasePointMesh.position.copy(this.mCenterPoint);

        this._getPart('axis_direction_block').classList.remove('unactive')
        this.mAxisDirection = eAxisDirection.AXIS_X
        this.mCurrentState = ePhase.BASE_DIRECTION
        this.mDirectionState = eDirection.FACE

        SceneContext.MAIN_SCENE.add(this.mBasePointMesh)
    }
    //__________________________________________________________________________________________
    private calculateDirection(pDirToCalculate: eAxisDirection) {
        let aNormal: Vector3

        let aDirectionData = this.mDirections.find(dir => dir.state == pDirToCalculate)

        aDirectionData.arrow_helper.position.set(this.mCenterPoint.x, this.mCenterPoint.y, this.mCenterPoint.z)
        switch (pDirToCalculate) {
            case eAxisDirection.AXIS_X:
                aNormal = new Vector3().crossVectors(this.mDirections[2].vector, this.mDirections[1].vector).multiplyScalar(-1)
                break;
            case eAxisDirection.AXIS_Y:
                aNormal = new Vector3().crossVectors(this.mDirections[0].vector, this.mDirections[2].vector).multiplyScalar(-1)
                break;
            case eAxisDirection.AXIS_Z:
                aNormal = new Vector3().crossVectors(this.mDirections[0].vector, this.mDirections[1].vector)
                break;
        }
        aDirectionData.vector = aNormal

        aDirectionData.arrow_helper.setDirection(aNormal);
        SceneContext.MAIN_SCENE.add(aDirectionData.arrow_helper);

        aDirectionData.input.value = `X ${aNormal.x.toFixed(2)}, Y ${aNormal.y.toFixed(2)}, Z ${aNormal.z.toFixed(2)}`
        aDirectionData.completed_field.style.opacity = '1'

        this._getPart('reset_btn').classList.remove('unactive')
        this._getPart('add_cs_to_part').classList.remove('unactive')

        this.mDirections[0].checkbox.classList.add('unactive')
        this.mDirections[1].checkbox.classList.add('unactive')
        this.mDirections[2].checkbox.classList.add('unactive')

        this.mCurrentState = ePhase.COMPLETED
        aDirectionData.flip_direction.style.opacity = '1'

        this.mDirections[0].checkbox.checked = false
        this.mDirections[1].checkbox.checked = false
        this.mDirections[2].checkbox.checked = false
    }
    //__________________________________________________________________________________________
    private checkCompletedDirections() {
        let aXCompleted = this.mDirections[0].vector != null
        let aYCompleted = this.mDirections[1].vector != null
        let aZCompleted = this.mDirections[2].vector != null

        if (aXCompleted && aYCompleted) {
            this.calculateDirection(eAxisDirection.AXIS_Z)
            return
        }
        if (aXCompleted && aZCompleted) {
            this.calculateDirection(eAxisDirection.AXIS_Y)
            return
        }
        if (aZCompleted && aYCompleted) {
            this.calculateDirection(eAxisDirection.AXIS_X)
            return
        }

    }
    //__________________________________________________________________________________________
    public setCSDirection(pNormal) {
        let aXCompleted = this.mDirections[0].vector != null
        let aYCompleted = this.mDirections[1].vector != null
        let aZCompleted = this.mDirections[2].vector != null
        let aAngleCorrect


        let aDirectionDataX = this.mDirections.find(dir => dir.state == eAxisDirection.AXIS_X)
        let aDirectionDataY = this.mDirections.find(dir => dir.state == eAxisDirection.AXIS_Y)
        let aDirectionDataZ = this.mDirections.find(dir => dir.state == eAxisDirection.AXIS_Z)

        switch (this.mAxisDirection) {
            case eAxisDirection.AXIS_X:
                if (aYCompleted) {
                    aAngleCorrect = this.checkAngle(aDirectionDataY.vector, pNormal)
                }
                if (aZCompleted) {
                    aAngleCorrect = this.checkAngle(aDirectionDataZ.vector, pNormal)
                }

                if (aAngleCorrect == false) return
                aDirectionDataX.input.value = `X ${pNormal.x.toFixed(2)}, Y ${pNormal.y.toFixed(2)}, Z ${pNormal.z.toFixed(2)}`
                aDirectionDataX.completed_field.style.opacity = '1'


                aDirectionDataX.arrow_helper.position.set(this.mCenterPoint.x, this.mCenterPoint.y, this.mCenterPoint.z)
                aDirectionDataX.arrow_helper.setDirection(pNormal);

                SceneContext.MAIN_SCENE.add(aDirectionDataX.arrow_helper)
                aDirectionDataX.vector = pNormal
                aDirectionDataX.flip_direction.style.opacity = '1'

                break;
            case eAxisDirection.AXIS_Y:
                if (aXCompleted) {
                    aAngleCorrect = this.checkAngle(aDirectionDataX.vector, pNormal)
                }
                if (aZCompleted) {
                    aAngleCorrect = this.checkAngle(aDirectionDataZ.vector, pNormal)
                }
                if (aAngleCorrect == false) return
                aDirectionDataY.input.value = `X ${pNormal.x.toFixed(2)}, Y ${pNormal.y.toFixed(2)}, Z ${pNormal.z.toFixed(2)}`
                aDirectionDataY.completed_field.style.opacity = '1'

                aDirectionDataY.arrow_helper.position.set(this.mCenterPoint.x, this.mCenterPoint.y, this.mCenterPoint.z)
                aDirectionDataY.arrow_helper.setDirection(pNormal);

                SceneContext.MAIN_SCENE.add(aDirectionDataY.arrow_helper)
                aDirectionDataY.vector = pNormal
                aDirectionDataY.flip_direction.style.opacity = '1'
                break;
            case eAxisDirection.AXIS_Z:
                if (aXCompleted) {
                    aAngleCorrect = this.checkAngle(aDirectionDataX.vector, pNormal)
                }
                if (aYCompleted) {
                    aAngleCorrect = this.checkAngle(aDirectionDataY.vector, pNormal)

                }
                if (aAngleCorrect == false) return
                aDirectionDataZ.input.value = `X ${pNormal.x.toFixed(2)}, Y ${pNormal.y.toFixed(2)}, Z ${pNormal.z.toFixed(2)}`
                aDirectionDataZ.completed_field.style.opacity = '1'

                aDirectionDataZ.arrow_helper.position.set(this.mCenterPoint.x, this.mCenterPoint.y, this.mCenterPoint.z)
                aDirectionDataZ.arrow_helper.setDirection(pNormal);

                SceneContext.MAIN_SCENE.add(aDirectionDataZ.arrow_helper)
                aDirectionDataZ.vector = pNormal
                aDirectionDataZ.flip_direction.style.opacity = '1'
                break;
        }

        this.checkCompletedDirections()


    }
    //__________________________________________________________________________________________
    private checkAngle(pVec1, pVec2) {
        let aAngle = new Vector3(pVec1.x.toFixed(2), pVec1.y.toFixed(2), pVec1.z.toFixed(2)).angleTo(new Vector3(pVec2.x.toFixed(2), pVec2.y.toFixed(2), pVec2.z.toFixed(2)))
        let aAngleDeg = aAngle * (180 / Math.PI)
        if (aAngleDeg !== 90) {
            return false
        }

        return true
    }
    //__________________________________________________________________________________________
    private createDataMongoChanges(pPart: AxisObject3D) {
        let aPartChange
        if (this.mAxisType.value == eChangesType[eChangesType.OPTICS_AXIS]) {
            let aOpticsFace = this.mOpticsShape.value == eBaseShape[eBaseShape.CIRCULAR] ? this.mOpticsCircularFaces : this.mOpticsVolumeFaces
            aPartChange = {
                type: eChangesType.OPTICS_AXIS,
                data: {
                    position: pPart.position.clone(),
                    rotation: pPart.rotation.clone(),
                    radius: this.mRadius,
                    length: this.mLength,
                    face: aOpticsFace.value,
                    shape: eBaseShape[this.mOpticsShape.value]
                }
            }
        } else if (this.mAxisType.value == eChangesType[eChangesType.BASIC_TRANSLATION]) {
            aPartChange = {
                data: {
                    deltaPos: pPart.position.clone(),
                    rot: pPart.rotation.clone()
                },
                type: eChangesType.BASIC_TRANSLATION
            };
        } else if (this.mAxisType.value == eChangesType[eChangesType.LASER_AXIS]) {
            aPartChange =
            {
                type: eChangesType.LASER_AXIS,
                data: {
                    position: pPart.position.clone(),
                    rotation: pPart.rotation.clone()
                }
            }

        } else if (this.mAxisType.value == eChangesType[eChangesType.CAGE_AXIS]) {
            aPartChange =
            {
                type: eChangesType.CAGE_AXIS,
                data: {
                    position: pPart.position.clone(),
                    rotation: pPart.rotation.clone()
                }
            }

        }

        PartsEventsHandler.addChangesToPart(this.mPart, [aPartChange], this.mEventData.edges.part)

        ChoosePartMode.instance.leaveChoosePartMode();


    }
    //__________________________________________________________________________________________
    private async addCSToPart() {
        let aXCompleted = this.mDirections[0].vector != null
        let aYCompleted = this.mDirections[1].vector != null
        let aZCompleted = this.mDirections[2].vector != null

        let aPartAxis = SceneContext.OP3D_SCENE.getAxisModel();
        if (aXCompleted && aYCompleted && aZCompleted) {
            Op3dContext.SCENE_HISTORY.addToHistory()

            let aAxis: iAxis = {
                object3D: aPartAxis,
                internal_id: Op3dUtils.idGenerator(),
                isCustom: true,
                type: eAxisType.GENERAL
            };

            if ('' != this.mCSName.value) {
                aAxis.name = this.mCSName.value;
            }

            this.mPart.iPart.axes.push(aAxis);
            aPartAxis.show()

            let aMatrix = new Matrix4().fromArray(CADUtils.mr_CFrameToCFrameRotationHomogeneous(
                [new Vector3(1, 0, 0), new Vector3(0, 1, 0), new Vector3(0, 0, 1)],
                [this.mDirections[0].vector, this.mDirections[1].vector, this.mDirections[2].vector]));

            aPartAxis.applyMatrix4(aMatrix)
            aPartAxis.position.copy(this.mCenterPoint)

            let aRotMatrix = this.mPart.iPart.object3D.matrixWorld.clone().invert()

            aPartAxis.applyMatrix4(aRotMatrix)

            this.mPart.iPart.object3D.add(aPartAxis)

            if (true == this.mCheckbox.checked) {
                this.mPart.setWorkingCS(aAxis);
            }

            if (Op3dContext.USER_VO.isAdmin == true) {
                this.createDataMongoChanges(aPartAxis)

                if (this.mAxisType == eChangesType[eChangesType.OPTICS_AXIS]) {
                    let aPreviousAxis = this.mEventData.edges.part.axes.find(axis =>
                        (eAxisType.OPTICS === axis.type))

                    if (aPreviousAxis !== undefined) {
                        aPreviousAxis.object3D = aPartAxis;
                        aPreviousAxis.internal_id = Op3dUtils.idGenerator();
                        aPreviousAxis.radius = this.mRadius
                        aPreviousAxis.length = this.mLength
                    } else {
                        this.mEventData.edges.part.axes.push({
                            object3D: aPartAxis,
                            internal_id: Op3dUtils.idGenerator(),
                            isForOptics: true,
                            isForLaser: false,
                            isForCage: false,
                            radius: this.mRadius,
                            length: this.mLength,
                        })
                    }
                }

                if (this.mAxisType == eChangesType[eChangesType.LASER_AXIS]) {
                    let aPreviousAxis = this.mEventData.edges.part.axes.find((axis: iAxis) =>
                        (eAxisType.LASER === axis.type))

                    if (aPreviousAxis !== undefined) {
                        aPreviousAxis.object3D = aPartAxis;
                        aPreviousAxis.internal_id = Op3dUtils.idGenerator();
                    } else {
                        this.mPart.iPart.axes.push({
                            object3D: aPartAxis,
                            internal_id: Op3dUtils.idGenerator(),
                            type: eAxisType.LASER
                        })
                    }

                }

                if (this.mAxisType == eChangesType[eChangesType.CAGE_AXIS]) {
                    let aPreviousAxis = this.mEventData.edges.part.axes.find((axis: iAxis) =>
                        (eAxisType.CAGE === axis.type))

                    if (aPreviousAxis !== undefined) {
                        aPreviousAxis.object3D = aPartAxis;
                        aPreviousAxis.internal_id = Op3dUtils.idGenerator();
                    } else {
                        this.mPart.iPart.axes.push({
                            object3D: aPartAxis,
                            internal_id: Op3dUtils.idGenerator(),
                            type: eAxisType.CAGE
                        })
                    }
                }

            }

            Op3dContext.SCENE_HISTORY.saveScene()
            OptixPartDisplayer.unHighlightObject(this.mPart);

            EventManager.dispatchEvent(EventsContext.RESTORE_TO_BASE_MODE, this)
            EventManager.removeEventListener(EventsContext.RESTORE_TO_BASE_MODE, this)
        }
    }
    //__________________________________________________________________________________________
    private flipDirection(pDirection: eAxisDirection, pIsRecursion?: boolean) {
        let aCurrentVector
        let aDirectionData = this.mDirections.find(dir => dir.state == pDirection)
        aCurrentVector = aDirectionData.vector

        let aXCompleted = this.mDirections[0].vector != null
        let aYCompleted = this.mDirections[1].vector != null
        let aZCompleted = this.mDirections[2].vector != null

        if (aXCompleted && aYCompleted && aZCompleted && !pIsRecursion) {
            switch (pDirection) {
                case eAxisDirection.AXIS_X:
                    this.flipDirection(eAxisDirection.AXIS_Z, true)
                    break;
                case eAxisDirection.AXIS_Z:
                    this.flipDirection(eAxisDirection.AXIS_X, true)
                    break;
                case eAxisDirection.AXIS_Y:
                    this.flipDirection(eAxisDirection.AXIS_Z, true)
                    break
            }
        }
        aDirectionData.vector = aDirectionData.vector.multiplyScalar(-1)

        aDirectionData.arrow_helper.position.set(this.mCenterPoint.x, this.mCenterPoint.y, this.mCenterPoint.z)
        aDirectionData.arrow_helper.setDirection(aDirectionData.vector);

        aDirectionData.input.value = `X ${aCurrentVector.x.toFixed(2)}, Y ${aCurrentVector.y.toFixed(2)}, Z ${aCurrentVector.z.toFixed(2)}`

    }
    //__________________________________________________________________________________________
    private choosmCurrentState(pCurrElement) {
        this.mDirEdgeBtn.classList.remove('active')
        this.mDirFaceBtn.classList.remove('active')
        this.mDirPointsBtn.classList.remove('active')

        pCurrElement.classList.add('active')
    }
    //__________________________________________________________________________________________
    private initDirectionButton(pDirButton, pState) {
        pDirButton.addEventListener('click', () => {
            this.mDirectionState = pState
            this.choosmCurrentState(pDirButton)
        })
    }
    //__________________________________________________________________________________________
    private addCustomEventListeners() {
        for (let direction of this.mDirections) {
            direction.checkbox.addEventListener('click', () => this.mAxisDirection = direction.state)
            direction.flip_direction.addEventListener('click', () => this.flipDirection(direction.state))
        }
    }
    //__________________________________________________________________________________________
    private onChooseAxisType(pAxisType: string) {
        switch (pAxisType) {
            case eChangesType[eChangesType.OPTICS_AXIS]:
                this.mContainer.getElementsByClassName('optics_block')[0].classList.remove('d-none')
                this.mOpticsShape.classList.remove('d-none')
                break;
            case eChangesType[eChangesType.LASER_AXIS]:
            case eChangesType[eChangesType.CAGE_AXIS]:
            case eChangesType[eChangesType.BASIC_TRANSLATION]:
                this.mContainer.getElementsByClassName('optics_block')[0].classList.add('d-none')
                break;
        }
    }
    //__________________________________________________________________________________________
    private onChooseShapeType(pShapeType: string) {

        switch (pShapeType) {
            case eBaseShape[eBaseShape.CIRCULAR]:
                this.mOpticsCircularFaces.classList.remove('d-none')
                this.mOpticsVolumeFaces.classList.add('d-none')
                break;
            case eBaseShape[eBaseShape.RECTANGULAR]:
            case eBaseShape[eBaseShape.VOLUME]:
                this.mOpticsCircularFaces.classList.add('d-none')
                this.mOpticsVolumeFaces.classList.remove('d-none')
                break;
        }
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {

        this.mAdminSaveBtn = this._getPart('admin_add_cs_to_part')
        if (Op3dContext.USER_VO.isAdmin == true) {
            this._getPart('cs_admin').classList.remove('d-none')
            this._getPart('add_cs_to_part').classList.add('d-none')
            this.mAdminSaveBtn.classList.remove('d-none')
            this.mAdminSaveBtn.addEventListener('click', () => {
                this.addCSToPart()
            })

            this.mAxisType = this._getPart('cs_admin_type') as HTMLSelectElement
            this.mAxisType.addEventListener('change', () => this.onChooseAxisType(this.mAxisType.value))

            this.mOpticsShape = this._getPart('cs_admin_shape') as HTMLSelectElement
            this.mOpticsShape.addEventListener('change', () => this.onChooseShapeType(this.mOpticsShape.value))

            this.mOpticsCircularFaces = this._getPart('shape_circular') as HTMLSelectElement
            this.mOpticsVolumeFaces = this._getPart('shape_rect') as HTMLSelectElement
        } else {
            ViewUtils.removeFromParent(this.mAdminSaveBtn.parentElement);
        }

        this.mDirFaceBtn = this._getPart('direction_face');
        this.initDirectionButton(this.mDirFaceBtn, eDirection.FACE)

        this.mDirEdgeBtn = this._getPart('direction_edge')
        this.initDirectionButton(this.mDirEdgeBtn, eDirection.EDGE)

        this.mDirPointsBtn = this._getPart('direction_points')
        this.initDirectionButton(this.mDirPointsBtn, eDirection.POINT)

        this.aBasePoint = this._getPart('base_point') as HTMLInputElement

        this.mCurrentState = ePhase.BASE_POINT


        this.mDirections = [{
            state: eAxisDirection.AXIS_X,
            checkbox: this._getPart('axis_x') as HTMLInputElement,
            flip_direction: this._getPart('flip_axis_x'),
            input: this._getPart('input_axis_x') as HTMLInputElement,
            completed_field: this._getPart('input_axis_x_completed'),
            arrow_helper: new ArrowHelper(new Vector3(), new Vector3(), 20, 0xFF0000),
            vector: undefined
        }, {
            state: eAxisDirection.AXIS_Y,
            checkbox: this._getPart('axis_y') as HTMLInputElement,
            flip_direction: this._getPart('flip_axis_y'),
            input: this._getPart('input_axis_y') as HTMLInputElement,
            completed_field: this._getPart('input_axis_y_completed'),
            arrow_helper: new ArrowHelper(new Vector3(), new Vector3(), 20, 0x00FF00),
            vector: undefined
        }, {
            state: eAxisDirection.AXIS_Z,
            checkbox: this._getPart('axis_z') as HTMLInputElement,
            flip_direction: this._getPart('flip_axis_z'),
            input: this._getPart('input_axis_z') as HTMLInputElement,
            completed_field: this._getPart('input_axis_z_completed'),
            arrow_helper: new ArrowHelper(new Vector3(), new Vector3(), 20, 0x0000FF),
            vector: undefined
        }]

        this._getPart('info_btn').addEventListener('click', () => {
            this.showInfo(this.mNotification == null)
        })


        this.addCustomEventListeners()

        this._getPart('add_cs_to_part').addEventListener('click', () => this.addCSToPart());
        this._getPart('close_btn').addEventListener('click', () => {
            EventManager.dispatchEvent(EventsContext.RESTORE_TO_BASE_MODE, this);
            EventManager.removeEventListener(EventsContext.RESTORE_TO_BASE_MODE, this);
            this.closeTool()
        });

        this._getPart('reset_btn').addEventListener('click', () => this.reset())
        this.mCheckbox = new Checkbox(this._getPart('checkbox'), {
            label: 'Set as LCS'
        });
        this.mCSName = this._getPart('cs_name') as HTMLInputElement;
    }
    //__________________________________________________________________________________________
    private reset() {
        this.aBasePoint.value = ''
        this._getPart('axis_direction_block').classList.add('unactive')
        this.mDirectionState = eDirection.FACE
        this.choosmCurrentState(this.mDirFaceBtn)

        for (let direction of this.mDirections) {
            SceneContext.MAIN_SCENE.remove(direction.arrow_helper)
            direction.vector = null
            direction.input.value = ''
            direction.completed_field.style.opacity = '0'
            direction.checkbox.checked = direction.state == eAxisDirection.AXIS_X ? true : false
            direction.checkbox.classList.remove('unactive')
            direction.flip_direction.style.opacity = '0'
        }
        SceneContext.MAIN_SCENE.remove(this.mBasePointMesh)
        this.showInfo(false)


        this._getPart('add_cs_to_part').classList.add('unactive')
        this._getPart('reset_btn').classList.add('unactive')

        this.mPart = null
        this.mEventData = null

        this.mCurrentState = ePhase.BASE_POINT
    }
    //__________________________________________________________________________________________

    public closeTool() {

        this.aBasePoint.value = ''
        this._getPart('axis_direction_block').classList.add('unactive')
        this.mDirectionState = eDirection.FACE
        this.choosmCurrentState(this.mDirFaceBtn)

        for (let direction of this.mDirections) {
            SceneContext.MAIN_SCENE.remove(direction.arrow_helper)
            direction.vector = null
            direction.input.value = ''
            direction.completed_field.style.opacity = '0'
            direction.checkbox.checked = direction.state == eAxisDirection.AXIS_X ? true : false
            direction.checkbox.classList.remove('unactive')
            direction.flip_direction.style.opacity = '0'
        }

        this.showInfo(false)

        SceneContext.MAIN_SCENE.remove(this.mBasePointMesh)

        this._getPart('add_cs_to_part').classList.add('unactive')
        this._getPart('reset_btn').classList.add('unactive')

        this.mPart = null
        this.mEventData = null
        this.isOpen = false

        this.close()
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
        // $(this.mContainer).draggable()
    }
    //__________________________________________________________________________________________
}
