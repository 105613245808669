import { eSetupType, eUnitType, eDataPermission } from "../../_context/Enums";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash, iSetupMenuSearchQuery, iSetupMetadata } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { SetupsDataLoader } from "../../data/data_loader/SetupsDataLoader";
import { GridManager } from "../../scene/GridManager";
import { SceneHistory } from "../../scene/SceneHistory";
import { ENVS, ServerContext } from "../../server/ServerContext";
import { UnitHandler } from "../../units/UnitsHandler";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { Popup } from "../forms/Popup";
import { Spinner } from "./Spinner";

export class OpenSetupForm extends Op3dComponentBase {

    private static INSTANCE: OpenSetupForm;
    private static LIMIT = 10;
    private static RIGHT_GRID_SECTION = "right-grid-section";
    private static GRID_CLASS = "grid-view-2";

    private mSingleItem: HTMLElement;
    private mResultsContainer: HTMLElement;
    private mCurrPage: number = 0;
    private mFreeStringInput: HTMLInputElement;
    private mSetupType = eSetupType.USER;
    private mCurrSort: iHash<number> = { name: 1 };
    private mDeleteBtn: HTMLElement;
    private mListViewBtn: HTMLElement;
    private mGridViewBtn: HTMLElement;
    private mRightContentSection: HTMLElement;
    private mSortItems: iHash<HTMLElement> = {};
    private mCheckAll: HTMLInputElement;
    private mFilterLabels: Array<string> = []
    private mItemsToDelete = Array<string>();
    private mItemsSelectedCount: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/home/open_setup_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (OpenSetupForm.INSTANCE == null) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.classList.add('ui-draggable');
            //aDiv.setAttribute('data-backdrop', 'false');
            document.getElementById('forms').appendChild(aDiv);

            OpenSetupForm.INSTANCE = new OpenSetupForm(aDiv);
        }
        return OpenSetupForm.INSTANCE
    }
    //__________________________________________________________________________________________
    private _onDeleteGeneral() {
        if (this.mItemsToDelete.length > 0) {
            Popup.instance.open({
                text: MessagesHandler.REMOVE_SETUPS_MSG_CONFIRMATION,
                noBtn: { title: "Cancel", callback: () => { } },
                yesBtn: { title: "Yes", callback: () => this._removeItems() }
            });
        }
    }
    //__________________________________________________________________________________________
    private async _removeItems() {
        Spinner.instance.show();

        let aDeleted = 0;
        let aErrors = 0;
        for (let i = 0; i < this.mItemsToDelete.length; i++) {

            let aRes = await SetupsDataLoader.instance.remove({ _id: this.mItemsToDelete[i] } as any);
            if (aRes.success) {
                aDeleted++
            } else {
                aErrors++;
            }
        }

        if (aErrors > 0) {
            Popup.instance.open({
                text: "Action finished with " + aErrors + " Errors"
            });
        }

        this.mCurrPage = 0;
        this.mCheckAll.checked = false;
        this.mItemsToDelete = new Array<string>();
        this._updateDeleteBtn();
        Spinner.instance.hide();
        this._getResults(true, this._getGeneralQuery());
    }
    //__________________________________________________________________________________________
    private _onScroll(event: Event) {
        let aElement = event.target as any;

        let aDisToButtom = (aElement.scrollHeight - aElement.scrollTop - aElement.clientHeight);
        if (Math.abs(aDisToButtom) < 1) {
            if (((1 + this.mCurrPage) * OpenSetupForm.LIMIT) <
                SetupsDataLoader.instance.dataTotal) {
                SetupsDataLoader.instance.needsUpdate = true;
                this.mCurrPage++;
                this._getResults(false, this._getGeneralQuery());
            }
        }
    }
    //__________________________________________________________________________________________
    private _onCheckAll(e: Event) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

        const aIschecked = this.mCheckAll.checked;
        $(this.mContainer).find(".setup-checkbox").each((_index, element) => {
            if (!(element as HTMLInputElement).disabled) {
                (element as HTMLInputElement).checked = aIschecked;
                element.dispatchEvent(new Event('change'));
            }
        });
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {
        this.mRightContentSection = this._getPart("right-content-section");
        this.mItemsSelectedCount = this._getPart("items-selected-count");
        this.mSingleItem = this._getPart("single-item");
        this.mResultsContainer = this.mSingleItem.parentElement;
        this.mSingleItem.parentElement.removeChild(this.mSingleItem);
        this.mFreeStringInput = this._getPart("free-string") as HTMLInputElement;
        this.mFreeStringInput.addEventListener('input',
            this._debounce(() => this._getResults(true, this._getGeneralQuery()), 500))
        this.mResultsContainer.addEventListener('scroll', (event: Event) => this._onScroll(event));

        this.mCheckAll = this._getPart("check-all") as HTMLInputElement;
        this.mCheckAll.addEventListener("change", (e: Event) => this._onCheckAll(e));

        this.mDeleteBtn = this._getPart("delete-btn");
        this.mDeleteBtn.addEventListener("click", (_e) => this._onDeleteGeneral());

        let aCheckboxList = this.mContainer.getElementsByClassName('dropdown-item');
        for (let i = 0; i < aCheckboxList.length; i++) {
            aCheckboxList[i].addEventListener('click', (e) => this._filterLabels(e, aCheckboxList[i]))
        }

        this.mListViewBtn = this._getPart("list-view", true);
        this.mListViewBtn.addEventListener("click", () => this._changeView(true));
        this.mGridViewBtn = this._getPart("grid-view", true);
        this.mGridViewBtn.addEventListener("click", () => this._changeView(false));

        this._initSort();
        await this._getResults(true, this._getGeneralQuery());
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _initSort() {

        this.mSortItems = {};
        $(this.mContainer).find("[sortable]").each((_index, element) => {
            let aAttr = element.getAttribute("sortable");
            this.mSortItems[aAttr] = element as HTMLElement;
            element.parentElement.addEventListener("click", () => this._sortBy(aAttr));
        })
    }
    //__________________________________________________________________________________________
    private _sortBy(pPath: string) {
        let aClasses = this.mSortItems[pPath].className;
        let aSortType: number;
        if (aClasses.indexOf("up") != -1) {
            aSortType = -1;
            this.mSortItems[pPath].classList.value = "sort-item down";
        } else {
            this.mSortItems[pPath].classList.value = "sort-item up";
            aSortType = 1;
        }

        for (let item in this.mSortItems) {
            ViewUtils.setElementActive(this.mSortItems[item], item == pPath);
        }

        this.mCurrSort = {};
        this.mCurrSort[pPath] = aSortType;

        this._getResults(true, this._getGeneralQuery());
    }
    //__________________________________________________________________________________________
    private _changeView(pIsList: boolean) {
        ViewUtils.setElementActive(this.mListViewBtn, pIsList);
        ViewUtils.setElementActive(this.mGridViewBtn, !pIsList);

        if (pIsList) {
            this.mRightContentSection.classList.remove(OpenSetupForm.RIGHT_GRID_SECTION);
            this.mResultsContainer.classList.remove(OpenSetupForm.GRID_CLASS);
        } else {
            this.mRightContentSection.classList.add(OpenSetupForm.RIGHT_GRID_SECTION);
            this.mResultsContainer.classList.add(OpenSetupForm.GRID_CLASS);
        }
    }
    //__________________________________________________________________________________________
    private _filterLabels(_e: Event, pCheckboxElem: Element) {


        let aCurrLabel = pCheckboxElem.children[0] as any
        if (aCurrLabel.checked) {
            this.mFilterLabels.push(aCurrLabel.attributes.label.value)
        } else {
            this.mFilterLabels = this.mFilterLabels.filter(el => el !== aCurrLabel.attributes.label.value)
        }


        if (this.mFilterLabels.length !== 0) {
            let x = document.getElementsByClassName('all-select-label')[0]
            x.setAttribute('count', this.mFilterLabels.length + '')
        } else {
            let x = document.getElementsByClassName('all-select-label')[0]
            x.removeAttribute('count')
        }

        this._getResults(true, this._getGeneralQuery())
    }
    //__________________________________________________________________________________________
    private _debounce(func, wait) {
        let aTimeout: any;

        // This is the function that is returned and will be executed many times
        // We spread (...args) to capture any number of parameters we want to pass
        return function executedFunction(...args) {

            // The callback function to be executed after 
            // the debounce time has elapsed
            const later = () => {
                // null timeout to indicate the debounce ended
                aTimeout = null;

                // Execute the callback
                func(...args);
            };
            // This will reset the waiting every function execution.
            // This is the step that prevents the function from
            // being executed because it will never reach the 
            // inside of the previous setTimeout  
            clearTimeout(aTimeout);

            // Restart the debounce waiting period.
            // setTimeout returns a truthy value (it differs in web vs Node)
            aTimeout = setTimeout(later, wait);
        };
    };
    //__________________________________________________________________________________________
    protected _onOpen() {
        this._openMySetups();
    }
    //__________________________________________________________________________________________
    private _openMySetups() {
        SetupsDataLoader.instance.needsUpdate = true;
        this.mFreeStringInput.value = "";
        this.mSetupType = eSetupType.USER;
        this.mCurrPage = 0;
        this._setDefaultSort();
        this._getResults(true, this._getGeneralQuery());
    }
    //__________________________________________________________________________________________
    private _setDefaultSort() {
        this.mCurrSort = { modified: 1 };
        for (let item in this.mSortItems) {
            ViewUtils.setElementActive(this.mSortItems[item], item == "modified");
        }
    }
    //__________________________________________________________________________________________
    private _clearResults() {
        this.mCurrPage = 0;
        SetupsDataLoader.instance.needsUpdate = true;
        ViewUtils.removeElementChildren(this.mResultsContainer);
    }
    //__________________________________________________________________________________________
    private async _getResults(pClear: boolean, pQuery: iSetupMenuSearchQuery) {
        if (pClear) {
            this._clearResults();
        }

        Spinner.instance.show();
        //let aResults = await SetupsDataLoader.instance.getManyItems(pQuery);
        let aResults = await SetupsDataLoader.instance.getManyItems(pQuery);
        this._fillList(aResults);

        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip().on('click', function () {
            $(this).blur()
        });

        //$(this.mContainer).find('[data-toggle="tooltip"]').tooltip();
        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _fillList(pResults: iSetupMetadata[]) {

        if (pResults.length === 0) {
            SceneHistory.TO_AUTO_SAVE = false
            this.mCheckAll.checked = false
        }

        for (let i = 0; i < pResults.length; i++) {

            const aData = pResults[i];
            if (!aData.filtered && aData.filtered !== undefined) {
                continue
            }

            let aClone = this.mSingleItem.cloneNode(true) as HTMLElement;

            let aImg = Op3dUtils.getElementIn(aClone, "setup-image", true) as HTMLImageElement;
            aImg.alt = aData.name;
            // let aImageSrc = pResults[i].parameters.details.image != null ? pResults[i].parameters.details.image : "./images/no_image.png";
            //  aImg.src = aImageSrc;

            aImg.src = `${ServerContext.user_prefix_setups}${Op3dContext.USER_VO.id}/${aData._id}/image.png?V=${Op3dUtils.idGenerator()}`

            aImg.onload = () => {
                ViewUtils.removeFromParent(Op3dUtils.getElementIn(aClone, "setup-image-ph", true) as HTMLImageElement)
                aImg.classList.remove('d-none')
            }

            let aName = Op3dUtils.getElementIn(aClone, "setup-name");
            aName.innerText = aData.name;

            let aLabel = Op3dUtils.getElementIn(aClone, "setup-label");

            if (aData.parameters.details.labels != null &&
                aData.parameters.details.labels.length > 0 &&
                aData.parameters.details?.labels.length < 3) {

                aLabel.innerHTML = aData.parameters.details.labels.map(el => `<span class=${el.toLowerCase().replace(" ", "_")}>${el}</span>`).join(' ')

            } else if (aData.parameters.details.labels != null && aData.parameters.details.labels.length > 2) {
                let aLabels = []
                for (let i = 0; i < 2; i++) {
                    let aLabel = aData.parameters.details.labels[i]
                    aLabels.push(`<span class='${aLabel.toLowerCase().replace(" ", "_")}'>${aLabel}</span>`)

                }
                aLabel.innerHTML = aLabels.join(' ') + '...'
            }

            let aOpened = Op3dUtils.getElementIn(aClone, "setup-closed");
            let aClosed = Op3dUtils.getElementIn(aClone, "setup-opened");

            if (ServerContext.env == ENVS.DEV) {
                aClosed.addEventListener('click', () => {
                    ServerContext.SERVER.closeSetup({ _id: aData._id })
                    this._getResults(true, this._getGeneralQuery());
                })
            }


            ViewUtils.setElementVisibilityByDNone(aOpened, true);

            let aDateGrid = Op3dUtils.getElementIn(aClone, "setup-date-grid");
            let aDateList = Op3dUtils.getElementIn(aClone, "setup-date-list");

            let aDate = new Date(aData.updatedAt);
            let aDateStr = "";
            aDateStr += aDate.getDate() + " ";
            let aMonth = aDate.toLocaleString('default', { month: 'short' });
            aDateStr += aMonth + " ";
            aDateStr += aDate.getFullYear();

            let aMinutes = aDate.getMinutes();
            let aHours = aDate.getHours();
            let aTimeStr = (aHours < 10 ? '0' + aHours : aHours) + ":" +
                (aMinutes < 10 ? '0' + aMinutes : aMinutes);

            aDateGrid.innerHTML = aDateStr + " | " + aTimeStr;
            aDateList.innerHTML = aDateStr + "<br>" + aTimeStr;

            //let aDeleteBtn = Op3dUtils.getElementIn(aClone, "delete-single-item");
            //aDeleteBtn.addEventListener("click", (e: Event) => this._onDeleteOne(e, aData));

            let aCheckbox = Op3dUtils.getElementIn(aClone, "setup-checkbox", true) as HTMLInputElement;
            aCheckbox.addEventListener("change", (e: Event) => this._onCheckItem(e, aData._id, aCheckbox));



            let aUnitElement = Op3dUtils.getElementIn(aClone, "setup-unit");
            let aSetupUnit = aData.parameters.unit;
            aUnitElement.innerText = (aSetupUnit == eUnitType.INCHES) ? "Imperial" : "Metric";

            let aIsDisabled = (aSetupUnit != UnitHandler.PRESENTED_UNIT);
            ViewUtils.setElementDisabled(aClone, aIsDisabled);
            aCheckbox.disabled = aIsDisabled;

            aClone.addEventListener("click", (e: Event) => this._onSelectItem(e, aData));

            this.mResultsContainer.appendChild(aClone);
        }
    }
    //__________________________________________________________________________________________
    private async _onSelectItem(pEvent: Event, pData: iSetupMetadata) {
        if (pEvent.target instanceof HTMLInputElement) {
            return;
        }
        Spinner.instance.show();
        let aRes = await Op3dContext.SETUPS_MANAGER.getOneSetup({ to_open: false, _id: pData._id });
        let aResContentUrl = `${ServerContext.user_prefix_setups}${Op3dContext.USER_VO.id}/${pData._id}/setup.opt?V=${Op3dUtils.idGenerator()}`
        aRes.data.content = await fetch(aResContentUrl).then(data => data.text());

        if (aRes.success) {
            let aUnitLength = aRes.data.parameters.unit == eUnitType.MILLIMETERS ? 25 : 1;
            GridManager.CELL_SIZE = aUnitLength;

            //this.distract();
            this.close()

            await Op3dContext.SETUPS_MANAGER.onOpenSetup({
                addToExisting: true,
                content: aRes.data
            });

        } else {
            Popup.instance.open({ text: MessagesHandler.ERROR_WHILE_LOADING_SETUP });
        }
        //document.getElementById('file-name').innerHTML = Op3dContext.SETUPS_MANAGER.fileName
        // Comment to find broken spinner/
        // Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _getGeneralQuery(): iSetupMenuSearchQuery {

        let aServerMetaDataQuery: iSetupMenuSearchQuery;
        let aStr = Op3dUtils.getNormalizedName(this.mFreeStringInput.value);

        switch (this.mSetupType) {
            case eSetupType.EXAMPLE:

                aServerMetaDataQuery = {
                    limit: OpenSetupForm.LIMIT,
                    filters: {
                        "parameters.setupType": eSetupType.EXAMPLE,
                        "permission": { $in: [eDataPermission.PUBLIC] },
                    },
                    sort: this.mCurrSort,
                    skip: (this.mCurrPage * OpenSetupForm.LIMIT),
                    ///    free_string: Op3dUtils.getNormalizedName(this.mFreeStringInput.value),
                };

                break;
            case eSetupType.USER:
                aServerMetaDataQuery = {
                    limit: OpenSetupForm.LIMIT,
                    filters: {
                        "parameters.setupType": eSetupType.USER,
                        "permission": { $in: [eDataPermission.PUBLIC, eDataPermission.PRIVATE] }
                    },
                    sort: this.mCurrSort,
                    skip: (this.mCurrPage * OpenSetupForm.LIMIT),
                };

                if (this.mFilterLabels.length > 0) {
                    aServerMetaDataQuery.filters["parameters.details.labels"] = { $in: this.mFilterLabels };
                }

                break;
        }

        if (aStr != "") {
            aServerMetaDataQuery.filters.name = { $regex: aStr, $options: "i" };
        }

        return aServerMetaDataQuery;
    }
    //__________________________________________________________________________________________
    private _onCheckItem(pEvent: Event, pId: string, pCheckbox: HTMLInputElement) {
        pEvent.preventDefault();
        pEvent.stopImmediatePropagation();
        pEvent.stopPropagation();

        if (pCheckbox.checked) {
            this.mItemsToDelete.push(pId);

        } else {
            let aIndex = this.mItemsToDelete.indexOf(pId);
            if (aIndex != -1) {
                this.mItemsToDelete.splice(aIndex, 1);
            }
            this.mCheckAll.checked = false
        }

        this._updateDeleteBtn();
    }
    //__________________________________________________________________________________________
    private _updateDeleteBtn() {
        ViewUtils.setElementVisibilityByDNone(this.mDeleteBtn, this.mItemsToDelete.length > 0);
        this.mItemsSelectedCount.innerText = this.mItemsToDelete.length + '';
    }
    //__________________________________________________________________________________________
}
