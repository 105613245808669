import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { iPopupBtn } from "../_globals/uiInterfaces";

export interface iPopupParams {
    text: string;
    onClose?: Function;
    yesBtn?: iPopupBtn;
    noBtn?: iPopupBtn;
    secondaryBtn?: iPopupBtn;
    title?: string;
    addWarningSign?: boolean;
}

export class Popup extends Op3dComponentBase<iPopupParams>  {

    private static INSTANCE: Popup;

    private mTextField!: HTMLElement;
    private mYesBtn!: HTMLElement;
    private mSecondaryBtn!: HTMLElement;
    private mNoBtn!: HTMLElement;
    private mPopupFooter!: HTMLElement;
    private mTitle!: HTMLElement;
    private mWarningSign: HTMLElement;
    private mOnClose?: Function;

    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/popup_alert.html'
        });
    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        super._onClose();
        if (this.mOnClose !== undefined) {
            this.mOnClose();
        }
        this.mOnClose = undefined;
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == Popup.INSTANCE) {
            let aFormsCotnainer = document.getElementById('forms');
            if (aFormsCotnainer != null) {
                let aContainer = document.createElement('div');
                aContainer.classList.add('modal');
                aContainer.classList.add('fade');
                aContainer.style.zIndex = '2000';
                aContainer.setAttribute('data-backdrop', 'static');
                aFormsCotnainer.appendChild(aContainer);
                Popup.INSTANCE = new Popup(aContainer);

            } else {
                throw new Error('No forms container');
            }
        }

        return Popup.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mTitle = this._getPart("popup-title", true);
        this.mTextField = this._getPart('pupup_alert_text', true);
        this.mYesBtn = this._getPart("yes-popup-btn", true);
        this.mSecondaryBtn = this._getPart("secondary-popup-btn", true);
        this.mNoBtn = this._getPart("no-popup-btn", true);
        this.mPopupFooter = this._getPart("popup-footer", true);
        this.mWarningSign = this._getPart("warning_sign");

        this.mYesBtn.addEventListener("click", () => this._onBtnClicked(this.mData.yesBtn));
        this.mNoBtn.addEventListener("click", () => this._onBtnClicked(this.mData.noBtn));
        this.mSecondaryBtn.addEventListener("click", () => this._onBtnClicked(this.mData.secondaryBtn));
    }
    //__________________________________________________________________________________________
    private _onBtnClicked(pData?: iPopupBtn) {
        if (pData != null && pData.callback) {
            pData.callback();
        }
    }
    //__________________________________________________________________________________________
    private _setBtnName(pBtn: HTMLElement, pDefaultName: string, pData?: iPopupBtn) {
        if (pData != null) {
            pBtn.innerHTML = pData.title != null ? pData.title : pDefaultName;
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData: iPopupParams): void {
        if (null == pData) {
            return;
        }

        this.mData = pData;
        this.mOnClose = this.mData.onClose;
        this.mTextField.innerHTML = pData.text;

        ViewUtils.setElementVisibilityByDNone(this.mPopupFooter,
            this.mData.noBtn != undefined || this.mData.yesBtn != undefined);

        ViewUtils.setElementVisibilityByDNone(this.mNoBtn, this.mData.noBtn != undefined);
        ViewUtils.setElementVisibilityByDNone(this.mWarningSign,
            (true === this.mData.addWarningSign));
        this._setBtnName(this.mNoBtn, "Cancel", this.mData.noBtn);
        ViewUtils.setElementVisibilityByDNone(this.mYesBtn, this.mData.yesBtn != undefined);
        this._setBtnName(this.mYesBtn, "Confirm", this.mData.yesBtn);

        let aTitle = pData.title != undefined ? pData.title : "Notice";
        this.mTitle.innerText = aTitle;
        let aSecondaryBtn = this.mData.secondaryBtn;
        ViewUtils.setElementVisibilityByDNone(this.mSecondaryBtn, (undefined != aSecondaryBtn));
        this._setBtnName(this.mSecondaryBtn, '', aSecondaryBtn);
    }
    //__________________________________________________________________________________________
}
