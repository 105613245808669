import { RegisteredServer } from "../server/RegisteredServer";
import { ServerContext } from "../server/ServerContext";
import { SimulationContext } from "../simulation/SimulationContext";
import { Popup } from "../ui/forms/Popup";
import { NotificationCenter } from "../ui/home/_notifications/NotificationCenter";
import { eNotificationToastDuration } from "../ui/home/_notifications/NotificationsContext";
import { piWavelengthSection } from "../ui/part_info/_light_source_section/piWavelengthSection";
import { UnitHandler } from "../units/UnitsHandler";
import { eErrorType, eUnitType } from "./Enums";
import { Op3dContext } from "./Op3dContext";
import { iServerErrorVO } from "./_interfaces/Interfaces";


export class MessagesHandler {

    public static CHAT_BOT_ERROR = "An error occurred. Please try again. ";
    public static UNAUTHORIZED_ACCESS = 'Unauthorized action'; // new
    public static SUCCESS_UPLOAD_OPTICS = 'Optical element successfully uploaded and added to scene'; // new
    public static FAILED_UPLOAD_OPTICS = 'Failed to upload optical element'; // new
    /**
    * @description warning message in library
    */
    public static CHOOSE_ONE_OPTIC = 'You can only add one optical part to your optomechanical part';
    /**
    * @description warning message in library when choose more then 10
    */
    public static CHOOSE_OPTICS_LIMIT = 'You can only select a maximum of 10 parts at a time';
    /**
    * @description warning message in library when choose more then 1 source
    */
    public static ERROR_DOWNLOAD_SETUP = 'Failed to download opt file, try again.'; // new
    public static CHOOSE_SOURCE_LIMIT = 'You can only add one source type to your laser';
    public static FAILED_UPLOAD_APERTURE = 'Failed to upload aperture';
    public static NO_RESULTS_ANALYSIS_FOUND = 'Analysis results not found';
    public static ERROR_OCCURED = 'Error occurred';
    public static PRIMARY_WL_CANNOT_BE_DELETED = "Primary wavelength cannot be deleted";
    public static STILL_ANALIZING = "Still analizing";
    public static DEPRECATED_OPTIC = "Deprecated optical element";
    public static IMPORTED_PART_SAVED_SUCCESSFULLY = 'CAD part has been uploaded to your "Private" directory.'
    public static MAX_EMITTED_RAYS_REACHED_CONFIRMATION = "The simulated number of rays will be bigger than the max defined in the user settings. Do you want to proceed?";
    public static MISSING_COATING_DATA = "Missing coating data";

    public static ADVANCED_QUICK_VIEW_MSG = "Quick view not available for advanced analysis";
    public static TERMS_ERROR_MESSSAGE = "You have to accept the 'Terms and Conditions' and the 'Privacy Policy' as well as to agree to receive our newsletters, announcements, and marketing offers.";
    public static ONE_OF_THE_FIELDS_IS_EMPTY = "One of the fields is empty"; // in use also in upload module form
    public static FIRST_NAME_NOT_VALID = "First name is not valid";
    public static LAST_NAME_NOT_VALID = "Last name is not valid";
    public static COMPANY_NOT_VALID = "Company/University is not valid";
    public static COMPANY_NOT_VALID_LETTERS = "Company/University is not valid. Only English letters and special characters are allowed";
    public static COUNTRY_NOT_VALID = "Select your country";
    public static EMAIL_NOT_VALID = "Email is not valid";
    public static PHONE_NOT_VALID = "Phone number is not valid";
    public static USER_ALREADY_EXISTS = "User with current email already exists";
    public static EMAIL_ALREADY_EXISTS = "This email address is already used";
    public static REGISTRATION_FAILED = "Registration failed";
    public static PASSWORD_CHANGED_SUCCESSFULLY = "Password changed successfully";
    public static PASSWORD_CHANGE_FAILED = "Failed to change password";
    public static WRONG_PASSWORD = "Your current password is incorrect";
    public static INVALID_EMAIL = "Invalid Email";
    public static RECOVERY_EMAIL_SENT_MSG = "A recovery email was sent. Please check your inbox";
    public static ERROR_SEND_RECOVERY_EMAIL = "Error sending email";
    public static ERROR_ON_SAVE = "Error occurred while saving lens data.";
    public static ERROR_ON_SAVE_FOREIGN_PART = "Error occurred while saving lens data. You can not save properties in foreigns parts, changes will not be saved.";
    private static UNREDOGNIZED_FILE_TYPE_MSG = "Unrecognized file name";
    public static BROWSER_MESSAGE = "The application works best with Chrome or Edge Browsers. Some features might not work.";
    public static UPDATE_USER_PROFILE = "User profile was updated";
    public static RADIUS_CURVATURE_ERROR = 'Radius of curvature must be equal or greater than half of the optical diameter';

    // BLACK BOX MESSAGES
    public static BLACK_BOX_ADD_NOTIFICATION = "Only individual unmounted optical elements can be added to black box";

    // MATERIAL INFO MESSAGES
    public static MATERIAL_INFO_INVALID_MATERIAL = "Material is invalid";
    public static MATERIAL_INFO_WRONG_FIELDS = "Something's wrong with one or more fields";

    // SETUP RELATED MESSAGES
    public static ERROR_WHILE_LOADING_SETUP = "An error occurred while loading a setup";
    public static ERROR_REMOVING_SETUP = "Error removing setup";
    public static UPDATE_EXAMPLE_SETUP = "Are you sure you want to update an example setup?";
    public static UPLOAD_EXAMPLE_SETUP = "Are you sure you want to upload an example setup?";
    public static ERROR_SAVING_SETUP = "An error occurred while saving setup";
    public static REMOVE_SETUP_MSG_CONFIRMATION = "Are you sure you want to delete the setup from the database? This action cannot be undone.";
    public static REMOVE_SETUPS_MSG_CONFIRMATION = "Are you sure you want to delete the setups from the database? This action cannot be undone.";
    public static DOWNLOAD_OPT_FILES = "Are you sure you want to download .opt files?";
    public static SAVE_AS_OPTICAL_ELEMENT_SAME_NAME = "You must change the name";
    public static SETUP_DELETED_SUCCESSFFULY = "Setup deleted";
    public static OPEN_NEW_SETUP_CONFIRMATION_MSG = "Are you sure you want to open a new setup? All previous work will not be saved"
    public static NEW_SCENE_CONFIRMATION_MSG = "Are you sure you want to reset the scene? All previous work will not be saved"
    public static THERE_IS_NO_SETUP_INFO = "There is no data associated with this setup.";
    public static OPT_CONTENT_NOT_SUPPORTED = "Opt content not supported";

    //FILTER FINDER
    public static ERROR_LOADING_FILTER_FINDER_FILE = "Error loading file";
    public static EMPTY_FILE = "Empty file";
    public static FILE_DATA_INVALID = "File data invalid";

    //EFL CALCULATOR MESSAGES
    public static CHOOSE_MATERIAL_MSG = "Choose material";
    public static ANALYSIS_START = "Analysis has started";
    public static ANALYSIS_FINISHED = "Analysis has finished";
    public static ANALYSIS_FINISHED_ERROR = "Error occurred while performing analysis";
    // public static ANALYSIS_NO_WAVELENGTHS_CHOSEN = "No wavelengths chosen";
    public static ANALYSIS_NO_RESULT_TO_COMPARE = "No analyses to compare";
    public static ANALYSIS_NO_RESULT_TO_RUN = "No analyses to run";

    //SEQ MESSAGES
    public static SEQ_CHANGES_UPDATED = 'Changes have been updated';
    public static SEQ_VERSION_ADDED = "Version added";
    public static ERROR_GENERATING_SEQ_FILE = "Error generating seq file";
    public static SEQ_ERROR_OCCURRED_READING_CONTENT = "Error occurred while reading content";


    //SIMULATION RELATED MESSAGES
    public static MAX_RAYS_COUNT_FOR_VISUALIZATION =
        "The sum total of rays that can be produced by all light sources on the scene is " + SimulationContext.SIMULATION_CONSTANTS.RAYS_COUNT_TOTAL_3D + " Remove: ";
    public static NEW_DESIGN_FORM_MISSING_REQUIRED_FIELD: string = "Not all required fields were filled";


    public static WEIGHT_0_MSG = "You must have at least one wavelength, with weight bigger then 0";
    public static TOO_MANY_WAVELENGTHS_PER_SETUP = "Max number of wavelengths per setup is " + SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT;
    public static TO_MANY_LIGHT_SOURCE_FOR_VISUALIZATION = "Max number of active light sources is " + SimulationContext.SIMULATION_CONSTANTS.LIGHT_SOURCE_COUNT;
    public static NO_SOURCES_FOUND = "No sources found";
    public static SIMULATION_FAILED = "Error occurred while running simulation";
    public static SIMULATION_TERMINATED = "Simulation terminated";
    public static USAGE_LIMIT_EXCEEDED = "GPU usage limit exceeded";

    public static ERROR_LOADING_LIGHT_SOURCE = "Error loading light source";
    public static ERROR_LOADING_SINGLE_MATERIAL = "An error occurred  while loading material";

    public static MATERIAL_WAVELENGTH_MESSAGE = "The material is not defined for the requested wavelength/s. It is calculated for wavelength ";
    public static MATERIAL_SOURCES_MESSAGE_PART = "Due to lack of wavelength information below [min] nm or above [max] nm "
        + "the transmission and reflection values outside the wavelength region will be calculated by the refractive index of [min] nm or [max] nm respectively.";


    public static SETUPS_DELETED_SUCCESSFULLY = 'Setups deleted successfuly';

    // GENERAL MESSAGES
    public static LOGIN_FAILED_EMAIL_PASSWORD = "Login failed! Invalid email or password";
    public static LOGIN_FAILED_GENERAL = "Login failed";
    private static ERROR_READING_FILE = "Error Reading File";
    private static GENERAL_ERROR_MESSAGE = "Error occurred. Please save your work, refresh the browser tab, and try again.";
    public static ERROR_LOADING_PART = "Error loading part";
    private static WRONG_DIAMETER_EFL_MSG = "Wrong diameter";
    private static CONNECTION_RESTORED_MSG = "Internet connection restored";
    private static LOST_CONNECTION_MSG = "Lost internet connection";
    private static NO_LASER_HAS_BEEN_CHOSEN_MSG = "Please choose which laser you would like to simulate with.<br>" +
        "Press the blue arrow next to the 'Propagation simulation' blue button and choose the laser.";

    public static NO_LASER_FOUND_FOR_SIMULATION = "There are no lasers found on scene";
    private static SAVED_SUCCESSFULLY = "Saved Successfully";
    public static ERROR_CALCULATING_WAVELENGTH_WEIGHT = "Error calculating wavelength weight";

    private static MEMORY_MSG = "High memory utilization in this browser tab<br>" +
        "We recommend you save your work.<br>" +
        "Refresh the browser tab and start over.";

    public static OPTICAL_ELEMENT_CREATION_ERROR = "An error occurred while creating an optical element";

    public static ERROR_FILE_NAME_FORMAT = "Invalid name format, supported characters are: (0-9, a-z, A-Z), as well as the following special characters: ! . \ - _ * ' ( ), file name length (including extension) should be less than 170 characters.";

    public static COPIED_TO_CLIPBOARD = "Copied to clipboard";
    public static INVALID_INPUT = "Invalid input";
    public static COMING_SOON = "Coming soon";

    public static GENERAL_SPECTRUM_RANGE_MSG = "Invalid Spectrum range. <br>Please try a different range.";
    public static GENERAL_SPECTROMETER_RANGE_MSG_MIN_0 = "Invalid amplitude range. <br> start cannot be less then 0."; // new
    public static GENERAL_SPECTROMETER_RANGE_MSG_MAX_1 = "Invalid amplitude range. <br> end cannot be greater then 1."; // new
    public static GENERAL_SPECTROMETER_RANGE_MSG_EMPTY_RANGE = "Invalid Spectrum range. <br> Empty range."; // new
    public static GENERAL_SPECTRUM_RANGE_MSG_MIN_0 = "Invalid Spectrum range. <br> start  cannot be less then 0."; // new
    public static GENERAL_SPECTRUM_RANGE_MSG_START_BIGGER_THEN_END = "Invalid Spectrum range. <br> start cannot be greater then end."; // new
    public static GENERAL_SPECTRUM_RANGE_MSG_EMPTY_RANGE = "Invalid Spectrum range. <br> Empty range."; // new


    //CUSTOM PARTS
    public static DELETE_CUSTOM_PART_MSG = "Are you sure you want to delete the part?<br>This action cannot be undone.<br>Please note that setups that contain the deleted part will be affected by this action.";
    public static PART_SUCCESS_UPLOAD = "Part successfully uploaded. The part is located in your private directory.";
    public static PRIVATE_PART_DELETED_SUCCESS = "Part successfully deleted.";
    public static PRIVATE_PART_DELETED_ERROR = "Error deleting part.";

    // GENERAL
    private static MINIMIZE_BAR_MESSAGE = "You can open the minimized form from the lower left sidebar.";

    //LASER MASK FORM
    public static RESET_GRID_CONFIRMATION_MSG = "Are you sure you want to reset the grid? this action cannot be undone"
    public static INVALID_SIZE = "Size is invalid";
    public static APPLY_TEMPLATE_MSG = "Are you sure you want to apply this template? this action cannot be undone";
    public static LARGE_GRID_SIZE_MSG = 'You selected more than 36 grid points. you may run out of memory during the simulation.'


    //MODULES RELATED MESSAGES
    public static ANALYSIS_MISSING_LASER = "you should run simulation first";
    public static GAUSSIAN_BEAM_TABLE_ERROR = "You must run a simulation with at least one Gaussian light source to open the Gaussian beam analysis";

    public static WIDGET_DIFFERRENT_VERSION_WITH_SETUP = 'The widget is not up to date. Please refresh the page.'

    public static WRONG_FILE_FORMAT = "Wrong File format";
    public static NO_FILE_CHOSEN = "No file chosen.";
    public static SINGLE_FILE_UNSUPPORTED = "Unsupported file format";
    public static PLURAL_FILE_UNSUPPORTED = "At least one of the files is unsupported";
    public static SOMETHING_WENT_WRONG = "Something went wrong.";

    //public static RELEASE_INFO = "New release"
    public static WL_INPUT_OUT_OF_RANGE: string = `Input out of range [${piWavelengthSection.WL_RANGE.min},${piWavelengthSection.WL_RANGE.max}]`;
    public static WRONG_ANALYSIS_RAYS_COUNT: string = "Number of rays exceeds the value set in the light source properties. For a higher quantity, please change the value in the “Analysis” field"
    public static ALL_WEIGHT_ZERO: string = 'The selected range (𝝺min-𝝺max) does not contain wavelengths with non-zero weight'
    public static MAX_WAVELENGTH_COUNT_TABLE: string = `Maximal quantity of wavelengths (${SimulationContext.SIMULATION_CONSTANTS.WAVELENGTHS_COUNT}) has been exceeded. Please adjust the wavelength ranges or the delta fields.`
    public static ERROR_SEARCH_MENU: string = "Error occurred during search";
    public static CLICK_MENU_ITEM: string = "To add the element click on the checkboc and then 'Add selected to design'";


    //__________________________________________________________________________________________
    public static MAX_ALLOWED_ARRAY_OF_ELEMENTS_COUNT_MSG(pCount: number) {
        return `Possible amount for elements in array is ${pCount}`;

    }
    //__________________________________________________________________________________________
    public static NO_LASER_HAS_BEEN_CHOSEN() {
        NotificationCenter.instance.pushNotification({
            message: this.NO_LASER_HAS_BEEN_CHOSEN_MSG,
            params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
        });
    }
    //__________________________________________________________________________________________
    public static SETUP_VERSION_ERROR() {
        Popup.instance.open({
            text: 'New 3DOptix version is available. Please refresh.'
        });
    }
    //__________________________________________________________________________________________
    public static SAVED_SUCCESSFULLY_MSG() {
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.SAVED_SUCCESSFULLY,
            params: NotificationCenter.NOTIFICATIONS_TYPES.SUCCESS_MESSAGE
        });
    }
    //__________________________________________________________________________________________
    public static CHANGE_ENVIORMENT(pNewUnit: eUnitType, pYesCallBack: Function) {
        let aNewEnv = (pNewUnit == eUnitType.INCHES) ? "Imperial" : "Metric";
        let aMsg = "You are about to switch to " + aNewEnv + " mode<br>";
        aMsg += 'Once you press "confirm" the page will refresh and you would need to log back to the system.<br>';
        aMsg += "Please save your work before you continue.";
        Popup.instance.open({
            text: aMsg,
            yesBtn: { callback: pYesCallBack, title: "Switch" }
        })
    }
    //__________________________________________________________________________________________
    public static WRONG_DIAMETER_EFL_CALCULATOR() {
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.WRONG_DIAMETER_EFL_MSG,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
        });
    }
    //__________________________________________________________________________________________
    public static ON_ERROR_BEHAVIORS(pParams: { error: any }) {
        pParams.error = pParams.error != null && pParams.error.stack != null ?
            pParams.error.stack.toString() : pParams.error;

        let aServerErrorVO: iServerErrorVO = {
            content: pParams,
            version: Op3dContext.VERSION_NUM,
            type: eErrorType.FEATURES_BEHAVIOR,
            email: Op3dContext.USER_VO.email
        }

        RegisteredServer.addError(aServerErrorVO);
    }
    //__________________________________________________________________________________________
    public static async ON_ERROR_PROGRAM(pParams: {
        email?: string,
        error: any, type: eErrorType,
        show_message?: boolean,
        redirectTo500?: boolean
    }) {

        let aEmail = pParams.email != null ? pParams.email : Op3dContext.USER_VO.email;
        let aShowMessage = pParams.show_message != null ? pParams.show_message : true;
        let aRedirectTo500 = pParams.redirectTo500 != null ? pParams.redirectTo500 : false;
        Op3dContext.USER_VO && Op3dContext.USER_VO.isEmployeeUser && console.log(pParams);

        if (aShowMessage) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.GENERAL_ERROR_MESSAGE,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
            });
        }


        let aServerErrorVO: iServerErrorVO = {
            email: aEmail,
            content: pParams.error != null && pParams.error.stack != null ?
                pParams.error.stack.toString() :
                pParams.error,
            version: Op3dContext.VERSION_NUM,
            type: pParams.type
        }

        await RegisteredServer.addError(aServerErrorVO);

        if (aRedirectTo500) {
            window.location.replace(ServerContext.base_link + "/error500.html");
        }
    }
    //__________________________________________________________________________________________
    public static async NETWORK_ON() {
        await Op3dContext.sleep(20);

        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.CONNECTION_RESTORED_MSG,
            params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
        });
    }
    //_______________________________________________________________
    public static NETWORK_OFF() {

        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.LOST_CONNECTION_MSG,
            params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
        });
    }
    //__________________________________________________________________________________________
    public static GENERAL_ERROR(pToShowMessage: boolean = true) {
        if (pToShowMessage) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.GENERAL_ERROR_MESSAGE,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
            });
        }
    }
    //__________________________________________________________________________________________
    public static WRONG_ENV_IMPORT_MESSAGE() {
        let aCurrUnit = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ?
            'Metric' : 'Imperial';
        let aSetupUnit = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ?
            'Imperial' : 'Metric';

        let aMsg = '<div><strong>Importing a file containing. ' + aCurrUnit + ' </strong>';
        aMsg += 'units into a setup with ' + aSetupUnit + ' units is impossible';

        NotificationCenter.instance.pushNotification({
            message: aMsg,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
            toastDuration: eNotificationToastDuration.INFINITE
        });
    }
    //__________________________________________________________________________________________
    public static MEMORY_LIMIT_REACHED() {
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.MEMORY_MSG,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
        });
    }
    //__________________________________________________________________________________________
    public static ERROR_LOADING_MODEL(pErr: Object) {
        Op3dContext
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.ERROR_LOADING_PART,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
        });

        MessagesHandler.ON_ERROR_PROGRAM({
            error: pErr,
            show_message: false,
            type: eErrorType.MODEL_LOADING
        });
    }
    //__________________________________________________________________________________________
    public static SHOW_MINIMIZE_MESSAGE() {
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.MINIMIZE_BAR_MESSAGE,
            params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL
        });
    }
    //__________________________________________________________________________________________
    public static UNREDOGNIZED_FILE_TYPE() {
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.UNREDOGNIZED_FILE_TYPE_MSG,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
        });
    }
    //__________________________________________________________________________________________
    public static ERROR_READING_FILE_MSG() {
        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.ERROR_READING_FILE,
            params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
        });
    }
    //__________________________________________________________________________________________
}