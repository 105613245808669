﻿

export abstract class sdSurfaceDeformation<T = any, S = T> {

    protected mContainer: HTMLElement;
    protected mOnChange: () => void;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pOnChange: () => void) {
        this.mContainer = pContainer;
        this.mOnChange = pOnChange;
        // this._initElements();
    }
    //__________________________________________________________________________________________
    public abstract setData(pData: T): void;
    //__________________________________________________________________________________________
    public abstract getData(): S;
    //__________________________________________________________________________________________
    protected abstract _initElements();
    //__________________________________________________________________________________________
}
