import classes from "./../../UI/optichat-message.module.scss";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iOptiChatMessage } from "../../store/OptiChatContext";

export default function OptiChatUserMessage(props: { data: iOptiChatMessage }) {
  const getUserInitials = () => {
    let aInitials = Op3dContext.USER_VO.getUserInitials();

    return aInitials;
  };

  return (
    <div
      data-testid="user-message"
      className={`${classes["chat-message"]} ${classes["user-message"]}`}
    >
      <div>
        <div className={classes.avatar}>
          <div className={classes["user-avatar"]}>{getUserInitials()}</div>
        </div>
        <div className={classes.content}>
          <div>{props.data.message}</div>
        </div>
      </div>
    </div>
  );
}
