

import { Zip } from "../../../loaders/Zip";
import { EventBase } from "../../../oc/events/EventBase";
import { EventManager } from "../../../oc/events/EventManager";
import { AnalyticsEventsContext } from "../../_context/AnalyticsEventsContext";
import { eStateToAnalysis, eModalWindowState } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iRenameSetupData } from "../../_context/_interfaces/Interfaces";
import { BOMGenerator } from "../../bom/BOMGenerator";
import { CoatingConvert } from "../../convert/CoatingConvert";
import { ConvertForm } from "../../convert/ConvertForm";
import { AdminCoatingParser } from "../../parser/AdminCoatingParser";
import { MaterialParser } from "../../parser/MaterialParser";
import { MountsParser } from "../../parser/MountsParser";
import { OpticsParser } from "../../parser/OpticsParser";
import { SourcesParser } from "../../parser/SourcesParser";
import { UploadFileForm, iInitUploadFormParams } from "../../parser/UploadFileForm";
import { UploadFileFormCadNew } from "../../parser/UploadFileFormCadNew";
import { eLabelType } from "../../scene/CSS2DLabel";
import { iSceneOptions } from "../../scene/GridManager";
import { Op3dScene } from "../../scene/Op3dScene";
import { SceneContext } from "../../scene/SceneContext";
import { AuthUtils } from "../../server/AuthUtils";
import { ServerContext } from "../../server/ServerContext";
import { SettingsContext } from "../../settings/SettingsContext";
import { SetupsManager } from "../../setups/SetupManager";
import { eLaserColorType } from "../../simulation/SimulationContext";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { UnitHandler } from "../../units/UnitsHandler";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { GaussianBeamTable } from "../analysis/GaussianBeamTable";
import { BreadboardForm } from "../forms/BreadboardForm";
import { ChoosePartMode } from "../forms/ChoosePartMode";
import { Popup } from "../forms/Popup";
import { ShareLink } from "../forms/ShareLink";
import { UserSettingsFormNew } from "../forms/UserSettingsFormNew";
import { GaussianBeamCalculator } from "../forms/calculators/GaussianBeamCalculator";
import { LensCalculator } from "../forms/calculators/LensCalculator";
import { OpticsDeleter } from "../forms/optics/OpticsDeleter";
import { FilterFinderForm } from "../forms/tools/FilterFinderForm";
import { ImportCad } from "../forms/tools/ImportCad";
import { MaterialInformationForm } from "../infos/MaterialInformationForm";
import { ImageQualityForm } from "../tools/ImageQualityForm";
import { ImagePresenter } from "./ImagePresenter";
import { NewDesignForm } from "./NewDesignForm";
import { OpenSetupForm } from "./OpenSetupForm";
import { Spinner } from "./Spinner";
import { WakeupScreen } from "./WakeupScreen";
import { NotificationCenter } from "./_notifications/NotificationCenter";


export class Menu extends Op3dComponentBase {
    public static LIMIT_CAD_FILE = {
        PREMIUM: 30,
        FREE: 5
    };
    private static FILE_NAME_LIMIT_LENGTH: number = 60;

    private static INSTANCE: Menu;
    private mNotificationCenterBell: HTMLElement;
    private mFileNameHeadline: HTMLElement;
    private mTryProffesionalBtn: HTMLElement;
    private mLabelsInput: HTMLInputElement;
    private mPowerDisplayOption: HTMLInputElement;
    private mImportCadBtn: HTMLElement;


    //__________________________________________________________________________________________
    constructor() {
        super({
            container: document.getElementById('menu-container'),
            skinPath: './skins/home/menu.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance(): Menu {
        if (this.INSTANCE == null) {
            this.INSTANCE = new Menu();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public async create() {
        await this._prepareForm();
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this._updateMenuUserName();
        this._initNotificationCenter();
        this._initTryProffesionalButton();

        ViewUtils.makeUnselectable(this.mContainer);
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _initTryProffesionalButton() {
        if (Op3dContext.USER_PERMISSION != null) {

            let aLinkToPricing = this._getPart("try_premium") as HTMLAnchorElement;
            aLinkToPricing.href = ServerContext.pricing_base_link;

            this.mTryProffesionalBtn = this._getPart("try-professional");
            ViewUtils.setElementVisibilityByDNone(this.mTryProffesionalBtn,
                Op3dContext.USER_PERMISSION.isFreeUser);
        }
    }
    //__________________________________________________________________________________________
    private _setBellActive(pIsActive: boolean) {
        ViewUtils.setClassState(this.mNotificationCenterBell, 'active_bell', pIsActive);
    }
    //__________________________________________________________________________________________
    private _initNotificationCenter() {
        this.mNotificationCenterBell = document.getElementById("notification-center-bell");
        this.mNotificationCenterBell.addEventListener("click",
            () => NotificationCenter.instance.toggle());

        EventManager.addEventListener(EventsContext.UNHIGHLIGHT_NOTICICATION_BELL,
            () => this._setBellActive(false), this);

        EventManager.addEventListener(EventsContext.HIGHLIGHT_NOTICICATION_BELL,
            () => this._setBellActive(true), this);
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mFileNameHeadline = this._getPart("file-name", true);

        this._initRayColorRB();
    }
    //__________________________________________________________________________________________
    private _initRayColorRB() {
        $(this.mContainer).find('input[type="radio"][name="rays-color"]').each(
            (_index: number, element) => {

                element.addEventListener('change', () => {

                    let aChoice = parseInt((element as HTMLInputElement).value);
                    switch (aChoice) {
                        case eLaserColorType.DIRECTION:
                        case eLaserColorType.INTENSITY:
                        case eLaserColorType.LOG:
                        case eLaserColorType.WAVELENGTH:
                            SimulationRunner.instance.setColorType = aChoice;
                            break;
                        case eLaserColorType.USER_DEFINED: {
                            if (Op3dContext.USER_VO.isBasicLicense) {
                                SimulationRunner.instance.setColorType = SimulationRunner.instance.colorType;
                            } else {
                                SimulationRunner.instance.setColorType = aChoice;
                            }
                            break;
                        }
                    }

                });
            });
    }
    //__________________________________________________________________________________________
    private _initAdminOptions() {
        const aAdminMenuOptions = this._getPart("admin-options-menu", true);

        if (Op3dContext.USER_VO.isAdmin) {
            this._getPart('mount_parser').addEventListener('click', () =>
                UploadFileForm.instance.open({
                    func: (pParams: iInitUploadFormParams) =>
                        new MountsParser(pParams.fileList, pParams.secondaryFileList, pParams.toReplace),
                    hasToReplace: true,
                    optomechanicParser: true,
                    hasSecondaryInput: true,
                    title: 'Mount Parser',
                    isMultiple: false,
                    inputTitle: 'Upload Mounts File',
                    secondaryInputTitle: 'Upload .step files (.zip)',
                    acceptedFormats: '.csv,.xlsx,.ods,.zip'
                }));

            this._getPart('showUploadCoatingParser').addEventListener('click', () =>
                UploadFileForm.instance.open({
                    func: (pParams: iInitUploadFormParams) =>
                        new AdminCoatingParser(pParams.fileList, pParams.toReplace),
                    title: 'Coating Parser',
                    hasToReplace: true,
                    isMultiple: true
                }));

            this._getPart('showCoatingCSVFileConvert').addEventListener('click', () =>
                ConvertForm.instance.open({
                    func: (pMapFile: File, pFileList: FileList) =>
                        new CoatingConvert(pMapFile, pFileList),
                    title: 'Coating CSV Convert',
                }));

            this._getPart('material_parser').addEventListener('click', () =>
                UploadFileForm.instance.open({
                    func: (pParams: iInitUploadFormParams) =>
                        new MaterialParser(pParams.fileList, pParams.toReplace),
                    hasToReplace: true,
                    title: 'Matrial Parser'
                }));

            this._getPart("optics-deleter").addEventListener("click",
                () => this._openOpticsDeleted());

            this._getPart('optics_parser').addEventListener('click', () =>
                UploadFileForm.instance.open({
                    func: (pParams: iInitUploadFormParams) =>
                        new OpticsParser(pParams.fileList, pParams.toReplace),
                    hasToReplace: true,
                    title: 'Optics Parser',
                    isMultiple: true
                }));

            this._getPart('sources_parser').addEventListener('click', () =>
                UploadFileForm.instance.open({
                    func: (pParams: iInitUploadFormParams) =>
                        new SourcesParser(pParams.fileList,
                            pParams.secondaryFileList, pParams.toReplace),
                    title: 'Sources Parser',
                    hasSecondaryInput: true,
                    hasToReplace: true,
                    inputTitle: 'Upload Sources File',
                    secondaryInputTitle: 'Upload Spectrum Files'
                }));

        } else {
            ViewUtils.removeFromParent(aAdminMenuOptions);
        }
    }
    //__________________________________________________________________________________________
    private _openOpticsDeleted() {
        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('fade');
        aDiv.classList.add('overflow-hidden');
        aDiv.setAttribute("data-backdrop", "false");
        aDiv.id = "optics-deleter";
        document.getElementById('forms').appendChild(aDiv);

        new OpticsDeleter(aDiv).open();
    }
    //__________________________________________________________________________________________
    private _onRecordScene() {
        Popup.instance.open({ text: MessagesHandler.COMING_SOON });
    }
    //__________________________________________________________________________________________
    private _initBreadboardInput(pBreadboardInput: HTMLInputElement) {
        pBreadboardInput.addEventListener('change', () =>
            SceneContext.GRID_MANAGER.setBreadboardVisiblity(pBreadboardInput.checked));


        EventManager.addEventListener(EventsContext.SHOW_BREADBOARD_SHORCUT,
            () => this._toggleBreadboard(pBreadboardInput), this);
    }
    //__________________________________________________________________________________________
    private _initGridInput(pGridInput: HTMLInputElement) {
        pGridInput.addEventListener('change', () =>
            SceneContext.GRID_MANAGER.setGridVisiblity(pGridInput.checked));


        EventManager.addEventListener(EventsContext.SHOW_GRID_SHORTCUT,
            () => this._toggleGrid(pGridInput), this);
    }
    //__________________________________________________________________________________________
    public setLabelsState(pState: boolean, pSaveScene: boolean) {
        this.mLabelsInput.checked = (true === pState);
        this._onChangeLabelsState(pSaveScene);
    }
    //__________________________________________________________________________________________
    public setPowerMeterLabelsState(pState: boolean, pSaveScene: boolean) {
        this.mPowerDisplayOption.checked = (true === pState);
        this._onChangeShowPowerState(pSaveScene);
    }
    //__________________________________________________________________________________________
    private _onChangeLabelsState(pSaveScene: boolean) {
        let aShowLabels = this.mLabelsInput.checked;
        Op3dScene.SHOW_ALL_LABELS = aShowLabels;
        Op3dContext.SETUPS_MANAGER.setupParameters.show_labels = aShowLabels;

        Op3dContext.PARTS_MANAGER.setAllLabelsVisibility(eLabelType.LABEL, aShowLabels);

        if (pSaveScene) {
            Op3dContext.SCENE_HISTORY.saveScene();
        }
    }
    //__________________________________________________________________________________________
    private _onChangeShowPowerState(pSaveScene: boolean) {
        let aShow = this.mPowerDisplayOption.checked;
        Op3dScene.SHOW_POWER_METER = aShow;
        Op3dContext.PARTS_MANAGER.setAllLabelsVisibility(eLabelType.POWER, aShow);

        if (pSaveScene) {
            Op3dContext.SCENE_HISTORY.saveScene();
        }
    }
    //__________________________________________________________________________________________
    private _initEdgeInput(pEdgeInput: HTMLInputElement) {
        pEdgeInput.addEventListener('change', () =>
            //show all edges
            Op3dContext.PARTS_MANAGER.isToShowEdges(pEdgeInput.checked))
    }
    //__________________________________________________________________________________________
    public isEdgesShown() {
        return (this._getPart('show-edges') as HTMLInputElement).checked
    }
    //__________________________________________________________________________________________
    private async _toggleGrid(pGridInput: HTMLInputElement) {
        pGridInput.checked =
            await SceneContext.GRID_MANAGER.toggleGridVisibility();
    }
    //__________________________________________________________________________________________
    private async _toggleBreadboard(pBreadboardInput: HTMLInputElement) {
        pBreadboardInput.checked =
            await SceneContext.GRID_MANAGER.toggleBreadboardVisibility();
    }
    //__________________________________________________________________________________________
    private _initSceneOptions() {
        let aGridCheckbox = this._getPart('show-grid-input') as HTMLInputElement;
        let aEdgeCheckbox = this._getPart('show-edges') as HTMLInputElement;
        let aBreadboardInput = this._getPart('show-breadboard-input') as HTMLInputElement;

        this._initBreadboardInput(aBreadboardInput);
        this._initGridInput(aGridCheckbox);
        this._initEdgeInput(aEdgeCheckbox);

        this.mLabelsInput = this._getPart('show_all_sprite_labels') as HTMLInputElement;
        this.mLabelsInput.checked = Op3dScene.SHOW_ALL_LABELS;
        this.mLabelsInput.addEventListener('change', () => this._onChangeLabelsState(true));
        this.mLabelsInput.nextElementSibling.addEventListener('mouseup',
            (e) => e.stopPropagation());


        this.mPowerDisplayOption = this._getPart('display_power_checkbox') as HTMLInputElement;
        this.mPowerDisplayOption.checked = Op3dScene.SHOW_POWER_METER;
        if (false === Op3dContext.USER_VO.isBasicLicense) {
            this.mPowerDisplayOption.addEventListener('change',
                () => this._onChangeShowPowerState(true));
        }
        this.mPowerDisplayOption.nextElementSibling.addEventListener('mouseup',
            (e) => e.stopPropagation());

        EventManager.addEventListener(EventsContext.UPDATE_SCENE_SETTINGS,
            (pEventBase: EventBase<iSceneOptions>) => {
                let aSceneOptions = pEventBase.data;
                aBreadboardInput.checked = aSceneOptions.isBreadboardVisible;
                aGridCheckbox.checked = aSceneOptions.isGridVisible;
                this.mLabelsInput.checked = Op3dScene.SHOW_ALL_LABELS;
            }, this);

    }
    //__________________________________________________________________________________________
    private _initGeneralOptions() {
        this._initSceneOptions();

        this._getPart('scene_options').addEventListener('click',
            () => BreadboardForm.instance.open());

        this._getPart('share-link').addEventListener('click',
            () => ShareLink.instance.open());

        this._getPart("show-controller").addEventListener("click",
            () => this._onShowControllerInstructions());

        this._getPart("run-simulation").addEventListener("click",
            () => SimulationRunner.instance.runVisualizationSimulation());

        this._getPart("material-info", true).addEventListener("click",
            () => MaterialInformationForm.instance.open());

        this._getPart('record-video').addEventListener("click",
            () => this._onRecordScene());

        this._getPart('existing-from-cloud').addEventListener("click", () =>
            OpenSetupForm.instance.open());

        this._getPart("generate-bom", true).addEventListener("click",
            () => this._onExportBom());
        this._getPart('existing-from-local').addEventListener("click", () =>
            SetupsManager.chooseOptFromLocal(false, true));

        this._getPart("menu_logout", true).addEventListener("click", () => this._onLogout());
        this._getPart("new-btn", true).addEventListener("click", () => this._onNewBtn());
        let aClosed = false;
        this._getPart("close-setups-list", true).addEventListener("click",
            async () => {
                EventManager.dispatchEvent(EventsContext.CLEAR_FILTERS, this)
                if (!aClosed) {
                    aClosed = true;
                    await Op3dContext.SETUPS_MANAGER.closeSetup();
                    Op3dContext.SETUPS_MANAGER.currSetupID = null;
                    this._onCloseSetup();
                    aClosed = false;
                }
            });

        this._getPart("zoom-in-menu", true).addEventListener("click", () => this.onZoom(1));
        this._getPart("zoom-out-menu", true).addEventListener("click", () => this.onZoom(-1));

        this._getPart("download-simple-img", true).addEventListener("click", () => this._onDownloadSimpleImage());

        this._getPart("efl-calculator", true).addEventListener("click", () => LensCalculator.instance.open());
        this._getPart("gbc-calculator", true).addEventListener("click", () => GaussianBeamCalculator.instance.open());
        this._getPart("gaussian-table", true).addEventListener("click", () => GaussianBeamTable.openGaussianTable());

        this._getPart("filter-search").addEventListener('click', () => FilterFinderForm.instance.open());
        this._getPart("open-setups-list", true).addEventListener("click", () => this._onCloseSetup());
        this._getPart("undo-btn-menu", true).addEventListener("click", () => Op3dContext.SCENE_HISTORY.undoHistory());

        let aLeftMenuOptions = this._getPart("left-options-menu");
        $(aLeftMenuOptions).find('.dropdown .dropdown-toggle').each((_index, element) => {
            element.addEventListener("click", (_e) => {
                ViewUtils.toggleClass(aLeftMenuOptions, "clicked");
            })

        });

        $('.dropdown').on('click', function () {
            $('.dropdown').not($(this)).removeClass('show');
        });

        this._getPart("save-copy", true).addEventListener("click", () =>
            EventManager.dispatchEvent(EventsContext.COPY_SETUP, this));

        this._getPart("redo-btn-menu", true).addEventListener("click",
            () => Op3dContext.SCENE_HISTORY.redoHistory());

        this._getPart("rename-setup", true).addEventListener("click", () =>
            EventManager.dispatchEvent(EventsContext.RENAME_SET_UP, this));

        this._getPart("setup_description", true).addEventListener("click", () =>
            EventManager.dispatchEvent(EventsContext.RENAME_SET_UP, this));

        this._getPart("export-btn", true).addEventListener("click",
            () => this._onExportSetupLocally());

        let aLogoImg = this._getPart("logo-link", true) as HTMLAnchorElement;
        aLogoImg.href = ServerContext.base_link;

        this._getPart('user_setting').addEventListener('click',
            () => {

                let aSetupParams = Op3dContext.SETUPS_MANAGER != null ?
                    Op3dContext.SETUPS_MANAGER.setupParameters.settings :
                    SettingsContext.getUserSimulationSettings();

                UserSettingsFormNew.instance.open({
                    userSettingsParams: Op3dContext.USER_VO.userVO,
                    currSettings: aSetupParams
                })
            });

        EventManager.addEventListener(EventsContext.OPEN_WAKEUP_SCREEN,
            () => this._onCloseSetup(), this);

        //CAD Menu
        this._getPart('faces-menu').addEventListener('click',
            () => ChoosePartMode.instance.enterFacesMode());
        this._getPart('solids-menu').addEventListener('click',
            () => ChoosePartMode.instance.enterSolidMode());
        this._getPart('leave_to_base').addEventListener('click',
            () => ChoosePartMode.instance.leaveChoosePartMode());
        this._getPart('change_base').addEventListener('click',
            () => ChoosePartMode.instance.enterChangeBaseMode());
        this._getPart('edges-menu').addEventListener('click',
            () => ChoosePartMode.instance.enterEdgesMode());
        // this._getPart('vertices-menu').addEventListener('click',
        //     () => ChoosePartMode.instance.enterVerticesMode());
        this._getPart('assembly_mode').addEventListener('click',
            () => ChoosePartMode.instance.enterAssemblyMode());
        this._getPart('color_face_mode').addEventListener('click',
            () => {
                ChoosePartMode.instance.enterColorFaceMode()
            });
        this._getPart('color_part_mode').addEventListener('click',
            () => {
                ChoosePartMode.instance.enterColorPartMode()
            });
        this._getPart('color_solid_mode').addEventListener('click',
            () => {
                ChoosePartMode.instance.enterColorSolidMode()
            });
        this._getPart('mesurement_mode').addEventListener('click', () => {
            EventManager.dispatchEvent(EventsContext.ENTER_MEASUREMENT_MODE, this);
        }
        );
        // this._getPart('group_mode').addEventListener('click',
        //     () => ChoosePartMode.instance.enterGroupMode());
        this._getPart('choose_CS_mode').addEventListener('click',
            () => {
                // Op3dContext.PARTS_EVENTS_HANDLER.enterAddLCSMode(Op3dContext.PARTS_MANAGER.partsContainer as any)
                // ChoosePartMode.instance.enterChooseCSMode()
            })

        this.setCADProgressBar()
    }
    //______________________________________________________________________________________________
    private _onExportBom() {
        if (Op3dContext.USER_VO.isBasicLicense === false) {
            new BOMGenerator().exportBOM()
        }
    }
    //__________________________________________________________________________________________
    public setCADProgressBar() {

        let aStorageProgressBar = this._getPart("storage_progress") as HTMLProgressElement

        let aCadData = Op3dContext.USER_VO.cadData;
        let aUsageInMb = aCadData.current_usage * 0.00000095367432;
        let aPercentageCad = Math.min(100, ((aUsageInMb / aCadData.limit) * 100));
        aStorageProgressBar.value = isNaN(aPercentageCad) ? 0 : aPercentageCad;

        if (aPercentageCad >= 100) {
            aStorageProgressBar.parentElement.setAttribute('disabled', 'true')
        } else {
            aStorageProgressBar.parentElement.removeAttribute('disabled')
        }
        aStorageProgressBar.nextElementSibling.innerHTML = aPercentageCad.toFixed(2) + '% used'
    }

    //__________________________________________________________________________________________
    public setCurrentEnvironment() {
        let aCurrEnv = this._getPart('current_environment');
        let aTitle = UnitHandler.envName + ' setup';

        aCurrEnv.setAttribute('title', aTitle);
        aCurrEnv.innerHTML = UnitHandler.shortSign;
    }
    //__________________________________________________________________________________________
    private _onShowControllerInstructions() {
        ImagePresenter.instance.open({ image: './images/mouse_controls.png' });
    }
    //__________________________________________________________________________________________
    private async _onCloseSetup() {
        if (Op3dContext.CONTAINER === null) {
            throw new Error("missing 3D canvas container");
        }
        await WakeupScreen.instance.open({ isNew: false });
        await WakeupScreen.instance.showWakeupScreen(true, Op3dContext.CONTAINER);
        if (GaussianBeamTable.instanceExists()) {
            GaussianBeamTable.instance.onClose();
        }
        AnalysisPortal.instance.enableRunAnalysis(
            eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
        document.getElementById('quick-view-btn').removeAttribute('active');
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._initAdminOptions();
        this._initGeneralOptions();
        this._initImportPart();
        // this._initImportOptixPart();
        // this._initImportOptLensPart();

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onUpdatePermissions(), this);
    }
    //__________________________________________________________________________________________
    private _onUpdatePermissions() {
        let aLimit = Op3dContext.USER_VO.isBasicLicense ?
            Menu.LIMIT_CAD_FILE.FREE : Menu.LIMIT_CAD_FILE.PREMIUM;
        this.mImportCadBtn.children[0].innerHTML = `Import CAD (up to ${aLimit} MB)`;
        this.setCADProgressBar();
    }
    //__________________________________________________________________________________________
    private _onClickImportCad() {
        let aLimit = Op3dContext.USER_VO.isBasicLicense ?
            Menu.LIMIT_CAD_FILE.FREE : Menu.LIMIT_CAD_FILE.PREMIUM;

        UploadFileFormCadNew.instance.open({
            func: (pFiles, pCadData) =>
                ImportCad.instance.convertStepToOptix(pFiles.fileList[0], pCadData),
            hasToReplace: false,
            title: `Upload a STEP file (up to ${aLimit}MB)`,
            acceptedFormats: '.stp, .step',
            acceptedSizeMb: aLimit,
            allowedNameRegex: /^[0-9a-zA-Z!.\-_*'()]+$/
        })

    }
    //__________________________________________________________________________________________
    private _initImportPart() {
        this.mImportCadBtn = this._getPart('import-part');
        this.mImportCadBtn.addEventListener('click', () => this._onClickImportCad());
        this._onUpdatePermissions();
    }
    //__________________________________________________________________________________________
    public async setFileName(pData: iRenameSetupData) {
        await this._prepareForm();
        if (pData == null || pData.name == null || pData.name == "") {
            return;
        }

        let aDisplayName = pData.name;
        if (aDisplayName.length > Menu.FILE_NAME_LIMIT_LENGTH) {
            aDisplayName = aDisplayName.slice(0, (Menu.FILE_NAME_LIMIT_LENGTH - 3));
            aDisplayName = aDisplayName + '...';
        }

        this.mFileNameHeadline.innerHTML = aDisplayName;
        this.mFileNameHeadline.title = pData.name;
        this.mFileNameHeadline.setAttribute("data-original-title", pData.name);
        // $(this.mFileNameHeadline).tooltip();
        this.mFileNameHeadline.title = '';
    }
    //__________________________________________________________________________________________
    private _onDownloadSimpleImage(pIsPDF: boolean = false) {
        ImageQualityForm.instance.open({ isPDF: pIsPDF });
    }
    //__________________________________________________________________________________________
    private async _onLogout() {
        AuthUtils.logout();
        AnalyticsEventsContext.triggerFinishSession();
        await Op3dContext.sleep(50);
        Op3dContext.MANAGER.reload();
        //  window.location.href = server.ServerContext.base_link;
    }
    //__________________________________________________________________________________________
    protected async _onNewBtn() {
        NewDesignForm.instance.open({
            isNew: false,
            state: eModalWindowState.NEW
        });
    }
    //__________________________________________________________________________________________
    private onZoom(pZoomDelta: number): any {
        EventManager.dispatchEvent(EventsContext.ON_ZOOM, this, pZoomDelta);
    }
    //__________________________________________________________________________________________
    public isValidFile(pFile: File) {
        let aSuffix = pFile.name.split('.').pop();
        switch (aSuffix) {
            case "opt":
            case "seq":
                return true;
            default:
                return false;
        }
    }
    //__________________________________________________________________________________________
    private async _onExportSetupLocally() {
        Spinner.instance.show();

        let aSetup = Op3dContext.SETUPS_MANAGER.getSetupData()
        Zip.createAndDownloadOpt({
            data: JSON.stringify(aSetup),
            fileName: Op3dContext.SETUPS_MANAGER.setupParameters.details.setupName,
            file_format: ".opt",
        });

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _updateMenuUserName() {
        let aEmail = Op3dContext.USER_VO.email;
        document.getElementById("menu_username").innerHTML = aEmail;
    }
    //__________________________________________________________________________________________

}
