import { eStateToAnalysis } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../../parts/Part";
import { iDetectorData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { CustomDetectorBehavior } from "../../parts/behaviors/CustomDetectorBehavior";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";
import { iNIEParams } from "./_light_source_section/piLightSourceSection";
import { piDetectorSizeSection } from "./piDetectorSizeSection";


export interface iDetectorSection {
    detectorData: iDetectorData;
    part: Part;
}

export class piDetectorSection extends PartInfoSection<iDetectorSection> {

    private mDetectorSizeSection: piDetectorSizeSection;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_detector_section.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._initSizeSection();
    }
    //__________________________________________________________________________________________
    private _initSizeSection() {
        let aContainer = this._getPart("detector-size-section-container", true);
        let aUpdateFunc = (pUpdateVis: boolean) => this._onDetectorDataChanged(pUpdateVis);

        this.mDetectorSizeSection = new piDetectorSizeSection(aContainer, aUpdateFunc);
    }
    //__________________________________________________________________________________________
    private _onDetectorDataChanged(pUpdateVisualization: boolean) {
        let aDetectorData: iDetectorData = {
            size: this.mDetectorSizeSection.getSizeData(),
            appearance: this.mDetectorSizeSection.getApperanceData(),
        }

        let aDetector = Op3dContext.PARTS_MANAGER.selectedPart;
        CustomDetectorBehavior.updateDetectorData(aDetector, aDetectorData,
            pUpdateVisualization);

        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iDetectorSection): void {
        this._setDetectorSizeSection(pData.detectorData);
    }
    //__________________________________________________________________________________________
    private _setDetectorSizeSection(pDetectorData: iDetectorData) {
        this.mDetectorSizeSection.setVisibility(null != pDetectorData);
        if (null == pDetectorData) {
            return;
        }

        this.mDetectorSizeSection.data = pDetectorData;
    }
    //__________________________________________________________________________________________
    public static getNumberInputElement(pData: iNIEParams<any>) {

        let aLongestNum = Math.max(pData.range.min.toString().length,
            pData.step.toString().length);

        let aFix = pData.toFixed != null ? pData.toFixed : Math.min(6, aLongestNum + 1);

        let aElement = new NumberInputElement(pData.container, {
            hasArrows: pData.hasArrows,
            isGlobalToFixed: true,
            toFixed: aFix,
            worldToUnit: pData.worldToUnit,
            sign: pData.sign,
            unitName: pData.unitName != null ? pData.unitName : "",
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: true
            }
        });

        aElement.setData({
            range: pData.range,
            step: pData.step,
            callback: pData.callback
        });

        aElement.value = pData.defaultValue;
        return aElement;
    }
    //__________________________________________________________________________________________
}
