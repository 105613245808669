﻿import { Chart } from "chart.js/auto";
import { iHash, iMinMax, iOP3DHTMLInputElement, iOP3DHTMLOptionElement, iPoint2D } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { iCustomSelectOption, CustomSelect } from "../../components/CustomSelect";
import { iCalcUnitSettings, eCalcUnitType } from "../FloatingCalcSettings";

// var Chart = Chart;

export interface iCalcGraphParams {
    calcHash: iHash<iHash<(pData: iHash<number>) => number>>;
    optionsX: Array<iCustomSelectOption<iCalcCustomSelectData>>;
    optionsY: Array<iCustomSelectOption<iCalcCustomSelectData>>;
}


export interface iCalcGraphData {
    data: iHash<number>;

    materialName: string;
    materialL?: string;
    materialR?: string;
}

export interface iCalcCustomSelectData {
    /**
     * @description Unit of the parameter in the option.
     */
    unit?: iCalcUnitSettings;

    /**
     * @description Unit of the parameter in the option.
     */
    defaultRange?: iMinMax;

    /**
     * @description Unit of the parameter in the option.
     */
    validationFuncion?: (pValue: number, pElements?: iHash<number>) => boolean;

    /**
     * @description Number of floating points for this parameter. Default - 2.
     */
    fixed?: number;
}

export interface iCalcOptionsUpdate {
    x: iCustomSelectOption<iCalcCustomSelectData>;
    y: iCustomSelectOption<iCalcCustomSelectData>;
}

export class CalculatorGraph extends Op3dComponentBase<iCalcGraphData> {

    private mGraphYSelect: CustomSelect<iCalcCustomSelectData>;
    private mGraphXSelect: CustomSelect<iCalcCustomSelectData>;

    private mMaterialInput: HTMLInputElement;
    private mMaterialLInput: HTMLInputElement;
    private mMaterialRInput: HTMLInputElement;

    private mStartX: iOP3DHTMLInputElement;
    private mEndX: iOP3DHTMLInputElement;
    private mStartY: iOP3DHTMLInputElement;
    private mEndY: iOP3DHTMLInputElement;

    private mGraphCot: HTMLElement;

    private mCalcGraphData: iCalcGraphParams;

    //__________________________________________________________________________________________
    private constructor(pParentElement: HTMLElement, pCalcGraphData: iCalcGraphParams) {
        super({
            container: pParentElement,
            skinPath: "./skins/tools/lens_calculator_graph.html",
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });

        this.mCalcGraphData = pCalcGraphData;
    }
    //__________________________________________________________________________________________
    public static getNewInstance(pCalcGraphData: iCalcGraphParams) {
        let aDiv = document.createElement('div');
        aDiv.classList.add('modal');
        aDiv.classList.add('fade');
        aDiv.setAttribute("data-backdrop", "false");
        aDiv.classList.add('new_modal');
        aDiv.classList.add('calc_graph');
        aDiv.style.left = (((window.innerWidth + 552) / 2) + 10) + 'px';
        aDiv.style.top = '55px';
        aDiv.style.display = 'none';
        document.getElementById('forms').appendChild(aDiv);

        return new CalculatorGraph(aDiv, pCalcGraphData);
    }
    //__________________________________________________________________________________________
    public updateUnit(pCalcUnitSettings: iCalcUnitSettings) {
        let aOptionsX = this.mGraphXSelect.options;
        if (null != aOptionsX) {
            this._changeUnit(aOptionsX, pCalcUnitSettings)
        }
        let aOptionsY = this.mGraphYSelect.options;
        if (null != aOptionsY) {
            this._changeUnit(aOptionsY, pCalcUnitSettings)
        }

        this._onChangeYParam(this.mGraphYSelect.value);
    }
    //__________________________________________________________________________________________
    private _changeUnit(pOptions: Array<iOP3DHTMLOptionElement<iCalcCustomSelectData>>,
        pCalcUnitSettings: iCalcUnitSettings) {
        for (let i = 0; i < pOptions.length; i++) {
            if ((null != pOptions[i].data) && (null != pOptions[i].data.unit) &&
                (eCalcUnitType.LENGHT == pOptions[i].data.unit.type)) {
                pOptions[i].data.unit = pCalcUnitSettings;
            }
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen(pData?: iCalcGraphData): void {
        this._onUpdate(pData);
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mGraphCot = this._getPart('graph_cot');

        this.mStartX = this._getPart('start_x') as iOP3DHTMLInputElement;
        this.mEndX = this._getPart('end_x') as iOP3DHTMLInputElement;
        this.mStartY = this._getPart('start_y') as iOP3DHTMLInputElement;
        this.mEndY = this._getPart('end_y') as iOP3DHTMLInputElement;

        this.mMaterialInput = this._getPart('substrate_material') as HTMLInputElement;
        this.mMaterialLInput = this._getPart('material_l') as HTMLInputElement;
        this.mMaterialRInput = this._getPart('material_r') as HTMLInputElement;

        this._initGraphSelectElements();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mStartX.addEventListener('change', () => this._onChangeRangeX(this.mStartX));
        this.mEndX.addEventListener('change', () => this._onChangeRangeX(this.mEndX));
        this.mStartY.addEventListener('change', () => this._onChangeRangeY(this.mStartY));
        this.mEndY.addEventListener('change', () => this._onChangeRangeY(this.mEndY));

        this.mStartX.addEventListener('focus', (e) => this._onFocusInput(e));
        this.mEndX.addEventListener('focus', (e) => this._onFocusInput(e));
        this.mStartY.addEventListener('focus', (e) => this._onFocusInput(e));
        this.mEndY.addEventListener('focus', (e) => this._onFocusInput(e));

        this.mStartX.addEventListener('blur', (e) => this._onBlurInput(e,
            this.mGraphXSelect.selectedOptionData));
        this.mEndX.addEventListener('blur', (e) => this._onBlurInput(e,
            this.mGraphXSelect.selectedOptionData));
        this.mStartY.addEventListener('blur', (e) => this._onBlurInput(e,
            this.mGraphYSelect.selectedOptionData));
        this.mEndY.addEventListener('blur', (e) => this._onBlurInput(e,
            this.mGraphYSelect.selectedOptionData));
    }
    //__________________________________________________________________________________________
    private _replaceInputs(pInput1: iOP3DHTMLInputElement, pInput2: iOP3DHTMLInputElement) {
        let aVal1 = pInput1.value;
        let aActualValue1 = pInput1.actualValue;

        pInput1.value = pInput2.value;
        pInput1.prevValue = pInput1.value;
        pInput1.actualValue = pInput2.actualValue;

        pInput2.value = aVal1;
        pInput2.prevValue = aVal1;
        pInput2.actualValue = aActualValue1;
    }
    //__________________________________________________________________________________________
    private _onFocusInput(e: Event) {
        let aInput = e.target as HTMLInputElement;
        aInput.value = parseFloat(aInput.value) + '';
    }
    //__________________________________________________________________________________________
    private _onBlurInput(e: Event, pData: iCalcCustomSelectData) {
        let aUnit = pData.unit;
        if (null == aUnit) {
            return;
        }

        let aInput = e.target as HTMLInputElement;
        aInput.value += ' ' + aUnit.sign;
    }
    //__________________________________________________________________________________________
    private _checkXRangeValidation(pInput: iOP3DHTMLInputElement) {
        let aVal = parseFloat(pInput.value);
        if (true == isNaN(aVal)) {
            pInput.value = pInput.prevValue;
            return false;
        }

        let aXdata = this.mGraphXSelect.selectedOptionData;
        let aValidationFunc = aXdata.validationFuncion;
        if (null != aValidationFunc) {
            if (false == aValidationFunc(aVal)) {
                pInput.value = pInput.prevValue;
                return false;
            }
        }

        pInput.prevValue = pInput.value;

        let aXScale = ((null != aXdata.unit) && (null != aXdata.unit.scale)) ?
            aXdata.unit.scale : 1;
        pInput.actualValue = (aVal * aXScale);

        aXdata.defaultRange

        if (this.mStartX.actualValue > this.mEndX.actualValue) {
            this._replaceInputs(this.mStartX, this.mEndX);
        }

        return true;
    }
    //__________________________________________________________________________________________
    private _onChangeRangeX(pInput: iOP3DHTMLInputElement) {
        if (false == this._checkXRangeValidation(pInput)) {
            return;
        }

        let aParamX = this.mGraphXSelect.value;
        let aParamY = this.mGraphYSelect.value;

        let aStartX = this.mStartX.actualValue;
        let aEndX = this.mEndX.actualValue;

        let aData = DataUtils.getObjectCopy(this.mData.data);
        aData[aParamX] = aStartX;
        let aY_StartX = this._calc(aParamY, aParamX, aData);

        aData[aParamX] = aEndX;
        let aY_EndX = this._calc(aParamY, aParamX, aData);


        if ((Infinity == aY_StartX) || (Infinity == aY_EndX) ||
            (true == isNaN(aY_StartX)) || (true == isNaN(aY_EndX))) {
            pInput.value = pInput.prevValue;
            return;
        }

        pInput.prevValue = pInput.value;

        let aYmin = Math.min(aY_StartX, aY_EndX);
        let aYmax = Math.max(aY_StartX, aY_EndX);
        this._setRangeY({ min: aYmin, max: aYmax });

        this._calculatePoints();
    }
    //__________________________________________________________________________________________
    private _checkYRangeValidation(pInput: iOP3DHTMLInputElement) {
        let aVal = parseFloat(pInput.value);
        if (true == isNaN(aVal)) {
            pInput.value = pInput.prevValue;
            return false;
        }

        let aYdata = this.mGraphYSelect.selectedOptionData;
        let aValidationFunc = aYdata.validationFuncion;
        if (null != aValidationFunc) {
            if (false == aValidationFunc(aVal)) {
                pInput.value = pInput.prevValue;
                return false;
            }
        }

        pInput.prevValue = pInput.value;

        let aYScale = ((null != aYdata.unit) && (null != aYdata.unit.scale)) ?
            aYdata.unit.scale : 1;
        pInput.actualValue = (aVal * aYScale);

        if (this.mStartY.actualValue > this.mEndY.actualValue) {
            this._replaceInputs(this.mStartY, this.mEndY);
        }

        return true;
    }
    //__________________________________________________________________________________________
    private _onChangeRangeY(pInput: iOP3DHTMLInputElement) {
        if (false == this._checkYRangeValidation(pInput)) {
            return;
        }

        let aParamX = this.mGraphXSelect.value;
        let aParamY = this.mGraphYSelect.value;

        let aStartY = this.mStartY.actualValue;
        let aEndY = this.mEndY.actualValue;

        let aData = DataUtils.getObjectCopy(this.mData.data);
        aData[aParamY] = aStartY;
        let aX_StartY = this._calc(aParamX, aParamY, aData);

        aData[aParamY] = aEndY;
        let aX_EndY = this._calc(aParamX, aParamY, aData);

        if ((Infinity == aX_StartY) || (Infinity == aX_EndY)) {
            pInput.value = pInput.prevValue;
            return;
        }

        pInput.prevValue = pInput.value;

        let aXMin = Math.min(aX_StartY, aX_EndY);
        let aXMax = Math.max(aX_StartY, aX_EndY);
        this._setRangeX({ min: aXMin, max: aXMax })

        this._calculatePoints();
    }
    //__________________________________________________________________________________________
    private _onChangeYParam(pVal: string) {
        let aOptionsX = this.mCalcGraphData.optionsX.filter(option => {
            let aOptionVal = option.value;

            let aIsValid = (Infinity != this.mData.data[aOptionVal]) &&
                (null != this.mCalcGraphData.calcHash[pVal][aOptionVal])

            return aIsValid;
        });

        this.mGraphXSelect.setOptions(aOptionsX, this.mGraphXSelect.value, false);
        if ('' == this.mGraphXSelect.value) {
            this.mGraphXSelect.setIndex(0, false);
        }

        this._onChangeGraph();
    }
    //__________________________________________________________________________________________
    private _initGraphSelectElements() {
        this.mGraphYSelect = new CustomSelect(this._getPart('graph_select_y'),
            {
                placeHolder: 'Choose parameter',
                isVertical: true,
                search: false,
                onChange: (pVal) => this._onChangeYParam(pVal),
                staticPostion: true,
                options: this.mCalcGraphData.optionsY,
                defaultOptionValue: this.mCalcGraphData.optionsY[0].value,
                maxDisplayedOptions: 8
            });

        // let aYVal = this.mGraphYSelect.value;
        // let aOptionsX = this.mCalcGraphData.optionsX.filter(option => {
        //     let aOptionVal = option.value;

        //     let aIsValid = (Infinity != this.mData[aOptionVal]) &&
        //         (null != this.mCalcGraphData.calcHash[aYVal][aOptionVal])

        //     return aIsValid;
        // });

        this.mGraphXSelect = new CustomSelect(this._getPart('graph_select_x'),
            {
                placeHolder: 'Choose parameter',
                search: false,
                onChange: () => this._onChangeGraph(),
                staticPostion: true,
                options: this.mCalcGraphData.optionsX,
                defaultOptionValue: this.mCalcGraphData.optionsY[0].value
            });

    }
    //__________________________________________________________________________________________
    private _setRangeX(pRange: iMinMax) {
        let aOptionXData = this.mGraphXSelect.selectedOptionData;
        let aFixedX = ((null != aOptionXData) && (null != aOptionXData.fixed)) ?
            aOptionXData.fixed : 2;
        let aUnitScale = ((null != aOptionXData) && (null != aOptionXData.unit) &&
            (null != aOptionXData.unit.scale)) ? aOptionXData.unit.scale : 1;

        let aXMin = pRange.min;
        let aXMax = pRange.max;

        let aFixedMin = OP3DMathUtils.ceilFloat((aXMin / aUnitScale), aFixedX);
        let aFixedMax = OP3DMathUtils.ceilFloat((aXMax / aUnitScale), aFixedX);

        this.mStartX.actualValue = aXMin;
        this.mStartX.value = aFixedMin.toString();
        this.mStartX.prevValue = this.mStartX.value;
        this.mStartX.dispatchEvent(new Event('blur'));

        this.mEndX.actualValue = aXMax;
        this.mEndX.value = aFixedMax.toString();
        this.mEndX.prevValue = this.mEndX.value;
        this.mEndX.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _setRangeY(pRange: iMinMax) {
        let aOptionYData = this.mGraphYSelect.selectedOptionData;
        let aFixedY = ((null != aOptionYData) && (null != aOptionYData.fixed)) ?
            aOptionYData.fixed : 2;
        let aUnitScale = ((null != aOptionYData) && (null != aOptionYData.unit) &&
            (null != aOptionYData.unit.scale)) ? aOptionYData.unit.scale : 1;

        let aYMin = pRange.min;
        let aYMax = pRange.max;

        let aFixedMin = OP3DMathUtils.ceilFloat((aYMin / aUnitScale), aFixedY);
        let aFixedMax = OP3DMathUtils.ceilFloat((aYMax / aUnitScale), aFixedY);

        this.mStartY.actualValue = aYMin;
        this.mStartY.value = aFixedMin.toString();
        this.mStartY.prevValue = this.mStartY.value;
        this.mStartY.dispatchEvent(new Event('blur'));

        this.mEndY.actualValue = aYMax;
        this.mEndY.value = aFixedMax.toString();
        this.mEndY.prevValue = this.mEndY.value;
        this.mEndY.dispatchEvent(new Event('blur'));
    }
    //__________________________________________________________________________________________
    private _onChangeGraph() {
        if (('' == this.mGraphXSelect.value) || ('' == this.mGraphYSelect.value)) {
            return;
        }

        let aParamX = this.mGraphXSelect.value;
        let aParamY = this.mGraphYSelect.value;

        let aRangeX = this._getXRange(aParamX);

        this._setRangeX(aRangeX);

        let aData = DataUtils.getObjectCopy(this.mData.data);
        aData[aParamX] = aRangeX.min;
        let aY_Xmin = this._calc(aParamY, aParamX, aData);

        aData[aParamX] = aRangeX.max;
        let aY_Xmax = this._calc(aParamY, aParamX, aData);

        let aYmin = Math.min(aY_Xmin, aY_Xmax);
        let aYmax = Math.max(aY_Xmin, aY_Xmax);

        this._setRangeY({ min: aYmin, max: aYmax });

        this._calculatePoints();
    }
    //__________________________________________________________________________________________
    private _calculatePoints() {
        let aParamX = this.mGraphXSelect.value;
        let aGraphXSelectData = this.mGraphXSelect.selectedOptionData;
        let aScaleX = ((null != aGraphXSelectData) && (null != aGraphXSelectData.unit) &&
            (null != aGraphXSelectData.unit.scale)) ? aGraphXSelectData.unit.scale : 1;

        let aGraphYSelectData = this.mGraphYSelect.selectedOptionData;
        let aScaleY = ((null != aGraphYSelectData) && (null != aGraphYSelectData.unit) &&
            (null != aGraphYSelectData.unit.scale)) ? aGraphYSelectData.unit.scale : 1;

        let aStartX = this.mStartX.actualValue;
        let aEndX = this.mEndX.actualValue;

        let aRangeX: iMinMax = { min: aStartX, max: aEndX };

        let aNumOfPoints = 100;
        let aDelta = ((aRangeX.max - aRangeX.min) / (aNumOfPoints - 1));
        let aData = DataUtils.getObjectCopy(this.mData.data);

        let aPoints = new Array<iPoint2D>();

        for (let i = 0; i < aNumOfPoints; i++) {
            let x = aRangeX.min + (i * aDelta);
            aData[aParamX] = x;
            let y = this._getPoint(aData);
            aPoints.push({ x: (x / aScaleX), y: (y / aScaleY) });
        }

        this._draw(aPoints);
    }
    //__________________________________________________________________________________________
    private _draw(pPoints: Array<iPoint2D>) {
        let aParamX = this.mGraphXSelect.value;
        let aParamY = this.mGraphYSelect.value;
        let aFixedX = ((null != this.mGraphXSelect.selectedOptionData) &&
            (null != this.mGraphXSelect.selectedOptionData.fixed)) ?
            this.mGraphXSelect.selectedOptionData.fixed : 5;

        let labels = pPoints.map((val) => OP3DMathUtils.roundNum(val.x, aFixedX));


        let data = {
            labels: labels,
            datasets: [
                {
                    axis: 'x',
                    // type: 'line',
                    label: aParamY,
                    data: pPoints,
                    fill: false,
                    backgroundColor: 'rgba(35, 167, 222, 1)',
                    color: 'rgba(35, 167, 222, 1)',
                    borderColor: 'rgba(35, 167, 222, 1)',
                    borderWidth: 2,
                    pointRadius: 0
                }]
        };

        ViewUtils.removeElementChildren(this.mGraphCot);

        let aGraphCanvas = document.createElement('canvas');
        this.mGraphCot.appendChild(aGraphCanvas);
        aGraphCanvas.id = Op3dUtils.idGenerator();
        let aBB = this.mGraphCot.getBoundingClientRect();
        aGraphCanvas.width = aBB.width;
        aGraphCanvas.height = aBB.height;

        var ctx = aGraphCanvas.getContext("2d");
        new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                plugins: {
                    tooltip: {
                        intersect: false,
                        callbacks: {
                            title: function (context) {
                                let x = OP3DMathUtils.toFixed((context[0].raw as any).x, aFixedX);
                                return (aParamX + ': ' + x);
                            }
                        },
                        displayColors: false
                    },
                    legend: {
                        display: false
                    }
                }
            }
        });
    }
    //__________________________________________________________________________________________
    private _getXRange(pVal: string) {
        let aOptionData = this.mGraphXSelect.selectedOptionData;

        let aCurrXVal = this.mData.data[pVal];
        let aMinX = aCurrXVal - Math.abs(aCurrXVal / 2);
        let aMaxX = aCurrXVal + Math.abs(aCurrXVal / 2);
        let aRangeX: iMinMax = { min: aMinX, max: aMaxX };
        if (null != aOptionData.defaultRange) {
            aRangeX.min = Math.max(aOptionData.defaultRange.min, aRangeX.min);
        }

        if (aRangeX.min >= aRangeX.max) {
            aRangeX.max = (0 == aRangeX.min) ? 0.1 : (2 * aRangeX.min);
        }

        return aRangeX;
    }
    //__________________________________________________________________________________________
    private _getPoint(pData: iHash<number>) {
        let aParamX = this.mGraphXSelect.value;
        let aParamY = this.mGraphYSelect.value;
        return this._calc(aParamY, aParamX, pData);
    }
    //__________________________________________________________________________________________
    private _calc(pFirst: string, pSecond: string, pData: iHash<number>) {
        let aData = this.mData.data;

        for (let data in aData) {
            if (Infinity == aData[data]) {
                pData[data] = Infinity;
            }
        }

        return this.mCalcGraphData.calcHash[pFirst][pSecond](pData);
    }
    //__________________________________________________________________________________________
    protected _onUpdate(pData?: iCalcGraphData): void {
        this.mData = pData;
        this.mMaterialInput.value = pData.materialName;
        this.mMaterialLInput.value = (null != pData.materialL) ? pData.materialL : '';
        this.mMaterialRInput.value = (null != pData.materialR) ? pData.materialR : '';
        ViewUtils.setElementVisibilityByDNone(this.mMaterialLInput.parentElement,
            (null != pData.materialL));
        ViewUtils.setElementVisibilityByDNone(this.mMaterialRInput.parentElement,
            (null != pData.materialR));

        this._onChangeYParam(this.mGraphYSelect.value);
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
}
