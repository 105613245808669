import { iSize } from "../../../../_context/_interfaces/Interfaces";
import { FileUtils } from "../../../../_utils/FileUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { Op3dComponentBase } from "../../../Op3dComponentBase";
import { ViewUtils } from "../../../ViewUtils";
import { iImageValidateRes } from "./UploadApertureImage";

export interface iUploadApertureFileData {
    acceptedSizeMb: number;
    title: string;
    func: (File: File, pSize: iSize) => void;
    size_to_compare?: iSize
}

export enum eApertureImageType {
    APERTURE_FILE = "APERTURE_FILE",
    PRESET_IMAGE = "PRESET_IMAGE"
}

export class UploadApertureFileForm extends Op3dComponentBase<iUploadApertureFileData> {

    private static INSTANCE: UploadApertureFileForm;
    private static PRESET_IMAGES = [
        '../../images/aperture_presets/3doptix_black_1000x1000.png',
        '../../images/aperture_presets/3doptix_white_1000x1000.png',
        '../../images/aperture_presets/Circular_aperture_R_500_RES_1001_1001_precise.png',
        '../../images/aperture_presets/Circular_obscuration_R_125_RES_1001x1001.png',
        '../../images/aperture_presets/Rectangular_aperture_80x80_RES_1001x1001.png',
        '../../images/aperture_presets/Rectangular_aperture_160x160_RES_1001x1001.png',
        '../../images/aperture_presets/Slit_440x80_RES_1001x1001.png',
        '../../images/aperture_presets/Spherical_lens_convex_Lambda_550nm_FL_1mm_HD_0_25mm_RES_1001x1001.png',
        '../../images/aperture_presets/Spherical_lens_convex_Lambda_550nm_FL_10mm_HD_0_25mm_RES_1001x1001.png',
        '../../images/aperture_presets/Two_circular_apertures_diam_200_dist_300_RES_1001x1001.png',
        '../../images/aperture_presets/Two_circular_apertures_diam_200_dist_500_RES_1001x1001.png',
        '../../images/aperture_presets/Lena_512x512.png',
    ]

    private static ERROR_MESSAGES = {
        FILE_NAME_ERR: "Supported characters are: (0-9, a-z, A-Z), as well as the following special characters: ! . \ - _ * ' ( )",
        FILE_UPLOAD_FAILED_ERR: "Error reading file",
        FORMAT_ERR: "Image format must be .png",
        FORMAT_GRAYSCALE_ERR: "Image format must be <strong>grayscale</strong>",
        IMAGE_RESOLUTION_ERR: "Maximum image size must be <strong>2048*2048 pixels</strong>",
        MAXIMUM_FILE_SIZE_ERR: "Maximum file size must be <strong>10 MB</strong>",
        COLOR_BIT_DEPTH_ERR: "Color depth must be <strong>8bit</strong>",
        IMAGE_SIZE_ERR: "Image size mismatch! Please select an image with the same resolution as the phase image",
    }

    private mTitle: HTMLElement;
    private mFunc: (File: File, pSize: iSize) => void;
    private mInput: HTMLInputElement;
    private mLabel: HTMLElement;
    private mMaxFileSizeMb?: number;
    private mImportBtn: HTMLElement;
    private mWarningDiv: HTMLElement;
    private mWarningMessage: HTMLElement;
    private mResolution?: iSize
    private mApertureImageType: Array<HTMLInputElement> = [];
    private mCurrChosenPreset?: string;

    private constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/upload_aperture_file_form.html'
        });
    }
    //_____________________________________________________________________________________
    public static get instance() {

        if (this.INSTANCE == null) {
            let aFormsContainer = document.getElementById('forms');
            if (aFormsContainer === null) {
                throw new Error("No forms container found");
            }
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('new-modal');
            aDiv.style.zIndex = '1064'
            aDiv.classList.add('upload_aperture_image');
            aDiv.classList.add('fade');
            aDiv.style.left = 'calc(50% - 400px)';
            aDiv.style.top = 'calc(50% - 250px)';
            aFormsContainer.appendChild(aDiv);
            this.INSTANCE = new UploadApertureFileForm(aDiv);
        }

        return this.INSTANCE;
    }
    //_____________________________________________________________________________________
    private _isImageGrayscale(imageData: Uint8ClampedArray) {
        for (let i = 0; i < imageData.length; i += 4) {
            const red = imageData[i];
            const green = imageData[i + 1];
            const blue = imageData[i + 2];

            // If any pixel has different R, G, and B values, it is not grayscale
            if (red !== green || red !== blue || green !== blue) {
                return false;
            }
        }

        return true;
    }
    //_____________________________________________________________________________________
    private async _validate(pLabel: HTMLElement) {
        let aFile = this.mInput.files![0];

        ViewUtils.setElementDisabled(this.mImportBtn, true);

        if (null == aFile) {
            return;
        }

        let aFormat = aFile.name.substring(aFile.name.lastIndexOf('.')).toLocaleLowerCase();
        if (aFormat != ".png") {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.FORMAT_ERR);
            return;
        }


        let aName = aFile.name;
        var pattern = /^[0-9a-zA-Z!.\-_*'()]+$/;
        if (pattern.test(aName) == false) {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.FILE_NAME_ERR);
            return;
        }

        if (this.mMaxFileSizeMb && this.mMaxFileSizeMb <= aFile.size) {
            this.mInput.name = "";
            this.mInput.value = '';
            this.mLabel.innerHTML = 'Choose File';
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.MAXIMUM_FILE_SIZE_ERR);
            return;
        }

        let aRes = await FileUtils.loadFileDataurl(aFile);
        if (aRes.success == false) {
            /**
             * @TODO:
             */
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.FILE_UPLOAD_FAILED_ERR)
            return;
        }

        let aResValidation = await this._readImage(aRes.data);
        if (aResValidation.isGrayScale == false) {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.FORMAT_GRAYSCALE_ERR);
            return;
        }

        let aIsBitOK = aResValidation.bitDepth == 8;
        if (aIsBitOK == false) {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.COLOR_BIT_DEPTH_ERR);
            return;
        }

        if (aResValidation.resolution.height > 2048 || aResValidation.resolution.width > 2048) {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.IMAGE_RESOLUTION_ERR);
            return;
        }

        if (this.mData!.size_to_compare != null &&
            (aResValidation.resolution.height != this.mData!.size_to_compare.height ||
                aResValidation.resolution.width != this.mData!.size_to_compare.width)) {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.IMAGE_SIZE_ERR);
            return;
        }

        this.mResolution = aResValidation.resolution;
        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, false);
        pLabel.innerHTML = aFile.name;
        ViewUtils.setElementDisabled(this.mImportBtn, false);
    }
    //_____________________________________________________________________________________
    private _showError(pTxt: string) {
        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, true);
        this.mWarningMessage.innerHTML = pTxt;
    }
    //_____________________________________________________________________________________
    private _getBitDepth(imageData: Uint8ClampedArray) {
        const max = imageData.reduce((max, v) => max >= v ? max : v, -Infinity);
        const bitDepth = Math.ceil(Math.log2(max + 1));
        return bitDepth;
    }
    //_____________________________________________________________________________________
    private _readImage(pResult: string) {
        return new Promise<iImageValidateRes>(resolve => {
            const image = new Image();
            image.src = pResult;
            image.addEventListener('load', () => {

                this.mResolution = {
                    width: image.width,
                    height: image.height,
                }

                const aCanvas = document.createElement('canvas');
                const aCtx = aCanvas.getContext('2d')!;

                aCanvas.width = image.width;
                aCanvas.height = image.height;
                aCtx.drawImage(image, 0, 0);
                const aImageData = aCtx.getImageData(0, 0, aCanvas.width, aCanvas.height);
                const aIsGrayscale = this._isImageGrayscale(aImageData.data);
                const aBitDepth = this._getBitDepth(aImageData.data);

                resolve({
                    resolution: this.mResolution,
                    isGrayScale: aIsGrayscale,
                    bitDepth: aBitDepth,
                });
            });
        });
    }
    //_____________________________________________________________________________________
    private _onChangePresetType(pType: eApertureImageType) {
        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, false);

        for (let i = 0; i < this.mApertureImageType.length; i++) {
            const aElement = this.mApertureImageType[i]
            const aVal = aElement.value as eApertureImageType;
            if (aVal == pType) {
                aElement.checked = true;
            }
        }

        switch (pType) {
            case eApertureImageType.APERTURE_FILE:
                let aIsDisabled = this.mInput.files === null || this.mInput.files.length === 0;
                ViewUtils.setElementDisabled(this.mImportBtn, aIsDisabled);
                break;
            case eApertureImageType.PRESET_IMAGE:
                $(this.mContainer).find('.single_preset_image').each(
                    (_index, element) => {
                        ViewUtils.setClassForElement(element as HTMLElement, "chosen", false);
                    });
                this.mCurrChosenPreset = undefined;
                ViewUtils.setElementDisabled(this.mImportBtn, true);
                break;
        }

        $(this.mContainer).find('[aperture-image-type]').each(
            (_index, element) => {
                let aAttr = element.getAttribute("aperture-image-type");
                ViewUtils.setElementDisabled(element, aAttr != pType);
            });
    }
    //_____________________________________________________________________________________
    private _initPresetRadio() {
        $(this.mContainer).find('input[type="radio"][name="aperture-image-type"]').each(
            (_index, element) => {

                let aLabel = Op3dUtils.getElementInByAttr(this.mContainer, "for", element.id) as HTMLLabelElement;
                element.id += Op3dComponentBase.ID_ITERATOR++;
                aLabel.htmlFor = element.id

                this.mApertureImageType.push(element as HTMLInputElement);
                element.addEventListener('change', () => {
                    let aVal = (element as HTMLInputElement).value as eApertureImageType;
                    this._onChangePresetType(aVal);
                });
            });
    }
    //_____________________________________________________________________________________
    private _initPresetImages() {

        let aSinglePresetImage = this._getPart("single_preset_image", true);
        let aPresetParent = aSinglePresetImage.parentElement!;
        ViewUtils.clearElementsChildren(aPresetParent);


        for (let i = 0; i < UploadApertureFileForm.PRESET_IMAGES.length; i++) {
            const aURL = UploadApertureFileForm.PRESET_IMAGES[i];
            const aClonePresetImage = aSinglePresetImage.cloneNode(true) as HTMLElement;
            const aImg = (aClonePresetImage.children[0] as HTMLImageElement);
            aImg.src = aURL;

            let aTooltip = aURL.slice(aURL.lastIndexOf("/") + 1);
            aClonePresetImage.title = aTooltip;
            // aClonePresetImage.setAttribute("data-original-title", aTooltip);

            aPresetParent.appendChild(aClonePresetImage);
            // $(aClonePresetImage).tooltip();


            // $(this.mFileNameHeadline).tooltip();
            // aPresetParent.title = '';

            aClonePresetImage.addEventListener("click", () => this._onPresetImageClicked(aClonePresetImage, aImg));
        }
        // $(this.mContainer).find('[data-toggle="tooltip"]').tooltip();


    }
    //_____________________________________________________________________________________
    private _onPresetImageClicked(pCurrImage: HTMLElement, pImg: HTMLImageElement) {
        /**
         * @TODO:
         * save the image as the current "uploaded" image
         */
        $(this.mContainer).find('.single_preset_image').each(
            (_index, element) => {
                ViewUtils.setClassForElement(element as HTMLElement, "chosen", false);
            });

        if (this.mData!.size_to_compare != null &&
            (pImg.naturalHeight != this.mData!.size_to_compare.height ||
                pImg.naturalWidth != this.mData!.size_to_compare.width)) {
            this._showError(UploadApertureFileForm.ERROR_MESSAGES.IMAGE_SIZE_ERR);
            this.mCurrChosenPreset = undefined;
            ViewUtils.setElementDisabled(this.mImportBtn, true);
            return;
        }


        this.mCurrChosenPreset = pImg.src;
        ViewUtils.setElementDisabled(this.mImportBtn, false);
        ViewUtils.setClassForElement(pCurrImage as HTMLElement, "chosen", true);
    }
    //_____________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mTitle = this._getPart('title', true);
        this.mInput = this._getPart('file_input', true) as HTMLInputElement;
        this.mLabel = this._getPart('file-title');
        this.mWarningMessage = this._getPart("text-warning");
        this.mWarningDiv = this._getPart("red-error-message");
        this.mInput.addEventListener('change', () => this._validate(this.mLabel));

        this.mImportBtn = this._getPart('import-btn');
        this.mImportBtn.addEventListener('click', () => this._onImport());

        this._initPresetImages();
        this._initPresetRadio()
        this._onChangePresetType(eApertureImageType.APERTURE_FILE);
        // $(this.mContainer).tooltip();
        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip();

        this.mIsReady = true;
    }
    //_____________________________________________________________________________________
    private _getChosenType() {
        let aApertureImageType = this.mApertureImageType.find(item => item.checked === true)?.value as eApertureImageType;
        return aApertureImageType;
    }
    //_____________________________________________________________________________________
    private async _onImport() {
        let aChosenType = this._getChosenType();
        switch (aChosenType) {
            case eApertureImageType.APERTURE_FILE:
                this.mFunc(this.mInput.files![0], this.mResolution!);
                break;

            case eApertureImageType.PRESET_IMAGE:

                if (this.mCurrChosenPreset !== undefined) {
                    const response = await fetch(this.mCurrChosenPreset);
                    const blob = await response.blob();
                    let aName = (this.mCurrChosenPreset.slice(this.mCurrChosenPreset.lastIndexOf("/") + 1));

                    let aWidth, aHeight
                    const aImg = new Image();
                    aImg.onload = function () {
                        aWidth = aImg.width;
                        aHeight = aImg.height;

                        const aFile = new File([blob], aName, { type: blob.type });
                        this.mFunc(aFile as any, { width: aWidth, height: aHeight });
                    }.bind(this);

                    aImg.src = this.mCurrChosenPreset;
                }

                break;
        }

        this.close();
    }
    //_____________________________________________________________________________________
    protected _onOpen(pParserPormParams: iUploadApertureFileData): void {
        this.mData = pParserPormParams;
        this.mResolution = undefined;
        ViewUtils.setElementVisibilityByDNone(this.mWarningDiv, false);
        ViewUtils.setElementDisabled(this.mImportBtn, true);
        this.mFunc = pParserPormParams.func;
        this.mTitle.innerHTML = pParserPormParams.title;
        this.mInput.value = '';
        this.mLabel.innerHTML = 'Choose File';

        let aType = this._getChosenType();
        this._onChangePresetType(aType);


        if (pParserPormParams.acceptedSizeMb) {
            this.mMaxFileSizeMb = pParserPormParams.acceptedSizeMb * 1024 * 1024;   // 10 * 1024 * 1024     Mb
        }
    }
    //_____________________________________________________________________________________
}
