import { Color, Euler, LineSegments, Matrix, Matrix4, Mesh, Object3D, Vector3 } from "three/src/Three.js";
import { iHash, iScatteringVO, t2DArray, t3DArray } from "../_context/_interfaces/Interfaces";
import { iSurfaceBasicData, tVector3Array } from "../_utils/MatrixUtils";
import { Part } from "./Part";
import { eSmBaseShapeKind, iGratingData, iPhaseProfile, iSimulationReflectionItem } from "../simulation/SimulationContext";
import { iJSONPartOptions, iPartData } from "./_parts_assets/ExportToJSONInterfaces";
import { eAxisType, eDataPermission } from "../_context/Enums";
import { eBaseShape } from "../_context/OpticsContext";
import { iApertureDataOpticsVO, iParaxialLens, iSurfaceDeformation } from "../data/VO/OpticsVOInterfaces";
import { PartVO, iPartVOData } from "../data/VO/PartVO";
import { iBlackBoxData } from "./BlackBox/utils/BlackBoxUtils";
import { AxisObject3D } from "./_parts_assets/Axis";

export interface iFacesMeshInfo {
    colors?: Array<number>;
    colorsBase?: Array<number>;
    data?: iHash<any>;
    indexes?: { start: number; end: number; };
    name?: string;
    path: tVector3Array;
    points?: Array<number>;
}

export interface iFrameData {
    is_frame: boolean;

    /**
     * these two parameters are only when the surface is frame
     * the value will be index to the two connected surfaces
     */
    front_surface?: number;
    back_surface?: number;
}

/**
* Added ability to define phase profile of surface; 
* phase profile contains two polynomials (for S and P polarizations); 
*/
export interface iPhaseData {
    phase_profile_enabled?: boolean;
    phase_profile?: iPhaseProfile;
}

export interface iSolidJSON extends iBasicJsonData {
    faces?: Array<iFacesJSON>;
}

export interface iShapeJSON extends iBasicJsonData {
    solids?: Array<iSolidJSON>;
}

export interface iPartsIDJSON extends iBasicJsonData {
    subParts?: Array<iPartsIDJSON>;
    shapes?: Array<iShapeJSON>;
    number_id?: string;
}

export interface iBasicJsonData {
    name?: string;
    internal_id: string;
    axes?: Array<iAxisJsonData>;
    matrixWorld: iJSONMatrix4;
}

export interface iJSONMatrix4 {
    elements: Array<number>;
}

export interface iAxisJsonData extends iBasicJsonData {
    linkedOpticsId?: string;
    matrix?: iJSONMatrix4;
    type: eAxisType;
}

export interface iFacesJSON extends iBasicJsonData {
    data: iFaceData;
    isForSimulation?: boolean;
    num_of_indices?: number
}

export interface iJonesMatrix {
    A: t2DArray<number>;
    B: t2DArray<number>;
    C: t2DArray<number>;
    D: t2DArray<number>;
}

export interface iJonesMatrixData {
    is_jones_matrix?: boolean;
    /**
     * object representing Jones matrix
     * | A B |
     * | C D |
     * where A, B, C and D are complex numbers
     * for example, the Jones matrix for right circular polarizer is
     * 0.5 * | 1    i |
     *       | -i   1 |
     */
    jones_matrix?: iJonesMatrix;
}

export interface iFaceData extends iSurfaceBasicData {
    coatingID?: string;
    materialID?: string;
    inside_material?: string;
    outside_material?: string;

    reflectionData?: iSimulationReflectionItem;
    apertureData?: iApertureDataOpticsVO;
    gratingData?: iGratingData;
    faces?: Array<Object>
    scattringVO?: iScatteringVO;
    indexes?: Array<Object>;
    paraxialLensData?: iParaxialLens;
    phase_profile?: iPhaseProfile;
    jones_matrix?: iJonesMatrix;
    is_inside_blackbox?: boolean
}

export interface iVisualization {
    mesh: Mesh;
    emissive?: Color;
    opacity?: number;
}
export interface iVisualizationEdge {
    mesh: LineSegments;
    emissive?: Color;
    opacity?: number;
}

export interface iBasicData {
    internal_id: string;
    axes?: Array<iAxis>;

    useID?: boolean;
}

export interface iStartEnd {
    start: number;
    end: number;
}

export interface iFace extends iBasicData {
    name?: string;
    data?: iFaceData;
    visualization: iVisualization;


    originalName?: string;
    indexes?: Array<iFaceDataNEW>;
    isForSimulation?: boolean;
}

export interface iEdgeParams {
    startPoint?: t3DArray<number>;
    endPoint?: t3DArray<number>;
    center?: t3DArray<number>;
    radius?: number;
    majorRadius?: number;
    minorRadius?: number;
}

export interface iEdge extends iBasicData {
    data?: iEdgeParams;
    name?: string;
    originalName?: string;
    indexes?: {
        start: number,
        end: number
    };
}

export interface iOptomechanicsFaceData {
    coatingID?: string;
    reflectionData?: iSimulationReflectionItem;
    scattaringID?: string;
    scattringVO?: iScatteringVO;
    gratingData?: null;
    materialID?: string;
}

export interface iFaceBasicDataNEW {
    name: string;
    originalName?: string;
    path: Array<number>;
    indexes: {
        start: number;
        end: number;
    }
}

export interface iFaceDataNEW extends iFaceBasicDataNEW {
    points: Array<number>;
    colors: Array<number>;
    colorsBase: Array<number>;
    data?: iOptomechanicsFaceData;
}

export interface iAxis {
    type: eAxisType;
    object3D?: AxisObject3D;
    internal_id?: string;
    name?: string;
    original_name?: string;
    radius?: number;
    length?: number;
    position?: Vector3;
    rotation?: Euler;
    linkedOpticsId?: string;
    face?: string;
    shape?: eBaseShape;
    isCustom?: boolean;
}

export interface iShape extends iBasicData {
    object3D?: Object3D;
    edgesMesh?: LineSegments;
    vertexMesh?: Mesh;

    solids?: Array<iSolid>;
    name?: string;

    // vertices?: Array<iVertex>;
    edges?: Array<iEdge>;
}

export interface iSolid extends iBasicData {
    object3D?: Object3D;

    faces?: Array<iFace>;
    name?: string;

}


export interface iPart extends iBasicData {
    isBlackBox?: boolean,
    partVO?: PartVO;
    number_id?: string;
    facesMesh?: Mesh;

    object3D?: Object3D;

    shapes?: Array<iShape>;
    name?: string;

    data?: iPartData;

    subParts?: Array<iPart>;

    isOnList?: boolean;

    isAssembley?: boolean;
    JSONAssemblyData?: iJSONAssembly;
    JSONPartData?: iJSONPart;
}

export interface iJSONAssemblyPart {
    matrix: Matrix;
    name: string;
    index: number;
    data: Array<iJSONAssemblyPartData>;
}
export interface iJSONAssemblyPartData {
    path: Array<number>;
    index: number;
    actions: iJSONAssemblyPartActions
}
export interface iJSONAssemblyPartActions {
    color: Array<number>;
    start: number;
    end: number;
}

export interface iPartChange<T = any> {
    type: eChangesType;
    data: T;
};

export interface iBasicTranslation {
    deltaPos?: Vector3;
    rot?: Euler;
    // position: Vector3;
    // rotation: Euler;
};

export interface iColorChange {
    color: Array<number>;
    start: number;
    end: number;
    path: Array<number>;
};

export interface iOpticsAxis {
    position: Vector3;
    rotation: Euler;
    radius: number;
    length: number;
    face: string;
    shape: eBaseShape;
};

export interface iLaserAxis {
    position: Vector3;
    rotation: Euler;
};
export interface iCageAxis {
    position: Vector3;
    rotation: Euler;
};

export interface iPartMongoDB {
    permission: eDataPermission;
    number_id: string;
    name: string;
    info?: iPartVOData;
    changes?: string;
    url?: string;
    assembly_parts?: string;
    owner?: string;
    black_box_data?: iBlackBoxData
}

export interface iAssemblyMongoDB {
    number_id: string;
    matrix: Matrix4
}

export interface iJSONPartActions {
    color: Array<number>;
    start: number;
    end: number;
    path: Array<number>;
}

export interface iJSONAssembly {
    name: string;
    axis: iAxis;
    parts: Array<iJSONAssemblyPart>;
}

export interface iJSONPart {
    name: string;
    changes: {
        axis: iAxis,
        color: Array<iJSONPartActions>
    },
    currentPartName: string;
}

export interface iOptixData {
    faces: Array<number>;
    facesColor: Array<number>;
    facesData: Array<iFaceDataNEW>;
    edges: Array<Vector3>;
    edgesColor: Array<number>;
    edgesData: Array<iEdge>;
}

export interface iRefCS {
    cs: iAxis;
    refPart: Part;
    isCage?: boolean;
}
export interface iRotAction {
    cs: Object3D;
    axis: Vector3;
    angle: number;
};

export interface iArrayOfElementsParameters {
    kind: eSmBaseShapeKind
    radius_x: number;
    radius_y: number;
    half_width: number;
    half_height: number;
    sampling_x: number;
    sampling_y: number;
    sourceShapeData: iSurfaceDeformation[]
}

export interface iArrayOfElementsOptions {
    group_type: eGroupType;
    is_array: boolean;
    data?: iArrayOfElementsParameters;
}



export enum eGroupType {
    REGULAR,
    ARRAY_OF_ELEMENTS,
}
export interface iPartOptions extends iJSONPartOptions {
    static?: boolean;
    initialHeight?: number;
    addAxis?: boolean;
    isOptix?: boolean;
}

export enum eChangesType {
    COLOR,
    OPTICS_AXIS,
    BASIC_TRANSLATION,
    LASER_AXIS,
    ADD_POST,
    CAGE_AXIS
};

export enum ePartType {
    GENERAL,
    PARAXIAL_LENS,

    CATALOG_OPTICS,
    CAD_PART,
    DYNAMIC_PART,
    GROUP,
    BLACKBOX,
    CS
}

export interface iLabel {
    label: string;
    index?: number;
    shortLabel?: string;
}

export enum ePartDataType {
    LIGHT_SOURCE
}