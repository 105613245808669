import JSZip from "jszip";
import { saveAs } from 'file-saver';

export interface iZipCreationData {
    data: string,
    fileName: string,
    file_format: '.json' | '.opt'
}

export class Zip {
    //__________________________________________________________________________________________
    private static _getFile(pData: iZipCreationData) {
        let zip = new JSZip();
        let aFileName = pData.fileName + pData.file_format;
        zip.file(aFileName, pData.data);
        return zip;
    }
    //__________________________________________________________________________________________
    public static createAndDownloadOpt(pData: iZipCreationData) {

        let aZip = this._getFile(pData);
        aZip.generateAsync({ type: "blob" }).then((blob) => {
            saveAs(blob, pData.fileName + '.opt');
        });
    }
    //__________________________________________________________________________________________
    public static createAndDownloadZip(pData: iZipCreationData) {

        let aZip = this._getFile(pData);
        aZip.generateAsync({ type: "blob", compression: "DEFLATE" }).then((blob) => {
            saveAs(blob, pData.fileName + '.zip');
        });
    }
    //__________________________________________________________________________________________
    public static async getZip(pData: iZipCreationData) {
        let aZip = this._getFile(pData);
        let aZipFile = await aZip.generateAsync({ type: "blob", compression: "DEFLATE" });

        return aZipFile;
    }
    //__________________________________________________________________________________________
}
