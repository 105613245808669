import { eUnitType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Part } from "../../parts/Part";
import { PostBehavior } from "../../parts/behaviors/PostBehavior";
import { UnitHandler } from "../../units/UnitsHandler";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";

export class piPostSection extends PartInfoSection<Part> {

    private mPostHeight: NumberInputElement;
    private mHidePost: HTMLInputElement;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_post_section.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._initPostHeight();
        this._initHidePostCheckbox();
    }
    //__________________________________________________________________________________________
    private _initPostHeight() {
        this.mPostHeight = new NumberInputElement(this._getPart('post-height-numeric-field'), {
            toFixed: Op3dContext.USER_VO.userVO.parameters.simulation.digitsAfterDot,
            unitName: UnitHandler.shortSign,
            isGlobalToFixed: true,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: true
            }
        });

        let aStep = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 12.5 : 0.5;
        this.mPostHeight.setData({
            callback: (pValue: number) => this._onUpdatePostHeight(pValue),
            step: aStep
        });
    }
    //__________________________________________________________________________________________
    private _onUpdatePostHeight(pValue: number) {
        let aValue = (pValue / UnitHandler.presentedScale);
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;

        PostBehavior.updatePostHeight(aSelectedPart, aValue)

        //* SAVING
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _initHidePostCheckbox() {
        this.mHidePost = this._getPart('hide-post-checkbox') as HTMLInputElement;
        this.mHidePost.addEventListener('change', () => this._onChangeHidePostState());
    }
    //__________________________________________________________________________________________
    private _onChangeHidePostState() {
        let aShowPost = (false == this.mHidePost.checked);
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        PostBehavior.displayPost(aSelectedPart, aShowPost);

        this.mPostHeight.enable = (true == aShowPost);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pPart: Part): void {
        let aScale = UnitHandler.presentedScale;
        let aRange = PostBehavior.getRange(pPart);
        this.mPostHeight.setData({
            range: {
                max: (aRange.max * aScale),
                min: (aRange.min * aScale)
            }
        });

        let aCurrHeight = PostBehavior.getCurrentHeight(pPart);

        this.mHidePost.checked = !PostBehavior.isPostShown(pPart);
        this.mPostHeight.value = (aCurrHeight * aScale);
        this.mPostHeight.enable = !this.mHidePost.checked;
    }
    //__________________________________________________________________________________________
}
