import { eDataPermission, eStateToAnalysis, eScatteringModel, eCadType, eAxisType } from "../_context/Enums";
import { Box3, Euler, Intersection, LineBasicMaterial, LineSegments, Matrix4, Mesh, Object3D, Vector3 } from "three";
import { EventsContext } from "../_context/EventsContext";
import { LabelHandler } from "../_context/LabelHandler";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { Group as THREEGroup } from "three";

import { iHash, iScatteringVO, iClientPoint, iSize, iPartParams, iAxisPath } from "../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import {
    iSimulationReflectionItem, iSMPartsData, iSmSystemFile, iSmShapeData, iSmMaterialData,
    iSmGeometryData, iSmGratingData, iGratingGroove, iSmSurfaceData, eSmMaskKind, iSmScatteringData,
    iSimulationGeometryData, eSmGeometryType, iSMCSParams, tSystemFileEntry, eSmRaysKind, eSmBaseShapeKind
} from "../simulation/SimulationContext";

import {
    iJSONPartOptions, iAnalysisData, iOptixPartJSON, iBehaviorsJSON, iRefCSJSON
} from "./_parts_assets/ExportToJSONInterfaces";

import { EventManager } from "../../oc/events/EventManager";
import { CSS2DLabel, eLabelType, iCSS2DLabelParams } from "../scene/CSS2DLabel";
import { PartVO } from "../data/VO/PartVO";
import { SceneContext } from "../scene/SceneContext";
import { eClickMode } from "../ui/_globals/PartsEventsHandler";
import { AnalysisPortal } from "../ui/analysis/AnalysisPortal";
import { OptixPartDisplayer } from "./_parts_assets/OptixPartDisplayer";
import { Button3D, iEventData, iOP3DButton } from "./base_3d/Button3D";
import { Behavior } from "./behaviors/Behavior";
import { eBehavior, iLaserBehaviorContext } from "./behaviors/BehaviorContext";
import { CageBehavior } from "./behaviors/CageBehavior";
import { CustomDetectorBehavior } from "./behaviors/CustomDetectorBehavior";
import { MovementBehavior } from "./behaviors/MovementBehavior";
import { PostBehavior } from "./behaviors/PostBehavior";
import { Strings } from "../_context/Strings";
import { OpticsDataLoader } from "../data/data_loader/OpticsDataLoader";
import { OptImporter } from "../setups/opt/OptImporter";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { iSimulationRaysOptions } from "../simulation/SimulationRunner";
import { Group } from "../ui/forms/Group";
import { BehaviorsFactory } from "./behaviors/BehaviorsFactory";
import { ServerContext } from "../server/ServerContext";
import { DataUtils } from "../_utils/DataUtils";
import { EdgesGeometry } from "three/src/geometries/EdgesGeometry.js";
import { eCoatingTypes } from "../ui/forms/optics/esCoating";
import { QuickViewHandler } from "../ui/analysis/QuickViewHandler";
import { WarningPopup } from "../ui/forms/WarningPopup";
import { LaserBehavior } from "./behaviors/LaserBehavior";
import {
    iArrayOfElementsOptions, iPartOptions, iPart, iAxis, iRefCS, iLabel, ePartType, eGroupType,
    iFace, iFacesJSON, iAxisJsonData, iSolidJSON, iSolid, iShapeJSON, iShape, iPartsIDJSON,
    iFaceData, iFaceDataNEW
} from "./PartInterfaces";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { GaussianBeamTable } from "../ui/analysis/GaussianBeamTable";

export class Part {


    public isDeprecated: boolean = false;

    private mGroupData: iArrayOfElementsOptions = { is_array: false, group_type: undefined };
    private mPartOptions: iPartOptions;
    protected mObject3D: Object3D;
    protected mBehaviors: iHash<Behavior> = {};
    private mInternalID: string = Op3dUtils.idGenerator();
    private mID: string;
    private mOpticalPartType: eCadType;
    private mOpticalData: any;
    protected mPart: iPart;
    /**
     * Working Local Coordinate System.
     */
    private mWorkingLCS: iAxis;

    /**
     * Referance Coordinate systems.
     */
    private mRefCS: iRefCS;

    /**
     * Analysis data of the part
     */
    private mAnalysisOptions: iAnalysisData[];

    private mMoveFunction: (pPoint: iClientPoint) => void;

    // /**
    //  * key is ePartDataType
    //  */
    // private mBehaviorData: iNumericKeyHash<tPartDataTypeStruct> = {}
    private mLinkedParts: Array<Part>;

    private mRefRotation: Euler = new Euler();
    private mRefPosition: Vector3 = new Vector3();

    private mWireFrame: Object3D;

    private mLabel: iLabel;
    private mLabelObject: CSS2DLabel;
    public mMeshIndex: number = 0;

    private mPowerLabelsHash: iHash<string> = {};
    private mLockedTransform: boolean = false;
    private mNumberID: string;
    private mParaxialLensData: iOpticsVO;
    //__________________________________________________________________________________________
    constructor(pPartParams?: iPartParams) {
        let aID: string, aNumberID: string, aOptions: iPartOptions;
        if (undefined !== pPartParams) {
            aOptions = pPartParams.options;
            aID = pPartParams.id;
            aNumberID = pPartParams.number_id;
        }

        this.mNumberID = aNumberID;
        this._setPartOptions(aOptions);

        this._init(aID);
        this._initPartLabel(aID);
        this._addOpticalProperties();
    }
    //__________________________________________________________________________________________
    public get numberID() {
        return this.mNumberID;
    }
    //__________________________________________________________________________________________
    public getLabel() {
        return this.mLabel;
    }
    //__________________________________________________________________________________________
    public getIndexedLabel(pIsHtml: boolean) {
        if (null == this.mLabel) {
            return null;
        }

        let aLabel = this.mLabel.label;
        let aShortLabel = this.getShortLabel();

        let aPrefix: string;
        if (pIsHtml) {
            aPrefix = (null != aShortLabel) ?
                '<strong>' + (aShortLabel + ': ') + '</strong>' : '';
        } else {
            aPrefix = (null != aShortLabel) ? (aShortLabel + ': ') : '';
        }

        let aSuffix = '';

        if (true == this.mPart.isAssembley) {
            aSuffix += ' assembley';
        }

        return (aPrefix + aLabel + aSuffix);
    }
    //______________________________________________________________________________________________
    public static async unlinkAllPartsAndRemove(pPartToClear: Part) {
        while (pPartToClear.mLinkedParts.length > 0) {
            let aPart = pPartToClear.mLinkedParts.pop()
            pPartToClear.removeFromLinkedParts(aPart);
            aPart.clearRef();
            await Op3dContext.PARTS_MANAGER.deletePart(aPart);
        }
    }
    //______________________________________________________________________________________________
    public async removeArrayOfElementsGroup() {
        if (ePartType.GROUP === this.mPartOptions.type) {
            await Part.unlinkAllPartsAndRemove(this);
            await Op3dContext.PARTS_MANAGER.deletePart(this);
        }
    }
    //______________________________________________________________________________________________
    public returnOpticsFromPart() {
        const optixAxis = this.getAxes().find(axis => axis.linkedOpticsId != null)
        if (optixAxis == null) {
            return undefined
        }
        const linkedOptics = this.linkedParts.find(part => part.numberID === optixAxis.linkedOpticsId)
        return linkedOptics
    }
    //______________________________________________________________________________________________
    public async setArrayOptions(pArrayData: iArrayOfElementsOptions) {
        let aLaserBehavior = this.mBehaviors[eBehavior.LASER_BEHAVIOR] as LaserBehavior;
        if (aLaserBehavior !== undefined) {
            aLaserBehavior.updateArrayOfSources(this, pArrayData);
            OptixPartDisplayer.highlightObject(this);
            return;
        }

        if (this.mGroupData.group_type === eGroupType.ARRAY_OF_ELEMENTS) {

            let aOriginal = this.mLinkedParts.shift();
            this.removeFromLinkedParts(aOriginal);
            aOriginal.clearRef();

            while (this.mLinkedParts.length > 0) {
                await Part.unlinkAllPartsAndRemove(this);
            }

            if (pArrayData.is_array === false) {

                await Op3dContext.PARTS_MANAGER.deletePart(this);
                Op3dContext.PARTS_MANAGER.setSelectedPart(aOriginal)
                Op3dContext.PARTS_MANAGER.updatePartsList(true);

            } else {
                await this._addArrayOfElements(pArrayData, aOriginal);
                Op3dContext.PARTS_MANAGER.deletePart(this);
            }
        }

        else {
            await this._addArrayOfElements(pArrayData, this);
        }

        await Op3dContext.PART_INFO.open();
    }
    //__________________________________________________________________________________________
    private async _addArrayOfElements(pOpts: iArrayOfElementsOptions, pPart: Part) {
        let aGroupRefPart = new Part({
            id: "Array of elements",
            options: {
                type: ePartType.GROUP
            }
        });
        aGroupRefPart.groupData = pOpts;

        const aSize: iSize = pOpts.data.kind === eSmBaseShapeKind.ELLIPSE ?
            { width: pOpts.data.radius_x, height: pOpts.data.radius_y } :
            { width: pOpts.data.half_width, height: pOpts.data.half_height }

        const aCountX = Math.floor(((aSize.width * 2) / pOpts.data.sampling_x));
        const aCountY = Math.floor(((aSize.height * 2) / pOpts.data.sampling_y));

        const aItems = new Array<{

            pos: Vector3,
            part: Part
        }>();

        const aNetoSize = Op3dUtils.getNetoItemSize(pPart.visibleObj, 5);
        const aDeltaX = aNetoSize.x / 2;
        const aDeltay = aNetoSize.y / 2;

        for (let i = 0; i < aCountY; i++) {
            for (let j = 0; j < aCountX; j++) {

                if (i == 0 && j == 0) {
                    let aPos = new Vector3(aDeltaX, aDeltay, 0)
                    aItems.push({ part: pPart, pos: aPos });
                    continue;
                }

                let aPos = new Vector3((j * pOpts.data.sampling_x), (i * pOpts.data.sampling_y), 0);
                let aClone: Part = await Op3dContext.PARTS_MANAGER.duplicatePart({
                    part: pPart,
                    isSingleDuplicate: false,
                    addToHistory: false
                });

                aClone.setPartLabel(pPart.getLabel().label);
                aItems.push({ part: aClone, pos: aPos });
                aClone.setRefrence({ cs: pPart.workingLCS, refPart: pPart });
                MovementBehavior.transformPart(aClone, aPos, new Euler());
            }
        }

        let aGroup = new THREEGroup();
        for (let data of aItems) {
            if (ePartType.GROUP !== data.part.partOptions.type) {
                data.part.clearRef()
                if (data.part.linkedParts != null) {
                    let aLinkedParts = [...data.part.linkedParts]
                    for (let i = 0; i < aLinkedParts.length; i++) {
                        data.part.removeFromLinkedParts(aLinkedParts[i])
                        aLinkedParts[i].clearRef()
                    }
                }
            }
            aGroup.add(data.part.visibleObj.clone());
            data.part.setRefrence({
                cs: aGroupRefPart.iPart.axes[0],
                refPart: aGroupRefPart
            });
        }

        let aBox = new Box3()
        aBox.setFromObject(aGroup)
        let aCenter = aBox.getCenter(new Vector3)

        aGroupRefPart.visibleObj.position.copy(aCenter.clone())

        Op3dContext.PARTS_MANAGER.updatePartsList(true);
        Op3dContext.PARTS_MANAGER.setSelectedPart(aGroupRefPart);
    }
    //__________________________________________________________________________________________
    public getShortLabel() {
        if ((null == this.mLabel) || (null == this.mLabel.shortLabel) ||
            (null == this.mLabel.index)) {
            return null;
        }

        return (this.mLabel.shortLabel + this.mLabel.index);
    }
    //__________________________________________________________________________________________
    private _initPartLabel(pID?: string) {
        if (null == pID) {
            return;
        }

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pID);
        let aPartType = this.mPartOptions.type;
        if (ePartType.GROUP === this.mPartOptions.type) {
            this.mLabel = {
                label: pID
            };
        }

        if ((undefined === aPartVO) && (ePartType.PARAXIAL_LENS !== aPartType) &&
            (ePartType.CS !== aPartType) && (ePartType.DYNAMIC_PART !== aPartType)) {
            return;
        }

        let aLabel = (undefined !== aPartVO) ? aPartVO.label : pID;
        let aShortLabel: string;

        switch (aPartType) {
            case ePartType.PARAXIAL_LENS:
                aShortLabel = 'PL';
                break;
            case ePartType.CS:
                aShortLabel = 'CS';
                break;
            case ePartType.DYNAMIC_PART:
                if ((undefined !== aPartVO) && (undefined !== aPartVO.laserData)) {
                    aShortLabel = 'LS';
                } else {
                    aShortLabel = 'D';
                }
                break;
            case ePartType.BLACKBOX:
                aLabel = aPartVO.name;
                break;
            default:
                break;
        }

        this.mLabel = {
            label: aLabel
        };

        let aIndex = LabelHandler.getPartIndex(this, aPartVO);
        if ((undefined !== aShortLabel) && (null !== aIndex)) {
            this.mLabel.shortLabel = aShortLabel;
            this.mLabel.index = aIndex;
        }

        this._updateLabelObject();
    }
    //__________________________________________________________________________________________
    public setPartLabel(pLabel: string, pTriggerChange: boolean = false) {
        if (true == pTriggerChange) {
            Op3dContext.SCENE_HISTORY.addToHistory();
        }
        if (null == this.mLabel) {
            this.mLabel = {
                label: pLabel
            };
        } else {
            this.mLabel.label = pLabel;
        }

        this._updateLabelObject();

        if (true == pTriggerChange) {
            Op3dContext.SCENE_HISTORY.saveScene();
            Op3dContext.PART_INFO.update();
        }

        AnalysisPortal.instance.updateLabels();

    }
    //__________________________________________________________________________________________
    private _updateLabelObject(pHardUpdate: boolean = false) {
        if ((null == this.mLabel) || (ePartType.GROUP === this.mPartOptions.type) ||
            (true == this.mPartOptions.static)) {
            return;
        }
        if (pHardUpdate === true) {
            this.mLabelObject.removeFromParent(this)
            this.mLabelObject.removeLabelFromType(eLabelType.LABEL);
            this.mLabelObject = null;
        }
        if (null == this.mLabelObject) {
            this.mLabelObject = new CSS2DLabel(this);
        }

        let aCSS2DLabelParams: iCSS2DLabelParams = {
            label: this.mLabel.label,
            type: eLabelType.LABEL
        };

        this.mLabelObject.update(this, aCSS2DLabelParams);
    }
    //__________________________________________________________________________________________
    public resetPowers() {
        this.mPowerLabelsHash = {};
        this.mLabelObject.removeLabelFromType(eLabelType.POWER);
    }
    //__________________________________________________________________________________________
    public updateTotalPowerLabel(pFaceID: string, pTotalPower: number) {
        let aFace = this.getFaces().find((face) => face.internal_id == pFaceID);
        if (null == aFace) {
            return;
        }

        if (null == this.mLabelObject) {
            this.mLabelObject = new CSS2DLabel(this);
        }

        let aFaceName = aFace.name;

        let aAccuracy = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        let aFixedPower = OP3DMathUtils.toFixed(pTotalPower, aAccuracy);
        this.mPowerLabelsHash[pFaceID] = `P(${aFaceName}) = ${aFixedPower}W`;

        let aLabel = '';
        for (let item in this.mPowerLabelsHash) {
            let aDiv = '<div>' + this.mPowerLabelsHash[item] + '</div>';
            aLabel += aDiv;
        }

        let aCSS2DLabelParams: iCSS2DLabelParams = {
            label: aLabel,
            type: eLabelType.POWER,
            options: {
                backgroundColor: { r: 255, g: 255, b: 255, a: 0.5 },
                border: { style: 'solid', color: { r: 255, g: 255, b: 255, a: 0.7 }, thickness: 1 },
                fontColor: { r: 0, g: 0, b: 0, a: 1 }
            }
        };

        this.mLabelObject.update(this, aCSS2DLabelParams);
    }
    //__________________________________________________________________________________________
    public setLabelIndex(pIndex: number) {
        this.mLabel.index = pIndex;
    }
    //__________________________________________________________________________________________
    public get labelVisiblity() {
        if (null == this.mLabelObject) {
            return false;
        }

        const aResult = this.mLabelObject.isVisible(eLabelType.LABEL);
        return aResult;
    }
    //__________________________________________________________________________________________
    public get groupData() {
        return this.mGroupData;
    }
    //__________________________________________________________________________________________
    public set groupData(pGroupData: iArrayOfElementsOptions) {
        this.mGroupData = pGroupData
    }
    //__________________________________________________________________________________________
    public set paraxialLensData(pOpticsVO: iOpticsVO) {
        this.mParaxialLensData = pOpticsVO;
    }
    //__________________________________________________________________________________________
    public get paraxialLensData() {
        return this.mParaxialLensData;
    }
    //__________________________________________________________________________________________
    public set labelVisiblity(pVal: boolean) {
        if (null == this.mLabelObject) {
            return;
        }
        const aParentExists = this.mLabelObject?.mainParentExists();
        if (aParentExists === false) {
            this._updateLabelObject(true);
        }
        this.mLabelObject.setLabelVisibility(eLabelType.LABEL, pVal);
    }
    //__________________________________________________________________________________________
    public setLabelVisiblity(pType: eLabelType, pVal: boolean) {
        if (null == this.mLabelObject) {
            return;
        }

        this.mLabelObject.setLabelVisibility(pType, pVal);
    }
    //__________________________________________________________________________________________
    public get powerReadingVisiblity() {
        if (null == this.mLabelObject) {
            return false;
        }

        return this.mLabelObject.isVisible(eLabelType.POWER);
    }
    //__________________________________________________________________________________________
    public get hasPowerReading() {
        return this.mLabelObject.isExist(eLabelType.POWER)
    }
    //__________________________________________________________________________________________
    public set powerReadingVisiblity(pVal: boolean) {
        if (null == this.mLabelObject) {
            return;
        }

        this.mLabelObject.setLabelVisibility(eLabelType.POWER, pVal);
    }
    //__________________________________________________________________________________________
    public setWireframeVisibility(pToShow: boolean) {
        this._removeWireframe();

        if (true == pToShow) {
            this._addWireframe();
        }
    }
    //__________________________________________________________________________________________
    private _removeWireframe() {
        if ((null != this.mWireFrame) && (null != this.mWireFrame.parent)) {
            this.mWireFrame.parent.remove(this.mWireFrame);
        }

        this.mWireFrame = null;
    }
    //__________________________________________________________________________________________
    private _addWireframe() {
        this.mWireFrame = new Object3D();
        let aObject3D = Op3dUtils.cloneObject3D(this.mObject3D);

        aObject3D.traverse((object) => {
            if ((object instanceof Mesh) && (true != object.userData.isAxisMesh)) {
                let aMesh = object;
                let aEdgesGeometry = new EdgesGeometry((aMesh as any).geometry.clone(), 90);
                let aEdgesMaterial = new LineBasicMaterial({ color: 0 });
                let aLineSegment = new LineSegments(aEdgesGeometry, aEdgesMaterial);

                aLineSegment.rotation.copy(aMesh.rotation);
                aLineSegment.position.copy(aMesh.position);

                this.mWireFrame.add(aLineSegment);
            }
        });

        this.mPart.object3D.add(this.mWireFrame);
    }
    //__________________________________________________________________________________________
    /**
     * @description Calculate the matrix that transform ref_cs matrix to lcs matrix.
     *              From that - we can get relative rotation and transition.
     */
    //__________________________________________________________________________________________
    private _calcRelativePose() {
        this.mRefRotation = new Euler();
        this.mRefPosition = new Vector3();

        let aWRot = new Matrix4().copy((this.mWorkingLCS.object3D as any).matrixWorld);
        let aRefRot = new Matrix4().copy((this.mRefCS.cs.object3D as any).matrixWorld);
        let aRelMat = new Matrix4().multiplyMatrices(aWRot.invert(), aRefRot).invert();
        let aRelRot = new Matrix4().extractRotation(aRelMat);

        this.mRefRotation.setFromRotationMatrix(aRelRot);
        this.mRefPosition.setFromMatrixPosition(aRelMat);
    }
    //__________________________________________________________________________________________
    public get refRotation() {
        return this.mRefRotation;
    }
    //__________________________________________________________________________________________
    public set refRotation(pRot: Euler) {
        this.mRefRotation = pRot;
    }
    //__________________________________________________________________________________________
    public get linkedParts() {
        return this.mLinkedParts;
    }
    //__________________________________________________________________________________________
    public addToLinkedParts(pPart: Part) {
        if (null == this.mLinkedParts) {
            this.mLinkedParts = new Array<Part>();
        }

        if (-1 == this.mLinkedParts.indexOf(pPart)) {
            this.mLinkedParts.push(pPart);
        }
    }
    //__________________________________________________________________________________________
    public removeFromLinkedParts(pPart: Part) {
        if (null == this.mLinkedParts) {
            return;
        }

        let aIndex = this.mLinkedParts.indexOf(pPart);
        if (-1 == aIndex) {
            return;
        }

        this.mLinkedParts.splice(aIndex, 1);
        if (0 == this.mLinkedParts.length) {
            this.mLinkedParts = null;
        }
    }
    //__________________________________________________________________________________________
    public get partOptions() {
        return this.mPartOptions
    }
    //__________________________________________________________________________________________
    public get analysisOptions() {
        return this.mAnalysisOptions;
    }
    //__________________________________________________________________________________________
    public removeAllAnalysisOptions() {
        if (null == this.mAnalysisOptions) {
            return;
        }

        this.mAnalysisOptions.splice(0);
    }
    //__________________________________________________________________________________________
    public removeAnalysisOption(pFaceId: string) {
        let aItemIndex = this.mAnalysisOptions.findIndex(item => item.faceId == pFaceId);
        if (aItemIndex != -1) {
            let aFastAnalysis = this.mAnalysisOptions[aItemIndex].fast;
            if (null != aFastAnalysis) {
                for (let i = 0, l = aFastAnalysis.length; i < l; i++) {
                    QuickViewHandler.instance.onDeleteQV(aFastAnalysis[i].id);
                }
            }

            this.mAnalysisOptions.splice(aItemIndex, 1);
        }

        Op3dContext.PARTS_MANAGER.updatePartsList(false);
    }
    //__________________________________________________________________________________________
    public addAnalysisOption(pAnalysisData: iAnalysisData) {
        if (this.mAnalysisOptions == null) {
            this.mAnalysisOptions = [];
        }
        let aItemIndex = this.mAnalysisOptions.findIndex(item => item.faceId == pAnalysisData.faceId);
        if (aItemIndex == -1) {
            // this surface doesnt exists
            this.mAnalysisOptions.push(pAnalysisData);
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
                eStateToAnalysis.FROM_SCENE);

        } else {
            // surface already exists
            this.mAnalysisOptions.splice(aItemIndex, 1, pAnalysisData);
        }

        Op3dContext.PARTS_MANAGER.updatePartsList(false);
    }
    //__________________________________________________________________________________________
    public set refCS(pRefCS: iRefCS) {
        this.mRefCS = pRefCS;
    }
    //__________________________________________________________________________________________
    public get refCS() {
        return this.mRefCS;
    }
    //__________________________________________________________________________________________
    public clearRef() {
        if ((null == this.mRefCS) || (Op3dContext.GCS == this.mRefCS.refPart)) {
            return;
        }

        this.mRefCS.refPart.removeFromLinkedParts(this);
        this.mRefCS = null;

        this._setGCSasRef();
    }
    //__________________________________________________________________________________________
    public setRefrence(pRefCS: iRefCS) {
        this.clearRef();

        if (null == pRefCS) {
            return;
        }

        if ((null != this.mRefCS) && (pRefCS.cs.internal_id == this.mRefCS.cs.internal_id)) {
            return;
        }

        this.mRefCS = pRefCS;
        pRefCS.refPart.addToLinkedParts(this);
        this._calcRelativePose();
    }
    //__________________________________________________________________________________________
    public setWorkingCS(pAxis: iAxis, pUpdate: boolean = true) {
        this.mWorkingLCS = pAxis;
        this._calcRelativePose();
        if (true == pUpdate) {
            Op3dContext.DIV_CONTROLLER.updatePartInfo();
            this.update();
        }
    }
    //__________________________________________________________________________________________
    public get id() {
        return this.mID;
    }
    //__________________________________________________________________________________________
    public get subParts() {
        return this.mPart.subParts;
    }
    //__________________________________________________________________________________________
    public getSubpartsByName(pPartName: string) {
        if (null == this.mPart.subParts) {
            return null;
        }

        let aSubParts: Array<iPart> = []

        for (let i = 0; i < this.mPart.subParts.length; i++) {
            if (pPartName == this.mPart.subParts[i].name) {
                aSubParts.push(this.mPart.subParts[i])
            }
        }

        return aSubParts

    }
    //__________________________________________________________________________________________
    public getSubpartByName(pPartName: string) {
        if (null == this.mPart.subParts) {
            return null;
        }

        for (let i = 0; i < this.mPart.subParts.length; i++) {
            if (pPartName == this.mPart.subParts[i].name) {
                return this.mPart.subParts[i];
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public add(pPart: iPart): Part;
    //__________________________________________________________________________________________
    public add(pParts: Array<iPart>): Part;
    //__________________________________________________________________________________________
    public add(pParts: iPart | Array<iPart>): Part;
    //__________________________________________________________________________________________
    public add(pParts: iPart | Array<iPart>) {
        if (null == this.mPart.subParts) {
            this.mPart.subParts = new Array<iPart>();
        }

        if (pParts instanceof Array) {
            for (let i = 0; i < pParts.length; i++) {
                this._addOnePart(pParts[i]);
            }

        } else {
            this._addOnePart(pParts);
        }

        this._updateBehaviors();

        if (null == this.mWorkingLCS) {
            let aAxes = this.getAxes();

            if (null != aAxes[0]) {
                this.mWorkingLCS = aAxes[0];
            }
        }

        this.updatePart();
        return this;
    }
    //__________________________________________________________________________________________
    public updatePart() {
        this._updateEventListeners();
        this._updateAxesScale();
    }
    //__________________________________________________________________________________________
    private _updateEventListeners() {
        switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
            case eClickMode.GROUP:
            case eClickMode.MEASUREMENT:
            case eClickMode.CS:
                if (false == this.mPartOptions.static) {
                    this._addListeners();
                    Op3dContext.PARTS_MANAGER.isToShowEdges(true)
                    Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(Op3dContext.PARTS_EVENTS_HANDLER.mode)
                }
                break;
            case eClickMode.BASE:
                if (false == this.mPartOptions.static) {
                    this._addListeners();
                }
                break;
            case eClickMode.LCS:
            case eClickMode.LCS_REF:
                this._addListeners();
                break;
        }

    }
    //__________________________________________________________________________________________
    public clickEvents(pIsActive: boolean) {
        if (pIsActive == true) {
            this._addListeners()
        } else {
            Button3D.removeEventListers(this);
        }
    }
    //__________________________________________________________________________________________
    private _addListeners() {
        let aListenersArray = new Array<iOP3DButton>();
        aListenersArray.push({
            type: 'mousedown',
            func: () => { Op3dContext.PARTS_MANAGER.mouseDownPart = this }
        });

        aListenersArray.push({
            type: 'dblclick',
            func: () => { Op3dContext.PARTS_MANAGER.mouseDownPart = this }
        });

        aListenersArray.push({
            type: 'mouseup',
            func: (pEventData: iEventData, _pEvent: iClientPoint, pFaceIndex?: Intersection) =>
                Op3dContext.PARTS_EVENTS_HANDLER.onMouseUp(this, pEventData, pFaceIndex)
        });

        aListenersArray.push({
            type: 'mouseover',
            func: (pEventData: iEventData, pEvent: iClientPoint, _pFaceIndex?: Intersection) =>
                Op3dContext.PARTS_EVENTS_HANDLER.onMouseOver(this, pEventData, pEvent)
        });

        aListenersArray.push({
            type: 'mouseout',
            func: (pEventData: iEventData, pEvent: iClientPoint) =>
                Op3dContext.PARTS_EVENTS_HANDLER.onMouseOut(this, pEventData, pEvent)
        });

        Button3D.removeEventListers(this);
        Button3D.addEventListeners(this.mPart, aListenersArray);
    }
    //__________________________________________________________________________________________
    private _addOnePart(pPart: iPart) {
        this.mPart.subParts.push(pPart);

        this._draw(pPart.object3D);
    }
    //__________________________________________________________________________________________
    private async _init(pID: string) {

        this.mID = pID;
        this.mObject3D = new Object3D();

        let aPartsParent = new Object3D();
        aPartsParent.name = 'parts_parent';
        this.mObject3D.add(aPartsParent);

        let aIPartName = this.mID;
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pID);
        if ((null != aPartVO) && (eDataPermission.PRIVATE == aPartVO.permission)) {
            aIPartName = aPartVO.name;
        }

        this.mPart = {
            object3D: aPartsParent,
            name: aIPartName,
            internal_id: Op3dUtils.idGenerator()
        };

        if (true == this.mPartOptions.addAxis) {
            let aIsCS = (ePartType.CS === this.mPartOptions.type);
            let aMainAxes = SceneContext.OP3D_SCENE.getAxisModel(aIsCS);
            this.mObject3D.add(aMainAxes);

            this.mPart.axes = [{
                object3D: aMainAxes,
                internal_id: Op3dUtils.idGenerator(),
                type: eAxisType.GENERAL
            }];

            this.mWorkingLCS = this.mPart.axes[0];
        }

        if (false == this.mPartOptions.static) {
            this._setGCSasRef();
        }

        this.mObject3D.position.setY(this.mPartOptions.initialHeight);
        Op3dContext.PARTS_MANAGER.addPart(this);
        this._initBasicBehaviors();
        this.updatePart();

        EventManager.addEventListener(EventsContext.MODE_CHANGED,
            () => this.updatePart(), this);

    }
    //__________________________________________________________________________________________
    private _addOpticalProperties() {
        let aPartVO: PartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mID);
        if (aPartVO && null != aPartVO.opticalData) {
            this.mOpticalPartType = aPartVO.opticalPartType;
            this.mOpticalData = aPartVO.opticalData;
            this.getFaces().forEach(face => {
                face.data = this.mOpticalData;
            })
        }
    }
    //__________________________________________________________________________________________
    private _setGCSasRef() {
        this.setRefrence({
            refPart: Op3dContext.GCS,
            cs: Op3dContext.GCS.getAxes()[0]
        });
    }
    //______________________________________________________________________________________________
    public get locked(): boolean {
        return this.mLockedTransform;
    }
    //______________________________________________________________________________________________
    public set locked(pVal: boolean) {
        this.mLockedTransform = pVal;

        if (this.mLockedTransform === true) {
            this.mMoveFunction = (_pPoint: iClientPoint) => {
                new WarningPopup("Move & rotate is locked").open();
            };

        } else if (this.mPartOptions.static !== true) {

            this.mMoveFunction = (pPoint: iClientPoint) => {
                MovementBehavior.moveOptixPart(this, pPoint);
            }
        }
    }
    //__________________________________________________________________________________________
    private _initBasicBehaviors() {
        if (false == this.mPartOptions.static) {
            this.mMoveFunction = (pPoint: iClientPoint) => {

                MovementBehavior.moveOptixPart(this, pPoint);
            }

            this.mBehaviors[eBehavior.SELECT_BEHAVIOR] =
                BehaviorsFactory.behaviorsFactory(this, eBehavior.SELECT_BEHAVIOR);

        } else {
            this.mMoveFunction = (_pPoint: iClientPoint) => { };
        }
    }
    //__________________________________________________________________________________________
    private _setPartOptions(pPartOptions: iPartOptions) {
        if (null == pPartOptions) {
            pPartOptions = {};
        }

        this.mPartOptions = {
            initialHeight: (undefined !== pPartOptions.initialHeight) ? pPartOptions.initialHeight :
                Op3dContext.OPTICAL_AXIS_HEIGHT,
            addAxis: (undefined !== pPartOptions.addAxis) ? pPartOptions.addAxis : true,
            static: (undefined !== pPartOptions.static) ? pPartOptions.static : false,
            type: (undefined !== pPartOptions.type) ? pPartOptions.type : ePartType.GENERAL
        };
    }
    //__________________________________________________________________________________________
    /**
     * @description
     * happens only on creation of part
     */
    //__________________________________________________________________________________________
    public getOpticsFaces() {
        return this.getFaces().filter((face) =>
            ((null != face.data) && (null != face.data.simGeoData)));
    }
    //__________________________________________________________________________________________

    public getFaceByID(pInternalID: string) {
        let aFaces = this.getFaces();
        for (let i = 0; i < aFaces.length; i++) {
            if (pInternalID == aFaces[i].internal_id) {
                return aFaces[i];
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public getFaces() {
        let aFaces = new Array<iFace>();
        this._getFacesRec(this.mPart, aFaces);
        return aFaces;
    }
    //__________________________________________________________________________________________
    public getSolidsFacesByNumber(pSolidNumber: number) {
        let aFaces = new Array<iFace>();
        this._getFacesRec(this.mPart, aFaces);
        let aSolidsFaces = aFaces[0].indexes.filter(face => face.path[1] === pSolidNumber);
        return aSolidsFaces;
    }
    //__________________________________________________________________________________________
    private _getFacesRec(pPart: iPart, pFaces: Array<iFace>) {
        let aShapes = pPart.shapes;
        if (null != aShapes) {
            for (let i = 0; i < aShapes.length; i++) {
                let aSolids = aShapes[i].solids;
                if (null != aSolids) {
                    for (let j = 0; j < aSolids.length; j++) {
                        if (null != aSolids[j].faces) {
                            pFaces.push(...aSolids[j].faces)
                        }
                    }
                }
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this._getFacesRec(pPart.subParts[i], pFaces);
            }
        }
    }
    //__________________________________________________________________________________________
    public getOpticsAxes() {
        return this.getAxes().find((axis) => (eAxisType.OPTICS == axis.type));
    }
    //______________________________________________________________________________________________
    private _getPartAxisPath(pPart: iPart, pAxisInternalID: string) {
        let aAxisPath: iAxisPath;

        let aShapes = pPart.shapes;
        if (undefined !== aShapes) {
            for (let sh = 0, sh_l = aShapes.length; sh < sh_l; sh++) {
                let aShapesAxes = aShapes[sh].axes;
                if (undefined !== aShapesAxes) {
                    for (let i = 0, l = aShapesAxes.length; i < l; i++) {
                        if (pAxisInternalID === aShapesAxes[i].internal_id) {
                            aAxisPath = {
                                axis: i,
                                shape: sh
                            };

                            return aAxisPath;
                        }
                    }
                }

                let aSolids = aShapes[sh].solids;
                if (undefined !== aSolids) {
                    for (let so = 0, so_l = aSolids.length; so < so_l; so++) {
                        let aSolidsAxes = aSolids[so].axes;
                        if (undefined !== aSolidsAxes) {
                            for (let i = 0, l = aSolidsAxes.length; i < l; i++) {
                                if (pAxisInternalID === aSolidsAxes[i].internal_id) {
                                    aAxisPath = {
                                        axis: i,
                                        shape: sh,
                                        solid: so
                                    };

                                    return aAxisPath;
                                }
                            }
                        }

                        let aFaces = aSolids[so].faces;
                        if (undefined !== aFaces) {
                            for (let f = 0, f_l = aFaces.length; f < f_l; f++) {
                                let aAxes = aFaces[f].axes;
                                if (undefined !== aAxes) {
                                    for (let i = 0, l = aAxes.length; i < l; i++) {
                                        if (pAxisInternalID === aAxes[i].internal_id) {
                                            aAxisPath = {
                                                axis: i,
                                                shape: sh,
                                                solid: so,
                                                face: f
                                            };

                                            return aAxisPath;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return aAxisPath;
    }
    //__________________________________________________________________________________________
    private _getWCSPathRec(pPart: iPart, pPartArray: Array<number>, pAxisInternalID: string) {
        let aAxisPath = this._getPartAxisPath(pPart, pAxisInternalID);
        if (undefined !== aAxisPath) {
            aAxisPath.part = pPartArray;
        } else if (undefined !== pPart.subParts) {
            for (let i = 0, l = pPart.subParts.length; i < l; i++) {
                let aSubpartArray = pPartArray.slice();
                aSubpartArray.push(i);

                let aSubpart = pPart.subParts[i];
                aAxisPath = this._getWCSPathRec(aSubpart, aSubpartArray, pAxisInternalID);
                if (undefined !== aAxisPath) {
                    break;
                }
            }
        }

        return aAxisPath;
    }
    //__________________________________________________________________________________________
    public getCSPath(pInternalID: string) {
        return this._getWCSPathRec(this.mPart, new Array<number>(), pInternalID);
    }
    //__________________________________________________________________________________________
    public getWCSPath() {
        return this.getCSPath(this.mWorkingLCS.internal_id);
    }
    //__________________________________________________________________________________________
    public getCSByPath(pAxisPath: iAxisPath) {
        if ((undefined === pAxisPath) || (undefined === pAxisPath.axis)) {
            return undefined;
        }

        let aAxis: iAxis;

        let aPart = this.mPart;
        for (let i = 0, l = pAxisPath.part.length; i < l; i++) {
            if (undefined !== aPart.subParts) {
                aPart = aPart.subParts[pAxisPath.part[i]];
            }
        }

        if (undefined === aPart) {
            return undefined;
        }

        if (undefined !== pAxisPath.shape) {
            if ((undefined !== aPart.shapes) && (undefined !== aPart.shapes[pAxisPath.shape])) {
                let aShape = aPart.shapes[pAxisPath.shape];
                if (undefined !== pAxisPath.solid) {
                    if ((undefined !== aShape.solids) &&
                        (undefined !== aShape.solids[pAxisPath.solid])) {
                        let aSolid = aShape.solids[pAxisPath.solid];
                        if (undefined !== pAxisPath.face) {
                            if ((undefined !== aSolid.faces) &&
                                (undefined !== aSolid.faces[pAxisPath.face]) &&
                                (undefined !== aSolid.faces[pAxisPath.face].axes)) {
                                aAxis = aSolid.faces[pAxisPath.face].axes[pAxisPath.axis];
                            }
                        } else if (undefined !== aSolid.axes) {
                            aAxis = aSolid.axes[pAxisPath.axis];
                        }

                    }
                } else if (undefined !== aShape.axes) {
                    aAxis = aShape.axes[pAxisPath.axis];
                }


            }
        } else {
            if (undefined !== pAxisPath.axis) {
                aAxis = aPart.axes[pAxisPath.axis];
            }
        }

        return aAxis;
    }
    //__________________________________________________________________________________________
    public getAxes() {
        let aAxes = new Array<iAxis>();
        this._getAxesRec(this.mPart, aAxes);
        return aAxes;
    }
    //__________________________________________________________________________________________
    private _getAxesRec(pPart: iPart, pAxes: Array<iAxis>) {
        if (null == pPart) {
            return;
        }

        if (null != pPart.axes) {
            pAxes.push(...pPart.axes);
        }

        let aShapes = pPart.shapes;
        if (null != aShapes) {
            for (let i = 0; i < aShapes.length; i++) {
                if (null != aShapes[i].axes) {
                    pAxes.push(...aShapes[i].axes);
                }

                let aSolids = aShapes[i].solids;
                if (null != aSolids) {
                    for (let j = 0; j < aSolids.length; j++) {
                        if (null != aSolids[j].axes) {
                            pAxes.push(...aSolids[j].axes);
                        }

                        if (null != aSolids[j].faces) {
                            for (let k = 0; k < aSolids[j].faces.length; k++) {
                                if (null != aSolids[j].faces[k].axes) {
                                    pAxes.push(...aSolids[j].faces[k].axes);
                                }
                            }
                        }
                    }
                }
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this._getAxesRec(pPart.subParts[i], pAxes);
            }
        }
    }
    //__________________________________________________________________________________________
    public removeAxis(pInternalId: string) {
        let aAxes = new Array<iAxis>();
        this._removeAxisRec(this.mPart, pInternalId);
        return aAxes;
    }
    //__________________________________________________________________________________________
    private _removeAxisRec(pPart: iPart, pInternalId: string) {
        if (null == pPart) {
            return;
        }


        if (null != pPart.axes) {
            let aIndex = pPart.axes.findIndex(axis => axis.internal_id == pInternalId)
            if (aIndex > -1) {
                pPart.axes[aIndex].object3D.parent.remove(pPart.axes[aIndex].object3D)
                pPart.axes.splice(aIndex, 1)
                return
            }
        }

        let aShapes = pPart.shapes;
        if (null != aShapes) {
            for (let i = 0; i < aShapes.length; i++) {
                if (null != aShapes[i].axes) {
                    let aIndex = aShapes[i].axes.findIndex(axis => axis.internal_id == pInternalId)
                    if (aIndex > -1) {
                        aShapes[i].axes[aIndex].object3D.parent.remove(aShapes[i].axes[aIndex].object3D)
                        aShapes[i].axes.splice(aIndex, 1)
                        return
                    }
                }

                let aSolids = aShapes[i].solids;
                if (null != aSolids) {
                    for (let j = 0; j < aSolids.length; j++) {
                        if (null != aSolids[j].axes) {
                            let aIndex = aSolids[j].axes.findIndex(axis => axis.internal_id == pInternalId)
                            if (aIndex > -1) {
                                aSolids[j].axes[aIndex].object3D.parent.remove(aSolids[j].axes[aIndex].object3D)
                                aSolids[j].axes.splice(aIndex, 1)
                                return
                            }
                        }

                        if (null != aSolids[j].faces) {
                            for (let k = 0; k < aSolids[j].faces.length; k++) {
                                if (null != aSolids[j].faces[k].axes) {
                                    let aIndex = aSolids[j].faces[k].axes.findIndex(axis => axis.internal_id == pInternalId)
                                    if (aIndex > -1) {
                                        aSolids[j].faces[k].axes[aIndex].object3D.parent.remove(aSolids[j].faces[k].axes[aIndex].object3D)
                                        aSolids[j].faces[k].axes.splice(aIndex, 1)
                                        return
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this._removeAxisRec(pPart.subParts[i], pInternalId);
            }
        }
    }
    //__________________________________________________________________________________________
    public get isSelected(): boolean {
        return (Op3dContext.PARTS_MANAGER.selectedPart == this);
    }
    //__________________________________________________________________________________________
    public get workingLCS() {
        return this.mWorkingLCS;
    }
    //__________________________________________________________________________________________
    public setOnPosition(pNewPos: Vector3): void {
        try {
            MovementBehavior.moveToPos(this, pNewPos);
        } catch (error) {
            MessagesHandler.ON_ERROR_BEHAVIORS({
                error: error,
            });
        }
    }
    //__________________________________________________________________________________________
    protected _updateBehaviors() {

        PostBehavior.init(this);
        CageBehavior.init(this);

        let aLaserAxis = this.getAxes().find((axis) => (eAxisType.LASER === axis.type));
        if ((null != aLaserAxis) &&
            (null == this.mBehaviors[eBehavior.LASER_BEHAVIOR])) {
            this.mBehaviors[eBehavior.LASER_BEHAVIOR] =
                BehaviorsFactory.behaviorsFactory(this, eBehavior.LASER_BEHAVIOR);
        }
    }
    //__________________________________________________________________________________________
    public replaceSubPart(pOld: iPart, pNew: iPart) {
        try {
            let aIndex = this.mPart.subParts.indexOf(pOld);
            this.mObject3D.children[0].children.splice(aIndex, 1, pNew.object3D);
            this.mPart.subParts.splice(aIndex, 1, pNew);
            SceneContext.OP3D_SCENE.activateRenderer();

        } catch (e) {
            throw new Error("replace didnt succeed");
        }
    }
    //__________________________________________________________________________________________
    public addSubPartByPartName(pParentPartName: string, pPartToAdd: iPart,
        pOffset?: Vector3) {

        let aPart = this.getSubpartByName(pParentPartName);
        this.addSubPartToPart(aPart, pPartToAdd, pOffset);
    }
    //__________________________________________________________________________________________
    public addSubPartToPart(pParentPart: iPart, pSubPart: iPart, pOffset?: Vector3) {
        // if (pParentPart.object3D.parent != this.mObject3D.children[0]) {
        //     throw new Error("pParentPart is not a child of this.mObject3D");
        // }

        if (null == pParentPart.subParts) {
            pParentPart.subParts = new Array<iPart>();
        }

        pParentPart.subParts.push(pSubPart);
        // this._initPart(pSubPart);
        pParentPart.object3D.add(pSubPart.object3D);

        if (null != pOffset) {
            pSubPart.object3D.position.add(pOffset);
        }

        this.setWireframeVisibility(null != this.mWireFrame);
        this.updatePart();
        // Op3dContext.PARTS_MANAGER.updatePartsList(false);
    }
    //__________________________________________________________________________________________
    public _updateAxesScale() {
        let aSize = Op3dUtils.getNetoItemSize(this.mObject3D);

        let aScale = (((null !== aSize) && (false === isNaN(aSize.y) && (false === isNaN(aSize.x) &&
            ((aSize.y + aSize.x) > 0)))) ? ((aSize.y + aSize.x) / 50) : 1);
        this.getAxes().forEach((axis) => axis.object3D.scale.set(aScale, aScale, aScale));
    }
    //__________________________________________________________________________________________
    public removeAllSubParts(): void {
        this.removeSubPartsFromPart(this.mPart);
    }
    //__________________________________________________________________________________________
    public removeSubPartsFromPart(pPart: iPart) {
        if (pPart == null || null == pPart.subParts) {
            return;
        }

        while (pPart.subParts.length > 0) {
            let aSubPart = pPart.subParts.shift();
            Button3D.removeButtonByObject3D(aSubPart.object3D);
            pPart.object3D.remove(aSubPart.object3D);
        }

        pPart.subParts = null;
        this.updatePart();
        this.setWireframeVisibility(false);
    }
    //__________________________________________________________________________________________
    private _getFacesJSON(pFaceJSON: iFacesJSON, pFace: iFace) {
        if (null != pFace.name) {
            pFaceJSON.name = pFace.name;
        }

        if (undefined !== pFace.indexes) {
            pFaceJSON.num_of_indices = pFace.indexes.length;
        }

        if (null != pFace.axes) {
            pFaceJSON.axes = new Array<iAxisJsonData>();

            for (let axis = 0, l = pFace.axes.length; axis < l; axis++) {
                let aMatrixAxis = pFace.axes[axis].object3D.matrixWorld;
                let aAxis: iAxisJsonData = {
                    internal_id: pFace.axes[axis].internal_id,
                    matrixWorld: OP3DMathUtils.getUnscaledMatrix(aMatrixAxis),
                    type: pFace.axes[axis].type
                };

                if (null != pFace.axes[axis].name) {
                    aAxis.name = pFace.axes[axis].name;
                }

                if (null != pFace.axes[axis].linkedOpticsId) {
                    aAxis.linkedOpticsId = pFace.axes[axis].linkedOpticsId;
                }

                pFaceJSON.axes.push(aAxis);
            }
        }
    }
    //__________________________________________________________________________________________
    private _getSolidsJSON(pSolidJSON: iSolidJSON, pSolid: iSolid) {
        if (null != pSolid.name) {
            pSolidJSON.name = pSolid.name;
        }

        if (null != pSolid.axes) {
            pSolidJSON.axes = new Array<iAxisJsonData>();
            for (let axis = 0, l = pSolid.axes.length; axis < l; axis++) {
                let aAxis: iAxisJsonData = {
                    internal_id: pSolid.axes[axis].internal_id,
                    matrixWorld:
                        OP3DMathUtils.getUnscaledMatrix(pSolid.axes[axis].object3D.matrixWorld),
                    type: pSolid.axes[axis].type
                };

                if (null != pSolid.axes[axis].name) {
                    aAxis.name = pSolid.axes[axis].name;
                }

                if (null != pSolid.axes[axis].linkedOpticsId) {
                    aAxis.linkedOpticsId = pSolid.axes[axis].linkedOpticsId;
                }

                pSolidJSON.axes.push(aAxis);
            }
        }

        let aFaces = pSolid.faces;
        if (null != aFaces) {
            pSolidJSON.faces = new Array<iFacesJSON>();
            for (let i = 0, l = aFaces.length; i < l; i++) {
                let aFaceMatrix = aFaces[i].visualization.mesh.matrixWorld;
                let aFaceJSON: iFacesJSON = {
                    internal_id: aFaces[i].internal_id,
                    data: aFaces[i].data,
                    matrixWorld: OP3DMathUtils.getUnscaledMatrix(aFaceMatrix),
                };

                if (false === aFaces[i].isForSimulation) {
                    aFaceJSON.isForSimulation = false;
                }

                pSolidJSON.faces.push(aFaceJSON);
                this._getFacesJSON(pSolidJSON.faces[i], aFaces[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    private _getShapesJson(pShapesJson: iShapeJSON, pShape: iShape) {
        if (null != pShape.name) {
            pShapesJson.name = pShape.name;
        }

        if (null != pShape.axes) {
            pShapesJson.axes = new Array<iAxisJsonData>();
            for (let axis = 0, l = pShape.axes.length; axis < l; axis++) {
                let aAxis: iAxisJsonData = {
                    internal_id: pShape.axes[axis].internal_id,
                    matrixWorld:
                        OP3DMathUtils.getUnscaledMatrix(pShape.axes[axis].object3D.matrixWorld),
                    type: pShape.axes[axis].type
                };

                if (null != pShape.axes[axis].name) {
                    aAxis.name = pShape.axes[axis].name;
                }

                if (null != pShape.axes[axis].linkedOpticsId) {
                    aAxis.linkedOpticsId = pShape.axes[axis].linkedOpticsId;
                }

                pShapesJson.axes.push(aAxis);
            }
        }

        let aSolids = pShape.solids;
        if (null != aSolids) {
            pShapesJson.solids = new Array<iSolidJSON>();
            for (let i = 0, l = aSolids.length; i < l; i++) {
                pShapesJson.solids.push({
                    internal_id: aSolids[i].internal_id,
                    matrixWorld: OP3DMathUtils.getUnscaledMatrix(aSolids[i].object3D.matrixWorld)
                });

                this._getSolidsJSON(pShapesJson.solids[i], aSolids[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    public getPartOptics() {
        let aOpticsHolder = this.getSubpartByName(Strings.OPTICS_HOLDER);
        if (aOpticsHolder == null) {
            if (null == this.mLinkedParts) {
                return null;
            }

            let aOpticsAxes = this.getAxes().find(axis => (eAxisType.OPTICS === axis.type));
            let aNumberID = (null != aOpticsAxes.linkedOpticsId) ?
                aOpticsAxes.linkedOpticsId : null;
            let aPart = this.mLinkedParts.find((part) =>
                part.refCS.cs.linkedOpticsId == aNumberID);

            if (null != aPart) {
                aOpticsHolder = aPart.getSubpartByName(Strings.OPTICS_HOLDER);
            }
        }

        let aOptics = ((null != aOpticsHolder) && (null != aOpticsHolder.subParts)) ?
            aOpticsHolder.subParts[0] : null;

        return aOptics;
    }
    //______________________________________________________________________________________________
    public get opticsNumberID() {
        let aNumberID: string = null;
        let aOpticsHolder = this.getSubpartByName(Strings.OPTICS_HOLDER);

        aNumberID = ((null != aOpticsHolder) && (null != aOpticsHolder.subParts) &&
            (null != aOpticsHolder.subParts[0]) && (null != aOpticsHolder.subParts[0].data)) ?
            aOpticsHolder.subParts[0].data.number_id : null;

        return aNumberID;
    }
    //______________________________________________________________________________________________
    private _getPartJSONRec(pPartIDsJSON: iPartsIDJSON, pPart: iPart) {
        if (null != pPart.name) {
            pPartIDsJSON.name = pPart.name;
        }

        if (undefined !== pPart.number_id) {
            pPartIDsJSON.number_id = pPart.number_id;
        }

        if (null != pPart.axes) {
            //add matrix
            pPartIDsJSON.axes = new Array<iAxisJsonData>();
            for (let i = 0; i < pPart.axes.length; i++) {
                pPart.axes[i].object3D.updateMatrix()
                pPart.axes[i].object3D.updateMatrixWorld(true);

                let aAxis: iAxisJsonData = {
                    internal_id: pPart.axes[i].internal_id,
                    type: pPart.axes[i].type,
                    matrix: pPart.axes[i].object3D.matrix.clone(),
                    matrixWorld: OP3DMathUtils.getUnscaledMatrix(pPart.axes[i].object3D.matrixWorld)
                };

                if (null != pPart.axes[i].name) {
                    aAxis.name = pPart.axes[i].name;
                }

                if (null != pPart.axes[i].linkedOpticsId) {
                    aAxis.linkedOpticsId = pPart.axes[i].linkedOpticsId;
                }

                pPartIDsJSON.axes.push(aAxis);
            }
        }

        if (null != pPart.shapes) {
            pPartIDsJSON.shapes = new Array<iShapeJSON>();
            for (let i = 0, l = pPart.shapes.length; i < l; i++) {

                pPartIDsJSON.shapes.push({
                    internal_id: pPart.shapes[i].internal_id,
                    matrixWorld: OP3DMathUtils.getUnscaledMatrix(pPart.shapes[i].object3D.matrixWorld)
                });

                this._getShapesJson(pPartIDsJSON.shapes[i], pPart.shapes[i]);
            }
        }

        if (null != pPart.subParts) {
            pPartIDsJSON.subParts = new Array<iPartsIDJSON>();
            for (let subpart = 0; subpart < pPart.subParts.length; subpart++) {

                pPartIDsJSON.subParts[subpart] = {
                    internal_id: pPart.subParts[subpart].internal_id,
                    matrixWorld: pPart.subParts[subpart].object3D.matrixWorld
                }
                this._getPartJSONRec(pPartIDsJSON.subParts[subpart], pPart.subParts[subpart]);
            }
        }
    }
    //__________________________________________________________________________________________
    private _getPartJSONData() {
        let aPartsIDs: iPartsIDJSON = {
            internal_id: this.mPart.internal_id,
            matrixWorld: this.mPart.object3D.matrixWorld
        };

        if (null != this.mPart.name) {
            aPartsIDs.name = this.mPart.name;
        }

        this._getPartJSONRec(aPartsIDs, this.mPart);

        return aPartsIDs;
    }
    //__________________________________________________________________________________________
    public exportToJSONObject() {
        if (true == this.mPartOptions.static || this.isDeprecated == true) {
            return null;
        }

        this.mObject3D.updateMatrixWorld(true);
        let aPartJSONData = this._getPartJSONData();
        let aOpticsVO = null;
        if (this.numberID !== undefined) {
            aOpticsVO = OpticsDataLoader.instance.getFromCache(this.numberID);
        }

        let aData: iOptixPartJSON = {
            id: this.mID,
            number_id: this.mNumberID,
            worldMatrix: this.mObject3D.matrixWorld.clone(),
            internalID: this.mInternalID,
            behaviors: this._getBehaviorData(),
            analysisOptions: this.mAnalysisOptions,
            groupData: this.mGroupData,
            partOptions: {
                type: this.mPartOptions.type,
                visible: this.visibleObj.visible,
            },
            isLocked: this.mLockedTransform,
            partIDs: aPartJSONData,
            rotationRef: this.mRefRotation,
            label: this.mLabel,
            paraxialLensData: this.mParaxialLensData,
            showLabel: this.labelVisiblity
        };

        if (aOpticsVO != null && aOpticsVO.parameters.polarizer_data != null) {
            aData.polarizer_data = aOpticsVO.parameters.polarizer_data;
        }

        if ((null != this.mRefCS) && (Op3dContext.GCS != this.mRefCS.refPart)) {
            aData.refCS = {
                axisInternalID: this.mRefCS.cs.internal_id,
                partInternalID: this.mRefCS.refPart.internalID,
            };

            if (true == this.mRefCS.isCage) {
                aData.refCS.isCage = true;
            }
        }

        aData.lcs = {
            axisInternalID: this.mWorkingLCS.internal_id
        }

        let aDetectorData = this.getDetectorData();
        if (null != aDetectorData) {
            aData.detectorData = aDetectorData;
        }

        return aData;
    }
    //__________________________________________________________________________________________
    public getDetectorData() {
        let aOpticsHolder = this.getSubpartByName(Strings.OPTICS_HOLDER);
        if ((null == aOpticsHolder) || (null == aOpticsHolder.subParts) ||
            (null == aOpticsHolder.subParts[0]) || (null == aOpticsHolder.subParts[0].data) ||
            (null == aOpticsHolder.subParts[0].data.detectorData)) {
            return null;
        }

        return aOpticsHolder.subParts[0].data.detectorData;
    }
    //__________________________________________________________________________________________
    private _getBehaviorData() {
        let aBehaviorJSON: iBehaviorsJSON = {};
        for (let behavior in this.mBehaviors) {
            this.mBehaviors[behavior].exportToJSON(this, aBehaviorJSON);
        }

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mID);
        if (null != aPartVO) {
            if (true == aPartVO.hasPost) {
                PostBehavior.exportToJSON(this, aBehaviorJSON);
            }
        }

        if (null != aPartVO) {
            if (true == aPartVO.hasCage) {
                CageBehavior.exportToJSON(this, aBehaviorJSON);
            }
        }

        return aBehaviorJSON;
    }
    //__________________________________________________________________________________________
    private _initLabelFromJson(pLabel: iLabel, pAddToCurrent: boolean = false) {
        if (null == pLabel) {
            return;
        }

        if (this.mLabel == null) {
            this.mLabel = {
                label: ""
            };
        }

        this.mLabel.label = pLabel.label;

        if (pAddToCurrent == false) {
            this.mLabel.index = pLabel.index;

            if (null != pLabel.index) {
                LabelHandler.updateIndex(this, pLabel.index);
            }
        }
    }
    //__________________________________________________________________________________________
    public async initFromJSON(pData: iOptixPartJSON, pUseIDS: boolean, pAddToCurrent: boolean = false) {
        let aMatrix = new Matrix4().fromArray(pData.worldMatrix.elements);
        this.labelVisiblity = pData.showLabel
        let aRotationMatrix = new Matrix4().extractRotation(aMatrix);
        this.mObject3D.rotation.setFromRotationMatrix(aRotationMatrix);
        this.mObject3D.position.setFromMatrixPosition(aMatrix);
        this.mObject3D.scale.setFromMatrixScale(aMatrix);

        const aIsLocked = pData.isLocked !== undefined ? pData.isLocked : false;
        this.locked = aIsLocked;

        this.mGroupData = pData.groupData;
        for (let behavior in this.mBehaviors) {
            await this.mBehaviors[behavior].initFromJSON(this, pData.behaviors, pUseIDS);
        }

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mID);
        if (null != aPartVO) {
            if (true == aPartVO.hasPost && pData.behaviors.postBehavior != null) {
                PostBehavior.initFromJSON(this, pData.behaviors);
            }
            if (true == aPartVO.hasCage) {
                CageBehavior.initFromJSON(this, pData.behaviors);
            }
        }

        this._initJSONPartOptions(pData.partOptions);

        let aLabel = (pData.label != null && pData.label.label == null) ? this.mLabel : pData.label
        this._initLabelFromJson(aLabel, pAddToCurrent);

        if (true == pUseIDS) {
            this.mInternalID = pData.internalID;
        }

        if (null != this.mAnalysisOptions) {
            this.mAnalysisOptions.splice(0);
        }

        if (undefined !== pData.paraxialLensData) {
            this.mParaxialLensData = pData.paraxialLensData;
        }

        /**
         * this block was transfered up because we had a bug with deprecated
         * optical elements that parts were linked to
         */
        // if (true == pUseIDS) {
        //     await CSS2DHash.removeSpriteLabelById(this.mInternalID);
        //     this.mInternalID = pData.internalID;
        // }

        if (null != pData.partIDs) {
            this._initPartIDs(pData.partIDs, this.mPart, pUseIDS);
        }

        this.initAnalysisOptionFromJSON(pData.analysisOptions);

        if (null != pData.lcs) {
            let aAxes = this.getAxes();
            for (let i = 0; i < aAxes.length; i++) {
                if (pData.lcs.axisInternalID == aAxes[i].internal_id) {
                    this.setWorkingCS(aAxes[i]);
                    break;
                }
            }
        }

        let aRot = pData.rotationRef;
        if (null != pData.refCS) {
            this._setRefFromJSON(pData.refCS, aRot);
        } else {
            this.clearRef();
            if (null != aRot) {
                this.mRefRotation = new Euler().copy(aRot);
            }
        }

        if (null != pData.detectorData) {
            CustomDetectorBehavior.updateDetectorData(this, pData.detectorData, true);
        }
    }
    //__________________________________________________________________________________________
    public initAnalysisOptionFromJSON(pAnalysisData: iAnalysisData[]) {
        if (null == pAnalysisData) {
            return;
        }

        let aFaces = this.getFaces().map(face => face.internal_id);
        let aAnalysisOptions = Object.values(pAnalysisData);
        for (let option of aAnalysisOptions) {
            if (aFaces.indexOf(option.faceId) == -1) {
                Op3dContext.USER_VO.isEmployeeUser && console.log(`Part: problematic surface ${option.faceId}`);
                continue;
            }
            let aOption: iAnalysisData = {
                faceId: option.faceId,
                fast: Object.values(option.fast),
                advanced: Object.values(option.advanced)
            }
            this.addAnalysisOption(aOption);
        }
    }
    //__________________________________________________________________________________________
    private async _setRefFromJSON(pRefCSJSON: iRefCSJSON, pRot: Euler) {
        let aPartInternalID = pRefCSJSON.partInternalID;
        let aPart: Part;
        await Op3dContext.wait(() => {
            aPart = Op3dContext.PARTS_MANAGER.getPartByInternalId(aPartInternalID);
            return (null != aPart);
        });

        let aAxisInrternalID = pRefCSJSON.axisInternalID;
        let aAxis: iAxis;
        await Op3dContext.wait(() => {
            aAxis = aPart.getAxes().find((axis) => axis.internal_id == aAxisInrternalID);
            return ((null != aAxis) || (true == aPart.isDeprecated));
        });

        if (true == aPart.isDeprecated) {
            this.clearRef();
            return;
        }

        this.setRefrence({
            cs: aAxis,
            refPart: aPart,
            isCage: pRefCSJSON.isCage
        });
        Op3dContext.PARTS_MANAGER.updatePartsList(false);

        if (null != pRot) {
            this.mRefRotation = new Euler().copy(pRot);
        }
    }
    //__________________________________________________________________________________________
    private _initPartIDs(pPartIds: iPartsIDJSON, pPart: iPart, pUseIDS: boolean) {
        if ((null == pPartIds) || (false == pPart.useID)) {
            return;
        }

        if (null != pPartIds.name) {
            pPart.name = pPartIds.name;
        }

        if ((null != pPartIds.internal_id) && (true == pUseIDS)) {
            pPart.internal_id = pPartIds.internal_id;
        }

        if ((null != pPart.axes) && (null != pPartIds.axes)) {

            /*
            *below if statement
            *added for case of undo/redo axis adding/removing
            */

            if (pPart.axes.length > pPartIds.axes.length) {
                let aPartIDsPrev = pPart.axes.map(axis => axis.internal_id);
                let aPartIDs = pPartIds.axes.map(axis => axis.internal_id);
                let aUniqueArr = aPartIDsPrev.filter(function (obj) {
                    return aPartIDs.indexOf(obj) == -1;
                });

                for (let aUniqueAxis of aUniqueArr) {
                    let aAxisIndex = pPart.axes.findIndex(axis => axis.internal_id == aUniqueAxis);
                    if (-1 != aAxisIndex) {
                        let aAxis = pPart.axes[aAxisIndex];
                        pPart.object3D.remove(aAxis.object3D);
                        pPart.axes.splice(aAxisIndex, 1);
                    }
                }
            }

            while (pPart.axes.length < pPartIds.axes.length) {
                let aAxisPart = SceneContext.OP3D_SCENE.getAxisModel();
                let aAxis: iAxis = {
                    internal_id: Op3dUtils.idGenerator(),
                    object3D: aAxisPart,
                    isCustom: true,
                    type: eAxisType.GENERAL
                };

                if (pPartIds.axes[pPart.axes.length].matrixWorld != null) {
                    let aAxisMatrixElements = pPartIds.axes[pPart.axes.length].matrixWorld.elements;
                    aAxisPart.applyMatrix4(new Matrix4().fromArray(aAxisMatrixElements));
                }
                pPart.object3D.add(aAxisPart)
                pPart.axes.push(aAxis)
            }

            for (let axis = 0; axis < pPart.axes.length; axis++) {
                if (true == pUseIDS) {
                    pPart.axes[axis].internal_id = pPartIds.axes[axis]?.internal_id;
                }

                if (null != pPartIds.axes[axis]?.name) {
                    pPart.axes[axis].name = pPartIds.axes[axis]?.name;
                }

                if (null != pPartIds.axes[axis]?.linkedOpticsId) {
                    pPart.axes[axis].linkedOpticsId = pPartIds.axes[axis]?.linkedOpticsId;
                }
            }
        }

        if ((null != pPart.shapes) && (null != pPartIds.shapes)) {
            for (let i = 0; i < pPart.shapes.length; i++) {
                this._initShapesIDsFromJSON(pPartIds.shapes[i], pPart.shapes[i], pUseIDS);
            }
        }

        if ((null != pPart.subParts) && (null != pPartIds.subParts)) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this._initPartIDs(pPartIds.subParts[i], pPart.subParts[i], pUseIDS)
            }
        }
    }
    //__________________________________________________________________________________________
    private _initShapesIDsFromJSON(pShapesJSON: iShapeJSON, pShape: iShape, pUseIDS: boolean) {
        if (null != pShapesJSON?.name) {
            pShape.name = pShapesJSON.name;
        }

        if ((null != pShapesJSON?.internal_id) && (true == pUseIDS)) {
            pShape.internal_id = pShapesJSON.internal_id;
        }

        if ((null != pShape.axes) && (null != pShapesJSON.axes)) {
            for (let axis = 0; axis < pShape.axes.length; axis++) {
                if (true == pUseIDS) {
                    pShape.axes[axis].internal_id = pShapesJSON.axes[axis]?.internal_id;
                }

                if (null != pShapesJSON.axes[axis].name) {
                    pShape.axes[axis].name = pShapesJSON.axes[axis]?.name;
                }

                if (null != pShapesJSON.axes[axis].linkedOpticsId) {
                    pShape.axes[axis].linkedOpticsId = pShapesJSON.axes[axis]?.linkedOpticsId;
                }
            }
        }

        if ((null != pShape?.solids) && (null != pShapesJSON?.solids)) {
            for (let i = 0; i < pShape.solids.length; i++) {
                this._initSolidsIDsFromJSON(pShapesJSON.solids[i], pShape.solids[i], pUseIDS);
            }
        }
    }
    //__________________________________________________________________________________________
    private _initSolidsIDsFromJSON(pSolidJSON: iSolidJSON, pSolid: iSolid, pUseIDS: boolean) {
        if (null != pSolidJSON.name) {
            pSolid.name = pSolidJSON.name;
        }

        if ((null != pSolidJSON.internal_id) && (true == pUseIDS)) {
            pSolid.internal_id = pSolidJSON.internal_id;
        }

        if ((null != pSolid.axes) && (null != pSolidJSON.axes)) {
            for (let axis = 0; axis < pSolid.axes.length; axis++) {
                if (true == pUseIDS) {
                    pSolid.axes[axis].internal_id = pSolidJSON.axes[axis].internal_id;
                }

                if (null != pSolidJSON.axes[axis].name) {
                    pSolid.axes[axis].name = pSolidJSON.axes[axis].name;
                }

                if (null != pSolidJSON.axes[axis].linkedOpticsId) {
                    pSolid.axes[axis].linkedOpticsId = pSolidJSON.axes[axis]?.linkedOpticsId;
                }
            }
        }

        if ((null != pSolid.faces) && (null != pSolidJSON.faces)) {
            for (let i = 0; i < pSolid.faces.length; i++) {
                this._initFacesIDsFromJSON(pSolidJSON.faces[i], pSolid.faces[i], pUseIDS);
            }
        }
    }
    //__________________________________________________________________________________________
    private _initFacesIDsFromJSON(pFaceJSON: iFacesJSON, pFace: iFace, pUseIDS: boolean) {
        if (null != pFaceJSON.name) {
            pFace.name = pFaceJSON.name;
        }

        if (null != pFaceJSON.data) {
            if (null == pFace.data) {
                pFace.data = {};
            }

            if (null != pFaceJSON.data.reflectionData) {
                pFace.data.reflectionData = pFaceJSON.data.reflectionData;
            }


            if (null != pFaceJSON.data.gratingData) {
                let aNumberID = this.getPartOptics().data.number_id;
                let aOpticsVO = OpticsDataLoader.instance.getFromCache(aNumberID);
                let aNewGratingData = OptImporter.backwardSupportReflectiveGratingData(pFaceJSON.data.gratingData, aOpticsVO.parameters.subType);
                pFace.data.gratingData = aNewGratingData; // pFaceJSON.data.gratingData;
            }

            if (null != pFaceJSON.data.scattringVO) {
                pFace.data.scattringVO = pFaceJSON.data.scattringVO;
            }
        }

        if ((null != pFaceJSON.internal_id) && (true == pUseIDS)) {
            pFace.internal_id = pFaceJSON.internal_id;
        }

        if ((null != pFace.axes) && (null != pFaceJSON.axes)) {
            for (let axis = 0; axis < pFace.axes.length; axis++) {
                if (true == pUseIDS) {
                    pFace.axes[axis].internal_id = pFaceJSON.axes[axis].internal_id;
                }

                if (null != pFaceJSON.axes[axis].name) {
                    pFace.axes[axis].name = pFaceJSON.axes[axis].name;
                }

                if (null != pFaceJSON.axes[axis].linkedOpticsId) {
                    pFace.axes[axis].linkedOpticsId = pFaceJSON.axes[axis]?.linkedOpticsId;
                }
            }
        }
    }
    //__________________________________________________________________________________________
    private _initJSONPartOptions(pPartOptions: iJSONPartOptions) {
        for (let key in pPartOptions) {
            if (pPartOptions[key] != null) {
                this.mPartOptions[key] = pPartOptions[key];
            }
        }
    }
    //__________________________________________________________________________________________
    public onSelect() {
        try {
            for (let key in this.mBehaviors) {
                this.mBehaviors[key].onSelect(this);
            }
        } catch (error) {
            MessagesHandler.ON_ERROR_BEHAVIORS({
                error: error,
            });
        }
    }
    //__________________________________________________________________________________________
    public onDblSelect() {
        try {
            for (let key in this.mBehaviors) {
                this.mBehaviors[key].onDblSelect(this);
            }
        } catch (error) {
            MessagesHandler.ON_ERROR_BEHAVIORS({
                error: error,
            });
        }
    }
    //__________________________________________________________________________________________
    public snapToGrid() {
        MovementBehavior.snapToGrid(this)
    }
    //__________________________________________________________________________________________
    public onDeselect() {
        try {
            for (let key in this.mBehaviors) {
                this.mBehaviors[key].onDeselect(this);
            }
        } catch (error) {
            MessagesHandler.ON_ERROR_BEHAVIORS({
                error: error,
            });
        }
    }
    //__________________________________________________________________________________________
    public unHighlightObject() {
        switch (SceneContext.CHOOSE_MODE.mode) {
            case eClickMode.BASE:
            case eClickMode.EDITOR:
            case eClickMode.GROUP:

                let aPart = Group.instance.findMainGroupPart(this);
                if ((ePartType.GROUP === aPart.partOptions.type) &&
                    (undefined !== aPart.linkedParts) && (null !== aPart.linkedParts)) {
                    aPart.linkedParts.forEach(part => {
                        if (ePartType.GROUP === part.partOptions.type) {
                            aPart.unhighlightRec(part.linkedParts)
                        } else {
                            OptixPartDisplayer.unHighlightObject(part)
                        }
                    });
                    return;
                }

                OptixPartDisplayer.unHighlightObject(aPart);
                break
            default:
                break;
        }
    }
    //__________________________________________________________________________________________
    private unhighlightRec(pParts: Array<Part>) {
        pParts.forEach(part => {
            if (ePartType.GROUP === part.partOptions.type) {
                this.unhighlightRec(part.linkedParts)
            } else {
                OptixPartDisplayer.unHighlightObject(part)
            }
        })
    }
    //__________________________________________________________________________________________
    private highlightRec(pParts: Array<Part>) {
        pParts.forEach(part => {
            if (ePartType.GROUP === part.partOptions.type) {
                this.highlightRec(part.linkedParts)
            } else {
                OptixPartDisplayer.highlightGroup(part)
            }
        })
    }
    //__________________________________________________________________________________________
    public highlightObject(pCurrentGroup?: boolean) {
        switch (SceneContext.CHOOSE_MODE.mode) {
            case eClickMode.BASE:
            case eClickMode.EDITOR:
            case eClickMode.GROUP:
                let aMainPart = Group.instance.findMainGroupPart(this);
                if ((ePartType.GROUP === aMainPart.partOptions.type) && (true === pCurrentGroup)) {
                    aMainPart = this;
                }

                if (ePartType.GROUP === aMainPart.partOptions.type) {
                    aMainPart.linkedParts.forEach(part => {
                        if (ePartType.GROUP === part.partOptions.type) {
                            aMainPart.highlightRec(part.linkedParts);
                        } else {
                            OptixPartDisplayer.highlightGroup(part);
                        }
                    });
                    return
                }
                break;
            default:
                break;
        }

        OptixPartDisplayer.highlightObject(this);
    }
    //__________________________________________________________________________________________
    private _updateLaserForSimulation(pSmSystemFile: iSmSystemFile, pRaysData: iSimulationRaysOptions) {
        let aLaserBehavior = this.mBehaviors[eBehavior.LASER_BEHAVIOR];
        if (null == aLaserBehavior) {
            return;
        }

        this.mObject3D.updateMatrixWorld(true);
        let aPartMatrixWorld = this.mObject3D.matrixWorld.clone();
        aLaserBehavior.updateJsonSimulationData(pSmSystemFile,
            aPartMatrixWorld,
            this, pRaysData);
    }
    //__________________________________________________________________________________________
    protected _updateSimulationForFaces(pSmSystemFile: iSmSystemFile, pFaces: Array<iFace>) {
        for (let i = 0; i < pFaces.length; i++) {
            const aFace = pFaces[i];

            if (false == aFace.isForSimulation) {
                return;
            }

            const aFaceData = aFace.data;
            if (null == aFaceData) {
                continue;
            }

            if (null != aFaceData.simGeoData) {
                let aMesh = aFace.visualization.mesh;

                let aSmShapeData: iSmShapeData = (null != aFaceData.shapeData) ?
                    Object.assign({}, aFaceData.shapeData) : null;

                let aShapeIndex = Part.getAttrIndex(pSmSystemFile, 'shapes', aSmShapeData);
                let aMaterial: iSmMaterialData = { number_id: aFaceData.materialID };
                let aGeometry: iSmGeometryData = Object.assign({}, aFaceData.simGeoData);
                if (-1 !== aShapeIndex) {
                    aGeometry.shape = aShapeIndex
                }

                let aIsFrame = (null != aFaceData.frameData) ? aFaceData.frameData.is_frame : false;



                let aIsGrating = aFaceData.gratingData != null;
                let aGratingData: iSmGratingData;
                if (aIsGrating) {
                    /**
                     * @TODO: handle with Arkadiy and Alex the grating
                     */
                    let aRotMat = new Matrix4().extractRotation(aMesh.matrixWorld.clone());
                    let aGrooveOrientation =
                        new Vector3().fromArray(aFaceData.gratingData.groove_orientation);

                    aGrooveOrientation.applyMatrix4(aRotMat);

                    let aGratingGroove: iGratingGroove = {
                        orientation: aGrooveOrientation.toArray(),
                        pitch: aFaceData.gratingData.groove_pitch,
                        thickness: 0
                    }

                    aGratingData = {
                        groove: Part.getAttrIndex(pSmSystemFile, 'grating_grooves', aGratingGroove),
                        max_reflection_order: aFaceData.gratingData.max_reflection_order,
                        max_transmission_order: aFaceData.gratingData.max_transmission_order,
                        min_reflection_order: aFaceData.gratingData.min_reflection_order,
                        min_transmission_order: aFaceData.gratingData.min_transmission_order,
                    }
                }

                let aSurfaceItem: iSmSurfaceData = {
                    is_mask: aFaceData.apertureData != null,
                    is_frame: aIsFrame,
                    max_num_bounces: aFaceData.reflectionData != null &&
                        aFaceData.reflectionData.enabled ?
                        aFaceData.reflectionData.max_num_bounces : 0,
                    reflection_enabled: aFaceData.reflectionData != null ?
                        aFaceData.reflectionData.enabled : false,
                    name: aFace.name,
                    id: aFace.internal_id,
                    coating: Part.getAttrIndex(pSmSystemFile, 'coatings', aFaceData.coatingID),
                    geometry: aIsFrame ? -1 :
                        Part.getAttrIndex(pSmSystemFile, 'geometries', aGeometry),
                    world_matrix: Op3dUtils.matrixToArray(aMesh.matrixWorld.clone()),
                    material: Part.getAttrIndex(pSmSystemFile, 'materials', aMaterial),
                    is_grating: aIsGrating,
                    scattering_enabled: false,
                    grating: aGratingData,
                    is_inside_blackbox: aFaceData.is_inside_blackbox
                }

                if (null != aFace.data.paraxialEFL) {
                    aSurfaceItem.is_ideal_lens = true;
                    aSurfaceItem.focal_length = aFace.data.paraxialEFL;
                }

                if (null != aFace.data.phase_profile) {
                    aSurfaceItem.phase_profile_enabled = true;
                    aSurfaceItem.phase_profile = aFace.data.phase_profile;
                }

                if (null != aFace.data.jones_matrix) {
                    aSurfaceItem.is_jones_matrix = true;
                    aSurfaceItem.jones_matrix = aFace.data.jones_matrix;
                }

                this._addMaskData(aFaceData, aSurfaceItem, pSmSystemFile);
                this._handleScattering(aSurfaceItem, aFaceData);

                if (true == aIsFrame) {
                    const aFrontID = pFaces[aFace.data.frameData.front_surface].internal_id;
                    const aBackID = pFaces[aFace.data.frameData.back_surface].internal_id;
                    const aFrontIdx = pSmSystemFile.surfaces.findIndex(surface =>
                        surface.id == aFrontID);
                    const aBackIdx = pSmSystemFile.surfaces.findIndex(surface =>
                        surface.id == aBackID);

                    aSurfaceItem.back_surface = aBackIdx;
                    aSurfaceItem.front_surface = aFrontIdx;
                }

                pSmSystemFile.surfaces.push(aSurfaceItem);


                let aSurfaceIndex = (pSmSystemFile.surfaces.length - 1);
                let aPartIndex =
                    pSmSystemFile.parts.findIndex((part) => part.partID == this.mInternalID);
                pSmSystemFile.parts[aPartIndex].surfacesIDs.push(aSurfaceIndex);
            }
        }
    }
    //______________________________________________________________________________________
    private _addMaskData(pFaceData: iFaceData, pSurfaceItem: iSmSurfaceData, pSmSystemFile: iSmSystemFile) {
        const aIsMask = pFaceData.apertureData != null;
        let aTransmitanceIdx: number;
        let aPhaseIdx: number;

        if (aIsMask == true) {

            if (pFaceData.apertureData.phase_mask !== undefined &&
                pFaceData.apertureData.phase_mask.url !== undefined &&
                pFaceData.apertureData.phase_mask.url !== "") {
                let aPhaseMaskData = {
                    kind: eSmMaskKind.IMAGE,
                    url: pFaceData.apertureData.phase_mask.url
                }
                aPhaseIdx = Part.getAttrIndex(pSmSystemFile, 'masks', aPhaseMaskData);
            }

            if (pFaceData.apertureData.transmittance_mask !== undefined &&
                pFaceData.apertureData.transmittance_mask.url !== undefined &&
                pFaceData.apertureData.transmittance_mask.url !== "") {

                let aTransmittanceMaskData = {
                    kind: eSmMaskKind.IMAGE,
                    url: pFaceData.apertureData.transmittance_mask.url
                }
                aTransmitanceIdx = Part.getAttrIndex(pSmSystemFile, 'masks', aTransmittanceMaskData);
            }
        }

        pSurfaceItem.transmittance_mask = aTransmitanceIdx;
        pSurfaceItem.phase_mask = aPhaseIdx;
    }
    //__________________________________________________________________________________________
    private _handleScattering(pSurfaceItem: iSmSurfaceData, pFaceData: iFaceData) {
        let aScatteringVO = pFaceData.scattringVO;
        if ((null == aScatteringVO) ||
            (false == Op3dContext.USER_PERMISSION.isScatteringEnabled) ||
            (false == this._checkScatteringValidation(aScatteringVO))) {
            return;
        }

        let aScatteringData = this.createScatteringSimulationSet(aScatteringVO);


        pSurfaceItem.scattering_enabled = true;
        pSurfaceItem.scattering = aScatteringData;
    }
    //__________________________________________________________________________________________
    private createScatteringSimulationSet(pScatteringVO: iScatteringVO): iSmScatteringData {
        let aScatteringData = {
            reflectance: pScatteringVO.parameters.reflectance,
            transmittance: pScatteringVO.parameters.transmittance,
            reflection_scatterer: {
                distribution: {
                    azimuth: pScatteringVO.parameters.bsdfParams.azimuth_theta,
                    kind: pScatteringVO.parameters.scatterModel,
                    sigma_x: pScatteringVO.parameters.bsdfParams.sigmaX,
                    sigma_y: pScatteringVO.parameters.bsdfParams.sigmaY,
                    N: pScatteringVO.parameters.bsdfParams.n,
                    A: pScatteringVO.parameters.bsdfParams.a,
                    B: pScatteringVO.parameters.bsdfParams.b,
                    g: pScatteringVO.parameters.bsdfParams.g
                },
                num_scattered_rays: pScatteringVO.parameters.numberOfRays,
                threshold: pScatteringVO.parameters.relativePowerThreshold
            },
            transmission_scatterer: {
                distribution: {
                    azimuth: pScatteringVO.parameters.bsdfParams.azimuth_theta,
                    kind: pScatteringVO.parameters.scatterModel,
                    sigma_x: pScatteringVO.parameters.bsdfParams.sigmaX,
                    sigma_y: pScatteringVO.parameters.bsdfParams.sigmaY,
                    N: pScatteringVO.parameters.bsdfParams.n,
                    A: pScatteringVO.parameters.bsdfParams.a,
                    B: pScatteringVO.parameters.bsdfParams.b,
                    g: pScatteringVO.parameters.bsdfParams.g
                },
                num_scattered_rays: pScatteringVO.parameters.numberOfRays,
                threshold: pScatteringVO.parameters.relativePowerThreshold
            }
        };
        return aScatteringData;
    }
    //__________________________________________________________________________________________
    private _checkScatteringValidation(pScatteringVO: iScatteringVO) {
        if ((true == isNaN(pScatteringVO.parameters.transmittance)) ||
            (true == isNaN(pScatteringVO.parameters.reflectance))) {
            return false;
        }

        if ((pScatteringVO.parameters.transmittance + pScatteringVO.parameters.reflectance) > 1) {
            return false;
        }

        switch (pScatteringVO.parameters.scatterModel) {
            case eScatteringModel.ABG:
                if ((true == isNaN(pScatteringVO.parameters.bsdfParams.a)) ||
                    (true == isNaN(pScatteringVO.parameters.bsdfParams.b)) ||
                    (true == isNaN(pScatteringVO.parameters.bsdfParams.g))) {
                    return false;
                }
                break;
            case eScatteringModel.GAUSSIAN:
                if ((true == isNaN(pScatteringVO.parameters.bsdfParams.sigmaX)) ||
                    (true == isNaN(pScatteringVO.parameters.bsdfParams.sigmaY)) ||
                    (true == isNaN(pScatteringVO.parameters.bsdfParams.azimuth_theta))) {
                    return false;
                }
                break;
            case eScatteringModel.LAMBERTIAN:
                break;
            case eScatteringModel.COS_NTH:
                if ((true == isNaN(pScatteringVO.parameters.bsdfParams.n))) {
                    return false;
                }
                break;
            default:
                return false;
        }

        return true;
    }
    //__________________________________________________________________________________________
    private async _updateOptomechanicsForSimulation(pSmSystemFile: iSmSystemFile) {
        let aID = this.mID;
        if (null == aID) {
            return;
        }
        if (ePartType.BLACKBOX == this.partOptions.type) {
            return;
        }

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aID);
        if ((null == aPartVO) || (null == aPartVO.number_id)) {
            return;
        }

        if ((null == this.mPart.subParts) || (0 == this.mPart.subParts.length)) {
            return;
        }
        let aMaterialIdx;
        let aCoatingIndex;

        if (this.opticalPartType == eCadType.OPTICS) {
            let aMaterial: iSmMaterialData = { number_id: this.mOpticalData.materialID };
            aMaterialIdx = Part.getAttrIndex(pSmSystemFile, 'materials', aMaterial);
            aCoatingIndex = -1;
        } else {
            aMaterialIdx = -1;
            aCoatingIndex = Part.getAttrIndex(pSmSystemFile, 'coatings', Strings.ABSORBING);
        }
        for (let i = 0; i < this.mPart.subParts.length; i++) {
            let aSubpart = this.mPart.subParts[i];
            let aSubpartID = aSubpart.name;
            if (null == aSubpartID) {
                continue;
            }

            let aSubpartPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aSubpartID);
            if (null == aSubpartPartVO) {
                continue;
            }

            let aURL = aSubpartPartVO.shortUrl;
            if (!aURL.includes('.optix')) {
                aURL = aURL + '.optix'
            }

            if (this.opticalPartType == eCadType.OPTICS) {
                aURL = `${ServerContext.user_prefix_cad}${aPartVO.owner}/optix/GPU_${this.mID}.optix`
            }
            if ('cage-rod.optix' == aURL) {
                continue;
            }
            let aMeshIndex = pSmSystemFile.geometries.length;
            let aOriginalGeometry: iSimulationGeometryData = {
                kind: eSmGeometryType.MESH,
                url: aURL,
                mesh: aMeshIndex,
            }
            pSmSystemFile.geometries.push(aOriginalGeometry);

            let aExcludedGeometry: iSimulationGeometryData = {
                kind: eSmGeometryType.MESH,
                mesh: aMeshIndex,
                excluded_faces: []
            }

            let aMatrix = aSubpart.object3D.matrixWorld.clone();

            if (null != aSubpartPartVO.translationMatrix) {
                aMatrix.multiply(aSubpartPartVO.translationMatrix.clone());
            }
            let aOriginalSurfaceData = {
                id: aSubpart.internal_id,
                coating: aCoatingIndex,
                material: aMaterialIdx,
                name: aURL,
                geometry: Part.getAttrIndex(pSmSystemFile, 'geometries', aExcludedGeometry),
                world_matrix: Op3dUtils.matrixToArray(aMatrix),

                is_frame: false,
                max_num_bounces: 0,
                is_grating: false,
                reflection_enabled: false,
                scattering_enabled: false,
                is_mask: false
            }

            let aIncludedSurfaces = {};
            let aExcludedSurfacesCount = 0;

            if (this.opticalData != null && this.opticalData.faces != null) {

                for (let w = 0; w < this.opticalData.faces.length; w++) {
                    if (this.opticalData.faces[w].data != null) {
                        let aShape = this.opticalData.faces[w].path[0];
                        let aSolid = this.opticalData.faces[w].path[1];
                        let aFace = this.opticalData.faces[w].path[2];
                        let aFacePath = [aShape, aSolid, aFace];

                        let aNewCoating = aCoatingIndex;
                        let aScatteringSimData = null;
                        let aReflectionEnabled = false;
                        let aNewCoatingId = null;


                        let aFaceMaterialID = {
                            number_id: this.opticalData.faces[w].data.materialID != null ?
                                this.opticalData.faces[w].data.materialID : null
                        }
                        let aFaceMaterialIdx = aFaceMaterialID.number_id != null ? Part.getAttrIndex(pSmSystemFile, 'materials', aFaceMaterialID) : null;

                        let aReflectionData: iSimulationReflectionItem = this.opticalData.faces[w].data.reflectionData != null ?
                            this.opticalData.faces[w].data.reflectionData : null;
                        let aScatteringVO: iScatteringVO = this.opticalData.faces[w].data.scattringVO != null ?
                            this.opticalData.faces[w].data.scattringVO : null;

                        if (aScatteringVO != null) aScatteringSimData = this.createScatteringSimulationSet(aScatteringVO);
                        if (this.opticalData.faces[w].data.coatingID != null) {
                            aNewCoatingId = this.opticalData.faces[w].data.coatingID;
                            if (this.opticalData.faces[w].data.coatingID === eCoatingTypes.UNCOATED) {
                                aNewCoating = -1;
                            } else {
                                aNewCoating = Part.getAttrIndex(pSmSystemFile, 'coatings', this.opticalData.faces[w].data.coatingID);
                            }
                        }
                        if (aScatteringVO == null && (this.opticalData.faces[w].data.coatingID == null || this.opticalData.faces[w].data.coatingID.length == 0) && (aReflectionData == null || aReflectionData.enabled == false)
                            && aFaceMaterialID.number_id == null) {
                            continue
                        }

                        if ((aReflectionData != null && aReflectionData.enabled === true) || ['ideal_reflector', 'np_beam_splitter_50_50', 'np_beam_splitter_70_30', 'np_beam_splitter_30_70'].includes(aNewCoatingId)) {
                            aReflectionEnabled = true;
                        }

                        aExcludedGeometry.excluded_faces.push(aFacePath);
                        aExcludedSurfacesCount++;

                        let aSimulationSurfaceInc: iSmSurfaceData = {
                            id: aSubpart.internal_id,
                            coating: aNewCoating,
                            material: aMaterialIdx != aFaceMaterialIdx ? aFaceMaterialIdx : aMaterialIdx,
                            name: aURL,
                            geometry: 0,
                            world_matrix: Op3dUtils.matrixToArray(aMatrix),

                            is_frame: false,

                            max_num_bounces: aReflectionEnabled ? aReflectionData.max_num_bounces : 0,
                            is_grating: false,

                            reflection_enabled: aReflectionEnabled,
                            scattering_enabled: aScatteringVO != null ? true : false,

                            is_mask: false,
                        }
                        if (aSimulationSurfaceInc.material === null) {
                            aSimulationSurfaceInc.material = aOriginalSurfaceData.material;
                        }
                        if (aSimulationSurfaceInc.scattering_enabled) {
                            aSimulationSurfaceInc["scattering"] = aScatteringSimData;
                        }
                        if (aSimulationSurfaceInc.material === null) {
                            delete aSimulationSurfaceInc.material
                        }
                        if (Object.keys(aIncludedSurfaces).length === 0) {
                            aIncludedSurfaces[`${JSON.stringify(aFacePath)}`] = JSON.stringify(aSimulationSurfaceInc);

                            let aGeometryInc = {
                                kind: eSmGeometryType.MESH,
                                included_faces: [aFacePath],
                                mesh: aMeshIndex
                            }

                            pSmSystemFile.geometries.push(aGeometryInc);

                            let aGeometryIdx = pSmSystemFile.geometries.length - 1;

                            aSimulationSurfaceInc.geometry = aGeometryIdx;
                            pSmSystemFile.surfaces.push(aSimulationSurfaceInc);

                        } else {
                            let aExistenceKey = null;
                            for (const key in aIncludedSurfaces) {
                                let aExistingSurface = aIncludedSurfaces[`${key}`];
                                if (aExistingSurface === JSON.stringify(aSimulationSurfaceInc)) {
                                    aExistenceKey = key;
                                    break;
                                }
                            }
                            if (aExistenceKey !== null) {
                                let aGeoIndex = null;

                                external_loop:
                                for (let m = 0; m < pSmSystemFile.geometries.length; m++) {
                                    const aRequiredGeoSurface = pSmSystemFile.surfaces.find(item => item.geometry === m)
                                    if (pSmSystemFile.geometries[m].included_faces !== undefined) {
                                        for (let q = 0; q < pSmSystemFile.geometries[m].included_faces.length; q++) {
                                            if (JSON.stringify(pSmSystemFile.geometries[m].included_faces[q]) === aExistenceKey &&
                                                aRequiredGeoSurface?.id === aSimulationSurfaceInc.id
                                            ) {
                                                aGeoIndex = m;
                                                break external_loop;
                                            }
                                        }
                                    }
                                }

                                aSimulationSurfaceInc.geometry = aGeoIndex;
                                pSmSystemFile.geometries[aGeoIndex].included_faces.push(aFacePath)
                            } else {
                                aIncludedSurfaces[`${JSON.stringify(aFacePath)}`] = JSON.stringify(aSimulationSurfaceInc);

                                let aGeometryInc = {
                                    kind: eSmGeometryType.MESH,
                                    included_faces: [aFacePath],
                                    mesh: aMeshIndex
                                }

                                pSmSystemFile.geometries.push(aGeometryInc);

                                let aGeometryIdx = pSmSystemFile.geometries.length - 1;

                                aSimulationSurfaceInc.geometry = aGeometryIdx;
                                pSmSystemFile.surfaces.push(aSimulationSurfaceInc);
                            }
                        }
                    }
                }
            }
            pSmSystemFile.surfaces.push(aOriginalSurfaceData);

            if (aExcludedSurfacesCount > 0 && aExcludedSurfacesCount === this.getFaces()[0].indexes.length) {
                let aExcGeoIdx = pSmSystemFile.geometries.findIndex(item => (item.mesh === aMeshIndex && item.excluded_faces != null));
                pSmSystemFile.geometries[aExcGeoIdx] = {
                    kind: eSmGeometryType.MESH,
                    mesh: aMeshIndex,
                }
                let aSurfacesIndexes = [];
                for (let p = 0; p < pSmSystemFile.surfaces.length; p++) {
                    if (pSmSystemFile.surfaces[p].geometry === aExcGeoIdx) {
                        aSurfacesIndexes.push(p);
                    }
                }
                for (let p = 0; p < aSurfacesIndexes.length; p++) {
                    pSmSystemFile.surfaces.splice(aSurfacesIndexes[p], 1);
                }
            }
        }
    }
    //__________________________________________________________________________________________
    protected _updateOpticalElementSimulation(pSmSystemFile: iSmSystemFile) {
        this._updateSimulationForPart(pSmSystemFile, this.mPart);
    }
    //__________________________________________________________________________________________
    private _updateSimulationForPart(pSmSystemFile: iSmSystemFile, pPart: iPart) {
        this._updateSimulationForShapes(pSmSystemFile, pPart.shapes);
        if (null == pPart.subParts) {
            return;
        }

        for (let i = 0; i < pPart.subParts.length; i++) {
            this._updateSimulationForPart(pSmSystemFile, pPart.subParts[i]);
        }
    }
    //__________________________________________________________________________________________
    private _updateSimulationForShapes(pSmSystemFile: iSmSystemFile, pShapes: Array<iShape>) {
        if (null == pShapes) {
            return;
        }

        for (let i = 0; i < pShapes.length; i++) {
            this._updateSimulationForSolids(pSmSystemFile, pShapes[i].solids);
        }
    }
    //__________________________________________________________________________________________
    private _updateSimulationForSolids(pSmSystemFile: iSmSystemFile, pSolids: Array<iSolid>) {
        if (null == pSolids) {
            return;
        }

        for (let i = 0; i < pSolids.length; i++) {
            let aFaces = pSolids[i].faces;
            this._updateSimulationForFaces(pSmSystemFile, aFaces);
        }
    }
    //__________________________________________________________________________________________
    protected _getReflectionData(pFaces: Array<iFace>) {
        let aReflectionData = new Array<iSimulationReflectionItem>();
        for (let i = 0; i < pFaces.length; i++) {
            const aFace = pFaces[i];
            /**
             * @TODO add ui in the part info to define these parameters
             */

            let aFaceReflectionData = aFace.data.reflectionData;
            let aIsEnabled = ((null != aFaceReflectionData) &&
                (null != aFaceReflectionData.enabled)) ? aFaceReflectionData.enabled : true;
            let aMaxBounces = ((null != aFaceReflectionData) &&
                (null != aFaceReflectionData.max_num_bounces)) ?
                aFaceReflectionData.max_num_bounces : 5;

            aReflectionData.push({
                enabled: aIsEnabled,
                max_num_bounces: aMaxBounces
            });
        }

        return aReflectionData;
    }
    //__________________________________________________________________________________________
    public updateForSimulation(pSmSystemFile: iSmSystemFile, pRaysData: iSimulationRaysOptions) {
        this._setPartForSimulation(pSmSystemFile);

        this._updateLaserForSimulation(pSmSystemFile, pRaysData);
        this._updateOpticalElementSimulation(pSmSystemFile);
        this._updateOptomechanicsForSimulation(pSmSystemFile);
    }
    //__________________________________________________________________________________________
    private _setPartForSimulation(pSmSystemFile: iSmSystemFile) {
        if (null == this.mRefCS) {
            return;
        }

        let aLCSMatrix = OP3DMathUtils.getUnscaledMatrix(this.mWorkingLCS.object3D.matrixWorld);
        let aRefMatrix = OP3DMathUtils.getUnscaledMatrix(this.mRefCS.cs.object3D.matrixWorld);

        let aLCS: iSMCSParams = {
            matrix: aLCSMatrix.toArray(),
            id: this.mWorkingLCS.internal_id,
            label: (null != this.mWorkingLCS.name) ? this.mWorkingLCS.name : ''
        };

        let aRCS: iSMCSParams = {
            matrix: aRefMatrix.toArray(),
            id: this.mRefCS.cs.internal_id,
            label: (null != this.mRefCS.cs.name) ? this.mRefCS.cs.name : ''
        };


        let aSMPartsData: iSMPartsData = {
            label: this.mLabel.label,
            partID: this.mInternalID,
            lcs: aLCS,
            rcs: aRCS,
            surfacesIDs: new Array<number>(),
        };

        if (null != this.mLinkedParts) {
            aSMPartsData.linkedPartIds = this.mLinkedParts.map((part) => part.internalID);
        }

        let aLaserBehavior = this.getBehavior('laserBehavior');
        if (null != aLaserBehavior) {
            if (eSmRaysKind.GAUSSIAN_BEAM == aLaserBehavior.laserData.lightSource.kind) {
                aSMPartsData.lightSourceIndex = pSmSystemFile.gaussianSources.length;
            } else {
                aSMPartsData.lightSourceIndex = pSmSystemFile.sources.length;
            }
        }

        pSmSystemFile.parts.push(aSMPartsData);
    }
    //__________________________________________________________________________________________
    public static getAttrIndex(pSmSystemFile: iSmSystemFile, pAttribute: tSystemFileEntry, pValue: any) {
        if (null == pValue) {
            return -1;
        }

        let aIndex = pSmSystemFile[pAttribute].findIndex((attr) => {
            return (DataUtils.isEqual(attr, pValue));
        });

        if (-1 == aIndex) {
            aIndex = pSmSystemFile[pAttribute].length;
            pSmSystemFile[pAttribute].push(pValue);
        }

        return aIndex;
    }
    //__________________________________________________________________________________________
    public moveOptixPart(pPoint: iClientPoint, _pIsGroup: boolean = false): void {
        try {

            this.mMoveFunction(pPoint);
            EventManager.dispatchEvent(EventsContext.PART_MOVED, this);

        } catch (error) {
            MessagesHandler.ON_ERROR_BEHAVIORS({
                error: error
            });
        }
    }
    //__________________________________________________________________________________________
    public update() {
        this.mObject3D.updateMatrix();
        this.mObject3D.updateMatrixWorld(true);
        SceneContext.OP3D_SCENE.activateRenderer();

        /**
         * @ this function is very heavy and does a lot each move of the item
         */
        //Op3dContext.DIV_CONROLLER.updatePartInfo(this);
    }
    //__________________________________________________________________________________________
    public isBelongToPart(pObject: Object3D) {
        let aIsBelongToPart = false;
        this.mObject3D.traverse((object) => {
            if (pObject == object) {
                aIsBelongToPart = true;
            }
        });

        return aIsBelongToPart;
    }
    //__________________________________________________________________________________________
    protected _draw(pObject3D: Object3D) {
        this.mObject3D.children[0].add(pObject3D);
    }
    //__________________________________________________________________________________________
    public get visibleObj() {
        return this.mObject3D;
    }
    //__________________________________________________________________________________________
    public getMatchingSurface(pObject: Object3D) {
        if (null == this.mPart.subParts) {
            return null;
        }

        for (let partIndex = 0; partIndex < this.mPart.subParts.length; partIndex) {
            for (let i = 0; i < this.mPart.subParts[partIndex].shapes[0].solids.length; i++) {
                let aSolid = this.mPart.subParts[partIndex].shapes[0].solids[i];
                for (let j = 0; j < aSolid.faces.length; j++) {
                    const element = aSolid.faces[j];
                    if (element.visualization.mesh == pObject) {
                        return element;
                    }
                }
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public get position() {
        return this.mObject3D.position;
    }
    //__________________________________________________________________________________________
    public get parent3D() {
        return this.mObject3D.parent;
    }
    //__________________________________________________________________________________________
    public get initialHeight() {
        return Op3dContext.OPTICAL_AXIS_HEIGHT;
    }
    //__________________________________________________________________________________________
    public get iPart() {
        return this.mPart;
    }
    //__________________________________________________________________________________________
    public delete() {

        while ((null != this.mLinkedParts) && (this.mLinkedParts.length > 0)) {
            this.mLinkedParts[0].clearRef();
        }

        this._deleteLabelObject();
        this.clearRef();
        this.deleteAllQV();
        Button3D.removeEventListers(this);
        if (GaussianBeamTable.GAUSSIAN_DATA != null) {
            delete GaussianBeamTable.GAUSSIAN_DATA[this.mInternalID];
        }
        this.mObject3D = null;
        this.mBehaviors = null;
        this.mPart = null;
    }
    //__________________________________________________________________________________________
    private _deleteLabelObject() {
        if (null == this.mLabelObject) {
            return;
        }

        this.mLabelObject.removeFromParent(this);
    }
    //__________________________________________________________________________________________
    public deleteAllQV() {
        let aAnalysis = this.mAnalysisOptions;
        if (null == aAnalysis) {
            return;
        }

        for (let i = 0; i < aAnalysis.length; i++) {
            if (null != aAnalysis[i].advanced) {
                for (let j = 0, l = aAnalysis[i].advanced.length; j < l; j++) {
                    QuickViewHandler.instance.onDeleteQV(aAnalysis[i].advanced[j].id);
                }
            }

            if (null != aAnalysis[i].fast) {
                for (let j = 0, l = aAnalysis[i].fast.length; j < l; j++) {
                    QuickViewHandler.instance.onDeleteQV(aAnalysis[i].fast[j].id);
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public get internalID() {
        return this.mInternalID;
    }
    //__________________________________________________________________________________________
    public getBehavior<K extends keyof iLaserBehaviorContext>(pType: K): iLaserBehaviorContext[K] {
        let aBehaviorEnum: eBehavior;

        switch (pType) {
            case 'laserBehavior':
                aBehaviorEnum = eBehavior.LASER_BEHAVIOR;
                break;
            case 'cageBehavior':
                aBehaviorEnum = eBehavior.CAGE_BEHAVIOR;
                break;
            case 'postBehavior':
                aBehaviorEnum = eBehavior.POST_BEHAVIOR;
                break;
            case 'irisBehavior':
                aBehaviorEnum = eBehavior.IRIS_BEHAVIOR;
                break;
            default:
                return null;
        }

        return this.mBehaviors[aBehaviorEnum] as iLaserBehaviorContext[K];
    }
    //__________________________________________________________________________________________
    public get opticalPartType() {
        return this.mOpticalPartType
    }
    //__________________________________________________________________________________________
    public set opticalPartType(pVal: eCadType) {
        this.mOpticalPartType = pVal;
    }
    //__________________________________________________________________________________________
    public set opticalData(pData: any) {
        this.mOpticalData = pData;
    }
    //__________________________________________________________________________________________
    public get opticalData() {
        return this.mOpticalData;
    }
    //__________________________________________________________________________________________
    public getCADFaceIndexes() {
        return this.mPart.subParts[0].shapes[0].solids[0].faces[0].indexes
    }
    //__________________________________________________________________________________________
    public userIsPartOwner() {
        let aID = this.mID;
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aID);
        let aPartOwner = aPartVO.owner;
        let aCurrUserId = Op3dContext.USER_VO.id
        if (aPartOwner == aCurrUserId) {
            return true;
        } else {
            return false;
        }
    }
    //__________________________________________________________________________________________
    public partPermission() {
        let aID = this.mID;
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(aID);
        return aPartVO.permission;
    }
    //__________________________________________________________________________________________
    protected _clearUselessOpticalData() {
        if (this.mOpticalData.faces !== undefined) {
            let aFaces = this.getFaces()[0].indexes
            let aFacesToSave = [];
            for (let i = 0; i < this.mOpticalData.faces.length; i++) {
                let aFaceIdx = aFaces.findIndex(face => JSON.stringify(face.path) === JSON.stringify(this.mOpticalData.faces[i].path))
                if (this.mOpticalData.faces[i].name === aFaces[aFaceIdx].originalName && Object.keys(this.mOpticalData.faces[i].data).length === 0) {
                    continue
                } else {
                    aFacesToSave.push(this.mOpticalData.faces[i])
                }
            }
            this.mOpticalData.faces = aFacesToSave;
        }
    }
    //__________________________________________________________________________________________
    public async saveOptomechanicsOpticalInfoInMongo(pNewFaceData?: iFaceDataNEW) {
        if (this.userIsPartOwner() === true) {
            this._clearUselessOpticalData()
            if (this.opticalData.faces == null) this.opticalData["faces"] = [];
            if (pNewFaceData !== undefined && pNewFaceData !== null) {
                let aCurrFaceIdx = this.opticalData.faces.findIndex(item => JSON.stringify(item.path) == JSON.stringify(pNewFaceData.path));
                if (aCurrFaceIdx !== -1) {
                    this.opticalData.faces[aCurrFaceIdx] = pNewFaceData;
                } else {
                    this.opticalData.faces.push(pNewFaceData);
                }
            };

            let aServerAnswer = await ServerContext.SERVER.addOpticalDataToPart({
                opticalData: JSON.stringify(this.opticalData) as any,
                number_id: Op3dContext.DATA_MANAGER.getPartVOById(this.id).number_id
            });
            Op3dContext.SCENE_HISTORY.saveScene();
            Op3dContext.PARTS_MANAGER.updateAnalysisOptions(false);
            return aServerAnswer.success;
        }
    }
    //__________________________________________________________________________________________
}