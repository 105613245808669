import { Object3D, MeshPhongMaterial, DoubleSide, Matrix4, BufferGeometry, Float32BufferAttribute, Vector3, Material, AxesHelper } from "three";
import { eAxisType, eDataPermission, eUnitType } from "../../_context/Enums";
import { OpticsContext, eOpticsTypeNames, eBaseShape, eOpticShape } from "../../_context/OpticsContext";
import { OpticsDBConvertor } from "../../_context/OpticsDBConvertor";
import { Strings } from "../../_context/Strings";
import { iHash, iMinMax } from "../../_context/_interfaces/Interfaces";
import { iSurface, iRing, MatrixUtils } from "../../_utils/MatrixUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { iOpticsVO, iParaxialLens, iApertureDataOpticsVO } from "../../data/VO/OpticsVOInterfaces";
import { iOpticsData } from "../../data/VO/PartVO";
import { ApertureDataLoader } from "../../data/data_loader/ApertureDataLoader";
import { CoatingDataLoader } from "../../data/data_loader/CoatingDataLoader";
import { SceneContext } from "../../scene/SceneContext";
import { iGratingData, SimulationContext } from "../../simulation/SimulationContext";
import { ColorUtils } from "../../ui/ColorUtils";
import { Popup } from "../../ui/forms/Popup";
import { Spinner } from "../../ui/home/Spinner";
import { UnitHandler } from "../../units/UnitsHandler";
import { OpticsShapeUtils } from "./OpticShapeUtils";
import { ThreeMaterialUtils } from "./ThreeMaterialUtils";
import { OpticsMesh } from "../../simulation/RayTracer";
import { LightSourceUtils } from "../../simulation/LightSourceUtils";
import { VertexNormalsHelper } from 'three/addons/helpers/VertexNormalsHelper.js';
import { iPart, iFace, iSolid, iShape } from "../PartInterfaces";
import { eCoatingTypes } from "../../ui/forms/optics/esCoating";

export enum eOpticsDirection {
    FRONT,
    BACK
}

export class OpticsFactory {

    //__________________________________________________________________________________________
    public static createOpticalDevice(pOpticsVO: iOpticsVO) {
        try {
            let aFaces = this.createOpticsNew(pOpticsVO);
            if (null == aFaces) {
                Popup.instance.open({
                    text: 'Optics is not supported'
                });
            }

            let aPartObject3D = new Object3D();
            let aShapeObject3D = new Object3D();
            aPartObject3D.add(aShapeObject3D);

            let aSolidObject3D = new Object3D();
            for (let i = 0; i < aFaces.length; i++) {
                aSolidObject3D.add(aFaces[i].visualization.mesh);
            }
            aShapeObject3D.add(aSolidObject3D);

            let aPart: iPart = {
                data: {
                    number_id: pOpticsVO.number_id,
                    isCustomizedOptics: OpticsContext.isCustomized(pOpticsVO),
                    isAperture: pOpticsVO.parameters.type == eOpticsTypeNames.APERTURE // new

                    // isCustomizedOptics:  (eDataPermission.PRIVATE == pOpticsVO.permission)
                },
                name: pOpticsVO.name,
                shapes: [{
                    solids: [{
                        faces: aFaces,
                        object3D: aSolidObject3D,
                        name: 'solid_0',
                        internal_id: Op3dUtils.idGenerator()
                    }],
                    name: 'shape_0',
                    object3D: aShapeObject3D,
                    internal_id: Op3dUtils.idGenerator()
                }],
                object3D: aPartObject3D,
                internal_id: Op3dUtils.idGenerator()
            };

            return aPart;
        } catch (e) {
            /**
             * @TODO only temporary message. should be ERROR_CREATING_OPTIC(e);
             */
            Popup.instance.open({
                text: 'Optics is not supported'
            });
            Spinner.instance.hide();
            //MessagesHandler.ERROR_CREATING_OPTIC(e);
        }
    }
    //__________________________________________________________________________________________
    public static getDefaultParaxialLensVO() {
        let aParaxialLensGeometry = this._getDefaultParaxialLensGeometry();

        let aOpticsVO: iOpticsVO = {
            name: Strings.PARAXIAL_LENS_NAME,
            parameters: {
                baseShape: eBaseShape.CIRCULAR,
                coating: null,
                geometry: aParaxialLensGeometry,
                materialID: Strings.TRANSPARENT_MATERIAL,
                shape: eOpticShape.PARAXIAL_LENS,
                info: {
                    brand: '3DOptix',
                    weblinks: null,
                },
                type: eOpticsTypeNames.LENS,
                subType: OpticsContext._Paraxial_Lens
            },
            number_id: null,
            permission: eDataPermission.PRIVATE
        };

        return aOpticsVO;
    }
    //__________________________________________________________________________________________
    private static _getDefaultParaxialLensGeometry() {
        let aParaxialLens: iParaxialLens;
        switch (UnitHandler.PRESENTED_UNIT) {
            case eUnitType.INCHES:
                aParaxialLens = {
                    diameter: 25.4,
                    paraxialEFL: 101.6
                };
                break;
            case eUnitType.MILLIMETERS:
                aParaxialLens = {
                    diameter: 25,
                    paraxialEFL: 100
                };
                break;
            default:
                throw new Error("Error with unit");
        }

        return aParaxialLens;
    }
    //__________________________________________________________________________________________

    public static getMeshesFromSurfaces(pSurfaces: Array<iSurface>) {
        let aMeshes = pSurfaces.map((surface) => {
            let aVertices = surface.vertices;
            let aNormals = surface.normals;
            let aMaterial = new MeshPhongMaterial();
            aMaterial.side = DoubleSide;

            let aMatrix4 = surface.matrix;
            let aRotationMatrix = new Matrix4().extractRotation(aMatrix4);

            let aGeo = new BufferGeometry();
            aGeo.setAttribute('position', new Float32BufferAttribute(aVertices, 3));
            aGeo.setAttribute('normal', new Float32BufferAttribute(aNormals, 3));
            // aGeo.computeVertexNormals();
            let aMesh = new OpticsMesh(aGeo, aMaterial);

            aMesh.rotation.setFromRotationMatrix(aRotationMatrix);
            aMesh.position.setFromMatrixPosition(aMatrix4);
            aMesh.name = surface.surfaceData.name;
            aMesh.updateMatrix();
            aMesh.updateMatrixWorld(true);

            return aMesh;
        });

        return aMeshes;
    }
    //__________________________________________________________________________________________
    private static _createCircularOptic(pOpticsVO: iOpticsVO) {
        let aShape = pOpticsVO.parameters.shape;
        let aSurfaces: Array<iSurface>;
        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as any;

        switch (aShape) {
            case eOpticShape.PARAXIAL_LENS:
                aSurfaces = MatrixUtils.getParaxialLens(aGeometry);
                break;
            case eOpticShape.MULTIPLET:
                aSurfaces = MatrixUtils.getNPlateLens(aGeometry);
                break;
            case eOpticShape.AXICON_PRISM:
                aSurfaces = MatrixUtils.getCircularAxicon(aGeometry);
                break;
            case eOpticShape.ROOF_PRISM_MIRROR:
                aSurfaces = MatrixUtils.getCircularRoofPrism(aGeometry);
                break;
            case eOpticShape.WEDGE:
                aSurfaces = MatrixUtils.getCircularWedgePrism(aGeometry);
                break;
            case eOpticShape.D_SHAPED:
                aSurfaces = MatrixUtils.getDShaped(aGeometry);
                break;
            case eOpticShape.ASPHERIC:
                aSurfaces = MatrixUtils.getCircularASphericLens(aGeometry);
                break;
            case eOpticShape.THIN_LENS:
                aSurfaces = MatrixUtils.getCircularThinLens(aGeometry);
                break;
            case eOpticShape.PARABOLIC:
            case eOpticShape.OFF_AXIS_PARABOLIC:
            case eOpticShape.CYLINDRICAL:
            case eOpticShape.CONIC:
            case eOpticShape.BALL_LENS:
            case eOpticShape.HALF_BALL_LENS:
            case eOpticShape.SPHERICAL:
                aSurfaces = MatrixUtils.getGeneralConicLens(aGeometry);
                break;
            case eOpticShape.GRATING:

                let aTransmission: iMinMax;
                let aReflection: iMinMax;

                if (OpticsContext.isReflectiveGrating(pOpticsVO.parameters.subType)) {
                    aTransmission = { max: -1, min: 0 };
                    aReflection = { max: 2, min: -2 };
                } else {
                    aTransmission = { max: 2, min: -2 };
                    aReflection = { max: 2, min: -2 };
                }

                let aGratingSide = pOpticsVO.parameters.physical_data.grating_side;
                let aGroovesNM = pOpticsVO.parameters.physical_data.grooves / 1e6;
                let aGratingData: iGratingData = {
                    groove_pitch: 1 / aGroovesNM,
                    groove_orientation: [
                        pOpticsVO.parameters.physical_data.orientation_vector.x,
                        pOpticsVO.parameters.physical_data.orientation_vector.y,
                        pOpticsVO.parameters.physical_data.orientation_vector.z],
                    min_reflection_order: aReflection.min,
                    max_reflection_order: aReflection.max,
                    min_transmission_order: aTransmission.min,
                    max_transmission_order: aTransmission.max
                };
                aSurfaces = MatrixUtils.getCircularGrating(aGeometry,
                    aGratingData, aGratingSide);
                break;
            default:
                throw new Error("Shape is not supported at the moment");
        }

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    private static _createRectanuglarOptic(pOpticsVO: iOpticsVO) {
        let aShape = pOpticsVO.parameters.shape;
        let aSurfaces: Array<iSurface>;
        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as any;

        switch (aShape) {
            case eOpticShape.WEDGE:
                aSurfaces = MatrixUtils.getRectWedgePrism(aGeometry);
                break;
            case eOpticShape.APERTURE:
                aSurfaces = MatrixUtils.getRectangularAperture(aGeometry,
                    aGeometry.thickness, pOpticsVO.parameters.physical_data);
                break;
            case eOpticShape.GRATING:
                let aTransmission: iMinMax;
                let aReflection: iMinMax;

                if (OpticsContext.isReflectiveGrating(pOpticsVO.parameters.subType)) {
                    aTransmission = { max: -1, min: 0 };
                    aReflection = { max: 2, min: -2 };
                } else {
                    aTransmission = { max: 2, min: -2 };
                    aReflection = { max: 2, min: -2 };
                }

                let aGratingSide = pOpticsVO.parameters.physical_data.grating_side;
                let aGroovesNM = pOpticsVO.parameters.physical_data.grooves / 1e6;
                let aGratingData: iGratingData = {
                    groove_pitch: 1 / aGroovesNM,
                    groove_orientation: [
                        pOpticsVO.parameters.physical_data.orientation_vector.x,
                        pOpticsVO.parameters.physical_data.orientation_vector.y,
                        pOpticsVO.parameters.physical_data.orientation_vector.z],
                    min_reflection_order: aReflection.min,
                    max_reflection_order: aReflection.max,
                    min_transmission_order: aTransmission.min,
                    max_transmission_order: aTransmission.max
                };
                aSurfaces = MatrixUtils.getRectangularGrating(aGeometry,
                    aGeometry.thickness, aGratingData, aGratingSide);
                break;
            case eOpticShape.THIN_LENS:
                aSurfaces = MatrixUtils.getPolygonBoxOptics(aGeometry);
                break;
            case eOpticShape.CYLINDRICAL:
                aSurfaces = MatrixUtils.getGeneralRectangularConicLens(aGeometry);
                break;
            case eOpticShape.ROOF_PRISM_MIRROR:
                aSurfaces = MatrixUtils.getSquaredRoofPrismMirror(aGeometry);
                break;
            default:
                throw new Error("Shape is not supported at the moment");
        }

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    private static _createVolumsOptic(pOpticsVO: iOpticsVO) {
        let aShape = pOpticsVO.parameters.shape;
        let aSurfaces: Array<iSurface>;
        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as any;

        switch (aShape) {
            case eOpticShape.RIGHT_ANGLE_PRISM:
                aSurfaces = MatrixUtils.getRightAnglePrism(aGeometry);
                break;
            case eOpticShape.DISPERSION_PRISM:
                aSurfaces = MatrixUtils.getDispertionPrism(aGeometry);
                break;
            case eOpticShape.RHOMBIC_PRISM:
                aSurfaces = MatrixUtils.getRhombicPrism(aGeometry);
                break;
            case eOpticShape.PELLIN_BROCA_PRISM:
                aSurfaces = MatrixUtils.getPellinBrocaPrism(aGeometry);
                break;
            case eOpticShape.PENTA_PRISM:
                aSurfaces = MatrixUtils.getPentaPrism(aGeometry);
                break;
            case eOpticShape.EQUAL_PRISM:
                aSurfaces = MatrixUtils.getEquilateralPrism(aGeometry);
                break;
            case eOpticShape.DOVE_PRISM:
                aSurfaces = MatrixUtils.getDovePrism(aGeometry);
                break;
            case eOpticShape.BS_CUBE:
                aSurfaces = MatrixUtils.getBSCube(aGeometry);
                break;
            default:
                throw new Error("Shape does not supported at the moment");
        }

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getOpticsOffset(pOpticsData: iOpticsData, pOptics: iPart) {
        if (null == pOpticsData) {
            return null;
        }

        let aOpticsSize = Op3dUtils.getNetoItemSize(pOptics.object3D);
        let aSize = new Vector3();

        if ((null != pOpticsData.geometry) && (null != pOpticsData.geometry.diameter) &&
            (eOpticsDirection.FRONT == pOpticsData.opticsDirection)) {
            let aOffsetZ = -(aOpticsSize.z);
            aSize.set(0, 0, aOffsetZ);

        } else if (eBaseShape.RECTANGULAR == pOpticsData.baseShape) {
            let aOffsetX = 0;
            if ((null != pOpticsData.opticsDirectionX) &&
                (null != pOpticsData.geometry.height)) {

                if ((aOpticsSize.x / 2) > (pOpticsData.geometry.height / 2)) {
                    aOffsetX = ((aOpticsSize.x - pOpticsData.geometry.height) / 2);
                    if (eOpticsDirection.BACK == pOpticsData.opticsDirectionX) {
                        aOffsetX *= -1;
                    }
                }
            }
            let aOffsetY = 0;
            if ((null != pOpticsData.opticsDirectionY) &&
                (null != pOpticsData.geometry.width)) {

                if ((aOpticsSize.y / 2) > (pOpticsData.geometry.width / 2)) {
                    aOffsetY = ((aOpticsSize.y - pOpticsData.geometry.width) / 2);
                    if (eOpticsDirection.BACK == pOpticsData.opticsDirectionY) {
                        aOffsetY *= -1;
                    }
                }
            }

            let aOffsetZ = 0;
            if (eOpticsDirection.FRONT == pOpticsData.opticsDirection) {
                aOffsetZ -= aOpticsSize.z;
            }

            aSize.set(aOffsetX, aOffsetY, aOffsetZ);
        } else if (eBaseShape.VOLUME == pOpticsData.baseShape) {
            let aOffsetY = (aOpticsSize.y / 2);
            aSize.set(0, aOffsetY, 0);
        }

        return aSize;
    }
    //__________________________________________________________________________________________
    public static createOpticsNew(pOpticsVO: iOpticsVO): Array<iFace> {
        let aBaseShape = pOpticsVO.parameters.baseShape;
        let aSurfaces: Array<iSurface>;
        switch (aBaseShape) {
            case eBaseShape.CIRCULAR: {
                aSurfaces = this._createCircularOptic(pOpticsVO);
                break;
            }
            case eBaseShape.RECTANGULAR: {
                aSurfaces = this._createRectanuglarOptic(pOpticsVO);
                break;
            }
            case eBaseShape.VOLUME: {
                aSurfaces = this._createVolumsOptic(pOpticsVO);
                break;
            }
            default:
                throw new Error("unknown baseshape");
        }

        if (eOpticShape.MULTIPLET == pOpticsVO.parameters.shape) {
            return this._getNPlateFaces(pOpticsVO, aSurfaces);
        }

        let aMaterialID = pOpticsVO.parameters.materialID;
        let aFaces = aSurfaces.map((surface) => {
            let aSurfaceName = surface.surfaceData.name;
            const aIsInactive = OpticsContext.isInactiveFace(pOpticsVO, aSurfaceName);
            const aIsMirror = OpticsContext.isMirrorFace(pOpticsVO, aSurfaceName);

            return OpticsFactory.getFace({
                surface: surface,
                materialID: aMaterialID,
                coating: pOpticsVO.parameters.coating,
                isMirror: aIsMirror,
                isInactive: aIsInactive,
            });
        });

        return aFaces;
    }
    //__________________________________________________________________________________________
    private static _getNPlateFaces(pOpticsVO: iOpticsVO, pSurfaces: Array<iSurface>) {
        let aFaces = new Array<iFace>();

        let aMaterials = pOpticsVO.parameters.materialID.split(';');
        for (let i = 0; i < aMaterials.length; i++) {
            let aLastFace = aFaces[(aFaces.length - 1)];
            aFaces.push(OpticsFactory.getFace({
                surface: pSurfaces[(2 * i)],
                materialID: aMaterials[i],
                outside_material: ((undefined !== aLastFace) ? aLastFace.data.outside_material :
                    undefined),
                inside_material: aMaterials[i],
                coating: pOpticsVO.parameters.coating
            }));
            aFaces.push(OpticsFactory.getFace({
                surface: pSurfaces[((2 * i) + 1)],
                materialID: aMaterials[i],
                outside_material: aMaterials[(i + 1)],
                inside_material: aMaterials[i],
                coating: pOpticsVO.parameters.coating
            }));
        }

        aFaces.push(OpticsFactory.getFace({
            surface: pSurfaces[(pSurfaces.length - 1)],
            materialID: aMaterials[0],
            coating: pOpticsVO.parameters.coating,
        }));

        return aFaces;
    }
    //__________________________________________________________________________________________
    // private static _createOptics(pOpticsVO: iOpticsVONew) {
    //     return OpticsFactory._createOpticsBySurfaces(pOpticsVO);
    // }
    //__________________________________________________________________________________________
    public static getMaterialByCoating(pSurface: iSurface, pCoatingID: string,
        pIsMirror: boolean): Material {

        const aSingleSurfaceData = pSurface.surfaceData;
        const aCoatingDL = CoatingDataLoader.instance;
        const aCoating = aCoatingDL.getFromCache(pCoatingID);
        let aMaterial: Material;

        if (null == aCoating) {
            aMaterial = ThreeMaterialUtils.createUncoatedMat();

        } else {
            let aCoatingForColor = (true == aSingleSurfaceData.isMirror || pIsMirror == true) ?
                aCoating.R_P : aCoating.T_P;

            const aColor = LightSourceUtils.getColor2(aCoating.wl,
                aCoatingForColor);
            const aRGB = aColor.color;
            if (Number.isNaN(aColor.color.r) || Number.isNaN(aColor.color.g) || Number.isNaN(aColor.color.b)) {
                aColor.color.r = 255;
                aColor.color.g = 255;
                aColor.color.b = 255;
            }
            const aHexColor = ColorUtils.rgbToNumber(aRGB.r, aRGB.g, aRGB.b);
            aMaterial = ThreeMaterialUtils.createCoatedMat(aHexColor, 1/**aColor.alpha */);
        }

        return aMaterial;
    }
    //__________________________________________________________________________________________
    public static getIris(pRing: iRing) {
        let aIrisSurfaces = MatrixUtils.getOutsideCircleFrame(pRing);
        let aFaces = aIrisSurfaces.map((surface) => {
            return OpticsFactory.getFace({
                surface: surface,
                materialID: Strings.TRANSPARENT_MATERIAL,
            });
        });

        (aFaces[0].visualization.mesh.material as any).color.setHex(0xA0A0A0);

        let aPartObject3D = new Object3D();
        let aShapeObject3D = new Object3D();
        aPartObject3D.add(aShapeObject3D);

        let aSolidObject3D = new Object3D();
        aShapeObject3D.add(aSolidObject3D);
        for (let i = 0; i < aFaces.length; i++) {
            aSolidObject3D.add(aFaces[i].visualization.mesh);
        }

        let aSolid: iSolid = {
            faces: aFaces,
            name: 'solid_0',
            object3D: aSolidObject3D,
            internal_id: Op3dUtils.idGenerator()
        };

        let aShape: iShape = {
            name: 'shape_0',
            object3D: aShapeObject3D,
            solids: [aSolid],
            internal_id: Op3dUtils.idGenerator()
        };

        let aIrisPart: iPart = {
            shapes: [aShape],
            object3D: aPartObject3D,
            data: {
                toHighlight: false
            },
            internal_id: Op3dUtils.idGenerator()
        };

        return aIrisPart
    }
    //__________________________________________________________________________________________
    public static getFace(pData: {
        surface: iSurface;
        materialID: string;
        inside_material?: string;
        outside_material?: string;
        coating?: iHash<string>;
        isMirror?: boolean;
        isInactive?: boolean;
        is_inside_blackbox?: boolean;
    }) {

        let aSingleSurfaceData = pData.surface.surfaceData;
        let aServerCoating = (null != pData.coating) ? pData.coating : {};

        let aMaterial: Material;
        let aCoatingID: string;

        let aIsMirror = ((true == pData.surface.surfaceData.isMirror) ||
            (true == pData.isMirror));

        if (true == aSingleSurfaceData.isInactive || pData.isInactive ||
            aServerCoating[aSingleSurfaceData.name] == Strings.ABSORBING) {

            aCoatingID = Strings.ABSORBING;
            aMaterial = ThreeMaterialUtils.createInActiveMat();

        } else {
            aCoatingID = aServerCoating[aSingleSurfaceData.name];
            if (null == aCoatingID) {
                aMaterial = ThreeMaterialUtils.createUncoatedMat();
            } else if (null != pData.surface.paraxialEFL) {
                aMaterial = ThreeMaterialUtils.createParaxialLensMat();
            } else {
                aIsMirror = aCoatingID === eCoatingTypes.IDEAL_REFLECTOR ? true : aIsMirror;
                aMaterial = this.getMaterialByCoating(pData.surface, aCoatingID, aIsMirror);
            }
        }

        if (Strings.TRANSPARENT_MATERIAL == pData.materialID) {
            aMaterial = ThreeMaterialUtils.createMat();
        }

        let aScaleMatrix = new Matrix4();
        let aScale = UnitHandler.scale;
        let aVertices = pData.surface.vertices;
        for (let i = 0; i < aVertices.length; i++) {
            aVertices[i] *= aScale;
        }

        aScaleMatrix.makeScale(aScale, aScale, aScale);

        let aEmmisive = (aMaterial as any).emissive.clone();
        let aIsApertureTexture = false;
        if (pData.surface.apertureData != null) {
            aMaterial = this._createUserApertureMaterial(aMaterial, pData.surface.apertureData);
            aIsApertureTexture = true;
        }

        let aMesh = OpticsShapeUtils.createMeshNew(aMaterial, aVertices);
        if (aIsApertureTexture === true) {
            let uvs = aMesh.geometry.attributes.uv.array;
            for (let i = 0; i < uvs.length; i += 2) {
                (uvs[i] as any) = 1.0 - uvs[i];
            }
            aMesh.geometry.attributes.uv.needsUpdate = true;
        }


        let aMatrix4 = pData.surface.matrix;
        let aRotationMatrix = new Matrix4().extractRotation(aMatrix4);
        aMesh.rotation.setFromRotationMatrix(aRotationMatrix);
        aMesh.position.setFromMatrixPosition(aMatrix4);
        aMesh.name = pData.surface.surfaceData.name;
        aMesh.position.applyMatrix4(aScaleMatrix);

        let aFace: iFace = {
            data: {
                paraxialEFL: pData.surface.paraxialEFL,
                frameData: pData.surface.frameData,
                shape: pData.surface.shape,
                coatingID: aCoatingID,
                materialID: pData.materialID,
                simGeoData: pData.surface.simGeoData,
                shapeData: pData.surface.shapeData,
                gratingData: pData.surface.gratingData,
                apertureData: pData.surface.apertureData,
                phase_profile: pData.surface.phase_profile,
                jones_matrix: pData.surface.jonesMatrix,
                is_inside_blackbox: pData.is_inside_blackbox,
                reflectionData: {
                    enabled: ((true == aIsMirror) && (Strings.ABSORBING != aCoatingID)),
                    max_num_bounces: SimulationContext.DEFAULT_MAX_BOUNCES
                }
            },
            name: aSingleSurfaceData.name,
            originalName: aSingleSurfaceData.name,
            visualization: {
                mesh: aMesh,
                emissive: aEmmisive
            },
            internal_id: Op3dUtils.idGenerator()
        };

        if (undefined !== pData.inside_material) {
            aFace.data.inside_material = pData.inside_material;
        }
        if (undefined !== pData.outside_material) {
            aFace.data.outside_material = pData.outside_material;
        }

        if (false == pData.surface.isForSimulation) {
            aFace.isForSimulation = false;

        } else {
            let aAxis = SceneContext.OP3D_SCENE.getAxisModel();
            aMesh.add(aAxis);

            aFace.axes = [{
                object3D: aAxis,
                name: aFace.name + ' CS',
                internal_id: Op3dUtils.idGenerator(),
                type: eAxisType.GENERAL
            }];
        }

        if (pData.surface.isTransparent == true) {
            (aFace.visualization.mesh.material as any).transparent = true;
            (aFace.visualization.mesh.material as any).opacity = 0.1;
        }

        return aFace;
    }
    //__________________________________________________________________________________________
    private static _createUserApertureMaterial(pCurrMat: Material,
        pApertureData: iApertureDataOpticsVO) {

        let aURL: string;

        if (pApertureData.transmittance_mask != null) {
            aURL = pApertureData.transmittance_mask.url || pApertureData.transmittance_mask.name;
        } else if (pApertureData.phase_mask != null) {
            aURL = pApertureData.phase_mask.url || pApertureData.phase_mask.name;
        }
        // let aUrl = pApertureData.transmittance_mask != null ? pApertureData.transmittance_mask.url || pApertureData.transmittance_mask.name : null;
        // aUrl = aUrl || pApertureData.phase_mask.url || pApertureData.phase_mask.name;

        let aMaterial = ApertureDataLoader.instance.getMaskByURL(aURL);

        if (aMaterial != null) {
            pCurrMat = aMaterial.clone();
        }

        return pCurrMat;
    }
    //__________________________________________________________________________________________
    // public static addOffPolynomial() {
    //     let aContainer = new Object3D();
    //     let aMMToInches = 1 / 25.4;
    //     let aConvex = this._createSingleSurface(eBaseShape.CIRCULAR, {
    //         innerRadius: 0,
    //         diameter: ,
    //         K: ,
    //         C: ,
    //         thicknessCenter: 0.1,
    //         coef: [

    //         ],
    //         shape: eOpticSurfaceShape.ODD_POLYNOMIAL,
    //     }, "front");

    //     this._convertToBufferGeomtry(aConvex.shape, "pOpticsVO.name");
    //     aContainer.add(aConvex.shape);
    //     var axesHelper = new AxesHelper(5);
    //     aConvex.shape.add(axesHelper);
    //     Op3dContext.PARTS_MANAGER.mPartsContainer.add(aContainer);
    // }
    //__________________________________________________________________________________________
    // public static addQConAspheric() {
    //     let aContainer = new Object3D();
    //     let aMMToInches = 1 / 25.4;
    //     let aC = -0.5 * aMMToInches;
    //     let aConvex = this._createSingleSurface(eBaseShape.CIRCULAR, {
    //         innerRadius: 0,
    //         diameter: (3.5 * 2) * aMMToInches,
    //         K: 0.156284,
    //         C: aC,
    //         thicknessCenter: 0.1,
    //         coefs: [
    //             0.070858 * aMMToInches,
    //             -0.057065 * aMMToInches,
    //             -0.009991999999999999 * aMMToInches,
    //             -0.002825 * aMMToInches,
    //             -0.000705 * aMMToInches,
    //             -0.000195 * aMMToInches,
    //             -0.000054 * aMMToInches,
    //             -0.16e-4 * aMMToInches
    //         ],
    //         shape: eOpticSurfaceShape.QCON_ASPHERE,
    //     }, "front");

    //     this._convertToBufferGeomtry(aConvex.shape, "pOpticsVO.name");
    //     aContainer.add(aConvex.shape);
    //     var axesHelper = new AxesHelper(5);
    //     aConvex.shape.add(axesHelper);
    //     Op3dContext.PARTS_MANAGER.mPartsContainer.add(aContainer);
    // }
    //__________________________________________________________________________________________
    // public static addXyPolinomial() {
    //     let aContainer = new Object3D();
    //     let aMMToInches = 1 / 25.4;
    //     let aConvex = this._createSingleSurface(eBaseShape.CIRCULAR, {
    //         name: "front",
    //         geometry: {
    //             innerRadius: 0,
    //             coefs: [
    //                 -0.0008408851407823 * aMMToInches,
    //                 0.0008208998508642 * aMMToInches,
    //                 -2.441348764365e-05 * aMMToInches,
    //                 - 0.07147059800929 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches,
    //                 1.629342297907 * aMMToInches,
    //                 - 2.52393127212e-05 * aMMToInches, 0.1234113007817 * aMMToInches,
    //                 0.001330147899051 * aMMToInches, - 0.2886512201018 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches,
    //                 0.007761115758132 * aMMToInches,
    //                 - 3.128684839151e-05 * aMMToInches, 0.4521101023143 * aMMToInches,
    //                 - 0.001923523004335 * aMMToInches, 3.600441984672 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches, 0 * aMMToInches,
    //                 0.06679801752254 * aMMToInches,
    //                 0.0002347244032207 * aMMToInches, - 0.767907581329 * aMMToInches,
    //                 - 0.004611540734948 * aMMToInches, - 6.38773713812 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches, 0
    //                 - 0.02767070107232 * aMMToInches,
    //                 - 0.0001896016125396 * aMMToInches, 0.3130866192706 * aMMToInches,
    //                 0.006232945535314 * aMMToInches, 3.137608882167 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches, 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches, 0 * aMMToInches,
    //                 0 * aMMToInches,
    //             ],
    //             diameter: 0.984251968503937,
    //             shape: eOpticSurfaceShape.XY_POLYNOMIAL,
    //             cylindricalDirection: eCylindricalDirection.Y
    //         },
    //     }, "front");

    //     this._convertToBufferGeomtry(aConvex.shape, "pOpticsVO.name");
    //     aContainer.add(aConvex.shape);
    //     var axesHelper = new AxesHelper(5);
    //     aConvex.shape.add(axesHelper);
    //     Op3dContext.PARTS_MANAGER.mPartsContainer.add(aContainer);
    // }
    //__________________________________________________________________________________________
    public static addAxesHelper(pObj: Object3D) {
        var axesHelper = new AxesHelper(5);
        pObj.add(axesHelper);
    }
    //__________________________________________________________________________________________
    public static addAxesHelperToSurface(pObj: Object3D, pIsFront: boolean) {
        if (pIsFront) {
            let aSurface = pObj.getObjectByName("front");
            var axesHelper = new AxesHelper(5);
            aSurface.add(axesHelper);
        } else {
            let aSurface = pObj.getObjectByName("back");
            var axesHelper = new AxesHelper(5);
            aSurface.add(axesHelper);
        }
    }
    //__________________________________________________________________________________________
    public static addNormals(pObj: Object3D) {
        let aColors = [0xff0000, 0x00ff00, 0x0000ff, 0xff00ff, 0xffff00, 0x00ffff]
        for (let i = 0; i < pObj.children.length; i++) {
            let aObj = pObj.children[i];
            let aColor = aColors[(i % aColors.length)];
            const helperFront = new VertexNormalsHelper(aObj, 12, aColor); // change library and deleted 4th paramater == 4
            SceneContext.MAIN_SCENE.add(helperFront);
        }
    }
    //__________________________________________________________________________________________
}
