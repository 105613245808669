import { EventBase } from "../../oc/events/EventBase";
import { EventManager } from "../../oc/events/EventManager";
import { eSidebarState } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { iRenameSetupData } from "../_context/_interfaces/Interfaces";
import { HeaderWidget } from "../_widget/HeaderWidget";
import { Part } from "../parts/Part";
import { Op3dComponentBase } from "./Op3dComponentBase";
import { Header } from "./home/Header";
import { Menu } from "./home/Menu";
import { SideBar } from "./home/SideBar";
import { op3dTooltip } from "./home/op3dTooltip";
import { OpticsMenu } from "./menus/_search/OpticsMenu";
import { PartInfo } from "./part_info/PartInfo";

export abstract class absDivController {

    private mCompBase: Array<Op3dComponentBase>;
    protected mHeader: Header | HeaderWidget;
    protected mSideBar: SideBar;

    constructor() {
        this._init();
    }
    //_______________________________________________________________________________________
    public showSpectrumChart(_pData: any) { }
    //_______________________________________________________________________________________
    public onOpenSearchMenu(_pType, _pOpenData: any) { }
    //_______________________________________________________________________________________
    public foldSideBar(_pSize: eSidebarState) { }
    //_______________________________________________________________________________________
    protected _init() {
        OpticsMenu.instance;
        new op3dTooltip();
        Op3dContext.PART_INFO = new PartInfo();
        this._addEventListeners();
    }
    //_______________________________________________________________________________________
    public updateSections(pSections: Array<string>) {
        Op3dContext.PART_INFO.updateSections(pSections);
    }
    //_______________________________________________________________________________________
    public updatePartInfo() {
        Op3dContext.PART_INFO.update();
    }
    //_______________________________________________________________________________________
    public openContextMenu(_pEvent: MouseEvent, _pPartUnder: Part) { }
    //_______________________________________________________________________________________
    protected abstract _onPartSelected(pPart: Part);
    //_______________________________________________________________________________________
    private _addEventListeners() {
        EventManager.addEventListener(EventsContext.PART_SELECTED,
            (pData: EventBase<Part>) => this._onPartSelected(pData.data),
            this);
        EventManager.addEventListener(EventsContext.PART_DESELECTED,
            (_pData: EventBase<Part>) => Op3dContext.PART_INFO.close(), this);
    }
    //_______________________________________________________________________________________
    public setFileName(pData: iRenameSetupData) {
        Menu.instance.setFileName(pData);
    }
    //_______________________________________________________________________________________
    public get sideBar() {
        return this.mSideBar;
    }
    //_______________________________________________________________________________________

    public removeFromOpenFormsArr(pCompBase: Op3dComponentBase) {
        if (this.mCompBase == null) {
            return;
        }
        for (let i = 0; i < this.mCompBase.length; i++) {
            if (this.mCompBase[i] == pCompBase) {
                this.mCompBase.splice(i, 1);
                break;
            }
        }
    }
    //_______________________________________________________________________________________
    public setLinesVisibility(_pBoolean: boolean) {
        this.mHeader.hideLines();
    }
    //_______________________________________________________________________________________
    public get header() {
        return this.mHeader;
    }
    //_______________________________________________________________________________________
}
