﻿import { iLightSourceCategories } from "../../_context/_interfaces/Interfaces";
import { ParserContext } from "../../parser/ParserContext";
import { iSourceVO } from "../../parser/SourcesParser";

export class LightSourceInfoContext {

    //__________________________________________________________________________________________
    // private static _getLightSourceType(pData: iSourceVO) {
    //     let aType = eLightSourceType[pData.parameters.type];
    //     aType = aType.replace("_", " ");
    //     return aType;
    // }
    //__________________________________________________________________________________________
    public static LIGHT_SOURCE_BASIC_FIELDS_MAP: Array<iLightSourceCategories> =
        [
            {
                title: "Type",
                path: "parameters.type",
                class_list: ["capitalize-text"],
                // translate: (pData: iSourceVO) => this._getLightSourceType(pData)
            },

            {
                title: "Width",
                path: "parameters.data.bandwidth",
                sign: ParserContext.SPECIAL_CHARACTERS.nm
            },
            {
                title: "Wavelength",
                path: "parameters.data.center_wavelength",
                sign: ParserContext.SPECIAL_CHARACTERS.nm
            },
            {
                title: "Wavelengths",
                path: "parameters.data.wavelengths",

                translate: (pData: iSourceVO) => this._getWavelengths(pData)
            },
            {
                title: "Wavelength Weights",
                path: "parameters.data.wavelengthWights",
                translate: (pData: iSourceVO) => this._getWavelengthWeights(pData)
            },
            {
                title: "Temperature",
                path: "parameters.data.temperature",
                sign: ParserContext.SPECIAL_CHARACTERS.k_sign
            }
        ]
    //__________________________________________________________________________________________
    private static _getWavelengthWeights(pSourceVO: iSourceVO) {
        let aWeights = pSourceVO.parameters.data.wavelengthWights;
        if (aWeights == null) {
            return null;
        }
        let aStr = "";
        for (let i = 0; i < aWeights.length; i++) {
            aStr += aWeights[i] + " ";
        }
        return aStr;
    }
    //__________________________________________________________________________________________
    private static _getWavelengths(pSourceVO: iSourceVO) {
        let aWavelengths = pSourceVO.parameters.data.wavelengths;
        if (aWavelengths == null) {
            return null;
        }

        const aSign = ParserContext.SPECIAL_CHARACTERS.nm;
        let aStr = "";
        for (let i = 0; i < aWavelengths.length; i++) {
            aStr += aWavelengths[i] + aSign + " ";
        }
        return aStr;
    }
    //__________________________________________________________________________________________
}
