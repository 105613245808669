﻿
import { Chart, ChartConfiguration, ChartData } from "chart.js/auto";

import { iCoatingVO, iMinMax } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { Popup } from "../forms/Popup";
import { Op3dContext } from "../../_context/Op3dContext";
import html2canvas from "html2canvas";
import { SnapshotTools } from "../../_utils/SnapshotTools";
import { tMimeType } from "../../_utils/FileUtils";
import { DataUtils } from "../../_utils/DataUtils";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { saveAs } from 'file-saver';
import { Strings } from "../../_context/Strings";

export class CoatingChart extends Op3dComponentBase<iCoatingVO> {

    private static INSTANCE: CoatingChart;
    private static COLORS_ARRAY = ['#23A7DE', '#1DA79D', '#8CC442', '#FFC31B'];
    private static DEFAULT_RANGE: iMinMax = { min: 150, max: 1500 };

    private mGraphContainer!: HTMLElement;
    private mStartRange!: HTMLInputElement;
    private mEndRange!: HTMLInputElement;
    private mCurrGraphData: ChartData;

    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/charts/coating_chart.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //_____________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aForms = document.getElementById('forms');
            if (aForms === null) {
                throw new Error("missing forms container");
            }

            let aDiv = document.createElement("div");
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.classList.add("analysis-modal");
            aDiv.style.width = "500px";
            aDiv.setAttribute("data-backdrop", "false");
            aForms.appendChild(aDiv);

            this.INSTANCE = new CoatingChart(aDiv);
        }

        return this.INSTANCE;
    }
    //_____________________________________________________________________________________________
    protected _onCreationComplete() {

        $(this.mContainer).find('.premium-feature').each((_index, element) => {
            if (document.body.classList.contains(Strings.BASIC_LICENSE)) {
                (element as HTMLElement).onclick = () => window.open('https://3doptix.com/pricing/', '_blank');
            }
        });

        this.mIsReady = true;
        ViewUtils.makeUnselectable(this.mContainer);
    }
    //_____________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        this.onFocusClose();
        this.mData = null;
    }
    //_____________________________________________________________________________________________
    private _drawGraphs(pData: iCoatingVO) {


        let aTpNew = new Array<number>();
        let aTsNew = new Array<number>();
        let aRpNew = new Array<number>();
        let aRsNew = new Array<number>();

        for (let i = 0; i < pData.wl.length; i++) {
            aTpNew.push((true == pData.perElement) ? Math.sqrt(pData.T_P[i]) : pData.T_P[i]);
            aTsNew.push((true == pData.perElement) ? Math.sqrt(pData.T_S[i]) : pData.T_S[i]);
            aRpNew.push((true == pData.perElement) ? Math.sqrt(1 - pData.T_P[i]) : pData.R_P[i]);
            aRsNew.push((true == pData.perElement) ? Math.sqrt(1 - pData.T_S[i]) : pData.R_S[i]);
        }

        const data: ChartData = {
            labels: pData.wl,
            datasets: [{
                label: 'Reflection P',
                data: aRpNew,
                pointRadius: 0,
                borderWidth: 1,
                backgroundColor: CoatingChart.COLORS_ARRAY[0],
                borderColor: CoatingChart.COLORS_ARRAY[0],
            }, {
                label: 'Reflection S',
                data: aRsNew,
                pointRadius: 0,
                borderWidth: 1,
                backgroundColor: CoatingChart.COLORS_ARRAY[1],
                borderColor: CoatingChart.COLORS_ARRAY[1],
            }, {
                label: 'Transmission P',
                data: aTpNew,
                pointRadius: 0,
                borderWidth: 1,
                backgroundColor: CoatingChart.COLORS_ARRAY[2],
                borderColor: CoatingChart.COLORS_ARRAY[2],
            }, {
                label: 'Transmission S',
                data: aTsNew,
                pointRadius: 0,
                borderWidth: 1,
                backgroundColor: CoatingChart.COLORS_ARRAY[3],
                borderColor: CoatingChart.COLORS_ARRAY[3],
            }]
        };

        this.mCurrGraphData = data;


        const config: ChartConfiguration = {
            type: 'line',
            data: data,
            options: {
                plugins: {
                    tooltip: {
                        intersect: false,
                        displayColors: false
                    },
                    legend: {
                        display: true
                    }
                }
            }
        };

        let aGraphCanvas = document.createElement('canvas');
        ViewUtils.clearElementsChildren(this.mGraphContainer)
        this.mGraphContainer.appendChild(aGraphCanvas);
        aGraphCanvas.id = Op3dUtils.idGenerator();
        let aBB = this.mGraphContainer.getBoundingClientRect();
        aGraphCanvas.width = aBB.width;
        aGraphCanvas.height = aBB.height;

        let a2D = aGraphCanvas.getContext('2d');



        new Chart(a2D, config);
    }
    //_____________________________________________________________________________________________
    protected async _onOpen(pData?: iCoatingVO) {
        this.mData = pData;

        this.onFocusOpen();
        this._setInitialRange();
        this._onDispaly();

        if (true == Op3dContext.USER_VO.isAdmin) {
            let aCoatingIDMsg = '%c' + "Coating name: " + pData.name;
            aCoatingIDMsg += (" \nCoating number_id: " + pData.number_id);
            let aMsgStyle = 'font-size:15px; margin:2px; background: #40E0D0; color: #000000';
            console.log(aCoatingIDMsg, aMsgStyle);
        }
    }
    //_____________________________________________________________________________________________
    private _onDownloadImage(pMimeType: tMimeType) {
        html2canvas(this.mGraphContainer).then(canvas => {

            SnapshotTools.downloadImageFromCanvas({
                canvas: canvas,
                mimeType: pMimeType,
                name: "coating"
            });
        });
    }
    //_____________________________________________________________________________________________
    protected _initElements() {
        super._initElements();

        let aDownloadPNG = this._getPart("download-png", true);
        aDownloadPNG.addEventListener("click", () => this._onDownloadImage("image/png"));

        let aDownloadJPG = this._getPart("download-jpg", true);
        aDownloadJPG.addEventListener("click", () => this._onDownloadImage("image/jpg"));

        let aDownloadXLSX = this._getPart("download-xlsx", true);
        aDownloadXLSX.addEventListener("click", () => this._onDownloadXLSX());

        this.mGraphContainer = this._getPart("graph-container");
        this.mStartRange = this._getPart("start-range", true) as HTMLInputElement;
        this.mEndRange = this._getPart("end-range", true) as HTMLInputElement;

        let aDisplayBtn = this._getPart("display-btn", true);
        aDisplayBtn.addEventListener("click", () => this._onDispaly());
    }
    //_____________________________________________________________________________________________
    protected _onDownloadXLSX(): void {

        if (Op3dContext.USER_VO.isBasicLicense === false) {

            let aDataType = 'Normalized Intensity vs Wavelength';
            let aName: string = this.mData.name;
            let wb = XLSX.utils.book_new();
            wb.Props = {
                Title: aName,
                Author: Op3dContext.USER_VO.userVO.name.first + " " + Op3dContext.USER_VO.userVO.name.last,
                CreatedDate: new Date()
            };

            let aDate = Op3dUtils.getDate();
            let aBase = Array<Array<any>>();
            aBase[0] = ["Date", aDate];
            aBase[1] = ["Coating name", aName];
            aBase[2] = ["Data type", aDataType];
            aBase[3] = ["Wavelength (nm)", "Transmission P", "Reflection P", "Transmission S", "Reflection S"];

            let aContent = Array<Array<any>>();
            let R_p = this.mCurrGraphData.datasets.find(item => item.label === "Reflection P");
            let T_p = this.mCurrGraphData.datasets.find(item => item.label === "Transmission P");
            let R_s = this.mCurrGraphData.datasets.find(item => item.label === "Reflection S");
            let T_s = this.mCurrGraphData.datasets.find(item => item.label === "Transmission S");

            for (let i = 0; i < this.mCurrGraphData.labels.length; i++) {
                aContent[i] = [this.mCurrGraphData.labels[i], T_p.data[i], R_p.data[i],
                T_s.data[i], R_s.data[i]];
            }

            // this._addSheet(wb, aName, aBase, aContent);
            this._addSheet(wb, "data", aBase, aContent);
            let aWBOutBinary = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
            let aBuffer = new ArrayBuffer(aWBOutBinary.length);
            let aView = new Uint8Array(aBuffer);
            for (let i = 0; i < aWBOutBinary.length; i++) {
                aView[i] = aWBOutBinary.charCodeAt(i) & 0xFF;
            }
            const aFinalName = aName.slice(aName.length - 5, aName.length) === ".xlsx" ?
            aName : aName + ".xlsx";
            saveAs(new Blob([aBuffer], { type: "application/octet-stream" }),
                aFinalName);
        }



    }
    //__________________________________________________________________________________________
    protected _addSheet(pWB: XLSX.WorkBook, pKey: string, pBase: any[][], pContent: any[][]) {
        pWB.SheetNames.push(pKey);
        pWB.Sheets[pKey] = XLSX.utils.aoa_to_sheet([...pBase, ...pContent]);
    }
    //__________________________________________________________________________________________

    private _onDispaly() {
        let aStart = parseFloat(this.mStartRange.value);
        let aEnd = parseFloat(this.mEndRange.value);

        if (aStart == aEnd || aStart > aEnd) {
            Popup.instance.open({ text: "Invalid range" });
            return;
        }


        if (aStart < this.mData.wl[0] || aEnd > this.mData.wl[this.mData.wl.length - 1]) {
            Popup.instance.open({ text: `Invalid range, the range of the current spectrum is [${this.mData.wl[0]}, ${this.mData.wl[this.mData.wl.length - 1]}]` });
            return;
        }

        let aCoatingData = DataUtils.getObjectCopy(this.mData);
        let aStartCut = aCoatingData.wl.indexOf(aStart);
        if (aStartCut === -1) {

            let aPrevWLIdx = Op3dUtils.getClosestPrevIndex(aStart, aCoatingData.wl);
            let aNextWLIdx = Op3dUtils.getClosestNextIndexInArray(aStart, aCoatingData.wl);


            let aRp = OP3DMathUtils.getLinearInterpolationValue(aStart, aPrevWLIdx, aNextWLIdx, aCoatingData.R_P[aPrevWLIdx], aCoatingData.R_P[aNextWLIdx]);
            let aRs = OP3DMathUtils.getLinearInterpolationValue(aStart, aPrevWLIdx, aNextWLIdx, aCoatingData.R_S[aPrevWLIdx], aCoatingData.R_S[aNextWLIdx]);
            let aTp = OP3DMathUtils.getLinearInterpolationValue(aStart, aPrevWLIdx, aNextWLIdx, aCoatingData.T_P[aPrevWLIdx], aCoatingData.T_P[aNextWLIdx]);
            let aTs = OP3DMathUtils.getLinearInterpolationValue(aStart, aPrevWLIdx, aNextWLIdx, aCoatingData.T_S[aPrevWLIdx], aCoatingData.T_S[aNextWLIdx]);
            aCoatingData.wl.splice(aPrevWLIdx + 1, 0, aStart);
            aCoatingData.R_P.splice(aPrevWLIdx + 1, 0, aRp);
            aCoatingData.R_S.splice(aPrevWLIdx + 1, 0, aRs);
            aCoatingData.T_P.splice(aPrevWLIdx + 1, 0, aTp);
            aCoatingData.T_S.splice(aPrevWLIdx + 1, 0, aTs);
            aStartCut = aPrevWLIdx + 1;
        }

        let aFinishCut = this.mData.wl.indexOf(aEnd);

        if (aFinishCut === -1) {
            let aPrevWLIdx = Op3dUtils.getClosestPrevIndex(aEnd, aCoatingData.wl);
            let aNextWLIdx = Op3dUtils.getClosestNextIndexInArray(aEnd, aCoatingData.wl);

            let aRp = OP3DMathUtils.getLinearInterpolationValue(aEnd, aPrevWLIdx, aNextWLIdx, aCoatingData.R_P[aPrevWLIdx], aCoatingData.R_P[aNextWLIdx]);
            let aRs = OP3DMathUtils.getLinearInterpolationValue(aEnd, aPrevWLIdx, aNextWLIdx, aCoatingData.R_S[aPrevWLIdx], aCoatingData.R_S[aNextWLIdx]);
            let aTp = OP3DMathUtils.getLinearInterpolationValue(aEnd, aPrevWLIdx, aNextWLIdx, aCoatingData.T_P[aPrevWLIdx], aCoatingData.T_P[aNextWLIdx]);
            let aTs = OP3DMathUtils.getLinearInterpolationValue(aEnd, aPrevWLIdx, aNextWLIdx, aCoatingData.T_S[aPrevWLIdx], aCoatingData.T_S[aNextWLIdx]);
            aCoatingData.wl.splice(aPrevWLIdx + 1, 0, aEnd);
            aCoatingData.R_P.splice(aPrevWLIdx + 1, 0, aRp);
            aCoatingData.R_S.splice(aPrevWLIdx + 1, 0, aRs);
            aCoatingData.T_P.splice(aPrevWLIdx + 1, 0, aTp);
            aCoatingData.T_S.splice(aPrevWLIdx + 1, 0, aTs);
            aFinishCut = aPrevWLIdx + 1;
        }

        let aFilteredPoints: iCoatingVO = {
            wl: aCoatingData.wl.slice(aStartCut, aFinishCut + 1),
            R_P: aCoatingData.R_P.slice(aStartCut, aFinishCut + 1),
            R_S: aCoatingData.R_S.slice(aStartCut, aFinishCut + 1),
            T_P: aCoatingData.T_P.slice(aStartCut, aFinishCut + 1),
            T_S: aCoatingData.T_S.slice(aStartCut, aFinishCut + 1),
            name: "custom",
            owner: null,
            perElement: aCoatingData.perElement
        }

        this._drawGraphs(aFilteredPoints);
    }
    //_____________________________________________________________________________________________
    protected _setInitialRange() {
        if (this.mData === undefined || this.mData === null) {
            this.mStartRange.value = '500';
            this.mEndRange.value = '1500';
        } else {
            let aStart = Math.max(this.mData.wl[0], CoatingChart.DEFAULT_RANGE.min);
            let aEnd = Math.min(this.mData.wl[this.mData.wl.length - 1], CoatingChart.DEFAULT_RANGE.max);
            if (aEnd < aStart) {
                aEnd = Math.max(this.mData.wl[this.mData.wl.length - 1], CoatingChart.DEFAULT_RANGE.max);
            }
            this.mStartRange.value = aStart.toString();
            this.mEndRange.value = aEnd.toString();
        }
    }
    // //_____________________________________________________________________________________________
}
