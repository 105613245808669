import { Vector3, Matrix4 } from "three";
import { t3DArray } from "../_context/_interfaces/Interfaces";
import { MatrixUtils } from "./MatrixUtils";

export class CADUtils {

    //__________________________________________________________________________________________
    public static mr_IdentityMatrix(pMatrixSize: number) {
        /*Identity matrix of given size.
        Input:
        pMatrixSize - size of matrix
        Return:
        aMatrix - identity matrix of size n.
        */
        let aMatrix = [];

        for (let i = 0; i < pMatrixSize; i++) {
            let aRow = new Array(pMatrixSize).fill(0)
            aRow[i] = 1;
            aMatrix.push(aRow);
        }
        return aMatrix;
    }
    //__________________________________________________________________________________________
    public static mr_VectorToVectorRotation(pNrmFixed, pNrmAction) {
        /*The matrix of rotation of one vector (NrmAction) to
        another (NrmFixed) about an axis, which together with them creates
        the right coordinate system.
         Input:
        pNrmFixed - fixed 3D vector,
        pNrmActive - moving (active) 3D vector,
        Return:
        aMatrix - matrix of rotation
        */
        let aAng, aMatrix, aRotV;
        aRotV = CADUtils.mr_vectprod3(pNrmAction, pNrmFixed);
        if (CADUtils.mr_vectlng(aRotV) === 0) {

            aRotV = pNrmAction.slice()
        }
        aRotV = CADUtils.mr_vectXnumb(aRotV, 1 / CADUtils.mr_vectlng(aRotV));


        aAng = CADUtils.mr_angle3_rad(pNrmAction, pNrmFixed);
        aMatrix = CADUtils.mr_RotateAxisMatrix(aRotV, -aAng);
        return aMatrix;
    }
    //__________________________________________________________________________________________
    public static mr_VectorToVectorRotationHomogeneous(pNrmFixed, pNrmAction) {
        /*The matrix of rotation of one vector (NrmAction) to
        another (NrmFixed) about an axis, which together with them creates
        the right coordinate system. HOMOGENEOUS coordinates.
         Input:
        pNrmFixed - fixed 3D vector,
        pNrmAction - moving (active) 3D vector,
        Return:
        aMatrix - homogeneous coordinates matrix of rotation
        */
        let aMatrix
        aMatrix = CADUtils.mr_VectorToVectorRotation(pNrmFixed, pNrmAction);

        return MatrixUtils.getMatrix4(aMatrix, [0, 0, 0]);
    }
    //__________________________________________________________________________________________
    public static mr_HomogeneousMatrixOfTranslation(pVector: Vector3) {
        /*Homogeneous matrix of translation determined by given vector.
         Input:
        pVector - translation vector in cartesian coordinates system
        Return:
        aMatrix -homogeneous matrix of translation
        */

        return MatrixUtils.getMatrix4(CADUtils.mr_IdentityMatrix(pVector.toArray().length), pVector.toArray())
    }
    //__________________________________________________________________________________________
    public static mr_PntVctrToPntVctrTransform(pF: Vector3, vF: Vector3, pA: Vector3, vA: Vector3) {
        /*A homogeneous transformation matrix from one binded point-vector
        pair to another.
        30 December 2022 <> 31 December 2022
         Input:
        pF - fixed 3D point,
        vF - binded 3D direction vector of the fixed point,
        pA - moving (active) 3D point,
        vA - binded 3D direction vector of the moving (active) point
        Return:
        mtrx - matrix of transformaation in homogeneous coordinates
        */

        // pAopposite = CADUtils.mr_vectXnumb(pA, -1);
        let aAopposite = pA.multiplyScalar(-1)
        let aMatrix = CADUtils.mr_HomogeneousMatrixOfTranslation(aAopposite);
        let aMatrixCurrent = CADUtils.mr_VectorToVectorRotationHomogeneous(vF.toArray(), vA.toArray());

        // aMatrix = CADUtils.mr_MatrMatrMult(aMatrixCurrent, aMatrix);
        let aMatrixesMultiply = new Matrix4().multiplyMatrices(new Matrix4().fromArray(aMatrixCurrent), new Matrix4().fromArray(aMatrix));
        let aMatrixCurrentVec = CADUtils.mr_HomogeneousMatrixOfTranslation(pF);
        // aMatrix = CADUtils.mr_MatrMatrMult(aMatrixCurrentVec, aMatrixesMultiply);
        return new Matrix4().multiplyMatrices(new Matrix4().fromArray(aMatrixCurrentVec), aMatrixesMultiply);
    }
    //__________________________________________________________________________________________
    public static mr_CFrameToCFrameRotationHomogeneous(FF: t3DArray<Vector3>,
        FA: t3DArray<Vector3>) {

        /*The matrix of rotation of one cartesian frame system FA to
        another FF.
        04 January 2023 <> 04 January 2023
         Input:
        FA - initial coordinate frame unit vectors,
        FF - final  coordinate frame unit vectors
        Return:
        Rotation matrix in homgeneous coordinates.
        */

        let mtrx = CADUtils.mr_TrihedronRotationMotionMatrix(FA, FF);
        return MatrixUtils.getMatrix4(mtrx, [0, 0, 0]);
    }
    //__________________________________________________________________________________________
    public static mr_TrihedronRotationMotionMatrix(FF0: t3DArray<Vector3>,
        FF1: t3DArray<Vector3>) {
        /*Matrix of rotational motion from one to another trihedron
        in the global coordinate system (align matrix).
         P' = inv(R0)*R01*R0*(P - O0) + O1; Matrix = inv(R0)*R01*R0
         Input:
        FF0 - orts of initial trihedron,
        FF1 - orts of final trihedron
        Return:
        Matix of rotation of trihedron motion.
        */
        var R, R0, Rcurr;
        R0 = CADUtils.mr_CoordTransformRotationalMatrix(FF0);
        Rcurr = CADUtils.mr_TrihedronRotationMatrix(FF0, FF1);
        R = CADUtils.mr_MatrMatrMult(Rcurr, R0);
        Rcurr = CADUtils.mr_MatrTranspose(R0);
        R = CADUtils.mr_MatrMatrMult(Rcurr, R);
        return R;
    }
    //__________________________________________________________________________________________
    private static mr_MatrTranspose(M: Array<Array<number>>) {
        let R = new Array<Array<number>>();
        for (let i = 0; i < M.length; i++) {
            R.push(new Array<number>());
            for (let j = 0; j < M[i].length; j++) {
                R[i].push(M[j][i]);
            }
        }

        return R;
    }
    //__________________________________________________________________________________________
    private static mr_TrihedronRotationMatrix(FF0: t3DArray<Vector3>,
        FF1: t3DArray<Vector3>) {
        /**
         * Trihedron rotation (moving) matrix: OABC -> OA'B'C',
         * O -> O, A -> A', B -> B', C -> C', O is the point of origin.
         * Means the unit right trihedrons.
         * 
         * If R is rotation matrix and x, y, z are the coordinates
         * of point M in OABC, then R*transpose([x,y,z]) are the coordinates
         * of M after rotation in the same coordinates sytem OABC.
         * Input:
         * FF0 - initial position of trihedron
         * vertices A,B,C (in global coordinate system),
         * FF1 - final position of trihedron
         * vertices A'B'C' (in global coordinate system)
         * 
         * @returns Moving rotation matrix
         */

        let R = new Array<Array<number>>();
        for (let i = 0; i < 3; i++) {
            let row = new Array<number>();
            for (let j = 0; j < 3; j++) {
                let rij = new Vector3().copy(FF1[j]).dot(FF0[i]);
                row.push(rij);
            }
            R.push(row);
        }

        return R;
    }
    //__________________________________________________________________________________________
    public static mr_CoordTransformRotationalMatrix(FF: Array<Vector3>) {
        /**
         * Matrix of rotational transform of coordinates from global
         * cartesian coordinate system to the new system defined by unit
         * coordinate vectors(trihedron).
         * Input:
         * FF - list of unit vectors of the new coordinate system
         * @returns Matrix of transform
         */

        let e = [
            new Vector3(1, 0, 0),
            new Vector3(0, 1, 0),
            new Vector3(0, 0, 1)
        ];

        let R = new Array<Array<number>>();

        for (let i = 0; i < 3; i++) {
            let row = new Array<number>();
            for (let j = 0; j < 3; j++) {
                let rij = new Vector3().copy(e[j]).dot(FF[i]);
                row.push(rij);
            }
            R.push(row);
        }

        return R;
    }
    //__________________________________________________________________________________________

    public static mr_PntCFrameToPntCFrameTransform(pF: Vector3,
        FF: t3DArray<Vector3>, pA: Vector3, FA: t3DArray<Vector3>) {

        /*A homogeneous transformation matrix from one cartesian coordinate
        system (origin + frame of unit basis vectors) to
        another.
        04 January 2023 <> 04 January 2023
         Input:
        pF - fixed 3D point (origin),
        FF - frame of unit basis vectors of the fixed point,
        pA - moving (active) 3D point (origin),
        FA - frame of unit basis vectors of the moving (active) point
        Return:
        mtrx - matrix of transformaation in homogeneous coordinates
        */
        let pAopposite = pA.multiplyScalar(-1);
        let mtrx = new Matrix4().fromArray(CADUtils.mr_HomogeneousMatrixOfTranslation(pAopposite));
        let mtrx_curr = new Matrix4().fromArray(CADUtils.mr_CFrameToCFrameRotationHomogeneous(FF, FA));
        mtrx = new Matrix4().multiplyMatrices(mtrx_curr, mtrx);
        //mtrx = CADUtils.mr_MatrMatrMult(mtrx_curr, mtrx);
        mtrx_curr = new Matrix4().fromArray(CADUtils.mr_HomogeneousMatrixOfTranslation(pF));
        mtrx = new Matrix4().multiplyMatrices(mtrx_curr, mtrx);

        return mtrx;
    }
    //__________________________________________________________________________________________

    public static mr_CoordFrameToFrameMappingTest(coord, pF, FF, pA, FA) {
        /*
         */
        var mtrxH, pntA, pntABH, pntAH, pntB;
        pntA = CADUtils.mr_vectsum(pA, CADUtils.mr_vectXnumb(FA[0], coord[0]));
        pntA = CADUtils.mr_vectsum(pntA, CADUtils.mr_vectXnumb(FA[1], coord[1]));
        pntA = CADUtils.mr_vectsum(pntA, CADUtils.mr_vectXnumb(FA[2], coord[2]));
        pntB = CADUtils.mr_vectsum(pF, CADUtils.mr_vectXnumb(FF[0], coord[0]));
        pntB = CADUtils.mr_vectsum(pntB, CADUtils.mr_vectXnumb(FF[1], coord[1]));
        pntB = CADUtils.mr_vectsum(pntB, CADUtils.mr_vectXnumb(FF[2], coord[2]));
        mtrxH = CADUtils.mr_PntCFrameToPntCFrameTransform(pF, FF, pA, FA);
        pntAH = pntA + [1];
        pntABH = CADUtils.mr_MatrVectMult(mtrxH, pntAH);
        return [pntA, pntB, mtrxH, pntABH];
    }


    //__________________________________________________________________________________________
    public static mr_RotateVecRelAxis(r, f, v) {
        /*Vector rotation relatively given axis passing through the origin.
         Input:
        r - axis direction unit vector;
        f - rotation angle;
        v - initial vector
        Returns:
        rotated vector
        */
        var a, a0, a1, a2, vers;
        a0 = [];
        a1 = [];
        a2 = [];
        vers = 1 - Math.cos(f);
        a0.append(r[0] * r[0] * vers + Math.cos(f));
        a0.append(r[0] * r[1] * vers - r[2] * Math.sin(f));
        a0.append(r[0] * r[2] * vers + r[1] * Math.sin(f));
        a1.append(r[0] * r[1] * vers + r[2] * Math.sin(f));
        a1.append(r[1] * r[1] * vers + Math.cos(f));
        a1.append(r[1] * r[2] * vers - r[0] * Math.sin(f));
        a2.append(r[0] * r[2] * vers - r[1] * Math.sin(f));
        a2.append(r[1] * r[2] * vers + r[0] * Math.sin(f));
        a2.append(r[2] * r[2] * vers + Math.cos(f));
        a = [a0, a1, a2];
        return CADUtils.mr_MatrVectMult3D(a, v);
    }

    //__________________________________________________________________________________________
    public static mr_vectsum(v1, v2) {
        /*Sum of two vectors of the same dimensions.
         */
        var lng, v;
        lng = v1.length;

        if (lng !== v2.length) {
            return null;
        }

        v = [];

        for (var k = 0, _pj_a = lng; k < _pj_a; k += 1) {
            v.append(v1[k] + v2[k]);
        }

        return v;
    }

    //__________________________________________________________________________________________
    public static mr_MatrVectMult(a, v) {
        /*General matrix-vector multiplication. Matrix is
        defined as list of raws.
         Input:
        a - m*n matrix (m raws, n columns),
        v - n vector
        Returns:
        vector of production of length m.
        */
        var m, n, vp;
        m = a.length;
        n = a[0].length;
        vp = m;

        for (var k = 0, _pj_a = m; k < _pj_a; k += 1) {
            for (var i = 0, _pj_b = n; i < _pj_b; i += 1) {
                vp[k] += a[k][i] * v[i];
            }
        }

        return vp;
    }

    //__________________________________________________________________________________________
    public static mr_MatrVectMult3D(a, v) {
        /*3D matrix-vector multiplication.
         Input:
        a - 3*3 matrix;
        v - 3D vector
        Returns:
        vector of production
        */
        var vp;
        vp = [0.0, 0.0, 0.0];

        for (var k = 0, _pj_a = 3; k < _pj_a; k += 1) {
            for (var i = 0, _pj_b = 3; i < _pj_b; i += 1) {
                vp[k] += a[k][i] * v[i];
            }
        }

        return vp;
    }

    //__________________________________________________________________________________________
    private static mr_vectprod3(pVector1, pVector2) {
        /*Vector (cross) product of 2 vectors.
         Input:
        v1,v2 - two 3-D vectors
        Return:
        vector (cross) product of v1 and v2
        */
        let aCrossVector = [];
        aCrossVector.push(pVector1[1] * pVector2[2] - pVector2[1] * pVector1[2]);
        aCrossVector.push(-(pVector1[0] * pVector2[2] - pVector2[0] * pVector1[2]));
        aCrossVector.push(pVector1[0] * pVector2[1] - pVector2[0] * pVector1[1]);
        return aCrossVector;
    }
    //__________________________________________________________________________________________
    private static mr_vectXnumb(v, alf) {

        let u = [];

        if (!Array.isArray(v)) {
            return alf * v;
        }

        for (var k = 0, _pj_a = v.length; k < _pj_a; k += 1) {
            u.push(alf * v[k]);
        }

        return u;
    }
    //__________________________________________________________________________________________
    private static mr_vectlng(v) {

        let d = 0;

        if (!Array.isArray(v)) {
            return Math.abs(v);
        }

        for (var k = 0, _pj_a = v.length; k < _pj_a; k += 1) {
            d += Math.pow(v[k], 2);
        }

        return Math.sqrt(d);
    }
    //__________________________________________________________________________________________
    private static mr_angle3_rad(pVector1, pVector2) {
        /*Angle between two 3D vectors in radians.
         Input:
        pVector1,pVector2 - two 3D vectors
        Returns:
        the value of angle bounded by 0 and 180 degrees;
        if pVector1 or pVector2 equal to 0 returns 360
        */
        let c, zero;
        zero = [0.0, 0.0, 0.0];

        if (CADUtils.mr_Eq3(pVector1, zero) || CADUtils.mr_Eq3(pVector2, zero)) {
            return 2 * Math.PI;
        }

        c = CADUtils.mr_dotprod3(pVector1, pVector2) / CADUtils.mr_vectlng3D(pVector1) / CADUtils.mr_vectlng3D(pVector2);

        if (Math.abs(c) > 1.0) {
            if (c < 0) {
                c = -1.0;
            } else {
                c = 1.0;
            }
        }

        return Math.acos(c);
    }
    //__________________________________________________________________________________________
    private static mr_RotateAxisMatrix(pAxisVector, pRotAngle) {
        /*Matrix of rotation relatively given 3D axis passing through
        the origin.
         Input:
        r - axis direction unit vector;
        f - rotation angle;
        Returns:
        rotation matrix
        */
        let a0, a1, a2, vers;
        a0 = [];
        a1 = [];
        a2 = [];
        vers = 1 - Math.cos(pRotAngle);
        a0.push(pAxisVector[0] * pAxisVector[0] * vers + Math.cos(pRotAngle));
        a0.push(pAxisVector[0] * pAxisVector[1] * vers - pAxisVector[2] * Math.sin(pRotAngle));
        a0.push(pAxisVector[0] * pAxisVector[2] * vers + pAxisVector[1] * Math.sin(pRotAngle));
        a1.push(pAxisVector[0] * pAxisVector[1] * vers + pAxisVector[2] * Math.sin(pRotAngle));
        a1.push(pAxisVector[1] * pAxisVector[1] * vers + Math.cos(pRotAngle));
        a1.push(pAxisVector[1] * pAxisVector[2] * vers - pAxisVector[0] * Math.sin(pRotAngle));
        a2.push(pAxisVector[0] * pAxisVector[2] * vers - pAxisVector[1] * Math.sin(pRotAngle));
        a2.push(pAxisVector[1] * pAxisVector[2] * vers + pAxisVector[0] * Math.sin(pRotAngle));
        a2.push(pAxisVector[2] * pAxisVector[2] * vers + Math.cos(pRotAngle));
        return [a0, a1, a2];
    }
    //__________________________________________________________________________________________
    private static mr_MatrMatrMult(a: Array<Array<number>>, b: Array<Array<number>>) {
        /*Rectangular matrices multiplication.
         Return:
        Matrix production a * b
        */
        let ab, m, mn, n;
        mn = a[0].length;

        if (mn !== b.length) {
            return [];
        }

        m = a.length;
        n = b[0].length;
        ab = [];

        for (var i = 0, _pj_a = m; i < _pj_a; i += 1) {
            ab.push(new Array());
        }

        for (var i = 0, _pj_a = m; i < _pj_a; i += 1) {
            for (var j = 0, _pj_b = n; j < _pj_b; j += 1) {
                ab[i][j] = 0;
                for (var k = 0, _pj_c = mn; k < _pj_c; k += 1) {
                    ab[i][j] += a[i][k] * b[k][j];
                }
            }
        }

        return ab;
    }
    //__________________________________________________________________________________________
    private static mr_dotprod3(pVector1, pVector2) {
        /*Scalar (dot) product of 3D vectors.
         Input:
        pVector1,pVector2 - two 3D vectors
        Returns:
        dot product of 'a' and 'b'
        */
        return pVector1[0] * pVector2[0] + pVector1[1] * pVector2[1] + pVector1[2] * pVector2[2];
    }
    //__________________________________________________________________________________________
    private static mr_vectlng3D(pVector) {
        /*Length of 3D vector
         Input:
        v - 3D vector
        Returns:
        length of v
        */
        let d = 0;

        for (var k = 0, _pj_a = 3; k < _pj_a; k += 1) {
            d += Math.pow(pVector[k], 2);
        }

        return Math.sqrt(d);

    }
    //__________________________________________________________________________________________
    private static mr_Eq3(a0, a1) {
        /*Check relational equalite of 3D vectors.
         */
        if (CADUtils.mr_Eq1(a0[0], a1[0]) && CADUtils.mr_Eq1(a0[1], a1[1]) && CADUtils.mr_Eq1(a0[2], a1[2])) {
            return true;
        } else {
            return false;
        }
    }
    //__________________________________________________________________________________________
    private static mr_Eq1(a, b) {
        /*Check relational equalite of numbers
         */
        let a1, b1, big, d, epseq1;
        epseq1 = 5e-07;
        // d = Number.parseFloat(Math.abs(a - b));
        d = Math.abs(a - b)

        if (0.0 === d) {
            return true;
        }

        // a1 = Number.parseFloat(Math.abs(a));
        a1 = Math.abs(a)
        // b1 = Number.parseFloat(Math.abs(b));
        b1 = Math.abs(b)

        if (a1 < epseq1 || b1 < epseq1) {
            if (d < epseq1) {
                return true;
            } else {
                return false;
            }
        }

        if (a1 > b1) {
            big = a1;
        } else {
            big = b1;
        }

        if (d / big < epseq1) {
            return true;
        } else {
            return false;
        }
    }
}
