import { eTextTransform } from "../_context/Enums";
import { Op3dContext } from "../_context/Op3dContext";
import { Strings } from "../_context/Strings";
import { ParserContext } from "../parser/ParserContext";
import { ColorUtils } from "./ColorUtils";
import { iRgba } from "./_globals/uiInterfaces";
import { eTextAlignment } from "./home/op3dTooltip";

export enum eTypingSpeed {
    SUPER_SLOW = 500,
    SLOW = 35,
    MEDIUM = 25,
    FAST = 10
}
export class ViewUtils {
    //__________________________________________________________________________________________
    public static setElementVisibilityByDFlexDNone(pElement: HTMLElement, pIsToShow: boolean) {
        if (pElement == null) {
            return;
        }

        if (pIsToShow) {
            pElement.classList.remove(Strings.D_NONE);
            pElement.classList.add(Strings.DISPLAY_FLEX_CLASS);
        } else {
            pElement.classList.remove(Strings.DISPLAY_FLEX_CLASS);
            pElement.classList.add(Strings.D_NONE);
        }
    }
    //__________________________________________________________________________________________
    public static getImage(pUrl: string) {
        return new Promise<HTMLImageElement>(resolve => {

            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => resolve(null);
            img.src = pUrl;
        })
    }
    //__________________________________________________________________________________________
    public static setTextAlign(pElement: HTMLElement, pTextAlign: eTextAlignment) {
        switch ((pTextAlign)) {
            case eTextAlignment.LEFT:
                pElement.setAttribute('text_align', 'left');
                break;
            case eTextAlignment.CENTER:
                pElement.setAttribute('text_align', 'center');
                break;
            case eTextAlignment.RIGHT:
                pElement.setAttribute('text_align', 'right');
                break;

            default:
                break;
        }
    }
    //__________________________________________________________________________________________
    public static disposeTooltip(pContainer: HTMLElement) {
        $(pContainer).tooltip("dispose");
    }
    //__________________________________________________________________________________________
    public static initTooltips() {
        $('[data-toggle="tooltip"]')
            .tooltip()
            .on("click", function () {
                $(this).blur();
            });
    }
    //__________________________________________________________________________________________
    public static convertReadmeToHtml(readmeText: string) {
        // Replace double asterisks with <strong> tags for bold text
        const boldRegex = /\*\*(.*?)\*\*/g;
        const htmlText = readmeText.replace(boldRegex, '<strong>$1</strong>');

        // Replace single asterisks or underscores with <em> tags for italic text
        const italicRegex = /(\*|_)(.*?)\1/g;
        const htmlTextWithItalic = htmlText.replace(italicRegex, '<em>$2</em>');

        // Replace headings
        const headingsRegex = /^(#{1,6})\s(.*)$/gm;
        const htmlTextWithHeadings = htmlTextWithItalic.replace(
            headingsRegex,
            (_match, level, title) => `<h${level.length}>${title}</h${level.length}>`
        );

        // Replace links
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const htmlTextWithLinks = htmlTextWithHeadings.replace(
            linkRegex,
            '<a href="$2">$1</a>'
        );

        // Replace code blocks (``` code ```)
        const codeBlockRegex = /```([\s\S]*?)```/g;
        const htmlTextWithCodeBlocks = htmlTextWithLinks.replace(
            codeBlockRegex,
            '<pre>$1</pre>'
        );

        return htmlTextWithCodeBlocks;


    }
    //__________________________________________________________________________________________
    public static async typeEffect(text: string, pContainer: HTMLElement, pSpeed: eTypingSpeed = eTypingSpeed.FAST) {
        if (pContainer === undefined || pContainer === null) {
            return;
        }

        let index = 0;

        let aFinished = false;
        do {
            if (index < text.length) {
                pContainer.innerText = text.slice(0, index + 1);

                index++;
                await Op3dContext.sleep(pSpeed);
                pContainer.scrollIntoView({ block: "end" });

            } else {
                aFinished = true;
            }

        } while (aFinished === false)
    }
    //__________________________________________________________________________________________
    public static setTextTransform(pElement: HTMLElement, pTextTransform: eTextTransform) {
        pElement.classList.remove('sentense_style');
        pElement.style.textTransform = null;

        switch (pTextTransform) {
            case eTextTransform.CAPITALIZE:
                pElement.style.textTransform = "capitalize";
                break;
            case eTextTransform.UPPERCASE:
                pElement.style.textTransform = "uppercase";
                break;
            case eTextTransform.LOWERCASE:
                pElement.style.textTransform = "lowercase";
                break;
            case eTextTransform.SENTENCE_STYLE:
                pElement.classList.add('sentense_style');
                break;
            default:
                break;
        }
    }
    //__________________________________________________________________________________________
    public static isElementActive(pElement: Element) {
        if (pElement == null) {
            return false;
        }

        return pElement.classList.contains(Strings.ACTIVE_STATE_CLASS);
    }
    //__________________________________________________________________________________________
    public static isElementMarked(pElement: Element) {
        if (pElement == null) {
            return false;
        }

        return pElement.classList.contains(Strings.MARKED);
    }
    //__________________________________________________________________________________________
    public static changeCloseMaskState(pElement: Element) {
        if (pElement.classList.contains(Strings.CLOSE_MASK_STATE)) {
            pElement.classList.remove(Strings.CLOSE_MASK_STATE);
        } else {
            pElement.classList.add(Strings.CLOSE_MASK_STATE);
        }
    }
    //__________________________________________________________________________________________
    public static setClassForElement(pElement: HTMLElement, pClass: string, pToAdd: boolean) {
        if (pElement == null) {
            return;
        }
        if (pToAdd) {
            pElement.classList.add(pClass);
        } else {
            pElement.classList.remove(pClass);
        }
    }
    //__________________________________________________________________________________________
    public static setElementBold(pElement: Element, pIsActive: boolean) {
        if (pElement == null) {
            return;
        }
        if (pIsActive) {
            pElement.classList.add(Strings.BOLD_CLASS);
        } else {
            pElement.classList.remove(Strings.BOLD_CLASS);
        }
    }
    //__________________________________________________________________________________________
    public static setElementVisibilityByBlockNone(pElement: HTMLElement, pVisible: boolean) {
        if (pElement == null) {
            return;
        }
        if (pVisible) {
            if (pElement.style.display != "block") {
                pElement.style.display = "block";
            }
        }
        else {
            if (pElement.style.display != "none") {
                pElement.style.display = "none";
            }
        }
    }
    //__________________________________________________________________________________________
    public static setClassState(pElement: HTMLElement, pClass: string, pVal: boolean) {
        if (pElement == null) {
            return;
        }
        if (pVal) {
            pElement.classList.add(pClass);
        } else {
            pElement.classList.remove(pClass);
        }
    }
    //__________________________________________________________________________________________
    public static selectElementByValue(pSelect: HTMLSelectElement, pValue: string,
        pDispatchChangeEvent: boolean = false) {

        if (pValue == pSelect.value) {
            return;
        }

        let aIdx = ViewUtils.getDropDownItemIndex(pSelect, pValue);
        if (aIdx == -1) {
            aIdx = 0;
        }
        pSelect.selectedIndex = aIdx;
        //pSelect.value = pValue;
        if (true == pDispatchChangeEvent) {
            pSelect.dispatchEvent(new Event('change'));
        }
    }
    //__________________________________________________________________________________________
    public static unActiveAllchildren(pParentElement: HTMLElement) {
        for (let i = 0; i < pParentElement.children.length; i++) {
            this.setElementActive(pParentElement.children[i], false);
        }
    }
    //__________________________________________________________________________________________
    public static createHTMLElement(tag: string, className?: string): HTMLElement {
        const element = document.createElement(tag);
        if (className) {
            element.classList.add(className);
        }
        return element;
    }
    //__________________________________________________________________________________________
    public static removeDegreeSign(pHtmlInput: HTMLInputElement) {
        if (pHtmlInput.value.indexOf(ParserContext.SPECIAL_CHARACTERS.degree) != -1) {
            pHtmlInput.value = pHtmlInput.value.replace(ParserContext.SPECIAL_CHARACTERS.degree, '');
        }
    }
    //__________________________________________________________________________________________
    public static setElementActive(pElement: Element, pIsActive: boolean) {
        if (pElement == null) {
            return;
        }
        if (pIsActive) {
            pElement.classList.add(Strings.ACTIVE_STATE_CLASS);
        } else {
            pElement.classList.remove(Strings.ACTIVE_STATE_CLASS);
        }
    }
    //__________________________________________________________________________________________
    public static toggleElementActive(pElement: Element) {
        return pElement.classList.toggle(Strings.ACTIVE_STATE_CLASS);
    }
    //__________________________________________________________________________________________
    public static toggleClass(pElement: Element, pClassName: string) {

        return pElement.classList.toggle(pClassName);
    }
    //__________________________________________________________________________________________
    public static setElementBackground(pElement: HTMLElement, pColor: iRgba) {
        if (pElement == null) {
            return;
        }

        pElement.style.backgroundColor = ColorUtils.RGBAtoString(pColor);
    }
    //__________________________________________________________________________________________
    public static setElementsEventsState(pAllowEvents: boolean, pField: HTMLAnchorElement,
        pIsUndefined?: boolean) {

        if (pAllowEvents) {
            pField.style.pointerEvents = "all";
            pField.style.color = "#23A7DE";
        } else {
            // pField.innerHTML = pIsActive ? "undefined" : "disabled";
            pField.style.pointerEvents = 'none';
            pField.style.color = "#2E3333";
        }

        if (pIsUndefined) {
            pField.innerText = "undefined";
        }
    }
    //__________________________________________________________________________________________
    public static setElementMarked(pElement: Element, pIsMarked: boolean) {
        if (pElement == null) {
            return;
        }

        if (pIsMarked) {
            pElement.classList.add(Strings.MARKED);
        } else {
            pElement.classList.remove(Strings.MARKED);
        }
    }
    //__________________________________________________________________________________________
    public static setElementAttribute(pElement: Element, pAttrName: string, pValue?: string) {
        if (pElement == null) {
            return;
        }

        if (pValue == null) {
            pElement.removeAttribute(pAttrName);
        } else {
            pElement.setAttribute(pAttrName, pValue);
        }
    }
    //__________________________________________________________________________________________
    public static setElementDisabledAttribute(pElement: Element, pIsDisabled: boolean) {
        if (pElement == null) {
            return;
        }

        if (pIsDisabled) {
            pElement.setAttribute(Strings.DISABLED_STATE, '');
        } else {
            pElement.removeAttribute(Strings.DISABLED_STATE);
        }
    }
    //__________________________________________________________________________________________
    public static setElementDisabled(pElement: Element, pIsDisabled: boolean) {
        if (pElement == null) {
            return;
        }

        if (pIsDisabled) {
            pElement.classList.add(Strings.DISABLED_STATE);
        } else {
            pElement.classList.remove(Strings.DISABLED_STATE);
        }
    }
    //__________________________________________________________________________________________
    public static addDegreeSign(pHtmlInput: HTMLInputElement) {
        if (pHtmlInput.value.indexOf(ParserContext.SPECIAL_CHARACTERS.degree) == -1) {
            pHtmlInput.value += ParserContext.SPECIAL_CHARACTERS.degree;
        }
    }
    //__________________________________________________________________________________________
    public static removePercentSign(pHtmlInput: HTMLInputElement) {
        if (pHtmlInput.value.indexOf(ParserContext.SPECIAL_CHARACTERS.percent) != -1) {
            pHtmlInput.value = pHtmlInput.value.replace(ParserContext.SPECIAL_CHARACTERS.percent, '');
        }
    }
    //__________________________________________________________________________________________
    public static addPercentSign(pHtmlInput: HTMLInputElement) {
        if (pHtmlInput.value.indexOf(ParserContext.SPECIAL_CHARACTERS.percent) == -1) {
            pHtmlInput.value += ParserContext.SPECIAL_CHARACTERS.percent;
        }
    }
    //__________________________________________________________________________________________
    public static removeFromParent(pElement: HTMLElement | null) {
        if (pElement == null || pElement.parentElement == null) {
            return;
        }

        pElement.parentElement.removeChild(pElement);
    }
    //__________________________________________________________________________________________
    public static setClassShowState(pElement: HTMLElement, pIsToShow: boolean) {
        if (pElement == null) {
            return;
        }
        if (pIsToShow) {
            pElement.classList.add(Strings.SHOW);
        } else {
            pElement.classList.remove(Strings.SHOW);
        }
    }
    //__________________________________________________________________________________________
    public static enableButton(pElement: HTMLElement, pIsToEnable: boolean) {
        if (pElement == null) {
            return;
        }
        if (pIsToEnable) {
            pElement.classList.remove(Strings.DISABLED_STATE);
        } else {
            pElement.classList.add(Strings.DISABLED_STATE);
        }
    }
    //__________________________________________________________________________________________
    public static clearElementsChildren(pSelectElement: HTMLElement) {
        while (pSelectElement.children.length > 0) {
            pSelectElement.removeChild(pSelectElement.children.item(0));
        }
    }
    //__________________________________________________________________________________________
    public static findIndexByValue(pSelect: HTMLSelectElement, pValue: string) {
        for (let i = 0; i < pSelect.options.length; i++) {
            const aOption = pSelect.options[i];
            if (aOption.value == pValue) {
                return i;
            }
        }

        return -1;
    }
    //__________________________________________________________________________________________
    public static makeUnselectable(pDiv: HTMLElement) {
        if (pDiv == null) {
            return;
        }
        let aElements = pDiv.getElementsByTagName('*');
        for (let i = 0; i < aElements.length; i++) {

            let aItem = aElements[i] as HTMLElement;
            if (!(aItem instanceof HTMLInputElement)) {
                aItem.style['user-drag'] = "none";
                aItem.style['-webkit-user-drag'] = "none";

                if (aItem.classList && !aItem.classList.contains("selectableItem")) {
                    aItem.style.userSelect = "none";
                    aItem.style['-webkit-user-select'] = "none";
                    aItem.style['-moz-user-select'] = "none";
                } else {
                    aItem.style.userSelect = "text!important";
                    aItem.style['-webkit-user-select'] = "text!important";
                    aItem.style['-moz-user-select'] = "text!important";
                    aItem.style['-ms-user-select'] = "text!important";
                }
            }

        }
    }
    //__________________________________________________________________________________________
    public static setMessageClass(pElement: HTMLElement, pIsSuccess: boolean) {
        if (pElement == null) {
            return;
        }
        if (pIsSuccess == true) {
            pElement.classList.add(Strings.SUCCESS);
            pElement.classList.remove(Strings.ERROR);
        } else {
            pElement.classList.remove(Strings.SUCCESS);
            pElement.classList.add(Strings.ERROR);
        }
    }
    //__________________________________________________________________________________________
    public static isDnone(pElement: HTMLElement) {
        return pElement.classList.contains(Strings.D_NONE);
    }
    //__________________________________________________________________________________________
    public static setElementVisibilityByDNone(pElement: HTMLElement | null, pIsToShow: boolean) {
        if (pElement == null) {
            return;
        }
        if (pIsToShow == true) {
            pElement.classList.remove(Strings.D_NONE);
        } else {
            pElement.classList.add(Strings.D_NONE);
        }
    }
    //__________________________________________________________________________________________
    public static removeElementChildren(pElement: HTMLElement, pStartFrom: number = 0) {
        if (pElement == null) {
            return;
        }

        if (0 == pStartFrom) {
            pElement.innerHTML = '';
            return;
        }

        while (pElement.children.length > pStartFrom) {
            pElement.removeChild(pElement.children[pStartFrom]);
        }
    }
    //__________________________________________________________________________________________
    public static getDropDownItemIndex(pDropDown: HTMLSelectElement, pString: string) {
        if (pString == null) {
            return -1;
        }

        let aStringToUpperCase = pString.toUpperCase().replace(/\s+/g, '')
        for (let i = 0; i < pDropDown.length; i++) {
            let aCurrString2 = pDropDown[i].childNodes[0] && pDropDown[i].childNodes[0].nodeValue != null ?
                pDropDown[i].childNodes[0].nodeValue.toUpperCase().replace(/\s+/g, '') : "";
            if ((aCurrString2 as string) === aStringToUpperCase) {
                return i;
            }
        }

        return -1
    }
    //__________________________________________________________________________________________
    public static selectOptionByIndex(pSelect: HTMLSelectElement, pIndex: number,
        pDispatchChangeEvent: boolean = false) {
        if (null == pSelect) {
            return;
        }

        pSelect.selectedIndex = pIndex;

        if (true == pDispatchChangeEvent) {
            pSelect.dispatchEvent(new Event('change'));
        }
    }
    //__________________________________________________________________________________________
}