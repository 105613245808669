import { eUnitType } from "../_context/Enums";
import { MathContext } from "../_context/MathContext";
import { eOpticShape, eBaseShape } from "../_context/OpticsContext";
import { tGratingSide } from "../_context/Types";
import { iNumericKeyHash, iMinMax } from "../_context/_interfaces/Interfaces";
import { iApertureDataOpticsVO, iGeneralSurfaceParams, iSurfaceDeformation, iParaxialLens, iMultiPlet, iThinCircularLens, iCircleBaseRoofPrismMirror, iRectBaseRoofPrismMirror, iAxiconPrism, iGeneralConicLens, iBallLens, iCircularOffAxisParabolic, iCircularParabolic, iASphericLens, iRightAnglePrism, iDispertionPrism, iRhombicPrism, iPellinBrocaPrism, iDShaped, iCircularWedgePrism, iRectWedgePrism, iPentaPrism, iEquilateralPrism, iBSCube, iDovePrism, eSurfaceDeformation, iZernikeDeformation, iPolynomialDeformation, iGeneralRectConicLens } from "../data/VO/OpticsVOInterfaces";
import { SurfaceContext } from "../parts/optics/SurfaceContext";
import { iSimulationGeometryData, iSmShapeData, iPhaseProfile, iGratingData, eSmGeometryType, eSmBaseShapeKind, eSmTermKind, iSMPolynomial } from "../simulation/SimulationContext";
import { UnitHandler } from "../units/UnitsHandler";
import { Strings } from "../_context/Strings";
import { OpticsShapeUtils } from "../parts/optics/OpticShapeUtils";
import { Matrix4, Vector3, Vector2, } from "three";
import { iFrameData, iJonesMatrix, iPhaseData } from "../parts/PartInterfaces";

export interface iSurfaceBasicData {
    shape?: eOpticShape;
    simGeoData?: iSimulationGeometryData;
    shapeData?: iSmShapeData;
    frameData?: iFrameData;
    paraxialEFL?: number;
    phase_profile?: iPhaseProfile;

    is_two_materials_surface?: boolean;
}

export interface iSurface extends iSurfaceBasicData {
    jonesMatrix?: iJonesMatrix;
    phaseData?: iPhaseData;
    apertureData?: iApertureDataOpticsVO;
    gratingData?: iGratingData;
    matrix: Matrix4;
    surfaceData: iGeneralSurfaceParams;

    normal?: Vector3;
    vertices?: Array<number>;
    isForSimulation?: boolean;
    isTransparent?: boolean;
    normals?: Array<number>;
};

export interface iSurfaceParams {
    rotationAxis: tVector3Array;
    rotationAngle: number;
    position: tVector3Array;

    surfaceName: string;
    scale?: tVector3Array;

    isInactive?: boolean;
    isMirror?: boolean;

    isForSumulation?: boolean;

    deformations?: Array<iSurfaceDeformation>;
}

export interface iPolygonCreationParameters extends iSurfaceParams {
    points: Array<number>;
}

export interface iRectPolygonCreationParameters extends iSurfaceParams {
    points: Array<number>;
    width: number;
    height: number;
}

export interface iRoofPrismCreationParams extends iSurfaceParams {
    diameter: number;
    thickness: number;
}

export interface iDShapedCreationParams extends iSurfaceParams {
    r: number;
    thickness: number;
    alpha_deg: number;
    center_height: number;
}

export interface iCircleCreationParams extends iSurfaceParams {
    r: number;
}

export interface iOneCircle {
    r: number;
    alpha: number;
}

export interface iRectCreationParams extends iRectParams, iSurfaceParams {

};

export interface iConicNPlateParams {
    dz: number;
    names: {
        front: string;
        back: string;
        frame: string;
    }
}

export interface iRectParams {
    width: number;
    height: number;
}

// export interface iCylinderCreationParams extends iSurfaceParams {
//     /**
//     * height of cylinder
//     */
//     height: number;
//     /**
//     * radius of base
//     */
//     r: number;
// };

export interface iAsphericCreationParams extends iSurfaceParams, iConicParams {
    coeffs: iNumericKeyHash<number>;
};

export interface iChebyshevCreationParams extends iSurfaceParams, iGeneralCircularConicParams {
    coeffs: iNumericKeyHash<iNumericKeyHash<number>>;
};

export interface iGeneralConicParams {
    cx: number;
    cy: number;
    kx: number;
    ky: number;

    x0?: number;
    y0?: number;
    z0?: number;

    deformations?: Array<iSurfaceDeformation>;
};

export interface iGeneralCircularConicParams extends iGeneralConicParams {
    diameter: number;
};
export interface iGeneralRectangularConicParams extends iGeneralConicParams {
    width: number;
    height: number;
};


export interface iConicZernike extends iGeneralCircularConicParams {
    coeffs: iNumericKeyHash<number>;
    normalization_radius: number;
}
export interface iBiconicZernike extends iConicZernike {
    thickness: number;
}

export interface iConicZernikeParams extends iSurfaceParams, iConicZernike {
}

export interface iZernikeFringeSag extends iConicZernikeParams {
    fringeSagCoeffs: [number, number, number, number, number, number, number, number];
}

export interface iZernikeStandartPhase extends iConicZernikeParams {
    M: number;
}

export interface iRectangularData extends iSurfaceParams {
    length: number;
    height: number;
    segments: number;
};

export interface iRectangularCylindricalCreationParams extends iSurfaceParams {
    length: number;
    height: number;
    rx: number;
    ry: number;
    segments: number;
};

export interface iCylindricalCreationParams extends iSurfaceParams {
    diameter: number;
    rx: number;
    ry: number;
};

export interface iQConCreationParams extends iSurfaceParams, iConicParams {
    coeffs: iNumericKeyHash<number>;
};

export interface iOffAxisParabolicCreationParams extends iSurfaceParams, iConicParams {
    y_offset: number;
};

export interface iParabolicCreationParams extends iSurfaceParams, iConicParams { }
export interface iSphericalCreationParams extends iSurfaceParams, iConicParams {
};

export interface iConicParams {
    /**
    * radius of curvature
    */
    R: number;
    /**
     * conic coefficient
     */
    K: number;
    /**
     * radius of base
     */
    diameter: number;
}

export interface iAxiconCreationParams extends iSurfaceParams {
    diameter: number;
    height: number;
    convexity: string;
}

export interface iConicCreationParams extends iSurfaceParams, iConicParams {
};

export interface iGenericRectConicCreationParams extends iSurfaceParams, iGeneralRectangularConicParams {
};

export interface iGenericConicCreationParams extends iSurfaceParams, iGeneralCircularConicParams {
};

export interface iGenericConicArcParams extends iGenericConicCreationParams {
    y_range: iMinMax<number>;
    num_of_points: number;
}

export interface iOddAsphereCreationParams extends iSurfaceParams, iConicParams {
    coefficients: iNumericKeyHash<number>;
};

export interface iParallelRects {
    front: iRectParams;
    back: iRectParams;
    thickness: number;
};

export interface iBoxParams extends iRectParams {
    thickness: number;
};

export interface iTrapezoidCreationParams extends iTrapezoidSurfaceParams, iSurfaceParams {

}
export interface iTrapezoidSurfaceParams {
    W1: number;
    W2: number;
    H: number;
}

export interface iOpticsCreation {
    surfaces: Array<iGeneralSurfaceParams>;
    baseShape: eBaseShape;
    shape: number;
}

export interface iRing {
    r1: number;
    r2: number;
    thickness?: number;
}

export interface iRingParams extends iSurfaceParams {
    r1: number;
    r2: number;
}

export type tVector3Array = [number, number, number];
export type tVector2Array = [number, number];

export class MatrixUtils {

    public static getRectangularAperture(
        pParams: iRectParams,
        pThickness: number,
        pPhysicalData: iApertureDataOpticsVO): iSurface[] {

        let aSurfaces: Array<iSurface>;

        aSurfaces = MatrixUtils.getRectBoxOptics({
            height: pParams.height,
            width: pParams.width,
            thickness: pThickness
        });

        aSurfaces.forEach(surface => {
            if (surface.surfaceData.name == Strings.FRONT) {
                //if ((pPhysicalData.phase_mask != null && pPhysicalData.phase_mask.url != "") ||  (pPhysicalData.transmittance_mask != null && pPhysicalData.transmittance_mask.url != "")) {

                surface.apertureData = {
                    phase_mask: pPhysicalData.phase_mask,
                    transmittance_mask: pPhysicalData.transmittance_mask
                }
                //}
            } else if (surface.surfaceData.name == Strings.BACK) {
                // nothing for now
                surface.isTransparent = true;
                surface.isForSimulation = false;
            } else {
                surface.isForSimulation = false;
            }
        });



        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static ROTATION_AXIS: tVector3Array = [0, 1, 0];
    //__________________________________________________________________________________________
    public static getMatrix4(pRotationMatrix: Array<Array<number>>, pPosition: Array<number>) {
        let aMatrixElements = [
            pRotationMatrix[0][0], pRotationMatrix[0][1], pRotationMatrix[0][2], 0,
            pRotationMatrix[1][0], pRotationMatrix[1][1], pRotationMatrix[1][2], 0,
            pRotationMatrix[2][0], pRotationMatrix[2][1], pRotationMatrix[2][2], 0,
            pPosition[0], pPosition[1], pPosition[2], 1
        ];

        return aMatrixElements;
    }
    //__________________________________________________________________________________________
    /**
     * Return 
     * @param pRing 
     */
    //__________________________________________________________________________________________
    // public static getLaser() {
    //     let aSize = 25;

    //     let aPoints = [
    //         -(aSize / 2), -(aSize / 2),
    //         -(aSize / 2), (aSize / 2),
    //         (aSize / 2), (aSize / 2),
    //         (aSize / 2), -(aSize / 2)
    //     ];

    //     let aRect = this._getPolygon({
    //         points: aPoints,
    //         position: [0, 0, 0],
    //         rotationAngle: 0,
    //         rotationAxis: [0, 1, 0],
    //         surfaceName: 'face_0',
    //     });

    //     let aVertices = aRect.vertices;

    //     let aMaterial = parts.optics.ThreeMaterialUtils.createUncoatedMat();
    //     aMaterial.color.setHex(0xFFFF00);
    //     aMaterial.transparent = false;
    //     aMaterial.depthTest = false;

    //     let aGeo = new BufferGeometry();
    //     aGeo.setAttribute('position', new Float32BufferAttribute(aVertices, 3));
    //     aGeo.computeVertexNormals();
    //     let aRectMesh = new Mesh(aGeo, aMaterial);
    //     aRectMesh.name = 'face_0';


    //     let aTrianglePoints = [
    //         -(aSize / 8), (aSize / 8),
    //         (aSize / 8), (aSize / 8),
    //         0, (-aSize / 8)
    //     ];

    //     let aTriangle = this._getPolygon({
    //         points: aTrianglePoints,
    //         position: [0, 0, 0.01],
    //         rotationAngle: 0,
    //         rotationAxis: [0, 1, 0],
    //         surfaceName: 'face_0',
    //     });

    //     let aTriangleMaterial = parts.optics.ThreeMaterialUtils.createUncoatedMat();
    //     aTriangleMaterial.color.setHex(0xFFFFFF);
    //     aTriangleMaterial.depthTest = false;
    //     aTriangleMaterial.transparent = false;
    //     aTriangleMaterial.side = BackSide;

    //     let aTriangleGeo = new BufferGeometry();
    //     aTriangleGeo.setAttribute('position', new Float32BufferAttribute(aTriangle.vertices, 3));
    //     aTriangleGeo.computeVertexNormals();
    //     let aTriangleMesh = new Mesh(aTriangleGeo, aTriangleMaterial);
    //     aTriangleMaterial.name = 'face_0';

    //     let aObject3D = new Object3D();
    //     aObject3D.name = 'parts_parent';
    //     aObject3D.add(new Object3D());
    //     aObject3D.children[0].name = 'L0001';
    //     aObject3D.children[0].add(new Object3D());
    //     aObject3D.children[0].children[0].name = 'shape_0';
    //     aObject3D.children[0].children[0].add(new Object3D());
    //     aObject3D.children[0].children[0].children[0].name = 'solid_0';

    //     aObject3D.children[0].children[0].add(new Object3D());
    //     aObject3D.children[0].children[0].children[1].name = 'solid_1';

    //     aObject3D.children[0].children[0].children[0].add(aRectMesh);
    //     aObject3D.children[0].children[0].children[1].add(aTriangleMesh);

    //     aObject3D.add(new Object3D());
    //     aObject3D.children[1].name = LASER_POINT;
    //     FileUtils.downloadFile({
    //         content: JSON.stringify(aObject3D.toJSON()),
    //         fullfileName: 'L0001.json',
    //         mimeType: 'application/json'
    //     });

    //     /*

    //                 */
    // }
    //__________________________________________________________________________________________
    public static getParaxialLens(pParaxialLens: iParaxialLens) {
        let aSurfaces = new Array<iSurface>();
        aSurfaces.push(this.getOneCircularThinSurface({
            position: [0, 0, 0],
            r: (pParaxialLens.diameter / 2),
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.PARAXIAL_SURFACE,
        }).surface);

        aSurfaces[0].paraxialEFL = pParaxialLens.paraxialEFL;

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getOutsideCircleFrame(pRing: iRing) {
        let aSurfaces = new Array<iSurface>();

        let aR1 = pRing.r1;
        let aR2 = pRing.r2;
        let aThickness = pRing.thickness;

        if (0 == aR1) {
            let aSurfaceFront = this.getOneCircularThinSurface({
                r: aR2,
                position: [0, 0, 0],
                rotationAngle: 0,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.FRONT,
                isInactive: true,
                isMirror: false
            });

            return [aSurfaceFront.surface];
        }

        let aSurfaceFront = this._getOneRing({
            r1: aR1,
            r2: aR2,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
            isInactive: true,
            isMirror: false
        });

        aSurfaces.push(aSurfaceFront.surface);

        if ((null != aThickness) && (aThickness > 0)) {
            let aSurfaceBack = this._getOneRing({
                r1: aR1,
                r2: aR2,
                position: [0, 0, aThickness],
                rotationAngle: 0,
                rotationAxis: [0, 1, 0],
                surfaceName: SurfaceContext.BACK,
                isInactive: true,
                isMirror: false,
            });

            aSurfaces.push(aSurfaceBack.surface);


            let aFrontArr = new Array<Vector3>();
            let aBackArr = new Array<Vector3>();
            for (let i = 0; i < aSurfaces[0].vertices.length; i += 3) {
                aFrontArr.push(new Vector3(
                    aSurfaces[0].vertices[i],
                    aSurfaces[0].vertices[i + 1],
                    0
                ));
                aBackArr.push(new Vector3(
                    aSurfaces[1].vertices[i],
                    aSurfaces[1].vertices[i + 1],
                    aThickness
                ));
            }

            this._addFrame(aSurfaceFront.edgeVerticesInside, aSurfaceBack.edgeVerticesInside,
                aSurfaceFront.surface, aSurfaceBack.surface, aSurfaces);

            this._addFrame(aSurfaceFront.edgeVerticesOutside, aSurfaceBack.edgeVerticesOutside,
                aSurfaceFront.surface, aSurfaceBack.surface, aSurfaces);
        }
        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getNPlateLens(pNPlate: iMultiPlet) {
        let aSurfaces = new Array<iSurface>();
        let aDiameter = pNPlate.diameter;

        let aNumberOfLenses = (pNPlate.multiplet_r.length - 1);
        let aDz = 0;

        let aFirstSurface: {
            surface: iSurface;
            edgeVertices: Vector3[];
        };
        let aLastSurface: {
            surface: iSurface;
            edgeVertices: Vector3[];
        };


        for (let i = 0; i < aNumberOfLenses; i++) {
            let aThicknessCenter = pNPlate.multiplet_thickness_center[i];
            let aR1 = pNPlate.multiplet_r[i];
            let aR2 = pNPlate.multiplet_r[(i + 1)];

            let aFrontSurfaceName = SurfaceContext.FRONT;
            if (i > 0) {
                aFrontSurfaceName += ('_' + i);
            }

            const aFrontSurface = this.getOneConicSurface({
                K: 0,
                R: aR1,
                diameter: aDiameter,
                position: [0, 0, aDz],
                rotationAngle: 0,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: aFrontSurfaceName
            });

            aFrontSurface.surface.is_two_materials_surface = true;
            if (0 == i) {
                aFirstSurface = aFrontSurface;
            }

            aSurfaces.push(aFrontSurface.surface);

            let aBackSurfaceName = SurfaceContext.BACK + '_' + (i + 1);

            let aBackSurface;
            if (0 !== aR2 && aR2 !== undefined) {
                aBackSurface = this.getOneConicSurface({
                    K: 0,
                    R: -aR2,
                    diameter: aDiameter,
                    position: [0, 0, (aDz + aThicknessCenter)],
                    rotationAngle: Math.PI,
                    rotationAxis: MatrixUtils.ROTATION_AXIS,
                    surfaceName: aBackSurfaceName
                });

            } else {
                aBackSurface = this.getOneCircularThinSurface({
                    r: (aDiameter / 2),
                    position: [0, 0, (aDz + aThicknessCenter)],
                    rotationAngle: Math.PI,
                    rotationAxis: MatrixUtils.ROTATION_AXIS,
                    surfaceName: aBackSurfaceName
                });
            }

            aDz += (aThicknessCenter + MathContext.EPSILON_3);

            aSurfaces.push(aBackSurface.surface);

            if (i == (aNumberOfLenses - 1)) {
                aLastSurface = aBackSurface;
                aLastSurface.surface.surfaceData.name =
                    SurfaceContext.BACK;
            }
        }

        let aBackVertices = aLastSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aLastSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFirstSurface.edgeVertices, aBackVertices,
            aFirstSurface.surface, aLastSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    private static _getOneRing(pRingParams: iRingParams) {
        let aR1 = pRingParams.r1;
        let aR2 = pRingParams.r2;

        let aDeltaPhi = (Math.PI / 180);
        let aVertices = new Array<number>();

        let aRotationAxis = pRingParams.rotationAxis;
        let aRotationAngle = pRingParams.rotationAngle;
        let aPosition = pRingParams.position;

        let aRotationMatrix = this.getAxisRotationMatrix(aRotationAxis, aRotationAngle);
        let aMatrixElements = this.getMatrix4(aRotationMatrix, aPosition);

        //IC - inside circle
        //OC - outside circle

        let aEdgeVerticesInside = new Array<Vector3>();
        let aEdgeVerticesOutside = new Array<Vector3>();
        for (let phi = aDeltaPhi; phi <= (2 * Math.PI); phi += aDeltaPhi) {
            let aPrevAngle = (phi - aDeltaPhi);

            let aSinPhi1 = Math.sin(aPrevAngle);
            let aSinPhi2 = Math.sin(phi);
            let aCosPhi1 = Math.cos(aPrevAngle);
            let aCosPhi2 = Math.cos(phi);

            let aICPoint1 = [(aR1 * aCosPhi1), (aR1 * aSinPhi1), 0];
            let aICPoint2 = [(aR1 * aCosPhi2), (aR1 * aSinPhi2), 0];
            let aOCPoint1 = [(aR2 * aCosPhi1), (aR2 * aSinPhi1), 0];
            let aOCPoint2 = [(aR2 * aCosPhi2), (aR2 * aSinPhi2), 0];

            aVertices.push(...aICPoint1, ...aICPoint2, ...aOCPoint1);
            aVertices.push(...aOCPoint1, ...aOCPoint2, ...aICPoint2);

            aEdgeVerticesInside.push(
                new Vector3(
                    (aR1 * aCosPhi1),
                    (aR1 * aSinPhi1),
                    aPosition[2])
            );
            aEdgeVerticesOutside.push(
                new Vector3(
                    (aR2 * aCosPhi1),
                    (aR2 * aSinPhi1),
                    aPosition[2])
            );
        }

        //aEdgeVerticesInside.push(new Vector3(aR1, 0, aPosition[2]));
        //aEdgeVerticesOutside.push(new Vector3(aR2, 0, aPosition[2]));

        let aSurface: iSurface = {
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pRingParams.surfaceName,
                isInactive: pRingParams.isInactive,
                isMirror: pRingParams.isMirror
            },
            vertices: aVertices
        };

        return {
            edgeVerticesInside: aEdgeVerticesInside,
            edgeVerticesOutside: aEdgeVerticesOutside,
            surface: aSurface
        };
    }
    //__________________________________________________________________________________________
    public static getOneRect(pRectParams: iRectParams, _pPosition: Array<number>) {
        let aSurfaces = new Array<iSurface>();

        let aW = pRectParams.width;
        let aH = pRectParams.height;

        aSurfaces.push(this._getPolygon({
            points: [
                -aW / 2, -aH / 2,
                -aW / 2, aH / 2,
                aW / 2, aH / 2,
                aW / 2, -aH / 2
            ],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: [1, 0, 0],
            surfaceName: SurfaceContext.FRONT
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularGrating(pGeoData: iThinCircularLens, pGratingData: iGratingData,
        pGratingSide: tGratingSide) {

        let aSurfaces = new Array<iSurface>();

        let aR = pGeoData.diameter / 2;
        let aThickness = pGeoData.thickness;

        const aFrontSurface = this.getOneCircularThinSurface({
            r: aR,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });

        aSurfaces.push(aFrontSurface.surface);

        const aBackSurface = this.getOneCircularThinSurface({
            r: aR,
            position: [0, 0, aThickness],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK
        });
        aSurfaces.push(aBackSurface.surface);

        if (pGratingSide == "Back") {
            aBackSurface.surface.gratingData = pGratingData;
        } else {
            aFrontSurface.surface.gratingData = pGratingData;
        }

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularThinLens(pThinCircularLens: iThinCircularLens) {
        let aSurfaces = new Array<iSurface>();

        let aR = pThinCircularLens.diameter / 2;
        let aThickness = pThinCircularLens.thickness;

        const aFrontSurface = this.getOneCircularThinSurface({
            r: aR,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });

        aSurfaces.push(aFrontSurface.surface);

        const aBackSurface = this.getOneCircularThinSurface({
            r: aR,
            position: [0, 0, aThickness],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK
        });
        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularRoofPrism(pRoofPrismMirror: iCircleBaseRoofPrismMirror) {
        let aSurfaces = new Array<iSurface>();
        const aFrontSurface = this.getOneCircularRoofPrismSurface({
            thickness: pRoofPrismMirror.thickness,
            diameter: pRoofPrismMirror.diameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });
        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface = this.getOneCircularThinSurface({
            r: (pRoofPrismMirror.diameter / 2),
            position: [0, 0, pRoofPrismMirror.thickness],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK,
        });

        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getSquaredRoofPrismMirror(pRoofPrismMirror: iRectBaseRoofPrismMirror) {
        let aSurfaces = new Array<iSurface>();

        let aW = pRoofPrismMirror.width;
        let aH = pRoofPrismMirror.height;
        let aT = pRoofPrismMirror.thickness;
        let aA = (aW / 2);

        let aRotationAxis: tVector3Array = [0, 1, 0];
        let aFrontSurfacesWidth = Math.hypot(aA, (aH / 2));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aFrontSurfacesWidth / 2, -aH / 2,
                -aFrontSurfacesWidth / 2, aH / 2,
                aFrontSurfacesWidth / 2, aH / 2,
                aFrontSurfacesWidth / 2, -aH / 2
            ],
            position: [0, 0, 0],
            rotationAngle: (Math.PI / 4),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT_RIGHT,
            isMirror: true
        }));

        let aFrontLeftX = (aW / 2);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aFrontSurfacesWidth / 2, -aH / 2,
                -aFrontSurfacesWidth / 2, aH / 2,
                aFrontSurfacesWidth / 2, aH / 2,
                aFrontSurfacesWidth / 2, -aH / 2
            ],
            position: [aFrontLeftX, 0, 0],
            rotationAngle: -(Math.PI / 4),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT_LEFT,
            isMirror: true
        }));

        let aRightX = -(aFrontSurfacesWidth * Math.SQRT1_2) / 2;
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aH / 2,
                -aA / 2, aH / 2,
                aA / 2, aH / 2,
                aA / 2, -aH / 2,
            ],
            position: [aRightX, 0, 0],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));

        let aLeftX = (-aRightX + aW / 2);
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aH / 2,
                -aA / 2, aH / 2,
                aA / 2, aH / 2,
                aA / 2, -aH / 2,
            ],
            position: [aLeftX, 0, 0],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT
        }));


        let aBackSidesZ = ((aA + aT) / 2);
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aT / 2, -aH / 2,
                -aT / 2, aH / 2,
                aT / 2, aH / 2,
                aT / 2, -aH / 2,
            ],
            position: [aRightX, 0, aBackSidesZ],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK_RIGHT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aT / 2, -aH / 2,
                -aT / 2, aH / 2,
                aT / 2, aH / 2,
                aT / 2, -aH / 2,
            ],
            position: [aLeftX, 0, aBackSidesZ],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK_LEFT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aH / 2,
                -aW / 2, aH / 2,
                aW / 2, aH / 2,
                aW / 2, -aH / 2,
            ],
            position: [(aFrontLeftX / 2), 0, (aA / 2 + aT)],
            rotationAngle: (Math.PI),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        aRotationAxis = [1, 0, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, -aA / 2,
                0, aA / 2,
                aW / 2, aA / 2
            ],
            position: [aRightX, aH / 2, 0],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP_FRONT_RIGHT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, aA / 2,
                0, -aA / 2,
                aW / 2, aA / 2
            ],
            position: [aRightX, -aH / 2, 0],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM_FRONT_RIGHT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, -aA / 2,
                0, aA / 2,
                -aW / 2, -aA / 2
            ],
            position: [aLeftX, aH / 2, 0],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP_FRONT_LEFT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, aA / 2,
                0, -aA / 2,
                -aW / 2, -aA / 2
            ],
            position: [aLeftX, -aH / 2, 0],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM_FRONT_LEFT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aT / 2,
                -aW / 2, aT / 2,
                aW / 2, aT / 2,
                aW / 2, -aT / 2,
            ],
            position: [(aFrontLeftX / 2), aH / 2, aBackSidesZ],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP_BACK,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aT / 2,
                -aW / 2, aT / 2,
                aW / 2, aT / 2,
                aW / 2, -aT / 2,
            ],
            position: [(aFrontLeftX / 2), -aH / 2, aBackSidesZ],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM_BACK,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularAxicon(pAxiconPrism: iAxiconPrism) {
        const aSurfaces = new Array<iSurface>();
        //let aHeight = pAxiconPrism.thickness_center - pAxiconPrism.thickness_edge;
        const aFrontSurface = this.getOneCircularAxicon({
            convexity: pAxiconPrism.convexity,
            height: pAxiconPrism.height,
            diameter: pAxiconPrism.diameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });
        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface = this.getOneCircularThinSurface({
            r: (pAxiconPrism.diameter / 2),
            position: [0, 0, pAxiconPrism.height + pAxiconPrism.thickness_edge],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK,
        });

        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;

    }
    //__________________________________________________________________________________________
    public static getCircularConicLens(pGeneralConicLens: iGeneralConicLens,
        pConicNPlate?: iConicNPlateParams) {
        const aSurfaces = new Array<iSurface>();
        const aThicknessCenter = pGeneralConicLens.thickness_center;


        let aDz = (null != pConicNPlate) ? pConicNPlate.dz : 0;
        let aFrontName = (null != pConicNPlate) ? pConicNPlate.names.front :
            SurfaceContext.FRONT;
        let aBackName = (null != pConicNPlate) ? pConicNPlate.names.back :
            SurfaceContext.BACK;
        let aFrameName = (null != pConicNPlate) ? pConicNPlate.names.frame :
            SurfaceContext.FRAME;

        const aFrontSurface = this.getOneGenericConicSurface({
            cx: pGeneralConicLens.front.cx,
            cy: pGeneralConicLens.front.cy,
            kx: pGeneralConicLens.front.kx,
            ky: pGeneralConicLens.front.ky,
            x0: pGeneralConicLens.front.x0,
            y0: pGeneralConicLens.front.y0,
            z0: pGeneralConicLens.front.z0,
            deformations: pGeneralConicLens.front.deformations,
            diameter: pGeneralConicLens.front.diameter,
            position: [0, 0, aDz],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: aFrontName,
        });

        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface;

        if ((0 == pGeneralConicLens.back.cx) && (0 == pGeneralConicLens.back.cy)) {
            aBackSurface = this.getOneCircularThinSurface({
                r: (pGeneralConicLens.back.diameter / 2),
                position: [0, 0, aThicknessCenter],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK,
                isInactive: true
            });
        } else {
            aBackSurface = this.getOneGenericConicSurface({
                cx: pGeneralConicLens.back.cx,
                cy: pGeneralConicLens.back.cy,
                kx: pGeneralConicLens.back.kx,
                ky: pGeneralConicLens.back.ky,
                x0: pGeneralConicLens.back.x0,
                y0: pGeneralConicLens.back.y0,
                z0: pGeneralConicLens.back.z0,
                deformations: pGeneralConicLens.back.deformations,
                isInactive: false,
                diameter: pGeneralConicLens.back.diameter,
                position: [0, 0, (aDz + aThicknessCenter)],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: aBackName,
            });
        }

        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces, aFrameName);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getHalfBallLens(pCylindrical: iBallLens) {
        let aSurfaces = new Array<iSurface>();

        const aDiameter = pCylindrical.diameter
        const aRadiusCurvature = (pCylindrical.diameter / 2) + MathContext.EPSILON_10;
        const aSphereHeight = this._sphereHeight(aRadiusCurvature, aDiameter);
        const aFrontSurface = this.getOneSphericalSurface({
            K: 0,
            R: aRadiusCurvature,
            isInactive: false,
            diameter: aDiameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });

        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface = this.getOneCircularThinSurface({
            r: (aDiameter / 2),
            position: [0, 0, aSphereHeight],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK,
        });

        aSurfaces.push(aBackSurface.surface);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getBallLens(pCylindrical: iBallLens) {
        let aSurfaces = new Array<iSurface>();

        const aDiameter = pCylindrical.diameter
        const aRadiusCurvature = (pCylindrical.diameter / 2) + MathContext.EPSILON_10;
        const aSphereHeight = this._sphereHeight(aRadiusCurvature, aDiameter) * 2;
        const aFrontSurface = this.getOneSphericalSurface({
            K: 0,
            R: aRadiusCurvature,
            isInactive: false,
            diameter: aDiameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });

        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface = this.getOneSphericalSurface({
            isInactive: false,
            K: 0,
            R: aRadiusCurvature,
            diameter: aDiameter,
            position: [0, 0, aSphereHeight],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK,
        });

        aSurfaces.push(aBackSurface.surface);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getOffAxisCircularParabolicLens(pParabolic: iCircularOffAxisParabolic) {
        let aSurfaces = new Array<iSurface>();

        const aDiameter = pParabolic.diameter
        const aThicknessEdge = pParabolic.thickness_edge;

        const aOffAxisAngle = (pParabolic.off_axis_angle);
        const R = pParabolic.radius_of_curvature;
        const c = -(1 / R);
        const x0 = (Math.tan(aOffAxisAngle / 2) * R);
        const z0 = (1 / (2 * R)) * (Math.pow(x0, 2));

        let zA = (1 / (2 * R)) * Math.pow((x0 + (aDiameter / 2)), 2);


        let aThicknessCenter = (aThicknessEdge - (zA - z0));

        const aFrontSurface = this.getOneGenericConicSurface({
            cx: c,
            cy: c,
            kx: -1,
            ky: -1,
            x0: x0,
            y0: 0,
            z0: z0,
            isInactive: false,
            diameter: aDiameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT
        });

        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface = this.getOneCircularThinSurface({
            r: (pParabolic.diameter / 2),
            position: [0, 0, aThicknessCenter],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK,
            isInactive: true
        });

        aSurfaces.push(aBackSurface.surface);


        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularParabolicLens(pParabolic: iCircularParabolic) {
        let aSurfaces = new Array<iSurface>();

        const aR1 = -pParabolic.radius_of_curvature;
        const aR2 = 0;//2 * pParabolic.efl;
        const aDiameter = pParabolic.diameter
        const aThicknessEdge = pParabolic.thickness_edge;
        const t = aR1 * (1 - Math.sqrt(1 - Math.pow((aDiameter / (2 * Math.abs(aR1))), 2)));
        let aThicknessCenter = (aThicknessEdge + t);

        const aFrontSurface = this.getOneCircularParabolic({
            K: -1,
            isInactive: false,
            R: aR1,
            diameter: aDiameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT,
        });

        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface;
        if (aR2 != null && 0 != aR2) {
            aBackSurface = this.getOneCircularParabolic({
                K: -1,
                R: -aR2,
                diameter: aDiameter,
                position: [0, 0, aThicknessCenter],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK,
            });

        } else {
            aBackSurface = this.getOneCircularThinSurface({
                r: (aDiameter / 2),
                position: [0, 0, aThicknessCenter],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK,
            });
        }

        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularASphericLens(pASphericLens: iASphericLens) {
        let aSurfaces = new Array<iSurface>();

        const aR1 = pASphericLens.r1;
        const aR2 = pASphericLens.r2;
        const aDiameter = pASphericLens.diameter
        const aThickness = pASphericLens.thickness_center;

        let aFrontSurface;
        let aBackSurface;


        if (aR1 == 0) {
            // front is thin lens
            aFrontSurface = this.getOneCircularThinSurface({
                r: (aDiameter / 2),
                position: [0, 0, 0],
                rotationAngle: 0,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.FRONT,
            });
        } else {
            aFrontSurface = this.getOneAsphericSurface({
                coeffs: pASphericLens.coeffs,
                isInactive: false,
                K: pASphericLens.k,
                R: aR1,
                diameter: aDiameter,
                position: [0, 0, 0],
                rotationAngle: 0,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.FRONT,
            });
        }

        if ((0 === aR2) || (undefined === aR2)) {
            aBackSurface = this.getOneCircularThinSurface({
                r: (aDiameter / 2),
                position: [0, 0, aThickness],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK,
            });
        } else {
            aBackSurface = this.getOneGenericConicSurface({
                isInactive: false,
                kx: pASphericLens.k,
                ky: pASphericLens.k,
                cx: -(1 / aR2),
                cy: -(1 / aR2),
                diameter: aDiameter,
                position: [0, 0, aThickness],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK,
            });
        }

        aSurfaces.push(aFrontSurface.surface);
        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getRectangularGrating(
        pParams: iRectParams, pThickness: number,
        pGratingData: iGratingData,
        pGratingSide: tGratingSide) {

        let aSurfaces = new Array<iSurface>();
        let aRotationAxis: tVector3Array = [1, 0, 0];
        let aWidth = pParams.width;
        let aHeight = pParams.height;

        let aFrontSurface = MatrixUtils._getPolygon({
            points: [-aWidth / 2, -aHeight / 2, -aWidth / 2, aHeight / 2,
            aWidth / 2, aHeight / 2, aWidth / 2, -aHeight / 2],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT,
        });

        aSurfaces.push(aFrontSurface);

        let aBackSurface = MatrixUtils._getPolygon({
            points: [-aWidth / 2, -aHeight / 2, -aWidth / 2, aHeight / 2,
            aWidth / 2, aHeight / 2, aWidth / 2, -aHeight / 2],
            position: [0, 0, pThickness],
            rotationAngle: Math.PI,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        });

        aSurfaces.push(aBackSurface);

        if (pGratingSide == "Back") {
            aBackSurface.gratingData = pGratingData;
        } else {
            aFrontSurface.gratingData = pGratingData;
        }

        let aHDelta = (aHeight / 2 - aHeight / 2);
        let aVec1 = new Vector3(0, 0, 1);
        let aTopBottomSurfaceVec = new Vector3(0, aHDelta, pThickness);
        let aFBAngleY = aVec1.angleTo(aTopBottomSurfaceVec) * Math.sign(aHDelta);

        let aH = aTopBottomSurfaceVec.length();
        let aTopHeight = (aHeight / 2 + aTopBottomSurfaceVec.y / 2);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [(-aWidth / 2), (-aH / 2), (-aWidth / 2), (aH / 2),
            (aWidth / 2), (aH / 2), (aWidth / 2), (-aH / 2)],
            position: [0, aTopHeight, pThickness / 2],
            rotationAngle: -((Math.PI / 2) - aFBAngleY),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [(-aWidth / 2), (-aH / 2), (-aWidth / 2), (aH / 2),
            (aWidth / 2), (aH / 2), (aWidth / 2), (-aH / 2)],
            position: [0, -aTopHeight, pThickness / 2],
            rotationAngle: ((Math.PI / 2) - aFBAngleY),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        //change rotation axis:

        aRotationAxis = [0, 1, 0];

        let aWDelta = (aWidth / 2 - aWidth / 2);

        let aW = Math.sqrt(Math.pow(pThickness, 2) + Math.pow(aWDelta, 2));
        let aLeftRightVec = new Vector3(aWDelta, 0, pThickness);
        let aRightW = -(aWidth / 2 + aLeftRightVec.x / 2);
        let aFBAngleX = aVec1.angleTo(aLeftRightVec) * Math.sign(aWDelta);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [-aW, (-aHeight / 2), 0, (-aHeight / 2),
                0, (aHeight / 2), -aW, (aHeight / 2)],
            position: [aRightW, 0, pThickness],
            rotationAngle: ((Math.PI / 2) - aFBAngleX),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [0, (-aHeight / 2), aW, (-aHeight / 2),
                aW, (aHeight / 2), 0, (aHeight / 2)],
            position: [-aRightW, 0, pThickness],
            rotationAngle: -((Math.PI / 2) - aFBAngleX),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getRectBoxOptics(pBoxParams: iBoxParams) {
        return this.getParallelRects({
            back: {
                height: pBoxParams.height,
                width: pBoxParams.width
            },
            front: {
                height: pBoxParams.height,
                width: pBoxParams.width
            },
            thickness: pBoxParams.thickness
        });
    }
    //__________________________________________________________________________________________
    public static getPolygonBoxOptics(pBoxParams: iBoxParams) {
        return this.getParallelPolygons({
            back: {
                height: pBoxParams.height,
                width: pBoxParams.width
            },
            front: {
                height: pBoxParams.height,
                width: pBoxParams.width
            },
            thickness: pBoxParams.thickness
        });
    }
    //__________________________________________________________________________________________
    public static getParallelRects(pParallelRects: iParallelRects) {
        let aSurfaces = new Array<iSurface>();
        let aRotationAxis: tVector3Array = [1, 0, 0];

        let aBackW = pParallelRects.back.width;
        let aBackH = pParallelRects.back.height;

        let aFrontW = pParallelRects.front.width;
        let aFrontH = pParallelRects.front.height;

        let aThickness = pParallelRects.thickness;

        aSurfaces.push(MatrixUtils._getRect({
            points: [-aFrontW / 2, -aFrontH / 2, -aFrontW / 2, aFrontH / 2,
            aFrontW / 2, aFrontH / 2, aFrontW / 2, -aFrontH / 2],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT,
            height: aFrontH,
            width: aFrontW
        }));

        aSurfaces.push(MatrixUtils._getRect({
            points: [-aBackW / 2, -aBackH / 2, -aBackW / 2, aBackH / 2,
            aBackW / 2, aBackH / 2, aBackW / 2, -aBackH / 2],
            position: [0, 0, aThickness],
            rotationAngle: Math.PI,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK,
            height: aBackH,
            width: aBackW
        }));

        let aHDelta = (aBackH / 2 - aFrontH / 2);

        let aVec1 = new Vector3(0, 0, 1);
        let aTopBottomSurfaceVec = new Vector3(0, aHDelta, aThickness);
        let aFBAngleY = aVec1.angleTo(aTopBottomSurfaceVec) * Math.sign(aHDelta);

        let aH = aTopBottomSurfaceVec.length();
        let aTopHeight = (aFrontH / 2 + aTopBottomSurfaceVec.y / 2);

        aSurfaces.push(MatrixUtils._getRect({
            points: [(-aFrontW / 2), (-aH / 2), (-aBackW / 2), (aH / 2),
            (aBackW / 2), (aH / 2), (aFrontW / 2), (-aH / 2)],
            position: [0, aTopHeight, aThickness / 2],
            rotationAngle: -((Math.PI / 2) - aFBAngleY),
            rotationAxis: aRotationAxis,
            surfaceName: 'top',
            height: pParallelRects.thickness,
            width: aFrontW,
            isForSumulation: false
        }));

        aSurfaces.push(MatrixUtils._getRect({
            points: [(-aBackW / 2), (-aH / 2), (-aFrontW / 2), (aH / 2),
            (aFrontW / 2), (aH / 2), (aBackW / 2), (-aH / 2)],
            position: [0, -aTopHeight, aThickness / 2],
            rotationAngle: ((Math.PI / 2) - aFBAngleY),
            rotationAxis: aRotationAxis,
            surfaceName: 'bottom',
            height: pParallelRects.thickness,
            width: aFrontW,
            isForSumulation: false
        }));

        //change rotation axis:

        aRotationAxis = [0, 1, 0];

        let aWDelta = (aBackW / 2 - aFrontW / 2);

        let aW = Math.sqrt(Math.pow(aThickness, 2) + Math.pow(aWDelta, 2));
        let aLeftRightVec = new Vector3(aWDelta, 0, aThickness);
        let aRightW = -(aFrontW / 2 + aLeftRightVec.x / 2);
        let aFBAngleX = aVec1.angleTo(aLeftRightVec) * Math.sign(aWDelta);

        aSurfaces.push(MatrixUtils._getRect({
            points: [(-aW / 2), (-aBackH / 2), (aW / 2), (-aFrontH / 2),
            (aW / 2), (aFrontH / 2), (-aW / 2), (aBackH / 2)],
            position: [aRightW, 0, aThickness / 2],
            //   rotationAngle: -((Math.PI / 2) - aFBAngleX),
            rotationAngle: ((Math.PI / 2) - aFBAngleX),
            rotationAxis: aRotationAxis,
            surfaceName: 'right',
            height: aFrontH,
            width: pParallelRects.thickness,
            isForSumulation: false
        }));

        aSurfaces.push(MatrixUtils._getRect({
            points: [(-aW / 2), (-aFrontH / 2), (aW / 2), (-aBackH / 2),
            (aW / 2), (aBackH / 2), (-aW / 2), (aFrontH / 2)],
            position: [-aRightW, 0, aThickness / 2],
            // rotationAngle: ((Math.PI / 2) - aFBAngleX),
            rotationAngle: -((Math.PI / 2) - aFBAngleX),
            rotationAxis: aRotationAxis,
            surfaceName: 'left',
            height: aFrontH,
            width: pParallelRects.thickness,
            isForSumulation: false
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getParallelPolygons(pParallelRects: iParallelRects) {
        let aSurfaces = new Array<iSurface>();
        let aRotationAxis: tVector3Array = [1, 0, 0];

        let aBackW = pParallelRects.back.width;
        let aBackH = pParallelRects.back.height;

        let aFrontW = pParallelRects.front.width;
        let aFrontH = pParallelRects.front.height;

        let aThickness = pParallelRects.thickness;

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [-aFrontW / 2, -aFrontH / 2, -aFrontW / 2, aFrontH / 2,
            aFrontW / 2, aFrontH / 2, aFrontW / 2, -aFrontH / 2],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT,
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [-aBackW / 2, -aBackH / 2, -aBackW / 2, aBackH / 2,
            aBackW / 2, aBackH / 2, aBackW / 2, -aBackH / 2],
            position: [0, 0, aThickness],
            rotationAngle: Math.PI,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        let aHDelta = (aBackH / 2 - aFrontH / 2);

        let aVec1 = new Vector3(0, 0, 1);
        let aTopBottomSurfaceVec = new Vector3(0, aHDelta, aThickness);
        let aFBAngleY = aVec1.angleTo(aTopBottomSurfaceVec) * Math.sign(aHDelta);

        let aH = aTopBottomSurfaceVec.length();
        let aTopHeight = (aFrontH / 2 + aTopBottomSurfaceVec.y / 2);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [(-aFrontW / 2), (-aH / 2), (-aBackW / 2), (aH / 2),
            (aBackW / 2), (aH / 2), (aFrontW / 2), (-aH / 2)],
            position: [0, aTopHeight, aThickness / 2],
            rotationAngle: -((Math.PI / 2) - aFBAngleY),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [(-aBackW / 2), (-aH / 2), (-aFrontW / 2), (aH / 2),
            (aFrontW / 2), (aH / 2), (aBackW / 2), (-aH / 2)],
            position: [0, -aTopHeight, aThickness / 2],
            rotationAngle: ((Math.PI / 2) - aFBAngleY),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM
        }));

        //change rotation axis:

        aRotationAxis = [0, 1, 0];

        let aWDelta = (aBackW / 2 - aFrontW / 2);

        let aW = Math.sqrt(Math.pow(aThickness, 2) + Math.pow(aWDelta, 2));
        let aLeftRightVec = new Vector3(aWDelta, 0, aThickness);
        let aRightW = -(aFrontW / 2 + aLeftRightVec.x / 2);
        let aFBAngleX = aVec1.angleTo(aLeftRightVec) * Math.sign(aWDelta);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [-aW, (-aBackH / 2), 0, (-aFrontH / 2),
                0, (aFrontH / 2), -aW, (aBackH / 2)],
            position: [aRightW, 0, aThickness],
            rotationAngle: ((Math.PI / 2) - aFBAngleX),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [0, (-aFrontH / 2), aW, (-aBackH / 2),
                aW, (aBackH / 2), 0, (aFrontH / 2)],
            position: [-aRightW, 0, aThickness],
            rotationAngle: -((Math.PI / 2) - aFBAngleX),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getRightAnglePrism(pRightAnglePrism: iRightAnglePrism) {
        let aSurfaces = new Array<iSurface>();

        let aA = pRightAnglePrism.a;
        let aB = pRightAnglePrism.b;
        let aC = pRightAnglePrism.c;

        let aRotationAxis: tVector3Array = [0, 1, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aC / 2,
                -aA / 2, aC / 2,
                aA / 2, aC / 2,
                aA / 2, -aC / 2
            ],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aB / 2, -aC / 2,
                -aB / 2, aC / 2,
                aB / 2, aC / 2,
                aB / 2, -aC / 2
            ],
            position: [-aA / 2, 0, aB / 2],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));


        let aBackLength = Math.hypot(aA, aB);
        let aAlpha = Math.atan(aB / aA);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aBackLength / 2, -aC / 2,
                -aBackLength / 2, aC / 2,
                aBackLength / 2, aC / 2,
                aBackLength / 2, -aC / 2
            ],
            position: [0, 0, aB / 2],
            rotationAngle: (Math.PI - aAlpha),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.HYPOTENUSE
        }));

        aRotationAxis = [1, 0, 0];
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, 0,
                -aA / 2, aB,
                aA / 2, 0
            ],
            position: [0, aC / 2, 0],
            rotationAngle: -Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                aA / 2, aB / 2,
                -aA / 2, -aB / 2,
                -aA / 2, aB / 2
            ],
            position: [0, -aC / 2, aB / 2],
            rotationAngle: Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getDispertionPrism(pRightAnglePrism: iDispertionPrism) {
        let aSurfaces = new Array<iSurface>();

        let aBase = pRightAnglePrism.a;
        let aAlpha = pRightAnglePrism.alpha;
        let aL = pRightAnglePrism.l;

        let aFrontHeight = (aBase / (2 * Math.sin(aAlpha / 2)));
        let aRotationAxis: tVector3Array = [1, 0, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aL / 2, -aFrontHeight / 2,
                -aL / 2, aFrontHeight / 2,
                aL / 2, aFrontHeight / 2,
                aL / 2, -aFrontHeight / 2
            ],
            position: [0, 0, 0],
            rotationAngle: -(aAlpha / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                aL / 2, -aFrontHeight / 2,
                aL / 2, aFrontHeight / 2,
                -aL / 2, aFrontHeight / 2,
                -aL / 2, -aFrontHeight / 2
            ],
            position: [0, 0, aBase / 2],
            rotationAngle: (aAlpha / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        let aPrismHeight = (aFrontHeight * Math.cos(aAlpha / 2));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aL / 2, -aBase / 2,
                -aL / 2, aBase / 2,
                aL / 2, aBase / 2,
                aL / 2, -aBase / 2
            ],
            position: [0, -aPrismHeight / 2, aBase / 4],
            rotationAngle: Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BASE
        }));

        aRotationAxis = [0, 1, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                aBase / 2, -aPrismHeight / 2,
                0, aPrismHeight / 2,
                -aBase / 2, -aPrismHeight / 2
            ],
            position: [-aL / 2, 0, aBase / 4],
            rotationAngle: Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aBase / 2, -aPrismHeight / 2,
                0, aPrismHeight / 2,
                aBase / 2, -aPrismHeight / 2
            ],
            position: [aL / 2, 0, aBase / 4],
            rotationAngle: Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getRhombicPrism(pRhombicPrism: iRhombicPrism) {
        let aSurfaces = new Array<iSurface>();
        let aA = pRhombicPrism.a;
        let aB = pRhombicPrism.b;
        let aC = pRhombicPrism.c;
        let aD = pRhombicPrism.d;

        let aAlpha = Math.acos(aC / aB);
        let aCapB = aB * Math.sin(aAlpha);

        let aRotationAxis: tVector3Array = [0, 1, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aD / 2, -aA / 2,
                -aD / 2, aA / 2,
                aD / 2, aA / 2,
                aD / 2, -aA / 2
            ],
            position: [-aCapB / 2, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aD / 2, -aA / 2,
                -aD / 2, aA / 2,
                aD / 2, aA / 2,
                aD / 2, -aA / 2
            ],
            position: [-3 * aCapB / 2, 0, aC],
            rotationAngle: Math.PI,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aB / 2, -aA / 2,
                -aB / 2, aA / 2,
                aB / 2, aA / 2,
                aB / 2, -aA / 2
            ],
            position: [-aD / 2 - aCapB, 0, aC / 2],
            rotationAngle: -(Math.PI / 2 - aAlpha),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aB / 2, -aA / 2,
                -aB / 2, aA / 2,
                aB / 2, aA / 2,
                aB / 2, -aA / 2
            ],
            position: [aD / 2 - aCapB, 0, aC / 2],
            rotationAngle: Math.PI - (Math.PI / 2 - aAlpha),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT
        }));

        aRotationAxis = [1, 0, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                aCapB / 2 + aD, -aC / 2,
                aCapB / 2, -aC / 2,
                -aCapB / 2, aC / 2,
                aD - aCapB / 2, aC / 2,
            ],
            position: [-aD / 2 - aCapB, aA / 2, aC / 2],
            rotationAngle: -Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aCapB / 2 - aD / 2, -aC / 2,
                aCapB / 2 - aD / 2, aC / 2,
                aCapB / 2 + aD / 2, aC / 2,
                aD / 2 - aCapB / 2, -aC / 2
            ],
            position: [-aCapB, -aA / 2, aC / 2],
            rotationAngle: Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getPellinBrocaPrism(pPellinBrocaPrism: iPellinBrocaPrism) {
        let aSurfaces = new Array<iSurface>();

        let aA = pPellinBrocaPrism.a;
        let aB = pPellinBrocaPrism.b;
        let aAlpha = pPellinBrocaPrism.alpha;
        let aBetha = pPellinBrocaPrism.beta;
        let aH = pPellinBrocaPrism.h;

        let aTanAlpha = Math.tan(aAlpha);
        let aTanBetha = Math.tan(aBetha);

        let aPB = (aB - aA * aTanAlpha) / (1 - aTanAlpha * aTanBetha);
        let aBackLength = (aB - aPB) / Math.sin(aAlpha);
        let aLeftLength = aPB / Math.cos(aBetha);
        let aPA = aBackLength * Math.cos(aAlpha);

        let aRotationAxis: tVector3Array = [0, 1, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aB / 2, -aH / 2,
                -aB / 2, aH / 2,
                aB / 2, aH / 2,
                aB / 2, -aH / 2
            ],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aH / 2,
                -aA / 2, aH / 2,
                aA / 2, aH / 2,
                aA / 2, -aH / 2
            ],
            position: [(-aB / 2), 0, (aA / 2)],
            rotationAngle: -Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));


        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aLeftLength / 2, -aH / 2,
                -aLeftLength / 2, aH / 2,
                aLeftLength / 2, aH / 2,
                aLeftLength / 2, -aH / 2
            ],
            position: [((aB - aPB) / 2), 0, (aLeftLength * Math.sin(aBetha) / 2)],
            rotationAngle: (Math.PI - aBetha),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aBackLength / 2, -aH / 2,
                -aBackLength / 2, aH / 2,
                aBackLength / 2, aH / 2,
                aBackLength / 2, -aH / 2
            ],
            position: [-aPB / 2, 0, (aA - aPA / 2)],
            rotationAngle: (Math.PI / 2 + aAlpha),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        aRotationAxis = [1, 0, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, 0,
                0, aA,
                (aB - aPB), (aA - aPA),
                aB, 0
            ],
            position: [(-aB / 2), (aH / 2), 0],
            rotationAngle: (Math.PI + Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aB / 2, aA / 2,
                aB / 2, aA / 2,
                (aB / 2 - aPB), -(aA / 2 - aPA),
                -aB / 2, -aA / 2
            ],
            position: [0, (-aH / 2), aA / 2],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getDShaped(pDShaped: iDShaped) {
        let aSurfaces = new Array<iSurface>();
        let aRadius = pDShaped.diameter / 2;
        let aThicknessSmall = pDShaped.thickness_small;
        if (aThicknessSmall == null) {
            aThicknessSmall = 0;
        }
        let aThickness = pDShaped.thickness;


        const aBigSurface = this.getOneDShaped({
            center_height: 0,
            alpha_deg: 180,
            thickness: aThickness,
            r: aRadius,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT
        });

        aSurfaces.push(aBigSurface.surface);

        let aCM = (aThickness * Math.sqrt(3)) / 3;
        let aAlphaDeg = Math.acos((aThickness * Math.sqrt(3)) / (3 * aRadius)) * MathContext.RAD_TO_DEG;
        const aSmallSurface = this.getOneDShaped({
            center_height: -aCM,
            alpha_deg: 2 * aAlphaDeg,
            thickness: aThickness,
            r: aRadius,
            position: [0, 0, aThickness + aThicknessSmall],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK
        });
        aSurfaces.push(aSmallSurface.surface);

        let aBackVertices = aSmallSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aSmallSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aBigSurface.edgeVertices, aBackVertices,
            aBigSurface.surface, aSmallSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getCircularWedgePrism(pCircularWedgePrism: iCircularWedgePrism) {
        let aSurfaces = new Array<iSurface>();

        let aD = pCircularWedgePrism.diameter;
        let aAlpha = pCircularWedgePrism.wedge_angle;
        let aT = pCircularWedgePrism.thickness_center;

        const aFrontSurface = this.getOneCircularThinSurface({
            r: aD / 2,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT
        });

        aSurfaces.push(aFrontSurface.surface);
        const aBackSurfaceD = aD / Math.cos(aAlpha);

        // let aDeltaT = (aBackSurfaceD * Math.sin(aAlpha)) / 2;
        /**
         * we dont need any deltaT because rotated surface already adds 
         * some additional thickness to the element 
         */

        const aBackSurface = this.getOneCircularThinSurface({
            r: aBackSurfaceD / 2,
            position: [0, 0, aT /*+ aDeltaT*/],
            rotationAngle: Math.PI + aAlpha,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK
        });
        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static _addFrame(
        pEdgeVertices1: Array<Vector3>,
        pEdgeVertices2: Array<Vector3>,
        pFrontSurface: iSurface,
        pBackSurface: iSurface,
        pSurfaces: Array<iSurface>,
        pName?: string) {

        let aFrame = this.getGeneralFrame(pEdgeVertices1, pEdgeVertices2, pName);
        aFrame.frameData.back_surface = pSurfaces.indexOf(pBackSurface);
        aFrame.frameData.front_surface = pSurfaces.indexOf(pFrontSurface);
        pSurfaces.push(aFrame);
        return aFrame;
    }
    //__________________________________________________________________________________________
    public static getGeneralFrame(pEdges1: Vector3[], pEdges2: Vector3[],
        pName?: string) {

        let aVerticesFrame = OpticsShapeUtils.createCylinderFromArrays(pEdges1,
            pEdges2);

        let aFrameName = (null != pName) ? pName : SurfaceContext.FRAME;

        let aSurface: iSurface = {
            matrix: new Matrix4(),
            frameData: {
                is_frame: true,
            },
            surfaceData: {
                isInactive: true,
                name: aFrameName
            },
            simGeoData: {
                kind: eSmGeometryType.FRAME,
            },
            vertices: aVerticesFrame
        };

        return aSurface;
    }
    //__________________________________________________________________________________________
    public static getRectWedgePrism(pRectWedgePrism: iRectWedgePrism) {
        let aSurfaces = new Array<iSurface>();

        let aW = pRectWedgePrism.width;
        let aH = pRectWedgePrism.height;
        let aAlpha = pRectWedgePrism.wedge_angle;
        let aT = pRectWedgePrism.t;

        let aRotationAxis: tVector3Array = [1, 0, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aH / 2,
                -aW / 2, aH / 2,
                aW / 2, aH / 2,
                aW / 2, -aH / 2
            ],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));

        let aBackHeight = aH / Math.cos(aAlpha);
        let aCap = Math.sqrt(Math.pow(aBackHeight, 2) - Math.pow(aH, 2));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aBackHeight / 2,
                -aW / 2, aBackHeight / 2,
                aW / 2, aBackHeight / 2,
                aW / 2, -aBackHeight / 2
            ],
            position: [0, 0, aT + aCap / 2],
            rotationAngle: Math.PI + aAlpha,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aT / 2,
                -aW / 2, aT / 2,
                aW / 2, aT / 2,
                aW / 2, -aT / 2
            ],
            position: [0, aH / 2, aT / 2],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        let aBottomLength = (aT + aCap);
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aW / 2, -aBottomLength / 2,
                -aW / 2, aBottomLength / 2,
                aW / 2, aBottomLength / 2,
                aW / 2, -aBottomLength / 2
            ],
            position: [0, -aH / 2, (aBottomLength / 2)],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        aRotationAxis = [0, 1, 0];
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, 0,
                0, aH,
                aT, aH,
                aBottomLength, 0
            ],
            position: [aW / 2, (-aH / 2), 0],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, 0,
                -aBottomLength, 0,
                -aT, aH,
                0, aH
            ],
            position: [-aW / 2, (-aH / 2), 0],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getPentaPrism(pPentaPrism: iPentaPrism) {
        let aSurfaces = new Array<iSurface>();

        let aA = pPentaPrism.a; // Front Width
        let aB = pPentaPrism.b; // Height
        let aC = pPentaPrism.c; // Front-right face length
        let aD = pPentaPrism.d; // Front-left  face length
        let aE = pPentaPrism.e; // Back-right  face length
        let aAlpha = pPentaPrism.alpha;
        let aRotationAxis: tVector3Array = [0, 1, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aB / 2,
                -aA / 2, aB / 2,
                aA / 2, aB / 2,
                aA / 2, -aB / 2
            ],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aC / 2, aB / 2,
                aC / 2, aB / 2,
                aC / 2, -aB / 2,
                -aC / 2, -aB / 2
            ],
            position: [-aA / 2, 0, aC / 2],
            rotationAngle: -Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aD / 2, aB / 2,
                aD / 2, aB / 2,
                aD / 2, -aB / 2,
                -aD / 2, -aB / 2
            ],
            position: [aA / 2 + (aD * Math.cos(aAlpha) / 2), 0, (aD * Math.sin(aAlpha) / 2)],
            rotationAngle: aAlpha,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT_LEFT,
            isMirror: true
        }));


        let aRearRightX = -aA / 2 + (aE * Math.sin(aAlpha) / 2);
        let aRearRightZ = aC + (aE * Math.cos(aAlpha) / 2);
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aE / 2, aB / 2,
                aE / 2, aB / 2,
                aE / 2, -aB / 2,
                -aE / 2, - aB / 2
            ],
            position: [aRearRightX, 0, aRearRightZ],
            rotationAngle: -(Math.PI / 2 + aAlpha),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.REAR_RIGHT,
            isMirror: true
        }));



        let aRearLeftStart = new Vector3((aD * Math.cos(aAlpha) + aA / 2), 0,
            aD * Math.sin(aAlpha));
        let aRearLeftEnd = new Vector3((aE * Math.sin(aAlpha) - aA / 2), 0,
            (aC + aE * Math.cos(aAlpha)));


        let aTopBackLength = aRearLeftStart.distanceTo(aRearLeftEnd);
        let aTopBackDirection = new Vector3().subVectors(aRearLeftEnd, aRearLeftStart);
        let aTopBackAngle = new Vector3(0, 0, 1).angleTo(aTopBackDirection);

        let aRearLeftX = (aRearLeftStart.x + ((aRearLeftEnd.x - aRearLeftStart.x) / 2));
        let aRearLeftZ = (aRearLeftStart.z + ((aRearLeftEnd.z - aRearLeftStart.z) / 2));
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aTopBackLength / 2, aB / 2,
                aTopBackLength / 2, aB / 2,
                aTopBackLength / 2, -aB / 2,
                -aTopBackLength / 2, - aB / 2
            ],
            position: [aRearLeftX, 0, aRearLeftZ],
            rotationAngle: (Math.PI / 2 + aTopBackAngle),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.REAR_LEFT,
            isInactive: true
        }));


        aRotationAxis = [1, 0, 0];


        let aBottomVertices = new Array<number>();
        aBottomVertices.push(...[
            0, 0,
            0, aC,
            aA, aC,
            aA + aD * Math.cos(aAlpha), aC - aD * Math.sin(aAlpha),
            aE * Math.sin(aAlpha), -aE * Math.cos(aAlpha)
        ]);

        let aCenter = new Vector2();
        for (let i = 0; i < aBottomVertices.length - 1; i += 2) {
            aCenter.x += aBottomVertices[i];
            aCenter.y += aBottomVertices[i + 1];
        }
        aCenter.multiplyScalar(2 / aBottomVertices.length);

        for (let i = 0; i < aBottomVertices.length - 1; i += 2) {
            aBottomVertices[i] -= aCenter.x;
            aBottomVertices[i + 1] -= aCenter.y;
        }

        aSurfaces.push(MatrixUtils._getPolygon({
            points: aBottomVertices,
            position: [-aA / 2 + aCenter.x, -aB / 2, aC - aCenter.y],
            rotationAngle: Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, 0,
                0, aC,
                (aA / 2 + aRearLeftEnd.x), aRearLeftEnd.z,
                (aA / 2 + aRearLeftStart.x), aRearLeftStart.z,
                aA, 0
            ],
            position: [-aA / 2, aB / 2, 0],
            rotationAngle: -Math.PI / 2,
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getEquilateralPrism(pEquailateralPrism: iEquilateralPrism) {
        let aSurfaces = new Array<iSurface>();

        let aA = pEquailateralPrism.a;
        let aHeight = pEquailateralPrism.t;
        let aRotationAxis: tVector3Array = [0, 1, 0];
        let aTriangleHeight = aA * Math.sin(Math.PI / 3);
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aHeight / 2,
                -aA / 2, aHeight / 2,
                aA / 2, aHeight / 2,
                aA / 2, -aHeight / 2
            ],
            position: [0, 0, 0],
            rotationAngle: -(Math.PI / 6),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT,
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aHeight / 2,
                -aA / 2, aHeight / 2,
                aA / 2, aHeight / 2,
                aA / 2, -aHeight / 2
            ],
            position: [0, 0, (aA / 2)],
            rotationAngle: (Math.PI + Math.PI / 6),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK,
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aA / 2, -aHeight / 2,
                -aA / 2, aHeight / 2,
                aA / 2, aHeight / 2,
                aA / 2, -aHeight / 2
            ],
            position: [(aTriangleHeight / 2), 0, (aA / 4)],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT,
        }));

        aRotationAxis = [1, 0, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                0, 0,
                -aTriangleHeight, aA / 2,
                0, aA
            ],
            position: [(aTriangleHeight / 2), (aHeight / 2), (-aA / 4)],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));


        let aBottomVertices = new Array<number>();
        aBottomVertices.push(...[
            0, 0,
            -aTriangleHeight, aA / 2,
            0, aA
        ]);

        let aCenter = new Vector2();
        for (let i = 0; i < aBottomVertices.length - 1; i += 2) {
            aCenter.x += aBottomVertices[i];
            aCenter.y += aBottomVertices[i + 1];
        }
        aCenter.multiplyScalar(2 / aBottomVertices.length);

        for (let i = 0; i < aBottomVertices.length - 1; i += 2) {
            aBottomVertices[i] -= aCenter.x;
            aBottomVertices[i + 1] -= aCenter.y;
        }

        aSurfaces.push(MatrixUtils._getPolygon({
            points: aBottomVertices,
            position: [(aTriangleHeight / 2) + aCenter.x, (-aHeight / 2), (3 * aA / 4) - aCenter.y],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getBSCube(pBsCubeData: iBSCube) {
        let aSurfaces = new Array<iSurface>();
        let aHalf = pBsCubeData.cube_side / 2;
        let aDistBetweenMiddles = MathContext.EPSILON_3;
        // front red

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aHalf, -aHalf,
                -aHalf, aHalf,
                aHalf, aHalf,
                aHalf, -aHalf
            ],
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: [0, 1, 0],
            surfaceName: SurfaceContext.FRONT
        }));

        // back green
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aHalf, -aHalf,
                -aHalf, aHalf,
                aHalf, aHalf,
                aHalf, -aHalf
            ],
            position: [0, 0, pBsCubeData.cube_side],
            rotationAngle: Math.PI,
            rotationAxis: [0, 1, 0],
            surfaceName: SurfaceContext.BACK
        }));

        //left blue
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aHalf, -aHalf,
                -aHalf, aHalf,
                aHalf, aHalf,
                aHalf, -aHalf
            ],
            position: [aHalf, 0, aHalf],
            rotationAngle: Math.PI / 2,
            rotationAxis: [0, 1, 0],
            surfaceName: SurfaceContext.LEFT
        }));

        //right pink
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aHalf, -aHalf,
                -aHalf, aHalf,
                aHalf, aHalf,
                aHalf, -aHalf
            ],
            position: [-aHalf, 0, aHalf],
            rotationAngle: -Math.PI / 2,
            rotationAxis: [0, 1, 0],
            surfaceName: SurfaceContext.RIGHT
        }));

        // middle left yellow
        let aBackLength = Math.hypot(pBsCubeData.cube_side, pBsCubeData.cube_side);
        let aSideMiddle = aBackLength / 2;
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aSideMiddle, -aHalf,
                -aSideMiddle, aHalf,
                aSideMiddle, aHalf,
                aSideMiddle, -aHalf
            ],
            position: [0, 0, aHalf - aDistBetweenMiddles],
            rotationAngle: -Math.PI / 4,
            rotationAxis: [0, 1, 0],
            surfaceName: SurfaceContext.HYPOTENUSE_1
        }));

        // middle right cyan

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aSideMiddle, -aHalf,
                -aSideMiddle, aHalf,
                aSideMiddle, aHalf,
                aSideMiddle, -aHalf
            ],
            position: [0, 0, aHalf + aDistBetweenMiddles],
            rotationAngle: (-Math.PI / 4) + Math.PI,
            rotationAxis: [0, 1, 0],
            surfaceName: SurfaceContext.HYPOTENUSE_2
        }));

        //top
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aHalf, -aHalf,
                -aHalf, aHalf,
                aHalf, aHalf,
                aHalf, -aHalf
            ],
            position: [0, aHalf, aHalf],
            rotationAngle: -Math.PI / 2,
            rotationAxis: [1, 0, 0],
            surfaceName: SurfaceContext.TOP,
            isInactive: true
        }));

        //bottom
        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                -aHalf, -aHalf,
                -aHalf, aHalf,
                aHalf, aHalf,
                aHalf, -aHalf
            ],
            position: [0, -aHalf, aHalf],
            rotationAngle: Math.PI / 2,
            rotationAxis: [1, 0, 0],
            surfaceName: SurfaceContext.BOTTOM,
            isInactive: true
        }));

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getDovePrism(pParallelRects: iDovePrism) {
        let aSurfaces = new Array<iSurface>();

        let aRotationAxis: tVector3Array = [1, 0, 0];

        let aB = pParallelRects.b;

        let aC = pParallelRects.c;
        let aD = pParallelRects.d;

        let aCap = (aD * Math.SQRT1_2);
        let aBackPosition = (aB - aCap);

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                aC / 2, -aD / 2,
                -aC / 2, -aD / 2,
                -aC / 2, aD / 2,
                aC / 2, aD / 2
            ],
            position: [0, 0, 0],
            rotationAngle: -(Math.PI / 4),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.FRONT
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                aC / 2, -aD / 2,
                -aC / 2, -aD / 2,
                -aC / 2, aD / 2,
                aC / 2, aD / 2
            ],
            position: [0, 0, aBackPosition],
            rotationAngle: -(Math.PI / 2 + Math.PI / 4),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BACK
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                (-aC / 2), (-aB / 2),
                (-aC / 2), (aB / 2),
                (aC / 2), (aB / 2),
                (aC / 2), (-aB / 2)
            ],
            position: [0, (-aCap / 2), aBackPosition / 2],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.BOTTOM
        }));


        let aTopLenght = (aB - (2 * aCap));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                (aC / 2), (-aTopLenght / 2),
                (aC / 2), (aTopLenght / 2),
                (-aC / 2), (aTopLenght / 2),
                (-aC / 2), (-aTopLenght / 2)
            ],
            position: [0, (aCap / 2), aBackPosition / 2],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.TOP
        }));



        //change rotation axis:

        aRotationAxis = [0, 1, 0];

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                (-aB / 2), (-aCap / 2),
                (-aTopLenght / 2), (aCap / 2),
                (aTopLenght / 2), (aCap / 2),
                (aB / 2), (-aCap / 2)
            ],
            position: [(aC / 2), 0, aBackPosition / 2],
            rotationAngle: (Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.LEFT,
            isInactive: true
        }));

        aSurfaces.push(MatrixUtils._getPolygon({
            points: [
                (-aB / 2), (-aCap / 2),
                (-aTopLenght / 2), (aCap / 2),
                (aTopLenght / 2), (aCap / 2),
                (aB / 2), (-aCap / 2)
            ],
            position: [(-aC / 2), 0, aBackPosition / 2],
            rotationAngle: -(Math.PI / 2),
            rotationAxis: aRotationAxis,
            surfaceName: SurfaceContext.RIGHT,
            isInactive: true
        }));
        /*
        
        let aLeftDistX = aRightDistX;
        
        let aLeftFaceThickness = ((aD / Math.SQRT2) - aA);
        if (aLeftFaceThickness > MathContext.EPSILON_7) {
            aLeftDistX += aLeftFaceThickness;
     
            let aLegPosition = (aRightDistX + (aLeftFaceThickness / 2));
     
            aSurfaces.push(MatrixUtils._getPolygon({
                points: [
                    (-aLeftFaceThickness / 2), (-aC / 2),
                    (aLeftFaceThickness / 2), (-aC / 2),
                    (aLeftFaceThickness / 2), (aC / 2),
                    (-aLeftFaceThickness / 2), (aC / 2)
                ],
                position: [aLegPosition, 0, (-aCap / 2)],
                rotationAngle: Math.PI,
                rotationAxis: aRotationAxis,
                surfaceName: SurfaceContext.FRONT_LEFT_LEG
            }));
     
            aSurfaces.push(MatrixUtils._getPolygon({
                points: [
                    (-aLeftFaceThickness / 2), (-aC / 2),
                    (aLeftFaceThickness / 2), (-aC / 2),
                    (aLeftFaceThickness / 2), (aC / 2),
                    (-aLeftFaceThickness / 2), (aC / 2)
                ],
                position: [aLegPosition, 0, (aCap / 2) + aBackPosition],
                rotationAngle: 0,
                rotationAxis: aRotationAxis,
                surfaceName: SurfaceContext.BACK_LEFT_LEG
            }));
     
            aSurfaces.push(MatrixUtils._getPolygon({
                points: [
                    (-aLeftFaceThickness / 2), (-aB / 2),
                    (aLeftFaceThickness / 2), (-aB / 2),
                    (aLeftFaceThickness / 2), (aB / 2),
                    (-aLeftFaceThickness / 2), (aB / 2)
                ],
                position: [aLegPosition, (aC / 2), aBackPosition / 2],
                rotationAngle: Math.PI / 2,
                rotationAxis: [1, 0, 0],
                surfaceName: SurfaceContext.TOP_LEFT_LEG
            }));
     
            aSurfaces.push(MatrixUtils._getPolygon({
                points: [
                    (-aLeftFaceThickness / 2), (-aB / 2),
                    (aLeftFaceThickness / 2), (-aB / 2),
                    (aLeftFaceThickness / 2), (aB / 2),
                    (-aLeftFaceThickness / 2), (aB / 2)
                ],
                position: [aLegPosition, (-aC / 2), aBackPosition / 2],
                rotationAngle: -Math.PI / 2,
                rotationAxis: [1, 0, 0],
                surfaceName: SurfaceContext.BOTTOM_LEFT_LEG
            }));
        }
        
        */
        return aSurfaces;
    }
    //__________________________________________________________________________________________
    /**
     * @param pRCuravature radius of sphere,
     * @param pDiameter diameter of spherical segment
     * @returns Height of spherical segment
     */
    //__________________________________________________________________________________________
    private static _sphereHeight(pRadiusCurvatre: number, pDiameter: number) {

        let rsgm = 0.5 * pDiameter;
        rsgm = pRadiusCurvatre * pRadiusCurvatre - rsgm * rsgm
        if (rsgm < 0) {
            return 0;
        }

        return pRadiusCurvatre - Math.pow(rsgm, 0.5);
    }
    //__________________________________________________________________________________________
    public static getOneCircularAxicon(pAxiconCreationParams: iAxiconCreationParams) {
        const aDiameter = pAxiconCreationParams.diameter;

        let aMatrixRotation = this.getAxisRotationMatrix(pAxiconCreationParams.rotationAxis,
            pAxiconCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pAxiconCreationParams.position);

        let aMul = pAxiconCreationParams.convexity == "Convex" ? 1 : -1;

        let aZFunc = (pX: number, pY: number) =>
            OpticsShapeUtils.calculateZAxicon(pX, pY, aDiameter,
                pAxiconCreationParams.height, aMul);

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pAxiconCreationParams.diameter / 2,
        });

        let aSurface: iSurface = {
            shape: eOpticShape.CONIC,
            matrix: new Matrix4().fromArray(aMatrixElements),
            surfaceData: {
                name: pAxiconCreationParams.surfaceName
            },
            frameData: {
                is_frame: false
            },
            simGeoData: {
                kind: eSmGeometryType.AXICON,

            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: ((aDiameter * UnitHandler.scale) / 2)
            },
            vertices: aResult.vertices
        };

        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getOneConicSurface(pConicCreationParams: iConicCreationParams) {
        let aR = pConicCreationParams.R;
        let aK = pConicCreationParams.K;
        let aDiameter = pConicCreationParams.diameter;

        let aMatrixRotation = this.getAxisRotationMatrix(pConicCreationParams.rotationAxis,
            pConicCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pConicCreationParams.position);

        let aZFunc = (pX: number, pY: number) =>
            OpticsShapeUtils.calcZCircleConic(pX, pY, aR, aK);

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pConicCreationParams.diameter / 2,
        });

        let aSurface: iSurface = {
            shape: eOpticShape.CONIC,
            matrix: new Matrix4().fromArray(aMatrixElements),
            surfaceData: {
                name: pConicCreationParams.surfaceName
            },
            frameData: {
                is_frame: false
            },
            simGeoData: {
                kind: eSmGeometryType.BICONIC,
                radius_x: (aR * UnitHandler.scale),
                radius_y: (aR * UnitHandler.scale),
                k_x: aK,
                k_y: aK
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aDiameter / 2
            },
            vertices: aResult.vertices
        };

        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getOneQConSurface(pQConCreationParams: iQConCreationParams) {

        const aR = pQConCreationParams.R;
        const aDiameter = pQConCreationParams.diameter;

        let aMatrixRotation = this.getAxisRotationMatrix(pQConCreationParams.rotationAxis,
            pQConCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pQConCreationParams.position);

        let aZFunc = (pX: number, pY: number) =>
            OpticsShapeUtils.calculateZQCon({
                x: pX,
                y: pY,
                K: pQConCreationParams.K,
                R: pQConCreationParams.R,
                radius: aDiameter / 2,
                coeffs: pQConCreationParams.coeffs
            });

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pQConCreationParams.diameter / 2,
        });

        let aSurface: iSurface = {
            shape: eOpticShape.QCON_ASPHERE,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: {
                is_frame: false,
            },
            surfaceData: {
                name: pQConCreationParams.surfaceName
            },
            simGeoData: {
                kind: eSmGeometryType.QCON,
                radius_x: (aR * UnitHandler.scale),
                radius_y: (aR * UnitHandler.scale),
                k_x: pQConCreationParams.K,
                k_y: pQConCreationParams.K,
                //  coeffs: pQConCreationParams.coeffs
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aDiameter / 2
            },
            vertices: aResult.vertices
        };

        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getThinRectangularHighPoly(pThinData: iRectangularData) {
        let aMatrixRotation = this.getAxisRotationMatrix(pThinData.rotationAxis,
            pThinData.rotationAngle);

        let aMatrixElements = this.getMatrix4(aMatrixRotation, pThinData.position);

        let aZFunc = (_pX: number, _pY: number) => { return 0; };


        let aResult = OpticsShapeUtils.getBufferRectangular(aZFunc, {
            width_segments: pThinData.segments,
            height_segments: pThinData.segments,
            width: pThinData.length,
            height: pThinData.height,
        });

        let aPoints = MatrixUtils.getPolygonVertices([
            -pThinData.length / 2, -pThinData.height / 2, 0,
            pThinData.length / 2, -pThinData.height / 2, 0,
            pThinData.length / 2, pThinData.height / 2, 0,
            -pThinData.length / 2, pThinData.height / 2, 0,
        ]);

        let aSurface: iSurface = {
            shape: eOpticShape.THIN_LENS,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false },
            surfaceData: {
                name: pThinData.surfaceName
            },
            simGeoData: {
                kind: eSmGeometryType.PLANAR,

            },
            shapeData: {
                kind: eSmBaseShapeKind.POLYGON,
                height: pThinData.height,
                width: pThinData.length,
                points: aPoints
            },
            vertices: aResult.vertices
        };


        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getOneAsphericSurface(pAsphericCreationParams: iAsphericCreationParams) {


        let aR = pAsphericCreationParams.R;
        let aDiameter = pAsphericCreationParams.diameter;

        let aMatrixRotation = this.getAxisRotationMatrix(pAsphericCreationParams.rotationAxis,
            pAsphericCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pAsphericCreationParams.position);

        let aZFunc = (pX: number, pY: number) =>
            OpticsShapeUtils.calculateZAspheric({
                x: pX,
                y: pY,
                K: pAsphericCreationParams.K,
                R: pAsphericCreationParams.R,
                coeffs: pAsphericCreationParams.coeffs
            });

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pAsphericCreationParams.diameter / 2,
        });

        let aTerms = new Array<Array<number>>();
        for (let index in pAsphericCreationParams.coeffs) {
            // const aCoefficient = pAsphericCreationParams.coeffs[index];


            let aCoeff = pAsphericCreationParams.coeffs[index];
            if ((null != aCoeff) && (0 != aCoeff)) {
                let aIndex = parseInt(index);
                aTerms.push([aIndex, aIndex, aCoeff]);
            }
        }

        let aSurface: iSurface = {
            shape: eOpticShape.ASPHERIC,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pAsphericCreationParams.surfaceName
            },
            simGeoData: {

                kind: eSmGeometryType.BICONIC,
                radius_x: aR * UnitHandler.scale,
                radius_y: aR * UnitHandler.scale,
                k_x: pAsphericCreationParams.K,
                k_y: pAsphericCreationParams.K,
                polynomial: {
                    term_kind: eSmTermKind.RADIAL_DISTANCE,
                    terms: aTerms
                },
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aDiameter * UnitHandler.scale / 2
            },
            vertices: aResult.vertices
        };


        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getOneGenericRectangularConicSurface(pParams: iGenericRectConicCreationParams) {
        let cx = pParams.cx;
        let cy = pParams.cy;
        let kx = pParams.kx;
        let ky = pParams.ky;

        let x0 = (null != pParams.x0) ? pParams.x0 : 0;
        let y0 = (null != pParams.y0) ? pParams.y0 : 0;
        let z0 = (null != pParams.z0) ? pParams.z0 : 0;

        let aDeformations = pParams.deformations;

        let aMatrixRotation = this.getAxisRotationMatrix(pParams.rotationAxis,
            pParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pParams.position);


        let aZFunc = (pX: number, pY: number) => (z0 +
            OpticsShapeUtils.calcCircleConicExtended((pX - x0), (pY - y0), cx, cy,
                kx, ky, aDeformations));

        let aResult = OpticsShapeUtils.getBufferRectangular(aZFunc, {
            height: pParams.height,
            height_segments: 15,
            width: pParams.width,
            width_segments: 15
        });

        let aRadiusX = (0 == cx) ? 0 : (1 / cx);
        let aRadiusY = (0 == cy) ? 0 : (1 / cy);

        let aSurface: iSurface = {
            shape: eOpticShape.SPHERICAL,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pParams.surfaceName
            },
            simGeoData: {
                kind: eSmGeometryType.BICONIC,
                radius_x: aRadiusX,
                radius_y: aRadiusY,
                k_x: kx,
                k_y: ky,
                center_offset: [x0, y0, z0]
            },
            shapeData: {
                kind: eSmBaseShapeKind.RECTANGLE,
                width: pParams.width,
                height: pParams.height
            },
            vertices: aResult.vertices
        };


        if ((0 === pParams.cx) && (0 === pParams.cy)) {
            aSurface.simGeoData = {
                kind: eSmGeometryType.PLANAR
            };
        }

        this._addDeformationsToSurface(aSurface, aDeformations);

        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getOneGenericConicSurface(pParams: iGenericConicCreationParams) {
        let cx = pParams.cx;
        let cy = pParams.cy;
        let kx = pParams.kx;
        let ky = pParams.ky;

        let x0 = (null != pParams.x0) ? pParams.x0 : 0;
        let y0 = (null != pParams.y0) ? pParams.y0 : 0;
        let z0 = (null != pParams.z0) ? pParams.z0 : 0;

        let r = (pParams.diameter / 2);

        let aDeformations = pParams.deformations;

        let aMatrixRotation = this.getAxisRotationMatrix(pParams.rotationAxis,
            pParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pParams.position);


        let aZFunc = (pX: number, pY: number) => (z0 +
            OpticsShapeUtils.calcCircleConicExtended((pX - x0), (pY - y0), cx, cy,
                kx, ky, aDeformations));

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 15,
            radius: r,
            N: (pX: number, pY: number) =>
                OpticsShapeUtils.getConicNormal(pX, pY, cx, cy, kx, ky)
        });

        let aRadiusX = (0 == cx) ? 0 : (1 / cx);
        let aRadiusY = (0 == cy) ? 0 : (1 / cy);

        let aSurface: iSurface = {
            shape: eOpticShape.SPHERICAL,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pParams.surfaceName
            },
            simGeoData: {
                kind: eSmGeometryType.BICONIC,
                radius_x: aRadiusX,
                radius_y: aRadiusY,
                k_x: kx,
                k_y: ky,
                center_offset: [x0, y0, z0]
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: r
            },
            vertices: aResult.vertices,
            normals: aResult.normals
        };

        this._addDeformationsToSurface(aSurface, aDeformations);

        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    private static _addDeformationsToSurface(pSurface: iSurface,
        pSurfaceDeformations: Array<iSurfaceDeformation>) {

        if (null == pSurfaceDeformations) {
            return;
        }

        let aSMDeformation = this.deformationToSM(pSurfaceDeformations[0]);
        if (null == aSMDeformation) {
            return;
        }

        switch (pSurfaceDeformations[0].type) {
            case eSurfaceDeformation.ZERNIKE_FRINGE_SAG:
            case eSurfaceDeformation.ZERNIKE_STANDARD_SAG:
            case eSurfaceDeformation.ASPHERE:
            case eSurfaceDeformation.EVEN_ASPHERE:
            case eSurfaceDeformation.ODD_ASPHERE:
                pSurface.simGeoData.polynomial = aSMDeformation;
                break;
            case eSurfaceDeformation.ZERNIKE_FRINGE_PHAZE:
                pSurface.phase_profile = {
                    P: aSMDeformation,
                    S: aSMDeformation
                };
                break;
            case eSurfaceDeformation.JONES_MATRIX:
                /**
                 * @TODO:
                 * check this 
                 */
                pSurface.jonesMatrix = aSMDeformation as any;
                break;
        }
    }
    //__________________________________________________________________________________________
    public static getOneCircularParabolic(pParabolicCreationParams: iParabolicCreationParams) {
        let aR = pParabolicCreationParams.R;
        let aDiameter = pParabolicCreationParams.diameter;
        let c = (0 != aR) ? (1 / aR) : 0;

        let aMatrixRotation = this.getAxisRotationMatrix(pParabolicCreationParams.rotationAxis,
            pParabolicCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pParabolicCreationParams.position);

        let aZFunc = (pX: number, pY: number) =>
            OpticsShapeUtils.calcCircleConicExtended(pX, pY, c, c, -1, -1);

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pParabolicCreationParams.diameter / 2,
        });

        let aSurface: iSurface = {
            shape: eOpticShape.SPHERICAL,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pParabolicCreationParams.surfaceName
            },
            simGeoData: {

                kind: eSmGeometryType.BICONIC,
                radius_x: aR * UnitHandler.scale,
                radius_y: aR * UnitHandler.scale,
                k_x: -1,
                k_y: -1,

            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aDiameter / 2
            },
            vertices: aResult.vertices
        };

        return { surface: aSurface, edgeVertices: aResult.edgeVertices };

    }
    //__________________________________________________________________________________________
    public static getOneSphericalSurface(pSphereCreationParams: iSphericalCreationParams) {

        let aR = pSphereCreationParams.R;
        let aDiameter = pSphereCreationParams.diameter;

        let aMatrixRotation = this.getAxisRotationMatrix(pSphereCreationParams.rotationAxis,
            pSphereCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pSphereCreationParams.position);

        let aDeformations = pSphereCreationParams.deformations;

        let aZFunc = (pX: number, pY: number) =>
            OpticsShapeUtils.calcZCircleSpherical(pX, pY, aR) +
            OpticsShapeUtils.addDeformations(pX, pY, aDeformations);

        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pSphereCreationParams.diameter / 2,
        });

        let aSurface: iSurface = {
            shape: eOpticShape.SPHERICAL,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pSphereCreationParams.surfaceName
            },

            simGeoData: {
                kind: eSmGeometryType.BICONIC,
                radius_x: aR * UnitHandler.scale,
                radius_y: aR * UnitHandler.scale,
                k_x: 0,
                k_y: 0,
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aDiameter * UnitHandler.scale / 2
            },
            vertices: aResult.vertices
        };

        if (null != aDeformations) {
            let aSMDeformation = this.deformationToSM(aDeformations[0]);
            if (null != aSMDeformation) {
                aSurface.simGeoData.polynomial = aSMDeformation;
            }
        }


        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static deformationToSM(pDeformation: iSurfaceDeformation) {
        if (null == pDeformation) {
            return null;
        }

        let aDeformationSM: iSMPolynomial;
        switch (pDeformation.type) {
            case eSurfaceDeformation.ZERNIKE_FRINGE_SAG:
            case eSurfaceDeformation.ZERNIKE_FRINGE_PHAZE:
                let aZernikeParams = pDeformation.params as iZernikeDeformation;
                let aZernikeTerms = new Array<tVector3Array>();
                let aCoeffs = aZernikeParams.coeffs;
                for (let j in aCoeffs) {
                    let aNM = OpticsShapeUtils.getZernikeNMByIndex(parseInt(j));
                    aZernikeTerms.push([aNM.n, aNM.m, aCoeffs[j]]);
                }

                aDeformationSM = {
                    term_kind: eSmTermKind.ZERNIKE_COEFFS,
                    normalization_radius: aZernikeParams.normalization_radius,
                    terms: aZernikeTerms
                };

                break;
            case eSurfaceDeformation.ASPHERE:
            case eSurfaceDeformation.EVEN_ASPHERE:
            case eSurfaceDeformation.ODD_ASPHERE:
                let aPolynomialCoeffs = pDeformation.params as iPolynomialDeformation;
                aDeformationSM = {
                    term_kind: eSmTermKind.RADIAL_DISTANCE,
                    terms: aPolynomialCoeffs.terms
                };

                break;
            case eSurfaceDeformation.JONES_MATRIX:
                /**
                 * @needs to be checked
                 */
                let aJonesMatrixParams = pDeformation.params as iJonesMatrix;
                aDeformationSM = aJonesMatrixParams as any;
                break;
        }

        return aDeformationSM;
    }
    //__________________________________________________________________________________________
    public static getPolygonVertices(pPoints: Array<number>) {
        let aVertices = new Array<number>();
        for (let i = 2; i < pPoints.length; i += 2) {
            aVertices.push(pPoints[0], pPoints[1], 0);
            aVertices.push(pPoints[i - 2], pPoints[i - 1], 0);
            aVertices.push(pPoints[i], pPoints[i + 1], 0);
        }

        return aVertices;
    }
    //__________________________________________________________________________________________
    private static _getRect(pPolygonCreationParameters: iRectPolygonCreationParameters) {
        if (pPolygonCreationParameters.points.length < 3) {
            return null;
        }

        let aRotationAxis = pPolygonCreationParameters.rotationAxis;
        let aRotationAngle = pPolygonCreationParameters.rotationAngle;
        let aPosition = pPolygonCreationParameters.position;

        let aRotationMatrix = this.getAxisRotationMatrix(aRotationAxis, aRotationAngle);
        let aMatrixElements = this.getMatrix4(aRotationMatrix, aPosition);

        let aVertices = MatrixUtils.getPolygonVertices(pPolygonCreationParameters.points);
        let aSurface: iSurface = {
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pPolygonCreationParameters.surfaceName,
                isInactive: pPolygonCreationParameters.isInactive,
                isMirror: pPolygonCreationParameters.isMirror
            },
            simGeoData: {
                kind: eSmGeometryType.PLANAR
            },
            shapeData: {
                kind: eSmBaseShapeKind.RECTANGLE,
                width: pPolygonCreationParameters.width * UnitHandler.scale,
                height: pPolygonCreationParameters.height * UnitHandler.scale,
            },
            vertices: aVertices,
            isForSimulation: pPolygonCreationParameters.isForSumulation
        };

        return aSurface;
    }
    //__________________________________________________________________________________________
    private static _getPolygon(pPolygonCreationParameters: iPolygonCreationParameters) {
        if (pPolygonCreationParameters.points.length < 3) {
            return null;
        }

        let aRotationAxis = pPolygonCreationParameters.rotationAxis;
        let aRotationAngle = pPolygonCreationParameters.rotationAngle;
        let aPosition = pPolygonCreationParameters.position;

        let aRotationMatrix = this.getAxisRotationMatrix(aRotationAxis, aRotationAngle);
        let aMatrixElements = this.getMatrix4(aRotationMatrix, aPosition);

        let aVertices = MatrixUtils.getPolygonVertices(pPolygonCreationParameters.points);
        let aSurface: iSurface = {
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                name: pPolygonCreationParameters.surfaceName,
                isInactive: pPolygonCreationParameters.isInactive,
                isMirror: pPolygonCreationParameters.isMirror
            },
            simGeoData: {
                kind: eSmGeometryType.PLANAR
            },
            shapeData: {
                kind: eSmBaseShapeKind.POLYGON,
                points: pPolygonCreationParameters.points.slice()
            },
            vertices: aVertices
        };

        if (eUnitType.INCHES == UnitHandler.currUnit) {
            for (let i = 0; i < aSurface.shapeData.points.length; i++) {
                aSurface.shapeData.points[i] *= UnitHandler.scale;
            }
        }

        return aSurface;
    }
    // //__________________________________________________________________________________________
    // private static _draw(pSurfaces: Array<iSurface>, pOptions?: {
    //     drawNormals?: boolean;
    //     drawPoints?: boolean;
    //     wireFrame?: boolean;
    // }) {
    //     let aColors = [0xff0000, 0x00ff00, 0x0000ff, 0xff00ff, 0xffff00, 0x00ffff, 0xffffff]
    //     let aObject3D = new Object3D();

    //     let aWireFrame = ((null != pOptions) && (null != pOptions.wireFrame)) ?
    //         pOptions.wireFrame : false;
    //     for (let i = 0; i < pSurfaces.length; i++) {
    //         let aVertices = pSurfaces[i].vertices;
    //         let aMaterial = ThreeMaterialUtils.createUncoatedMat();
    //         aMaterial.color.setHex(aColors[(i % aColors.length)]);
    //         aMaterial.wireframe = aWireFrame;

    //         let aMesh = OpticsShapeUtils.createMeshNew(aMaterial, aVertices);
    //         let aMat = new Matrix4().copy(pSurfaces[i].matrix);
    //         let aMatrixRot = new Matrix4().extractRotation(aMat.clone());
    //         aMesh.rotation.setFromRotationMatrix(aMatrixRot);
    //         aMesh.position.setFromMatrixPosition(aMat);
    //         aMesh.scale.setFromMatrixScale(aMat);
    //         aMesh.name = pSurfaces[i].surfaceData.name;
    //         aObject3D.add(aMesh);
    //     }

    //     let aDrawNormals = ((null != pOptions) && (null != pOptions.drawNormals)) ?
    //         pOptions.drawNormals : false;

    //     if (true == aDrawNormals) {
    //         OpticsFactory.addNormals(aObject3D);
    //     }


    //     //if (pOptions.drawPoints) {
    //     // let aPoints = [];
    //     // for (let i = 0; i < pSurfaces.length; i++) {
    //     //     pSurfaces[i].vertices.
    //     // }
    //     //}

    //     SceneContext.MAIN_SCENE.add(aObject3D);


    //     //aObject3D.position.set(0, 76.2, 0);
    // }
    //__________________________________________________________________________________________
    /**
     * @description Rotation matrix about given 3D axis passing through the origin.
     * @param pAxis axis direction unit vector;
     * @param pAngle rotation angle in radians;
     * @returns rotation matrix
     */
    public static getAxisRotationMatrix(pAxis: Array<number>, pAngle: number) {
        let aRotMat = new Array<Array<number>>();

        let aCosAngle = Math.cos(pAngle);
        let aSinAngle = Math.sin(pAngle);
        let aVers = (1 - aCosAngle);

        aRotMat.push([
            (pAxis[0] * pAxis[0] * aVers + aCosAngle),
            (pAxis[0] * pAxis[1] * aVers - pAxis[2] * aSinAngle),
            (pAxis[0] * pAxis[2] * aVers + pAxis[1] * aSinAngle)
        ]);

        aRotMat.push([
            (pAxis[0] * pAxis[1] * aVers + pAxis[2] * aSinAngle),
            (pAxis[1] * pAxis[1] * aVers + aCosAngle),
            (pAxis[2] * pAxis[1] * aVers - pAxis[0] * aSinAngle)
        ]);

        aRotMat.push([
            (pAxis[0] * pAxis[2] * aVers - pAxis[1] * aSinAngle),
            (pAxis[1] * pAxis[2] * aVers + pAxis[0] * aSinAngle),
            (pAxis[2] * pAxis[2] * aVers + aCosAngle)
        ]);

        return aRotMat;
    }
    //__________________________________________________________________________________________
    public static getOneCircularRoofPrismSurface(pCircleCreationParams: iRoofPrismCreationParams) {
        let aMatrixRotation = this.getAxisRotationMatrix(pCircleCreationParams.rotationAxis,
            pCircleCreationParams.rotationAngle);
        let aMatrixElements = this.getMatrix4(aMatrixRotation, pCircleCreationParams.position);
        let aZFunc = (pX: number, _pY: number) =>
            OpticsShapeUtils.calculateZRoofPrism(pX,
                pCircleCreationParams.thickness);
        let aResult = OpticsShapeUtils.getBufferCircular(aZFunc, {
            circles: 90,
            radius: pCircleCreationParams.diameter / 2,
        });

        let aSurface: iSurface = {
            shape: eOpticShape.CONIC,
            matrix: new Matrix4().fromArray(aMatrixElements),
            surfaceData: {
                name: pCircleCreationParams.surfaceName
            },
            frameData: {

                is_frame: false
            },
            simGeoData: {
                kind: eSmGeometryType.AXICON,

            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: pCircleCreationParams.diameter * UnitHandler.scale / 2
            },
            vertices: aResult.vertices
        };
        return { surface: aSurface, edgeVertices: aResult.edgeVertices };
    }
    //__________________________________________________________________________________________
    public static getOneDShaped(pDShaped: iDShapedCreationParams) {
        let aR = pDShaped.r;
        let aRotationAxis = pDShaped.rotationAxis;
        let aRotationAngle = pDShaped.rotationAngle;
        let aPosition = pDShaped.position;
        let aRotationMatrix = this.getAxisRotationMatrix(aRotationAxis, aRotationAngle);
        let aMatrixElements = this.getMatrix4(aRotationMatrix, aPosition);

        let aResult = OpticsShapeUtils.getHalfCircleVertices({
            center_height: pDShaped.center_height,
            alpha_deg: pDShaped.alpha_deg,
            radius: pDShaped.r,
            thickness: pDShaped.thickness
        });

        let aSurface: iSurface = {
            shape: eOpticShape.THIN_LENS,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                isMirror: pDShaped.isMirror,
                isInactive: pDShaped.isInactive,
                name: pDShaped.surfaceName
            },
            simGeoData: {
                kind: eSmGeometryType.PLANAR,
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aR * UnitHandler.scale
            },
            vertices: aResult.vertices
        };

        return { edgeVertices: aResult.edgeVertices, surface: aSurface };
    }
    //__________________________________________________________________________________________
    public static getGeneralRectangularConicLens(pGeneralConicLens: iGeneralRectConicLens) {
        let aSurfaces = new Array<iSurface>();

        let aThicknessCenter = pGeneralConicLens.thickness_center;

        let aFrontSurfacesWidth = pGeneralConicLens.front.width;
        let aFrontSurfacesHeight = pGeneralConicLens.front.height;
        let aFrontSurface;
        if ((0 == pGeneralConicLens.front.cx) && (0 == pGeneralConicLens.front.cy)) {
            aFrontSurface = MatrixUtils._getPolygon({
                points: [
                    -aFrontSurfacesWidth / 2, -aFrontSurfacesHeight / 2,
                    -aFrontSurfacesWidth / 2, aFrontSurfacesHeight / 2,
                    aFrontSurfacesWidth / 2, aFrontSurfacesHeight / 2,
                    aFrontSurfacesWidth / 2, -aFrontSurfacesHeight / 2
                ],
                position: [0, 0, 0],
                rotationAngle: 0,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.FRONT,
            });
        } else {
            aFrontSurface = this.getOneGenericRectangularConicSurface({
                cx: pGeneralConicLens.front.cx,
                cy: pGeneralConicLens.front.cy,
                kx: pGeneralConicLens.front.kx,
                ky: pGeneralConicLens.front.ky,
                x0: pGeneralConicLens.front.x0,
                y0: pGeneralConicLens.front.y0,
                z0: pGeneralConicLens.front.z0,
                deformations: pGeneralConicLens.front.deformations,
                isInactive: false,
                width: pGeneralConicLens.front.width,
                height: pGeneralConicLens.front.height,
                position: [0, 0, 0],
                rotationAngle: 0,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.FRONT
            });
        }
        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface = this.getOneGenericRectangularConicSurface({
            cx: -pGeneralConicLens.back.cx,
            cy: -pGeneralConicLens.back.cy,
            kx: pGeneralConicLens.back.kx,
            ky: pGeneralConicLens.back.ky,
            x0: pGeneralConicLens.back.x0,
            y0: pGeneralConicLens.back.y0,
            z0: pGeneralConicLens.back.z0,
            deformations: pGeneralConicLens.back.deformations,
            isInactive: false,
            width: pGeneralConicLens.back.width,
            height: pGeneralConicLens.back.height,
            position: [0, 0, aThicknessCenter],
            rotationAngle: Math.PI,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.BACK
        });
        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getGeneralConicLens(pGeneralConicLens: iGeneralConicLens) {
        let aSurfaces = new Array<iSurface>();

        let aThicknessCenter = pGeneralConicLens.thickness_center;
        const aFrontSurface = this.getOneGenericConicSurface({
            cx: pGeneralConicLens.front.cx,
            cy: pGeneralConicLens.front.cy,
            kx: pGeneralConicLens.front.kx,
            ky: pGeneralConicLens.front.ky,
            x0: pGeneralConicLens.front.x0,
            y0: pGeneralConicLens.front.y0,
            z0: pGeneralConicLens.front.z0,
            deformations: pGeneralConicLens.front.deformations,
            isInactive: false,
            diameter: pGeneralConicLens.front.diameter,
            position: [0, 0, 0],
            rotationAngle: 0,
            rotationAxis: MatrixUtils.ROTATION_AXIS,
            surfaceName: SurfaceContext.FRONT
        });

        aSurfaces.push(aFrontSurface.surface);

        let aBackSurface;
        if ((0 == pGeneralConicLens.back.cx) && (0 == pGeneralConicLens.back.cy)) {
            aBackSurface = this.getOneCircularThinSurface({
                r: (pGeneralConicLens.back.diameter / 2),
                position: [0, 0, aThicknessCenter],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK,
                isInactive: false
            });
        } else {
            aBackSurface = this.getOneGenericConicSurface({
                cx: -pGeneralConicLens.back.cx,
                cy: -pGeneralConicLens.back.cy,
                kx: pGeneralConicLens.back.kx,
                ky: pGeneralConicLens.back.ky,
                x0: pGeneralConicLens.back.x0,
                y0: pGeneralConicLens.back.y0,
                z0: pGeneralConicLens.back.z0,
                deformations: pGeneralConicLens.back.deformations,
                isInactive: false,
                diameter: pGeneralConicLens.back.diameter,
                position: [0, 0, aThicknessCenter],
                rotationAngle: Math.PI,
                rotationAxis: MatrixUtils.ROTATION_AXIS,
                surfaceName: SurfaceContext.BACK
            });
        }
        aSurfaces.push(aBackSurface.surface);

        let aBackVertices = aBackSurface.edgeVertices.map(vertex => {
            let aClone = vertex.clone();
            aClone = aClone.applyMatrix4(aBackSurface.surface.matrix.clone());
            return aClone;
        });

        this._addFrame(aFrontSurface.edgeVertices, aBackVertices,
            aFrontSurface.surface, aBackSurface.surface, aSurfaces);

        return aSurfaces;
    }
    //__________________________________________________________________________________________
    public static getGeneralConicLensDerivative(pConicParams: iConicParams, r: number) {
        let c = (0 != pConicParams.R) ? (1 / pConicParams.R) : 0;
        let k = pConicParams.K;

        let aD = ((c * r) / Math.sqrt(1 - ((1 + k) * Math.pow(c, 2) * Math.pow(r, 2))));
        return aD;
    }
    //__________________________________________________________________________________________
    public static getOneCircularThinSurface(pCircleCreationParams: iCircleCreationParams) {
        let aR = pCircleCreationParams.r;

        let aRotationAxis = pCircleCreationParams.rotationAxis;
        let aRotationAngle = pCircleCreationParams.rotationAngle;
        let aPosition = pCircleCreationParams.position;

        let aRotationMatrix = this.getAxisRotationMatrix(aRotationAxis, aRotationAngle);
        let aMatrixElements = this.getMatrix4(aRotationMatrix, aPosition);

        let aResult = OpticsShapeUtils.getCircleVertices(aR);

        let aSurface: iSurface = {
            shape: eOpticShape.THIN_LENS,
            matrix: new Matrix4().fromArray(aMatrixElements),
            frameData: { is_frame: false, },
            surfaceData: {
                isMirror: pCircleCreationParams.isMirror,
                isInactive: pCircleCreationParams.isInactive,
                name: pCircleCreationParams.surfaceName
            },
            simGeoData: {
                kind: eSmGeometryType.PLANAR,
            },
            shapeData: {
                kind: eSmBaseShapeKind.CIRCLE,
                radius: aR * UnitHandler.scale
            },
            vertices: aResult.vertices,
        };

        return { edgeVertices: aResult.edgeVertices, surface: aSurface };
    }
    //__________________________________________________________________________________________


}
