import { Op3dContext } from "../../../../_context/Op3dContext";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { ViewUtils } from "../../../ViewUtils";
import { iOMOneDropdownItem, iOpticsFilterComp, iDropdownData, iOMDropdownJSONData, iOMOptionElement, iOMCheckboxElement } from "../MenusContext";
import { eOMFilterName } from "../_filterHandlers/OpticsFilterHandler";
import { Filter } from "./Filter";

export class DropdownFilter extends Filter {

    private static SKIN_PATH = "./skins/menus/filters/dropdown_filter.html";
    private mDropdown: HTMLSelectElement;
    private mSingleCheckBox: HTMLElement;
    private mCheckBoxesParent: HTMLElement;
    private mSelectedOptions = new Array<iOMOneDropdownItem>();

    constructor(pData: iOpticsFilterComp<iDropdownData>) {
        super(DropdownFilter.SKIN_PATH, pData);
    }
    //_______________________________________________________
    public reset(pToChange: boolean = false) {
        // await Op3dContext.wait(() => (true == this.mIsReady));

        this.clear();
        if (this.mDropdown != null) {
            (<any>$('#' + this.mDropdown.id)).selectpicker('refresh');
        }

        this._onChange(pToChange);
    }
    //_______________________________________________________
    public distract() {
        // no need to impl distract
    }
    //_______________________________________________________
    protected _onCreationComplete(): void {
        if (Op3dContext.isWidget === true) {
            return
        }
        super._onCreationComplete();
        this.mIsReady = true;
    }
    //_______________________________________________________
    protected init() {
        super.init();

        this.mDropdown = this._getPart("dropdown-element", true) as HTMLSelectElement;
        this.mDropdown.classList.add("selectpicker");
        this.mDropdown.setAttribute('data-size', "5");
        (<any>$(this.mDropdown)).selectpicker();
        this.mDropdown.addEventListener("change", () => this._onDropdownPick());

        this.mCheckBoxesParent = this._getPart('checkboxes_parent', true);
        this.mSingleCheckBox = this._getPart('multiple_checkbox_component');
        ViewUtils.removeFromParent(this.mSingleCheckBox);
    }
    //_______________________________________________________
    public _onChange(pToChange: boolean = true) {
        if (null != this.mOnChange) {
            let aNames = (this.mSelectedOptions.length <= 0) ?
                null : this.mSelectedOptions.map((option) => { return option.serverName; });
            this.mOnChange(aNames, pToChange);
        }
    }
    //_______________________________________________________
    // public exportToJson() {
    //     let aData: iOMDropdownJSONData = {};

    //     let aOptions = this.mDropdown.options;
    //     if (aOptions.length > 0) {
    //         aData.options = new Array<iOMOneDropdownItem>();
    //     }

    //     for (let i = 0; i < aOptions.length; i++) {
    //         let aOption = aOptions[i] as iOMOptionElement;
    //         if ((null != aOption.data) && (null != aOption.data.name) && ("" != aOption.data.name)) {
    //             aData.options.push(aOption.data);
    //         }
    //     }

    //     if (this.mSelectedOptions.length > 0) {
    //         aData.selectedOptions = new Array<iOMOneDropdownItem>();
    //     }
    //     for (let i = 0; i < this.mSelectedOptions.length; i++) {
    //         let aOption = this.mSelectedOptions[i];
    //         if ((null != aOption) && (null != aOption.name) && ("" != aOption.name)) {
    //             aData.selectedOptions.push(aOption);
    //         }
    //     }

    //     return aData;
    // }
    //_______________________________________________________
    public initFromJson(pData: iOMDropdownJSONData) {
        if (null == pData) {
            return;
        }

        ViewUtils.removeElementChildren(this.mDropdown);
        this.clear();

        if ((null != pData) && (null != pData.options)) {
            for (let i = 0; i < pData.options.length; i++) {
                this.addOption(pData.options[i]);
            }
        }

        if (null != pData.selectedOptions) {
            for (let i = 0; i < pData.selectedOptions.length; i++) {
                this.addCheckbox(pData.selectedOptions[i], true, false);
            }
        }

        (<any>$(this.mDropdown)).selectpicker('refresh');
        this._onChange(false);
    }
    //_______________________________________________________
    public updateFilterData(pDropdownData: iDropdownData, pToChange: boolean = true) {

        if (pDropdownData.data != null) {
            this._initDropDown(pDropdownData.data);
        }

        let aTitle = this._getPart('label_tilte');
        aTitle.innerHTML = 'Choose ' + this.mTitle;
        this._onChange(pToChange);
    }
    //__________________________________________________________________________________________
    private _initDropDown(pData: Array<iOMOneDropdownItem>) {
        ViewUtils.removeElementChildren(this.mDropdown);

        for (let i = 0; i < pData.length; i++) {
            this.addOption(pData[i]);
        }

        (<any>$(this.mDropdown)).selectpicker('refresh');
        this.mDropdown.selectedIndex = 0;
    }
    //_______________________________________________________
    // private _getGroup(pGroupName: string) {
    //     if (null == pGroupName) {
    //         return null;
    //     }

    //     if (null == this.mGroups[pGroupName]) {
    //         let aGroup = document.createElement('optgroup');
    //         aGroup.label = pGroupName;
    //         this.mGroups[pGroupName] = aGroup;
    //         this.mDropdown.appendChild(aGroup);
    //     }

    //     return this.mGroups[pGroupName];
    // }
    //_______________________________________________________
    private addOption(pData: iOMOneDropdownItem) {
        if ((null == this.mDropdown) || (null == pData) || (null == pData.name)) {
            return;
        }

        let aOption = document.createElement('option') as iOMOptionElement;
        aOption.text = pData.name;
        aOption.data = pData;
        if (null == aOption.data.serverName) {
            aOption.data.serverName = aOption.data.name
        }

        // let aGroup = this._getGroup(pData.groupName);
        // if (null != aGroup) {
        //     aGroup.appendChild(aOption);
        // } else {
        this.mDropdown.appendChild(aOption);
        //}
    }
    //_______________________________________________________
    private _onDropdownPick() {
        let aSelectedIndex = this.mDropdown.selectedIndex;
        let aOption = this.mDropdown.options[aSelectedIndex] as iOMOptionElement;
        if (aOption == null) {
            return;
        }

        ViewUtils.removeFromParent(aOption);
        (<any>$('#' + this.mDropdown.id)).selectpicker('refresh');

        this.addCheckbox(aOption.data);
    }
    //_______________________________________________________
    private addCheckbox(pOptionData: iOMOneDropdownItem, pIsChecked: boolean = true, pToChange: boolean = true) {
        let aCheckboxParent = this.mSingleCheckBox.cloneNode(true) as HTMLElement;
        let aCheckbox = Op3dUtils.getElementIn(aCheckboxParent, 'check_box_input', true) as iOMCheckboxElement;
        aCheckbox.addEventListener('change', () => this._onCheckboxChange(aCheckbox, pOptionData));
        aCheckbox.checked = pIsChecked;
        aCheckbox.name = pOptionData.name;
        aCheckbox.serverName = pOptionData.serverName;
        let aLabel = Op3dUtils.getElementIn(aCheckboxParent, 'check_box_label') as HTMLLabelElement;
        aLabel.htmlFor = aCheckbox.id;
        aLabel.innerHTML = pOptionData.name;

        let aClose = Op3dUtils.getElementIn(aCheckboxParent, 'checkbox_close_btn', true) as HTMLElement;
        aClose.addEventListener('click', () => this.onCloseCheckbox(aCheckbox, pOptionData, aCheckboxParent));

        this.mCheckBoxesParent.appendChild(aCheckboxParent);

        this._onCheckboxChange(aCheckbox, pOptionData, pToChange);
    }
    //_______________________________________________________
    private onCloseCheckbox(pCheckbox: HTMLInputElement, pOptionData: iOMOneDropdownItem, pParent: HTMLElement) {
        pCheckbox.checked = false;
        this._onCheckboxChange(pCheckbox, pOptionData);
        ViewUtils.removeFromParent(pParent);
        this.addOption(pOptionData);
        (<any>$('#' + this.mDropdown.id)).selectpicker('refresh');
    }
    //_______________________________________________________
    private _onCheckboxChange(pCheckbox: HTMLInputElement, pOptionData: iOMOneDropdownItem, pToChange: boolean = true) {
        let aIndex = this.mSelectedOptions.indexOf(pOptionData);
        let aIsExist = (aIndex != -1);

        if ((true == pCheckbox.checked) && (false == aIsExist)) {
            this.mSelectedOptions.push(pOptionData);
        } else if ((false == pCheckbox.checked) && (true == aIsExist)) {
            this.mSelectedOptions.splice(aIndex, 1);
        }

        this._onChange(pToChange);
    }
    //_______________________________________________________
    private clear() {
        for (let i = 0; i < this.mSelectedOptions.length; i++) {
            this.addOption(this.mSelectedOptions[i]);
        }

        this.mSelectedOptions.splice(0);
        (<any>$('#' + this.mDropdown.id)).selectpicker('refresh');

        ViewUtils.removeElementChildren(this.mCheckBoxesParent);
    }
    //_______________________________________________________
    public setData(pName: string, pData: Array<any>) {

        for (let i = 0; i < pData.length; i++) {
            let aKey = Object.keys(pData[i])[0];
            if (aKey === pName) {

                switch (pName) {
                    case eOMFilterName.materialID: {
                        let aMaterials = Object.values(pData[i])[0] as Array<string>;
                        if (aMaterials.length > 0) {
                            for (let i = 0; i < aMaterials.length; i++) {
                                const aMaterialID = aMaterials[i];
                                let aMaterialName = Op3dContext.DATA_MANAGER.getMaterialNameByNumberID(aMaterialID)
                                this.addCheckbox({
                                    name: aMaterialName,
                                    selected: true,
                                    serverName: aMaterialID
                                }, true, false);

                            }
                        }

                        (<any>$(this.mDropdown)).selectpicker('refresh');
                        this._onChange(false);
                        this.updateCollapse({ toShow: true, scrollInfoView: true });
                        break;
                    }
                    default:



                        break;
                }

                return;
            }
        }
    }
    //_______________________________________________________
}
