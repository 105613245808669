﻿import { eDataPermission } from "../../../_context/Enums";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iCoatingVO, iHash } from "../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { CoatingDataLoader } from "../../../data/data_loader/CoatingDataLoader";
import { Part } from "../../../parts/Part";
import { iFace, iFaceDataNEW } from "../../../parts/PartInterfaces";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { CoatingChart } from "../../charts/CoatingChart";
import { Popup } from "../Popup";
import { UploadCoatingFileForm } from "./new/UploadCoatingFileForm";
import { eCoatedState, eCoatedType } from "./new/UploadOpticsContext";
import { uoSection } from "./uoSection";
import { ServerContext } from "../../../server/ServerContext";


export type tCoatingType = "TOTAL_ABSORBER" | "UNCOATED" | "IDEAL_REFLECTOR" | "NPBS_50_50" | "NPBS_70_30" | "NPBS_30_70" | "IDEAL_BBAR"
export enum eCoatingTypes {
    TOTAL_ABSORBER = 'absorbing',
    UNCOATED = 'uncoated',
    IDEAL_REFLECTOR = 'ideal_reflector',
    NPBS_50_50 = 'np_beam_splitter_50_50',
    NPBS_70_30 = 'np_beam_splitter_70_30',
    NPBS_30_70 = 'np_beam_splitter_30_70',
    IDEAL_BBAR = 'ideal_broadband_ar',
}

export class esCoating extends uoSection<iFace | iFaceDataNEW> {

    private static SKIN_PATH = './skins/forms/optics/es_coating_info.html';

    private mFace: iFace | iFaceDataNEW;
    private mPart: Part;
    private mCoatingProps!: HTMLElement;
    private mCoatingTypeGroup: Array<HTMLInputElement> = [];
    private mIsCoatedGroup: Array<HTMLInputElement> = [];
    private mPresetCoatings!: HTMLSelectElement;
    private mCoatingChartBtn!: HTMLElement;
    private mCustomUploadBtn!: HTMLElement;
    private mRemoveCustomBtn2!: HTMLElement;
    private mCoatingSpanName!: HTMLElement;

    private mParsedCoatings: iHash<iCoatingVO> = {};
    private mOriginalName!: string;
    private mCoatingState: iHash<string | undefined> = {}
    mCoatingData: iCoatingVO;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: esCoating.SKIN_PATH,
            title: 'Coating Info',
            isNewSkin: false,
            isPremiumSection: true
        });
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mCoatingChartBtn = this._getPart("coating-chart", true);
        this.mCoatingChartBtn.addEventListener("click", () => this._showCoatingChart());

        this.mCoatingSpanName = this._getPart("coating-name", true);
        this.mRemoveCustomBtn2 = this._getPart("remove-coating-btn", true);
        this.mRemoveCustomBtn2.addEventListener("click", () => this._onRemoveCoating());

        this.mCustomUploadBtn = this._getPart("custom-file-btn", true);
        this.mCustomUploadBtn.addEventListener("click", () => this._onClickCustomFile());

        this.mPresetCoatings = this._getPart("preset-coatings", true) as HTMLSelectElement;
        this.mPresetCoatings.addEventListener("change", () => this._onChangePreset());
        this.mCoatingProps = this._getPart("coating-props", true);

        $(this.mContainer).find('input[type="radio"][name="coating-face-state"]').each(
            (_index, element) => {

                let aLabel = Op3dUtils.getElementInByAttr(this.mContainer, "for", element.id) as HTMLLabelElement;
                element.id += Op3dComponentBase.ID_ITERATOR++;
                aLabel.htmlFor = element.id;

                this.mIsCoatedGroup.push(element as HTMLInputElement);

                element.addEventListener('change',
                    () => this._onIsCoatedChanged((element as HTMLInputElement).value == eCoatedState.COATED, true));
            });

        $(this.mContainer).find('input[type="radio"][name="coating-type"]').each(
            (_index, element) => {

                let aLabel = Op3dUtils.getElementInByAttr(this.mContainer, "for", element.id) as HTMLLabelElement;
                element.id += Op3dComponentBase.ID_ITERATOR++;
                aLabel.htmlFor = element.id

                this.mCoatingTypeGroup.push(element as HTMLInputElement);
                element.addEventListener('change', () => {
                    let aVal = (element as HTMLInputElement).value as eCoatedType;
                    this._changeCoatingType(aVal, true);
                });
            });
            let aLinkToPricing = this._getPart('try-professional-label', true) as HTMLAnchorElement;
            aLinkToPricing.href = ServerContext.pricing_base_link;
    }
    //__________________________________________________________________________________________
    private async _onParseCoating(pCoating: iCoatingVO) {

        this._setCoatingBtn(true)


        this.mParsedCoatings[this.mOriginalName] = pCoating;
        CoatingDataLoader.instance.addItemManually(pCoating);
        this.mCoatingSpanName.innerText = pCoating.name;
        this.mCoatingData = pCoating
        
        this._onChangePreset();
    }

    //__________________________________________________________________________________________
    private _onClickCustomFile() {

        if (Op3dContext.USER_VO.isBasicLicense == true) {
            return;
        }
        UploadCoatingFileForm.instance.open((pCoating: iCoatingVO) => this._onParseCoating(pCoating));
    }
    //__________________________________________________________________________________________
    private async _showCoatingChart() {

        let aCoatingNumberID = this.mCoatingState[this.mOriginalName];

        if (aCoatingNumberID !== undefined) {
            let aCoatingVO = await CoatingDataLoader.instance.getSingleFullData({ number_id: aCoatingNumberID });
            if (aCoatingVO == null) {
                Popup.instance.open({
                    text: MessagesHandler.MISSING_COATING_DATA
                });
                return
            }
            CoatingChart.instance.open(aCoatingVO)
        }
    }
    //______________________________________________________________________________________________
    private _onRemoveCoating() {
        this._setCoatingBtn(false)

        ViewUtils.setElementAttribute(this.mRemoveCustomBtn2, "number_id");
        this.mCoatingSpanName.innerText = "";


    }
    //__________________________________________________________________________________________
    private _onIsCoatedChanged(pIsCoated: boolean, pToUpdate: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mCoatingProps, pIsCoated);
        if (pToUpdate) {
            this._onChangePreset();
        }
    }
    //__________________________________________________________________________________________
    private _setIsCoatedState(pType: eCoatedState) {
        for (let i = 0; i < this.mIsCoatedGroup.length; i++) {
            const aElement = this.mIsCoatedGroup[i]
            const aVal = aElement.value as eCoatedState;
            if (aVal == pType) {
                aElement.checked = true;
            }
        }
        this._onIsCoatedChanged(pType == eCoatedState.COATED, false);
    }
    //__________________________________________________________________________________________
    private _onChangePreset() {
        this.mCoatingState[this.mOriginalName] = this._getCoatingNumberID()
        // EventManager.dispatchEvent(EventsContext.OPTICS_VO_COATING_CHANGED, this); 
    }
    //__________________________________________________________________________________________
    private _changeCoatingType(pType: eCoatedType, pToUpdate: boolean) {

        for (let i = 0; i < this.mCoatingTypeGroup.length; i++) {
            const aElement = this.mCoatingTypeGroup[i]
            const aVal = aElement.value as eCoatedType;
            if (aVal == pType) {
                aElement.checked = true;
            }
        }

        $(this.mContainer).find('[coating-type]').each(
            (_index, element) => {
                let aAttr = element.getAttribute("coating-type");
                ViewUtils.setElementDisabled(element, aAttr != pType);
            });

        if (pToUpdate) {
            this._onChangePreset();
        }
    }
    //__________________________________________________________________________________________
    public fillAttachments(pOpticsVO: iOpticsVO, pCoatingAttachments: iHash<iCoatingVO>, pIsNewInstance: boolean) {
        for (let key in pOpticsVO.parameters.coating) {
            if (this.mParsedCoatings[key] != null) {
                pCoatingAttachments[key] = this.mParsedCoatings[key];

            } else if (pIsNewInstance) {
                if (this._isCoatingPreset(pOpticsVO.parameters.coating[key])) {
                    continue;
                }

                pCoatingAttachments[key] = CoatingDataLoader.instance.getFromCache(pOpticsVO.parameters.coating[key]) as iCoatingVO;
            }
        }
    }
    //__________________________________________________________________________________________
     private _isCoatingPreset(pCoatingNumberID: string) {
        const aPresetKeys = Object.values(eCoatingTypes);
        return aPresetKeys.indexOf(pCoatingNumberID as eCoatingTypes) != -1;
    }
    //__________________________________________________________________________________________
    private _getCoatingNumberID(): string | undefined {
        let aCheckedItem = this.mIsCoatedGroup.find(item => item.checked == true);
        if (aCheckedItem === undefined) {
            throw new Error("No coating selected");
        }

        let aCoatedState = aCheckedItem.value as eCoatedState;
        if (aCoatedState == eCoatedState.COATED) {
            let aCoatingTypeGroup = this.mCoatingTypeGroup.find(item => item.checked == true);
            let aChoice: eCoatedType;

            if (aCoatingTypeGroup === undefined) {
                this._changeCoatingType(eCoatedType.PRESET, false);
                aChoice = eCoatedType.PRESET;

            } else {
                aChoice = aCoatingTypeGroup.value as eCoatedType;
            }

            switch (aChoice) {
                case eCoatedType.CUSTOM:
                    if (this.mParsedCoatings[this.mOriginalName] != null) {
                        return this.mParsedCoatings[this.mOriginalName].number_id;
                    }

                    let aCoatingNumberID = this.mRemoveCustomBtn2.getAttribute("number_id");
                    return (aCoatingNumberID !== null ? aCoatingNumberID : undefined);

                case eCoatedType.PRESET:
                    let aOption = this.mPresetCoatings.value as tCoatingType;
                    const aPresetNumberID = eCoatingTypes[aOption];
                    return aPresetNumberID;
            }

        } else {
            return undefined;
        }
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO): void {

        if (this.mOriginalName == null) {
            return;
        }

        if (pOpticsVO.parameters.coating == undefined) {
            pOpticsVO.parameters.coating = {};
        }

        for (let face in this.mCoatingState) {
            const aNumberID = this.mCoatingState[face];
            if (aNumberID === undefined) {
                delete pOpticsVO.parameters.coating[face];
            } else {
                pOpticsVO.parameters.coating[face] = aNumberID;
            }
        }
    }
    //__________________________________________________________________________________________
    public clear() {
        this.mCoatingState = {}
        this.mParsedCoatings = {};
        this.mPresetCoatings.selectedIndex = 0;
        this.mCoatingData = null;
        this._setCoatingBtn(false)
    }
    //__________________________________________________________________________________________
    private _setCoatingBtn(pIsCustomCoated: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mRemoveCustomBtn2, pIsCustomCoated);
        ViewUtils.setElementVisibilityByDNone(this.mCustomUploadBtn, !pIsCustomCoated);
    }
    //__________________________________________________________________________________________
    protected async _setData(pFace: iFace | iFaceDataNEW, pPart?: Part) {
        this._clear();

        this.mFace = pFace;
        if (null != pPart) {
            this.mPart = pPart;
        }
        if (pFace === null) {
            this.show(false);
            return;
        }
        if (pFace.data == null) {
            pFace.data = {}
        };

        this.mOriginalName = pFace.originalName;
        this.show(true);

        let aCoatingData = pFace.data.coatingID != null ? pFace.data.coatingID : undefined;

        ViewUtils.setElementAttribute(this.mRemoveCustomBtn2, "number_id");
        this._setCoatingBtn(false)

        if (aCoatingData === undefined) {
            // uncoated
            this._setIsCoatedState(eCoatedState.DEFAULT);
            this.mPresetCoatings.selectedIndex = 0;
            delete this.mCoatingState[this.mOriginalName];
        } else {
            // coated
            this._setIsCoatedState(eCoatedState.COATED);
            const aPresetKeys = Object.values(eCoatingTypes);
            const aPresetIndex = aPresetKeys.indexOf(aCoatingData as any);
            const aIsPreset = aPresetIndex != -1;
            const aCoatedType = aIsPreset ? eCoatedType.PRESET : eCoatedType.CUSTOM;

            this._changeCoatingType(aCoatedType, false);
            if (aIsPreset) {
                this.mPresetCoatings.value = Object.keys(eCoatingTypes)[aPresetIndex];
                this.mCoatingState[this.mOriginalName] = aPresetKeys[aPresetIndex];
            } else {
                let aCoatingData2 = CoatingDataLoader.instance.getFromCache(aCoatingData) as iCoatingVO;
                if(aCoatingData2 == null){
                    aCoatingData2 = await CoatingDataLoader.instance.getSingleFullData({number_id: aCoatingData})
                }
                let aCoatingName: string = "";
                let aNumberID: string | undefined;
                if (aCoatingData2 === null) {
                    aCoatingName == "Custom coating";
                } else {
                    aNumberID = aCoatingData2.number_id;
                    aCoatingName = aCoatingData2.name;
                }

                let aCustomFileName = this.mParsedCoatings[this.mOriginalName] != null ?
                    this.mParsedCoatings[this.mOriginalName].name : aCoatingName;
                if (Op3dContext.USER_VO.isBasicLicense == true) {
                    aCustomFileName = "Custom coating";
                    this.mCustomUploadBtn.style.pointerEvents = "none";
                }

                this._setCoatingBtn(true);
                this.mCoatingSpanName.innerText = aCustomFileName;
                ViewUtils.setElementAttribute(this.mRemoveCustomBtn2, "number_id", aNumberID);
                this.mCoatingState[this.mOriginalName] = aNumberID;
                this.mPresetCoatings.selectedIndex = 0;
            }
        }
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mFace = null;
        this.mPart = null;
    }
    //__________________________________________________________________________________________
    protected _checkFaceExisting() {
        let aFaceToChange = this.mPart.opticalData.faces.find(item => JSON.stringify(item.path) == JSON.stringify((this.mFace as iFaceDataNEW).path));
        if (aFaceToChange === undefined) {
            this.mPart.opticalData.faces.push(this.mFace);
            return this.mPart.opticalData.faces.slice(-1)[0];
        }
        return aFaceToChange;
    }
    //__________________________________________________________________________________________
    public async saveCoating(){
        this.mFace.data.coatingID = null;
        if (this.mPart != null) {
            if(this.mIsCoatedGroup[1].checked === true){
                let aCoatingNumberID = this._getCoatingNumberID();
                // const aPresetType = Object.values(eCoatingTypes).find(item => item === 'uncoated')
                const aIsPreset = Object.values(eCoatingTypes).includes(aCoatingNumberID as eCoatingTypes)
                if(aCoatingNumberID != null && aIsPreset !== true){
                    let aRes = await ServerContext.SERVER.addCoating({
                        toReplace: false, 
                        data: this.mCoatingData,
                        permission: eDataPermission.PRIVATE
                    });
                    if (aRes.success == false) {
                        Popup.instance.open({
                            text: "Something went wrong, please try again to add a coating."
                        });
                    }
                }else{
                    aCoatingNumberID = eCoatingTypes[`${this.mPresetCoatings.value}`];
                }
                this.mFace.data.coatingID = aCoatingNumberID
                let aFaceToChange = this._checkFaceExisting()
                aFaceToChange.data.coatingID = this.mFace.data.coatingID;
            }else{
                let aFaceToChange = this._checkFaceExisting()
                aFaceToChange.data.coatingID = this.mFace.data.coatingID;
            }
        }
    }
    //__________________________________________________________________________________________
}
