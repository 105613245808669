import { iMinMax } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";

export interface iAnalysisMatrixData {
    matrix: Array<Array<number>>;
    values_range: iMinMax;
    totalPower?: number;
    id: string;
}
export class AnalysisMatrix {

    public original: iAnalysisMatrixData;
    private mWorkingMatrix: iAnalysisMatrixData;

    constructor(pOriginalMatrix: iAnalysisMatrixData) {
        this.original = pOriginalMatrix;
        this._copyMatrix();
    }
    //______________________________________________________________________________________________
    private _copyMatrix() {
        this.mWorkingMatrix = {
            id: Op3dUtils.idGenerator(),
            matrix: new Array<Array<number>>(),
            values_range: {
                min: this.original.values_range.min,
                max: this.original.values_range.max
            },
            totalPower: this.original.totalPower
        }
        for (let i = 0; i < this.original.matrix.length; i++) {
            this.mWorkingMatrix.matrix[i] = new Array<number>();
            for (let j = 0; j < this.original.matrix[i].length; j++) {
                this.mWorkingMatrix.matrix[i].push(this.original.matrix[i][j]);
            }
        }
    }
    //______________________________________________________________________________________________
    public clone() {
        let aClone = new AnalysisMatrix(this.original);
        return aClone;
    }
    //______________________________________________________________________________________________
    public setColorMapRange(pMin: number, pMax: number) {
        const aNewValuesRange: iMinMax = {
            min: -1,
            max: -1
        };

        let aNewMat = new Array<Array<number>>();
        for (let i = 0; i < this.original.matrix.length; i++) {
            aNewMat[i] = new Array<number>();
            for (let j = 0; j < this.original.matrix[i].length; j++) {
                let aNewVal: number;
                if (this.original.matrix[i][j] < pMin) {
                    aNewVal = pMin;
                } else if (this.original.matrix[i][j] > pMax) {
                    aNewVal = pMax;
                } else {
                    aNewVal = this.original.matrix[i][j];
                }

                aNewValuesRange.min = aNewValuesRange.min === -1 ? aNewVal : Math.min(aNewValuesRange.min, aNewVal);
                aNewValuesRange.max = aNewValuesRange.max === -1 ? aNewVal : Math.max(aNewValuesRange.max, aNewVal);
                aNewMat[i].push(aNewVal);
            }
        }

        this.mWorkingMatrix = {
            id: Op3dUtils.idGenerator(),
            matrix: aNewMat,
            values_range: aNewValuesRange,
            totalPower: this.original.totalPower
        };
    }
    //______________________________________________________________________________________________
    public convertToLinear() {
        this._copyMatrix();
    }
    //______________________________________________________________________________________________
    public normalizeMatrix() {
        const aMaxNumber = this.original.values_range.max;
        const aNewRange: iMinMax = { min: 0, max: 1 };

        let aNewMat = new Array<Array<number>>();
        for (let i = 0; i < this.original.matrix.length; i++) {
            aNewMat[i] = new Array<number>();
            for (let j = 0; j < this.original.matrix[i].length; j++) {
                let aVal = (this.original.matrix[i][j] / aMaxNumber);
                aNewMat[i].push(aVal);
            }
        }

        this.mWorkingMatrix = {
            id: Op3dUtils.idGenerator(),
            matrix: aNewMat,
            values_range: aNewRange,
            totalPower: this.original.totalPower
        };
    }
    //______________________________________________________________________________________________
    public convertLog10() {
        const aMaxNumber = this.original.values_range.max;
        const aNewRange: iMinMax = {
            min: -1,
            max: -1
        };

        let aNewMat = new Array<Array<number>>();
        for (let i = 0; i < this.original.matrix.length; i++) {
            aNewMat[i] = new Array<number>();
            for (let j = 0; j < this.original.matrix[i].length; j++) {

                // let aVal = Math.log10(this.original.matrix[i][j] / aMaxNumber);
                let aVal = 10 * Math.log10(this.original.matrix[i][j] / aMaxNumber);
                if (aVal == Infinity || aVal == -Infinity) {
                    aVal = 0;
                }
                aNewRange.min = aNewRange.min === -1 ? aVal : Math.min(aNewRange.min, aVal);
                aNewRange.max = aNewRange.max === -1 ? aVal : Math.max(aNewRange.max, aVal);
                aNewMat[i].push(aVal);
            }
        }

        this.mWorkingMatrix = {
            id: Op3dUtils.idGenerator(),
            matrix: aNewMat,
            values_range: aNewRange,
            totalPower: this.original.totalPower
        };
    }
    //______________________________________________________________________________________________
    /**
     * @description returns the working data
     */
    //______________________________________________________________________________________________
    public get working() {
        return this.mWorkingMatrix;
    }

    //______________________________________________________________________________________________
}