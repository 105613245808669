import { useContext, useEffect, useRef, useState } from "react";
import classes from "./../../UI/optichat-message.module.scss";
import reviewAvatar from "../../../../../public/images/icons_new/chatbot/review_avatar.svg";
import { ServerContext } from "../../../server/ServerContext";
import { ViewUtils } from "../../../ui/ViewUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import warningIcon from "../../../../../public/images/icons_new/warning.svg";
import OptiChatContext, {
  eChatMessageType,
  iOptiChatMessage,
} from "../../store/OptiChatContext";
import { REVIEW_MESSAGES, waitingDots } from "../../store/OptiChatProvider";

export default function OptiChatReviewMessage(props: {
  data: iOptiChatMessage;
  onFinishReview;
}) {
  const contentRef = useRef<HTMLDivElement>();
  const [isError, setIsError] = useState(
    props.data.type === eChatMessageType.ERROR_RESPONSE
  );
  const optiChatContext = useContext(OptiChatContext);

  let intervalId;

  let aDots = "";

  useEffect(() => {
    if (props.data.userMessage === undefined) {
      if (props.data.typed === true) {
        contentRef.current.innerText = props.data.message;
        return;
      }
      ViewUtils.typeEffect(props.data.message, contentRef.current);
      props.data.typed = true;
    } else {
      sendReview();
    }
  });

  const sendReview = async () => {
    // optiChatContext.setIsWaitingServerResponse(true);
    optiChatContext.data.current.isWaitingServerResponse = true;
    let aResponse = await ServerContext.SERVER.addReview({
      content: props.data.userMessage,
      session: optiChatContext.session,
    });

    clearInterval(intervalId);
    props.data.typed = true;
    props.data.userMessage = undefined;

    if (aResponse.success === true) {
      let aIdx = OP3DMathUtils.getRandomNumber(REVIEW_MESSAGES.length);

      let aReviewResponse = REVIEW_MESSAGES[aIdx];
      await ViewUtils.typeEffect(aReviewResponse, contentRef.current);
      props.data.message = aReviewResponse;
    } else {
      setIsError(true);
      let aMessage = MessagesHandler.CHAT_BOT_ERROR;
      props.data.type = eChatMessageType.ERROR_RESPONSE;
      props.data.message = aMessage;

      await ViewUtils.typeEffect(aMessage, contentRef.current);
    }
    // optiChatContext.setIsWaitingServerResponse(false);
    optiChatContext.data.current.isWaitingServerResponse = false;

    props.onFinishReview();
  };

  useEffect(() => {
    intervalId = setInterval(() => {
      if (props.data.typed === true) {
        clearInterval(intervalId);
        return;
      }

      aDots = waitingDots(contentRef.current, aDots);
    }, 400);
    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div
      className={`${classes["chat-message"]} ${
        isError
          ? classes[eChatMessageType.ERROR_RESPONSE]
          : classes[props.data.type]
      }`}
    >
      <div>
        <div className={classes.avatar}>
          {isError ? <img src={warningIcon} /> : <img src={reviewAvatar} />}
        </div>
        <div className={classes.content}>
          <div ref={contentRef}></div>
        </div>
      </div>
    </div>
  );
}
