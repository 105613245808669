import { iGaussianBeam } from "../parts/behaviors/LightSourceContext";
import { iCoatingVO, iHash, iPoint2D } from "../_context/_interfaces/Interfaces";
import { Matrix4, Vector3, Triangle, BufferGeometry, DoubleSide, Raycaster, Intersection, Mesh, MeshPhongMaterial } from "three";
import { eSmGeometryType, eSmRaysKind, iSmGeometryData } from "./SimulationContext";
import { Op3dContext } from "../_context/Op3dContext";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { ColorUtils } from "../ui/ColorUtils";
import { OpticsDataLoader } from "../data/data_loader/OpticsDataLoader";
import { OpticsContext } from "../_context/OpticsContext";
import { CoatingDataLoader } from "../data/data_loader/CoatingDataLoader";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { MaterialUtils } from "../_utils/MaterialUtils";
import { MaterialDataLoader } from "../data/data_loader/MaterialDataLoader";
import { GaussianBeamTable, iGaussianBeamAnalysis } from "../ui/analysis/GaussianBeamTable";
import { EventManager } from "../../oc/events/EventManager";
import { EventsContext } from "../_context/EventsContext";
import { SimulationRunner } from "./SimulationRunner";
import { Part } from "../parts/Part";
import { eAxisType } from "../_context/Enums";
import { LaserBehavior } from "../parts/behaviors/LaserBehavior";

export interface iIntersection extends Intersection {
    normal: Vector3;
}

export interface iCoatingValues {
    R_P: number;
    R_S: number;
    T_P: number;
    T_S: number;
}

export interface iGaussianMesh extends Mesh {
    index: number;
    materialID: string;
    coatingID: string;

    geometry_index: number;

    isMirror: boolean;

    isDummy?: boolean;

    partLabel: string;

    geo_data: iSmGeometryData;
}

export interface iGaussianRay {
    beam: iGaussianBeam;
    mediumRefIndex: number;
    w_index: number;

    isInAir?: boolean;

    sourceID: string;
}

export interface iGaussianEllipse {
    rx: number;
    ry: number;
    zx: number;
    zy: number;
}

export interface iGaussianMatrixDrawing extends iGaussianEllipse {
    pos: Vector3;
    rotMat: Matrix4;
}

export interface iGaussianRayTracingRun {
    ellipsesToCompare: [iGaussianEllipse, iGaussianEllipse];
    ellipsesToDraw: iGaussianMatrixDrawing;

    triangle: Triangle;

    p: [Vector3, Vector3, Vector3, Vector3];
    n: [Vector3, Vector3, Vector3, Vector3];

    r: iGaussianRay;

    vertices: Array<number>;
    colors: Array<number>;

    o: Vector3;
    d: Vector3;

    raycaster: Raycaster;
}

var __z_Axis: Vector3 = new Vector3(0, 0, 1);

var __r: iGaussianRay;

var __o: Vector3 = new Vector3();
var __d: Vector3 = new Vector3();

var __p: Vector3 = new Vector3();
var __p1: Vector3 = new Vector3();
var __p2: Vector3 = new Vector3();
var __p3: Vector3 = new Vector3();
var __n1: Vector3 = new Vector3();
var __n2: Vector3 = new Vector3();
var __n3: Vector3 = new Vector3();

var __triangle: Triangle = new Triangle();

var __invertObjectMatrix: Matrix4 = new Matrix4();
var __sourceMatrixRot: Matrix4 = new Matrix4();
var __barycoord: Vector3 = new Vector3();
var __normal: Vector3 = new Vector3();

var __raycaster = new Raycaster();

var __colorsArray = new Array<number>();
var __gaussianVertices = new Array<number>();

var __currSurface: iGaussianMesh;

var __gaussianBeamGeometry: BufferGeometry = new BufferGeometry();
var __gaussianBeamMateiral: MeshPhongMaterial = new MeshPhongMaterial({
    vertexColors: true,
    side: DoubleSide,
    reflectivity: 0
});

var __gaussian_rays_mesh: Mesh = new Mesh(__gaussianBeamGeometry, __gaussianBeamMateiral);

var __gaussian_matrix: [number, number, number, number] = [0, 0, 0, 0];

export class GaussianRayTracer {

    private mEllipsesToDraw: Array<iGaussianMatrixDrawing> = new Array<iGaussianMatrixDrawing>();
    private mEllipsesVertices: Array<Array<number>> = new Array<Array<number>>();

    private mCoatings: iHash<Array<iCoatingValues>>;
    private mRefractiveIndices: iHash<Array<number>>;
    private mWavelengths: Array<number>;
    private mSurfaces: Array<iGaussianMesh>;

    private mPrevEllipses: Array<iGaussianEllipse> = new Array<iGaussianEllipse>();

    private mTotalEllipses: number = 0;
    private mReducedEllipses: number = 0;

    //______________________________________________________________________________________________
    constructor() {
        if (null != __gaussian_rays_mesh.parent) {
            __gaussian_rays_mesh.parent.remove(__gaussian_rays_mesh);
        }

        this.mRefractiveIndices = {};
        this.mCoatings = {};
        __raycaster.near = 0.01;
    }
    //______________________________________________________________________________________________
    public async runSimulation() {
        await this._prepateSimulation();
        this._simulate();
    }
    //______________________________________________________________________________________________
    private _simulate() {
        const aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0, l = aParts.length; i < l; i++) {
            let aLaserBehavior = aParts[i].getBehavior('laserBehavior');
            if ((undefined !== aLaserBehavior) && (undefined !== aLaserBehavior.laserData) &&
                (undefined !== aLaserBehavior.laserData.lightSource) &&
                (eSmRaysKind.GAUSSIAN_BEAM === aLaserBehavior.laserData.lightSource.kind)) {
                let aSource = aParts[i];

                let aWavelengthData = aLaserBehavior.laserData.lightSource.wavelengthData;
                let aWavelengths = aWavelengthData.map(wd => wd.wl);
                let aID = aParts[i].internalID;

                let aLaserPoint = aSource.getAxes().find((axis) => (axis.type === eAxisType.LASER));
                let aWorldMatrix = aLaserPoint.object3D.matrixWorld.clone();

                let aLightSource = aLaserBehavior.laserData.lightSource;
                let aCombinedMatrix = LaserBehavior.getCombinedMatrix(aLightSource.directionalData);
                let aNewMatrix4 = new Matrix4().multiplyMatrices(aWorldMatrix, aCombinedMatrix);
                let aBeam = aLightSource.gaussianBeam;

                for (let j = 0; j < aWavelengths.length; j++) {
                    __sourceMatrixRot.extractRotation(aNewMatrix4);
                    __o.setFromMatrixPosition(aNewMatrix4);
                    __p.copy(__o);
                    __d.set(0, 0, 1);
                    __d.applyMatrix4(__sourceMatrixRot);

                    __r = {
                        beam: { q_x: aBeam.q_x.clone(), q_y: aBeam.q_y.clone() },
                        mediumRefIndex: 1,
                        w_index: this.mWavelengths.findIndex((w) => (w == aWavelengths[j])),
                        sourceID: aID
                    };

                    this._raycast();
                }
            }
        }

        this._draw();
        EventManager.dispatchEvent(EventsContext.ON_FINISH_GAUSSIAN_SIMULATION, this);
    }
    //______________________________________________________________________________________________
    private _draw() {
        SimulationRunner.instance.addGaussianRays(__gaussianVertices, __colorsArray);
        this._distract();
    }
    //______________________________________________________________________________________________
    private _distract() {
        __colorsArray.splice(0);
        __gaussianVertices.splice(0);
    }
    //______________________________________________________________________________________________
    private _endSimulation(pDist: number) {
        let aEndPoint = __d.clone().multiplyScalar(pDist).add(__o)
        this._calcGaussian(aEndPoint);
        this._updateTable(aEndPoint);

        this._calculateVertices();

        this.mEllipsesToDraw.splice(0);
        this.mEllipsesVertices.splice(0);
    }
    //______________________________________________________________________________________________
    private _updateTable(pHitPoint: Vector3) {
        let aMat = (undefined !== __currSurface) ? __currSurface.materialID : undefined;
        let aWl = __r.w_index;
        let n = __r.mediumRefIndex;
        if ((undefined !== aMat) && (false === __r.isInAir)) {
            n = this.mRefractiveIndices[aMat][aWl]
        }
        let aLabel = (false == __r.isInAir) ? __currSurface.partLabel : 'Air gap';

        let aData: iGaussianBeamAnalysis = {
            distance: pHitPoint.distanceTo(__o),
            label: aLabel,
            n: n,
            q_x: __r.beam.q_x,
            q_y: __r.beam.q_y
        };

        let aID = __r.sourceID;
        let aWavelength = this.mWavelengths[aWl];

        if (null == GaussianBeamTable.GAUSSIAN_DATA[aID]) {
            GaussianBeamTable.GAUSSIAN_DATA[aID] = {};
        }

        if (null == GaussianBeamTable.GAUSSIAN_DATA[aID][aWavelength]) {
            GaussianBeamTable.GAUSSIAN_DATA[aID][aWavelength] = new Array<iGaussianBeamAnalysis>();
        }

        GaussianBeamTable.GAUSSIAN_DATA[aID][aWavelength].push(aData);
    }
    //______________________________________________________________________________________________
    private _raycast() {
        __raycaster.set(__p, __d);
        let aIntersection = __raycaster.intersectObjects(this.mSurfaces, false)[0];
        if (null == aIntersection) {
            let aDist = Op3dContext.USER_VO.simulationSettings.distanceOfLaserRay;
            this._endSimulation((__p.distanceTo(__o) + aDist));
            return;
        }

        let aObject = aIntersection.object as iGaussianMesh;
        if (true == aObject.isDummy) {
            __p.copy(aIntersection.point);
            this._raycast();
            return;
        }

        this._calcNormal(aIntersection);
        this._calcGaussian(aIntersection.point);
        this._updateTable(aIntersection.point);

        this._calcBeam(aIntersection.point);

        if (true != __currSurface.isMirror) {
            this._snellLaw();
        } else {
            this.onReflection()
        }

        __o.copy(aIntersection.point);
        __p.copy(__o);

        this._raycast();
    }
    //______________________________________________________________________________________________
    private onReflection() {
        let N_dot_V = __normal.dot(__d);
        let cosThetaIn = N_dot_V; // __normal & __d assumed to be normalized;

        __d.sub(__normal.multiplyScalar(2 * cosThetaIn));
        __r.isInAir = __triangle.isFrontFacing(__d);
    }
    //______________________________________________________________________________________________
    private _calcBeam(pHitPoint: Vector3) {
        let aMatIndex = __currSurface.materialID;
        let aGeometry = __currSurface.geo_data;

        let elementRefIndex = this.mRefractiveIndices[aMatIndex][__r.w_index];
        let aIsInAir = (false != __r.isInAir);
        let n1 = (true == aIsInAir) ? __r.mediumRefIndex : elementRefIndex;
        let n2 = (true == aIsInAir) ? elementRefIndex : __r.mediumRefIndex;

        var dist = pHitPoint.distanceTo(__o);

        let a = 1;
        let b = ((elementRefIndex > 1) || (true === aGeometry.is_ideal_lens)) ? 0 : -dist;
        let c = 0;
        let d = (true == __currSurface.isMirror) ? 1 : (n1 / n2);

        switch (aGeometry.kind) {
            case eSmGeometryType.BICONIC:
                let R = (Math.SQRT1_2 * Math.hypot(aGeometry.radius_x, aGeometry.radius_y));
                c = (true === __currSurface.isMirror) ? (2 / R) : ((n1 - n2) / (R * n2));
                break;
            case eSmGeometryType.PLANAR:
                if (true === aGeometry.is_ideal_lens) {
                    c = (-1 / aGeometry.focal_length);
                }
                break;
            default:
                break;
        }

        if (true === __currSurface.isMirror) {
            __r.beam.q_x.re *= -1;
            __r.beam.q_y.re *= -1;
            this.mPrevEllipses.splice(0);
        }

        __gaussian_matrix = [a, b, c, d];


        let q_x = __r.beam.q_x.clone();
        q_x.re = (dist - q_x.re);

        let q_x_num = q_x.clone();
        q_x_num.multiply(__gaussian_matrix[0]);
        q_x_num.add(__gaussian_matrix[1]);

        let q_x_denum = q_x.clone();
        q_x_denum.multiply(__gaussian_matrix[2]);
        q_x_denum.add(__gaussian_matrix[3]);
        q_x.divideComplexNumbers(q_x_num, q_x_denum);

        let q_y = __r.beam.q_y.clone();
        q_y.re = (dist - q_y.re);

        let q_y_num = q_y.clone();
        q_y_num.multiply(__gaussian_matrix[0]);
        q_y_num.add(__gaussian_matrix[1]);

        let q_y_denum = q_y.clone();
        q_y_denum.multiply(__gaussian_matrix[2]).add(__gaussian_matrix[3]);
        q_y.divideComplexNumbers(q_y_num, q_y_denum);

        __r.beam.q_x = q_x;
        __r.beam.q_y = q_y;
    }
    //______________________________________________________________________________________________
    private _calcNormal(pIntersection: Intersection) {
        __currSurface = pIntersection.object as iGaussianMesh;
        let face = pIntersection.face;
        let pos = __currSurface.geometry.attributes['position'].array;
        let normal = __currSurface.geometry.attributes['normal'].array;

        __p1.set(pos[(3 * face.a)], pos[((3 * face.a) + 1)], pos[((3 * face.a) + 2)]);
        __p2.set(pos[(3 * face.b)], pos[((3 * face.b) + 1)], pos[((3 * face.b) + 2)]);
        __p3.set(pos[(3 * face.c)], pos[((3 * face.c) + 1)], pos[((3 * face.c) + 2)]);

        __n1.set(normal[(3 * face.a)], normal[((3 * face.a) + 1)], normal[((3 * face.a) + 2)]);
        __n2.set(normal[(3 * face.b)], normal[((3 * face.b) + 1)], normal[((3 * face.b) + 2)]);
        __n3.set(normal[(3 * face.c)], normal[((3 * face.c) + 1)], normal[((3 * face.c) + 2)]);

        __invertObjectMatrix.copy(__currSurface.matrixWorld).invert();
        __p.applyMatrix4(__invertObjectMatrix);

        __triangle.set(__p1, __p2, __p3);
        __triangle.getBarycoord(__p, __barycoord);

        __normal.set(0, 0, 0);
        __normal.addScaledVector(__n1, __barycoord.x);
        __normal.addScaledVector(__n2, __barycoord.y);
        __normal.addScaledVector(__n3, __barycoord.z);

        __invertObjectMatrix.extractRotation(__currSurface.matrixWorld);
        __normal.applyMatrix4(__invertObjectMatrix);
    }
    //______________________________________________________________________________________________
    private _snellLaw() {
        let n_element = this.mRefractiveIndices[__currSurface.materialID][__r.w_index];
        if (1 == n_element) {
            return;
        }

        let eta: number;
        if (false == __r.isInAir) {
            __r.isInAir = true;
            eta = (n_element / __r.mediumRefIndex);
            __normal.multiplyScalar(-1);
        } else {
            eta = (__r.mediumRefIndex / n_element);
            __r.isInAir = false;
        }

        let N_dot_V = __normal.dot(__d);
        let cosThetaIn = N_dot_V; // __normal & __d assumed to be normalized;
        cosThetaIn = OP3DMathUtils.clampValue(cosThetaIn, -1, 1);

        let sinThetaIn = Math.sin(Math.acos(cosThetaIn));

        if ((sinThetaIn * eta) > 1) { //TIR
            __d.sub(__normal.multiplyScalar(2 * cosThetaIn));
            __r.isInAir = false;
        } else {
            let sinPhi = sinThetaIn * eta;
            let XY = eta * N_dot_V + Math.cos(Math.asin(sinPhi));

            __d.x = -(__normal.x * XY - eta * __d.x);
            __d.y = -(__normal.y * XY - eta * __d.y);
            __d.z = -(__normal.z * XY - eta * __d.z);
        }
        __d.normalize();
    }
    //______________________________________________________________________________________________
    private _calcGaussian(pHitPoint: Vector3) {
        let wavelength = (this.mWavelengths[__r.w_index] * OP3DMathUtils.NANO_TO_MM);
        let pi = Math.PI;
        let n = (false == __r.isInAir) ?
            this.mRefractiveIndices[__currSurface.materialID][__r.w_index] : __r.mediumRefIndex;


        let w_x_0 = Math.sqrt((Math.abs(__r.beam.q_x.im) * wavelength) / (pi * n));
        let w_y_0 = Math.sqrt((Math.abs(__r.beam.q_y.im) * wavelength) / (pi * n));
        let aRot = OP3DMathUtils.getVecToVecRotMatrix2(__z_Axis, __d);

        var dist = pHitPoint.distanceTo(__o);

        let epsilon = OP3DMathUtils.MICRO;
        let delta = OP3DMathUtils.MILI;
        for (let t = 0; t < dist; t += delta) {
            let e = this._getEllipse(w_x_0, w_y_0, t, aRot);
            this.mTotalEllipses++

            if ((undefined === this.mPrevEllipses[0])) {
                this.mPrevEllipses.push(e);
                this.mEllipsesToDraw.push(e);
                this.mReducedEllipses++;
            } else if (undefined === this.mPrevEllipses[1]) {
                this.mPrevEllipses.push(e);
                continue;
            } else {
                let e1 = this.mPrevEllipses[0];
                let e2 = this.mPrevEllipses[1];

                let p1_x: iPoint2D = { x: e1.zx, y: e1.rx };
                let p2_x: iPoint2D = { x: e2.zx, y: e2.rx };
                let p3_x: iPoint2D = { x: e.zx, y: e.rx };

                let p1_y: iPoint2D = { x: e1.zy, y: e1.ry };
                let p2_y: iPoint2D = { x: e2.zy, y: e2.ry };
                let p3_y: iPoint2D = { x: e.zy, y: e.ry };

                if ((false == OP3DMathUtils.isCloseToLinear(p1_x, p2_x, p3_x, epsilon)) ||
                    (false == OP3DMathUtils.isCloseToLinear(p1_y, p2_y, p3_y, epsilon))) {

                    this.mPrevEllipses[0] = e;
                    this.mPrevEllipses.splice(1);
                    this.mEllipsesToDraw.push(e);
                    this.mReducedEllipses++;
                } else {
                    this.mPrevEllipses[1] = e;
                }
            }
        }

        this.mEllipsesToDraw.push(this._getEllipse(w_x_0, w_y_0, dist, aRot));
    }
    //______________________________________________________________________________________________
    private _getEllipse(w_x_0: number, w_y_0: number, t: number, rot: Matrix4) {
        let z_x_0 = __r.beam.q_x.re;
        let z_y_0 = __r.beam.q_y.re;
        let zR_x_0 = __r.beam.q_x.im;
        let zR_y_0 = __r.beam.q_y.im;

        let z_x = (t + z_x_0);
        let z_y = (t + z_y_0);

        let w_x = w_x_0 * Math.sqrt(1 + Math.pow((z_x / zR_x_0), 2));
        let w_y = w_y_0 * Math.sqrt(1 + Math.pow((z_y / zR_y_0), 2));

        let aPos = new Vector3().copy(__d).multiplyScalar(t).add(__o);

        let aEllipse: iGaussianMatrixDrawing = {
            pos: aPos,
            rotMat: rot,
            rx: w_x,
            ry: w_y,
            zx: z_x,
            zy: z_y
        };

        return aEllipse;
    }
    //______________________________________________________________________________________________
    private _calcEllipse(a: number, b: number, pos: Vector3, pMat: Matrix4) {
        let aEllpise = new Array<number>();
        let num_of_points = 60;

        for (let i = 0; i < num_of_points; i++) {
            let theta = (i / num_of_points) * 2 * Math.PI;

            let x = a * Math.cos(theta);
            let y = b * Math.sin(theta);
            let z = 0;

            let vec = new Vector3(x, y, z).applyMatrix4(pMat);
            vec.add(pos);
            aEllpise.push(...vec.toArray());
        }

        this.mEllipsesVertices.push(aEllpise);
    }
    //______________________________________________________________________________________________
    private _calculateVertices() {
        for (let i = 0; i < this.mEllipsesToDraw.length; i++) {
            let e = this.mEllipsesToDraw[i];
            this._calcEllipse(e.rx, e.ry, e.pos, e.rotMat);
        }

        let aColor = ColorUtils.wavelengthToRGB(this.mWavelengths[__r.w_index], 1, true);

        let aNumOfEllipses = this.mEllipsesVertices.length;
        let l = this.mEllipsesVertices[0].length;

        for (let i = 0; i < (aNumOfEllipses - 1); i++) {
            let aCurr = this.mEllipsesVertices[i];
            let aNext = this.mEllipsesVertices[(i + 1)];

            for (let j = 0; j < l; j += 3) {
                var k = ((j + 3) % l);

                __gaussianVertices.push(aCurr[j], aCurr[j + 1], aCurr[j + 2]);
                __gaussianVertices.push(aNext[j], aNext[j + 1], aNext[j + 2]);
                __gaussianVertices.push(aCurr[k], aCurr[k + 1], aCurr[k + 2]);


                __gaussianVertices.push(aCurr[k], aCurr[k + 1], aCurr[k + 2]);
                __gaussianVertices.push(aNext[j], aNext[j + 1], aNext[j + 2]);
                __gaussianVertices.push(aNext[k], aNext[k + 1], aNext[k + 2]);

                __colorsArray.push(aColor.r, aColor.g, aColor.b);
                __colorsArray.push(aColor.r, aColor.g, aColor.b);
                __colorsArray.push(aColor.r, aColor.g, aColor.b);
                __colorsArray.push(aColor.r, aColor.g, aColor.b);
                __colorsArray.push(aColor.r, aColor.g, aColor.b);
                __colorsArray.push(aColor.r, aColor.g, aColor.b);
            }
        }
    }
    //______________________________________________________________________________________________
    private async _prepateSimulation() {
        let aParts = Op3dContext.PARTS_MANAGER.parts;

        this._prepareWavelengths(aParts);
        await this._prepareMaterial(aParts);
        await this._prepareCoatings(aParts);
        this._prepareSurfaces(aParts);
    }
    //______________________________________________________________________________________________
    private _prepareSurfaces(pParts: Array<Part>) {
        this.mSurfaces = pParts.flatMap((part) =>
            part.getOpticsFaces().flatMap((face) => {
                if ((undefined === face.data) || (undefined === face.data.simGeoData)) {
                    return [];
                }

                let aMesh = face.visualization.mesh as iGaussianMesh;


                let aIsMirrorFace = false;
                let aOpticsNumberID = part.opticsNumberID;

                if (null != aOpticsNumberID) {
                    let aOpticsVO = OpticsDataLoader.instance.getFromCache(aOpticsNumberID);
                    aIsMirrorFace = OpticsContext.isMirrorFace(aOpticsVO, face.originalName);
                }

                let aMaterialID = face.data.materialID;

                aMesh.isDummy = ((1 === this.mRefractiveIndices[aMaterialID][0]) &&
                    (undefined === face.data.paraxialLensData));

                aMesh.materialID = aMaterialID;
                aMesh.coatingID = face.data.coatingID;
                aMesh.isMirror = aIsMirrorFace;
                aMesh.partLabel = part.getLabel().label
                aMesh.geo_data = {
                    kind: face.data.simGeoData.kind,
                    radius_x: face.data.simGeoData.radius_x,
                    radius_y: face.data.simGeoData.radius_y,
                    is_ideal_lens: face.data.simGeoData.is_ideal_lens,
                    focal_length: face.data.simGeoData.focal_length
                };

                return aMesh;
            })
        );
    }
    //______________________________________________________________________________________________
    private async _prepareCoatings(pParts: Array<Part>) {
        let aCoatingsHash: iHash<iCoatingVO> = {};

        for (let i = 0, l = pParts.length; i < l; i++) {
            let aFaces = pParts[i].getFaces();
            for (let j = 0, j_l = aFaces.length; j < j_l; j++) {
                let aFace = aFaces[j];
                let aFaceData = aFace.data;
                if (undefined !== aFaceData) {
                    let aCoatingID = aFaceData.coatingID;
                    if ((undefined !== aCoatingID) && ('' !== aCoatingID)) {
                        if (undefined === aCoatingsHash[aCoatingID]) {
                            aCoatingsHash[aCoatingID] =
                                await CoatingDataLoader.instance.getSingleFullData({
                                    number_id: aCoatingID
                                });

                            let aCoating = aCoatingsHash[aCoatingID];
                            this.mCoatings[aCoatingID] = new Array<iCoatingValues>();
                            for (let j = 0, j_l = this.mWavelengths.length; j < j_l; j++) {
                                let wl = aCoating.wl;
                                let c1 = Op3dUtils.getClosestPrevIndex(this.mWavelengths[j], wl);
                                let c2 = Op3dUtils.getClosestNextIndexInArray(this.mWavelengths[j],
                                    wl);

                                let w = this.mWavelengths[j];
                                let w1 = aCoating.wl[c1];
                                let w2 = aCoating.wl[c1];

                                let rp = aCoating.R_P;
                                let rs = aCoating.R_P;
                                let tp = aCoating.R_P;
                                let ts = aCoating.R_P;

                                this.mCoatings[aCoatingID].push({
                                    R_P: OP3DMathUtils.getLinearInterpolationValue(w, w1, w2,
                                        rp[c1], rp[c2]),
                                    R_S: OP3DMathUtils.getLinearInterpolationValue(w, w1, w2,
                                        rs[c1], rs[c2]),
                                    T_P: OP3DMathUtils.getLinearInterpolationValue(w, w1, w2,
                                        tp[c1], tp[c2]),
                                    T_S: OP3DMathUtils.getLinearInterpolationValue(w, w1, w2,
                                        ts[c1], ts[c2])
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    //______________________________________________________________________________________________
    private _prepareWavelengths(pParts: Array<Part>) {
        let aWavelengths = new Array<number>();
        for (let i = 0, l = pParts.length; i < l; i++) {
            let aLaserBehavior = pParts[i].getBehavior('laserBehavior');
            if ((undefined !== aLaserBehavior) &&
                (undefined !== aLaserBehavior.laserData.lightSource) &&
                (undefined !== aLaserBehavior.laserData.lightSource.wavelengthData)) {
                let wls = aLaserBehavior.laserData.lightSource.wavelengthData.map((wl => wl.wl));
                aWavelengths.push(...wls);
            }
        }

        this.mWavelengths = new Array<number>();
        this.mWavelengths.push(...new Set(aWavelengths));
    }
    //______________________________________________________________________________________________
    private async _prepareMaterial(pParts: Array<Part>) {
        for (let i = 0, l = pParts.length; i < l; i++) {
            let aFaces = pParts[i].getFaces();
            for (let j = 0, j_l = aFaces.length; j < j_l; j++) {
                let aFace = aFaces[j];
                let aFaceData = aFace.data;
                if (undefined !== aFaceData) {
                    let aMaterialID = aFaceData.materialID;
                    if ((undefined !== aMaterialID) && ('' !== aMaterialID)) {
                        if (undefined === this.mRefractiveIndices[aMaterialID]) {
                            let aMaterial = await MaterialDataLoader.instance.getSingleFullData({
                                number_id: aMaterialID
                            });

                            let aRefractiveIndices = new Array<number>();
                            for (let j = 0, j_l = this.mWavelengths.length; j < j_l; j++) {
                                let n = MaterialUtils.getN(aMaterial, this.mWavelengths[j]);
                                aRefractiveIndices.push(n);
                                this.mRefractiveIndices[aMaterialID] = aRefractiveIndices;
                            }
                        }
                    }
                }
            }
        }
    }
    //______________________________________________________________________________________________
}
