import { ePlaneType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { t3DArray, iClientPoint } from "../../_context/_interfaces/Interfaces";
import { Behavior } from "./Behavior";
import { SceneContext } from "../../scene/SceneContext";
import { Group } from "../../ui/forms/Group";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { Euler, Matrix4, Quaternion, Vector2, Vector3 } from "three";
import { Part } from "../Part";
import { iAxis, iFace } from "../PartInterfaces";

export interface iPose {
    position: t3DArray;
    rotationEuler: t3DArray;
}

export class MovementBehavior extends Behavior {

    private static MIN_DELTA_TO_MOVE: number = 3;
    private static mClientClick = new Vector2(0, 0);

    //__________________________________________________________________________________________
    public static distBetweenAxes(pAxis1: iAxis, pAxis2: iAxis) {
        let aAxis1Mat = OP3DMathUtils.getUnscaledMatrix(pAxis1.object3D.matrixWorld);
        let aAxis2Mat = OP3DMathUtils.getUnscaledMatrix(pAxis2.object3D.matrixWorld);
        let aRelMat = new Matrix4();
        aRelMat = aRelMat.multiplyMatrices(aAxis1Mat.invert(), aAxis2Mat).invert();

        return new Vector3().setFromMatrixPosition(aRelMat);
    }
    //__________________________________________________________________________________________
    public static worldPosition(pPart: Part) {
        return MovementBehavior.distBetweenAxes(pPart.workingLCS, Op3dContext.GCS.workingLCS);
    }
    //__________________________________________________________________________________________
    public static copyPose(pFrom: Part, pTo: Part) {
        let aPos = MovementBehavior.distToRef(pFrom);
        let aRot = (undefined !== pFrom.refRotation) ? pFrom.refRotation : new Euler();

        let aRCS = pFrom.refCS;
        if (undefined !== aRCS) {
            pTo.setRefrence(aRCS);
        }

        MovementBehavior.transformPart(pTo, aPos, aRot);
    }
    //__________________________________________________________________________________________
    public static distToRef(pPart: Part) {
        let aRefCS = pPart.refCS;
        if (null == aRefCS) {
            return null;
        }

        let workingCS = pPart.workingLCS;
        let refCS = aRefCS.cs;

        return MovementBehavior.distBetweenAxes(workingCS, refCS);
    }
    //__________________________________________________________________________________________
    public static moveOptixPart(pPart: Part, pPoint: iClientPoint): void {

        let aPoint = pPoint;
        let aMousePos = SceneContext.POSITION_GENERATOR.getMousePointPlaneIntersection(
            aPoint, ePlaneType.HORIZONTAL, pPart);

        if (null != aMousePos) {
            let aDeltaX = Math.abs(pPoint.clientX - this.mClientClick.x);
            let aDeltaY = Math.abs(pPoint.clientY - this.mClientClick.y);

            if ((aDeltaX > MovementBehavior.MIN_DELTA_TO_MOVE) &&
                (aDeltaY > MovementBehavior.MIN_DELTA_TO_MOVE)) {


                MovementBehavior._move(pPart, aMousePos, false);
            }
        }

        Op3dContext.PARTS_MANAGER.updateLinkedParts();
    }
    //__________________________________________________________________________________________
    public static addToPos(pPart: Part, pDelta: Vector3, pUpdateLinked: boolean = true) {
        pPart.visibleObj.position.add(pDelta);

        if ((true == pUpdateLinked) && (null != pPart.linkedParts)) {
            for (let i = 0; i < pPart.linkedParts.length; i++) {
                MovementBehavior.addToPos(pPart.linkedParts[i], pDelta);
            }
        }
        SceneContext.OP3D_SCENE.activateRenderer();
        Op3dContext.PARTS_MANAGER.updateLinkedParts();
    }
    //__________________________________________________________________________________________
    public static getTranslatedPosition(pPart: Part, pPos: Vector3, pFace: iFace) {
        let aSelectedCSPos = pFace.axes[0].object3D.getWorldPosition(new Vector3());
        let aRef = pPart.workingLCS.object3D;

        let aRefPos = aRef.getWorldPosition(new Vector3());
        let aRefRot = new Matrix4().extractRotation(aRef.matrixWorld);
        let aRefRotInvert = aRefRot.clone().invert();
        aSelectedCSPos.sub(aRefPos);
        aSelectedCSPos.applyMatrix4(aRefRotInvert);

        let aNewPos = pPos.clone();
        let aDeltaPos = aNewPos.sub(aSelectedCSPos).applyMatrix4(aRefRot);
        return aDeltaPos;
    }
    //__________________________________________________________________________________________
    public static resetTransforms(pPart: Part) {
        MovementBehavior.transformPart(pPart, new Vector3(), new Euler());
    }
    //__________________________________________________________________________________________
    public static transformPart(pPart: Part, pPos: Vector3, pRot: Euler) {
        pPart.refRotation = pRot.clone();

        let aLinkedPartsFunctions = new Array<Function>();
        let aLinkedParts = pPart.linkedParts;
        if (null != aLinkedParts) {
            for (let i = 0; i < aLinkedParts.length; i++) {
                let aPos = MovementBehavior.distToRef(aLinkedParts[i]);
                let aRot = aLinkedParts[i].refRotation;

                aLinkedPartsFunctions.push(() => MovementBehavior.transformPart(aLinkedParts[i],
                    aPos, aRot));
            }
        }

        let lcsM = pPart.workingLCS.object3D.matrixWorld.clone();
        let s = new Vector3().setFromMatrixScale(lcsM);
        let q = new Quaternion().setFromEuler(pRot);
        let m = new Matrix4().compose(pPos, q, s);
        let refMat = OP3DMathUtils.getUnscaledMatrix(pPart.refCS.cs.object3D.matrixWorld);
        let t = m.multiplyMatrices(refMat, m);
        t.multiply(lcsM.invert());
        //pPart.visibleObj.applyMatrix4(lcsM.invert());
        pPart.visibleObj.applyMatrix4(t);
        pPart.visibleObj.updateMatrixWorld(true);

        for (let i = 0; i < aLinkedPartsFunctions.length; i++) {
            aLinkedPartsFunctions[i]();
        }
    }
    //__________________________________________________________________________________________
    public static moveToPos(pPart: Part, pNewPos: Vector3) {
        this._movePart(pPart, pNewPos, true);
    }
    //__________________________________________________________________________________________
    private static _move(pPart: Part, pNewPos: Vector3, pIsHeightMovement: boolean) {
        MovementBehavior._movePart(pPart, pNewPos, pIsHeightMovement);
    }
    //__________________________________________________________________________________________
    public static snapToGrid(pPart: Part) {
        let aPos = pPart.visibleObj.position.clone();
        let closestPos = SceneContext.GRID_MANAGER.addToGrid(aPos);
        MovementBehavior._movePart(pPart, closestPos)
    }
    //__________________________________________________________________________________________
    /**
     * this function is public for automation use
     */
    //__________________________________________________________________________________________
    public static _movePart(pPart: Part, pNewPos: Vector3, pIsHeight: boolean = false) {
        let aOldPos = pPart.visibleObj.position.clone();

        let aNewPos = pNewPos.clone()
        let aDeltaPos = new Vector3().subVectors(aNewPos, aOldPos);

        if (false == pIsHeight) {
            aDeltaPos.y = 0
        }
        let aPart = Group.instance.findSelectedPart(pPart)

        MovementBehavior.addToPos(aPart, aDeltaPos);

        pPart.update();
    }
    //__________________________________________________________________________________________
}
