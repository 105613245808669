import { JSONLoader } from "../../loaders/JsonLoader";
import { EventManager } from "../../oc/events/EventManager";
import { AnalyticsEventsContext } from "../_context/AnalyticsEventsContext";
import { eErrorType, eDataPermission, eBrandTypes } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { Links } from "../_context/Links";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { iHash, iSideBarMainSection, iMaterialMetaDataVO, iSideBarCategoryQuery } from "../_context/_interfaces/Interfaces";
import { DataUtils } from "../_utils/DataUtils";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { iPartMongoDB } from "../parts/PartInterfaces";
import { PartsCatalog } from "../parts/_parts_assets/PartsCatalog";
import { ServerContext } from "../server/ServerContext";
import { iFilterFinderResult } from "../ui/forms/tools/FilterFinderContext";
import { GeneralVO, iGeneralVOData } from "./VO/GeneralVO";
import { PartVO } from "./VO/PartVO";

export enum eVERSIONS_TYPE {
    snellius = "snellius"
}

export class DataManager {

    private static OPTICS_IMAGES_PATHS: iHash<string> = {};

    private mCategories: Array<iSideBarMainSection>;
    private mPartsData: iHash<PartVO>;
    private mMaterialsData: Array<iMaterialMetaDataVO>;
    private mBrands: iHash<Array<string>> = {};
    private mCoatingsHash: iHash<iFilterFinderResult> = {};
    private mIsReady: boolean = false;
    private mConfigurations: { snellius: string }

    //__________________________________________________________________________________________
    constructor() {
        this._init();
    }
    //__________________________________________________________________________________________
    public get isReady() {
        return this.mIsReady;
    }
    //__________________________________________________________________________________________
    public getImagePathNew(pType: string, pSubType: string, pVendor: string = "DEFAULT", pIsRect: boolean = false) {

        let aPath: string;
        let aType = pType.toUpperCase();
        let aSubType = pIsRect ?
            pSubType.toUpperCase() + " RECT" : pSubType.toUpperCase();
        let aVendor = pVendor.toUpperCase();

        if ((null != DataManager.OPTICS_IMAGES_PATHS[aType]) &&
            (null != DataManager.OPTICS_IMAGES_PATHS[aType][aSubType])) {

            let aImage = (null != DataManager.OPTICS_IMAGES_PATHS[aType][aSubType][aVendor]) ?
                DataManager.OPTICS_IMAGES_PATHS[aType][aSubType][aVendor] :
                DataManager.OPTICS_IMAGES_PATHS[aType][aSubType]['DEFAULT'];

            aPath = ServerContext.optic_images + aImage;
        }

        return aPath;
    }
    //__________________________________________________________________________________________
    public getImagePath(pType: string, pSubType: string,
        pIsRect: boolean) {

        let aPath: string;
        let aSubType = pIsRect ?
            pSubType.toUpperCase() + " RECT" : pSubType.toUpperCase();

        if (DataManager.OPTICS_IMAGES_PATHS[pType.toUpperCase()] && DataManager.OPTICS_IMAGES_PATHS[pType.toUpperCase()][aSubType]) {
            aPath = ServerContext.optic_images + DataManager.OPTICS_IMAGES_PATHS[pType.toUpperCase()][aSubType]['DEFAULT']
        }

        return aPath;
    }
    //__________________________________________________________________________________________
    private _getFilterFinderResults(pFilters: Array<string>,) {
        let aResults: iHash<iFilterFinderResult> = {};
        let aToSearch = new Array<string>();

        for (let i = 0; i < pFilters.length; i++) {
            let aCoating = this.mCoatingsHash[pFilters[i]];
            if (aCoating == null) {
                aToSearch.push(pFilters[i]);
            } else {
                aResults[pFilters[i]] = aCoating;
            }
        }

        return { toSearch: aToSearch, results: aResults };
    }
    //__________________________________________________________________________________________
    public async getFiltersData(pFilters: Array<string>, pFromServer: boolean = false) {
        let aData = this._getFilterFinderResults(pFilters);

        if (aData.toSearch.length == 0 || pFromServer) {
            return aData.results;

        } else {
            ////let aCallback = () => this.getFiltersData(pFilters, true);

            const aRes = await ServerContext.SERVER.getFilterFinderData({ names: aData.toSearch });
            if (aRes.success) {
                this._onFiltersReceived(aRes.data);
                return this.getFiltersData(pFilters, true);
            } else {
                this._onErrorGettingsCoatingData(aRes.data);
            }
        }
    }
    //__________________________________________________________________________________________
    private _onErrorGettingsCoatingData(pError: any) {
        MessagesHandler.ON_ERROR_PROGRAM({ error: pError, show_message: false, type: eErrorType.INTERNAL_ERROR });
    }
    //__________________________________________________________________________________________
    private _onFiltersReceived(pData: any) {
        let aFilters: Array<iFilterFinderResult> = pData != null &&
            pData.data != null ? pData.data : [];

        for (let i = 0; i < aFilters.length; i++) {
            let aCoatingData = aFilters[i];
            this.mCoatingsHash[aCoatingData.systemName] = aCoatingData;
        }
    }
    //__________________________________________________________________________________________
    // public calculate_ray_data_file(pRaysCount: number, pCircles: number, pRadius: number) {
    //     let aAngleDelta = 2 * Math.PI / pRaysCount;
    //     for (let i = 0; i < pRaysCount; i++) {
    //         for (let j = 1; j < pCircles; j++) {
    //             let aNewPos = new Vector3();
    //             let aTheta = aAngleDelta * i;
    //             aNewPos.x = (j / pCircles) * pRadius * Math.cos(aTheta);
    //             aNewPos.y = (j / pCircles) * pRadius * Math.sin(aTheta);
    //             console.log(aNewPos);
    //         }
    //     }
    // }
    //__________________________________________________________________________________________
    //private _getCSV() {
    // let aData = {
    // }
    // let aStr = "wl,r_p,r_s,t_p,t_s";
    // let aLength = Math.max(
    //     aData.R_P.length,
    //     aData.R_S.length,
    //     aData.T_P.length,
    //     aData.T_S.length);

    // for (let i = 0; i < aLength; i++) {
    //     let aWl = aData.wl[i] / 10;
    //     aStr += aWl + "," + aData.R_P[i] + "," + aData.R_S[i] +
    //         "," + aData.T_P[i] + "," + aData.T_S[i] + "\r\n";
    // }

    // FileUtils.downloadFile({
    //     content: aStr,
    //     fullfileName: "UnionOptics_WZD0120-8421-M25-4-400nm_800mn.csv",
    //     mimeType: "text/csv"
    // });
    //}
    //__________________________________________________________________________________________
    private async _init() {
        this._loadCategoriesJSON();
        this._loadPartsJSON();
        this._loadOpticsImagesJSON();
        await this._loadVersionsFile();

        // EventManager.addEventListener(EventsContext.FILTER_SIDE_BAR_PARTS,
        //     (pData: EventBase<iSideBarCategoryQuery>) =>
        //         this.onFilterParts(pData.data), this);

        await PartsCatalog.instance.loadAxisModel();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    public getPartVOByUrl(pUrl: string) {
        for (let partID in this.mPartsData) {
            if (this.mPartsData[partID].isCustom) {
                if (this.mPartsData[partID].baseUrl == pUrl) {
                    return this.mPartsData[partID];
                }
            } else {
                if (this.mPartsData[partID].url == pUrl) {
                    return this.mPartsData[partID];
                }
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public async getMaterialByNumberID(pNumberID: string): Promise<iMaterialMetaDataVO> {
        let aMaterials = await this.getMaterials();
        for (let i = 0; i < aMaterials.length; i++) {
            if (aMaterials[i].number_id == pNumberID) {
                return aMaterials[i];
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public async getMaterialByName(pMaterialName: string): Promise<iMaterialMetaDataVO> {
        let aMaterials = await this.getMaterials();
        for (let i = 0; i < aMaterials.length; i++) {
            if (aMaterials[i].name == pMaterialName) {
                return aMaterials[i];
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public async getMaterialByOriginalName(pMaterialOriginalName: string) {
        let aMaterials = await this.getMaterials();
        return aMaterials.find((material) => material.original_name == pMaterialOriginalName);
    }
    //__________________________________________________________________________________________
    public getMaterialNameByNumberID(pNumberID: string) {
        const aRes = this.mMaterialsData.find((material) => material.number_id == pNumberID);
        if (aRes != null) {
            return aRes.name;
        }
        return null;
    }
    //__________________________________________________________________________________________
    public async getMaterialNumberIDByName(pMaterialName: string) {
        let aMaterials = await this.getMaterials();
        const aRes = aMaterials.find((material) => material.name == pMaterialName);
        if (aRes != null) {
            return aRes.number_id;
        }
        return null;
    }
    //__________________________________________________________________________________________
    public async getMaterials(): Promise<Array<iMaterialMetaDataVO>> {
        if (this.mMaterialsData == null) {
            await this._loadMaterials();
        }

        return this.mMaterialsData;
    }
    //__________________________________________________________________________________________
    public async getMaterial3DOptixName(pMaterialName: string) {
        let aMaterials = await this.getMaterials();
        const aRes = aMaterials.find((material) => material.original_name == pMaterialName);
        if (aRes != null) {
            return aRes.name;
        }

        return null;
    }
    //__________________________________________________________________________________________
    public getPartVOById(pId: string) {
        return DataUtils.findInObject(this.mPartsData, (partVO) => pId === partVO.id);
    }
    //__________________________________________________________________________________________
    public getPartVOByNumberID(pNumberID: string) {
        return this.mPartsData[pNumberID];
    }
    //__________________________________________________________________________________________
    public async prepareDataManager() {
        while (this.mPartsData == null) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    public async onFilterParts(pData: iSideBarCategoryQuery) {
        await this.getPartsData();

        let aMainSection = pData.main_section;
        let aSection = pData.section;
        let aCategory = pData.category;
        let aSubCategory = pData.subCategory;
        let aSearchedWord = pData.searchedWord;

        if (aSearchedWord != "") {
            aCategory = "";
            aSubCategory = "";
        }

        AnalyticsEventsContext.triggerPartsSearchAnalyticsEvent(aMainSection, aSection,
            aCategory, aSubCategory, aSearchedWord);

        let aPartsToSearch = Op3dUtils.filterHash<PartVO>(this.mPartsData,
            (pPartVO) => ((true == pPartVO.isSideBarPart) &&
                ((eDataPermission.PUBLIC == pPartVO.permission) ||
                    (Op3dContext.USER_VO.id == pPartVO.owner))));

        let aFilteredParts = new Array<GeneralVO>();
        if ("" != aSearchedWord) {
            for (let partID in aPartsToSearch) {
                if (true == aPartsToSearch[partID].isMatchSearch(aSearchedWord)) {
                    aFilteredParts.push(aPartsToSearch[partID]);
                }
            }
        } else {
            for (let partID in aPartsToSearch) {
                if ((aMainSection.toLowerCase() ==
                    aPartsToSearch[partID].mainSection.toLowerCase()) &&
                    (aSection.toLowerCase() ==
                        aPartsToSearch[partID].section.toLowerCase()) &&
                    (aCategory.toLowerCase() ==
                        aPartsToSearch[partID].category.toLowerCase()) &&
                    (aSubCategory.toLowerCase() ==
                        aPartsToSearch[partID].subCategory.toLowerCase())) {
                    aFilteredParts.push(aPartsToSearch[partID]);
                }
            }
        }

        EventManager.dispatchEvent(EventsContext.UPDATE_SIDE_BAR, this,
            aFilteredParts);
    }
    //__________________________________________________________________________________________
    private async _loadMaterials() {

        let aRes = await ServerContext.SERVER.getManyMaterials();
        if (aRes.success) {
            this.mMaterialsData = aRes.data;
        } else {
            this.mMaterialsData = [];
        }
    }
    //__________________________________________________________________________________________
    private async _loadOpticsImagesJSON() {
        let aRes = await new JSONLoader().load(Links.OPTICS_IMAGES_PATHS_JSON);
        if (aRes.success) {
            DataManager.OPTICS_IMAGES_PATHS = JSON.parse(aRes.data);
        } else {
            MessagesHandler.ON_ERROR_PROGRAM({
                type: eErrorType.ASSET_LOADING,
                show_message: false,

                error: aRes.data
            });
        }
    }
    //__________________________________________________________________________________________
    private async _loadCategoriesJSON() {
        let aUrl = `${Links.CATEGORIES_JSON}?V_${Op3dContext.VERSION_NUM}`
        let aRes = await new JSONLoader().load(aUrl);
        if (aRes.success) {
            this._onLoadCategoriesJSON(aRes.data)
        }
        else {
            Op3dContext.USER_VO.isEmployeeUser && console.log("Error loading json. Path: " + aUrl);
        }
    }
    //__________________________________________________________________________________________
    public async getBrands(pBrandType: eBrandTypes) {
        if (this.mBrands[pBrandType] != null) {
            return this.mBrands[pBrandType];
        }

        switch (pBrandType) {
            case eBrandTypes.OPTIC: {
                let aRes = await ServerContext.SERVER.getOpticBrands();
                if (aRes.success) {
                    this.mBrands[eBrandTypes.OPTIC] = aRes.data;
                } else {
                    this.mBrands[eBrandTypes.OPTIC] = [];
                }
                break;
            }
            case eBrandTypes.LIGHT_SOURCE: {
                let aRes = await ServerContext.SERVER.getLightSourceBrands();
                if (aRes.success) {
                    this.mBrands[eBrandTypes.LIGHT_SOURCE] = aRes.data;
                } else {
                    this.mBrands[eBrandTypes.LIGHT_SOURCE] = [];
                }
                break;

            }
            case eBrandTypes.OPTOMECHANIC: {
                let aRes = await ServerContext.SERVER.getOpticBrands();
                if (aRes.success) {
                    this.mBrands[eBrandTypes.OPTOMECHANIC] = aRes.data;
                } else {
                    this.mBrands[eBrandTypes.OPTOMECHANIC] = [];
                }
                break;
            }
        }

        let aFiltered = this.mBrands[pBrandType].filter(brand => brand != "" && brand != null);
        this.mBrands[pBrandType] = aFiltered;
        return this.mBrands[pBrandType];
    }
    //__________________________________________________________________________________________
    private _onLoadCategoriesJSON(pData: any) {
        let aSidebarSectionsData = (JSON.parse(pData)).main_sections as Array<iSideBarMainSection>;
        this.mCategories = aSidebarSectionsData.slice();
    }
    //__________________________________________________________________________________________
    private async _loadVersionsFile() {
        let aFileNum = Math.floor(Math.random() * Math.floor(100));
        let aPath = `${Links.CONFIGURATION}?V_${aFileNum}`;
        let aRes = await new JSONLoader().load(aPath);
        if (aRes.success) {
            this._onLoadVersionJSON(aRes.data)
        }
        else {
            Op3dContext.USER_VO.isEmployeeUser && console.log("Error loading versions. Path:" + aPath);
        };
    }
    //__________________________________________________________________________________________
    private _onLoadVersionJSON(pContent: string) {
        this.mConfigurations = JSON.parse(pContent);
        Op3dContext.USER_VO.isEmployeeUser && console.log("Snellius version ", this.mConfigurations.snellius)

    }
    //__________________________________________________________________________________________
    public get snelliusVersion() {
        return this.mConfigurations[eVERSIONS_TYPE.snellius];
    }
    //__________________________________________________________________________________________
    private async _loadPartsJSON() {

        this.mPartsData = {};

        let aRes = await ServerContext.SERVER.getPartsInfo();

        if (aRes.success == true) {
            let aPartsInfo = aRes.data

            for (let i = 0; i < aPartsInfo.length; i++) {
                let aGeneralVO = aPartsInfo[i].info;
                let aCurrId = aPartsInfo[i].number_id;
                aGeneralVO.number_id = aCurrId;
                aGeneralVO.owner = aPartsInfo[i].owner;
                aGeneralVO.permission = aPartsInfo[i].permission;
                aGeneralVO.isOptix = true;
                // this.mPartsData[aGeneralVO.id] = new PartVO(aGeneralVO);
                if (aPartsInfo[i].owner == Op3dContext.USER_VO.id && aGeneralVO.sideBarPart === true) {
                    this.mPartsData[aGeneralVO.number_id] = new PartVO(aGeneralVO);
                    continue
                }

                switch (aGeneralVO.id) {
                    case 'B2448F':
                    case 'B3036F':
                    case 'B7590A':
                    case 'R1111':
                    case 'L1111':
                    case 'D1012':
                    case 'L1013':
                    case 'L1014':
                    case 'L1020':
                    case 'L1012':
                    case 'L1011':
                        this.mPartsData[aGeneralVO.number_id] = new PartVO(aGeneralVO);
                        break;
                    default:
                        if (aGeneralVO.id.includes('_Assembly')) {
                            this.mPartsData[aGeneralVO.number_id] = new PartVO(aGeneralVO);
                        }

                }
            }
        }
    }
    //__________________________________________________________________________________________
    public addToPartsData(pGenerealVO: iPartMongoDB) {
        let aGeneralVO = pGenerealVO.info as iGeneralVOData
        aGeneralVO.number_id = pGenerealVO.number_id;
        aGeneralVO.owner = pGenerealVO.owner;
        aGeneralVO.isOptix = true
        aGeneralVO.permission = pGenerealVO.permission;
        this.mPartsData[pGenerealVO.info.number_id] = new PartVO(aGeneralVO);
    }
    //__________________________________________________________________________________________
    public removeFromPartsData(pId: string) {
        delete this.mPartsData[pId];
    }
    //__________________________________________________________________________________________
    public async getPartsData() {
        await Op3dContext.wait(() => { return (null != this.mPartsData) });
        return this.mPartsData;
    }
    //__________________________________________________________________________________________
    public async getCategories() {
        await Op3dContext.wait(() => (null != this.mCategories));

        return this.mCategories;
    }
    //__________________________________________________________________________________________

}
