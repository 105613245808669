import { Op3dContext } from "../../../_context/Op3dContext";
import { iAppearanceData } from "../../../parts/behaviors/LightSourceContext";
import { SceneContext } from "../../../scene/SceneContext";
import { eSmRaysKind } from "../../../simulation/SimulationContext";
import { SimulationRunner } from "../../../simulation/SimulationRunner";
import { ColorUtils } from "../../ColorUtils";
import { ViewUtils } from "../../ViewUtils";
import { PartInfoSection } from "../PartInfoSection";

export class piAppearanceSection extends PartInfoSection<iAppearanceData> {

    private mParams: { onChange: (pData: iAppearanceData) => void };
    private mPointSourceRadius: HTMLInputElement;
    private mMaterialColor: HTMLInputElement;
    private mUserRaysColor: HTMLInputElement;
    private mMaterialAlpha: HTMLInputElement;

    constructor(pContainer: HTMLElement,
        pParams: { onChange: (pData: iAppearanceData) => void }) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_appearance_light_source.html'
        });

        this.mParams = pParams;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mPointSourceRadius = this._getPart("point-source-model-size") as HTMLInputElement;
        this.mPointSourceRadius.addEventListener("change", () => this._onPointSourceModelChanged());

        this.mMaterialAlpha = this._getPart("material-alpha") as HTMLInputElement;
        this.mMaterialAlpha.addEventListener("change", () => this._onMaterialColorChanged());

        this.mMaterialColor = this._getPart("material-color") as HTMLInputElement;
        this.mMaterialColor.addEventListener("change", () => this._onMaterialColorChanged());

        this.mUserRaysColor = this._getPart("user-rays-color") as HTMLInputElement;
        this.mUserRaysColor.addEventListener("change", () => this._onUserRaysColorChanged());


    }
    //__________________________________________________________________________________________
    public onChangeSourceType(pSourceType: eSmRaysKind) {
        this.mContainer.setAttribute("kind", pSourceType);


    }
    //__________________________________________________________________________________________
    public hideFieldsForNonUserDefined(pIsUserDefined: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mMaterialColor.parentElement, pIsUserDefined);
        ViewUtils.setElementVisibilityByDNone(this.mMaterialAlpha.parentElement, pIsUserDefined);
        ViewUtils.setElementVisibilityByDNone(this.mPointSourceRadius.parentElement, pIsUserDefined);
    }
    //__________________________________________________________________________________________
    public getData(): iAppearanceData {
        return ({
            model_radius: +this.mPointSourceRadius.value,
            color: this.mMaterialColor.value,
            alpha: (+this.mMaterialAlpha.value) / 100,
            rays_color: this.mUserRaysColor.value
        });
    }
    //__________________________________________________________________________________________
    private _onPointSourceModelChanged() {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if (aPart == null) { return; }
        Op3dContext.SCENE_HISTORY.addToHistory();
        let aRadius = +this.mPointSourceRadius.value;
        this.mParams.onChange({ model_radius: aRadius });
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _onMaterialColorChanged() {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if (aPart == null) { return; }

        let aColor = this.mMaterialColor.value;
        let aAlpha = (+this.mMaterialAlpha.value) / 100;
        let aRadius = (+this.mPointSourceRadius.value);

        Op3dContext.SCENE_HISTORY.addToHistory();
        let aLaserBehavior = aPart.getBehavior("laserBehavior");
        aLaserBehavior.changeMaterialColor(aAlpha, aColor, aPart);
        this.mParams.onChange({ alpha: aAlpha, color: aColor, model_radius: aRadius });
        SceneContext.OP3D_SCENE.activateRenderer();
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _onUserRaysColorChanged() {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if (aPart == null) { return; }
        this.mParams.onChange({ rays_color: this.mUserRaysColor.value });

        Op3dContext.SCENE_HISTORY.addToHistory();
        SceneContext.OP3D_SCENE.activateRenderer();
        SimulationRunner.instance.draw();
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    public fillSection(pData: iAppearanceData) {
        this._fillSection(pData);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iAppearanceData): void {
        let aColor: string;
        let aAlpha = pData.alpha != null ? pData.alpha : 1;
        let aModelRadius = pData.model_radius != null ? pData.model_radius : 3;

        if (pData.color != null) {
            aColor = pData.color;
        } else {
            let aFaces = Op3dContext.PARTS_MANAGER.selectedPart.getFaces();
            let aColorHex = (aFaces[0].visualization.mesh.material as any).color.getHex();
            aColor = ColorUtils.numToHEXColor(aColorHex);
        }

        this.mPointSourceRadius.value = aModelRadius.toString();
        this.mMaterialColor.value = aColor;
        this.mMaterialAlpha.value = (aAlpha * 100).toString();
        this.mUserRaysColor.value = pData.rays_color;
    }
    //__________________________________________________________________________________________
    public get container() {
        return this.mContainer;
    }
    //__________________________________________________________________________________________
}
