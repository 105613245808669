
import { eOMPFilterName } from "../ui/menus/_search/_filterHandlers/OptomechanicFilterHandler";

export enum eOpticShape {
    CIRCULAR = 'Circular',
    RECTANGULAR = 'Rectangular',
    PRISM = 'Prism',
    SQUARE = 'Square',
    CUBE = 'Cube',
    CYLINDRICAL = 'Cylindrical',
    D_SHAPE = 'D-Shape',
    CIRCULAR_RECTANGULAR = 'Circular/Rectangular',
}



export enum eOptomechanicTypeNames {
    ALL = "All",
    OPTICS_MOUNT = "Optics Mount",
    MOUNT_ACCESSORIES = "Mount Accessories",
}
export enum eMountingData {
    _1 = "8-32",
    _2 = "M4",
    _3 = "4-40",
    _4 = "M3",
    _5 = "M6",
    _6 = "1/4in",
    _7 = '8-32 (M4) CLR',
    _8 = '8-32/M4'
}
export enum eDriveType {
    M3_ADJUSTMENT_SCREW = "M3 Adjustment Screw",
    _100TPI = "100TPI",
    MICROMETER = "Micrometer",
    DIFFERENTIAL = "Differential",
    LEAD_SCREW = "Lead screw",

}
export const THREAD = [
    'M5.5 x 0.5',
    'M6.5 x 0.5',
    'M7.5 x 0.5',
    'M8.5 x 0.5',
    'M9.5 x 0.5',
    'M10.5 x 0.5',
    'M11.5 x 0.5',
    'M12.5 x 0.5',
    'SM05',
    'M13.5 x 0.5',
    'M14.5 x 0.5',
    'M15.5 x 0.5',
    'M16.5 x 0.5',
    'M17.5 x 0.5',
    '0.750in-40',
    'M19.5 x 0.5',
    '0.800in-36',
    'M20.5 x 0.5',
    '1.00in-32',
    'SM1',
    'SM30',
    'M31.5 x 0.5',
    'M32.5 x 0.5',
    'M33.5 x 0.5',
    'M34.5 x 0.5',
    'M35.5 x 0.5',
    'M36.5 x 0.5',
    'M37.5 x 0.5',
    'M38 x 0.5',
    'SM1.5',
    'M39.5 x 0.5',
    'M40.5 x 0.5',
    'M41.5 x 0.5',
    'M42.5 x 0.5',
    'M43.5 x 0.5',
    'M44.5 x 0.5',
    'M45.5 x 0.5',
    'M46.5 x 0.5',
    'M47.5 x 0.5',
    'M48.5 x 0.5',
    'SM2',
    'M75.5 x 0.5',
    'SM3',
    'M100.75 x 0.5',
    'SM4',
    '3/16in',
    '0.535-40',
    '1.035-40',
    '2.035-40',
    '8-32',
    'M4',
    'M6',
]


export enum eCompatibleLensTubes {
    SM0_5 = "SM0.5",
    SM1 = "SM1",
    SM2 = "SM2",
}

export enum eBrands {
    THORLABS = "Thorlabs",
    EDMUND_OPTICS = "Edmund Optics",
    MKS_NEWPORT = "MKS/Newport",
    OPTOSIGMA = "OptoSigma"

}
export enum eMovementTypes {
    TIP_TILT = "Tip-Tilt",
    TIP_TILT_3_HEX = "Tip-Tilt (3-Hex)",
    FIX = "Fix",
    TILT = 'Tilt',
    PITCH = 'Pitch',
    ROTATION = 'Rotation'
}
export enum eMovementTypesAccessories {
    LINEAR_X = "Linear (X)",
    LINEAR_X_Y = "Linear (XY)",
    LINEAR_X_Y_Z = "Linear (XYZ)",
}
export enum eCoating {
    BLACK_ANODIZED = "Black Anodized",
    ANODIZED = "Anodized",
    UNANODIZED = "Unanodized",
    BLACK_ANODIZED_ALUMINUM = 'Black Anodized Aluminum',
    BRAS_THREAD_BUSHINGS = 'Brass Thread Bushings',
    SAINLESS_STEEL_SCREWS = 'Stainless Steel Screws',
    ALUMINUM_PLATES = 'Aluminum Plates',
    STAINLESS_STEEL = 'Stainless Steel',


}
export enum eOrientationTypes {
    RIGHT_HANDED = 'Right-handed',
    LEFT_HANDED = 'Left-handed'

}

export enum eKeyFeatures {
    SMALL_DESIGN = "Small Design",
    LOCKABLE = "Lockable",
    VACUUM_COMPATIBLE = "Vacuum Compatible",
    LOW_PROFILE_ADJUSTERS = "Low Profile Adjusters",
    TOP_ADJUST = "Top Ajust",
    LOW_DISTORTION = "Low Distortion",
    CLEAR_EDGE = "Clear-Edge",
    THREADED_PLATFORM = "Threaded Platform",
    CORNER = "Corner",
    FRONT_LOADING = "Front-Loading",
    HIGH_RESOLUTION = "High Resolution",
    REAR_LOADING = "Rear Loading",
    ROTATION_WITHOUT_TRANSLATION = "Rotation without Translation",

}
export enum eKeyFeaturesAccessories {
    DUAL = "Dual",
    MICROMETER = "Micrometer",
    DIFFERENTIAL = "Differential",

}

export class OptomechanicContext {

    //Optics Mount Subtypes
    public static _Fixed_Optical_Mount = "Fixed Optic Mount";
    public static _Kinematic_Optical_Mount = "Kinematic Optic Mount";
    public static _Kinematic_Platform_Mount = "Kinematic Platform Mount";
    public static _Rotation_Mount = "Rotation Mount";
    //Mount Accessories Subtypes
    public static _Optical_Filter_Mount = "Optical Filter Mount";
    public static _Translation_Optics_Mount = "Translation Optics Mount";
    public static _Lens_Tube_Mount = "Lens Tube Mount";
    public static _Irises_Apertures = "Irises/Apertures";



    //Material
    public static _Aluminum = "Aluminum";
    public static _Stainless_Steel = "Stainless Steel";
    public static _Plastic = "Plastic";
    public static _Blackened_Brass = "Blackened Brass";
    public static _Brass_Spring_Steel = "Brass-Spring Steel";
    public static _Brass = "Brass";

    //__________________________________________________________________________________________
    public static getMountOpticsSubtypes() {
        return [OptomechanicContext._Fixed_Optical_Mount,
        OptomechanicContext._Kinematic_Optical_Mount,
        OptomechanicContext._Kinematic_Platform_Mount,
        OptomechanicContext._Rotation_Mount
        ]
    }
    //__________________________________________________________________________________________
    public static getMountAccessoriesSubtypes() {
        return [OptomechanicContext._Optical_Filter_Mount,
        OptomechanicContext._Translation_Optics_Mount,
        OptomechanicContext._Lens_Tube_Mount,
        OptomechanicContext._Irises_Apertures,
        ]
    }
    //__________________________________________________________________________________________
    public static getMaterails() {
        return [OptomechanicContext._Stainless_Steel,
        OptomechanicContext._Aluminum,
        OptomechanicContext._Plastic,
        OptomechanicContext._Brass_Spring_Steel,
        OptomechanicContext._Brass,
        OptomechanicContext._Blackened_Brass]
    }
    //__________________________________________________________________________________________
    public static getAccessoriesSliderFilters() {
        return [
            {
                path: eOMPFilterName.optic_diameter,
                name: 'Optic diameter',
                min: 0,
                max: 180,
            },
            {
                path: eOMPFilterName.thickness,
                name: 'Thickness',
                min: 0,
                max: 50,
            },
            {
                path: eOMPFilterName.min_aperture,
                name: 'Aperture',
                min: 0.6,
                max: 225,
            },
            {
                path: eOMPFilterName.drive_mechanism_per_revolution,
                name: 'Drive Mechanism',
                min: 0,
                max: 500,
                unit: 'um per/r'
            },
            {
                path: eOMPFilterName.iris_diaphragm,
                name: 'Iris Diaphragm',
                min: 0,
                max: 75,
            },
            {
                path: eOMPFilterName.inner_diameter,
                name: 'Inner Diameter',
                min: 0,
                max: 80,
            },
            {
                path: eOMPFilterName.outer_diameter,
                name: 'Outer Diameter',
                min: 10,
                max: 300,
            },
            {
                path: eOMPFilterName.a,
                name: 'A',
                min: 50,
                max: 150,
            },
            {
                path: eOMPFilterName.b,
                name: 'B',
                min: 70,
                max: 170,
            },
            {
                path: eOMPFilterName.number_of_optics,
                name: 'Number of Optics',
                min: 1,
                max: 5,
                unit: 'number'
            },
            {
                path: eOMPFilterName.od,
                name: 'OD',
                min: 0,
                max: 100,
            },
            {
                path: eOMPFilterName.travel_x,
                name: 'Travel (X)',
                min: 0,
                max: 100,
            },
            {
                path: eOMPFilterName.travel_y,
                name: 'Travel (Y)',
                min: 0,
                max: 50,
            },
        ]
    }
    //__________________________________________________________________________________________
    public static getMountsSliderFilters() {
        return [{
            path: eOMPFilterName.fine_tilt_angular_range,
            name: 'Fine Tilt Angular range (deg)',
            min: 0,
            max: 15,
            unit: 'deg'
        },
        {
            path: eOMPFilterName.adjuster_screw_thread,
            name: 'Adjuster Screw Thread ',
            min: 0,
            max: 250,
            unit: 'TPI'
        },
        {
            path: eOMPFilterName.size_of_compatible_optics,
            name: 'Size Of Compatible Optics',
            min: 3,
            max: 78,
        },
        {
            path: eOMPFilterName.tall_rectangular_optics,
            name: 'Tall Rectangular Optics ',
            min: 0,
            max: 75,
        },
        {
            path: eOMPFilterName.though_hole_dia,
            name: 'Though Hole Dia ',
            min: 10,
            max: 47,
        },
        {
            path: eOMPFilterName.maximum_depth,
            name: 'Maximum Depth ',
            min: 0,
            max: 80,
        },

        {
            path: eOMPFilterName.platform_size,
            name: 'Platform Size',
            min: 0,
            max: 69.9,
        },
        {
            path: eOMPFilterName.angular_range,
            name: 'Angular Range ',
            min: 0,
            max: 10,
            unit: 'deg'
        },
        {
            path: eOMPFilterName.adjustment_per_revolution,
            name: 'Adjustment per Revolution',
            min: 0,
            max: 15,
        },
        {
            path: eOMPFilterName.resolution_tilt,
            name: 'Resolution / Tilt (deg)',
            min: 0.1,
            max: 0.9,
            step: 0.1
        },
        {
            path: eOMPFilterName.pointing_stability,
            name: 'Pointing Stability (μrad)',
            min: 0,
            max: 6,
            unit: 'mrad'
        },
        {
            path: eOMPFilterName.optics_diameter_size,
            name: 'Optic Diameter Size',
            min: 0,
            max: 150,
        },
        {
            path: eOMPFilterName.sensitivity_arc,
            name: 'Sensitivity ',
            min: 1.8,
            max: 8.8,
            unit: 'arc sec',
            step: 0.1
        }
        ]
    }
    //__________________________________________________________________________________________
    public static getMountsMainSliderFilters() {
        return [{
            path: eOMPFilterName.thickness_of_compatible_optics,
            name: 'Thickness of Compatible Optics',
            min: 0,
            max: 75,
        },

        {
            path: eOMPFilterName.clear_aparture_diameter,
            name: 'Clear Aperture Diameter',
            min: 0,
            max: 100,
        },
        {
            path: eOMPFilterName.weight,
            name: 'Weight',
            min: 0,
            max: 1,
            unit: 'kg',
            step: 0.005
        },
        {
            path: eOMPFilterName.optical_height,
            name: 'Optical Height ',
            min: 0,
            max: 50,
        },
        ]
    }
    //__________________________________________________________________________________________
    public static getSubtypes() {
        return OptomechanicContext.getMountOpticsSubtypes().concat(OptomechanicContext.getMountAccessoriesSubtypes())
    }
    //__________________________________________________________________________________________
    public static getCompatibleLensTypes() {
        return Object.values(eCompatibleLensTubes)
    }
    //__________________________________________________________________________________________
    public static getCoatings() {
        return Object.values(eCoating)
    }
    //__________________________________________________________________________________________
    public static getAllBrands() {
        return Object.values(eBrands)
    }
    //__________________________________________________________________________________________
    public static getMountTypes() {
        return [eOptomechanicTypeNames.OPTICS_MOUNT, eOptomechanicTypeNames.MOUNT_ACCESSORIES]
    }
    //__________________________________________________________________________________________
    public static getMovementTypes() {
        return Object.values(eMovementTypes)
    }
    //__________________________________________________________________________________________
    public static getOpticShapes() {
        return Object.values(eOpticShape)
    }
    //__________________________________________________________________________________________
    public static getKeyFeatures() {
        return Object.values(eKeyFeatures)
    }
    //__________________________________________________________________________________________
    public static OPTOMECHANICS_DATA: Array<{ type: eOptomechanicTypeNames, subtypes: Array<string>, compatible_lens_tubes: Array<string>, type_of_movement: Array<string>, key_feature: Array<string> }> = [
        {
            type: eOptomechanicTypeNames.OPTICS_MOUNT,
            subtypes: [
                OptomechanicContext._Fixed_Optical_Mount,
                OptomechanicContext._Kinematic_Optical_Mount,
                OptomechanicContext._Kinematic_Platform_Mount,
                OptomechanicContext._Rotation_Mount
            ],
            compatible_lens_tubes: Object.values(eCompatibleLensTubes),
            type_of_movement: Object.values(eMovementTypes),
            key_feature: Object.values(eKeyFeatures),


        },
        {
            type: eOptomechanicTypeNames.MOUNT_ACCESSORIES,
            subtypes: [
                OptomechanicContext._Optical_Filter_Mount,
                OptomechanicContext._Translation_Optics_Mount,
                OptomechanicContext._Lens_Tube_Mount,
                OptomechanicContext._Irises_Apertures,
            ],
            compatible_lens_tubes: Object.values(eCompatibleLensTubes),
            type_of_movement: Object.values(eMovementTypesAccessories),
            key_feature: Object.values(eKeyFeaturesAccessories)
        },

    ]
}
    //__________________________________________________________________________________________

