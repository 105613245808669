import { useCallback, useContext, useState } from "react";
import { StyledOptiChat } from "../chat/OptiChat";
import OptiChatHeader from "../OptiChatHeader";
import OptiChatMessageInput from "../chat/OptiChatMessageInput";
import OptiChatConversation from "../chat/OptiChatConversation";
import OptiChatContext, {
  eChatMessageType,
  iOptiChatMessage,
} from "../../store/OptiChatContext";
import DraggableForm from "../DraggableForm";

export default function OptiChatReview() {
  const [isReviewEnabled, setReviewEnabled] = useState(true);
  const optiChatContext = useContext(OptiChatContext);

  const onCloseHandler = () => {
    optiChatContext.setReviewOpen(false);
    optiChatContext.setReviewConversation([]);
    setReviewEnabled(true);
  };

  const onAddMessage = (pMessage: string) => {
    let aNewMessage: iOptiChatMessage = {
      message: pMessage,
      typed: true,
      type: eChatMessageType.USER_MESSAGE,
    };

    let aReviewResponse: iOptiChatMessage = {
      message: "",
      typed: false,
      type: eChatMessageType.REVIEW_MESSAGE,
      userMessage: pMessage,
    };

    optiChatContext.setReviewConversation([
      ...optiChatContext.reviewConversation,
      aNewMessage,
      aReviewResponse,
    ]);
  };

  const onFinishReviewHandler = useCallback(() => {
    setReviewEnabled(false);
  }, []);

  return (
    <DraggableForm right="80" bottom="150" handle=".handle">
      <StyledOptiChat>
        <OptiChatHeader
          hasReset={false}
          className="handle"
          onClose={onCloseHandler}
          title="OPTICHAT™ - USER REVIEW"
          hasInfoBtn={false}
        />
        <OptiChatConversation
          conversation={optiChatContext.reviewConversation}
          onFinishReview={onFinishReviewHandler}
          isDisabled={false}
        />
        {isReviewEnabled && (
          <OptiChatMessageInput
            isDisabled={false}
            onSendMessage={onAddMessage}
            hasReviewBtn={false}
          />
        )}
      </StyledOptiChat>
    </DraggableForm>
  );
}
