import { Op3dContext } from "../../_context/Op3dContext";

export class ContainerLoader {

    //______________________________________________________________
    constructor(pSkinPath: string, pContainer: HTMLElement, pFunc: Function) {
        if (null == pContainer) {
            throw new Error("No container!");
        }

        let aSkin = pSkinPath + "?v=" + Op3dContext.VERSION_NUM;
        $(pContainer).load(aSkin, () => pFunc());
    }
    //______________________________________________________________
}
