import { eDataPermission } from "../_context/Enums";
import { Op3dContext } from "../_context/Op3dContext";
import { eBaseShape, OpticsContext } from "../_context/OpticsContext";
import { tGratingSide } from "../_context/Types";
import { iHash } from "../_context/_interfaces/Interfaces";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { CoatingDataLoader } from "../data/data_loader/CoatingDataLoader";
import { OpticsDataLoader } from "../data/data_loader/OpticsDataLoader";
import { UnitHandler } from "../units/UnitsHandler";
import { Parser, eManipulationType } from "./Parser";
import { ParserContext } from "./ParserContext";


export class OpticsParser extends Parser<iOpticsVO> {
    private mMaterialsHash: iHash<string> = {};
    private mCoatingHash: iHash<string> = {};

    //__________________________________________________________________________________________
    constructor(pFileList: FileList, pToReplace: boolean) {
        super();

        this.load({
            fileList: pFileList,
            dataLoader: OpticsDataLoader.instance,
            replaceExist: pToReplace,
            build: {
                checkValidationFunc: (pData, pRow) => this._checkOpticsVOValidation(pData, pRow),
                errorIdentificationFunc: (pRow) => {
                    let aID = pRow['ID number'];
                    let aBrandCatalogNumber = pRow['Brand-Catalog Number'];
                    let aMainName = pRow['Main Name'];

                    let aRet = 'Number ID: ' + aID;
                    aRet += 'Brand Catalog Number: ' + aBrandCatalogNumber;
                    aRet += 'Main Name: ' + aMainName;

                    return aRet;
                },
                form: {
                    'id number': {
                        path: 'number_id',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'permission': {
                        path: 'permission',
                        default: eDataPermission.PUBLIC,
                        isRequired: true
                    },
                    'main name': {
                        path: 'name',
                        isRequired: true,
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData, pRow) => this._getOpticsName(pData, pRow)
                    },
                    'shape': {
                        path: 'parameters.baseShape',
                        isRequired: true,
                        manipulationType: eManipulationType.CUSTOM,
                        default: eBaseShape.VOLUME,
                        manipulationFunction: (pData) => this._getBaseShape(pData)
                    },
                    'type': {
                        path: 'parameters.type',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'sub-type': {
                        path: 'parameters.subType',
                        manipulationType: eManipulationType.TRIM,
                        isRequired: true
                    },
                    'brand-catalog number': {
                        path: 'parameters.info.catalog_number',
                        manipulationType: eManipulationType.TRIM,
                    },

                    'brand': {
                        path: 'parameters.info.brand',
                        isRequired: true
                    },
                    'weblink': {
                        path: 'parameters.info.weblinks',
                        manipulationType: eManipulationType.SEMI_COLON_PARSE
                    },
                    // 'coating name': {
                    //     path: 'parameters.coating',
                    //     manipulationType: eManipulationType.CUSTOM,
                    //     manipulationFunction: (pData, pRow) => this._getCoating(pRow),
                    //     default: {}
                    // },
                    'material': {
                        path: 'parameters.materialID',
                        isRequired: true,
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData, pRow) => this._getMaterial(pData, pRow)
                    },
                    'a': {
                        path: 'parameters.geometry.a',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'b': {
                        path: 'parameters.geometry.b',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'c': {
                        path: 'parameters.geometry.c',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'd': {
                        path: 'parameters.geometry.d',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'e': {
                        path: 'parameters.geometry.e',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'f': {
                        path: 'parameters.geometry.f',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'g': {
                        path: 'parameters.geometry.g',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'h': {
                        path: 'parameters.geometry.h',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'j': {
                        path: 'parameters.geometry.j',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'k': {
                        path: 'parameters.geometry.k',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'l': {
                        path: 'parameters.geometry.l',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    't': {
                        path: 'parameters.geometry.t',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'x': {
                        path: 'parameters.geometry.x',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'y': {
                        path: 'parameters.geometry.y',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'r': {
                        path: 'parameters.geometry.r',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'r1': {
                        path: 'parameters.geometry.r1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'r2': {
                        path: 'parameters.geometry.r2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'r3': {
                        path: 'parameters.geometry.r3',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'r4': {
                        path: 'parameters.geometry.r4',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'length of face': {
                        path: 'parameters.geometry.length_of_face',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'length': {
                        path: 'parameters.geometry.length',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'length of legs (a)': {
                        path: 'parameters.geometry.length_of_legs',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'length of hypotenuse (b)': {
                        path: 'parameters.geometry.length_of_hypotenuse',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'dimensions': {
                        path: 'parameters.geometry.dimensions',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'dimension 1': {
                        path: 'parameters.geometry.dimension_1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'convexity': {
                        path: 'parameters.geometry.convexity',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData) => this._getConvexity(pData)

                    },
                    'dimension 2': {
                        path: 'parameters.geometry.dimension_2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'apex angle': {
                        path: 'parameters.geometry.apex_angle',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'off-axis angle': {
                        path: 'parameters.geometry.off_axis_angle'
                    },
                    'alpha': {
                        path: 'parameters.geometry.alpha',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'beta': {
                        path: 'parameters.geometry.beta',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'phi': {
                        path: 'parameters.geometry.phi',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'angle a': {
                        path: 'parameters.geometry.angle_a',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'angle b': {
                        path: 'parameters.geometry.angle_b',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'angle c': {
                        path: 'parameters.geometry.angle_c',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'angle d': {
                        path: 'parameters.geometry.angle_d',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'wedge angle': {
                        path: 'parameters.geometry.wedge_angle',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'theta': {
                        path: 'parameters.geometry.theta',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'theta1': {
                        path: 'parameters.geometry.theta1',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'theta2': {
                        path: 'parameters.geometry.theta2',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'diameter': {
                        path: 'parameters.geometry.diameter',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'width': {
                        path: 'parameters.geometry.width',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'height': {
                        path: 'parameters.geometry.height',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'cube side length': {
                        path: 'parameters.geometry.cube_side',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'l1': {
                        path: 'parameters.geometry.l1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'l2': {
                        path: 'parameters.geometry.l2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (center)': {
                        path: 'parameters.geometry.thickness_center',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (center)1': {
                        path: 'parameters.geometry.thickness_center_1',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (center)2': {
                        path: 'parameters.geometry.thickness_center_2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (center)3': {
                        path: 'parameters.geometry.thickness_center_3',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (center)a': {
                        path: 'parameters.geometry.thickness_center_a',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (center)b': {
                        path: 'parameters.geometry.thickness_center_b',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness (edge)': {
                        path: 'parameters.geometry.thickness_edge',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness h': {
                        path: 'parameters.geometry.thickness_h',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'beam deviation': {
                        path: 'parameters.geometry.beam_deviation',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'diopter': {
                        path: 'parameters.geometry.diopter',
                    },
                    'deviation angle': {
                        path: 'parameters.geometry.deviation_angle',
                        manipulationType: eManipulationType.DEG_TO_RAD
                    },
                    'thickness (edge) max': {
                        path: 'parameters.geometry.edge_thickness_max',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'thickness': {
                        path: 'parameters.geometry.thickness',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'efl': {
                        path: 'parameters.geometry.efl',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'bfl': {
                        path: 'parameters.geometry.bfl',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'pfl': {
                        path: 'parameters.geometry.pfl',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'inner hole radius': {
                        path: 'parameters.geometry.innerRadius',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'outer hole radius': {
                        path: 'parameters.geometry.outerRadius',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'lambda of n': {
                        path: 'parameters.wavelength',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'coeffs': {
                        path: 'parameters.geometry.coeffs',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "bs reflection": {
                        path: 'parameters.physical_data.bs_reflection',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "bs transmission": {
                        path: 'parameters.physical_data.bs_transmission',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a2': {
                        path: 'parameters.geometry.a2',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a3': {
                        path: 'parameters.geometry.a3',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a4': {
                        path: 'parameters.geometry.a4',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a5': {
                        path: 'parameters.geometry.a5',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a6': {
                        path: 'parameters.geometry.a6',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a7': {
                        path: 'parameters.geometry.a7',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a8': {
                        path: 'parameters.geometry.a8',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a9': {
                        path: 'parameters.geometry.a9',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a10': {
                        path: 'parameters.geometry.a10',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a11': {
                        path: 'parameters.geometry.a11',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a12': {
                        path: 'parameters.geometry.a12',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a13': {
                        path: 'parameters.geometry.a13',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a14': {
                        path: 'parameters.geometry.a14',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a15': {
                        path: 'parameters.geometry.a15',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'a16': {
                        path: 'parameters.geometry.a16',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    'na': {
                        path: 'parameters.geometry.na',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "radius of curvature": {
                        path: 'parameters.geometry.radius_of_curvature',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "weblink status": {
                        path: 'parameters.info.weblink_status',
                    },
                    "dispersion": {
                        path: 'parameters.physical_data.dispersion',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "blaze wavelength": {
                        path: 'parameters.physical_data.blaze_wavelength',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "order": {
                        path: 'parameters.physical_data.order',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "blaze angle": {
                        path: 'parameters.physical_data.blaze_angle',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "orientation vector": {
                        path: 'parameters.physical_data.orientation_vector',
                        manipulationType: eManipulationType.VECTOR_3,
                    },
                    "grating side": {
                        path: 'parameters.physical_data.grating_side',
                        manipulationType: eManipulationType.CUSTOM,
                        manipulationFunction: (pData) => this._getGratingSide(pData as tGratingSide)
                    },
                    "grooves-mm": {
                        path: 'parameters.physical_data.grooves',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "cut on": {
                        path: 'parameters.physical_data.cut_on',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "cut off": {
                        path: 'parameters.physical_data.cut_off',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    },
                    "cwl": {
                        path: 'parameters.physical_data.cwl',
                        manipulationType: eManipulationType.PARSE_FLOAT
                    }

                }
            }
        });
    }
    //__________________________________________________________________________________________
    private async _getGratingSide(pValue: tGratingSide) {
        switch (pValue) {
            case "Front":
            case "Back":
                return pValue;
            default:
                throw new Error("Invalid grating side ");
        }
    }
    //__________________________________________________________________________________________
    protected async _getConvexity(pValue: string) {
        switch (pValue) {
            case "Convex":
            case "Concave":
                return pValue;
            default:
                throw new Error("Invalid convexity type");
        }
    }
    //__________________________________________________________________________________________
    protected _getNumber(pValue: string, pManipulationType: eManipulationType) {
        let aFactor = 1;

        if (pValue.indexOf('in') > -1) {
            pValue = pValue.substring(0, (pValue.length - 2));
            aFactor = UnitHandler.IN_TO_MM;
        }

        let aValue: number;
        switch (pManipulationType) {
            case eManipulationType.PARSE_INT:
                aValue = parseInt(pValue);
                break;
            case eManipulationType.PARSE_FLOAT:
                aValue = parseFloat(pValue);
                break;
            default:
                throw new Error("Not a number.");
        }

        if (true == isNaN(aValue)) {
            return null;
        }

        return (aValue * aFactor);
    }
    //__________________________________________________________________________________________
    private async _getMaterial(pData: string, pRow: iHash<string>) {


        if (null != pRow['Material ID']) {
            let aMaterialID = '';
            aMaterialID = pRow['Material ID'];
            return aMaterialID;

        } else {

            if (pData.includes(";")) {
                let aMaterialNames = pData.split(';');
                const aMaterialIdsArray = [];
                for (let i = 0; i < aMaterialNames.length; i++) {
                    if (null == this.mMaterialsHash[aMaterialNames[i]]) {
                        let aMatID = await
                            Op3dContext.DATA_MANAGER.getMaterialNumberIDByName(aMaterialNames[i]);
                        this.mMaterialsHash[aMaterialNames[i]] = aMatID;
                    }

                    aMaterialIdsArray.push(this.mMaterialsHash[aMaterialNames[i]])
                }
                return aMaterialIdsArray;

            } else {
                return await Op3dContext.DATA_MANAGER.getMaterialNumberIDByName(pData);
            }
        }
    }
    //__________________________________________________________________________________________
    private async _getBaseShape(pData: string) {
        switch (pData.toUpperCase()) {
            case 'CIRCULAR':
                return eBaseShape.CIRCULAR;
            case 'RECTANGULAR':
                return eBaseShape.RECTANGULAR;
            default:
                return eBaseShape.VOLUME;
        }
    }
    //__________________________________________________________________________________________
    private async _setCoating(pData: iOpticsVO, pRow: iHash<string>) {
        let aCoatingName1Temp = pRow['Coating Name'];
        let aEfficiencyName = pRow['Efficiency name'];

        let aCoatingName1 = aEfficiencyName != null ? aEfficiencyName : aCoatingName1Temp;

        let aCoatingName2 = pRow['Coating Name2'];
        let aCoatingName3 = pRow['Coating Name3'];
        let aIsCoating1 = ((null != aCoatingName1) && ('' != aCoatingName1) &&
            ('UNCOATED' != aCoatingName1.toUpperCase()));
        let aIsCoating2 = ((null != aCoatingName2) && ('' != aCoatingName2) &&
            ('UNCOATED' != aCoatingName2.toUpperCase()));
        let aIsCoating3 = ((null != aCoatingName3) && ('' != aCoatingName3) &&
            ('UNCOATED' != aCoatingName3.toUpperCase()));

        if ((false == aIsCoating1) && (false == aIsCoating2) && (false == aIsCoating3)) {
            return null;
        }

        let aCoatingHash: iHash<string> = {

        };

        let aType = pRow['Type'].trim();
        let aSubtype = pRow['Sub-Type'].trim();

        let aOpticsType = OpticsContext.OPTICS_DATA.find((value) => (value.type == aType));
        if (null == aOpticsType) {
            return null;
        }

        let aOpticsData = aOpticsType.subtypes.find((subtype) => (aSubtype == subtype.name));
        if (null == aOpticsData) {
            return null;
        }

        let aCoating1NumberID = (false == aIsCoating1) ? null :
            await this._getCoatingNumberIDByName(aCoatingName1);
        let aCoating2NumberID = (false == aIsCoating2) ? null :
            await this._getCoatingNumberIDByName(aCoatingName2);
        let aCoating3NumberID = (false == aIsCoating3) ? null :
            await this._getCoatingNumberIDByName(aCoatingName3);

        let aCoatingsMap = aOpticsData.coatedSurfacesMap;
        if (null == aCoatingsMap) {
            return null;
        }

        let aIsCoating1Exist = this._setSurfacesNamesForCoatings(aCoatingHash, aCoatingsMap[0],
            aCoating1NumberID);
        let aIsCoating2Exist = this._setSurfacesNamesForCoatings(aCoatingHash, aCoatingsMap[1],
            aCoating2NumberID);
        let aIsCoating3Exist = this._setSurfacesNamesForCoatings(aCoatingHash, aCoatingsMap[2],
            aCoating3NumberID);

        let aMsg = '';
        if ((true == aIsCoating1) && (false == aIsCoating1Exist)) {
            aMsg += "[" + aCoatingName1 + '] Missing coating';
        }
        if ((true == aIsCoating2) && (false == aIsCoating2Exist)) {
            aMsg += "[" + aCoatingName2 + '] Missing coating';
        }
        if ((true == aIsCoating3) && (false == aIsCoating3Exist)) {
            aMsg += "[" + aCoatingName3 + '] Missing coating';
        }

        if ('' != aMsg) {
            return aMsg;
        }

        pData.parameters.coating = aCoatingHash;

        return null;
    }
    //__________________________________________________________________________________________
    private async _getCoatingNumberIDByName(pName: string) {
        let aNumberId = this.mCoatingHash[pName];
        if (aNumberId == null) {
            aNumberId = await CoatingDataLoader.instance.getNumberIDByName(pName);
            this.mCoatingHash[pName] = aNumberId;
        }

        return aNumberId;
    }
    //__________________________________________________________________________________________
    private _setSurfacesNamesForCoatings(pCoatingsHash: iHash<string>,
        pSurfaceNames: Array<string>, pCoatingNumberID: string) {
        if ((null == pCoatingNumberID) || (null == pSurfaceNames)) {
            return false;
        }

        for (let i = 0; i < pSurfaceNames.length; i++) {
            let aSurfaceName = pSurfaceNames[i];
            pCoatingsHash[aSurfaceName] = pCoatingNumberID;
        }

        return true;
    }
    //__________________________________________________________________________________________
    private async _getOpticsName(_pData: string, pRow: iHash<string>) {
        let aName = '';

        let aBrandCatalogNumber = pRow['Brand-Catalog Number'];
        if (null != aBrandCatalogNumber && ("" != aBrandCatalogNumber)) {
            aName += aBrandCatalogNumber.toString().trim();
        }

        let aNameOfCoating = pRow['Name of Coating'];
        if (null != aNameOfCoating && ("" != aNameOfCoating)) {
            aName += ' ' + aNameOfCoating.toString().trim();;
        }

        let aMainName = pRow['Main Name'];
        if (null != aMainName && ("" != aMainName)) {
            aName += ' ' + aMainName.toString().trim();;
        }

        let aBandwidthCoating = pRow['Bandwidth coating'];
        if (null != aBandwidthCoating && ("" != aBandwidthCoating)) {
            aName += ' ' + aBandwidthCoating.toString().trim();;
        }

        aName = ParserContext.addSpecialCharachters(aName);
        aName = aName.replace('  ', ' ');

        return aName;
    }
    //__________________________________________________________________________________________
    private async _checkOpticsVOValidation(pData: iOpticsVO, pRow?: iHash<string>) {
        let aTypeValidation = this._checkTypeValidation(pData.parameters.type);
        if (null != aTypeValidation) {
            return aTypeValidation;
        }

        let aType = pData.parameters.type;
        let aSubtype = pData.parameters.subType;

        let aOpticsType = OpticsContext.OPTICS_DATA.find((value) => (value.type == aType));
        if (null == aOpticsType) {
            return 'Wrong type';
        }

        let aOpticsData = aOpticsType.subtypes.find((subtype) => (aSubtype == subtype.name));
        if (null == aOpticsData) {
            return 'Wrong subtype';
        }

        // let aWeblinkStatus = pRow['Weblink Status'];
        // if (aWeblinkStatus != null && aWeblinkStatus.toLowerCase().indexOf("invalid ") != -1) {
        //     return "Invalid element " + aWeblinkStatus;
        // }

        let aCoatingValidation = await this._setCoating(pData, pRow);
        if (null != aCoatingValidation) {
            return aCoatingValidation;
        }

        if (null == pData.parameters.materialID) {
            return 'Material does not exist in the data base.';
        }

        pData.parameters.shape = this._getShape(pData);

        return null;
    }
    //__________________________________________________________________________________________
    private _getShape(pData: iOpticsVO) {
        return OpticsContext.getEnumShapeBySubtype(pData.parameters.subType);
    }
    //__________________________________________________________________________________________
    private _checkTypeValidation(_pType: string) {


        return null;
    }
    //__________________________________________________________________________________________


}

