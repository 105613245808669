import { Vector3 } from "three";
import { iRGB, iRGBA, iVectorNumeric, t3DArray } from "../_context/_interfaces/Interfaces";
import { Op3dUtils } from "./Op3dUtils";
import { iEdgeParams, iFaceBasicDataNEW, iOptixData } from "../parts/PartInterfaces";

export interface iVFace {
    vertices: Array<number>;
    colors: Array<number>;
    opacity: Array<number>;

    points: Array<number>;
    indices: Array<number>;
}

export interface iOptixEdge {
    type: eEdgeType;

    points: Array<number>;
    indices: Array<number>;

    params?: iEdgeParams;
    color: iRGB;
}

export enum eEdgeType {
    NONE,
    LINE,
    CIRCLE,
    POINTS,
    ELLIPSE
}

export interface iVSolid {
    faces: Array<iVFace>,
}

export interface iVShape {
    solids: Array<iVSolid>;
    edges?: Array<iOptixEdge>;
    vertices?: Array<number>;
}

export interface iVPart {
    shapes: Array<iVShape>;
    id: string;

}

export class OptixReader {

    public static SIZEOF_INT: number = 4;
    //         public static SIZEOF_SHORT: number = 2;
    public static SIZEOF_CHAR: number = 1;

    public static SIZEOF_LONG: number = 8;
    public static SIZEOF_SHORT: number = 2;

    private mFileName: string;

    private mView: DataView;
    private mBytePointer: number;
    public mVersion: number;

    mIdFromAssembly: string;

    constructor() { }
    //__________________________________________________________________________________________
    public returnFacesMesh(pArrayBuffer: ArrayBuffer) {
        return this._onReadingFileFaces(pArrayBuffer);
    }
    //__________________________________________________________________________________________
    public read(pArrayBuffer: ArrayBuffer, pPreDefinedNormalizedColor?: iRGBA) {
        return this._onReadingFileNEW(pArrayBuffer, pPreDefinedNormalizedColor);
    }
    //__________________________________________________________________________________________
    private _getFace() {
        let aVertices = new Array<number>();
        let aColors = new Array<number>();

        let aR = (this.mView.getFloat32(this.mBytePointer, true));
        this.mBytePointer += OptixReader.SIZEOF_INT;
        let aG = (this.mView.getFloat32(this.mBytePointer, true));
        this.mBytePointer += OptixReader.SIZEOF_INT;
        let aB = (this.mView.getFloat32(this.mBytePointer, true));
        this.mBytePointer += OptixReader.SIZEOF_INT;
        let aA = (this.mView.getFloat32(this.mBytePointer, true));
        this.mBytePointer += OptixReader.SIZEOF_INT;

        let aNumOfPoints = this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        let aNumOfNormals
        if (this.mVersion >= 4) {
            aNumOfNormals = this.mView.getUint32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;
        }

        let aNumOfIndices = this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        let aPoints = new Array<iVectorNumeric>();
        let aIndexes = new Array<number>();

        for (let l = 0; l < aNumOfPoints; l++) {
            let aX = this.mView.getFloat32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;
            let aY = this.mView.getFloat32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;
            let aZ = this.mView.getFloat32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;

            aPoints.push({ x: aX, y: aY, z: aZ });
        }

        if (this.mVersion >= 4) {
            let aNormalsSize = aNumOfNormals * OptixReader.SIZEOF_INT
            this.mBytePointer += aNormalsSize
        }

        for (let m = 0; m < aNumOfIndices; m++) {
            aIndexes.push(this.mView.getUint16(this.mBytePointer, true));
            this.mBytePointer += OptixReader.SIZEOF_SHORT;
        }

        if (aR < 0) {
            aR = (46 / 255);
            aG = (51 / 255);
            aB = (51 / 255);
            aA = 1;
        }

        for (let n = 0; n < aIndexes.length; n++) {
            let aIndex = aIndexes[n];
            aVertices.push(aPoints[aIndex].x, aPoints[aIndex].y, aPoints[aIndex].z);
            aColors.push(aR, aG, aB, aA);
        }

        let aFace: iVFace = {
            vertices: aVertices,
            colors: aColors,
            opacity: [],
            points: [], //delete if return this class
            indices: [] //delete if return this class
        };

        return aFace;
    }
    //__________________________________________________________________________________________
    private _getSolid() {
        let aNumberOfFaces = this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;
        let aFaces = new Array<iVFace>();
        for (let i = 0; i < aNumberOfFaces; i++) {
            let aFace = this._getFace();
            aFaces.push(aFace);
        }

        let aSolid: iVSolid = {
            faces: aFaces
        };

        return aSolid;
    }
    //__________________________________________________________________________________________
    private _getPartNew() {

        let aShapes = new Array<iVShape>();

        let aNumOfShapes = this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        for (let i = 0; i < aNumOfShapes; i++) {

            let aNumOfVertices = this.mView.getUint32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;

            let aShapeVertices = new Array<number>();
            for (let j = 0; j < aNumOfVertices; j++) {
                let aX = this.mView.getFloat32(this.mBytePointer, true);
                this.mBytePointer += OptixReader.SIZEOF_INT;
                let aY = this.mView.getFloat32(this.mBytePointer, true);
                this.mBytePointer += OptixReader.SIZEOF_INT;
                let aZ = this.mView.getFloat32(this.mBytePointer, true);
                this.mBytePointer += OptixReader.SIZEOF_INT;

                aShapeVertices.push(aX, aY, aZ);
            }

            let aNumberOfEdges = this.mView.getUint32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;

            let aEdges = new Array<iOptixEdge>();
            for (let j = 0; j < aNumberOfEdges; j++) {
                let aEdgeType = this.mView.getUint8(this.mBytePointer);
                this.mBytePointer += OptixReader.SIZEOF_CHAR;
                switch (aEdgeType) {
                    case eEdgeType.NONE:
                        let aRNone = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aGNone = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aBNone = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aANone = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aNoneEdgeColor: iRGBA = {
                            r: aRNone,
                            g: aGNone,
                            b: aBNone,
                            a: aANone
                        };


                        let aNumOfEdgePointsNone = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aNumOfEdgeIndicesNone = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aVerticesNoneEdge = new Array<number>();
                        for (let k = 0; k < aNumOfEdgePointsNone; k++) {
                            let aX = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aY = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aZ = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;

                            aVerticesNoneEdge.push(aX, aY, aZ);
                        }

                        let aNoneIndices = new Array<number>();
                        for (let k = 0; k < aNumOfEdgeIndicesNone; k++) {
                            let aIndex = this.mView.getUint16(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_SHORT;
                            aNoneIndices.push(aIndex);
                        }

                        aEdges.push({
                            color: aNoneEdgeColor,
                            indices: aNoneIndices,
                            type: aEdgeType,
                            points: aVerticesNoneEdge
                        });

                        break;
                    case eEdgeType.LINE:
                        let aStartX = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aStartY = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aStartZ = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aStartPoint: t3DArray = [aStartX, aStartY, aStartZ];

                        let aEndX = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aEndY = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aEndZ = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aEndPoint: t3DArray = [aEndX, aEndY, aEndZ];

                        let aR = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aG = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aB = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aA = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aLineEdgeColor: iRGBA = {
                            r: aR,
                            g: aG,
                            b: aB,
                            a: aA
                        };


                        let aNumOfEdgePoints = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aNumOfEdgeIndices = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aVerticesLineEdge = new Array<number>();
                        for (let k = 0; k < aNumOfEdgePoints; k++) {
                            let aX = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aY = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aZ = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;

                            aVerticesLineEdge.push(aX, aY, aZ);
                        }

                        let aLineIndices = new Array<number>();
                        for (let k = 0; k < aNumOfEdgeIndices; k++) {
                            let aIndex = this.mView.getUint16(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_SHORT;
                            aLineIndices.push(aIndex);
                        }

                        aEdges.push({
                            color: aLineEdgeColor,
                            indices: aLineIndices,
                            params: {
                                startPoint: aStartPoint,
                                endPoint: aEndPoint
                            },
                            type: aEdgeType,
                            points: aVerticesLineEdge
                        });

                        break;
                    case eEdgeType.CIRCLE:
                        let aRadius = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aCenterX = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aCenterY = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aCenterZ = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aCenterCircleEdge: t3DArray = [aCenterX, aCenterY, aCenterZ]


                        let aRCircle = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aGCircle = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aBCircle = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aACircle = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aCircleEdgeColor: iRGBA = {
                            r: aRCircle,
                            g: aGCircle,
                            b: aBCircle,
                            a: aACircle
                        };


                        let aNumOfEdgePointsCircle = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aNumOfEdgeIndicesCircle = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aVerticesCircleEdge = new Array<number>();
                        for (let k = 0; k < aNumOfEdgePointsCircle; k++) {
                            let aX = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aY = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aZ = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;

                            aVerticesCircleEdge.push(aX, aY, aZ);
                        }

                        let aCircleIndices = new Array<number>();
                        for (let k = 0; k < aNumOfEdgeIndicesCircle; k++) {
                            let aIndex = this.mView.getUint16(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_SHORT;
                            aCircleIndices.push(aIndex);
                        }

                        aEdges.push({
                            color: aCircleEdgeColor,
                            indices: aCircleIndices,
                            params: {
                                radius: aRadius,
                                center: aCenterCircleEdge
                            },
                            type: aEdgeType,
                            points: aVerticesCircleEdge
                        });

                        break;
                    case eEdgeType.ELLIPSE:
                        let aMinorRadius = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aMajorRadius = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aCenterEllipseX = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aCenterEllipseY = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aCenterEllipseZ = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aCenterEllipseEdge: t3DArray = [aCenterEllipseX, aCenterEllipseY, aCenterEllipseZ]


                        let aREllipse = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aGEllipse = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aBEllipse = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;
                        let aAEllipse = this.mView.getFloat32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aEllipseEdgeColor: iRGBA = {
                            r: aREllipse,
                            g: aGEllipse,
                            b: aBEllipse,
                            a: aAEllipse
                        };


                        let aNumOfEdgePointsEllipse = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aNumOfEdgeIndicesEllipse = this.mView.getUint32(this.mBytePointer, true);
                        this.mBytePointer += OptixReader.SIZEOF_INT;

                        let aVerticesEllipseEdge = new Array<number>();
                        for (let k = 0; k < aNumOfEdgePointsEllipse; k++) {
                            let aX = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aY = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;
                            let aZ = this.mView.getFloat32(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_INT;

                            aVerticesEllipseEdge.push(aX, aY, aZ);
                        }

                        let aEllipseIndices = new Array<number>();
                        for (let k = 0; k < aNumOfEdgeIndicesEllipse; k++) {
                            let aIndex = this.mView.getUint16(this.mBytePointer, true);
                            this.mBytePointer += OptixReader.SIZEOF_SHORT;
                            aEllipseIndices.push(aIndex);
                        }

                        aEdges.push({
                            color: aEllipseEdgeColor,
                            indices: aEllipseIndices,
                            params: {
                                minorRadius: aMinorRadius,
                                majorRadius: aMajorRadius,
                                center: aCenterEllipseEdge
                            },
                            type: aEdgeType,
                            points: aVerticesEllipseEdge
                        });

                        break;
                }
            }

            let aSolids = new Array<iVSolid>();

            let aFreeFacesSolid = this._getSolid();
            if (aFreeFacesSolid.faces.length > 0) {
                aSolids.push(aFreeFacesSolid);
            }

            let aNumberOfSolids = this.mView.getUint32(this.mBytePointer, true);
            this.mBytePointer += OptixReader.SIZEOF_INT;

            for (let i = 0; i < aNumberOfSolids; i++) {
                let aSolid = this._getSolid();
                aSolids.push(aSolid);

            }

            aShapes.push({
                solids: aSolids,
                edges: aEdges,
                vertices: aShapeVertices
            });

        }

        return {
            shapes: aShapes,
            id: this.mFileName
        };

    }
    //__________________________________________________________________________________________
    private _getPartFacesNEW(pPart: iVPart, pPreDefinedNormalizedColor?: iRGBA) {
        let aVertices = new Array<number>();
        let aColors = new Array<number>();
        let aFacesArray = new Array<iFaceBasicDataNEW>();
        let aNameCounter = 1;

        for (let i = 0; i < pPart.shapes.length; i++) {
            for (let j = 0; j < pPart.shapes[i].solids.length; j++) {
                for (let k = 0; k < pPart.shapes[i].solids[j].faces.length; k++) {
                    let aStart = aVertices.length
                    let aCountIndex = pPart.shapes[i].solids[j].faces[k].vertices.length;

                    let aVerticesNum = pPart.shapes[i].solids[j].faces[k].vertices.length
                    for (let l = 0; l < aVerticesNum; l++) {
                        aVertices.push(pPart.shapes[i].solids[j].faces[k].vertices[l]);
                    }

                    let aColorsNum = pPart.shapes[i].solids[j].faces[k].colors.length;
                    if (undefined !== pPreDefinedNormalizedColor) {
                        for (let l = 0; l < aColorsNum; l += 4) {
                            aColors.push(pPreDefinedNormalizedColor.r);
                            aColors.push(pPreDefinedNormalizedColor.g);
                            aColors.push(pPreDefinedNormalizedColor.b);
                            aColors.push(pPreDefinedNormalizedColor.a);
                        }
                    } else {
                        for (let l = 0; l < aColorsNum; l++) {
                            aColors.push(pPart.shapes[i].solids[j].faces[k].colors[l]);
                        }
                    }

                    aFacesArray.push(
                        {
                            name: 'Face_' + aNameCounter,
                            originalName: 'Face_' + aNameCounter,
                            path: [i, j, k],
                            indexes: {
                                start: (aStart / 3),
                                end: (((aStart + aCountIndex) / 3) - 1)
                            }
                        });
                    aNameCounter++;
                }
            }
        }

        return [aVertices, aColors, aFacesArray] as any;
    }
    //__________________________________________________________________________________________
    private _onReadingFileNEW(pData: ArrayBuffer, pPreDefinedNormalizedColor?: iRGBA) {
        this.mView = new DataView(pData);
        this.mBytePointer = 0;

        this.mVersion = this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        let aPart
        try {
            aPart = this._getPartNew();
        } catch (e) {
            return
        }


        let [aVerFaces, aColorFaces, aFacesData] = this._getPartFacesNEW(aPart,
            pPreDefinedNormalizedColor);
        let [aVerEdges, aColorEdges, aEdgesData] = this._getPartEdgesNEW(aPart);
        // let [aVerVertex, aColorVertex, aVertexData] = this._getPartVerticesNEW(aPart)

        let aOptixData: iOptixData = {
            faces: aVerFaces,
            facesColor: aColorFaces,
            facesData: aFacesData,
            edges: aVerEdges,
            edgesColor: aColorEdges,
            edgesData: aEdgesData,
            // vertex: aVerVertex,
            // vertexColor: aColorVertex,
            // vertexData: aVertexData,
        }

        return aOptixData
    }
    //__________________________________________________________________________________________
    public _onReadingFileFaces(pData: ArrayBuffer) {

        this.mView = new DataView(pData);
        this.mBytePointer = 0;

        this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        this.mView.getUint32(this.mBytePointer, true);
        this.mBytePointer += OptixReader.SIZEOF_INT;

        let aPart = this._getPartNew();

        return this._getPartFacesNEW(aPart)
    }
    //__________________________________________________________________________________________
    private _getPartEdgesNEW(pPart: iVPart) {
        // let aVertices = new Array<number>();
        // let aParts = []
        let aEdgesArray = []

        let aPointsArray = []
        let aColorArray = []
        let aDefColor = 128 / 255;
        for (let i = 0; i < pPart.shapes.length; i++) {
            for (let j = 0; j < pPart.shapes[i].edges.length; j++) {
                let aItem = {
                    indices: pPart.shapes[i].edges[j].indices,
                    verteces: pPart.shapes[i].edges[j].points,
                    type: pPart.shapes[i].edges[j].type,
                    params: pPart.shapes[i].edges[j].params
                }
                // aParts.push(aItem);

                // for (let k = 0; k < pPart.shapes[i].edges[j].points.length; k++) {
                // aVertices.push(pPart.shapes[i].edges[j].points[k]);
                // }

                let aPointsArr = []
                let aCountIndex = 0
                let start = aPointsArray.length

                for (let j = 0; j < aItem.verteces.length; j += 3) {
                    aPointsArr.push(new Vector3(aItem.verteces[j], aItem.verteces[j + 1], aItem.verteces[j + 2]))
                }
                let indices = aItem.indices
                let verteces = aPointsArr

                for (let index of indices) {
                    aPointsArray.push(verteces[index])
                    aCountIndex++
                    aColorArray.push(aDefColor, aDefColor, aDefColor)
                }

                aEdgesArray.push(
                    {
                        name: 'edge_' + i,
                        internal_id: Op3dUtils.idGenerator(),
                        indexes: {
                            start: start,
                            end: start + aCountIndex - 1
                        },
                        // type: aParts[i].type,
                        // data: aParts[i].params
                        type: aItem.type,
                        data: aItem.params
                    })
            }
        }


        // for (let i = 0; i < aParts.length; i++) {
        //     let aPointsArr = []
        //     let aCountIndex = 0
        //     let start = aPointsArray.length
        //     for (let j = 0; j < aParts[i].verteces.length; j += 3) {
        //         aPointsArr.push(new Vector3(aParts[i].verteces[j], aParts[i].verteces[j + 1], aParts[i].verteces[j + 2]))
        //     }
        //     let indices = aParts[i].indices
        //     let verteces = aPointsArr

        //     for (let index of indices) {
        //         aPointsArray.push(verteces[index])
        //         aCountIndex++
        //         aColorArray.push(128 / 255, 128 / 255, 128 / 255)
        //     }

        //     aEdgesArray.push(
        //         {
        //             name: 'edge_' + i,
        //             internal_id: Op3dUtils.idGenerator(),
        //             indexes: {
        //                 start: start,
        //                 end: start + aCountIndex - 1
        //             },
        //             type: aParts[i].type,
        //             data: aParts[i].params
        //         })
        // }

        // aVertices = null
        return [
            aPointsArray, aColorArray, aEdgesArray
        ]

    }

}
