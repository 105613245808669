import { eUnitType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { iMinMax } from "../../_context/_interfaces/Interfaces";
import { iIrisBehaviorJSON } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { UnitHandler } from "../../units/UnitsHandler";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";

export interface iIrisSection {
    irisBehaviorJSON: iIrisBehaviorJSON;
    range: iMinMax;
};

export class piIrisSection extends PartInfoSection<iIrisSection> {

    private mIrisInnerRadius: NumberInputElement;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_iris_section.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._initIrisInnerRadiusElement();
    }
    //__________________________________________________________________________________________
    private _initIrisInnerRadiusElement() {
        this.mIrisInnerRadius = new NumberInputElement(this._getPart('iris_inner_radius'),
            {
                isGlobalToFixed: true,
                toFixed: Op3dContext.USER_VO.userVO.parameters.simulation.digitsAfterDot,
                unitName: UnitHandler.shortSign
            });

        this.mIrisInnerRadius.setData({
            callback: (pValue: number) => this._onChangeIrisInnerRadius(pValue),
            step: (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 0.1 : 0.004
        });
    }
    //__________________________________________________________________________________________
    private _onChangeIrisInnerRadius(pInnerRadius: number) {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        aPart.getBehavior('irisBehavior').changeInnerRadius(aPart, pInnerRadius);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pIrisSection: iIrisSection): void {
        this.mIrisInnerRadius.setData({
            range: pIrisSection.range,
        });

        this.mIrisInnerRadius.value = pIrisSection.irisBehaviorJSON.innerRadius;
    }
    //__________________________________________________________________________________________
}
