

export class EventsContext {


    //SIDE BAR EVENTS
    public static SIDE_BAR_ITEMS_READY = "SIDE_BAR_ITEMS_READY";
    public static UPDATE_SIDE_BAR = "UPDATE_SIDE_BAR";
    public static UPDATE_SIDE_BAR_PRIVATE = "UPDATE_SIDE_BAR_PRIVATE";
    public static CLEAR_FILTERS = "CLEAR_FILTERS";
    public static ITEM_LOADED = "ITEM_LOADED";
    public static ON_CLEAR_ALL_PARTS = "ON_CLEAR_ALL_PARTS";
    public static CHANGE_ANALYSIS_RAYS_COUNT = "CHANGE_ANALYSIS_RAYS_COUNT";
    public static OPTICS_VO_COATING_CHANGED = "OPTICS_VO_COATING_CHANGED";
    public static SCATTERING_SECTION_DATA_CHANGE = "SCATTERING_SECTION_DATA_CHANGE";
    public static SIMULATION_SECTION_DATA_CHANGE = "SIMULATION_SECTION_DATA_CHANGE";
    public static BASIC_FACE_SECTION_CHANGED = "BASIC_FACE_SECTION_CHANGED";

    public static RESTORE_TO_BASE_MODE = "RESTORE_TO_BASE_MODE";
    public static OPTICS_VO_BASIC_DATA_CHANGED = "OPTICS_VO_BASIC_DATA_CHANGED";

    public static OPTICS_VO_GEO_DATA_CHANGED = "OPTICS_VO_GEO_DATA_CHANGED";
    public static SHOW_CUBE = "SHOW_CUBE";
    public static ROTATION_FROM_CUBE = "ROTATION_FROM_CUBE";
    public static HIGHLIGHT_NOTICICATION_BELL: string = "HIGHLIGHT_NOTICICATION_BELL";
    public static UNHIGHLIGHT_NOTICICATION_BELL: string = "UNHIGHLIGHT_NOTICICATION_BELL";
    public static NOTIFICATION_REMOVED = "NOTIFICATION_REMOVED";
    public static ON_ZOOM = "ON_ZOOM";
    public static ON_EXPORT = "ON_EXPORT";
    public static SAVE_EXAMPLE_OPT = "SAVE_EXAMPLE_OPT";
    public static RENAME_SET_UP = "RENAME_SET_UP";
    public static COPY_SETUP = "COPY_SETUP";
    public static AUTO_SAVE_CHANGED = "AUTO_SAVE_CHANGED";
    public static ON_IMPORT = "ON_IMPORT";
    public static ON_NEW = "ON_NEW";
    public static ON_CLOSE = "ON_CLOSE";
    public static RESIZE_ALL_SCREEN = "RESIZE_ALL_SCREEN";
    public static PART_SELECTED = "PART_SELECTED";
    public static PART_DESELECTED = "PART_DESELECTED";
    public static PART_DELETED = "PART_DELETED";
    public static PART_ADDED = "PART_ADDED";
    public static UPDATE_ANALYSIS_PORTAL = "UPDATE_ANALYSIS_PORTAL";

    public static LOCKED_ANALYSIS: string = "LOCKED_ANALYSIS";
    public static AUTO_SAVE = "AUTO_SAVE";
    public static PERMISSION_UPDATE = "PERMISSION_UPDATE";
    public static TIMERS_UPDATE = "TIMERS_UPDATE";
    public static MODE_CHANGED = "MODE_CHANGED";
    public static OPTIX_BREP_PART_ADDED = "OPTIX_BREP_PART_ADDED";
    public static UPDATE_PARTS_LIST = "PARTS_CONTAINER_UPDATE";
    public static UPDATE_SIDEBAR_PART_NAME = "UPDATE_SIDEBAR_PART_NAME";

    // GENERAL EVENTS
    //public static SHOW_SPECTROMETER_CHART = "SHOW_SPECTROMETER_CHART";
    //public static ON_START_SIMULATION = "ON_START_SIMULATION";
    public static WINDOW_RESIZE = "WINDOW_RESIZE"
    public static SAVE_OPT_CLOUD: string = "SAVE_OPT_CLOUD";
    public static OPEN_SETUP: string = "OPEN_SETUP";
    public static OPEN_WAKEUP_SCREEN: string = "OPEN_WAKEUP_SCREEN";
    public static POSITION_CHANGED: string = "POSITION_CHANGED";
    public static ON_MOVE: string = "CAMERA MOVE"


    public static OPTICS_PHYSICAL_DATA_SECTION_CHANGED = "OPTICS_PHYSICAL_DATA_SECTION_CHANGED";
    public static OPTICS_POLARIZER_DATA_SECTION_CHANGED = "OPTICS_POLARIZER_DATA_SECTION_CHANGED";
    public static OPTICS_DEFORMATION_SECTION_CHANGED = "OPTICS_DEFORMATION_SECTION_CHANGED";
    public static OPTICS_DEFORMATION_CHANGE = "OPTICS_DEFORMATION_CHANGE";
    public static OPTICS_FORM_CHANGE = "EDIT_OPTICS_OPTICS_FORM";
    public static OPTICS_APERTURE_CHANGED = "OPTICS_APERTURE_CHANGED";
    public static OPTICS_MATERIAL_SECTION_CHANGED = "OPTICS_MATERIAL_SECTION_CHANGED";
    public static OPTICS_MAT_CHANGE = "OPTICS_MAT_CHANGE";
    public static OPTICS_BASIC_CHANGE = "OPTICS_BASIC_CHANGE";
    public static MATERIAL_WL_CHANGED = "MATERIAL_WL_CHANGED";
    public static MATERIAL_WL_CHANGED_NEW = "MATERIAL_WL_CHANGED_NEW";

    public static OPTOMECH_MAT_CHANGE = "OPTOMECH_MAT_CHANGE";
    public static OPTOMECH_COLOR_CHANGE = "OPTOMECH_COLOR_CHANGE";

    public static ENTER_MEASUREMENT_MODE = "ENTER_MEASUREMENT_MODE";
    public static LEAVE_MEASUREMENT_MODE = "LEAVE_MEASUREMENT_MODE";

    //SVG EVENTS
    //public static DETECTOR_POINT_CLICKED = "DETECTOR_POINT_CLICKED";
    public static ANALYSIS_PROGRESS = "ANALYSIS_PROGRESS";
    public static FIELDS_UPDATED = "FIELDS_UPDATED";
    public static SHOW_BREADBOARD_SHORCUT = "SHOW_BREADBOARD_SHORCUT";
    public static SHOW_GRID_SHORTCUT = "SHOW_GRID_SHORTCUT";
    public static SHOW_All_LABELS = "SHOW_ALL_LABELS";
    public static UPDATE_SCENE_SETTINGS = "UPDATE_SCENE_SETTINGS";
    public static UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";

    public static ON_START_SIMULATION = "ON_START_SIMULATION";
    public static ON_FINISH_SIMULATION = "ON_FINISH_SIMULATION";
    public static ON_FINISH_GAUSSIAN_SIMULATION = "ON_FINISH_GAUSSIAN_SIMULATION";
    public static UPDATE_SPATIAL_INFO: string = "UPDATE_SPATIAL_INFO";

    public static PART_MOVED = 'PART_MOVED';
    public static UNDO = 'UNDO';
    public static REDO = 'REDO';

    public static CHAT_BOT_REQUEST_PENDING_STATE = "CHAT_BOT_REQUEST_PENDING_STATE";

}