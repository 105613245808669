// import { Socket } from "socket.io-client";
import { Socket } from "socket.io-client";
import { iHash } from "../_context/_interfaces/Interfaces";
import { ServerContext } from "./ServerContext";


export enum eRequestType {
    PUT = "PUT",
    POST = "POST",
    GET = "GET"
}

export enum eContentType {
    APPLICATION_JSON = "application/json",
    MULTI_PART_FORM_DATA = "multipart/form-data",
    APPLICATION_FORM_ENCODED = "application/x-www-form-urlencoded"
}

export class ServerProxy {

    public static webSocketEmit(socket: Socket, event: string, data: any) {

        const aToken = ServerContext.tokens.access_token;
        data.authorization = "Bearer " + aToken;
        socket.emit(event, data);
    }
    //__________________________________________________________________________________________
    public static webSocketAddEventListener(socket: Socket, event: string, callback: Function) {
        socket.on(event, callback as any);
    }
    //__________________________________________________________________________________________
    public static webSocketRemoveAllListeners(socket: Socket) {
        socket.removeAllListeners();
    }
    //__________________________________________________________________________________________
    public static httpPutFile(pFile: File,
        pUrl: string,
        pCallback: (pErr: any) => void) {
        let aRequest = new XMLHttpRequest();
        aRequest.open('PUT', pUrl, true);
        aRequest.setRequestHeader("Access-Control-Allow-Origin", "*");
        aRequest.onload = () => {
            if (aRequest.status === 200) {
                pCallback(null);
            }
        }
        aRequest.onerror = (e) => {
            pCallback(e);
        };
        aRequest.send(pFile);
    }
    //__________________________________________________________________________________________
    public static httpGetFile(pUrl: string,
        pCallback: (pErr: any, pFile?: File) => void) {
        let aRequest = new XMLHttpRequest();
        aRequest.open('GET', pUrl, true);
        aRequest.responseType = 'arraybuffer';
        aRequest.setRequestHeader("Access-Control-Allow-Origin", "*");
        aRequest.onload = () => {
            if (aRequest.status === 200) {
                var aFilename = pUrl.substring(pUrl.lastIndexOf('/') + 1);
                let aFile = new File([aRequest.response], aFilename);
                pCallback(null, aFile);
            }
        }
        aRequest.onerror = (e) => {
            pCallback(e);
        };
        aRequest.send();
    }
    //__________________________________________________________________________________________
    public static ajaxPOST_JSON(pURL: string,
        pData: Object,
        pSuccessCallback: (pData: any) => void,
        pErrorCallback: (pData: any) => void): JQueryAjaxSettings {

        return this._ajax({
            URL: pURL,
            requestType: eRequestType.POST,
            data: pData,
            contentType: eContentType.APPLICATION_JSON,
            successCallback: pSuccessCallback,
            errorCallback: pErrorCallback,
            stringifyData: true,
        });
    }
    //__________________________________________________________________________________________
    public static ajaxGET_JSON(pURL: string,
        pData: Object,
        pSuccessCallback: (pData: any) => void,
        pErrorCallback: (pData: any) => void): JQueryAjaxSettings {

        return this._ajax({
            URL: pURL,
            requestType: eRequestType.GET,
            data: pData,
            contentType: eContentType.APPLICATION_JSON,
            successCallback: pSuccessCallback,
            errorCallback: pErrorCallback,
            stringifyData: true
        });
    }
    //__________________________________________________________________________________________
    // public static ajaxGET_MULTIPART(pURL: string,
    //     pData: FormData,
    //     pSuccessCallback: (pData: any) => void,
    //     pErrorCallback: (pData: any) => void): JQueryAjaxSettings {

    //     return this._ajax({
    //         URL: pURL,
    //         requestType: eRequestType.GET,
    //         data: pData,
    //         contentType: eCotentType.MULTI_PART_FORM_DATA,
    //         successCallback: pSuccessCallback,
    //         errorCallback: pErrorCallback,
    //         stringifyData: false,
    //     });
    // }
    //__________________________________________________________________________________________
    public static ajaxPUTMultipart(pURL: string, pData: FormData,
        pSuccessCallback: (pData: any) => void,
        pErrorCallback: (pData: any) => void): JQueryAjaxSettings {
        return this._ajax({
            URL: pURL,
            requestType: eRequestType.PUT,
            data: pData,
            contentType: eContentType.MULTI_PART_FORM_DATA,
            successCallback: pSuccessCallback,
            errorCallback: pErrorCallback,
            stringifyData: false,
        });
    }
    //__________________________________________________________________________________________
    public static ajaxPOST_MULTIPART(pURL: string,
        pData: FormData,
        pSuccessCallback: (pData: any) => void,
        pErrorCallback: (pData: any) => void): JQueryAjaxSettings {

        return this._ajax({
            URL: pURL,
            requestType: eRequestType.POST,
            data: pData,
            contentType: eContentType.MULTI_PART_FORM_DATA,
            successCallback: pSuccessCallback,
            errorCallback: pErrorCallback,
            stringifyData: false,
        });
    }
    //__________________________________________________________________________________________
    private static _ajax(pParams: {
        URL: string,
        requestType: eRequestType,
        data: Object,
        stringifyData: boolean,
        contentType: eContentType,
        successCallback: (pData: any) => void,
        errorCallback: (pData: any) => void
    }) {

        let aData = pParams.data;
        if (pParams.data != null && pParams.stringifyData) {
            aData = JSON.stringify(pParams.data);
        }
        const aToken = ServerContext.tokens != null ? ServerContext.tokens.access_token : "";
        let aHeaders: iHash = {
            "Authorization": "Bearer " + aToken,
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
        }

        if (pParams.contentType == eContentType.APPLICATION_JSON) {
            aHeaders["Content-Type"] = pParams.contentType;
        }

        let aAjaxCall: JQueryAjaxSettings = {
            url: pParams.URL,
            headers: aHeaders,
            type: pParams.requestType,
            data: aData,
            processData: false,
            contentType: false,
            crossDomain: false,
            success: function (data) {
                if (pParams.successCallback != null) {
                    pParams.successCallback(data);
                }
            },
            error: function (error) {
                if (pParams.errorCallback != null) {
                    pParams.errorCallback(error);
                }
            }
        }
        $.ajax(aAjaxCall);
        return aAjaxCall;

    }
    //__________________________________________________________________________________________
}
