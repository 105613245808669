﻿import { EventManager } from "../../../../../oc/events/EventManager";
import { eBrandTypes, eDataPermission } from "../../../../_context/Enums";
import { EventsContext } from "../../../../_context/EventsContext";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { OpticsContext, eBaseShape, eOpticsTypeNames } from "../../../../_context/OpticsContext";
import { OpticsDefaults } from "../../../../_context/OpticsDefaults";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { ViewUtils } from "../../../ViewUtils";
import { uoSection } from "../uoSection";
import { eFormType } from "./UploadOpticsContext";

export class uoBasicInfoNew extends uoSection<{ opticsVO: iOpticsVO, formType: eFormType }> {

    public static SKIN_PATH = './skins/forms/optics/uo_basic_info_new.html';
    private mTypesSelect!: HTMLSelectElement;
    private mSubtypesSelect!: HTMLSelectElement;
    private mNameInput!: HTMLInputElement;
    private mBrandSelect!: HTMLSelectElement;
    private mShape!: HTMLSelectElement;
    private mWeblink!: HTMLInputElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoBasicInfoNew.SKIN_PATH,
            title: 'Basic Info',
            collapseQaId: "uo_basic_info_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: false
        });
    }
    //__________________________________________________________________________________________
    public get name() {
        return this.mNameInput.value;
    }
    //__________________________________________________________________________________________
    private _componentsVisibilityState(pOpticsVO: iOpticsVO) {

        return {
            is_change_type_subtype: pOpticsVO.parameters.subType != OpticsContext._Paraxial_Lens &&
                Op3dContext.USER_VO.isBasicLicense == false,
            is_private_optics: pOpticsVO.permission === eDataPermission.PRIVATE
        }
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: { opticsVO: iOpticsVO, formType: eFormType }) {

        this.mContainer.setAttribute("form_type", pData.formType);
        this.mTypesSelect.value = pData.opticsVO.parameters.type;
        this._onTypeChanged(false);

        this.mSubtypesSelect.value = pData.opticsVO.parameters.subType;
        this._updateShapeSelect();
        this.mShape.value = pData.opticsVO.parameters.baseShape.toString();
        this.mNameInput.value = pData.opticsVO.name;

        let aComponentsVisibilityState = this._componentsVisibilityState(pData.opticsVO);

        ViewUtils.setElementAttribute(this.mContainer, "user-type", aComponentsVisibilityState.is_private_optics === true ? "private" : undefined);
        ViewUtils.setElementAttribute(this.mContainer, "f-type", aComponentsVisibilityState.is_change_type_subtype ? "basic" : "advanced");

        if (pData.opticsVO.parameters.info !== undefined) {
            if (pData.opticsVO.parameters.info.brand != "") {
                this.mBrandSelect.value = pData.opticsVO.parameters.info.brand;
            }

            if (pData.opticsVO.parameters.info.weblinks != null &&
                pData.opticsVO.parameters.info.weblinks[0] != "") {
                this.mWeblink.value = pData.opticsVO.parameters.info.weblinks[0];
            } else {
                this.mWeblink.value = "";
            }
        }


        this.expand();
    }
    //__________________________________________________________________________________________
    public get hasImageBtn(): boolean {
        let aType = this.mTypesSelect.value;

        switch (aType) {
            case eOpticsTypeNames.APERTURE:
                return false;
            default:
                return true;
        }
    }
    //__________________________________________________________________________________________
    private _onChange(pOpticsVO?: iOpticsVO) {
        EventManager.dispatchEvent(EventsContext.OPTICS_VO_BASIC_DATA_CHANGED, this, pOpticsVO);
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO) {

        pOpticsVO.name = this.mNameInput.value.trim();
        pOpticsVO.parameters.type = this.mTypesSelect.value;
        pOpticsVO.parameters.subType = this.mSubtypesSelect.value;
        pOpticsVO.parameters.baseShape = parseInt(this.mShape.value);

        if (pOpticsVO.parameters.info !== undefined) {
            // let aBrand = pOpticsVO.permission == eDataPermission.PRIVATE ? "" : pOpticsVO.parameters.info.brand;
            // pOpticsVO.parameters.info.brand = aBrand;
            if (this.mWeblink.value !== "") {
                pOpticsVO.parameters.info.weblinks = [this.mWeblink.value];
            }
        }
    }
    //__________________________________________________________________________________________
    private _onNameChanged() {

        this._onChange();
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mShape = Op3dUtils.getElementIn(this.mContainer, "shapes-select", true) as HTMLSelectElement;
        this.mShape.addEventListener("change", () => this._onShapeChanged(true));


        this.mNameInput = $(this.mContainer).find('input[path="name"]')[0] as HTMLInputElement;
        this.mNameInput.addEventListener("change", () => this._onNameChanged());

        this.mTypesSelect = Op3dUtils.getElementIn(this.mContainer, 'types_select') as HTMLSelectElement;
        this.mTypesSelect.addEventListener("change", () => this._onTypeChanged(true));

        this.mSubtypesSelect = Op3dUtils.getElementIn(this.mContainer, 'subtypes_select') as HTMLSelectElement;
        this.mSubtypesSelect.addEventListener("change", () => this._onSubtypeChanged(true));
        this.mWeblink = Op3dUtils.getElementIn(this.mContainer, 'weblink') as HTMLInputElement;

        await this._initBrands();
        this._initWeblinkButton();
    }
    //__________________________________________________________________________________________
    private _onShapeChanged(pToUpdate: boolean) {
        if (pToUpdate) {
            const aBaseShape = parseInt(this.mShape.value) as eBaseShape;
            let aOpticsVO = this._getDefaultOpticsVO(aBaseShape);
            this._onChange(aOpticsVO);
        }
    }
    //__________________________________________________________________________________________
    private _initWeblinkButton() {
        let aWeblinkBtn = Op3dUtils.getElementIn(this.mContainer, 'weblink_button');
        if (aWeblinkBtn != null) {
            aWeblinkBtn.addEventListener('click',
                () => Op3dUtils.openPopupWindow(this.mWeblink.value));
        }
    }
    //__________________________________________________________________________________________
    private async _initBrands() {
        let aBrands = await Op3dContext.DATA_MANAGER.getBrands(eBrandTypes.OPTIC);
        this.mBrandSelect = Op3dUtils.getElementIn(this.mContainer, 'brands_select') as HTMLSelectElement;

        for (let i = 0; i < aBrands.length; i++) {
            let aOption = document.createElement('option');
            let aBrandName = aBrands[i];
            aOption.value = aBrandName;
            aOption.text = aBrandName;
            this.mBrandSelect.appendChild(aOption);
        }
    }
    //__________________________________________________________________________________________
    private _onTypeChanged(pToUpdate: boolean) {
        const aSelectedType = this.mTypesSelect.value;
        const aItem = OpticsContext.OPTICS_DATA.find(item => item.type == aSelectedType);
        if (aItem == null) {
            throw new Error("Type not found");
        }

        let aSubtypes = aItem.subtypes;
        ViewUtils.clearElementsChildren(this.mSubtypesSelect);

        for (let i = 0; i < aSubtypes.length; i++) {
            let aOpt = document.createElement("option");
            aOpt.value = aSubtypes[i].name;
            aOpt.innerText = aSubtypes[i].name;
            this.mSubtypesSelect.appendChild(aOpt);
            if (aSubtypes[i].selectableOnUploadOptics === false) {
                ViewUtils.setElementDisabledAttribute(aOpt, true);
            }
            if (aSubtypes[i].showOnOpticsMenu === false) {
                ViewUtils.setElementVisibilityByDNone(aOpt, false);
            }
        }

        if (pToUpdate) {
            this.mSubtypesSelect.selectedIndex = 0;
            this._onSubtypeChanged(pToUpdate);
        }
        /**
         * @TODO:
         * do we need to do something about this change
         */
    }
    //__________________________________________________________________________________________
    private _updateShapeSelect() {
        let aType = this.mTypesSelect.value as eOpticsTypeNames;
        let aSubtype = this.mSubtypesSelect.value;
        let aRes = OpticsContext.OPTICS_DATA.find(item => item.type == aType);
        if (aRes !== undefined) {
            let aOpt = aRes.subtypes.find(item => item.name == aSubtype);
            if (aOpt !== undefined) {
                let aBaseShapes = aOpt.baseShapes;

                ViewUtils.setElementVisibilityByDNone(this.mShape, aBaseShapes.length > 0);
                if (aBaseShapes.length > 0) {
                    for (let i = 0; i < this.mShape.options.length; i++) {
                        let aVal = parseInt(this.mShape.options[i].value);
                        let aToShow = aBaseShapes.indexOf(aVal) != -1;
                        ViewUtils.setElementVisibilityByDNone(this.mShape.options[i], aToShow);
                    }
                }

                this.mShape.value = aBaseShapes[0].toString();
            }
        }
    }
    //__________________________________________________________________________________________
    private _getDefaultOpticsVO(pBaseShape: eBaseShape | undefined) {
        const aType = this.mTypesSelect.value as eOpticsTypeNames;
        const aSubType = this.mSubtypesSelect.value;
        // const aBaseShape = parseInt(this.mShape.value) as eBaseShape;
        let aDefaultOpticsVO = OpticsDefaults.getDefaultOpticsVO(aType, aSubType, pBaseShape, UnitHandler.PRESENTED_UNIT);
        return aDefaultOpticsVO;
    }
    //__________________________________________________________________________________________
    private _onSubtypeChanged(pToUpdate: boolean) {
        this._updateShapeSelect();
        if (pToUpdate) {
            const aBaseShape = parseInt(this.mShape.options[this.mShape.selectedIndex].value);
            const aOpticsVO = this._getDefaultOpticsVO(aBaseShape as eBaseShape);
            this._onChange(aOpticsVO);
        }
    }
    //__________________________________________________________________________________________
}
