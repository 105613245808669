import { EventManager } from "../../../oc/events/EventManager";
import { eUnitType, eIntensityColorCount } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { iUserVO, iUserSimulationSettings, iUserViewSettings, iGeneralUserSettings } from "../../_context/_interfaces/SettingInterfaces";
import { Registration } from "../../_main/Registration";
import { DataUtils } from "../../_utils/DataUtils";
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { SceneContext } from "../../scene/SceneContext";
import { ServerContext } from "../../server/ServerContext";
import { SettingsContext } from "../../settings/SettingsContext";
import { ColorUtils } from "../ColorUtils";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { CustomSelect, iCustomSelectOption } from "../components/CustomSelect";
import { Spinner } from "../home/Spinner";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { Popup } from "./Popup";

export interface iUserSettingsPageData {
    btn: HTMLElement;
    content: HTMLElement
}

export interface iSimulationSettingsElements {
    max_emitted_light_source_rays: HTMLInputElement;
    length_of_laser_ray: HTMLInputElement;
    length_of_laser_ray_unit: HTMLSelectElement;
    amplitude_threshold: HTMLInputElement
    visualization_threshold: HTMLInputElement
    intensity_color_dd: CustomSelect
    max_display_ray: HTMLInputElement;
    intensity_color_custom_input: HTMLInputElement;
    intensity_color_custom_input_parent: HTMLElement;
    numeric_accuracy: HTMLInputElement
}

export enum eUserSettingsPage {
    PERSONAL_INFO = "PERSONAL_INFO",
    GENERAL_SETTINGS = "GENERAL_SETTINGS",
    DEFAULTS_SETUP = "DEFAULTS_SETUP",
    CURR_SETUP_SETTINGS = "CURR_SETUP_SETTINGS",

    VIEW_SETTINGS = "VIEW_SETTINGS",
    BILLING = "BILLING",
    LICENSE = "LICENSE",
    API_KEY = "API_KEY",
}


export class UserSettingsFormNew extends Op3dComponentBase<{ userSettingsParams: iUserVO, currSettings: iUserSimulationSettings }>{

    private static INSTANCE: UserSettingsFormNew;
    private static SKIN_PATH = "./skins/home/user_settings_new.html";

    // private mIsChanged: boolean;
    // private mDigitsAfterDot: HTMLSelectElement;

    private mRestoreDefaultSettingsBtn: HTMLElement;
    private mPages: iHash<iUserSettingsPageData> = {}
    private mSettingsSideBar: HTMLElement;
    private mCurrPageType: eUserSettingsPage;

    //personal page
    private mFirstName: HTMLInputElement;
    private mLastName: HTMLInputElement;
    private mCountrySelect: HTMLSelectElement;
    private mCompany: HTMLInputElement;
    private mPhone: HTMLInputElement;

    //simulation settings 
    private mSimulationSettingsElements: {
        defaults: iSimulationSettingsElements,
        setup: iSimulationSettingsElements
    } = {
            defaults: null,
            setup: null
        }

    //general setings
    private mWavelengthsUnit: HTMLSelectElement;

    // view settings
    private mLightLevelSettings: HTMLSelectElement;
    private mSceneBackgroundColor: HTMLInputElement;

    // license
    private mLicenseType: HTMLElement;
    private mGPUTimeLimit: HTMLElement;
    private mGPUContainer: HTMLElement;
    private mGPUTimePercentage: HTMLElement;
    private mCadFillClock: HTMLElement;
    private mGPUFillClock: HTMLElement;
    private mTransactionTime: HTMLElement;
    private mTotalCadFolderSize: HTMLElement;
    private mCadCurrent: HTMLElement;
    private mCadSizePercentage: HTMLElement;
    private mApiKey: HTMLElement;
    private mApiTryBtn: HTMLElement;



    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {

        this.mSettingsSideBar = this._getPart("settings-side-bar");
        $(this.mSettingsSideBar).find('[page-btn]').each((_index, element) => {
            let aPageType = element.getAttribute("page-btn") as eUserSettingsPage;
            let aBtn = Op3dUtils.getElementInByAttr(this.mContainer, "page-btn", aPageType);
            let aData: iUserSettingsPageData = {
                btn: aBtn,
                content: Op3dUtils.getElementInByAttr(this.mContainer, "page", aPageType)
            }

            aBtn.addEventListener("click", () => this._onClickPage(aPageType));
            this.mPages[aPageType] = aData;
        });

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _addIntensityCountDD(pContainer: HTMLElement, pParent: HTMLElement) {
        let aOptions: iCustomSelectOption[] = [{
            value: "0",
            text: "3",
            enable: true
        },
        {
            value: "1",
            text: "10",
            enable: true
        },
        {
            value: "2",
            text: "Continuous",
            enable: true
        },
        {
            value: "3",
            text: "User defined",
            isPremiumOnly: true,
            enable: true
        }];
        let aSelect = new CustomSelect(
            pContainer, {
            labelOptions: {
                bold: false,
                justify: "start",
                label: 'Intensity colors count',
            },
            placeHolder: 'Intensity colors count',
            onChange: (pVal: string) => this._onIntensityColorDDChanged(pVal, pParent),
            class: ["forms-custom-select", "w-auto"],
            staticPostion: true,
        });

        aSelect.setOptions(aOptions);
        return aSelect;
    }
    //__________________________________________________________________________________________
    private _onClickPage(pPageType: eUserSettingsPage) {
        this.mSettingsSideBar.setAttribute("page-type", pPageType);
        this.mCurrPageType = pPageType;
        ViewUtils.setElementVisibilityByDNone(this.mRestoreDefaultSettingsBtn.parentElement, true);

        switch (this.mCurrPageType) {
            case eUserSettingsPage.CURR_SETUP_SETTINGS:
                this.mRestoreDefaultSettingsBtn.innerText = "Restore to globals";
                break;
            case eUserSettingsPage.DEFAULTS_SETUP:
            case eUserSettingsPage.GENERAL_SETTINGS:
            case eUserSettingsPage.VIEW_SETTINGS:
                this.mRestoreDefaultSettingsBtn.innerText = "Restore factory settings";
                break;
            default:
                ViewUtils.setElementVisibilityByDNone(this.mRestoreDefaultSettingsBtn.parentElement, false);
                break;
        }

        for (let item in this.mPages) {
            ViewUtils.setElementVisibilityByDNone(this.mPages[item].content, item == pPageType);
            ViewUtils.setElementActive(this.mPages[item].btn, item == pPageType)
        }
    }
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: UserSettingsFormNew.SKIN_PATH
        });
    }

    public static get instance() {
        if (this.INSTANCE == null) {

            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.classList.add('w-100-modal');
            document.getElementById('forms').appendChild(aDiv);
            this.INSTANCE = new UserSettingsFormNew(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    private _fillForm(pUserSettingsParams: iUserVO, pCurrSettings: iUserSimulationSettings) {

        this._fillPersonaInfo(pUserSettingsParams)
        this._fillSetupSettings(pUserSettingsParams.parameters.simulation, this.mSimulationSettingsElements.defaults);
        this._fillSetupSettings(pCurrSettings, this.mSimulationSettingsElements.setup);
        this._fillGeneraSettings(pUserSettingsParams.parameters.simulation);
        this._fillViewSettings(pUserSettingsParams.parameters.simulation);
        this._fillLicense();
        this._fillApiKey();
    }
    //__________________________________________________________________________________________
    private _fillViewSettings(pSettings: iUserViewSettings) {
        this.mLightLevelSettings.value = pSettings.lightLevel.toString();
        this.mSceneBackgroundColor.value = ColorUtils.numToHEXColor(pSettings.sceneBGColor);
    }
    //__________________________________________________________________________________________
    private _fillGeneraSettings(pSettings: iGeneralUserSettings) {
        this.mWavelengthsUnit.value = pSettings.wavelengthUnit.toString();
    }
    //__________________________________________________________________________________________
    private _fillPersonaInfo(pUserSettingsParams: iUserVO) {
        this.mFirstName.value = pUserSettingsParams.name.first;
        this.mLastName.value = pUserSettingsParams.name.last;
        this.mPhone.value = pUserSettingsParams.personal.phone;
        this.mCompany.value = pUserSettingsParams.personal.company;
        this.mCountrySelect.value = pUserSettingsParams.personal.country;
    }
    //__________________________________________________________________________________________
    private _initGeneraSettings() {
        this.mWavelengthsUnit = this._getPart("wavelength_unit") as HTMLSelectElement;
    }
    //__________________________________________________________________________________________
    private _fillSetupSettings(pSettings: iUserSimulationSettings, pElements: iSimulationSettingsElements) {
        let aUnit = parseInt(pElements.length_of_laser_ray_unit.value) as eUnitType;
        let aScalar = aUnit == eUnitType.MILLIMETERS ? 1 : 1 / 25;

        pElements.max_emitted_light_source_rays.value = OP3DMathUtils.getScientificValue(pSettings.maxEmittedRays);
        this._onEmittedRaysCountChanged(pElements.max_emitted_light_source_rays);
        pElements.length_of_laser_ray.value = (pSettings.distanceOfLaserRay * aScalar).toString();
        pElements.amplitude_threshold.value = pSettings.amplitudeTreshold.toString();
        pElements.visualization_threshold.value = pSettings.visualizationTreshold.toString();
        pElements.max_display_ray.value = pSettings.maxDisplayRays.toString();
        pElements.intensity_color_dd.value = pSettings.intensityColorCount.choice.toString();
        pElements.numeric_accuracy.value = pSettings.numericAccuracy.toString();

        ViewUtils.setElementVisibilityByDNone(pElements.intensity_color_custom_input_parent,
            pSettings.intensityColorCount.choice == eIntensityColorCount.USER_DEFINED);

        pElements.intensity_color_custom_input.value = pSettings.intensityColorCount.count != null ?
            pSettings.intensityColorCount.count.toString() : "";
    }
    //__________________________________________________________________________________________
    protected _onOpen(pSettings: { userSettingsParams: iUserVO, currSettings: iUserSimulationSettings }): void {
        this._onClickPage(eUserSettingsPage.PERSONAL_INFO);
        this._fillForm(pSettings.userSettingsParams, pSettings.currSettings);
    }
    //__________________________________________________________________________________________
    private _fillLicense() {

        const aUserData = Op3dContext.USER_VO;
        const aUserLicinseType = aUserData.getLicenseType();
        this.mLicenseType.innerText = aUserLicinseType;
        this.mGPUTimeLimit.innerText = `${aUserData.GPULimit} hours`;

        /**
         * @TODO: 
         * hide billing
         */
        //ViewUtils.setElementVisibilityByDNone(this.mBillingSectionMenuItem, Op3dContext.USER_PERMISSION.GPUTimeEnabled);
        ViewUtils.setElementVisibilityByDNone(this.mGPUContainer, Op3dContext.USER_PERMISSION.GPUTimeEnabled);

        let aVal = Op3dContext.USER_PERMISSION.GPUTimeEnabled ? "paying" : null;
        ViewUtils.setElementAttribute(this.mSettingsSideBar, "license", aVal);

        if (Op3dContext.USER_PERMISSION.GPUTimeEnabled) {

            //gpu utilization
            let aTransactionInHours = (aUserData.userVO.billing.license.transaction_time * SettingsContext.MILLISECONDS_TO_HOURS);
            let aPercentage = Math.min(100, ((aTransactionInHours / Op3dContext.USER_VO.GPULimit) * 100));
            this.mGPUTimePercentage.innerHTML = `${aPercentage.toFixed(2)}%`;
            let aDeg = (aPercentage / 100) * 360;
            this.mGPUFillClock.style.background = `conic-gradient(#23a7de ${aDeg}deg, #ededed 0deg)`

            let aTransactionInSeconds = (Op3dContext.USER_VO.userVO.billing.license.transaction_time * SettingsContext.MILLISECONDS_TO_SECONDS);
            const hours = Math.floor(aTransactionInSeconds / 3600);
            const minutes = Math.floor((aTransactionInSeconds % 3600) / 60);
            const seconds = Math.floor(aTransactionInSeconds % 60);
            this.mTransactionTime.innerText = `${hours} hour(s), ${minutes} minute(s) ${seconds} second(s)`;
        }

        let aCadData = Op3dContext.USER_VO.cadData;
        this.mTotalCadFolderSize.innerHTML = `${aCadData.limit.toFixed(2)} MB`;

        let aUsageInMb = aCadData.current_usage * 0.00000095367432;
        this.mCadCurrent.innerHTML = `${aUsageInMb.toFixed(2)} MB`;

        // cad utilization
        let aPercentageCad = Math.min(100, ((aUsageInMb / aCadData.limit) * 100));
        this.mCadSizePercentage.innerHTML = `${aPercentageCad.toFixed(2)}%`;
        let aDegCad = (aPercentageCad / 100) * 360;
        this.mCadFillClock.style.background = `conic-gradient(#23a7de ${aDegCad}deg, #ededed 0deg)`;
    }
    //__________________________________________________________________________________________
    private _fillApiKey() {

        const aUserApiKey = Op3dContext.USER_VO.userVO.apiKey;
        if (Op3dContext.USER_PERMISSION.isFreeUser !== true) {
            this.mApiKey.innerHTML = aUserApiKey.toLowerCase();
        } else {
            this.mApiKey.innerHTML = ''
            this.mApiKey.style.backgroundColor = 'rgba(237, 237, 237, 1)';
            this.mApiTryBtn.classList.remove('d-none');
        }
    }
    //__________________________________________________________________________________________
    private _initLicense() {
        this.mLicenseType = this._getPart("license-type");
        this.mGPUTimeLimit = this._getPart("gpu_time_limit");
        this.mGPUContainer = this._getPart("gpu-container");
        this.mGPUTimePercentage = this._getPart("gpu-time-percentage");
        this.mCadFillClock = this._getPart("fill-clock-cad");
        this.mGPUFillClock = this._getPart("fill-clock-gpu");
        this.mTransactionTime = this._getPart("transaction_time");
        this.mTotalCadFolderSize = this._getPart('total_cad_folder_size');
        this.mCadCurrent = this._getPart('cad_folder_size');
        this.mCadSizePercentage = this._getPart("cad-size-percentage");
    }
    //__________________________________________________________________________________________
    private _initApi() {
        this.mApiKey = this._getPart("api-key-span");
        this.mApiTryBtn = this._getPart("api-key-try-btn-container");

    }
    //__________________________________________________________________________________________
    private _restoreToUserDefaults() {
        this._fillSetupSettings(Op3dContext.USER_VO.userVO.parameters.simulation,
            this.mSimulationSettingsElements.setup);
    }
    //__________________________________________________________________________________________
    private _restoreToFactoryDefault() {
        let aUserVO = DataUtils.getObjectCopy(Op3dContext.USER_VO.userVO);
        aUserVO.parameters = SettingsContext.DEFAULT_USER_VO.parameters;

        this._fillGeneraSettings(aUserVO.parameters.simulation);
        this._fillViewSettings(aUserVO.parameters.simulation);
        this._fillSetupSettings(aUserVO.parameters.simulation,
            this.mSimulationSettingsElements.defaults);
    }
    //__________________________________________________________________________________________
    private _onRestoreDefaultSettings() {
        switch (this.mCurrPageType) {
            case eUserSettingsPage.GENERAL_SETTINGS:
            case eUserSettingsPage.DEFAULTS_SETUP:
            case eUserSettingsPage.VIEW_SETTINGS:
                this._restoreToFactoryDefault();
                break;
            case eUserSettingsPage.CURR_SETUP_SETTINGS:
                this._restoreToUserDefaults();
                break;
        }
    }
    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mRestoreDefaultSettingsBtn = this._getPart("restore-defaults", true);
        this._initPersonalInfo();
        this._initSetupDefaults();
        this._initSetupSettings();
        this._initGeneraSettings();
        this._initViewSettings();
        this._initLicense();
        this._initApi();

        let aSaveBtn = this._getPart("save-btn");
        aSaveBtn.addEventListener("click", () => this._onSave());

        (<any>$(this.mContainer).find('[data-toggle="tooltip"]')).tooltip();
        let aBillingIframe = this._getPart("billing-iframe") as HTMLIFrameElement;
        aBillingIframe.src = ServerContext.billing_url;
    }
    //__________________________________________________________________________________________
    private _initSetupSettings() {
        // let aIntensityDD = this._getPart("intensity-color-dd-setup") as HTMLSelectElement;
        let aIntensityParent = this._getPart("intensity-colors-count-parent-setup")
        let aIntensityDD = this._addIntensityCountDD(this._getPart("intensity-color-dd-setup"), aIntensityParent);
        this.mSimulationSettingsElements.setup = {
            numeric_accuracy: this._getPart("numeric-accuracy-setup") as HTMLInputElement,
            max_emitted_light_source_rays: this._getPart("max_emitted_ls_rays_setup") as HTMLInputElement,
            amplitude_threshold: this._getPart("ampltitude-treshold-setup") as HTMLInputElement,
            intensity_color_custom_input: this._getPart("intensity-custom-count-input-setup") as HTMLInputElement,
            intensity_color_custom_input_parent: aIntensityParent,
            intensity_color_dd: aIntensityDD,
            length_of_laser_ray: this._getPart("laser-ray-distance-setup") as HTMLInputElement,
            length_of_laser_ray_unit: this._getPart("length-of-rays-setup-unit") as HTMLSelectElement,
            max_display_ray: this._getPart("max-display-rays-setup") as HTMLInputElement,
            visualization_threshold: this._getPart("visualization-treshold-setup") as HTMLInputElement
        }

        this._addSimulationSettingsListeners(this.mSimulationSettingsElements.setup);
    }
    //__________________________________________________________________________________________
    private _personalInfoCheck() {
        let aError = null;
        let aCompany = this.mCompany.value.trim();
        if (aCompany == "") {
            aError = MessagesHandler.COMPANY_NOT_VALID;
        }

        let aCompanyRegex = new RegExp(/^[a-zA-Z0-9!@#$%^&* ()_+\-=\[\]{};':"\\|,.<>\/?]*$/);
        if (!aCompanyRegex.test(aCompany)) {
            aError = MessagesHandler.COMPANY_NOT_VALID_LETTERS;
        }

        let aRegexAlphabetic = new RegExp(/^[a-zA-Z -]+$/);
        let aFirstName = this.mFirstName.value.trim();
        let aLastName = this.mLastName.value.trim();

        if (aFirstName == "" || aLastName == "") {
            aError = MessagesHandler.ONE_OF_THE_FIELDS_IS_EMPTY
        }

        if (!aRegexAlphabetic.test(aFirstName)) {
            aError = MessagesHandler.FIRST_NAME_NOT_VALID;
        }

        if (!aRegexAlphabetic.test(aLastName)) {
            aError = MessagesHandler.LAST_NAME_NOT_VALID;
        }

        let aPhone = this.mPhone.value.trim();
        let aPhoneRegex = new RegExp(/^[0-9-+]+$/);
        if (aPhone != "" && !aPhoneRegex.test(aPhone)) {
            aError = MessagesHandler.PHONE_NOT_VALID;
        }

        if (aError != null) {
            NotificationCenter.instance.pushNotification({
                message: aError,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });
            return null;
        }

        return {
            first: aFirstName,
            last: aLastName,
            company: aCompany,
            phone: aPhone
        };
    }
    //__________________________________________________________________________________________
    private _simulationInfoCheck(pSettings: iUserSimulationSettings) {
        let aError = null;

        if (isNaN(pSettings.amplitudeTreshold) || pSettings.amplitudeTreshold <= 0) {
            aError = "The amplitude threshold must be higher than 0";
        }

        if (isNaN(pSettings.distanceOfLaserRay) || pSettings.distanceOfLaserRay < 1) {
            aError = "The ray length must be greater than 0";
        }

        if (isNaN(pSettings.maxDisplayRays) || pSettings.maxDisplayRays < 1 || pSettings.maxDisplayRays > 20000) {
            aError = "The quantity of displayed rays must be between 1 and 20K";
        }

        if (isNaN(pSettings.visualizationTreshold) || pSettings.visualizationTreshold <= 0 ||
            pSettings.visualizationTreshold > 100) {
            aError = "Visualization threshold has to be higher than 0 and lower than or equal to 100";
        }

        if (isNaN(pSettings.maxEmittedRays) || pSettings.maxEmittedRays < 1000 || pSettings.maxEmittedRays > 1e+20) {
            aError = "Max emitted got light source has to be higher than 1000 and lower than or equal to 1e+20";
        }

        if (pSettings.intensityColorCount.choice == eIntensityColorCount.USER_DEFINED) {

            if (isNaN(pSettings.intensityColorCount.count) || pSettings.intensityColorCount.count < 10 || pSettings.intensityColorCount.count > 16581375) {
                aError = "Intensity color count has to be higher than 10 and lower than or equal to 255^3";
            }
        }

        if (aError != null) {
            Popup.instance.open({ text: aError });
            return false;
        }

        return true;
    }
    //__________________________________________________________________________________________
    private async _onSave() {
        let aPersonalDetails = this._personalInfoCheck();
        if (aPersonalDetails == null) {
            return;
        }

        let aDefaults = this._getSimulationSettings(this.mSimulationSettingsElements.defaults);
        let aIsOk = this._simulationInfoCheck(aDefaults);
        if (aIsOk == false) {
            return;
        }

        let aSetupData = this._getSimulationSettings(this.mSimulationSettingsElements.setup);
        aIsOk = this._simulationInfoCheck(aSetupData);
        if (aIsOk == false) {
            return;
        }

        let aServerData: iUserVO = {
            personal: {
                company: aPersonalDetails.company,
                country: this.mCountrySelect.value,
                phone: aPersonalDetails.phone
            },
            name: {
                first: aPersonalDetails.first,
                last: aPersonalDetails.last
            },
            parameters: {
                simulation: {
                    maxEmittedRays: aDefaults.maxEmittedRays,
                    amplitudeTreshold: aDefaults.amplitudeTreshold,
                    distanceOfLaserRay: aDefaults.distanceOfLaserRay,
                    intensityColorCount: aDefaults.intensityColorCount,
                    lightLevel: parseFloat(this.mLightLevelSettings.value),
                    sceneBGColor: ColorUtils.stringColorHexToNumber(this.mSceneBackgroundColor.value),
                    visualizationTreshold: aDefaults.visualizationTreshold,
                    maxZoomIn: Op3dContext.USER_VO.userVO.parameters.simulation.maxZoomIn,
                    maxZoomOut: Op3dContext.USER_VO.userVO.parameters.simulation.maxZoomOut,
                    maxDisplayRays: aDefaults.maxDisplayRays,
                    digitsAfterDot: Op3dContext.USER_VO.userVO.parameters.simulation.digitsAfterDot,
                    steps: Op3dContext.USER_VO.userVO.parameters.simulation.steps,
                    wavelengthUnit: parseInt(this.mWavelengthsUnit.value),
                    numericAccuracy: aDefaults.numericAccuracy
                },
                presets: Op3dContext.USER_VO.userVO.parameters.presets
            },
            billing: null
        }

        delete aServerData.billing;
        Spinner.instance.show();
        let aData = await ServerContext.SERVER.editUserData(aServerData);
        if ((null != aData) && (true == aData.success) && (null != aData.data)) {
            Op3dContext.USER_VO.set(aData.data);
            this._updateSettings();
        }

        Spinner.instance.hide();
        this.close();

        if (Op3dContext.SETUPS_MANAGER != null && Op3dContext.SETUPS_MANAGER.currSetupID != null) {
            Op3dContext.SETUPS_MANAGER.setupParameters.settings = aSetupData;
            Op3dContext.SCENE_HISTORY.saveScene();
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = true;
        }
    }
    //__________________________________________________________________________________________
    private _updateSettings() {
        if (SceneContext.OP3D_SCENE != null) {
            SceneContext.OP3D_SCENE.updateLights();
            //let aSceneColor = Op3dContext.USER_VO.simulationSettings.sceneBGColor;
            // SceneContext.RENDERER.setClearColor(aSceneColor, 0.9);

            if (Op3dContext.USER_VO.isBasicLicense == true) {
                SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER)
                SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER2)
                SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER3)
                SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER4)
            } else {
                SceneContext.RENDERER.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
                SceneContext.RENDERER2.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
                SceneContext.RENDERER3.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
                SceneContext.RENDERER4.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
            }
        }

        EventManager.dispatchEvent(EventsContext.UPDATE_USER_SETTINGS, this);
    }
    //__________________________________________________________________________________________
    private _getSimulationSettings(pElements: iSimulationSettingsElements): iUserSimulationSettings {
        let aUnit = parseInt(pElements.length_of_laser_ray_unit.value);

        let aScale = aUnit == eUnitType.INCHES ? 25 : 1;
        let aSettings: iUserSimulationSettings = {
            maxEmittedRays: Math.round(parseFloat(pElements.max_emitted_light_source_rays.value)),
            amplitudeTreshold: parseFloat(pElements.amplitude_threshold.value),
            maxDisplayRays: Math.round(parseFloat(pElements.max_display_ray.value)),
            distanceOfLaserRay: parseFloat(pElements.length_of_laser_ray.value) * aScale,
            intensityColorCount: {
                choice: parseFloat(pElements.intensity_color_dd.value) as eIntensityColorCount,
                count: Math.round(parseFloat(pElements.intensity_color_custom_input.value))
            },
            visualizationTreshold: parseFloat(pElements.visualization_threshold.value),
            numericAccuracy: parseInt(pElements.numeric_accuracy.value)
        }

        return aSettings;
    }
    //__________________________________________________________________________________________
    private _updateRangeRaysDistance(pInput: HTMLInputElement, pUnit: eUnitType) {

        const aScalar = pUnit == eUnitType.MILLIMETERS ? 1 : 25.4;
        pInput.min = (0.1 / aScalar).toString();
        pInput.max = (1000000 / aScalar).toString();
    }
    //__________________________________________________________________________________________
    private _addSimulationSettingsListeners(pElements: iSimulationSettingsElements) {
        this._onLengthRayUnitChanged(parseInt(pElements.length_of_laser_ray_unit.value), pElements.length_of_laser_ray);

        pElements.length_of_laser_ray_unit.addEventListener("change",
            () => this._onLengthRayUnitChanged(parseInt(pElements.length_of_laser_ray_unit.value),
                pElements.length_of_laser_ray));

        pElements.max_emitted_light_source_rays.addEventListener("change",
            () => this._onEmittedRaysCountChanged(pElements.max_emitted_light_source_rays));
    }
    //__________________________________________________________________________________________
    private _onEmittedRaysCountChanged(pInput: HTMLInputElement) {
        let aValue = parseFloat(pInput.value);
        if (isNaN(aValue)) {
            aValue = Math.round(parseFloat(pInput.min));
            pInput.value = pInput.min;
        }

        let aMin = parseFloat(pInput.min);
        let aMax = parseFloat(pInput.max);
        if (aValue <= 0) {
            pInput.value = "";
            return;
        }

        let aNewVal = this._roundToClosest1000(aValue);
        pInput.value = OP3DMathUtils.getScientificValue(OP3DMathUtils.clampValue(aNewVal, aMin, aMax));
    }
    //__________________________________________________________________________________________
    private _roundToClosest1000(pNum: number) {
        return Math.round(pNum / 1000) * 1000;
    }
    //__________________________________________________________________________________________
    private _initSetupDefaults() {
        let aParent = this._getPart("intensity-colors-count-parent-defaults")

        this.mSimulationSettingsElements.defaults = {
            max_emitted_light_source_rays: this._getPart("max_emitted_ls_rays_defaults") as HTMLInputElement,
            amplitude_threshold: this._getPart("ampltitude-treshold-default") as HTMLInputElement,
            intensity_color_custom_input: this._getPart("intensity-custom-count-input-defaults") as HTMLInputElement,
            intensity_color_custom_input_parent: aParent,
            intensity_color_dd: this._addIntensityCountDD(this._getPart("intensity-color-dd-defaults"), aParent),
            length_of_laser_ray: this._getPart("laser-ray-distance-defaults") as HTMLInputElement,
            length_of_laser_ray_unit: this._getPart("length-of-rays-default-unit") as HTMLSelectElement,
            max_display_ray: this._getPart("max-display-rays-defaults") as HTMLInputElement,
            visualization_threshold: this._getPart("visualization-treshold-default") as HTMLInputElement,
            numeric_accuracy: this._getPart("numeric-accuracy-default") as HTMLInputElement
        }

        this._addSimulationSettingsListeners(this.mSimulationSettingsElements.defaults);
    }
    //__________________________________________________________________________________________
    private _onLengthRayUnitChanged(pUnit: eUnitType, pInput: HTMLInputElement) {
        let aScalar = pUnit == eUnitType.INCHES ? 1 / 25 : 25;
        let aVal = parseFloat(pInput.value) * aScalar;
        pInput.value = aVal.toString();
        this._updateRangeRaysDistance(pInput, pUnit);
    }
    //__________________________________________________________________________________________
    private _initViewSettings() {
        this.mLightLevelSettings = this._getPart("light_level_dropdown") as HTMLSelectElement;
        this.mSceneBackgroundColor = this._getPart("scene-background-color") as HTMLInputElement;
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mRestoreDefaultSettingsBtn.addEventListener("click", () => this._onRestoreDefaultSettings());
        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE, () => this._onPermissionsChanged(), this);
        EventManager.addEventListener(EventsContext.TIMERS_UPDATE, () => this._initLicense(), this);
    }
    //__________________________________________________________________________________________
    private _onPermissionsChanged() {
        this._initLicense();
    }
    //__________________________________________________________________________________________
    private _onIntensityColorDDChanged(pChoice: string, pParent: HTMLElement) {
        let aChoice = parseInt(pChoice);
        ViewUtils.setElementVisibilityByDNone(pParent,
            (aChoice == eIntensityColorCount.USER_DEFINED));
    }
    //__________________________________________________________________________________________
    private _initPersonalInfo() {
        this.mFirstName = this._getPart("first_name", true) as HTMLInputElement;
        this.mLastName = this._getPart("last_name", true) as HTMLInputElement;
        this.mCompany = this._getPart("company-reg", true) as HTMLInputElement;
        this.mPhone = this._getPart("phone-reg", true) as HTMLInputElement;
        this.mCountrySelect = this._getPart("country-reg-menu") as HTMLSelectElement;
        const aCountriesList = Registration.COUNTRIES_LIST;

        for (let i = 0; i < aCountriesList.length; i++) {
            let aOption = document.createElement('option');
            aOption.value = aCountriesList[i];
            aOption.innerHTML = aCountriesList[i];

            this.mCountrySelect.add(aOption);
        }
    }
    //__________________________________________________________________________________________
}
