import { iNumericKeyHash, iHash } from "../_context/_interfaces/Interfaces";
import { DataUtils } from "../_utils/DataUtils";

export enum eCacheDataType {
    MATERIAL,
    OPTIC_VO,
    COATING,
    SOURCE,
    PARTS,
    APERTURE,
    APERTURE_MATERIAL,
    OPTOMECHANIC

    // LIGHT_SOURCE_DATA,
    // FIBER_VO,
    // SNAPSHOT,
    // FLUOROPHORE,
    // FLUOROPHORE_CSV,
    // FILTER_SET,
    // SCAN,
    // SCATTERING,
    // DETECTOR_CSV
}

export class CacheStorage {

    private mGlobalStorageHash: iNumericKeyHash<iHash<any>> = {};
    private static INSTANCE: CacheStorage;

    private constructor() { }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            this.INSTANCE = new CacheStorage();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public distract() {
        for (let key in this.mGlobalStorageHash) {
            this.mGlobalStorageHash[key] = {}
        }
    }
    //__________________________________________________________________________________________
    public registerNewStorage(pType: eCacheDataType) {
        if (this.mGlobalStorageHash[pType] == null) {
            this.mGlobalStorageHash[pType] = {};
        }
    }
    //__________________________________________________________________________________________
    public getFromCache<T>(pName: string, pType: eCacheDataType): T {
        let aCache = this.getHashByEnum(pType);
        return aCache[pName.toLowerCase()];
    }
    //__________________________________________________________________________________________
    public getHashByEnum(pType: eCacheDataType): iHash<any> {
        return this.mGlobalStorageHash[pType];
    }
    //__________________________________________________________________________________________
    public addData(pName: string, pData: Object, pType: eCacheDataType) {
        if (pData == null) {
            return;
        }

        let aCache = this.getHashByEnum(pType);
        aCache[pName.toLowerCase()] = pData;
    }
    //__________________________________________________________________________________________
    public removeData(pKey: string, pType: eCacheDataType) {
        if (pKey == null) {
            return;
        }

        let aCache = this.getHashByEnum(pType);

        let aRet = DataUtils.getObjectCopy(aCache[pKey]);
        delete aCache[pKey];

        return aRet;
    }
    //__________________________________________________________________________________________
}
