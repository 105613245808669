import { EventManager } from "../../../../oc/events/EventManager";
import { eUnitType, eDataPermission, eMaterialEquationType } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iOP3DHTMLInputElement, iHash } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { MaterialUtils } from "../../../_utils/MaterialUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { MaterialDataLoader } from "../../../data/data_loader/MaterialDataLoader";
import { UnitHandler } from "../../../units/UnitsHandler";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { CustomSelect, iCustomSelectOption } from "../../components/CustomSelect";
import { RBGroup } from "../../components/RBGroup";
import { iCalcUnitSettings, FloatingCalcSettings, iCalcSettings, eCalcUnitType } from "../FloatingCalcSettings";
import { CalculatorGraph, iCalcCustomSelectData } from "../tools/CalculatorGraph";

export interface iGBCObject {

    /**
     * @description Input element.
     */
    input: iOP3DHTMLInputElement;

    /**
     * @description Determined if this element open for changed for premium users only.
     */
    isPremium: boolean;

    /**
     * @description If mentioned - this function will be dispatched in event of changing the 
     *  input of the element, before general _calc() function.
     */
    calcFunction?: () => void;

    /**
     * @description units of the element.
     * 
     * If the presented units is different from the calculated units by scale, the
     *  presented value will be the actual value divided by unitScale.   If the user type 
     *  some value - the actual value will be the entered value multiplied by unitScale.
     */
    units?: iCalcUnitSettings;

    /**
     * @description If mentioned - this will be the default value of the input.
     */
    defaultValue?: number;

    // /**
    //  * @description If the presented units is different from the calculated units by scale, the
    //  *  presented value will be the actual value divided by unitScale.   If the user type 
    //  *  some value - the actual value will be the entered value multiplied by unitScale.
    //  */
    // unitScale?: number;

    /**
     * @description If mentioned - this function should return true, in order the entered value
     *  will be validate. In case that the value is invalid - it will return the previos one.
     */
    validationFuncion?: (pValue: number, pElements?: iHash<number>) => boolean;
};

export interface iGBCParams {
    b: iGBCObject;
    omega_0: iGBCObject;
    omega_r: iGBCObject;
    omega_z: iGBCObject;
    psi_z: iGBCObject;
    r_z: iGBCObject;
    theta: iGBCObject;
    z: iGBCObject;
    z_r: iGBCObject;
}


export enum eGBCParams {
    B = 'b',
    OMEGA_0 = 'omega_0',
    OMEGA_R = 'omega_r',
    OMEGA_Z = 'omega_z',
    PSI_Z = 'psi_z',
    R_Z = 'r_z',
    THETA = 'theta',
    Z = 'z',
    Z_R = 'z_r'
}

export class GaussianBeamCalculator extends Op3dComponentBase {

    private static INSTANCE: GaussianBeamCalculator;

    private mElements: iGBCParams;

    private mMaterialSelect: CustomSelect;
    private mRefIndex: iOP3DHTMLInputElement;
    private mWavelength: iOP3DHTMLInputElement;

    private mRBGroup: RBGroup;
    private mGraph: CalculatorGraph;
    private mCalcSettings: FloatingCalcSettings;

    private mSettings: iCalcSettings = {
        decimalPrecision: 5,
        lenghtUnits: {
            scale: (OP3DMathUtils.MILI / UnitHandler.presentedScale),
            sign: UnitHandler.shortSign,
            type: eCalcUnitType.LENGHT
        }
    }
    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/calculators/gbc.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == GaussianBeamCalculator.INSTANCE) {
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.classList.add('gb_calculator');
            aContainer.classList.add('new_modal');
            aContainer.classList.add('p-0');
            aContainer.style.left = ((window.innerWidth - 552) / 2) + 'px';
            aContainer.style.top = '55px';
            aContainer.setAttribute('data-backdrop', 'static');
            document.getElementById('forms').appendChild(aContainer);
            GaussianBeamCalculator.INSTANCE = new GaussianBeamCalculator(aContainer);
        }

        return GaussianBeamCalculator.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mElements = {
            b: {
                input: this._getPart('b_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let b = this.mElements.b.input.actualValue;
                    let wavelenght = this.mWavelength.actualValue;
                    let n = this.mRefIndex.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_b(b, wavelenght, n);
                    this._onParamChange(waist);
                },
                validationFuncion: (pValue) => (pValue > 0),
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)
            },
            omega_0: {
                input: this._getPart('omega_0_input') as iOP3DHTMLInputElement,
                isPremium: false,
                defaultValue: (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ?
                    (2 * OP3DMathUtils.MILI) : (2.032 * OP3DMathUtils.MILI),
                validationFuncion: (pValue) => (pValue > 0),
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)

            },
            omega_r: {
                input: this._getPart('omega_r_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let omegaR = this.mElements.omega_r.input.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_omegaR(omegaR);
                    this._onParamChange(waist);
                },
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)
            },
            omega_z: {
                input: this._getPart('omega_z_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let omegaZ = this.mElements.omega_z.input.actualValue;
                    let wavelength = this.mWavelength.actualValue;
                    let n = this.mRefIndex.actualValue;
                    let z = this.mElements.z.input.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_omegaZ(omegaZ,
                        wavelength, n, z);
                    this._onParamChange(waist);
                },
                validationFuncion: (pValue) => (pValue > 0),
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)
            },
            psi_z: {
                input: this._getPart('psi_z_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let psiZ = this.mElements.psi_z.input.actualValue;
                    let wavelength = this.mWavelength.actualValue;
                    let n = this.mRefIndex.actualValue;
                    let z = this.mElements.z.input.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_psiZ(psiZ, wavelength, n,
                        z);
                    this._onParamChange(waist);
                },
                units: {
                    scale: OP3DMathUtils.MILI,
                    sign: 'mRad'
                }
            },
            r_z: {
                input: this._getPart('r_z_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let rZ = this.mElements.r_z.input.actualValue;
                    let wavelength = this.mWavelength.actualValue;
                    let n = this.mRefIndex.actualValue;
                    let z = this.mElements.z.input.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_rZ(rZ, wavelength, n, z);
                    this._onParamChange(waist);
                },
                validationFuncion: (rz, pElements) =>
                ((Math.sign(rz) == Math.sign(pElements.z)) &&
                    (Math.abs(rz) > Math.abs(pElements.z))),
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)
            },
            theta: {
                input: this._getPart('thata_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let theta = this.mElements.theta.input.actualValue;
                    let wavelength = this.mWavelength.actualValue;
                    let n = this.mRefIndex.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_theta(theta, wavelength,
                        n);
                    this._onParamChange(waist);
                },
                validationFuncion: (pValue) => (pValue > 0),
                units: {
                    scale: OP3DMathUtils.DEG_TO_RAD,
                    sign: 'Deg'
                }
            },
            z: {
                input: this._getPart('z_input') as iOP3DHTMLInputElement,
                isPremium: false,
                defaultValue: (eUnitType.MILLIMETERS == UnitHandler.presentedScale) ?
                    (10 * OP3DMathUtils.MILI) : (10.16 * OP3DMathUtils.MILI),
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)
            },
            z_r: {
                input: this._getPart('z_r_input') as iOP3DHTMLInputElement,
                isPremium: true,
                calcFunction: () => {
                    let zR = this.mElements.z_r.input.actualValue;
                    let wavelength = this.mWavelength.actualValue;
                    let n = this.mRefIndex.actualValue;

                    let waist = GaussianBeamCalculator.calcOmega0From_Zr(zR, wavelength, n);
                    this._onParamChange(waist);
                },
                validationFuncion: (pValue) => (pValue > 0),
                units: DataUtils.getObjectCopy(this.mSettings.lenghtUnits)
            }
        };

        let aFixed = this.mSettings.decimalPrecision;
        for (let elem in this.mElements) {
            let aElement = this.mElements[elem] as iGBCObject;
            if (null != aElement.defaultValue) {
                let aUnits = aElement.units;
                let aScale = ((null != aUnits) && (null != aUnits.scale)) ? aUnits.scale : 1;


                let aVal = (aElement.defaultValue / aScale);

                let aSign = (null != aUnits) ? aUnits.sign : '';
                aElement.input.value = (OP3DMathUtils.toFixed(aVal, aFixed) + ' ' + aSign);
                aElement.input.prevValue = aElement.input.value;

                aElement.input.actualValue = aElement.defaultValue;
            }
        }

        this.mRefIndex = this._getPart('n_input') as iOP3DHTMLInputElement;
        this.mRefIndex.value = '1';
        this.mRefIndex.prevValue = this.mRefIndex.value;
        this.mRefIndex.actualValue = 1;

        this.mWavelength = this._getPart('material_wavelenght') as iOP3DHTMLInputElement;
        this.mWavelength.value = '550 nm';
        this.mWavelength.prevValue = this.mWavelength.value;
        this.mWavelength.actualValue = 550 * OP3DMathUtils.NANO;

        this._initRB();
        this._initGraph();
        this._initSettings();
        this._initMaterialSelect();
    }
    //__________________________________________________________________________________________
    private _initSettings() {
        this.mCalcSettings = new FloatingCalcSettings({
            callback: (pSettings) => this._onUpdateSettings(pSettings),
            lenghtUnitsSettings: [{
                sign: UnitHandler.SHORT_IN_SIGN,
                scale: (OP3DMathUtils.MILI * UnitHandler.IN_TO_MM),
                type: eCalcUnitType.LENGHT,
                isDefault: (UnitHandler.PRESENTED_UNIT == eUnitType.INCHES)
            },
            {
                sign: UnitHandler.SHORT_MM_SIGN,
                scale: (OP3DMathUtils.MILI),
                type: eCalcUnitType.LENGHT,
                isDefault: (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS)
            }]
        });
    }
    //__________________________________________________________________________________________
    private _initGraph() {
        let aOptionsX = new Array<iCustomSelectOption<iCalcCustomSelectData>>();
        aOptionsX.push({
            value: eGBCParams.B, text: 'b', data: {
                unit: this.mElements.b.units,
                fixed: 5,
                validationFuncion: this.mElements.b.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.OMEGA_0, text: 'ω<sub>0</sub>',
            data: {
                unit: this.mElements.omega_0.units,
                fixed: 5,
                validationFuncion: this.mElements.omega_0.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.OMEGA_R, text: 'ω<sub>R</sub> (Z<sub>R</sub>)',
            data: {
                unit: this.mElements.omega_r.units,
                fixed: 5,
                validationFuncion: this.mElements.omega_r.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.OMEGA_Z, text: 'ω<sub>z</sub>',
            data: {
                unit: this.mElements.omega_z.units,
                fixed: 5,
                validationFuncion: this.mElements.omega_z.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.PSI_Z, text: 'ψ<sub>z</sub>',
            data: {
                unit: this.mElements.psi_z.units,
                fixed: 5,
                validationFuncion: this.mElements.psi_z.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.R_Z, text: 'R<sub>z</sub>',
            data: {
                unit: this.mElements.r_z.units,
                fixed: 5,
                validationFuncion: this.mElements.r_z.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.THETA, text: 'Θ',
            data: {
                unit: this.mElements.theta.units,
                fixed: 5,
                validationFuncion: this.mElements.theta.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });
        aOptionsX.push({
            value: eGBCParams.Z_R, text: 'Z<sub>R</sub>',
            data: {
                unit: this.mElements.z_r.units,
                fixed: 5,
                validationFuncion: this.mElements.z_r.validationFuncion
            } as iCalcCustomSelectData,
            enable: true
        });


        let aOptionsY = new Array<iCustomSelectOption>();
        aOptionsY.push({
            value: eGBCParams.OMEGA_0, text: 'ω<sub>0</sub>',
            data: {
                unit: this.mElements.omega_0.units,
                fixed: 5,
                validationFuncion: this.mElements.omega_0.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.B, text: 'b', data: {
                unit: this.mElements.b.units,
                fixed: 5,
                validationFuncion: this.mElements.b.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.OMEGA_R, text: 'ω<sub>R</sub> (Z<sub>R</sub>)',
            data: {
                unit: this.mElements.omega_r.units,
                fixed: 5,
                validationFuncion: this.mElements.omega_r.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.OMEGA_Z, text: 'ω<sub>z</sub>',
            data: {
                unit: this.mElements.omega_z.units,
                fixed: 5,
                validationFuncion: this.mElements.omega_z.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.PSI_Z, text: 'ψ<sub>z</sub>',
            data: {
                unit: this.mElements.psi_z.units,
                fixed: 5,
                validationFuncion: this.mElements.psi_z.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.R_Z, text: 'R<sub>z</sub>',
            data: {
                unit: this.mElements.r_z.units,
                fixed: 5,
                validationFuncion: this.mElements.r_z.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.THETA, text: 'Θ',
            data: {
                unit: this.mElements.theta.units,
                fixed: 5,
                validationFuncion: this.mElements.theta.validationFuncion
            },
            enable: true
        });
        aOptionsY.push({
            value: eGBCParams.Z_R, text: 'Z<sub>R</sub>',
            data: {
                unit: this.mElements.z_r.units,
                fixed: 5,
                validationFuncion: this.mElements.z_r.validationFuncion
            },
            enable: true
        });

        let aGraphHash = this._getGraphHash();
        this.mGraph = CalculatorGraph.getNewInstance({
            calcHash: aGraphHash,
            optionsX: aOptionsX,
            optionsY: aOptionsY
        });
    }
    //__________________________________________________________________________________________
    private _onParamChange(waist: number) {
        this.mElements.omega_0.input.actualValue = waist;
        let aScale = this.mElements.omega_0.units.scale;
        let aFixed = this.mSettings.decimalPrecision;

        this.mElements.omega_0.input.value = (OP3DMathUtils.toFixed((waist / aScale), aFixed) +
            ' ' + this.mElements.omega_0.units.sign);
        this.mElements.omega_0.input.prevValue = this.mElements.omega_0.input.value;

        this._calc();
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_Zr(zR: number, wavelength: number, n: number) {
        return Math.sqrt((zR * wavelength) / (n * Math.PI));
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_b(b: number, wavelength: number, n: number) {
        return GaussianBeamCalculator.calcOmega0From_Zr((b / 2), wavelength, n);
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_theta(theta: number, wavelength: number, n: number) {
        return (wavelength / (n * Math.PI * theta));
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_omegaR(omegaR: number) {
        return (omegaR * Math.SQRT1_2);
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_omegaZ(omegaZ: number, wavelength: number, n: number,
        z: number) {

        let omegaZ2 = (omegaZ * omegaZ);
        let omegaZ4 = (omegaZ2 * omegaZ2);

        let sqrIn = ((omegaZ4 / 4) - Math.pow(((2 * z * wavelength) / (Math.PI * n)), 2));
        let sqrOut = ((omegaZ2 / 2) + Math.sqrt(sqrIn));


        return (Math.sqrt(sqrOut));
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_rZ(rZ: number, wavelength: number, n: number, z: number) {
        let waist = Math.sqrt(((wavelength * z) / (Math.PI * n)));
        waist *= Math.pow(((rZ / z) - 1), 0.25);

        return waist;
    }
    //__________________________________________________________________________________________
    public static calcOmega0From_psiZ(psiZ: number, wavelength: number, n: number,
        z: number) {

        let num = (wavelength * z);
        let denum = Math.PI * n * Math.tan(psiZ)

        return Math.sqrt((num / denum));
    }
    //__________________________________________________________________________________________
    private _onUpdateSettings(pCalcSettings: iCalcSettings) {
        this.mSettings = pCalcSettings;
        for (let elem in this.mElements) {
            let aElement = this.mElements[elem] as iGBCObject;

            if (eCalcUnitType.LENGHT == aElement.units.type) {
                aElement.units = this.mSettings.lenghtUnits;
            }
        }

        let aScale = pCalcSettings.lenghtUnits.scale;
        let aFixed = pCalcSettings.decimalPrecision;
        let aSign = pCalcSettings.lenghtUnits.sign;

        let z = this.mElements.z.input.actualValue;
        this.mElements.z.input.value = (OP3DMathUtils.toFixed((z / aScale), aFixed) + ' ' + aSign);
        this.mElements.z.input.prevValue = this.mElements.z.input.value;

        let omega_0 = this.mElements.omega_0.input.actualValue;
        this.mElements.omega_0.input.value = (OP3DMathUtils.toFixed((omega_0 / aScale), aFixed) +
            ' ' + aSign);
        this.mElements.omega_0.input.prevValue = this.mElements.omega_0.input.value;

        this._calc();
        this.mGraph.updateUnit(pCalcSettings.lenghtUnits);
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        let aCalcBtn = this._getPart('calc_settings_btn');
        aCalcBtn.addEventListener('click', (_e) => {
            let aBB = aCalcBtn.getBoundingClientRect();
            this.mCalcSettings.open({ clientX: aBB.right, clientY: aBB.top }, this.mSettings);
        });

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._updatePermission(), this);
        this._getPart('show_graphs').addEventListener('click', () => this._onShowGraph());

        for (let element in this.mElements) {
            let aElement = this.mElements[element] as iGBCObject;
            aElement.input.addEventListener('focus', () =>
                aElement.input.value = parseFloat(aElement.input.value) + '');
            aElement.input.addEventListener('blur', () =>
                aElement.input.value = parseFloat(aElement.input.value) + ' ' +
                aElement.units.sign);

            aElement.input.addEventListener('change', () => {
                let aParsedValue = parseFloat(aElement.input.value);
                let aIsValid = (false == isNaN(aParsedValue)) &&
                    ((null == aElement.validationFuncion) ||
                        (true == aElement.validationFuncion(aParsedValue,
                            this._elementsToHash())));

                if (false == aIsValid) {
                    aElement.input.value = aElement.input.prevValue;
                    return;
                }

                aElement.input.prevValue = aElement.input.value;

                let aScale = (null != aElement.units.scale) ? aElement.units.scale : 1;
                aElement.input.actualValue = (parseFloat(aElement.input.value) * aScale);

                if (null != aElement.calcFunction) {
                    aElement.calcFunction();
                }

                this._calc();
            });
        }

        this.mWavelength.addEventListener('focus', () =>
            this.mWavelength.value = parseFloat(this.mWavelength.value) + '');
        this.mWavelength.addEventListener('blur', () =>
            this.mWavelength.value = parseFloat(this.mWavelength.value) + ' nm');
        this.mWavelength.addEventListener('change', () => {
            let aVal = parseFloat(this.mWavelength.value);

            if ((true == isNaN(aVal)) || (aVal < 140)) {
                this.mWavelength.value = this.mWavelength.prevValue;
                return;
            }

            this.mWavelength.prevValue = this.mWavelength.value;
            this.mWavelength.actualValue = (aVal * OP3DMathUtils.NANO);
            this._calc();
        });

        this.mRefIndex.addEventListener('change', () => {
            let aVal = parseFloat(this.mRefIndex.value);
            if ((true == isNaN(aVal)) || (aVal < 1)) {
                this.mRefIndex.value = this.mRefIndex.prevValue;
                return;
            }

            this.mRefIndex.prevValue = this.mRefIndex.value;
            this.mRefIndex.actualValue = aVal;

            this._calc();
        });
    }
    //__________________________________________________________________________________________
    private _elementsToHash() {
        let aHash: iHash<number> = {
            b: this.mElements.b.input.actualValue,
            omega_0: this.mElements.omega_0.input.actualValue,
            omega_r: this.mElements.omega_r.input.actualValue,
            omega_z: this.mElements.omega_z.input.actualValue,
            psi_z: this.mElements.psi_z.input.actualValue,
            r_z: this.mElements.r_z.input.actualValue,
            theta: this.mElements.theta.input.actualValue,
            z: this.mElements.z.input.actualValue,
            z_r: this.mElements.z_r.input.actualValue,
            wavelenght: this.mWavelength.actualValue,
            n: this.mRefIndex.actualValue
        };

        return aHash;
    }
    //__________________________________________________________________________________________
    private _onShowGraph() {
        let aMaterialName = this.mMaterialSelect.text;
        this.mGraph.open({
            data: this._elementsToHash(),
            materialName: (null != aMaterialName) ? aMaterialName : 'Customized',
        });
    }
    //__________________________________________________________________________________________
    private _calcZrFromOmega0(omega0: number, n: number, wavelength: number) {
        return ((Math.PI * (omega0 * omega0) * n) / wavelength);
    }
    //__________________________________________________________________________________________
    private _calcZrFromOmegaR(omegaR: number, n: number, wavelength: number) {
        return ((Math.PI * (omegaR * omegaR) * n) / (2 * wavelength));
    }
    //__________________________________________________________________________________________
    private _calcZrFromOmegaZ(omegaZ: number, n: number, wavelength: number, z: number) {
        let omegaZsq = (omegaZ * omegaZ);
        let aZr = ((Math.PI * n) / wavelength) * Math.sqrt((omegaZsq / 2) -
            Math.sqrt(((omegaZsq * omegaZsq) / 4) -
                Math.pow(((z * wavelength) / (Math.PI * n)), 2)));
        return aZr;
    }
    //__________________________________________________________________________________________
    private _calcZrFromTheta(theta: number, n: number, wavelength: number) {
        return ((wavelength / (Math.PI * n)) * (1 / (theta * theta)));
    }
    //__________________________________________________________________________________________
    private _calcZrFromRz(rZ: number, n: number, wavelength: number, z: number) {
        return Math.sqrt(((z * wavelength) / (Math.PI * n))) * Math.pow(((rZ / z) - 1), 0.25);
    }
    //__________________________________________________________________________________________
    private _calcZrFromPsiZ(psiZ: number, n: number, wavelength: number, z: number) {
        return Math.sqrt(((z * wavelength) / (Math.PI * n)) * (1 / Math.tan(psiZ)));
    }
    //__________________________________________________________________________________________
    private _calcomegaRFromOmega0(omega0: number) {
        return (Math.SQRT2 * omega0);
    }
    //__________________________________________________________________________________________
    private _calcThetaFromOmega0(omega0: number, n: number, wavelength: number) {
        return (wavelength / (Math.PI * n * omega0));
    }
    //__________________________________________________________________________________________
    private _calcThetaFromZr(zR: number, n: number, wavelength: number) {
        return Math.sqrt(wavelength / (Math.PI * n * zR));
    }
    //__________________________________________________________________________________________
    private _calcOmegaZFromOmega0(omega0: number, n: number, wavelength: number, z: number) {
        let Zr = this._calcZrFromOmega0(omega0, n, wavelength);
        return (omega0 * Math.sqrt(1 + Math.pow((z / Zr), 2)));
    }
    //__________________________________________________________________________________________
    private _calcRzFromOmega0(omega0: number, n: number, wavelength: number, z: number) {
        let zR = this._calcZrFromOmega0(omega0, n, wavelength);
        return (z * (1 + Math.pow((zR / z), 2)));
    }
    //__________________________________________________________________________________________
    private _calcRzFromZr(zR: number, z: number) {
        return (z * (1 + Math.pow((zR / z), 2)));
    }
    //__________________________________________________________________________________________
    private _calcPsiZFromOmega0(omega0: number, n: number, wavelength: number, z: number) {
        let Zr = this._calcZrFromOmega0(omega0, n, wavelength);
        return Math.atan(z / Zr);
    }
    //__________________________________________________________________________________________
    private _calcPsiZFromZr(Zr: number, _n: number, _wavelength: number, z: number) {
        return Math.atan(z / Zr);
    }
    //__________________________________________________________________________________________
    private _calcPsiZFromRz(rZ: number, _n: number, _wavelength: number, z: number) {
        return Math.atan(Math.sqrt((rZ / z - 1)));
    }
    //__________________________________________________________________________________________
    private _calc() {
        let aFixed = this.mSettings.decimalPrecision;

        let omega0 = this.mElements.omega_0.input.actualValue;
        let wavelength = this.mWavelength.actualValue;
        let z = this.mElements.z.input.actualValue;
        let n = this.mRefIndex.actualValue;

        let Zr = ((Math.PI * (omega0 * omega0) * n) / wavelength);
        let ZrScale = this.mElements.z_r.units.scale;
        this.mElements.z_r.input.value = (OP3DMathUtils.toFixed((Zr / ZrScale), aFixed) + ' ' +
            this.mElements.z_r.units.sign);
        this.mElements.z_r.input.prevValue = this.mElements.z_r.input.value;
        this.mElements.z_r.input.actualValue = Zr;

        let b = (2 * Zr);
        let bScale = this.mElements.b.units.scale;
        this.mElements.b.input.value = (OP3DMathUtils.toFixed((b / bScale), aFixed) + ' ' +
            this.mElements.b.units.sign);
        this.mElements.b.input.prevValue = this.mElements.b.input.value;
        this.mElements.b.input.actualValue = b;

        let omegaR = (Math.SQRT2 * omega0);
        let omegaRScale = this.mElements.omega_r.units.scale;
        this.mElements.omega_r.input.value = (OP3DMathUtils.toFixed((omegaR / omegaRScale),
            aFixed) + ' ' + this.mElements.omega_r.units.sign);
        this.mElements.omega_r.input.prevValue = this.mElements.omega_r.input.value;
        this.mElements.omega_r.input.actualValue = omegaR;

        let theta = (wavelength / (Math.PI * n * omega0));
        let thetaScale = this.mElements.theta.units.scale;
        this.mElements.theta.input.value = (OP3DMathUtils.toFixed((theta / thetaScale), aFixed) +
            ' ' + this.mElements.theta.units.sign);
        this.mElements.theta.input.prevValue = this.mElements.theta.input.value;
        this.mElements.theta.input.actualValue = theta;

        let omegaZ = (omega0 * Math.sqrt(1 + ((z * z) / (Zr * Zr))));
        let omegaZScale = this.mElements.omega_z.units.scale;
        this.mElements.omega_z.input.value =
            (OP3DMathUtils.toFixed((omegaZ / omegaZScale), aFixed) + ' ' +
                this.mElements.omega_z.units.sign);
        this.mElements.omega_z.input.prevValue = this.mElements.omega_z.input.value;
        this.mElements.omega_z.input.actualValue = omegaZ;

        let rZ = (z * (1 + ((Zr * Zr) / (z * z))));
        let rZScale = this.mElements.r_z.units.scale;
        this.mElements.r_z.input.value = (OP3DMathUtils.toFixed((rZ / rZScale), aFixed) + ' ' +
            this.mElements.r_z.units.sign);
        this.mElements.r_z.input.prevValue = this.mElements.r_z.input.value;
        this.mElements.r_z.input.actualValue = rZ;

        let psiZ = Math.atan(z / Zr);
        let psiScale = this.mElements.r_z.units.scale;
        this.mElements.psi_z.input.value = (OP3DMathUtils.toFixed((psiZ / psiScale), aFixed) + ' ' +
            this.mElements.psi_z.units.sign);
        this.mElements.psi_z.input.prevValue = this.mElements.psi_z.input.value;
        this.mElements.psi_z.input.actualValue = psiZ;
    }
    //__________________________________________________________________________________________
    private _initRB() {
        this.mRBGroup = new RBGroup(this._getPart('rb_group'));
        this._updatePermission();
    }
    //__________________________________________________________________________________________
    private _onChangeRB(pPermission: eDataPermission) {
        for (let element in this.mElements) {
            let aElement = this.mElements[element] as iGBCObject;

            if (true == aElement.isPremium) {
                let aInputCot = aElement.input.parentElement;
                ViewUtils.setClassState(aInputCot, 'calc_target',
                    eDataPermission.PUBLIC == pPermission);
            }
        }
    }
    //__________________________________________________________________________________________
    private _updatePermission() {
        let aIsFreeUser = Op3dContext.USER_PERMISSION.isFreeUser;
        let aDefVal = (true == aIsFreeUser) ? eDataPermission.PUBLIC : eDataPermission.PRIVATE;

        this.mRBGroup.update({
            radioButtons: [
                {
                    label: 'Basic',
                    value: eDataPermission.PUBLIC
                },
                {
                    label: 'Extended',
                    value: eDataPermission.PRIVATE,
                    isPremiumFeature: true
                }
            ],
            title: 'Calculate',
            defaultValue: aDefVal,
            onChange: (pValue) => this._onChangeRB(pValue)
        });

        this.mRBGroup.show(aIsFreeUser);
        this._onChangeRB(aDefVal);
    }
    //__________________________________________________________________________________________
    private async _initMaterialSelect() {
        this.mMaterialSelect = new CustomSelect(this._getPart('material_select'),
            {
                labelOptions: {
                    label: 'From material',
                    checkbox: true,
                    onCheckboxChange: (pVal) => {
                        ViewUtils.setClassState(this.mRefIndex.parentElement, 'readonly', pVal),
                            this.mRefIndex.readOnly = pVal;
                        this._onChangeMaterial();
                    },
                    defaultCheckboxState: false
                },
                staticPostion: true,
                placeHolder: 'Choose material',
                search: true,
                onChange: () => this._onChangeMaterial()
            });
        let aMaterials = await Op3dContext.DATA_MANAGER.getMaterials();
        let aOptions = aMaterials.map((val) => {
            let aOption: iCustomSelectOption = {
                value: val.number_id,
                text: val.name,
                enable: true
            };

            return aOption;
        });

        this.mMaterialSelect.setOptions(aOptions, 'n-bk7_schott');
        this._calc();
    }
    //__________________________________________________________________________________________
    private async _onChangeMaterial() {
        if ((null == this.mMaterialSelect) || (false == this.mMaterialSelect.enabled)) {
            return;
        }

        let aNumberID = this.mMaterialSelect.value;
        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({
            number_id: aNumberID
        });

        let aIsConstant = (eMaterialEquationType.CONSTANT == aMaterialVO.parameters.type);
        let aCurrW = parseFloat(this.mWavelength.value);
        let aNewW = aCurrW;
        if (false == aIsConstant) {
            let aStartLambda = aMaterialVO.parameters.startLambda;
            let aEndLambda = aMaterialVO.parameters.endLambda;

            aNewW = OP3DMathUtils.clampValue(aCurrW, aStartLambda, aEndLambda);
            this.mWavelength.value = aNewW + '';
        }


        let n = MaterialUtils.getN(aMaterialVO, aNewW);
        this.mRefIndex.value = n + '';
        this.mRefIndex.dispatchEvent(new Event('change'));
    }
    //__________________________________________________________________________________________
    private _getGraphHash() {
        let aGraphEqHash: iHash<iHash<(pData: iHash<number>) => number>> = {};

        //ω0
        aGraphEqHash[eGBCParams.OMEGA_0] = {};
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.PSI_Z] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_psiZ(pData[eGBCParams.PSI_Z],
                pData.wavelenght, pData.n, pData.z);
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.B] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_b(pData[eGBCParams.B], pData.wavelenght,
                pData.n);
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.THETA] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_theta(pData[eGBCParams.THETA],
                pData.wavelenght, pData.n);
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.OMEGA_R] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_omegaR(pData[eGBCParams.OMEGA_R]);
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.R_Z] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_rZ(pData[eGBCParams.R_Z], pData.wavelenght,
                pData.n, pData.z);
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.OMEGA_Z] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_omegaZ(pData[eGBCParams.OMEGA_Z],
                pData.wavelenght, pData.n, pData.z);
        aGraphEqHash[eGBCParams.OMEGA_0][eGBCParams.Z_R] = (pData) =>
            GaussianBeamCalculator.calcOmega0From_Zr(pData[eGBCParams.Z_R], pData.wavelenght,
                pData.n);


        //zR
        aGraphEqHash[eGBCParams.Z_R] = {};
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.OMEGA_0] = (pData) =>
            this._calcZrFromOmega0(pData.omega_0, pData.n, pData.wavelenght);
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.B] = (pData) => (pData[eGBCParams.B] / 2);
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.OMEGA_R] = (pData) =>
            this._calcZrFromOmegaR(pData[eGBCParams.OMEGA_R], pData.n, pData.wavelenght);
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.OMEGA_Z] = (pData) =>
            this._calcZrFromOmegaZ(pData[eGBCParams.OMEGA_Z], pData.n, pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.THETA] = (pData) =>
            this._calcZrFromTheta(pData[eGBCParams.THETA], pData.n, pData.wavelenght);
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.R_Z] = (pData) =>
            this._calcZrFromRz(pData[eGBCParams.R_Z], pData.n, pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.Z_R][eGBCParams.PSI_Z] = (pData) =>
            this._calcZrFromPsiZ(pData[eGBCParams.PSI_Z], pData.n, pData.wavelenght, pData.z);


        //b
        aGraphEqHash[eGBCParams.B] = {};
        aGraphEqHash[eGBCParams.B][eGBCParams.OMEGA_0] = (pData) =>
            (2 * this._calcZrFromOmega0(pData[eGBCParams.OMEGA_0], pData.n, pData.wavelenght));
        aGraphEqHash[eGBCParams.B][eGBCParams.Z_R] = (pData) => (2 * pData[eGBCParams.Z_R]);
        aGraphEqHash[eGBCParams.B][eGBCParams.OMEGA_R] = (pData) =>
            (2 * this._calcZrFromOmegaR(pData[eGBCParams.OMEGA_R], pData.n, pData.wavelenght));
        aGraphEqHash[eGBCParams.B][eGBCParams.OMEGA_Z] = (pData) =>
            (2 * this._calcZrFromOmegaZ(pData[eGBCParams.OMEGA_Z], pData.n, pData.wavelenght, pData.z));
        aGraphEqHash[eGBCParams.B][eGBCParams.THETA] = (pData) =>
            (2 * this._calcZrFromTheta(pData[eGBCParams.THETA], pData.n, pData.wavelenght));
        aGraphEqHash[eGBCParams.B][eGBCParams.R_Z] = (pData) =>
            (2 * this._calcZrFromRz(pData[eGBCParams.R_Z], pData.n, pData.wavelenght, pData.z));
        aGraphEqHash[eGBCParams.B][eGBCParams.PSI_Z] = (pData) =>
            (2 * this._calcZrFromPsiZ(pData[eGBCParams.PSI_Z], pData.n, pData.wavelenght, pData.z));


        //ωR
        aGraphEqHash[eGBCParams.OMEGA_R] = {};
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.OMEGA_0] = (pData) =>
            this._calcomegaRFromOmega0(pData.omega_0);
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.Z_R] = (pData) =>
        (Math.SQRT2 * GaussianBeamCalculator.calcOmega0From_Zr(pData[eGBCParams.Z_R],
            pData.wavelenght, pData.n));
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.B] = (pData) =>
        (Math.SQRT2 * GaussianBeamCalculator.calcOmega0From_b(pData[eGBCParams.B],
            pData.wavelenght, pData.n));
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.OMEGA_Z] = (pData) =>
        (Math.SQRT2 * GaussianBeamCalculator.calcOmega0From_omegaZ(pData[eGBCParams.OMEGA_Z],
            pData.wavelenght, pData.n, pData.z));
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.THETA] = (pData) =>
        (Math.SQRT2 * GaussianBeamCalculator.calcOmega0From_theta(pData[eGBCParams.THETA],
            pData.wavelenght, pData.n));
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.R_Z] = (pData) =>
        (Math.SQRT2 * GaussianBeamCalculator.calcOmega0From_rZ(pData[eGBCParams.R_Z],
            pData.wavelenght, pData.n, pData.z));
        aGraphEqHash[eGBCParams.OMEGA_R][eGBCParams.PSI_Z] = (pData) =>
        (Math.SQRT2 * GaussianBeamCalculator.calcOmega0From_psiZ(pData[eGBCParams.PSI_Z],
            pData.wavelenght, pData.n, pData.z));

        //ωZ
        aGraphEqHash[eGBCParams.OMEGA_Z] = {};
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.OMEGA_0] = (pData) =>
            this._calcOmegaZFromOmega0(pData[eGBCParams.OMEGA_0], pData.n, pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.Z_R] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_Zr(pData[eGBCParams.Z_R],
                pData.wavelenght, pData.n);
            return this._calcOmegaZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        };
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.B] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_b(pData[eGBCParams.B],
                pData.wavelenght, pData.n);
            return this._calcOmegaZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        };
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.OMEGA_R] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_omegaR(
                pData[eGBCParams.OMEGA_R]);
            return this._calcOmegaZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        };
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.THETA] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_theta(pData[eGBCParams.THETA],
                pData.wavelenght, pData.n);
            return this._calcOmegaZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        };
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.R_Z] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_rZ(pData[eGBCParams.R_Z],
                pData.wavelenght, pData.n, pData.z);
            return this._calcOmegaZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        };
        aGraphEqHash[eGBCParams.OMEGA_Z][eGBCParams.PSI_Z] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_psiZ(pData[eGBCParams.PSI_Z],
                pData.wavelenght, pData.n, pData.z);
            return this._calcOmegaZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        };

        //θ
        aGraphEqHash[eGBCParams.THETA] = {};
        aGraphEqHash[eGBCParams.THETA][eGBCParams.OMEGA_0] = (pData) =>
            this._calcThetaFromOmega0(pData[eGBCParams.OMEGA_0], pData.n, pData.wavelenght);
        aGraphEqHash[eGBCParams.THETA][eGBCParams.Z_R] = (pData) =>
            this._calcThetaFromZr(pData[eGBCParams.Z_R], pData.n, pData.wavelenght);
        aGraphEqHash[eGBCParams.THETA][eGBCParams.B] = (pData) =>
            this._calcThetaFromZr((pData[eGBCParams.B] / 2), pData.n, pData.wavelenght);
        aGraphEqHash[eGBCParams.THETA][eGBCParams.OMEGA_R] = (pData) =>
            this._calcThetaFromOmega0((pData[eGBCParams.OMEGA_R] * Math.SQRT1_2), pData.n,
                pData.wavelenght);
        aGraphEqHash[eGBCParams.THETA][eGBCParams.OMEGA_Z] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_omegaZ(
                pData[eGBCParams.OMEGA_Z], pData.wavelenght, pData.n, pData.z);
            return this._calcThetaFromOmega0(aOmega0, pData.n, pData.wavelenght);
        }
        aGraphEqHash[eGBCParams.THETA][eGBCParams.R_Z] = (pData) => {
            let aZr = this._calcZrFromRz(pData[eGBCParams.R_Z], pData.wavelenght, pData.n,
                pData.z);
            return this._calcThetaFromZr(aZr, pData.n, pData.wavelenght);
        }
        aGraphEqHash[eGBCParams.THETA][eGBCParams.PSI_Z] = (pData) => {
            let aZr = this._calcZrFromPsiZ(pData[eGBCParams.PSI_Z], pData.wavelenght, pData.n,
                pData.z);
            return this._calcThetaFromZr(aZr, pData.n, pData.wavelenght);
        }

        //Rz
        aGraphEqHash[eGBCParams.R_Z] = {};
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.OMEGA_0] = (pData) =>
            this._calcRzFromOmega0(pData[eGBCParams.OMEGA_0], pData.n, pData.wavelenght,
                pData.z);
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.Z_R] = (pData) =>
            this._calcRzFromZr(pData[eGBCParams.Z_R], pData.z);
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.B] = (pData) =>
            this._calcRzFromZr((pData[eGBCParams.B] / 2), pData.z);
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.OMEGA_R] = (pData) =>
            this._calcRzFromOmega0((pData[eGBCParams.OMEGA_R] * Math.SQRT1_2), pData.n,
                pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.OMEGA_Z] = (pData) => {
            let aZr = this._calcZrFromOmegaZ(pData[eGBCParams.OMEGA_Z], pData.n, pData.wavelenght, pData.z);
            return this._calcRzFromZr(aZr, pData.z);
        }
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.THETA] = (pData) => {
            let aZr = this._calcZrFromTheta(pData[eGBCParams.THETA], pData.n, pData.wavelenght);
            return this._calcRzFromZr(aZr, pData.z);
        }
        aGraphEqHash[eGBCParams.R_Z][eGBCParams.PSI_Z] = (pData) => {
            let aZr = this._calcZrFromPsiZ(pData[eGBCParams.PSI_Z], pData.n, pData.wavelenght,
                pData.z);
            return this._calcRzFromZr(aZr, pData.z);
        }

        //ψz
        aGraphEqHash[eGBCParams.PSI_Z] = {};
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.OMEGA_0] = (pData) =>
            this._calcPsiZFromOmega0(pData[eGBCParams.OMEGA_0], pData.n, pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.Z_R] = (pData) =>
            this._calcPsiZFromZr(pData[eGBCParams.Z_R], pData.n, pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.B] = (pData) =>
            this._calcPsiZFromZr((pData[eGBCParams.B] / 2), pData.n, pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.OMEGA_R] = (pData) =>
            this._calcPsiZFromOmega0((pData[eGBCParams.OMEGA_R] / Math.SQRT2), pData.n,
                pData.wavelenght, pData.z);
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.OMEGA_Z] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_omegaZ(
                pData[eGBCParams.OMEGA_Z], pData.wavelenght, pData.n, pData.z);
            return this._calcPsiZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        }
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.THETA] = (pData) => {
            let aOmega0 = GaussianBeamCalculator.calcOmega0From_theta(pData[eGBCParams.THETA],
                pData.wavelenght, pData.n);
            return this._calcPsiZFromOmega0(aOmega0, pData.n, pData.wavelenght, pData.z);
        }
        aGraphEqHash[eGBCParams.PSI_Z][eGBCParams.R_Z] = (pData) =>
            this._calcPsiZFromRz(pData[eGBCParams.R_Z], pData.n, pData.wavelenght, pData.z);

        return aGraphEqHash;
    }
    //__________________________________________________________________________________________
}