import OptiChatBotMessage from "./chat/OptiChatBotMessage";
import OptiChatReviewMessage from "./review/OptiChatReviewMessage";
import OptiChatUserMessage from "./chat/OptiChatUserMessage";
import { iOptiChatMessage, eChatMessageType } from "../store/OptiChatContext";

export class OptiChatMessagesFactory {
  private static MESSAGES_COUNTER = 0;

  public static getMessage(pItem: iOptiChatMessage, pOnFinishReview: Function) {
    switch (pItem.type) {
      case eChatMessageType.USER_MESSAGE:
        return (
          <OptiChatUserMessage
            key={`message_${this.MESSAGES_COUNTER++}`}
            data={pItem}
          />
        );
      case eChatMessageType.ERROR_RESPONSE:
      case eChatMessageType.CHATBOT_MESSAGE:
        return (
          <OptiChatBotMessage
            key={`message_${this.MESSAGES_COUNTER++}`}
            data={pItem}
          />
        );
      case eChatMessageType.REVIEW_MESSAGE:
        return (
          <OptiChatReviewMessage
            onFinishReview={pOnFinishReview}
            key={`message_${this.MESSAGES_COUNTER++}`}
            data={pItem}
          />
        );

      default:
        throw new Error("unhandeled type");
    }
  }
}
