import { EventBase } from "../../../../oc/events/EventBase";
import { EventManager } from "../../../../oc/events/EventManager";
import { eAxisType, eDataPermission } from "../../../_context/Enums";
import { EventsContext } from "../../../_context/EventsContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { eBaseShape, OpticsContext, eOpticShape } from "../../../_context/OpticsContext";
import { Strings } from "../../../_context/Strings";
import { iChooseOpticsEvent } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOpticsVOGeometry, iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../../data/data_loader/OpticsDataLoader";
import { Part } from "../../../parts/Part";
import { iPart } from "../../../parts/PartInterfaces";
import { OpticsShapeUtils } from "../../../parts/optics/OpticShapeUtils";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Spinner } from "../../home/Spinner";
import { Popup } from "../Popup";
import { Optics3DPresenter } from "../tools/Optics3DPresenter";
import { uoUserApertureInfo } from "./_apertures/uoUserApertureInfo";
import { iApertureReturnData } from "./new/UploadOpticsContext";
import { uoBasicInfo } from "./uoBasicInfo";
import { uoGeometricalInfo } from "./uoGeometricalInfo";
import { uoMaterial } from "./uoMaterial";
import { uoPhysicalInfo } from "./uoPhysicalInfo";
import { uoSection } from "./uoSection";
import { uoSurfaces } from "./uoSurfaces";

export interface i3DOpenData {
    show_image: boolean
}

export interface iOpticsFormSections {
    basicInfo: uoBasicInfo;
    material: Array<uoMaterial>;
    physicalInfo: uoPhysicalInfo;
    geometricalInfo: uoGeometricalInfo;
    surfacesInfo: uoSurfaces;
    userApertureInfo: uoUserApertureInfo;
};

export interface iOpticsCreateParams {
    name: string;
    geometry: iOpticsVOGeometry;
    materialID: string;
};

export interface iOpticsFormParams {
    opticsVO: iOpticsVO;
    opticsPart: iPart;
    part: Part;
    presenter_3d: i3DOpenData,
    callback?: (pData: {
        opticsVO: iOpticsVO, phase_mask?:
        { file: File, name: string },
        transmittance_mask?: { file: File, name: string }
    }) => void;
};

export class OpticsForm extends Op3dComponentBase<iOpticsFormParams> {

    private static INSTANCE: OpticsForm;

    private mSections!: iOpticsFormSections;
    private mOptics3DPresenter: Optics3DPresenter;
    private mCurrentOpticsVO: iOpticsVO;
    private mPart: Part;
    private mSaveBtn: HTMLElement;
    private mMaterialsContainer: HTMLElement;

    private mCallback: (pData: {
        opticsVO: iOpticsVO,
        phase_mask?: { file: File, name: string },
        transmittance_mask?: { file: File, name: string }
    }) => void;

    //__________________________________________________________________________________________
    constructor(pElement: HTMLElement) {
        super({
            container: pElement,
            skinPath: './skins/forms/optics/upload_optics_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == OpticsForm.INSTANCE) {
            let aForms = document.getElementById('forms');
            if (aForms === null) {
                throw new Error("No forms container found");
            }
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aForms.appendChild(aContainer);

            OpticsForm.INSTANCE = new OpticsForm(aContainer);
        }

        return OpticsForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _prepareForm(): Promise<void> {
        while (!this.mIsReady || !this.mOptics3DPresenter.isReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    private _onOpen3DPresenter(pOpticsVO: iOpticsVO, p3DPresenterData: i3DOpenData) {

        this.mOptics3DPresenter.onRefreshOptic(pOpticsVO);

        this.mOptics3DPresenter.showImageBtn = (p3DPresenterData.show_image);
        if (p3DPresenterData.show_image) {
            this.mOptics3DPresenter.updateImage(
                pOpticsVO.parameters.type!,
                pOpticsVO.parameters.subType!,
                pOpticsVO.parameters.info!.brand,
                (eBaseShape.RECTANGULAR == pOpticsVO.parameters.baseShape));
        }
        this.mOptics3DPresenter.toUpdate = true;
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pOpticsFormParams: iOpticsFormParams) {
        Spinner.instance.show();
        this._clear();

        this.mPart = pOpticsFormParams.part;
        const aOpticsVO = pOpticsFormParams.opticsVO;

        this._onOpen3DPresenter(aOpticsVO, pOpticsFormParams.presenter_3d);
        this.mCurrentOpticsVO = aOpticsVO;
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement!, true);

        await this.mSections.basicInfo.setData(aOpticsVO);
        let aMaterials = aOpticsVO.parameters.materialID.split(';');
        for (let i = 0; i < aMaterials.length; i++) {
            if ('' == aMaterials[i]) {
                continue;
            }

            let aContainer = document.createElement('div');
            aContainer.classList.add('col-12');
            aContainer.classList.add('px-0');
            aContainer.classList.add('upload_optics_section');
            aContainer.style.borderTop = 'none';
            aContainer.setAttribute("qa_id", "qa_material_section_" + (i + 1));

            this.mMaterialsContainer.appendChild(aContainer);
            let aTitle = 'Material ';
            if (aMaterials.length > 1) {
                aTitle += ((i + 1) + ' ');
            }
            aTitle += 'info';

            let aUOMaterial = new uoMaterial(aContainer, aTitle
            );
            this.mSections.material.push(aUOMaterial);
            await aUOMaterial.setData({
                materialID: aMaterials[i],
                wavelength: aOpticsVO.parameters.wavelength
            });

            aUOMaterial.show(Strings.TRANSPARENT_MATERIAL != aMaterials[i]);
        }

        await this.mSections.physicalInfo.setData(aOpticsVO.parameters.physical_data);
        this.mSections.geometricalInfo.show(OpticsContext.showGeometrialInfo(aOpticsVO));
        await this.mSections.geometricalInfo.setData(aOpticsVO);

        let aToShowapertureData = OpticsContext.showUserApertureInfo(aOpticsVO)
        this.mSections.userApertureInfo.show(aToShowapertureData);
        if (aToShowapertureData) {
            await this.mSections.userApertureInfo.setData(aOpticsVO);
        }

        let aShowSurfacesSection = OpticsContext.showSurfacesSection(aOpticsVO);
        this.mSections.surfacesInfo.show(aShowSurfacesSection);
        if (true == aShowSurfacesSection) {
            await this.mSections.surfacesInfo.setData({
                mouseenter: (pFaceName) => this.mOptics3DPresenter.highlightMesh(pFaceName),
                mouseleave: () => this.mOptics3DPresenter.unHighlightMesh(),
                optics: pOpticsFormParams.opticsPart,
                part: pOpticsFormParams.part
            });
        }

        if (Op3dContext.USER_VO.isAdmin) {
            console.log('%c' + "ID number is: " + aOpticsVO.number_id,
                'font-size:15px; margin:2px; background: #40E0D0; color: #000000');

            console.log('%c' + "coating data is: " + aOpticsVO.parameters.coating,
                'font-size:15px; margin:2px; background: #40E0D0; color: #000000');
        }

        this.mCallback = pOpticsFormParams.callback;
        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    protected _onClose(): void {
        this.mOptics3DPresenter.toUpdate = false;
        this._clear();
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mMaterialsContainer, 0);
        this.mSections.material.forEach(item => item.discard());
        this.mSections.material.splice(0);
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        super._initElements();
        this.mSaveBtn = this._getPart('save_btn');

        this._initSections();
        this._init3DPresenter();
    }
    //__________________________________________________________________________________________
    private _init3DPresenter() {
        this.mOptics3DPresenter = new Optics3DPresenter(
            this._getPart('optic-preview-element'), {
            isFixedDiv: false,
            alwaysShowImage: false
        });
    }
    //__________________________________________________________________________________________
    private async _initSections() {
        this.mSections = {
            basicInfo: new uoBasicInfo(this._getPart('basic_info_section')),
            geometricalInfo: new uoGeometricalInfo(this._getPart('geometrial_info_section')),
            material: new Array<uoMaterial>(),
            physicalInfo: new uoPhysicalInfo(this._getPart('physical_info_section')),
            surfacesInfo: new uoSurfaces(this._getPart('surfaces_section')),
            userApertureInfo: new uoUserApertureInfo(this._getPart("aperture_info_section"))
        };

        this.mMaterialsContainer = this._getPart('material_sections');

        await Op3dContext.wait(() => {
            for (let section in this.mSections) {
                if (true != this.mSections[section].isReady) {
                    return false;
                }
            }

            return true;
        });
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mSaveBtn.addEventListener('click', () => this._onSave());

        EventManager.addEventListener(EventsContext.OPTICS_FORM_CHANGE,
            (pData: EventBase) => this._updateOpticsVO(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_APERTURE_CHANGED,
            (pData: EventBase) => this._updateOpticsVO(pData.sender), this);

        EventManager.addEventListener(EventsContext.MATERIAL_WL_CHANGED,
            (pData: EventBase<number>) => this._updateDBWavelength(pData.data, pData.sender),
            this);

        EventManager.addEventListener(EventsContext.OPTICS_MAT_CHANGE,
            (pData: EventBase) => this._updateOpticsVO(pData.sender), this);

        EventManager.addEventListener(EventsContext.OPTICS_BASIC_CHANGE,
            (pData: EventBase) => this._updateOpticsVO(pData.sender), this);
    }
    //__________________________________________________________________________________________
    private async _onSave() {
        if (Op3dContext.USER_VO.isBasicLicense) {
            return;
        }
        let aMaterialsIDs = '';
        for (let i = 0; i < this.mSections.material.length; i++) {
            if (i > 0) {
                aMaterialsIDs += ';';
            }
            aMaterialsIDs += this.mSections.material[i].getData();
        }

        let aCurrOptics: iOpticsCreateParams = {
            name: this.mSections.basicInfo.name,
            geometry: this.mSections.geometricalInfo.getData(),
            materialID: aMaterialsIDs,
        };

        await this._createNewOptics(aCurrOptics);


    }
    //__________________________________________________________________________________________
    private async _updateDBWavelength(pWavelenght: number, pSender: uoMaterial) {
        if (this.mSections.material.indexOf(pSender) == -1) {
            return;
        }
        let aOpticsVO = this._getOpticsVO();
        aOpticsVO.parameters.wavelength = pWavelenght;
        await this.mSections.geometricalInfo.setData(aOpticsVO);
    }

    //__________________________________________________________________________________________
    private _isSectionExists(pSection: uoSection) {
        for (let key in this.mSections) {
            if (this.mSections[key] instanceof Array) {
                let aIdx = this.mSections[key].findIndex(item => item == pSection);
                if (aIdx != -1) {
                    return true;
                }
            } else {
                if (this.mSections[key] == pSection) {
                    return true;
                }
            }
        }

        return false;
    }
    //__________________________________________________________________________________________
    private async _updateOpticsVO(pSender: uoMaterial) {
        if (this._isSectionExists(pSender) == false) {
            // if the sender is not one of our sections then we dont want to update optics vo
            return;
        }

        let aOpticsVO = this._getOpticsVO();
        if (this.mCurrentOpticsVO == aOpticsVO) {
            let aIsNameChanged = (this.mSections.basicInfo.name != this.mCurrentOpticsVO.name);

            // ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, (false == aIsNameChanged));
            ViewUtils.setElementDisabled(this.mSaveBtn.parentElement,
                Op3dContext.USER_VO.isBasicLicense == true || (false == aIsNameChanged));
            // can happend when the user have changes the element and then set to the initial parameters
            this.mOptics3DPresenter.onRefreshOptic(aOpticsVO, { fitToZoom: false });
            return;
        }

        if (aOpticsVO.parameters.subType == OpticsContext._User_Aperture) {
            if (aOpticsVO.parameters.physical_data.transmittance_mask.name == null &&
                aOpticsVO.parameters.physical_data.phase_mask.name == null ||
                (aOpticsVO.parameters.geometry.width == 0 || aOpticsVO.parameters.geometry.height == 0)) {

                ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, true);
                // if this is a aperture and no image was loaded the save option is disabled
                return;
            }
        }

        // ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, false);
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, Op3dContext.USER_VO.isBasicLicense == true);
        await this.mSections.geometricalInfo.setData(aOpticsVO);
        await this.mSections.basicInfo.setData(aOpticsVO);
        this.mOptics3DPresenter.onRefreshOptic(aOpticsVO, { fitToZoom: false });
    }
    //__________________________________________________________________________________________
    private _getGeometryEditedOpticsVO(pGeometryInfo: iOpticsVOGeometry) {
        let aDuplicateOpticsVO: iOpticsVO = DataUtils.getObjectCopy(this.mCurrentOpticsVO);
        let aDeformations = DataUtils.getObjectCopy(aDuplicateOpticsVO.parameters.geometry.deformation);
        aDuplicateOpticsVO.parameters.geometry = pGeometryInfo;
        if (null != aDeformations) {
            aDuplicateOpticsVO.parameters.geometry.deformation = aDeformations;
        }

        return aDuplicateOpticsVO;
    }
    //__________________________________________________________________________________________
    private async _createNewOptics(pOpticsCreateParams: iOpticsCreateParams) {
        Spinner.instance.show();
        // ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, false);
        ViewUtils.setElementDisabled(this.mSaveBtn.parentElement, Op3dContext.USER_VO.isBasicLicense == true);
        try {

            let aDuplicateOpticsVO = this._getGeometryEditedOpticsVO(pOpticsCreateParams.geometry);
            let aPhysicalData = this.mSections.physicalInfo.getData();
            if (aPhysicalData != null) {
                aDuplicateOpticsVO.parameters.physical_data = DataUtils.getObjectCopy(aPhysicalData);
            }

            aDuplicateOpticsVO.number_id = Op3dUtils.idGenerator();
            aDuplicateOpticsVO.parameters.materialID = pOpticsCreateParams.materialID;
            aDuplicateOpticsVO.permission = eDataPermission.PRIVATE;
            aDuplicateOpticsVO.name = pOpticsCreateParams.name;
            aDuplicateOpticsVO.parameters.info.weblinks = null;
            aDuplicateOpticsVO.parameters.subType = OpticsShapeUtils.getSubtype(aDuplicateOpticsVO);

            let aApertureData: iApertureReturnData;
            if (aDuplicateOpticsVO.parameters.subType == OpticsContext._User_Aperture) {
                aApertureData = this.mSections.userApertureInfo.getData();
                this._fillApertureData(aApertureData, aDuplicateOpticsVO);
            }

            if (null != this.mCallback) {
                this.mCallback({
                    opticsVO: aDuplicateOpticsVO,
                    phase_mask: aApertureData != null ? aApertureData.phase_mask : null,
                    transmittance_mask: aApertureData != null ? aApertureData.transmittance_mask : null
                });

            } else {
                await this._uploadNewOpticalElement(aApertureData, aDuplicateOpticsVO);
            }

            Op3dContext.DIV_CONTROLLER.updatePartInfo();

        } catch (e) {

            Popup.instance.open({
                text: MessagesHandler.OPTICAL_ELEMENT_CREATION_ERROR,
            })
            return;

        } finally {
            Spinner.instance.hide();
        }

        this.close();
    }
    //__________________________________________________________________________________________
    private async _uploadNewOpticalElement(_pApertureData: iApertureReturnData, pOpticsVO: iOpticsVO) {
        if (pOpticsVO.parameters.subType == OpticsContext._User_Aperture) {

            // try {
            //     await ServerContext.SERVER.addAperture({
            //         data: pOpticsVO,
            //         images: {
            //             phase: pApertureData != null ? pApertureData.phase_mask.file : null,
            //             transmittance: pApertureData != null ? pApertureData.transmittance_mask.file : null,
            //         }
            //     });
            // } catch (e) {
            //     NotificationCenter.instance.pushNotification({
            //         message: MessagesHandler.FAILED_UPLOAD_APERTURE,
            //         params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            //     });
            //     return;
            // }

            // try {
            //     const aNewOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
            //         number_id: pOpticsVO.number_id
            //     });

            //     pOpticsVO = aNewOpticsVO;

            // } catch (e) {
            //     NotificationCenter.instance.pushNotification({
            //         message: MessagesHandler.APERTURE_CREATION_ERROR,
            //         params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            //     });
            // }

        } else {
            OpticsDataLoader.instance.addItemManually(pOpticsVO);
            await OpticsDataLoader.instance.add(pOpticsVO);
        }

        let aPartForOpticsMenu = this.mPart;
        if (eAxisType.OPTICS === this.mPart.refCS.cs.type) {
            aPartForOpticsMenu = this.mPart.refCS.refPart;
        }

        let aOpticsData: iChooseOpticsEvent = {
            part: aPartForOpticsMenu,
            opticsVO: pOpticsVO,
            copy_data: true
        };
        Op3dContext.PARTS_MANAGER.chooseOptics(aOpticsData);

    }
    //__________________________________________________________________________________________
    private _fillApertureData(pApertureData: iApertureReturnData, pOpticsVO: iOpticsVO) {

        if (pApertureData == null) {
            return;
        }

        /**
         * If we have a file than we will use the file's data, 
         * otherwise if we have a valid url then we are in edit 
         * mode and we will use the saved data if such exists 
         */

        const aTransmittanceMask = pApertureData.transmittance_mask;
        if (aTransmittanceMask.file != null) {
            pOpticsVO.parameters.physical_data.transmittance_mask = {
                name: aTransmittanceMask.file.name,
                url: ""
            }
        } else if (aTransmittanceMask.url != null && aTransmittanceMask.url != "") {
            pOpticsVO.parameters.physical_data.transmittance_mask = {
                name: aTransmittanceMask.name,
                url: aTransmittanceMask.url
            }
        }

        const aPhaseMask = pApertureData.phase_mask;
        if (aPhaseMask.file != null) {
            pOpticsVO.parameters.physical_data.phase_mask = {
                name: aPhaseMask.file.name,
                url: ""
            }
        } else if (aPhaseMask.url != null && aPhaseMask.url != "") {
            pOpticsVO.parameters.physical_data.phase_mask = {
                name: aPhaseMask.name,
                url: aPhaseMask.url
            }
        }
    }
    //__________________________________________________________________________________________
    private _getOpticsVO() {
        let aNewOpticsVO = this.mCurrentOpticsVO;

        let aGeometryInfo = this.mCurrentOpticsVO.parameters.geometry;
        let aEditedGeometryInfo = this.mSections.geometricalInfo.getData();
        for (let key in aEditedGeometryInfo) {
            if (aEditedGeometryInfo[key] != aGeometryInfo[key]) {
                aNewOpticsVO = this._getGeometryEditedOpticsVO(aEditedGeometryInfo);
                break;
            }
        }

        let aName = this.mSections.basicInfo.name;
        if (aName != this.mCurrentOpticsVO.name) {
            aNewOpticsVO = DataUtils.getObjectCopy(aNewOpticsVO);
            aNewOpticsVO.name = aName;
        }

        let aMaterialsIDs = '';
        for (let i = 0; i < this.mSections.material.length; i++) {
            if (i > 0) {
                aMaterialsIDs += ';';
            }
            aMaterialsIDs += this.mSections.material[i].getData();
        }

        if (aMaterialsIDs != this.mCurrentOpticsVO.parameters.materialID) {
            aNewOpticsVO = DataUtils.getObjectCopy(aNewOpticsVO);
            aNewOpticsVO.parameters.materialID = aMaterialsIDs;
        }

        let aCurrSubtype = aNewOpticsVO.parameters.subType;
        let aSubtype = aCurrSubtype;

        let aShape = aNewOpticsVO.parameters.shape;
        switch (aShape) {
            case eOpticShape.SPHERICAL: {
                const aR1 = aNewOpticsVO.parameters.geometry.r1;
                const aR2 = aNewOpticsVO.parameters.geometry.r2;
                const aType = aNewOpticsVO.parameters.type;
                aSubtype = OpticsContext.getSphericalSubtype(aR1, aR2, aType)
            }
                break;
            case eOpticShape.CYLINDRICAL: {
                const aR1X = aNewOpticsVO.parameters.geometry.r1_x;
                const aR1Y = aNewOpticsVO.parameters.geometry.r1_x;
                const aType = aNewOpticsVO.parameters.type;

                aSubtype = OpticsContext.getCylindricalSubtype(aR1X, aR1Y, aType);
            }
                break;
            default:
                break;
        }
        if (aSubtype != aCurrSubtype) {
            aNewOpticsVO = DataUtils.getObjectCopy(aNewOpticsVO);
            aNewOpticsVO.parameters.subType = aSubtype;
        }

        if (aNewOpticsVO.parameters.subType == OpticsContext._User_Aperture) {
            aNewOpticsVO = DataUtils.getObjectCopy(aNewOpticsVO);

            let aApertureData = this.mSections.userApertureInfo.getData();
            if (aApertureData.phase_mask != null) {

                //file was uploaded 
                aNewOpticsVO.parameters.physical_data.phase_mask = {
                    name: aApertureData.phase_mask.name,
                    url: aApertureData.phase_mask.url
                }

            }
            if (aApertureData.transmittance_mask != null) {

                //file was uploaded 
                aNewOpticsVO.parameters.physical_data.transmittance_mask = {
                    name: aApertureData.transmittance_mask.name,
                    url: aApertureData.transmittance_mask.url
                }
            }

            for (let key in aApertureData.geo) {
                aNewOpticsVO.parameters.geometry[key] = aApertureData.geo[key];
            }
        }

        return aNewOpticsVO;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
}
