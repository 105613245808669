import { EventManager } from "../../../oc/events/EventManager";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { iNavBarItem } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ViewUtils } from "../ViewUtils";
import { AnalysisConstants } from "../analysis/AnalysisConstants";

export class MinimizedNavbar {

    private mNavBarItemsParent: HTMLUListElement;
    private mMinimizeElement: HTMLLIElement;
    private mItems: Array<iNavBarItem> = new Array<iNavBarItem>();

    private static INSTANCE: MinimizedNavbar;

    private constructor() {
        this._initElements();

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this.reset(), this);

        EventManager.addEventListener(EventsContext.ON_CLOSE,
            () => this.reset(), this);
    }
    //____________________________________________________________________________
    public static get instance(): MinimizedNavbar {
        if (this.INSTANCE == null) {
            this.INSTANCE = new MinimizedNavbar();
        }

        return this.INSTANCE;
    }
    //____________________________________________________________________________
    private reset() {
        while (this.mItems.length > 0) {
            ViewUtils.removeFromParent(this.mItems[0].htmlItem);
            this.mItems.shift();
        }
    }
    //____________________________________________________________________________
    private _initElements() {
        this.mNavBarItemsParent = document.getElementById("navbar-items-parent") as HTMLUListElement;
        this.mMinimizeElement = document.getElementById("minimize-item") as HTMLLIElement;
        ViewUtils.removeFromParent(this.mMinimizeElement);
    }
    //____________________________________________________________________________
    public addItem(pItemData: iNavBarItem, pTitle?: string) {
        if (this._isExists(pItemData)) {
            return;
        }

        pItemData.isMinimized = true;
        let aCloneItem = this.mMinimizeElement.cloneNode(true) as HTMLElement;
        ViewUtils.setElementVisibilityByDNone(aCloneItem, true);
        aCloneItem.addEventListener("click", () => this._onItemClicked(pItemData));
        this.mNavBarItemsParent.appendChild(aCloneItem);
        let aIconItem = Op3dUtils.getElementIn(aCloneItem, "icon-item", true);
        let aClasses = pItemData.iconClasses.split(' ');
        aIconItem.classList.add(...aClasses);
        aCloneItem.title = pTitle ? pTitle : pItemData.title;
        $(aCloneItem).tooltip();
        pItemData.htmlItem = aCloneItem;
        if (pItemData.isAnalysisItem) {
            const aColor = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.type === pItemData.analysisType && item.name === pItemData.title).color;
            aCloneItem.classList.add(aColor);
            //AnalysisContext.getLabelColorClassByName(pItemData.title as tAnalysisType,pItemData.analysisType))
        }

        this.mItems.push(pItemData);
        MessagesHandler.SHOW_MINIMIZE_MESSAGE();
    }
    //____________________________________________________________________________
    private _isExists(pItemData: iNavBarItem) {
        return (this.mItems.indexOf(pItemData) > -1);
    }
    //____________________________________________________________________________
    public _onItemClicked(pItemData: iNavBarItem) {
        pItemData.showCallback();
        // $(pItemData.htmlItem).tooltip('dispose');
        this.removeItem(pItemData);
    }
    //____________________________________________________________________________
    public removeItem(pItemData: iNavBarItem) {
        $(pItemData.htmlItem).tooltip('dispose');
        let aIndex = this.mItems.indexOf(pItemData);
        if (aIndex != -1) {

            pItemData.isMinimized = false;
            let aHtmlElement = this.mItems[aIndex].htmlItem;
            if (aHtmlElement != null && aHtmlElement.parentElement != null) {
                ViewUtils.removeFromParent(aHtmlElement);
            }

            this.mItems.splice(aIndex, 1);
        }
    }
    //____________________________________________________________________________
}
