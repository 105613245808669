import { ArrowHelper, BufferGeometry, Camera, DoubleSide, Float32BufferAttribute, HemisphereLight, Mesh, MeshPhongMaterial, Object3D, OrthographicCamera, Raycaster, Renderer, Scene, Vector2, Vector3, WebGLRenderer } from "three";
import { EventManager } from "../../oc/events/EventManager";
import { EventsContext } from "../_context/EventsContext";
import { iCubeParams, iCubeClickParams } from "../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../_utils/Op3dUtils";
import { SceneContext } from "./SceneContext";
import { OptixReader } from "../_utils/OptixReader";
import { ServerContext } from "../server/ServerContext";


export enum eViewTypeXYZ {
    XY_VIEW = 'XY-View',
    YZ_VIEW = 'YZ-View',
    XZ_VIEW = 'XZ-View',
    ISO_VIEW = 'ISO-View',
    FOUR_WINDOW_VIEW = 'FourWindow-View',
}
export enum eViewTypeSpheric {
    FRONT = 'Front view',
    RIGHT = 'Right view',
    TOP = 'Top view',
    ISO_VIEW = 'ISO-View',
}

export abstract class absSceneCube {
    protected static MENU_SKIN_PATH = "./skins/home/camera_view_menu.html";
    private static CLASS_NAME = "Cube";
    public static SIZE = 130;

    private mCubeParams: iCubeParams;
    private mInstanceID: string;
    private mRayCaster: Raycaster;
    private mScene: Scene;
    private mCamera: Camera;
    protected mRenderer: WebGLRenderer;
    private mCube: Object3D;

    protected constructor(pCubeParams: iCubeParams) {
        this.mCubeParams = pCubeParams;
        this.mInstanceID = absSceneCube.CLASS_NAME + Op3dUtils.idGenerator();

        this.mRayCaster = new Raycaster();
        this._initScene();
        this._initCamera();
        this._initRenderer();
        this._addEventListeners();

    }
    //_______________________________________________________________________________
    public get cubeRenderer() {
        return this.mRenderer
    }
    //_______________________________________________________________________________
    public async _getOptixFile(pURL: string) {
        return new Promise<File>((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", pURL, true);
            xhr.responseType = "arraybuffer";

            //xhr.setRequestHeader("Content-type", "application/json");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    resolve(xhr.response);
                } else if (xhr.status == ServerContext.NOT_FOUND) {
                    resolve(null);
                }
            }
            xhr.send();
        });
    }
    //_______________________________________________________________________________

    public async getCube() {
        let aURL = 'https://assets-3doptix-staging.s3.amazonaws.com/part_models/cube-3d.optix';

        let aFile = await this._getOptixFile(aURL);
        let aArrayBuffer = await new Blob([aFile]).arrayBuffer();
        let aPart = await new OptixReader().returnFacesMesh(aArrayBuffer);

        let aCubeGeo = new BufferGeometry()
        aCubeGeo.setAttribute('position', new Float32BufferAttribute(aPart[0], 3));
        aCubeGeo.setAttribute('color', new Float32BufferAttribute(aPart[1], 4));
        aCubeGeo.computeVertexNormals();

        let aCubeMat = new MeshPhongMaterial({
            vertexColors: true,
            side: DoubleSide,
            transparent: true
        });

        let aCubeFacesMesh = new Mesh(aCubeGeo, aCubeMat);
        return aCubeFacesMesh;
    }
    //_______________________________________________________________________________
    private async _addCube() {

        // let aCube = await PartsCatalog.instance.getCube() as Mesh
        let aCube = await this.getCube()
        aCube.geometry.center();
        (aCube.material as any).transparent = true;
        (aCube.material as any).opacity = 0.9

        var arrowPos = new Vector3(0, 0, 0);
        this.mScene.add(new ArrowHelper(new Vector3(1, 0, 0), arrowPos, 25, 0xFF0000, 5, 5));
        this.mScene.add(new ArrowHelper(new Vector3(0, 1, 0), arrowPos, 25, 0x00FF00, 5, 5));
        this.mScene.add(new ArrowHelper(new Vector3(0, 0, 1), arrowPos, 25, 0x0000FF, 5, 5));

        this._onCubeLoaded(aCube as Object3D)
    }
    //_______________________________________________________________________________
    protected abstract _initRenderer();
    //_______________________________________________________________________________
    private _initScene() {
        this.mScene = new Scene();
        let aAmbientLight = new HemisphereLight(0xffffff, 2)
        aAmbientLight.intensity = 2.5

        this.mScene.add(aAmbientLight)
        this._addCube();
    }
    //_______________________________________________________________________________
    private async _onCubeLoaded(pCube: Object3D) {

        this.mCube = pCube;
        this.mScene.add(pCube);
        this._showCube(true);
    }
    //_______________________________________________________________________________
    protected abstract _addEventListeners();
    //_______________________________________________________________________________
    private _update(pCamera: Camera, pLookAt: Vector3): void {
        this.mRenderer.render(this.mScene, this.mCamera);
        let aPos = pCamera.position.clone();
        aPos.x -= pLookAt.x;
        aPos.z -= pLookAt.z;
        aPos.y -= pLookAt.y;
        aPos.normalize();
        this.mCamera.position.x = aPos.x * 60;
        this.mCamera.position.y = aPos.y * 60;
        this.mCamera.position.z = aPos.z * 60;
        this.mCamera.lookAt(new Vector3());
    }
    //_______________________________________________________________________________
    public set up(pUpVec: Vector3) {
        this.mCamera.up.copy(pUpVec);
    }
    //_______________________________________________________________________________
    public onClick(e: MouseEvent) {

        const aBB = this.mRenderer.domElement.getBoundingClientRect()
        let aMouse = new Vector2(
            ((e.clientX - aBB.left) / absSceneCube.SIZE) * 2 - 1,
            -((e.clientY - aBB.top) / absSceneCube.SIZE) * 2 + 1);

        this.mRayCaster.setFromCamera(aMouse, this.mCamera);
        let aIntersects = this.mRayCaster.intersectObjects([this.mCube], true);

        if (aIntersects.length > 0) {

            let aNormal = aIntersects[0].face.normal;
            let aData: iCubeClickParams = { cube: this, normal: aNormal.negate() };
            EventManager.dispatchEvent(EventsContext.ROTATION_FROM_CUBE,
                this, aData);

        }
    }
    //_______________________________________________________________________________
    protected _onWindowResize() {
        this.mRenderer.domElement.style.position = "absolute"
        this.mRenderer.domElement.style.left = (0) + "px";
        this.mRenderer.domElement.style.bottom = "0";
    }
    //__________________________________________________________________________________________
    public orthoViewAxisChanged(pNormal: Vector3, pCallback: Function) {
        let aData: iCubeClickParams = {
            cube: this,
            normal: pNormal,
            callback: pCallback
        };
        EventManager.dispatchEvent(EventsContext.ROTATION_FROM_CUBE,
            this, aData);
    }
    //_______________________________________________________________________________


    protected _showCube(pVal: boolean) {
        this.mScene.visible = pVal;

        if (pVal) {
            SceneContext.OP3D_SCENE.addRenderCallback(this.mInstanceID,
                () => this._update(SceneContext.CAMERA,
                    SceneContext.OP3D_SCENE.lookAt));

        } else {
            SceneContext.OP3D_SCENE.removeRenderCallback(this.mInstanceID);
        }
    }
    //_______________________________________________________________________________
    private _initCamera() {
        const frustumSize = 50;
        const aspect = 1;
        this.mCamera = new OrthographicCamera(frustumSize * aspect / - 2,
            frustumSize * aspect / 2, frustumSize / 2,
            frustumSize / - 2, 0.1, 10000);

        //this.mCamera = new PerspectiveCamera(45, 1, 0.1, 10000);
        this.mScene.add(this.mCubeParams.camera);
    }
    //_______________________________________________________________________________

}
