import { EventManager } from "../../../oc/events/EventManager";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { SceneContext } from "../../scene/SceneContext";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { eClickMode } from "../_globals/PartsEventsHandler";
import { Menu } from "../home/Menu";
import { AssemblyMode } from "./AssemblyMode";
import { CSMode } from "./CSMode";
import { ColorPicker } from "./ColorPicker";
import { Group } from "./Group";
import { Measurement } from "./Measurement";

export class ChoosePartMode {

    private static INSTANCE: ChoosePartMode;
    mPrevNavState: string;
    //__________________________________________________________________________________________
    constructor() { }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ChoosePartMode.INSTANCE) {

            ChoosePartMode.INSTANCE = new ChoosePartMode();
        }

        return ChoosePartMode.INSTANCE;
    }
    //__________________________________________________________________________________________
    public async enterChoosePartMode(pMode: eClickMode) {
        await Op3dContext.PART_INFO.close();

        let aSideBar = Op3dContext.DIV_CONTROLLER.sideBar;
        this.mPrevNavState = aSideBar.getNavigatorState();




        if (pMode == eClickMode.EDITOR) {
            SceneContext.CHOOSE_MODE.mode = pMode
            AssemblyMode.instance.open()
            return
        }

        let aEscapeFunc = (e: KeyboardEvent) => {
            if (e.code != Strings.KEY_ESCAPE) {
                return;
            }

            this.leaveChoosePartMode();
            window.removeEventListener('keyup', aEscapeFunc);
        };
        window.addEventListener('keyup', aEscapeFunc);

        if (pMode == eClickMode.COLOR_FACE || pMode == eClickMode.COLOR_PART ||
            pMode == eClickMode.COLOR_SOLID) {
            SceneContext.CHOOSE_MODE.mode = pMode;
            ColorPicker.instance.open();
            return;
        }

        Op3dContext.PARTS_MANAGER.isToShowEdges(true)
        SceneContext.CHOOSE_MODE.mode = pMode
        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(pMode);
    }
    //__________________________________________________________________________________________
    public async enterChoosePositionMode(pMode: eClickMode) {
        await Op3dContext.PART_INFO.close();
        let aSideBar = Op3dContext.DIV_CONTROLLER.sideBar;
        this.mPrevNavState = aSideBar.getNavigatorState();

        let aEscapeFunc = (e: KeyboardEvent) => {
            if (e.code != Strings.KEY_ESCAPE) {
                return;
            }

            this.leaveChoosePartMode();
            window.removeEventListener('keyup', aEscapeFunc);
        };
        window.addEventListener('keyup', aEscapeFunc);

        SceneContext.CHOOSE_MODE.mode = pMode
        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(pMode);
    }
    //__________________________________________________________________________________________
    public leaveChoosePartMode() {
        Op3dContext.PART_INFO.close();
        SceneContext.RENDERER.domElement.style.border = ''

        Op3dContext.PARTS_MANAGER.onMouseDown(undefined, undefined)

        if (Menu.instance.isEdgesShown() !== true) {
            Op3dContext.PARTS_MANAGER.isToShowEdges(false)
        }

        SceneContext.CHOOSE_MODE.mode = eClickMode.BASE
        Op3dContext.PARTS_EVENTS_HANDLER._removeConnectedLine();
        Op3dContext.PARTS_EVENTS_HANDLER.resetHighlighting()

        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(eClickMode.BASE);

    }
    //__________________________________________________________________________________________
    public static closeOpenTools() {
        if (Group.INSTANCE && Group.instance.isOpen == true) {
            Group.instance.closeTool()
        }
        // if (BlackBox.INSTANCE && BlackBox.instance.isOpen == true) {
        //     BlackBox.instance.closeTool()
        // }
        if (Measurement.INSTANCE && Measurement.instance.isOpen == true) {
            Measurement.instance.close()
        }
        if (CSMode.INSTANCE && CSMode.instance.isOpen == true) {
            EventManager.dispatchEvent(EventsContext.RESTORE_TO_BASE_MODE, this)
            EventManager.removeEventListener(EventsContext.RESTORE_TO_BASE_MODE, this)
            CSMode.instance.closeTool()
        }

    }

    //__________________________________________________________________________________________
    public enterPositionMode() {
        this.enterChoosePositionMode(eClickMode.RAYS_POSITION)
    }
    public enterOneTargetFacesMode() {
        this.enterChoosePartMode(eClickMode.CHANGE_FACE_PROPERTIES)
    }
    public enterOneTargetSolidMode() {
        this.enterChoosePartMode(eClickMode.CHANGE_SOLIDS_MATERIAL)
    }
    public enterFacesMode() {
        this.enterChoosePartMode(eClickMode.FACES)
    }
    public enterChangeBaseMode() {
        this.enterChoosePartMode(eClickMode.CHANGE_BASE)
    }
    public enterEdgesMode() {
        this.enterChoosePartMode(eClickMode.EDGES)
    }
    public enterSolidMode() {
        this.enterChoosePartMode(eClickMode.SOLIDS)
    }

    public enterAssemblyMode() {
        this.enterChoosePartMode(eClickMode.EDITOR)
    }
    public enterColorFaceMode() {
        this.enterChoosePartMode(eClickMode.COLOR_FACE)
    }
    public enterMesurementMode() {

        if (Measurement.instance.isOpen == true) {
            Measurement.instance.close()
            return
        }
        ChoosePartMode.closeOpenTools()

        Op3dContext.PART_INFO.close();


        Op3dContext.PARTS_MANAGER.isToShowEdges(true);
        Op3dContext.PARTS_MANAGER.setSelectedPart(null);

        SimulationRunner.instance.setRaysVisibility(false);
        Measurement.instance.open();

        SceneContext.CHOOSE_MODE.mode = eClickMode.MEASUREMENT;
        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(eClickMode.MEASUREMENT);

    }
    public enterGroupMode() {
        if (Group.instance.isOpen == true) {
            Group.instance.closeTool()
            return
        }
        ChoosePartMode.closeOpenTools()

        Op3dContext.PART_INFO.close();
        Group.instance.open()
        SceneContext.CHOOSE_MODE.mode = eClickMode.GROUP

    }
    // public enterBlackBoxMode() {
    //     if (BlackBox.instance.isOpen == true) {
    //         BlackBox.instance.closeTool()
    //         return
    //     }
    //     ChoosePartMode.closeOpenTools()

    //     Op3dContext.PART_INFO.close();
    //     BlackBox.instance.open()
    //     SceneContext.CHOOSE_MODE.mode = eClickMode.BB

    // }
    public enterColorPartMode() {
        this.enterChoosePartMode(eClickMode.COLOR_PART)
    }
    public enterColorSolidMode() {
        this.enterChoosePartMode(eClickMode.COLOR_SOLID)
    }
    public enterChooseCSMode() {
        ChoosePartMode.closeOpenTools()
        Op3dContext.PART_INFO.close();
        Op3dContext.PARTS_MANAGER.selectedPart != null && Op3dContext.PARTS_MANAGER.selectedPart.unHighlightObject()

        Op3dContext.PARTS_MANAGER.isToShowEdges(true)
        CSMode.instance.open()

        SceneContext.CHOOSE_MODE.mode = eClickMode.CS
        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(eClickMode.CS);

    }

}