import { eStateToAnalysis } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iMinMax, iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";
import { iNIETriggers } from "./NumberInputElement";
import { SettingsContext } from "../../../settings/SettingsContext";

export interface iNIE {
    label?: {
        text: string;
        justify?: 'start' | 'cener' | 'space-between' | 'end';
        bold?: boolean;
    };
    unit?: string;

    isInt?: boolean;

    validation?: {
        func: (pVal: number) => string;
        returnToPrevValue: boolean;
    },
    onChange?: (pVal: number) => any;

    mod?: number;
    presentedScale?: number;
    decimalPrecision?: number;
    toExp?: boolean;
    isGlobalToFixed?: boolean;
    initialValue?: number;

    range?: iMinMax;

    class?: string[];

    triggers?: iNIETriggers;
    qa_id?: string;
};

export class NIE {

    private mParams: iNIE;
    private mContainer: HTMLElement;
    private mInput: iOP3DHTMLInputElement;

    //______________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pParams: iNIE) {
        this.mContainer = pContainer;
        this.mParams = pParams;

        this._create();
    }
    //______________________________________________________________________________________________
    public set actualValue(pVal: number) {
        let aScale = (undefined !== this.mParams.presentedScale) ? this.mParams.presentedScale : 1;
        this._setValue((pVal * aScale), false, false);
    }
    //______________________________________________________________________________________________
    public get actualValue() {
        return this.mInput.actualValue;
    }
    //______________________________________________________________________________________________
    public get value() {
        return parseFloat(this.mInput.value);
    }
    //______________________________________________________________________________________________
    public set value(pVal: number) {
        this._setValue(pVal, false, false);
    }
    //______________________________________________________________________________________________
    private getDecimalPrecision() {
        let aToFixed: number;
        if (this.mParams.isGlobalToFixed === true) {
            try {
                aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
            } catch (e) {
                aToFixed = SettingsContext.DEFAULT_USER_VO.parameters.simulation.numericAccuracy;
            }

        } else if (undefined != this.mParams.decimalPrecision) {
            aToFixed = this.mParams.decimalPrecision;
        }

        return aToFixed;
    }
    //______________________________________________________________________________________________
    public setParams(pParams: iNIE) {
        for (let param in pParams) {
            this.mParams[param] = pParams[param];
        }
        this.actualValue = this.actualValue;
    }
    //______________________________________________________________________________________________
    public updateRange(pRange: iMinMax) {
        this.mParams.range = pRange;
    }
    //______________________________________________________________________________________________
    public set params(pParams: iNIE) {
        this.mParams = pParams;
        this._create();
    }
    //______________________________________________________________________________________________
    public get params() {
        return this.mParams;
    }
    //______________________________________________________________________________________________
    public set readonly(pVal: boolean) {
        ViewUtils.setClassState(this.mContainer, 'readonly', pVal);
        this.mInput.readOnly = pVal;
    }
    //______________________________________________________________________________________________
    private _clear() {
        this.mContainer.removeAttribute('class');
        this.mContainer.removeAttribute('style');
        this.mContainer.attributes;
        let aID = this.mContainer.id;
        while (this.mContainer.attributes.length > 0) {
            this.mContainer.removeAttribute(this.mContainer.attributes[0].name);
        }

        this.mContainer.id = aID;
        ViewUtils.removeElementChildren(this.mContainer);
    }
    //______________________________________________________________________________________________
    private _create() {
        this._clear();
        this.mContainer.classList.add('input_cot');
        this.mContainer.classList.add('nie_n');
        this.mContainer.classList.add('stat');

        if (undefined !== this.mParams.class) {
            this.mContainer.classList.add(...this.mParams.class);
        }

        if (undefined !== this.mParams.label) {
            let aLabel = document.createElement('label');
            aLabel.innerHTML = this.mParams.label.text;
            if (undefined !== this.mParams.label.justify) {
                aLabel.setAttribute('justify', this.mParams.label.justify);
            }

            if (false === this.mParams.label.bold) {
                aLabel.style.fontWeight = '400';
            }

            this.mContainer.appendChild(aLabel);
        }

        let aParsingFunc = (true === this.mParams.isInt) ? (pVal: string) => parseInt(pVal) :
            (pVal: string) => parseFloat(pVal);

        this.mInput = document.createElement('input') as iOP3DHTMLInputElement;
        if (this.mParams.qa_id !== undefined) {
            this.mInput.setAttribute("qa_id", this.mParams.qa_id);
        }
        this.mContainer.appendChild(this.mInput);

        if (undefined !== this.mParams.unit) {
            this.mInput.addEventListener('focus', () => {
                this.mInput.value = aParsingFunc(this.mInput.value).toString();
                this.mInput.select();
            });
            this.mInput.addEventListener('blur', () => {
                let aVal = aParsingFunc(this.mInput.value);
                if (Infinity !== aVal) {
                    this.mInput.value = aVal + ' ' + this.mParams.unit;
                }

                this.mInput.prevValue = this.mInput.value;
            });
        } else {
            this.mInput.addEventListener('focus', () => {
                this.mInput.select();
            });
        }

        let aInitialValue = (undefined !== this.mParams.initialValue) ?
            this.mParams.initialValue : 0;
        this._setValue(aInitialValue, false, false);

        this.mInput.addEventListener('change', () =>
            this._setValue(aParsingFunc(this.mInput.value), true, true));
    }
    //______________________________________________________________________________________________
    private _setValue(pVal: number, pDispatchEvents: boolean, pCheckValidation: boolean) {
        if (true === isNaN(pVal)) {
            this.mInput.value = this.mInput.prevValue;
            return;
        }

        let aScale = (undefined !== this.mParams.presentedScale) ? this.mParams.presentedScale : 1;
        let aActualValue = (pVal / aScale);

        if (undefined !== this.mParams.mod) {
            aActualValue %= this.mParams.mod;
        }

        if (true === this.mParams.isInt) {
            aActualValue = parseInt(aActualValue.toString());
        }

        if (undefined !== this.mParams.range) {
            aActualValue = OP3DMathUtils.clampValue(aActualValue, this.mParams.range.min,
                this.mParams.range.max);
        }

        if ((true === pCheckValidation) && (undefined !== this.mParams.validation)) {
            let aValidationMsg = this.mParams.validation.func(aActualValue);
            if (undefined !== aValidationMsg) {
                if (true === this.mParams.validation.returnToPrevValue) {
                    this.mInput.value = this.mInput.prevValue;
                } else {
                    this.mInput.setCustomValidity(aValidationMsg);
                }
                return;
            }
        }

        this.mInput.setCustomValidity("");
        let aValue = (aActualValue * aScale);

        let aDecimalPrecision = this.getDecimalPrecision();
        let aToExp = (true === this.mParams.toExp);
        if (aDecimalPrecision !== undefined) {
            this.mInput.value = OP3DMathUtils.toFixed(aValue, aDecimalPrecision, aToExp);
        }

        this.mInput.prevValue = this.mInput.value;
        if (undefined !== this.mParams.unit) {
            this.mInput.dispatchEvent(new Event('blur'));
        }
        this.mInput.actualValue = aActualValue;

        if (true === pDispatchEvents) {
            if (undefined !== this.mParams.onChange) {
                this.mParams.onChange(this.mInput.actualValue);
            }

            if (undefined !== this.mParams.triggers) {
                if (true === this.mParams.triggers.saveHistory) {
                    Op3dContext.SCENE_HISTORY.addToHistory();
                }

                if (true === this.mParams.triggers.saveScene) {
                    Op3dContext.SCENE_HISTORY.saveScene();
                }

                if (true === this.mParams.triggers.triggerAnalysis) {
                    AnalysisPortal.instance.enableRunAnalysis(
                        eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
                }
            }
        }
    }
    //______________________________________________________________________________________________
}
