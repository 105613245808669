import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { Op3dComponentBase } from "../ui/Op3dComponentBase";
import { ViewUtils } from "../ui/ViewUtils";
import { Popup } from "../ui/forms/Popup";
import { Menu } from "../ui/home/Menu";

export interface iInitUploadFormParams {
    fileList: FileList;
    secondaryFileList?: FileList;
    toReplace: boolean;
    nameInput?: string;

}

export interface iUploadFileParams {
    func: (pParams: iInitUploadFormParams) => void;
    title: string;
    hasToReplace: boolean;
    hasSecondaryInput?: boolean;
    inputTitle?: string;
    secondaryInputTitle?: string;
    acceptedFormats?: string;
    show_supported_text?: boolean;
    isMultiple?: boolean;
    acceptedSizeMb?: number;
    conditions?: { title: string, data: Array<string> };
    addNameInput?: boolean;
    optomechanicParser?: boolean
};

export class UploadFileForm extends Op3dComponentBase<iUploadFileParams> {

    private static INSTANCE: UploadFileForm;
    private static DEFAULT_ACCEPTED_FORMATS: string = '.csv,.xlsx,.ods,.txt';

    private mFunc: (pParams: iInitUploadFormParams) => void;
    private mTitle: HTMLElement;

    private mInput: HTMLInputElement;
    private mLabel: HTMLElement;
    private mInputTitle: HTMLElement;

    private mSecondaryInput: HTMLInputElement;
    private mSecondaryLabel: HTMLElement;
    private mSecondaryInputTitle: HTMLElement;

    private mSecondaryFilesParent: HTMLElement;

    private mToReplaceInput: HTMLInputElement;
    private mToReplaceInputContainer: HTMLElement;

    private mUploadFileName: HTMLElement;
    private mUploadFileNameInput: HTMLInputElement;
    private mRequiredName: boolean;
    private mConditions: HTMLElement;

    private mMaxFileSizeMb?: number;
    private mSupportedTxt: HTMLElement;
    private mConditionsList: HTMLUListElement;
    private mConditionsTitle: HTMLElement;
    private mOptomechanicParser: boolean;

    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/upload_file_form.html'
        });
    }
    //__________________________________________________________________________________________
    public async close() {
        this.hide();
        this._onClose();
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == UploadFileForm.INSTANCE) {
            let aFormsContainer = document.getElementById('forms');
            if (aFormsContainer === null) {
                throw new Error("Forms container not found")
            }

            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aFormsContainer.appendChild(aDiv);

            UploadFileForm.INSTANCE = new UploadFileForm(aDiv);
        }

        return UploadFileForm.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mSupportedTxt = this._getPart("supported-formats-lbl");

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pParserPormParams: iUploadFileParams): void {
        this.mData = pParserPormParams;

        if (this.mData.acceptedFormats != null && this.mData.show_supported_text == true) {
            this.mSupportedTxt.innerText = `Supported formats: ${this.mData.acceptedFormats}`;
        }

        this.mOptomechanicParser = pParserPormParams.optomechanicParser

        this.mFunc = pParserPormParams.func;
        this.mTitle.innerHTML = pParserPormParams.title;

        this.mInput.value = '';
        this.mLabel.innerHTML = 'Choose File';
        this.mSecondaryInput.value = '';
        this.mSecondaryLabel.innerHTML = '';
        this.mInput.accept = (null != pParserPormParams.acceptedFormats) ?
            pParserPormParams.acceptedFormats : UploadFileForm.DEFAULT_ACCEPTED_FORMATS;
        this.mSecondaryInput.accept = (null != pParserPormParams.acceptedFormats) ?
            pParserPormParams.acceptedFormats : UploadFileForm.DEFAULT_ACCEPTED_FORMATS;

        this.mInputTitle.innerHTML = (null != pParserPormParams.inputTitle) ?
            pParserPormParams.inputTitle : 'Upload File';

        if (pParserPormParams.acceptedSizeMb) {
            this.mMaxFileSizeMb = pParserPormParams.acceptedSizeMb * 1024 * 1024;   // 25 * 1024 * 1024     Mb
        }

        let aHasSecondaryInput = (null != pParserPormParams.hasSecondaryInput) ?
            pParserPormParams.hasSecondaryInput : false;

        this.mSecondaryInputTitle.innerHTML = (null != pParserPormParams.secondaryInputTitle) ?
            pParserPormParams.secondaryInputTitle : 'Upload File';

        ViewUtils.setElementVisibilityByDNone(this.mSecondaryFilesParent,
            aHasSecondaryInput);

        ViewUtils.setElementVisibilityByDNone(this.mToReplaceInputContainer,
            pParserPormParams.hasToReplace);

        this.mInput.multiple = (null != pParserPormParams.isMultiple) ?
            pParserPormParams.isMultiple : false;

        this.mRequiredName = (true == pParserPormParams.addNameInput);
        ViewUtils.setElementVisibilityByDNone(this.mUploadFileName, this.mRequiredName);
        this.mUploadFileNameInput.value = '';

        this._addConditions();

    }
    //__________________________________________________________________________________________
    private _addConditions() {
        if (this.mData.conditions == null) {
            return;
        }

        ViewUtils.clearElementsChildren(this.mConditionsList);
        this.mConditionsTitle.innerText = this.mData.conditions.title;
        for (let i = 0; i < this.mData.conditions.data.length; i++) {
            let aItem = document.createElement("li");
            this.mConditionsList.appendChild(aItem);
            aItem.innerHTML = this.mData.conditions.data[i];

        }
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {

        this.mConditions = this._getPart("conditions-container", true);
        this.mConditionsList = this.mConditions.getElementsByTagName("ul")[0] as HTMLUListElement;
        this.mConditionsTitle = this.mConditions.getElementsByTagName("div")[0] as HTMLElement;
        this.mToReplaceInput = this._getPart("to-replace") as HTMLInputElement;
        this.mToReplaceInputContainer = this._getPart("to-replace-container");

        this.mInput = this._getPart('file_input') as HTMLInputElement;
        this.mSecondaryInput = this._getPart('secondary_file_input') as HTMLInputElement;

        this.mLabel = this._getPart('csv_file_title');
        this.mSecondaryLabel = this._getPart('secondary_csv_file_title');

        this.mSecondaryFilesParent = this._getPart('secondary_files');
        this.mInputTitle = this._getPart('upload_file_title');
        this.mSecondaryInputTitle = this._getPart('secondary_upload_file_title');

        this.mUploadFileName = this._getPart('input-name-container');
        this.mUploadFileNameInput = this._getPart('name_input') as HTMLInputElement;

        this.mInput.addEventListener('change',
            () => this._onFileChange(this.mInput, this.mLabel));

        this.mSecondaryInput.addEventListener('change',
            () => this._onFileChange(this.mSecondaryInput, this.mSecondaryLabel));

        this.mTitle = this._getPart('title');
        this._getPart('parse_btn').addEventListener('click', () => this._onParse());
    }
    //__________________________________________________________________________________________
    private _onParse() {
        if ((true == this.mRequiredName) && ('' == this.mUploadFileNameInput.value)) {
            Popup.instance.open({
                text: 'Part name is required'
            });
            return;
        }


        if (this.mInput.files!.length == 0) {
            Popup.instance.open({
                text: MessagesHandler.NO_FILE_CHOSEN
            });
            return;
        }
        if (this.mOptomechanicParser === true && this.mSecondaryInput.files.length == 0) {
            Popup.instance.open({
                text: MessagesHandler.NO_FILE_CHOSEN
            });
            return;
        }

        if (this.mData.acceptedFormats != null) {
            let aFiles = this.mInput.files!;
            for (let i = 0; i < aFiles.length; i++) {
                let aDotIndex = aFiles[i].name.lastIndexOf('.');
                let aType = aFiles[i].name.substring(aDotIndex).toLowerCase();
                if (this.mData.acceptedFormats.toLowerCase().indexOf(aType) == -1) {
                    let aMessage = this.mData.isMultiple ? MessagesHandler.PLURAL_FILE_UNSUPPORTED : MessagesHandler.SINGLE_FILE_UNSUPPORTED;
                    Popup.instance.open({
                        text: aMessage
                    });
                    return;
                }
            }
        }

        this.mFunc({
            fileList: this.mInput.files!,
            secondaryFileList: this.mSecondaryInput.files!,
            toReplace: this.mToReplaceInput.checked,
            nameInput: this.mUploadFileNameInput.value
        });
        this.close();
    }
    //__________________________________________________________________________________________
    private _onFileChange(pInput: HTMLInputElement, pLabel: HTMLElement) {
        let aFile = pInput.files![0];

        if (null == aFile) {
            return;
        }

        if (this.mMaxFileSizeMb && this.mMaxFileSizeMb <= aFile.size) {
            this.mInput.name = "";
            this.mInput.value = '';
            this.mSecondaryInput.value = '';
            this.mLabel.innerHTML = 'Choose File';
            this.mSecondaryInput.innerHTML = 'Choose File';
            let aLimit = Op3dContext.USER_VO.isBasicLicense ?
                Menu.LIMIT_CAD_FILE.FREE : Menu.LIMIT_CAD_FILE.PREMIUM;
            Popup.instance.open({ text: `File is too big, please select another file with maximum size ${aLimit}MB.` });
            return null;
        }

        pLabel.innerHTML = '';
        for (let i = 0; i < pInput.files!.length; i++) {
            pLabel.innerHTML += pInput.files![i].name + '; ';
        }
    }
    //__________________________________________________________________________________________
    protected _onClose() {
        this.mInput.value = '';
        this.mSecondaryInput.value = '';
        this.mLabel.innerHTML = 'Choose File';
        this.mSecondaryInput.innerHTML = '';
    }
    //__________________________________________________________________________________________
}
