import { iUserPermissions } from "../UserPermissions";
import { eGeneralWebsocketType, eSimulationWebsocketEventType } from "../_context/Enums";
import { iCoatingVO, iHash } from "../_context/_interfaces/Interfaces";
import { iFullUserData } from "../_context/_interfaces/SettingInterfaces";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { AuthUtils } from "./AuthUtils";
import { RegisteredServer } from "./RegisteredServer";

export enum ENVS {
    DEV = "DEV",
    QA = "QA",
    RELEASE = "RELEASE",
    PROD = "PROD",
    CHINA = "CHINA",
}

export enum eSimulationResultsStorage {
    S3 = 's3',
    HTTP = 'http'
}

export interface iCreateAperture {

    images: {
        phase?: File,
        transmittance?: File
    },
    data: iOpticsVO,

}

export interface iMaskAttachments {
    phase: File | undefined
    transmittance: File | undefined
}

export interface iOpticAttachments {
    masks: iMaskAttachments;
    coating: iHash<iCoatingVO>;

}

export type tWebsocketEventsType = eGeneralWebsocketType | eSimulationWebsocketEventType;


export interface iEventData<T = tWebsocketEventsType> {
    type: T,
    id: string,
    payload: any;
}

export interface iUserTokens {
    id_token: string;
    refresh_token: string;
    access_token: string;
}
export interface iLoginResultData {
    userData: iFullUserData;
    curr_cad_folder_size: number;
    cad_parts_limit: number;
    limit: number;
    permissions: iUserPermissions;
}

export abstract class ServerContext {

    /**
     * check access token interval data
     */
    private static _intervalKey: number;
    //private static INTERVAL_TIME = 30 * 60 * 1000; // half an hour
    private static INTERVAL_TIME = 30 * 60 * 1000; // 15 minutes
    private static FAIL_REQUEST_INTERVAL_TIME = 60 * 1000; //30 seconds
    private static _tokens: iUserTokens;

    /**
     * the main links of the website
     */
    public static cognito_domain: string;
    public static info_account_id: string;
    public static cookie_domain: string;
    public static redirect_uri: string;
    public static index_addition: string = "";
    public static base_link: string;
    public static backend_url: string;
    public static image_prefix: string;
    public static part_prefix: string;
    public static parts_data_prefix: string;
    public static user_prefix_setups: string;
    public static user_prefix_aperture_images: string;
    public static user_prefix_cad: string;
    public static cognito_client_id: string;
    public static pricing_base_link: string;
    public static optic_images: string;
    public static env: ENVS;
    public static SERVER: RegisteredServer;
    private static SAVE_IN_COOKIE: boolean;

    /**
     * Error codes
     */
    public static NOT_FOUND = 404;
    public static FORBIDDEN = 403;
    public static PreconditionFailed = 412;
    public static UNAUTHORIZED = 401;
    public static INTERNAL_SERVER_ERROR = 500;
    public static website_base_link: string;
    public static warehouse_base_link: string;
    public static billing_url: string;

    //_________________________________________________________________________________
    public static setTokens(pTokens: iUserTokens, pSaveInCookie: boolean) {
        this._tokens = pTokens;
        this.SAVE_IN_COOKIE = pSaveInCookie;
        this._initInterval();
    }
    //_________________________________________________________________________________
    private static _initInterval() {
        if (this._intervalKey == null) {
            this._intervalKey = setInterval(() => this._checkToken(), ServerContext.INTERVAL_TIME) as any;
        }
    }
    //_________________________________________________________________________________
    private static async _checkToken() {

        if (this._tokens != null && this._tokens.refresh_token != null) {
            let aRes = await RegisteredServer.getUpdatedAccessToken({ refresh_token: this._tokens.refresh_token });
            if (aRes.success) {

                /**
                 * 1) save in cookies
                 * 2) save in local tokens
                 */

                if (this.SAVE_IN_COOKIE) {
                    AuthUtils.saveCookies(aRes.data);
                }
                this._tokens = aRes.data;

            } else {

                /**
                * 1) create a new interval to check again
                * 2) check why it didn't succeeded
                */

                setTimeout(() => this._checkToken(), ServerContext.FAIL_REQUEST_INTERVAL_TIME);
            }
        }
    }
    //_________________________________________________________________________________
    public static get tokens() {
        return this._tokens;
    }
    //_________________________________________________________________________________
}
