import { iOP3DHTMLInputElement } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { ViewUtils } from "../ViewUtils";

export interface iRBElement {
    /**
     * @description Label of radio button.
     */
    label: string;

    /**
     * @description Value of the radio button. If not mention, it will be the same as label.
     */
    value?: any;

    /**
     * @description Determined if the radio button is enabled or not. Default - true;
     */
    enable?: boolean;

    /**
     * @description Determined if this option is for premium users only. If so and the it's a 
     *  basic user, it will be disabled and our overlay will be added.
     */
    isPremiumFeature?: boolean;
}

export interface iSingleRB<T = any> extends iRBElement {

    /**
     * @description Funcions occures on change rb select.
     * 
     * @param pValue - value of chosen rb.
     */
    onChange?: (pValue: T) => any;

    name: string;
    initialState?: boolean;
}

export interface iRBStyleOptions {
    /**
     * The gap between each radio button. Default - 16px.
     */
    gap?: number;

    /**
     * Width of each radio button element. Default - auto.
     */
    width?: number;
}

export class RBElement<T = any> {

    public static RB_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" 
    xmlns="http://www.w3.org/2000/svg"><rect x="3.5" y="3.5" width="17" height="17" rx="8.5" 
    fill="white" stroke="#23A7DE"/><rect class="rbc" x="7.5" y="7.5" width="9" height="9" rx="4.5" 
    fill="#23A7DE"/></svg>`;

    private mContainer: HTMLElement;

    //______________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pData: iSingleRB<T>) {
        this.mContainer = pContainer;
        this._create(pData);
    }
    //______________________________________________________________________________________________
    public set checked(pVal: boolean) {
        let aInput = this.mContainer.children[0] as iOP3DHTMLInputElement;
        aInput.checked = pVal;
    }
    //______________________________________________________________________________________________
    public get checked() {
        let aInput = this.mContainer.children[0] as iOP3DHTMLInputElement;
        return aInput.checked;
    }
    //______________________________________________________________________________________________
    public get value() {
        let aInput = this.mContainer.children[0] as iOP3DHTMLInputElement;
        return aInput.data;
    }
    //______________________________________________________________________________________________
    public set enable(pToEnable: boolean) {
        ViewUtils.setElementDisabled(this.mContainer, (false === pToEnable));
    }
    //______________________________________________________________________________________________
    private _create(pData: iSingleRB) {
        this.mContainer.removeAttribute('class');
        this.mContainer.classList.add('rb_element');
        this.mContainer.innerHTML += RBElement.RB_SVG;

        let aInput = document.createElement('input') as iOP3DHTMLInputElement<T>;
        aInput.type = 'radio';
        aInput.name = pData.name;
        aInput.id = Op3dUtils.idGenerator();

        this.mContainer.addEventListener('click', () => {
            if (true === aInput.checked) {
                return;
            }

            aInput.checked = true;
            if (undefined !== pData.onChange) {
                pData.onChange(aValue);
            }
        });

        let aValue = (undefined !== pData.value) ? pData.value : pData.label;
        aInput.checked = (true === pData.initialState);
        aInput.data = aValue;

        this.mContainer.insertBefore(aInput, this.mContainer.children[0]);
    }
    //______________________________________________________________________________________________
}