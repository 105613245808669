import OptiChatHeader from "../OptiChatHeader";
import OptiChatMessageInput from "./OptiChatMessageInput";
import styled, { CSSProperties } from "styled-components";
import OptiChatConversation from "./OptiChatConversation";
import React, { useContext, useEffect, useState } from "react";
import OptiChatContext from "../../store/OptiChatContext";
import { generateExample } from "../../store/OptiChatProvider";
import OptiChatMenu from "../menu/OptiChatMenu";
import OptiChatInfo from "../info/OptiChatInfo";
import OptiChatReview from "../review/OptiChatReview";
import { createPortal } from "react-dom";
import DraggableForm from "../DraggableForm";
import { OptiChatFiltersUtils } from "../../utils/OptiChatFiltersUtils";

export const StyledOptiChat = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--White, #fff);
  width: 560px;
  border-radius: 4px;
  min-height: calc(270px); // header 40, footer 40, content 150

  height: inherit;
  width: inherit;

  @media screen and (max-height: 700px) {
    height: 60vh;
  }
`;

export const BASIC_PARENT_CONTAINER_STYLE: CSSProperties = {
  width: "100vw",
  height: "100vh",
  position: "absolute",
  top: "0",
};
const OptiChat = function OptiChat({ onClose, position }) {
  const optiChatContext = useContext(OptiChatContext);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const onCloseHandler = () => {
    onClose();
  };

  useEffect(() => {
    OptiChatFiltersUtils.handleFilters(optiChatContext);
  }, []);

  const onInfoHandler = () => {
    setIsInfoOpen((prevState) => !prevState);
  };

  const onReset = () => {
    optiChatContext.resetSession();
  };

  return (
    <>
      {isInfoOpen &&
        createPortal(
          <div style={BASIC_PARENT_CONTAINER_STYLE}>
            <OptiChatInfo onClose={() => setIsInfoOpen(false)} />
          </div>,
          document.getElementById("forms")
        )}
      {optiChatContext.isReviewOpen &&
        createPortal(
          <div style={BASIC_PARENT_CONTAINER_STYLE}>
            <OptiChatReview />
          </div>,
          document.getElementById("forms")
        )}

      <DraggableForm
        right={position.right}
        bottom={position.bottom}
        handle=".handle"
      >
        <StyledOptiChat data-testid="optichat">
          <OptiChatHeader
            hasReset={true}
            onReset={onReset}
            className="handle"
            onClose={onCloseHandler}
            title="OPTICHAT™ - SEARCH COPILOT"
            hasInfoBtn={true}
            onInfo={onInfoHandler}
          />

          {optiChatContext.conversation.length === 0 ? (
            <OptiChatMenu
              isDisabled={optiChatContext.isReviewOpen}
              options={generateExample()}
            />
          ) : (
            <OptiChatConversation
              conversation={optiChatContext.conversation}
              isDisabled={optiChatContext.isReviewOpen}
            />
          )}

          <OptiChatMessageInput
            isDisabled={optiChatContext.isReviewOpen}
            onSendMessage={optiChatContext.addUserMessage}
            hasReviewBtn={true}
          />
        </StyledOptiChat>
      </DraggableForm>
    </>
  );
};

export default React.memo(OptiChat);
