import { eAxisType, eStateToAnalysis } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { eOpticsTypeNames, OpticsContext } from "../../_context/OpticsContext";
import { Strings } from "../../_context/Strings";
import { DataUtils } from "../../_utils/DataUtils";
import { iOpticsVO } from "../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../data/data_loader/OpticsDataLoader";
import { Part } from "../../parts/Part";
import { iFace } from "../../parts/PartInterfaces";
import { iPartData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { iGratingSectionData } from "../../simulation/SimulationContext";
import { ViewUtils } from "../ViewUtils";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { EditOpticsForm } from "../forms/optics/new/EditOpticsForm";
import { eFormType } from "../forms/optics/new/UploadOpticsContext";
import { OpticsMenu } from "../menus/_search/OpticsMenu";
import { PartInfoSection } from "./PartInfoSection";
import { piGratingSection } from "./piGratingSection";

export interface iOpticsSection {
    part: Part,
    partData: iPartData
};

export class piOpticsSettingsSection extends PartInfoSection<iOpticsSection> {

    private mOpticsName: HTMLElement;
    private mDeleteBtn: HTMLElement;
    private mOpticalSettings: HTMLElement;
    private mGratingSection: piGratingSection;
    private mAdvancedOpticsSection: HTMLElement;
    private mHideInactiveSurfaces: HTMLInputElement;
    private mCurrNumberID: string;
    private mSearchOptics: HTMLElement;

    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_optics_settings_section.html'
        });
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mOpticsName = this._getPart("optics_name");

        this.mOpticalSettings = this._getPart('optical_settings');
        this.mOpticalSettings.addEventListener("click", () => this._openOpticsForm());

        this.mDeleteBtn = this._getPart("delete-optics-btn");
        this.mDeleteBtn.addEventListener("click", () => this._onDeleteOptics());

        this.mSearchOptics = this._getPart("search-optics-btn");
        this.mSearchOptics.addEventListener("click", () => this._onOpenSearchOpticsMenu());

        this.mGratingSection = new piGratingSection(this._getPart("grating-section"),
            (pData) => this._onGratingChanged(pData as iGratingSectionData));

        this.mAdvancedOpticsSection = this._getPart('advanced_optics_section');

        this._initInactiveSurfacesCheckbox();

        let aTooltips = $(this.mContainer).find('[data-toggle="tooltip"]');
        for (let i = 0; i < aTooltips.length; i++) {
            let aText = aTooltips[i].title;
            aTooltips[i].removeAttribute('title');
            aTooltips[i].addEventListener('mouseenter', () => {
                let aBB = aTooltips[i].getBoundingClientRect();

                Op3dContext.TOOLTIP.show({
                    clientX: aBB.left,
                    clientY: aBB.bottom
                }, aText)
            });
            aTooltips[i].addEventListener('mouseleave', () => Op3dContext.TOOLTIP.hide());
        }
    }
    //__________________________________________________________________________________________
    private _onGratingChanged(pData: iGratingSectionData) {
        let aPartFaces = Op3dContext.PARTS_MANAGER.selectedPart.getOpticsFaces();
        for (let i = 0; i < aPartFaces.length; i++) {
            if (aPartFaces[i].data.gratingData != null) {

                aPartFaces[i].data.gratingData.min_transmission_order =
                    pData.min_transmission_order;
                aPartFaces[i].data.gratingData.max_transmission_order =
                    pData.max_transmission_order;
                aPartFaces[i].data.gratingData.min_reflection_order =
                    pData.min_reflection_order;
                aPartFaces[i].data.gratingData.max_reflection_order =
                    pData.max_reflection_order;
            }
        }
        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
    }
    //__________________________________________________________________________________________
    private _onDeleteOptics() {
        Op3dContext.SCENE_HISTORY.addToHistory();

        Op3dContext.PARTS_MANAGER.deleteOpticsFromPart(Op3dContext.PARTS_MANAGER.selectedPart);

        Op3dContext.DIV_CONTROLLER.updatePartInfo();
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _onOpenSearchOpticsMenu() {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        let aOpticsHolder = aPart.getSubpartByName(Strings.OPTICS_HOLDER);
        let aSearchData = ((null != aOpticsHolder) && (null != aOpticsHolder.subParts) &&
            (null != aOpticsHolder.subParts[0]) && (null != aOpticsHolder.subParts[0].data)) ?
            aOpticsHolder.subParts[0].data.searchData : null;

        if (eAxisType.OPTICS == aPart.refCS.cs.type) {
            aPart = aPart.refCS.refPart;
        }

        OpticsMenu.instance.open({
            part: aPart,
            searchData: aSearchData
        });
    }
    //__________________________________________________________________________________________
    private _initInactiveSurfacesCheckbox() {
        this.mHideInactiveSurfaces = this._getPart('hide_inactive') as HTMLInputElement;
        this.mHideInactiveSurfaces.addEventListener('click', () => this._onHideInactive());
    }
    //__________________________________________________________________________________________
    private _onHideInactive() {
        let aPart = Op3dContext.PARTS_MANAGER.selectedPart;
        let aPartFaces: iFace[];
        let aOpticalAxis = aPart.getAxes().find(axis => (eAxisType.OPTICS === axis.type));
        let aOptics = ((null != aOpticalAxis) && (null != aPart.linkedParts)) ?
            aPart.linkedParts.find((part) => (eAxisType.OPTICS == part.refCS.cs.type)) :
            null;

        if (null != aOptics) {
            aPartFaces = aOptics.getOpticsFaces();
            aPart = aOptics;
        } else {
            aPartFaces = aPart.getOpticsFaces();
        }

        for (let i = 0; i < aPartFaces.length; i++) {
            let aFace = aPartFaces[i];
            if (Strings.ABSORBING == aFace.data.coatingID) {
                aFace.visualization.mesh.visible = !this.mHideInactiveSurfaces.checked;
            }
        }

        aPart.setWireframeVisibility(this.mHideInactiveSurfaces.checked);
    }
    //__________________________________________________________________________________________
    protected async _fillSection(pOpticsSection: iOpticsSection) {
        let aOpticsAxes = pOpticsSection.part.getAxes().find(axis =>
            (eAxisType.OPTICS === axis.type));
        let aNumberID: string;

        if (null != aOpticsAxes) {
            aNumberID = aOpticsAxes.linkedOpticsId ? aOpticsAxes.linkedOpticsId : null;

        } else {

            let aOpticsHolder = pOpticsSection.part.getSubpartByName(Strings.OPTICS_HOLDER);
            let aOptics = (null != aOpticsHolder.subParts) ? aOpticsHolder.subParts[0] : null;
            aNumberID = (null != aOptics.data) ? aOptics.data.number_id : null;
        }

        this._setAdvanceOptics(aNumberID);
        this._setOptics(pOpticsSection, aNumberID);
    }
    //__________________________________________________________________________________________
    private async _setAdvanceOptics(pNumberId: string) {
        let aIsShown = (null != pNumberId)

        ViewUtils.setElementVisibilityByDNone(this.mAdvancedOpticsSection, aIsShown);
        if (false == aIsShown) {
            return;
        }

        let aOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
            number_id: pNumberId
        });

        if (aOpticsVO.parameters.type == eOpticsTypeNames.APERTURE) {
            ViewUtils.setElementVisibilityByDNone(this.mAdvancedOpticsSection, false);
        }

        this.mCurrNumberID = pNumberId;

        let aPartFaces: iFace[];
        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        let aOpticalAxis = aSelectedPart.getAxes().find(axis => (eAxisType.OPTICS === axis.type));
        let aOptics = ((null != aOpticalAxis) && (null != aSelectedPart.linkedParts)) ?
            aSelectedPart.linkedParts.find((part) => (eAxisType.OPTICS === part.refCS.cs.type)) :
            null;

        if (null != aOptics) {
            aPartFaces = aOptics.getOpticsFaces();
        } else {
            aPartFaces = aSelectedPart.getOpticsFaces();
        }

        this._setInactiveCheckboxState(aPartFaces);
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mOpticsName.innerHTML = 'No optical element';
        ViewUtils.setElementActive(this.mOpticsName, false);
        this.mOpticsName.removeAttribute('data-original-title');
        ViewUtils.setElementVisibilityByDNone(this.mOpticalSettings, false);
    }
    //__________________________________________________________________________________________
    private async _setOptics(pOpticsSection: iOpticsSection, pNumberId: string) {

        this._clear();
        // this.mCurrOptics = pOptics;
        ViewUtils.setElementVisibilityByDNone(this.mSearchOptics, true);
        if (null != pNumberId) {

            await this._setRegularOptic(pOpticsSection, pNumberId);
            return;
        }

        this.mOpticsName.removeAttribute("number_id");
        ViewUtils.setElementVisibilityByDNone(this.mDeleteBtn, false);
    }
    //__________________________________________________________________________________________
    private async _setRegularOptic(pOpticsSection: iOpticsSection, pNumberId: string) {
        let aToShowDeleteBtn = (null != pOpticsSection.part.id);
        ViewUtils.setElementVisibilityByDNone(this.mDeleteBtn, aToShowDeleteBtn);

        let aOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
            number_id: pNumberId
        });

        ViewUtils.setElementVisibilityByDNone(this.mOpticalSettings, true);
        // ViewUtils.setElementVisibilityByDNone(this.mSearchOptics, true);


        if (aOpticsVO.parameters.type == eOpticsTypeNames.APERTURE) {

            ViewUtils.setElementVisibilityByDNone(this.mSearchOptics, false);
            if (Op3dContext.USER_VO.isBasicLicense) {
                ViewUtils.setElementVisibilityByDNone(this.mOpticalSettings, false);
            }
        }

        this._setGratingSection(aOpticsVO);
        let aPrefix = '';
        // if (eDataPermission.PRIVATE == aOpticsVO.permission) {
        if (OpticsContext.isCustomized(aOpticsVO)) {
            aPrefix += '<strong>(Customized) </strong>';
        }

        let aName = (aPrefix + aOpticsVO.name);
        this.mOpticsName.innerHTML = aName;
        this.mOpticsName.setAttribute('data-original-title', aName);
        this.mOpticsName.setAttribute("number_id", pNumberId);
    }
    //__________________________________________________________________________________________
    private _setGratingSection(pOpticsVO: iOpticsVO) {
        let aPartFaces = Op3dContext.PARTS_MANAGER.selectedPart.getOpticsFaces();
        let aGratingDataFace = aPartFaces.find(face => face.data.gratingData != null);
        let aIsGrating = (null != aGratingDataFace);
        if (true == aIsGrating) {
            this.mGratingSection.data = {
                max_reflection_order: aGratingDataFace.data.gratingData.max_reflection_order,
                min_reflection_order: aGratingDataFace.data.gratingData.min_reflection_order,
                max_transmission_order: aGratingDataFace.data.gratingData.max_transmission_order,
                min_transmission_order: aGratingDataFace.data.gratingData.min_transmission_order,
                opticsVO: pOpticsVO
            };
        }
        this.mGratingSection.setVisibility(aIsGrating);
    }
    //__________________________________________________________________________________________
    private _setInactiveCheckboxState(pPartFaces: Array<iFace>) {
        let aHasInactiveSurfaces = false;
        let aIsInactiveSurfacesShown = true;

        for (let i = 0; i < pPartFaces.length; i++) {
            let aFace = pPartFaces[i];
            if (Strings.ABSORBING == aFace.data.coatingID) {
                aHasInactiveSurfaces = true;
                aIsInactiveSurfacesShown = aFace.visualization.mesh.visible;
                break;
            }
        }

        this.mHideInactiveSurfaces.checked = !aIsInactiveSurfacesShown;

        let aCheckboxParent = this.mHideInactiveSurfaces.parentElement;
        ViewUtils.setElementDisabledAttribute(aCheckboxParent, !aHasInactiveSurfaces);
    }
    //__________________________________________________________________________________________
    private async _openOpticsForm() {
        if (null == this.mCurrNumberID) {
            return;
        }

        const aOpticsVO = await OpticsDataLoader.instance.getFromCache(this.mCurrNumberID);

        EditOpticsForm.instance.open({
            formType: eFormType.EDIT,
            openMenuPoint: null,
            opticsVO: DataUtils.getObjectCopy(aOpticsVO),
            part: Op3dContext.PARTS_MANAGER.selectedPart
        });
    }
    //__________________________________________________________________________________________
}
