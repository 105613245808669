import { EventManager } from "../oc/events/EventManager";
import { eStateToAnalysis } from "./_context/Enums";
import { EventsContext } from "./_context/EventsContext";
import { MessagesHandler } from "./_context/MessagesHandler";
import { Op3dContext } from "./_context/Op3dContext";
import { Strings } from "./_context/Strings";
import { ViewUtils } from "./ui/ViewUtils";
import { AnalysisPortal } from "./ui/analysis/AnalysisPortal";
import { NotificationCenter } from "./ui/home/_notifications/NotificationCenter";
import { eNotificationToastDuration } from "./ui/home/_notifications/NotificationsContext";

export interface iUserPermissions {
    scattering: boolean;
    advancedAnalysis: boolean;
    privateSetups: boolean;
    gpu_time: boolean;
    billing: boolean;
}

export class UserPermissions {

    private mUserPermissions: iUserPermissions;

    //__________________________________________________________________________________________
    constructor(pUserPermissions: iUserPermissions) {
        if (pUserPermissions != null) {
            this.mUserPermissions = pUserPermissions;
        } else {
            this.mUserPermissions = {
                advancedAnalysis: false,
                privateSetups: false,
                scattering: false,
                gpu_time: false,
                billing: false,
            }
        }
    }
    //__________________________________________________________________________________________
    /**
     * when the permissions changed call this function
     */
    public update(pUserPermissions: iUserPermissions) {

        this.mUserPermissions = pUserPermissions;

        EventManager.dispatchEvent(EventsContext.PERMISSION_UPDATE, this);
        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);

        ViewUtils.setClassForElement(document.body, Strings.BASIC_LICENSE, Op3dContext.USER_VO.isBasicLicense);

        NotificationCenter.instance.pushNotification({
            message: MessagesHandler.UPDATE_USER_PROFILE,
            params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
            toastDuration: eNotificationToastDuration.INFINITE
        });
    }
    //__________________________________________________________________________________________
    public get isFreeUser() {
        return Op3dContext.USER_VO.billing.license.line_items.length == 0;
    }
    //__________________________________________________________________________________________
    public get billingTimeEnabled() {
        return (true == this.mUserPermissions.billing);
    }
    //__________________________________________________________________________________________
    public get GPUTimeEnabled() {
        return (true == this.mUserPermissions.gpu_time);
    }
    //__________________________________________________________________________________________
    public get isScatteringEnabled() {
        return (true == this.mUserPermissions.scattering);
    }
    //__________________________________________________________________________________________
    public get hasAdvancedAnalysis() {
        return (true == this.mUserPermissions.advancedAnalysis);
    }
    //__________________________________________________________________________________________
    public get hasPrivateSetupsPermission() {
        return (true == this.mUserPermissions.privateSetups);
    }
    //__________________________________________________________________________________________
}