import { Op3dUtils } from "../_utils/Op3dUtils";
import { iUserTokens } from "./ServerContext";

export class AuthUtils {


    private static ID_TOKEN: string = '3doptix_id_token';
    private static ACCESS_TOKEN: string = '3doptix_access_token';
    private static REFRESH_TOKEN: string = '3doptix_refresh_token';
    public static COOKIE_ENV_PASS: string = '3doptix_env_pass';
    public static TRANSFER_AFTER_REGISTRATION: string = '3doptix_registation_form';


    //__________________________________________________________________________________________
    public static saveCookies(pData: iUserTokens) {
        Op3dUtils.setCookie(AuthUtils.ID_TOKEN,
            pData.id_token, 7, 0);

        Op3dUtils.setCookie(AuthUtils.ACCESS_TOKEN,
            pData.access_token, 0, 1);

        Op3dUtils.setCookie(AuthUtils.REFRESH_TOKEN,
            pData.refresh_token, 7, 0);
    }
    //__________________________________________________________________________________________
    static logout() {
        this.deleteCookies();
    }
    //__________________________________________________________________________________________
    static getRefreshtoken() {
        return Op3dUtils.getCookie(AuthUtils.REFRESH_TOKEN);
    }
    //__________________________________________________________________________________________
    public static deleteCookies() {
        Op3dUtils.deleteCookie(AuthUtils.ID_TOKEN);
        Op3dUtils.deleteCookie(AuthUtils.ACCESS_TOKEN);
        Op3dUtils.deleteCookie(AuthUtils.REFRESH_TOKEN);
    }
    //__________________________________________________________________________________________
    public static getTokensFromCookies() {
        let aTokens: iUserTokens = {
            id_token: Op3dUtils.getCookie(AuthUtils.ID_TOKEN),
            refresh_token: Op3dUtils.getCookie(AuthUtils.REFRESH_TOKEN),
            access_token: Op3dUtils.getCookie(AuthUtils.ACCESS_TOKEN)
        }
        return aTokens;
    }
    //__________________________________________________________________________________________
}