import { iPoint2D } from "../../_context/_interfaces/Interfaces";
import { ViewUtils } from "../ViewUtils";

export interface iContextMenu {
    position: iPoint2D;
    items: Array<iContextMenuItem>;
}

export interface iContextMenuItem {
    title: string;
    callback: Function;
}

export class ContextMenu {

    private static INSTANCE: ContextMenu;

    private mContainer: HTMLElement;
    private mCloseFunc: () => void;

    //______________________________________________________________________________________________
    private constructor() {
        this._init();
    }
    //______________________________________________________________________________________________
    private _init() {
        this.mContainer = this._getCot();
        this.mCloseFunc = () => this.close();
    }
    //______________________________________________________________________________________________
    private _getCot() {
        let aContainer = document.createElement('div');
        aContainer.classList.add('floating_context_menu');
        aContainer.setAttribute('data-toggle', 'modal');
        aContainer.setAttribute('data-backdrop', 'true');

        return aContainer;
    }
    //______________________________________________________________________________________________
    public static get instance() {
        if (undefined === ContextMenu.INSTANCE) {
            ContextMenu.INSTANCE = new ContextMenu();
        }

        return ContextMenu.INSTANCE;
    }
    //______________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mContainer);
    }
    //______________________________________________________________________________________________
    public close() {
        this._clear();

        if (null !== this.mContainer.parentElement) {
            this.mContainer.parentElement.removeChild(this.mContainer);
        }

        window.removeEventListener('mouseup', this.mCloseFunc);
    }
    //______________________________________________________________________________________________
    public open(pData: iContextMenu) {
        if (0 === pData.items.length) {
            return;
        }

        this._clear();

        this.mContainer.style.left = pData.position.x + 'px';
        this.mContainer.style.top = pData.position.y + 'px';

        for (let i = 0, l = pData.items.length; i < l; i++) {
            this._addItem(pData.items[i]);
        }

        window.addEventListener('mouseup', this.mCloseFunc);
        document.body.appendChild(this.mContainer);
    }
    //______________________________________________________________________________________________
    private _addItem(pContextMenuItem: iContextMenuItem) {
        let aItem = document.createElement('div');
        aItem.classList.add('floating_context_menu__item');
        aItem.addEventListener('mouseup', () => this._onClick(pContextMenuItem));

        let aTextItem = document.createElement('div');
        aTextItem.classList.add('floating_context_menu__item__text');
        aTextItem.innerHTML = pContextMenuItem.title;

        aItem.appendChild(aTextItem);
        this.mContainer.appendChild(aItem);
    }
    //______________________________________________________________________________________________
    private _onClick(pItem: iContextMenuItem) {
        pItem.callback();
    }
    //______________________________________________________________________________________________
}
