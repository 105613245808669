
import { jsPDF } from "jspdf";
import { iHash, iSetupDetails } from "../_context/_interfaces/Interfaces";
import autoTable from 'jspdf-autotable'
import { iBOMExportData, iBOMItem } from "../parts/_parts_assets/PartsManager";
import { Part } from "../parts/Part";
import { Op3dContext } from "../_context/Op3dContext";
import { iBOMData } from "../parts/absPartsManager";
import { CageBehavior } from "../parts/behaviors/CageBehavior";
import { UnitHandler } from "../units/UnitsHandler";
import { PostBehavior } from "../parts/behaviors/PostBehavior";
import { eDataPermission, ePolarizationType, eUnitType, eWavelengthUnit } from "../_context/Enums";
import { PartVO } from "../data/VO/PartVO";
import { PartsDataLoader } from "../data/data_loader/PartsDataLoader";
import { OpticsDataLoader } from "../data/data_loader/OpticsDataLoader";
import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { eSmPlaneWaveType, eSmRaysKind } from "../simulation/SimulationContext";
import { OpticsContext } from "../_context/OpticsContext";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";
import { Popup } from "../ui/forms/Popup";
import { ePartType } from "../parts/PartInterfaces";

export interface iTextStyle {
    font_size: number,
    color: string;
    line_height: number
}

export interface iBOMOptions {
    footer_offset: number
    date_margin: number;
    margin: number;
    image_ratio: number;
    date: iTextStyle;
    h1: iTextStyle;
    h3: iTextStyle;
    h5: iTextStyle;
    h6: iTextStyle;

}
export class BOMGenerator {

    private static TABLE_NAMES = {
        optomechanics: "Optomechanical parts",
        optics: "Optical elements"
    }

    private static BOM_OPTIONS: iBOMOptions = {
        footer_offset: 40,
        date_margin: 20,
        margin: 10,
        image_ratio: 0.2,
        date: {
            font_size: 12,
            color: "#808080",
            line_height: 6
        },
        h6: {
            font_size: 11,
            color: "#000000",
            line_height: 5
        },
        h5: {
            font_size: 12,
            color: "#000000",
            line_height: 6
        },
        h3: {
            font_size: 14,
            color: "#000000",
            line_height: 7,
        },
        h1: {
            color: "#000000",
            font_size: 22,
            line_height: 9
        }
    }


    private mHeightPointer = 0;

    constructor() { }
    //___________________________________________________________
    private static _rodBOM(pPart: Part) {
        try {
            let aCurrHeight = CageBehavior.getCageLength(pPart);
            if (aCurrHeight === 0) {
                return;
            }
            // converting height in mm to height in leading unit 
            aCurrHeight *= UnitHandler.MM_TO_IN;
            const aSteps = CageBehavior.CAGE_STEPS;

            let aVal: { length: number, pn: string };
            if (aCurrHeight < aSteps[0].length) {
                aVal = aSteps[0];

            } else {

                for (let i = 0; i < aSteps.length - 1; i++) {
                    let aLowerBound = aSteps[i];
                    let aUpperBound = aSteps[i + 1];

                    if ((aCurrHeight > aLowerBound.length) && (aCurrHeight < aUpperBound.length)) {
                        if (Math.abs(aCurrHeight - aLowerBound.length) > Math.abs(aCurrHeight - aUpperBound.length)) {
                            aVal = aUpperBound;
                        } else {
                            aVal = aLowerBound;
                        }
                        break;
                    }

                    if (aCurrHeight <= aLowerBound.length) {
                        aVal = aLowerBound;
                        break;
                    }

                    if (aCurrHeight > aUpperBound.length && (i + 1) < aSteps.length) {
                        continue;
                    }

                    aVal = aUpperBound;
                    break;
                }
            }

            let aBomItem: iBOMItem = {
                count: 1,
                catalogNumber: aVal.pn,
                id: aVal.pn,
                name: `Rod ${aVal.length}"`
            };

            return aBomItem;
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e, "Failed to create bom item for rods");
        }
    }
    //___________________________________________________________
    private static _postBOM(pPart: Part) {
        try {
            let aCurrHeight = PostBehavior.getActualPostLength(pPart);
            if (aCurrHeight === -1) {
                return;
            }

            // converting height in mm to height in leading unit 
            aCurrHeight *= UnitHandler.presentedScale;

            const aShortSign = UnitHandler.presentedScale == eUnitType.MILLIMETERS ? "mm" : '"';
            const aSteps = PostBehavior.POST_SETPS.thorlabs[UnitHandler.PRESENTED_UNIT];

            let aVal: number;
            if (aCurrHeight < aSteps[0]) {
                aVal = aSteps[0];

            } else {
                for (let i = 0; i < aSteps.length - 1; i++) {
                    let aLowerBound = aSteps[i];
                    let aUpperBound = aSteps[i + 1];

                    if ((aCurrHeight > aLowerBound) && (aCurrHeight < aUpperBound)) {
                        if (Math.abs(aCurrHeight - aLowerBound) > Math.abs(aCurrHeight - aUpperBound)) {
                            aVal = aUpperBound;
                        } else {
                            aVal = aLowerBound;
                        }
                        break;
                    }

                    if (aCurrHeight <= aLowerBound) {
                        aVal = aLowerBound;
                        break;
                    }

                    if (aCurrHeight > aUpperBound && (i + 1) < aSteps.length) {
                        continue;
                    }

                    aVal = aUpperBound;
                    break;
                }
            }

            let aKey = `post_${aVal}`
            let aBomItem: iBOMItem = {
                count: 1,
                catalogNumber: "",
                id: aKey,
                name: `Post ${aVal}${aShortSign}`
            };

            return aBomItem;
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e, "Failed to create bom item for post");
        }
    }
    //___________________________________________________________
    private static _getLSBOM(pPart: Part) {
        try {
            const aData = pPart.getBehavior("laserBehavior").laserData.lightSource;
            let aIsArrayOfSources = aData.arrayOfSourcesData != null ? aData.arrayOfSourcesData.is_array === true : false;
            const aType = aData.kind.replaceAll("_", ' ').toLowerCase();
            const aPrimaryWavelength = aData.wavelengthData.find(item => item.isPrimary === true).wl;
            const aIsNM = (Op3dContext.USER_VO.userVO.parameters.simulation.wavelengthUnit === eWavelengthUnit.NM);
            const aMul = (true == aIsNM) ? 1 : 1000;

            let aSizeTxt = "";
            let aGeoData = aData.sourceGeometricalData as any;
            let aScalar = UnitHandler.presentedScale;
            let aSign = UnitHandler.shortSign;

            switch (aData.kind) {
                case eSmRaysKind.PLANE_WAVE:
                    switch (aData.shape) {
                        case eSmPlaneWaveType.CIRCULAR:
                            const aRadius = OP3DMathUtils.toFixed(aGeoData.radius * aScalar, 4);
                            aSizeTxt = `radius: ${aRadius}${aSign},`;
                            break;
                        case eSmPlaneWaveType.ELLIPTICAL:
                            const aRadiusX = OP3DMathUtils.toFixed(aGeoData.radius_x * aScalar, 4);
                            const aRadiusY = OP3DMathUtils.toFixed(aGeoData.radius_y * aScalar, 4);
                            aSizeTxt = `radius x: ${aRadiusX}${aSign}, radius y: ${aRadiusY}${aSign}, `;
                            break;
                        case eSmPlaneWaveType.RECTANGULAR:
                            const aHalfHeight = OP3DMathUtils.toFixed(aGeoData.height / 2 * aScalar, 4);
                            const aHalfWidth = OP3DMathUtils.toFixed(aGeoData.width / 2 * aScalar, 4);
                            aSizeTxt = `half width: ${aHalfWidth}${aSign}, half height: ${aHalfHeight}${aSign}, `;
                            break;

                    }
                    break;
                case eSmRaysKind.POINT_SOURCE:

                    break;
                case eSmRaysKind.GAUSSIAN_BEAM:

                    break;
            }

            let aPower = aData.power;
            let aPolarization = aData.polarization.polarizationState === ePolarizationType.POLARIZED ? "polarized" : "unpolarized";

            let aName = `${aIsArrayOfSources ? "Array of sources" : 'Light source'} , type: ${aType}, ${aSizeTxt}${aPrimaryWavelength * aMul}nm, ${aPower}W, ${aPolarization}`;
            let aBomItem: iBOMItem = {
                count: 1,
                catalogNumber: "",
                id: aName,
                name: aName,
            };

            return aBomItem;
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e, "Failed to create bom item for LS");
        }
    }
    //___________________________________________________________
    private static _getDetectorBOM(pPart: Part) {
        try {

            const aDetectorData = pPart.getDetectorData();
            const aScalar = UnitHandler.presentedScale;
            const aSign = UnitHandler.shortSign
            const aHalfWidth = OP3DMathUtils.toFixed(aDetectorData.size.width / 2 * aScalar, 4);
            const aHalfHeight = OP3DMathUtils.toFixed(aDetectorData.size.height / 2 * aScalar, 4);
            const aName = `Detector, half width: ${aHalfWidth}${aSign}, half height: ${aHalfHeight}${aSign}`;

            let aBomItem: iBOMItem = {
                count: 1,
                catalogNumber: "",
                id: aName,
                name: aName,
            };
            return aBomItem;
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e, "Failed to create bom item for detector");
        }
    }
    //___________________________________________________________
    private static _specialBOMTreatment(_pPartVO: PartVO, pId: string, pPart: Part) {
        switch (pId) {
            case "D1012":

                return this._getDetectorBOM(pPart);

            case "L1111":
                return this._getLSBOM(pPart);

            case "rod":
                return this._rodBOM(pPart)

            case "post": {

                return this._postBOM(pPart);
            }
        }
    }
    //___________________________________________________________
    private static _getOneOptomechanicalData(pPart: Part, pId: string, pArr: iHash<iBOMItem>) {
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pId);

        if (aPartVO == null) {
            return;
        }

        if (aPartVO.isAssembly === false) {



            let aKey = aPartVO.id;
            let aHashItem = this._specialBOMTreatment(aPartVO, pId, pPart);

            if (aHashItem !== undefined) {

                aKey = aHashItem.id;

            } else {

                let aIsPublic = aPartVO.permission === eDataPermission.PUBLIC;
                let aCatalogNumber = aIsPublic ? aPartVO.originalUrl : "";

                aHashItem = {
                    count: 1,
                    catalogNumber: aCatalogNumber,
                    id: aPartVO.id,
                    name: aPartVO.name,
                };
            }

            if (pArr[aKey] === undefined) {
                pArr[aKey] = aHashItem;
            } else {
                pArr[aKey].count++;
            }

            return;
        }

        let aData = PartsDataLoader.instance.getFromCache(aPartVO.number_id, false);
        if (aData !== null) {
            let aParts: Array<any> = JSON.parse(aData.assembly_parts);
            for (let i = 0; i < aParts.length; i++) {
                let aSubPart = PartsDataLoader.instance.getFromCache(aParts[i].number_id, false);
                this._getOneOptomechanicalData(pPart, aSubPart.info.id, pArr);
            }
        }
    }
    //______________________________________________________________________________________________
    private static _addParaxialData(pPart: Part, pArr: iHash<iBOMItem>) {
        if (ePartType.PARAXIAL_LENS !== pPart.partOptions.type) {
            return;
        }

        let aOpticsVO = pPart.paraxialLensData;
        if (undefined === aOpticsVO) {
            return;
        }


        let aEFL = aOpticsVO.parameters.geometry.paraxialEFL;
        let aDia = aOpticsVO.parameters.geometry.diameter;
        let aScale = UnitHandler.presentedScale;

        const aParaxialEFL = OP3DMathUtils.toFixed(aEFL * aScale, 4);
        const aDiameter = OP3DMathUtils.toFixed(aDia * aScale, 4);
        const aShortSign = UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS ? "mm" : '"';

        let aKey = `paraxial_lens_${aParaxialEFL}_${aDiameter}`;

        if (pArr[aKey] === undefined) {

            pArr[aKey] = {
                catalogNumber: "",
                count: 1,
                id: aKey,
                name: `Paraxial lens dia.${aDiameter}${aShortSign} EFL:${aParaxialEFL}${aShortSign}`
            }
        } else {
            pArr[aKey].count++;
        }
    }
    //______________________________________________________________________________________________
    private static _getOneOpticData(pOpticsNumberID: string, pArr: iHash<iBOMItem>) {
        let aOpticSVO = OpticsDataLoader.instance.getFromCache(pOpticsNumberID, false) as iOpticsVO;
        if (aOpticSVO !== null && aOpticSVO !== undefined) {
            if (pArr[pOpticsNumberID] === undefined) {
                let aCatalogNumber: string = "";
                if (aOpticSVO.permission === eDataPermission.PUBLIC) {
                    aCatalogNumber = aOpticSVO.name.split(' ')[0];
                }
                pArr[pOpticsNumberID] = {
                    catalogNumber: aCatalogNumber,
                    count: 1,
                    id: pOpticsNumberID,
                    name: aOpticSVO.name
                }
            } else {
                pArr[pOpticsNumberID].count++;
            }
        }
    }
    //___________________________________________________________
    public static addToBOM(pPart: Part, pHash: iBOMData) {

        switch (pPart.id) {
            case OpticsContext._Paraxial_Lens:
                this._addParaxialData(pPart, pHash.optics);
                break;
            case "GCS":
                break;
            case null:
            case undefined:
                if (pPart.opticsNumberID !== null) {
                    this._getOneOpticData(pPart.opticsNumberID, pHash.optics);
                }
                break;
            default:
                if (pPart.id !== null && pPart.id !== undefined) {

                    // optomechanics
                    this._getOneOptomechanicalData(pPart, pPart.id, pHash.optomechanics);
                }
                break;
        }
    }
    //___________________________________________________________
    public exportBOM() {
        let aBomData = Op3dContext.PARTS_MANAGER.getRawBOMData();

        let aToExport = Object.keys(aBomData.optomechanics).length > 0 ||
            Object.keys(aBomData.optics).length > 0;

        if (aToExport === false) {
            Popup.instance.open({
                text: "No elements to export"
            })
            return;
        }
        let aBOM: iBOMExportData = {
            optics: aBomData.optics,
            optomechanics: aBomData.optomechanics,
            name: Op3dContext.SETUPS_MANAGER.fileName,
            setupDetails: Op3dContext.SETUPS_MANAGER.setupParameters.details
        }

        this._exportBOM(aBOM);
        // new BOMGenerator().exportBOM(aBOM);
    }
    //___________________________________________________________
    private async _exportBOM(pData: iBOMExportData) {
        const aDoc = new jsPDF();
        this.mHeightPointer = BOMGenerator.BOM_OPTIONS.margin;

        await this._addHeader(aDoc, pData);

        this._addTable(aDoc, pData.optomechanics, BOMGenerator.TABLE_NAMES.optomechanics);
        this._addTable(aDoc, pData.optics, BOMGenerator.TABLE_NAMES.optics);
        this._addFooter(aDoc);

        aDoc.save(`BOM_${pData.name}.pdf`);
    }
    //___________________________________________________________
    private _addFooter(pDoc: jsPDF) {

        const aFooterText = ["3DOptix team", "info@3doptix.com", "www.3doptix.com"];
        let aFinalY = (pDoc as any).previousAutoTable.finalY;
        const aOffsetFromBottomWhenFooter = 10;
        const aPageHeight = pDoc.internal.pageSize.getHeight();

        if (aFinalY && aFinalY + BOMGenerator.BOM_OPTIONS.footer_offset - aOffsetFromBottomWhenFooter > aPageHeight) {
            pDoc.addPage();
            aFinalY = BOMGenerator.BOM_OPTIONS.margin;
        }

        let aFooterPointer = aFinalY + BOMGenerator.BOM_OPTIONS.footer_offset;
        pDoc.setTextColor(BOMGenerator.BOM_OPTIONS.h5.color);
        pDoc.setFontSize(BOMGenerator.BOM_OPTIONS.h5.font_size);

        for (let i = 0; i < aFooterText.length; i++) {
            let aCurrText = aFooterText[i];
            pDoc.text(aCurrText, BOMGenerator.BOM_OPTIONS.margin, aFooterPointer);
            aFooterPointer += BOMGenerator.BOM_OPTIONS.h5.line_height;
        }
    }
    //______________________________________________________________________________________________
    private _getDataAsRows(pData: iHash<iBOMItem>) {
        let aRows = new Array<Array<string>>();

        for (let key in pData) {
            const aItem = pData[key];
            let aName = aItem.name;
            aName = aName.replace(/λ/g, 'lambda');
            aName = aName.replace(/ø/g, 'dia');
            aName = aName.replace(/°/g, 'deg');
            aName = aName.replace(/±/g, '+/-');
            const aCatalogNumber = aItem.catalogNumber !== undefined ? aItem.catalogNumber : aItem.id;
            aRows.push([aCatalogNumber, aName, aItem.count.toString()]);
        }

        return aRows;
    }
    //___________________________________________________________
    private _addTable(pDoc: jsPDF, pData: iHash<iBOMItem>, pTableTitle: string) {
        if (Object.keys(pData).length === 0) {
            return;
        }

        autoTable(pDoc, { html: 'table' });

        let aTitles = ["P/n", "Description", "Quantity"];
        let aRows = this._getDataAsRows(pData);

        pDoc.setFontSize(BOMGenerator.BOM_OPTIONS.h3.font_size);
        pDoc.setTextColor(BOMGenerator.BOM_OPTIONS.h3.color);

        pDoc.text(pTableTitle,
            BOMGenerator.BOM_OPTIONS.margin,
            this.mHeightPointer);  // Adjust the position as needed

        this.mHeightPointer += BOMGenerator.BOM_OPTIONS.h3.line_height;

        autoTable(pDoc, {

            head: [aTitles],
            body: aRows,
            styles: { overflow: 'linebreak' },
            rowPageBreak: 'auto',
            margin: BOMGenerator.BOM_OPTIONS.margin,
            startY: this.mHeightPointer,
            columnStyles: {
                0: { cellWidth: 35 },
                1: { cellWidth: 135 },
                2: {
                    cellWidth: 20,
                    halign: 'center'
                },
            },
        })

        let aFinalY = (pDoc as any).previousAutoTable.finalY;

        this.mHeightPointer = aFinalY;
        this.mHeightPointer += BOMGenerator.BOM_OPTIONS.margin * 2;
    }
    //___________________________________________________________
    private _addText(pDoc: jsPDF, pTitle: string,
        pStyle: iTextStyle) {

        pDoc.setFontSize(pStyle.font_size);
        pDoc.setTextColor(pStyle.color);

        const aTotalWidth = pDoc.internal.pageSize.getWidth() - (BOMGenerator.BOM_OPTIONS.margin * 2);
        const aLines = pDoc.splitTextToSize(pTitle, aTotalWidth);

        for (let i = 0; i < aLines.length; i++) {
            let aCurrText = aLines[i];
            pDoc.text(aCurrText, BOMGenerator.BOM_OPTIONS.margin, this.mHeightPointer);

            this.mHeightPointer += pStyle.line_height;
        }
    }
    //___________________________________________________________
    private async _addHeader(pJsPDF: jsPDF, pData: iBOMExportData) {
        await this._addLogo(pJsPDF);
        this.mHeightPointer += BOMGenerator.BOM_OPTIONS.h5.line_height
        this._addDate(pJsPDF);
        this._addText(pJsPDF, pData.name, BOMGenerator.BOM_OPTIONS.h1);
        this._addSetupDetails(pJsPDF, pData.setupDetails);
        this.mHeightPointer += BOMGenerator.BOM_OPTIONS.h5.line_height;
    }
    //___________________________________________________________
    private _addSetupDetails(doc: jsPDF, pSetupDetails: iSetupDetails) {
        doc.setFontSize(BOMGenerator.BOM_OPTIONS.h3.font_size);
        doc.setTextColor(BOMGenerator.BOM_OPTIONS.h3.color);

        // this._addText(doc, "Description:", BOMGenerator.BOM_OPTIONS.h6);
        this._addText(doc, `Description: ${pSetupDetails.generalComments}`, BOMGenerator.BOM_OPTIONS.h6);

        if (pSetupDetails.title !== undefined && pSetupDetails.title !== "") {
            this._addText(doc, `Publication title: ${pSetupDetails.title}`, BOMGenerator.BOM_OPTIONS.h6);
        }
        if (pSetupDetails.abstract !== undefined && pSetupDetails.abstract !== "") {
            this._addText(doc, `Publication abstract: ${pSetupDetails.abstract}`, BOMGenerator.BOM_OPTIONS.h6);
        }
        if (pSetupDetails.link !== undefined && pSetupDetails.link !== "") {
            this._addText(doc, `Publication link: ${pSetupDetails.link}`, BOMGenerator.BOM_OPTIONS.h6);
        }
        if (pSetupDetails.authors !== undefined && pSetupDetails.authors !== "") {
            this._addText(doc, `Publication authors: ${pSetupDetails.authors}`, BOMGenerator.BOM_OPTIONS.h6);
        }
    }
    //___________________________________________________________
    private _addDate(doc: jsPDF) {

        doc.setFontSize(BOMGenerator.BOM_OPTIONS.date.font_size);
        doc.setTextColor(BOMGenerator.BOM_OPTIONS.date.color)

        var currentDate = new Date();
        var dateString = currentDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
        var totalWidth = doc.internal.pageSize.getWidth();
        let aX = totalWidth - BOMGenerator.BOM_OPTIONS.margin - BOMGenerator.BOM_OPTIONS.date_margin;
        doc.text(dateString, aX, BOMGenerator.BOM_OPTIONS.margin);  // Adjust the position as needed
    }
    //___________________________________________________________
    private _addLogo(pJSPDF: jsPDF) {

        return new Promise((resolve) => {
            let aImageURL = "images/logo-smaller.png";
            let aImgElement = new Image();

            aImgElement.onload = () => {
                let aCanvas = document.createElement("canvas");
                aCanvas.width = aImgElement.width;
                aCanvas.height = aImgElement.height;

                let aCtx = aCanvas.getContext("2d");

                aCtx.fillStyle = "white";
                aCtx.fillRect(0, 0, aCanvas.width, aCanvas.height);
                aCtx.drawImage(aImgElement, 0, 0);
                let aImgData = aCanvas.toDataURL("image/jpeg");

                pJSPDF.addImage(aImgData, "JPEG",
                    BOMGenerator.BOM_OPTIONS.margin,
                    BOMGenerator.BOM_OPTIONS.margin,
                    aImgElement.width * BOMGenerator.BOM_OPTIONS.image_ratio,
                    aImgElement.height * BOMGenerator.BOM_OPTIONS.image_ratio);



                this.mHeightPointer += aImgElement.height * BOMGenerator.BOM_OPTIONS.image_ratio;
                this.mHeightPointer += BOMGenerator.BOM_OPTIONS.margin;
                resolve(true)
            };
            aImgElement.src = aImageURL;

        });
    }
    //___________________________________________________________
    public getBOMAsTxt(pData: iBOMExportData) {
        let aTxt = "";

        // add date
        let aCurrentDate = new Date();
        let aDateString = aCurrentDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
        aTxt += `Date: ${aDateString}\n`;

        // add file name
        aTxt += `File name: ${pData.name}\n\n`;

        // add optomechaincs table
        aTxt = this._addTextTable(pData.optomechanics,
            BOMGenerator.TABLE_NAMES.optomechanics,
            aTxt);

        aTxt = this._addTextTable(pData.optics,
            BOMGenerator.TABLE_NAMES.optics,
            aTxt);


        return aTxt;
    }
    //___________________________________________________________
    private _addTextTable(pTableData: iHash<iBOMItem>,
        pTableName: string,
        pTxt: string) {

        if (Object.keys(pTableData).length === 0) {
            return;
        }

        pTxt += "================================================================================================\n";
        pTxt += `Category name: ${pTableName}\n`;
        pTxt += "================================================================================================\n";
        const aRows = this._getDataAsRows(pTableData);


        let columnWidths = aRows.reduce((widths: any, row) => {
            return row.map((cell, index) => Math.max(widths[index] || 0, cell.length));
        }, []);

        // Make sure id column has an even width
        columnWidths[0] = Math.max(columnWidths[0] + 1, 7); // Minimum width of 6

        // Create the header
        const header = columnWidths.map((width: any, index) => {
            return index === 0 ? "P/n".padEnd(width) : (index === 1 ? "Name".padEnd(width) : "Quantity".padEnd(width));
        }).join('\t');


        // const header = "ID\tDescription\tQuantity";
        const tableLines = [header, ...aRows.map(row => {
            return row.map((cell, index) => cell.padEnd(columnWidths[index])).join('\t');
        })];
        const tableText = tableLines.join('\n');
        pTxt += tableText;
        pTxt += "\n\n";

        return pTxt;
    }
    //___________________________________________________________
}
