import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { iHash, iCubeClickParams } from "../_context/_interfaces/Interfaces";
import { Op3dOrbitController } from "../scene/Op3dOrbitController";
import { eMouseMode, Op3dScene } from "../scene/Op3dScene";
import { SceneContext } from "../scene/SceneContext";
import { SceneLights } from "../scene/SceneLights";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { PositionGenerator } from "../scene/PositionGenerator";
import { EventBase } from "../../oc/events/EventBase";
import { EventManager } from "../../oc/events/EventManager";
import { Axis } from "../parts/_parts_assets/Axis";

import { ColorUtils } from "../ui/ColorUtils";

import { Vector3, WebGLRenderer, OrthographicCamera, Scene } from "three";
import { SceneCubeWidget } from "./SceneCubeWidget";

export class Op3dSceneWidget {

    private static RESET_CAMERA_TARGET = new Vector3(0, 0, 0);
    private static RESET_CAMERA_POSITION = new Vector3(-596, 701, -1041);
    public static START_CAMERA_VAL: number = 673.5753140545634;

    private mOrbitController: Op3dOrbitController;
    private mSceneLights: SceneLights;

    private mCameraToLocation: Vector3;
    private mCube: SceneCubeWidget;
    private mOnRenderHash: iHash<Function> = {};


    private mRendererFrame = (pTimeStamp: DOMHighResTimeStamp) => this._update(pTimeStamp);
    private mAxis: Axis;
    private mStopAt: DOMHighResTimeStamp;



    constructor(pContainer: HTMLElement, pIsCreateCube: boolean) {
        Op3dContext.CONTAINER = pContainer;

        this._initScene();
        this.mSceneLights = new SceneLights();
        this.mAxis = new Axis();

        this._initCamera();
        this._initRenderers();

        this.mOrbitController = new Op3dOrbitController(this);
        //this.resetCamera();


        if (pIsCreateCube) {
            this.mCube = new SceneCubeWidget({
                rendererElement: Op3dContext.CONTAINER,
                camera: SceneContext.CAMERA
            });
        }
        let aNow = window.performance.now();
        this.mStopAt = (aNow + 150);
        this._update(aNow);
        // this._setFPS();

        this._addEventListeners();
        // setInterval(this.mMemoryCheck, 500);


    }
    //__________________________________________________________________________________________

    //__________________________________________________________________________________________
    public activateRenderer() {
        this.mStopAt = (performance.now() + 150);
    }
    //__________________________________________________________________________________________
    public setMouseMode(pMouseMode: eMouseMode) {
        this.mOrbitController.setMouseMode(pMouseMode);
    }
    //__________________________________________________________________________________________
    public get op3dOrbitController() {
        return this.mOrbitController;
    }

    //__________________________________________________________________________________________
    public get lights() {
        return this.mSceneLights.getLights();
    }
    //__________________________________________________________________________________________
    public get cube() {
        return this.mCube;
    }
    //__________________________________________________________________________________________
    public orthoViewAxisChanged(pNormal: Vector3, pCallback: Function) {
        this.mCube.orthoViewAxisChanged(pNormal, pCallback);
    }
    //__________________________________________________________________________________________
    public set cameraToLocation(pVec: Vector3) {
        this.mCameraToLocation = pVec;
    }
    //__________________________________________________________________________________________

    public removeRenderCallback(pKey: string) {
        delete this.mOnRenderHash[pKey];
    }
    //__________________________________________________________________________________________
    public addRenderCallback(pKey: string, pCallback: Function) {
        this.mOnRenderHash[pKey] = pCallback;
    }
    //__________________________________________________________________________________________
    public rotateCamera() {

        if (this.mCameraToLocation != null) {

            let aCameraToPosition = this.mCameraToLocation.clone();


            this.mOrbitController.update();
            this.mCameraToLocation = null;

            SceneContext.CAMERA.position.copy(aCameraToPosition);

        }

    }
    //__________________________________________________________________________________________
    private _update(pTimeStamp: DOMHighResTimeStamp): void {
        requestAnimationFrame(this.mRendererFrame);
        if (pTimeStamp < this.mStopAt) {
            this._doUpdate();
        }
    }
    //__________________________________________________________________________________________
    private _doUpdate() {
        for (let key in this.mOnRenderHash) {
            this.mOnRenderHash[key]();
        }

        this.rotateCamera()
        this._renderSceneUpdate(SceneContext.RENDERER, SceneContext.CAMERA)
    }
    //__________________________________________________________________________________________
    private _renderSceneUpdate(pRenderer: WebGLRenderer, pCamera: OrthographicCamera) {
        pRenderer.autoClear = false;
        pRenderer.clear();
        pRenderer.clearDepth();
        pRenderer.render(SceneContext.MAIN_SCENE, pCamera);
        SceneContext.SPRITE_RENDERER.render(SceneContext.MAIN_SCENE, pCamera);
    }
    //__________________________________________________________________________________________
    private _addEventListeners() {
        EventManager.addEventListener(EventsContext.RESIZE_ALL_SCREEN,
            () => this._onWindowResize(), this);
        EventManager.addEventListener(EventsContext.WINDOW_RESIZE,
            () => this._onWindowResize(), this);
        EventManager.addEventListener(EventsContext.ROTATION_FROM_CUBE,
            (pData: EventBase<iCubeClickParams>) => this._rotationFromCube(pData.data), this);
    }
    //__________________________________________________________________________________________

    public getCameraPosition(pData: iCubeClickParams, pCamera: OrthographicCamera) {
        let center = Op3dContext.PARTS_MANAGER.getCenter();
        let aVectorToLookAt = this.vectorToLookAtFunc(center, pCamera);
        let aZoom = aVectorToLookAt.length();
        let aPosition: Vector3 = pData.normal;

        aPosition = aPosition.multiplyScalar(aZoom);
        aVectorToLookAt.normalize();
        aVectorToLookAt.multiplyScalar(0.01);
        aPosition.add(aVectorToLookAt);
        // aPosition.add(this.mOrbitController.lookAt);
        aPosition.add(center);

        return aPosition;
    }
    //__________________________________________________________________________________________
    public vectorToLookAtFunc(pObjCenter: Vector3, pCamera: OrthographicCamera): Vector3 {
        let aCamPos = pCamera.position.clone();
        let aVectorToLookAt = aCamPos.sub(pObjCenter);
        return aVectorToLookAt;
    }
    //__________________________________________________________________________________________

    private _rotationFromCube(pData: iCubeClickParams) {

        let center = Op3dContext.PARTS_MANAGER.getCenter()

        this.mOrbitController.setLookAt(center)

        let aData = pData;
        let aVectorToLookAt = this.mOrbitController.vectorToLookAt;
        let aZoom = aVectorToLookAt.length();

        let aPosition = aData.normal;


        aPosition = aPosition.multiplyScalar(aZoom);
        aVectorToLookAt.normalize();
        aVectorToLookAt.multiplyScalar(0.01);
        aPosition.add(aVectorToLookAt);
        aPosition.add(this.mOrbitController.lookAt);
        if (Math.abs(aPosition.y) < 1) {
            aPosition.y = 20;
        }
        this.mCameraToLocation = aPosition;

    }
    //__________________________________________________________________________________________
    private _renderResize(pRenderer: WebGLRenderer, pCamera: OrthographicCamera, width: number, height: number) {
        let aFrustumScale = 3;
        pCamera.left = -width / aFrustumScale
        pCamera.right = width / aFrustumScale
        pCamera.top = height / aFrustumScale
        pCamera.bottom = -height / aFrustumScale
        pCamera.updateProjectionMatrix();
        pRenderer.setSize(width, height);

    }
    //__________________________________________________________________________________________
    private _onWindowResize() {
        let width = Op3dContext.CONTAINER.clientWidth
        let height = Op3dContext.CONTAINER.clientHeight

        this._renderResize(SceneContext.RENDERER, SceneContext.CAMERA, width, height);
        this._renderResize(SceneContext.SPRITE_RENDERER as any, SceneContext.CAMERA, width, height);
    }
    //__________________________________________________________________________________________
    public updateRendererSize() {
        this._onWindowResize()
    }
    //__________________________________________________________________________________________
    private _initCamera() {
        let aFrustumScale = 3;
        let aWidth = Op3dContext.CONTAINER.clientWidth / aFrustumScale; //new
        let aHeight = Op3dContext.CONTAINER.clientHeight / aFrustumScale; //new
        SceneContext.CAMERA = new OrthographicCamera
            (-aWidth, aWidth, aHeight, -aHeight, 0.1, 10000);
        this.setCameraDefault();

        SceneContext.MAIN_SCENE.add(SceneContext.CAMERA);
    }
    //__________________________________________________________________________________________
    public setCameraDefault(pVec: Vector3 = new Vector3()) {
        SceneContext.CAMERA.position.set(-1500, Op3dScene.START_CAMERA_VAL, -1500);

        if (SceneContext.OP3D_SCENE) {
            SceneContext.OP3D_SCENE.lookAt = pVec;

        }
    }
    //__________________________________________________________________________________________

    public resetCamera() {
        this.setCamera(Op3dSceneWidget.RESET_CAMERA_POSITION, Op3dSceneWidget.RESET_CAMERA_TARGET);
    }
    //__________________________________________________________________________________________
    public setCamera(pNewCameraPosition: Vector3, pNewCameraLookAt: Vector3) {
        SceneContext.CAMERA.position.copy(pNewCameraPosition);

        this.mOrbitController.setLookAt(pNewCameraLookAt.clone());
        SceneContext.CAMERA.lookAt(pNewCameraLookAt.clone());
        SceneContext.CAMERA.updateMatrix();
        SceneContext.CAMERA.updateMatrixWorld(true);
    }
    //__________________________________________________________________________________________
    public setBasicWatermark() {
        let canvas = document.createElement('canvas')
        canvas.width = SceneContext.RENDERER.domElement.width
        canvas.height = SceneContext.RENDERER.domElement.height
        let ctx = canvas.getContext('2d')
        ctx.fillStyle = ColorUtils.numToHEXColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor)
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        let img = new Image()
        img.onload = function () {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            ctx.globalCompositeOperation = 'source-out';
            SceneContext.RENDERER.domElement.style.backgroundImage = 'url(' + canvas.toDataURL() + ')'
        }
        img.src = '../www/images/watermark.png'
    }
    //__________________________________________________________________________________________
    private _rendererOptions(pRenderer: WebGLRenderer, pWidth: number, pHeigth: number,
        pDOMElement: Element) {
        pRenderer.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1.0);
        pRenderer.setSize(pWidth, pHeigth);
        pDOMElement.prepend(pRenderer.domElement);
    }
    //__________________________________________________________________________________________
    private _onContextLost() {
        Op3dContext.USER_VO.isEmployeeUser && console.log("_onContextLost");
    }
    //__________________________________________________________________________________________
    private _initRenderersOptions() {
        let width = Op3dContext.CONTAINER.clientWidth;
        let height = Op3dContext.CONTAINER.clientHeight;

        this._rendererOptions(SceneContext.RENDERER, width, height, SceneContext.ISO_VIEW);
    }
    //__________________________________________________________________________________________
    private _onContextRestored() {
        Op3dContext.USER_VO.isEmployeeUser && console.log("_onContextRestored");
        this._initRenderersOptions();
    }
    //__________________________________________________________________________________________
    protected _initRenderers() {
        let aParam = {
            antialias: true,
            logarithmicDepthBuffer: true,
            preserveDrawingBuffer: true
        };

        //Init 1 main View rendering
        SceneContext.RENDERER = new WebGLRenderer(aParam);

        SceneContext.SPRITE_RENDERER = new CSS2DRenderer() as any;
        SceneContext.SPRITE_RENDERER.setSize(Op3dContext.CONTAINER.clientWidth, Op3dContext.CONTAINER.clientHeight);
        SceneContext.SPRITE_RENDERER.domElement.style.position = 'absolute';
        SceneContext.SPRITE_RENDERER.domElement.style.top = '50px';
        const aWorkSpaceCanvasContainer = document.getElementById('canvas_view_container');
        aWorkSpaceCanvasContainer.appendChild(SceneContext.SPRITE_RENDERER.domElement);

        SceneContext.RENDERER.domElement.addEventListener('webglcontextlost',
            () => this._onContextLost(), false);
        SceneContext.RENDERER.domElement.addEventListener('webglcontextrestored',
            () => this._onContextRestored(), false);
        SceneContext.ISO_VIEW = document.getElementById('canvas_container');
        this._initRenderersOptions();
    }
    //__________________________________________________________________________________________
    private _initScene() {
        SceneContext.MAIN_SCENE = new Scene();
        SceneContext.POSITION_GENERATOR = new PositionGenerator();
    }
    //__________________________________________________________________________________________
    public updateLights() {
        this.mSceneLights.updateLights();
    }
    //__________________________________________________________________________________________
    public updateZoomParams() {
        this.mOrbitController.updateZoomParams();
    }
    //__________________________________________________________________________________________
    public enableController(pEnable: boolean) {
        this.mOrbitController.enable(pEnable);
    }
    //__________________________________________________________________________________________
    public set lookAt(pLookAt: Vector3) {
        this.mOrbitController.setLookAt(pLookAt);
    }
    //__________________________________________________________________________________________
    public get lookAt() {
        return this.mOrbitController.lookAt;
    }
    //__________________________________________________________________________________________

    public get target() {
        return this.mOrbitController.orbitControls.target
    }
    //__________________________________________________________________________________________
    public get orbitControls() {
        return this.mOrbitController.orbitControls
    }
    //__________________________________________________________________________________________
    public getAngle(pAngle: string) {
        if (pAngle === 'VERTICAL') {
            return this.mOrbitController.orbitControls.getPolarAngle()
        } else if (pAngle === 'HORIZONTAL') {
            return this.mOrbitController.orbitControls.getAzimuthalAngle()
        }


    }
    //__________________________________________________________________________________________
    public getAxisModel(pIsPart: boolean = false) {
        return this.mAxis.getAxisModel(pIsPart);
    }
    //__________________________________________________________________________________________
}