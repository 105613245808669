import { PlaneGeometry, MeshBasicMaterial, DoubleSide, Mesh, LineSegments, Raycaster, Vector2, Vector3, Intersection, Object3D } from "three";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash, iClientPoint } from "../../_context/_interfaces/Interfaces";
import { OptixPartUtils } from "../../_utils/OptixPartUtils";
import { SceneContext } from "../../scene/SceneContext";
import { eClickMode } from "../../ui/_globals/PartsEventsHandler";
import { ePhase } from "../../ui/forms/AssemblyMode";
import { CSMode, eDirection } from "../../ui/forms/CSMode";
import { Part } from "../Part";
import { AxisObject3D } from "../_parts_assets/Axis";
import { iEventParams } from "./Button3D";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { eObjectType } from "../absPartsManager";

export class ClickManager {


    private static PLANE_SIZE: number = 1000000000;
    // private static TRESHOLD: number = 0.25;

    private mMouseUpHash: iHash<(pPoint: iClientPoint, pFaceIndex?: Intersection) => void>
    private mMouseDownHash: iHash<(pEvent: iClientPoint, pFaceIndex?: number) => void>
    private mDblMouseDownHash: iHash<(pEvent: iClientPoint, pFaceIndex?: number) => void>
    private mMouseOutHash: iHash<() => void>
    private mMouseOverHash: iHash<(pFaceIndex?: Intersection) => void>;
    ///private mButtonsHash: iHash<base_3d.Button3D>;
    private mClickableObjects: Array<Object3D>;
    private mHoverableObjects: Array<Object3D>;
    private mMouseOverObject: Object3D;
    private mRayCaster: Raycaster;
    private mZoomPlane: Mesh;

    private mTargets: Array<Object3D>;
    previousStateObjectGeometry: any;


    constructor() {
        this._initStorage();
        this._createPlane();
    }
    //__________________________________________________________________________________________
    public clear() {
        this.mMouseUpHash = {};
        this.mMouseDownHash = {};
        this.mMouseOutHash = {};
        this.mMouseOverHash = {};
        this.mDblMouseDownHash = {};
    }
    //__________________________________________________________________________________________
    public enterAxisChoosingMode() {
        this.mTargets = new Array<Object3D>();
        SceneContext.MAIN_SCENE.traverse((obj) => {
            if (obj.name == AxisObject3D.AXIS_NAME) {
                this.mTargets.push(obj);
            }
        })
    }
    //__________________________________________________________________________________________
    public enterAddCSMode() {
        this.mTargets = this.mClickableObjects.slice();
        SceneContext.MAIN_SCENE.traverse((obj) => {
            if (obj.name == AxisObject3D.AXIS_NAME) {
                this.mTargets.push(obj);
            }
        });
    }
    //__________________________________________________________________________________________
    public changeRaycastTargets(pMode: eClickMode) {
        switch (pMode) {
            case eClickMode.FACES:
            case eClickMode.CHANGE_FACE_PROPERTIES:
            case eClickMode.CHANGE_SOLIDS_MATERIAL:
            case eClickMode.COLOR_FACE:
            case eClickMode.COLOR_PART:
            case eClickMode.SOLIDS:
                this.mTargets = Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.OPTOMECHANICS_FACE)
                break;
            case eClickMode.EDGES:
            case eClickMode.CHANGE_BASE:
            case eClickMode.CS:
            case eClickMode.EDITOR:
                this.mTargets = Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.OPTOMECHANICS_FACE).concat(Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.OPTOMECHANICS_EDGE))
                break;
            case eClickMode.RAYS_POSITION:
                this.mTargets = [SimulationRunner.instance.mainLaserLinesContainer];
                break;
            case eClickMode.GROUP:
            case eClickMode.BASE:
                this.mTargets = null
                break;
            case eClickMode.MEASUREMENT:
                this.mTargets = Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.OPTOMECHANICS_EDGE).concat(Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.OPTOMECHANICS_FACE), Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.OPTICS), Op3dContext.PARTS_MANAGER.getSceneObjectsByType(eObjectType.USER_DEFINED))
                break;
        }
    }
    //__________________________________________________________________________________________
    public enterGeneralMode() {
        this.mTargets = null;
    }
    //__________________________________________________________________________________________
    private _createPlane() {
        let aGeo = new PlaneGeometry(ClickManager.PLANE_SIZE, ClickManager.PLANE_SIZE);
        let aMat = new MeshBasicMaterial({
            side: DoubleSide,
            color: 0x0000ff, transparent: false, opacity: 1
        });
        aMat.visible = false;
        this.mZoomPlane = new Mesh(aGeo, aMat);
        this.mZoomPlane.position.set(0, 0, 0);
        this.mZoomPlane.rotateX(-Math.PI / 2);
        this.mZoomPlane.visible = true;
        SceneContext.MAIN_SCENE.add(this.mZoomPlane);
    }
    //__________________________________________________________________________________________
    public getObjectUnder(pEvent: iClientPoint) {
        this._adjustRayCast(pEvent);
        let aIntersects = this.mRayCaster.intersectObjects(this.mClickableObjects, true);
        this.mRayCaster = null;
        let aPart: Part;
        if (aIntersects.length > 0) {
            aPart = OptixPartUtils.getOptixPartOfMesh(aIntersects[0].object);
        }
        return aPart;
    }
    //__________________________________________________________________________________________
    public onMouseUp(pEvent: iClientPoint) {
        let aObject: Intersection;
        this._adjustRayCast(pEvent);

        let aTargets = (null != this.mTargets) ? this.mTargets : this.mClickableObjects;

        let aIntersects = this.mRayCaster.intersectObjects(aTargets, true);

        this.mRayCaster = null;

        switch (SceneContext.CHOOSE_MODE.mode) {
            case eClickMode.BASE:
                aObject = aIntersects.find(el => el.object instanceof Mesh);
                break;
            case eClickMode.EDGES:
            case eClickMode.CS:
                let aEdge
                if (CSMode.instance.currentState() == ePhase.BASE_DIRECTION) {
                    switch (CSMode.instance.mDirectionState) {
                        case eDirection.FACE:
                            aObject = aIntersects.find(el => el.object instanceof Mesh);
                            break
                        case eDirection.POINT:
                        case eDirection.EDGE:
                            aObject = aIntersects.find(el => el.object instanceof LineSegments);
                            break
                    }

                } else {
                    aEdge = aIntersects.find(el => el.object instanceof LineSegments);
                    if ((null != aEdge) &&
                        (Math.floor(aIntersects[0].distance) == Math.floor(aEdge.distance))) {
                        aObject = aEdge;
                    } else {
                        aObject = aIntersects[0];
                    }
                }

                break;
            default:
                aObject = aIntersects[0];
                break;
        }

        if ((null != aObject) && (null != aObject.object)) {
            let aFunc = this.mMouseUpHash[aObject.object.uuid];
            if (aFunc != null) {
                aFunc(pEvent, aObject);
            } else {
                aObject = null
            }
        } else {
            Op3dContext.PARTS_EVENTS_HANDLER.resetHighlighting();
        }

        return aObject;
    }
    //__________________________________________________________________________________________
    public onHover(pEvent: iClientPoint) {
        if (pEvent.buttons == 2 || pEvent.buttons == 1 || pEvent.buttons == 4) return

        this._adjustRayCast(pEvent);
        this._onMouseOut();

        let aTargets = (null != this.mTargets) ? this.mTargets : this.mHoverableObjects;
        let aIntersects = this.mRayCaster.intersectObjects(aTargets, true);


        this.mRayCaster = null;

        if (aIntersects.length > 0) {
            let aObject = aIntersects[0].object
            if (SceneContext.CHOOSE_MODE.mode == eClickMode.EDGES || SceneContext.CHOOSE_MODE.mode == eClickMode.CS || SceneContext.CHOOSE_MODE.mode == eClickMode.MEASUREMENT) {
                let aEdge = aIntersects.filter(el => el.object instanceof LineSegments)[0]
                if (Math.floor(aIntersects[0]?.distance) == Math.floor(aEdge?.distance)) {
                    aObject = aEdge.object
                    aIntersects[0] = aEdge
                }
            }
            if (SceneContext.CHOOSE_MODE.mode === eClickMode.RAYS_POSITION) {
                document.body.classList.add('custom-crosshair');
            }

            if (null != aObject) {
                this._onMouseOver(aObject, aIntersects[0]);
            }


        }

    }
    //__________________________________________________________________________________________
    private _onMouseOver(aObject: Object3D, aIntersection: Intersection) {
        let aMouseOverFunc = this.mMouseOverHash[aObject.uuid];
        if (null == aMouseOverFunc) {
            return;
        }

        aMouseOverFunc(aIntersection);
        this.mMouseOverObject = aObject;
    }
    //__________________________________________________________________________________________
    private _onMouseOut() {
        document.body.classList.remove("custom-crosshair");
        document.body.classList.remove("pointer");
        if (null == this.mMouseOverObject) {
            return;
        }

        let aMouseOutFunc = this.mMouseOutHash[this.mMouseOverObject.uuid];
        if (null == aMouseOutFunc) {
            return;
        }

        aMouseOutFunc();
        this.mMouseOverObject = null;

    }
    //__________________________________________________________________________________________

    public onMouseDown(pEvent: iClientPoint) {
        this._adjustRayCast(pEvent);

        let aTargets = (null != this.mTargets) ? this.mTargets : this.mClickableObjects;
        let aIntersects = this.mRayCaster.intersectObjects(aTargets, true);
        this.mRayCaster = null;

        if (aIntersects.length > 0) {
            SceneContext.POSITION_GENERATOR.updateH(aIntersects[0].point.y);
            let aFunc = this.mMouseDownHash[aIntersects[0].object.uuid];
            if (aFunc != null) {
                if (SceneContext.CHOOSE_MODE.mode == eClickMode.FACES ||
                    SceneContext.CHOOSE_MODE.mode == eClickMode.CHANGE_FACE_PROPERTIES) {
                    aFunc(pEvent, aIntersects[0].face.a);
                } else {
                    aFunc(pEvent, (aIntersects[0] as any).faceIndex);
                }
            }
            this.mRayCaster = null
            return aIntersects[0].object;
        }
        return null
    }
    //__________________________________________________________________________________________
    public onDblMouseDown(pEvent: iClientPoint) {
        this._adjustRayCast(pEvent);

        let aTargets = (null != this.mTargets) ? this.mTargets : this.mClickableObjects;
        let aIntersects = this.mRayCaster.intersectObjects(aTargets, true);
        this.mRayCaster = null;

        if (aIntersects.length > 0) {
            SceneContext.POSITION_GENERATOR.updateH(aIntersects[0].point.y);
            let aFunc = this.mDblMouseDownHash[aIntersects[0].object.uuid];
            if (aFunc != null) {
                if (SceneContext.CHOOSE_MODE.mode == eClickMode.FACES ||
                    SceneContext.CHOOSE_MODE.mode == eClickMode.CHANGE_FACE_PROPERTIES) {
                    aFunc(pEvent, aIntersects[0].face.a);
                } else {
                    aFunc(pEvent, (aIntersects[0] as any).faceIndex);
                }
            }
            this.mRayCaster = null
            return aIntersects[0].object;
        }
        return null
    }
    //__________________________________________________________________________________________
    public removeClick(pDisplayObject: Object3D) {
        //delete this.mButtonsHash[pButton.display.uuid];
        let aIndex = this.mClickableObjects.findIndex((item) => item.uuid == pDisplayObject.uuid);
        if (aIndex != -1) {
            this.mClickableObjects.splice(aIndex, 1);
        }

        let aIndex2 = this.mHoverableObjects.findIndex((item) => item.uuid == pDisplayObject.uuid);
        if (aIndex != -1) {
            this.mHoverableObjects.splice(aIndex2, 1);
        }

        delete this.mMouseUpHash[pDisplayObject.uuid];
        delete this.mMouseDownHash[pDisplayObject.uuid];
        delete this.mMouseOverHash[pDisplayObject.uuid];
        delete this.mMouseOutHash[pDisplayObject.uuid];
        delete this.mDblMouseDownHash[pDisplayObject.uuid];
    }
    //__________________________________________________________________________________________
    private _addCallback(pCallback: Function, pHash: iHash<Function>, pKey: string) {
        if (null != pCallback) {
            pHash[pKey] = pCallback;
        }
    }
    //__________________________________________________________________________________________
    public removeAllListeners(pObject: Object3D) {
        pObject.traverse((obj) => {
            let aIndex = this.mClickableObjects.indexOf(obj);
            if (aIndex > -1) {
                this.mClickableObjects.splice(aIndex, 1);
            }
        });

        pObject.traverse((obj) => {
            let aIndex = this.mHoverableObjects.indexOf(obj);
            if (aIndex > -1) {
                this.mHoverableObjects.splice(aIndex, 1);
            }
        });
    }
    //__________________________________________________________________________________________
    public addEventListener(pDisplayObject: Object3D, pEventParams: iEventParams) {
        switch (pEventParams.type) {
            case "mousedown":
                this._addCallback(pEventParams.func, this.mMouseDownHash, pDisplayObject.uuid);
                this._addToClickableObjectsHash(pDisplayObject);
                break;
            case "dblclick":
                this._addCallback(pEventParams.func, this.mDblMouseDownHash, pDisplayObject.uuid);
                // this._addToClickableObjectsHash(pDisplayObject);
                break;
            case "mouseout":
                this._addCallback(pEventParams.func, this.mMouseOutHash, pDisplayObject.uuid);
                this._addToHoverObjectsHash(pDisplayObject);
                break;
            case "mouseover":
                this._addCallback(pEventParams.func, this.mMouseOverHash, pDisplayObject.uuid);
                this._addToHoverObjectsHash(pDisplayObject);
                break;
            case "mouseup":
                this._addCallback(pEventParams.func, this.mMouseUpHash, pDisplayObject.uuid);
                this._addToClickableObjectsHash(pDisplayObject);
                break;
            default:
                throw new Error("Wrong Button3DEvent type");
        }
    }
    //__________________________________________________________________________________________
    public removeObjectEventListener(pObject: Object3D) {
        pObject.traverse((obj) => {

            if (obj instanceof Mesh) {
                delete this.mMouseDownHash[obj.uuid];
                delete this.mMouseOutHash[obj.uuid];
                delete this.mMouseOverHash[obj.uuid];
                delete this.mMouseUpHash[obj.uuid];
                delete this.mDblMouseDownHash[obj.uuid];

                let aClickableObjectIndex = this.mClickableObjects.indexOf(obj);
                if (aClickableObjectIndex > -1) {
                    this.mClickableObjects.splice(aClickableObjectIndex, 1);
                }
            }
        });
    }
    //__________________________________________________________________________________________
    private _initStorage() {
        this.mClickableObjects = new Array<Object3D>();
        this.mHoverableObjects = new Array<Object3D>();
        //this.mButtonsHash = {};
        this.mMouseUpHash = {};
        this.mMouseDownHash = {};
        this.mMouseOutHash = {};
        this.mMouseOverHash = {};
        this.mDblMouseDownHash = {};
    }
    //__________________________________________________________________________________________
    private _addToClickableObjectsHash(pDisplayObject: Object3D) {
        let aFindIndexFunc = (item: Object3D) => (item.uuid == pDisplayObject.uuid);
        let aIndex = this.mClickableObjects.findIndex(aFindIndexFunc);
        if (aIndex == -1) {
            this.mClickableObjects.push(pDisplayObject);
        }
    }
    //__________________________________________________________________________________________
    private _addToHoverObjectsHash(pDisplayObject: Object3D) {
        let aFindIndexFunc = (item: Object3D) => (item.uuid == pDisplayObject.uuid);
        let aIndex = this.mHoverableObjects.findIndex(aFindIndexFunc);
        if (aIndex == -1) {
            this.mHoverableObjects.push(pDisplayObject);
        }
    }
    //__________________________________________________________________________________________
    private _adjustRayCast(pEvent: { clientX: number, clientY: number }) {
        this.mRayCaster = new Raycaster();

        let aVec = new Vector3();
        aVec.subVectors(SceneContext.CAMERA.position, SceneContext.OP3D_SCENE.lookAt);
        // let aDist = Math.min(1, aVec.length() / 150) / SceneContext.CAMERA.zoom;
        // let aTreshold = Math.max(Math.pow(aDist, 3) * 4, ClickManager.TRESHOLD)

        // this.mRayCaster.params.Line.threshold = aTreshold;
        // this.mRayCaster.params.Points.threshold = aTreshold;

        // (this.mRayCaster as any).linePrecision = aTreshold;

        let aBounding = SceneContext.RENDERER.domElement.getBoundingClientRect();
        let aMouse = new Vector2();
        aMouse.x = ((pEvent.clientX - aBounding.left) / SceneContext.RENDERER.domElement.clientWidth) * 2 - 1;
        aMouse.y = - ((pEvent.clientY - aBounding.top) / SceneContext.RENDERER.domElement.clientHeight) * 2 + 1;
        this.mRayCaster.setFromCamera(aMouse, SceneContext.CAMERA);
    }
    //__________________________________________________________________________________________
    public getZoomPoint(pEvent: { clientX: number, clientY: number }) {

        let aMouse = new Vector2();
        let aBounding = SceneContext.RENDERER.domElement.getBoundingClientRect();
        aMouse.x = ((pEvent.clientX - aBounding.left) / SceneContext.RENDERER.domElement.clientWidth) * 2 - 1;
        aMouse.y = - ((pEvent.clientY - aBounding.top) / SceneContext.RENDERER.domElement.clientHeight) * 2 + 1;
        this.mRayCaster = new Raycaster();
        this.mRayCaster.setFromCamera(aMouse, SceneContext.CAMERA);
        let aIntersects = this.mRayCaster.intersectObject(this.mZoomPlane);
        return aIntersects[0].point;
    }
    //__________________________________________________________________________________________
}
