import { SetupsManager } from "../setups/SetupManager";

export class UIUtils {
    public static getNewFileName(): string {
        if (SetupsManager.FILE_COUNTER == 0) {
            SetupsManager.FILE_COUNTER++;
            return "Untitled Setup";
        }
        return "Untitled Setup (" + (SetupsManager.FILE_COUNTER++).toString() + ")";
    }
    //__________________________________________________________________________________________
}
