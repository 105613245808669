import { eSidebarState } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { SnapshotTools } from "../../_utils/SnapshotTools";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { RBGroup } from "../components/RBGroup";

export enum eImageQuality {
    HD,
    FHD,
    QHD,
    FOUR_K
};
export enum eImageFormat {
    JPEG, PDF
};

export interface iImageQualityFormData {
    isPDF: boolean;
}

export class ImageQualityForm extends Op3dComponentBase<iImageQualityFormData> {

    private static INSTANCE: ImageQualityForm;
    private mImageType: eImageFormat;
    private mImageResolution: eImageQuality;
    mResolutionInput: RBGroup<eImageQuality>;
    mFormatInput: RBGroup<eImageFormat>;

    //______________________________________________________________________________________________
    constructor() {
        super({
            container: ImageQualityForm._getContainer(),
            skinPath: "./skins/tools/image_quality_form.html",
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //______________________________________________________________________________________________
    public static get instance() {
        if (undefined === ImageQualityForm.INSTANCE) {
            ImageQualityForm.INSTANCE = new ImageQualityForm();
        }

        return ImageQualityForm.INSTANCE;
    }
    //______________________________________________________________________________________________
    private static _getContainer() {
        let aContainer = document.createElement('div');
        aContainer.classList.add('modal');
        aContainer.classList.add('image_q_form');
        aContainer.classList.add('gb_calculator');
        aContainer.classList.add('new_modal');
        aContainer.classList.add('p-0');
        aContainer.style.left = ((window.innerWidth / 2) - 200) + 'px';
        aContainer.style.top = ((window.innerHeight / 2) - 150) + 'px';
        aContainer.style.width = '400px';
        aContainer.setAttribute('data-backdrop', 'static');
        document.getElementById('forms').appendChild(aContainer);

        return aContainer;
    }
    //______________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this._createResolutionField();
        this._createFileFormatField()
        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
    protected _addEventListeners(): void {
        this._getPart('download').addEventListener('click', () => this._onDownloadImage());
    }
    //______________________________________________________________________________________________
    private _createResolutionField() {
        let aNotice4K = this._getPart('notice_4k');

        this.mResolutionInput = new RBGroup<eImageQuality>(this._getPart('file_resolution'), {
            radioButtons: [
                {
                    label: 'High Definition (HD)',
                    value: eImageQuality.HD
                },
                {
                    label: 'Full High Definition (FHD)',
                    value: eImageQuality.FHD
                },
                {
                    label: 'Quad High Definition (QHD)',
                    value: eImageQuality.QHD
                },
                {
                    label: '4K',
                    value: eImageQuality.FOUR_K,
                    isPremiumFeature: true
                }
            ],
            onChange: (pValue) => {
                ViewUtils.setElementVisibilityByDNone(aNotice4K, (eImageQuality.FOUR_K === pValue))
                this.mImageResolution = pValue
            }
            ,
            title: 'Image Resolution',
            isColumn: true
        });
    }
    //______________________________________________________________________________________________
    private _createFileFormatField() {

        this.mFormatInput = new RBGroup<eImageFormat>(this._getPart('file_format'), {
            radioButtons: [
                {
                    label: 'jpeg',
                    value: eImageFormat.JPEG
                },
                {
                    label: 'pdf',
                    value: eImageFormat.PDF
                }
            ],
            onChange: (pValue) =>
                this.mImageType = pValue,
            title: 'Image file format',
            isColumn: false
        });
    }
    //______________________________________________________________________________________________
    protected _onOpen() {
        this.mResolutionInput.setValue(eImageQuality.HD, true);
        this.mFormatInput.setValue(eImageFormat.JPEG, true);
    }
    //______________________________________________________________________________________________
    private _onDownloadImage() {
        Op3dContext.DIV_CONTROLLER.foldSideBar(eSidebarState.MEDIUM);
        let aSetupName = Op3dContext.SETUPS_MANAGER.fileName;
        SnapshotTools.downloadImage(this.mImageType, this.mImageResolution, aSetupName);
        this.close();
    }
    //______________________________________________________________________________________________
}