﻿import { Op3dUtils } from "../../_utils/Op3dUtils";

export interface iDataHTMLInputElement<T> extends HTMLInputElement {
    data: T;
}

export interface iRadioButtonsComponentData<T> {
    elements: Array<{
        checked?: boolean;
        name: string;
        callback: () => void;
        data: T;
    }>
}

export class RadioButtonsComponent<T> {
    private static CUSTOM_COMPONENT_CLASS: string = "custom-toggle-component";
    private static DEFAULT_CHECKED_ELEMENT: string = "def_check";
    private mParentContainer: HTMLElement;

    constructor(pContainer: HTMLElement, pParams: iRadioButtonsComponentData<T>) {
        this.mParentContainer = pContainer;
        this._createComponent(pContainer, pParams);
    }
    //______________________________________________________________
    private _createComponent(pContainer: HTMLElement, pParams: iRadioButtonsComponentData<T>) {

        this.mParentContainer.classList.add(RadioButtonsComponent.CUSTOM_COMPONENT_CLASS);

        /*
        <div class="btn-group btn_group_parent" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="surface_btn" autocomplete="off">
            <label class="btn btn-grey"></label>
        </div>
        */

        let aDiv = document.createElement("div");
        aDiv.classList.add("btn-group", "btn_group_parent");
        aDiv.setAttribute("role", "group");

        let aGroupName = Op3dUtils.idGenerator();
        for (let i = 0; i < pParams.elements.length; i++) {

            let aId = "label_" + Op3dUtils.idGenerator();

            let aInput = document.createElement("input") as iDataHTMLInputElement<T>;
            aInput.type = "radio";
            aInput.classList.add("btn-check");
            aInput.name = aGroupName;
            aInput.id = aId;
            aInput.data = pParams.elements[i].data;

            if (pParams.elements[i].checked == true) {
                aInput.checked = true;
                aInput.setAttribute(RadioButtonsComponent.DEFAULT_CHECKED_ELEMENT, 'true');
            }

            let aLabel = document.createElement("label");
            aLabel.classList.add("btn", "btn-outline-primary");
            aLabel.innerHTML = pParams.elements[i].name;
            aLabel.addEventListener("click", pParams.elements[i].callback);
            aLabel.htmlFor = aId;

            aDiv.appendChild(aInput);
            aDiv.appendChild(aLabel);
        }


        pContainer.appendChild(aDiv)
    }
    //______________________________________________________________
    /**
     * @description - return the state of the toggle component to default
     */
    //______________________________________________________________
    public resetState() {
        let aElement = $(this.mParentContainer).find('input[type="radio"][' + RadioButtonsComponent.DEFAULT_CHECKED_ELEMENT + '="true"]')[0];
        if (aElement != null) {
            (aElement as HTMLInputElement).checked = true;
        }
    }
    //______________________________________________________________
    public getCurrentData(): T {
        let aCheckedElement = $(this.mParentContainer).find('input[type="radio"]:checked')[0] as iDataHTMLInputElement<T>;
        return aCheckedElement != null ? aCheckedElement.data : null;
    }
    //______________________________________________________________
}
