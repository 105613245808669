
import { eSidebarState } from "../_context/Enums";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { Part } from "../parts/Part";
import { ViewUtils } from "./ViewUtils";
import { eClickMode } from "./_globals/PartsEventsHandler";
import { absDivController } from "./absDivController";
import { Header } from "./home/Header";
import { SideBar } from "./home/SideBar";
import { TesselationProps } from "./home/TesselationPrefs";
import { NotificationCenter } from "./home/_notifications/NotificationCenter";
import { eNotificationToastDuration } from "./home/_notifications/NotificationsContext";
import { OpticsMenu } from "./menus/_search/OpticsMenu";
import { OptomechanicMenu } from "./menus/_search/OptomechanicMenu";

export class DivController extends absDivController {


    constructor() {
        super();

    }
    //__________________________________________________________________________________________
    protected _init() {
        super._init();

        this.mSideBar = new SideBar();
        this.mHeader = new Header();

        this._checkBrowser();
        this.internalUseFunctions();

    }
    //__________________________________________________________________________________________
    private internalUseFunctions() {
        const aEmail = Op3dContext.USER_VO.email;
        if (Op3dContext.APP_FEATURES_CONFIG.triangulation_prefs.enabled === true &&
            Op3dContext.APP_FEATURES_CONFIG.triangulation_prefs.email.indexOf(aEmail) !== -1) {
            new TesselationProps().open();
        } else {
            ViewUtils.removeFromParent(document.getElementById("tesselation-props"));
        }
    }
    //__________________________________________________________________________________________
    private _checkBrowser() {
        if (!((navigator.userAgent.indexOf("Edg") !== -1 || navigator.userAgent.indexOf("Chrome") !== -1) && navigator.userAgent.indexOf("OPR") === -1)) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.BROWSER_MESSAGE,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ALARM,
                toastDuration: eNotificationToastDuration.INFINITE
            });
        }
    }
    //__________________________________________________________________________________________
    public async onOpenSearchMenu(pType, pCloseType) {
        let pPrevOpenedClass
        switch (pCloseType) {
            case 'Optics':
                pPrevOpenedClass = OpticsMenu
                break;
            case 'Opto-Mechanics':
                pPrevOpenedClass = OptomechanicMenu
                break;

        }


        switch (pType) {
            case 'Optics':
                OpticsMenu.instance.open(pPrevOpenedClass.instance.data)
                break;
            case 'Opto-Mechanics':
                OptomechanicMenu.instance.open(pPrevOpenedClass.instance.data)
                break;
        }
        await Op3dContext.sleep(500)
        pPrevOpenedClass.instance.close()
    }
    //__________________________________________________________________________________________
    public foldSideBar(pSize: eSidebarState) {
        switch (pSize) {
            case eSidebarState.SMALL:
                this.mSideBar.foldS();
                break;
            case eSidebarState.MEDIUM:
                this.mSideBar.foldM();
                break;
            case eSidebarState.LARGE:
                this.mSideBar.foldL();
                break;
        }
    }
    //__________________________________________________________________________________________
    protected _onPartSelected(pPart: Part) {

        if ((Op3dContext.PARTS_MANAGER.selectedPart != pPart) ||
            (true == pPart.partOptions.static) || (false == pPart.visibleObj.visible)) {
            return;
        }

        Op3dContext.PART_INFO.open();
    }
    //__________________________________________________________________________________________
    private _openPartMenu(pEvent: MouseEvent, pPart: Part) {
        if ((null != pPart.partOptions) && (true == pPart.partOptions.static)) {
            return;
        }

        if (null != pPart) {
            Op3dContext.SIDEBAR_LIST.openContextMenu(pPart, pEvent);
            return;
        }
    }
    //__________________________________________________________________________________________
    public openContextMenu(pEvent: MouseEvent, pPartUnder: Part) {
        switch (Op3dContext.PARTS_EVENTS_HANDLER.mode) {
            case eClickMode.BASE:
                if ((null != pPartUnder) && (false == pPartUnder.partOptions.static)) {
                    this._openPartMenu(pEvent, pPartUnder);
                } else {
                    Op3dContext.PARTS_MANAGER.setSelectedPart(null);
                    Op3dContext.SIDEBAR_LIST.openContextMenu(null, pEvent);
                }
                break;
            default:
                return;
        }
    }
    //__________________________________________________________________________________________
}
