import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iMinMax, iPoint2D } from "../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iSurfaceDeformation } from "../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../parts/Part";
import { eGroupType, iArrayOfElementsOptions, iArrayOfElementsParameters } from "../../../parts/PartInterfaces";
import { eSmBaseShapeKind } from "../../../simulation/SimulationContext";
import { UnitHandler } from "../../../units/UnitsHandler";
import { ViewUtils } from "../../ViewUtils";
import { RadioComponent } from "../../components/RadioComponent";
import { aoeSurfaceShape } from "../../forms/array_of_elements/aoeSurfaceShape";
import { iSurfaceShapeLightSource } from "../../forms/optics/esSurfaceShapeLightSource";
import { Spinner } from "../../home/Spinner";
import { NotificationCenter } from "../../home/_notifications/NotificationCenter";
import { PartInfoSection } from "../PartInfoSection";
import { NIE } from "../_components/NIE";

export enum eSamplingCellsDef {
    SAMPLING = "SAMPLING",
    COUNT = "COUNT"
}

export interface iArrayOfElementsSectionData {
    data: iArrayOfElementsOptions,
    isGroupSettings: boolean;
    countAddition: boolean;
    updateRange?: (aArrayData: iArrayOfElementsOptions) => void;
    defaults: iArrayOfelementsDefaults;
    partInternalID: string;
    hasCountCheck: boolean;
    isCad?: boolean;
}

export interface iArrayOfelementsDefaults {
    sampling: iPoint2D,
    half_height: number,
    half_width: number,
    radius_x: number,
    radius_y: number,
    range: iMinMax,
    count_range: iMinMax,
    count: iPoint2D
}
export class piArrayofElementsSettings extends PartInfoSection<iArrayOfElementsSectionData> {

    /**
     * the maximal amount of elements possible in the array of elements
     */
    private static MAXIMAL_MUL_COUNT = { optics: 100, cad: 26 };

    public static DEFAULTS: {
        general: iArrayOfelementsDefaults,
        source: iArrayOfelementsDefaults,
    } = {
            source: {
                sampling: { x: 2, y: 2 },
                half_height: 10,
                half_width: 10,
                radius_x: 10,
                radius_y: 10,
                range: { min: 0.001, max: 1000 },
                count_range: { min: 1, max: 2e5 },
                count: { x: 5, y: 5 }
            },
            general: {
                sampling: { x: 2, y: 2 },
                half_height: 10,
                half_width: 10,
                radius_x: 10,
                radius_y: 10,
                range: { min: 0.001, max: 10000 },
                count_range: { min: 1, max: 2e5 },
                count: { x: 1, y: 1 }
            },
        }

    private mIsArrayCheckbox: HTMLInputElement;
    private mArraySourcesContainer: HTMLElement;
    private mShapeSelect: HTMLSelectElement;
    private mEditShapeComponent: aoeSurfaceShape;

    private mElements: {
        half_height?: NIE;
        half_width?: NIE;
        radius_x?: NIE;
        radius_y?: NIE;
        sampling_x?: NIE;
        sampling_y?: NIE;
        count_x?: NIE;
        count_y?: NIE;

    } = {}
    private mSurfaceShapeData: iSurfaceDeformation<any>[];
    private mSamplingComponent: RadioComponent;
    private mEditSurfaceShapeBtn: HTMLElement;
    private mOptions: iArrayOfElementsSectionData;

    constructor(pContainer: HTMLElement,) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_array_elements_settings.html'
        });
    }
    //__________________________________________________________________________________________
    public static getOpticDefaults(pMainItem: Part): iArrayOfelementsDefaults {

        const aNetoSize = Op3dUtils.getNetoItemSize(pMainItem.visibleObj, 5);

        let aMinSize: iPoint2D = {
            x: aNetoSize.x,
            y: aNetoSize.y
        }

        // let aMaxSize = Math.max(aMinSize.x, aMinSize.y);

        let aData: iArrayOfelementsDefaults = {
            count_range: { min: 1, max: 2e5 },
            half_height: aMinSize.y,
            half_width: aMinSize.x,
            radius_x: aMinSize.x,
            radius_y: aMinSize.y,
            sampling: aMinSize,
            count: { x: 1, y: 1 },
            range: piArrayofElementsSettings.DEFAULTS.general.range
        }

        return aData
    }
    //__________________________________________________________________________________________
    private _onOpenEditForm() {
        if (this.mEditShapeComponent == null) {
            let aFormsContainer = document.getElementById("forms");
            if (aFormsContainer === null) {
                throw new Error("Missing forms container");
            }

            let aContainer = document.createElement("div");
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.id = "aos-edit-shape-form";
            aFormsContainer.appendChild(aContainer);
            this.mEditShapeComponent = new aoeSurfaceShape(aContainer, () => this.onShapeUpdated());
        }

        let aRadius = this._getNormalizationRadius();
        let aData: iSurfaceShapeLightSource = {
            height: this.mElements.half_height.actualValue * 2,
            width: this.mElements.half_width.actualValue * 2,
            normalization_radius: aRadius,
            deformations: this.mSurfaceShapeData,
            shape: this.mShapeSelect.value as eSmBaseShapeKind
        }
        this.mEditShapeComponent.open(aData);
    }
    //__________________________________________________________________________________________
    private async onShapeUpdated() {
        await this._onChange();
        this.mSurfaceShapeData = this.mEditShapeComponent.getData();
    }
    //__________________________________________________________________________________________
    private _getSurfaceShapeData() {
        if (this.mEditShapeComponent == null) {
            return null;
        }

        return this.mEditShapeComponent.getData()
    }
    //__________________________________________________________________________________________
    private async _onChange() {
        Spinner.instance.show();
        Op3dContext.SCENE_HISTORY.addToHistory();
        let aData = this.getData();
        await Op3dContext.sleep(10);
        let aPart = Op3dContext.PARTS_MANAGER.getPartByInternalId(this.mOptions.partInternalID);
        await aPart.setArrayOptions(aData);

        Spinner.instance.hide();
        this.show(false);
        this.mData.data = aData;
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _onChangeSamplingView(pVal: string) {
        $(this.mContainer).find("[sampling]").each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                pVal != "" && element.getAttribute('sampling').indexOf(pVal) != -1);
        });
    }
    //__________________________________________________________________________________________
    // private _getSizeOf(pArrayData: iArrayOfElementsOptions): iPoint2D {

    //     switch (pArrayData.data.kind) {
    //         case eSmBaseShapeKind.ELLIPSE:
    //             return {
    //                 x: pArrayData.data.radius_x,
    //                 y: pArrayData.data.radius_y
    //             }

    //         case eSmBaseShapeKind.RECTANGLE:
    //             return {
    //                 x: pArrayData.data.half_width,
    //                 y: pArrayData.data.half_height
    //             }
    //     }
    // }
    //__________________________________________________________________________________________
    // public updateRanges(pMainItem: Part, pArrayData: iArrayOfElementsOptions) {
    //     if (pMainItem === undefined) {
    //         return;
    //     }

    //     let aNetoSize = Op3dUtils.getNetoItemSize(pMainItem.visibleObj, 5);

    //     let aMinSize: iPoint2D = {
    //         x: aNetoSize.x,
    //         y: aNetoSize.y,
    //     }

    //     this.mElements.half_width.updateRange({
    //         min: aMinSize.x,
    //         max: piArrayofElementsSettings.DEFAULTS.general.range.max
    //     });

    //     this.mElements.half_height.updateRange({
    //         min: aMinSize.y,
    //         max: piArrayofElementsSettings.DEFAULTS.general.range.max
    //     });

    //     let aHalfSize = pArrayData !== undefined && pArrayData.data !== undefined ?
    //         this._getSizeOf(pArrayData) : aMinSize;

    //     this.mElements.count_x.updateRange({
    //         min: 2,
    //         max: Math.floor((aHalfSize.x * 2) / aNetoSize.x)
    //     });

    //     this.mElements.count_y.updateRange({
    //         min: 2,
    //         max: Math.floor((aHalfSize.y * 2) / aNetoSize.y)
    //     });

    //     this.mElements.sampling_x.updateRange({
    //         min: aNetoSize.x,
    //         max: aHalfSize.x * 2
    //     });

    //     this.mElements.sampling_y.updateRange({
    //         min: aNetoSize.y,
    //         max: aHalfSize.y * 2
    //     });
    // }
    //__________________________________________________________________________________________
    private _updateSampling(pCheckCount: boolean) {
        // count was changed

        let aCountX = this.mElements.count_x.actualValue;
        let aCountY = this.mElements.count_y.actualValue;
        let aSize = this._getSize();

        let aAddition = 0;
        if (this.mOptions.countAddition) {
            aAddition = 1;
        }

        // let aSamplingMin = this.mData.defaults.range.min;
        let aSamplingX = (aSize.x * 2) / (aCountX - aAddition)
        // let aSamplingX = Math.max(((aSize.x * 2) / (aCountX - aAddition)), aSamplingMin);
        this.mElements.sampling_x.actualValue = aSamplingX;

        // let aSamplingY = Math.max(((aSize.y * 2) / (aCountY - aAddition)), aSamplingMin);
        let aSamplingY = (aSize.y * 2) / (aCountY - aAddition);
        this.mElements.sampling_y.actualValue = aSamplingY;

        if (pCheckCount) {
            this._checkCount();
        }
    }
    //__________________________________________________________________________________________
    private _checkCount() {
        if (this.mOptions.hasCountCheck === false) {
            return;
        }

        let aSize = this._getSize();
        const aCountX = Math.floor(((aSize.x * 2) / this.mElements.sampling_x.actualValue));
        const aCountY = Math.floor(((aSize.y * 2) / this.mElements.sampling_y.actualValue));

        let aIsCad = this.mOptions.isCad;
        let aTotalCount = aCountX * aCountY;
        let aMax: number = aIsCad ?
            piArrayofElementsSettings.MAXIMAL_MUL_COUNT.cad :
            piArrayofElementsSettings.MAXIMAL_MUL_COUNT.optics;


        if (aTotalCount > aMax) {
            const aRatio = Math.sqrt(aMax / aTotalCount);

            this.mElements.count_x.actualValue = Math.max(1,
                Math.floor(this.mElements.count_x.actualValue * aRatio));

            this.mElements.count_y.actualValue = Math.max(1,
                Math.floor(this.mElements.count_y.actualValue * aRatio));

            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.MAX_ALLOWED_ARRAY_OF_ELEMENTS_COUNT_MSG(aMax),
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
            });

            this._onChangeSamplingOption(eSamplingCellsDef.COUNT, false);
        }
    }
    //__________________________________________________________________________________________
    private _getSize(): iPoint2D {
        let aSizeX: number;
        let aSizeY: number;

        switch (this.mShapeSelect.value as eSmBaseShapeKind) {
            case eSmBaseShapeKind.ELLIPSE:
                aSizeX = this.mElements.radius_x.actualValue;
                aSizeY = this.mElements.radius_y.actualValue;
                break;

            case eSmBaseShapeKind.RECTANGLE:
                aSizeX = this.mElements.half_width.actualValue;
                aSizeY = this.mElements.half_height.actualValue;
                break;
        }

        return { x: aSizeX, y: aSizeY };
    }
    //__________________________________________________________________________________________
    private _updateCount(pToCheckCount: boolean) {
        // sampling was changed

        let aSamplingX = this.mElements.sampling_x.actualValue;
        let aSamplingY = this.mElements.sampling_y.actualValue;
        let aSize = this._getSize();

        let aAddition = 0;
        if (this.mOptions.countAddition) {
            aAddition = 1;
        }

        let aCountX = Math.floor(((aSize.x * 2) / aSamplingX)) + aAddition;
        this.mElements.count_x.actualValue = aCountX;

        let aCountY = Math.floor(((aSize.y * 2) / aSamplingY)) + aAddition;
        this.mElements.count_y.actualValue = aCountY;

        if (pToCheckCount) {
            this._checkCount();
        }
    }
    //__________________________________________________________________________________________
    private async _onChangeSamplingOption(pOpt: eSamplingCellsDef, pToChange: boolean = true) {
        switch (pOpt) {
            case eSamplingCellsDef.COUNT:
                this._updateSampling(pToChange);
                break;
            case eSamplingCellsDef.SAMPLING:
                this._updateCount(pToChange);
                break;
        }
        if (pToChange) {
            await this._onChange();
        }

    }
    //__________________________________________________________________________________________
    private async _onChangeHalfSize() {
        let aSize = this._getSize();
        let aMinSampling = this.mData.defaults.range.min;
        let aMaxSamplingX = (2 * aSize.x);
        let aMaxSamplingY = (2 * aSize.y);

        let aSamplingX = this.mElements.sampling_x.actualValue;
        let aSamplingY = this.mElements.sampling_y.actualValue;

        // let aAddition = 0;
        // if (this.mOptions.countAddition) {
        //     aAddition = 1;
        // }
        this.mElements.sampling_x.updateRange({
            min: aMinSampling,
            max: aMaxSamplingX
        });
        this.mElements.sampling_x.actualValue = Math.min(aMaxSamplingX,
            aSamplingX);

        this.mElements.sampling_y.updateRange({
            min: aMinSampling,
            max: aMaxSamplingY
        });
        this.mElements.sampling_y.actualValue = Math.min(aMaxSamplingY, aSamplingY);

        let aMinCount = this.mData.defaults.count_range.min;
        let aMaxCountX = (aMaxSamplingX / aMinSampling);
        let aMaxCountY = (aMaxSamplingY / aMinSampling);
        let aCountX = this.mElements.count_x.actualValue;
        let aCountY = this.mElements.count_y.actualValue;

        this.mElements.count_x.updateRange({ min: aMinCount, max: aMaxCountX });
        this.mElements.count_y.updateRange({ min: aMinCount, max: aMaxCountY });

        this.mElements.count_x.actualValue = OP3DMathUtils.clampValue(aCountX,
            aMinCount, aMaxCountX);
        this.mElements.count_y.actualValue = OP3DMathUtils.clampValue(aCountY,
            aMinCount, aMaxCountX);

        await this._onChangeSamplingOption(this.mSamplingComponent.chosenOption as eSamplingCellsDef, true);
    }
    //__________________________________________________________________________________________
    // public setDefaultRange() {
    //     const aUnitConversion = UnitHandler.presentedScale;

    //     this.mElements.radius_x.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.range.min,
    //         max: piArrayofElementsSettings.DEFAULTS.general.range.max * aUnitConversion

    //     })

    //     this.mElements.radius_y.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.range.min,
    //         max: piArrayofElementsSettings.DEFAULTS.general.range.max * aUnitConversion

    //     })

    //     this.mElements.half_width.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.range.min,
    //         max: piArrayofElementsSettings.DEFAULTS.general.range.max * aUnitConversion

    //     })


    //     this.mElements.half_height.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.range.min,
    //         max: piArrayofElementsSettings.DEFAULTS.general.range.max * aUnitConversion

    //     })

    //     this.mElements.sampling_x.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.range.min,
    //         max: ((2 * piArrayofElementsSettings.DEFAULTS.general.half_width) * aUnitConversion)

    //     })

    //     this.mElements.sampling_y.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.range.min,
    //         max: ((2 * piArrayofElementsSettings.DEFAULTS.general.half_height) * aUnitConversion)

    //     })

    //     this.mElements.count_x.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.count_range.min,
    //         max: piArrayofElementsSettings.DEFAULTS.general.count_range.max

    //     })

    //     this.mElements.count_y.updateRange({
    //         min: piArrayofElementsSettings.DEFAULTS.general.count_range.min,
    //         max: piArrayofElementsSettings.DEFAULTS.general.count_range.max

    //     })
    // }
    //__________________________________________________________________________________________
    protected _initElements(): void {

        this.mEditSurfaceShapeBtn = this._getPart("set-shape-component-aos");
        this.mEditSurfaceShapeBtn.addEventListener("click", () => this._onOpenEditForm());
        this.mArraySourcesContainer = this._getPart("is_array_sources_container", true);
        this.mIsArrayCheckbox = this._getPart("is-array-of-sources") as HTMLInputElement;
        this.mIsArrayCheckbox.addEventListener("change", () => this._onChangeState(true));

        this.mShapeSelect = this._getPart("surface-2d-shape", true) as HTMLSelectElement;
        this.mShapeSelect.addEventListener("change", () => this._onChangeShape(true));

        this.mSamplingComponent = new RadioComponent(this._getPart("sampling-component"), {
            value_attr: "sampling-btn",
            onChange: (pVal: string) => this._onChangeSamplingView(pVal as eSamplingCellsDef),
            triggers: {
                saveHistory: false,
                saveScene: false,
                triggerAnalysis: false
            }
        });

        const aUnitSign = UnitHandler.shortSign;

        this.mElements.radius_x = new NIE(
            this._getPart("radius_x", true),
            {
                qa_id: "qa_radius_x",
                isGlobalToFixed: true,
                label: {
                    text: "Radius x",
                    bold: false,
                    justify: "start"
                },
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.range.max
                },
                unit: aUnitSign,
                initialValue: piArrayofElementsSettings.DEFAULTS.general.radius_x,
                presentedScale: UnitHandler.presentedScale,
                class: ["pi_aoe"],
                onChange: () => this._onChangeHalfSize(),
            });

        this.mElements.radius_y = new NIE(
            this._getPart("radius_y", true),
            {
                qa_id: "qa_radius_y",
                isGlobalToFixed: true,
                label: {
                    text: "Radius y",
                    bold: false,
                    justify: "start"
                },
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.range.max
                },
                unit: aUnitSign,
                initialValue: piArrayofElementsSettings.DEFAULTS.general.radius_y,
                presentedScale: UnitHandler.presentedScale,
                class: ["pi_aoe"],
                onChange: () => this._onChangeHalfSize(),
            });

        this.mElements.half_width = new NIE(
            this._getPart("half-width", true),
            {
                qa_id: "qa_half_width",
                isGlobalToFixed: true,
                label: {
                    text: "Half width",
                    bold: false,
                    justify: "start"
                },
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.range.max
                },
                unit: aUnitSign,
                initialValue: piArrayofElementsSettings.DEFAULTS.general.half_width,
                presentedScale: UnitHandler.presentedScale,
                class: ["pi_aoe"],
                onChange: () => this._onChangeHalfSize(),
            });

        this.mElements.half_height = new NIE(
            this._getPart("half-height", true), {
            qa_id: "qa_half_height",
            presentedScale: UnitHandler.presentedScale,
            label: {
                text: "Half height",
                bold: false,
                justify: "start"
            },
            isGlobalToFixed: true,
            initialValue: piArrayofElementsSettings.DEFAULTS.general.half_height,
            onChange: () => this._onChangeHalfSize(),
            range: {
                min: piArrayofElementsSettings.DEFAULTS.general.range.min,
                max: piArrayofElementsSettings.DEFAULTS.general.range.max
            },
            class: ["pi_aoe"],
            unit: aUnitSign
        });

        this.mElements.sampling_x = new NIE(
            this._getPart("sampling-x", true),
            {
                qa_id: "qa_sampling_x",
                isGlobalToFixed: true,
                label: {
                    text: "Period-X",
                    bold: false,
                    justify: "start"
                },
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.range.max
                    // max: ((2 * piArrayofElementsSettings.DEFAULTS.general.half_width) * aUnitConversion)
                },
                unit: aUnitSign,
                initialValue: piArrayofElementsSettings.DEFAULTS.general.sampling.x,
                presentedScale: UnitHandler.presentedScale,
                class: ["pi_aoe"],
                onChange: () => this._onChangeSamplingOption(eSamplingCellsDef.SAMPLING),
            });

        this.mElements.sampling_y = new NIE(
            this._getPart("sampling-y", true),
            {
                qa_id: "qa_sampling_y",
                isGlobalToFixed: true,
                label: {
                    text: "Period-Y",
                    bold: false,
                    justify: "start"
                },
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.range.max
                    // max: ((2 * piArrayofElementsSettings.DEFAULTS.general.half_height) * aUnitConversion)
                },
                unit: aUnitSign,
                initialValue: piArrayofElementsSettings.DEFAULTS.general.sampling.y,
                presentedScale: UnitHandler.presentedScale,
                class: ["pi_aoe"],
                onChange: () => this._onChangeSamplingOption(eSamplingCellsDef.SAMPLING),
            });

        this.mElements.count_x = new NIE(
            this._getPart("count-x", true),
            {
                qa_id: "qa_count_x",
                isGlobalToFixed: true,
                label: {
                    text: "Count-X",
                    bold: false,
                    justify: "start"
                },
                isInt: true,
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.count_range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.count_range.max
                },
                initialValue: piArrayofElementsSettings.DEFAULTS.general.count.x,
                class: ["pi_aoe"],
                onChange: () => this._onChangeSamplingOption(eSamplingCellsDef.COUNT),
            });

        this.mElements.count_y = new NIE(
            this._getPart("count-y", true),
            {
                qa_id: "qa_count_y",
                isGlobalToFixed: true,
                label: {
                    text: "Count-Y",
                    bold: false,
                    justify: "start"
                },
                isInt: true,
                range: {
                    min: piArrayofElementsSettings.DEFAULTS.general.count_range.min,
                    max: piArrayofElementsSettings.DEFAULTS.general.count_range.max
                },
                initialValue: piArrayofElementsSettings.DEFAULTS.general.count.y,
                class: ["pi_aoe"],
                onChange: () => this._onChangeSamplingOption(eSamplingCellsDef.COUNT),
            });
    }
    //__________________________________________________________________________________________
    private async _onChangeShape(pUpdate: boolean) {
        let aVal = this.mShapeSelect.value;
        $(this.mContainer).find('[shape_2d]').each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                aVal != "" && element.getAttribute('shape_2d').indexOf(aVal) != -1);
        });

        if (pUpdate) {
            await this._onChange();
        }
    }
    //__________________________________________________________________________________________
    private _setInitials() {

        this.mShapeSelect.value = eSmBaseShapeKind.RECTANGLE;
        this._onChangeShape(false);
        this.mElements.radius_x.actualValue = this.mData.defaults.radius_x;
        this.mElements.radius_y.actualValue = this.mData.defaults.radius_y;
        this.mElements.half_width.actualValue = this.mData.defaults.half_width;
        this.mElements.half_height.actualValue = this.mData.defaults.half_height;
        this.mElements.sampling_x.actualValue = this.mData.defaults.sampling.x;
        this.mElements.sampling_y.actualValue = this.mData.defaults.sampling.y;
        this.mElements.count_x.actualValue = this.mData.defaults.count.x;
        this.mElements.count_y.actualValue = this.mData.defaults.count.y;
    }
    //__________________________________________________________________________________________
    private async _onChangeState(pUpdate: boolean) {
        if (Op3dContext.USER_VO.isBasicLicense) {
            return;
        }

        let aIsChecked = this.mIsArrayCheckbox.checked;
        ViewUtils.setElementVisibilityByDNone(this.mArraySourcesContainer, aIsChecked);

        if (pUpdate) {

            if (aIsChecked) {
                this._setInitials();
            }

            await this._onChange();
            Op3dContext.PARTS_MANAGER.updatePartsList(false);
        }
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iArrayOfElementsSectionData): void {
        const aArrayData = pData.data;
        this.mOptions = pData;

        const aIsArray = aArrayData != null ? aArrayData.is_array : false;
        this.mIsArrayCheckbox.checked = aIsArray
        this._onChangeState(false);
        this.mSurfaceShapeData = null;
        ViewUtils.setElementVisibilityByDNone(this.mEditSurfaceShapeBtn, !pData.isGroupSettings);

        $(this.mContainer).find(".aoe-blocked").each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                !pData.isGroupSettings);
        });

        if (aIsArray) {

            this.mSurfaceShapeData = aArrayData.data.sourceShapeData;
            this.mShapeSelect.value = aArrayData.data.kind;
            this._onChangeShape(false);
            this.mElements.radius_x.actualValue = aArrayData.data.radius_x;
            this.mElements.radius_y.actualValue = aArrayData.data.radius_y;
            this.mElements.half_width.actualValue = aArrayData.data.half_width;
            this.mElements.half_height.actualValue = aArrayData.data.half_height;
            this.mElements.sampling_x.actualValue = aArrayData.data.sampling_x;
            this.mElements.sampling_y.actualValue = aArrayData.data.sampling_y;

            this._updateCount(false);
        }
    }
    //__________________________________________________________________________________________
    private _getNormalizationRadius() {
        switch (this.mShapeSelect.value as eSmBaseShapeKind) {
            case eSmBaseShapeKind.ELLIPSE:
                let radius_x = this.mElements.radius_x.actualValue;
                let radius_y = this.mElements.radius_y.actualValue;
                return Math.min(radius_x, radius_y) / 2;
            case eSmBaseShapeKind.RECTANGLE:
                let half_width = this.mElements.half_width!.actualValue;
                let half_height = this.mElements.half_height!.actualValue;
                return Math.min(half_width, half_height) / 2;
        }
    }
    //__________________________________________________________________________________________
    public getData() {

        let aProps: iArrayOfElementsParameters = {
            kind: this.mShapeSelect.value as eSmBaseShapeKind,
            radius_x: this.mElements.radius_x.actualValue,
            radius_y: this.mElements.radius_y.actualValue,
            half_width: this.mElements.half_width.actualValue,
            half_height: this.mElements.half_height.actualValue,
            sampling_x: this.mElements.sampling_x.actualValue,
            sampling_y: this.mElements.sampling_y.actualValue,
            sourceShapeData: this._getSurfaceShapeData()
        }

        const aIsGroup = this.mIsArrayCheckbox.checked;
        let aData: iArrayOfElementsOptions = {
            group_type: aIsGroup ? eGroupType.ARRAY_OF_ELEMENTS : undefined,
            is_array: aIsGroup,
            data: aIsGroup ? aProps : undefined
        }

        return aData;
    }
    //__________________________________________________________________________________________
}
