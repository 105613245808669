import { MathContext } from "../../../_context/MathContext";
import { ComplexNumber } from "../../../_utils/ComplexNumber";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Part } from "../../../parts/Part";
import { ePropogationType, iGaussianBeam } from "../../../parts/behaviors/LightSourceContext";
import { UnitHandler } from "../../../units/UnitsHandler";
import { CustomSelect } from "../../components/CustomSelect";
import { FloatingImage } from "../../forms/FloatingImage";
import { PartInfoSection } from "../PartInfoSection";
import { NIE } from "../_components/NIE";

export interface iGaussianBeamSection {
    beam: iGaussianBeam;
    part: Part;
}

export class piGaussianBeam extends PartInfoSection<iGaussianBeamSection> {

    private mCallback: () => void;

    private n: number;
    private wavelength: number;

    private omega_x_0: NIE;
    private omega_y_0: NIE;
    private half_angle_x: NIE;
    private half_angle_y: NIE;
    private z_x_0: NIE;
    private z_y_0: NIE;
    private astigmatism: NIE;
    private assymetry: NIE;
    private omega_0_r: NIE;
    private z_0_r: NIE;

    private zR_x_0: NIE;
    private zR_y_0: NIE;

    private mPropogationTypeSelec: CustomSelect;

    constructor(pContainer: HTMLElement, pCallback: () => void) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_gaussian_beam.html'
        });

        this.mCallback = pCallback;
    }
    //______________________________________________________________________________________________
    protected _initElements(): void {
        this.omega_x_0 = new NIE(Op3dUtils.getElementIn(this.mContainer, 'waist_x'),
            {
                label: { text: 'Waist x', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                isGlobalToFixed: true,
                onChange: () => this._onChange(),
                initialValue: (3 * OP3DMathUtils.MILI),
                decimalPrecision: 5
            });
        this.omega_y_0 = new NIE(Op3dUtils.getElementIn(this.mContainer, 'waist_y'),
            {
                label: { text: 'Waist y', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                isGlobalToFixed: true,
                onChange: () => this._onChange(),
                initialValue: (3 * OP3DMathUtils.MILI),
                decimalPrecision: 5
            });
        this.half_angle_x = new NIE(Op3dUtils.getElementIn(this.mContainer, 'half_angle_x'),
            {
                label: { text: 'Divergence angle x', justify: 'start', bold: false },
                unit: 'deg',
                isGlobalToFixed: true,
                decimalPrecision: 10,
                presentedScale: MathContext.RAD_TO_DEG,
                range: { min: 0, max: (Math.PI / 2) },
                onChange: (pVal) => {
                    let aWavelength = this.wavelength;
                    this.omega_x_0.actualValue = (aWavelength / (Math.PI * this.n * pVal));
                    this._onChange();
                }
            });
        this.half_angle_y = new NIE(Op3dUtils.getElementIn(this.mContainer, 'half_angle_y'),
            {
                label: { text: 'Divergence angle y', justify: 'start', bold: false },
                unit: 'deg',
                isGlobalToFixed: true,
                decimalPrecision: 10,
                presentedScale: MathContext.RAD_TO_DEG,
                range: { min: 0, max: (Math.PI / 2) },
                onChange: (pVal) => {
                    let aWavelength = this.wavelength;
                    this.omega_y_0.actualValue = (aWavelength / (Math.PI * this.n * pVal));
                    this._onChange();
                }
            });
        this.z_x_0 = new NIE(Op3dUtils.getElementIn(this.mContainer, 'waist_position_x'),
            {
                label: { text: 'Waist position x', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                onChange: () => this._onChange(),
                initialValue: 0,
                isGlobalToFixed: true,
                decimalPrecision: 5
            });
        this.z_y_0 = new NIE(Op3dUtils.getElementIn(this.mContainer, 'waist_position_y'),
            {
                label: { text: 'Waist position y', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                onChange: () => this._onChange(),
                initialValue: 0,
                isGlobalToFixed: true,
                decimalPrecision: 5
            });
        this.zR_x_0 = new NIE(Op3dUtils.getElementIn(this.mContainer, 'rayleigh_range_x'),
            {
                label: { text: 'Rayleigh range x', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                isGlobalToFixed: true,
                onChange: (pVal) => {
                    let aWavelength = this.wavelength;
                    this.omega_x_0.actualValue =
                        Math.sqrt((aWavelength * pVal) / (Math.PI * this.n));
                    this._onChange();

                },
                initialValue: 0,
                decimalPrecision: 5
            });
        this.zR_y_0 = new NIE(Op3dUtils.getElementIn(this.mContainer, 'rayleigh_range_y'),
            {
                label: { text: 'Rayleigh range y', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                isGlobalToFixed: true,
                onChange: (pVal) => {
                    let aWavelength = this.wavelength;
                    this.omega_y_0.actualValue =
                        Math.sqrt((aWavelength * pVal) / (Math.PI * this.n));
                    this._onChange();
                },
                initialValue: 0,
                decimalPrecision: 5
            });
        this.astigmatism = new NIE(Op3dUtils.getElementIn(this.mContainer, 'astigmatism'),
            {
                label: { text: 'Astigmatism', justify: 'start', bold: false },
                isGlobalToFixed: true,
                onChange: (pVal) => {

                    let aZrAvg = ((this.zR_x_0.actualValue + this.zR_y_0.actualValue) / 2);
                    let aDz = (pVal * aZrAvg);
                    this.z_x_0.actualValue = (this.z_0_r.actualValue + aDz);
                    this.z_y_0.actualValue = (this.z_0_r.actualValue - aDz);

                    let aWavelength = this.wavelength;
                    this.omega_x_0.actualValue = Math.sqrt((aWavelength * this.zR_x_0.actualValue) /
                        (Math.PI * this.n));
                    this.omega_y_0.actualValue = Math.sqrt((aWavelength * this.zR_y_0.actualValue) /
                        (Math.PI * this.n));
                    this._onChange();
                },
                decimalPrecision: 10
            });
        this.assymetry = new NIE(Op3dUtils.getElementIn(this.mContainer, 'assymetry'),
            {
                label: { text: 'Assymetry', justify: 'start', bold: false },
                isGlobalToFixed: true,
                onChange: (pVal) => {

                    this.omega_x_0.actualValue = (this.omega_0_r.actualValue * (1 + pVal));
                    this.omega_y_0.actualValue = (this.omega_0_r.actualValue * (1 - pVal));

                    this._onChange();
                },
                decimalPrecision: 10
            });
        this.omega_0_r = new NIE(Op3dUtils.getElementIn(this.mContainer, 'reference_waist'),
            {
                label: { text: 'Reference waist', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                isGlobalToFixed: true,
                onChange: (pVal) => {

                    let aDeltaOmega = (pVal * this.assymetry.actualValue);
                    this.omega_x_0.actualValue = ((this.omega_0_r.actualValue + aDeltaOmega) / 2);
                    this.omega_y_0.actualValue = ((this.omega_0_r.actualValue - aDeltaOmega) / 2);

                    this._onChange();
                },
                decimalPrecision: 5
            });
        this.z_0_r = new NIE(Op3dUtils.getElementIn(this.mContainer, 'reference_waist_position'),
            {
                label: { text: 'Reference waist position', justify: 'start', bold: false },
                unit: UnitHandler.shortSign,
                isGlobalToFixed: true,
                decimalPrecision: 5
            });

        this.mPropogationTypeSelec = new CustomSelect(this._getPart('propogation_type_select'),
            {
                labelOptions: {
                    bold: false,
                    justify: "start",
                    label: 'Intensity colors count',
                },
                class: ["forms-custom-select", "flex_100", "w-auto"],
                staticPostion: true
            }
        );

        this.mPropogationTypeSelec.setOptions([{
            value: ePropogationType.RAY_TRACING,
            enable: false
        },
        {
            value: ePropogationType.BEAM_PROPOGATION,
            enable: true
        },
        {
            value: ePropogationType.IMPROVED_RAY_TRACING,
            enable: false
        }
        ], ePropogationType.BEAM_PROPOGATION, false);

        this.n = 1;

        this._initDiagrams();
    }
    //______________________________________________________________________________________________
    private async _initDiagrams() {
        let aAzimuthalAngleButton = this._getPart('azimuthal_angle_graph');
        let aAstigmatismAsymmetryButton = this._getPart('astigmatism_assymetry_graph');

        let aAzimuthalAngleDiagram = await new FloatingImage().set({
            imageAsElement: this._getPart('azimuthal_angle_image'),
            title: 'Azimuthal angle'
        });
        let aAstigmatismAsymmetryDiagram = await new FloatingImage().set({
            imageAsElement: this._getPart('astigmatism_assymetry_image'),
            title: 'Astigmatism - asymmetry'
        });

        aAzimuthalAngleButton.addEventListener('click',
            () => {
                let aLeft = (this.mContainer.getBoundingClientRect().left - 400);
                let aTop = (aAzimuthalAngleButton.getBoundingClientRect().top - 300);
                aAzimuthalAngleDiagram.open({ elementPos: { left: aLeft, top: aTop } });
            });
        aAstigmatismAsymmetryButton.addEventListener('click',
            () => {
                let aLeft = (this.mContainer.getBoundingClientRect().left - 400);
                let aTop = (aAstigmatismAsymmetryButton.getBoundingClientRect().top - 150);
                aAstigmatismAsymmetryDiagram.open({ elementPos: { left: aLeft, top: aTop } });
            });
    }
    //______________________________________________________________________________________________
    private _onChange() {
        this._calc();
        this.mCallback();
    }
    //______________________________________________________________________________________________
    private _calc() {
        let wavelength = this.wavelength;
        let n = this.n;
        let pi = Math.PI;

        let aWaistY = this.omega_y_0.actualValue;
        let aWaistX = this.omega_x_0.actualValue;

        this.zR_x_0.actualValue = (((pi * n) * (aWaistX * aWaistX)) / wavelength);
        this.zR_y_0.actualValue = (((pi * n) * (aWaistY * aWaistY)) / wavelength);

        this.half_angle_x.actualValue = (((wavelength) / (pi * n * aWaistX)));
        this.half_angle_y.actualValue = (((wavelength) / (pi * n * aWaistY)));

        this.omega_0_r.actualValue = ((aWaistX + aWaistY) / 2);

        let zR_ref = ((this.zR_x_0.actualValue + this.zR_y_0.actualValue) / 2);
        let aDzPos = ((this.z_x_0.actualValue - this.z_y_0.actualValue) / 2);
        this.astigmatism.actualValue = (aDzPos / zR_ref);
        this.assymetry.actualValue = ((aWaistX - aWaistY) / (aWaistX + aWaistY));
        this.z_0_r.actualValue = ((this.z_x_0.actualValue + this.z_y_0.actualValue) / 2);
    }
    //______________________________________________________________________________________________
    public fillSection(pData: iGaussianBeamSection) {
        this._fillSection(pData);
    }
    //______________________________________________________________________________________________
    protected _fillSection(pData: iGaussianBeamSection): void {
        let aPrimaryWavelength = pData.part.getBehavior('laserBehavior').getPrimaryWavelength();
        this.wavelength = (aPrimaryWavelength * OP3DMathUtils.NANO_TO_MM);

        let aBeam = pData.beam;
        this.z_x_0.actualValue = aBeam.q_x.re;
        this.z_y_0.actualValue = aBeam.q_y.re;
        this.zR_x_0.actualValue = aBeam.q_x.im;
        this.zR_y_0.actualValue = aBeam.q_y.im;

        let aScale = UnitHandler.presentedScale;
        let aPi = Math.PI;
        let aMinOmega = (((2 * this.wavelength) / (aPi * aPi * this.n)) * aScale);
        let aMaxOmega = (75 * aScale);
        this.omega_x_0.updateRange({ min: aMinOmega, max: aMaxOmega });
        this.omega_y_0.updateRange({ min: aMinOmega, max: aMaxOmega });

        let aMinZ_R = (((aPi * (aMinOmega * aMinOmega) * this.n) / this.wavelength) * aScale);
        let aMaxZ_R = (((aPi * (aMaxOmega * aMaxOmega) * this.n) / this.wavelength) * aScale);
        this.zR_x_0.updateRange({ min: aMinZ_R, max: aMaxZ_R });
        this.zR_y_0.updateRange({ min: aMinZ_R, max: aMaxZ_R });

        this.omega_x_0.actualValue =
            Math.sqrt((this.zR_x_0.actualValue * this.wavelength) / Math.PI);
        this.omega_y_0.actualValue =
            Math.sqrt((this.zR_y_0.actualValue * this.wavelength) / Math.PI);

        let aMinAngle = ((this.wavelength / (aPi * this.n * aMaxOmega)) * MathContext.RAD_TO_DEG);
        this.half_angle_x.updateRange({ min: aMinAngle, max: (Math.PI / 2) });
        this.half_angle_y.updateRange({ min: aMinAngle, max: (Math.PI / 2) });

        this._calc();
    }
    //______________________________________________________________________________________________
    public getData(): iGaussianBeam {
        let aBeam: iGaussianBeam = {
            q_x: new ComplexNumber({
                re: this.z_x_0.actualValue,
                im: this.zR_x_0.actualValue
            }),
            q_y: new ComplexNumber({
                re: this.z_y_0.actualValue,
                im: this.zR_y_0.actualValue
            })
        };

        return aBeam;
    }
    //______________________________________________________________________________________________
    public static getWaist(q: ComplexNumber, pWavelengthMM: number) {
        let zR = q.im;
        let waist = Math.sqrt((zR * pWavelengthMM) / Math.PI);

        return (waist);
    }
    //______________________________________________________________________________________________
    // private _updateRanges() {

    // }
    //______________________________________________________________________________________________
}
