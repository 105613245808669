﻿import { EventManager } from "../../../../../oc/events/EventManager";
import { eDataPermission, eScatteringModel, eScatteringType } from "../../../../_context/Enums";
import { EventsContext } from "../../../../_context/EventsContext";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { iHash, iOP3DHTMLInputElement, iScatteringBSDFParams, iScatteringVO } from "../../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../../_utils/DataUtils";
import { OP3DMathUtils } from "../../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { Part } from "../../../../parts/Part";
import { iFace } from "../../../../parts/PartInterfaces";
import { OpticsFactory } from "../../../../parts/optics/OpticsFactory";
import { SettingsContext } from "../../../../settings/SettingsContext";
import { ViewUtils } from "../../../ViewUtils";
import { iMaterialParam } from "../uoMaterial";
import { uoSection } from "../uoSection";

export class uoScatteringInfoNew extends uoSection<{
    opticsVO: iOpticsVO,
    originalName: string,
    face: iFace,
    part: Part
}> {

    private static DEFAULT_BSDF: iScatteringBSDFParams = {
        sigmaX: 10,
        sigmaY: 10,
        azimuth_theta: 0,
        a: 1,
        b: 2,
        g: 2,
        n: 1.5
    };

    private static DEFAULT_GAUSSIAN_SCATTERING: iScatteringVO = {
        name: 'Scattering model',
        number_id: null,
        parameters: {
            scatteringType: eScatteringType.SIMPLE,
            scatterModel: eScatteringModel.GAUSSIAN,
            bsdfParams: {
                sigmaX: 10,
                sigmaY: 10,
                azimuth_theta: 0,
                a: 1,
                b: 2,
                g: 2,
                n: 1.5
            },
            numberOfRays: 10,
            relativePowerThreshold: 0.00001,
            transmittance: 1,
            reflectance: 0,
            absorption: 0
        },
        permission: eDataPermission.PUBLIC
    };

    private static _GAUSSIAN_BSDF_PARAMS: Array<iMaterialParam> = [
        {
            name: 'σ<sub>x</sub>',
            path: 'sigmaX',
            range: {
                max: 90,
                min: 0
            },
            value: 10,
            unit: 'Deg'
        },
        {
            name: 'σ<sub>y</sub>',
            path: 'sigmaY',
            range: {
                max: 90,
                min: 0
            },
            value: 10,
            unit: 'Deg'
        },
        {
            name: 'Azimuth θ',
            path: 'azimuth_theta',
            range: {
                max: 90,
                min: -90
            },
            value: 0,
            unit: 'Deg'
        }
    ];

    private static _ABG_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            range: {
                min: 0,
                max: Infinity
            },
            path: 'a',
            value: 1
        },
        {
            name: 'B',
            path: 'b',
            value: 1,
            unit: 'si',
            rangeFunc: (pVal, pScatteringParams) => {
                let aMin = (0 == pScatteringParams.g) ? 0 : 1e-12;
                let aMax = Infinity;

                return OP3DMathUtils.clampValue(pVal, aMin, aMax);
            }
        } as iMaterialParam<iScatteringBSDFParams>,
        {
            name: 'g',
            path: 'g',
            value: 1
        }];

    private static _COS_NTH_PARAMS: Array<iMaterialParam> = [
        {
            name: 'N',
            path: 'n',
            value: 1.5
        }
    ];

    private mOneParamElement: HTMLElement;
    private mBSDFParamsParent: HTMLElement;

    private mHasScattaringCheckbox: HTMLInputElement;
    private mNumOfScatteredRays: iOP3DHTMLInputElement;
    private mRelThreshold: iOP3DHTMLInputElement;
    private mTransmittance: iOP3DHTMLInputElement;
    private mReflectance: iOP3DHTMLInputElement;
    private mAbsorption: iOP3DHTMLInputElement;

    private mScattringSettings: HTMLElement;
    private mScatterModel: HTMLSelectElement;

    private mScatteringsVO: iHash<{ hasScattering: boolean, scatteringVO: iScatteringVO }> = {};
    private mPrevPartInternalID: string = null;
    private mCurrFaceName: string = null;


    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: './skins/forms/optics/uo_scattering_info_new.html',
            title: 'Scattering Info',
            collapseQaId: "uo_face_scattering_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: true
        });
    }
    //__________________________________________________________________________________________
    private _updateEnergyDistribution() {
        const aParams = this.mScatteringsVO[this.mCurrFaceName].scatteringVO.parameters;
        aParams.transmittance = (0.01 * parseFloat(this.mTransmittance.value));
        aParams.reflectance = (0.01 * parseFloat(this.mReflectance.value));
        aParams.absorption = (0.01 * parseFloat(this.mAbsorption.value));

        // this._updateFaceScatteringVO();
        this._onChange();
    }
    //__________________________________________________________________________________________
    private _getToFixedVal(pVal: number) {

        let aToFixed: number;

        try {
            aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        } catch (e) {
            aToFixed = SettingsContext.DEFAULT_USER_VO.parameters.simulation.numericAccuracy;
        }

        return OP3DMathUtils.toFixed(pVal, aToFixed)
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param', true);
        this.mBSDFParamsParent = this.mOneParamElement.parentElement;

        this.mTransmittance = this._getPart('transmittance') as iOP3DHTMLInputElement;
        this.mReflectance = this._getPart('reflectance') as iOP3DHTMLInputElement;
        this.mAbsorption = this._getPart('absorption') as iOP3DHTMLInputElement;

        this.mTransmittance.addEventListener('change', () => {
            let aTransmittance = parseFloat(this.mTransmittance.value);
            if (true == isNaN(aTransmittance)) {
                this.mTransmittance.value = this._getToFixedVal(parseFloat(this.mTransmittance.prevValue));
                return;
            }

            aTransmittance = OP3DMathUtils.clampValue(aTransmittance, 0, 100);
            this.mTransmittance.value = this._getToFixedVal(aTransmittance);
            this.mTransmittance.prevValue = this.mTransmittance.value;
            let aReflectance = parseFloat(this.mReflectance.value);

            let aTransPlusRef = (aTransmittance + aReflectance);
            if (100 == aTransPlusRef) {
                this.mAbsorption.value = '0';
            } else if (aTransPlusRef < 100) {
                this.mAbsorption.value = (100 - aTransPlusRef).toString();
            } else {
                this.mAbsorption.value = '0';
                this.mReflectance.value = this._getToFixedVal(100 - aTransmittance);
                this.mReflectance.prevValue = this.mReflectance.value;
            }

            this._updateEnergyDistribution();
        });

        this.mReflectance.addEventListener('change', () => {
            let aReflectance = parseFloat(this.mReflectance.value);
            if (true == isNaN(aReflectance)) {
                this.mReflectance.value = this._getToFixedVal(parseFloat(this.mReflectance.prevValue));
                return;
            }

            aReflectance = OP3DMathUtils.clampValue(aReflectance, 0, 100);
            this.mReflectance.value = this._getToFixedVal(aReflectance);
            this.mReflectance.prevValue = this.mReflectance.value;
            let aTransmittance = parseFloat(this.mTransmittance.value);

            let aTransPlusRef = (aTransmittance + aReflectance);
            if (100 == aTransPlusRef) {
                this.mAbsorption.value = '0';
            } else if (aTransPlusRef < 100) {
                this.mAbsorption.value = this._getToFixedVal((100 - aTransPlusRef));
            } else {
                this.mAbsorption.value = '0';
                this.mTransmittance.value = this._getToFixedVal((100 - aReflectance));
                this.mTransmittance.prevValue = this.mTransmittance.value;
            }

            this._updateEnergyDistribution();
        });

        this.mRelThreshold = this._getPart('relative_power_treshold') as iOP3DHTMLInputElement;
        this.mRelThreshold.addEventListener('change', () => {
            let aRelTreshold = parseFloat(this.mRelThreshold.value);
            if (true == isNaN(aRelTreshold)) {
                this.mRelThreshold.value = this._getToFixedVal(parseFloat(this.mRelThreshold.prevValue));
                return;
            }

            aRelTreshold = OP3DMathUtils.clampValue(aRelTreshold, 0.00001, 1);
            this.mRelThreshold.value = this._getToFixedVal(aRelTreshold);
            this.mRelThreshold.prevValue = this.mRelThreshold.value;

            this.mScatteringsVO[this.mCurrFaceName].scatteringVO.parameters.relativePowerThreshold =
                parseFloat(this.mRelThreshold.value);

            // this._updateFaceScatteringVO();
            this._onChange();
        });

        this.mNumOfScatteredRays = this._getPart('num_of_scattered_rays') as iOP3DHTMLInputElement;
        this.mNumOfScatteredRays.addEventListener('change', () => {
            let aValue = parseInt(this.mNumOfScatteredRays.value);
            if (true == isNaN(aValue)) {
                this.mNumOfScatteredRays.value = this._getToFixedVal(parseFloat(this.mNumOfScatteredRays.prevValue));
                return;
            }

            aValue = OP3DMathUtils.clampValue(aValue, 1, 100);

            this.mNumOfScatteredRays.value = this._getToFixedVal(aValue);
            this.mNumOfScatteredRays.prevValue = this.mNumOfScatteredRays.value;

            this.mScatteringsVO[this.mCurrFaceName].scatteringVO.parameters.numberOfRays = aValue;
            // this._updateFaceScatteringVO();
            this._onChange();
        });

        this.mScatterModel = this._getPart('scattering_type') as HTMLSelectElement;
        this.mScatterModel.addEventListener('change', () => this._onChangeScatteringType());

        this.mHasScattaringCheckbox = this._getPart('use_scattring', true) as HTMLInputElement;
        let aLabel = this._getPart('use_scattring_label', true) as HTMLLabelElement;
        aLabel.htmlFor = this.mHasScattaringCheckbox.id;
        this.mHasScattaringCheckbox.addEventListener('change', () => this._onSetScattering(true));

        this.mScattringSettings = this._getPart('scattering_settings');
        // let aLinkToPricing = this._getPart('try-professional-scattering', true) as HTMLAnchorElement;
        // aLinkToPricing.href = ServerContext.pricing_base_link;
    }
    //__________________________________________________________________________________________
    private _onChangeScatteringType(pBSDF: iScatteringBSDFParams = uoScatteringInfoNew.DEFAULT_BSDF,
        pToUpdate: boolean = true) {
        ViewUtils.removeElementChildren(this.mBSDFParamsParent);

        let aScatteredModel = this.mScatterModel.value as eScatteringModel;
        switch (aScatteredModel) {
            case eScatteringModel.GAUSSIAN:
                this._createParams(uoScatteringInfoNew._GAUSSIAN_BSDF_PARAMS, pBSDF);
                break;
            case eScatteringModel.LAMBERTIAN:
                this._createParams([], pBSDF);
                break;
            case eScatteringModel.ABG:
                this._createParams(uoScatteringInfoNew._ABG_PARAMS, pBSDF);
                break;
            case eScatteringModel.COS_NTH:
                this._createParams(uoScatteringInfoNew._COS_NTH_PARAMS, pBSDF);
                break;
            default:
                break;
        }

        if (true == pToUpdate) {
            this.mScatteringsVO[this.mCurrFaceName].scatteringVO.parameters.scatterModel = aScatteredModel;
            this._onChange();
        }
    }
    //__________________________________________________________________________________________
    private _onChange() {
        EventManager.dispatchEvent(EventsContext.SCATTERING_SECTION_DATA_CHANGE, this);
    }
    //__________________________________________________________________________________________
    private _onSetScattering(pToUpdate: boolean) {
        let aIsEnable = this.mHasScattaringCheckbox.checked;
        this.mScatteringsVO[this.mCurrFaceName].hasScattering = aIsEnable;
        ViewUtils.setElementVisibilityByDNone(this.mScattringSettings, aIsEnable);

        if (pToUpdate == true) {
            this._onChange();
        }
    }
    //__________________________________________________________________________________________
    protected async _setData(pData: { originalName: string, part: Part, opticsVO: iOpticsVO }) {

        if (pData == null) {
            this.show(false);
            return;
        }

        if (pData.part !== undefined && this.mPrevPartInternalID != pData.part.internalID) {
            this.clear();
            this.mPrevPartInternalID = pData.part.internalID;
        }

        this.mCurrFaceName = pData.originalName;
        let aScatteringVO: iScatteringVO;
        let aHasScattering: boolean;
        let aPrevData = this.mScatteringsVO[this.mCurrFaceName] != null ? this.mScatteringsVO[this.mCurrFaceName] : null;
        if (aPrevData != null) {
            aScatteringVO = aPrevData.scatteringVO;
            aHasScattering = aPrevData.hasScattering;
        } else {
            let aFaces: Array<iFace>;
            if (pData.part !== undefined) {
                const aOptics = pData.part.returnOpticsFromPart()
                const aPart = aOptics || pData.part
                aFaces = aPart.getFaces();

            } else {
                let aIPart = OpticsFactory.createOpticalDevice(pData.opticsVO);
                aFaces = aIPart.shapes[0].solids[0].faces;
            }

            const aCurrFace = aFaces.find(face => face.originalName == pData.originalName);
            aHasScattering = aCurrFace.data.scattringVO != null;
            aScatteringVO = aHasScattering ?
                DataUtils.getObjectCopy(aCurrFace.data.scattringVO) :
                DataUtils.getObjectCopy(uoScatteringInfoNew.DEFAULT_GAUSSIAN_SCATTERING);
            this.mScatteringsVO[this.mCurrFaceName] = { scatteringVO: aScatteringVO, hasScattering: aHasScattering };
        }

        this.show(true);
        this._fillData(aScatteringVO, aHasScattering);
    }
    //__________________________________________________________________________________________
    private _fillData(pScattetingVO: iScatteringVO, pIsEnabled: boolean) {
        this.mHasScattaringCheckbox.checked = pIsEnabled;
        this._onSetScattering(false);

        this.mScatterModel.value = pScattetingVO.parameters.scatterModel.toString();
        if (null != pScattetingVO.parameters.numberOfRays) {
            this.mNumOfScatteredRays.value = pScattetingVO.parameters.numberOfRays.toString();
        }

        this.mRelThreshold.value = (null != pScattetingVO.parameters.relativePowerThreshold) ?
            pScattetingVO.parameters.relativePowerThreshold.toString() : '0.00001';

        this.mTransmittance.value = this._getToFixedVal((100 * pScattetingVO.parameters.transmittance));
        this.mTransmittance.prevValue = this.mTransmittance.value;
        this.mReflectance.value = this._getToFixedVal((100 * pScattetingVO.parameters.reflectance));
        this.mReflectance.prevValue = this.mReflectance.value;
        this.mAbsorption.value = this._getToFixedVal((100 * pScattetingVO.parameters.absorption));

        this._onChangeScatteringType(pScattetingVO.parameters.bsdfParams, false);
        this.mScatteringsVO[this.mCurrFaceName] = { scatteringVO: pScattetingVO, hasScattering: pIsEnabled };
    }
    //__________________________________________________________________________________________
    private _createParams(pData: Array<iMaterialParam>, pBSDF: iScatteringBSDFParams) {
        for (let i = 0; i < pData.length; i++) {
            let aData = pData[i];

            let aName = aData.name;
            let aUnit = aData.unit;
            let aPath = aData.path;

            let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
            this.mBSDFParamsParent.appendChild(aOneParam);

            Op3dUtils.getElementIn(aOneParam, 'param_name').innerHTML = aName;

            let aUnitElement = Op3dUtils.getElementIn(aOneParam, 'unit_span');
            if (null != aUnit) {
                aUnitElement.innerHTML = aUnit;
            } else {
                ViewUtils.removeFromParent(aUnitElement.parentElement);
            }

            let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as iOP3DHTMLInputElement;

            aInput.addEventListener('change', () => {
                let aValue = parseFloat(aInput.value);
                if (null != aData.range) {
                    aValue = OP3DMathUtils.clampValue(aValue, aData.range.min, aData.range.max);
                    aInput.value = this._getToFixedVal(aValue);
                    aInput.prevValue = aInput.value;

                }
                if (null != aData.rangeFunc) {
                    aValue = aData.rangeFunc(aValue, this.mScatteringsVO[this.mCurrFaceName].scatteringVO.parameters.bsdfParams);
                    if (null == aValue) {
                        aInput.value = this._getToFixedVal(parseFloat(aInput.prevValue));
                        return;
                    }

                    aInput.value = this._getToFixedVal(aValue);
                    aInput.prevValue = aInput.value;
                }
                this.mScatteringsVO[this.mCurrFaceName].scatteringVO.parameters.bsdfParams[aPath] = aValue;

                this._onChange();
            });

            aInput.value = this._getToFixedVal(pBSDF[aPath]);
            aInput.prevValue = aInput.value;
        }
    }
    //__________________________________________________________________________________________
    public fillSurfaceData(pData: iHash<iScatteringVO>) {
        for (let face in this.mScatteringsVO) {
            if (this.mScatteringsVO[face].hasScattering) {
                pData[face] = this.mScatteringsVO[face].scatteringVO;
            }
        }
    }
    //__________________________________________________________________________________________
    public clear() {
        this.collapse();
        this.mCurrFaceName = null;
        this.mPrevPartInternalID = null;
        this.mScatteringsVO = {};
    }
    //__________________________________________________________________________________________
}
