import { EventManager } from "../../oc/events/EventManager";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { SnapshotTools } from "../_utils/SnapshotTools";
import { ServerContext } from "../server/ServerContext";
import { SimulationRunner } from "../simulation/SimulationRunner";

export class SceneSnapshotGenerator {

    constructor() {

        EventManager.addEventListener(EventsContext.ON_NEW, () => this.onNew(), this);
        EventManager.addEventListener(EventsContext.ON_FINISH_SIMULATION, () => this._onFinishSimulation(), this);
    }

    private mSceneHasRays: boolean = false;
    private mPartsCount: number;
    private mSavedWithRays = false;

    //______________________________________________________________________________________________
    private _init() {
        this.mSceneHasRays = false;
        this.mSavedWithRays = false;
        this.mPartsCount = null;
        //   this.mImageHasRays = false;
    }

    //______________________________________________________________________________________________
    public async onNew() {
        this._init();

        if (Op3dContext.SETUPS_MANAGER.currSetupID != null) {
            let aRes = await ServerContext.SERVER.isRaysFileExists({ _id: Op3dContext.SETUPS_MANAGER.currSetupID })
            if (aRes.success == true) {
                this.mSavedWithRays = aRes.data;
            } else {
                this.mSavedWithRays = false
            }
        } else {
            this.mSavedWithRays = false
        }

    }
    //______________________________________________________________________________________________
    private _onFinishSimulation() {
        this.mSceneHasRays = SimulationRunner.instance.isRaysShown();
        this.mSavedWithRays = false;
        Op3dContext.SETUPS_MANAGER.saveOpt({ showMessage: false })
    }
    //______________________________________________________________________________________________
    public getSceneSnapshot() {
        /**
         * if the scene has rays than we will not override this image by an image that has no rays
         * even though the setup has changed
         * 
         */

        if (this.mSavedWithRays == false) {

            if (this.mSceneHasRays) {
                this.mSavedWithRays = true;
                // image with rays
                let aImage = SnapshotTools.getSceneSnapshot2(false);
                return aImage;

            } else {
                // image with no rays
                // we will save only if parts have been added or the same
                if (this.mPartsCount == null || this.mPartsCount <= Op3dContext.PARTS_MANAGER.parts.length) {
                    this.mPartsCount = Op3dContext.PARTS_MANAGER.parts.length;
                    let aImage = SnapshotTools.getSceneSnapshot2(false);
                    return aImage;
                } else {
                    // it means that the count of items is smaller
                    // do nothing
                }
            }

        } else {
            // do nothing
        }
    }
    //________________________________________________________________________________________________
}

