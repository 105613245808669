import { Colors } from "../../_context/Colors";
import { Op3dContext } from "../../_context/Op3dContext";
import { SceneContext } from "../../scene/SceneContext";
import { Part } from "../Part";
import { iPart, iAxis, iShape, iSolid, iFace, ePartType } from "../PartInterfaces";


export enum eHighlightMode {
    HIGHLIGHTED_REGULAR,
    UNHIGHLIGHTED_REGULAR,
    HIGHLIGHTED_MEASURES,
    UNHIGHLIGHTED_MEASURES
}

export class OptixPartDisplayer {

    //__________________________________________________________________________________________
    public static highlightGroup(pPart: Part) {
        if ((null == pPart.id) || (ePartType.DYNAMIC_PART === pPart.partOptions.type)) {
            this.highlightPartOLDGroup(pPart.iPart);
        } else {
            this.highlightPartInGroup(pPart.iPart);
        }

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public static highlightObject(pPart: Part) {
        if ((null == pPart.id) || (ePartType.DYNAMIC_PART === pPart.partOptions.type) ||
            (ePartType.PARAXIAL_LENS === pPart.partOptions.type)) {
            this.highlightPartOLD(pPart.iPart);
        } else {
            this.highlightPart(pPart.iPart);
        }

        if ((null != pPart.workingLCS) && (ePartType.GROUP !== pPart.partOptions.type)) {
            pPart.workingLCS.object3D.show();
            pPart.workingLCS.object3D.higlight();
        }


    }
    //__________________________________________________________________________________________
    public static highlightPartOLD(pPart: iPart) {
        if ((null != pPart.data) && (false == pPart.data.toHighlight)) {
            return;
        }

        if (null != pPart.shapes) {
            for (let i = 0; i < pPart.shapes.length; i++) {
                this.highlightShape(pPart.shapes[i]);
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this.highlightPartOLD(pPart.subParts[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    public static highlightPartOLDGroup(pPart: iPart) {
        if ((null != pPart.data) && (false == pPart.data.toHighlight)) {
            return;
        }

        if (null != pPart.shapes) {
            for (let i = 0; i < pPart.shapes.length; i++) {
                this.highlightShape(pPart.shapes[i], true);
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this.highlightPartOLDGroup(pPart.subParts[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    public static highlightPart(pPart: iPart) {
        if ((null != pPart.data) && (false == pPart.data.toHighlight)) {
            return;
        }

        if (null != pPart.facesMesh) {
            if ((pPart.facesMesh.material as any).opacity !== Op3dContext.UNMUTABLE_MESH_MATERIAL_OPACITY) {
                pPart.facesMesh.material = Op3dContext.GLOBAL_MATERIAL_HIGHLIGHTED;
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this.highlightPart(pPart.subParts[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    public static highlightPartInGroup(pPart: iPart) {
        if ((null != pPart.data) && (false == pPart.data.toHighlight)) {
            return;
        }

        // this._hideAxes(pPart.axes)

        if (null != pPart.facesMesh) {

            pPart.facesMesh.material = Op3dContext.GLOBAL_MATERIAL_HIGHLIGHTED_GROUP;
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this.highlightPartInGroup(pPart.subParts[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    private static _hideAxes(pAxes: Array<iAxis>) {
        if (null == pAxes) {
            return;
        }

        for (let i = 0; i < pAxes.length; i++) {
            pAxes[i].object3D.hide();
            pAxes[i].object3D.unHiglight();
        }
    }
    //__________________________________________________________________________________________
    public static unHighlightObject(pPart: Part) {

        if (null != pPart.workingLCS) {
            pPart.workingLCS.object3D.hide();
            pPart.workingLCS.object3D.unHiglight();
        }

        if ((null == pPart.id) || (ePartType.DYNAMIC_PART === pPart.partOptions.type) ||
            (ePartType.PARAXIAL_LENS === pPart.partOptions.type)) {
            this.unhighlightPartOLD(pPart.iPart);
        } else {
            this.unhighlightPart(pPart.iPart);
        }

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public static unhighlightPart(pPart: iPart) {
        this._hideAxes(pPart.axes);
        if (null != pPart.facesMesh) {
            // 0.2555 special value (it's hard to repeat manually) to not to change a material automatically
            if ((pPart.facesMesh.material as any).opacity !== Op3dContext.UNMUTABLE_MESH_MATERIAL_OPACITY) {
                pPart.facesMesh.material = Op3dContext.GLOBAL_MATERIAL;
            }
        }

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this.unhighlightPart(pPart.subParts[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    public static unhighlightPartOLD(pPart: iPart) {
        this._unhighlightShapes(pPart.shapes);
        this._hideAxes(pPart.axes);

        if (null != pPart.subParts) {
            for (let i = 0; i < pPart.subParts.length; i++) {
                this.unhighlightPartOLD(pPart.subParts[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    private static _unhighlightShapes(pShapes: Array<iShape>) {
        if (null == pShapes) {
            return;
        }

        for (let i = 0; i < pShapes.length; i++) {
            this.unhighlightSolids(pShapes[i].solids);
            this._hideAxes(pShapes[i].axes);
        }
    }
    __________________________________________________________________________________________
    public static unhighlightSolid(pSolid: iSolid) {
        this._unhighlightFaces(pSolid.faces);
        this._hideAxes(pSolid.axes);
    }
    //__________________________________________________________________________________________
    public static unhighlightSolids(pSolids: Array<iSolid>) {
        if (null == pSolids) {
            return;
        }

        for (let i = 0; i < pSolids.length; i++) {
            this._unhighlightFaces(pSolids[i].faces);
            this._hideAxes(pSolids[i].axes);
        }
    }
    //__________________________________________________________________________________________
    private static _unhighlightFaces(pFaces: Array<iFace>) {
        if (null == pFaces) {
            return;
        }

        for (let i = 0; i < pFaces.length; i++) {
            this.unhighlightFace(pFaces[i]);
        }
    }
    //__________________________________________________________________________________________
    public static highlightShape(pShape: iShape, pIsGroup?: boolean) {
        let aSolids = pShape.solids;
        for (let i = 0; i < aSolids.length; i++) {
            this.highlightSolid(aSolids[i], pIsGroup);
        }
    }
    //__________________________________________________________________________________________
    public static highlightSolid(pSolid: iSolid, pIsGroup?: boolean) {
        let aFaces = pSolid.faces;
        for (let i = 0; i < aFaces.length; i++) {
            this.highlightFace(aFaces[i], pIsGroup);
        }
    }
    //__________________________________________________________________________________________
    public static highlightFace(pFace: iFace, pIsGroup?: boolean) {
        if (null == (pFace.visualization.mesh.material as any).emissive) {
            return;
        }
        let aColor = Colors.HIGHLIGHT_COLOR
        if (pIsGroup == true) {
            aColor = 0xeb9834
        }
        (pFace.visualization.mesh.material as any).emissive.setHex(aColor);
    }
    //__________________________________________________________________________________________
    public static unhighlightFace(pFace: iFace) {
        this._hideAxes(pFace.axes);

        if (null == (pFace.visualization.mesh.material as any).emissive) {
            return;
        }

        let aColor = pFace.visualization.emissive.getHex();
        (pFace.visualization.mesh.material as any).emissive.setHex(aColor);
    }
    //__________________________________________________________________________________________
}
