import { useState } from "react";
import OptiChatNavbarOption from "./OptiChatNavbarOption";
import classes from "../../UI/optichat-info.module.scss";
import { INFO_DATA } from "./OptiChatInfoData";

export default function OptiChatNavbar(props: {
  onChangeOption: (option: string) => void;
}) {
  const [activeIdx, setActivIdx] = useState(0);

  const onChangeOptionHandler = (option, idx) => {
    props.onChangeOption(option);
    setActivIdx(idx);
  };
  return (
    <div className={classes.navbar}>
      {INFO_DATA.map((item, idx) => (
        <OptiChatNavbarOption
          key={idx}
          idx={idx}
          title={item.title}
          active={idx === activeIdx}
          onChangeOption={onChangeOptionHandler}
        />
      ))}
    </div>
  );
}
