import { eUnitType } from "../../../../_context/Enums";
import { Strings } from "../../../../_context/Strings";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { Op3dComponentBase } from "../../../Op3dComponentBase";
import { ViewUtils } from "../../../ViewUtils";
import { iOpticsFilterComp, FilterDataType, iOpticsFilterParams } from "../MenusContext";

export abstract class Filter extends Op3dComponentBase {

    protected mOnChange: Function;
    protected mOnClose: Function;
    private mTitleDiv: HTMLElement;
    private mCollapseTarget: HTMLElement;
    protected mTitle: string = '';
    protected mIsCanBeNone: boolean = true;
    protected mInfoBtn: HTMLElement;
    protected mSendDataOnEdges: boolean;
    protected mFilterComp: iOpticsFilterComp<FilterDataType>;


    constructor(pSkinPath: string,
        pOpticsFilterComp: iOpticsFilterComp) {
        super({
            skinPath: pSkinPath,
            container: pOpticsFilterComp.container
        });

        this.mFilterComp = pOpticsFilterComp;

        // this.updateFilter(pOpticsFilterComp, false);
        // this.updateCloseBtn(pOpticsFilterComp.params.showCloseBtn/* ,
        //    pOpticsFilterComp.params.onClose*/);
    }
    //_______________________________________________________
    public distract() {
        // no need to impl distract
    }
    //_______________________________________________________
    protected _onCreationComplete(): void {

        this.init();
        this.addEventListeners();
        this.updateFilter(this.mFilterComp, false);

        this.updateCloseBtn(this.mFilterComp.params.showCloseBtn
                /*,pOpticsFilterComp.params.onClose*/);
        //this.mIsReady = true;
    }
    //_______________________________________________________
    protected init() {
        this.mTitleDiv = this._getPart('comp_title') as HTMLElement;

        this.mCollapseTarget = this._getPart('collapse_target', true) as HTMLElement;
        if (null != this.mCollapseTarget) {
            let aCollapseDiv = this._getPart('collapse_div') as HTMLElement;
            aCollapseDiv.setAttribute('href', '#' + this.mCollapseTarget.id);
        }

        this.mInfoBtn = this._getPart('info-optics-icon', true);
    }
    //_______________________________________________________
    private _onClickResetBtn(e: Event) {
        e.stopPropagation();
        e.preventDefault();

        this.reset(true);
    }
    //_______________________________________________________
    protected addEventListeners() {
        let aResetBtn = this._getPart('reset_filter_btn');
        if (null !== aResetBtn && undefined !== aResetBtn) {
            if (this.mFilterComp.params.hideResetBtn == true) {
                ViewUtils.setElementVisibilityByDNone(aResetBtn, false)

            } else {
                aResetBtn.addEventListener('click', (e: Event) => this._onClickResetBtn(e));
            }
        }
    }
    //_______________________________________________________
    public exportToJson() {
        return null;
    }
    //_______________________________________________________
    public initFromJson(_pData: any, _unit?: eUnitType) {

    }
    //_______________________________________________________
    public updateUnit(_pUnit: eUnitType) { }
    //_______________________________________________________
    protected async updateCloseBtn(pToShow: boolean = true, pOnClose?: Function) {
        this.mOnClose = pOnClose;
        let aCloseBtn = this._getPart('filters_close_btns', true);
        if (pToShow && pOnClose != null) {
            aCloseBtn.addEventListener('click', () => this.onCloseFilter());
        } else {
            ViewUtils.removeFromParent(aCloseBtn);
        }
    }
    //_______________________________________________________
    private onCloseFilter() {
        if (this.mOnClose != null) {
            this.mOnClose(this);
        }

        this.reset(true);
    }
    //_______________________________________________________
    protected _updateTitle(pTitle: string) {
        this.mTitle = pTitle;
        this.mTitleDiv.innerHTML = this.mTitle;
    }
    //_______________________________________________________
    protected _updateChangeCallback(pChangeCallBack: Function) {
        this.mOnChange = pChangeCallBack;
    }
    //_______________________________________________________
    public updateCollapse(pOptions: { toShow: boolean, scrollInfoView?: boolean }) {
        pOptions.toShow = pOptions.toShow !== undefined ? pOptions.toShow : false;
        pOptions.scrollInfoView = pOptions.scrollInfoView !== undefined ? pOptions.scrollInfoView : false;

        ViewUtils.setClassShowState(this.mCollapseTarget, pOptions.toShow);
        let aCollapseDiv = Op3dUtils.getElementIn(this.mCollapseTarget.previousElementSibling as HTMLElement, 'collapse_div')
        aCollapseDiv.setAttribute('aria-expanded', pOptions.toShow + '');

        if (pOptions.scrollInfoView === true) {
            aCollapseDiv.scrollIntoView();
        }
    }
    //_______________________________________________________
    public setVisibility(pToShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mContainer, pToShow);
    }
    //_______________________________________________________
    protected abstract _onChange(pToChange: boolean): void;
    //_______________________________________________________
    public abstract reset(pToThrowChange?: boolean, pToClear?: boolean);
    //_______________________________________________________
    public updateFilter(pOpticsFilterComp: iOpticsFilterComp<any>,
        pToChange: boolean = true) {

        if (pOpticsFilterComp.params != null) {
            this.updateParams(pOpticsFilterComp.params);
        }

        if (pOpticsFilterComp.data != null) {
            this.updateFilterData(pOpticsFilterComp.data, pToChange);
        }
    }
    //_______________________________________________________
    public updateParams(pRangeParams: iOpticsFilterParams) {
        if (pRangeParams.onChange != null) {
            this._updateChangeCallback(pRangeParams.onChange);
        }

        if (pRangeParams.title != null) {
            this._updateTitle(pRangeParams.title);
        }

        if (pRangeParams.showOnStart != null) {
            this.updateCollapse({ toShow: pRangeParams.showOnStart });
        }

        if (pRangeParams.isCanBeNone != null) {
            this.mIsCanBeNone = pRangeParams.isCanBeNone;
        }
    }
    //_______________________________________________________
    public updateInfo(_pMsg: string) {
    }
    //_______________________________________________________
    public showInfo(pToShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mInfoBtn, pToShow);
    }
    //_______________________________________________________
    public setValue(..._args) { }
    //_______________________________________________________
    public disable() {
        this.mContainer.classList.add(Strings.DISABLED_STATE)
    }
    //_______________________________________________________
    public enable() {
        this.mContainer.classList.remove(Strings.DISABLED_STATE)
    }
    //_______________________________________________________
    public get title() {
        return this.mTitle;
    }
    //_______________________________________________________
    public get isCanBeNone() {
        return this.mIsCanBeNone;
    }
    //__________________________________________________________________________________________ 
    public setData(_pKey: string, _pData: any) { }
    //__________________________________________________________________________________________      
    // public getData(): any {
    //     return '';
    // }
    //__________________________________________________________________________________________
    public abstract updateFilterData(pData: any, pToChange?: boolean): void;

    //_______________________________________________________
    public set sendDataOnEdges(pVal: boolean) {
        this.mSendDataOnEdges = pVal
    }
    //__________________________________________________________________________________________   
}
