import { eTextTransform } from "../../../_context/Enums";
import { iMLSearchData, iClientPoint } from "../../../_context/_interfaces/Interfaces";
import { DataLoader } from "../../../data/data_loader/DataLoader";
import { iSourceVO } from "../../../parser/SourcesParser";
import { Part } from "../../../parts/Part";
import { FiltersHandler } from "./_filterHandlers/FiltersHandler";
import { SliderValue, iBootstrapSliderOptions } from "./filters/RangeFilter";

export type FilterDataType = iMultipleCheckboxData | iRadioBtnsData | iRangeData | iDropdownData;
//______________________________________________________________________________________________
export interface iMenuListColumn {
    /**
     * @description Whether to show this column on first time opening the menu. Default - FALSE.
     * */
    showOnStart?: boolean; // Default - FALSE.

    /**
     * @description Whether to capitalize the text or not
     * default TRUE
     * */
    capitalize?: boolean;

    // /**
    //  * @description Whether this column is visible. 
    //  * Default - true.
    //  * */
    isVisible?: boolean;

    /**
     * @description If true - this columns will always show, without the option to hide it. Default - FALSE.
     * */
    static_col?: boolean;

    /**
     * @description Min width of this column. Default - 100.
     */
    minWidth?: number;

    /**
     * @description Default width of this column. Default - 100.
     */
    width?: number;

    /**
     * @description Min width of this column. Default - 1000.
     */
    maxWidth?: number;

    /**
     * @description Type of the data presented in this columns. Default - eMenuListColumnsDataType.STRING.
     */
    dataType?: eMenuListColumnsDataType;


    // /**
    //  * @description Relevant only for columns with data type eMenuListColumnsDataType.NUMBER. If false - in case the value of the data is zero - it present blank. Default - TRUE.
    //  * */
    // allowZero?: boolean;


    /**
     * @description a translate fnction will be sent on demand
     */
    translateFunction?: (pData: string) => string;

    /**
     * @description Relevant only for columns with data type eMenuListColumnsDataType.NUMBER. 
     * If mentioned - numbers will presented with [fixed] digits after the floating point.
     * */
    fixed?: number;

    /**
     * @description Title of the column.
     * */
    name: string;

    /**
     * @description Path to the data from the receiving object. Default - name.
     * */
    serverPath?: string;

    /**
     * @description If mentioned - the columns could be sorted. SortName is the name should be 
     * sended to the server in order to sort this column. Default - null.
     * */
    sortName?: string;

    /**
     * @description Units of the data. If "env" - the units will change according to the 
    envioement of the menu.
    */
    units?: string;

    // /**
    //  * @description If mentioned - This function should translate the data received 
    //from the server.
    //  * */
    // translateFunction?: (pData: any) => string;

    /**
     * @description Relevant only for columns with data type eMenuListColumnsDataType.STRING. 
    Transform the presented text to the requested form. Default - eTextTransform.CAPITALIZE.
     * */
    text_transform?: eTextTransform;

    /**
     * @description Transform the column title to the requested form. Default - text_transform.
     * */
    title_transform?: eTextTransform;
}
//______________________________________________________________________________________________
export enum eFilterType {
    RANGE,
    MULTIPLE_CHECKBOX,
    RADIO_BTNS,
    DROPDOWN
}
//______________________________________________________________________________________________
export interface iOpticsFilterParams {
    showOnStart?: boolean;
    showCloseBtn?: boolean;
    title: string;
    onChange: Function;
    isCanBeNone?: boolean;
    hideResetBtn?: boolean;
}
//______________________________________________________________________________________________
export interface iMenuParams {
    dataLoader: DataLoader;
    category: string,
    limit: number;
    title: string;
    columns: Array<iMenuListColumn>;
    isHelpMeSearchBtnExits: boolean;
}
//______________________________________________________________________________________________
export interface iSingleFilterData<T = FilterDataType> {
    filterType: eFilterType;
    data: T;
    name: string;
    params: iOpticsFilterParams;
    isVisible: boolean;
}
//______________________________________________________________________________________________
export interface iOMCheckboxElement extends HTMLInputElement {
    serverName?: any;
}
//______________________________________________________________________________________________
export interface iOMOptionElement extends HTMLOptionElement {
    data?: iOMOneDropdownItem;
}
//______________________________________________________________________________________________
export interface iOMOneDropdownItem {
    name: string;
    serverName?: string;
    selected?: boolean; //Default - false
}
//______________________________________________________________________________________________
export interface iRadioBtnSpecialBtn {
    text: string;
    callback: Function;
}
//______________________________________________________________________________________________
export interface iRadioBtnComp {
    radioBtn: HTMLInputElement;
    wrapper: HTMLElement;
    name: string;
    // specialBtn?: iRadioBtnSpecialBtn;
    serverName?: string;
}
//______________________________________________________________________________________________
export interface iOMDropdownJSONData {
    options?: Array<iOMOneDropdownItem>;
    selectedOptions?: Array<iOMOneDropdownItem>;
}
//______________________________________________________________________________________________
export interface iDropdownData {
    data?: Array<iOMOneDropdownItem>;
}
//______________________________________________________________________________________________
export interface iMultipleCheckboxData {
    selectAllCheckbox?: boolean;
    checkboxesData?: Array<iOneCheckBoxData>;
}
//______________________________________________________________________________________________
export interface iRangeDropdownElement {
    value: SliderValue,
    title: string
}
//______________________________________________________________________________________________
export interface iOneCheckBoxData {
    name: string;
    isChecked?: boolean;
    isEnabled?: boolean;
}
//______________________________________________________________________________________________
export interface iRangeData {
    data?: iBootstrapSliderOptions;
    unit?: string;
    isCanChangeUnit?: boolean;
    showDropdown?: boolean;
    sendDataOnEdges?: boolean; //If false - when the slider will be in the edges of the range - it will send null. Default - false.
    filterHandler: FiltersHandler;
}
//______________________________________________________________________________________________
export interface iOneRadioBtnData {
    name: string;
    serverName?: any;
}
//______________________________________________________________________________________________
export enum eMenuListColumnsDataType {
    NUMBER,
    STRING
}
//______________________________________________________________________________________________
export interface iOpenMenu {
    part?: Part;
    searchData?: iMLSearchData;
}
//______________________________________________________________________________________________
export interface iOpenSourcesMenu extends iOpenMenu {
    part_id: string;
    onSelectItem?: (pSource: iSourceVO) => void;
}
//______________________________________________________________________________________________
export interface iOpticsMenuOpen extends iOpenMenu {
    openMenuPoint?: iClientPoint;
}
//______________________________________________________________________________________________
export interface iOptomechanicMenuOpen extends iOpenMenu {
    openMenuPoint?: iClientPoint;
}
//______________________________________________________________________________________________
export interface iRadioBtnsData {
    data: Array<iOneRadioBtnData>;
    defaultNameIndex: number;
}
//______________________________________________________________________________________________
export interface iOpticsFilterComp<T = FilterDataType> {
    container: HTMLElement;
    data: T;
    params: iOpticsFilterParams;
}
//______________________________________________________________________________________________
