import {
    BoxHelper, Vector3, Line3, Matrix4, Object3D, BufferGeometry,
    LineBasicMaterialParameters, Line, LineBasicMaterial, Box3, Mesh,
    EdgesGeometry, LineSegments, Quaternion, Material, Float32BufferAttribute,
    MathUtils, CatmullRomCurve3, Color
} from "three";
import { Op3dContext } from "../_context/Op3dContext";
import { iHash } from "../_context/_interfaces/Interfaces";
import { ColorUtils } from "../ui/ColorUtils";
import { Op3dComponentBase } from "../ui/Op3dComponentBase";
import { SceneContext } from "../scene/SceneContext";
import { Part } from "../parts/Part";
import { AxisObject3D } from "../parts/_parts_assets/Axis";
import { OptixPartDisplayer } from "../parts/_parts_assets/OptixPartDisplayer";
import { UploadFileForm, iInitUploadFormParams } from "../parser/UploadFileForm";
import { ServerContext } from "../server/ServerContext";
import { ReactElement } from "react";
import { createRoot } from "react-dom/client";
import { Measurement } from "../ui/forms/Measurement";
import { NotificationCenter } from "../ui/home/_notifications/NotificationCenter";
import { ePartType } from "../parts/PartInterfaces";

export class Op3dUtils {

    private static TMP_BH: BoxHelper;
    //__________________________________________________________________________________________
    /**
     * @descriptionis An algorithm that decimates a curve composed of line segments to a similar curve with fewer points. 
     * @link https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm
     */
    //__________________________________________________________________________________________
    public static RamerDouglasPeucker(pPoints: Array<Vector3>, pEpsilon: number): Array<Vector3> {
        let aLine = new Line3(pPoints[0], pPoints[pPoints.length - 1]);

        let aDMax = 0;
        let aIndex = 0
        let aEnd = pPoints.length;
        for (let i = 1; i < aEnd; i++) {
            let d = aLine.closestPointToPoint(pPoints[i], false,
                new Vector3).distanceTo(pPoints[i]);
            if (d > aDMax) {
                aIndex = i;
                aDMax = d;
            }
        }

        let aResultList = new Array<Vector3>();

        // If max distance is greater than epsilon, recursively simplify
        if (aDMax > pEpsilon) {
            //# Recursive call
            let aRecResults1 = Op3dUtils.RamerDouglasPeucker(pPoints.slice(0, aIndex), pEpsilon);
            let aRecResults2 = Op3dUtils.RamerDouglasPeucker(pPoints.slice(aIndex), pEpsilon);

            //# Build the result list
            aResultList.push(...aRecResults1);
            aResultList.push(...aRecResults2);

        } else {
            aResultList = [pPoints[0], pPoints[aEnd - 1]];
        }
        //# Return the result
        return aResultList
    }
    //__________________________________________________________________________________________
    public static getMaxArrValue(pArray: Array<number>): number {
        let aMaxVal = Number.MIN_VALUE;
        for (let item in pArray) {
            if (pArray[item] > aMaxVal) {
                aMaxVal = pArray[item];
            }
        }

        return aMaxVal;
    }
    //__________________________________________________________________________________________
    public static isMac() {
        let aIsMac = (navigator.userAgent.indexOf('Mac') != -1);
        return aIsMac;
    }
    //__________________________________________________________________________________________
    public static idGenerator(pToLowerCase: boolean = false): string {
        let a1 = Date.now().toString(36);
        let a2 = Math.random().toString(36).substring(2, 5);
        let aID = (a1 + a2).toUpperCase();
        if (pToLowerCase) {
            aID = aID.toLowerCase();
        }
        return aID;
    }
    //__________________________________________________________________________________________
    public static getEnumKeyByValue(pEnumType: any, pVal: number) {
        let aKey = Object.keys(pEnumType).find(key => pEnumType[key] === pVal);
        if (aKey !== undefined) {
            return pEnumType[aKey];
        }
    }
    //__________________________________________________________________________________________
    public static matrixToArray(pMatrix: Matrix4) {
        let aArr = new Array<number>();
        aArr.push(...pMatrix.elements);
        return aArr;
    }
    //__________________________________________________________________________________________
    public static getNormalizedName(pName: string) {
        if (null == pName) {
            return undefined;
        }

        let aNewName = pName.toLowerCase();
        aNewName = aNewName.replace(/\s+/g, ' ').replace(/\)/g, '\\)').replace(/\(/g, '\\(');
        aNewName = aNewName.trim();
        return aNewName;
    }
    //__________________________________________________________________________________________
    public static toExponential(pNumber: number, pToFixed: number = 0) {
        let aToFixed = pNumber.toFixed(pToFixed);
        if ((0 != pNumber) && (0 == parseFloat(aToFixed))) {
            aToFixed = pNumber.toExponential(pToFixed);
        }

        return aToFixed;
    }
    //__________________________________________________________________________________________
    public static arrayMax(pArray: any) {
        let aMax = pArray[Object.keys(pArray)[0]];
        for (let item in pArray) {
            if (pArray[item] > aMax) {
                aMax = pArray[item];
            }
        }

        return aMax;
    }
    //____________________________________________________________________________
    public static arrayMin(pArray: any) {
        let aMin = pArray[Object.keys(pArray)[0]];
        for (let item in pArray) {
            if (pArray[item] < aMin) {
                aMin = pArray[item];
            }
        }

        return aMin;
    }
    //__________________________________________________________________________________________
    private static normalizeValue(value: number) {
        return Math.floor((value / 255) * 255);
    }
    //__________________________________________________________________________________________
    public static initialsToColor(pLetter1: string, pLetter2: string) {
        var ascii1 = pLetter1.charCodeAt(0);
        var ascii2 = pLetter2.charCodeAt(0);

        // Step 2: Normalize ASCII values to range 0-255
        var normalized1 = this.normalizeValue(ascii1);
        var normalized2 = this.normalizeValue(ascii2);

        // Step 3: Assign normalized values to red (R) and green (G) components
        var red = normalized1;
        var green = normalized2;

        // Step 4: Set blue (B) component to 0 or a fixed value
        var blue = 0;

        // Step 5: Combine components to create color value
        var color = (red << 16) | (green << 8) | blue;

        // Return the final color value
        return color;
    }
    //__________________________________________________________________________________________
    public static getLineByTwoPoints(pStartPoint: Vector3,
        pEndPoint: Vector3,
        pDirection: Vector3,
        pColor: any,
        pAlpha: number = 1,
        pName: string = ''): Object3D {

        if (pStartPoint == null || pEndPoint == null || pDirection == null) {
            return;
        }

        let aContainer = new Object3D()

        let lineGeometry = new BufferGeometry();
        (lineGeometry as any).setAttribute('position',
            new Float32BufferAttribute([0, 0, 0, 0, 1, 0], 3));

        aContainer.position.copy(pStartPoint.clone());

        let aLineBasicMaterialParemeters: LineBasicMaterialParameters = {};
        aLineBasicMaterialParemeters.color = ColorUtils.threeColorToHex(pColor);

        if (pAlpha != 1) {
            aLineBasicMaterialParemeters.transparent = true;
            aLineBasicMaterialParemeters.opacity = pAlpha;
        }

        let aLine = new Line(lineGeometry, new LineBasicMaterial(aLineBasicMaterialParemeters));
        aLine.matrixAutoUpdate = false;

        let aLength = pStartPoint.distanceTo(pEndPoint);

        this._setDirection(aContainer, pDirection.clone());
        aLine.scale.set(1, aLength, 1);
        aLine.updateMatrix();
        aLine.name = pName;
        (aLine as any).color = (aLine.material as any).color;

        aContainer.add(aLine);
        return aContainer;
    }
    //__________________________________________________________________________________________
    private static _setDirection(aObj: Object3D, pDirection: Vector3) {
        let aAxis = new Vector3();
        let aRadians: number;
        if (pDirection.y > 0.99999) {

            aObj.quaternion.set(0, 0, 0, 1);

        } else if (pDirection.y < - 0.99999) {

            aObj.quaternion.set(1, 0, 0, 0);

        } else {

            aAxis.set(pDirection.z, 0, - pDirection.x).normalize();
            aRadians = Math.acos(pDirection.y);
            aObj.quaternion.setFromAxisAngle(aAxis, aRadians);
        }
    }
    //__________________________________________________________________________________________
    public static getElementIn(pElement: HTMLElement, pID: string, pToChangeID?: boolean): HTMLElement | null {
        if (null == pElement) {
            return null;
        }

        //let aElement = $(pElement).find('#' + pID)[0];
        let aElement = $(pElement).find('[id="' + pID + '"]')[0];

        if (null == aElement) {
            return null;
        }

        if (true == pToChangeID) {
            aElement.id += Op3dComponentBase.ID_ITERATOR;
            Op3dComponentBase.ID_ITERATOR++;
        }

        return aElement;
    }
    //__________________________________________________________________________________________
    public static getElementInByAttr(pElement: HTMLElement, pAttr: string, pValue: string) {
        if (null == pElement) {
            return null;
        }

        let aElement = $(pElement).find('[' + pAttr + '="' + pValue + '"]')[0];
        return aElement;
    }
    //__________________________________________________________________________________________
    public static getCookie(pCookieName: string) {
        let aName = pCookieName + "=";
        let aDecodedCookie = decodeURIComponent(document.cookie);
        let aCookiesArr = aDecodedCookie.split(';');
        for (let i = 0; i < aCookiesArr.length; i++) {
            let aCurrentCookie = aCookiesArr[i].trim();
            if (0 === aCurrentCookie.indexOf(aName)) {
                return aCurrentCookie.substring(aName.length, aCurrentCookie.length);
            }
        }
        return "";
    }
    //__________________________________________________________________________________________
    public static deleteCookie(pCookieName: string) {
        var aDate = new Date(0);

        let aDomain = 'domain=' + ServerContext.cookie_domain + ';';
        if (document.location.href.toLowerCase().indexOf("localhost") != -1) {
            aDomain = '';
        }
        let expires = "expires=" + (aDate as any).toGMTString();
        document.cookie = pCookieName + "=;" + aDomain + expires + ";path=/";
    }
    //__________________________________________________________________________________________
    public static setCookie(pCookieName: string, pValue: string, pDays: number, pHours: number) {
        var aDate = new Date();
        let aDaysInMS = (pDays * 24 * 60 * 60 * 1000);
        let aHoursInMs = (pHours * 60 * 60 * 1000);
        aDate.setTime(aDate.getTime() + (aDaysInMS + aHoursInMs));
        let expires = "expires=" + (aDate as any).toGMTString();
        let aDomain = 'domain=' + ServerContext.cookie_domain + ';';
        if (document.location.href.toLowerCase().indexOf("localhost") != -1) {
            aDomain = '';
        }

        document.cookie = pCookieName + "=" + pValue + ";" + aDomain + expires + ";path=/";
    }
    //__________________________________________________________________________________________
    public static getBoxOfObj(pObj: Object3D) {
        let aBox = new Box3().setFromObject(pObj);
        return aBox;
    }
    //__________________________________________________________________________________________
    public static isObjectInsideObject(pInsideObject: Object3D,
        pOutsideObject: Object3D) {

        let aInsideBox = new Box3().setFromObject(pInsideObject);
        let aOutsideBox = new Box3().setFromObject(pOutsideObject);

        //return aOutsideBox.containsBox(aInsideBox);

        return (
            (aInsideBox.max.x < aOutsideBox.max.x) &&
            (aInsideBox.max.y < aOutsideBox.max.y) &&
            (aInsideBox.max.z < aOutsideBox.max.z) &&
            (aInsideBox.min.x > aOutsideBox.min.x) &&
            (aInsideBox.min.y > aOutsideBox.min.y) &&
            (aInsideBox.min.z > aOutsideBox.min.z));
    }
    //__________________________________________________________________________________________
    public static addBoxHelperToScene(pObj: Object3D) {
        let aBox = new BoxHelper(pObj);
        SceneContext.MAIN_SCENE.add(aBox);
    }
    //__________________________________________________________________________________________
    public static addBoxHelperToSelectedPart() {
        if (null != Op3dUtils.TMP_BH && Op3dUtils.TMP_BH.parent != null) {
            Op3dUtils.TMP_BH.parent.remove(Op3dUtils.TMP_BH);
        }

        let aPart = Op3dContext.PARTS_MANAGER.selectedPart.visibleObj.children[0];
        Op3dUtils.TMP_BH = new BoxHelper(aPart);
        let aBox3 = new Box3().setFromObject(Op3dUtils.TMP_BH);

        SceneContext.MAIN_SCENE.add(Op3dUtils.TMP_BH);
    }
    //__________________________________________________________________________________________
    public static setWireFrame(pPart: Part) {
        if (null == pPart) {
            return;
        }

        OptixPartDisplayer.unHighlightObject(pPart);

        let aObject3d = new Object3D();

        pPart.visibleObj.traverse((object) => {
            if (object.name == AxisObject3D.AXIS_NAME && object.parent != null) {
                object.parent.remove(object);
            }
        });

        pPart.visibleObj.traverse((object) => {
            if (object instanceof Mesh) {
                let aMesh = object;
                let aEdgesGeometry = new EdgesGeometry(aMesh.geometry.clone(), 90);
                let aEdgesMaterial = new LineBasicMaterial({ color: 0 });
                let aLineSegment = new LineSegments(aEdgesGeometry, aEdgesMaterial);

                aLineSegment.rotation.setFromQuaternion(aMesh.getWorldQuaternion(new Quaternion()));
                aLineSegment.position.copy(aMesh.getWorldPosition(new Vector3()));

                aObject3d.add(aLineSegment);
            }
        });

        SceneContext.MAIN_SCENE.add(aObject3d);
        Op3dContext.PARTS_MANAGER.deletePart(pPart);
    }
    //__________________________________________________________________________________________
    public static uploadImages() {
        UploadFileForm.instance.open({
            func: (pImagesFiles) => this.uploadImagetoServer(pImagesFiles),
            hasToReplace: false,
            title: 'UploadImages',
            acceptedFormats: '.png',
            show_supported_text: true,
            isMultiple: true,
        });
    }
    //__________________________________________________________________________________________
    public static async uploadImagetoServer(pImagesFiles: iInitUploadFormParams) {
        let aImages = pImagesFiles.fileList;
        for (let i = 0; i < aImages.length; i++) {
            let aImageName = aImages[i].name.split('.')[0];
            ServerContext.SERVER.uploadPartImage(aImageName, aImages[i], 'png');
        }
    }
    //__________________________________________________________________________________________
    public static cloneObject3D(pObject3D: Object3D) {
        let aClonedObject = pObject3D.clone();
        Op3dUtils._deepCloneMaterial(aClonedObject);
        Op3dUtils._deepCloneGeometry(aClonedObject);

        return aClonedObject;
    }
    //__________________________________________________________________________________________
    private static _deepCloneMaterial(pPart: Object3D) {
        if (pPart instanceof Mesh && pPart.material != null) {
            this._cloneMat(pPart);
        }

        if (pPart.children.length > 0) {
            for (let i = 0; i < pPart.children.length; i++) {
                this._deepCloneMaterial(pPart.children[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    private static _deepCloneGeometry(pPart: Object3D) {
        if (pPart instanceof Mesh && pPart.geometry != null) {
            this._cloneGeometry(pPart);
        }

        if (pPart.children.length > 0) {
            for (let i = 0; i < pPart.children.length; i++) {
                this._deepCloneGeometry(pPart.children[i]);
            }
        }
    }
    //__________________________________________________________________________________________
    private static _cloneGeometry(pPart: Mesh) {
        pPart.geometry = pPart.geometry.clone();
    }
    //__________________________________________________________________________________________
    private static _cloneMat(pPart: Mesh) {
        if (pPart.material instanceof Array) {
            let aLength = (pPart.material as any).length;
            let aCloneMat = new Array<Material>();
            for (let i = 0; i < aLength; i++) {
                aCloneMat[i] = pPart.material[i].clone();
            }

            // support for parts that have a array of materials
            (pPart as any).material = aCloneMat;
        } else {
            pPart.material = pPart.material.clone();
        }
    }
    //__________________________________________________________________________________________
    public static filterHash<T>(pHash: iHash<T>, pFunc: (pItem: T) => boolean): iHash<T> {
        let aRetHash: iHash<T> = {}
        for (let key in pHash) {
            if (true == pFunc(pHash[key])) {
                aRetHash[key] = pHash[key];
            }
        }

        return aRetHash;
    }
    //__________________________________________________________________________________________
    public static changePartColorWithInterval(object3D: Object3D, changeCount: number = 3, interval: number = 300): void {
        const originalMaterials: Map<Mesh, Material> = new Map();

        const highlightMaterial = Op3dContext.GLOBAL_MATERIAL_HIGHLIGHTED_WARNING;
        const emissiveColor = 0xFF0000;

        const highlightMeshes = (highlight: boolean) => {
            object3D.traverse((child) => {
                if (child instanceof Mesh) {
                    if (!originalMaterials.has(child)) {
                        originalMaterials.set(child, child.material);
                    }
                    child.material = highlight ? highlightMaterial : originalMaterials.get(child);
                    if (highlight) {
                        highlightMaterial.emissive.set(emissiveColor);
                    }
                }
            });
        };

        let count = 0;
        const changeColorIntervalId = setInterval(() => {
            highlightMeshes(count % 2 === 0);
            count++;

            if (count >= 2 * changeCount) {
                clearInterval(changeColorIntervalId);
                highlightMeshes(false); // Reset to original materials
            }
        }, interval);
    }
    //__________________________________________________________________________________________
    /**
     * @description check collision between blackbox and categories:optics,lens,dynamic and general
     */
    public static checkBlackBoxCollision() {
        const aBlackboxes: Array<Part> = []
        const aParts: Array<Part> = []
        Op3dContext.PARTS_MANAGER.parts.forEach(part => {
            const aPartType = part.partOptions.type
            if (aPartType === ePartType.BLACKBOX) {
                aBlackboxes.push(part)
            } else if (
                aPartType === ePartType.CATALOG_OPTICS ||
                aPartType === ePartType.PARAXIAL_LENS ||
                aPartType === ePartType.DYNAMIC_PART ||
                aPartType === ePartType.GENERAL
            ) {
                aParts.push(part)
            }
        })

        for (const blackbox of aBlackboxes) {
            for (const part of aParts) {
                if (Op3dUtils.checkCollisionBetween(blackbox, part)) {
                    Op3dUtils.changePartColorWithInterval(part.visibleObj)
                    return true
                }
            }
        }

        return false
    }

    //__________________________________________________________________________________________
    /**
    * @description collision between 2 parts
     */
    public static checkCollisionBetween(pPart1: Part, pPart2: Part) {
        const aBoxPartFirst = new Box3().setFromObject(Op3dUtils.getNetoItem(pPart1.visibleObj))
        const aBoxPartSecond = new Box3().setFromObject(Op3dUtils.getNetoItem(pPart2.visibleObj))
        return aBoxPartFirst.intersectsBox(aBoxPartSecond)
    }
    //__________________________________________________________________________________________
    public static getNetoItemSize(pObject3D: Object3D, pPrecision?: number) {
        if (null === pObject3D) {
            return null;
        }

        let aObject3D = new Object3D().copy(pObject3D);
        aObject3D.rotation.setFromQuaternion(new Quaternion());

        let aAxes = new Array<Object3D>();
        aObject3D.traverse((obj) => {
            if (obj.name == AxisObject3D.AXIS_NAME) {
                aAxes.push(obj);
            }
        });

        for (let i = 0; i < aAxes.length; i++) {
            aAxes[i].parent.remove(aAxes[i]);
        }

        let aSize = new Box3().setFromObject(aObject3D).getSize(new Vector3());

        if (pPrecision !== undefined) {

            let aNewVec = new Vector3(
                parseFloat(aSize.x.toFixed(pPrecision)),
                parseFloat(aSize.y.toFixed(pPrecision)),
                parseFloat(aSize.z.toFixed(pPrecision)));

            return aNewVec;
        } else {
            return aSize;
        }

    }
    //__________________________________________________________________________________________
    public static getNetoItem(pObject3D: Object3D,) {
        // let aObject3D = pObject3D.clone();
        let aObject3D = new Object3D().copy(pObject3D);
        // aObject3D.rotation.setFromQuaternion(new Quaternion());

        let aAxes = new Array<Object3D>();
        aObject3D.traverse((obj) => {
            if (obj.name == AxisObject3D.AXIS_NAME) {
                aAxes.push(obj);
            }
        });

        for (let i = 0; i < aAxes.length; i++) {
            aAxes[i].parent!.remove(aAxes[i]);
        }

        return aObject3D;
    }
    //__________________________________________________________________________________________
    public static removeObject3DFromParent(pObj: Object3D) {
        Op3dContext.INPUT_DISPATCHER.clickManager.removeAllListeners(pObj);

        if (null == pObj.parent) {
            return;
        }
        pObj.parent.remove(pObj);
    }
    //__________________________________________________________________________________________
    public static openPopupWindow(pLink: string) {
        let params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,';
        params += 'width=600,height=300,left=100,top=100';

        window.open(pLink, pLink, params);
    }
    //__________________________________________________________________________________________
    public static getDate() {
        let aDateTime = new Date().toJSON().slice(0, 10).split('-').reverse().join('/');
        return aDateTime;
    }
    //__________________________________________________________________________________________
    public static addTooltip(pElement: HTMLElement, pText: string) {
        let aMouseLeaveFunc = () => {
            pElement.removeEventListener('mouseleave', aMouseLeaveFunc);

            Op3dContext.TOOLTIP.hide();
        }

        let aMouseEnterFunc = (_e: MouseEvent) => {
            pElement.addEventListener('mouseleave', aMouseLeaveFunc);
            let aBB = pElement.getBoundingClientRect();

            Op3dContext.TOOLTIP.show({ clientX: aBB.right, clientY: aBB.top }, pText);
        };

        pElement.addEventListener('mouseenter', aMouseEnterFunc);
    }
    //__________________________________________________________________________________________
    public static sortObject<T>(pObj: iHash<T>, sortFunction: (a: T, b: T) => number) {
        return Object.fromEntries((Object.entries(pObj)).sort((a, b) => {
            return sortFunction(a[1], b[1]);
        }));
    }
    //__________________________________________________________________________________________
    public static createCurveBetween(pFirstLine: Line, pSecondLine: Line) {
        const firstLineEnd = new Vector3(pFirstLine.geometry.attributes['position'].getX(0),
            pFirstLine.geometry.attributes['position'].getY(0),
            pFirstLine.geometry.attributes['position'].getZ(0))
        const firstLineStart = new Vector3(pFirstLine.geometry.attributes['position'].getX(1),
            pFirstLine.geometry.attributes['position'].getY(1),
            pFirstLine.geometry.attributes['position'].getZ(1))
        const secondLineEnd = new Vector3(pSecondLine.geometry.attributes['position'].getX(0),
            pSecondLine.geometry.attributes['position'].getY(0),
            pSecondLine.geometry.attributes['position'].getZ(0))
        const secondLineStart = new Vector3(pSecondLine.geometry.attributes['position'].getX(1),
            pSecondLine.geometry.attributes['position'].getY(1),
            pSecondLine.geometry.attributes['position'].getZ(1))
        let aCurvePoints = [];

        let aSecondVector = new Vector3().subVectors(secondLineEnd, secondLineStart).normalize()
        let aFirstVector = new Vector3().subVectors(firstLineStart, firstLineEnd).normalize()

        let aFisrtLineLength = firstLineStart.distanceTo(firstLineEnd);
        let aSecondLineLength = secondLineStart.distanceTo(secondLineEnd);
        let aMainVectorOffset
        if (aFisrtLineLength > aSecondLineLength) {
            aMainVectorOffset = aSecondLineLength * 0.2;
        } else {
            aMainVectorOffset = aFisrtLineLength * 0.2;
        }

        const aSecondVectorOffsetPoint = secondLineStart.clone().add(aSecondVector.clone().multiplyScalar(aMainVectorOffset));
        const aFirstVectorOffsetPoint = firstLineEnd.clone().add(aFirstVector.clone().multiplyScalar(aMainVectorOffset));

        const angleRadians = aSecondVector.angleTo(aFirstVector);
        const angleDegrees = MathUtils.radToDeg(angleRadians);

        let aCenterPointOnBetweenVector = new Vector3().copy(aSecondVectorOffsetPoint.clone()).add(aFirstVectorOffsetPoint.clone()).multiplyScalar(0.5);
        let aNewVectorBetween = new Vector3().subVectors(aCenterPointOnBetweenVector.clone(), firstLineEnd.clone())
        const aNewVectorLength = aNewVectorBetween.length();
        const aNewVectorLengthOffset = aNewVectorLength * 1.1;
        aNewVectorBetween.normalize();
        aNewVectorBetween.multiplyScalar(aNewVectorLengthOffset);
        const aCenterPoint = firstLineEnd.clone().add(aNewVectorBetween);

        aCurvePoints.push(aSecondVectorOffsetPoint); // End point of the first line
        aCurvePoints.push(aCenterPoint); // Control point (adjust as needed)
        aCurvePoints.push(aFirstVectorOffsetPoint); // Start point of the second line

        const aCurve = new CatmullRomCurve3(aCurvePoints);

        const aCurveGeometry = new BufferGeometry().setFromPoints(aCurve.getPoints(50));


        const aCurvedLine = new LineSegments(aCurveGeometry, Measurement.instance.mLineMaterial);
        return { line: aCurvedLine, angle: angleDegrees, curveCenterPoint: aCenterPoint }
    }
    //__________________________________________________________________________________________
    public static getPremiumButton(pIsSmall: boolean = false) {
        let aPFButton = document.createElement('div');
        aPFButton.classList.add('pf_button');
        if (true == pIsSmall) {
            aPFButton.classList.add('pf_small');
        }

        aPFButton.addEventListener('click', () => {
            let aAnchor = document.createElement('a');
            aAnchor.target = '_blank';
            aAnchor.href = ServerContext.pricing_base_link;
            aAnchor.click();
        });

        return aPFButton;
    }
    //__________________________________________________________________________________________
    /**
     * 
     * @description Returns the index of pVal or of the closest smaller value in pArr.
     * @param pVal - required value to find.
     * @param pArr - sorted array of number.
     * @returns 
     */
    //__________________________________________________________________________________________
    public static getClosestPrevIndex(pVal: number, pArr: Array<number>) {
        let l = pArr.length;

        if (pVal < pArr[0]) return -1;
        if (pVal > pArr[(l - 1)]) return -1;

        if (pVal == pArr[0]) return 0;

        for (let i = 1; i < l; i++)
            if (pArr[i] >= pVal)
                return (i - 1);

        return (l - 1);
    }
    //__________________________________________________________________________________________
    /**
     * 
     * @description Returns the index of pVal or of the closest higher value in pArr.
     * @param pVal - required value to find.
     * @param pArr - sorted array of number.
     * @returns 
     */
    //__________________________________________________________________________________________
    public static getClosestNextIndexInArray(pVal: number, pArr: Array<number>) {
        let l = pArr.length;

        if (pVal < pArr[0]) return -1;
        if (pVal > pArr[(l - 1)]) return -1;

        if (pVal == pArr[0]) return 0;

        for (let i = 1; i < l; i++)
            if (pArr[i] > pVal)
                return i;

        return (l - 1);
    }
    //__________________________________________________________________________________________
    public static renderReactComponentIn(pElement: HTMLElement, pElementID: string, pComponent: ReactElement) {
        const aElementToRenderIn = Op3dUtils.getElementIn(pElement, pElementID)
        const aReactRoot = createRoot(aElementToRenderIn)
        aReactRoot.render(
            pComponent
        )
    }
    //__________________________________________________________________________________________
}
