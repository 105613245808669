import { Op3dContext } from "../../_context/Op3dContext";
import { Op3dComponentBase } from "../Op3dComponentBase";

export interface iFloatingImageParams {
    imageAsElement: HTMLElement;
    title: string;
}

export interface iHTMLPos {
    left: number;
    top: number;
}

export interface iFloatingImage {
    elementPos?: iHTMLPos;
}

export class FloatingImage extends Op3dComponentBase<iFloatingImage> {

    //______________________________________________________________________________________________
    constructor() {
        super({
            container: FloatingImage._getCot(),
            skinPath: './skins/forms/floating_image.html',
            draggableParams: {
                snap: true,
                containment: 'window',
                handle: '.modal-header'
            }
        });
    }
    //______________________________________________________________________________________________
    private static _getCot() {
        let aContainer = document.createElement('div');
        aContainer.classList.add('modal');
        aContainer.classList.add('new_modal');
        aContainer.classList.add('round_bottom_border');
        aContainer.classList.add('w-auto');
        aContainer.classList.add('h-auto');
        aContainer.setAttribute('data-toggle', 'modal');
        aContainer.setAttribute('data-backdrop', 'false');

        document.getElementById('forms').appendChild(aContainer);

        return aContainer;
    }
    //______________________________________________________________________________________________
    public async set(pFloatingImage: iFloatingImageParams) {
        await Op3dContext.wait(() => (true === this.mIsReady));

        this._getPart('body').appendChild(pFloatingImage.imageAsElement);
        this._getPart('title').innerHTML = pFloatingImage.title;

        return this;
    }
    //______________________________________________________________________________________________
    protected _onOpen(pData?: iFloatingImage): void {
        if (undefined === pData) {
            return;
        }

        if (undefined !== pData.elementPos) {
            this.mContainer.style.left = pData.elementPos.left + 'px';
            this.mContainer.style.top = pData.elementPos.top + 'px';
        }
    }
    //______________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //______________________________________________________________________________________________
}