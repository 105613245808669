import { Vector3 } from "three";

export class ROTATION_CUBE_NORMALS {
    public static POS_X = new Vector3(1, 0, 0);
    public static NEG_X = new Vector3(-1, 0, 0);
    public static POS_Y = new Vector3(0, 1, 0);
    public static POS_Z = new Vector3(0, 0, 1);
    public static NEG_Z = new Vector3(0, 0, -1);
}

export class DIRECTION {
    public static TOP = new Vector3(0, 0, -1);
    public static BOTTOM = new Vector3(0, 0, 1);
    public static RIGHT = new Vector3(1, 0, 0);
    public static LEFT = new Vector3(-1, 0, 0);

    public static UP = new Vector3(0, 1, 0);
    public static DOWN = new Vector3(0, -1, 0);
};

export class MathContext {
    public static EPSILON_20 = 1E-20;
    public static EPSILON_10 = 1E-10;
    public static EPSILON_7 = 1E-7;
    public static EPSILON_3 = 1E-3;
    public static VALID_VALUE_REFRACTIVE_INDEX = this.EPSILON_7;
    public static OP3D_MAX_NUM = 1e+10;

    static RAD_TO_DEG = 180 / Math.PI
    static DEG_TO_RAD = Math.PI / 180;
    static TO_FIXED_3: number = 3;
    static TO_FIXED_5: number = 5;

    //__________________________________________________________________________________________
    public static isInRange(pValue: number, pDesiredValue: number, pEpsilon: number = 0) {
        return (((pValue - pEpsilon) <= pDesiredValue) &&
            ((pValue + pEpsilon) >= pDesiredValue));
    }
    //__________________________________________________________________________________________

}
