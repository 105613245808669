import { EventBase } from "../../../oc/events/EventBase";
import { EventManager } from "../../../oc/events/EventManager";
import { eSidebarState, eDataPermission } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iSideBarMainSection, iSideBarSection, iSideBarCategory, iSideBarCategoryQuery, iClientPoint, iSideBarData } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { eObjectType, GeneralVO } from "../../data/VO/GeneralVO";
import { PartVO } from "../../data/VO/PartVO";
import { PartsDataLoader } from "../../data/data_loader/PartsDataLoader";
import { Part } from "../../parts/Part";
import { ePartType } from "../../parts/PartInterfaces";
import { PartsCatalog } from "../../parts/_parts_assets/PartsCatalog";
import { SceneContext } from "../../scene/SceneContext";
import { ServerContext } from "../../server/ServerContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { eClickMode } from "../_globals/PartsEventsHandler";
import { Popup } from "../forms/Popup";
import { Menu } from "./Menu";
import { SidebarList } from "./SidebarList";
import { Spinner } from "./Spinner";
import defaultSideBarIMG from "./defaultSideBarIMG";

export interface iSideBarActivePartData {
    url: string;
    type: eObjectType;
    isCustom: boolean;
}

export enum eListItemType {
    PART,
    SHAPE,
    SOLID,
    FACE,
    COORDINATE_SYSTEM,
    GENERAL
}

export interface iSidebarLabelStructure {
    currValue: string;
    changeFunc?: (pValue: string) => void;
    actionTitle?: string;
}

export interface iSideBarListData<T = any> {
    type: eListItemType;
    part: Part;
    item: T;
    selectNodeFunc?: () => void;
    deselectNodeFunc?: (pFromSidebar?: boolean) => void;
    labelInfo: iSidebarLabelStructure;
}

export interface iHTMLDropdownElement extends HTMLElement {
    value: string;
}


export interface iSidebarHTMLElement extends HTMLElement {
    part_image: HTMLImageElement;
    number_id: string;
    url: string;
    type: eObjectType;
}


export class SideBar extends Op3dComponentBase {

    private static SKIN_PATH: string = './skins/home/side_bar_menu.html';
    private static ICON_CHEVRON_DOWN: string = ' <i class="icon-chevron-down"></i>';

    private mSectionsSelect: iHTMLDropdownElement;
    private mCategorySelect: iHTMLDropdownElement;
    private mSubCategorySelect: iHTMLDropdownElement;
    private mSidebarState: eSidebarState;
    private mDropdownTemplate: HTMLElement;
    private mSectionsDD: HTMLElement;
    private mCategoriesDD: HTMLElement;
    private mSearchBarInput: HTMLInputElement;
    private mPartsContainer: HTMLElement;
    private mSubCategoriesDD: HTMLElement;
    private mSideBarItem: HTMLDivElement;
    private mActiveParts: Array<iSideBarActivePartData> = new Array<iSideBarActivePartData>();

    //private mTooltip: op3dTooltip;

    private mNavigatorTabs: HTMLElement;
    private mMainSectionsSelect: iHTMLDropdownElement;
    private mMainSectionsDD: HTMLElement;

    constructor() {
        super({
            container: document.getElementById("side-bar-container"),
            skinPath: SideBar.SKIN_PATH
        });
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {

        ViewUtils.setElementVisibilityByDNone(this.mContainer, true);
        this._initPartsList();
        await this._initSelectBars();
        await this._initCategories();

        this._initNavigator();

        // draggable modal 
        // $("#chartModal").draggable({ handle: ".modal-header" });
        (<any>$(".pdf-modal")).draggable({ handle: ".modal-header" });

        $("#fold-s").click(() => this.foldS());
        $("#fold-m").click(() => this.foldM());
        $("#fold-l").click(() => this.foldL());
        this.mSidebarState = eSidebarState.MEDIUM;

        this.mIsReady = true;
        ViewUtils.makeUnselectable(this.mContainer);
    }
    //__________________________________________________________________________________________
    private _initPartsList() {
        Op3dContext.SIDEBAR_LIST = new SidebarList(this._getPart('jstree_demo_div'));
    }
    //__________________________________________________________________________________________
    public getNavigatorState() {
        let aSearchString = '[id="navigator_tabs"] [attr_name] :first-child.active';
        let aNavItem = $(this.mContainer).find(aSearchString)[0].parentElement;
        return aNavItem.getAttribute('attr_name');
    }
    //__________________________________________________________________________________________
    public enterChooseRefMode(pPart: Part) {
        let aParts = Op3dContext.PARTS_MANAGER.parts;
        let aOptionalRefParts = aParts.filter((part) => {
            if (pPart == part) {
                return false;
            }

            if (ePartType.GROUP === part.partOptions.type) return false

            if ((null != part.refCS) && (part.refCS.refPart == pPart)) {
                return false;
            }

            return true;
        });
        Op3dContext.SIDEBAR_LIST.updatePartsList(aOptionalRefParts);
        this.enableTabs(false);
        this.setNavigatorByAttrName('Parts');
    }
    //__________________________________________________________________________________________
    public enable(pEnable: boolean) {
        ViewUtils.setElementDisabled(this.mContainer, (false == pEnable));
    }
    //__________________________________________________________________________________________
    public enterChooseLCSMode(pPart: Part) {
        let aParts = Op3dContext.PARTS_MANAGER.parts;
        let aOptionalRefParts = aParts.filter((part) => {
            if (pPart == part) {
                return true;
            }

            if (ePartType.GROUP === part.partOptions.type) return false

            return false;
        });

        Op3dContext.SIDEBAR_LIST.updatePartsList(aOptionalRefParts);
        this.enableTabs(false);
        this.setNavigatorByAttrName('Parts');
        Op3dContext.SIDEBAR_LIST.openAll();
    }
    //__________________________________________________________________________________________
    public enableTabs(pToEnable: boolean) {
        ViewUtils.setElementDisabled(this.mNavigatorTabs, (false == pToEnable));
    }
    //__________________________________________________________________________________________
    public setNavigatorByAttrName(pAttrName: string) {
        let aSearchString = '[id="navigator_tabs"] [attr_name="' + pAttrName + '"]';
        let aNavItem = $(this.mContainer).find(aSearchString)[0];
        aNavItem.click();
        Op3dContext.TOOLTIP.hide();
    }
    //__________________________________________________________________________________________
    private _initNavigator() {
        this.mNavigatorTabs = this._getPart('navigator_tabs');
        let aNavigatorElementsParent = this._getPart('navigator_elements');

        for (let i = 0; i < this.mNavigatorTabs.children.length; i++) {
            let aNav = this.mNavigatorTabs.children[i] as HTMLElement;
            aNav.addEventListener('click', () => {
                for (let i = 0; i < this.mNavigatorTabs.children.length; i++) {
                    let aChild = this.mNavigatorTabs.children[i] as HTMLElement;
                    let aIsActive = (aNav == aChild);
                    ViewUtils.setElementActive(aChild.children[0], aIsActive);
                }

                let aTargets = aNav.getAttribute('nav_targets').split(',');
                for (let j = 0; j < aNavigatorElementsParent.children.length; j++) {
                    let aCurrTarget = aNavigatorElementsParent.children[j] as HTMLElement;
                    let aTargetName = aCurrTarget.getAttribute('nav_target_name');
                    let aToShow = (aTargets.indexOf(aTargetName) > -1);
                    ViewUtils.setElementVisibilityByDNone(aCurrTarget, aToShow);
                }
            });


            let aText = aNav.getAttribute('attr_name');
            aNav.addEventListener('mouseenter',
                () => {
                    let aNavBB = aNav.getBoundingClientRect();
                    Op3dContext.TOOLTIP.show({
                        clientX: aNavBB.left,
                        clientY: (aNavBB.top - 31)
                    }, aText);
                });
            aNav.addEventListener('mouseleave', () => Op3dContext.TOOLTIP.hide());
        }
    }
    //__________________________________________________________________________________________
    private async _initSelectBars() {
        let aSections = await Op3dContext.DATA_MANAGER.getCategories();
        ViewUtils.removeElementChildren(this.mMainSectionsDD);

        for (let i = 0; i < aSections.length; i++) {
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aSections[i].name;
            // aItem.addEventListener('click', () => this._onSectionChanged(aSections[i]));
            aItem.addEventListener('click', () => this._onMainSectionChanged(aSections[i]));
            this.mMainSectionsDD.appendChild(aItem);
        }
    }
    //__________________________________________________________________________________________
    private _onMainSectionChanged(pSection: iSideBarMainSection) {
        ViewUtils.clearElementsChildren(this.mSectionsDD);
        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pSection.name;

        this.mMainSectionsSelect.innerHTML = aDiv.outerHTML + SideBar.ICON_CHEVRON_DOWN;
        this.mMainSectionsSelect.value = pSection.name;

        let aSections = pSection.sections;
        for (let i = 0; i < aSections.length; i++) {
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aSections[i].name;
            aItem.addEventListener('click', () => this._onSectionChanged(aSections[i]));
            this.mSectionsDD.appendChild(aItem);
        }

        let aSectionInputGroup = this.mSectionsSelect.parentElement.parentElement;
        let aHasSection = (pSection.sections.length > 1);
        ViewUtils.setElementVisibilityByDFlexDNone(aSectionInputGroup, aHasSection);

        this._onSectionChanged(pSection.sections[0]);
    }
    //__________________________________________________________________________________________
    private _onSectionChanged(pSection: iSideBarSection) {
        ViewUtils.clearElementsChildren(this.mCategoriesDD);
        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pSection.name;

        this.mSectionsSelect.innerHTML = aDiv.outerHTML + SideBar.ICON_CHEVRON_DOWN;
        this.mSectionsSelect.value = pSection.name;

        let aCategories = pSection.categories;
        for (let i = 0; i < aCategories.length; i++) {
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aCategories[i].name;
            aItem.addEventListener('click', () => this._onCategoryChanged(aCategories[i]));
            this.mCategoriesDD.appendChild(aItem);
        }

        let aCategoryInputGroup = this.mCategorySelect.parentElement.parentElement;
        let aHasCategories = (pSection.categories.length > 1);
        ViewUtils.setElementVisibilityByDFlexDNone(aCategoryInputGroup, aHasCategories);

        this._onCategoryChanged(pSection.categories[0]);
    }
    //__________________________________________________________________________________________
    private _onCategoryChanged(pCategory: iSideBarCategory) {
        ViewUtils.clearElementsChildren(this.mSubCategoriesDD);

        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pCategory.name;

        this.mCategorySelect.innerHTML = aDiv.outerHTML + SideBar.ICON_CHEVRON_DOWN;
        this.mCategorySelect.value = pCategory.name;

        let aSubCategories = pCategory.subcategories;
        for (let i = 0; i < aSubCategories.length; i++) {
            let aSubCategory = aSubCategories[i];
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aSubCategory;
            aItem.addEventListener('click', () => this._onSubcategoryChanged(aSubCategory));
            this.mSubCategoriesDD.appendChild(aItem);
        }

        let aSubCategoryInputGroup = this.mSubCategoriesDD.parentElement.parentElement;
        let aHasSubCategories = (pCategory.subcategories.length > 1);
        ViewUtils.setElementVisibilityByDFlexDNone(aSubCategoryInputGroup, aHasSubCategories);

        this._onSubcategoryChanged(pCategory.subcategories[0]);
    }
    //__________________________________________________________________________________________
    private _onSubcategoryChanged(pSubCategory: string) {
        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pSubCategory;

        this.mSubCategorySelect.innerHTML = aDiv.outerHTML + SideBar.ICON_CHEVRON_DOWN;
        this.mSubCategorySelect.value = pSubCategory;
        this.mSearchBarInput.value = '';

        this._onSearch();
    }
    //__________________________________________________________________________________________
    private async _onSearch() {
        await Op3dContext.DATA_MANAGER.getPartsData();

        let aData: iSideBarCategoryQuery = {
            main_section: this.mMainSectionsSelect.value,
            section: this.mSectionsSelect.value,
            category: this.mCategorySelect.value,
            subCategory: this.mSubCategorySelect.value,
            searchedWord: this.mSearchBarInput.value
        };

        Op3dContext.DATA_MANAGER.onFilterParts(aData);
    }
    //__________________________________________________________________________________________
    private async _initCategories() {
        let aMainSections = await Op3dContext.DATA_MANAGER.getCategories();
        // let aSection = aSections.find((section) => section.name.toLowerCase() == 'favorites')
        let aMainSection = aMainSections.find((section) => section.name.toLowerCase() == 'assembly')

        this._onMainSectionChanged(aMainSection);
        //await this._onSearch();
    }
    //__________________________________________________________________________________________
    public set sidebarState(pSidebarState: eSidebarState) {
        switch (pSidebarState) {
            case eSidebarState.SMALL:
                this.foldS();
                break;
            case eSidebarState.MEDIUM:
                this.foldM();
                break;
            case eSidebarState.LARGE:
                this.foldL();
                break;
            default:
                throw new Error("Invalid value");
        }
    }
    //__________________________________________________________________________________________
    public get sidebarState() {
        return this.mSidebarState;
    }
    //__________________________________________________________________________________________
    public foldL() {
        if (eSidebarState.LARGE == this.mSidebarState) {
            return;
        }

        $("#fold-l").addClass("active");
        $("#fold-s").removeClass("active");
        $("#fold-m").removeClass("active");
        $(".elements-bar").removeClass("elements-bar-out");
        $(".elements-bar").addClass("elements-bar-expand");

        let aModel = $(".model");
        aModel.removeClass("fold-s");
        aModel.removeClass("fold-m");
        aModel.addClass("fold-l");
        EventManager.dispatchEvent(EventsContext.WINDOW_RESIZE, this);
        //Op3dContext.DIV_CONROLLER.setModesContainerPosition(eSidebarState.LARGE);
        this.mSidebarState = eSidebarState.LARGE;

        SceneContext.OP3D_SCENE.activateRenderer();
        //TODO: qv impl eliav
        //views.QVDetectorChart.SMALL_QV_INITIAL_POSITION.x = SideBar.SIDE_BAR_WIDTH.large;
    }
    //__________________________________________________________________________________________
    public foldM() {
        if (eSidebarState.MEDIUM == this.mSidebarState) {
            return;
        }

        $('#fold-m').addClass("active");
        $("#fold-s").removeClass("active");
        $("#fold-l").removeClass("active");
        $(".elements-bar").removeClass("elements-bar-out");
        $(".elements-bar").removeClass("elements-bar-expand");

        let aModel = $(".model");
        aModel.addClass("fold-m");
        aModel.removeClass("fold-s");
        aModel.removeClass("fold-l");
        EventManager.dispatchEvent(EventsContext.WINDOW_RESIZE, this);
        this.mSidebarState = eSidebarState.MEDIUM;
        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public foldS() {
        if (eSidebarState.SMALL == this.mSidebarState) {
            return;
        }

        $('#fold-s').addClass("active");
        // $(this).addClass("active");
        $("#fold-m").removeClass("active");
        $("#fold-l").removeClass("active");
        $(".elements-bar").addClass("elements-bar-out");
        $(".elements-bar").removeClass("elements-bar-expand");

        let aModel = $(".model");
        aModel.addClass("fold-s");
        aModel.removeClass("fold-m");
        aModel.removeClass("fold-l");

        EventManager.dispatchEvent(EventsContext.WINDOW_RESIZE, this);
        //Op3dContext.DIV_CONROLLER.setModesContainerPosition(eSidebarState.SMALL);

        //TODO: qv impl
        //views.QVDetectorChart.SMALL_QV_INITIAL_POSITION.x = SideBar.SIDE_BAR_WIDTH.small;

        this.mSidebarState = eSidebarState.SMALL;

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    private _clearSearch() {
        this.mSearchBarInput.value = '';
        this._onSearch();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {

        this.mSearchBarInput.addEventListener("change", () => this._onSearch());
        this._getPart("sidebar_search_btn").addEventListener('click', () => this._onSearch());
        this._getPart("side_bar_search_x").addEventListener('click', () => this._clearSearch());

        this._getPart("section-select-menu").addEventListener('mouseleave', () => this._getPart("section-select-menu").classList.remove('show'));



        EventManager.addEventListener(EventsContext.ITEM_LOADED,
            (pData: EventBase<string>) => this._itemLoaded(pData.data), this);

        // EventManager.addEventListener(EventsContext.ON_REFRESH_SIDE_BAR,
        //     () => this._onRefreshSideBar(), this);

        EventManager.addEventListener(EventsContext.UPDATE_SIDE_BAR,
            (pData: EventBase<Array<GeneralVO>>) =>
                this._onUpdateSideBar(pData.data), this);

        EventManager.addEventListener(EventsContext.UPDATE_SIDE_BAR_PRIVATE,
            () => this.updatePrivateParts(), this);

        // EventManager.addEventListener(EventsContext.CUSTOM_PART_ADDED,
        //     () => this._updateForCustomParts(), this);


        EventManager.addEventListener(EventsContext.MODE_CHANGED,
            (pData: EventBase) => this._onModeChanged(pData.data), this);

        EventManager.addEventListener(EventsContext.UPDATE_SIDEBAR_PART_NAME,
            (pData: EventBase) => this._updateSidebarPartName(pData.data), this);
    }
    //__________________________________________________________________________________________
    public async updatePrivateParts() {
        if (this.mSectionsSelect.value.toLowerCase() == 'private') {
            await this._onSearch();
        }
    }
    //__________________________________________________________________________________________
    private _onModeChanged(pMode: eClickMode) {
        switch (pMode) {
            case eClickMode.LCS:
            case eClickMode.LCS_REF:
                break;
            default:
                break;
        }


    }
    //__________________________________________________________________________________________
    // private _hideSpinnerFromCell(pIndex: number) {
    //     (this.mPartsContainer.children[pIndex] as any).part_image.style.opacity = 1;
    //     (this.mPartsContainer.children[pIndex] as any).spinner.style.display = "none";
    // }
    //__________________________________________________________________________________________
    private _itemLoaded(_pUrl: string) {
        for (let i = 0; i < this.mPartsContainer.children.length; i++) {
            // let aCurrChild = this.mPartsContainer.children[i] as any;

            // if (aCurrChild.url === pUrl) {
            //     this._hideSpinnerFromCell(i);
            // }
        }
    }
    //__________________________________________________________________________________________
    private _convertFileToBase64viaFileReader(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (xhr.status === 200) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            } else {
                callback(defaultSideBarIMG);

            }
        };

        xhr.open('GET', url);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Access-Control-Allow-Credentials", 'true');
        xhr.send();

    }
    //__________________________________________________________________________________________
    private _updateSidebarPartName(pPartVO: PartVO) {
        let aNumberID = pPartVO.number_id;
        let aItems = this.mPartsContainer.children;
        for (let i = 0; i < aItems.length; i++) {
            let aItem = aItems[i] as iSidebarHTMLElement;
            if (aNumberID == aItem.number_id) {
                let aSidebarName = pPartVO.toStringForSideBar();
                let aNameDiv = Op3dUtils.getElementIn(aItem, 'part-name') as HTMLDivElement;
                aNameDiv.innerHTML = aSidebarName;
                break;
            }
        }
    }
    //__________________________________________________________________________________________
    private _createPartItem(pPartVO: GeneralVO): HTMLElement {

        let aClonePartItem = this.mSideBarItem.cloneNode(true) as iSidebarHTMLElement;
        aClonePartItem.number_id = pPartVO.number_id;

        aClonePartItem.part_image = Op3dUtils.getElementIn(aClonePartItem, 'part-img') as HTMLImageElement;
        // aClonePartItem.part_image.src = './images/3doptix.svg';

        let aImageURL = Op3dContext.IMAGES_HASH[pPartVO.id];
        if (null != aImageURL) {
            aClonePartItem.part_image.src = aImageURL;
            aClonePartItem.part_image.classList.add('img-responsive');
            aClonePartItem.classList.remove('pointer_events_none');
        } else {
            let aBase64 = (pPartVO.imageUrl + `?V=${Op3dUtils.idGenerator()}`);

            this._convertFileToBase64viaFileReader(aBase64, (imageURL: string) => {
                aClonePartItem.part_image.src = imageURL;
                aClonePartItem.part_image.classList.add('img-responsive');

                Op3dContext.IMAGES_HASH[pPartVO.id] = imageURL;
                aClonePartItem.classList.remove('pointer_events_none');
            });
        }

        let aSidebarName = pPartVO.toStringForSideBar();
        let aNameDiv = Op3dUtils.getElementIn(aClonePartItem, 'part-name') as HTMLDivElement;
        aNameDiv.innerHTML = aSidebarName;

        let aPartUrl = pPartVO.url;

        aClonePartItem.url = aPartUrl;
        aClonePartItem.type = pPartVO.objectType;

        let aIsPartLoaded = PartsCatalog.instance.isPartReady(aPartUrl);
        if (aIsPartLoaded || pPartVO.isCustom) {
            // for custom parts we have an different impl
            aClonePartItem.part_image.style.opacity = '1';
            //aClonePartItem.spinner.style.display = "none";
        }

        let aCustomPartsbuttons = Op3dUtils.getElementIn(aClonePartItem, "custom-part-buttons");
        if (eDataPermission.PRIVATE == pPartVO.permission) {

            let aDeleteBtn = Op3dUtils.getElementIn(aCustomPartsbuttons, "delete-custom-part");
            aDeleteBtn.addEventListener("mousedown",
                (e) => this._onDeletePrivatePart(e, pPartVO, aClonePartItem));

        } else {
            ViewUtils.removeFromParent(aCustomPartsbuttons);
        }

        aClonePartItem.addEventListener('mousedown',
            (e: MouseEvent | TouchEvent) => this._onPartMouseDown(e, pPartVO));

        aClonePartItem.addEventListener('mouseup',
            (e: MouseEvent | TouchEvent) => this._onPartMouseUp(e, pPartVO));


        return aClonePartItem;
    }
    //__________________________________________________________________________________________
    private async _onPartMouseUp(pEvent: MouseEvent | TouchEvent, _pPartVO: GeneralVO) {
        pEvent.stopPropagation()
        pEvent.preventDefault()

        Op3dContext.PARTS_MANAGER.unAddPart()
    }
    //__________________________________________________________________________________________
    private async _onPartMouseDown(pEvent: MouseEvent | TouchEvent, pPartVO: GeneralVO) {
        pEvent.preventDefault();

        if (pEvent instanceof MouseEvent && pEvent.button === 2) { return }

        let aClientEvent: iClientPoint = pEvent instanceof TouchEvent ? {
            clientX: pEvent.touches[0].clientX,
            clientY: pEvent.touches[0].clientY
        } : {
            clientX: pEvent.clientX,
            clientY: pEvent.clientY
        };

        let aData: iSideBarData = {
            event: aClientEvent,
            item: pPartVO
        };

        Op3dContext.PARTS_MANAGER.onSideBarMouseDown(aData)
        // EventManager.dispatchEvent(EventsContext.ON_PART_MOUSE_DOWN,
        //     this, aData);
    }
    //__________________________________________________________________________________________
    private _onDeletePrivatePart(e: Event, pPartVO: GeneralVO, pElement: HTMLElement) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        //TODO
        Popup.instance.open({
            text: MessagesHandler.DELETE_CUSTOM_PART_MSG,
            yesBtn: {
                title: 'Confirm',
                callback: () => this.deletePrivatePart(pPartVO, pElement)
            }
        });
    }
    //__________________________________________________________________________________________
    private async deletePrivatePart(pPartVO: GeneralVO, _pElement?: HTMLElement) {
        Spinner.instance.show();

        let aPartIsExisted = PartsDataLoader.instance.getFromCache(pPartVO.number_id, false)
        if (aPartIsExisted != null) {
            Op3dContext.SCENE_HISTORY.clearHistory()
        }
        let aQuery = {
            id: pPartVO.id,
            number_id: pPartVO.number_id,
        }
        let aPartInfo = await PartsDataLoader.instance.getSingleFullData({
            number_id: pPartVO.number_id
        }, false);

        if (aPartInfo.assembly_parts != null) {
            let aAssemblyParts = JSON.parse(aPartInfo.assembly_parts)

            for (let part of aAssemblyParts) {
                let aPartInfo = await PartsDataLoader.instance.getSingleFullData({
                    number_id: part.number_id
                }, false);
                let aQuery = {
                    id: aPartInfo.info.id,
                    number_id: part.number_id,
                }
                await ServerContext.SERVER.deleteCADPart(aQuery);
            }
        }
        try {
            if (aPartInfo.info.opticalData != null && aPartInfo.info.opticalData.materialID != null) {
                aQuery['prefix'] = 'GPU_'
            }
        } catch (e) {
        }
        let aRes = await ServerContext.SERVER.deleteCADPart(aQuery);

        if (true == aRes.success) {
            Op3dContext.USER_VO.cadData.current_usage = aRes.data;

            Op3dContext.DATA_MANAGER.removeFromPartsData(pPartVO.number_id);
            await this.updatePrivateParts();
            await Op3dContext.PARTS_MANAGER.deletePartsByID(pPartVO.id);
            Spinner.instance.hide();

            Popup.instance.open({
                text: MessagesHandler.PRIVATE_PART_DELETED_SUCCESS
            });

            Menu.instance.setCADProgressBar()

        } else {
            Spinner.instance.hide();
            Popup.instance.open({
                text: MessagesHandler.PRIVATE_PART_DELETED_ERROR
            });
        }
    }
    //__________________________________________________________________________________________
    private async _waitForReady() {
        while (!this.mActiveParts || this.mPartsContainer == null) {
            await Op3dContext.sleep(50);
        }

        return;
    }
    //__________________________________________________________________________________________
    private async _loadPartItems(pPartsToLoad: Array<GeneralVO>) {
        await this._waitForReady();

        this.mActiveParts = new Array<iSideBarActivePartData>();
        ViewUtils.clearElementsChildren(this.mPartsContainer);

        for (let i = 0; i < pPartsToLoad.length; i++) {
            if (pPartsToLoad[i].id === "G0001") {
                continue
            }

            let aCurrItemData: GeneralVO = pPartsToLoad[i];

            // if (!aCurrItemData.isCustom ) {
            //     continue;
            // }

            let aIsAdminPart = aCurrItemData.isAdminPart;
            let aIsAdminUser = Op3dContext.USER_VO.isAdmin;
            if (aIsAdminPart && !aIsAdminUser) {
                continue;
            }

            let aCurrItemToAdd = this._createPartItem(aCurrItemData);
            this.mPartsContainer.appendChild(aCurrItemToAdd);
            this.mActiveParts.push({
                url: aCurrItemData.url,
                type: aCurrItemData.objectType,
                isCustom: aCurrItemData.isCustom
            });
        }
    }
    //__________________________________________________________________________________________
    private async _onUpdateSideBar(pParts: Array<GeneralVO>) {
        if (pParts) {
            await this._loadPartItems(pParts);
        }
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mMainSectionsSelect = this._getPart('main-sections-select') as iHTMLDropdownElement;
        this.mSectionsSelect = this._getPart('sections-select') as iHTMLDropdownElement;
        this.mCategorySelect = this._getPart('category-select') as iHTMLDropdownElement;
        this.mSubCategorySelect = this._getPart('subCategory-select') as iHTMLDropdownElement;
        this.mDropdownTemplate = this._getPart('dropdown-item');
        this.mMainSectionsDD = this._getPart('main-section-select-menu');
        this.mSectionsDD = this._getPart('section-select-menu');
        this.mCategoriesDD = this._getPart('category-select-menu');
        this.mSubCategoriesDD = this._getPart('subCategory-select-menu');
        this.mSearchBarInput = this._getPart('sidebar_search_input') as HTMLInputElement;
        this.mPartsContainer = this._getPart('parts-container');
        this.mSideBarItem = document.getElementById('part-item') as HTMLDivElement;
        this.mPartsContainer.removeChild(this.mSideBarItem);
    }
    //__________________________________________________________________________________________
    protected async _onNew() {
        let aMainSections = await Op3dContext.DATA_MANAGER.getCategories();
        let aMainSection = aMainSections.find((section) => section.name.toLowerCase() == 'assembly')

        this._onMainSectionChanged(aMainSection);
    }
    //__________________________________________________________________________________________
}
