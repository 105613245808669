

import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iMinMax } from "../../_context/_interfaces/Interfaces";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { ContainerLoader } from "../tools/ContainerLoader";


export interface iNumberInputElementParams {
    callback?: (pValue: number, pFromArrowKey?: boolean) => void;
    range?: iMinMax;
    step?: number;
    startValue?: number;
    factor?: number;
    mod?: number;
    isInt?: boolean;
};

export abstract class PartInfoSection<T = any> {

    protected mContainer: HTMLElement;
    protected mIsReady: boolean = false;
    protected mData!: T;
    protected mThumb!: HTMLElement;
    private mShowFunc!: EventListener;
    private mHideFunc!: EventListener;
    private mResizeFunc!: EventListener;
    private mPIBody!: HTMLElement;

    //__________________________________________________________________________________________
    protected constructor(pParams: { skinPath: string, container: HTMLElement }) {
        this.mContainer = pParams.container;

        new ContainerLoader(pParams.skinPath, pParams.container,
            () => this._creationComplete());
    }
    //__________________________________________________________________________________________
    public set data(pData: T) {
        this.mData = pData;

        if (true == this.isShown()) {
            this.update();
        }
    }
    //__________________________________________________________________________________________
    public isShown() {
        return ViewUtils.isElementActive(this.mContainer.parentElement);
    }
    //__________________________________________________________________________________________
    public isVisible() {
        return (false == ViewUtils.isDnone(this.mContainer.parentElement));
    }
    //__________________________________________________________________________________________
    public async setParentVisibility(pToShow: boolean) {
        await Op3dContext.wait(() => (true == this.mIsReady));
        let aParent = this.mContainer.parentElement;
        if (aParent === null) {
            throw new Error("Parent is null");
        }

        if (true == pToShow) {
            window.addEventListener('resize', this.mResizeFunc);
            aParent.addEventListener('show_pi', this.mShowFunc);
            aParent.addEventListener('hide_pi', this.mHideFunc);
        } else {
            window.removeEventListener('resize', this.mResizeFunc);
            aParent.removeEventListener('show_pi', this.mShowFunc);
            aParent.removeEventListener('hide_pi', this.mHideFunc);
        }

        ViewUtils.setElementVisibilityByDNone(aParent, pToShow);
    }
    //__________________________________________________________________________________________
    public close() {
        if (false == ViewUtils.isElementActive(this.mContainer.parentElement)) {
            return;
        }

        this.setParentVisibility(false);
        this.hide();
    }
    //__________________________________________________________________________________________
    public async update() {
        await Op3dContext.wait(() => this.mIsReady);

        //let aData = this._getData();
        this._fillSection(this.mData);
    }
    //__________________________________________________________________________________________
    public unPin() {
        ViewUtils.setElementActive(this.mThumb, false);
    }
    //__________________________________________________________________________________________
    private async _creationComplete() {
        let aSizeFunc = () => {
            if (null != this.mPIBody) {
                let aBB = this.mPIBody.getBoundingClientRect();
                this.mPIBody.style.maxHeight = (window.innerHeight - aBB.top - 30) + 'px';
            }
        };

        this.mPIBody = this.mContainer.getElementsByClassName('pi_body')[0] as HTMLElement;

        this.mResizeFunc = () => aSizeFunc();
        this.mShowFunc = () => {
            this.show();
            aSizeFunc();
        };

        this.mHideFunc = () => this.hide();
        this._initThumb();

        $(this.mContainer).find('.premium-feature').each((_index, element) => {
            if (document.body.classList.contains(Strings.BASIC_LICENSE)) {
                (element as HTMLElement).onclick = () => window.open('https://3doptix.com/pricing/', '_blank');
            }
        });

        this._initElements();
        this._onCreationComplete();
    }
    //__________________________________________________________________________________________
    private _initThumb() {
        this.mThumb = this.mContainer.getElementsByClassName('pi_thumb')[0] as HTMLElement;
        if (null == this.mThumb) {
            return;
        }

        this.mThumb.addEventListener('click', () => this._onClickThumb());
    }
    //__________________________________________________________________________________________
    private _onClickThumb() {
        let aIsActive = ViewUtils.toggleElementActive(this.mThumb);
        let aThumbSection = (true == aIsActive) ? this : null;
        Op3dContext.PART_INFO.setPinSection(aThumbSection);
    }
    //__________________________________________________________________________________________
    protected _onHide() { }
    //__________________________________________________________________________________________
    protected _onCreationComplete() {
        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _getPart(pId: string, pToChangeId: boolean = false) {
        let aElement = $(this.mContainer).find('#' + pId).get(0);

        if (true == pToChangeId && aElement != null) {
            aElement.id += Op3dComponentBase.ID_ITERATOR;
            Op3dComponentBase.ID_ITERATOR++;
        }

        return aElement;
    }
    //__________________________________________________________________________________________
    protected abstract _initElements(): void;
    //__________________________________________________________________________________________
    protected async _prepareForm() {
        while (!this.mIsReady) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
    protected abstract _fillSection(pData: T): void;
    //__________________________________________________________________________________________
    public async show(pUpdate: boolean = true) {
        ViewUtils.setElementActive(this.mContainer.parentElement, true);

        if (null != this.mPIBody) {
            let aBB = this.mPIBody.getBoundingClientRect();
            this.mPIBody.style.maxHeight = (window.innerHeight - aBB.top - 30) + 'px';
        }

        if (pUpdate) {
            this.update();
        }



    }
    //__________________________________________________________________________________________
    public hide(): void {
        ViewUtils.setElementActive(this.mContainer.parentElement, false);
        this._onHide();
    }
    //__________________________________________________________________________________________
    public setVisibility(pToShow: boolean) {
        if (true == pToShow) {
            this.show();
        } else {
            this.hide();
        }

        ViewUtils.setElementVisibilityByDNone(this.mContainer, pToShow);
    }
    //__________________________________________________________________________________________
    public get isReady() {
        return this.mIsReady;
    }
    //__________________________________________________________________________________________
}
