import OptiChatMenuOptions from "./OptiChatMenuOptions";
import optiChatLogo from "../../../../../public/images/icons_new/chatbot/ai_avatar.svg";
import classes from "../../UI/optichat-menu.module.scss";
import { iOptiChatMenuOptionExample } from "../../store/OptiChatContext";

export default function OptiChatMenu(props: {
  isDisabled: boolean;
  options: Array<iOptiChatMenuOptionExample>;
}) {
  return (
    <div
      className={`${props.isDisabled ? classes.disabled : ""} ${
        classes["optichat-menu"]
      }`}
    >
      <div className={classes.header}>
        <div className={classes.avatar}>
          <img src={optiChatLogo} />
        </div>
        <div className={classes.title}>OptiChat™</div>
      </div>
      <OptiChatMenuOptions options={props.options} />
    </div>
  );
}
