import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { ServerContext } from "../../server/ServerContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";

export class ShareLink extends Op3dComponentBase {

    private static INSTANCE: ShareLink;

    mGenerateLinkBtn: HTMLInputElement;
    mGeneratedLink: HTMLInputElement;
    mLinkBlock: HTMLInputElement;
    mLinkList: HTMLInputElement;
    mDisableSharing: HTMLInputElement;
    mEnableSharing: HTMLInputElement;
    mLink: HTMLInputElement;
    mGenerateLinkIframe: HTMLInputElement;
    mInputWidth: HTMLInputElement;
    mInputHeight: HTMLInputElement;
    mLinkIframe: HTMLInputElement;
    mIframeBlock: HTMLInputElement;
    mShareBtn: HTMLInputElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/share_link_form.html'
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ShareLink.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            document.getElementById('forms').appendChild(aDiv);

            ShareLink.INSTANCE = new ShareLink(aDiv);
        }

        return ShareLink.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _onOpen() {
        this.updateSharableState()
        this.initLinkField()
        this.generateLink()
    }
    //__________________________________________________________________________________________
    private updateSharableState() {
        if (Op3dContext.USER_PERMISSION.isFreeUser === true) {
            this.enableSharing(true)
            ViewUtils.setElementDisabled(this.mShareBtn, true);

        } else {
            if (Op3dContext.SETUPS_MANAGER.setupParameters.isShareable !== true) {

                ViewUtils.setElementVisibilityByDNone(this.mDisableSharing, false)
                ViewUtils.setElementVisibilityByDNone(this.mEnableSharing, true)
            } else {
                ViewUtils.setElementVisibilityByDNone(this.mDisableSharing, true)
                ViewUtils.setElementVisibilityByDNone(this.mEnableSharing, false)
            }


        }
    }
    //__________________________________________________________________________________________

    protected _initElements(): void {
        this.mDisableSharing = this._getPart('disable_shareing') as HTMLInputElement;
        this.mLink = this._getPart('link') as HTMLInputElement;
        this.mShareBtn = this._getPart('share_btn') as HTMLInputElement;
        this.mEnableSharing = this._getPart('enable_shareing') as HTMLInputElement;

        // this.mGenerateLinkBtn = this._getPart('generate_link') as HTMLInputElement;
        this.mIframeBlock = this._getPart('admin_iframe_link') as HTMLInputElement;

        this.mDisableSharing.addEventListener('click', () => this.enableSharing(false))
        this.mEnableSharing.addEventListener('click', () => this.enableSharing(true))
        this.updateSharableState()

        this.mGenerateLinkIframe = this._getPart('generate_link_iframe') as HTMLInputElement;
        this.mLinkIframe = this._getPart('link_iframe') as HTMLInputElement;
        this.mInputWidth = this._getPart('width_iframe') as HTMLInputElement;
        this.mInputHeight = this._getPart('height_iframe') as HTMLInputElement;
        this.mGenerateLinkIframe.addEventListener('click', () => this.generateLinkIframe())

        // this.mGenerateLinkBtn.addEventListener('click', () => this.generateLink())

        this.initLinkField()

    }
    //__________________________________________________________________________________________
    private enableSharing(pIsEnable) {
        Op3dContext.SETUPS_MANAGER.setupParameters.isShareable = pIsEnable;
        Op3dContext.SCENE_HISTORY.saveScene(true, true)

        if (pIsEnable == true) {
            ViewUtils.setElementVisibilityByDNone(this.mDisableSharing, true)
            ViewUtils.setElementVisibilityByDNone(this.mEnableSharing, false)
        } else {
            ViewUtils.setElementVisibilityByDNone(this.mDisableSharing, false)
            ViewUtils.setElementVisibilityByDNone(this.mEnableSharing, true)
        }
    }
    //__________________________________________________________________________________________
    private initLinkField() {
        let aEncodedLink = window.btoa(Op3dContext.SETUPS_MANAGER.getSetupData()._id)
        this.mLink.value = ServerContext.base_link + "/widget.html?id=" + aEncodedLink;
    }
    //__________________________________________________________________________________________

    private generateLinkIframe() {

        this.mLinkIframe.innerHTML = `<iframe id='3doptix-iframe' width='${this.mInputWidth.value}' height='${this.mInputHeight.value}' src='${this.mLink.value}'></iframe>`
        navigator.clipboard.writeText(this.mLinkIframe.innerHTML).then(() => {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.COPIED_TO_CLIPBOARD,
                params: NotificationCenter.NOTIFICATIONS_TYPES.CONSTRUCTION_COMMENT,
            });
        });
    }
    //__________________________________________________________________________________________
    private generateLink() {
        navigator.clipboard.writeText(this.mLink.value).then(() => {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.COPIED_TO_CLIPBOARD,
                params: NotificationCenter.NOTIFICATIONS_TYPES.CONSTRUCTION_COMMENT,
            });
        });

    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
}
