import { Op3dContext } from "../../../_context/Op3dContext";

export interface iOP3DTableData {
    titles: Array<{ name: string; flexGrow?: number }>;
    data: Array<Array<string>>;
};

export class OP3DTable {

    private mContainer: HTMLElement;

    //______________________________________________________________________________________________
    constructor(pContainer: HTMLElement, pData?: iOP3DTableData) {
        this.mContainer = pContainer;

        if (undefined !== pData) {
            this.set(pData);
        }
    }
    //______________________________________________________________________________________________
    private _clear() {
        this.mContainer.innerHTML = '';
    }
    //______________________________________________________________________________________________
    public set(pData: iOP3DTableData) {
        this._clear();

        let aDiv = document.createElement('div');
        aDiv.classList.add('c_an_table');

        let aHeader = document.createElement('div');
        aHeader.classList.add('c_table_header_row');
        aDiv.appendChild(aHeader);

        let aTitles = pData.titles;
        for (let i = 0, l = aTitles.length; i < l; i++) {
            let aFlexGrow = (null != aTitles[i].flexGrow) ? aTitles[i].flexGrow : 1;
            let aHeaderItem = document.createElement('div');
            aHeaderItem.classList.add('c_table_header_row_item');
            aHeaderItem.style.flexGrow = aFlexGrow.toString();

            let aTextItem = document.createElement('div');
            aTextItem.classList.add('c_table_text_item');
            aTextItem.innerHTML = aTitles[i].name;
            aHeaderItem.appendChild(aTextItem);

            aHeaderItem.addEventListener('mouseenter', (e) => {
                let aTimeout = setTimeout(() => Op3dContext.TOOLTIP.show(e, aTitles[i].name), 500);
                let aMouseOutEvent = () => {
                    clearTimeout(aTimeout);
                    aHeaderItem.removeEventListener('mouseleave', aMouseOutEvent);
                };

                aHeaderItem.addEventListener('mouseleave', aMouseOutEvent);
            });

            aHeader.appendChild(aHeaderItem);
        }

        let aTableBody = document.createElement('div');
        aTableBody.classList.add('c_table_body');
        aDiv.appendChild(aTableBody);

        let aData = pData.data;
        for (let i = 0, l = aData.length; i < l; i++) {
            let aTableBodyRow = document.createElement('div');
            aTableBodyRow.classList.add('c_table_body_row');
            aTableBody.appendChild(aTableBodyRow);

            let aCurrRow = aData[i];
            for (let j = 0, lj = aTitles.length; j < lj; j++) {
                let aFlexGrow = (null != aTitles[j].flexGrow) ? aTitles[j].flexGrow : 1;

                let aBodyItem = document.createElement('div');
                aBodyItem.classList.add('c_table_body_row_item');
                aBodyItem.style.flexGrow = aFlexGrow.toString();

                let aTextItem = document.createElement('div');
                aTextItem.classList.add('c_table_text_item');
                aTextItem.innerHTML = (null != aCurrRow[j]) ? aCurrRow[j] : '';
                aBodyItem.appendChild(aTextItem);

                if (null != aCurrRow[j]) {
                    aBodyItem.addEventListener('mouseenter', (e) => {
                        let aTimeout = setTimeout(() =>
                            Op3dContext.TOOLTIP.show(e, aCurrRow[j]), 500);
                        let aMouseOutEvent = () => {
                            Op3dContext.TOOLTIP.hide();
                            clearTimeout(aTimeout);
                            aBodyItem.removeEventListener('mouseleave', aMouseOutEvent);
                        };

                        aBodyItem.addEventListener('mouseleave', aMouseOutEvent);
                    });
                }


                aTableBodyRow.appendChild(aBodyItem);
            }
        }

        this.mContainer.appendChild(aDiv);
    }
    //______________________________________________________________________________________________
}