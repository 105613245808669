import { MessagesHandler } from "../_context/MessagesHandler";
import { iRayDataFile } from "./RaysDisplayer";

export class LaserRay {

    public show: boolean;
    private mRayData: iRayDataFile;

    constructor(pRay: iRayDataFile) {
        this._initData(pRay);
        this.show = true;
    }
    //__________________________________________________________________________________________
    private _initData(pData: iRayDataFile) {
        try {
            if (null == pData.exit) {
                throw new Error("Invalid exit point");
            }

            if (null == pData.direction) {
                throw new Error("Invalid hit point");
            }

            if ((null == pData.color) || (true == isNaN(pData.color)) || (pData.color < 0) ||
                (pData.color > 0xffffff)) {
                throw new Error("Invalid color");
            }

            if ((null == pData.relative_intensity) || (pData.relative_intensity < 0) || (pData.relative_intensity > 1)) {
                throw new Error("Invalid intensity");
            }

            this.mRayData = pData;
            return true;

        } catch (error) {
            MessagesHandler.ON_ERROR_PROGRAM(error as any);
            return false;
        }
    }
    //__________________________________________________________________________________________
    public toggleLight() {
        this.show = !this.show;
        return this.show;
    }
    //___________________________________________________________________________________
    public get rayData() {
        return this.mRayData;
    }
    //__________________________________________________________________________________________

}