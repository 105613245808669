import { WebGLRenderer } from "three";
import { Op3dContext } from "../_context/Op3dContext";
import { SceneContext } from "../scene/SceneContext";
import { eViewTypeXYZ } from "../scene/absSceneCube";
import { eClickMode } from "../ui/_globals/PartsEventsHandler";
import { tMimeType } from "./FileUtils";
import { PDFImageGenerator } from "./PDFGenerator";
import { eImageFormat, eImageQuality } from "../ui/tools/ImageQualityForm";
import { Spinner } from "../ui/home/Spinner";
import { CameraUtils } from "../scene/_camera/CameraUtils";


export class SnapshotTools {

    private static SNAPSHOT_RESOLUTION = {
        width: 1920,
        height: 1080
    };

    private static QUALITY = [1280, 1920, 2560, 4096];

    //__________________________________________________________________________________________
    /**
     * @returns snapshot of the canvas as base64 format
     */
    //__________________________________________________________________________________________
    public static downloadImage(pImageType: eImageFormat, pImageQuality: eImageQuality, pName: string) {
        Spinner.instance.show();

        let aRenderer = new WebGLRenderer();
        let aDomElement = SceneContext.RENDERER.domElement;
        let aScale = (SnapshotTools.QUALITY[pImageQuality] / aDomElement.width);

        let aW = (aDomElement.width * aScale);
        let aH = (aDomElement.height * aScale);
        aRenderer.setSize(aW, aH);
        aRenderer.setClearColor(Op3dContext.USER_VO.simulationSettings.sceneBGColor, 1);
        aRenderer.render(SceneContext.MAIN_SCENE, SceneContext.CAMERA);

        const aCanvas = document.createElement("canvas");
        aCanvas.width = aW;
        aCanvas.height = aH;

        const aCtx = aCanvas.getContext("2d");
        if (SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW) {
            aCtx.drawImage(SceneContext.RENDERER.domElement, 0, 0, aCanvas.width / 2, aCanvas.height / 2);
            aCtx.drawImage(SceneContext.RENDERER2.domElement, aCanvas.width / 2, 0, aCanvas.width / 2, aCanvas.height / 2);
            aCtx.drawImage(SceneContext.RENDERER3.domElement, 0, aCanvas.height / 2, aCanvas.width / 2, aCanvas.height / 2);
            aCtx.drawImage(SceneContext.RENDERER4.domElement, aCanvas.width / 2, aCanvas.height / 2, aCanvas.width / 2, aCanvas.height / 2);
        } else {
            aCtx.drawImage(aRenderer.domElement, 0, 0, aCanvas.width, aCanvas.height);
        }


        let aLogoImg = document.getElementById("logo-img") as HTMLImageElement;
        aCtx.drawImage(aLogoImg, 20, 20, aLogoImg.width, aLogoImg.height);

        switch (pImageType) {
            case eImageFormat.PDF:
                let aImageBase64 = aCanvas.toDataURL('image/jpg')
                PDFImageGenerator.saveImage(aImageBase64, true, aW, aH, pName);
                break;
            case eImageFormat.JPEG:
                SnapshotTools.downloadImageFromCanvas({
                    mimeType: "image/jpg",
                    canvas: aCanvas, name: pName
                })
                break;
        }

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    public static getSimpleSnapshot(pIsPDF: boolean) {

        const aCanvas = document.createElement("canvas");
        const aWidth = SceneContext.RENDERER.domElement.width;
        const aHeight = SceneContext.RENDERER.domElement.height;
        const aCtx = aCanvas.getContext("2d");

        aCanvas.width = aWidth;
        aCanvas.height = aHeight;

        aCtx.drawImage(SceneContext.RENDERER.domElement, 0, 0,
            aCanvas.width,
            aCanvas.height);

        let aLogoImg = document.getElementById("logo-img") as HTMLImageElement;
        aCtx.drawImage(aLogoImg, 20, 20, aLogoImg.width, aLogoImg.height);

        if (pIsPDF) {
            let aImageBase64 = aCanvas.toDataURL('image/jpg')
            PDFImageGenerator.saveImage(aImageBase64, true, aWidth, aHeight, 'test');
        } else {
            SnapshotTools.downloadImageFromCanvas({
                mimeType: "image/jpg",
                canvas: aCanvas, name: 'test'
            })
        }
    }
    //__________________________________________________________________________________________
    public static getSceneSnapshot2(pWithFitToZoom: boolean) {
        if (undefined === SceneContext.SNAPSHOT_RENDERER) {
            SceneContext.SNAPSHOT_CAMERA = SceneContext.CAMERA.clone();
            SceneContext.SNAPSHOT_RENDERER = new WebGLRenderer({
                preserveDrawingBuffer: true
            });
        }

        const aCanvas = document.createElement("canvas");
        let aCtx = aCanvas.getContext("2d");
        const aWidth = this.SNAPSHOT_RESOLUTION.width;
        const aHeight = this.SNAPSHOT_RESOLUTION.height;
        aCanvas.width = aWidth;
        aCanvas.height = aHeight;

        let aSceneColor = Op3dContext.USER_VO.simulationSettings.sceneBGColor;
        SceneContext.SNAPSHOT_RENDERER.setSize(aCanvas.width, aCanvas.height);
        SceneContext.SNAPSHOT_RENDERER.setClearColor(aSceneColor, 1.0);

        if (pWithFitToZoom) {
            SceneContext.SNAPSHOT_CAMERA.position.set(-1500, 675, -1500);
            SceneContext.SNAPSHOT_CAMERA.lookAt(Op3dContext.PARTS_MANAGER.getCenter());
            CameraUtils.fitCameraToCenteredObject(
                SceneContext.SNAPSHOT_CAMERA,
                Op3dContext.PARTS_MANAGER.partsContainer, eViewTypeXYZ.ISO_VIEW, true);
        }


        let aSelectedPart = Op3dContext.PARTS_MANAGER.selectedPart;
        if (null != aSelectedPart && SceneContext.CHOOSE_MODE.mode == eClickMode.BASE) {
            // OptixPartDisplayer.unHighlightObject(aSelectedPart);
            aSelectedPart.unHighlightObject();
        }

        SceneContext.SNAPSHOT_RENDERER.render(SceneContext.MAIN_SCENE,
            SceneContext.SNAPSHOT_CAMERA);

        let aFrustumScale = 3;
        SceneContext.SNAPSHOT_CAMERA.left = -aWidth / aFrustumScale
        SceneContext.SNAPSHOT_CAMERA.right = aWidth / aFrustumScale
        SceneContext.SNAPSHOT_CAMERA.top = aHeight / aFrustumScale
        SceneContext.SNAPSHOT_CAMERA.bottom = -aHeight / aFrustumScale
        SceneContext.SNAPSHOT_CAMERA.updateProjectionMatrix();
        SceneContext.SNAPSHOT_RENDERER.setSize(aWidth, aHeight);
        SceneContext.SNAPSHOT_RENDERER.render(SceneContext.MAIN_SCENE,
            SceneContext.SNAPSHOT_CAMERA);

        let aDomElement = SceneContext.SNAPSHOT_RENDERER.domElement;
        aCtx.drawImage(aDomElement, 0, 0, aCanvas.width, aCanvas.height);

        if (null != aSelectedPart && SceneContext.CHOOSE_MODE.mode == eClickMode.BASE) {
            // OptixPartDisplayer.highlightObject(aSelectedPart);
            aSelectedPart.highlightObject();
        }
        // this.downloadImageFromCanvas({
        //     canvas: aCtx.canvas,
        //     mimeType: "image/png",
        //     name: "snapshot"
        // });
        return aCtx.canvas.toDataURL('image/webp', 0.1);
    }
    //__________________________________________________________________________________________
    public static downloadImageFromCanvas(pParams: { mimeType: tMimeType, name: string, canvas: HTMLCanvasElement }) {
        let aAnchor = document.createElement("a");
        aAnchor.href = pParams.canvas.toDataURL(pParams.mimeType);
        aAnchor.download = pParams.name + "." + pParams.mimeType.split("/")[1];
        aAnchor.click();
    }
    //__________________________________________________________________________________________

}
