import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Op3dComponentBase } from "../Op3dComponentBase";

export interface iCustomProgressBarElement extends HTMLElement {
    min: number;
    max: number;
    currentValue: number;
}

export interface iProggressBarParams {
    /**
     * Min value of the proggress bar. Default - 0.
     */
    min?: number;

    /**
     * Max value of the proggress bar.
     */
    max: number;

    /**
     * Initial Value of the proggress bar. Default - 0.
     */
    initialValue?: number;

    /**
     * @description Title of the proggress bar form.
     */
    title: string;

    /**
     * @description Subtitle of the proggress bar form.
     */
    subtitle?: string;

    /**
     * @description If true - the progress bar will close on 100%
     * @default - false.
     */
    closeOnFinish?: boolean;
}

export class ProgressBar extends Op3dComponentBase {

    private static INSTANCE: ProgressBar;

    private mProgressBar: iCustomProgressBarElement;
    private mTitle: HTMLElement;
    private mSubTitle: HTMLElement;
    private mDetails: HTMLElement;
    private mCloseOnFinish: boolean;

    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: "./skins/tools/progress_bar.html"
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == ProgressBar.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            // aDiv.classList.add('fade');
            aDiv.style.zIndex = '1050';

            document.getElementById('forms').appendChild(aDiv);
            ProgressBar.INSTANCE = new ProgressBar(aDiv);
        }

        return ProgressBar.INSTANCE;
    }
    //__________________________________________________________________________________________
    /**
     * @description Update the value of the progress bar.
     */
    //__________________________________________________________________________________________
    public async update(pValue: number) {
        await Op3dContext.wait(() => { return this.mIsReady; });
        this.mProgressBar.currentValue = pValue;

        let aRange = Math.max(1, (this.mProgressBar.max - this.mProgressBar.min));
        let aPrecent = (100 * (pValue / aRange));
        this.mProgressBar.setAttribute('aria-valuenow', aPrecent.toFixed());
        this.mProgressBar.style.width = aPrecent + '%';
    }
    //__________________________________________________________________________________________
    public async updateDetails(pDetails: string) {
        await Op3dContext.wait(() => { return this.mIsReady; });

        this.mDetails.innerHTML = pDetails;
    }
    //__________________________________________________________________________________________
    /**
     * @description increment the count by one
     */
    //__________________________________________________________________________________________
    public async increment() {
        await Op3dContext.wait(() => { return this.mIsReady; });
        this.update(this.mProgressBar.currentValue + 1);
        this._onTransitionEnd()
    }
    //__________________________________________________________________________________________
    /**
     * @description Opens the data of the proggress bar.
     * @param max - Max value of the proggress bar.
     * @param initialValue - Initial value of the proggress bar.
     * @param title - Title of the progress bar form..
     */

    //__________________________________________________________________________________________
    public async close() {
        await this._prepareForm();
        this._show(false);
    }
    //__________________________________________________________________________________________
    private _show(pVal: boolean) {
        if (true == pVal) {
            $(this.mContainer).modal('show');
        } else {
            $(this.mContainer).modal('hide');
        }
    }
    //__________________________________________________________________________________________
    protected _onOpen(pProggressBarParams: iProggressBarParams) {
        try {
            this.mProgressBar.min = (null != pProggressBarParams.min) ?
                pProggressBarParams.min : 0;
            this.mProgressBar.max = pProggressBarParams.max;
            this.mDetails.innerHTML = '';

            this.mTitle.innerHTML = pProggressBarParams.title;
            this.mSubTitle.innerHTML = (null != pProggressBarParams.subtitle) ?
                pProggressBarParams.subtitle : '';
            this.mProgressBar.setAttribute('aria-valuemin', this.mProgressBar.min.toString());
            this.mProgressBar.setAttribute('aria-valuemax', this.mProgressBar.max.toString());

            this.mCloseOnFinish = (null != pProggressBarParams.closeOnFinish) ?
                pProggressBarParams.closeOnFinish : false;

            let aInitialValue = (null != pProggressBarParams.initialValue) ?
                pProggressBarParams.initialValue : this.mProgressBar.min;
            this.update(aInitialValue);

        } catch (error) {
            MessagesHandler.ON_ERROR_PROGRAM(error as any);
        }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this._init();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    private _init() {
        this.mProgressBar = this._getPart('progress_bar') as iCustomProgressBarElement;
        this.mTitle = this._getPart('title');
        this.mSubTitle = this._getPart('sub_title');
        this.mDetails = this._getPart('details');
        this.mProgressBar.addEventListener('transitionend', () => this._onTransitionEnd());
    }
    //__________________________________________________________________________________________
    private _onTransitionEnd() {
        if (false == this.mCloseOnFinish) {
            return;
        }

        if (this.mProgressBar.currentValue == this.mProgressBar.max) {
            this.close();
        }
    }
    //__________________________________________________________________________________________

}
