import { AnalyticsEventsContext } from "../../../_context/AnalyticsEventsContext";
import {
  eDataPermission,
  eIngredientType,
  eTextTransform,
  eUnitType,
} from "../../../_context/Enums";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { OpticsContext, eBaseShape } from "../../../_context/OpticsContext";
import {
  iElasticQuery,
  iHash,
  iMLSearchData,
} from "../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../../data/data_loader/OpticsDataLoader";
import { ServerContext } from "../../../server/ServerContext";
import { Popup } from "../../forms/Popup";
import { Spinner } from "../../home/Spinner";
import { MenuListAbstract } from "./MenuListAbstract";
import {
  iOpticsMenuOpen,
  eMenuListColumnsDataType,
  iMenuListColumn,
} from "./MenusContext";
import {
  eOMFilterName,
  OpticsFiltersHandler,
} from "./_filterHandlers/OpticsFilterHandler";
import autoComplete from "@tarekraafat/autocomplete.js";
import { Filter } from "./filters/Filter";

export class OpticsMenu extends MenuListAbstract<iOpticsVO, iOpticsMenuOpen> {
  private static INSTANCE: OpticsMenu;
  private pFromPart: boolean;
  //__________________________________________________________________________________________
  private constructor(pParams: { skinPath: string; container: HTMLElement }) {
    super(
      {
        skinPath: pParams.skinPath,
        container: pParams.container,
      },
      {
        isHelpMeSearchBtnExits: true,
        title: "PRODUCT CATALOG",
        category: "Optics",
        limit: 50,
        dataLoader: OpticsDataLoader.instance,
        columns: [
          {
            name: "Name",
            serverPath: "name",
            sortName: "name",
            showOnStart: true,
            capitalize: true,
            static_col: true,
            width: 600,
          },
          {
            name: "Type",
            serverPath: eOMFilterName.type,
            sortName: eOMFilterName.type,
            showOnStart: true,
            width: 200,
          },
          {
            name: "SubType",
            serverPath: eOMFilterName.subType,
            sortName: eOMFilterName.subType,
            showOnStart: true,
            width: 300,
          },
          {
            name: "Brand",
            serverPath: eOMFilterName.brand,
            sortName: eOMFilterName.brand,
            showOnStart: true,
            width: 150,
          },
          {
            name: "Material Name",
            serverPath: eOMFilterName.materialID,
            sortName: eOMFilterName.materialID,
            showOnStart: true,
            translateFunction: (pNumberID: string) =>
              this._getMaterialName(pNumberID),
            width: 200,
          },
          {
            name: "Diameter",
            serverPath: eOMFilterName.diameter,
            sortName: eOMFilterName.diameter,
            showOnStart: true,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "Width",
            serverPath: eOMFilterName.width,
            sortName: eOMFilterName.width,
            showOnStart: true,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "Height",
            serverPath: eOMFilterName.height,
            sortName: eOMFilterName.height,
            showOnStart: true,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "a",
            serverPath: eOMFilterName.a,
            sortName: eOMFilterName.a,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "b",
            serverPath: eOMFilterName.b,
            sortName: eOMFilterName.b,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "c",
            serverPath: eOMFilterName.c,
            sortName: eOMFilterName.c,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "d",
            serverPath: eOMFilterName.d,
            sortName: eOMFilterName.d,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "e",
            serverPath: eOMFilterName.e,
            sortName: eOMFilterName.e,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "f",
            serverPath: eOMFilterName.f,
            sortName: eOMFilterName.f,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "g",
            serverPath: eOMFilterName.g,
            sortName: eOMFilterName.g,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "h",
            serverPath: eOMFilterName.h,
            sortName: eOMFilterName.h,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "j",
            serverPath: eOMFilterName.j,
            sortName: eOMFilterName.j,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "l",
            serverPath: eOMFilterName.l,
            sortName: eOMFilterName.l,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "t",
            serverPath: eOMFilterName.t,
            sortName: eOMFilterName.t,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "x",
            serverPath: eOMFilterName.x,
            sortName: eOMFilterName.x,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "y",
            serverPath: eOMFilterName.y,
            sortName: eOMFilterName.y,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },

          {
            name: "r1",
            serverPath: eOMFilterName.r1,
            sortName: eOMFilterName.r1,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "r2",
            serverPath: eOMFilterName.r2,
            sortName: eOMFilterName.r2,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "efl",
            serverPath: eOMFilterName.efl,
            sortName: eOMFilterName.efl,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            title_transform: eTextTransform.UPPERCASE,
            units: "env",
          },
          {
            name: "bfl",
            serverPath: eOMFilterName.bfl,
            sortName: eOMFilterName.bfl,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            title_transform: eTextTransform.UPPERCASE,
            units: "env",
          },
          {
            name: "Length of face",
            serverPath: eOMFilterName.length_of_face,
            sortName: eOMFilterName.length_of_face,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "length",
            serverPath: eOMFilterName.length,
            sortName: eOMFilterName.length,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "Length of legs",
            serverPath: eOMFilterName.length_of_legs,
            sortName: eOMFilterName.length_of_legs,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "Length of hypotenuse",
            serverPath: eOMFilterName.length_of_hypotenuse,
            sortName: eOMFilterName.length_of_hypotenuse,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "Dimensions",
            serverPath: eOMFilterName.dimensions,
            sortName: eOMFilterName.dimensions,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "dimension 1",
            serverPath: eOMFilterName.dimension_1,
            sortName: eOMFilterName.dimension_1,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "dimension 2",
            serverPath: eOMFilterName.dimension_2,
            sortName: eOMFilterName.dimension_2,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "cube side",
            serverPath: eOMFilterName.cube_side,
            sortName: eOMFilterName.cube_side,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "l1",
            serverPath: eOMFilterName.l1,
            sortName: eOMFilterName.l1,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "l2",
            serverPath: eOMFilterName.l2,
            sortName: eOMFilterName.l2,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "k",
            serverPath: eOMFilterName.k,
            sortName: eOMFilterName.k,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
          },
          {
            name: "convexity",
            serverPath: eOMFilterName.convexity,
            sortName: eOMFilterName.convexity,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.STRING,
            width: 200,
          },
          {
            name: "thickness center",
            serverPath: eOMFilterName.thickness_center,
            sortName: eOMFilterName.thickness_center,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "thickness edge",
            serverPath: eOMFilterName.thickness_edge,
            sortName: eOMFilterName.thickness_edge,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "thickness",
            serverPath: eOMFilterName.thickness,
            sortName: eOMFilterName.thickness,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "edge thickness max",
            serverPath: eOMFilterName.edge_thickness_max,
            sortName: eOMFilterName.edge_thickness_max,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "thickness h",
            serverPath: eOMFilterName.thickness_h,
            sortName: eOMFilterName.thickness_h,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            translateFunction: (pValue) => this._getScaledNumber(pValue),
            units: "env",
          },
          {
            name: "alpha",
            serverPath: eOMFilterName.alpha,
            sortName: eOMFilterName.alpha,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "apex angle",
            serverPath: eOMFilterName.apex_angle,
            sortName: eOMFilterName.apex_angle,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "beta",
            serverPath: eOMFilterName.beta,
            sortName: eOMFilterName.beta,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "phi",
            serverPath: eOMFilterName.phi,
            sortName: eOMFilterName.phi,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "angle a",
            serverPath: eOMFilterName.angle_a,
            sortName: eOMFilterName.angle_a,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "angle b",
            serverPath: eOMFilterName.angle_b,
            sortName: eOMFilterName.angle_b,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "angle c",
            serverPath: eOMFilterName.angle_c,
            sortName: eOMFilterName.angle_c,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "angle d",
            serverPath: eOMFilterName.angle_d,
            sortName: eOMFilterName.angle_d,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "theta",
            serverPath: eOMFilterName.theta,
            sortName: eOMFilterName.theta,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "theta1",
            serverPath: eOMFilterName.theta1,
            sortName: eOMFilterName.theta1,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "theta2",
            serverPath: eOMFilterName.theta2,
            sortName: eOMFilterName.theta2,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "wedge angle",
            serverPath: eOMFilterName.wedge_angle,
            sortName: eOMFilterName.wedge_angle,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
          {
            name: "deviation angle",
            serverPath: eOMFilterName.deviation_angle,
            sortName: eOMFilterName.deviation_angle,
            showOnStart: false,
            dataType: eMenuListColumnsDataType.NUMBER,
            width: 200,
            units: "rad",
          },
        ],
      }
    );

    this.mSignelLineItemQaId = "qa_optics_item";
  }
  //__________________________________________________________________________________________
  private _getScaledNumber(pValue: string) {
    if (null == pValue) {
      return "";
    }

    let aToFixed: number;
    try {
      aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
    } catch (e) {
      aToFixed = 4;
    }

    const aScale =
      eUnitType.INCHES == this.mFiltersHandler.filterUnit ? 1 / 25.4 : 1;
    return OP3DMathUtils.toFixed(parseFloat(pValue) * aScale, aToFixed);
  }
  //__________________________________________________________________________________________
  public _getMaterialName(pNumberID: string) {
    let aMaterialsNumberID = pNumberID.split(",");
    let aNames = Op3dContext.DATA_MANAGER.getMaterialNameByNumberID(
      aMaterialsNumberID[0]
    );

    for (let i = 1; i < aMaterialsNumberID.length; i++) {
      let aMaterialName = Op3dContext.DATA_MANAGER.getMaterialNameByNumberID(
        aMaterialsNumberID[i]
      );
      if (aMaterialName !== null) {
        aNames += ", ";
        aNames += aMaterialName;
      }
    }

    return aNames;
  }

  //__________________________________________________________________________________________
  public checkAddToCanvasLimit() {
    let aLimit, aWarningMessage;
    if (this.pFromPart == true) {
      aLimit = 1;
      aWarningMessage = MessagesHandler.CHOOSE_ONE_OPTIC;
    } else {
      aLimit = 10;
      aWarningMessage = MessagesHandler.CHOOSE_OPTICS_LIMIT;
    }
    return { limit: aLimit, warningMessage: aWarningMessage };
  }
  //__________________________________________________________________________________________
  /*
   * @desription iterating through choosing items and send event of creating optics part
   */
  protected async _onSelectItem(pItem: Array<iOpticsVO>) {
    Spinner.instance.show();
    let aCountNotSupported = 0;

    for (let i = 0; i < pItem.length; i++) {
      let aIsSupported = true;
      switch (pItem[i]["parameters.subType"]) {
        case OpticsContext._Roof_Prism_Mirror: {
          if (pItem[i]["parameters.baseShape"] == eBaseShape.CIRCULAR) {
            aIsSupported = false;
          }
          break;
        }
        case OpticsContext._Axicon_Prism:
        case OpticsContext._D_Shaped_Mirror:
          aIsSupported = false;
          break;
        // case OpticsContext._Cylindrical_Mirror_Plano_Concave:
        // case OpticsContext._Cylindrical_Mirror_Plano_Convex:
        // case OpticsContext._Cylindrical_Lens_Plano_Concave:
        // case OpticsContext._Cylindrical_Lens_Plano_Convex:
        //   if (pItem[i]["parameters.baseShape"] == eBaseShape.RECTANGULAR) {
        //     aIsSupported = false;
        //   }
        //   break;
      }

      if (aIsSupported == false) {
        aCountNotSupported++;
        continue;
      }

      let aSearchData: iMLSearchData = {
        filtersData: this.mFiltersHandler.exportToJson(),
        searchString: this.mSearchInput.value,
        sort: this.mSortObj,
      };

      const aOpticsVO = await OpticsDataLoader.instance.getSingleFullData({
        number_id: pItem[i].number_id,
      });

      if (aOpticsVO === undefined) {
        continue;
      }

      Op3dContext.PARTS_MANAGER.chooseOptics({
        openMenuPoint: this.mData?.openMenuPoint,
        part: this.mData?.part,
        opticsVO: aOpticsVO,
        searchData: aSearchData,
        index: i,
      });

      AnalyticsEventsContext.onChoseOptics(pItem[i]);
    }

    Op3dContext.PART_INFO.update();

    this.close();
    Spinner.instance.hide();
    Op3dContext.SCENE_HISTORY.saveScene();
    if (aCountNotSupported !== 0) {
      Popup.instance.open({
        text: "Some optics is not supported",
      });
    }
  }
  //__________________________________________________________________________________________
  private _addFromToData(pValues: Array<number>) {
    let aData: object | number = {
      from: pValues[0],
      to: pValues[1],
    };

    if (pValues[0] == pValues[1]) {
      aData = pValues[0];
    }

    return aData;
  }
  //__________________________________________________________________________________________
  protected _getQuery() {
    this.resetAppliedFilters();

    let aFilters = this.mFiltersHandler.getFilters();

    let aBaseShape = aFilters?.[eOMFilterName.baseShape];
    let aBrands = aFilters?.[eOMFilterName.brand];
    let aMaterialID = aFilters?.[eOMFilterName.materialID];
    let aPermission = aFilters?.permission;
    let aType = aFilters?.[eOMFilterName.type];
    let aSubType = aFilters?.[eOMFilterName.subType];
    let aCut_ON = [
      aFilters?.[eOMFilterName.cut_on]?.from,
      aFilters?.[eOMFilterName.cut_on]?.to,
    ];
    let aCut_OFF = [
      aFilters?.[eOMFilterName.cut_off]?.from,
      aFilters?.[eOMFilterName.cut_off]?.to,
    ];
    let aEFL = [
      aFilters?.[eOMFilterName.efl]?.from,
      aFilters?.[eOMFilterName.efl]?.to,
    ];
    let aBFL = [
      aFilters?.[eOMFilterName.bfl]?.from,
      aFilters?.[eOMFilterName.bfl]?.to,
    ];
    let aR1 = [
      aFilters?.[eOMFilterName.r1]?.from,
      aFilters?.[eOMFilterName.r1]?.to,
    ];
    let aR2 = [
      aFilters?.[eOMFilterName.r2]?.from,
      aFilters?.[eOMFilterName.r2]?.to,
    ];
    let aDiameter = [
      aFilters?.[eOMFilterName.diameter]?.from,
      aFilters?.[eOMFilterName.diameter]?.to,
    ];
    let aGrooves = [
      aFilters?.[eOMFilterName.grooves]?.from,
      aFilters?.[eOMFilterName.grooves]?.to,
    ];
    let aFreeString = Op3dUtils.getNormalizedName(
      this.mSearchInput.value
    )?.trim();

    this.privateFilterApplied(aPermission == 0);

    let aLimit = +this._getPart<HTMLSelectElement>("results").value;
    let aOrFilters = [];
    let aAndFilters = [];
    const aAggsMustFilters = [];
    const aAggsShouldFilters = [];
    const aAggsFilter = [];
    aAggsFilter.push(
      eOMFilterName.baseShape,
      eOMFilterName.brand,
      eOMFilterName.permission,
      eOMFilterName.type,
      eOMFilterName.subType
    );

    if (aPermission != null) {
      if (aPermission.length != 2) {
        this.updateAppliedFilters(
          "Ownership",
          aPermission[0] == 1 ? "Public" : "Private",
          "permission"
        );
      }

      if (aPermission == 0) {
        aAndFilters.push({ [eOMFilterName.owner]: Op3dContext.USER_VO.id });
        aAggsMustFilters.push({
          match: { [eOMFilterName.owner]: Op3dContext.USER_VO.id },
        });
      } else if (aPermission == 1) {
        aAndFilters.push({ [eOMFilterName.permission]: aPermission[0] });
        aAggsMustFilters.push({
          match: { [eOMFilterName.permission]: aPermission[0] },
        });
      } else {
        aOrFilters.push([
          { [eOMFilterName.permission]: 1 },
          { [eOMFilterName.owner]: Op3dContext.USER_VO.id },
        ]);
        aAggsShouldFilters.push({
          bool: {
            should: [
              { match: { [eOMFilterName.permission]: 1 } },
              { match: { [eOMFilterName.owner]: Op3dContext.USER_VO.id } },
            ],
          },
        });
      }
    } else {
      aOrFilters.push([
        { [eOMFilterName.permission]: 1 },
        { [eOMFilterName.owner]: Op3dContext.USER_VO.id },
      ]);
      aAggsShouldFilters.push({
        bool: {
          should: [
            { match: { [eOMFilterName.permission]: 1 } },
            { match: { [eOMFilterName.owner]: Op3dContext.USER_VO.id } },
          ],
        },
      });
    }

    if (this.mFavoriteFilter.style.fill === "rgb(149, 158, 158)") {
      this.updateAppliedFilters("Filter", "Favorites", "favorites");
      if (this.mStarredItems != null && this.mStarredItems.length != 0) {
        aOrFilters.push(
          this.mStarredItems.map((favorite) => ({
            [eOMFilterName.numberId]: favorite,
          }))
        );
      } else {
        aAndFilters.push({ [eOMFilterName.numberId]: "EMPTY" });
      }
    }

    if (aBaseShape != null) {
      this.updateAppliedFilters(
        "Shape",
        eBaseShape[aBaseShape[0]],
        eOMFilterName.baseShape
      );
      aAndFilters.push({ [eOMFilterName.baseShape]: aBaseShape[0] });
      aAggsMustFilters.push({
        match: { [eOMFilterName.baseShape]: aBaseShape[0] },
      });
    }

    if (aType != null) {
      this.updateAppliedFilters("Type", aType, eOMFilterName.type);
      aAndFilters.push({ [eOMFilterName.type]: aType });
      aAggsMustFilters.push({ match: { [eOMFilterName.type]: aType } });
    }
    if (aBrands != null) {
      this.updateAppliedFilters("Brands", aBrands, eOMFilterName.brand);
      aOrFilters.push(
        aBrands.map((brand: string) => ({ [eOMFilterName.brand]: brand }))
      );
      aAggsShouldFilters.push({
        terms: {
          [`${[eOMFilterName.brand]}.enum`]: aBrands.map((brand) => brand),
        },
      });
    }
    if (aMaterialID != null) {
      this.updateAppliedFilters(
        "Material",
        aMaterialID,
        eOMFilterName.materialID
      );
      aAndFilters.push(
        { [eOMFilterName.materialID]: aMaterialID }
        // aMaterialID.map((material: string) => ({
        //   [eOMFilterName.materialID]: material,
        // }))
      );
      aAggsShouldFilters.push({
        terms: {
          [`${[eOMFilterName.materialID]}.enum`]: aMaterialID,
        },
      });
    }

    if (aSubType != null) {
      this.updateAppliedFilters("SubType", aSubType, eOMFilterName.subType);
      aOrFilters.push(
        aSubType.map((subType: string) => ({
          [eOMFilterName.subType]: subType,
        }))
      );
      aAggsShouldFilters.push({
        terms: {
          [`${[eOMFilterName.subType]}.enum`]: aSubType.map(
            (subType) => subType
          ),
        },
      });
    }
    if (aCut_ON[0] != null && aCut_ON[1] != null) {
      this.updateAppliedFilters(
        "CutOn",
        `${aCut_ON[0]} -  ${aCut_ON[1]}`,
        eOMFilterName.cut_on
      );

      aAndFilters.push({
        [eOMFilterName.cut_on]: this._addFromToData(aCut_ON),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.cut_on]: {
            gte: aCut_ON[0],
            lte: aCut_ON[1],
          },
        },
      });
    }
    if (aCut_OFF[0] != null && aCut_OFF[1] != null) {
      this.updateAppliedFilters(
        "CutOff",
        `${aCut_OFF[0]} -  ${aCut_OFF[1]}`,
        eOMFilterName.cut_off
      );
      aAndFilters.push({
        [eOMFilterName.cut_off]: this._addFromToData(aCut_OFF),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.cut_off]: {
            gte: aCut_OFF[0],
            lte: aCut_OFF[1],
          },
        },
      });
    }
    if (aEFL[0] != null && aEFL[1] != null) {
      this.updateAppliedFilters(
        "EFL",
        `${aEFL[0]} -  ${aEFL[1]}`,
        eOMFilterName.efl
      );
      aAndFilters.push({
        [eOMFilterName.efl]: this._addFromToData(aEFL),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.efl]: {
            gte: aEFL[0],
            lte: aEFL[1],
          },
        },
      });
    }
    if (aBFL[0] != null && aBFL[1] != null) {
      this.updateAppliedFilters(
        "BFL",
        `${aBFL[0]} -  ${aBFL[1]}`,
        eOMFilterName.bfl
      );
      aAndFilters.push({
        [eOMFilterName.bfl]: this._addFromToData(aBFL),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.bfl]: {
            gte: aBFL[0],
            lte: aBFL[1],
          },
        },
      });
    }
    if (aR1[0] != null && aR1[1] != null) {
      this.updateAppliedFilters(
        "R1",
        `${aR1[0]} -  ${aR1[1]}`,
        eOMFilterName.r1
      );
      aAndFilters.push({
        [eOMFilterName.r1]: this._addFromToData(aR1),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.r1]: {
            gte: aR1[0],
            lte: aR1[1],
          },
        },
      });
    }
    if (aR2[0] != null && aR2[1] != null) {
      this.updateAppliedFilters(
        "R2",
        `${aR2[0]} -  ${aR2[1]}`,
        eOMFilterName.r2
      );
      aAndFilters.push({
        [eOMFilterName.r2]: this._addFromToData(aR2),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.r2]: {
            gte: aR2[0],
            lte: aR2[1],
          },
        },
      });
    }

    if (aDiameter[0] != null && aDiameter[1] != null) {
      this.updateAppliedFilters(
        "Diameter",
        `${aDiameter[0]} -  ${aDiameter[1]}`,
        eOMFilterName.diameter
      );

      aAndFilters.push({
        [eOMFilterName.diameter]: this._addFromToData(aDiameter),
      });

      aAggsMustFilters.push({
        range: {
          [eOMFilterName.diameter]: {
            gte: aDiameter[0],
            lte: aDiameter[1],
          },
        },
      });
    }
    if (aGrooves[0] != null && aGrooves[1] != null) {
      this.updateAppliedFilters(
        "Grooves",
        `${aGrooves[0]} -  ${aGrooves[1]}`,
        eOMFilterName.grooves
      );
      aAndFilters.push({
        [eOMFilterName.grooves]: this._addFromToData(aGrooves),
      });
      aAggsMustFilters.push({
        range: {
          [eOMFilterName.grooves]: {
            gte: aGrooves[0],
            lte: aGrooves[1],
          },
        },
      });
    }

    let aAggsQuery = {
      index: "",
      body: {
        query: {
          bool: {
            must: aAggsMustFilters.concat(aAggsShouldFilters),
            // should: { match: { 'parameters.subType': 'Wedge Prism' } }
          },
        },
      },
    };

    aOrFilters.forEach((filter) => aAndFilters.push({ any: filter }));
    let aNewResNew: iElasticQuery = {
      query: aFreeString,
      filters: { all: aAndFilters },
      page: {
        current: this.mCurrentPage + 1,
        size: aLimit,
      },
      sort: { name: "asc" },
      aggregation: aAggsFilter,
      originalSearchQuery: aAggsQuery,
    };

    if (this.mSortObj != null) {
      let aSortField = this.mSortObj.field;
      aNewResNew.sort = {
        [aSortField]: this.mSortObj.order == 1 ? "asc" : "desc",
      };
    }
    return aNewResNew;
  }
  //__________________________________________________________________________________________
  protected addPromotedResults(
    pQueryElastic: iElasticQuery,
    pBrands: Array<string>
  ) {
    this.mResultsListPromoted.innerHTML = "";
    for (let brand of pBrands) {
      this.fillPromotedList(pQueryElastic, brand, "OPTICS");
    }
  }
  //__________________________________________________________________________________________
  protected deleteSelected() {
    let aItemsToDelete = this.mItemsToCanvasList.filter(
      (item) => item.item.permission === eDataPermission.PRIVATE
    );
    if (aItemsToDelete.length > 0) {
      this._showWarning({
        message: `Are you sure you want to delete ${aItemsToDelete.length} items? This action cannot be undone.`,
        yesBtn: {
          text: "OK",
          callback: () => this._onDeleteSelected(),
        },
        noBtn: {
          text: "Cancel",
        },
      });
    } else {
      this._showWarning({
        message: `No private items to delete`,
      });
    }
  }
  //__________________________________________________________________________________________
  private async _onDeleteSelected() {
    Spinner.instance.show();
    let aPrivateItems = this.mItemsToCanvasList.filter(
      (item) => item.item.permission === eDataPermission.PRIVATE
    );
    let aTotal = aPrivateItems.length;
    let aRemoved = 0;
    let aErrors = 0;
    for (let i = 0; i < aPrivateItems.length; i++) {
      let aRes = await ServerContext.SERVER.deleteIngredient({
        ingredient: eIngredientType.OPTIC,
        numberID: aPrivateItems[i].item.number_id,
      });

      if (aRes.success === true) {
        try {
          await Op3dContext.PARTS_MANAGER.deleteAllOpticsByNumberID(
            aPrivateItems[i].item.number_id
          );
        } catch (e) {
          Op3dContext.USER_VO.isEmployeeUser &&
            console.log("failed to delete parts from scene");
        }
        aRemoved++;
      } else {
        aErrors++;
      }
    }

    Spinner.instance.hide();
    let aCallback = () => {
      this.mItemsToCanvasList = [];
      this.changeSelectedCount();
      this._getResults(true, this.pFromPart);
    };
    Popup.instance.open({
      text: `${aRemoved}/${aTotal} removed successfully`,
      yesBtn: {
        callback: aCallback,
      },
      onClose: aCallback,
    });

    if (aRemoved > 0) {
      Op3dContext.SCENE_HISTORY.clearHistory();
    }
  }
  //__________________________________________________________________________________________
  protected _onClose(pData?: any): void {
    super._onClose(pData);
    this._clearResults();
    const event = new CustomEvent("close_optics_menu");
    window.dispatchEvent(event);
  }
  //__________________________________________________________________________________________
  public static get instance(): OpticsMenu {
    if (this.INSTANCE == null) {
      let aDiv = document.createElement("div");
      aDiv.classList.add("modal");
      // aDiv.classList.add('fade');
      aDiv.classList.add("w-100-modal");

      document.getElementById("menus").appendChild(aDiv);

      this.INSTANCE = new OpticsMenu({
        container: aDiv,
        skinPath: MenuListAbstract.SKIN_PATH,
      });
    }

    return this.INSTANCE;
  }
  //__________________________________________________________________________________________
  private async returnAutocompleteResults(freeString: string) {
    let aRes = await ServerContext.SERVER.getElasticOpticsSuggestions({
      query: freeString,
    } as iElasticQuery);

    let aNewArray: Array<string> = [];
    (aRes.data as any).results.documents.forEach((item: any) => {
      aNewArray.push(item.suggestion);
    });

    return aNewArray;
  }
  //__________________________________________________________________________________________
  private initAutocomplete() {
    const autoCompleteJS = new autoComplete({
      selector: "#search-input",
      data: {
        src: async (freeString: string) => {
          try {
            let items = await this.returnAutocompleteResults(freeString);
            return items;
          } catch (error) {
            return error;
          }
        },
      },
      placeHolder: "Search",
      resultItem: {
        class: "result_item",
        element: (item: HTMLElement, data: string) => {
          (item as any).style =
            "display: flex; justify-content: space-between;";
          item.innerHTML = `
                    <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                      ${data.match}
                    </span>`;
        },
        highlight: "autoComplete_highlight",
        selected: "autoComplete_selected",
      },
      resultsList: {
        class: "result_list",
        noResults: true,
        maxResults: 10,
        tabSelect: true,
      },
      submit: true,
      debounce: 150,
      searchEngine: "strict",
    });

    autoCompleteJS.input.addEventListener("selection", (event: CustomEvent) => {
      const feedback = event.detail;
      autoCompleteJS.input.blur();

      const selectionAll = feedback.selection.value;
      autoCompleteJS.input.value = selectionAll;

      this._getResults(true, this.pFromPart);
    });
  }
  //__________________________________________________________________________________________
  protected async _onCreationComplete() {
    this.initAutocomplete();

    super._onCreationComplete();

    await Op3dContext.DATA_MANAGER.getMaterials();
    this.mFiltersHandler = new OpticsFiltersHandler(
      this,
      this.mFilterTemplate,
      this.mFiltersParent
    );
    await this.mFiltersHandler.reload();

    this.mIsReady = true;
  }
  //__________________________________________________________________________________________
  protected fillCompareTable(pParts: Array<any>) {
    let aTable = this._getPart("compare-table") as HTMLTableElement;

    let aCallWidth: string = "90%";
    switch (pParts.length) {
      case 1:
        aCallWidth = "90%";
        break;
      case 2:
        aCallWidth = "45%";
        break;
      case 3:
        aCallWidth = "30%";
        break;
      default:
        aCallWidth = "30%";
        let aWarningMsg = this._getPart("compare-warning");
        aWarningMsg.style.display = "flex";
        setTimeout(() => {
          aWarningMsg.style.display = "none";
        }, 2500);
    }
    let row = aTable.insertRow();
    row.insertCell();

    for (let i = 0; i < (pParts.length >= 3 ? 3 : pParts.length); i++) {
      let aName = row.insertCell(i + 1);
      aName.innerHTML = pParts[i].name;
      aName.style.width = aCallWidth;
    }

    this.mMenuParams.columns.forEach((key: iMenuListColumn) => {
      if (key.serverPath !== "name") {
        let row = aTable.insertRow();
        let aName = row.insertCell();
        aName.innerHTML = key.name;
        let aCounter = 0;
        for (let i = 0; i < (pParts.length >= 3 ? 3 : pParts.length); i++) {
          let aCurrentPart = pParts[i] as any;
          let aCell = row.insertCell();
          let aValue = aCurrentPart[key.serverPath];

          let aConvertedValue = aCurrentPart[key.serverPath];
          if (null != key.translateFunction) {
            aConvertedValue = key?.translateFunction(
              aCurrentPart[key.serverPath]
            );
          }

          if (aValue != null) {
            aCell.innerHTML = aConvertedValue;
          } else {
            aCell.innerHTML = "";
            aCounter++;
          }
        }
        if (aCounter === (pParts.length >= 3 ? 3 : pParts.length)) {
          aTable.deleteRow(row.rowIndex);
        }
      }
    });
  }
  //__________________________________________________________________________________________
  protected excludePromotedResults(pResults: Array<iOpticsVO>) {
    const filteredArray = pResults.filter(
      (obj) => !this.mPromotedIds.includes((obj.number_id as any).raw)
    );
    this.mPromotedIds = [];
    return filteredArray;
  }
  //__________________________________________________________________________________________
  protected fillOneExtraField(pData: iOpticsVO, pCloneItem: HTMLElement) {
    let aLink;

    for (let attribute in pData) {
      let aAttribute = attribute;

      if (aAttribute === "name") {
        pCloneItem.firstElementChild!.innerHTML = pData[aAttribute];
        continue;
      }

      if (
        aAttribute === "parameters.info.weblinks" &&
        pData[aAttribute] !== null
      ) {
        aLink = pData[aAttribute][0];
        let aLinkBlock = document.createElement("a");
        aLinkBlock.href = aLink;
        aLinkBlock.target = "_blank";
        aLinkBlock.innerHTML = aLink;
        pCloneItem.appendChild(aLinkBlock);
        continue;
      }
      let aFieldExists = this.mMenuParams.columns.find(
        (item) => item.serverPath === aAttribute
      );
      if (aFieldExists) {
        let aBlock = document.createElement("div");
        aBlock.className = "info-block";
        let aConvertedValue = pData[aAttribute];
        if (null != aFieldExists.translateFunction) {
          aConvertedValue = aFieldExists?.translateFunction(pData[aAttribute]);
        }

        aBlock.innerHTML = `<div>${aFieldExists.name}</div><div>${aConvertedValue}</div>`;

        let aContainer = Op3dUtils.getElementIn(
          pCloneItem,
          "items-container"
        ) as HTMLElement;
        aContainer.appendChild(aBlock);
        continue;
      }
    }
  }
  //__________________________________________________________________________________________
  protected async _onOpen(pData: iOpticsMenuOpen) {
    let aOptomechanicToShow =
      Op3dContext.APP_FEATURES_CONFIG.optomech_menu.enabled;
    this.mData = pData;
    this.mFiltersHandler.setCategory("Optics");

    let aCategoryFilter =
      aOptomechanicToShow &&
      (this.mFiltersHandler.filterComponent("Category") as Filter);

    if (pData.part != null) {
      await this.mFiltersHandler.reload(pData.part);
      aOptomechanicToShow && aCategoryFilter.setVisibility(false);
      if (null != pData.searchData) {
        this.mFiltersHandler.initFromJson(pData.searchData.filtersData);
        this.mSearchInput.value = pData.searchData.searchString;
        this.mSortObj = pData.searchData.sort;
      } else {
        this.mSearchInput.value = "";
        this.mSortObj = null;
      }
      this._getResults(true, true);
      return;
    }

    aOptomechanicToShow && aCategoryFilter.setVisibility(true);
    this._getResults(true, false);
  }
  //__________________________________________________________________________________________
  private setBaseShapeCount(
    pAggs,
    pAggsKey: string,
    pFilters: iHash<Object>,
    pFilterContainer: HTMLElement
  ) {
    let aCount = 0;
    if (pFilters["parameters.baseShape"] === undefined) {
      for (let title of pAggs[pAggsKey].buckets) {
        aCount += title.doc_count;
      }

      let aData = $(pFilterContainer).find(`[nameforcount="None"]`)[0];

      if (aData === undefined) {
        return;
      }
      aData.innerHTML += ` (${aCount})`;
    } else {
      for (let title of pAggs[pAggsKey].buckets) {
        let aShapeState =
          eBaseShape.CIRCULAR === title.key ? "Private" : "Public";
        switch (title.key) {
          case eBaseShape.CIRCULAR:
            aShapeState = "Circular";
            break;
          case eBaseShape.VOLUME:
            aShapeState = "Volume";
            break;
          case eBaseShape.RECTANGULAR:
            aShapeState = "Rectangular";
            break;
        }
        aCount += title.doc_count;
        let aShapeTitle = $(pFilterContainer).find(
          `[nameforcount="${aShapeState}"]`
        )[0];
        if (aShapeTitle === undefined) {
          continue;
        }
        aShapeTitle.innerHTML += ` (${title.doc_count})`;
      }
    }
  }
  //__________________________________________________________________________________________

  //__________________________________________________________________________________________
  protected setCounts(pAggs: any) {
    super.setCounts(pAggs);
    for (let aggs of Object.keys(pAggs)) {
      let aFilterComponent = this.mFiltersHandler.filterComponent(aggs);
      let aFilterContainer = aFilterComponent.container;
      let aFilters = this.mFiltersHandler.getFilters();
      if (aggs === "parameters.baseShape") {
        this.setBaseShapeCount(pAggs, aggs, aFilters, aFilterContainer);
      } else if (aggs === "parameters.type") {
        this.setTypeCount(pAggs, aggs, aFilters, aFilterContainer);
      } else {
        this.setCount(pAggs, aggs, aFilterContainer);
      }
    }
  }

  //|| aggs === 'info.parameters.type'
  //__________________________________________________________________________________________
  public async _getResults(
    pClearResults: boolean,
    pFromPart?: boolean,
    pQuery?: iElasticQuery
  ) {
    this.pFromPart = pFromPart;
    super._getResults(pClearResults, pFromPart, pQuery);

    let aFilters = this.mFiltersHandler.getFilters();

    let aBaseShape = aFilters[eOMFilterName.baseShape]
      ? aFilters[eOMFilterName.baseShape][0]
      : [];

    let aStrBaseShape = [];

    for (let i = 0; i < aBaseShape.length; i++) {
      aStrBaseShape.push(OpticsFiltersHandler.SHAPES_NAMES[aBaseShape[i]]);
    }

    AnalyticsEventsContext.triggerOpticsSearchAnalyticsEvent({
      type: aFilters["parameters.type"] as string,
      subType:
        aFilters["parameters.subType"] != null
          ? aFilters["parameters.subType"][0]
          : null,
      brand:
        aFilters["parameters.info.brand"] != null
          ? aFilters["parameters.info.brand"]
          : null,
      searchString: this.mSearchInput.value,
      baseShape: aStrBaseShape,
    });
  }
  //__________________________________________________________________________________________
  public setFilters(pData: Array<any>) {
    this.mFiltersHandler.reset();
    this.mCurrentPage = 0;
    this.mFiltersHandler.setFilters(pData);

    let aQuery = pData.find((item) => Object.keys(item)[0] === "query");
    if (aQuery !== undefined) {
      let aStr = Object.values(aQuery)[0] as string;
      this.mSearchInput.value = aStr;
    } else {
      this.mSearchInput.value = "";
    }
  }
  //__________________________________________________________________________________________
}
