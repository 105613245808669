import { ePolarizationSourceType } from "../../_context/Enums";
import { iMinMax, iPoint2D, iRGBA, iHash } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { LightSourceUtils } from "../../simulation/LightSourceUtils";
import { tAnalysisType, eAnalysisType } from "./AnalysisContext";
import { AnalysisMatrix } from "./AnalysisMatrix";
import { AnalysisPortal } from "./AnalysisPortal";
import { ExtendedViewNew } from "./_extendedView/ExtendedViewNew";


export interface iAnalysisQuery2 {
    name: tAnalysisType;
    id: string;
    wavelengths: Array<number>;
    polarization: ePolarizationSourceType
    polarizationKind: ePolarizationSourceType;
}


export interface iAnalysisCommonData {
    /**
    * face id of the surface
    */
    faceId: string;
    analysis_id: string;
    /**
    * face size in x around center point
    */
    x_range: iMinMax;
    /**
    * face size in y around center point
    */
    y_range: iMinMax;
    /**
    * number of rays that hit a surface
    */
    total_hits: Number;
    /**
    * resolution of the image
    */
    resolution: iPoint2D,
    /**
    * fast of advanced
    */
    analysisType: eAnalysisType;
    /**
    * The wavelegnth of this matrix
    */
    wavelength: number;

    spectralColors?: () => Array<Array<iRGBA>>;

    polarization_kind?: ePolarizationSourceType;
}

export interface iAnalysisCacheData {

    common_data: iAnalysisCommonData
    /**
     * polarization type of the response
     */
    polarization: ePolarizationSourceType;
    /**
     * 2 dimensional matrix representing each item in grid resolutionX * resolutionY
     * raw value from snellius
     */
    matrix: AnalysisMatrix,
    /**
     * analysis name 
     */
    name: tAnalysisType,
}

export interface iOneColorCell {
    wavelength: number;
    intensity: number;
}

export type tMatrix<T> = Array<Array<T>>;


export class AnalysisCache {


    private static CACHE_CHANGES_LISTENERS = new Array<{
        key: string,
        exec_all: boolean;
        analysisIds: Array<string>,
        callback: Function
    }>();

    private static CURRENT_ANALYSIS_DATA = new Array<iAnalysisCacheData>();
    private static CURRENT_SPECTRAL_RESULT: iHash<Array<Array<iRGBA>>> = {};

    //__________________________________________________________________________________________
    static clear() {
        this.CURRENT_ANALYSIS_DATA = new Array<iAnalysisCacheData>();
        this.CURRENT_SPECTRAL_RESULT = {};
    }
    //__________________________________________________________________________________________
    public static removeEventListener(pKey: string) {
        let aIdx = this.CACHE_CHANGES_LISTENERS.findIndex(item => item.key == pKey);
        if (aIdx != -1) {
            this.CACHE_CHANGES_LISTENERS.splice(aIdx, 1);
        }
    }
    //__________________________________________________________________________________________
    private static clearSpectralColors(pAnalysisKey: string) {
        for (let key in this.CURRENT_SPECTRAL_RESULT) {
            if (key.indexOf(pAnalysisKey) != -1) {
                delete this.CURRENT_SPECTRAL_RESULT[key];
            }
        }

    }
    //__________________________________________________________________________________________
    static clearByIds(pAnalysisToRun: string[]) {

        for (let i = 0; i < pAnalysisToRun.length; i++) {

            this.clearSpectralColors(pAnalysisToRun[i])


            let aResults = this.CURRENT_ANALYSIS_DATA.filter(item => item.common_data.analysis_id.includes(pAnalysisToRun[i]));
            for (let j = 0; j < aResults.length; j++) {
                let aIdx = this.CURRENT_ANALYSIS_DATA.findIndex(item => item == aResults[j]);
                if (aIdx != -1) {
                    this.CURRENT_ANALYSIS_DATA.splice(aIdx, 1);
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public static addEventListener(pKeyListener: string,
        pCallback: Function, pAnalysisIds: Array<string>, pExecAll: boolean) {

        let aIdx = this.CACHE_CHANGES_LISTENERS.findIndex(item => item.key == pKeyListener);
        if (aIdx != -1) {
            this.CACHE_CHANGES_LISTENERS[aIdx] = {
                key: pKeyListener,
                callback: pCallback, analysisIds: pAnalysisIds, exec_all: pExecAll
            };
        } else {
            this.CACHE_CHANGES_LISTENERS.push({
                key: pKeyListener,
                callback: pCallback, analysisIds: pAnalysisIds, exec_all: pExecAll
            });
        }
    }
    //__________________________________________________________________________________________
    public static addAnalysisData(pNewData: Array<iAnalysisCacheData>, pAnalysisId: string) {

        for (let i = 0; i < pNewData.length; i++) {
            const aCurrItem = pNewData[i];
            let aMatrix = aCurrItem.matrix.clone();
            let aClone = DataUtils.getObjectCopy(aCurrItem);
            aClone.matrix = aMatrix;
            aClone.common_data.analysis_id = pAnalysisId;

            AnalysisCache.CURRENT_ANALYSIS_DATA.push(aClone);
        }

        if (pNewData.length > 0) {
            this._notifyCacheUpdated([pAnalysisId], pNewData);
        }
    }
    //__________________________________________________________________________________________
    private static _notifyCacheUpdated(pAnalysisIds: Array<string>, pNewData?: Array<iAnalysisCacheData>) {
        let aId = ExtendedViewNew.returnClearPolarizationAnalysisId(pAnalysisIds[0]);
        pAnalysisIds[0] = aId
        for (let i = this.CACHE_CHANGES_LISTENERS.length - 1; i >= 0; i--) {
            let aContains = this.CACHE_CHANGES_LISTENERS[i].analysisIds.some(id => pAnalysisIds.includes(id)) || this.CACHE_CHANGES_LISTENERS[i].exec_all == true;
            if (aContains == true) {
                if (AnalysisPortal.instance.runningPolarizedAnalyses[`${pAnalysisIds[0]}`] !== undefined && AnalysisPortal.instance.runningPolarizedAnalyses[`${pAnalysisIds[0]}`] < 3) {
                    AnalysisPortal.instance.increasePolarizedAnalysesByOne(pAnalysisIds[0]);
                } else {
                    this.CACHE_CHANGES_LISTENERS[i].callback(pAnalysisIds, pNewData);
                }
            }
        }
    }
    //__________________________________________________________________________________________
    public static getWavelengthsForAnalysis(pOptions: Omit<iAnalysisQuery2, "wavelengths">) {
        let aData = this.CURRENT_ANALYSIS_DATA.filter(item => item.common_data.analysis_id == pOptions.id);
        let aRes = new Array<number>();
        for (let i = 0; i < aData.length; i++) {
            const aCurrData = aData[i];
            if (aRes.indexOf(aCurrData.common_data.wavelength) == -1) {
                aRes.push(aCurrData.common_data.wavelength);
            }
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    public static getAllDataByQuery(pOptions: iAnalysisQuery2, pUseWavelengths: boolean) {

        //let aData = this.getDataByType(pOptions.type);
        let aRes = new Array<iAnalysisCacheData>();
        let aResults;
        const aPolirizedIndex = pOptions.id.indexOf('_')
        let aRequiredId = pOptions.id;
        if (aPolirizedIndex !== -1) {
            aRequiredId = pOptions.id.slice(aPolirizedIndex + 1);
        }


        if (pOptions.polarizationKind !== undefined) {
            aResults = this.CURRENT_ANALYSIS_DATA.filter(item => item.common_data.analysis_id === aRequiredId && item.common_data.polarization_kind === pOptions.polarizationKind);
        } else {
            aResults = this.CURRENT_ANALYSIS_DATA.filter(item => item.common_data.analysis_id === aRequiredId)
        }
        for (let i = 0; i < aResults.length; i++) {
            let aExistsWavelength = pUseWavelengths === true ?
                pOptions.wavelengths.indexOf(aResults[i].common_data.wavelength) !== -1 : true;

            if (aResults[i].name === pOptions.name &&
                aResults[i].common_data.polarization_kind === pOptions.polarizationKind &&
                aExistsWavelength) {
                aRes.push(aResults[i]);
            }
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    public static getResultsById(pId: string) {
        let aResults = this.CURRENT_ANALYSIS_DATA.filter(item => item.common_data.analysis_id == pId);
        return aResults;
    }
    //__________________________________________________________________________________________
    public static getResultsByIdAndPolarizationType(pId: string, pPolarizationType: ePolarizationSourceType) {
        let aResults = this.CURRENT_ANALYSIS_DATA.filter(item =>
            (item.common_data.analysis_id === pId) &&
            (item.common_data.polarization_kind === pPolarizationType));

        return aResults;
    }
    //__________________________________________________________________________________________
    /**
     * @param pOptions the query to search for the results
     * @returns combined matrix containing the while data of the wavelengths 
     */
    //__________________________________________________________________________________________
    private static getColors(pData: iAnalysisCacheData[], pSize: iPoint2D<number>,
        pRange: iMinMax<number>) {
        let aColorsData = new Array<Array<Array<iOneColorCell>>>();
        //!TODO refactor this function
        for (let y = 0; y < pSize.y; y++) {
            aColorsData.push(new Array<Array<iOneColorCell>>());
            for (let x = 0; x < pSize.x; x++) {
                aColorsData[y].push(new Array<iOneColorCell>());
            }
        }

        for (let i = 0; i < pData.length; i++) {
            let aCommonData = pData[i].common_data;
            let aRange = pRange;
            let aMatrix = pData[i].matrix.original.matrix;
            let aNormalizationFactor = (aRange.max - aRange.min);
            let aWavelength = aCommonData.wavelength;


            for (let y = 0; y < pSize.y; y++) {
                for (let x = 0; x < pSize.x; x++) {
                    aColorsData[y][x].push({
                        intensity: ((aMatrix[y][x] - aRange.min) / aNormalizationFactor),
                        wavelength: aWavelength
                    });
                }
            }
        }

        let aColorsRGB = new Array<Array<iRGBA>>();
        for (let y = 0; y < pSize.y; y++) {
            aColorsRGB.push(new Array<iRGBA>())
            for (let x = 0; x < pSize.x; x++) {
                let aNumOfWavelengths = aColorsData[y][x].length;
                let aWavelengths = new Array<number>();
                let aSource = new Array<number>();
                for (let i = 0; i < aNumOfWavelengths; i++) {
                    let aIntensity = (aColorsData[y][x][i].intensity);
                    aSource.push(aIntensity);
                    aWavelengths.push(aColorsData[y][x][i].wavelength);
                }

                let aIndex0 = aSource.indexOf(0);
                while (aIndex0 > -1) {
                    aWavelengths.splice(aIndex0, 1);
                    aSource.splice(aIndex0, 1);
                    aIndex0 = aSource.indexOf(0);
                }

                let aRes = LightSourceUtils.getColor2(aWavelengths, aSource);
                let aColor = aRes.color;
                aColorsRGB[y].push({
                    r: ((false == isNaN(aColor.r)) ? aColor.r : 0),
                    g: ((false == isNaN(aColor.g)) ? aColor.g : 0),
                    b: ((false == isNaN(aColor.b)) ? aColor.b : 0),
                    a: ((false == isNaN(aRes.alpha)) ? aRes.alpha : 0)
                });
            }
        }

        return aColorsRGB;

    }
    //__________________________________________________________________________________________
    public static getSpecificCombinedData(pOptions: iAnalysisQuery2, pUseWavelengths: boolean) {

        if (pUseWavelengths && pOptions.wavelengths.length == 0) {
            return this.getEmptyMatrix(pOptions)
        }

        let aResults = this.getAllDataByQuery(pOptions, pUseWavelengths);
        if (aResults.length == 0) {
            return undefined;
        }

        const aFirstResult = aResults[0];
        const aResolution = aFirstResult.common_data.resolution;
        let aRes = this._getCombinedMatrix(aResults, aResolution);

        let aResult: iAnalysisCacheData = {
            matrix: new AnalysisMatrix({
                id: Op3dUtils.idGenerator(),
                values_range: aRes.values_range,
                matrix: aRes.mat
            }),
            name: pOptions.name,
            polarization: aFirstResult.polarization,
            common_data: {
                analysis_id: "",
                resolution: aResolution,
                analysisType: aFirstResult.common_data.analysisType,
                faceId: aFirstResult.common_data.faceId,
                wavelength: 0,
                x_range: aFirstResult.common_data.x_range,
                y_range: aFirstResult.common_data.y_range,
                total_hits: aFirstResult.common_data.total_hits,
                spectralColors: () => this._getSpectralMatrix(aResults, aRes.values_range)
            }
        }

        return aResult;
    }
    //__________________________________________________________________________________________
    private static _getSpectralMatrix(pData: iAnalysisCacheData[], pRange: iMinMax<number>) {
        const aSize = pData[0].common_data.resolution;
        let aKey = 'face_id_' + pData[0].common_data.faceId;
        aKey += 'size_x_' + aSize.x + ',' + 'size_y_' + aSize.y;
        aKey += 'range_min_' + pRange.min + 'max_' + pRange.max;
        for (let i = 0; i < pData.length; i++) {
            aKey += 'w_' + i + '_' + pData[i].common_data.wavelength;
        }

        // try to override the key to analysis id
        let aW = new Array<number>();
        for (let i = 0; i < pData.length; i++) {
            aW.push(pData[i].common_data.wavelength);
        }
        aW.sort((a, b) => (a - b));
        let aWkey = '';
        for (let i = 0; i < aW.length; i++) {
            aWkey += aW.toString();
        }
        aKey = pData[0].common_data.analysis_id + '_' + aWkey;
        if (null == AnalysisCache.CURRENT_SPECTRAL_RESULT[aKey]) {
            AnalysisCache.CURRENT_SPECTRAL_RESULT[aKey] = this.getColors(pData, aSize, pRange);
        }

        return AnalysisCache.CURRENT_SPECTRAL_RESULT[aKey];
    }
    //__________________________________________________________________________________________
    public static getEmptyMatrix(pOptions: iAnalysisQuery2) {

        const aResults = this.getAllDataByQuery(pOptions, false);
        const aFirstResult = aResults[0];
        let aMat = new Array<Array<number>>();
        for (let x = 0; x < aFirstResult.common_data.resolution.y; x++) {
            aMat[x] = new Array<number>();
            for (let y = 0; y < aFirstResult.common_data.resolution.x; y++) {
                aMat[x][y] = 0;
            }
        }

        let aResult: iAnalysisCacheData = {
            matrix: new AnalysisMatrix({ matrix: aMat, id: Op3dUtils.idGenerator(), values_range: { max: 0, min: 0 } }),
            name: pOptions.name,
            polarization: aFirstResult.polarization,
            common_data: {
                analysis_id: "",
                resolution: aFirstResult.common_data.resolution,
                analysisType: aFirstResult.common_data.analysisType,
                faceId: aFirstResult.common_data.faceId,
                wavelength: 0,
                x_range: aFirstResult.common_data.x_range,
                y_range: aFirstResult.common_data.y_range,
                total_hits: Number(0),

            },
        }
        aResult.common_data.spectralColors = () => this._getSpectralMatrix([aResult], { max: 0, min: 0 })


        return aResult;
    }
    //__________________________________________________________________________________________
    private static _getCombinedMatrix(pData: Array<iAnalysisCacheData>, pResolution: iPoint2D) {

        let aValuesRange: iMinMax = {
            min: -1,
            max: -1
        }

        /**
         * it should be opposite x and y because this is resolution and not matrix structure 
         */


        let aResultMat = new Array<Array<number>>();
        for (let x = 0; x < pResolution.y; x++) {
            aResultMat[x] = new Array<number>();

            for (let y = 0; y < pResolution.x; y++) {

                aResultMat[x][y] = 0;
                for (let i = 0; i < pData.length; i++) {
                    aResultMat[x][y] += pData[i].matrix.working.matrix[x][y];
                }

                if (aValuesRange.min == -1) {
                    aValuesRange.min = aResultMat[x][y];
                } else {
                    aValuesRange.min = Math.min(aValuesRange.min, aResultMat[x][y]);
                }

                if (aValuesRange.max == -1) {
                    aValuesRange.max = aResultMat[x][y];
                } else {
                    aValuesRange.max = Math.max(aValuesRange.max, aResultMat[x][y]);
                }
            }
        }

        return {
            values_range: aValuesRange,
            mat: aResultMat
        }
    }
    //__________________________________________________________________________________________
}
