import { OpticsContext, eOpticsTypeNames } from "../_context/OpticsContext";
import { OpticsDBConvertor, tBrands } from "../_context/OpticsDBConvertor";
import { iPoint2D } from "../_context/_interfaces/Interfaces";
import { iOpticsVO, iGeneralConicLens } from "../data/VO/OpticsVOInterfaces";
import { MaterialDataLoader } from "../data/data_loader/MaterialDataLoader";
import { LensCalculator } from "../ui/forms/calculators/LensCalculator";
import { MaterialUtils } from "./MaterialUtils";
import { iGeneralConicParams } from "./MatrixUtils";

export class OpticUtils {

    public static getAxiconHeightByAlpha(pAlphaRad: number, pDiameter: number) {
        let aH = (pDiameter / 2 * Math.tan(pAlphaRad));
        return aH;
    }
    //__________________________________________________________________________________________
    private static async _calcEFLSpherical(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
        let R1 = (1 / aGeometry.front.cx);
        let R2 = (1 / aGeometry.back.cx);
        let aThickess = aGeometry.thickness_center;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pOpticsVO.parameters.materialID });

        let n = MaterialUtils.getN(aMaterialVO, pLambda);
        let aEFL = LensCalculator.calculateEFL(R1, R2, aThickess, n);
        return aEFL;
    }
    //__________________________________________________________________________________________
    public static async CALC_EFL(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        if (false == OpticsContext.isConic(pOpticsVO.parameters.shape)) {
            return null;
        }

        let aType = pOpticsVO.parameters.type;

        switch (aType) {
            case eOpticsTypeNames.LENS:
            case eOpticsTypeNames.WINDOW:
                return await OpticUtils._calcEFLSpherical(pOpticsVO, pLambda);

            case eOpticsTypeNames.MIRROR:
                let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
                let c = (Math.SQRT1_2 * Math.hypot(aGeometry.front.cx, aGeometry.front.cy));

                let aSign = (0 != aGeometry.front.cx) ? Math.sign(aGeometry.front.cx) :
                    Math.sign(aGeometry.front.cy);

                let R = (aSign / c);
                let RFL = -(R / 2);

                if (pOpticsVO.parameters.subType == OpticsContext._Off_Axis_Parabolic_Mirror) {
                    let aBrand = pOpticsVO.parameters.info.brand as tBrands;
                    let aOpticsGeo = pOpticsVO.parameters.geometry;
                    let aOffAxisAngle = OpticsDBConvertor.getOffAxisAngle(aBrand, aOpticsGeo);
                    RFL /= Math.pow(Math.cos(aOffAxisAngle / 2), 2);
                }

                return RFL;
            default:
                return null;
        }
    }
    //__________________________________________________________________________________________
    public static async CALC_BFL(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        if (false == OpticsContext.isSpherical(pOpticsVO.parameters.subType)) {
            return null;
        }

        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
        let R1 = (1 / aGeometry.front.cx);
        let R2 = (1 / aGeometry.back.cx);
        let aThickess = aGeometry.thickness_center;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pOpticsVO.parameters.materialID });

        let aN = MaterialUtils.getN(aMaterialVO, pLambda);
        let aBFL = LensCalculator.calculateBFL(R1, R2, aThickess, aN);
        return aBFL;
    }
    //__________________________________________________________________________________________
    public static async CALC_FFL(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        if (false == OpticsContext.isSpherical(pOpticsVO.parameters.subType)) {
            return null;
        }
        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
        let R1 = (1 / aGeometry.front.cx);
        let R2 = (1 / aGeometry.back.cx);
        let aThickess = aGeometry.thickness_center;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pOpticsVO.parameters.materialID });
        let aN = MaterialUtils.getN(aMaterialVO, pLambda);
        let aFFL = LensCalculator.calculateFFL(R1, R2, aThickess, aN);
        return aFFL;
    }
    //__________________________________________________________________________________________
    public static async CALC_EFL_CYLINDRICAL(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        if (false == OpticsContext.isCylindrical(pOpticsVO.parameters.subType)) {
            return null;
        }

        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
        let R1_X = (1 / aGeometry.front.cx);
        let R2_X = (1 / aGeometry.back.cx);
        let R1_Y = (1 / aGeometry.front.cy);
        let R2_Y = (1 / aGeometry.back.cy);
        let aThickess = aGeometry.thickness_center;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pOpticsVO.parameters.materialID });

        let n = MaterialUtils.getN(aMaterialVO, pLambda);
        let aEFL_X = LensCalculator.calculateEFL(R1_X, R2_X, aThickess, n);
        let aEFL_Y = LensCalculator.calculateEFL(R1_Y, R2_Y, aThickess, n);

        let aEFL: iPoint2D = {
            x: aEFL_X,
            y: aEFL_Y
        };

        return aEFL;
    }
    //__________________________________________________________________________________________
    public static async CALC_BFL_CYLINDRICAL(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        if (false == OpticsContext.isCylindrical(pOpticsVO.parameters.subType)) {
            return null;
        }

        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
        let R1_X = (1 / aGeometry.front.cx);
        let R2_X = (1 / aGeometry.back.cx);
        let R1_Y = (1 / aGeometry.front.cy);
        let R2_Y = (1 / aGeometry.back.cy);
        let aThickess = aGeometry.thickness_center;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pOpticsVO.parameters.materialID });

        let aN = MaterialUtils.getN(aMaterialVO, pLambda);
        let aBFL_X = LensCalculator.calculateBFL(R1_X, R2_X, aThickess, aN);
        let aBFL_Y = LensCalculator.calculateBFL(R1_Y, R2_Y, aThickess, aN);

        let aBFL: iPoint2D = {
            x: aBFL_X,
            y: aBFL_Y
        };

        return aBFL;
    }
    //__________________________________________________________________________________________
    public static async CALC_FFL_CYLINDRICAL(pOpticsVO: iOpticsVO, pLambda: number = 550) {
        if (false == OpticsContext.isCylindrical(pOpticsVO.parameters.subType)) {
            return null;
        }

        let aGeometry = OpticsDBConvertor.getConvertedOptics(pOpticsVO) as iGeneralConicLens;
        let R1_X = (1 / aGeometry.front.cx);
        let R2_X = (1 / aGeometry.back.cx);
        let R1_Y = (1 / aGeometry.front.cy);
        let R2_Y = (1 / aGeometry.back.cy);
        let aThickess = aGeometry.thickness_center;

        let aMaterialVO = await MaterialDataLoader.instance.getSingleFullData({ number_id: pOpticsVO.parameters.materialID });
        let aN = MaterialUtils.getN(aMaterialVO, pLambda);
        let aFFL_X = LensCalculator.calculateFFL(R1_X, R2_X, aThickess, aN);
        let aFFL_Y = LensCalculator.calculateFFL(R1_Y, R2_Y, aThickess, aN);


        let aFFL: iPoint2D = {
            x: aFFL_X,
            y: aFFL_Y
        };

        return aFFL;
    }
    //__________________________________________________________________________________________
}
