import classes from "./../UI/header.module.scss";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

export default function OptiChatHeader(props: {
  className: string;
  title: string;
  onClose: () => void;
  onReset?: () => void;
  onInfo?: Function;
  hasInfoBtn: boolean;
  hasReset: boolean;
}) {
  const onClickInfoHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onInfo();
  };

  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClose();
  };

  const onReset = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onReset();
  };

  return (
    <div className={`${props.className} ${classes.header}`}>
      <div className={classes.header__title}>
        <div className={classes.title__name}>{props.title}</div>
        {props.hasInfoBtn && (
          <div className={classes.info} onClick={onClickInfoHandler}>
            <img alt="Info" />
          </div>
        )}
      </div>
      <div className={classes.header__controls}>
        {props.hasReset && (
          <div
            className={classes.reset}
            onClick={onReset}
            data-tooltip-content="Reset"
            data-tooltip-id="header-reset"
          >
            <Tooltip id="header-reset" place="top" />
            <img />
          </div>
        )}
        <div
          data-testid="close-btn-header"
          data-tooltip-content="Close"
          data-tooltip-id="header-close"
          className={classes.close}
          onClick={onClose}
        >
          <Tooltip id="header-close" place="top" />
          <img />
        </div>
      </div>
    </div>
  );
}
