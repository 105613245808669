import { EventBase } from "../../../oc/events/EventBase";
import { EventManager } from "../../../oc/events/EventManager";
import { ePolarizationSourceType } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { AnalysesSynchronizer, iLockAnalsysData } from "./AnalysesSynchronizer";
import { AnalysisCache, iAnalysisCacheData } from "./AnalysisCache";
import { AnalysisCanvasUtils } from "./AnalysisCanvasUtils";
import { AnalysisConstants } from "./AnalysisConstants";
import { tAnalysisType, eAnalysisDisplay, eAnalysisType, AnalysisContext } from "./AnalysisContext";

export interface iSingleQVItem {
    listenerId: string;
    name: tAnalysisType;
    display: eAnalysisDisplay;
    graphContainer: HTMLElement,
    spinner: HTMLElement,
    isLocked: boolean,
    mainContainer: HTMLElement,
    analysisId: string;
    faceID: string;
    lockBtn: HTMLElement;
}
export class QuickViewHandler extends Op3dComponentBase {

    private static INSTANCE: QuickViewHandler;
    public static isAnalysisFromQuickView: boolean;
    private static SKIN_PATH = "./skins/charts/analysis_quick_view.html";
    private mQVItemTemplate: HTMLElement;
    private mQVParent: HTMLElement;
    private mId = Op3dUtils.idGenerator();
    private mItems = new Array<iSingleQVItem>();

    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: QuickViewHandler.SKIN_PATH,
            minimizeData: {
                title: "Quick View",
                showCallback: () => this.open(),
                htmlItem: null,
                iconClasses: "bi bi-box-arrow-up-right"
            }
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aContainer = document.createElement('div');
            //aDiv.classList.add('modal');
            aContainer.id = "quick-view-container";
            //aDiv.style.resize = "both";
            aContainer.classList.add('modal');
            aContainer.classList.add('fade');
            aContainer.style.resize = "both";
            this.isAnalysisFromQuickView = false;
            if (Op3dContext.USER_VO.email == 'guest@3doptix.com') {
                aContainer.style.top = '55px'
            }

            aContainer.style.height = "auto";
            aContainer.style.width = "auto";
            aContainer.style.zIndex = "1062";
            // aContainer.style.zIndex = "1700";
            aContainer.setAttribute('data-backdrop', 'false');
            document.getElementById('forms').appendChild(aContainer);
            this.INSTANCE = new QuickViewHandler(aContainer)

        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public get qvItems(): Array<string> {
        let aIds = this.mItems.map(item => item.analysisId);
        return aIds;
    }
    //__________________________________________________________________________________________
    protected _onNew() {
        while (this.mItems.length > 0) {
            let aItem = this.mItems.pop();
            ViewUtils.removeFromParent(aItem.mainContainer);
        }
        this.close();
    }
    //__________________________________________________________________________________________
    public clearQVItems() {
        while (this.mItems.length > 0) {
            let aItem = this.mItems.pop();
            ViewUtils.removeFromParent(aItem.mainContainer);
        }
    }
    //__________________________________________________________________________________________
    public async addAnalysis(pData: {
        name: tAnalysisType,
        display: eAnalysisDisplay, analysisId: string, faceID: string
    }) {
        if (!this.mIsVisible) {
            await this.open();
        }

        let aIdx = this.mItems.findIndex(item => item.analysisId == pData.analysisId);
        if (aIdx != -1) {
            // item already exists
            return;
        }

        let aClone = this.mQVItemTemplate.cloneNode(true) as HTMLElement;
        let aGraphContainer = Op3dUtils.getElementIn(aClone, "graph-container", true);

        this.mQVParent.appendChild(aClone);
        let aDeleteBtn = Op3dUtils.getElementIn(aClone, "delete-btn", true);
        aDeleteBtn.addEventListener("click", () => this.onDeleteQV(pData.analysisId));

        let aLabelSign = Op3dUtils.getElementIn(aClone, "analysis-label-sign", true);
        let aColor = AnalysisConstants.ANALYSES_OPTIONS.find(item => item.name === pData.name && eAnalysisType.FAST === item.type).color;
        aLabelSign.classList.add(aColor);
        let aName = Op3dContext.PARTS_MANAGER.getPartByFaceID(pData.faceID).getIndexedLabel(true);
        let aTxt = aName + ' - ' + pData.name;
        aLabelSign.setAttribute("tooltip_title", aTxt);
        this._addTooltip(aLabelSign);

        let aSpinner = Op3dUtils.getElementIn(aClone, "analysis-spinner", true);
        ViewUtils.setElementVisibilityByDNone(aSpinner, false);
        let aLockBtn = Op3dUtils.getElementIn(aClone, "lock-btn", true);

        let aListnerId = this.mId + this.mItems.length;
        let aRes: iSingleQVItem = {
            listenerId: aListnerId,
            isLocked: false,
            mainContainer: aClone,
            graphContainer: aGraphContainer,
            display: pData.display,
            name: pData.name,
            analysisId: pData.analysisId,
            spinner: aSpinner,
            faceID: pData.faceID,
            lockBtn: aLockBtn
        }

        AnalysisCache.addEventListener(aRes.listenerId, () => this._onAnalysisFinished(aListnerId),
            [pData.analysisId], true);

        AnalysesSynchronizer.instance.setLockedItems({ analysisID: pData.analysisId, isLocked: false }, false);

        $(this.mContainer).find('[data-toggle="tooltip"]').each((_index, element) => {
            this._addTooltip(element as HTMLElement);
        });

        EventManager.addEventListener<Part>(EventsContext.PART_SELECTED,
            (pData: EventBase<Part>) =>
                this._onSelectItem(pData.data), this);

        EventManager.addEventListener<Part>(EventsContext.PART_DESELECTED,
            () => this._clearActive(), this);

        this.mItems.push(aRes);
        aLockBtn.addEventListener("click", (e) => this._lockItem(e, aRes));

        this._updateVisualItem(this.mItems.length - 1);

        if (Op3dContext.PARTS_MANAGER.selectedPart) {
            let aFaces = Op3dContext.PARTS_MANAGER.selectedPart.getFaces().map(el => el.internal_id)

            this.mItems.forEach(el => {
                if (aFaces.includes(el.faceID)) {
                    el.graphContainer.classList.add('active')
                }
            })
        }
    }
    //__________________________________________________________________________________________
    private _addTooltip(pElement: HTMLElement) {
        pElement.addEventListener('mouseenter',
            () => {
                let aNavBB = pElement.getBoundingClientRect();
                Op3dContext.TOOLTIP.show({
                    clientX: aNavBB.right,
                    clientY: aNavBB.top
                }, pElement.getAttribute("tooltip_title"));
            });


        pElement.addEventListener('mouseleave', () => Op3dContext.TOOLTIP.hide());
    }
    //__________________________________________________________________________________________
    private _onLockChanged(pData: iLockAnalsysData) {
        let aItem = this.mItems.find(item => item.analysisId == pData.analysisID);
        if (aItem != null) {
            aItem.isLocked = pData.isLocked;
            ViewUtils.setClassForElement(aItem.lockBtn, "open", !pData.isLocked);
        }
    }
    //__________________________________________________________________________________________
    private _clearActive() {
        this.mItems.forEach(el => {
            el.graphContainer.classList.remove('active')

        })
    }
    //__________________________________________________________________________________________
    private _onSelectItem(pPart: Part) {
        this._clearActive()

        let aFaces = pPart.getFaces().map(el => el.internal_id)

        this.mItems.forEach(el => {
            if (aFaces.includes(el.faceID)) {
                el.graphContainer.classList.toggle('active')
            }
        })

    }
    //__________________________________________________________________________________________
    private _lockItem(e: Event, pResult: iSingleQVItem) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        let aIsOpen = ViewUtils.toggleClass(pResult.lockBtn, "open");
        pResult.isLocked = !aIsOpen;
        AnalysesSynchronizer.instance.setLockedItems({
            analysisID: pResult.analysisId,
            isLocked: pResult.isLocked
        }, true);
    }
    //__________________________________________________________________________________________
    public onDeleteQV(pAnalysisId: string): any {
        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip('hide')
        let aIdx = this.mItems.findIndex(item => item.analysisId == pAnalysisId);
        if (aIdx > -1) {

            ViewUtils.removeFromParent(this.mItems[aIdx].mainContainer);
            AnalysisCache.removeEventListener(this.mItems[aIdx].listenerId);
            this.mItems.splice(aIdx, 1);

            if (0 == this.mItems.length) {
                this.close();
            }
        }
    }
    //__________________________________________________________________________________________
    public show() {
        $(this.mContainer).modal().show("fast");
        this.mIsVisible = true;
    }
    //__________________________________________________________________________________________
    public hide() {

        $(this.mContainer).modal().hide("fast")
        this.mIsVisible = false;
    }
    //__________________________________________________________________________________________
    protected _onShown(): void {
        $(this.mContainer).resize(() => this._onResize());
    }
    //__________________________________________________________________________________________
    public updateAll() {
        for (let i = 0; i < this.mItems.length; i++) {
            this._updateVisualItem(i);
        }
    }
    //__________________________________________________________________________________________
    private _onResize() {
        if (this.mIsVisible == false) {
            return;
        }

        this.updateAll();
    }
    //__________________________________________________________________________________________
    protected _onOpen(_pData?: any): void {
        this._onResize();
    }
    //__________________________________________________________________________________________
    private _updateVisualItem(pIdx: number) {

        if (this.mItems[pIdx].isLocked) {
            return;
        }

        let aCacheData: iAnalysisCacheData = AnalysisCache.getSpecificCombinedData({
            id: this.mItems[pIdx].analysisId,
            name: this.mItems[pIdx].name,
            polarization: ePolarizationSourceType.X,
            polarizationKind: ePolarizationSourceType.X,
            wavelengths: []

        }, false);

        let aDisplayType: eAnalysisDisplay = this.mItems[pIdx].display;

        const aResult = AnalysisCanvasUtils.initCanvas({
            container: this.mItems[pIdx].graphContainer,
            containerID: AnalysisContext.CANVAS_CONTAINER,
            isAdditionalCanvas: false,
            isTextual: false,
            fitToContainer: false
        });

        let aCanvas: CanvasImageSource;
        if (aCacheData != undefined) {
            aCanvas = AnalysisCanvasUtils.drawImage(aCacheData.matrix.working.matrix,
                aCacheData.common_data.resolution,
                aDisplayType,
                aCacheData.matrix.working.values_range,
                aCacheData.common_data.spectralColors);

            aResult.ctx.drawImage(
                aCanvas,
                0, 0,
                aCacheData.common_data.resolution.x, aCacheData.common_data.resolution.y,
                0, 0,
                aResult.size.width, aResult.size.height,
            );
        } else {
            var txt = "run analysis to update";
            aResult.ctx.font = '14px Arial';
            aResult.ctx.fillStyle = '#23a7de';
            aResult.ctx.fillRect(0, 0, aResult.size.width, aResult.size.height);
            aResult.ctx.fillStyle = "white";
            aResult.ctx.fillText(txt, 5, 80);
        }
    }
    //__________________________________________________________________________________________
    public onUpdateBtn(pIds: Array<string>) {
        pIds.forEach(id => {
            let aAnalysis = this.mItems.find(analysis => analysis.analysisId == id);
            if (aAnalysis != null) {
                if (aAnalysis.isLocked == false) {
                    ViewUtils.setElementVisibilityByDNone(aAnalysis.spinner, true);
                }
            }
        });
        // for (let i = 0; i < this.mItems.length; i++) {
        //     if (this.mItems[i].isLocked == false) {
        //         ViewUtils.setElementVisibilityByDNone(this.mItems[i].spinner, true);
        //     }
        // }
    }
    //__________________________________________________________________________________________
    private _onAnalysisFinished(pListenerId: string) {
        let aIdx = this.mItems.findIndex(item => item.listenerId == pListenerId);
        if (aIdx != -1) {

            if (this.mItems[aIdx].isLocked) {
                return;
            }

            let aCacheData: iAnalysisCacheData = AnalysisCache.getSpecificCombinedData({
                id: this.mItems[aIdx].analysisId,
                name: this.mItems[aIdx].name,
                polarization: ePolarizationSourceType.X,
                polarizationKind: ePolarizationSourceType.X,
                wavelengths: []
            }, false);

            if (aCacheData == null) {
                // not ready yet
                return;
            }

            ViewUtils.setElementVisibilityByDNone(this.mItems[aIdx].spinner, false);
            this._updateVisualItem(aIdx);
        }
    }
    //__________________________________________________________________________________________

    protected _onCreationComplete(): void {
        this.mQVItemTemplate = this._getPart("qv-item", true);
        this.mQVParent = this.mQVItemTemplate.parentElement;
        ViewUtils.removeFromParent(this.mQVItemTemplate);
        let aAlignGridBtn = this._getPart("align-grid");
        aAlignGridBtn.addEventListener("click", () => this._changeGridAlignment(aAlignGridBtn));

        // this._getPart("update-btn").addEventListener("click", () => this._onUpdateBtn());
        $(this.mContainer).draggable({
            containment: 'window',
            snap: true
        });

        if (Op3dContext.USER_VO.email == 'guest@3doptix.com') {
            this.mQVItemTemplate.getElementsByClassName('analysis-btns-hover')[0].classList.add('d-none')
            this._getPart("minimize-btn").classList.add('d-none')
        }


        // document.getElementsByClassName('analysis-btns-hover')[0].classList.add('d-none')

        EventManager.addEventListener<iLockAnalsysData>(EventsContext.LOCKED_ANALYSIS,
            (pData: EventBase<iLockAnalsysData>) => this._onLockChanged(pData.data), this);

        EventManager.addEventListener(EventsContext.ON_CLOSE,
            () => this.hide(), this);

        this.mIsReady = true;

    }
    //__________________________________________________________________________________________
    private _changeGridAlignment(pBtn: HTMLElement) {
        if (this.mItems.length > 1) {

            ViewUtils.toggleClass(this.mQVParent, "flex-direction-column");
            ViewUtils.toggleClass(pBtn, "flex-column-grid");
        }
    }
    //__________________________________________________________________________________________
}
