import { jsPDF } from "jspdf";


export class PDFImageGenerator {
    private static A4_SIZE_LANDSCAPE = { width: 297, height: 210 };

    constructor() { }
    //___________________________________________________________
    public static saveImage(pImgData: string, pIsLandscape: boolean, pWidth: number, pHeight: number, pName?:string) {
        if (pName === undefined) {
            pName = "image";
        }
        let aJsPDF = (true === pIsLandscape) ? new jsPDF('l') : new jsPDF();
        let aRatio = pWidth / pHeight;
        let aLeftMargins = 10;
        let aNewWidth = PDFImageGenerator.A4_SIZE_LANDSCAPE.width - aLeftMargins;
        let aNewHeight = aNewWidth / aRatio;
        let aMarginX = (PDFImageGenerator.A4_SIZE_LANDSCAPE.width - aNewWidth) / 2;
        let aMarginY = (PDFImageGenerator.A4_SIZE_LANDSCAPE.height - aNewHeight) / 2;
        aJsPDF.addImage(pImgData, 'JPEG', aMarginX, aMarginY, aNewWidth, aNewHeight);
        aJsPDF.save(`${pName}.pdf`);
    }
    //___________________________________________________________
}
