import { iOpticsVO } from "../data/VO/OpticsVOInterfaces";
import { SurfaceContext } from "../parts/optics/SurfaceContext";
import { eDataPermission } from "./Enums";
import { iHash, iNumericKeyHash } from "./_interfaces/Interfaces";

export enum eParamUnitType {
    NONE,
    LENGHT,
    ANGLE_RAD
};

export interface iOpticalElementTypesData {
    subtypes: Array<{
        /**
         *@description name of subtype
         */
        name: string;
        /**
         * @description enum of the shape
         */
        shape: eOpticShape;
        /**
         * @description whether the type is supported now or not
         */
        pending?: boolean;
        /**
         *  Coated Surfaces List
         */
        coatedSurfacesMap?: Array<Array<string>>;
        baseShapes: Array<eBaseShape>;
        showOnOpticsMenu?: boolean;
        selectableOnUploadOptics?: boolean;
    }>,
    type: string;
}

export enum eBaseShape {
    CIRCULAR,
    RECTANGULAR,
    VOLUME
}

export enum eOpticShape {
    THIN_LENS, //0
    D_SHAPED, //1
    ODD_ASPHERE, //2
    CONIC, //3
    SPHERICAL, //4
    CYLINDRICAL, //5
    ELLIPSOIDAL,
    PARABOLIC, //7
    ASPHERIC,
    HALF_BALL_LENS,
    BALL_LENS, //10
    OFF_AXIS_PARABOLIC,
    ROOF_PRISM_MIRROR,
    BS_CUBE,
    RIGHT_ANGLE_PRISM,
    EQUAL_PRISM, //15
    WEDGE, // 16
    PENTA_PRISM,
    DISPERSION_PRISM,
    PELLIN_BROCA_PRISM,
    DOVE_PRISM,
    AXICON_PRISM,
    RHOMBIC_PRISM,

    // free forms
    XY_POLYNOMIAL,
    ODD_POLYNOMIAL,
    QCON_ASPHERE,
    Y_TOROID,
    X_TOROID,
    GRATING,

    MULTIPLET,
    PARAXIAL_LENS,

    ZERNIKE,
    APERTURE
};

export enum eOpticsTypeNames {
    ALL = "All",
    MIRROR = "Mirror",
    LENS = "Lens",
    FILTER = "Filter",
    BEAM_SPLITTER = "Beam Splitter",
    PRISM = "Prism",
    GRATING = "Grating",
    WINDOW = "Window",
    // NON_LINEAR_OPTICS = "Nonlinear Optics (Coming soon)",
    // OBJECTIVE = "Objective (Coming soon)",
    // POLARIZER = "Polarizer (Coming soon)",
    // WAVE_PLATE = "Wave Plate (Coming soon)",
    APERTURE = "Aperture",
    POLARIZING_ELEMENT = "Polarizing Element"
}

export class OpticsContext {


    public static isCustomized(pOpticsVO: iOpticsVO) {

        let aCond1 = pOpticsVO.parameters.shape !== eOpticShape.PARAXIAL_LENS;
        let aCond2 = pOpticsVO.permission === eDataPermission.PRIVATE && (pOpticsVO.creator !== pOpticsVO.owner);
        // let aCond3 = (pOpticsVO.parameters.type != eOpticsTypeNames.APERTURE);
        return aCond1 && aCond2;
    }

    //14
    public static _Metallic_Mirror = "Metallic Mirror";
    public static _Dielectric_Mirror = "Dielectric Mirror";
    public static _Dichroic_Mirror = "Dichroic Mirror";
    public static _Curved_Mirror_Concave = "Curved Mirror (Concave)";
    public static _Curved_Mirror_Convex = "Curved Mirror (Convex)";
    public static _Cylindrical_Mirror_Plano_Convex = "Cylindrical Mirror (Plano-Convex)";
    public static _Cylindrical_Mirror_Plano_Concave = "Cylindrical Mirror (Plano-Concave)";
    public static _Parabolic_Mirror_Concave = "Parabolic Mirror (Concave)";
    public static _Off_Axis_Parabolic_Mirror = "Off-Axis Parabolic Mirror";
    public static _D_Shaped_Mirror = "D-Shaped Mirror";
    public static _Knife_Edge_Prism_Mirror = "Knife-Edge Prism Mirror";
    public static _Right_Angle_Prism_Mirror = "Right-Angle Prism Mirror";
    public static _Roof_Prism_Mirror = "Roof Prism Mirror";
    public static _Ellipsoidal = "Ellipsoidal";

    //10
    public static _Knife_Edge_Prism = "Knife-Edge Prism";
    public static _Right_Angle_Prism = "Right-Angle Prism";
    public static _Equilateral_Prism = "Equilateral Prism";
    public static _Dove_Prism = "Dove Prism";
    public static _Wedge_Prism = "Wedge Prism";
    public static _Pellin_Broca_Prism = "Pellin Broca Prism";
    public static _Penta_Prism = "Penta Prism";
    public static _Axicon_Prism = "Axicon Prism";
    public static _Rhombic_Prism = "Rhombic Prism";

    public static _Multiplets = "Multiplets";
    public static _Spherical_Lens_Plano_Convex = "Spherical Lens (Plano-Convex)";
    public static _Spherical_Lens_Plano_Concave = "Spherical Lens (Plano-Concave)";
    public static _Spherical_Lens_Convex_Convex = "Spherical Lens (Convex-Convex)";
    public static _Spherical_Lens_Concave_Concave = "Spherical Lens (Concave-Concave)";
    public static _Spherical_Lens_Concave_Convex = "Spherical Lens (Concave-Convex)";
    public static _Spherical_Lens_Convex_Concave = "Spherical Lens (Convex-Concave)";
    public static _Cylindrical_Lens_Plano_Convex = "Cylindrical Lens (Plano-Convex)";
    public static _Cylindrical_Lens_Plano_Concave = "Cylindrical Lens (Plano-Concave)";
    public static _Ball_Lens = "Ball Lens";
    public static _Half_Ball_Lens = "Half Ball Lens";
    public static _Off_Axis_Parabolic_Lens = "Off-Axis Parabolic Lens";
    public static _Aspheric_Lens = "Aspheric Lens";
    public static _Odd_Asphere = "Odd Asphere";

    public static _Paraxial_Lens = "Paraxial lens";

    public static _Biconic_Zernike = "Biconic Zernike";

    //Filters:
    public static _Longpass_Filter = "Longpass Filter";
    public static _Shortpass_Filter = "Shortpass Filter";
    public static _Bandpass_Filter = "Bandpass Filter";
    public static _Dichroic_Filter = "Dichroic Filter";
    public static _Notch_Filter = "Notch Filter";
    public static _Calibration_Filter = "Calibration Filter";
    public static _Spectral_Shape_Filter = "Spectral Shape Filter";
    public static _Laser_Line_Filter = "Laser-line Filter";
    public static _Color_Glass = "Color Glass";
    public static _Neutral_Density_Filter = "Neutral Density Filter";
    public static _Hot_Cold_Mirror = "Hot/Cold Mirror";
    // public static _Customized = "Customized";

    //Beam Splitter:
    public static _Thin_BS = "Thin BS";
    public static _Cube_BS = "Cube BS";
    public static _Cube_BS_POLARIZED = "Cube BS Polarizing";

    //grating
    public static _Blazed_Ruled_Reflective_Grating = "Blazed Ruled Reflective Grating";
    public static _Reflective_Grating = "Reflective Grating";
    public static _Echelle_Grating = "Echelle Grating";
    public static _Transmission_Grating = "Transmission Grating";

    //Windows
    public static _Flat_Window = "Flat Window";
    public static _Wedge_Window = "Wedge Window";
    public static _Curved_Window_Plano_Convex = "Curved Window (Plano-Convex)";
    public static _Curved_Window_Plano_Concave = "Curved Window (Plano-Concave)";


    //Aperture subtypes
    public static _Elliptical_Aperture = "Elliptical Aperture";
    public static _Rectangular_Aperture = "Rectangula Aperture";
    public static _User_Aperture = "User Aperture";

    // Polarizer Element
    public static _Ideal_Polarizer = "Ideal Polarizer";
    public static _Ideal_Waveplate = "Ideal Waveplate";

    //__________________________________________________________________________________________
    public static showSurfacesSection(pOpticsVO: iOpticsVO) {
        let aIsAperture = pOpticsVO.parameters.type == eOpticsTypeNames.APERTURE;
        let aIsParaxialLens = eOpticShape.PARAXIAL_LENS == pOpticsVO.parameters.shape;
        return !aIsAperture && !aIsParaxialLens;
    }
    //__________________________________________________________________________________________
    public static showUserApertureInfo(pOpticsVO: iOpticsVO) {
        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._User_Aperture:
                return true;
            default:
                return false;
        }
    }
    //__________________________________________________________________________________________
    public static showGeometrialInfo(pOpticsVO: iOpticsVO) {
        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._User_Aperture:
                return false;
            default:
                return true;
        }
    }

    //__________________________________________________________________________________________
    public static SPHERICAL_TYPES_HASH: iNumericKeyHash<iNumericKeyHash<iHash<string>>>;
    //__________________________________________________________________________________________
    private static _initSphericalTypeHash() {
        this.SPHERICAL_TYPES_HASH = {};
        this.SPHERICAL_TYPES_HASH[1] = {};
        this.SPHERICAL_TYPES_HASH[1][0] = {};
        this.SPHERICAL_TYPES_HASH[1][1] = {};
        this.SPHERICAL_TYPES_HASH[1][-1] = {};
        this.SPHERICAL_TYPES_HASH[-1] = {};
        this.SPHERICAL_TYPES_HASH[-1][0] = {};
        this.SPHERICAL_TYPES_HASH[-1][1] = {};
        this.SPHERICAL_TYPES_HASH[-1][-1] = {};

        let aLens = eOpticsTypeNames.LENS;
        let aMirror = eOpticsTypeNames.MIRROR;
        let aWindow = eOpticsTypeNames.WINDOW;

        this.SPHERICAL_TYPES_HASH[1][0][aLens] = OpticsContext._Spherical_Lens_Plano_Convex;
        this.SPHERICAL_TYPES_HASH[1][0][aMirror] = OpticsContext._Curved_Mirror_Convex;
        this.SPHERICAL_TYPES_HASH[1][0][aWindow] = OpticsContext._Curved_Window_Plano_Convex;

        this.SPHERICAL_TYPES_HASH[-1][0][aLens] = OpticsContext._Spherical_Lens_Plano_Concave;
        this.SPHERICAL_TYPES_HASH[-1][0][aMirror] = OpticsContext._Curved_Mirror_Concave;
        this.SPHERICAL_TYPES_HASH[-1][0][aWindow] = OpticsContext._Curved_Window_Plano_Concave;

        this.SPHERICAL_TYPES_HASH[1][1][aLens] = OpticsContext._Spherical_Lens_Convex_Concave;
        this.SPHERICAL_TYPES_HASH[1][-1][aLens] = OpticsContext._Spherical_Lens_Convex_Convex;

        this.SPHERICAL_TYPES_HASH[-1][1][aLens] = OpticsContext._Spherical_Lens_Concave_Concave;
        this.SPHERICAL_TYPES_HASH[-1][-1][aLens] = OpticsContext._Spherical_Lens_Concave_Convex;


    }
    //__________________________________________________________________________________________
    public static CYLINDRICAL_TYPES_HASH: iNumericKeyHash<iNumericKeyHash<string>>;
    //__________________________________________________________________________________________
    private static _initCylindricalTypeHash() {
        this.CYLINDRICAL_TYPES_HASH = {};
        this.CYLINDRICAL_TYPES_HASH[1] = {};
        this.CYLINDRICAL_TYPES_HASH[-1] = {};

        const LENS = eOpticsTypeNames.LENS;
        const MIRROR = eOpticsTypeNames.MIRROR;

        this.CYLINDRICAL_TYPES_HASH[1][LENS] = OpticsContext._Cylindrical_Lens_Plano_Convex;
        this.CYLINDRICAL_TYPES_HASH[1][MIRROR] = OpticsContext._Cylindrical_Mirror_Plano_Convex;

        this.CYLINDRICAL_TYPES_HASH[-1][LENS] = OpticsContext._Cylindrical_Lens_Plano_Concave;
        this.CYLINDRICAL_TYPES_HASH[-1][MIRROR] = OpticsContext._Cylindrical_Mirror_Plano_Concave;
    }

    //__________________________________________________________________________________________
    public static getSphericalSubtype(pR1: number, pR2: number = 0, pType: string) {
        if (undefined === OpticsContext.SPHERICAL_TYPES_HASH) {
            this._initSphericalTypeHash();
        }

        if (pR1 === 0) {
            pR1 = Number.MAX_SAFE_INTEGER;
        }


        return OpticsContext.SPHERICAL_TYPES_HASH[Math.sign(pR1)][Math.sign(pR2)][pType];
    }
    //__________________________________________________________________________________________
    public static getCylindricalSubtype(pRX1: number, pRY1: number, pType: string): string {
        if (undefined === OpticsContext.CYLINDRICAL_TYPES_HASH) {
            this._initCylindricalTypeHash();
        }

        let aR1 = ((Math.abs(pRX1) > Math.abs(pRY1)) ? pRX1 : pRY1);
        return OpticsContext.CYLINDRICAL_TYPES_HASH[Math.sign(aR1)][pType];
    }
    //__________________________________________________________________________________________
    public static isInactiveFace(pOpticsVO: iOpticsVO, pFaceName: string): boolean {
        switch (pOpticsVO.parameters.type) {
            case eOpticsTypeNames.MIRROR:
                return ((pOpticsVO.parameters.subType != this._Dichroic_Mirror) &&
                    (pFaceName == SurfaceContext.BACK));
            case eOpticsTypeNames.GRATING:
                return ((true == OpticsContext._isGratingFaceMirror(pOpticsVO, pFaceName)) &&
                    (pFaceName == SurfaceContext.BACK));
            default:
                break;
        }

        return false;
    }
    //__________________________________________________________________________________________
    public static isMirrorFace(pOpticsVO: iOpticsVO, pFaceName: string): boolean {
        switch (pOpticsVO.parameters.type) {
            case eOpticsTypeNames.MIRROR:
            case eOpticsTypeNames.FILTER:
                return (pFaceName == SurfaceContext.FRONT) || ((pFaceName == SurfaceContext.HYPOTENUSE));
            case eOpticsTypeNames.BEAM_SPLITTER:
                return this._isMirrorFaceCubeBS(pOpticsVO, pFaceName);
            case eOpticsTypeNames.GRATING:
                return this._isGratingFaceMirror(pOpticsVO, pFaceName);
            default:
                break;
        }

        return false;
    }
    //__________________________________________________________________________________________
    public static isCircularConic(pOpticsVO: iOpticsVO) {
        if (eBaseShape.CIRCULAR != pOpticsVO.parameters.baseShape) {
            return;
        }

        switch (pOpticsVO.parameters.shape) {
            case eOpticShape.PARABOLIC:
            case eOpticShape.OFF_AXIS_PARABOLIC:
            case eOpticShape.CYLINDRICAL:
            case eOpticShape.CONIC:
            case eOpticShape.BALL_LENS:
            case eOpticShape.HALF_BALL_LENS:
            case eOpticShape.SPHERICAL:
                return true;
            default:
                return false;
        }
    }
    //__________________________________________________________________________________________
    public static isReflectiveGrating(pGratingSubtype: string) {
        return ((pGratingSubtype == OpticsContext._Reflective_Grating) ||
            (pGratingSubtype == OpticsContext._Blazed_Ruled_Reflective_Grating) ||
            (pGratingSubtype == OpticsContext._Echelle_Grating));
    }
    //__________________________________________________________________________________________
    private static _isGratingFaceMirror(pOpticsVO: iOpticsVO, pFaceName: string): boolean {
        let aSubType = pOpticsVO.parameters.subType;
        let aIsReflective = ((aSubType == OpticsContext._Blazed_Ruled_Reflective_Grating) ||
            (aSubType == OpticsContext._Reflective_Grating));

        return ((true == aIsReflective) && (pFaceName == SurfaceContext.FRONT));
    }
    //__________________________________________________________________________________________
    private static _isMirrorFaceCubeBS(pOpticsVO: iOpticsVO, pFaceName: string): boolean {
        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._Cube_BS:
                return (pFaceName == SurfaceContext.HYPOTENUSE_1);
            case OpticsContext._Thin_BS:
                return (pFaceName == SurfaceContext.FRONT);
            default:
                throw new Error("Wrong subtype");
        }
    }
    //__________________________________________________________________________________________
    public static isConic(pShape: eOpticShape) {
        switch (pShape) {
            case eOpticShape.PARABOLIC:
            case eOpticShape.OFF_AXIS_PARABOLIC:
            case eOpticShape.CONIC:
            case eOpticShape.BALL_LENS:
            case eOpticShape.HALF_BALL_LENS:
            case eOpticShape.SPHERICAL:
                return true;
            default:
                return false;
        }
    }
    //__________________________________________________________________________________________
    public static isSpherical(pSubType: string) {
        switch (pSubType) {
            case OpticsContext._Spherical_Lens_Convex_Concave:
            case OpticsContext._Spherical_Lens_Concave_Convex:
            case OpticsContext._Spherical_Lens_Concave_Concave:
            case OpticsContext._Spherical_Lens_Convex_Convex:
            case OpticsContext._Curved_Window_Plano_Concave:
            case OpticsContext._Curved_Window_Plano_Convex:
            case OpticsContext._Spherical_Lens_Plano_Convex:
            case OpticsContext._Spherical_Lens_Plano_Concave:
            case OpticsContext._Curved_Mirror_Concave:
            case OpticsContext._Curved_Mirror_Convex:
                return true;
            default:
                return false;
        }
    }
    //__________________________________________________________________________________________
    public static isCylindrical(pSubType: string) {
        switch (pSubType) {
            case OpticsContext._Cylindrical_Lens_Plano_Concave:
            case OpticsContext._Cylindrical_Lens_Plano_Convex:
            case OpticsContext._Cylindrical_Mirror_Plano_Concave:
            case OpticsContext._Cylindrical_Mirror_Plano_Convex:
                return true;
            default:
                return false;
        }
    }
    //__________________________________________________________________________________________
    public static isSphericalToShow(pSubType: string) {
        switch (pSubType) {
            case OpticsContext._Spherical_Lens_Convex_Concave:
            case OpticsContext._Spherical_Lens_Concave_Convex:
            case OpticsContext._Spherical_Lens_Concave_Concave:
            case OpticsContext._Spherical_Lens_Convex_Convex:
            case OpticsContext._Curved_Window_Plano_Concave:
            case OpticsContext._Curved_Window_Plano_Convex:
            case OpticsContext._Spherical_Lens_Plano_Convex:
            case OpticsContext._Spherical_Lens_Plano_Concave:
                return true;
            default:
                return false;
        }
    }

    //__________________________________________________________________________________________
    public static getEnumShapeBySubtype(pSubtype: string) {
        for (let type in OpticsContext.OPTICS_DATA) {
            let aSubtypes = OpticsContext.OPTICS_DATA[type].subtypes;
            let aCurrItem = aSubtypes.find(item => item.name == pSubtype);
            if (aCurrItem != null) {
                return aCurrItem.shape;
            }
        }

        return eOpticShape.THIN_LENS;
    }
    //__________________________________________________________________________________________
    public static getSubtypes(pType: string): Array<string> {
        let aItem = OpticsContext.OPTICS_DATA.find(item => item.type == pType);
        if (aItem == null) {
            return [];
        }

        let aSubtypes = aItem.subtypes.map(item => item.name);
        return aSubtypes;
    }
    //__________________________________________________________________________________________
    public static OPTICS_DATA: Array<iOpticalElementTypesData> = [
        {
            type: eOpticsTypeNames.MIRROR,
            subtypes: [{
                name: OpticsContext._Metallic_Mirror,
                shape: eOpticShape.THIN_LENS,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
            },
            {
                name: OpticsContext._Dielectric_Mirror,
                shape: eOpticShape.THIN_LENS,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
            },
            {
                name: OpticsContext._Dichroic_Mirror,
                shape: eOpticShape.THIN_LENS,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
            },
            {
                name: OpticsContext._Curved_Mirror_Concave,
                shape: eOpticShape.SPHERICAL,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT],
                    [SurfaceContext.BACK]
                ],
                baseShapes: [eBaseShape.CIRCULAR]
            },
            {
                name: OpticsContext._Curved_Mirror_Convex,
                shape: eOpticShape.SPHERICAL,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT],
                ],
                baseShapes: [eBaseShape.CIRCULAR]
            },
            {
                name: OpticsContext._Cylindrical_Mirror_Plano_Convex,
                shape: eOpticShape.CYLINDRICAL,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR]
            },
            {
                name: OpticsContext._Cylindrical_Mirror_Plano_Concave,
                shape: eOpticShape.CYLINDRICAL,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR]
            },
            {
                name: OpticsContext._Parabolic_Mirror_Concave,
                shape: eOpticShape.PARABOLIC,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR]
            },
            {
                name: OpticsContext._Off_Axis_Parabolic_Mirror,
                shape: eOpticShape.OFF_AXIS_PARABOLIC,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR]
            },
            // {
            //     name: OpticsContext._D_Shaped_Mirror,
            //     shape: eOpticShape.D_SHAPED,
            //     coatedSurfacesMap: [
            //         [SurfaceContext.FRONT]
            //     ],
            //     // should be hidden for now 
            //     baseShapes: [eBaseShape.CIRCULAR]
            // },
            {
                name: OpticsContext._Knife_Edge_Prism_Mirror,
                shape: eOpticShape.RIGHT_ANGLE_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT,
                    SurfaceContext.RIGHT],
                    [SurfaceContext.HYPOTENUSE]
                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Right_Angle_Prism_Mirror,
                shape: eOpticShape.RIGHT_ANGLE_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT,
                    SurfaceContext.RIGHT],
                    [SurfaceContext.HYPOTENUSE]
                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Roof_Prism_Mirror,
                shape: eOpticShape.ROOF_PRISM_MIRROR,

                coatedSurfacesMap: [
                    [SurfaceContext.FRONT_RIGHT,
                    SurfaceContext.FRONT_LEFT],
                    [SurfaceContext.FRONT,
                    SurfaceContext.BACK]
                ],
                /**
                 * @TODO:
                 * the circular shape should be supported in the future
                 */
                // baseShapes: [eBaseShape.RECTANGULAR,eBaseShape.CIRCULAR]
                baseShapes: [eBaseShape.RECTANGULAR]
            },

            ]
        },
        {
            type: eOpticsTypeNames.PRISM,
            subtypes: [{
                name: OpticsContext._Knife_Edge_Prism,
                shape: eOpticShape.RIGHT_ANGLE_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT,
                    SurfaceContext.RIGHT],
                    [SurfaceContext.BACK],

                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Right_Angle_Prism,
                shape: eOpticShape.RIGHT_ANGLE_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT,
                    SurfaceContext.RIGHT],
                    [SurfaceContext.BACK]
                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Equilateral_Prism,
                shape: eOpticShape.EQUAL_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT,
                    SurfaceContext.LEFT,
                    SurfaceContext.BACK]
                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Dove_Prism,
                shape: eOpticShape.DOVE_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT, SurfaceContext.BACK],
                    [SurfaceContext.BOTTOM]
                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Wedge_Prism,
                shape: eOpticShape.WEDGE,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT, SurfaceContext.BACK]
                ],
                baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
            },
            {
                name: OpticsContext._Pellin_Broca_Prism,
                shape: eOpticShape.PELLIN_BROCA_PRISM,
                //There is no coating in the db for now.
                baseShapes: [eBaseShape.VOLUME]
            },
            {
                name: OpticsContext._Penta_Prism,
                shape: eOpticShape.PENTA_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT, SurfaceContext.RIGHT],
                    [SurfaceContext.FRONT_LEFT,
                    SurfaceContext.REAR_RIGHT]
                ],
                baseShapes: [eBaseShape.VOLUME]
            },
            // {
            //     name: OpticsContext._Axicon_Prism,
            //     shape: eOpticShape.AXICON_PRISM
            //     // not supported for now
            // },
            {
                name: OpticsContext._Rhombic_Prism,
                shape: eOpticShape.RHOMBIC_PRISM,
                coatedSurfacesMap: [
                    [SurfaceContext.RIGHT,
                    SurfaceContext.LEFT]
                ],
                baseShapes: [eBaseShape.VOLUME]
            }
            ]
        },
        {
            type: eOpticsTypeNames.APERTURE,
            subtypes: [
                {
                    name: OpticsContext._User_Aperture,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.RECTANGULAR]
                },
            ]
        },
        {
            type: eOpticsTypeNames.LENS,
            subtypes: [
                {
                    name: OpticsContext._Spherical_Lens_Plano_Convex,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Spherical_Lens_Plano_Concave,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Spherical_Lens_Convex_Convex,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Spherical_Lens_Concave_Concave,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Spherical_Lens_Concave_Convex,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Spherical_Lens_Convex_Concave,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Cylindrical_Lens_Plano_Convex,
                    shape: eOpticShape.CYLINDRICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Cylindrical_Lens_Plano_Concave,
                    shape: eOpticShape.CYLINDRICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Ball_Lens,
                    shape: eOpticShape.BALL_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Half_Ball_Lens,
                    shape: eOpticShape.HALF_BALL_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                // {
                //     name: OpticsContext._Off_Axis_Parabolic_Lens,
                //     shape: eOpticShape.OFF_AXIS_PARABOLIC,
                //     //TODO
                // },
                {
                    name: OpticsContext._Aspheric_Lens,
                    shape: eOpticShape.ASPHERIC,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Multiplets,
                    shape: eOpticShape.MULTIPLET,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT,
                        SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Paraxial_Lens,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        []
                    ],
                    showOnOpticsMenu: false,
                    selectableOnUploadOptics: false,
                    baseShapes: [eBaseShape.CIRCULAR]
                }
            ]
        },
        {
            type: eOpticsTypeNames.FILTER,
            subtypes: [
                {
                    name: OpticsContext._Longpass_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Shortpass_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Bandpass_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Dichroic_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Notch_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Calibration_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Spectral_Shape_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Laser_Line_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Color_Glass,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Neutral_Density_Filter,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Hot_Cold_Mirror,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                // {
                //     name: OpticsContext._Customized,
                //     shape: eOpticShape.THIN_LENS,
                //     coatedSurfacesMap: [
                //         [SurfaceContext.FRONT, SurfaceContext.BACK]
                //     ],
                //     baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                // }
            ]
        },
        {
            type: eOpticsTypeNames.BEAM_SPLITTER,
            subtypes: [
                {
                    name: OpticsContext._Thin_BS,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT],
                        [SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Cube_BS,
                    shape: eOpticShape.BS_CUBE,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK, SurfaceContext.LEFT,
                        SurfaceContext.RIGHT],
                        [SurfaceContext.HYPOTENUSE_1],
                        [SurfaceContext.HYPOTENUSE_2]
                    ],
                    baseShapes: [eBaseShape.VOLUME]
                },
                {
                    name: OpticsContext._Cube_BS_POLARIZED,
                    shape: eOpticShape.BS_CUBE,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK, SurfaceContext.LEFT,
                        SurfaceContext.RIGHT],
                        [SurfaceContext.HYPOTENUSE_1],
                        [SurfaceContext.HYPOTENUSE_2]
                    ],
                    baseShapes: [eBaseShape.VOLUME]
                }
            ]
        },
        {
            type: eOpticsTypeNames.GRATING,
            subtypes: [
                {
                    name: OpticsContext._Blazed_Ruled_Reflective_Grating,
                    shape: eOpticShape.GRATING,
                    coatedSurfacesMap: [
                        []
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                }, {
                    name: OpticsContext._Reflective_Grating,
                    shape: eOpticShape.GRATING,
                    coatedSurfacesMap: [
                        []
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Echelle_Grating,
                    shape: eOpticShape.GRATING,
                    coatedSurfacesMap: [
                        []
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Transmission_Grating,
                    shape: eOpticShape.GRATING,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                }
            ]
        },
        {
            type: eOpticsTypeNames.WINDOW,
            subtypes: [
                {
                    name: OpticsContext._Flat_Window,
                    shape: eOpticShape.THIN_LENS,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
                },
                {
                    name: OpticsContext._Wedge_Window,
                    shape: eOpticShape.WEDGE,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.RECTANGULAR, eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Curved_Window_Plano_Convex,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                },
                {
                    name: OpticsContext._Curved_Window_Plano_Concave,
                    shape: eOpticShape.SPHERICAL,
                    coatedSurfacesMap: [
                        [SurfaceContext.FRONT, SurfaceContext.BACK]
                    ],
                    baseShapes: [eBaseShape.CIRCULAR]
                }
            ]
        },

        {
            type: eOpticsTypeNames.POLARIZING_ELEMENT,
            subtypes: [{
                name: OpticsContext._Ideal_Polarizer,
                shape: eOpticShape.THIN_LENS,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
            },
            {
                name: OpticsContext._Ideal_Waveplate,
                shape: eOpticShape.THIN_LENS,
                coatedSurfacesMap: [
                    [SurfaceContext.FRONT]
                ],
                baseShapes: [eBaseShape.CIRCULAR, eBaseShape.RECTANGULAR]
            },
        ]
        }
    ];
}

//__________________________________________________________________________________________

