import {
    BufferAttribute, BufferGeometry, DoubleSide, Float32BufferAttribute,
    LineBasicMaterial, Mesh, MeshBasicMaterial, MeshPhysicalMaterial,
    Object3D, PointsMaterial, TextureLoader
} from "three";
import { UserPermissions } from "../UserPermissions";
import { Main } from "../_main/Main";
import { SetupsManagerWidget } from "../_widget/SetupManagerWidget";
import { DataManager } from "../data/DataManager";
import { UserVO } from "../data/VO/UserVO";
import { Part } from "../parts/Part";
import { absPartsManager } from "../parts/absPartsManager";
import { GridManager } from "../scene/GridManager";
import { SceneContext } from "../scene/SceneContext";
import { absSceneHistory } from "../scene/absSceneHistory";
import { SetupsManager } from "../setups/SetupManager";
import { InputDispatcher } from "../ui/_globals/InputDispatcher";
import { PartsEventsHandler } from "../ui/_globals/PartsEventsHandler";
import { absDivController } from "../ui/absDivController";
import { SidebarList } from "../ui/home/SidebarList";
import { op3dTooltip } from "../ui/home/op3dTooltip";
import { PartInfo } from "../ui/part_info/PartInfo";
import { eGpuType } from "./Enums";
import { iAppFeaturesConfig, iHash } from "./_interfaces/Interfaces";

export class Op3dContext {

    public static TOTAL_PARTS: number = 0;
    public static LOADED_PARTS: number = 0

    public static WELCOME_TUTORIAL_ID: string = "KL7T290F9B73T";
    public static IS_IN_LIVE_MODE: boolean = false;
    public static ALIGNMENT_MODE: boolean = true;
    public static VERSION_NUM: string = "";
    public static SETUP_NUM: string = "";
    public static USER_VO: UserVO;
    public static TOOLTIP: op3dTooltip;
    public static GPU_MACHINE: eGpuType = eGpuType.DEFAULT;

    public static setupIsLoaded: boolean = false;
    public static SIDEBAR_LIST: SidebarList;

    /**
     * The dom element that owns the canvas
     */
    public static CONTAINER: HTMLElement;


    public static AXIS_MODEL: Object3D;
    public static DATA_MANAGER: DataManager;
    public static DIV_CONTROLLER: absDivController;
    public static MANAGER: Main;
    public static PARTS_MANAGER: absPartsManager;

    public static INPUT_DISPATCHER: InputDispatcher;
    public static PARTS_EVENTS_HANDLER: PartsEventsHandler;
    public static SETUPS_MANAGER: SetupsManager | SetupsManagerWidget;
    public static SCENE_HISTORY: absSceneHistory;
    public static USER_PERMISSION: UserPermissions;
    public static APP_FEATURES_CONFIG: iAppFeaturesConfig;

    public static OPTICAL_AXIS_HEIGHT: number = (4 * GridManager.CELL_SIZE);
    public static PART_INFO: PartInfo;

    public static isWidget: boolean = false;
    public static isWidgetAnalysis: boolean = false;

    public static IMAGES_HASH: iHash<string> = {};

    public static GLOBAL_MATERIAL = new MeshPhysicalMaterial({
        vertexColors: true,
        side: DoubleSide,
        transparent: true,
        polygonOffset: true,
        polygonOffsetFactor: 1.0,
        polygonOffsetUnits: 1.0,
        reflectivity: 1,
        roughness: 0.5,
        metalness: 0.5,
        ior: 1
    });

    public static UNMUTABLE_MESH_MATERIAL_OPACITY = 0.255555; // with this opacity part will not highlighted/unhighlited (material of the part will not be changed)

    public static GLOBAL_MATERIAL_HIGHLIGHTED = new MeshPhysicalMaterial({
        vertexColors: true,
        side: DoubleSide,
        emissive: 0x23A7DE
    })
    public static GLOBAL_MATERIAL_HIGHLIGHTED_GROUP = new MeshPhysicalMaterial({
        vertexColors: true,
        side: DoubleSide,
        emissive: 0xeb9834
    })
    public static GLOBAL_MATERIAL_HIGHLIGHTED_WARNING = new MeshPhysicalMaterial({
        vertexColors: true,
        side: DoubleSide,
        emissive: 0xFF0000
    })

    private static _POINTS_MATERIAL: PointsMaterial;

    public static GLOBAL_LINE_MATERIAL = new LineBasicMaterial({
        vertexColors: true,
    });


    /**
     * Global Coordinate System
     */
    public static GCS: Part;


    //__________________________________________________________________________________________
    public static get GLOBAL_POINTS_MATERIAL() {
        if (null == Op3dContext._POINTS_MATERIAL) {
            Op3dContext._POINTS_MATERIAL = new PointsMaterial({
                size: 10,
                sizeAttenuation: false,
                alphaTest: 0.5,
                // transparent: true,
                vertexColors: true,
            });

            new TextureLoader().load('images/disc.png', (data) => {
                (Op3dContext._POINTS_MATERIAL as any).map = data
            });
        }

        return Op3dContext._POINTS_MATERIAL;
    }
    //__________________________________________________________________________________________
    public static addTriangle(pVertices: Array<number>) {
        let aGeo = new BufferGeometry();
        aGeo.setAttribute("position", new Float32BufferAttribute(pVertices, 3));
        aGeo.setAttribute('size', new BufferAttribute([10, 10, 10, 10, 10, 10, 10, 10, 10, 10,], 1));

        let aMat = new MeshBasicMaterial({ color: 0xff0000, side: DoubleSide });
        let aMesh = new Mesh(aGeo, aMat);
        SceneContext.MAIN_SCENE.add(aMesh);
    }
    //__________________________________________________________________________________________
    private static getVersion(pVersion: string) {
        let aParts = pVersion.split('.');
        let aMul = 1;
        let aRes = 0;
        while (aParts.length > 0) {
            let aNum = parseInt(aParts.pop());
            aRes += (aNum * aMul);
            aMul *= 10;
        }

        return aRes;
    }
    //__________________________________________________________________________________________
    /**
     * @description -1 if ver1 is bigger , 0 if equal 1 if ver2 bigger
     * @param pVer1 version 1 to compare
     * @param pVer2 version 2 to compare
     */
    //__________________________________________________________________________________________
    public static compareVersions(pVer1: string, pVer2: string): number {
        let aVersion1 = this.getVersion(pVer1);
        let aVersion2 = this.getVersion(pVer2);

        if (aVersion1 > aVersion2) {
            return -1;
        } else if (aVersion1 < aVersion2) {
            return 1;
        }

        return 0;

    }
    //__________________________________________________________________________________________
    public static sleep(pMilliseconds: number) {
        return new Promise((resolve) => {
            setTimeout(() => { resolve(true) }, pMilliseconds, {
            })
        });
    }
    //__________________________________________________________________________________________
    public static async wait(pFunc: () => boolean) {
        while (false == pFunc()) {
            await Op3dContext.sleep(50);
        }
    }
    //__________________________________________________________________________________________
}