import { PartVO } from "../data/VO/PartVO";
import { Part } from "../parts/Part";
import { ePartType } from "../parts/PartInterfaces";
import { Op3dContext } from "./Op3dContext";

export interface iLastIndexes {
    LS_LAST_INDEX: number;
    D_LAST_INDEX: number;
    PL_LAST_INDEX: number;
}
export class LabelHandler {

    /**
     * Lasers index
     */
    private static LS_LAST_INDEX: number = 0;

    /**
    * Detector index
    */
    private static D_LAST_INDEX: number = 0;

    /**
    * Paraxial lens index
    */
    private static PL_LAST_INDEX: number = 0;


    //__________________________________________________________________________________________
    public static getCurrentIndex(pPart: Part, pPartVO: PartVO) {
        let aIsLaser = ((undefined !== pPartVO) && (undefined !== pPartVO.laserData));
        let aIsParaxialLens = (ePartType.PARAXIAL_LENS == pPart.partOptions.type);

        if (true == aIsLaser) {
            return LabelHandler.LS_LAST_INDEX;
        } else if (ePartType.DYNAMIC_PART === pPart.partOptions.type) {
            return LabelHandler.D_LAST_INDEX;
        } else if (true == aIsParaxialLens) {
            return LabelHandler.PL_LAST_INDEX;
        }

        return null;
    }
    //__________________________________________________________________________________________
    public static getPartIndex(pPart: Part, pPartVO: PartVO) {
        let aIsLaser = ((undefined !== pPartVO) && (undefined !== pPartVO.laserData));
        let aIsParaxialLens = (ePartType.PARAXIAL_LENS == pPart.partOptions.type);

        if (true == aIsLaser) {
            return ++LabelHandler.LS_LAST_INDEX;
        } else if (ePartType.DYNAMIC_PART === pPart.partOptions.type) {
            return ++LabelHandler.D_LAST_INDEX;
        } else if (true == aIsParaxialLens) {
            return ++LabelHandler.PL_LAST_INDEX;
        }

        return null;
    }
    //__________________________________________________________________________________________
    public static updateIndex(pPart: Part, pIndex: number) {
        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id);
        let aIsLaser = ((null != aPartVO) && (null != aPartVO.laserData));
        if (true == aIsLaser) {
            if (LabelHandler.LS_LAST_INDEX < pIndex) {
                LabelHandler.LS_LAST_INDEX = pIndex;
            }
        } else if (ePartType.DYNAMIC_PART === pPart.partOptions.type) {
            if (LabelHandler.D_LAST_INDEX < pIndex) {
                LabelHandler.D_LAST_INDEX = pIndex;
            }
        }
    }
    //__________________________________________________________________________________________
    public static resetIndexes() {
        this.LS_LAST_INDEX = 0;
        this.D_LAST_INDEX = 0;
        this.PL_LAST_INDEX = 0;
    }
    //__________________________________________________________________________________________
}
