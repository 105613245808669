import { Vector3, Object3D, BufferGeometry, Float32BufferAttribute, LineBasicMaterial, DoubleSide, LineSegments, Mesh, MeshPhongMaterial } from "three";
import { EventManager } from "../../oc/events/EventManager";
import { eIntensityColorCount } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { SceneContext } from "../scene/SceneContext";
import { ColorUtils } from "../ui/ColorUtils";
import { iRgba, iRGB } from "../ui/_globals/uiInterfaces";
import { LaserRay } from "./LaserRay";
import { eLaserColorType } from "./SimulationContext";
import { Part } from "../parts/Part";
import { Button3D, iOP3DButton } from "../parts/base_3d/Button3D";
import { iClientPoint } from "../_context/_interfaces/Interfaces";

export interface iRayDataFile {
    //id: string;
    exit: Vector3;
    direction: Vector3;
    sourceID: string;
    endPoint: Vector3;
    rgba: iRgba;
    color: number;
    /**
     * relative intensity according to the parent light source
     */
    relative_intensity: number;


    log_intensity: number;
    /**
     * intensity in general ratio between all light sources on scene
     */
    general_intensity: number;
    surfaceID: string;
    /**
     * @description the id of the previous ray of the current ray
     */
    //parentID: string;
    /**
     * @description the id of the original ray of the current ray
     * may occur in more then one ray
     */
    //familyID: string;

    /**
     * @TODO
     */
    isSpectrumRay?: boolean;
    wavelength?: number;
    //As?: number;
    Ap?: number;
    //phase?: number;
    finalPoint?: Vector3;

}

export interface iDisplayedRay {
    ray: iRayDataFile;
    show: boolean;
};

export class RaysDisplayer {

    public static POW: number = 0.3;

    private mMainLaserLinesContainer: Object3D = new Object3D();
    private mRays: Array<LaserRay> | null = null;

    private mLaserColorType: eLaserColorType = eLaserColorType.WAVELENGTH;


    constructor() {

        SceneContext.MAIN_SCENE.add(this.mMainLaserLinesContainer);

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this._onNew(), this);

        EventManager.addEventListener(EventsContext.ON_CLEAR_ALL_PARTS,
            () => this._onNew(), this);
    }
    //__________________________________________________________________________________________
    public get laserColorType(): eLaserColorType {
        return this.mLaserColorType;
    }
    //__________________________________________________________________________________________
    public set laserColorType(value: eLaserColorType) {
        this.mLaserColorType = value;
    }
    //__________________________________________________________________________________________
    public addGaussianMesh(pVertices: Array<number>, pColors: Array<number>) {
        let aGeo = new BufferGeometry();
        let aMat = new MeshPhongMaterial({
            vertexColors: true,
            side: DoubleSide
        });
        aGeo.setAttribute('position', new Float32BufferAttribute(pVertices, 3));
        aGeo.setAttribute('color', new Float32BufferAttribute(pColors, 3));
        aGeo.computeVertexNormals();
        let aRays = new Mesh(aGeo, aMat);
        aRays.name = "gauss_mesh";
        this.mMainLaserLinesContainer.add(aRays);
    }
    //__________________________________________________________________________________________
    public removeRaysOfLaser(pPart: Part) {
        if (this.mRays === null) {
            return;
        }

        const aPartId = pPart.internalID;
        for (let i = this.mRays.length - 1; i >= 0; i--) {
            if (this.mRays[i].rayData.sourceID == aPartId) {
                this.mRays.splice(i, 1);
            }
        }

        this.draw();
    }
    //__________________________________________________________________________________________
    public isRaysShown(pPart?: Part): boolean {
        if (null === this.mRays) {
            return false;
        }

        if (pPart) {
            let aCurrRay = this.mRays.find(el => el.rayData.sourceID == pPart.internalID);
            return ((null != aCurrRay) && (true == aCurrRay.show));
        }

        return this.mRays.some(el => el.show);
    }
    //__________________________________________________________________________________________
    public setRaysVisibility(pShow: boolean) {
        if (this.mRays && this.mRays.length > 0) {
            for (let i = this.mRays.length - 1; i >= 0; i--) {
                this.mRays[i].show = pShow;
            }
        }

        this.removeRays();
        this.draw();
    }
    //__________________________________________________________________________________________
    public toggleRaysOfLaser(pPart: Part) {
        if (this.mRays === null) {
            return;
        }

        const aPartId = pPart.internalID;
        let aShow = false;
        for (let i = this.mRays.length - 1; i >= 0; i--) {
            if (this.mRays[i].rayData.sourceID == aPartId) {
                aShow = this.mRays[i].toggleLight()
            }
        }

        this.removeRays();
        this.draw();
        return aShow;
    }
    //__________________________________________________________________________________________
    private _onNew() {
        this.clearScene();
    }
    //__________________________________________________________________________________________
    public removeRays() {
        this._removeListeners();
        while (this.mMainLaserLinesContainer.children.length > 0) {
            this.mMainLaserLinesContainer.children.pop();
        }
        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public clearScene() {
        this.removeRays();
        this.mRays = null
        // SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public draw() {
        if (null == this.mRays) {

            return;
        }
        let aGeometry = new BufferGeometry();

        let aRayDist = Op3dContext.SETUPS_MANAGER.settings.distanceOfLaserRay;

        let aVertices = new Array<number>();
        let aColors = new Array<number>();
        const aVisualizationThreshold = Op3dContext.SETUPS_MANAGER.settings.visualizationTreshold / 100;
        for (let i = 0; i < this.mRays.length; i++) {
            if (false == this.mRays[i].show) {
                continue;
            }

            let aRay = this.mRays[i].rayData;
            let aExitPoint = aRay.exit;
            let aEndPoint = (null != aRay.surfaceID) ? aRay.endPoint :
                aExitPoint.clone().add(aRay.direction.clone().multiplyScalar(aRayDist));

            let aAlpha: number;
            let aView: { color: iRGB, alpha: number };

            switch (this.mLaserColorType) {
                case eLaserColorType.WAVELENGTH:
                    aAlpha = aRay.relative_intensity;
                    aView = {
                        color: ColorUtils.hexToNormalizedRGB(aRay.color),
                        alpha: aRay.relative_intensity
                    }

                    break;

                case eLaserColorType.LOG:
                    aView = {
                        color: ColorUtils.hexToNormalizedRGB(aRay.color),
                        alpha: aRay.log_intensity
                    }

                    aAlpha = aRay.relative_intensity;
                    break;
                case eLaserColorType.INTENSITY:
                    const aIntensityCount = Op3dContext.SETUPS_MANAGER.settings.intensityColorCount.choice;
                    aAlpha = aRay.relative_intensity;
                    aView = {
                        color: this._getIntensityColor(aRay, aIntensityCount),
                        alpha: 1
                    }
                    break;
                case eLaserColorType.DIRECTION:
                    aView = {
                        color: ColorUtils.directionToRGB(aRay.direction, true),
                        alpha: 1
                    }
                    aAlpha = 1;
                    break;
                case eLaserColorType.USER_DEFINED:
                    let aNumColor = Op3dContext.PARTS_MANAGER.getPartByInternalId(this.mRays[i].rayData.sourceID).getBehavior('laserBehavior').laserData.lightSource.rays_color;
                    if (aNumColor == null) {
                        aNumColor = '#91ff00';
                    }
                    let aRColor = ColorUtils.stringColorHexToNumber(aNumColor);
                    aView = {
                        color: ColorUtils.hexToNormalizedRGB(aRColor),
                        alpha: 1
                    }
                    aAlpha = 1;
                    break;
            }

            if (aAlpha < aVisualizationThreshold) {
                continue;
            }
            aVertices.push(...aExitPoint.toArray(), ...aEndPoint.toArray());
            aColors.push(aView.color.r, aView.color.g, aView.color.b, aView.alpha);
            aColors.push(aView.color.r, aView.color.g, aView.color.b, aView.alpha);
        }

        let aPositionsBuffer = new Float32BufferAttribute(aVertices, 3);
        let aColorsBuffer = new Float32BufferAttribute(aColors, 4);
        aGeometry.setAttribute('position', aPositionsBuffer);
        aGeometry.setAttribute('color', aColorsBuffer);

        let aMaterial = new LineBasicMaterial({
            vertexColors: true,
            side: DoubleSide,
            transparent: true
        });

        let aLineSegmant = new LineSegments(aGeometry, aMaterial);
        this.mMainLaserLinesContainer.add(aLineSegmant);
        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    private _getIntensityColor(pRay: iRayDataFile, pCount: eIntensityColorCount) {
        let aIntensity: number;

        switch (pCount) {
            case eIntensityColorCount.NUMBER_3:
                if (pRay.general_intensity <= (1 / 3)) {
                    aIntensity = 0;
                } else if (pRay.general_intensity <= (2 / 3)) {
                    aIntensity = ((645 - 440) / 510);
                } else {
                    aIntensity = 1;
                }
                break;
            case eIntensityColorCount.NUMBER_10:
                aIntensity = this._getNormalizedIntensity(pRay.general_intensity, 10);
                break;
            case eIntensityColorCount.USER_DEFINED:
                let aCount = Op3dContext.SETUPS_MANAGER.settings.intensityColorCount.count;
                aIntensity = this._getNormalizedIntensity(pRay.general_intensity, aCount);
                break;
            case eIntensityColorCount.CONTINUOUS:
                aIntensity = pRay.general_intensity;
                break;
        }

        let aColor = ColorUtils.intensityToRGB(aIntensity, true);
        return aColor;
    }
    //__________________________________________________________________________________________
    private _getNormalizedIntensity(pIntensity: number, pCount: number) {
        for (let i = 1; i < pCount; i++) {
            let aCurr = ((i - 1) / pCount);
            let aNext = (i / pCount);

            if ((pIntensity >= aCurr) && (pIntensity < aNext)) {
                return ((i - 1) / (pCount - 1));
            }
        }

        return 1;
    }
    //__________________________________________________________________________________________
    public show(pData: Array<iRayDataFile>) {
        if (pData) {
            // this.clearScene();
            this.mRays = new Array<LaserRay>();
            for (let i = 0; i < pData.length; i++) {
                this.mRays.push(new LaserRay(pData[i]));
            }

            this.draw();
        }
    }
    //__________________________________________________________________________________________
    public get raysOnScene() {
        return this.mRays;
    }
    //__________________________________________________________________________________________
    public get mainLaserLinesContainer() {
        return this.mMainLaserLinesContainer;
    }
    //__________________________________________________________________________________________
    public removeListeners() {
        Button3D.removeRaysButton(this.mainLaserLinesContainer);
    }
    //__________________________________________________________________________________________
    public addListeners() {
        let aListenersArray = new Array<iOP3DButton>();

        aListenersArray.push({
            type: 'mouseup',
            func: (pEventData: any,
                pEvent: iClientPoint) => Op3dContext.PARTS_EVENTS_HANDLER.onMouseUp(this,
                    pEventData, pEvent as any)
        });

        Button3D.removeRaysButton(this.mainLaserLinesContainer)
        Button3D.addRaysButton(this.mainLaserLinesContainer, aListenersArray);
    }
    //__________________________________________________________________________________________
    private _removeListeners() {
        Button3D.removeRaysButton(this.mainLaserLinesContainer)
    }
    //__________________________________________________________________________________________
    private isPointOnLine(pStartPoint: Vector3, pEndPoint: Vector3, pPointToCheck: Vector3) {
        let aCrossVector = new Vector3();
        let aResultCV = aCrossVector.crossVectors(pStartPoint.clone().sub(pPointToCheck), pEndPoint.clone().sub(pPointToCheck));
        let aResultCVLength = aResultCV.length()
        return aResultCVLength;
    }
    //__________________________________________________________________________________________
    public getRayOfPoint(pPoint: Vector3): any {
        let aRaysIndexes = []
        for (let i = 0; i < this.mRays.length; i++) {

            let aRequestedPoint = structuredClone(pPoint)

            let aStartPoint = new Vector3(this.mRays[i].rayData.exit.x, this.mRays[i].rayData.exit.y, this.mRays[i].rayData.exit.z)
            let aFinalPoint = new Vector3(this.mRays[i].rayData.finalPoint.x, this.mRays[i].rayData.finalPoint.y, this.mRays[i].rayData.finalPoint.z)



            let aRayLocationIndex = this.isPointOnLine(aStartPoint, aFinalPoint, aRequestedPoint);
            if (aRayLocationIndex < 1) {
                aRaysIndexes.push({ ray: this.mRays[i], index: aRayLocationIndex })
            }

        }

        let closestLine = null;
        let closestDistance = Infinity;

        for (let i = 0; i < aRaysIndexes.length; i++) {
            const lineDirection = new Vector3().subVectors(aRaysIndexes[i].ray.rayData.finalPoint, aRaysIndexes[i].ray.rayData.exit);

            // Calculate the vector from the start of the line to the mouse point
            const mouseToStart = new Vector3().subVectors(pPoint, aRaysIndexes[i].ray.rayData.exit);

            // Calculate the t parameter for the point on the line closest to the mouse point
            const t = mouseToStart.dot(lineDirection) / lineDirection.lengthSq();

            // Clamp the t parameter to ensure the closest point is within the line segment
            const clampedT = Math.max(0, Math.min(1, t));

            // Calculate the closest point on the line to the mouse point
            const closestPoint = new Vector3().addVectors(aRaysIndexes[i].ray.rayData.exit, lineDirection.multiplyScalar(clampedT));

            // Calculate the distance between the closest point and the mouse point
            const distance = pPoint.distanceTo(closestPoint);
            if (distance < closestDistance) {
                closestLine = aRaysIndexes[i];
                closestDistance = distance;
            }
        }

        let aDistance = this.calculateDistance(closestLine.ray.rayData.exit, pPoint)

        return { ray: closestLine.ray, distance: aDistance };

    }
    //__________________________________________________________________________________________
    private numberOfTransitions(pRay: LaserRay, pExitPoint: Vector3, pInitialPosition = 0) {
        if (pExitPoint.equals(pRay.rayData.exit)) {
            return pInitialPosition;
        }
        pRay = this.mRays.find(item => {
            return item.rayData.endPoint.equals(pRay.rayData.exit)
        })
        if (pRay === undefined) {
            return pInitialPosition;
        }
        pInitialPosition++;
        return this.numberOfTransitions(pRay, pExitPoint, pInitialPosition)
    }
    //__________________________________________________________________________________________
    public getRaysOfPoint(pPoint: Vector3) {
        let aRayData = this.getRayOfPoint(pPoint);
        let aDistance = aRayData.distance;




        let aRays = [];
        let aLaserExitPoint: Vector3;
        for (let i = 0; i < this.mRays.length; i++) {
            if (aRayData.ray.rayData.sourceID === this.mRays[i].rayData.sourceID) {
                aLaserExitPoint = this.mRays[i].rayData.exit;
                break;
            }
        }

        let aNumOfMainRayTransitions = this.numberOfTransitions(aRayData.ray, aLaserExitPoint);
        for (let i = 0; i < this.mRays.length; i++) {
            if (aRayData.ray.rayData.sourceID === this.mRays[i].rayData.sourceID && aRayData.ray.rayData.surfaceID === this.mRays[i].rayData.surfaceID) {
                const aNumOfTransitionsI = this.numberOfTransitions(this.mRays[i], aLaserExitPoint)
                if (aNumOfTransitionsI === aNumOfMainRayTransitions) {
                    aRays.push(this.mRays[i]);
                }
            }
        }
        let aResultFilteredRays = []
        for (let i = 0; i < aRays.length; i++) {

            let aMarginOfError = this.calculateDistance(aRayData.ray.rayData.exit, aRays[i].rayData.exit);
            if (aMarginOfError < 50) {
                aResultFilteredRays.push(aRays[i])
            }

        }

        return { rays: aResultFilteredRays, distance: aDistance };
    }
    //__________________________________________________________________________________________
    private calculateDistance(pPoint1: Vector3, pPoint2: Vector3): number {
        const aVectDistance = new Vector3().subVectors(pPoint2, pPoint1)
        return Math.sqrt(aVectDistance.x ** 2 + aVectDistance.y ** 2 + aVectDistance.z ** 2);
    }
    //__________________________________________________________________________________________
    private calculateAverageDirection(pVectors: Array<Vector3>) {
        let aAvg = new Vector3();
        for (const vector of pVectors) {
            aAvg.add(vector)
        }

        const aTotalVectors = pVectors.length;
        aAvg.divideScalar(aTotalVectors);

        // Calculate the magnitude of the average vector
        const aMagnitude = Math.sqrt(aAvg.x ** 2 + aAvg.y ** 2 + aAvg.z ** 2);

        // Normalize the average vector to get the direction
        const aDirection = aAvg.divideScalar(aMagnitude);

        return aDirection;
    }
    //__________________________________________________________________________________________
    private calculatePointInDirection(pStartPoint: Vector3, pDirection: Vector3, pDistance: number): Vector3 {
        const aMagnitude = Math.sqrt(pDirection.x ** 2 + pDirection.y ** 2 + pDirection.z ** 2);

        const aNormalizedDirection = pDirection.divideScalar(aMagnitude);

        const aNewX = pStartPoint.x + aNormalizedDirection.x * pDistance;
        const aNewY = pStartPoint.y + aNormalizedDirection.y * pDistance;
        const aNewZ = pStartPoint.z + aNormalizedDirection.z * pDistance;

        return new Vector3(aNewX, aNewY, aNewZ);
    }
    //__________________________________________________________________________________________
    public getCoordinatesOfRayCenter(pPoint: Vector3): any {
        let aRaysData = this.getRaysOfPoint(pPoint);




        let aRays = aRaysData.rays;
        let aCoordinates = [];
        let aFindDirections = [];
        aRays.map(item => {
            aCoordinates.push([item.rayData.exit.x, item.rayData.exit.y, item.rayData.exit.z]);
            aFindDirections.push(item.rayData.direction);
        });

        let aDistance = aRaysData.distance;
        const aAvgDirectionNormalized = this.calculateAverageDirection(aFindDirections);
        const aNumVectors = aCoordinates.length;
        const aNumDimensions = aCoordinates[0].length;
        const aSumComponents = new Array(aNumDimensions).fill(0);

        for (const vector of aCoordinates) {
            for (let i = 0; i < aNumDimensions; i++) {
                aSumComponents[i] += vector[i];
            }
        }
        const aCenter = aSumComponents.map(sum => sum / aNumVectors);
        let aRes = this.calculatePointInDirection(new Vector3(aCenter[0], aCenter[1], aCenter[2]), new Vector3(aAvgDirectionNormalized.x, aAvgDirectionNormalized.y, aAvgDirectionNormalized.z), aDistance);
        return { position: aRes, direction: aAvgDirectionNormalized }
    }
    //__________________________________________________________________________________________
}