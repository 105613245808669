import { iPoint2D, iMinMax, iRGBA, iSize } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { UnitHandler } from "../../units/UnitsHandler";
import { ColorUtils } from "../ColorUtils";
import { iRgba } from "../_globals/uiInterfaces";
import { iAnalysisCacheData } from "./AnalysisCache";
import { AnalysisConstants } from "./AnalysisConstants";
import { eAnalysisDisplay, AnalysisContext, iGraphUIParams, iAnalysisItem, tAnalysisType } from "./AnalysisContext";
import { iCreateThumbnailAnalysisOptions } from "./ResultFactory/anSmallResultFactory";

export class AnalysisCanvasUtils {

    //__________________________________________________________________________________________
    /**
     * @descriptionv fits the canvas container accodting to the div container
     * @param pCanvas the given canvas
     * @param pSize the size of the parent
     */
    //__________________________________________________________________________________________
    private static _fitToContainer(pCanvas: HTMLCanvasElement, pSize: iSize) {
        // Make it visually fill the positioned parent
        pCanvas.style.width = '100%';
        pCanvas.style.height = '100%';
        // ...then set the internal size to match
        // pCanvas.width = pCanvas.offsetWidth;
        // pCanvas.height = pCanvas.offsetHeight;
        pCanvas.width = pSize.width;
        pCanvas.height = pSize.height;
    }
    //__________________________________________________________________________________________
    /**
     * @param pOptions container name will be sent only in cases of canvas
     * @returns 
     */
    //__________________________________________________________________________________________
    public static initCanvas(pOptions: {
        container: HTMLElement,
        containerID?: string,
        isTextual: boolean,
        isAdditionalCanvas?: boolean,
        fitToContainer: boolean
    }) {
        try {
            let aIsAdditionalCanvas = pOptions.isAdditionalCanvas != null ? pOptions.isAdditionalCanvas : false;
            let aRect = pOptions.container.getBoundingClientRect();

            let aCanvas: HTMLCanvasElement;
            if (aIsAdditionalCanvas) {
                aCanvas = document.createElement("canvas");
                pOptions.container.appendChild(aCanvas);
                aCanvas.id = pOptions.containerID + '';
            }
            else {
                aCanvas = Op3dUtils.getElementIn(pOptions.container,
                    pOptions.containerID) as HTMLCanvasElement;
                if (aCanvas == null) {
                    aCanvas = document.createElement("canvas");
                    aCanvas.id = pOptions.containerID;
                    pOptions.container.appendChild(aCanvas);
                }
            }

            let aSize: iSize = { width: aRect.width, height: aRect.height };
            if (pOptions.fitToContainer) {
                this._fitToContainer(aCanvas, aSize);
            }

            let aCtx = aCanvas.getContext("2d");
            this._setProps(aCtx, aSize.width, aSize.height, pOptions.isTextual);
            pOptions.container.style.width = aSize.width + 'px';
            pOptions.container.style.height = aSize.height + 'px';
            // if (!pOptions.isTranspaernt) {
            //     aCtx.fillStyle = "#FFFFFF";
            //     aCtx.fillRect(0, 0, aRect.width, aRect.height);
            // }
            return { ctx: aCtx, size: aSize };

        } catch (e) {
            return null
        }
    }
    //__________________________________________________________________________________________
    private static _setProps(pCtx: CanvasRenderingContext2D,
        pWidth: number, pHeight: number, pIsTextual: boolean) {
        pCtx.canvas.style.imageRendering = "pixelated";
        pCtx.imageSmoothingQuality = "high";
        window.devicePixelRatio = 1;

        if (pIsTextual) {
            window.devicePixelRatio = 2;
        }
        let aScale = window.devicePixelRatio;
        pCtx.canvas.width = Math.floor(pWidth * aScale);
        pCtx.canvas.height = Math.floor(pHeight * aScale);
        pCtx.scale(aScale, aScale);
    }
    //__________________________________________________________________________________________
    private static _getCanvas(pResolution: iPoint2D, pIsTextual: boolean, pCanvasId: string) {
        let aCanvas = document.createElement("canvas");
        aCanvas.width = pResolution.x;
        aCanvas.height = pResolution.y;
        if (pCanvasId != null) {
            aCanvas.id = pCanvasId;
        }

        let aCtx = aCanvas.getContext("2d", { willReadFrequently: true });
        this._setProps(aCtx, pResolution.x, pResolution.y, pIsTextual);
        return aCtx;
    }
    //__________________________________________________________________________________________
    public static drawImage(pMatrix: Array<Array<number>>,
        pResolution: iPoint2D,
        pDisplayType: eAnalysisDisplay,
        pRange: iMinMax,
        pSpectralColorsFunc?: () => Array<Array<iRGBA>>,
        pCanvasId?: string): HTMLCanvasElement {

        let aSpectralColors: Array<Array<iRGBA>>;
        if (eAnalysisDisplay.SPECTRAL_VIEW == pDisplayType) {
            aSpectralColors = pSpectralColorsFunc();
        }

        let aCtx = this._getCanvas(pResolution, false, pCanvasId);
        let aRange = DataUtils.getSize(pRange);
        let id = aCtx.createImageData(pResolution.x, pResolution.y); // only do this once per page
        for (let x = 0; x < pResolution.y; x++) {
            for (let y = 0; y < pResolution.x; y++) {
                let aMatVal = isNaN(pMatrix[x][y]) == false ? pMatrix[x][y] - pRange.min : null;
                const aItem = (null != aMatVal && aRange != 0) ? ((aMatVal / aRange) * 255) : 0;

                let aColor: iRgba;
                switch (pDisplayType) {
                    case eAnalysisDisplay.FALSE_COLOR:
                        let aCurrColor = ColorUtils.intensityToRGB(aItem / 255);
                        aColor = {
                            r: aCurrColor.r,
                            g: aCurrColor.g,
                            b: aCurrColor.b,
                            a: 255
                        }
                        break;
                    case eAnalysisDisplay.SPECTRAL_VIEW:
                        aColor = aSpectralColors[x][y];
                        break;
                    default:
                        aColor = { r: aItem, g: aItem, b: aItem, a: 255 };
                }

                let aIndex = (y + x * id.width) * 4;
                id.data[aIndex] = aColor.r;
                id.data[aIndex + 1] = aColor.g;
                id.data[aIndex + 2] = aColor.b;
                id.data[aIndex + 3] = 255;
            }
        }

        aCtx.putImageData(id, 0, 0);
        //this.downloadImageFromCanvas({ canvas: aCtx.canvas, mimeType: "image/png", name: "test_first" });
        return aCtx.canvas;
    }
    //__________________________________________________________________________________________
    public static drawRectangleOnContext(e: MouseEvent, pMouseDown: iPoint2D,
        pCtx: CanvasRenderingContext2D): { startPoint: iPoint2D, width: number, height: number } {

        if (pMouseDown == null) {
            return;
        }

        let aOffset = $(e.target).offset();
        let aX = e.clientX - aOffset.left;
        let aY = e.clientY - aOffset.top;
        let aP1: iPoint2D = { x: pMouseDown.x, y: pMouseDown.y };
        let aP2: iPoint2D = { x: aX, y: aY };

        let aWidth = Math.abs(aP1.x - aP2.x);
        let aHeight = Math.abs(aP1.y - aP2.y);

        let aStratPoint: iPoint2D;
        if (aP1.x < aP2.x && aP1.y < aP2.y) {
            // top left ->  bottom right
            aStratPoint = { x: aP1.x, y: aP1.y };

        } else if (aP1.x > aP2.x && aP2.y < aP1.y) {
            // bottom right -> top left
            aStratPoint = { x: aP2.x, y: aP2.y };

        } else if (aP2.x > aP1.x && aP1.y > aP2.y) {
            // bottom left -> top right
            aStratPoint = { x: aP1.x, y: aP2.y };

        } else {
            // top right -> bottom left
            aStratPoint = { x: aP2.x, y: aP1.y };
        }

        pCtx.clearRect(0, 0, pCtx.canvas.width,
            pCtx.canvas.height);

        // this._addPoint(aSecondPoint.x, aSecondPoint.y);

        pCtx.fillStyle = "#6868ed55";
        pCtx.fillRect(aStratPoint.x, aStratPoint.y, aWidth, aHeight);

        pCtx.strokeStyle = "#6868ed";
        pCtx.lineWidth = 1;
        pCtx.strokeRect(aStratPoint.x, aStratPoint.y, aWidth, aHeight);
        return { startPoint: aStratPoint, width: aWidth, height: aHeight };
    }
    //__________________________________________________________________________________________
    public static addAxisLabels(pOptions: iCreateThumbnailAnalysisOptions) {

        // x axis label ----------------------------------------------------------
        const aXLabelContainer = Op3dUtils.getElementIn(pOptions.container,
            AnalysisContext.X_LABEL_CONTAINER);
        this.addXLabelContainer(aXLabelContainer, pOptions.graphData.labels.x, pOptions.uiParams);
        //------------------------------------------------------------------------

        // y axis label ----------------------------------------------------------
        const aYLabelContainer = Op3dUtils.getElementIn(pOptions.container,
            AnalysisContext.Y_LABEL_CONTAINER);
        this.addYLabelContainer(aYLabelContainer, pOptions.graphData.labels.y, pOptions.uiParams);
        //------------------------------------------------------------------------
    }
    //__________________________________________________________________________________________
    public static addXLabelContainer(pContainer: HTMLElement, pText: string, pUIParams: iGraphUIParams) {
        let aRes = this.initCanvas({
            container: pContainer,
            isAdditionalCanvas: false,
            isTextual: true,
            fitToContainer: true
        });
        aRes.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.labels;
        aRes.ctx.font = pUIParams.label_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;
        let aTextWidth = aRes.ctx.measureText(pText).width;

        aRes.ctx.fillText(pText,
            aRes.size.width / 2 - (aTextWidth / 2),
            pUIParams.label_font_size);
    }
    //__________________________________________________________________________________________
    public static getSpectrumLabel(pAnalysisData: iAnalysisItem<tAnalysisType>) {
        switch (pAnalysisData.display) {
            case eAnalysisDisplay.GRAY_SCALE:
            case eAnalysisDisplay.FALSE_COLOR:
            case eAnalysisDisplay.SPECTRAL_VIEW:
                const aItem = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
                    item.type === pAnalysisData.type && item.name === pAnalysisData.name);
                if (aItem === undefined) {
                    return "";
                }

                return `${aItem.label2} ${aItem.unitLabel}`;

        }
    }
    //__________________________________________________________________________________________
    public static getRange(pCacheData: iAnalysisCacheData, pDisplay: eAnalysisDisplay): iPoint2D<iMinMax> {
        let aRange: iPoint2D<iMinMax>;

        switch (pDisplay) {
            case eAnalysisDisplay.GRAY_SCALE:
            case eAnalysisDisplay.FALSE_COLOR:
            case eAnalysisDisplay.SPECTRAL_VIEW:
                aRange = {
                    x: pCacheData.common_data.x_range,
                    y: pCacheData.common_data.y_range
                }
                break;
            case eAnalysisDisplay.X_CROSS_SECTION:
                aRange = {
                    x: pCacheData.common_data.x_range,
                    y: {
                        min: pCacheData.matrix.working.values_range.min,
                        max: pCacheData.matrix.working.values_range.max
                    }
                }
                break;

            case eAnalysisDisplay.Y_CROSS_SECTION:
                aRange = {
                    x: pCacheData.common_data.y_range,
                    y: {
                        min: pCacheData.matrix.working.values_range.min,
                        max: pCacheData.matrix.working.values_range.max
                    }
                }
                break;

            case eAnalysisDisplay.XY_CROSS_SECTION:
                const aScale = UnitHandler.presentedScale;
                let aRangeX = DataUtils.getSize(pCacheData.common_data.x_range);
                let aRangeY = DataUtils.getSize(pCacheData.common_data.y_range);

                let aMaxX = aRangeX > aRangeY ? pCacheData.common_data.x_range : pCacheData.common_data.y_range;

                aRange = {
                    // x: aMaxX,
                    x: {
                        min: aMaxX.min * aScale,
                        max: aMaxX.max * aScale,
                    },
                    y: {
                        min: pCacheData.matrix.working.values_range.min,
                        max: pCacheData.matrix.working.values_range.max
                    }
                }
                break;

        }

        return aRange;
    }
    //__________________________________________________________________________________________
    public static getLabels(pAnalysisData: iAnalysisItem<tAnalysisType>) {
        switch (pAnalysisData.display) {
            case eAnalysisDisplay.GRAY_SCALE:
            case eAnalysisDisplay.SPECTRAL_VIEW:
            case eAnalysisDisplay.FALSE_COLOR:
                let aRes: iPoint2D<string> = {
                    x: `${AnalysisContext.GRAY_SCALE_LABELS.x} [${UnitHandler.shortSign}]`,
                    y: `${AnalysisContext.GRAY_SCALE_LABELS.y} [${UnitHandler.shortSign}]`,
                }

                return aRes;
            case eAnalysisDisplay.X_CROSS_SECTION: {
                const aDataItem = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
                    item.type === pAnalysisData.type && item.name === pAnalysisData.name);
                if (aDataItem === undefined) {
                    throw new Error("missing analysis data");
                }
                let aRes: iPoint2D<string> = {
                    x: `${AnalysisContext.X_CROSS_SECTION_LABELS.x} [${UnitHandler.shortSign}]`,
                    y: `${aDataItem.label2} ${aDataItem.unitLabel}`
                }
                return aRes;
            }
            case eAnalysisDisplay.Y_CROSS_SECTION: {
                const aDataItem = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
                    item.type === pAnalysisData.type && item.name === pAnalysisData.name);
                if (aDataItem === undefined) {
                    throw new Error("missing analysis data");
                }
                let aRes: iPoint2D<string> = {
                    x: `${AnalysisContext.Y_CROSS_SECTION_LABELS.x} [${UnitHandler.shortSign}]`,
                    y: `${aDataItem.label2} ${aDataItem.unitLabel}`
                }
                return aRes;
            }
            case eAnalysisDisplay.XY_CROSS_SECTION: {
                const aDataItem = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
                    item.type === pAnalysisData.type && item.name === pAnalysisData.name);
                if (aDataItem === undefined) {
                    throw new Error("missing analysis data");
                }
                let aRes: iPoint2D<string> = {
                    x: `${AnalysisContext.XY_CROSS_SECTION_LABELS.x} [${UnitHandler.shortSign}]`,
                    y: `${aDataItem.label2} ${aDataItem.unitLabel}`
                }
                return aRes;
            }

        }
    }
    //__________________________________________________________________________________________
    public static addYLabelContainer(pContainer: HTMLElement,
        pText: string,
        pUIParams: iGraphUIParams) {

        let aRes = this.initCanvas({
            container: pContainer, fitToContainer: true,
            isAdditionalCanvas: false, isTextual: true
        });

        let aTextWidth = (aRes.ctx.measureText(pText).width / 2);
        aRes.ctx.font = pUIParams.label_font_size + "px " + AnalysisContext.GENERAL_OPTIONS.font_family;
        aRes.ctx.fillStyle = AnalysisContext.GENERAL_OPTIONS.labels;
        aRes.ctx.translate(pUIParams.label_font_size, aRes.size.height / 2 + (aTextWidth));
        aRes.ctx.rotate(-Math.PI / 2);
        aRes.ctx.fillText(pText, pUIParams.label_font_size / 2, pUIParams.label_font_size / 2);
    }
    //__________________________________________________________________________________________
}
