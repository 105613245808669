import { useRef, useState } from "react";
import { NotificationCenter } from "../../../ui/home/_notifications/NotificationCenter";
import { eNotificationToastDuration, eNotificationType } from "../../../ui/home/_notifications/NotificationsContext";
import { BlackBoxNotification } from './BlackBoxNotification';
import ReactDomServer from 'react-dom/server';
import { NotificationNote } from "../../../ui/home/_notifications/NotificationNote";

export function BlackBoxHeader({ onClose }) {
    const [isNotificationShown, setNotification] = useState(false);
    const notification = useRef<NotificationNote>()

    const showInfo = async () => {
        if (isNotificationShown) {
            NotificationCenter.instance.removeNotification(notification.current);
            setNotification(false)
        } else {
            notification.current = await NotificationCenter.instance.pushNotification({
                message: ReactDomServer.renderToString(<BlackBoxNotification />),
                toastDuration: eNotificationToastDuration.INFINITE,
                params: {
                    type: eNotificationType.CONSTRUCTION_COMMENT,
                    title: 'BLACK BOX TOOL - INFO',
                    color: '#23A7DE',
                    isColorBoxVisible: false
                }
            });
            setNotification(true)
        }
    }

    return (
        <div className="modal-header d-block">
            <div className="d-flex justify-content-between">
                <div className="modal-title">BLACK BOX TOOL</div>
                <img className={ isNotificationShown ? 'active' : '' } onClick={ showInfo } src='./images/icons_new/assembly/info.svg' />
                <button onClick={ onClose } type="button" className="close" >
                    <i className="icon-close"></i>
                </button>
            </div>
        </div>
    );
}
