import { LineSegments, Object3D, Mesh, Vector3, Color, BufferGeometry, Float32BufferAttribute, LineBasicMaterial, Box3, MeshBasicMaterial, Vector2, PlaneGeometry, DoubleSide, MeshPhysicalMaterial, TextureLoader, BoxGeometry, RepeatWrapping } from "three";
import { EventManager } from "../../oc/events/EventManager";
import { Colors } from "../_context/Colors";
import { eUnitType } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { Op3dContext } from "../_context/Op3dContext";
import { iPoint2D } from "../_context/_interfaces/Interfaces";
import { OptixPartUtils } from "../_utils/OptixPartUtils";
import { UnitHandler } from "../units/UnitsHandler";
import { SceneContext } from "./SceneContext";
import { Part } from "../parts/Part";
import { OP3DMathUtils } from "../_utils/OP3DMathUtils";

export interface iSceneOptions {
    isGridVisible: boolean;
    isBreadboardVisible: boolean;
    divisions: iPoint2D;
};

export class GridManager {

    private static DEFAULT_SCENE_OPTIONS: iSceneOptions = {
        divisions: { x: 100, y: 100 },
        isBreadboardVisible: false,
        isGridVisible: true
    };

    public static CELL_SIZE: number = 25; //1 in inch.

    private mGrid: LineSegments;
    private mOP3DBreadboard: Object3D;
    private mDivisions: iPoint2D;

    private mPlane: Mesh;

    private mIsReady: boolean = false;

    //__________________________________________________________________________________________
    constructor(pSceneOptions: iSceneOptions = GridManager.DEFAULT_SCENE_OPTIONS) {
        this._create(pSceneOptions);
    }
    //__________________________________________________________________________________________
    public getSize() {
        let aScale = UnitHandler.presentedScale;

        let aX = (this.mDivisions.x * aScale);
        let aY = (0.5 * aScale);
        let aZ = (this.mDivisions.y * aScale);

        let aSize = new Vector3(aX, aY, aZ);

        return aSize;
    }
    //__________________________________________________________________________________________
    public async getSceneOptions() {
        await Op3dContext.wait(() => true == this.mIsReady);

        let aSceneOptions: iSceneOptions = {
            divisions: this.mDivisions,
            isBreadboardVisible: this.mOP3DBreadboard.visible,
            isGridVisible: (this.mGrid.material as any).visible
        };

        return aSceneOptions;
    }
    //__________________________________________________________________________________________
    public async setSceneOptions(pSceneOptions: iSceneOptions, pUpdate: boolean = false) {
        await Op3dContext.wait(() => true == this.mIsReady);

        if (true == pUpdate) {
            Op3dContext.SCENE_HISTORY.addToHistory();
        }

        if ((this.mDivisions.x != pSceneOptions.divisions.x) ||
            (this.mDivisions.y != pSceneOptions.divisions.y)) {
            this._remove();
            await this._create(pSceneOptions);
        } else {
            (this.mGrid.material as any).visible = pSceneOptions.isGridVisible;
            this.mOP3DBreadboard.visible = pSceneOptions.isBreadboardVisible;
        }

        Op3dContext.SETUPS_MANAGER.onUpdateSceneOptions(pSceneOptions);
        if (true == pUpdate) {
            Op3dContext.SCENE_HISTORY.saveScene(true);
        }

        SceneContext.OP3D_SCENE.activateRenderer();
        EventManager.dispatchEvent(EventsContext.UPDATE_SCENE_SETTINGS, this,
            pSceneOptions);
    }
    //__________________________________________________________________________________________
    private _createGrid(pIsVisible: boolean) {
        this.mGrid = this.getGridMesh();
        this.mGrid.name = "grid";
        SceneContext.MAIN_SCENE.add(this.mGrid);
        (this.mGrid.material as any).visible = pIsVisible;
    }
    //__________________________________________________________________________________________
    public getGridMesh() {
        let aColor1 = new Color(0x444444);
        let aColor2 = new Color(0x888888);
        const aVertices = [];
        const colors = [];

        let aStep = (eUnitType.INCHES == UnitHandler.PRESENTED_UNIT) ? 25.4 : 25;

        let aX = this.mDivisions.x * aStep;
        let aZ = this.mDivisions.y * aStep;

        let aDivisionsX = this.mDivisions.x;
        let aDivisionsZ = this.mDivisions.y;

        let aCenterX = (aDivisionsX / 2);
        let aCenterZ = (aDivisionsZ / 2);

        let aColorIndex = 0;
        for (let i = 0; i <= aDivisionsX; i++) {
            let x = -(aX / 2) + i * aStep;
            aVertices.push(x, 0, -(aZ / 2), x, 0, (aZ / 2));

            let aColor = (i == aCenterX) ? aColor1 : aColor2;
            aColor.toArray(colors, aColorIndex);
            aColorIndex += 3;
            aColor.toArray(colors, aColorIndex);
            aColorIndex += 3;
        }

        for (let j = 0; j <= aDivisionsZ; j++) {
            let z = -(aZ / 2) + j * aStep;
            aVertices.push(-(aX / 2), 0, z, (aX / 2), 0, z);

            let aColor = (j == aCenterZ) ? aColor1 : aColor2;
            aColor.toArray(colors, aColorIndex);
            aColorIndex += 3;
            aColor.toArray(colors, aColorIndex);
            aColorIndex += 3;
        }

        let geometry = new BufferGeometry();
        geometry.setAttribute('position', new Float32BufferAttribute(aVertices, 3));
        geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));

        let material = new LineBasicMaterial({
            vertexColors: true,
            toneMapped: false
        } as any);

        let aGrid = new LineSegments(geometry, material);

        return aGrid;
    }
    //__________________________________________________________________________________________
    private async _getBreadbox() {
        const geometry = new BoxGeometry(1, 1, 1);
        const loader = new TextureLoader();

        let aTexture = loader.load('./images/breadboard.jpg');
        aTexture.wrapS = RepeatWrapping;
        aTexture.wrapT = RepeatWrapping;
        aTexture.repeat.set(100, 100);

        let aBumpmap = loader.load('./images/breadboard_bump.jpg');
        aTexture.wrapS = RepeatWrapping;
        aTexture.wrapT = RepeatWrapping;
        aTexture.repeat.set(100, 100);

        let aMatTopBottoms = new MeshPhysicalMaterial({
            map: aTexture,
            transparent: false,
            side: DoubleSide,
            bumpMap: aBumpmap,
            bumpScale: 1,
            roughness: 0.95
        });

        let aSidesMaterial = new MeshPhysicalMaterial({
            transparent: false,
            side: DoubleSide,
            color: new Color().setHex(0x000000)
        });

        const cubeMaterials = [
            aSidesMaterial, //right side
            aSidesMaterial, //left side
            aMatTopBottoms, //top side
            aMatTopBottoms, //bottom side
            aSidesMaterial, //front side
            aSidesMaterial, //back side
        ];
        let cube = new Mesh(geometry, cubeMaterials);
        let aBreadboard = new Object3D();
        aBreadboard.add(cube);

        return aBreadboard;
    }
    //__________________________________________________________________________________________
    private async _createBreadboard(pToShow: boolean) {
        this.mOP3DBreadboard = await this._getBreadbox();
        let aScale = GridManager.CELL_SIZE;
        let aSizeX = (this.mDivisions.x * aScale);
        let aSizeZ = (this.mDivisions.y * aScale);

        let aBoxSizeVec = new Vector3();
        new Box3().setFromObject(this.mOP3DBreadboard).getSize(aBoxSizeVec);
        let aScaleX = (aSizeX / aBoxSizeVec.x);
        let aScaleY = ((0.5 * aScale) / aBoxSizeVec.y);
        let aScaleZ = (aSizeZ / aBoxSizeVec.z);

        this.mOP3DBreadboard.scale.set(aScaleX, aScaleY, aScaleZ);
        let aMesh = this.mOP3DBreadboard.children[0] as Mesh;
        let aMaterial = aMesh.material[2] as MeshPhysicalMaterial;
        aMaterial.map.repeat = new Vector2(this.mDivisions.x, this.mDivisions.y);

        this.mOP3DBreadboard.position.y = - (0.5 * aScaleY);
        this.mOP3DBreadboard.visible = false;
        SceneContext.MAIN_SCENE.add(this.mOP3DBreadboard);

        this.mOP3DBreadboard.visible = pToShow;

        SceneContext.OP3D_SCENE.activateRenderer();
    }
    //__________________________________________________________________________________________
    public async toggleBreadboardVisibility() {
        return await this.setBreadboardVisiblity(!this.mOP3DBreadboard.visible);
    }
    //__________________________________________________________________________________________
    public async setBreadboardVisiblity(pToShow: boolean) {
        let aSceneOptions = await this.getSceneOptions();
        aSceneOptions.isBreadboardVisible = pToShow;

        this.setSceneOptions(aSceneOptions, true);

        return aSceneOptions.isBreadboardVisible;
    }
    //__________________________________________________________________________________________
    public async toggleGridVisibility() {
        return await this.setGridVisiblity(!(this.mGrid.material as any).visible);
    }
    //__________________________________________________________________________________________
    public async setGridVisiblity(pVal: boolean) {
        let aSceneOptions = await this.getSceneOptions();
        aSceneOptions.isGridVisible = pVal;
        this.setSceneOptions(aSceneOptions, true);

        return aSceneOptions.isGridVisible;
    }
    //__________________________________________________________________________________________
    private _createPlane() {
        let aGeometry = new PlaneGeometry(GridManager.CELL_SIZE * this.mDivisions.x,
            GridManager.CELL_SIZE * this.mDivisions.y);

        let aMaterial = new MeshBasicMaterial({
            color: Colors.YELLOW,
            side: DoubleSide,
            transparent: true,
            opacity: 0
        });

        this.mPlane = new Mesh(aGeometry, aMaterial);

        this.mPlane.name = "grid_plane";
        this.mPlane.rotateX(-Math.PI / 2);
        this.mPlane.layers.mask = 2;
        SceneContext.MAIN_SCENE.add(this.mPlane);
    }
    //__________________________________________________________________________________________
    public snapToGrid(pPoint: Vector3, _pPart: Part) {
        let aRoundTo = (eUnitType.MILLIMETERS == UnitHandler.PRESENTED_UNIT) ? 1 : 1.016;
        let aPoint = this.getRoundedPoints(pPoint.clone(), aRoundTo);
        return aPoint;
    }
    //__________________________________________________________________________________________
    public addToGrid(pPoint: Vector3) {
        let aPoint = new Vector3();
        let aNewX: number = pPoint.x / GridManager.CELL_SIZE;
        aNewX = Math.round(aNewX);
        aNewX *= GridManager.CELL_SIZE;

        let aNewY: number = pPoint.y / GridManager.CELL_SIZE;
        aNewY = Math.round(aNewY);
        aNewY *= GridManager.CELL_SIZE

        let aNewZ: number = pPoint.z / GridManager.CELL_SIZE;
        aNewZ = Math.round(aNewZ);
        aNewZ *= GridManager.CELL_SIZE;


        aPoint.set(aNewX, aNewY, aNewZ);
        return aPoint
    }
    //__________________________________________________________________________________________
    private getRoundedPoints(pPoint: Vector3, pRoundTo: number) {
        let aPoint = new Vector3();

        let aNewX: number = pPoint.x
        aNewX = OP3DMathUtils.getFloatRound(aNewX, pRoundTo);

        let aNewY: number = pPoint.y
        aNewY = OP3DMathUtils.getFloatRound(aNewY, pRoundTo);


        let aNewZ: number = pPoint.z
        aNewZ = OP3DMathUtils.getFloatRound(aNewZ, pRoundTo);

        aPoint.set(aNewX, aNewY, aNewZ);

        return aPoint;
    }
    //__________________________________________________________________________________________
    private _remove() {
        this.mIsReady = false;
        OptixPartUtils.distractPart(this.mPlane);
        if (this.mGrid != null) {
            this.mGrid.parent.remove(this.mGrid);
            this.mGrid = null;
        }

        if (null != this.mOP3DBreadboard) {
            this.mOP3DBreadboard.parent.remove(this.mOP3DBreadboard);
            this.mOP3DBreadboard = null;
        }
    }
    //__________________________________________________________________________________________
    private async _create(pSceneOptions: iSceneOptions) {
        this.mDivisions = pSceneOptions.divisions;
        await this._createBreadboard(pSceneOptions.isBreadboardVisible);
        this._createGrid(pSceneOptions.isGridVisible);
        this._createPlane();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________

}
