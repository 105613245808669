import { useContext, useEffect, useRef, useState } from "react";
import classes from "./../../UI/optichat-message.module.scss";
import botAvatar from "../../../../../public/images/icons_new/chatbot/ai_avatar.svg";
import { ServerContext } from "../../../server/ServerContext";
import { MessagesHandler } from "../../../_context/MessagesHandler";
import warningIcon from "../../../../../public/images/icons_new/warning.svg";
import OptiChatContext, {
  eChatMessageType,
  iChatBotElasticResponse,
  iOptiChatMessage,
} from "../../store/OptiChatContext";
import { waitingDots } from "../../store/OptiChatProvider";
import { iOptiChatTabData } from "./OptiChatTabs";
import { OpticsMenu } from "../../../ui/menus/_search/OpticsMenu";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { ViewUtils } from "../../../ui/ViewUtils";
import { OptiChatTabUtils } from "../../utils/OptiChatTabUtils";

const OptiChatBotMessage = (props: { data: iOptiChatMessage }) => {
  const contentRef = useRef<HTMLDivElement>();
  const [message, setMessage] = useState(
    ViewUtils.convertReadmeToHtml(props.data.message)
  );
  const [isError, setIsError] = useState(
    props.data.type === eChatMessageType.ERROR_RESPONSE
  );
  const { data, session, tabs, setTabs } = useContext(OptiChatContext);

  const hasResponse = props.data.message !== "";
  let intervalId;
  let aDots = "";

  useEffect(() => {
    if (props.data.typed === true) {
      contentRef.current.scrollIntoView({ block: "end" });
      return;
    }

    contentRef.current.scrollIntoView({ block: "end" });
    addMessage();
  }, [hasResponse]);

  const addErrorMessage = async () => {
    setIsError(true);
    let aMessage = MessagesHandler.CHAT_BOT_ERROR;
    props.data.type = eChatMessageType.ERROR_RESPONSE;
    props.data.message = aMessage;
    await ViewUtils.typeEffect(aMessage, contentRef.current);
  };
  const addMessage = async () => {
    data.current.isWaitingServerResponse = true;

    let aResponse = await ServerContext.SERVER.addMessage({
      message: props.data.userMessage,
      session: session,
      changes: data.current.changes,
    });

    const activeTab = tabs.find((item) => item.active === true);
    OptiChatTabUtils.updateCurrTab(data.current, activeTab);

    data.current.changes = [];

    clearInterval(intervalId);
    props.data.typed = true;

    if (aResponse.success === true) {
      try {
        let aMessage = aResponse.data.data.message_to_user.content;
        props.data.message = aMessage;

        handleElasticResponse(aResponse.data.data.elastic);

        await ViewUtils.typeEffect(aMessage, contentRef.current);
        if (contentRef.current != null) {
          contentRef.current.scrollIntoView({ block: "end" });
        }

        setMessage(ViewUtils.convertReadmeToHtml(aMessage));
      } catch (e) {
        await addErrorMessage();
      }
    } else {
      await addErrorMessage();
    }

    data.current.isWaitingServerResponse = false;
  };

  const handleElasticResponse = async (
    pElasticData: Array<iChatBotElasticResponse>
  ) => {
    let aLastAdded: iOptiChatTabData = null;
    let aUpdatedItems = [...tabs];

    for (let i = 0; i < pElasticData.length; i++) {
      if (Object.keys(pElasticData[i]).length === 0) {
        continue;
      }
      const aCurr = pElasticData[i];
      const aIdx = aUpdatedItems.findIndex(
        (item) => item.title === aCurr.title
      );

      let aNewTabData: iOptiChatTabData = {
        key: Op3dUtils.idGenerator(),
        active: false,
        title: aCurr.title,
        requested_params: aCurr.requested_params,
      };

      aLastAdded = aNewTabData;
      if (aIdx !== -1) {
        if (aUpdatedItems[aIdx].active !== true) {
          const aNewChanges = OptiChatTabUtils.switchTab(
            data.current,
            aCurr.title
          );

          data.current.changes = aNewChanges;
        }

        aUpdatedItems.splice(aIdx, 1, aNewTabData);
      } else {
        aUpdatedItems.push(aNewTabData);
      }
    }

    if (aLastAdded !== null) {
      aUpdatedItems.forEach((item) => (item.active = false));
      aLastAdded.active = true;
      OptiChatTabUtils.updateCurrTab(data.current, aLastAdded);
      data.current.currTab = aLastAdded.title;
      setTabs([...aUpdatedItems]);
      OpticsMenu.instance.setFilters(aLastAdded.requested_params);
      await OpticsMenu.instance._getResults(true, false);
    }
  };

  useEffect(() => {
    intervalId = setInterval(() => {
      if (hasResponse) {
        clearInterval(intervalId);
        return;
      }

      aDots = waitingDots(contentRef.current, aDots);
    }, 400);
    return () => {
      clearInterval(intervalId);
    };
  }, [hasResponse]);

  return (
    <div
      data-testid="bot-message"
      className={`${classes["chat-message"]} ${
        isError ? classes["error-response"] : classes[props.data.type]
      }`}
    >
      <div>
        <div className={classes.avatar}>
          {isError ? <img src={warningIcon} /> : <img src={botAvatar} />}
        </div>
        <div className={classes.content}>
          <div
            ref={contentRef}
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default OptiChatBotMessage;
