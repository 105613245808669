import { eUnitType } from "../../../../_context/Enums";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { Strings } from "../../../../_context/Strings";
import { iOP3DHTMLInputElement } from "../../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { ViewUtils } from "../../../ViewUtils";
import { iRangeDropdownElement, iOpticsFilterComp, iRangeData } from "../MenusContext";
import { Filter } from "./Filter";

export interface iBootstrapSliderOptions {
    min?: number;
    max?: number;
    step?: number;
    precision?: number;
    value?: number | Array<number>;
    range?: boolean;
    reversed?: boolean;
    enabled?: boolean;
    orientation?: string;
    selection?: string;
    tooltip?: string;
    tooltip_position?: string;
    tooltip_split?: boolean;
    handle?: string;
    rtl?: string | boolean;
    formatter?: Function;
    natural_arrow_keys?: boolean;
    ticks?: Array<number>;
    ticks_positions?: Array<number>;
    ticks_labels?: Array<string>;
    ticks_snap_bounds?: number;
    ticks_tooltip?: boolean;
    scale?: string;
    focus?: boolean;
    labelledby?: string | [];
    rangeHighlights?: [];
    lock_to_ticks?: boolean;
}

export type SliderEvent = "slide" | "slideStart" | "slideStop" | "change" | "slideEnabled" | "slideDisabled";
export type SliderValue = number & Array<number>;

interface Slider {
    on(eventType: SliderEvent, callback: Function): void;
    off(eventType: SliderEvent, callback: Function): void;
    isEnabled(): boolean;
    destroy(): void;
    enable(): void;
    disable(): void;
    toggle(): void;
    setValue(newValue: SliderValue, triggerSlideEvent?: boolean, triggerChangeEvent?: boolean);
    getValue(): SliderValue;
    getElement(): HTMLElement;
    getAttribute(pAttribute: string): any;
    setAttribute(pAttribute: string, pValue: any): void;
    refresh(options?): void;
    relayout(): void;
}

declare var Slider: {
    prototype: Slider;
    new(pId: string, pBootstrapSliderOptions: iBootstrapSliderOptions): Slider;
};


export interface iRangeFilterJson {
    min: number;
    max: number;
    value: SliderValue;
    showDropdown: boolean;
    unit: eUnitType;
};


export type RangeChangeFunc = (pSliderValue: SliderValue) => void;

export class RangeFilter extends Filter {

    private static SKIN_PATH = "./skins/menus/filters/range_filter.html";
    private static SERVER_ERR: number = 0;

    private mSingleInputElement: HTMLElement;
    private mInputsParent: HTMLElement;
    private mSliderInput: HTMLInputElement;

    //Slider:
    protected mSlider: Slider;
    private mInputElements: Array<iOP3DHTMLInputElement>;
    private mIsUnitCanChange: boolean = false;

    private mDropdown: HTMLElement;
    private mSelect: HTMLSelectElement;
    private mRangeDropdownData: Array<iRangeDropdownElement>;
    private mShowDropdown: boolean = false;

    private mUnitSign: string;

    //__________________________________________________________________________________________
    constructor(pData: iOpticsFilterComp<iRangeData>) {
        super(RangeFilter.SKIN_PATH, pData);
    }
    //__________________________________________________________________________________________
    public distract() {
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        if (Op3dContext.isWidget === true) {
            return
        }
        super._onCreationComplete();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected init() {
        super.init();

        this.mInputsParent = this._getPart('input_elements_parent', true);
        this.mSliderInput = this._getPart('slider_input_comp', true) as HTMLInputElement;
        this.mDropdown = this._getPart('dropdown_parent', true);
        this.mSelect = this._getPart('dropdown-element', true) as HTMLSelectElement;
        this.mSelect.addEventListener('change', () => this.onChangeSelect());
        this.mSingleInputElement = this._getPart('single_input_element');
        ViewUtils.removeFromParent(this.mSingleInputElement);
        this.mInputElements = new Array<iOP3DHTMLInputElement>();

        let aMsg = "The selected mount has a shape and size limitation.<br/>";
        aMsg += "The Optical element search form, will show the compatible Optics for the selected mount.<br/>";
        aMsg += "If you wish to search the entire database, use mount G0001: Optical Design Mount.";

        this.updateInfo(aMsg);
    }
    //__________________________________________________________________________________________
    public reset(pToChange: boolean = false) {
        if (this.mSlider == null) {
            return;
        }

        let aSliderMin = parseFloat(this.mSlider.getAttribute('min'));
        let aSliderMax = parseFloat(this.mSlider.getAttribute('max'));

        let aVal = [aSliderMin, aSliderMax] as SliderValue
        //this.setValue(aVal, false);

        this.mSlider.setValue(aVal);
        this.onSlide(aVal);

        if (null != this.mOnChange) {
            let aResetVal = (true == this.mSendDataOnEdges) ? aVal : null;
            this.mOnChange(aResetVal, pToChange);
        }
    }
    //__________________________________________________________________________________________
    public updateFilterData(pRangeData: iRangeData, pToChange: boolean = true) {
        let aData = pRangeData.data;
        if (null == this.mSlider) {
            if (null != aData) {
                this.createSlider(aData);
            }

            this.mIsUnitCanChange = (null != pRangeData.isCanChangeUnit) ?
                pRangeData.isCanChangeUnit : true;

            let aUnitSign = pRangeData.unit != null ? pRangeData.unit :
                UnitHandler.shortSign;
            this.createInputElements(aUnitSign);
        } else {
            for (let item in aData) {
                this.mSlider.setAttribute(item, aData[item]);
            }

            for (let i = 0; i < this.mInputElements.length; i++) {
                this.onChangeInput(i, pToChange);
            }
        }

        if (null != aData) {
            if (false == aData.enabled) {
                this.disable();
            } else {
                this.enable();
            }
        }

        this.mSendDataOnEdges = (null != pRangeData.sendDataOnEdges) ?
            pRangeData.sendDataOnEdges : false;

        this._updateDropdown(pRangeData.showDropdown);
    }
    //__________________________________________________________________________________________
    private _updateDropdown(pToShow: boolean) {
        this._resetDropdown();

        this.mShowDropdown = pToShow != null ? pToShow : false;
        if (pToShow == true) {
            this.createDropdown();
        }
    }
    //__________________________________________________________________________________________
    private _resetDropdown() {
        ViewUtils.setElementVisibilityByDNone(this.mDropdown, false);

        if (this.mSelect == null) {
            return;
        }

        while (this.mSelect.children.length > 1) {
            let aChild = this.mSelect.children[1];
            ViewUtils.removeFromParent(aChild as HTMLElement);
        }

        if (this.mRangeDropdownData != null) {
            this.mRangeDropdownData.splice(0);
        }
    }
    //__________________________________________________________________________________________
    public setData(pName: string, pData: Array<any>) {
        for (let i = 0; i < pData.length; i++) {
            let aKey = Object.keys(pData[i])[0];
            if (aKey === pName) {

                switch (pName) {
                    default:
                        let aVal: { from: number, to: number } = Object.values(pData[i])[0] as any;
                        let aSliderValue = [aVal.from, aVal.to] as SliderValue;

                        this.mShowDropdown = true;
                        this._updateDropdown(this.mShowDropdown);
                        this.setValue(aSliderValue, false);
                        this.updateCollapse({ toShow: true, scrollInfoView: true });
                        break;
                }
                return;
            }
        }
    }
    //__________________________________________________________________________________________
    private createDropdown() {
        let aUnit = (this.mFilterComp.data as iRangeData).filterHandler.filterUnit;
        let aSign = UnitHandler.getShortSign(aUnit);
        let aFactor = (aUnit == eUnitType.MILLIMETERS) ? 25.4 : 1;

        let aLetter = this.mTitle != null ? this.mTitle.substring(0, 1).toUpperCase() : "";
        let aMin = this.mSlider.getAttribute('min') as number;
        let aMax = this.mSlider.getAttribute('max') as number;

        let aFrom = 0.5 * aFactor;
        if (aFrom < aMin) {
            ViewUtils.setElementVisibilityByDNone(this.mDropdown, false);
            return;
        }

        ViewUtils.setElementVisibilityByDNone(this.mDropdown, true);

        let aTo = aFrom;
        this.mSelect.appendChild(this.getDropdownOption(aLetter, aSign, null, aTo));

        aTo = 1 * aFactor;
        if (aMax >= aTo) {
            this.mSelect.appendChild(this.getDropdownOption(aLetter, aSign, aFrom, aTo));
        }

        if (aMax > aTo) {
            aFrom = 1 * aFactor
            this.mSelect.appendChild(this.getDropdownOption(aLetter, aSign, aFrom, aTo));
        }

        aTo = 2 * aFactor;
        if (aMax >= aTo) {
            this.mSelect.appendChild(this.getDropdownOption(aLetter, aSign, aFrom, aTo));
        }

        if (aMax >= aTo) {
            aFrom = 2 * aFactor
            this.mSelect.appendChild(this.getDropdownOption(aLetter, aSign, aFrom, aTo));
        }

        if (aMax > aTo) {
            this.mSelect.appendChild(this.getDropdownOption(aLetter, aSign, aFrom));
        }
    }
    //__________________________________________________________________________________________
    private getDropdownOption(pLetter: string, pSign: string, pFrom?: number, pTo?: number) {
        let aOption = document.createElement('option') as HTMLOptionElement;

        let aFrom = (pFrom != null) ? pFrom : this.mSlider.getAttribute('min');
        let aTo = (pTo != null) ? pTo : this.mSlider.getAttribute('max');
        aOption.setAttribute('from', aFrom.toString());
        aOption.setAttribute('to', aTo.toString());

        let aText = '';

        if (pFrom != pTo) {
            aText += (pFrom != null) ? (pFrom + pSign + ' < ') : '';
            aText += pLetter;
            aText += (pTo != null) ? (' < ' + pTo + pSign) : '';
        } else {
            aText += pLetter + ' = ' + pFrom + pSign;
        }

        aOption.text = aText;

        return aOption;
    }
    //__________________________________________________________________________________________
    private onChangeSelect() {
        let aIndex = this.mSelect.selectedIndex;
        if (aIndex <= 0) {
            return;
        }

        let aOption = this.mSelect.options[aIndex];
        let aVal = [parseFloat(aOption.getAttribute('from')), parseFloat(aOption.getAttribute('to'))] as SliderValue;
        this.setValue(aVal);
    }
    //__________________________________________________________________________________________
    public initFromJson(pData: iRangeFilterJson): void {
        if (null == pData) {
            return;
        }

        let aValue = new Array<number>() as SliderValue;
        for (let i = 0; i < pData.value.length; i++) {
            aValue.push(pData.value[i]);
        }

        this.mSlider.setAttribute('min', pData.min);
        this.mSlider.setAttribute('max', pData.max);
        this.mShowDropdown = pData.showDropdown;

        this._updateDropdown(this.mShowDropdown);
        this.setValue(aValue, false);

        let aFilterUnit = (this.mFilterComp.data as iRangeData).filterHandler.filterUnit;
        if ((true == this.mIsUnitCanChange) && (pData.unit != aFilterUnit)) {
            this.updateUnit(aFilterUnit);
            this._onChange(false);
        }
    }
    //_______________________________________________________
    public exportToJson() {
        if (true == this.mContainer.classList.contains(Strings.D_NONE)) {
            return null;
        }

        let aData: iRangeFilterJson = {
            min: this.mSlider.getAttribute('min'),
            max: this.mSlider.getAttribute('max'),
            value: this.mSlider.getValue().slice() as SliderValue,
            showDropdown: this.mShowDropdown,
            unit: (this.mFilterComp.data as iRangeData).filterHandler.filterUnit
        };

        return aData;
    }
    //_______________________________________________________
    public setValue(pSliderValue: SliderValue, pToChange: boolean = true) {
        if (pSliderValue == null || this.mSlider == null) {
            return;
        }

        this.mSlider.setValue(pSliderValue);
        this.onSliderStop(pSliderValue, pToChange);
    }
    //__________________________________________________________________________________________
    private createSlider(pData: iBootstrapSliderOptions) {
        this.mSlider = new Slider('#' + this.mSliderInput.id, pData);
        this.mSlider.on('slide', (e: SliderValue) =>
            this.doBeforeChange(() => this.onSlide(e)));
        this.mSlider.on('slideStop', (e: SliderValue) =>
            this.doBeforeChange(() => this.onSliderStop(e)));
    }
    //__________________________________________________________________________________________
    private doBeforeChange(pCallback: Function) {
        if (null != this.mSelect) {
            this.mSelect.selectedIndex = 0;
        }

        if (pCallback) {
            pCallback();
        }
    }
    //__________________________________________________________________________________________
    private createInputElements(pUnit: string) {
        let aValue = this.mSlider.getValue();

        let aSmallerThenLabel = this._getPart('smaller_then_label');
        ViewUtils.removeFromParent(aSmallerThenLabel);

        for (let i = 0; i < 2; i++) {
            let aInputDiv = this.mSingleInputElement.cloneNode(true) as HTMLElement;

            let aInput = Op3dUtils.getElementIn(aInputDiv, 'slider_input', true) as
                iOP3DHTMLInputElement;
            aInput.value = aValue[i].toString();
            aInput.prevValue = aInput.value;
            aInput.addEventListener('change',
                () => this.doBeforeChange(() => this.onChangeInput(i)));

            let aUnit = Op3dUtils.getElementIn(aInputDiv, 'input_element_unit_name');
            if (pUnit == "") {
                ViewUtils.removeFromParent(aUnit);
            } else {
                aUnit.innerHTML = pUnit;
                aUnit.id += i.toString();
                this.mUnitSign = pUnit;
            }

            this.mInputElements.push(aInput);
            this.mInputsParent.appendChild(aInputDiv);
        }
    }
    //__________________________________________________________________________________________
    private onSlide(pSliderValue: SliderValue) {
        if (this.mInputElements == null) {
            return;
        }

        for (let i = 0; i < pSliderValue.length; i++) {
            this.mInputElements[i].value = pSliderValue[i].toString();
        }
    }
    //__________________________________________________________________________________________
    private onSliderStop(e: SliderValue, pToChange: boolean = true) {
        this.onSlide(e);
        this._onChange(pToChange);
    }
    //__________________________________________________________________________________________
    protected _onChange(pToChange: boolean = true) {
        if (this.mOnChange != null) {
            let aVal = this._sliderRealUnit;
            this.mOnChange(aVal, pToChange);
        }
    }
    //__________________________________________________________________________________________
    private onChangeInput(pIndex: number = 0, pToChange: boolean = true) {
        let aInput = this.mSlider.getValue();
        let aVal = parseFloat(this.mInputElements[pIndex].value);
        if (true == isNaN(aVal)) {
            this.mInputElements[pIndex].value = this.mInputElements[pIndex].prevValue;
            return;
        }
        this.mInputElements[pIndex].prevValue = this.mInputElements[pIndex].value;

        if (aInput.length != null) {
            aInput[pIndex] = aVal;
            if (aInput[0] > aInput[1]) {
                let aVal1 = aInput[1];
                aInput[1] = aInput[0];
                aInput[0] = aVal1;
            }
        } else {
            (aInput as number) = aVal;
        }

        this.mInputElements[pIndex].blur();

        this.mSlider.setValue(aInput);
        this.onSliderStop(this.mSlider.getValue(), pToChange);
    }
    //__________________________________________________________________________________________
    public updateUnit(pUnit: eUnitType): void {
        if (false == this.mIsUnitCanChange) {
            return;
        }

        let aUnitConversion = (eUnitType.MILLIMETERS == pUnit) ? 25.4 : (1 / 25.4);

        let aVal = this.mSlider.getValue();
        for (let i = 0; i < aVal.length; i++) {
            aVal[i] *= aUnitConversion;
            this.mInputElements[i].value = aVal[i].toString();
            let aUnitSign = Op3dUtils.getElementIn(this.mContainer, 'input_element_unit_name' + i);
            aUnitSign.innerHTML = UnitHandler.getShortSign(pUnit);
        }

        this.mSlider.setAttribute('min', (this.mSlider.getAttribute('min') * aUnitConversion));
        this.mSlider.setAttribute('max', (this.mSlider.getAttribute('max') * aUnitConversion));
        this.mSlider.setValue(aVal);

        if (this.mShowDropdown == true) {
            this._updateDropdown(true);
        }
    }
    //__________________________________________________________________________________________
    protected get _sliderRealUnit(): Array<number> {
        let aMin = this.mSlider.getAttribute('min');
        let aMax = this.mSlider.getAttribute('max');

        let aVal = (Object as any).assign([], this.mSlider.getValue()) as any;
        aVal[0] = OP3DMathUtils.roundNum(Math.max(aVal[0] *
            (1 - Math.sign(aVal[0]) * RangeFilter.SERVER_ERR), aMin), 2);
        aVal[1] = OP3DMathUtils.roundNum(Math.min(aVal[1] *
            (1 + Math.sign(aVal[1]) * RangeFilter.SERVER_ERR), aMax), 2);

        if ((false == this.mSendDataOnEdges) && ((aVal[0] == aMin) && (aVal[1] == aMax))) {
            return null;
        }

        if (this.mUnitSign == '%') {
            aVal[0] /= 100;
            aVal[1] /= 100;

            return aVal;
        }

        /**
         * @TODO handle unit change
         */
        if (this.mIsUnitCanChange == false) {
            return aVal;
        }

        let aFilterUnit = (this.mFilterComp.data as iRangeData).filterHandler.filterUnit;

        let aScale = (eUnitType.MILLIMETERS == aFilterUnit) ? 1 : 25.4;
        for (let i = 0; i < aVal.length; i++) {
            aVal[i] *= aScale;
        }

        return aVal;
    }
    //__________________________________________________________________________________________
    public disable() {
        this._getPart('div_parent').classList.add(Strings.DISABLED_STATE);
        if (null != this.mSlider) {
            this.mSlider.disable();
        }
        this.showInfo(true);
    }
    //__________________________________________________________________________________________
    public enable() {
        this._getPart('div_parent').classList.remove(Strings.DISABLED_STATE);
        if (null != this.mSlider) {
            this.mSlider.enable();
        }
        this.showInfo(false);
    }
    //__________________________________________________________________________________________
}
