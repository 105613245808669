import { Box3, MathUtils, Object3D, OrthographicCamera, Vector3, WebGLRenderer } from "three"
import { Op3dContext } from "../../_context/Op3dContext"
import { eViewTypeSpheric, eViewTypeXYZ } from "../absSceneCube"
import { SceneContext } from "../SceneContext"
import { SceneCube } from "../SceneCube"
import { OP3DMathUtils } from "../../_utils/OP3DMathUtils"
import { MathContext } from "../../_context/MathContext"
import { eViewMenuState } from "./ViewMenu/ViewMenu"

export namespace CameraUtils {
    const DIAGONAL = 550;
    //__________________________________________________________________________________________

    export const switchSceneView = (pCoordsSys: string) => {
        let center = Op3dContext.PARTS_MANAGER.getCenter()
        let aSphericViewText
        switch (pCoordsSys) {
            case eViewTypeXYZ.XY_VIEW:
                SceneContext.CURRENT_VIEW.type = eViewTypeXYZ.XY_VIEW
                _enableFourView(false)
                setCameraView(center, eViewTypeXYZ.XY_VIEW)

                addReturnToUserViewCallback(eViewTypeXYZ.XY_VIEW)

                aSphericViewText = eViewTypeSpheric.FRONT
                break;
            case eViewTypeXYZ.YZ_VIEW:
                SceneContext.CURRENT_VIEW.type = eViewTypeXYZ.YZ_VIEW
                _enableFourView(false)
                setCameraView(center, eViewTypeXYZ.YZ_VIEW)

                addReturnToUserViewCallback(eViewTypeXYZ.YZ_VIEW)
                aSphericViewText = eViewTypeSpheric.RIGHT
                break;
            case eViewTypeXYZ.XZ_VIEW:
                SceneContext.CURRENT_VIEW.type = eViewTypeXYZ.XZ_VIEW
                _enableFourView(false)
                setCameraView(center, eViewTypeXYZ.XZ_VIEW)

                addReturnToUserViewCallback(eViewTypeXYZ.XZ_VIEW)
                aSphericViewText = eViewTypeSpheric.TOP
                break
            case eViewTypeXYZ.ISO_VIEW:
                SceneContext.CURRENT_VIEW.type = eViewTypeXYZ.ISO_VIEW
                _enableFourView(false)
                setCameraView(center, eViewTypeXYZ.ISO_VIEW)

                addReturnToUserViewCallback(eViewTypeXYZ.ISO_VIEW)
                aSphericViewText = eViewTypeSpheric.ISO_VIEW
                break
            case eViewTypeXYZ.FOUR_WINDOW_VIEW:
                _enableFourView(true)
                break;
        }

        if (eViewTypeXYZ.FOUR_WINDOW_VIEW === pCoordsSys) return

        if (SceneContext.CURRENT_VIEW.mode === eViewMenuState.XYZ) {
            SceneContext.CURRENT_VIEW.type = eViewTypeXYZ.ISO_VIEW
            SceneContext.ISO_VIEW.setAttribute('data-content', pCoordsSys)
        } else {
            SceneContext.ISO_VIEW.setAttribute('data-content', aSphericViewText)
        }


    }
    //__________________________________________________________________________________________
    const addReturnToUserViewCallback = (pViewType: eViewTypeXYZ) => {
        const someFunc = () => {
            let aAzimuth = MathUtils.radToDeg(SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.getAzimuthalAngle()).toFixed(0)
            let aPolar = MathUtils.radToDeg(SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.getPolarAngle()).toFixed(0)
            const aViewAngles = {
                [eViewTypeXYZ.ISO_VIEW]: { PHI: 65, THETA: 225 },
                [eViewTypeXYZ.XY_VIEW]: { PHI: 90, THETA: 90 },
                [eViewTypeXYZ.YZ_VIEW]: { PHI: 90, THETA: 180 },
                [eViewTypeXYZ.XZ_VIEW]: { PHI: 0, THETA: 180 },
            }

            if (aAzimuth !== aViewAngles[pViewType].PHI || aPolar !== aViewAngles[pViewType].THETA) {
                SceneContext.ISO_VIEW.setAttribute('data-content', 'User view')
                SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.removeEventListener('change', someFunc);
            }
        }
        SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.addEventListener('change', someFunc);
    }
    //__________________________________________________________________________________________
    export const returnXYZAngles = () => {
        let horizontalAngle = +CameraUtils.phi().toFixed(0);
        let verticalAngle = +CameraUtils.theta().toFixed(0);

        let aZAngle = 360 - Math.abs((90 - horizontalAngle))
        if (horizontalAngle < 90) {
            aZAngle = 90 - horizontalAngle
        }
        let aXAngle = horizontalAngle

        return { X: aXAngle, Y: verticalAngle, Z: aZAngle };
    }
    //__________________________________________________________________________________________
    const changeLabelsForFourViews = () => {
        if (SceneContext.CURRENT_VIEW.mode === eViewMenuState.SPHERIC) {
            SceneContext.XY_VIEW.setAttribute('data-content', eViewTypeSpheric.FRONT)
            SceneContext.YZ_VIEW.setAttribute('data-content', eViewTypeSpheric.RIGHT)
            SceneContext.ZX_VIEW.setAttribute('data-content', eViewTypeSpheric.TOP)
        } else {
            SceneContext.XY_VIEW.setAttribute('data-content', eViewTypeXYZ.XY_VIEW)
            SceneContext.YZ_VIEW.setAttribute('data-content', eViewTypeXYZ.YZ_VIEW)
            SceneContext.ZX_VIEW.setAttribute('data-content', eViewTypeXYZ.XZ_VIEW)
        }

    }
    //__________________________________________________________________________________________
    export const _enableFourView = (pState: boolean) => {
        changeLabelsForFourViews()
        if (pState) {

            SceneContext.CURRENT_VIEW.type = eViewTypeXYZ.FOUR_WINDOW_VIEW
            SceneContext.OP3D_SCENE.cube.cubeRenderer.setSize(100, 100);
            SceneContext.XY_VIEW.classList.remove('hide_view')
            SceneContext.YZ_VIEW.classList.remove('hide_view')
            SceneContext.ZX_VIEW.classList.remove('hide_view')

            SceneContext.RENDERER.setSize(Op3dContext.CONTAINER.clientWidth / 2,
                Op3dContext.CONTAINER.clientHeight / 2);
            SceneContext.SPRITE_RENDERER.setSize(Op3dContext.CONTAINER.clientWidth / 2,
                Op3dContext.CONTAINER.clientHeight / 2);
            _setFourViewsSize(SceneContext.CAMERA, SceneContext.RENDERER)


            let center = Op3dContext.PARTS_MANAGER.getCenter()
            SceneContext.OP3D_SCENE.lookAt = center
            CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, 'BASE')

            _setCurrentCameraView({ normal: new Vector3(0, 0, -1), camera: SceneContext.CAMERA2, viewType: eViewTypeXYZ.XY_VIEW, renderer: SceneContext.RENDERER2 });
            _setCurrentCameraView({ normal: new Vector3(-1, 0, 0), camera: SceneContext.CAMERA3, viewType: eViewTypeXYZ.YZ_VIEW, renderer: SceneContext.RENDERER3 });
            _setCurrentCameraView({ normal: new Vector3(0, 1, 0), camera: SceneContext.CAMERA4, viewType: eViewTypeXYZ.XZ_VIEW, renderer: SceneContext.RENDERER4 });

            SceneContext.CAMERA4.rotation.z = 0
            SceneContext.CAMERA4.rotateZ(-Math.PI / 2)

        } else {

            SceneContext.OP3D_SCENE.cube.cubeRenderer.setSize(SceneCube.SIZE, SceneCube.SIZE);

            SceneContext.XY_VIEW.classList.add('hide_view')
            SceneContext.YZ_VIEW.classList.add('hide_view')
            SceneContext.ZX_VIEW.classList.add('hide_view')

            SceneContext.RENDERER.setSize(Op3dContext.CONTAINER.clientWidth,
                Op3dContext.CONTAINER.clientHeight);
            SceneContext.SPRITE_RENDERER.setSize(Op3dContext.CONTAINER.clientWidth,
                Op3dContext.CONTAINER.clientHeight);

            SceneContext.OP3D_SCENE.setBasicWatermark(SceneContext.RENDERER);
        }
    }
    //__________________________________________________________________________________________
    export const _setFourViewsSize = (pCamera: OrthographicCamera, pRenderer: WebGLRenderer) => {
        let width = Op3dContext.CONTAINER.clientWidth / 2
        let height = Op3dContext.CONTAINER.clientHeight / 2
        let aFrustumScale = 3;
        pCamera.left = -width / aFrustumScale
        pCamera.right = width / aFrustumScale
        pCamera.top = height / aFrustumScale
        pCamera.bottom = -height / aFrustumScale
        pCamera.updateProjectionMatrix();
        pRenderer.setSize(width, height);

        SceneContext.OP3D_SCENE.setBasicWatermark(pRenderer);
    }
    //__________________________________________________________________________________________
    export const _setCurrentCameraView = async (pData: { viewType: eViewTypeXYZ, normal: Vector3, camera: OrthographicCamera, renderer: WebGLRenderer }) => {
        _setFourViewsSize(pData.camera, pData.renderer)
        let aPosCAMERA = SceneContext.OP3D_SCENE.getCameraPosition({ cube: null, normal: pData.normal }, pData.camera);
        pData.camera.position.copy(aPosCAMERA)
        pData.camera.lookAt(SceneContext.OP3D_SCENE.lookAt.clone());
        CameraUtils.fitCameraToCenteredObject(pData.camera, Op3dContext.PARTS_MANAGER.partsContainer, pData.viewType);
    }
    //__________________________________________________________________________________________
    export const setZoomToParts = (pCenter: Vector3) => {

        const distance = SceneContext.CAMERA.position.distanceTo(pCenter);
        const zoomRange = 100 - 1;
        const zoomFactor = 1 - (distance / pCenter.distanceTo(SceneContext.CAMERA.position));
        const zoom = 1 + zoomRange * zoomFactor;

        SceneContext.CAMERA.zoom = zoom;
        SceneContext.CAMERA.updateProjectionMatrix()
    }
    //__________________________________________________________________________________________
    export const setCameraView = (pCenter: Vector3, pViewType: eViewTypeXYZ) => {

        SceneContext.OP3D_SCENE.lookAt = pCenter;
        switch (pViewType) {
            case eViewTypeXYZ.XY_VIEW:
                CameraUtils.setPhi(90, 90)
                CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, eViewTypeXYZ.XY_VIEW)
                break;
            case eViewTypeXYZ.YZ_VIEW:
                CameraUtils.setPhi(180, 90)
                CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, eViewTypeXYZ.YZ_VIEW)
                break;
            case eViewTypeXYZ.XZ_VIEW:
                CameraUtils.setTheta(0, 180)
                CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, eViewTypeXYZ.XZ_VIEW)
                break;
            case eViewTypeXYZ.ISO_VIEW:
                CameraUtils.setIsometricView()
                CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, eViewTypeXYZ.ISO_VIEW)
                break;
        }

        CameraUtils._updateOnWindowResize(SceneContext.CAMERA, SceneContext.RENDERER)
        SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.update()
    }
    //__________________________________________________________________________________________

    export const fitCameraToCenteredObject = (
        pCamera: OrthographicCamera,
        pSceneParts: Object3D,
        pCase: string,
        pForSnapShot?: boolean) => {

        let aFrustumScale = SceneContext.CURRENT_VIEW.type === eViewTypeXYZ.FOUR_WINDOW_VIEW ? 1 : 4;
        let width = Op3dContext.CONTAINER.clientWidth / aFrustumScale;
        let height = Op3dContext.CONTAINER.clientHeight / aFrustumScale;

        let aChildrenNum = pSceneParts.children.length;

        if (pForSnapShot) {
            width = 300
            height = 0
        }

        let aWindowSize = (width + height)

        const aBoundingBox = new Box3();
        aBoundingBox.setFromObject(pSceneParts);
        const size = aBoundingBox.getSize(new Vector3());


        if (aChildrenNum === 0) return

        switch (pCase) {
            case eViewTypeXYZ.ISO_VIEW:

                _setCameraZoom(size.x, size.z,
                    aChildrenNum, pCamera,
                    aWindowSize, 0.2, -1000);
                break;
            case eViewTypeXYZ.XY_VIEW:
                _setCameraZoom(size.x, size.y,
                    aChildrenNum, pCamera,
                    aWindowSize, 0.2, -1000);
                break;
            case eViewTypeXYZ.YZ_VIEW:

                _setCameraZoom(size.z, size.y,
                    aChildrenNum, pCamera,
                    aWindowSize, 0.2, -1000);
                break;
            case eViewTypeXYZ.XZ_VIEW:
                if (size.x > size.z) {
                    _setCameraZoom(size.x + 500, size.z, aChildrenNum, pCamera,
                        aWindowSize, 0.2, -600);
                } else {
                    _setCameraZoom(size.z, size.x + 500, aChildrenNum, pCamera, aWindowSize,
                        0.2, -800);
                }
                break;
            default:

                let coeff = 250 - (SceneContext.OP3D_SCENE.getAngle('VERTICAL') * 200);
                _setCameraZoom(size.x, size.z, aChildrenNum, pCamera, aWindowSize, 0.2,
                    coeff - 1000);
                break;
        }

        pCamera.updateProjectionMatrix();
    }

    //_______________________________________________________________________________
    export const _updateOnWindowResize = (pCamera: OrthographicCamera, pRenderer: WebGLRenderer) => {
        let width = Op3dContext.CONTAINER.clientWidth
        let height = Op3dContext.CONTAINER.clientHeight

        pCamera.left = -width / 3
        pCamera.right = width / 3
        pCamera.top = height / 3
        pCamera.bottom = -height / 3
        pCamera.updateProjectionMatrix();
        pRenderer.setSize(width, height);
    }
    //_______________________________________________________________________________
    export const setTheta = (pTheta: number, pPhi: number) => {
        let aTheta = MathUtils.degToRad(pTheta)
        let aPhi = MathUtils.degToRad(pPhi);

        if (aTheta <= 0) {
            aTheta = MathContext.EPSILON_10;
        } else if (aTheta >= Math.PI) {
            aTheta = Math.PI - MathContext.EPSILON_10;
        }

        let aLookAt = SceneContext.OP3D_SCENE.lookAt.clone();
        let aPos = SceneContext.CAMERA.position.sub(aLookAt)
        let aR = aPos.length();

        SceneContext.CAMERA.position.x = aR * Math.sin(aTheta) * Math.cos(aPhi);
        SceneContext.CAMERA.position.z = aR * Math.sin(aTheta) * Math.sin(aPhi);
        SceneContext.CAMERA.position.y = aR * Math.cos(aTheta);
        aPos.add(aLookAt);

        SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.target = aLookAt
        SceneContext.CAMERA.lookAt(aLookAt);
        SceneContext.CAMERA.updateMatrixWorld(true);
        SceneContext.CAMERA.updateMatrix();
    }
    //_______________________________________________________________________________
    export const setPhi = (pPhi: number, Theta) => {
        let aTheta = MathUtils.degToRad(Theta)
        let aPhi = MathUtils.degToRad(pPhi);
        let aLookAt = SceneContext.OP3D_SCENE.lookAt.clone();
        let aPos = SceneContext.CAMERA.position.sub(aLookAt)

        let aR = aPos.length();

        if (aTheta <= 0) {
            aTheta = MathContext.EPSILON_10;
        } else if (aTheta >= Math.PI) {
            aTheta = Math.PI - MathContext.EPSILON_10;
        }


        SceneContext.CAMERA.position.x = aR * Math.sin(aTheta) * Math.cos(aPhi);
        SceneContext.CAMERA.position.z = aR * Math.sin(aTheta) * Math.sin(aPhi);
        SceneContext.CAMERA.position.y = aR * Math.cos(aTheta);
        aPos.add(aLookAt);

        SceneContext.OP3D_SCENE.op3dOrbitController.orbitControls.target = aLookAt
        SceneContext.CAMERA.lookAt(aLookAt);
        SceneContext.CAMERA.updateMatrixWorld(true);
        SceneContext.CAMERA.updateMatrix();
    }
    //_______________________________________________________________________________
    export const phi = () => {
        let aVec = new Vector3();
        aVec.subVectors(SceneContext.CAMERA.position, SceneContext.OP3D_SCENE.lookAt);


        let aPosX = aVec.x != 0 ? aVec.x : MathContext.EPSILON_10;
        let aPosZ = aVec.z;
        let aPhi = Math.atan2(aPosZ, aPosX);
        if (aPosZ < 0) {
            aPhi += (2 * Math.PI);
        }

        return (aPhi * MathUtils.RAD2DEG);
    }
    //_______________________________________________________________________________
    export const theta = () => {
        let aVec = new Vector3();
        aVec.subVectors(SceneContext.CAMERA.position, SceneContext.OP3D_SCENE.lookAt);

        let aR = aVec.length();
        let aPosY = aVec.y;
        let aTheta = Math.acos(aPosY / aR);

        if (aTheta <= 0) {
            aTheta = MathContext.EPSILON_10;
        } else if (aTheta >= Math.PI) {
            aTheta = Math.PI - MathContext.EPSILON_10;
        }

        return (aTheta * MathUtils.RAD2DEG);
    }
    //_______________________________________________________________________________
    export const setIsometricView = () => {
        CameraUtils.setPhi(225, 65)
        CameraUtils.setTheta(65, 225)
    }
    //_______________________________________________________________________________
    export const fitToZoom = (pType: eViewTypeXYZ) => {
        let center = Op3dContext.PARTS_MANAGER.getCenter()
        SceneContext.OP3D_SCENE.lookAt = center
        CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, pType)
    }
    //_______________________________________________________________________________
    export const _setCameraZoom = (pBoxSize1: number, pBoxSize2: number, pChildrenNum: number,
        pCamera: OrthographicCamera, pWindowSize: number, pCoeffOneChild: number,
        pCoeff: number) => {

        let aBoxMeasureSide = OP3DMathUtils.calculateBoxDiagonal(pBoxSize1, pBoxSize2)
        let aBoxWindow = aBoxMeasureSide + pWindowSize

        if (pChildrenNum === 1) {
            pCamera.zoom = ((DIAGONAL / pCoeffOneChild) / aBoxWindow);

        } else {
            pCamera.zoom = ((DIAGONAL - pCoeff) / aBoxWindow);
        }
    }
}