import { useEffect, useState } from "react"

export interface iBlackBoxDiameterProps {
    title: string,
    diameterData?: iDiameterData,
    changeInput: Function
}
interface iDiameterData {
    defaultDiameter: string
}

export function BlackBoxInputDiameterField({ title, changeInput, diameterData }: iBlackBoxDiameterProps) {
    const [prevValue, setPrevValue] = useState('')
    const [value, setValue] = useState(diameterData.defaultDiameter)

    useEffect(() => {
        setValue(diameterData.defaultDiameter);
        changeInput(diameterData.defaultDiameter, title.toLowerCase().replaceAll(' ', '_'))
    }, [diameterData.defaultDiameter]);

    const onChangeValue = (event) => {
        const aCurrentValue = event.target.value

        if (!isNaN(aCurrentValue)) {
            setValue(aCurrentValue)
        }

    }


    const resetOnFocus = () => {
        setPrevValue(value)
        setValue('')
    }

    const addMmOnBlur = (event) => {
        const aValue = event.target.value
        const aDiameterParsed = parseFloat(aValue)

        const aDefaultParsed = parseFloat(diameterData.defaultDiameter)
        if (aValue !== '') {
            if (aDefaultParsed > aDiameterParsed) {
                setValue(aDefaultParsed + ' mm')
                changeInput(aDefaultParsed + ' mm', title.toLowerCase().replaceAll(' ', '_'))
            } else {
                setValue(aDiameterParsed + ' mm')
                changeInput(aDiameterParsed + ' mm', title.toLowerCase().replaceAll(' ', '_'))
            }
        } else {
            setValue(prevValue)
            changeInput(prevValue, title.toLowerCase().replaceAll(' ', '_'))
        }
    }


    return <div>
        <span>{ title }</span>
        <input className='w-100' type='text' value={ value } onBlur={ addMmOnBlur } placeholder={ prevValue } onFocus={ resetOnFocus } onChange={ onChangeValue } />
    </div>
}