import { ePolarizationSourceType } from "../../../_context/Enums";
import { iPoint2D, iSize } from "../../../_context/_interfaces/Interfaces";
import { iBasicGraphDataParams } from "../AnalysisContext";


export interface iZoomData {
    startPoint: iPoint2D, //sp 
    rectSize: iSize,
    totalSize: iSize; // W
}

export interface iExtendedAnalysisViewData {
    fromMinimize: boolean;
    uiData: { pixel: iPoint2D, graphOffset: iPoint2D };
    analysisChartData: iBasicGraphDataParams;
    polarizationKind?: ePolarizationSourceType
}

export enum eLogarithmicView {
    LOG_10 = "LOG_10",
    LINEAR = "LINEAR",
    NORMALIZED = "NORMALIZED"
}
export enum eExtendedViewType {
    LINE,
    TWO_D
}

export interface iDrawData {
    startPoint: iPoint2D,
    rectSize: iSize,
    totalSize: iSize;
    context: CanvasRenderingContext2D;
}

export abstract class anAnalysisResultyFactory {
    public static X_TICKS_TOTAL_LENGTH: number = 6;
    public static Y_TICKS_TOTAL_LENGTH: number = 5;

    public abstract createResult(pOptions: any): iDrawData;
}