import { eDataPermission, eStateToAnalysis } from "../../../../_context/Enums";
import { MessagesHandler } from "../../../../_context/MessagesHandler";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { OpticsContext } from "../../../../_context/OpticsContext";
import { iHash, iScatteringVO } from "../../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../../_utils/DataUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { OpticsDataLoader } from "../../../../data/data_loader/OpticsDataLoader";
import { iFace } from "../../../../parts/PartInterfaces";
import { iSimulationReflectionItem } from "../../../../simulation/SimulationContext";
import { ViewUtils } from "../../../ViewUtils";
import { AnalysisPortal } from "../../../analysis/AnalysisPortal";
import { Spinner } from "../../../home/Spinner";
import { NotificationCenter } from "../../../home/_notifications/NotificationCenter";
import { iFaceBasicData, iSurfaceSectionsData, iUploadOpticsParamsNew } from "./UploadOpticsContext";
import { UploadOpticsFormNew } from "./UploadOpticsFormNew";
import { uoFaceBasicInfoNew } from "./uoFaceBasicInfoNew";
import { uoFaceSimulationSettings } from "./uoFaceSimulationSettings";
import { uoScatteringInfoNew } from "./uoScatteringInfoNew";

export class EditOpticsForm extends UploadOpticsFormNew {

    protected static INSTANCE: EditOpticsForm;

    private constructor(pElement: HTMLElement) {
        super(pElement);
    }
    //____________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aFormsContainer = document.getElementById('forms');
            if (aFormsContainer === null) {
                throw new Error('Forms container not found');
            }
            let aContainer = document.createElement('div');
            aContainer.classList.add('modal');
            aContainer.setAttribute("data-backdrop", "false")
            aContainer.classList.add('fade');
            aContainer.setAttribute("qa_id", "qa_edit_optics_form")
            aFormsContainer.appendChild(aContainer);

            EditOpticsForm.INSTANCE = new EditOpticsForm(aContainer);
        }

        return this.INSTANCE;
    }

    //____________________________________________________________________________
    protected async _onCreationComplete() {
        super._onCreationComplete();

        this.mIsReady = true;
    }
    //____________________________________________________________________________
    protected _initSections(): void {
        super._initSections();
        this.mSections.scatteringInfo = new uoScatteringInfoNew(this._getPart("scattering-section", true))
        this.mSections.faceBasicInfo = new uoFaceBasicInfoNew(this._getPart("face-basic-info-section", true))
        this.mSections.simulationSettings = new uoFaceSimulationSettings(this._getPart("face-simulation-settings", true))
    }
    //____________________________________________________________________________
    private _getSurfacesData(): iSurfaceSectionsData {
        let aSurfacesData: iSurfaceSectionsData = {
            scattering: {},
            basicData: {},
            simulationData: {},
            deformation: {}
        }

        this.mSections.scatteringInfo?.fillSurfaceData(aSurfacesData.scattering);
        this.mSections.faceBasicInfo?.fillSurfaceData(aSurfacesData.basicData);
        this.mSections.simulationSettings?.fillSurfaceData(aSurfacesData.simulationData);
        return aSurfacesData;
    }
    //____________________________________________________________________________
    private _checkIfOpticChanged(pOriginal: iOpticsVO, pNewOptics: iOpticsVO) {

        let aIsEqualObjects = DataUtils.isObjectsEqual(pOriginal, pNewOptics);
        return aIsEqualObjects == false;
    }
    //____________________________________________________________________________
    protected async _updateSaveBtn(pFaceEvent: boolean, pMajorChange: boolean) {

        let aOldOpticsVO = DataUtils.getObjectCopy(this.mData.opticsVO);
        let aNewOpticsVO = this._getOpticsVO();
        let aIsEqualVO = DataUtils.isObjectsEqual(aOldOpticsVO, aNewOpticsVO);
        let aIsUploadAllowedByLicense = Op3dContext.USER_VO.isBasicLicense === false;
        let aAttachments = await this._getAttachments(aNewOpticsVO, false);
        let aIsSaveAllowed = this._isSaveAllowed(aNewOpticsVO, aAttachments, false);
        let aSurfacesData = this._getSurfacesData();
        let aKeys = Object.keys(aSurfacesData.deformation).length +
            Object.keys(aSurfacesData.simulationData).length +
            Object.keys(aSurfacesData.scattering).length +
            Object.keys(aSurfacesData.basicData).length;

        let aCond1 = (aIsUploadAllowedByLicense && (aIsEqualVO === false || pMajorChange));
        let aCond2 = (pFaceEvent || aKeys > 0);
        let aEnabeld = (aCond1 || aCond2) && aIsSaveAllowed === null;
        ViewUtils.setElementDisabled(this.mSaveButtonsContainer, aEnabeld == false);
    }
    //____________________________________________________________________________
    private _updateScatteringInfo(pScatteringData: iHash<iScatteringVO>) {
        const aPart = this.mData.surfacesPart;//part
        for (let original_name in pScatteringData) {


            if (aPart !== undefined) {
                let aFaces = aPart.getFaces();
                let aCurrFace = aFaces.find(face => face.originalName == original_name);
                if (aCurrFace !== undefined && aCurrFace.data !== undefined) {
                    aCurrFace.data.scattringVO = pScatteringData[original_name];
                }
            }
        }
    }
    //____________________________________________________________________________
    private _updateSimulationFaceData(pFacesData: iHash<iSimulationReflectionItem>) {
        const aPart = this.mData.surfacesPart;//part
        for (let original_name in pFacesData) {

            if (aPart !== undefined) {
                let aFaces = aPart.getFaces();
                let aCurrFace = aFaces.find(face => face.originalName == original_name);
                if (aCurrFace !== undefined && aCurrFace.data !== undefined) {
                    aCurrFace.data.reflectionData = pFacesData[original_name];
                }
            }
        }
    }
    //____________________________________________________________________________
    private _updateBasicFaceData(pFacesData: iHash<iFaceBasicData>) {
        const aPart = this.mData.surfacesPart;//part
        for (let original_name in pFacesData) {
            if (aPart !== undefined) {
                let aFaces = aPart.getFaces();
                let aCurrFace = aFaces.find(face => face.originalName == original_name);
                if (aCurrFace != null) {
                    aCurrFace.name = pFacesData[original_name].face_name;
                }
            }
        }

        Op3dContext.PARTS_MANAGER.updatePartsList();
    }
    //____________________________________________________________________________
    protected _onClose(): void {
        super._onClose();
        this.mSections.scatteringInfo?.clear();
        this.mSections.faceBasicInfo?.clear();
        this.mSections.simulationSettings?.clear();
    }
    //____________________________________________________________________________
    private _saveParaxial(pOpticsVO: iOpticsVO) {
        const aPart = this.mData.surfacesPart;//part
        if (aPart !== undefined) {
            aPart.removeSubPartsFromPart(aPart.iPart);
            Op3dContext.PARTS_MANAGER.addParaxialLens(aPart, pOpticsVO);
            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
            Op3dContext.SCENE_HISTORY.saveScene();
            Spinner.instance.hide();
            this.close();
        }
    }
    //____________________________________________________________________________
    protected async _onSave() {

        Spinner.instance.show();
        let aNewOptics = this._getOpticsVO();

        // special tratment for certain subtypes
        switch (aNewOptics.parameters.subType) {

            case OpticsContext._Paraxial_Lens:
                this._saveParaxial(aNewOptics);
                return;
        }

        let aOriginal = this.mData.originalOpticsVO;
        let aIsChanged = this._checkIfOpticChanged(aOriginal, aNewOptics);
        if (aIsChanged) {
            if (Op3dContext.USER_VO.isBasicLicense == false) {
                OpticsDataLoader.instance.removeFromCache(aNewOptics.number_id);
                let aPart = await super._onSave();
                this.mData.part = aPart;
                this.mData.surfacesPart = aPart;

                if (this.mData.groupEdit !== true) {
                    Op3dContext.PARTS_MANAGER.updatePartsByNumberID(aNewOptics.number_id,
                        this.mData.part.internalID);
                }

                //return this.mData.part;
            }
        }

        try {

            let aSurfacesData = this._getSurfacesData();
            let aIsKeysChanged = false;
            for (let item in aSurfacesData) {
                if (Object.keys(aSurfacesData[item]).length > 0) {
                    aIsKeysChanged = true;
                    break;
                }
            }

            if (aIsKeysChanged === false) {
                Spinner.instance.hide();
                if (this.mData.onFinishSave !== undefined) {
                    await this.mData.onFinishSave(this.mData.part);
                }
                return;
            }

            this._updateScatteringInfo(aSurfacesData.scattering);
            this._updateBasicFaceData(aSurfacesData.basicData);
            this._updateSimulationFaceData(aSurfacesData.simulationData);

            if (this.mData.onFinishSave !== undefined) {
                await this.mData.onFinishSave(this.mData.surfacesPart);
            }

            AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
            Op3dContext.SCENE_HISTORY.saveScene();


            this.close();

        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(e);
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.OPTICAL_ELEMENT_CREATION_ERROR,
                params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR
            });

        } finally {
            Spinner.instance.hide();
        }

        Op3dContext.SCENE_HISTORY.clearHistory();

        // return this.mData.part;
        return undefined;
    }
    //____________________________________________________________________________
    protected async _onOpen(pData: iUploadOpticsParamsNew) {
        await super._onOpen(pData);


        Op3dContext.USER_VO.isAdmin && console.log("number_id:", pData.opticsVO.number_id);

        let aIsToShowSaveAs = Op3dContext.USER_VO.isBasicLicense == false &&
            pData?.opticsVO.parameters.subType !== OpticsContext._Paraxial_Lens;

        ViewUtils.setElementVisibilityByDNone(this.mSaveAsBtn, aIsToShowSaveAs);

        if (this.mData.opticsVO.permission === eDataPermission.PUBLIC) {
            this.mData.opticsVO.number_id = Op3dUtils.idGenerator(true);
        }

        ViewUtils.setElementDisabled(this.mSaveButtonsContainer, true);

        if (pData.face !== undefined) {
            this.mSections.surfacesInfo.expand();
            this.mSections.surfacesInfo.chooseSurface(pData.face);
        }
    }
    //____________________________________________________________________________
    protected async _onClickSurface(pFace: iFace) {
        super._onClickSurface(pFace);

        let aPart = this.mData.surfacesPart //  part
        if (pFace != null) {
            this.mSections.simulationSettings?.setData({
                originalName: pFace.originalName,
                part: aPart,
                opticsVO: this.mData.opticsVO
            });

            this.mSections.faceBasicInfo?.setData({
                originalName: pFace.originalName,
                name: pFace.name
            });

            this.mSections.scatteringInfo?.setData({
                opticsVO: this.mData.opticsVO,
                originalName: pFace.originalName,
                part: aPart,
                face: pFace
            });
        } else {
            this.mSections.simulationSettings?.setData(null);
            this.mSections.faceBasicInfo?.setData(null);
            this.mSections.scatteringInfo?.setData(null);
        }
    }
    //____________________________________________________________________________
    protected async _fillSections() {
        await super._fillSections();

        this.mSections.faceBasicInfo?.setData(null);
        this.mSections.scatteringInfo?.setData(null);
        this.mSections.simulationSettings?.setData(null);
    }
    //____________________________________________________________________________
}

