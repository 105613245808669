import { eUnitType } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iLightSource, tRaysKind } from "../../../parts/behaviors/LightSourceContext";
import { eSmRaysKind, eSmPlaneWaveType, eSmRaysDensity } from "../../../simulation/SimulationContext";
import { UnitHandler } from "../../../units/UnitsHandler";
import { ViewUtils } from "../../ViewUtils";
import { PartInfoSection } from "../PartInfoSection";
import { newNumberInputElement } from "../_components/NewNumberInputElement";
import { iShapeNIEParams } from "./piLightSourceSection";

export interface iSpatialInfoData {
    lightSourceData: iLightSource
}

export class piSpatialInformationSection extends PartInfoSection<iSpatialInfoData> {
    public static DEFAULTS = {
        half_height: 1,
        half_width: 1,
        radius: 1,
        radius_x: 1,
        radius_y: 1,
        range: { min: 0.0001, max: 100 },
        half_width_height: {
            min: 0.0001, max: 100000
        }
    }

    private mElements: {
        half_height: newNumberInputElement,
        half_width: newNumberInputElement,
        radius: newNumberInputElement,
        radius_x: newNumberInputElement,
        radius_y: newNumberInputElement,

    };
    private mSourceShape: HTMLSelectElement;
    private mParams: { onChange: (pShape?: eSmRaysKind) => void };
    private mDensitySelect: HTMLSelectElement;

    constructor(pContainer: HTMLElement, pParams: { onChange: (pShape?: eSmRaysKind) => void }) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_spatial_info.html'
        });

        this.mParams = pParams;
    }
    //__________________________________________________________________________________________
    /**
     * @deprecated
     * @description use NIE class instead 
     */
    public static getNewNumberInputElement(pData: iShapeNIEParams) {
        let aLongestNum = Math.max(pData.range.min.toString().length,
            pData.step.toString().length);

        let aFix = pData.toFixed != null ? pData.toFixed : Math.min(6, aLongestNum + 1);

        let aElement = new newNumberInputElement(pData.container, {
            startValue: pData.defaultValue,
            isGlobalToFixed: true,
            hasArrows: pData.hasArrows,
            isScientific: pData.isScientific,
            label: pData.label,
            toFixed: aFix,
            worldToUnit: pData.worldToUnit,
            sign: pData.sign,
            unitName: pData.unitName,
            qa_id: pData.qa_id,
            unitElementWidth: pData.unitElementWidth
        });

        aElement.setData({
            factor: pData.factor,
            range: pData.range,
            step: pData.step,
            callback: pData.callback,
            isInt: (null != pData.isInt) ? pData.isInt : false
        });

        aElement.value = pData.defaultValue;
        return aElement;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {

        const aUnitConversion = (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS) ?
            1 : 0.04;

        const aUnitSign = UnitHandler.shortSign;
        const aToFixed = 4;
        this.mElements = {
            half_height: piSpatialInformationSection.getNewNumberInputElement({
                container: this._getPart("half-height"),
                worldToUnit: aUnitConversion,
                label: "Half height",
                toFixed: aToFixed,
                defaultValue: piSpatialInformationSection.DEFAULTS.half_height * aUnitConversion,
                callback: () => this._updateShape(),
                range: {
                    min: piSpatialInformationSection.DEFAULTS.half_width_height.min,
                    max: piSpatialInformationSection.DEFAULTS.half_width_height.max * aUnitConversion
                },
                step: 1 * aUnitConversion,
                unitName: aUnitSign,

            }),
            half_width: piSpatialInformationSection.getNewNumberInputElement({
                container: this._getPart("half-width"),
                worldToUnit: aUnitConversion,
                hasArrows: true,
                label: "Half width",
                defaultValue: piSpatialInformationSection.DEFAULTS.half_width * aUnitConversion,
                callback: () => this._updateShape(),
                range: {
                    min: piSpatialInformationSection.DEFAULTS.half_width_height.min,
                    max: piSpatialInformationSection.DEFAULTS.half_width_height.max * aUnitConversion
                },
                step: 1 * aUnitConversion,
                toFixed: aToFixed,
                unitName: aUnitSign,

            }),
            radius: piSpatialInformationSection.getNewNumberInputElement({
                container: this._getPart("radius"),
                worldToUnit: aUnitConversion,
                hasArrows: true,
                label: "Radius",
                defaultValue: piSpatialInformationSection.DEFAULTS.radius * aUnitConversion,
                callback: () => this._updateShape(),
                range: {
                    min: piSpatialInformationSection.DEFAULTS.range.min,
                    max: piSpatialInformationSection.DEFAULTS.range.max * aUnitConversion
                },
                step: 1 * aUnitConversion,
                toFixed: aToFixed,
                unitName: aUnitSign,

            }),
            radius_x: piSpatialInformationSection.getNewNumberInputElement({
                container: this._getPart("radius_x"),
                worldToUnit: aUnitConversion,
                hasArrows: true,
                label: "Radius x",
                defaultValue: piSpatialInformationSection.DEFAULTS.radius_x * aUnitConversion,
                callback: () => this._updateShape(),
                range: {
                    min: piSpatialInformationSection.DEFAULTS.range.min,
                    max: piSpatialInformationSection.DEFAULTS.range.max * aUnitConversion
                },
                step: 1 * aUnitConversion,
                toFixed: aToFixed,
                unitName: aUnitSign,

            }),
            radius_y: piSpatialInformationSection.getNewNumberInputElement({
                container: this._getPart("radius_y"),
                worldToUnit: aUnitConversion,
                hasArrows: true,
                label: "Radius y",
                toFixed: aToFixed,
                step: 1 * aUnitConversion,
                defaultValue: piSpatialInformationSection.DEFAULTS.radius_x * aUnitConversion,
                callback: () => this._updateShape(),
                range: {
                    min: piSpatialInformationSection.DEFAULTS.range.min,
                    max: piSpatialInformationSection.DEFAULTS.range.max * aUnitConversion
                },
                unitName: aUnitSign,

            })
        }

        this.mDensitySelect = this._getPart("rays-density") as HTMLSelectElement;
        this.mDensitySelect.addEventListener("change", () => this._onDensityChanged());
        this.mSourceShape = this._getPart("source-shape", true) as HTMLSelectElement;
        this.mSourceShape.addEventListener("change", () => this._onChangeSourceShape());
    }
    //__________________________________________________________________________________________
    private _onDensityChanged() {
        this._updateShape();
    }
    //__________________________________________________________________________________________
    private _updateShape() {
        Op3dContext.SCENE_HISTORY.addToHistory();
        this.mParams.onChange();
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _onChangeSourceShape() {
        this._setSourceShapeSectionVisibility();
        this._setInitials();
        this._updateShape();
    }
    //__________________________________________________________________________________________
    private _setInitials() {
        const aUnitConversion = (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS) ?
            1 : 0.04;
        const aDefaults = piSpatialInformationSection.DEFAULTS;
        const aValue = this.mSourceShape.value as eSmPlaneWaveType;
        switch (aValue) {
            case eSmPlaneWaveType.RECTANGULAR:
                this.mElements.half_height.value = aDefaults.half_height * aUnitConversion;
                this.mElements.half_width.value = aDefaults.half_width * aUnitConversion;
                this.mDensitySelect.value = eSmRaysDensity.XY_GRID;
                break;
            case eSmPlaneWaveType.CIRCULAR:
                this.mElements.radius.value = aDefaults.radius * aUnitConversion;
                this.mDensitySelect.value = eSmRaysDensity.CONCENTRIC_CIRCLES;
                break;
            case eSmPlaneWaveType.ARBITRARY:
                throw new Error("unhandeled");
            case eSmPlaneWaveType.ELLIPTICAL:
                this.mElements.radius_x.value = aDefaults.radius_x * aUnitConversion;
                this.mElements.radius_y.value = aDefaults.radius_y * aUnitConversion;
                this.mDensitySelect.value = eSmRaysDensity.CONCENTRIC_CIRCLES;
        }
    }
    //__________________________________________________________________________________________
    private _setSourceShapeSectionVisibility() {
        const aValue = this.mSourceShape.value;
        $(this.mContainer).find('[plane_wave]').each((_index, element) => {
            ViewUtils.setElementVisibilityByDNone(element as HTMLElement,
                aValue != "" && element.getAttribute('plane_wave')?.indexOf(aValue) != -1);
        });
    }
    //__________________________________________________________________________________________
    public fillSection(pData: iSpatialInfoData) {
        this._fillSection(pData);
    }
    //__________________________________________________________________________________________
    public getData() {
        const aScale = UnitHandler.presentedScale;

        return {
            height: this.mElements.half_height.value * 2 / aScale,
            width: this.mElements.half_width.value * 2 / aScale,
            radius: this.mElements.radius.value / aScale,
            radius_x: this.mElements.radius_x.value / aScale,
            radius_y: this.mElements.radius_y.value / aScale,
            shape: this.mSourceShape.value,
            density_pattern: this.mDensitySelect.value
        };
    }
    //__________________________________________________________________________________________
    private _fillShape(pPlaneWave: eSmPlaneWaveType, pGeoData: tRaysKind) {
        let aScale = UnitHandler.presentedScale;

        this.mSourceShape.value = pPlaneWave;
        this.mDensitySelect.value = pGeoData.density_pattern;
        this._setSourceShapeSectionVisibility();

        switch (pPlaneWave) {
            case eSmPlaneWaveType.CIRCULAR:
                this.mElements.radius.value = (aScale * pGeoData['radius']);
                break;
            case eSmPlaneWaveType.RECTANGULAR:
                this.mElements.half_height.value = ((aScale * pGeoData['height']) / 2);
                this.mElements.half_width.value = ((aScale * pGeoData['width']) / 2);
                break;
            case eSmPlaneWaveType.ARBITRARY:
                throw new Error("unhandeled");
            case eSmPlaneWaveType.ELLIPTICAL:
                this.mElements.radius_x.value = ((aScale * pGeoData['radius_x']));
                this.mElements.radius_y.value = ((aScale * pGeoData['radius_y']));
                break;
        }
    }
    //__________________________________________________________________________________________
    protected async _fillSection(pData: iSpatialInfoData): Promise<void> {
        if (null == pData) {
            return;
        }

        let aLightSourceData = pData.lightSourceData;
        this._fillShape(aLightSourceData.shape, aLightSourceData.sourceGeometricalData);
    }
    //__________________________________________________________________________________________
}
