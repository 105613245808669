import { Box3 } from "three";
import { unZip } from "../../../loaders/UnZip";
import { EventBase } from "../../../oc/events/EventBase";
import { EventManager } from "../../../oc/events/EventManager";
import { AnalyticsEventsContext } from "../../_context/AnalyticsEventsContext";
import { ePolarizationSourceType, eStateToAnalysis, eGpuType, eBucketToRetrieve } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { iHash, iPoint2D } from "../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../_utils/DataUtils";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { iAnalysisData } from "../../parts/_parts_assets/ExportToJSONInterfaces";
import { PartsManager } from "../../parts/_parts_assets/PartsManager";
import { ServerContext } from "../../server/ServerContext";
import { eSmRaysKind } from "../../simulation/SimulationContext";
import { SimulationRunner, iAnalysisResponseData } from "../../simulation/SimulationRunner";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { Popup } from "../forms/Popup";
import { MinimizedNavbar } from "../home/MinimizeNavbar";
import { Spinner } from "../home/Spinner";
import { NotificationCenter } from "../home/_notifications/NotificationCenter";
import { AnalysesSynchronizer } from "./AnalysesSynchronizer";
import { AnalysisCache } from "./AnalysisCache";
import { AnalysisConstants } from "./AnalysisConstants";
import { AnalysisContext, iAnalysisItem, iAnalysisReloadData } from "./AnalysisContext";
import { AnalysisDataUtils } from "./AnalysisDataUtils";
import { AnalysisThumbnail } from "./AnalysisThumbnail";
import { CompareAnalysis } from "./CompareAnalysis";
import { LightSourcesListAnalysisPortal } from "./LightSourcesListAnalysisPortal";
import { QuickViewHandler } from "./QuickViewHandler";
import { eExtendedViewType } from "./ResultFactory/anAnalysisResultyFactory";
import { AnalysisViewFactory } from "./_extendedView/AnalysisViewFactory";
import { ExtendedViewNew } from "./_extendedView/ExtendedViewNew";
import { anSurfaceComponent } from "./components/anSurfaceComponent";
import { ePartType } from "../../parts/PartInterfaces";

export class AnalysisPortal extends Op3dComponentBase {

    private static INSTANCE: AnalysisPortal;
    private mLightSourceDD: LightSourcesListAnalysisPortal;
    private mDetectors: Array<anSurfaceComponent> = [];
    private mSurfaces: Array<anSurfaceComponent> = [];

    // detector item
    private mDetectorSingleItem: HTMLElement;
    private mDetectorsContainer: HTMLElement;
    //-------------------------------------------

    //detectig surfaces
    private mSurfacesContainer: HTMLElement;
    private mAnalysisSpinner: HTMLElement;
    private mIsTerminated: boolean;

    private mRunAnalysisBtn: HTMLElement;
    private mTerminateBtn: HTMLElement;
    public mAbortController: AbortController;
    private mChangedFromScene = true;
    public mChangedFromPortal = true
    private mRunAnalysisBtnBan: HTMLElement;

    private mStartOnFastMachine: HTMLElement;
    private mRunningPolarizedAnalyses: iHash = {};
    private mLastIndexOfReadyAnalysis: number = 0;

    private mLoadOldAnalysis: boolean = false;

    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,

            skinPath: './skins/forms/analysis/analysis_portal.html',
            minimizeData: {
                isMinimized: false,
                title: "Analysis Portal",
                //  showCallback: () => this.show(),
                showCallback: () => this.onMaximize(),
                htmlItem: null,
                iconClasses: "icon-analysis"
            }
        });
    }
    //__________________________________________________________________________________________
    public get isTerminated() {
        return this.mIsTerminated;
    }
    //__________________________________________________________________________________________
    public set isTerminated(value: boolean) {
        this.mIsTerminated = value;
    }
    //__________________________________________________________________________________________
    private onMaximize() {
        this.show();
        this._updateComponents(true);
    }
    //__________________________________________________________________________________________
    public closeOptionsDD(pThumbnail: AnalysisThumbnail) {
        this.mDetectors.forEach(item => item.closeOptionsDD(pThumbnail));
        this.mSurfaces.forEach(item => item.closeOptionsDD(pThumbnail));
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add("analysis-modal");
            aDiv.style.width = "800px";
            aDiv.style.minWidth = "715px";
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.id = "analysisModal";
            aDiv.style.resize = "both";
            document.getElementById('forms').appendChild(aDiv);
            this.INSTANCE = new AnalysisPortal(aDiv);
        }
        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    public onClickScreen(pAnalysisItem: iAnalysisItem) {

        let aAllWls = ExtendedViewNew.getAllWavelengthsOfItem(pAnalysisItem);

        let aUseOneWavelength = AnalysisConstants.ANALYSES_OPTIONS.find(
            item => item.name === pAnalysisItem.name).useOneWavelength === true;

        let aWavelengths = [];
        if (aUseOneWavelength) {
            aWavelengths = [aAllWls[0]];
        }

        const aCacheData = AnalysisCache.getSpecificCombinedData({
            id: pAnalysisItem.id,
            polarization: ePolarizationSourceType.X,
            polarizationKind: ePolarizationSourceType.X,
            name: pAnalysisItem.name,
            wavelengths: aWavelengths
        }, aUseOneWavelength);

        const aCopy = AnalysisCache.getSpecificCombinedData({
            id: pAnalysisItem.id,
            polarization: ePolarizationSourceType.X,
            polarizationKind: ePolarizationSourceType.X,
            name: pAnalysisItem.name,
            wavelengths: aWavelengths
        }, aUseOneWavelength);

        let aPixel: iPoint2D = {
            x: Math.round(aCacheData.common_data.resolution.y / 2),
            y: Math.round(aCacheData.common_data.resolution.x / 2)
        }

        AnalysisViewFactory.createAnalysis({
            viewType: eExtendedViewType.TWO_D,
            pixel: aPixel,
            cacheData: aCacheData,
            originalCacheData: aCopy,
            analysisItem: DataUtils.getObjectCopy(pAnalysisItem),
            canvas: null,
            fromMinimize: false,
            isLogView: false,
            polarizationKind: ePolarizationSourceType.X,

        }).open();
    }
    //__________________________________________________________________________________________
    public show() {
        $(this.mContainer).modal().show();
        this.mIsVisible = true;
        this.mContainer.addEventListener("mouseup", this._onMouseUpWindow);
    }
    //__________________________________________________________________________________________
    public hide() {
        $(this.mContainer).modal().hide()
        this.mIsVisible = false;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mLightSourceDD = new LightSourcesListAnalysisPortal(this._getPart("light-source-component", true));
        this._getPart("compare-selected", true).addEventListener("click", () => this._compareSelected());

        this.mRunAnalysisBtn = this._getPart("run-analysis");
        this.mRunAnalysisBtnBan = this._getPart("run-analysis-ban");
        this.mRunAnalysisBtn.addEventListener("click", () => this._onRunAnalysis());



        this.mTerminateBtn = this._getPart("terminate-btn")
        ViewUtils.setElementDisabled(this.mTerminateBtn, true)
        this.mTerminateBtn.addEventListener("click", () => this._onTerminateAnalyses());
        this.mDetectorSingleItem = this._getPart("single-surface-analysis-item", true);
        this.mDetectorSingleItem.parentElement.removeChild(this.mDetectorSingleItem);
        this.mDetectorsContainer = this._getPart("detectors-container");
        this.mSurfacesContainer = this._getPart("surfaces-container");
        $(this.mContainer).find('[data-toggle="popover"]').popover({
            trigger: 'focus',
            html: true,
            content: `<p>You can set as detector any optical surface. To do so, right click on any surface on the left sidebar and select "Add surface as a detecting surface".</p>
            <p>After running fast analysis, you can add the resulting image to the quick view bar. To do so, right click on the image and select "Add to quick view".</p>`
        });

        this.mStartOnFastMachine = this._getPart('fast-machine-check');
        this._onUpdatePermissions();
    }

    //__________________________________________________________________________________________
    private _onTerminateAnalyses() {
        try {
            this.mAbortController.abort();
            SimulationRunner.instance.mAbortController.abort();
        } catch (e) {
            Op3dContext.USER_VO.isEmployeeUser && console.log("terminate", e);
        }
        this._setSpinner(false);
        this.mIsTerminated = true;
        this.mSurfaces.forEach(item => item.terminateAnalyses());
        this.mDetectors.forEach(item => item.terminateAnalyses());

        this.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL);
        ViewUtils.setElementDisabled(this.mTerminateBtn, true)
    }
    //__________________________________________________________________________________________
    public isUpdateEnabled() {
        return this.mChangedFromPortal || this.mChangedFromScene;
    }
    //__________________________________________________________________________________________
    public async enableRunAnalysis(pToEnable: eStateToAnalysis, pIsFrom?: eStateToAnalysis) {
        await this._prepareForm();

        this.mChangedFromPortal = this.mChangedFromPortal || (pIsFrom === eStateToAnalysis.FROM_PORTAL);
        this.mChangedFromScene = this.mChangedFromScene || (pIsFrom === eStateToAnalysis.FROM_SCENE);

        this.mRunAnalysisBtn.parentElement.setAttribute('data-original-title', '')

        switch (pToEnable) {
            case eStateToAnalysis.ENABLE_ANALYSIS:
                ViewUtils.setElementDisabled(this.mRunAnalysisBtn, false);
                ViewUtils.setClassForElement(this.mRunAnalysisBtnBan, Strings.D_NONE, true)
                ViewUtils.setClassForElement(this.mRunAnalysisBtn, Strings.D_NONE, false);
                this.mRunAnalysisBtn.children[0].className = 'fa fa-play';
                break;
            case eStateToAnalysis.DISABLE_ANALYSIS:
                ViewUtils.setElementDisabled(this.mRunAnalysisBtnBan, true);
                ViewUtils.setClassForElement(this.mRunAnalysisBtnBan, Strings.D_NONE, false);
                ViewUtils.setClassForElement(this.mRunAnalysisBtn, Strings.D_NONE, true);
                break;
        }
    }
    //______________________________________________________________________________________________
    public updateSpecificLaser(pLaserID: string, pType: eSmRaysKind) {
        for (let i = 0; i < this.mDetectors.length; i++) {
            this.mDetectors[i].updateSpecificLaser(pLaserID, pType);
        }

        for (let i = 0; i < this.mSurfaces.length; i++) {
            this.mDetectors[i].updateSpecificLaser(pLaserID, pType);
        }
    }
    //______________________________________________________________________________________________
    private _compareSelected() {
        /**
         * @TODO
         */
        let aCompareAnalysisId = new Array<string>();
        this.mSurfaces.forEach(surface => aCompareAnalysisId.push(...surface.getSelected4Compare()));
        this.mDetectors.forEach(detector => aCompareAnalysisId.push(...detector.getSelected4Compare()));

        if (aCompareAnalysisId.length == 0) {
            NotificationCenter.instance.pushNotification({
                message: MessagesHandler.ANALYSIS_NO_RESULT_TO_COMPARE,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL,
            });
            return;
        }

        CompareAnalysis.instance.open({ analysis_ids: aCompareAnalysisId, fromMinimize: false })
    }
    //__________________________________________________________________________________________
    private _setSpinner(pIsOn: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mAnalysisSpinner, pIsOn);
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete() {
        this.mAnalysisSpinner = Op3dUtils.getElementIn(document.body, "icon-analysis-spinner", true);

        $(this.mContainer).resize(() => {
            $(this.mContainer).draggable({
                handle: '.modal-header, .modal-body',
                containment: [-(0.7 * this.mContainer.clientWidth), 0,
                (window.innerWidth - (this.mContainer.clientWidth * 0.1)),
                window.innerHeight - (this.mContainer.clientHeight * 0.2)]
            });
        });

        this._getPart('light-source-title').addEventListener('click', (e: Event) => this._showLightSources(e, false))
        this.mContainer.addEventListener('click', (e) => this._showLightSources(e, true))

        $(window).resize(() => {
            $(this.mContainer).draggable({
                handle: '.modal-header',
                containment: [-this.mContainer.clientWidth + this.mContainer.clientWidth * 0.1,
                    0, window.innerWidth - this.mContainer.clientWidth * 0.1,
                window.innerHeight - this.mContainer.clientHeight * 0.2]
            });
        });

        $(this.mContainer).draggable({
            handle: '.modal-header',
            containment: [-this.mContainer.clientWidth + this.mContainer.clientWidth * 0.2, 0,
            window.innerWidth - this.mContainer.clientWidth / 2,
            window.innerHeight - this.mContainer.clientHeight / 2]
        });

        EventManager.addEventListener(EventsContext.ON_CLOSE,
            () => this.hide(), this);

        EventManager.addEventListener(EventsContext.ON_NEW,
            () => this._onNew(), this);

        EventManager.addEventListener(EventsContext.UNDO,
            () => this._onUndoRedo(), this);

        EventManager.addEventListener(EventsContext.REDO,
            () => this._onUndoRedo(), this);

        EventManager.addEventListener(EventsContext.ON_CLEAR_ALL_PARTS,
            () => this._onNew(), this);

        EventManager.addEventListener(EventsContext.PART_DELETED,
            () => this._updateComponents(false), this);

        EventManager.addEventListener(EventsContext.PART_ADDED,
            () => this._updateComponents(true), this);

        EventManager.addEventListener<Part>(EventsContext.PART_SELECTED,
            (pData: EventBase<Part>) =>
                this._onSelectItem(pData.data), this);

        EventManager.addEventListener(EventsContext.PERMISSION_UPDATE,
            () => this._onUpdatePermissions(), this);

        ViewUtils.makeUnselectable(this.mContainer);
        this.mIsReady = true;

        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip({ trigger: "hover" })
    }
    //__________________________________________________________________________________________
    private _onUpdatePermissions() {

        this.mStartOnFastMachine.addEventListener('click', () => {
            if (Op3dContext.USER_VO.isBasicLicense) {
                return;
            }
            let aHasActive = ViewUtils.toggleClass(this.mStartOnFastMachine, "active");
            Op3dContext.GPU_MACHINE = aHasActive ? eGpuType.FAST : eGpuType.DEFAULT;

            if (aHasActive) {
                Popup.instance.open({ text: "Notice, GPU consumption rate will be twice than in the normal mode" });
            }
        });

        ViewUtils.setElementVisibilityByDNone(this.mStartOnFastMachine,
            Op3dContext.USER_VO.isBasicLicense == false);

    }
    //__________________________________________________________________________________________
    private _onUndoRedo() {
        this.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
        this._updateComponents(true);
    }
    //__________________________________________________________________________________________
    private _showLightSources(e, isScreen: boolean) {
        let aLsDD = document.getElementById('ls-dd')
        if (aLsDD.classList.contains('d-none') && isScreen) {
            return
        }

        if (e.target.classList.contains('all-ls')) {
            return
        }

        e.stopImmediatePropagation()
        ViewUtils.toggleClass(document.getElementById('ls-dd'), Strings.D_NONE);
    }
    //__________________________________________________________________________________________
    private _openFirstAvailable() {

        let aSurfaces = [...this.mSurfaces, ...this.mDetectors];
        let aAllActiveWithActiveChildren = aSurfaces.filter(el => el.isActive());

        if (0 == aAllActiveWithActiveChildren.length) {
            aAllActiveWithActiveChildren = this.mSurfaces.filter(el => el.isActive());
        }

        if (aAllActiveWithActiveChildren.some(el => el.isShown())) {
            return;
        }

        aAllActiveWithActiveChildren.find(element => (false == element.isShown())).show();
    }
    //__________________________________________________________________________________________
    private async _loadAnalysisResults() {

        if (this.mLoadOldAnalysis === true) {
            return;
        }

        this.mLoadOldAnalysis = true;

        // the analysis results dont match the scene/ analysis portal preferences
        if (Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis === true) {
            return;
        }

        const aAnalysisList = PartsManager.analysisList;
        const aWls = Op3dContext.PARTS_MANAGER.getAllWavelengthsInSystem();
        const aSetupId = Op3dContext.SETUPS_MANAGER.currSetupID;

        aAnalysisList.map(item => this._loadOne(item, aSetupId, aWls));

        const aSurfaces = [...this.mDetectors, ...this.mSurfaces];
        aSurfaces[0] && aSurfaces[0].show();
    }
    //__________________________________________________________________________________________
    private async _loadOne(pItem: iAnalysisReloadData, pSetupId: string, pWls: Array<number>) {
        const aAnalysisExists = await ServerContext.SERVER.isExistsAnalysisResult({
            setup_id: pSetupId,
            analysis_id: pItem.analysis.id
        });

        if (aAnalysisExists.success === true && aAnalysisExists.data === true) {
            const aOwnerId = Op3dContext.SETUPS_MANAGER.ownerId;
            const aFileName = `${pItem.analysis.id}_analysis_`;
            const aSuffix = `.bin.zip`;

            const aSurfaces = [...this.mDetectors, ...this.mSurfaces];
            aSurfaces.map(surface => surface.updateAnalysisResults([pItem.analysis.id]));
            aSurfaces.map(surface => surface.changedAnalysisParameters(pItem.analysis.id, false));

            const aUserPrefix = ServerContext.user_prefix_setups;
            const aPrefixURL = `${aUserPrefix}${aOwnerId}/${pSetupId}/analyses`;
            const aVersion = Op3dUtils.idGenerator();

            const aAnalysisDDItem = AnalysisConstants.ANALYSES_OPTIONS.find(item =>
                item.name === pItem.analysis.name);

            if (true === aAnalysisDDItem.isPolarized) {
                const aXPolarization = `${aPrefixURL}/${aFileName}0${aSuffix}?V=${aVersion}`;
                const aNonPolarization = `${aPrefixURL}/${aFileName}1${aSuffix}?V=${aVersion}`;
                const aYPolarization = `${aPrefixURL}/${aFileName}2${aSuffix}?V=${aVersion}`;
                const aZPolarization = `${aPrefixURL}/${aFileName}3${aSuffix}?V=${aVersion}`;

                this._loadOneAnalysisResult(aXPolarization, pItem, pItem.analysis.id, pWls);
                this._loadOneAnalysisResult(aNonPolarization, pItem, pItem.analysis.id, pWls);
                this._loadOneAnalysisResult(aYPolarization, pItem, pItem.analysis.id, pWls);
                this._loadOneAnalysisResult(aZPolarization, pItem, pItem.analysis.id, pWls);
            } else {
                const aURL = `${aPrefixURL}/${aFileName}0${aSuffix}?V=${aVersion}`;
                this._loadOneAnalysisResult(aURL, pItem, pItem.analysis.id, pWls);
            }
        }
    }
    //__________________________________________________________________________________________
    private async _loadOneAnalysisResult(pURL: string, pItem: iAnalysisReloadData, pId: string, pWls: Array<number>) {
        const aContent = await ServerContext.SERVER.httpGetFile(pURL);
        const aUnziped = await unZip(aContent);

        let aAnalysisResponseData: iAnalysisResponseData = {
            analysis_id: pId,
            analysis_name: pItem.analysis.name,
            analysisType: pItem.analysis.type,
            faceId: pItem.faceId,
            faceSize: pItem.size,
            content: aUnziped
        };
        let aCacheData = AnalysisDataUtils.handleAnalysis(aAnalysisResponseData, pWls);
        AnalysisCache.addAnalysisData(aCacheData, pId);
    }
    //__________________________________________________________________________________________
    protected async _onShown(): Promise<void> {
        await Op3dContext.wait(() => ((Op3dContext.PARTS_MANAGER != null)))
        this._updateComponents(true);
    }
    //__________________________________________________________________________________________
    public removeSurfaceById(pFaceId: string) {
        this._removeAnalysisItems(this.mDetectors, pFaceId)
        this._removeAnalysisItems(this.mSurfaces, pFaceId)
    }
    //__________________________________________________________________________________________
    private _removeAnalysisItems(pArr: Array<anSurfaceComponent>, pFaceID: string) {
        let currentIndex = pArr.findIndex(item => item.faceId == pFaceID)
        if (currentIndex != -1) {
            pArr[currentIndex].distract()
            pArr.splice(currentIndex, 1)
        }
    }
    //__________________________________________________________________________________________
    private _onRunAnalysis() {
        try {
            if (Op3dUtils.checkBlackBoxCollision() === true) {
                NotificationCenter.instance.pushNotification({
                    message: "Please eliminate the blackbox collision conflict",
                    params: NotificationCenter.NOTIFICATIONS_TYPES.ALARM
                });
                return
            }

            const aInvalidAnalysis = [];
            this.mSurfaces.forEach(item => aInvalidAnalysis.push(...item.getAllInvalidAnalysis));
            this.mDetectors.forEach(item => aInvalidAnalysis.push(...item.getAllInvalidAnalysis));
            QuickViewHandler.isAnalysisFromQuickView = true;

            if (aInvalidAnalysis.length > 0) {
                Popup.instance.open({
                    text: MessagesHandler.WRONG_ANALYSIS_RAYS_COUNT
                });
                return;
            }

            const aAnalysisToRun = new Array<AnalysisThumbnail>();

            const aAllAnalyses = [...this.mSurfaces, ...this.mDetectors];
            aAllAnalyses.forEach(item => aAnalysisToRun.push(...(item.analysisToRun(this.mChangedFromScene))));

            if (Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis) {
                aAnalysisToRun.forEach(item => item.changeCurrentAnalysisVersion())
                Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis = false;
            }

            if (aAnalysisToRun.length == 0) {
                NotificationCenter.instance.pushNotification({
                    message: MessagesHandler.ANALYSIS_NO_RESULT_TO_RUN,
                    params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL
                });
                return;
            }
            let aIsInvalidRaysQuantity = false;
            aAnalysisToRun.forEach(item => {
                for (let key in item.raysCount) {
                    const aPart = Op3dContext.PARTS_MANAGER.getPartByInternalId(key);
                    let aLaserBehavior = aPart.getBehavior("laserBehavior");
                    const aLaseBehaviorRaysCount = Number(aLaserBehavior.laserData.lightSource.sourceGeometricalData.count_analysis)
                    item.checkRaysCount({ rays_count: aLaseBehaviorRaysCount, internal_id: key })
                    if (item.raysCount[`${key}`].classList.contains(Strings.RED_BORDER)) {
                        aIsInvalidRaysQuantity = true;
                    }
                }

                item.enable = false;
            })
            if (aIsInvalidRaysQuantity) {
                return;
            }

            ViewUtils.setElementDisabled(this.mRunAnalysisBtn, true);
            this.mRunAnalysisBtn.parentElement.setAttribute('data-original-title', '')
            ViewUtils.setElementDisabled(this.mTerminateBtn, false);
            this.mRunAnalysisBtn.children[0].className = Strings.BTN_SPINNER_ICON;

            AnalyticsEventsContext.triggerEvent(AnalyticsEventsContext.RUN_ANALYSIS, "simulation");
            this._runVisualization();
            this._openFirstAvailable();
            this.mIsTerminated = false;

            this._setSpinner(true);
            this.mAbortController = new AbortController()
            const aIds = [];
            const aBucketIds: iHash<eBucketToRetrieve> = {}
            for (let i = 0; i < aAnalysisToRun.length; i++) {
                const aItem = aAnalysisToRun[i]
                aIds.push(aItem.id);
                if (aItem.analysisVersionOld == '') {
                    aItem.changeCurrentAnalysisVersion();
                    aItem.changeOldAnalysisVersion(aItem.analysisVersionCurrent);

                    aBucketIds[aItem.id] = eBucketToRetrieve.GPU;
                } else if (aItem.analysisVersionCurrent !== aItem.analysisVersionOld) {
                    aItem.changeOldAnalysisVersion(aItem.analysisVersionCurrent);
                    aBucketIds[aAnalysisToRun[i].id] = eBucketToRetrieve.GPU;
                } else {
                    aBucketIds[aAnalysisToRun[i].id] = eBucketToRetrieve.S3;
                }
            }

            aAnalysisToRun.forEach(item => {


                if (AnalysisConstants.ANALYSES_OPTIONS.find(data =>
                    data.name === item.name && data.type === item.analysisType).isPolarized === true) {
                    let aId = ExtendedViewNew.returnClearPolarizationAnalysisId(item.id);
                    this.mRunningPolarizedAnalyses[`${aId}`] = 0;
                }
                return item.changedAnalysisParameters = false;
            });

            SimulationRunner.instance.runAnalysesByIds({
                analysisToRun: aIds,
                analysisBuckets: aBucketIds,
                onFinish: (pIsOk: boolean, e: any, pErrMsg: string, pID?: string) =>
                    this._onAnalysisFinished(pIsOk, aAnalysisToRun, e, pErrMsg, pID),
                signal: this.mAbortController.signal
            });
            if (this.mAbortController.signal.aborted) return;

            this.mSurfaces.forEach(surface => surface.updateAnalysisResults(aIds));
            this.mDetectors.forEach(detector => detector.updateAnalysisResults(aIds));

            aAnalysisToRun.forEach(item => {
                const aOldV = item.analysisVersionOld
                const aCurrV = item.analysisVersionCurrent
                if (aOldV != aCurrV || aOldV == '') {
                    item.changeCurrentAnalysisVersion();
                    item.changeOldAnalysisVersion(item.analysisVersionCurrent)
                }
            });
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis = false;
        } catch (e) {
            this.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL)
        } finally {
            Spinner.instance.hide();
        }
    }
    //__________________________________________________________________________________________
    public updateLabels() {
        this.mDetectors.forEach((surface) => surface.updateSurfaceTitle());
        this.mSurfaces.forEach((surface) => surface.updateSurfaceTitle());
    }
    //__________________________________________________________________________________________
    public updateByIds(pAnalyses: string[]) {
        this.mSurfaces.forEach(item => item.updateAnalyses(pAnalyses));
        this.mDetectors.forEach(item => item.updateAnalyses(pAnalyses));
    }
    //__________________________________________________________________________________________
    private _onAnalysisFinished(pIsOk: boolean, pTotalAnalyses: Array<AnalysisThumbnail>, pErr: any,

        pErrMessage: string, pID?: string) {

        this._setSpinner(false);
        if (false == pIsOk) {
            let aErrorMessage = pErrMessage != null ? pErrMessage : MessagesHandler.ANALYSIS_FINISHED_ERROR;
            if (pErr != null && pErr.error != null && pErr.error.message != null) {
                if (MessagesHandler[pErr.error.message]) {
                    aErrorMessage = MessagesHandler[pErr.error.message];
                }
            }

            Popup.instance.open({ text: aErrorMessage });
            Op3dContext.USER_VO.isEmployeeUser && console.log(pErr, "error");

            if (null != pID) {
                let aAnalysis = pTotalAnalyses.find((item) => item.id == pID);
                if (null != aAnalysis) {
                    aAnalysis.enable = true;
                    aAnalysis.terminateAnalysis();
                }
            } else {
                this._onTerminateAnalyses();
                return;
            }
        }
        /**
         * 3 - is a last index of polarizaed analyses for 1 detector (none, x, y, z);
        */
        let aPolarizedAnalysesDone = 3
        let aReadyResults = pTotalAnalyses.filter(item => {
            if ((null == item.result) || (true == item.result.isReady)) {
                let aId = ExtendedViewNew.returnClearPolarizationAnalysisId(item.result.id);
                if (!(this.mRunningPolarizedAnalyses[`${aId}`] !== undefined &&
                    this.mRunningPolarizedAnalyses[`${aId}`] < aPolarizedAnalysesDone)) {
                    return true;
                }
            }
            return false;
        }).length;
        if (this.mLastIndexOfReadyAnalysis !== aReadyResults) {
            NotificationCenter.instance.pushNotification({
                message: "Analyses completed " + aReadyResults + "/" + pTotalAnalyses.length,
                params: NotificationCenter.NOTIFICATIONS_TYPES.GENERAL
            });
            this.mLastIndexOfReadyAnalysis++;
        }

        this._onAllAnalysisFinished(pTotalAnalyses.length, aReadyResults)
    }
    //__________________________________________________________________________________________
    private async _runVisualization() {
        const aShowSpinner = Spinner.instance.spinnerCallBack;

        if (this.mChangedFromScene || SimulationRunner.instance.isRaysDrawn() == false) {

            aShowSpinner('Uploading...', () => SimulationRunner.instance.onStopVisualization())
            await SimulationRunner.instance.startVisualizationSimulation(true, aShowSpinner);
            Spinner.instance.hide();
            this.mChangedFromScene = false;
        }
    }
    //__________________________________________________________________________________________
    private _onAllAnalysisFinished(pTotalAnalysis: number, pReadyAnalysis: number) {
        if (pTotalAnalysis <= pReadyAnalysis) {
            if (this.mIsVisible == false) this.open();

            this.enableRunAnalysis(eStateToAnalysis.DISABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL);
            ViewUtils.setElementDisabled(this.mTerminateBtn, true)
            Op3dContext.SCENE_HISTORY.saveScene(false, true);
            this.mRunningPolarizedAnalyses = {};
            this.mLastIndexOfReadyAnalysis = 0;
        }
    }
    //__________________________________________________________________________________________
    protected _onUpdate(_pData?: any): void {
        //this._onOpen();
        this._updateComponents(true);
    }
    //__________________________________________________________________________________________
    private _addDetector(pParams: {
        part: Part;
        onChange: (pInternalId: string, pData: iAnalysisData) => void;
        parentContainer: HTMLElement;
        arr: Array<anSurfaceComponent>;
    }) {

        let aFaces = pParams.part.getFaces().map(face => face.internal_id);
        let aNewKeys = pParams.part.analysisOptions.map(item => item.faceId);
        let aCurrKeys = pParams.arr.map(item => item.faceId);
        let aDeleted = aNewKeys.filter(item => !aCurrKeys.includes(item));

        for (let i = 0; i < aDeleted.length; i++) {
            let aIdx = pParams.arr.findIndex(item => item.faceId == aDeleted[i]);
            if (aIdx != -1) {
                pParams.arr[aIdx].distract();
                pParams.arr.splice(aIdx, 1);
            }
        }

        for (let analysis of pParams.part.analysisOptions) {

            if (aFaces.indexOf(analysis.faceId) == -1) {
                Op3dContext.USER_VO.isEmployeeUser && console.log(`AnalysisPortal: problematic surface ${analysis.faceId}`);
                continue;
            }

            let aIdx = pParams.arr.findIndex(item => item.faceId == analysis.faceId);
            if (aIdx != -1) {
                continue;
            }

            let aClone = this.mDetectorSingleItem.cloneNode(true) as HTMLElement;
            pParams.parentContainer.appendChild(aClone);
            let aName = pParams.part.getIndexedLabel(true);

            let aDetectorItem = new anSurfaceComponent(aClone, {
                part: pParams.part,
                analysisData: analysis,
                name: aName,
                internalId: pParams.part.internalID,
                onChange: pParams.onChange,
                faceId: analysis.faceId
            });

            pParams.arr.push(aDetectorItem);
        }
    }
    //__________________________________________________________________________________________
    private _fillItems() {

        // if there is a new information then we want to add it 

        const aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            const aPart = aParts[i];

            let aLaserBehavior = aPart.getBehavior("laserBehavior");
            if (aLaserBehavior != null) {
                if (this.mLightSourceDD.isExists(aPart.internalID)) {
                    continue;
                }

                this.mLightSourceDD.addLightSource(aLaserBehavior, aPart);
                continue;
            }

            if (aPart.analysisOptions != null && aPart.analysisOptions.length > 0) {

                let aOpticsHolder = aPart.getSubpartByName(Strings.OPTICS_HOLDER);
                let aIsDetector = ((null != aOpticsHolder) &&
                    (true == aOpticsHolder.object3D.userData.isDetector));

                for (let j = 0; j < aPart.analysisOptions.length; j++) {


                    if (aIsDetector) {
                        this._addDetector({
                            part: aPart,
                            onChange: (pInternalId: string, pAnalysisData: iAnalysisData) =>
                                this._onDetectorDataChanged(pInternalId, pAnalysisData),
                            parentContainer: this.mDetectorsContainer,
                            arr: this.mDetectors
                        });
                    } else {
                        this._addDetector({
                            part: aPart,
                            onChange: (pInternalId: string, pAnalysisData: iAnalysisData) =>
                                this._onDetectorDataChanged(pInternalId, pAnalysisData),
                            parentContainer: this.mSurfacesContainer,
                            arr: this.mSurfaces
                        });
                    }
                }
            }
        }
    }
    //__________________________________________________________________________________________
    private _updateRunAnalysisBtnState() {

        let aAnalysisCount = 0;
        this.mDetectors.forEach(item => aAnalysisCount += item.totalItemsCount())
        this.mSurfaces.forEach(item => aAnalysisCount += item.totalItemsCount());

        let state = aAnalysisCount == 0 || this.mLightSourceDD.internalIds.length == 0 ? true : false
        ViewUtils.setElementDisabled(this.mRunAnalysisBtn, state);
        if (state === true) {
            this.mRunAnalysisBtn.parentElement.setAttribute('data-original-title',
                'Add a detector to your design in order to run analysis')
        }
        if (!this.mChangedFromPortal && !this.mChangedFromScene) {
            this.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_PORTAL);
        }
    }
    //__________________________________________________________________________________________
    protected _onNew() {
        this._clearForm();
        AnalysisContext.DETECTOR_ID = 0;
        this.close();
        this.mChangedFromPortal = true;
        this.mChangedFromScene = true;
        this.mLoadOldAnalysis = false;
        AnalysisCache.clear();
    }
    //__________________________________________________________________________________________
    private _clearForm() {
        // clear light sources
        this.mLightSourceDD.reset();

        this.mDetectors.forEach(item => item.distract())
        this.mSurfaces.forEach(item => item.distract())
        this.mDetectors = new Array<anSurfaceComponent>();
        this.mSurfaces = new Array<anSurfaceComponent>();
        ViewUtils.clearElementsChildren(this.mDetectorsContainer);
        ViewUtils.clearElementsChildren(this.mSurfacesContainer);
    }
    //__________________________________________________________________________________________
    private _onDetectorDataChanged(pInternalId: string, pAnalysisData: iAnalysisData) {
        const aPart = Op3dContext.PARTS_MANAGER.getPartByInternalId(pInternalId);
        aPart.addAnalysisOption(pAnalysisData);
        this._updateRunAnalysisBtnState();
        Op3dContext.SCENE_HISTORY.saveScene(true);

        if (AnalysesSynchronizer.instance.checkIfAllLocked()) {
            return;
        }
    }
    //__________________________________________________________________________________________
    private _updateSurfaces(pSurfaces: Array<anSurfaceComponent>, pPartIds: Array<string>) {
        let aSurfacesIds = pSurfaces.map(detector => detector.internalID);
        for (let i = 0; i < aSurfacesIds.length; i++) {
            if (pPartIds.indexOf(aSurfacesIds[i]) == -1) {
                // removed

                let aIdx = pSurfaces.findIndex(item => item.internalID == aSurfacesIds[i]);
                if (aIdx != -1) {
                    pSurfaces[aIdx].distract();
                    pSurfaces.splice(aIdx, 1);
                }
            }
        }

        pSurfaces.forEach(surface => {
            surface.updateRaysComponent();
        });
    }
    //__________________________________________________________________________________________
    private _clearOutDatedParts() {
        this.mLightSourceDD.update(1);

        let aPartIds = Op3dContext.PARTS_MANAGER.parts.map(part => part.internalID);
        this._updateSurfaces(this.mDetectors, aPartIds);
        this._updateSurfaces(this.mSurfaces, aPartIds);
    }
    //__________________________________________________________________________________________
    private _updateComponents(pFillItems: boolean) {
        if (Op3dContext.PARTS_MANAGER != null) {
            this._clearOutDatedParts();
        }
        this._updateRunAnalysisBtnState();
        if (pFillItems == false) {
            return;
        }

        this._fillItems();
        this.mDetectors.forEach(item => item.updateAllNeedsUpdate());
        this.mSurfaces.forEach(item => item.updateAllNeedsUpdate());
    }
    //__________________________________________________________________________________________
    protected _onOpen(_pData?: any): void {
        this._updateComponents(true);
        this._loadAnalysisResults();
        MinimizedNavbar.instance.removeItem(this.mParams.minimizeData);
    }
    //__________________________________________________________________________________________
    private _onSelectItem(pPart: Part) {
        if (this.mIsVisible == false) {
            return;
        }
        let aComp = this.mDetectors.find((surfaceComp) => (surfaceComp.params.part == pPart));
        if (null == aComp) {
            aComp = this.mSurfaces.find((surfaceComp) => (surfaceComp.params.part == pPart));
            if (null == aComp) {
                return;
            }
        }

        aComp.show();
    }
    //__________________________________________________________________________________________
    private _updateMainIcon() {
        let aHasResults = this.mDetectors.findIndex(item => item.hasResults()) != -1 ||
            this.mSurfaces.findIndex(item => item.hasResults()) != -1;
        ViewUtils.setElementActive(document.getElementById("analysis-portal-btn"), aHasResults);
    }
    //__________________________________________________________________________________________
    protected _onClose(_pData?: any): void {
        this._updateMainIcon();
        this.mContainer.removeEventListener("mouseup", this._onMouseUpWindow);
    }
    //__________________________________________________________________________________________
    private _onMouseUpWindow(_e: Event) {

        let aItem = document.querySelectorAll(".analysis-dd-item.show")[0];
        if (aItem == null) {
            return;
        }
        ViewUtils.toggleClass(aItem, Strings.SHOW);
    }
    //__________________________________________________________________________________________
    public get runningPolarizedAnalyses(): iHash {
        return this.mRunningPolarizedAnalyses;
    }
    //__________________________________________________________________________________________
    public increasePolarizedAnalysesByOne(pId: string) {
        let aId = ExtendedViewNew.returnClearPolarizationAnalysisId(pId);
        this.mRunningPolarizedAnalyses[`${aId}`] += 1;
    }
    //__________________________________________________________________________________________
}