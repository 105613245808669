import { EventManager } from "../../../../../oc/events/EventManager";
import { EventsContext } from "../../../../_context/EventsContext";
import { MessagesHandler } from "../../../../_context/MessagesHandler";
import { OpticsContext } from "../../../../_context/OpticsContext";
import { iOP3DHTMLInputElement } from "../../../../_context/_interfaces/Interfaces";
import { iOpticsVO } from "../../../../data/VO/OpticsVOInterfaces";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { Popup } from "../../Popup";
import { iApertureReturnData } from "../new/UploadOpticsContext";
import { uoSection } from "../uoSection";
import { UploadApertureImage } from "./UploadApertureImage";


export class uoUserApertureInfo extends uoSection<iOpticsVO, iApertureReturnData> {

    private static SKIN_PATH = './skins/forms/optics/uo_aperture_info.html'
    private static MAX_HALF_SIZE_MM = 1000;
    private mTransparencyImage: UploadApertureImage;
    private mPhaseImage: UploadApertureImage;
    private mMajorDimensionHalfSize: iOP3DHTMLInputElement;

    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoUserApertureInfo.SKIN_PATH,
            title: 'Aperture Images',
            isNewSkin: false,
            isPremiumSection: true
        });
    }
    //_________________________________________________________________________________
    protected async _onCreationComplete(): Promise<void> {
        super._onCreationComplete();

        let aMajorDimensionUnit = this._getPart("major-dimension-unit");
        aMajorDimensionUnit.innerText = UnitHandler.shortSign;

        this.mMajorDimensionHalfSize = this._getPart("major-dimension", true) as iOP3DHTMLInputElement;
        this.mMajorDimensionHalfSize.addEventListener("change", () => this._onDataChanged());

        let aFirst = this._getPart("single-upload-image");
        let aParent = aFirst.parentElement;
        let aSecond = aFirst.cloneNode(true) as HTMLElement;
        aParent.appendChild(aSecond);

        this.mTransparencyImage = new UploadApertureImage({
            upload_btn_qa_id: "transarency_upload_img",
            container: aFirst,
            title: "Transparency image",
            defaultValue: 1,
            onChange: () => this._onDataChanged(),
            defaultBGColor: "#ffffff",

        });

        this.mPhaseImage = new UploadApertureImage({
            upload_btn_qa_id: "phase_upload_img",
            container: aSecond,
            title: "Phase image",
            defaultValue: 0,
            onChange: () => this._onDataChanged(),
            defaultBGColor: "#000000"
        });

        this.mTransparencyImage.secondImage = this.mPhaseImage;
        this.mPhaseImage.secondImage = this.mTransparencyImage;
    }
    //_________________________________________________________________________________
    private _onDataChanged() {

        let aMajorInput = parseFloat(this.mMajorDimensionHalfSize.value);
        if (isNaN(aMajorInput) || aMajorInput < 0) {
            Popup.instance.open({
                text: `${MessagesHandler.INVALID_INPUT}, major dimension is invalid`,
            })
            this.mMajorDimensionHalfSize.value = this.mMajorDimensionHalfSize.prevValue;
        }

        const aScale = UnitHandler.getUnitToWorld(UnitHandler.PRESENTED_UNIT);
        aMajorInput *= aScale;
        if (aMajorInput > uoUserApertureInfo.MAX_HALF_SIZE_MM) {
            Popup.instance.open({
                text: `${MessagesHandler.INVALID_INPUT}, max size is ${uoUserApertureInfo.MAX_HALF_SIZE_MM}mm`,
            })
            this.mMajorDimensionHalfSize.value = this.mMajorDimensionHalfSize.prevValue;
        }

        this.mMajorDimensionHalfSize.prevValue = this.mMajorDimensionHalfSize.value;
        this.mMajorDimensionHalfSize.actualValue = parseFloat(this.mMajorDimensionHalfSize.value);

        EventManager.dispatchEvent(EventsContext.OPTICS_APERTURE_CHANGED, this);
    }
    //_________________________________________________________________________________
    protected async _setData(pOpticsVO: iOpticsVO) {
        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._User_Aperture:
                this.show(true);
                break;
            default:
                this.show(false);
                return;
        }

        this.mPhaseImage.reset();
        await this.mPhaseImage.set(pOpticsVO.parameters.physical_data.phase_mask);

        this.mTransparencyImage.reset();
        await this.mTransparencyImage.set(pOpticsVO.parameters.physical_data.transmittance_mask);
        const aScale = UnitHandler.getUnitToWorld(UnitHandler.PRESENTED_UNIT)

        let aMaxSize = (Math.max(pOpticsVO.parameters.geometry.width, pOpticsVO.parameters.geometry.height));
        this.mMajorDimensionHalfSize.value = ((aMaxSize * aScale) / 2).toString();
        this.mMajorDimensionHalfSize.actualValue = ((aMaxSize * aScale) / 2);
        this.mMajorDimensionHalfSize.prevValue = this.mMajorDimensionHalfSize.value;
    }
    //_________________________________________________________________________________
}