import { OpticsMenu } from "../../ui/menus/_search/OpticsMenu";
import { iOptiChatTabData } from "../components/chat/OptiChatTabs";
import {
  eOptiChatTabActionType,
  iOptiChatChange,
  iOptiChatContext,
} from "../store/OptiChatContext";
import { eOMFilterName } from "../../ui/menus/_search/_filterHandlers/OpticsFilterHandler";
import { DataUtils } from "../../_utils/DataUtils";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { OpticsContext } from "../../_context/OpticsContext";

export namespace OptiChatFiltersUtils {

  const MAIN_TAB = "Manual Search";
  const FILTERS_TO_IGNORE = [eOMFilterName.permission];

  function updateTabFilters(
    currTab: iOptiChatTabData,
    optiChatContext: iOptiChatContext
  ) {
    const filterTabsNoPermission = DataUtils.removeKeys(
      DataUtils.arrToHash(currTab.requested_params),
      FILTERS_TO_IGNORE
    );

    const allFilters = OpticsMenu.instance.getFilters();

    const filterNoPermission = DataUtils.removeKeys(
      allFilters,
      FILTERS_TO_IGNORE
    );

    const searchStrings = OpticsMenu.instance.searchString.trim();
    if (searchStrings.length > 0) {
      filterNoPermission["query"] = searchStrings;
    }

    const cleanFiltersNoDuplicatesTabs = getFiltersReadyToSend(
      filterTabsNoPermission
    );
    const cleanFiltersNoDuplicatesUser =
      getFiltersReadyToSend(filterNoPermission);

    /**
     * check if the were changed
     */

    const isEqual = DataUtils.isEqual(
      cleanFiltersNoDuplicatesTabs,
      cleanFiltersNoDuplicatesUser
    );

    if (isEqual === false) {
      addChangeFilterChanges(optiChatContext, filterNoPermission);
    }
  };
  //________________________________________________________________
  function getFiltersReadyToSend(filters: iHash<any>) {
    for (let key in filters) {
      switch (key) {
        case eOMFilterName.subType:
          const type = filters[eOMFilterName.type];
          const subtypeData = filters[key];
          let totalSubtypes = OpticsContext.OPTICS_DATA.find(
            (item) => item.type === type
          ).subtypes;

          totalSubtypes = totalSubtypes.filter(
            (subtype) => subtype.showOnOpticsMenu !== false
          );
          if (subtypeData.length == totalSubtypes.length) {
            delete filters[key];
          }
          break;
      }
    }

    return filters;
  };
  //________________________________________________________________
  function changeFilter(pOptiChatContext: iOptiChatContext,
    pFilters: iHash, pActiveTab: iOptiChatTabData) {

    const aFilteredChanges = pOptiChatContext.data.current.changes.filter(
      (change) => !(change.type === eOptiChatTabActionType.CHANGE_FILTER && change.tab === pActiveTab.title)
    );

    let aChange: iOptiChatChange = {
      type: eOptiChatTabActionType.CHANGE_FILTER,
      filters: pFilters,
      tab: pActiveTab.title,
    }

    aFilteredChanges.push(aChange);
    pOptiChatContext.data.current.changes = aFilteredChanges;
  }
  //________________________________________________________________
  function newTabFilters(pOptiChatContext: iOptiChatContext, pFilters: iHash) {

    const aFilteredChanges = pOptiChatContext.data.current.changes.filter(
      (change) => !(change.type === eOptiChatTabActionType.TAB_CREATION)
    );

    let aChange = {
      type: eOptiChatTabActionType.TAB_CREATION,
      filters: pFilters,
      tab_name: MAIN_TAB,
    };

    aFilteredChanges.push(aChange);
    pOptiChatContext.data.current.changes = aFilteredChanges;
  }
  //________________________________________________________________
  function addChangeFilterChanges(
    optiChatContext: iOptiChatContext,
    filters: iHash
  ) {
    const aActiveTab = optiChatContext.tabs.find((tab) => tab.active === true);

    if (aActiveTab !== undefined) {
      changeFilter(optiChatContext, filters, aActiveTab);
    } else {

      // whether we have a convo or not
      newTabFilters(optiChatContext, filters);

    }

  };
  //________________________________________________________________
  export function handleFilters(optiChatContext: iOptiChatContext) {
    const currTab = optiChatContext.tabs.find((item) => item.active === true);
    if (currTab !== undefined) {
      /**
       * check if we need to update filters
       */

      updateTabFilters(currTab, optiChatContext);
    } else {
      initialFilters(optiChatContext);
    }
  };
  //________________________________________________________________
  function initialFilters(optiChatContext: iOptiChatContext) {
    const allFilters = OpticsMenu.instance.getFilters();
    const cleanFilters = DataUtils.removeKeys(allFilters, FILTERS_TO_IGNORE);

    let searchStrings = OpticsMenu.instance.searchString.trim();
    if (searchStrings.length > 0) {
      cleanFilters["query"] = searchStrings;
    }

    const hasConversation = optiChatContext.conversation.length > 0;

    if (Object.keys(cleanFilters).length > 0 || hasConversation) {
      const aCleanFiltersNoDuplicates = getFiltersReadyToSend(cleanFilters);
      addChangeFilterChanges(optiChatContext, aCleanFiltersNoDuplicates);
    }
  };
  //________________________________________________________________

}