import { eCadType, eCadTriangulationResolution } from "../_context/Enums";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { iCadUploadData, iCadResolution, iMaterialMetaDataVO } from "../_context/_interfaces/Interfaces";
import { Op3dComponentBase } from "../ui/Op3dComponentBase";
import { ViewUtils } from "../ui/ViewUtils";
import { Checkbox } from "../ui/components/Checkbox";
import { RadioComponent } from "../ui/components/RadioComponent";
import { Popup } from "../ui/forms/Popup";
import { PopupChooseMaterial } from "../ui/forms/PopupChooseMaterial";
import { Menu } from "../ui/home/Menu";
import { iInitUploadFormParams } from "./UploadFileForm";


export interface iCadUploadFileParams {
    func: (pParams: iInitUploadFormParams, pCadOpticalData) => void;
    title: string;
    hasToReplace: boolean;
    hasSecondaryInput?: boolean;
    inputTitle?: string;
    secondaryInputTitle?: string;
    acceptedFormats?: string;
    isMultiple?: boolean;
    acceptedSizeMb?: number;
    allowedNameRegex?: RegExp

    addNameInput?: boolean;
};


export class UploadFileFormCadNew extends Op3dComponentBase<iCadUploadFileParams> {

    private static INSTANCE: UploadFileFormCadNew;

    private static DEFAULT_ACCEPTED_FORMATS: string = '.csv,.xlsx,.ods,.txt';

    private mFunc: (pParams: iInitUploadFormParams, pCadOpticalData?: iCadUploadData) => void;
    private mTitle: HTMLElement;

    private mInput: HTMLInputElement;
    private mLabel: HTMLElement;
    private mInputTitle: HTMLElement;

    private mSecondaryInput: HTMLInputElement;
    private mSecondaryLabel: HTMLElement;
    private mSecondaryInputTitle: HTMLElement;

    private mSecondaryFilesParent: HTMLElement;

    private mToReplaceInput: HTMLInputElement;
    private mToReplaceInputContainer: HTMLElement;

    private mUploadFileNameInput: HTMLInputElement;
    private mRequiredName: boolean;

    private mTryPremiumBlock: HTMLElement;

    private mMaterialSelectorPopup: PopupChooseMaterial;

    private mCadTypeInput: RadioComponent;
    private mCadResolutionInput: RadioComponent;
    private mCadLensMaterialID: string;
    private mAdvancedSection: HTMLElement;

    private mCadTypeValue: eCadType = eCadType.OPTO_MECHANICS;
    private mCadResolutionValue: iCadResolution = { angle: "0.6", deflection: "0.09" }

    private mMaxFileSizeMb?: number;
    private mSupportedTxt: HTMLElement;
    private mMaterialsList: iMaterialMetaDataVO[] = [];

    private mOpticsCadColorCheckbox: Checkbox;
    private mMaterialColor: HTMLInputElement;
    private mMaterialColorContainer: HTMLElement;

    //__________________________________________________________________________________________
    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/upload_file_form_cad_new.html'
        });
    }
    //__________________________________________________________________________________________
    public async close() {
        this.hide();
        this._onClose();
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == UploadFileFormCadNew.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            document.getElementById('forms').appendChild(aDiv);

            UploadFileFormCadNew.INSTANCE = new UploadFileFormCadNew(aDiv);
        }

        return UploadFileFormCadNew.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mSupportedTxt = this._getPart("supported-formats-lbl");
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _onOpen(pParserPormParams?: iCadUploadFileParams): void {
        this.mData = pParserPormParams;
        this.mCadLensMaterialID = '';
        if (this.mData.acceptedFormats != null) {
            this.mSupportedTxt.innerText = `Supported formats: ${this.mData.acceptedFormats}`;
        }
        this.mFunc = pParserPormParams.func;
        this.mTitle.innerHTML = pParserPormParams.title;

        this.mInput.value = '';
        this.mLabel.innerHTML = 'Choose File';
        this.mSecondaryInput.value = '';
        this.mSecondaryLabel.innerHTML = '';
        this.mInput.accept = (null != pParserPormParams.acceptedFormats) ?
            pParserPormParams.acceptedFormats : UploadFileFormCadNew.DEFAULT_ACCEPTED_FORMATS;

        this.mInputTitle.innerHTML = (null != pParserPormParams.inputTitle) ?
            pParserPormParams.inputTitle : 'Upload File';

        if (pParserPormParams.acceptedSizeMb) {
            this.mMaxFileSizeMb = pParserPormParams.acceptedSizeMb * 1024 * 1024;   // 25 * 1024 * 1024     Mb
        }

        let aHasSecondaryInput = (null != pParserPormParams.hasSecondaryInput) ?
            pParserPormParams.hasSecondaryInput : false;

        this.mSecondaryInputTitle.innerHTML = (null != pParserPormParams.secondaryInputTitle) ?
            pParserPormParams.secondaryInputTitle : 'Upload File';

        ViewUtils.setElementVisibilityByDNone(this.mSecondaryFilesParent,
            aHasSecondaryInput);

        ViewUtils.setElementVisibilityByDNone(this.mToReplaceInputContainer,
            pParserPormParams.hasToReplace);

        this.mInput.multiple = (null != pParserPormParams.isMultiple) ?
            pParserPormParams.isMultiple : false;
        this.mOpticsCadColorCheckbox.checked = false;
        this.mUploadFileNameInput.value = '';
        this._onClose();
    }
    //__________________________________________________________________________________________
    private _onChangeCadType() {
        let aOpt = this.mCadTypeInput.chosenOption as eCadType;

        switch (aOpt) {
            case eCadType.OPTICS:
                this.mCadTypeValue = eCadType.OPTICS
                ViewUtils.setElementVisibilityByDNone(this.mAdvancedSection, true);
                this.mOpticsCadColorCheckbox.label = 'Use 3DOptix optics color';
                this.mOpticsCadColorCheckbox.checked = false;
                this.mMaterialColorContainer.classList.add('d-none');
                break;
            case eCadType.OPTO_MECHANICS:
                this.mCadTypeValue = eCadType.OPTO_MECHANICS;
                ViewUtils.setElementVisibilityByDNone(this.mAdvancedSection, false);
                this.mOpticsCadColorCheckbox.label = 'Use custom color';
                this.mOpticsCadColorCheckbox.checked = false;
                this.mMaterialColorContainer.classList.add('d-none');
                break;
        }
    }
    //__________________________________________________________________________________________
    private _onChangeCadResolution() {
        let aOpt = this.mCadResolutionInput.chosenOption as eCadTriangulationResolution;
        switch (aOpt) {
            case eCadTriangulationResolution.LOW:
                this.mCadResolutionValue = { angle: '0.5', deflection: "0.01" }
                break;
            case eCadTriangulationResolution.MEDIUM:
                this.mCadResolutionValue = { angle: '0.4', deflection: '0.0005' }
                break;
            case eCadTriangulationResolution.HIGH:
                this.mCadResolutionValue = { angle: '0.4', deflection: '0.00005' }
                break;
        }
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mTryPremiumBlock = this._getPart("upload_cad_type_btns_group__try_btn");

        this.mToReplaceInput = this._getPart("to-replace") as HTMLInputElement;
        this.mToReplaceInputContainer = this._getPart("to-replace-container");

        this.mInput = this._getPart('file_input') as HTMLInputElement;
        this.mSecondaryInput = this._getPart('secondary_file_input') as HTMLInputElement;

        this.mLabel = this._getPart('csv_file_title');
        this.mSecondaryLabel = this._getPart('secondary_csv_file_title');

        this.mSecondaryFilesParent = this._getPart('secondary_files');
        this.mInputTitle = this._getPart('upload_file_title');
        this.mSecondaryInputTitle = this._getPart('secondary_upload_file_title');

        this.mUploadFileNameInput = this._getPart('name_input') as HTMLInputElement;
        this.mCadTypeValue = eCadType.OPTO_MECHANICS;

        this.mMaterialColor = this._getPart("user_cad_color") as HTMLInputElement;
        this.mMaterialColorContainer = this._getPart("cad_color_picker") as HTMLElement;

        if (!Op3dContext.USER_VO.isBasicLicense) {
            this.mCadTypeInput = new RadioComponent(this._getPart("counts-component-type"), {
                value_attr: "counts_btn",
                onChange: (_pVal: string) => this._onChangeCadType(),
                triggers: {
                    saveHistory: false,
                    saveScene: false,
                    triggerAnalysis: false
                }
            });
            this.mAdvancedSection = this._getPart('advanced_section');
            this.mAdvancedSection.classList.remove('d-none');
            this.mTryPremiumBlock.classList.add('d-none');
            const aBtn = this._getPart('counts_btn_OPTICS');
            aBtn.classList.remove('disabled')
            ViewUtils.setElementVisibilityByDNone(this.mAdvancedSection, false);
            if (this.mMaterialsList.length == 0) {
                this.mMaterialsList = await Op3dContext.DATA_MANAGER.getMaterials();
            }

            this.mCadResolutionInput = new RadioComponent(this._getPart("counts-component-resolution"), {
                value_attr: "counts_btn_res",
                onChange: (_pVal: string) => this._onChangeCadResolution(),
                triggers: {
                    saveHistory: false,
                    saveScene: false,
                    triggerAnalysis: false
                }
            });
        }

        this.mInput.addEventListener('change', () => { return this._onFileChange(this.mInput, this.mLabel) });

        this.mSecondaryInput.addEventListener('change',
            () => this._onFileChange(this.mSecondaryInput, this.mSecondaryLabel));

        this.mTitle = this._getPart('title');
        this._getPart('parse_btn').addEventListener('click', () => this._onParse());
        this.mOpticsCadColorCheckbox = new Checkbox(this._getPart('cad_color_checkbox'), {
            label: 'Use custom color',
            onChange: () => {
                if(this.mCadTypeValue === eCadType.OPTO_MECHANICS && this.mOpticsCadColorCheckbox.checked === true){
                    this.mMaterialColorContainer.classList.remove('d-none');
                }else{
                    this.mMaterialColorContainer.classList.add('d-none');
                }
            }
        });
        if(Op3dContext.USER_VO.isBasicLicense === true){
            ViewUtils.setElementDisabled(this._getPart('cad_color_checkbox'), true)
            this._getPart('upload_cad_custom_color__try_btn').classList.remove('d-none');
        }else{
            ViewUtils.setElementDisabled(this._getPart('cad_color_checkbox'), false)
        }
    }
    //__________________________________________________________________________________________
    private async _onParse() {

        if ((true == this.mRequiredName) && ('' == this.mUploadFileNameInput.value)) {
            Popup.instance.open({
                text: 'Part name is required'
            });
            return;
        }


        if (this.mInput.files.length == 0) {
            Popup.instance.open({
                text: MessagesHandler.NO_FILE_CHOSEN
            });
            return;
        }

        if (this.mData.acceptedFormats != null) {
            let aFiles = this.mInput.files;
            for (let i = 0; i < aFiles.length; i++) {
                let aDotIndex = aFiles[i].name.lastIndexOf('.');
                let aType = aFiles[i].name.substring(aDotIndex).toLowerCase();
                if (this.mData.acceptedFormats.toLowerCase().indexOf(aType) == -1) {
                    let aMessage = this.mData.isMultiple ? MessagesHandler.PLURAL_FILE_UNSUPPORTED : MessagesHandler.SINGLE_FILE_UNSUPPORTED;
                    Popup.instance.open({
                        text: aMessage
                    });
                    return;
                }
            }
        }
        let aMaterialChosen = false;
        this.mMaterialsList.forEach(item => {
            if (item.number_id == this.mCadLensMaterialID) {
                aMaterialChosen = true;
            }
        })
        if (this.mUploadFileNameInput.value.length < 1) {
            this.mUploadFileNameInput.required = true;
            return;
        }

        if ((false === aMaterialChosen) && (eCadType.OPTICS === this.mCadTypeValue)) {
            if (null === this.mMaterialSelectorPopup) {
                this.mMaterialSelectorPopup = new PopupChooseMaterial();
                await this.mMaterialSelectorPopup.open({
                    text: 'Choose material', yesBtn:
                    {
                        title: 'Confirm', callback: (pMaterial) => {
                            this.mFunc({
                                fileList: this.mInput.files,
                                secondaryFileList: this.mSecondaryInput.files,
                                toReplace: this.mToReplaceInput.checked,
                                nameInput: this.mUploadFileNameInput.value
                            }, {
                                materialID: pMaterial,
                                name: this.mUploadFileNameInput.value,
                                triangulation_resolution: this.mCadResolutionValue,
                                cad_type: this.mCadTypeValue,
                                use3DOptixColor: this.mOpticsCadColorCheckbox.checked
                            }
                            );
                            this.close();
                        }
                    }, noBtn:
                        { title: 'Cancel', callback: () => { 
                            aMaterialChosen = false;
                            this.mMaterialSelectorPopup = null;
                        }}
                })
            }

        } else {
            this.mFunc({
                fileList: this.mInput.files,
                secondaryFileList: this.mSecondaryInput.files,
                toReplace: this.mToReplaceInput.checked,
                nameInput: this.mUploadFileNameInput.value
            }, {
                materialID: this.mCadLensMaterialID,
                name: this.mUploadFileNameInput.value,
                triangulation_resolution: this.mCadResolutionValue,
                cad_type: this.mCadTypeValue,
                use3DOptixColor: ((eCadType.OPTICS === this.mCadTypeValue) &&
                    (true === this.mOpticsCadColorCheckbox.checked)),
                useCustomColor: (this.mOpticsCadColorCheckbox.checked && this.mCadTypeValue === eCadType.OPTO_MECHANICS),
                materialColor: (this.mOpticsCadColorCheckbox.checked === true && this.mCadTypeValue === eCadType.OPTO_MECHANICS)  ? this.mMaterialColor.value : undefined,

            });
            this.close();
        }

    }
    //__________________________________________________________________________________________
    private _onFileChange(pInput: HTMLInputElement, pLabel: HTMLElement) {
        let aFile = pInput.files[0];

        if (null == aFile) {
            return;
        }

        if (this.mData.allowedNameRegex != null) {
            if (this.mData.allowedNameRegex.test(aFile.name) == false || aFile.name.length > 170) {
                Popup.instance.open({
                    text: MessagesHandler.ERROR_FILE_NAME_FORMAT,
                });
                this._chooseNewfile();
                return;
            }
        }

        if (this.mMaxFileSizeMb != null && this.mMaxFileSizeMb <= aFile.size) {
            this._chooseNewfile();
            let aLimit = Op3dContext.USER_VO.isBasicLicense ?
                Menu.LIMIT_CAD_FILE.FREE : Menu.LIMIT_CAD_FILE.PREMIUM;
            Popup.instance.open({ text: `File is too big, please select another file with maximum size ${aLimit}MB.` });
            return null;
        }

        pLabel.innerHTML = '';
        for (let i = 0; i < pInput.files.length; i++) {
            pLabel.innerHTML += pInput.files[i].name + '; ';
            let aDotIndex = pInput.files[i].name.lastIndexOf('.');
            let aName = pInput.files[i].name.substring(0, aDotIndex);
            this.mUploadFileNameInput.value = aName
        }
    }
    //__________________________________________________________________________________________
    private _chooseNewfile() {
        this.mInput.files = null
        this.mInput.name = "";
        this.mInput.value = null;
        this.mSecondaryInput.value = '';
        this.mLabel.innerHTML = 'Choose File';
        this.mSecondaryInput.innerHTML = '';
    }
    //__________________________________________________________________________________________
    protected _onClose() {
        this.mInput.value = '';
        this.mSecondaryInput.value = '';
        this.mLabel.innerHTML = 'Choose File';
        this.mSecondaryInput.innerHTML = '';
        this.mUploadFileNameInput.value = '';
        this.mUploadFileNameInput.required = false;
        this.mCadLensMaterialID = '';
        this.mMaterialColorContainer.classList.add('d-none');
        this.mOpticsCadColorCheckbox.checked = false;
        if (this.mCadResolutionInput != null) {
            this.mCadTypeInput.chooseOption("OPTO_MECHANICS", true)
            this.mCadResolutionInput.chooseOption("MEDIUM", true)
            this.mMaterialSelectorPopup = null;
        }
    }
    //__________________________________________________________________________________________
}
