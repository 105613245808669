
import { absSceneHistory } from "./absSceneHistory";

export class SceneHistoryWidget extends absSceneHistory {

    constructor() {
        super();
    }
    //__________________________________________________________________________________________
}
