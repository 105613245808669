import classes from "../../UI/optichat-menu.module.scss";
import { iOptiChatMenuOptionExample } from "../../store/OptiChatContext";
import OptiChatMenuOption from "./OptiChatMenuOption";

export default function OptiChatMenuOptions(props: {
  options: Array<iOptiChatMenuOptionExample>;
}) {
  return (
    <div className={classes["options-container"]}>
      <div className={classes.options}>
        {props.options.map((item) => (
          <OptiChatMenuOption key={item.title} data={item} />
        ))}
      </div>
    </div>
  );
}
