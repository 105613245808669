﻿import { EventManager } from "../../../../oc/events/EventManager";
import { EventsContext } from "../../../_context/EventsContext";
import { iHash } from "../../../_context/_interfaces/Interfaces";
import { iOpticsVO, iSurfaceDeformation, eSurfaceDeformation, iZernikeDeformation } from "../../../data/VO/OpticsVOInterfaces";
import { iFace, iJonesMatrix } from "../../../parts/PartInterfaces";
import { iSMPolynomial, eSmTermKind } from "../../../simulation/SimulationContext";
import { ViewUtils } from "../../ViewUtils";
import { sdJonesMatrix } from "./sdJonesMatrix";
import { sdPolynomial } from "./sdPolynomial";
import { sdSurfaceDeformation } from "./sdSurfaceDeformation";
import { sdZernike } from "./sdZernike";
import { uoSection } from "./uoSection";

export interface iSurfaceShape {
    opticsVO: iOpticsVO;
    face: iFace;
};



export class esSurfaceShape<T> extends uoSection<T, Array<iSurfaceDeformation>> {

    protected mDeformationTypeSelect!: HTMLSelectElement;
    private mEnable!: HTMLInputElement;
    private mSDContainer: iHash<sdSurfaceDeformation> = {}
    protected mSurfaceShape!: T;


    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: './skins/forms/optics/es_surface_shape.html',
            title: 'Surface shape',
            isNewSkin: false,
            isPremiumSection: false

        });
    }
    //__________________________________________________________________________________________
    protected _onUpdateSurfaceShape() {
        EventManager.dispatchEvent(EventsContext.OPTICS_DEFORMATION_CHANGE, this);
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mEnable = this._getPart('enable_surface_shape', true) as HTMLInputElement;
        let aLabel = this._getPart("label_enable_surface_shape") as HTMLLabelElement;
        aLabel.htmlFor = this.mEnable.id;
        this.mDeformationTypeSelect = this._getPart('deformation_type') as HTMLSelectElement;
        this._initContainers();
    }
    //__________________________________________________________________________________________
    private _initContainers() {
        let aContainersParent = this._getPart('params_parent');

        for (let i = 0; i < this.mDeformationTypeSelect.options.length; i++) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('row');
            aDiv.setAttribute('eq_type', this.mDeformationTypeSelect.options[i].value);
            aContainersParent.appendChild(aDiv);
        }
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
        this.mEnable.addEventListener('change', () => this._onChangeEnableDeformation());

        this.mDeformationTypeSelect.addEventListener('change',
            () => this._onChangeDeformationType());
    }
    //__________________________________________________________________________________________
    private _onChangeEnableDeformation(pToUpdate: boolean = true) {
        let aShapeSettings = this._getPart('shape_settings');
        ViewUtils.setElementVisibilityByDNone(aShapeSettings, this.mEnable.checked);

        if (true == pToUpdate) {
            this._onUpdateSurfaceShape()
        }
    }
    //__________________________________________________________________________________________
    protected _getDeformations() {
        let aFace = (this.mSurfaceShape as unknown as iSurfaceShape).face;
        let aOpticsVO = (this.mSurfaceShape as unknown as iSurfaceShape).opticsVO;
        let aDeformations = (null != aOpticsVO.parameters.geometry.deformation) ?
            aOpticsVO.parameters.geometry.deformation[aFace.originalName] : null;

        return aDeformations;
    }
    //__________________________________________________________________________________________
    protected _getNormalizationRadius() {
        let aOpticsVO = (this.mSurfaceShape as unknown as iSurfaceShape).opticsVO;
        let aRadius = (null != aOpticsVO.parameters.geometry.diameter) ?
            (aOpticsVO.parameters.geometry.diameter / 2) : 25;
        return aRadius;
    }
    //__________________________________________________________________________________________
    private _onChangeDeformationType(pToUpdate: boolean = true) {
        $(this.mContainer).find('[eq_type]').each((_index, elem) => {
            let aToShow = (elem.getAttribute('eq_type') == this.mDeformationTypeSelect.value);
            ViewUtils.setClassShowState(elem as HTMLElement, aToShow);
        });

        let aType = this.mDeformationTypeSelect.value as eSurfaceDeformation;
        let aContainer = $(this.mContainer).find('[eq_type="' + aType + '"]')[0];
        let aDeformations = this._getDeformations();

        switch (aType) {
            case eSurfaceDeformation.ZERNIKE_STANDARD_SAG:
            case eSurfaceDeformation.ZERNIKE_STANDARD_PHAZE:
            case eSurfaceDeformation.ZERNIKE_FRINGE_PHAZE:
            case eSurfaceDeformation.ZERNIKE_FRINGE_SAG:
            case eSurfaceDeformation.ZERNIKE_ANNULAR_PHAZE:
                if (null == this.mSDContainer[this.mDeformationTypeSelect.value]) {
                    this.mSDContainer[aType] = new sdZernike(aContainer, () => this._onUpdateSurfaceShape());
                }

                if ((null != aDeformations) && (aDeformations[0].type == aType)) {
                    this.mSDContainer[aType].setData(aDeformations[0]);
                } else {


                    let aNormalizationRadius = this._getNormalizationRadius();
                    let aZernikeSurfaceDeformation: iSurfaceDeformation<iZernikeDeformation> = {
                        type: aType,
                        params: {
                            coeffs: {},
                            normalization_radius: aNormalizationRadius
                        }
                    };

                    this.mSDContainer[aType].setData(aZernikeSurfaceDeformation);
                }

                break;
            case eSurfaceDeformation.JONES_MATRIX:
                if (null == this.mSDContainer[aType]) {
                    this.mSDContainer[aType] = new sdJonesMatrix(aContainer, () => this._onUpdateSurfaceShape());
                }

                if ((null != aDeformations) && (aDeformations[0].type == aType)) {
                    this.mSDContainer[aType].setData(aDeformations[0]);
                } else {
                    let aJMDeformation: iSurfaceDeformation<iJonesMatrix> = {
                        type: aType,
                        params: {
                            A: [0, 0],
                            B: [0, 0],
                            C: [0, 0],
                            D: [0, 0]
                        }
                    };

                    this.mSDContainer[aType].setData(aJMDeformation);
                }

                break;

            case eSurfaceDeformation.ASPHERE:
            case eSurfaceDeformation.EVEN_ASPHERE:
            case eSurfaceDeformation.ODD_ASPHERE:
                if (null == this.mSDContainer[aType]) {
                    this.mSDContainer[aType] = new sdPolynomial(aContainer,
                        () => this._onUpdateSurfaceShape());
                }

                if ((null != aDeformations) && (aDeformations[0].type == aType)) {
                    this.mSDContainer[aType].setData(aDeformations[0]);
                } else {
                    let aPolynomialDeformation: iSurfaceDeformation<iSMPolynomial> = {
                        type: aType,
                        params: {
                            term_kind: eSmTermKind.RADIAL_DISTANCE,
                            terms: []
                        }
                    };

                    this.mSDContainer[aType].setData(aPolynomialDeformation);
                }

                break;

        }

        if (true == pToUpdate) {
            this._onUpdateSurfaceShape();
        }
    }
    //__________________________________________________________________________________________
    protected async _setData(pSurfaceShape: T) {
        this.mSurfaceShape = pSurfaceShape;
        let aDeformations = this._getDeformations()

        this.mEnable.checked = (null != aDeformations);
        this._onChangeEnableDeformation(false);

        if ((null != aDeformations) && (null != aDeformations[0])) {
            this.mDeformationTypeSelect.value = aDeformations[0].type;
        } else {
            this.mDeformationTypeSelect.selectedIndex = 0;
        }

        this._onChangeDeformationType(false);
    }
    //__________________________________________________________________________________________
    public getData(): iSurfaceDeformation[] {
        if ((false == this.mEnable.checked) || ('' == this.mDeformationTypeSelect.value)) {
            return null;
        }

        let aSurfaceDeformationArr = new Array<iSurfaceDeformation>();
        let aDeformation = this.mSDContainer[this.mDeformationTypeSelect.value];
        let aData = aDeformation.getData();
        if (null != aData) {
            aSurfaceDeformationArr.push(aData);
        }

        return aSurfaceDeformationArr;
    }
    //__________________________________________________________________________________________
}
