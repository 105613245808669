﻿import { eScatteringType, eScatteringModel, eDataPermission, eStateToAnalysis } from "../../../_context/Enums";
import { Op3dContext } from "../../../_context/Op3dContext";
import { iScatteringBSDFParams, iScatteringVO, iOP3DHTMLInputElement } from "../../../_context/_interfaces/Interfaces";
import { DataUtils } from "../../../_utils/DataUtils";
import { OP3DMathUtils } from "../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../_utils/Op3dUtils";
import { Part } from "../../../parts/Part";
import { iFace, iFaceDataNEW } from "../../../parts/PartInterfaces";
import { ServerContext } from "../../../server/ServerContext";
import { SettingsContext } from "../../../settings/SettingsContext";
import { ViewUtils } from "../../ViewUtils";
import { AnalysisPortal } from "../../analysis/AnalysisPortal";

import { iMaterialParam } from "./uoMaterial";
import { uoSection } from "./uoSection";


export class esScattering extends uoSection<iFace | iFaceDataNEW> {


    private static DEFAULT_BSDF: iScatteringBSDFParams = {
        sigmaX: 10,
        sigmaY: 10,
        azimuth_theta: 0,
        a: 1,
        b: 2,
        g: 2,
        n: 1.5
    };

    private static DEFAULT_GAUSSIAN_SCATTERING: iScatteringVO = {
        name: 'Scattering model',
        number_id: null,
        parameters: {
            scatteringType: eScatteringType.SIMPLE,
            scatterModel: eScatteringModel.GAUSSIAN,
            bsdfParams: {
                sigmaX: 10,
                sigmaY: 10,
                azimuth_theta: 0,
                a: 1,
                b: 2,
                g: 2,
                n: 1.5
            },
            numberOfRays: 10,
            relativePowerThreshold: 0.00001,
            transmittance: 1,
            reflectance: 0,
            absorption: 0
        },
        permission: eDataPermission.PUBLIC
    };

    private static _GAUSSIAN_BSDF_PARAMS: Array<iMaterialParam> = [
        {
            name: 'σ<sub>x</sub>',
            path: 'sigmaX',
            range: {
                max: 90,
                min: 0
            },
            value: 10,
            unit: 'Deg'
        },
        {
            name: 'σ<sub>y</sub>',
            path: 'sigmaY',
            range: {
                max: 90,
                min: 0
            },
            value: 10,
            unit: 'Deg'
        },
        {
            name: 'Azimuth θ',
            path: 'azimuth_theta',
            range: {
                max: 90,
                min: -90
            },
            value: 0,
            unit: 'Deg'
        }
    ];

    private static _ABG_PARAMS: Array<iMaterialParam> = [
        {
            name: 'A',
            range: {
                min: 0,
                max: Infinity
            },
            path: 'a',
            value: 1
        },
        {
            name: 'B',
            path: 'b',
            value: 1,
            unit: 'si',
            rangeFunc: (pVal, pScatteringParams) => {
                let aMin = (0 == pScatteringParams.g) ? 0 : 1e-12;
                let aMax = Infinity;

                return OP3DMathUtils.clampValue(pVal, aMin, aMax);
            }
        } as iMaterialParam<iScatteringBSDFParams>,
        {
            name: 'g',
            path: 'g',
            value: 1
        }];

    private static _COS_NTH_PARAMS: Array<iMaterialParam> = [
        {
            name: 'N',
            path: 'n',
            value: 1.5
        }
    ];

    private mOneParamElement: HTMLElement;
    private mBSDFParamsParent: HTMLElement;

    private mHasScattaringCheckbox: HTMLInputElement;
    private mNumOfScatteredRays: iOP3DHTMLInputElement;
    private mRelThreshold: iOP3DHTMLInputElement;
    private mTransmittance: iOP3DHTMLInputElement;
    private mReflectance: iOP3DHTMLInputElement;
    private mAbsorption: iOP3DHTMLInputElement;

    private mScattringSettings: HTMLElement;
    private mScatterModel: HTMLSelectElement;

    private mScatteringVO: iScatteringVO;
    private mFace: iFace | iFaceDataNEW;
    // private mOptomechanicsLens: Part;
    private mPart: Part;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: './skins/forms/optics/es_scattering_info.html',
            title: 'Scattering Info',
            isNewSkin: false,
            isPremiumSection: true
        });
    }
    //__________________________________________________________________________________________
    private _updateEnergyDistribution() {
        this.mScatteringVO.parameters.transmittance =
            (0.01 * parseFloat(this.mTransmittance.value));
        this.mScatteringVO.parameters.reflectance =
            (0.01 * parseFloat(this.mReflectance.value));
        this.mScatteringVO.parameters.absorption =
            (0.01 * parseFloat(this.mAbsorption.value));

        this._updateFaceScatteringVO();
    }
    //__________________________________________________________________________________________
    protected async _initElements(): Promise<void> {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param');
        this.mBSDFParamsParent = this.mOneParamElement.parentElement;

        this.mTransmittance = this._getPart('transmittance') as iOP3DHTMLInputElement;
        this.mReflectance = this._getPart('reflectance') as iOP3DHTMLInputElement;
        this.mAbsorption = this._getPart('absorption') as iOP3DHTMLInputElement;

        this.mTransmittance.addEventListener('change', () => {
            let aTransmittance = parseFloat(this.mTransmittance.value);
            if (true == isNaN(aTransmittance)) {
                this.mTransmittance.value = this.mTransmittance.prevValue;
                return;
            }

            aTransmittance = OP3DMathUtils.clampValue(aTransmittance, 0, 100);
            this.mTransmittance.value = aTransmittance.toString();

            this.mTransmittance.prevValue = this.mTransmittance.value;

            let aReflectance = parseFloat(this.mReflectance.value);

            let aTransPlusRef = (aTransmittance + aReflectance);
            if (100 == aTransPlusRef) {
                this.mAbsorption.value = '0';
            } else if (aTransPlusRef < 100) {
                this.mAbsorption.value = (100 - aTransPlusRef).toString();
            } else {
                this.mAbsorption.value = '0';
                this.mReflectance.value = (100 - aTransmittance).toString();
                this.mReflectance.prevValue = this.mReflectance.value;
            }

            this._updateEnergyDistribution();
        });

        this.mReflectance.addEventListener('change', () => {
            let aReflectance = parseFloat(this.mReflectance.value);
            if (true == isNaN(aReflectance)) {
                this.mReflectance.value = this.mReflectance.prevValue;
                return;
            }

            aReflectance = OP3DMathUtils.clampValue(aReflectance, 0, 100);
            this.mReflectance.value = aReflectance.toString();

            this.mReflectance.prevValue = this.mReflectance.value;

            let aTransmittance = parseFloat(this.mTransmittance.value);

            let aTransPlusRef = (aTransmittance + aReflectance);
            if (100 == aTransPlusRef) {
                this.mAbsorption.value = '0';
            } else if (aTransPlusRef < 100) {
                this.mAbsorption.value = (100 - aTransPlusRef).toString();
            } else {
                this.mAbsorption.value = '0';
                this.mTransmittance.value = (100 - aReflectance).toString();
                this.mTransmittance.prevValue = this.mTransmittance.value;
            }

            this._updateEnergyDistribution();
        });

        this.mRelThreshold = this._getPart('relative_power_treshold') as iOP3DHTMLInputElement;
        this.mRelThreshold.addEventListener('change', () => {
            let aRelTreshold = parseFloat(this.mRelThreshold.value);
            if (true == isNaN(aRelTreshold)) {
                this.mRelThreshold.value = this.mRelThreshold.prevValue;
                return;
            }

            aRelTreshold = OP3DMathUtils.clampValue(aRelTreshold, 0.00001, 1);
            this.mRelThreshold.value = this._getToFixedVal(aRelTreshold);
            this.mRelThreshold.prevValue = this.mRelThreshold.value;

            this.mScatteringVO.parameters.relativePowerThreshold =
                parseFloat(this.mRelThreshold.value);

            this._updateFaceScatteringVO();
        });

        this.mNumOfScatteredRays = this._getPart('num_of_scattered_rays') as iOP3DHTMLInputElement;
        this.mNumOfScatteredRays.addEventListener('change', () => {
            let aValue = parseInt(this.mNumOfScatteredRays.value);
            if (true == isNaN(aValue)) {
                this.mNumOfScatteredRays.value = this.mNumOfScatteredRays.prevValue;
                return;
            }

            aValue = OP3DMathUtils.clampValue(aValue, 1, 100);

            this.mNumOfScatteredRays.value = aValue.toString();
            this.mNumOfScatteredRays.prevValue = this.mNumOfScatteredRays.value;

            this.mScatteringVO.parameters.numberOfRays = aValue;
            this._updateFaceScatteringVO();
        });

        this.mScatterModel = this._getPart('scattering_type') as HTMLSelectElement;
        this.mScatterModel.addEventListener('change', () => this._onChangeScatteringType());

        this.mHasScattaringCheckbox = this._getPart('use_scattring', true) as HTMLInputElement;
        let aLabel = this._getPart('use_scattring_label', true) as HTMLLabelElement;
        aLabel.htmlFor = this.mHasScattaringCheckbox.id;
        this.mHasScattaringCheckbox.addEventListener('change', () => this._onSetScattering());

        this.mScattringSettings = this._getPart('scattering_settings');
        let aLinkToPricing = this._getPart('try-professional-label', true) as HTMLAnchorElement;
        aLinkToPricing.href = ServerContext.pricing_base_link;
    }
    //__________________________________________________________________________________________
    private _onChangeScatteringType(pBSDF: iScatteringBSDFParams = esScattering.DEFAULT_BSDF,
        pToUpdate: boolean = true) {
        ViewUtils.removeElementChildren(this.mBSDFParamsParent);

        let aScatteredModel = this.mScatterModel.value as eScatteringModel;
        switch (aScatteredModel) {
            case eScatteringModel.GAUSSIAN:
                this._createParams(esScattering._GAUSSIAN_BSDF_PARAMS, pBSDF);
                break;
            case eScatteringModel.LAMBERTIAN:
                this._createParams([], pBSDF);
                break;
            case eScatteringModel.ABG:
                this._createParams(esScattering._ABG_PARAMS, pBSDF);
                break;
            case eScatteringModel.COS_NTH:
                this._createParams(esScattering._COS_NTH_PARAMS, pBSDF);
                break;
            default:
                break;
        }

        if (true == pToUpdate) {
            this.mScatteringVO.parameters.scatterModel = aScatteredModel;
            this._updateFaceScatteringVO();
        }
    }
    //__________________________________________________________________________________________
    private _onSetScattering(pToUpdate: boolean = true) {
        let aIsEnable = this.mHasScattaringCheckbox.checked;
        ViewUtils.setElementVisibilityByDNone(this.mScattringSettings, aIsEnable);

        if (true == pToUpdate) {
            this._updateFaceScatteringVO();
        }
    }
    //__________________________________________________________________________________________
    protected async _setData(pFace: iFace | iFaceDataNEW, pOptomechanicsLens?: Part) {
        this._clear();
        // this.mOptomechanicsLens = pOptomechanicsLens != null ? pOptomechanicsLens : null
        // if (null == pFace.data && this.mOptomechanicsLens != null) {
        this.mPart = pOptomechanicsLens != null ? pOptomechanicsLens : null
        if (null == pFace.data && this.mPart != null) {
            pFace["data"] = {};
        }
        let aHasScatteringVO = (null != pFace.data.scattringVO);
        let aScatteringVO = (true == aHasScatteringVO) ? pFace.data.scattringVO :
            esScattering.DEFAULT_GAUSSIAN_SCATTERING;

        this.mFace = pFace;
        this._fillData(aScatteringVO, aHasScatteringVO);
    }
    //__________________________________________________________________________________________
    private _fillData(pScattetingVO: iScatteringVO, pIsEnabled: boolean) {
        this.mHasScattaringCheckbox.checked = pIsEnabled;
        this._onSetScattering(false);

        this.mScatterModel.value = pScattetingVO.parameters.scatterModel.toString();
        if (null != pScattetingVO.parameters.numberOfRays) {
            this.mNumOfScatteredRays.value = pScattetingVO.parameters.numberOfRays.toString();
        }

        this.mRelThreshold.value = (null != pScattetingVO.parameters.relativePowerThreshold) ?
            pScattetingVO.parameters.relativePowerThreshold.toString() : '0.00001';

        this.mTransmittance.value = (100 * pScattetingVO.parameters.transmittance).toString();
        this.mTransmittance.prevValue = this.mTransmittance.value;
        this.mReflectance.value = (100 * pScattetingVO.parameters.reflectance).toString();
        this.mReflectance.prevValue = this.mReflectance.value;
        this.mAbsorption.value = (100 * pScattetingVO.parameters.absorption).toString();

        this._onChangeScatteringType(pScattetingVO.parameters.bsdfParams, false);

        this.mScatteringVO = pScattetingVO;
    }
    //__________________________________________________________________________________________
    private _createParams(pData: Array<iMaterialParam>, pBSDF: iScatteringBSDFParams) {
        for (let i = 0; i < pData.length; i++) {
            let aData = pData[i];

            let aName = aData.name;
            let aUnit = aData.unit;
            let aPath = aData.path;

            let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
            this.mBSDFParamsParent.appendChild(aOneParam);

            Op3dUtils.getElementIn(aOneParam, 'param_name').innerHTML = aName;

            let aUnitElement = Op3dUtils.getElementIn(aOneParam, 'unit_span');
            if (null != aUnit) {
                aUnitElement.innerHTML = aUnit;
            } else {
                ViewUtils.removeFromParent(aUnitElement.parentElement);
            }

            let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as
                iOP3DHTMLInputElement;

            aInput.addEventListener('change', () => {
                let aValue = parseFloat(aInput.value);
                if (null != aData.range) {
                    aValue = OP3DMathUtils.clampValue(aValue, aData.range.min, aData.range.max);
                    aInput.value = aValue.toString();
                    aInput.prevValue = aInput.value;

                }
                if (null != aData.rangeFunc) {
                    aValue = aData.rangeFunc(aValue, this.mScatteringVO.parameters.bsdfParams);
                    if (null == aValue) {
                        aInput.value = aInput.prevValue;
                        return;
                    }

                    aInput.value = aValue.toString();
                    aInput.prevValue = aInput.value;
                }

                this.mScatteringVO.parameters.bsdfParams[aPath] = aValue;
                this._updateFaceScatteringVO();
            });

            aInput.value = pBSDF[aPath].toString();
            aInput.prevValue = aInput.value;
        }
    }
    //__________________________________________________________________________________________
    private _updateFaceScatteringVO() {
        Op3dContext.SCENE_HISTORY.addToHistory();
 
        /** 
         * changes the info in the face object, remove it from there and do func saveScatter();
         * */ 

        // this.mFace.data.scattringVO = (true == this.mHasScattaringCheckbox.checked) ?
        //     DataUtils.getObjectCopy(this.mScatteringVO) : null;

        // if (this.mPart != null) {    

        //     if (this.mPart.opticalData.faces == null) {
        //         this.mPart.opticalData['faces'] = [];
        //     }
        //     // let aItems = this.mOptomechanicsLens.opticalData.faces.filter(item => item.name == this.mFace.name)
        //     let aItems = this.mPart.opticalData.faces.filter(item => item.name == this.mFace.name)
        //     if (aItems.length < 1) {
        //         // this.mOptomechanicsLens.opticalData.faces.push(this.mFace)
        //         this.mPart.opticalData.faces.push(this.mFace)
        //     } else {
        //         aItems[0] = this.mFace;
        //     }
        // }
        AnalysisPortal.instance.enableRunAnalysis(eStateToAnalysis.ENABLE_ANALYSIS,
            eStateToAnalysis.FROM_SCENE);
        Op3dContext.SCENE_HISTORY.saveScene();
    }
    //__________________________________________________________________________________________
    private _clear() {
        this.mFace = null;
        this.mPart = null;
    }
    //__________________________________________________________________________________________
    public saveScattering(){
        this.mFace.data.scattringVO = (true == this.mHasScattaringCheckbox.checked) ?
            DataUtils.getObjectCopy(this.mScatteringVO) : null;

        if (this.mPart != null) {    

            if (this.mPart.opticalData.faces == null) {
                this.mPart.opticalData['faces'] = [];
            }
            let aItems = this.mPart.opticalData.faces.filter(item => item.name == this.mFace.name)
            if (aItems.length < 1) {
                this.mPart.opticalData.faces.push(this.mFace)
            } else {
                aItems[0] = this.mFace;
            }
        }
    }
    //__________________________________________________________________________________________
    private _getToFixedVal(pVal: number) {

        let aToFixed: number;

        try {
            aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        } catch (e) {
            aToFixed = SettingsContext.DEFAULT_USER_VO.parameters.simulation.numericAccuracy;
        }

        return OP3DMathUtils.toFixed(pVal, aToFixed)
    }
}
