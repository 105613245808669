import { Strings } from "../../../../_context/Strings";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { ViewUtils } from "../../../ViewUtils";
import { iOpticsFilterComp, iMultipleCheckboxData, iOneCheckBoxData } from "../MenusContext";
import { eOMFilterName } from "../_filterHandlers/OpticsFilterHandler";
import { Filter } from "./Filter";

export class MultipleCheckboxFilter extends Filter {

    private static SKIN_PATH = "./skins/menus/filters/multiple_checkbox_component.html";

    private mCheckBoxes: Array<HTMLInputElement> = new Array<HTMLInputElement>();
    private mSingleCheckBox: HTMLElement;
    private mCheckBoxesParent: HTMLElement;

    private mSelectAllDiv: HTMLElement;
    private mSelectAllCheckbox: HTMLInputElement;
    private mNamesArray: Array<string> = new Array<string>();

    constructor(pData: iOpticsFilterComp<iMultipleCheckboxData>) {
        super(MultipleCheckboxFilter.SKIN_PATH, pData);
    }
    //__________________________________________________________________________________________
    protected init() {
        super.init();

        this.mCheckBoxesParent = this._getPart('checkboxes_parent', true);
        this.mSingleCheckBox = this._getPart('multiple_checkbox_component');
        ViewUtils.removeFromParent(this.mSingleCheckBox);
        this.mSelectAllDiv = this._getPart('select_all_checkbox_parent', true) as
            HTMLElement;
        this._initSelectAll();
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        super._onCreationComplete();
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    public exportToJson() {
        let aCheckesBoxData: iHash<iOneCheckBoxData> = {};
        for (let i = 0; i < this.mCheckBoxes.length; i++) {
            let aName = this.mCheckBoxes[i].name;
            let aIsChecked = this.mCheckBoxes[i].checked;
            let aIsEnabled = this.mCheckBoxes[i].getAttribute('enabled') == 'true' ?
                true : false;
            aCheckesBoxData[aName] = {
                name: aName,
                isChecked: aIsChecked,
                isEnabled: aIsEnabled
            };
        }

        return aCheckesBoxData;
    }
    //__________________________________________________________________________________________
    public initFromJson(pData: Array<iOneCheckBoxData>) {
        for (let i = 0; i < this.mCheckBoxes.length; i++) {
            let aName = this.mCheckBoxes[i].name;
            if (null != pData[aName]) {
                this.mCheckBoxes[i].checked = pData[aName].isChecked;
                let aIsEnable = (true == pData[aName].isEnabled) ? 'true' : 'false';
                this.mCheckBoxes[i].setAttribute('enabled', aIsEnable);

                this._onCheckboxChange(this.mCheckBoxes[i], aName, false);
            }
        }
    }
    //__________________________________________________________________________________________
    public reset(pToChange: boolean = false, pToClear: boolean = false) {
        this.mNamesArray.splice(0);

        for (let i = 0; i < this.mCheckBoxes.length; i++) {
            this.mCheckBoxes[i].checked = false;
        }

        if (this.mSelectAllCheckbox != null) {
            this.mSelectAllCheckbox.checked = false;
        }

        this._onChange(pToChange, pToClear);
    }
    //__________________________________________________________________________________________
    private _clear() {
        while (this.mCheckBoxesParent.children.length > 0) {
            this.mCheckBoxesParent.removeChild(this.mCheckBoxesParent.children[0]);
        }

        this.mCheckBoxes.splice(0);
        this.mNamesArray.splice(0);
    }
    //__________________________________________________________________________________________
    private _updateCheckboxesData(pCheckboxesData: Array<iOneCheckBoxData>) {
        this._clear();

        for (let i = 0; i < pCheckboxesData.length; i++) {
            let aCheckboxParent = this.mSingleCheckBox.cloneNode(true) as HTMLElement;
            let aCheckBox = Op3dUtils.getElementIn(aCheckboxParent, 'check_box_input',
                true) as HTMLInputElement;

            let aIsEnabled = pCheckboxesData[i].isEnabled != null ?
                pCheckboxesData[i].isEnabled : true;
            ViewUtils.setElementDisabled(aCheckboxParent, !aIsEnabled);

            if (aIsEnabled) {
                aCheckBox.checked = pCheckboxesData[i].isChecked != null ?
                    pCheckboxesData[i].isChecked : false;
                aCheckBox.addEventListener('change',
                    () => this._onCheckboxChange(aCheckBox, pCheckboxesData[i].name));
                aCheckBox.setAttribute('enabled', 'true');

                if (aCheckBox.checked) {
                    this.mNamesArray.push(pCheckboxesData[i].name);
                }

            }
            aCheckBox.name = pCheckboxesData[i].name;

            let aLabel = Op3dUtils.getElementIn(aCheckboxParent,
                'check_box_label', true) as HTMLLabelElement;
            aLabel.setAttribute('nameForCount', pCheckboxesData[i].name)
            aLabel.htmlFor = aCheckBox.id;
            aLabel.innerHTML = pCheckboxesData[i].name;

            this.mCheckBoxesParent.appendChild(aCheckboxParent);
            this.mCheckBoxes.push(aCheckBox);
        }
    }
    //__________________________________________________________________________________________
    private _onCheckboxChange(pElement: HTMLInputElement, pName: string,
        pToChange: boolean = true) {
        this._addToNamesArray(pElement.checked, pName);
        this._onChange(pToChange);
    }
    //__________________________________________________________________________________________
    private _addToNamesArray(pIsChecked: boolean, pName: string) {
        let aIndex = this.mNamesArray.indexOf(pName);
        if (pIsChecked && aIndex == -1) {
            this.mNamesArray.push(pName);
        } else if (aIndex > -1 && pIsChecked == false) {
            this.mNamesArray.splice(aIndex, 1);
        }
    }
    //__________________________________________________________________________________________
    protected _onChange(pToChange: boolean, pToClear: boolean = false) {
        if (null == this.mOnChange) {
            return;
        }

        let aCheckedNamesNum = this.mNamesArray.length;
        let aNames = null;
        if (aCheckedNamesNum < this.mCheckBoxes.length) {
            aNames = (aCheckedNamesNum > 0) ? this.mNamesArray : this._getAllNames(pToClear);
        }

        this.mOnChange(aNames, pToChange);
    }
    //__________________________________________________________________________________________
    private _getAllNames(pToClear: boolean) {
        if (pToClear == true || this.mIsCanBeNone == true ||
            this.mCheckBoxes.length == 0) {
            return null;
        }

        let aArr = [];
        for (let i = 0; i < this.mCheckBoxes.length; i++) {
            let aIsEnable = (this.mCheckBoxes[i].getAttribute('enabled') == 'true');
            if (aIsEnable) {
                let aName = this.mCheckBoxes[i].name;
                aArr.push(aName);
            }
        }

        return aArr;
    }
    //__________________________________________________________________________________________
    private _initSelectAll() {
        this.mSelectAllCheckbox = Op3dUtils.getElementIn(this.mSelectAllDiv,
            'select_all_checkbox', true) as HTMLInputElement;
        let aLabel = Op3dUtils.getElementIn(this.mSelectAllDiv,
            'check_box_label', true) as HTMLLabelElement;
        aLabel.htmlFor = this.mSelectAllCheckbox.id;
        this.mSelectAllCheckbox.addEventListener('change',
            () => this._toggleSelectAll(this.mSelectAllCheckbox));
    }
    //__________________________________________________________________________________________
    private _updateSelectAll(pToShow: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mSelectAllDiv, pToShow);
    }
    //__________________________________________________________________________________________
    private _toggleSelectAll(pCheckbox: HTMLInputElement) {
        let aChecked = pCheckbox.checked;

        for (let i = 0; i < this.mCheckBoxes.length; i++) {
            aChecked = (this.mCheckBoxes[i].getAttribute('enabled') == 'true') ?
                pCheckbox.checked : false;
            this.mCheckBoxes[i].checked = aChecked;
            let aName = this.mCheckBoxes[i].name;
            this._addToNamesArray(aChecked, aName);
        }

        this._onChange(true);
    }
    //__________________________________________________________________________________________
    public updateFilterData(pMultipleCheckboxData: iMultipleCheckboxData,
        pToChange: boolean = true) {
        if (pMultipleCheckboxData == null) {
            return;
        }

        if (pMultipleCheckboxData.checkboxesData != null) {
            this._updateCheckboxesData(pMultipleCheckboxData.checkboxesData);
        }
        if (pMultipleCheckboxData.selectAllCheckbox != null) {
            this._updateSelectAll(pMultipleCheckboxData.selectAllCheckbox);
        }

        this.mSelectAllCheckbox.checked = false;

        this._onChange(pToChange);
    }
    //__________________________________________________________________________________________
    public disable() {
        this.mCheckBoxesParent.classList.add(Strings.DISABLED_STATE)
    }
    //__________________________________________________________________________________________
    public enable() {
        this.mCheckBoxesParent.classList.remove(Strings.DISABLED_STATE)
    }
    //__________________________________________________________________________________________
    public setData(pName: string, pData: Array<any>) {



        for (let i = 0; i < pData.length; i++) {
            let aKey = Object.keys(pData[i])[0];
            if (aKey === pName) {

                switch (pName) {

                    case eOMFilterName.brand:
                    case eOMFilterName.subType: {
                        let aValues = Object.values(pData[i])[0] as Array<string>;

                        for (let i = 0; i < this.mCheckBoxes.length; i++) {
                            const aName = this.mCheckBoxes[i].name;
                            this.mCheckBoxes[i].checked = aValues.indexOf(aName) !== -1;
                            this._onCheckboxChange(this.mCheckBoxes[i], aName, false);
                            this.updateCollapse({ toShow: true, scrollInfoView: true });
                        }

                        break;
                    }
                    default:

                        let aVal = Object.values(pData[i])[0] as string;

                        for (let i = 0; i < this.mCheckBoxes.length; i++) {
                            const aName = this.mCheckBoxes[i].name;
                            this.mCheckBoxes[i].checked = aVal === (aName);
                            this._onCheckboxChange(this.mCheckBoxes[i], aName, false);
                            this.updateCollapse({ toShow: true, scrollInfoView: true });
                        }

                        break;
                }

                return;
            }
        }
    }
    //__________________________________________________________________________________________
    // public getData() {
    //     return this.mNamesArray != null ? this.mNamesArray.toString() : '';
    // }
    //__________________________________________________________________________________________
}
