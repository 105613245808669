import { EventManager } from "../../../../../oc/events/EventManager";
import { ePARAM_TYPE } from "../../../../_context/Enums";
import { EventsContext } from "../../../../_context/EventsContext";
import { MathContext } from "../../../../_context/MathContext";
import { MessagesHandler } from "../../../../_context/MessagesHandler";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { OpticsContext, eOpticsTypeNames } from "../../../../_context/OpticsContext";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../../_utils/OP3DMathUtils";
import { Op3dUtils } from "../../../../_utils/Op3dUtils";
import { OpticUtils } from "../../../../_utils/OpticUtils";
import { iOpticsVO, iOpticsVOGeometry } from "../../../../data/VO/OpticsVOInterfaces";
import { ParserContext } from "../../../../parser/ParserContext";
import { UnitHandler } from "../../../../units/UnitsHandler";
import { ViewUtils } from "../../../ViewUtils";
import { Popup } from "../../Popup";
import { iParamInfo } from "../uoGeometricalInfo";
import { uoSection } from "../uoSection";
import { uoPolarizingElementInfo } from "./uoPolarizingElementInfo";

export class uoGeometricalInfoNew extends uoSection<iOpticsVO, iOpticsVOGeometry> {

    private static SKIN_PATH = './skins/forms/optics/uo_geometrial_info.html'

    private static PARAMS_INFO: iHash<iParamInfo<iOpticsVO>> = {
        a: {
            name: 'A',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        b: {
            name: 'B',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        c: {
            name: 'C',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        d: {
            name: 'D',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        e: {
            name: 'E',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        f: {
            name: 'F',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        g: {
            name: 'G',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        h: {
            name: 'H',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        j: {
            name: 'J',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        l: {
            name: 'L',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        k: {
            name: 'k',
            type: ePARAM_TYPE.NON_DIMENTIONAL_NUMBER
        },
        t: {
            name: 'T',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        x: {
            name: 'x',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            show: () => false
        },
        r: {
            name: 'R',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        r1: {
            name: 'R1',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            onChange: (pData) => {
                let aDiameter = $(pData.parent).find('input[param_key="diameter"]')[0] as HTMLInputElement;
                if (null == aDiameter) {
                    return;
                }

                let aRadius = parseFloat(aDiameter.value) / 2;
                let aCurvature = Math.abs(parseFloat(pData.element.value));
                if (aCurvature != 0 && aRadius > aCurvature) {
                    // curvature can be 0 because this is our way to make a planar surface 
                    pData.element.value = aRadius;
                    Popup.instance.open({
                        text: MessagesHandler.RADIUS_CURVATURE_ERROR
                    });
                }

            }
        },
        r2: {
            name: 'R2',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            onChange: (pData) => {
                let aDiameter = $(pData.parent).find('input[param_key="diameter"]')[0] as
                    HTMLInputElement;
                if (null == aDiameter) {
                    return;
                }

                let aRadius = parseFloat(aDiameter.value) / 2;
                let aCurvature = Math.abs(parseFloat(pData.element.value));
                if (aCurvature != 0 && aRadius > aCurvature) {
                    // curvature can be 0 because this is our way to make a planar surface 
                    pData.element.value = aRadius;
                    Popup.instance.open({
                        text: MessagesHandler.RADIUS_CURVATURE_ERROR
                    });
                }
                this
            },
        },
        r3: {
            name: 'R3',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        r4: {
            name: 'R4',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        r1_x: {
            name: 'R1(x)',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        r1_y: {
            name: 'R1(y)',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        a2: {
            name: 'A2',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a3: {
            name: 'A3',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a4: {
            name: 'A4',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a5: {
            name: 'A5',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a6: {
            name: 'A6',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a7: {
            name: 'A7',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a8: {
            name: 'A8',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a9: {
            name: 'A9',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a10: {
            name: 'A10',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a11: {
            name: 'A11',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a12: {
            name: 'A12',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a13: {
            name: 'A13',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a14: {
            name: 'A14',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a15: {
            name: 'A15',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        a16: {
            name: 'A16',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            toFixed: false
        },
        radius_of_curvature: {
            name: 'Radius of curvature',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        length_of_face: {
            name: 'Length of Face',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        length: {
            name: 'Length',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        length_of_legs: {
            name: 'Length of legs',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        length_of_hypotenuse: {
            name: 'Length of hypotenuse',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            show: () => false
        },
        dimensions: {
            name: 'dimensions',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        dimension_1: {
            name: 'Dimension 1',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        dimension_2: {
            name: 'Dimension 2',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_center: {
            name: 'Thickness center',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_center_1: {
            name: 'Thickness center 1',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_center_2: {
            name: 'Thickness center 2',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_center_3: {
            name: 'Thickness center 3',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_center_a: {
            name: 'Thickness center A',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_center_b: {
            name: 'Thickness center B',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        thickness_edge: {
            name: 'Thickness edge',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            show: (pOpticsVOGeometry) =>
                (null == pOpticsVOGeometry.parameters.geometry.thickness_center)
        },
        thickness: {
            name: 'Thickness',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            onChange: () => {
                uoPolarizingElementInfo.CHANGED_PARAM = null;
            }
        },
        off_axis_angle: {
            name: 'Off-axis angle',
            type: ePARAM_TYPE.ANGLE_DEG
        },
        alpha: {
            name: 'α',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        beta: {
            name: 'β',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        phi: {
            name: ParserContext.SPECIAL_CHARACTERS.phi_small,
            type: ePARAM_TYPE.ANGLE_RAD
        },
        theta: {
            name: ParserContext.SPECIAL_CHARACTERS.theta_small,
            type: ePARAM_TYPE.ANGLE_RAD
        },
        angle_a: {
            name: 'Angle A',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        angle_b: {
            name: 'Angle B',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        angle_c: {
            name: 'Angle C',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        angle_d: {
            name: 'Angle D',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        theta1: {
            name: 'ϑ1',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        theta2: {
            name: 'ϑ2',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        wedge_angle: {
            name: 'Wedge Angle',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        deviation_angle: {
            name: 'Deviation Angle',
            type: ePARAM_TYPE.ANGLE_RAD
        },
        diameter: {
            name: 'Diameter',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
        },
        paraxialEFL: {
            name: 'EFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER
        },
        innerRadius: {
            name: 'Inner radius',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            show: () => false
        },
        outerRadius: {
            name: 'Outer radius',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            show: () => false
        },
        width: {
            name: 'Width',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
        },
        cube_side: {
            name: 'Cube side',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
        },
        height: {
            name: 'Height',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
        },
        pfl: {
            name: 'PFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            onChange: (pData) => {
                let aR = $(pData.parent).find('input[param_key="radius_of_curvature"]')[0] as
                    HTMLInputElement;
                if (null == aR) {
                    return;
                }

                aR.value = OP3DMathUtils.toFixed((2 * parseFloat(pData.element.value)),
                    Op3dContext.SETUPS_MANAGER.settings.numericAccuracy);
            }
        }
    }

    private static NON_DB_PARAMS_INFO: iHash<iParamInfo<iOpticsVO>> = {
        efl: {
            name: 'EFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aEFL = await OpticUtils.CALC_EFL(pOpticsVO, aWavelength);
                if (null == aEFL) {
                    return 0;
                }

                aEFL *= UnitHandler.presentedScale;
                return aEFL;
            },
            show: (pOpticsVO) => this._isShowEFL_FFL(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'EFL';
                if (pOpticsVO.parameters.type != eOpticsTypeNames.MIRROR) {
                    aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';
                }

                return aTitle;
            },
            isEnabled: false
        },
        bfl: {
            name: 'BFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aBFL = await OpticUtils.CALC_BFL(pOpticsVO, aWavelength);
                aBFL *= UnitHandler.presentedScale;

                return aBFL;
            },
            show: (pOpticsVO) => this._isShowBFL(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'BFL';
                aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';

                return aTitle;
            },
            isEnabled: false
        },
        ffl: {
            name: 'FFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aFFL = await OpticUtils.CALC_FFL(pOpticsVO, aWavelength);
                aFFL *= UnitHandler.presentedScale;

                return aFFL;
            },
            show: (pOpticsVO) => this._isShowEFL_FFL(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'FFL';
                aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';

                return aTitle;
            },
            isEnabled: false
        },
        efl_x: {
            name: 'EFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aEFL = (await OpticUtils.CALC_EFL_CYLINDRICAL(pOpticsVO, aWavelength)).x;
                if (null == aEFL) {
                    return 0;
                }

                aEFL *= UnitHandler.presentedScale;
                return aEFL;
            },
            show: (pOpticsVO) => this._isShowCylindricalFoucuses(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'EFL(x)';
                if (pOpticsVO.parameters.type != eOpticsTypeNames.MIRROR) {
                    aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';
                }

                return aTitle;
            },
            isEnabled: false
        },
        bfl_x: {
            name: 'BFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aBFL = (await OpticUtils.CALC_BFL_CYLINDRICAL(pOpticsVO, aWavelength)).x;
                aBFL *= UnitHandler.presentedScale;

                return aBFL;
            },
            show: (pOpticsVO) => this._isShowCylindricalFoucuses(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'BFL(x)';
                aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';

                return aTitle;
            },
            isEnabled: false
        },
        ffl_x: {
            name: 'FFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aFFL = (await OpticUtils.CALC_FFL_CYLINDRICAL(pOpticsVO, aWavelength)).x;
                aFFL *= UnitHandler.presentedScale;

                return aFFL;
            },
            show: (pOpticsVO) => this._isShowCylindricalFoucuses(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'FFL(x)';
                aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';

                return aTitle;
            },
            isEnabled: false
        },
        efl_y: {
            name: 'EFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aEFL = (await OpticUtils.CALC_EFL_CYLINDRICAL(pOpticsVO, aWavelength)).y;
                if (null == aEFL) {
                    return 0;
                }

                aEFL *= UnitHandler.presentedScale;
                return aEFL;
            },
            show: (pOpticsVO) => this._isShowCylindricalFoucuses(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'EFL(y)';
                if (pOpticsVO.parameters.type != eOpticsTypeNames.MIRROR) {
                    aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';
                }

                return aTitle;
            },
            isEnabled: false
        },
        bfl_y: {
            name: 'BFL',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aBFL = (await OpticUtils.CALC_BFL_CYLINDRICAL(pOpticsVO, aWavelength)).y;
                aBFL *= UnitHandler.presentedScale;

                return aBFL;
            },
            show: (pOpticsVO) => this._isShowCylindricalFoucuses(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'BFL(y)';
                aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';

                return aTitle;
            },
            isEnabled: false
        },
        ffl_y: {
            name: 'FFL(y)',
            type: ePARAM_TYPE.DIMENTIONAL_NUMBER,
            calculationFunc: async (pOpticsVO) => {
                let aWavelength = pOpticsVO.parameters.wavelength;
                let aFFL = (await OpticUtils.CALC_FFL_CYLINDRICAL(pOpticsVO, aWavelength)).y;
                aFFL *= UnitHandler.presentedScale;

                return aFFL;
            },
            show: (pOpticsVO) => this._isShowCylindricalFoucuses(pOpticsVO),
            titleFunc: (pOpticsVO) => {
                let aTitle = 'FFL';
                aTitle += '<sub>λ=' + pOpticsVO.parameters.wavelength + '</sub>';

                return aTitle;
            },
            isEnabled: false
        }
    }

    private mOneParamElement: HTMLElement;
    private mParamsParent: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super(pContainer, {
            skinPath: uoGeometricalInfoNew.SKIN_PATH,
            title: 'Optics Geometrical Properties',
            collapseQaId: "uo_geometrial_info_section_collapse_qa_id",
            isNewSkin: true,
            isPremiumSection: true,
            isAllSectionHidden: true
        });
    }
    //__________________________________________________________________________________________
    protected async _setData(pOpticsVO: iOpticsVO) {
        this._clear();
        this._fillData(pOpticsVO);

        switch (pOpticsVO.parameters.subType) {
            case OpticsContext._User_Aperture:

                this.show(false);
                break;
            default:
                this.show(true);
                break;
        }

    }
    //__________________________________________________________________________________________
    private static _isShowEFL_FFL(pOpticsVO: iOpticsVO) {
        let aCond1 = OpticsContext.isConic(pOpticsVO.parameters.shape);
        let aCond2 = pOpticsVO.parameters.subType != OpticsContext._Multiplets;
        return aCond1 === true && aCond2 === true;
    }
    //__________________________________________________________________________________________
    private static _isShowBFL(pOpticsVO: iOpticsVO) {
        let aCond1 = OpticsContext.isSphericalToShow(pOpticsVO.parameters.subType);
        let aCond2 = pOpticsVO.parameters.subType != OpticsContext._Multiplets;
        return aCond1 === true && aCond2 === true;
    }
    //__________________________________________________________________________________________
    private static _isShowCylindricalFoucuses(pOpticsVO: iOpticsVO) {
        let aCond1 = OpticsContext.isCylindrical(pOpticsVO.parameters.subType);
        let aCond2 = pOpticsVO.parameters.subType != OpticsContext._Multiplets;
        return aCond1 === true && aCond2 === true;
    }
    //__________________________________________________________________________________________
    private _fillData(pOpticsVO: iOpticsVO) {
        this._fillDBData(pOpticsVO);
        this._fillNonDBData(pOpticsVO);
    }
    //__________________________________________________________________________________________
    private _fillDBData(pOpticsVO: iOpticsVO) {
        let aGeometry = pOpticsVO.parameters.geometry;
        for (let key in aGeometry) {
            let aParamInfo = uoGeometricalInfoNew.PARAMS_INFO[key];
            if (null == aParamInfo) {
                continue;
            }

            if ((null != aParamInfo.show) && (false == aParamInfo.show(pOpticsVO))) {
                continue;
            }

            this._addOneItem(pOpticsVO, aParamInfo, key);
        }
    }
    //__________________________________________________________________________________________
    private _fillNonDBData(pOpticsVO: iOpticsVO) {
        for (let key in uoGeometricalInfoNew.NON_DB_PARAMS_INFO) {
            let aParamInfo = uoGeometricalInfoNew.NON_DB_PARAMS_INFO[key];
            if ((null != aParamInfo.show) && (false == aParamInfo.show(pOpticsVO))) {
                continue;
            }

            this._addOneItem(pOpticsVO, aParamInfo, key);
        }
    }
    //__________________________________________________________________________________________
    private async _addOneItem(pData: iOpticsVO, pParamInfo: iParamInfo<iOpticsVO>, pKey: string) {
        let aGeometry = pData.parameters.geometry;
        let aOneParam = this.mOneParamElement.cloneNode(true) as HTMLElement;
        this.mParamsParent.appendChild(aOneParam);
        let aItemLabel = Op3dUtils.getElementIn(aOneParam, 'param_name');
        aItemLabel.innerHTML = (null != pParamInfo.titleFunc) ? pParamInfo.titleFunc(pData) :
            pParamInfo.name;

        let aUnitParam = Op3dUtils.getElementIn(aOneParam, 'unit_span');
        switch (pParamInfo.type) {
            case ePARAM_TYPE.DIMENTIONAL_NUMBER:
                aUnitParam.innerHTML = UnitHandler.shortSign;
                break;
            case ePARAM_TYPE.ANGLE_RAD:
            case ePARAM_TYPE.ANGLE_DEG:
                aUnitParam.innerHTML = 'Deg';
                break;
            default:
                ViewUtils.removeFromParent(aUnitParam);
                break;
        }

        let aScale = UnitHandler.presentedScale;
        let aValue: number;
        if (null != pParamInfo.calculationFunc) {
            aValue = await pParamInfo.calculationFunc(pData)
        } else {
            switch (pParamInfo.type) {
                case ePARAM_TYPE.DIMENTIONAL_NUMBER:
                    aValue = (aGeometry[pKey] * aScale);
                    break;
                case ePARAM_TYPE.ANGLE_RAD:
                    aValue = (aGeometry[pKey] * MathContext.RAD_TO_DEG);
                    break;
                default:
                    aValue = aGeometry[pKey];
                    break;
            }
        }

        const aToFixed = Op3dContext.SETUPS_MANAGER.settings.numericAccuracy;
        let aInput = Op3dUtils.getElementIn(aOneParam, 'param_input') as HTMLInputElement;
        if (false == pParamInfo.toFixed) {
            aInput.value = aValue.toString();
        } else {
            aInput.value = OP3DMathUtils.toFixed(aValue, aToFixed);
        }
        aInput.title = aInput.value;

        if (false == pParamInfo.isEnabled) {
            aInput.readOnly = (false == pParamInfo.isEnabled);
        } else {
            aInput.setAttribute('param_key', pKey);
            aInput.setAttribute('qa_id', pKey);
            aInput.addEventListener('change', () => {
                if (false != pParamInfo.toFixed) {
                    aInput.value = OP3DMathUtils.toFixed(parseFloat(aInput.value), aToFixed);
                }

                if (null != pParamInfo.onChange) {
                    pParamInfo.onChange({
                        element: aInput,
                        parent: this.mParamsParent
                    });
                }

                this._onChange();
            });
        }
    }
    //__________________________________________________________________________________________
    private _onChange(pIsExternall: boolean = false) {
        EventManager.dispatchEvent(EventsContext.OPTICS_VO_GEO_DATA_CHANGED, this, pIsExternall);
    }
    //__________________________________________________________________________________________
    public fillObject(pOpticsVO: iOpticsVO): void {
        let aGeo = this.getData();
        pOpticsVO.parameters.geometry = aGeo;
    }
    //__________________________________________________________________________________________
    public getData() {
        let aData: iOpticsVOGeometry = {

        };

        let aScale = UnitHandler.presentedScale;
        $(this.mParamsParent).find('input[param_key]').each(
            (_index, element) => {
                let aKey = element.getAttribute('param_key');

                let aParamInfo = uoGeometricalInfoNew.PARAMS_INFO[aKey];
                let aValue = parseFloat((element as HTMLInputElement).value);
                switch (aParamInfo.type) {
                    case ePARAM_TYPE.DIMENTIONAL_NUMBER:
                        aValue /= aScale;
                        break;
                    case ePARAM_TYPE.ANGLE_RAD:
                        aValue *= MathContext.DEG_TO_RAD;
                        break;
                    default:
                        break;
                }

                aData[aKey] = aValue;
            }
        );

        return aData;
    }
    //__________________________________________________________________________________________
    private _clear() {
        ViewUtils.removeElementChildren(this.mParamsParent);
    }

    //__________________________________________________________________________________________
    protected async _initElements() {
        this.mOneParamElement = Op3dUtils.getElementIn(this.mContainer, 'one_param');
        this.mParamsParent = this.mOneParamElement.parentElement;
        this._clear();
    }
    //__________________________________________________________________________________________
    protected _addEventListeners(): void {
    }
    //__________________________________________________________________________________________
    public updateLensThicknessExternally(pThicknessValue: number): void { 
        $(this.mParamsParent).find('input[param_key]').each(
            (_index, element) => {
                let aKey = element.getAttribute('param_key');
                if(aKey === 'thickness') {
                    (element as undefined as HTMLInputElement).value = pThicknessValue.toString();
                }
            }
        );
        this._onChange()
    }
    //__________________________________________________________________________________________
}
