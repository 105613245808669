import { Object3D, Mesh } from "three";
import { Op3dContext } from "../_context/Op3dContext";
import { iClientPoint } from "../_context/_interfaces/Interfaces";
import { Part } from "../parts/Part";

export class OptixPartUtils {

    //__________________________________________________________________________________________
    public static getGroupOfMesh(pObject: Object3D) {
        if (pObject == null) {
            return null;
        }

        if (pObject.parent == null) {
            return null;
        }
    }
    //__________________________________________________________________________________________
    public static isInBoundingRect(pEvent: iClientPoint, pContainer: HTMLElement) {
        let aBounding = pContainer.getBoundingClientRect();
        if (pEvent.clientX < aBounding.left ||
            pEvent.clientX > aBounding.right ||
            pEvent.clientY < aBounding.top ||
            pEvent.clientY > aBounding.bottom) {
            return false;
        }
        return true;
    }
    //__________________________________________________________________________________________
    /**
     * @description - this function sorts an array fo optix part by a logical insert order
     * @param pArr  - array of optix part to sort
     * @returns  - an sorted array of optix part
     */
    //________________________________________________________________________
    public static sortOptixParts(pArr: Array<Part>) {
        pArr.sort((_a, _b) => {
            return -1;
        });

        return pArr;
    }
    //__________________________________________________________________________________________
    private static _distractChild(pObj: Object3D) {
        if (pObj instanceof Mesh) {
            if (pObj.material instanceof Array) {
                while (pObj.material.length > 0) {
                    pObj.material.pop().dispose();
                }
            } else {
                pObj.material.dispose();
            }

            if (pObj.geometry) {
                pObj.geometry.dispose();
            }
        }

        if (pObj.parent) {
            pObj.parent.remove(pObj);
        }
    }
    //__________________________________________________________________________________________
    public static smartMathRound(pNum: number) {

        return Math.ceil(pNum);


    }
    //__________________________________________________________________________________________
    public static getOptixPartOfMesh(pObject: Object3D) {

        if (pObject == null) {
            return null
        }

        let aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            if (aParts[i].isBelongToPart(pObject)) {
                return aParts[i];
            }
        }

        return null;
    }
    //__________________________________________________________________________________________
    public static distractPart(pObject: Object3D) {
        if (pObject == null) {
            return;
        }

        pObject.traverse((pObj) => this._distractChild(pObj));

        if (pObject.parent) {
            pObject.parent.remove(pObject);
        }
    }
    //__________________________________________________________________________________________
}
