import { OpticsContext } from "../../_context/OpticsContext";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { iGratingSectionData, iGratingRangeData } from "../../simulation/SimulationContext";
import { ViewUtils } from "../ViewUtils";
import { PartInfoSection } from "./PartInfoSection";
import { NumberInputElement } from "./_components/NumberInputElement";

export class piGratingSection extends PartInfoSection<iGratingSectionData> {

    private mOnChange: (pData: iGratingRangeData) => void;
    private mElements: iHash<NumberInputElement>;

    private mTransmitionParams: HTMLElement;
    private mReflectionParams: HTMLElement;

    constructor(pContainer: HTMLElement, pOnChange: (pData: iGratingRangeData) => void) {
        super({
            container: pContainer,
            skinPath: './skins/part_info/pi_grating_section.html'
        });

        this.mOnChange = pOnChange;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mElements = {
            min_transmission: this._initOne(this._getPart("min-transmission-order")),
            max_transmission: this._initOne(this._getPart("max-transmission-order")),
            min_reflection: this._initOne(this._getPart("min-reflection-order")),
            max_reflection: this._initOne(this._getPart("max-reflection-order"))
        };

        this.mTransmitionParams = this._getPart('transmition_params');
        this.mReflectionParams = this._getPart('reflection_params');
    }
    //__________________________________________________________________________________________
    private _initOne(pContainer: HTMLElement) {
        let aNumberInputElement = new NumberInputElement(pContainer, {
            isGlobalToFixed: true,
            unitName: "", hasArrows: true,
            triggers: {
                saveHistory: true,
                saveScene: true,
                triggerAnalysis: false
            }
        });

        aNumberInputElement.setData({
            callback: () => this._onOrderChanged(),
            range: { min: -1000, max: 1000 },
            step: 1,
            isInt: true
        });

        return aNumberInputElement;
    }
    //__________________________________________________________________________________________
    private _onOrderChanged() {

        let aGratingData: iGratingRangeData = {
            min_transmission_order: this.mElements["min_transmission"].value,
            max_transmission_order: this.mElements["max_transmission"].value,
            min_reflection_order: this.mElements["min_reflection"].value,
            max_reflection_order: this.mElements["max_reflection"].value
        };

        this.mOnChange(aGratingData);
    }
    //__________________________________________________________________________________________
    protected _fillSection(pData: iGratingSectionData): void {

        let aSubtype = pData.opticsVO.parameters.subType;
        let aShowReflectiveGrating = OpticsContext.isReflectiveGrating(aSubtype);
        // ((OpticsContext._Reflective_Grating == aSubtype) ||
        //     (OpticsContext._Echelle_Grating == aSubtype) ||
        //     (OpticsContext._Blazed_Ruled_Reflective_Grating == aSubtype));

        let aHasTransmission = false == aShowReflectiveGrating
        ViewUtils.setElementVisibilityByDNone(this.mTransmitionParams, aHasTransmission);

        ViewUtils.setElementVisibilityByDNone(this.mReflectionParams, true);
        /** Arkadiy said that reflection order should always be displayed */
        //   (true == aShowReflectiveGrating));

        if (aHasTransmission) {
            this.mElements["min_transmission"].value = pData.min_transmission_order;
            this.mElements["max_transmission"].value = pData.max_transmission_order;
        }
        //else {
        //  this.mElements["min_transmission"].value = 0;
        // this.mElements["max_transmission"].value = -1;
        // this._onOrderChanged();
        // }

        this.mElements["min_reflection"].value = pData.min_reflection_order;
        this.mElements["max_reflection"].value = pData.max_reflection_order;
    };
}
    //__________________________________________________________________________________________
