import { eDataPermission, eLoadingState } from "../../_context/Enums";
import { MessagesHandler } from "../../_context/MessagesHandler";
import { Op3dContext } from "../../_context/Op3dContext";
import { iCoatingVOMetadata, iCoatingVO } from "../../_context/_interfaces/Interfaces";
import { ServerContext } from "../../server/ServerContext";
import { CacheStorage, eCacheDataType } from "../CacheStorage";
import { DataLoader } from "./DataLoader";

export class CoatingDataLoader extends DataLoader<iCoatingVOMetadata,
    { number_id: string }, iCoatingVO, iCoatingVO> {

    private static INSTANCE: CoatingDataLoader;

    private constructor() {
        super({
            cacheData: {
                key: 'number_id',
                type: eCacheDataType.COATING
            },
            // dataConvertionFunc: async (pData: iCoatingVO) => this.fillBlanks(pData),
            fullDataServerCall: (pData: { number_id: string }) =>
                ServerContext.SERVER.getFullCoating(pData),
            addItemServerCall: (pData) =>
                ServerContext.SERVER.addCoating({ data: pData, toReplace: true, permission: eDataPermission.PUBLIC }),
            getNumberIDByName: (pName) =>
                ServerContext.SERVER.getCoatingNumberIDByName({ name: pName })
            //todo
            // metaDataServerCall: (pData: iServerMetaDataQuery) =>
            //     engine.Op3dContext.serverManager.getCoatingMetaData(pData),
            // addItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.addCoating(pData),
            // editItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.addCoating(pData),
            // removeItemServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.removeCoatingSync(pData),
            // isExistServerCall: (pData) =>
            //     engine.Op3dContext.serverManager.isCoatingExists(pData)
        });
    }
    //__________________________________________________________________________________________
    public async getSingleFullData(pData: { number_id: string }, pConverted: boolean = true, pCallback?: Function) {
        try {
            let aToSaveOnCache = (null != this.mDataLoaderParams.cacheData);
            if ((true == aToSaveOnCache) &&
                (null == pData[this.mDataLoaderParams.cacheData.key])) {
                let aErrMsg = 'key does not supply for data in function getFullData(pData: Q)';
                aErrMsg += ' in ' + this.constructor.name;
                throw new Error(aErrMsg);
            }

            if (true == aToSaveOnCache) {
                let aName = pData[this.mDataLoaderParams.cacheData.key];
                let aCacheData = this.getFromCache(aName, pConverted);
                if (aCacheData === eLoadingState.LOADING) {
                    await Op3dContext.wait(() => this.getFromCache(aName, pConverted) !== eLoadingState.LOADING);
                }
                if (!(null === aCacheData || undefined === aCacheData)) {
                    return aCacheData
                }

            }

            CacheStorage.instance.addData(pData[this.mDataLoaderParams.cacheData.key],
                eLoadingState.LOADING, this.mDataLoaderParams.cacheData.type);

            let aServerData = await this.mDataLoaderParams.fullDataServerCall(pData);

            let aData = (null != aServerData && (aServerData as any).data != "") ? aServerData.data : null;
            let aConvertedData: iCoatingVO = aData;

            if ((null != aData) && (true == aToSaveOnCache)) {
                // convert data to C
                aConvertedData = await this.saveToCache(aData, pConverted);
            }

            if (pCallback != null) pCallback(aConvertedData)

            return (aConvertedData as iCoatingVO);

        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM(e as any);
        }
    }
    //__________________________________________________________________________________________
    public async fillBlanks(pData: iCoatingVO) {
        let aParsedCoating: iCoatingVO = {
            name: pData.name,
            owner: pData.owner,
            perElement: pData.perElement,
            number_id: pData.number_id,
            permission: pData.permission,
            R_P: new Array<number>(),
            R_S: new Array<number>(),
            T_P: new Array<number>(),
            T_S: new Array<number>(),
            wl: new Array<number>()
        }

        let aLastRP = pData.R_P[0];
        let aLastRS = pData.R_S[0];
        let aLastTP = pData.T_P[0];
        let aLastTS = pData.T_S[0];

        for (let i = pData.wl[0]; i < pData.wl[pData.wl.length - 1]; i++) {
            aParsedCoating.wl.push(i);

            let aWLMissing = pData.wl.findIndex(item => item === i) === -1;
            if (aWLMissing === true) {
                aParsedCoating.R_P.push(aLastRP);
                aParsedCoating.R_S.push(aLastRS);
                aParsedCoating.T_P.push(aLastTP);
                aParsedCoating.T_S.push(aLastTS);

            } else {
                aLastRP = pData.R_P[i];
                aLastRS = pData.R_S[i];
                aLastTP = pData.T_P[i];
                aLastTS = pData.T_S[i];
            }
        }

        return aParsedCoating;
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == this.INSTANCE) {
            this.INSTANCE = new this();
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
}
