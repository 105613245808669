
import { Vector3, Matrix4, Object3D } from "three";
import { eDataPermission, eUnitType } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { iSideBarMainSection, iSideBarCategory, iSideBarSection } from "../../_context/_interfaces/Interfaces";
import { CADUtils } from "../../_utils/CADUtills";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { iPartVOData } from "../../data/VO/PartVO";
import { PartsDataLoader } from "../../data/data_loader/PartsDataLoader";
import { SceneContext } from "../../scene/SceneContext";
import { ServerContext } from "../../server/ServerContext";
import { Op3dComponentBase } from "../Op3dComponentBase";
import { ViewUtils } from "../ViewUtils";
import { eClickMode } from "../_globals/PartsEventsHandler";
import { UnitHandler } from "../../units/UnitsHandler";
import { Spinner } from "../home/Spinner";
import { Part } from "../../parts/Part";
import { iPartMongoDB, iPart, iAssemblyMongoDB } from "../../parts/PartInterfaces";

export enum ePhase {
    BASE_POINT,
    BASE_DIRECTION,
    SECOND_POINT,
    SECOND_DIRECTION,
    COMPLETED,

}

export interface iHTMLDropdownElement extends HTMLElement {
    value: string;
}

export interface iChoosedPart {
    point: Vector3;
    normal: Vector3;
    part?: Part
}
export class AssemblyMode extends Op3dComponentBase {
    public static ICON_CHEVRON_DOWN: string = ' <i class="icon-chevron-down"></i>';

    private static INSTANCE: AssemblyMode;
    private mBaseDirectionBlock: HTMLInputElement;
    private mSecondPointBlock: HTMLInputElement;
    private mSecondDirectionBlock: HTMLInputElement;
    private mBasePointInput: HTMLInputElement;
    private mBaseDirectionInput: HTMLInputElement;
    private mSecondPointInput: HTMLInputElement;
    private mSecondDirectionInput: HTMLInputElement;
    private mCurrectPhase: ePhase

    private mPartFirst: iChoosedPart = {
        point: new Vector3(),
        normal: new Vector3()
    }
    private mPartSecond: iChoosedPart = {
        point: new Vector3(),
        normal: new Vector3()
    }

    private mApplyBtn: HTMLInputElement;
    private mSaveBtn: HTMLInputElement;
    private mAssemblyPart: Part;
    private mPartSecondMatrix: any;
    private mStatesArray: any[];
    private mIsEdited: boolean;
    private mBasePointBlock: HTMLInputElement;
    private mPartSecondGlobal: Matrix4;
    private mOffsetData: HTMLInputElement;
    mSaveCase: string;
    mSecondAssembly: boolean;
    mPartFirstMatrix: Matrix4;
    mPartFirstGlobal: Matrix4;
    mSectionsSelect: iHTMLDropdownElement;
    mCategorySelect: iHTMLDropdownElement;
    mSubCategorySelect: iHTMLDropdownElement;
    mDropdownTemplate: HTMLElement;
    mSectionsDD: HTMLElement;
    mCategoriesDD: HTMLElement;
    mSubCategoriesDD: HTMLElement;
    mPartsContainer: HTMLElement;
    mSideBarItem: HTMLDivElement;
    mMainSectionsSelect: any;
    mMainSectionsDD: HTMLElement;

    //__________________________________________________________________________________________
    constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/forms/assembly_mode.html'
        });
    }
    //__________________________________________________________________________________________
    public async updateAssembly(pPart: Part, pClonedPartId: string) {
        Spinner.instance.show();
        PartsDataLoader.instance.deleteFromCache(Op3dContext.DATA_MANAGER.getPartVOById(pPart.id).number_id);

        let aPartToAdd = {
            matrix: pPart.iPart.subParts.at(-1).object3D.matrix.clone(),
            number_id: pClonedPartId
        }

        await ServerContext.SERVER.addPartToAssembly({ number_id: Op3dContext.DATA_MANAGER.getPartVOById(pPart.id).number_id, part: aPartToAdd })

        let aNumberID = Op3dContext.DATA_MANAGER.getPartVOById(pPart.id).number_id;
        let aJSONData = pPart.exportToJSONObject();
        let aNewPart =
            await PartsDataLoader.instance.getSingleFullData({
                number_id: aNumberID
            }, false);
        let aNewPartParts =
            await PartsDataLoader.instance.getSingleFullData({
                number_id: aNumberID
            });


        Op3dContext.DATA_MANAGER.addToPartsData(aNewPart)
        let aPartNew = new Part({ id: pPart.id, number_id: aNumberID }).add(aNewPartParts);
        Op3dContext.PARTS_MANAGER.deletePart(pPart);

        aPartNew.initFromJSON(aJSONData, true);
        Spinner.instance.hide()

    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (null == AssemblyMode.INSTANCE) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "false");
            aDiv.style.width = "330px";
            document.getElementById('forms').appendChild(aDiv);

            AssemblyMode.INSTANCE = new AssemblyMode(aDiv);
        }

        return AssemblyMode.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected async _onOpen() {
        if (AssemblyMode.INSTANCE) {
            Op3dContext.PARTS_MANAGER.isToShowVertices(true)
            Op3dContext.PARTS_MANAGER.isToShowEdges(true)
            Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(SceneContext.CHOOSE_MODE.mode);

            this.mCurrectPhase = ePhase.BASE_POINT

            await this._initCategories();
            await this._initSelectBars();
        }
    }
    //__________________________________________________________________________________________
    public setPartToConnect(pPart) {
        this.mPartSecond.part = pPart

        this.mPartSecondMatrix = (pPart.visibleObj as Object3D).matrix.clone()

        if (!this.mIsEdited) {
            this.mPartSecondGlobal = (pPart.visibleObj as Object3D).matrix.clone()
        }

    }
    //__________________________________________________________________________________________
    public setPartMain(pPart) {
        this.mPartFirst.part = pPart

        this.mPartFirstMatrix = (pPart.visibleObj as Object3D).matrix.clone()
        // }
        if (!this.mIsEdited) {
            this.mPartFirstGlobal = (pPart.visibleObj as Object3D).matrix.clone()
        }
    }
    //__________________________________________________________________________________________
    public currentPhase() {
        return this.mCurrectPhase
    }
    //__________________________________________________________________________________________
    private _isToDisableFields(pFields: Array<HTMLElement>, pToDisable: boolean) {
        pFields.forEach(element => pToDisable ? element.classList.add('disabled') : element.classList.remove('disabled'))
    }
    //__________________________________________________________________________________________
    public _setActiveField(pCoords: Vector3) {
        switch (this.mCurrectPhase) {
            case ePhase.BASE_POINT:
                this.mBasePointInput.value = `x: ${pCoords.x.toFixed(2)} y: ${pCoords.y.toFixed(2)} z: ${pCoords.z.toFixed(2)}`
                this.mPartFirst.point = pCoords.clone()
                this.mStatesArray[0].classList.remove('d-none')
                if (this.mIsEdited == true) {
                    this.mCurrectPhase = ePhase.COMPLETED
                    this.mIsEdited = false
                    this._isToDisableFields([this.mBaseDirectionBlock, this.mSecondPointBlock, this.mSecondDirectionBlock], false)
                    return
                }
                this.mCurrectPhase = ePhase.BASE_DIRECTION
                this.mBaseDirectionBlock.classList.remove('disabled')

                break
            case ePhase.BASE_DIRECTION:

                this.mBaseDirectionInput.value = `x: ${pCoords.x.toFixed(2)} y: ${pCoords.y.toFixed(2)} z: ${pCoords.z.toFixed(2)}`
                this.mPartFirst.normal = pCoords.clone()
                this.mStatesArray[1].classList.remove('d-none')
                if (this.mIsEdited == true) {
                    this.mCurrectPhase = ePhase.COMPLETED
                    this.mIsEdited = false
                    this._isToDisableFields([this.mBasePointBlock, this.mSecondPointBlock, this.mSecondDirectionBlock], false)
                    return
                }
                this.mCurrectPhase = ePhase.SECOND_POINT
                this.mSecondPointBlock.classList.remove('disabled')
                break
            case ePhase.SECOND_POINT:

                this.mSecondPointInput.value = `x: ${pCoords.x.toFixed(2)} y: ${pCoords.y.toFixed(2)} z: ${pCoords.z.toFixed(2)}`
                this.mPartSecond.point = pCoords.clone()
                this.mStatesArray[2].classList.remove('d-none')
                if (this.mIsEdited == true) {
                    this.mCurrectPhase = ePhase.COMPLETED
                    this.mIsEdited = false
                    this._isToDisableFields([this.mBasePointBlock, this.mBaseDirectionBlock, this.mSecondDirectionBlock], false)
                    return
                }
                this.mCurrectPhase = ePhase.SECOND_DIRECTION
                this.mSecondDirectionBlock.classList.remove('disabled')

                break
            case ePhase.SECOND_DIRECTION:
                this.mSecondDirectionInput.value = `x: ${pCoords.x.toFixed(2)} y: ${pCoords.y.toFixed(2)} z: ${pCoords.z.toFixed(2)}`
                this.mPartSecond.normal = pCoords.clone()
                this.mStatesArray[3].classList.remove('d-none')
                if (this.mIsEdited == true) {
                    this.mCurrectPhase = ePhase.COMPLETED
                    this.mIsEdited = false
                    this._isToDisableFields([this.mBasePointBlock, this.mBaseDirectionBlock, this.mSecondPointBlock], false)
                    return
                }
                this.mApplyBtn.classList.remove('disabled')

                this.mCurrectPhase = ePhase.COMPLETED
                break
        }

    }
    //__________________________________________________________________________________________
    private leaveAssemblyMode() {
        Op3dContext.PARTS_MANAGER.onMouseDown(undefined, undefined)

        // Op3dContext.PARTS_MANAGER.isToShowVertices(false)
        Op3dContext.PARTS_MANAGER.isToShowEdges(false)

        SceneContext.CHOOSE_MODE.mode = eClickMode.BASE
        Op3dContext.PARTS_EVENTS_HANDLER.resetHighlighting()

        Op3dContext.INPUT_DISPATCHER.clickManager.changeRaycastTargets(eClickMode.BASE);
    }
    //__________________________________________________________________________________________
    protected _onClose() {
        this._isToDisableFields([this.mSecondDirectionBlock, this.mBaseDirectionBlock, this.mSecondPointBlock], true)
        this._isToDisableFields([this.mBasePointBlock], false)
        this.mBasePointInput.value = ''
        this.mSecondPointInput.value = ''
        this.mBaseDirectionInput.value = ''
        this.mSecondDirectionInput.value = ''

        this.mIsEdited = false

        this.mStatesArray.forEach(el => el.classList.add('d-none'))
        this.mCurrectPhase = ePhase.BASE_POINT

        this.mApplyBtn.classList.add('disabled')
        this.leaveAssemblyMode()

    }

    //__________________________________________________________________________________________
    private _onCloseSaveWindow() {
        this._getPart('main_window').classList.remove('d-none')
        this._getPart('save_window').classList.add('d-none')
        this.mSaveCase = undefined
    }
    //__________________________________________________________________________________________
    private _openSaveWindow(pSaveCase: string, pAssemName?: Part) {
        this.applyMatrix();
        let aNameField = (this._getPart('assembly_name') as HTMLInputElement)
        let aDescriptionField = (this._getPart('assembly_description') as HTMLInputElement)

        if (pSaveCase == 'UPDATE') {
            aNameField.value = pAssemName.id;
            aDescriptionField.value = Op3dContext.DATA_MANAGER.getPartVOById(pAssemName.id).name;
            // aDescriptionField.value = pAssemName.iPart.partVO.name;
            aNameField.disabled = true
            aDescriptionField.disabled = true
        } else if (pSaveCase == 'SAVE') {
            aNameField.value = Op3dUtils.idGenerator();
        }


        this._getPart('main_window').classList.add('d-none')
        this._getPart('save_window').classList.remove('d-none')
        this.mSaveCase = pSaveCase
    }
    //__________________________________________________________________________________________
    private async _recreateFromMongo(pPart: iPartMongoDB) {
        let aJSONData = this.mAssemblyPart.exportToJSONObject();
        let aNumberID = pPart.number_id;
        let aNewPart =
            await PartsDataLoader.instance.getSingleFullData({
                number_id: aNumberID
            });

        let aPartNew = new Part({
            id: this.mAssemblyPart.id + '_Assembly',
            number_id: aNumberID
        }).add(aNewPart);
        // aPartNew.iPart.partVO = pPart.iPart.partVO
        Op3dContext.PARTS_MANAGER.deletePart(this.mAssemblyPart);
        Op3dContext.DATA_MANAGER.addToPartsData(pPart)
        aPartNew.initFromJSON(aJSONData, true);
    }
    //__________________________________________________________________________________________
    private async _updateAssemblyPart(pGroupToUpdate, pPartFirst: Part, pPartSecond: Part) {
        this.mAssemblyPart = pPartFirst


        pGroupToUpdate.object3D.position.copy(pPartSecond.visibleObj.position)
        pGroupToUpdate.object3D.rotation.copy(pPartSecond.visibleObj.rotation)

        this.mAssemblyPart.visibleObj.worldToLocal(pGroupToUpdate.object3D.position)

        this.mAssemblyPart.add(pGroupToUpdate)
        this.mAssemblyPart.iPart.subParts.at(-1).object3D.updateMatrix();

        let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(pPartSecond.id)

        let aPartClone = await ServerContext.SERVER.copyPart({ number_id: aPartVO.number_id, inAssembly: true })


        if (aPartClone.data.info.id == 'rod' || aPartClone.data.info.id == 'cage-rod') {
            // pPartSecond.iPart.name = 'rod'
            this.mAssemblyPart.iPart.subParts.at(-1).name = 'rod'
        }

        Op3dContext.PARTS_MANAGER.deletePart(pPartSecond)

        let aPartsData = PartsDataLoader.instance.getFromCache(Op3dContext.DATA_MANAGER.getPartVOById(this.mAssemblyPart.id).number_id, false) as iPartMongoDB;
        if (aPartsData == null) {
            aPartsData = await (await ServerContext.SERVER.getPartById({ number_id: Op3dContext.DATA_MANAGER.getPartVOById(this.mAssemblyPart.id).number_id })).data
        }
        // let aPartsData = PartsDataLoader.instance.getFromCache(this.mAssemblyPart.iPart.partVO.number_id, false) as
        //     iPartMongoDB;


        let aPermission = aPartsData.permission
        let aIsOwner = aPartsData.owner && aPartsData.owner == Op3dContext.USER_VO.id

        switch (aPermission) {
            case eDataPermission.PRIVATE:
                if (aIsOwner == true) {
                    this.updateAssembly(this.mAssemblyPart, aPartClone.data.number_id)
                } else {
                    let aClonedAssembly = await ServerContext.SERVER.copyAssembly({ number_id: aPartsData.number_id });
                    let aPartToAdd = {
                        matrix: this.mAssemblyPart.iPart.subParts.at(-1).object3D.matrix.clone(),
                        number_id: aPartClone.data.number_id
                    }
                    await ServerContext.SERVER.addPartToAssembly({ number_id: aClonedAssembly.data.number_id, part: aPartToAdd })
                    this._recreateFromMongo(aClonedAssembly.data)
                }
                break;
            case eDataPermission.PUBLIC:
                if (Op3dContext.USER_VO.isAdmin == true) {
                    this.updateAssembly(this.mAssemblyPart, aPartClone.data.number_id)
                } else {
                    let aClonedAssembly = await ServerContext.SERVER.copyAssembly({ number_id: aPartsData.number_id });
                    let aPartToAdd = {
                        matrix: this.mAssemblyPart.iPart.subParts.at(-1).object3D.matrix.clone(),
                        number_id: aPartClone.data.number_id
                    }
                    await ServerContext.SERVER.addPartToAssembly({ number_id: aClonedAssembly.data.number_id, part: aPartToAdd })
                    this._recreateFromMongo(aClonedAssembly.data)
                }
        }
    }
    //__________________________________________________________________________________________
    private async _onSaveToAWS() {
        let aChildGroup1: iPart = this.mPartFirst.part.iPart.subParts[0]
        let aChildGroup2: iPart = this.mPartSecond.part.iPart.subParts[0]

        let aName = (this._getPart('assembly_name') as HTMLInputElement).value
        let aDesription = (this._getPart('assembly_description') as HTMLInputElement).value

        switch (this.mSaveCase) {
            case 'UPDATE':
                if (Op3dContext.DATA_MANAGER.getPartVOById(this.mPartFirst.part.id).isAssembly == true) {
                    this._updateAssemblyPart(aChildGroup2, this.mPartFirst.part, this.mPartSecond.part)
                } else if (Op3dContext.DATA_MANAGER.getPartVOById(this.mPartSecond.part.id).isAssembly == true) {
                    this._updateAssemblyPart(aChildGroup1, this.mPartSecond.part, this.mPartFirst.part)
                }
                break;
            case "SAVE":
                if ((aName == '' || typeof aName !== 'string') && (aDesription == '' || typeof aDesription !== 'string')) return
                aChildGroup1.object3D.position.copy(this.mPartFirst.part.visibleObj.position)
                aChildGroup1.object3D.rotation.copy(this.mPartFirst.part.visibleObj.rotation)
                aChildGroup2.object3D.position.copy(this.mPartSecond.part.visibleObj.position)
                aChildGroup2.object3D.rotation.copy(this.mPartSecond.part.visibleObj.rotation)

                let aPosition = this.mPartFirst.part.visibleObj.position.clone();

                let aNumberID = Op3dUtils.idGenerator().toLowerCase()
                this.mAssemblyPart = new Part({ id: aName, number_id: aNumberID })

                this.mAssemblyPart.visibleObj.position.copy(aPosition)

                aChildGroup1.object3D.position.copy(new Vector3())


                aChildGroup2.object3D.position.sub(aPosition)
                this.mAssemblyPart.visibleObj.worldToLocal(aChildGroup2.object3D.position.clone())

                this.mAssemblyPart.add(aChildGroup1)
                this.mAssemblyPart.add(aChildGroup2)



                Op3dContext.PARTS_MANAGER.deletePart(this.mPartFirst.part)
                Op3dContext.PARTS_MANAGER.deletePart(this.mPartSecond.part)
                this.saveAssembly(aDesription, aNumberID)
                break;
        }

        this._getPart('main_window').classList.remove('d-none')
        this._getPart('save_window').classList.add('d-none')
        this._onClose()
        this.close()
    }
    //__________________________________________________________________________________________

    private resetPosition() {

        if (this.mSecondAssembly == true) {
            let aMatrix = this.mPartFirstGlobal
            let aRotationMatrix = new Matrix4().extractRotation(aMatrix);
            this.mPartFirst.part.visibleObj.rotation.setFromRotationMatrix(aRotationMatrix);
            this.mPartFirst.part.visibleObj.position.setFromMatrixPosition(aMatrix);
            this.mPartFirst.part.visibleObj.scale.setFromMatrixScale(aMatrix);
            this.mSecondAssembly = false
        } else {
            let aMatrix = this.mPartSecondGlobal

            let aRotationMatrix = new Matrix4().extractRotation(aMatrix);
            this.mPartSecond.part.visibleObj.rotation.setFromRotationMatrix(aRotationMatrix);
            this.mPartSecond.part.visibleObj.position.setFromMatrixPosition(aMatrix);
            this.mPartSecond.part.visibleObj.scale.setFromMatrixScale(aMatrix);
        }

        this._isToDisableFields([this.mSecondDirectionBlock, this.mBaseDirectionBlock, this.mSecondPointBlock], true)
        this._isToDisableFields([this.mBasePointBlock], false)

        this.mBasePointInput.value = ''
        this.mBaseDirectionInput.value = ''
        this.mSecondPointInput.value = ''
        this.mSecondDirectionInput.value = ''

        this.mApplyBtn.classList.add('disabled')
        this.mStatesArray.forEach(el => el.classList.add('d-none'))

        this.mCurrectPhase = ePhase.BASE_POINT

        this.mIsEdited == false
        this.mOffsetData.value = '0'

    }
    //__________________________________________________________________________________________
    private applyMatrix() {

        let aFirstPoint = this.mPartFirst.point.clone()
        let aFirstDir = this.mPartFirst.normal.clone()
        let aSecondtPoint = this.mPartSecond.point.clone()
        let aSecondDir = this.mPartSecond.normal.clone()

        if (this.mCurrectPhase != ePhase.COMPLETED) return

        if (Op3dContext.DATA_MANAGER.getPartVOById(this.mPartSecond.part.id).isAssembly == true) {
            UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS ?
                aSecondtPoint.y = aSecondtPoint.y + +this.mOffsetData.value
                :
                aSecondtPoint.y = aSecondtPoint.y + (UnitHandler.IN_TO_MM * +this.mOffsetData.value)

            let aMatrix = CADUtils.mr_PntVctrToPntVctrTransform(aSecondtPoint, aSecondDir, aFirstPoint, aFirstDir)

            this.mPartFirst.part.visibleObj.position.setFromMatrixPosition(this.mPartFirstMatrix.clone())
            this.mPartFirst.part.visibleObj.rotation.setFromRotationMatrix(new Matrix4().extractRotation(this.mPartFirstMatrix.clone()))
            this.mPartFirst.part.visibleObj.applyMatrix4(aMatrix)
            this.mSecondAssembly = true
        } else {
            aFirstPoint.y = (UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS) ?
                (aFirstPoint.y + +this.mOffsetData.value) :
                (aFirstPoint.y + (UnitHandler.IN_TO_MM * +this.mOffsetData.value))

            let aMatrix = CADUtils.mr_PntVctrToPntVctrTransform(aFirstPoint, aFirstDir, aSecondtPoint, aSecondDir,)

            this.mPartSecond.part.visibleObj.position.setFromMatrixPosition(this.mPartSecondMatrix.clone())
            this.mPartSecond.part.visibleObj.rotation.setFromRotationMatrix(new Matrix4().extractRotation(this.mPartSecondMatrix.clone()))

            this.mPartSecond.part.visibleObj.applyMatrix4(aMatrix);
        }
    }
    //__________________________________________________________________________________________
    public setCoords(pCoords: Vector3) {
        this._setActiveField(pCoords)
    }
    //__________________________________________________________________________________________
    public async saveAssembly(pDescription: string, pNumberID: string) {
        let aHasRod = false
        let aHasPost = false
        let aAssemblyParts = []
        for (let i = 0; i < this.mAssemblyPart.iPart.subParts.length; i++) {
            this.mAssemblyPart.iPart.subParts[i].object3D.updateMatrix();

            // let aPartVO = Op3dContext.DATA_MANAGER.getPartVOById(this.mAssemblyPart.iPart.subParts[i].name)
            let aPartVO = this.mAssemblyPart.iPart.subParts[i].partVO

            let aPartClone = await ServerContext.SERVER.copyPart({ number_id: aPartVO.number_id, inAssembly: true })
            if (aPartClone.data.info.id == 'rod' || aPartClone.data.info.id == 'cage-rod') {
                aHasRod = true
                this.mAssemblyPart.iPart.subParts[i].name = 'rod'
            }
            if (aPartClone.data.info.id == 'post') {
                aHasPost = true
            }
            let aToSaveData: iAssemblyMongoDB = {
                number_id: aPartClone.data.number_id,
                matrix: this.mAssemblyPart.iPart.subParts[i].object3D.matrix
            }

            aAssemblyParts.push(aToSaveData)
        }

        let aPartVO: iPartVOData = {
            number_id: pNumberID,
            id: this.mAssemblyPart.id + '_Assembly',
            name: pDescription || 'Some discription',
            label: this.mAssemblyPart.id + '_Assembly',
            url: this.mAssemblyPart.id,
            sideBarPart: true,
            isAssembly: true,
            originalName: this.mAssemblyPart.id,
            main_section: "Assembly",
            section: this.mSectionsSelect.value,
            category: this.mCategorySelect.value,
            subCategory: this.mSubCategorySelect.value,
            laserData: [],
            isEdited: true,
            hasCage: aHasRod,
            hasPost: aHasPost,
            original_url: ''
        }

        let aPartMongo: iPartMongoDB = {
            permission: eDataPermission.PRIVATE,
            number_id: pNumberID,
            name: this.mAssemblyPart.id + '_Assembly',
            assembly_parts: JSON.stringify(aAssemblyParts),
            info: aPartVO,
            owner: Op3dContext.USER_VO.id
        }

        Spinner.instance.show();
        await PartsDataLoader.instance.add(aPartMongo)

        let aNumberID = pNumberID;
        let aJSONData = this.mAssemblyPart.exportToJSONObject();
        let aNewPart =
            await PartsDataLoader.instance.getSingleFullData({
                number_id: aNumberID
            });
        Op3dContext.DATA_MANAGER.addToPartsData(aPartMongo)
        let aPartNew = new Part({
            id: this.mAssemblyPart.id + '_Assembly',
            number_id: aNumberID
        }).add(aNewPart);
        // aPartNew.iPart.partVO = new PartVO(aPartVO)
        Op3dContext.PARTS_MANAGER.deletePart(this.mAssemblyPart);
        aPartNew.initFromJSON(aJSONData, true);

        Spinner.instance.hide();
    }
    //__________________________________________________________________________________________
    private _flipDirection(pPhase: ePhase) {
        if (ePhase.BASE_DIRECTION == pPhase) {
            this.mPartFirst.normal = this.mPartFirst.normal.clone().negate()
            this.mBaseDirectionInput.value = `x: ${this.mPartFirst.normal.x.toFixed(2)} y: ${this.mPartFirst.normal.y.toFixed(2)} z: ${this.mPartFirst.normal.z.toFixed(2)}`

        } else if (ePhase.SECOND_DIRECTION == pPhase) {
            this.mPartSecond.normal = this.mPartSecond.normal.clone().negate()
            this.mSecondDirectionInput.value = `x: ${this.mPartSecond.normal.x.toFixed(2)} y: ${this.mPartSecond.normal.y.toFixed(2)} z: ${this.mPartSecond.normal.z.toFixed(2)}`
        }
        this.applyMatrix()
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this.mBasePointBlock = this._getPart('base-point') as HTMLInputElement;
        this.mBaseDirectionBlock = this._getPart('base-direction') as HTMLInputElement;
        this.mSecondPointBlock = this._getPart('second-point') as HTMLInputElement;
        this.mSecondDirectionBlock = this._getPart('second-direction') as HTMLInputElement;
        this.mBasePointInput = this._getPart('base-point-input') as HTMLInputElement;
        this.mBaseDirectionInput = this._getPart('base-direction-input') as HTMLInputElement;
        this.mSecondPointInput = this._getPart('second-point-input') as HTMLInputElement;
        this.mSecondDirectionInput = this._getPart('second-direction-input') as HTMLInputElement;
        this.mApplyBtn = this._getPart('apply_btn') as HTMLInputElement;
        this.mSaveBtn = this._getPart('save_btn') as HTMLInputElement;
        this._getPart('reset_btn').addEventListener('click', () => this.resetPosition())

        this._getPart('assembly_info_1').addEventListener('click', () => alert('Some INFO stage 1'))
        this._getPart('assembly_info_2').addEventListener('click', () => alert('Some INFO stage 2'))
        this._getPart('assembly_info_3').addEventListener('click', () => alert('Some INFO stage 3'))
        this._getPart('assembly_info_4').addEventListener('click', () => alert('Some INFO stage 4'))

        this._getPart('assembly_flip_1').addEventListener('click', () => this._flipDirection(ePhase.BASE_DIRECTION))
        this._getPart('assembly_flip_2').addEventListener('click', () => this._flipDirection(ePhase.SECOND_DIRECTION))

        this.mOffsetData = this._getPart('assembly_offset') as HTMLInputElement
        this.mOffsetData.value = '0'

        this.mStatesArray = []

        this.mStatesArray.push(this._getPart('assembly_completed_1'), this._getPart('assembly_completed_2'), this._getPart('assembly_completed_3'), this._getPart('assembly_completed_4'))

        this._getPart('assembly_edit_1').addEventListener('click', () => this.editOneField(ePhase.BASE_POINT, this.mStatesArray[0]))
        this._getPart('assembly_edit_2').addEventListener('click', () => this.editOneField(ePhase.BASE_DIRECTION, this.mStatesArray[1]))
        this._getPart('assembly_edit_3').addEventListener('click', () => this.editOneField(ePhase.SECOND_POINT, this.mStatesArray[2]))
        this._getPart('assembly_edit_4').addEventListener('click', () => this.editOneField(ePhase.SECOND_DIRECTION, this.mStatesArray[3]))

        this._getPart('save_assembly_btn').addEventListener('click', () => this._onSaveToAWS())
        this._getPart('save_assembly_close_btn').addEventListener('click', () => this._onCloseSaveWindow())

        UnitHandler.PRESENTED_UNIT == eUnitType.MILLIMETERS ?
            this._getPart('assembly_units_mm').classList.remove('d-none')
            :
            this._getPart('assembly_units_in').classList.remove('d-none')


        this.mApplyBtn.addEventListener('click', () => this.applyMatrix())
        this.mSaveBtn.addEventListener('click', () => this.onSave())



        //!TODO DELETE
        this.mMainSectionsSelect = this._getPart('main-sections-select') as iHTMLDropdownElement;
        this.mSectionsSelect = this._getPart('sections-select') as iHTMLDropdownElement;
        this.mCategorySelect = this._getPart('category-select') as iHTMLDropdownElement;
        this.mSubCategorySelect = this._getPart('subCategory-select') as iHTMLDropdownElement;
        this.mDropdownTemplate = this._getPart('dropdown-item');
        this.mMainSectionsDD = this._getPart('main-section-select-menu');
        this.mSectionsDD = this._getPart('section-select-menu');
        this.mCategoriesDD = this._getPart('category-select-menu');
        this.mSubCategoriesDD = this._getPart('subCategory-select-menu');
        this.mPartsContainer = this._getPart('parts-container');
        this.mSideBarItem = document.getElementById('part-item') as HTMLDivElement;

    }
    //__________________________________________________________________________________________
    private async _initCategories() {
        let aMainSections = await Op3dContext.DATA_MANAGER.getCategories();
        let aMainSection = aMainSections.find((section) => section.name.toLowerCase() == 'assembly')

        this._onMainSectionChanged(aMainSection);
    }
    //__________________________________________________________________________________________
    private _onMainSectionChanged(pSection: iSideBarMainSection) {
        ViewUtils.clearElementsChildren(this.mSectionsDD);
        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pSection.name;

        this.mMainSectionsSelect.innerHTML = aDiv.outerHTML + AssemblyMode.ICON_CHEVRON_DOWN;
        this.mMainSectionsSelect.value = pSection.name;

        let aSections = pSection.sections;
        for (let i = 0; i < aSections.length; i++) {
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aSections[i].name;
            aItem.addEventListener('click', () => this._onSectionChanged(aSections[i]));
            this.mSectionsDD.appendChild(aItem);
        }

        let aSectionInputGroup = this.mSectionsSelect.parentElement.parentElement;
        let aHasSection = (pSection.sections.length > 1);
        ViewUtils.setElementVisibilityByDFlexDNone(aSectionInputGroup, aHasSection);

        this._onSectionChanged(pSection.sections[0]);
    }
    //__________________________________________________________________________________________
    private _onSubcategoryChanged(pSubCategory: string) {
        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pSubCategory;

        this.mSubCategorySelect.innerHTML = aDiv.outerHTML + AssemblyMode.ICON_CHEVRON_DOWN;
        this.mSubCategorySelect.value = pSubCategory;


    }
    //__________________________________________________________________________________________
    private _onCategoryChanged(pCategory: iSideBarCategory) {
        ViewUtils.clearElementsChildren(this.mSubCategoriesDD);

        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pCategory.name;

        this.mCategorySelect.innerHTML = aDiv.outerHTML + AssemblyMode.ICON_CHEVRON_DOWN;
        this.mCategorySelect.value = pCategory.name;

        let aSubCategories = pCategory.subcategories;
        for (let i = 0; i < aSubCategories.length; i++) {
            let aSubCategory = aSubCategories[i];
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aSubCategory;
            aItem.addEventListener('click', () => this._onSubcategoryChanged(aSubCategory));
            this.mSubCategoriesDD.appendChild(aItem);
        }

        let aSubCategoryInputGroup = this.mSubCategoriesDD.parentElement.parentElement;
        let aHasSubCategories = (pCategory.subcategories.length > 1);
        ViewUtils.setElementVisibilityByDFlexDNone(aSubCategoryInputGroup, aHasSubCategories);

        this._onSubcategoryChanged(pCategory.subcategories[0]);
    }
    //__________________________________________________________________________________________
    private async _initSelectBars() {
        let aMainSections = await Op3dContext.DATA_MANAGER.getCategories();
        ViewUtils.removeElementChildren(this.mMainSectionsDD);

        for (let i = 0; i < aMainSections.length; i++) {
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aMainSections[i].name;
            aItem.addEventListener('click', () => this._onMainSectionChanged(aMainSections[i]));
            this.mMainSectionsDD.appendChild(aItem);
        }
    }
    //__________________________________________________________________________________________
    private _onSectionChanged(pSection: iSideBarSection) {
        ViewUtils.clearElementsChildren(this.mCategoriesDD);
        let aDiv = document.createElement('div');
        aDiv.classList.add('ellip_div');
        aDiv.innerHTML = pSection.name;

        this.mSectionsSelect.innerHTML = aDiv.outerHTML + AssemblyMode.ICON_CHEVRON_DOWN;
        this.mSectionsSelect.value = pSection.name;

        let aCategories = pSection.categories;
        for (let i = 0; i < aCategories.length; i++) {
            let aItem: HTMLElement = this.mDropdownTemplate.cloneNode(true) as HTMLElement;
            aItem.innerHTML = aCategories[i].name;
            aItem.addEventListener('click', () => this._onCategoryChanged(aCategories[i]));
            this.mCategoriesDD.appendChild(aItem);
        }

        let aCategoryInputGroup = this.mCategorySelect.parentElement.parentElement;
        let aHasCategories = (pSection.categories.length > 1);
        ViewUtils.setElementVisibilityByDFlexDNone(aCategoryInputGroup, aHasCategories);

        this._onCategoryChanged(pSection.categories[0]);
    }
    //__________________________________________________________________________________________
    private editOneField(pPhase: ePhase, pEditBtn: HTMLElement) {

        if (this.mIsEdited) {
            pEditBtn.classList.remove('d-none')
            this._isToDisableFields([this.mSecondDirectionBlock, this.mBaseDirectionBlock, this.mSecondPointBlock], false)
            this.mCurrectPhase = ePhase.COMPLETED
            this.mIsEdited = false
            return
        }
        pEditBtn.classList.add('d-none')
        this.mIsEdited = true
        this.mCurrectPhase = pPhase

        switch (pPhase) {
            case ePhase.BASE_POINT:
                this._isToDisableFields([this.mSecondDirectionBlock, this.mBaseDirectionBlock, this.mSecondPointBlock], true)
                break;
            case ePhase.BASE_DIRECTION:
                this._isToDisableFields([this.mSecondDirectionBlock, this.mBasePointBlock, this.mSecondPointBlock], true)
                break;
            case ePhase.SECOND_POINT:
                this._isToDisableFields([this.mSecondDirectionBlock, this.mBasePointBlock, this.mBaseDirectionBlock], true)
                break;
            case ePhase.SECOND_DIRECTION:
                this._isToDisableFields([this.mSecondPointBlock, this.mBasePointBlock, this.mBaseDirectionBlock], true)
                break;
        }
    }
    //__________________________________________________________________________________________
    private onSave() {
        if (this.mCurrectPhase == ePhase.COMPLETED) {

            if (Op3dContext.DATA_MANAGER.getPartVOById(this.mPartFirst.part.id)?.isAssembly == true && Op3dContext.DATA_MANAGER.getPartVOById(this.mPartSecond.part.id)?.isAssembly == true) {
                return
            } else if (Op3dContext.DATA_MANAGER.getPartVOById(this.mPartFirst.part.id).isAssembly == true) {
                this.mSaveCase = 'UPDATE'
                this._onSaveToAWS()
            } else if (Op3dContext.DATA_MANAGER.getPartVOById(this.mPartSecond.part.id).isAssembly == true) {
                this.mSaveCase = 'UPDATE'
                this._onSaveToAWS()
            } else {
                this._openSaveWindow('SAVE')
            }

        } else {
            return
        }
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mIsReady = true;
        (<any>$(this.mContainer)).draggable()
    }
    //__________________________________________________________________________________________
}