import { EventManager } from "../../oc/events/EventManager";
import { AnalyticsEventsContext } from "../_context/AnalyticsEventsContext";
import { EventsContext } from "../_context/EventsContext";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { eMouseMode } from "../scene/Op3dScene";
import { SceneContext } from "../scene/SceneContext";
import { SimulationRunner } from "../simulation/SimulationRunner";
import { Op3dComponentBase } from "../ui/Op3dComponentBase";
import { ViewUtils } from "../ui/ViewUtils";
import { AnalysesSynchronizer } from "../ui/analysis/AnalysesSynchronizer";
import { Popup } from "../ui/forms/Popup";
import { LightSourceHeaderList } from "../ui/analysis/components/LightSourceHeaderList";
import { CameraUtils } from "../scene/_camera/CameraUtils";

export class HeaderWidget extends Op3dComponentBase {

    private mShowLinkedPartsButton: HTMLElement;
    private mLightSourceDD: LightSourceHeaderList;

    constructor() {
        super({
            container: document.getElementById('header-container'),
            skinPath: './skins/home/header_widget.html'
        });
    }
    //__________________________________________________________________________________________
    protected async _checkOptVersions() {
        const aOriginalSetupData = await Op3dContext.SETUPS_MANAGER.getOneSetup({ _id: Op3dContext.SETUPS_MANAGER.currSetupID, to_open: false })
        const aOriginalSetupVersion = aOriginalSetupData.data.parameters.setup_version_to_compare;
        const aWidgetSetupVersion = Op3dContext.SETUPS_MANAGER.setupParameters.setup_version_to_compare;
        const aOriginalSimulationVersion = aOriginalSetupData.data.parameters.simulation_version_to_compare;
        const aWidgetSimulationVersion = Op3dContext.SETUPS_MANAGER.setupParameters.simulation_version_to_compare;

        if ((aOriginalSetupVersion == aWidgetSetupVersion && aOriginalSimulationVersion > aWidgetSimulationVersion) ||
            (aOriginalSetupVersion !== aWidgetSetupVersion && aOriginalSimulationVersion == aWidgetSimulationVersion)) {
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = false;
        };
        if ((aOriginalSetupVersion !== aWidgetSetupVersion && aOriginalSimulationVersion > aWidgetSimulationVersion) ||
            aOriginalSimulationVersion == 0) {
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup = true;
            Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis = true;
            Op3dContext.isWidgetAnalysis = true;
            return true;
        }
        return false;
    }
    //__________________________________________________________________________________________
    protected _initElements(): void {
        this._getPart("check-btn").addEventListener("click", () => this._onClickRunAnalysisBtn());
        let aLaserIcon = this._getPart("toggle_all_rays")
        aLaserIcon.addEventListener("click", () => this.toggleLasers(aLaserIcon));
        this._getPart("quick-view-btn").addEventListener("click", () => this._onShowAllAnalysis());
        this._getPart("zoom-in-btn", true).addEventListener("click", () => this._onZoom(1));
        this._getPart("fit-to-zoom", true).addEventListener("click", () => {
            let center = Op3dContext.PARTS_MANAGER.getCenter()
            SceneContext.OP3D_SCENE.lookAt = center

            CameraUtils.fitCameraToCenteredObject(SceneContext.CAMERA, Op3dContext.PARTS_MANAGER.partsContainer, 'BASE')
        });
        this._getPart("zoom-out-btn", true).addEventListener("click", () => this._onZoom(-1));

        let aPanBtn = this._getPart('pan_btn');

        aPanBtn.addEventListener('click', () => this._setMouseMode(eMouseMode.PAN,
            aPanBtn));
        this.mLightSourceDD = new LightSourceHeaderList(document.getElementById("light-source-component2"));

        document.getElementById("laser_selector_dropdown_btn").addEventListener("click",
            () => this.mLightSourceDD.update(0));

    }
    //__________________________________________________________________________________________
    private async _onClickRunAnalysisBtn() {
        AnalysesSynchronizer.instance.getAllFastAnalysisQV()
        const aCheckVersionResult = await this._checkOptVersions();
        await Op3dContext.sleep(50);
        await SimulationRunner.instance.runVisualizationSimulation();
        if (aCheckVersionResult) Popup.instance.open({ text: MessagesHandler.WIDGET_DIFFERRENT_VERSION_WITH_SETUP });
        Op3dContext.isWidgetAnalysis = false;
        AnalyticsEventsContext.triggerEvent(AnalyticsEventsContext.CLICK_PROPAGATION_SIMULATION_WIDGET, "widget");
    }
    //__________________________________________________________________________________________
    private toggleLasers(pIcon: HTMLElement) {
        let aIsAllShown = SimulationRunner.instance.isRaysShown();
        if (true == aIsAllShown) {
            pIcon.classList.replace('hidden', 'shown');
        } else {
            pIcon.classList.replace('shown', 'hidden');
        }
        SimulationRunner.instance.setRaysVisibility(!aIsAllShown);
    }
    //__________________________________________________________________________________________
    private _onShowAllAnalysis() {
        AnalysesSynchronizer.instance.getAllFastAnalysisQV()
    }
    //__________________________________________________________________________________________
    private _setMouseMode(_pMode: eMouseMode, pPanBtn: HTMLElement) {
        let aIsPan = ViewUtils.toggleElementActive(pPanBtn);
        let aMouseMode = (true == aIsPan) ? eMouseMode.PAN : eMouseMode.GENERAL;
        SceneContext.OP3D_SCENE.op3dOrbitController.setMouseMode(aMouseMode);
    }
    //__________________________________________________________________________________________
    public hideLines() {
        ViewUtils.setElementActive(this.mShowLinkedPartsButton, false);
        Op3dContext.PARTS_MANAGER.showLinesVisibility(false);
    }
    //__________________________________________________________________________________________

    private _onZoom(pZoomDelta: number) {
        EventManager.dispatchEvent<number>(EventsContext.ON_ZOOM,
            this, pZoomDelta)
    }
    //__________________________________________________________________________________________
    protected async _onCreationComplete() {
        $(this.mContainer).find('[data-toggle="tooltip"]').tooltip().on('click', function () {
            $(this).blur()
        });

        ViewUtils.makeUnselectable(this.mContainer);
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________

}
