import { ePolarizationSourceType } from "../../../_context/Enums";
import { iPoint2D } from "../../../_context/_interfaces/Interfaces";
import { iAnalysisCacheData } from "../AnalysisCache";
import { iAnalysisItem } from "../AnalysisContext";
import { eExtendedViewType } from "../ResultFactory/anAnalysisResultyFactory";
import { anCrossSectionGraph } from "./anCrossSectionGraph";
import { anSpotGraph } from "./anSpotGraph";

export interface iAnalysisData {
    analysisItem: iAnalysisItem,
    viewType: eExtendedViewType;
    cacheData: iAnalysisCacheData;
    fromMinimize: boolean;
    polarizationKind: ePolarizationSourceType;
    isLogView: boolean;
    originalCacheData: iAnalysisCacheData;
}

export interface iAnalysisSpotData extends iAnalysisData {
    canvas: HTMLCanvasElement;
}

export interface iAnalysisCrossSectionData extends iAnalysisData {
    spotGraph: anSpotGraph;
    pixel: iPoint2D;
}

export type tAnalysisData = iAnalysisSpotData | iAnalysisCrossSectionData;


export class AnalysisViewFactory {

    public static createAnalysis(pOptions: tAnalysisData) {

        switch (pOptions.viewType) {
            case eExtendedViewType.LINE:
                return new anCrossSectionGraph(pOptions as iAnalysisCrossSectionData);

            case eExtendedViewType.TWO_D:
                return new anSpotGraph(pOptions as iAnalysisSpotData);
        }
    }
    //____________________________________________________________________________
}