export function BlackBoxNotification() {
    return <div style={ { padding: '7px' } }>
        <small style={ { display: 'block' } }>
            <ol style={ { marginBottom: '5px' } }>
                <li>Click on parts of the design to add to the black box.</li>
                <li>Define front and back margins and the outer diameter or use the default parameters.</li>
            </ol>
            <p style={ { marginBottom: '5px' } }>Default parameters:</p>
            <ul style={ { marginBottom: '5px', marginLeft: '17px' } }>
                <li>Front margin: 5 mm/ 0.2”</li>
                <li>Back margin: 5 mm/ 0.2”</li>
                <li>Barrel outer diameter: diameter of the biggest object + 2 mm/ 1”</li>
            </ul>
            <p style={ { margin: '5px -10px' } }>
                <strong>The Black box file will open in a separate tab, while your design in
                    the current tab will stay unchanged.
                    It is not possible to “un-black box” a black box.</strong>
            </p>
        </small>
    </div>
}