import { Op3dComponentBase } from "../Op3dComponentBase";

export class ImagePresenter extends Op3dComponentBase {



    private static INSTANCE: ImagePresenter;
    private mImageContainer: HTMLImageElement;

    private constructor(pContainer: HTMLElement) {
        super({
            container: pContainer,
            skinPath: './skins/home/image_presenter.html',
        });
    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            let aDiv = document.createElement('div');
            aDiv.classList.add('modal');
            aDiv.classList.add('fade');
            aDiv.setAttribute("data-backdrop", "false");
            document.getElementById('forms').appendChild(aDiv);
            this.INSTANCE = new ImagePresenter(aDiv);
        }

        return this.INSTANCE;
    }
    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mImageContainer = this._getPart("image-elem") as HTMLImageElement;
        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected async _onOpen(pData?: { image: string }) {

        this.mImageContainer.src = pData.image;
    }
    //__________________________________________________________________________________________
}
