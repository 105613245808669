import { EventBase } from "../../oc/events/EventBase";
import { EventManager } from "../../oc/events/EventManager";
import { AnalyticsEventsContext } from "../_context/AnalyticsEventsContext";
import { eDataPermission, eSetupType, eStateToAnalysis, eErrorType, eModalWindowState, eFileType } from "../_context/Enums";
import { EventsContext } from "../_context/EventsContext";
import { LabelHandler } from "../_context/LabelHandler";
import { MessagesHandler } from "../_context/MessagesHandler";
import { Op3dContext } from "../_context/Op3dContext";
import { iSetupParameters, iSetupDetails, iRenameSetupData, iOpenSetupsMenu, iGetOneSetup, iUploadSetupData, iDownloadFullSetupData, iBasicSetupData, iID } from "../_context/_interfaces/Interfaces";
import { DataUtils } from "../_utils/DataUtils";
import { UIUtils } from "../_utils/UIUtils";
import { PartsDataLoader } from "../data/data_loader/PartsDataLoader";
import { SetupsDataLoader } from "../data/data_loader/SetupsDataLoader";
import { Part } from "../parts/Part";
import { iOptixPartJSON } from "../parts/_parts_assets/ExportToJSONInterfaces";
import { iSceneOptions } from "../scene/GridManager";
import { Op3dScene } from "../scene/Op3dScene";
import { SceneContext } from "../scene/SceneContext";
import { eViewTypeXYZ } from "../scene/absSceneCube";
import { ServerContext } from "../server/ServerContext";
import { SettingsContext } from "../settings/SettingsContext";
import { SimulationRunner } from "../simulation/SimulationRunner";
import { AnalysisPortal } from "../ui/analysis/AnalysisPortal";
import { Popup } from "../ui/forms/Popup";
import { Menu } from "../ui/home/Menu";
import { NewDesignForm } from "../ui/home/NewDesignForm";
import { Spinner } from "../ui/home/Spinner";
import { WakeupScreen } from "../ui/home/WakeupScreen";
import { NotificationCenter } from "../ui/home/_notifications/NotificationCenter";
import { eNotificationToastDuration } from "../ui/home/_notifications/NotificationsContext";
import { UnitHandler } from "../units/UnitsHandler";
import { SceneSnapshotGenerator } from "./SceneSnapshotGenerator";
import { OptImporter } from "./opt/OptImporter";
import { OptReader } from "./opt/OptReader";
import { CacheStorage } from "../data/CacheStorage";
import { Zip } from "../../loaders/Zip";
import { CameraUtils } from "../scene/_camera/CameraUtils";
import { AxiosError } from "axios";

export interface iSaveOptParams {
    /**
     *  Weather to present a success ore an error message after saving.
     */
    showMessage?: boolean;
}


export class SetupsManager {
    private static DEFAULT_SETUP_PERMISISON: eDataPermission = eDataPermission.PUBLIC;
    public static FILE_COUNTER = 0;
    public static SETUP_VERSION = '0';


    private mSession: string;
    private mSetupPermission: eDataPermission = eDataPermission.PUBLIC;
    private mSetupParameters: iSetupParameters;
    private mCurrSetupID: string;
    private mBeforeUnloadFunc: EventListener;
    private mSnapshotGenerator = new SceneSnapshotGenerator();
    private mPreviewsPermission: eDataPermission;
    private mInitialPartsQuantity: number = 0;

    constructor() {
        this._init();
        this._initParameters();
    }
    //______________________________________________________________________________________________
    public get snapshotGenerator() {
        return this.mSnapshotGenerator;
    }
    //______________________________________________________________________________________________
    public get currSetupID() {
        return this.mCurrSetupID;
    }
    //______________________________________________________________________________________________
    public set currSetupID(pID: string) {
        this.mCurrSetupID = pID;
    }
    //______________________________________________________________________________________________
    public set session(pID: string) {
        this.mSession = pID;
    }
    //______________________________________________________________________________________________
    public onUpdateSceneOptions(pSceneOptions: iSceneOptions) {
        this.mSetupParameters.sceneOptions = pSceneOptions;
    }
    //______________________________________________________________________________________________
    public updateSetupDetails(pDetails: iSetupDetails, pSetupPermisison: eDataPermission) {
        this._checkName(pDetails);
        for (let param in pDetails) {
            if (null != pDetails[param]) {
                this.mSetupParameters.details[param] = pDetails[param];
            }
        }
        if (pSetupPermisison === this.mPreviewsPermission) {
            this.mPreviewsPermission = this.mSetupPermission;
            this.mSetupParameters.previews_permission = this.mSetupPermission;
        }

        this.mSetupPermission = pSetupPermisison;
        this.mSetupParameters.previews_permission = this.mSetupPermission;
    }
    //______________________________________________________________________________________________
    private async _initParameters() {
        this.mSetupPermission = SetupsManager.DEFAULT_SETUP_PERMISISON;
        this.mSetupParameters = {
            settings: SettingsContext.getUserSimulationSettings(),
            env: ServerContext.env,
            details: { setupName: "Untitled Setup" },
            setupType: eSetupType.USER,
            unit: UnitHandler.PRESENTED_UNIT,
            version: Op3dContext.SETUP_NUM,
            env_version: Op3dContext.VERSION_NUM,
            to_publish: false,
            changes_in_setup: false,
            setup_version_to_compare: '',
            simulation_version_to_compare: 0,
            changes_in_setup_for_analysis: false,
            show_labels: Op3dScene.SHOW_ALL_LABELS,
            show_power_reading: Op3dScene.SHOW_POWER_METER,
            previews_permission: this.mSetupPermission,
            initialPartsQuantity: 0,
        };
    }
    //______________________________________________________________________________________________
    // public get seqSystem() {
    //     return this.mCurrSeqSystemHandler;
    // }
    //______________________________________________________________________________________________
    public resetCurrSetupID() {
        this.mCurrSetupID = null;
    }
    //______________________________________________________________________________________________
    public get ownerId() {
        return Op3dContext.USER_VO.id;
    }
    //______________________________________________________________________________________________
    private _init() {
        //todo

        EventManager.addEventListener(EventsContext.RENAME_SET_UP,
            (_pData: EventBase<iRenameSetupData>) =>
                this._onRenameSetup(), this);

        EventManager.addEventListener(EventsContext.COPY_SETUP,
            (_pData: EventBase<iRenameSetupData>) =>
                this._onCopySetup(), this);

        EventManager.addEventListener(EventsContext.ON_NEW,
            (pData: EventBase<{ permission: eDataPermission, details: iSetupDetails }>) => this._onNew(pData.data, pData.sender), this);

        EventManager.addEventListener(EventsContext.SAVE_OPT_CLOUD,
            () => this._onSaveSetupCloud(), this);

        EventManager.addEventListener<iOpenSetupsMenu>(EventsContext.OPEN_SETUP,
            (pData: EventBase<iOpenSetupsMenu>) =>
                this.onOpenSetup(pData.data), this);

        EventManager.addEventListener(EventsContext.AUTO_SAVE,
            () => this.saveOpt({ showMessage: false }), this);

        EventManager.addEventListener(EventsContext.ON_CLEAR_ALL_PARTS,
            () => this._onClearAllParts(), this);

        EventManager.addEventListener(EventsContext.ON_EXPORT,
            (pData: EventBase<eFileType>) =>
                this._onExportSetup(pData.data), this);

        this.mBeforeUnloadFunc = async (_event) => {
            const aToken = ServerContext.tokens != null ? ServerContext.tokens.access_token : "";
            fetch(ServerContext.backend_url + "/api/setup/close", {
                keepalive: true,
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + aToken,
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify({ _id: this.mCurrSetupID }),
            });
            AnalyticsEventsContext.triggerFinishSession(true);
        };


        window.addEventListener('beforeunload', this.mBeforeUnloadFunc);
    }
    //______________________________________________________________________________________________
    public get beforeUnloadFunc() {
        return this.mBeforeUnloadFunc;
    }
    //______________________________________________________________________________________________
    private _onClearAllParts() {

    }
    //______________________________________________________________________________________________
    private async _onNew(pParams: { permission: eDataPermission, details: iSetupDetails }, pSender) {
        if (pSender instanceof WakeupScreen || pSender instanceof NewDesignForm || pSender instanceof SetupsManager) return

        let aName = UIUtils.getNewFileName()
        // this._initParameters();
        this.onStartNewDesign({
            parameters: this.mSetupParameters,
            name: pParams.details.setupName,
            _id: null,
            permission: pParams.permission,
        }, pParams.permission)
        this.mCurrSetupID = null;
        if (pParams) {
            aName = pParams.details.setupName
        }
        this.mSetupParameters.previews_permission = pParams.permission;
        this.mPreviewsPermission = pParams.permission;

        this.mSetupParameters.initialPartsQuantity = 0;
        this.mInitialPartsQuantity = 0;
        Op3dContext.DIV_CONTROLLER.setFileName({
            name: aName,
            to_update: false
        });

        SceneContext.CAMERA.zoom = 0.7
        SceneContext.CAMERA.updateProjectionMatrix()
        Op3dContext.PARTS_EVENTS_HANDLER.restoreToGeneralMode();
    }
    //______________________________________________________________________________________________
    public async onOpenSetup(pData: iOpenSetupsMenu) {
        if (pData.content.content) {
            Op3dContext.TOTAL_PARTS = JSON.parse(pData.content.content).length;
        }
        else {
            pData.content.content = '[]';
            Op3dContext.TOTAL_PARTS = 0;
        };
        Op3dContext.setupIsLoaded = false;
        Op3dContext.LOADED_PARTS = 0;
        Spinner.instance.show();
        await Op3dContext.wait(() => (true == Op3dContext.DATA_MANAGER.isReady));
        Spinner.instance.hide();

        try {
            if (true == pData.addToExisting) {
                this._addToCurrentSetup(pData);
                return;
            }

            Spinner.instance.show();
            this.mCurrSetupID = pData.content._id;
            this.mSession = pData.content.session;
            EventManager.dispatchEvent(EventsContext.ON_NEW, this)
            EventManager.dispatchEvent(EventsContext.ON_CLEAR_ALL_PARTS, this);

            await new OptImporter().readContent(pData.content);

            Op3dContext.DIV_CONTROLLER.setFileName({
                name: pData.content.name,
                to_update: false
            });

            switch (this.mSetupParameters.setupType) {
                case eSetupType.EXAMPLE: {
                    if (!(Op3dContext.USER_VO.isAdmin &&
                        Op3dContext.USER_VO.isOwner(pData.content.owner))) {
                        this.mCurrSetupID = null;
                        this.mSetupParameters.setupType = eSetupType.USER;
                        this.mSetupPermission = pData.content.permission;
                    }
                    break;
                }
            }

            this.mSetupPermission = (true == Op3dContext.USER_PERMISSION.hasPrivateSetupsPermission) ?
                pData.content.permission : eDataPermission.PUBLIC;
            if (pData.content.parameters.previews_permission !== undefined) {
                this.mPreviewsPermission = pData.content.parameters.previews_permission;
                this.mSetupParameters.previews_permission = this.mPreviewsPermission;
            } else {
                this.mPreviewsPermission = this.mSetupPermission;
                this.mSetupParameters.previews_permission = this.mPreviewsPermission;
            }
            let aSceneOptions = this.mSetupParameters.sceneOptions;
            if (null != aSceneOptions) {
                SceneContext.GRID_MANAGER.setSceneOptions(aSceneOptions);
            }
            Spinner.instance.hide();
            Op3dContext.SCENE_HISTORY.clearHistory()

            let aSetupContent = JSON.stringify(Op3dContext.SETUPS_MANAGER.getSetupData());
            Op3dContext.SCENE_HISTORY.setLastContent(aSetupContent);
            AnalysisPortal.instance.enableRunAnalysis(
                eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);

            // Menu.instance.setLabelsState(this.mSetupParameters.show_labels, false);
            if (true === Op3dContext.USER_VO.isBasicLicense) {
                this.mSetupParameters.show_power_reading = false;
            }
            Menu.instance.setPowerMeterLabelsState(this.mSetupParameters.show_power_reading, false);

            if (pData.content.parameters.initialPartsQuantity != null) {
                this.mInitialPartsQuantity = pData.content.parameters.initialPartsQuantity;
                this.mSetupParameters.initialPartsQuantity = this.mInitialPartsQuantity;
            } else {
                this.mInitialPartsQuantity = Op3dContext.TOTAL_PARTS;
                this.mSetupParameters.initialPartsQuantity = this.mInitialPartsQuantity;
            }
        }
        catch (error) {
            Op3dContext.USER_VO.isEmployeeUser && console.log(error);

            Spinner.instance.hide();
            Popup.instance.open({ text: MessagesHandler.ERROR_WHILE_LOADING_SETUP });
        }
        finally {
            Spinner.instance.hide();
            Op3dContext.setupIsLoaded = true;
            Op3dContext.PARTS_MANAGER.updatePartsList();

        }
    }
    //______________________________________________________________________________________________
    private async _addToCurrentSetup(pData: iOpenSetupsMenu) {
        Spinner.instance.show();

        Op3dContext.SCENE_HISTORY.addToHistory();
        await new OptImporter().readContent(pData.content, true);
        Op3dContext.SCENE_HISTORY.saveScene();

        Spinner.instance.hide();
    }
    //______________________________________________________________________________________________
    public async initAfterLoad() {
        await Op3dContext.PARTS_MANAGER.updateAllParts();

        CameraUtils.setIsometricView()
        CameraUtils.fitToZoom(eViewTypeXYZ.ISO_VIEW)


        await SimulationRunner.instance.loadRayTracingVisualization();
        if (this.mSetupParameters.details.runSimOnload) {
            await SimulationRunner.instance.runVisualizationSimulation();
        }

        // Op3dContext.DIV_CONTROLLER.showSetupDetails(true);
    }
    //______________________________________________________________________________________________
    private _export(pParts: Array<Part>) {

        let aExportArrayParts = new Array<iOptixPartJSON>();

        let aErrorOccured = false;
        for (let i = 0; i < pParts.length; i++) {
            try {
                const aCurrPart = pParts[i].exportToJSONObject();
                if (aCurrPart != null) {
                    aExportArrayParts.push(aCurrPart);
                }
            } catch (e) {
                MessagesHandler.ON_ERROR_PROGRAM({
                    show_message: false,
                    error: {
                        error: e,
                        part: pParts[i].internalID,
                        setupId: Op3dContext.SETUPS_MANAGER.currSetupID
                    },
                    type: eErrorType.IMPORT_EXPORT_SETUP
                });

                aErrorOccured = true;
            }
        }
        return { parts: aExportArrayParts, error: aErrorOccured };


    }
    //______________________________________________________________________________________________
    public async getOneSetup(pData: iGetOneSetup) {
        let aRes = await ServerContext.SERVER.getOneSetup(pData);
        Op3dContext.SCENE_HISTORY.ignoreSaving = false;
        return aRes;
    }
    //______________________________________________________________________________________________
    public async closeSetup() {
        if (null == this.mCurrSetupID) {
            return;
        }

        Op3dContext.SCENE_HISTORY.ignoreSaving = true;
        LabelHandler.resetIndexes();
        CacheStorage.instance.distract();
        PartsDataLoader.instance.distract();
        Op3dContext.GLOBAL_MATERIAL.dispose();

        EventManager.dispatchEvent(EventsContext.ON_CLEAR_ALL_PARTS, this);

        SceneContext.RENDERER.clear();
        SceneContext.RENDERER.dispose();
    }
    //______________________________________________________________________________________________
    public getSetupData(pCustomParts?: Array<Part>): iUploadSetupData {
        let aParts: Array<iOptixPartJSON>;


        if (pCustomParts != null) {
            const aRes = this._export(pCustomParts);
            aParts = aRes.parts;
        } else {
            const aRes = this._export(Op3dContext.PARTS_MANAGER.parts);
            aParts = aRes.parts;

            if (aRes.error === true) {
                NotificationCenter.instance.pushNotification({
                    message: MessagesHandler.ERROR_DOWNLOAD_SETUP,
                    params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
                    toastDuration: eNotificationToastDuration.INFINITE
                });
                return
            }

        }

        const aSetupParameters = DataUtils.getObjectCopy(this.mSetupParameters);

        const aPermission = aSetupParameters.setupType === eSetupType.EXAMPLE ?
            eDataPermission.PUBLIC : this.mSetupPermission;

        const aSetupBomData = Op3dContext.PARTS_MANAGER.getRawBOMData();

        const aSetupData: iUploadSetupData = {
            content: JSON.stringify(aParts),
            _id: this.mCurrSetupID,
            name: aSetupParameters.details.setupName,
            permission: aPermission,
            parameters: aSetupParameters,
            bom: {
                optics: Object.values(aSetupBomData.optics) as any,
                optomechanics: Object.values(aSetupBomData.optomechanics) as any,
            }
        };

        return aSetupData;
    }
    //______________________________________________________________________________________________
    public async saveOpt(pSaveOptParams: iSaveOptParams) {
        Op3dContext.SCENE_HISTORY.setIsLoading(true)

        let aImage = this.mSnapshotGenerator.getSceneSnapshot();

        this.mSetupParameters.sceneOptions = await SceneContext.GRID_MANAGER.getSceneOptions();
        const aSetupData = this.getSetupData();
        aSetupData.image = aImage;

        this.checkForWarehouse(aSetupData);
        aSetupData.session = this.mSession;

        const aRes = await SetupsDataLoader.instance.add(aSetupData);

        if ((null != aRes) && (true == aRes.success)) {
            this.mCurrSetupID = aRes.data;
            SetupsManager.SETUP_VERSION = aRes.version;

            if (true == pSaveOptParams.showMessage) {
                MessagesHandler.SAVED_SUCCESSFULLY_MSG();
            }

        } else {
            let aError = (aRes.data as any) as AxiosError;
            if (true == pSaveOptParams.showMessage) {
                if ((undefined !== aError) && (undefined !== aError.response) &&
                    (ServerContext.PreconditionFailed === aError.response.status)) {
                    MessagesHandler.SETUP_VERSION_ERROR();
                } else {
                    NotificationCenter.instance.pushNotification({
                        message: MessagesHandler.ERROR_SAVING_SETUP,
                        params: NotificationCenter.NOTIFICATIONS_TYPES.ERROR,
                        toastDuration: eNotificationToastDuration.INFINITE
                    });
                }
            }
        }

        Op3dContext.SCENE_HISTORY.setIsLoading(false);
    }
    //______________________________________________________________________________________________
    public closeOpenSetup(pForce: boolean, pData?: iID) {
        if ((true === pForce) || ((undefined !== pData) && (pData.id === this.mCurrSetupID))) {
            this.closeSetup();
            Op3dContext.SETUPS_MANAGER.currSetupID = null;
            EventManager.dispatchEvent(EventsContext.OPEN_WAKEUP_SCREEN, this);
        }
    }
    //______________________________________________________________________________________________
    private _toPublish() {

        // one of the parts i gcs
        let aCond1 = Op3dContext.PARTS_MANAGER.parts.length > 4
        let aCond2 = Op3dContext.USER_VO.isAdmin == false;
        let aCond3;
        if (this.mPreviewsPermission === eDataPermission.PUBLIC && this.mSetupPermission === eDataPermission.PRIVATE) {
            aCond3 = true
        } else {
            aCond3 = this.mSetupPermission === eDataPermission.PUBLIC;
        }
        let aCond4 = false;
        if ((Op3dContext.PARTS_MANAGER.parts.length < this.mInitialPartsQuantity - 2) || (Op3dContext.PARTS_MANAGER.parts.length > this.mInitialPartsQuantity + 2)) {
            aCond4 = true;
            this.mInitialPartsQuantity = 0;
            this.mSetupParameters.initialPartsQuantity = 0;
        };
        let aToPublish = aCond1 && aCond2 && aCond3 && aCond4;
        return aToPublish;
    }
    //______________________________________________________________________________________________
    private checkForWarehouse(pCurrentSetup: iUploadSetupData) {
        /**
         * this parameters is 3 beacuse we have always the LCS as part
         */
        pCurrentSetup.parameters.to_publish = this._toPublish();
    }

    //______________________________________________________________________________________________
    private _onSaveSetupCloud() {
        NewDesignForm.instance.open({
            setup: this.getSetupData(),
            isNew: false,
            state: eModalWindowState.SAVE
        });
    }
    //______________________________________________________________________________________________
    private _checkName(pDetails: iSetupDetails) {
        if (this.mSetupParameters.details.setupName != pDetails.setupName) {
            Op3dContext.DIV_CONTROLLER.setFileName({
                to_update: false,
                name: pDetails.setupName
            });
        }
    }
    //______________________________________________________________________________________________
    public static async chooseOptFromLocal(pIsNew: boolean, pAddToExisting: boolean = false,
        pCallback?: Function) {
        let aInput = document.createElement('input');
        aInput.setAttribute('type', 'file');
        aInput.setAttribute('accept', '.opt');
        aInput.onchange = (e) => {
            let aFile = (e.target as any).files[0] as File;
            this.openFile(aFile, pIsNew, pAddToExisting, pCallback);
        };
        aInput.click();
    }
    //______________________________________________________________________________________________
    public static async openFileSavva(pContent: iDownloadFullSetupData, pIsNew: boolean, pAddToExisting: boolean,
        pCallback?: Function) {





        delete pContent.owner;
        delete pContent.updatedAt;
        delete pContent._id;

        if ((true == pAddToExisting) &&
            (pContent.parameters.unit != UnitHandler.PRESENTED_UNIT)) {
            MessagesHandler.WRONG_ENV_IMPORT_MESSAGE();
            return;
        }

        if (null != pCallback) {
            pCallback();
        }

        await SetupsManager._openSetup(pContent, pIsNew, pAddToExisting);
    }
    //______________________________________________________________________________________________
    public static async openFile(pFile: File, pIsNew: boolean, pAddToExisting: boolean,
        pCallback?: Function) {


        let aOpt = await new OptReader().readFile(pFile);

        if ((aOpt as any).data == "") {
            return;
        }

        let aData = JSON.parse((aOpt as any).data) as iDownloadFullSetupData;

        delete aData.owner;
        delete aData.updatedAt;
        delete aData._id;

        if ((true == pAddToExisting) &&
            (aData.parameters.unit != UnitHandler.PRESENTED_UNIT)) {
            MessagesHandler.WRONG_ENV_IMPORT_MESSAGE();
            return;
        }

        if (null != pCallback) {
            pCallback();
        }

        await SetupsManager._openSetup(aData, pIsNew, pAddToExisting);
    }
    //______________________________________________________________________________________________
    private static async _openSetup(pData: iDownloadFullSetupData, pIsNew: boolean, pAddToExisting: boolean) {
        if (Op3dContext.USER_PERMISSION.isFreeUser === false) {
            pData.permission = eDataPermission.PRIVATE;
            pData.parameters.isShareable = false
        } else {
            pData.parameters.isShareable = true
        }
        if (true == pIsNew) {
            Op3dContext.MANAGER.openExistingSetup(pData);
        } else {
            if (UnitHandler.PRESENTED_UNIT !== undefined && UnitHandler.PRESENTED_UNIT == pData.parameters.unit) {
                await Op3dContext.SETUPS_MANAGER.onOpenSetup({
                    addToExisting: pAddToExisting,
                    content: pData
                });
                Op3dContext.SCENE_HISTORY.saveScene(true);
            } else {
                Op3dContext.MANAGER.reloadAndOpenExistingSetup(pData);
            }

            AnalysisPortal.instance.enableRunAnalysis(
                eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
        }
    }
    //______________________________________________________________________________________________
    public downloadOPT() {
        const aRes = this.getSetupData();
        Zip.createAndDownloadOpt({
            data: JSON.stringify(aRes),
            fileName: this.mSetupParameters.details.setupName,
            file_format: ".opt",
        });
    }
    //______________________________________________________________________________________________
    public get settings() {
        return this.mSetupParameters.settings;
    }
    //______________________________________________________________________________________________
    public onStartNewDesign(pSetupData: iBasicSetupData, pSetupPermisison: eDataPermission) {
        if (this.mCurrSetupID != null) {
            this.closeSetup();
            this.mCurrSetupID = null;
        }

        this._checkName(pSetupData.parameters.details);
        Op3dContext.SCENE_HISTORY.ignoreSaving = false;
        this.mSetupPermission = pSetupPermisison;
        this.mSetupParameters.previews_permission = pSetupPermisison;

        this.mSetupParameters.isShareable = pSetupPermisison === eDataPermission.PUBLIC;


        this.mSetupParameters.settings = pSetupData.parameters.settings;
        this.mSetupParameters.details = pSetupData.parameters.details;
    }
    //______________________________________________________________________________________________
    private async _onExportSetup(pFileType: eFileType) {

        switch (pFileType) {
            case eFileType.OPT:
                NewDesignForm.instance.open({
                    setup: this.getSetupData(),
                    isNew: false,
                    state: eModalWindowState.DOWNLOAD
                });
                break;
        }
    }
    //______________________________________________________________________________________________
    private _onRenameSetup() {
        NewDesignForm.instance.open({
            setup: this.getSetupData(),
            isNew: false,
            state: eModalWindowState.RENAME
        });
    }
    //______________________________________________________________________________________________
    private _onCopySetup() {
        let aSetup = DataUtils.getObjectCopy(this.getSetupData());
        let aName = 'Copy of ' + aSetup.name;
        aSetup.name = aName;
        aSetup.parameters.details.setupName = aName;

        NewDesignForm.instance.open({
            setup: aSetup,
            isNew: false,
            state: eModalWindowState.COPY
        });
    }
    //______________________________________________________________________________________________
    // public set labelState(pVal: boolean) {
    //     this.mSetupParameters.show_labels = pVal;
    // }
    //______________________________________________________________________________________________
    // public get labelState() {
    //     return this.mSetupParameters.show_labels;
    // }
    //______________________________________________________________________________________________
    public set setupParameters(pParameters: iSetupParameters) {
        this.mSetupParameters = pParameters;
        // this.mSetupParameters.settings = DataUtils.fillObjectAccordingTo(pParameters.settings,
        //     settings.SettingsContext.getUserSimulationSettings());
        this.mSetupParameters.version = Op3dContext.SETUP_NUM;
        this.mSetupParameters.env_version = Op3dContext.VERSION_NUM;
    }
    //______________________________________________________________________________________________
    public get setupParameters() {
        return this.mSetupParameters;
    }
    //______________________________________________________________________________________________
    public get fileName(): string {
        return this.mSetupParameters.details.setupName;
    }
    //______________________________________________________________________________________________
    // public removeSeqSystem(pSeqSystem: seq.SeqSystem) {
    //     let aIndex = this.mSeqSystemHandlers.indexOf(pSeqSystem);
    //     if (aIndex != -1) {
    //         this.mSeqSystemHandlers.splice(aIndex, 1);
    //     }
    // }
    // //______________________________________________________________________________________________
    // public addSeqSystem(pSeqSystem: seq.SeqSystem) {
    //     if (this.mSeqSystemHandlers == null) {
    //         this.mSeqSystemHandlers = new Array<seq.SeqSystem>();
    //     }

    //     if (this.mSeqSystemHandlers.indexOf(pSeqSystem) == -1) {
    //         this.mSeqSystemHandlers.push(pSeqSystem);
    //     }
    // }
    //______________________________________________________________________________________________
}
