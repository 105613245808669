import styled from "styled-components";
import { OptiChatMessagesFactory } from "../OptiChatMessagesFactory";
import { iOptiChatMessage } from "../../store/OptiChatContext";
import React from "react";

const Conversation = styled.div`
  width: 100%;
  min-height: 150px;
  height: calc(100% - 120px);
  overflow-y: auto;
  display: flex;
  padding: 8px 8px 4px 8px;

  flex-direction: column;
  align-items: flex-end;

  & .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: inset 0 0 10px 10px #afbabb;
    border: solid 5px transparent;
    border-radius: 10px;
  }
`;
const OptiChatConversation = (props: {
  isDisabled: boolean;
  conversation: Array<iOptiChatMessage>;
  onFinishReview?: Function;
}) => {
  return (
    <Conversation
      data-testid="conversation-container"
      className={props.isDisabled ? "disabled" : ""}
    >
      {props.conversation.map((item) =>
        OptiChatMessagesFactory.getMessage(item, props.onFinishReview)
      )}
    </Conversation>
  );
};

export default React.memo(OptiChatConversation);
