import { EventManager } from "../../../oc/events/EventManager";
import { eBucketToRetrieve } from "../../_context/Enums";
import { EventsContext } from "../../_context/EventsContext";
import { Op3dContext } from "../../_context/Op3dContext";
import { iHash } from "../../_context/_interfaces/Interfaces";
import { Op3dUtils } from "../../_utils/Op3dUtils";
import { Part } from "../../parts/Part";
import { SimulationRunner } from "../../simulation/SimulationRunner";
import { AnalysisPortal } from "./AnalysisPortal";
import { QuickViewHandler } from "./QuickViewHandler";

export interface iLockAnalsysData {
    analysisID: string,
    isLocked: boolean
}

export class AnalysesSynchronizer {

    private static INSTANCE: AnalysesSynchronizer;
    private mLockedAnalysis: Array<iLockAnalsysData> = new Array<iLockAnalsysData>();

    private constructor() {
        this._addEventListeners();

    }
    //__________________________________________________________________________________________
    public static get instance() {
        if (this.INSTANCE == null) {
            this.INSTANCE = new AnalysesSynchronizer();
        }

        return this.INSTANCE;
    }

    //__________________________________________________________________________________________
    public init() {

    }
    //__________________________________________________________________________________________
    public isLocked(pId: string) {
        let aItem = this.mLockedAnalysis.find(analysis => analysis.analysisID == pId);
        if (aItem == null) {
            this.setLockedItems({ analysisID: pId, isLocked: false }, false);
            return false;
        }

        return aItem.isLocked;
    }
    //__________________________________________________________________________________________
    private _addEventListeners() {
        // EventManager.addEventListener(
        //     EventsContext.ON_FINISH_SIMULATION, () => this._onSimulationFinished(), this);

        EventManager.addEventListener(
            EventsContext.ON_START_SIMULATION, () => this._onStartSimulation(), this);

        EventManager.addEventListener(
            EventsContext.ON_NEW, () => this.distract(), this);
    }
    //__________________________________________________________________________________________
    public onDeleteAnalysis(pAnalysisId: string) {
        QuickViewHandler.instance.onDeleteQV(pAnalysisId);
    }
    //__________________________________________________________________________________________
    // private getAllFastAnalysis() {
    //     let aFastAnalysesIds = new Array<string>();
    //     let aParts = Op3dContext.PARTS_MANAGER.parts;
    //     for (let i = 0; i < aParts.length; i++) {
    //         const aAnalysisOptions = aParts[i].analysisOptions;
    //         if (aAnalysisOptions != null) {
    //             for (let j = 0; j < aAnalysisOptions.length; j++) {
    //                 aFastAnalysesIds.push(...aAnalysisOptions[j].fast.map(item => item.id));
    //             }
    //         }
    //     }
    //     return aFastAnalysesIds;
    // }
    //__________________________________________________________________________________________
    // private getAllAnalysis() {
    //     let aAllAnalysesIds = new Array<string>();
    //     let aParts = Op3dContext.PARTS_MANAGER.parts;
    //     for (let i = 0; i < aParts.length; i++) {
    //         const aAnalysisOptions = aParts[i].analysisOptions;
    //         if (aAnalysisOptions != null) {
    //             for (let j = 0; j < aAnalysisOptions.length; j++) {
    //                 aAllAnalysesIds.push(...aAnalysisOptions[j].fast.map(item => item.id));
    //                 aAllAnalysesIds.push(...aAnalysisOptions[j].advanced.map(item => item.id));
    //             }
    //         }
    //     }
    //     return aAllAnalysesIds;
    // }
    //__________________________________________________________________________________________
    public getAllFastAnalysisQV() {
        QuickViewHandler.instance.clearQVItems()
        let aFastAnalyses = new Array<any>();
        let aParts = Op3dContext.PARTS_MANAGER.parts;
        for (let i = 0; i < aParts.length; i++) {
            const aAnalysisOptions = aParts[i].analysisOptions;

            if (aAnalysisOptions != null) {
                let faceId = aAnalysisOptions[0].faceId
                for (let j = 0; j < aAnalysisOptions.length; j++) {
                    let active = aAnalysisOptions[j].fast.filter(el => el.isActive)

                    let obj = active
                        .map(el => ({
                            display: el.display,
                            name: el.name,
                            analysisId: el.id,
                            faceID: faceId,
                        }))

                    aFastAnalyses.push(...obj)
                }
            }
        }

        aFastAnalyses.forEach(el => QuickViewHandler.instance.addAnalysis(el))

    }
    //__________________________________________________________________________________________
    public addCurrentDetectorToQV(pPart: Part) {
        let aFastAnalyses = new Array<any>();

        const aAnalysisOptions = pPart.analysisOptions;
        let faceId = aAnalysisOptions[0].faceId;
        for (let j = 0; j < aAnalysisOptions.length; j++) {
            let active = aAnalysisOptions[j].fast.filter(el => el.isActive)

            let obj = active.map(el => ({
                    display: el.display,
                    name: el.name,
                    analysisId: el.id,
                    faceID: faceId,
                }))

            aFastAnalyses.push(...obj)
        }

        aFastAnalyses.forEach(el => QuickViewHandler.instance.addAnalysis(el));
    }
    //__________________________________________________________________________________________
    public distract() {
        this.mLockedAnalysis = [];
    }
    //__________________________________________________________________________________________
    public setLockedItems(pItem: iLockAnalsysData, pChangeLock: boolean, pFirstTime?: boolean) {

        let aIdx = this.mLockedAnalysis.findIndex(item => item.analysisID == pItem.analysisID);
        if (aIdx == -1) {
            this.mLockedAnalysis.push(pItem);
        } else {
            if (pChangeLock) {
                this.mLockedAnalysis[aIdx].isLocked = pItem.isLocked;
                this._notifyLocked(this.mLockedAnalysis[aIdx]);
            }
        }

        if (pFirstTime)
            return;
    }
    //__________________________________________________________________________________________
    public checkIfAllLocked() {
        return this.mLockedAnalysis.every(item => item.isLocked)
    }
    //__________________________________________________________________________________________
    private _notifyLocked(pData: iLockAnalsysData) {
        EventManager.dispatchEvent<iLockAnalsysData>(EventsContext.LOCKED_ANALYSIS, this, pData);
    }
    //__________________________________________________________________________________________
    private _onStartSimulation() {
        let aQVAnalyses = QuickViewHandler.instance.qvItems;
        let aUnlockedAnalyses = new Array<string>();
        for (let i = 0; i < aQVAnalyses.length; i++) {
            let aIdx = this.mLockedAnalysis.findIndex(item => item.analysisID == aQVAnalyses[i]);
            if (aIdx != -1 && this.mLockedAnalysis[aIdx].isLocked == false) {
                aUnlockedAnalyses.push(aQVAnalyses[i]);
            }
        }

        let aIsUpdateEnabled = AnalysisPortal.instance.isUpdateEnabled();
        if (Op3dContext.isWidget !== null) { aIsUpdateEnabled = true; }
        if (aUnlockedAnalyses.length > 0 && aIsUpdateEnabled && !QuickViewHandler.isAnalysisFromQuickView) {
            //AnalysisPortal.instance.setNotUpdated(true);
            AnalysisPortal.instance.updateByIds(aQVAnalyses);
            QuickViewHandler.instance.onUpdateBtn(aQVAnalyses);
            let aBucketIds: iHash<eBucketToRetrieve> = {};
            aQVAnalyses.forEach(item => {
                const aAnalysis = Op3dContext.PARTS_MANAGER.getAnalysisById(item);
                const aAnalysisCurrVersion = aAnalysis.analysis_version;
                const aAnalysisOldVersion = aAnalysis.analysis_version_old;
                if (Op3dContext.SETUPS_MANAGER.setupParameters.changes_in_setup_for_analysis) {
                    aAnalysis.analysis_version = Op3dUtils.idGenerator();
                    aAnalysis.analysis_version_old = aAnalysis.analysis_version;
                    // let aObj = {};
                    // aObj[item] = eBucketToRetrieve.GPU;
                    aBucketIds[item] = eBucketToRetrieve.GPU
                } else if (aAnalysisOldVersion !== aAnalysisCurrVersion || aAnalysisCurrVersion == '') {
                    // let aObj = {};
                    //aObj[item] = eBucketToRetrieve.GPU;
                    aAnalysis.analysis_version = Op3dUtils.idGenerator();
                    aAnalysis.analysis_version_old = aAnalysis.analysis_version;
                    // aBucketIds.push(aObj);
                    aBucketIds[item] = eBucketToRetrieve.GPU
                } else {
                    let aObj = {};
                    aObj[item] = eBucketToRetrieve.S3;
                    // aBucketIds.push(aObj);
                    aBucketIds[item] = eBucketToRetrieve.S3
                }
            })
            SimulationRunner.instance.runAnalysesByIds({
                analysisToRun: aUnlockedAnalyses,
                analysisBuckets: aBucketIds,
                onFinish: (pIsOk: boolean, _e: any) => this._onAnalysisFinished(pIsOk, aUnlockedAnalyses.length),
            });
        }
    }
    //__________________________________________________________________________________________
    //private _onSimulationFinished() {

    // let aFastAnalyses = this.getAllFastAnalysis();
    // let aUnlockedAnalyses = new Array<string>();
    // for (let i = 0; i < aFastAnalyses.length; i++) {
    // let aIdx = this.mLockedAnalysis.findIndex(item => item.analysisID == aFastAnalyses[i]);
    //  if (aIdx != -1 && this.mLockedAnalysis[aIdx].isLocked == false) {
    //  aUnlockedAnalyses.push(aFastAnalyses[i]);
    //}
    //}

    //   let aIsUpdateEnabled = AnalysisPortal.instance.isUpdateEnabled();
    //   if (Op3dContext.isWidget !== null) aIsUpdateEnabled = true

    // if (aUnlockedAnalyses.length > 0 && aIsUpdateEnabled) {

    //AnalysisPortal.instance.setNotUpdated(true);
    // AnalysisPortal.instance.updateFast();
    /// QuickViewHandler.instance.onUpdateBtn();

    // simulation.SimulationRunner.instance.runAnalysesByIds({
    //    analysisToRun: aUnlockedAnalyses,
    //   onFinish: (pIsOk: boolean, e: any) => this._onAnalysisFinished(pIsOk, aUnlockedAnalyses.length),
    // });
    //}
    //}
    //__________________________________________________________________________________________
    private _onAnalysisFinished(_pIsOk: boolean, _pAnalysisoRun: number) {

    }
    //__________________________________________________________________________________________

}
