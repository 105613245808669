import { iMaterialVO } from "../_context/_interfaces/Interfaces";
import { eMaterialEquationType } from "../_context/Enums";

export class MaterialUtils {

    //__________________________________________________________________________________________
    public static getN(pMaterialVO: iMaterialVO, pLambdaNano: number): number {

        let aLambdaMicro = 0.001 * pLambdaNano;
        let aMaterialType = pMaterialVO.parameters.type;
        let aParams = pMaterialVO.parameters;

        switch (aMaterialType) {
            case eMaterialEquationType.SELLMEIER:
            case eMaterialEquationType.MODIFIED_SELLMEIER: {
                let b1 = ((undefined !== aParams.coeffs.b1) ? aParams.coeffs.b1 : 0);
                let b2 = ((undefined !== aParams.coeffs.b2) ? aParams.coeffs.b2 : 0);
                let b3 = ((undefined !== aParams.coeffs.b3) ? aParams.coeffs.b3 : 0);
                let b4 = ((undefined !== aParams.coeffs.b4) ? aParams.coeffs.b4 : 0);
                let b5 = ((undefined !== aParams.coeffs.b5) ? aParams.coeffs.b5 : 0);
                let b6 = ((undefined !== aParams.coeffs.b6) ? aParams.coeffs.b6 : 0);
                let b7 = ((undefined !== aParams.coeffs.b7) ? aParams.coeffs.b7 : 0);
                let c1 = ((undefined !== aParams.coeffs.c1) ? aParams.coeffs.c1 : 0);
                let c2 = ((undefined !== aParams.coeffs.c2) ? aParams.coeffs.c2 : 0);
                let c3 = ((undefined !== aParams.coeffs.c3) ? aParams.coeffs.c3 : 0);
                let c4 = ((undefined !== aParams.coeffs.c4) ? aParams.coeffs.c4 : 0);
                let c5 = ((undefined !== aParams.coeffs.c5) ? aParams.coeffs.c5 : 0);
                let c6 = ((undefined !== aParams.coeffs.c6) ? aParams.coeffs.c6 : 0);
                let c7 = ((undefined !== aParams.coeffs.c7) ? aParams.coeffs.c7 : 0);

                let aLm2 = (aLambdaMicro * aLambdaMicro);
                let a = (aMaterialType == eMaterialEquationType.MODIFIED_SELLMEIER) ?
                    aParams.coeffs.a : 1;

                let aNSquare = (a + ((b1 * aLm2) / (aLm2 - c1)) + ((b2 * aLm2) / (aLm2 - c2)) +
                    ((b3 * aLm2) / (aLm2 - c3)) + ((b4 * aLm2) / (aLm2 - c4)) +
                    ((b5 * aLm2) / (aLm2 - c5)) + ((b6 * aLm2) / (aLm2 - c6)) +
                    ((b7 * aLm2) / (aLm2 - c7)));

                return Math.sqrt(aNSquare);
            }

            case eMaterialEquationType.CAUCHY: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);

                let aN = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / aLambda2) + (aParams.coeffs.d / aLambda4) +
                    (aParams.coeffs.e / aLambda6) + (aParams.coeffs.f / aLambda8));

                return aN;
            }

            case eMaterialEquationType.DRUDE_LORENTZ: {
                return this._refractionIndexDrude(
                    aParams.coeffs.b1,
                    aParams.coeffs.c1,
                    aParams.coeffs.b2,
                    aParams.coeffs.c2,
                    aParams.coeffs.b3,
                    aParams.coeffs.c3,
                    pLambdaNano);
            }

            case eMaterialEquationType.CUSTOMIZED: {
                let aRefractiveIndex = pMaterialVO.data[pLambdaNano];
                if (!isNaN(aRefractiveIndex)) {
                    return aRefractiveIndex;
                }

                let aIndexMin = Math.floor(pLambdaNano);
                let aIndexMax = Math.ceil(pLambdaNano);

                let aMinRefractiveIndex = pMaterialVO.data[aIndexMin];
                let aMaxRefractiveIndex = pMaterialVO.data[aIndexMax];

                aRefractiveIndex = aMinRefractiveIndex +
                    (((aMaxRefractiveIndex - aMinRefractiveIndex) /
                        (aIndexMax - aIndexMin)) * (pLambdaNano - aIndexMin));

                return aRefractiveIndex
            }

            case eMaterialEquationType.CONSTANT: {
                return aParams.coeffs.n;
            }

            case eMaterialEquationType.SCHOTT: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / aLambda2) + (aParams.coeffs.d / aLambda4) +
                    (aParams.coeffs.e / aLambda6) + (aParams.coeffs.f / aLambda8));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.CAUCHY2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b / aLambda2) +
                    (aParams.coeffs.c / aLambda4));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.CONRADY1: {
                let aLambda3_5 = Math.pow(aLambdaMicro, 3.5);

                let n = (aParams.coeffs.a + (aParams.coeffs.b / aLambdaMicro) +
                    (aParams.coeffs.c / aLambda3_5));

                return n;
            }

            case eMaterialEquationType.CONRADY2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda3_5 = Math.pow(aLambdaMicro, 3.5);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b / aLambda2) +
                    (aParams.coeffs.c / aLambda3_5));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HELMHOLTZ: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let a = aParams.coeffs.hl_a;
                let b = aParams.coeffs.hl_b;
                let c = aParams.coeffs.hl_c;

                let aNSqure = a[0];

                for (let i = 1, l = aParams.coeffs.hl_a.length; i < l; i++) {
                    let d = (aLambda2 - (c[i] * c[i]));
                    aNSqure += ((a[i] * aLambda2) / (d + (b[i] * aLambda2) / d));
                }

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HARTMANN1: {
                let aNSqure = (aParams.coeffs.a +
                    (aParams.coeffs.b / (aParams.coeffs.c - aLambdaMicro)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HARTMANN2: {
                let aNSqure = (aParams.coeffs.a +
                    Math.pow((aParams.coeffs.b / (aParams.coeffs.c - aLambdaMicro)), 2));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HARTMANN3A: {
                let aNSqure = (aParams.coeffs.a +
                    Math.pow((aParams.coeffs.b / (aParams.coeffs.c - aLambdaMicro)), 12));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HARTMANN3B: {
                let aNSqure = Math.pow((aParams.coeffs.a / (aParams.coeffs.b - aLambdaMicro)), 12)

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HARTMANN4: {
                let aNSqure = (aParams.coeffs.a +
                    (aParams.coeffs.b / (aParams.coeffs.c - aLambdaMicro)) +
                    (aParams.coeffs.d / (aParams.coeffs.e - aLambdaMicro)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HERZBERGER2X2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / (aLambda2 - 0.028)) +
                    (aParams.coeffs.d / Math.pow((aLambda2 - 0.028), 2)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HERZBERGER3X2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    + (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d / (aLambda2 - 0.028)) +
                    (aParams.coeffs.e / Math.pow((aLambda2 - 0.028), 2)));

                return Math.sqrt(aNSqure);
            }
            case eMaterialEquationType.HERZBERGER4X2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    + (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d * aLambda6) +
                    (aParams.coeffs.e / (aLambda2 - 0.028)) +
                    (aParams.coeffs.f / Math.pow((aLambda2 - 0.028), 2)));

                return Math.sqrt(aNSqure);
            }
            case eMaterialEquationType.HERZBERGER3X3: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    + (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d / (aLambda2 - 0.028)) +
                    (aParams.coeffs.e / Math.pow((aLambda2 - 0.028), 2)) +
                    (aParams.coeffs.f / Math.pow((aLambda2 - 0.028), 2)));

                return Math.sqrt(aNSqure);
            }
            case eMaterialEquationType.HERZBERGER5X2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    + (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d * aLambda6) +
                    (aParams.coeffs.e * aLambda8) + (aParams.coeffs.f / (aLambda2 - 0.028)) +
                    (aParams.coeffs.g / Math.pow((aLambda2 - 0.028), 2)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HERZBERGERJK: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);

                let j = (undefined !== aParams.coeffs.j) ? aParams.coeffs.j : 0.035;
                let k = (undefined !== aParams.coeffs.k) ? aParams.coeffs.k : 0.035;

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    + (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d * aLambda6) +
                    (aParams.coeffs.e / (aLambda2 - j)) +
                    (aParams.coeffs.f / Math.pow((aLambda2 - k), 2)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SCHOTT2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);
                let aLambda10 = (aLambda4 * aLambda6);
                let aLambda12 = (aLambda6 * aLambda6);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / aLambda2) + (aParams.coeffs.d / aLambda4) +
                    (aParams.coeffs.e / aLambda6) + (aParams.coeffs.f / aLambda8) +
                    (aParams.coeffs.g / aLambda10) + (aParams.coeffs.h / aLambda12));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SCHOTT3: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d / aLambda2) +
                    (aParams.coeffs.e / aLambda4) + (aParams.coeffs.f / aLambda6) +
                    (aParams.coeffs.g / aLambda8));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SCHOTT4: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d * aLambda6) +
                    (aParams.coeffs.e / aLambda2) + (aParams.coeffs.f / aLambda4) +
                    (aParams.coeffs.g / aLambda6) + (aParams.coeffs.h / aLambda8));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SCHOTT5: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);
                let aLambda8 = (aLambda4 * aLambda4);
                let aLambda10 = (aLambda4 * aLambda6);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d * aLambda6) +
                    (aParams.coeffs.e * aLambda8) + (aParams.coeffs.f / aLambda2) +
                    (aParams.coeffs.g / aLambda4) + (aParams.coeffs.h / aLambda6) +
                    (aParams.coeffs.j / aLambda8) + (aParams.coeffs.k / aLambda10));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HO01: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / (aLambda2 - aParams.coeffs.d)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.HO02: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    ((aParams.coeffs.c * aLambda2) / (aLambda2 - aParams.coeffs.d)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD1: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambdaMicro) +
                    (aParams.coeffs.c * aLambda2) +
                    ((aParams.coeffs.d * aLambda2) / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD1A: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambdaMicro) +
                    (aParams.coeffs.c * aLambda2) +
                    (aParams.coeffs.d / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) +
                    ((aParams.coeffs.d * aLambda2) / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD2A: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) +
                    (aParams.coeffs.d / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD3: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = ((((aParams.coeffs.a * aLambda2) + aParams.coeffs.b) /
                    (aLambda2 - aParams.coeffs.c)) +
                    ((aParams.coeffs.d * aLambda2) / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD4: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / aLambda2) +
                    ((aParams.coeffs.d * aLambda2) / (aLambda2 - aParams.coeffs.e)) +
                    ((aParams.coeffs.f * aLambda2) / (aLambda2 - aParams.coeffs.g)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD5: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    ((aParams.coeffs.c * aLambda2) / (aLambda2 - aParams.coeffs.d)) +
                    ((aParams.coeffs.e * aLambda2) / (aLambda2 - aParams.coeffs.f)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD6: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);

                let aNSqure = (aParams.coeffs.a +
                    ((aParams.coeffs.b * aLambda2) / (aLambda2 - aParams.coeffs.c)) +
                    (aParams.coeffs.d / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD7: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d / aLambda6) +
                    ((aParams.coeffs.e * aLambda2) / (aLambda2 - aParams.coeffs.f)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD7A: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d / aLambda6) +
                    (aParams.coeffs.e / (aLambda2 - aParams.coeffs.f)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD8: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) +
                    (aParams.coeffs.d / (aLambda2 - aParams.coeffs.e)) +
                    (aParams.coeffs.f / (aLambda2 - aParams.coeffs.g)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.SELLMOD9: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);
                let aLambda6 = (aLambda2 * aLambda4);

                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b / aLambda2) +
                    (aParams.coeffs.c / aLambda4) + (aParams.coeffs.d / aLambda6) +
                    (aParams.coeffs.e / (aLambda2 - aParams.coeffs.f)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.KINGSLAKE1: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);


                let aNSqure = (aParams.coeffs.a +
                    (aParams.coeffs.b / (aLambda2 - aParams.coeffs.c)) +
                    (aParams.coeffs.d / (aLambda2 - aParams.coeffs.e)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.KINGSLAKE2: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);


                let aNSqure = (aParams.coeffs.a +
                    (aParams.coeffs.b / (aLambda2 - aParams.coeffs.c)) +
                    (aParams.coeffs.d / (aLambda2 - aParams.coeffs.e)) +
                    (aParams.coeffs.f / (aLambda2 - aParams.coeffs.g)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.MISK01: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);


                let aNSqure = (aParams.coeffs.a +
                    (aParams.coeffs.b / (aLambda2 - aParams.coeffs.c)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.MISK02: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);


                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c / (aLambda2 - aParams.coeffs.d)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.MISK03: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);


                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b / aLambda2) +
                    ((aParams.coeffs.c * aLambda2) / (aLambda2 - aParams.coeffs.d)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.MISK04: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);
                let aLambda4 = (aLambda2 * aLambda2);


                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b * aLambda2) +
                    (aParams.coeffs.c * aLambda4) + (aParams.coeffs.d / aLambda2) +
                    ((aParams.coeffs.e * aLambda2) / (aLambda2 - aParams.coeffs.f)) +
                    ((aParams.coeffs.g * aLambda2) / (aLambda2 - aParams.coeffs.h)));

                return Math.sqrt(aNSqure);
            }

            case eMaterialEquationType.MISK05: {
                let aLambda2 = (aLambdaMicro * aLambdaMicro);


                let aNSqure = (aParams.coeffs.a + (aParams.coeffs.b / aLambda2) +
                    ((aParams.coeffs.b / aLambda2) / (1 - (aLambda2 / aParams.coeffs.c))) +
                    (aParams.coeffs.d / (aParams.coeffs.e - (1 / aLambda2))));

                return Math.sqrt(aNSqure);
            }

            default:
                throw new Error("Undefined material");

        }
    }
    //__________________________________________________________________________________________
    private static _refractionIndexDrude(B1: number,
        C1: number,
        B2: number,
        C2: number,
        B3: number,
        C3: number,
        lam: number): number {

        // TODO  update this func
        let a = 0.001 * lam;
        let lam2 = a * a;

        return Math.sqrt(1 + B1 *
            lam2 / (lam2 - C1) + B2 *
            lam2 / (lam2 - C2) + B3 *
            lam2 / (lam2 - C3));
    }
    //__________________________________________________________________________________________
}
