import { eStateToAnalysis } from "../../_context/Enums";
import { Op3dContext } from "../../_context/Op3dContext";
import { Strings } from "../../_context/Strings";
import { ViewUtils } from "../ViewUtils";
import { AnalysisPortal } from "../analysis/AnalysisPortal";
import { iNIETriggers } from "../part_info/_components/NumberInputElement";

export interface iRadioParamsComponent {
    value_attr: string;
    onChange: (pVal: string) => void;
    triggers: iNIETriggers;
}

export class RadioComponent {

    private mButtons: Array<HTMLButtonElement> = [];
    private mContainer: HTMLElement;
    private mChosenOption: string;
    private mParams: iRadioParamsComponent;

    constructor(pContainer: HTMLElement, pParams: iRadioParamsComponent) {
        this.mContainer = pContainer;
        this.mParams = pParams;
        this._initButtons();
    }
    //__________________________________________________________________________________________
    private _initButtons() {
        let aActiveElement: HTMLElement;
        let aButtons = this.mContainer.getElementsByTagName("button");
        for (let i = 0; i < aButtons.length; i++) {
            const aButton = aButtons[i];
            this.mButtons.push(aButton);
            if (aButton.classList.contains(Strings.ACTIVE_STATE_CLASS)) {
                aActiveElement = aButton;
            }
            aButton.addEventListener("click", () => this._onOptionChosen(aButton, true));
        }

        this.mChosenOption = aActiveElement.getAttribute(this.mParams.value_attr);

    }
    //__________________________________________________________________________________________
    private _onOptionChosen(pButton: HTMLButtonElement, pDispatchEvent: boolean) {
        this.mButtons.map(btn => {
            ViewUtils.setElementActive(btn, btn == pButton);
            if (btn == pButton) {
                this.mChosenOption = btn.getAttribute(this.mParams.value_attr);
            }
        });

        if (pDispatchEvent) {

            if (true == this.mParams.triggers.saveHistory) {
                Op3dContext.SCENE_HISTORY.addToHistory();
            }

            if (true == this.mParams.triggers.saveScene) {
                Op3dContext.SCENE_HISTORY.saveScene();
            }

            if (true == this.mParams.triggers.triggerAnalysis) {
                AnalysisPortal.instance.enableRunAnalysis(
                    eStateToAnalysis.ENABLE_ANALYSIS, eStateToAnalysis.FROM_SCENE);
            }

            this.mParams.onChange(this.mChosenOption);
        }
    }
    //__________________________________________________________________________________________
    public updateView() {
        this.mParams.onChange(this.mChosenOption);
    }
    //__________________________________________________________________________________________
    public chooseOption(pOption: string, pDispatchEvent: boolean) {
        let aButton = this.mButtons.find(btn => btn.getAttribute(this.mParams.value_attr) == pOption);

        this._onOptionChosen(aButton, pDispatchEvent);

    }
    //__________________________________________________________________________________________
    // public set chosenOption(pOption: string) {
    //     let aButton = this.mButtons.find(btn => btn.getAttribute(this.mParams.value_attr) == pOption);
    //     this._onOptionChosen(aButton);
    // }
    //__________________________________________________________________________________________
    public get chosenOption() {
        return this.mChosenOption;
    }
    //__________________________________________________________________________________________
}