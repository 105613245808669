import { useEffect, useState } from "react";
import styled from "styled-components";

import opti_chat_off from "../../../../public/images/icons_new/chatbot/opti_chat_off.png";
import opti_chat_off_hover from "../../../../public/images/icons_new/chatbot/opti_chat_off_hover.png";
import opti_chat_on from "../../../../public/images/icons_new/chatbot/opti_chat_on.png";
import opti_chat_on_hover from "../../../../public/images/icons_new/chatbot/opti_chat_on_hover.png";

import { ViewUtils } from "../../ui/ViewUtils";
import OptiChat, { BASIC_PARENT_CONTAINER_STYLE } from "./chat/OptiChat";
import OptiChatProvider from "../store/OptiChatProvider";
import { createPortal } from "react-dom";
import OptiChatTabs from "./chat/OptiChatTabs";
import { Op3dUtils } from "../../_utils/Op3dUtils";

const OptiChatButton = styled.div`
  .opti_chat {
    display: block;
  }
  .opti_chat_hover {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .opti_chat {
      display: none;
    }
    .opti_chat_hover {
      display: block;
    }
  }
`;

export function OptiChatBtn() {
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);

  const handleCloseOpticsMenu = () => {
    setIsChatBotOpen(false);
  };

  useEffect(() => {
    let aMainContainer = document.getElementById("main-library");

    ViewUtils.setClassForElement(aMainContainer, "chatbot", isChatBotOpen);
    let aTitle = Op3dUtils.getElementIn(aMainContainer, "menu_title");
    window.addEventListener("close_optics_menu", handleCloseOpticsMenu);

    if (isChatBotOpen) {
      aTitle.innerText = "Product catalog - search copilot";
    } else {
      aTitle.innerText = "Product catalog";
    }

    return () => {
      window.removeEventListener("close_optics_menu", handleCloseOpticsMenu);
    };
  }, [isChatBotOpen]);

  const toggleModal = () => {
    setIsChatBotOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  const closeModal = () => {
    setIsChatBotOpen(false);
  };

  return (
    <OptiChatProvider>
      {isChatBotOpen && <OptiChatTabs />}
      <OptiChatButton onClick={toggleModal} data-testid="optichat-btn">
        {isChatBotOpen ? (
          <span>
            <img src={opti_chat_on} className="opti_chat" alt="OptiChat" />
            <img
              src={opti_chat_on_hover}
              className="opti_chat_hover"
              alt="OptiChat"
            />
          </span>
        ) : (
          <span>
            <img src={opti_chat_off} className="opti_chat" alt="OptiChat" />
            <img
              src={opti_chat_off_hover}
              className="opti_chat_hover"
              alt="OptiChat"
            />
          </span>
        )}
      </OptiChatButton>
      {isChatBotOpen &&
        createPortal(
          <div style={BASIC_PARENT_CONTAINER_STYLE}>
            <OptiChat
              position={{ right: 60, bottom: 130 }}
              onClose={closeModal}
            />
          </div>,
          document.getElementById("forms")
        )}
    </OptiChatProvider>
  );
}
