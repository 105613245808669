import { eBrandTypes } from "../../../../_context/Enums";
import { Op3dContext } from "../../../../_context/Op3dContext";
import { eOpticsTypeNames } from "../../../../_context/OpticsContext";
import { Strings } from "../../../../_context/Strings";
import { iHash } from "../../../../_context/_interfaces/Interfaces";
import { OP3DMathUtils } from "../../../../_utils/OP3DMathUtils";
import { eLightSourceType, iSourceVO } from "../../../../parser/SourcesParser";
import { MenuListAbstract } from "../MenuListAbstract";
import { eFilterType, iSingleFilterData, iRangeData, iOneCheckBoxData, iRadioBtnsData, iOneRadioBtnData } from "../MenusContext";
import { SliderValue, iBootstrapSliderOptions } from "../filters/RangeFilter";
import { FiltersHandler } from "./FiltersHandler";
import { eOMFilterName } from "./OpticsFilterHandler";


export enum eSMFilterName {
    TYPES = 'parameters.type',
    BRANDS = 'parameters.info.brand',
    WAVELENGTH = 'parameters.data.center_wavelength',
    TEMPERATURE = 'parameters.data.temperature',
    // MATERIALS = 'parameters.materialID',
    // DIAMETER = 'parameters.geometry.diameter',
    // BASE_SHAPE = 'parameters.baseShape',
    // WIDTH = 'parameters.geometry.width',
    // HEIGHT = 'parameters.geometry.height',
}

export class SourcesFiltersHandler extends FiltersHandler {

    private mSpecificFilters: iHash<Object> = {};

    constructor(pMenu: MenuListAbstract<iSourceVO>, pFilterTemplate: HTMLElement,
        pFilterContainer: HTMLElement) {
        super(pMenu, pFilterTemplate, pFilterContainer);
        this.isMongoQuery = true

    }
    //__________________________________________________________________________________________
    protected async _initFilters(): Promise<void> {

        this._createFilter(this._addTypeFilter());
        // this._createFilter(this._addSubtypeFilter());

        //---------------------------------
        const aBrands = await Op3dContext.DATA_MANAGER.getBrands(eBrandTypes.LIGHT_SOURCE);
        this._createFilter(this._addCheckBoxFilter({
            items: aBrands,
            title: "Brands",
            type: eOMFilterName.brand,

        }));
        //---------------------------------
        // this._createFilter(await this._addMeterialFilter());
        // this._createFilter(this._addShapeFilter());
        this._createFilter(this._addWavelengthFilter());
        this._createFilter(this._addTemperatureFilter());

        this.mFiltersAdded = true;
    }
    //__________________________________________________________________________________________
    private _onTypeChange(pType: eLightSourceType | string, pToChange: boolean = true) {
        this.mFilterComponents[eSMFilterName.TEMPERATURE].setVisibility(false);
        this.mFilterComponents[eSMFilterName.WAVELENGTH].setVisibility(false);

        let aType = (pType != Strings.ALL) ? pType : null;
        let aIsSubtypeChosen = (aType != null);
        // const aTypeAsEnum = aType != null ? pType : null;
        if (aIsSubtypeChosen) {
            switch (aType) {
                case eLightSourceType.BLACKBODY:
                    this.mFilterComponents[eSMFilterName.TEMPERATURE].setVisibility(true);
                    break;
                case eLightSourceType.GAUSSIAN:
                    this.mFilterComponents[eSMFilterName.WAVELENGTH].setVisibility(true);
                    break;
            }
        }

        this._onIngredientChange(eSMFilterName.TYPES,
            aType,
            eFilterType.RADIO_BTNS,
            pToChange);
    }
    //__________________________________________________________________________________________
    private _addWavelengthFilter(): iSingleFilterData<iRangeData> {
        const aStep = 0.01;
        let aBootstrapSliderOptions: iBootstrapSliderOptions = {
            value: [0, 20000],
            min: 0,
            max: 20000,
            step: aStep,
            precision: Math.abs(OP3DMathUtils.pow10(aStep)),
        };

        return {

            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eSMFilterName.WAVELENGTH,
            params: {
                title: "Central Wavelength",
                onChange: (pData: SliderValue, pToChange: boolean) =>
                    this._onRangeChanged(pData, pToChange, eSMFilterName.WAVELENGTH)
            },
            data: {
                unit: "nm",
                data: aBootstrapSliderOptions,
                showDropdown: false,
                filterHandler: this
            }
        }
    }
    //__________________________________________________________________________________________
    private _addTemperatureFilter(): iSingleFilterData<iRangeData> {

        const aStep = 0.01;
        let aBootstrapSliderOptions: iBootstrapSliderOptions = {
            value: [0, 10000],
            step: aStep,
            precision: Math.abs(OP3DMathUtils.pow10(aStep)),
            min: 0,
            max: 10000
        };

        return {

            filterType: eFilterType.RANGE,
            isVisible: false,
            name: eSMFilterName.TEMPERATURE,
            params: {
                title: "Temperature",
                onChange: (pData: SliderValue, pToChange: boolean) =>
                    this._onRangeChanged(pData, pToChange, eSMFilterName.TEMPERATURE)
            },
            data: {
                unit: "K",
                data: aBootstrapSliderOptions,
                showDropdown: false,
                filterHandler: this
            }
        }
    }
    //__________________________________________________________________________________________
    private _onRangeChanged(pData: SliderValue, pToChange: boolean,
        pProp: eSMFilterName) {
        this._onIngredientChange(pProp, pData,
            eFilterType.RANGE, pToChange);
    }
    //__________________________________________________________________________________________
    private _addCheckBoxFilter(pData: {
        title: string,
        type: eOMFilterName, items: Array<string>
    }) {
        let aOnChange = (pItem: string, pToChange: boolean) =>
            this._onCheckBoxDataChanged(pItem, pData.type, pToChange);

        let aCheckboxesData = new Array<iOneCheckBoxData>();
        for (let i = 0; i < pData.items.length; i++) {
            aCheckboxesData.push({ name: pData.items[i] });
        }
        return {
            filterType: eFilterType.MULTIPLE_CHECKBOX,
            params: {
                onChange: aOnChange,
                title: pData.title
            },
            name: pData.type,
            data: {
                checkboxesData: aCheckboxesData,
                selectAllCheckbox: true
            },
            isVisible: true
        }
    }
    //__________________________________________________________________________________________
    private _addTypeFilter(): iSingleFilterData<iRadioBtnsData> {
        /**
         * @TODO - transfer it to a suitable class
         */

        let aData: iRadioBtnsData = {
            defaultNameIndex: 0,
            data: new Array<iOneRadioBtnData>()
        };

        aData.data.push({
            name: Strings.ALL
        });

        let aNames = Object.values(eLightSourceType);
        for (let i = 0; i < aNames.length; i++) {
            const aName = aNames[i];
            aData.data.push({
                name: aName
            });
        }

        return {
            filterType: eFilterType.RADIO_BTNS,
            data: aData,
            isVisible: true,
            name: eOMFilterName.type,
            params: {
                title: "Types",
                onChange: (pType: eLightSourceType, pToChange: boolean) =>
                    this._onTypeChange(pType, pToChange),
            }
        }
    }
    //__________________________________________________________________________________________
    private _onCheckBoxDataChanged(pItem: string, pType: eOMFilterName, pToChange: boolean = true) {
        let aBrand = (pItem != eOpticsTypeNames.ALL) ? pItem : null;
        this._onIngredientChange(pType,
            aBrand,
            eFilterType.MULTIPLE_CHECKBOX,
            pToChange);
    }
    //__________________________________________________________________________________________
    public async reload() {
        await super.reload();
        //this._updateShape();
    }
    //__________________________________________________________________________________________
    public getFilters() {
        let aFilters = Object.assign({}, this.mFilters, this.mSpecificFilters);
        return aFilters;
    }
    //__________________________________________________________________________________________
}
