﻿import { MessagesHandler } from "../../../_context/MessagesHandler";
import { Op3dContext } from "../../../_context/Op3dContext";
import { eBaseShape, OpticsContext } from "../../../_context/OpticsContext";
import { iOpticsVO } from "../../../data/VO/OpticsVOInterfaces";
import { GridManager } from "../../../scene/GridManager";
import { SceneContext } from "../../../scene/SceneContext";
import { Op3dComponentBase } from "../../Op3dComponentBase";
import { ViewUtils } from "../../ViewUtils";
import { Popup } from "../Popup";
import { OpticsFactory } from "../../../parts/optics/OpticsFactory";
import { Part } from "../../../parts/Part";
import { OrbitControls } from "../../../scene/OrbitControls.js";
import { BasicShadowMap, MOUSE, FrontSide, BufferAttribute, Box3, Camera, Euler, GridHelper, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, PlaneGeometry, Scene, WebGLRenderer } from "three";
import { iFaceDataNEW } from "../../../parts/PartInterfaces.js";
// import { Camera } from "three/src/cameras/Camera.js";
// import { PerspectiveCamera } from "three/src/cameras/PerspectiveCamera.js";
// import { Object3D } from "three/src/core/Object3D.js";
// import { PlaneGeometry } from "three/src/geometries/PlaneGeometry.js";
// import { GridHelper } from "three/src/helpers/GridHelper.js";
// import { MeshBasicMaterial } from "three/src/materials/MeshBasicMaterial.js";
// import { Box3 } from "three/src/math/Box3.js";
// import { Euler } from "three/src/math/Euler.js";
// import { Mesh } from "three/src/objects/Mesh.js";
// import { WebGLRenderer } from "three/src/renderers/WebGLRenderer.js";
// import { Scene } from "three/src/scenes/Scene.js";
// import { iFaceDataNEW } from "../../../parts/PartInterfaces.js";

export interface i3DPresenterParams {
    isFixedDiv: boolean;
    ///parentForm: asBase.CoComponentBase;

    isInfo?: boolean;
    isShowSpaceBtn?: boolean;
    enableAchromaticPrefs?: boolean;
    enableExportBtn?: boolean;
    alwaysShowImage?: boolean;
    showImage?: boolean;
}

export enum eDisplayedItem {
    Image,
    optic3D
};

export interface i3DPresentOpticsOptions {
    fitToZoom?: boolean;
    highlightedFaceName?: string;
    isOptomechanicsPart?: boolean;
}

export class Optics3DPresenter extends Op3dComponentBase<iOpticsVO> {

    private static SKIN_PATH: string = "./skins/tools/optics_3d_presenter.html";
    private static DEFAULT_DISPLAY_ITEM: eDisplayedItem = eDisplayedItem.optic3D;
    private static DIV_WIDTH: number = 338;
    private static DIV_HEIGHT: number = 275;
    private static BG_COLOR: number = 0xf5f5f5;

    //3d scene
    private mOrbitController: OrbitControls;
    //private mCamera: PerspectiveCamera;
    private mCamera: Camera;
    private mScene: Scene;
    private mOpticsContainer: Object3D;
    private mRenderer: WebGLRenderer;
    private mShape: Object3D;
    private mGrid: GridHelper;
    private mPlane: Mesh;

    //html 
    private mCanvasContainer: HTMLElement;

    private mImageParent: HTMLElement;
    private mOpticsImg: HTMLImageElement;
    private mNoImageDIv: HTMLElement;
    private mImageBtn: HTMLElement;
    private mOptic3DBtn: HTMLElement;
    private mCanvasSwitchBtns: HTMLElement;

    // data
    private mCurrDisplayedItem: eDisplayedItem = eDisplayedItem.optic3D;
    private mToUpdate: boolean = false;
    private mRendereFrame = () => this._updateView();
    private mPresenterData: i3DPresenterParams;

    private mUnHighlightFunc: Function;
    private mOptomechanicsMesh: Mesh;
    private mPartForPresenter: any;


    constructor(pParentElement: HTMLElement, pPresenterData: i3DPresenterParams) {
        super({
            container: pParentElement,
            skinPath: Optics3DPresenter.SKIN_PATH
        });

        this.mPresenterData = pPresenterData;
        if (null != this.mParams) {
            this._initParams();
        }
    }
    //__________________________________________________________________________________________
    public set showImageBtn(pVal: boolean) {
        ViewUtils.setElementVisibilityByDNone(this.mImageBtn, pVal);
    }
    //__________________________________________________________________________________________
    public initForInfo(pOpticsVO: iOpticsVO) {
        this._resetCanvas();
        let aIsRect = pOpticsVO.parameters.baseShape == eBaseShape.RECTANGULAR;
        this.updateImage(pOpticsVO.parameters.type, pOpticsVO.parameters.subType,
            pOpticsVO.parameters.info.brand, aIsRect);
    }
    //__________________________________________________________________________________________
    private _setCanvasVisibility(pVisible: boolean) {
        let aIsImageVisible = ((true == this.mPresenterData.alwaysShowImage) || (false == pVisible));
        let aShowCanvas = ((true == this.mPresenterData.alwaysShowImage) || (true == pVisible));

        ViewUtils.setElementVisibilityByBlockNone(this.mCanvasContainer, aShowCanvas);
        ViewUtils.setElementVisibilityByBlockNone(this.mImageParent, aIsImageVisible);
    }
    //__________________________________________________________________________________________
    private _onZoomOut() {
        this.mOrbitController.dIn(SceneContext.CONTROLLER_ZOOM_STEPS);
        this.mOrbitController.update();
    }
    //__________________________________________________________________________________________
    private _onZoomIn() {
        this.mOrbitController.dOut(SceneContext.CONTROLLER_ZOOM_STEPS);
        this.mOrbitController.update();
    }

    //__________________________________________________________________________________________
    protected _onCreationComplete(): void {
        this.mCanvasContainer = this._getPart("optics-3d-presenter-canvas");
        this.mCanvasContainer.onwheel = function (event) {
            event.preventDefault();
        }; this.mCanvasContainer.addEventListener("mousedown", (event) => {
            event.preventDefault();
        })
        this.mImageParent = this._getPart("image-parent");
        this.mOpticsImg = this._getPart("optics-img") as HTMLImageElement;
        this.mNoImageDIv = this._getPart("no-image-div");

        this._getPart("zoom-in-3d").addEventListener('click',
            () => this._onZoomIn());

        this._getPart("zoom-out-3d").addEventListener('click',
            () => this._onZoomOut());

        this._getPart("show-grid-3d").addEventListener('click',
            () => this._onShowGrid());

        this.mOptic3DBtn = this._getPart("show_3d", true);
        this.mOptic3DBtn.addEventListener("click",
            () => this.onChangeItemType(eDisplayedItem.optic3D, true));

        this.mImageBtn = this._getPart("show_image", true);
        this.mImageBtn.addEventListener("click",
            () => this.onChangeItemType(eDisplayedItem.Image, true));

        $('[data-toggle="tooltip"]').tooltip().on('click', function () {
            $(this).blur()
        });
        this.mCanvasSwitchBtns = this._getPart('canvas_switch_btns');

        this._removeContainerFixed();
        ///this.initAchromaticPreferences();
        ///this.initSpaceBtn();
        this._initScene();
        this._updateView();
        this._resetCanvas();

        ViewUtils.setElementVisibilityByDNone(this.mCanvasSwitchBtns,
            (false != this.mPresenterData.showImage));

        this.mIsReady = true;
    }
    //__________________________________________________________________________________________
    protected _updateView() {
        requestAnimationFrame(this.mRendereFrame);

        if (this.mToUpdate) {
            this.mOrbitController.update();
            this.mRenderer.render(this.mScene, this.mCamera);
        }
    }
    //__________________________________________________________________________________________
    public updateImage(pType: string, pSubtype: string, pVendor: string, pIsRect: boolean) {
        let aToShowImage = ((null != this.mParams && true == this.mPresenterData.alwaysShowImage) ||
            (this.mCurrDisplayedItem === eDisplayedItem.Image));

        if (false == aToShowImage) {
            return;
        }

        let aPath = Op3dContext.DATA_MANAGER.getImagePathNew(pType, pSubtype, pVendor, pIsRect);
        this._setImage(aPath)
    }
    //__________________________________________________________________________________________
    private _resetCanvas() {
        this._clearShape();
        this.mData = null;
        this.onChangeItemType(Optics3DPresenter.DEFAULT_DISPLAY_ITEM, true);
        // this._initCamera();
    }
    //__________________________________________________________________________________________
    private _getOpticRotation(pSubType: string): Euler {
        let aRot: Euler = new Euler();
        switch (pSubType) {
            case OpticsContext._Off_Axis_Parabolic_Lens:
            case OpticsContext._Off_Axis_Parabolic_Mirror:
                aRot = new Euler(0, Math.PI, 0);
                break;
        }

        return aRot.clone();
    }
    //__________________________________________________________________________________________
    public onChangeItemType(pType: eDisplayedItem, pUpdate3D: boolean) {
        if (pType === this.mCurrDisplayedItem) {
            return;
        }

        this.mCurrDisplayedItem = pType;

        switch (pType) {
            case eDisplayedItem.Image:
                this._setCanvasVisibility(false);
                this.mImageBtn.classList.add("active");
                this.mOptic3DBtn.classList.remove("active");

                if (this.mData) {
                    const aIsRect = this.mData.parameters.baseShape == eBaseShape.RECTANGULAR;
                    const aPath = Op3dContext.DATA_MANAGER.getImagePath(
                        this.mData.parameters.type,
                        this.mData.parameters.subType,
                        aIsRect);
                    this._setImage(aPath);

                }
                break;
            case eDisplayedItem.optic3D:
                this._setCanvasVisibility(true);
                this.mImageBtn.classList.remove("active");
                this.mOptic3DBtn.classList.add("active");

                if (pUpdate3D === true) {
                    if (!this.mPresenterData.isInfo || this.mShape == null) {
                        this.onRefreshOptic(this.mData);
                    }
                }

                break;
        }
    }
    //__________________________________________________________________________________________
    public set toUpdate(pVal: boolean) {
        this.mToUpdate = pVal;
    }
    //__________________________________________________________________________________________
    private _zoomToFit(pMeshY: number) {
        let aZoomDelta = pMeshY * 5
        this.mCamera.position.set(0, pMeshY * 3, -aZoomDelta);
        this.mOrbitController.target.set(0, pMeshY, 0)
        this.mOrbitController.update()
    }
    //__________________________________________________________________________________________
    private async _initScene() {
        this.mOpticsContainer = new Object3D();
        this.mOpticsContainer.name = "optics-container";
        this.mScene = new Scene();

        let aWidth: number = Optics3DPresenter.DIV_WIDTH;
        let aHeight: number = Optics3DPresenter.DIV_HEIGHT;
        let aAspectRatio: number = aWidth / aHeight;
        this.mCamera = new PerspectiveCamera(45, aAspectRatio, 0.1, 10000 * 10000);

        this.mRenderer = new WebGLRenderer({ antialias: true });
        (this.mRenderer as any).setClearColor(Optics3DPresenter.BG_COLOR, 1.0);
        this.mRenderer.setSize(Optics3DPresenter.DIV_WIDTH, Optics3DPresenter.DIV_HEIGHT);
        this.mRenderer.shadowMap.enabled = true;
        this.mRenderer.shadowMap.type = BasicShadowMap;
        this.mCanvasContainer.appendChild(this.mRenderer.domElement);

        this.mOrbitController = new OrbitControls(this.mCamera, this.mRenderer.domElement);
        this.mOrbitController.mouseButtons = {
            // ORBIT: MOUSE.RIGHT,
            // ZOOM: MOUSE.MIDDLE,
            // PAN: MOUSE.LEFT,
            LEFT: null,
            RIGHT: MOUSE.LEFT,
            MIDDLE: MOUSE.RIGHT
        }
        this.mOrbitController.maxDistance = 2500;

        this.mScene.add(this.mOpticsContainer);
        this.mScene.add(this.mCamera);

        await this._initLights();

        let aDivisions: number = 50;
        this.mGrid = new GridHelper((GridManager.CELL_SIZE / 2) * aDivisions, aDivisions);
        //this.mGrid.position.y = -(GridManager.CELL_SIZE * 1.5);
        this.mScene.add(this.mGrid);

        let aGeo = new PlaneGeometry(150, 150);
        let aMat = new MeshBasicMaterial({ side: FrontSide, color: Optics3DPresenter.BG_COLOR, transparent: false, opacity: 1 });
        this.mPlane = new Mesh(aGeo, aMat);
        //this.mPlane.position.set(0, -(GridManager.CELL_SIZE * 1.5), 0);
        this.mPlane.rotateX(-Math.PI / 2);
        this.mPlane.visible = true;
        //this.mPlane.receiveShadow = true;

        this._setCanvasVisibility(true);
    }
    //__________________________________________________________________________________________
    private async _initLights() {
        let aLights = await SceneContext.OP3D_SCENE.lights;
        if (aLights) {
            aLights.name = "lights";
            this.mScene.add(aLights);
        }
    }
    //__________________________________________________________________________________________
    private _setImage(pPath: string) {
        try {

            if (pPath != null && pPath != "") {
                this.mOpticsImg.src = pPath;
                this.mNoImageDIv.style.display = "none";
                ViewUtils.setElementVisibilityByDNone(this.mOpticsImg, true);
                ViewUtils.setClassShowState(this.mImageParent, true);
            } else {
                this.mNoImageDIv.style.display = (null != this.mParams &&
                    true == this.mPresenterData.alwaysShowImage) ? 'none' : "contents";
                ViewUtils.setElementVisibilityByDNone(this.mOpticsImg, false);
                ViewUtils.setClassShowState(this.mImageParent, false);
            }
        } catch (e) {
            MessagesHandler.ON_ERROR_PROGRAM(e as any);
        }
    }
    //__________________________________________________________________________________________
    private _onShowGrid() {
        let aGridVisibility = this.mGrid.visible;
        this.mGrid.visible = !aGridVisibility;
    }
    //__________________________________________________________________________________________
    public onRefreshOptic(pOpticsVO: iOpticsVO, pOptions?: i3DPresentOpticsOptions) {
        try {
            this.onChangeItemType(eDisplayedItem.optic3D, false);
            this.mData = pOpticsVO;
            let aFaces = OpticsFactory.createOpticsNew(pOpticsVO);

            let aContainer = new Object3D();

            for (let i = 0; i < aFaces.length; i++) {
                const aFace = aFaces[i];
                aContainer.add(aFace.visualization.mesh);
            }

            this._setCanvasVisibility(true);
            this._updateOptics(aContainer, pOptions);
        } catch (e) {
            this._setCanvasVisibility(false);
            Popup.instance.open({ text: MessagesHandler.OPTICAL_ELEMENT_CREATION_ERROR });
        }
    }
    //__________________________________________________________________________________________
    public highlightMesh(pName: string) {
        let aUnHighlighFuncs = new Array<Function>();
        for (let i = 0; i < this.mShape.children.length; i++) {
            let aMesh = this.mShape.children[i] as Mesh;
            let aOpacity = (aMesh.material as MeshBasicMaterial).opacity;
            if (aMesh.name == pName) {
                let aColor = (aMesh.material as MeshBasicMaterial).color.getHex();
                (aMesh.material as MeshBasicMaterial).color.setHex(0x23A7DE);
                aUnHighlighFuncs.push(() => {
                    (aMesh.material as MeshBasicMaterial).color.setHex(aColor);
                    (aMesh.material as MeshBasicMaterial).opacity = aOpacity;
                });
                (aMesh.material as MeshBasicMaterial).opacity = 1;
            } else {
                (aMesh.material as MeshBasicMaterial).opacity = 0.5;
                (aMesh.material as MeshBasicMaterial).transparent = true;
                aUnHighlighFuncs.push(() => {
                    (aMesh.material as MeshBasicMaterial).opacity = aOpacity;
                });
            }
        }

        this.mUnHighlightFunc = () => {
            for (let i = 0; i < aUnHighlighFuncs.length; i++) {
                aUnHighlighFuncs[i]();
            }
        };
    }
    //__________________________________________________________________________________________
    public unHighlightMesh() {
        if (null == this.mUnHighlightFunc) {
            return;
        }

        this.mUnHighlightFunc();
    }
    //__________________________________________________________________________________________
    private _removeContainerFixed() {
        if (!this.mPresenterData.isFixedDiv) {
            let aFixedContainer = this._getPart("fixed-container");
            aFixedContainer.classList.remove('position-fixed');
        }
    }
    //__________________________________________________________________________________________
    private _updateOptics(pShape: Object3D, pOptions?: i3DPresentOpticsOptions) {

        this._clearShape();
        let aRot = this.mData != null ?
            this._getOpticRotation(this.mData.parameters.subType) : new Euler();

        this.mShape = pShape;
        this.mShape.name = "shape";
        this.mShape.receiveShadow = false;
        this.mShape.rotation.copy(new Euler(aRot.x, aRot.y, aRot.z));

        let boundingBox = new Box3().setFromObject(this.mShape)
        this.mShape.position.set(0, Math.abs(boundingBox.min.y), 0)

        this.mOpticsContainer.add(this.mShape);

        let aFitToZoom = ((null != pOptions) && (null != pOptions.fitToZoom)) ?
            pOptions.fitToZoom : true;
        if (true == aFitToZoom) {
            let aMinZoom = 15;
            let aZoomToFitVal = Math.abs(boundingBox.min.y) > aMinZoom ? Math.abs(boundingBox.min.y) : aMinZoom;
            this._zoomToFit(aZoomToFitVal)
        }

        if ((null != pOptions) && (null != pOptions.highlightedFaceName)) {
            if (null != pOptions.isOptomechanicsPart && pOptions.isOptomechanicsPart) {
                this.highlightOptomechanicsFace(pOptions.highlightedFaceName);
            } else {
                this.highlightMesh(pOptions.highlightedFaceName);
            }
        }
    }
    //__________________________________________________________________________________________
    public _clearShape() {
        if (this.mShape != null && this.mShape.parent != null) {
            this.mShape.parent.remove(this.mShape);
            this.mShape = null;
        }
    }
    //__________________________________________________________________________________________
    private _initParams() {
        if (true == this.mPresenterData.alwaysShowImage) {
            this.mContainer.classList.add('al_image_dis');
        }
    }
    //__________________________________________________________________________________________
    public async onRefreshOptomechanicsOptic(pPart: Part, pOptions?: i3DPresentOpticsOptions) {
        try {
            this.mImageBtn.classList.add('d-none')
            pPart.unHighlightObject()
            this.mPartForPresenter = pPart;
            ViewUtils.setElementVisibilityByDNone(this.mOpticsImg, false)
            let aContainer = new Object3D();
            const aFaces = pPart.getFaces();
            for (let i = 0; i < aFaces.length; i++) {
                const aFace = aFaces[i];
                let aCloneMesh = aFace.visualization.mesh.clone();
                let aCloneMat = (aCloneMesh.material as MeshBasicMaterial).clone();
                aCloneMat.opacity = 0.5;
                aCloneMat.transparent = true;
                aCloneMesh.material = aCloneMat;
                this.mOptomechanicsMesh = aCloneMesh;
                aContainer.add(aCloneMesh)
            }

            this._setCanvasVisibility(true);
            this._updateOptics(aContainer, pOptions);
        } catch (e) {
            this._setCanvasVisibility(false);
            Popup.instance.open({ text: MessagesHandler.OPTICAL_ELEMENT_CREATION_ERROR });
        }
    }
    //__________________________________________________________________________________________
    public highlightOptomechanicsFace(pName: string, pNotUnhighlight: boolean = false) {
        if (pNotUnhighlight === false) {
            this.unHighlightOptomechanicsFaceAll()
        }
        let aPartFaces = this.mPartForPresenter.subParts[0].shapes[0].solids[0].faces[0].indexes;
        let aCurrFace = aPartFaces.find((item: iFaceDataNEW) => {
            return item.name == pName;
        })

        for (let i = aCurrFace.indexes.start; i <= aCurrFace.indexes.end; i++) {
            this.mOptomechanicsMesh.geometry.attributes['color'].setXYZ(i, 0, 0, 255);
        }

        this.mOptomechanicsMesh.geometry.attributes['color'].needsUpdate = true
    }
    //__________________________________________________________________________________________
    public highlightOptomechanicsSolid(pPathNum: number, pNotUnhighlight: boolean = false) {
        if (pNotUnhighlight === false) {
            this.unHighlightOptomechanicsFaceAll()
        }
        let aFaces = this.mPartForPresenter.subParts[0].shapes[0].solids[0].faces[0].indexes.filter(item => item.path[1] === pPathNum);

        for (let q = 0; q < aFaces.length; q++) {
            let aCurrFace = aFaces[q]
            for (let i = aCurrFace.indexes.start; i <= aCurrFace.indexes.end; i++) {
                this.mOptomechanicsMesh.geometry.attributes['color'].setXYZ(i, 0, 0, 255);
            }

        }

        this.mOptomechanicsMesh.geometry.attributes['color'].needsUpdate = true
    }
    //__________________________________________________________________________________________
    public unHighlightOptomechanicsFaceAll() {
        if (this.mOptomechanicsMesh !== undefined) {
            (this.mOptomechanicsMesh.geometry.attributes['color'] as BufferAttribute).copy(this.mOptomechanicsMesh.geometry.attributes['colorBase'] as BufferAttribute);
            (this.mOptomechanicsMesh.material as MeshBasicMaterial).opacity = 0.5;
            (this.mOptomechanicsMesh.material as MeshBasicMaterial).transparent = true;
            this.mOptomechanicsMesh.geometry.attributes['color'].needsUpdate = true;
            this._updateView();
        }
    }
    //__________________________________________________________________________________________
}