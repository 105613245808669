export interface iComplexNumber {
    re: number;
    im: number;
};

export interface iComplexNumberPolar {
    r: number;
    thetha: number;
}

export class ComplexNumber {

    public re: number = 0;
    public im: number = 0;

    //__________________________________________________________________________________________
    constructor(pRe: number);
    constructor(pComplexNumber: iComplexNumber);
    //__________________________________________________________________________________________
    constructor(pNumber?: iComplexNumber | number) {
        this.set(pNumber);
    }
    //__________________________________________________________________________________________
    public set polar(pNum: iComplexNumberPolar) {
        this.re = pNum.r * Math.cos(pNum.thetha);
        this.im = pNum.r * Math.sin(pNum.thetha);
    }
    //__________________________________________________________________________________________
    public get polar() {
        let aPolar: iComplexNumberPolar = {
            r: this.abs,
            thetha: this.angle
        };

        return aPolar;
    }
    //__________________________________________________________________________________________
    public set(pNumber?: iComplexNumber | number) {
        if (typeof pNumber == "number") {
            this.re = pNumber;
        } else {
            if (null != pNumber.re) {
                this.re = pNumber.re;
            }
            if (null != pNumber.im) {
                this.im = pNumber.im;
            }
        }
    }
    //__________________________________________________________________________________________
    public get abs() {
        return Math.sqrt((this.re * this.re) + (this.im * this.im));
    }
    //__________________________________________________________________________________________
    public get angle() {
        return Math.atan2(this.im, this.re);
    }
    //__________________________________________________________________________________________
    public get conjugate() {
        return new ComplexNumber({ re: this.re, im: -this.im });
    }
    //__________________________________________________________________________________________
    public isEqual(pNum: ComplexNumber) {
        return ((this.re == pNum.re) && (this.im == pNum.im));
    }
    //__________________________________________________________________________________________
    public add(pNum: number): this;
    public add(pNum: ComplexNumber): this;
    public add(pNum: ComplexNumber | number) {
        if (typeof pNum == "number") {
            this.re += pNum;
        } else {
            this.im += pNum.im;
            this.re += pNum.re;
        }

        return this;
    }
    //__________________________________________________________________________________________
    public sub(pNum: number): this;
    public sub(pNum: ComplexNumber): this;
    public sub(pNum: ComplexNumber | number) {
        if (typeof pNum == "number") {
            this.re -= pNum;
        } else {
            this.im -= pNum.im;
            this.re -= pNum.re;
        }

        return this;
    }
    //__________________________________________________________________________________________
    public multiply(pNum: number): this;
    public multiply(pNum: ComplexNumber): this;
    public multiply(pNum: ComplexNumber | number) {
        if (typeof pNum == "number") {
            this.re *= pNum;
            this.im *= pNum;
        } else {
            let aRe = ((this.re * pNum.re) - (this.im * pNum.im));
            let aIm = ((this.im * pNum.re) + (this.re * pNum.im));

            this.re = aRe;
            this.im = aIm;
        }

        return this;
    }
    //__________________________________________________________________________________________
    public divideComplexNumbers(pNum: ComplexNumber, pDenum: ComplexNumber) {
        this.re = pNum.re;
        this.im = pNum.im;

        return this.divide(pDenum);
    }
    //__________________________________________________________________________________________
    public divide(pNum: ComplexNumber | number) {
        if (typeof pNum == "number") {
            this.re /= pNum;
            this.im /= pNum;
        } else {
            let conjugate = pNum.conjugate;
            let r = ((pNum.re * pNum.re) + (pNum.im * pNum.im));

            this.multiply(conjugate);
            this.divide(r);
        }
        return this;
    }
    //__________________________________________________________________________________________
    public pow(pNum: number) {
        let abs = this.abs;
        let angle = this.angle;

        let r = Math.pow(abs, pNum);
        this.re = r * Math.cos(pNum * angle);
        this.im = r * Math.sin(pNum * angle);

        return this;
    }
    //__________________________________________________________________________________________
    public clone() {
        return new ComplexNumber({ re: this.re, im: this.im });
    }
    //__________________________________________________________________________________________
}